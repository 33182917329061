import { createSelector } from 'reselect';
import { SelectOption } from '../../components/common';
import { getUDFInitialFormValues } from '../../components/enrollment/helper';
import { PaginationSetting, SorterConfigEx } from '../../model/CommonModel';
import { OptionsButtonBuilder } from '../../model/DeviceAdminModel';
import {
	AddActionOptions,
	AdvanceSearchCriteria,
	CredentialTemplate,
	EnrollmentGridItem,
	EnrollmentResourceStrings,
	initialFormValueObject,
	PersonDetailModel,
	PersonFormModel,
	PersonGroups,
	PersonTemplate,
	TreeViewPersonGroupModel,
} from '../../model/EnrollmentModel';
import { UserDefinedFieldModel } from '../../model/UserDefinedFields';
import { RootReducer } from '../rootReducer';

export const selectPersonGroups = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personGroups,
	(personGroups: PersonGroups[]) => personGroups
);

export const selectSelectedPersonGroup = createSelector(
	(state: RootReducer) => state.enrollmentReducer.selectedPersonGroup,
	(selectedPersonGroup: TreeViewPersonGroupModel) => selectedPersonGroup
);

export const selectIsFICAMEnabled = createSelector(
	(state: RootReducer) => state.enrollmentReducer.isFICAMEnabled,
	(isFICAMEnabled: boolean) => isFICAMEnabled
);

export const selectPersonTableInformation = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personTableInformation,
	(personTableInformation: EnrollmentGridItem) => personTableInformation
);

export const selectIsPersonTableLoading = createSelector(
	(state: RootReducer) => state.enrollmentReducer.isPersonTableLoading,
	(isPersonTableLoading: boolean) => isPersonTableLoading
);

export const selectBlankProfilePictureBase64 = createSelector(
	(state: RootReducer) => state.enrollmentReducer.blankProfilePictureBase64,
	(blankProfileBase64: string) => blankProfileBase64
);

export const selectBlankProfileSignatureBase64 = createSelector(
	(state: RootReducer) => state.enrollmentReducer.blankProfileSignatureBase64,
	(blankProfileSignatureBase64: string) => blankProfileSignatureBase64
);

export const selectSelectedRowKeys = createSelector(
	(state: RootReducer) => state.enrollmentReducer.selectedRowKeys,
	(selectedRowKeys: React.Key[]) => selectedRowKeys
);

export const selectPersonTablePaginationSettings = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personTablePaginationSettings,
	(personTablePaginationSettings: PaginationSetting) => personTablePaginationSettings
);

export const selectUpdateCounter = createSelector(
	(state: RootReducer) => state.enrollmentReducer.updateCounter,
	(updateCounter: number) => updateCounter
);

export const selectAdvanceSearchCriteria = createSelector(
	(state: RootReducer) => state.enrollmentReducer.advanceSearchCriteria,
	(advanceSearchCriteria: AdvanceSearchCriteria) => advanceSearchCriteria
);

export const selectActiveAdvancedSearch = createSelector(
	(state: RootReducer) => state.enrollmentReducer.activeAdvancedSearch,
	(activeAdvancedSearch: boolean) => activeAdvancedSearch
);

export const selectPersonId = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personInformationId,
	(personInformationId: number) => personInformationId
);

export const selectPersonInformationData = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personInformationData,
	(personInformationData: PersonDetailModel) => personInformationData
);

export const selectPersonInformationPersonGroups = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personInformationData,
	(personInformationData: PersonDetailModel) => personInformationData.PersonGroups
);

export const selectIsEnrollmentTabLoading = createSelector(
	(state: RootReducer) => state.enrollmentReducer.isEnrollmentTabLoading,
	(isEnrollmentTabLoading: boolean) => isEnrollmentTabLoading
);

export const selectPersonTableSorterConfig = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personTableSorterConfig,
	(personTableSorterConfig: SorterConfigEx) => personTableSorterConfig
);

export const selectEnrollmentResourceStrings = createSelector(
	(state: RootReducer) => state.enrollmentReducer.resourceStrings,
	(resourceStrings: EnrollmentResourceStrings) => resourceStrings
);

export const selectEnrollmentResourceStringsTitleSelectOptions = createSelector(
	(state: RootReducer) => state.enrollmentReducer.resourceStrings,
	(resourceStrings: EnrollmentResourceStrings) => {
		const opts: SelectOption = [
			{ label: resourceStrings.Mr, value: resourceStrings.Mr },
			{ label: resourceStrings.Mrs, value: resourceStrings.Mrs },
			{ label: resourceStrings.Ms, value: resourceStrings.Ms },
			{ label: resourceStrings.Dr, value: resourceStrings.Dr },
		];
		return opts;
	}
);

export const selectEnrollmentResourceStringsSuffixSelectOptions = createSelector(
	(state: RootReducer) => state.enrollmentReducer.resourceStrings,
	(resourceStrings: EnrollmentResourceStrings) => {
		const opts: SelectOption = [
			{ label: resourceStrings.Jr, value: resourceStrings.Jr },
			{ label: resourceStrings.Sr, value: resourceStrings.Sr },
		];
		return opts;
	}
);

export const selectFetchingScanData = createSelector(
	(state: RootReducer) => state.enrollmentReducer.fetchingScanData,
	(fetchingScanData: boolean) => fetchingScanData
);

export const selectDisplayAddViaSmartCard = createSelector(
	(state: RootReducer) => state.enrollmentReducer.displayAddViaSmartCard,
	(displayAddViaSmartCard: boolean) => displayAddViaSmartCard
);

export const selectHavePersonChanged = createSelector(
	(state: RootReducer) => state.enrollmentReducer.havePersonChanged,
	(havePersonChanged: boolean) => havePersonChanged
);

export const selectPersonPIVFullName = createSelector(
	[(state: RootReducer) => state.enrollmentReducer.personInformationData, (state: RootReducer) => state.enrollmentReducer.personInformationId],
	(personInformationData: PersonDetailModel, personInformationId: number) =>
		personInformationId !== 0 ? `${personInformationData.LastName}, ${personInformationData.FirstName}` : ''
);

export const selectPivUdfFields = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personInformationData,
	(personInformationData: PersonDetailModel) =>
		personInformationData.UserDefinedFields.reduce<number[]>((result: number[], udf: UserDefinedFieldModel) => {
			if (udf.IsPIVField) {
				result.push(udf.FieldNumber);
			}
			return result;
		}, [])
);

export const selectHavePivField = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personInformationData,
	(personInformationData: PersonDetailModel) => {
		const pivFieldIndex: number = personInformationData.UserDefinedFields.findIndex((udf: UserDefinedFieldModel) => udf.IsPIVField);
		return pivFieldIndex !== -1;
	}
);

export const selectPersonFullName = createSelector(
	[(state: RootReducer) => state.enrollmentReducer.personInformationData, (state: RootReducer) => state.enrollmentReducer.personInformationId],
	(personInformationData: PersonDetailModel, personInformationId: number) => {
		let personFullName: string;
		if (personInformationId === -100 || personInformationData.IsTemplate) {
			personFullName = `<${_('NewPerson')}>`;
		} else {
			personFullName = `${personInformationData.LastName}, ${personInformationData.FirstName}`;
		}
		return personFullName;
	}
);

export const selectPersonInitialValues = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personInformationData,
	(personInformationData: PersonDetailModel) => {
		const personFormObject: PersonFormModel = new PersonFormModel();
		const personInitialValues: initialFormValueObject[] = Object.keys(personFormObject.FormKeysObject).map(
			(key: keyof Omit<PersonFormModel, 'FormKeysObject'>) => {
				return {
					[key]: personInformationData[key],
				};
			}
		);

		const udfInitialValues: initialFormValueObject[] = getUDFInitialFormValues(personInformationData.UserDefinedFields);

		return Object.assign({}, ...personInitialValues, ...udfInitialValues);
	}
);

export const selectAddCredentialOptions = createSelector(
	[(state: RootReducer) => state.enrollmentReducer.credentialTemplates, (state: RootReducer) => state.enrollmentReducer.defaultCredentialTemplateId],
	(credentialTemplates: CredentialTemplate[], defaultCredentialTemplateId: number) => {
		let options: OptionsButtonBuilder<string> = {
			labelOrIcon: _('AddCredential'),
			options: [
				{
					id: 'enrollmentAddCredentialButton',
					label: _('AddCredential'),
					value: `${AddActionOptions.AddDefault},${defaultCredentialTemplateId}`,
					separator: true,
				},
			],
		};

		options.options.push(
			...credentialTemplates.map((credential, index) => ({
				id: `enrollmentAddCredentialButton-${index}`,
				label: `${_('Template')} - ${credential.Description}`,
				value: `${AddActionOptions.AddTemplate},${credential.CredentialTemplateId}`,
			}))
		);

		return options;
	}
);

export const selectAddPersonOptions = createSelector(
	(state: RootReducer) => state.enrollmentReducer.personTemplates,
	(personTemplates: PersonTemplate[]) => {
		let options: OptionsButtonBuilder<string> = {
			labelOrIcon: _('AddPerson'),
			options: [
				{
					id: 'enrollmentAddDefaultPersonButton',
					label: _('AddPerson'),
					value: `${AddActionOptions.AddDefault},-100`,
					separator: true,
				},
			],
		};

		options.options.push(
			...personTemplates.map((personTemplate, index) => ({
				id: `enrollmentAddTemplatePersonButton-${index}`,
				label: `${_('Template')} - ${personTemplate.Name}`,
				value: `${AddActionOptions.AddTemplate},${personTemplate.PersonTemplateId}`,
			}))
		);

		return options;
	}
);

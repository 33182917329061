import { AvailableDoorInfo, PhotoCallUpUserConfiguration, PhotoCallUpUserInformationModel } from '../../model/PhotoCallUpModel';
import { PhotoCallUpAction } from './actions';
import { PhotoCallUpActionTypes } from './actionTypes';

//States' definition
const INIT_STATE: PhotoCallUpState = {
	selectedDoors: [],
	quantityPerDoor: 0,
	loadCachedEntries: true,
	modalUserInfo: null,
	photoCallUpUserConfiguration: null,
	configurationNotification: 0,
	openConfigurationModal: false,
	isModalLoading: true,
};

export type PhotoCallUpState = {
	selectedDoors: AvailableDoorInfo[];
	quantityPerDoor: number;
	loadCachedEntries: boolean;
	modalUserInfo: PhotoCallUpUserInformationModel;
	photoCallUpUserConfiguration: PhotoCallUpUserConfiguration;
	configurationNotification: number;
	openConfigurationModal: boolean;
	isModalLoading: boolean;
};

//Reducers
const photoCallUpReducer = (state: Readonly<PhotoCallUpState> = INIT_STATE, action: PhotoCallUpAction): PhotoCallUpState => {
	switch (action.type) {
		case PhotoCallUpActionTypes.RETRIEVE_ENROLLMENT_USER_INFO:
			return {
				...state,
				modalUserInfo: action.payload,
			};

		case PhotoCallUpActionTypes.OPEN_CONFIGURATION:
			return {
				...state,
				quantityPerDoor: action.payload.quantityPerDoor,
				loadCachedEntries: action.payload.loadCachedEntries,
				selectedDoors: action.payload.selectedDoors,
				openConfigurationModal: true,
			};

		case PhotoCallUpActionTypes.SET_PHOTOCALLUP_USER_CONFIGURATION:
			return {
				...state,
				photoCallUpUserConfiguration: action.payload,
			};

		case PhotoCallUpActionTypes.CONFIGURATION_SAVE_NOTIFICATION:
			return {
				...state,
				configurationNotification: action.payload,
			};

		case PhotoCallUpActionTypes.CLOSE_CONFIGURATION:
			return {
				...state,
				openConfigurationModal: action.payload,
			};

		case PhotoCallUpActionTypes.SET_MODAL_LOADING:
			return {
				...state,
				isModalLoading: action.payload,
			};

		default:
			return state;
	}
};

export { photoCallUpReducer };

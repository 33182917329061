import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { configurationApi } from '../../api/';
import { ConfigurationState, SettingsValue } from '../../model/ConfigurationModel';
import { ConfigurationActionTypes } from './actionTypes';

type GeneralSettingsSetDateFormat = {
	type: ConfigurationActionTypes.SET_CULTURE_FORMAT;
	payload: string;
};

type GeneralSettingsSetInitial = {
	type: ConfigurationActionTypes.SET_DEFAULT_DATA;
	payload: ConfigurationState;
};

type GeneralSettingsSetCheckBoxes = {
	type: ConfigurationActionTypes.SET_CHECKBOX_SETTING_VALUE;
	payload: SettingsValue<boolean>;
};

type ViewSettingsSetSettingValue = {
	type: ConfigurationActionTypes.SET_VIEW_SETTING_VALUE;
	payload: SettingsValue<string>;
};

type SettingsSetSettingValue = {
	type: ConfigurationActionTypes.SET_SETTING_VALUE;
	payload: SettingsValue<string>;
};

type SettingsHaveChanged = {
	type: ConfigurationActionTypes.SET_SETTINGS_HAVE_CHANGED;
	payload: boolean;
};

//Union Action Types
export type GeneralSettingsAction =
	| GeneralSettingsSetDateFormat
	| GeneralSettingsSetInitial
	| GeneralSettingsSetCheckBoxes
	| ViewSettingsSetSettingValue
	| SettingsSetSettingValue
	| SettingsHaveChanged;

//Action Creators
const setGeneralSettingsDateFormatAction = (payload: string): GeneralSettingsSetDateFormat => {
	return {
		type: ConfigurationActionTypes.SET_CULTURE_FORMAT,
		payload,
	};
};

//Export Action
export const setViewSettingsSetValue = (payload: SettingsValue<string>): ViewSettingsSetSettingValue => {
	return {
		type: ConfigurationActionTypes.SET_VIEW_SETTING_VALUE,
		payload,
	};
};

export const setGeneralSettingsSetCheckboxes = (payload: SettingsValue<boolean>): GeneralSettingsSetCheckBoxes => {
	return {
		type: ConfigurationActionTypes.SET_CHECKBOX_SETTING_VALUE,
		payload,
	};
};

export const setSettingsSetValue = (payload: SettingsValue<string>): SettingsSetSettingValue => {
	return {
		type: ConfigurationActionTypes.SET_SETTING_VALUE,
		payload,
	};
};

export const setGeneralSettingsSetInitial = (payload: ConfigurationState): GeneralSettingsSetInitial => {
	return {
		type: ConfigurationActionTypes.SET_DEFAULT_DATA,
		payload,
	};
};

export const setSettingsHaveChanged = (payload: boolean): SettingsHaveChanged => {
	return {
		type: ConfigurationActionTypes.SET_SETTINGS_HAVE_CHANGED,
		payload,
	};
};

export const setGeneralSettingsDateFormat = (value: string): ThunkAction<Promise<void>, {}, {}, GeneralSettingsAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, GeneralSettingsAction>): Promise<void> => {
		const res = await configurationApi.retrieveCultureDateFormat(value);
		dispatch(setGeneralSettingsDateFormatAction(res));
	};
};

import { InputNumber } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../Helper';
import { setMsgPerPoll, StoreContext } from '../../contextXbox';
import styles from '../../xboxModal.module.scss';

const MsgPerPoll: React.FC = () => {
	const {
		xboxState: { messagesPerPoll },
		dispatcher,
	} = useContext(StoreContext);

	const handleOnChangeMessages = (value: string) => {
		if (!Number(value)) {
			return;
		}

		dispatcher(setMsgPerPoll(Number(value)));
	};

	const xboxMessagesPerPollInputId = 'xboxMessagesPerPollInput';

	return (
		<div className={styles.sections}>
			<label htmlFor={xboxMessagesPerPollInputId}>{_('MsgPerPoll')}</label>
			<div>
				<InputNumber
					id={xboxMessagesPerPollInputId}
					key='MsgPerPoll'
					min={6}
					max={15}
					maxLength={2}
					value={messagesPerPoll}
					onChange={value => handleOnChangeMessages(value.toString())}
					className={cx(styles.input)}
					formatter={noneDecimalFormatter}
				/>
				<label htmlFor={xboxMessagesPerPollInputId}> 6 = {_('Minimum')}</label>
			</div>
		</div>
	);
};

export { MsgPerPoll };

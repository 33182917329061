import { Alert, Button, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import constants from '../../../constants';
import { AvailableDoorInfo, PhotoCallUpUserConfiguration } from '../../../model/PhotoCallUpModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { closeConfiguration, saveConfiguration } from '../../../store/photocallup/actions';
import { selectIsModalLoading, selectMappedSelectedDoors, selectPhotoCallUpUserConfiguration } from '../../../store/photocallup/selectors';
import { Modal } from '../../common';
import './configuration.scss';
import { ConfigurationDoors } from './ConfigurationDoors/ConfigurationDoors';
import { ConfigurationView } from './ConfigurationView/ConfigurationView';

const configuration = _('PhotoCallUpConfiguration');
const applyChanges = _('SaveChanges');
const cancel = _('Cancel');
const initialErrorState = { isValid: true, SelectedDoors: '', QuantityPerDoor: '', Error: '' };

const Configuration: React.FC = () => {
	const photoCallUpUserConfigurationStore: PhotoCallUpUserConfiguration = useStoreSelector<PhotoCallUpUserConfiguration>(selectPhotoCallUpUserConfiguration);
	const [photoCallUpUserConfiguration, setPhotoCallUpUserConfiguration] = useState<PhotoCallUpUserConfiguration>(photoCallUpUserConfigurationStore);
	const [errors, setErrors] = useState(initialErrorState);

	const dispatch = useStoreDispatch();
	const mapDoorsSelected: AvailableDoorInfo[] = useStoreSelector<AvailableDoorInfo[]>(selectMappedSelectedDoors);
	const isModalLoading: boolean = useStoreSelector<boolean>(selectIsModalLoading);

	useEffect(() => {
		setPhotoCallUpUserConfiguration(photoCallUpUserConfigurationStore);
		if (photoCallUpUserConfigurationStore === null) {
			setErrors(initialErrorState);
		}
	}, [photoCallUpUserConfigurationStore]);

	const handlePhotoCallUpUserConfigurationChange = (name: string, value: string | number | string[] | boolean | AvailableDoorInfo[]) => {
		setPhotoCallUpUserConfiguration(prevPhotoCallUpUserConfiguration => ({
			...prevPhotoCallUpUserConfiguration,
			[name]: value,
		}));
	};

	const handleCheckBoxChange = (event: CheckboxChangeEvent) => {
		const { name, checked } = event.target;
		handlePhotoCallUpUserConfigurationChange(name, checked);
	};

	const handleSubmit = () => {
		const sessionStorageKey = constants.sessionStorage.photoCallUp.RETRIEVE_SAVE_CONFIGURATION_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '1') {
			return;
		}

		window.sessionStorage.setItem(sessionStorageKey, '1');
		const formErrors = PhotoCallUpUserConfiguration.isValid(photoCallUpUserConfiguration);
		if (!formErrors.isValid) {
			setErrors(formErrors);
			window.sessionStorage.setItem(sessionStorageKey, '0');
			return;
		}
		dispatch(saveConfiguration(photoCallUpUserConfiguration));
	};

	const handleChangeSelectedDoors = (doors: AvailableDoorInfo[]) => {
		handlePhotoCallUpUserConfigurationChange('SelectedDoors', [...doors]);
	};

	const handleCloseConfiguration = () => {
		dispatch(closeConfiguration());
	};

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={handleCloseConfiguration}
			onClickOk={() => null}
			title={configuration}
			visible={true}
			width={1000}
			destroyOnClose
			customZoomClass='withModalZoomPhotoCallUp'
			footer={[
				<Button
					id={'photoCallUpConfigurationSaveButton'}
					key='applyChanges'
					type='primary'
					htmlType='submit'
					disabled={isModalLoading}
					onClick={() => {
						handleSubmit();
					}}>
					{applyChanges}
				</Button>,
				<Button
					id={'photoCallUpConfigurationCancelButton'}
					key='closePhotoCallUpConfiguration'
					onClick={handleCloseConfiguration}
					disabled={isModalLoading}>
					{cancel}
				</Button>,
			]}>
			<Spin size='large' tip={`${_('Loading')}...`} spinning={isModalLoading}>
				<div id='photoCallUpConfigurationModal' className='photo-grid-container'>
					<ConfigurationView
						photoCallUpUserConfiguration={photoCallUpUserConfiguration}
						handleCheckBoxChange={handleCheckBoxChange}
						handlePhotoCallUpUserConfigurationChange={handlePhotoCallUpUserConfigurationChange}
					/>
					<div>
						<fieldset className='photo-configure-border'>
							<legend className='photo-configure-section-title'>{_('AvailableDoors')}</legend>
							<ConfigurationDoors
								doorsBelongFromParent={mapDoorsSelected}
								controllersForDoors={photoCallUpUserConfiguration?.ControllersForDoors}
								onChangeDoorsSelected={handleChangeSelectedDoors}
							/>
						</fieldset>
					</div>
				</div>
				{errors.Error && <Alert message={errors.Error} type='error' className='photo-configure-error' />}
			</Spin>
		</Modal>
	);
};

export { Configuration };

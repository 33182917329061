import { Button, notification, Spin, Tabs } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../api';
import { unlockHandleBeforeUnload, unlockSNIBSearchComponent, validateIPv4AddressFormat, validateIPv6AddressFormat } from '../../../../Helper';
import { getPermissionErrorMessage, SecuredComponents, User } from '../../../../model/AccountModel';
import { PaginationSetting } from '../../../../model/CommonModel';
import { CurrentDeviceControlObj, DeviceObjectType, Port, PortConnectionType, SerialInfo } from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { setControllerContacts } from '../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { Modal, ModalLoading, NotificationStatus, WarningProtocolMessage, WithLockedValidation } from '../../../common';
import {
	initPortAction,
	portContext,
	PortContextState,
	portState,
	PortStoreContext,
	resetSearchModal,
	setDefaultGatewatIPv6,
	setDefaultGateway,
	setIPAddress,
	setIPPort,
	setIPv6Address,
	setName,
	setSubnetMask,
	setSubnetPrefix,
} from './contextPort';
import styles from './portModal.module.scss';
import { SearchModal } from './SearchModal/SearchModal';
import { AdvancedSettings } from './Tabs/AdvancedSettings/AdvancedSettings';
import { General } from './Tabs/General/General';

const { TabPane } = Tabs;

type Props = {
	id: number;
	address: string;
	setShouldResetSearchColumn: () => void;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	saveCallBack?: () => void;
	setRedirectPage?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const permissionsEnabled = User.getComponentPermission(user, SecuredComponents.Ports).canUpdate;

const PortModal: React.FC<Props> = WithLockedValidation(({ id, address, setShouldResetSearchColumn, cancelHandle, saveCallBack, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const [stateContext, dispatchActionContext] = useReducer(portContext, portState);
	const [nameError, setNameError] = useState(false);
	const [submittedForm, setSubmitForm] = useState(false);
	const [ipInvalid, setIPInvalid] = useState(false);
	const [subnetMaskValid, setSubnetMaskValid] = useState(false);
	const [defaultGatewayValid, setDefaultGatewayValid] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [velocityConfigurationLoading, setVelocityConfigurationLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState('');

	const {
		name,
		openSearchModal,
		snibSelected,
		portNetworkType,
		ipAddress,
		ipv6Address,
		subnetMask,
		defaultGateway,
		defaultGatewayIPv6,
		subnetPrefix,
		protocol,
		enablePort,
		hasPortChanged,
		resetEncryption,
		comPort,
	} = stateContext;

	const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const getSerialInfo = async (): Promise<SerialInfo> => {
		const baudRates: number[] = await deviceAdminApi.getBaudRates();
		const { Entity: comPorts } = await deviceAdminApi.getAvailableCOMPorts();

		const serialInfo: SerialInfo = {
			BaudRates: baudRates,
			COMPorts: comPorts,
		};
		return serialInfo;
	};

	useEffect(() => {
		if (id !== 0) {
			Promise.all([deviceAdminApi.getPort(id), getSerialInfo()]).then(res => {
				const port = res[0];
				const serialInfo = res[1];

				let initPort: Partial<PortContextState> = {
					id: port.Id,
					name: port.Name,
					portNetworkType: port.NetworkType,
					protocol: port.Protocol,
					ipAddress: port.IPAddress,
					ipPort: port.IPPort,
					subnetMask: port.SubnetMask,
					defaultGateway: port.DefaultGateway,
					maxRetryAttempts: port.MaxRetryAttempts,
					totalTimeoutMultiplier: port.TotalTimeoutMultiplier,
					totalTimeoutConstant: port.TotalTimeoutConstant,
					intervalTimeout: port.IntervalTimeout,
					resetEncryption: port.ResetEncryption,
					enablePort: port.EnablePort,
					defaultConnectionType: port.DefaultConnectionType,
					portStyle: port.PortStyle,
					portType: port.PortType,
					baudRate: port.BaudRate,
					portAddress: port.PortAddress,
					comPort: port.COMPort,
					baudRates: serialInfo.BaudRates,
					comPorts: serialInfo.COMPorts,
				};

				if (port.NetworkType > 2 && port.PortType === 15) {
					initPort.ipv6Address = port.IPAddress;
					initPort.subnetPrefix = port.SubnetMask;
					initPort.defaultGatewayIPv6 = port.DefaultGateway;
				}
				dispatchActionContext(initPortAction(initPort));
				setIsLoading(false);
			});
		} else {
			getSerialInfo().then(res => {
				const initPort: Partial<PortContextState> = {
					baudRates: res.BaudRates,
					comPorts: res.COMPorts,
					defaultConnectionType: PortConnectionType.XNET,
				};

				dispatchActionContext(initPortAction(initPort));
				setIsLoading(false);
			});
		}
	}, [id]);

	const shouldResetSearchColumn = isEditedPort => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!isEditedPort) {
			return;
		}

		if (setRedirectPage) {
			setRedirectPage();
		}
	};

	const handleSaveChanges = async () => {
		setSubmitForm(true);
		if (name === '') {
			return;
		}

		if (portNetworkType > 2) {
			if (!validateIPv6AddressFormat(ipv6Address)) {
				setIPInvalid(true);
				setErrorMessage(_('IPAddressIsRequired'));
				return;
			}
			if (subnetPrefix === '') {
				setSubnetMaskValid(true);
				return;
			}

			if (!validateIPv6AddressFormat(defaultGatewayIPv6)) {
				setDefaultGatewayValid(true);
				return;
			}
		} else if (portNetworkType === 2) {
			if (!validateIPv4AddressFormat(ipAddress)) {
				setIPInvalid(true);
				setErrorMessage(_('IPAddressIsRequired'));
				return;
			}

			if (protocol > 2) {
				if (!validateIPv4AddressFormat(subnetMask) && protocol !== 2) {
					setSubnetMaskValid(true);
					return;
				}
				if (!validateIPv4AddressFormat(defaultGateway) && protocol !== 2) {
					setDefaultGatewayValid(true);
					return;
				}
			}
		}

		const port: Port = {
			Id: stateContext.id,
			Name: stateContext.name,
			NetworkType: stateContext.portNetworkType,
			Protocol: stateContext.protocol,
			IPAddress: portNetworkType > 2 ? stateContext.ipv6Address : stateContext.ipAddress,
			IPPort: stateContext.ipPort,
			SubnetMask: portNetworkType > 2 ? stateContext.subnetPrefix : stateContext.subnetMask,
			DefaultGateway: portNetworkType > 2 ? stateContext.defaultGatewayIPv6 : stateContext.defaultGateway,
			MaxRetryAttempts: stateContext.maxRetryAttempts,
			TotalTimeoutMultiplier: stateContext.totalTimeoutMultiplier,
			TotalTimeoutConstant: stateContext.totalTimeoutConstant,
			IntervalTimeout: stateContext.intervalTimeout,
			ResetEncryption: stateContext.resetEncryption,
			EnablePort: stateContext.enablePort,
			DefaultConnectionType: stateContext.defaultConnectionType,
			PortStyle: stateContext.portStyle,
			PortType: stateContext.portType,
			PortAddress: stateContext.portAddress,
			BaudRate: stateContext.baudRate,
			COMPort: stateContext.comPort,
			HasChanged: stateContext.hasPortChanged,
		};

		if (protocol !== 2 && resetEncryption) {
			WarningProtocolMessage({ onConfirm: () => savePort(port), protocolData: { portId: id, protocol: protocol } });
		} else {
			savePort(port);
		}
	};

	const savePort = async (port: Port) => {
		const isEditedPort: boolean = id > 0;
		shouldResetSearchColumn(isEditedPort);
		if (isEditedPort && hasPortChanged) {
			setVelocityConfigurationLoading(hasPortChanged);
		}
		const response = isEditedPort ? await deviceAdminApi.editPort(port) : await deviceAdminApi.addPort(port);
		if (isEditedPort && hasPortChanged) {
			setVelocityConfigurationLoading(false);
		}
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;
		const toDispatch = setControllerContacts(DeviceObjectType.Port, paginationSetting);

		NotificationStatus({
			responseData: response,
			notUseDefaultNotification: true,
			onSuccessCallback: () => {
				closePortModal(toDispatch);
				if (saveCallBack) {
					saveCallBack();
				}
			},
			onFailedValidation: () => {
				setNameError(true);
			},
			onAdditionalInfoRequiredCallback: () => {
				setIPInvalid(true);
				setErrorMessage(response.ErrorMessage);
			},
			onDefaultEventCallback: async () => {
				unlockHandleBeforeUnload(port.Id, DeviceObjectType.Port);
			},
		});
	};

	const closePortModal = toDispatch => {
		closeVelocityConfigurationLoading();
		dispatch(toDispatch);
		setSubmitForm(false);
		handleCloseMainModal();
	};

	const closeVelocityConfigurationLoading = () => {
		setVelocityConfigurationLoading(false);
	};

	const handleSaveAddress = () => {
		if (snibSelected !== null) {
			dispatchActionContext(setName(snibSelected?.Hostname));
			dispatchActionContext(setIPAddress(snibSelected?.IPAddress));
			dispatchActionContext(setIPv6Address(snibSelected?.IPv6Address));
			dispatchActionContext(setIPPort(snibSelected?.Port));

			dispatchActionContext(setSubnetMask(snibSelected?.SubnetMask));
			dispatchActionContext(setSubnetPrefix(snibSelected?.IPv6Prefix));
			dispatchActionContext(setDefaultGateway(snibSelected?.DefaultGateway));
			dispatchActionContext(setDefaultGatewatIPv6(snibSelected?.IPv6Gateway));

			handleCancelSubModal();
		} else {
			notification['error']({
				message: _('SearchSelectPort'),
			});
		}
	};

	const handleCloseMainModal = () => {
		const newDevice = { Id: 0, DeviceObjectType: DeviceObjectType.Port, IsModalOpen: false };
		if (cancelHandle) {
			cancelHandle({ Id: 0, DeviceObjectType: DeviceObjectType.Port });
		} else {
			dispatch(setCurrentDevice(newDevice));
		}
		unlockPort();
	};

	const unlockPort = () => {
		unlockHandleBeforeUnload(id, DeviceObjectType.Port);
	};

	const handleCancelSubModal = () => {
		/*dispatchActionContext(setOpenSearchModal(false));
		dispatchActionContext(setBroadcastData([]));
		dispatchActionContext(setSNIBSelected(null));*/
		dispatchActionContext(resetSearchModal());
		unlockSNIBSearchComponent();
	};

	const isNameEmpty = submittedForm && name === '';
	const isIPInvalid = submittedForm && ipInvalid;
	const isSubnetMaskValid = submittedForm && subnetMaskValid;
	const isDefaultGatewayValid = submittedForm && defaultGatewayValid;

	const contextValue = useMemo(() => {
		return { portState: stateContext, dispatcher: dispatchActionContext };
	}, [stateContext, dispatchActionContext]);

	return (
		<PortStoreContext.Provider value={contextValue}>
			<Modal
				onClickOk={() => null}
				onCancel={() => handleCloseMainModal()}
				title={id > 0 ? `${_('EditPort')} ${address}` : _('AddPort')}
				visible={true}
				footer={[
					<Button
						id='portSaveChangesButton'
						key='save'
						type='primary'
						disabled={!permissionsEnabled}
						title={getPermissionErrorMessage(permissionsEnabled)}
						onClick={() => handleSaveChanges()}>
						{_('SaveChanges')}
					</Button>,
					<Button id='portCancelButton' key='cancel' onClick={() => handleCloseMainModal()}>
						{_('Cancel')}
					</Button>,
				]}
				width='726px'>
				<div id='portModalContainer' className={styles.container}>
					<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default'>
						<Tabs type='card' defaultActiveKey='1'>
							<TabPane id='portGeneralTab' tab={_('General')} key='1'>
								<General
									nameValidation={isNameEmpty}
									nameError={nameError}
									onResetNameError={() => setNameError(false)}
									ipAddressValidation={isIPInvalid}
									onResetIPAddressValidation={() => setIPInvalid(false)}
									subnetMaskValidation={isSubnetMaskValid}
									onResetSubnetMaskValidation={() => setSubnetMaskValid(false)}
									defaultGatewayValidation={isDefaultGatewayValid}
									onResetDefaultGatewayValidation={() => setDefaultGatewayValid(false)}
									errorMessage={errorMessage}
									onResetErrorMessage={() => setErrorMessage('')}
								/>
							</TabPane>
							<TabPane id='portAdvancedSettingsTab' tab={_('AdvancedSettings')} key='2'>
								<AdvancedSettings />
							</TabPane>
						</Tabs>
					</Spin>
				</div>
			</Modal>
			{openSearchModal && <SearchModal onHandleCancelSubModal={handleCancelSubModal} onHandleSaveAddress={handleSaveAddress} />}
			<ModalLoading visible={velocityConfigurationLoading}>
				<label id='portConfiguringPortLabel'>{_('VelocityIsConfiguringPort')}</label>
			</ModalLoading>
		</PortStoreContext.Provider>
	);
});

export { PortModal };

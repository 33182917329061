import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../../model/AccountModel';
import { PaginationSetting } from '../../../../../model/CommonModel';
import { ControlZone, ReaderControlGroupInfo, ReaderControlGroupItem } from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setReaderControlGroupsBy } from '../../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfigurationFilterMode } from '../../../../../store/deviceControl/selectors';
import { Modal, ModalWarning, NotificationStatus } from '../../../../common';
import {
	ControllersSelected,
	StoreContext,
	readerContext,
	readerState,
	setControlZoneSelected,
	setControlZones,
	setController,
	setControllers,
	setDisplayNetworkLayout,
	setEditControlZoneHasValue,
	setErrors,
	setName,
	setReaders,
	setReadersPreSelected,
	setReadersSelected,
	setSelectedReadersGridInfo,
} from './contextReader';
import styles from './readerModal.module.scss';
import { ControlZonesDropdown, ControllersGrid, EditControlZone, InfoGrid, NameInput, ReadersGrid } from './sections';

type Props = {
	onSetVisible: () => void;
	readerToEdit?: ReaderControlGroupItem;
	setShouldResetSearchColumn: () => void;
	setRedirectPage?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Reader_Control_Group);

const ReaderModal: React.FC<Props> = ({ onSetVisible, readerToEdit, setShouldResetSearchColumn, setRedirectPage }) => {
	const dispatchReduxAction = useStoreDispatch();
	const [stateContext, dispatchActionContext] = useReducer(readerContext, readerState);
	const [emptyNameError, setEmptyNameError] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	const [resetSelectAllReaders, setResetSelectAllReaders] = useState(false);

	const [showEditControlZoneModal, setEditControlZonesModal] = useState(false);
	const [editSingleRowControlZone, setEditSingleRowControlZone] = useState(null as ControllersSelected);
	const [controllerIdForEditControlZone, setControllerIdForEdit] = useState(0);
	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const {
		controllerSelectedId,
		readersPreSelected,
		controlZoneSelected,
		name,
		displayMyNetworkLayout,
		readersSelected,
		readersInfoGridSelected,
		controlZoneEditValue,
	} = stateContext;

	useEffect(() => {
		const action = readerToEdit
			? deviceAdminApi.editReaderControlGroupDialog(readerToEdit.ReaderControlGroupId)
			: deviceAdminApi.addReaderControlGroupDialog();
		action.then(res => {
			Promise.all([
				dispatchActionContext(setControllers(res.ControllersForReaders)),
				res.ControllersForReaders.length ? dispatchActionContext(setController(res.ControllersForReaders[0].Id)) : undefined,
				dispatchActionContext(setName(readerToEdit?.Name ?? '')),
				dispatchActionContext(
					setReadersSelected(
						res.ReaderControlGroup.ReaderControlGroupReaders.map(x => ({
							Address: x.ReaderAddress,
							ControlZoneIndex: x.ControlZoneIndex,
							ControlZoneName: x.ControlZoneName,
							ControllerId: x.ControllerId,
							ControllerName: null,
							IsMasterControlZone: false,
							ReaderId: x.ReaderId,
							ReaderIndex: x.ReaderIndex,
							ReaderName: x.ReaderName,
						}))
					)
				),
			]).then(res => setIsLoading(false));
		});

		deviceAdminApi.getErrorMessages(SecuredComponents.Reader_Control_Group).then(res => setEmptyNameError(res.EmptyName));
		window.addEventListener('beforeunload', handleCloseModal);

		return () => {
			window.removeEventListener('beforeunload', handleCloseModal);
		};
	}, []);

	useEffect(() => {
		if (controllerSelectedId !== 0 && displayMyNetworkLayout) {
			deviceAdminApi.getControllersReaders(controllerSelectedId).then(res => dispatchActionContext(setReaders(res)));
			dispatchActionContext(setControlZoneSelected(null));
			dispatchActionContext(setReadersPreSelected([]));
			deviceAdminApi.getControlZonesByController(controllerSelectedId).then(res => {
				dispatchActionContext(
					setControlZones(
						res.map(x => ({
							ControlZoneIndex: x.CZIndex,
							ControlZoneName: x.Name,
						}))
					)
				);
			});
		} else if (!displayMyNetworkLayout) {
			deviceAdminApi.getControllersReaders(-1).then(res => dispatchActionContext(setReaders(res)));
		}
	}, [controllerSelectedId, displayMyNetworkLayout]);

	const handleAddReaderToGrid = () => {
		const readersPreselectLength = readersPreSelected.length;
		if (!controlZoneSelected || readersPreselectLength === 0) {
			if (readersPreselectLength === 0) dispatchActionContext(setErrors({ value: true, type: 'readerInput' }));
			if (!controlZoneSelected) dispatchActionContext(setErrors({ value: true, type: 'controlZoneInput' }));
			return;
		}
		const zone: ControlZone = {
			ControlZoneIndex: controlZoneSelected.ControlZoneIndex,
			ControlZoneName: controlZoneSelected.ControlZoneName,
			IsMasterControlZone: controlZoneSelected.IsMasterControlZone,
		};
		const selected: ControllersSelected[] = [...readersSelected];
		const preSelected: ControllersSelected[] = readersPreSelected.map(x => ({
			...x,
			...zone,
		}));

		const selectAllNotSelected = preSelected.filter(x => selected.findIndex(w => w.ReaderId === x.ReaderId) < 0);
		dispatchActionContext(setReadersSelected([...selected, ...selectAllNotSelected]));
		dispatchActionContext(setReadersPreSelected([]));
		setResetSelectAllReaders(false);
	};

	const shouldResetSearchColumn = readerToEdit => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!readerToEdit) {
			return;
		}

		setRedirectPage();
	};

	const handleOnClickSave = () => {
		if (!name || name.trim() === '') {
			dispatchActionContext(setErrors({ value: emptyNameError, type: 'nameInput' }));
			return;
		}
		const readers: ReaderControlGroupInfo = {
			ReaderControlGroupId: readerToEdit ? readerToEdit.ReaderControlGroupId : 0,
			ReaderControlGroupName: name.trim(),
			ReaderControlGroupReaders: readersSelected.map(x => ({
				ControlZoneIndex: x.ControlZoneIndex,
				ControlZoneName: x.ControlZoneName,
				ControllerId: x.ControllerId,
				ReaderAddress: x.Address,
				ReaderId: x.ReaderId,
				ReaderIndex: x.ReaderIndex,
				ReaderName: x.ReaderName,
			})),
		};

		const actionApi = readerToEdit ? deviceAdminApi.editReaderControlGroup(readers) : deviceAdminApi.addReaderControlGroup(readers);
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		actionApi.then(res =>
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {
					dispatchReduxAction(setReaderControlGroupsBy(paginationSetting));
					shouldResetSearchColumn(readerToEdit);
					onSetVisible();
				},
				onFailedValidation: () => dispatchActionContext(setErrors({ value: res.ErrorMessage, type: 'nameInput' })),
			})
		);
	};
	const handleOkControlZonesEdit = () => {
		if (!controlZoneEditValue) {
			dispatchActionContext(setErrors({ value: true, type: 'controlZoneEditInput' }));
			return;
		}
		const cloneState = readersSelected.map(x =>
			(editSingleRowControlZone ? editSingleRowControlZone.ReaderId === x.ReaderId : readersInfoGridSelected.some(w => w === x.ReaderId))
				? {
						...x,
						ControlZoneName: controlZoneEditValue.ControlZoneName,
						ControlZoneIndex: controlZoneEditValue.ControlZoneIndex,
						IsMasterControlZone: controlZoneEditValue.IsMasterControlZone,
				  }
				: x
		);
		dispatchActionContext(setReadersSelected([...cloneState]));
		dispatchActionContext(setEditControlZoneHasValue(null));
		dispatchActionContext(setErrors({ value: false, type: 'controlZoneEditInput' }));
		setEditSingleRowControlZone(null);
		setEditControlZonesModal(false);
	};
	const handleCancelConfirmation = () => {
		dispatchActionContext(setErrors({ type: 'controlZoneEditInput', value: false }));
		setEditControlZonesModal(false);
		setEditSingleRowControlZone(null);
	};
	const handleCheckDisplayNetwork = (e: CheckboxChangeEvent) => {
		const value = e.target.checked;
		dispatchActionContext(setDisplayNetworkLayout(value));
		setResetSelectAllReaders(false);
	};
	const handleRemoveReader = () => {
		const cloneState = [...readersSelected];
		readersInfoGridSelected.map(d => {
			const index = cloneState.findIndex(x => x.ReaderId === d);
			if (~index) {
				cloneState.splice(index, 1);
			}
		});
		dispatchActionContext(setReadersSelected([...cloneState]));
		dispatchActionContext(setSelectedReadersGridInfo([]));
	};
	const handleOnClickGridReaderRow = (reader: ControllersSelected) => {
		setControllerIdForEdit(reader.ControllerId);
		setEditSingleRowControlZone(reader);
		setEditControlZonesModal(true);
	};

	const handleEditMultipleRows = () => {
		const getFirstOrDefaultController = readersSelected.find(x => x.ReaderId === readersInfoGridSelected[0]).ControllerId;

		const findDifferentController = (currentValue: number) =>
			readersSelected.find(x => x.ReaderId === currentValue).ControllerId !== getFirstOrDefaultController;

		const someIsDifferent = readersInfoGridSelected.some(findDifferentController);
		if (someIsDifferent && readersInfoGridSelected.length > 1) {
			ModalWarning({
				hideCancelButton: true,
				okText: 'OK',
				content:
					'Control zones that belong to different controllers cannot be edited. Please select readers that belong to the same controller to edit.',
			});
		} else {
			setControllerIdForEdit(getFirstOrDefaultController);
			setEditControlZonesModal(true);
		}
	};

	const handleCloseModal = () => {
		if (readerToEdit) {
			deviceAdminApi.unlockComponentDeviceAdmin(readerToEdit.ReaderControlGroupId, SecuredComponents.Reader_Control_Group);
		}
		onSetVisible();
	};

	const permissionsEnabled = readerToEdit ? componentPermission.canUpdate : componentPermission.canAdd;
	const disabled = !permissionsEnabled;

	const disableActionReaderButtons = readersInfoGridSelected.length === 0;

	const contextValue = useMemo(() => ({ readerState: stateContext, dispatcher: dispatchActionContext }), [stateContext, dispatchActionContext]);
	const { Provider } = StoreContext;

	return (
		<Provider value={contextValue}>
			<Modal
				footer={[
					<Button
						id='readerControlGroupSaveChangesButton'
						key='save'
						title={getPermissionErrorMessage(permissionsEnabled)}
						type='primary'
						disabled={disabled}
						onClick={() => handleOnClickSave()}>
						{_('SaveChanges')}
					</Button>,
					<Button id='readerControlGroupCancelButton' key='cancel' onClick={() => handleCloseModal()}>
						{_('Cancel')}
					</Button>,
				]}
				width='900px'
				visible={true}
				title={readerToEdit ? _('EditReaderControlGroup') : _('AddReaderControlGroup')}
				onCancel={() => handleCloseModal()}
				onClickOk={() => null}>
				{showEditControlZoneModal && (
					<EditControlZone
						controllerId={controllerIdForEditControlZone}
						onOkControlZonesEdit={handleOkControlZonesEdit}
						onCancel={handleCancelConfirmation}
					/>
				)}
				<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
					<div className={styles.container}>
						<div className={styles.sections}>
							<NameInput />
							<ControlZonesDropdown />
						</div>
						<div className={styles.sections}>
							{displayMyNetworkLayout && <ControllersGrid />}
							<ReadersGrid reset={resetSelectAllReaders} onSelectAll={setResetSelectAllReaders} />
						</div>
						<div className={styles.sections}>
							<div className={styles.network}>
								<Checkbox id='readerControlGroupNetworkLayoutCheckBox' onChange={handleCheckDisplayNetwork} checked={displayMyNetworkLayout}>
									{_('DisplayMyNetworkLayout')}
								</Checkbox>
							</div>
							<div className={styles.add}>
								<Button id='readerControlGroupAddReaderButton' type='primary' onClick={() => handleAddReaderToGrid()}>
									<PlusOutlined /> {_('Add')}
								</Button>
							</div>
						</div>
						<div className={styles.sections}>
							<InfoGrid onClickRow={handleOnClickGridReaderRow} />
						</div>
						<div className={styles.sections}>
							<div className={styles.edit}>
								<Button
									id='readerControlGroupEditReaderButton'
									key='edit'
									type='primary'
									onClick={() => handleEditMultipleRows()}
									disabled={disableActionReaderButtons}>
									<EditOutlined /> {_('Edit')}
								</Button>
								<Button
									id='readerControlGroupRemoveReaderButton'
									key='remove'
									onClick={() => handleRemoveReader()}
									disabled={disableActionReaderButtons}>
									<MinusOutlined /> {_('Remove')}
								</Button>
							</div>
						</div>
					</div>
				</Spin>
			</Modal>
		</Provider>
	);
};

export { ReaderModal };

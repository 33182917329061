import { Col, InputNumber, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { Select } from '../../../../../../common';
import { setDoorPhotoCallUpDelayAction, setDoorPhotoCallUpTemplateIdAction } from '../../../DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorStoreSingleContext';
import styles from '../../general.module.scss';

type Props = {};

const PhotoCallUp: React.FC<Props> = () => {
	const [badgeTemplatesOptions, setBadgeTemplatesOptions] = useState([]);

	const {
		contextStateDoor: {
			door: {
				door: { PhotoCallUpDelay, PhotoCallUpTemplateId },
				badgeTemplates,
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleSelectPhotoCallUp = (value: string) => {
		dispatcherDoor(setDoorPhotoCallUpTemplateIdAction(Number(value)));
	};

	const handleChangeDelay = (e: React.ReactText) => {
		const value = Number(e.toString());
		dispatcherDoor(setDoorPhotoCallUpDelayAction(value));
	};

	useEffect(() => {
		if (badgeTemplates !== null) {
			const badgeTemplatesOptions = badgeTemplates.map(x => ({ value: x.CanvasId, label: x.DisplayName }));
			setBadgeTemplatesOptions([...badgeTemplatesOptions]);
		}
	}, []);

	return (
		<div className={styles.containerLegend}>
			<fieldset>
				<legend className={styles.legend}>{_('PhotoCallUpDoor')}</legend>
				<div className={styles.leftPadding}>
					<Row gutter={[8, 8]} className={styles.gridSpaceBetween}>
						<Col span={12}>
							<div className={styles.alignSpan}>
								<span className={styles.contentSpan}>{_('Template')}</span>
								<Select
									id='doorModalTemplateDropdown'
									className={styles.select}
									options={badgeTemplatesOptions}
									onChange={handleSelectPhotoCallUp}
									key='photoCallUpTemplate'
									value={PhotoCallUpTemplateId.toString()}
									getPopupContainer={() => document.getElementById('generalDoorContainer')}
								/>
							</div>
						</Col>
						<Col span={5}>
							<div className={styles.alignSpan}>
								<span className={styles.contentSpan}>{_('Delay')}</span>
								<InputNumber
									id='doorModalDelayInput'
									min={0}
									max={3600}
									value={PhotoCallUpDelay}
									onChange={handleChangeDelay}
									formatter={noneDecimalFormatter}
									step={1}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</fieldset>
		</div>
	);
};

export { PhotoCallUp };

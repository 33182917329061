import cx from 'classnames';
import React from 'react';
import { DoorGroupFGsAndFGExt, DoorGroupUserAndCredential, MasterDoorGroupByDG } from '../../../../../../model/DeviceAdminModel';
import styles from './doorWarning.module.scss';

type Props = {
	messageError: string;
	isMasterGroup: boolean;
	doorGroupUsersAndCredentials: DoorGroupUserAndCredential[];
	doorGroupFGsAndFGExts: DoorGroupFGsAndFGExt[];
	masterDoorGroupsByDG: MasterDoorGroupByDG[];
};

const DoorWarning: React.FC<Props> = ({ doorGroupFGsAndFGExts, doorGroupUsersAndCredentials, masterDoorGroupsByDG, isMasterGroup, messageError }) => {
	return (
		<div className={styles.container}>
			<p>{messageError}</p>
			{doorGroupUsersAndCredentials.length > 0 && (
				<div className={cx(styles.gridBase, styles.userCredentials)}>
					<div>{_('UserID')}</div>
					<div>{_('FirstName')}</div>
					<div>{_('LastName')}</div>
					<div>{_('CredentialID')}</div>
					<div>{_('CredentialDescription')}</div>
					<div>{isMasterGroup ? _('MasterDoorGroupID') : _('DoorGroupID')}</div>
					{doorGroupUsersAndCredentials.map((data: DoorGroupUserAndCredential, index) => (
						<React.Fragment key={index}>
							<div>{data.UserId}</div>
							<div>{data.FirstName}</div>
							<div>{data.LastName}</div>
							<div>{data.CredentialId}</div>
							<div>{data.CredentialDescription}</div>
							<div>{data.ObjectId}</div>
						</React.Fragment>
					))}
				</div>
			)}
			{doorGroupFGsAndFGExts.length > 0 && (
				<div className={cx(styles.gridBase, styles.doorGroup)}>
					<div>{_('FunctionGroupName')}</div>
					<div>{_('Extension')}</div>
					<div>{_('Function')}</div>
					<div>{isMasterGroup ? _('MasterDoorGroupID') : _('DoorGroupID')}</div>
					{doorGroupFGsAndFGExts.map((data: DoorGroupFGsAndFGExt, index) => (
						<React.Fragment key={index}>
							<div>{data.FunctionGroupName}</div>
							<div>{data.FunctionGroupExtension}</div>
							<div>{data.Function}</div>
							<div>{data.ObjectId}</div>
						</React.Fragment>
					))}
				</div>
			)}
			{masterDoorGroupsByDG.length > 0 && (
				<div className={cx(styles.gridBase, styles.masterDoor)}>
					<div>{_('MasterDoorGroupName')}</div>
					{masterDoorGroupsByDG.map((data: MasterDoorGroupByDG, index) => (
						<div key={index}>{data.MasterDoorGroupName}</div>
					))}
				</div>
			)}
		</div>
	);
};

export { DoorWarning };

import { DeleteFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, InputNumber, Spin, Table } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { ColumnsProps, buildColumn, filterOption, noneDecimalFormatter } from '../../../../../Helper';
import { deviceAdminApi, enrollmentApi } from '../../../../../api';
import { User } from '../../../../../model/AccountModel';
import { BaseColumns } from '../../../../../model/CommonModel';
import { ControlZone, DoorGroup, SelectOption } from '../../../../../model/DeviceAdminModel';
import { CredentialFunction, FunctionCategory, FunctionGroup, FunctionModel, PanelZone, ThreatLevel } from '../../../../../model/EnrollmentModel';
import { Logger } from '../../../../../model/LoggingModel';
import { Modal, Select } from '../../../../common';
import { filterDoorGroups } from '../../Helper';
import {
	setControlZonesArrayAction,
	setCredentialFunctionsArrayAction,
	setFunctionTabServicesDataAction,
	setRootModelPropertyAction,
} from '../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../credentialTemplateContext/context';
import styles from '../../credentialmodal.module.scss';

enum CredentialFunctionEnum {
	CONTROL_ZONE,
	DOOR_GROUP,
	FUNCTION_GROUP,
	THREAT_LEVEL,
	INDEXED_COMMAND,
	DEADMAN_TIMER,
	SPECIAL_DOOR_GROUP,
}

const MAX_DEADMAN_SECONDS: number = 65000;
type CredentialFunctionsDataType = CredentialFunction & BaseColumns;
type AddCredentialFunction = {
	credentialFunction: CredentialFunctionEnum;
	controlZoneIndex?: number;
	controllerIndex?: number;
};

const user: User = getUser();
const noOfCustomAccessZones: number = user.noOfCustomAccessZones;

const CredentialTemplateModalFunctionsTab: React.FC = () => {
	const {
		credentialInitialState: {
			configData: { CCMVersionSupportsHRecord, CustomAccessZones, IsDeviceAdminCredentialTemplate, OverlapAccessFlag, IndexedCommandsOptions },
			fetchedCredential,
			functionsTabState: { controllers, controlZones, credentialFunctions, doorGroups, functionCategories, functionGroups },
			generalTabState: {
				IDF,
				informationSectionState: { credentialTemplates, linkToCredential },
			},
			modelEstablished,
			limitsTabState: { threatLevels },
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	const [controller, setController] = useState<number>(-1);
	const [controlZone, setControlZone] = useState<number>(-1);
	const [doorGroup, setDoorGroup] = useState<string>('');
	const [localDoorGroups, setLocalDoorGroups] = useState<DoorGroup[]>([]);
	const [functionCategory, setFunctionCategory] = useState<number>(-1);
	const [functionGroup, setFunctionGroup] = useState<number>(-1);
	const [functionS, setFunctionS] = useState<number>(-1);
	const [showConflictMsgModal, setShowConflictMsgModal] = useState<boolean>(false);
	const [conflictMsgModal, setConflictMsgModal] = useState<JSX.Element>(null);
	const [fetchedControlZones, setFetchedControlZones] = useState<Map<number, ControlZone[]>>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isTabDisabled, setIsTabDisabled] = useState<boolean>(false);
	const [deadmanTime, setDeadmanTime] = useState<number>(0);
	const [indexedCommandId, setIndexedCommandId] = useState<number>(1);
	const [threatLevel, setThreatLevel] = useState<number>(0);

	const addCredentialFunction = ({ credentialFunction, controlZoneIndex = -1, controllerIndex = -1 }: AddCredentialFunction) => {
		const selectedFunctionCategory = functionCategories.find(fc => fc.FunctionCategoryIndex === functionCategory);
		const selectedFunction = selectedFunctionCategory.Functions.find(f => f.FunctionIndex === functionS);
		const newTableData: Partial<CredentialFunction>[] = credentialFunctions ? [...credentialFunctions] : [];
		switch (credentialFunction) {
			case CredentialFunctionEnum.DOOR_GROUP:
			case CredentialFunctionEnum.SPECIAL_DOOR_GROUP:
				if (doorGroup && doorGroups?.length > 0) {
					const [_, strDoorGroupId, strIsMaster] = doorGroup.split('%');
					const doorGroupId = parseInt(strDoorGroupId);
					const boolIsMaster = strIsMaster === 'true';
					const selectedDoorGroup = doorGroups.find(dg => dg.DoorGroupId === doorGroupId && dg.IsMasterDoorGroup === boolIsMaster);
					const newItem = {
						key: buildUniqueIdDoorGroup(credentialFunction, selectedDoorGroup),
						controllerIds: selectedDoorGroup.ControllerIds,
						functionModel: selectedFunction,
						functionCategory: selectedFunctionCategory,
					};

					if (selectedDoorGroup && doorGroupId > -1 && !checkConflicts(newTableData, newItem)) {
						newTableData.push({
							key: newItem.key,
							FunctionIndex: newItem.functionModel.FunctionIndex,
							FunctionName: newItem.functionModel.DefaultFunctionName,
							FunctionCategory: newItem.functionCategory.FunctionCategoryIndex,
							ControllerIds: newItem.controllerIds,
							Name: selectedDoorGroup.Name,
							DisplayName: selectedDoorGroup.Name,
							HostZone: selectedDoorGroup.IsMasterDoorGroup ? -1 : selectedDoorGroup.DoorGroupId,
							MasterDoorGroupId: selectedDoorGroup.IsMasterDoorGroup ? selectedDoorGroup.DoorGroupId : -1,
							Zones: undefined,
							IsMasterDoorGroup: selectedDoorGroup.IsMasterDoorGroup,
						});
						dispatcher(setCredentialFunctionsArrayAction(newTableData));
					}

					const tempDgs = filterDoorGroups(newTableData, doorGroups);
					setLocalDoorGroups(tempDgs);
				}
				setDoorGroup('');
				break;

			case CredentialFunctionEnum.FUNCTION_GROUP:
				if (functionGroup > 0 && functionGroups?.length > 0) {
					const selectedFunctionGroup = functionGroups.find(fg => fg.FunctionGroupId === functionGroup);
					if (selectedFunctionGroup) {
						const { ControllerIds: controllerIds, FunctionGroupId, Name } = selectedFunctionGroup;
						const newItem = {
							key: buildUniqueIdFunctionGroup(credentialFunction, selectedFunctionGroup),
							controllerIds,
							functionModel: selectedFunction,
							functionCategory: selectedFunctionCategory,
						};
						if (!checkConflicts(newTableData, newItem)) {
							newTableData.push({
								key: newItem.key,
								FunctionIndex: newItem.functionModel.FunctionIndex,
								FunctionName: newItem.functionModel.DefaultFunctionName,
								FunctionCategory: newItem.functionCategory.FunctionCategoryIndex,
								ControllerIds: newItem.controllerIds,
								Name,
								DisplayName: Name,
								HostZone: FunctionGroupId,
								MasterDoorGroupId: -1,
								IsMasterDoorGroup: false,
								Zones: undefined,
							});
							dispatcher(setCredentialFunctionsArrayAction(newTableData));
						}
					}
				}
				setFunctionGroup(-1);
				break;

			case CredentialFunctionEnum.INDEXED_COMMAND:
				if (controllers?.length > 0) {
					const indexedCommandOption = IndexedCommandsOptions.find(option => option.Id === indexedCommandId);
					const cController = controllers.find(c => c.ControllerId === controllerIndex);
					if (cController && indexedCommandOption) {
						const { ControllerId } = cController;
						const newItem = {
							key: buildUniqueIdIndexedCommand(credentialFunction, indexedCommandOption),
							controllerIds: [ControllerId],
							functionModel: selectedFunction,
							functionCategory: selectedFunctionCategory,
						};
						if (!checkConflicts(newTableData, newItem)) {
							newTableData.push({
								key: newItem.key,
								FunctionIndex: newItem.functionModel.FunctionIndex,
								FunctionName: newItem.functionModel.DefaultFunctionName,
								FunctionCategory: newItem.functionCategory.FunctionCategoryIndex,
								ControllerIds: newItem.controllerIds,
								Name: indexedCommandOption.Name,
								DisplayName: `${indexedCommandOption.Name} (${cController.ControllerName})`,
								HostZone: -1,
								MasterDoorGroupId: -1,
								IsMasterDoorGroup: false,
								Zones: [
									{
										ControllerId,
										ZoneIndex: indexedCommandId,
										DeadmanTime: undefined,
									},
								],
							});
							dispatcher(setCredentialFunctionsArrayAction(newTableData));
						}
					}
				}
				setController(-1);
				break;

			case CredentialFunctionEnum.THREAT_LEVEL:
				if (controllers?.length > 0 && threatLevels?.length > 0) {
					const threatLevelOption = threatLevels.find(option => option.ThreatId === threatLevel);
					const cController = controllers.find(c => c.ControllerId === controllerIndex);
					if (cController && threatLevelOption) {
						const { ControllerId } = cController;
						const newItem = {
							key: buildUniqueIdThreatLevel(credentialFunction, threatLevelOption),
							controllerIds: [ControllerId],
							functionModel: selectedFunction,
							functionCategory: selectedFunctionCategory,
						};
						if (!checkConflicts(newTableData, newItem)) {
							newTableData.push({
								key: newItem.key,
								FunctionIndex: newItem.functionModel.FunctionIndex,
								FunctionName: newItem.functionModel.DefaultFunctionName,
								FunctionCategory: newItem.functionCategory.FunctionCategoryIndex,
								ControllerIds: newItem.controllerIds,
								Name: threatLevelOption.Name,
								DisplayName: `${threatLevelOption.Name} (${cController.ControllerName})`,
								HostZone: -1,
								MasterDoorGroupId: -1,
								IsMasterDoorGroup: false,
								Zones: [
									{
										ControllerId,
										ZoneIndex: threatLevel,
										DeadmanTime: undefined,
									},
								],
							});
							dispatcher(setCredentialFunctionsArrayAction(newTableData));
						}
					}
				}
				setController(-1);
				break;

			default:
				if (controlZoneIndex > 0 && controllers?.length > 0 && controlZones?.length > 0) {
					const cControlZone = controlZones.find(cz => cz.ControlZoneIndex === controlZoneIndex);
					const cController = controllers.find(c => c.ControllerId === controllerIndex);
					if (cController && cControlZone) {
						const { ControlZoneIndex, ControlZoneName } = cControlZone;
						const { ControllerId } = cController;
						const newItem = {
							key: buildUniqueIdDefault(credentialFunction, ControlZoneIndex, ControllerId),
							controllerIds: [ControllerId],
							functionModel: selectedFunction,
							functionCategory: selectedFunctionCategory,
						};
						if (!checkConflicts(newTableData, newItem)) {
							const deadmanTimeSec: number = credentialFunction === CredentialFunctionEnum.DEADMAN_TIMER ? deadmanTime : undefined;
							newTableData.push({
								key: newItem.key,
								FunctionIndex: newItem.functionModel.FunctionIndex,
								FunctionName: newItem.functionModel.DefaultFunctionName,
								FunctionCategory: newItem.functionCategory.FunctionCategoryIndex,
								ControllerIds: newItem.controllerIds,
								Name: ControlZoneName,
								DisplayName: `${ControlZoneName} (${cController.ControllerName})`,
								HostZone: -1,
								MasterDoorGroupId: -1,
								IsMasterDoorGroup: false,
								Zones: [
									{
										ControllerId,
										ZoneIndex: ControlZoneIndex,
										DeadmanTime: deadmanTimeSec,
									},
								],
							});
							dispatcher(setCredentialFunctionsArrayAction(newTableData));
						}
					}
				}
				setControlZone(-1);
				break;
		}
	};

	useEffect(() => {
		setIsLoading(true);
		Promise.all([
			enrollmentApi.getFunctionCategories(),
			enrollmentApi.getOperatorDoorGroups(),
			enrollmentApi.getOperatorFunctionGroups(),
			enrollmentApi.getXboxControllers(),
		])
			.then(res => {
				const [functionCategories, doorGroups, functionGroups, controllers] = res;
				const defDg: DoorGroup = {
					ControllerIds: [],
					DoorGroupId: -1,
					IsMasterDoorGroup: false,
					Name: '',
				};
				const defFg: FunctionGroup = {
					ControllerIds: [],
					Defined: false,
					FunctionGroupId: -1,
					Name: '',
				};
				const dgs: DoorGroup[] = doorGroups?.length > 0 ? [defDg, ...doorGroups.sort((a, b) => a.Name.localeCompare(b.Name))] : [defDg];
				const fgs: FunctionGroup[] = functionGroups?.length > 0 ? [defFg, ...functionGroups] : [defFg];
				dispatcher(
					setFunctionTabServicesDataAction({
						controllers,
						doorGroups: dgs,
						functionCategories,
						functionGroups: fgs,
					})
				);
				if (functionCategories?.length > 0) setFunctionCategory(functionCategories[0].FunctionCategoryIndex);
				if (credentialFunctions?.length > 0) {
					setLocalDoorGroups(filterDoorGroups(credentialFunctions, dgs));
				} else {
					setLocalDoorGroups(dgs);
				}
				setIsLoading(false);
			})
			.catch(err => {
				Logger.writeErrorLog(err);
				dispatcher(
					setFunctionTabServicesDataAction({
						controllers: [],
						doorGroups: [],
						functionCategories: [],
						functionGroups: [],
					})
				);
			});
	}, []);

	useEffect(() => {
		if (functionCategories?.length > 0) {
			const selectedFunctionCategory = functionCategories[0];
			setFunctionCategory(selectedFunctionCategory.FunctionCategoryIndex);
			setFunctionS(selectedFunctionCategory.Functions[0].FunctionIndex);
		}
	}, [JSON.stringify(functionCategories)]);

	useEffect(() => {
		if (controllers && controllers.length > 0) {
			const controllerId = controllers[0].ControllerId;
			setControllerIdState(controllerId);
		}
	}, [JSON.stringify(controllers)]);

	useEffect(() => {
		if (functionCategories?.length > 0 && doorGroup) {
			if (functionCategory == 4 && (functionS === 25 || functionS === 26 || functionS === 27)) {
				addCredentialFunction({
					credentialFunction: CredentialFunctionEnum.SPECIAL_DOOR_GROUP,
				});
			} else {
				addCredentialFunction({
					credentialFunction: CredentialFunctionEnum.DOOR_GROUP,
				});
			}
		}
	}, [doorGroup]);

	useEffect(() => {
		if (functionCategories?.length > 0 && functionGroup > -1) {
			addCredentialFunction({
				credentialFunction: CredentialFunctionEnum.FUNCTION_GROUP,
			});
		}
	}, [functionGroup]);

	useEffect(() => {
		setIsTabDisabled(false);
		if (IsDeviceAdminCredentialTemplate && IDF === 0) setIsTabDisabled(true);
		else if (linkToCredential) setIsTabDisabled(true);
		else if (IDF === 0) setIsTabDisabled(true);
	}, [IDF]);

	useEffect(() => {
		linkToChkChanged();
	}, [linkToCredential]);

	useEffect(() => {
		if (fetchedCredential && modelEstablished) {
			setLocalDoorGroups(filterDoorGroups(credentialFunctions, doorGroups));
		}
	}, [fetchedCredential, modelEstablished]);

	const linkToChkChanged = () => {
		if (credentialTemplates?.length > 0) {
			if (linkToCredential) {
				setIsTabDisabled(true);
			} else {
				setIsTabDisabled(false);
			}
		}
	};

	const setControllerIdState = (controllerId: number): void => {
		getControlZones(controllerId);
		setController(controllerId);
	};

	const isAccessFunction = (functionIndex: number): boolean => {
		if (
			// Access
			functionIndex === 1 ||
			functionIndex === 2 ||
			functionIndex === 3 ||
			functionIndex === 34 ||
			functionIndex === 37 ||
			functionIndex === 39 ||
			functionIndex === 40 ||
			// Password
			functionIndex === 20 ||
			functionIndex === 21 ||
			functionIndex === 22 ||
			functionIndex === 23 ||
			functionIndex === 24 ||
			// Special
			functionIndex === 25 ||
			functionIndex === 26 ||
			functionIndex === 27
		) {
			return true;
		}

		return false;
	};

	const getErrorMessageForConflictControllers = (
		conflicts: {
			controllerId: number;
			conflictedItems: string[];
			functionModel: FunctionModel;
			functionCategory: FunctionCategory;
		}[]
	): string[] => {
		const errors: string[] = conflicts.map(conflicted => {
			const { controllerId, functionModel, functionCategory, conflictedItems } = conflicted;
			let message = `Function: ${functionModel.DefaultFunctionName} ${functionCategory.DefaultCategoryName}`;
			if (isAccessFunction(functionModel.FunctionIndex) || functionModel.FunctionIndex === 32) {
				message += ` (${conflictedItems.join(', ')})`;
			}
			const controller = controllers.find(x => x.ControllerId === controllerId);

			return `${message}   Controller: ${controller.ControllerName}`;
		});

		return errors;
	};

	const checkConflicts = (
		dataSource: Partial<CredentialFunction>[],
		newItem: {
			key: string;
			functionModel: FunctionModel;
			functionCategory: FunctionCategory;
			controllerIds: number[];
		}
	): boolean => {
		let hasConflicts: boolean = false;
		const conflicts: {
			controllerId: number;
			conflictedItems: string[];
			functionModel: FunctionModel;
			functionCategory: FunctionCategory;
		}[] = [];

		const conflictedItems = dataSource.filter(x => x.ControllerIds.some(y => newItem.controllerIds.includes(y)));
		for (const controllerId of newItem.controllerIds) {
			const currentItemsCount = conflictedItems.flatMap(x => x.ControllerIds).filter(y => y === controllerId).length;
			for (const existing of conflictedItems) {
				if (OverlapAccessFlag) {
					if (currentItemsCount) {
						const { functionModel } = newItem;
						if (
							functionModel.FunctionIndex !== existing.FunctionIndex ||
							(functionModel.FunctionIndex === existing.FunctionIndex && !isAccessFunction(functionModel.FunctionIndex)) ||
							(functionModel.FunctionIndex === existing.FunctionIndex &&
								isAccessFunction(existing.FunctionIndex) &&
								currentItemsCount > noOfCustomAccessZones)
						) {
							hasConflicts = true;
							let item = conflicts.find(x => x.controllerId === controllerId && x.functionModel.FunctionIndex === existing.FunctionIndex);
							if (!item) {
								item = {
									...newItem,
									controllerId,
									conflictedItems: [],
								};
								conflicts.push(item);
							}
							item.conflictedItems.push(existing.Name);
						}
					}
				} else {
					if (currentItemsCount && existing.key !== newItem.key) {
						hasConflicts = true;
						let item = conflicts.find(x => x.controllerId === controllerId && x.functionModel.FunctionIndex === existing.FunctionIndex);
						if (!item) {
							item = {
								...newItem,
								controllerId,
								conflictedItems: [],
							};
							conflicts.push(item);
						}
						item.conflictedItems.push(existing.Name);
					}
				}
			}
		}

		if (hasConflicts) {
			buildConflictMsgModal(getErrorMessageForConflictControllers(conflicts));
		}

		return hasConflicts;
	};

	const getControlZones = async (controllerId: number) => {
		try {
			let controlZones: ControlZone[] = [];
			const currentControlZones = new Map(fetchedControlZones);
			if (currentControlZones.has(controllerId) && currentControlZones.get(controllerId)?.length > 0) {
				controlZones = currentControlZones.get(controllerId);
			} else {
				controlZones = await deviceAdminApi.retrieveControlZones(controllerId);
				currentControlZones.set(controllerId, controlZones || []);
				setFetchedControlZones(currentControlZones);
			}
			dispatcher(setControlZonesArrayAction(controlZones));
		} catch (err) {
			Logger.writeErrorLog(err);
			dispatcher(setControlZonesArrayAction([]));
		}
	};

	const handleOnChangeFunctionCategory = (stringValue: string) => {
		const value: number = Number(stringValue);
		const selectedFunctionCategory = functionCategories.find(fc => fc.FunctionCategoryIndex === value);
		setFunctionCategory(selectedFunctionCategory.FunctionCategoryIndex);
		setFunctionS(selectedFunctionCategory.Functions[0].FunctionIndex);
	};

	const handleOnChangeFunction = (stringValue: string) => {
		const value: number = Number(stringValue);
		if (functionCategory == 4 && (value === 28 || value === 29 || value === 41)) {
			setController(-1);
		}
		setFunctionS(value);
	};

	const removeTableData = (idx: number) => {
		if (credentialFunctions && credentialFunctions.length > 0) {
			let newTableData = [...credentialFunctions];
			newTableData.splice(idx, 1);
			dispatcher(setCredentialFunctionsArrayAction(newTableData));
			const tempDgs = filterDoorGroups(newTableData, doorGroups);
			setLocalDoorGroups(tempDgs);
		}
	};

	const handleOnChangeDoorGroup = (value: string) => {
		setDoorGroup(value);
		dispatcher(setRootModelPropertyAction({ hasChanged: true }));
	};

	const handleOnChangeFunctionGroup = (value: number) => {
		setFunctionGroup(value);
		dispatcher(setRootModelPropertyAction({ hasChanged: true }));
	};

	const columns: ColumnsProps<Partial<CredentialFunction>>[] = [
		{
			...buildColumn(_('Function'), 'FunctionName', '15em', 'start'),
		},
		{
			...buildColumn(_('GroupZoneName'), 'DisplayName', 'auto', 'start'),
		},
		{
			...buildColumn(`${_('Time')} (${_('Sec')})`, 'Zones', '7em', 'start'),
			render: (Zones: PanelZone[], record: CredentialFunctionsDataType) => {
				let content: string | number = '';
				if (record.FunctionCategory == 4 && record.FunctionIndex === 28 && Zones && Zones.length > 0) {
					content = Zones[0].DeadmanTime;
				}

				return <span>{content}</span>;
			},
		},
		{
			...buildColumn('', '', '30px', 'end'),
			render: (_, record: CredentialFunctionsDataType, idx: number) =>
				!isTabDisabled && (
					<DeleteFilled
						onClick={() => {
							removeTableData(idx);
						}}
					/>
				),
		},
	];

	const controlZoneDropdownComponent = (handleOnChange: (value: number) => void) => (
		<>
			<label htmlFor='controlZone'>{_('ControlZone')}:</label>
			{controlZones && (
				<Select
					notUseCurrentParse
					id='controlZone'
					className={styles.select}
					disabled={isTabDisabled}
					getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
					onChange={handleOnChange}
					options={controlZones.map(cz => ({
						key: cz.ControlZoneIndex,
						label: cz.ControlZoneName,
						value: cz.ControlZoneIndex,
					}))}
					style={{ width: 505 }}
					value={controlZone > -1 ? controlZone : ''}
					showSearch={true}
					filterOption={filterOption}
					virtual={true}
				/>
			)}
		</>
	);

	const controllerDropdownComponent = (handleOnChange: (value: number) => void) => (
		<>
			<label htmlFor='controller'>{_('Controller')}:</label>
			<Select
				notUseCurrentParse
				id='controller'
				className={styles.select}
				disabled={isTabDisabled}
				getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
				onChange={handleOnChange}
				options={controllers.map(controller => ({
					key: controller.ControllerId,
					label: controller.ControllerName,
					value: controller.ControllerId,
				}))}
				style={{ width: 505 }}
				value={controller > -1 ? controller : ''}
				showSearch={true}
				filterOption={filterOption}
				virtual={true}
			/>
		</>
	);

	const handleOnChangeControlZone = (credentialFunction: CredentialFunctionEnum, value: number): void => {
		setControlZone(value);
		if (functionCategories?.length > 0 && value > -1) {
			addCredentialFunction({
				credentialFunction,
				controlZoneIndex: value,
				controllerIndex: controller,
			});
		}
		dispatcher(setRootModelPropertyAction({ hasChanged: true }));
	};

	const buildUniqueIdDoorGroup = (credentialFunction: CredentialFunctionEnum, doorGroup: DoorGroup): string => {
		return `${credentialFunction}%${doorGroup.DoorGroupId}%${doorGroup.IsMasterDoorGroup}`;
	};

	const buildUniqueIdFunctionGroup = (credentialFunction: CredentialFunctionEnum, functionGroup: FunctionGroup): string => {
		return `${credentialFunction}%${functionGroup.FunctionGroupId}`;
	};

	const buildUniqueIdIndexedCommand = (credentialFunction: CredentialFunctionEnum, indexedCommand: SelectOption): string => {
		return `${credentialFunction}%${indexedCommand.Id}`;
	};

	const buildUniqueIdThreatLevel = (credentialFunction: CredentialFunctionEnum, threatLevel: ThreatLevel): string => {
		return `${credentialFunction}%${threatLevel.ThreatId}`;
	};

	const buildUniqueIdDefault = (credentialFunction: CredentialFunctionEnum, index: number, controllerId: number): string => {
		return `${credentialFunction}%${index}%${controllerId}`;
	};

	const renderControlZoneLayout = (): JSX.Element => {
		let dd: JSX.Element = null;
		if ((controllers && controllers.length > 0 && controlZones && controlZones.length > 0 && functionCategory == 1) || functionCategory == 2) {
			dd = (
				<>
					<div className={cx(styles.sections, styles.functionSections)}>{controllerDropdownComponent(setControllerIdState)}</div>
					<div className={cx(styles.sections, styles.functionSections)}>
						{controlZoneDropdownComponent((value: number) => handleOnChangeControlZone(CredentialFunctionEnum.CONTROL_ZONE, value))}
					</div>
				</>
			);
		} else if (functionCategory === 5 && functionGroups?.length > 0) {
			dd = (
				<div className={cx(styles.sections, styles.functionSections)}>
					<label htmlFor='FunctionGroup'>{_('FunctionGroup')}:</label>
					<div>
						<Select
							notUseCurrentParse
							id='FunctionGroup'
							className={styles.select}
							disabled={isTabDisabled}
							getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
							onChange={handleOnChangeFunctionGroup}
							options={functionGroups.map(fg => ({
								key: fg.FunctionGroupId,
								label: fg.Name || ' ',
								value: fg.FunctionGroupId,
							}))}
							style={{ width: 505 }}
							value={functionGroup}
							showSearch={true}
							filterOption={filterOption}
							virtual={true}
						/>
					</div>
				</div>
			);
		} else if (functionCategory == 4 && (functionS === 28 || functionS === 29 || functionS === 41)) {
			switch (functionS) {
				case 28: // Deadman timer
					dd = (
						<div>
							<div className={cx(styles.sections, styles.functionSections)}>{controllerDropdownComponent(setControllerIdState)}</div>
							<div className={styles.deadmanTimer}>
								{controlZoneDropdownComponent((value: number) => handleOnChangeControlZone(CredentialFunctionEnum.DEADMAN_TIMER, value))}
								<label htmlFor='deadmanTimerDropdown'>
									{_('Time')} ({_('Sec')}):
								</label>
								<InputNumber
									className={styles.inputNumber}
									disabled={isTabDisabled}
									id='deadmanTimerDropdown'
									max={MAX_DEADMAN_SECONDS}
									min={0}
									onChange={(value: number) => {
										setDeadmanTime(value);
									}}
									value={deadmanTime}
									formatter={noneDecimalFormatter}
								/>
							</div>
						</div>
					);
					break;

				case 29: // Indexed command
					dd = (
						<div className={styles.indexedCommand}>
							<div className={cx(styles.sections, styles.functionSections)}>
								<label htmlFor='indexedCommandDropdown'>{_('IndexedCommand')}:</label>
								<Select
									notUseCurrentParse
									id='indexedCommandDropdown'
									className={styles.select}
									disabled={isTabDisabled}
									getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
									onChange={(value: number) => setIndexedCommandId(value)}
									options={IndexedCommandsOptions.map(option => ({
										key: option.Id,
										label: option.Name,
										value: option.Id,
									}))}
									style={{ width: 505 }}
									value={indexedCommandId}
									showSearch={true}
									filterOption={filterOption}
									virtual={true}
								/>
							</div>
							<div className={cx(styles.sections, styles.functionSections)}>
								{controllerDropdownComponent((value: number) => {
									setControllerIdState(value);
									addCredentialFunction({
										credentialFunction: CredentialFunctionEnum.INDEXED_COMMAND,
										controllerIndex: value,
									});
								})}
							</div>
						</div>
					);
					break;

				case 41: // Threat level
					dd = (
						<div>
							<div className={cx(styles.sections, styles.functionSections)}>
								<label htmlFor='threatLevelDropdown'>{_('SetThreatLevel')}:</label>
								<Select
									notUseCurrentParse
									id='threatLevelDropdown'
									className={styles.select}
									disabled={isTabDisabled}
									getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
									onChange={(value: number) => setThreatLevel(value)}
									options={threatLevels.map((threatLevel: ThreatLevel) => ({
										key: threatLevel.ThreatId,
										label: threatLevel.Name,
										value: threatLevel.ThreatId,
									}))}
									style={{ width: 505 }}
									value={threatLevel}
									showSearch={true}
									filterOption={filterOption}
									virtual={true}
								/>
							</div>
							<div className={cx(styles.sections, styles.functionSections)}>
								{controllerDropdownComponent((value: number) => {
									setControllerIdState(value);
									addCredentialFunction({
										credentialFunction: CredentialFunctionEnum.THREAT_LEVEL,
										controllerIndex: value,
									});
								})}
							</div>
						</div>
					);
					break;
			}
		} else {
			dd = (
				<div className={cx(styles.sections, styles.functionSections)}>
					<label htmlFor='doorGroup'>{_('DoorGroup')}:</label>
					<div>
						<Select
							className={styles.select}
							id='doorGroup'
							disabled={isTabDisabled}
							getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
							onChange={handleOnChangeDoorGroup}
							options={localDoorGroups.map((dg: DoorGroup) => {
								const key = buildUniqueIdDoorGroup(CredentialFunctionEnum.DOOR_GROUP, dg);
								return {
									key,
									label: dg.Name || ' ',
									title: dg.Name,
									value: key,
								};
							})}
							style={{ width: 505 }}
							value={doorGroup}
							showSearch={true}
							filterOption={filterOption}
							virtual={true}
						/>
					</div>
				</div>
			);
		}
		return <div id='ddContainer'>{dd}</div>;
	};

	const buildConflictMsgModal = (conflicts: string[]): void => {
		const errorMessages = conflicts.map(x => {
			return (
				<>
					<br />
					{x}
				</>
			);
		});

		const conflictMsgModal = (
			<div className={styles.container}>
				<div className={styles.functionsConflictsModal}>
					<InfoCircleOutlined style={{ fontSize: '300%' }} />
					{OverlapAccessFlag ? (
						<p>
							{_('CredentialFunctionConflictMessage1').replace('%1', CCMVersionSupportsHRecord).replace('%2', CustomAccessZones.toString())}
							<br />
							<br />
							{_('CredentialFunctionConflictMessage2')}
							<br />
							{errorMessages}
						</p>
					) : (
						<p>
							{_('CredentialFunctionConflictMessage4').replace('%1', CCMVersionSupportsHRecord)}
							<br />
							<br />
							{_('CredentialFunctionConflictMessage5').replace('%1', CustomAccessZones.toString()).replace('%2', CCMVersionSupportsHRecord)}
							<br />
							<br />
							{_('CredentialFunctionConflictMessage2')}
							<br />
							{errorMessages}
						</p>
					)}
				</div>
			</div>
		);
		setConflictMsgModal(conflictMsgModal);
		setShowConflictMsgModal(true);
	};

	const footer = (
		<Button
			htmlType='button'
			onClick={() => {
				setShowConflictMsgModal(false);
			}}
			type='default'>
			{_('Ok')}
		</Button>
	);

	const getFunctionOptions = (): FunctionModel[] => {
		let options: FunctionModel[] = [];
		if (functionCategories?.length > 0 && functionCategory > -1) {
			const lFunctionCategory = functionCategories.find(fc => fc.FunctionCategoryIndex === functionCategory);
			if (lFunctionCategory) options = lFunctionCategory.Functions;
		}
		return options;
	};

	return (
		<Spin spinning={isLoading} tip={`${_('Loading')}...`}>
			<div className={styles.container}>
				<Modal customZoomClass={styles.modalWithAutoZoom} closable={false} footer={[footer]} visible={showConflictMsgModal}>
					{conflictMsgModal}
				</Modal>
				<div className={styles.form} id='functionDDContainer'>
					<div style={{ display: 'grid', gridTemplateColumns: '475px 475px' }}>
						<div>
							<div className={cx(styles.sections, styles.functionSections)}>
								<label htmlFor='functionCategory'>{_('FunctionCategory')}:</label>
								<Select
									className={styles.select}
									disabled={isTabDisabled}
									id='functionCategory'
									onChange={handleOnChangeFunctionCategory}
									options={functionCategories?.map(fc => ({
										key: fc.FunctionCategoryIndex?.toString(),
										label: fc.DefaultCategoryName,
										value: fc.FunctionCategoryIndex?.toString(),
									}))}
									style={{ width: 240 }}
									value={functionCategory?.toString()}
								/>
							</div>
							<div style={{ display: 'grid', gridTemplateColumns: '370px auto' }}>
								<div className={cx(styles.sections, styles.functionSections)}>
									<label htmlFor='functionS'>{_('Function')}:</label>
									<Select
										className={styles.select}
										disabled={isTabDisabled}
										id='functionS'
										onChange={handleOnChangeFunction}
										options={getFunctionOptions().map(f => ({
											key: f.FunctionIndex?.toString(),
											label: f.DefaultFunctionName,
											value: f.FunctionIndex?.toString(),
										}))}
										style={{ width: 240 }}
										value={functionS?.toString()}
									/>
								</div>
							</div>
						</div>
						<div>
							<div style={{ marginTop: 7, minHeight: 33 }} />
							<div style={{ margin: '7px 0 0 -70px' }}>
								<label>{_('SelectFunctionMessage')}</label>
							</div>
						</div>
					</div>
					{renderControlZoneLayout()}
					<Table
						className={styles.functionsTable}
						columns={columns}
						dataSource={credentialFunctions?.map((cf, idx: number) => ({
							...cf,
							key: idx,
						}))}
						id={'credentialFunctionsTable'}
						pagination={false}
						scroll={{ y: '370px' }}
						size='small'
						style={{ height: 410, marginTop: 20, width: 920 }}
					/>
				</div>
			</div>
		</Spin>
	);
};

export { CredentialTemplateModalFunctionsTab };

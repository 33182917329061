import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { Select } from '../../../../../../common';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { StoreContext, setAssuranceLevel, setRS485ReaderType } from '../../../contextReader';
import styles from '../../General/general.module.scss';

const readerTypeSelectId: string = 'readerModalReaderTypeSelect';

type Props = {
	useDoorContext: boolean;
};

const ReaderType: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					rS485ReaderTypes,
					isDoorWireless,
					selections: { rS485ReaderType },
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				rS485ReaderTypes,
				isDoorWireless,
				selections: { rS485ReaderType },
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [rs485Types, setRs485Types] = useState<SelectOptions<string>[]>([]);
	const [readerType, setReaderType] = useState(undefined);

	useEffect(() => {
		setRs485Types(rS485ReaderTypes.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [rS485ReaderTypes]);

	useEffect(() => {
		if (!isDoorWireless) {
			setReaderType(rS485ReaderType.toString());
		} else if (rS485ReaderTypes.length > 0) {
			setReaderType(rS485ReaderTypes[0].Id.toString());
		} else {
			setReaderType(undefined);
		}
	}, [rS485ReaderType]);

	const handleOnSetReaderType = (value: string) => {
		if (!isDoorWireless) {
			useDoorContext ? dispatcherDoor(setAssuranceLevel(0)) : dispatcher(setAssuranceLevel(0));
		}
		useDoorContext ? dispatcherDoor(setRS485ReaderType(Number(value))) : dispatcher(setRS485ReaderType(Number(value)));
	};

	return (
		<div className={styles.readerType}>
			<label htmlFor={readerTypeSelectId}>{_('ReaderType')}</label>
			<div>
				<Select
					className={styles.select}
					options={rs485Types}
					onChange={handleOnSetReaderType}
					id={readerTypeSelectId}
					key='readerType'
					value={readerType}
					getPopupContainer={() => document.getElementById('generalReaderContainer')}
				/>
			</div>
		</div>
	);
};

export { ReaderType };

import React from 'react';
import { ConfigurationState, ProfileSetting, SettingsValue } from '../../../model/ConfigurationModel';
import { CheckboxHelper } from '../Common/CheckboxHelper';
import { InputNumberHelper } from '../Common/InputNumberHelper';
import styles from './statusDashboardSettings.module.scss';

// Normal properties for the component
interface Props {
	configuration: ConfigurationState;
	onSetCheckBoxesEnabled: (value: SettingsValue<boolean>) => void;
	onSetViewSettingValue: (value: SettingsValue<string>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
}

//Avoid creating object style inline, since increases reconciliations
const minSecondsValue: number = 5;
const maxSecondsValue: number = 60;
const parentElement: string = 'settingsStatusDashboardShowWidgets';
const accessSummaryContainerId = `${parentElement}AccessSummary`;
const peopleCredentialsContainerId = `${parentElement}PeopleCredentials`;
const queuedDownloadsContainerId = `${parentElement}QueuedDownloads`;
const liveEventsContainerId = `${parentElement}LiveEvents`;
const eventsSummaryContainerId = `${parentElement}EventsSummary`;
const performanceStatusContainerId = `${parentElement}PerformanceStatus`;
const onlineActiveUsersContainerId = `${parentElement}OnlineActiveUsers`;

const StatusDashboardSettings: React.FC<Props> = ({ configuration, onSetCheckBoxesEnabled, onSetViewSettingValue, onSetSettingsHaveChanged }) => {
	let showAccessSummaryOnStatusDashboard: ProfileSetting;
	let showCredentialsOnStatusDashboard: ProfileSetting;
	let autoRefreshCredentialsOnStatusDashboard: ProfileSetting;
	let autoRefreshTimerCredentialsOnStatusDashboard: ProfileSetting;
	let showQueuedDownloadsOnStatusDashboard: ProfileSetting;
	let showLiveEventsOnStatusDashboard: ProfileSetting;
	let showEventsSummaryOnStatusDashboard: ProfileSetting;

	if (configuration.AccessSummaryWidgetVisible) {
		showAccessSummaryOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'ShowAccessSummaryOnStatusDashboard');
	}

	if (configuration.PeopleCredentialsWidgetVisible) {
		showCredentialsOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'ShowCredentialsOnStatusDashboard');
		autoRefreshCredentialsOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'AutoRefreshCredentialsOnStatusDashboard');
		autoRefreshTimerCredentialsOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'AutoRefreshTimerCredentialsOnStatusDashboard');
	}

	if (configuration.QueuedDownloadsWidgetVisible) {
		showQueuedDownloadsOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'ShowQueuedDownloadsOnStatusDashboard');
	}

	if (configuration.EventsWidgetVisible) {
		showLiveEventsOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'ShowLiveEventsOnStatusDashboard');
		showEventsSummaryOnStatusDashboard = configuration.ViewSettings.find(r => r.Name === 'ShowEventsSummaryOnStatusDashboard');
	}

	const performanceStatusOnStatusDashboard: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'PerformanceStatusOnStatusDashboard');
	const autoRefreshPerformanceStatusOnStatusDashboard: ProfileSetting = configuration.ViewSettings.find(
		r => r.Name === 'AutoRefreshPerformanceStatusOnStatusDashboard'
	);
	const autoRefreshTimerPerformanceStatusOnStatusDashboard: ProfileSetting = configuration.ViewSettings.find(
		r => r.Name === 'AutoRefreshTimerPerformanceStatusOnStatusDashboard'
	);

	const onlineActiveUsersDashboard: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'OnlineActiveUsersDashboard');
	const autoRefreshOnlineActiveUsersDashboard: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'AutoRefreshOnlineActiveUsersDashboard');
	const autoRefreshTimerOnlineActiveUsersDashboard: ProfileSetting = configuration.ViewSettings.find(
		r => r.Name === 'AutoRefreshTimerOnlineActiveUsersDashboard'
	);

	const setSettingsHaveChanged = () => {
		if (!configuration?.HaveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	const handleEnableCheckBox = (value: boolean, key: string) => {
		const setValues: SettingsValue<boolean> = {
			key,
			value,
		};
		onSetCheckBoxesEnabled(setValues);
		setSettingsHaveChanged();
	};

	const handleSettingChange = (value: React.Key, profileSetting: ProfileSetting) => {
		if (!value || value === undefined || (value as number) < minSecondsValue) {
			value = minSecondsValue;
		}

		const setValues: SettingsValue<string> = {
			key: profileSetting.Name,
			value: value.toString(),
		};
		onSetViewSettingValue(setValues);
		setSettingsHaveChanged();
	};

	return (
		<div className={styles.container} id='ConfigTabData_4'>
			<div id='ShowWidgets'>{_('ShowWidgets')}</div>
			<div>
				{showAccessSummaryOnStatusDashboard && (
					<div>
						<CheckboxHelper
							profileSetting={showAccessSummaryOnStatusDashboard}
							onChange={value => handleEnableCheckBox(value, showAccessSummaryOnStatusDashboard.Name)}
							id={`${accessSummaryContainerId}Checkbox`}
						/>
					</div>
				)}
				{showCredentialsOnStatusDashboard && (
					<>
						<div>
							<CheckboxHelper
								profileSetting={showCredentialsOnStatusDashboard}
								onChange={value => handleEnableCheckBox(value, showCredentialsOnStatusDashboard.Name)}
								id={`${peopleCredentialsContainerId}Checkbox`}
							/>
						</div>
						<div className={styles.addMarginLeft}>
							<CheckboxHelper
								profileSetting={autoRefreshCredentialsOnStatusDashboard}
								onChange={value => handleEnableCheckBox(value, autoRefreshCredentialsOnStatusDashboard.Name)}
								disabled={!showCredentialsOnStatusDashboard.SelectedValue}
								id={`${peopleCredentialsContainerId}AutoRefreshCheckbox`}
							/>
							<label htmlFor={`${peopleCredentialsContainerId}AutoRefreshTimerTextbox`}>
								{autoRefreshTimerCredentialsOnStatusDashboard.Label}
							</label>
							<InputNumberHelper
								profileSetting={{ ...autoRefreshTimerCredentialsOnStatusDashboard, Label: '' }}
								min={minSecondsValue}
								max={maxSecondsValue}
								maxLength={2}
								onChange={value => {
									handleSettingChange(value, autoRefreshTimerCredentialsOnStatusDashboard);
								}}
								disabled={!showCredentialsOnStatusDashboard.SelectedValue || !autoRefreshCredentialsOnStatusDashboard.SelectedValue}
								id={`${peopleCredentialsContainerId}AutoRefreshTimerTextbox`}
							/>
						</div>
					</>
				)}
				{showQueuedDownloadsOnStatusDashboard && (
					<>
						<div>
							<CheckboxHelper
								profileSetting={showQueuedDownloadsOnStatusDashboard}
								onChange={value => handleEnableCheckBox(value, showQueuedDownloadsOnStatusDashboard.Name)}
								id={`${queuedDownloadsContainerId}Checkbox`}
							/>
						</div>
					</>
				)}
				{showLiveEventsOnStatusDashboard && (
					<div>
						<CheckboxHelper
							profileSetting={showLiveEventsOnStatusDashboard}
							onChange={value => handleEnableCheckBox(value, showLiveEventsOnStatusDashboard.Name)}
							id={`${liveEventsContainerId}Checkbox`}
						/>
					</div>
				)}
				{showEventsSummaryOnStatusDashboard && (
					<div>
						<CheckboxHelper
							profileSetting={showEventsSummaryOnStatusDashboard}
							onChange={value => handleEnableCheckBox(value, showEventsSummaryOnStatusDashboard.Name)}
							id={`${eventsSummaryContainerId}Checkbox`}
						/>
					</div>
				)}
				{performanceStatusOnStatusDashboard && (
					<div>
						<CheckboxHelper
							profileSetting={performanceStatusOnStatusDashboard}
							onChange={value => handleEnableCheckBox(value, performanceStatusOnStatusDashboard.Name)}
							id={`${performanceStatusContainerId}Checkbox`}
						/>
					</div>
				)}
				{onlineActiveUsersDashboard && (
					<>
						<div>
							<CheckboxHelper
								profileSetting={onlineActiveUsersDashboard}
								onChange={value => handleEnableCheckBox(value, onlineActiveUsersDashboard.Name)}
								id={`${onlineActiveUsersContainerId}Checkbox`}
							/>
						</div>
						<div className={styles.addMarginLeft}>
							<CheckboxHelper
								profileSetting={autoRefreshOnlineActiveUsersDashboard}
								onChange={value => handleEnableCheckBox(value, autoRefreshOnlineActiveUsersDashboard.Name)}
								disabled={!onlineActiveUsersDashboard.SelectedValue}
								id={`${onlineActiveUsersContainerId}AutoRefreshCheckbox`}
							/>
							<label htmlFor={`${onlineActiveUsersContainerId}AutoRefreshTimerTextbox`}>{autoRefreshTimerOnlineActiveUsersDashboard.Label}</label>
							<InputNumberHelper
								profileSetting={{ ...autoRefreshTimerOnlineActiveUsersDashboard, Label: '' }}
								min={minSecondsValue}
								max={maxSecondsValue}
								maxLength={2}
								onChange={value => {
									handleSettingChange(value, autoRefreshTimerOnlineActiveUsersDashboard);
								}}
								disabled={!onlineActiveUsersDashboard.SelectedValue || !autoRefreshOnlineActiveUsersDashboard.SelectedValue}
								id={`${onlineActiveUsersContainerId}AutoRefreshTimerTextbox`}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export { StatusDashboardSettings };

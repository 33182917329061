import { Dropdown, Menu } from 'antd';
import cx from 'classnames';
import React from 'react';
import { ColumnsProps } from '../../../Helper';
import { SelectOptions } from '../../../model/CommonModel';
import { MoveCredential, WhosInsideCredentialDataType, ZoneGroupTree } from '../../../model/WhosInsideModel';
import { WhosInsideMoveTo } from '../WhosInsideMoveTo/WhosInsideMoveTo';
import styles from './whosInsideHelperElements.module.scss';

const { SubMenu, Item } = Menu;
type WhosInsideCellProps<DataType> = {
	isTableInEditMode: boolean;
	record: DataType;
	children?: React.ReactNode;
	options?: SelectOptions<DataType>[];
	onClickOption?: (value: DataType, moveCredentialInfo?: Partial<MoveCredential>) => void;
	onChangeSelection?: (value: DataType) => void;
	selectedCredentials?: number;
	storedZoneGroupTree?: ZoneGroupTree;
} & React.HTMLAttributes<HTMLElement>;

const WhosInsideCell = ({
	isTableInEditMode,
	record,
	children,
	options,
	onClickOption,
	onChangeSelection,
	selectedCredentials,
	storedZoneGroupTree,
	...restProps
}: WhosInsideCellProps<ColumnsProps<WhosInsideCredentialDataType>>) => {
	let content: JSX.Element;
	if (!isTableInEditMode && options?.length > 0) {
		const menu = (
			<Menu getPopupContainer={(element: HTMLElement) => element.closest('.ant-table-body')}>
				{options.map(({ label, disabled, title, id, separator, value }, index) => {
					if (label === _('MoveTo')) {
						const titleElement = <span title={title}>{label}</span>;
						return (
							<SubMenu key={`${label}-${selectedCredentials}-${index}`} disabled={disabled} title={titleElement}>
								<Item key={`${label}-${index}`} disabled={true} className={styles.moveToMenuItem}>
									<WhosInsideMoveTo actionValue={value} onClickOption={onClickOption} storedZoneGroupTree={storedZoneGroupTree} />
								</Item>
							</SubMenu>
						);
					} else {
						return (
							<Item
								id={id}
								key={`${label}-${index}`}
								onClick={() => onClickOption?.(value)}
								className={cx({ [styles.separator]: separator })}
								disabled={disabled}
								title={title}>
								{label}
							</Item>
						);
					}
				})}
			</Menu>
		);

		content = (
			<Dropdown
				getPopupContainer={(element: HTMLElement) => element.closest('.ant-table-body')}
				overlay={menu}
				trigger={['contextMenu']}
				onOpenChange={visible => {
					if (visible) {
						onChangeSelection?.(record);
					}
				}}
				destroyPopupOnHide
				overlayStyle={{ width: 'max-content' }}>
				<td {...restProps}>{children}</td>
			</Dropdown>
		);
	} else {
		content = <td {...restProps}>{children}</td>;
	}

	return content;
};

export { WhosInsideCell };

import { Input } from 'antd';
import cx from 'classnames';
import React from 'react';
import InputMask from 'react-input-mask';
import styles from './ipv6AddressInput.module.scss';

type Props = {
	id?: string;
	key?: string;
	disabled?: boolean;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	addressValidation: boolean;
	defaultValue?: string;
};

const checkIPv6 = value => {
	const subIps = value.split(':');

	if (subIps.length > 8) {
		return false;
	}

	const invalidSubIps = subIps.filter(ip => {
		return ip.split('').length > 4;
	});

	if (invalidSubIps.length !== 0) {
		return false;
	}

	let emptyIpCount = 0;

	subIps.forEach(ip => {
		if (ip === '') {
			emptyIpCount++;
		}
	});

	if (emptyIpCount > 1) {
		return false;
	}
	return true;
};

const formatChars = {
	'9': '[0-9aAbBcCdDeEfF:]',
};

const IPv6AddressInput: React.FC<Props> = ({ addressValidation, ...props }) => {
	const beforeMaskValueChange = (newState, oldState) => {
		let value = newState.value;
		const oldValue = oldState.value;
		let selection = newState.selection;
		let cursorPosition = selection ? selection.start : null;
		const result = checkIPv6(value);

		if (!result) {
			value = value.trim();
			const newValue = value.substring(0, value.length - 1) + ':' + value.substring(value.length - 1);

			if (checkIPv6(newValue)) {
				cursorPosition++;
				selection = { start: cursorPosition, end: cursorPosition };
				value = newValue;
			} else {
				value = oldValue;
			}
		}

		return {
			value,
			selection,
		};
	};

	return (
		<InputMask
			formatChars={formatChars}
			mask='999999999999999999999999999999999999999'
			maskChar={null}
			alwaysShowMask={false}
			beforeMaskedValueChange={beforeMaskValueChange}
			{...props}>
			{inputProps => (
				<Input
					{...inputProps}
					id={props.id}
					key={props.key}
					disabled={props.disabled}
					maxLength={39}
					defaultValue={props.defaultValue}
					className={cx(styles.input, {
						[styles.error]: addressValidation,
					})}
				/>
			)}
		</InputMask>
	);
};

export { IPv6AddressInput };

import { Spin, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { ScrollType, buildColumn, getDefaultTablePaginationConfig } from '../../../Helper';
import { statusApi } from '../../../api';
import constants from '../../../constants';
import { Logger } from '../../../model/LoggingModel';
import { PortStatusViewer, WidgetInfo } from '../../../model/StatusModel';
import './portStatus.scss';

// Internal state for the component
interface State {
	isLoading: boolean;
	data: PortStatusViewer[];
}

// Normal properties for the component
interface OwnProps {
	isAll?: boolean;
	isExpanded: boolean;
	widgetInfo: WidgetInfo;
}

// combine them together
type Props = OwnProps;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: 1500, y: 312 };
const pagination: TablePaginationConfig = getDefaultTablePaginationConfig();
const columns: ColumnsType<any> = [
	{
		...buildColumn(_('Name'), 'PortName', '25%', 'left'),
		sorter: true,
	},
	{
		...buildColumn(_('Address'), 'Address', '20%', 'left'),
		sorter: true,
	},
	{
		...buildColumn(_('Status'), 'Status', '15%', 'left'),
		sorter: true,
	},
	{
		...buildColumn(_('Enabled'), 'Enabled', '15%', 'left'),
		sorter: true,
	},
];

class PortStatus extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isLoading: true,
			data: null,
		};

		//We use this flag to avoid multiple api calls at the same time
		window.sessionStorage.setItem(constants.sessionStorage.statusViewer.RETRIEVE_PORT_STATUS_FLAG, '0');
	}

	componentDidMount() {
		this.fetchData();
	}

	componentWillUnmount() {
		this.clearFetchTimeout();
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	clearFetchTimeout() {
		if (this.autoRefreshPerformanceLoop) {
			clearTimeout(this.autoRefreshPerformanceLoop);
		}
	}

	private autoRefreshPerformanceLoop: null | ReturnType<typeof setTimeout> = null;

	shouldComponentUpdate(nextProps, nextState) {
		const { isExpanded } = this.props;

		if (nextProps.isExpanded) {
			this.clearFetchTimeout();

			if (!isExpanded) {
				this.fetchData();
			} else {
				const { widgetInfo } = this.props;

				if (widgetInfo.autoRefresh) {
					this.autoRefreshPerformanceLoop = setTimeout(() => {
						this.fetchData();
					}, widgetInfo.autoRefreshTimer * 1000);
				}
			}
		}

		return JSON.stringify(this.state.data) !== JSON.stringify(nextState.data);
	}

	fetchData() {
		const sessionStorageKey = constants.sessionStorage.statusViewer.RETRIEVE_PORT_STATUS_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');
			this.setState({
				...this.state,
				isLoading: true,
			});
			statusApi
				.retrievePortStatusViewer()
				.then(response => {
					window.sessionStorage.setItem(sessionStorageKey, '0');

					this.setState({
						isLoading: false,
						data: response,
					});
				})
				.catch(e => Logger.writeErrorLog(e));
		}
	}

	render() {
		const { isLoading, data } = this.state;
		const { isAll } = this.props;

		let content;
		if (!isLoading) {
			let tableData;
			if (data && data.length) {
				tableData = data.map((port, index) => {
					return {
						key: index,
						PortName: port.PortName,
						Address: port.Address,
						Status: port.Status,
						Enabled: port.Enabled == 1 ? _('Yes') : _('No'),
					};
				});
			}

			content = (
				<Table
					id='statusDashboardStatusViewerPortsTable'
					columns={columns}
					dataSource={tableData}
					scroll={scroll}
					size='small'
					pagination={pagination}
					className={'status-widget-table-nested'}
					loading={isLoading}
				/>
			);
		}

		return (
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className='spin-container'>
				<div className='port-status-container'>
					{isAll && (
						<div className='header'>
							<h3>{_('Ports')}</h3>
						</div>
					)}
					{content}
				</div>
			</Spin>
		);
	}
}

export { PortStatus };

import { CaretDownOutlined } from '@ant-design/icons';
import React from 'React';
import { AlarmAcknowledgementActions, AlarmActionOptions } from '../../../model/AlarmModel';
import { ButtonDropdown } from '../../common';
import { getActionDropdownOptions } from '../helpers';

type Props = {
	hasSelectedAlarms: boolean;
	isAlarmTableEmpty: boolean;
	isAcknowledgedAlarmTable: boolean;
	handleActionDropdown: (option: AlarmAcknowledgementActions | AlarmActionOptions) => Promise<void>;
};

const component: React.FC<Props> = ({ hasSelectedAlarms, isAlarmTableEmpty, isAcknowledgedAlarmTable, handleActionDropdown }: Props) => {
	const prefix: string = isAcknowledgedAlarmTable ? 'Ack' : 'Alarm';
	const actionDropdown = getActionDropdownOptions(hasSelectedAlarms, isAlarmTableEmpty, isAcknowledgedAlarmTable);

	return (
		<ButtonDropdown
			id={`${prefix}ActionDropdown`}
			icon={<CaretDownOutlined />}
			menuOptions={actionDropdown.options}
			labelIcon={actionDropdown.labelOrIcon}
			onClickOption={handleActionDropdown}
		/>
	);
};

const AlarmActionDropdown = React.memo(component);

export { AlarmActionDropdown };

import { SettingOutlined } from '@ant-design/icons';
import { Button, notification, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { configurationApi } from '../../api/';
import { HeaderBar, ModalConfirmCustomFooter } from '../../components/common';
import {
	AlarmSettings,
	EnrollmentSettings,
	EventSettings,
	GeneralSettings,
	PhotoCallUpSettings,
	SecuritySettings,
	StatusDashboardSettings,
} from '../../components/configuration/';
import { handleResponse } from '../../Helper';
import { User } from '../../model/AccountModel';
import { ResponseObjectEntity } from '../../model/CommonModel';
import { ApplicationSetting, ConfigurationAction, ConfigurationState, ProfileSetting, SettingsValue } from '../../model/ConfigurationModel';
import { Logger } from '../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../store';
import {
	setGeneralSettingsDateFormat,
	setGeneralSettingsSetCheckboxes,
	setGeneralSettingsSetInitial,
	setSettingsHaveChanged,
	setSettingsSetValue,
	setViewSettingsSetValue,
} from '../../store/configuration/actions';
import { selectConfiguration } from '../../store/configuration/selectors';
import styles from './configuration.module.scss';

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const { isAdmin } = user;
const castNumber = (value: number): boolean => (value === 1 ? true : false);
const { TabPane } = Tabs;

const ConfigurationPage = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedActiveKey, setSelectedActiveKey] = useState<string>('General');
	const [redirectTo, setRedirectTo] = useState<string>('');
	const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState<boolean>(false);

	const dispatch = useStoreDispatch();
	const configuration: ConfigurationState = useStoreSelector<ConfigurationState>(selectConfiguration);

	useEffect(() => {
		configurationApi
			.retrieveConfiguration()
			.then(response => {
				batch(() => {
					dispatch(setGeneralSettingsSetInitial(response));
					setIsLoading(false);
				});
			})
			.catch(e => Logger.writeErrorLog(e));

		document.body.addEventListener('click', handleLinkOnClick);
		return () => {
			document.body.removeEventListener('click', handleLinkOnClick);
		};
	}, []);

	const handleLinkOnClick = event => {
		const helpUrl = `${window.location.href}#`;
		const target: HTMLAnchorElement = event.target;
		const url: string = target.href !== 'javascript:void(0)' && target.href !== helpUrl ? target.href : '';

		if (target.tagName.toLocaleLowerCase() === 'a' && configuration?.HaveSettingsChanged && url !== '') {
			event.preventDefault();
			setRedirectTo(url);
			setIsSaveChangesModalOpen(true);
		}
	};

	const handleSaveChangesSettings = async () => {
		handleOnSetSettingsHaveChanged(false);

		const currentRegion: ProfileSetting = {
			Name: 'CurrentRegion',
			ObjectId: configuration.WebPersistedObjectId,
			Value: configuration.SelectedSupportedLanguage,
		};

		let selectedLandingPageValue: string = undefined;
		let selectedLandingPage = configuration.LandingPageOptions.find(x => x.Item1.toString() === configuration.SelectedLandingPage);
		if (!selectedLandingPage && configuration.LandingPageOptions.length) {
			selectedLandingPage = configuration.LandingPageOptions[0];
		}

		if (selectedLandingPage) {
			selectedLandingPageValue = selectedLandingPage.Item1.toString();
		}

		const currentLandingPage: ProfileSetting = {
			Name: 'LandingPage',
			ObjectId: configuration.WebPersistedObjectId,
			Value: selectedLandingPageValue,
		};

		const settings: ProfileSetting[] = [...configuration.ViewSettings, currentLandingPage, currentRegion];
		const appSettings: ApplicationSetting[] = [
			configuration.DisplayPhotoInEnrollment,
			configuration.EnrollmentQuerySize,
			configuration.LockoutPeriodAttackTime,
			configuration.LockoutAmountTime,
			configuration.LockoutPerIpAddress,
			configuration.EnableTwoFactorAuthentication,
			configuration.LockoutMaxAttempts,
			configuration.AlarmStackingEnabled,
			configuration.ReportReturnToNormalAlarms,
			configuration.AutoAcknowledgeOfReturnToNormal,
			configuration.AutoCleanReturnToNormal,
			configuration.AlwaysShowInstructions,
			configuration.BadgePrintingMethod,
			configuration.PhotoCallUpBasicView,
		];

		batch(() => {
			setIsLoading(true);
			setIsSaveChangesModalOpen(false);
			setRedirectTo('');
		});

		const res: ResponseObjectEntity<ConfigurationAction> = await configurationApi.saveSettingsChanges(settings, appSettings);
		if (!handleResponse(res)) {
			const response: ConfigurationAction = res.Entity;
			if ((response & ConfigurationAction.ReloadPage) == ConfigurationAction.ReloadPage) {
				window.location.reload();
			} else {
				if ((response & ConfigurationAction.ReloadShowAlarmBanner) == ConfigurationAction.ReloadShowAlarmBanner) {
					const showAlarmBanner: ProfileSetting = settings.find(r => r.Name === 'ShowAlarmBanner');
					if (showAlarmBanner !== undefined) {
						const input = document.getElementById('ShowAlarmBannerHidden') as HTMLInputElement;
						input.value = showAlarmBanner.Value.toString();
					}
				} else if ((response & ConfigurationAction.ReloadAlarmSound) == ConfigurationAction.ReloadAlarmSound) {
					//TODO: FIX THIS WE NEED TO REFRESH THE SOUND CONFIGURATION
					//this.props.setAlarmSoundConfiguration();
				}

				setIsLoading(false);
				notification['success']({ message: _('SettingsSaved') });
			}
		}
	};

	const handleOnSetDateFormat = (cultureCode: string) => {
		dispatch(setGeneralSettingsDateFormat(cultureCode));
	};

	const handleOnSetSettingValue = (value: SettingsValue<string>) => {
		dispatch(setSettingsSetValue(value));
	};

	const handleOnSetSettingsHaveChanged = (value: boolean) => {
		dispatch(setSettingsHaveChanged(value));
	};

	const handleOnSetCheckBoxesEnabled = (value: SettingsValue<boolean>) => {
		dispatch(setGeneralSettingsSetCheckboxes(value));
	};

	const handleOnSetViewSettingValue = (value: SettingsValue<string>) => {
		dispatch(setViewSettingsSetValue(value));
	};

	let tabs: JSX.Element[] = [];
	if (configuration) {
		tabs.push(
			<TabPane tab={_('General')} key='General'>
				<GeneralSettings
					configuration={configuration}
					isAdmin={isAdmin}
					onSetDateFormat={handleOnSetDateFormat}
					onSetSettingValue={handleOnSetSettingValue}
					onSetViewSettingValue={handleOnSetViewSettingValue}
					onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
				/>
			</TabPane>
		);

		if (isAdmin && configuration.EnrollmentTabVisible) {
			tabs.push(
				<TabPane tab={_('Enrollment')} key='Enrollment'>
					<EnrollmentSettings
						castNumber={castNumber}
						configuration={configuration}
						onSetSettingValue={handleOnSetSettingValue}
						onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
					/>
				</TabPane>
			);
		}

		if (isAdmin || configuration.AlarmTabVisible) {
			tabs.push(
				<TabPane tab={_('AlarmViewer')} key='Alarm'>
					<AlarmSettings
						castNumber={castNumber}
						configuration={configuration}
						onSetSettingValue={handleOnSetSettingValue}
						onSetViewSettingValue={handleOnSetViewSettingValue}
						onSetCheckBoxesEnabled={handleOnSetCheckBoxesEnabled}
						onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
					/>
				</TabPane>
			);
		}

		if (configuration.EventTabVisible) {
			tabs.push(
				<TabPane tab={_('Events')} key='Event'>
					<EventSettings
						configuration={configuration}
						onSetViewSettingValue={handleOnSetViewSettingValue}
						onSetCheckBoxesEnabled={handleOnSetCheckBoxesEnabled}
						onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
					/>
				</TabPane>
			);
		}

		if (isAdmin) {
			tabs.push(
				<TabPane tab={_('PhotoCallUp')} key='PhotoCallUp'>
					<PhotoCallUpSettings
						castNumber={castNumber}
						configuration={configuration}
						onSetSettingValue={handleOnSetSettingValue}
						onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
					/>
				</TabPane>
			);
		}

		tabs.push(
			<TabPane tab={_('StatusDashboard')} key='StatusDashboard'>
				<StatusDashboardSettings
					configuration={configuration}
					onSetViewSettingValue={handleOnSetViewSettingValue}
					onSetCheckBoxesEnabled={handleOnSetCheckBoxesEnabled}
					onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
				/>
			</TabPane>
		);

		if (isAdmin) {
			tabs.push(
				<TabPane tab={_('SecuritySettings')} key='SecuritySettings'>
					<SecuritySettings
						castNumber={castNumber}
						configuration={configuration}
						onSetSettingValue={handleOnSetSettingValue}
						onSetSettingsHaveChanged={handleOnSetSettingsHaveChanged}
					/>
				</TabPane>
			);
		}
	}

	return (
		<>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
				{!isLoading && (
					<>
						<HeaderBar icon={<SettingOutlined />} title={_('Settings')} />
						<Tabs
							defaultActiveKey='General'
							activeKey={selectedActiveKey}
							className={styles.spacingTabList}
							onChange={activeKey => setSelectedActiveKey(activeKey)}>
							{tabs}
						</Tabs>
						<div className={styles.footer}>
							<div className={styles.description}>*{_('SecuritySettingsDescription')}</div>
							<Button type='primary' className='{styles.configurationSettingsAlignButton}' onClick={handleSaveChangesSettings}>
								{_('SaveChanges')}
							</Button>
						</div>
					</>
				)}
			</Spin>
			<ModalConfirmCustomFooter
				title={_('Warning')}
				width={'550px'}
				visible={isSaveChangesModalOpen}
				navigateTo={redirectTo}
				onOk={handleSaveChangesSettings}
				onCancel={() => setIsSaveChangesModalOpen(false)}
			/>
		</>
	);
};

export { ConfigurationPage };

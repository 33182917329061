import { LabeledValue } from 'antd/lib/select';
import React from 'React';
import { CustomFilterGroup } from '../../../model/EventModel';
import { Select } from '../../common';

type Props = {
	customFilterGroupsId: string;
	filteredCustomFilterGroups: CustomFilterGroup[];
	currentSelectedCustomFilterGroups: CustomFilterGroup[];
	handleOnChangeCustomFilterGroups: (values: any[]) => void;
};

const CustomFilterSelect: React.FC<Props> = React.memo(
	({ customFilterGroupsId, filteredCustomFilterGroups, currentSelectedCustomFilterGroups, handleOnChangeCustomFilterGroups }: Props) => (
		<Select
			id={customFilterGroupsId}
			mode='multiple'
			placeholder={_('CustomFilters')}
			options={filteredCustomFilterGroups?.map(x => ({ id: x.FilterGroupId.toString(), value: x.FilterGroupId, label: x.GroupName }))}
			key={customFilterGroupsId}
			value={currentSelectedCustomFilterGroups?.map(x => {
				const mappedItem: LabeledValue = {
					key: x.FilterGroupId.toString(),
					value: x.FilterGroupId,
					label: x.GroupName,
				};

				return mappedItem;
			})}
			onChange={handleOnChangeCustomFilterGroups}
		/>
	)
);

export { CustomFilterSelect };

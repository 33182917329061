import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { CSSProperties } from 'react';
import styles from './modalWarning.module.scss';

type Props<T> = {
	onOk?: (value: T) => void;
	onCancel?: (value: T) => void;
	content: React.ReactNode;
	title?: string | React.ReactNode;
	okText?: string;
	cancelText?: string;
	width?: string;
	style?: CSSProperties;
	hideCancelButton?: boolean;
};

const okButtonProps: ButtonProps = { id: 'warningOkButton', className: styles.okButton };
const cancelButtonProps: ButtonProps = { id: 'warningCancelButton', className: styles.cancelButton };

const ModalWarning = <T extends object | string | number>({ content, onCancel, onOk, title, cancelText, okText, width, style, hideCancelButton }: Props<T>) => {
	return Modal.warning({
		keyboard: false,
		maskClosable: false,
		style,
		width,
		content,
		title,
		onOk: onOk,
		onCancel,
		okText: okText || 'Yes',
		cancelText: hideCancelButton ? undefined : cancelText || 'No',
		icon: <InfoCircleOutlined className={styles.icon} />,
		okButtonProps: okButtonProps,
		cancelButtonProps: cancelButtonProps,
		className: styles.body,
	});
};

export { ModalWarning };

import { getPageId } from '../Helper';
import { SecuredComponents } from '../model/AccountModel';
import { ResponseObject, ResponseObjectEntity, SelectedDeviceKeys, Tuple } from '../model/CommonModel';
import {
	AssuranceLevelList,
	BroadCastDevice,
	CCMFirmwareConfiguration,
	CheckComponentLock,
	Command,
	CommandEdit,
	CommandSetPagination,
	CommandSetView,
	Commands,
	ConflictDoorGroupViewModel,
	ControlZone,
	ControlZonesControllers,
	ControlZonesEditor,
	ControlZonesStdMaster,
	Controller,
	ControllerACBSetup,
	ControllerBaseData,
	ControllerContact,
	ControllerData,
	ControllerDoor,
	ControllerReader,
	ControllerType,
	ControllerXBoxData,
	CredentialTemplateData,
	CredentialTemplatePagination,
	DeviceAdminWarningType,
	DeviceAdminWarningTypeString,
	DeviceObjectType,
	DeviceSelectedPage,
	Door,
	DoorDeletingWarning,
	DoorGroup,
	DoorGroupAndMasterInfo,
	DoorGroupAndMasterPagination,
	DoorGroupInfo,
	DoorGroupViewModel,
	DownloadTSFirmware,
	ErrorMessages,
	ExpansionRelayInfo,
	GlobalIOGroup,
	GlobalIOGroupInfo,
	GlobalIOGroupMembersPagination,
	GlobalIOGroupPagination,
	GlobalIOGroupServerInfo,
	GrandMasterTimeZone,
	GrandMasterTimeZonePagination,
	GrandMasterTimeZones,
	Holiday,
	HolidayPagination,
	Holidays,
	Input,
	LoadMessagesForFirmwareConfiguration,
	Locations,
	LockAffectedDoorGroups,
	LockStatusDoorGroupViewModel,
	LockStatusWarning,
	MasterDoorGroup,
	MasterDoorGroupViewModel,
	MasterTimeZonePagination,
	MasterTimeZones,
	MasterTimeZonesEntity,
	NetworkInfo,
	NewControllerData,
	OSDPLEDOption,
	OccupancySetupByZone,
	OperatorModel,
	OperatorPagination,
	PhysicalPort,
	PingInfo,
	Port,
	Reader,
	ReaderControlGroupInfo,
	ReaderControlGroupItem,
	ReaderControlGroupPagination,
	ReaderControlGroupView,
	ReaderDeletingWarning,
	RolePagination,
	RoleResponseObject,
	SNIB3EncryptionResetWarning,
	SNIBFirmwareConfiguration,
	ScrambleFactorImageCategories,
	SearchIPAddressConfig,
	SelectOption,
	SelectTimeZone,
	SelectedDoorGroup,
	SendDeviceCommand,
	StandardTimeZone,
	StandardTimeZoneAndMasterTimeZone,
	StandardTimeZonePagination,
	TimeZoneType,
	UpdateControlZone,
	UseList,
	UserRole,
	VelocityCommandType,
	VelocityDevice,
	WarningMsgResetEncryption,
	XboxData,
} from '../model/DeviceAdminModel';
import {
	ActiveDirectoryUserFlags,
	ActiveDirectoryUserValidation,
	ActiveDirectoryUsersPaginated,
	OperatorCredential,
	OperatorPaginationSetting,
	OperatorPasswordErrorMessage,
	OperatorPropertyModel,
	OperatorSecurityKeyContext,
	OrganizationalUnit,
	UserAccountOperationResponse,
} from '../model/OperatorModel';
import { PaginationSetting } from './../model/CommonModel';
import { Api } from './api';

class DeviceAdminApi extends Api {
	public constructor() {
		//NEVER FORGET THE SUPER
		super();
	}

	public getControllerContacts = async (deviceObjectType: DeviceObjectType, onlyIds: boolean = false): Promise<ResponseObjectEntity<ControllerContact[]>> => {
		const url = this.getUrl('DeviceAdmin/GetControllerContacts', true);
		const data = {
			controllerId: -1,
			type: deviceObjectType,
			onlyIds,
		};

		return await this.post<ResponseObjectEntity<ControllerContact[]>>(url, data);
	};

	public getControllerContactsBy = async (
		deviceObjectType: DeviceObjectType,
		paginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<ControllerContact[]>> => {
		const url = this.getUrl('DeviceAdmin/GetControllerContactsBy', true);
		const data = {
			controllerId: -1,
			deviceObjectType,
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<ControllerContact[]>>(url, data);
	};

	public getControllerContactPage = async (
		deviceObjectType: DeviceObjectType,
		deviceId: number,
		paginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<number>> => {
		const url = this.getUrl('DeviceAdmin/GetControllerContactPage', true);
		const data = {
			controllerId: -1,
			deviceId,
			deviceObjectType,
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<number>>(url, data);
	};

	public sendDeviceCommand = async ({ type, action, controllerId, deviceId }: SendDeviceCommand): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SendDeviceCommand', true);
		const data = {
			action,
			contactType: type,
			controllerId,
			deviceId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public getOperators = async (): Promise<OperatorModel[]> => {
		const url = this.getUrl('DeviceAdmin/GetOperators', true);

		return await this.post<OperatorModel[]>(url);
	};

	public getOperatorErrorMessages = async (): Promise<ResponseObjectEntity<OperatorPasswordErrorMessage>> => {
		const url = this.getUrl('DeviceAdmin/GetOperatorPasswordErrorCollection', true);
		return await this.post<ResponseObjectEntity<OperatorPasswordErrorMessage>>(url);
	};

	public getOperatorFormCaption = async (): Promise<ResponseObjectEntity<string>> => {
		const url = this.getUrl('DeviceAdmin/GetOperatorFormCaption', true);
		return await this.post<ResponseObjectEntity<string>>(url);
	};

	public isNewOperatorDuplicate = async (
		name: string,
		domain: string,
		foundByFinder: boolean,
		isEnableCustomGroupNames: boolean
	): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/IsNewOperatorDuplicate', true);
		const data = {
			name,
			domain,
			foundByFinder,
			isEnableCustomGroupNames,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public createNewAccountAndOperator = async (
		newOperator: OperatorPropertyModel,
		subscribedRoleIds: number[],
		foundInFinder: boolean,
		oUPath: string,
		contextSecurityKeys: OperatorSecurityKeyContext[],
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObjectEntity<UserAccountOperationResponse>> => {
		const url = this.getUrl('DeviceAdmin/CreateNewAccountAndOperator', true);
		const data = {
			newOperator,
			subscribedRoleIds,
			foundInFinder,
			oUPath,
			contextSecurityKeys,
			elevatedCredentials,
		};
		return await this.post<ResponseObjectEntity<UserAccountOperationResponse>>(url, data);
	};

	public createNewOperator = async (
		newOperator: OperatorPropertyModel,
		subscribedRoleIds: number[],
		contextSecurityKeys: OperatorSecurityKeyContext[]
	): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/CreateNewOperator', true);
		const data = {
			newOperator,
			subscribedRoleIds,
			contextSecurityKeys,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public updateOperatorSecurityKeys = async (
		operatorToUpdate: OperatorPropertyModel,
		contextSecurityKeys: OperatorSecurityKeyContext[]
	): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UpdateOperatorSecurityKeys', true);
		const data = {
			operatorToUpdate,
			contextSecurityKeys,
		};
		return await this.post<ResponseObject>(url, data);
	};

	public updateOperator2fa = async (operatorToUpdate: OperatorPropertyModel, contextSecurityKeys: OperatorSecurityKeyContext[]): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UpdateOperator2fa', true);
		const data = {
			operatorToUpdate,
			contextSecurityKeys,
		};
		return await this.post<ResponseObject>(url, data);
	};

	public updateOperator = async (
		operatorToUpdate: OperatorPropertyModel,
		subscribedRoleIds: number[],
		unsubscribedRoleIds: number[],
		contextSecurityKeys: OperatorSecurityKeyContext[]
	): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/UpdateOperator', true);
		const data = {
			operatorToUpdate,
			subscribedRoleIds,
			unsubscribedRoleIds,
			contextSecurityKeys,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public getRolesPermissions = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<RolePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetRolesPermissions', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<RolePagination>>(url, data);
	};

	public getRoleIds = async (): Promise<number[]> => {
		const url = this.getUrl('DeviceAdmin/GetRoleIds', true);

		return await this.post<number[]>(url);
	};

	public getRoleById = async (roleId: number): Promise<ResponseObjectEntity<UserRole>> => {
		const url = this.getUrl('DeviceAdmin/GetRole', true);
		const data = {
			roleId,
		};

		return await this.post<ResponseObjectEntity<UserRole>>(url, data);
	};

	public getEnableCustomGroupNamesSetting = async (): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/GetEnableCustomGroupNamesSetting', true);
		return await this.post<ResponseObjectEntity<boolean>>(url);
	};

	public isUseDomainAuthentication = async (): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/IsUseDomainAuthentication', true);
		return await this.post<ResponseObjectEntity<boolean>>(url);
	};

	public getMachineName = async (): Promise<ResponseObjectEntity<string>> => {
		const url = this.getUrl('DeviceAdmin/GetMachineName', true);
		return await this.post<ResponseObjectEntity<string>>(url);
	};

	public getOperatorsBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<OperatorPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetOperatorsBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<OperatorPagination>>(url, data);
	};

	public getOperatorProperties = async (
		operatorId: number,
		isEnableCustomGroupNames: boolean,
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObjectEntity<OperatorPropertyModel>> => {
		const url = this.getUrl('DeviceAdmin/GetOperatorProperties', true);
		const data = {
			operatorId,
			isEnableCustomGroupNames,
			elevatedCredentials,
		};
		return await this.post<ResponseObjectEntity<OperatorPropertyModel>>(url, data);
	};

	public getRolesOperatorNotIn = async (operatorId: number, paginationSetting: OperatorPaginationSetting): Promise<ResponseObjectEntity<RolePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetRolesOperatorNotIn', true);
		const data = {
			operatorId,
			paginationSetting,
		};
		return await this.post<ResponseObjectEntity<RolePagination>>(url, data);
	};

	public getRolesOperatorIn = async (operatorId: number, paginationSetting: OperatorPaginationSetting): Promise<ResponseObjectEntity<RolePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetRolesOperatorIn', true);
		const data = {
			operatorId,
			paginationSetting,
		};
		return await this.post<ResponseObjectEntity<RolePagination>>(url, data);
	};

	public doesDefaultOperatorRoleExist = async (roleId: number, roleName: string): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/DoesDefaultOperatorRoleExist', true);
		const data = {
			roleId,
			roleName,
		};
		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public getRoleIdsOperatorIn = async (operatorId: number): Promise<ResponseObjectEntity<number[]>> => {
		const url = this.getUrl('DeviceAdmin/GetRoleIdsOperatorIn', true);
		const data = {
			operatorId,
		};
		return await this.post<ResponseObjectEntity<number[]>>(url, data);
	};

	public getRoleIdsOperatorNotIn = async (operatorId: number): Promise<ResponseObjectEntity<number[]>> => {
		const url = this.getUrl('DeviceAdmin/GetRoleIdsOperatorNotIn', true);
		const data = {
			operatorId,
		};
		return await this.post<ResponseObjectEntity<number[]>>(url, data);
	};

	public getDomains = async (
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('DeviceAdmin/GetDomains', true);
		return await this.post<ResponseObjectEntity<string[]>>(url, { elevatedCredentials });
	};

	public getForest = async (): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('DeviceAdmin/GetForest', true);
		return await this.post<ResponseObjectEntity<string[]>>(url);
	};

	public getAuthenticationPath = async (): Promise<ResponseObjectEntity<string>> => {
		const url = this.getUrl('DeviceAdmin/GetAuthenticationPath', true);
		return await this.post<ResponseObjectEntity<string>>(url);
	};

	public getUsersInDomain = async (
		searchDomain: string,
		excludeExistingOperators: boolean,
		paginationSetting: PaginationSetting,
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObjectEntity<ActiveDirectoryUsersPaginated>> => {
		const url = this.getUrl('DeviceAdmin/GetUsersInDomain', true);
		const data = {
			searchDomain,
			excludeExistingOperators,
			paginationSetting,
			elevatedCredentials,
		};
		return await this.post<ResponseObjectEntity<ActiveDirectoryUsersPaginated>>(url, data);
	};

	public getUserInDomain = async (
		domain: string,
		samAccountName: string,
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObjectEntity<ActiveDirectoryUserValidation>> => {
		const url = this.getUrl('DeviceAdmin/GetUserInDomain', true);
		const data = {
			domain,
			samAccountName,
			elevatedCredentials,
		};
		return await this.post<ResponseObjectEntity<ActiveDirectoryUserValidation>>(url, data);
	};

	public getUserFlags = async (
		domain: string,
		samAccountName: string,
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObjectEntity<ActiveDirectoryUserFlags>> => {
		const url = this.getUrl('DeviceAdmin/GetUserActiveDirectoryFlags', true);
		const data = {
			domain,
			samAccountName,
			elevatedCredentials,
		};
		return await this.post<ResponseObjectEntity<ActiveDirectoryUserFlags>>(url, data);
	};

	public getOrganizationalUnits = async (domain: string): Promise<ResponseObjectEntity<OrganizationalUnit>> => {
		const url = this.getUrl('DeviceAdmin/GetOrganizationalUnits', true);
		const data = {
			domain,
		};
		return await this.post<ResponseObjectEntity<OrganizationalUnit>>(url, data);
	};

	public lockDownOperator = async (operator: OperatorModel): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/LockDownOperator', true);

		return await this.post<ResponseObject>(url, operator);
	};

	public enableOperator = async (operator: OperatorModel): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EnableOperator', true);

		return await this.post<ResponseObject>(url, operator);
	};

	public getHolidays = async (): Promise<Holidays[]> => {
		const url = this.getUrl('DeviceAdmin/GetHolidays', true);

		return await this.post<Holidays[]>(url);
	};

	public getHolidaysBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<HolidayPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetHolidaysBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<HolidayPagination>>(url, data);
	};

	public getCommandSets = async (): Promise<ResponseObjectEntity<Commands[]>> => {
		const url = this.getUrl('DeviceAdmin/GetCommandSets', true);

		return await this.post<ResponseObjectEntity<Commands[]>>(url);
	};

	public getCommandSetsBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<CommandSetPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetCommandSetsBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<CommandSetPagination>>(url, data);
	};

	public getDoorGroupsAndMasterDoorGroups = async (): Promise<DoorGroupAndMasterInfo[]> => {
		const url = this.getUrl('DeviceAdmin/GetDoorGroupsAndMasterDoorGroups', true);

		return await this.post<DoorGroupAndMasterInfo[]>(url);
	};

	public getDoorGroupsAndMasterDoorGroupsBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<DoorGroupAndMasterPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetDoorGroupsAndMasterDoorGroupsBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<DoorGroupAndMasterPagination>>(url, data);
	};

	public getReaderControlGroups = async (): Promise<ResponseObjectEntity<ReaderControlGroupItem[]>> => {
		const url = this.getUrl('DeviceAdmin/GetReaderControlGroups', true);

		return await this.post<ResponseObjectEntity<ReaderControlGroupItem[]>>(url);
	};

	public getReaderControlGroupsBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<ReaderControlGroupPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetReaderControlGroupsBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<ReaderControlGroupPagination>>(url, data);
	};

	public getCredentialTemplates = async (): Promise<ResponseObjectEntity<CredentialTemplateData[]>> => {
		const url = this.getUrl('DeviceAdmin/GetCredentialTemplates', true);

		return await this.post<ResponseObjectEntity<CredentialTemplateData[]>>(url);
	};

	public getCredentialTemplatesBy = async (paginationSetting?: PaginationSetting): Promise<ResponseObjectEntity<CredentialTemplatePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetCredentialTemplatesBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<CredentialTemplatePagination>>(url, data);
	};

	public executeCommandSet = async (commandSetId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/ExecuteCommandSet', true);
		const data = {
			commandSetId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteCommandSetRequest = async (commandSetId: number, commandSetName: string): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteCommandSetById', true);
		const data = {
			commandSetId,
			commandSetName,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteRole = async (roleId: number, roleName: string): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteRole', true);
		const data = {
			roleId,
			roleName,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public copyRole = async (originalRoleId: number, name: string, description: string): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CopyRole', true);
		const data = {
			originalRoleId,
			description,
			name,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public createNewRole = async (name: string, description: string, createWithAllPermissions: boolean): Promise<RoleResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CreateNewRole', true);
		const data = {
			name,
			description,
			createWithAllPermissions,
		};

		return await this.post<RoleResponseObject>(url, data);
	};

	public unlockComponentDeviceAdmin = async (objectId: number, securedComponents: SecuredComponents): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UnlockComponentDeviceAdmin', true);
		const data = {
			objectId,
			securedComponents,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public cleanUpMultiCastUtilityWrapper = async (): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CleanUpMultiCastUtilityWrapper', true);
		const data = {
			pageId: getPageId(),
		};

		return await this.post<ResponseObject>(url, data);
	};

	public canDeleteDoorGroup = async (doorGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CanDeleteDoorGroup', true);
		const data = {
			doorGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public canDeleteMasterDoorGroup = async (masterDoorGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CanDeleteMasterDoorGroup', true);
		const data = {
			masterDoorGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public getDoorGroupAndMasterDoorDeleteWarning = async (
		objectId: number,
		isMasterDoorGroup: boolean,
		warningType: string
	): Promise<DoorDeletingWarning & ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/GetDoorGroupAndMasterDoorDeleteWarning', true);
		const data = {
			objectId,
			isMasterDoorGroup,
			warningType,
		};

		return await this.post<DoorDeletingWarning & ResponseObject>(url, data);
	};

	public deleteMasterDoorGroup = async (masterDoorGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteMasterDoorGroup', true);
		const data = {
			masterDoorGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteDoorGroup = async (doorGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteDoorGroup', true);
		const data = {
			doorGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public canDeleteReaderControlGroup = async (readerControlGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CanDeleteReaderControlGroup', true);
		const data = {
			readerControlGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public getReaderControlGroupDeleteWarning = async (readerControlGroupId: number): Promise<ReaderDeletingWarning> => {
		const url = this.getUrl('DeviceAdmin/GetReaderControlGroupDeleteWarning', true);
		const data = {
			readerControlGroupId,
		};

		return await this.post<ReaderDeletingWarning>(url, data);
	};

	public deleteReaderControlGroup = async (readerControlGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteReaderControlGroup', true);
		const data = {
			readerControlGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public setDefaultCredential = async (credentialTemplateId: number, description: string): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SetDefaultCredentialTemplate', true);
		const data = {
			Description: description,
			CredentialTemplateId: credentialTemplateId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteCredentialTemplateRequest = async (credentialId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteCredentialTemplate', true);
		const data = {
			credentialId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public retrieveStandardTimeZones = async (): Promise<ResponseObjectEntity<StandardTimeZone[]>> => {
		const url = this.getUrl('DeviceAdmin/GetStandardTimeZonesPreview', true);

		return await this.post<ResponseObjectEntity<StandardTimeZone[]>>(url);
	};

	public retrieveStandardTimeZonesBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<StandardTimeZonePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetStandardTimeZonesBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<StandardTimeZonePagination>>(url, data);
	};

	public retrieveMasterTimeZones = async (): Promise<ResponseObjectEntity<MasterTimeZones[]>> => {
		const url = this.getUrl('DeviceAdmin/GetMasterTimeZonesPreview', true);

		return await this.post<ResponseObjectEntity<MasterTimeZones[]>>(url);
	};

	public retrieveMasterTimeZonesBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<MasterTimeZonePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetMasterTimeZonesBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<MasterTimeZonePagination>>(url, data);
	};

	public retrieveGrandMasterTimeZones = async (): Promise<ResponseObjectEntity<GrandMasterTimeZones[]>> => {
		const url = this.getUrl('DeviceAdmin/GetGrandMasterTimeZonesPreview', true);

		return await this.post<ResponseObjectEntity<GrandMasterTimeZones[]>>(url);
	};

	public retrieveGrandMasterTimeZonesBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<GrandMasterTimeZonePagination>> => {
		const url = this.getUrl('DeviceAdmin/GetGrandMasterTimeZonesBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<GrandMasterTimeZonePagination>>(url, data);
	};

	public deleteStandardTimeZone = async (timeZoneId: number): Promise<ResponseObject & UseList> => {
		const url = this.getUrl('DeviceAdmin/DeleteStandardTimeZone', true);
		const data = {
			timeZoneId,
		};

		return await this.post<ResponseObject & UseList>(url, data);
	};

	public removeTimeZoneLocks = async (timeZoneType: TimeZoneType): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/ClearTimeZoneLocks', true);
		const data = {
			timeZoneType,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteGrandMasterTimeZone = async (grandMasterTimeZoneId: number): Promise<ResponseObject & UseList> => {
		const url = this.getUrl('DeviceAdmin/DeleteGrandMasterTimeZone', true);
		const data = {
			grandMasterTimeZoneId,
		};

		return await this.post<ResponseObject & UseList>(url, data);
	};

	public deleteMasterTimeZone = async (masterTimeZoneId: number): Promise<ResponseObject & UseList> => {
		const url = this.getUrl('DeviceAdmin/DeleteMasterTimeZone', true);
		const data = {
			masterTimeZoneId,
		};

		return await this.post<ResponseObject & UseList>(url, data);
	};

	public getControllers = async (): Promise<VelocityDevice[]> => {
		const url = this.getUrl('DeviceAdmin/GetControllers', true);

		return await this.post<VelocityDevice[]>(url);
	};

	public getAllControllers = async (): Promise<VelocityDevice[]> => {
		const url = this.getUrl('DeviceAdmin/GetAllControllers', true);

		return await this.post<VelocityDevice[]>(url);
	};

	public getErrorMessages = async (securedComponent: SecuredComponents): Promise<ErrorMessages> => {
		const url = this.getUrl('DeviceAdmin/GetErrorMessages', true);

		return await this.post<ErrorMessages>(url, { securedComponent });
	};

	public addCommandSetDialog = async (type: VelocityCommandType): Promise<CommandSetView> => {
		const url = this.getUrl('DeviceAdmin/AddCommandSetDialog', true);

		return await this.post<CommandSetView>(url, { type });
	};

	public getLocations = async (functionId: number, deviceId: number): Promise<Locations> => {
		const url = this.getUrl('DeviceAdmin/GetLocations', true);
		const data = {
			functionId,
			deviceId,
		};

		return await this.post<Locations>(url, data);
	};

	public getCommand = async (functionId: number, locationId: number): Promise<string> => {
		const url = this.getUrl('DeviceAdmin/GetCommand', true);
		const data = {
			functionId,
			locationId,
		};

		return await this.post<string>(url, data);
	};

	public addCommandSet = async (commands: Command): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddCommandSet', true);
		const data = {
			...commands,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public editCommandSet = async (commands: CommandEdit): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditCommandSet', true);
		const data = {
			...commands,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public getAvailableXAddresses = async (isNewXbox: boolean, portId: number, xboxAddress: number): Promise<number[]> => {
		const url = this.getUrl('DeviceAdmin/GetAvailableXboxAddresses', true);
		const data = {
			isNewXbox,
			portId,
			xboxAddress,
		};

		return await this.post<number[]>(url, data);
	};

	public getXboxData = async (xboxId: number): Promise<XboxData> => {
		const url = this.getUrl('DeviceAdmin/GetXboxData', true);
		const data = {
			xboxId,
		};

		return await this.post<XboxData>(url, data);
	};

	public saveXbox = async (xboxData: XboxData): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SaveXbox', true);
		const data = {
			xboxData,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public addReaderControlGroupDialog = async (): Promise<ReaderControlGroupView> => {
		const url = this.getUrl('DeviceAdmin/AddReaderControlGroupDialog', true);

		return await this.post<ReaderControlGroupView>(url);
	};

	public getControllersReaders = async (controllerId: number): Promise<ControllerReader[]> => {
		const url = this.getUrl('DeviceAdmin/GetControllersReaders', true);

		return await this.post<ControllerReader[]>(url, { controllerId });
	};

	public addReaderControlGroup = async (readerControlGroup: ReaderControlGroupInfo): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddReaderControlGroup', true);

		return await this.post<ResponseObject>(url, { readerControlGroup });
	};

	public editReaderControlGroup = async (readerControlGroup: ReaderControlGroupInfo): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditReaderControlGroup', true);

		return await this.post<ResponseObject>(url, { readerControlGroup });
	};

	public editReaderControlGroupDialog = async (readerControlGroupId: number): Promise<ReaderControlGroupView> => {
		const url = this.getUrl('DeviceAdmin/EditReaderControlGroupDialog', true);

		return await this.post<ReaderControlGroupView>(url, { readerControlGroupId });
	};

	public addPort = async (port: Port): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddPort', true);
		const data = {
			...port,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public editPort = async (port: Port): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditPort', true);
		const data = {
			...port,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public sendPingToIP = async (ipAddress: string): Promise<ResponseObjectEntity<PingInfo[]>> => {
		const url = this.getUrl('DeviceAdmin/SendPingToIP', true);
		const data = {
			ipAddress,
		};

		return await this.post<ResponseObjectEntity<PingInfo[]>>(url, data);
	};

	public getPort = async (portId: number): Promise<Port> => {
		const url = this.getUrl('DeviceAdmin/GetPort', true);
		const data = {
			portId,
		};

		return await this.post<Port>(url, data);
	};

	public searchIPAddress = async (searchIPAddressConfig: SearchIPAddressConfig): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SearchIPAddress', true);
		const data = {
			searchIPAddressConfig,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public stopIPAddressSearch = async (pageId: string): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/StopIPAddressSearch', true);
		const data = {
			pageId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public getLineModulesForInputs = async (): Promise<string[]> => {
		const url = this.getUrl('DeviceAdmin/GetLineModulesForInputs', true);

		return await this.post<string[]>(url);
	};

	public getGenericsControlTimeZonesByInput = async (inputId: number, isExpansionInput: boolean): Promise<ControlZonesStdMaster> => {
		const url = this.getUrl('DeviceAdmin/GetGenericsControlTimeZonesByInput', true);
		const data = {
			inputId,
			isExpansionInput,
		};

		return await this.post<ControlZonesStdMaster>(url, data);
	};

	public getGenericTimeZones = async (): Promise<SelectTimeZone[]> => {
		const url = this.getUrl('DeviceAdmin/GetGenericTimeZonesByOperator', true);

		return await this.post<SelectTimeZone[]>(url);
	};

	public getGenericsControlTimeZonesByController = async (controllerId: number): Promise<ControlZonesStdMaster> => {
		const url = this.getUrl('DeviceAdmin/GetGenericsControlTimeZonesByController', true);

		return await this.post<ControlZonesStdMaster>(url, { controllerId });
	};

	public getInput = async (inputId: number): Promise<Input> => {
		const url = this.getUrl('DeviceAdmin/LoadInput', true);

		return await this.post<Input>(url, { inputId });
	};

	public addStandardTimeZone = async (timeZone: Omit<StandardTimeZone, 'InUse'>): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddStandardTimeZone', true);

		return await this.post<ResponseObject>(url, { timeZone });
	};

	public editStandardTimeZone = async (timeZone: Omit<StandardTimeZone, 'InUse'>): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditStandardTimeZone', true);

		return await this.post<ResponseObject>(url, { timeZone });
	};

	public addMasterTimeZone = async (masterTimeZone: MasterTimeZonesEntity): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddMasterTimeZone', true);

		return await this.post<ResponseObject>(url, { masterTimeZone });
	};

	public editMasterTimeZone = async (masterTimeZone: MasterTimeZonesEntity): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditMasterTimeZone', true);

		return await this.post<ResponseObject>(url, { masterTimeZone });
	};

	public getStandardTimeZoneAndMasterTimeZones = async (): Promise<StandardTimeZoneAndMasterTimeZone[]> => {
		const url = this.getUrl('DeviceAdmin/GetStandardTimeZoneAndMasterTimeZones', true);

		return await this.post<StandardTimeZoneAndMasterTimeZone[]>(url);
	};

	public addGrandMasterTimeZone = async (grandMasterTimeZone: GrandMasterTimeZone): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddGrandMasterTimeZone', true);

		return await this.post<ResponseObject>(url, { grandMasterTimeZone });
	};

	public editGrandMasterTimeZone = async (grandMasterTimeZone: GrandMasterTimeZone): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditGrandMasterTimeZone', true);

		return await this.post<ResponseObject>(url, { grandMasterTimeZone });
	};

	public renameDevice = async (deviceType: DeviceObjectType, deviceName: string, deviceId: number, deviceSubType: number = 0): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/RenameDevice', true);
		const data = {
			deviceType,
			deviceName,
			deviceId,
			deviceSubType,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public downloadFirmware = async (downloadInfo: DownloadTSFirmware): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DownloadTSFirmware', true);
		const data = {
			downloadInfo,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public initiateOSDPHandshake = async (readerId: number, controllerId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/OSDPHandshake', true);
		const data = {
			readerId,
			controllerId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public editReader = async (reader: Partial<Reader>): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SaveReader', true);
		const data = {
			reader,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public validateEACAddress = async (reader: Partial<Reader>): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/ValidateEACAddress', true);
		const data = {
			reader,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public loadExpansionRelay = async (expansionRelayId: number): Promise<ExpansionRelayInfo> => {
		const url = this.getUrl('DeviceAdmin/LoadExpansionRelay', true);
		const data = {
			expansionRelayId,
		};

		return await this.post<ExpansionRelayInfo>(url, data);
	};

	public loadRelay = async (relayId: number): Promise<ExpansionRelayInfo> => {
		const url = this.getUrl('DeviceAdmin/LoadRelay', true);
		const data = {
			relayId,
		};

		return await this.post<ExpansionRelayInfo>(url, data);
	};

	public editExpansionRelay = async (expansionRelay: ExpansionRelayInfo): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SaveExpansionRelay', true);
		const data = {
			expansionRelay,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public editRelay = async (relay: ExpansionRelayInfo): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/SaveRelay', true);
		const data = {
			relay,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public isComponentLocked = async (lockInfo: CheckComponentLock): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CheckComponentLocks', true);
		const data = {
			lockInfo,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public loadReader = async (readerId: number): Promise<Reader> => {
		const url = this.getUrl('DeviceAdmin/LoadReader', true);
		const data = {
			readerId,
		};

		return await this.post<Reader>(url, data);
	};

	public getAssuranceLevels = async (rs485ReaderType: number): Promise<AssuranceLevelList> => {
		const url = this.getUrl('DeviceAdmin/GetAssuranceLists', true);
		const data = {
			rs485ReaderType,
		};

		return await this.post<AssuranceLevelList>(url, data);
	};

	public editInput = async (input: Input): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditInput', true);
		const data = {
			...input,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public editGrandMasterTimeZoneDialog = async (grandMasterTimeZoneId: number): Promise<GrandMasterTimeZones[]> => {
		const url = this.getUrl('DeviceAdmin/EditGrandMasterTimeZoneDialog', true);

		return await this.post<GrandMasterTimeZones[]>(url, { grandMasterTimeZoneId });
	};

	public getExpInput = async (expansionInputId: number): Promise<Input> => {
		const url = this.getUrl('DeviceAdmin/GetExpansionInput', true);

		return await this.post<Input>(url, { expansionInputId });
	};

	public editExpansionInput = async (expansionInput: Input): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditExpansionInput', true);
		const data = {
			...expansionInput,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public checkIfAnyTimeZoneIsNotLocked = async (): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/CheckIfAnyTimeZoneIsNotLocked', true);

		return await this.post<ResponseObject>(url);
	};

	public getWarningMessagePortProtocol = async (portId: number, protocol: number): Promise<WarningMsgResetEncryption> => {
		const url = this.getUrl('DeviceAdmin/GetWarningMessagePortProtocol', true);

		return await this.post<WarningMsgResetEncryption>(url, { portId, protocol });
	};

	public getControllersDoors = async (controllerId: number): Promise<ControllerDoor[]> => {
		const url = this.getUrl('DeviceAdmin/GetControllersDoors', true);

		return await this.post<ControllerDoor[]>(url, { controllerId });
	};

	public retrieveControllersDoors = async (controllers: number[]): Promise<ControllerDoor[]> => {
		const url = this.getUrl('DeviceAdmin/RetrieveControllersDoors', true);

		return await this.post<ControllerDoor[]>(url, { controllers });
	};

	public addDoorGroupDialog = async (): Promise<DoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/AddDoorGroupDialog', true);

		return await this.post<DoorGroupViewModel>(url);
	};

	public editDoorGroupDialog = async (doorGroupId: number): Promise<DoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/EditDoorGroupDialog', true);

		return await this.post<DoorGroupViewModel>(url, { doorGroupId });
	};

	public editHoliday = async (holidayId: number): Promise<ResponseObjectEntity<Holidays>> => {
		const url = this.getUrl('DeviceAdmin/EditHoliday', true);

		return await this.post<ResponseObjectEntity<Holidays>>(url, { holidayId });
	};

	public deleteHoliday = async (holidayId: number): Promise<ResponseObjectEntity<Tuple[]>> => {
		const url = this.getUrl('DeviceAdmin/DeleteHoliday', true);

		return await this.post<ResponseObjectEntity<Tuple[]>>(url, { holidayId });
	};

	public deleteXbox = async (xboxId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteXbox', true);

		return await this.post<ResponseObject>(url, { xboxId });
	};

	public addDoorGroup = async (doorGroup: DoorGroupInfo): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('DeviceAdmin/AddDoorGroup', true);

		return await this.post<ResponseObjectEntity<string[]>>(url, { doorGroup });
	};

	public editDoorGroup = async (doorGroup: DoorGroupInfo): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('DeviceAdmin/EditDoorGroup', true);

		return await this.post<ResponseObjectEntity<string[]>>(url, { doorGroup });
	};

	public getControlZonesByController = async (controllerId: number): Promise<ControlZonesControllers[]> => {
		const url = this.getUrl('DeviceAdmin/GetControlZonesByController', true);

		return await this.post<ControlZonesControllers[]>(url, { controllerId });
	};

	public addMasterDoorGroupDialog = async (): Promise<MasterDoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/AddMasterDoorGroupDialog', true);

		return await this.post<MasterDoorGroupViewModel>(url);
	};

	public editMasterDoorGroupDialog = async (masterDoorGroupId: number): Promise<MasterDoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/EditMasterDoorGroupDialog', true);

		return await this.post<MasterDoorGroupViewModel>(url, { masterDoorGroupId });
	};

	public addMasterDoorGroup = async (masterDoorGroup: MasterDoorGroup): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddMasterDoorGroup', true);

		return await this.post<ResponseObject>(url, { masterDoorGroup });
	};

	public editMasterDoorGroup = async (masterDoorGroup: MasterDoorGroup): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditMasterDoorGroup', true);

		return await this.post<ResponseObject>(url, { masterDoorGroup });
	};

	public getDoorGroupConflict = async (warningType: DeviceAdminWarningType, objectId: number, controllers: string): Promise<ConflictDoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/GetDoorGroupConflict', true);

		return await this.post<ConflictDoorGroupViewModel>(url, { warningType, objectId, controllers });
	};

	public checkZoneAvailability = async (masterDoorGroup: MasterDoorGroup): Promise<ConflictDoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/CheckZoneAvailability', true);

		return await this.post<ConflictDoorGroupViewModel>(url, { masterDoorGroup });
	};

	public getDoorGroupLockStatusWarning = async (
		warningType: DeviceAdminWarningTypeString | DeviceAdminWarningType,
		objectId: number,
		isMasterDoorGroup: boolean
	): Promise<LockStatusDoorGroupViewModel> => {
		const url = this.getUrl('DeviceAdmin/GetDoorGroupLockStatusWarning', true);

		return await this.post<LockStatusDoorGroupViewModel>(url, { objectId, warningType, isMasterDoorGroup });
	};

	public editCommandSetDialog = async (commandSetId: number, type: VelocityCommandType, isUpdate: boolean): Promise<CommandSetView> => {
		const url = this.getUrl('DeviceAdmin/EditCommandSetDialog', true);

		return await this.post<CommandSetView>(url, { commandSetId, type, isUpdate });
	};

	public saveHolidays = async (holiday: Holiday): Promise<ResponseObjectEntity<Tuple[]>> => {
		const url = this.getUrl('DeviceAdmin/SaveHoliday', true);

		return await this.post<ResponseObjectEntity<Tuple[]>>(url, { holiday });
	};

	public getDoor = async (doorId: number): Promise<Door> => {
		const url = this.getUrl('DeviceAdmin/RetrieveDoor', true);

		return await this.post<Door>(url, { doorId });
	};

	public editDoor = async (door: Door): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditDoor', true);
		const data = {
			...door,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public getLockAffectedDoorGroups = async (doorId: number): Promise<LockAffectedDoorGroups & ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/RetrieveLockAffectedDoorGroups', true);

		return await this.post<LockAffectedDoorGroups & ResponseObject>(url, { doorId });
	};

	public getDoorLocks = async (doorId: number, lockScope: number): Promise<LockStatusWarning & ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/RetrieveDoorLocks', true);
		const data = {
			doorId,
			lockScope,
		};

		return await this.post<LockStatusWarning & ResponseObject>(url, data);
	};

	public getIsDoorEntryReaderOnly = async (doorId: number): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/RetrieveIsDoorEntryReaderOnly', true);
		const data = {
			doorId,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public deletePort = async (portId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeletePort', true);

		return await this.post<ResponseObject>(url, { portId });
	};

	public deleteController = async (controllerId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteController', true);

		return await this.post<ResponseObject>(url, { controllerId });
	};

	public getAvailableCOMPorts = async (): Promise<ResponseObjectEntity<PhysicalPort[]>> => {
		const url = this.getUrl('DeviceAdmin/GetAvailableCOMPorts', true);

		return await this.post<ResponseObjectEntity<PhysicalPort[]>>(url);
	};

	public getBaudRates = async (): Promise<number[]> => {
		const url = this.getUrl('DeviceAdmin/GetBaudRates', true);

		return await this.post<number[]>(url);
	};

	public getController = async (controllerId: number): Promise<ResponseObjectEntity<Controller>> => {
		const url = this.getUrl('DeviceAdmin/GetController', true);

		return await this.post<ResponseObjectEntity<Controller>>(url, {
			controllerId,
		});
	};

	public getDefaultACBSetup = async (controllerId: number, controllerType: ControllerType, panelTimeZoneMap: number[]): Promise<ControllerACBSetup[]> => {
		const url = this.getUrl('DeviceAdmin/GetDefaultACBSetup', true);

		return await this.post<ControllerACBSetup[]>(url, {
			controllerId,
			controllerType,
			panelTimeZoneMap,
		});
	};

	public checkLastUserDBLocation = async (controllerId: number, currentUserDBLocation: number): Promise<string> => {
		const url = this.getUrl('DeviceAdmin/CheckLastUserDBLocation', true);

		return await this.post<string>(url, {
			controllerId,
			currentUserDBLocation,
		});
	};

	public editController = async (controllerData: ControllerData): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditController', true);

		return await this.post<ResponseObject>(url, {
			controllerData,
		});
	};

	public getOccupancyOptionsByZone = async (controllerId: number, zoneIndex: number): Promise<OccupancySetupByZone> => {
		const url = this.getUrl('DeviceAdmin/GetOccupancyOptionsByZone', true);

		return await this.post<OccupancySetupByZone>(url, {
			controllerId,
			zoneIndex,
		});
	};

	public getControlZonesEditor = async (controllerId: number): Promise<ResponseObjectEntity<ControlZonesEditor>> => {
		const url = this.getUrl('DeviceAdmin/GetControlZonesEditor', true);

		return await this.post<ResponseObjectEntity<ControlZonesEditor>>(url, {
			controllerId,
		});
	};

	public changeControllerType = async (controllerType: ControllerType, controllerId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/ChangeControllerTypeForAffectedDoorGroups', true);

		return await this.post<ResponseObject>(url, {
			controllerType,
			controllerId,
		});
	};

	public updateStandardControlZone = async (updateControlZoneData: UpdateControlZone): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UpdateStandardControlZone', true);

		return await this.post<ResponseObject>(url, {
			updateControlZoneData,
		});
	};

	public updateMasterControlZone = async (updateControlZoneData: UpdateControlZone): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UpdateMasterControlZone', true);

		return await this.post<ResponseObject>(url, {
			updateControlZoneData,
		});
	};

	public getAffectedDoorGroupByControllerChange = async (controllerType: ControllerType, controllerId: number): Promise<DoorGroup[]> => {
		const url = this.getUrl('DeviceAdmin/GetAffectedDoorGroupByControllerChange', true);

		return await this.post<DoorGroup[]>(url, {
			controllerType,
			controllerId,
		});
	};

	public getAddController = async (): Promise<ControllerBaseData> => {
		const url = this.getUrl('DeviceAdmin/GetNewController', true);

		return await this.post<ControllerBaseData>(url);
	};

	public getControllerXBoxes = async (portId: number): Promise<ControllerXBoxData[]> => {
		const url = this.getUrl('DeviceAdmin/GetControllerXBoxes', true);

		return await this.post<ControllerXBoxData[]>(url, { portId });
	};

	public getAvailableControllerAddress = async (portId: number, xBoxId: number): Promise<ResponseObjectEntity<SelectOption[]>> => {
		const url = this.getUrl('DeviceAdmin/GetControllerAddresses', true);

		return await this.post<ResponseObjectEntity<SelectOption[]>>(url, { portId, xBoxId });
	};

	public addController = async (newControllerData: NewControllerData): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddController', true);

		return await this.post<ResponseObject>(url, { newControllerData });
	};

	public getGenericTimeZonesWithPermissions = async (): Promise<SelectTimeZone[]> => {
		const url = this.getUrl('DeviceAdmin/GetGenericTimeZonesWithPermissions', true);

		return await this.post<SelectTimeZone[]>(url);
	};

	public sendSNIBConfiguration = async (broadCastDevice: BroadCastDevice): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/SendSNIBConfiguration', true);
		const data = {
			pageId: getPageId(),
			broadCastDevice,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public getControllerTypeChangeLocks = async (controllerId: number, controllerType: ControllerType): Promise<LockStatusWarning & ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/RetrieveControllerTypeChangeLocks', true);
		const data = {
			controllerId,
			controllerType,
		};

		return await this.post<LockStatusWarning & ResponseObject>(url, data);
	};

	public loadMessagesForFirmwareConfiguration = async (isSNIB3: boolean): Promise<ResponseObjectEntity<LoadMessagesForFirmwareConfiguration>> => {
		const url = this.getUrl('DeviceAdmin/LoadMessagesForFirmwareConfiguration', true);
		const data = {
			isSNIB3,
		};

		return await this.post<ResponseObjectEntity<LoadMessagesForFirmwareConfiguration>>(url, data);
	};

	public retrieveCCMInfoFirmware = async (): Promise<ResponseObjectEntity<CCMFirmwareConfiguration>> => {
		const url = this.getUrl('DeviceAdmin/RetrieveCCMInfoFirmware', true);

		return await this.post<ResponseObjectEntity<CCMFirmwareConfiguration>>(url);
	};

	public retrieveSNIBInfoFirmware = async (isSNIB3: boolean): Promise<ResponseObjectEntity<SNIBFirmwareConfiguration>> => {
		const url = this.getUrl('DeviceAdmin/RetrieveSNIBInfoFirmware', true);

		return await this.post<ResponseObjectEntity<SNIBFirmwareConfiguration>>(url, { isSNIB3 });
	};

	public updateCCMFirmwareController = async (controllerId: number, firmwareId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UpdateCCMFirmwareController', true);

		return await this.post<ResponseObject>(url, { controllerId, firmwareId });
	};

	public updateSNIBFirmwareController = async (controllerId: number, firmwareId: number, isSNIB3: boolean): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/UpdateSNIBFirmwareController', true);

		return await this.post<ResponseObject>(url, { controllerId, firmwareId, isSNIB3 });
	};

	public getGlobalIOGroups = async (): Promise<ResponseObjectEntity<GlobalIOGroup[]>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroups', true);

		return await this.post<ResponseObjectEntity<GlobalIOGroup[]>>(url);
	};

	public getGlobalIOGroupsBy = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<GlobalIOGroupPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroupsBy', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<GlobalIOGroupPagination>>(url, data);
	};

	public getGlobalIOGroupServerInfo = async (globalIOGroup: GlobalIOGroup): Promise<ResponseObjectEntity<GlobalIOGroupServerInfo>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroupServerInfo', true);
		const data = {
			globalIOGroup,
		};

		return await this.post<ResponseObjectEntity<GlobalIOGroupServerInfo>>(url, data);
	};

	public getGlobalIOGroupMembersPagination = async (
		globalIOGroupInfo: Partial<GlobalIOGroupInfo>,
		leftPaginationSetting: PaginationSetting,
		rightPaginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<GlobalIOGroupMembersPagination>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroupMembersPagination', true);
		const data = {
			globalIOGroupInfo,
			leftPaginationSetting,
			rightPaginationSetting,
		};

		return await this.post<ResponseObjectEntity<GlobalIOGroupMembersPagination>>(url, data);
	};

	public getGlobalIOGroupMembersBySelectedMembers = async (globalIOGroupInfo: Partial<GlobalIOGroupInfo>): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroupMembersBySelectedMembers', true);
		const data = {
			globalIOGroupInfo,
		};

		return await this.post<ResponseObjectEntity<string[]>>(url, data);
	};

	public getGlobalIOGroupServersBySelectedMembers = async (globalIOGroupInfo: Partial<GlobalIOGroupInfo>): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroupServersBySelectedMembers', true);
		const data = {
			globalIOGroupInfo,
		};

		return await this.post<ResponseObjectEntity<string[]>>(url, data);
	};

	public addGlobalIOGroup = async (globalIOGroup: GlobalIOGroupInfo): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/AddGlobalIOGroup', true);

		return await this.post<ResponseObject>(url, { globalIOGroup });
	};

	public editGlobalIOGroup = async (globalIOGroup: GlobalIOGroup): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/EditGlobalIOGroup', true);

		return await this.post<ResponseObject>(url, { globalIOGroup });
	};

	public deleteGlobalIOGroup = async (globalIOGroupId: number): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteGlobalIOGroup', true);

		return await this.post<ResponseObject>(url, { globalIOGroupId });
	};

	public retrieveSNIB3EncryptionSwitch = async (controllerId: number): Promise<ResponseObjectEntity<SNIB3EncryptionResetWarning>> => {
		const url = this.getUrl('DeviceAdmin/GetSNIB3EncryptionSwitch', true);

		return await this.post<ResponseObjectEntity<SNIB3EncryptionResetWarning>>(url, { controllerId });
	};

	public getStandardTimeZone = async (standardTimeZoneId: number): Promise<ResponseObjectEntity<StandardTimeZone>> => {
		const url = this.getUrl('DeviceAdmin/GetStandardTimeZone', true);

		return await this.post<ResponseObjectEntity<StandardTimeZone>>(url, { standardTimeZoneId });
	};

	public getMasterTimeZone = async (masterTimeZoneId: number): Promise<ResponseObjectEntity<MasterTimeZones>> => {
		const url = this.getUrl('DeviceAdmin/GetMasterTimeZone', true);

		return await this.post<ResponseObjectEntity<MasterTimeZones>>(url, { masterTimeZoneId });
	};

	public getGrandMasterTimeZone = async (grandMasterTimeZoneId: number): Promise<ResponseObjectEntity<GrandMasterTimeZones>> => {
		const url = this.getUrl('DeviceAdmin/GetGrandMasterTimeZone', true);

		return await this.post<ResponseObjectEntity<GrandMasterTimeZones>>(url, { grandMasterTimeZoneId });
	};

	public getControllerContactsBySelectedKeys = async (
		deviceObjectType: DeviceObjectType,
		selectedKeys: string[]
	): Promise<ResponseObjectEntity<ControllerContact[]>> => {
		const url = this.getUrl('DeviceAdmin/GetControllerContactsBySelectedKeys', true);
		const data = {
			deviceObjectType,
			selectedKeys,
		};

		return await this.post<ResponseObjectEntity<ControllerContact[]>>(url, data);
	};

	public getVelocityDeviceSelectedPage = async (
		deviceSelectedPage: DeviceSelectedPage,
		paginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<number>> => {
		const url = this.getUrl('DeviceAdmin/GetVelocityDeviceSelectedPage', true);
		const data = {
			deviceSelectedPage,
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<number>>(url, data);
	};

	public getVelocityDevicesBySelectedKeys = async (
		deviceObjectType: DeviceObjectType,
		selectedKeys: string[],
		selectedSubKeys: string[] = []
	): Promise<ResponseObjectEntity<SelectedDeviceKeys[]>> => {
		const url = this.getUrl('DeviceAdmin/GetVelocityDevicesBySelectedKeys', true);
		const data = {
			deviceObjectType,
			selectedKeys,
			selectedSubKeys,
		};

		return await this.post<ResponseObjectEntity<SelectedDeviceKeys[]>>(url, data);
	};

	public getCommandSet = async (commandSetId: number): Promise<ResponseObjectEntity<Commands>> => {
		const url = this.getUrl('DeviceAdmin/GetCommandSet', true);
		const data = {
			commandSetId,
		};

		return await this.post<ResponseObjectEntity<Commands>>(url, data);
	};

	public getCredentialTemplate = async (credentialTemplateId: number): Promise<ResponseObjectEntity<CredentialTemplateData>> => {
		const url = this.getUrl('DeviceAdmin/GetCredentialTemplate', true);
		const data = {
			credentialTemplateId,
		};

		return await this.post<ResponseObjectEntity<CredentialTemplateData>>(url, data);
	};

	public getReaderControlGroup = async (readerControlGroupId: number): Promise<ResponseObjectEntity<ReaderControlGroupItem>> => {
		const url = this.getUrl('DeviceAdmin/GetReaderControlGroup', true);
		const data = {
			readerControlGroupId,
		};

		return await this.post<ResponseObjectEntity<ReaderControlGroupItem>>(url, data);
	};

	public getHoliday = async (holidayId: number): Promise<ResponseObjectEntity<Holidays>> => {
		const url = this.getUrl('DeviceAdmin/GetHoliday', true);
		const data = {
			holidayId,
		};

		return await this.post<ResponseObjectEntity<Holidays>>(url, data);
	};

	public getGlobalIOGroup = async (globalIOGroupId: number): Promise<ResponseObjectEntity<GlobalIOGroup>> => {
		const url = this.getUrl('DeviceAdmin/GetGlobalIOGroup', true);
		const data = {
			globalIOGroupId,
		};

		return await this.post<ResponseObjectEntity<GlobalIOGroup>>(url, data);
	};

	public getDoorGroup = async (selectedDoorGroup: SelectedDoorGroup): Promise<ResponseObjectEntity<DoorGroupAndMasterInfo>> => {
		const url = this.getUrl('DeviceAdmin/GetDoorGroupOrMasterDoorGroup', true);
		const data = {
			selectedDoorGroup,
		};

		return await this.post<ResponseObjectEntity<DoorGroupAndMasterInfo>>(url, data);
	};

	public retrieveControlZones = async (controllerId: number): Promise<ControlZone[]> => {
		const url = this.getUrl('DeviceAdmin/RetrieveControlZones', true);
		const data = {
			controllerId,
		};

		return await this.post<ControlZone[]>(url, data);
	};

	public getOSDPLEDOptions = async (readerId: number): Promise<OSDPLEDOption[]> => {
		const url = this.getUrl('DeviceAdmin/GetOSDPLEDOptions', true);

		return await this.post<OSDPLEDOption[]>(url, { readerId });
	};

	public getActiveNetworks = async (): Promise<ResponseObjectEntity<NetworkInfo[]>> => {
		const url = this.getUrl('DeviceAdmin/GetActiveNetworks', true);

		return await this.post<ResponseObjectEntity<NetworkInfo[]>>(url);
	};

	public getAddressesToSearch = async (octet3): Promise<ResponseObjectEntity<number>> => {
		const url = this.getUrl('DeviceAdmin/GetAddressesToSearch', true);

		const data = {
			pageId: getPageId(),
			octet3,
		};

		return await this.post<ResponseObjectEntity<number>>(url, data);
	};

	public getDoorPhotoCallUpDelay = async (doorId: number): Promise<ResponseObjectEntity<number>> => {
		const url = this.getUrl('DeviceAdmin/GetDoorPhotoCallUpDelay', true);

		const data = {
			doorId,
		};

		return await this.post<ResponseObjectEntity<number>>(url, data);
	};

	public getScrambleFactorCategories = async (readerId: number): Promise<ResponseObjectEntity<ScrambleFactorImageCategories[]>> => {
		const url = this.getUrl('DeviceAdmin/GetScrambleFactorCategories', true);

		const data = {
			readerId,
		};

		return await this.post<ResponseObjectEntity<ScrambleFactorImageCategories[]>>(url, data);
	};

	public canImageBeDeleted = async (imageId: number, readerId: number): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/CanImageBeDeleted', true);
		const data = {
			imageId,
			readerId,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public uniqueNameCheck = async (imageName: string, imageId?: number): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/UniqueNameCheck', true);
		const data = {
			imageName,
			imageId,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};

	public get3FRReaderBitMasks = async (readerId: number): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('DeviceAdmin/Get3FRReaderBitmasks', true);

		return await this.post<ResponseObjectEntity<boolean>>(url, { readerId });
	};

	public deleteOperator = async (operatorId: number, removeFromGroup: boolean, elevatedCredentials: OperatorCredential): Promise<ResponseObject> => {
		const url = this.getUrl('DeviceAdmin/DeleteOperator', true);
		const data = {
			elevatedCredentials,
			operatorId,
			removeFromGroup,
		};

		return await this.post<ResponseObject>(url, data);
	};
}

const deviceAdminApi = new DeviceAdminApi();

export { deviceAdminApi };

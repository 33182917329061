import { notification } from 'antd';
import { ResponseObject, ResponseStatusCode } from '../../../model/CommonModel';

type Props = {
	responseData: ResponseObject;
	onSuccessCallback?: () => void;
	onSystemErrorCallback?: () => void;
	onPermissionErrorCallback?: () => void;
	onDefaultEventCallback?: () => void;
	onComponentLockedCallback?: () => void;
	onAdditionalInfoRequiredCallback?: () => void;
	onFailedValidation?: () => void;
	onInputErrorCallback?: () => void;
	onUndefinedCallback?: () => void;
	onDatabaseAccessCallback?: () => void;
	notUseDefaultNotification?: boolean;
};

const NotificationStatus = ({
	responseData,
	onDefaultEventCallback,
	onPermissionErrorCallback,
	onSuccessCallback,
	onSystemErrorCallback,
	onComponentLockedCallback,
	onAdditionalInfoRequiredCallback,
	onFailedValidation,
	onInputErrorCallback,
	onUndefinedCallback,
	onDatabaseAccessCallback,
	notUseDefaultNotification,
}: Props) => {
	let defaultNotificationDisplayed: boolean = false;
	if (!notUseDefaultNotification && responseData.ErrorMessage) {
		defaultNotificationDisplayed = true;
		notification['error']({
			message: responseData.ErrorMessage,
		});
	}

	if (!notUseDefaultNotification && responseData.ResponseErrorDescription) {
		defaultNotificationDisplayed = true;
		notification['error']({
			message: responseData.ResponseErrorDescription,
		});
	}

	let callUndefinedCallback: boolean = true;
	switch (responseData.ResponseStatusCode) {
		case ResponseStatusCode.Success:
			if (onSuccessCallback) {
				callUndefinedCallback = false;
				onSuccessCallback();
			}
			break;

		case ResponseStatusCode.PermissionError:
			if (!notUseDefaultNotification && !defaultNotificationDisplayed) {
				notification['error']({
					message: _(`ResponseStatusCode_${responseData.ResponseStatusCode}`),
				});
			}

			if (onPermissionErrorCallback) {
				callUndefinedCallback = false;
				onPermissionErrorCallback();
			}
			break;

		case ResponseStatusCode.InputError:
			if (onInputErrorCallback) {
				callUndefinedCallback = false;
				onInputErrorCallback();
			}
			break;

		case ResponseStatusCode.FailedValidation:
			if (onFailedValidation) {
				callUndefinedCallback = false;
				onFailedValidation();
			}
			break;

		case ResponseStatusCode.SystemError:
			if (onSystemErrorCallback) {
				callUndefinedCallback = false;
				onSystemErrorCallback();
			}
			break;

		case ResponseStatusCode.AdditionalInfoRequired:
			if (onAdditionalInfoRequiredCallback) {
				callUndefinedCallback = false;
				onAdditionalInfoRequiredCallback();
			}
			break;

		case ResponseStatusCode.ComponentLocked:
			if (onComponentLockedCallback) {
				callUndefinedCallback = false;
				onComponentLockedCallback();
			}
			break;

		case ResponseStatusCode.DatabaseAccessError:
			if (onDatabaseAccessCallback) {
				callUndefinedCallback = false;
				onDatabaseAccessCallback();
			}
			break;

		default:
			if (!notUseDefaultNotification) {
				notification['info']({
					message: _(`ResponseStatusCode_${responseData.ResponseStatusCode}`),
				});
			}

			if (onDefaultEventCallback) {
				callUndefinedCallback = false;
				onDefaultEventCallback();
			}
			break;
	}

	if (callUndefinedCallback) {
		onUndefinedCallback?.();
	}
};

export { NotificationStatus };

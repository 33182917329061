import { FolderFilled } from '@ant-design/icons';
import { AutoComplete, Breadcrumb, Checkbox, Col, Input, InputNumber, Row, TimePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { handleResponse } from '../../../../../../Helper';
import { accountApi, deviceAdminApi } from '../../../../../../api';
import { SelectOptions } from '../../../../../../model/CommonModel';
import { OUPath, OperatorProperty, OperatorPropertyModel, OrganizationalUnit } from '../../../../../../model/OperatorModel';
import { Select } from '../../../../../common';
import { setOperatorAction, setOperatorPropAction, setRootModelPropertyAction } from '../OperatorContext/action';
import { OperatorStoreContext } from '../OperatorContext/context';
import { WindowsCredential } from './components/WindowsCredential/WindowsCredential';
import styles from './operatorPropertyGeneralTab.module.scss';

type OperatorGeneralTabProps = {
	operatorId: number;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	authPath: string;
	domains: string[];
	operatorPropertyModel?: OperatorPropertyModel;
};

const timeFormat: string = 'hh:mm:ss a';
const machineName: string = webServiceMachineName();

const OperatorPropertyGeneralTab: React.FC<OperatorGeneralTabProps> = ({ operatorId, setIsLoading, authPath, domains, operatorPropertyModel }) => {
	const {
		operatorInitialState: {
			rootContext: {
				enableCustomGroupNames,
				entryError: { isSamAccountError, isSamAccountEmpty, isFullNameEmpty, isDomainEmpty },
				foundInAD,
			},
			operator,
		},
		dispatcher,
	} = useContext(OperatorStoreContext);
	const { RestrictShift, AutoLock, AutoLockTime, AbsenteeLimit, AckAlarmThreshold } = operator;
	const [isDomainLoading, setIsDomainLoading] = useState<boolean>(true);
	const [isPropertyLoading, setIsPropertyLoading] = useState<boolean>(true);
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [isDomain, setIsDomain] = useState<boolean>(false);
	const [domainOptions, setDomainOptions] = useState<SelectOptions<string>[]>([]);
	const [selectedDomain, setSelectedDomain] = useState<string>('');
	const [customDomain, setCustomDomain] = useState<string>('');
	const [lastSelectedDomain, setLastSelectedDomain] = useState<string>('');
	const [shiftStartTime, setShiftStartTime] = useState<Dayjs | null>(null);
	const [shiftEndTime, setShiftEndTime] = useState<Dayjs | null>(null);
	const [organizationalUnit, setOrganizationalUnit] = useState<OrganizationalUnit | null>(null);
	const [breadcrumbItems, setBreadcrumbItems] = useState<
		(
			| {
					title: string;
					menu: any;
			  }
			| {
					title: JSX.Element;
			  }
		)[]
	>([{ title: '', menu: undefined }]);

	useEffect(() => {
		if (operatorId === 0) {
			setIsDomainLoading(true);
			const mappedDomains = mapDomains(domains);
			batch(() => {
				setDomainOptions(mappedDomains);
				setSelectedDomain(authPath);
				setLastSelectedDomain(authPath);
				setIsDomainLoading(false);
			});
		} else {
			dispatcher(setOperatorPropAction({ field: 'Id', value: operatorId }));
		}
	}, []);

	useEffect(() => {
		if (domains) {
			const mappedDomains = mapDomains(domains);
			setDomainOptions(mappedDomains);
		}
	}, [domains]);

	useEffect(() => {
		if (selectedDomain?.toLowerCase() === machineName?.toLowerCase()) {
			setIsDomain(false);
		} else {
			setIsDomain(true);
		}
	}, [selectedDomain]);

	useEffect(() => {
		setIsPropertyLoading(true);
		if (operatorId === 0) {
			accountApi.getNewOperatorGuid().then(res => {
				if (!handleResponse(res)) {
					dispatcher(setOperatorPropAction({ field: 'OperatorGuid', value: res.Entity }));
				}
			});
			batch(() => {
				setFields(getOperatorPropertyDefaultFields());
				setIsPropertyLoading(false);
			});
		} else {
			setIsDomainLoading(true);
			if (operatorPropertyModel) {
				const mappedPropertyModel = mapData(operatorPropertyModel);
				batch(() => {
					setFields(mappedPropertyModel);
					setIsPropertyLoading(false);
					setIsDomainLoading(false);
				});
			} else {
				deviceAdminApi
					.getOperatorProperties(operatorId, enableCustomGroupNames)
					.then(res => {
						if (!handleResponse(res)) {
							const mappedData = mapData(res.Entity);
							batch(() => {
								setFields(mappedData);
								setSelectedDomain(mappedData.Domain);
							});
						}
					})
					.finally(() => {
						batch(() => {
							setIsPropertyLoading(false);
							setIsDomainLoading(false);
						});
					});
			}
		}
	}, []);

	useEffect(() => {
		batch(() => {
			dispatcher(setOperatorPropAction({ field: 'DirectorySource', value: selectedDomain }));
		});
	}, [selectedDomain]);

	useEffect(() => {
		if (isDomainLoading && isPropertyLoading) {
			setIsLoading(true);
		} else if (!isDomainLoading && !isPropertyLoading) {
			setIsLoading(false);
		}
	}, [isDomainLoading, isPropertyLoading]);

	const mapDomains = (data: string[]): SelectOptions<string>[] => {
		if (data?.length > 0) {
			return data.map<SelectOptions<string>>((d: string) => ({
				id: d,
				label: d,
				value: d,
			}));
		}
		return [] as SelectOptions<string>[];
	};

	const mapData = (data: OperatorPropertyModel): OperatorProperty => {
		return {
			Id: data.Id,
			Name: data.Name,
			FullName: data.FullName,
			Description: data.Description,
			IsAutoLock: data.AutoLock,
			AutoLockTime: data.AutoLockTime,
			IsRestrictShift: data.RestrictShift,
			ShiftStartTime: dayjs(data.ShiftStart),
			ShiftEndTime: dayjs(data.ShiftEnd),
			AckAlarmThreshold: data.AckAlarmThreshold,
			AbsenteeLimit: data.AbsenteeLimit,
			Domain: data.DirectorySource,
			Flags: {
				IsPasswordExpired: data.Flags.IsPasswordExpired,
				IsCannotChangePassword: data.Flags.IsCannotChangePassword,
				IsPasswordNeverExpires: data.Flags.IsPasswordNeverExpires,
				IsAccountDisabled: data.Flags.IsAccountDisabled,
				IsAccountLockedOut: data.Flags.IsAccountLockedOut,
			},
		};
	};

	const getOperatorPropertyDefaultFields = (): OperatorProperty => {
		return {
			Id: 0,
			Name: '',
			FullName: '',
			Description: '',
			IsAutoLock: false,
			AutoLockTime: 0,
			IsRestrictShift: false,
			ShiftStartTime: dayjs(new Date('1900-01-01').setHours(0, 0, 0)),
			ShiftEndTime: dayjs(new Date('1900-01-01').setHours(0, 0, 0)),
			AckAlarmThreshold: 99,
			AbsenteeLimit: 0,
			Domain: '',
			Flags: {
				IsPasswordExpired: false,
				IsCannotChangePassword: false,
				IsPasswordNeverExpires: false,
				IsAccountDisabled: false,
				IsAccountLockedOut: false,
			},
		};
	};

	const setFields = (data: OperatorProperty) => {
		batch(() => {
			setIsAdmin(data.Id === 1);
			dispatcher(
				setOperatorAction({
					...operator,
					Id: operatorId,
					Name: data.Name,
					FullName: data.FullName,
					Description: data.Description,
					RestrictShift: data.IsRestrictShift,
					ShiftStart: data.ShiftStartTime.toDate(),
					ShiftEnd: data.ShiftEndTime.toDate(),
					AutoLock: data.IsAutoLock,
					AutoLockTime: data.AutoLockTime,
					AckAlarmThreshold: data.AckAlarmThreshold,
					AbsenteeLimit: data.AbsenteeLimit,
					Flags: {
						IsPasswordExpired: data.Flags.IsPasswordExpired,
						IsCannotChangePassword: data.Flags.IsCannotChangePassword,
						IsPasswordNeverExpires: data.Flags.IsPasswordNeverExpires,
						IsAccountDisabled: data.Flags.IsAccountDisabled,
						IsAccountLockedOut: data.Flags.IsAccountLockedOut,
					},
				})
			);
			setShiftStartTime(data.ShiftStartTime);
			setShiftEndTime(data.ShiftEndTime);
			if (operatorId !== 0) {
				setSelectedDomain(data.Domain);
				setLastSelectedDomain(data.Domain);
			}
		});
	};

	const selectBeforeTrustedDomains = (
		<Select
			id='operatorPropertyGeneralDomainSelect'
			value={selectedDomain}
			options={domainOptions}
			onChange={(e: string) => {
				batch(() => {
					setSelectedDomain(e);
					dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
					if (e === machineName && operator.Name?.length > 20) {
						const truncatedName: string = operator.Name.substring(0, 20);
						dispatcher(setOperatorPropAction({ field: 'Name', value: truncatedName }));
					}
				});
			}}
			disabled={!selectedDomain || foundInAD || operatorId > 0}
		/>
	);

	const selectBefore = (
		<AutoComplete
			id='operatorDomainsAutoComplete'
			value={customDomain?.length > 0 ? customDomain : selectedDomain}
			disabled={operatorId > 0 || foundInAD}
			options={domainOptions}
			onChange={(e: string) => {
				batch(() => {
					setCustomDomain('');
					setSelectedDomain(e);
					if (domainOptions?.some(d => d.value === e)) {
						setLastSelectedDomain(e);
					}
					dispatcher(setOperatorPropAction({ field: 'DirectorySource', value: e }));
					dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
				});
			}}
			onBlur={() => {
				if (selectedDomain?.length === 0) {
					setSelectedDomain(lastSelectedDomain);
				}
			}}
		/>
	);

	const generateBreadcrumbMenuItems = (paths: OUPath[]) => {
		if (paths?.length > 1) {
			return paths.map((path, index) => ({
				key: `${path.FullPath}-${index}`,
				label: (
					<a onClick={e => onHandleOrganizationalPathSelection(path)} id={path.FullPath}>
						{path.FormattedPath}
					</a>
				),
			}));
		}
		return undefined;
	};

	const generateBreadcrumbItems = (OUName: string, domain: string, pathNames: string[], paths: OUPath[], isShowMenuItems: boolean) => {
		const changingItems = pathNames
			.filter(level => level !== OUName)
			.map(l => ({
				title: (
					<>
						<span className={styles.breadcrumbText}>{l}</span>{' '}
					</>
				),
				menu: undefined,
			}));

		const updateBreadcrumbItems = [
			{
				title: (
					<>
						<FolderFilled />
						<span className={styles.breadcrumbText}>{domain}</span>
					</>
				),
			},
			...changingItems,
			{
				title: (
					<>
						<span className={styles.breadcrumbText}>{OUName}</span>{' '}
					</>
				),
				menu: isShowMenuItems ? { items: generateBreadcrumbMenuItems(paths) } : undefined,
			},
		];

		setBreadcrumbItems(updateBreadcrumbItems);
	};

	const onHandleOrganizationalPathSelection = (path: OUPath) => {
		setOrganizationalUnit(prev => {
			generateBreadcrumbItems(prev.OrganizationalUnitName, prev.RootDomain, path.LevelNames, prev.Paths, prev.Paths.length > 1);
			return {
				...prev,
				SelectedPath: {
					...prev.SelectedPath,
					FullPath: path.FullPath,
					FormattedPath: path.FormattedPath,
					PathLevel: path.PathLevel,
					LevelNames: {
						...path.LevelNames,
					},
				},
			};
		});
		dispatcher(setRootModelPropertyAction({ field: 'oUManagement', value: path.FullPath }));
	};

	return (
		<div id='operatorGeneralTabContainer' className={styles.containerGeneralTab}>
			<Row key='operatorGeneralTabContainer-1'>
				<Col span={24}>
					<div className={styles.informationSections}>
						<label id='operatorUserNameLabel' htmlFor='operatorUserNameInput' className={styles.labelSection}>
							{_('UserName')}
						</label>
						<div>
							<Input
								id='operatorUserNameInput'
								addonBefore={
									enableCustomGroupNames && operatorId === 0 && !foundInAD
										? selectBefore
										: operatorId > 0 || domainOptions?.length === 1
										? selectedDomain
										: selectBeforeTrustedDomains
								}
								value={operator.Name}
								disabled={operatorId > 0 || isAdmin || foundInAD}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									batch(() => {
										dispatcher(setOperatorPropAction({ field: 'Name', value: e.target.value }));
										dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
									});
								}}
								maxLength={!isDomain ? 20 : 100}
								className={cx('', {
									[styles.error]: isSamAccountError || isSamAccountEmpty || isDomainEmpty,
								})}
							/>
							{isSamAccountEmpty && <p className={styles.errorMessage}>{_('FilterGroupErrorMessage')}</p>}
							{isDomainEmpty && <p className={styles.errorMessage}>{_('SelectDomain')}</p>}
						</div>
					</div>
					{organizationalUnit && (
						<div className={styles.informationSections}>
							<label id='operatorOrganizationalUnitLabel' htmlFor='operatorOrganizationalUnitInput' className={styles.labelSection}>
								{_('OrganizationalUnit')}
							</label>
							<Breadcrumb items={breadcrumbItems} />
						</div>
					)}
					<div className={styles.informationSections}>
						<label id='operatorFullNameLabel' htmlFor='operatorFullNameInput' className={styles.labelSection}>
							{_('FullName')}
						</label>
						<div>
							<Input
								id='operatorFullNameInput'
								value={operator.FullName}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									batch(() => {
										dispatcher(setOperatorPropAction({ field: 'FullName', value: e.target.value }));
										dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
									});
								}}
								maxLength={75}
								className={cx('', {
									[styles.error]: isFullNameEmpty,
								})}
							/>
							{isFullNameEmpty && <p className={styles.errorMessage}>{_('FullNameErrorMessage')}</p>}
						</div>
					</div>
					<div className={styles.informationSections}>
						<label id='operatorDescriptionLabel' htmlFor='operatorDescriptionTextArea' className={styles.labelSection}>
							{_('Description')}
						</label>
						<TextArea
							id='operatorDescriptionTextArea'
							className={styles.textAreaCustom}
							value={operator.Description}
							onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
								batch(() => {
									dispatcher(setOperatorPropAction({ field: 'Description', value: e.target.value }));
									dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
								});
							}}
							maxLength={50}
							rows={2}
						/>
					</div>
				</Col>
			</Row>
			<Row key='operatorGeneralTabContainer-2'>
				<Col span={24}>
					<WindowsCredential isEditMode={operatorId > 0} authPath={authPath} domains={domains} setSelectedDomain={setSelectedDomain} />
				</Col>
			</Row>
			<Row key='operatorGeneralTabContainer-3'>
				<Col span={24}>
					<div className={styles.containerLegend}>
						<div className={styles.fieldsetBorder}>
							<fieldset>
								<legend>
									<Checkbox
										className={styles.fieldSetlegend}
										checked={RestrictShift}
										onChange={(e: CheckboxChangeEvent) => {
											batch(() => {
												dispatcher(setOperatorPropAction({ field: 'RestrictShift', value: e.target.checked }));
												dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
											});
										}}>
										{'Restricted by Shift'}
									</Checkbox>
								</legend>
								<div className={styles.shiftContainer}>
									<div className={styles.columnLabel}>
										<label id='operatorShiftStartLabel' htmlFor='operatorShiftStartTimePicker'>
											{'Shift Starts'}
										</label>
									</div>
									<div className={styles.columntimePicker}>
										<TimePicker
											id='operatorShiftStartTimePicker'
											disabled={!RestrictShift}
											format={timeFormat}
											value={shiftStartTime}
											onChange={(value: Dayjs) => {
												batch(() => {
													setShiftStartTime(value);
													dispatcher(setOperatorPropAction({ field: 'ShiftStart', value: value.toDate() }));
													dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
												});
											}}
										/>
									</div>
									<div className={styles.columnLabel}>
										<label id='operatorShiftEndLabel' htmlFor='operatorShiftEndTimePicker'>
											{'Shift End'}
										</label>
									</div>
									<div className={styles.columntimePicker}>
										<TimePicker
											id='operatorShiftEndTimePicker'
											disabled={!RestrictShift}
											format={timeFormat}
											value={shiftEndTime}
											onChange={(value: Dayjs) => {
												batch(() => {
													setShiftEndTime(value);
													dispatcher(setOperatorPropAction({ field: 'ShiftEnd', value: value.toDate() }));
													dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
												});
											}}
										/>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
				</Col>
			</Row>
			<Row key='operatorGeneralTabContainer-4' style={{ marginTop: '10px' }}>
				<Col span={10}>
					<Row key='operatorGeneralTabContainer-4.1'>
						<Checkbox
							id='autoLockCb'
							className={styles.firstRowText}
							checked={AutoLock}
							onChange={(e: CheckboxChangeEvent) => {
								batch(() => {
									dispatcher(setOperatorPropAction({ field: 'AutoLock', value: e.target.checked }));
									dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
								});
							}}>
							{_('AutoLockWorkstation')}
						</Checkbox>
					</Row>
					<Row key='operatorGeneralTabContainer-4.2'>
						<label id='disableOperatorLbl' className={styles.nthRowTextIndented}>
							{_('DisableOperatorAfter')}
						</label>
					</Row>
					<Row key='operatorGeneralTabContainer-4.3'>
						<label id='ackAlarmsLevelLbl' className={styles.nthRowTextIndented}>
							{_('AckAlarmLevel')}
						</label>
					</Row>
				</Col>
				<Col span={4}>
					<Row key='operatorGeneralTabContainer-4.4'>
						<InputNumber
							id='autoLockInput'
							controls
							min={0}
							max={60}
							value={AutoLockTime}
							disabled={!AutoLock}
							onChange={(e: number) => {
								batch(() => {
									dispatcher(setOperatorPropAction({ field: 'AutoLockTime', value: e }));
									dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
								});
							}}
						/>
					</Row>
					<Row key='operatorGeneralTabContainer-4.5'>
						<InputNumber
							id='disableOperatorInput'
							className={styles.nthRowBox}
							disabled={isAdmin}
							controls
							min={0}
							max={365}
							value={AbsenteeLimit}
							onChange={(e: number) => {
								batch(() => {
									dispatcher(setOperatorPropAction({ field: 'AbsenteeLimit', value: e }));
									dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
								});
							}}
						/>
					</Row>
					<Row key='operatorGeneralTabContainer-4.6'>
						<InputNumber
							id='ackAlarmsLevelInput'
							className={styles.nthRowBox}
							controls
							min={0}
							max={99}
							value={isAdmin ? 0 : AckAlarmThreshold}
							onChange={(e: number) => {
								batch(() => {
									dispatcher(setOperatorPropAction({ field: 'AckAlarmThreshold', value: e }));
									dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
								});
							}}
						/>
					</Row>
				</Col>
				<Col span={10}>
					<Row key='operatorGeneralTabContainer-4.7'>
						<label id='autoLockUomLbl' className={styles.firstRowText}>
							{_('MinutesOfIdleTime')}
						</label>
					</Row>
					<Row key='operatorGeneralTabContainer-4.8'>
						<label id='disableOperatorUomLbl' className={styles.nthRowText}>
							{_('DaysOfInActivity')}
						</label>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export { OperatorPropertyGeneralTab };

const { JSONPath } = require('jsonpath-plus');
import { notification, Table } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { UploadProps } from 'antd/lib';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { INTERNAL_SELECTION_ITEM } from 'antd/lib/table/hooks/useSelection';
import { SelectionItem, SorterResult } from 'antd/lib/table/interface';
import { TransferDirection } from 'antd/lib/transfer';
import { RcFile } from 'antd/lib/upload';
import JSEncrypt from 'jsencrypt';
import { parseBigInt } from 'jsencrypt/lib/lib/jsbn/jsbn';
import React, { CSSProperties, ReactNode, useLayoutEffect, useState } from 'react';
import { useDispatch as _useDispatch, useSelector as _useSelector, shallowEqual } from 'react-redux';
import { deviceAdminApi } from './api';
import { SelectOption } from './components/common';
import { SecuredComponents, User } from './model/AccountModel';
import {
	ChartData,
	CodeProperties,
	DefaultColumns,
	ImageResolution,
	PaginationSetting,
	PreviewBadgeResponse,
	ResponseObject,
	ResponseStatusCode,
	SortDirections,
	Tuple,
} from './model/CommonModel';
import {
	CheckComponentLock,
	DeviceObjectType,
	IPAddressOctets,
	NetworkInfo,
	ObjectTypes,
	SelectedRowKeyPagination,
	SelectTimeZone,
	TimeZoneInfo,
	TimeZoneType,
} from './model/DeviceAdminModel';
import { ImageWithCredentialId, PrintMultipleBadgeInfo } from './model/EnrollmentModel';
import { CustomFilterGroup, EventFilter, EventFilterPage, EventFilterTypes, VelocityEventModel, VelocityEventType } from './model/EventModel';
import { Logger } from './model/LoggingModel';
import { SubscriptionType } from './model/WebSocketModel';

///generics types for columns
type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnsProps<T> = { editable?: boolean; dataIndex: string } & ColumnsType<T>[number];
export type ScrollType = Exclude<EditableTableProps['scroll'], undefined>;

type PrintBadgeProps = {
	image: PreviewBadgeResponse;
	credentialId: number;
	restImagesWithCredentialId?: ImageWithCredentialId[];
	callback?: (badgesToPrint: PrintMultipleBadgeInfo) => void;
	logPrintEvent?: (credentialId: number, personId?: number) => void;
	personId?: number;
};

/**
 * @deprecated Use `useStoreDispatch` instead, it for some reason it doesn't work use this one as last resource.
 */
export function useDispatch<T>() {
	const dispatch = _useDispatch();
	return (event: T) => {
		dispatch(event);
	};
}

export function useSelector<TStoreType, TReturnType>(fn: (store: TStoreType) => TReturnType): TReturnType {
	return _useSelector(fn, shallowEqual);
}

export function printBadge({ image, credentialId, restImagesWithCredentialId, callback, logPrintEvent, personId }: PrintBadgeProps): void {
	const name = getGuid();
	let myWindow: Window = window.open('', name, 'height=400,width=1000');
	if (myWindow) {
		let orientation = image.IsLandscape ? 'landscape' : 'portrait';
		myWindow.document.write('<html>');
		myWindow.document.write('<head>');
		myWindow.document.write('<style> ');
		myWindow.document.write(`@page { size: auto ${orientation}; margin: 0mm; } `);
		myWindow.document.write('* { margin: 0; padding: 0; border: 0; } ');
		myWindow.document.write('html, body { height: auto; border-left: 1px groove transparent; } ');
		myWindow.document.write('@media print { .noprint { visibility: hidden; } .print { visibility: visible; } } ');
		//myWindow.document.write('@media print { html * { height: auto; border: 1px solid black; } .noprint { visibility: hidden; } .print {visibility: visible;} } ');
		myWindow.document.write('</style>');
		myWindow.document.write('</head>');
		myWindow.document.write('<body class="noprint" style="margin: 0px !important;">');

		const imageSizeStyle = `width: ${image.Width}px !important; height: ${image.Height}px !important;`;
		for (let i = 0; i < image.PhotoCredentialsBase64.length; i++) {
			let doubleSided = image.PhotoCredentialsBase64.length > 1 && i == 0;
			if (doubleSided) {
				myWindow.document.write("<div class='print' style='page-break-after: always;'>");
			}
			myWindow.document.write(
				`<img class='print' src='${image.PhotoCredentialsBase64[i]}' style='${imageSizeStyle} position: relative; overflow: auto; left: 0; right: 0; top: 0; bottom: 0; margin: 0px !important; display: inline-block !important;' />`
			);
			if (doubleSided) {
				myWindow.document.write('</div>');
			}
		}

		myWindow.document.write('</body>');
		myWindow.document.write('</html>');
		myWindow.document.close(); // necessary for IE >= 10
		myWindow.focus(); // necessary for IE >= 10
		// necessary for EdgeHTML
		const browser = (function (agent) {
			switch (true) {
				case agent.indexOf('edge') > -1:
					return 'edge';
				default:
					return 'other';
			}
		})(window.navigator.userAgent.toLowerCase());

		if (browser === 'edge') {
			myWindow.print();
		} else {
			myWindow.onload = function () {
				myWindow.print();
			};
		}

		setTimeout(function () {
			myWindow.close();
		}, 500);

		const timerAfterClosed = setInterval(function () {
			if (myWindow.closed) {
				clearInterval(timerAfterClosed);
				if (credentialId && logPrintEvent) {
					logPrintEvent(credentialId, personId);
				}
				if (callback && restImagesWithCredentialId && restImagesWithCredentialId.length > 0) {
					const { CredentialId, Image } = restImagesWithCredentialId.pop();
					callback({ Image, CredentialId, RestImagesWithCredentialId: restImagesWithCredentialId, Success: true });
				}
			}
		}, 1000);
	} else if (callback) {
		callback({ Image: image, CredentialId: credentialId, RestImagesWithCredentialId: restImagesWithCredentialId, Success: false });
	}
}

export function getDefaultFontFamily(): string {
	return `'Segoe UI', 'Helvetica Neue'`;
}

export function getPieChartConfig(data: ChartData[]): any {
	const config = {
		data: data === undefined || data === null ? [] : data,
		color: data === undefined || data === null ? [] : data.map(x => x.color),
		colorField: 'type',
		forceFit: true,
		height: 297,
		radius: 1,
		angleField: 'value',
		label: {
			visible: true,
			type: 'spider',
			autoRotate: false,
			style: {
				fontSize: 14,
				fontFamily: getDefaultFontFamily(),
				fill: 'black',
			},
		},
		legend: {
			visible: true,
			position: 'right' as const,
			content: {
				style: {
					fontSize: 14,
				},
			},
		},
		padding: 70,
	};

	return config;
}

export function numberWithCommas(number: number): string {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function applyCurrentEventFilters(
	updatedDataState: VelocityEventModel[],
	currentEventFilters: EventFilter[],
	currentCustomFilterGroups: CustomFilterGroup[]
): VelocityEventModel[] {
	let filter: string = '';
	let retValue: VelocityEventModel[] = [...updatedDataState];
	if (currentEventFilters !== undefined && currentEventFilters.length) {
		let whereClause: string[] = [];
		currentEventFilters.forEach(f => {
			if (f.VelocityEventType !== VelocityEventType.Unknown) {
				//whereClause.push(`(@.EventId >= ${f.Min} && @.EventId <= ${f.Max} && @.EventType === ${f.VelocityEventType})`);
				whereClause.push(`(@.EventType === ${f.VelocityEventType})`);
			}
		});

		filter = whereClause.join(' || ');
	}

	if (currentCustomFilterGroups !== undefined && currentCustomFilterGroups.length) {
		let whereClause: string[] = [];
		currentCustomFilterGroups.forEach(f => {
			whereClause.push(f.EventFilterClause);
		});

		if (filter) {
			filter = `(${filter}) && (${whereClause.join(' || ')})`;
		} else {
			filter = whereClause.join(' || ');
		}
	}

	if (filter) {
		retValue = JSONPath({ path: `$[?(${filter})]`, json: updatedDataState });
	}

	return retValue;
}

export function getEventFilter(eventType: VelocityEventType): string {
	let type: EventFilterTypes = EventFilterTypes.Software;

	switch (eventType) {
		case VelocityEventType.Alarm:
			type = EventFilterTypes.Alarm;
			break;

		case VelocityEventType.External:
			type = EventFilterTypes.External;
			break;

		case VelocityEventType.Internal:
			type = EventFilterTypes.Internal;
			break;

		case VelocityEventType.Miscellaneous:
			type = EventFilterTypes.Misc;
			break;

		case VelocityEventType.Port:
			type = EventFilterTypes.Ports;
			break;

		case VelocityEventType.EdgeProgramming:
			type = EventFilterTypes.Programming;
			break;

		case VelocityEventType.Software:
			type = EventFilterTypes.Software;
			break;

		case VelocityEventType.Transaction:
			type = EventFilterTypes.Access;
			break;

		case VelocityEventType.XBOX:
			type = EventFilterTypes.Xbox;
			break;
	}

	return _(EventFilterTypes[type]);
}

export function getEventFilterOptions(): Tuple[] {
	let retValue: Tuple[] = [];
	for (const value in EventFilterTypes) {
		const numberValue: number = Number(value);
		if (isNaN(numberValue) || numberValue === 0) {
			continue;
		}

		retValue.push({ Item1: numberValue, Item2: _(EventFilterTypes[value]) });
	}

	return retValue;
}

export function buildColumn(title: string | ReactNode, dataIndex: string, width?: string, align = 'center'): any {
	const column = {
		title: title,
		dataIndex: dataIndex,
		sortDirections: ['descend', 'ascend'],
		align,
	};

	return width
		? {
				...column,
				width,
		  }
		: column;
}

export function buildActionColumn(disabled: boolean): any {
	return buildColumn('', 'Action', disabled ? '150px' : '95px', 'start');
}

export function getFullVelocityUrl(): string {
	return `${location.origin}${getBaseUrl()}`;
}

export function getWebSocketSubscription(subscriptionType: SubscriptionType): WebSocket {
	const headerInfo = document.getElementById('HeaderInformation') as HTMLInputElement;
	if (headerInfo) {
		const webSocketEndpoint = `${getFullVelocityUrl()
			.replace('https://', 'wss://')
			.replace('http://', 'ws://')}util/VelocityWebSocketHttpHandler.ashx?subscriptionType=${subscriptionType}&headerInfo=${
			headerInfo.value
		}&pageId=${getPageId()}`;

		return new WebSocket(webSocketEndpoint);
	}

	return undefined;
}

export function canAddNewTimeZones(): boolean {
	const user: User = getUser();
	const standardComponentPermission = User.getComponentPermission(user, SecuredComponents.Standard_Time_Zone);
	const masterComponentPermission = User.getComponentPermission(user, SecuredComponents.Master_Time_Zone);
	const grandComponentPermission = User.getComponentPermission(user, SecuredComponents.Grand_Master_Time_Zone);

	return standardComponentPermission.canAdd || masterComponentPermission.canAdd || grandComponentPermission.canAdd;
}

export const canSaveControllerControlZones = (): boolean => {
	const user: User = getUser();
	return User.getComponentPermission(user, SecuredComponents.Control_Zone_Editor).canUpdate;
};

export const doorGroupLockedValidation = async (deviceId: number, isMasterDoorGroup: boolean, lockComponent?: boolean): Promise<boolean> => {
	let isLocked: boolean = false;
	const componentLockObject: CheckComponentLock = {
		ObjectId: deviceId,
		SecuredComponent: isMasterDoorGroup ? SecuredComponents.Master_Door_Group : SecuredComponents.Door_Groups,
		LockComponent: lockComponent === true,
	};

	try {
		await deviceAdminApi.isComponentLocked(componentLockObject).then(res => {
			isLocked = handleResponse(res);
		});
	} catch (error) {
		Logger.writeErrorLog(error);
		throw error;
	} finally {
		return isLocked;
	}
};

export const statusViewerGroupLockedValidation = async (lockComponent: boolean, deviceId: number): Promise<boolean> => {
	let isLocked: boolean = false;
	const componentLockObject: CheckComponentLock = {
		ObjectId: deviceId,
		SecuredComponent: SecuredComponents.Status_Viewer,
		LockComponent: lockComponent === true,
	};

	try {
		await deviceAdminApi.isComponentLocked(componentLockObject).then(res => {
			isLocked = handleResponse(res);
		});
	} catch (error) {
		Logger.writeErrorLog(error);
		throw error;
	} finally {
		return isLocked;
	}
};

export const timeZoneLockedValidation = async (timeZoneType: TimeZoneType, lockComponent?: boolean): Promise<boolean> => {
	let isLocked: boolean = false;
	let componentLockObject: Partial<CheckComponentLock> = {
		ObjectId: 1,
		LockComponent: lockComponent === true,
	};

	try {
		switch (timeZoneType) {
			case TimeZoneType.Standard:
				componentLockObject.SecuredComponent = SecuredComponents.Standard_Time_Zone;
				break;

			case TimeZoneType.Master:
				componentLockObject.SecuredComponent = SecuredComponents.Master_Time_Zone;
				break;

			case TimeZoneType.Grand:
				componentLockObject.SecuredComponent = SecuredComponents.Grand_Master_Time_Zone;
				break;
		}

		await deviceAdminApi.isComponentLocked(componentLockObject as CheckComponentLock).then(res => {
			isLocked = handleResponse(res);
		});
	} catch (error) {
		Logger.writeErrorLog(error);
		throw error;
	} finally {
		return isLocked;
	}
};

export const searchLockedValidation = async () => {
	let isLocked: boolean = false;
	const componentLockObject: CheckComponentLock = {
		SecuredComponent: SecuredComponents.SNIB_Search,
		ObjectId: 1,
		LockComponent: true,
	};

	try {
		const res = await deviceAdminApi.isComponentLocked(componentLockObject);
		isLocked = handleResponse(res);
	} catch (error) {
		Logger.writeErrorLog(error);
		throw error;
	} finally {
		return isLocked;
	}
};

export const lockedValidation = async (deviceType: DeviceObjectType, deviceId: number, lockComponent?: boolean): Promise<boolean> => {
	if (deviceId < 1) {
		return;
	}

	let isLocked: boolean = false;
	let componentLockObject: Partial<CheckComponentLock> = {
		ObjectId: deviceId,
		LockComponent: lockComponent === true,
	};

	try {
		switch (deviceType) {
			case DeviceObjectType.CommandSets:
				componentLockObject.SecuredComponent = SecuredComponents.Command_Set;
				break;

			case DeviceObjectType.CredentialTemplates:
			case DeviceObjectType.EnrollmentCredential:
				componentLockObject.SecuredComponent = SecuredComponents.Enrollment_Credential;
				break;

			case DeviceObjectType.ReaderControlGroup:
				componentLockObject.SecuredComponent = SecuredComponents.Reader_Control_Group;
				break;

			case DeviceObjectType.Port:
				componentLockObject.SecuredComponent = SecuredComponents.Ports;
				break;

			case DeviceObjectType.Xbox:
				componentLockObject.SecuredComponent = SecuredComponents.XBox;
				break;

			case DeviceObjectType.ExpansionInputs:
				componentLockObject.SecuredComponent = SecuredComponents.Expansion_Input;
				break;

			case DeviceObjectType.ExpansionRelays:
				componentLockObject.SecuredComponent = SecuredComponents.Expansion_Relay;
				break;

			case DeviceObjectType.Input:
				componentLockObject.SecuredComponent = SecuredComponents.Input;
				break;

			case DeviceObjectType.Relay:
				componentLockObject.SecuredComponent = SecuredComponents.Relay;
				break;

			case DeviceObjectType.Holidays:
				componentLockObject.SecuredComponent = SecuredComponents.Holiday;
				break;

			case DeviceObjectType.Reader:
				componentLockObject.SecuredComponent = SecuredComponents.Reader;
				break;

			case DeviceObjectType.Door:
				componentLockObject.SecuredComponent = SecuredComponents.Door;
				break;

			case DeviceObjectType.Controller:
				componentLockObject.SecuredComponent = SecuredComponents.Controller;
				break;

			case DeviceObjectType.GlobalIOGroup:
				componentLockObject.SecuredComponent = SecuredComponents.Global_IO_Group;
				break;

			case DeviceObjectType.Roles:
				componentLockObject.SecuredComponent = SecuredComponents.Roles;
				break;

			case DeviceObjectType.Operators:
				componentLockObject.SecuredComponent = SecuredComponents.Operator;
				break;
		}

		await deviceAdminApi.isComponentLocked(componentLockObject as CheckComponentLock).then(res => {
			isLocked = handleResponse(res);
		});
	} catch (error) {
		Logger.writeErrorLog(error);
		throw error;
	} finally {
		return isLocked;
	}
};

export const unlockSNIBSearchComponent = () => {
	deviceAdminApi.cleanUpMultiCastUtilityWrapper();
	deviceAdminApi.unlockComponentDeviceAdmin(1, SecuredComponents.SNIB_Search);
};

export const unlockHandleBeforeUnload = (objectId: number, deviceObjectType: DeviceObjectType, isMasterDoorGroup?: boolean) => {
	if (objectId < 1) {
		return;
	}

	switch (deviceObjectType) {
		case DeviceObjectType.Port:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Ports);
			break;

		case DeviceObjectType.Xbox:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.XBox);
			break;

		case DeviceObjectType.Controller:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Controller);
			break;

		case DeviceObjectType.Door:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Door);
			break;

		case DeviceObjectType.DoorGroup:
			deviceAdminApi.unlockComponentDeviceAdmin(
				objectId,
				isMasterDoorGroup === true ? SecuredComponents.Master_Door_Group : SecuredComponents.Door_Groups
			);
			break;

		case DeviceObjectType.ReaderControlGroup:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Reader_Control_Group);
			break;

		case DeviceObjectType.ExpansionInputs:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Expansion_Input);
			break;

		case DeviceObjectType.ExpansionRelays:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Expansion_Relay);
			break;

		case DeviceObjectType.Input:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Input);
			break;

		case DeviceObjectType.Relay:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Relay);
			break;

		case DeviceObjectType.Reader:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Reader);
			break;

		case DeviceObjectType.Holidays:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Holiday);
			break;

		case DeviceObjectType.GlobalIOGroup:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Global_IO_Group);
			break;

		case DeviceObjectType.CredentialTemplates:
		case DeviceObjectType.EnrollmentCredential:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Enrollment_Credential);
			break;
		case DeviceObjectType.Roles:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Roles);
			break;
		case DeviceObjectType.Operators:
			deviceAdminApi.unlockComponentDeviceAdmin(objectId, SecuredComponents.Operator);
			break;
	}
};

export const handleResponse = (response: ResponseObject): boolean => {
	if (response.ResponseStatusCode === ResponseStatusCode.ComponentLocked) {
		notification['info']({
			message: _(response.ResponseErrorDescription),
		});
		return true;
	} else if (response.ResponseStatusCode === ResponseStatusCode.PermissionError) {
		notification['error']({
			message: response.ErrorMessage ? response.ErrorMessage : response.ResponseErrorDescription,
		});
		return true;
	} else if (response.ResponseStatusCode === ResponseStatusCode.SystemError) {
		notification['error']({
			message: response.ErrorMessage
				? response.ErrorMessage
				: response.ResponseErrorDescription
				? response.ResponseErrorDescription
				: _(`ResponseStatusCode_${response.ResponseStatusCode}`),
		});
		return true;
	} else if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
		notification['error']({
			message: response.ErrorMessage ? response.ErrorMessage : response.ResponseErrorDescription,
		});
		return true;
	} else if (response.ResponseStatusCode !== ResponseStatusCode.Success) {
		notification['error']({
			message: _(`ResponseStatusCode_${response.ResponseStatusCode}`),
		});
		return true;
	}

	return false;
};

export const getDefaultTableSelectionConfig = (disabled: boolean): INTERNAL_SELECTION_ITEM[] | undefined => {
	return disabled ? undefined : [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE];
};

export const getDefaultTableSelectionConfigPagination = (
	disabled: boolean,
	handleSelectAll: () => void,
	handleSelectInvert: () => void
): INTERNAL_SELECTION_ITEM[] | undefined => {
	return disabled
		? undefined
		: [
				{ key: 'SELECTION_ALL', text: 'Select all data', onSelect: handleSelectAll } as SelectionItem,
				{ key: 'SELECTION_INVERT', text: 'Invert current page', onSelect: handleSelectInvert } as SelectionItem,
				Table.SELECTION_NONE,
		  ];
};

export const getDefaultTransferTableSelection = (
	direction: TransferDirection,
	onItemSelectAll: (dataSource: string[], checkAll: boolean) => void,
	handleSelectAll: (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => void,
	handleSelectInvert: (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => void
): INTERNAL_SELECTION_ITEM[] | undefined => {
	return [
		{ key: 'SELECTION_ALL', text: 'Select all data', onSelect: () => handleSelectAll(direction, onItemSelectAll) } as SelectionItem,
		{ key: 'SELECTION_INVERT', text: 'Invert current page', onSelect: () => handleSelectInvert(direction, onItemSelectAll) } as SelectionItem,
		Table.SELECTION_NONE,
	];
};

export const getPaginatedDataBy = <T extends {}>(data: T[], pageNumber: number, pageSize: number): T[] => {
	const startRow: number = (pageNumber - 1) * pageSize;
	const endRow: number = pageNumber * pageSize;

	return data.slice(startRow, endRow);
};

export const getDefaultTablePaginationConfig = (
	disabled?: boolean,
	onChange?: (page: number, pageSize?: number) => void,
	currentPage?: number,
	pageSize?: number,
	total?: number,
	showSizeChanger?: boolean,
	selectedRowKeys?: React.Key[]
): TablePaginationConfig => {
	const itemsSelectedCount: number = selectedRowKeys ? selectedRowKeys.length : 0;
	const defaultCurrentPage: number = 1;

	const tablePaginationConfig: TablePaginationConfig = {
		defaultPageSize: 25,
		pageSizeOptions: ['10', '25', '50', '100'],
		showSizeChanger: showSizeChanger ?? true,
		disabled,
		showTotal: value => {
			let header: string;
			if (itemsSelectedCount) {
				header = `${itemsSelectedCount.toLocaleString('en-US')}/${value.toLocaleString('en-US')} ${_('Items').toLowerCase()}`;
			} else {
				header = `${value.toLocaleString('en-US')} ${_('Items').toLowerCase()}`;
			}

			return header;
		},
		pageSize,
		total,
		defaultCurrent: defaultCurrentPage,
	};

	return onChange
		? {
				...tablePaginationConfig,
				current: currentPage ?? defaultCurrentPage,
				onChange,
		  }
		: tablePaginationConfig;
};

export const getDefaultPaginationSettings = (): PaginationSetting => {
	return {
		PageSize: 25,
		PageNumber: 1,
		SortDirection: SortDirections.None,
		SortField: '',
		SearchedValue: '',
	};
};

export const removeCommasAndPercentSign = (value: string): string => {
	return value?.replace(/[,%]/g, '');
};

export function noneDecimalFormatter(value: React.ReactText) {
	return `${value}`.replace(/[^0-9]+/g, '');
}

export const numericPositiveAndNegativeNonDecimalFormatter = (value: string) => {
	return value.replace(/[^\d\s-]+/g, '');
};

export const removeAllNonNumberCharacters = (value: string): string => value?.replace(/\D/g, '');

export const numericAndCommaMinusSignFormatter = (value: string) => {
	return value.replace(/[^\d\s,-]+/g, '');
};

export function getPageId() {
	const input = document.getElementById('PageId') as HTMLInputElement;

	return input.value;
}

export function getUserName() {
	const input = document.getElementById('UserName') as HTMLInputElement;

	return input?.value;
}

/**
 *
 * @param genericTimeZone current Time Zone Id
 * @param timeZoneList List of generic Time Zones including permissions
 * @returns True if operator does not have access to, False if operator can select Time Zone
 */
export const getUnpermittedTimeZoneValue = (timeZoneList: SelectTimeZone[], genericTimeZone: number): boolean => {
	const timeZoneIndex: number = timeZoneList.findIndex(x => x.GenericId === genericTimeZone);
	let isUnpermitted: boolean = false;
	if (~timeZoneIndex) {
		isUnpermitted = timeZoneList[timeZoneIndex].Unpermitted;
	}

	return isUnpermitted;
};

/**
 *
 * @param selectedTimeZones Array with Generic Time Zones Id that are selected and might be unpermitted
 * @param timeZonesList Generic Time Zones List with permissions
 * @returns SelectOption list with filtered timeZoneList and keeps selectedTimeZones elements
 */
export const getGenericTimeZonesByIds = (selectedTimeZones: number[], timeZonesList: SelectTimeZone[]): SelectOption => {
	const allowedTimeZones: SelectTimeZone[] = timeZonesList.filter(x => selectedTimeZones.includes(x.GenericId) || !x.Unpermitted);
	const timeZoneListOptions: SelectOption = allowedTimeZones.map(x => ({ label: x.Name, value: x.GenericId }));

	return timeZoneListOptions;
};

/**
 *
 * @param currentTimeZoneId Generic Time Zone Id selected in dropdown
 * @param timeZonesList Generic Time Zones List with permissions
 * @returns Unpermitted value of current Time Zone to disable dropdown and mapped list of Time Zone Options filtered by Unpermitted
 */
export const getTimeZoneInfoByList = (currentTimeZoneId: number, timeZonesList: SelectTimeZone[]): TimeZoneInfo => {
	let isUnpermitted: boolean = false;
	const allowedTimeZones: SelectTimeZone[] = timeZonesList.filter(x => {
		if (x.GenericId === currentTimeZoneId) {
			isUnpermitted = x.Unpermitted;
			return true;
		}
		return !x.Unpermitted;
	});
	const timeZoneListOptions: SelectOption = allowedTimeZones.map(x => ({ label: x.Name, value: x.GenericId }));
	let timeZoneInfo: TimeZoneInfo = { IsUnpermitted: isUnpermitted, TimeZoneList: timeZoneListOptions };

	return timeZoneInfo;
};

/**
 *
 * @param array to be converted to object
 * @param key key as object unique identifier
 * @returns object typed
 */
export const convertArrayToObject = <T extends {}>(array: T[], key: Extract<keyof T, string>) => {
	const initialValue = {};

	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key.toString()]]: item,
		};
	}, initialValue);
};

/**
 *
 * @param object to be converted to an array
 * @returns array typed
 */
export const convertObjectToArray = <T extends {}>(object: T) => {
	const values = (Object.keys(object) as Array<keyof typeof object>).reduce((accumulator, current) => {
		accumulator.push(object[current]);
		return accumulator;
	}, [] as (typeof object)[keyof typeof object][]);
	return values;
};

/**
 * @param ipAddress will be validated if the format correspondent to a IPv4 address using a RegEx
 * @returns boolean value if it's valid or not
 */
export const validateIPv4AddressFormat = (ipv4Address: string): boolean => {
	const validation =
		/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
			ipv4Address
		);
	return validation && ipv4Address !== '';
};

/**
 * @param ipAddress will be validated if the format correspondent to a IPv6 address using a RegEx
 * @returns boolean value if it's valid or not
 */
export const validateIPv6AddressFormat = (ipv6Address: string): boolean => {
	const validation =
		/(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/.test(
			ipv6Address
		);
	return validation && ipv6Address !== '';
};

/**
 * @param a,b will be ordered by name
 * @returns result of the comparison
 */
export const orderByName = (a: string, b: string) => a.localeCompare(b);

/**
 * @param selectedRowKeys new selected row keys
 * @param selectedRowKeysPagination state of selected row keys of pagination
 * @param currentPage the current page number
 * @param setIsItemOnSamePage callback to update the state
 * @returns the current state of selected row keys for pagination
 */
export const getSelectedRowKeysPagination = (
	selectedRowKeys: React.Key[],
	selectedRowKeysPagination: SelectedRowKeyPagination[],
	currentPage: number,
	setIsItemOnSamePage: (value: boolean) => void
) => {
	if (!selectedRowKeys.length) {
		return [] as SelectedRowKeyPagination[];
	}

	const newSelectedKeysPagination: SelectedRowKeyPagination[] = selectedRowKeys
		.filter(newSelectedKey => !(selectedRowKeysPagination.findIndex(selectedKey => selectedKey.Id === newSelectedKey) >= 0))
		.map<SelectedRowKeyPagination>(selectedKey => ({
			Id: selectedKey,
			PaginationPage: currentPage,
		}));

	const selectedKeysPagination: SelectedRowKeyPagination[] = selectedRowKeysPagination
		.filter(selectedKey => selectedRowKeys.findIndex(newSelectedKey => newSelectedKey === selectedKey.Id) >= 0)
		.map<SelectedRowKeyPagination>(selectedKey => ({
			...selectedKey,
		}));

	const cloneSelectedKeysPagination: SelectedRowKeyPagination[] = [...newSelectedKeysPagination, ...selectedKeysPagination];

	if (newSelectedKeysPagination.length === 1) {
		setIsItemOnSamePage(true);
	} else {
		const selectedKey = cloneSelectedKeysPagination.find(selectedKey => selectedKey.Id === selectedRowKeys[0]);
		setIsItemOnSamePage(selectedKey?.PaginationPage === currentPage);
	}
	return [...cloneSelectedKeysPagination];
};

/**
 *
 * @param tableKeys array of keys
 * @param selectedItemKey selected device key
 * @param pageSize size of page
 * @returns page number of selected device
 */
export const getPageNumberSelectedItem = (tableKeys: React.Key[], selectedItemKey: string, pageSize: number): number => {
	const indexSelectedItem = tableKeys.findIndex(x => x === selectedItemKey);
	const pageSelectedItem = Math.ceil((indexSelectedItem + 1) / pageSize);
	return pageSelectedItem;
};

/**
 *
 * @param selectedDevicePage page of selected device
 * @param currentPage current page
 * @param setSelectedRow function to be executed to select the row of device
 * @param setCurrentPage function to update the current page value
 * @param setIsItemOnSamePage function to check if the device is in the same page
 */
export const handlePageChanged = (
	selectedDevicePage: number,
	currentPage: number,
	setSelectedRow: () => void,
	setCurrentPage: (value: number) => void,
	setIsItemOnSamePage: (value: boolean) => void
) => {
	if (selectedDevicePage !== currentPage) {
		setSelectedRow();
		setCurrentPage(selectedDevicePage);
		setIsItemOnSamePage(true);
	} else {
		setIsItemOnSamePage(true);
	}
};
/**
 *
 * @param data array to be sort
 * @param direction order of sorting
 * @param field field to be sort
 * @returns sorted array
 */
export const sortArray = <T extends {}>(data: T[], direction: SortDirections, field: string): T[] => {
	if (direction === SortDirections.None) {
		return [];
	}
	const payload: T[] =
		direction === SortDirections.Ascend
			? data.sort((a, b) => a[field]?.toString()?.localeCompare(b[field]?.toString()))
			: data.sort((a, b) => b[field]?.toString()?.localeCompare(a[field]?.toString()));

	return payload;
};

/**
 * (Invert current Page Pagination)
 *@param dataToInvert array to invert data 
 @param selectedRowKeys current selected row keys
 @Returns array inverted
 */
export const invertSelectedRowKeys = (dataToInvert: React.Key[], selectedRowKeys: React.Key[]): React.Key[] => {
	const dataInverted = dataToInvert.reduce<React.Key[]>((result, key) => {
		if (!selectedRowKeys.includes(key)) {
			result.push(key);
		}
		return result;
	}, []);

	const selectedRowKeysInverted = selectedRowKeys.filter(selectedKey => dataToInvert.findIndex(key => key === selectedKey) === -1);

	return [...selectedRowKeysInverted, ...dataInverted];
};

/**
 *@param array1 
 @param array2
 @returns array with unique values
 */
export const getUniqueValuesArray = <T extends {}>(array1: T[], array2: T[]): T[] => {
	return [...Array.from(new Set<T>([...array1, ...array2]))];
};

/**
 * @param inputValue text from search input
 * @param option option type of select
 * @returns true if match and add to filtered set, false if not match and exclude from filtered set.
 */
export const filterOption = (inputValue: string, option) => option.children.toLowerCase().includes(inputValue.toLowerCase());

/**
 * Custom hook that handles the table change of page number, page size, sort and search values
 * @param clearSelection text from search input
 * @param filters internal table filter model, includes field name and values of column search
 * @param handleChangePagination function to handle change of the pagination
 * @param isSearchPerformed indicates an active search
 * @param pagination internal table pagination
 * @param shouldResetSearchColumn indicates the reset button click in the search column
 * @param sorter internal table sort model, includes field key and sort direction
 * @param tablePaginationSetting current pagination settings of the table (state)
 * @returns Object which includes new page number, page size, should update
 */

type OnChangeTableLogicProps = {
	clearSelection: () => void;
	filters: { Name?: string[] };
	handleChangePagination: (current: number, pageSize: number) => void;
	isSearchPerformed: React.MutableRefObject<boolean>;
	pagination: TablePaginationConfig;
	shouldResetSearchColumn: boolean;
	sorter: SorterResult<DefaultColumns>;
	tablePaginationSetting: PaginationSetting;
};

export const useHandleOnChangeTableLogic =
	() =>
	({
		clearSelection,
		filters,
		handleChangePagination,
		isSearchPerformed,
		pagination,
		shouldResetSearchColumn,
		sorter,
		tablePaginationSetting,
	}: Partial<OnChangeTableLogicProps>): {
		current: number;
		pageSize: number;
		shouldUpdateTableResults: boolean;
		shouldUpdateSearchResults: boolean;
		sortField: React.Key | readonly React.Key[];
		sortOrder: SortDirections;
	} => {
		const { order, field } = sorter;
		let sortOrder = SortDirections.None;
		const sortField = field ?? '';

		const { PageNumber, PageSize, SortDirection, SortField, SearchedValue } = tablePaginationSetting;

		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		if (sortOrder !== SortDirection) {
			clearSelection();
		}

		const { current, pageSize } = pagination;

		handleChangePagination(current, pageSize);

		const shouldUpdateTableResults =
			(current !== PageNumber || pageSize !== PageSize || sortOrder !== SortDirection || sortField !== SortField) &&
			!isSearchPerformed.current &&
			!shouldResetSearchColumn;

		const filter: string[] = filters?.Name ? filters.Name : [];

		const shouldUpdateSearchResults = isSearchPerformed.current && (filter?.findIndex(x => x === SearchedValue) !== -1 ?? false);
		if (isSearchPerformed.current) isSearchPerformed.current = false;

		return {
			current,
			pageSize,
			shouldUpdateSearchResults,
			shouldUpdateTableResults,
			sortField,
			sortOrder,
		};
	};

/**
 * Get displayed columns CSS width value
 * @param columns array of displayed columns
 * @param columnsWidth Object with each column width
 * @returns CSS property Grid-Template-Columns with the value of displayed columns
 */
export const getColumnWidth = <T extends {}>(columns: ColumnsProps<T>[], columnsWidth: Record<keyof T, string>): CSSProperties => {
	let gridTemplateColumnsString: string = '';
	columns.forEach((column: ColumnsProps<T>) => {
		if (columnsWidth[column.dataIndex] === undefined) {
			return;
		}
		gridTemplateColumnsString += ` ${columnsWidth[column.dataIndex]}`;
	});

	return {
		gridTemplateColumns: gridTemplateColumnsString,
	};
};

/**
 * Custom hook that gets the size of the window when resized
 * @returns Window width and height when resized
 */
export const useWindowResize = (): number[] => {
	const [size, setSize] = useState<number[]>([0, 0]);

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const updateSize = (): void => {
		setSize([window.innerWidth, window.innerHeight]);
	};

	return size;
};

/**
 * Gets the tab's EventFilterPage value you are currently on
 * @returns EventFilterPage with the tab you are on
 */
export const getCurrentEventFilterPage = (): EventFilterPage => {
	let eventFilterPage: EventFilterPage = EventFilterPage.None;
	const headerInfo = document.getElementById('eventFilterPage') as HTMLInputElement;
	if (headerInfo) {
		eventFilterPage = EventFilterPage[headerInfo.value];
	}

	return eventFilterPage;
};

/**
 *
 * @param cultureDateFormat current date format
 * @returns date format without seconds and normalized for moment.js
 */
export const cleanCultureDateFormat = (cultureDateFormat: string): string => {
	if (!cultureDateFormat) return '';
	const dateFormatNoSeconds = cultureDateFormat.replace(':ss', '').replace('tt', 'a').replaceAll('&#39;', `'`);
	const dateFormats = dateFormatNoSeconds.split(' ');

	if (dateFormats && dateFormats.length) {
		dateFormats[0] = dateFormats[0].toUpperCase();
	}

	return dateFormats.join(' ');
};

/**
 *
 * @param cultureDateFormat current only date format
 * @returns date format without time and normalized for moment.js
 */
export const cultureDateOnlyFormat = (cultureDateFormat: string): string => {
	let dateFormat: string;
	const dateFormatArray: string[] = cultureDateFormat.split(' ');
	if (dateFormatArray && dateFormatArray.length > 0) {
		if (dateFormatArray[0].length <= 5 && dateFormatArray.length >= 3) {
			dateFormat = `${dateFormatArray[0].toUpperCase()} ${dateFormatArray[1].toUpperCase()} ${dateFormatArray[2].toUpperCase()}`;
		} else {
			dateFormat = dateFormatArray[0].toUpperCase();
		}
	}

	return dateFormat;
};

/**
 * Port SNIB Search - only purpose
 * @param ipAddress
 * @param octet4Text default value for this field
 * @returns IPAddressOctets
 */
export const splitIPAddressIntoOctets = (networkInfo: NetworkInfo, octet4Text?: string): IPAddressOctets => {
	if (networkInfo?.DefaultGateway) {
		const ipAddressOctets = networkInfo.DefaultGateway.split('.');
		if (ipAddressOctets.length <= 2) {
			return {} as IPAddressOctets;
		}

		const octets: IPAddressOctets = {
			octet1: ipAddressOctets[0],
			octet2: ipAddressOctets[1],
			octet3: ipAddressOctets[2],
			octet4: octet4Text ? octet4Text : ipAddressOctets[3],
		};

		return octets;
	}

	return {} as IPAddressOctets;
};

/**
 *
 * @param value T
 * @returns boolean
 */
export const isUndefinedOrNull = <T>(value: T) => {
	return value === undefined || value === null;
};

/**
 *
 * @param {string} value value to validate
 * @returns boolean
 */
export const isEmptyOrSpaces = (value: string): boolean => {
	return value === undefined || value === null || value.trim() === '';
};

/**
 *
 * @param {string} value value to be parsed
 * @param {boolean} formatDateStd should we use standard formatting?
 * @param {string} defaultRetValue default return value if formatting fails
 * @param {boolean} includeNonStdFormatTime should include time in non standard formatting?
 * @returns boolean
 */
export const parseDateTimeString = (
	value: string,
	formatDateStd: boolean = true,
	defaultRetValue: string = '',
	includeNonStdFormatTime: boolean = false
): string => {
	if (isEmptyOrSpaces(value)) {
		return defaultRetValue;
	}

	let retValue: string;
	if (formatDateStd) {
		try {
			retValue = FormatDateStd(value);
		} catch {
			retValue = defaultRetValue;
		}
	} else {
		try {
			retValue = FormatDate(value, includeNonStdFormatTime, null, true);
		} catch {
			retValue = defaultRetValue;
		}
	}

	return retValue;
};

export const getDeviceObjectTypeBy = (objectType: ObjectTypes) => {
	switch (objectType) {
		case ObjectTypes.pollingPorts:
			return DeviceObjectType.Port;

		case ObjectTypes.xboxes:
			return DeviceObjectType.Xbox;

		case ObjectTypes.hardwareControllers:
			return DeviceObjectType.Controller;

		case ObjectTypes.doors:
			return DeviceObjectType.Door;

		case ObjectTypes.inputs:
			return DeviceObjectType.Input;

		case ObjectTypes.relays:
			return DeviceObjectType.Relay;

		case ObjectTypes.expansionInputs:
			return DeviceObjectType.ExpansionInputs;

		case ObjectTypes.expansionRelays:
			return DeviceObjectType.ExpansionRelays;

		case ObjectTypes.readers:
			return DeviceObjectType.Reader;
	}

	return DeviceObjectType.Unknown;
};

export const getObjectTypeBy = (deviceObjectType: DeviceObjectType) => {
	switch (deviceObjectType) {
		case DeviceObjectType.Port:
			return ObjectTypes.pollingPorts;

		case DeviceObjectType.Xbox:
			return ObjectTypes.xboxes;

		case DeviceObjectType.Controller:
			return ObjectTypes.hardwareControllers;

		case DeviceObjectType.Door:
			return ObjectTypes.doors;

		case DeviceObjectType.Input:
			return ObjectTypes.inputs;

		case DeviceObjectType.Relay:
			return ObjectTypes.relays;

		case DeviceObjectType.ExpansionInputs:
			return ObjectTypes.expansionInputs;

		case DeviceObjectType.ExpansionRelays:
			return ObjectTypes.expansionRelays;

		case DeviceObjectType.Reader:
			return ObjectTypes.readers;
	}

	return ObjectTypes.none;
};

export const getFileDetails = (fileName: string): { nameWithoutExtension: string; extension: string } => {
	const lastDotIndex = fileName.lastIndexOf('.');

	if (lastDotIndex === -1) {
		// If there's no dot in the file name, treat the entire name as the name without extension
		return { nameWithoutExtension: fileName, extension: '' };
	}

	const nameWithoutExtension = fileName.substring(0, lastDotIndex);
	const extension = fileName.substring(lastDotIndex);

	return { nameWithoutExtension, extension };
};

export const hasFlag = <T extends number>(current: T, expected: T): boolean => {
	return (current & expected) === expected;
};

export const convertExitReaderAddressToDoorAddress = (address: string): string => {
	let readerAddress: string = '';

	if (address.includes('.SM')) {
		const doorIndex: number = parseInt(address.substring(address.length - 2));
		readerAddress = address.replace('SM', 'DR');
		readerAddress = readerAddress.substring(0, readerAddress.length - 2) + (doorIndex + 8).toString().padStart(2, '0');
	}

	return readerAddress;
};

export const convertToDoorAddress = (address: string): string => {
	let doorAddress: string = '';
	if (address.length >= 4) {
		const deviceInfo: string = address.substring(address.length - 4);
		const deviceLetters: string = deviceInfo.substring(0, deviceInfo.length - 2);

		switch (deviceLetters) {
			case 'SM':
				if (parseInt(deviceInfo.substring(2)) < 9) {
					doorAddress = address.replace('SM', 'DR');
				} else {
					doorAddress = convertExitReaderAddressToDoorAddress(address);
				}
				break;
			case 'BI':
				doorAddress = address.replace('BI', 'DR');
				break;
			case 'BR':
				doorAddress = address.replace('BR', 'DR');
				break;
		}
	}
	return doorAddress;
};

export const trimStringUpTo = (value: string, maxLength: number) => {
	if (value.length > maxLength) {
		return `${value.substring(0, maxLength)}...`;
	}

	return value;
};

export const getEnrollmentUploadProps = (
	onLoad: (source: string) => void,
	validFormats: string[] = ['.jpg', '.jpeg', '.png', '.bmp'],
	validResolution: ImageResolution = undefined
): UploadProps => {
	return {
		accept: validFormats.join(','),
		showUploadList: false,
		onChange: (info: UploadChangeParam) => {
			if (info.file.status === ('error' as UploadFileStatus)) {
				notification.error({
					message: _('FileUploadFailed').replace('%1', info.file.name),
				});
			}
		},
		beforeUpload: (file: RcFile) => {
			const { extension } = getFileDetails(file.name);
			if (!extension || validFormats.indexOf(extension.toLowerCase()) < 0) {
				notification['error']({
					message: _('UnsupportedFileType'),
				});
				return false;
			}

			const isValid: boolean = validFormats.includes(extension.toLowerCase());
			if (!isValid) {
				notification.error({
					message: _('FormatNotSupported').replace('%1', file.type),
				});
			} else {
				let isResolutionValid: boolean = true;
				if (validResolution) {
					const img = new Image();
					img.onload = () => {
						if (img.width !== validResolution.width && img.height !== validResolution.height) {
							const resMessage: string = _('ResolutionNotSupported')
								.replace('%w', img.width.toString())
								.replace('%h', img.height.toString())
								.replace('%c', validResolution.description)
								.replace('%W', validResolution.width.toString())
								.replace('%H', validResolution.height.toString());
							validResolution.callback
								? validResolution.callback(resMessage)
								: notification.info({
										message: resMessage,
								  });
							isResolutionValid = false;
							return false;
						}
					};
					img.onerror = () => {
						notification.error({ message: 'Failed to load image' });
					};
					img.src = URL.createObjectURL(file);
				}
				const reader: FileReader = new FileReader();
				reader.onload = e => {
					if (isResolutionValid) {
						onLoad(e.target.result.toString());
					}
				};
				reader.onerror = e => {
					console.log(e);
				};
				reader.readAsDataURL(file);
			}
			return false;
		},
	};
};

export const getMaskMechanism = (codeProperties: CodeProperties): JSEncrypt => {
	const eStr: string = codeProperties.E;
	const nStr: string = codeProperties.N;
	const e = parseBigInt(eStr, 16);
	const n = parseBigInt(nStr, 16);
	const encryptEngine = new JSEncrypt();
	const k = encryptEngine.getKey();
	k.parsePropertiesFrom({ n, e });

	return encryptEngine;
};

export const getNewId = (excludeIds: string[]) => {
	excludeIds = excludeIds || [];
	let newId: string;
	do {
		newId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			const r = (Math.random() * 16) | 0;
			const v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	} while (excludeIds.includes(newId));
	return newId;
};

import { Table } from 'antd';
import cx from 'classnames';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import { buildColumn, ColumnsProps, ScrollType } from '../../../Helper';
import { AlarmDetail, AlarmModel } from '../../../model/AlarmModel';
import { BaseColumns } from '../../../model/CommonModel';
import { useStoreSelector } from '../../../store';
import { selectAlarmModel } from '../../../store/alarm/selectors';
import { getAlarmTextColor } from '../helpers';

import styles from './alarmTableInModal.module.scss';

type AlarmTableInModalDataSourceType = Pick<AlarmDetail, 'HardwareTime' | 'HostTime'> & Pick<AlarmModel, 'State' | 'ReturnedToNormal'> & BaseColumns;
const scroll: ScrollType = { x: 'max-content', y: 200 };
const hiddenScrollX: CSSProperties = { overflowX: 'hidden' };
const getAlarmFormattedDate = (value, record: AlarmTableInModalDataSourceType, index: number): ReactNode => {
	let text = value;
	try {
		text = FormatDate(text, true, null, true);
	} catch {
		text = value;
	}

	return <span style={getAlarmTextColor(record.ReturnedToNormal)}>{text}</span>;
};

const AlarmTableInModal: React.FC = () => {
	const [dataSource, setDataSource] = useState<AlarmTableInModalDataSourceType[]>([]);
	const [alarmModelCommon, setAlarmModelCommon] = useState<Partial<AlarmModel>>({});
	const [singleAlarmDetail, setSingleAlarmDetail] = useState<AlarmDetail>(undefined);
	const [height, setHeight] = useState<number>(200);

	const alarmModel: AlarmModel = useStoreSelector<AlarmModel>(selectAlarmModel);

	useEffect(() => {
		if (alarmModel) {
			setAlarmModelCommon(alarmModel);
			if (alarmModel.AlarmDetails.length > 1) {
				let tableHeight: number = 200;
				switch (alarmModel.AlarmDetails.length) {
					case 1:
						tableHeight = 40;
						break;

					case 2:
						tableHeight = 80;
						break;

					case 3:
						tableHeight = 120;
						break;

					case 4:
						tableHeight = 160;
						break;

					default:
						tableHeight = 200;
						break;
				}

				setHeight(tableHeight);
				setDataSource(
					alarmModel.AlarmDetails.map<AlarmTableInModalDataSourceType>((alarmDetail: AlarmDetail) => ({
						HardwareTime: alarmDetail.HardwareTime,
						HostTime: alarmDetail.HostTime,
						key: alarmDetail.ActiveAlarmId,
						State: alarmModel.State,
						ReturnedToNormal: alarmModel.ReturnedToNormal,
					}))
				);
			} else if (alarmModel.AlarmDetails.length === 1) {
				setSingleAlarmDetail(alarmModel.AlarmDetails[0]);
			}
		}
	}, []);

	const columns: ColumnsProps<AlarmTableInModalDataSourceType>[] = [
		{
			...buildColumn(_('HostTime'), 'HostTime', '250px', 'start'),
			render: getAlarmFormattedDate,
			width: 250,
		},
		{
			...buildColumn(_('ControllerTime'), 'HardwareTime', '250px', 'start'),
			render: getAlarmFormattedDate,
			width: 250,
		},
	];

	const alarmStyle: CSSProperties = getAlarmTextColor(alarmModelCommon.ReturnedToNormal);
	const renderVirtualList = rawData => {
		return (
			<Grid columnCount={columns.length} columnWidth={249} height={height} rowCount={rawData.length} rowHeight={40} width={501} style={hiddenScrollX}>
				{({ columnIndex, rowIndex, style }) => (
					<div className={cx(styles.virtualTableCell, { [styles.whiteRow]: rowIndex % 2 === 0, [styles.grayRow]: rowIndex % 2 !== 0 })} style={style}>
						<span style={alarmStyle}>{FormatDate(rawData[rowIndex][columns[columnIndex].dataIndex], true, null, true)}</span>
					</div>
				)}
			</Grid>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.alarmInfo}>
				<div>
					<div>
						{_('Description')}: <span style={alarmStyle}>{alarmModelCommon.Description}</span>
					</div>
					<div>
						{_('Address')}: <span style={alarmStyle}>{alarmModelCommon.Address}</span>
					</div>
				</div>
				<div>
					<div>
						{_('Level')}: <span style={alarmStyle}>{alarmModelCommon.Level}</span>
					</div>
					<div>
						{_('AlarmID')}: <span style={alarmStyle}>{alarmModelCommon.EventId}</span>
					</div>
				</div>
				{singleAlarmDetail && (
					<div>
						<div>
							{_('HostTime')}: <span style={alarmStyle}>{FormatDate(singleAlarmDetail.HostTime, true, null, true)}</span>
						</div>
						<div>
							{_('ControllerTime')}: <span style={alarmStyle}>{FormatDate(singleAlarmDetail.HardwareTime, true, null, true)}</span>
						</div>
					</div>
				)}
			</div>
			{!singleAlarmDetail && (
				<div className={styles.tableContainer}>
					<Table
						className={styles.alarmInfoTable}
						columns={columns}
						pagination={false}
						components={{
							body: renderVirtualList,
						}}
						scroll={scroll}
						dataSource={dataSource}
						size='small'
					/>
					{dataSource.length > 1 && <span>{`${dataSource.length} ${_('Items')}`}</span>}
				</div>
			)}
		</div>
	);
};

export { AlarmTableInModal };

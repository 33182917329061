import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { ColumnsProps, useWindowResize } from '../../../Helper';
import { getNextPage } from '../../alarms/helpers';
import { ContextMenu, ContextMenuProps } from '../../common';
import { RefWrap } from './RefWrap';
import { Row } from './Row';
import styles from './virtualTable.module.scss';

const minimumRowHeight: number = 30;

type VirtualTableProps<T> = {
	columnsWidth: CSSProperties;
	columns: ColumnsProps<T>[];
	dataSource: T[];
	currentPage: number;
	pageSize: number;
	contextMenuOptions?: ContextMenuProps<number>;
	backgroundColor?: CSSProperties;
	maxHeight: number;
	isEventViewer?: boolean;
	customRow?: React.ReactNode;
	fetchData: (pageNumber: number, reset?: boolean) => void;
};

type sizeMap = {
	[index: number]: number;
};

const VirtualTable = <T extends {}>({
	columns,
	dataSource,
	currentPage,
	pageSize,
	columnsWidth,
	contextMenuOptions,
	backgroundColor,
	maxHeight,
	fetchData,
	isEventViewer,
}: VirtualTableProps<T>) => {
	const [headerScroll, setHeaderScroll] = useState<number>(0);
	const [height, setHeight] = useState<number>(minimumRowHeight);

	const listRef = useRef(undefined);
	const headerRef = useRef(undefined);
	const sizeMap = useRef<sizeMap>({});
	const setSize = useCallback((index: number, size: number) => {
		sizeMap.current = { ...sizeMap.current, [index]: size };
		if (listRef?.current) {
			listRef.current.resetAfterIndex(index);
		}
	}, []);
	const getSize = (index: number): number => sizeMap.current[index] || minimumRowHeight;
	const [windowWidth] = useWindowResize();

	useEffect(() => {
		if (headerRef?.current) {
			headerRef.current.scrollLeft = headerScroll;
		}
	}, [headerScroll]);

	useEffect(() => {
		if (listRef?.current && dataSource?.length > 0) {
			listRef.current.scrollToItem(0);
		}
	}, [height]);

	const onFetchData = (): void => {
		const quantityOfEvents = dataSource?.length;
		const newPage = getNextPage(quantityOfEvents, pageSize);

		if (currentPage != newPage) {
			fetchData(newPage);
		}
	};

	const handleInnerRef = ref => {
		if (ref?.children) {
			setTimeout(() => {
				let totalHeight: number = 0;
				for (let index = 0; index < ref.children.length; index++) {
					const elementDiv = ref.children[index];
					if (elementDiv && elementDiv.style.height) {
						const heightNumber: number = Number(elementDiv.style.height.toLowerCase().split('px')[0]);
						if (!isNaN(heightNumber) && heightNumber <= maxHeight && heightNumber >= 2) {
							totalHeight += heightNumber;
						} else {
							return;
						}
					}
				}

				if (totalHeight === 0) {
					setHeight(minimumRowHeight - 2);
				} else if (totalHeight <= maxHeight && totalHeight >= 2) {
					setHeight(totalHeight - 2);
				} else if (height !== maxHeight) {
					setHeight(maxHeight);
				}
			}, 0);
		}
	};

	const listKey: string = dataSource.length === 0 && isEventViewer ? `${Math.floor(Math.random() * 100)}` : undefined;
	const listComponent: JSX.Element = (
		<RefWrap fetchData={onFetchData} headerScroll={headerScroll} setHeaderScroll={setHeaderScroll}>
			<List
				id='eventsTable'
				ref={listRef}
				height={height}
				width='100%'
				itemCount={dataSource.length}
				itemSize={getSize}
				itemData={dataSource}
				innerRef={handleInnerRef}
				className={isEventViewer ? styles.list : styles.customList}
				overscanCount={10}
				style={backgroundColor}>
				{({ data, index, style }) => (
					<div style={style}>
						<Row
							data={data}
							index={index}
							setSize={setSize}
							windowWidth={windowWidth}
							columns={columns}
							columnsWidth={columnsWidth}
							contextMenuOptions={contextMenuOptions}
							backgroundColor={backgroundColor}
							isEventViewer={isEventViewer}
						/>
					</div>
				)}
			</List>
		</RefWrap>
	);

	return (
		<div className={styles.container}>
			<div ref={headerRef} className={styles.header} style={columnsWidth}>
				{columns.map((column: ColumnsProps<T>) => (
					<div key={`header-${column.dataIndex}`}>{column.title}</div>
				))}
			</div>
			<React.Fragment key={listKey}>
				{isEventViewer && contextMenuOptions ? (
					<ContextMenu
						options={contextMenuOptions.options}
						onClickOption={contextMenuOptions.onClickOption}
						children={<div style={backgroundColor}>{listComponent}</div>}
					/>
				) : (
					listComponent
				)}
			</React.Fragment>
		</div>
	);
};

export { VirtualTable };

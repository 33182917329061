import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../../model/AccountModel';
import { PaginationSetting } from '../../../../../model/CommonModel';
import { Command, CommandEdit, CommandRow, Commands, VelocityCommandType } from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setCommandsSetsBy } from '../../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfigurationFilterMode } from '../../../../../store/deviceControl/selectors';
import { Modal, NotificationStatus } from '../../../../common';
import styles from './commandSetsModal.module.scss';
import {
	StoreContext,
	commandContext,
	commandState,
	resetSelections,
	setCommand,
	setCommands,
	setControllers,
	setFunctions,
	setLocations,
	setName,
	setNameErrors,
} from './contextCommand';
import { CommandInput, CommandsGrid, Controller, Function, Location, Name } from './sections';

type Props = {
	onSetVisible: (visible: boolean) => void;
	commandToBeEditable?: Commands;
	setShouldResetSearchColumn: () => void;
	setRedirectPage?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Command_Set);

const CommandSetsModal: React.FC<Props> = ({ onSetVisible, commandToBeEditable, setShouldResetSearchColumn, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const [submitted, setSubmit] = useState(false);
	const [submittedMain, setSubmitMain] = useState(false);
	const [nameError, setNameError] = useState(false);

	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);
	const [stateContext, dispatchActionContext] = useReducer(commandContext, commandState);
	const {
		command,
		commands,
		name,
		editionCommandSetKey,
		selections: { location, controller, functionName },
	} = stateContext;

	useEffect(() => {
		if (commandToBeEditable) {
			deviceAdminApi.editCommandSetDialog(commandToBeEditable.CommandSetId, VelocityCommandType.DigiTracCommand, true).then(res => {
				let keyIndex = 0;
				const commands: CommandRow[] = [];
				res.CmdStrings.map(x => {
					commands.push({
						command: x.CommandString,
						controller: x.DeviceId,
						function: 0,
						location: -1,
						name: x.DeviceName,
						key: keyIndex,
						controllerName: '',
					});
					keyIndex++;
				});

				dispatchActionContext(setCommands(commands));
				dispatchActionContext(setName(res.CommandSetName.trim()));
			});
		}
	}, [commandToBeEditable]);

	useEffect(() => {
		if (functionName !== null && controller) {
			deviceAdminApi.getLocations(functionName, controller).then(res => dispatchActionContext(setLocations({ list: res.Items, name: res.Name })));
		}
	}, [functionName, controller]);

	useEffect(() => {
		if (location) {
			deviceAdminApi.getCommand(functionName, location).then(res => dispatchActionContext(setCommand(res)));
		}
	}, [location]);

	useEffect(() => {
		deviceAdminApi.addCommandSetDialog(VelocityCommandType.DigiTracCommand).then(res => dispatchActionContext(setFunctions(res.FunctionOptions)));
		deviceAdminApi.getControllers().then(res => {
			dispatchActionContext(setControllers(res));
		});
		deviceAdminApi
			.getErrorMessages(SecuredComponents.Command_Set)
			.then(res => dispatchActionContext(setNameErrors({ emptyName: res.EmptyName, duplicatedName: res.DuplicatedName })));

		window.addEventListener('beforeunload', handleCloseModal);

		return () => {
			window.removeEventListener('beforeunload', handleCloseModal);
		};
	}, []);

	const handleAddCommand = () => {
		setSubmit(true);

		if (command === '' || controller === null) {
			return;
		} else {
			const maxLength = 4000;
			let commandAssign;
			if (command.length === maxLength) {
				commandAssign = `${command.slice(0, -1)}#`;
			} else {
				commandAssign = command[command.length - 1] !== '#' ? `${command}#` : command;
			}

			const commandValue: CommandRow = {
				command: commandAssign,
				name,
				location: location,
				function: functionName,
				controller: controller,
				controllerName: '',
				key: commands.length > 0 ? Number(commands[commands.length - 1].key) + 1 : 0,
			};

			dispatchActionContext(setCommands([...commands, commandValue]));

			//reset form selections to initial state
			dispatchActionContext(resetSelections());

			setSubmit(false);
		}
	};

	const shouldResetSearchColumn = isEditCommandSet => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!isEditCommandSet) {
			return;
		}

		setRedirectPage();
	};

	const handleAddEditCommandSet = async () => {
		setSubmitMain(true);
		if (!name || name.trim() === '') {
			return;
		}

		const commandSet: Command = {
			name: name.trim(),
			type: VelocityCommandType.DigiTracCommand,
			commands: commands.map(x => ({
				CommandString: x.command,
				DeviceId: x.controller,
				DeviceName: x.name,
				Type: VelocityCommandType.DigiTracCommand,
				CommandSetStringId: 0,
			})),
		};

		const commandEdited: CommandEdit = {
			commandName: commandSet.name,
			commandSetID: commandToBeEditable?.CommandSetId,
			commandType: VelocityCommandType.DigiTracCommand,
			commands: commandSet.commands,
		};

		const response = commandToBeEditable ? await deviceAdminApi.editCommandSet(commandEdited) : await deviceAdminApi.addCommandSet(commandSet);
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		NotificationStatus({
			responseData: response,
			onSuccessCallback: () => {
				dispatch(setCommandsSetsBy(paginationSetting));
				shouldResetSearchColumn(commandToBeEditable);
				onSetVisible(false);
			},
			onInputErrorCallback: () => setNameError(true),
		});
	};

	const handleOnPressKeyForCommandInput = (value: string) => {
		dispatchActionContext(setCommand(value));
	};

	const handleCloseModal = () => {
		if (commandToBeEditable) {
			deviceAdminApi.unlockComponentDeviceAdmin(commandToBeEditable.CommandSetId, SecuredComponents.Command_Set);
		}
		onSetVisible(false);
	};

	const controllerValidation = submitted && controller === -1;
	const nameValidation = submittedMain && (!name || name.trim() === '');
	const commandValidation = submitted && (command === '' || controller === null);

	const contextValue = useMemo(() => {
		return { commandState: stateContext, dispatcher: dispatchActionContext };
	}, [stateContext, dispatchActionContext]);

	const permissionsEnabled = commandToBeEditable ? componentPermission.canUpdate : componentPermission.canAdd;
	const disabled = !permissionsEnabled || editionCommandSetKey !== -1;

	return (
		<StoreContext.Provider value={contextValue}>
			<Modal
				footer={[
					<Button
						id='commandSetSaveButton'
						title={getPermissionErrorMessage(permissionsEnabled)}
						key='save'
						type='primary'
						disabled={disabled}
						onClick={() => handleAddEditCommandSet()}>
						{_('SaveChanges')}
					</Button>,
					<Button id='commandSetCancelButton' key='cancel' onClick={() => handleCloseModal()}>
						{_('Cancel')}
					</Button>,
				]}
				visible={true}
				customZoomClass={styles.withModalZoom}
				width='700px'
				title={commandToBeEditable ? _('EditCommandSet') : _('AddCommandSet')}
				onCancel={() => handleCloseModal()}
				onClickOk={() => null}>
				<div className={styles.container}>
					<div className={styles.form}>
						<div>
							<Name nameValidation={nameValidation} nameError={nameError} onResetNameError={() => setNameError(false)} />
							<Function />
							<CommandInput
								commandValidation={commandValidation}
								onKeyPressCommand={handleOnPressKeyForCommandInput}
								value={command}
								label={_('Command')}
								inputId={'commandSetCommandInput'}
							/>
						</div>
						<div>
							<Controller controllerValidation={controllerValidation} />
							<Location />
							<Button
								id='commandSetAddCommandButton'
								key='save'
								type='primary'
								onClick={() => handleAddCommand()}
								disabled={disabled}
								children={
									<>
										<PlusOutlined /> {_('AddCommand')}
									</>
								}
							/>
						</div>
					</div>
					<CommandsGrid />
				</div>
			</Modal>
		</StoreContext.Provider>
	);
};

export { CommandSetsModal };

import React from 'react';
import {
	ConcatenatedCardDataResponse,
	CredentialTemplateModalConfigData,
} from '../components/enrollment/CredentialTemplateModal/CredentialTemplateModalModels';
import { CriteriaSearchRequest, PaginationSetting, ResponseObjectEntity } from '../model/CommonModel';
import { ColumnConfiguration } from '../model/ConfigurationModel';
import { CredentialEnrollmentResponse, CredentialTemplateConfirmation } from '../model/CredentialModel';
import {
	AssignCredentialDetail,
	CredentialActionResponse,
	CredentialActions,
	CredentialDescription,
	CredentialDetail,
	CredentialResponse,
	RelativeShortcut,
} from '../model/EnrollmentModel';
import { Api } from './api';

class CredentialApi extends Api {
	public constructor() {
		super();
	}

	public getCredentialsColumnsConfiguration = async (): Promise<ColumnConfiguration> => {
		const url = this.getUrl('Credential/GetCredentialsColumnsConfiguration', true);

		return await this.post<ColumnConfiguration>(url);
	};

	public getCredentialsByPage = async (
		criteriaSearchRequest: CriteriaSearchRequest,
		paginationSetting: PaginationSetting,
		includePersonInformationColumns: boolean
	): Promise<ResponseObjectEntity<CredentialResponse>> => {
		const url = this.getUrl('Credential/GetCredentials', true);

		return await this.post<ResponseObjectEntity<CredentialResponse>>(url, { criteriaSearchRequest, paginationSetting, includePersonInformationColumns });
	};

	public getAllCredentialsIds = async (criteriaSearchRequest: CriteriaSearchRequest): Promise<ResponseObjectEntity<React.Key[]>> => {
		const url = this.getUrl('Credential/GetAllCredentialsIds', true);

		return await this.post<ResponseObjectEntity<React.Key[]>>(url, { criteriaSearchRequest });
	};

	public getCredentialDescriptionByIds = async (credentialIds: number[]): Promise<ResponseObjectEntity<CredentialDescription[]>> => {
		const url = this.getUrl('Credential/GetCredentialDescriptionByIds', true);

		return await this.post<ResponseObjectEntity<CredentialDescription[]>>(url, { credentialIds });
	};

	public getAssignCredentialsInfo = async (credentialIds: number[]): Promise<ResponseObjectEntity<AssignCredentialDetail>> => {
		const url = this.getUrl('Credential/GetAssignCredentialsInfo', true);

		return await this.post<ResponseObjectEntity<AssignCredentialDetail>>(url, { credentialIds });
	};

	public performCredentialAction = async (action: CredentialActions, credentialId: number, additionalId?: number): Promise<CredentialActionResponse> => {
		const url = this.getUrl('Credential/PerformCredentialAction', true);
		const data = {
			action,
			credentialId,
			additionalId,
		};

		return await this.post<CredentialActionResponse>(url, data);
	};

	public getCredentialInformation = async (credentialId: number, isNew: boolean, personId: number): Promise<CredentialDetail> => {
		const url = this.getUrl('Credential/GetCredentialInformation', true);
		const data = {
			credentialId,
			isNew,
			personId,
		};

		return await this.post<CredentialDetail>(url, data);
	};

	public getRelativeTimeShortcuts = async (): Promise<ResponseObjectEntity<RelativeShortcut[]>> => {
		const url = this.getUrl('Credential/GetRelativeTimeShortcuts', true);

		return await this.post<ResponseObjectEntity<RelativeShortcut[]>>(url);
	};

	public retrieveConcatenatedCardData = async (credentialId: number, personId: number, isNew: boolean): Promise<ConcatenatedCardDataResponse> => {
		const url = this.getUrl('Credential/RetrieveConcatenatedCardData', true);
		const data = {
			credentialId,
			personId,
			isNew,
		};

		return await this.post<ConcatenatedCardDataResponse>(url, data);
	};

	public generateMatchCode = async (cardData: string, cardType: number): Promise<string> => {
		const url = this.getUrl('Credential/GenerateMatchCode', true);
		const data = {
			cardData,
			formatId: cardType,
		};

		return await this.post<string>(url, data);
	};

	public getDeactivatedLinkCredentialCount = async (credentialId: number): Promise<number> => {
		const url = this.getUrl('Credential/GetDeactivatedLinkdCredentialCount', true);
		const data = {
			credentialId,
		};

		return await this.post<number>(url, data);
	};

	public saveUserCredential = async (credential: CredentialDetail): Promise<CredentialEnrollmentResponse> => {
		const url = this.getUrl('Credential/SaveUserCredential', true);
		const data = {
			credential,
		};

		return await this.post<CredentialEnrollmentResponse>(url, data);
	};

	public generateRandomPinCode = async (length: number): Promise<number> => {
		const url = this.getUrl('Credential/GenerateRandomPinCode', true);
		const data = {
			length,
		};

		return await this.post<number>(url, data);
	};

	public getConfirmationDialog = async (question: string, templateId: number): Promise<CredentialTemplateConfirmation> => {
		const url = this.getUrl('Credential/GetConfirmationDialog', true);
		const data = {
			question,
			templateId,
		};

		return await this.post<CredentialTemplateConfirmation>(url, data);
	};

	public getCredentialTemplateModalConfig = async (isTemplate: boolean): Promise<CredentialTemplateModalConfigData> => {
		const url = this.getUrl('Credential/GetCredentialTemplateModalInitConfigurationData', true);

		return await this.post<CredentialTemplateModalConfigData>(url, { isTemplate });
	};
}

const credentialApi = new CredentialApi();

export { credentialApi };

import { DictionaryCustom, GridColumn, PaginationSetting, ResponseObject, ResponseObjectEntity } from '../model/CommonModel';
import { StatusGroupSelected, StatusObjectEnum, StatusViewer, StatusViewerGroup, StatusViewerPointByType } from '../model/StatusViewerModel';
import { Api } from './api';

class StatusViewerApi extends Api {
	public constructor() {
		super();
	}

	public retrieveStatusViewer = async (statusGroupId: number, paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<StatusViewer>> => {
		if (statusGroupId === -1) {
			return undefined;
		}

		const url = this.getUrl('StatusViewer/RetrieveStatusViewerGroupPagination', true);
		const data = { statusGroupId, paginationSetting };

		return await this.post<ResponseObjectEntity<StatusViewer>>(url, data);
	};

	public retrieveStatusViewerByPagination = async (
		statusGroupId: number,
		objectId: number,
		pagination: PaginationSetting
	): Promise<ResponseObjectEntity<StatusViewer>> => {
		const url = this.getUrl('StatusViewer/RetrieveStatusViewerGroupPaginationByType', true);
		const data = {
			statusGroupId: statusGroupId,
			statusViewerObjectType: objectId,
			paginationSetting: pagination,
		};

		return await this.post<ResponseObjectEntity<StatusViewer>>(url, data);
	};

	public retrieveStatusViewerMaskedOnly = async (paginationSetting: PaginationSetting): Promise<ResponseObjectEntity<StatusViewer>> => {
		const url = this.getUrl('StatusViewer/RetrieveStatusViewerMaskedDevice', true);
		const data = {
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<StatusViewer>>(url, data);
	};

	public retrieveStatusViewerConfiguration = async (statusGroupId: number): Promise<ResponseObjectEntity<StatusViewerGroup>> => {
		const url = this.getUrl('StatusViewer/RetrieveStatusViewerConfiguration', true);
		const data = { statusGroupId };

		return await this.post<ResponseObjectEntity<StatusViewerGroup>>(url, data);
	};

	public retrieveStatusViewerColumnConfiguration = async (): Promise<ResponseObjectEntity<Record<StatusObjectEnum, GridColumn[]>>> => {
		const url = this.getUrl('StatusViewer/RetriveStatusViewerColumnConfiguration', true);

		return await this.post<ResponseObjectEntity<Record<StatusObjectEnum, GridColumn[]>>>(url);
	};

	public retrieveStatusPointsByType = async (
		objectId: number,
		paginationSetting: PaginationSetting,
		selectedDeviceIds: number[],
		selectedExpansionDeviceIds: number[]
	): Promise<ResponseObjectEntity<StatusViewerPointByType>> => {
		const url = this.getUrl('StatusViewer/RetrieveStatusPointsByType', true);
		const data = { objectId, selectedDeviceIds, selectedExpansionDeviceIds, paginationSetting };

		return await this.post<ResponseObjectEntity<StatusViewerPointByType>>(url, data);
	};

	public retrieveStatusPointsIdsByType = async (
		objectId: number,
		targetKeys: string[],
		searched: string
	): Promise<ResponseObjectEntity<StatusViewerPointByType>> => {
		const url = this.getUrl('StatusViewer/RetrieveStatusPointsIdsByType', true);
		const extendObject: number[] = [24, 25];
		const selectedDeviceIds =
			targetKeys && targetKeys.length > 0
				? targetKeys.filter(f => Number(f.split('-')[0]) === objectId && !extendObject.includes(Number(f.split('-')[1]))).map(m => m.split('-')[2])
				: [];
		const selectedExpansionDeviceIds =
			targetKeys && targetKeys.length > 0
				? targetKeys.filter(f => Number(f.split('-')[0]) === objectId && extendObject.includes(Number(f.split('-')[1]))).map(m => m.split('-')[2])
				: [];
		const data = { objectId, selectedDeviceIds, selectedExpansionDeviceIds, searched };

		return await this.post<ResponseObjectEntity<StatusViewerPointByType>>(url, data);
	};

	public retrieveStatusViewerByType = async (
		statusViewerObjectTypeId: number,
		paginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<StatusViewer>> => {
		if (statusViewerObjectTypeId === -1) {
			return undefined;
		}

		const url = this.getUrl('StatusViewer/RetrieveStatusViewerPaginationByType', true);
		const data = { statusViewerObjectTypeId, paginationSetting };

		return await this.post<ResponseObjectEntity<StatusViewer>>(url, data);
	};

	public retrieveStatusGroups = async (): Promise<StatusViewerGroup[]> => {
		const url = this.getUrl('StatusViewer/RetrieveStatusGroups', true);

		return await this.post<StatusViewerGroup[]>(url);
	};

	public newStatusViewerGroup = async (statusViewerGroup: StatusViewerGroup): Promise<ResponseObject> => {
		const url = this.getUrl('StatusViewer/AddStatusViewerGroup', true);
		const data = {
			StatusGroupId: statusViewerGroup.StatusGroupId,
			Name: statusViewerGroup.Name,
			StatusPoints: statusViewerGroup.StatusPoints,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public updateStatusViewerGroup = async (statusViewerGroup: StatusViewerGroup): Promise<ResponseObject> => {
		const url = this.getUrl('StatusViewer/EditStatusViewerGroup', true);
		const data = {
			StatusGroupId: statusViewerGroup.StatusGroupId,
			Name: statusViewerGroup.Name,
			StatusPoints: statusViewerGroup.StatusPoints,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteStatusViewerGroup = async (statusViewerGroup: StatusGroupSelected): Promise<ResponseObject> => {
		const url = this.getUrl('StatusViewer/DeleteStatusViewer', true);
		const data = { statusGroupId: statusViewerGroup.Id };

		return await this.post<ResponseObject>(url, data);
	};

	public saveStatusViewerColumnConfiguration = async (statusViewerColumnConfiguration: Record<StatusObjectEnum, GridColumn[]>): Promise<ResponseObject> => {
		const url = this.getUrl('StatusViewer/SaveStatusViewerColumnConfiguration', true);
		const data = {
			statusViewerColumns: Object.keys(statusViewerColumnConfiguration).map<DictionaryCustom<StatusObjectEnum, GridColumn[]>>(m => ({
				Key: StatusObjectEnum[m],
				Value: statusViewerColumnConfiguration[m],
			})),
		};

		return await this.post<ResponseObject>(url, data);
	};
}

const statusViewerApi = new StatusViewerApi();

export { statusViewerApi };

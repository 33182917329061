import React, { useEffect, useState } from 'react';
import { configurationApi } from '../../../api';
import { Modal } from '../../common';
import styles from './aboutModal.module.scss';

const date: Date = new Date();

type Props = {
	hideModal: () => void;
};

const AboutModal: React.FC<Props> = ({ hideModal }: Props) => {
	const [versionInformation, setVersionInformation] = useState([]);

	useEffect(() => {
		configurationApi.getVersionInformation().then(response => {
			setVersionInformation(response.Updates);
		});
	}, []);

	const copyrightInfo: string = _('CopyrightInfoFormat').replace('{0}', date.getFullYear()?.toString());

	return (
		<Modal
			footer={[<label key='aboutModalFooter' id='aboutModalFooter' dangerouslySetInnerHTML={{ __html: copyrightInfo }} />]}
			visible={true}
			customZoomClass={styles.withModalZoom}
			width='700px'
			title={_('About')}
			onCancel={hideModal}
			onClickOk={() => null}>
			<div className={styles.container}>
				{versionInformation.map((item, index) => (
					<div key={index}>
						<label id={`aboutModalDescription-${index}`} htmlFor={`aboutModalDescription-${index}`}>
							{item.Description}{' '}
						</label>
						<label id={`aboutModalVersion-${index}`} htmlFor={`aboutModalVersion-${index}`}>
							{item.Version}
						</label>
					</div>
				))}
			</div>
		</Modal>
	);
};

export { AboutModal };

import { RightOutlined } from '@ant-design/icons';
import { Button, Table, Transfer } from 'antd';
import { SelectionItem, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import { TransferDirection, TransferItem } from 'antd/lib/transfer';
import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { ColumnsProps } from '../../../Helper';
import { ObjectTypeTransfer } from '../../../model/CommonModel';
import { StatusViewerConfigurationColumns } from '../../../model/StatusViewerModel';
import styles from './statusGroupTransferTable.module.scss';

type Props<ObjectType> = {
	dataSource: ObjectTypeTransfer<ObjectType>[];
	titles?: string[];
	targetKeys: string[];
	onChange: (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => void;
	className?: string;
	showSearch: boolean;
	filterOption?: (inputValue: string, option: TransferItem) => boolean;
	onSearch?: (direction: string, value: string) => void;
	columnsLeft: ColumnsProps<ObjectType>[];
	columnsRight: ColumnsProps<ObjectType>[];
	rowSelectionLeft: TableRowSelection<ObjectType>;
	setSelectedKeysLeft: React.Dispatch<React.SetStateAction<React.Key[]>>;
	selectedRowKeysPaginationLeft: StatusViewerConfigurationColumns[];
	setSelectedRowKeysPaginationLeft: React.Dispatch<React.SetStateAction<StatusViewerConfigurationColumns[]>>;
	rowSelectionRight: TableRowSelection<ObjectType>;
	setSelectedKeysRight: React.Dispatch<React.SetStateAction<React.Key[]>>;
	selectedRowKeysPaginationRight: StatusViewerConfigurationColumns[];
	setSelectedRowKeysPaginationRight: React.Dispatch<React.SetStateAction<StatusViewerConfigurationColumns[]>>;
	tableId: string;
	paginationTableRight: TablePaginationConfig;
	paginationTableLeft: TablePaginationConfig;
	disableItem?: number;
	onChangeTable?: (pagination, filters, sorter, extra, direction) => void;
	canAddEditDeleteStatusGroups: boolean;
	onHandleSelectionAllLeft: (currentRowKeys: React.Key[], selectedKeysTransfer, onItemSelectAll) => void;
};

type customType<T> = {
	[Property in keyof T]: any;
};

const TableTransfer = <ObjectType extends {}>({
	tableId,
	columnsLeft,
	columnsRight,
	rowSelectionLeft,
	setSelectedKeysLeft,
	selectedRowKeysPaginationLeft,
	setSelectedRowKeysPaginationLeft,
	rowSelectionRight,
	setSelectedKeysRight,
	selectedRowKeysPaginationRight,
	setSelectedRowKeysPaginationRight,
	paginationTableRight,
	paginationTableLeft,
	disableItem,
	onChangeTable,
	canAddEditDeleteStatusGroups,
	onHandleSelectionAllLeft,
	...restProps
}: Props<ObjectType>) => {
	const [buttonsContainer, setButtonsContainer] = useState<Element>(null);

	useEffect(() => {
		const container = document.querySelector('.ant-transfer-operation');
		if (container) {
			setButtonsContainer(container);
		}
	}, []);

	const handleOnClickBtn = (direction: TransferDirection) => {
		let nxtTargetKeys = [...restProps.targetKeys];
		const selectedKeys: string[] = rowSelectionLeft?.selectedRowKeys?.map(k => k.toString()) || [];
		nxtTargetKeys = [...nxtTargetKeys, ...selectedKeys];
		const { onChange } = restProps;
		onChange(nxtTargetKeys, direction, selectedKeys);
	};

	const isButtonDisabled = useMemo(() => (rowSelectionLeft?.selectedRowKeys?.length || 0) < 1, [rowSelectionLeft]);

	return (
		<div className={styles.statusGroupTransferContainer}>
			<Transfer showSelectAll={false} render={item => item.title} {...restProps}>
				{({ direction, filteredItems, onItemSelect, onItemSelectAll, selectedKeys }) => {
					const selectPagination = direction === 'left' ? paginationTableLeft : paginationTableRight;
					const shouldDisableItem = (key: string, selectedRowsKeys: string[]): boolean => {
						const isItemSelected: boolean = selectedRowsKeys.includes(key);
						const itemId = Number(key);
						const isItemDisabled = (isItemSelected && itemId === disableItem) || (!isItemSelected && itemId === disableItem);
						return isItemDisabled ? true : false;
					};

					const selectedRowSelection =
						direction === 'left'
							? {
									...rowSelectionLeft,
									selections: !canAddEditDeleteStatusGroups
										? undefined
										: [
												{
													key: 'SELECTION_ALL',
													text: 'Select all data',
													onSelect: (currentRowKeys: React.Key[]) => {
														onHandleSelectionAllLeft(currentRowKeys, selectedKeys, onItemSelectAll);
													},
												} as SelectionItem,
												Table.SELECTION_INVERT,
												Table.SELECTION_NONE,
										  ],
							  }
							: rowSelectionRight;
					const selectedColumns = direction === 'left' ? columnsLeft : columnsRight;
					const selectedKeysCustom: string[] = selectedRowSelection.selectedRowKeys.map(m => m.toString());
					if (direction === 'left' && selectedKeysCustom.length === 0 && selectedKeys.length > 0) {
						const clearWhenChangeComponentLeft = setInterval(async () => {
							onItemSelectAll(selectedKeys, false);
							clearInterval(clearWhenChangeComponentLeft);
						}, 1);
					}
					if (direction === 'right' && selectedKeysCustom.length === 0 && selectedKeys.length > 0) {
						const clearWhenChangeComponentRight = setInterval(async () => {
							onItemSelectAll(selectedKeys, false);
							clearInterval(clearWhenChangeComponentRight);
						}, 1);
					}
					const rowSelectionTransfer = {
						...selectedRowSelection,
						getCheckboxProps: record => ({
							...record,
							disabled: shouldDisableItem(record.key, selectedKeysCustom),
						}),
						selectedRowKeys: selectedKeysCustom,
						onChange: (selectedRowKeys: React.Key[], selectedRows: StatusViewerConfigurationColumns[]) => {
							if (selectedKeys.length > 0) {
								onItemSelectAll(selectedKeys, false);
							}
							onItemSelectAll(
								selectedRowKeys.map(m => m.toString()),
								selectedRowKeys.length > 0
							);
							if (direction === 'left') {
								const keyMissing = selectedRowKeys.filter(key => !selectedRowSelection.selectedRowKeys.includes(key));
								if (keyMissing.length > 0) {
									setSelectedKeysLeft(selectedRowKeys);
									setSelectedRowKeysPaginationLeft([
										...selectedRowKeysPaginationLeft,
										...selectedRows.filter(f => f != undefined && keyMissing.includes(f?.key)),
									]);
								} else {
									setSelectedKeysLeft(selectedRowKeys);
									const selectedRowKeysPagination = selectedRowKeysPaginationLeft.filter(f => selectedRowKeys.includes(f.key));
									setSelectedRowKeysPaginationLeft(selectedRowKeysPagination);
								}
							}
							if (direction === 'right') {
								setSelectedKeysRight(selectedRowKeys);
								setSelectedRowKeysPaginationRight(selectedRows);
							}
						},
					};
					const dataSourceMapped: ObjectType[] = filteredItems.map<ObjectType>((item: TransferItem) => {
						return { ...item, disabled: shouldDisableItem(item.key, selectedKeysCustom) } as customType<ObjectType>;
					});
					const handleOnClick = record => {
						if (record.disabled) return;
						if (direction === 'left') {
							if (selectedRowSelection.selectedRowKeys.includes(record.key)) {
								setSelectedKeysLeft([...selectedRowSelection.selectedRowKeys.filter(f => f !== record.key)]);
								setSelectedRowKeysPaginationLeft([...selectedRowKeysPaginationLeft.filter(f => f.key !== record.key)]);
							} else {
								setSelectedKeysLeft([...selectedRowSelection.selectedRowKeys, record.key]);
								setSelectedRowKeysPaginationLeft([...selectedRowKeysPaginationLeft, record]);
							}
						}
						if (direction === 'right') {
							if (selectedRowSelection.selectedRowKeys.includes(record.key)) {
								setSelectedKeysRight([...selectedRowSelection.selectedRowKeys.filter(f => f !== record.key)]);
								setSelectedRowKeysPaginationRight([...selectedRowKeysPaginationRight.filter(f => f.key !== record.key)]);
							} else {
								setSelectedKeysRight([...selectedRowSelection.selectedRowKeys, record.key]);
								setSelectedRowKeysPaginationRight([...selectedRowKeysPaginationRight, record]);
							}
						}
						onItemSelect(record.key, !selectedKeysCustom.includes(record.key));
					};

					const handleChangePagination = (pagination, filters, sorter, extra) => {
						onChangeTable(pagination, filters, sorter, extra, direction);
					};

					return (
						<>
							{buttonsContainer &&
								direction === 'right' &&
								createPortal(
									<Button
										className={styles.customButton}
										disabled={isButtonDisabled}
										onClick={() => {
											handleOnClickBtn(direction);
										}}
										type='primary'
										htmlType='button'
										icon={<RightOutlined />}
										size='small'
									/>,
									buttonsContainer
								)}
							<Table
								id={tableId}
								rowSelection={rowSelectionTransfer}
								columns={selectedColumns}
								dataSource={dataSourceMapped}
								size='small'
								pagination={selectPagination}
								onRow={record => ({
									onClick: () => handleOnClick(record),
								})}
								className={styles.transferTable}
								onChange={handleChangePagination}
								scroll={{ y: 310 }}
								rowClassName={(_, index) => {
									if (index % 2 !== 0) {
										return styles.evenRow;
									}
								}}
							/>
						</>
					);
				}}
			</Transfer>
		</div>
	);
};

export { TableTransfer };

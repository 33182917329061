import { Button } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { PaginationSetting } from '../../../../model/CommonModel';
import { CurrentDeviceControlObj, DeviceObjectType, Input } from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { setControllerContacts } from '../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { InformationMessages, Modal, ModalWarning, NotificationStatus, WithLockedValidation } from '../../../common';
import { InputContainer } from './InputContainer/InputContainer';
import styles from './inputModal.module.scss';
import { StoreContext, inputContextReducers, inputContextState } from './inputModalContext';

type Props = {
	currentDeviceObj: CurrentDeviceControlObj;
	setShouldResetSearchColumn: () => void;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	setRedirectPage?: () => void;
	saveCallBack?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const canSaveInput = User.getComponentPermission(user, SecuredComponents.Input).canUpdate;
const canSaveExpansionInput = User.getComponentPermission(user, SecuredComponents.Expansion_Input).canUpdate;

const InputModal: React.FC<Props> = WithLockedValidation(({ currentDeviceObj, setShouldResetSearchColumn, cancelHandle, saveCallBack, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const [stateContext, dispatchActionContext] = useReducer(inputContextReducers, inputContextState);
	const [submittedForm, setSubmitForm] = useState(false);
	const [nameError, setNameError] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [errorText, setErrorText] = useState('');
	const { input, lineModules } = stateContext;

	const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const securedComponent = currentDeviceObj.DeviceObjectType === DeviceObjectType.Input ? SecuredComponents.Input : SecuredComponents.Expansion_Input;

	const handleCancelModal = () => {
		deviceAdminApi.unlockComponentDeviceAdmin(currentDeviceObj.Id, securedComponent);
		const newDevice = { Id: 0, DeviceObjectType: currentDeviceObj.DeviceObjectType, IsModalOpen: false };
		if (cancelHandle) {
			cancelHandle(currentDeviceObj);
		} else {
			dispatch(setCurrentDevice(newDevice));
		}
	};

	const shouldResetSearchColumn = () => {
		if (setRedirectPage) {
			setRedirectPage();
		}
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const handleSaveChanges = async () => {
		setSubmitForm(true);

		let name: string;
		if (!input.Name || input.Name.trim() === '') {
			name = '';
		} else {
			name = input.Name.trim();
		}

		const inputEdited: Input = {
			Id: input.Id,
			ControllerId: input.ControllerId,
			Name: name,
			Enabled: input.Enabled,
			Rqmo: input.Rqmo,
			Rqretrig: input.Rqretrig,
			LineModuleId: input.LineModuleId,
			NormallyOpen: input.NormallyOpen,
			RQEEnabled: input.RQEEnabled,
			RQEType: input.RQEType,
			DoorModeTime: input.DoorModeTime,
			DOTLTime: input.DOTLTime,
			DOTLWaitingTime: input.DOTLWaitingTime,
			EntryDelayTime: input.EntryDelayTime,
			ExitDelayTime: input.ExitDelayTime,
			ArClo: input.ArClo,
			ArOff: input.ArOff,
			Overexmax: input.Overexmax,
			Overexw: input.Overexw,
			ReportDOTLWhenUnlocked: input.ReportDOTLWhenUnlocked,
			MaskTamperWhenDoorContactMasked: input.MaskTamperWhenDoorContactMasked,
			VerifyPassback: input.VerifyPassback,
			MaskDuringTimeZoneSelected: input.MaskDuringTimeZoneSelected,
			DisabledDuringTimeZoneSelected: input.DisabledDuringTimeZoneSelected,
			ActuateRelaysInControlZoneId: input.ActuateRelaysInControlZoneId,
			DisabledRelaysInControlZoneId: input.DisabledRelaysInControlZoneId,
			TriggerRelaysInControlZoneId: input.TriggerRelaysInControlZoneId,
			RetriggerRelaysInControlZoneId: input.RetriggerRelaysInControlZoneId,
			DisabledStateChangeReportingWhenMasked: input.DisabledStateChangeReportingWhenMasked,
			EnabledContactStateChangeReportingDoorContact: input.EnabledContactStateChangeReportingDoorContact,
			EnabledContactStateChangeReportingRQE: input.EnabledContactStateChangeReportingRQE,
			EnabledContactStateChangeReportingTamper: input.EnabledContactStateChangeReportingTamper,
			EnabledContactStateChangeReportingLineFault: input.EnabledContactStateChangeReportingLineFault,
			EnabledContactStateChangeReportingLineFault2: input.EnabledContactStateChangeReportingLineFault2,
			EnabledContactStateChangeReportingLineFault3: input.EnabledContactStateChangeReportingLineFault3,
			EnabledMaskStateChangeReportingDoorRelay: input.EnabledMaskStateChangeReportingDoorRelay,
			EnabledMaskStateChangeReportingRQE: input.EnabledMaskStateChangeReportingRQE,
			EnabledMaskStateChangeReportingEntyExitDelay: input.EnabledMaskStateChangeReportingEntyExitDelay,
			EnabledMaskStateChangeReportingUnlock: input.EnabledMaskStateChangeReportingUnlock,
			EnabledMaskStateChangeReportingTimeZone: input.EnabledMaskStateChangeReportingTimeZone,
			EnabledMaskStateChangeReportingControlCode: input.EnabledMaskStateChangeReportingControlCode,
			MaskingStateChangeEnableFlags: input.MaskingStateChangeEnableFlags,
			MaskTZ: input.MaskTZ,
			TZDentDly: input.TZDentDly,
			GenericTimeZones: [],
			moduleTypeDisabled: input.moduleTypeDisabled,
			inputRQEDisabled: input.inputRQEDisabled,
			WaitingTimeError: input.WaitingTimeError,
		};

		const response =
			currentDeviceObj.DeviceObjectType === DeviceObjectType.Input
				? await deviceAdminApi.editInput(inputEdited)
				: await deviceAdminApi.editExpansionInput(inputEdited);

		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		const toDispatch =
			currentDeviceObj.DeviceObjectType === DeviceObjectType.Input
				? setControllerContacts(DeviceObjectType.Input, paginationSetting)
				: setControllerContacts(DeviceObjectType.ExpansionInputs, paginationSetting);

		NotificationStatus({
			responseData: response,
			onSuccessCallback: () => {
				dispatch(toDispatch);
				shouldResetSearchColumn();
				handleCancelModal();
				if (saveCallBack) {
					saveCallBack();
				}
			},
			onAdditionalInfoRequiredCallback: () => {
				const messages = response.ErrorMessage.split('\n');
				const content = <InformationMessages messages={messages} />;
				ModalWarning({
					title: _('FailedToSyncTimeZone'),
					content,
					onOk: () => handleCancelModal(),
					onCancel: () => handleCancelModal(),
					okText: _('Ok'),
				});
			},
			onFailedValidation: () => {
				setErrorText(response.ErrorMessage);
				setNameError(true);
			},
			onDefaultEventCallback: async () => {
				await deviceAdminApi.unlockComponentDeviceAdmin(currentDeviceObj.Id, securedComponent);
			},
		});
	};

	useEffect(() => {
		if (!nameError) {
			setErrorText('');
		}
	}, [nameError]);

	const isNameEmpty = submittedForm && (!input.Name || input.Name.trim() === '');
	const permissionsEnabled = currentDeviceObj.DeviceObjectType === DeviceObjectType.Input ? canSaveInput : canSaveExpansionInput;

	const contextValue = useMemo(() => ({ contextState: stateContext, dispatcher: dispatchActionContext }), [stateContext, dispatchActionContext]);
	const { Provider } = StoreContext;
	const ATTLValidation = input?.DOTLTime < input?.DOTLWaitingTime;

	return (
		<Provider value={contextValue}>
			<Modal
				width='900px'
				visible={true}
				onCancel={() => handleCancelModal()}
				onClickOk={() => null}
				customZoomClass={styles.withModalZoom}
				title={
					currentDeviceObj.DeviceObjectType === DeviceObjectType.Input
						? `${_('EditInput')} ${currentDeviceObj.Address}`
						: `${_('EditExpansionInput')} ${currentDeviceObj.Address}`
				}
				footer={[
					<Button
						id={currentDeviceObj.DeviceObjectType === DeviceObjectType.Input ? 'inputSaveChangesButton' : 'expansionInputSaveChangesButton'}
						key='save'
						title={getPermissionErrorMessage(permissionsEnabled)}
						type='primary'
						onClick={() => handleSaveChanges()}
						disabled={isLoading || !permissionsEnabled || ATTLValidation}>
						{_('SaveChanges')}
					</Button>,
					<Button
						id={currentDeviceObj.DeviceObjectType === DeviceObjectType.Input ? 'inputCancelButton' : 'expansionInputCancelButton'}
						key='cancel'
						onClick={() => handleCancelModal()}>
						{_('Cancel')}
					</Button>,
				]}>
				<InputContainer
					currentDeviceObj={currentDeviceObj}
					nameValidation={isNameEmpty}
					nameError={nameError}
					onResetNameError={() => setNameError(false)}
					setLoading={setLoading}
					isLoading={isLoading}
					useDoorContext={false}
					errorText={errorText}
					previousSelection={null}
				/>
			</Modal>
		</Provider>
	);
});

export { InputModal };

import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification } from 'antd';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { getFullVelocityUrl, getWebSocketSubscription, numberWithCommas } from '../../../Helper';
import { alarmApi, configurationApi, eventApi, statusApi } from '../../../api';
import { SubPermissions, User } from '../../../model/AccountModel';
import { AlarmDetail, AlarmListResponseObject, AlarmModel, AlarmSoundConfiguration, AlarmSoundInfo } from '../../../model/AlarmModel';
import { StatusSummary } from '../../../model/EventModel';
import { Logger } from '../../../model/LoggingModel';
import { SubscriptionType, WebSocketNotification } from '../../../model/WebSocketModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { setConfiguration, setSocketLiveEventsResponse, setSocketResponse } from '../../../store/common/actions';
import { selectAckAlarmList, selectAlarmList, selectClearedAlarmList, selectStatusSummary } from '../../../store/common/selectors';
import { ModalInfo, OffNormalModal } from '../../common';
import { ServiceNoConnectionModal } from '../ServiceNoConnectionModal/ServiceNoConnectionModal';
import styles from './footer.module.scss';

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const alarmSoundConfiguration: AlarmSoundConfiguration = user.alarmSoundConfiguration;
const canViewOffNormalPoints: boolean = User.getSubComponentPermission(user, SubPermissions.Velocity_Off_NormalWindow_Use).allowed;
const canViewControlServiceManager: boolean = User.getSubComponentPermission(
	user,
	SubPermissions.ServiceControlManager_ServiceControlManager_ChangeSettings
).allowed;
const canViewAlarms: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmViewer_Use).allowed;
const { showStatusBar, showThreatLevel } = user;

const fullVelocityUrl: string = getFullVelocityUrl();
const beepSound = new Audio(`${fullVelocityUrl}Content/Sounds/WindowsBackground.wav`);
const audioIssueHelpLink: string = "https://identivdocs.atlassian.net/wiki/x/CwCQeQ";

const handleOnClickAlarms = (): void => {
	const url: string = `${fullVelocityUrl}Events/EventViewer`;
	location.href = url;
};
const isDisplayingAlarmViewer = (): boolean => {
	const alarmsContainer = document.getElementById('alarmsPageContainer') as HTMLDivElement;
	return alarmsContainer ? true : false;
};

const isDisplayingEventViewer = (): boolean => {
	const eventViewerContainer = document.getElementById('eventViewerContainer') as HTMLDivElement;
	return eventViewerContainer ? true : false;
};
const isAlarmSoundEnabled = (): boolean => {
	return (
		(alarmSoundConfiguration.BackgroundPlay || isDisplayingAlarmViewer()) &&
		(!alarmSoundConfiguration.SilenceCustom || !alarmSoundConfiguration.SilenceBeep)
	);
};
const webSocketCloseCodeSuccess: number = 1000;
const webSocketCloseCodeLeaveTab: number = 1001;
const webSocketReconnectMaxAttempts: number = 10;

const component: React.FC = () => {
	const dispatch = useStoreDispatch();

	const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
	const [displayOffNormalModal, setDisplayOffNormalModal] = useState<boolean>(false);
	const [isShowServiceNoConnectionModal, setIsShowServiceNoConnectionModal] = useState<boolean>(false);
	const [digitracOnline, setDigitracOnline] = useState<boolean>(false);
	const [activeAlarmsCounter, setActiveAlarmsCounter] = useState<number>(0);
	const [ackAlarmsCounter, setAckAlarmsCounter] = useState<number>(0);
	const [offNormalCounter, setOffNormalCounter] = useState<number>(0);
	const [lastAlarmDescription, setLastAlarmDescription] = useState<string>('');
	const [threatLevel, setThreatLevel] = useState<string>(null);
	const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(SubscriptionType.StatusSummary);

	const statusSummary: StatusSummary = useStoreSelector<StatusSummary>(selectStatusSummary);
	const alarmList: AlarmModel[] = useStoreSelector<AlarmModel[]>(selectAlarmList);
	const acknowledgedAlarmList: AlarmModel[] = useStoreSelector<AlarmModel[]>(selectAckAlarmList);
	const clearedAlarmList: AlarmModel[] = useStoreSelector<AlarmModel[]>(selectClearedAlarmList);

	const audioContext = useRef<AudioContext>(undefined);
	const fistTimeLoad = useRef<boolean>(true);
	const breakBeep = useRef<boolean>(false);
	const customSoundLoop = useRef<NodeJS.Timeout>(undefined);
	const beepSoundLoop = useRef<NodeJS.Timeout>(undefined);
	const getFirstAlarmLoop = useRef<NodeJS.Timeout>(undefined);
	const lastActiveId = useRef<number>(-1);
	const incomingAlarm = useRef<boolean>(false);
	const lastMakeDelay = useRef<boolean>(false);
	const alarmPlayingAudioId = useRef<number>(0);
	const reconnectAttemptNumber = useRef<number>(0);

	useEffect(() => {
		Promise.all([eventApi.retrieveLiveEventSummary(), playFirstAlarm(), configurationApi.retrieveConfiguration()]).then(res => {
			const [liveEvents, firstAlarmResponse, configuration] = res;
			dispatch(setSocketLiveEventsResponse(liveEvents));
			dispatch(setConfiguration(configuration));

			if (firstAlarmResponse && firstAlarmResponse.alarmList && firstAlarmResponse.alarmList.length > 0) {
				playIncomingAlarm(firstAlarmResponse.alarmList[0]);
			}

			const photoCallUpContainer = document.getElementById('photoCollectionsGrid') as HTMLDivElement;
			const statusViewerContainer = document.getElementById('statusViewerContainer') as HTMLDivElement;
			const whosInsideContainer = document.getElementById('whosInsideContainer') as HTMLDivElement;
			const statusDashboardContainer = document.getElementById('statusDashboardContainer') as HTMLDivElement;
			const deviceControlContainer = document.getElementById('deviceControlContainer') as HTMLDivElement;
			let currentSubscriptionType: SubscriptionType = SubscriptionType.StatusSummary;
			if (isDisplayingAlarmViewer()) {
				currentSubscriptionType |= SubscriptionType.Alarms;
			}

			if (isDisplayingEventViewer()) {
				currentSubscriptionType |= SubscriptionType.Events;
			}

			if (photoCallUpContainer) {
				currentSubscriptionType |= SubscriptionType.PhotoCallUp;
			}

			if (statusViewerContainer) {
				currentSubscriptionType |= SubscriptionType.StatusViewer;
			}

			if (whosInsideContainer) {
				currentSubscriptionType |= SubscriptionType.WhosInside;
			}

			if (deviceControlContainer) {
				currentSubscriptionType |= SubscriptionType.DeviceControl;
			}

			if (statusDashboardContainer) {
				statusApi
					.retrieveStatusDashboard()
					.then(response => {
						response.WidgetList.forEach(widget => {
							const key = widget.id;
							switch (key) {
								case 'Widget-AccessSummary':
									currentSubscriptionType |= SubscriptionType.AccessSummary;
									break;

								case 'Widget-Downloads':
									currentSubscriptionType |= SubscriptionType.QueuedDownloads;
									break;

								case 'Widget-PerformanceStatus':
									currentSubscriptionType |= SubscriptionType.PerformanceStatus;
									break;

								case 'Widget-Events':
								case 'Widget-EventsSummary':
									currentSubscriptionType |= SubscriptionType.Events;
									break;
							}
						});

						setWebSocketBySubscriptionType(currentSubscriptionType);
					})
					.catch(e => {
						Logger.writeErrorLog(e);
					});
			} else {
				setWebSocketBySubscriptionType(currentSubscriptionType);
			}
		});

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		if (webSocket) {
			webSocket.onmessage = (e: MessageEvent) => {
				const notification = JSON.parse(e.data) as WebSocketNotification;
				dispatch(setSocketResponse(notification));
			};
			webSocket.onerror = (e: Event) => {
				console.log(e);
			};
			webSocket.onopen = (e: Event) => {
				reconnectAttemptNumber.current = 0;
				console.log(`socket opened at: ${new Date(Date.now())}`);
			};
			webSocket.onclose = (e: CloseEvent) => {
				console.log(`socket closed at: ${new Date(Date.now())}`);
				console.log(`code: ${e.code}`);
				console.log(`wasClean: ${e.wasClean}`);

				if (
					e.code !== webSocketCloseCodeSuccess &&
					e.code !== webSocketCloseCodeLeaveTab &&
					reconnectAttemptNumber.current <= webSocketReconnectMaxAttempts
				) {
					setTimeout(() => {
						setWebSocketBySubscriptionType(subscriptionType);
						reconnectAttemptNumber.current += 1;
					}, Math.floor(Math.random() * 1000));
				}
			};
		}
	}, [webSocket]);

	useEffect(() => {
		if (isAlarmSoundEnabled()) {
			const alarms: AlarmModel[] = [...acknowledgedAlarmList, ...clearedAlarmList];
			if (alarms.length && alarms.length > 0) {
				let alarmPlayingSoundDetail: AlarmDetail = null;
				alarms.some((alarmModel: AlarmModel) => {
					const alarmDetail = alarmModel.AlarmDetails.find((alarmDetail: AlarmDetail) => alarmDetail.ActiveAlarmId === alarmPlayingAudioId.current);
					if (alarmDetail) {
						alarmPlayingSoundDetail = alarmDetail;
						return true;
					}
					return false;
				});

				if (alarmPlayingSoundDetail) {
					resetValuesForPlayingIncomingAlarm();
					alarmPlayingAudioId.current = 0;
					if (getFirstAlarmLoop.current) clearTimeout(getFirstAlarmLoop.current);
					getFirstAlarmLoop.current = setTimeout(() => {
						playFirstAlarm().then(x => {
							if (x && x.alarmList && x.alarmList.length > 0) {
								playIncomingAlarm(x.alarmList[0]);
							}
						});
					}, 1500);
				}
			}
		}
	}, [acknowledgedAlarmList, clearedAlarmList]);

	useEffect(() => {
		if (alarmList.length && alarmList.length > 0) {
			playIncomingAlarm(alarmList[0]);
		}
	}, [alarmList]);

	useEffect(() => {
		if (statusSummary && statusSummary.IsUpdated) {
			batch(() => {
				if (!statusSummary.SDConnected) {
					showServiceNoConnectionModal(true);
				}
				setDigitracOnline(statusSummary.DTConnected);
				setActiveAlarmsCounter(statusSummary.NumAlarms);
				setAckAlarmsCounter(statusSummary.NumAckAlarms);
				setOffNormalCounter(statusSummary.OffNormal);
				setThreatLevel(statusSummary.ThreatLevelCaption);
				updateTitle(statusSummary.NumAlarms);
				if (showAlarmBanner() && statusSummary.LastAlarmDescription && lastAlarmDescription !== statusSummary.LastAlarmDescription) {
					setLastAlarmDescription(statusSummary.LastAlarmDescription);
					notification['error']({
						message: statusSummary.LastAlarmDescription,
					});
				}
			});
		}
	}, [statusSummary]);

	const setWebSocketBySubscriptionType = (subscriptionType: SubscriptionType) => {
		console.log(`setWebSocketBySubscriptionType attempt '${reconnectAttemptNumber.current}' for subscriptionType '${subscriptionType}'`);
		const ws: WebSocket = getWebSocketSubscription(subscriptionType);
		setSubscriptionType(subscriptionType);
		setWebSocket(ws);
	};

	const handleBeforeUnload = () => {
		if (webSocket) {
			webSocket.close();
		}
	};

	const showAlarmBanner = (): boolean => {
		const input = document.getElementById('ShowAlarmBannerHidden') as HTMLInputElement;

		return input.value === '1';
	};

	const updateTitle = (numAlarms: number) => {
		let appendCount = false;
		let title = document.title;
		const index = title.indexOf('(');

		if (index > 0) {
			title = title.substring(0, index).trim();
			if (numAlarms > 0) {
				appendCount = true;
			}
		} else if (numAlarms > 0) {
			appendCount = true;
		}

		if (appendCount) {
			title = `${title} (${numberWithCommas(numAlarms)})`;
		}

		document.title = title;
	};

	const showServiceNoConnectionModal = (visible: boolean) => setIsShowServiceNoConnectionModal(visible);

	const playFirstAlarm = (): Promise<AlarmListResponseObject> => {
		if (isAlarmSoundEnabled()) {
			return alarmApi.getStoredAlarms(1, 1000, false, true);
		}

		return undefined;
	};

	const playIncomingAlarm = (alarmToPlayAudio: Partial<AlarmModel>) => {
		if (isAlarmSoundEnabled()) {
			resetValuesForPlayingIncomingAlarm();
			let alarmId: number = 0;
			if (alarmToPlayAudio && alarmToPlayAudio.AlarmDetails && alarmToPlayAudio.AlarmDetails.length > 0) {
				alarmId = alarmToPlayAudio?.AlarmDetails[0]?.ActiveAlarmId;
			}
			alarmPlayingAudioId.current = alarmId;
			playSound(alarmToPlayAudio?.AlarmSoundInfo ? alarmToPlayAudio?.AlarmSoundInfo : ({ ActiveId: alarmId } as AlarmSoundInfo));
		}
	};

	const playSound = (alarmSoundInfo: AlarmSoundInfo) => {
		if (alarmSoundInfo?.Data) {
			const contextForLoadFile: AudioContext = new AudioContext();
			const alarmSound: string = atob(alarmSoundInfo.Data);
			const buffer: ArrayBuffer = new ArrayBuffer(alarmSound.length);
			const view: Uint8Array = new Uint8Array(buffer);

			for (var n = 0; n < alarmSound.length; n++) {
				view[n] = alarmSound.charCodeAt(n);
			}

			contextForLoadFile
				.decodeAudioData(buffer)
				.then(decodedAudio => {
					playWave(decodedAudio);
					breakBeep.current = true;
				})
				.catch(e => {
					if (alarmSoundInfo.Data.length === 0 || !alarmSoundConfiguration.MakeDelay) {
						playBeep();
						breakBeep.current = false;
					} else {
						Logger.writeErrorLog(e);
					}
				});
		} else {
			playBeep();
			breakBeep.current = false;
		}
	};

	const playWave = (soundArrayBuffer: AudioBuffer) => {
		resetComponent();
		const source: AudioBufferSourceNode = audioContext.current.createBufferSource();
		source.buffer = soundArrayBuffer;
		source.connect(audioContext.current.destination);
		if (!alarmSoundConfiguration.SilenceCustom) {
			source.start();
		} else {
			source.start();
			source.stop();
		}
		source.onended = () => {
			if (alarmSoundConfiguration.MakeDelay) {
				customSoundLoop.current = setTimeout(() => {
					if (lastActiveId.current != 0) {
						playWave(soundArrayBuffer);
					}
				}, alarmSoundConfiguration.DelaySeconds);
			} else if (!incomingAlarm.current) {
				playBeep();
				breakBeep.current = false;
			} else {
				if (lastMakeDelay.current) {
					setTimeout(() => {
						lastActiveId.current = -1;
						incomingAlarm.current = false;
					}, alarmSoundConfiguration.DelaySeconds);
				} else {
					playBeep();
					breakBeep.current = false;
				}
			}
		};
		if (audioContext.current.state === 'suspended') {
			if (fistTimeLoad.current) {
				fistTimeLoad.current = false;
				displaySoundModalError();

				audioContext.current.resume();
			}
			setTimeout(() => {
				playWave(soundArrayBuffer);
			}, 1500);
		}
	};

	const resetComponent = () => {
		if (audioContext.current) audioContext.current.close();
		if (beepSoundLoop.current) clearTimeout(beepSoundLoop.current);
		audioContext.current = new window.AudioContext();
		beepSound.currentTime = 0;
	};

	const playBeep = () => {
		resetComponent();
		if (!alarmSoundConfiguration.SilenceBeep && lastActiveId.current != 0) {
			var promise = beepSound.play();
			if (promise !== undefined) {
				promise
					.then(() => {
						//audio is playing
					})
					.catch(() => {
						if (audioContext.current.state === 'suspended' && fistTimeLoad.current) {
							fistTimeLoad.current = false;
							displaySoundModalError();
						}
					});
			}
		}
		beepSoundLoop.current = setTimeout(() => {
			if (!alarmSoundConfiguration.SilenceBeep && !breakBeep.current) {
				playBeep();
			}
		}, 500);
	};

	const resetValuesForPlayingIncomingAlarm = () => {
		if (customSoundLoop.current) clearTimeout(customSoundLoop.current);
		beepSound.pause();
		incomingAlarm.current = true;
		resetComponent();
	};

	const displaySoundModalError = () => {
		const content = <p>
							<span>{`${_('SoundCannotBePlayed')}. ${_('PleaseFollowInstructions')} `}</span>
							<a id='audioHelpLink' href={audioIssueHelpLink} target='_blank'>{_('InOurDocumentation')}</a>
							<span>{' '}{_('ForEnablingTheSound')}.</span>
						</p>;

		ModalInfo({
			content: content,
			onOk: () => null,
			onCancel: () => null,
			okText: _('Ok'),
			width: '40%'
		});
	}

	let content: React.ReactElement = <></>;
	if (showStatusBar) {
		content = (
			<div className={styles.container}>
				{canViewControlServiceManager && (
					<div id='footerDigitracServiceContainer'>
						{digitracOnline ? <CheckCircleOutlined className={styles.onlineIcon} /> : <CloseCircleOutlined className={styles.offlineIcon} />}
						<span className={styles.footerText}>
							{_('DIGITRACService')}: {digitracOnline ? _('Online') : _('Offline')}
						</span>
					</div>
				)}
				{canViewAlarms && (
					<>
						<div>
							<ExclamationCircleOutlined
								className={cx(undefined, {
									[styles.disableActiveAlarmsCounter]: activeAlarmsCounter < 1,
									[styles.activeExclamationIcon]: activeAlarmsCounter > 0,
								})}
							/>
							<Button id='footerActiveAlarmsButton' type='link' onClick={handleOnClickAlarms}>
								<strong data-leavetab={true}>
									{_('ActiveAlarms')}: {numberWithCommas(activeAlarmsCounter)}
								</strong>
							</Button>
						</div>
						<div>
							<ExclamationCircleOutlined
								className={cx(undefined, {
									[styles.disableAckAlarmsCounter]: ackAlarmsCounter < 1,
									[styles.ackExclamationIcon]: ackAlarmsCounter > 0,
								})}
							/>
							<Button id='footerAckAlarmsButton' type='link' onClick={handleOnClickAlarms}>
								<strong data-leavetab={true}>
									{_('AckAlarms')}: {numberWithCommas(ackAlarmsCounter)}
								</strong>
							</Button>
						</div>
					</>
				)}
				{canViewOffNormalPoints && (
					<Button
						id='footerOffNormalButton'
						type='link'
						onClick={() => {
							setDisplayOffNormalModal(true);
						}}>
						<span>
							<strong>
								{_('OffNormal')}: {numberWithCommas(offNormalCounter)}
							</strong>
						</span>
					</Button>
				)}
				{showThreatLevel && (
					<div id='footerThreatLevel'>
						<span className={styles.footerText}>
							{_('ThreatLevel')}: {threatLevel}
						</span>
					</div>
				)}
				{displayOffNormalModal && (
					<OffNormalModal
						onClose={() => {
							setDisplayOffNormalModal(false);
						}}
					/>
				)}
				{isShowServiceNoConnectionModal && <ServiceNoConnectionModal onSetVisible={showServiceNoConnectionModal} />}
			</div>
		);
	}

	return content;
};

const Footer = React.memo(component);

export { Footer };

import { CriteriaSearchRequest } from './CommonModel';

export type Reports = {
	GroupId: number;
	ReportGroupName: string;
	ReportsInfo: Report[];
};

export type Report = {
	Name: string;
	ReportId: number;
} & ReportUDF;

export type ReportRequestParameters = CriteriaSearchRequest & {
	Sorting: ReportSortingRequest[];
	ReportId: number;
	ReturnFilePathOnly: boolean;
	AckArchiveNotice: boolean;
	ExportFormat: ReportExportFormat;
};

export type ReportSortingRequest = {
	FieldId: number;
	Direction: ReportSorting;
};

export type ReportArchiveListInfo = {
	Name: string;
	Exists: boolean;
};

export type ReportFile = {
	ReportFileData: number[];
	Name: string;
	FileName: string;
	FilePath: string;
	Extension: string;
};

export type ReportUDF = {
	UDF1: number;
	UDF2: number;
	UDF3: number;
};

export type SortingInfo = ReportSortingRequest & { key: number; fieldName: string; sortingName: string };

export enum ReportSorting {
	none,
	asc,
	desc,
}

export enum ReportExportFormat {
	CSV = 5,
	PDF = 31,
	HTML = 32,
	Xls = 38,
}

export type ReportUDFInfo = {
	ReportId: number;
} & ReportUDF;

import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { PortStoreContext, setMaxRetryAttempts, setPortChangedAction } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const MaxRetryAttempt: React.FC<Props> = () => {
	const {
		portState: { maxRetryAttempts, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChange = (value: string) => {
		if (!(Number(value) >= 0)) {
			return;
		}
		dispatcher(setMaxRetryAttempts(Number(value)));
		checkIfPortChanged();
	};

	const portGeneralMaxRetryAttemptsInputId = 'portGeneralMaxRetryAttemptsInput';

	return (
		<div className={styles.maxRetryAttemptsDiv}>
			<label id='portGeneralMaxRetryAttemptsLabel' className={styles.maxRetryAttemps}>
				{_('MaxRetryAttempts')}
			</label>
			<InputNumber
				id={portGeneralMaxRetryAttemptsInputId}
				key='maxRetryAttempts'
				className={styles.inputNumber}
				value={maxRetryAttempts}
				min={0}
				max={99}
				step={1}
				maxLength={2}
				onChange={handleChange}
				formatter={noneDecimalFormatter}
			/>
			<label id='portGeneralInfiniteTriesLabel' htmlFor={portGeneralMaxRetryAttemptsInputId} className={styles.infiniteTries}>
				{_('InfiniteTries')}
			</label>
		</div>
	);
};

export { MaxRetryAttempt };

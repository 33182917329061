import React from 'react';
import { CredentialTemplateModalModel } from '../CredentialTemplateModalModels';
import { ActionContext, ContextActionTypes } from './actionTypes';

export const getCredentialInitialState = (): CredentialTemplateModalModel => {
	return {
		alertModalObj: {
			children: undefined,
			hidden: true,
		},
		configData: {
			CCMVersionSupportsHRecord: undefined,
			CustomAccessZones: undefined,
			EdgeEnabledStatus: false,
			IsDeviceAdminCredentialTemplate: undefined,
			OverlapAccessFlag: undefined,
			RelativeShortcuts: undefined,
			SecurePINChecking: undefined,
			UserDefinedFieldSetups: undefined,
			IndexedCommandsOptions: [],
		},
		credentialMessages: undefined,
		currentPersonId: 0,
		errors: {
			keypadCode: undefined,
			matchCode: undefined,
			name: undefined,
			other: undefined,
		},
		fetchedCredential: false,
		fetchInitialServices: false,
		functionsTabState: {
			controllers: undefined,
			controlZones: undefined,
			credentialFunctions: undefined,
			doorGroups: undefined,
			functionCategories: undefined,
			functionGroups: undefined,
		},
		generalTabState: {
			activationExpirationSectionState: {
				activationDate: undefined,
				expiration: undefined,
				expirationFunction: 0,
				expirationUsed: false,
				expirationUsedChanged: false,
				hostExpirationUDF: 0,
				hostExpirationInterval: 0,
				hostExpirationOffset: '',
			},
			cardSectionState: {
				cardData: '',
				cardFormats: undefined,
				cardStamp: '',
				cardType: undefined,
				concatenatedData: undefined,
				saveConcatenatedData: false,
				matchCode: '',
				hasUDFDataSelected: false,
			},
			credentialId: -1,
			credentialTemplateId: -1,
			IDF: 1,
			isCredentialTemplate: undefined,
			informationSectionState: {
				badgeTemplate: 0,
				badgeTemplates: undefined,
				credentialDescription: '',
				credentialTemplates: undefined,
				linkedTemplateId: 0,
				linkToCredential: undefined,
				isLinkToConfirmed: undefined,
				linkToNewCredentials: false,
			},
			keypadSectionState: {
				alwaysHidePin: false,
				duressDigit: undefined,
				pinCode: undefined,
				pinCodeConfirmMasked: undefined,
				pinCodeLength: 4,
				pinCodeMasked: undefined,
				showClearPin: false,
			},
		},
		hasChanged: false,
		lastCredentialId: -2,
		limitsTabState: {
			globalize: false,
			limitCount: 1,
			limitRule: 0,
			threatLevel: 0,
			threatLevels: undefined,
			twoPersonRule: 0,
		},
		linkedInfoOnly: false,
		loading: true,
		modelEstablished: false,
		optionsTabState: {
			alertAction: false,
			credentialReissueInfo: undefined,
			currentIssueStatus: '',
			currentIssueStatusDropdown: '',
			disableAction: false,
			enableIssueControl: false,
			enablePrintControl: false,
			executiveOverride: false,
			isCommandSetsTableDisabled: false,
			issueControlCount: 1,
			issueControlMax: 0,
			printControlAttempts: 0,
			printControlConfirmation: false,
			printControlCount: 0,
			printControlMax: 0,
			readerControlGroup: 0,
			readerControlGroups: undefined,
			scrambleNormal: false,
			specialNeeds: false,
			tagAction: false,
		},
		previousLinkNewValue: false,
		restartedModel: false,
		userId: undefined,
		CodeOverrideAnswer: false,
		DualOverrideAnswer: false,
		PinOverrideAnswer: false,
	};
};

const credentialInitialState: CredentialTemplateModalModel = getCredentialInitialState();

const credentialTemplateContextReducer = (state: Readonly<CredentialTemplateModalModel>, action: ActionContext): CredentialTemplateModalModel => {
	const { type } = action;
	switch (type) {
		//
		case ContextActionTypes.INIT_CONFIG_DATA: {
			const {
				CCMVersionSupportsHRecord,
				CustomAccessZones,
				EdgeEnabledStatus,
				IsDeviceAdminCredentialTemplate,
				OverlapAccessFlag,
				RelativeShortcuts,
				SecurePINChecking,
				UserDefinedFieldSetups,
				IndexedCommandsOptions,
			} = action.payload;
			return {
				...state,
				configData: {
					...state.configData,
					CCMVersionSupportsHRecord,
					CustomAccessZones,
					EdgeEnabledStatus,
					IsDeviceAdminCredentialTemplate,
					OverlapAccessFlag,
					RelativeShortcuts,
					SecurePINChecking,
					UserDefinedFieldSetups,
					IndexedCommandsOptions,
				},
			};
		}
		case ContextActionTypes.SET_COMPLETE_MODEL: {
			const {
				fetchedCredential,
				fetchInitialServices,
				functionsTabState,
				generalTabState,
				hasChanged,
				limitsTabState,
				linkedInfoOnly,
				modelEstablished,
				optionsTabState,
				restartedModel,
				previousLinkNewValue,
			} = action.payload;
			return {
				...state,
				configData: {
					...state.configData,
				},
				fetchInitialServices,
				fetchedCredential,
				functionsTabState: {
					...state.functionsTabState,
					...functionsTabState,
				},
				generalTabState: {
					...state.generalTabState,
					credentialId: generalTabState.credentialId,
					credentialTemplateId: generalTabState.credentialTemplateId,
					IDF: generalTabState.IDF,
					isCredentialTemplate: generalTabState.isCredentialTemplate,
					activationExpirationSectionState: {
						...state.generalTabState.activationExpirationSectionState,
						...generalTabState.activationExpirationSectionState,
					},
					cardSectionState: {
						...state.generalTabState.cardSectionState,
						...generalTabState.cardSectionState,
					},
					informationSectionState: {
						...state.generalTabState.informationSectionState,
						...generalTabState.informationSectionState,
					},
					keypadSectionState: {
						...state.generalTabState.keypadSectionState,
						...generalTabState.keypadSectionState,
					},
				},
				hasChanged,
				limitsTabState: {
					...state.limitsTabState,
					...limitsTabState,
				},
				linkedInfoOnly,
				modelEstablished,
				optionsTabState: {
					...state.optionsTabState,
					...optionsTabState,
				},
				previousLinkNewValue,
				restartedModel,
			};
		}
		case ContextActionTypes.SET_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					...action.payload,
				},
			};
		case ContextActionTypes.SET_ROOT_MODEL_PROPERTY:
			return {
				...state,
				...action.payload,
			};
		case ContextActionTypes.SET_OVERRIDE_ANSWER:
			return {
				...state,
				...action.payload,
			};
		// GeneralTab
		case ContextActionTypes.SET_CRED_TEMPLATE_ID:
			return {
				...state,
				generalTabState: {
					...state.generalTabState,
					credentialTemplateId: action.payload,
				},
			};
		case ContextActionTypes.SET_GENERAL_TAB_SERVICES_DATA:
			return {
				...state,
				generalTabState: {
					...state.generalTabState,
					cardSectionState: {
						...state.generalTabState.cardSectionState,
						cardFormats: action.payload.cardFormats,
					},
					informationSectionState: {
						...state.generalTabState.informationSectionState,
						badgeTemplates: action.payload.badgeTemplates,
						credentialTemplates: action.payload.credentialTemplates,
					},
				},
			};
		case ContextActionTypes.SET_GT_IDF:
			return {
				...state,
				hasChanged: true,
				generalTabState: {
					...state.generalTabState,
					IDF: action.payload,
				},
			};
		// --- Activation/Expiration Section
		case ContextActionTypes.SET_ACTIVATION_EXPIRATION_PROPERTY: {
			return {
				...state,
				generalTabState: {
					...state.generalTabState,
					activationExpirationSectionState: {
						...state.generalTabState.activationExpirationSectionState,
						...action.payload,
					},
				},
			};
		}
		// --- CardSection --- //
		case ContextActionTypes.SET_CARD_PROPERTY:
			return {
				...state,
				generalTabState: {
					...state.generalTabState,
					cardSectionState: {
						...state.generalTabState.cardSectionState,
						...action.payload,
					},
				},
			};
		// --- InformationSection --- //
		case ContextActionTypes.SET_INFORMATION_PROPERTY:
			return {
				...state,
				generalTabState: {
					...state.generalTabState,
					informationSectionState: {
						...state.generalTabState.informationSectionState,
						...action.payload,
					},
				},
			};
		// --- KeypadSection
		case ContextActionTypes.SET_KEYPAD_PROPERTY:
			return {
				...state,
				hasChanged: true,
				generalTabState: {
					...state.generalTabState,
					keypadSectionState: {
						...state.generalTabState.keypadSectionState,
						...action.payload,
					},
				},
			};
		// OptionsTab
		case ContextActionTypes.SET_OPTION_TAB_PROPERTY:
			return {
				...state,
				optionsTabState: {
					...state.optionsTabState,
					...action.payload,
				},
			};
		// LimitsTab
		case ContextActionTypes.SET_LIMITS_TAB_PROPERTY:
			return {
				...state,
				limitsTabState: {
					...state.limitsTabState,
					...action.payload,
				},
			};
		// FunctionsTab
		case ContextActionTypes.SET_CONTROL_ZONES_ARRAY:
			return {
				...state,
				functionsTabState: {
					...state.functionsTabState,
					controlZones: action.payload,
				},
			};
		case ContextActionTypes.SET_CREDENTIAL_FUNCTIONS_ARRAY:
			return {
				...state,
				functionsTabState: {
					...state.functionsTabState,
					credentialFunctions: action.payload,
				},
			};
		case ContextActionTypes.SET_FUNCTION_TAB_SERVICES_DATA:
			return {
				...state,
				functionsTabState: {
					...state.functionsTabState,
					controllers: action.payload.controllers,
					doorGroups: action.payload.doorGroups,
					functionCategories: action.payload.functionCategories,
					functionGroups: action.payload.functionGroups,
				},
			};

		default:
			return { ...state };
	}
};

type CredentialTemplateModalContext = {
	credentialInitialState: Readonly<CredentialTemplateModalModel>;
	dispatcher: React.Dispatch<ActionContext>;
};

const CredentialStoreContext = React.createContext<CredentialTemplateModalContext>({ credentialInitialState, dispatcher: null });

export { credentialTemplateContextReducer, CredentialStoreContext, credentialInitialState };

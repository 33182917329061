// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".draggableContainer{position:relative;overflow:hidden}.door-status-container .status-widget-collapse .ant-collapse-content-box{padding:0px}.door-status-container .status-widget>.ant-collapse-header{font-size:15px !important;font-weight:600}.door-status-container .status-widget-collapse-content .ant-collapse-content{min-height:353px !important;max-height:353px !important}", "",{"version":3,"sources":["webpack://src/components/widget/DoorStatus/doorStatus.scss"],"names":[],"mappings":"AAAA,oBACC,iBAAA,CACA,eAAA,CAGD,yEACE,WAAA,CAGF,2DACC,yBAAA,CACA,eAAA,CAGD,6EACE,2BAAA,CACA,2BAAA","sourcesContent":[".draggableContainer {\r\n\tposition: relative;\r\n\toverflow: hidden;\r\n}\r\n\r\n.door-status-container .status-widget-collapse .ant-collapse-content-box {\r\n\t\tpadding: 0px;\r\n\t}\r\n\r\n.door-status-container .status-widget > .ant-collapse-header {\r\n\tfont-size: 15px !important;\r\n\tfont-weight: 600;\r\n\t}\r\n\r\n.door-status-container .status-widget-collapse-content .ant-collapse-content {\r\n\t\tmin-height: 353px !important;\r\n\t\tmax-height: 353px !important;\r\n\t}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggableContainer": "draggableContainer",
	"door-status-container": "door-status-container",
	"status-widget-collapse": "status-widget-collapse",
	"ant-collapse-content-box": "ant-collapse-content-box",
	"status-widget": "status-widget",
	"ant-collapse-header": "ant-collapse-header",
	"status-widget-collapse-content": "status-widget-collapse-content",
	"ant-collapse-content": "ant-collapse-content"
};
export default ___CSS_LOADER_EXPORT___;

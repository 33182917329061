import { Button, Radio, RadioChangeEvent, Space, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import { buildColumn, ColumnsProps, ScrollType } from '../../../../../Helper';
import { BaseColumns, SelectOptions } from '../../../../../model/CommonModel';
import { OSDPLEDOption, SelectOption as SelectOptionModel } from '../../../../../model/DeviceAdminModel';
import { Modal, Select, SelectOption as SelectOptionComponent } from '../../../../common';
import styles from './osdpColorModal.module.scss';

type OSDPLEDOptionDataSource = OSDPLEDOption & BaseColumns;

type Props = {
	hideModal: () => void;
	readerId: number;
	useCustomColor: boolean;
	colorOptions: SelectOptionModel[];
	osdpLEDOptions: OSDPLEDOption[];
	setOSDPLEDOptions: (value: OSDPLEDOption[]) => void;
	setUseCustomOSDPLEDColor: (value: boolean) => void;
	setChangedOSDPLEDColor: (value: boolean) => void;
};

const scroll: ScrollType = { y: 272 };

const OSDPColorsModal: React.FC<Props> = ({
	readerId,
	osdpLEDOptions,
	colorOptions,
	useCustomColor,
	hideModal,
	setOSDPLEDOptions,
	setUseCustomOSDPLEDColor,
	setChangedOSDPLEDColor,
}) => {
	const [readerLEDOption, setReaderLEDOption] = useState<boolean>(useCustomColor);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dataSource, setDataSource] = useState<OSDPLEDOptionDataSource[]>([]);

	useEffect(() => {
		if (osdpLEDOptions === undefined || osdpLEDOptions.length === 0) {
			setIsLoading(true);
			deviceAdminApi
				.getOSDPLEDOptions(readerId)
				.then(response => {
					if (response && response.length > 0) {
						setOSDPLEDOptions(response);
						const newDataSource: OSDPLEDOptionDataSource[] = response.map<OSDPLEDOptionDataSource>((option: OSDPLEDOption) => ({
							...option,
							key: option.Index,
						}));
						setDataSource(newDataSource);
					}
				})
				.finally(() => setIsLoading(false));
		} else {
			const newDataSource: OSDPLEDOptionDataSource[] = osdpLEDOptions.map<OSDPLEDOptionDataSource>((option: OSDPLEDOption) => ({
				...option,
				key: option.Index,
			}));
			setDataSource(newDataSource);
		}
	}, []);

	const handleOnOk = () => {
		setChangedOSDPLEDColor(true);
		if (useCustomColor) {
			setOSDPLEDOptions(dataSource);
		}
		hideModal();
	};

	const handleSelectCheckRadio = (e: RadioChangeEvent) => {
		setUseCustomOSDPLEDColor(e.target.value);
		setReaderLEDOption(e.target.value);
	};

	const handleOnChangeLEDOptionValue = (value: number, index: number) => {
		const clonedState: OSDPLEDOptionDataSource[] = [...dataSource];
		const currentOption: OSDPLEDOptionDataSource = clonedState.find((option: OSDPLEDOptionDataSource) => option.Index === index);
		if (currentOption) {
			currentOption.Value = value;
			setDataSource(clonedState);
		}
	};

	const options: SelectOptionComponent = colorOptions.map<SelectOptions<number>>((option: SelectOptionModel) => ({ label: option.Name, value: option.Id }));

	const columns: ColumnsProps<OSDPLEDOptionDataSource>[] = [
		{
			...buildColumn(_('LEDOption'), 'Name', 'auto', 'start'),
			render: (name: string, row: OSDPLEDOptionDataSource) => <label htmlFor={`additionalColor-${row.Index}`}>{name}</label>,
		},
		{
			...buildColumn(_('LEDColor'), 'Index', 'auto', 'start'),
			render: (index: number, row: OSDPLEDOptionDataSource) => (
				<Select
					notUseCurrentParse
					id={`additionalColor-${row.Index}`}
					options={options}
					value={row.Value}
					onChange={value => handleOnChangeLEDOptionValue(Number(value), row.Index)}
					getPopupContainer={element => element.parentElement}
					className={styles.select}
				/>
			),
		},
	];

	return (
		<Modal
			visible={true}
			title={_('OSDPCustomLEDColors')}
			onCancel={hideModal}
			width='450px'
			customZoomClass={styles.modal}
			nestedModal={true}
			footer={[
				<Button id='saveOSDPColorsButton' type='primary' key='save' onClick={handleOnOk}>
					{_('Ok')}
				</Button>,
				<Button id='cancelOSDPColorsButton' key='cancel' onClick={hideModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div className={styles.container}>
					<span>{_('DefineTheColorForLEDOptions')}:</span>
					<Radio.Group defaultValue={useCustomColor} onChange={handleSelectCheckRadio} value={readerLEDOption}>
						<Space direction='vertical'>
							<Radio id='useDefaultsRadioButton' value={false}>
								{_('UseDefaults')}
							</Radio>
							<Radio id='useCustomRadioButton' value={true}>
								{_('UseCustomLEDColors')}
							</Radio>
						</Space>
					</Radio.Group>
					<div>
						<Table
							scroll={scroll}
							size='small'
							columns={columns}
							dataSource={useCustomColor ? dataSource : []}
							className={styles.table}
							pagination={false}
							rowClassName={(record, index) => {
								if (index % 2 !== 0) {
									return styles.evenRow;
								}
							}}
						/>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export { OSDPColorsModal };

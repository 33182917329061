import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';

type Props = {
	useDoorContext: boolean;
};

const ModuleEnabled: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleChange = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ Enabled: e.target.checked }));
	};

	return (
		<Checkbox id='Enabled' onChange={handleChange} checked={inputContext.context?.input?.Enabled}>
			{_('Enabled')}
		</Checkbox>
	);
};

export { ModuleEnabled };

import { Checkbox, Col, InputNumber, Radio, RadioChangeEvent, Row, Select, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { noneDecimalFormatter } from '../../../../../Helper';
import '../../../../../resources/style/main.scss';
import { LimitsTabProperty } from '../../CredentialTemplateModalModels';
import { setLimitsTabPropertyAction, setRootModelPropertyAction } from '../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../credentialTemplateContext/context';
import styles from '../../credentialmodal.module.scss';

const maxLimit = 255;
const maxLimitAbsent = 254;

export const CredentialTemplateModalLimitsTab: React.FC = () => {
	const {
		credentialInitialState: {
			configData: { IsDeviceAdminCredentialTemplate },
			generalTabState: {
				cardSectionState: { saveConcatenatedData },
				IDF,
				informationSectionState: { credentialTemplates, linkToCredential },
			},
			limitsTabState: { globalize, limitCount, limitRule, threatLevel, threatLevels, twoPersonRule },
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	const [isTabDisabled, setIsTabDisabled] = useState<boolean>(false);
	const [maxLimitCount, setMaxLimitCount] = useState(255);

	useEffect(() => {
		setIsTabDisabled(false);
		if (IsDeviceAdminCredentialTemplate && IDF === 0) setIsTabDisabled(true);
		else if (linkToCredential) setIsTabDisabled(true);
		else if (IDF === 0) setIsTabDisabled(true);
	}, [IDF]);

	useEffect(() => {
		linkToChkChanged();
	}, [linkToCredential]);

	const linkToChkChanged = () => {
		if (credentialTemplates?.length > 0) {
			if (linkToCredential) {
				setIsTabDisabled(true);
			} else {
				setIsTabDisabled(false);
			}
		}
	};

	const handleChangeLimitsProperty = (value: LimitsTabProperty) => {
		batch(() => {
			dispatcher(setRootModelPropertyAction({ hasChanged: true }));
			dispatcher(setLimitsTabPropertyAction(value));
		});
	};

	const handleChangeLimitRule = (e: RadioChangeEvent) => {
		let { value } = e.target;
		const values: Partial<LimitsTabProperty> = {
			limitRule: value,
		};
		if (value === 3) {
			setMaxLimitCount(maxLimitAbsent);
			if (limitCount > maxLimitAbsent) values.limitCount = maxLimitAbsent;
		} else setMaxLimitCount(maxLimit);

		handleChangeLimitsProperty(values);
	};

	return (
		<>
			{threatLevels?.length > 0 && (
				<div className={styles.container}>
					<div className={styles.form} style={{ paddingBottom: 5 }}>
						<Row>
							<Col span={24}>
								<div className={styles.sections} style={{ gridTemplateColumns: '50% 50%' }}>
									<div>
										<Checkbox
											checked={globalize}
											disabled={isTabDisabled}
											id='globalize'
											name='globalize'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeLimitsProperty({ globalize: e.target.checked });
											}}
										/>
										<label htmlFor='globalize'>{` ${_('ApplyCredentialManagementGlobally')}`}</label>
									</div>
									<div>
										<label htmlFor='threatLevels' style={{ marginRight: 25 }}>
											{_('ThreatAuthority')}:
										</label>
										<Select
											className={styles.select}
											disabled={isTabDisabled}
											id='threatLevels'
											getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
											options={threatLevels.map(tl => ({
												key: tl.ThreatId,
												label: tl.Name,
												value: tl.ThreatId,
											}))}
											onChange={value => {
												handleChangeLimitsProperty({ threatLevel: value });
											}}
											style={{ width: 210 }}
											value={threatLevel}
										/>
									</div>
								</div>
							</Col>
						</Row>
						<Row className={styles.radioButtonsContainer} style={{ marginTop: 15 }}>
							<Col span={12}>
								<div className={cx(styles.containerLegend, 'fieldsetBorder')}>
									<fieldset>
										<legend className={styles.legend}>{`${_('Day')}/${_('Use')}/${_('AbsenteeLimits')}`}</legend>
										<Row>
											<Col span={12}>
												<Radio.Group disabled={isTabDisabled} onChange={handleChangeLimitRule} value={limitRule}>
													<Space direction='vertical'>
														<Radio id='limitRule_0' value={0}>
															{_('NoLimit')}
														</Radio>
														<Radio id='limitRule_1' value={1}>
															{_('DayLimit')}
														</Radio>
														<Radio id='limitRule_2' value={2}>
															{_('UseLimit')}
														</Radio>
														<Radio id='limitRule_3' value={3}>
															{_('AbsenteeLimit')}
														</Radio>
													</Space>
												</Radio.Group>
											</Col>
											<Col span={12} style={{ marginTop: 5, textAlign: 'right' }}>
												<label htmlFor='limitCount'>
													<span>{`${_('Day')}/${_('Use')} (1-255)`}</span>
													<br />
													<span>{`${_('Absentee')} (1-254)`}</span>
													<br />
												</label>
												<InputNumber
													aria-label={_('limitCount')}
													className={styles.inputNumber}
													disabled={isTabDisabled}
													id='limitCount'
													max={maxLimitCount}
													min={1}
													name='limitCount'
													onChange={(value: React.ReactText) => {
														if (value) {
															handleChangeLimitsProperty({ limitCount: Number(value.toString()) });
														}
													}}
													size='small'
													style={{ marginTop: 10 }}
													value={limitCount}
													formatter={noneDecimalFormatter}
												/>
											</Col>
										</Row>
									</fieldset>
								</div>
							</Col>
							<Col span={12}>
								<div className={cx(styles.containerLegend, 'fieldsetBorder')}>
									<fieldset>
										<legend className={styles.legend}>{_('TwoPersonRule')}</legend>
										<div style={{ width: '100%' }}>
											<Radio.Group
												disabled={isTabDisabled}
												onChange={(e: RadioChangeEvent) => {
													handleChangeLimitsProperty({ twoPersonRule: e.target.value });
												}}
												value={twoPersonRule}>
												<Space direction='vertical'>
													<Radio id='twoPersonRule_0' value={0}>
														{_('Normal')}
													</Radio>
													<Radio id='twoPersonRule_1' value={1}>
														{_('RuleA')}
													</Radio>
													<Radio id='twoPersonRule_2' value={2}>
														{_('RuleB')}
													</Radio>
													<Radio id='twoPersonRule_3' value={3}>
														{_('ExecutiveOverride')}
													</Radio>
												</Space>
											</Radio.Group>
										</div>
									</fieldset>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			)}
		</>
	);
};

export default CredentialTemplateModalLimitsTab;

import { Button } from 'antd';
import React, { useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { PaginationSetting } from '../../../../model/CommonModel';
import { CurrentDeviceControlObj, DeviceObjectType, ExpansionRelayInfo } from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { setControllerContacts } from '../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { InformationMessages, Modal, ModalWarning, NotificationStatus, WithLockedValidation } from '../../../common';
import { RelayLogicContainer } from './RelayLogicContainer/RelayLogicContainer';
import { StoreContext, expansionRelayContext, expansionRelayState } from './contextExpansionRelay';
import styles from './expansionRelayModal.module.scss';

type Props = {
	currentDeviceObj: CurrentDeviceControlObj;
	setShouldResetSearchColumn: () => void;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	setRedirectPage?: () => void;
	saveCallBack?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const canSaveRelay = User.getComponentPermission(user, SecuredComponents.Relay).canUpdate;
const canSaveExpansionRelay = User.getComponentPermission(user, SecuredComponents.Expansion_Relay).canUpdate;

const ExpansionRelayModal: React.FC<Props> = WithLockedValidation(
	({ currentDeviceObj, setShouldResetSearchColumn, cancelHandle, saveCallBack, setRedirectPage }) => {
		const dispatch = useStoreDispatch();
		const [stateContext, dispatchActionContext] = useReducer(expansionRelayContext, expansionRelayState);
		const [isLoading, setLoading] = useState(true);
		const [nameError, setNameError] = useState(false);
		const [nameErrorText, setNameErrorText] = useState('');
		const [submittedForm, setSubmitForm] = useState(false);

		const {
			name,
			normalState,
			doorDelayTime,
			controlModeTime,
			controlDelayTime,
			controllerId,
			operateTimeZones,
			actuateTimeZones,
			disableTimeZones,
			autoClearAfterTimeZones,
			selections: { operateTimeZone, actuateTimeZone, disableTimeZone, autoClearAfterTimeZone, triggerControlZone, reTriggerControlZone },
			unpermitted,
		} = stateContext;

		const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
		const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

		const shouldResetSearchColumn = () => {
			if (setRedirectPage) {
				setRedirectPage();
			}
			if (isFilterMode) {
				setShouldResetSearchColumn();
			}
		};

		const handleEditExpansionRelay = async () => {
			setSubmitForm(true);

			const expansionRelay: ExpansionRelayInfo = {
				Name: name,
				Id: currentDeviceObj.Id,
				DoorDelayTime: doorDelayTime,
				ControlMode: controlModeTime,
				ControlDelay: controlDelayTime,
				NormalState: normalState,
				OperateTimeZone: unpermitted.operateTimeZone ? unpermitted.operateTimeZone : operateTimeZones.find(x => x.GenericId === operateTimeZone),
				ActuateTimeZone: unpermitted.actuateTimeZone ? unpermitted.actuateTimeZone : actuateTimeZones.find(x => x.GenericId === actuateTimeZone),
				DisableTimeZone: unpermitted.disableTimeZone ? unpermitted.disableTimeZone : disableTimeZones.find(x => x.GenericId === disableTimeZone),
				AutoClearTimeZone: unpermitted.autoClearAfterTimeZone
					? unpermitted.autoClearAfterTimeZone
					: autoClearAfterTimeZones.find(x => x.GenericId === autoClearAfterTimeZone),
				TriggerControlZone: triggerControlZone,
				ReTriggerControlZone: reTriggerControlZone,
				GenericTimeZones: [],
				ControlZones: [],
				StandardControlZones: [],
				ControllerId: controllerId,
			};

			const normalStateText: string = normalState ? _('Energized') : '';

			const response =
				currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay
					? await deviceAdminApi.editRelay(expansionRelay)
					: await deviceAdminApi.editExpansionRelay(expansionRelay);

			const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

			const toDispatch =
				currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay
					? setControllerContacts(DeviceObjectType.Relay, paginationSetting)
					: setControllerContacts(DeviceObjectType.ExpansionRelays, paginationSetting);

			NotificationStatus({
				responseData: response,
				onSuccessCallback: () => {
					dispatch(toDispatch);
					handleCancelModal();
					shouldResetSearchColumn();
					if (saveCallBack) {
						saveCallBack();
					}
				},
				onAdditionalInfoRequiredCallback: () => {
					const messages = response.ErrorMessage.split('\n');

					const content = <InformationMessages messages={messages} />;
					ModalWarning({
						title: _('FailedToSyncTimeZone'),
						content: content,
						onOk: () => handleCancelModal(),
						onCancel: () => handleCancelModal(),
						okText: _('Ok'),
						width: '700px',
					});
				},
				onFailedValidation: () => {
					setNameErrorText(response.ErrorMessage);
					setNameError(true);
				},
			});
		};

		const handleCloseModal = () => {
			const newDevice = { Id: 0, DeviceObjectType: currentDeviceObj.DeviceObjectType, IsModalOpen: false };
			if (cancelHandle) {
				cancelHandle(currentDeviceObj);
			} else {
				dispatch(setCurrentDevice(newDevice));
			}
		};

		const handleCancelModal = () => {
			const securedComponent = currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay ? SecuredComponents.Relay : SecuredComponents.Expansion_Relay;
			deviceAdminApi.unlockComponentDeviceAdmin(currentDeviceObj.Id, securedComponent);
			handleCloseModal();
		};

		const contextValue = useMemo(() => {
			return { expansionRelayState: stateContext, dispatcher: dispatchActionContext };
		}, [stateContext, dispatchActionContext]);

		const isNameEmpty = submittedForm && name === '';
		const permissionsEnabled = currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay ? canSaveRelay : canSaveExpansionRelay;

		return (
			<StoreContext.Provider value={contextValue}>
				<Modal
					onClickOk={() => null}
					onCancel={() => handleCancelModal()}
					title={
						currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay
							? `${_('EditRelay')} ${currentDeviceObj.Address}`
							: `${_('EditExpansionRelay')} ${currentDeviceObj.Address}`
					}
					visible={true}
					customZoomClass={styles.withModalZoom}
					footer={[
						<Button
							id={currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay ? 'relaySaveChangesButton' : 'expansionRelaySaveChangesButton'}
							key='save'
							type='primary'
							onClick={() => handleEditExpansionRelay()}
							title={getPermissionErrorMessage(permissionsEnabled)}
							disabled={
								isLoading ||
								!permissionsEnabled ||
								operateTimeZone === 0 ||
								actuateTimeZone === 0 ||
								disableTimeZone === 0 ||
								autoClearAfterTimeZone === 0
							}>
							{_('SaveChanges')}
						</Button>,
						<Button
							id={currentDeviceObj.DeviceObjectType === DeviceObjectType.Relay ? 'relayCancelButton' : 'expansionRelayCancelButton'}
							key='cancel'
							onClick={() => handleCancelModal()}>
							{_('Cancel')}
						</Button>,
					]}
					width='800px'>
					<div className={styles.container}>
						<RelayLogicContainer
							deviceType={currentDeviceObj.DeviceObjectType}
							isNameEmpty={isNameEmpty}
							nameError={nameError}
							nameErrorText={nameErrorText}
							setNameError={setNameError}
							id={currentDeviceObj.Id}
							setLoading={setLoading}
							isLoading={isLoading}
							useDoorContext={false}></RelayLogicContainer>
					</div>
				</Modal>
			</StoreContext.Provider>
		);
	}
);

export { ExpansionRelayModal };

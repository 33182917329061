import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { PhotoCallUp } from '../../../model/PhotoCallUpModel';
import { CardData } from '../CardData/CardData';
import './cardSlider.scss';

// Normal properties for the component
interface OwnProps {
	cards: PhotoCallUp[];
	isHidden: boolean;
	showDoorName: boolean;
	showDateTime: boolean;
	showBorderCard: boolean;
	showDoorsInFullWidth: boolean;
	title: string;
	color: string;
	doorId: number;
	onToggleDoor: Function;
	photoCallUpBasicView: boolean;
}

// combine them together
type Props = OwnProps;

const CardSlider = (props: Props) => {
	const { title, color, doorId, cards, isHidden, showDoorName, showDateTime, showBorderCard, showDoorsInFullWidth, onToggleDoor, photoCallUpBasicView } =
		props;

	let listRef = doorId > 0 ? React.createRef<HTMLDivElement>() : null;
	const allDoorsSection: boolean = doorId <= 0;
	const sliderWidth: string = showDoorsInFullWidth ? 'photo-slider-full-width' : 'photo-slider-half-width';
	const items: React.ReactNode[] = cards.map((card, index) => {
		const key = card.LogId;

		return (
			<CardData
				key={key}
				index={index}
				card={card}
				showDoorName={showDoorName}
				showDateTime={showDateTime}
				showBorderCard={showBorderCard}
				photoCallUpBasicView={photoCallUpBasicView}
			/>
		);
	});

	let close: React.ReactNode;
	if (!allDoorsSection) {
		close = (
			<span className={'photo-slider-close-span'}>
				<Button
					onClick={() => onToggleDoor(doorId)}
					className={'photo-slider-close-button'}
					icon={<CloseOutlined className={'photo-slider-close-x'} alt={_('Close')} />}
				/>
			</span>
		);
	}

	let style;
	if (isHidden === true) {
		style = { display: 'none' };
	}

	const useGetSnapshotBeforeUpdate = (cb, props, state) => {
		// get prev props and state
		const { prevProps, prevState } = usePrevPropsAndState(props, state);

		const snapshot = useRef(null);

		// getSnapshotBeforeUpdate - not run on mount + run on every update
		const componentJustMounted = useRef(true);
		useLayoutEffect(() => {
			if (!componentJustMounted.current) {
				snapshot.current = cb(prevProps, prevState);
			}
			componentJustMounted.current = false;
		}, [cards]);

		const useComponentDidUpdate = cb => {
			useEffect(() => {
				if (!componentJustMounted.current) {
					cb(prevProps, prevState, snapshot.current);
				}
			}, [cards]);
		};
		return useComponentDidUpdate;
	};

	const usePrevPropsAndState = (props, state) => {
		const prevPropsAndStateRef = useRef({ props: null, state: null });
		const prevProps = prevPropsAndStateRef.current.props;
		const prevState = prevPropsAndStateRef.current.state;

		useEffect(() => {
			prevPropsAndStateRef.current = { props, state };
		});

		return { prevProps, prevState };
	};

	const useComponentDidUpdate = useGetSnapshotBeforeUpdate(
		(prevProps, prevState) => {
			if (doorId > 0) {
				if (
					prevProps.cards.length !== cards.length ||
					(prevProps.cards.length > 0 && cards.length > 0 && prevProps.cards[0].LogId !== cards[0].LogId)
				) {
					return 0;
				}
			}
			return null;
		},
		props,
		[]
	);

	useComponentDidUpdate((prevProps, prevState, snapshot) => {
		if (snapshot !== null) {
			listRef.current.scrollLeft = 0;
		}
	});

	//Avoid creating object style inline, since increases reconciliations
	const sliderStyle: React.CSSProperties = { backgroundColor: color };

	return (
		<div id={`photoCallUpCollections-${doorId}`} style={style} className={sliderWidth}>
			<p style={sliderStyle} className={'photo-slider-title'}>
				<span className={'photo-slider-title-name'}>{title}</span>
				{close}
			</p>
			<div ref={listRef} className={'photo-cards-list photo-slider-scroll'}>
				{items}
			</div>
		</div>
	);
};

export { CardSlider };

import { Pie } from '@ant-design/charts';
import { UpOutlined } from '@ant-design/icons';
import { Collapse, Spin } from 'antd';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { getPieChartConfig } from '../../../Helper';
import { statusApi } from '../../../api';
import constants from '../../../constants';
import { ChartData } from '../../../model/CommonModel';
import { Logger } from '../../../model/LoggingModel';
import { CredentialSummary, WidgetInfo } from '../../../model/StatusModel';
import styles from './peopleCredentials.module.scss';

const { Panel } = Collapse;

// Internal state for the component
interface State {
	isLoading: boolean;
	responseData: CredentialSummary;
	isExpanded: boolean;
}

// Normal properties for the component
interface OwnProps {
	widgetInfo: WidgetInfo;
}

// combine them together
type Props = OwnProps;

const HeaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{_('PeopleCredentials')}</span>);

class PeopleCredentials extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isLoading: true,
			responseData: null,
			isExpanded: true,
		};

		//We use this flag to avoid multiple api calls at the same time
		window.sessionStorage.setItem(constants.sessionStorage.statusDashboard.RETRIEVE_PEOPLE_CREDENTIALS_FLAG, '0');
	}

	componentDidMount() {
		this.fetchData();
	}

	componentWillUnmount() {
		this.clearFetchTimeout();
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	clearFetchTimeout() {
		if (this.autoRefreshPerformanceLoop) {
			clearTimeout(this.autoRefreshPerformanceLoop);
		}
	}

	private autoRefreshPerformanceLoop: null | ReturnType<typeof setTimeout> = null;

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		const { isExpanded } = this.state;

		if (nextState.isExpanded) {
			this.clearFetchTimeout();

			if (!isExpanded) {
				this.fetchData();
			} else {
				const { widgetInfo } = this.props;

				if (widgetInfo.autoRefresh) {
					this.autoRefreshPerformanceLoop = setTimeout(() => {
						this.fetchData();
					}, widgetInfo.autoRefreshTimer * 1000);
				}
			}
		}

		return JSON.stringify(this.state.responseData) !== JSON.stringify(nextState.responseData);
	}

	fetchData() {
		const sessionStorageKey = constants.sessionStorage.statusDashboard.RETRIEVE_PEOPLE_CREDENTIALS_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');

			statusApi
				.retrieveCredentialSummary()
				.then(response => {
					window.sessionStorage.setItem(sessionStorageKey, '0');

					this.setState({
						isLoading: false,
						responseData: response,
					});
				})
				.catch(e => Logger.writeErrorLog(e));
		}
	}

	handleOnChange(isExpanded) {
		if (isExpanded) {
			this.setState({
				isLoading: true,
				isExpanded: true,
			});
		} else {
			this.setState({
				isExpanded: false,
			});
		}
	}

	render() {
		const { isLoading, responseData } = this.state;

		let activeUsedCredentials = 0;
		let inactiveUsedCredentials = 0;
		let unassignedCredentials = 0;
		let guestCredentials = 0;
		let credentials = 0;
		let people = 0;

		if (responseData !== null) {
			activeUsedCredentials = responseData.ActiveUsedCredentials;
			inactiveUsedCredentials = responseData.InActiveUsedCredentials;
			unassignedCredentials = responseData.UnassignedCredentials;
			guestCredentials = responseData.GuestCredentials;
			credentials = activeUsedCredentials + unassignedCredentials + guestCredentials + inactiveUsedCredentials;
			people = responseData.People;
		}

		const data: ChartData[] = [
			{
				type: `${_('Active30days')}`,
				value: activeUsedCredentials,
				color: 'blue',
			},
			{
				type: `${_('InActive30days')}`,
				value: inactiveUsedCredentials,
				color: 'orange',
			},
		];

		const config = getPieChartConfig(data);

		return (
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
				<div className={styles.peopleCredentialsContainer}>
					<Collapse
						bordered={true}
						defaultActiveKey={'1'}
						expandIconPosition='right'
						expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
						onChange={(keys: string[]) => {
							this.handleOnChange(keys.length === 1);
						}}
						className={styles.statusWidgetCollapse}>
						<Panel id='statusDashboardPeopleCredentialsWidget' key='1' header={<HeaderWidget />} className={styles.statusWidget}>
							<div className={styles.titlePie}>
								<div>
									<span>{`${_('People')}: ${people}`}</span>
									<span>{`${_('Credentials')}: ${credentials}`}</span>
								</div>
							</div>
							{!isLoading && <Pie {...config} />}
						</Panel>
					</Collapse>
				</div>
			</Spin>
		);
	}
}

export { PeopleCredentials };

enum DeviceControlVelocityConfigurationActionTypes {
	SET_HOLIDAYS = 'SET_HOLIDAYS',
	SET_OPERATORS = 'SET_OPERATORS',
	SET_ROLES = 'SET_ROLES',
	SET_COMMANDS_SETS = 'SET_COMMANDS_SETS',
	SET_CREDENTIAL_TEMPLATES = 'SET_CREDENTIAL_TEMPLATES',
	SET_DOOR_GROUPS = 'SET_DOOR_GROUPS',
	SET_READER_CONTROL_GROUPS = 'SET_READER_CONTROL_GROUPS',
	SET_TIME_ZONES_MASTER = 'SET_TIME_ZONES_MASTER',
	SET_TIME_ZONES_GRAND = 'SET_TIME_ZONES_GRAND',
	SET_TIME_ZONES_STANDARD = 'SET_TIME_ZONES_STANDARD',
	DELETE_COMMANDS_SETS = 'DELETE_COMMANDS_SETS',
	DELETE_CREDENTIAL_TEMPLATE = 'DELETE_CREDENTIAL_TEMPLATE',
	DELETE_DOOR_GROUP = 'DELETE_DOOR_GROUP',
	DELETE_READER_CONTROL_GROUP = 'DELETE_READER_CONTROL_GROUP',
	DELETE_TIMEZONES_MASTER = 'DELETE_TIMEZONES_MASTER',
	DELETE_TIMEZONES_GRAND = 'DELETE_TIMEZONES_GRAND',
	DELETE_TIMEZONES_STANDARD = 'DELETE_TIMEZONES_STANDARD',
	DELETE_XBOX = 'DELETE_XBOX',
	DELETE_PORT = 'DELETE_PORT',
	DELETE_CONTROLLER = 'DELETE_CONTROLLER',
	SET_VELOCITY_CONFIGURATION_EDIT_MODE = 'SET_VELOCITY_CONFIGURATION_EDIT_MODE',
	SET_VELOCITY_CONFIGURATION_ACTION_SCOPE_KEY = 'SET_VELOCITY_CONFIGURATION_ACTION_SCOPE_KEY',
	DELETE_HOLIDAY = 'DELETE_HOLIDAY',
	SET_GLOBAL_IO_GROUPS = 'SET_GLOBAL_IO_GROUPS',
	DELETE_GLOBAL_IO_GROUP = 'DELETE_GLOBAL_IO_GROUP',
	SET_VELOCITY_CONFIGURATION_FILTER_MODE = 'SET_VELOCITY_CONFIGURATION_FILTER_MODE',
	CLEAR_VELOCITY_CONFIGURATION_SELECTIONS = 'CLEAR_VELOCITY_CONFIGURATION_SELECTIONS',
	SET_SELECTED_ROW_KEYS_PAGINATION = 'SET_SELECTED_ROW_KEYS_PAGINATION',
}

enum DeviceControlDigiTracActionTypes {
	SET_CONTROLLER_CONTACTS = 'SET_CONTROLLER_CONTACTS',
	SET_DIGI_TRAC_EDIT_MODE = 'SET_DIGI_TRAC_EDIT_MODE',
	SET_SELECTED_ROW_KEYS_PAGINATION = 'SET_DIGI_TRACT_SELECTED_ROW_KEYS_PAGINATION',
	SET_CONTROLLER_SELECTED_KEY = 'SET_CONTROLLER_SELECTED_KEY',
	SET_DIGI_TRAC_FILTER_MODE = 'SET_DIGI_TRAC_FILTER_MODE',
	CLEAR_DIGI_TRAC_SELECTIONS = 'CLEAR_DIGI_TRAC_SELECTIONS',
}

enum DeviceControlCommonActionTypes {
	SET_TABLE_PAGINATION_SETTING = 'SET_TABLE_PAGINATION_SETTING',
}

export { DeviceControlCommonActionTypes, DeviceControlDigiTracActionTypes, DeviceControlVelocityConfigurationActionTypes };

import React, { useContext, useEffect, useState } from 'react';
import { RS485Body, ReaderType, WirelessVersion } from '..';
import { ReaderLabel } from '../../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import styles from '../../General/general.module.scss';

type Props = {
	submittedForm: boolean;
	handleDownloadFirmware: () => void;
};

const RS485Version: React.FC<Props> = ({ submittedForm, handleDownloadFirmware }) => {
	var {
		contextStateDoor: {
			exitReader: { labels },
		},
	} = useContext(DoorStoreSingleContext);

	const [fieldsetLabel, setFieldsetLabel] = useState('');
	const [rs485Body, setRs485Body] = useState<JSX.Element>(<></>);

	useEffect(() => {
		const isWireless: ReaderLabel = labels.find(x => x.Label === 'GeneralSecondFieldSet');
		if (isWireless) {
			setFieldsetLabel(isWireless.Value);
			setRs485Body(<WirelessVersion submittedForm={submittedForm}></WirelessVersion>);
		} else {
			setFieldsetLabel(_('RS485OSDP'));
			setRs485Body(<RS485Body handleDownloadFirmware={handleDownloadFirmware}></RS485Body>);
		}
	}, [labels]);

	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{fieldsetLabel}</legend>
					<div>
						<ReaderType></ReaderType>
						{rs485Body}
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { RS485Version };


import { Button, Checkbox } from 'antd';
import React, { useState } from 'react';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { OptionsInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerMiscSystemSetupAction, setControllerPropertyAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerMisc, ControllerProperty } from '../../../../../../../store/deviceControl/controller/actionTypes';
import {
	selectEnabledUserDBlocation,
	selectIsSEKInstalled,
	selectOptionsInfo,
	selectSupportsSNIB3DB,
	selectUserDBLocationList,
} from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection, Modal, Select } from '../../../../../../common';
import styles from '../setup.module.scss';

const style: React.CSSProperties = { marginTop: 30 };

const Options: React.FC = () => {
	const dispatch = useStoreDispatch();
	const optionsInfo: OptionsInfoStore = useStoreSelector<OptionsInfoStore>(selectOptionsInfo);
	const enabledUserDBLocation: number = useStoreSelector<number>(selectEnabledUserDBlocation);
	const supportsSNIB3DB: boolean = useStoreSelector<boolean>(selectSupportsSNIB3DB);
	const isSEKInstalled: boolean = useStoreSelector<boolean>(selectIsSEKInstalled);
	const userDBLocationList: SelectOptions<number>[] = useStoreSelector<SelectOptions<number>[]>(selectUserDBLocationList);

	const [advanceOptions, setAdvanceOptions] = useState({
		advancedBypassErrors: optionsInfo.advancedBypassErrors,
		advancedBypassCodeConflict: optionsInfo.advancedBypassCodeConflict,
		advancedEnableSEK: optionsInfo.advancedEnableSEK,
	});
	const [showModal, setShowModal] = useState(false);

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	const handleChangeControllerProperty = (value: ControllerProperty) => {
		dispatch(
			setControllerPropertyAction({
				...value,
			})
		);
	};

	const handleShowAdvanceOptions = () => {
		setShowModal(true);
	};

	const handleHideAdvanceOptions = () => {
		setShowModal(false);
	};

	const handleOkAdvanceSetup = () => {
		handleChangeControllerMiscSystemSetup({
			AdvancedBypassErrors: advanceOptions.advancedBypassErrors,
			AdvancedBypassCodeConflict: advanceOptions.advancedBypassCodeConflict,
			AdvancedEnableSEK: advanceOptions.advancedEnableSEK,
		});
		setShowModal(false);
	};

	return (
		<CardSection title={_('Options')}>
			<div className={styles.optionsContainer}>
				<div className={styles.setupOptions}>
					<Checkbox
						id='controllerSetupEnableDuressCheckbox'
						onChange={e => handleChangeControllerMiscSystemSetup({ OptionsEnableDuressAlarm: e.target.checked })}
						checked={optionsInfo.enableDuressAlarmMode}>
						{_('EnableDuressAlarmMode')}
					</Checkbox>
					<Checkbox
						id='controllerSetupEmptyLocalCheckbox'
						onChange={e => handleChangeControllerMiscSystemSetup({ OptionsEmptyLocalAlarm: e.target.checked })}
						checked={optionsInfo.emptyLocalAlarmViaReset}>
						{_('EmptyLocalAlarmViaReset')}
					</Checkbox>
					<Checkbox
						id='controllerSetupValidateOnDualCheckbox'
						onChange={e => handleChangeControllerMiscSystemSetup({ OptionsValidateOnDualCCOTZ: e.target.checked })}
						checked={optionsInfo.validateOnDualCCOTZ}>
						{_('ValidateOnDualCCOTZ')}
					</Checkbox>
					<Checkbox
						id='controllerSetupEnableGlobalCredentialCheckbox'
						onChange={e => handleChangeControllerMiscSystemSetup({ OptionsEnableGlobalCredentialManagement: e.target.checked })}
						checked={optionsInfo.enableGlobalCredentialManagement}>
						{_('EnableGlobalCredentialManagement')}
					</Checkbox>
					<Checkbox
						id='controllerSetupEnableGlobalMCZCheckbox'
						onChange={e => handleChangeControllerMiscSystemSetup({ OptionsEnableGlobalMCZ: e.target.checked })}
						checked={optionsInfo.enableGlobalMCZ}>
						{_('EnableGlobalMCZ')}
					</Checkbox>
					<Checkbox
						id='controllerSetupValidateImmediatelyCheckbox'
						onChange={e => handleChangeControllerMiscSystemSetup({ OptionsValidateImmediatelyCCOTZ: e.target.checked })}
						checked={optionsInfo.validateImmediatelyCCOTZ}>
						{_('ValidateImmediatelyCCOTZ')}
					</Checkbox>
				</div>
				{supportsSNIB3DB && (
					<div className={styles.dataBaseOptions}>
						<span>{_('CredentialDataBaseLocation')}:</span>
						<Select
							id='controllerSetupCredentialDatabaseDropdown'
							onChange={value => handleChangeControllerProperty({ EnabledUserDBLocation: Number(value) })}
							value={enabledUserDBLocation}
							notUseCurrentParse
							options={userDBLocationList}
							getPopupContainer={element => element}
						/>
					</div>
				)}
				<Button style={!supportsSNIB3DB ? style : undefined} type='primary' onClick={handleShowAdvanceOptions}>
					{_('Advanced')}
				</Button>
			</div>
			{showModal && (
				<Modal
					customZoomClass={styles.customModal}
					onClickOk={() => null}
					onCancel={handleHideAdvanceOptions}
					title={_('AdvancedOptionsSetups')}
					visible={true}
					footer={[
						<Button key='save' type='primary' onClick={handleOkAdvanceSetup}>
							{_('Save')}
						</Button>,
						<Button key='cancel' onClick={handleHideAdvanceOptions}>
							{_('Cancel')}
						</Button>,
					]}
					width='500px'>
					<div className={styles.advanceOptions}>
						<span>{_('AdvancedOptionsSetupNote')}</span>
						<Checkbox
							id='controllerSetupBypassErrorsCheckbox'
							onChange={e => setAdvanceOptions({ ...advanceOptions, advancedBypassErrors: e.target.checked })}
							defaultChecked={optionsInfo.advancedBypassErrors}
							checked={advanceOptions.advancedBypassErrors}>
							{_('BypassNoSuchHardwareErrors')}
						</Checkbox>
						<Checkbox
							id='controllerSetupBypassCodeConflicsCheckbox'
							defaultChecked={optionsInfo.advancedBypassCodeConflict}
							onChange={e => setAdvanceOptions({ ...advanceOptions, advancedBypassCodeConflict: e.target.checked })}
							checked={advanceOptions.advancedBypassCodeConflict}>
							{_('BypassCODEConflictChecking')}
						</Checkbox>
						{isSEKInstalled && (
							<Checkbox
								id='controllerSetupEnableSEKCheckbox'
								onChange={e => setAdvanceOptions({ ...advanceOptions, advancedEnableSEK: e.target.checked })}
								defaultChecked={optionsInfo.advancedEnableSEK}
								checked={advanceOptions.advancedEnableSEK}>
								{_('EnableSEK')}
							</Checkbox>
						)}
					</div>
				</Modal>
			)}
		</CardSection>
	);
};

export { Options };

import { InputNumber } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../Helper';
import { setLogOff, StoreContext } from '../../contextXbox';
import styles from '../../xboxModal.module.scss';

const LogOff: React.FC = () => {
	const {
		xboxState: { logOffTime },
		dispatcher,
	} = useContext(StoreContext);

	const handleOnChangeLogOff = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== 0) {
				return;
			}
		}
		dispatcher(setLogOff(Number(value.toString())));
	};

	const xboxLogOffTimeInputId = 'xboxLogOffTimeInput';

	return (
		<div className={styles.sections}>
			<label htmlFor={xboxLogOffTimeInputId}>
				{_('LogOffTime')} ({_('Seconds')})
			</label>
			<div>
				<InputNumber
					id={xboxLogOffTimeInputId}
					key='LogOffTime'
					min={0}
					max={3600}
					maxLength={4}
					value={logOffTime}
					onChange={value => handleOnChangeLogOff(value)}
					className={cx(styles.input)}
					formatter={noneDecimalFormatter}
				/>
				<label htmlFor={xboxLogOffTimeInputId}> 0 = {_('NerverLogOff')}</label>
			</div>
		</div>
	);
};

export { LogOff };

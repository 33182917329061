import cx from 'classnames';
import React from 'react';
import styles from './errorMessage.module.scss';

type Props = {
	message: string;
	show: boolean;
};

const ErrorMessage: React.FC<Props> = ({ children, message, show }) => {
	const classError = cx({ [styles.error]: show });
	return (
		<div className={classError}>
			{children}
			{show && <span>{message}</span>}
		</div>
	);
};

export { ErrorMessage };

import { Button, Checkbox, Input } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { filterOption, removeCommasAndPercentSign } from '../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../api';
import { Modal, NotificationStatus, Select } from '../../../../../../components/common';
import { ComponentPermission, SecuredComponents, User, getPermissionErrorMessage } from '../../../../../../model/AccountModel';
import { PaginationSetting, SorterConfig } from '../../../../../../model/CommonModel';
import { MasterTimeZones, MasterTimeZonesEntity, RowMasterTimeZone, StandardTimeZone, TimeZoneType } from '../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { setCurrentModalTimeZone } from '../../../../../../store/common/actions';
import { selectItemSearchedPagination, selectMasterTimeZone } from '../../../../../../store/common/selectors';
import { setMasterTimeZonesBy } from '../../../../../../store/deviceControl/actions';
import { selectVelocityConfigurationFilterMode } from '../../../../../../store/deviceControl/selectors';
import styles from './master.module.scss';

const user: User = getUser();
const componentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Master_Time_Zone);
const maxInputLength: number = 50;
const mainDivContainerId: string = 'masterTimeZoneModalContainer';

const gridRowHeader = [
	{ display: 'M', key: 'Monday' },
	{ display: 'T', key: 'Tuesday' },
	{ display: 'W', key: 'Wednesday' },
	{ display: 'T', key: 'Thursday' },
	{ display: 'F', key: 'Friday' },
	{ display: 'S', key: 'Saturday' },
	{ display: 'S', key: 'Sunday' },
	{ display: 'H', key: 'Holidays' },
	{ display: '1', key: 'HolidayTable1' },
	{ display: '2', key: 'HolidayTable2' },
	{ display: '3', key: 'HolidayTable3' },
	{ display: '4', key: 'HolidayTable4' },
];

type Props = {
	onEntityAction?: (genericTimeZoneId: number) => void;
	currentPage: number;
	currentPageSize: number;
	sorterConfig: SorterConfig;
	isPaginationItemSelected: boolean;
	setShouldResetSearchColumn: () => void;
	setRedirectPage: (value: boolean) => void;
};

const Master: React.FC<Props> = ({
	onEntityAction,
	setShouldResetSearchColumn,
	setRedirectPage,
	currentPage,
	currentPageSize,
	sorterConfig,
	isPaginationItemSelected,
}) => {
	const dispatch = useStoreDispatch();
	const [standardTimeZonesOptions, setStandardTimeZonesOptions] = useState<StandardTimeZone[]>([]);
	const [rows, setRows] = useState([] as RowMasterTimeZone[]);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [emptyNameError, setEmptyNameError] = useState('');
	const [standardTimeZones, setStandardTimeZones] = useState<StandardTimeZone[]>([]);

	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const itemSearchedPagination: string = useStoreSelector(selectItemSearchedPagination);

	const editedEntity: MasterTimeZones = useStoreSelector(selectMasterTimeZone);

	const fakeTimeZone: StandardTimeZone = {
		EndTime: null,
		Friday: false,
		HolidayTable1: false,
		HolidayTable2: false,
		HolidayTable3: false,
		HolidayTable4: false,
		Holidays: false,
		Id: 0,
		InUse: false,
		Monday: false,
		Name: ' ',
		Saturday: false,
		StartTime: null,
		Sunday: false,
		Thursday: false,
		Tuesday: false,
		Wednesday: false,
	};

	useEffect(() => {
		init();

		deviceAdminApi.getErrorMessages(SecuredComponents.Master_Time_Zone).then(res => setEmptyNameError(res.EmptyName));
		window.addEventListener('beforeunload', handleOnCLose);

		return () => {
			window.removeEventListener('beforeunload', handleOnCLose);
		};
	}, []);

	const init = async () => {
		const { Entity: standardTimeZonesList } = await deviceAdminApi.retrieveStandardTimeZones();

		setStandardTimeZones([...standardTimeZonesList]);

		const rowRepeat = 8;
		const standardTimeZoneOptions = standardTimeZonesList.map(x => (x.InUse === true ? { ...x, InUse: false } : x));

		if (editedEntity) {
			const totalRowsFromEdit = editedEntity.StandardTimeZones.length;
			const remainingRows = rowRepeat - totalRowsFromEdit;
			const defaultRows: StandardTimeZone[] = Array(remainingRows)
				.fill(fakeTimeZone)
				.flatMap<StandardTimeZone>((x, index) => ({ ...x }));

			const mapRows =
				totalRowsFromEdit > 0
					? editedEntity.StandardTimeZones.concat(defaultRows).flatMap<RowMasterTimeZone>((x, index) => ({ ...x, key: index }))
					: [...defaultRows.flatMap<RowMasterTimeZone>((x, index) => ({ ...x, key: index }))];

			setStandardTimeZonesOptions([...standardTimeZoneOptions]);
			setRows([...mapRows]);
			setName(editedEntity.Name);
		} else {
			const defaultRows: RowMasterTimeZone[] = Array(rowRepeat)
				.fill(fakeTimeZone)
				.flatMap<RowMasterTimeZone>((x, index) => ({ ...x, key: index }));
			setStandardTimeZonesOptions([...standardTimeZoneOptions]);
			setRows([...defaultRows]);
		}
	};

	const handleOnCLose = () => {
		deviceAdminApi.removeTimeZoneLocks(TimeZoneType.Master);
		dispatch(setCurrentModalTimeZone({ timeZoneType: null, entity: null }));
	};

	const handleSelectTimeZone = (key: number, value: number, row: RowMasterTimeZone) => {
		const standardTimeZoneOptions = standardTimeZones.map(x => (x.InUse === true ? { ...x, InUse: false } : x));
		const findTimeZoneToMap = value === 0 ? fakeTimeZone : standardTimeZoneOptions.find(x => x.Id === value);
		const cloneRow = rows;
		const newRows = cloneRow.map(x => (x.key === key ? { ...findTimeZoneToMap, key } : x));
		setRows([...newRows]);
	};

	const shouldResetSearchColumn = editedEntity => {
		if (isPaginationItemSelected && editedEntity && isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const redirectPage = editedEntity => {
		if (editedEntity) {
			setRedirectPage(true);
		}
	};

	const handleSave = () => {
		if (!name || name.trim() === '') {
			setNameError(emptyNameError);
			return;
		}
		const standardTimeZones = rows.filter(x => x.Id !== 0);
		const masterTimeZone: MasterTimeZonesEntity = {
			Id: 0,
			InUse: false,
			Name: name.trim(),
			StandardTimeZoneIds: standardTimeZones.map(x => x.Id),
		};

		const action =
			editedEntity === null
				? deviceAdminApi.addMasterTimeZone(masterTimeZone)
				: deviceAdminApi.editMasterTimeZone({ ...masterTimeZone, InUse: editedEntity.InUse, Id: editedEntity.Id });
		action.then(res => {
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {
					dispatch(
						setMasterTimeZonesBy({
							PageNumber: currentPage,
							PageSize: currentPageSize,
							SortDirection: sorterConfig.direction,
							SearchedValue: itemSearchedPagination,
						} as PaginationSetting)
					);
					onEntityAction(res.ResponseObjectId);
					shouldResetSearchColumn(editedEntity);
					redirectPage(editedEntity);
					handleOnCLose();
				},
				onFailedValidation: () => {
					setNameError(res.ErrorMessage);
				},
			});
		});
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		setName(value);
		setNameError('');
	};

	const permissionsEnabled = editedEntity === null ? componentPermission.canAdd : componentPermission.canUpdate;
	const disabled = !permissionsEnabled;

	const getCurrentOptions = (currentValue: StandardTimeZone) => {
		const selectAllNotSelected = standardTimeZones.filter(x => rows.findIndex(w => w.Id === x.Id && w.Id !== currentValue.Id) < 0);
		return [fakeTimeZone, ...selectAllNotSelected];
	};

	const masterTimeZoneNameInputId = 'masterTimeZoneNameInput';

	return (
		<Modal
			width='900px'
			footer={[
				<Button
					id='masterTimeZoneSaveButton'
					key='save'
					title={getPermissionErrorMessage(permissionsEnabled)}
					type='primary'
					disabled={disabled}
					onClick={() => handleSave()}>
					{_('SaveChanges')}
				</Button>,
				<Button id='masterTimeZoneCancelButton' key='cancel' onClick={() => handleOnCLose()}>
					{_('Cancel')}
				</Button>,
			]}
			onClickOk={() => null}
			onCancel={() => handleOnCLose()}
			customZoomClass={styles.withModalZoom}
			title={editedEntity ? _('EditMasterTimeZone') : _('AddMasterTimeZone')}
			visible={true}>
			<div className={styles.container} id={mainDivContainerId}>
				<div className={cx({ [styles.error]: nameError })}>
					<label htmlFor={masterTimeZoneNameInputId}>{_('Name')}</label>
					<Input id={masterTimeZoneNameInputId} onChange={handleChangeName} value={name} maxLength={maxInputLength} />
					<label id='masterTimeZoneErrorMessage' htmlFor={masterTimeZoneNameInputId}>
						{nameError}
					</label>
				</div>
				<div className={styles.grid}>
					<div className={cx(styles.row, styles.headers)}>
						<div>{_('StandardTimeZones')}</div>
						<div>{_('StartTimeTimeZones')}</div>
						<div>{_('EndTimeTimeZones')}</div>
						<div className={styles.week}>
							{gridRowHeader.map((x, index) => (
								<div key={index}>
									<label>{x.display}</label>
								</div>
							))}
						</div>
					</div>
					{rows.length > 0 &&
						rows.map((record, index) => (
							<div className={cx(styles.row, styles.body)} key={index}>
								<Select
									id={`masterTimeZoneStandardTimeZoneDropdown-${index}`}
									onChange={value => handleSelectTimeZone(index, Number(value), record)}
									options={getCurrentOptions(record).map((x, optionIndex) => ({
										label: x.Name,
										value: x.Id.toString(),
										id: `masterTimeZoneDropdownOptions-${index}-${optionIndex}`,
									}))}
									value={record.InUse ? record.Name : record.Id.toString()}
									getPopupContainer={() => document.getElementById(mainDivContainerId)}
									disabled={record.InUse}
									showSearch={true}
									filterOption={filterOption}
									maxSearchLength={maxInputLength}
									ariaAttributes={{ 'aria-label': `${_('StandardTimeZones')} ${index}` }}
								/>
								<Input value={record.StartTime} disabled aria-label='startMaster' />
								<Input value={record.EndTime} disabled aria-label='endMaster' />
								<div className={styles.week}>
									{gridRowHeader.map((x, headerIndex) => (
										<div key={headerIndex}>
											<Checkbox checked={record[x.key]} disabled id={`checkboxMaster-${index}-${headerIndex}`}>
												<label htmlFor={`checkboxMaster-${index}-${headerIndex}`} className={styles.srOnly}>{`${_(x.key)}`}</label>
											</Checkbox>
										</div>
									))}
								</div>
							</div>
						))}
				</div>
			</div>
		</Modal>
	);
};

export { Master };

import React from 'react';
import { GenericControlTimeZones, SelectTimeZone } from '../../../../model/DeviceAdminModel';

export type ExpansionRelayContextState = {
	id?: number;
	name: string;
	normalState: boolean;
	doorDelayTime: number;
	controlModeTime: number;
	controlDelayTime: number;
	controllerId: number;
	operateTimeZones: SelectTimeZone[];
	actuateTimeZones: SelectTimeZone[];
	disableTimeZones: SelectTimeZone[];
	autoClearAfterTimeZones: SelectTimeZone[];
	triggerControlZones: GenericControlTimeZones[];
	reTriggerControlZones: GenericControlTimeZones[];
	selections: {
		operateTimeZone: number;
		actuateTimeZone: number;
		disableTimeZone: number;
		autoClearAfterTimeZone: number;
		triggerControlZone: number;
		reTriggerControlZone: number;
	};
	unpermitted: {
		operateTimeZone: SelectTimeZone;
		actuateTimeZone: SelectTimeZone;
		disableTimeZone: SelectTimeZone;
		autoClearAfterTimeZone: SelectTimeZone;
	};
};

enum ExpansionRelayContextActionTypes {
	SET_NAME = 'SET_NAME',
	SET_NORMAL_STATE = 'SET_NORMAL_STATE',
	SET_DOOR_DELAY_TIME = 'SET_DOOR_DELAY_TIME',
	SET_CONTROL_MODE_TIME = 'SET_CONTROL_MODE_TIME',
	SET_CONTROL_DELAY_TIME = 'SET_CONTROL_DELAY_TIME',
	SET_CONTROLLER_ID = 'SET_CONTROLLER_ID',
	SET_OPERATE_TIME_ZONE = 'SET_OPERATE_TIME_ZONE',
	SET_OPERATE_TIME_ZONES = 'SET_OPERATE_TIME_ZONES',
	SET_ACTUATE_TIME_ZONE = 'SET_ACTUATE_TIME_ZONE',
	SET_ACTUATE_TIME_ZONES = 'SET_ACTUATE_TIME_ZONES',
	SET_DISABLE_TIME_ZONE = 'SET_DISABLE_TIME_ZONE',
	SET_DISABLE_TIME_ZONES = 'SET_DISABLE_TIME_ZONES',
	SET_AUTO_CLEAR_AFTER_TIME_ZONE = 'SET_AUTO_CLEAR_AFTER_TIME_ZONE',
	SET_AUTO_CLEAR_AFTER_TIME_ZONES = 'SET_AUTO_CLEAR_AFTER_TIME_ZONES',
	SET_TRIGGER_CONTROL_ZONE = 'SET_TRIGGER_CONTROL_ZONE',
	SET_TRIGGER_CONTROL_ZONES = 'SET_TRIGGER_CONTROL_ZONES',
	SET_RETRIGGER_CONTROL_ZONE = 'SET_RETRIGGER_CONTROL_ZONE',
	SET_RETRIGGER_CONTROL_ZONES = 'SET_RETRIGGER_CONTROL_ZONES',
	SET_UNPERMITTED_OPERATE_TIME_ZONE = 'SET_UNPERMITTED_OPERATE_TIME_ZONE',
	SET_UNPERMITTED_ACTUATE_TIME_ZONE = 'SET_UNPERMITTED_ACTUATE_TIME_ZONE',
	SET_UNPERMITTED_DISABLE_TIME_ZONE = 'SET_UNPERMITTED_DISABLE_TIME_ZONE',
	SET_UNPERMITTED_AUTO_CLEAR_TIME_ZONE = 'SET_UNPERMITTED_AUTO_CLEAR_TIME_ZONE',
}

type contextExpansionRelaySetName = {
	type: ExpansionRelayContextActionTypes.SET_NAME;
	payload: string;
};

type contextExpansionRelaySetNormalState = {
	type: ExpansionRelayContextActionTypes.SET_NORMAL_STATE;
	payload: boolean;
};

type contextExpansionRelaySetDoorDelayTime = {
	type: ExpansionRelayContextActionTypes.SET_DOOR_DELAY_TIME;
	payload: number;
};

type contextExpansionRelaySetControlMode = {
	type: ExpansionRelayContextActionTypes.SET_CONTROL_MODE_TIME;
	payload: number;
};

type contextExpansionRelaySetControlDelay = {
	type: ExpansionRelayContextActionTypes.SET_CONTROL_DELAY_TIME;
	payload: number;
};

type contextExpansionRelaySetControllerId = {
	type: ExpansionRelayContextActionTypes.SET_CONTROLLER_ID;
	payload: number;
};

type contextExpansionRelaySetOperateTimeZone = {
	type: ExpansionRelayContextActionTypes.SET_OPERATE_TIME_ZONE;
	payload: number;
};

type contextExpansionRelaySetOperateTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_OPERATE_TIME_ZONES;
	payload: SelectTimeZone[];
};

type contextExpansionRelaySetActuateTimeZone = {
	type: ExpansionRelayContextActionTypes.SET_ACTUATE_TIME_ZONE;
	payload: number;
};

type contextExpansionRelaySetActuateTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_ACTUATE_TIME_ZONES;
	payload: SelectTimeZone[];
};

type contextExpansionRelaySetDisableTimeZone = {
	type: ExpansionRelayContextActionTypes.SET_DISABLE_TIME_ZONE;
	payload: number;
};

type contextExpansionRelaySetDisableTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_DISABLE_TIME_ZONES;
	payload: SelectTimeZone[];
};

type contextExpansionRelaySetAutoClearAfterTimeZone = {
	type: ExpansionRelayContextActionTypes.SET_AUTO_CLEAR_AFTER_TIME_ZONE;
	payload: number;
};

type contextExpansionRelaySetAutoClearAfterTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_AUTO_CLEAR_AFTER_TIME_ZONES;
	payload: SelectTimeZone[];
};

type contextExpansionRelaySetTriggerControlZone = {
	type: ExpansionRelayContextActionTypes.SET_TRIGGER_CONTROL_ZONE;
	payload: number;
};

type contextExpansionRelaySetTriggerControlZones = {
	type: ExpansionRelayContextActionTypes.SET_TRIGGER_CONTROL_ZONES;
	payload: GenericControlTimeZones[];
};

type contextExpansionRelaySetReTriggerControlZone = {
	type: ExpansionRelayContextActionTypes.SET_RETRIGGER_CONTROL_ZONE;
	payload: number;
};

type contextExpansionRelaySetReTriggerControlZones = {
	type: ExpansionRelayContextActionTypes.SET_RETRIGGER_CONTROL_ZONES;
	payload: GenericControlTimeZones[];
};

type contextExpansionRelaySetUnpermittedOperateTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_OPERATE_TIME_ZONE;
	payload: SelectTimeZone;
};

type contextExpansionRelaySetUnpermittedActuateTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_ACTUATE_TIME_ZONE;
	payload: SelectTimeZone;
};

type contextExpansionRelaySetUnpermittedDisableTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_DISABLE_TIME_ZONE;
	payload: SelectTimeZone;
};

type contextExpansionRelaySetUnpermittedAutoClearTimeZones = {
	type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_AUTO_CLEAR_TIME_ZONE;
	payload: SelectTimeZone;
};

type ExpansionRelayActionContext =
	| contextExpansionRelaySetName
	| contextExpansionRelaySetNormalState
	| contextExpansionRelaySetDoorDelayTime
	| contextExpansionRelaySetControlMode
	| contextExpansionRelaySetControlDelay
	| contextExpansionRelaySetControllerId
	| contextExpansionRelaySetOperateTimeZone
	| contextExpansionRelaySetOperateTimeZones
	| contextExpansionRelaySetActuateTimeZone
	| contextExpansionRelaySetActuateTimeZones
	| contextExpansionRelaySetDisableTimeZone
	| contextExpansionRelaySetDisableTimeZones
	| contextExpansionRelaySetAutoClearAfterTimeZone
	| contextExpansionRelaySetAutoClearAfterTimeZones
	| contextExpansionRelaySetTriggerControlZone
	| contextExpansionRelaySetTriggerControlZones
	| contextExpansionRelaySetReTriggerControlZone
	| contextExpansionRelaySetReTriggerControlZones
	| contextExpansionRelaySetUnpermittedOperateTimeZones
	| contextExpansionRelaySetUnpermittedActuateTimeZones
	| contextExpansionRelaySetUnpermittedDisableTimeZones
	| contextExpansionRelaySetUnpermittedAutoClearTimeZones;

const expansionRelayState: ExpansionRelayContextState = {
	name: '',
	normalState: false,
	doorDelayTime:0,
	controlModeTime: 24,
	controlDelayTime: 0,
	controllerId: 0,
	operateTimeZones: [],
	actuateTimeZones: [],
	disableTimeZones: [],
	autoClearAfterTimeZones: [],
	triggerControlZones: [],
	reTriggerControlZones: [],
	selections: {
		operateTimeZone: 1,
		actuateTimeZone: 1,
		disableTimeZone: 1,
		autoClearAfterTimeZone: 1,
		triggerControlZone: 0,
		reTriggerControlZone: 0,
	},
	unpermitted: {
		operateTimeZone: null,
		actuateTimeZone: null,
		disableTimeZone: null,
		autoClearAfterTimeZone: null,
	},
};

const expansionRelayContext = (state: Readonly<ExpansionRelayContextState>, action: ExpansionRelayActionContext): ExpansionRelayContextState => {
	switch (action.type) {
		case ExpansionRelayContextActionTypes.SET_NAME:
			return { ...state, name: action.payload };

		case ExpansionRelayContextActionTypes.SET_NORMAL_STATE:
			return { ...state, normalState: action.payload };

		case ExpansionRelayContextActionTypes.SET_DOOR_DELAY_TIME:
			return { ...state, doorDelayTime: action.payload };

		case ExpansionRelayContextActionTypes.SET_CONTROL_MODE_TIME:
			return { ...state, controlModeTime: action.payload };

		case ExpansionRelayContextActionTypes.SET_CONTROL_DELAY_TIME:
			return { ...state, controlDelayTime: action.payload };

		case ExpansionRelayContextActionTypes.SET_CONTROLLER_ID:
			return { ...state, controllerId: action.payload };

		case ExpansionRelayContextActionTypes.SET_OPERATE_TIME_ZONES:
			return { ...state, operateTimeZones: action.payload };

		case ExpansionRelayContextActionTypes.SET_OPERATE_TIME_ZONE:
			return { ...state, selections: { ...state.selections, operateTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_ACTUATE_TIME_ZONES:
			return { ...state, actuateTimeZones: action.payload };

		case ExpansionRelayContextActionTypes.SET_ACTUATE_TIME_ZONE:
			return { ...state, selections: { ...state.selections, actuateTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_DISABLE_TIME_ZONES:
			return { ...state, disableTimeZones: action.payload };

		case ExpansionRelayContextActionTypes.SET_DISABLE_TIME_ZONE:
			return { ...state, selections: { ...state.selections, disableTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_AUTO_CLEAR_AFTER_TIME_ZONES:
			return { ...state, autoClearAfterTimeZones: action.payload };

		case ExpansionRelayContextActionTypes.SET_AUTO_CLEAR_AFTER_TIME_ZONE:
			return { ...state, selections: { ...state.selections, autoClearAfterTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_TRIGGER_CONTROL_ZONES:
			return { ...state, triggerControlZones: action.payload };

		case ExpansionRelayContextActionTypes.SET_TRIGGER_CONTROL_ZONE:
			return { ...state, selections: { ...state.selections, triggerControlZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_RETRIGGER_CONTROL_ZONES:
			return { ...state, reTriggerControlZones: action.payload };

		case ExpansionRelayContextActionTypes.SET_RETRIGGER_CONTROL_ZONE:
			return { ...state, selections: { ...state.selections, reTriggerControlZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_UNPERMITTED_OPERATE_TIME_ZONE:
			return { ...state, unpermitted: { ...state.unpermitted, operateTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_UNPERMITTED_ACTUATE_TIME_ZONE:
			return { ...state, unpermitted: { ...state.unpermitted, actuateTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_UNPERMITTED_DISABLE_TIME_ZONE:
			return { ...state, unpermitted: { ...state.unpermitted, disableTimeZone: action.payload } };

		case ExpansionRelayContextActionTypes.SET_UNPERMITTED_AUTO_CLEAR_TIME_ZONE:
			return { ...state, unpermitted: { ...state.unpermitted, autoClearAfterTimeZone: action.payload } };

		default:
			return { ...state };
	}
};

const setName = (payload: string): contextExpansionRelaySetName => {
	return {
		type: ExpansionRelayContextActionTypes.SET_NAME,
		payload,
	};
};

const setNormalState = (payload: boolean): contextExpansionRelaySetNormalState => {
	return {
		type: ExpansionRelayContextActionTypes.SET_NORMAL_STATE,
		payload,
	};
};

const setDoorDelayTime = (payload: number): contextExpansionRelaySetDoorDelayTime => {
	return {
		type: ExpansionRelayContextActionTypes.SET_DOOR_DELAY_TIME,
		payload,
	};
};

const setControlMode = (payload: number): contextExpansionRelaySetControlMode => {
	return {
		type: ExpansionRelayContextActionTypes.SET_CONTROL_MODE_TIME,
		payload,
	};
};

const setControlDelay = (payload: number): contextExpansionRelaySetControlDelay => {
	return {
		type: ExpansionRelayContextActionTypes.SET_CONTROL_DELAY_TIME,
		payload,
	};
};

const setControllerId = (payload: number): contextExpansionRelaySetControllerId => {
	return {
		type: ExpansionRelayContextActionTypes.SET_CONTROLLER_ID,
		payload,
	};
};

const setOperateTimeZone = (payload: number): contextExpansionRelaySetOperateTimeZone => {
	return {
		type: ExpansionRelayContextActionTypes.SET_OPERATE_TIME_ZONE,
		payload,
	};
};

const setOperateTimeZones = (payload: SelectTimeZone[]): contextExpansionRelaySetOperateTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_OPERATE_TIME_ZONES,
		payload,
	};
};

const setActuateTimeZone = (payload: number): contextExpansionRelaySetActuateTimeZone => {
	return {
		type: ExpansionRelayContextActionTypes.SET_ACTUATE_TIME_ZONE,
		payload,
	};
};

const setActuateTimeZones = (payload: SelectTimeZone[]): contextExpansionRelaySetActuateTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_ACTUATE_TIME_ZONES,
		payload,
	};
};

const setDisableTimeZone = (payload: number): contextExpansionRelaySetDisableTimeZone => {
	return {
		type: ExpansionRelayContextActionTypes.SET_DISABLE_TIME_ZONE,
		payload,
	};
};

const setDisableTimeZones = (payload: SelectTimeZone[]): contextExpansionRelaySetDisableTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_DISABLE_TIME_ZONES,
		payload,
	};
};

const setAutoClearAfterTimeZone = (payload: number): contextExpansionRelaySetAutoClearAfterTimeZone => {
	return {
		type: ExpansionRelayContextActionTypes.SET_AUTO_CLEAR_AFTER_TIME_ZONE,
		payload,
	};
};

const setAutoClearAfterTimeZones = (payload: SelectTimeZone[]): contextExpansionRelaySetAutoClearAfterTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_AUTO_CLEAR_AFTER_TIME_ZONES,
		payload,
	};
};

const setTriggerControlZone = (payload: number): contextExpansionRelaySetTriggerControlZone => {
	return {
		type: ExpansionRelayContextActionTypes.SET_TRIGGER_CONTROL_ZONE,
		payload,
	};
};

const setTriggerControlZones = (payload: GenericControlTimeZones[]): contextExpansionRelaySetTriggerControlZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_TRIGGER_CONTROL_ZONES,
		payload,
	};
};

const setReTriggerControlZone = (payload: number): contextExpansionRelaySetReTriggerControlZone => {
	return {
		type: ExpansionRelayContextActionTypes.SET_RETRIGGER_CONTROL_ZONE,
		payload,
	};
};

const setReTriggerControlZones = (payload: GenericControlTimeZones[]): contextExpansionRelaySetReTriggerControlZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_RETRIGGER_CONTROL_ZONES,
		payload,
	};
};

const setUnpermittedActuateTimeZone = (payload: SelectTimeZone): contextExpansionRelaySetUnpermittedActuateTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_ACTUATE_TIME_ZONE,
		payload,
	};
};

const setUnpermittedOperateTimeZone = (payload: SelectTimeZone): contextExpansionRelaySetUnpermittedOperateTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_OPERATE_TIME_ZONE,
		payload,
	};
};

const setUnpermittedDisableTimeZone = (payload: SelectTimeZone): contextExpansionRelaySetUnpermittedDisableTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_DISABLE_TIME_ZONE,
		payload,
	};
};

const setUnpermittedAutoClearTimeZone = (payload: SelectTimeZone): contextExpansionRelaySetUnpermittedAutoClearTimeZones => {
	return {
		type: ExpansionRelayContextActionTypes.SET_UNPERMITTED_AUTO_CLEAR_TIME_ZONE,
		payload,
	};
};

type ExpansionRelayContext = {
	expansionRelayState: Readonly<ExpansionRelayContextState>;
	dispatcher: React.Dispatch<ExpansionRelayActionContext>;
};

const StoreContext = React.createContext<ExpansionRelayContext>({ expansionRelayState, dispatcher: null });

export {
	expansionRelayContext,
	expansionRelayState,
	setName,
	setNormalState,
	setDoorDelayTime,
	setControlMode,
	setControlDelay,
	setControllerId,
	setOperateTimeZone,
	setOperateTimeZones,
	setActuateTimeZone,
	setActuateTimeZones,
	setDisableTimeZone,
	setDisableTimeZones,
	setAutoClearAfterTimeZone,
	setAutoClearAfterTimeZones,
	setTriggerControlZone,
	setTriggerControlZones,
	setReTriggerControlZone,
	setReTriggerControlZones,
	setUnpermittedActuateTimeZone,
	setUnpermittedOperateTimeZone,
	setUnpermittedDisableTimeZone,
	setUnpermittedAutoClearTimeZone,
	StoreContext,
};


import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { Select } from '../../../../../../common';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setFromZone, setToZone, StoreContext } from '../../../contextReader';
import styles from '../../Logic/logic.module.scss';

type Props = {
	useDoorContext: boolean;
};

const PassbackZones: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					passbackZones,
					selections: { toZone, fromZone },
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				passbackZones,
				selections: { toZone, fromZone },
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [passbackZonesList, setPassbackZonesList] = useState<SelectOptions<string>[]>([]);

	useEffect(() => {
		setPassbackZonesList(passbackZones.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [passbackZones]);

	const handleOnSetFromZone = (value: string) => {
		useDoorContext ? dispatcherDoor(setFromZone(Number(value))) : dispatcher(setFromZone(Number(value)));
	};

	const handleOnSetToZone = (value: string) => {
		useDoorContext ? dispatcherDoor(setToZone(Number(value))) : dispatcher(setToZone(Number(value)));
	};

	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('PassbackZones')}</legend>
					<div>
						<div className={styles.passbackSection}>
							<span>{_('FromZone')}</span>
							<div>
								<Select
									className={styles.select}
									options={passbackZonesList}
									onChange={handleOnSetFromZone}
									key='fromZone'
									id='readerModalFromZoneSelect'
									value={fromZone.toString()}
									getPopupContainer={() => document.getElementById('logicReaderContainer')}
								/>
							</div>
						</div>
						<div className={styles.passbackSection}>
							<span>{_('ToZone')}</span>
							<div>
								<Select
									className={styles.select}
									options={passbackZonesList}
									onChange={handleOnSetToZone}
									key='toZone'
									id='readerModalToZoneSelect'
									value={toZone.toString()}
									getPopupContainer={() => document.getElementById('logicReaderContainer')}
								/>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { PassbackZones };

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, notification, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../../api';
import { canAddNewTimeZones, convertObjectToArray } from '../../../../Helper';
import { ComponentPermission, getPermissionErrorMessage, SecuredComponents, SubPermissions, User } from '../../../../model/AccountModel';
import { ResponseStatusCode } from '../../../../model/CommonModel';
import {
	ControllerACBSetup,
	ControllerData,
	ControllerError,
	ControllerInfoStore,
	ControllerType,
	CurrentDeviceControlObj,
	DeviceAdminWarningType,
	DeviceObjectType,
	DoorGroup,
	LockStatusDoorGroupViewModel,
	SelectTimeZone,
	TimeZoneType,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import {
	resetControllerAction,
	setControllerAction,
	setControllerError,
	setControllerHasChanged,
	setControllerMiscSystemSetupAction,
	setControllerPassbackSetupAction,
	setControllerPropertyAction,
	setControllerReportingSetupAction,
	setCurrentTimeZones,
	setOpenTimeZones,
} from '../../../../store/deviceControl/controller/actions';
import { selectControllerInfo } from '../../../../store/deviceControl/controller/selectors';
import { setControlZonesForControllers, setControlZonesHasChangedName } from '../../../../store/deviceControl/controlZones/actions';
import {
	InformationMessages,
	Modal,
	ModalConfirmation,
	ModalConfirmCustomFooter,
	ModalError,
	ModalWarning,
	NotificationStatus,
	WithLockedValidation,
} from '../../../common';
import { TimeZones } from '../../../devicecontrol/VelocityConfiguration/TimeZones/TimeZones';
import { DoorGroupLockStatus } from '../../VelocityConfiguration/DoorGroup/warnings';
import styles from './controllerModal.module.scss';
import { AlarmActions, Communications, ControlZones, General, PassBack, Reporting, Setup } from './tabs';
import { ChangeDBLocation } from './Warnings/ChangeDBLocation/ChangeDBLocation';
import { ControllerTypeChange } from './Warnings/ControllerTypeChange/ControllerTypeChange';

const user: User = getUser();
const canSaveController: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Controller);

const { TabPane } = Tabs;

enum ControllerKeyTabs {
	General = '1',
	Setup = '2',
	Reporting = '3',
	Passback = '4',
	ControlZones = '5',
	AlarmActions = '6',
	Communications = '7',
}

enum LoadingType {
	MainSaveChanges = '1',
	WarningSaveChanges = '2',
}

type Props = {
	currentDevice: CurrentDeviceControlObj;
	navigationParentTitle: string;
	navigationChildTitle: string;
	callbackRedirect?: () => void;
	isModal?: boolean;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	saveCallBack?: () => void;
};

const handleModalWarning = (errorMessages: string, callback?: () => void) => {
	const messages: string[] = errorMessages.split('\n');

	const content: JSX.Element = (
		<div className={styles.warningMessages}>
			<InformationMessages messages={Array.from(new Set(messages))} />
		</div>
	);
	ModalWarning({
		content: content,
		hideCancelButton: true,
		okText: _('Ok'),
		width: '600px',
		onOk: callback,
	});
};

const canOpenTabs = () => {
	const user: User = getUser();
	return {
		general: User.getSubComponentPermission(user, SubPermissions.Controller_ViewGeneralTab).allowed,
		setup: User.getSubComponentPermission(user, SubPermissions.Controller_ViewSetupTab).allowed,
		reporting: User.getSubComponentPermission(user, SubPermissions.Controller_ViewReportingTab).allowed,
		passback: User.getSubComponentPermission(user, SubPermissions.Controller_ViewPassbackTab).allowed,
		controlZones:
			User.getSubComponentPermission(user, SubPermissions.Controller_ViewControlZonesTab).allowed &&
			User.getComponentPermission(user, SecuredComponents.Control_Zone_Editor).canView,
		communications: User.getSubComponentPermission(user, SubPermissions.Controller_ViewCommunicationsTab).allowed,
		alarms: User.getSubComponentPermission(user, SubPermissions.Controller_ViewAlarmActionsTab).allowed,
	};
};

const tabActionKey = (): string => {
	const user: User = getUser();
	let actionKey: string = '1';
	if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewGeneralTab).allowed) {
		actionKey = ControllerKeyTabs.General;
	} else if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewSetupTab).allowed) {
		actionKey = ControllerKeyTabs.Setup;
	} else if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewReportingTab).allowed) {
		actionKey = ControllerKeyTabs.Reporting;
	} else if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewPassbackTab).allowed) {
		actionKey = ControllerKeyTabs.Passback;
	} else if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewControlZonesTab).allowed) {
		actionKey = ControllerKeyTabs.ControlZones;
	} else if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewAlarmActionsTab).allowed) {
		actionKey = ControllerKeyTabs.AlarmActions;
	} else if (User.getSubComponentPermission(user, SubPermissions.Controller_ViewCommunicationsTab).allowed) {
		actionKey = ControllerKeyTabs.Communications;
	}
	return actionKey;
};

const ControllerModal: React.FC<Props> = WithLockedValidation(
	({ currentDevice, navigationParentTitle, navigationChildTitle, callbackRedirect, isModal = false, cancelHandle, saveCallBack }) => {
		const dispatch = useStoreDispatch();
		const controllerInfo: ControllerInfoStore = useStoreSelector<ControllerInfoStore>(selectControllerInfo);

		const [activeTab, setActiveTab] = useState(tabActionKey());
		const [showHasChangedModal, setHasChangedModal] = useState(false);
		const [submitted, setSubmitted] = useState<boolean>(false);
		const [warningSaveChangesSubmitted, setWarningSaveChangesSubmitted] = useState<boolean>(false);
		const [changeDBLocation, setChangeDBLocation] = useState<string[]>([]);
		const [redirectTo, setRedirectTo] = useState<string>('');
		const [loading, setLoading] = useState(false);

		const hasChangedControllerRef = React.useRef(controllerInfo.hasChangedController);
		const isBackToControllersRef = React.useRef(false);

		useEffect(() => {
			setLoading(true);
			if (canOpenTabs().controlZones) {
				Promise.allSettled([deviceAdminApi.getControlZonesEditor(currentDevice.Id), deviceAdminApi.getController(currentDevice.Id)])
					.then(arrResp => {
						if (arrResp?.[0].status === 'fulfilled') {
							dispatch(setControlZonesForControllers(arrResp[0].value.Entity));
						}
						if (arrResp?.[1].status === 'fulfilled') {
							const controllerResp = arrResp[1].value;
							dispatch(setControllerAction(controllerResp.Entity));
							if (controllerResp.ResponseStatusCode === ResponseStatusCode.AdditionalInfoRequired) {
								handleModalWarning(controllerResp.ErrorMessage);
							}
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				deviceAdminApi
					.getController(currentDevice.Id)
					.then(response => {
						dispatch(setControllerAction(response.Entity));
						if (response.ResponseStatusCode === ResponseStatusCode.AdditionalInfoRequired) {
							handleModalWarning(response.ErrorMessage);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			}

			window.addEventListener('beforeunload', handleBeforeUnload);
			window.addEventListener('click', handleClickRedirectTo, true);

			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
				window.removeEventListener('click', handleClickRedirectTo, true);
				dispatch(resetControllerAction());
			};
		}, []);

		useEffect(() => {
			hasChangedControllerRef.current = controllerInfo.hasChangedController;
		}, [controllerInfo.hasChangedController]);

		//fire time zone according current property of controller fired when a new time zone is added (or edited)
		useEffect(() => {
			switch (controllerInfo.controllerData.openTimeZone?.propertyType) {
				case 'Tag':
					dispatch(setControllerMiscSystemSetupAction({ TagTimeZoneGenericId: controllerInfo.controllerData.newTimeZoneAddedId }));
					break;
				case 'Alert':
					dispatch(setControllerMiscSystemSetupAction({ AlertTimeZoneGenericId: controllerInfo.controllerData.newTimeZoneAddedId }));
					break;
				case 'LocalPrinter':
					dispatch(
						setControllerReportingSetupAction({
							DisableReportingOnLocalPrinterTimeZone: controllerInfo.controllerData.newTimeZoneAddedId,
						})
					);
					break;
				case 'Transaction':
					dispatch(
						setControllerReportingSetupAction({
							DisableReportingTransactionsTimeZone: controllerInfo.controllerData.newTimeZoneAddedId,
						})
					);
					break;
				case 'Events':
					dispatch(setControllerReportingSetupAction({ DisableReportingEventsTimeZone: controllerInfo.controllerData.newTimeZoneAddedId }));
					break;
				case 'GrantedTransactions':
					dispatch(
						setControllerReportingSetupAction({
							DisableReportingGrantedTransactionsTimeZone: controllerInfo.controllerData.newTimeZoneAddedId,
						})
					);
					break;
				case 'PassbackOptions':
					dispatch(setControllerPassbackSetupAction({ OptionsDisableTimeZonePassback: controllerInfo.controllerData.newTimeZoneAddedId }));
					break;
				case 'TwoPersonRule':
					dispatch(setControllerPassbackSetupAction({ TwoPersonDisableTimeZonePassback: controllerInfo.controllerData.newTimeZoneAddedId }));
					break;
			}
		}, [controllerInfo.controllerData.newTimeZoneAddedId]);

		const handleClickRedirectTo = event => {
			const helpUrl = `${window.location.href}#`;
			const target: HTMLAnchorElement = event.target;
			const url: string = target.href !== 'javascript:void(0)' && target.href !== helpUrl ? target.href : '';
			const tagName = target?.tagName?.toLocaleLowerCase();

			if (tagName === 'a' && hasChangedControllerRef.current && url !== '') {
				event.preventDefault();
				event.stopImmediatePropagation();
				setRedirectTo(url);
				setHasChangedModal(true);
			}
		};

		const setLoadingAction = (isLoading: boolean, loadingType: LoadingType) => {
			loadingType === LoadingType.MainSaveChanges ? setSubmitted(isLoading) : setWarningSaveChangesSubmitted(isLoading);
		};

		const handleSaveChangesController = () => {
			setLoadingAction(true, LoadingType.WarningSaveChanges);
			saveController();
		};

		const redirectToLink = () => {
			if (!isModal) {
				if (isBackToControllersRef.current) {
					handleBackControllersList(true);
				} else {
					window.history.replaceState({}, '', `${redirectTo}`);
					window.location.href = redirectTo;
				}
			}
		};

		const handleSaveController = () => {
			isBackToControllersRef.current = true;
			setLoadingAction(true, LoadingType.MainSaveChanges);
			saveController();
		};

		const saveController = () => {
			const { controllerData } = controllerInfo;
			const normArray = convertObjectToArray(controllerData.ControllerACBSetup);
			const controller: ControllerData = {
				...controllerData,
				ControllerACBSetup: [...normArray],
			};
			dispatch(setControllerHasChanged(false));
			deviceAdminApi.checkLastUserDBLocation(controllerData.ControllerId, controllerData.EnabledUserDBLocation).then(checkLastUserDBLocation => {
				controller.ControllerPassbackSetup.OccupancySetupByZone = [...controllerData.occupancyTwoPersonsRules];
				if (checkLastUserDBLocation) {
					const messages: string[] = checkLastUserDBLocation.split('\n');
					setChangeDBLocation(messages);
				} else {
					controller.DownloadAllCredentials = false;
					onTryEditController(controller);
				}
			});
		};

		const onTryEditController = (data: ControllerData) => {
			deviceAdminApi.editController(data).then(response => {
				setHasChangedModal(false);
				setLoadingAction(false, LoadingType.MainSaveChanges);
				setLoadingAction(false, LoadingType.WarningSaveChanges);
				NotificationStatus({
					responseData: response,
					notUseDefaultNotification: true,
					onSuccessCallback: () => {
						redirectToLink();
						saveCallBack?.();
					},
					onFailedValidation: () => {
						switch (response.AdditionalResponseInfo) {
							case ControllerError[ControllerError.Name]:
								dispatch(setControllerError({ name: true, message: response.ErrorMessage }));
								if (canOpenTabs().general) {
									setActiveTab(ControllerKeyTabs.General);
								}
								break;
							case ControllerError[ControllerError.MasterCode]:
								dispatch(setControllerError({ systemCode: true, message: response.ErrorMessage }));
								if (canOpenTabs().setup) {
									setActiveTab(ControllerKeyTabs.Setup);
								}
								break;
							case ControllerError[ControllerError.WirelessController]:
								dispatch(setControllerError({ wirelessController: true, message: response.ErrorMessage }));
								if (canOpenTabs().general) {
									setActiveTab(ControllerKeyTabs.General);
								}
								break;
							case ControllerError[ControllerError.ControllerType]:
								dispatch(setControllerError({ controllerType: true, message: response.ErrorMessage }));
								if (canOpenTabs().general) {
									setActiveTab(ControllerKeyTabs.General);
								}
								break;
							case ControllerError[ControllerError.TwoPersonThresholdTime]:
							case ControllerError[ControllerError.TwoPersonRuleRange]:
								dispatch(setControllerError({ twoPersonsRules: true }));
								if (canOpenTabs().passback) {
									setActiveTab(ControllerKeyTabs.Passback);
								}
								break;
							case ControllerError[ControllerError.TwoPersonEvenPeople]:
								dispatch(setControllerError({ twoPersonEvenPeople: true, message: response.ErrorMessage }));
								if (canOpenTabs().passback) {
									setActiveTab(ControllerKeyTabs.Passback);
								}
								break;
						}
					},
					onAdditionalInfoRequiredCallback: async () => {
						const warningType: DeviceAdminWarningType = Number(response.ResponseErrorDescription) as DeviceAdminWarningType;
						if (warningType === DeviceAdminWarningType.ChangeControllerType) {
							const affectedDoorGroups: DoorGroup[] = await deviceAdminApi.getAffectedDoorGroupByControllerChange(
								data.ControllerBasicSetup.ControllerType,
								data.ControllerId
							);

							ModalConfirmation({
								width: '600px',
								cancelText: _('No'),
								okText: _('Yes'),
								onCancel: () => {
									redirectToLink();
									cancelHandle?.(currentDevice);
								},
								onConfirm: () =>
									handleChangeControllerType(data.ControllerBasicSetup.ControllerType, data.ControllerId, () =>
										handleModalWarning(response.ErrorMessage, () => {
											redirectToLink();
											cancelHandle?.(null);
										})
									),
								content: <ControllerTypeChange warningTitle={response.AdditionalResponseInfo} affectedDoorGroups={affectedDoorGroups} />,
							});
						} else {
							handleModalWarning(response.ErrorMessage, () => {
								redirectToLink();
								cancelHandle?.(null);
							});
						}
					},
					onPermissionErrorCallback: async () => {
						notification['error']({
							message: response.ErrorMessage,
						});
						redirectToLink();
						cancelHandle?.(null);
					},
					onDatabaseAccessCallback: async () => {
						const messages: string[] = response.ErrorMessage.split('\n');
						ModalError({
							content: (
								<div className={styles.warningMessages}>
									<InformationMessages messages={messages} />
								</div>
							),
							okText: _('OK'),
							width: '600px',
							onOk: () => {
								redirectToLink();
								cancelHandle?.(null);
							},
						});
					},
				});
			});
		};

		const handleChangeControllerType = (controllerType: ControllerType, controllerId: number, callback?: () => void) => {
			deviceAdminApi.changeControllerType(controllerType, controllerId).then(response => {
				NotificationStatus({
					responseData: response,
					notUseDefaultNotification: true,
					onSuccessCallback: () => {
						callback();
					},
					onComponentLockedCallback: async () => {
						const warningType: DeviceAdminWarningType = Number(response.ResponseErrorDescription) as DeviceAdminWarningType;
						if (warningType === DeviceAdminWarningType.DoorGroupLockStatus) {
							const lockStatus = await deviceAdminApi.getControllerTypeChangeLocks(controllerId, controllerType);
							const { ErrorMessage, ResponseErrorDescription } = lockStatus;
							const conflictModel: LockStatusDoorGroupViewModel = {
								IsMasterDoorGroup: false,
								LockStatusWarning: lockStatus,
								ObjectId: controllerId,
							};
							const title: string = ResponseErrorDescription;
							const content = <DoorGroupLockStatus conflictModel={conflictModel} errorDescription={ErrorMessage} isDoorLocks={true} />;
							ModalWarning({
								title: title,
								width: '600px',
								hideCancelButton: true,
								okText: 'OK',
								content: content,
								onOk: callback,
							});
						} else {
							handleModalWarning(response.ErrorMessage, callback);
						}
					},
					onPermissionErrorCallback: async () => {
						notification['error']({
							message: response.ErrorMessage,
						});
					},
				});
			});
		};

		const handleBackControllersList = (saveChanges: boolean) => {
			if (!saveChanges && controllerInfo.hasChangedController) {
				setHasChangedModal(true);
				isBackToControllersRef.current = true;
			} else {
				const deviceId = Number(controllerIdParam);
				deviceAdminApi.unlockComponentDeviceAdmin(deviceId, SecuredComponents.Controller).finally(() => {
					if (!isModal) window.history.replaceState({}, '', `${location.pathname}`);
					const newDevice = { Id: deviceId, DeviceObjectType: DeviceObjectType.Controller, IsModalOpen: false };
					batch(() => {
						dispatch(setControllerHasChanged(false));
						dispatch(setCurrentDevice(newDevice));
						callbackRedirect?.();
						cancelHandle?.(newDevice);
					});
				});
			}
		};

		const handleChangeDB = (changeLocation: boolean) => {
			const { controllerData } = controllerInfo;
			const normArray: ControllerACBSetup[] = convertObjectToArray(controllerData.ControllerACBSetup);
			const controller: ControllerData = {
				...controllerData,
				ControllerACBSetup: [...normArray],
			};
			controller.DownloadAllCredentials = changeLocation;
			setChangeDBLocation([]);
			onTryEditController(controller);
		};

		const handleCloseNewTimeZone = (newId: number) => {
			deviceAdminApi.getGenericTimeZonesWithPermissions().then(genericTimeZones => {
				if (canAddNewTimeZones()) {
					const newTimeZone: SelectTimeZone = {
						Name: `<${_('New')}>`,
						Unpermitted: false,
						GenericId: 0,
						TimeZoneType: TimeZoneType.Standard,
						GlobalId: 0,
					};

					genericTimeZones.unshift(newTimeZone);
				}

				dispatch(setControllerPropertyAction({ GenericTimeZones: [...genericTimeZones] }));
				dispatch(setCurrentTimeZones(newId));
				dispatch(setOpenTimeZones(null));
			});
		};

		const handleOnCloseTimeZoneModal = () => {
			dispatch(setOpenTimeZones(null));
			dispatch(setCurrentTimeZones(0));
		};

		const handleNoSaveController = () => {
			setLoadingAction(false, LoadingType.WarningSaveChanges);
			if (!isModal) window.history.replaceState({}, '', `${location.pathname}`);
			const newDevice = { Id: Number(controllerIdParam), DeviceObjectType: DeviceObjectType.Controller, IsModalOpen: false };
			batch(() => {
				dispatch(setControllerHasChanged(false));
				dispatch(setCurrentDevice(newDevice));
			});
			cancelHandle?.(newDevice);
			deviceAdminApi.unlockComponentDeviceAdmin(Number(controllerIdParam), SecuredComponents.Controller);
		};

		const handleCancelSaveController = () => {
			setLoadingAction(false, LoadingType.WarningSaveChanges);
			setHasChangedModal(false);
		};

		const handleBeforeUnload = () => {
			deviceAdminApi.unlockComponentDeviceAdmin(Number(controllerIdParam), SecuredComponents.Controller);
		};

		const controllerIdParam = new URLSearchParams(location.search).get('controllerId');

		//ensure data is ready to be rendered
		const dataIsLoaded = controllerInfo.controllerData?.Address;

		const navigationTitle = `${navigationParentTitle} / ${navigationChildTitle}`;
		const controllerTitle = `${_('EditControllerAtAddress')}: ${controllerInfo.controllerData?.Address}`;

		const renderController = () => (
			<Spin wrapperClassName={styles.spin} size='large' tip={`${_('Loading')}...`} spinning={!dataIsLoaded || loading}>
				{dataIsLoaded && (
					<div className={styles.container}>
						{!isModal && (
							<>
								<div className={styles.navigationContainer}>
									<Breadcrumb>
										<Breadcrumb.Item>
											<a onClick={() => handleBackControllersList(false)}>
												<ArrowLeftOutlined /> <label id='go-back-label'>{navigationTitle}</label>
											</a>
										</Breadcrumb.Item>
										<Breadcrumb.Item>{controllerInfo?.controllerData?.Name}</Breadcrumb.Item>
									</Breadcrumb>
								</div>
								<div className={styles.controllerHeader}>
									<label className={styles.label}>{controllerTitle}</label>
								</div>
							</>
						)}
						<Tabs
							destroyInactiveTabPane={true}
							activeKey={activeTab}
							type='card'
							onChange={key => {
								setActiveTab(key);
								if (controllerInfo.hasChangedName) {
									ModalWarning({
										okText: _('Ok'),
										onOk: () => dispatch(setControlZonesHasChangedName(false)),
										hideCancelButton: true,
										content: <div>{_('UnSavedChangesWarning')}</div>,
									});
								} else {
									dispatch(setControlZonesHasChangedName(false));
								}
							}}
							className={styles.tabs}>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().general)}>{_('General')}</label>}
								key={ControllerKeyTabs.General}
								disabled={!canOpenTabs().general || controllerInfo.isControlZoneEditing}>
								<General callbackRedirect={callbackRedirect} />
							</TabPane>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().setup)}>{_('SetUp')}</label>}
								key={ControllerKeyTabs.Setup}
								disabled={!canOpenTabs().setup || controllerInfo.isControlZoneEditing}>
								<Setup />
							</TabPane>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().reporting)}>{_('Reporting')}</label>}
								key={ControllerKeyTabs.Reporting}
								disabled={!canOpenTabs().reporting || controllerInfo.isControlZoneEditing}>
								<Reporting />
							</TabPane>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().passback)}>{_('Passback')}</label>}
								key={ControllerKeyTabs.Passback}
								disabled={!canOpenTabs().passback || controllerInfo.isControlZoneEditing}>
								<PassBack />
							</TabPane>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().controlZones)}>{_('ControlZones')}</label>}
								key={ControllerKeyTabs.ControlZones}
								disabled={!canOpenTabs().controlZones || controllerInfo.isControlZoneEditing}>
								<ControlZones />
							</TabPane>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().alarms)}>{_('AlarmActions')}</label>}
								key={ControllerKeyTabs.AlarmActions}
								disabled={!canOpenTabs().alarms || controllerInfo.isControlZoneEditing}>
								<AlarmActions />
							</TabPane>
							<TabPane
								tab={<label title={getPermissionErrorMessage(canOpenTabs().communications)}>{_('Communications')}</label>}
								key={ControllerKeyTabs.Communications}
								disabled={!canOpenTabs().communications || controllerInfo.isControlZoneEditing}>
								<Communications />
							</TabPane>
						</Tabs>
						<Modal
							keyboard={false}
							maskClosable={false}
							visible={controllerInfo.controllerData?.openTimeZone?.open}
							title={_('TimeZones')}
							onClickOk={handleOnCloseTimeZoneModal}
							onCancel={handleOnCloseTimeZoneModal}
							width={'900px'}
							footer={null}>
							<TimeZones disableDelete onEntityAction={handleCloseNewTimeZone} />
						</Modal>
						<ModalConfirmCustomFooter
							title={_('Warning')}
							width={'700px'}
							visible={showHasChangedModal}
							navigateTo={redirectTo}
							loadingOk={warningSaveChangesSubmitted}
							onOk={handleSaveChangesController}
							onNo={isBackToControllersRef.current ? handleNoSaveController : undefined}
							onCancel={handleCancelSaveController}
							children={<label className={styles.saveChangesWarning}>{_('ControllerSettingsHaveChangedSavesChanges')}</label>}
						/>
						{activeTab !== '5' ? (
							<div>
								<Button
									id='controllerSaveChangesButton'
									disabled={
										Object.entries(controllerInfo.controllerData.error).some(([key, value]) => value === true && key !== 'systemCode') ||
										controllerInfo.controllerData.occupancyTwoPersonsRules.some(x => x.error) ||
										!canSaveController.canUpdate ||
										controllerInfo.isControlZoneEditing
									}
									key='save'
									type='primary'
									onClick={handleSaveController}
									title={getPermissionErrorMessage(canSaveController.canUpdate)}
									loading={submitted}>
									{_('SaveChanges')}
								</Button>
								<Button id='controllerCancelButton' key='cancel' onClick={() => handleBackControllersList(false)}>
									{_('Cancel')}
								</Button>
							</div>
						) : (
							<div />
						)}
						<ChangeDBLocation
							width='500px'
							visible={changeDBLocation.length > 0}
							content={<InformationMessages messages={changeDBLocation} />}
							onClickOk={() => handleChangeDB(true)}
							onClickCancel={() => {
								setChangeDBLocation([]);
								setLoadingAction(false, LoadingType.MainSaveChanges);
							}}
							onClickNo={() => handleChangeDB(false)}
						/>
					</div>
				)}
			</Spin>
		);

		if (isModal) {
			return (
				<Modal
					className={styles.modal}
					closable
					footer={null}
					visible
					title={controllerTitle}
					width='1200px'
					onCancel={() => handleBackControllersList(false)}>
					{renderController()}
				</Modal>
			);
		}
		return renderController();
	}
);

export { ControllerModal };

import { Checkbox, Input } from 'antd';
import cx from 'classnames';
import React, { useContext, useState } from 'react';
import { filterOption, removeCommasAndPercentSign } from '../../../../../../Helper';
import { ControllerPortData, ControllerType, ControllerXBoxData, PortConnectionType } from '../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../common';
import {
	setAddress,
	setControllerType,
	setDescription,
	setDisplayTimeZone,
	setEnable,
	setName,
	setPort,
	setStandardTimeZone,
	setUseDST,
	setWirelessVendor,
	setXBox,
	StoreContext,
} from '../AddControllerContext';
import styles from '../addControllerModal.module.scss';

type Props = {
	disableAddress: boolean;
	submitted: boolean;
	errorMessage: string;
	resetErrorMessage: () => void;
	resetSubmit: () => void;
};

const mainDivName: string = 'addControllerSections';

const BasicAddControllerInformation: React.FC<Props> = ({ resetSubmit, resetErrorMessage, errorMessage, disableAddress, submitted }) => {
	const {
		addControllerState: {
			name,
			ports,
			xBoxes,
			availableAddresses,
			systemTimeZones,
			enabled,
			useDaylightSavingsTime,
			controllerTypeList,
			description,
			wirelessVendorList,
			selections: { wirelessVendor, portId, xBoxId, address, controllerType, timeZoneDisplayName },
		},
		dispatcher,
	} = useContext(StoreContext);

	const [selectedPort, setSelectedPort] = useState<ControllerPortData>();
	const [selectedXBox, setSelectedXBox] = useState<ControllerXBoxData>();

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		dispatcher(setName(value));
		resetErrorMessage();
	};

	const handleOnChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.currentTarget.value ? e.currentTarget.value : '';
		dispatcher(setDescription(value));
	};

	const handleOnChangePort = (value: string) => {
		dispatcher(setPort(Number(value)));
		const portIndex: number = ports.findIndex(x => x.Id === Number(value));
		if (~portIndex) {
			setSelectedPort(ports[portIndex]);
			setSelectedXBox(undefined);
		}
		resetSubmit();
	};

	const handleOnChangeXBox = (value: string) => {
		dispatcher(setXBox(Number(value)));
		const xBoxIndex: number = xBoxes.findIndex(x => x.Id === Number(value));
		if (~xBoxIndex) {
			setSelectedXBox(xBoxes[xBoxIndex]);
		}
		resetSubmit();
	};

	const handleOnChangeControllerType = (value: string) => {
		dispatcher(setControllerType(Number(value)));
		if (Number(value) == ControllerType.Mx1w) {
			dispatcher(setWirelessVendor(1));
		}
	};

	const handleOnChangeTimeZone = (displayName: string) => {
		const timeZoneIndex: number = systemTimeZones.findIndex(x => x.DisplayName === displayName);
		if (~timeZoneIndex) {
			dispatcher(setStandardTimeZone(systemTimeZones[timeZoneIndex].StandardName));
			dispatcher(setDisplayTimeZone(displayName));
		}
	};

	const portValue = portId === 0 ? undefined : portId.toString();
	const xBoxValue = xBoxId === 0 ? undefined : xBoxId.toString();
	const portValidation: boolean = submitted && portId === 0;
	const xBoxValidation: boolean = submitted && xBoxId === 0;
	const addControllerPortsDropdownId = 'addControllerPortsDropdown';
	const addControllerXBoxDropdownId = 'addControllerXBoxDropdown';
	const addControllerNameInputId = 'addControllerNameInput';
	const addControllerEnableCheckboxId = 'addControllerEnableCheckbox';
	const addControllerUseDSTCheckboxId = 'addControllerUseDSTCheckbox';
	const addControllerWirelessVendorDropdownId = 'addControllerWirelessVendorDropdown';
	const addControllerTimeZoneDropdownId = 'addControllerTimeZoneDropdown';

	return (
		<div id={mainDivName}>
			<div className={styles.portsXBoxes}>
				<div>
					<label htmlFor={addControllerPortsDropdownId}>{_('Ports')}</label>
					<Select
						id={addControllerPortsDropdownId}
						onChange={handleOnChangePort}
						options={ports.map((x, optionIndex) => ({
							label: x.NameWithAddress,
							value: x.Id.toString(),
							id: `addControllerPortsOption-${optionIndex}`,
						}))}
						value={portValue}
						showSearch={true}
						filterOption={filterOption}
						maxSearchLength={32}
						className={cx({
							[styles.error]: portValidation,
						})}
						placeholder={_('SelectPort')}
						getPopupContainer={() => document.getElementById(mainDivName)}
						showOptionalStringOnSelectedValue={currentLabel => selectedPort?.Name}
					/>
					{portValidation && (
						<React.Fragment>
							<div></div>
							<label className={styles.errorMessage} htmlFor={addControllerPortsDropdownId}>
								{_('PortIsRequired')}
							</label>
						</React.Fragment>
					)}
				</div>
				<div className={styles.addressMargin}>
					<span>{_('PortAddress')}: </span>
					<span>{selectedPort?.Address}</span>
				</div>
				{selectedPort?.PortConnectionType === PortConnectionType.XNET && (
					<div className={styles.xBoxContainer}>
						<div>
							<label htmlFor={addControllerXBoxDropdownId}>{_('XBoxes')}:</label>
							<Select
								id={addControllerXBoxDropdownId}
								onChange={handleOnChangeXBox}
								options={xBoxes.map((x, optionIndex) => ({
									label: x.NameWithAddress,
									value: x.Id.toString(),
									id: `addControllerXBoxOption-${optionIndex}`,
								}))}
								value={xBoxValue}
								showSearch={true}
								filterOption={filterOption}
								maxSearchLength={32}
								className={cx({
									[styles.error]: xBoxValidation,
								})}
								placeholder={_('SelectXBox')}
								getPopupContainer={() => document.getElementById(mainDivName)}
								showOptionalStringOnSelectedValue={currentLabel => selectedXBox?.Name}
							/>
							{xBoxValidation && (
								<React.Fragment>
									<div></div>
									<label className={styles.errorMessage} htmlFor={addControllerXBoxDropdownId}>
										{_('XBoxIsRequired')}
									</label>{' '}
								</React.Fragment>
							)}
						</div>
						<div className={styles.addressMargin}>
							<label htmlFor={addControllerXBoxDropdownId}>{_('XBoxAddress')}:</label>
							<label htmlFor={addControllerXBoxDropdownId}>{selectedXBox?.Address}</label>
						</div>
					</div>
				)}
			</div>
			<div className={styles.sections}>
				<div>
					<label htmlFor={addControllerNameInputId}>{_('Name')}</label>
					<Input
						id={addControllerNameInputId}
						maxLength={32}
						onChange={handleOnChangeName}
						value={name}
						className={cx({
							[styles.error]: errorMessage,
						})}
					/>
					{errorMessage && (
						<React.Fragment>
							<div></div>
							<label className={styles.errorMessage} htmlFor={addControllerNameInputId}>
								{errorMessage}
							</label>
						</React.Fragment>
					)}
				</div>
				<div>
					<label htmlFor='addControllerAddressDropdown'>{_('Address')}</label>
					<Select
						id='addControllerAddressDropdown'
						options={availableAddresses.map(x => ({ label: x.Name.toString(), value: x.Id.toString() }))}
						onChange={value => {
							dispatcher(setAddress(Number(value)));
						}}
						disabled={disableAddress}
						value={address.toString()}
					/>
				</div>
				<div>
					<label htmlFor='addControllerDescriptionInput'>{_('Description')}</label>
					<Input id='addControllerDescriptionInput' maxLength={50} onChange={handleOnChangeDescription} value={description} />
				</div>
				<div>
					<label htmlFor='addControllerTypeDropdown'>{_('Model')}</label>
					<Select
						id='addControllerTypeDropdown'
						onChange={handleOnChangeControllerType}
						value={controllerType}
						notUseCurrentParse
						options={controllerTypeList.map(x => ({ label: x.Name, value: x.Id }))}
					/>
				</div>
				<label className={styles.timeZoneLabel} htmlFor={addControllerTimeZoneDropdownId}>
					{_('TimeZoneWhereControllerIsLocated')}
				</label>
				<div>
					{controllerType === ControllerType.Mx1w && (
						<div className={styles.wirelessVendor}>
							<label htmlFor={addControllerWirelessVendorDropdownId}>{_('WirelessVendor')}:</label>
							<Select
								id={addControllerWirelessVendorDropdownId}
								onChange={value => {
									dispatcher(setWirelessVendor(Number(value)));
								}}
								value={wirelessVendor}
								notUseCurrentParse
								options={wirelessVendorList.map(x => ({ label: x.Name, value: x.Id }))}
							/>
						</div>
					)}
				</div>
				<Select
					id={addControllerTimeZoneDropdownId}
					onChange={handleOnChangeTimeZone}
					value={timeZoneDisplayName}
					options={systemTimeZones.map(ts => ({ label: ts.DisplayName, value: ts.DisplayName }))}
					showSearch
				/>
				<Checkbox
					id={addControllerEnableCheckboxId}
					onChange={value => {
						dispatcher(setEnable(value.target.checked));
					}}
					checked={enabled}>
					<label htmlFor={addControllerEnableCheckboxId}>{_('Enable')}</label>
				</Checkbox>
				<Checkbox
					id={addControllerUseDSTCheckboxId}
					onChange={value => {
						dispatcher(setUseDST(value.target.checked));
					}}
					checked={useDaylightSavingsTime}>
					<label htmlFor={addControllerUseDSTCheckboxId}>{_('UseDaylightSavingsTime')}</label>
				</Checkbox>
			</div>
		</div>
	);
};

export { BasicAddControllerInformation };

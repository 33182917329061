import React, { useState } from 'react';
import { filterOption } from '../../../Helper';
import { Zone } from '../../../model/WhosInsideModel';
import { Select } from '../../common';
import styles from '../whosInside.module.scss';

type Props = {
	headerText: string;
	sharedZonesList: Zone[];
	setSharedZoneIdRef: (sharedZoneId: number) => void;
};

const MapToSharedZone: React.FC<Props> = ({ headerText, sharedZonesList, setSharedZoneIdRef }) => {
	const [sharedZoneId, setSharedZoneId] = useState<number>(1);

	const handleOnChangeMapToSharedZone = (sharedZoneId: string) => {
		setSharedZoneIdRef(Number(sharedZoneId));
		setSharedZoneId(Number(sharedZoneId));
	};

	return (
		<div id='whosInsideMapToSharedZoneContainer'>
			<p id='whosInsideMapToSharedZoneText'>{headerText}</p>
			<Select
				ariaAttributes={{
					'aria-label': _('SharedZonesDropdownOptions'),
					'aria-owns': 'whosInsideMapToSharedZonesDropdown',
					'aria-activedescendant': 'whosInsideMapToSharedZonesDropdown',
					'aria-controls': 'whosInsideMapToSharedZonesDropdown',
					'aria-expanded': 'true',
				}}
				className={styles.mapToSharedZonesDropdown}
				id='whosInsideMapToSharedZonesDropdown'
				notUseCurrentParse
				value={sharedZoneId}
				options={sharedZonesList.map((x, optionIndex) => ({
					label: x.ZoneName,
					value: x.ZoneId,
					id: `sharedZonesDropdownOptions-${optionIndex}`,
				}))}
				onChange={handleOnChangeMapToSharedZone}
				getPopupContainer={element => element}
				showSearch={true}
				filterOption={filterOption}
			/>
		</div>
	);
};

export { MapToSharedZone };

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useState } from 'react';
import { noneDecimalFormatter } from '../../../../../../../../../Helper';
import { SubPermissions, User } from '../../../../../../../../../model/AccountModel';
import { InformationMessages, ModalConfirmation, ModalWarning } from '../../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderVersions } from '../../../../../Tabs/sections/RS485Version/RS485Body/RS485Body';
import { UpdateFirmwareModal } from '../../../../UpdateFirmwareModal/UpdateFirmwareModal';
import { setExitReaderOSDPAddress, setExitReaderOkDegrade } from '../../../../exitReaderContext';
import styles from '../../../General/general.module.scss';

type Props = {
	handleDownloadFirmware: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const userCanUpdateFirmware = User.getSubComponentPermission(user, SubPermissions.Reader_FirmwareUpdateTSReader).allowed;
const style: React.CSSProperties = { color: '#faad14' };

const RS485Body: React.FC<Props> = ({ handleDownloadFirmware }) => {
	const [showModal, setShowModal] = useState(false);
	var {
		contextStateDoor: {
			exitReader: {
				isFICAMEnabled,
				updateFirmwarePermissionText,
				canUpdateFirmware,
				oSDPAddress,
				firmware,
				appVersion,
				displayBoardVersion,
				firmwareConfirm,
				okDegrade,
				firmwareTitle,
				selections: { rS485ReaderType },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleOnChangeAddress = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value != 0) {
				return;
			}
		}

		dispatcherDoor(setExitReaderOSDPAddress(Number(value.toString())));
	};

	const handleOnClickUpdateFirmware = () => {
		if (userCanUpdateFirmware) {
			const messages: string[] = firmwareConfirm.split('\n');
			const content: JSX.Element = <InformationMessages messages={messages} />;

			ModalConfirmation({
				title: firmwareTitle,
				content: content,
				onConfirm: () => setShowModal(true),
				okText: _('Yes'),
				cancelText: _('No'),
				width: '700px',
				icon: <ExclamationCircleOutlined style={style} />,
			});
		} else {
			const content: JSX.Element = <InformationMessages messages={[updateFirmwarePermissionText]} />;

			ModalWarning({
				okText: _('Ok'),
				content: content,
				width: '600px',
			});
		}
	};

	const handleOnSetOkDegrade = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderOkDegrade(value.target.checked));
	};

	const handleOnCloseUpdateFirmwareModal = () => {
		setShowModal(false);
	};

	return (
		<>
			<div className={styles.sections}>
				<span>{_('OSDPAddress')}</span>
				<div>
					<InputNumber
						key='osdpAddress'
						min={0}
						max={126}
						maxLength={3}
						value={oSDPAddress}
						onChange={handleOnChangeAddress}
						id={'osdpAddress'}
						className={styles.input}
						formatter={noneDecimalFormatter}
					/>
				</div>
			</div>

			{isFICAMEnabled && (
				<div className={styles.sections}>
					<div className={styles.okDegrade}>
						<Checkbox checked={okDegrade} onChange={handleOnSetOkDegrade} id={'okDegrade'}>
							<span>{_('AllowAccessInDegrade')}</span>
						</Checkbox>
					</div>
				</div>
			)}
			<div className={styles.doubleColumn}>
				<ReaderVersions rS485ReaderType={rS485ReaderType} firmware={firmware} appVersion={appVersion} displayBoardVersion={displayBoardVersion} />
				<div className={styles.rightColumn}>
					{canUpdateFirmware && (
						<Button type='primary' id='exitReaderModalUpdateReaderFirmwareButton' onClick={handleOnClickUpdateFirmware}>
							{_('UpdateReaderFirmware')}
						</Button>
					)}
				</div>
			</div>
			<UpdateFirmwareModal
				visible={showModal}
				closeModal={handleOnCloseUpdateFirmwareModal}
				handleDownloadFirmware={handleDownloadFirmware}></UpdateFirmwareModal>
		</>
	);
};

export { RS485Body };

import { Button, Spin } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { PaginationSetting, ResponseObject } from '../../../../model/CommonModel';
import { ControllerContact, CurrentDeviceControlObj, DetailedXboxAddress, DeviceObjectType, XboxData } from '../../../../model/DeviceAdminModel';
import { Logger } from '../../../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { setControllerContacts } from '../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { Modal, ModalLoading, NotificationStatus, WithLockedValidation } from '../../../common';
import { StoreContext, initXbox, setPorts, setXAddress, setXAddresses, xboxContext, xboxState } from './contextXbox';
import { LogOff, MsgPerPoll, Name, Ports, XAddress, XboxState } from './sections';
import styles from './xboxModal.module.scss';

const user: User = getUser();
const permissionsEnabled: boolean = User.getComponentPermission(user, SecuredComponents.XBox).canUpdate;

type Props = {
	xboxToBeEditable?: CurrentDeviceControlObj;
	setShouldResetSearchColumn: () => void;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	setRedirectPage?: () => void;
	saveCallBack?: () => void;
};

const XboxModal: React.FC<Props> = WithLockedValidation(({ xboxToBeEditable, setShouldResetSearchColumn, cancelHandle, saveCallBack, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const [stateContext, dispatchActionContext] = useReducer(xboxContext, xboxState);

	const contextValue = useMemo(() => {
		return { xboxState: stateContext, dispatcher: dispatchActionContext };
	}, [stateContext, dispatchActionContext]);

	const [submitted, setSubmit] = useState<boolean>(false);
	const [nameError, setNameError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
	const [originalValues, setOriginalValues] = useState<{ LogOff: number; MsgPerPoll: number; XAddress: number; Enabled: boolean }>({
		LogOff: 0,
		MsgPerPoll: 0,
		XAddress: 0,
		Enabled: true,
	});

	const {
		messagesPerPoll,
		logOffTime,
		enabled,
		revision,
		name,
		selections: { port, xAddress },
	} = stateContext;

	const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const nameValidation: boolean = submitted && (!name || name.trim() === '');
	const portValidation: boolean = submitted && port === 0;
	const disabledDropdown: boolean = xboxToBeEditable.Id === 0 ? false : true;

	useEffect(() => {
		if (xboxToBeEditable.Id !== 0) {
			deviceAdminApi.getXboxData(xboxToBeEditable.Id).then(res => {
				dispatchActionContext(initXbox(res));
				setOriginalValues({ LogOff: res.LogOff, MsgPerPoll: res.MsgPerPoll, XAddress: res.XAddress, Enabled: res.Enabled });
			});
		}
	}, [xboxToBeEditable.Id]);

	useEffect(() => {
		if (~port) {
			let xAddress: number = 0;
			let isNewXbox: boolean = true;

			if (xboxToBeEditable.Id !== 0) {
				const detailedAddress: DetailedXboxAddress = getDetailedXboxAddress();
				xAddress = Number(detailedAddress.XAddress);
				isNewXbox = false;
			}

			deviceAdminApi.getAvailableXAddresses(isNewXbox, port, xAddress).then(res => {
				dispatchActionContext(setXAddresses(res));
				if (isNewXbox && res.length > 0 && port != 0) {
					dispatchActionContext(setXAddress(res[0]));
				}
			});
		}
	}, [port]);

	useEffect(() => {
		deviceAdminApi
			.getControllerContacts(DeviceObjectType.Port)
			.then(res => {
				const xNetPorts: ControllerContact[] = res.Entity.filter(x => x.Protocol.match(/xnet/i));
				dispatchActionContext(setPorts(xNetPorts));
			})
			.catch(e => {
				Logger.writeErrorLog(e);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const getDetailedXboxAddress = (): DetailedXboxAddress => {
		const unbundledAddress: string[] = xboxToBeEditable.Address.split('.');
		const detailedAddress: DetailedXboxAddress = {
			Protocol: unbundledAddress[0],
			SecurityDomain: unbundledAddress[1],
			Port: unbundledAddress[2],
			XAddress: unbundledAddress[3],
		};

		return detailedAddress;
	};

	const getUpdatedAddressForEdit = (): string => {
		let xboxAddress: string = '';
		if (xboxToBeEditable.Id !== 0) {
			const detailedXboxAddress: DetailedXboxAddress = getDetailedXboxAddress();
			let updatedXAddress: string = xAddress.toString();
			while ((updatedXAddress + '').length < 3) {
				updatedXAddress = '0' + updatedXAddress;
			}
			xboxAddress = `${detailedXboxAddress.Protocol}.${detailedXboxAddress.SecurityDomain}.${detailedXboxAddress.Port}.${updatedXAddress}`;
		}
		return xboxAddress;
	};

	const hasXboxChanged = () => {
		let hasChanged = false;

		hasChanged = logOffTime !== originalValues.LogOff ? true : hasChanged;
		hasChanged = messagesPerPoll !== originalValues.MsgPerPoll ? true : hasChanged;
		hasChanged = enabled !== originalValues.Enabled ? true : hasChanged;
		hasChanged = xAddress !== originalValues.XAddress ? true : hasChanged;

		return hasChanged;
	};

	const shouldResetSearchColumn = isEditXbox => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!isEditXbox) {
			return;
		}

		if (setRedirectPage) {
			setRedirectPage();
		}
	};

	const handleAddEditXbox = async () => {
		setSubmit(true);
		if (!name || name.trim() === '' || port === 0 || xAddress === 0) {
			return;
		}

		setConfirmLoading(hasXboxChanged());
		const xboxId: number = xboxToBeEditable.Id !== 0 ? xboxToBeEditable.Id : 0;
		const xboxAddress: string = getUpdatedAddressForEdit();

		const xboxData: XboxData = {
			OriginalXAddress: originalValues.XAddress,
			OriginalEnabled: originalValues.Enabled,
			MsgPerPoll: messagesPerPoll,
			LogOff: logOffTime,
			Enabled: enabled,
			XAddress: xAddress,
			XboxId: xboxId,
			XboxName: name,
			PortId: port,
			Revision: revision,
			UnpermittedPortName: '',
			UnpermittedPortAddress: '',
		};

		const isEditXbox: boolean = xboxToBeEditable.Id !== 0;
		const response: ResponseObject = await deviceAdminApi.saveXbox(xboxData);
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		NotificationStatus({
			responseData: response,
			onSuccessCallback: () => {
				dispatch(setControllerContacts(DeviceObjectType.Xbox, paginationSetting));
				setConfirmLoading(false);
				handleCancelModal();
				shouldResetSearchColumn(isEditXbox);
				if (saveCallBack) {
					saveCallBack();
				}
			},
			onFailedValidation: () => {
				setNameError(true);
				setConfirmLoading(false);
			},
		});
	};

	const handleCloseModal = (): void => {
		let newDevice = { Id: 0, DeviceObjectType: DeviceObjectType.Xbox, address: '', IsModalOpen: false };
		if (cancelHandle) {
			cancelHandle(xboxToBeEditable);
		}
		dispatch(setCurrentDevice(newDevice));
	};

	const handleCancelModal = (): void => {
		deviceAdminApi.unlockComponentDeviceAdmin(xboxToBeEditable.Id, SecuredComponents.XBox);
		handleCloseModal();
	};

	return (
		<StoreContext.Provider value={contextValue}>
			<Modal
				footer={[
					<Button
						id='xboxSaveChangesButton'
						key='save'
						type='primary'
						title={getPermissionErrorMessage(permissionsEnabled)}
						disabled={!permissionsEnabled}
						onClick={() => handleAddEditXbox()}>
						{_('SaveChanges')}
					</Button>,
					<Button id='xboxCancelButton' key='cancel' onClick={() => handleCancelModal()}>
						{_('Cancel')}
					</Button>,
				]}
				visible={true}
				title={xboxToBeEditable.Id === 0 ? _('AddXbox') : `${_('EditXbox')} ${xboxToBeEditable.Address}`}
				onCancel={() => handleCancelModal()}
				onClickOk={() => null}
				width={'750px'}
				customZoomClass={styles.withModalZoom}>
				<Spin wrapperClassName={styles.spin} size='large' tip={`${_('Loading')}...`} spinning={isLoading}>
					<div className={styles.container} id='xboxModalContainer'>
						<div className={styles.form}>
							<Ports portValidation={portValidation} disableEdit={disabledDropdown} />
							<Name nameValidation={nameValidation} nameError={nameError} onResetNameError={() => setNameError(false)} />
							<XboxState />
							<XAddress />
							<MsgPerPoll />
							<LogOff />
							<div className={styles.revision}>
								<label>
									{_('Revision')} {revision}
								</label>
							</div>
						</div>
					</div>
				</Spin>
			</Modal>
			<ModalLoading visible={originalValues.Enabled && enabled && confirmLoading && xboxToBeEditable.Id !== 0}>
				<label>{_('VelocityIsConfiguringXbox')}</label>
			</ModalLoading>
		</StoreContext.Provider>
	);
});

export { XboxModal };

import React from 'react';
import styles from '../../portModal.module.scss';
import { IntervalTimeout, TimeoutConstant, TimeoutMultiplier } from '../sections';
type Props = {};

const AdvancedSettings: React.FC<Props> = () => {
	return (
		<div className={styles.form}>
			<div className={styles.advancedSettingsContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('TimeoutSettings')}</legend>
					<TimeoutMultiplier />
					<TimeoutConstant />
					<IntervalTimeout />
				</fieldset>
			</div>
		</div>
	);
};

export { AdvancedSettings };

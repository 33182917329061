import { Tree } from 'antd';
import { EventDataNode } from 'antd/lib/tree';
import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getDefaultTablePaginationConfig } from '../../Helper';
import { HeaderBar } from '../../components/common';
import { ControllerModal } from '../../components/devicecontrol/DigiTrac/ControllerModal/ControllerModal';
import { DigiTrac, VelocityConfiguration } from '../../components/devicecontrol/Index';
import { EventTable } from '../../components/event';
import { SecuredComponents, User } from '../../model/AccountModel';
import { PaginationSetting, SortDirections } from '../../model/CommonModel';
import { ControllerContact, CurrentDeviceControlObj, DeviceObjectType, DeviceType, ObjectTypes, TimeZoneType } from '../../model/DeviceAdminModel';
import { Logger } from '../../model/LoggingModel';
import { StoreEvent } from '../../store';
import { setAllItemsPagination, setCurrentDevice } from '../../store/common/actions';
import {
	setCommandsSetsBy,
	setControllerContacts,
	setControllerContactsByData,
	setCredentialTemplatesBy,
	setDefaultTablePaginationSetting,
	setDigiTracFilterMode,
	setDoorGroupsBy,
	setGlobalIOGroupsBy,
	setGrandTimeZonesBy,
	setHolidaysBy,
	setMasterTimeZonesBy,
	setOperatorsBy,
	setReaderControlGroupsBy,
	setRolesBy,
	setStandardTimeZonesBy,
} from '../../store/deviceControl/actions';
import { RootReducer } from '../../store/rootReducer';
import styles from './deviceControl.module.scss';

const user: User = getUser();
const { isAdmin, showEventsOnDeviceAdmPage } = user;

// Internal state for the component
interface State {
	expandedKeys: string[];
	currentDeviceObject: DeviceObjectType;
	currentDevice: DeviceType;
	propKey: number;
	selectedKeys: React.Key[];
}

// Props those being mapped from Store
interface StateProps {
	currentDevice: CurrentDeviceControlObj;
	isEditMode: boolean;
	currentTimeZone: TimeZoneType;
	itemSearchedPagination: string;
	tablePaginationSetting: PaginationSetting;
}

// Dispatchable methods (invoke our store's actions)
interface DispatchProps {
	onChangeDevice: (deviceType: CurrentDeviceControlObj) => void;
	setCommandsSetsBy: (paginationSetting?: PaginationSetting) => void;
	setContactsControllers: (type: DeviceObjectType, paginationSetting?: PaginationSetting) => void;
	setCredentialTemplatesBy: (paginationSetting?: PaginationSetting) => void;
	setDoorGroupsBy: (paginationSetting?: PaginationSetting) => void;
	setReaderControlGroupBy: (paginationSetting?: PaginationSetting) => void;
	setStandardTimeZonesBy: (paginationSetting: PaginationSetting) => void;
	setMasterTimeZonesBy: (paginationSetting: PaginationSetting) => void;
	setGrandMasterTimeZonesBy: (paginationSetting: PaginationSetting) => void;
	setHolidaysBy: (paginationSetting?: PaginationSetting) => void;
	setOperatorsBy: (paginationSetting?: PaginationSetting) => void;
	setRolesBy: (paginationSetting?: PaginationSetting) => void;
	setControllerContactsByData: (type: DeviceObjectType, data: ControllerContact[]) => void;
	setGlobalIOGroupsBy: (paginationSetting?: PaginationSetting) => void;
	setAllItemsPagination: (deviceObjectType: DeviceObjectType, objectType?: ObjectTypes) => void;
	setDefaultTablePaginationSetting: () => void;
	setDigitracFilterMode: (isFilterMode: boolean) => void;
}

// Normal properties for the component
interface OwnProps {}

// combine them together
type Props = StateProps & DispatchProps & OwnProps;

type BasicDeviceControlChildrenTreeDataType = {
	title: React.ReactNode;
	key: string;
};

type DeviceControlChildrenTreeDataType = {
	deviceObjectType: DeviceObjectType;
} & BasicDeviceControlChildrenTreeDataType;

type DeviceControlTreeDataType = {
	deviceType: DeviceType;
	children: DeviceControlChildrenTreeDataType[];
} & BasicDeviceControlChildrenTreeDataType;

export const getTitleComponent = (title: string, id: string): React.ReactNode => (
	<label title={title} id={id}>
		{title}
	</label>
);

//#region Velocity Configuration children
const canViewCommandSets = User.getComponentPermission(user, SecuredComponents.Command_Set).canView;
const canViewCredentialTemplates = User.getComponentPermission(user, SecuredComponents.Credential_Template).canView;
const canViewDoorGroups = User.getComponentPermission(user, SecuredComponents.Door_Groups).canView;
const canViewNetworkGIO = User.getComponentPermission(user, SecuredComponents.Global_IO_Group).canView;
const canViewReaderControlGroups = User.getComponentPermission(user, SecuredComponents.Reader_Control_Group).canView;
const canViewHolidays = User.getComponentPermission(user, SecuredComponents.Holiday).canView;
const canViewTimeZones =
	User.getComponentPermission(user, SecuredComponents.Master_Time_Zone).canView ||
	User.getComponentPermission(user, SecuredComponents.Standard_Time_Zone).canView ||
	User.getComponentPermission(user, SecuredComponents.Grand_Master_Time_Zone).canView;

const velocityConfigurationChildren: DeviceControlChildrenTreeDataType[] = [];

if (canViewCommandSets) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('CommandSets'), 'velocityConfigurationCommandSets'),
		key: '0-0-0',
		deviceObjectType: DeviceObjectType.CommandSets,
	});
}

if (canViewCredentialTemplates) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('CredentialTemplates'), 'velocityConfigurationCredentialTemplates'),
		key: '0-0-1',
		deviceObjectType: DeviceObjectType.CredentialTemplates,
	});
}

if (canViewDoorGroups) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('DoorGroups'), 'velocityConfigurationDoorGroup'),
		key: '0-0-2',
		deviceObjectType: DeviceObjectType.DoorGroup,
	});
}

if (canViewNetworkGIO) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('NetworkGlobalIOConfiguration'), 'velocityConfigurationNetworkGlobalIO'),
		key: '0-0-3',
		deviceObjectType: DeviceObjectType.GlobalIOGroup,
	});
}

if (canViewReaderControlGroups) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('ReaderControlGroups'), 'velocityConfigurationReaderControlGroup'),
		key: '0-0-4',
		deviceObjectType: DeviceObjectType.ReaderControlGroup,
	});
}

if (canViewHolidays) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('Holidays'), 'velocityConfigurationHoliday'),
		key: '0-0-5',
		deviceObjectType: DeviceObjectType.Holidays,
	});
}

if (canViewTimeZones) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('TimeZones'), 'velocityConfigurationTimeZones'),
		key: '0-0-6',
		deviceObjectType: DeviceObjectType.TimeZones,
	});
}

if (isAdmin || isConnectionSecure()) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('Operators'), 'velocityConfigurationOperators'),
		key: '0-0-7',
		deviceObjectType: DeviceObjectType.Operators,
	});
}

if (isAdmin) {
	velocityConfigurationChildren.push({
		title: getTitleComponent(_('Roles'), 'velocityConfigurationRoles'),
		key: '0-0-8',
		deviceObjectType: DeviceObjectType.Roles,
	});
}
//#endregion

//#region Digitrack Configuration children
const canViewPorts = User.getComponentPermission(user, SecuredComponents.Ports).canView;
const canViewXboxes = User.getComponentPermission(user, SecuredComponents.XBox).canView;
const canViewControllers = User.getComponentPermission(user, SecuredComponents.Controller).canView;
const canViewDoors = User.getComponentPermission(user, SecuredComponents.Door).canView;
const canViewExpInputs = User.getComponentPermission(user, SecuredComponents.Expansion_Input).canView;
const canViewExpRelays = User.getComponentPermission(user, SecuredComponents.Expansion_Relay).canView;
const canViewReaders = User.getComponentPermission(user, SecuredComponents.Reader).canView;
const canViewInputs = User.getComponentPermission(user, SecuredComponents.Input).canView;
const canViewRelays = User.getComponentPermission(user, SecuredComponents.Relay).canView;

const digiTrackConfigurationChildren: DeviceControlChildrenTreeDataType[] = [];
if (canViewPorts) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Ports'), 'digiTracConfigurationPort'),
		key: '0-1-0',
		deviceObjectType: DeviceObjectType.Port,
	});
}

if (canViewXboxes) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Xboxes'), 'digiTracConfigurationXbox'),
		key: '0-1-1',
		deviceObjectType: DeviceObjectType.Xbox,
	});
}

if (canViewControllers) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Controllers'), 'digiTracConfigurationControllers'),
		key: '0-1-2',
		deviceObjectType: DeviceObjectType.Controller,
	});
}

if (canViewDoors) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Doors'), 'digiTracConfigurationDoors'),
		key: '0-1-3',
		deviceObjectType: DeviceObjectType.Door,
	});
}

if (canViewExpInputs) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('ExpansionInputs'), 'digiTracConfigurationExpansionInputs'),
		key: '0-1-4',
		deviceObjectType: DeviceObjectType.ExpansionInputs,
	});
}

if (canViewExpRelays) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('ExpansionRelays'), 'digiTracConfigurationExpansionRelays'),
		key: '0-1-5',
		deviceObjectType: DeviceObjectType.ExpansionRelays,
	});
}

if (canViewReaders) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Readers'), 'digiTracConfigurationReaders'),
		key: '0-1-6',
		deviceObjectType: DeviceObjectType.Reader,
	});
}

if (canViewInputs) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Inputs'), 'digiTracConfigurationInputs'),
		key: '0-1-7',
		deviceObjectType: DeviceObjectType.Input,
	});
}

if (canViewRelays) {
	digiTrackConfigurationChildren.push({
		title: getTitleComponent(_('Relays'), 'digiTracConfigurationRelays'),
		key: '0-1-8',
		deviceObjectType: DeviceObjectType.Relay,
	});
}
//#endregion

let treeData: DeviceControlTreeDataType[] = [];
if (velocityConfigurationChildren?.length > 0) {
	treeData.push({
		title: getTitleComponent(_('VelocityConfiguration'), 'velocityConfiguration'),
		key: '0-0',
		deviceType: DeviceType.Velocity,
		children: velocityConfigurationChildren,
	});
}

if (digiTrackConfigurationChildren?.length > 0) {
	treeData.push({
		title: getTitleComponent(_('DigiTracConfiguration'), 'digiTracConfiguration'),
		key: '0-1',
		deviceType: DeviceType.DigiTrac,
		children: digiTrackConfigurationChildren,
	});
}

const paginationSetting: PaginationSetting = {
	PageNumber: 1,
	PageSize: getDefaultTablePaginationConfig()?.defaultPageSize,
	SortDirection: SortDirections.Ascend,
	SortField: '',
	SearchedValue: '',
};

class Page extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		const firstChild = velocityConfigurationChildren?.length > 0 ? velocityConfigurationChildren[0] : digiTrackConfigurationChildren[0];
		const expandedKeys: string[] = [];
		const devType = velocityConfigurationChildren?.length > 0 ? DeviceType.Velocity : DeviceType.DigiTrac;
		if (treeData?.length > 1) {
			expandedKeys.push(treeData[1].key);
		}
		if (velocityConfigurationChildren?.length > 0) {
			expandedKeys.push(velocityConfigurationChildren[0].key);
		} else {
			expandedKeys.push(digiTrackConfigurationChildren[0].key);
		}

		this.state = {
			expandedKeys,
			currentDeviceObject: firstChild.deviceObjectType,
			currentDevice: devType,
			propKey: 1,
			selectedKeys: [firstChild.key],
		};
	}

	private handleExpand = (selectedKeys, { node }) => {
		const { onChangeDevice } = this.props;
		// find nodes for current selection, only one child can be selected and always one must be selected
		const findNodeParent = selectedKeys.filter(x => x.length === 3);
		const findNodeChild = selectedKeys.filter(x => x.length === 5);
		const currentChild = node.key;
		//check if there is a child selected once open another parent group
		const lastChild = findNodeChild[findNodeChild.length - 1] ?? currentChild;
		const expandedKeys = [...findNodeParent, lastChild];
		let deviceObjectType: DeviceObjectType;
		let deviceType: DeviceType;
		treeData.forEach(x => {
			const deviceObj = x.children.find(w => w.key === lastChild);
			if (deviceObj) {
				deviceObjectType = deviceObj.deviceObjectType;
				deviceType = x.deviceType;
			}
		});

		this.setState({ expandedKeys, currentDeviceObject: deviceObjectType, currentDevice: deviceType });
		const currentDevice: CurrentDeviceControlObj = {
			Id: 0,
			DeviceObjectType: deviceObjectType,
			IsModalOpen: false,
		};

		onChangeDevice(currentDevice);
	};

	private handleSelectNode = (selectedKeys: React.Key[], { node }) => {
		const infoNode = node as EventDataNode<DeviceControlTreeDataType>;
		const currentNodeParentKey = selectedKeys[0].toString().substring(0, 3);
		if (selectedKeys[0].toString().length === 3) {
			return;
		}

		this.setState({ selectedKeys, propKey: new Date().getTime() });

		const currentDevice = treeData.find(x => x.key === currentNodeParentKey).children.find(x => x.key === infoNode.key).deviceObjectType;

		const {
			setCommandsSetsBy,
			setDoorGroupsBy,
			setStandardTimeZonesBy,
			setReaderControlGroupBy,
			setMasterTimeZonesBy,
			setGrandMasterTimeZonesBy,
			setContactsControllers,
			setCredentialTemplatesBy,
			setHolidaysBy,
			setOperatorsBy,
			setRolesBy,
			setGlobalIOGroupsBy,
			setAllItemsPagination,
			setDefaultTablePaginationSetting,
			setDigitracFilterMode,
			currentTimeZone,
		} = this.props;

		setDefaultTablePaginationSetting();

		let objectType: ObjectTypes = ObjectTypes.none;
		switch (currentDevice) {
			case DeviceObjectType.CommandSets:
				setCommandsSetsBy();
				break;

			case DeviceObjectType.CredentialTemplates:
				setCredentialTemplatesBy();
				break;

			case DeviceObjectType.DoorGroup:
				setDoorGroupsBy();
				break;

			case DeviceObjectType.ReaderControlGroup:
				setReaderControlGroupBy();
				break;

			case DeviceObjectType.TimeZones:
				if (currentTimeZone === TimeZoneType.Grand) {
					setGrandMasterTimeZonesBy({ ...paginationSetting, SearchedValue: this.props.itemSearchedPagination });
					objectType = ObjectTypes.grandMasterTimeZones;
				} else if (currentTimeZone === TimeZoneType.Master) {
					setMasterTimeZonesBy({ ...paginationSetting, SearchedValue: this.props.itemSearchedPagination });
					objectType = ObjectTypes.masterTimeZones;
				} else {
					setStandardTimeZonesBy({ ...paginationSetting, SearchedValue: this.props.itemSearchedPagination });
					objectType = ObjectTypes.timeZones;
				}
				setAllItemsPagination(currentDevice, objectType);
				break;

			case DeviceObjectType.Holidays:
				setHolidaysBy();
				break;

			case DeviceObjectType.Operators:
				setOperatorsBy();
				break;

			case DeviceObjectType.Roles:
				setRolesBy();
				break;

			case DeviceObjectType.GlobalIOGroup:
				setGlobalIOGroupsBy();
				break;

			default:
				setContactsControllers(currentDevice);
				setDigitracFilterMode(false);
		}
	};

	componentDidMount() {
		//set initial device data load
		const node = velocityConfigurationChildren?.length > 0 ? velocityConfigurationChildren[0] : digiTrackConfigurationChildren[0];
		this.handleSelectNode(this.state.selectedKeys, { node: node });
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	componentDidUpdate(prevProps, prevState) {
		const { currentDevice, tablePaginationSetting, setContactsControllers } = this.props;

		if (prevProps.currentDevice !== currentDevice) {
			if (currentDevice.DeviceObjectType === DeviceObjectType.Controller) {
				this.setState({ selectedKeys: ['0-1-2'], currentDeviceObject: DeviceObjectType.Controller, currentDevice: DeviceType.DigiTrac });
				setContactsControllers(DeviceObjectType.Controller, tablePaginationSetting);
			}
		}
	}

	render() {
		const { expandedKeys, currentDeviceObject, currentDevice, propKey, selectedKeys } = this.state;
		const { DirectoryTree } = Tree;

		const renderSwitch = () => {
			switch (currentDevice) {
				case DeviceType.Velocity:
					return <VelocityConfiguration deviceObjectType={currentDeviceObject} propKey={propKey} />;

				case DeviceType.DigiTrac:
					return <DigiTrac deviceObjectType={currentDeviceObject} />;
			}
		};

		const isController = new URLSearchParams(location.search).get('controllerId');

		return (
			<>
				{!isController && (
					<div id='deviceControlContainer' className={styles.deviceHeaderBar}>
						<HeaderBar title={_('DeviceControl')} />
					</div>
				)}
				<div className={cx(styles.container, { [styles.grayContent]: !isController, [styles.whiteContent]: isController })}>
					{isController ? (
						<ControllerModal
							currentDevice={{
								DeviceObjectType: DeviceObjectType.Controller,
								Id: Number(isController),
							}}
							navigationParentTitle={_('DeviceControl')}
							navigationChildTitle={_('Controllers')}
						/>
					) : (
						<>
							<div>
								<DirectoryTree
									disabled={this.props.isEditMode}
									onExpand={!this.props.isEditMode ? this.handleExpand : () => null}
									treeData={treeData}
									expandedKeys={expandedKeys}
									selectedKeys={selectedKeys}
									onSelect={this.handleSelectNode}
									defaultSelectedKeys={selectedKeys}
									data-id={'deviceControlTree'}
								/>
							</div>
							<div>{renderSwitch()}</div>
						</>
					)}
				</div>
				{showEventsOnDeviceAdmPage && <EventTable />}
			</>
		);
	}
}

//TODO: FIX THIS
const mapStateToProps = (states: RootReducer, ownProps: OwnProps): StateProps => {
	return {
		currentDevice: states.commonReducer.currentDevice,
		isEditMode: states.deviceControlReducer.digiTrac.isEditMode || states.deviceControlReducer.velocityConfiguration.isEditMode,
		currentTimeZone: states.commonReducer.selectedTimeZone,
		itemSearchedPagination: states.commonReducer.itemSearchedPagination,
		tablePaginationSetting: states.deviceControlReducer.tablePaginationSetting,
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, StoreEvent>, ownProps: OwnProps): DispatchProps => {
	return {
		onChangeDevice: async (deviceType: CurrentDeviceControlObj) => {
			await dispatch(setCurrentDevice(deviceType));
		},
		setCommandsSetsBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setCommandsSetsBy(paginationSetting));
		},
		setContactsControllers: async (type: DeviceObjectType, paginationSetting?: PaginationSetting) => {
			await dispatch(setControllerContacts(type, paginationSetting));
		},
		setCredentialTemplatesBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setCredentialTemplatesBy(paginationSetting));
		},
		setControllerContactsByData: async (type: DeviceObjectType, data: ControllerContact[]) => {
			await dispatch(setControllerContactsByData(type, data));
		},
		setDoorGroupsBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setDoorGroupsBy(paginationSetting));
		},
		setGrandMasterTimeZonesBy: async (paginationSetting: PaginationSetting) => {
			await dispatch(setGrandTimeZonesBy(paginationSetting));
		},
		setHolidaysBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setHolidaysBy(paginationSetting));
		},
		setOperatorsBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setOperatorsBy(paginationSetting));
		},
		setRolesBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setRolesBy(paginationSetting));
		},
		setMasterTimeZonesBy: async (paginationSetting: PaginationSetting) => {
			await dispatch(setMasterTimeZonesBy(paginationSetting));
		},
		setReaderControlGroupBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setReaderControlGroupsBy(paginationSetting));
		},
		setStandardTimeZonesBy: async (paginationSetting: PaginationSetting) => {
			await dispatch(setStandardTimeZonesBy(paginationSetting));
		},
		setGlobalIOGroupsBy: async (paginationSetting?: PaginationSetting) => {
			await dispatch(setGlobalIOGroupsBy(paginationSetting));
		},
		setAllItemsPagination: async (deviceObjectType, objectType) => {
			await dispatch(setAllItemsPagination(deviceObjectType, objectType));
		},
		setDefaultTablePaginationSetting: async () => {
			await dispatch(setDefaultTablePaginationSetting());
		},
		setDigitracFilterMode: async (isFilterMode: boolean) => {
			await dispatch(setDigiTracFilterMode(isFilterMode));
		},
	};
};

const DeviceControlPage = connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(Page);

export { DeviceControlPage };

import { MinusSquareOutlined, PlusSquareOutlined, ReadOutlined, SettingFilled, SnippetsOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { ColumnsProps, buildColumn, getColumnWidth, handleResponse, parseDateTimeString } from '../../../Helper';
import { alarmApi } from '../../../api';
import constants from '../../../constants';
import { SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../../model/AccountModel';
import {
	AcknowledgedAndClearAlarmsPayload,
	AlarmAcknowledgementActions,
	AlarmActionOptions,
	AlarmDetail,
	AlarmListResponseObject,
	AlarmModel,
	AlarmNote,
	AlarmNoteForAcknowledge,
} from '../../../model/AlarmModel';
import { CheckboxMenuOptions, GridDataColumnItem, ResponseObjectEntity, ResponseStatusCode } from '../../../model/CommonModel';
import { ColumnConfiguration as ColumnConfigurationModel } from '../../../model/ConfigurationModel';
import { Logger } from '../../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import {
	setAlarmNoteForAcknowledge,
	setOperatorNotesAdded,
	setShowAlarmAddOperatorNote,
	setShowAlarmInstructions,
	setShowAlarmNotes,
} from '../../../store/alarm/actions';
import { selectOperatorNotesAdded } from '../../../store/alarm/selectors';
import {
	selectAckAlarmList,
	selectAlarmList,
	selectClearedAlarmList,
	selectTotalAcknowledgedAlarms,
	selectTotalActiveAlarms,
} from '../../../store/common/selectors';
import { CheckboxMenu, ColumnConfiguration, HeaderBar } from '../../common';
import { AlarmActionDropdown } from '../AlarmActionDropdown/AlarmActionDropdown';
import { AlarmExpandColumn } from '../VirtualTableExpandableRow/ExpandableRow';
import { VirtualTableExpandableRow } from '../VirtualTableExpandableRow/VirtualTableExpandableRow';
import {
	AcknowledgedAlarmTableColumns,
	AddDataPayload,
	AlarmActionColumnData,
	AlarmTableColumns,
	AlarmTableDataSourceModel,
	acknowledgeAndClearAlarmCommon,
	acknowledgedAndClearAlarms,
	addNotes,
	createTableData,
	getAlarmCount,
	getAlarmNoteAddOperatorNoteModal,
	getAlarmTextColor,
	getCurrentPage,
	getMultipleAlarmAction,
	instructionsStyle,
	noIconStyle,
	notesStyle,
	selectAllAlarms,
	updateTotalAlarmPages,
} from '../helpers';
import styles from './alarmTable.module.scss';

const user: User = getUser();
const { alarmsPerPage, alwaysShowInstructions, alarmStackingEnabled, queueSortDir } = user;
const canChangeColumnConfiguration: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmViewer_ChangeProperties).allowed;

type Props = {
	isAcknowledgedAlarmTable: boolean;
};

const component: React.FC<Props> = ({ isAcknowledgedAlarmTable }) => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tableColumns, setTableColumns] = useState<ColumnsProps<AlarmTableDataSourceModel>[]>([]);
	const [dataSource, setDataSource] = useState<AlarmTableDataSourceModel[]>([]);
	const [selectedAlarmKeys, setSelectedAlarmKeys] = useState<React.Key[]>([]);
	const [totalAlarms, setTotalAlarms] = useState<number>(-1);
	const [showAlarmConfigureColumns, setShowAlarmConfigureColumns] = useState<boolean>(false);
	const [totalAlarmPages, setTotalAlarmPages] = useState<number>(0);

	const checkboxMenuChecked = useRef<boolean>(false);
	const dataSourceReference = useRef<AlarmTableDataSourceModel[]>(dataSource);
	const setDataSourceReference = (newDataSourceReference: AlarmTableDataSourceModel[]): void => {
		dataSourceReference.current = newDataSourceReference;
		setDataSource(newDataSourceReference);
	};
	const getDataSourceReference = (): AlarmTableDataSourceModel[] => {
		return dataSourceReference.current;
	};

	const selectedAlarmKeysReference = useRef<React.Key[]>(selectedAlarmKeys);
	const setSelectedAlarmKeysReference = (newSelectedAlarmKeysReference: React.Key[]): void => {
		selectedAlarmKeysReference.current = newSelectedAlarmKeysReference;
		setSelectedAlarmKeys(newSelectedAlarmKeysReference);
	};
	const getSelectedAlarmKeysReference = (): React.Key[] => {
		return selectedAlarmKeysReference.current;
	};

	const realTimeAlarmList: AlarmModel[] = isAcknowledgedAlarmTable ? [] : useStoreSelector<AlarmModel[]>(selectAlarmList);
	const realTimeAckAlarmList: AlarmModel[] = useStoreSelector<AlarmModel[]>(selectAckAlarmList);
	const realTimeClearedAlarmList: AlarmModel[] = useStoreSelector<AlarmModel[]>(selectClearedAlarmList);
	const operatorNotesAdded: AlarmNote[] = useStoreSelector(selectOperatorNotesAdded);
	const totalActiveAlarms: number = useStoreSelector<number>(isAcknowledgedAlarmTable ? selectTotalAcknowledgedAlarms : selectTotalActiveAlarms);

	const sessionStorageFetchKey: string = isAcknowledgedAlarmTable
		? constants.sessionStorage.alarmViewer.FETCH_ACK_TABLE
		: constants.sessionStorage.alarmViewer.FETCH_ACTIVE_TABLE;

	const sessionStorageLiveEventKey: string = isAcknowledgedAlarmTable
		? constants.sessionStorage.alarmViewer.LIVE_ACK_TABLE
		: constants.sessionStorage.alarmViewer.LIVE_ACTIVE_TABLE;

	const prefix: string = isAcknowledgedAlarmTable ? 'Ack' : 'Alarm';
	const tableColumnsWidth: Record<keyof any, string> = isAcknowledgedAlarmTable
		? ({
				CollapseIcon: '30px',
				Action: '6em',
				Icon: '3em',
				HardwareTime: '13.2em',
				Count: '4em',
				HostTime: '13.2em',
				AcknowledgedTime: '13.2em',
				Workstation: '10em',
				Description: '45em',
				Address: '15em',
				AcknowledgedBy: '9em',
				Level: '4em',
				ActiveAlarmId: '5em',
		  } as Record<keyof AcknowledgedAlarmTableColumns, string>)
		: ({
				CollapseIcon: '30px',
				Action: '6em',
				Icon: '3em',
				HardwareTime: '13.2em',
				Count: '4em',
				HostTime: '13.2em',
				Description: '45em',
				Address: '15em',
				Level: '4em',
				ActiveAlarmId: '5em',
		  } as Record<keyof AlarmTableColumns, string>);

	const getDependencies = (): React.DependencyList => {
		if (isAcknowledgedAlarmTable) {
			return [realTimeAckAlarmList, realTimeClearedAlarmList];
		}

		return [realTimeAlarmList, realTimeAckAlarmList, realTimeClearedAlarmList];
	};

	useEffect(() => {
		//We use this flag to avoid multiple api calls at the same time
		window.sessionStorage.setItem(sessionStorageLiveEventKey, '0');
		window.sessionStorage.setItem(sessionStorageFetchKey, '0');

		if (alarmsPerPage) {
			initTable();
		}
	}, []);

	useEffect(() => {
		if (operatorNotesAdded && operatorNotesAdded.length) {
			setDataSourceReference(addNotes(getDataSourceReference(), operatorNotesAdded));
			dispatch(setOperatorNotesAdded([]));
		}
	}, [operatorNotesAdded]);

	useEffect(() => {
		if (realTimeAlarmList.length || realTimeAckAlarmList.length || realTimeClearedAlarmList.length) {
			batch(() => {
				window.sessionStorage.setItem(sessionStorageLiveEventKey, '1');
				const actualDataSource = getDataSourceReference();
				const dataSourceCount: number = getAlarmCount(actualDataSource);
				const removeItems: AlarmModel[] = isAcknowledgedAlarmTable
					? [...realTimeClearedAlarmList]
					: [...realTimeAckAlarmList, ...realTimeClearedAlarmList];
				const addedItems: AlarmModel[] = isAcknowledgedAlarmTable ? [...realTimeAckAlarmList] : [...realTimeAlarmList];

				let newDataSource: AlarmTableDataSourceModel[] = removeAlarmFromTable(removeItems, actualDataSource);
				if (addedItems.length) {
					newDataSource = addData({ source: newDataSource, newAlarms: addedItems.filter(x => x !== undefined && x !== null), isRealTimeAlarm: true });
				}

				const newDataSourceCount: number = getAlarmCount(newDataSource);
				if (newDataSourceCount !== dataSourceCount) {
					const newPage = getCurrentPage(newDataSourceCount, alarmsPerPage);
					const currentPage = newPage === 0 ? 1 : newPage;
					const totalPages = updateTotalAlarmPages(currentPage, totalAlarmPages);
					setTotalAlarmPages(totalPages);
					removeSelectedKeyAlarm(removeItems, getSelectedAlarmKeysReference());
					setTotalAlarms(newDataSourceCount);
					setDataSourceReference(createTableData(newDataSource));
					if (newDataSourceCount === 0) {
						window.sessionStorage.setItem(sessionStorageLiveEventKey, '0');
						loadFirstPage();
					}
				}
				window.sessionStorage.setItem(sessionStorageLiveEventKey, '0');
			});
		}
	}, getDependencies());

	const removeAlarmFromTable = (alarmsToRemove: AlarmModel[], sourceTarget: AlarmTableDataSourceModel[]): AlarmTableDataSourceModel[] => {
		let clonedDataSource: AlarmTableDataSourceModel[] = [...sourceTarget];
		let isInHeaderDetails = false;

		alarmsToRemove.forEach(alarmModelToRemove => {
			alarmModelToRemove.AlarmDetails.forEach(alarmToRemove => {
				let fatherHeaderAlarmDetails: AlarmDetail[] = [];
				clonedDataSource.forEach((alarmDataSource, index, alarmDataSources) => {
					if (
						alarmDataSource.HeaderAlarmDetails &&
						alarmDataSource.HeaderAlarmDetails.find(alarm => alarm.ActiveAlarmId === alarmToRemove.ActiveAlarmId)
					) {
						alarmDataSource.HeaderAlarmDetails = alarmDataSource.HeaderAlarmDetails.filter(x => x.ActiveAlarmId !== alarmToRemove.ActiveAlarmId);
						alarmDataSource.HeaderCount = alarmDataSource.HeaderAlarmDetails.length;
						fatherHeaderAlarmDetails = alarmDataSource.HeaderAlarmDetails;
						if (alarmDataSource.HeaderAlarmDetails.length <= 1) {
							alarmDataSource = { ...alarmDataSource, HeaderAlarmDetails: [], IsExpanded: false, ExpandColumn: AlarmExpandColumn.None };
						}
						isInHeaderDetails = true;
						alarmDataSources[index] = alarmDataSource;
					}

					if (alarmDataSource.AlarmDetails.find(alarm => alarm.ActiveAlarmId === alarmToRemove.ActiveAlarmId)) {
						const newAlarmDetails = alarmDataSource.AlarmDetails.filter(x => x.ActiveAlarmId !== alarmToRemove.ActiveAlarmId);
						if (newAlarmDetails.length === 0) {
							if (isInHeaderDetails && alarmDataSource.HeaderAlarmDetails.length > 0) {
								const nextIndex = index + 1;
								if (alarmDataSources.length > nextIndex + 1) {
									if (alarmDataSource.HeaderAlarmDetails.length > 1) {
										alarmDataSources[nextIndex].HeaderAlarmDetails = alarmDataSource.HeaderAlarmDetails;
									}
									alarmDataSources[nextIndex].IsExpanded = alarmDataSource.IsExpanded;
									alarmDataSources[nextIndex].ExpandColumn = alarmDataSource.ExpandColumn;
								}
							}
							alarmDataSources.splice(index, 1);
						} else {
							alarmDataSource.AlarmDetails = [...newAlarmDetails];
							alarmDataSources[index] = { ...alarmDataSource, IsExpanded: false, ExpandColumn: AlarmExpandColumn.None };
						}
					}

					if (
						alarmDataSource.hasOwnProperty('HeaderAlarmDetails') &&
						alarmDataSource.HeaderAlarmDetails?.length === 0 &&
						fatherHeaderAlarmDetails.length > 0 &&
						alarmDataSource.AlarmDetails &&
						alarmDataSource.AlarmDetails.length > 0
					) {
						const alarmIndex = fatherHeaderAlarmDetails.findIndex(x => x.ActiveAlarmId === alarmDataSource.AlarmDetails[0].ActiveAlarmId);
						if (
							(fatherHeaderAlarmDetails.length > 0 && alarmIndex === fatherHeaderAlarmDetails.length - 1) ||
							fatherHeaderAlarmDetails.length === 1
						) {
							alarmDataSources[index].ExpandColumn =
								fatherHeaderAlarmDetails.length === 1 ? AlarmExpandColumn.None : AlarmExpandColumn.LastExpandedRow;
							fatherHeaderAlarmDetails = [];
						}
					}
					isInHeaderDetails = false;
				});
			});
		});

		return clonedDataSource;
	};

	const removeSelectedKeyAlarm = (alarms: AlarmModel[], selectedAlarmKeys: React.Key[]) => {
		const alarmIds = selectAllAlarms(alarms);
		const newSelectedKeys: React.Key[] = selectedAlarmKeys.filter(key => !alarmIds.includes(key.toString()));
		setSelectedAlarmKeysReference(newSelectedKeys);
	};

	const loadFirstPage = () => {
		fetchData(1);
	};

	const toggleItemActive = (itemIndex: number) => {
		const prevState = getDataSourceReference();
		let newExpandedValue = false;
		let newAlarm: AlarmTableDataSourceModel;
		let result = prevState.map((alarm, index) => {
			if (index === itemIndex) {
				newExpandedValue = !alarm.IsExpanded;
				newAlarm = { ...alarm, IsExpanded: !alarm.IsExpanded };
				if (alarm.IsExpanded) {
					let alarmDetails: AlarmDetail[] = prevState.filter(x => isSameAlarmToStack(x, alarm)).map(x => x.AlarmDetails[0]);
					newAlarm.AlarmDetails.push(...alarmDetails);
					newAlarm.AlarmDetails = getUniqueAlarmRows(newAlarm.AlarmDetails);
					newAlarm.HeaderAlarmDetails = [];
				}

				return newAlarm;
			}
			return alarm;
		});

		if (!newExpandedValue && newAlarm) {
			result = result.filter((x, index) => {
				const isSameAlarmRow: boolean = isSameAlarmToStack(x, newAlarm);
				return x.IsExpanded || !isSameAlarmRow || (isSameAlarmRow && index === itemIndex);
			});
		} else {
			result = getExpandedRows(result);
		}
		setDataSourceReference(result);
	};

	const setSelectedAlarm = (alarmId: string, checked: boolean) => {
		const prevState = getSelectedAlarmKeysReference();
		let alarmKeys: React.Key[] = [...prevState];
		if (checked) {
			if (!prevState.includes(alarmId)) {
				alarmKeys = [...prevState, alarmId];
			}
		} else {
			alarmKeys = prevState.filter(key => key !== alarmId);
		}

		setSelectedAlarmKeysReference(alarmKeys);
	};

	const handleOnChangeAlarmCheckbox = (event: CheckboxChangeEvent, alarm: AlarmActionColumnData) => {
		const { checked } = event.target;
		const { ActiveAlarmId, IsExpandableRowHeader, IsExpanded } = alarm;

		const prevState = getDataSourceReference();
		setDataSourceReference(
			prevState.map(alarm => {
				if (alarm.HeaderAlarmDetails && alarm.HeaderAlarmDetails.length > 0) {
					const hasAlarmId = alarm.HeaderAlarmDetails.some(x => x.ActiveAlarmId === ActiveAlarmId);
					if (hasAlarmId) {
						const alarmHeaderDetails = alarm.HeaderAlarmDetails.map(alarmHeaderDetail => {
							if (IsExpandableRowHeader && !IsExpanded) {
								setSelectedAlarm(alarmHeaderDetail.ActiveAlarmId.toString(), checked);
								return {
									...alarmHeaderDetail,
									IsChecked: checked,
								};
							} else if (alarmHeaderDetail.ActiveAlarmId === ActiveAlarmId) {
								setSelectedAlarm(alarmHeaderDetail.ActiveAlarmId.toString(), checked);
								return {
									...alarmHeaderDetail,
									IsChecked: checked,
								};
							}
							return alarmHeaderDetail;
						});
						alarm.HeaderAlarmDetails = [...alarmHeaderDetails];
					}
				}

				const hasAlarmId = alarm.AlarmDetails.some(x => x.ActiveAlarmId === ActiveAlarmId);
				if (hasAlarmId) {
					const alarmDetails = alarm.AlarmDetails.map(alarmDetail => {
						if (IsExpandableRowHeader && !IsExpanded) {
							setSelectedAlarm(alarmDetail.ActiveAlarmId.toString(), checked);
							return {
								...alarmDetail,
								IsChecked: checked,
							};
						} else if (alarmDetail.ActiveAlarmId === ActiveAlarmId) {
							setSelectedAlarm(alarmDetail.ActiveAlarmId.toString(), checked);
							return {
								...alarmDetail,
								IsChecked: checked,
							};
						}
						return alarmDetail;
					});
					return { ...alarm, AlarmDetails: [...alarmDetails] };
				}
				return alarm;
			})
		);
	};

	const getIndeterminateHeaderCheckboxMenu = (alarm: AlarmActionColumnData): boolean => {
		const { dataSource } = alarm;

		const quantityOfAlarms = dataSource.reduce((quantity, alarm) => alarm.AlarmDetails.length + quantity, 0);
		const quantityOfCheckedAlarms = dataSource.reduce((quantity, alarm) => {
			return quantity + alarm.AlarmDetails.filter(x => x.IsChecked).length;
		}, 0);

		if (!quantityOfCheckedAlarms) {
			checkboxMenuChecked.current = false;
		} else {
			checkboxMenuChecked.current = true;
		}

		return quantityOfAlarms !== quantityOfCheckedAlarms && quantityOfCheckedAlarms >= 1;
	};

	const getIndeterminateRowCheckbox = (alarm: AlarmActionColumnData): boolean => {
		const { IsExpandableRowHeader, AlarmDetails, IsExpanded } = alarm;

		if (!IsExpandableRowHeader || (IsExpandableRowHeader && IsExpanded)) {
			return false;
		}

		const quantityOfAlarms = AlarmDetails.length;
		const quantityOfCheckedAlarms = AlarmDetails.filter(alarm => alarm.IsChecked).length;

		return quantityOfAlarms !== quantityOfCheckedAlarms && quantityOfCheckedAlarms >= 1;
	};

	const handleOnChangeCheckboxMenu = (checked: boolean) => {
		checkboxMenuChecked.current = checked;
		const prevState = getDataSourceReference();
		if (checked) {
			selectStateAllAlarms(prevState);
		} else {
			setSelectedAlarmKeysReference([]);
		}
		setDataSourceReference(
			prevState.map(alarm => {
				if (alarm.HeaderAlarmDetails && alarm.HeaderAlarmDetails.length > 0) {
					alarm.HeaderAlarmDetails = alarm.HeaderAlarmDetails.map(alarmHeaderDetail => {
						return {
							...alarmHeaderDetail,
							IsChecked: checked,
						};
					});
				}

				const alarmDetails = alarm.AlarmDetails.map(alarmDetail => {
					return {
						...alarmDetail,
						IsChecked: checked,
					};
				});
				return { ...alarm, AlarmDetails: [...alarmDetails] };
			})
		);
	};

	const selectStateAllAlarms = (dataSource: AlarmTableDataSourceModel[]) => {
		const newKeys = selectAllAlarms(dataSource);
		setSelectedAlarmKeysReference([...newKeys]);
	};

	const handleCheckboxMenuAction = (option: CheckboxMenuOptions) => {
		switch (option) {
			case CheckboxMenuOptions.SELECT_ALL:
				handleOnChangeCheckboxMenu(true);
				break;
			case CheckboxMenuOptions.CLEAR_ALL:
				handleOnChangeCheckboxMenu(false);
				break;
		}
	};

	const getRowAction = (alarm: AlarmActionColumnData, alarmModel: AlarmTableDataSourceModel): React.ReactNode => {
		const alarmNotes: AlarmNote[] = alarm.AlarmNotes;
		const isIndeterminate: boolean = getIndeterminateRowCheckbox(alarm);
		const extraProps = isIndeterminate ? { indeterminate: isIndeterminate } : {};
		const firstAlarmDetail: AlarmDetail = alarmModel.AlarmDetails?.length > 0 ? alarmModel.AlarmDetails[0] : undefined;
		const instructionsData: AlarmModel = alarmModel.HeaderAlarmDetails?.length > 0 ? { ...alarmModel, AlarmDetails: [firstAlarmDetail] } : alarmModel;
		const alarmNoteModel: AlarmDetail[] = alarmModel.AlarmDetails?.length > 0 ? [alarmModel.AlarmDetails[0]] : [];
		const checkboxId: string = `checkboxRowActionColumn${prefix}-${alarm.ActiveAlarmId}`;

		return (
			<div className={styles.rowActions}>
				<Checkbox
					id={checkboxId}
					aria-label={`checkboxRowActionColumn${prefix}`}
					checked={alarm.IsChecked}
					onChange={(e: CheckboxChangeEvent) => handleOnChangeAlarmCheckbox(e, alarm)}
					{...extraProps}>
					<label htmlFor={checkboxId} className={styles.srOnly}>{`${_('SelectItem')}`}</label>
				</Checkbox>
				{!alwaysShowInstructions && alarm.Instructions !== '' ? (
					<ReadOutlined style={instructionsStyle} onClick={() => dispatch(setShowAlarmInstructions(instructionsData))} />
				) : (
					<span style={noIconStyle} />
				)}
				{alarmNotes && alarmNotes.length > 0 ? (
					<SnippetsOutlined style={notesStyle} onClick={() => dispatch(setShowAlarmNotes({ ...alarmModel, AlarmDetails: alarmNoteModel }))} />
				) : (
					<span style={noIconStyle} />
				)}
			</div>
		);
	};

	const getHeaderAction = (alarm: AlarmActionColumnData): React.ReactNode => {
		const isIndeterminate = getIndeterminateHeaderCheckboxMenu(alarm);
		const extraProps = isIndeterminate ? { indeterminate: isIndeterminate } : {};

		return (
			<CheckboxMenu<CheckboxMenuOptions>
				id={`checkboxHeaderActionColumn${prefix}`}
				ariaLabel={`checkboxHeaderActionColumn${prefix}`}
				checked={checkboxMenuChecked.current}
				onChange={handleOnChangeCheckboxMenu}
				onClickOption={handleCheckboxMenuAction}
				{...extraProps}
			/>
		);
	};

	const createActionElement = (alarm: AlarmActionColumnData, row: AlarmTableDataSourceModel): React.ReactNode => {
		const { columnActionHeader } = alarm;
		return <>{columnActionHeader ? getHeaderAction(alarm) : getRowAction(alarm, row)}</>;
	};

	const isSameAlarmToStack = (alarmA: AlarmTableDataSourceModel, alarmB: AlarmTableDataSourceModel): boolean =>
		alarmA.Description === alarmB.Description && alarmA.Address === alarmB.Address && alarmA.EventId === alarmB.EventId;

	const getStackedAlarms = (clonedDataSource: AlarmTableDataSourceModel[], alarmsToStack: AlarmTableDataSourceModel[]): AlarmTableDataSourceModel[] => {
		alarmsToStack.forEach(alarm => {
			const stackedAlarmIndex: number[] = clonedDataSource.reduce((previousValue: number[], x: AlarmTableDataSourceModel, index: number) => {
				if (isSameAlarmToStack(x, alarm)) {
					previousValue.push(index);
				}

				return previousValue;
			}, []);

			if (stackedAlarmIndex.length === 1) {
				const alarmIndex: number = stackedAlarmIndex[0];
				if (alarmIndex >= 0) {
					clonedDataSource[alarmIndex].NeedsSorting = true;
					if (queueSortDir) {
						clonedDataSource[alarmIndex].AlarmDetails = [...alarm.AlarmDetails, ...clonedDataSource[alarmIndex].AlarmDetails];
					} else {
						clonedDataSource[alarmIndex].AlarmDetails = [...clonedDataSource[alarmIndex].AlarmDetails, ...alarm.AlarmDetails];
					}
				}
			} else if (stackedAlarmIndex.length > 1) {
				const firstIndex = stackedAlarmIndex.shift();
				if (clonedDataSource[firstIndex].HeaderAlarmDetails && clonedDataSource[firstIndex].HeaderAlarmDetails.length > 0) {
					clonedDataSource[firstIndex].NeedsSorting = true;
					if (queueSortDir) {
						clonedDataSource[firstIndex].HeaderAlarmDetails = [...alarm.AlarmDetails, ...clonedDataSource[firstIndex].HeaderAlarmDetails];
					} else {
						clonedDataSource[firstIndex].HeaderAlarmDetails = [...clonedDataSource[firstIndex].HeaderAlarmDetails, ...alarm.AlarmDetails];
					}
				}
			} else {
				alarm.NeedsSorting = true;
				clonedDataSource.push(alarm);
			}
		});

		return clonedDataSource;
	};

	const addData = ({ source, newAlarms, isRealTimeAlarm = false }: AddDataPayload): AlarmTableDataSourceModel[] => {
		let updatedAlarmList: AlarmTableDataSourceModel[] = [];
		const sourceKeys = selectAllAlarms(source);
		newAlarms.forEach((x: AlarmModel) => {
			let header: AlarmModel = undefined;
			x.AlarmDetails.forEach((y: AlarmDetail) => {
				if (!sourceKeys.includes(y.ActiveAlarmId.toString())) {
					if (header === undefined) {
						header = { ...x, AlarmDetails: [] };
					}

					header.AlarmDetails.push({ ...y });
				}
			});

			if (header !== undefined) {
				updatedAlarmList.push(header);
			}
		});

		if (updatedAlarmList.length === 0) {
			return source;
		}

		let updatedDataSource: AlarmTableDataSourceModel[] = [];
		if (alarmStackingEnabled) {
			// first we stacked the new alarms between each other to optimize the amount of loops
			let clonedDataSource: AlarmTableDataSourceModel[] = getStackedAlarms(source, updatedAlarmList);
			clonedDataSource.forEach(x => {
				if (x.NeedsSorting) {
					x.NeedsSorting = false;
					if (x.HeaderAlarmDetails && x.HeaderAlarmDetails.length > 0) {
						x.HeaderAlarmDetails = sortAlarmsByHostTime(getUniqueAlarmRows(x.HeaderAlarmDetails), queueSortDir);
						const [firstItem] = x.HeaderAlarmDetails;
						x.AlarmDetails = [{ ...firstItem }];
					} else {
						x.AlarmDetails = sortAlarmsByHostTime(getUniqueAlarmRows(x.AlarmDetails), queueSortDir);
					}
				}
			});
			updatedDataSource = clonedDataSource;
		} else {
			// !queueSortDir OLDEST TO MOST RECENT
			// queueSortDir MOST RECENT TO OLDEST
			if (queueSortDir) {
				updatedDataSource = [...updatedAlarmList, ...source];
			} else {
				updatedDataSource = [...source, ...updatedAlarmList];
			}
		}

		if (queueSortDir) {
			updatedDataSource.sort((a, b) => new Date(b.AlarmDetails[0].HostTime).getTime() - new Date(a.AlarmDetails[0].HostTime).getTime());
		} else {
			updatedDataSource.sort((a, b) => new Date(a.AlarmDetails[0].HostTime).getTime() - new Date(b.AlarmDetails[0].HostTime).getTime());
		}

		if (alarmStackingEnabled) {
			updatedDataSource = getExpandedRows(updatedDataSource);
		}

		return updatedDataSource;
	};

	const getExpandedRows = (dataSource: AlarmTableDataSourceModel[]): AlarmTableDataSourceModel[] => {
		let expandedRows: AlarmTableDataSourceModel[] = [];
		let clonedState: AlarmTableDataSourceModel[] = [...dataSource];

		dataSource.forEach((x, alarmIndex) => {
			if (x.IsExpanded) {
				clonedState = clonedState.filter((y, index) => {
					const isSameAlarmRow: boolean = isSameAlarmToStack(x, y);
					return y.IsExpanded || !isSameAlarmRow || (isSameAlarmRow && index === alarmIndex);
				});
			}
		});

		clonedState.forEach(x => {
			if (x.IsExpanded) {
				if (x.HeaderAlarmDetails && x.HeaderAlarmDetails.length > 0) {
					x.HeaderAlarmDetails.push(...x.AlarmDetails.filter(y => y.ActiveAlarmId !== x.AlarmDetails[0].ActiveAlarmId));
					let alarmsToAdd: AlarmDetail[] = x.HeaderAlarmDetails.length > 0 ? x.HeaderAlarmDetails : x.AlarmDetails;
					expandedRows.push(...addExpandedRow(x, x.HeaderAlarmDetails, alarmsToAdd));
				} else {
					let alarmsToAdd: AlarmDetail[] = x.HeaderAlarmDetails?.length > 0 ? x.HeaderAlarmDetails : x.AlarmDetails;
					expandedRows.push(...addExpandedRow(x, x.AlarmDetails, alarmsToAdd));
				}
			} else {
				expandedRows.push(x);
			}
		});

		const activeAlarmIds: number[] = [];
		expandedRows.forEach(
			x =>
				(x.AlarmDetails = x.AlarmDetails.filter(y => {
					if (!activeAlarmIds.includes(y.ActiveAlarmId)) {
						activeAlarmIds.push(y.ActiveAlarmId);
						return true;
					}
					return false;
				}))
		);
		expandedRows = expandedRows.filter(x => x.AlarmDetails.length > 0);

		return expandedRows;
	};

	const addExpandedRow = (
		alarmBase: AlarmTableDataSourceModel,
		alarmDetailsBaseArray: AlarmDetail[],
		alarmDetailsAddArray: AlarmDetail[]
	): AlarmTableDataSourceModel[] => {
		const expandedRows: AlarmTableDataSourceModel[] = [];
		alarmDetailsBaseArray.forEach((y, index) => {
			const isFirstRow: boolean = index === 0;

			expandedRows.push({
				...alarmBase,
				IsExpanded: isFirstRow,
				Count: isFirstRow ? alarmDetailsBaseArray.length : 1,
				HeaderCount: isFirstRow ? alarmDetailsAddArray.length : 1,
				HeaderAlarmDetails: isFirstRow ? alarmDetailsAddArray : [],
				AlarmDetails: [y],
				ExpandColumn: index === alarmDetailsBaseArray.length - 1 ? AlarmExpandColumn.LastExpandedRow : AlarmExpandColumn.InExpandedRow,
			});
		});

		return expandedRows;
	};

	const sortAlarmsByHostTime = (alarmsToSort: AlarmDetail[], sortAsc: boolean): AlarmDetail[] => {
		const sortedAlarms: AlarmDetail[] = [...alarmsToSort];
		if (sortAsc) {
			sortedAlarms.sort((a, b) => new Date(b.HostTime).getTime() - new Date(a.HostTime).getTime());
		} else {
			sortedAlarms.sort((a, b) => new Date(a.HostTime).getTime() - new Date(b.HostTime).getTime());
		}

		return sortedAlarms;
	};

	const getUniqueAlarmRows = (alarmRows: AlarmDetail[]): AlarmDetail[] =>
		alarmRows.filter(
			(alarmDetail: AlarmDetail, index: number, self: AlarmDetail[]) => index === self.findIndex(x => x.ActiveAlarmId === alarmDetail.ActiveAlarmId)
		);

	const fetchData = (pageNumber: number): void => {
		if (window.sessionStorage.getItem(sessionStorageLiveEventKey) === '1' || window.sessionStorage.getItem(sessionStorageFetchKey) === '1') {
			return;
		}
		window.sessionStorage.setItem(sessionStorageFetchKey, '1');
		setIsLoading(true);

		batch(() => {
			getStoredAlarms(pageNumber)
				.then(res => {
					const { alarmList, totalRecords } = res;
					if (totalRecords !== totalAlarms) {
						const updatedState: AlarmTableDataSourceModel[] = addData({
							source: getDataSourceReference(),
							newAlarms: alarmList.filter(x => x !== undefined && x !== null),
						});
						const newDataSourceCount: number = getAlarmCount(updatedState);
						const newPage = getCurrentPage(newDataSourceCount, alarmsPerPage);
						const currentPage = newPage === 0 ? 1 : newPage;
						const totalPages = updateTotalAlarmPages(currentPage, totalAlarmPages);
						setTotalAlarmPages(totalPages);
						setTotalAlarms(newDataSourceCount);
						setDataSourceReference(createTableData(updatedState));
					}
				})
				.catch(err => {
					Logger.writeErrorLog(err);
				})
				.finally(() => {
					setIsLoading(false);
					window.sessionStorage.setItem(sessionStorageFetchKey, '0');
				});
		});
	};

	const initTable = async (): Promise<void> => {
		getTableColumns().then(tableColumnsResponse => {
			if (tableColumnsResponse.ResponseStatusCode === ResponseStatusCode.Success) {
				const tableColumns = tableColumnsResponse.Entity;
				const columns = tableColumns
					.filter(f => !f.IsAuxColumn)
					.map<ColumnsProps<AlarmTableDataSourceModel>>((c: GridDataColumnItem) => {
						return {
							...buildColumn(c.Value, c.DataIndex, undefined, 'start'),
							key: c.Key,
							dataIndex: c.DataIndex,
							render: (value, row) => {
								let text = value;
								if (c.IsDateFormat) {
									text = parseDateTimeString(value, false, value, true);
								}

								return <span style={getAlarmTextColor(row.ReturnedToNormal)}>{text}</span>;
							},
						};
					});

				const actionsColumn = [
					{
						...buildColumn('', 'CollapseIcon', undefined, 'start'),
						key: 'CollapseIconHeader',
						dataIndex: 'CollapseIcon',
						render: (expandIcon: AlarmExpandColumn, row: AlarmTableDataSourceModel) => {
							let component: JSX.Element = <></>;
							switch (expandIcon) {
								case AlarmExpandColumn.ExpandIcon:
									component = <MinusSquareOutlined />;
									break;

								case AlarmExpandColumn.CollapseIcon:
									component = <PlusSquareOutlined />;
									break;

								case AlarmExpandColumn.InExpandedRow:
									component = <span className={styles.inExpandColumn}></span>;
									break;

								case AlarmExpandColumn.LastExpandedRow:
									component = <span className={styles.lastExpandColumn}></span>;
									break;
							}

							return component;
						},
					},
					{
						...buildColumn('', 'Action', undefined, 'start'),
						key: 'ActionHeader',
						dataIndex: 'Action',
						render: (value: AlarmActionColumnData, row: AlarmTableDataSourceModel) => createActionElement(value, row),
					},
				];
				setTableColumns([...actionsColumn, ...columns]);
				loadFirstPage();
			}
		});
	};

	const getStoredAlarms = async (pageNumber: number): Promise<AlarmListResponseObject> => {
		return alarmApi.getStoredAlarms(pageNumber, Number(alarmsPerPage), isAcknowledgedAlarmTable, false, selectAllAlarms(getDataSourceReference()));
	};

	const getTableColumns = async (): Promise<ResponseObjectEntity<GridDataColumnItem[]>> => {
		return alarmApi.getAlarmsTableColumns(isAcknowledgedAlarmTable);
	};

	const headerColumnsWidth: CSSProperties = useMemo(
		() => getColumnWidth<AlarmTableDataSourceModel>(tableColumns, tableColumnsWidth as Record<keyof AlarmTableDataSourceModel, string>),
		[tableColumns]
	);

	const headerBarConfigureButton: React.ReactNode = useMemo(
		() => (
			<Button
				id={`${isAcknowledgedAlarmTable ? 'acknowledged' : 'active'}AlarmTableConfigureButton`}
				type='text'
				icon={<SettingFilled />}
				onClick={() => setShowAlarmConfigureColumns(true)}
				disabled={!canChangeColumnConfiguration}
				title={getPermissionErrorMessage(canChangeColumnConfiguration)}
			/>
		),
		[]
	);

	const handleOnLoadColumnConfiguration = async (): Promise<ColumnConfigurationModel> => {
		const promise: ResponseObjectEntity<ColumnConfigurationModel> = await alarmApi.getAlarmsColumnsConfiguration(isAcknowledgedAlarmTable);
		if (!handleResponse(promise)) {
			return promise?.Entity;
		}

		return undefined;
	};

	const addOperatorNoteCallback = (action: AlarmAcknowledgementActions, payload: AcknowledgedAndClearAlarmsPayload) => {
		return getAlarmNoteAddOperatorNoteModal(action, true, payload.alarmIds, getDataSourceReference(), payload.alarmsDetails);
	};

	const openAddOperatorNoteModal = (action: AlarmAcknowledgementActions, isAlarmNoteForAcknowledge: boolean, alarmIds: React.Key[]) => {
		const alarmNote = getAlarmNoteAddOperatorNoteModal(action, isAlarmNoteForAcknowledge, alarmIds, getDataSourceReference());
		dispatch(setShowAlarmAddOperatorNote(true));
		dispatch(setAlarmNoteForAcknowledge(alarmNote));
	};

	const getAllAlarmIds = async (): Promise<AlarmModel[]> => {
		const response = await alarmApi.getAllAlarmIds(isAcknowledgedAlarmTable);
		if (handleResponse(response)) {
			return [];
		}
		return response.Entity;
	};

	const setAlarmNoteForAcknowledgeCallback = (alarmNote: AlarmNoteForAcknowledge) => {
		dispatch(setAlarmNoteForAcknowledge(alarmNote));
	};

	const setShowAlarmAddOperatorNoteCallback = () => {
		dispatch(setShowAlarmAddOperatorNote(true));
	};

	const handleActionDropdown = async (option: AlarmAcknowledgementActions | AlarmActionOptions) => {
		let payload: AcknowledgedAndClearAlarmsPayload = {
			action: option as AlarmAcknowledgementActions,
			operatorNotes: [],
			alarmIds: [],
			acknowledgeAndClearCallback: acknowledgeAndClearAlarmCommon,
			addOperatorNoteCallback,
			setAlarmNoteForAcknowledgeCallback,
			setShowAlarmAddOperatorNoteCallback,
		};

		const actualSelectedAlarmKeys = getSelectedAlarmKeysReference();
		switch (option) {
			case AlarmAcknowledgementActions.AcknowledgedOne:
			case AlarmAcknowledgementActions.ClearOneAcknowledged:
			case AlarmAcknowledgementActions.ClearOneUnacknowledged:
				payload.alarmIds = actualSelectedAlarmKeys.map<number>(x => Number(x));
				payload.action = getMultipleAlarmAction(actualSelectedAlarmKeys, option);
				acknowledgedAndClearAlarms(payload);
				break;

			case AlarmAcknowledgementActions.AcknowledgedAll:
			case AlarmAcknowledgementActions.ClearAllAcknowledged:
			case AlarmAcknowledgementActions.ClearAllUnacknowledged:
				setIsLoading(true);
				const alarmsModel: AlarmModel[] = await getAllAlarmIds().finally(() => setIsLoading(false));
				payload.alarmIds = selectAllAlarms(alarmsModel).map(x => Number(x));
				payload.alarmsDetails = alarmsModel;
				handleCheckboxMenuAction(CheckboxMenuOptions.SELECT_ALL);
				acknowledgedAndClearAlarms(payload);
				break;

			case AlarmActionOptions.ADD_OPERATOR_NOTE:
				const action = AlarmAcknowledgementActions.None;
				openAddOperatorNoteModal(action, false, actualSelectedAlarmKeys);
				break;
		}
	};

	const actualDataSource = getDataSourceReference();
	const actualSelectedAlarmKeys = getSelectedAlarmKeysReference();
	const hasSelectedAlarms = actualSelectedAlarmKeys.length > 0;
	const applyBorder: string = actualDataSource.length === 0 ? styles.border : '';
	const prefixBody: string = isAcknowledgedAlarmTable ? 'acknowledgedAlarms' : 'alarms';

	const fetchDataCallback = useCallback((pageNumber: number) => {
		fetchData(pageNumber);
	}, []);

	const toggleItemActiveCallback = useCallback((itemIndex: number) => {
		toggleItemActive(itemIndex);
	}, []);

	const handleActionDropdownCallback = useCallback(async (option: AlarmAcknowledgementActions | AlarmActionOptions) => {
		handleActionDropdown(option);
	}, []);

	const initTableCallback = useCallback(async () => initTable(), []);
	const setShowAlarmConfigureFalseColumnsCallback = useCallback(() => setShowAlarmConfigureColumns(false), []);
	const handleOnLoadColumnConfigurationCallback = useCallback(async () => handleOnLoadColumnConfiguration(), [isAcknowledgedAlarmTable]);

	return (
		<>
			<div id={`${prefixBody}Container`} className={styles.container}>
				<div id={`${prefixBody}Actions`} className={styles.alarmActionsContainer}>
					<AlarmActionDropdown
						hasSelectedAlarms={hasSelectedAlarms}
						isAlarmTableEmpty={actualDataSource.length === 0}
						isAcknowledgedAlarmTable={isAcknowledgedAlarmTable}
						handleActionDropdown={handleActionDropdownCallback}
					/>
				</div>
				<div id={`${prefixBody}TableContainer`} className={applyBorder}>
					<HeaderBar title={isAcknowledgedAlarmTable ? _('AcknowledgedAlarms') : _('Alarms')} buttons={headerBarConfigureButton} />
					<div className={styles.alarmContainer}>
						{actualDataSource.length === 0 ? (
							<div className={styles.empty}>
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={isAcknowledgedAlarmTable ? _('ThereAreNoAcknowledgedAlarms') : _('ThereAreNoActiveAlarms')}
								/>
							</div>
						) : (
							<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
								<VirtualTableExpandableRow
									columns={tableColumns}
									dataSource={actualDataSource}
									fetchData={fetchDataCallback}
									pageSize={alarmsPerPage}
									headerColumnsWidth={headerColumnsWidth}
									maxHeight={270}
									idTable={`${prefixBody}Table`}
									handleOnExpandRow={toggleItemActiveCallback}
									alwaysShowInstructions={alwaysShowInstructions}
									alarmStackingEnabled={alarmStackingEnabled}
									totalAlarms={totalActiveAlarms}
									sessionStorageFetchKey={sessionStorageFetchKey}
									sessionStorageLiveEventKey={sessionStorageLiveEventKey}
									containerClassName={isAcknowledgedAlarmTable ? 'acknowledgedContainer' : 'activeContainer'}
								/>
							</Spin>
						)}
						{totalAlarms !== 0 && (
							<div className={styles.tableCount}>
								{actualSelectedAlarmKeys.length > 0 ? (
									<span>{`${actualSelectedAlarmKeys.length}/${totalAlarms} ${_('Items')}`}</span>
								) : (
									<span>{`${totalAlarms} ${_('Items')}`}</span>
								)}
							</div>
						)}
					</div>
				</div>
				{showAlarmConfigureColumns && (
					<ColumnConfiguration
						onHideModal={setShowAlarmConfigureFalseColumnsCallback}
						loadColumnConfiguration={handleOnLoadColumnConfigurationCallback}
						dispatchAction={initTableCallback}
						securedComponent={SecuredComponents.Alarm_Viewer}
					/>
				)}
			</div>
		</>
	);
};

const AlarmTable = React.memo(component);

export { AlarmTable };

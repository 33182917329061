import cx from 'classnames';
import React, { useContext } from 'react';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setTriggerControlZone, StoreContext } from '../../../../contextExpansionRelay';
import styles from '../../../../expansionRelayModal.module.scss';

type Props = {
	useDoorContext: boolean;
};

const Trigger: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: { triggerControlZones, selections },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			expansionRelayState: { triggerControlZones, selections },
			dispatcher,
		} = useContext(StoreContext);
	}

	return (
		<div className={styles.sections}>
			<span>{_('TriggerRelaysInControlZone')}</span>
			<div>
				<Select
					id='relayLogicTriggerControlZoneDropdown'
					className={cx(styles.select)}
					options={triggerControlZones.map(x => ({
						label: x.Name,
						value: x.CZIndex.toString(),
						id: `relayLogicTriggerControlZoneOption-${x.CZIndex.toString()}`,
					}))}
					onChange={value => {
						useDoorContext ? dispatcherDoor(setTriggerControlZone(Number(value))) : dispatcher(setTriggerControlZone(Number(value)));
					}}
					key='triggerControlZone'
					value={selections.triggerControlZone.toString()}
					getPopupContainer={() => document.getElementById('logicRelayContainer')}
				/>
			</div>
		</div>
	);
};

export { Trigger };

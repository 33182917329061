import { Button, notification } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { batch } from 'react-redux';
import { unlockHandleBeforeUnload } from '../../../../Helper';
import { deviceAdminApi } from '../../../../api/DeviceAdminApi';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { PaginationSetting } from '../../../../model/CommonModel';
import {
	CurrentDeviceControlObj,
	DeviceObjectType,
	Door,
	ExpansionRelayInfo,
	Input,
	LockScope,
	LockStatusDoorGroupViewModel,
	Reader,
	ReaderError,
	ReaderInterface,
	ScrambleFactorImage,
	ScrambleFactorImageAction,
	ScrambleFactorImageCategories,
} from '../../../../model/DeviceAdminModel';
import { Logger } from '../../../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { setControllerContacts } from '../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { InformationMessages, Modal, ModalWarning, NotificationStatus, WithLockedValidation } from '../../../common';
import { DoorGroupLockStatus } from '../../VelocityConfiguration/DoorGroup/warnings/DoorGroupLockStatus/DoorGroupLockStatus';
import { setExitReaderError, setExitUpdateScrambleFactorImageSelection } from '../ReaderModal/ExitReaderModal/exitReaderContext';
import { setEntryReaderError, setUpdateScrambleFactorImageSelection } from '../ReaderModal/contextReader';
import { DoorContainer } from './DoorContainer/DoorContainer';
import { DoorStoreSingleContext, contextStateDoor, doorReducer } from './DoorStoreSingleContext';

type Props = {
	currentDevice: CurrentDeviceControlObj;
	setShouldResetSearchColumn: () => void;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	setRedirectPage?: () => void;
	saveCallBack?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const permissionsEnabled = User.getComponentPermission(user, SecuredComponents.Door).canUpdate;

const DoorModal: React.FC<Props> = WithLockedValidation(({ currentDevice, setShouldResetSearchColumn, cancelHandle, saveCallBack, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const [doorNameError, setDoorNameError] = useState<boolean>(false);
	const [relayNameError, setRelayNameError] = useState<boolean>(false);
	const [inputNameError, setInputNameError] = useState<boolean>(false);
	const [errorText, setErrorText] = useState('');
	const [activeTab, setActiveTab] = useState(undefined);
	const [errorType, setErrorType] = useState<ReaderError>(undefined);
	const [submittedForm, setSubmitForm] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(true);

	const [doorStateContext, dispatchActionContext] = useReducer(doorReducer, contextStateDoor);

	const {
		door: { door, relayBelongToDoor, entryReaderId, exitReaderId },
		input: { input },
		relay,
		entryReader,
		exitReader,
	} = doorStateContext;

	const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	useEffect(() => {
		if (activeTab === '2' && entryReader.selections.rS485ReaderType === 9) {
			dispatchActionContext(setUpdateScrambleFactorImageSelection(!entryReader.updateScrambleFactorSelection));
		} else if (activeTab === '3' && exitReader.selections.rS485ReaderType === 9) {
			dispatchActionContext(setExitUpdateScrambleFactorImageSelection(!exitReader.updateScrambleFactorSelection));
		}
	}, [activeTab]);

	const setTabError = tab => {
		tab !== '6' ? setActiveTab(tab) : setActiveTab('2');
		switch (tab) {
			case '1':
				setDoorNameError(true);
				break;
			case '2':
				dispatchActionContext(setEntryReaderError(true));
				break;
			case '3':
				dispatchActionContext(setExitReaderError(true));
				break;
			case '4':
				setRelayNameError(true);
				break;
			case '5':
				setInputNameError(true);
				break;
			case '6':
				dispatchActionContext(setEntryReaderError(true));
				dispatchActionContext(setExitReaderError(true));
				break;
		}
	};

	const shouldResetSearchColumn = () => {
		if (setRedirectPage) {
			setRedirectPage();
		}
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const handleSaveChanges = async () => {
		batch(() => {
			setSubmitForm(true);
			setLoading(true);
		});

		try {
			const relayEdited: ExpansionRelayInfo = {
				Id: relayBelongToDoor.Id,
				Name: relay.name,
				DoorDelayTime: relay.doorDelayTime,
				ControlMode: relay.controlModeTime,
				ControlDelay: relay.controlDelayTime,
				NormalState: relay.normalState,
				OperateTimeZone: relay.unpermitted.operateTimeZone
					? relay.unpermitted.operateTimeZone
					: relay.operateTimeZones.find(x => x.GenericId === relay.selections.operateTimeZone),
				ActuateTimeZone: relay.unpermitted.actuateTimeZone
					? relay.unpermitted.actuateTimeZone
					: relay.actuateTimeZones.find(x => x.GenericId === relay.selections.actuateTimeZone),
				DisableTimeZone: relay.unpermitted.disableTimeZone
					? relay.unpermitted.disableTimeZone
					: relay.disableTimeZones.find(x => x.GenericId === relay.selections.disableTimeZone),
				AutoClearTimeZone: relay.unpermitted.autoClearAfterTimeZone
					? relay.unpermitted.autoClearAfterTimeZone
					: relay.autoClearAfterTimeZones.find(x => x.GenericId === relay.selections.autoClearAfterTimeZone),
				TriggerControlZone: relay.selections.triggerControlZone,
				ReTriggerControlZone: relay.selections.reTriggerControlZone,
				GenericTimeZones: [],
				ControlZones: [],
				StandardControlZones: [],
				ControllerId: relay.controllerId,
				TwoMan: door.EnableTwoPersonRule,
			};

			const entryReaderEdited: Partial<Reader> = {
				ControllerId: entryReader.controllerId,
				Name: entryReader.name,
				Id: entryReaderId,
				Index: entryReader.index,
				SupportedReaderClass: entryReader.supportedReaderClass,
				CardMap: entryReader.selections.cardMap,
				RS485Interface: entryReader.readerInterface === ReaderInterface.RS485,
				AnyReaderOptions: entryReader.anyReaderOptions,
				CardReaderOptions: entryReader.cardReaderOptions,
				ScramblePadKeyPadOptions: entryReader.scramblePadKeyPadOptions,
				DisabledReaderAboveLevelId: entryReader.selections.readerThreatLevel,
				DisabledCCOTZAboveLevelId: entryReader.selections.cCOTZThreatLevel,
				OSDPAddress: entryReader.oSDPAddress,
				WLHubAddress: entryReader.wLHubAddress,
				EACAddress: entryReader.eACAddress,
				WL2HubAddress: entryReader.wL2HubAddress,
				AllegionLockAddress: entryReader.allegionLockAddress,
				OkDegrade: entryReader.okDegrade,
				AntiPassback: entryReader.antiPassback,
				CCOTZAssurance: entryReader.cCOTZAssurance,
				AssuranceLevel: entryReader.selections.assuranceLevel,
				DefaultAssuranceLevel: entryReader.selections.defaultAssuranceLevel,
				Firmware: entryReader.firmware,
				ToZone: entryReader.selections.toZone,
				FromZone: entryReader.selections.fromZone,
				EnableReaderBezelTamper: entryReader.enableReaderBezelTamper,
				EnableHexPassThrough: entryReader.enableHexPassThrough,
				SpecialHandlingInstruction: entryReader.selections.specialHandlingInstruction,
				EnableMatchBezelTamper: entryReader.enableMatchBezelTamper,
				FacilityCodeCardNumber: entryReader.facilityCodeCardNumber,
				Corporate1000: entryReader.corporate1000,
				HexDigitUUID: entryReader.hexDigitUUID,
				EnableKeyPad: entryReader.enableKeyPad,
				CardCodeOnlyTimeZone: entryReader.unpermitted.cardCodeOnlyTimeZone
					? entryReader.unpermitted.cardCodeOnlyTimeZone
					: entryReader.genericTimeZones.find(x => x.GenericId === entryReader.selections.cardCodeOnlyTimeZone),
				RS485ReaderType: entryReader.selections.rS485ReaderType,
				ReaderInterface: entryReader.readerInterface,
				MatchAlgorithm: entryReader.matchAlgorithm,
				FascnHandling: entryReader.fascnHandling,
				ChangedOSDPLEDColor: entryReader.changedOSDPLEDColor,
				OSDPLEDOptions: entryReader.osdpLEDOptions,
				UseCustomOSDPLEDColor: entryReader.useCustomOSDPLEDColor,
				TSScrambleImages: entryReader.selectedFactorImages,
				ViewPINEntryIndicator: entryReader.viewPINEntryIndicator,
			};

			const exitReaderEdited: Partial<Reader> = {
				ControllerId: exitReader.controllerId,
				Name: exitReader.name,
				Id: exitReaderId,
				Index: exitReader.index,
				SupportedReaderClass: exitReader.supportedReaderClass,
				CardMap: exitReader.selections.cardMap,
				RS485Interface: exitReader.readerInterface === ReaderInterface.RS485,
				AnyReaderOptions: exitReader.anyReaderOptions,
				CardReaderOptions: exitReader.cardReaderOptions,
				ScramblePadKeyPadOptions: exitReader.scramblePadKeyPadOptions,
				DisabledReaderAboveLevelId: exitReader.selections.readerThreatLevel,
				DisabledCCOTZAboveLevelId: exitReader.selections.cCOTZThreatLevel,
				OSDPAddress: exitReader.oSDPAddress,
				WLHubAddress: exitReader.wLHubAddress,
				EACAddress: exitReader.eACAddress,
				WL2HubAddress: exitReader.wL2HubAddress,
				AllegionLockAddress: exitReader.allegionLockAddress,
				OkDegrade: exitReader.okDegrade,
				AntiPassback: exitReader.antiPassback,
				CCOTZAssurance: exitReader.cCOTZAssurance,
				AssuranceLevel: exitReader.selections.assuranceLevel,
				DefaultAssuranceLevel: exitReader.selections.defaultAssuranceLevel,
				Firmware: exitReader.firmware,
				ToZone: exitReader.selections.toZone,
				FromZone: exitReader.selections.fromZone,
				EnableReaderBezelTamper: exitReader.enableReaderBezelTamper,
				EnableHexPassThrough: exitReader.enableHexPassThrough,
				SpecialHandlingInstruction: exitReader.selections.specialHandlingInstruction,
				EnableMatchBezelTamper: exitReader.enableMatchBezelTamper,
				FacilityCodeCardNumber: exitReader.facilityCodeCardNumber,
				Corporate1000: exitReader.corporate1000,
				HexDigitUUID: exitReader.hexDigitUUID,
				EnableKeyPad: exitReader.enableKeyPad,
				CardCodeOnlyTimeZone: exitReader.unpermitted.cardCodeOnlyTimeZone
					? exitReader.unpermitted.cardCodeOnlyTimeZone
					: exitReader.genericTimeZones.find(x => x.GenericId === exitReader.selections.cardCodeOnlyTimeZone),
				RS485ReaderType: exitReader.selections.rS485ReaderType,
				ReaderInterface: exitReader.readerInterface,
				MatchAlgorithm: exitReader.matchAlgorithm,
				FascnHandling: exitReader.fascnHandling,
				ChangedOSDPLEDColor: exitReader.changedOSDPLEDColor,
				OSDPLEDOptions: exitReader.osdpLEDOptions,
				UseCustomOSDPLEDColor: exitReader.useCustomOSDPLEDColor,
				TSScrambleImages: exitReader.selectedFactorImages,
				ViewPINEntryIndicator: exitReader.viewPINEntryIndicator,
			};

			const inputEdited: Partial<Input> = {
				...input,
				ArClo: door.AutoRelockOnClose,
				ArOff: door.AutoRelockDisable,
				Overexmax: door.ExtendedAccessOverrideTime,
				Overexw: door.ExtendedAccessWarningTime,
			};
			const doorEdited: Door = {
				...door,
				Input: { ...inputEdited },
				Relay: { ...relayEdited },
				EntryReader: { ...entryReaderEdited },
				ExitReader: { ...exitReaderEdited },
			};

			let scrambleFactorImages = [];
			entryReader.scrambleFactorCategories.forEach((category: ScrambleFactorImageCategories) =>
				category.ScrambleFactorImages.forEach((image: ScrambleFactorImage) => {
					if (image.Action !== ScrambleFactorImageAction.None) {
						scrambleFactorImages.push(image);
					}
				})
			);

			doorEdited.EntryReader.ScrambleFactorImages = scrambleFactorImages;

			const response = await deviceAdminApi.editDoor(doorEdited);

			const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;
			const toDispatch = setControllerContacts(DeviceObjectType.Door, paginationSetting);
			const handleSuccess = () => {
				dispatch(toDispatch);
				shouldResetSearchColumn();
				if (saveCallBack) {
					saveCallBack();
				}
				handleCancelModal();
			};

			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					handleSuccess();
				},
				onComponentLockedCallback: async () => {
					const { AffectedDoorGroups, ErrorMessage } = await deviceAdminApi.getLockAffectedDoorGroups(door.DoorId);

					const content: JSX.Element = <InformationMessages messages={AffectedDoorGroups} />;
					ModalWarning({
						title: ErrorMessage,
						content,
						onOk: () => null,
						onCancel: () => null,
						okText: _('Ok'),
					});
				},
				onAdditionalInfoRequiredCallback: async () => {
					const responseObjectId = Number(response.ResponseObjectId);
					let title: string = '';
					let content: JSX.Element = null;
					if (responseObjectId === 1) {
						const messages: string[] = response.ErrorMessage.split('\n');
						content = <InformationMessages messages={messages} />;
						title = _('FailedToSyncTimeZone');
					} else if (responseObjectId === 2 || responseObjectId === 3) {
						const messages: string[] = response.AdditionalResponseInfo.split('\n');
						content = <InformationMessages messages={messages} />;
						title = responseObjectId === 2 ? _('FailedToSyncTimeZone') : _('UnableToDeleteImage');
					} else {
						const lockStatus = await deviceAdminApi.getDoorLocks(door.DoorId, LockScope.PerExitDoor);
						const { ErrorMessage, ResponseErrorDescription } = lockStatus;
						const conflictModel: LockStatusDoorGroupViewModel = {
							IsMasterDoorGroup: false,
							LockStatusWarning: lockStatus,
							ObjectId: door.DoorId,
						};
						title = ResponseErrorDescription;
						content = <DoorGroupLockStatus conflictModel={conflictModel} errorDescription={ErrorMessage} isDoorLocks={true} />;
					}
					ModalWarning({
						title,
						content,
						onOk: () => handleSuccess(),
						onCancel: () => handleSuccess(),
						okText: _('Ok'),
						width: '700px',
					});
				},
				onFailedValidation: () => {
					setTabError(response.ResponseErrorDescription);

					if (errorType !== ReaderError.EACAddress) {
						setErrorType(undefined);
					}

					setErrorText(response.ErrorMessage);
					setErrorType(ReaderError[response.AdditionalResponseInfo]);
				},
				onDefaultEventCallback: async () => {
					unlockHandleBeforeUnload(currentDevice.Id, currentDevice.DeviceObjectType);
				},
				onPermissionErrorCallback: async () => {
					notification['error']({
						message: response.ErrorMessage,
					});
				},
			});
		} catch (e) {
			const error: Error = e as Error;
			Logger.writeErrorLog(`${error.name}: ${error.message}`);
		} finally {
			setLoading(false);
			setSubmitForm(false);
		}
	};

	const handleCancelModal = () => {
		unlockHandleBeforeUnload(currentDevice.Id, currentDevice.DeviceObjectType);
		const newDevice = { Id: 0, DeviceObjectType: currentDevice.DeviceObjectType, IsModalOpen: false };
		if (cancelHandle) {
			cancelHandle(currentDevice);
		} else {
			dispatch(setCurrentDevice(newDevice));
		}
	};

	const doorContextValue = useMemo(
		() => ({ contextStateDoor: doorStateContext, dispatcherDoor: dispatchActionContext }),
		[doorStateContext, dispatchActionContext]
	);
	const { Provider } = DoorStoreSingleContext;
	const OTTLValidation = input?.DOTLTime < input?.DOTLWaitingTime;
	return (
		<Provider value={doorContextValue}>
			<Modal
				width='900px'
				visible={true}
				onCancel={() => handleCancelModal()}
				onClickOk={() => null}
				title={`${_('EditDoor')} ${currentDevice.Address}`}
				footer={[
					<Button
						key='save'
						type='primary'
						id='doorModalSaveChangesButton'
						onClick={() => handleSaveChanges()}
						disabled={isLoading || !permissionsEnabled || OTTLValidation}
						loading={isLoading}
						title={getPermissionErrorMessage(permissionsEnabled)}>
						{_('SaveChanges')}
					</Button>,
					<Button disabled={isLoading} id='doorModalCancelButton' key='cancel' loading={isLoading} onClick={() => handleCancelModal()}>
						{_('Cancel')}
					</Button>,
				]}>
				<div id='doorModal'>
					<DoorContainer
						currentDevice={currentDevice}
						submittedForm={submittedForm}
						doorNameError={doorNameError}
						relayNameError={relayNameError}
						inputNameError={inputNameError}
						isLoading={isLoading}
						errorText={errorText}
						errorType={errorType}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						onResetDoorNameError={() => setDoorNameError(false)}
						onResetRelayNameError={() => setRelayNameError(false)}
						onResetInputNameError={() => setInputNameError(false)}
						setErrorText={() => setErrorText('')}
						setLoading={setLoading}
					/>
				</div>
			</Modal>
		</Provider>
	);
});

export { DoorModal };

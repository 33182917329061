import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { deviceAdminApi } from '../../../../api/DeviceAdminApi';
import { SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	OperatorsColumn,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	SelectOptions,
	SelectedDeviceKeys,
} from '../../../../model/CommonModel';
import { ButtonBuilder, CurrentDeviceControlObj, DeviceObjectType, OperatorModel, OptionsButtonBuilder } from '../../../../model/DeviceAdminModel';
import { Logger } from '../../../../model/LoggingModel';
import { OperatorCredential, OperatorPropertyModel } from '../../../../model/OperatorModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice, setLoading } from '../../../../store/common/actions';
import { selectCurrentDevice } from '../../../../store/common/selectors';
import {
	clearVelocityConfigurationSelectionsAction,
	setOperatorsBy,
	setOperatorsByData,
	setVelocityConfigurationFilterMode,
} from '../../../../store/deviceControl/actions';
import { selectOperators, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, ModalConfirmationList, ModalError, SearchColumn } from '../../../common';
import { EditableCell } from '../../../common/EditableCell/EditableCell';
import { DeletingLoadingModal } from './DeletingLoadingModal';
import { OperatorPermissionModal } from './OperatorsModal/OperatorPermissionModal/OperatorPermissionModal';
import { OperatorsModal } from './OperatorsModal/OperatorsModal';
import styles from './operators.module.scss';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Operator);
const isUserAdmin: boolean = user.isAdmin;
const TwoFASubPermission = User.getSubComponentPermission(user, SubPermissions.Web_Client_MultiFactor_TwoFactorAdministrator);
const isUser2FAAdmin: boolean = TwoFASubPermission.allowed;
const scroll: ScrollType = { x: 379, y: 430 };
const maxLength: number = 20;
const deviceObjectType: DeviceObjectType = DeviceObjectType.Operators;

const adminErrorModalContent: JSX.Element = (
	<div className={styles.errorModalContent}>
		<span>{_('AdminAccountCannotBeDeleted')}</span>
		<span>{_('PressOkToContinue')}</span>
	</div>
);

const createButtonOptions = (isMoreThanOneSelected: boolean, selectedOps: React.Key[], disableDelete: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				label: _('Enable'),
				disabled: !isUserAdmin,
				value: 'enableOperator',
				title: getPermissionErrorMessage(isUserAdmin),
			},
			{
				label: _('LockDown'),
				disabled: !isUserAdmin,
				value: 'lockDownOperator',
				title: getPermissionErrorMessage(isUserAdmin),
			},
			{
				label: _('Edit'),
				disabled: isMoreThanOneSelected,
				value: 'editOperator',
				title: getPermissionErrorMessage(true, false, isMoreThanOneSelected),
			},
			{
				label: _('Delete'),
				disabled: disableDelete,
				value: 'deleteOperator',
				title: getPermissionErrorMessage(isUserAdmin, false),
			},
		],
	};
};

const Operators: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const operators: OperatorModel[] = useStoreSelector(selectOperators);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);
	const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<OperatorsColumn[]>([]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const isSearchPerformed = useRef<boolean>(false);
	const isLoadingDomainsReference = useRef<boolean>(true);
	const isCancellingOccurring = useRef<boolean>(false);
	const needElevatedCredentialForDomainsRef = useRef<boolean>(false);
	const [openPermissionModal, setOpenPermissionModal] = useState<boolean>(false);
	const [isOpenPermissionModalFromEdit, setIsOpenPermissionModalFromEdit] = useState<boolean>(false);
	const [isOpenPermissionModalFromAdd, setIsOpenPermissionModalFromAdd] = useState<boolean>(false);
	const [authPath, setAuthPath] = useState<string>('');
	const [isEnableCustomGroupNames, setIsEnableCustomGroupNames] = useState<boolean>(false);
	const [operatorToEdit, setOperatorToEdit] = useState<OperatorPropertyModel | null>(null);
	const [domains, setDomains] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedOps, setSelectedOps] = useState<OperatorModel[]>([]);
	const [totalOperatorsToDelete, setTotalOperatorsToDelete] = useState<number>(0);
	const [elevatedCredentials, setElevatedCredentials] = useState<OperatorCredential>(null);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
	const [showDeletingLoadingModal, setShowDeletingLoadingModal] = useState<boolean>(false);
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [needElevatedCredentialForDomains, setNeedElevatedCredentialForDomains] = useState<boolean>(false);
	const [isLoadingDomains, setIsLoadingDomains] = useState<boolean>(true);

	const clearSelection = () => {
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: deviceObjectType }));
		setSelectedRowKeys([]);
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	const handleOnOperatorsModalClosed = () => {
		const newDevice: CurrentDeviceControlObj = {
			Id: 0,
			DeviceObjectType: DeviceObjectType.Operators,
			Address: '',
			IsModalOpen: false,
			IsNew: false,
		};
		dispatch(setCurrentDevice(newDevice));
	};

	const handleOnOperatorsModalOpen = (operatorId: number, isNewOperator: boolean) => {
		const newDevice: CurrentDeviceControlObj = {
			...currentDevice,
			Id: operatorId,
			IsModalOpen: !currentDevice.IsModalOpen,
			DeviceObjectType: DeviceObjectType.Operators,
			IsNew: isNewOperator,
		};
		dispatch(setCurrentDevice(newDevice));
	};

	useEffect(() => {
		const mapRes: OperatorsColumn[] = createData();
		setTableData(mapRes);
	}, [JSON.stringify(operators)]);

	useEffect(() => {
		isLoadingDomainsReference.current = isLoadingDomains;
	}, [isLoadingDomains]);

	useEffect(() => {
		needElevatedCredentialForDomainsRef.current = needElevatedCredentialForDomains;
	}, [needElevatedCredentialForDomains]);

	useEffect(() => {
		deviceAdminApi.getAuthenticationPath().then(res => {
			if (!handleResponse(res)) {
				const { Entity: ap } = res;
				setAuthPath(ap);
			}
		});
		deviceAdminApi.getEnableCustomGroupNamesSetting().then(res => {
			if (!handleResponse(res)) {
				const { Entity: customGroupNameFlag } = res;
				setIsEnableCustomGroupNames(customGroupNameFlag);
			}
		});

		return () => {
			clearSelection();
		};
	}, []);

	useEffect(() => {
		const fetchDomains = async () => {
			try {
				let ds: string[] = [];
				const mName: string = webServiceMachineName();
				let useDomainAuth: boolean = false;
				const res1 = await deviceAdminApi.getDomains();
				if (!handleResponse(res1)) {
					ds = res1.Entity;
					setDomains(ds);
					const res2 = await deviceAdminApi.isUseDomainAuthentication();
					if (!handleResponse(res2)) {
						useDomainAuth = res2.Entity;
						if (useDomainAuth && ds?.length == 1) {
							const foundDomain = ds[0].toLowerCase();
							if (foundDomain === mName.toLowerCase()) {
								setNeedElevatedCredentialForDomains(true);
							}
						}
					}
				}
			} catch (error) {
				Logger.writeErrorLog(`${error.name}: ${error.message}`);
			} finally {
				setIsLoadingDomains(false);
			}
		};

		fetchDomains();
	}, []);

	useEffect(() => {
		if (domains?.length > 0) {
			deviceAdminApi.getForest().then(res => {
				if (!handleResponse(res)) {
					const { Entity: forest } = res;
					if (forest?.length > 0) {
						const newDomains = forest.filter(f => !domains.includes(f));
						setDomains(prev => prev.concat(newDomains));
					}
				}
			});
		}
	}, [JSON.stringify(domains)]);

	useEffect(() => {
		if (isOpenPermissionModalFromEdit || isOpenPermissionModalFromAdd || isCancellingOccurring.current === true) {
			isCancellingOccurring.current = false;
			return;
		}
		if (!isErrorModalOpen && !openPermissionModal) {
			if (selectedOps?.length > 0) {
				const currentOperator = selectedOps[0];
				if (currentOperator.Id === 1) {
					showDeleteErrorModalConfirm(adminErrorModalContent);
				} else {
					deviceAdminApi
						.deleteOperator(
							currentOperator.Id,
							true,
							elevatedCredentials || {
								Domain: '',
								Password: '',
								UseCredentials: false,
								UserName: '',
							}
						)
						.then(deleteOperatorResponse => {
							handleDeleteOperationValidationResponse(deleteOperatorResponse, currentOperator);
						})
						.catch(e => {
							const error = e as Error;
							Logger.writeErrorLog(`${error.name}: ${error.message}`);
							showDeleteErrorModalConfirm(buildGeneralErrorModalContent(currentOperator.Name));
						});
				}
			}
		}
		if (selectedOps.length === 0) {
			batch(() => {
				if (openPermissionModal) {
					setOpenPermissionModal(false);
				}
				setIsLoading(false);
				setShowDeletingLoadingModal(false);
				setElevatedCredentials(null);
				setSelectedRowKeys([]);
				if (!isFirstLoad) {
					dispatch(setOperatorsBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: '' }));
				}
				setIsFirstLoad(false);
			});
		}
	}, [isErrorModalOpen, openPermissionModal, selectedOps]);

	const handleDeleteOperationValidationResponse = (
		deleteOperatorResponse: ResponseObject,
		currentOperator: OperatorModel,
		closePermissionModal?: boolean
	) => {
		if (deleteOperatorResponse.ResponseStatusCode !== ResponseStatusCode.Success) {
			if (
				deleteOperatorResponse.ResponseStatusCode === ResponseStatusCode.PermissionError &&
				deleteOperatorResponse.AdditionalResponseInfo === 'require elevated credentials'
			) {
				setOpenPermissionModal(true);
			} else if (
				deleteOperatorResponse.ResponseStatusCode === ResponseStatusCode.FailedValidation ||
				deleteOperatorResponse.ResponseStatusCode === ResponseStatusCode.PermissionError ||
				deleteOperatorResponse.ResponseStatusCode === ResponseStatusCode.ComponentLocked
			) {
				let msg: string = '';
				switch (deleteOperatorResponse.ResponseStatusCode) {
					case ResponseStatusCode.ComponentLocked:
						msg = deleteOperatorResponse.ResponseErrorDescription;
						break;
					case ResponseStatusCode.PermissionError:
						msg = deleteOperatorResponse.ErrorMessage;
						break;
					default:
						msg = _(deleteOperatorResponse.ErrorMessage);
				}
				const errorModalContent: JSX.Element = (
					<div className={styles.errorModalContent}>
						<span>
							{_('NextErrorOccurredDeletingOperator')} '{currentOperator.Name}':
						</span>
						<span className={styles.redFont}>{msg}</span>
						<span>{_('PressOkToContinue')}</span>
					</div>
				);
				showDeleteErrorModalConfirm(errorModalContent);
			} else {
				showDeleteErrorModalConfirm(buildGeneralErrorModalContent(currentOperator.Name));
			}
		} else {
			const [, ...newSelectedOps] = selectedOps;
			setSelectedOps(newSelectedOps);
		}
		if (closePermissionModal) {
			setOpenPermissionModal(false);
		}
	};

	const showDeleteErrorModalConfirm = (content: JSX.Element) => {
		setIsErrorModalOpen(true);
		ModalError({
			centered: false,
			content,
			okText: _('Ok'),
			onOk: () => {
				batch(() => {
					const [, ...newSelectedOps] = selectedOps;
					setSelectedOps(newSelectedOps);
					setIsErrorModalOpen(false);
				});
			},
		});
	};

	const buildGeneralErrorModalContent = (operatorName: string): JSX.Element => (
		<div className={styles.errorModalContent}>
			<span>
				{_('AnErrorOccurredDeletingOperator')} '{operatorName}'.
			</span>
			<span>{_('ContactAnAdmin')}</span>
			<span>{_('PressOkToContinue')}</span>
		</div>
	);

	const onEnableRequest = async (selectedOperators: OperatorModel[]): Promise<void> => {
		dispatch(setLoading(true));

		for (const operator of selectedOperators) {
			const response = await deviceAdminApi.enableOperator(operator);
			handleResponse(response);
		}
	};

	const onLockDownRequest = async (selectedOperators: OperatorModel[]): Promise<void> => {
		dispatch(setLoading(true));

		for (const operator of selectedOperators) {
			const response = await deviceAdminApi.lockDownOperator(operator);
			handleResponse(response);
		}
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const disabled: boolean = false;
	const isMoreThanOneSelected: boolean = selectedRowKeys?.length > 1;
	let disableDelete: boolean = false;

	if (!isUserAdmin) {
		disableDelete = true;
	} else if (isMoreThanOneSelected) {
		if (selectedRowKeys?.length === 2) {
			disableDelete = selectedRowKeys.some(id => id.toString() === '1') && selectedRowKeys.some(id => id.toString() === user.operatorId.toString());
		}
	} else if (selectedRowKeys) {
		disableDelete = selectedRowKeys.some(id => id.toString() === '1' || id.toString() === user.operatorId.toString());
	}

	const items = createButtonOptions(isMoreThanOneSelected, selectedRowKeys, disableDelete);

	const handleOnSearchResults = (searchedValue: string) => {
		dispatch(setOperatorsBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	const columns: ColumnsProps<OperatorsColumn>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			sorter: isUserAdmin || isUser2FAAdmin,
			...(isFindUserEnabled() && (isUserAdmin || isUser2FAAdmin)
				? SearchColumn({
						maxLength,
						dataIndex: 'Name',
						reset: undefined,
						label: undefined,
						notVisible: undefined,
						resetSearch: shouldResetSearchColumn,
						setIsFilterMode: setVelocityConfigurationFilterMode,
						clearSelection,
						handleResetSearch: () => setShouldResetSearchColumn(false),
						setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
						setSearchedValue: undefined,
						searchColumnId: undefined,
						setSearchPerformed: (value: boolean) => setSearchPerformed(value),
				  })
				: {}),
		},
		{
			...buildColumn(_('Status'), 'OperatorStatus', 'auto', 'start'),
			sorter: isUserAdmin || isUser2FAAdmin,
		},
	];

	const onChangeSelection = (key: BaseColumns) => {
		const operatorId = key.key.toString();
		if (selectedRowKeys.findIndex(key => key === operatorId) === -1) {
			const cloneState: OperatorModel[] = operators.map(x => (x.Id.toString() === operatorId ? { ...x, checked: true } : { ...x, checked: false }));
			dispatch(setOperatorsByData(cloneState));
			setSelectedRowKeys([operatorId]);
		}
	};

	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];

	const mergedColumns = columns.map(col => ({
		...col,
		onCell: (record: OperatorsColumn) => ({
			record,
			dataIndex: col.dataIndex,
			title: col.title,
			maxLength,
			options: contextMenuOptions,
			isTableInEditMode: false,
			onChangeSelection,
			onClickOption: handleActionScope,
		}),
	}));

	const getSelectedOperators = async (): Promise<SelectedDeviceKeys[]> => {
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);
		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const handleActionScope = async (key: string) => {
		const selectedOperatorsKeys = await getSelectedOperators();
		const selectedOperators = selectedOperatorsKeys.map(x => ({ Id: x.Id, Name: x.Name } as OperatorModel));
		switch (key) {
			case 'enableOperator': {
				const operatorAction: string = _('Enable');
				ModalConfirmationList({
					type: operatorAction,
					translationKey: 'AreYouSureYouWantToTheseOperators',
					data: selectedOperators,
					onConfirm: () =>
						onEnableRequest(selectedOperators).then(res => {
							dispatch(setOperatorsBy(tablePaginationSetting));
						}),
				});
				break;
			}

			case 'lockDownOperator': {
				const operatorAction: string = _('LockDown');
				ModalConfirmationList({
					type: operatorAction,
					translationKey: 'AreYouSureYouWantToTheseOperators',
					data: selectedOperators,
					onConfirm: () =>
						onLockDownRequest(selectedOperators).then(res => {
							dispatch(setOperatorsBy(tablePaginationSetting));
						}),
				});
				break;
			}

			case 'editOperator': {
				const op = tableData.find(td => td.key === selectedRowKeys[0]);
				const operatorId = op?.key;
				setIsLoading(true);
				const response = await deviceAdminApi.getOperatorProperties(Number(operatorId), isEnableCustomGroupNames);
				if (response.ResponseStatusCode === ResponseStatusCode.Success) {
					const { Entity: operatorProps } = response;
					setOperatorToEdit(operatorProps);
				} else {
					setIsOpenPermissionModalFromEdit(true);
					setOpenPermissionModal(true);
					setIsLoading(false);
					return;
				}
				const isLocked: boolean = await lockedValidation(deviceObjectType, Number(operatorId), false);
				if (isLocked) {
					setIsLoading(false);
					return;
				}
				batch(() => {
					handleOnOperatorsModalOpen(Number(operatorId), !isEditMode);
					setIsEditMode(true);
					setIsModalOpen(true);
					setIsLoading(false);
				});
				break;
			}
			case 'deleteOperator': {
				const operatorAction: string = _('Delete');
				const newSelectedRowKeys = [];
				const newSelectedOperators = selectedOperators.filter(op => {
					const flag = op.Id !== 1 && op.Id.toString() !== user.operatorId.toString();
					if (flag) {
						newSelectedRowKeys.push(op.Id.toString());
					}
					return flag;
				});
				if (newSelectedOperators.length > 0 && selectedOperators.length > 0 && selectedOperators.length !== newSelectedOperators.length) {
					notification.error({
						message: _('CannotDeleteCurrentOrAdminOperator'),
					});
				}
				setSelectedRowKeys(newSelectedRowKeys);
				if (newSelectedOperators.length > 0) {
					ModalConfirmationList({
						type: operatorAction,
						translationKey: 'AreYouSureYouWantToTheseOperators',
						data: newSelectedOperators,
						onConfirm: () => {
							batch(() => {
								setIsLoading(true);
								setShowDeletingLoadingModal(true);
								setSelectedOps(newSelectedOperators);
								setTotalOperatorsToDelete(selectedOperators.length);
							});
						},
					});
				}
				break;
			}
		}
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getOperators();
		const commandSetsIds = response?.map<string>(x => x.Id.toString());
		return getUniqueValuesArray(newSelectedRowKeys, commandSetsIds);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: OperatorModel[] = operators.map(x => ({ ...x, checked: selectedRowKeys.findIndex(r => r === x.Id) !== -1 }));
		dispatch(setOperatorsByData(cloneState));
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: OperatorModel[] = operators.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		dispatch(setOperatorsByData(cloneState));
		setSelectedRowKeys(newSelectedRowKeys);
		setIsTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = operators.map<React.Key>(x => x.Id?.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		const cloneState: OperatorModel[] = operators.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.Id?.toString()) !== -1,
		}));

		dispatch(setOperatorsByData(cloneState));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const createActionElement = (operator: OperatorModel): React.ReactNode => {
		const content: React.ReactNode = undefined;

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): OperatorsColumn[] => {
		if (operators.length > 0) {
			return operators.map<OperatorsColumn>((data: OperatorModel) => ({
				key: data.Id.toString(),
				Action: createActionElement(data),
				Name: data.Name,
				OperatorStatus: data.OperatorStatus ? _('Enabled') : _('LockedDown'),
				Bypass2FA: data.Bypass2FA,
			}));
		}

		return [];
	};

	const rowSelection: TableRowSelection<OperatorsColumn> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(!isUserAdmin, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `operatorsTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`operatorsTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectOperator')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<OperatorsColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setOperatorsBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	function waitUntilDomainsHaveLoaded(timeOut: number = 5000): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			let timer: NodeJS.Timeout;
			const interval = setInterval(() => {
				if (isLoadingDomainsReference.current === false) {
					clearInterval(interval);
					resolve();
				}
			}, 100);
			timer = setTimeout(() => {
				clearInterval(interval);
				reject(new Error('Timeout occurred'));
			}, timeOut);
		});
	}

	const buttons: ButtonBuilder[] = [
		{
			id: 'addOperatorButton',
			label: _('AddNewOperator'),
			icon: <PlusOutlined />,
			disabled: !componentPermission.canAdd || (isLoadingDomainsReference.current && isOpenPermissionModalFromAdd),
			title: getPermissionErrorMessage(componentPermission.canAdd),
			onClick: () => {
				if (isLoadingDomainsReference.current === true) {
					setIsLoading(true);
					waitUntilDomainsHaveLoaded()
						.then(() => {
							if (needElevatedCredentialForDomainsRef.current === true) {
								batch(() => {
									setIsOpenPermissionModalFromAdd(true);
									setOpenPermissionModal(true);
								});
							} else {
								batch(() => {
									setIsEditMode(false);
									setIsModalOpen(true);
								});
							}
						})
						.catch(error => {
							Logger.writeErrorLog(`${error.name}: ${error.message}`);
						})
						.finally(() => {
							setIsLoading(false);
						});
				} else {
					if (needElevatedCredentialForDomainsRef.current === true) {
						batch(() => {
							setIsOpenPermissionModalFromAdd(true);
							setOpenPermissionModal(true);
						});
					} else {
						batch(() => {
							setIsEditMode(false);
							setIsModalOpen(true);
						});
					}
				}
			},
		},
	];

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} onClick={x.onClick} disabled={x.disabled} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const unlockOperator = () => {
		if (isEditMode) {
			const operatorId = tableData.find(td => td.key === selectedRowKeys[0])?.key;
			deviceAdminApi.unlockComponentDeviceAdmin(Number(operatorId), SecuredComponents.Operator);
		}
	};

	const onHandleElevatedOperation = async (credentials: OperatorCredential): Promise<ResponseStatusCode> => {
		const operatorId: React.Key = tableData.find(td => td.key === selectedRowKeys[0])?.key;
		const isLocked: boolean = await lockedValidation(deviceObjectType, Number(operatorId), false);
		if (isLocked) {
			return ResponseStatusCode.ComponentLocked;
		}

		const response = await deviceAdminApi.getOperatorProperties(Number(operatorId), isEnableCustomGroupNames, credentials);
		if (!handleResponse(response)) {
			batch(() => {
				setOperatorToEdit(response.Entity);
				setOpenPermissionModal(false);
				handleOnOperatorsModalOpen(Number(operatorId), !isEditMode);
				setIsEditMode(true);
			});
		}

		return response.ResponseStatusCode;
	};

	const onHandleElevatedOperationDelete = async (credentials: OperatorCredential): Promise<ResponseStatusCode> => {
		if (selectedOps.length > 0) {
			const operator = selectedOps[0];

			if (operator.Id === 1) {
				showDeleteErrorModalConfirm(adminErrorModalContent);
			} else {
				const response = await deviceAdminApi.deleteOperator(operator.Id, true, credentials);
				if (response.ResponseStatusCode === ResponseStatusCode.PermissionError) {
					return response.ResponseStatusCode;
				}
				if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation && response.AdditionalResponseInfo === 'elevated credentials') {
					return response.ResponseStatusCode;
				}
				handleDeleteOperationValidationResponse(response, operator, false);
			}
		}
		return ResponseStatusCode.Success;
	};

	const onHandleElevatedOperationAdd = async (credentials: OperatorCredential): Promise<ResponseStatusCode> => {
		const response = await deviceAdminApi.getDomains(credentials);
		if (!handleResponse(response)) {
			const { Entity: ds } = response;
			batch(() => {
				setDomains(ds);
				setOpenPermissionModal(false);
			});
		}

		return ResponseStatusCode.Success;
	};

	const onHandlePermissionModalConfirm = () => {
		batch(() => {
			setIsModalOpen(true);
			setIsOpenPermissionModalFromEdit(false);
			setIsOpenPermissionModalFromAdd(false);
		});
	};

	const onHandlePermissionModalCancel = () => {
		isCancellingOccurring.current = true;
		batch(() => {
			setOperatorToEdit(null);
			setOpenPermissionModal(false);
			setIsOpenPermissionModalFromEdit(false);
			setIsOpenPermissionModalFromAdd(false);
		});
	};

	const onHandlePermissionModalCancelDelete = async () => {
		setElevatedCredentials(null);
		const operator = selectedOps[0];
		const response = await deviceAdminApi.deleteOperator(operator.Id, false, null);
		handleDeleteOperationValidationResponse(response, operator, true);
		notification['warning']({
			message: _('NotRemovedFromWindowsGroup').replace('%', velocityUsersGroupName()),
		});
	};

	const renderOperatorsModal = (): React.ReactNode => {
		if (!isModalOpen) {
			return null;
		}

		const op: OperatorModel = isEditMode
			? operators.find(o => o.Id.toString() === selectedRowKeys[0]?.toString())
			: ({ Id: 0, Name: '', OperatorStatus: false, Bypass2FA: false } as OperatorModel);

		return (
			<OperatorsModal
				isEditMode={isEditMode}
				operator={op}
				onCancel={() => {
					batch(() => {
						setIsModalOpen(false);
						handleOnOperatorsModalClosed();
						setSelectedRowKeys([]);
					});
					unlockOperator();
				}}
				onConfirm={() => {
					batch(() => {
						setIsModalOpen(false);
						handleOnOperatorsModalClosed();
						setSelectedRowKeys([]);
						dispatch(setOperatorsBy(tablePaginationSetting));
					});
					unlockOperator();
				}}
				authPath={authPath}
				domains={domains}
				operatorPropertyModel={operatorToEdit}
			/>
		);
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	const currentNumberOfOperatorsToDelete = useMemo(() => totalOperatorsToDelete - (selectedOps.length - 1), [totalOperatorsToDelete, selectedOps]);

	return (
		<div className={styles.container}>
			{
				<div className={styles.buttonContainer}>
					<ButtonDropdown
						id={'operatorsTableActionDropdown'}
						disabled={disabledActionButtons}
						menuOptions={items.options}
						icon={<CaretDownOutlined />}
						labelIcon={items.labelOrIcon}
						onClickOption={handleActionScope}
					/>
					{isUserAdmin && buttonsBuilder(buttons)}
				</div>
			}
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<Table
					id='operatorsTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					pagination={(isUserAdmin || isUser2FAAdmin) && pagination}
					scroll={scroll}
					size='small'
					className={styles.operatorsTable}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
				/>
			</Spin>
			{renderOperatorsModal()}
			{openPermissionModal && (
				<OperatorPermissionModal
					onConfirm={selectedOps.length > 0 ? null : onHandlePermissionModalConfirm}
					onCancel={selectedOps.length > 0 ? onHandlePermissionModalCancelDelete : onHandlePermissionModalCancel}
					onHandleElevatedOperation={
						selectedOps.length > 0
							? onHandleElevatedOperationDelete
							: isOpenPermissionModalFromAdd
							? onHandleElevatedOperationAdd
							: onHandleElevatedOperation
					}
					authPath={authPath}
				/>
			)}
			{showDeletingLoadingModal && <DeletingLoadingModal current={currentNumberOfOperatorsToDelete} total={totalOperatorsToDelete} />}
		</div>
	);
};

export { Operators };

import { Checkbox, Input, InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { OptionsForLocalKeypadInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import {
	setControllerBasicSetupAction,
	setControllerError,
	setControllerMiscSystemSetupAction,
} from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerBasic, ControllerMisc, ErrorTabs } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectError, selectOptionsForLocalKeypadInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { ErrorMessage, Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../setup.module.scss';

const OptionsForLocalKeypad: React.FC = () => {
	const dispatch = useStoreDispatch();
	const optionsForLocalKeypadInfo: OptionsForLocalKeypadInfoStore = useStoreSelector<OptionsForLocalKeypadInfoStore>(selectOptionsForLocalKeypadInfo);
	const error: ErrorTabs = useStoreSelector<ErrorTabs>(selectError);

	const handleChangeTimeout = (value: ControllerMisc) => {
		const timeout: number = value.LocalKeypadProgrammingTimeout;
		if (!timeout || timeout === undefined || timeout < 2) {
			value.LocalKeypadProgrammingTimeout = 2;
		}

		handleChangeControllerMiscSystemSetup(value);
	};

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	const handleChangeControllerBasicSetup = (value: ControllerBasic) => {
		dispatch(
			setControllerBasicSetupAction({
				...value,
			})
		);
	};

	const handleChangeMasterCode = (value: string) => {
		if (error.systemCode) {
			dispatch(setControllerError({ systemCode: false, message: '' }));
		}
		if (value === '123' || value.length < 3) {
			dispatch(setControllerError({ systemCode: true }));
		}
		handleChangeControllerBasicSetup({ MasterCode: value });
	};

	const controllerErrorMessage: string = error.systemCode ? error.message : '';
	const masterCodeMessage: string = controllerErrorMessage
		? error.message
		: optionsForLocalKeypadInfo.systemCode === '123'
		? _('MasterCodeCanNotBeSetTo123')
		: _('CodeMustBe3To15Digits');

	return (
		<CardSection title={_('OptionsForLocalKeypad')}>
			<div className={styles.optionsForLocalKeypadContainer}>
				<div className={styles.alignThree}>
					<span>{_('Timeout')}:</span>
					<InputNumber
						id='controllerSetupTimeoutInput'
						min={2}
						max={20}
						maxLength={2}
						onChange={value => handleChangeTimeout({ LocalKeypadProgrammingTimeout: Number(value) })}
						value={optionsForLocalKeypadInfo.timeout}
						formatter={noneDecimalFormatter}
					/>
					<span>({`2-20 ${_('Minutes')}`})</span>
				</div>
				<div className={styles.alignTwo}>
					<span>{_('AutoGenCodeLength')}:</span>
					<Select
						id='controllerSetupAutoGenCodeDropdown'
						onChange={value => handleChangeControllerMiscSystemSetup({ LocalKeypadProgrammingAutoGenCode: Number(value) })}
						value={optionsForLocalKeypadInfo.autoGenCodeLength}
						notUseCurrentParse
						options={optionsForLocalKeypadInfo.autoGenCodeLengthOptions.map(x => ({ label: x.Name, value: x.Id }))}
						getPopupContainer={element => element}
					/>
				</div>
				<div className={styles.systemCode}>
					<span>{_('SystemCodeController')}:</span>
					<ErrorMessage message={masterCodeMessage} show={error.systemCode}>
						<Input
							id='controllerSetupSystemCodeInput'
							maxLength={15}
							onChange={e => handleChangeMasterCode(noneDecimalFormatter(e.target.value))}
							value={optionsForLocalKeypadInfo.systemCode}
						/>
					</ErrorMessage>
					<Checkbox
						id='controllerSetupDisableSystemCodeCheckbox'
						disabled={optionsForLocalKeypadInfo.disableOptions}
						onChange={value => handleChangeControllerBasicSetup({ IsDisableMasterCode: value.target.checked })}
						checked={optionsForLocalKeypadInfo.isMasterCodeDisable}>
						{_('Disable')}
					</Checkbox>
				</div>
				<Checkbox
					id='controllerSetupGenCodesCheckbox'
					onChange={value => handleChangeControllerMiscSystemSetup({ LocalKeypadProgrammingAutoGenCodesWithDuress: value.target.checked })}
					checked={optionsForLocalKeypadInfo.genCodesWIthDuressDigit}>
					{_('GenCodesWIthDuressDigit')}
				</Checkbox>
				<Checkbox
					id='controllerSetupEnableLocalSystemCheckbox'
					disabled={optionsForLocalKeypadInfo.disableOptions}
					onChange={value => handleChangeControllerMiscSystemSetup({ LocalKeypadProgrammingEnableCodeReset: value.target.checked })}
					checked={optionsForLocalKeypadInfo.enableLocalSystemCodeReset}>
					{_('EnableLocalSystemReset')}
				</Checkbox>
				<div className={styles.duressDigit}>
					<span className={styles.spanAlign}>{_('DuressDigit')}:</span>
					<Select
						id='controllerSetupDuressDigitDropdown'
						onChange={value => handleChangeControllerMiscSystemSetup({ LocalKeypadProgrammingDuressDigit: Number(value) })}
						value={optionsForLocalKeypadInfo.duressDigit}
						disabled={!optionsForLocalKeypadInfo.genCodesWIthDuressDigit}
						options={optionsForLocalKeypadInfo.duressDigitOptions.map(x => ({ label: x.Name.toString(), value: x.Id }))}
						getPopupContainer={element => element}
					/>
				</div>
				<Checkbox
					id='controllerSetupLocalPrinterCheckbox'
					onChange={value => handleChangeControllerMiscSystemSetup({ LocalKeypadProgrammingPrinterShowsNewCredentialCodes: value.target.checked })}
					checked={optionsForLocalKeypadInfo.localPrinterShowsNewCredentialCodes}>
					{_('LocalPrinterShowsNewCredentialCodes')}
				</Checkbox>
				<Checkbox
					id='controllerSetupReduceAutoGenCheckbox'
					onChange={value => handleChangeControllerMiscSystemSetup({ LocalKeypadProgrammingReduceAutoGenPrintOut: value.target.checked })}
					checked={optionsForLocalKeypadInfo.reduceAutoGenPrintout}>
					{_('ReduceAutoGenPrintout')}
				</Checkbox>
			</div>
		</CardSection>
	);
};

export { OptionsForLocalKeypad };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".general-container--BLsrikg2-Wkc5-yjvm7-s{width:100%}.general-container--BLsrikg2-Wkc5-yjvm7-s .general-grid--l693hlhzjEfILjpoeu0of{display:grid;grid-template-columns:50% 48%;row-gap:30px;column-gap:10px;align-items:center;justify-content:center}.general-container--BLsrikg2-Wkc5-yjvm7-s .general-gridAlignLeft--1PWTUhDAdupH7kuNzr3JGy{display:flex;align-items:center;justify-content:space-between;margin:15px 0px 15px 5px}.general-container--BLsrikg2-Wkc5-yjvm7-s .general-gridRow--3UjBlm-IPkoOVuC7qPEDF7{display:grid;grid-template-rows:62% 38%;row-gap:30px;margin:30px 3px 0px 3px}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/DigiTrac/InputModal/tabs/General/general.module.scss"],"names":[],"mappings":"AAAA,0CACC,UAAA,CACA,+EACC,YAAA,CACA,6BAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAED,yFACC,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,wBAAA,CAED,mFACC,YAAA,CACA,0BAAA,CACA,YAAA,CACA,uBAAA","sourcesContent":[".container {\r\n\twidth: 100%;\r\n\t.grid {\r\n\t\tdisplay: grid;\r\n\t\tgrid-template-columns: 50% 48%;\r\n\t\trow-gap: 30px;\r\n\t\tcolumn-gap: 10px;\r\n\t\talign-items: center;\r\n\t\tjustify-content: center;\r\n\t}\r\n\t.gridAlignLeft {\r\n\t\tdisplay: flex;\r\n\t\talign-items: center;\r\n\t\tjustify-content: space-between;\r\n\t\tmargin: 15px 0px 15px 5px;\r\n\t}\r\n\t.gridRow {\r\n\t\tdisplay: grid;\r\n\t\tgrid-template-rows: 62% 38%;\r\n\t\trow-gap: 30px;\r\n\t\tmargin: 30px 3px 0px 3px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "general-container--BLsrikg2-Wkc5-yjvm7-s",
	"grid": "general-grid--l693hlhzjEfILjpoeu0of",
	"gridAlignLeft": "general-gridAlignLeft--1PWTUhDAdupH7kuNzr3JGy",
	"gridRow": "general-gridRow--3UjBlm-IPkoOVuC7qPEDF7"
};
export default ___CSS_LOADER_EXPORT___;

import { ReadOutlined } from '@ant-design/icons';
import cx from 'classnames';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { ColumnsProps } from '../../../Helper';
import { useStoreDispatch } from '../../../store';
import { setShowAlarmInstructions } from '../../../store/alarm/actions';
import { AlarmTableDataSourceModel, instructionsStyle } from '../helpers';
import { columnKeys, getColumnValue } from './ExpandableRow';
import styles from './virtualTableExpandableRow.module.scss';

type Props = {
	data: AlarmTableDataSourceModel;
	index: number;
	rowColumnsWidth: CSSProperties;
	windowWidth: number;
	columns: ColumnsProps<AlarmTableDataSourceModel>[];
	alwaysShowInstructions: boolean;
	alarmStackingEnabled: boolean;
	isExpandableRow: boolean;
	setSize: (index: number, size: number) => void;
	handleOnToggleItemActive: (itemIndex: number) => void;
};

const component: React.FC<Props> = ({
	data,
	index,
	setSize,
	windowWidth,
	columns,
	rowColumnsWidth,
	alwaysShowInstructions,
	isExpandableRow,
	handleOnToggleItemActive,
}: Props): JSX.Element => {
	const rowRef = useRef<HTMLDivElement>(undefined);
	const dispatch = useStoreDispatch();

	useEffect(() => {
		const { current } = rowRef;
		if (current) {
			setSize(index, current.getBoundingClientRect().height);
		}
	}, [setSize, index, windowWidth]);

	const createRow = () => {
		const [alarmDetail] = data.AlarmDetails;
		const showInstructions: boolean = data.Instructions !== '' && alwaysShowInstructions;
		let instructions: string = data.Instructions;
		let showMore: boolean = true;
		let instructionsData: AlarmTableDataSourceModel = data;
		if (showInstructions) {
			const breakSpace: string[] = instructions.split('\r\n\r\n');
			if (breakSpace.length > 1) {
				instructions = breakSpace[0];
			} else if (instructions.length > 151) {
				instructions = instructions.substring(0, 149);
			} else {
				showMore = false;
			}
			instructionsData = data.HeaderAlarmDetails?.length > 0 ? { ...data, AlarmDetails: [alarmDetail] } : data;
		}
		const stripeClassName: string = index % 2 === 0 ? styles.whiteRow : styles.grayRow;

		const rowSelectedClassName: string = alarmDetail?.IsChecked ? styles.rowSelected : stripeClassName;

		return (
			<div ref={rowRef}>
				<div className={cx(styles.row, rowSelectedClassName)} style={rowColumnsWidth}>
					{columns.map((column: ColumnsProps<AlarmTableDataSourceModel>, columnIndex: number) => (
						<div
							key={`header-${index}-${columnIndex}`}
							className={cx(styles.cell, rowSelectedClassName)}
							onClick={column.dataIndex === columnKeys.CollapseIcon && isExpandableRow ? () => handleOnToggleItemActive(index) : undefined}>
							{column.render(
								getColumnValue({
									column: column.dataIndex,
									alarmDetail,
									data,
									isHeaderRow: isExpandableRow,
									alwaysShowInstructions,
								}),
								data,
								columnIndex
							)}
						</div>
					))}
				</div>
				{showInstructions ? (
					<div className={cx(styles.rowInstruction, rowSelectedClassName)} style={rowColumnsWidth}>
						<div className={styles.instructionCell}>
							{(!isExpandableRow || (isExpandableRow && data.IsExpanded)) &&
								columns.length > 0 &&
								columns[0].render(
									getColumnValue({
										column: columns[0].dataIndex,
										alarmDetail,
										data,
										isHeaderRow: isExpandableRow,
										isInstructionRow: true,
										alwaysShowInstructions,
									}),
									data,
									0
								)}
						</div>
						<div></div>
						<div className={cx(styles.instruction, rowSelectedClassName)}>
							<ReadOutlined style={instructionsStyle} />
							{instructions}
							{showMore && (
								<>
									&hellip;&nbsp;
									<span className={styles.showMore} onClick={() => dispatch(setShowAlarmInstructions(instructionsData))}>
										{_('ShowMore')}
									</span>
								</>
							)}
						</div>
					</div>
				) : undefined}
			</div>
		);
	};

	return <>{createRow()}</>;
};

const Row = React.memo(component);

export { Row };

import React from 'react';
import styles from '../../../expansionRelayModal.module.scss';
import { ControlDelay, ControlMode } from './';
import { DoorDelay } from './DoorDelay/DoorDelay';
import { DoorMode } from './DoorMode/DoorMode';

type Props = {
	useDoorContext: boolean;
};

const Timers: React.FC<Props> = ({ useDoorContext }) => {
	return (
		<div className={styles.section}>
			<div className={styles.options}>
				<div className={styles.sectionContainer}>
					<fieldset>
						<legend className={styles.legend}>{_('Timers')}</legend>
						<div>
							{useDoorContext && (
								<>
									<DoorMode />
									<DoorDelay />
								</>
							)}
							<ControlMode useDoorContext={useDoorContext}></ControlMode>
							<ControlDelay useDoorContext={useDoorContext}></ControlDelay>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	);
};

export { Timers };

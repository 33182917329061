import { Table } from 'antd';
import { ColumnsType, GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { buildColumn, ColumnsProps, ScrollType } from '../../../../../Helper';
import { ControllerDoor } from '../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../common';
import { DataType } from '../ConfigurationDoors';
import styles from '../doorsContainer.module.scss';

type Props = {
	onChangeSelection: (selectedRecords: DataType[]) => void;
	doors: DataType[];
	disableSelection: boolean;
	doorsSelectedKeys: React.Key[];
	loading: boolean;
};

const scroll: ScrollType = { y: 131 };
const maxLength = 32;

const mergedColumns = (columns: ColumnsProps<ControllerDoor>[]) =>
	columns.map(col => ({
		...col,
		onCell: (record: ControllerDoor) => ({
			record,
		}),
	})) as ColumnsType;

const Doors: React.FC<Props> = ({ onChangeSelection, doors, disableSelection, doorsSelectedKeys, loading }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	useEffect(() => {
		const refreshSelectedKeys = selectedRowKeys.filter(x => doors.findIndex(w => w.key === x) >= 0);
		setSelectedRowKeys(refreshSelectedKeys);
	}, [JSON.stringify(doors)]);

	const columns: ColumnsProps<ControllerDoor>[] = [
		{
			...buildColumn(_('DoorName'), 'DoorName', undefined, 'start'),
			sorter: (a, b) => a.DoorName.localeCompare(b.DoorName),
			...SearchColumn({ maxLength, dataIndex: 'DoorName', reset: false, label: _('DoorName') }),
		},
	];

	const disableSelectionRow = (record: DataType) => disableSelection && !doorsSelectedKeys.some(x => x === record.key);

	const handleOnSelectRow = (record: DataType) => {
		const findKey = selectedRowKeys.indexOf(record.DoorId);
		let findDoors = [];
		if (~findKey) {
			const cloneState = selectedRowKeys;
			cloneState.splice(findKey, 1);
			setSelectedRowKeys([...cloneState]);
			findDoors = doors.filter(x => cloneState.indexOf(x.DoorId) >= 0);
		} else {
			if (disableSelectionRow(record)) {
				return;
			}
			const newKeys = [...selectedRowKeys, record.DoorId];
			setSelectedRowKeys([...newKeys]);
			findDoors = doors.filter(x => newKeys.indexOf(x.DoorId) >= 0);
		}
		onChangeSelection(findDoors);
	};

	const rowSelection: TableRowSelection<DataType> = {
		type: 'checkbox',
		selectedRowKeys,
		hideSelectAll: true,
		getCheckboxProps: (record: DataType) => ({
			disabled: disableSelectionRow(record),
		}),
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRowKeys(selectedRowKeys);
			onChangeSelection(selectedRows);
		},
	};

	const rowKey: GetRowKey<DataType> = record => record.DoorId;

	return (
		<div className={styles.containerTable}>
			<Table
				id='photoCallUpConfigurationControllerDoorsTable'
				onRow={(record: DataType) => ({
					onClick: () => handleOnSelectRow(record),
					title: disableSelectionRow(record) ? _('Max10DoorsSelected') : '',
				})}
				scroll={scroll}
				pagination={false}
				className={styles.statusWidgetTable}
				rowSelection={rowSelection}
				columns={mergedColumns(columns) as ColumnsType}
				size='small'
				dataSource={doors}
				loading={{ tip: _('Loading'), spinning: loading }}
				rowKey={rowKey}
				onHeaderRow={() => ({ id: 'photoCallUpConfigurationControllerDoorsTableHeader' })}
				getPopupContainer={() => document.getElementById('photoCallUpConfigurationControllerDoorsTableHeader')}
			/>
		</div>
	);
};

const memoizedComponent = React.memo(Doors);
export { memoizedComponent as Doors };

import { Button, Input } from 'antd';
import cx from 'classnames';
import React, { AriaAttributes, useEffect, useState } from 'react';
import { filterOption, removeCommasAndPercentSign } from '../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../api';
import { Modal, NotificationStatus, Select } from '../../../../../../components/common';
import { ComponentPermission, SecuredComponents, User, getPermissionErrorMessage } from '../../../../../../model/AccountModel';
import { PaginationSetting, SorterConfig } from '../../../../../../model/CommonModel';
import { GrandMasterTimeZones, RowGrandMasterTimeZone, StandardTimeZoneAndMasterTimeZone, TimeZoneType } from '../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { setCurrentModalTimeZone } from '../../../../../../store/common/actions';
import { selectItemSearchedPagination } from '../../../../../../store/common/selectors';
import { setGrandTimeZonesBy } from '../../../../../../store/deviceControl/actions';
import { selectVelocityConfigurationFilterMode } from '../../../../../../store/deviceControl/selectors';
import { RootReducer } from '../../../../../../store/rootReducer';
import styles from './grandMaster.module.scss';

const user: User = getUser();
const componentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Grand_Master_Time_Zone);
const maxInputLength: number = 50;
const mainDivContainerId: string = 'grandMasterTimeZoneContainer';

type Props = {
	onEntityAction?: (genericTimeZoneId: number) => void;
	isPaginationItemSelected: boolean;
	currentPage: number;
	currentPageSize: number;
	sorterConfig: SorterConfig;
	setShouldResetSearchColumn: () => void;
	setRedirectPage: (value: boolean) => void;
};

const GrandMaster: React.FC<Props> = ({
	onEntityAction,
	setShouldResetSearchColumn,
	setRedirectPage,
	isPaginationItemSelected,
	currentPage,
	currentPageSize,
	sorterConfig,
}) => {
	const dispatch = useStoreDispatch();

	const [masterAndStandardTimeZones, setMasterAndStandardTimeZones] = useState([] as StandardTimeZoneAndMasterTimeZone[]);
	const [grandMasterTimeZonesDropDowns, setGrandMasterTimeZonesDropDowns] = useState([] as RowGrandMasterTimeZone[]);
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');
	const [emptyNameError, setEmptyNameError] = useState('');

	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const itemSearchedPagination: string = useStoreSelector<string>(selectItemSearchedPagination);

	const editedEntity: GrandMasterTimeZones = useStoreSelector(
		(state: RootReducer): GrandMasterTimeZones => state.commonReducer.currentTimeZoneModal.entity as GrandMasterTimeZones
	);

	const fakeTimeZone: StandardTimeZoneAndMasterTimeZone = {
		Id: 0,
		Name: ' ',
		TimeZoneType: null,
		GenericTimeZoneId: 0,
		InUse: false,
	};

	const init = async () => {
		const responseOptions = await deviceAdminApi.getStandardTimeZoneAndMasterTimeZones();
		setMasterAndStandardTimeZones([...responseOptions]);
		const rowRepeat = 8;
		if (editedEntity) {
			deviceAdminApi.editGrandMasterTimeZoneDialog(editedEntity.Id).then(res => {
				const grandMasterTimeZone = res[0];
				const mixedTimeZones = grandMasterTimeZone.MixedTimeZonesList.map<StandardTimeZoneAndMasterTimeZone>(x => ({
					Name: x.Name,
					GenericTimeZoneId: x.GenericTimeZoneId,
					TimeZoneType: x.Type,
					Id: x.Id,
					InUse: x.InUse,
				}));

				const mergeArray = [...mixedTimeZones, ...Array(rowRepeat - grandMasterTimeZone.MixedTimeZonesList.length).fill(fakeTimeZone)];
				setGrandMasterTimeZonesDropDowns([...mergeArray.map<RowGrandMasterTimeZone>((x, index) => ({ ...x, key: index }))]);
				setName(grandMasterTimeZone.Name);
			});
		} else {
			const defaultRows: RowGrandMasterTimeZone[] = Array(rowRepeat)
				.fill(fakeTimeZone)
				.flatMap<RowGrandMasterTimeZone>((x, index) => ({ ...x, key: index }));
			setGrandMasterTimeZonesDropDowns([...defaultRows]);
		}
	};

	useEffect(() => {
		init();

		deviceAdminApi.getErrorMessages(SecuredComponents.Grand_Master_Time_Zone).then(res => setEmptyNameError(res.EmptyName));
		window.addEventListener('beforeunload', handleOnCLose);

		return () => {
			window.removeEventListener('beforeunload', handleOnCLose);
		};
	}, []);

	const handleOnCLose = () => {
		deviceAdminApi.removeTimeZoneLocks(TimeZoneType.Grand);
		dispatch(setCurrentModalTimeZone({ timeZoneType: null, entity: null }));
	};

	const handleOnSelectTimeZone = (key: number, value: number) => {
		const findTimeZoneToMap = value === 0 ? fakeTimeZone : masterAndStandardTimeZones.find(x => x.GenericTimeZoneId === value);
		const cloneRow = grandMasterTimeZonesDropDowns;
		const newRows = cloneRow.map(x => (x.key === key ? { ...findTimeZoneToMap, key } : x));
		setGrandMasterTimeZonesDropDowns([...newRows]);
	};

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		setName(value);
		setNameError('');
	};

	const shouldResetSearchColumn = editedEntity => {
		if (isPaginationItemSelected && editedEntity && isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const redirectPage = editedEntity => {
		if (editedEntity) {
			setRedirectPage(true);
		}
	};

	const handleOnSave = () => {
		if (!name || name.trim() === '') {
			setNameError(emptyNameError);
			return;
		}

		const timeZonesList = grandMasterTimeZonesDropDowns.filter(x => x.Id !== 0);

		const action =
			editedEntity === null
				? deviceAdminApi.addGrandMasterTimeZone({
						Id: 0,
						Name: name.trim(),
						StandardTimeZoneAndMasterTimeZones: timeZonesList,
				  })
				: deviceAdminApi.editGrandMasterTimeZone({
						Id: editedEntity.Id,
						Name: name.trim(),
						StandardTimeZoneAndMasterTimeZones: timeZonesList,
				  });

		action.then(res =>
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {
					dispatch(
						setGrandTimeZonesBy({
							PageNumber: currentPage,
							PageSize: currentPageSize,
							SortDirection: sorterConfig.direction,
							SearchedValue: itemSearchedPagination,
						} as PaginationSetting)
					);
					onEntityAction(res.ResponseObjectId);
					shouldResetSearchColumn(editedEntity);
					redirectPage(editedEntity);
					handleOnCLose();
				},
				onFailedValidation: () => setNameError(res.ErrorMessage),
			})
		);
	};

	const permissionsEnabled = editedEntity === null ? componentPermission.canAdd : componentPermission.canUpdate;
	const disabled = !permissionsEnabled;

	const getCurrentOptions = (currentValue: StandardTimeZoneAndMasterTimeZone) => {
		const selectAllNotSelected = masterAndStandardTimeZones.filter(
			x =>
				grandMasterTimeZonesDropDowns.findIndex(
					w => w.GenericTimeZoneId === x.GenericTimeZoneId && w.GenericTimeZoneId !== currentValue.GenericTimeZoneId
				) < 0
		);
		return [fakeTimeZone, ...selectAllNotSelected];
	};

	const grandMasterTimeZoneNameInputId = 'grandMasterTimeZoneNameInput';
	const label: AriaAttributes = {
		'aria-label': 'TimeZone',
	};

	return (
		<Modal
			width='900px'
			footer={[
				<Button
					id='grandMasterTimeZoneSaveButton'
					key='save'
					title={getPermissionErrorMessage(permissionsEnabled)}
					type='primary'
					disabled={disabled}
					onClick={() => handleOnSave()}>
					{_('SaveChanges')}
				</Button>,
				<Button id='grandMasterTimeZoneCancelButton' key='cancel' onClick={() => handleOnCLose()}>
					{_('Cancel')}
				</Button>,
			]}
			onClickOk={() => null}
			onCancel={() => handleOnCLose()}
			customZoomClass={styles.withModalZoom}
			title={editedEntity ? _('EditGrandMasterTimeZone') : _('AddGrandMasterTimeZone')}
			visible={true}>
			<div className={styles.container} id={mainDivContainerId}>
				<div className={cx({ [styles.error]: nameError })}>
					<label htmlFor={grandMasterTimeZoneNameInputId}>{_('Name')}</label>
					<Input id={grandMasterTimeZoneNameInputId} onChange={handleOnChangeName} value={name} maxLength={50} />
					<label id='grandMasterTimeZoneErrorMessage' htmlFor={grandMasterTimeZoneNameInputId}>
						{nameError}
					</label>
				</div>
				<label htmlFor='grandMasterTimeZoneTimeZonesDropdown'>{_('MasterStandardTimeZones')}</label>
				{grandMasterTimeZonesDropDowns.flatMap((record, index) => (
					<Select
						id={`grandMasterTimeZoneTimeZonesDropdown-${index}`}
						key={index}
						onChange={value => handleOnSelectTimeZone(index, Number(value))}
						value={record.InUse ? record.Name : record.GenericTimeZoneId.toString()}
						getPopupContainer={() => document.getElementById(mainDivContainerId)}
						options={getCurrentOptions(record).map((x, optionIndex) => ({
							label: x.Name,
							value: x.GenericTimeZoneId.toString(),
							id: `grandMasterTimeZoneDropdownOptions-${index}-${optionIndex}`,
						}))}
						disabled={record.InUse}
						showSearch={true}
						filterOption={filterOption}
						maxSearchLength={maxInputLength}
						ariaAttributes={{ 'aria-label': `${_('TimeZone')} ${index}` }}
					/>
				))}
			</div>
		</Modal>
	);
};

export { GrandMaster };

import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { Select } from '../../../../../../common';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setCCOTZThreatLevel, setReaderThreatLevel, StoreContext } from '../../../contextReader';
import styles from '../../General/general.module.scss';

type Props = {
	useDoorContext: boolean;
};

const ThreatLevel: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					readerThreatLevels,
					selections: { readerThreatLevel, cCOTZThreatLevel },
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				readerThreatLevels,
				selections: { readerThreatLevel, cCOTZThreatLevel },
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [readerLevels, setReaderLevels] = useState<SelectOptions<string>[]>([]);

	useEffect(() => {
		setReaderLevels(readerThreatLevels.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [readerThreatLevels]);

	const handleOnSetReaderThreatLevel = (value: string) => {
		useDoorContext ? dispatcherDoor(setReaderThreatLevel(Number(value))) : dispatcher(setReaderThreatLevel(Number(value)));
	};

	const handleOnSetCCOTZThreatLevel = (value: string) => {
		useDoorContext ? dispatcherDoor(setCCOTZThreatLevel(Number(value))) : dispatcher(setCCOTZThreatLevel(Number(value)));
	};

	return (
		<>
			<div className={styles.sections}>
				<span>{_('DisableReaderAbove')}</span>
				<div>
					<Select
						className={styles.select}
						options={readerLevels}
						onChange={handleOnSetReaderThreatLevel}
						key='readerThreatLevel'
						id='readerModalDisableReaderAboveSelect'
						value={readerThreatLevel.toString()}
						getPopupContainer={() => document.getElementById('generalReaderContainer')}
					/>
				</div>
			</div>
			<div className={styles.sections}>
				<span>{_('DisableCCOTZAbove')}</span>
				<div>
					<Select
						className={styles.select}
						options={readerLevels}
						onChange={handleOnSetCCOTZThreatLevel}
						key='ccotzThreatLevel'
						id='readerModalDisableCCOTZAboveSelect'
						value={cCOTZThreatLevel.toString()}
						getPopupContainer={() => document.getElementById('generalReaderContainer')}
					/>
				</div>
			</div>
		</>
	);
};

export { ThreatLevel };

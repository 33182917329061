import { TreeSelect } from 'antd';
import { TreeNodeProps } from 'rc-tree';
import React, { useEffect, useRef, useState } from 'react';
import { PersonGroups, TreeViewPersonGroupModel } from '../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setSelectedPersonGroup } from '../../../../store/enrollment/actions';
import { selectPersonGroups, selectSelectedPersonGroup } from '../../../../store/enrollment/selectors';
import styles from '../enrollmentTab.module.scss';

const { TreeNode } = TreeSelect;
type Props = {
	onSelectPersonGroup: () => void;
};
const enrollmentPersonGroupsSelectId: string = 'enrollmentPersonGroupsSelect';

const PersonGroupSelect: React.FC<Props> = ({ onSelectPersonGroup }) => {
	const dispatch = useStoreDispatch();
	const [treeData, setTreeData] = useState<React.FC<TreeNodeProps>[]>([]);
	const [selectedNode, setSelectedNode] = useState<string>('');
	const isFirstRun = useRef<boolean>(true);
	const personGroups: PersonGroups[] = useStoreSelector<PersonGroups[]>(selectPersonGroups);
	const selectedPersonGroups: TreeViewPersonGroupModel = useStoreSelector<TreeViewPersonGroupModel>(selectSelectedPersonGroup);

	useEffect(() => {
		if (personGroups.length > 0) {
			setTreeData(getRenderTreeNodes(personGroups));

			if (!selectedPersonGroups) {
				const index: number = personGroups.length === 1 ? 0 : 1;
				const firstPersonGroupToLoad: TreeViewPersonGroupModel = {
					GroupId: personGroups[index].GroupId,
					FolderId: personGroups[index].FolderId,
					Name: personGroups[index].Name,
				};

				dispatch(setSelectedPersonGroup(firstPersonGroupToLoad));
				setSelectedNode(`${firstPersonGroupToLoad.GroupId},${firstPersonGroupToLoad.FolderId}`);
			} else if (selectedPersonGroups && isFirstRun.current) {
				if (selectedPersonGroups.GroupId === 0) {
					setSelectedSubGroupNode(personGroups, selectedPersonGroups);
				} else {
					setSelectedGroupNode(personGroups, selectedPersonGroups);
				}
			}
		}
	}, [personGroups]);

	const setSelectedGroupNode = (currentPersonGroups: PersonGroups[], currentSelectedPersonGroup: TreeViewPersonGroupModel): void => {
		const groupIndex: number = currentPersonGroups.findIndex(x => x.GroupId === currentSelectedPersonGroup.GroupId);
		if (groupIndex === -1) {
			const firstGroupIndex: number = currentPersonGroups.length === 1 ? 0 : 1;
			setSelectedNode(`${currentPersonGroups[firstGroupIndex].GroupId},${currentPersonGroups[firstGroupIndex].FolderId}`);
		} else {
			setSelectedNode(`${currentSelectedPersonGroup.GroupId},${currentSelectedPersonGroup.FolderId}`);
		}
	};

	const setSelectedSubGroupNode = (currentPersonGroups: PersonGroups[], currentSelectedPersonGroup: TreeViewPersonGroupModel): void => {
		currentPersonGroups.every((personGroup: PersonGroups) => {
			let continueLoop: boolean = true;

			const previousSubGroup: TreeViewPersonGroupModel = personGroup.ChildGroups.find(
				(subGroup: TreeViewPersonGroupModel) => subGroup.GroupId === 0 && subGroup.FolderId === currentSelectedPersonGroup.FolderId
			);

			if (previousSubGroup) {
				setSelectedNode(`${previousSubGroup.GroupId},${previousSubGroup.FolderId}`);
				continueLoop = false;
			} else if (personGroup.ChildGroups.length > 0) {
				setSelectedSubGroupNode(personGroup.ChildGroups as PersonGroups[], currentSelectedPersonGroup);
			}

			return continueLoop;
		});
	};

	const handleOnSelectGroup = (value, node) => {
		if (value) {
			const groupValues: string[] = value.split(',');
			const selectedGroup: TreeViewPersonGroupModel = {
				GroupId: Number(groupValues[0]),
				FolderId: Number(groupValues[1]),
				Name: node.title,
			};

			dispatch(setSelectedPersonGroup(selectedGroup));
			onSelectPersonGroup();
			setSelectedNode(value);
		}
	};

	const getRenderTreeNodes = personGroups =>
		personGroups.map(group => {
			const groupValue: string = `${group.GroupId},${group.FolderId}`;
			if (group.ChildGroups) {
				return (
					<TreeNode value={groupValue} key={groupValue} title={group.Name}>
						{getRenderTreeNodes(group.ChildGroups)}
					</TreeNode>
				);
			}
			return <TreeNode value={groupValue} key={groupValue} title={group.Name} />;
		});

	return (
		<div>
			<label htmlFor={enrollmentPersonGroupsSelectId}>{_('PersonGroups')}</label>
			<TreeSelect
				id={enrollmentPersonGroupsSelectId}
				className={styles.personGroupSelect}
				popupClassName={styles.personGroupDropdown}
				onSelect={handleOnSelectGroup}
				value={selectedNode}>
				{treeData}
			</TreeSelect>
		</div>
	);
};

export { PersonGroupSelect };

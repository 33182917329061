import { Checkbox, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './rqeEnabled.module.scss';

type Props = {
	useDoorContext: boolean;
};

const RQEEnabled: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleSelectCheckBox = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ RQEEnabled: e.target.checked }));
	};

	const handleSelectRadio = (e: RadioChangeEvent) => {
		inputContext.dispatcher(setInputAction({ RQEType: e.target.value }));
	};

	const groupDisabled: boolean = !inputContext.context?.input?.RQEEnabled || inputContext.context?.input?.LineModuleId === 0;

	return (
		<div className={styles.container}>
			<fieldset>
				<legend className={styles.legend}>
					<Checkbox
						id='RQEEnabled'
						checked={inputContext.context?.input?.RQEEnabled}
						onChange={handleSelectCheckBox}
						disabled={inputContext.context?.input?.LineModuleId === 0 || inputContext.context?.input?.inputRQEDisabled}>
						{_('RQEEnabled')}
					</Checkbox>
				</legend>
				<div className={styles.content}>
					<Radio.Group
						value={inputContext.context?.input?.RQEType}
						onChange={inputContext.context?.input?.inputRQEDisabled ? null : handleSelectRadio}>
						<Radio id='TriggerMaskOnly' disabled={groupDisabled} value={0} className={styles.radioGroupVertical}>
							{_('TriggerMaskOnly')}
						</Radio>
						{useDoorContext && (
							<Radio id='TriggerRelayAndMaskOnly' disabled={groupDisabled} value={1} className={styles.radioGroupVertical}>
								{_('TriggerRelayAndMaskOnly')}
							</Radio>
						)}
						<Radio id='RetriggerMaskOnly' disabled={groupDisabled} value={2} className={styles.radioGroupVertical}>
							{_('RetriggerMaskOnly')}
						</Radio>
						{useDoorContext && (
							<Radio id='RetriggerRelayAndMaskOnly' disabled={groupDisabled} value={3} className={styles.radioGroupVertical}>
								{_('RetriggerRelayAndMaskOnly')}
							</Radio>
						)}
					</Radio.Group>
				</div>
			</fieldset>
		</div>
	);
};

export { RQEEnabled };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".photo-pagination-div{border:1px solid #84aad0;padding:19px 32px 16px 32px !important;margin-bottom:24px !important}.photo-pagination>.ant-spin-nested-loading>.ant-spin-container>.ant-row{margin-left:0px !important;margin-right:0px !important;display:flex !important;justify-content:space-around !important}.photo-pagination>.ant-spin-nested-loading>.ant-spin-container>.ant-row>div>.ant-col{padding:0px 0px 0px 0px !important}", "",{"version":3,"sources":["webpack://src/components/photocallup/Pagination/pagination.scss"],"names":[],"mappings":"AAAA,sBACC,wBAAA,CACA,sCAAA,CACA,6BAAA,CAGD,wEACC,0BAAA,CACA,2BAAA,CACA,uBAAA,CACA,uCAAA,CAED,qFACC,kCAAA","sourcesContent":[".photo-pagination-div {\r\n\tborder: 1px solid #84aad0;\r\n\tpadding: 19px 32px 16px 32px !important;\r\n\tmargin-bottom: 24px !important;\r\n}\r\n\r\n.photo-pagination > .ant-spin-nested-loading > .ant-spin-container > .ant-row {\r\n\tmargin-left: 0px !important;\r\n\tmargin-right: 0px !important;\r\n\tdisplay: flex !important;\r\n\tjustify-content: space-around !important;\r\n}\r\n.photo-pagination > .ant-spin-nested-loading > .ant-spin-container > .ant-row > div > .ant-col {\r\n\tpadding: 0px 0px 0px 0px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photo-pagination-div": "photo-pagination-div",
	"photo-pagination": "photo-pagination",
	"ant-spin-nested-loading": "ant-spin-nested-loading",
	"ant-spin-container": "ant-spin-container",
	"ant-row": "ant-row",
	"ant-col": "ant-col"
};
export default ___CSS_LOADER_EXPORT___;

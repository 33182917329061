import { Button } from 'antd';
import React, { useContext } from 'react';
import { searchLockedValidation } from '../../../../../../../Helper';
import { PortStoreContext, setOpenSearchModal } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const Search: React.FC<Props> = () => {
	const {
		portState: { protocol },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleClick = async () => {
		const isSNIBLocked = await searchLockedValidation();

		if (!isSNIBLocked) {
			dispatcher(setOpenSearchModal(true));
		}
	};

	return (
		<Button
			id='portGeneralSearchButton'
			key='search'
			type='primary'
			onClick={() => handleClick()}
			className={styles.searchButton}
			disabled={protocol === 2}>
			{_('Search')}
		</Button>
	);
};

export { Search };

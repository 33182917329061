import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Form, Input } from 'antd';
import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { canSaveControllerControlZones, getTimeZoneInfoByList, handleResponse, removeCommasAndPercentSign } from '../../../../../../../Helper';
import { MasterControlZonesEditor, TimeZoneInfo, UpdateControlZone } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setOpenTimeZones } from '../../../../../../../store/deviceControl/controller/actions';
import { seMasterControlZoneEditor, setControlZonesHasChangedName } from '../../../../../../../store/deviceControl/controlZones/actions';
import { Select } from '../../../../../../common';
import styles from '../controlZones.module.scss';
import {
	selectorControllerId,
	selectorControllerTimeZoneMap,
	selectorGlobalId,
	selectorMasterControlZones,
	selectorMasterControlZonesEditor,
	selectorMasterZonesById,
	selectorNewTimeZoneAdded,
	selectorThreatLevels,
	selectorTimeZoneModal,
	selectorTimeZones,
} from '../selectors';
import { Confirmation } from './Confirmation/Confirmation';
import { StandardZones } from './StandardZones/StandardZones';

const { Search } = Input;
const canSave = canSaveControllerControlZones();

const MasterControlZones: React.FC = ({ children }) => {
	const masterControlZonesEditor = useStoreSelector(selectorMasterControlZonesEditor);
	const masterControlThreatLevel = useStoreSelector(selectorThreatLevels);
	const masterControlGlobalId = useStoreSelector(selectorGlobalId);
	const masterControlTimeZones = useStoreSelector(selectorTimeZones);
	const masterControlById = useStoreSelector(selectorMasterZonesById);
	const masterControlZonesIndexes = useStoreSelector(selectorMasterControlZones);
	const controllerId = useStoreSelector(selectorControllerId);
	const timeZonesMap = useStoreSelector(selectorControllerTimeZoneMap);
	const newTimeZoneAdded = useStoreSelector(selectorNewTimeZoneAdded);
	const newTimeZonModal = useStoreSelector(selectorTimeZoneModal);

	const dispatch = useStoreDispatch();

	const [currentMasterZoneSelected, setCurrentMasterZoneSelected] = useState<Partial<MasterControlZonesEditor>>(
		masterControlZonesEditor[masterControlById[0]]
	);
	const [filterValues, setFilterValues] = useState<MasterControlZonesEditor[]>([]);
	const [nextMasterControlZone, setNextMasterControlZone] = useState<number>(-1);
	const [form] = Form.useForm<{ name: string; description: string; timeZone: number }>();

	useEffect(() => {
		if (newTimeZonModal?.propertyType === 'MasterControlZones') {
			form.setFields([
				{
					name: 'timeZone',
					errors: [],
					value: newTimeZoneAdded,
				},
			]);
		}
	}, [newTimeZoneAdded]);

	const handleMasterControlZoneEditor = (value: Partial<MasterControlZonesEditor>) => {
		const actionToDispatch = seMasterControlZoneEditor({ propertyIndex: value.Index.toString(), value: { ...value, Dirty: true } });
		dispatch(actionToDispatch);
	};

	const handleChangeCurrentMasterControlZoneEditor = (value: Partial<MasterControlZonesEditor>) => {
		setCurrentMasterZoneSelected(prevState => ({ ...prevState, ...value, Dirty: true }));
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		const filterSearching = value
			? Object.values(masterControlZonesEditor).filter(
					values => values.Index !== currentMasterZoneSelected.Index && values.Name.toLocaleLowerCase().includes(value.toString().toLocaleLowerCase())
			  )
			: [];
		setFilterValues([...filterSearching]);
	};

	const handleSelectMasterControlZone = (value: string) => {
		if (currentMasterZoneSelected.Dirty) {
			setNextMasterControlZone(Number(value));
		} else {
			const findMasterZone = masterControlZonesEditor[Number(value)];
			setCurrentMasterZoneSelected({ ...findMasterZone, Dirty: false });
			form.setFields([
				{
					name: 'name',
					errors: [],
					value: findMasterZone.Name,
				},
				{
					name: 'description',
					errors: [],
					value: findMasterZone.Description,
				},
				{
					name: 'timeZone',
					errors: [],
					value: findMasterZone.GenericTimeZoneId,
				},
			]);
		}
	};

	const handleSaveChanges = async () => {
		const row = await form.getFieldsValue();
		form.setFields([
			{
				name: 'name',
				errors: [],
			},
			{
				name: 'timeZone',
				errors: [],
			},
		]);
		const masterZoneUpdated = {
			...(currentMasterZoneSelected as MasterControlZonesEditor),
			Name: row.name,
			Description: row.description,
			GenericTimeZoneId: row.timeZone,
		};

		const findMasterZone = nextMasterControlZone > -1 ? masterControlZonesEditor[nextMasterControlZone] : masterZoneUpdated;

		const masterControlZone: UpdateControlZone = {
			ControllerId: controllerId,
			ControllerTimeZoneMap: timeZonesMap,
			GenericTimeZones: masterControlTimeZones,
			MasterControlZone: {
				...masterZoneUpdated,
			},
			StandardControlZone: null,
		};
		const response = await deviceAdminApi.updateMasterControlZone(masterControlZone);

		if (response.ErrorMessage) {
			form.setFields([
				{
					name: response.ErrorMessage.includes('TZ') ? 'timeZone' : 'name',
					errors: [response.ErrorMessage],
				},
			]);
			return;
		}
		if (!handleResponse(response)) {
			if (currentMasterZoneSelected.Name !== row.name) {
				dispatch(setControlZonesHasChangedName(true));
			}
			//assign before state change
			handleMasterControlZoneEditor({ ...masterZoneUpdated });
			setCurrentMasterZoneSelected({ ...findMasterZone, Dirty: false });
			form.setFields([
				{
					name: 'name',
					errors: [],
					value: findMasterZone.Name,
				},
				{
					name: 'description',
					errors: [],
					value: findMasterZone.Description,
				},
				{
					name: 'timeZone',
					errors: [],
					value: findMasterZone.GenericTimeZoneId,
				},
			]);
			setNextMasterControlZone(-1);
		}
	};

	const handleClickNoConfirmation = () => {
		const findMasterZone = masterControlZonesEditor[nextMasterControlZone];
		setCurrentMasterZoneSelected({ ...findMasterZone, Dirty: false });
		setNextMasterControlZone(-1);
		form.setFields([
			{
				name: 'name',
				errors: [],
				value: findMasterZone.Name,
			},
			{
				name: 'description',
				errors: [],
				value: findMasterZone.Description,
			},
			{
				name: 'timeZone',
				errors: [],
				value: findMasterZone.GenericTimeZoneId,
			},
		]);
	};

	const handleChangeAction = useCallback((value: Partial<MasterControlZonesEditor>) => {
		handleChangeCurrentMasterControlZoneEditor({ ...value });
	}, []);

	const masterZoneSelected = currentMasterZoneSelected;

	const handleOnChangeName = (e: React.ChangeEvent<HTMLFormElement>) => removeCommasAndPercentSign(e.target.value);

	const timeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(currentMasterZoneSelected.GenericTimeZoneId, masterControlTimeZones);

	return (
		<Form
			onFieldsChange={() => setCurrentMasterZoneSelected(prevState => ({ ...prevState, Dirty: true }))}
			form={form}
			component={false}
			initialValues={{
				name: currentMasterZoneSelected.Name,
				description: currentMasterZoneSelected.Description,
				timeZone: currentMasterZoneSelected.GenericTimeZoneId,
			}}>
			<div className={styles.flexAlign}>
				<Card style={{ width: 451 }}>
					<div className={styles.masterControlZones}>
						<div className={styles.search}>
							<Search placeholder={_('SearchByName')} onChange={handleSearch} />
						</div>
						<div className={cx(styles.searchArea, { [styles.searchValues]: filterValues.length > 0 })}>
							{filterValues.length > 0 ? (
								filterValues.map(x => (
									<label
										key={x.Index}
										onClick={() => {
											handleSelectMasterControlZone(x.Index.toString());
										}}>
										{x.Name}
									</label>
								))
							) : (
								<>
									<SearchOutlined style={{ fontSize: 42, color: '#e3e3e3' }} />
									<label>{_('SearchResultsWillAppearHere')}</label>
								</>
							)}
						</div>
						<Divider />
						<div className={styles.gridAlign}>
							<div>
								<label>{_('MasterControlZone')} #</label>
								<Select
									onChange={handleSelectMasterControlZone}
									notUseCurrentParse
									value={masterZoneSelected.Index}
									options={masterControlZonesIndexes.map(x => ({ value: x.Id, label: x.Name }))}
								/>
							</div>
							<div>
								<label>{_('Name')}:</label>
								<div>
									<Form.Item name='name' getValueFromEvent={handleOnChangeName}>
										<Input maxLength={32} />
									</Form.Item>
								</div>
							</div>
							<div>
								<label>{_('Description')}:</label>
								<Form.Item name='description' noStyle>
									<Input maxLength={32} />
								</Form.Item>
							</div>
							<div>
								<label>{_('TimeZone')}:</label>
								<Form.Item
									name='timeZone'
									getValueFromEvent={value => {
										const timeZoneId = Number(value);
										if (timeZoneId === 0) {
											return currentMasterZoneSelected.GenericTimeZoneId;
										}
										return timeZoneId;
									}}>
									<Select
										notUseCurrentParse
										options={timeZoneInfo.TimeZoneList}
										onChange={value => {
											if (Number(value) === 0) {
												dispatch(
													setOpenTimeZones({
														index: currentMasterZoneSelected.Index,
														open: true,
														propertyType: 'MasterControlZones',
													})
												);
											}
										}}
										disabled={timeZoneInfo.IsUnpermitted}
									/>
								</Form.Item>
							</div>
							<div>
								<label>{_('SetThreatLevelTo')}: </label>
								<Select
									onChange={value => handleChangeCurrentMasterControlZoneEditor({ SecurityLevel: Number(value) })}
									notUseCurrentParse
									value={masterZoneSelected.SecurityLevel}
									options={masterControlThreatLevel.map(x => ({ value: x.Id, label: x.Name }))}
								/>
							</div>
							<div>
								<label>{_('MapToGlobalID')}: </label>
								<Select
									onChange={value => handleChangeCurrentMasterControlZoneEditor({ GlobalId: Number(value) })}
									notUseCurrentParse
									value={masterZoneSelected.GlobalId}
									options={masterControlGlobalId.map(x => ({ value: x.Id, label: x.Name }))}
								/>
							</div>
							<div>
								<Checkbox
									onChange={e => handleChangeCurrentMasterControlZoneEditor({ Tag: e.target.checked })}
									checked={masterZoneSelected.Tag}>
									{_('Tag')}
								</Checkbox>
								<Checkbox
									onChange={e => handleChangeCurrentMasterControlZoneEditor({ BroadcastGlobally: e.target.checked })}
									checked={masterZoneSelected.BroadcastGlobally}>
									{_('BroadcastGlobally')}
								</Checkbox>
								<Checkbox
									onChange={e => handleChangeCurrentMasterControlZoneEditor({ Alert: e.target.checked })}
									checked={masterZoneSelected.Alert}>
									{_('Alert')}
								</Checkbox>
								<Checkbox
									onChange={e => handleChangeCurrentMasterControlZoneEditor({ DisableCredential: e.target.checked })}
									checked={masterZoneSelected.DisableCredential}>
									{_('DisableCredential')}
								</Checkbox>
							</div>
						</div>
						<Button onClick={handleSaveChanges} type='primary' disabled={!(masterZoneSelected.Dirty && canSave)}>
							{_('SaveMasterControlZone')}
						</Button>
					</div>
				</Card>
				<div className={styles.standarSection}>
					<StandardZones
						currentZone={currentMasterZoneSelected.Index}
						onChangeActionZone={handleChangeAction}
						actionControlZones={masterZoneSelected.ActionControlZones}
					/>
				</div>
				<Confirmation
					visible={nextMasterControlZone !== -1}
					onClickOk={handleSaveChanges}
					onClickCancel={() => {
						setNextMasterControlZone(-1);
					}}
					onClickNo={handleClickNoConfirmation}
				/>
			</div>
		</Form>
	);
};

export { MasterControlZones };

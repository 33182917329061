export default {
	sessionStorage: {
		photoCallUp: {
			RETRIEVE_ACCESS_FLAG: 'RETRIEVE_ACCESS_FLAG',
			RETRIEVE_CONFIGURATION_FLAG: 'RETRIEVE_CONFIGURATION_FLAG',
			RETRIEVE_SAVE_CONFIGURATION_FLAG: 'RETRIEVE_SAVE_CONFIGURATION_FLAG',
			RETRIEVE_ENROLLMENT_USER_INFO_FLAG: 'RETRIEVE_ENROLLMENT_USER_INFO_FLAG',
		},
		statusViewer: {
			RETRIEVE_CONTROLLER_STATUS_FLAG: 'RETRIEVE_CONTROLLER_STATUS_FLAG',
			RETRIEVE_DOOR_STATUS_FLAG: 'RETRIEVE_DOOR_STATUS_FLAG',
			RETRIEVE_DOOR_ACCESS_FLAG: 'RETRIEVE_DOOR_ACCESS_FLAG',
			RETRIEVE_PORT_STATUS_FLAG: 'RETRIEVE_PORT_STATUS_FLAG',
			RETRIEVE_XBOX_STATUS_FLAG: 'RETRIEVE_XBOX_STATUS_FLAG',
		},
		statusDashboard: {
			RETRIEVE_PEOPLE_CREDENTIALS_FLAG: 'RETRIEVE_PEOPLE_CREDENTIALS_FLAG',
			RETRIEVE_QUEUE_DOWNLOADS_FLAG: 'RETRIEVE_QUEUE_DOWNLOADS_FLAG',
			RETRIEVE_ONLINE_ACTIVE_USERS_FLAG: 'RETRIEVE_ONLINE_ACTIVE_USERS_FLAG',
			RETRIEVE_ACCESS_SUMMARY_FLAG: 'RETRIEVE_ACCESS_SUMMARY_FLAG',
		},
		alarmViewer: {
			LIVE_ACK_TABLE: 'LIVE_ACK_TABLE',
			LIVE_ACTIVE_TABLE: 'LIVE_ACTIVE_TABLE',
			FETCH_ACK_TABLE: 'FETCH_ACK_TABLE',
			FETCH_ACTIVE_TABLE: 'FETCH_ACTIVE_TABLE',
		},
	},
};
import {
	Controller,
	ControllerACBSetup,
	ControllerAdvancedReportingSetup,
	ControllerBasicSetup,
	ControllerMiscSystemSetup,
	ControllerPassbackSetup,
	ControllerReportingSetup,
	ControllerStateDenom,
	LoadMessagesForFirmwareConfiguration,
	OccupancySetupByZone,
} from '../../../model/DeviceAdminModel';

export enum ActionTypes {
	SET_CONTROLLER_DATA = 'SET_CONTROLLER_DATA',
	RESET_CONTROLLER_DATA = 'RESET_CONTROLLER_DATA',
	SET_CONTROLLER_PROPERTY = 'SET_CONTROLLER_PROPERTY',
	SET_CONTROLLER_MISC_SYSTEM_SETUP = 'SET_CONTROLLER_MISC_SYSTEM_SETUP',
	SET_CONTROLLER_REPORTING_SETUP = 'SET_CONTROLLER_REPORTING_SETUP',
	SET_CONTROLLER_PASSBACK_SETUP = 'SET_CONTROLLER_PASSBACK_SETUP',
	SET_CONTROLLER_BASIC_SETUP = 'SET_CONTROLLER_BASIC_SETUP',
	SET_OCCUPANCY_TWO_PERSONS_RULES = 'SET_OCCUPANCY_TWO_PERSONS_RULES',
	SET_CONTROLLER_REPORTING_ADVANCE_SETUP = 'SET_CONTROLLER_REPORTING_ADVANCE_SETUP',
	SET_ALARMS = 'SET_ALARMS',
	SET_CONTROLLER_ERROR = 'SET_CONTROLLER_ERROR',
	SET_OPEN_TIME_ZONES = 'SET_OPEN_TIME_ZONES',
	SET_CONTROLLER_CURRENT_TIME_ZONE = 'SET_CONTROLLER_CURRENT_TIME_ZONE',
	SET_CONTROLLER_RESET_HAS_CHANGED = 'SET_CONTROLLER_RESET_HAS_CHANGED',
	SET_FIRMWARE_MESSAGES = 'SET_FIRMWARE_MESSAGES',
	SET_CURRENT_RULE_ID = 'SET_CURRENT_RULE_ID',
}

export type SetCurrentRuleId = {
	type: ActionTypes.SET_CURRENT_RULE_ID;
	payload: number;
};

export type SetFirmwareMessage = {
	type: ActionTypes.SET_FIRMWARE_MESSAGES;
	payload: LoadMessagesForFirmwareConfiguration;
};

export type ResetControllerData = {
	type: ActionTypes.RESET_CONTROLLER_DATA;
};

export type SetControllerData = {
	type: ActionTypes.SET_CONTROLLER_DATA;
	payload: Controller;
};

export type ErrorTabs = {
	linesPerPageForFormFeed: boolean;
	thresholdTime: boolean;
	systemCode: boolean;
	name: boolean;
	twoPersonsRules: boolean;
	wirelessController: boolean;
	controllerType: boolean;
	twoPersonEvenPeople: boolean;
	message?: string;
};
export type SetError = {
	type: ActionTypes.SET_CONTROLLER_ERROR;
	payload: Partial<ErrorTabs>;
};

export type ControllerProperty = Partial<
	Omit<ControllerStateDenom, 'ControllerMiscSystemSetup' | 'ControllerReportingSetup' | 'ControllerPassbackSetup' | 'ControllerBasicSetup'>
>;
export type SetControllerProperty = {
	type: ActionTypes.SET_CONTROLLER_PROPERTY;
	payload: ControllerProperty;
};

export type ControllerMisc = Partial<ControllerMiscSystemSetup>;
export type SetControllerMisc = {
	type: ActionTypes.SET_CONTROLLER_MISC_SYSTEM_SETUP;
	payload: ControllerMisc;
};

export type ControllerReporting = Partial<ControllerReportingSetup>;
export type SetControllerReporting = {
	type: ActionTypes.SET_CONTROLLER_REPORTING_SETUP;
	payload: ControllerReporting;
};

export type ControllerPassback = Partial<ControllerPassbackSetup>;
export type SetControllerPassback = {
	type: ActionTypes.SET_CONTROLLER_PASSBACK_SETUP;
	payload: ControllerPassback;
};

export type ControllerBasic = Partial<ControllerBasicSetup>;
export type SetControllerBasic = {
	type: ActionTypes.SET_CONTROLLER_BASIC_SETUP;
	payload: ControllerBasic;
};

export type SetControllerOccupancyTwoPersonsRules = {
	type: ActionTypes.SET_OCCUPANCY_TWO_PERSONS_RULES;
	payload: OccupancySetupByZone[];
};

export type ControllerReportingAdvanceSetup = Partial<ControllerAdvancedReportingSetup>;
export type SetControllerReportingAdvanceSetup = {
	type: ActionTypes.SET_CONTROLLER_REPORTING_ADVANCE_SETUP;
	payload: ControllerReportingAdvanceSetup;
};

export type SetAlarms = {
	type: ActionTypes.SET_ALARMS;
	payload: { data: ControllerACBSetup; key: string };
};

export type NewTimeZone = {
	open: boolean;
	propertyType:
		| 'Tag'
		| 'Alert'
		| 'LocalPrinter'
		| 'Transaction'
		| 'Events'
		| 'GrantedTransactions'
		| 'PassbackOptions'
		| 'TwoPersonRule'
		| 'MasterControlZones'
		| 'StandardControlZones';
	index: number;
};
export type SetOpenTimeZones = {
	type: ActionTypes.SET_OPEN_TIME_ZONES;
	payload: NewTimeZone;
};

export type SetControllerHasChanged = {
	type: ActionTypes.SET_CONTROLLER_RESET_HAS_CHANGED;
	payload: boolean;
};

export type SetCurrentTimeZone = {
	type: ActionTypes.SET_CONTROLLER_CURRENT_TIME_ZONE;
	payload: number;
};

export type ControllerActionType =
	| ResetControllerData
	| SetControllerData
	| SetControllerProperty
	| SetControllerMisc
	| SetControllerReporting
	| SetControllerPassback
	| SetControllerBasic
	| SetControllerOccupancyTwoPersonsRules
	| SetControllerReportingAdvanceSetup
	| SetError
	| SetAlarms
	| SetOpenTimeZones
	| SetCurrentTimeZone
	| SetControllerHasChanged
	| SetCurrentRuleId
	| SetFirmwareMessage;

import { Button } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Modal } from '../../../../common';
import { PortStoreContext, setNetworkType } from '../contextPort';
import styles from './searchModal.module.scss';
import { SearchTable } from './sections/SearchTable/SearchTable';
import { SnibConfiguration } from './sections/SnibConfiguration/SnibConfiguration';

type Props = {
	onHandleCancelSubModal: () => void;
	onHandleSaveAddress: () => void;
};

const SearchModal: React.FC<Props> = ({ onHandleCancelSubModal, onHandleSaveAddress }) => {
	const [isSearchTableView, setIsSearchTableView] = useState<boolean>(true);

	// TODO: Make local title modal functionality
	const {
		portState: { portNetworkType, snibSelected, titleModal },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		dispatcher(setNetworkType(portNetworkType));
	}, []);

	const isUpdatePortInfoDisabled = useMemo(() => snibSelected === null, [snibSelected]);
	const footer: React.ReactNode[] = [
		<Button id='searchUpdatePortInfoButton' key='save' type='primary' onClick={() => onHandleSaveAddress()} disabled={isUpdatePortInfoDisabled}>
			{_('UpdatePortInfo')}
		</Button>,
		<Button id='searchCancelButton' key='cancel' onClick={() => onHandleCancelSubModal()}>
			{_('Cancel')}
		</Button>,
	];

	return (
		<>
			<Modal
				onClickOk={() => null}
				onCancel={() => {
					onHandleCancelSubModal();
				}}
				title={titleModal}
				visible={true}
				footer={footer}
				width='1250px'
				customZoomClass={styles.PingModalZoom}>
				<SearchTable onHandleIsSearchTableView={setIsSearchTableView} />
			</Modal>
			{!isSearchTableView && <SnibConfiguration handleSetIsSearchTableView={setIsSearchTableView} />}
		</>
	);
};

export { SearchModal };

import { Button, Divider, Form, notification, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { enrollmentApi } from '../../../../../api';
import { cultureDateOnlyFormat, handleResponse, removeAllNonNumberCharacters, removeCommasAndPercentSign } from '../../../../../Helper';
import { ComponentPermission, getPermissionErrorMessage, SecuredComponents, SubPermissions, User } from '../../../../../model/AccountModel';
import { ResponseStatusCode } from '../../../../../model/CommonModel';
import {
	EnrollmentResourceStrings,
	FailedValidationReason,
	initialFormValueObject,
	PersonDetailModel,
	PersonFormModel,
} from '../../../../../model/EnrollmentModel';
import { UDFGroupModel, UserDefinedFieldDetailModel, UserDefinedFieldModel, UserDefinedFieldType } from '../../../../../model/UserDefinedFields';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import {
	loadPersonInformation,
	setDisplayAddViaSmartCard,
	setHavePersonChanged,
	setIsEnrollmentTabLoading,
	setPersonInformationId,
	setSelectedRowKeys,
} from '../../../../../store/enrollment/actions';
import {
	selectBlankProfilePictureBase64,
	selectBlankProfileSignatureBase64,
	selectDisplayAddViaSmartCard,
	selectEnrollmentResourceStrings,
	selectHavePersonChanged,
	selectHavePivField,
	selectIsFICAMEnabled,
	selectPersonId,
	selectPersonInformationData,
	selectPersonInitialValues,
	selectPivUdfFields,
	selectSelectedRowKeys,
	selectUpdateCounter,
} from '../../../../../store/enrollment/selectors';
import { ModalConfirmation, ModalConfirmCustomFooter, PersonInformation } from '../../../../common';
import {
	buildPersonModelOutOfFormValues,
	enrollmentGoBackTextId,
	getClickableElement,
	getFirstExtraTabKey,
	getTabBarStyle,
	getUDFInitialFormValues,
	handleOnFailedCredentialUpdates,
	isWindowsOS,
} from '../../../helper';
import { AdditionalImages, AdditionalNotes, Groups, HiddenFormItems, UDFTable } from '../PersonInformationTabs';
import styles from './personInformationTabs.module.scss';

const { TabPane } = Tabs;

const personFormObject: PersonFormModel = new PersonFormModel();
const { FirstName, MiddleName, LastName, CategoryId } = personFormObject.FormKeysObject;
const user: User = getUser();
const cultureDateFormat: string = user.cultureDateFormat;
const personPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Enrollment_Person);
const canUseScanners: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Person_Usescanners).allowed;
const udfViewPermissions: boolean[] = [
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewGeneralTab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB1Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB2Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB3Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB4Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB5Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB6Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB7Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB8Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB9Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewTAB10Tab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewGroupsTab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewAdditionalImagesTab).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_ViewAdditionalNotesTab).allowed,
];

const tabKeys: Record<string, number> = {
	personInformation: 0,
	groupsActiveKey: 11,
	additionalImages: 12,
	additionalNotes: 13,
};

type Props = {
	updatePivPersonData?: PersonDetailModel;
	clearUpdatePivPersonData?: () => void;
	setReloadPersonTable: () => void;
	handleOnDeletePerson: (reloadPersonTable: boolean) => void;
};

type OnNoHandler = () => void | ((isButton?: boolean) => void);
type SaveChangesProps = {
	url: string;
	isUndo: boolean;
	changeTab: boolean;
	footerButton: HTMLElement;
	mouseEvent: PointerEvent;
};

const PersonInformationTabs: React.FC<Props> = ({ updatePivPersonData, setReloadPersonTable, handleOnDeletePerson, clearUpdatePivPersonData }) => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showActionButtons, setShowActionButtons] = useState<boolean>(true);
	const [activeKey, setActiveKey] = useState<number>(0);
	const [udfTabs, setUDFTabs] = useState<UDFGroupModel[]>([]);
	const [undoChangesCounter, setUndoChangesCounter] = useState<number>(0);
	const [resetFormCounter, setResetFormCounter] = useState<number>(0);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [failedValidationReasons, setFailedValidationReasons] = useState<FailedValidationReason[]>([]);
	const [dateFormat, setDateFormat] = useState<string>('YYYY-MM-DD');
	const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState<boolean>(false);
	const [redirectTo, setRedirectTo] = useState<string>('');
	const [isUndoChanges, setIsUndoChanges] = useState<boolean>(false);
	const [footerButton, setFooterButton] = useState<HTMLElement>(undefined);
	const [havePersonChangedState, setHavePersonChangedState] = useState<boolean>(false);
	const [manualDisable, setManualDisable] = useState<boolean>(false);

	const initialValues: initialFormValueObject[] = useStoreSelector<initialFormValueObject[]>(selectPersonInitialValues);
	const personInformationData: PersonDetailModel = useStoreSelector<PersonDetailModel>(selectPersonInformationData);
	const pivFieldsNumber: number[] = useStoreSelector<number[]>(selectPivUdfFields);
	const havePivFields: boolean = useStoreSelector<boolean>(selectHavePivField);
	const displayAddViaSmartCard: boolean = useStoreSelector<boolean>(selectDisplayAddViaSmartCard);
	const defaultSignatureBase64: string = useStoreSelector<string>(selectBlankProfileSignatureBase64);
	const defaultPictureBase64: string = useStoreSelector<string>(selectBlankProfilePictureBase64);
	const updateCounter: number = useStoreSelector<number>(selectUpdateCounter);
	const isFICAMEnabled: boolean = useStoreSelector<boolean>(selectIsFICAMEnabled);
	const resourceStrings: EnrollmentResourceStrings = useStoreSelector<EnrollmentResourceStrings>(selectEnrollmentResourceStrings);
	const personId: number = useStoreSelector<number>(selectPersonId);
	const selectedRowKeys: React.Key[] = useStoreSelector<React.Key[]>(selectSelectedRowKeys);
	const havePersonChangedSelector: boolean = useStoreSelector<boolean>(selectHavePersonChanged);

	const isSaveChangesModalOpenReference = React.useRef<boolean>(isSaveChangesModalOpen);
	const setIsSaveChangesModalOpenReference = (isSaveChanges: boolean): void => {
		isSaveChangesModalOpenReference.current = isSaveChanges;
		setIsSaveChangesModalOpen(isSaveChanges);
	};

	const redirectToReference = React.useRef<string>(redirectTo);
	const setRedirectToReference = (redirectTo: string): void => {
		redirectToReference.current = redirectTo;
		setRedirectTo(redirectTo);
	};

	const isUndoChangesReference = React.useRef<boolean>(isUndoChanges);
	const setIsUndoChangesReference = (isUndoChanges: boolean): void => {
		isUndoChangesReference.current = isUndoChanges;
		setIsUndoChanges(isUndoChanges);
	};

	const footerButtonReference = React.useRef<HTMLElement>(footerButton);
	const setFooterButtonReference = (footerButton: HTMLElement): void => {
		footerButtonReference.current = footerButton;
		setFooterButton(footerButton);
	};

	const havePersonChangedReference = React.useRef<boolean>(havePersonChangedState);
	const setHavePersonChangedReference = (havePersonChanged: boolean): void => {
		havePersonChangedReference.current = havePersonChanged;
		setHavePersonChangedState(havePersonChanged);
	};

	const [form] = Form.useForm<PersonDetailModel>();

	useEffect(() => {
		if (resetFormCounter > 0) {
			form.resetFields();
			setUndoChangesCounter(prevState => prevState + 1);
		}
	}, [initialValues, resetFormCounter]);

	useEffect(() => {
		if (updatePivPersonData && !displayAddViaSmartCard) {
			const { CertificatesData, ProfilePictureBase64, LastName, FirstName, Title, Suffix, MiddleName, UserDefinedFields } = updatePivPersonData;
			const udfInitialFormValues: initialFormValueObject[] = getUDFInitialFormValues(UserDefinedFields);
			const udfPivFields: number[] = UserDefinedFields.map<number>((udf: Partial<UserDefinedFieldModel>) => udf.FieldNumber);
			form.setFieldsValue(
				Object.assign(
					{
						CertificatesData: CertificatesData,
						ProfilePictureBase64: ProfilePictureBase64,
						LastName: LastName,
						FirstName: FirstName,
						Title: Title,
						Suffix: Suffix,
						MiddleName: MiddleName,
					},
					...udfInitialFormValues
				)
			);
			clearUpdatePivPersonData();
			handleOnChangeValues();
			loadTabs(true, udfPivFields);
			setUndoChangesCounter(prevState => prevState + 1);
			setManualDisable(true);
		}
	}, [displayAddViaSmartCard]);

	useEffect(() => {
		if (!manualDisable) {
			loadTabs();
		}
	}, [havePivFields, manualDisable]);

	useEffect(() => {
		setHavePersonChangedReference(havePersonChangedSelector);
	}, [havePersonChangedSelector]);

	useEffect(() => {
		const firstVisibleTabIndex: number = udfViewPermissions.findIndex((allowed: boolean) => allowed);
		if (firstVisibleTabIndex !== -1) {
			loadTabs();
		} else {
			handleOnCannotViewTabs();
		}

		document.body.addEventListener('click', handleClickRedirectTo, true);
		return () => {
			document.body.removeEventListener('click', handleClickRedirectTo);
		};
	}, []);

	const loadTabs = (isPivUpdate?: boolean, udfPivFields?: number[]): void => {
		setIsLoading(true);
		enrollmentApi
			.getUDFTabs()
			.then(res => {
				if (res.length > 0) {
					if (havePivFields || isPivUpdate) {
						const fieldNumbersArray: number[] = isPivUpdate ? udfPivFields : pivFieldsNumber;
						res.forEach((tab: UDFGroupModel) => {
							tab.Items.forEach((udf: UserDefinedFieldDetailModel) => {
								udf.Value = form.getFieldValue(`udfField-${udf.FieldNumber},${udf.Type}`);
								udf.IsPIVField = fieldNumbersArray.includes(udf.FieldNumber);
							});
						});
					}
					setUDFTabs(res);
					let activeKey: number = -1;
					for (let tabId: number = tabKeys.personInformation; tabId < tabKeys.groupsActiveKey; tabId++) {
						const tabIndex: number = res.findIndex((udfGroupModel: UDFGroupModel) => udfGroupModel.TabId === tabId);

						if (tabIndex !== -1 && udfViewPermissions[tabId]) {
							activeKey = tabId;
							break;
						}
					}

					if (activeKey === -1) {
						const extraTabActiveKey: number = getFirstExtraTabKey(udfViewPermissions, tabKeys);
						if (extraTabActiveKey === -1) {
							handleOnCannotViewTabs();
						} else {
							setActiveKey(extraTabActiveKey);
							setCultureDateFormat();
						}
					} else {
						setActiveKey(activeKey);
						setCultureDateFormat();
					}
				} else if (
					udfViewPermissions[tabKeys.groupsActiveKey] ||
					udfViewPermissions[tabKeys.additionalImages] ||
					udfViewPermissions[tabKeys.additionalNotes]
				) {
					setActiveKey(getFirstExtraTabKey(udfViewPermissions, tabKeys));
					setCultureDateFormat();
				} else {
					handleOnCannotViewTabs();
				}
			})
			.finally(() => setIsLoading(false));
	};

	const setCultureDateFormat = (): void => {
		if (cultureDateFormat && cultureDateFormat !== '') {
			const dateFormat: string = cultureDateOnlyFormat(cultureDateFormat);
			setDateFormat(dateFormat);
		}
	};

	const handleClickRedirectTo = event => {
		if (havePersonChangedReference.current && !isSaveChangesModalOpenReference.current && personPermissions.canUpdate) {
			const target: HTMLAnchorElement = event.target;
			const tagName: string = target.tagName.toLocaleLowerCase();

			if (target.id === enrollmentGoBackTextId) {
				displaySaveChangesModal({ mouseEvent: event });
			} else if (tagName === 'a' && target.dataset.leavetab && target.href !== 'javascript:void(0)') {
				displaySaveChangesModal({ url: target.href, mouseEvent: event });
			} else if (target.dataset.leavetab) {
				displaySaveChangesModal({ footerButton: getClickableElement(target), mouseEvent: event });
			} else if (tagName === 'svg' || tagName === 'path') {
				const firstParentElement: HTMLElement = target.parentElement;
				if (firstParentElement.tagName.toLocaleLowerCase() === 'svg') {
					const secondParentElement: HTMLElement = firstParentElement.parentElement;
					if (secondParentElement.dataset.leavetab) {
						displaySaveChangesModal({ footerButton: getClickableElement(secondParentElement), mouseEvent: event });
					}
				} else if (firstParentElement.dataset.leavetab) {
					displaySaveChangesModal({ footerButton: getClickableElement(firstParentElement), mouseEvent: event });
				}
			}
		}
	};

	const displaySaveChangesModal = ({ url = '', isUndo, footerButton, mouseEvent }: Partial<SaveChangesProps>): void => {
		if (mouseEvent) {
			mouseEvent.preventDefault();
			mouseEvent.stopImmediatePropagation();
		}
		if (url !== redirectToReference.current) {
			setRedirectToReference(url);
		}
		if (isUndo !== isUndoChangesReference.current) {
			setIsUndoChangesReference(isUndo);
		}
		if (footerButton !== footerButtonReference.current) {
			setFooterButtonReference(footerButton);
		}
		if (!isSaveChangesModalOpenReference.current) {
			setIsSaveChangesModalOpenReference(true);
		}
	};

	const handleOnCannotViewTabs = (): void => {
		notification.error({
			message: resourceStrings.ViewTabError,
		});
		dispatch(setPersonInformationId(0));
	};

	const handleOnFinishForm = (values: PersonDetailModel) => {
		dispatch(setIsEnrollmentTabLoading(true));
		const personDetail: PersonDetailModel = buildPersonModelOutOfFormValues(values, defaultPictureBase64, defaultSignatureBase64, personId);
		personDetail.TemplateId = personInformationData.TemplateId;

		enrollmentApi
			.enrollPerson(personDetail)
			.then(response => {
				if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
					const fieldsStatus = response.FailedValidationReasons.map((reason: FailedValidationReason) => ({
						name: reason.FormName,
						errors: [reason.ErrorMessage],
					}));
					form.setFields(fieldsStatus);
					setFailedValidationReasons(response.FailedValidationReasons);
					setErrorMessage(response.ErrorMessage);
					notification.error({ message: response.ErrorMessage });
				} else if (!handleResponse(response)) {
					setReloadPersonTable();
					if (addMode) {
						batch(() => {
							dispatch(setPersonInformationId(response.PersonId));
							dispatch(loadPersonInformation(response.PersonId));
							dispatch(setHavePersonChanged(false));
						});
					} else {
						batch(() => {
							dispatch(loadPersonInformation(personId));
							dispatch(setHavePersonChanged(false));
						});
					}
					setUndoChangesCounter(prevState => prevState + 1);
					if (manualDisable) {
						setManualDisable(false);
					}
					notification.success({ message: _('PersonInformationUpdatedSuccessfully') });
				}

				if (response.FailedCredentialUpdates.length) {
					handleOnFailedCredentialUpdates(response.FailedCredentialUpdates);
				}
			})
			.finally(() => dispatch(setIsEnrollmentTabLoading(false)));
	};

	const onValuesChange = changedValues => {
		const clonedState: FailedValidationReason[] = [...failedValidationReasons];
		Object.entries(changedValues).forEach(field => {
			const [key, value] = field;

			if (key !== CategoryId) {
				handleOnChangeValues();
			}

			if (key === FirstName || key === LastName || key === MiddleName) {
				const newNameValue: string = typeof value === 'string' ? value.toString() : '';
				const nameField = {
					name: key,
					value: removeCommasAndPercentSign(newNameValue),
				};
				form.setFields([nameField]);
			}

			if (key.includes('udfField')) {
				const udfType = Number(key.split(',')[1]);
				if (
					udfType === UserDefinedFieldType.Number ||
					udfType === UserDefinedFieldType.UniqueNumber ||
					udfType === UserDefinedFieldType.AutoSequentialNumber ||
					udfType === UserDefinedFieldType.AutoUniqueNumber
				) {
					const newUDFNumberValue: string = typeof value === 'string' ? value.toString() : '';
					const udfNumberField = {
						name: key,
						value: removeAllNonNumberCharacters(newUDFNumberValue),
					};
					form.setFields([udfNumberField]);
				}
			}

			const error: string[] = form.getFieldError(key);
			if (!error.length) {
				return;
			}

			if (clonedState.length > 0) {
				const newErrorTabs: FailedValidationReason[] = clonedState.filter((error: FailedValidationReason) => error.FormName !== key);
				setFailedValidationReasons(newErrorTabs);
				if (newErrorTabs.length === 0) {
					setErrorMessage('');
				}
			} else if (errorMessage !== '') {
				setErrorMessage('');
			}

			form.setFields([
				{
					name: key,
					errors: [],
				},
			]);
		});
	};

	const handleOnUndoChanges = (isButton?: boolean): void => {
		if (isButton && havePersonChangedReference.current) {
			displaySaveChangesModal({ isUndo: true });
		} else {
			form.resetFields();
			setErrorMessage('');
			setFailedValidationReasons([]);
			if (havePivFields) {
				if (addMode) {
					dispatch(setPersonInformationId(-200));
					dispatch(setPersonInformationId(-100));
					setResetFormCounter(prevState => prevState + 1);
					loadTabs(true, []);
				} else {
					dispatch(loadPersonInformation(personId));
					loadTabs(true, []);
				}
			}
			if (manualDisable) {
				setManualDisable(false);
			}
			if (isSaveChangesModalOpenReference.current) {
				setIsSaveChangesModalOpenReference(false);
			}
			setUndoChangesCounter(prevState => prevState + 1);
			setTimeout(() => {
				dispatch(setHavePersonChanged(false));
			}, 0);
		}
	};

	const handleOnDismissChanges = (): void => {
		batch(() => {
			dispatch(setPersonInformationId(0));
			dispatch(setDisplayAddViaSmartCard(false));
			dispatch(setHavePersonChanged(false));
		});
	};

	const handleOnSaveChanges = (): void => {
		setFooterButtonReference(undefined);
		form.submit();
		setIsSaveChangesModalOpenReference(false);
	};

	const handleOnChangeValues = () => {
		if (!havePersonChangedReference.current) {
			dispatch(setHavePersonChanged(true));
		}
	};

	const handleOnCancelSaveChanges = () => {
		setFooterButtonReference(undefined);
		setIsSaveChangesModalOpenReference(false);
	};

	const handleOnClickFooterButton = () => {
		if (footerButtonReference.current) {
			footerButtonReference.current.click();
		}
		setIsSaveChangesModalOpenReference(false);
		handleOnDismissChanges();
	};

	const getHandleOnNo = (redirectURL: string, isUndoChanges: boolean): OnNoHandler => {
		let handler: OnNoHandler;
		if (redirectURL !== '') {
			handler = undefined;
		} else if (isUndoChanges) {
			handler = handleOnUndoChanges;
		} else if (footerButtonReference.current) {
			handler = handleOnClickFooterButton;
		} else {
			handler = handleOnDismissChanges;
		}

		return handler;
	};

	const handleOnNo: OnNoHandler = getHandleOnNo(redirectToReference.current, isUndoChangesReference.current);

	const handleOnClickDeletePerson = () => {
		ModalConfirmation({
			onConfirm: () => {
				dispatch(setIsEnrollmentTabLoading(true));
				enrollmentApi
					.deletePerson(personId)
					.then(response => {
						if (!handleResponse(response)) {
							const clonedSelectedRowKeys: React.Key[] = [...selectedRowKeys];
							const newSelectedRowKeys: React.Key[] = clonedSelectedRowKeys.filter((key: React.Key) => key !== personId.toString());
							dispatch(setSelectedRowKeys(newSelectedRowKeys));
							handleOnDeletePerson(true);
						}
					})
					.finally(() => dispatch(setIsEnrollmentTabLoading(false)));
			},
			content: <div>{_('AreYouSureYouWantToDeletePerson').replace('%1', `${personInformationData.FirstName} ${personInformationData.LastName}`)}</div>,
		});
	};

	const handleOnOpenAddViaSmartCard = () => {
		if (isWindowsOS) {
			dispatch(setDisplayAddViaSmartCard(true));
		} else {
			notification.error({
				message: _('SmartCardEnrollmentWindowsOS'),
			});
		}
	};

	const showActionButtonValidation: boolean = activeKey !== tabKeys.additionalImages || (activeKey === tabKeys.additionalImages && showActionButtons);
	const disableUpdateViaSmartCard: boolean = !personPermissions.canUpdate || !isFICAMEnabled || !canUseScanners;
	const addMode: boolean = personId === -100;

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			<Form
				form={form}
				name='personInformation'
				initialValues={initialValues}
				autoComplete='off'
				colon={false}
				onFinish={handleOnFinishForm}
				labelWrap={true}
				labelAlign={'left'}
				size={'middle'}
				onValuesChange={onValuesChange}>
				<div className={styles.container} key={`form-${undoChangesCounter}`}>
					<Tabs type='card' activeKey={activeKey.toString()} onChange={(key: string) => setActiveKey(Number(key))}>
						{udfTabs.map((tab: UDFGroupModel) =>
							tab.TabId === tabKeys.personInformation
								? udfViewPermissions[tabKeys.personInformation] && (
										<TabPane
											key={`${tab.TabId}`}
											tab={getTabBarStyle(failedValidationReasons, tab.TabId, tab.TabCaption)}
											forceRender={true}
											className={`${tab.TabId}`}>
											<div className={styles.modalInnerContainer}>
												<PersonInformation
													key={`personInformation-${updateCounter}`}
													form={form}
													personInformation={personInformationData}
													udfData={tab.Items}
													showInfoOnly={false}
													tabId={tabKeys.personInformation}
													errorMessage={errorMessage}
													addMode={addMode}
												/>
											</div>
										</TabPane>
								  )
								: udfViewPermissions[tab.TabId] && (
										<TabPane
											key={`${tab.TabId}`}
											tab={getTabBarStyle(failedValidationReasons, tab.TabId, tab.TabCaption)}
											forceRender={true}>
											<UDFTable
												key={`UDFTable-${updateCounter}`}
												form={form}
												udfInfo={tab.Items}
												tabId={tab.TabId}
												dateFormat={dateFormat}
												addMode={addMode}
											/>
										</TabPane>
								  )
						)}
						{udfViewPermissions[tabKeys.groupsActiveKey] && (
							<TabPane key={tabKeys.groupsActiveKey} tab={_('Groups')} forceRender={true}>
								<Groups form={form} tabId={tabKeys.groupsActiveKey} />
							</TabPane>
						)}
						{udfViewPermissions[tabKeys.additionalImages] && !addMode && (
							<TabPane key={tabKeys.additionalImages} tab={_('AdditionalImages')}>
								<AdditionalImages form={form} setShowActionButtons={setShowActionButtons} tabId={tabKeys.additionalImages} />
							</TabPane>
						)}
						{udfViewPermissions[tabKeys.additionalNotes] && !addMode && (
							<TabPane key={tabKeys.additionalNotes} tab={_('AdditionalNotes')}>
								<AdditionalNotes form={form} />
							</TabPane>
						)}
					</Tabs>
				</div>
				<div className={styles.footer}>
					<Divider className={styles.actionButtonsDivider} />
					{showActionButtonValidation && (
						<div className={styles.actionButtons}>
							{!addMode && !personInformationData.IsTemplate && (
								<Button
									id='enrollmentPersonDeleteButton'
									disabled={!personPermissions.canDelete}
									title={getPermissionErrorMessage(personPermissions.canDelete)}
									onClick={handleOnClickDeletePerson}>
									{_('DeletePerson')}
								</Button>
							)}
							<Button id='enrollmentPersonUndoChangesButton' onClick={() => handleOnUndoChanges(true)} disabled={!havePersonChangedSelector}>
								{_('UndoChanges')}
							</Button>
							{!addMode && (
								<Button
									id='enrollmentPersonUpdateButton'
									disabled={disableUpdateViaSmartCard}
									title={getPermissionErrorMessage(!disableUpdateViaSmartCard)}
									onClick={handleOnOpenAddViaSmartCard}>
									{_('UpdatePersonViaSmartCard')}
								</Button>
							)}
							<Button
								id='enrollmentPersonApplyChangesButton'
								type='primary'
								htmlType='submit'
								disabled={!personPermissions.canUpdate}
								title={getPermissionErrorMessage(personPermissions.canUpdate)}>
								{_('ApplyChanges')}
							</Button>
						</div>
					)}
				</div>
				<ModalConfirmCustomFooter
					title={_('DataModified')}
					width={'400px'}
					visible={isSaveChangesModalOpenReference.current}
					navigateTo={redirectToReference.current}
					onOk={handleOnSaveChanges}
					onCancel={handleOnCancelSaveChanges}
					onNo={handleOnNo}>
					<span>{_('PersonDataChanged')}</span>
				</ModalConfirmCustomFooter>
				<HiddenFormItems />
			</Form>
		</Spin>
	);
};

export { PersonInformationTabs };

import React from 'react';
import styles from '../../../expansionRelayModal.module.scss';
import { ReTrigger, Trigger } from './';

type Props = {
	useDoorContext: boolean;
};
const ControlZones: React.FC<Props> = ({ useDoorContext }) => {
	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('ControlZones')}</legend>
					<div className={styles.radioButton}>
						<Trigger useDoorContext={useDoorContext}></Trigger>
						<ReTrigger useDoorContext={useDoorContext}></ReTrigger>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { ControlZones };

import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setAntiPassback, StoreContext } from '../../../contextReader';
import styles from '../../Logic/logic.module.scss';

type Props = {
	useDoorContext: boolean;
};

const AntiPassback: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { antiPassback },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { antiPassback },
			dispatcher,
		} = useContext(StoreContext);
	}

	const handleOnChangeAntiPassback = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== 0) {
				return;
			}
		}

		useDoorContext ? dispatcherDoor(setAntiPassback(Number(value.toString()))) : dispatcher(setAntiPassback(Number(value.toString())));
	};

	return (
		<div className={styles.antiPassbackSection}>
			<span>
				{_('AntiPassback')} ({_('Minutes')})
			</span>
			<div>
				<InputNumber
					key='antiPassback'
					min={0}
					max={254}
					maxLength={3}
					value={antiPassback}
					onChange={handleOnChangeAntiPassback}
					id={'antiPassback'}
					className={styles.input}
					formatter={noneDecimalFormatter}
				/>
			</div>
		</div>
	);
};

export { AntiPassback };

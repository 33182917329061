import {
	ActiveDirectoryUserFlags,
	EntryErrorFlags,
	FieldProp,
	OperatorPropertyModel,
	OperatorRoleModifications,
	OperatorSecurityKeyContext,
	RootModelContext,
} from '../../../../../../model/OperatorModel';

export enum ContextActionTypes {
	SET_ROOT_MODEL_PROPERTIES = 'SET_ROOT_MODEL_PROPERTIES',
	SET_ROOT_MODEL_PROPERTY = 'SET_ROOT_MODEL_PROPERTY',
	SET_ERROR_ENTRY_FLAGS = 'SET_ERROR_ENTRY_FLAGS',
	SET_ERROR_ENTRY_FLAG = 'SET_ERROR_ENTRY_FLAG',
	SET_WINDOWS_CREDENTIAL_PROPERTY = 'SET_WINDOWS_CREDENTIAL_PROPERTY',
	SET_OPERATOR_ROLE_TAB_ERROR = 'SET_OPERATOR_ROLE_TAB_ERROR',
	SET_OPERATOR = 'SET_OPERATOR',
	SET_OPERATOR_PROP = 'SET_OPERATOR_PROP',
	SET_OPERATOR_PASSWORD_FLAGS = 'SET_OPERATOR_PASSWORD_FLAGS',
	SET_OPERATOR_ROLES_MODIFICATIONS = 'SET_OPERATOR_ROLES_MODIFICATIONS',
	SET_SECURITY_KEYS_MODIFICATIONS = 'SET_SECURITY_KEYS_MODIFICATIONS',
	SET_SECURITY_KEYS_TABLE_EDIT_MODE = 'SET_SECURITY_KEYS_TABLE_EDIT_MODE',
	SET_TWO_FACTOR_TAB_MODIFICATIONS = 'SET_TWO_FACTOR_TAB_MODIFICATIONS',
	UPDATE_SECURITY_KEYS_MODIFICATIONS = 'UPDATE_SECURITY_KEYS_MODIFICATIONS',
}

export type setRootModelProperties = {
	type: ContextActionTypes.SET_ROOT_MODEL_PROPERTIES;
	payload: RootModelContext;
};

export type setRootModelProperty = {
	type: ContextActionTypes.SET_ROOT_MODEL_PROPERTY;
	payload: FieldProp;
};

export type setErrorEntryFlags = {
	type: ContextActionTypes.SET_ERROR_ENTRY_FLAGS;
	payload: EntryErrorFlags;
};

export type setErrorEntryFlag = {
	type: ContextActionTypes.SET_ERROR_ENTRY_FLAG;
	payload: FieldProp;
};

export type setWindowsCredentialProperty = {
	type: ContextActionTypes.SET_WINDOWS_CREDENTIAL_PROPERTY;
	payload: FieldProp;
};

export type setOperator = {
	type: ContextActionTypes.SET_OPERATOR;
	payload: OperatorPropertyModel;
};

export type setOperatorProp = {
	type: ContextActionTypes.SET_OPERATOR_PROP;
	payload: FieldProp;
};

export type setOperatorPasswordFlags = {
	type: ContextActionTypes.SET_OPERATOR_PASSWORD_FLAGS;
	payload: ActiveDirectoryUserFlags;
};

export type setOperatorRoleModifications = {
	type: ContextActionTypes.SET_OPERATOR_ROLES_MODIFICATIONS;
	payload: OperatorRoleModifications;
};

export type setSecurityKeyModifications = {
	type: ContextActionTypes.SET_SECURITY_KEYS_MODIFICATIONS;
	payload: OperatorSecurityKeyContext;
};

export type setSecurityKeyTableEditMode = {
	type: ContextActionTypes.SET_SECURITY_KEYS_TABLE_EDIT_MODE;
	payload: boolean;
};

export type setTwoFactorTabModification = {
	type: ContextActionTypes.SET_TWO_FACTOR_TAB_MODIFICATIONS;
	payload: boolean;
};

export type updateSecurityKeyModifications = {
	type: ContextActionTypes.UPDATE_SECURITY_KEYS_MODIFICATIONS;
	payload: OperatorSecurityKeyContext;
};

export type ActionContext =
	| setRootModelProperties
	| setRootModelProperty
	| setErrorEntryFlags
	| setErrorEntryFlag
	| setWindowsCredentialProperty
	| setOperatorProp
	| setOperator
	| setOperatorPasswordFlags
	| setOperatorRoleModifications
	| setSecurityKeyModifications
	| setSecurityKeyTableEditMode
	| setTwoFactorTabModification
	| updateSecurityKeyModifications;

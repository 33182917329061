import { InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../Helper';
import { ProfileSetting } from '../../../model/ConfigurationModel';

interface Props {
	title?: string;
	profileSetting: ProfileSetting;
	min: number;
	max: number;
	maxLength: number;
	onChange?: (value: React.Key) => void;
	disabled?: boolean;
	id?: string;
}

const InputNumberHelper = (props: Props) => {
	const { profileSetting, min, max, maxLength, disabled, id, title } = props;
	let actualId: string;
	if (id) {
		actualId = id;
	} else {
		actualId = profileSetting.Name;
	}

	return (
		<>
			<InputNumber
				name={profileSetting.Name}
				min={min}
				max={max}
				maxLength={maxLength}
				value={Number(profileSetting.Value)}
				onChange={props.onChange}
				disabled={disabled}
				id={actualId}
				formatter={noneDecimalFormatter}
				title={title}
			/>
			{profileSetting.Label && <label htmlFor={actualId}>{profileSetting.Label}</label>}
		</>
	);
};

export { InputNumberHelper };

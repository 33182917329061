import {
	AlarmAcknowledgementActions,
	AlarmAcknowledgementResults,
	AlarmAssessmentCode,
	AlarmListResponseObject,
	AlarmModel,
	AlarmNote,
	AlarmSoundConfiguration,
} from './../model/AlarmModel';
import { GridDataColumnItem, ResponseObjectEntity } from './../model/CommonModel';
import { ColumnConfiguration } from './../model/ConfigurationModel';
import { Api } from './api';

class AlarmApi extends Api {
	public constructor() {
		super();
	}

	public getStoredAlarms = async (
		pageNumber: number,
		pageSize: number,
		acknowledged: boolean,
		isForAlarmSound: boolean = false,
		excludeAlarmIds: string[] = []
	): Promise<AlarmListResponseObject> => {
		const url = this.getUrl('Events/RetrieveStoredAlarms', true);
		const data = {
			pageNumber,
			pageSize,
			acknowledged,
			excludeAlarmIds,
			isForAlarmSound,
		};

		return await this.post<AlarmListResponseObject>(url, data);
	};

	public getAlarmsColumnsConfiguration = async (isAcknowledged: boolean): Promise<ResponseObjectEntity<ColumnConfiguration>> => {
		const url = this.getUrl('Events/GetAlarmsColumnsConfiguration', true);
		const data = {
			isAcknowledged,
		};

		return await this.post<ResponseObjectEntity<ColumnConfiguration>>(url, data);
	};

	public getAlarmsTableColumns = async (isAcknowledged: boolean): Promise<ResponseObjectEntity<GridDataColumnItem[]>> => {
		const url = this.getUrl('Events/GetAlarmsTableColumns', true);
		const data = {
			isAcknowledged,
		};

		return await this.post<ResponseObjectEntity<GridDataColumnItem[]>>(url, data);
	};

	public getAlarmNotes = async (alarmId: number): Promise<AlarmNote[]> => {
		const url = this.getUrl('Events/RetrieveAlarmNotes', true);

		return await this.post<AlarmNote[]>(url, { alarmId });
	};

	public getAlarmAssessmentCodes = async (): Promise<ResponseObjectEntity<AlarmAssessmentCode[]>> => {
		const url = this.getUrl('Events/GetAlarmAssessmentCodes', true);

		return await this.post<ResponseObjectEntity<AlarmAssessmentCode[]>>(url, {});
	};

	public getAlarmSoundConfiguration = async (): Promise<ResponseObjectEntity<AlarmSoundConfiguration>> => {
		const url = this.getUrl('Events/GetAlarmSoundConfiguration', true);

		return await this.post<ResponseObjectEntity<AlarmSoundConfiguration>>(url);
	};

	public acknowledgeAndClearAlarms = async (
		action: AlarmAcknowledgementActions,
		alarmIds: number[],
		notes: AlarmNote[]
	): Promise<AlarmAcknowledgementResults> => {
		const url = this.getUrl('Events/AcknowledgeAndClearAlarms', true);
		const data = { action, alarmIds, notes };

		return await this.post<AlarmAcknowledgementResults>(url, data);
	};

	public saveAlarmNotes = async (notes: AlarmNote[]): Promise<ResponseObjectEntity<AlarmNote[]>> => {
		const url = this.getUrl('Events/SaveAlarmNotes', true);
		const data = {
			notes,
		};

		return await this.post<ResponseObjectEntity<AlarmNote[]>>(url, data);
	};

	public getAllAlarmIds = async (isAcknowledge: boolean): Promise<ResponseObjectEntity<AlarmModel[]>> => {
		const url = this.getUrl('Events/GetAllAlarmIds', true);
		const data = {
			isAcknowledge,
		};

		return await this.post<ResponseObjectEntity<AlarmModel[]>>(url, data);
	};
}

const alarmApi = new AlarmApi();

export { alarmApi };

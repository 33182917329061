import { Dropdown, Menu } from 'antd';
import React from 'react';
import { SelectOptions } from '../../../model/CommonModel';
import styles from './contextMenu.module.scss';

const { Item } = Menu;

export type ContextMenuProps<T> = {
	options?: SelectOptions<T>[];
	onClickOption?: (value: T) => void;
	children?: JSX.Element;
	menuId?: string;
	destroyPopupOnHide?: boolean;
};

export const ContextMenu: React.FC<ContextMenuProps<number>> = <T extends {}>({
	options,
	onClickOption,
	children,
	destroyPopupOnHide,
	menuId,
}: ContextMenuProps<T>) => {
	const createMenuItems = (options: SelectOptions<T>[]) => {
		const menuItems: JSX.Element[] = [];
		options.forEach(({ label, disabled, title, icon, id, separator, value, key }, index) => {
			let newKey = `${label}-${index}`;
			if (key) {
				newKey = `${newKey}-${key}`;
			}

			menuItems.push(
				<Item
					id={id}
					key={newKey}
					onClick={e => {
						e.domEvent.stopPropagation();
						onClickOption?.(value);
					}}
					className={separator ? styles.separator : undefined}
					disabled={disabled}
					title={title}
					icon={icon}>
					{label}
				</Item>
			);
		});

		return menuItems;
	};

	const menu: JSX.Element = (
		<Menu id={menuId} getPopupContainer={(element: HTMLElement) => element.parentElement}>
			{createMenuItems(options)}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['contextMenu']} destroyPopupOnHide={destroyPopupOnHide} overlayStyle={{ width: 'max-content' }}>
			{children}
		</Dropdown>
	);
};

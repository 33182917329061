import { Button, Card, Checkbox, Descriptions } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../api';
import { ResponseObject, SelectOptions } from '../../../../../../model/CommonModel';
import { DownloadTSFirmware } from '../../../../../../model/DeviceAdminModel';
import { InformationMessages, Modal, NotificationStatus, Select } from '../../../../../common';
import { DoorStoreSingleContext } from '../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderApplyToAllReaders, setExitReaderTSFirmware } from '../exitReaderContext';
import styles from './updateFirmwareModal.module.scss';

type Props = {
	visible: boolean;
	closeModal: () => void;
	handleDownloadFirmware: () => void;
};

const UpdateFirmwareModal: React.FC<Props> = ({ handleDownloadFirmware, visible, closeModal }) => {
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [firmwares, setFirmwares] = useState<SelectOptions<string>[]>([]);
	const [warningMessage, setWarningMessage] = useState<JSX.Element>(<></>);

	var {
		contextStateDoor: {
			exitReader: {
				firmwareTitle,
				selections: { tSFirmware },
				tSFirmwares,
				firmwareWarning,
				applyToAllReaders,
				name,
				id,
				index,
				controllerId,
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	useEffect(() => {
		const messages: string[] = firmwareWarning.split('\n');
		setFirmwares(tSFirmwares.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
		setWarningMessage(<InformationMessages messages={messages} />);
	}, [tSFirmwares]);

	const handleUpdateFirmware = () => {
		setDownloadLoading(true);
		const tsFirmwareInfo: DownloadTSFirmware = {
			ControllerId: controllerId,
			ReaderIndex: index,
			ReaderId: id,
			ReaderName: name,
			FirmwareId: tSFirmware,
			ApplyToAllReaders: applyToAllReaders,
		};
		deviceAdminApi.downloadFirmware(tsFirmwareInfo).then((res: ResponseObject) => {
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {
					handleDownloadFirmware();
					closeModal();
				},
			});
			setDownloadLoading(false);
		});
	};

	const handleOnSetTSFirmware = (value: string) => {
		dispatcherDoor(setExitReaderTSFirmware(Number(value)));
	};

	const handleOnApplyToAllReaders = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderApplyToAllReaders(value.target.checked));
	};

	return (
		<Modal
			className={styles.modalAutoZoom}
			visible={visible}
			title={firmwareTitle}
			onClickOk={handleUpdateFirmware}
			onCancel={closeModal}
			width={'900px'}
			footer={[
				<Button key='download' type='primary' loading={downloadLoading} onClick={handleUpdateFirmware} disabled={tSFirmware === 0}>
					{_('Download')}
				</Button>,
				<Button key='cancel' onClick={closeModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<div className={styles.firmwareModal} id='updateFirmwareModal'>
				<p>{_('SelectFirmware')}</p>
				<Select
					className={styles.select}
					options={firmwares}
					onChange={handleOnSetTSFirmware}
					key='tsFirmware'
					value={tSFirmware.toString()}
					getPopupContainer={() => document.getElementById('updateFirmwareModal')}
				/>
			</div>
			<div className={styles.firmwareCard}>
				<Card>
					<Descriptions>
						<Descriptions.Item>{warningMessage}</Descriptions.Item>
					</Descriptions>
				</Card>
			</div>
			<div className={styles.firmwareModal}>
				<Checkbox checked={applyToAllReaders} onChange={handleOnApplyToAllReaders} id={'applyFirmware'}>
					<span>{_('ApplyFirmwareToAllReaders')}</span>
				</Checkbox>
			</div>
		</Modal>
	);
};

export { UpdateFirmwareModal };

import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useState } from 'react';
import { batch } from 'react-redux';
import {
	setOperatorPasswordFlagsAction,
	setOperatorPropAction,
	setRootModelPropertiesAction,
	setRootModelPropertyAction,
	setWindowsCredentialPropertyAction,
} from '../../../OperatorContext/action';
import { OperatorStoreContext } from '../../../OperatorContext/context';
import { FindUserActiveDirectoryModal } from '../FindUserActiveDirectoryModal/FindUserActiveDirectoryModal';
import styles from './windowsCredential.module.scss';

type WindowsCredentialProps = {
	isEditMode: boolean;
	setSelectedDomain: React.Dispatch<React.SetStateAction<string>>;
	authPath: string;
	domains: string[];
};

const WindowsCredential: React.FC<WindowsCredentialProps> = ({ isEditMode, setSelectedDomain, authPath, domains }) => {
	const {
		operatorInitialState: {
			rootContext,
			rootContext: { enableCustomGroupNames },
			windowsCredential: { password, confirmPassword },
			rootContext: {
				entryError: { isPasswordError, isConfirmPasswordError },
				foundInAD,
			},
			operator: { Flags, DirectorySource: intialDomain },
		},
		dispatcher,
	} = useContext(OperatorStoreContext);
	const { IsPasswordExpired, IsCannotChangePassword, IsPasswordNeverExpires, IsAccountDisabled, IsAccountLockedOut } = Flags;
	const [isFindModalOpen, setIsFindModalOpen] = useState<boolean>(false);

	const onFindUserConfirm = () => {
		batch(() => {
			setIsFindModalOpen(false);
			dispatcher(setWindowsCredentialPropertyAction({ field: 'password', value: '' }));
			dispatcher(setWindowsCredentialPropertyAction({ field: 'confirmPassword', value: '' }));
			dispatcher(setRootModelPropertyAction({ field: 'foundInAD', value: true }));
			dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
			dispatcher(
				setRootModelPropertiesAction({
					...rootContext,
					entryError: {
						...rootContext.entryError,
						isGeneralTabError: false,
						isSamAccountError: false,
						isSamAccountEmpty: false,
						isFullNameEmpty: false,
						isDomainEmpty: false,
						isPasswordError: false,
						isConfirmPasswordError: false,
					},
				})
			);
		});
	};

	const onFindUserCancel = () => {
		setIsFindModalOpen(false);
	};

	const renderFindUserModal = (): React.ReactNode => {
		if (!isFindModalOpen) {
			return;
		}
		return (
			<FindUserActiveDirectoryModal
				onConfirm={onFindUserConfirm}
				onCancel={onFindUserCancel}
				authPath={authPath}
				setDomain={setSelectedDomain}
				initialDomain={intialDomain}
				domains={domains}
			/>
		);
	};

	return (
		<div
			className={cx(styles.container, {
				[styles.containerLabel]: isEditMode,
			})}>
			{!isEditMode ? (
				<div className={styles.containerLegend}>
					<legend className={styles.legend}>{_('WindowsCredential')}</legend>
				</div>
			) : (
				<label id='windowCredentialLabel'>{_('WindowsCredential')}</label>
			)}
			{!isEditMode && (
				<div className={styles.windowsCredentialSections}>
					<label id='operatorPassword' htmlFor='operatorPasswordInput'>
						{_('Password')}
					</label>
					<div>
						<Input.Password
							id='operatorPasswordInput'
							value={password}
							disabled={isEditMode || foundInAD || enableCustomGroupNames}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								dispatcher(setWindowsCredentialPropertyAction({ field: 'password', value: e.target.value }));
								dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
							}}
							maxLength={100}
							className={cx(styles.input, {
								[styles.error]: isPasswordError,
							})}
							autoComplete='new-password'
						/>
						{isPasswordError && <p className={styles.errorMessage}>{_('PasswordEntryError')}</p>}
					</div>
				</div>
			)}
			{!isEditMode && (
				<div className={styles.windowsCredentialSections}>
					<label id='operatorConfirmPassword' htmlFor='operatorConfirmPasswordInput'>
						{_('ConfirmPassword')}
					</label>
					<div>
						<Input.Password
							id='operatorConfirmPasswordInput'
							value={confirmPassword}
							disabled={isEditMode || foundInAD || enableCustomGroupNames}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								batch(() => {
									dispatcher(setWindowsCredentialPropertyAction({ field: 'confirmPassword', value: e.target.value }));
									dispatcher(setOperatorPropAction({ field: 'Password', value: e.target.value }));
									dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
								});
							}}
							maxLength={100}
							className={cx(styles.input, {
								[styles.error]: isConfirmPasswordError,
							})}
						/>
						{isConfirmPasswordError && <p className={styles.errorMessage}>{_('ConfirmPasswordEntryError')}</p>}
					</div>
				</div>
			)}
			<div
				id='operatorCredentialOptions'
				className={cx(styles.optionCheckboxes, {
					[styles.optionCheckboxesEditMode]: isEditMode,
				})}>
				{false && (
					<Checkbox
						id='isPasswordExpiredCb'
						disabled={isEditMode || IsCannotChangePassword || IsPasswordNeverExpires || foundInAD || enableCustomGroupNames}
						checked={IsPasswordExpired}
						onChange={(e: CheckboxChangeEvent) => {
							batch(() => {
								dispatcher(setOperatorPasswordFlagsAction({ ...Flags, IsPasswordExpired: e.target.checked }));
								dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
							});
						}}>
						{_('MustChangePassword')}
					</Checkbox>
				)}
				<Checkbox
					id='isCannotChangePasswordCb'
					disabled={isEditMode || IsPasswordExpired || foundInAD || enableCustomGroupNames}
					checked={IsCannotChangePassword}
					onChange={(e: CheckboxChangeEvent) => {
						batch(() => {
							dispatcher(setOperatorPasswordFlagsAction({ ...Flags, IsCannotChangePassword: e.target.checked }));
							dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
						});
					}}>
					{_('CannotChangePassword')}
				</Checkbox>
				<Checkbox
					id='isPasswordNeverExpiresCb'
					disabled={isEditMode || IsPasswordExpired || foundInAD || enableCustomGroupNames}
					checked={IsPasswordNeverExpires}
					onChange={(e: CheckboxChangeEvent) => {
						batch(() => {
							dispatcher(setOperatorPasswordFlagsAction({ ...Flags, IsPasswordNeverExpires: e.target.checked }));
							dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
						});
					}}>
					{_('PasswordNeverExpires')}
				</Checkbox>
				<Checkbox
					id='isAccountDisabledCb'
					disabled={isEditMode || foundInAD || enableCustomGroupNames}
					checked={IsAccountDisabled}
					onChange={(e: CheckboxChangeEvent) => {
						batch(() => {
							dispatcher(setOperatorPasswordFlagsAction({ ...Flags, IsAccountDisabled: e.target.checked }));
							dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
						});
					}}>
					{_('AccountIsDisabled')}
				</Checkbox>
				{isEditMode || foundInAD ? (
					<Checkbox
						id='isAccountLockedOutCb'
						disabled={isEditMode || foundInAD || enableCustomGroupNames}
						checked={IsAccountLockedOut}
						onChange={(e: CheckboxChangeEvent) => {
							batch(() => {
								dispatcher(setOperatorPasswordFlagsAction({ ...Flags, IsAccountLockedOut: e.target.checked }));
								dispatcher(setRootModelPropertyAction({ field: 'isGeneralTabModified', value: true }));
							});
						}}>
						{_('AccountIsLockedOut')}
					</Checkbox>
				) : null}
			</div>
			{!isEditMode ? (
				<div id='operatorCredentialFindButton' className={styles.containerButton}>
					<Button
						id='findButton'
						key='operatorFindButton'
						onClick={() => {
							setIsFindModalOpen(true);
						}}
						disabled={!isFindUserEnabled()}
						style={{ marginLeft: 'auto' }}
						title={_('FindUsers')}
						type='primary'>
						{<PlusOutlined />} {_('Find')}
					</Button>
				</div>
			) : (
				<></>
			)}
			{renderFindUserModal()}
		</div>
	);
};

export { WindowsCredential };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkboxes-container--bzfp5-Uauapt3ieoH1ZQr{display:flex;justify-content:flex-start;align-items:start}.checkboxes-container--bzfp5-Uauapt3ieoH1ZQr .checkboxes-checkBoxMargin--2x8XZw2u5ll1Fjbw1sWvWN{margin-right:7%}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/DigiTrac/InputModal/tabs/General/sections/CheckBoxes/checkboxes.module.scss"],"names":[],"mappings":"AAAA,6CACC,YAAA,CACA,0BAAA,CACA,iBAAA,CAEA,gGACC,eAAA","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tjustify-content: flex-start;\r\n\talign-items: start;\r\n\r\n\t.checkBoxMargin {\r\n\t\tmargin-right: 7%;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "checkboxes-container--bzfp5-Uauapt3ieoH1ZQr",
	"checkBoxMargin": "checkboxes-checkBoxMargin--2x8XZw2u5ll1Fjbw1sWvWN"
};
export default ___CSS_LOADER_EXPORT___;

import { Spin, Tabs } from 'antd';
import React, { useState } from 'react';
import { HeaderBar } from '../../components/common';
import { CredentialTab, EnrollmentTab } from '../../components/enrollment';
import { EventTable } from '../../components/event';
import { User } from '../../model/AccountModel';
import { EnrollmentTabKey } from '../../model/EnrollmentModel';
import { useStoreSelector } from '../../store';
import { selectFetchingScanData } from '../../store/enrollment/selectors';
import './enrollment.scss';

const user: User = getUser();
const { showEventsOnEnrollmentPage } = user;
const { TabPane } = Tabs;

const EnrollmentPage: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeKey, setActiveKey] = useState<EnrollmentTabKey>(EnrollmentTabKey.Enrollment);

	const fetchingScanData: boolean = useStoreSelector<boolean>(selectFetchingScanData);

	const handleOnChangeActiveKey = (key: string) => {
		setActiveKey(Number(key));
	};

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			<HeaderBar title={_('Enrollment')} />
			<Tabs className='enrollmentTab' type='card' destroyInactiveTabPane={false} activeKey={activeKey.toString()} onChange={handleOnChangeActiveKey}>
				<TabPane key={EnrollmentTabKey.Enrollment} tab={<span data-test-id='credentialEnrollmentTab'>{_('Enrollment')}</span>}>
					<EnrollmentTab />
				</TabPane>
				<TabPane
					key={EnrollmentTabKey.UnassignedAndGuestCredentials}
					tab={
						<span data-leavetab={true} data-test-id='credentialUnassignedAndGuestTab' className='tabTitleContent'>
							{_('UnassignedAndGuestCredentials')}
						</span>
					}
					disabled={fetchingScanData}>
					<CredentialTab />
				</TabPane>
			</Tabs>
			{showEventsOnEnrollmentPage && <EventTable />}
		</Spin>
	);
};

export { EnrollmentPage };

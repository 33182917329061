import { Form, FormInstance, Input, Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { enrollmentApi } from '../../../../../../../api';
import { handleResponse } from '../../../../../../../Helper';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { AdditionalNote, AdditionalNotesCategory, PersonDetailModel, PersonFormModel } from '../../../../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setHavePersonChanged } from '../../../../../../../store/enrollment/actions';
import { selectHavePersonChanged, selectPersonId } from '../../../../../../../store/enrollment/selectors';
import { Select, SelectOption } from '../../../../../../common';
import styles from './additionalNotes.module.scss';

const { TextArea } = Input;
const personFormObject: PersonFormModel = new PersonFormModel();
const { CategoryId, NoteContent } = personFormObject.FormKeysObject;
const categoryDropdownId: string = 'enrollmentAdditionalNoteDropdown';

type Props = {
	form: FormInstance<PersonDetailModel>;
};

const AdditionalNotes: React.FC<Props> = ({ form }) => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [prevCategoryId, setPrevCategoryId] = useState<number>(undefined);
	const [categories, setCategories] = useState<AdditionalNotesCategory[]>([]);

	const personId: number = useStoreSelector<number>(selectPersonId);
	const havePersonChanged: boolean = useStoreSelector<boolean>(selectHavePersonChanged);

	useEffect(() => {
		enrollmentApi
			.getAdditionalNotesCategories()
			.then(response => {
				if (!handleResponse(response)) {
					restartAdditionalNotes(response.Entity);
					setCategories(response.Entity);
				}
			})
			.finally(() => setIsLoading(false));
	}, []);

	const restartAdditionalNotes = (categories: AdditionalNotesCategory[]): void => {
		if (categories.length > 0) {
			const categoryId: string = categories[0].CategoryId.toString();
			form.setFieldsValue({
				...form.getFieldsValue(true),
				CategoryId: categoryId,
			});
			setPrevCategoryId(Number(categoryId));
			handleOnChangeCategory(categoryId);
		}
	};

	const handleOnChangeCategory = (value: string): void => {
		const additionalNotes: AdditionalNote[] = form.getFieldsValue().AdditionalNotes ?? [];

		const currentAdditionalNote: AdditionalNote = additionalNotes.find((additionalNote: AdditionalNote) => additionalNote.CategoryId === Number(value));
		const prevAdditionalNote: AdditionalNote = additionalNotes.find((additionalNote: AdditionalNote) => additionalNote.CategoryId === prevCategoryId);
		if (prevAdditionalNote) {
			prevAdditionalNote.NoteContent = form.getFieldsValue().NoteContent;
		}

		if (currentAdditionalNote) {
			form.setFieldsValue({
				...form.getFieldsValue(true),
				AdditionalNotes: additionalNotes,
				NoteContent: currentAdditionalNote.NoteContent,
			});
		} else {
			const categoryId: string = form.getFieldsValue().CategoryId;
			setIsLoading(true);
			enrollmentApi
				.getAdditionalNote(Number(categoryId), personId)
				.then(response => {
					if (!handleResponse(response)) {
						form.setFieldsValue({
							...form.getFieldsValue(true),
							AdditionalNotes: [...additionalNotes, response.Entity],
							NoteContent: response.Entity.NoteContent,
						});
					}
				})
				.finally(() => setIsLoading(false));
		}

		setPrevCategoryId(Number(value));
	};

	const handleOnChangeNoteContent = () => {
		if (!havePersonChanged) {
			dispatch(setHavePersonChanged(true));
		}
	};

	const emptyCategoryList: boolean = categories.length === 0;
	const selectOptions: SelectOption = useMemo(
		() =>
			categories.map<SelectOptions<number>>((category: AdditionalNotesCategory) => ({
				id: `enrollmentAdditionalNotesCategory-${category.CategoryId}`,
				label: category.CategoryName,
				value: category.CategoryId,
			})),
		[categories]
	);

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			<div className={styles.additionalNotesContainer}>
				<Form.Item name={CategoryId} htmlFor={categoryDropdownId} label={_('SelectCategory')}>
					<Select
						id={categoryDropdownId}
						onChange={handleOnChangeCategory}
						options={selectOptions}
						className={styles.category}
						disabled={emptyCategoryList}
					/>
				</Form.Item>
				<Form.Item name={NoteContent}>
					<TextArea
						id='enrollmentAdditionalNoteInput'
						maxLength={2048}
						disabled={emptyCategoryList}
						className={styles.additionalNotesData}
						aria-label={_('AdditionalNoteData')}
						onChange={handleOnChangeNoteContent}
					/>
				</Form.Item>
			</div>
		</Spin>
	);
};

export { AdditionalNotes };

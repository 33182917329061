import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { buildColumn, ScrollType } from '../../../../../../../Helper';
import { DataTableType } from '../../../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../../../common';
import { ControllersSelected, setSelectedReadersGridInfo, StoreContext } from '../../contextReader';
import styles from './infoGrid.module.scss';

type Props = {
	onClickRow: (reader: ControllersSelected) => void;
};

type DataType = DataTableType<ControllersSelected>;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 210 };

const InfoGrid: React.FC<Props> = ({ onClickRow }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([] as React.Key[]);
	const [active, setActive] = useState(0);

	const {
		readerState: { readersSelected, readersInfoGridSelected },
		dispatcher,
	} = useContext(StoreContext);

	//reset state on items selected has been removed from InfoGrid
	useEffect(() => {
		if (readersInfoGridSelected.length === 0) {
			setSelectedRowKeys([]);
		}
	}, [JSON.stringify(readersInfoGridSelected)]);

	//keep until any bug has been found
	// const handleCheck = (checked: boolean, readerId: number) => {
	// 	if (checked) {
	// 		dispatcher(setSelectedReadersGridInfo([...readersInfoGridSelected, readerId]));
	// 	} else {
	// 		const cloneState = readersInfoGridSelected;
	// 		const index = cloneState.findIndex(x => x === readerId);
	// 		if (~index) {
	// 			cloneState.splice(index, 1);
	// 			dispatcher(setSelectedReadersGridInfo([...cloneState]));
	// 		}
	// 	}
	// };

	const handleSelectAll = (checked: boolean) => {
		if (checked) {
			dispatcher(setSelectedReadersGridInfo([...readersSelected.map(x => x.ReaderId)]));
		} else {
			dispatcher(setSelectedReadersGridInfo([]));
		}
	};

	const handleClickRow = (reader: DataType) => {
		let selectedRows: React.Key[] = [];
		let doorSelected: number[] = [];
		if (selectedRowKeys.find(x => x === reader.key)) {
			selectedRows = selectedRowKeys.filter(readerSelected => readerSelected !== reader.key);
			doorSelected = readersInfoGridSelected.filter(readerSelected => readerSelected !== reader.ReaderId);
		} else {
			selectedRows = [...selectedRowKeys, reader.key];
			doorSelected = [...readersInfoGridSelected, reader.ReaderId];
		}
		setSelectedRowKeys(selectedRows);
		dispatcher(setSelectedReadersGridInfo(doorSelected));
	};

	// rowSelection object indicates the need for row selection
	const rowSelection: TableRowSelection<DataType> = {
		selectedRowKeys,
		onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			setSelectedRowKeys(selectedRowKeys);
			dispatcher(setSelectedReadersGridInfo(selectedRows.map(x => x.ReaderId)));
		},
		// onSelect: (record: DataType, selected: boolean) => {
		// 	handleCheck(selected, record.ReaderId);
		// },
		onSelectAll: (selected: boolean) => {
			handleSelectAll(selected);
		},
		getCheckboxProps: (record: DataType) => ({
			id: `readerControlGroupSelectedReadersCheckBox-${record.ReaderId}`,
		}),
	};

	const createData = (data: ControllersSelected[]) =>
		data.map<DataType>((dataItem: ControllersSelected, index: number) => ({
			key: dataItem.ReaderId,
			...dataItem,
		}));

	const columns: ColumnsType<any> = [
		{
			...buildColumn(_('Address'), 'Address', '210px', 'start'),
			sorter: (a, b) => a.Address.localeCompare(b.Address),
		},
		{
			...buildColumn(_('Readers'), 'ReaderName', 'auto', 'start'),
			sorter: (a, b) => a.ReaderName.localeCompare(b.ReaderName),
			...SearchColumn({ maxLength: 32, dataIndex: 'ReaderName', reset: false, label: _('ReaderName'), searchColumnId: 'searchInfoGrid' }),
		},
		{
			...buildColumn(_('ControlZone'), 'ControlZoneName', '150px', 'start'),
			sorter: (a, b) => a.ControlZoneName.localeCompare(b.ControlZoneName),
		},
	];

	const readersSelectedDataTable = createData(readersSelected);

	return (
		<div className={styles.container}>
			<Table
				id='readerControlGroupSelectedReadersTable'
				rowSelection={{
					...rowSelection,
				}}
				onRow={(record: DataType) => ({
					onClick: () => handleClickRow(record),
				})}
				size='small'
				columns={columns}
				dataSource={readersSelectedDataTable}
				scroll={scroll}
				className={styles.statusWidgetTable}
				pagination={false}
				onHeaderRow={() => ({ id: 'readerControlGroupSelectedReadersTableHeader' })}
				getPopupContainer={() => document.getElementById('readerControlGroupSelectedReadersTableHeader')}
			/>
		</div>
	);
};

export { InfoGrid };

import React, { useEffect, useState } from 'react';
import { lockedValidation, unlockHandleBeforeUnload } from '../../../../Helper';
import { CurrentDeviceControlObj, DeviceObjectType } from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { selectCurrentDevice } from '../../../../store/common/selectors';

/**
 *
 * @param WrappedComponent component to be showed after loading is true
 */
const WithLockedValidation = <T extends {}>(WrappedComponent: React.FC<T>) => {
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const ComponentWithLockedValidation: React.FC<T> = props => {
		const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);
		const [isLocked, setIsLocked] = useState(true);
		const dispatch = useStoreDispatch();
		useEffect(() => {
			const isController = new URLSearchParams(location.search).get('controllerId');
			let deviceId: number = currentDevice.Id;
			let deviceObjectType: DeviceObjectType = currentDevice.DeviceObjectType;
			const isNewCredential: boolean =
				currentDevice.IsNew &&
				(deviceObjectType === DeviceObjectType.CredentialTemplates || deviceObjectType === DeviceObjectType.EnrollmentCredential);

			if (isController) {
				deviceObjectType = DeviceObjectType.Controller;
				deviceId = Number(isController);
			} else if (isNewCredential) {
				deviceId = -1;
			}

			lockedValidation(deviceObjectType, deviceId, true).then(res => {
				setIsLocked(res);
				if (res) {
					if (isController) {
						window.history.replaceState({}, '', location.pathname);
					}
					dispatch(setCurrentDevice({ ...currentDevice, IsModalOpen: false }));
				} else if (isController) {
					dispatch(setCurrentDevice({ ...currentDevice, IsModalOpen: true }));
				}
			});

			if (!isNewCredential) {
				window.addEventListener('beforeunload', handleBeforeUnload);
			}

			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}, []);

		const handleBeforeUnload = () => {
			unlockHandleBeforeUnload(currentDevice.Id, currentDevice.DeviceObjectType);
		};

		return isLocked ? null : <WrappedComponent {...(props as T)} />;
	};

	ComponentWithLockedValidation.displayName = `withLockedValidation(${displayName})`;

	return ComponentWithLockedValidation;
};

export { WithLockedValidation };

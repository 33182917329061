import cx from 'classnames';
import React, { useContext } from 'react';
import { Select } from '../../../../../common';
import { setXAddress, StoreContext } from '../../contextXbox';
import styles from '../../xboxModal.module.scss';

const XAddress: React.FC = () => {
	const {
		xboxState: { xAddresses, selections },
		dispatcher,
	} = useContext(StoreContext);

	const xAddressValue = selections.xAddress === 0 ? undefined : selections.xAddress.toString();
	const portContainerDiv: string = 'xboxXAddressContainer';
	const xboxXAddressDropdownId = 'xboxXAddressDropdown';

	return (
		<div className={styles.sections} id={portContainerDiv}>
			<label htmlFor={xboxXAddressDropdownId}>{_('Address')}</label>
			<div>
				<Select
					id={xboxXAddressDropdownId}
					className={cx(styles.selectXAddress)}
					options={xAddresses.map(x => ({ label: x.toString(), value: x.toString(), id: x.toString() }))}
					onChange={value => {
						dispatcher(setXAddress(Number(value)));
					}}
					disabled={xAddresses.length === 0 || selections.port === 0}
					key='xAddress'
					value={xAddressValue}
				/>
			</div>
		</div>
	);
};

export { XAddress };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';

type Props = {
	useDoorContext: boolean;
};

const DisableState: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleChange = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ DisabledStateChangeReportingWhenMasked: e.target.checked }));
	};

	return (
		<div>
			<Checkbox
				id='stateChangeReportingInputDisableStateChangeCheckbox'
				checked={inputContext.context?.input?.DisabledStateChangeReportingWhenMasked}
				onChange={handleChange}>
				{_('DisableStateChangeReportingWhenMasked')}
			</Checkbox>
		</div>
	);
};

export { DisableState };

import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Form, Input, Radio, Spin, Table, notification } from 'antd';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { ColumnsProps, ScrollType, buildColumn, filterOption, handleResponse } from '../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../api';
import { BaseColumns, ResponseObjectEntity, ResponseStatusCode, SelectOptions } from '../../../../../../model/CommonModel';
import {
	ReaderContextType,
	ReaderError,
	ScrambleFactorImage,
	ScrambleFactorImageAction,
	ScrambleFactorImageCategories,
	ScrambleFactorImageShort,
	SelectOption,
} from '../../../../../../model/DeviceAdminModel';
import { Logger } from '../../../../../../model/LoggingModel';
import { ModalConfirmation, Select } from '../../../../../common';
import { Action, DoorStoreSingleContext } from '../../../DoorModal/DoorStoreSingleContext';
import {
	ExitReaderActionDefinition,
	ExitViewPinEntryIndicator,
	SetExitReaderErrorImageCategory,
	SetExitReaderErrorMessage,
	SetExitReaderErrorType,
	setExitReaderErrorMessage,
	setExitReaderErrorType,
	setExitScrambleFactorCategories,
	setExitScrambleFactorErrorImageCategory,
	setExitSelectedFactorImages,
	setExitViewPinEntryIndicator,
} from '../../ExitReaderModal/exitReaderContext';
import {
	ReaderActionContext,
	ReaderActionDefinition,
	SetReaderErrorImageCategory,
	SetReaderErrorMessage,
	SetReaderErrorType,
	StoreContext,
	ViewPinEntryIndicator,
	setReaderErrorMessage,
	setReaderErrorType,
	setScrambleFactorCategories,
	setScrambleFactorErrorImageCategory,
	setSelectedFactorImages,
	setViewPinEntryIndicator,
} from '../../contextReader';
import { ImportImageModal } from './ImportImageModal/ImportImageModal';
import styles from './scrambleFactor.module.scss';

//#region types/enums/interfaces
type Props = {
	readerId: number;
	readerContextType: ReaderContextType;
};

type ScrambleFactorType = {
	Category: ReactNode;
	Name: ReactNode;
	/* Action: ReactNode; */
} & BaseColumns;
//#endregion

type ScrambleFactorShort = { ID: string; isDirty?: boolean };
type SelectedUpdateImage = { categoryId: number; imageId: number | string };
type DoorScrambleFactorCategory = (categories: ScrambleFactorImageCategories[]) => void;
const scroll: ScrollType = { x: 379 };

export const isImageNameUniqueInLocalCategories = (scrambleFactorImageCategories: ScrambleFactorImageCategories[], imageName: string) => {
	scrambleFactorImageCategories.forEach((category: ScrambleFactorImageCategories) => {
		const existingImage: ScrambleFactorImage = category.ScrambleFactorImages.find(
			(scrambleFactorImage: ScrambleFactorImage) => scrambleFactorImage.Name === imageName
		);
		if (existingImage) {
			return false;
		}
	});

	return true;
};

const ScrambleFactor: React.FC<Props> = ({ readerContextType, readerId }) => {
	const [scramblePadTypeCategory, setScramblePadTypeCategory] = useState<ScrambleFactorImageCategories>();
	const [scramblePadTypes, setScramblePadTypes] = useState<ScrambleFactorImage[]>();
	const [selectedPadType, setSelectedPadType] = useState<string>('0');
	const [selectedCategoryImages, setSelectedCategoryImages] = useState<Map<string, ScrambleFactorShort>>(new Map<string, ScrambleFactorShort>());
	const [selectedCategoryImage, setSelectedCategoryImage] = useState<JSX.Element>();
	const [selectedCategoryImageInfo, setSelectedCategoryImageInfo] = useState<ScrambleFactorImageCategories>();
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isControlDirty, setIsControlDirty] = useState<boolean>(false);
	const [readerName, setReaderName] = useState<string>();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [editingKey, setEditingKey] = useState<string>('');
	const [firstEditingImgNameRender, setFirstEditingImgNameRender] = useState<boolean>(true);
	const [isBitMaskActive, setIsBitMaskActive] = useState<boolean>(false);
	const [disableActionButtons, setDisableActionButtons] = useState<boolean>(false);
	const [form] = Form.useForm();
	const editingNameValue = Form.useWatch('Name', form);

	let dispatcher: React.Dispatch<Action | ReaderActionContext>;
	let rS485ReaderTypes: SelectOption[];
	let rS485ReaderType: number;
	let scrambleFactorCategories: ScrambleFactorImageCategories[];
	let readerErrorMessage: string;
	let readerErrorType: ReaderError;
	let updateScrambleFactorSelection: boolean;
	let readerErrorImageCategory: string;
	let setSelectedFactorImagesAction: ReaderActionDefinition | ExitReaderActionDefinition;
	let setScrambleFactorViewPinEntryIndicatorAction: ViewPinEntryIndicator | ExitViewPinEntryIndicator;
	let setScrambleFactorCategoriesAction: DoorScrambleFactorCategory;
	let setReaderErrorTypeAction: SetReaderErrorType | SetExitReaderErrorType;
	let setReaderErrorMessageAction: SetReaderErrorMessage | SetExitReaderErrorMessage;
	let setReaderErrorImageCategoryAction: SetReaderErrorImageCategory | SetExitReaderErrorImageCategory;

	switch (readerContextType) {
		case ReaderContextType.Door: {
			const ctx = useContext(DoorStoreSingleContext);
			rS485ReaderTypes = ctx.contextStateDoor.entryReader.rS485ReaderTypes;
			rS485ReaderType = ctx.contextStateDoor.entryReader.selections.rS485ReaderType;
			scrambleFactorCategories = ctx.contextStateDoor.entryReader.scrambleFactorCategories;
			readerErrorMessage = ctx.contextStateDoor.entryReader.readerErrorMessage;
			readerErrorType = ctx.contextStateDoor.entryReader.readerErrorType;
			updateScrambleFactorSelection = ctx.contextStateDoor.entryReader.updateScrambleFactorSelection;
			readerErrorImageCategory = ctx.contextStateDoor.entryReader.scrambleFactorErrorImageCategory;
			dispatcher = ctx.dispatcherDoor;
			setSelectedFactorImagesAction = setSelectedFactorImages;
			setScrambleFactorViewPinEntryIndicatorAction = setViewPinEntryIndicator;
			setScrambleFactorCategoriesAction = categories => setDoorScrambleFactorCategoriesAction(dispatcher, categories);
			setReaderErrorTypeAction = setReaderErrorType;
			setReaderErrorMessageAction = setReaderErrorMessage;
			setReaderErrorImageCategoryAction = setScrambleFactorErrorImageCategory;
			break;
		}
		case ReaderContextType.ExitReader: {
			const ctx = useContext(DoorStoreSingleContext);
			rS485ReaderTypes = ctx.contextStateDoor.exitReader.rS485ReaderTypes;
			rS485ReaderType = ctx.contextStateDoor.exitReader.selections.rS485ReaderType;
			scrambleFactorCategories = ctx.contextStateDoor.exitReader.scrambleFactorCategories;
			readerErrorMessage = ctx.contextStateDoor.exitReader.readerErrorMessage;
			readerErrorType = ctx.contextStateDoor.exitReader.readerErrorType;
			updateScrambleFactorSelection = ctx.contextStateDoor.exitReader.updateScrambleFactorSelection;
			readerErrorImageCategory = ctx.contextStateDoor.exitReader.scrambleFactorErrorImageCategory;
			dispatcher = ctx.dispatcherDoor;
			setSelectedFactorImagesAction = setExitSelectedFactorImages;
			setScrambleFactorViewPinEntryIndicatorAction = setExitViewPinEntryIndicator;
			setScrambleFactorCategoriesAction = categories => setDoorScrambleFactorCategoriesAction(dispatcher, categories);
			setReaderErrorTypeAction = setExitReaderErrorType;
			setReaderErrorMessageAction = setExitReaderErrorMessage;
			setReaderErrorImageCategoryAction = setExitScrambleFactorErrorImageCategory;
			break;
		}
		default: {
			const ctx = useContext(StoreContext);
			rS485ReaderTypes = ctx.readerState.rS485ReaderTypes;
			rS485ReaderType = ctx.readerState.selections.rS485ReaderType;
			scrambleFactorCategories = ctx.readerState.scrambleFactorCategories;
			readerErrorMessage = ctx.readerState.readerErrorMessage;
			readerErrorType = ctx.readerState.readerErrorType;
			updateScrambleFactorSelection = ctx.readerState.updateScrambleFactorSelection;
			readerErrorImageCategory = ctx.readerState.scrambleFactorErrorImageCategory;
			dispatcher = ctx.dispatcher;
			setSelectedFactorImagesAction = setSelectedFactorImages;
			setScrambleFactorViewPinEntryIndicatorAction = setViewPinEntryIndicator;
			setScrambleFactorCategoriesAction = categories => dispatcher(setScrambleFactorCategories(categories));
			setReaderErrorTypeAction = setReaderErrorType;
			setReaderErrorMessageAction = setReaderErrorMessage;
			setReaderErrorImageCategoryAction = setScrambleFactorErrorImageCategory;
		}
	}

	useEffect(() => {
		setLoading(true);

		Promise.all([deviceAdminApi.get3FRReaderBitMasks(readerId), deviceAdminApi.getScrambleFactorCategories(readerId)])
			.then((response: [ResponseObjectEntity<boolean>, ResponseObjectEntity<ScrambleFactorImageCategories[]>]) => {
				const [bitMaskActiveResponse, scrambleFactorCategoriesResponse] = response;
				if (!handleResponse(bitMaskActiveResponse)) {
					setIsBitMaskActive(bitMaskActiveResponse.Entity);
					dispatcher(setScrambleFactorViewPinEntryIndicatorAction(bitMaskActiveResponse.Entity));
				}

				if (!handleResponse(scrambleFactorCategoriesResponse) && scrambleFactorCategoriesResponse.Entity?.length > 0) {
					handleOnGetScrambleFactorCategories(scrambleFactorCategoriesResponse.Entity);
				}
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		const item = rS485ReaderTypes.find(t => t.Id === rS485ReaderType);
		if (item) {
			setReaderName(item.Name);
		}
	}, [rS485ReaderType, rS485ReaderTypes]);

	useEffect(() => {
		const selectedFactorImages: ScrambleFactorImageShort[] = [
			{
				ID: parseInt(selectedPadType),
				Dirty: false,
			},
		];
		if (selectedCategoryImages.size > 0) {
			selectedCategoryImages.forEach(imgId => {
				const numberId = Number(imgId.ID);
				if (numberId > 0) {
					selectedFactorImages.push({
						ID: numberId,
						Dirty: imgId.isDirty ?? false,
					});
				} else if (isNaN(numberId)) {
					selectedFactorImages.push({
						AddGUID: imgId.ID,
						ID: 0,
						Dirty: imgId.isDirty ?? false,
					});
				}
			});
		}
		dispatcher(setSelectedFactorImagesAction(selectedFactorImages));
	}, [selectedCategoryImages, selectedPadType]);

	useEffect(() => {
		const categoryImages = Array.from(selectedCategoryImages, ([_, value]) => ({ value }));
		const dirty = categoryImages.some(cat => {
			if (cat.value.isDirty) return cat.value.isDirty == true;
			return false;
		});
		setIsControlDirty(dirty);
	}, [selectedCategoryImages]);

	useEffect(() => {
		selectedCategoryImages.forEach(imageInDropdown => {
			scrambleFactorCategories.forEach(category => {
				const selectedImage = category.ScrambleFactorImages.find(scrambleFactorImage => scrambleFactorImage.ID.toString() === imageInDropdown.ID);
				if (selectedImage && selectedImage.Action === ScrambleFactorImageAction.Delete) {
					handleOnChangeCategory(scrambleFactorCategories, category.ID, '-1');
				}
			});
		});
	}, [scrambleFactorCategories]);

	useEffect(() => {
		if (scrambleFactorCategories) {
			refreshDeleteButtons(
				scrambleFactorCategories.filter(c => c.ID !== 1),
				scrambleFactorCategories,
				scrambleFactorImage => {
					let retValue = false;
					selectedCategoryImages.forEach(imageInDropdown => {
						if (scrambleFactorImage.ID.toString() === imageInDropdown.ID) {
							retValue = true;
						}
					});

					return retValue;
				}
			);
		}
	}, [updateScrambleFactorSelection]);

	const setDoorScrambleFactorCategoriesAction = (
		dispatcherLocal: React.Dispatch<Action | ReaderActionContext>,
		categories: ScrambleFactorImageCategories[]
	) => {
		dispatcherLocal(setScrambleFactorCategories(categories));
		dispatcherLocal(setExitScrambleFactorCategories(categories));
	};

	const handleOnGetScrambleFactorCategories = async (newScrambleFactorCategories: ScrambleFactorImageCategories[]) => {
		const padTypesCat: ScrambleFactorImageCategories = newScrambleFactorCategories[0];
		const otherScrambleFactorCategories: ScrambleFactorImageCategories[] = newScrambleFactorCategories.filter(c => c.ID !== 1);
		const selectedScrambleFactorCategories = getSelectedScrambleFactorCategories(otherScrambleFactorCategories);

		batch(() => {
			setScramblePadTypes(scramblePadTypes);
			setScramblePadTypeCategory(padTypesCat);
			if (scrambleFactorCategories && scrambleFactorCategories.length === 0) {
				setScrambleFactorCategoriesAction(otherScrambleFactorCategories);
			} else {
				refreshDeleteButtons(otherScrambleFactorCategories, scrambleFactorCategories, scrambleFactorImage => scrambleFactorImage.Selected);
			}
			setSelectedCategoryImages(selectedScrambleFactorCategories);

			if (padTypesCat?.ScrambleFactorImages?.length > 0) {
				const savedScramblePadType: ScrambleFactorImage = padTypesCat?.ScrambleFactorImages?.find((image: ScrambleFactorImage) => image.Selected);
				let selectedScramblePadType: string = padTypesCat.ScrambleFactorImages[0].ID?.toString() || '0';

				if (savedScramblePadType) {
					selectedScramblePadType = savedScramblePadType.ID.toString();
				}

				setSelectedPadType(selectedScramblePadType);
				setScramblePadTypes(padTypesCat.ScrambleFactorImages);
			}
		});
	};

	const refreshDeleteButtons = (
		storedScrambleFactorCategories: ScrambleFactorImageCategories[],
		stateScrambleFactorCategories: ScrambleFactorImageCategories[],
		findSelectedImages: (scrambleFactorImage) => boolean
	) => {
		const updateObjects: SelectedUpdateImage[] = [];
		const promises: Promise<ResponseObjectEntity<boolean>>[] = [];

		storedScrambleFactorCategories.forEach((category: ScrambleFactorImageCategories) => {
			const selectedImage: ScrambleFactorImage = category.ScrambleFactorImages.find(findSelectedImages);
			if (selectedImage) {
				promises.push(deviceAdminApi.canImageBeDeleted(Number(selectedImage.ID), readerId));
				updateObjects.push({ categoryId: category.ID, imageId: selectedImage.ID });
			}
		});

		if (promises.length > 0) {
			Promise.all(promises)
				.then((response: ResponseObjectEntity<boolean>[]) => {
					for (let index = 0; index < updateObjects.length; index++) {
						stateScrambleFactorCategories.forEach((category: ScrambleFactorImageCategories) => {
							category.ScrambleFactorImages.forEach((image: ScrambleFactorImage) => {
								if (image.ID === updateObjects[index].imageId && category.ID === updateObjects[index].categoryId) {
									image.CanBeDeleted = response[index].Entity;
									image.Selected = true;
								}
							});
						});
					}

					setScrambleFactorCategoriesAction([...stateScrambleFactorCategories]);
				})
				.catch(err => {
					Logger.writeErrorLog(`${err.name}: ${err.message}`);
				});
		}
	};

	const getSelectedScrambleFactorCategories = (scrambleFactorCategories: ScrambleFactorImageCategories[]): Map<string, ScrambleFactorShort> => {
		const newSelectedCategoryImages = new Map<string, ScrambleFactorShort>();

		scrambleFactorCategories?.forEach((category: ScrambleFactorImageCategories) => {
			category.ScrambleFactorImages?.filter((image: ScrambleFactorImage) => image.Selected).forEach((image: ScrambleFactorImage) =>
				newSelectedCategoryImages.set(category.ID.toString(), { ID: image.ID.toString() })
			);
		});

		return newSelectedCategoryImages;
	};

	const columns: ColumnsProps<ScrambleFactorType>[] = [
		{
			...buildColumn('Category', 'Category', '9em', 'start'),
		},
		{
			...buildColumn('Name', 'Name', 'auto', 'start'),
		},
	];

	const renderCurrentPadImage = () => {
		if (scramblePadTypes?.length > 0 && scramblePadTypeCategory) {
			const image = scramblePadTypes.find(image => image.ID === parseInt(selectedPadType));
			if (image) {
				return (
					<img
						src={`data:image/JPG;base64,${image.Image}`}
						alt='keypad'
						className={styles.scImgSource}
						style={{ height: scramblePadTypeCategory.Height, width: scramblePadTypeCategory.Width }}
					/>
				);
			}
		}
		return '';
	};

	const handleOnChangeCategory = async (sfic: ScrambleFactorImageCategories[], catId: number, imgId: string) => {
		const newSelectedCategoryImages = new Map<string, ScrambleFactorShort>(selectedCategoryImages);
		newSelectedCategoryImages.set(catId.toString(), { ID: imgId, isDirty: true });
		let currentImg: ScrambleFactorImage = null;
		let newScrambleFactorCategories: ScrambleFactorImageCategories[] = null;
		if (imgId !== '-1') {
			let canImageBeDeleted = true;
			if (!isNaN(Number(imgId))) {
				canImageBeDeleted = await checkIfCanImageBeDeleted(parseInt(imgId), true);
			}
			newScrambleFactorCategories = [...sfic];
			const currentCat = newScrambleFactorCategories.find(c => c.ID === catId);
			if (currentCat) {
				currentImg = currentCat.ScrambleFactorImages.find(img => img.ID.toString() === imgId);
				if (currentImg) {
					currentImg.CanBeDeleted = canImageBeDeleted;
				}
			}
		}
		batch(() => {
			if (currentImg) {
				setScrambleFactorCategoriesAction(newScrambleFactorCategories);
			}
			setSelectedCategoryImages(newSelectedCategoryImages);
			setImg(sfic, catId.toString(), imgId);
			setSelectedRowKeys([catId]);
		});
	};

	const loadUploadModal = (currentInfo: ScrambleFactorImageCategories) => {
		batch(() => {
			setDisableActionButtons(true);
			setSelectedCategoryImageInfo(currentInfo);
			setShowModal(true);
		});
	};

	const hideUploadModal = () => {
		batch(() => {
			setSelectedCategoryImageInfo(undefined);
			setShowModal(false);
			setDisableActionButtons(false);
		});
	};

	const showNotificationResponseError = (msg?: string) => {
		notification.error({
			message: msg || _('AnErrorHasOcurred'),
		});
	};

	const getDisabledButtonState = (imgId: string) => {
		if (editingKey || Number(imgId) < 1 || disableActionButtons) return true;
		return false;
	};

	const handleOnCancelEditImageName = () => {
		batch(() => {
			setEditingKey('');
			setFirstEditingImgNameRender(true);
			form.setFieldValue('Name', '');
		});
	};

	const handleOnPerformSearch = debounce(async (categoryId: number, currentImage: ScrambleFactorImage) => {
		handleOnSaveEditImgName(categoryId, currentImage);
	}, 100);

	const renderEditMode = (catId: number): JSX.Element => {
		const currentCat = scrambleFactorCategories?.find(c => c.ID === catId);
		let selectedCategoryImage: string | number = Number(selectedCategoryImages.get(catId?.toString())?.ID || '-1');
		if (isNaN(selectedCategoryImage)) {
			selectedCategoryImage = selectedCategoryImages.get(catId?.toString())?.ID;
		}

		if (currentCat) {
			const currentImg = currentCat.ScrambleFactorImages?.find(img => img.ID === selectedCategoryImage);
			if (currentImg) {
				if (firstEditingImgNameRender) {
					form.setFieldValue('Name', currentImg.Name);
					setFirstEditingImgNameRender(false);
				}
				return (
					<div className={cx(styles.editImagecontainer, { [styles.scrambleNameEmpty]: !editingNameValue })}>
						<Form.Item name='Name'>
							<Input
								id={`editImageNameInput-${readerContextType}`}
								autoFocus
								maxLength={32}
								onFocus={e => e.target.select()}
								suffix={
									<>
										<Button
											id={`cancelEditImageButton-${readerContextType}`}
											icon={<CloseOutlined />}
											onClick={handleOnCancelEditImageName}
											type='link'
										/>
										<Button
											id={`saveEditImageButton-${readerContextType}`}
											disabled={!editingNameValue}
											icon={<CheckOutlined style={{ color: '#406080' }} />}
											onClick={() => handleOnSaveEditImgName(catId, currentImg)}
											type='link'
										/>
									</>
								}
								onPressEnter={e => handleOnPerformSearch(catId, currentImg)}
							/>
						</Form.Item>
						{!editingNameValue && <p>{_('ScrambleImageNameCannotBeEmpty')}</p>}
					</div>
				);
			}
		}
		return null;
	};

	const renderNormalMode = (cat: ScrambleFactorImageCategories) => {
		const defaultOpt: SelectOptions<string> = {
			key: '-1',
			label: _('Default'),
			value: '-1',
		};
		let catImages: SelectOptions<string>[] = [];

		const selectedCategoryImage = selectedCategoryImages?.get(cat.ID.toString())?.ID ?? '-1';
		let currentImg: ScrambleFactorImage = {
			CanBeDeleted: false,
			CategoryID: 0,
			Description: '',
			ID: 0,
			Image: '',
			Name: '',
			Selected: false,
		};

		if (cat.ScrambleFactorImages?.length > 0) {
			catImages = cat.ScrambleFactorImages.filter(x => x.Action !== ScrambleFactorImageAction.Delete).map(img => {
				if (img.ID.toString() === selectedCategoryImage) {
					currentImg = {
						...img,
					};
				}
				return {
					key: img.ID?.toString(),
					label: img.Name,
					value: img.ID?.toString(),
				};
			});
		}
		catImages.unshift(defaultOpt);

		return (
			<div className={styles.imageNameActionContainer}>
				<Select
					id={`scrambleFactorImageSelect-${cat.ID}-${readerContextType}`}
					className={styles.scSelectCategories}
					disabled={editingKey || disableActionButtons ? true : false}
					options={catImages}
					onChange={(value: string) => handleOnChangeCategory(scrambleFactorCategories, cat.ID, value)}
					value={selectedCategoryImage}
					showSearch={true}
					filterOption={filterOption}
				/>
				<div className={styles.imageNameActionButtonsContainer}>
					<Button
						id={`scrambleFactorAddImageButton-${cat.ID}-${readerContextType}`}
						className={styles.scActionButton}
						disabled={editingKey || disableActionButtons ? true : false}
						onClick={() => loadUploadModal(cat)}
						icon={<PlusOutlined />}
						shape='circle'
						type='primary'
					/>
					<Button
						id={`scrambleFactorEditImageNameButton-${cat.ID}-${readerContextType}`}
						className={styles.scActionButton}
						disabled={getDisabledButtonState(selectedCategoryImage)}
						onClick={() => {
							setEditingKey(cat.ID?.toString());
							resetErrorMessage(readerErrorType);
						}}
						icon={<EditOutlined />}
						shape='circle'
						type='primary'
					/>
					<Button
						id={`scrambleFactorDeleteImageButton-${cat.ID}-${readerContextType}`}
						className={styles.scActionButton}
						disabled={!currentImg.CanBeDeleted || getDisabledButtonState(selectedCategoryImage)}
						onClick={() => {
							setDisableActionButtons(true);
							handleDeleteTsScrambleFactorImage(cat.ID);
							resetErrorMessage(readerErrorType);
						}}
						icon={<DeleteOutlined />}
						shape='circle'
						type='primary'
					/>
				</div>
			</div>
		);
	};

	const resetErrorMessage = (readerErrorType: ReaderError) => {
		if (readerErrorType === ReaderError.DuplicatedScrambleImageName) {
			batch(() => {
				dispatcher(setReaderErrorImageCategoryAction(''));
				dispatcher(setReaderErrorMessageAction(''));
				dispatcher(setReaderErrorTypeAction(undefined));
			});
		}
	};

	const checkNameDirty = (name: string, categoryID: string) => {
		const categoryImage = selectedCategoryImages.get(categoryID);
		if (categoryImage === undefined) {
			return name;
		}

		const imgId = Number(categoryImage.ID);
		const actualCat = scrambleFactorCategories.find(c => c.ID === parseInt(categoryID));
		if (actualCat) {
			const selectedImage = isNaN(imgId) || actualCat.ScrambleFactorImages.find(i => i.ID == imgId);
			const savedImage = actualCat.ScrambleFactorImages.find(i => i.Selected);

			if (!savedImage && !selectedImage) {
				return name;
			}
		}

		if (categoryImage.isDirty) {
			return (
				<>
					<Badge status='warning' className={styles.dirtyCategoryBadge} /> <span className={styles.scCategory}>{name}</span>
				</>
			);
		}

		return name;
	};

	const dataSource = useMemo(() => {
		if (scrambleFactorCategories?.length > 0) {
			return scrambleFactorCategories.map(cat => {
				return {
					key: cat.ID,
					Category: checkNameDirty(cat.Name, cat.ID.toString()),
					Name: cat.ID?.toString() === editingKey ? renderEditMode(cat.ID) : renderNormalMode(cat),
				};
			});
		}
		return [];
	}, [scrambleFactorCategories, selectedCategoryImages, editingKey, editingNameValue, firstEditingImgNameRender, disableActionButtons]);

	const getScramblePadTypesOptions = (): SelectOptions<string>[] => {
		if (scramblePadTypes?.length > 0) {
			return scramblePadTypes.map(padType => ({
				key: padType.ID.toString(),
				label: padType.Name,
				value: padType.ID.toString(),
			}));
		}
		return [];
	};

	const setImg = (sfic: ScrambleFactorImageCategories[], currentCatKey: string, currentCategoryImg: string) => {
		let newImg: JSX.Element = null;
		if (currentCategoryImg && currentCategoryImg !== '-1') {
			const cat = sfic.find(cat => cat.ID === parseInt(currentCatKey));
			if (cat) {
				const image = cat.ScrambleFactorImages.find(img => img.ID.toString() === currentCategoryImg);
				if (image) {
					const src = `data:image/JPG;base64,${image.Image}`;
					newImg = (
						<img
							src={src}
							alt='logo container'
							className={cx(styles.scImgSource, styles.categoryImage)}
							style={{ width: cat.Width, height: cat.Height }}
						/>
					);
				}
			}
		}
		setSelectedCategoryImage(newImg);
	};

	const rowSelection = {
		onChange: (selectedRKs: React.Key[]) => {
			if (selectedRKs?.length > 0 && selectedCategoryImages?.size > 0 && scrambleFactorCategories?.length > 0) {
				const currentCatKey = selectedRKs[0];
				const currentCategoryImg = selectedCategoryImages.get(currentCatKey.toString());
				setImg(scrambleFactorCategories, currentCatKey.toString(), currentCategoryImg.ID);
			} else {
				setImg(null, null, null);
			}
			setSelectedRowKeys(selectedRKs);
		},
	};

	const padTypeOptions = getScramblePadTypesOptions();

	const radioRender = (record: ScrambleFactorType): JSX.Element => {
		return (
			<Radio
				id={`categoryRowRadioButton-${record.key}-${readerContextType}`}
				checked={selectedRowKeys?.some(k => k === record.key)}
				disabled={editingKey ? true : false}
				onChange={() => {
					const catId = record.key.toString();
					const imgId = selectedCategoryImages.get(catId)?.ID || '-1';
					handleOnChangeCategory(scrambleFactorCategories, parseInt(catId), imgId);
					setSelectedRowKeys([record.key]);
				}}
			/>
		);
	};

	const handleOnShowBitsOnScreen = () => {
		const currentBitMaskState = !isBitMaskActive;
		setIsBitMaskActive(currentBitMaskState);
		dispatcher(setScrambleFactorViewPinEntryIndicatorAction(currentBitMaskState));
	};

	const isScrambleFactorImageNameUnique = (imageName: string, imageId?: string | number): Promise<boolean> => {
		setLoading(true);

		if (imageId === undefined || isNaN(Number(imageId))) {
			imageId = -1;
		}

		return deviceAdminApi
			.uniqueNameCheck(imageName, Number(imageId))
			.then((response: ResponseObjectEntity<boolean>) => {
				let isUnique: boolean = false;
				if (!handleResponse(response)) {
					if (response.Entity) {
						isUnique = true;
					} else {
						showNotificationResponseError(_('ScrambleImageNameInUse').replace('%1', imageName));
					}
				}

				return isUnique;
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
				return false;
			})
			.finally(() => setLoading(false));
	};

	const handleOnAddScrambleFactorImage = (categoryId: number, newImage: ScrambleFactorImage) => {
		setDisableActionButtons(false);
		const categoryIndex = scrambleFactorCategories.findIndex(category => category.ID === categoryId);
		if (categoryIndex !== -1) {
			scrambleFactorCategories[categoryIndex].ScrambleFactorImages.push(newImage);
			handleOnChangeCategory(scrambleFactorCategories, categoryId, newImage.ID.toString());
			setScrambleFactorCategoriesAction([...scrambleFactorCategories]);
		}
	};

	const handleOnSaveEditImgName = (categoryId: number, image: ScrambleFactorImage) => {
		if (editingNameValue) {
			if (!isImageNameUniqueInLocalCategories(scrambleFactorCategories, editingNameValue)) {
				showNotificationResponseError(_('ScrambleImageNameInUse').replace('%1', editingNameValue));
			} else {
				isScrambleFactorImageNameUnique(editingNameValue, image.ID)
					.then((isUnique: boolean) => {
						if (isUnique) {
							const clonedScrambleFactorCategories: ScrambleFactorImageCategories[] = [...scrambleFactorCategories];
							const categoryIndex: number = clonedScrambleFactorCategories.findIndex(category => category.ID === categoryId);
							if (categoryIndex !== -1) {
								const imageIndex = scrambleFactorCategories[categoryIndex].ScrambleFactorImages.findIndex(
									scrambleFactorImage => scrambleFactorImage.ID === image.ID
								);
								if (imageIndex !== -1) {
									if (scrambleFactorCategories[categoryIndex].ScrambleFactorImages[imageIndex].Action !== ScrambleFactorImageAction.Add) {
										scrambleFactorCategories[categoryIndex].ScrambleFactorImages[imageIndex].Action = ScrambleFactorImageAction.Update;
									}
									scrambleFactorCategories[categoryIndex].ScrambleFactorImages[imageIndex].Name = editingNameValue;
									batch(() => {
										setScrambleFactorCategoriesAction([...scrambleFactorCategories]);
										setEditingKey('');
										setFirstEditingImgNameRender(true);
										form.setFieldValue('Name', '');
										handleOnChangeCategory(scrambleFactorCategories, categoryId, image.ID.toString());
									});
								}
							}
						}
					})
					.finally(() => setDisableActionButtons(false));
			}
		} else {
			setDisableActionButtons(false);
		}
	};

	const callDeleteTsScrambleFactorImage = (categoryId: number, imageId: string | number) => {
		const clonedScrambleFactorCategories: ScrambleFactorImageCategories[] = [...scrambleFactorCategories];
		const categoryIndex: number = clonedScrambleFactorCategories.findIndex(category => category.ID === categoryId);
		if (categoryIndex !== -1) {
			const imageIndex = scrambleFactorCategories[categoryIndex].ScrambleFactorImages.findIndex(image => image.ID === imageId);
			if (imageIndex !== -1) {
				if (scrambleFactorCategories[categoryIndex].ScrambleFactorImages[imageIndex].Action !== ScrambleFactorImageAction.Add) {
					scrambleFactorCategories[categoryIndex].ScrambleFactorImages[imageIndex].Action = ScrambleFactorImageAction.Delete;
				} else {
					scrambleFactorCategories[categoryIndex].ScrambleFactorImages.splice(imageIndex, 1);
				}
				setScrambleFactorCategoriesAction([...scrambleFactorCategories]);
				handleOnChangeCategory(scrambleFactorCategories, categoryId, '-1');
			}
		}
		setDisableActionButtons(false);
	};

	const handleDeleteTsScrambleFactorImage = async (catId: number) => {
		let canImageBeDeleted = false;
		let imageId: string | number = Number(selectedCategoryImages.get(catId?.toString())?.ID);
		if (!isNaN(imageId) && imageId <= 0) {
			notification.error({
				message: _('ChoseImageToDelete'),
			});
			setDisableActionButtons(false);
		} else {
			if (isNaN(imageId)) {
				imageId = selectedCategoryImages.get(catId?.toString())?.ID;
				canImageBeDeleted = true;
			} else {
				canImageBeDeleted = await checkIfCanImageBeDeleted(Number(imageId));
			}

			if (canImageBeDeleted) {
				const category = scrambleFactorCategories.find(c => c.ID === catId);
				if (category) {
					const img = category.ScrambleFactorImages.find(i => i.ID === imageId);
					if (img) {
						const msg = _('AreYouSureYouWantToDeleteTsScrambleFactorImage').replace('%1', category.Name).replace('%2', img.Name);
						ModalConfirmation({
							content: <label>{msg}</label>,
							width: '644px',
							onConfirm: () => callDeleteTsScrambleFactorImage(category.ID, imageId),
							onCancel: () => setDisableActionButtons(false),
						});
					} else {
						setDisableActionButtons(false);
					}
				} else {
					const newScrambleFactorCategories: ScrambleFactorImageCategories[] = [...scrambleFactorCategories];
					const currentCat = newScrambleFactorCategories.find(c => c.ID === catId);
					if (currentCat) {
						const currentCat = newScrambleFactorCategories.find(c => c.ID === catId);
						const currentImg: ScrambleFactorImage = currentCat.ScrambleFactorImages.find(img => img.ID === imageId);
						if (currentImg) {
							currentImg.CanBeDeleted = canImageBeDeleted;
							setScrambleFactorCategoriesAction(newScrambleFactorCategories);
						}
					}
					notification.error({
						message: _('ScrambleImageIsBeingUsed'),
					});
					setDisableActionButtons(false);
				}
			}
		}
	};

	const checkIfCanImageBeDeleted = async (imageId: number, hideNotification: boolean = false): Promise<boolean> => {
		let canBeDeleted: boolean = false;
		try {
			const response = await deviceAdminApi.canImageBeDeleted(imageId, readerId);
			if (response.Entity) {
				canBeDeleted = true;
			} else if (!hideNotification && response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
				notification['error']({
					message: _(response.ErrorMessage),
				});
			} else if (!hideNotification) {
				handleResponse(response);
			}
		} catch (e) {
			const error = e as Error;
			Logger.writeErrorLog(`${error.name}: ${error.message}`);
		}
		return canBeDeleted;
	};

	return (
		<div className={styles.form}>
			<Spin tip={_('Loading')} spinning={loading}>
				{!padTypeOptions || padTypeOptions.length === 0 ? (
					<></>
				) : (
					<Form form={form} component={false}>
						<section className={styles.scSection} key='padOptions'>
							<div className={cx(styles.scContent, styles.scPadOptions)}>
								<span className={styles.scSectionTitle}>{`${readerName} ${_('Reader')}:`}</span>
								<span className={styles.scSelectPadContainer}>
									<label htmlFor='scramblePadTypeDropdown'>{`${_('SelectScramblePadType')}:`}</label>
									<Select
										id={`scramblePadTypeDropdown-${readerContextType}`}
										className={styles.scSelectPad}
										key='scramblePadType'
										options={padTypeOptions}
										onChange={(value: string) => setSelectedPadType(value)}
										value={selectedPadType}
										showSearch={true}
										filterOption={filterOption}
									/>
								</span>
								<br />
								<div>
									<Checkbox
										id={`viewPINEntryIndicatorCheckbox-${readerContextType}`}
										checked={isBitMaskActive}
										onChange={() => handleOnShowBitsOnScreen()}>
										<span>{_('ViewPINEntryIndicator')}</span>
									</Checkbox>
								</div>
							</div>
							{renderCurrentPadImage()}
						</section>
						<section className={styles.scSection} key='categoryOptions'>
							<div className={cx(styles.scContent, styles.scCategoryOptions)}>
								<div className={styles.categoriesTitles}>
									<span className={styles.scSectionTitle}>{`${_('LogoAndBackground')}:`}</span>
									<span>{`${_('SelectImageCategory')}:`}</span>
								</div>
								<div>
									<Table
										size={'small'}
										columns={columns}
										dataSource={dataSource}
										rowSelection={{
											...rowSelection,
											renderCell: (_, r) => radioRender(r),
											selectedRowKeys,
											type: 'radio',
										}}
										pagination={false}
										className={styles.table}
										scroll={scroll}
										rowClassName={record => {
											let className = '';
											if (
												readerErrorType === ReaderError.DuplicatedScrambleImageName &&
												readerErrorMessage &&
												record.key.toString() === readerErrorImageCategory
											) {
												className = styles.error;
											}
											return className;
										}}
									/>
									{readerErrorType === ReaderError.DuplicatedScrambleImageName && (
										<span className={styles.errorMessage}>{readerErrorMessage}</span>
									)}
								</div>
								<p hidden={!isControlDirty}>
									<Badge status='warning' />
									&nbsp;&nbsp;
									{_('PendingDownload')}
								</p>
								<p>{_('NoteImageCanBeDeletedIfNotInUse')}</p>
							</div>
							{selectedCategoryImage}
						</section>
						{showModal && (
							<ImportImageModal
								scrambleFactorImageCategories={selectedCategoryImageInfo}
								scrambleFactorCategories={scrambleFactorCategories}
								hideModal={hideUploadModal}
								handleModal={setShowModal}
								showNotificationResponseError={showNotificationResponseError}
								addScrambleFactorImage={handleOnAddScrambleFactorImage}
							/>
						)}
					</Form>
				)}
			</Spin>
		</div>
	);
};

export { ScrambleFactor };


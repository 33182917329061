import { Checkbox, Divider, InputNumber } from 'antd';
import React, { useState } from 'react';
import { batch } from 'react-redux';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../../api';
import { GenericControlTimeZones, OccupancySetupByZone, OptionsViolationsZoneInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { ControllerPassback, ErrorTabs } from '../../../../../../../store/deviceControl/controller/actionTypes';
import {
	setControllerError,
	setControllerOccupancyTwoPersonsRulesAction,
	setControllerPassbackSetupAction,
	setCurrentRuleId,
} from '../../../../../../../store/deviceControl/controller/actions';
import {
	selectControlZones,
	selectControllerId,
	selectCurrentRuleId,
	selectError,
	selectOccupancyTwoPersonsRules,
	selectOptionsViolationsZoneInfo,
} from '../../../../../../../store/deviceControl/controller/selectors';
import { ErrorMessage, Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../passback.module.scss';

const controllerPassbackReportAllOccupancyCheckboxId = 'controllerPassbackReportAllOccupancyCheckbox';
const controllerPassbackOccupancyZoneDropdownId = 'controllerPassbackOccupancyZoneDropdown';
const controllerPassbackEvenPersonInputId = 'controllerPassbackEvenPersonInput';
const controllerPassbackMinPeopleInputId = 'controllerPassbackMinPeopleInput';
const controllerPassbackMaxPeopleInputId = 'controllerPassbackMaxPeopleInput';
const controllerPassbackGoes0To1DropdownId = 'controllerPassbackGoes0To1Dropdown';
const controllerPassbackGoes1To2DropdownId = 'controllerPassbackGoes1To2Dropdown';
const controllerPassbackGoes2To1DropdownId = 'controllerPassbackGoes2To1Dropdown';
const controllerPassbackGoes1To0DropdownId = 'controllerPassbackGoes1To0Dropdown';
const controllerPassbackIsAtMaximumDropdownId = 'controllerPassbackIsAtMaximumDropdown';
const controllerPassbackIsAtMinimum_1Dropdown = 'controllerPassbackIsAtMinimum-1Dropdown';
const controllerPassbackIsAtMinimumPlus1Dropdown = 'controllerPassbackIsAtMinimum+1Dropdown';
const controllerPassbackIsAtMaximumDropdown = 'controllerPassbackIsAtMaximumDropdown';
const controllerPassbackIsAtMaximum_1Dropdown = 'controllerPassbackIsAtMaximum-1Dropdown';

const OptionsViolationsZones: React.FC = () => {
	const dispatch = useStoreDispatch();
	const optionsViolationsZonesInfo: OptionsViolationsZoneInfoStore = useStoreSelector<OptionsViolationsZoneInfoStore>(selectOptionsViolationsZoneInfo);
	const occupancyTwoPersonRules: OccupancySetupByZone[] = useStoreSelector<OccupancySetupByZone[]>(selectOccupancyTwoPersonsRules);
	const currentRuleId: number = useStoreSelector<number>(selectCurrentRuleId);
	const controllerId: number = useStoreSelector<number>(selectControllerId);
	const error: ErrorTabs = useStoreSelector<ErrorTabs>(selectError);
	const controlZones: GenericControlTimeZones[] = useStoreSelector<GenericControlTimeZones[]>(selectControlZones);

	const [rules, setRules] = useState<OccupancySetupByZone[]>(
		occupancyTwoPersonRules && occupancyTwoPersonRules.length > 0 ? occupancyTwoPersonRules : optionsViolationsZonesInfo.occupancySetupByZone
	);
	const [currentRule, setCurrentRule] = useState<number>(
		currentRuleId && currentRuleId !== 0 ? currentRuleId : optionsViolationsZonesInfo.occupancyZones[0].Id
	);

	const handleChangeCurrentRule = async (value: string) => {
		const valueNumber = Number(value);
		const newData = [...rules];
		const index = newData.findIndex(item => item.ZoneIndex === valueNumber);
		if (index === -1) {
			const newItem = await deviceAdminApi.getOccupancyOptionsByZone(controllerId, valueNumber);
			newData.push(newItem);
			setRules(newData);
			const actionToDispatch = setControllerOccupancyTwoPersonsRulesAction([...newData]);
			dispatch(actionToDispatch);
		}
		setCurrentRule(valueNumber);
		dispatch(setCurrentRuleId(valueNumber));
	};

	const handleChangeNumberField = (value: string | number, key: keyof OccupancySetupByZone) => {
		if (!value || value === undefined || (value as number) < 1) {
			value = 0;
		}

		handleChangeOccupancyRule(value.toString(), key);
	};

	const handleChangeOccupancyRule = (value: string, key: keyof OccupancySetupByZone) => {
		const valueNumber = Number(value);
		const newData = [...rules];
		const index = newData.findIndex(item => item.ZoneIndex === currentRule);

		if (~index) {
			const item = newData[index];
			item.error = false;
			dispatch(setControllerError({ twoPersonEvenPeople: false, message: '' }));
			if (key === 'TwoManDisableThreshold' && valueNumber % 2 !== 0) {
				item.error = true;
			}
			newData.splice(index, 1, { ...item, [key]: valueNumber });
			setRules(newData);
			setCurrentRule(currentRule);
			batch(() => {
				dispatch(setCurrentRuleId(currentRule));
				dispatch(setControllerOccupancyTwoPersonsRulesAction([...newData]));
			});
		}
	};

	const handleChangeControllerPassBackSetup = (value: ControllerPassback) => {
		dispatch(
			setControllerPassbackSetupAction({
				...value,
			})
		);
	};

	const currentRuleSelected = rules.find(x => x.ZoneIndex === currentRule);
	const mapOccupancyZones = optionsViolationsZonesInfo.occupancyZones.map(x => ({ label: x.Name, value: x.Id }));
	const mapControlZones = controlZones.map(x => ({ label: x.Name, value: x.CZIndex }));
	const currentIndex = optionsViolationsZonesInfo.occupancyZones.findIndex(x => x.Id === currentRule);

	return (
		<CardSection>
			<div className={styles.occupancyOptions}>
				<Checkbox
					id={controllerPassbackReportAllOccupancyCheckboxId}
					disabled={!optionsViolationsZonesInfo.enabled}
					onChange={value => handleChangeControllerPassBackSetup({ ReportAllOccupancyViolations: value.target.checked })}
					checked={optionsViolationsZonesInfo.reportOptionsOccupancyViolationsInAllZones}>
					<label htmlFor={controllerPassbackReportAllOccupancyCheckboxId}>{_('ReportOptionsOccupancyViolationsInAllZones')}</label>
				</Checkbox>
				<label htmlFor={controllerPassbackOccupancyZoneDropdownId} className={styles.occupancyTitle}>
					{_('OccupancyOptionsApplyToThisZone')}:
				</label>
				<Select
					id={controllerPassbackOccupancyZoneDropdownId}
					disabled={!optionsViolationsZonesInfo.enabled}
					value={currentRule.toString()}
					onChange={handleChangeCurrentRule}
					options={mapOccupancyZones}
				/>
				<div className={styles.optionsViolationsZones}>
					<div className={styles.zoneName}>
						<label>{optionsViolationsZonesInfo.occupancyZones[currentIndex].Name}</label>
					</div>
					<div>
						<div>
							<div className={styles.peopleInsidePassback}>
								<div>
									<label>
										{_('NumberOfPeopleInsideDisable2PersonsRule')} ({_('EvenNumber')}):
									</label>
									<ErrorMessage message={_('NumberOfPersonsMustBeEven')} show={currentRuleSelected.error || error.twoPersonEvenPeople}>
										<InputNumber
											id={controllerPassbackEvenPersonInputId}
											min={0}
											max={32766}
											maxLength={5}
											disabled={!optionsViolationsZonesInfo.enabled}
											value={currentRuleSelected.TwoManDisableThreshold}
											onChange={value => handleChangeNumberField(value, 'TwoManDisableThreshold')}
											formatter={noneDecimalFormatter}
											className={styles.numberOfPersonsInside}
										/>
									</ErrorMessage>
									<label htmlFor={controllerPassbackEvenPersonInputId}>(0-32766)</label>
								</div>
								<div>
									<label htmlFor={controllerPassbackMinPeopleInputId}>{_('MinimumPeople')}:</label>
									<InputNumber
										id={controllerPassbackMinPeopleInputId}
										min={0}
										max={32766}
										maxLength={5}
										disabled={!optionsViolationsZonesInfo.enabled}
										onChange={value => handleChangeNumberField(value, 'MinimumPeopleInside')}
										value={currentRuleSelected.MinimumPeopleInside}
										formatter={noneDecimalFormatter}
									/>
									<label htmlFor={controllerPassbackMinPeopleInputId}>(0-32766)</label>
									<label htmlFor={controllerPassbackMaxPeopleInputId}>{_('MaximumPeople')}:</label>
									<InputNumber
										id={controllerPassbackMaxPeopleInputId}
										min={0}
										max={32766}
										maxLength={5}
										disabled={!optionsViolationsZonesInfo.enabled}
										onChange={value => handleChangeNumberField(value, 'MaximumPeopleInside')}
										value={currentRuleSelected.MaximumPeopleInside}
										formatter={noneDecimalFormatter}
									/>
									<label htmlFor={controllerPassbackMaxPeopleInputId}>(0-32766)</label>
								</div>
							</div>
						</div>
						<Divider type='vertical' className={styles.passbackDivider} />
						<div>
							<p>{_('WhenOccupancy')}:</p>
							<label>{_('TriggerControlZone')}:</label>
							<label htmlFor={controllerPassbackGoes0To1DropdownId}>{_('GoesFrom0to1')}</label>
							<Select
								id={controllerPassbackGoes0To1DropdownId}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'Occupancy0To1')}
								value={currentRuleSelected.Occupancy0To1.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackGoes1To2DropdownId}>{_('GoesFrom1to2')}</label>
							<Select
								id={controllerPassbackGoes1To2DropdownId}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'Occupancy1To2')}
								value={currentRuleSelected.Occupancy1To2.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackGoes2To1DropdownId}>{_('GoesFrom2to1')}</label>
							<Select
								id={controllerPassbackGoes2To1DropdownId}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'Occupancy2To1')}
								value={currentRuleSelected.Occupancy2To1.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackGoes1To0DropdownId}>{_('GoesFrom1to0')}</label>
							<Select
								id={controllerPassbackGoes1To0DropdownId}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'Occupancy1To0')}
								value={currentRuleSelected.Occupancy1To0.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackIsAtMaximumDropdownId}>{_('IsAtMinimum')}</label>
							<Select
								id={controllerPassbackIsAtMaximumDropdownId}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'OccupancyMinimum')}
								value={currentRuleSelected.OccupancyMinimum.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackIsAtMinimum_1Dropdown}>{_('IsAtMinimum-1')}</label>
							<Select
								id={controllerPassbackIsAtMinimum_1Dropdown}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'OccupancyMinimumLess1')}
								value={currentRuleSelected.OccupancyMinimumLess1.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackIsAtMinimumPlus1Dropdown}>{_('IsAtMinimum+1')}</label>
							<Select
								id={controllerPassbackIsAtMinimumPlus1Dropdown}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'OccupancyMinimumPlus1')}
								value={currentRuleSelected.OccupancyMinimumPlus1.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackIsAtMaximumDropdown}>{_('IsAtMaximum')}</label>
							<Select
								id={controllerPassbackIsAtMaximumDropdown}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'OccupancyMaximum')}
								value={currentRuleSelected.OccupancyMaximum.toString()}
								options={mapControlZones}
							/>
							<label htmlFor={controllerPassbackIsAtMaximum_1Dropdown}>{_('IsAtMaximum-1')}</label>
							<Select
								id={controllerPassbackIsAtMaximum_1Dropdown}
								disabled={!optionsViolationsZonesInfo.enabled}
								onChange={(value: string) => handleChangeOccupancyRule(value, 'OccupancyMaximumLess1')}
								value={currentRuleSelected.OccupancyMaximumLess1.toString()}
								options={mapControlZones}
							/>
						</div>
					</div>
				</div>
			</div>
		</CardSection>
	);
};

export { OptionsViolationsZones };

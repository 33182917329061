// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".name-input-containerInput--AwTycR5s9dIx3TgZ8sZ2b{margin-bottom:10px;align-items:baseline;display:grid;grid-template-columns:13% 87%}.name-input-containerInput--AwTycR5s9dIx3TgZ8sZ2b .name-input-error--2uV64HscSE33ZR_dT0DbdE{border:1px solid red}.name-input-containerInput--AwTycR5s9dIx3TgZ8sZ2b .name-input-errorMessage--2lG_1420miflo-XnvQhWF-{color:red;display:flex;justify-content:flex-start;margin-bottom:1em}.name-input-containerInput--AwTycR5s9dIx3TgZ8sZ2b .name-input-input--2aZRgNVsPDOx2bkJtMQDhx{height:30px !important}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/DigiTrac/InputModal/tabs/General/sections/NameInput/nameInput.module.scss"],"names":[],"mappings":"AAAA,kDACC,kBAAA,CACA,oBAAA,CACA,YAAA,CACA,6BAAA,CACA,4FACC,oBAAA,CAED,mGACC,SAAA,CACA,YAAA,CACA,0BAAA,CACA,iBAAA,CAGD,4FACC,sBAAA","sourcesContent":[".containerInput {\r\n\tmargin-bottom: 10px;\r\n\talign-items: baseline;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: 13% 87%;\r\n\t.error {\r\n\t\tborder: 1px solid red;\r\n\t}\r\n\t.errorMessage {\r\n\t\tcolor: red;\r\n\t\tdisplay: flex;\r\n\t\tjustify-content: flex-start;\r\n\t\tmargin-bottom: 1em;\r\n\t}\r\n\r\n\t.input {\r\n\t\theight: 30px !important;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerInput": "name-input-containerInput--AwTycR5s9dIx3TgZ8sZ2b",
	"error": "name-input-error--2uV64HscSE33ZR_dT0DbdE",
	"errorMessage": "name-input-errorMessage--2lG_1420miflo-XnvQhWF-",
	"input": "name-input-input--2aZRgNVsPDOx2bkJtMQDhx"
};
export default ___CSS_LOADER_EXPORT___;

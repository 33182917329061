import { Select as SelectAntd } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import React, { AriaAttributes, useState } from 'react';
import { removeCommasAndPercentSign } from '../../../Helper';
import { SelectOptions } from '../../../model/CommonModel';

export type SelectOption = Omit<SelectOptions<string | number>, 'icon' | 'separator'>[];

type Props = {
	defaultValue?: string | number | LabeledValue;
	onChange?: (value: string | string[] | number | number[] | LabeledValue | LabeledValue[]) => void;
	options?: SelectOption;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	value?: string | string[] | number | number[] | LabeledValue | LabeledValue[];
	getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
	listHeight?: number;
	id?: string;
	showSearch?: boolean;
	filterOption?: (inputValue: string, option) => boolean;
	maxSearchLength?: number;
	labelInValue?: boolean;
	notUseCurrentParse?: boolean;
	onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
	onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
	onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
	defaultActiveFirstOption?: boolean;
	onSearchText?: (inputValue: string) => void;
	notFoundContent?: React.ReactNode;
	onSelect?: (value: string) => void;
	removePercentParse?: boolean;
	style?: React.CSSProperties;
	showOptionalStringOnSelectedValue?: (valueOption: string) => string;
	loading?: boolean;
	ariaAttributes?: AriaAttributes;
	mode?: 'multiple' | 'tags';
	title?: string;
	virtual?: boolean;
};

const Select = ({
	onChange,
	options,
	defaultValue,
	placeholder,
	className,
	disabled,
	value,
	getPopupContainer,
	listHeight,
	id,
	showSearch,
	filterOption,
	maxSearchLength,
	labelInValue,
	onBlur,
	onFocus,
	onScroll,
	defaultActiveFirstOption,
	onSearchText,
	notFoundContent,
	notUseCurrentParse,
	onSelect,
	style,
	removePercentParse,
	showOptionalStringOnSelectedValue,
	loading,
	ariaAttributes,
	mode,
	title,
	virtual = false,
}: Props) => {
	const [searchValue, setSearchValue] = useState('');
	const { Option } = SelectAntd;
	const selectedValue = value;

	const optionsMenu = options?.map(({ label, value, id, disabled }, index) => (
		<Option id={id} key={id ?? index} value={notUseCurrentParse ? value : value.toString()} disabled={disabled}>
			{selectedValue !== undefined &&
			selectedValue !== null &&
			((Array.isArray(selectedValue) && selectedValue.findIndex(x => x.toString() === value) !== -1) ||
				(!Array.isArray(selectedValue) && value.toString() === selectedValue.toString())) &&
			showOptionalStringOnSelectedValue
				? showOptionalStringOnSelectedValue(label)
				: label}
		</Option>
	));

	const onSearch = (inputValue: string): void => {
		onSearchText?.(inputValue);
		setSearchValue(
			removePercentParse ? inputValue?.substring(0, maxSearchLength ?? 64) : removeCommasAndPercentSign(inputValue?.substring(0, maxSearchLength ?? 64))
		);
	};

	const titleObject = { title: title };

	return (
		<SelectAntd
			mode={mode}
			virtual={virtual}
			listHeight={listHeight}
			defaultValue={defaultValue}
			onChange={onChange}
			placeholder={placeholder}
			getPopupContainer={getPopupContainer}
			className={className}
			disabled={disabled}
			value={value}
			id={id}
			style={style}
			onSelect={onSelect}
			notFoundContent={notFoundContent}
			defaultActiveFirstOption={defaultActiveFirstOption}
			onBlur={onBlur}
			onPopupScroll={onScroll}
			onFocus={onFocus}
			showSearch={showSearch ?? false}
			filterOption={showSearch ? filterOption : null}
			optionFilterProp={showSearch ? 'children' : ''}
			onSearch={showSearch ? onSearch : null}
			searchValue={showSearch ? searchValue : ''}
			labelInValue={labelInValue ?? false}
			loading={loading ?? false}
			dropdownRender={(menu: React.ReactElement) => <label aria-labelledby={id}>{menu}</label>}
			{...titleObject}
			{...ariaAttributes}>
			{optionsMenu}
		</SelectAntd>
	);
};

export { Select };

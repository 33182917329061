import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { timeZoneLockedValidation } from '../../../../Helper';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { PaginationSetting, SortDirections, SorterConfig } from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	DeviceObjectType,
	ObjectTypes,
	OptionsButtonBuilder,
	SelectedRowKeyPagination,
	TimeZoneType,
	TimeZonesStateProps,
	UseModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setAllItemsPagination, setCurrentModalTimeZone, setItemSearchedPagination, setSelectedTimeZone } from '../../../../store/common/actions';
import { selectTimeZoneType } from '../../../../store/common/selectors';
import {
	clearVelocityConfigurationSelectionsAction,
	setGrandTimeZonesBy,
	setMasterTimeZonesBy,
	setStandardTimeZonesBy,
	setVelocityActionScopeKey,
} from '../../../../store/deviceControl/actions';
import { selectTimeZonesState } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, ModalAlert, Select } from '../../../common';
import { WithLoader } from '../../../common/hoc/WithLoader/WithLoader';
import { TimeZoneAlert } from './TimeZoneAlert/TimeZoneAlert';
import { TimeZonesGrandMaster } from './TimeZonesGrandMaster/TimeZonesGrandMaster';
import { TimeZonesMaster } from './TimeZonesMaster/TimeZonesMaster';
import { TimeZonesStandard } from './TimeZonesStandard/TimeZonesStandard';
import { GrandMaster, Master, Standard } from './modals';
import styles from './timeZones.module.scss';

type Props = {
	onEntityAction?: (genericTimeZoneId: number) => void;
	disableDelete?: boolean;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const standardComponentPermission = User.getComponentPermission(user, SecuredComponents.Standard_Time_Zone);
const masterComponentPermission = User.getComponentPermission(user, SecuredComponents.Master_Time_Zone);
const grandComponentPermission = User.getComponentPermission(user, SecuredComponents.Grand_Master_Time_Zone);

const getDefaultTimeZoneState = () => {
	if (standardComponentPermission.canView) {
		return TimeZoneType.Standard;
	}

	if (masterComponentPermission.canView) {
		return TimeZoneType.Master;
	}

	return TimeZoneType.Grand;
};

const TimeZones: React.FC<Props> = ({ onEntityAction, disableDelete }) => {
	const dispatch = useStoreDispatch();
	const [current, setCurrent] = useState<TimeZoneType>(getDefaultTimeZoneState());
	const [selectedRowKeysPagination, setSelectedRowKeysPagination] = useState<SelectedRowKeyPagination[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [currentPageSize, setCurrentPageSize] = useState<number>(25);
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const { grand, master, standard, isEditMode, actionScopeKey }: TimeZonesStateProps = useStoreSelector(selectTimeZonesState);

	const redirectPage = useRef<boolean>(false);
	const sorterConfig = useRef<SorterConfig>({ direction: SortDirections.None, field: '' });

	useEffect(() => {
		switch (current) {
			case TimeZoneType.Standard:
				dispatch(
					setStandardTimeZonesBy({
						PageNumber: currentPage,
						PageSize: currentPageSize,
						SortDirection: sorterConfig.current.direction,
						SearchedValue: '',
					} as PaginationSetting)
				);
				dispatch(setAllItemsPagination(DeviceObjectType.TimeZones, ObjectTypes.timeZones));
				dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.TimeZones, subObjectType: 'standard' }));
				break;

			case TimeZoneType.Grand:
				dispatch(
					setGrandTimeZonesBy({
						PageNumber: currentPage,
						PageSize: currentPageSize,
						SortDirection: sorterConfig.current.direction,
						SearchedValue: '',
					} as PaginationSetting)
				);
				dispatch(setAllItemsPagination(DeviceObjectType.TimeZones, ObjectTypes.grandMasterTimeZones));
				dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.TimeZones, subObjectType: 'grand' }));
				break;

			case TimeZoneType.Master:
				dispatch(
					setMasterTimeZonesBy({
						PageNumber: currentPage,
						PageSize: currentPageSize,
						SortDirection: sorterConfig.current.direction,
						SearchedValue: '',
					} as PaginationSetting)
				);
				dispatch(setAllItemsPagination(DeviceObjectType.TimeZones, ObjectTypes.masterTimeZones));
				dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.TimeZones, subObjectType: 'master' }));
				break;
		}
	}, [current]);

	const handleShowAlert = (list: UseModel[], title: string, timeZoneType: TimeZoneType) => {
		let errorMessage: string = '';
		switch (timeZoneType) {
			case TimeZoneType.Standard:
				errorMessage = _('CannotDeleteStandardTimeZoneBecause');
				break;
			case TimeZoneType.Master:
				errorMessage = _('CannotDeleteMasterTimeZoneBecause');
				break;
			case TimeZoneType.Grand:
				errorMessage = _('CannotDeleteGrandMasterTimeZoneBecause');
				break;
		}

		ModalAlert({
			title: title,
			children: <TimeZoneAlert records={list} title={errorMessage} />,
		});
	};

	const cleanUpTable = () => {
		setCurrentPage(1);
		setSelectedRowKeys([]);
		dispatch(setItemSearchedPagination(''));
		sorterConfig.current.direction = SortDirections.None;
	};

	const handleChangeTimeOption = (value: string) => {
		setCurrent(Number(value) as TimeZoneType);
		dispatch(setSelectedTimeZone(Number(value) as TimeZoneType));
		cleanUpTable();
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} onClick={x.onClick} disabled={x.disabled} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const buttons: ButtonBuilder[] = [
		{
			id: 'addStandardTimeZoneButton',
			label: _('AddStandardTimeZones'),
			icon: <PlusOutlined />,
			onClick: () => handleAddTimeZone(TimeZoneType.Standard),
			disabled: !standardComponentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(standardComponentPermission.canAdd),
		},
		{
			id: 'addMasterTimeZoneButton',
			label: _('AddMasterTimeZones'),
			icon: <PlusOutlined />,
			onClick: () => handleAddTimeZone(TimeZoneType.Master),
			disabled: !masterComponentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(masterComponentPermission.canAdd),
		},
		{
			id: 'addGrandMasterTimeZoneButton',
			label: _('AddGrandMasterTimeZones'),
			icon: <PlusOutlined />,
			onClick: () => handleAddTimeZone(TimeZoneType.Grand),
			disabled: !grandComponentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(grandComponentPermission.canAdd),
		},
	];

	const getTimezoneSelectionLength = (): number => {
		switch (current) {
			case TimeZoneType.Standard:
				return selectedRowKeys.length;

			case TimeZoneType.Master:
				return selectedRowKeys.length;

			case TimeZoneType.Grand:
				return selectedRowKeys.length;
		}
	};

	const createButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
		switch (current) {
			case TimeZoneType.Standard:
				return createStandardButtonOptions(hasMoreThanOne, isItemOnSamePage);

			case TimeZoneType.Master:
				return createMasterButtonOptions(hasMoreThanOne, isItemOnSamePage);

			case TimeZoneType.Grand:
				return createGrandButtonOptions(hasMoreThanOne, isItemOnSamePage);
		}
	};

	const createStandardButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
		const buttons = {
			labelOrIcon: '...',
			options: [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'editStandardTimeZoneButton',
					label: _('Edit'),
					disabled: !standardComponentPermission.canView || hasMoreThanOne,
					value: 'edit',
					title: getPermissionErrorMessage(standardComponentPermission.canView, false, hasMoreThanOne),
				},
				{
					id: 'renameStandardTimeZoneButton',
					label: _('Rename'),
					disabled: !standardComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
					value: 'rename',
					title: getPermissionErrorMessage(standardComponentPermission.canUpdate, false, hasMoreThanOne),
				},
			],
		};

		if (!disableDelete) {
			buttons.options.push({
				id: 'deleteStandardTimeZoneButton',
				label: _('Delete'),
				disabled: !standardComponentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(standardComponentPermission.canDelete),
			});
		}

		return buttons;
	};

	const createMasterButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
		const buttons = {
			labelOrIcon: '...',
			options: [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'editMasterTimeZoneButton',
					label: _('Edit'),
					disabled: !masterComponentPermission.canView || hasMoreThanOne,
					value: 'edit',
					title: getPermissionErrorMessage(masterComponentPermission.canView, false, hasMoreThanOne),
				},
				{
					id: 'renameMasterTimeZoneButton',
					label: _('Rename'),
					disabled: !masterComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
					value: 'rename',
					title: getPermissionErrorMessage(masterComponentPermission.canUpdate, false, hasMoreThanOne),
				},
			],
		};

		if (!disableDelete) {
			buttons.options.push({
				id: 'deleteMasterTimeZoneButton',
				label: _('Delete'),
				disabled: !masterComponentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(masterComponentPermission.canDelete),
			});
		}

		return buttons;
	};

	const createGrandButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
		const buttons = {
			labelOrIcon: '...',
			options: [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'editGrandMasterTimeZone',
					label: _('Edit'),
					disabled: !grandComponentPermission.canView || hasMoreThanOne,
					title: getPermissionErrorMessage(grandComponentPermission.canView, false, hasMoreThanOne),
					value: 'edit',
				},
				{
					id: 'renameGrandMasterTimeZone',
					label: _('Rename'),
					disabled: !grandComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
					title: getPermissionErrorMessage(grandComponentPermission.canUpdate, false, hasMoreThanOne),
					value: 'rename',
				},
			],
		};

		if (!disableDelete) {
			buttons.options.push({
				id: 'deleteGrandMasterTimeZone',
				label: _('Delete'),
				disabled: !grandComponentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(grandComponentPermission.canDelete),
			});
		}

		return buttons;
	};

	const handleAddTimeZone = async (timeZoneType: TimeZoneType) => {
		const isLocked: boolean = await timeZoneLockedValidation(timeZoneType, true);
		if (isLocked) {
			return;
		}

		dispatch(setCurrentModalTimeZone({ entity: null, timeZoneType: timeZoneType }));
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const timezoneSelectionLength = getTimezoneSelectionLength();
	const itemOptions = createButtonOptions(timezoneSelectionLength > 1, isItemOnSamePage);
	const disabledActionButtons = isEditMode || timezoneSelectionLength === 0;
	const isPaginationItemSelectedTZ: boolean = standard.filter(c => c.checked).length !== 1;
	const isPaginationItemSelectedMTZ: boolean = master.filter(c => c.checked).length !== 1;
	const isPaginationItemSelectedGMTZ: boolean = grand.filter(c => c.checked).length !== 1;

	const renderTimeZone = () => {
		switch (current) {
			case TimeZoneType.Standard:
				return (
					<TimeZonesStandard
						data={standard}
						actionScopeKey={actionScopeKey}
						options={itemOptions}
						selectedRowKeysPagination={selectedRowKeysPagination}
						currentPage={currentPage}
						shouldResetSearchColumn={shouldResetSearchColumn}
						redirectPage={redirectPage.current}
						currentPageSize={currentPageSize}
						sorterConfig={sorterConfig.current}
						selectedRowKeys={selectedRowKeys}
						onShowAlert={handleShowAlert}
						setSelectedRowKeysPagination={setSelectedRowKeysPagination}
						setCurrentPage={setCurrentPage}
						setIsItemOnSamePage={setIsItemOnSamePage}
						setShouldResetSearchColumn={setShouldResetSearchColumn}
						setRedirectPage={setRedirectPage}
						setCurrentPageSize={setCurrentPageSize}
						setSorterConfig={value => (sorterConfig.current = value)}
						setSelectedRowKeys={setSelectedRowKeys}
					/>
				);

			case TimeZoneType.Master:
				return (
					<TimeZonesMaster
						data={master}
						actionScopeKey={actionScopeKey}
						options={itemOptions}
						selectedRowKeysPagination={selectedRowKeysPagination}
						currentPage={currentPage}
						shouldResetSearchColumn={shouldResetSearchColumn}
						redirectPage={redirectPage.current}
						currentPageSize={currentPageSize}
						sorterConfig={sorterConfig.current}
						selectedRowKeys={selectedRowKeys}
						onShowAlert={handleShowAlert}
						setSelectedRowKeysPagination={setSelectedRowKeysPagination}
						setCurrentPage={setCurrentPage}
						setIsItemOnSamePage={setIsItemOnSamePage}
						setShouldResetSearchColumn={setShouldResetSearchColumn}
						setRedirectPage={setRedirectPage}
						setCurrentPageSize={setCurrentPageSize}
						setSorterConfig={value => (sorterConfig.current = value)}
						setSelectedRowKeys={setSelectedRowKeys}
					/>
				);

			case TimeZoneType.Grand:
				return (
					<TimeZonesGrandMaster
						data={grand}
						actionScopeKey={actionScopeKey}
						options={itemOptions}
						selectedRowKeysPagination={selectedRowKeysPagination}
						shouldResetSearchColumn={shouldResetSearchColumn}
						redirectPage={redirectPage.current}
						currentPage={currentPage}
						currentPageSize={currentPageSize}
						sorterConfig={sorterConfig.current}
						selectedRowKeys={selectedRowKeys}
						onShowAlert={handleShowAlert}
						setSelectedRowKeysPagination={setSelectedRowKeysPagination}
						setCurrentPage={setCurrentPage}
						setIsItemOnSamePage={setIsItemOnSamePage}
						setShouldResetSearchColumn={setShouldResetSearchColumn}
						setRedirectPage={setRedirectPage}
						setCurrentPageSize={setCurrentPageSize}
						setSorterConfig={value => (sorterConfig.current = value)}
						setSelectedRowKeys={setSelectedRowKeys}
					/>
				);
		}
	};

	const handleCallOnEntityAdded = (id: number) => {
		if (onEntityAction) {
			onEntityAction(id);
		}
	};

	const renderTimeZoneModal = () => {
		const modalType: TimeZoneType = useStoreSelector<TimeZoneType>(selectTimeZoneType);

		switch (modalType) {
			case TimeZoneType.Standard:
				return (
					<Standard
						onEntityAction={handleCallOnEntityAdded}
						isPaginationItemSelected={!isPaginationItemSelectedTZ}
						currentPage={currentPage}
						currentPageSize={currentPageSize}
						sorterConfig={sorterConfig.current}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						setRedirectPage={setRedirectPage}
					/>
				);

			case TimeZoneType.Master:
				return (
					<Master
						onEntityAction={handleCallOnEntityAdded}
						isPaginationItemSelected={!isPaginationItemSelectedMTZ}
						currentPage={currentPage}
						currentPageSize={currentPageSize}
						sorterConfig={sorterConfig.current}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						setRedirectPage={setRedirectPage}
					/>
				);

			case TimeZoneType.Grand:
				return (
					<GrandMaster
						onEntityAction={handleCallOnEntityAdded}
						isPaginationItemSelected={!isPaginationItemSelectedGMTZ}
						currentPage={currentPage}
						currentPageSize={currentPageSize}
						sorterConfig={sorterConfig.current}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						setRedirectPage={setRedirectPage}
					/>
				);
		}
	};

	let options: any[] = [];
	if (standardComponentPermission.canView) {
		options.push({ label: _('StandardTimeZones'), id: 'timeZonesStandardOption', value: TimeZoneType.Standard.toString() });
	}

	if (masterComponentPermission.canView) {
		options.push({ label: _('MasterTimeZones'), id: 'timeZonesMasterOption', value: TimeZoneType.Master.toString() });
	}

	if (grandComponentPermission.canView) {
		options.push({ label: _('GrandMasterTimeZones'), id: 'timeZonesGrandOption', value: TimeZoneType.Grand.toString() });
	}

	const handleActionScope = async (key: string) => {
		dispatch(setVelocityActionScopeKey(key));
	};

	const timeZonesViewDropdownId = 'timeZonesViewDropdown';

	return (
		<div className={styles.container}>
			{renderTimeZoneModal()}
			<div className={styles.buttonContainer}>
				<ButtonDropdown
					id={'timezonesTableActionDropdown'}
					disabled={disabledActionButtons}
					menuOptions={itemOptions.options}
					icon={<CaretDownOutlined />}
					labelIcon={itemOptions.labelOrIcon}
					onClickOption={handleActionScope}
				/>
				{buttonsBuilder(buttons)}
			</div>
			<div className={styles.headerSection}>
				<div>
					<label htmlFor={timeZonesViewDropdownId}>{_('View')}</label>
					<Select
						id={timeZonesViewDropdownId}
						options={options}
						defaultValue={current.toString()}
						onChange={handleChangeTimeOption}
						className={styles.select}
						disabled={isEditMode}
					/>
					<div className={styles.references}>
						<div>
							<div />
							<label>{_('InUse')}</label>
						</div>
						<div>
							<div />
							<label>{_('NotReferenced')}</label>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.gridSection}>{renderTimeZone()}</div>
		</div>
	);
};

const componentWithLoader = WithLoader(TimeZones);

export { componentWithLoader as TimeZones };

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './state.module.scss';

type Props = {
	useDoorContext: boolean;
};

const State: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleSelectCheckBox = (e: RadioChangeEvent) => {
		useDoorContext
			? inputContext.dispatcher(setInputAction({ NormallyOpen: e.target.value }))
			: inputContext.dispatcher(setInputAction({ NormallyOpen: e.target.value }));
	};

	return (
		<div className={useDoorContext ? styles.container : styles.containerInput}>
			<fieldset>
				<legend className={styles.legend}>{_('NormalStateWhenSecure')}</legend>
				<div className={styles.content}>
					<Radio.Group value={inputContext.context?.input?.NormallyOpen} onChange={handleSelectCheckBox}>
						<Radio value={true} className={styles.radioGroupVertical} id='inputModalNormallyOpenRadio'>
							{_('NormallyOpen')}
						</Radio>
						<Radio value={false} className={styles.radioGroupVertical} id='inputModalNormallyClosedRadio'>
							{_('NormallyClosed')}
						</Radio>
					</Radio.Group>
				</div>
			</fieldset>
		</div>
	);
};

export { State };

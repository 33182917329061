import { createSelector } from 'reselect';
import { AlarmModel } from '../../model/AlarmModel';
import { ConfigurationState } from '../../model/ConfigurationModel';
import { BroadCastSummary, CurrentDeviceControlObj, GrandMasterTimeZones, MasterTimeZones, StandardTimeZone, TimeZoneType } from '../../model/DeviceAdminModel';
import { StatusSummary, VelocityEventModel } from '../../model/EventModel';
import { PhotoCallUp } from '../../model/PhotoCallUpModel';
import { DigiTracTransaction, PerformanceData } from '../../model/StatusModel';
import { StatusViewerEvent } from '../../model/StatusViewerModel';
import { PIVEnrollmentAction } from '../../model/WebSocketModel';
import { WhosInsideActionDetail } from '../../model/WhosInsideModel';
import { RootReducer } from '../../store/rootReducer';

export const selectPIVEnrollmentAction = createSelector(
	(state: RootReducer) => state.commonReducer.pivEnrollmentAction,
	(pivEnrollmentAction: PIVEnrollmentAction) => pivEnrollmentAction
);

export const selectPerformanceStatus = createSelector(
	(state: RootReducer) => state.commonReducer.performanceStatus,
	(performanceStatus: PerformanceData) => performanceStatus
);

export const selectPhotoCallCredentials = createSelector(
	(state: RootReducer) => state.commonReducer.photoCallCredentials,
	(photoCallCredentials: PhotoCallUp[]) => photoCallCredentials
);

export const selectEventList = createSelector(
	(state: RootReducer) => state.commonReducer.eventList,
	(eventList: VelocityEventModel[]) => eventList
);

export const selectAlarmList = createSelector(
	(state: RootReducer) => state.commonReducer.alarmList,
	(alarmList: AlarmModel[]) => alarmList
);

export const selectAckAlarmList = createSelector(
	(state: RootReducer) => state.commonReducer.ackAlarmList,
	(ackAlarmList: AlarmModel[]) => ackAlarmList
);

export const selectClearedAlarmList = createSelector(
	(state: RootReducer) => state.commonReducer.clearedAlarmList,
	(clearedAlarmList: AlarmModel[]) => clearedAlarmList
);

export const selectTotalActiveAlarms = createSelector(
	(state: RootReducer) => state.commonReducer.statusSummary.NumAlarms,
	(activeAlarms: number) => activeAlarms
);

export const selectTotalAcknowledgedAlarms = createSelector(
	(state: RootReducer) => state.commonReducer.statusSummary.NumAckAlarms,
	(acknowledged: number) => acknowledged
);

export const selectStatusSummary = createSelector(
	(state: RootReducer) => state.commonReducer.statusSummary,
	(statusSummary: StatusSummary) => statusSummary
);

export const selectTransactions = createSelector(
	(state: RootReducer) => state.commonReducer.transactions,
	(transactions: DigiTracTransaction[]) => transactions
);

export const selectBroadCastSummary = createSelector(
	(state: RootReducer) => state.commonReducer.broadCastDeviceSummary,
	(broadCastDeviceSummary: BroadCastSummary) => broadCastDeviceSummary
);

export const selectWhosInsideActionDetails = createSelector(
	(state: RootReducer) => state.commonReducer.whosInsideActionDetails,
	(whosInsideActionDetails: WhosInsideActionDetail[]) => whosInsideActionDetails
);

export const selectStatusViewerEvents = createSelector(
	(state: RootReducer) => state.commonReducer.statusViewerEvents,
	(statusViewerEvents: StatusViewerEvent[]) => statusViewerEvents
);

export const selectIsLoading = createSelector(
	(state: RootReducer) => state.commonReducer.isLoading,
	(isLoading: boolean) => isLoading
);

export const selectCurrentDevice = createSelector(
	(state: RootReducer) => state.commonReducer.currentDevice,
	(currentDevice: CurrentDeviceControlObj) => currentDevice
);

export const selectItemSearchedPagination = createSelector(
	(state: RootReducer) => state.commonReducer.itemSearchedPagination,
	(itemSearchedPagination: string) => itemSearchedPagination
);

export const selectStandardTimeZone = createSelector(
	(state: RootReducer) => state.commonReducer.currentTimeZoneModal.entity,
	(entity: StandardTimeZone | GrandMasterTimeZones | MasterTimeZones | null) => entity as StandardTimeZone
);

export const selectMasterTimeZone = createSelector(
	(state: RootReducer) => state.commonReducer.currentTimeZoneModal.entity,
	(entity: StandardTimeZone | GrandMasterTimeZones | MasterTimeZones | null) => entity as MasterTimeZones
);

export const selectGrandMasterTimeZone = createSelector(
	(state: RootReducer) => state.commonReducer.currentTimeZoneModal.entity,
	(entity: StandardTimeZone | GrandMasterTimeZones | MasterTimeZones | null) => entity as GrandMasterTimeZones
);

export const selectTimeZoneType = createSelector(
	(state: RootReducer) => state.commonReducer.currentTimeZoneModal.timeZoneType,
	(timeZoneType: TimeZoneType) => timeZoneType
);
export const selectViewSettings = createSelector(
	(state: RootReducer) => state.commonReducer?.configuration,
	(configurationState: ConfigurationState) => configurationState?.ViewSettings
);

import { MinusSquareOutlined, PlusSquareOutlined, ReadOutlined } from '@ant-design/icons';
import { Collapse, Typography } from 'antd';
import cx from 'classnames';
import React, { CSSProperties, memo, useEffect, useRef } from 'react';
import { areEqual } from 'react-window';
import { ColumnsProps } from '../../../Helper';
import { User } from '../../../model/AccountModel';
import { AlarmDetail, AlarmModel } from '../../../model/AlarmModel';
import { useStoreDispatch } from '../../../store';
import { setShowAlarmInstructions } from '../../../store/alarm/actions';
import { AcknowledgedAlarmTableColumns, AlarmActionColumnData, AlarmTableDataSourceModel, instructionsStyle } from '../helpers';
import styles from './virtualTableExpandableRow.module.scss';

const { Panel } = Collapse;
const { Paragraph } = Typography;

type Props = {
	data: AlarmTableDataSourceModel;
	index: number;
	rowColumnsWidth: CSSProperties;
	windowWidth: number;
	columns: ColumnsProps<AlarmTableDataSourceModel>[];
	textColor: CSSProperties;
	alwaysShowInstructions: boolean;
	setSize: (index: number, size: number) => void;
	handleOnToggleItemActive: (itemIndex: number) => void;
};

const user: User = getUser();
const { queueSortDir } = user;

export const columnKeys: Partial<Record<keyof AcknowledgedAlarmTableColumns, string>> = {
	Description: 'Description',
	Address: 'Address',
	HostTime: 'HostTime',
	HardwareTime: 'HardwareTime',
	Level: 'Level',
	ActiveAlarmId: 'ActiveAlarmId',
	CollapseIcon: 'CollapseIcon',
	Count: 'Count',
	Action: 'Action',
	Workstation: 'Workstation',
	AcknowledgedTime: 'AcknowledgedTime',
	AcknowledgedBy: 'AcknowledgedBy',
	Icon: 'Icon',
};

export const getIconColumn = (alarm: AlarmTableDataSourceModel, alarmId: number): React.ReactNode => {
	const { Status, ReturnedToNormal } = alarm;
	if (Status === 2 || ReturnedToNormal) {
		if (!ReturnedToNormal) {
			return <span id={`RTN-${alarmId}`} className={styles.greenDot}></span>;
		}
	} else {
		return <span id={`RTN-${alarmId}`} className={styles.redDot}></span>;
	}

	return <span id={`RTN-${alarmId}`} className={styles.grayDot}></span>;
};

export enum AlarmExpandColumn {
	None = 0,
	ExpandIcon,
	CollapseIcon,
	InExpandedRow,
	LastExpandedRow,
}

// TODO move to helper
type GetColumnValueType = {
	column: string;
	alarmDetail: AlarmDetail;
	data: AlarmTableDataSourceModel;
	isHeaderRow: boolean;
	isInstructionRow: boolean;
	alwaysShowInstructions: boolean;
};

// TODO move to helper
export const getColumnValue = ({
	column,
	alarmDetail,
	data,
	isHeaderRow,
	isInstructionRow,
	alwaysShowInstructions,
}: Partial<GetColumnValueType>): string | React.ReactNode | AlarmExpandColumn | Date | AlarmActionColumnData => {
	let columnValue: string | React.ReactNode | AlarmExpandColumn | Date | AlarmActionColumnData = undefined;

	switch (column) {
		case columnKeys.Description:
			columnValue = data.Description;
			break;

		case columnKeys.Address:
			columnValue = data.Address;
			break;

		case columnKeys.Level:
			columnValue = data.Level;
			break;

		case columnKeys.HostTime:
			columnValue = alarmDetail?.HostTime;
			break;

		case columnKeys.HardwareTime:
			columnValue = alarmDetail?.HardwareTime;
			break;

		case columnKeys.ActiveAlarmId:
			columnValue = data.EventId;
			break;

		case columnKeys.Count:
			let count: string | number = '';
			if (isHeaderRow) {
				if (data.HeaderAlarmDetails && data.HeaderAlarmDetails.length > 1) {
					count = data.HeaderAlarmDetails.length;
				} else if (data.AlarmDetails && data.AlarmDetails.length > 1) {
					count = data.AlarmDetails.length;
				}
			}

			columnValue = count;
			break;

		case columnKeys.Action:
			const alarmModalInfo: AlarmActionColumnData = getAlarmActionColumnDataObject(data, alarmDetail);
			columnValue = alarmModalInfo;
			break;

		case columnKeys.CollapseIcon:
			let value: AlarmExpandColumn = AlarmExpandColumn.None;
			if (isHeaderRow && !isInstructionRow) {
				if (data.IsExpanded) {
					value = AlarmExpandColumn.ExpandIcon;
				} else {
					value = AlarmExpandColumn.CollapseIcon;
				}
			} else if (isHeaderRow && isInstructionRow) {
				value = AlarmExpandColumn.InExpandedRow;
			} else if (data.ExpandColumn !== AlarmExpandColumn.None) {
				if (!isInstructionRow && alwaysShowInstructions && data.Instructions !== '' && data.ExpandColumn === AlarmExpandColumn.LastExpandedRow) {
					value = AlarmExpandColumn.InExpandedRow;
				} else {
					value = data.ExpandColumn;
				}
			}

			columnValue = value;
			break;
		case columnKeys.AcknowledgedTime:
			columnValue = alarmDetail?.Time;
			break;

		case columnKeys.AcknowledgedBy:
			columnValue = alarmDetail?.Operator;
			break;

		case columnKeys.Workstation:
			columnValue = alarmDetail?.Workstation;
			break;

		case columnKeys.Icon:
			columnValue = getIconColumn(data, alarmDetail?.ActiveAlarmId);
	}

	return columnValue ? columnValue : '';
};

export const getAlarmActionColumnDataObject = (alarmModel: AlarmTableDataSourceModel, alarmDetail: AlarmDetail): AlarmActionColumnData => ({
	ActiveAlarmId: alarmDetail?.ActiveAlarmId,
	AlarmNotes: alarmDetail?.AlarmNotes,
	Description: alarmModel?.Description,
	EventId: alarmModel?.EventId,
	HardwareTime: alarmDetail?.HardwareTime,
	HostTime: alarmDetail?.HostTime,
	Instructions: alarmModel?.Instructions,
	State: alarmModel?.State,
	IsChecked: alarmDetail?.IsChecked,
	IsExpandableRowHeader: alarmDetail?.IsExpandableRowHeader,
	IsExpanded: alarmModel?.IsExpanded,
	AlarmDetails: alarmModel?.AlarmDetails,
	columnActionHeader: false,
	dataSource: [],
});

const ExpandableRow = memo(
	({ data, index, setSize, windowWidth, columns, rowColumnsWidth, alwaysShowInstructions, handleOnToggleItemActive }: Props): JSX.Element => {
		const dispatch = useStoreDispatch();

		const rowRef = useRef<HTMLDivElement>(undefined);

		useEffect(() => {
			const { current } = rowRef;
			if (current) {
				setSize(index, current.getBoundingClientRect().height);
			}
		}, [setSize, index, windowWidth]);

		const getExpandIcon = ({ isActive }) => {
			if (isActive) {
				return <MinusSquareOutlined />;
			} else {
				return <PlusSquareOutlined />;
			}
		};

		const createRow = (alarmDetail: AlarmDetail, key: string, isHeaderRow: boolean = false, alarmIndex: number = index) => {
			let alarmModelModalInfo: AlarmModel = { ...data };
			const collapseColumns = columns.filter(x => x.dataIndex !== columnKeys.CollapseIcon);
			const showInstructions: boolean = data.Instructions !== '' && alwaysShowInstructions;
			const isHeader: boolean = key === 'header';
			const isEvenRow: boolean = alarmIndex % 2 === 0;

			if (showInstructions) {
				alarmModelModalInfo.AlarmDetails = isHeader ? data.AlarmDetails : [alarmDetail];
			}

			return (
				<div
					key={`${key}-${alarmIndex}`}
					className={cx({
						[styles.grayRowContent]: !isEvenRow && !isHeader,
						[styles.grayRow]: !isEvenRow && isHeader,
						[styles.whiteRowContent]: isEvenRow && !isHeader,
						[styles.whiteRow]: isEvenRow && isHeader,
					})}
					onClick={() => handleOnToggleItemActive(index)}>
					<div className={isHeaderRow ? styles.row : styles.collapseRow} style={rowColumnsWidth}>
						{collapseColumns.map((column: ColumnsProps<AlarmTableDataSourceModel>, columnIndex: number) => (
							<div key={`${key}-${alarmIndex}-${columnIndex}`} className={styles.cell}></div>
						))}
					</div>
					{showInstructions ? (
						<div className={isHeaderRow ? styles.instruction : styles.collapseInstruction}>
							<ReadOutlined style={instructionsStyle} />
							<Paragraph
								ellipsis={{
									rows: 1,
									expandable: true,
									symbol: <span className={styles.showMore}>{_('ShowMore')}</span>,
									onExpand: event => {
										event.preventDefault();
										event.stopPropagation();
										dispatch(setShowAlarmInstructions(alarmModelModalInfo));
									},
								}}>
								{data.Instructions}
							</Paragraph>
						</div>
					) : undefined}
				</div>
			);
		};

		const createHeader = () => {
			let alarmDetail: AlarmDetail = undefined;
			if (queueSortDir) {
				[alarmDetail] = data.AlarmDetails.slice(0, 1);
			} else {
				[alarmDetail] = data.AlarmDetails.slice(-1);
			}
			return createRow(alarmDetail, 'header', true);
		};

		const createRows = () => {
			let alarms: AlarmDetail[] = [];
			if (queueSortDir) {
				alarms = data.AlarmDetails.slice(1);
			} else {
				alarms = data.AlarmDetails.slice(0, -1);
			}

			return alarms.map((alarm, alarmIndex) => createRow(alarm, 'row', false, alarmIndex + index + 1));
		};

		const header = <>{data ? createHeader() : undefined}</>;
		const activeKey: string = data.IsExpanded ? index.toString() : '';

		return (
			<div
				ref={rowRef}
				className={cx(styles.collapseContainer, { [styles.grayRow]: index % 2 !== 0, [styles.whiteRow]: index % 2 === 0 })}
				onClick={() => handleOnToggleItemActive(index)}>
				<Collapse defaultActiveKey={activeKey} ghost expandIcon={getExpandIcon} collapsible='header'>
					<Panel key={index} header={header}>
						{createRows()}
					</Panel>
				</Collapse>
			</div>
		);
	},
	areEqual
);

export { ExpandableRow };

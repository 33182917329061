import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { CriteriaField } from '../../../../../model/CommonModel';
import { ReportSorting, SortingInfo } from '../../../../../model/ReportsModel';
import { Select, SelectOption } from '../../../../common';
import { sorting } from '../criteriaOptions';
import styles from '../reportTabs.module.scss';

type Props = {
	name: string;
	columnData: CriteriaField[];
	onAddSortingRequest: (sorting: SortingInfo) => void;
	lastKey: number;
	currentSortingFieldsId: number[];
	sortingToEdit: SortingInfo;
	hasUDFChanged: boolean;
	isDisabled: boolean;
	onResetUDFChanged: () => void;
};
const ReportSortingTab: React.FC<Props> = ({
	name,
	columnData,
	onAddSortingRequest,
	lastKey,
	currentSortingFieldsId,
	sortingToEdit,
	hasUDFChanged,
	isDisabled,
	onResetUDFChanged,
}) => {
	const [fieldSortingSelected, setFieldSortingSelected] = useState<CriteriaField>();
	const [sortingValue, setSortingValue] = useState<ReportSorting>();

	useEffect(() => {
		if (sortingToEdit) {
			setFieldSortingSelected(columnData.find(x => x.FieldId === sortingToEdit.FieldId));
			setSortingValue(sortingToEdit.Direction);
		} //reset form when sorting to edit is canceled or has been finished to edit
		else if (sortingToEdit === null) {
			onResetForm();
		}
	}, [sortingToEdit]);

	useEffect(() => {
		if (hasUDFChanged) {
			setFieldSortingSelected({ FieldId: 0 } as CriteriaField);
			setSortingValue(ReportSorting.asc);
			onResetUDFChanged();
		}
	}, [hasUDFChanged]);

	const handleSelectSortingField = (value: string) => {
		const fieldId = Number(value);
		const findValue = columnData.find(x => x.FieldId === fieldId);
		setFieldSortingSelected(findValue);
	};
	const handleAddSortingRequest = () => {
		const newKey = sortingToEdit ? sortingToEdit.key : lastKey + 1;
		const sortingRequest: SortingInfo = {
			Direction: sortingValue,
			FieldId: fieldSortingSelected.FieldId,
			sortingName: sorting.find(x => x.value === sortingValue).label,
			fieldName: fieldSortingSelected.DisplayName,
			key: newKey,
		};
		onAddSortingRequest(sortingRequest);
		onResetForm();
	};
	const onResetForm = () => {
		setFieldSortingSelected(undefined);
		setSortingValue(undefined);
	};
	const isDisabledAddButtonSorting = (): boolean => {
		const isCurrentlySorted = currentSortingFieldsId.some(x => x === fieldSortingSelected?.FieldId);
		if (!fieldSortingSelected) {
			return true;
		} else if (!sortingValue) {
			return true;
		} else if (isCurrentlySorted && !sortingToEdit) {
			return true;
		}
		return false;
	};
	const mapColumnDataToOption: SelectOption = columnData.map(x => ({
		label: x.DisplayName,
		value: x.FieldId,
		disabled: currentSortingFieldsId.some(f => f === x.FieldId && f !== sortingToEdit?.FieldId),
	}));
	return (
		<div className={styles.sorting}>
			<div>
				<label htmlFor='SelectSortingField'>{_('Field')}</label>
				<Select
					ariaAttributes={{
						'aria-label': _('SortingCriteriaField'),
						'aria-owns': 'SelectSortingField',
						'aria-activedescendant': 'SelectSortingField',
						'aria-controls': 'SelectSortingField',
						'aria-expanded': 'false',
					}}
					id='SelectSortingField'
					value={fieldSortingSelected?.FieldId.toString()}
					options={mapColumnDataToOption}
					onChange={handleSelectSortingField}
					disabled={isDisabled}
				/>
				<Button id='SortingUpdateAddButton' disabled={isDisabledAddButtonSorting() || isDisabled} onClick={() => handleAddSortingRequest()}>
					{sortingToEdit ? _('Update') : _('Add')}
				</Button>
			</div>
			<div>
				<label htmlFor='SelectDirectionField'>{_('Direction')}</label>
				<Select
					ariaAttributes={{
						'aria-label': _('SortingDirection'),
						'aria-owns': 'SelectDirectionField',
						'aria-activedescendant': 'SelectDirectionField',
						'aria-controls': 'SelectDirectionField',
						'aria-expanded': 'false',
					}}
					id='SelectDirectionField'
					value={sortingValue?.toString()}
					options={sorting}
					onChange={value => setSortingValue(Number(value) as ReportSorting)}
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};

export { ReportSortingTab };

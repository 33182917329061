import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { ReaderInterface } from '../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../common';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderCardMap, setExitReaderEnableReaderBezelTamper } from '../../exitReaderContext';
import { MatchCardReaderInterface, RS485CardReaderInterface } from '../sections';
import styles from './cardreadersetup.module.scss';

const CardReaderSetup: React.FC = () => {
	const {
		contextStateDoor: {
			exitReader: {
				cardMaps,
				selections: { cardMap },
				enableReaderBezelTamper,
				readerInterface,
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [cardMapsList, setCardMapsList] = useState([]);

	useEffect(() => {
		setCardMapsList(cardMaps.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [cardMaps]);

	const handleOnSetCardMap = (value: string) => {
		dispatcherDoor(setExitReaderCardMap(Number(value)));
	};

	const handleOnSetEnableReaderBezelTamper = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderEnableReaderBezelTamper(value.target.checked));
	};

	return (
		<div id='cardExitReaderContainer' className={styles.form}>
			<div className={styles.sections}>
				<span>{_('CustomCardCodes')}</span>
				<div>
					<Select
						className={styles.select}
						options={cardMapsList}
						onChange={handleOnSetCardMap}
						key='cardCode'
						id='exitReadeModalCustomCardCodesSelect'
						value={cardMap.toString()}
						getPopupContainer={() => document.getElementById('cardExitReaderContainer')}
					/>
				</div>
			</div>
			<div className={styles.sections}>
				<Checkbox checked={enableReaderBezelTamper} onChange={handleOnSetEnableReaderBezelTamper} id={'readerBezel'}>
					<span>{_('EnableReaderBezel')}</span>
				</Checkbox>
			</div>
			{readerInterface === ReaderInterface.MatchDS47 && <MatchCardReaderInterface />}
			{readerInterface === ReaderInterface.OnboardWiegand && <RS485CardReaderInterface isOnboardWiegand={true} />}
			{readerInterface === ReaderInterface.RS485 && <RS485CardReaderInterface isOnboardWiegand={false} />}
		</div>
	);
};

export { CardReaderSetup };

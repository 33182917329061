import { Button } from 'antd';
import React, { useState } from 'react';
import { ReportArchiveListInfo } from '../../../../../model/ReportsModel';
import { Modal } from '../../../../common';
import styles from './reportModalWarning.module.scss';

type Props = {
	data: ReportArchiveListInfo[];
	onVisible: () => void;
	generateReport: () => void;
};

const ReportModalWarning: React.FC<Props> = ({ data, onVisible, generateReport }) => {
	const [step, setStep] = useState<number>(1);

	const handleSetStep = () => {
		if (step === 1) {
			setStep(2);
		} else if (step === 2) {
			const checkIfFilesExist = data.some(x => !x.Exists);
			if (checkIfFilesExist) {
				setStep(3);
			} else {
				onVisible();
				generateReport();
			}
		} else {
			onVisible();
			generateReport();
		}
	};

	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<div>
						<label>{_('GetReportsWithoutCriteria')}</label>
					</div>
				);
			case 2:
				return (
					<div>
						<label>{_('ThisOperationNeedsToRestoreReports')}</label>
						<div className={styles.filesInfo}>
							{data.map((r, index) => (
								<label key={index}>{r.Name}</label>
							))}
						</div>
					</div>
				);
			case 3:
				return (
					<div>
						<label>{_('MissingArchiveFiles')}</label>
						<div className={styles.filesInfo}>
							{data
								.filter(w => !w.Exists)
								.map((r, index) => (
									<label key={index}>{r.Name}</label>
								))}
						</div>
					</div>
				);
		}
	};

	return (
		<Modal
			footer={[
				<Button id='ModalOkButton' key='save' title={''} type='primary' onClick={() => handleSetStep()}>
					<label htmlFor='ModalOkButton'>{_('Ok')}</label>
				</Button>,
				<Button id='ModalCancelButton' key='cancel' onClick={() => onVisible()}>
					<label htmlFor='ModalCancelButton'>{_('Cancel')}</label>
				</Button>,
			]}
			customZoomClass={styles.WithModalZoom}
			onClickOk={() => null}
			onCancel={() => null}
			title={''}
			closable={false}
			visible={true}>
			{renderStep()}
		</Modal>
	);
};

export { ReportModalWarning };

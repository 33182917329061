import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
	unlockHandleBeforeUnload,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	OperatorsColumn,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	SelectOptions,
	SelectedDeviceKeys,
	SelectedDevicePage,
} from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	DeviceObjectType,
	DeviceSelectedPage,
	GlobalIOGroup,
	GlobalIOGroupColumns,
	GlobalIOGroupTopic,
	OptionsButtonBuilder,
	SelectedRowKeyPagination,
	VelocityConfigurationModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import {
	clearVelocityConfigurationSelectionsAction,
	setGlobalIOGroupsBy,
	setGlobalIOGroupsSelected,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
	setVelocitySelectedRowKeysPaginationAction,
} from '../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfiguration } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, EditableCell, ModalConfirmationList, SearchColumn } from '../../../common';
import { GlobalIOGroupModal } from './GlobalIOGroupModal/GlobalIOGroupModal';
import styles from './networkGlobalIO.module.scss';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Global_IO_Group);
const scroll: ScrollType = { x: 379, y: 312 };
const maxLength: number = 64;
const deviceObjectType: DeviceObjectType = DeviceObjectType.GlobalIOGroup;

const createButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
				id: 'editNetworkGlobalIOButton',
				label: _('Edit'),
				disabled: !componentPermission.canView || hasMoreThanOne,
				value: 'edit',
				title: getPermissionErrorMessage(componentPermission.canView, false, hasMoreThanOne),
			},
			{
				id: 'renameNetworkGlobalIOButton',
				label: _('Rename'),
				disabled: !componentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
				value: 'rename',
				title: getPermissionErrorMessage(componentPermission.canUpdate, false, hasMoreThanOne),
			},
			{
				id: 'deleteNetworkGlobalIOButton',
				label: _('Delete'),
				disabled: !componentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(componentPermission.canDelete),
			},
		],
	};
};

const NetworkGlobalIO: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const { globalIOGroups, isEditMode, isFilterMode, selectedRowKeysPagination }: VelocityConfigurationModel = useStoreSelector(selectVelocityConfiguration);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [globalIOGroupKeyToEdit, setGlobalIOGroupKeyToEdit] = useState<string>('');
	const [globalIOGroupToEdit, setGlobalIOGroupToEdit] = useState<GlobalIOGroup>(undefined);
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<GlobalIOGroupColumns[]>([]);
	const [isCredentialModalVisible, setIsCredentialModalVisible] = useState<boolean>(false);
	const [isMasterCZModalVisible, setIsMasterCZModalVisible] = useState<boolean>(false);
	const [selectedGlobalIOGroupPage, setSelectedGlobalIOGroupPage] = useState<SelectedDevicePage>(null);
	const [form] = Form.useForm();

	const redirectPage = useRef<boolean>(false);
	const isSearchPerformed = useRef<boolean>(false);

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setIsItemOnSamePage(isSamePage);
		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	// * This hook is responsible for loading the previously edited command set
	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage.current) {
			const commandSetId = Number(selectedRowKeys?.[0]);
			getSelectedGlobalIOGroupPage(commandSetId);
		}
	}, [redirectPage.current]);

	useEffect(() => {
		if (selectedGlobalIOGroupPage) {
			setRedirectPage(false);
			setIsItemOnSamePage(true);

			const { deviceId, pageNumber } = selectedGlobalIOGroupPage;

			batch(() => {
				dispatch(setVelocitySelectedRowKeysPaginationAction([{ Id: deviceId.toString(), PaginationPage: pageNumber }]));
				dispatch(setGlobalIOGroupsBy({ ...tablePaginationSetting, PageNumber: pageNumber, SearchedValue: '' }));
			});

			setSelectedGlobalIOGroupPage(null);
		}
	}, [JSON.stringify(selectedGlobalIOGroupPage)]);

	useEffect(() => {
		const dataMapped: GlobalIOGroupColumns[] = createData();
		setTableData(dataMapped);
	}, [JSON.stringify(globalIOGroups)]);

	// * This hook ensure that always return a valid page number when no values has returned
	useEffect(() => {
		if (tablePaginationSetting.TotalItems === 0 && tablePaginationSetting.PageNumber > 1) {
			const { PageNumber: currentPage } = tablePaginationSetting;
			dispatch(
				setGlobalIOGroupsBy({
					...tablePaginationSetting,
					PageNumber: currentPage - 1,
				})
			);
		}
	}, [tablePaginationSetting]);

	useEffect(() => {
		return () => {
			clearSelection();
		};
	}, []);

	const getSelectedGlobalIOGroupPage = async (deviceId: number) => {
		const deviceSelectedPage: DeviceSelectedPage = {
			DeviceId: deviceId,
			DeviceObjectType: deviceObjectType,
			IsMasterDoorGroup: false,
		};
		const response = await deviceAdminApi.getVelocityDeviceSelectedPage(deviceSelectedPage, { ...tablePaginationSetting, SearchedValue: '' });
		const { Entity: pageNumber } = response;

		setSelectedGlobalIOGroupPage({ deviceId, pageNumber });
	};

	const clearSelection = () => {
		if (redirectPage.current) {
			return;
		}
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: deviceObjectType }));
		setSelectedRowKeys([]);
	};

	const getSelectedGlobalIOGroups = async (): Promise<SelectedDeviceKeys[]> => {
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);

		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const getGlobalIOGroup = async (globalIOGroupId: number): Promise<GlobalIOGroup> => {
		const response = await deviceAdminApi.getGlobalIOGroup(globalIOGroupId);

		if (handleResponse(response)) {
			return;
		}

		return response.Entity;
	};

	const getSelectedGlobalIOGroup = async (globalIOGroupId: number): Promise<GlobalIOGroup> => {
		return globalIOGroups.find(x => x.id === globalIOGroupId) ?? (await getGlobalIOGroup(globalIOGroupId));
	};

	const handleActionScope = async (key: string) => {
		if (key === 'delete') {
			const selectedGlobalIOGroups = await getSelectedGlobalIOGroups();
			ModalConfirmationList({
				type: _('GlobalIOGroup'),
				data: selectedGlobalIOGroups,
				onConfirm: () => handleOnTryDeleteGlobalIOGroup(selectedGlobalIOGroups),
			});

			return;
		}

		const selectedGlobalIOGroupId = Number(selectedRowKeys?.[0]);
		const globalIOGroup: GlobalIOGroup = await getSelectedGlobalIOGroup(selectedGlobalIOGroupId);
		const lockComponentCondition: boolean = key === 'edit';
		const isLocked: boolean = await lockedValidation(DeviceObjectType.GlobalIOGroup, globalIOGroup.id, lockComponentCondition);
		if (isLocked) {
			return;
		}

		switch (key) {
			case 'rename':
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: GlobalIOGroupColumns = tableData.find(x => x.key === globalIOGroup.id.toString());
				edit(findKey);
				const cloneState: GlobalIOGroup[] = changeStateProps(globalIOGroup.id, { editable: true });
				dispatch(setGlobalIOGroupsSelected(cloneState));
				break;

			case 'edit':
				globalIOGroup.topic === GlobalIOGroupTopic.CredentialManagement
					? handleSetCredentialModalVisible(true, globalIOGroup)
					: handleSetMasterCZModalVisible(true, globalIOGroup);
				break;
		}
	};

	const resetSearchColumn = () => {
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const handleOnSaveEditRow = async (globalIOGroup: GlobalIOGroup) => {
		const lockComponentCondition: boolean = false;
		const isLocked: boolean = await lockedValidation(DeviceObjectType.GlobalIOGroup, globalIOGroup.id, lockComponentCondition);

		if (isLocked) {
			setGlobalIOGroupKeyToEdit('');
			const cloneState: GlobalIOGroup[] = changeStateProps(globalIOGroup.id, { editable: false });
			dispatch(setGlobalIOGroupsSelected(cloneState));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(DeviceObjectType.GlobalIOGroup, name.trim(), globalIOGroup.id, globalIOGroup.topic);

		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});

			setGlobalIOGroupKeyToEdit('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setGlobalIOGroupsBy(tablePaginationSetting));
			return;
		}

		setGlobalIOGroupKeyToEdit('');
		dispatch(setVelocityConfigurationEditMode(false));
		resetSearchColumn();
		setRedirectPage(true);
		dispatch(setGlobalIOGroupsBy(tablePaginationSetting));
	};

	const handleOnCancelEditRow = (globalIOGroup: GlobalIOGroup) => {
		const cloneState: GlobalIOGroup[] = changeStateProps(globalIOGroup.id, { editable: false });
		dispatch(setGlobalIOGroupsSelected(cloneState));
		dispatch(setVelocityConfigurationEditMode(false));
		setGlobalIOGroupKeyToEdit('');
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getGlobalIOGroups();
		const commandSetsIds = response?.Entity.map<string>(x => x.id.toString());
		return getUniqueValuesArray(newSelectedRowKeys, commandSetsIds);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: GlobalIOGroup[] = globalIOGroups.map(x => ({ ...x, checked: selectedRowKeys.includes(x.id) }));
		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			selectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setGlobalIOGroupsSelected(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: GlobalIOGroup[] = globalIOGroups.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(r => r === x.id?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setGlobalIOGroupsSelected(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		setIsTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = globalIOGroups.map<React.Key>(x => x.id?.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		const cloneState: GlobalIOGroup[] = globalIOGroups.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.id?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setGlobalIOGroupsSelected(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const handleSetCredentialModalVisible = (isVisible: boolean, globalIOGroup?: GlobalIOGroup) => {
		globalIOGroup
			? setGlobalIOGroupToEdit(globalIOGroup)
			: setGlobalIOGroupToEdit({ id: 0, name: '', topic: GlobalIOGroupTopic.CredentialManagement } as GlobalIOGroup);

		setIsCredentialModalVisible(isVisible);
	};

	const handleSetMasterCZModalVisible = (isVisible: boolean, globalIOGroup?: GlobalIOGroup) => {
		globalIOGroup
			? setGlobalIOGroupToEdit(globalIOGroup)
			: setGlobalIOGroupToEdit({ id: 0, name: '', topic: GlobalIOGroupTopic.MasterControlZone } as GlobalIOGroup);

		setIsMasterCZModalVisible(isVisible);
	};

	const handleOnRemoveGlobalIOGroupsLocks = async (globalIOGroupId: number) => {
		unlockHandleBeforeUnload(globalIOGroupId, DeviceObjectType.GlobalIOGroup);
	};

	const handleOnTryDeleteGlobalIOGroup = async (selectedDeviceKeys: SelectedDeviceKeys[]) => {
		const selectedGlobalIOGroups: GlobalIOGroup[] = selectedDeviceKeys.map(x => ({ id: x.Id, name: x.Name } as GlobalIOGroup));
		for (const globalIOGroup of selectedGlobalIOGroups) {
			await handleOnDeleteGlobalIOGroup(globalIOGroup);
		}
		setSelectedRowKeys([]);
		dispatch(setVelocitySelectedRowKeysPaginationAction([]));
		dispatch(setGlobalIOGroupsBy(tablePaginationSetting));
	};

	const handleOnDeleteGlobalIOGroup = async (globalIOGroup: GlobalIOGroup) => {
		const globalIOGroupId = globalIOGroup.id;
		const response: ResponseObject = await deviceAdminApi.deleteGlobalIOGroup(globalIOGroupId);

		if (handleResponse(response)) {
			return;
		}

		await handleOnRemoveGlobalIOGroupsLocks(globalIOGroupId);
	};

	const handleBeforeUnload = () => {
		for (const key of selectedRowKeys) {
			unlockHandleBeforeUnload(Number(key), deviceObjectType);
		}
	};

	const changeStateProps = (newId: number, globalIOGroup: Partial<GlobalIOGroup>): GlobalIOGroup[] => {
		const cloneState: GlobalIOGroup[] = [...globalIOGroups];
		const index = cloneState.findIndex(x => x.id === Number(newId));
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...globalIOGroup });
		}

		return cloneState;
	};

	const edit = (record: GlobalIOGroupColumns) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setGlobalIOGroupKeyToEdit(record.key.toString());
	};

	const createActionElement = (globalIOGroup: GlobalIOGroup): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (globalIOGroup.editable) {
			content = (
				<>
					<Button id='renameNetworkGlobalIOSaveButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(globalIOGroup)}>
						{_('Save')}
					</Button>
					<Button id='renameNetworkGlobalIOCancelButton' key='cancel' onClick={() => handleOnCancelEditRow(globalIOGroup)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): GlobalIOGroupColumns[] => {
		if (globalIOGroups.length > 0) {
			const globalIOGroupsMapped: GlobalIOGroupColumns[] = globalIOGroups.map<GlobalIOGroupColumns>((dataItem: GlobalIOGroup, index: number) => ({
				...dataItem,
				Action: createActionElement(dataItem),
				key: dataItem.id.toString(),
				Name: dataItem.name,
				Topic: dataItem.topic === GlobalIOGroupTopic.CredentialManagement ? _('CredentialManagement') : _('MasterControlZone'),
			}));

			return globalIOGroupsMapped;
		}

		return [];
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} disabled={x.disabled} onClick={x.onClick} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const buttons: ButtonBuilder[] = [
		{
			id: 'addNetworkGlobalIOCredentialManagementGroupButton',
			label: _('AddNetworkGlobalIOCredentialManagementGroup'),
			icon: <PlusOutlined />,
			onClick: () => {
				handleSetCredentialModalVisible(true, undefined);
			},
			disabled: !componentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(componentPermission.canAdd),
		},
		{
			id: 'addNetworkGlobalIOMasterControlZoneGroupButton',
			label: _('AddNetworkGlobalIOMasterControlZoneGroup'),
			icon: <PlusOutlined />,
			onClick: () => {
				handleSetMasterCZModalVisible(true, undefined);
			},
			disabled: !componentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(componentPermission.canAdd),
		},
	];

	const disabled: boolean = globalIOGroupKeyToEdit !== '';
	const hasMoreThanOneKeySelected: boolean = selectedRowKeys.length > 1;
	const items = createButtonOptions(hasMoreThanOneKeySelected, isItemOnSamePage);

	const onChangeSelection = (key: BaseColumns) => {
		const globalIOId = key.key.toString();
		if (selectedRowKeys.findIndex(key => key === globalIOId) === -1) {
			const cloneState: GlobalIOGroup[] = globalIOGroups.map(g => (g.id.toString() === globalIOId ? { ...g, checked: true } : { ...g, checked: false }));
			dispatch(setGlobalIOGroupsSelected(cloneState));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: globalIOId.toString(),
				PaginationPage: tablePaginationSetting.PageNumber,
			};
			setIsItemOnSamePage(true);
			dispatch(setVelocitySelectedRowKeysPaginationAction([{ ...selectedKeysPagination }]));
			setSelectedRowKeys([globalIOId]);
		}
	};

	const handleOnSearchResults = (searchedValue: string) => {
		if (redirectPage.current) {
			return;
		}
		dispatch(setGlobalIOGroupsBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	const columns: ColumnsProps<GlobalIOGroupColumns>[] = [
		{
			...buildActionColumn(disabled),
		},
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
		{
			...buildColumn(_('Topic'), 'Topic', 'auto', 'start'),
			sorter: !isEditMode,
		},
	];

	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];
	const isEditting = (record: GlobalIOGroupColumns) => record.key.toString() === globalIOGroupKeyToEdit;

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: GlobalIOGroupColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: GlobalIOGroupColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						inputType: 'text',
						editing: isEditting(record),
					}),
			  }
	);

	const rowSelection: TableRowSelection<GlobalIOGroupColumns> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `globalIOGroupTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`globalIOGroupTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectNetwork')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<OperatorsColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setGlobalIOGroupsBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<div id='networkGlobalIOContainer' className={styles.container}>
			<div className={styles.buttonsContainer}>
				<ButtonDropdown
					id={'networkGlobalIOTableActionDropdown'}
					disabled={disabledActionButtons}
					menuOptions={items.options}
					icon={<CaretDownOutlined />}
					labelIcon={items.labelOrIcon}
					onClickOption={handleActionScope}
				/>
				{buttonsBuilder(buttons)}
			</div>
			<Form form={form} component={false}>
				<Table
					id='networkGlobalIOTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					scroll={scroll}
					pagination={pagination}
					size='small'
					className={styles.table}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
				/>
			</Form>
			{isCredentialModalVisible && (
				<GlobalIOGroupModal
					globalIOGroupToEdit={globalIOGroupToEdit}
					onCloseModal={() => handleSetCredentialModalVisible(false)}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
			{isMasterCZModalVisible && (
				<GlobalIOGroupModal
					globalIOGroupToEdit={globalIOGroupToEdit}
					onCloseModal={() => handleSetMasterCZModalVisible(false)}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
		</div>
	);
};

export { NetworkGlobalIO };

import React, { useContext } from 'react';
import { PortNetWorkType } from '../../../../../../../../../model/DeviceAdminModel';
import { IPv4AddressInput } from '../../../../../../../../common/IPv4AddressInput/IPv4AddressInput';
import { IPv6AddressInput } from '../../../../../../../../common/IPv6AddressInput/IPv6AddressInput';
import { PortStoreContext, setValueSNIBSelectedAction } from '../../../../../contextPort';
import styles from '../../snibConfiguration.module.scss';

type Props = {
	isIPAddressInvalid: boolean;
	onResetIPAddressError: () => void;
};

const IPAddress: React.FC<Props> = ({ isIPAddressInvalid, onResetIPAddressError }) => {
	const {
		portState: { snibSelected, networkType },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleIPv4AddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'IPAddress', value: value }));
		onResetIPAddressError();
	};

	const handleIPv6AddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'IPv6Address', value: value }));
		onResetIPAddressError();
	};

	const searchSNIBConfigurationIPv6AddressInputId = 'searchSNIBConfigurationIPv6AddressInput';
	const searchSNIBConfigurationIPv4AddressInputId = 'searchSNIBConfigurationIPv4AddressInput';

	return (
		<div className={styles.row}>
			<label id='searchSNIBConfigurationIPAddressLabel'>{_('IPAddress')}</label>
			<div className={styles.inputContainer}>
				{networkType === PortNetWorkType.IPv6 ? (
					<IPv6AddressInput
						id='searchSNIBConfigurationIPv6AddressInput'
						key='ipv6Address'
						addressValidation={isIPAddressInvalid}
						value={snibSelected?.IPv6Address}
						defaultValue={'0000:0000:0000:0000:0000:0000:0000:0000'}
						onChange={handleIPv6AddressChange}
						disabled={snibSelected?.DHCPEnabled === 'Yes'}
					/>
				) : (
					<IPv4AddressInput
						id='searchSNIBConfigurationIPv4AddressInput'
						key='ipv4Address'
						addressValidation={isIPAddressInvalid}
						value={snibSelected?.IPAddress}
						defaultValue={'0.0.0.0'}
						onChange={handleIPv4AddressChange}
						disabled={snibSelected?.DHCPEnabled === 'Yes'}
					/>
				)}
				{isIPAddressInvalid && (
					<label
						id='searchSNIBConfigurationIPAddressErrorLabel'
						htmlFor={networkType === PortNetWorkType.IPv6 ? searchSNIBConfigurationIPv6AddressInputId : searchSNIBConfigurationIPv4AddressInputId}
						className={styles.errorMessage}>
						{_('IPAddressIsRequired')}
					</label>
				)}
			</div>
		</div>
	);
};

export { IPAddress };

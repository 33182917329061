import { CustomFilterGroup, EventFilter } from '../../model/EventModel';
import { EventAction } from './actions';
import { EventActionType } from './actionsTypes';

export type EventState = {
	eventFilters: EventFilter[];
	customFilterGroups: CustomFilterGroup[];
	selectedCustomFilterGroups: CustomFilterGroup[];
};

//States' definition
// add readonly state for avoid to overwrite default state
const INIT_STATE: EventState = {
	eventFilters: undefined,
	customFilterGroups: undefined,
	selectedCustomFilterGroups: [],
};

//Reducers
const eventReducer = (state: Readonly<EventState> = INIT_STATE, action: EventAction): EventState => {
	switch (action.type) {
		case EventActionType.SET_EVENT_FILTERS: {
			return {
				...state,
				eventFilters: action.payload,
			};
		}

		case EventActionType.SET_CUSTOM_FILTER_GROUPS: {
			return {
				...state,
				customFilterGroups: action.payload,
			};
		}

		case EventActionType.SET_SELECTED_CUSTOM_FILTER_GROUPS: {
			return {
				...state,
				selectedCustomFilterGroups: action.payload,
			};
		}

		default:
			return state;
	}
};

export { eventReducer };

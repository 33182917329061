import React from 'react';
import { SCIBInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerMiscSystemSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerMisc } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectDisableOptions, selectSCIBInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../setup.module.scss';

const Scib: React.FC = () => {
	const scibInfo: SCIBInfoStore = useStoreSelector<SCIBInfoStore>(selectSCIBInfo);
	const disableOptions: boolean = useStoreSelector<boolean>(selectDisableOptions);
	const dispatch = useStoreDispatch();

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	return (
		<CardSection title={_('SCIB')}>
			<div className={styles.scibContainer}>
				<div>
					<span>{_('Baud')}:</span>
					<Select
						id='controllerSetupSCIBBaudDropdown'
						onChange={value => handleChangeControllerMiscSystemSetup({ SCIBBaud: Number(value) })}
						notUseCurrentParse
						value={scibInfo.baud.value}
						options={scibInfo.baud.options.map(x => ({ label: x.Name, value: x.Id }))}
						disabled={disableOptions}
						getPopupContainer={element => element}
					/>
				</div>
				<div>
					<span>{_('Parity')}:</span>
					<Select
						id='controllerSetupSCIBParityDropdown'
						onChange={value => handleChangeControllerMiscSystemSetup({ SCIBParity: Number(value) })}
						notUseCurrentParse
						value={scibInfo.parity.value}
						disabled={disableOptions}
						options={scibInfo.parity.options.map(x => ({ label: x.Name, value: x.Id }))}
						getPopupContainer={element => element}
					/>
				</div>
				<div>
					<span>{_('DataBits')}:</span>
					<Select
						id='controllerSetupSCIBDataBitsDropdown'
						onChange={value => handleChangeControllerMiscSystemSetup({ SCIBDataBits: Number(value) })}
						notUseCurrentParse
						value={scibInfo.dataBits.value}
						disabled={disableOptions}
						options={scibInfo.dataBits.options.map(x => ({ label: x.Name, value: x.Id }))}
						getPopupContainer={element => element}
					/>
				</div>
				<div>
					<span>{_('StopBits')}:</span>
					<Select
						id='controllerSetupSCIBStopBitsDropdown'
						onChange={value => handleChangeControllerMiscSystemSetup({ SCIBStopBits: Number(value) })}
						notUseCurrentParse
						value={scibInfo.stopBits.value}
						disabled={disableOptions}
						options={scibInfo.stopBits.options.map(x => ({ label: x.Name, value: x.Id }))}
						getPopupContainer={element => element}
					/>
				</div>
			</div>
		</CardSection>
	);
};

export { Scib };

import { CriteriaFunctionValue, CriteriaOperatorFunction, FieldDataType, SelectOptions, WithinTimes } from '../../../../model/CommonModel';
import { ReportExportFormat, ReportSorting } from '../../../../model/ReportsModel';
import { SelectOption } from '../../../common';

export type SelectOptionForCriteria = Omit<SelectOptions<string | number>, 'icon' | 'separator'> & {
	fieldType: FieldDataType[];
};

export const criteria: SelectOptionForCriteria[] = [
	{
		label: _('between'),
		value: CriteriaFunctionValue.between,
		fieldType: [FieldDataType.DateTime, FieldDataType.Numeric],
	},
	{
		label: _('equalto'),
		value: CriteriaFunctionValue.equalTo,
		fieldType: [FieldDataType.DateTime, FieldDataType.Numeric, FieldDataType.String, FieldDataType.Boolean],
	},
	{
		label: _('greaterthan'),
		value: CriteriaFunctionValue.greaterThan,
		fieldType: [FieldDataType.DateTime, FieldDataType.Numeric],
	},
	{
		label: _('lessthan'),
		value: CriteriaFunctionValue.lessThan,
		fieldType: [FieldDataType.DateTime, FieldDataType.Numeric],
	},
	{
		label: _('like'),
		value: CriteriaFunctionValue.like,
		fieldType: [FieldDataType.String],
	},
	{
		label: _('startingwith'),
		value: CriteriaFunctionValue.startingWith,
		fieldType: [FieldDataType.String],
	},
	{
		label: _('within'),
		value: CriteriaFunctionValue.within,
		fieldType: [FieldDataType.DateTime],
	},
];

export const functionCriteria: SelectOption = [
	{
		label: _('is'),
		value: CriteriaOperatorFunction.is,
	},
	{
		label: _('isnot'),
		value: CriteriaOperatorFunction.isNot,
	},
];

export const sorting: SelectOption = [
	{
		label: _('Ascending'),
		value: ReportSorting.asc,
	},
	{
		label: _('Descending'),
		value: ReportSorting.desc,
	},
];

export const exportTypes: SelectOption = [
	{
		label: _('AcrobatPDF'),
		value: ReportExportFormat.PDF,
	},
	{
		label: _('Excel97DataOnly'),
		value: ReportExportFormat.Xls,
	},
];

export const withinTimes: SelectOption = [
	{
		label: _('AnHour'),
		value: WithinTimes.anHour,
	},
	{
		label: `12 ${_('Hours')}`,
		value: WithinTimes.twelveHours,
	},
	{
		label: _('ADay'),
		value: WithinTimes.aDay,
	},
	{
		label: `2 ${_('Days')}`,
		value: WithinTimes.twoDays,
	},
	{
		label: `3 ${_('Days')}`,
		value: WithinTimes.threeDays,
	},
	{
		label: _('AWeek'),
		value: WithinTimes.aWeek,
	},
	{
		label: _('AMonth'),
		value: WithinTimes.aMonth,
	},
	{
		label: _('AYear'),
		value: WithinTimes.aYear,
	},
];

export const buildCriteriaDescription = (field: string, functionOperator: string, criteria: string, primaryInput: string, secondaryInput: string) => {
	let displayName = '';
	displayName = `${field} ${functionOperator} ${criteria} ${primaryInput}`;
	if (secondaryInput) {
		displayName = `${displayName} ${_('AND').toLocaleUpperCase()} ${secondaryInput}`;
	}
	return displayName;
};

export const isOrEqualToConverter = (
	criteria: CriteriaFunctionValue,
	isOrEqualTo: boolean,
	functionValue: CriteriaOperatorFunction
): { criteriaConverted: CriteriaFunctionValue; criteriaText: CriteriaFunctionValue; newFunctionValue: CriteriaOperatorFunction } => {
	if (functionValue === CriteriaOperatorFunction.is) {
		if (isOrEqualTo && criteria === CriteriaFunctionValue.greaterThan) {
			return {
				criteriaConverted: CriteriaFunctionValue.greaterThanOrEqualTo,
				criteriaText: CriteriaFunctionValue.greaterThanOrEqualTo,
				newFunctionValue: functionValue,
			};
		}
		if (isOrEqualTo && criteria === CriteriaFunctionValue.lessThan) {
			return {
				criteriaConverted: CriteriaFunctionValue.lessThanOrEqualTo,
				criteriaText: CriteriaFunctionValue.lessThanOrEqualTo,
				newFunctionValue: functionValue,
			};
		}
	} else {
		if (isOrEqualTo && criteria === CriteriaFunctionValue.greaterThan) {
			return {
				criteriaConverted: CriteriaFunctionValue.greaterThanOrEqualTo,
				criteriaText: CriteriaFunctionValue.lessThan,
				newFunctionValue: CriteriaOperatorFunction.is,
			};
		}
		if (!isOrEqualTo && criteria === CriteriaFunctionValue.greaterThan) {
			return {
				criteriaConverted: CriteriaFunctionValue.greaterThan,
				criteriaText: CriteriaFunctionValue.lessThanOrEqualTo,
				newFunctionValue: CriteriaOperatorFunction.is,
			};
		}
		if (isOrEqualTo && criteria === CriteriaFunctionValue.lessThan) {
			return {
				criteriaConverted: CriteriaFunctionValue.lessThanOrEqualTo,
				criteriaText: CriteriaFunctionValue.greaterThan,
				newFunctionValue: CriteriaOperatorFunction.is,
			};
		}
		if (!isOrEqualTo && criteria === CriteriaFunctionValue.lessThan) {
			return {
				criteriaConverted: CriteriaFunctionValue.lessThan,
				criteriaText: CriteriaFunctionValue.greaterThanOrEqualTo,
				newFunctionValue: CriteriaOperatorFunction.is,
			};
		}
	}

	return { criteriaConverted: criteria, criteriaText: criteria, newFunctionValue: functionValue };
};

export const getRemoveTimeSetup = (withinTimes: WithinTimes) => {
	let setup;

	switch (withinTimes) {
		case WithinTimes.anHour:
			setup = { amount: 1, unit: 'h' };
			break;

		case WithinTimes.twelveHours:
			setup = { amount: 12, unit: 'h' };
			break;

		case WithinTimes.aDay:
			setup = { amount: 1, unit: 'd' };
			break;

		case WithinTimes.twoDays:
			setup = { amount: 2, unit: 'd' };
			break;

		case WithinTimes.threeDays:
			setup = { amount: 3, unit: 'd' };
			break;

		case WithinTimes.aWeek:
			setup = { amount: 1, unit: 'w' };
			break;

		case WithinTimes.aMonth:
			setup = { amount: 1, unit: 'M' };
			break;

		case WithinTimes.aYear:
			setup = { amount: 1, unit: 'y' };
			break;
	}

	return setup;
};

import {
	ActiveDirectoryUserFlags,
	EntryErrorFlags,
	FieldProp,
	OperatorPropertyModel,
	OperatorRoleModifications,
	OperatorSecurityKeyContext,
	RootModelContext,
} from '../../../../../../model/OperatorModel';
import {
	ContextActionTypes,
	setErrorEntryFlag,
	setErrorEntryFlags,
	setOperator,
	setOperatorPasswordFlags,
	setOperatorProp,
	setOperatorRoleModifications,
	setRootModelProperties,
	setRootModelProperty,
	setSecurityKeyModifications,
	setSecurityKeyTableEditMode,
	setTwoFactorTabModification,
	setWindowsCredentialProperty,
	updateSecurityKeyModifications,
} from './actionTypes';

const setRootModelPropertiesAction = (payload: RootModelContext): setRootModelProperties => {
	return {
		type: ContextActionTypes.SET_ROOT_MODEL_PROPERTIES,
		payload,
	};
};

const setRootModelPropertyAction = (payload: FieldProp): setRootModelProperty => {
	return {
		type: ContextActionTypes.SET_ROOT_MODEL_PROPERTY,
		payload,
	};
};

const setErrorEntryFlagsAction = (payload: EntryErrorFlags): setErrorEntryFlags => {
	return {
		type: ContextActionTypes.SET_ERROR_ENTRY_FLAGS,
		payload,
	};
};

const setErrorEntryFlagAction = (payload: FieldProp): setErrorEntryFlag => {
	return {
		type: ContextActionTypes.SET_ERROR_ENTRY_FLAG,
		payload,
	};
};

const setWindowsCredentialPropertyAction = (payload: FieldProp): setWindowsCredentialProperty => {
	return {
		type: ContextActionTypes.SET_WINDOWS_CREDENTIAL_PROPERTY,
		payload,
	};
};

const setOperatorAction = (payload: OperatorPropertyModel): setOperator => {
	return {
		type: ContextActionTypes.SET_OPERATOR,
		payload,
	};
};

const setOperatorPropAction = (payload: FieldProp): setOperatorProp => {
	return {
		type: ContextActionTypes.SET_OPERATOR_PROP,
		payload,
	};
};

const setOperatorPasswordFlagsAction = (payload: ActiveDirectoryUserFlags): setOperatorPasswordFlags => {
	return {
		type: ContextActionTypes.SET_OPERATOR_PASSWORD_FLAGS,
		payload,
	};
};

const setOperatorRoleModificationsAction = (payload: OperatorRoleModifications): setOperatorRoleModifications => {
	return {
		type: ContextActionTypes.SET_OPERATOR_ROLES_MODIFICATIONS,
		payload,
	};
};

const setSecurityKeyModificationsAction = (payload: OperatorSecurityKeyContext): setSecurityKeyModifications => {
	return {
		type: ContextActionTypes.SET_SECURITY_KEYS_MODIFICATIONS,
		payload,
	};
};

const setSecurityKeyTableEditModeAction = (payload: boolean): setSecurityKeyTableEditMode => {
	return {
		type: ContextActionTypes.SET_SECURITY_KEYS_TABLE_EDIT_MODE,
		payload,
	};
};

const setTwoFactorTabModificationAction = (payload: boolean): setTwoFactorTabModification => {
	return {
		type: ContextActionTypes.SET_TWO_FACTOR_TAB_MODIFICATIONS,
		payload,
	};
};

const updateSecurityKeyModificationsAction = (payload: OperatorSecurityKeyContext): updateSecurityKeyModifications => {
	return {
		type: ContextActionTypes.UPDATE_SECURITY_KEYS_MODIFICATIONS,
		payload,
	};
};

export {
	setErrorEntryFlagAction,
	setErrorEntryFlagsAction,
	setOperatorAction,
	setOperatorPasswordFlagsAction,
	setOperatorPropAction,
	setOperatorRoleModificationsAction,
	setRootModelPropertiesAction,
	setRootModelPropertyAction,
	setSecurityKeyModificationsAction,
	setSecurityKeyTableEditModeAction,
	setTwoFactorTabModificationAction,
	setWindowsCredentialPropertyAction,
	updateSecurityKeyModificationsAction,
};

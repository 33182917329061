import { CriteriaField, PaginationSetting, PreviewBadgeResponse, ResponseObject, ResponseObjectEntity } from '../model/CommonModel';
import { ColumnConfiguration } from '../model/ConfigurationModel';
import { CredentialPreviewBadge } from '../model/CredentialModel';
import { DoorGroup, ReaderControlGroupItem } from '../model/DeviceAdminModel';
import {
	AdditionalImageList,
	AdditionalNote,
	AdditionalNotesCategory,
	AdvanceSearchCriteria,
	BadgeTemplate,
	CardFormat,
	CredentialMessages,
	CredentialTemplate,
	EnrollmentGridItem,
	EnrollmentResponse,
	EnrollmentTabInfo,
	FunctionCategory,
	FunctionGroup,
	PersonDelete,
	PersonDetailModel,
	PersonEnrollment,
	PersonModel,
	PivEnrollment,
	PivProfileResponse,
	PivValidation,
	SearchUniqueValues,
	ThreatLevel,
	XboxController,
} from '../model/EnrollmentModel';
import { UDFGroupModel, UserDefinedFieldSetup, UserDefinedFieldType } from './../model/UserDefinedFields';
import { Api } from './api';

class EnrollmentApi extends Api {
	public constructor() {
		super();
	}

	public retrieveValidationProfiles = async (): Promise<PivProfileResponse> => {
		const url = this.getUrl('Enrollment/GetValidationProfiles', true);

		return await this.post<PivProfileResponse>(url);
	};

	public getPivEnrollment = async (readCardInfo: PivEnrollment): Promise<string> => {
		const url = this.getUrl('Enrollment/GetPivEnrollment', true);

		return await this.post<string>(url, readCardInfo);
	};

	public retrievePivEnrollment = async (pageId: string): Promise<PivValidation> => {
		const url = this.getUrl('Enrollment/RetrievePivEnrollment', true);
		const data = {
			pageId,
		};

		return await this.post<PivValidation>(url, data);
	};

	public createPersonEnrollment = async (personEnrollment: PersonEnrollment): Promise<ResponseObjectEntity<PersonDetailModel>> => {
		const url = this.getUrl('Enrollment/CreatePersonEnrollment', true);

		return await this.post<ResponseObjectEntity<PersonDetailModel>>(url, personEnrollment);
	};

	public updatePersonEnrollment = async (personEnrollment: PersonEnrollment): Promise<ResponseObjectEntity<PersonDetailModel>> => {
		const url = this.getUrl('Enrollment/UpdatePersonEnrollment', true);

		return await this.post<ResponseObjectEntity<PersonDetailModel>>(url, personEnrollment);
	};

	public getBadgeTemplates = async (): Promise<BadgeTemplate[]> => {
		const url = this.getUrl('Enrollment/GetBadgeTemplates', true);

		return await this.post<BadgeTemplate[]>(url);
	};

	public getUserDefinedFields = async (): Promise<ResponseObjectEntity<UserDefinedFieldSetup[]>> => {
		const url = this.getUrl('Enrollment/GetUserDefinedFields', true);

		return await this.post<ResponseObjectEntity<UserDefinedFieldSetup[]>>(url);
	};

	public getEnrollmentTabInfo = async (): Promise<EnrollmentTabInfo> => {
		const url = this.getUrl('Enrollment/LoadEnrollmentTab', true);

		return await this.post<EnrollmentTabInfo>(url);
	};

	public getPersonsByName = async (name: string, personGroupId: number, folderId: number): Promise<PersonModel[]> => {
		const url = this.getUrl('Enrollment/GetPersonsByName', true);
		const data = {
			name,
			personGroupId,
			folderId,
		};

		return await this.post<PersonModel[]>(url, data);
	};

	public getPersonColumnsConfiguration = async (): Promise<ColumnConfiguration> => {
		const url = this.getUrl('Enrollment/GetPersonColumnsConfiguration', true);

		return await this.post<ColumnConfiguration>(url);
	};

	public retrievePersonGroupsPagination = async (
		groupId: number,
		folderId: number,
		paginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<EnrollmentGridItem>> => {
		const url = this.getUrl('Enrollment/RetrievePersonGroupsPagination', true);
		const data = {
			groupId,
			folderId,
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<EnrollmentGridItem>>(url, data);
	};

	public getAdvanceSearchUserFields = async (): Promise<CriteriaField[]> => {
		const url = this.getUrl('Enrollment/GetAdvanceSearchUserFields', true);

		return await this.post<CriteriaField[]>(url);
	};

	public getAllUsersPagination = async (advanceSearchCriteria: AdvanceSearchCriteria, searchKeyword: string): Promise<ResponseObjectEntity<string[]>> => {
		const url = this.getUrl('Enrollment/GetAllUsersPagination', true);
		const data = { advanceSearchCriteria, searchKeyword };

		return await this.post<ResponseObjectEntity<string[]>>(url, data);
	};

	public getUsersToDeleteInfo = async (usersIds: number[]): Promise<ResponseObjectEntity<PersonDelete[]>> => {
		const url = this.getUrl('Enrollment/GetUsersToDeleteInfo', true);

		return await this.post<ResponseObjectEntity<PersonDelete[]>>(url, { usersIds });
	};

	public deletePerson = async (personId: number): Promise<ResponseObject> => {
		const url = this.getUrl('Enrollment/DeletePerson', true);

		return await this.post<ResponseObject>(url, { personId });
	};

	public getUsersByAdvancedSearch = async (
		advanceSearchCriteria: AdvanceSearchCriteria,
		paginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<EnrollmentGridItem>> => {
		const url = this.getUrl('Enrollment/GetUsersByAdvancedSearch', true);
		const data = {
			advanceSearchCriteria,
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<EnrollmentGridItem>>(url, data);
	};

	public getUniqueColumnSearchValuesBySearchText = async (
		criteriaField: CriteriaField,
		searchUniqueValuesHelper: Partial<SearchUniqueValues>
	): Promise<string[]> => {
		const url = this.getUrl('Enrollment/GetUniqueColumnSearchValuesBySearchText', true);
		const data = {
			criteriaField,
			searchUniqueValuesHelper,
		};

		return await this.post<string[]>(url, data);
	};

	public getPreviewBadges = async (credentialsIds: number[], isPrintAction: boolean): Promise<ResponseObjectEntity<PreviewBadgeResponse[]>> => {
		const url = this.getUrl('Enrollment/GetPreviewBadges', true);

		return await this.post<ResponseObjectEntity<PreviewBadgeResponse[]>>(url, { credentialsIds, isPrintAction });
	};

	public getNewCredentialPrintableBadge = async (credentialPreviewBadge: CredentialPreviewBadge): Promise<PreviewBadgeResponse> => {
		const url = this.getUrl('Enrollment/GetNewCredentialPrintableBadge', true);
		const data = {
			credentialPreviewBadge,
		};

		return await this.post<PreviewBadgeResponse>(url, data);
	};

	public enrollPerson = async (person: PersonDetailModel): Promise<EnrollmentResponse> => {
		const url = this.getUrl('Enrollment/EnrollPerson', true);

		return await this.post<EnrollmentResponse>(url, { person });
	};

	public getCardFormats = async (): Promise<CardFormat[]> => {
		const url = this.getUrl('Enrollment/GetCardFormats', true);

		return await this.post<CardFormat[]>(url);
	};

	public getCredentialTemplatesByOperator = async (): Promise<ResponseObjectEntity<CredentialTemplate[]>> => {
		const url = this.getUrl('Enrollment/GetCredentialTemplatesByOperator', true);

		return await this.post<ResponseObjectEntity<CredentialTemplate[]>>(url);
	};

	public getFunctionCategories = async (): Promise<FunctionCategory[]> => {
		const url = this.getUrl('Enrollment/GetFunctionCategories', true);

		return await this.post<FunctionCategory[]>(url);
	};

	public getXboxControllers = async (): Promise<XboxController[]> => {
		const url = this.getUrl('Enrollment/GetOperatorXboxControllers', true);

		return await this.post<XboxController[]>(url);
	};

	public getOperatorDoorGroups = async (): Promise<DoorGroup[]> => {
		const url = this.getUrl('Enrollment/GetOperatorDoorGroups', true);

		return await this.post<DoorGroup[]>(url);
	};

	public getOperatorFunctionGroups = async (): Promise<FunctionGroup[]> => {
		const url = this.getUrl('Enrollment/GetOperatorFunctionGroups', true);

		return await this.post<FunctionGroup[]>(url);
	};

	public getCredentialTemplateReaderControlGroups = async (): Promise<ReaderControlGroupItem[]> => {
		const url = this.getUrl('Enrollment/GetCredentialTemplateReaderControlGroups', true);

		return await this.post<ReaderControlGroupItem[]>(url);
	};

	public getCredentialTemplateThreatLevels = async (): Promise<ThreatLevel[]> => {
		const url = this.getUrl('Enrollment/GetCredentialTemplateThreatLevels', true);

		return await this.post<ThreatLevel[]>(url);
	};

	public getPersonInformation = async (personId?: number, lockRequired: boolean = true): Promise<PersonDetailModel> => {
		const url = this.getUrl('Enrollment/GetPersonInformation', true);

		return await this.post<PersonDetailModel>(url, { personId, lockRequired });
	};

	public getUDFTabs = async (): Promise<UDFGroupModel[]> => {
		const url = this.getUrl('Enrollment/GetUDFTabs', true);

		return await this.post<UDFGroupModel[]>(url);
	};

	public getAdditionalImageList = async (personId: number, paginationSetting: PaginationSetting): Promise<AdditionalImageList> => {
		const url = this.getUrl('Enrollment/GetAdditionalImageList', true);

		return await this.post<AdditionalImageList>(url, { personId, paginationSetting });
	};

	public getAdditionalNotesCategories = async (): Promise<ResponseObjectEntity<AdditionalNotesCategory[]>> => {
		const url = this.getUrl('Enrollment/GetAdditionalNotesCategories', true);

		return await this.post<ResponseObjectEntity<AdditionalNotesCategory[]>>(url);
	};

	public getAdditionalNote = async (categoryId: number, personId: number): Promise<ResponseObjectEntity<AdditionalNote>> => {
		const url = this.getUrl('Enrollment/GetAdditionalNote', true);

		return await this.post<ResponseObjectEntity<AdditionalNote>>(url, { categoryId, personId });
	};

	public getUserDefinedFieldSetups = async (udfFieldType: UserDefinedFieldType): Promise<ResponseObjectEntity<UserDefinedFieldSetup[]>> => {
		const url = this.getUrl('Enrollment/GetUserDefinedFieldSetups', true);
		const data = { udfFieldType };

		return await this.post<ResponseObjectEntity<UserDefinedFieldSetup[]>>(url, data);
	};

	public getCredentialMessages = async (): Promise<CredentialMessages> => {
		const url = this.getUrl('Enrollment/GetCredentialMessages', true);

		return await this.post<CredentialMessages>(url);
	};

	public printBadgeAndLogEvent = async (credentialId: number, personId?: number): Promise<CredentialMessages> => {
		const url = this.getUrl('Enrollment/PrintBadgeAndLogEvent', true);
		const data = { credentialId, personId };

		return await this.post<CredentialMessages>(url, data);
	};
}

const enrollmentApi = new EnrollmentApi();

export { enrollmentApi };

import React from 'react';
import { ControllerReader, ControlZone, VelocityDevice } from '../../../../../model/DeviceAdminModel';

type ValidationErrors = {
	controlZoneInput: boolean;
	readerInput: boolean;
	nameInput: '';
	controlZoneEditInput: boolean;
};

export type ControllersSelected = ControllerReader & ControlZone;

type ReaderState = {
	controlZones: ControlZone[];
	controlZoneSelected: ControlZone;
	controllersForReaders: VelocityDevice[];
	controllerSelectedId: number;
	readers: ControllerReader[];
	readersSelected: ControllersSelected[];
	readersPreSelected: ControllersSelected[];
	readersInfoGridSelected: number[];
	name: string;
	errors: ValidationErrors;
	displayMyNetworkLayout: boolean;
	controlZoneEditValue: ControlZone;
};

enum ReaderContextActionTypes {
	SET_CONTROL_ZONES = 'SET_CONTROL_ZONES',
	SET_CONTROLLERS_FOR_READERS = 'SET_CONTROLLERS_FOR_READERS',
	SET_CONTROLLER_SELECTED = 'SET_CONTROLLER_SELECTED',
	SET_READERS = 'SET_READERS',
	SET_READERS_SELECTED = 'SET_READERS_SELECTED',
	SET_CONTROL_ZONE_SELECTED = 'SET_CONTROL_ZONE_SELECTED',
	SET_PRESELECTED_READERS = 'SET_PRESELECTED_READERS',
	SET_ERRORS_VALIDATION = 'SET_ERRORS_VALIDATION',
	SET_NAME = 'SET_NAME',
	SET_DISPLAY_NETWORK = 'SET_DISPLAY_NETWORK',
	SET_SELECTED_READERS_GRID = 'SET_SELECTED_READERS_GRID',
	SET_EDIT_CONTROL_ZONE = 'SET_EDIT_CONTROL_ZONE',
}

type contextReaderSetControlZones = {
	type: ReaderContextActionTypes.SET_CONTROL_ZONES;
	payload: ControlZone[];
};
type contextReaderSetControllers = {
	type: ReaderContextActionTypes.SET_CONTROLLERS_FOR_READERS;
	payload: VelocityDevice[];
};
type contextReaderSetControllerSelected = {
	type: ReaderContextActionTypes.SET_CONTROLLER_SELECTED;
	payload: number;
};
type contextReaderSetReaders = {
	type: ReaderContextActionTypes.SET_READERS;
	payload: ControllerReader[];
};
type contextReaderSetReadersSelected = {
	type: ReaderContextActionTypes.SET_READERS_SELECTED;
	payload: ControllersSelected[];
};
type contextReaderSetReadersPreSelected = {
	type: ReaderContextActionTypes.SET_PRESELECTED_READERS;
	payload: ControllersSelected[];
};
type contextReaderSetControlZoneSelected = {
	type: ReaderContextActionTypes.SET_CONTROL_ZONE_SELECTED;
	payload: ControlZone;
};
type contextReaderSetErrorValidations = {
	type: ReaderContextActionTypes.SET_ERRORS_VALIDATION;
	payload: { type: keyof ValidationErrors; value: boolean | string };
};
type contextReaderSetName = {
	type: ReaderContextActionTypes.SET_NAME;
	payload: string;
};
type contextReaderSetDisplayNetwork = {
	type: ReaderContextActionTypes.SET_DISPLAY_NETWORK;
	payload: boolean;
};
type contextReaderDeleteReaders = {
	type: ReaderContextActionTypes.SET_SELECTED_READERS_GRID;
	payload: number[];
};
type contextReaderSetEditControlZone = {
	type: ReaderContextActionTypes.SET_EDIT_CONTROL_ZONE;
	payload: ControlZone;
};

type ReaderActionContext =
	| contextReaderSetControlZones
	| contextReaderSetControllers
	| contextReaderSetControllerSelected
	| contextReaderSetReaders
	| contextReaderSetReadersSelected
	| contextReaderSetControlZoneSelected
	| contextReaderSetReadersPreSelected
	| contextReaderSetErrorValidations
	| contextReaderSetName
	| contextReaderSetDisplayNetwork
	| contextReaderDeleteReaders
	| contextReaderSetEditControlZone;

const readerState: ReaderState = {
	controlZones: [],
	controllersForReaders: [],
	controllerSelectedId: 0,
	readers: [],
	readersSelected: [],
	readersPreSelected: [],
	controlZoneSelected: null,
	errors: {
		controlZoneInput: false,
		nameInput: '',
		readerInput: false,
		controlZoneEditInput: false,
	},
	name: '',
	displayMyNetworkLayout: true,
	readersInfoGridSelected: [],
	controlZoneEditValue: null,
};
const readerContext = (state: Readonly<ReaderState>, action: ReaderActionContext): ReaderState => {
	switch (action.type) {
		case ReaderContextActionTypes.SET_CONTROL_ZONES:
			return { ...state, controlZones: action.payload };

		case ReaderContextActionTypes.SET_CONTROLLERS_FOR_READERS:
			return { ...state, controllersForReaders: action.payload };

		case ReaderContextActionTypes.SET_CONTROLLER_SELECTED:
			return { ...state, controllerSelectedId: action.payload };

		case ReaderContextActionTypes.SET_READERS_SELECTED:
			return { ...state, readersSelected: action.payload };

		case ReaderContextActionTypes.SET_PRESELECTED_READERS:
			return { ...state, readersPreSelected: action.payload };

		case ReaderContextActionTypes.SET_READERS:
			return { ...state, readers: action.payload };

		case ReaderContextActionTypes.SET_CONTROL_ZONE_SELECTED:
			return { ...state, controlZoneSelected: action.payload };

		case ReaderContextActionTypes.SET_NAME:
			return { ...state, name: action.payload };

		case ReaderContextActionTypes.SET_DISPLAY_NETWORK:
			return { ...state, readersPreSelected: [], displayMyNetworkLayout: action.payload };

		case ReaderContextActionTypes.SET_SELECTED_READERS_GRID:
			return { ...state, readersInfoGridSelected: action.payload };

		case ReaderContextActionTypes.SET_EDIT_CONTROL_ZONE:
			return { ...state, controlZoneEditValue: action.payload };

		case ReaderContextActionTypes.SET_ERRORS_VALIDATION:
			return { ...state, errors: { ...state.errors, [action.payload.type]: action.payload.value } };

		default:
			return { ...state };
	}
};

const setControlZones = (payload: ControlZone[]): contextReaderSetControlZones => {
	return {
		type: ReaderContextActionTypes.SET_CONTROL_ZONES,
		payload,
	};
};
const setControllers = (payload: VelocityDevice[]): contextReaderSetControllers => {
	return {
		type: ReaderContextActionTypes.SET_CONTROLLERS_FOR_READERS,
		payload,
	};
};
const setController = (payload: number): contextReaderSetControllerSelected => {
	return {
		type: ReaderContextActionTypes.SET_CONTROLLER_SELECTED,
		payload,
	};
};
const setReaders = (payload: ControllerReader[]): contextReaderSetReaders => {
	return {
		type: ReaderContextActionTypes.SET_READERS,
		payload,
	};
};
const setReadersSelected = (payload: ControllersSelected[]): contextReaderSetReadersSelected => {
	return {
		type: ReaderContextActionTypes.SET_READERS_SELECTED,
		payload,
	};
};
const setReadersPreSelected = (payload: ControllersSelected[]): contextReaderSetReadersPreSelected => {
	return {
		type: ReaderContextActionTypes.SET_PRESELECTED_READERS,
		payload,
	};
};
const setControlZoneSelected = (payload: ControlZone): contextReaderSetControlZoneSelected => {
	return {
		type: ReaderContextActionTypes.SET_CONTROL_ZONE_SELECTED,
		payload,
	};
};
const setErrors = (payload: { type: keyof ValidationErrors; value: boolean | string }): contextReaderSetErrorValidations => {
	return {
		type: ReaderContextActionTypes.SET_ERRORS_VALIDATION,
		payload,
	};
};
const setName = (payload: string): contextReaderSetName => {
	return {
		type: ReaderContextActionTypes.SET_NAME,
		payload,
	};
};
const setDisplayNetworkLayout = (payload: boolean): contextReaderSetDisplayNetwork => {
	return {
		type: ReaderContextActionTypes.SET_DISPLAY_NETWORK,
		payload,
	};
};
const setSelectedReadersGridInfo = (payload: number[]): contextReaderDeleteReaders => {
	return {
		type: ReaderContextActionTypes.SET_SELECTED_READERS_GRID,
		payload,
	};
};
const setEditControlZoneHasValue = (payload: ControlZone): contextReaderSetEditControlZone => {
	return {
		type: ReaderContextActionTypes.SET_EDIT_CONTROL_ZONE,
		payload,
	};
};

type CommandContext = {
	readerState: Readonly<ReaderState>;
	dispatcher: React.Dispatch<ReaderActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const StoreContext = React.createContext<CommandContext>({ readerState, dispatcher: null });

export {
	readerState,
	StoreContext,
	readerContext,
	setControlZones,
	setControllers,
	setController,
	setReaders,
	setReadersSelected,
	setControlZoneSelected,
	setReadersPreSelected,
	setErrors,
	setName,
	setDisplayNetworkLayout,
	setSelectedReadersGridInfo,
	setEditControlZoneHasValue,
};

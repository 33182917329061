import { buildColumn } from '../../Helper';
import { GridDataItem, PaginationSetting, SortDirections, TupleTyped } from '../../model/CommonModel';
import { ObjectTypes } from '../../model/DeviceAdminModel';
import { StatusObjectEnum, StatusViewerItem, TargetKeyOption } from '../../model/StatusViewerModel';

const DoorColumnWith: Record<string, string> = {
	Name: '200px',
	Address: '200px',
	Status: '220px',
	Alarm: '65px',
	Masked: '65px',
	Enabled: '65px',
	Input1: '65px',
	Input2: '65px',
	Input3: '65px',
	'LM Type': '65px',
	Voltage: '65px',
	'Relay Status': '65px',
	'Relay Details': '65px',
	'2PR': '50px',
	LastUpdated: '170px',
};

const InputColumnWith: Record<string, string> = {
	Name: '330px',
	Address: '200px',
	Status: '250px',
	Alarm: '75px',
	Masked: '75px',
	Enabled: '75px',
	Input1: '75px',
	Input2: '75px',
	Input3: '75px',
	'LM Type': '75px',
	Voltage: '75px',
	LastUpdated: '170px',
};

const RelayColumnWith: Record<string, string> = {
	Name: '300px',
	Address: '220px',
	Status: '250px',
	'Relay Details': '250px',
	LastUpdated: '170px',
};

const ReaderColumnWith: Record<string, string> = {
	Name: '200px',
	Address: '200px',
	Status: '200px',
	ThreatLevel: '100px',
	CTamper: '100px',
	PTamper: '100px',
	LastUpdated: '170px',
};

const ControllerColumnWith: Record<string, string> = {
	Name: '300px',
	Address: '200px',
	Status: '200px',
	ThreatLevel: 'auto',
	Enclosure: '100px',
	Enabled: '100px',
	Terminal: '100px',
	ACPower: '100px',
	UPSBattF: 'auto',
	KeypadMode: 'auto',
	MemoryBatt: '100px',
	ParallelPrint: '100px',
	SerialPrint: '100px',
	TamperPending: 'auto',
	CCMFirmwareVersion: 'auto',
	CCMBIOSVersion: 'auto',
	SNIB2FirmwareVersion: 'auto',
	SNIB3FirmwareVersion: 'auto',
	SNIB3OSVersion: 'auto',
	SNIB3DriverVersion: 'auto',
	LastUpdated: '200px',
};

const XBoxColumnWith: Record<string, string> = {
	Name: '350px',
	Address: '200px',
	ControllerName: '350px',
	ControllerAddress: '200px',
	Status: '100px',
	Enabled: '100px',
	Rev: '100px',
	LastUpdated: '170px',
};

const PortColumnWith: Record<string, string> = {
	Name: '350px',
	Address: '250px',
	Status: '200px',
	Enabled: '100px',
	LastUpdated: '200px',
};

const WirelessLocksColumnWith: Record<string, string> = {
	Name: '230px',
	Address: '200px',
	Status: '250px',
	Alarm: '80px',
	Masked: '80px',
	Enabled: '80px',
	Input1: 'auto',
	Input2: 'auto',
	Input3: 'auto',
	'LM Type': 'auto',
	Voltage: 'auto',
	'Relay Status': 'auto',
	'Relay Details': 'auto',
	'2PR': '50px',
	BatteryStat: 'auto',
	LastUpdated: '170px',
};

const PortsWithNetworkGlobalIOColumnWith: Record<string, string> = {
	Name: '300px',
	Address: '200px',
	Status: '200px',
	Enabled: '100px',
	IPAddress: '100px',
	CMServer: '100px',
	CMClient: '100px',
	MCZGroup: '150px',
	MCZServer: '150px',
	MCZClient: '150px',
	CMGroup: '200px',
	LastUpdated: '170px',
};

const MaskedOnlyColumnWith: Record<string, string> = {
	Name: '300px',
	Address: '300px',
	Status: 'auto',
	LastUpdated: '170px',
};

export const buildColumnStatusGroup = (statusObject: StatusObjectEnum, key: string, hasData: boolean): any => {
	switch (statusObject) {
		case StatusObjectEnum.Door:
			return buildColumn(_(key), key, hasData ? DoorColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.Input:
			return buildColumn(_(key), key, hasData ? InputColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.Relay:
			return buildColumn(_(key), key, hasData ? RelayColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.Reader:
			return buildColumn(_(key), key, hasData ? ReaderColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.Controller:
			return buildColumn(_(key), key, hasData ? ControllerColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.XBox:
			return buildColumn(_(key), key, hasData ? XBoxColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.Port:
			return buildColumn(_(key), key, hasData ? PortColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.WirelessLocks:
			return buildColumn(_(key), key, hasData ? WirelessLocksColumnWith[key] ?? 'auto' : 'auto', 'start');
		case StatusObjectEnum.PortsWithNetworkGlobalIO:
			return buildColumn(_(key), key, hasData ? PortsWithNetworkGlobalIOColumnWith[key] ?? 'auto' : 'auto', 'start');
		default:
			return buildColumn(_(key), key, 'auto', 'start');
	}
};

/**
 * (Validate if the real-time event can be added)
 *@param statusViewerItem status viewer item from real-time event
 @Returns boolean
 */
export const canAddRealTimeEventItem = (statusViewerItem: StatusViewerItem): boolean => {
	const {
		PaginationSetting: { PageNumber: pageNumber, PageSize: pageSize },
		TotalItems: totalItems,
	} = statusViewerItem;

	const totalItemsWithAddition = totalItems + 1;

	if (pageNumber === 1 && totalItemsWithAddition <= pageSize) {
		return true;
	}
};

/**
 * (Validate if the real-time event can be deleted)
 *@param statusViewerItem status viewer item from real-time event
 @Returns boolean
 */
export const canDeleteRealTimeEventItem = (paginationSetting: PaginationSetting, totalItems: number): boolean => {
	const { PageNumber, PageSize } = paginationSetting;

	const realTotalItems = totalItems - 1;
	if (PageNumber === 1 && realTotalItems <= PageSize && realTotalItems > 0) {
		return true;
	}

	return false;
};

/**
 *
 * @param data array to be sort
 * @param direction order of sorting
 * @param field field to be sort
 * @returns sorted array
 */
export const sortStatusViewerItems = (statusViewerItem: GridDataItem[][], direction: SortDirections, field: string): GridDataItem[][] => {
	if (direction === SortDirections.None) {
		return statusViewerItem;
	}

	let payload: GridDataItem[][] = [];
	if (direction === SortDirections.Ascend) {
		payload = statusViewerItem.sort((a, b) => a.find(x => x.Key === field).Value.localeCompare(b.find(x => x.Key === field).Value));
	} else {
		payload = statusViewerItem.sort((a, b) => b.find(x => x.Key === field).Value.localeCompare(a.find(x => x.Key === field).Value));
	}

	return payload;
};

/**
 *
 * @param gridDataItem key-value to compare
 * @param key
 * @param value
 * @returns boolean
 */
export const isSameGridDataItem = (gridDataItem: GridDataItem, key: string, value: string): boolean => gridDataItem.Key === key && gridDataItem.Value === value;

const splitTargetKeys = (targetKeys: string[], splitKeyOption: TargetKeyOption, objectId?: number): string[] => {
	switch (splitKeyOption) {
		case TargetKeyOption.ObjectId:
			return targetKeys.filter(key => Number(key.split('-')[0]) === objectId);
		case TargetKeyOption.ObjectType:
			let expansionDeviceType = ObjectTypes.pollingPorts;
			switch (objectId) {
				case Number(StatusObjectEnum.Input):
					expansionDeviceType = ObjectTypes.expansionInputs;
					break;
				case Number(StatusObjectEnum.Relay):
					expansionDeviceType = ObjectTypes.expansionRelays;
					break;
			}
			return targetKeys.filter(key => Number(key.split('-')[1]) === expansionDeviceType);
		case TargetKeyOption.DeviceId:
			return targetKeys.map(key => key.split('-')[2]);
	}
};
export const getDevicesIdsBy = (objectId: number, targetKeys: string[]): TupleTyped<number[], number[]> => {
	if (!targetKeys && targetKeys.length == 0) {
		return {} as TupleTyped<number[], number[]>;
	}

	const statusObjectId = objectId as StatusObjectEnum;
	const isPortOrGlobalIO = (key: StatusObjectEnum) => key === StatusObjectEnum.PortsWithNetworkGlobalIO || key === StatusObjectEnum.Port;
	let selectedDeviceIds: number[] = [];
	let deviceKeys: string[] = [];
	let deviceObjectTypeKeys: string[] = [];

	if (isPortOrGlobalIO(statusObjectId)) {
		deviceKeys = [
			...splitTargetKeys(targetKeys, TargetKeyOption.ObjectId, StatusObjectEnum.Port),
			...splitTargetKeys(targetKeys, TargetKeyOption.ObjectId, StatusObjectEnum.PortsWithNetworkGlobalIO),
		];
		selectedDeviceIds = deviceKeys.length > 0 ? splitTargetKeys(deviceKeys, TargetKeyOption.DeviceId).map<number>(x => Number(x)) : [];
	} else {
		deviceObjectTypeKeys = splitTargetKeys(targetKeys, TargetKeyOption.ObjectType, objectId);
		deviceKeys = splitTargetKeys(targetKeys, TargetKeyOption.ObjectId, objectId).filter(val => !deviceObjectTypeKeys.includes(val));
		selectedDeviceIds = deviceKeys.length > 0 ? splitTargetKeys(deviceKeys, TargetKeyOption.DeviceId).map<number>(x => Number(x)) : [];
	}

	const expansionObjectTypeKeys = splitTargetKeys(targetKeys, TargetKeyOption.ObjectType, objectId);
	const selectedExpansionDeviceIds =
		expansionObjectTypeKeys.length > 0 ? splitTargetKeys(expansionObjectTypeKeys, TargetKeyOption.DeviceId).map<number>(x => Number(x)) : [];

	return { Item1: selectedDeviceIds, Item2: selectedExpansionDeviceIds } as TupleTyped<number[], number[]>;
};

import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../../api';
import { handleResponse } from '../../../../../../../../Helper';
import { SecuredComponents } from '../../../../../../../../model/AccountModel';
import { CCMFirmwareConfiguration, DeviceObjectType } from '../../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../../store';
import { setCurrentDevice } from '../../../../../../../../store/common/actions';
import { selectControllerId } from '../../../../../../../../store/deviceControl/controller/selectors';
import { InformationMessages, Modal, Select } from '../../../../../../../common';
import styles from '../../general.module.scss';

type Props = {
	onCancel: () => void;
	callbackRedirect?: () => void;
};

const styleCombo: React.CSSProperties = { width: 300, marginBottom: 40 };

const FirmwareUpdate: React.FC<Props> = ({ onCancel, callbackRedirect }) => {
	const [data, setData] = useState<CCMFirmwareConfiguration>(null);
	const [firmwareId, setFirmwareId] = useState<number>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const dispatch = useStoreDispatch();
	const controllerId: number = useStoreSelector<number>(selectControllerId);

	useEffect(() => {
		deviceAdminApi.retrieveCCMInfoFirmware().then(res => {
			createData(res.Entity);
			setFirmwareId(res.Entity.CCMFirmwareOptions[0]?.FirmwareID ?? 0);
		});
	}, []);

	const createData = (ccmFirmwareConfiguration: CCMFirmwareConfiguration) => {
		const cloneCCMFirmwareConfig = { ...ccmFirmwareConfiguration };
		cloneCCMFirmwareConfig.Labels = [...ccmFirmwareConfiguration.Labels, _('DownloadButtonRedirectDeviceControlWarning')];
		setData(cloneCCMFirmwareConfig);
	};

	const handleUpdateFirmware = () => {
		setIsLoading(true);
		deviceAdminApi.updateCCMFirmwareController(controllerId, firmwareId).then(res => {
			setIsLoading(false);
			if (!handleResponse(res)) {
				onCancel();
				deviceAdminApi.unlockComponentDeviceAdmin(controllerId, SecuredComponents.Controller).finally(() => {
					window.history.replaceState({}, '', `${location.pathname}`);
					const newDevice = { Id: controllerId, DeviceObjectType: DeviceObjectType.Controller, IsModalOpen: false };
					dispatch(setCurrentDevice(newDevice));
					callbackRedirect?.();
				});
			}
		});
	};

	const options =
		data && data.CCMFirmwareOptions.length > 0
			? data.CCMFirmwareOptions.map(x => ({
					label: `${x.RevisionNum} (${FormatDate(x.RevisionDate, false, undefined, true, false)})`,
					value: x.FirmwareID,
			  }))
			: [{ label: _('NoFirmwareBinaries'), value: 0 }];

	return (
		<>
			{data && (
				<Modal
					visible={true}
					title={_('UpdateCCMFirmware')}
					onClickOk={() => null}
					customZoomClass={styles.ccmFirmwareModalZoom}
					onCancel={onCancel}
					width='560px'
					footer={[
						<Button key='ok' disabled={data.CCMFirmwareOptions.length === 0} type='primary' onClick={handleUpdateFirmware} loading={isLoading}>
							{_('Download')}
						</Button>,
						<Button key='cancel' onClick={onCancel}>
							{_('Cancel')}
						</Button>,
					]}>
					<div className={styles.ccmFirmwareSelect}>
						<label>{_('SelectFirmwareVersionToUpdate')}</label>
						<Select
							style={styleCombo}
							notUseCurrentParse
							onChange={value => setFirmwareId(Number(value))}
							options={options}
							value={firmwareId}
							disabled={data.CCMFirmwareOptions.length === 0}
						/>
					</div>
					<InformationMessages messages={data.Labels} />
				</Modal>
			)}
		</>
	);
};

export { FirmwareUpdate };

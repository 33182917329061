import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { PortStoreContext, setPortChangedAction, setTotalTimeoutMultiplier } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const TimeoutMultiplier: React.FC<Props> = () => {
	const {
		portState: { totalTimeoutMultiplier, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChange = (value: string) => {
		if (!(Number(value) >= 0)) {
			return;
		}
		dispatcher(setTotalTimeoutMultiplier(Number(value)));
		checkIfPortChanged();
	};

	const portAdvancedSettingsTotalTimeoutMultiplierInputId = 'portAdvancedSettingsTotalTimeoutMultiplierInput';

	return (
		<div className={styles.labelContainer}>
			<label id='portAdvancedSettingsTotalTimeoutMultiplierLabel' htmlFor={portAdvancedSettingsTotalTimeoutMultiplierInputId}>
				{_('TotalTimeoutMultiplier')}
			</label>
			<InputNumber
				id={portAdvancedSettingsTotalTimeoutMultiplierInputId}
				key='totalTimeoutMultiplier'
				className={styles.inputNumber}
				value={totalTimeoutMultiplier}
				min={0}
				max={99}
				step={1}
				maxLength={2}
				onChange={handleChange}
				formatter={noneDecimalFormatter}
			/>
		</div>
	);
};

export { TimeoutMultiplier };

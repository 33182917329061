import React, { useContext, useEffect, useState } from 'react';
import { GenericControlTimeZones } from '../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './controlZones.module.scss';

type Props = {
	useDoorContext: boolean;
};

const ControlZones: React.FC<Props> = ({ useDoorContext }) => {
	const [stdControlZones, setStdControlZones] = useState<GenericControlTimeZones[]>([]);
	const [mtControlZones, setMtControlZones] = useState<GenericControlTimeZones[]>([]);

	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleSelectMasking = (value: string) => {
		inputContext.dispatcher(setInputAction({ ActuateRelaysInControlZoneId: Number(value) }));
	};
	const handleSelectDisable = (value: string) => {
		inputContext.dispatcher(setInputAction({ DisabledRelaysInControlZoneId: Number(value) }));
	};
	const handleSelectTrigger = (value: string) => {
		inputContext.dispatcher(setInputAction({ TriggerRelaysInControlZoneId: Number(value) }));
	};
	const handleSelectReTrigger = (value: string) => {
		inputContext.dispatcher(setInputAction({ RetriggerRelaysInControlZoneId: Number(value) }));
	};

	useEffect(() => {
		if (inputContext.context?.controlZones?.StdControlZones !== null && inputContext.context?.controlZones?.MtControlZones) {
			setStdControlZones(inputContext.context?.controlZones?.StdControlZones);
			setMtControlZones(inputContext.context?.controlZones?.MtControlZones);
		}
	}, [inputContext.context?.controlZones]);

	const disabled = stdControlZones?.length === 0 || mtControlZones?.length === 0;

	return (
		<div className={styles.container}>
			<fieldset>
				<legend className={styles.legend}>{_('ControlZones')}</legend>
				<div className={styles.content}>
					<span>{_('ActuateRelaysInControlZone')}</span>
					<Select
						id='logicInputActuateRelaysDropdown'
						value={inputContext.context?.input?.ActuateRelaysInControlZoneId?.toString()}
						onChange={handleSelectMasking}
						options={stdControlZones.map(x => ({ label: x.Name, value: x.CZIndex }))}
						key='actuateRelaysInControlZone'
						disabled={disabled}
						getPopupContainer={() => document.getElementById('logicInputContainer')}
					/>
					<span>{_('DisableRelaysInControlZone')}</span>
					<Select
						id='logicInputDisableRelaysDropdown'
						value={inputContext.context?.input?.DisabledRelaysInControlZoneId?.toString()}
						onChange={handleSelectDisable}
						options={stdControlZones.map(x => ({ label: x.Name, value: x.CZIndex }))}
						key='disableRelaysInControlZone'
						disabled={disabled}
						getPopupContainer={() => document.getElementById('logicInputContainer')}
					/>
					<span>{_('TriggerRelaysInControlZone')}</span>
					<Select
						id='logicInputTriggerRelaysDropdown'
						value={inputContext.context?.input?.TriggerRelaysInControlZoneId?.toString()}
						onChange={handleSelectTrigger}
						options={mtControlZones.map(x => ({ label: x.Name, value: x.CZIndex }))}
						key='triggerRelaysInControlZone'
						disabled={disabled}
						getPopupContainer={() => document.getElementById('logicInputContainer')}
					/>
					<span>{_('RetriggerRelaysInControlZone')}</span>
					<Select
						id='logicInputRetriggerRelaysDropdown'
						value={inputContext.context?.input?.RetriggerRelaysInControlZoneId?.toString()}
						onChange={handleSelectReTrigger}
						options={stdControlZones.map(x => ({ label: x.Name, value: x.CZIndex }))}
						key='retriggerRelaysInControlZone'
						disabled={disabled}
						getPopupContainer={() => document.getElementById('logicInputContainer')}
					/>
				</div>
			</fieldset>
		</div>
	);
};

export { ControlZones };

import cx from 'classnames';
import React, { useContext } from 'react';
import { RelayLogicTimeZones } from '../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setActuateTimeZone, StoreContext } from '../../../../contextExpansionRelay';
import styles from '../../../../expansionRelayModal.module.scss';

type Props = {
	setPreviousSelections: (RelayLogicTimeZones) => void;
	previousSelections: RelayLogicTimeZones;
	useDoorContext: boolean;
};

const Actuate: React.FC<Props> = props => {
	const { setPreviousSelections, previousSelections, useDoorContext } = props;
	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: { actuateTimeZones, selections, unpermitted },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			expansionRelayState: { actuateTimeZones, selections, unpermitted },
			dispatcher,
		} = useContext(StoreContext);
	}

	const options = unpermitted.actuateTimeZone
		? [
				{
					label: unpermitted.actuateTimeZone.Name,
					value: unpermitted.actuateTimeZone.GenericId.toString(),
					id: `relayLogicActuateTimeZoneOption-${unpermitted.actuateTimeZone.GenericId.toString()}`,
				},
		  ]
		: actuateTimeZones.map(x => ({ label: x.Name, value: x.GenericId.toString(), id: `relayLogicActuateTimeZoneOption-${x.GenericId.toString()}` }));

	return (
		<div className={styles.sections}>
			<span>{_('ActuateTimezone')}</span>
			<div>
				<Select
					id='relayLogicActuateTimeZoneDropdown'
					className={cx(styles.select)}
					options={options}
					onChange={value => {
						const parseValue = Number(value);
						if (parseValue !== 0) {
							setPreviousSelections({ ...previousSelections, Actuate: parseValue });
						}
						useDoorContext ? dispatcherDoor(setActuateTimeZone(Number(value))) : dispatcher(setActuateTimeZone(Number(value)));
					}}
					key='actuateTimeZone'
					value={selections.actuateTimeZone.toString()}
					disabled={unpermitted.actuateTimeZone ? true : false}
					getPopupContainer={() => document.getElementById('logicRelayContainer')}
				/>
			</div>
		</div>
	);
};

export { Actuate };

import { createSelector } from 'reselect';
import { CustomFilterGroup, EventFilter } from '../../model/EventModel';
import { RootReducer } from '../../store/rootReducer';

export const selectEventFilters = createSelector(
	(state: RootReducer) => state.eventReducer.eventFilters,
	(eventFilters: EventFilter[]) => eventFilters
);

export const selectCustomFilterGroups = createSelector(
	(state: RootReducer) => state.eventReducer.customFilterGroups,
	(customFilterGroups: CustomFilterGroup[]) => customFilterGroups
);

export const selectSelectedCustomFilterGroups = createSelector(
	(state: RootReducer) => state.eventReducer.selectedCustomFilterGroups,
	(selectedCustomFilterGroups: CustomFilterGroup[]) => selectedCustomFilterGroups
);

import { Button, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../../../api';
import { getDefaultPaginationSettings, handleResponse, removeCommasAndPercentSign, unlockHandleBeforeUnload } from '../../../../../Helper';
import { getPermissionErrorMessage, SecuredComponents, User } from '../../../../../model/AccountModel';
import { ErrorMessage, PaginationSetting, ResponseObjectEntity, SelectOptions } from '../../../../../model/CommonModel';
import {
	DeviceObjectType,
	ErrorMessages,
	GlobalIOGroup,
	GlobalIOGroupInfo,
	GlobalIOGroupMembersPagination,
	GlobalIOGroupServerInfo,
	GlobalIOGroupSNIB3,
	GlobalIOGroupSNIBTransfer,
	GlobalIOGroupTopic,
	VelocityDevice,
} from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setGlobalIOGroupsBy } from '../../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfigurationFilterMode } from '../../../../../store/deviceControl/selectors';
import { Modal, NotificationStatus } from '../../../../common';
import styles from './globalIOGroupModal.module.scss';
import { GlobalIOServer } from './sections/GlobalIOServer/GlobalIOServer';
import { MembersTableTransfer } from './sections/MembersTableTransfer/MembersTableTransfer';
import { Name } from './sections/Name/Name';

type Props = {
	globalIOGroupToEdit: GlobalIOGroup;
	onCloseModal: () => void;
	setShouldResetSearchColumn: () => void;
	setRedirectPage?: () => void;
};

type Errors = {
	globalIOName?: ErrorMessage;
	globalIOServer?: ErrorMessage;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Global_IO_Group);
const initPagination: PaginationSetting = getDefaultPaginationSettings();
const initErrorMessages: Errors = {
	globalIOName: { message: '', isInvalid: false },
	globalIOServer: { message: '', isInvalid: false },
};

const GlobalIOGroupModal: React.FC<Props> = ({ globalIOGroupToEdit, onCloseModal, setShouldResetSearchColumn, setRedirectPage }) => {
	const dispatchRedux = useStoreDispatch();
	const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [errorMessages, setErrorMessages] = useState<Errors>({ ...initErrorMessages });
	const [selectOptions, setSelectOptions] = useState<SelectOptions<string>[]>([]);
	const [dataSource, setDataSource] = useState<GlobalIOGroupSNIBTransfer[]>([]);
	const [selectedMembers, setSelectedMembers] = useState<GlobalIOGroupSNIB3[]>([]);
	const [unselectedMembers, setUnselectedMembers] = useState<GlobalIOGroupSNIB3[]>([]);
	const [isLeftTransferLoading, setIsLeftTransferLoading] = useState<boolean>(false);
	const [isRightTransferLoading, setIsRightTransferLoading] = useState<boolean>(false);
	const [leftPagination, setLeftPagination] = useState<PaginationSetting>(initPagination);
	const [rightPagination, setRightPagination] = useState<PaginationSetting>(initPagination);
	const [globalIOServerId, setGlobalIOServerId] = useState<number>(0);
	const [name, setName] = useState<string>('');

	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	useEffect(() => {
		initGlobalIOGroup();
	}, []);

	useEffect(() => {
		const clonedDataSource = dataSource.map(x => ({ ...x, disabled: x.key === globalIOServerId.toString() }));
		setDataSource(clonedDataSource);
	}, [globalIOServerId]);

	const initGlobalIOGroup = () => {
		Promise.all([getErrorMessages(), getGlobalIOServers(), getGlobalIOGroupMembers(selectedMembers, leftPagination, rightPagination)]).then(res => {
			const [errorMessages, responseGlobalIOServers, responseGlobalIOMembers] = res;

			createErrorMessages(errorMessages);
			createGlobalIOServers(responseGlobalIOServers);
			setName(globalIOGroupToEdit.name);
			createMembersData(responseGlobalIOMembers);
			setIsLoading(false);
		});
	};

	const getErrorMessages = (): Promise<ErrorMessages> => {
		return deviceAdminApi.getErrorMessages(SecuredComponents.Global_IO_Group);
	};

	const createErrorMessages = (errorMessages: ErrorMessages) => {
		const { EmptyName, EmptySelection } = errorMessages;
		const emptyName = EmptyName.replace('%%1', isCredentialManagement ? _('CredentialManagement') : _('MasterControlZone'));
		const errors: Errors = {
			globalIOName: { message: emptyName, isInvalid: false },
			globalIOServer: { message: EmptySelection, isInvalid: false },
		};
		setErrorMessages(errors);
	};

	const getGlobalIOServers = (): Promise<ResponseObjectEntity<GlobalIOGroupServerInfo>> => {
		return deviceAdminApi.getGlobalIOGroupServerInfo(globalIOGroupToEdit);
	};

	const createGlobalIOServers = (response: ResponseObjectEntity<GlobalIOGroupServerInfo>) => {
		if (handleResponse(response)) {
			return;
		}
		const { globalIOGroupServers, globalIOServerId } = response.Entity;
		createSelectOptions(globalIOGroupServers);
		setGlobalIOServerId(globalIOServerId);
	};

	const createSelectOptions = (globalIOGroupServers: VelocityDevice[]) => {
		const options: SelectOptions<string>[] = globalIOGroupServers.map((server, index) => ({
			label: server.Name,
			value: server.Id.toString(),
			id: `globalIOGroupSNIBServersDropdownOption-${index}`,
		}));

		setSelectOptions(options);
	};

	const getGlobalIOGroupMembers = (
		selectedMembers: GlobalIOGroupSNIB3[],
		leftPaginationSetting: PaginationSetting,
		rightPaginationSetting: PaginationSetting
	): Promise<ResponseObjectEntity<GlobalIOGroupMembersPagination>> => {
		const { id, topic } = globalIOGroupToEdit;
		const globalIOGroupInfo: Partial<GlobalIOGroupInfo> = {
			id,
			topic,
			selectedMembers,
		};
		return deviceAdminApi.getGlobalIOGroupMembersPagination(globalIOGroupInfo, leftPaginationSetting, rightPaginationSetting);
	};

	const mapData = (unselectedMembers: GlobalIOGroupSNIB3[], selectedMembers: GlobalIOGroupSNIB3[]): GlobalIOGroupSNIBTransfer[] => {
		if (unselectedMembers.length || selectedMembers.length) {
			const allMembers = [...unselectedMembers, ...selectedMembers];
			const membersDataMapped = allMembers.map<GlobalIOGroupSNIBTransfer>(dataItem => ({
				...dataItem,
				key: dataItem.portId.toString(),
				title: dataItem.portName,
				disabled: false,
			}));

			return membersDataMapped;
		}
	};

	const createMembersData = (response: ResponseObjectEntity<GlobalIOGroupMembersPagination>, isSearchPerformed?: boolean) => {
		if (handleResponse(response)) {
			return;
		}

		const {
			selectedMembers: { globalIOSNIB3Groups: selectedMembers, TotalItemsPaginated: totalSelectedItems },
			unselectedMembers: { globalIOSNIB3Groups: unselectedMembers, TotalItemsPaginated: totalUnselectedItems },
		} = response.Entity;

		const newTotalSelectedItems: number = isSearchPerformed ? rightPagination.TotalItems : totalSelectedItems;

		const dataTableTransferMapped: GlobalIOGroupSNIBTransfer[] = mapData(unselectedMembers, selectedMembers);

		batch(() => {
			setDataSource(dataTableTransferMapped);
			setSelectedMembers(selectedMembers);
			setUnselectedMembers(unselectedMembers);
			setLeftPagination({ ...leftPagination, TotalItems: totalUnselectedItems });
			setRightPagination({ ...rightPagination, TotalItems: newTotalSelectedItems });
		});
	};

	const getUnselectedMembersPagination = async (leftPaginationSetting: PaginationSetting) => {
		setIsLeftTransferLoading(true);
		const res = await getGlobalIOGroupMembers(selectedMembers, leftPaginationSetting, rightPagination);

		if (handleResponse(res)) {
			return;
		}

		const {
			unselectedMembers: { globalIOSNIB3Groups, TotalItemsPaginated: totalItemsPaginated },
		} = res.Entity;

		const dataTableTransferMapped: GlobalIOGroupSNIBTransfer[] = mapData(globalIOSNIB3Groups, selectedMembers);

		batch(() => {
			setDataSource(dataTableTransferMapped);
			setUnselectedMembers(globalIOSNIB3Groups);
			setLeftPagination({ ...leftPaginationSetting, TotalItems: totalItemsPaginated });
			setIsLeftTransferLoading(false);
		});
	};

	const getSelectedMembersPagination = async (rightPaginationSetting: PaginationSetting, isSearchActive = false) => {
		setIsRightTransferLoading(true);
		const res = await getGlobalIOGroupMembers(selectedMembers, leftPagination, rightPaginationSetting);

		if (handleResponse(res)) {
			return;
		}

		const {
			selectedMembers: { globalIOSNIB3Groups, TotalItemsPaginated: totalItemsPaginated },
		} = res.Entity;

		const dataTableTransferMapped: GlobalIOGroupSNIBTransfer[] = mapData(unselectedMembers, globalIOSNIB3Groups);

		const newSelectedMembers = isSearchActive ? selectedMembers : globalIOSNIB3Groups;

		batch(() => {
			setDataSource(dataTableTransferMapped);
			setSelectedMembers(newSelectedMembers);
			setRightPagination({ ...rightPaginationSetting, TotalItems: totalItemsPaginated });
			setIsRightTransferLoading(false);
		});
	};

	const getGlobalIOMembersPagination = async (selectedMembers: GlobalIOGroupSNIB3[], isSearchPerformed?: boolean) => {
		batch(() => {
			setIsLeftTransferLoading(true);
			setIsRightTransferLoading(true);
		});
		const newRightPagination: PaginationSetting = isSearchPerformed ? { ...rightPagination, SearchedValue: '' } : rightPagination;

		const res = await getGlobalIOGroupMembers(selectedMembers, leftPagination, newRightPagination);
		createMembersData(res, isSearchPerformed);

		batch(() => {
			setIsLeftTransferLoading(false);
			setIsRightTransferLoading(false);
		});
	};

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = removeCommasAndPercentSign(e.currentTarget.value);
		setName(value);
		setErrorMessages(prevState => ({ ...prevState, globalIOName: { ...prevState.globalIOName, isInvalid: false } }));
	};

	const handleOnChangeGlobalIOServer = (value: string) => {
		const selectedServerId: number = Number(value);
		setGlobalIOServerId(selectedServerId);
		setErrorMessages(prevState => ({ ...prevState, globalIOServer: { ...prevState.globalIOServer, isInvalid: false } }));

		const isSelectedMember: boolean = selectedMembers.some(member => member.portId === selectedServerId);

		if (isSelectedMember) {
			return;
		}

		const newMember = {
			portId: selectedServerId,
		} as GlobalIOGroupSNIB3;

		const newSelectedMembers = [...selectedMembers, newMember];

		getGlobalIOMembersPagination(newSelectedMembers);
	};

	const shouldResetSearchColumn = isEditMode => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!isEditMode) {
			return;
		}

		setRedirectPage();
	};

	const saveGlobalIOGroup = async () => {
		const { id, topic } = globalIOGroupToEdit;

		if (!name || name.trim() === '') {
			setErrorMessages(prevState => ({ ...prevState, globalIOName: { ...prevState.globalIOName, isInvalid: true } }));
			return;
		}

		if (!globalIOServerId || globalIOServerId === 0) {
			setErrorMessages(prevState => ({ ...prevState, globalIOServer: { ...prevState.globalIOServer, isInvalid: true } }));
			return;
		}

		setFormSubmitted(true);

		const globalIOGroupInfo: GlobalIOGroupInfo = {
			id,
			name: name.trim(),
			topic,
			serverId: globalIOServerId,
			unselectedMembers,
			selectedMembers,
		};

		const response = isEditMode ? await deviceAdminApi.editGlobalIOGroup(globalIOGroupInfo) : await deviceAdminApi.addGlobalIOGroup(globalIOGroupInfo);
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		NotificationStatus({
			responseData: response,
			notUseDefaultNotification: true,
			onSuccessCallback: () => {
				dispatchRedux(setGlobalIOGroupsBy(paginationSetting));
				shouldResetSearchColumn(isEditMode);
				setFormSubmitted(false);
				handleOnCloseModal();
			},
			onFailedValidation: () => {
				setErrorMessages(prevState => ({ ...prevState, globalIOName: { message: response.ErrorMessage, isInvalid: true } }));
				setFormSubmitted(false);
			},
			onPermissionErrorCallback: async () => {
				notification['error']({
					message: response.ErrorMessage,
				});
				setFormSubmitted(false);
			},
		});
	};

	const getTitleNameByTopic = (): string => {
		if (isEditMode) {
			return isCredentialManagement ? _('EditGlobalCredentialManagementGroup') : _('EditGlobalMasterControlZoneGroup');
		} else {
			return isCredentialManagement ? _('AddGlobalCredentialManagementGroup') : _('AddGlobalMasterControlZoneGroup');
		}
	};

	const handleOnCloseModal = () => {
		if (isEditMode) unlockHandleBeforeUnload(globalIOGroupToEdit.id, DeviceObjectType.GlobalIOGroup);
		onCloseModal();
	};

	const isEditMode: boolean = globalIOGroupToEdit.id > 0;
	const isCredentialManagement: boolean = globalIOGroupToEdit.topic === GlobalIOGroupTopic.CredentialManagement;
	const permissionsEnabled: boolean = globalIOGroupToEdit ? componentPermission.canUpdate : componentPermission.canAdd;
	const titleName: string = getTitleNameByTopic();
	const mainContainerId: string = `${isCredentialManagement ? 'credentialManagement' : 'masterControlZone'}ModalContainer`;

	return (
		<Modal
			title={titleName}
			visible={true}
			width='1100px'
			onClickOk={() => undefined}
			customZoomClass={styles.withModalZoom}
			onCancel={handleOnCloseModal}
			footer={[
				<Button
					id={`${isCredentialManagement ? 'credentialManagement' : 'masterControlZone'}ModalSaveChangesButton`}
					key='save'
					type='primary'
					loading={formSubmitted}
					disabled={!permissionsEnabled}
					title={getPermissionErrorMessage(permissionsEnabled)}
					onClick={saveGlobalIOGroup}>
					{_('SaveChanges')}
				</Button>,
				<Button
					id={`${isCredentialManagement ? 'credentialManagement' : 'masterControlZone'}ModalCancelButton`}
					key='cancel'
					onClick={handleOnCloseModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default'>
				<div id={mainContainerId} className={styles.container}>
					<div className={styles.inputsContainer}>
						<Name errorMessage={errorMessages.globalIOName} name={name} handleOnChangeName={handleOnChangeName} />
						<GlobalIOServer
							selectOptions={selectOptions}
							globalIOServerId={globalIOServerId}
							errorMessage={errorMessages.globalIOServer}
							handleOnChange={handleOnChangeGlobalIOServer}
							mainContainerId={mainContainerId}
						/>
					</div>
					<MembersTableTransfer
						globalIOGroup={globalIOGroupToEdit}
						isCredentialManagement={isCredentialManagement}
						dataTableTransfer={dataSource}
						globalIOServerId={globalIOServerId}
						isLeftTransferLoading={isLeftTransferLoading}
						isRightTransferLoading={isRightTransferLoading}
						leftPagination={leftPagination}
						rightPagination={rightPagination}
						selectedMembers={selectedMembers}
						unselectedMembers={unselectedMembers}
						getUnselectedMembersPagination={getUnselectedMembersPagination}
						getSelectedMembersPagination={getSelectedMembersPagination}
						setRightPagination={setRightPagination}
						getGlobalIOMembersPagination={getGlobalIOMembersPagination}
						setIsLeftTransferLoading={setIsLeftTransferLoading}
						setIsRightTransferLoading={setIsRightTransferLoading}
					/>
				</div>
			</Spin>
		</Modal>
	);
};

export { GlobalIOGroupModal };

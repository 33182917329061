import { ControlZone, DoorGroup } from '../../../../model/DeviceAdminModel';
import {
	BadgeTemplate,
	CardFormat,
	CredentialFunction,
	CredentialTemplate,
	FunctionCategory,
	FunctionGroup,
	OverrideAnswer,
	XboxController,
} from '../../../../model/EnrollmentModel';
import {
	CredentialTemplateModalConfigData,
	CredentialTemplateModalModel,
	CredentialTemplateModalModelProp,
	GeneralTabActivationExpirationProperty,
	GeneralTabCardProperty,
	GeneralTabInformationProperty,
	GeneralTabKeypadProperty,
	LimitsTabProperty,
	OptionsTabProperty,
	ValidationErrorsProperty,
} from '../CredentialTemplateModalModels';
import {
	ContextActionTypes,
	initConfigData,
	setCardProperty,
	setCompleteModel,
	setControlZonesArray,
	setCredentialFunctionsArray,
	setCredTemplateId,
	setError,
	setFunctionTabServicesData,
	setGeneralTabActivationExpirationProperty,
	setGeneralTabServicesData,
	setGtIdf,
	setInformationProperty,
	setKeypadProperty,
	setLimitsTabProperty,
	setOptionsTabProperty,
	setOverrideAnswer,
	setRootModelProperty,
} from './actionTypes';

const initConfigDataAction = (payload: CredentialTemplateModalConfigData): initConfigData => {
	return {
		type: ContextActionTypes.INIT_CONFIG_DATA,
		payload,
	};
};

const setCompleteModelAction = (payload: CredentialTemplateModalModel): setCompleteModel => {
	return {
		type: ContextActionTypes.SET_COMPLETE_MODEL,
		payload,
	};
};

const setErrorAction = (payload: ValidationErrorsProperty): setError => {
	return {
		type: ContextActionTypes.SET_ERROR,
		payload,
	};
};

const setRootModelPropertyAction = (payload: CredentialTemplateModalModelProp): setRootModelProperty => {
	return {
		type: ContextActionTypes.SET_ROOT_MODEL_PROPERTY,
		payload,
	};
};

const setOverrideResponseAction = (payload: OverrideAnswer): setOverrideAnswer => {
	return {
		type: ContextActionTypes.SET_OVERRIDE_ANSWER,
		payload,
	};
};

// GeneralTabActions
const setGeneralTabServicesDataAction = (payload: {
	badgeTemplates: BadgeTemplate[];
	cardFormats: CardFormat[];
	credentialTemplates: CredentialTemplate[];
}): setGeneralTabServicesData => {
	return {
		type: ContextActionTypes.SET_GENERAL_TAB_SERVICES_DATA,
		payload,
	};
};

const setGtIdfAction = (payload: number): setGtIdf => {
	return {
		type: ContextActionTypes.SET_GT_IDF,
		payload,
	};
};

const setGeneralTabActivationExpirationPropertyAction = (payload: GeneralTabActivationExpirationProperty): setGeneralTabActivationExpirationProperty => {
	return {
		type: ContextActionTypes.SET_ACTIVATION_EXPIRATION_PROPERTY,
		payload,
	};
};

const setCredTemplateIdAction = (payload: number): setCredTemplateId => {
	return {
		type: ContextActionTypes.SET_CRED_TEMPLATE_ID,
		payload,
	};
};

// --- CardSection --- //
const setCardPropertyAction = (payload: GeneralTabCardProperty): setCardProperty => {
	return {
		type: ContextActionTypes.SET_CARD_PROPERTY,
		payload,
	};
};

// --- InformationSection --- //
const setInformationPropertyAction = (payload: GeneralTabInformationProperty): setInformationProperty => {
	return {
		type: ContextActionTypes.SET_INFORMATION_PROPERTY,
		payload,
	};
};

// --- KeypadSection --- //
const setKeypadPropertyAction = (payload: GeneralTabKeypadProperty): setKeypadProperty => {
	return {
		type: ContextActionTypes.SET_KEYPAD_PROPERTY,
		payload,
	};
};

// FunctionsTab
const setControlZonesArrayAction = (payload: ControlZone[]): setControlZonesArray => {
	return {
		type: ContextActionTypes.SET_CONTROL_ZONES_ARRAY,
		payload,
	};
};

const setCredentialFunctionsArrayAction = (payload: Partial<CredentialFunction>[]): setCredentialFunctionsArray => {
	return {
		type: ContextActionTypes.SET_CREDENTIAL_FUNCTIONS_ARRAY,
		payload,
	};
};

const setFunctionTabServicesDataAction = (payload: {
	controllers: XboxController[];
	doorGroups: DoorGroup[];
	functionCategories: FunctionCategory[];
	functionGroups: FunctionGroup[];
}): setFunctionTabServicesData => {
	return {
		type: ContextActionTypes.SET_FUNCTION_TAB_SERVICES_DATA,
		payload,
	};
};

//LimitsTab
const setLimitsTabPropertyAction = (payload: LimitsTabProperty): setLimitsTabProperty => {
	return {
		type: ContextActionTypes.SET_LIMITS_TAB_PROPERTY,
		payload,
	};
};

// OptionsTab
const setOptionsTabPropertyAction = (payload: OptionsTabProperty): setOptionsTabProperty => {
	return {
		type: ContextActionTypes.SET_OPTION_TAB_PROPERTY,
		payload,
	};
};

export {
	initConfigDataAction,
	setCompleteModelAction,
	setErrorAction,
	setRootModelPropertyAction,
	setOverrideResponseAction,
	// GeneralTab
	setCredTemplateIdAction,
	setGeneralTabServicesDataAction,
	setGtIdfAction,
	// --- Activation/Expiration Section -- //
	setGeneralTabActivationExpirationPropertyAction,
	// --- CardSection --- //
	setCardPropertyAction,
	// --- InformationSection --- //
	setInformationPropertyAction,
	// --- KeypadSection --- //
	setKeypadPropertyAction,
	// FunctionsTab
	setControlZonesArrayAction,
	setCredentialFunctionsArrayAction,
	setFunctionTabServicesDataAction,
	// LimitsTab
	setLimitsTabPropertyAction,
	// OptionsTab
	setOptionsTabPropertyAction,
};

import React, { useContext } from 'react';
import { Select } from '../../../../../../common';
import { PortStoreContext, setCOMPortAction, setPortChangedAction } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

const mainDivContainerId = 'portModalCOMPortDropdownContainer';

const COMPort: React.FC = () => {
	const {
		portState: { comPorts, hasPortChanged, comPort },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChangeCOMPort = option => {
		dispatcher(setCOMPortAction(Number(option)));
		checkIfPortChanged();
	};

	return (
		<div id={mainDivContainerId} className={styles.dropdownContainer}>
			<label id='portGeneralCOMPortLabel'>{_('Port')}</label>
			<Select
				id='portGeneralCOMPortDropdown'
				onChange={handleChangeCOMPort}
				options={comPorts.map((x, index) => ({
					label: x.PortNameFormatted,
					value: index.toString(),
					id: `portGeneralCOMPortDropdownOption-${index}`,
				}))}
				value={comPort.toString()}
				className={styles.dropdown}
				getPopupContainer={() => document.getElementById(mainDivContainerId)}
			/>
		</div>
	);
};

export default COMPort;

import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../model/CommonModel';
import { Select } from '../../../../../common';
import { setPort, StoreContext } from '../../contextXbox';
import styles from '../../xboxModal.module.scss';

type Props = {
	portValidation: boolean;
	disableEdit: boolean;
};

const Ports: React.FC<Props> = ({ portValidation, disableEdit }) => {
	const {
		xboxState: { ports, selections, unpermitted },
		dispatcher,
	} = useContext(StoreContext);

	const [options, setOptions] = useState<SelectOptions<string>[]>([]);
	const [portAddress, setPortAddress] = useState(ports.map(x => (x.Id === selections.port ? x.Address : '')));

	const handleOnChangePort = (value: string): void => {
		dispatcher(setPort(Number(value)));
		setPortAddress(ports.map(x => (x.Id === Number(value) ? x.Address : '')));
	};

	const portValue = selections.port === 0 ? undefined : selections.port.toString();
	const portContainerDiv: string = 'xboxPortContainer';

	useEffect(() => {
		const isPortPermitted = ports.some(x => x.Name.localeCompare(unpermitted.portName) === 0);

		if (isPortPermitted || selections.port === 0) {
			setOptions(ports.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
			setPortAddress(ports.map(x => (x.Id === selections.port ? x.Address : '')));
		} else {
			const unpermittedOption: SelectOptions<string>[] = [
				{
					label: unpermitted.portName,
					value: selections.port.toString(),
					id: selections.port.toString(),
				},
			];
			setOptions(unpermittedOption);
			setPortAddress([unpermitted.portAddress]);
		}
	}, [ports, unpermitted.portName]);

	const xboxPortDropdownId = 'xboxPortDropdown';

	return (
		<React.Fragment>
			<div className={styles.sections} id={portContainerDiv}>
				<label htmlFor={xboxPortDropdownId}>{_('Port')}</label>
				<div>
					<Select
						id={xboxPortDropdownId}
						className={cx(styles.select, {
							[styles.error]: portValidation,
						})}
						options={options}
						onChange={handleOnChangePort}
						key='port'
						placeholder={_('SelectPort')}
						value={portValue}
						disabled={disableEdit}
						getPopupContainer={() => document.getElementById(portContainerDiv)}
					/>
					{portValidation && (
						<label className={styles.errorMessage} htmlFor={xboxPortDropdownId}>
							{_('PortIsRequired')}
						</label>
					)}
				</div>
			</div>
			<div className={styles.sections}>
				<div></div>
				<div className={styles.portAddress}>
					<label htmlFor={xboxPortDropdownId}>
						{_('PortAddress')} {portAddress}
					</label>
				</div>
			</div>
		</React.Fragment>
	);
};

export { Ports };

import React from 'react';
import { getPermissionErrorMessage, User } from '../../../model/AccountModel';
import { ApplicationSetting, ConfigurationState, ProfileSetting, SettingsValue, SettingType } from '../../../model/ConfigurationModel';
import { CheckboxApplicationSettingHelper } from '../Common/CheckboxApplicationSettingHelper';
import { CheckboxHelper } from '../Common/CheckboxHelper';
import { InputNumberHelper } from '../Common/InputNumberHelper';
import styles from './alarm-settings.module.scss';

// Normal properties for the component
interface Props {
	configuration: ConfigurationState;
	onSetSettingValue: (value: SettingsValue<string>) => void;
	onSetCheckBoxesEnabled: (value: SettingsValue<boolean>) => void;
	onSetViewSettingValue: (value: SettingsValue<string>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
	castNumber: (value: number) => boolean;
}

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const { isAdmin } = user;
const minDelayedSecondsValue: number = 0;
const minAlarmsPerPageValue: number = 1;

const AlarmSettings: React.FC<Props> = ({
	configuration,
	onSetSettingValue,
	onSetCheckBoxesEnabled,
	onSetViewSettingValue,
	onSetSettingsHaveChanged,
	castNumber,
}) => {
	const alarmTabVisible: boolean = configuration.AlarmTabVisible;

	const alwaysShowInstructions: ApplicationSetting = configuration.AlwaysShowInstructions;
	const alarmStackingEnabled: ApplicationSetting = configuration.AlarmStackingEnabled;
	const reportReturnToNormalAlarms: ApplicationSetting = configuration.ReportReturnToNormalAlarms;
	const autoAcknowledgeOfReturnToNormal: ApplicationSetting = configuration.AutoAcknowledgeOfReturnToNormal;
	const autoCleanReturnToNormal: ApplicationSetting = configuration.AutoCleanReturnToNormal;

	const alarmsPerPageSetting: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'AlarmsPerPage');
	const queueSortDirSetting: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'QueueSortDir');
	const chkGeneral0: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkGeneral:0');
	const chkGeneral2: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkGeneral:2');
	const chkNewNote0: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkNewNote:0');
	const chkGeneral3: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkGeneral:3');
	const chkNewNote1: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkNewNote:1');
	const soundRepeat: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'SoundRepeat');
	const showAlarmBanner: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowAlarmBanner');
	const soundDelay: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'SoundDelay');
	const chkGeneral7: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkGeneral:7');
	const chkGeneral8: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'chkGeneral:8');
	const allowBackgroundSound: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'AllowBackgroundSound');

	const setSettingsHaveChanged = () => {
		if (!configuration.HaveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	const handleOnChangeCheckBox = (value: boolean, type: SettingType) => {
		const setValues: SettingsValue<string> = {
			key: 'Value',
			value: value ? '1' : '0',
			type,
		};
		onSetSettingValue(setValues);
		setSettingsHaveChanged();
	};

	const handleEnableCheckBox = (value: boolean, key: string) => {
		const setValues: SettingsValue<boolean> = {
			key,
			value,
			type: 'Alarm',
		};
		onSetCheckBoxesEnabled(setValues);
		setSettingsHaveChanged();
	};

	const handleSettingChange = (value: string, key: string) => {
		const setValues: SettingsValue<string> = {
			key,
			value,
			type: 'Alarm',
		};
		onSetViewSettingValue(setValues);
		setSettingsHaveChanged();
	};

	const alarmStackingChecked: boolean = castNumber(Number(alarmStackingEnabled.Value ?? 0));
	const reportReturnToNormalAlarmsChecked: boolean = castNumber(Number(reportReturnToNormalAlarms.Value ?? 0));
	const autoAcknowledgeOfReturnToNormalChecked: boolean = castNumber(Number(autoAcknowledgeOfReturnToNormal.Value ?? 0));

	return (
		<div className={styles.container} id='ConfigTabData_2'>
			<div className={styles.info}>
				<div className={styles.addBoldFont}>*{_('SystemWideSettings')}</div>
				<div>
					<div>
						<CheckboxApplicationSettingHelper
							applicationSetting={alwaysShowInstructions}
							hasPermission={isAdmin && alarmTabVisible}
							disabled={!(isAdmin && alarmTabVisible)}
							onChange={e => {
								handleOnChangeCheckBox(e, 'AlwaysShowInstructions');
							}}
							castNumber={castNumber}
						/>
					</div>
					<div>
						<CheckboxApplicationSettingHelper
							applicationSetting={alarmStackingEnabled}
							hasPermission={alarmTabVisible}
							disabled={!alarmTabVisible}
							onChange={e => {
								handleOnChangeCheckBox(e, 'AlarmStackingEnabled');
							}}
							castNumber={castNumber}
						/>
					</div>
					<div>
						<CheckboxApplicationSettingHelper
							applicationSetting={reportReturnToNormalAlarms}
							hasPermission={alarmTabVisible}
							disabled={!alarmTabVisible}
							onChange={e => {
								handleOnChangeCheckBox(e, 'ReportReturnToNormalAlarms');
							}}
							castNumber={castNumber}
						/>
					</div>
					<div className={styles.addMarginLeft}>
						<CheckboxApplicationSettingHelper
							applicationSetting={autoAcknowledgeOfReturnToNormal}
							hasPermission={alarmTabVisible}
							disabled={!alarmTabVisible || alarmStackingChecked || !reportReturnToNormalAlarmsChecked}
							onChange={e => {
								handleOnChangeCheckBox(e, 'AutoAcknowledgeOfReturnToNormal');
							}}
							castNumber={castNumber}
						/>
					</div>
					<div className={styles.addMarginDoubleLeft}>
						<CheckboxApplicationSettingHelper
							applicationSetting={autoCleanReturnToNormal}
							hasPermission={alarmTabVisible}
							disabled={!alarmTabVisible || alarmStackingChecked || !reportReturnToNormalAlarmsChecked || !autoAcknowledgeOfReturnToNormalChecked}
							onChange={e => {
								handleOnChangeCheckBox(e, 'AutoCleanReturnToNormal');
							}}
							castNumber={castNumber}
						/>
					</div>
				</div>
				<div className={styles.addBoldFont}>{_('AlarmAlerts')}</div>
				<div>
					<CheckboxHelper
						doesntHavePermission={!alarmTabVisible}
						profileSetting={showAlarmBanner}
						onChange={e => handleEnableCheckBox(e, showAlarmBanner.Name)}
						disabled={!alarmTabVisible}
					/>
				</div>
				<div className={styles.addBoldFont}>{_('MaximumPageSize')}</div>
				<div>
					<label htmlFor='AlarmsPerPage'>{_('Show')}</label>
					{
						<InputNumberHelper
							title={getPermissionErrorMessage(alarmTabVisible)}
							profileSetting={alarmsPerPageSetting}
							min={minAlarmsPerPageValue}
							max={100}
							maxLength={3}
							id='AlarmsPerPage'
							disabled={!alarmTabVisible}
							onChange={value => {
								if (!value || value === undefined || (value as number) < minAlarmsPerPageValue) {
									value = minAlarmsPerPageValue;
								}
								handleSettingChange(value.toString(), alarmsPerPageSetting.Name);
							}}
						/>
					}
				</div>
				<div className={styles.addBoldFont}>{_('AlarmSorting')}</div>
				<div>
					<CheckboxHelper
						doesntHavePermission={!alarmTabVisible}
						profileSetting={queueSortDirSetting}
						onChange={e => handleEnableCheckBox(e, queueSortDirSetting.Name)}
						disabled={!alarmTabVisible}
					/>
				</div>
				<div className={styles.addBoldFont}>{_('AlarmAcknowledgeOptions')}</div>
				<div>
					<div>
						<CheckboxHelper profileSetting={chkGeneral0} onChange={e => handleEnableCheckBox(e, chkGeneral0.Name)} disabled={!alarmTabVisible} />
					</div>
					<div>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={chkGeneral2}
							id='checkRequireEntryOfNoteOnAcknowledge'
							onChange={e => handleEnableCheckBox(e, chkGeneral2.Name)}
							disabled={!alarmTabVisible}
						/>
					</div>
					<div className={styles.addMarginLeft}>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={chkNewNote0}
							id='checkForceNewNoteForMultipleAcknowledge'
							onChange={e => handleEnableCheckBox(e, chkNewNote0.Name)}
							disabled={!alarmTabVisible || !chkGeneral2.SelectedValue}
						/>
					</div>
					<div>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={chkGeneral3}
							id='checkRequireEntryOfNoteOnClear'
							onChange={e => handleEnableCheckBox(e, chkGeneral3.Name)}
							disabled={!alarmTabVisible}
						/>
					</div>
					<div className={styles.addMarginLeft}>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={chkNewNote1}
							id='checkForceNewNoteForMultipleClear'
							onChange={e => handleEnableCheckBox(e, chkNewNote1.Name)}
							disabled={!alarmTabVisible || !chkGeneral3.SelectedValue}
						/>
					</div>
				</div>
				<div className={styles.addBoldFont}>{_('AlarmSoundOptions')}</div>
				<div>
					<div>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={soundRepeat}
							id='checkRepeatMultimedia'
							onChange={e => handleEnableCheckBox(e, soundRepeat.Name)}
							disabled={!alarmTabVisible}
						/>
					</div>
					<div className={styles.addMarginLeft}>
						<label htmlFor='delayedSeconds'>{soundDelay.Label}</label>
						<InputNumberHelper
							title={getPermissionErrorMessage(alarmTabVisible)}
							profileSetting={{ ...soundDelay, Label: '' }}
							id='delayedSeconds'
							min={minDelayedSecondsValue}
							max={65}
							maxLength={2}
							onChange={value => {
								if (!value || value === undefined || (value as number) < minDelayedSecondsValue) {
									value = minDelayedSecondsValue;
								}
								handleSettingChange(value.toString(), soundDelay.Name);
							}}
							disabled={!alarmTabVisible || !soundRepeat.SelectedValue}
						/>
					</div>
					<div>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={chkGeneral7}
							onChange={e => handleEnableCheckBox(e, chkGeneral7.Name)}
							disabled={!alarmTabVisible}
						/>
					</div>
					<div>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={chkGeneral8}
							id='checkDisableAlarmSound'
							onChange={e => handleEnableCheckBox(e, chkGeneral8.Name)}
							disabled={!alarmTabVisible}
						/>
					</div>
					<div>
						<CheckboxHelper
							doesntHavePermission={!alarmTabVisible}
							profileSetting={allowBackgroundSound}
							id='checkBackgroundSound'
							onChange={e => handleEnableCheckBox(e, allowBackgroundSound.Name)}
							disabled={!alarmTabVisible}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export { AlarmSettings };

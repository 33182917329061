import React from 'react';
import { ControllerContact, XboxData } from '../../../../model/DeviceAdminModel';

type XboxContextState = {
	ports: ControllerContact[];
	xAddresses: number[];
	messagesPerPoll: number;
	logOffTime: number;
	enabled: boolean;
	revision: string;
	name: string;
	selections: {
		port: number;
		xAddress: number;
	};
	unpermitted: {
		portName: string;
		portAddress: string;
	};
};

enum XboxContextActionTypes {
	SET_PORTS = 'SET_PORTS',
	SET_PORT = 'SET_PORT',
	SET_XADDRESS = 'SET_XADDRESS',
	SET_XADDRESSES = 'SET_XADDRESSES',
	SET_MSGPERPOLL = 'SET_MSGPERPOLL',
	SET_LOGOFF = 'SET_LOGOFF',
	SET_XBOXSTATE = 'SET_XBOXSTATE',
	SET_NAME = 'SET_NAME',
	INIT_XBOX = 'INIT_XBOX',
}

type contextXboxInitXbox = {
	type: XboxContextActionTypes.INIT_XBOX;
	payload: Partial<XboxData>;
};
type contextXboxSetPorts = {
	type: XboxContextActionTypes.SET_PORTS;
	payload: ControllerContact[];
};
type contextXboxSetPort = {
	type: XboxContextActionTypes.SET_PORT;
	payload: number;
};
type contextXboxSetXAddress = {
	type: XboxContextActionTypes.SET_XADDRESS;
	payload: number;
};
type contextXboxSetXAddresses = {
	type: XboxContextActionTypes.SET_XADDRESSES;
	payload: number[];
};
type contextXboxSetMsgPerPoll = {
	type: XboxContextActionTypes.SET_MSGPERPOLL;
	payload: number;
};
type contextXboxSetLogOff = {
	type: XboxContextActionTypes.SET_LOGOFF;
	payload: number;
};
type contextXboxSetXboxState = {
	type: XboxContextActionTypes.SET_XBOXSTATE;
	payload: boolean;
};
type contextXboxSetName = {
	type: XboxContextActionTypes.SET_NAME;
	payload: string;
};

type XboxActionContext =
	| contextXboxSetPorts
	| contextXboxSetXAddress
	| contextXboxSetMsgPerPoll
	| contextXboxSetLogOff
	| contextXboxSetXboxState
	| contextXboxSetName
	| contextXboxSetPort
	| contextXboxSetXAddresses
	| contextXboxInitXbox;

const xboxState: XboxContextState = {
	ports: [],
	xAddresses: [],
	messagesPerPoll: 15,
	logOffTime: 10,
	enabled: true,
	revision: '0.00',
	name: 'New XBox',
	selections: {
		port: 0,
		xAddress: 0,
	},
	unpermitted: {
		portName: '',
		portAddress: '',
	},
};

const xboxContext = (state: Readonly<XboxContextState>, action: XboxActionContext): XboxContextState => {
	switch (action.type) {
		case XboxContextActionTypes.INIT_XBOX:
			return {
				...state,
				unpermitted: { portName: action.payload.UnpermittedPortName, portAddress: action.payload.UnpermittedPortAddress },
				name: action.payload.XboxName,
				revision: action.payload.Revision,
				messagesPerPoll: action.payload.MsgPerPoll,
				logOffTime: action.payload.LogOff,
				enabled: action.payload.Enabled,
				selections: { ...state.selections, port: action.payload.PortId, xAddress: action.payload.XAddress },
			};

		case XboxContextActionTypes.SET_PORTS:
			return { ...state, ports: action.payload };

		case XboxContextActionTypes.SET_PORT:
			return { ...state, selections: { ...state.selections, port: action.payload } };

		case XboxContextActionTypes.SET_XADDRESS:
			return { ...state, selections: { ...state.selections, xAddress: action.payload } };

		case XboxContextActionTypes.SET_XADDRESSES:
			return { ...state, xAddresses: action.payload };

		case XboxContextActionTypes.SET_MSGPERPOLL:
			return { ...state, messagesPerPoll: action.payload };

		case XboxContextActionTypes.SET_LOGOFF:
			return { ...state, logOffTime: action.payload };

		case XboxContextActionTypes.SET_XBOXSTATE:
			return { ...state, enabled: action.payload };

		case XboxContextActionTypes.SET_NAME:
			return { ...state, name: action.payload };

		default:
			return { ...state };
	}
};

const initXbox = (payload: Partial<XboxData>): contextXboxInitXbox => {
	return {
		type: XboxContextActionTypes.INIT_XBOX,
		payload,
	};
};
const setPorts = (payload: ControllerContact[]): contextXboxSetPorts => {
	return {
		type: XboxContextActionTypes.SET_PORTS,
		payload,
	};
};
const setPort = (payload: number): contextXboxSetPort => {
	return {
		type: XboxContextActionTypes.SET_PORT,
		payload,
	};
};
const setXAddress = (payload: number): contextXboxSetXAddress => {
	return {
		type: XboxContextActionTypes.SET_XADDRESS,
		payload,
	};
};
const setXAddresses = (payload: number[]): contextXboxSetXAddresses => {
	return {
		type: XboxContextActionTypes.SET_XADDRESSES,
		payload,
	};
};
const setMsgPerPoll = (payload: number): contextXboxSetMsgPerPoll => {
	return {
		type: XboxContextActionTypes.SET_MSGPERPOLL,
		payload,
	};
};
const setLogOff = (payload: number): contextXboxSetLogOff => {
	return {
		type: XboxContextActionTypes.SET_LOGOFF,
		payload,
	};
};
const setXboxState = (payload: boolean): contextXboxSetXboxState => {
	return {
		type: XboxContextActionTypes.SET_XBOXSTATE,
		payload,
	};
};
const setName = (payload: string): contextXboxSetName => {
	return {
		type: XboxContextActionTypes.SET_NAME,
		payload,
	};
};

type XboxContext = {
	xboxState: Readonly<XboxContextState>;
	dispatcher: React.Dispatch<XboxActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const StoreContext = React.createContext<XboxContext>({ xboxState, dispatcher: null });

export { initXbox, xboxContext, xboxState, setPort, setPorts, setXAddresses, setXAddress, setMsgPerPoll, setLogOff, setXboxState, setName, StoreContext };

import React from 'react';
import { CardSection } from '../../../../../common/CardSection/CardSection';
import { AdvanceReportingSetup } from './AdvanceReportingSetup/AdvanceReportingSetup';
import { DisableReportingDuringTimeZone } from './DisableReportingDuringTimeZone/DisableReportingDuringTimeZone';
import { DisableReportingOf } from './DisableReportingOf/DisableReportingOf';
import { LocalPrinterSetup } from './LocalPrinterSetup/LocalPrinterSetup';
import styles from './reporting.module.scss';

const Reporting: React.FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.generalReporting}>
				<CardSection title={`${_('DisableReportingOf')}:`}>
					<DisableReportingOf />
				</CardSection>
				<CardSection title={_('DisableReportingDuringTimeZone')}>
					<DisableReportingDuringTimeZone />
				</CardSection>
				<CardSection title={_('LocalPrinterSetup')} width={550}>
					<LocalPrinterSetup />
				</CardSection>
			</div>
			<div className={styles.advancedReporting}>
				<p>{_('AdvancedReportingSetups')}</p>
				<p className={styles.advanceReportingWarning}>{_('AdvancedReportingWarning')}</p>
				<AdvanceReportingSetup />
			</div>
		</div>
	);
};

export { Reporting };

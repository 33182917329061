import React from 'react';
import { DeviceObjectType } from '../../../model/DeviceAdminModel';
import { WithLoader } from '../../common';
import { CommandSets } from './CommandSets/CommandSets';
import { CredentialTemplate } from './CredentialTemplate/CredentialTemplate';
import { DoorGroup } from './DoorGroup/DoorGroup';
import { Holidays } from './Holidays/Holidays';
import { NetworkGlobalIO } from './NetworkGlobalIO/NetworkGlobalIO';
import { Operators } from './Operators/Operators';
import { ReaderControlGroup } from './ReaderControlGroup/ReaderControlGroup';
import { Roles } from './Roles/Roles';
import { TimeZones } from './TimeZones/TimeZones';

type Props = {
	deviceObjectType: DeviceObjectType;
	propKey: number;
};

const VelocityConfiguration = WithLoader(({ deviceObjectType, propKey }: Props) => {
	return (
		<React.Fragment key={propKey}>
			{/*Keep in the DOM in order to use memoization from react HOOK  */}
			{deviceObjectType === DeviceObjectType.CommandSets && <CommandSets />}
			{deviceObjectType === DeviceObjectType.DoorGroup && <DoorGroup />}
			{deviceObjectType === DeviceObjectType.ReaderControlGroup && <ReaderControlGroup />}
			{deviceObjectType === DeviceObjectType.Operators && <Operators />}
			{deviceObjectType === DeviceObjectType.Roles && <Roles />}
			{deviceObjectType === DeviceObjectType.CredentialTemplates && <CredentialTemplate />}
			{deviceObjectType === DeviceObjectType.Holidays && <Holidays />}
			{deviceObjectType === DeviceObjectType.TimeZones && <TimeZones />}
			{deviceObjectType === DeviceObjectType.GlobalIOGroup && <NetworkGlobalIO />}
		</React.Fragment>
	);
});
export { VelocityConfiguration };

export enum EnrollmentActionTypes {
	FETCHING_SCAN_DATA = 'FETCHING_SCAN_DATA',
	DISPLAY_ADD_VIA_SMART_CARD = 'DISPLAY_ADD_VIA_SMART_CARD',
	SET_ENROLLMENT_TAB_INFO = 'SET_ENROLLMENT_TAB_INFO',
	SET_SELECTED_PERSON_GROUP = 'SET_SELECTED_PERSON_GROUP',
	SET_PERSON_TABLE_INFORMATION_BY_PAGE = 'SET_PERSON_TABLE_INFORMATION_BY_PAGE',
	SET_IS_PERSON_TABLE_LOADING = 'SET_IS_PERSON_TABLE_LOADING',
	SET_SELECTED_ROW_KEYS = 'SET_SELECTED_ROW_KEYS',
	SET_KEYWORD_SEARCH_TEXT = 'SET_KEYWORD_SEARCH_TEXT',
	SET_PERSON_TABLE_PAGINATION_SETTINGS = 'SET_PERSON_TABLE_PAGINATION_SETTINGS',
	SET_UPDATE_COUNTER = 'SET_UPDATE_COUNTER',
	SET_ACTIVE_ADVANCED_SEARCH = 'SET_ACTIVE_ADVANCED_SEARCH',
	SET_ADVANCED_SEARCH_CRITERIA = 'SET_ADVANCED_SEARCH_CRITERIA',
	CLEAR_ADVANCED_SEARCH = 'CLEAR_ADVANCED_SEARCH',
	SET_PERSON_INFORMATION_ID = 'SET_PERSON_INFORMATION_ID',
	SET_PERSON_INFORMATION_DATA = 'SET_PERSON_INFORMATION_DATA',
	SET_IS_ENROLLMENT_TAB_LOADING = 'SET_IS_ENROLLMENT_TAB_LOADING',
	SET_PERSON_TABLE_SORTER_CONFIG = 'SET_PERSON_TABLE_SORTER_CONFIG',
	SET_HAVE_PERSON_CHANGED = 'SET_HAVE_PERSON_CHANGED',
	SET_ENROLLMENT_BLANK_IMAGES = 'SET_ENROLLMENT_BLANK_IMAGES',
}

import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../Helper';
import { ReaderError } from '../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setEntryReaderError, setName, setReaderErrorType, StoreContext } from '../../../contextReader';
import styles from '../../General/general.module.scss';

type Props = {
	useDoorContext: boolean;
	setErrorText: () => void;
};
const Name: React.FC<Props> = ({ useDoorContext, setErrorText }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { name, readerErrorMessage, readerErrorType, isEntryReaderError },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { name, readerErrorMessage, readerErrorType },
			dispatcher,
		} = useContext(StoreContext);
	}

	const [errorMessage, setErrorMessage] = useState<JSX.Element>(<></>);

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		useDoorContext ? dispatcherDoor(setName(value)) : dispatcher(setName(value));

		if (readerErrorType === ReaderError.Name) {
			useDoorContext
				? (dispatcherDoor(setReaderErrorType(undefined)), dispatcherDoor(setEntryReaderError(false)), setErrorText())
				: dispatcher(setReaderErrorType(undefined));
		}
	};

	useEffect(() => {
		if (readerErrorType === ReaderError.Name) {
			setErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else {
			setErrorMessage(<></>);
		}
	}, [readerErrorType]);

	return (
		<div className={styles.generalSection}>
			<span>{useDoorContext ? _('ReaderName') : _('Name')}</span>
			<div>
				<Input
					key='name'
					id='readerModalInputReaderName'
					value={name}
					onChange={handleOnChangeName}
					className={cx(styles.inputName, {
						[styles.error]:
							(readerErrorType === ReaderError.Name && isEntryReaderError) || (readerErrorType === ReaderError.Name && !useDoorContext),
					})}
					maxLength={32}
					autoComplete='chrome-off'
				/>
				{(isEntryReaderError && errorMessage) || (!useDoorContext && errorMessage)}
			</div>
		</div>
	);
};

export { Name };

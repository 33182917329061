// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".state-change-reporting-container--3B3pq4xFuKk33MO9v2CPT8{margin-bottom:30px}.state-change-reporting-container--3B3pq4xFuKk33MO9v2CPT8 .state-change-reporting-gridSelect--ysWuduS0V4nuCyyP8i8-0{display:grid !important;grid-template-columns:60% 40%;row-gap:20px}.state-change-reporting-container--3B3pq4xFuKk33MO9v2CPT8 .state-change-reporting-gridDiv--bU3lWYjHZiSiEpJnXr3As{display:grid !important;grid-template-rows:repeat(2, 50%);row-gap:20px}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/DigiTrac/InputModal/tabs/StateChangeReporting/stateChangeReporting.module.scss"],"names":[],"mappings":"AAAA,0DACC,kBAAA,CACA,oHACC,uBAAA,CACA,6BAAA,CACA,YAAA,CAED,iHACC,uBAAA,CACA,iCAAA,CACA,YAAA","sourcesContent":[".container {\r\n\tmargin-bottom: 30px;\r\n\t.gridSelect {\r\n\t\tdisplay: grid !important;\r\n\t\tgrid-template-columns: 60% 40%;\r\n\t\trow-gap: 20px;\r\n\t}\r\n\t.gridDiv {\r\n\t\tdisplay: grid !important;\r\n\t\tgrid-template-rows: repeat(2, 50%);\r\n\t\trow-gap: 20px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "state-change-reporting-container--3B3pq4xFuKk33MO9v2CPT8",
	"gridSelect": "state-change-reporting-gridSelect--ysWuduS0V4nuCyyP8i8-0",
	"gridDiv": "state-change-reporting-gridDiv--bU3lWYjHZiSiEpJnXr3As"
};
export default ___CSS_LOADER_EXPORT___;

import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { credentialApi, deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	DefaultColumnsCredential,
	OperatorsColumn,
	PaginationSetting,
	ResponseStatusCode,
	SelectOptions,
	SelectedDeviceKeys,
	SelectedDevicePage,
} from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	CredentialTemplateData,
	CurrentDeviceControlObj,
	DeviceObjectType,
	DeviceSelectedPage,
	OptionsButtonBuilder,
	SelectedRowKeyPagination,
	VelocityConfigurationModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { selectCurrentDevice } from '../../../../store/common/selectors';
import {
	clearVelocityConfigurationSelectionsAction,
	setCredentialTemplatesBy,
	setCredentialTemplatesByData,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
	setVelocitySelectedRowKeysPaginationAction,
} from '../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfiguration } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, EditableCell, ModalConfirmationList, NotificationStatus, SearchColumn } from '../../../common';
import CredentialTemplateModal from '../../../enrollment/CredentialTemplateModal';
import { CredentialTemplateModalConfigData } from '../../../enrollment/CredentialTemplateModal/CredentialTemplateModalModels';
import styles from './credentialTemplate.module.scss';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Credential_Template);
const maxLength: number = 50;
const scroll: ScrollType = { x: 379, y: 312 };
const deviceObjectType: DeviceObjectType = DeviceObjectType.CredentialTemplates;

const isSetAsDefaultDisabled = (): boolean => {
	return !(componentPermission.canAdd || (componentPermission.canView && componentPermission.canUpdate));
};

const createButtonOptions = (hasMoreThanOne: boolean, hasDefaultTemplateSelected: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
	let opts = [
		{
			label: _('SetAsDefault'),
			disabled: isSetAsDefaultDisabled() || hasMoreThanOne,
			separator: true,
			value: 'setAsDefault',
			title: getPermissionErrorMessage(!isSetAsDefaultDisabled(), false, hasMoreThanOne),
			id: 'credentialTemplateTableSetAsDefaultOption',
		},
		{
			//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
			label: `${_('Edit')} / ${_('Properties')}`,
			disabled: !componentPermission.canView || hasMoreThanOne,
			value: 'edit',
			id: 'credentialTemplateTableEditOption',
			title: getPermissionErrorMessage(componentPermission.canView, false, hasMoreThanOne),
		},
	];

	if (hasMoreThanOne || !(!hasMoreThanOne && hasDefaultTemplateSelected)) {
		opts.push(
			{
				label: _('Rename'),
				disabled: !componentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
				value: 'rename',
				id: 'credentialTemplateTableRenametOption',
				title: getPermissionErrorMessage(componentPermission.canUpdate, false, hasMoreThanOne),
			},
			{
				label: _('Delete'),
				disabled: !componentPermission.canDelete,
				value: 'delete',
				id: 'credentialTemplateTableSetDeleteOption',
				title: getPermissionErrorMessage(componentPermission.canDelete),
			}
		);
	}

	return {
		labelOrIcon: '...',
		options: opts,
	};
};

const CredentialTemplate: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const { credentialTemplates, isEditMode, isFilterMode, selectedRowKeysPagination }: VelocityConfigurationModel =
		useStoreSelector(selectVelocityConfiguration);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);
	const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [editingKey, setEditingKey] = useState('');
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<DefaultColumnsCredential[]>([]);
	const [credentialTemplatePage, setCredentialTemplatePage] = useState<SelectedDevicePage>(null);
	const [form] = Form.useForm();
	const [credentialTemplateId, setCredentialTemplateId] = useState<number>(-1);
	const [configData, setConfigData] = useState<CredentialTemplateModalConfigData>(undefined);
	const [isNewTemplate, setIsNewTemplate] = useState<boolean>(false);

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setIsItemOnSamePage(isSamePage);
		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	const redirectPage = useRef<boolean>(false);
	const isSearchPerformed = useRef<boolean>(false);

	useEffect(() => {
		const mapRes: DefaultColumnsCredential[] = createData();
		setTableData(mapRes);
	}, [JSON.stringify(credentialTemplates)]);

	// * This hook is responsible for loading the previously edited command set
	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage.current) {
			const credentialTemplateId = Number(selectedRowKeys?.[0]);
			getCredentialTemplateSelectedPage(credentialTemplateId);
		}
	}, [redirectPage.current]);

	useEffect(() => {
		if (credentialTemplatePage) {
			setRedirectPage(false);
			setIsItemOnSamePage(true);

			const { deviceId, pageNumber } = credentialTemplatePage;

			batch(() => {
				dispatch(setVelocitySelectedRowKeysPaginationAction([{ Id: deviceId.toString(), PaginationPage: pageNumber }]));
				dispatch(setCredentialTemplatesBy({ ...tablePaginationSetting, PageNumber: pageNumber, SearchedValue: '' }));
			});

			setCredentialTemplatePage(null);
		}
	}, [JSON.stringify(credentialTemplatePage)]);

	// * This hook ensure that always return a valid page number when no values has returned
	useEffect(() => {
		if (tablePaginationSetting.TotalItems === 0 && tablePaginationSetting.PageNumber > 1) {
			const { PageNumber: currentPage } = tablePaginationSetting;
			dispatch(
				setCredentialTemplatesBy({
					...tablePaginationSetting,
					PageNumber: currentPage - 1,
				})
			);
		}
	}, [tablePaginationSetting]);

	useEffect(() => {
		return () => {
			clearSelection();
		};
	}, []);

	const getCredentialTemplateModalInitConfig = () => {
		if (!configData) {
			credentialApi.getCredentialTemplateModalConfig(true).then(data => {
				setConfigData(data);
			});
		}
	};

	const getCredentialTemplateSelectedPage = async (deviceId: number) => {
		const deviceSelectedPage: DeviceSelectedPage = {
			DeviceId: deviceId,
			DeviceObjectType: deviceObjectType,
			IsMasterDoorGroup: false,
		};
		const response = await deviceAdminApi.getVelocityDeviceSelectedPage(deviceSelectedPage, { ...tablePaginationSetting, SearchedValue: '' });
		const { Entity: pageNumber } = response;

		setCredentialTemplatePage({ deviceId, pageNumber });
	};

	const clearSelection = () => {
		if (redirectPage.current) {
			return;
		}
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: deviceObjectType }));
		setSelectedRowKeys([]);
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const onDeleteCredentialTemplateRequest = async (credential: CredentialTemplateData): Promise<void> => {
		const response = await deviceAdminApi.deleteCredentialTemplateRequest(credential.CredentialTemplateId);
		if (handleResponse(response)) {
			return;
		}

		onRemoveCredentialTemplateLocks(credential);
	};

	const handleOkConfirmation = async (selectedCredentialTemplates: SelectedDeviceKeys[]) => {
		for (const credential of selectedCredentialTemplates) {
			const credentialTemplate = { CredentialTemplateId: credential.Id, Description: credential.Name } as CredentialTemplateData;
			await onDeleteCredentialTemplateRequest(credentialTemplate);
		}
		setSelectedRowKeys([]);
		dispatch(setVelocitySelectedRowKeysPaginationAction([]));
		dispatch(setCredentialTemplatesBy(tablePaginationSetting));
	};

	const disabled: boolean = editingKey !== '';
	const hasMoreThanOneKeySelected: boolean = selectedRowKeys.length > 1;
	const hasDefaultTemplateSelected: boolean = selectedRowKeys.find(key => Number(key) === 1) !== undefined;
	const items = createButtonOptions(hasMoreThanOneKeySelected, hasDefaultTemplateSelected, isItemOnSamePage);

	const handleOnSearchResults = (searchedValue: string) => {
		if (redirectPage.current) {
			return;
		}
		dispatch(setCredentialTemplatesBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	let columns: ColumnsProps<DefaultColumnsCredential>[] = [
		{
			...buildColumn(_('CredentialID'), 'CredentialId', '120px', 'start'),
			sorter: !isEditMode,
		},
		{
			...buildColumn(_('Description'), 'Description', 'auto', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: 'Description',
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
	];
	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const onChangeSelection = (key: BaseColumns) => {
		const credentialTemplateId = key.key;
		if (selectedRowKeys.findIndex(key => key.toString() === credentialTemplateId.toString()) === -1) {
			const cloneState: CredentialTemplateData[] = credentialTemplates.map(x =>
				x.CredentialTemplateId.toString() === credentialTemplateId.toString() ? { ...x, checked: true } : { ...x, checked: false }
			);

			dispatch(setCredentialTemplatesByData(cloneState));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: credentialTemplateId,
				PaginationPage: tablePaginationSetting.PageNumber,
			};

			setIsItemOnSamePage(true);
			dispatch(setVelocitySelectedRowKeysPaginationAction([{ ...selectedKeysPagination }]));
			setSelectedRowKeys([credentialTemplateId]);
		}
	};

	const isEditing = (record: DefaultColumnsCredential) => record.key.toString() === editingKey;
	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: DefaultColumnsCredential) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: DefaultColumnsCredential) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,

						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const getSelectedKeysCredentialTemplates = async (): Promise<SelectedDeviceKeys[]> => {
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);

		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const getCredentialTemplate = async (credentialTemplateId: number): Promise<CredentialTemplateData> => {
		const response = await deviceAdminApi.getCredentialTemplate(credentialTemplateId);
		if (handleResponse(response)) {
			return;
		}

		return response.Entity;
	};

	const getSelectedCredentialTemplate = async (credentialTemplateId: number): Promise<CredentialTemplateData> => {
		return credentialTemplates.find(x => x.CredentialTemplateId === credentialTemplateId) ?? (await getCredentialTemplate(credentialTemplateId));
	};

	const handleActionScope = async (key: string) => {
		if (key === 'delete') {
			const selectedCredentialTemplates = await getSelectedKeysCredentialTemplates();
			const selectedCredentialTemplatesWithoutDefault = selectedCredentialTemplates.filter(credentialTemplate => credentialTemplate.Id !== 1);
			ModalConfirmationList({
				type: _('CredentialTemplate'),
				data: selectedCredentialTemplatesWithoutDefault,
				onConfirm: () => handleOkConfirmation(selectedCredentialTemplatesWithoutDefault),
			});
			return;
		}

		const selectedCredentialTemplateId = Number(selectedRowKeys?.[0]);
		const credentialTemplate: CredentialTemplateData = await getSelectedCredentialTemplate(selectedCredentialTemplateId);
		const isLocked: boolean = await lockedValidation(DeviceObjectType.CredentialTemplates, credentialTemplate.CredentialTemplateId);
		if (isLocked) {
			return;
		}

		switch (key) {
			case 'setAsDefault':
				onSetAsDefault(credentialTemplate);
				break;

			case 'rename':
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: DefaultColumnsCredential = tableData.find(x => x.key.toString() === credentialTemplate.CredentialTemplateId.toString());
				edit(findKey);
				const cloneState: CredentialTemplateData[] = changeStateProps(credentialTemplate.CredentialTemplateId, { editable: true });
				dispatch(setCredentialTemplatesByData(cloneState));
				break;
			case 'edit':
			case 'readOnly':
				getCredentialTemplateModalInitConfig();
				batch(() => {
					setCredentialTemplateId(credentialTemplate.CredentialTemplateId);
					handleOnCredentialModalOpen(credentialTemplate.CredentialTemplateId, false);
					setIsNewTemplate(false);
				});
				break;
		}
	};

	const resetSearchColumn = () => {
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const changeStateProps = (deviceId: number, credentialTemplate: Partial<CredentialTemplateData>): CredentialTemplateData[] => {
		const cloneState: CredentialTemplateData[] = [...credentialTemplates];
		const index = cloneState.findIndex(x => x.CredentialTemplateId === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...credentialTemplate });
		}

		return cloneState;
	};

	const edit = (record: DefaultColumnsCredential) => {
		form.setFieldsValue({
			Description: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const handleOnSaveEditRow = async (deviceId: number) => {
		const isLocked: boolean = await lockedValidation(DeviceObjectType.CredentialTemplates, deviceId, false);
		if (isLocked) {
			setEditingKey('');
			const cloneState: CredentialTemplateData[] = changeStateProps(deviceId, { editable: false });
			dispatch(setCredentialTemplatesByData(cloneState));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const description = form.getFieldValue('Description');
		const descriptionError = await deviceAdminApi.renameDevice(DeviceObjectType.CredentialTemplates, description.trim(), deviceId);
		if (descriptionError.ResponseStatusCode !== ResponseStatusCode.Success && descriptionError.ErrorMessage) {
			form.setFields([
				{
					name: 'Description',
					errors: [descriptionError.ErrorMessage],
				},
			]);
			return;
		} else if (descriptionError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: descriptionError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setCredentialTemplatesBy(tablePaginationSetting));
			return;
		}

		setEditingKey('');
		dispatch(setVelocityConfigurationEditMode(false));
		resetSearchColumn();
		setRedirectPage(true);
		dispatch(setCredentialTemplatesBy(tablePaginationSetting));
	};

	const handleOnCancelEditRow = deviceId => {
		const cloneState: CredentialTemplateData[] = changeStateProps(deviceId, { editable: false });
		dispatch(setCredentialTemplatesByData(cloneState));
		dispatch(setVelocityConfigurationEditMode(false));
		setEditingKey('');
	};

	const onSetAsDefault = async (values: CredentialTemplateData) => {
		const responseData = await deviceAdminApi.setDefaultCredential(values.CredentialTemplateId, values.Description);

		NotificationStatus({
			responseData,
			onSuccessCallback: () => {
				const cloneState: CredentialTemplateData[] = [...credentialTemplates];
				const defaultRow = cloneState.findIndex(x => x.IsDefault);
				if (~defaultRow) {
					cloneState[defaultRow].IsDefault = false;
				}

				const currentRow = cloneState.findIndex(x => x.CredentialTemplateId === values.CredentialTemplateId);
				if (~currentRow) {
					cloneState[currentRow].IsDefault = true;
				}

				dispatch(setCredentialTemplatesByData(cloneState));
			},
		});
	};

	const onRemoveCredentialTemplateLocks = async (credential: CredentialTemplateData): Promise<void> => {
		await deviceAdminApi.unlockComponentDeviceAdmin(credential.CredentialTemplateId, SecuredComponents.Enrollment_Credential);
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getCredentialTemplates();
		if (handleResponse(response)) {
			return;
		}

		const credentialTemplatesIds = response.Entity?.map<string>(x => x.CredentialTemplateId.toString());
		return getUniqueValuesArray(newSelectedRowKeys, credentialTemplatesIds);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: CredentialTemplateData[] = credentialTemplates.map(x => ({
			...x,
			checked: selectedRowKeys.findIndex(r => r.toString() === x.CredentialTemplateId.toString()) !== -1,
		}));
		dispatch(setCredentialTemplatesByData(cloneState));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			selectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: CredentialTemplateData[] = credentialTemplates.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(r => r.toString() === x.CredentialTemplateId?.toString()) !== -1,
		}));
		dispatch(setCredentialTemplatesByData(cloneState));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		setIsTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = credentialTemplates.map<React.Key>(x => x.CredentialTemplateId?.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);

		const cloneState: CredentialTemplateData[] = credentialTemplates.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key.toString() === x.CredentialTemplateId?.toString()) !== -1,
		}));
		dispatch(setCredentialTemplatesByData(cloneState));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const createActionElement = (credential: CredentialTemplateData): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (credential.editable) {
			const credentialTemplateId = credential.CredentialTemplateId;

			content = (
				<>
					<Button id='renameSaveCredentialTemplateButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(credentialTemplateId)}>
						{_('Save')}
					</Button>
					<Button id='renameCancelCredentialTemplateButton' key='cancel' onClick={() => handleOnCancelEditRow(credentialTemplateId)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): DefaultColumnsCredential[] => {
		if (credentialTemplates.length > 0) {
			return credentialTemplates.map<DefaultColumnsCredential>((data: CredentialTemplateData) => ({
				key: data.CredentialTemplateId.toString(),
				Action: createActionElement(data),
				Description: data.Description,
				CredentialId: data.CredentialTemplateId,
				IsDefault: data.IsDefault,
			}));
		}

		return [];
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} onClick={x.onClick} disabled={x.disabled} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const handleOnCredentialModalCancel = () => {
		const newDevice: CurrentDeviceControlObj = {
			Id: 0,
			DeviceObjectType: DeviceObjectType.CredentialTemplates,
			Address: '',
			IsModalOpen: false,
			IsNew: false,
		};
		dispatch(setCurrentDevice(newDevice));
	};

	const handleOnCredentialModalOpen = (newCredentialTemplateId: number, isNewCredential: boolean) => {
		const newDevice: CurrentDeviceControlObj = {
			...currentDevice,
			Id: newCredentialTemplateId,
			IsModalOpen: !currentDevice.IsModalOpen,
			DeviceObjectType: DeviceObjectType.CredentialTemplates,
			IsNew: isNewCredential,
		};
		dispatch(setCurrentDevice(newDevice));
	};

	const handleOnCredentialModalSuccess = () => {
		handleOnCredentialModalCancel();
		dispatch(setCredentialTemplatesBy(tablePaginationSetting));
	};

	const renderCredentialModal = (): React.ReactNode => {
		if (!currentDevice.IsModalOpen || !configData || currentDevice.DeviceObjectType !== DeviceObjectType.CredentialTemplates) return null;
		return (
			<CredentialTemplateModal
				configData={configData}
				credentialId={credentialTemplateId}
				handleModalOnCancel={handleOnCredentialModalCancel}
				handleModalOnSuccess={handleOnCredentialModalSuccess}
				isNew={isNewTemplate}
				isTemplate={true}
				personId={undefined}
				user={user}
				userId={0}
			/>
		);
	};

	const buttons: ButtonBuilder[] = [
		{
			label: _('AddCredentialTemplate'),
			icon: <PlusOutlined />,
			disabled: !componentPermission.canAdd || isEditMode,
			onClick: () => {
				getCredentialTemplateModalInitConfig();
				batch(() => {
					setCredentialTemplateId(-1);
					handleOnCredentialModalOpen(-1, true);
					setIsNewTemplate(true);
				});
			},
			id: 'credentialTemplateAddButton',
			title: getPermissionErrorMessage(componentPermission.canAdd),
		},
	];

	const rowSelection: TableRowSelection<DefaultColumnsCredential> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `credentialTemplateTableCheckbox-${record.key}`,
			disabled,
			role: 'checkbox',
			['aria-label']: _('SelectCredentialTemplate'),
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<OperatorsColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setCredentialTemplatesBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<>
			{renderCredentialModal()}
			<div className={styles.container}>
				<div className={styles.buttonContainer}>
					<ButtonDropdown
						id={'credentialTemplateActionButton'}
						disabled={disabledActionButtons}
						menuOptions={items.options}
						icon={<CaretDownOutlined />}
						labelIcon={items.labelOrIcon}
						onClickOption={handleActionScope}
					/>
					{buttonsBuilder(buttons)}
				</div>
				<Form form={form} component={false}>
					<Table
						id='credentialTemplateTable'
						columns={mergedColumns as ColumnsType}
						components={{
							body: {
								cell: EditableCell,
							},
						}}
						dataSource={tableData}
						pagination={pagination}
						scroll={scroll}
						size='small'
						className={styles.statusWidgetTable}
						rowClassName={(record: DefaultColumnsCredential) => (record.IsDefault ? styles.defaultCredential : '')}
						rowSelection={rowSelection}
						onChange={handleOnChangeTable}
						loading={isTableLoading}
					/>
				</Form>
			</div>
		</>
	);
};

export { CredentialTemplate };

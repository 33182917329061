import cx from 'classnames';
import React, { useContext } from 'react';
import { RelayLogicTimeZones } from '../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setOperateTimeZone, StoreContext } from '../../../../contextExpansionRelay';
import styles from '../../../../expansionRelayModal.module.scss';

type Props = {
	setPreviousSelections: (RelayLogicTimeZones) => void;
	previousSelections: RelayLogicTimeZones;
	useDoorContext: boolean;
};

const Operate: React.FC<Props> = props => {
	const { setPreviousSelections, previousSelections, useDoorContext } = props;
	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: { operateTimeZones, selections, unpermitted },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			expansionRelayState: { operateTimeZones, selections, unpermitted },
			dispatcher,
		} = useContext(StoreContext);
	}

	const options = unpermitted.operateTimeZone
		? [
				{
					label: unpermitted.operateTimeZone.Name,
					value: unpermitted.operateTimeZone.GenericId.toString(),
					id: `relayLogicOperateTimeZoneOption-${unpermitted.operateTimeZone.GenericId.toString()}`,
				},
		  ]
		: operateTimeZones.map(x => ({ label: x.Name, value: x.GenericId.toString(), id: `relayLogicOperateTimeZoneOption-${x.GenericId.toString()}` }));

	return (
		<div className={styles.sections}>
			<span>{_('OperateTimezone')}</span>
			<div>
				<Select
					id='relayLogicOperateTimeZoneDropdown'
					className={cx(styles.select)}
					options={options}
					onChange={value => {
						if (Number(value) !== 0) {
							setPreviousSelections({ ...previousSelections, Operate: Number(value) });
						}

						useDoorContext ? dispatcherDoor(setOperateTimeZone(Number(value))) : dispatcher(setOperateTimeZone(Number(value)));
					}}
					key='operateTimeZone'
					value={selections.operateTimeZone.toString()}
					disabled={unpermitted.operateTimeZone ? true : false}
					getPopupContainer={() => document.getElementById('logicRelayContainer')}
				/>
			</div>
		</div>
	);
};

export { Operate };

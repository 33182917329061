import { Button, Checkbox, Input, Radio, RadioChangeEvent, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import { Moment } from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { credentialApi } from '../../../../../../api';
import { cleanCultureDateFormat } from '../../../../../../Helper';
import { User } from '../../../../../../model/AccountModel';
import { DatePicker, ModalConfirmation } from '../../../../../common';
import styles from '../../../credentialmodal.module.scss';
import {
	setGeneralTabActivationExpirationPropertyAction,
	setOptionsTabPropertyAction,
	setRootModelPropertyAction
} from '../../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../../credentialTemplateContext/context';
import { GeneralTabActivationExpirationProperty } from '../../../CredentialTemplateModalModels';
import { getMomentDate } from '../../../Helper';
import { ExpirationDateModal } from '../ExpirationDateModal/ExpirationDateModal';

const user: User = getUser();
const cultureDateFormat: string = user.cultureDateFormat;

const ActivationExpirationSection: React.FC = () => {
	const {
		credentialInitialState: {
			configData: { IsDeviceAdminCredentialTemplate },
			fetchedCredential,
			generalTabState: {
				credentialId,
				informationSectionState: { credentialTemplates, linkToCredential, isLinkToConfirmed },
				activationExpirationSectionState: { activationDate, expiration, expirationFunction, expirationUsed, hostExpirationUDF, hostExpirationInterval },
			},
			modelEstablished,
			optionsTabState: { disableAction },
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	const dateFormat = cleanCultureDateFormat(cultureDateFormat);

	const [activationDateD, setActivationDateD] = useState(false);
	const [expirationD, setExpirationD] = useState(!expirationUsed);
	const [openExpirationModal, setOpenExpirationModal] = useState(false);
	const [expirationFunctionD, setExpirationFunctionD] = useState(!expirationUsed);
	const [expirationUsedD, setExpirationUsedD] = useState(false);
	const [expiryButtonH, setExpiryButtonH] = useState(true);
	const [expirationUsedChanged, setExpirationUsedChanged] = useState<boolean>(undefined);
	const [mActivationDate, setMActivationDate] = useState<Moment>(getMomentDate(activationDate || '', dateFormat));
	const [mExpiration, setMExpiration] = useState<Moment>(getMomentDate(expiration || '', dateFormat));
	const firstRender = useRef<boolean>(true);

	useEffect(() => {
		const lActivationDate = getMomentDate(activationDate || '', dateFormat).toISOString(true);
		let lExpiration = '';
		if (hostExpirationUDF > 0 || hostExpirationInterval !== 0) {
			lExpiration = expiration;
		} else {
			lExpiration = getMomentDate(expiration || '', dateFormat).toISOString(true);
		}
		dispatcher(setGeneralTabActivationExpirationPropertyAction({ activationDate: lActivationDate, expiration: lExpiration }));
	}, []);

	useEffect(() => {
		if (!firstRender.current) {
			expirationChanged();
		} else {
			firstRender.current = false;
		}
	}, [expirationUsed]);

	useEffect(() => {
		if (isLinkToConfirmed !== undefined) linkToChkChanged();
	}, [isLinkToConfirmed]);

	useEffect(() => {
		if (fetchedCredential && modelEstablished) {
			linkToChkChanged();
		}
	}, [fetchedCredential, modelEstablished]);

	useEffect(() => {
		setMActivationDate(getMomentDate(activationDate || '', dateFormat));
		setMExpiration(getMomentDate(expiration || '', dateFormat));
		expirationChanged();
	}, [activationDate, expiration]);

	const linkToChkChanged = () => {
		if (credentialTemplates?.length > 0) {
			if (linkToCredential) {
				toggleDisableAll(true);
			} else {
				toggleDisableAll(false);
				expirationChanged(true);
			}
		}
	};

	const handleChangeGeneralTabProperty = (value: GeneralTabActivationExpirationProperty) => {
		dispatcher(setGeneralTabActivationExpirationPropertyAction(value));
		dispatcher(setRootModelPropertyAction({ hasChanged: true }));
	};

	const showDisableCredentialConfirmation = () => {
		ModalConfirmation({
			cancelText: _('No'),
			okText: _('Yes'),
			width: '600px',
			content: (
				<div data-test-id='DisabledCredentialWarningModal'>
					<label htmlFor=''>{`${_('DisableCredentialOption')} `}</label>
					<label htmlFor=''>{_('DoYouWantToReEnableCredential')}</label>
				</div>
			),
			onConfirm: () => {
				batch(() => {
					dispatcher(setRootModelPropertyAction({ hasChanged: true }));
					dispatcher(setOptionsTabPropertyAction({ disableAction: false }));
				});
			},
		});
	};

	const expirationChanged = (linkToChange?: boolean /* , isLinkedTo?: boolean*/) => {
		if (expirationUsed) {
			// const booleanIsLinkedTo = linkToCredential ? true : false;
			setExpirationFunctionD(linkToCredential);

			if (IsDeviceAdminCredentialTemplate || hostExpirationUDF !== 0) {
				setExpirationD(true);
			} else {
				setExpirationD(linkToCredential);
			}
			if (!linkToCredential) setExpiryButtonH(false);
		} else {
			setExpirationFunctionD(true);
			setExpirationD(true);
			setExpiryButtonH(true);

			if (!linkToChange && disableAction && !expirationUsed && !IsDeviceAdminCredentialTemplate) {
				showDisableCredentialConfirmation();
			}
		}
	};

	const toggleDisableAll = (disabled: boolean) => {
		setActivationDateD(disabled);
		setExpirationD(disabled);
		setExpirationFunctionD(disabled);
		setExpirationUsedD(disabled);
		setExpiryButtonH(disabled);
	};

	const showCredentialTemplateExpirationConfirmation = () => {
		ModalConfirmation({
			cancelText: _('No'),
			okText: _('Yes'),
			width: '600px',
			content: (
				<div>
					<label htmlFor=''>{`${_('CredentialTemplateExpirationConfirmation')} `}</label>
					<label htmlFor=''>{_('DoYouWantToProceed')}</label>
				</div>
			),
			onConfirm: () => setOpenExpirationModal(true),
		});
	};

	const handleOnClickExpireButton = async () => {
		if (IsDeviceAdminCredentialTemplate) {
			const linkedCredentialCount = await credentialApi.getDeactivatedLinkCredentialCount(credentialId);

			if (linkedCredentialCount === 0) {
				setOpenExpirationModal(true);
			} else {
				showCredentialTemplateExpirationConfirmation();
			}
		} else {
			setOpenExpirationModal(true);
		}
	};

	const handleOnChangeExpirationDate = (date: Moment) => {
		handleChangeGeneralTabProperty({ expiration: date ? date.toISOString(true) : null });
	};

	const handleOnExpirationUsedChanged = (e: CheckboxChangeEvent) => {
		setExpirationUsedChanged(!expirationUsedChanged);
		handleChangeGeneralTabProperty({ expirationUsed: e.target.checked });
	};

	return (
		<>
			<div className={cx(styles.containerLegend, styles.rightContainerLegend)}>
				<fieldset className={styles.fieldset}>
					<legend className={styles.legend}>{`${_('Activation')}/${_('ExpirationDate')}`}</legend>
					<div className={styles.form}>
						<div className={styles.sections}>
							<label htmlFor='activationDate'>{_('Activate')}:</label>
							<DatePicker
								allowClear={false}
								className={styles.input}
								disabled={activationDateD}
								format={dateFormat}
								getPopupContainer={(triggerElement: HTMLElement) => triggerElement.parentElement}
								id='activationDate'
								name='activationDate'
								onChange={(momentDate: Moment) => {
									handleChangeGeneralTabProperty({ activationDate: momentDate ? momentDate.toISOString(true) : null });
								}}
								showTime
								value={mActivationDate}
							/>
						</div>
						<div className={styles.sections} style={{ gridTemplateColumns: '110px 230px 90px' }}>
							<Checkbox
								checked={expirationUsed}
								disabled={expirationUsedD}
								id='expirationUsed'
								name='ExpirationUsed'
								onChange={handleOnExpirationUsedChanged}>
								{_('Expire')}
							</Checkbox>
							{hostExpirationUDF > 0 || hostExpirationInterval !== 0 ? (
								<Input
									className={styles.input}
									id={hostExpirationInterval !== 0 ? 'expirationOnShortcut' : 'expirationOnUDF'}
									maxLength={50}
									name='expirationOnUDF'
									disabled
									value={expiration}
								/>
							) : (
								<DatePicker
									allowClear={false}
									aria-label={_('Expiration')}
									className={styles.input}
									disabled={expirationD}
									format={dateFormat}
									getPopupContainer={(triggerElement: HTMLElement) => triggerElement.parentElement}
									id='expiration'
									name='expiration'
									onChange={handleOnChangeExpirationDate}
									showTime
									value={mExpiration}
								/>
							)}
							<Button
								className={cx({ [styles.hidden]: expiryButtonH })}
								htmlType='button'
								id='ExpiryButton'
								style={{ marginLeft: 15, marginTop: 0 }}
								onClick={handleOnClickExpireButton}>
								...
							</Button>
						</div>
						<div className={styles.sections} style={{ marginTop: 10 }}>
							<div></div>
							<div>
								<label>{`${_('OnExpiration')}:`}</label>
								<div style={{ marginTop: 8 }}>
									<Radio.Group
										defaultValue={0}
										disabled={expirationFunctionD}
										id='expirationFunction'
										name='expirationFunction'
										onChange={(e: RadioChangeEvent) => {
											handleChangeGeneralTabProperty({ expirationFunction: e.target.value });
										}}
										value={expirationFunction}>
										<Space direction='vertical'>
											<Radio value={0}>{_('Disable')}</Radio>
											<Radio value={1}>{_('DeleteFromControllers')}</Radio>
											<Radio value={3}>{_('DisableAndUnassign')}</Radio>
										</Space>
									</Radio.Group>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
			{openExpirationModal && <ExpirationDateModal onCloseModal={() => setOpenExpirationModal(false)} />}
		</>
	);
};

export default ActivationExpirationSection;

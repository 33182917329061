import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { deviceAdminApi } from '../../../../api/DeviceAdminApi';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	RolesColumn,
	SelectOptions,
	SelectedDeviceKeys,
} from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	CurrentDeviceControlObj,
	DeviceObjectType,
	OptionsButtonBuilder,
	UserRole,
	VelocityConfigurationModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { selectCurrentDevice } from '../../../../store/common/selectors';
import {
	clearVelocityConfigurationSelectionsAction,
	setRolesBy,
	setRolesByData,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
	setVelocitySelectedRowKeysPaginationAction,
} from '../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfiguration } from '../../../../store/deviceControl/selectors';
import { ModalConfirmationList, SearchColumn } from '../../../common';
import { EditableCell } from '../../../common/EditableCell/EditableCell';
import { AddRolesModal } from './AddRolesModal/AddRolesModal';
import styles from './roles.module.scss';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Roles);
const isUserAdmin: boolean = user.isAdmin;
const scroll: ScrollType = { x: 379, y: 430 };
const maxLength: number = 20;
const deviceObjectType: DeviceObjectType = DeviceObjectType.Roles;

const createButtonOptions = (isMoreThanOneSelected: boolean, isAdminRoleSelected: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				label: _('Copy'),
				disabled: !isUserAdmin || isMoreThanOneSelected,
				value: 'copy',
				title: getPermissionErrorMessage(isUserAdmin),
			},
			{
				label: _('Rename'),
				disabled: !isUserAdmin || isMoreThanOneSelected || isAdminRoleSelected,
				value: 'rename',
				title: getPermissionErrorMessage(isUserAdmin, false, isMoreThanOneSelected),
			},
			{
				label: _('Delete'),
				disabled: !isUserAdmin || (!isMoreThanOneSelected && isAdminRoleSelected),
				value: 'delete',
				title: getPermissionErrorMessage(isUserAdmin),
			},
		],
	};
};

const Roles: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const [isCopyMode, setIsCopyMode] = useState<boolean>(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

	const { isEditMode, roles, isFilterMode }: VelocityConfigurationModel = useStoreSelector(selectVelocityConfiguration);
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);
	const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<RolesColumn[]>([]);

	const isSearchPerformed = useRef<boolean>(false);
	const redirectPage = useRef<boolean>(false);

	useEffect(() => {
		return () => {
			clearSelection();
		};
	}, []);

	useEffect(() => {
		const mapRes: RolesColumn[] = createData();
		setTableData(mapRes);
	}, [JSON.stringify(roles)]);

	useEffect(() => {
		if (currentDevice.Address === 'passback') {
			setSelectedRowKeys([currentDevice.Id.toString()]);
			setCurrentDevice({
				...currentDevice,
				Address: '',
			});
		}
	}, [JSON.stringify(currentDevice)]);

	const clearSelection = () => {
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: deviceObjectType }));
		setSelectedRowKeys([]);
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	const onRemoveRoleLocks = async (role: UserRole): Promise<void> => {
		await deviceAdminApi.unlockComponentDeviceAdmin(role.Id, SecuredComponents.Roles);
	};

	const onDeleteRoleRequest = async (role: UserRole): Promise<void> => {
		const response = await deviceAdminApi.deleteRole(role.Id, role.Name);
		if (handleResponse(response)) {
			return;
		}

		onRemoveRoleLocks(role);
	};

	const onDelete = async (selectedRoles: UserRole[]): Promise<void> => {
		for (const role of selectedRoles) {
			await onDeleteRoleRequest(role);
		}
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const disabled: boolean = editingKey !== '';
	const isMoreThanOneSelected: boolean = selectedRowKeys.length > 1;
	const isAdminRoleSelected: boolean = selectedRowKeys.find(key => Number(key) === 1) !== undefined;
	const items = createButtonOptions(isMoreThanOneSelected, isAdminRoleSelected);

	const handleOnSearchResults = (searchedValue: string) => {
		dispatch(setRolesBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	const columns: ColumnsProps<RolesColumn>[] = [
		{
			...buildColumn(_('Name'), 'Name', '220px', 'start'),
			editable: true,
			sorter: (a, b) => a.Name.localeCompare(b.Name),
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: undefined,
				label: undefined,
				notVisible: undefined,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
		{
			...buildColumn(_('Description'), 'Description', 'auto', 'start'),
			editable: false,
			sorter: (a, b) => a.Description.localeCompare(b.Description),
		},
	];

	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const onChangeSelection = (key: BaseColumns) => {
		const roleId = key.key.toString();
		if (selectedRowKeys.findIndex(key => key === roleId) === -1) {
			const cloneState: UserRole[] = roles.map(x => (x.Id.toString() === roleId ? { ...x, checked: true } : { ...x, checked: false }));
			batch(() => {
				dispatch(setRolesByData(cloneState));
				setSelectedRowKeys([roleId]);
			});
		}
	};

	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];
	const isEditing = (record: RolesColumn) => record.key.toString() === editingKey;

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: RolesColumn) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						//options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: RolesColumn) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						//options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const getSelectedRoles = async (): Promise<SelectedDeviceKeys[]> => {
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);
		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const handleActionScope = async (key: string) => {
		const selectedRolesKeys = await getSelectedRoles();
		const selectedRoles = selectedRolesKeys.map(x => ({ Id: x.Id, Name: x.Name } as UserRole));
		switch (key) {
			case 'copy': {
				const isLocked: boolean = await lockedValidation(deviceObjectType, selectedRoles[0].Id, false);
				if (isLocked) {
					return;
				}
				batch(() => {
					setIsAddModalOpen(true);
					setIsCopyMode(true);
				});
				break;
			}
			case 'delete': {
				const operatorAction: string = _('Delete');
				const selectedRolesFiltered = selectedRoles.filter(sr => sr.Name !== 'Administrators');
				ModalConfirmationList({
					type: operatorAction,
					translationKey: 'AreYouSureYouWantToTheseRoles',
					data: selectedRolesFiltered,
					onConfirm: () =>
						onDelete(selectedRolesFiltered).then(() => {
							batch(() => {
								setSelectedRowKeys([]);
								dispatch(setVelocitySelectedRowKeysPaginationAction([]));
								dispatch(setRolesBy(tablePaginationSetting));
							});
						}),
				});
				break;
			}
			case 'rename': {
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: RolesColumn = tableData.find(row => row.key.toString() === selectedRoles[0].Id.toString());
				edit(findKey);
				const cloneState: UserRole[] = changeStateProps(selectedRoles[0].Id, { editable: true });
				dispatch(setRolesByData(cloneState));
				break;
			}
		}
	};

	const changeStateProps = (deviceId: number, commandsSet: Partial<UserRole>): UserRole[] => {
		const cloneState: UserRole[] = [...roles];
		const index = cloneState.findIndex(x => x.Id === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...commandsSet });
		}

		return cloneState;
	};

	const edit = (record: RolesColumn) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const resetSearchColumn = () => {
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getRoleIds();
		const roleIds = response?.map<string>(x => x.toString());
		return getUniqueValuesArray(newSelectedRowKeys, roleIds);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: UserRole[] = roles.map(x => ({ ...x, checked: selectedRowKeys.findIndex(r => r === x.Id) !== -1 }));
		dispatch(setRolesByData(cloneState));
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);
		const cloneState: UserRole[] = roles.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		batch(() => {
			dispatch(setRolesByData(cloneState));
			setSelectedRowKeys(newSelectedRowKeys);
			setIsTableLoading(false);
		});
	};

	const handleSelectInvert = () => {
		const dataKeys = roles.map<React.Key>(x => x.Id?.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		const cloneState: UserRole[] = roles.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.Id?.toString()) !== -1,
		}));

		dispatch(setRolesByData(cloneState));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleOnSaveEditRow = async (roleId: number) => {
		const isLocked: boolean = await lockedValidation(deviceObjectType, roleId, false);
		if (isLocked) {
			setEditingKey('');
			const cloneState: UserRole[] = changeStateProps(roleId, { editable: false });
			dispatch(setRolesByData(cloneState));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(deviceObjectType, name.trim(), roleId);

		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setRolesBy(tablePaginationSetting));
			return;
		}

		setEditingKey('');
		dispatch(setVelocityConfigurationEditMode(false));
		resetSearchColumn();
		setRedirectPage(true);
		dispatch(setRolesBy(tablePaginationSetting));
	};

	const handleOnCancelEditRow = (roleId: number) => {
		dispatch(setVelocityConfigurationEditMode(false));
		const cloneState: UserRole[] = changeStateProps(roleId, { editable: false });
		dispatch(setRolesByData(cloneState));
		setEditingKey('');
	};

	const createActionElement = (role: UserRole): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (role.editable) {
			content = (
				<>
					<Button id='renameSaveButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(role.Id)}>
						{_('Save')}
					</Button>
					<Button id='renameCancelButton' key='cancel' onClick={() => handleOnCancelEditRow(role.Id)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}
		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): RolesColumn[] => {
		if (roles.length > 0) {
			return roles.map<RolesColumn>((data: UserRole) => ({
				key: data.Id.toString(),
				Action: createActionElement(data),
				Name: data.Name,
				Description: data.Description,
			}));
		}

		return [];
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} onClick={x.onClick} disabled={x.disabled} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const buttons: ButtonBuilder[] = [
		{
			label: _('AddNewRole'),
			icon: <PlusOutlined />,
			disabled: !componentPermission.canAdd || isEditMode,
			onClick: () => {
				batch(() => {
					setIsAddModalOpen(true);
					setIsCopyMode(false);
				});
			},
			id: 'rolesAddButton',
			title: getPermissionErrorMessage(componentPermission.canAdd),
		},
	];

	const rowSelection: TableRowSelection<RolesColumn> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `rolesTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`rolesTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectRoles')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<RolesColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setRolesBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<div className={styles.container}>
			{/* <div className={styles.buttonContainer}>
				<ButtonDropdown
					id={'rolesTableActionDropdown'}
					disabled={disabledActionButtons}
					menuOptions={items.options}
					icon={<CaretDownOutlined />}
					labelIcon={items.labelOrIcon}
					onClickOption={handleActionScope}
				/>
				{buttonsBuilder(buttons)}
			</div> */}
			<Form form={form}>
				<Table
					id='rolesTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					pagination={pagination}
					scroll={scroll}
					size='small'
					className={styles.rolesTable}
					//rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
				/>
			</Form>
			{isAddModalOpen && (
				<AddRolesModal
					isCopyMode={isCopyMode}
					copyFromRoleId={isCopyMode ? (selectedRowKeys[0] as number) : undefined}
					onConfirm={() => {
						batch(() => {
							setIsAddModalOpen(false);
							setSelectedRowKeys([]);
							dispatch(setVelocitySelectedRowKeysPaginationAction([]));
							dispatch(setRolesBy(tablePaginationSetting));
						});
					}}
					onCancel={() => setIsAddModalOpen(false)}
				/>
			)}
		</div>
	);
};

export { Roles };

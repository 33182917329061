import { Button, Checkbox } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { filterOption } from '../../../Helper';
import {
	CriteriaField,
	CriteriaFunctionValue,
	CriteriaInfo,
	CriteriaOperatorFunction,
	FieldDataType,
	SearchEqualToKey,
	WithinTimes,
} from '../../../model/CommonModel';
import { ModalWarning, Select, SelectOption } from '../../common';
import { ReportCriteriaType } from '../../reports/Reports/ReportTabs/ReportCriteriaType/ReportCriteriaType';
import { criteria, functionCriteria, isOrEqualToConverter, withinTimes } from '../../reports/Reports/ReportTabs/criteriaOptions';
import styles from './buildCriteria.module.scss';

type Props = {
	onAddRequestCriteria: (request: CriteriaInfo) => void;
	columnData: CriteriaField[];
	lastKey: number;
	criteriaToEdit: CriteriaInfo;
	hasUDFChanged: boolean;
	isDisabled: boolean;
	onResetUDFChanged: () => void;
	getUniqueColumnsValues: (criteriaField: CriteriaField, value: string) => Promise<string[]>;
	getUniqueColumnsValuesPagination: (criteriaField: CriteriaField, currentPage: number, pageSize: number) => Promise<string[]>;
	addButtonAtBottom: boolean;
};

const BuildCriteria: React.FC<Props> = ({
	criteriaToEdit,
	onAddRequestCriteria,
	columnData,
	lastKey,
	hasUDFChanged,
	isDisabled,
	onResetUDFChanged,
	getUniqueColumnsValues,
	getUniqueColumnsValuesPagination,
	addButtonAtBottom,
}) => {
	const [criteriaValue, setCriteriaValue] = useState<CriteriaFunctionValue>();
	const [functionValue, setFunctionValue] = useState<CriteriaOperatorFunction>(CriteriaOperatorFunction.is);
	const [withinValue, setWithinValue] = useState<WithinTimes>(WithinTimes.anHour);
	const [fieldCriteriaSelected, setFieldCriteriaSelected] = useState<CriteriaField>();
	const [expressionCriteria, setExpressionCriteria] = useState<{ primaryInput: string; secondaryInput: string }>({
		primaryInput: null,
		secondaryInput: null,
	});
	const [isOrEqualTo, setIsOrEqualTo] = useState<boolean>();
	const [optionsForEqualTo, setOptionsForEqualTo] = useState<SelectOption>([]);
	const [optionsForEqualToBySearchText, setOptionsForEqualToBySearchText] = useState<SelectOption>([]);
	const [searchEqualToKey, setSearchEqualToKey] = useState<SearchEqualToKey>(SearchEqualToKey.none);
	const [selectCriteria, setSelectCriteria] = useState<number>(1);
	const lastTotalItems = useRef<number>(-1);

	useEffect(() => {
		if (criteriaToEdit) {
			const { Function, FunctionOperator, Within } = criteriaToEdit.criteriaSetup;
			const isLessOrGreaterEqual =
				Function === CriteriaFunctionValue.greaterThanOrEqualTo
					? CriteriaFunctionValue.greaterThan
					: Function === CriteriaFunctionValue.lessThanOrEqualTo
					? CriteriaFunctionValue.lessThan
					: Function;
			setIsOrEqualTo(Function === CriteriaFunctionValue.greaterThanOrEqualTo || Function === CriteriaFunctionValue.lessThanOrEqualTo);
			setCriteriaValue(isLessOrGreaterEqual);
			setFunctionValue(FunctionOperator);
			setWithinValue(Within);
			setFieldCriteriaSelected(columnData.find(x => x.FieldId === criteriaToEdit.FieldId));
			setExpressionCriteria({ primaryInput: criteriaToEdit.PrimaryInput, secondaryInput: criteriaToEdit.SecondaryInput });
			onResetUDFChanged();
		} //reset form when criteria to edit is canceled or has been finished to edit
		else if (criteriaToEdit === null) {
			onResetForm();
		}
	}, [criteriaToEdit]);

	useEffect(() => {
		if (hasUDFChanged) {
			setFieldCriteriaSelected(undefined);
			setFunctionValue(CriteriaOperatorFunction.is);
			setWithinValue(WithinTimes.anHour);
			onResetUDFChanged();
		}
	}, [hasUDFChanged]);

	useEffect(() => {
		if (criteriaValue === CriteriaFunctionValue.equalTo) {
			setOptionsForEqualTo([]);
			setOptionsForEqualToBySearchText([]);
			setExpressionCriteria({ primaryInput: null, secondaryInput: null });
			lastTotalItems.current = -1;
		}
	}, [selectCriteria]);

	const handleOnSearchCriteria = (value: string) => {
		setSearchEqualToKey(SearchEqualToKey.searchByText);
		getUniqueColumnsValues(fieldCriteriaSelected, value)
			.then(response => setOptionsForEqualToBySearchText(response.map(x => ({ value: x, label: x }))))
			.finally(() => setSearchEqualToKey(SearchEqualToKey.none));
	};

	const handleOnScrollPagination = (currentPage: number, reset?: boolean) => {
		const pageSize = 300;
		setSearchEqualToKey(SearchEqualToKey.searchByPagination);
		if (lastTotalItems.current !== 0 || currentPage === 1) {
			getUniqueColumnsValuesPagination(fieldCriteriaSelected, currentPage, pageSize)
				.then(response => {
					if (response.length > 0) {
						if (reset) {
							setOptionsForEqualTo(response.map(x => ({ value: x, label: x })));
						} else {
							setOptionsForEqualTo(prevState => [...prevState, ...response.map(x => ({ value: x, label: x }))]);
						}
					}

					lastTotalItems.current = response.length;
				})
				.finally(() => setSearchEqualToKey(SearchEqualToKey.none));
		} else {
			setSearchEqualToKey(SearchEqualToKey.none);
		}
	};

	const handleSelectCriteriaField = (value: string) => {
		const fieldId = Number(value);
		const findValue = columnData.find(x => x.FieldId === fieldId);
		setCriteriaValue(undefined);
		setFieldCriteriaSelected(findValue);
		//reset value to search on field type has changed
		setExpressionCriteria({ primaryInput: null, secondaryInput: null });
	};

	const handleOnChangeInput = (start: string, end?: string) => {
		if (start !== undefined && end !== undefined) {
			setExpressionCriteria({ primaryInput: start, secondaryInput: end });
		} else {
			setExpressionCriteria(value => {
				if (start !== undefined) {
					return {
						...value,
						primaryInput: start,
					};
				} else if (end !== undefined) {
					return {
						...value,
						secondaryInput: end,
					};
				}
				return { ...value };
			});
		}
	};

	const handleAddReportCriteria = () => {
		const newKey = criteriaToEdit ? criteriaToEdit.key : lastKey + 1;
		const { criteriaConverted, criteriaText, newFunctionValue } = isOrEqualToConverter(criteriaValue, isOrEqualTo, functionValue);
		const tryToFindCriteria = criteria.find(x => x.value === criteriaText);

		let criteriaNameString = '';
		if (tryToFindCriteria) {
			if (criteriaValue === CriteriaFunctionValue.within) {
				criteriaNameString = criteria.find(x => x.value === CriteriaFunctionValue.between).label;
			} else {
				criteriaNameString = tryToFindCriteria.label;
			}
		} else if (criteriaText === CriteriaFunctionValue.greaterThanOrEqualTo) {
			criteriaNameString = _('greaterthanorequalto');
		} else {
			criteriaNameString = _('lessthanorequalto');
		}

		const newCriteria: CriteriaInfo = {
			FieldId: fieldCriteriaSelected.FieldId,
			Function: criteriaText,
			FunctionOperator: newFunctionValue,
			OrNextCriteria: criteriaToEdit?.OrNextCriteria ?? false,
			PrimaryInput: expressionCriteria.primaryInput,
			SecondaryInput: expressionCriteria.secondaryInput,
			fieldName: fieldCriteriaSelected.DisplayName,
			criteriaName: criteriaNameString,
			key: newKey,
			Type: fieldCriteriaSelected.Type,
			functionName: functionCriteria.find(x => x.value === newFunctionValue).label,
			criteriaSetup: {
				Function: criteriaConverted,
				FunctionOperator: functionValue,
				Within: withinValue,
			},
			IsUDF: fieldCriteriaSelected.UDFNumber !== null,
			IsEqualTo: isOrEqualTo,
			UDFNumber: fieldCriteriaSelected.UDFNumber,
		};

		if (
			newCriteria.PrimaryInput?.includes(`"`) ||
			newCriteria.SecondaryInput?.includes(`"`) ||
			newCriteria.PrimaryInput?.includes(`--`) ||
			newCriteria.SecondaryInput?.includes(`--`)
		) {
			ModalWarning({ content: <label>{_('InvalidQuerySelection')}</label>, okText: _('Ok') });
			return;
		}
		if (newCriteria.Function === CriteriaFunctionValue.between || criteriaValue === CriteriaFunctionValue.within) {
			if (newCriteria.Type === FieldDataType.DateTime) {
				const startDate = moment(newCriteria.PrimaryInput);
				const endDate = moment(newCriteria.SecondaryInput);

				if (endDate.isSameOrBefore(startDate)) {
					ModalWarning({ content: <label>{_('ReportInputError_2')}</label>, okText: _('Ok') });
					return;
				}
			}
		}

		onAddRequestCriteria(newCriteria);
		onResetForm();
	};

	const onResetForm = () => {
		setCriteriaValue(undefined);
		setFunctionValue(CriteriaOperatorFunction.is);
		setWithinValue(WithinTimes.anHour);
		setFieldCriteriaSelected(undefined);
		setExpressionCriteria({ primaryInput: null, secondaryInput: null });
		setOptionsForEqualTo([]);
		setOptionsForEqualToBySearchText([]);
	};

	const mapColumnDataToOption: SelectOption = columnData.map(x => ({ label: x.DisplayName, value: x.FieldId }));
	const criteriaBasedFieldType: SelectOption = criteria.map(x => ({
		label: x.label,
		value: x.value,
		disabled: !x.fieldType.some(f => f === fieldCriteriaSelected?.Type),
	}));
	const isDisabledAddButtonCriteria = (): boolean => {
		const isDateTimeOrBoolean: boolean = fieldCriteriaSelected?.Type === FieldDataType.DateTime || fieldCriteriaSelected?.Type === FieldDataType.Boolean;

		if (!isDateTimeOrBoolean && criteriaValue === CriteriaFunctionValue.equalTo && expressionCriteria.primaryInput === '') {
			return false;
		} else if (!fieldCriteriaSelected) {
			return true;
		} else if (!expressionCriteria.primaryInput) {
			return true;
		} else if (!criteriaValue) {
			return true;
		} else if (
			(criteriaValue === CriteriaFunctionValue.between || criteriaValue === CriteriaFunctionValue.within) &&
			(!expressionCriteria.primaryInput || !expressionCriteria.secondaryInput)
		) {
			return true;
		}
		return false;
	};
	const buildClass = cx({
		[styles.threeCols]: criteriaValue === CriteriaFunctionValue.between || criteriaValue === CriteriaFunctionValue.within,
		[styles.fourCols]:
			criteriaValue === CriteriaFunctionValue.equalTo &&
			fieldCriteriaSelected?.Type !== FieldDataType.DateTime &&
			fieldCriteriaSelected?.Type !== FieldDataType.Boolean,
		[styles.oneCol]:
			(criteriaValue !== CriteriaFunctionValue.between &&
				criteriaValue !== CriteriaFunctionValue.within &&
				criteriaValue !== CriteriaFunctionValue.equalTo) ||
			(criteriaValue === CriteriaFunctionValue.equalTo && fieldCriteriaSelected?.Type === FieldDataType.DateTime) ||
			(criteriaValue === CriteriaFunctionValue.equalTo && fieldCriteriaSelected?.Type === FieldDataType.Boolean),
	});
	return (
		<div className={cx(styles.criteria, { [styles.addButtonAtBottom]: addButtonAtBottom })}>
			<div>
				<label htmlFor='SelectField'>{_('Field')}</label>
				<Select
					ariaAttributes={{
						'aria-label': _('Field'),
						'aria-owns': 'SelectField',
						'aria-activedescendant': 'SelectField',
						'aria-controls': 'SelectField',
						'aria-expanded': 'false',
					}}
					id='SelectField'
					value={fieldCriteriaSelected?.FieldId.toString()}
					options={mapColumnDataToOption}
					onChange={handleSelectCriteriaField}
					disabled={isDisabled}
					showSearch={true}
					filterOption={filterOption}
					maxSearchLength={100}
					placeholder={_('SelectField')}
				/>
				{!addButtonAtBottom && (
					<Button
						id='EnrollmentUpdateAddButton'
						disabled={isDisabledAddButtonCriteria() || isDisabled}
						onClick={handleAddReportCriteria}
						title={isDisabled ? _('ThereIsNoReportSelected') : ''}>
						<label htmlFor='EnrollmentUpdateAddButton'>{criteriaToEdit ? _('Update') : _('Add')}</label>
					</Button>
				)}
			</div>
			<div>
				<label htmlFor='CriteriaFunction'>{_('Function')}</label>
				<Select
					ariaAttributes={{
						'aria-label': _('IsFunction'),
						'aria-owns': 'CriteriaFunction',
						'aria-activedescendant': 'CriteriaFunction',
						'aria-controls': 'CriteriaFunction',
						'aria-expanded': 'true',
					}}
					id='CriteriaFunction'
					value={functionValue?.toString()}
					options={functionCriteria}
					onChange={value => {
						setFunctionValue(Number(value) as CriteriaOperatorFunction);
					}}
					disabled={isDisabled}
				/>
				<Select
					ariaAttributes={{
						'aria-label': _('CriteriaFunction'),
						'aria-owns': 'CriteriaFunctionValue',
						'aria-activedescendant': 'CriteriaFunctionValue',
						'aria-controls': 'CriteriaFunctionValue',
						'aria-expanded': 'false',
					}}
					className={styles.selectCriteriaFunction}
					id='CriteriaFunctionValue'
					options={criteriaBasedFieldType}
					value={criteriaValue?.toString()}
					onChange={value => {
						const reportCriteria: CriteriaFunctionValue = Number(value) as CriteriaFunctionValue;
						setCriteriaValue(reportCriteria);
						setExpressionCriteria({ primaryInput: null, secondaryInput: null });
						if (reportCriteria === CriteriaFunctionValue.equalTo) {
							setOptionsForEqualTo([]);
							setOptionsForEqualToBySearchText([]);
						}
					}}
					disabled={isDisabled}
				/>
				{(criteriaValue === CriteriaFunctionValue.greaterThan || criteriaValue === CriteriaFunctionValue.lessThan) && (
					<Checkbox id='IsOrEqualTo' checked={isOrEqualTo} onChange={e => setIsOrEqualTo(e.target.checked)}>
						<label htmlFor='IsOrEqualTo'>{_('orequalto')}</label>
					</Checkbox>
				)}
				{criteriaValue === CriteriaFunctionValue.within && (
					<Select
						ariaAttributes={{
							'aria-label': _('Within'),
							'aria-owns': 'SelectWithin',
							'aria-activedescendant': 'SelectWithin',
							'aria-controls': 'SelectWithin',
							'aria-expanded': 'true',
						}}
						id='SelectWithin'
						value={withinValue?.toString()}
						options={withinTimes}
						onChange={value => {
							setWithinValue(Number(value) as WithinTimes);
						}}
					/>
				)}
			</div>
			<div className={buildClass}>
				{criteriaValue && (
					<ReportCriteriaType
						options={optionsForEqualTo}
						optionsBySearchText={optionsForEqualToBySearchText}
						fieldType={fieldCriteriaSelected?.Type}
						criteria={criteriaValue}
						onChangeInput={handleOnChangeInput}
						onSearchSelect={handleOnSearchCriteria}
						onScrollPagination={handleOnScrollPagination}
						defaultPrimaryValue={expressionCriteria.primaryInput}
						defaultSecondaryValue={expressionCriteria.secondaryInput}
						onSelectCriteria={setSelectCriteria}
						selectCriteria={selectCriteria}
						searchEqualToKey={searchEqualToKey}
						withinTimes={withinValue}
						isEditing={criteriaToEdit ? true : false}
					/>
				)}
			</div>
			{addButtonAtBottom && (
				<div>
					<div></div>
					<Button
						id='CriteriaUpdateAddButton'
						disabled={isDisabledAddButtonCriteria() || isDisabled}
						onClick={handleAddReportCriteria}
						title={isDisabled ? _('ThereIsNoReportSelected') : ''}>
						<label htmlFor='CriteriaUpdateAddButton'>{criteriaToEdit ? _('Update') : _('Add')}</label>
					</Button>
				</div>
			)}
		</div>
	);
};

export { BuildCriteria };

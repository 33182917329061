import { AlarmModel } from './AlarmModel';
import { GridDataColumnItem, ResponseObject } from './CommonModel';
import { VelocityDevice } from './DeviceAdminModel';

export type EventConfiguration = {
	EventsPerPage: number;
	EventFilters: EventFilter[];
	Columns: GridDataColumnItem[];
	CustomFilterGroups: CustomFilterGroup[];
	SelectedCustomFilterGroups: CustomFilterGroup[];
};

export type CustomEventConfiguration = {
	FilterGroupSelected: number;
	Ports: VelocityDevice[];
	Controllers: VelocityDevice[];
	Xboxes: VelocityDevice[];
	Events: EventModel[];
	EventFilterGroups: EventFilterGroup[];
};

export type BuildCustomFilter = {
	CustomFilter: CustomFilterGroup;
};

export type EventModel = {
	Id: number;
	DefaultDescription: string;
	Description: string;
};

export type EventFilterGroup = {
	ObjectId: number;
	GroupName: string;
};

export type EventFilter = {
	Min: number;
	Max: number;
	EventFilterTypes: EventFilterTypes;
	VelocityEventType: VelocityEventType;
};

export type CustomFilterGroup = {
	FilterGroupId: number;
	GroupName: string;
	EventFilterClause: string;
} & ResponseObject;

export type EventCustomFilterGroup = {
	FilterType: number;
	CanApply: boolean;
	CustomFilters: CustomFilter[];
} & CustomFilterGroup;

export type CustomFilter = {
	FilterId?: number;
	FilterType?: number;
	Filter: string;
	Description: string;
	Sql?: string;
};

export type EventListResponseModel = {
	EventList: VelocityEventModel[];
	TotalRecords: number;
	TotalPages: number;
	PageNumber: number;
} & ResponseObject;

export type LiveEvents = {
	StatusSummaryHasChanges: boolean;
	StatusSummary: StatusSummary;
	EventSummary: LatestEventAlarmResponseModel;
};

export type EventSummary = {
	Items: EventSummaryItem[];
};

export type EventSummaryItem = {
	Count: number;
	EventColor: EventColors;
	EventType: VelocityEventType;
};

export type StatusSummary = {
	SDConnected: boolean;
	DTConnected: boolean;
	VergeConnected: boolean;
	NumAlarms: number;
	NumAckAlarms: number;
	ThreatLevel: number;
	ThreatLevelCaption: string;
	OffNormal: number;
	LastAlarmDescription: string;
	LastAlarmAddress: string;
	LastAlarmEventId: number;
	IsUpdated: boolean;
};

export type LatestEventAlarmResponseModel = {
	EventList: VelocityEventModel[];
	AlarmList: AlarmModel[];
	AckAlarmList: AlarmModel[];
	ClearedAlarmList: AlarmModel[];
};

export type VelocityEventModel = {
	OperatorId?: number;
	HostTime: Date;
	HardwareTime: Date;
	Description: string;
	Address: string;
	EventType: VelocityEventType;
	EventColor: EventColors;
	LogId: number;
	EventId: number;
	IsAlarm: boolean;
	HexColor: string;
	EventTypeTranslation: string;
};

export enum VelocityEventType {
	Unknown = 0,
	Alarm = 1,
	Transaction = 2,
	Internal = 3,
	External = 4,
	Miscellaneous = 5,
	Port = 6,
	XBOX = 7,
	Communication = 8, //Velocity Event viewer and some other places combine XBox events and Port events to display as "Communication" Events, for some reason.
	Software = 9,
	IntegratedAlarm = 10,
	IntegratedEvent = 11,
	EdgeProgramming = 12,
	CommandError = 13,
}

export enum EventColors {
	BackColor = 0,
	ForeColor,
	SuspendBackColor,
	Alarms,
	External,
	Internal,
	Misc,
	Port,
	Programming,
	Software,
	System,
	XBox,
}

export enum EventFilterTypes {
	All = 0,
	Software = 1,
	Access = 2,
	Internal = 3,
	External = 4,
	Alarm = 5,
	Programming = 6,
	Xbox = 7,
	Misc = 8,
	Ports = 9,
}

export enum EventFilterPage {
	None = 0,
	Enrollment,
	Alarms,
	Events,
	DeviceControl,
	StatusViewer,
	WhosInside,
	PhotoCallUp,
	Reporting,
	StatusDashboard,
	Graphics,
}

export enum EventViewerActions {
	Suspend = 0,
}

import { Button, Input, Spin, notification } from 'antd';
import cx from 'classnames';
import React, { CSSProperties, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { getNewId, handleResponse, removeCommasAndPercentSign } from '../../../Helper';
import { accountApi } from '../../../api';
import { CredentialCreateOptions, PublicKeyCredential } from '../../../model/AccountModel';
import { ResponseObjectEntity, ResponseStatusCode } from '../../../model/CommonModel';
import { Logger } from '../../../model/LoggingModel';
import { USBIcon } from '../../account/LogOnForm/LogOnIcons/logOnIcons';
import { buildAuthenticatorAttestationResponse, getConvertedSecurityOptions, handleNavigatorError } from '../../account/helpers';
import { Modal } from '../../common';
import styles from './registerSecurityKeyModal.module.scss';

type Props = {
	operatorId: number;
	operatorGuid: string;
	userName: string;
	setKeyName: React.Dispatch<React.SetStateAction<string>>;
	setKeyId: React.Dispatch<React.SetStateAction<string>>;
	existingKeyIds?: string[];
	handleOnCloseModal: () => void;
	handleOnSuccess: () => void;
	handleKeyNameVerification: (name: string) => Promise<ResponseObjectEntity<boolean>>;
};

const iconStyle: CSSProperties = { width: 40, height: 50 };

const RegisterSecurityKeyModal: React.FC<Props> = ({
	operatorId,
	operatorGuid,
	userName,
	handleOnCloseModal,
	handleOnSuccess,
	setKeyName: setNewKeyName,
	setKeyId: setNewKeyId,
	existingKeyIds,
	handleKeyNameVerification,
}) => {
	const [keyName, setKeyName] = useState<string>('');
	const [keyId, setKeyId] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [validationErrorMessage, setValidationErrorMessage] = useState<string>(undefined);
	const newCredentialRef = useRef<PublicKeyCredential>(undefined);
	const [showIntroductoryMessage, setShowIntroductoryMessage] = useState<boolean>(true);

	const handleOnRegisterKey = () => {
		setIsLoading(true);
		let successExit: boolean = false;
		handleKeyNameVerification(keyName?.trim()).then(response => {
			if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
				batch(() => {
					setValidationErrorMessage(response.ErrorMessage);
					setIsLoading(false);
				});
			} else if (!handleResponse(response) && response.Entity) {
				const data = buildAuthenticatorAttestationResponse(newCredentialRef.current);
				accountApi
					.registerSecurityKeyCache(data, keyName.trim(), keyId, operatorGuid)
					.then(res => {
						if (!handleResponse(res)) {
							notification.success({
								message: _('KeyRegisteredSuccessfully'),
							});
							successExit = true;
						}
					})
					.catch(err => {
						Logger.writeErrorLog(`${err.name}: ${err.message}`);
						notification.error({
							message: err.message,
						});
					})
					.finally(() => {
						if (successExit) {
							handleOnSuccess();
						} else {
							setIsLoading(false);
						}
					});
			}
		});
	};

	const makeOptions = () => {
		const newKeyId: string = getNewId(existingKeyIds);
		batch(() => {
			setIsLoading(true);
			setKeyId(newKeyId);
			setNewKeyId(newKeyId);
		});
		accountApi
			.retrieveCurrentSecurityKeyOptions(userName, newKeyId, operatorGuid, operatorId === 0)
			.then((makeCredentialOptions: ResponseObjectEntity<CredentialCreateOptions>) => {
				if (!handleResponse(makeCredentialOptions)) {
					const securityOptions = getConvertedSecurityOptions(makeCredentialOptions.Entity);

					navigator.credentials
						.create({
							publicKey: securityOptions,
						})
						.then((response: PublicKeyCredential) => {
							newCredentialRef.current = response;
							setShowIntroductoryMessage(false);
						})
						.catch(err => {
							handleNavigatorError(err);
						})
						.finally(() => setIsLoading(false));
				}
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
				setIsLoading(false);
			});
	};

	const handleOnChangeKeyName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		if (value !== undefined) {
			batch(() => {
				setKeyName(value);
				setNewKeyName(value?.trim());
			});
		}
	};

	const handleOnRegisterKeyFunction = newCredentialRef.current ? handleOnRegisterKey : makeOptions;

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={undefined}
			onClickOk={() => null}
			width={600}
			title={_('TwoFactorAuthentication')}
			visible={true}
			customZoomClass={styles.WithModalZoom}
			hideCloseButton>
			<div className={styles.content}>
				<div>
					{showIntroductoryMessage ? (
						<span>
							<p className={styles.alternativeMessage}>{`${_('AddSecurityKeyByOperatorMessage')} ${_('PressContinue')}`}</p>
						</span>
					) : (
						<span>
							<p className={styles.alternativeMessage}>{`${_('EnterSecurityKeyName')} ${_('PressSaveToFinalize')}`}</p>
						</span>
					)}
				</div>
				{newCredentialRef.current ? (
					<div className={styles.addKeyBody}>
						<div className={styles.addKeyIsConfigured}>
							<USBIcon style={iconStyle} />
							<span>{_('KeyIsConfigured')}</span>
						</div>
						<div className={cx(styles.addKeyNameContainer, { [styles.error]: validationErrorMessage })}>
							<label htmlFor='keyNameInput'>{_('Name')}</label>
							<Input id='keyNameInput' onChange={handleOnChangeKeyName} value={keyName} maxLength={50} />
							<div className={styles.addKeyErrorMessageContainer}>
								{validationErrorMessage && <span id='keyNameErrorMessage'>{_(validationErrorMessage)}</span>}
							</div>
						</div>
					</div>
				) : (
					<div>
						<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
							<div className={styles.spinContent}></div>
						</Spin>
					</div>
				)}
				<div className={styles.addKeyFooter}>
					<Button id='addKeyButton' type='primary' onClick={handleOnRegisterKeyFunction} loading={isLoading}>
						{showIntroductoryMessage ? _('Continue') : _('Save')}
					</Button>
					<Button id='cancelAddKeyButton' onClick={handleOnCloseModal} disabled={isLoading}>
						{_('Cancel')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export { RegisterSecurityKeyModal };

import { Checkbox } from 'antd';
import React from 'react';
import { OnPassbackViolationInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerPassbackSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerPassback } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectOnPassbackViolationInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../passback.module.scss';

const OnPassbackViolation: React.FC = () => {
	const onPassbackViolationInfo: OnPassbackViolationInfoStore = useStoreSelector<OnPassbackViolationInfoStore>(selectOnPassbackViolationInfo);
	const dispatch = useStoreDispatch();

	const handleChangeControllerPassBackSetup = (value: ControllerPassback) => {
		dispatch(
			setControllerPassbackSetupAction({
				...value,
			})
		);
	};

	const controllerPassbackViolationReportCheckboxId = 'controllerPassbackViolationReportCheckbox';
	const controllerPassbackViolationGenerateAlarmCheckboxId = 'controllerPassbackViolationGenerateAlarmCheckbox';
	const controllerPassbackViolationDenyAccessCheckboxId = 'controllerPassbackViolationDenyAccessCheckbox';

	return (
		<CardSection title={`${_('OnPassbackViolation')}:`}>
			<div className={styles.onPassbackViolation}>
				<Checkbox
					id={controllerPassbackViolationReportCheckboxId}
					onChange={e => handleChangeControllerPassBackSetup({ OnPassbackViolationReport: e.target.checked })}
					checked={onPassbackViolationInfo.reportEnablePassBackOccupancyTracking}>
					<label htmlFor={controllerPassbackViolationReportCheckboxId}>{_('ReportEnablePassBackOccupancyTracking')}</label>
				</Checkbox>
				<Checkbox
					id={controllerPassbackViolationGenerateAlarmCheckboxId}
					onChange={e => handleChangeControllerPassBackSetup({ OnPassbackViolationGenerateAlarm: e.target.checked })}
					checked={onPassbackViolationInfo.generateAlarm}>
					<label htmlFor={controllerPassbackViolationGenerateAlarmCheckboxId}>{_('GenerateAlarm')}</label>
				</Checkbox>
				<Checkbox
					id={controllerPassbackViolationDenyAccessCheckboxId}
					onChange={e => handleChangeControllerPassBackSetup({ OnPassbackViolationDenyAccess: e.target.checked })}
					checked={onPassbackViolationInfo.denyAccess}>
					<label htmlFor={controllerPassbackViolationDenyAccessCheckboxId}>{_('DenyAccess')}</label>
				</Checkbox>
			</div>
		</CardSection>
	);
};

export { OnPassbackViolation };

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { FascnHandling, MatchAlgorithm, OSDPLEDOption, ReaderInterface } from '../../../../../../../model/DeviceAdminModel';
import { InformationMessages, ModalConfirmation } from '../../../../../../common';
import { NotificationStatus } from '../../../../../../common/NotificationStatus/NotificationStatus';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { OSDPColorsModal } from '../../../OSDPColorsModal/OSDPColorsModal';
import {
	StoreContext,
	setChangedOSDPLEDColor,
	setCorporate1000,
	setEnableKeyPad,
	setFacilityCodeCardNumber,
	setFascnHandling,
	setHexDigitUUID,
	setMatchAlgorithm,
	setOSDPLEDOptions,
	setUseCustomOSDPLEDColor,
} from '../../../contextReader';
import styles from '../../CardReaderSetup/cardreadersetup.module.scss';

type Props = {
	isOnboardWiegand: boolean;
	useDoorContext: boolean;
};

//Avoid creating object style inline, since increases reconciliations
const style: React.CSSProperties = { color: '#faad14' };

const RS485CardReaderInterface: React.FC<Props> = ({ isOnboardWiegand, useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					matchAlgorithm,
					facilityCodeCardNumber,
					corporate1000,
					hexDigitUUID,
					oSDPConfirmation,
					oSDPTitle,
					id,
					controllerId,
					enableKeyPad,
					enableOSDPHandshake,
					fascnHandling,
					readerInterface,
					isDoorWireless,
					osdpCustomLEDColors,
					osdpLEDOptions,
					useCustomOSDPLEDColor,
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				matchAlgorithm,
				facilityCodeCardNumber,
				corporate1000,
				hexDigitUUID,
				oSDPConfirmation,
				oSDPTitle,
				id,
				controllerId,
				enableKeyPad,
				enableOSDPHandshake,
				fascnHandling,
				readerInterface,
				isDoorWireless,
				osdpCustomLEDColors,
				osdpLEDOptions,
				useCustomOSDPLEDColor,
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [fieldsetLabel, setFieldsetLabel] = useState('');
	const [showOSDPColorModal, setShowOSDPColorModal] = useState<boolean>(false);

	useEffect(() => {
		if (isOnboardWiegand) {
			setFieldsetLabel(_('OnboardWiegandInterface'));
		} else {
			setFieldsetLabel(_('RS485OSDPInterface'));
		}
	}, [isOnboardWiegand]);

	const initiateHandshake = () => {
		deviceAdminApi.initiateOSDPHandshake(id, controllerId).then(res => {
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {},
			});
		});
	};

	const handleOnClickOSDPHandshake = () => {
		const messages: string[] = oSDPConfirmation.split('\n');
		const content: JSX.Element = <InformationMessages messages={messages} />;

		ModalConfirmation({
			title: oSDPTitle,
			content: content,
			onConfirm: () => initiateHandshake(),
			okText: _('Ok'),
			cancelText: _('Cancel'),
			width: '700px',
			icon: <ExclamationCircleOutlined style={style} />,
		});
	};

	const handleOnSetMatchAlgorithm = (e: RadioChangeEvent) => {
		useDoorContext ? dispatcherDoor(setMatchAlgorithm(e.target.value)) : dispatcher(setMatchAlgorithm(e.target.value));
	};

	const handleOnSetFascnHandling = (e: RadioChangeEvent) => {
		useDoorContext ? dispatcherDoor(setFascnHandling(e.target.value)) : dispatcher(setFascnHandling(e.target.value));
	};

	const handleOnSetFacilityCodeCardNumber = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setFacilityCodeCardNumber(value.target.checked)) : dispatcher(setFacilityCodeCardNumber(value.target.checked));
	};

	const handleOnSetCorporate = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setCorporate1000(value.target.checked)) : dispatcher(setCorporate1000(value.target.checked));
	};

	const handleOnSetHexDigitUUID = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setHexDigitUUID(value.target.checked)) : dispatcher(setHexDigitUUID(value.target.checked));
	};

	const handleOnSetEnableKeyPad = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setEnableKeyPad(value.target.checked)) : dispatcher(setEnableKeyPad(value.target.checked));
	};

	const handleOnSetOSDPLEDOptions = (value: OSDPLEDOption[]) => {
		useDoorContext ? dispatcherDoor(setOSDPLEDOptions(value)) : dispatcher(setOSDPLEDOptions(value));
	};

	const handleOnSetUseCustomOSDPLEDColor = (value: boolean) => {
		useDoorContext ? dispatcherDoor(setUseCustomOSDPLEDColor(value)) : dispatcher(setUseCustomOSDPLEDColor(value));
	};

	const handleOnSetChangedOSDPLEDColor = (value: boolean) => {
		useDoorContext ? dispatcherDoor(setChangedOSDPLEDColor(value)) : dispatcher(setChangedOSDPLEDColor(value));
	};

	return (
		<div className={styles.cardReaderInterface}>
			<span className={styles.interfaceTitle}>{fieldsetLabel}</span>
			<Divider className={styles.divider} />
			<div className={styles.doubleColumn}>
				<div className={styles.radioSectionContainer}>
					<fieldset>
						<legend className={styles.legend}>{_('MatchAlgorithm')}</legend>
						<Radio.Group defaultValue={matchAlgorithm} onChange={handleOnSetMatchAlgorithm}>
							<Radio id='readerModalDisableMatchAlgorithmRadio' value={MatchAlgorithm.Disable}>
								{_('DisableMatchAlgorithm')}
							</Radio>
							<Radio value={MatchAlgorithm.StandardWiegandP} id='readerModalDisableStandardWiegandPRadio'>
								{_('StandardWiegandP')}
							</Radio>
							<Radio value={MatchAlgorithm.StandardWiegandNP} id='readerModalStandardWiegandNPRadio'>
								{_('StandardWiegandNP')}
							</Radio>
							<Radio value={MatchAlgorithm.OctalPassThroughP} id='readerModalOctalPassThroughPRadio'>
								{_('OctalPassThroughP')}
							</Radio>
							<Radio value={MatchAlgorithm.OctalPassThroughNP} id='readerModalOctalPassThroughNPRadio'>
								{_('OctalPassThroughNP')}
							</Radio>
							<Radio value={MatchAlgorithm.OctalPassThroughDP} id='readerModalOctalPassThroughDPRadio'>
								{_('OctalPassThroughDP')}
							</Radio>
							<Radio value={MatchAlgorithm.DecimalSerialNumber} id='readerModalDecimalSerialNumberRadio'>
								{_('DecimalSerialNumber')}
							</Radio>
							<Radio value={MatchAlgorithm.HexPassThrough} id='readerModalHexPassThroughRadio'>
								{_('HexPassThrough')}
							</Radio>
							<Radio value={MatchAlgorithm.BinaryTestMode} id='readerModalBinaryTestModeRadio'>
								{_('BinaryTestMode')}
							</Radio>
						</Radio.Group>
					</fieldset>
				</div>
				<div>
					<div className={styles.sectionContainer}>
						<fieldset>
							<legend className={styles.legend}>{_('FixedBitLength')}</legend>
							<div>
								<Checkbox checked={facilityCodeCardNumber} onChange={handleOnSetFacilityCodeCardNumber} id='readerFacilityCode'>
									<span>{_('FacilityCode')}</span>
								</Checkbox>
								<Checkbox checked={corporate1000} onChange={handleOnSetCorporate} id='readerCorporate'>
									<span>{_('Corporate1000')}</span>
								</Checkbox>
								<Checkbox checked={hexDigitUUID} onChange={handleOnSetHexDigitUUID} id='readerHexDigit'>
									<span>{_('PivUUID')}</span>
								</Checkbox>
							</div>
						</fieldset>
					</div>
					<div className={styles.radioSectionContainer}>
						<fieldset className={styles.pivCard}>
							<legend className={styles.legend}>{_('PivCardFascn')}</legend>
							<Radio.Group defaultValue={fascnHandling} onChange={handleOnSetFascnHandling}>
								<Radio value={FascnHandling.Disable} id='readerModalDisableFascnHandlingRadio'>
									{_('DisableFascnHandling')}
								</Radio>
								<Radio value={FascnHandling.Piv64In16} id='readerModalPiv64In16Radio'>
									{_('Piv64In16')}
								</Radio>
								<Radio value={FascnHandling.Piv75In14} id='readerModalPiv75In14Radio'>
									{_('Piv75In14')}
								</Radio>
								<Radio value={FascnHandling.Piv75In16} id='readerModalPiv75In16Radio'>
									{_('Piv75In16')}
								</Radio>
								<Radio value={FascnHandling.Piv75In22} id='readerModalPiv75In22Radio'>
									{_('Piv75In22')}
								</Radio>
								<Radio value={FascnHandling.Piv200In32} id='readerModalPiv200In32Radio'>
									{_('Piv200In32')}
								</Radio>
								<Radio value={FascnHandling.Piv128In32} id='readerModalPiv128In32Radio'>
									{_('Piv128In32')}
								</Radio>
							</Radio.Group>
						</fieldset>
					</div>
				</div>
			</div>
			<div className={styles.enableKeypad}>
				<Checkbox checked={enableKeyPad} onChange={handleOnSetEnableKeyPad} id='readerEnableKeyPad'>
					<span>{_('EnableKeypad')}</span>
				</Checkbox>
			</div>
			<div className={styles.osdpButtons}>
				{readerInterface === ReaderInterface.RS485 && (
					<>
						{!isDoorWireless && (
							<Button id='readerOSDPHandshakeButton' type='primary' onClick={handleOnClickOSDPHandshake} disabled={!enableOSDPHandshake}>
								{_('InitiateSecureOSDPConnection')}
							</Button>
						)}
						<Button id='readerCustomizeLEDColorsButton' type='primary' onClick={() => setShowOSDPColorModal(true)}>
							{_('CustomizeLEDColors')}
						</Button>
					</>
				)}
			</div>
			{showOSDPColorModal && (
				<OSDPColorsModal
					colorOptions={osdpCustomLEDColors}
					hideModal={() => setShowOSDPColorModal(false)}
					osdpLEDOptions={osdpLEDOptions}
					readerId={id}
					setChangedOSDPLEDColor={handleOnSetChangedOSDPLEDColor}
					setOSDPLEDOptions={handleOnSetOSDPLEDOptions}
					setUseCustomOSDPLEDColor={handleOnSetUseCustomOSDPLEDColor}
					useCustomColor={useCustomOSDPLEDColor}
				/>
			)}
		</div>
	);
};

export { RS485CardReaderInterface };

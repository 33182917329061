// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".information-scroll-list-overFlow--1iHGB3oW9kohbSmGewQW7{overflow-y:scroll;max-height:30vh;min-height:10vh}", "",{"version":3,"sources":["webpack://src/components/common/InformationScrollList/informationScrollList.module.scss"],"names":[],"mappings":"AAAA,yDACC,iBAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".overFlow {\r\n\toverflow-y: scroll;\r\n\tmax-height: 30vh;\r\n\tmin-height: 10vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overFlow": "information-scroll-list-overFlow--1iHGB3oW9kohbSmGewQW7"
};
export default ___CSS_LOADER_EXPORT___;

import { GlobalOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Input, Tree } from 'antd';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { handleResponse } from '../../../Helper';
import { whosInsideApi } from '../../../api';
import { ResponseStatusCode } from '../../../model/CommonModel';
import { ActionToExecute, WhosInsideCurrentAction, ZoneGroupTree, ZoneGroupType, ZoneKey, ZoneType } from '../../../model/WhosInsideModel';
import { ModalConfirmation } from '../../common';
import {
	MapToSharedZone,
	SharedZoneModal,
	WhosInsideAddCustomZone,
	createOptions,
	getRenamedGroups,
	getRenamedZones,
	highLightText,
	keywordFilter,
	renameTreeGroupZoneNode,
} from '../../whosInside';
import styles from '../whosInside.module.scss';

const { DirectoryTree } = Tree;
const { Search } = Input;

const noIconStyle: React.CSSProperties = {
	height: 0,
	width: 0,
};

type Props = {
	treeData: ZoneGroupTree;
	menuCollapsed: boolean;
	zoneSelected;
	currentEditionAction: WhosInsideCurrentAction;
	selectedKey: string[];
	setIsLoading: (value: boolean) => void;
	fetchZones: () => void;
	setZoneSelectedZoneName: (name: string) => void;
	setZoneSelectedZoneGroupName: (name: string) => void;
	setSelectedRowKeys: (rowKeys: React.Key[]) => void;
	setZoneSelected: (zone) => void;
	setCurrentEditionAction: (currentAction: WhosInsideCurrentAction) => void;
	setSelectedKey: (selectedKey: string) => void;
};

const WhosInsideTree: React.VFC<Props> = ({
	treeData,
	menuCollapsed,
	zoneSelected,
	currentEditionAction,
	selectedKey,
	setIsLoading,
	fetchZones,
	setZoneSelectedZoneName,
	setZoneSelectedZoneGroupName,
	setSelectedRowKeys,
	setZoneSelected,
	setCurrentEditionAction,
	setSelectedKey,
}) => {
	const [data, setData] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [autoExpandParent, setAutoExpandParent] = useState(true);
	const [isContextMenu, setContextMenu] = useState(false);
	const [addCustomZone, setAddCustomZone] = useState<boolean>();
	const [showEditSharedZone, setShowEditSharedZone] = useState<boolean>(false);
	const maxLength = useRef<number>(100);

	const [form] = Form.useForm();

	useEffect(() => {
		if (treeData) {
			buildTree(treeData);
		}
	}, [treeData]);

	const onClickOption = (
		type: ActionToExecute,
		zoneKey: ZoneKey,
		key: React.Key,
		zoneId: number,
		initialValue: string,
		zoneGroupId: number,
		zoneType: ZoneType
	) => {
		switch (type) {
			case ActionToExecute.Rename:
				if (zoneType === ZoneType.Shared) {
					maxLength.current = 255;
				} else {
					maxLength.current = 100;
				}

				setCurrentEditionAction({ type, zoneKey, key, zoneId, zoneGroupId, zoneType });
				form.setFields([
					{
						name: 'zoneName',
						value: initialValue,
					},
				]);
				break;

			case ActionToExecute.Edit:
				if (zoneKey === ZoneKey.Custom) {
					setCurrentEditionAction({ type, zoneKey, key, zoneId, zoneGroupId, zoneType });
					setAddCustomZone(true);
				}
				break;

			case ActionToExecute.AddNewCustomGroup:
				setCurrentEditionAction({ type, zoneKey, key, zoneId, zoneGroupId, zoneType });
				setAddCustomZone(true);
				break;

			case ActionToExecute.Delete:
				ModalConfirmation({
					onConfirm: () => deleteCustomZoneGroup(zoneGroupId),
					content: <div>{_('AreYouSureYouWantToDeleteCustomZoneGroup').replace('%1', initialValue)}</div>,
				});
				break;

			case ActionToExecute.MapToSharedZone:
				setIsLoading(true);
				whosInsideApi
					.getSharedZonesList()
					.then(response => {
						let sharedZoneId: number = 1;
						ModalConfirmation({
							width: '600px',
							cancelText: _('Cancel'),
							okText: _('Ok'),
							onConfirm: () => mapToSharedZone(sharedZoneId, zoneGroupId, zoneId),
							content: (
								<MapToSharedZone
									headerText={_('MapZoneToTheFollowingSharedZone').replace('%1', initialValue)}
									sharedZonesList={response}
									setSharedZoneIdRef={value => (sharedZoneId = value)}
								/>
							),
						});
					})
					.finally(() => setIsLoading(false));
				break;

			case ActionToExecute.EditShareZones:
				setShowEditSharedZone(true);
				break;

			case ActionToExecute.ForgiveAllUsersCredentialsInZone:
				setIsLoading(true);
				ModalConfirmation({
					onConfirm: () => {
						whosInsideApi
							.forgiveCredentialsByZoneOrGroup(
								zoneGroupId,
								zoneId,
								zoneType === ZoneType.Shared ? ZoneGroupType.SharedZone : ZoneGroupType.Custom
							)
							.then(response => {
								handleResponse(response);
							})
							.finally(() => setIsLoading(false));
					},
					onCancel: () => setIsLoading(false),
					content: <div>{_('AreYouSureYouWantToForgiveAllCredentials').replace('%1', initialValue)}</div>,
				});
				break;

			case ActionToExecute.ForgiveAllUsersCredentialsInGroup:
				setIsLoading(true);
				ModalConfirmation({
					onConfirm: () => {
						whosInsideApi
							.forgiveCredentialsByZoneOrGroup(zoneGroupId, 0)
							.then(response => {
								handleResponse(response);
							})
							.finally(() => setIsLoading(false));
					},
					onCancel: () => setIsLoading(false),
					content: <div>{_('AreYouSureYouWantToForgiveAllCredentials').replace('%1', initialValue)}</div>,
				});

				break;
		}
	};

	const mapToSharedZone = (sharedZoneId: number, zoneGroupId: number, zoneId: number) => {
		setIsLoading(true);
		whosInsideApi
			.mapToSharedZone(sharedZoneId, zoneGroupId, zoneId)
			.then(response => {
				if (response.ResponseStatusCode !== ResponseStatusCode.Success) {
					handleResponse(response);
				} else {
					fetchZones();
				}
			})
			.finally(() => setIsLoading(false));
	};

	const deleteCustomZoneGroup = (zoneGroupId: number) => {
		setIsLoading(true);

		whosInsideApi
			.deleteCustomZoneGroup(zoneGroupId)
			.then(response => {
				if (response.ResponseStatusCode !== ResponseStatusCode.Success) {
					handleResponse(response);
				} else {
					fetchZones();
					setZoneSelectedZoneGroupName('');
				}
			})
			.finally(() => setIsLoading(false));
	};

	const buildTree = (zoneGroupTree: ZoneGroupTree) => {
		const buildGlobalized = zoneGroupTree.GlobalizedGroup.map((x, index) => ({
			title: x.ZoneGroupName,
			key: `0-1-${x.ZoneGroupId}`,
			zone: { ...x },
			options: createOptions((type, zoneKey) =>
				onClickOption(type, zoneKey, `0-1-${x.ZoneGroupId}`, x.ZoneGroupId, x.ZoneGroupName, x.ZoneGroupId, ZoneType.Globalized)
			).globalizedOptions(false),
			children: x.Zones.map((c, subIndex) => ({
				title: c.ZoneName,
				key: `0-1-${x.ZoneGroupId}-${c.ZoneId}`,
				isLeaf: true,
				showIcon: false,
				icon: <span style={noIconStyle} />,
				zone: { ...c },
				options: createOptions((type, zoneKey) =>
					onClickOption(type, zoneKey, `0-1-${x.ZoneGroupId}-${c.ZoneId}`, c.ZoneId, c.ZoneName, x.ZoneGroupId, ZoneType.Globalized)
				).globalizedOptions(true),
				credentialsOnZone: c.CredentialsOnZoneFormat,
				showCountInBack: zoneGroupTree.ShowCountInBack,
			})),
		}));

		const buildCustomGroup = zoneGroupTree.CustomGroup.map((x, index) => ({
			title: x.ZoneGroupName,
			key: `0-2-${x.ZoneGroupId}`,
			zone: { ...x },
			options: createOptions((type, zoneKey) =>
				onClickOption(type, zoneKey, `0-2-${x.ZoneGroupId}`, x.ZoneGroupId, x.ZoneGroupName, x.ZoneGroupId, ZoneType.Custom)
			).customOptions(false, x.ZoneGroupCategory),
			children: x.Zones.map((c, subIndex) => ({
				title: c.ZoneName,
				key: `0-2-${x.ZoneGroupId}-${c.ZoneId}`,
				isLeaf: true,
				showIcon: false,
				icon: <span style={noIconStyle} />,
				zone: { ...c },
				options: createOptions((type, zoneKey) =>
					onClickOption(type, zoneKey, `0-2-${x.ZoneGroupId}-${c.ZoneId}`, c.ZoneId, c.ZoneName, x.ZoneGroupId, ZoneType.Custom)
				).customOptions(true),
				credentialsOnZone: c.CredentialsOnZoneFormat,
				showCountInBack: zoneGroupTree.ShowCountInBack,
			})),
		}));

		const buildSharedZones = zoneGroupTree.SharedZones.map((x, index) => ({
			title: x.ZoneName,
			key: `0-3-${x.ZoneId}`,
			options: createOptions((type, zoneKey) =>
				onClickOption(type, zoneKey, `0-3-${x.ZoneId}`, x.ZoneId, x.ZoneName, x.ZoneGroupId, ZoneType.Shared)
			).sharedOptions(),
			isLeaf: true,
			showIcon: false,
			icon: <span style={noIconStyle} />,
			zone: { ...x },
			credentialsOnZone: x.CredentialsOnZoneFormat,
			showCountInBack: zoneGroupTree.ShowCountInBack,
		}));

		const treeData = [
			{
				title: `${zoneGroupTree.EveryoneGroup?.ZoneName} ${zoneGroupTree.EveryoneGroup?.CredentialsOnZoneFormat}`,
				key: '0-0',
				icon: <GlobalOutlined />,
				zone: { ...zoneGroupTree.EveryoneGroup },
			},
			{
				title: _('GlobalizedZoneGroup'),
				key: '0-1',
				children: buildGlobalized,
			},
			{
				title: _('CustomGroup'),
				key: '0-2',
				options: createOptions((type, zoneKey) =>
					onClickOption(type, zoneKey, 'noAction', undefined, '', undefined, ZoneType.Custom)
				).customSingleOptions(),
				children: buildCustomGroup,
			},
			{
				title: _('SharedZones'),
				key: '0-3',
				options: createOptions((type, zoneKey) =>
					onClickOption(type, zoneKey, 'noAction', undefined, '', undefined, ZoneType.Shared)
				).sharedSingleOptions(),
				children: buildSharedZones,
			},
		];

		setData(treeData);
	};

	const handleCancelRename = () => {
		setCurrentEditionAction(undefined);
	};

	const handleSaveRename = () => {
		const name: string = form.getFieldValue('zoneName');
		const { zoneGroupId, zoneId, zoneKey, zoneType } = currentEditionAction;

		whosInsideApi
			.renameWhosInsideElement({ GroupId: zoneGroupId, ZoneId: zoneId, Name: name, ZoneKey: zoneKey })
			.then(response => {
				switch (response.ResponseStatusCode) {
					case ResponseStatusCode.FailedValidation:
						form.setFields([
							{
								name: 'zoneName',
								errors: [response.ErrorMessage],
							},
						]);
						break;

					case ResponseStatusCode.Success:
						handleResponse(response);
						setCurrentEditionAction(undefined);
						switch (zoneKey) {
							case ZoneKey.Custom:
							case ZoneKey.Globalized:
							case ZoneKey.Shared:
								handleRenameTreeZone(treeData, zoneGroupId, zoneId, name, zoneType);
								setZoneSelectedZoneName(name);
								break;
							case ZoneKey.WholeGroup:
								handleRenameTreeGroup(treeData, zoneGroupId, name, zoneType);
								setZoneSelectedZoneGroupName(name);
								break;
						}
						break;

					default:
						handleResponse(response);
						setCurrentEditionAction(undefined);
						break;
				}
			})
			.catch(() => setCurrentEditionAction(undefined));
	};

	const handleRenameTreeGroup = (zoneGroupTreeRawData: ZoneGroupTree, zoneGroupId: number, name: string, zoneType: ZoneType): void => {
		let zoneGroupNewTreeData: ZoneGroupTree = zoneGroupTreeRawData;

		switch (zoneType) {
			case ZoneType.Custom:
				zoneGroupNewTreeData.CustomGroup = getRenamedGroups(zoneGroupTreeRawData.CustomGroup, zoneGroupId, name);
				break;

			case ZoneType.Globalized:
				zoneGroupNewTreeData.GlobalizedGroup = getRenamedGroups(zoneGroupTreeRawData.GlobalizedGroup, zoneGroupId, name);
				break;
		}

		buildTree(zoneGroupNewTreeData);
	};

	const handleRenameTreeZone = (zoneGroupTreeRawData: ZoneGroupTree, zoneGroupId: number, zoneId: number, name: string, zoneType: ZoneType): void => {
		let zoneGroupNewTreeData: ZoneGroupTree = zoneGroupTreeRawData;

		switch (zoneType) {
			case ZoneType.Custom:
				zoneGroupNewTreeData.CustomGroup = renameTreeGroupZoneNode(zoneGroupTreeRawData.CustomGroup, zoneGroupId, zoneId, name);
				break;

			case ZoneType.Globalized:
				zoneGroupNewTreeData.GlobalizedGroup = renameTreeGroupZoneNode(zoneGroupTreeRawData.GlobalizedGroup, zoneGroupId, zoneId, name);
				break;

			case ZoneType.Shared:
				zoneGroupNewTreeData.SharedZones = getRenamedZones(zoneGroupNewTreeData.SharedZones, zoneId, name);
				break;
		}

		buildTree(zoneGroupNewTreeData);
	};

	const onExpand = (expandedKeys: []) => {
		if (isContextMenu || currentEditionAction?.key) {
			return;
		}
		setExpandedKeys(expandedKeys);
		setAutoExpandParent(false);
	};

	const isEditMode: boolean = currentEditionAction?.type === ActionToExecute.Rename;

	const setCurrentName = (zoneGroupId: number) => {
		const name = data.find(x => x.children?.some(x => x.zone?.ZoneGroupId === zoneGroupId));
		if (name !== undefined) {
			const groupName = name.children?.find(x => x.zone?.ZoneGroupId === zoneGroupId);
			if (groupName !== undefined) {
				setZoneSelectedZoneGroupName(groupName?.zone?.ZoneGroupName);
			}
		}
	};

	const handleOnSelectTreeNode = e => {
		const parseAsAny = e.node as any;
		setSelectedKey(parseAsAny.key);
		if (parseAsAny.zone) {
			if (zoneSelected !== parseAsAny.zone) {
				setSelectedRowKeys([]);
				if (parseAsAny.zone.ZoneName) {
					setZoneSelectedZoneName(parseAsAny.zone.ZoneName);
					setCurrentName(parseAsAny.zone.ZoneGroupId);
				} else if (parseAsAny.zone.ZoneGroupName) {
					setZoneSelectedZoneName('');
					setZoneSelectedZoneGroupName(parseAsAny.zone.ZoneGroupName);
				}
				setZoneSelected(parseAsAny.zone);
			}
		} else {
			setZoneSelected(undefined);
			setZoneSelectedZoneName('');
			setZoneSelectedZoneGroupName('');
			setSelectedRowKeys([]);
		}
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		const searchedNodes: string[] = keywordFilter(data, value.trim().toLowerCase(), value.trim().toLowerCase());
		setSearchValue(value);
		setAutoExpandParent(true);
		if (value.trim().length === 0) {
			setExpandedKeys([]);
		} else {
			setExpandedKeys(searchedNodes);
		}
	};

	return (
		<>
			{menuCollapsed ? (
				<div className={cx(styles.searchIconContainer, { [styles.activeSearchContainer]: searchValue.trim().length })}>
					<SearchOutlined
						id='whosInsideSearchIcon'
						className={cx(styles.icon, styles.disableIcon, { [styles.activeSearchIcon]: searchValue.trim().length })}
					/>
				</div>
			) : (
				<div className={styles.filter}>
					<Search
						aria-label={_('SearchZone')}
						aria-labelledby='whosInsideSearchTreeInput'
						id='whosInsideSearchTreeInput'
						disabled={isEditMode}
						placeholder='Zone List'
						onChange={onChange}
						value={searchValue}
					/>
				</div>
			)}
			<Form form={form} component={false}>
				<div id='whosInsideTreeContainer' className={cx(styles.wrapperTree, { [styles.collapsed]: menuCollapsed, [styles.disabledTree]: isEditMode })}>
					<DirectoryTree
						height={600}
						treeData={highLightText(
							data,
							searchValue,
							maxLength.current,
							currentEditionAction,
							handleCancelRename,
							handleSaveRename,
							setContextMenu,
							menuCollapsed
						)}
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						className={cx({ [styles.wrapperCollapsedMenu]: menuCollapsed })}
						autoExpandParent={autoExpandParent}
						onSelect={(rest, e) => handleOnSelectTreeNode(e)}
						disabled={isEditMode}
						motion={false}
						selectedKeys={selectedKey}
					/>
				</div>
			</Form>
			{addCustomZone && (
				<WhosInsideAddCustomZone
					onOkModal={(newName: string) => {
						fetchZones();
						setAddCustomZone(false);
						setCurrentEditionAction(undefined);
						setZoneSelectedZoneGroupName(newName);
					}}
					onHideModal={() => {
						setAddCustomZone(false);
						setCurrentEditionAction(undefined);
					}}
					zoneId={currentEditionAction?.zoneId ?? 0}
				/>
			)}
			{showEditSharedZone && (
				<SharedZoneModal
					onOkModal={() => {
						fetchZones();
						setShowEditSharedZone(false);
					}}
					onHideModal={() => {
						setShowEditSharedZone(false);
					}}
				/>
			)}
		</>
	);
};

const WhosInsideTreeMemo = React.memo(WhosInsideTree);

export { WhosInsideTreeMemo as WhosInsideTree };

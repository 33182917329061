export enum AlarmActionType {
	SET_SHOW_ALARM_INSTRUCTIONS = 'SET_SHOW_ALARM_INSTRUCTIONS',
	SET_SHOW_ALARM_NOTES = 'SET_SHOW_ALARM_NOTES',
	SET_SHOW_ALARM_ADD_OPERATOR_NOTE = 'SET_SHOW_ALARM_ADD_OPERATOR_NOTE',
	SET_ADDED_OPERATOR_NOTE = 'SET_ADDED_OPERATOR_NOTE',
	SET_RELOAD_ALARM_TABLE = 'SET_RELOAD_ALARM_TABLE',
	SET_RELOAD_ACKNOWLEDGE_ALARM_TABLE = 'SET_RELOAD_ACKNOWLEDGE_ALARM_TABLE',
	SET_ALARM_NOTE_FOR_ACKNOWLEDGE = 'SET_ALARM_NOTE_FOR_ACKNOWLEDGE',
	SET_OPERATOR_NOTES_ADDED = 'SET_OPERATOR_NOTES_ADDED',
}

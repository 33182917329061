import { Button, Checkbox, Input, notification } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { removeCommasAndPercentSign } from '../../../../../Helper';
import { deviceAdminApi } from '../../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../../model/AccountModel';
import { PaginationSetting, ResponseStatusCode } from '../../../../../model/CommonModel';
import { Holiday, HolidayDate } from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setHolidaysBy } from '../../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfigurationFilterMode } from '../../../../../store/deviceControl/selectors';
import { Calendar, CalendarViewMode, Modal, ModalWarning, NotificationStatus } from '../../../../common';
import { HolidayAlert } from '../HolidayAlert/HolidayAlert';
import styles from './holidayModal.module.scss';

type Props = {
	onSetVisible: () => void;
	holidayId?: number;
	setShouldResetSearchColumn: () => void;
	setRedirectPage?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Holiday);

const HolidayModal: React.FC<Props> = ({ onSetVisible, holidayId, setShouldResetSearchColumn, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	useEffect(() => {
		if (holidayId) {
			deviceAdminApi.editHoliday(holidayId).then(response => {
				if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
					notification['warning']({
						message: response.ResponseErrorDescription,
					});
				}

				const { T1, T2, T3, T4, HolidayDates, Name } = response.Entity;
				const day1 = T1 ? true : false;
				const day2 = T2 ? true : false;
				const day3 = T3 ? true : false;
				const day4 = T4 ? true : false;
				setNameState({ ...nameState, name: Name });
				setHolidaysState({ ...holidaysState, day1, day2, day3, day4 });
				//remove 1 in order to match with JS Date
				setDatesState({ ...datesState, dates: [...HolidayDates.map(x => new Date(x.Year, x.Month - 1, x.Day))] });
			});
		} else {
			setDatesState({ ...datesState, dates: [] });
		}
		deviceAdminApi.getErrorMessages(SecuredComponents.Holiday).then(res => setEmptyNameError(res.EmptyName));
	}, []);

	const [nameState, setNameState] = useState({
		name: '',
		nameError: '',
	});
	const [holidaysState, setHolidaysState] = useState({
		day1: false,
		day2: false,
		day3: false,
		day4: false,
		error: false,
	});
	const [datesState, setDatesState] = useState({
		dates: undefined as Date[],
		errorDate: false,
	});
	const [modalWidth, setModalWidth] = useState('341px');
	const [emptyNameError, setEmptyNameError] = useState('');

	const handleSelectDates = (dates: Date[]) => {
		setDatesState({
			dates,
			errorDate: false,
		});
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		setNameState({ nameError: '', name: value });
	};

	const handleCheckedHolidays = (checked: boolean, key: string) => {
		setHolidaysState({
			...holidaysState,
			error: false,
			[key]: checked,
		});
	};

	const { name, nameError } = nameState;
	const { day1, day2, day3, day4, error } = holidaysState;
	const { errorDate, dates } = datesState;

	const shouldResetSearchColumn = holidayId => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!holidayId) {
			return;
		}

		setRedirectPage();
	};

	const handleOnSave = () => {
		if (!name || name.trim() === '' || (!day1 && !day2 && !day3 && !day4) || dates.length === 0) {
			if (!name || name.trim() === '') {
				setNameState({ ...nameState, nameError: emptyNameError });
			}
			if (!day1 && !day2 && !day3 && !day4) {
				setHolidaysState({
					...holidaysState,
					error: true,
				});
			}
			if (dates.length === 0) {
				setDatesState({
					...datesState,
					errorDate: true,
				});
			}
			return;
		}

		const getShortNumber = (): Omit<HolidayDate, 'Month' | 'Year' | 'Day'> => ({
			T1: day1 ? 1 : 0,
			T2: day2 ? 1 : 0,
			T3: day3 ? 1 : 0,
			T4: day4 ? 1 : 0,
		});

		const holiday: Holiday = {
			HolidayDates: dates.map(x => ({
				...getShortNumber(),
				Day: x.getDate(),
				Month: x.getMonth() + 1, //add 1 because Month of the year in JS start in 0
				Year: x.getFullYear(),
			})),
			HolidayID: holidayId || 0,
			Name: name.trim(),
			...getShortNumber(),
		};

		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		deviceAdminApi.saveHolidays(holiday).then(res => {
			if (res.Entity?.length > 0) {
				ModalWarning({
					okText: _('Ok'),
					content: <HolidayAlert data={res.Entity} />,
					title: _('HolidaysInUse'),
				});
			}

			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {
					onSetVisible();
					dispatch(setHolidaysBy(paginationSetting));
					shouldResetSearchColumn(holidayId);
				},
				onInputErrorCallback: () => setNameState({ ...nameState, nameError: res.ErrorMessage }),
			});
		});
	};

	const handleOnClose = () => {
		if (holidayId) {
			deviceAdminApi.unlockComponentDeviceAdmin(holidayId, SecuredComponents.Holiday);
		}
		onSetVisible();
	};

	const handleAdjustModalWidth = (viewMode: CalendarViewMode) => {
		switch (viewMode) {
			case CalendarViewMode.Monthly:
				setModalWidth('341px');
				break;

			case CalendarViewMode.Quarterly:
			case CalendarViewMode.Yearly:
				setModalWidth('933px');
				break;
		}
	};

	const permissionsEnabled = holidayId ? componentPermission.canUpdate : componentPermission.canAdd;
	const disabled = !permissionsEnabled;
	const holidayAddInputNameId = 'holidayAddInputName';
	const holidayAddCheckbox1Id = 'holidayAddCheckbox1';
	const holidayAddCheckbox2Id = 'holidayAddCheckbox2';
	const holidayAddCheckbox3Id = 'holidayAddCheckbox3';
	const holidayAddCheckbox4Id = 'holidayAddCheckbox4';

	return (
		<Modal
			footer={[
				<Button
					key='save'
					title={getPermissionErrorMessage(permissionsEnabled)}
					type='primary'
					id='holidayAddSaveChanges'
					disabled={disabled}
					onClick={() => handleOnSave()}>
					{_('SaveChanges')}
				</Button>,
				<Button key='cancel' onClick={() => handleOnClose()} id='holidayAddCancel'>
					{_('Cancel')}
				</Button>,
			]}
			width={modalWidth}
			visible={true}
			customZoomClass={styles.withModalZoom}
			title={holidayId ? _('EditHoliday') : _('AddHoliday')}
			onCancel={() => handleOnClose()}
			onClickOk={() => handleOnSave()}>
			<div className={styles.container}>
				<div className={styles.sections}>
					<div className={styles.inputDiv}>
						<label htmlFor={holidayAddInputNameId}>{_('Name')}</label>
						<div className={cx({ [styles.error]: nameError })}>
							<Input value={name} maxLength={32} onChange={handleChangeName} id={holidayAddInputNameId} />
							{nameError && <label htmlFor={holidayAddInputNameId}>{nameError}</label>}
						</div>
					</div>
				</div>
				<div className={styles.sections}>
					<div>
						<label>{_('Date')}</label>
						<div className={cx(styles.calendar, { [styles.errorDiv]: errorDate })}>
							{dates && <Calendar selectedDates={dates} onSelectedDate={handleSelectDates} onChangeViewMode={handleAdjustModalWidth} />}
						</div>
					</div>
				</div>
				<div className={styles.sections}>
					<div className={cx({ [styles.errorDate]: errorDate })}>{errorDate && <label className={styles.error}>One Date is required</label>}</div>
				</div>
				<div className={styles.sections}>
					<fieldset className={cx(styles.schedules, { [styles.errorDiv]: error })}>
						<legend>{_('HolidaySchedules')}</legend>
						<div>
							<Checkbox checked={day1} onChange={e => handleCheckedHolidays(e.target.checked, 'day1')} id={holidayAddCheckbox1Id}>
								<label htmlFor={holidayAddCheckbox1Id}>1</label>
							</Checkbox>
							<Checkbox checked={day2} onChange={e => handleCheckedHolidays(e.target.checked, 'day2')} id={holidayAddCheckbox2Id}>
								<label htmlFor={holidayAddCheckbox2Id}>2</label>
							</Checkbox>
							<Checkbox checked={day3} onChange={e => handleCheckedHolidays(e.target.checked, 'day3')} id={holidayAddCheckbox3Id}>
								<label htmlFor={holidayAddCheckbox3Id}>3</label>
							</Checkbox>
							<Checkbox checked={day4} onChange={e => handleCheckedHolidays(e.target.checked, 'day4')} id={holidayAddCheckbox4Id}>
								<label htmlFor={holidayAddCheckbox4Id}>4</label>
							</Checkbox>
						</div>
					</fieldset>
				</div>
				<div className={styles.sections}>
					<div className={cx({ [styles.error]: error })}>{error && <label className={styles.error}>One Holiday is required</label>}</div>
				</div>
			</div>
		</Modal>
	);
};

export { HolidayModal };

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SelectOptions } from '../../../../../../../../model/CommonModel';
import { ReaderInterface } from '../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderCardCodeOnlyTimeZone } from '../../../exitReaderContext';
import styles from '../../Logic/logic.module.scss';

type Props = {
	setPreviousSelection: (number) => void;
};

const CCOTZ: React.FC<Props> = ({ setPreviousSelection }) => {
	var {
		contextStateDoor: {
			exitReader: {
				genericTimeZones,
				readerInterface,
				selections: { cardCodeOnlyTimeZone },
				unpermitted,
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [options, setOptions] = useState<SelectOptions<string>[]>([]);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	useEffect(() => {
		if (unpermitted.cardCodeOnlyTimeZone) {
			const unpermittedOption: SelectOptions<string>[] = [
				{
					label: unpermitted.cardCodeOnlyTimeZone.Name,
					value: unpermitted.cardCodeOnlyTimeZone.GenericId.toString(),
					id: unpermitted.cardCodeOnlyTimeZone.GenericId.toString(),
				},
			];
			setOptions(unpermittedOption);
			setIsDisabled(true);
		} else {
			setOptions(genericTimeZones.map(x => ({ label: x.Name, value: x.GenericId.toString(), id: x.GenericId.toString() })));
		}
	}, [genericTimeZones]);

	const handleOnSelectCCOTZ = (value: string) => {
		setPreviousSelection(cardCodeOnlyTimeZone);
		dispatcherDoor(setExitReaderCardCodeOnlyTimeZone(Number(value)));
	};

	const CCOTZLabel: string = useMemo(() => {
		if (readerInterface !== ReaderInterface.RS485) {
			return `${_('EnableCCOTZ')}: (${_('CCOTZ')})`;
		} else {
			return _('EnableCCOTZAlt');
		}
	}, [readerInterface]);

	return (
		<div className={styles.ccotzSection}>
			<span>{CCOTZLabel}</span>
			<div>
				<Select
					className={styles.timeZone}
					options={options}
					onChange={handleOnSelectCCOTZ}
					key='readerType'
					id='exitReadeModalCCOTZSelect'
					value={cardCodeOnlyTimeZone.toString()}
					disabled={isDisabled}
					getPopupContainer={() => document.getElementById('logicExitReaderContainer')}
				/>
			</div>
		</div>
	);
};

export { CCOTZ };

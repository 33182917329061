import { Select } from 'antd';
import React from 'react';
import style from './paginationSelect.module.scss';

const PaginationSelect = props => {
	return (
		<Select
			defaultValue={props.value}
			onChange={props.onChange}
			className={style.select}
			size={'small'}
			dropdownRender={(menu: React.ReactElement) => <label aria-label={_('TablePaginationSizes')}>{menu}</label>}
			aria-label={_('TablePagination')}>
			{React.Children.map(props.children, props => {
				return <Select.Option value={props.props.value}>{props.props.children}</Select.Option>;
			})}
		</Select>
	);
};

PaginationSelect.Option = props => {
	return <Select.Option value={props.value}>{props.children}</Select.Option>;
};

export { PaginationSelect };

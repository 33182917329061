import { ControlZone, ControlZonesEditor, MasterControlZonesEditor, StandardControlZonesEditor } from '../../../model/DeviceAdminModel';
import {
	ActionTypes,
	SeMasterControlZones,
	SetControlZonesEditMode,
	SetControlZonesEditor,
	SetControlZonesForController,
	SetControlZonesHasChangeName,
	SetStandardListControlZones,
} from './actionTypes';

export const setControlZonesForControllers = (payload: ControlZonesEditor): SetControlZonesForController => {
	return {
		type: ActionTypes.SET_CONTROL_ZONES_FOR_CONTROLLER,
		payload,
	};
};

export const setControlZonesEditor = (payload: { data: Partial<StandardControlZonesEditor>; propertyIndex: string }): SetControlZonesEditor => {
	return {
		type: ActionTypes.SET_CONTROL_ZONE_EDITOR,
		payload,
	};
};

export const seMasterControlZoneEditor = (payload: { propertyIndex: string; value: Partial<MasterControlZonesEditor> }): SeMasterControlZones => {
	return {
		type: ActionTypes.SET_MASTER_CONTROL_ZONES,
		payload,
	};
};

export const setStandardControlZonesList = (payload: ControlZone): SetStandardListControlZones => {
	return {
		type: ActionTypes.SET_STANDARD_CONTROL_ZONE_LIST,
		payload,
	};
};

export const setControlZonesEditMode = (payload: boolean): SetControlZonesEditMode => {
	return {
		type: ActionTypes.SET_CONTROL_ZONES_EDIT_MODE,
		payload,
	};
};

export const setControlZonesHasChangedName = (payload: boolean): SetControlZonesHasChangeName => {
	return {
		type: ActionTypes.SET_CONTROL_ZONES_HAS_CHANGE_NAME,
		payload,
	};
};

import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Button } from 'antd';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { enrollmentApi } from '../../../../api';
import { PaginationSetting } from '../../../../model/CommonModel';
import { AdvanceSearchCriteria, PersonModel, TreeViewPersonGroupModel } from '../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setKeywordSearchText } from '../../../../store/enrollment/actions';
import { selectAdvanceSearchCriteria, selectBlankProfilePictureBase64, selectPersonTablePaginationSettings } from '../../../../store/enrollment/selectors';
import styles from '../enrollmentTab.module.scss';

const { Option } = AutoComplete;
const highlightStyle: React.CSSProperties = { color: '#1890ff', fontWeight: 'bold' };
type Props = {
	inModal?: boolean;
	onSelectPerson: (option) => void;
	personGroup: TreeViewPersonGroupModel;
};

const SearchPersonAutocompleteId = 'searchPersonAutocomplete';

const SearchPersonBar: React.FC<Props> = ({ inModal, personGroup, onSelectPerson }) => {
	const storedPersonTablePaginationSettings: PaginationSetting = useStoreSelector<PaginationSetting>(selectPersonTablePaginationSettings);
	const [searchResults, setSearchResults] = useState<PersonModel[]>([]);
	const [searchText, setSearchText] = useState<string>(storedPersonTablePaginationSettings.SearchedValue);
	const [inputValue, setInputValue] = useState(storedPersonTablePaginationSettings.SearchedValue);
	const dispatch = useStoreDispatch();
	const blankProfilePictureBase64: string = useStoreSelector<string>(selectBlankProfilePictureBase64);
	const advanceSearchCriteria: AdvanceSearchCriteria = useStoreSelector<AdvanceSearchCriteria>(selectAdvanceSearchCriteria);
	const firstRender = useRef<boolean>(true);

	useEffect(() => {
		if (!firstRender.current) {
			setSearchText('');
			setInputValue('');
			setSearchResults([]);
		}
	}, [personGroup]);

	useEffect(() => {
		if (storedPersonTablePaginationSettings.SearchedValue.length === 0) {
			setInputValue('');
			setSearchResults([]);
		}
	}, [storedPersonTablePaginationSettings.SearchedValue]);

	useEffect(() => {
		firstRender.current = false;
	}, []);

	const handleSearch = useCallback(
		debounce(async (name: string) => {
			setSearchText(name.trim());
			if (!name.trim() && storedPersonTablePaginationSettings.SearchedValue !== '') {
				handleOnClearAutocomplete();
			}

			if (!name.trim() || !personGroup) {
				setSearchResults([]);
			} else {
				enrollmentApi.getPersonsByName(name.trim(), personGroup.GroupId, personGroup.FolderId).then(res => setSearchResults(res));
			}
		}, 500),
		[storedPersonTablePaginationSettings?.SearchedValue, personGroup?.GroupId, personGroup?.FolderId]
	);

	const getAutoCompleteOption = (person: PersonModel, isLastOption: boolean) => {
		const fullName = `${person.LastName}, ${person.FirstName} ${person.MiddleName}` as any;
		let nameElement: JSX.Element = <label>{fullName}</label>;

		const lowerSearchText = searchText.trim().toLowerCase();
		const index: number = fullName.toLowerCase().indexOf(lowerSearchText);

		if (index > -1) {
			const beforeStr = fullName.substr(0, index);
			const matchStr = fullName.substr(index, searchText.trim().length);
			const afterStr = fullName.substr(index + searchText.trim().length);
			nameElement = (
				<span>
					{beforeStr}
					<span style={highlightStyle}>{matchStr}</span>
					{afterStr}
				</span>
			);
		}

		return (
			<Option key={person.PersonId} value={`${person.PersonId}`}>
				<div
					data-test-id={`${SearchPersonAutocompleteId}-item`}
					className={cx(styles.autoCompleteOption, { [styles.autoCompleteBorder]: !isLastOption })}>
					<div>
						{person.PhotoId > 1 && person.ShowProfilePicture && (
							<img width={50} src={`data:image/bmp;base64,${person.ProfilePictureBase64}`} alt={`${person.LastName} ${person.FirstName}`} />
						)}
						{(person.PhotoId <= 1 || !person.ShowProfilePicture) && (
							<img width={50} src={`data:image/bmp;base64,${blankProfilePictureBase64}`} alt={`${person.LastName} ${person.FirstName}`} />
						)}
					</div>
					<div>
						<span>
							<strong>{_('PersonID')}</strong> {person.PersonId}
						</span>
						<br />
						<span>
							<strong>{_('Name')}</strong> {nameElement}
						</span>
						<br />
						<span>
							<strong>{_('LastUpdated')}</strong> {FormatDateStd(person.LastUpdated)}
						</span>
					</div>
				</div>
			</Option>
		);
	};

	const handleOnSelectSearchResult = (value, option) => {
		setInputValue('');
		onSelectPerson(option);
	};

	const handleOnClearAutocomplete = () => {
		dispatch(setKeywordSearchText(''));
	};

	const handleOnKeyDown = e => {
		if (e.keyCode === 13) {
			handleOnPerformSearch(e.target.value);
		}
	};

	const handleOnChangeAutocomplete = (value: string) => {
		if (value && value.length >= 100) {
			value = value.substring(0, 100);
		}
		setInputValue(value);
	};

	const handleOnPerformSearch = debounce(async (value: string) => {
		if (!inModal) {
			const valueTrim = value.trim();
			if (valueTrim !== '') {
				dispatch(setKeywordSearchText(valueTrim));
			}
		}
	}, 500);

	const disableSearchPersonBar: boolean = advanceSearchCriteria !== undefined;

	const searchButton: JSX.Element =
		storedPersonTablePaginationSettings.SearchedValue !== '' ? (
			<Button
				onClick={() => {
					handleOnPerformSearch(inputValue);
				}}
				type='primary'
				icon={<SearchOutlined />}
				disabled={disableSearchPersonBar}
			/>
		) : (
			<Button
				onClick={() => {
					handleOnPerformSearch(inputValue);
				}}
				icon={<SearchOutlined />}
				disabled={disableSearchPersonBar}
			/>
		);

	return (
		<div className={styles.autoCompleteContainer}>
			<AutoComplete
				allowClear={true}
				data-test-id={SearchPersonAutocompleteId}
				onSearch={handleSearch}
				placeholder={inModal ? _('SearchPerson') : _('SearchInThisGroup')}
				onSelect={handleOnSelectSearchResult}
				listHeight={402}
				onKeyUp={handleOnKeyDown}
				value={inputValue}
				onChange={handleOnChangeAutocomplete}
				disabled={disableSearchPersonBar}
				aria-label={_('AutocompletePerson')}>
				{searchResults.map((person: PersonModel, index: number) => getAutoCompleteOption(person, index === searchResults.length - 1))}
			</AutoComplete>
			{!inModal && searchButton}
		</div>
	);
};

export { SearchPersonBar };

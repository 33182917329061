import { batch } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CustomFilterGroup, EventConfiguration, EventFilter } from '../../model/EventModel';
import { EventActionType } from './actionsTypes';

type EventSetFilterResponse = {
	type: EventActionType.SET_EVENT_FILTERS;
	payload: EventFilter[];
};

type EventSetCustomFilterGroupResponse = {
	type: EventActionType.SET_CUSTOM_FILTER_GROUPS;
	payload: CustomFilterGroup[];
};

type EventSetSelectedCustomFilterGroupResponse = {
	type: EventActionType.SET_SELECTED_CUSTOM_FILTER_GROUPS;
	payload: CustomFilterGroup[];
};

//Union Action Types
export type EventAction = EventSetFilterResponse | EventSetCustomFilterGroupResponse | EventSetSelectedCustomFilterGroupResponse;

//Action Creators

//Export Action
export const setConfigurationResponse = (response: EventConfiguration): ThunkAction<Promise<void>, {}, {}, EventAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, EventAction>): Promise<void> => {
		batch(() => {
			dispatch(setFiltersResponse(response.EventFilters));
			dispatch(setCustomFilterGroupsResponseAction(response.CustomFilterGroups));
			dispatch(setSelectedCustomFilterGroupsResponseAction(response.SelectedCustomFilterGroups));
		});
	};
};

export const setFiltersResponse = (payload: EventFilter[]): EventSetFilterResponse => {
	return {
		type: EventActionType.SET_EVENT_FILTERS,
		payload,
	};
};

export const setCustomFilterGroupsResponseAction = (payload: CustomFilterGroup[]): EventSetCustomFilterGroupResponse => {
	return {
		type: EventActionType.SET_CUSTOM_FILTER_GROUPS,
		payload,
	};
};

export const setSelectedCustomFilterGroupsResponseAction = (payload: CustomFilterGroup[]): EventSetSelectedCustomFilterGroupResponse => {
	return {
		type: EventActionType.SET_SELECTED_CUSTOM_FILTER_GROUPS,
		payload,
	};
};

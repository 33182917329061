import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	doorGroupLockedValidation,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	OperatorsColumn,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	SelectOptions,
	SelectedDeviceKeys,
	SelectedDevicePage,
} from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	DeviceAdminWarningType,
	DeviceAdminWarningTypeString,
	DeviceObjectType,
	DeviceSelectedPage,
	DoorGroupAndMasterInfo,
	DoorGroupColumns,
	DoorGroupType,
	OptionsButtonBuilder,
	SelectedDoorGroup,
	SelectedRowKeyPagination,
	VelocityConfigurationModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import {
	clearVelocityConfigurationSelectionsAction,
	setDoorGroupsBy,
	setDoorGroupsByData,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
	setVelocitySelectedRowKeysPaginationAction,
} from '../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfiguration } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, EditableCell, ModalConfirmation, ModalConfirmationList, ModalWarning, NotificationStatus, SearchColumn } from '../../../common';
import { DoorMasterModal } from './DoorMasterModal/DoorMasterModal';
import { DoorModal } from './DoorModal/DoorModal';
import styles from './doorGroup.module.scss';
import { DoorGroupLockStatus, DoorWarning } from './warnings';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const doorGroupComponentPermission = User.getComponentPermission(user, SecuredComponents.Door_Groups);
const masterDoorGroupComponentPermission = User.getComponentPermission(user, SecuredComponents.Master_Door_Group);
const scroll: ScrollType = { x: 379, y: 312 };
const maxLength: number = 64;
const deviceObjectType: DeviceObjectType = DeviceObjectType.DoorGroup;
const getRowClassName = (record: DoorGroupColumns) => (record.Type === _('MasterDoorGroup') ? styles.masterDoorGroupRow : styles.doorGroupRow);

const createButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean, doorGroup: DoorGroupAndMasterInfo[]): OptionsButtonBuilder<string> => {
	const doorGroupsSelected = doorGroup.filter(x => !x.IsMasterDoor);
	const masterDoorGroupsSelected = doorGroup.filter(x => x.IsMasterDoor);
	let options = [];

	if (masterDoorGroupsSelected.length >= 1 && !doorGroupsSelected.length) {
		options = [
			{
				///This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
				id: 'doorGroupTableActionDropdownEditButton',
				label: _('Edit'),
				disabled: !masterDoorGroupComponentPermission.canView || hasMoreThanOne,
				value: 'edit',
				title: getPermissionErrorMessage(masterDoorGroupComponentPermission.canView, false, hasMoreThanOne),
			},
			{
				id: 'doorGroupTableActionDropdownRenameButton',
				label: _('Rename'),
				disabled: !masterDoorGroupComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
				value: 'rename',
				title: getPermissionErrorMessage(masterDoorGroupComponentPermission.canUpdate, false, hasMoreThanOne),
			},
			{
				id: 'doorGroupTableActionDropdownDeleteButton',
				label: _('Delete'),
				disabled: !masterDoorGroupComponentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(masterDoorGroupComponentPermission.canDelete),
			},
		];
	} else if (doorGroupsSelected.length >= 1 && !masterDoorGroupsSelected.length) {
		options = [
			{
				///This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
				id: 'doorGroupTableActionDropdownEditButton',
				label: _('Edit'),
				disabled: !doorGroupComponentPermission.canView || hasMoreThanOne,
				value: 'edit',
				title: getPermissionErrorMessage(doorGroupComponentPermission.canView, false, hasMoreThanOne),
			},
			{
				id: 'doorGroupTableActionDropdownRenameButton',
				label: _('Rename'),
				disabled: !doorGroupComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
				value: 'rename',
				title: getPermissionErrorMessage(doorGroupComponentPermission.canUpdate, false, hasMoreThanOne),
			},
			{
				id: 'doorGroupTableActionDropdownDeleteButton',
				label: _('Delete'),
				disabled: !doorGroupComponentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(doorGroupComponentPermission.canDelete),
			},
		];
	} else {
		options = [
			{
				///This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
				id: 'doorGroupTableActionDropdownEditButton',
				label: _('Edit'),
				disabled: !(doorGroupComponentPermission.canView || masterDoorGroupComponentPermission.canView) || hasMoreThanOne,
				value: 'edit',
				title: getPermissionErrorMessage(doorGroupComponentPermission.canView || masterDoorGroupComponentPermission.canView, false, hasMoreThanOne),
			},
			{
				id: 'doorGroupTableActionDropdownRenameButton',
				label: _('Rename'),
				disabled: !(doorGroupComponentPermission.canUpdate || masterDoorGroupComponentPermission.canUpdate) || hasMoreThanOne || !isItemOnSamePage,
				value: 'rename',
				title: getPermissionErrorMessage(doorGroupComponentPermission.canUpdate || masterDoorGroupComponentPermission.canUpdate, false, hasMoreThanOne),
			},
			{
				id: 'doorGroupTableActionDropdownDeleteButton',
				label: _('Delete'),
				disabled: !(doorGroupComponentPermission.canDelete || masterDoorGroupComponentPermission.canDelete),
				value: 'delete',
				title: getPermissionErrorMessage(doorGroupComponentPermission.canDelete || masterDoorGroupComponentPermission.canDelete),
			},
		];
	}
	return {
		labelOrIcon: '...',
		options,
	};
};

const DoorGroup: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const { doorGroups, isEditMode, isFilterMode, selectedRowKeysPagination }: VelocityConfigurationModel = useStoreSelector(selectVelocityConfiguration);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [editingKey, setEditingKey] = useState('');
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedDoorGroupKeys, setSelectedDoorGroupKeys] = useState<SelectedDoorGroup[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<DoorGroupColumns[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [openMasterModal, setOpenMasterModal] = useState(false);
	const [doorToDelete, setDoorToDelete] = useState<Partial<DoorGroupAndMasterInfo>[]>([]);
	const [doorToEdit, setDoorToEdit] = useState<DoorGroupAndMasterInfo>(undefined);
	const [doorGroupSelectedPage, setDoorGroupSelectedPage] = useState<SelectedDevicePage>(null);
	const [form] = Form.useForm();

	const redirectPage = useRef<boolean>(false);
	const isSearchPerformed = useRef<boolean>(false);

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setIsItemOnSamePage(isSamePage);
		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		const mapRes: DoorGroupColumns[] = createData();
		setTableData(mapRes);
	}, [JSON.stringify(doorGroups)]);

	// * This hook is responsible for loading the previously edited command set
	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage.current) {
			const selectedDoorGroup = getDoorGroupIdFromUniqueId(selectedRowKeys?.[0].toString());
			getDoorGroupSelectedPage(selectedDoorGroup);
		}
	}, [redirectPage.current]);

	useEffect(() => {
		if (doorGroupSelectedPage) {
			setRedirectPage(false);
			setIsItemOnSamePage(true);

			const { deviceId, pageNumber } = doorGroupSelectedPage;

			batch(() => {
				dispatch(setVelocitySelectedRowKeysPaginationAction([{ Id: deviceId.toString(), PaginationPage: pageNumber }]));
				dispatch(setDoorGroupsBy({ ...tablePaginationSetting, PageNumber: pageNumber, SearchedValue: '' }));
			});

			setDoorGroupSelectedPage(null);
		}
	}, [JSON.stringify(doorGroupSelectedPage)]);

	// * This hook ensure that always return a valid page number when no values has returned
	useEffect(() => {
		if (tablePaginationSetting.TotalItems === 0 && tablePaginationSetting.PageNumber > 1) {
			const { PageNumber: currentPage } = tablePaginationSetting;
			dispatch(
				setDoorGroupsBy({
					...tablePaginationSetting,
					PageNumber: currentPage - 1,
				})
			);
		}
	}, [tablePaginationSetting]);

	useEffect(() => {
		return () => {
			clearSelection();
		};
	}, []);

	const getDoorGroupIdFromUniqueId = (uniqueId: string) => {
		const doorGroups = selectedDoorGroupKeys.filter(x => x.UniqueId === uniqueId);
		return doorGroups[0];
	};

	const getDoorGroupSelectedPage = async (selectedDoorGroup: SelectedDoorGroup) => {
		const { DeviceId: deviceId, IsMasterDoorGroup: isMasterDoorGroup } = selectedDoorGroup;
		const deviceSelectedPage: DeviceSelectedPage = {
			DeviceId: deviceId,
			DeviceObjectType: deviceObjectType,
			IsMasterDoorGroup: isMasterDoorGroup,
		};
		const response = await deviceAdminApi.getVelocityDeviceSelectedPage(deviceSelectedPage, { ...tablePaginationSetting, SearchedValue: '' });
		const { Entity: pageNumber } = response;

		setDoorGroupSelectedPage({ deviceId, pageNumber });
	};

	const clearSelection = () => {
		if (redirectPage.current) {
			return;
		}
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.DoorGroup }));
		setSelectedRowKeys([]);
		setSelectedDoorGroupKeys([]);
	};

	const getSelectedDoorGroups = async (): Promise<SelectedDeviceKeys[]> => {
		const doorGroupKeys: string[] = selectedDoorGroupKeys.filter(x => !x.IsMasterDoorGroup).map<string>(x => x.DeviceId.toString()) ?? [];
		const masterDoorGroupKeys: string[] = selectedDoorGroupKeys.filter(x => x.IsMasterDoorGroup).map<string>(x => x.DeviceId.toString()) ?? [];
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, doorGroupKeys, masterDoorGroupKeys);

		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const getDoorGroup = async (uniqueId: string): Promise<DoorGroupAndMasterInfo> => {
		const selectedDoorGroup = getDoorGroupIdFromUniqueId(uniqueId);
		const response = await deviceAdminApi.getDoorGroup(selectedDoorGroup);

		if (handleResponse(response)) {
			return;
		}

		return response.Entity;
	};

	const getSelectedDoorGroup = async (uniqueId: string): Promise<DoorGroupAndMasterInfo> => {
		return doorGroups.find(x => x.UniqueId === uniqueId) ?? (await getDoorGroup(uniqueId));
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const handleActionScope = async (key: string) => {
		const selectedDoorGroupUniqueId = selectedRowKeys?.[0].toString();
		const doorGroup: DoorGroupAndMasterInfo = await getSelectedDoorGroup(selectedDoorGroupUniqueId);
		const isMasterDoor: boolean = doorGroup.IsMasterDoor;
		const deviceId: number = doorGroup.Id;
		const lockComponentCondition: boolean = key === 'edit';
		const isLocked: boolean = await doorGroupLockedValidation(deviceId, isMasterDoor, lockComponentCondition);
		if (isLocked) {
			return;
		}

		switch (key) {
			case 'delete':
				const selectedDoorGroups = await getSelectedDoorGroups();
				ModalConfirmationList({
					type: _('DoorGroup'),
					data: selectedDoorGroups,
					onConfirm: () => onTryDeleteDoorGroup(selectedDoorGroups),
				});
				break;
			case 'rename':
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: DoorGroupColumns = tableData.find(x => x.key === doorGroup.UniqueId.toString());
				edit(findKey);
				const cloneState: DoorGroupAndMasterInfo[] = changeStateProps(doorGroup.UniqueId, { editable: true });
				dispatch(setDoorGroupsByData(cloneState));
				break;

			case 'edit':
				if (isMasterDoor) {
					handleSetModalMasterDoorVisible(true, doorGroup);
				} else {
					handleSetModalVisible(true, doorGroup);
				}
				break;
		}
	};

	const changeStateProps = (uniqueId: string, doorGroupAndMasterInfo: Partial<DoorGroupAndMasterInfo>): DoorGroupAndMasterInfo[] => {
		const cloneState: DoorGroupAndMasterInfo[] = [...doorGroups];
		const index = cloneState.findIndex(x => x.UniqueId === uniqueId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...doorGroupAndMasterInfo });
		}

		return cloneState;
	};

	const edit = (record: DoorGroupColumns) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const resetSearchColumn = () => {
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const handleOnSaveEditRow = async (doorGroup: DoorGroupAndMasterInfo) => {
		const deviceId: number = doorGroup.Id;
		const uniqueId: string = doorGroup.UniqueId;
		const isMasterDoorGroup: boolean = doorGroup.IsMasterDoor;

		const isLocked: boolean = await doorGroupLockedValidation(deviceId, isMasterDoorGroup, false);
		if (isLocked) {
			setEditingKey('');
			const cloneState: DoorGroupAndMasterInfo[] = changeStateProps(uniqueId, { editable: false });
			dispatch(setDoorGroupsByData(cloneState));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(
			DeviceObjectType.DoorGroup,
			name.trim(),
			deviceId,
			isMasterDoorGroup ? DoorGroupType.Master : DoorGroupType.Standard
		);

		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setDoorGroupsBy(tablePaginationSetting));
			return;
		}

		setEditingKey('');
		dispatch(setVelocityConfigurationEditMode(false));
		resetSearchColumn();
		setRedirectPage(true);
		dispatch(setDoorGroupsBy(tablePaginationSetting));
	};

	const handleOnCancelEditRow = (doorGroup: DoorGroupAndMasterInfo) => {
		const cloneState: DoorGroupAndMasterInfo[] = changeStateProps(doorGroup.UniqueId, { editable: false });
		dispatch(setDoorGroupsByData(cloneState));
		dispatch(setVelocityConfigurationEditMode(false));
		setEditingKey('');
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getDoorGroupsAndMasterDoorGroups();
		const doorGroupKeys = response?.map<string>(x => x.UniqueId.toString());
		return getUniqueValuesArray(newSelectedRowKeys, doorGroupKeys);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: DoorGroupAndMasterInfo[] = doorGroups.map(x => ({ ...x, checked: selectedRowKeys.findIndex(r => r === x.UniqueId) !== -1 }));
		dispatch(setDoorGroupsByData(cloneState));
		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			selectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(selectedRowKeys);
		const selectedDoorGroupKeys: SelectedDoorGroup[] = doorGroups
			.filter(doorGroup => selectedRowKeys.findIndex(x => x === doorGroup.UniqueId) !== -1)
			.map<SelectedDoorGroup>(x => ({ DeviceId: x.Id, UniqueId: x.UniqueId, IsMasterDoorGroup: x.IsMasterDoor, Name: x.Name }));
		setSelectedDoorGroupKeys(selectedDoorGroupKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: DoorGroupAndMasterInfo[] = doorGroups.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setDoorGroupsByData(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		setIsTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = doorGroups.map<React.Key>(x => x.UniqueId.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		const cloneState: DoorGroupAndMasterInfo[] = doorGroups.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.UniqueId?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setDoorGroupsByData(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		const selectedDoorGroupKeys: SelectedDoorGroup[] = doorGroups
			.filter(doorGroup => selectedRowKeys.findIndex(x => x === doorGroup.UniqueId) !== -1)
			.map<SelectedDoorGroup>(x => ({ DeviceId: x.Id, UniqueId: x.UniqueId, IsMasterDoorGroup: x.IsMasterDoor, Name: x.Name }));
		setSelectedDoorGroupKeys(selectedDoorGroupKeys);
	};

	const handleSetModalVisible = (visible: boolean, door?: DoorGroupAndMasterInfo) => {
		setDoorToEdit(door);
		setOpenModal(visible);
	};

	const handleSetModalMasterDoorVisible = (visible: boolean, door?: DoorGroupAndMasterInfo) => {
		setDoorToEdit(door);
		setOpenMasterModal(visible);
	};

	const handleOkConfirmation = async (doorGroup: DoorGroupAndMasterInfo) => {
		const isMasterDoor = doorGroup.IsMasterDoor;
		const objectId = doorGroup.Id;
		const response: ResponseObject = isMasterDoor ? await deviceAdminApi.deleteMasterDoorGroup(objectId) : await deviceAdminApi.deleteDoorGroup(objectId);
		const isValid = !handleResponse(response);

		updateDeleteState(doorGroup);
		if (isValid) {
			const selectedDoorGroup = selectedDoorGroupKeys.find(x => x.DeviceId === doorGroup.Id && x.IsMasterDoorGroup === doorGroup.IsMasterDoor);
			setSelectedRowKeys(prevState => [...prevState.filter(key => key !== selectedDoorGroup.UniqueId)]);
			setSelectedDoorGroupKeys(prevState => [...prevState.filter(doorGroup => doorGroup.UniqueId !== selectedDoorGroup.UniqueId)]);
			const newSelectedRowKeysPagination = selectedRowKeysPagination.filter(x => x.Id !== selectedDoorGroup.DeviceId);
			dispatch(setVelocitySelectedRowKeysPaginationAction([...newSelectedRowKeysPagination]));
		}

		dispatch(setDoorGroupsBy(tablePaginationSetting));
	};

	const handleCancelConfirmation = async (doorGroup: Partial<DoorGroupAndMasterInfo>) => {
		await deviceAdminApi.unlockComponentDeviceAdmin(
			doorGroup.Id,
			doorGroup.IsMasterDoor ? SecuredComponents.Master_Door_Group : SecuredComponents.Door_Groups
		);
		updateDeleteState(doorGroup);
	};

	const updateDeleteState = (doorGroup: Partial<DoorGroupAndMasterInfo>) => {
		setDoorToDelete(doorGroups => {
			return [...doorGroups.filter(x => x.UniqueId !== doorGroup.UniqueId)];
		});
	};

	const onTryDeleteDoorGroup = async (selectedDeviceKeys: SelectedDeviceKeys[]) => {
		const selectedDoorGroups = selectedDeviceKeys.map<DoorGroupAndMasterInfo>(
			x =>
				({
					Id: x.Id,
					Name: x.Name,
					IsMasterDoor: x.IsMasterDoorGroup,
				} as DoorGroupAndMasterInfo)
		);

		setDoorToDelete(() => {
			return [...selectedDoorGroups];
		});

		for (const doorGroup of selectedDoorGroups) {
			const doorName = doorGroup.Name;
			const isMasterDoor = doorGroup.IsMasterDoor;
			const objectId = doorGroup.Id;
			const response: ResponseObject = isMasterDoor
				? await deviceAdminApi.canDeleteMasterDoorGroup(objectId)
				: await deviceAdminApi.canDeleteDoorGroup(objectId);

			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					handleOkConfirmation(doorGroup);
				},
				onAdditionalInfoRequiredCallback: async () => {
					const warningData = await deviceAdminApi.getDoorGroupAndMasterDoorDeleteWarning(objectId, isMasterDoor, response.ResponseErrorDescription);
					const { IsMasterDoorGroup, DoorGroupFGsAndFGExts, DoorGroupUsersAndCredentials, MasterDoorGroupsByDG, ErrorMessage } = warningData;
					const content = (
						<DoorWarning
							isMasterGroup={IsMasterDoorGroup}
							messageError={ErrorMessage}
							doorGroupFGsAndFGExts={DoorGroupFGsAndFGExts}
							doorGroupUsersAndCredentials={DoorGroupUsersAndCredentials}
							masterDoorGroupsByDG={MasterDoorGroupsByDG}
						/>
					);
					const contentMessage = <label dangerouslySetInnerHTML={{ __html: createMessageContent(isMasterDoor, doorName) }} />;

					ModalConfirmation({
						title: contentMessage,
						content,
						width: '644px',
						onConfirm: () => handleOkConfirmation(doorGroup),
						onCancel: () => handleCancelConfirmation(doorGroup),
					});
				},
				onComponentLockedCallback: async () => {
					const warningType = DeviceAdminWarningType[response.ResponseStatusCode] as DeviceAdminWarningTypeString;
					const conflictModel = await deviceAdminApi.getDoorGroupLockStatusWarning(warningType, objectId, true);
					ModalWarning({
						width: '600px',
						hideCancelButton: true,
						okText: 'OK',
						content: <DoorGroupLockStatus conflictModel={conflictModel} />,
					});
				},
				onPermissionErrorCallback: async () => {
					notification['error']({
						message: response.ErrorMessage,
					});
				},
			});
		}
	};

	const createActionElement = (doorGroup: DoorGroupAndMasterInfo): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (doorGroup.editable) {
			content = (
				<>
					<Button id={'editableSaveButton'} key='save' type='primary' onClick={() => handleOnSaveEditRow(doorGroup)}>
						{_('Save')}
					</Button>
					<Button id={'editableCancelButton'} key='cancel' onClick={() => handleOnCancelEditRow(doorGroup)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): DoorGroupColumns[] => {
		if (doorGroups.length > 0) {
			return doorGroups.map<DoorGroupColumns>((dataItem: DoorGroupAndMasterInfo) => ({
				key: dataItem.UniqueId.toString(),
				Action: createActionElement(dataItem),
				Name: dataItem.Name,
				Type: dataItem.IsMasterDoor ? _('MasterDoorGroup') : _('DoorGroup'),
			}));
		}

		return [];
	};

	const createMessageContent = (isMasterDoorGroup: boolean, doorName: string): string => {
		let content: string;
		if (isMasterDoorGroup) {
			content = _('AreYouSureYouWantToDeleteMasterDoorGroup');
		} else {
			content = _('AreYouSureYouWantToDeleteDoorGroup');
		}

		return content.replace('%1', doorName);
	};

	const handleBeforeUnload = () => {
		setDoorToDelete(doorGroups => {
			for (const doorGroup of doorGroups) {
				deviceAdminApi.unlockComponentDeviceAdmin(
					doorGroup.Id,
					doorGroup.IsMasterDoor ? SecuredComponents.Master_Door_Group : SecuredComponents.Door_Groups
				);
			}

			return doorGroups;
		});
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} disabled={x.disabled} onClick={x.onClick} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const buttons: ButtonBuilder[] = [
		{
			id: 'doorGroupAddDoorGroupButton',
			label: _('AddDoorGroup'),
			icon: <PlusOutlined />,
			disabled: !doorGroupComponentPermission.canAdd || isEditMode,
			onClick: () => handleSetModalVisible(true, undefined),
			title: getPermissionErrorMessage(doorGroupComponentPermission.canAdd),
		},
		{
			id: 'doorGroupAddMasterDoorGroupButton',
			label: _('AddMasterDoorGroup'),
			icon: <PlusOutlined />,
			disabled: !masterDoorGroupComponentPermission.canAdd || isEditMode,
			onClick: () => handleSetModalMasterDoorVisible(true, undefined),
			title: getPermissionErrorMessage(masterDoorGroupComponentPermission.canAdd),
		},
	];

	const disabled: boolean = editingKey !== '';
	const hasMoreThanOneKeySelected: boolean = selectedRowKeys.length > 1;
	const selectedDoorGroups: DoorGroupAndMasterInfo[] = doorGroups.filter(x => selectedRowKeys.includes(x.Id.toString()));
	const items = createButtonOptions(hasMoreThanOneKeySelected, isItemOnSamePage, selectedDoorGroups);

	const handleOnSearchResults = (searchedValue: string) => {
		if (redirectPage.current) {
			return;
		}
		dispatch(setDoorGroupsBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	let columns: ColumnsProps<DoorGroupColumns>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
		{
			...buildColumn(_('Type'), 'Type', 'auto', 'start'),
			sorter: !isEditMode,
		},
	];
	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const onChangeSelection = (key: BaseColumns) => {
		const doorGroupId = key.key.toString();
		if (selectedRowKeys.findIndex(key => key === doorGroupId) === -1) {
			const cloneState: DoorGroupAndMasterInfo[] = doorGroups.map(x =>
				x.Id.toString() === doorGroupId ? { ...x, checked: true } : { ...x, checked: false }
			);
			dispatch(setDoorGroupsByData(cloneState));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: doorGroupId,
				PaginationPage: tablePaginationSetting.PageNumber,
			};
			setIsItemOnSamePage(true);
			dispatch(setVelocitySelectedRowKeysPaginationAction([{ ...selectedKeysPagination }]));
			setSelectedRowKeys([doorGroupId]);
			const selectedDoorGroupKeys: SelectedDoorGroup[] = doorGroups
				.filter(doorGroup => doorGroup.UniqueId === doorGroupId)
				.map<SelectedDoorGroup>(x => ({ DeviceId: x.Id, UniqueId: x.UniqueId, IsMasterDoorGroup: x.IsMasterDoor, Name: x.Name }));
			setSelectedDoorGroupKeys(selectedDoorGroupKeys);
		}
	};

	const isEditing = (record: DoorGroupColumns) => record.key.toString() === editingKey;
	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];
	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: DoorGroupColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: DoorGroupColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,

						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const rowSelection: TableRowSelection<DoorGroupColumns> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `doorGroupsTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`doorGroupsTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectDoorGroup')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<OperatorsColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setDoorGroupsBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<div id='doorGroupContainer' className={styles.container}>
			<div className={styles.buttonContainer}>
				<ButtonDropdown
					id={'doorGroupTableActionDropdown'}
					disabled={disabledActionButtons}
					menuOptions={items.options}
					icon={<CaretDownOutlined />}
					labelIcon={items.labelOrIcon}
					onClickOption={handleActionScope}
				/>
				{buttonsBuilder(buttons)}
			</div>
			<Form form={form} component={false}>
				<Table
					id='doorGroupTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					pagination={pagination}
					scroll={scroll}
					size='small'
					className={styles.statusWidgetTable}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
					rowClassName={getRowClassName}
				/>
			</Form>
			{openModal && (
				<DoorModal
					doorToEdit={doorToEdit}
					onSetVisible={() => handleSetModalVisible(false)}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
			{openMasterModal && (
				<DoorMasterModal
					doorToEdit={doorToEdit}
					onSetVisible={() => handleSetModalMasterDoorVisible(false)}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
		</div>
	);
};

export { DoorGroup };

import { Spin, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { canAddNewTimeZones } from '../../../../../Helper';
import { enrollmentApi } from '../../../../../api';
import { deviceAdminApi } from '../../../../../api/DeviceAdminApi';
import { SubPermissions, User } from '../../../../../model/AccountModel';
import {
	AssuranceLevelList,
	CurrentDeviceControlObj,
	DeviceObjectType,
	GenericControlTimeZones,
	Input,
	InputLogicTimeZones,
	Reader,
	ReaderError,
	RelayLogicTimeZones,
	SelectTimeZone,
	TimeZoneType,
} from '../../../../../model/DeviceAdminModel';
import styles from '../../DoorModal/General/general.module.scss';
import { RelayLogicContainer } from '../../ExpansionRelayModal/RelayLogicContainer/RelayLogicContainer';
import {
	setActuateTimeZone,
	setActuateTimeZones,
	setAutoClearAfterTimeZone,
	setAutoClearAfterTimeZones,
	setControlDelay,
	setControlMode,
	setControllerId,
	setDisableTimeZone,
	setDisableTimeZones,
	setDoorDelayTime,
	setName,
	setNormalState,
	setOperateTimeZone,
	setOperateTimeZones,
	setReTriggerControlZone,
	setReTriggerControlZones,
	setTriggerControlZone,
	setTriggerControlZones,
	setUnpermittedActuateTimeZone,
	setUnpermittedAutoClearTimeZone,
	setUnpermittedDisableTimeZone,
	setUnpermittedOperateTimeZone,
} from '../../ExpansionRelayModal/contextExpansionRelay';
import stylesRelay from '../../ExpansionRelayModal/expansionRelayModal.module.scss';
import { InputContainer } from '../../InputModal/InputContainer/InputContainer';
import { initInputAction, setControlZonesAction, setInputAction, setLineModulesAction } from '../../InputModal/inputModalContext';
import { ExitReaderContainer } from '../../ReaderModal/ExitReaderModal/ExitReaderContainer/ExitReaderContainer';
import {
	initExitReaderAction,
	setExitReaderAssuranceInfo,
	setExitReaderGenericTimeZones,
	setExitReaderUnpermittedCardCodeOnly,
} from '../../ReaderModal/ExitReaderModal/exitReaderContext';
import { ReaderContainer } from '../../ReaderModal/ReaderContainer/ReaderContainer';
import { initReaderAction, setAssuranceInfo, setGenericTimeZones, setUnpermittedCardCodeOnly } from '../../ReaderModal/contextReader';
import { DoorState, setInitDoorAction } from '../DoorModalContext';
import { DoorStoreSingleContext } from '../DoorStoreSingleContext';
import { General } from '../General/General';

const { TabPane } = Tabs;

type Props = {
	currentDevice: CurrentDeviceControlObj;
	submittedForm: boolean;
	doorNameError: boolean;
	relayNameError: boolean;
	inputNameError: boolean;
	isLoading: boolean;
	errorText: string;
	errorType: ReaderError;
	activeTab: string;
	setActiveTab: (string) => void;
	onResetDoorNameError: () => void;
	onResetRelayNameError: () => void;
	onResetInputNameError: () => void;
	setErrorText: () => void;
	setLoading: (flag: boolean) => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();

const DoorContainer: React.FC<Props> = ({
	currentDevice,
	submittedForm,
	doorNameError,
	relayNameError,
	inputNameError,
	isLoading,
	errorText,
	errorType,
	activeTab,
	setActiveTab,
	onResetDoorNameError,
	onResetRelayNameError,
	onResetInputNameError,
	setErrorText,
	setLoading,
}) => {
	const canViewGeneralTab: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewGeneralTab).allowed;
	const canViewEntryReaderTab: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewEntryReaderTab).allowed;
	const canViewExitReaderTab: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewExitReaderTab).allowed;
	const canViewRelayTab: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewRelayTab).allowed;
	const canViewInputTab: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewInputTab).allowed;

	const [downloadedFirmware, setDownloadedFirmware] = useState<boolean>(false);
	const [previousSelections, setPreviousSelections] = useState<InputLogicTimeZones>({ MaskDuring: null, DisableEntry: null });
	const [relayTimeZonesFromDoor, setRelayTimeZonesFromDoor] = useState<RelayLogicTimeZones>(undefined);

	const {
		contextStateDoor: {
			door: { door, inputBelongToDoor, relayBelongToDoor, entryReaderId, exitReaderId },
			entryReader,
			exitReader,
			relay,
			input: { input },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	useEffect(() => {
		if (currentDevice.Id !== 0) {
			Promise.all([enrollmentApi.getBadgeTemplates(), deviceAdminApi.getDoor(currentDevice.Id)]).then(res => {
				const badgeTemplates = res[0];
				const door = res[1];

				const initDoor: DoorState = {
					badgeTemplates,
					door,
					inputBelongToDoor: {
						Id: door.InputId,
						DeviceObjectType: DeviceObjectType.Input,
					},
					relayBelongToDoor: {
						Id: door.RelayId,
						DeviceObjectType: DeviceObjectType.Relay,
					},
					entryReaderId: door.EntryReaderId,
					exitReaderId: door.ExitReaderId,
					newTimeZoneAdded: 0,
				};

				dispatcherDoor(setInitDoorAction(initDoor));
			});
		}
	}, []);

	useEffect(() => {
		if (inputBelongToDoor) {
			deviceAdminApi.getGenericsControlTimeZonesByInput(inputBelongToDoor.Id, false).then(res => dispatcherDoor(setControlZonesAction(res)));
			deviceAdminApi.getLineModulesForInputs().then(res => dispatcherDoor(setLineModulesAction(res)));
			deviceAdminApi.getInput(inputBelongToDoor.Id).then(res => {
				if (!canAddNewTimeZones()) {
					const genericTimezones = res.GenericTimeZones.filter(item => item.GenericId !== 0);
					res.GenericTimeZones = [...genericTimezones];
				}

				if (door.IsDoorWireless) {
					dispatcherDoor(
						initInputAction({
							...res,
							LineModuleId: 1,
							moduleTypeDisabled: true,
							RQEEnabled: true,
							RQEType: 2,
							inputRQEDisabled: true,
						} as Partial<Input>)
					);
				} else {
					dispatcherDoor(initInputAction(res as Partial<Input>));
				}
				setPreviousSelections({ MaskDuring: res.MaskDuringTimeZoneSelected, DisableEntry: res.DisabledDuringTimeZoneSelected });
			});
		}
	}, [inputBelongToDoor]);

	useEffect(() => {
		if (relayBelongToDoor) {
			deviceAdminApi.loadRelay(relayBelongToDoor.Id).then(res => {
				const noControlZone: GenericControlTimeZones = {
					Name: `<${_('NoControl')}>`,
					CZIndex: 0,
				};

				dispatcherDoor(setName(res.Name));
				dispatcherDoor(setNormalState(res.NormalState));
				dispatcherDoor(setControlMode(res.ControlMode));
				dispatcherDoor(setControlDelay(res.ControlDelay));
				dispatcherDoor(setControllerId(res.ControllerId));

				if (canAddNewTimeZones()) {
					const newTimeZone: SelectTimeZone = {
						Name: `<${_('New')}>`,
						Unpermitted: true,
						GenericId: 0,
						TimeZoneType: TimeZoneType.Standard,
						GlobalId: 0,
					};

					res.GenericTimeZones.unshift(newTimeZone);
				}

				dispatcherDoor(setOperateTimeZones(res.GenericTimeZones));
				dispatcherDoor(setActuateTimeZones(res.GenericTimeZones));
				dispatcherDoor(setAutoClearAfterTimeZones(res.GenericTimeZones));
				dispatcherDoor(setDisableTimeZones(res.GenericTimeZones));

				res.ControlZones.unshift(noControlZone);
				res.StandardControlZones.unshift(noControlZone);
				dispatcherDoor(setTriggerControlZones(res.ControlZones));
				dispatcherDoor(setReTriggerControlZones(res.StandardControlZones));

				if (res.OperateTimeZone.Unpermitted) {
					dispatcherDoor(setUnpermittedOperateTimeZone(res.OperateTimeZone));
				}

				if (res.ActuateTimeZone.Unpermitted) {
					dispatcherDoor(setUnpermittedActuateTimeZone(res.ActuateTimeZone));
				}

				if (res.DisableTimeZone.Unpermitted) {
					dispatcherDoor(setUnpermittedDisableTimeZone(res.DisableTimeZone));
				}

				if (res.AutoClearTimeZone.Unpermitted) {
					dispatcherDoor(setUnpermittedAutoClearTimeZone(res.AutoClearTimeZone));
				}

				dispatcherDoor(setOperateTimeZone(res.OperateTimeZone.GenericId));
				dispatcherDoor(setActuateTimeZone(res.ActuateTimeZone.GenericId));
				dispatcherDoor(setDisableTimeZone(res.DisableTimeZone.GenericId));
				dispatcherDoor(setAutoClearAfterTimeZone(res.AutoClearTimeZone.GenericId));

				dispatcherDoor(setTriggerControlZone(res.TriggerControlZone));
				dispatcherDoor(setReTriggerControlZone(res.ReTriggerControlZone));
				dispatcherDoor(setDoorDelayTime(res.DoorDelayTime));

				const relayTimeZonesBelongFromDoor: RelayLogicTimeZones = {
					Actuate: res.ActuateTimeZone.GenericId,
					AutoClear: res.AutoClearTimeZone.GenericId,
					Disable: res.DisableTimeZone.GenericId,
					Operate: res.OperateTimeZone.GenericId,
				};
				setRelayTimeZonesFromDoor(relayTimeZonesBelongFromDoor);
				setLoading(false);
			});
		}
	}, [relayBelongToDoor]);

	useEffect(() => {
		if (entryReaderId !== 0) {
			deviceAdminApi.loadReader(entryReaderId).then((res: Reader) => {
				if (canAddNewTimeZones()) {
					const newTimeZone: SelectTimeZone = {
						Name: `<${_('New')}>`,
						Unpermitted: true,
						GenericId: 0,
						TimeZoneType: TimeZoneType.Standard,
						GlobalId: 0,
					};
					res.GenericTimeZones.unshift(newTimeZone);
				}

				dispatcherDoor(initReaderAction(res));

				if (res.CardCodeOnlyTimeZone.Unpermitted) {
					dispatcherDoor(setUnpermittedCardCodeOnly(res.CardCodeOnlyTimeZone));
				}
			});
		}
	}, [entryReaderId]);

	useEffect(() => {
		if (exitReaderId !== 0) {
			deviceAdminApi.loadReader(exitReaderId).then((res: Reader) => {
				if (canAddNewTimeZones()) {
					const newTimeZone: SelectTimeZone = {
						Name: `<${_('New')}>`,
						Unpermitted: true,
						GenericId: 0,
						TimeZoneType: TimeZoneType.Standard,
						GlobalId: 0,
					};
					res.GenericTimeZones.unshift(newTimeZone);
				}

				dispatcherDoor(initExitReaderAction(res));

				if (res.CardCodeOnlyTimeZone.Unpermitted) {
					dispatcherDoor(setExitReaderUnpermittedCardCodeOnly(res.CardCodeOnlyTimeZone));
				}
			});
		}
	}, [exitReaderId]);

	useEffect(() => {
		if (entryReader.selections.rS485ReaderType !== 0 && entryReader.supportedReaderClass === 0) {
			deviceAdminApi.getAssuranceLevels(entryReader.selections.rS485ReaderType).then((res: AssuranceLevelList) => {
				dispatcherDoor(setAssuranceInfo(res));
			});
		}
		if (exitReader.selections.rS485ReaderType !== 0 && exitReader.supportedReaderClass === 0) {
			deviceAdminApi.getAssuranceLevels(exitReader.selections.rS485ReaderType).then((res: AssuranceLevelList) => {
				dispatcherDoor(setExitReaderAssuranceInfo(res));
			});
		}
	}, [entryReader.selections.rS485ReaderType, exitReader.selections.rS485ReaderType]);

	useEffect(() => {
		if (door) {
			setLoading(false);
		}
	}, [door]);

	const handleDownloadFirmware = () => {
		setDownloadedFirmware(true);
	};

	const onRefreshTimeZones = (timeZones: SelectTimeZone[]) => {
		dispatcherDoor(setOperateTimeZones(timeZones));
		dispatcherDoor(setActuateTimeZones(timeZones));
		dispatcherDoor(setAutoClearAfterTimeZones(timeZones));
		dispatcherDoor(setDisableTimeZones(timeZones));

		dispatcherDoor(setGenericTimeZones(timeZones));

		dispatcherDoor(setInputAction({ GenericTimeZones: timeZones }));

		dispatcherDoor(setExitReaderGenericTimeZones(timeZones));
	};

	const isRelayNameEmpty = submittedForm && relay.name === '';
	const isInputNameEmpty = submittedForm && input.Name === '';
	return (
		<>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
				{door && (
					<Tabs activeKey={activeTab} onChange={activeKey => setActiveTab(activeKey)} type='card'>
						{canViewGeneralTab && (
							<TabPane tab={<span id='doorModalGeneralTabName'>{_('General')}</span>} key='1'>
								<General
									nameError={doorNameError}
									nameErrorText={errorText}
									onResetNameError={onResetDoorNameError}
									setErrorText={setErrorText}></General>
							</TabPane>
						)}
						{canViewEntryReaderTab && (
							<TabPane tab={<span id='doorModalEntryReaderTabName'>{_('EntryReader')}</span>} key='2'>
								<ReaderContainer
									submittedForm={submittedForm}
									handleDownloadFirmware={handleDownloadFirmware}
									errorType={errorType}
									errorMessage={errorText}
									id={entryReaderId}
									setLoading={setLoading}
									isLoading={isLoading}
									useDoorContext={true}
									setErrorText={setErrorText}
									onRefreshTimeZones={onRefreshTimeZones}
								/>
							</TabPane>
						)}
						{canViewExitReaderTab && !door.EntryReadersOnly && (
							<TabPane tab={<span id='doorModalExitReaderTabName'>{_('ExitReader')}</span>} key='3'>
								<ExitReaderContainer
									submittedForm={submittedForm}
									handleDownloadFirmware={handleDownloadFirmware}
									errorType={errorType}
									errorMessage={errorText}
									id={exitReaderId}
									setLoading={setLoading}
									isLoading={isLoading}
									setErrorText={setErrorText}
								/>
							</TabPane>
						)}
						{canViewRelayTab && (
							<TabPane tab={<span id='doorModalRelayTabName'>{_('Relay')}</span>} key='4'>
								<div className={stylesRelay.container}>
									<RelayLogicContainer
										deviceType={relayBelongToDoor.DeviceObjectType}
										isNameEmpty={isRelayNameEmpty}
										nameError={relayNameError}
										nameErrorText={errorText}
										setNameError={onResetRelayNameError}
										id={relayBelongToDoor.Id}
										setLoading={setLoading}
										isLoading={isLoading}
										useDoorContext={true}
										setErrorText={setErrorText}
										onRefreshTimeZones={onRefreshTimeZones}
										timeZonesBelongFromDoor={relayTimeZonesFromDoor}
									/>
								</div>
							</TabPane>
						)}
						{canViewInputTab && door.IsInputTabVisible && (
							<TabPane tab={<span id='doorModalInputTabName'>{_('Input')}</span>} key='5'>
								<InputContainer
									currentDeviceObj={inputBelongToDoor}
									nameValidation={isInputNameEmpty}
									nameError={inputNameError}
									errorText={errorText}
									onResetNameError={onResetInputNameError}
									setLoading={setLoading}
									isLoading={isLoading}
									useDoorContext={true}
									previousSelection={previousSelections}
									setErrorText={setErrorText}
									onRefreshTimeZones={onRefreshTimeZones}
								/>
							</TabPane>
						)}
					</Tabs>
				)}
			</Spin>
		</>
	);
};

export { DoorContainer };

import React, { Key } from 'react';
import { ConfigurationState, ProfileSetting, SettingsValue } from '../../../model/ConfigurationModel';
import { CheckboxHelper } from '../Common/CheckboxHelper';
import { InputNumberHelper } from '../Common/InputNumberHelper';
import styles from './eventSettings.module.scss';

// Normal properties for the component
interface Props {
	configuration: ConfigurationState;
	onSetViewSettingValue: (value: SettingsValue<string>) => void;
	onSetCheckBoxesEnabled: (value: SettingsValue<boolean>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
}

//Avoid creating object style inline, since increases reconciliations
const minValue: number = 1;

const EventSettings: React.FC<Props> = ({ configuration, onSetViewSettingValue, onSetCheckBoxesEnabled, onSetSettingsHaveChanged }) => {
	const eventsPerEventViewer: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'MaxLines');
	const eventsPerPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'EventsPerPage');
	const loadCachedEvents: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'LoadCachedEvents');
	const showEventsOnEnrollmentPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnEnrollmentPage');
	const showEventsOnDeviceAdmPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnDeviceAdmPage');
	const showEventsOnAlarmsPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnAlarmsPage');
	const showEventsOnPhotoCallUpPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnPhotoCallUpPage');
	const showEventsOnStatusViewerPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnStatusViewerPage');
	const showEventsOnWhosInsidePage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnWhosInsidePage');
	const showEventsOnReportingPage: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'ShowEventsOnReportingPage');

	const setSettingsHaveChanged = () => {
		if (!configuration?.HaveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	const handleSettingChange = (value: string, key: string) => {
		if (!Number(value)) {
			return;
		}
		const setValues: SettingsValue<string> = {
			key,
			value,
		};
		onSetViewSettingValue(setValues);
		setSettingsHaveChanged();
	};

	const handleEnableCheckBox = (values: SettingsValue<boolean>) => {
		onSetCheckBoxesEnabled(values);
		setSettingsHaveChanged();
	};

	const handleOnChangeNumber = (profileName: string, value: Key) => {
		if (!value || value === undefined || (value as number) < minValue) {
			value = minValue;
		}

		handleSettingChange(value.toString(), profileName);
	};

	return (
		<div className={styles.container} id='ConfigTabData_3'>
			<div className={styles.row}>
				<div>{_('MaximumLinesToDisplay')}</div>
				<div className={styles.maximumOptions}>
					{_('Show')}
					<InputNumberHelper
						profileSetting={eventsPerEventViewer}
						min={minValue}
						max={1500}
						maxLength={4}
						onChange={value => handleOnChangeNumber(eventsPerEventViewer.Name, value)}
						id='eventsPerEventViewer'
					/>
				</div>
				<div>{_('MaximumPageSize')}</div>
				<div className={styles.listOptions}>
					<div className={styles.maximumOptions}>
						{_('Show')}
						<InputNumberHelper
							profileSetting={eventsPerPage}
							min={minValue}
							max={100}
							maxLength={3}
							onChange={value => handleOnChangeNumber(eventsPerPage.Name, value)}
							id='EventsPerPage'
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={loadCachedEvents}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: loadCachedEvents.Name,
								})
							}
						/>
					</div>
				</div>
			</div>
			<div className={styles.row}>
				<div>{_('ShowEvents')}</div>
				<div className={styles.listOptions}>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnEnrollmentPage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnEnrollmentPage.Name,
								})
							}
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnAlarmsPage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnAlarmsPage.Name,
								})
							}
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnDeviceAdmPage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnDeviceAdmPage.Name,
								})
							}
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnStatusViewerPage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnStatusViewerPage.Name,
								})
							}
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnWhosInsidePage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnWhosInsidePage.Name,
								})
							}
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnPhotoCallUpPage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnPhotoCallUpPage.Name,
								})
							}
						/>
					</div>
					<div>
						<CheckboxHelper
							profileSetting={showEventsOnReportingPage}
							onChange={e =>
								handleEnableCheckBox({
									value: e,
									key: showEventsOnReportingPage.Name,
								})
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export { EventSettings };

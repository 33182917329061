import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { buildColumn, ScrollType } from '../../../../../../../Helper';
import { ControllerReader, ControlZone, DataTableType } from '../../../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../../../common/SearchColumn/SearchColumn';
import { ControllersSelected, setControlZones, setControlZoneSelected, setErrors, setReadersPreSelected, StoreContext } from '../../contextReader';
import styles from './readersGrid.module.scss';

type Props = {
	reset: boolean;
	onSelectAll: (checked: boolean) => void;
};

type DataType = DataTableType<ControllerReader>;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 156 };

const ReadersGrid: React.FC<Props> = ({ reset, onSelectAll }) => {
	const [currentSelection, setCurrentSelection] = useState(undefined as number);
	const [selectedRowKeys, setSelectedRowKeys] = useState([] as React.Key[]);

	const {
		readerState: {
			readers,
			controlZoneSelected,
			readersSelected,
			readersPreSelected,
			displayMyNetworkLayout,
			errors: { readerInput },
		},
		dispatcher,
	} = useContext(StoreContext);

	useEffect(() => {
		const selectedKeys = selectedRowKeys.filter(x => readersSelected.findIndex(f => f.ReaderId === x) < 0);
		setSelectedRowKeys(selectedKeys);

		//set readers preselected on selected keys for avoiding to add unselected items
		if (selectedRowKeys.length > 0) {
			const zone: ControlZone = {
				ControlZoneIndex: controlZoneSelected.ControlZoneIndex,
				ControlZoneName: controlZoneSelected.ControlZoneName,
				IsMasterControlZone: controlZoneSelected.IsMasterControlZone,
			};
			const selected: ControllersSelected[] = readersPreSelected
				.filter(x => selectedRowKeys.indexOf(x.ReaderId) >= 0)
				.map(x => ({
					Address: x.Address,
					ControlZoneIndex: zone.ControlZoneIndex,
					ReaderId: x.ReaderId,
					ControlZoneName: zone.ControlZoneName,
					ControllerId: x.ControllerId,
					ControllerName: x.ControllerName,
					ReaderIndex: x.ReaderIndex,
					ReaderName: x.ReaderName,
					IsMasterControlZone: zone.IsMasterControlZone,
				}));
			dispatcher(setReadersPreSelected([...selected]));
		}
	}, [readersSelected.length]);

	useEffect(() => {
		setCurrentSelection(undefined);
		setSelectedRowKeys([]);
	}, [displayMyNetworkLayout]);

	//keep until any bug has been found
	// const handelSelectedReader = (reader: ControllerReader, isChecked: boolean) => {
	// 	if (isChecked) {
	// 		//reload control zones by user checking reader and avoid to select more than one reader whether display network is disabled
	// 		dispatcher(setControlZoneSelected(null));
	// 		deviceAdminApi.getControlZonesByController(reader.ControllerId).then(res => {
	// 			dispatcher(
	// 				setControlZones(
	// 					res.map(x => ({
	// 						ControlZoneIndex: x.CZIndex,
	// 						ControlZoneName: x.Name,
	// 					}))
	// 				)
	// 			);
	// 		});

	// 		const zone: ControlZone = {
	// 			IsMasterControlZone: controlZoneSelected?.IsMasterControlZone ?? false,
	// 			ControlZoneIndex: controlZoneSelected?.ControlZoneIndex ?? null,
	// 			ControlZoneName: controlZoneSelected?.ControlZoneName ?? null,
	// 		};
	// 		const newReader: ControllersSelected = {
	// 			...reader,
	// 			...zone,
	// 		};
	// 		if (displayMyNetworkLayout) {
	// 			const preSelected: ControllersSelected[] = readersPreSelected.map(x => ({
	// 				...x,
	// 				...zone,
	// 			}));

	// 			dispatcher(setReadersPreSelected([...preSelected, newReader]));
	// 		} else {
	// 			dispatcher(setReadersPreSelected([newReader]));
	// 		}
	// 		dispatcher(setErrors({ type: 'readerInput', value: false }));
	// 	} else {
	// 		const cloneState = readersPreSelected;
	// 		const findIndex = cloneState.findIndex(x => x.ReaderId === reader.ReaderId);
	// 		if (~findIndex) {
	// 			cloneState.splice(findIndex, 1);
	// 			dispatcher(setReadersPreSelected([...cloneState]));
	// 		}
	// 	}
	// };

	const handleCheckAllReaders = (checked: boolean) => {
		onSelectAll(checked);
		if (checked) {
			const selected: ControllersSelected[] = readers.map(x => ({
				...x,
				IsMasterControlZone: controlZoneSelected?.IsMasterControlZone ?? false,
				ControlZoneIndex: controlZoneSelected?.ControlZoneIndex ?? null,
				ControlZoneName: controlZoneSelected?.ControlZoneName ?? null,
			}));
			dispatcher(setReadersPreSelected([...selected]));
			dispatcher(setErrors({ type: 'readerInput', value: false }));
		} else {
			dispatcher(setReadersPreSelected([]));
		}
	};

	const buildColumns = () => {
		let columns: ColumnsType<any> = [
			{
				...buildColumn(_('Index'), 'ReaderIndex', '80px', 'start'),
				sorter: (a, b) => a.ReaderIndex - b.ReaderIndex,
			},
			{
				...buildColumn(_('Readers'), 'ReaderName', 'auto', 'start'),
				sorter: (a, b) => a.ReaderName.localeCompare(b.ReaderName),
				...SearchColumn({ maxLength: 32, dataIndex: 'ReaderName', reset: false, label: _('ReaderName'), searchColumnId: 'searchReadersGrid' }),
			},
		];

		if (!displayMyNetworkLayout) {
			columns = [
				{
					...buildColumn(_('Address'), 'Address', '210px', 'start'),
					sorter: (a, b) => a.Address.localeCompare(b.Address),
				},
				...columns,
			];
		}

		return columns;
	};

	const selectReader = (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
		setSelectedRowKeys(selectedRowKeys);

		if (selectedRows.length > 0) {
			const controllerId: number = selectedRows[0].ControllerId;
			const differentController: boolean = currentSelection !== controllerId;
			if (!displayMyNetworkLayout && differentController) {
				setCurrentSelection(controllerId);
				dispatcher(setControlZoneSelected(null));
				deviceAdminApi.getControlZonesByController(controllerId).then(res => {
					dispatcher(
						setControlZones(
							res.map(x => ({
								ControlZoneIndex: x.CZIndex,
								ControlZoneName: x.Name,
							}))
						)
					);
				});
			}
		}

		const zone: ControlZone = {
			IsMasterControlZone: controlZoneSelected?.IsMasterControlZone ?? false,
			ControlZoneIndex: controlZoneSelected?.ControlZoneIndex ?? null,
			ControlZoneName: controlZoneSelected?.ControlZoneName ?? null,
		};
		const selections: ControllersSelected[] = selectedRows.map(x => ({
			...x,
			...zone,
		}));
		dispatcher(setReadersPreSelected([...selections]));
		dispatcher(setErrors({ type: 'readerInput', value: false }));
	};

	// rowSelection object indicates the need for row selection
	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			selectReader(selectedRowKeys, selectedRows);
		},
		// onSelect: (record: DataType, selected: boolean) => {
		// 	handelSelectedReader(record, selected);
		// },
		onSelectAll: (selected: boolean) => {
			handleCheckAllReaders(selected);
		},
		getCheckboxProps: (record: DataType) => ({
			id: `readerControlGroupReaderRadioCheckBox-${record.ReaderId}`,
		}),
	};

	const createData = (data: ControllerReader[]) =>
		data.map<DataType>((dataItem: ControllerReader, index: number) => ({
			...dataItem,
			key: dataItem.ReaderId,
		}));

	const excludeSelectedDoors = () => createData(readers).filter(x => readersSelected.findIndex(w => w.ReaderId === Number(x.key)) < 0);

	const handleOnClickRow = (record: DataType) => {
		if (!displayMyNetworkLayout) {
			selectReader([record.key], [record]);
		} else {
			let selectedRows: React.Key[] = [];
			let readerSelected: DataType[] = [];

			if (selectedRowKeys.find(x => x === record.key)) {
				selectedRows = selectedRowKeys.filter(controllerSelected => controllerSelected !== record.key);
				readerSelected = readersPreSelected
					.filter(controllerSelected => controllerSelected.ReaderId !== record.ReaderId)
					.map(x => ({ ...x, key: x.ReaderId }));
			} else {
				selectedRows = [...selectedRowKeys, record.key];
				readerSelected = [...readersPreSelected.map(x => ({ ...x, key: x.ReaderId })), record];
			}

			selectReader([...selectedRows], [...readerSelected]);
		}
	};

	const readerControlGroupReadersTableId = 'readerControlGroupReadersTable';
	const style: React.CSSProperties = !displayMyNetworkLayout ? { width: '100%' } : undefined;

	return (
		<div className={cx(styles.container, { [styles.error]: readerInput })} style={style}>
			<div>
				<Table
					id={readerControlGroupReadersTableId}
					rowSelection={{
						...rowSelection,
						selectedRowKeys,
						type: !displayMyNetworkLayout ? 'radio' : 'checkbox',
					}}
					columns={buildColumns()}
					dataSource={excludeSelectedDoors()}
					scroll={scroll}
					className={cx(styles.statusWidgetTable)}
					pagination={false}
					size='small'
					onRow={(record: DataType) => ({
						onClick: () => handleOnClickRow(record),
					})}
					onHeaderRow={() => ({ id: `${readerControlGroupReadersTableId}Header` })}
					getPopupContainer={() => document.getElementById(`${readerControlGroupReadersTableId}Header`)}
				/>
			</div>
			{readerInput && (
				<label id='readerControlGroupReadersListErrorLabel' htmlFor={readerControlGroupReadersTableId}>
					{!displayMyNetworkLayout
						? _('YouMustSelectAtLeastOneReaderToAddToTheReaderControlGroup')
						: _('YouMustSelectOneReaderToAddToTheReaderControlGroup')}
				</label>
			)}
		</div>
	);
};

export { ReadersGrid };

import { Radio, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DataNode } from 'antd/lib/tree';
import React, { useContext, useEffect, useState } from 'react';
import { buildColumn, ScrollType } from '../../../../../../../Helper';
import { DataTableType, VelocityDevice } from '../../../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../../../common/SearchColumn/SearchColumn';
import { setController, StoreContext } from '../../contextReader';
import styles from './controllersGrid.module.scss';

type DataType = DataTableType<VelocityDevice>;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 156 };

const ControllersGrid: React.FC = () => {
	const [treeData, setTree] = useState([] as DataNode[]);
	const [selectedRowKeys, setSelectedRowKeys] = useState(['0-0']);

	const {
		readerState: { controllersForReaders, controllerSelectedId },
		dispatcher,
	} = useContext(StoreContext);

	useEffect(() => {
		setSelectedRowKeys([`${controllerSelectedId}`]);
	}, [controllerSelectedId]);

	useEffect(() => {
		const controllers: DataType[] = controllersForReaders.map<DataType>(x => ({
			...x,
			key: `${x.Id}`,
		}));
		setTree(controllers);
	}, [controllersForReaders]);

	const handleClick = (controller: DataType) => {
		setSelectedRowKeys([controller.key.toString()]);
		dispatcher(setController(controller.Id));
	};

	const columns: ColumnsType<any> = [
		{
			...buildColumn(_('ControllerName'), 'Name', undefined, 'start'),
			sorter: (a, b) => a.Name.localeCompare(b.Name),
			...SearchColumn({ maxLength: 32, searchColumnId: 'searchControllerName' }),
		},
	];

	return (
		<div className={styles.container}>
			<Table
				id='readerControlGroupControllersTable'
				columns={columns}
				onRow={(record: DataType) => ({
					onClick: () => handleClick(record),
				})}
				dataSource={treeData}
				scroll={scroll}
				pagination={false}
				size='small'
				className={styles.statusWidgetTable}
				rowSelection={{
					selectedRowKeys,
					hideSelectAll: true,
					renderCell: (selected, record) => <Radio id={`readerControlGroupControllerRadioButton-${record.Id}`} checked={selected} />,
				}}
				onHeaderRow={() => ({ id: 'readerControlGroupControllersTableHeader' })}
				getPopupContainer={() => document.getElementById('readerControlGroupControllersTableHeader')}
			/>
		</div>
	);
};

export { ControllersGrid };

import { AxiosRequestConfig } from 'axios';
import React from 'react';
import { PaginationSettingTyped, PreviewBadgeResponse, ResponseObject, ResponseObjectEntity } from '../model/CommonModel';
import { ColumnConfiguration } from '../model/ConfigurationModel';
import { CredentialDescription } from '../model/EnrollmentModel';
import {
	AllZoneCredentialsItemsPagination,
	LoadControllerZonesHelper,
	LoadZonesHelper,
	MoveCredential,
	RenameRequest,
	RenameResponse,
	SharedZoneModalInfo,
	WhosInsideModel,
	WhosInsideReportData,
	WhosInsideResponse,
	Zone,
	ZoneControllersColumns,
	ZoneControllersPagination,
	ZoneCredentialsColumns,
	ZoneGroupModalInfo,
	ZoneGroupTree,
	ZoneGroupType,
	ZonesColumns,
	ZonesPagination,
} from '../model/WhosInsideModel';
import { Api } from './api';

class WhosInsideApi extends Api {
	public constructor() {
		super();
	}

	public initWhosInside = async (): Promise<WhosInsideModel> => {
		const url: string = this.getUrl('WhosInside/InitWhosInside', true);

		return await this.post<WhosInsideModel>(url);
	};

	public hideEmptyZones = async (hideEmptyZones: boolean): Promise<ResponseObjectEntity<ZoneGroupTree>> => {
		const url: string = this.getUrl('WhosInside/GetHideEmptyZonesList', true);

		return await this.post<ResponseObjectEntity<ZoneGroupTree>>(url, { hideEmptyZones });
	};

	public getZonesGroupTree = async (abortSignal?: AbortSignal): Promise<ZoneGroupTree> => {
		const url: string = this.getUrl('WhosInside/GetZoneList', true);
		let config: AxiosRequestConfig = this.getDefaultAxiosRequestConfig();
		if (abortSignal) {
			config.signal = abortSignal;
		}

		return await this.post<ZoneGroupTree>(url, undefined, config);
	};

	public loadAllZoneCredentialsIds = async (
		zoneInfo: Zone,
		paginationSettings: PaginationSettingTyped<ZoneCredentialsColumns>,
		abortSignal?: AbortSignal
	): Promise<AllZoneCredentialsItemsPagination> => {
		let config: AxiosRequestConfig = this.getDefaultAxiosRequestConfig();
		if (abortSignal) {
			config.signal = abortSignal;
		}
		const url: string = this.getUrl('WhosInside/LoadAllZoneCredentialsIds', true);
		const data = {
			zoneInfo,
			paginationSettings,
		};

		return await this.post<AllZoneCredentialsItemsPagination>(url, data, config);
	};

	public makeReport = async (): Promise<ResponseObjectEntity<WhosInsideReportData>> => {
		const url: string = this.getUrl('WhosInside/MakeReport', true);

		return await this.post<ResponseObjectEntity<WhosInsideReportData>>(url);
	};

	public renameWhosInsideElement = async (renameInfo: RenameRequest): Promise<ResponseObjectEntity<RenameResponse>> => {
		const url: string = this.getUrl('WhosInside/RenameWhosInsideElement', true);

		return await this.post<ResponseObjectEntity<RenameResponse>>(url, { renameInfo });
	};

	public getCustomZoneGroupModal = async (zoneGroupId: number): Promise<ResponseObjectEntity<ZoneGroupModalInfo>> => {
		const url: string = this.getUrl('WhosInside/GetCustomZoneGroupModal', true);

		return await this.post<ResponseObjectEntity<ZoneGroupModalInfo>>(url, { zoneGroupId });
	};

	public addCustomZoneGroup = async (zoneName: string, selectedControllersId: number[]): Promise<ResponseObject> => {
		const url: string = this.getUrl('WhosInside/AddCustomZoneGroup', true);

		return await this.post<ResponseObject>(url, { zoneName, selectedControllersId });
	};

	public editCustomZoneGroup = async (editData: ZoneGroupModalInfo): Promise<ResponseObject> => {
		const url: string = this.getUrl('WhosInside/EditCustomZoneGroup', true);

		return await this.post<ResponseObject>(url, { editData });
	};

	public forgiveCredentials = async (credentialsId: number[]): Promise<ResponseObject> => {
		const url: string = this.getUrl('WhosInside/ForgiveCredentials', true);

		return await this.post<ResponseObject>(url, { credentialsId });
	};

	public getWhosInsidePreviewBadgeCredential = async (credentialsIds: number[]): Promise<ResponseObjectEntity<PreviewBadgeResponse[]>> => {
		const url: string = this.getUrl('WhosInside/GetWhosInsidePreviewBadgeCredential', true);

		return await this.post<ResponseObjectEntity<PreviewBadgeResponse[]>>(url, { credentialsIds });
	};

	public deleteCustomZoneGroup = async (zoneGroupId: number): Promise<ResponseObject> => {
		const url: string = this.getUrl('WhosInside/DeleteCustomZoneGroup', true);

		return await this.post<ResponseObject>(url, { zoneGroupId });
	};

	public mapToSharedZone = async (sharedZoneId: number, zoneGroupId: number, zoneId: Number): Promise<ResponseObjectEntity<ZoneGroupTree>> => {
		const url: string = this.getUrl('WhosInside/MapToSharedZone', true);
		const data = {
			sharedZoneId,
			zoneGroupId,
			zoneId,
		};

		return await this.post<ResponseObjectEntity<ZoneGroupTree>>(url, data);
	};

	public getSharedZonesList = async (): Promise<Zone[]> => {
		const url: string = this.getUrl('WhosInside/GetSharedZonesList', true);

		return await this.post<Zone[]>(url);
	};

	public getSharedZonesModalInfo = async (sharedZoneId: number): Promise<SharedZoneModalInfo> => {
		const url: string = this.getUrl('WhosInside/GetAvailableZones', true);

		return await this.post<SharedZoneModalInfo>(url, { sharedZoneId });
	};

	public saveSharedZones = async (sharedZoneModal: SharedZoneModalInfo[]): Promise<ResponseObject> => {
		const url: string = this.getUrl('WhosInside/SaveSharedZones', true);

		return await this.post<ResponseObject>(url, { sharedZoneModal });
	};

	public moveCredentialWithingZoneGroup = async (moveInfo: Partial<MoveCredential>): Promise<ResponseObjectEntity<string>> => {
		const url: string = this.getUrl('WhosInside/MoveCredentialWithinZoneGroup', true);

		return await this.post<ResponseObjectEntity<string>>(url, { moveInfo });
	};

	public moveCredentialOutsideZoneGroup = async (moveInfo: Partial<MoveCredential>): Promise<ResponseObjectEntity<string>> => {
		const url: string = this.getUrl('WhosInside/MoveCredentialOutsideZoneGroup', true);

		return await this.post<ResponseObjectEntity<string>>(url, { moveInfo });
	};

	public forgiveCredentialsByZoneOrGroup = async (
		zoneGroupId: number,
		zoneId: number,
		zoneType: ZoneGroupType = ZoneGroupType.Custom
	): Promise<ResponseObject> => {
		const zoneIndex: number = zoneId - 1;
		const url: string = this.getUrl('WhosInside/ForgiveCredentialsByZoneOrGroup', true);
		const data = {
			zoneGroupId,
			zoneIndex,
			zoneType,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public loadZoneCredentialsByPage = async (
		zoneInfo: Zone,
		paginationSetting: PaginationSettingTyped<ZoneCredentialsColumns>,
		abortSignal?: AbortSignal
	): Promise<ResponseObjectEntity<WhosInsideResponse>> => {
		let config: AxiosRequestConfig = this.getDefaultAxiosRequestConfig();
		if (abortSignal) {
			config.signal = abortSignal;
		}
		const url: string = this.getUrl('WhosInside/LoadZoneCredentialsByPage', true);
		const data = {
			zoneInfo,
			paginationSetting,
		};

		return await this.post<ResponseObjectEntity<WhosInsideResponse>>(url, data, config);
	};

	public loadZoneGroupControllersByPage = async (
		zoneInfo: LoadControllerZonesHelper,
		paginationSetting: PaginationSettingTyped<ZoneControllersColumns>
	): Promise<ZoneControllersPagination> => {
		const url: string = this.getUrl('WhosInside/LoadZoneGroupControllersByPage', true);
		const data = {
			zoneInfo,
			paginationSetting,
		};

		return await this.post<ZoneControllersPagination>(url, data);
	};

	public loadSharedZoneZonesByPage = async (zoneInfo: LoadZonesHelper, paginationSetting: PaginationSettingTyped<ZonesColumns>): Promise<ZonesPagination> => {
		const url: string = this.getUrl('WhosInside/LoadZonesByPage', true);
		const data = {
			zoneInfo,
			paginationSetting,
		};

		return await this.post<ZonesPagination>(url, data);
	};

	public getZoneKeysAllItemsPagination = async (
		zoneInfoHelper: LoadZonesHelper,
		paginationSetting?: PaginationSettingTyped<ZonesColumns>
	): Promise<React.Key[]> => {
		const url: string = this.getUrl('WhosInside/GetZoneKeysAllItemsPagination', true);
		const data = {
			zoneInfoHelper,
			paginationSetting,
		};

		return await this.post<React.Key[]>(url, data);
	};

	public getZoneControllersAllItemsPagination = async (
		zoneInfoHelper: LoadControllerZonesHelper,
		paginationSetting?: PaginationSettingTyped<ZoneControllersColumns>
	): Promise<number[]> => {
		const url: string = this.getUrl('WhosInside/GetZoneControllersAllItemsPagination', true);
		const data = {
			zoneInfoHelper,
			paginationSetting,
		};

		return await this.post<number[]>(url, data);
	};

	public getZoneInfo = async (zoneGroupId: number, zoneId: number): Promise<Zone> => {
		const url: string = this.getUrl('WhosInside/GetZoneInfo', true);
		const data = {
			zoneGroupId,
			zoneId,
		};

		return await this.post<Zone>(url, data);
	};

	public getCredentialPersonNameByIds = async (zoneInfo: Zone, credentialIds: number[]): Promise<ResponseObjectEntity<CredentialDescription[]>> => {
		const url = this.getUrl('WhosInside/GetCredentialPersonNameByIds', true);
		const data = {
			zoneInfo,
			credentialIds,
		};

		return await this.post<ResponseObjectEntity<CredentialDescription[]>>(url, data);
	};

	public getColumnsConfiguration = async (): Promise<ColumnConfiguration> => {
		const url = this.getUrl('WhosInside/GetColumnsConfiguration', true);

		return await this.post<ColumnConfiguration>(url);
	};
}

const whosInsideApi = new WhosInsideApi();

export { whosInsideApi };

import { InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { CodeTamperAlarmInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerMiscSystemSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerMisc } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectCodeTamperAlarmInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../setup.module.scss';

const CodeTamperAlarm: React.FC = () => {
	const dispatch = useStoreDispatch();
	const codeTamperAlarmInfo: CodeTamperAlarmInfoStore = useStoreSelector<CodeTamperAlarmInfoStore>(selectCodeTamperAlarmInfo);

	const handleChangeInvalidPenalty = (value: ControllerMisc) => {
		const invalidPenalty: number = value.CodeTamperInvalidPenaltyTimer;
		if (!invalidPenalty || invalidPenalty === undefined || invalidPenalty < 1) {
			value.CodeTamperInvalidPenaltyTimer = 1;
		}

		handleChangeControllerMiscSystemSetup(value);
	};

	const handleChangeThresholdTime = (value: ControllerMisc) => {
		const thresholdTime: number = value.CodeTamperThresholdTimer;
		if (!thresholdTime || thresholdTime === undefined || thresholdTime < 1) {
			value.CodeTamperThresholdTimer = 1;
		}

		handleChangeControllerMiscSystemSetup(value);
	};

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	return (
		<CardSection title={_('CodeTamperAlarm')} height='92%'>
			<div className={styles.codeTamperAlarm}>
				<div>{_('InvalidPenaltyTimer')}:</div>
				<InputNumber
					id='controllerSetupInvalidPenaltyInput'
					min={1}
					max={100}
					maxLength={3}
					onChange={value => handleChangeInvalidPenalty({ CodeTamperInvalidPenaltyTimer: Number(value) })}
					value={codeTamperAlarmInfo.invalidPenaltyTimer}
					formatter={noneDecimalFormatter}
				/>
				<span>(1-100 {_('Seconds')})</span>
				<div>{_('ThresholdTimer')}:</div>
				<InputNumber
					id='controllerSetupThresholdTimerInput'
					min={1}
					max={100}
					maxLength={3}
					onChange={value => handleChangeThresholdTime({ CodeTamperThresholdTimer: Number(value) })}
					value={codeTamperAlarmInfo.thresholdTimer}
					formatter={noneDecimalFormatter}
				/>
				<span>(1-100 {_('Seconds')})</span>
				<div>{_('LockoutTime')}:</div>
				<InputNumber
					id='controllerSetupLockoutTimeInput'
					min={0}
					max={255}
					maxLength={3}
					onChange={value => handleChangeControllerMiscSystemSetup({ CodeTamperLockoutTimer: Number(value) })}
					value={codeTamperAlarmInfo.lockoutTime}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-255 {_('Minutes')})</span>
			</div>
		</CardSection>
	);
};

export { CodeTamperAlarm };

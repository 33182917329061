import { loggerApi } from '../api/LoggerApi';

export const enum Severity {
	ERROR = 0,
	WARN,
	INFO,
	DEBG,
	PERF,
}

export class Logger {
	public static writeInfoLog(message: string): void {
		loggerApi.writeMessage(message, Severity.INFO);
	}

	public static writeErrorLog(message: string): void {
		loggerApi.writeMessage(message, Severity.ERROR);
	}

	public static writeDebugLog(message: string): void {
		loggerApi.writeMessage(message, Severity.DEBG);
	}

	public static writeWarnLog(message: string): void {
		loggerApi.writeMessage(message, Severity.WARN);
	}

	public static writePerfLog(message: string): void {
		loggerApi.writeMessage(message, Severity.PERF);
	}
}

import { Input, Spin, Tree } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { whosInsideApi } from '../../../api';
import { ColumnsProps } from '../../../Helper';
import { MoveCredential, WhosInsideCredentialDataType, ZoneGroupTree } from '../../../model/WhosInsideModel';
import { highLightText, keywordFilter } from '../../whosInside';
import styles from './whosInsideMoveTo.module.scss';

const { Search } = Input;
const { DirectoryTree } = Tree;
type actionValueType = ColumnsProps<WhosInsideCredentialDataType> | string;

type Props = {
	actionValue: actionValueType;
	onClickOption: (value: actionValueType, moveCredentialInfo: Partial<MoveCredential>) => void;
	isPopover?: boolean;
	storedZoneGroupTree?: ZoneGroupTree;
};

const noIconStyle: React.CSSProperties = {
	height: 0,
	width: 0,
};

const WhosInsideMoveTo: React.VFC<Props> = ({ actionValue, onClickOption, isPopover, storedZoneGroupTree }) => {
	const [autoExpandParent, setAutoExpandParent] = useState(true);
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedZone, setSelectedZone] = useState<boolean>(false);

	useEffect(() => {
		let isMounted: boolean = true;
		if (storedZoneGroupTree && isMounted) {
			buildTree(storedZoneGroupTree);
			setIsLoading(false);
		} else {
			whosInsideApi
				.getZonesGroupTree()
				.then((response: ZoneGroupTree) => {
					if (isMounted) {
						buildTree(response);
					}
				})
				.finally(() => {
					if (isMounted) {
						setIsLoading(false);
					}
				});
		}
		return () => {
			isMounted = false;
		};
	}, []);

	const buildTree = (zoneGroupTree: ZoneGroupTree) => {
		const buildGlobalized = zoneGroupTree.GlobalizedGroup.map((x, index) => ({
			title: x.ZoneGroupName,
			key: `0-0-MT-${x.ZoneGroupId}`,
			zone: { ...x },
			options: [],
			children: x.Zones.map((c, subIndex) => ({
				title: c.ZoneName,
				key: `0-0-MT-${x.ZoneGroupId}-${subIndex}`,
				isLeaf: true,
				showIcon: false,
				icon: <span style={noIconStyle} />,
				zone: { ...c },
				options: [],
				credentialsOnZone: c.CredentialsOnZoneFormat,
				showCountInBack: zoneGroupTree.ShowCountInBack,
			})),
		}));
		const buildCustomGroup = zoneGroupTree.CustomGroup.map((x, index) => ({
			title: x.ZoneGroupName,
			key: `0-1-MT-${x.ZoneGroupId}`,
			zone: { ...x },
			options: [],
			children: x.Zones.map((c, subIndex) => ({
				title: c.ZoneName,
				key: `0-1-MT-${x.ZoneGroupId}-${subIndex}`,
				isLeaf: true,
				showIcon: false,
				icon: <span style={noIconStyle} />,
				zone: { ...c },
				options: [],
				credentialsOnZone: c.CredentialsOnZoneFormat,
				showCountInBack: zoneGroupTree.ShowCountInBack,
			})),
		}));
		const treeData = [
			{
				title: _('GlobalizedZoneGroup'),
				key: '0-0-MT',
				children: buildGlobalized,
			},
			{
				title: _('CustomGroup'),
				key: '0-1-MT',
				children: buildCustomGroup,
			},
		];
		setData(treeData);
	};

	const onExpand = (expandedKeys: []) => {
		setExpandedKeys(expandedKeys);
		setAutoExpandParent(false);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		const searchedNodes: string[] = keywordFilter(data, value.trim().toLowerCase(), value.trim().toLowerCase());
		setSearchValue(value);
		setAutoExpandParent(true);
		if (value.trim().length === 0) {
			setExpandedKeys([]);
		} else {
			setExpandedKeys(searchedNodes);
		}
	};

	return (
		<div
			id='moveToMenuContainer'
			className={cx(styles.moveToMenuContainer, styles.movetoMenu, {
				[styles.moveToMenuContainerPopover]: isPopover,
				[styles.movetoMenuContext]: !isPopover,
			})}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large' className={styles.spin}>
				<div className={styles.moveToSearch}>
					<Search id='whosInsideMoveToSearchMoveToTreeInput' placeholder='Zone List' onChange={onChange} />
				</div>
				<div className={styles.wrapperTree}>
					<DirectoryTree
						height={460}
						treeData={highLightText(data, searchValue, 100)}
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						autoExpandParent={autoExpandParent}
						onSelect={(rest, e) => {
							const parseAsAny = e.node as any;
							if (parseAsAny.zone && parseAsAny.zone.ZoneName) {
								onClickOption(actionValue, {
									ToZoneId: parseAsAny.zone.ZoneId,
									ToZoneGroupId: parseAsAny.zone.ZoneGroupId,
									ToZoneName: parseAsAny.zone.ZoneName,
								});
								setSelectedZone(true);
							}
						}}
						disabled={selectedZone}
						motion={false}
					/>
				</div>
			</Spin>
		</div>
	);
};

export { WhosInsideMoveTo };

import React from 'react';
import styles from './whosInsideHelperElements.module.scss';

type TreeNodeNameProps = {
	hasChildren?: JSX.Element;
	name: string | JSX.Element;
	count: string;
	showCountInBack: boolean;
	nodeKey: string;
};

const TreeNodeName: React.FC<TreeNodeNameProps> = ({ hasChildren, name, count, showCountInBack, nodeKey }) => {
	let content: JSX.Element;

	if (hasChildren) {
		content = (
			<span id={`groupName-${nodeKey}`} className={styles.treeNodeNameHasChild}>
				{name}
			</span>
		);
	} else {
		if (showCountInBack) {
			content = (
				<span id={`zoneNameContainerBack-${nodeKey}`} className={styles.treeNodeNameShowCountInBack}>
					<span id={`zoneName-${nodeKey}`}>{name}</span>
					<span id={`zoneCount-${nodeKey}`}>
						<strong>{count}</strong>
					</span>
				</span>
			);
		} else {
			content = (
				<span id={`zoneNameContainerFront-${nodeKey}`} className={styles.treeNodeNameShowCountInFront}>
					<span id={`zoneCount-${nodeKey}`}>
						<strong>{count}</strong>
					</span>
					<span id={`Name-${nodeKey}`}>{name}</span>
				</span>
			);
		}
	}

	return content;
};

export { TreeNodeName };

import { DragOutlined } from '@ant-design/icons';
import { Button, Checkbox, List, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { ReactFragment, useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import { ConcatenatedCardData } from '../../../../../../../model/EnrollmentModel';
import { Modal } from '../../../../../../common';
import styles from '../../../../credentialmodal.module.scss';
import { is200BitCardType } from '../../../../Helper';

const CARD_DATA_UDF_START = '<UDF>';
const CARD_DATA_MAX_LENGTH = 32;

const { Option } = Select;

type UDFModalProps = {
	cardType: number;
	concatenatedCardData: ConcatenatedCardData[];
	IsDeviceAdminCredentialTemplate: boolean;
	onCheckItem: (idx: number, checked: boolean) => void;
	onOkModal: (data: string, hasChecked: boolean, concatenatedData: number[]) => void;
	onSortEnd: SortEndHandler;
	setShowUDFModal: (flag: boolean) => void;
	showUDFModal: boolean;
};

const UDFModal: React.FC<UDFModalProps> = ({
	cardType,
	concatenatedCardData,
	IsDeviceAdminCredentialTemplate,
	onCheckItem,
	onOkModal,
	onSortEnd,
	setShowUDFModal,
	showUDFModal,
}) => {
	const [udfData, setUdfData] = useState<Map<number, string>>(
		new Map<number, string>([
			[1, '-1'],
			[2, '-1'],
			[3, '-1'],
			[4, '-1'],
			[5, '-1'],
			[6, '-1'],
			[7, '-1'],
			[8, '-1'],
			[9, '-1'],
		])
	);

	const isFasCN: boolean = is200BitCardType(cardType);

	useEffect(() => {
		if (isFasCN) {
			const clonedUdfData: Map<number, string> = new Map(udfData);
			concatenatedCardData.forEach((ccd, idx: number) => {
				const v = idx;
				if (ccd.Selected) clonedUdfData.set(v + 1, v.toString());
			});
			setUdfData(clonedUdfData);
		}
	}, [JSON.stringify(concatenatedCardData)]);

	const handleOnOkUDFModal = () => {
		let data = '';
		let hasChecked = false;
		const concatenatedFields: number[] = [];
		// handle UDF logic
		if (isFasCN) {
			udfData.forEach((value: string, key: number) => {
				if (value !== '-1') {
					hasChecked = true;
					const intValue = parseInt(value, 0);
					const selectedObj = concatenatedCardData[intValue];
					if (!IsDeviceAdminCredentialTemplate) {
						data += selectedObj.Value || '';
					}
					concatenatedFields.push(selectedObj.FieldNumber);
				}
			});
		} else {
			concatenatedCardData.forEach(c => {
				if (c.Selected) {
					hasChecked = true;
					if (!IsDeviceAdminCredentialTemplate) {
						data += c.Value || '';
					}
					concatenatedFields.push(c.FieldNumber);
				}
			});
		}
		if (data === '') {
			if (hasChecked) data = CARD_DATA_UDF_START;
		} else {
			data = data.replace(CARD_DATA_UDF_START, '').substring(0, Math.min(CARD_DATA_MAX_LENGTH, data.length));
		}

		onOkModal(data, hasChecked, concatenatedFields);
	};

	const buildSelectStructure = (): ReactFragment => {
		const opts: Partial<ConcatenatedCardData>[] = [{ Caption: _('SelectUDFField'), Value: '-1' }, ...concatenatedCardData];
		const options = opts.map((ccd, idx: number) => (
			<Option key={`${ccd.Caption}-${ccd.Value}`} value={(idx ? idx - 1 : -1).toString()}>
				{ccd.Caption}
			</Option>
		));
		return (
			<div className={styles.container}>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-1'>{_('AgencyCode')}</label>
					<Select
						id='udf-1'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(1, value))}
						value={udfData.get(1)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-2'>{_('SystemCode')}</label>
					<Select
						id='udf-2'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(2, value))}
						value={udfData.get(2)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-3'>{_('CredentialNumber')}</label>
					<Select
						id='udf-3'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(3, value))}
						value={udfData.get(3)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-4'>{_('CredentialSeries')}</label>
					<Select
						id='udf-4'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(4, value))}
						value={udfData.get(4)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-5'>{_('IndividualCredentialIssue')}</label>
					<Select
						id='udf-5'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(5, value))}
						value={udfData.get(5)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-6'>{_('PersonIdentifier')}</label>
					<Select
						id='udf-6'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(6, value))}
						value={udfData.get(6)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-7'>{_('OrganizationalCategory')}</label>
					<Select
						id='udf-7'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(7, value))}
						value={udfData.get(7)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-8'>{_('OrganizationalIdentifier')}</label>
					<Select
						id='udf-8'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(8, value))}
						value={udfData.get(8)}>
						{options}
					</Select>
				</div>
				<div className={cx(styles.form, styles.sections, styles.equalSections)}>
					<label htmlFor='udf-9'>{_('PersonOrganizationAssociation')}</label>
					<Select
						id='udf-9'
						getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
						onChange={value => setUdfData(new Map(udfData).set(9, value))}
						value={udfData.get(9)}>
						{options}
					</Select>
				</div>
			</div>
		);
	};

	const buildListStructure = (): ReactFragment => {
		return <SortableList items={concatenatedCardData} onSortEnd={onSortEnd} helperClass={styles.rowDragging} />;
	};

	const SortableItem = SortableElement(({ sortIndex, value }) => (
		<List.Item>
			<Checkbox
				checked={concatenatedCardData[sortIndex].Selected}
				onChange={(e: CheckboxChangeEvent) => {
					onCheckItem(sortIndex, e.target.checked);
				}}
				style={{ paddingLeft: 10 }}>
				{value}
			</Checkbox>
			<DragOutlined style={{ cursor: 'grab', color: '#999', paddingRight: 20 }} />
		</List.Item>
	));

	const SortableList = SortableContainer(({ items }) => (
		<List header={null} footer={null} style={{ width: '100%' }}>
			{(items as ConcatenatedCardData[]).map((item, index) => {
				return <SortableItem index={index} key={`${item.Caption}-${item.Value}`} sortIndex={index} value={item.Caption} />;
			})}
		</List>
	));

	const footer = [
		<Button key='OkButton' type='primary' htmlType='button' onClick={handleOnOkUDFModal}>
			{_('OK')}
		</Button>,
	];

	return (
		<Modal
			destroyOnClose={true}
			footer={footer}
			keyboard={false}
			onCancel={() => {
				setShowUDFModal(false);
			}}
			onClickOk={handleOnOkUDFModal}
			title={isFasCN ? _('ConcatenateUDFs').replace(' ', ` ${_('FASCN')} `) : _('ConcatenateUDFs')}
			visible={showUDFModal}>
			<div className={styles.udfInnerContainer}>
				{isFasCN ? _('SelectNumericUDFsFASCN') : _('SelectNumericUDFs')}
				<br />
				<br />
				{isFasCN ? (
					buildSelectStructure()
				) : (
					<>
						<i>Drag and drop rows to arrange UDF order</i>
						{buildListStructure()}
					</>
				)}
			</div>
		</Modal>
	);
};

export default UDFModal;

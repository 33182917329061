import { InputNumber, Tooltip } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../../Helper';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, StoreContext, setInputAction } from '../../../../inputModalContext';
import styles from './timers.module.scss';

type Props = {
	useDoorContext: boolean;
};

const Timers: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };
	let errorMessage = '';
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
		errorMessage = contextStateDoor?.door?.door?.WaitingTimeError ?? '';
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
		errorMessage = contextState?.input?.WaitingTimeError ?? '';
	}

	const handleChangeModeTime = (e: React.ReactText) => {
		inputContext.dispatcher(setInputAction({ DoorModeTime: Number(e.toString()) }));
	};
	const handleChangeAATL = (e: React.ReactText) => {
		inputContext.dispatcher(setInputAction({ DOTLTime: Number(e.toString()) }));
	};
	const handleChangeAATLWarning = (e: React.ReactText) => {
		const value = Number(e.toString());
		inputContext.dispatcher(
			setInputAction({ DOTLWaitingTime: value > inputContext.context?.input?.DOTLTime ? inputContext.context?.input?.DOTLTime : value })
		);
	};
	const handleChangeEntryTime = (e: React.ReactText) => {
		inputContext.dispatcher(setInputAction({ EntryDelayTime: Number(e.toString()) }));
	};
	const handleChangeExitTime = (e: React.ReactText) => {
		inputContext.dispatcher(setInputAction({ ExitDelayTime: Number(e.toString()) }));
	};

	const mainContainer = 'timersContainer';

	const ATTLValidation = inputContext.context?.input?.DOTLTime < inputContext.context?.input?.DOTLWaitingTime;
	return (
		<div className={styles.container} id={mainContainer}>
			<fieldset>
				<legend className={styles.legend}>{_('TimersSeconds')}</legend>
				<div className={styles.content}>
					<div className={styles.timers}>
						<div className={styles.timerDiv}>
							<span>{useDoorContext ? _('DoorModeTime') : _('ModeTime')}</span>
							<InputNumber
								id='ModeTime'
								min={0}
								max={8100}
								step={0.25}
								maxLength={6}
								precision={2}
								value={inputContext.context?.input?.DoorModeTime}
								onChange={handleChangeModeTime}
								formatter={value => `${value}`.replace(/[^0-9\.]+/g, '')}></InputNumber>
						</div>
						<div className={styles.timerDiv}>
							<span>{useDoorContext ? _('DOTLWarningTime') : _('AATLWarningTime')}</span>
							<InputNumber
								id='AATLWarningTime'
								min={0}
								max={8100}
								maxLength={4}
								value={inputContext.context?.input?.DOTLWaitingTime}
								onChange={handleChangeAATLWarning}
								formatter={noneDecimalFormatter}
								step={1}></InputNumber>
						</div>
						<div className={styles.timerDiv}>
							<span>{_('ExitDelayTime')}</span>
							<InputNumber
								id='ExitDelayTime'
								min={0}
								max={255}
								maxLength={3}
								value={inputContext.context?.input?.ExitDelayTime}
								onChange={handleChangeExitTime}
								formatter={noneDecimalFormatter}
								step={1}></InputNumber>
						</div>
						<div className={cx(styles.timerDiv, { [styles.error]: ATTLValidation })}>
							<span>{useDoorContext ? _('DOTLTime') : _('AATLTime')}</span>
							<Tooltip
								getPopupContainer={() => document.getElementById(mainContainer)}
								open={ATTLValidation}
								color='red'
								title={errorMessage}
								children={
									<InputNumber
										id='AATLTime'
										min={0}
										max={8100}
										maxLength={4}
										value={inputContext.context?.input?.DOTLTime}
										onChange={handleChangeAATL}
										formatter={noneDecimalFormatter}
										step={1}
									/>
								}
							/>
						</div>
						<div className={styles.timerDiv}>
							<span>{_('EntryDelayTime')}</span>
							<InputNumber
								id='EntryDelayTime'
								min={0}
								max={255}
								maxLength={3}
								value={inputContext.context?.input?.EntryDelayTime}
								onChange={handleChangeEntryTime}
								formatter={noneDecimalFormatter}
								step={1}></InputNumber>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export { Timers };

import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../../Helper';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderAntiPassback } from '../../../exitReaderContext';
import styles from '../../Logic/logic.module.scss';

const AntiPassback: React.FC = () => {
	var {
		contextStateDoor: {
			exitReader: { antiPassback },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleOnChangeAntiPassback = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== 0) {
				return;
			}
		}

		dispatcherDoor(setExitReaderAntiPassback(Number(value.toString())));
	};

	return (
		<div className={styles.antiPassbackSection}>
			<span>
				{_('AntiPassback')} ({_('Minutes')})
			</span>
			<div>
				<InputNumber
					key='antiPassback'
					min={0}
					max={254}
					maxLength={3}
					value={antiPassback}
					onChange={handleOnChangeAntiPassback}
					id={'antiPassback'}
					className={styles.input}
					formatter={noneDecimalFormatter}
				/>
			</div>
		</div>
	);
};

export { AntiPassback };

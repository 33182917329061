// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".groups-container--guwwC0jZNBbCMNqog7mpc{max-height:550px;overflow:auto;height:550px;min-width:500px;display:flex;gap:15px}.groups-container--guwwC0jZNBbCMNqog7mpc>div:nth-of-type(2){flex:1}", "",{"version":3,"sources":["webpack://src/components/enrollment/EnrollmentTab/PersonInformationContainer/PersonInformationTabs/Tabs/Groups/groups.module.scss"],"names":[],"mappings":"AAAA,yCACC,gBAAA,CACA,aAAA,CACA,YAAA,CACA,eAAA,CACA,YAAA,CACA,QAAA,CAEA,4DACC,MAAA","sourcesContent":[".container {\r\n\tmax-height: 550px;\r\n\toverflow: auto;\r\n\theight: 550px;\r\n\tmin-width: 500px;\r\n\tdisplay: flex;\r\n\tgap: 15px;\r\n\r\n\t& > div:nth-of-type(2) {\r\n\t\tflex: 1;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "groups-container--guwwC0jZNBbCMNqog7mpc"
};
export default ___CSS_LOADER_EXPORT___;

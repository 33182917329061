import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { PortStoreContext, setPortChangedAction, setResetEncryption } from '../../../contextPort';

type Props = {};

const ResetEncryption: React.FC<Props> = () => {
	const {
		portState: { resetEncryption, protocol, hasPortChanged, id },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChange = (e: CheckboxChangeEvent) => {
		const value = e.target.checked;
		dispatcher(setResetEncryption(value));
		checkIfPortChanged();
	};

	return (
		<div>
			<Checkbox id='portGeneralResetEncryptionCheckbox' checked={resetEncryption} onChange={handleChange} disabled={protocol === 2 || id === 0}>
				{_('ResetEncryption')}
			</Checkbox>
		</div>
	);
};

export { ResetEncryption };

import { InputNumber } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { noneDecimalFormatter } from '../../../../../../../../../Helper';
import { ReaderError, SupportedReaderClass } from '../../../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderErrorType } from '../../../../exitReaderContext';
import styles from '../../../General/general.module.scss';

type Props = {
	handleOnFocusOut: (number) => void;
	maxLockNumber: number;
	maxWirelessNumber: number;
	wirelessAddress: number;
	setWirelessAddress: (number) => void;
	lockAddress: number;
	setLockAddress: (number) => void;
};

const WirelessFields: React.FC<Props> = ({
	handleOnFocusOut,
	maxLockNumber,
	maxWirelessNumber,
	wirelessAddress,
	setWirelessAddress,
	lockAddress,
	setLockAddress,
}) => {
	const [lockLabel, setLockLabel] = useState('');
	const [errorMessage, setErrorMessage] = useState<JSX.Element>(<></>);
	const [eacErrorMessage, setEACErrorMessage] = useState<JSX.Element>(<></>);

	var {
		contextStateDoor: {
			exitReader: { supportedReaderClass, readerErrorType, readerErrorMessage, isExitReaderError },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	useEffect(() => {
		if (readerErrorType === ReaderError.DuplicatedWAddress) {
			setErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else if (readerErrorType === ReaderError.EACAddress && supportedReaderClass === SupportedReaderClass.WL_Aperio) {
			setEACErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else {
			setErrorMessage(<></>);
			setEACErrorMessage(<></>);
		}
	}, [readerErrorType]);

	useEffect(() => {
		if (supportedReaderClass === SupportedReaderClass.WL_Allegion) {
			setLockLabel(_('LockAddress'));
		} else {
			setLockLabel(_('EACAddress'));
		}
	}, [supportedReaderClass]);

	const handleOnSetWirelessAddress = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== 0) {
				return;
			}
		}

		setWirelessAddress(Number(value.toString()));
		if (readerErrorType === ReaderError.DuplicatedWAddress) {
			dispatcherDoor(setExitReaderErrorType(undefined));
		}
	};

	const handleOnSetLockAddress = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== 0) {
				return;
			}
		}

		setLockAddress(Number(value.toString()));
	};

	return (
		<div className={styles.wirelessFields}>
			<div>
				<span>{_('WirelessHubAddress')}</span>
				<InputNumber
					key='wirelessAddress'
					min={1}
					max={maxWirelessNumber}
					maxLength={3}
					value={wirelessAddress}
					onChange={handleOnSetWirelessAddress}
					id={'wirelessAddress'}
					className={cx(styles.input, {
						[styles.error]: readerErrorType === ReaderError.DuplicatedWAddress && isExitReaderError,
					})}
					formatter={noneDecimalFormatter}
					onBlur={handleOnFocusOut}
				/>
				{errorMessage}
			</div>
			<div>
				<span>{lockLabel}</span>
				<InputNumber
					key='LockAddress'
					min={0}
					max={maxLockNumber}
					maxLength={3}
					value={lockAddress}
					onChange={handleOnSetLockAddress}
					id={'lockAddress'}
					className={cx(styles.input, {
						[styles.error]: readerErrorType === ReaderError.EACAddress || (readerErrorType === ReaderError.DuplicatedWAddress && isExitReaderError),
					})}
					formatter={noneDecimalFormatter}
					onBlur={handleOnFocusOut}
				/>
				{eacErrorMessage}
			</div>
		</div>
	);
};

export { WirelessFields };

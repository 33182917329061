import { Controller, ControllerACBSetup, LoadMessagesForFirmwareConfiguration, OccupancySetupByZone } from '../../../model/DeviceAdminModel';
import {
	ActionTypes,
	ControllerBasic,
	ControllerMisc,
	ControllerPassback,
	ControllerProperty,
	ControllerReporting,
	ControllerReportingAdvanceSetup,
	ErrorTabs,
	NewTimeZone,
	ResetControllerData,
	SetAlarms,
	SetControllerBasic,
	SetControllerData,
	SetControllerHasChanged,
	SetControllerMisc,
	SetControllerOccupancyTwoPersonsRules,
	SetControllerPassback,
	SetControllerProperty,
	SetControllerReporting,
	SetControllerReportingAdvanceSetup,
	SetCurrentRuleId,
	SetCurrentTimeZone,
	SetError,
	SetFirmwareMessage,
	SetOpenTimeZones,
} from './actionTypes';

export const resetControllerAction = (): ResetControllerData => {
	return {
		type: ActionTypes.RESET_CONTROLLER_DATA,
	};
};

export const setFirmwareMessageAction = (payload: LoadMessagesForFirmwareConfiguration): SetFirmwareMessage => {
	return {
		type: ActionTypes.SET_FIRMWARE_MESSAGES,
		payload,
	};
};

export const setControllerAction = (payload: Controller): SetControllerData => {
	return {
		type: ActionTypes.SET_CONTROLLER_DATA,
		payload,
	};
};

export const setControllerMiscSystemSetupAction = (payload: ControllerMisc): SetControllerMisc => {
	return {
		type: ActionTypes.SET_CONTROLLER_MISC_SYSTEM_SETUP,
		payload,
	};
};

export const setControllerReportingSetupAction = (payload: ControllerReporting): SetControllerReporting => {
	return {
		type: ActionTypes.SET_CONTROLLER_REPORTING_SETUP,
		payload,
	};
};

export const setControllerPassbackSetupAction = (payload: ControllerPassback): SetControllerPassback => {
	return {
		type: ActionTypes.SET_CONTROLLER_PASSBACK_SETUP,
		payload,
	};
};

export const setControllerBasicSetupAction = (payload: ControllerBasic): SetControllerBasic => {
	return {
		type: ActionTypes.SET_CONTROLLER_BASIC_SETUP,
		payload,
	};
};

export const setControllerPropertyAction = (payload: ControllerProperty): SetControllerProperty => {
	return {
		type: ActionTypes.SET_CONTROLLER_PROPERTY,
		payload,
	};
};

export const setControllerOccupancyTwoPersonsRulesAction = (payload: OccupancySetupByZone[]): SetControllerOccupancyTwoPersonsRules => {
	return {
		type: ActionTypes.SET_OCCUPANCY_TWO_PERSONS_RULES,
		payload,
	};
};

export const setControllerReportingAdvanceSetupAction = (payload: ControllerReportingAdvanceSetup): SetControllerReportingAdvanceSetup => {
	return {
		type: ActionTypes.SET_CONTROLLER_REPORTING_ADVANCE_SETUP,
		payload,
	};
};

export const setControllerError = (payload: Partial<ErrorTabs>): SetError => {
	return {
		type: ActionTypes.SET_CONTROLLER_ERROR,
		payload,
	};
};

export const setAlarms = (payload: { data: ControllerACBSetup; key: string }): SetAlarms => {
	return {
		type: ActionTypes.SET_ALARMS,
		payload,
	};
};

export const setOpenTimeZones = (payload: NewTimeZone): SetOpenTimeZones => {
	return {
		type: ActionTypes.SET_OPEN_TIME_ZONES,
		payload,
	};
};

export const setCurrentTimeZones = (payload: number): SetCurrentTimeZone => {
	return {
		type: ActionTypes.SET_CONTROLLER_CURRENT_TIME_ZONE,
		payload,
	};
};

export const setControllerHasChanged = (payload: boolean): SetControllerHasChanged => {
	return {
		type: ActionTypes.SET_CONTROLLER_RESET_HAS_CHANGED,
		payload,
	};
};

export const setCurrentRuleId = (payload: number): SetCurrentRuleId => {
	return {
		type: ActionTypes.SET_CURRENT_RULE_ID,
		payload,
	};
};

import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import constants from '../../../constants';
import { trimStringUpTo } from '../../../Helper';
import { PhotoCallUp } from '../../../model/PhotoCallUpModel';
import { StoreEvent } from '../../../store';
import { openEnrollmentUserInfo } from '../../../store/photocallup/actions';
import './cardData.scss';

// Props those being mapped from Store
interface StateProps {}

// Dispatchable methods (invoke our store's actions)
interface DispatchProps {
	handleOpenEnrollmentUserInfo: (personId: number, credentialId: number, logId: number) => void;
}

// Normal properties for the component
interface OwnProps {
	card: PhotoCallUp;
	showDoorName: boolean;
	showDateTime: boolean;
	showBorderCard: boolean;
	photoCallUpBasicView: boolean;
	index: number;
}

// combine them together
type Props = StateProps & DispatchProps & OwnProps;

//Avoid creating object style inline, since increases reconciliations
const bodyStyle: React.CSSProperties = { padding: '0px' };

const component = (props: Props) => {
	const { card, showDoorName, showDateTime, showBorderCard, index, photoCallUpBasicView, handleOpenEnrollmentUserInfo } = props;

	const cardWidth = card.UsersInformation.length > 1 ? 'photo-card-dual' : 'photo-card-single';
	let cardBorder = 'photo-access-denied-card-border';
	let iconBorder = 'photo-access-denied-card-icon-border';
	if (index === 0) {
		if (card.AccessGranted) {
			if (showBorderCard) {
				cardBorder = 'photo-last-card-border';
				iconBorder = 'photo-last-card-icon-border';
			} else {
				cardBorder = 'photo-access-granted-card-border';
				iconBorder = 'photo-access-granted-card-icon-border';
			}
		}
	} else {
		if (card.AccessGranted) {
			cardBorder = 'photo-access-granted-card-border';
			iconBorder = 'photo-access-granted-card-icon-border';
		}
	}

	const logId = card.LogId;
	const accessType = card.AccessGranted ? _('AccessGranted') : _('AccessDenied');
	let dateTimeAccess: string;
	try {
		dateTimeAccess = FormatDate(card.HardwareTime, true, null, true);
	} catch {
		dateTimeAccess = card.HardwareTime.toString();
	}

	const items = card.UsersInformation.map(user => {
		const key = user.PersonId;
		const handleOnClick = () => {
			const sessionStorageKey = constants.sessionStorage.photoCallUp.RETRIEVE_ENROLLMENT_USER_INFO_FLAG;
			const currentUserInfo = window.sessionStorage.getItem(sessionStorageKey) === '1';

			if (currentUserInfo || key < 1) {
				return;
			}

			window.sessionStorage.setItem(sessionStorageKey, '1');
			handleOpenEnrollmentUserInfo(key, user.CredentialID, logId);
		};

		const cardCover = photoCallUpBasicView ? (
			<div title={user.PersonFullName} className={'profile-container'} onClick={handleOnClick}>
				<img alt={user.PersonFullName} className={'profile-picture'} src={user.PhotoCredentialBase64} />
				<span className={'profile-container-text photo-card-div-p'}>{trimStringUpTo(user.PersonFullName, 28)}</span>
			</div>
		) : (
			<img title={user.PersonFullName} alt={user.PersonFullName} className={'photo-card-img'} src={user.PhotoCredentialBase64} onClick={handleOnClick} />
		);

		return <Card key={key} cover={cardCover} hoverable={key > 0} bodyStyle={bodyStyle} bordered={false} className={'photo-card'} />;
	});

	const iconFloating = card.AccessGranted ? (
		<CheckCircleOutlined className={iconBorder} alt={accessType} />
	) : (
		<StopOutlined className={iconBorder} alt={accessType} />
	);

	return (
		<div data-log-id={logId} data-event-id={card.EventId} data-disabled='false' className={`${cardWidth} ${cardBorder}`}>
			<div className='photo-card-icon-container' title={accessType}>
				{iconFloating}
			</div>
			<div className={'photo-card-date-div'}>
				{showDateTime && (
					<p className={'photo-card-div-p photo-card-date-p'}>
						<span>{dateTimeAccess}</span>
					</p>
				)}
			</div>
			{items}
			<div className={'photo-card-door-div'}>
				{showDoorName && (
					<p className={'photo-card-div-p'} title={card.DoorAccess}>
						{trimStringUpTo(card.DoorAccess, items.length >= 2 ? 64 : 32)}
					</p>
				)}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, StoreEvent>, ownProps: OwnProps): DispatchProps => {
	return {
		handleOpenEnrollmentUserInfo: async (personId: number, credentialId: number, logId: number) => {
			await dispatch(openEnrollmentUserInfo(personId, credentialId, logId));
		},
	};
};

const CardData = connect<StateProps, DispatchProps, OwnProps>(null, mapDispatchToProps)(component);

export { CardData };

import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Spin, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../../model/AccountModel';
import { PaginationSetting } from '../../../../../model/CommonModel';
import { DeviceAdminWarningType, DoorGroupAndMasterInfo, DoorGroupInfo, GenericTimeZone, TimeZoneType } from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setDoorGroupsBy } from '../../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfigurationFilterMode } from '../../../../../store/deviceControl/selectors';
import { InformationScrollList, Modal, ModalWarning, NotificationStatus } from '../../../../common';
import { TimeZones } from '../../TimeZones/TimeZones';
import { DoorGroupConflict, DoorGroupLockStatus } from '../warnings';
import {
	ControllersSelected,
	StoreContext,
	readerContext,
	readerState,
	setAlert,
	setControllers,
	setDisableCredential,
	setDisplayNetworkLayout,
	setEditTimeZoneHasValue,
	setErrors,
	setLoading,
	setName,
	setReaders,
	setReadersPreSelected,
	setReadersSelected,
	setSelectedReadersGridInfo,
	setTag,
	setTimeZoneModal,
	setTimeZoneSelected,
	setTimeZones,
} from './context';
import styles from './doorModal.module.scss';
import { ControllersGrid, DoorsGrid, EditTimeZone, InfoGrid, NameInput, TagsCheckBoxes, TimeZonesDropdown } from './sections';

type Props = {
	onSetVisible: () => void;
	doorToEdit?: DoorGroupAndMasterInfo;
	setShouldResetSearchColumn: () => void;
	setRedirectPage?: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Door_Groups);

const DoorModal: React.FC<Props> = ({ onSetVisible, doorToEdit, setShouldResetSearchColumn, setRedirectPage }) => {
	const dispatchReduxAction = useStoreDispatch();
	const [stateContext, dispatchActionContext] = useReducer(readerContext, readerState);
	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const [emptyNameError, setEmptyNameError] = useState('');

	const [resetSelectAllReaders, setResetSelectAllReaders] = useState(false);
	const [showEditControlZoneModal, setEditControlZonesModal] = useState(false);
	const [editSingleRowControlZone, setEditSingleRowControlZone] = useState(null as ControllersSelected);
	const [isLoading, setIsLoading] = useState(true);
	const [guid, setGuid] = useState('');
	const [isUse, setUse] = useState(false);
	const [previousTimeZone, setPreviousTimeZone] = useState<GenericTimeZone>(null);

	const {
		controllersSelectedId,
		readersPreSelected,
		timeZoneSelected,
		name,
		displayMyNetworkLayout,
		readersSelected,
		readersInfoGridSelected,
		timeZoneEditValue,
		alert,
		tag,
		disableCredential,
		openTimeZoneModal,
	} = stateContext;

	useEffect(() => {
		if (timeZoneSelected && timeZoneSelected.Type !== TimeZoneType.New) {
			setPreviousTimeZone(timeZoneSelected);
		}
	}, [timeZoneSelected]);

	useEffect(() => {
		const action = doorToEdit ? deviceAdminApi.editDoorGroupDialog(doorToEdit.Id) : deviceAdminApi.addDoorGroupDialog();
		action.then(res => {
			Promise.all([
				dispatchActionContext(setTimeZones(res.GenericTimeZones)),
				dispatchActionContext(setControllers(res.ControllersForDoors)),
				dispatchActionContext(setName(doorToEdit ? res.DoorGroup.DoorGroupName : '')),
				dispatchActionContext(setAlert(doorToEdit ? res.DoorGroup.Alert : false)),
				dispatchActionContext(setTag(doorToEdit ? res.DoorGroup.Tag : false)),
				dispatchActionContext(setDisableCredential(doorToEdit ? res.DoorGroup.DisableCredential : false)),
				setGuid(doorToEdit ? res.DoorGroup.Guid : ''),
				setUse(doorToEdit ? res.DoorGroup.InUse : false),
				dispatchActionContext(
					setReadersSelected(
						res.DoorGroup.DoorGroupDoors.map(x => ({
							Address: x.DoorAddress,
							BelongToDoor: 0,
							ControllerId: x.ControllerId,
							ControllerName: '',
							DoorName: x.DoorName,
							GenericTimeZoneId: x.GenericTimeZoneId,
							GlobalTimeZoneId: x.GlobalTimeZoneId,
							Name: x.TimeZoneName,
							ReaderId: x.ReaderId,
							ReaderIndex: x.ReaderIndex,
							ReaderName: x.ReaderName,
							Type: x.TimeZoneType,
							isInUse: x.InUse,
						}))
					)
				),
			]).then(res => setIsLoading(false));
		});

		deviceAdminApi.getErrorMessages(SecuredComponents.Door_Groups).then(res => setEmptyNameError(res.EmptyName));
		window.addEventListener('beforeunload', handleCloseModal);

		return () => {
			window.removeEventListener('beforeunload', handleCloseModal);
		};
	}, []);

	useEffect(() => {
		dispatchActionContext(setLoading(true));
		if (controllersSelectedId.length !== 0 && displayMyNetworkLayout) {
			deviceAdminApi
				.retrieveControllersDoors(controllersSelectedId)
				.then(res => dispatchActionContext(setReaders(res)))
				.finally(() => dispatchActionContext(setLoading(false)));
		} else if (!displayMyNetworkLayout) {
			deviceAdminApi
				.getControllersDoors(-1)
				.then(res => dispatchActionContext(setReaders(res)))
				.finally(() => dispatchActionContext(setLoading(false)));
		} else if (controllersSelectedId.length === 0) {
			batch(() => {
				dispatchActionContext(setReaders([]));
				dispatchActionContext(setLoading(false));
			});
		}
	}, [controllersSelectedId, displayMyNetworkLayout]);

	const handleAddReaderToGrid = () => {
		const readersPreselectLength = readersPreSelected.length;
		if (!timeZoneSelected || readersPreselectLength === 0) {
			if (readersPreselectLength === 0) dispatchActionContext(setErrors({ value: true, type: 'readerInput' }));
			if (!timeZoneSelected) dispatchActionContext(setErrors({ value: true, type: 'controlZoneInput' }));
			return;
		}
		const zone: GenericTimeZone = {
			GenericTimeZoneId: timeZoneSelected.GenericTimeZoneId,
			GlobalTimeZoneId: timeZoneSelected.GlobalTimeZoneId,
			Name: timeZoneSelected.Name,
			Type: timeZoneSelected.Type,
		};
		const selected: ControllersSelected[] = [...readersSelected];
		const preSelected: ControllersSelected[] = readersPreSelected.map(x => ({
			...x,
			...zone,
		}));
		const selectAllNotSelected = preSelected.filter(x => selected.findIndex(w => w.ReaderId === x.ReaderId) < 0);
		dispatchActionContext(setReadersSelected([...selected, ...selectAllNotSelected]));
		dispatchActionContext(setReadersPreSelected([]));
		setResetSelectAllReaders(false);
	};

	const shouldResetSearchColumn = doorToEdit => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!doorToEdit) {
			return;
		}

		setRedirectPage();
	};

	const saveDispatch = (doorToEdit: DoorGroupAndMasterInfo) => {
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;
		dispatchReduxAction(setDoorGroupsBy(paginationSetting));
		shouldResetSearchColumn(doorToEdit);
		onSetVisible();
	};

	const handleOnClickSave = () => {
		if (!name || name.trim() === '') {
			dispatchActionContext(setErrors({ value: emptyNameError, type: 'nameInput' }));
			return;
		}
		const doors: DoorGroupInfo = {
			DoorGroupId: doorToEdit ? doorToEdit.Id : 0,
			DoorGroupName: name.trim(),
			Alert: alert,
			InUse: isUse,
			Tag: tag,
			Guid: guid,
			DisableCredential: disableCredential,
			DoorGroupDoors: readersSelected.map(x => ({
				ControllerId: x.ControllerId,
				DoorAddress: x.Address,
				DoorName: x.DoorName,
				GenericTimeZoneId: x.GenericTimeZoneId,
				GlobalTimeZoneId: x.GlobalTimeZoneId,
				ReaderId: x.ReaderId,
				ReaderIndex: x.ReaderIndex,
				ReaderName: x.ReaderName,
				TimeZoneName: x.Name,
				TimeZoneType: x.Type,
				InUse: false,
			})),
		};

		const actionApi = doorToEdit ? deviceAdminApi.editDoorGroup(doors) : deviceAdminApi.addDoorGroup(doors);
		actionApi.then(res =>
			NotificationStatus({
				responseData: res,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					saveDispatch(doorToEdit);
				},
				onFailedValidation: () => dispatchActionContext(setErrors({ value: res.ErrorMessage, type: 'nameInput' })),
				onAdditionalInfoRequiredCallback: async () => {
					const warningType = Number(res.ResponseErrorDescription) as DeviceAdminWarningType;
					const responseModel = await deviceAdminApi.getDoorGroupConflict(warningType, doorToEdit.Id, res.AdditionalResponseInfo);
					ModalWarning({
						width: '600px',
						hideCancelButton: true,
						okText: 'OK',
						content: <DoorGroupConflict conflictModel={responseModel} />,
					});
				},
				onComponentLockedCallback: async () => {
					const warningType = Number(res.ResponseErrorDescription) as DeviceAdminWarningType;
					const conflictModel = await deviceAdminApi.getDoorGroupLockStatusWarning(warningType, doorToEdit.Id, false);
					ModalWarning({
						width: '600px',
						hideCancelButton: true,
						okText: 'OK',
						content: <DoorGroupLockStatus conflictModel={conflictModel} />,
					});
				},
				onPermissionErrorCallback: async () => {
					notification['error']({
						message: res.ErrorMessage,
					});
				},
				onInputErrorCallback: () => {
					ModalWarning({
						width: '600px',
						hideCancelButton: true,
						okText: 'OK',
						content: <InformationScrollList information={res.Entity} />,
						onOk: () => saveDispatch(doorToEdit),
					});
				},
			})
		);
	};

	const handleOkControlZonesEdit = () => {
		if (!timeZoneEditValue) {
			dispatchActionContext(setErrors({ value: true, type: 'timeZoneEditInput' }));
			return;
		}
		const cloneState = readersSelected.map(x =>
			(editSingleRowControlZone ? editSingleRowControlZone.ReaderId === x.ReaderId : readersInfoGridSelected.some(w => w === x.ReaderId))
				? {
						...x,
						GenericTimeZoneId: timeZoneEditValue.GenericTimeZoneId,
						GlobalTimeZoneId: timeZoneEditValue.GlobalTimeZoneId,
						Name: timeZoneEditValue.Name,
				  }
				: x
		);
		dispatchActionContext(setReadersSelected([...cloneState]));
		dispatchActionContext(setEditTimeZoneHasValue(null));
		dispatchActionContext(setErrors({ value: false, type: 'timeZoneEditInput' }));
		setEditSingleRowControlZone(null);
		setEditControlZonesModal(false);
	};
	const handleCancelConfirmation = () => {
		dispatchActionContext(setErrors({ type: 'timeZoneEditInput', value: false }));
		setEditControlZonesModal(false);
		setEditSingleRowControlZone(null);
	};
	const handleCheckDisplayNetwork = (e: CheckboxChangeEvent) => {
		const value = e.target.checked;
		dispatchActionContext(setDisplayNetworkLayout(value));
	};
	const handleRemoveReader = () => {
		const cloneState = [...readersSelected];
		readersInfoGridSelected.map(d => {
			const index = cloneState.findIndex(x => x.ReaderId === d);
			if (~index) {
				cloneState.splice(index, 1);
			}
		});
		dispatchActionContext(setReadersSelected([...cloneState]));
		dispatchActionContext(setSelectedReadersGridInfo([]));
	};
	const handleOnClickGridReaderRow = (reader: ControllersSelected) => {
		setEditSingleRowControlZone(reader);
		setEditControlZonesModal(true);
	};

	const handleOpenTimeZoneModal = (open: boolean) => {
		dispatchActionContext(setTimeZoneModal(open));
		handleOnAddNewTimeZone(null);
	};

	const handleCloseModal = () => {
		if (doorToEdit?.Id) {
			deviceAdminApi.unlockComponentDeviceAdmin(Number(doorToEdit.Id), SecuredComponents.Door_Groups);
		}
		onSetVisible();
	};

	const handleOnAddNewTimeZone = (id: number) => {
		deviceAdminApi.getGenericTimeZones().then(res => {
			dispatchActionContext(
				setTimeZones(
					res.map(x => ({
						GenericTimeZoneId: x.GenericId,
						GlobalTimeZoneId: x.GlobalId,
						Name: x.Name,
						Type: x.TimeZoneType,
					}))
				)
			);
			dispatchActionContext(setTimeZoneModal(false));
			dispatchActionContext(setErrors({ type: 'controlZoneInput', value: false }));
			if (id) {
				const selectedTimeZone = res.find(x => x.GenericId === id);

				dispatchActionContext(
					setTimeZoneSelected({
						GenericTimeZoneId: selectedTimeZone.GenericId,
						GlobalTimeZoneId: selectedTimeZone.GlobalId,
						Name: selectedTimeZone.Name,
						Type: selectedTimeZone.TimeZoneType,
					})
				);
			} else {
				const existPreviousTimeZone = res.find(x => x.GenericId === previousTimeZone?.GenericTimeZoneId) ? previousTimeZone : null;
				dispatchActionContext(setTimeZoneSelected(existPreviousTimeZone));
			}

			setPreviousTimeZone(null);
		});
	};

	const permissionsEnabled = doorToEdit ? componentPermission.canUpdate : componentPermission.canAdd;
	const disabled = !permissionsEnabled;
	const disableActionReaderButtons = readersInfoGridSelected.length === 0;

	const contextValue = useMemo(() => ({ readerState: stateContext, dispatcher: dispatchActionContext }), [stateContext, dispatchActionContext]);
	const { Provider } = StoreContext;
	const doorGroupModalNetworkLayoutCheckboxId = 'doorGroupModalNetworkLayoutCheckbox';

	return (
		<Provider value={contextValue}>
			<Modal
				footer={[
					<Button
						id='doorGroupModalSaveButton'
						key='save'
						type='primary'
						title={getPermissionErrorMessage(permissionsEnabled)}
						disabled={disabled}
						onClick={() => handleOnClickSave()}>
						{_('SaveChanges')}
					</Button>,
					<Button id='doorGroupCancelButton' key='cancel' onClick={() => handleCloseModal()}>
						{_('Cancel')}
					</Button>,
				]}
				width='900px'
				visible={true}
				title={doorToEdit ? _('EditDoorGroup') : _('AddDoorGroup')}
				onCancel={() => handleCloseModal()}
				className={styles.modal}
				onClickOk={() => null}>
				{showEditControlZoneModal && <EditTimeZone onOkControlZonesEdit={handleOkControlZonesEdit} onCancel={handleCancelConfirmation} />}
				<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
					<div className={styles.container} id='doorGroupAddDoorGroupModal'>
						<div className={styles.sections}>
							<NameInput />
							<TimeZonesDropdown />
						</div>
						<div className={styles.sections}>
							<TagsCheckBoxes />
						</div>
						<div className={styles.sections}>
							{displayMyNetworkLayout && <ControllersGrid />}
							<DoorsGrid reset={resetSelectAllReaders} onSelectAll={setResetSelectAllReaders} />
						</div>
						<div className={styles.sections}>
							<div className={styles.network}>
								<Checkbox id={doorGroupModalNetworkLayoutCheckboxId} onChange={handleCheckDisplayNetwork} checked={displayMyNetworkLayout}>
									<label htmlFor={doorGroupModalNetworkLayoutCheckboxId}> {_('DisplayMyNetworkLayout')}</label>
								</Checkbox>
							</div>
							<div className={styles.add}>
								<Button id='doorGroupAddDoorModalButton' type='primary' onClick={() => handleAddReaderToGrid()}>
									<PlusOutlined /> {_('Add')}
								</Button>
							</div>
						</div>
						<div className={styles.sections}>
							<InfoGrid onClickRow={handleOnClickGridReaderRow} />
						</div>
						<div className={styles.sections}>
							<div className={styles.edit}>
								<Button
									id='doorGroupEditSelectedDoorButton'
									key='edit'
									type='primary'
									onClick={() => setEditControlZonesModal(true)}
									disabled={disableActionReaderButtons}>
									<EditOutlined /> {_('Edit')}
								</Button>
								<Button
									id='doorGroupModalRemoveSelectedDoorButton'
									key='remove'
									onClick={() => handleRemoveReader()}
									disabled={disableActionReaderButtons}>
									<MinusOutlined /> {_('Remove')}
								</Button>
							</div>
						</div>
					</div>
				</Spin>
			</Modal>
			<Modal
				width='1100px'
				visible={openTimeZoneModal}
				title={_('AddNewTimeZone')}
				onCancel={() => handleOpenTimeZoneModal(false)}
				onClickOk={() => null}>
				<TimeZones onEntityAction={handleOnAddNewTimeZone} disableDelete />
			</Modal>
		</Provider>
	);
};

export { DoorModal };

import { CaretDownOutlined, CaretUpOutlined, PlusOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import { Button, Spin, notification } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { handleResponse } from '../../../Helper';
import { enrollmentApi } from '../../../api';
import { ComponentPermission, SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../../model/AccountModel';
import { CriteriaInfo, FieldDataType, PaginationSetting } from '../../../model/CommonModel';
import { OptionsButtonBuilder } from '../../../model/DeviceAdminModel';
import {
	AddActionOptions,
	AdvanceSearchCriteria,
	PersonActionOptions,
	PersonDetailModel,
	PersonGroups,
	TreeViewPersonGroupModel,
} from '../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import {
	clearAdvancedSearch,
	loadPersonInformation,
	setActiveAdvancedSearch,
	setDisplayAddViaSmartCard,
	setEnrollmentTabInfo,
	setIsEnrollmentTabLoading,
	setKeywordSearchText,
	setPersonInformationId,
	setSelectedRowKeys,
	setUpdateCounter,
} from '../../../store/enrollment/actions';
import {
	selectAddPersonOptions,
	selectAdvanceSearchCriteria,
	selectDisplayAddViaSmartCard,
	selectIsEnrollmentTabLoading,
	selectIsFICAMEnabled,
	selectPersonGroups,
	selectPersonId,
	selectPersonTablePaginationSettings,
	selectSelectedPersonGroup,
	selectSelectedRowKeys,
} from '../../../store/enrollment/selectors';
import { ButtonDropdown, ColumnConfiguration, HeaderBar, ModalConfirmCustomFooter, ModalConfirmationDualList } from '../../common';
import { AdvancedSearch, FindCredential, PIVContainer, PersonGroupSelect, PersonInformationContainer, PersonTable, SearchPersonBar } from '../../enrollment';
import { createEnrollmentButtonOptions, getAddPersonButtonsPermissionErrorMessage, isWindowsOS } from '../helper';
import styles from './enrollmentTab.module.scss';

const user: User = getUser();
const canUseScanners: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Person_Usescanners).allowed;
const canUseFindCredential: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_EnrollmentManager_FindCredential).allowed;
const personPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Enrollment_Person);

const EnrollmentTab: React.FC = () => {
	const dispatch = useStoreDispatch();
	const [displayColumnConfiguration, setDisplayColumnConfiguration] = useState<boolean>(false);
	const [displayAdvancedSearch, setDisplayAdvancedSearch] = useState<boolean>(false);
	const [displayFindCredential, setDisplayFindCredential] = useState<boolean>(false);
	const [hideAdvancedSearchValue, setHideAdvancedSearchValue] = useState<boolean>(false);
	const [requestCriteria, setRequestCriteria] = useState<CriteriaInfo[]>([]);
	const [hasCriteriaChanged, setHasCriteriaChanged] = useState<boolean>(false);
	const [displayCriteriaChangedModal, setDisplayCriteriaChangedModal] = useState<boolean>(false);
	const [updatePivPersonData, setUpdatePivPersonData] = useState<PersonDetailModel>(undefined);

	const selectedPersonGroups: TreeViewPersonGroupModel = useStoreSelector<TreeViewPersonGroupModel>(selectSelectedPersonGroup);
	const isFICAMEnabled: boolean = useStoreSelector<boolean>(selectIsFICAMEnabled);
	const selectedRowKeys: React.Key[] = useStoreSelector<React.Key[]>(selectSelectedRowKeys);
	const personTablePaginationSettings: PaginationSetting = useStoreSelector<PaginationSetting>(selectPersonTablePaginationSettings);
	const advanceSearchCriteria: AdvanceSearchCriteria = useStoreSelector<AdvanceSearchCriteria>(selectAdvanceSearchCriteria);
	const addPersonOptions: OptionsButtonBuilder<string> = useStoreSelector<OptionsButtonBuilder<string>>(selectAddPersonOptions);
	const personGroups: PersonGroups[] = useStoreSelector<PersonGroups[]>(selectPersonGroups);
	const personId: number = useStoreSelector<number>(selectPersonId);
	const isEnrollmentTabLoading: boolean = useStoreSelector<boolean>(selectIsEnrollmentTabLoading);
	const displayAddViaSmartCard: boolean = useStoreSelector<boolean>(selectDisplayAddViaSmartCard);

	useEffect(() => {
		enrollmentApi.getEnrollmentTabInfo().then(res => {
			batch(() => {
				dispatch(setEnrollmentTabInfo(res));
				dispatch(setIsEnrollmentTabLoading(false));
			});
		});
	}, []);

	const handleOnAddPerson = async (key: string) => {
		const splitKey = key.split(',');
		if (splitKey.length === 2) {
			const action: number = Number(splitKey[0]);
			const personId: number = Number(splitKey[1]);
			switch (action) {
				case AddActionOptions.AddDefault:
					dispatch(setPersonInformationId(personId));
					break;

				case AddActionOptions.AddTemplate:
					dispatch(loadPersonInformation(personId, true));
					break;
			}
		}
	};

	const handleActionScope = async (action: PersonActionOptions): Promise<void> => {
		switch (action) {
			case PersonActionOptions.Edit: {
				dispatch(loadPersonInformation(Number(selectedRowKeys[0])));
				break;
			}

			case PersonActionOptions.Delete: {
				enrollmentApi.getUsersToDeleteInfo(selectedRowKeys.map<number>(x => Number(x))).then(res => {
					if (!handleResponse(res)) {
						ModalConfirmationDualList({
							type: _('Person'),
							data: res.Entity,
							onConfirm: () => handleOkConfirmation(),
							firstColumnTitle: _('LastName'),
							secondColumnTitle: _('FirstName'),
							firstDataIndex: 'LastName',
							secondDataIndex: 'FirstName',
						});
					}
				});

				break;
			}
		}
	};

	const handleOkConfirmation = async () => {
		const usersIdsToDelete: React.Key[] = [...selectedRowKeys];
		const clonedState: React.Key[] = [...selectedRowKeys];

		for (const userId of usersIdsToDelete) {
			const response = await enrollmentApi.deletePerson(Number(userId));
			if (handleResponse(response)) {
				continue;
			}

			const personIndex = clonedState.findIndex(x => x === userId);
			if (personIndex !== -1) {
				clonedState.splice(personIndex, 1);
			}
		}

		batch(() => {
			dispatch(setSelectedRowKeys(clonedState));
			dispatch(setUpdateCounter());
		});
	};

	const handleOnResetSearch = (): void => {
		if (advanceSearchCriteria) {
			dispatch(clearAdvancedSearch());
			setRequestCriteria([]);
		} else {
			dispatch(setKeywordSearchText(''));
		}
	};

	const handleOnSetRequestCriteria = (criteriaInfo: CriteriaInfo[], firstPass?: boolean): void => {
		if (!firstPass) {
			setHasCriteriaChanged(true);
		}
		setRequestCriteria(criteriaInfo);
	};

	const onHideAdvancedSearch = (): void => {
		if (displayAdvancedSearch) {
			if (requestCriteria.length !== 0 && hasCriteriaChanged) {
				setDisplayCriteriaChangedModal(true);
			} else {
				hideAdvancedSearch();
			}
		} else {
			setDisplayAdvancedSearch(true);
			setHideAdvancedSearchValue(false);
			setHasCriteriaChanged(false);
		}
	};

	const handleOnDismissChanges = () => {
		if (advanceSearchCriteria) {
			handleOnResetSearch();
		}
		hideAdvancedSearch();
	};

	const hideAdvancedSearch = () => {
		if (displayCriteriaChangedModal) {
			setDisplayCriteriaChangedModal(false);
		}
		if (hasCriteriaChanged) {
			setHasCriteriaChanged(false);
		}
		setRequestCriteria([]);
		setHideAdvancedSearchValue(true);
		setTimeout(() => {
			setDisplayAdvancedSearch(false);
		}, 400);
	};

	const handleOnAdvancedSearch = () => {
		const currentAdvanceSearchCriteria: AdvanceSearchCriteria = {
			CriteriaRequest: requestCriteria.map(x => {
				let secondaryInput = x.SecondaryInput;
				let primaryInput = x.PrimaryInput;
				if (x.Type === FieldDataType.DateTime) {
					primaryInput = moment(x.PrimaryInput).format('YYYY-MM-DD');
					if (secondaryInput) {
						secondaryInput = moment(x.SecondaryInput).format('YYYY-MM-DD');
					}
				} else if (x.Type === FieldDataType.Boolean) {
					primaryInput = `${x.PrimaryInput}`;
				}

				return {
					...x,
					PrimaryInput: primaryInput,
					SecondaryInput: secondaryInput,
				};
			}),
			GroupId: selectedPersonGroups.GroupId,
			FolderId: selectedPersonGroups.FolderId,
		};

		dispatch(setActiveAdvancedSearch(currentAdvanceSearchCriteria));
		setHasCriteriaChanged(false);
	};

	const handleOnConfirmCriteriaChanges = () => {
		setDisplayCriteriaChangedModal(false);
		handleOnAdvancedSearch();
	};

	const handleOnOpenAddViaSmartCard = () => {
		if (isWindowsOS) {
			dispatch(setDisplayAddViaSmartCard(true));
		} else {
			notification.error({
				message: _('SmartCardEnrollmentWindowsOS'),
			});
		}
	};

	const hasNoPersonGroupSelected: boolean = selectedPersonGroups?.GroupId === -1;
	const hasAtLeastOneGroupAvailable: boolean = personGroups?.length > 1;
	const disableAddButtons: boolean = !personPermissions.canAdd || hasNoPersonGroupSelected;
	const disableAddViaSmartCard: boolean = !personPermissions.canAdd || !isFICAMEnabled || !canUseScanners;
	const disabledActionButtons: boolean = selectedRowKeys.length === 0;
	const hasMoreThanOne: boolean = selectedRowKeys.length > 1;
	const items: OptionsButtonBuilder<string | number> = createEnrollmentButtonOptions(hasMoreThanOne);
	const hasPersonTemplates: boolean = addPersonOptions.options.length > 1;

	const headerTitle: string =
		personTablePaginationSettings.SearchedValue !== '' || advanceSearchCriteria
			? advanceSearchCriteria
				? _('AdvancedSearchResults')
				: _('SearchResults')
			: `${_('PersonsGroups')} - ${selectedPersonGroups?.Name ? selectedPersonGroups.Name : ''}`;

	const headerTitleElement: JSX.Element =
		personTablePaginationSettings.SearchedValue !== '' || advanceSearchCriteria ? (
			<a onClick={handleOnResetSearch} className={styles.resetSearchLink}>
				{_('ResetSearch')}
			</a>
		) : undefined;

	const advancedSearchButton = displayAdvancedSearch ? (
		<Button id='enrollmentCloseAdvancedSearchButton' onClick={onHideAdvancedSearch} className={styles.expandedAdvancedSearch}>
			{_('AdvancedSearch')}
			<label onClick={onHideAdvancedSearch}></label>
			<CaretUpOutlined />
		</Button>
	) : (
		<Button id='enrollmentOpenAdvancedSearchButton' onClick={onHideAdvancedSearch}>
			{_('AdvancedSearch')}
			<CaretDownOutlined />
		</Button>
	);

	const addPersonButton = hasPersonTemplates ? (
		<span title={getAddPersonButtonsPermissionErrorMessage(personPermissions.canAdd, hasNoPersonGroupSelected, hasAtLeastOneGroupAvailable)}>
			<ButtonDropdown
				id='enrollmentAddPersonDropdown'
				menuOptions={addPersonOptions.options}
				labelIcon={
					<span>
						<PlusOutlined /> {addPersonOptions.labelOrIcon}
					</span>
				}
				icon={<CaretDownOutlined />}
				onClickOption={handleOnAddPerson}
				disabled={disableAddButtons}
				useParentAsPopupContainer={false}
			/>
		</span>
	) : (
		<span>
			<Button
				id='enrollmentAddPersonButton'
				disabled={disableAddButtons}
				type='primary'
				title={getAddPersonButtonsPermissionErrorMessage(personPermissions.canAdd, hasNoPersonGroupSelected, hasAtLeastOneGroupAvailable)}
				onClick={() => dispatch(setPersonInformationId(-100))}>
				<PlusOutlined /> {_('AddPerson')}
			</Button>
		</span>
	);

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isEnrollmentTabLoading} size='large'>
			{displayAddViaSmartCard && <PIVContainer goBackText={headerTitle} setUpdatePivPersonData={value => setUpdatePivPersonData(value)} />}
			{personId === 0 && !displayAddViaSmartCard && (
				<div className={styles.enrollmentContainer}>
					<div className={styles.headerContainer}>
						<div>
							<PersonGroupSelect onSelectPersonGroup={handleOnResetSearch} />
							<SearchPersonBar onSelectPerson={option => dispatch(loadPersonInformation(option.key))} personGroup={selectedPersonGroups} />
						</div>
						<div className={cx({ [styles.advanceSearchActive]: advanceSearchCriteria })}>
							{advancedSearchButton}
							<ButtonDropdown
								id='enrollmentActionsDropdown'
								disabled={disabledActionButtons}
								menuOptions={items.options}
								icon={<CaretDownOutlined />}
								labelIcon={items.labelOrIcon}
								onClickOption={handleActionScope}
							/>
						</div>
						<div>
							<div className={styles.addButtons}>
								<Button
									id='enrollmentFindCredentialButton'
									disabled={!canUseFindCredential}
									onClick={() => setDisplayFindCredential(true)}
									title={getPermissionErrorMessage(canUseFindCredential)}>
									<SearchOutlined /> {_('FindCredential')}
								</Button>
								<div>{addPersonButton}</div>
								<Button
									id='enrollmentAddPersonViaSmartCardButton'
									type='primary'
									onClick={handleOnOpenAddViaSmartCard}
									disabled={disableAddViaSmartCard || hasNoPersonGroupSelected}
									title={getAddPersonButtonsPermissionErrorMessage(
										!disableAddViaSmartCard,
										hasNoPersonGroupSelected,
										hasAtLeastOneGroupAvailable
									)}>
									<PlusOutlined /> {_('AddPersonViaSmartCard')}
								</Button>
							</div>
						</div>
					</div>
					{displayAdvancedSearch && (
						<AdvancedSearch
							hideAdvancedSearchValue={hideAdvancedSearchValue}
							requestCriteria={requestCriteria}
							hasCriteriaChanged={hasCriteriaChanged}
							hideAdvancedSearch={onHideAdvancedSearch}
							setRequestCriteria={handleOnSetRequestCriteria}
							handleOnAdvancedSearch={handleOnAdvancedSearch}
							displaySaveChangesModal={() => setDisplayCriteriaChangedModal(true)}
						/>
					)}
					<HeaderBar
						title={headerTitle}
						titleElement={headerTitleElement}
						buttons={
							<Button
								id='enrollmentConfigureColumnsButton'
								type='text'
								icon={<SettingFilled />}
								onClick={e => setDisplayColumnConfiguration(true)}
								className={styles.configureButton}
							/>
						}
					/>
					<PersonTable handleActionScope={handleActionScope} />
				</div>
			)}
			{displayColumnConfiguration && (
				<ColumnConfiguration
					onHideModal={() => setDisplayColumnConfiguration(false)}
					loadColumnConfiguration={enrollmentApi.getPersonColumnsConfiguration}
					dispatchAction={() => dispatch(setUpdateCounter())}
					securedComponent={SecuredComponents.Enrollment_Manager}
				/>
			)}
			{displayFindCredential && personId === 0 && <FindCredential onHideModal={() => setDisplayFindCredential(false)} />}
			<ModalConfirmCustomFooter
				width={'400px'}
				visible={displayCriteriaChangedModal}
				onOk={handleOnConfirmCriteriaChanges}
				onNo={handleOnDismissChanges}
				onCancel={() => setDisplayCriteriaChangedModal(false)}>
				<label>{_('ThisSearchHasNotAppliedYet')}</label>
				<br />
				<label>{_('DoYouWishToApplyAdvancedSearch')}</label>
			</ModalConfirmCustomFooter>
			{personId !== 0 && (
				<PersonInformationContainer
					personTableTitle={headerTitle}
					hidden={displayAddViaSmartCard}
					updatePivPersonData={updatePivPersonData}
					clearUpdatePivPersonData={() => setUpdatePivPersonData(undefined)}
				/>
			)}
		</Spin>
	);
};

export { EnrollmentTab };

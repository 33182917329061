import { Button, Input, Spin, notification } from 'antd';
import cx from 'classnames';
import React, { CSSProperties, useRef, useState } from 'react';
import { handleResponse, removeCommasAndPercentSign } from '../../../Helper';
import { accountApi } from '../../../api';
import { AuthenticatorAttestationRawResponse, CredentialCreateOptions, PublicKeyCredential } from '../../../model/AccountModel';
import { ResponseObjectEntity, ResponseStatusCode } from '../../../model/CommonModel';
import { Logger } from '../../../model/LoggingModel';
import { USBIcon } from '../../account/LogOnForm/LogOnIcons/logOnIcons';
import { buildAuthenticatorAttestationResponse, getConvertedSecurityOptions, handleNavigatorError } from '../../account/helpers';
import { Modal } from '../../common';
import styles from './addSecurityKeyModal.module.scss';

type Props = {
	userName: string;
	handleOnCloseModal: () => void;
	handleOnSuccess: () => void;
};

const iconStyle: CSSProperties = { width: 40, height: 50 };

const AddSecurityKeyModal: React.FC<Props> = ({ userName, handleOnCloseModal, handleOnSuccess }) => {
	const [keyName, setKeyName] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [validationErrorMessage, setValidationErrorMessage] = useState<string>(undefined);
	const newCredentialRef = useRef<PublicKeyCredential>(undefined);

	const handleOnAddKey = () => {
		setIsLoading(true);
		let successExit: boolean = false;
		const data: AuthenticatorAttestationRawResponse = buildAuthenticatorAttestationResponse(newCredentialRef.current);

		accountApi
			.addSecurityKey(data, keyName)
			.then(response => {
				if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
					setValidationErrorMessage(response.ErrorMessage);
				} else if (!handleResponse(response) && response.Entity) {
					notification.success({
						message: _('KeyAddedSuccessfully'),
					});
					successExit = true;
				}
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
				notification.error({
					message: err.message,
				});
			})
			.finally(() => {
				if (successExit) {
					handleOnSuccess();
				} else {
					setIsLoading(false);
				}
			});
	};

	const makeOptions = () => {
		setIsLoading(true);
		accountApi
			.getSecurityKeyOptions(userName)
			.then((makeCredentialOptions: ResponseObjectEntity<CredentialCreateOptions>) => {
				if (!handleResponse(makeCredentialOptions)) {
					const securityOptions = getConvertedSecurityOptions(makeCredentialOptions.Entity);

					navigator.credentials
						.create({
							publicKey: securityOptions,
						})
						.then((response: PublicKeyCredential) => {
							newCredentialRef.current = response;
						})
						.catch(err => {
							handleNavigatorError(err);
						})
						.finally(() => setIsLoading(false));
				}
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
				setIsLoading(false);
			});
	};

	const handleOnChangeKeyName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		if (value !== undefined) {
			setKeyName(value);
		}
	};

	const handleOnAddKeyFunction = newCredentialRef.current ? handleOnAddKey : makeOptions;

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={undefined}
			onClickOk={() => null}
			width={600}
			title={_('TwoFactorAuthentication')}
			visible={true}
			className={styles.container}
			hideCloseButton>
			<div className={styles.content}>
				<div>
					<span>{`${_('CompleteTwoFactorAuthentication')}.`}</span>
					<br />
					<span>{`${_('InsertSecurityKey')}.`}</span>
				</div>
				{newCredentialRef.current ? (
					<div className={styles.addKeyBody}>
						<div className={styles.addKeyIsConfigured}>
							<USBIcon style={iconStyle} />
							<span>{_('KeyIsConfigured')}</span>
						</div>
						<div className={cx(styles.addKeyNameContainer, { [styles.error]: validationErrorMessage })}>
							<label htmlFor='keyNameInput'>{_('Name')}</label>
							<Input id='keyNameInput' onChange={handleOnChangeKeyName} value={keyName} maxLength={50} />
							<div className={styles.addKeyErrorMessageContainer}>
								{validationErrorMessage && <span id='keyNameErrorMessage'>{_(validationErrorMessage)}</span>}
							</div>
						</div>
					</div>
				) : (
					<div>
						<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
							<div className={styles.spinContent}></div>
						</Spin>
					</div>
				)}
				<div className={styles.addKeyFooter}>
					<Button id='addKeyButton' type='primary' onClick={handleOnAddKeyFunction} loading={isLoading}>
						{_('AddKeyForAuthentication')}
					</Button>
					<Button id='cancelAddKeyButton' onClick={handleOnCloseModal} disabled={isLoading}>
						{_('Cancel')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export { AddSecurityKeyModal };

import React from 'react';
import NumberFormat from 'react-number-format';
import styles from './inputTimePicker.module.scss';

type Props = {
	id?: string;
	value: string;
	onChange: (value: string) => void;
	onBlur?: (value: string) => void;
};

const InputTimePicker: React.FC<Props> = ({ id, value, onChange, onBlur }) => {
	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		if (value === '') {
			onChange('00:00');
		} else {
			onChange(value.replace(/\s/g, '0'));
		}
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const hour = Number(value.substring(0, 2));
		const minutes = Number(value.substring(3));
		if (hour > 24) {
			onChange('00:00');
		} else if (minutes > 60) {
			onChange('00:00');
		} else if (hour === 24 && minutes > 0) {
			onChange('00:00');
		}
		onBlur?.(value);
	};

	return (
		<NumberFormat id={id} className={styles.container} value={value} onChange={handleOnChange} mask=' ' format='##:##' onBlur={handleOnBlur}></NumberFormat>
	);
};

export { InputTimePicker };

import React from 'react';
import { HeaderBar } from '../../components/common';
import { EventTable } from '../../components/event/';
import styles from './event.module.scss';

const EventPage: React.FC = () => {
	return (
		<div className={styles.eventTablePage}>
			<HeaderBar title={_('EventViewer')} />
			<EventTable />
		</div>
	);
};

export { EventPage };

import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, notification, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import {
	buildActionColumn,
	buildColumn,
	ColumnsProps,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getPageNumberSelectedItem,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handlePageChanged,
	handleResponse,
	invertSelectedRowKeys,
	ScrollType,
	timeZoneLockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../../Helper';
import { BaseColumns, PaginationSetting, ResponseObject, ResponseStatusCode, SelectOptions, SorterConfig } from '../../../../../model/CommonModel';
import {
	DeviceObjectType,
	MasterTimeZones,
	OptionsButtonBuilder,
	SelectedRowKeyPagination,
	StandardTimeZone,
	TimeZoneType,
	UseModel,
	VelocityConfigurationModel,
} from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setCurrentModalTimeZone, setItemSearchedPagination, setTotalItemsPagination } from '../../../../../store/common/actions';
import { CommonState } from '../../../../../store/common/reducer';
import {
	clearVelocityConfigurationSelectionsAction,
	deleteTimeZoneMaster,
	setMasterTimeZonesBy,
	setMasterTimeZonesByData,
	setVelocityActionScopeKey,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
} from '../../../../../store/deviceControl/actions';
import { RootReducer } from '../../../../../store/rootReducer';
import { ModalConfirmationList, SearchColumn } from '../../../../common';
import { EditableCell } from '../../../../common/EditableCell/EditableCell';
import { StandardTimeZoneColumns } from '../TimeZonesStandard/TimeZonesStandard';
import styles from './timeZonesMaster.module.scss';

type Props = {
	data: MasterTimeZones[];
	options: OptionsButtonBuilder<string>;
	actionScopeKey: string;
	shouldResetSearchColumn: boolean;
	selectedRowKeysPagination: SelectedRowKeyPagination[];
	currentPage: number;
	redirectPage: boolean;
	currentPageSize: number;
	sorterConfig: SorterConfig;
	selectedRowKeys: React.Key[];
	onShowAlert: (records: UseModel[], title: string, timeZoneType: TimeZoneType) => void;
	setShouldResetSearchColumn: (value: boolean) => void;
	setRedirectPage: (value: boolean) => void;
	setSelectedRowKeysPagination: (value: SelectedRowKeyPagination[]) => void;
	setCurrentPage: (value: number) => void;
	setIsItemOnSamePage: (value: boolean) => void;
	setCurrentPageSize: (value: number) => void;
	setSorterConfig: (value: SorterConfig) => void;
	setSelectedRowKeys: (value: React.Key[]) => void;
};

type MasterTimeZoneColumns = {
	MasterTimeZoneId: number;
	Name: string;
	InUse: boolean;
	Action: React.ReactNode;
	StandardTimeZones: StandardTimeZoneColumns[];
} & BaseColumns;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: 379, y: 370 };
const maxLength: number = 50;

const TimeZonesMaster: React.FC<Props> = ({
	data,
	actionScopeKey,
	options,
	shouldResetSearchColumn,
	selectedRowKeysPagination,
	currentPage,
	redirectPage,
	currentPageSize,
	sorterConfig,
	selectedRowKeys,
	setSelectedRowKeys,
	onShowAlert,
	setShouldResetSearchColumn,
	setRedirectPage,
	setSelectedRowKeysPagination,
	setCurrentPage,
	setIsItemOnSamePage,
	setCurrentPageSize,
	setSorterConfig,
}) => {
	const dispatch = useStoreDispatch();
	const { isEditMode, isFilterMode }: VelocityConfigurationModel = useStoreSelector(
		(state: RootReducer): VelocityConfigurationModel => state.deviceControlReducer.velocityConfiguration
	);
	const { totalItemsPagination, itemSearchedPagination, allItemsPagination }: CommonState = useStoreSelector(
		(state: RootReducer): CommonState => state.commonReducer
	);
	const [tableData, setTableData] = useState<MasterTimeZoneColumns[]>([]);
	const [masterTimeZoneSelected, setMasterTimeZoneSelected] = useState<MasterTimeZones>();

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [editingKey, setEditingKey] = useState('');
	const [form] = Form.useForm();
	const isSearchPerformed = useRef<boolean>(false);
	const tablePaginationSetting: PaginationSetting = useMemo(
		() => ({
			PageNumber: currentPage,
			PageSize: currentPageSize,
			SortDirection: sorterConfig.direction,
			SortField: sorterConfig.field,
		}),
		[currentPage, currentPageSize, JSON.stringify(sorterConfig)]
	);

	useEffect(() => {
		const mapRes: MasterTimeZoneColumns[] = createData();
		setTableData(mapRes);
		getPreviousSelectedRowKeys();
	}, [JSON.stringify(data)]);

	useEffect(() => {
		if (actionScopeKey !== '') {
			handleActionScope(actionScopeKey).then(res => dispatch(setVelocityActionScopeKey('')));
		}
	}, [actionScopeKey]);

	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage) {
			setRedirectPage(false);
			const masterTimeZoneKeySelected = selectedRowKeys?.[0];
			getMasterTimeZoneSelected(masterTimeZoneKeySelected);
		}
	}, [JSON.stringify(tableData)]);

	useEffect(() => {
		if (masterTimeZoneSelected) {
			const selectedMasterTimezonePage = getPageNumberSelectedItem(
				allItemsPagination.map(x => x),
				masterTimeZoneSelected.Id.toString(),
				pagination.pageSize
			);

			handlePageChanged(
				selectedMasterTimezonePage,
				currentPage,
				() => setSelectedRowKeysPagination([{ Id: masterTimeZoneSelected.Id.toString(), PaginationPage: selectedMasterTimezonePage }]),
				setCurrentPage,
				setIsItemOnSamePage
			);

			const newConfig: PaginationSetting = {
				...tablePaginationSetting,
				PageNumber: selectedMasterTimezonePage,
				SearchedValue: itemSearchedPagination,
			};

			dispatch(setMasterTimeZonesBy(newConfig));
		}
	}, [JSON.stringify(masterTimeZoneSelected)]);

	useEffect(() => {
		return () => {
			clearSelection();
			clearPagination();
		};
	}, []);

	const clearPagination = () => {
		dispatch(setTotalItemsPagination(0));
		dispatch(setItemSearchedPagination(''));
	};

	const clearSelection = () => {
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.TimeZones, subObjectType: 'master' }));
		setSelectedRowKeys([]);
		setMasterTimeZoneSelected(undefined);
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const getMasterTimeZoneSelected = async (key: React.Key) => {
		const masterTimeZoneId = Number(key);

		const masterTimeZoneSelected = data.find(timeZone => timeZone.Id === masterTimeZoneId);

		const masterTimeZone = masterTimeZoneSelected ? masterTimeZoneSelected : await fetchMasterTimeZone(masterTimeZoneId);

		setMasterTimeZoneSelected(masterTimeZone);
	};

	const fetchMasterTimeZone = async (masterTimeZoneId: number): Promise<MasterTimeZones> => {
		const { Entity: masterTimeZone } = await deviceAdminApi.getMasterTimeZone(masterTimeZoneId);

		return masterTimeZone;
	};

	const onDeleteTimezoneRequest = async (timeZone: MasterTimeZones): Promise<void> => {
		const id = timeZone.Id;
		const response = await deviceAdminApi.deleteMasterTimeZone(id);
		switch (response.ResponseStatusCode) {
			case ResponseStatusCode.FailedValidation:
				onShowAlert(response.InUseByList, _('TheFollowingDependenciesWhereFoundTimeZone').replace('%1', timeZone.Name), TimeZoneType.Master);
				break;
			case ResponseStatusCode.Success:
				dispatch(deleteTimeZoneMaster(id));
				break;
			default:
				handleResponse(response);
				break;
		}
	};

	const handleOkConfirmation = async masterTimeZonesSelected => {
		for (const timeZone of masterTimeZonesSelected) {
			await onDeleteTimezoneRequest(timeZone);
		}
		clearSelection();
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setCurrentPage(page);
		setIsItemOnSamePage(isSamePage);
		if (pageSize !== currentPageSize) {
			clearSelection();
			setCurrentPageSize(pageSize);
		}
	};

	const disabled: boolean = editingKey !== '';
	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		currentPage,
		currentPageSize,
		totalItemsPagination,
		undefined,
		selectedRowKeys
	);
	const isPaginationItemSelected: boolean = selectedRowKeys.length !== 1;

	const subColumnsHeader = () => (
		<div className={styles.subColumnsHeader}>
			<div>{_('StandardTimeZones')}</div>
			<div>{_('Start')}</div>
			<div>{_('End')}</div>
			<div>M</div>
			<div>T</div>
			<div>W</div>
			<div>T</div>
			<div>F</div>
			<div>S</div>
			<div>S</div>
			<div>H</div>
			<div>1</div>
			<div>2</div>
			<div>3</div>
			<div>4</div>
		</div>
	);

	let columns: ColumnsProps<MasterTimeZoneColumns>[] = [
		,
		{
			...buildColumn(_('Name'), 'Name', '250px', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: undefined,
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection: () => clearSelection(),
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => {
					const newConfig: PaginationSetting = {
						...tablePaginationSetting,
						PageNumber: 1,
						SearchedValue: searchedValue,
					};
					dispatch(setMasterTimeZonesBy(newConfig));
				},
				setSearchedValue: (searchedValue: string) => dispatch(setItemSearchedPagination(searchedValue)),
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
			render: (text, row) => {
				return {
					children: <label id={`masterTimeZoneName-${row.MasterTimeZoneId}`}>{text}</label>,
					props: { className: row.InUse ? styles.inUse : styles.notReferenced },
				};
			},
		},
		{
			...buildColumn(subColumnsHeader, 'StandardTimeZones', '650px', 'start'),
			render: (value, row) => {
				const isRowSelected = selectedRowKeys.findIndex(id => id === row.MasterTimeZoneId.toString()) !== -1;
				let children: ReactNode = undefined;
				let className = undefined;

				row.StandardTimeZones.length > 0
					? ((children = (
							<div className={cx(styles.gridStdTimezone, { [styles.rowSelected]: isRowSelected || (isEditMode && isRowSelected) })}>
								{row.StandardTimeZones.map((stdTimezone, index) => (
									<React.Fragment key={`${row.MasterTimeZoneId}-${index}`}>
										<div>
											<div>{stdTimezone.Name}</div>
											<div>{stdTimezone.Start}</div>
											<div>{stdTimezone.End}</div>
											<div>{stdTimezone.Monday}</div>
											<div>{stdTimezone.Tuesday}</div>
											<div>{stdTimezone.Wednesday}</div>
											<div>{stdTimezone.Thursday}</div>
											<div>{stdTimezone.Friday}</div>
											<div>{stdTimezone.Saturday}</div>
											<div>{stdTimezone.Sunday}</div>
											<div>{stdTimezone.Holiday}</div>
											<div>{stdTimezone.Holiday1}</div>
											<div>{stdTimezone.Holiday2}</div>
											<div>{stdTimezone.Holiday3}</div>
											<div>{stdTimezone.Holiday4}</div>
										</div>
									</React.Fragment>
								))}
							</div>
					  )),
					  (className = styles.gridStdTimezoneContainer))
					: ((children = (
							<div
								className={cx(styles.extraColumnTableContainer, {
									[styles.extraColumnTableSelected]: isRowSelected || (isEditMode && isRowSelected),
								})}></div>
					  )),
					  (className = cx(styles.extraColumnTable, { [styles.extraColumnTableSelected]: isRowSelected || (isEditMode && isRowSelected) })));
				return {
					children,
					props: { className },
				};
			},
		},
	];
	if (isEditMode) {
		columns.unshift({
			...buildActionColumn(disabled),
			render: (value, row, index) => {
				return {
					children: <label id={`masterTimeZoneActionContainer-${row.MasterTimeZoneId}`}>{value}</label>,
					props: { className: row.InUse ? styles.inUse : styles.notReferenced },
				};
			},
		});
	}

	const onChangeSelection = (key: MasterTimeZoneColumns) => {
		const masterTimeZoneIdSelected = key.key;
		if (selectedRowKeys.findIndex(masterTimeZoneId => masterTimeZoneId === masterTimeZoneIdSelected) === -1) {
			const cloneStateProps: MasterTimeZones[] = data.map(x =>
				x.Id?.toString() === masterTimeZoneIdSelected ? { ...x, checked: true } : { ...x, checked: false }
			);
			dispatch(setMasterTimeZonesByData(cloneStateProps));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: masterTimeZoneIdSelected,
				PaginationPage: currentPage,
			};
			setIsItemOnSamePage(true);
			setSelectedRowKeysPagination([{ ...selectedKeysPagination }]);
			setSelectedRowKeys([masterTimeZoneIdSelected]);
		}
	};

	const isEditing = (record: MasterTimeZoneColumns) => record.MasterTimeZoneId.toString() === editingKey;
	const contextMenuOptions: SelectOptions<string>[] = [...options?.options];

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: MasterTimeZoneColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: MasterTimeZoneColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const getMasterTimeZonesSelected = async (): Promise<MasterTimeZones[]> => {
		const { Entity: masterTimeZones } = await deviceAdminApi.retrieveMasterTimeZones();

		const masterTimeZonesSelected = masterTimeZones.filter(masterTimeZone => selectedRowKeys.findIndex(k => k === masterTimeZone.Id?.toString()) !== -1);

		return masterTimeZonesSelected;
	};

	const handleActionScope = async (key: string) => {
		setRedirectPage(false);

		if (key === 'delete') {
			const masterTimeZonesSelected = await getMasterTimeZonesSelected();
			ModalConfirmationList({
				type: _('MasterTimeZone'),
				data: masterTimeZonesSelected,
				onConfirm: () => handleOkConfirmation(masterTimeZonesSelected),
			});

			return;
		}

		const masterTimeZoneIdSelected = Number(selectedRowKeys?.[0]);
		const masterTimeZoneSelected = data.find(timeZone => timeZone.Id === masterTimeZoneIdSelected);
		const timeZone: MasterTimeZones = masterTimeZoneSelected ? masterTimeZoneSelected : await fetchMasterTimeZone(masterTimeZoneIdSelected);
		const lockComponentCondition: boolean = key === 'edit';
		const isLocked: boolean = await timeZoneLockedValidation(TimeZoneType.Master, lockComponentCondition);

		if (isLocked) {
			return;
		}

		switch (key) {
			case 'rename':
				const cloneState: MasterTimeZones[] = changeStateProps(timeZone.Id, { editable: true });
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: MasterTimeZoneColumns = tableData.find(x => x.MasterTimeZoneId === timeZone.Id);
				edit(findKey);
				dispatch(setMasterTimeZonesByData(cloneState));
				break;

			case 'edit':
				dispatch(setCurrentModalTimeZone({ entity: timeZone, timeZoneType: TimeZoneType.Master }));
				break;
		}
	};

	const changeStateProps = (deviceId: number, masterTimeZone: Partial<MasterTimeZones>): MasterTimeZones[] => {
		const cloneState: MasterTimeZones[] = [...data];
		const index = cloneState.findIndex(x => x.Id === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...masterTimeZone });
		}

		return cloneState;
	};

	const edit = (record: MasterTimeZoneColumns) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.MasterTimeZoneId.toString());
	};

	const handleOnSaveEditRow = async (deviceId: number) => {
		const isLocked: boolean = await timeZoneLockedValidation(TimeZoneType.Master, false);
		if (isLocked) {
			setEditingKey('');
			const cloneStateProps: MasterTimeZones[] = changeStateProps(deviceId, { editable: false });
			dispatch(setMasterTimeZonesByData(cloneStateProps));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(DeviceObjectType.TimeZones, name.trim(), deviceId, TimeZoneType.Master);
		const newConfig: PaginationSetting = {
			...tablePaginationSetting,
			SearchedValue: itemSearchedPagination,
		};
		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setMasterTimeZonesBy(newConfig));
			return;
		}

		setEditingKey('');
		setRedirectPage(true);
		dispatch(setMasterTimeZonesBy(newConfig));
		dispatch(setVelocityConfigurationEditMode(false));
		if (!isPaginationItemSelected && isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const handleOnCancelEditRow = (deviceId: number) => {
		const cloneStateProps: MasterTimeZones[] = changeStateProps(deviceId, { editable: false });
		dispatch(setMasterTimeZonesByData(cloneStateProps));
		dispatch(setVelocityConfigurationEditMode(false));
		setEditingKey('');
	};

	const getSelectedRowKeysForSelectAll = (newSelectedRowKeys: React.Key[]): React.Key[] => {
		return getUniqueValuesArray<React.Key>(newSelectedRowKeys, allItemsPagination);
	};

	const handleChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneStateProps: MasterTimeZones[] = data.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			currentPage,
			setIsItemOnSamePage
		);

		dispatch(setMasterTimeZonesByData(cloneStateProps));
		setSelectedRowKeysPagination(selectedKeysPagination);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectAll = () => {
		const newSelectedRowKeys = getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneStateProps: MasterTimeZones[] = data.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			currentPage,
			setIsItemOnSamePage
		);
		dispatch(setMasterTimeZonesByData(cloneStateProps));
		setSelectedRowKeysPagination(selectedKeysPagination);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectInvert = () => {
		const dataKeys = data.map<React.Key>(x => x.Id?.toString());

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);

		const cloneStateProps: MasterTimeZones[] = data.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(key => key === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			currentPage,
			setIsItemOnSamePage
		);
		dispatch(setMasterTimeZonesByData(cloneStateProps));
		setSelectedRowKeysPagination(selectedKeysPagination);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const createActionElement = (masterTimeZone: MasterTimeZones): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (masterTimeZone.editable) {
			const id = masterTimeZone.Id;

			content = (
				<>
					<Button id='renameMasterTimeZoneSaveButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(id)}>
						{_('Save')}
					</Button>
					<Button id='renameMasterTimeZoneCancelButton' key='cancel' onClick={() => handleOnCancelEditRow(id)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): MasterTimeZoneColumns[] => {
		if (data.length > 0) {
			let result: MasterTimeZoneColumns[] = [];
			data.forEach((masterTimeZone: MasterTimeZones) => {
				let masterColumn: MasterTimeZoneColumns[];
				if (masterTimeZone.StandardTimeZones.length === 0) {
					masterColumn = [
						{
							MasterTimeZoneId: masterTimeZone.Id,
							key: masterTimeZone.Id?.toString(),
							Action: createActionElement(masterTimeZone),
							Name: masterTimeZone.Name,
							InUse: masterTimeZone.InUse,
							StandardTimeZones: [],
						},
					];
				} else {
					masterColumn = [
						{
							MasterTimeZoneId: masterTimeZone.Id,
							key: masterTimeZone.Id?.toString(),
							Action: createActionElement(masterTimeZone),
							Name: masterTimeZone.Name,
							InUse: masterTimeZone.InUse,
							StandardTimeZones: masterTimeZone.StandardTimeZones.map((standardTimeZone: StandardTimeZone, index: number) => ({
								key: `${masterTimeZone.Id}-${standardTimeZone.Id}`,
								Name: standardTimeZone.Name,
								Start: standardTimeZone.StartTime,
								End: standardTimeZone.EndTime,
								Monday: standardTimeZone.Monday ? <CheckOutlined /> : null,
								Tuesday: standardTimeZone.Tuesday ? <CheckOutlined /> : null,
								Wednesday: standardTimeZone.Wednesday ? <CheckOutlined /> : null,
								Thursday: standardTimeZone.Thursday ? <CheckOutlined /> : null,
								Friday: standardTimeZone.Friday ? <CheckOutlined /> : null,
								Saturday: standardTimeZone.Saturday ? <CheckOutlined /> : null,
								Sunday: standardTimeZone.Sunday ? <CheckOutlined /> : null,
								Holiday: standardTimeZone.Holidays ? <CheckOutlined /> : null,
								Holiday1: standardTimeZone.HolidayTable1 ? <CheckOutlined /> : null,
								Holiday2: standardTimeZone.HolidayTable2 ? <CheckOutlined /> : null,
								Holiday3: standardTimeZone.HolidayTable3 ? <CheckOutlined /> : null,
								Holiday4: standardTimeZone.HolidayTable4 ? <CheckOutlined /> : null,
								Action: undefined,
								InUse: standardTimeZone.InUse,
							})),
						},
					];
				}

				result.push(...masterColumn);
			});

			return result;
		}

		return [];
	};

	const getPreviousSelectedRowKeys = () => {
		const previousSelectedRowKeys = data.reduce<React.Key[]>((result, item) => {
			if (item.checked) {
				result.push(item.Id.toString());
			}
			return result;
		}, []);

		setSelectedRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedRowKeys));
	};

	const rowSelection: TableRowSelection<MasterTimeZoneColumns> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		selectedRowKeys,
		getCheckboxProps: record => ({
			id: `masterTimeZonesTableCheckbox-${record.key}`,
			disabled,
		}),
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination, filters: { Name?: string[] }, sorter) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setMasterTimeZonesBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SearchedValue: itemSearchedPagination,
				})
			);
		}
	};

	return (
		<div id='masterTimeZonesTable' className={styles.container}>
			<Form form={form} component={false}>
				<Table
					id='masterTimeZonesTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					pagination={pagination}
					scroll={scroll}
					size='small'
					rowClassName={(record: MasterTimeZoneColumns, index) => {
						return record.InUse ? styles.inUse : styles.notReferenced;
					}}
					className={styles.masterTimeZonesTable}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					onHeaderRow={() => ({ id: 'masterTimeZonesTableHeader' })}
					getPopupContainer={() => document.getElementById('masterTimeZonesTableHeader')}
				/>
			</Form>
		</div>
	);
};

export { TimeZonesMaster };

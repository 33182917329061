import { CheckOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import moment, { Moment } from 'moment';
import React, { CSSProperties, ReactNode } from 'react';
import { ComponentPermission, SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../model/AccountModel';
import { CriteriaFunctionValue, CriteriaOperatorFunction, CriteriaRequest, CriteriaSearchRequest, FieldDataType, SelectOptions } from '../../model/CommonModel';
import { IdentificationFormatTypes, OptionsButtonBuilder } from '../../model/DeviceAdminModel';
import {
	AdditionalNote,
	CredentialActionOptions,
	CredentialOptionsHelper,
	CredentialResponseCode,
	CredentialSearchField,
	CredentialType,
	FailedCredentialUpdateReason,
	FailedValidationReason,
	PersonActionOptions,
	PersonDetailModel,
	PhotoActionOptions,
	initialFormValueObject,
} from '../../model/EnrollmentModel';
import { UserDefinedFieldModel, UserDefinedFieldType } from '../../model/UserDefinedFields';
import { SelectOption } from '../common';

const user: User = getUser();
const personPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Enrollment_Person);
const credentialPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Enrollment_Credential);
const canForgivePassback: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_ForgivePassback).allowed;
const canOverrideCodeTamper: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_OverrideCodeTamper).allowed;
const canResetLimitCount: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_ResetLimitCount).allowed;
const canForceDownload: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_ForceDownload).allowed;
const canAssignUnassign: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_AssignUnassign).allowed;
const canViewOptionsTab: boolean = User.getSubComponentPermission(user, SubPermissions.Enrollment_Credential_ViewOptionsTab).allowed;
const canPreviewBadge: boolean = User.getSubComponentPermission(user, SubPermissions.Badges_Badges_Preview).allowed;
const canPrintBadge: boolean = User.getSubComponentPermission(user, SubPermissions.Badges_Badges_Print).allowed;
const canDisplayPinCodes: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_EnrollmentManager_DisplayPINCodes).allowed;
const disableTagAlertDisable: boolean = canViewOptionsTab && credentialPermissions.canUpdate;
const isStandardBrowser: boolean = !window.navigator.userAgent.toLowerCase().includes('ipad') && !window.navigator.userAgent.toLowerCase().includes('iphone');

export const isWindowsOS: boolean = window.navigator.userAgent.indexOf('Win') !== -1;
export const enrollmentGoBackTextId: string = 'enrollmentGoBackText';

export const createEnrollmentButtonOptions = (hasMoreThanOne: boolean): OptionsButtonBuilder<string | number> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				id: 'enrollmentEditPersonButton',
				label: _('Edit'),
				disabled: !personPermissions.canUpdate || hasMoreThanOne,
				value: PersonActionOptions.Edit,
				title: getPermissionErrorMessage(personPermissions.canUpdate, false, hasMoreThanOne),
			},
			{
				id: 'enrollmentDeletePersonButton',
				label: _('Delete'),
				disabled: !personPermissions.canDelete,
				value: PersonActionOptions.Delete,
				title: getPermissionErrorMessage(personPermissions.canDelete),
			},
		],
	};
};

export const credentialTypesOptions: SelectOption = [
	{
		label: _('Unassigned'),
		value: CredentialType.Unassigned,
	},
	{
		label: _('Guest'),
		value: CredentialType.Guest,
	},
];

export const badgeOptions: SelectOptions<string | number>[] = [
	{
		id: 'credentialPreviewButton',
		label: _('PreviewBadge'),
		disabled: !canPreviewBadge,
		value: CredentialActionOptions.Preview,
		title: getPermissionErrorMessage(canPreviewBadge),
	},
	{
		id: 'credentialPrintButton',
		label: _('PrintBadge'),
		disabled: !canPrintBadge,
		value: CredentialActionOptions.Print,
		title: getPermissionErrorMessage(canPrintBadge),
	},
];

export const editPhotoOptions: OptionsButtonBuilder<number> = {
	labelOrIcon: _('EditPhoto'),
	options: [
		{
			id: 'enrollmentUploadImageButton',
			label: _('UploadPhoto'),
			separator: !isStandardBrowser,
			value: PhotoActionOptions.Upload,
		},
		...(isStandardBrowser
			? [
					{
						id: 'enrollmentCaptureImageButton',
						label: _('CapturePhoto'),
						separator: true,
						value: PhotoActionOptions.Capture,
					},
			  ]
			: []),
		{
			id: 'enrollmentRemoveImageButton',
			label: _('Remove'),
			value: PhotoActionOptions.Remove,
		},
	],
};

export const createCredentialButtonOptions = (
	hasMoreThanOne: boolean,
	selectedCredentialHelper: CredentialOptionsHelper,
	userId: number
): OptionsButtonBuilder<string | number> => {
	const onPersonInformationMode: boolean = userId > 0;
	const onUnassignedMode: boolean = userId === -1;
	const onGuestMode: boolean = userId === -2;
	const onFindCredentialMode: boolean = userId === -3;
	let firstCredentialActions: SelectOptions<string | number>[] = [];

	let isBadgeOnly: boolean = false;
	let assignedCredential: boolean = false;

	if (selectedCredentialHelper !== undefined && !hasMoreThanOne) {
		isBadgeOnly = selectedCredentialHelper.IDF === IdentificationFormatTypes.BadgeOnly;
		assignedCredential = selectedCredentialHelper.HostUserId > 0;
		firstCredentialActions = [
			{
				id: 'credentialTagButton',
				label: _('Tag'),
				disabled: !disableTagAlertDisable || isBadgeOnly,
				value: CredentialActionOptions.Tag,
				title: getCredentialOptionsPermissionErrorMessage(disableTagAlertDisable, isBadgeOnly),
				icon: selectedCredentialHelper.Tag ? <CheckOutlined /> : undefined,
			},
			{
				id: 'credentialAlertButton',
				label: _('Alert'),
				disabled: !disableTagAlertDisable || isBadgeOnly,
				value: CredentialActionOptions.Alert,
				title: getCredentialOptionsPermissionErrorMessage(disableTagAlertDisable, isBadgeOnly),
				icon: selectedCredentialHelper.Alert ? <CheckOutlined /> : undefined,
			},
			{
				id: 'credentialEnableDisableButton',
				label: selectedCredentialHelper.Disabled ? _('Enable') : _('Disable'),
				disabled: !disableTagAlertDisable || isBadgeOnly,
				value: selectedCredentialHelper.Disabled ? CredentialActionOptions.Enable : CredentialActionOptions.Disable,
				title: getCredentialOptionsPermissionErrorMessage(disableTagAlertDisable, isBadgeOnly),
				separator: true,
			},
		];
	} else {
		firstCredentialActions = [
			{
				id: 'credentialTagButton',
				label: _('Tag'),
				disabled: !disableTagAlertDisable,
				value: CredentialActionOptions.Tag,
				title: getPermissionErrorMessage(disableTagAlertDisable),
			},
			{
				id: 'credentialAlertButton',
				label: _('Alert'),
				disabled: !disableTagAlertDisable,
				value: CredentialActionOptions.Alert,
				title: getPermissionErrorMessage(disableTagAlertDisable),
			},
			{
				id: 'credentialEnableButton',
				label: _('Enable'),
				disabled: !disableTagAlertDisable,
				value: CredentialActionOptions.Enable,
				title: getPermissionErrorMessage(disableTagAlertDisable),
			},
			{
				id: 'credentialDisableButton',
				label: _('Disable'),
				disabled: !disableTagAlertDisable,
				value: CredentialActionOptions.Disable,
				title: getPermissionErrorMessage(disableTagAlertDisable),
				separator: true,
			},
		];
	}

	const displayAssignOption: boolean = (onUnassignedMode || (onFindCredentialMode && (!assignedCredential || hasMoreThanOne))) && !onPersonInformationMode;
	const displayUnassignOption: boolean = onPersonInformationMode || onGuestMode || (onFindCredentialMode && (assignedCredential || hasMoreThanOne));
	const lastOptions: SelectOptions<string | number>[] = [
		...(!hasMoreThanOne && !(onGuestMode || onUnassignedMode) && !onPersonInformationMode && assignedCredential
			? [
					{
						id: 'credentialViewPersonInformationButton',
						label: _('ViewPersonInformation'),
						value: CredentialActionOptions.ViewPersonInformation,
					},
			  ]
			: []),
		...(displayAssignOption
			? [
					{
						id: 'credentialAssignButton',
						label: _('Assign'),
						disabled: !canAssignUnassign,
						value: CredentialActionOptions.Assign,
						title: getPermissionErrorMessage(canAssignUnassign),
						separator: !displayUnassignOption,
					},
			  ]
			: []),
		...(displayUnassignOption
			? [
					{
						id: 'credentialUnassignButton',
						label: _('Unassign'),
						disabled: !canAssignUnassign,
						value: CredentialActionOptions.Unassign,
						title: getPermissionErrorMessage(canAssignUnassign),
						separator: !displayAssignOption || (displayAssignOption && displayUnassignOption),
					},
			  ]
			: []),
		{
			id: 'credentialEditButton',
			label: _('Edit'),
			disabled: !credentialPermissions.canView || hasMoreThanOne,
			value: CredentialActionOptions.Edit,
			title: getPermissionErrorMessage(credentialPermissions.canView, false, hasMoreThanOne),
		},
		{
			id: 'credentialDeleteButton',
			label: _('Delete'),
			disabled: !credentialPermissions.canDelete,
			value: CredentialActionOptions.Delete,
			title: getPermissionErrorMessage(credentialPermissions.canDelete),
		},
	];

	return {
		labelOrIcon: '...',
		options: [
			...firstCredentialActions,
			{
				id: 'credentialForgivePassbackButton',
				label: _('ForgivePassback'),
				disabled: !canForgivePassback || isBadgeOnly,
				value: CredentialActionOptions.ForgivePassback,
				title: getCredentialOptionsPermissionErrorMessage(canForgivePassback, isBadgeOnly),
			},
			{
				id: 'credentialOverrideCodeTamperButton',
				label: _('OverrideCodeTamper'),
				disabled: !canOverrideCodeTamper || isBadgeOnly,
				value: CredentialActionOptions.OverrideCodeTamper,
				title: getCredentialOptionsPermissionErrorMessage(canOverrideCodeTamper, isBadgeOnly),
			},
			{
				id: 'credentialResetLimitCountButton',
				label: _('ResetLimitCount'),
				disabled: !canResetLimitCount || isBadgeOnly,
				value: CredentialActionOptions.ResetLimitCount,
				title: getCredentialOptionsPermissionErrorMessage(canResetLimitCount, isBadgeOnly),
				separator: true,
			},
			{
				id: 'credentialForceDownloadCountButton',
				label: _('ForceDownload'),
				disabled: !canForceDownload || isBadgeOnly,
				value: CredentialActionOptions.ForceDownload,
				title: getCredentialOptionsPermissionErrorMessage(canForceDownload, isBadgeOnly),
				separator: true,
			},
			...lastOptions,
		],
	};
};

export const getAddPersonButtonsPermissionErrorMessage = (
	hasPermission: boolean,
	hasSearchAllGroupsSelected: boolean,
	hasAtLeastOneGroupAvailable: boolean
): string => {
	if (hasPermission && !hasSearchAllGroupsSelected) {
		return '';
	}

	if (!hasPermission) {
		return _('DisabledButtonPermissionsWarning');
	}

	if (!hasAtLeastOneGroupAvailable) {
		return _('NoAccessToAnyPersonGroups');
	}

	return _('NoPersonGroupSelectedWarning');
};

export const getCredentialOptionsPermissionErrorMessage = (hasPermission: boolean, isBadgeOnly: boolean): string => {
	if (hasPermission && !isBadgeOnly) {
		return '';
	}

	if (!hasPermission) {
		return _('DisabledButtonPermissionsWarning');
	}

	return _('BadgeOnlyCredentialOptionWarning');
};

export const buildCredentialCriteriaRequest = (data: string, option: CredentialSearchField, criteriaFunction: CriteriaFunctionValue): CriteriaRequest => ({
	FieldId: option,
	Function: criteriaFunction,
	FunctionOperator: CriteriaOperatorFunction.is,
	PrimaryInput: data,
	SecondaryInput: '',
	OrNextCriteria: false,
	Type: FieldDataType.String,
	IsUDF: false,
	IsEqualTo: false,
	UDFNumber: 0,
});

export const getTableTitleHeader = (userId: number, criteriaSearchRequest: CriteriaSearchRequest): string => {
	let element: string = '';

	switch (userId) {
		case CredentialType.Guest:
			element = _('GuestCredentials');
			break;
		case CredentialType.Unassigned:
			element = _('UnassignedCredentials');
			break;
		case CredentialType.FindCredential:
			if (criteriaSearchRequest?.CriteriaRequest.length > 0) {
				const searchOption: number = criteriaSearchRequest.CriteriaRequest[0].FieldId;
				let searchValue: string = criteriaSearchRequest.CriteriaRequest[0].PrimaryInput;
				if (searchOption == CredentialSearchField.PinCode && !canDisplayPinCodes) {
					searchValue = searchValue.replace(/./g, '*');
				}
				element = `${_(CredentialSearchField[searchOption])} (${searchValue})`;
			}
			break;

		default:
			element = _('Credentials');
			break;
	}

	return element;
};

const rotateMarks: number[] = [0, 45, 90, 135, 180, 225, 270, 315, 360];
export const getNextRotateMark = (value: number): number => {
	value++;
	let nextMark: number = 360;

	if (value !== 361) {
		for (let i: number = 0; i < rotateMarks.length; i++) {
			if (rotateMarks[i] >= value && rotateMarks[i] < nextMark) {
				nextMark = rotateMarks[i];
			}
		}
	} else {
		nextMark = 45;
	}

	return nextMark;
};

const PROFILE_THUMBNAIL_IMAGE_WIDTH: number = 50;
export const getThumbnailCanvasOptions = (imageData: Cropper.ImageData): Cropper.GetCroppedCanvasOptions => {
	const ratio: number = PROFILE_THUMBNAIL_IMAGE_WIDTH / imageData.naturalWidth;
	const height: number = Math.floor(imageData.naturalHeight * ratio);

	const nPercentW: number = PROFILE_THUMBNAIL_IMAGE_WIDTH / imageData.naturalWidth;
	const nPercentH: number = height / imageData.naturalHeight;
	const nPercent: number = nPercentH < nPercentW ? nPercentH : nPercentW;

	const destWidth: number = 2 * Math.floor(imageData.naturalWidth * nPercent);
	const destHeight: number = 2 * Math.floor(imageData.naturalHeight * nPercent);

	const finalWidth: number = destWidth > imageData.naturalWidth ? imageData.naturalWidth : destWidth;
	const finalHeight: number = destHeight > imageData.naturalHeight ? imageData.naturalHeight : destHeight;

	return {
		width: finalWidth,
		height: finalHeight,
	};
};

export const supportsHTML5Video = (): boolean => {
	const navigatorPolyfill = navigator as any;
	return !!(
		navigatorPolyfill.mediaDevices ||
		navigatorPolyfill.getUserMedia ||
		navigatorPolyfill.webkitGetUserMedia ||
		navigatorPolyfill.mozGetUserMedia ||
		navigatorPolyfill.msGetUserMedia
	);
};

export const getFirstExtraTabKey = (udfViewPermissions: boolean[], tabKeys: Record<string, number>): number => {
	let key: number = -1;
	for (let tabId: number = tabKeys.groupsActiveKey; tabId <= tabKeys.additionalNotes; tabId++) {
		if (udfViewPermissions[tabId]) {
			key = tabId;
			break;
		}
	}

	return key;
};

const errorMessageStyle: CSSProperties = { color: 'red' };
export const getTabBarStyle = (errorList: FailedValidationReason[], tabId: number, tabName: string): ReactNode => {
	const errorIndex: number = errorList.findIndex((error: FailedValidationReason) => error.TabId === tabId);
	if (errorIndex !== -1) {
		return <span style={errorMessageStyle}>{tabName}</span>;
	}

	return tabName;
};

export const handleOnFailedCredentialUpdates = (failedCredentialUpdates: FailedCredentialUpdateReason[]): void => {
	let counter: number = 2;
	for (const failedCredential of failedCredentialUpdates) {
		if (failedCredential.Reason === CredentialResponseCode.InvalidMatchLength) {
			notification['error']({
				message: (
					<div>
						{failedCredential.ErrorMessage}
						<br />
						{`Dependent Credential ID (${failedCredential.CredentialId}) not downloaded.`}
					</div>
				),
				duration: 5 + counter,
			});
		} else {
			notification['error']({
				message: (
					<div>
						{`UDF generated MATCH code for dependent Credential ID (${failedCredential.CredentialId}).`}
						<br />
						{failedCredential.ErrorMessage}
						<br />
						{'Credential not downloaded.'}
					</div>
				),
				duration: 5 + counter,
			});
		}

		counter += 2;
	}
};

export const buildPersonModelOutOfFormValues = (
	values: PersonDetailModel,
	defaultPictureBase64: string,
	defaultSignatureBase64: string,
	personId: number
): PersonDetailModel => {
	let personDetail: PersonDetailModel = { PersonId: personId, UserDefinedFields: [] };
	for (const [key, value] of Object.entries(values)) {
		if (key.includes('udfField-')) {
			const udfInfo: string = key.replace('udfField-', '');
			const udfNumber: number = Number(udfInfo.split(',')[0]);
			const udfType: UserDefinedFieldType = Number(udfInfo.split(',')[1]);

			const udfField: Partial<UserDefinedFieldModel> = { FieldNumber: udfNumber, Value: `${value}` };
			if (udfType === UserDefinedFieldType.Date) {
				udfField.Value = value ? moment(value as string).format('YYYY-MM-DD') : '';
			}

			personDetail.UserDefinedFields.push(udfField);
		} else {
			personDetail = { ...personDetail, [key]: value };
		}
	}

	if (values.ProfilePictureBase64) {
		personDetail.ProfilePictureBase64 = values.ProfilePictureBase64.split(',')[1];
	} else {
		personDetail.ProfilePictureBase64 = defaultPictureBase64;
	}

	if (values.ProfileSignatureBase64) {
		personDetail.ProfileSignatureBase64 = values.ProfileSignatureBase64.split(',')[1];
	} else {
		personDetail.ProfileSignatureBase64 = defaultSignatureBase64;
	}

	if (values.AdditionalNotes !== undefined && values.CategoryId !== undefined) {
		const currentAdditionalNote: AdditionalNote = values.AdditionalNotes.find(
			(additionalNote: AdditionalNote) => additionalNote.CategoryId === Number(values.CategoryId)
		);

		if (currentAdditionalNote) {
			currentAdditionalNote.NoteContent = values.NoteContent;
		}
	}

	personDetail.AdditionalNotes = values.AdditionalNotes;

	return personDetail;
};

export const getUDFInitialFormValues = (userDefinedFields: Partial<UserDefinedFieldModel>[]): initialFormValueObject[] =>
	userDefinedFields.map((userDefinedField: UserDefinedFieldModel) => {
		let value: string | Moment = userDefinedField.Value;
		if (userDefinedField.Value !== '') {
			switch (userDefinedField.Type) {
				case UserDefinedFieldType.Date:
					value = moment(userDefinedField.Value);
					break;
				case UserDefinedFieldType.AutoUniqueNumber:
					value = userDefinedField.Value.replace('<Auto unique>', '').replace('<Auto-unique>', '');
					break;
				case UserDefinedFieldType.AutoSequentialNumber:
					value = userDefinedField.Value.replace('<Auto sequential>', '').replace('<Auto-sequential>', '');
					break;
			}
		}

		return {
			[`udfField-${userDefinedField.FieldNumber},${userDefinedField.Type}`]: value,
		};
	});

export const getProfilePictureSource = (isFormActive: boolean, blankProfilePictureBase64: string, pictureSource: string): string => {
	let profilePictureSource: string = `data:image/JPG;base64,${blankProfilePictureBase64}`;
	if (!isFormActive && pictureSource && pictureSource !== '') {
		profilePictureSource = pictureSource;
	}

	return profilePictureSource;
};

export const getClickableElement = (leaveTabElement: HTMLElement): HTMLElement => {
	const maxParentsSearch: number = 7;
	let clickableElement: HTMLElement = leaveTabElement;

	for (let index: number = 0; index < maxParentsSearch; index++) {
		if (leaveTabElement.onclick === null) {
			leaveTabElement = leaveTabElement.parentElement;
		} else {
			clickableElement = leaveTabElement;
			break;
		}
	}

	return clickableElement;
};

import { createSelector } from 'reselect';
import { AlarmModel, AlarmNote, AlarmNoteForAcknowledge } from '../../model/AlarmModel';

import { RootReducer } from '../../store/rootReducer';

export const selectShowAlarmInstructions = createSelector(
	(state: RootReducer) => state.alarmReducer.showAlarmInstructions,
	(showAlarmInstructions: boolean) => showAlarmInstructions
);

export const selectShowAlarmNotes = createSelector(
	(state: RootReducer) => state.alarmReducer.showAlarmNotes,
	(showAlarmNotes: boolean) => showAlarmNotes
);

export const selectAlarmModel = createSelector(
	(state: RootReducer) => state.alarmReducer.alarmModel,
	(alarmModel: AlarmModel) => alarmModel
);

export const selectShowAddOperatorNote = createSelector(
	(state: RootReducer) => state.alarmReducer.showAddOperatorNote,
	(showAddOperatorNote: boolean) => showAddOperatorNote
);

export const selectAlarmNoteForAcknowledge = createSelector(
	(state: RootReducer) => state.alarmReducer.alarmNoteForAcknowledge,
	(alarmNoteForAcknowledge: AlarmNoteForAcknowledge) => alarmNoteForAcknowledge
);

export const selectOperatorNotesAdded = createSelector(
	(state: RootReducer) => state.alarmReducer.operatorNoteAdded,
	(operatorNotesAdded: AlarmNote[]) => operatorNotesAdded
);

import React from 'react';
import {
	AssuranceLevelList,
	OSDPLEDOption,
	Reader,
	ReaderError,
	ReaderInterface,
	ReaderLabel,
	ReaderOptionsTab,
	ScrambleFactorImageCategories,
	ScrambleFactorImageShort,
	SelectOption,
	SelectTimeZone,
	SupportedReaderClass,
} from '../../../../model/DeviceAdminModel';
import { getDefaultAssuranceLevelSelection } from '../helpers';

export type ReaderContextState = {
	id?: number;
	controllerId: number;
	name: string;
	index: number;
	supportedReaderClass: SupportedReaderClass;
	labels: ReaderLabel[];
	anyReaderOptions: ReaderOptionsTab[];
	cardReaderOptions: ReaderOptionsTab[];
	scramblePadKeyPadOptions: ReaderOptionsTab[];
	genericTimeZones: SelectTimeZone[];
	cardMaps: SelectOption[];
	specialHandlingInstructions: SelectOption[];
	readerThreatLevels: SelectOption[];
	isReaderWireless: boolean;
	rS485ReaderTypes: SelectOption[];
	oSDPAddress: number;
	wLHubAddress: number;
	eACAddress: number;
	wL2HubAddress: number;
	allegionLockAddress: number;
	okDegrade: boolean;
	passbackZones: SelectOption[];
	cCOTZAssurance: boolean;
	assuranceLevels: SelectOption[];
	defaultAssuranceLevels: SelectOption[];
	isOnboardWiegandAvailable: boolean;
	isControllerSNIB3: boolean;
	isControllerMATCH: boolean;
	firmware: string;
	appVersion: string;
	displayBoardVersion: string;
	enableReaderBezelTamper: boolean;
	enableHexPassThrough: boolean;
	enableMatchBezelTamper: boolean;
	facilityCodeCardNumber: boolean;
	corporate1000: boolean;
	hexDigitUUID: boolean;
	enableKeyPad: boolean;
	enableOSDPHandshake: boolean;
	isDoorWireless: boolean;
	oSDPTitle: string;
	oSDPConfirmation: string;
	firmwareConfirm: string;
	firmwareWarning: string;
	firmwareTitle: string;
	tSFirmwares: SelectOption[];
	canUpdateFirmware: boolean;
	applyToAllReaders: boolean;
	readerInterface: ReaderInterface;
	isFICAMEnabled: boolean;
	antiPassback: number;
	matchAlgorithm: number;
	fascnHandling: number;
	updateFirmwarePermissionText: string;
	isEntryReaderError: boolean;
	readerErrorType: ReaderError;
	readerErrorMessage: string;
	selections: {
		cardMap: number;
		readerThreatLevel: number;
		cCOTZThreatLevel: number;
		assuranceLevel: number;
		defaultAssuranceLevel: number;
		toZone: number;
		fromZone: number;
		specialHandlingInstruction: number;
		cardCodeOnlyTimeZone: number;
		tSFirmware: number;
		rS485ReaderType: number;
	};
	unpermitted: {
		cardCodeOnlyTimeZone: SelectTimeZone;
	};
	changedOSDPLEDColor: boolean;
	useCustomOSDPLEDColor: boolean;
	osdpLEDOptions: OSDPLEDOption[];
	osdpCustomLEDColors: SelectOption[];
	readerTypeChanged: boolean;
	scrambleFactorCategories: ScrambleFactorImageCategories[];
	selectedFactorImages: ScrambleFactorImageShort[];
	viewPINEntryIndicator: boolean;
	updateScrambleFactorSelection: boolean;
	scrambleFactorErrorImageCategory: string;
};

const enum ReaderActionTypes {
	SET_READER_CONTROLLER_ID = 'SET_READER_CONTROLLER_ID',
	SET_READER_NAME = 'SET_READER_NAME',
	SET_READER_INDEX = 'SET_READER_INDEX',
	SET_READER_SUPPORTED_READER_CLASS = 'SET_READER_SUPPORTED_READER_CLASS',
	SET_READER_LABELS = 'SET_READER_LABELS',
	SET_READER_ANY_READER_OPTIONS = 'SET_READER_ANY_READER_OPTIONS',
	SET_READER_CARD_READER_OPTIONS = 'SET_READER_CARD_READER_OPTIONS',
	SET_READER_SCRAMBLE_PAD_KEY_OPTIONS = 'SET_READER_SCRAMBLE_PAD_KEY_OPTIONS',
	SET_READER_GENERIC_TIME_ZONES = 'SET_READER_GENERIC_TIME_ZONES',
	SET_READER_CARD_MAPS = 'SET_READER_CARD_MAPS',
	SET_READER_SPECIAL_HANDLING_INSTRUCTIONS = 'SET_READER_SPECIAL_HANDLING_INSTRUCTIONS',
	SET_READER_THREAT_LEVELS = 'SET_READER_THREAT_LEVELS',
	SET_READER_IS_READER_WIRELESS = 'SET_READER_IS_READER_WIRELESS',
	SET_READER_RS485_READER_TYPES = 'SET_READER_RS485_READER_TYPES',
	SET_READER_OSDP_ADDRESS = 'SET_READER_OSDP_ADDRESS',
	SET_READER_WLHUB_ADDRESS = 'SET_READER_WLHUB_ADDRESS',
	SET_READER_EAC_ADDRESS = 'SET_READER_EAC_ADDRESS',
	SET_READER_WL2HUB_ADDRESS = 'SET_READER_WL2HUB_ADDRESS',
	SET_READER_ALLEGION_LOCK_ADDRESS = 'SET_READER_ALLEGION_LOCK_ADDRESS',
	SET_READER_OK_DEGRADE = 'SET_READER_OK_DEGRADE',
	SET_READER_PASSBACK_ZONES = 'SET_READER_PASSBACK_ZONES',
	SET_READER_CCOTZ_ASSURANCE = 'SET_READER_CCOTZ_ASSURANCE',
	SET_READER_ASSURANCE_LEVELS = 'SET_READER_ASSURANCE_LEVELS',
	SET_READER_DEFAULT_ASSURANCE_LEVELS = 'SET_READER_DEFAULT_ASSURANCE_LEVELS',
	SET_READER_IS_ONBOARD_WIEGAND_AVAILABLE = 'SET_READER_IS_ONBOARD_WIEGAND_AVAILABLE',
	SET_READER_IS_CONTROLLER_SNIB3 = 'SET_READER_IS_CONTROLLER_SNIB3',
	SET_READER_IS_CONTROLLER_MATCH = 'SET_READER_IS_CONTROLLER_MATCH',
	SET_READER_FIRMWARE = 'SET_READER_FIRMWARE',
	SET_READER_ENABLE_READER_BEZEL_TAMPER = 'SET_READER_ENABLE_READER_BEZEL_TAMPER',
	SET_READER_ENABLE_HEX_PASSTHROUGH = 'SET_READER_ENABLE_HEX_PASSTHROUGH',
	SET_READER_ENABLE_MATCH_BEZEL_TAMPER = 'SET_READER_ENABLE_MATCH_BEZEL_TAMPER',
	SET_READER_FACILITY_CODE_CARD_NUMBER = 'SET_READER_FACILITY_CODE_CARD_NUMBER',
	SET_READER_CORPORATE1000 = 'SET_READER_CORPORATE1000',
	SET_READER_HEX_DIGIT_UUID = 'SET_READER_HEX_DIGIT_UUID',
	SET_READER_ENABLE_KEY_PAD = 'SET_READER_ENABLE_KEY_PAD',
	SET_READER_ENABLE_OSDP_HANDSHAKE = 'SET_READER_ENABLE_OSDP_HANDSHAKE',
	SET_READER_IS_DOOR_WIRELESS = 'SET_READER_IS_DOOR_WIRELESS',
	SET_READER_OSDP_TITLE = 'SET_READER_OSDP_TITLE',
	SET_READER_OSDP_CONFIRMATION = 'SET_READER_OSDP_CONFIRMATION',
	SET_READER_FIRMWARE_CONFIRM = 'SET_READER_FIRMWARE_CONFIRM',
	SET_READER_FIRMWARE_WARNING = 'SET_READER_FIRMWARE_WARNING',
	SET_READER_FIRMWARE_TITLE = 'SET_READER_FIRMWARE_TITLE',
	SET_READER_TS_FIRMWARES = 'SET_READER_TS_FIRMWARES',
	SET_READER_CAN_UPDATE_FIRMWARE = 'SET_READER_CAN_UPDATE_FIRMWARE',
	SET_READER_APPLY_TO_ALL_READERS = 'SET_READER_APPLY_TO_ALL_READERS',
	SET_READER_CARD_MAP = 'SET_CARD_MAP',
	SET_READER_THREAT_LEVEL = 'SET_READER_THREAT_LEVEL',
	SET_READER_CCOTZ_THREAT_LEVEL = 'SET_READER_CCOTZ_THREAT_LEVEL',
	SET_READER_ANTIPASSBACK = 'SET_READER_ANTIPASSBACK',
	SET_READER_MATCH_ALGORITHM = 'SET_READER_MATCH_ALGORITHM',
	SET_READER_FASCN_HANDLING = 'SET_READER_FASCN_HANDLING',
	SET_READER_ASSURANCE_LEVEL = 'SET_READER_ASSURANCE_LEVEL',
	SET_READER_DEFAULT_ASSURANCE_LEVEL = 'SET_READER_DEFAULT_ASSURANCE_LEVEL',
	SET_READER_TO_ZONE = 'SET_READER_TO_ZONE',
	SET_READER_FROM_ZONE = 'SET_READER_FROM_ZONE',
	SET_READER_SPECIAL_HANDLING_INSTRUCTION = 'SET_READER_SPECIAL_HANDLING_INSTRUCTION',
	SET_READER_CARD_CODE_ONLY_TIME_ZONE = 'SET_READER_CARD_CODE_ONLY_TIME_ZONE',
	SET_READER_TS_FIRMWARE = 'SET_READER_TS_FIRMWARE',
	SET_READER_RS485_READER_TYPE = 'SET_READER_RS485_READER_TYPE',
	SET_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE = 'SET_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE',
	SET_READER_INTERFACE = 'SET_READER_INTERFACE',
	SET_READER_IS_FICAM_ENABLED = 'SET_READER_IS_FICAM_ENABLED',
	SET_READER_UPDATE_FIRMWARE_PERMISSION_TEXT = 'SET_READER_UPDATE_FIRMWARE_PERMISSION_TEXT',
	SET_READER_ERROR_TYPE = 'SET_READER_ERROR_TYPE',
	SET_READER_ERROR_MESSAGE = 'SET_READER_ERROR_MESSAGE',
	INIT_READER = 'INIT_READER',
	SET_READER_ASSURANCE_INFO = 'SET_READER_ASSURANCE_INFO',
	SET_ENTRY_READER_ERROR = 'SET_ENTRY_READER_ERROR',
	SET_OSDP_LED_OPTIONS = 'SET_OSDP_LED_OPTIONS',
	SET_USE_CUSTOM_OSDP_LED_COLOR = 'SET_USE_CUSTOM_OSDP_LED_COLOR',
	SET_CHANGED_OSDP_LED_COLOR = 'SET_CHANGED_OSDP_LED_COLOR',
	SET_SCRAMBLE_FACTOR_CATEGORIES = 'SET_SCRAMBLE_FACTOR_CATEGORIES',
	SET_SELECTED_FACTOR_IMAGES = 'SET_SELECTED_FACTOR_IMAGES',
	SET_VIEW_PIN_ENTRY_INDICATOR = 'SET_VIEW_PIN_ENTRY_INDICATOR',
	SET_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION = 'SET_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION',
	SET_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY = 'SET_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY',
}

type CommonActionTypes<T extends ReaderActionTypes, K> = {
	type: T;
	payload: K;
};

//#region typesOfContextActions
type contextReaderInitReader = CommonActionTypes<ReaderActionTypes.INIT_READER, Partial<Reader>>;

type contextReaderSetAssuranceInfo = CommonActionTypes<ReaderActionTypes.SET_READER_ASSURANCE_INFO, Partial<AssuranceLevelList>>;

type contextReaderSetName = CommonActionTypes<ReaderActionTypes.SET_READER_NAME, string>;

type contextReaderControllerId = CommonActionTypes<ReaderActionTypes.SET_READER_CONTROLLER_ID, number>;

type contextReaderSetIndex = CommonActionTypes<ReaderActionTypes.SET_READER_INDEX, number>;

type contextReaderSetSupportedReaderClass = CommonActionTypes<ReaderActionTypes.SET_READER_SUPPORTED_READER_CLASS, SupportedReaderClass>;

type contextReaderSetLabels = CommonActionTypes<ReaderActionTypes.SET_READER_LABELS, ReaderLabel[]>;

type contextReaderSetAnyReaderOptions = CommonActionTypes<ReaderActionTypes.SET_READER_ANY_READER_OPTIONS, ReaderOptionsTab[]>;

type contextReaderSetCardReaderOptions = CommonActionTypes<ReaderActionTypes.SET_READER_CARD_READER_OPTIONS, ReaderOptionsTab[]>;

type contextReaderSetScramblePadKeyOptions = CommonActionTypes<ReaderActionTypes.SET_READER_SCRAMBLE_PAD_KEY_OPTIONS, ReaderOptionsTab[]>;

type contextReaderSetGenericTimeZones = CommonActionTypes<ReaderActionTypes.SET_READER_GENERIC_TIME_ZONES, SelectTimeZone[]>;

type contextReaderSetCardMaps = CommonActionTypes<ReaderActionTypes.SET_READER_CARD_MAPS, SelectOption[]>;

type contextReaderSetSpecialHandlingInstructions = CommonActionTypes<ReaderActionTypes.SET_READER_SPECIAL_HANDLING_INSTRUCTIONS, SelectOption[]>;

type contextReaderSetReaderThreatLevels = CommonActionTypes<ReaderActionTypes.SET_READER_THREAT_LEVELS, SelectOption[]>;

type contextReaderSetIsReaderWireless = CommonActionTypes<ReaderActionTypes.SET_READER_IS_READER_WIRELESS, boolean>;

type contextReaderSetRS485ReaderTypes = CommonActionTypes<ReaderActionTypes.SET_READER_RS485_READER_TYPES, SelectOption[]>;

type contextReaderSetOSDPAddress = CommonActionTypes<ReaderActionTypes.SET_READER_OSDP_ADDRESS, number>;

type contextReaderSetWLHubAddress = CommonActionTypes<ReaderActionTypes.SET_READER_WLHUB_ADDRESS, number>;

type contextReaderSetEACAddress = CommonActionTypes<ReaderActionTypes.SET_READER_EAC_ADDRESS, number>;

type contextReaderSetWL2HubAddress = CommonActionTypes<ReaderActionTypes.SET_READER_WL2HUB_ADDRESS, number>;

type contextReaderSetAllegionLockAddress = CommonActionTypes<ReaderActionTypes.SET_READER_ALLEGION_LOCK_ADDRESS, number>;

type contextReaderSetOkDegrade = CommonActionTypes<ReaderActionTypes.SET_READER_OK_DEGRADE, boolean>;

type contextReaderSetPassbackZones = CommonActionTypes<ReaderActionTypes.SET_READER_PASSBACK_ZONES, SelectOption[]>;

type contextReaderSetCCOTZAssurance = CommonActionTypes<ReaderActionTypes.SET_READER_CCOTZ_ASSURANCE, boolean>;

type contextReaderSetAssuranceLevels = CommonActionTypes<ReaderActionTypes.SET_READER_ASSURANCE_LEVELS, SelectOption[]>;

type contextReaderSetDefaultAssuranceLevels = CommonActionTypes<ReaderActionTypes.SET_READER_DEFAULT_ASSURANCE_LEVELS, SelectOption[]>;

type contextReaderSetIsOnboardWiegandAvailable = CommonActionTypes<ReaderActionTypes.SET_READER_IS_ONBOARD_WIEGAND_AVAILABLE, boolean>;

type contextReaderSetIsControllerSNIB3 = CommonActionTypes<ReaderActionTypes.SET_READER_IS_CONTROLLER_SNIB3, boolean>;

type contextReaderSetIsControllerMatch = CommonActionTypes<ReaderActionTypes.SET_READER_IS_CONTROLLER_MATCH, boolean>;

type contextReaderSetFirmware = CommonActionTypes<ReaderActionTypes.SET_READER_FIRMWARE, string>;

type contextReaderSetEnableReaderBezelTamper = CommonActionTypes<ReaderActionTypes.SET_READER_ENABLE_READER_BEZEL_TAMPER, boolean>;

type contextReaderSetEnableHexPassthrough = CommonActionTypes<ReaderActionTypes.SET_READER_ENABLE_HEX_PASSTHROUGH, boolean>;

type contextReaderSetEnableMatchBezelTamper = CommonActionTypes<ReaderActionTypes.SET_READER_ENABLE_MATCH_BEZEL_TAMPER, boolean>;

type contextReaderSetFacilityCodeCardNumber = CommonActionTypes<ReaderActionTypes.SET_READER_FACILITY_CODE_CARD_NUMBER, boolean>;

type contextReaderSetCorporate1000 = CommonActionTypes<ReaderActionTypes.SET_READER_CORPORATE1000, boolean>;

type contextReaderSetHexDigitUUID = CommonActionTypes<ReaderActionTypes.SET_READER_HEX_DIGIT_UUID, boolean>;

type contextReaderSetEnableKeyPad = CommonActionTypes<ReaderActionTypes.SET_READER_ENABLE_KEY_PAD, boolean>;

type contextReaderSetEnableOSDPHandshake = CommonActionTypes<ReaderActionTypes.SET_READER_ENABLE_OSDP_HANDSHAKE, boolean>;

type contextReaderSetIsDoorWireless = CommonActionTypes<ReaderActionTypes.SET_READER_IS_DOOR_WIRELESS, boolean>;

type contextReaderSetOSDPTitle = CommonActionTypes<ReaderActionTypes.SET_READER_OSDP_TITLE, string>;

type contextReaderSetOSDPConfirmation = CommonActionTypes<ReaderActionTypes.SET_READER_OSDP_CONFIRMATION, string>;

type contextReaderSetFirmwareConfirm = CommonActionTypes<ReaderActionTypes.SET_READER_FIRMWARE_CONFIRM, string>;

type contextReaderSetFirmwareWarning = CommonActionTypes<ReaderActionTypes.SET_READER_FIRMWARE_WARNING, string>;

type contextReaderSetFirmwareTitle = CommonActionTypes<ReaderActionTypes.SET_READER_FIRMWARE_TITLE, string>;

type contextReaderSetTSFirmwares = CommonActionTypes<ReaderActionTypes.SET_READER_TS_FIRMWARES, SelectOption[]>;

type contextReaderSetCanUpdateFirmware = CommonActionTypes<ReaderActionTypes.SET_READER_CAN_UPDATE_FIRMWARE, boolean>;

type contextReaderSetApplyToAllReaders = CommonActionTypes<ReaderActionTypes.SET_READER_APPLY_TO_ALL_READERS, boolean>;

type contextReaderSetReaderInterface = CommonActionTypes<ReaderActionTypes.SET_READER_INTERFACE, ReaderInterface>;

type contextReaderSetCardMap = CommonActionTypes<ReaderActionTypes.SET_READER_CARD_MAP, number>;

type contextReaderSetReaderThreatLevel = CommonActionTypes<ReaderActionTypes.SET_READER_THREAT_LEVEL, number>;

type contextReaderSetCCOTZThreatLevel = CommonActionTypes<ReaderActionTypes.SET_READER_CCOTZ_THREAT_LEVEL, number>;

type contextReaderSetAntipassback = CommonActionTypes<ReaderActionTypes.SET_READER_ANTIPASSBACK, number>;

type contextReaderSetMatchAlgorithm = CommonActionTypes<ReaderActionTypes.SET_READER_MATCH_ALGORITHM, number>;

type contextReaderSetFascnHandling = CommonActionTypes<ReaderActionTypes.SET_READER_FASCN_HANDLING, number>;

type contextReaderSetAssuranceLevel = CommonActionTypes<ReaderActionTypes.SET_READER_ASSURANCE_LEVEL, number>;

type contextReaderSetDefaultAssuranceLevel = CommonActionTypes<ReaderActionTypes.SET_READER_DEFAULT_ASSURANCE_LEVEL, number>;

type contextReadeSetToZone = CommonActionTypes<ReaderActionTypes.SET_READER_TO_ZONE, number>;

type contextReaderSetFromZone = CommonActionTypes<ReaderActionTypes.SET_READER_FROM_ZONE, number>;

type contextReaderSetSpecialHandlingInstruction = CommonActionTypes<ReaderActionTypes.SET_READER_SPECIAL_HANDLING_INSTRUCTION, number>;

type contextReaderSetCardCodeOnlyTimeZone = CommonActionTypes<ReaderActionTypes.SET_READER_CARD_CODE_ONLY_TIME_ZONE, number>;

type contextReaderSetTSFirmware = CommonActionTypes<ReaderActionTypes.SET_READER_TS_FIRMWARE, number>;

type contextReaderSetRS485ReaderType = CommonActionTypes<ReaderActionTypes.SET_READER_RS485_READER_TYPE, number>;

type contextReaderSetIsFICAMEnabled = CommonActionTypes<ReaderActionTypes.SET_READER_IS_FICAM_ENABLED, boolean>;

type contextReaderSetUnpermittedCardCodeOnlyTimeZone = CommonActionTypes<ReaderActionTypes.SET_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE, SelectTimeZone>;

type contextReaderSetUpdateFirmwarePermissionText = CommonActionTypes<ReaderActionTypes.SET_READER_UPDATE_FIRMWARE_PERMISSION_TEXT, string>;

type contextReaderSetReaderErrorType = CommonActionTypes<ReaderActionTypes.SET_READER_ERROR_TYPE, ReaderError>;

type contextReaderSetReaderErrorMessage = CommonActionTypes<ReaderActionTypes.SET_READER_ERROR_MESSAGE, string>;

type contextReaderSetEntryReaderError = CommonActionTypes<ReaderActionTypes.SET_ENTRY_READER_ERROR, boolean>;

type contextReaderSetOSDPLEDOptions = CommonActionTypes<ReaderActionTypes.SET_OSDP_LED_OPTIONS, OSDPLEDOption[]>;

type contextReaderSetUseCustomOSDPLEDColor = CommonActionTypes<ReaderActionTypes.SET_USE_CUSTOM_OSDP_LED_COLOR, boolean>;

type contextReaderSetChangedOSDLEDColor = CommonActionTypes<ReaderActionTypes.SET_CHANGED_OSDP_LED_COLOR, boolean>;

type contextReaderSetUpdateScrambleFactorImageSelection = CommonActionTypes<ReaderActionTypes.SET_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION, boolean>;

type contextReaderSetScrambleFactorErrorCategory = CommonActionTypes<ReaderActionTypes.SET_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY, string>;

export type contextReaderSetScrambleFactorCategories = CommonActionTypes<ReaderActionTypes.SET_SCRAMBLE_FACTOR_CATEGORIES, ScrambleFactorImageCategories[]>;

export type contextReaderSetSelectedFactorImages = CommonActionTypes<ReaderActionTypes.SET_SELECTED_FACTOR_IMAGES, ScrambleFactorImageShort[]>;

export type contextViewPinEntryIndicator = CommonActionTypes<ReaderActionTypes.SET_VIEW_PIN_ENTRY_INDICATOR, boolean>;

export type ReaderActionDefinition = (payload: ScrambleFactorImageShort[]) => contextReaderSetSelectedFactorImages;

export type SetScrambleFactorCategories = (payload: ScrambleFactorImageCategories[]) => contextReaderSetScrambleFactorCategories;

export type ViewPinEntryIndicator = (payload: boolean) => contextViewPinEntryIndicator;

export type SetReaderErrorType = (payload: ReaderError) => contextReaderSetReaderErrorType;

export type SetReaderErrorMessage = (payload: string) => contextReaderSetReaderErrorMessage;

export type SetReaderErrorImageCategory = (payload: string) => contextReaderSetScrambleFactorErrorCategory;

//#endregion

export type ReaderActionContext =
	| contextReaderSetName
	| contextReaderControllerId
	| contextReaderSetIndex
	| contextReaderSetSupportedReaderClass
	| contextReaderSetLabels
	| contextReaderSetAnyReaderOptions
	| contextReaderSetCardReaderOptions
	| contextReaderSetScramblePadKeyOptions
	| contextReaderSetGenericTimeZones
	| contextReaderSetCardMaps
	| contextReaderSetSpecialHandlingInstructions
	| contextReaderSetReaderThreatLevels
	| contextReaderSetIsReaderWireless
	| contextReaderSetRS485ReaderTypes
	| contextReaderSetOSDPAddress
	| contextReaderSetWLHubAddress
	| contextReaderSetEACAddress
	| contextReaderSetWL2HubAddress
	| contextReaderSetOkDegrade
	| contextReaderSetPassbackZones
	| contextReaderSetCCOTZAssurance
	| contextReaderSetAssuranceLevels
	| contextReaderSetDefaultAssuranceLevels
	| contextReaderSetIsOnboardWiegandAvailable
	| contextReaderSetIsControllerSNIB3
	| contextReaderSetIsControllerMatch
	| contextReaderSetFirmware
	| contextReaderSetEnableReaderBezelTamper
	| contextReaderSetEnableHexPassthrough
	| contextReaderSetEnableMatchBezelTamper
	| contextReaderSetFacilityCodeCardNumber
	| contextReaderSetCorporate1000
	| contextReaderSetHexDigitUUID
	| contextReaderSetEnableKeyPad
	| contextReaderSetEnableOSDPHandshake
	| contextReaderSetIsDoorWireless
	| contextReaderSetOSDPTitle
	| contextReaderSetFirmwareConfirm
	| contextReaderSetFirmwareWarning
	| contextReaderSetFirmwareTitle
	| contextReaderSetTSFirmwares
	| contextReaderSetCanUpdateFirmware
	| contextReaderSetApplyToAllReaders
	| contextReaderSetCardMap
	| contextReaderSetReaderThreatLevel
	| contextReaderSetCCOTZThreatLevel
	| contextReaderSetAntipassback
	| contextReaderSetMatchAlgorithm
	| contextReaderSetFascnHandling
	| contextReaderSetAssuranceLevel
	| contextReaderSetDefaultAssuranceLevel
	| contextReadeSetToZone
	| contextReaderSetFromZone
	| contextReaderSetSpecialHandlingInstruction
	| contextReaderSetCardCodeOnlyTimeZone
	| contextReaderSetTSFirmware
	| contextReaderSetAllegionLockAddress
	| contextReaderSetOSDPConfirmation
	| contextReaderSetRS485ReaderType
	| contextReaderSetReaderInterface
	| contextReaderSetIsFICAMEnabled
	| contextReaderInitReader
	| contextReaderSetUpdateFirmwarePermissionText
	| contextReaderSetReaderErrorType
	| contextReaderSetReaderErrorMessage
	| contextReaderSetAssuranceInfo
	| contextReaderSetUnpermittedCardCodeOnlyTimeZone
	| contextReaderSetOSDPLEDOptions
	| contextReaderSetUseCustomOSDPLEDColor
	| contextReaderSetChangedOSDLEDColor
	| contextReaderSetEntryReaderError
	| contextReaderSetUpdateScrambleFactorImageSelection
	| contextReaderSetScrambleFactorCategories
	| contextReaderSetSelectedFactorImages
	| contextReaderSetScrambleFactorErrorCategory
	| contextViewPinEntryIndicator;

const readerState: ReaderContextState = {
	controllerId: 0,
	name: '',
	index: 0,
	supportedReaderClass: SupportedReaderClass.Default,
	labels: [],
	anyReaderOptions: [],
	cardReaderOptions: [],
	scramblePadKeyPadOptions: [],
	genericTimeZones: [],
	cardMaps: [],
	specialHandlingInstructions: [],
	readerThreatLevels: [],
	isReaderWireless: false,
	rS485ReaderTypes: [],
	oSDPAddress: 0,
	wLHubAddress: 0,
	eACAddress: 0,
	wL2HubAddress: 0,
	allegionLockAddress: 0,
	okDegrade: false,
	passbackZones: [],
	cCOTZAssurance: false,
	assuranceLevels: [],
	defaultAssuranceLevels: [],
	isOnboardWiegandAvailable: false,
	isControllerSNIB3: false,
	isControllerMATCH: false,
	firmware: '',
	appVersion: '',
	displayBoardVersion: '',
	enableReaderBezelTamper: false,
	enableHexPassThrough: false,
	enableMatchBezelTamper: false,
	facilityCodeCardNumber: false,
	corporate1000: false,
	hexDigitUUID: false,
	enableKeyPad: false,
	enableOSDPHandshake: false,
	isDoorWireless: false,
	oSDPTitle: '',
	oSDPConfirmation: '',
	firmwareConfirm: '',
	firmwareWarning: '',
	firmwareTitle: '',
	tSFirmwares: [],
	canUpdateFirmware: true,
	applyToAllReaders: false,
	readerInterface: ReaderInterface.MatchDS47,
	isFICAMEnabled: false,
	antiPassback: 0,
	matchAlgorithm: 0,
	fascnHandling: 0,
	updateFirmwarePermissionText: '',
	isEntryReaderError: false,
	readerErrorMessage: '',
	readerErrorType: undefined,
	selections: {
		cardMap: 0,
		readerThreatLevel: 0,
		cCOTZThreatLevel: 0,
		assuranceLevel: 0,
		defaultAssuranceLevel: 0,
		toZone: 0,
		fromZone: 0,
		specialHandlingInstruction: 0,
		cardCodeOnlyTimeZone: 1,
		tSFirmware: 0,
		rS485ReaderType: 0,
	},
	unpermitted: {
		cardCodeOnlyTimeZone: null,
	},
	osdpLEDOptions: [],
	useCustomOSDPLEDColor: false,
	changedOSDPLEDColor: false,
	osdpCustomLEDColors: [],
	readerTypeChanged: false,
	scrambleFactorCategories: [],
	selectedFactorImages: [],
	viewPINEntryIndicator: false,
	updateScrambleFactorSelection: false,
	scrambleFactorErrorImageCategory: '',
};

const readerContext = (state: Readonly<ReaderContextState>, action: ReaderActionContext): ReaderContextState => {
	switch (action.type) {
		case ReaderActionTypes.INIT_READER:
			return {
				...state,
				id: action.payload.Id,
				controllerId: action.payload.ControllerId,
				name: action.payload.Name,
				index: action.payload.Index,
				supportedReaderClass: action.payload.SupportedReaderClass,
				labels: action.payload.Labels,
				anyReaderOptions: action.payload.AnyReaderOptions,
				cardReaderOptions: action.payload.CardReaderOptions,
				scramblePadKeyPadOptions: action.payload.ScramblePadKeyPadOptions,
				genericTimeZones: action.payload.GenericTimeZones,
				cardMaps: action.payload.CardMaps,
				specialHandlingInstructions: action.payload.SpecialHandlingInstructions,
				readerThreatLevels: action.payload.ReaderThreatLevels,
				isReaderWireless: action.payload.IsReaderWireless,
				rS485ReaderTypes: action.payload.RS485ReaderTypes,
				oSDPAddress: action.payload.OSDPAddress,
				wLHubAddress: action.payload.WLHubAddress,
				eACAddress: action.payload.EACAddress,
				wL2HubAddress: action.payload.WL2HubAddress,
				allegionLockAddress: action.payload.AllegionLockAddress,
				okDegrade: action.payload.OkDegrade,
				passbackZones: action.payload.PassbackZones,
				cCOTZAssurance: action.payload.CCOTZAssurance,
				isOnboardWiegandAvailable: action.payload.IsOnboardWiegandAvailable,
				isControllerSNIB3: action.payload.IsControllerSNIB3,
				isControllerMATCH: action.payload.IsControllerMATCH,
				firmware: action.payload.Firmware,
				appVersion: action.payload.AppVersion,
				displayBoardVersion: action.payload.DisplayBoardVersion,
				isFICAMEnabled: action.payload.IsFICAMEnabled,
				enableReaderBezelTamper: action.payload.EnableReaderBezelTamper,
				enableHexPassThrough: action.payload.EnableHexPassThrough,
				enableMatchBezelTamper: action.payload.EnableMatchBezelTamper,
				facilityCodeCardNumber: action.payload.FacilityCodeCardNumber,
				corporate1000: action.payload.Corporate1000,
				hexDigitUUID: action.payload.HexDigitUUID,
				enableKeyPad: action.payload.EnableKeyPad,
				enableOSDPHandshake: action.payload.EnableOSDPHandshake,
				isDoorWireless: action.payload.IsDoorWireless,
				oSDPTitle: action.payload.OSDPTitle,
				oSDPConfirmation: action.payload.OSDPConfirmation,
				firmwareConfirm: action.payload.FirmwareConfirm,
				firmwareWarning: action.payload.FirmwareWarning,
				firmwareTitle: action.payload.FirmwareTitle,
				tSFirmwares: action.payload.TSFirmwares,
				canUpdateFirmware: action.payload.CanUpdateFirmware,
				applyToAllReaders: action.payload.ApplyToAllReaders,
				readerInterface: action.payload.ReaderInterface,
				antiPassback: action.payload.AntiPassback,
				matchAlgorithm: action.payload.MatchAlgorithm,
				fascnHandling: action.payload.FascnHandling,
				updateFirmwarePermissionText: action.payload.UpdateFirmwarePermissionText,
				osdpCustomLEDColors: action.payload.OSDPCustomLEDColors,
				useCustomOSDPLEDColor: action.payload.UseCustomOSDPLEDColor,
				selections: {
					...state.selections,
					cardCodeOnlyTimeZone: action.payload.CardCodeOnlyTimeZone.GenericId,
					cardMap: action.payload.CardMap,
					readerThreatLevel: action.payload.DisabledReaderAboveLevelId,
					cCOTZThreatLevel: action.payload.DisabledCCOTZAboveLevelId,
					assuranceLevel: action.payload.AssuranceLevel,
					defaultAssuranceLevel: action.payload.DefaultAssuranceLevel,
					toZone: action.payload.ToZone,
					fromZone: action.payload.FromZone,
					specialHandlingInstruction: action.payload.SpecialHandlingInstruction,
					rS485ReaderType: action.payload.SupportedReaderClass === 0 ? action.payload.RS485ReaderType : undefined,
				},
				selectedFactorImages: action.payload.TSScrambleImages,
				viewPINEntryIndicator: action.payload.ViewPINEntryIndicator,
			};

		case ReaderActionTypes.SET_READER_ASSURANCE_INFO:
			const defaultAssuranceLevelSelection: number = getDefaultAssuranceLevelSelection(
				action.payload.DefaultAssuranceLevels,
				state.selections.defaultAssuranceLevel,
				action.payload.DefaultAssurance,
				state.readerTypeChanged
			);

			return {
				...state,
				assuranceLevels: action.payload.AssuranceLevels,
				defaultAssuranceLevels: action.payload.DefaultAssuranceLevels,
				canUpdateFirmware: action.payload.CanUpdateFirmware,
				selections: { ...state.selections, defaultAssuranceLevel: defaultAssuranceLevelSelection },
			};

		case ReaderActionTypes.SET_READER_CONTROLLER_ID:
			return { ...state, controllerId: action.payload };

		case ReaderActionTypes.SET_READER_NAME:
			return { ...state, name: action.payload };

		case ReaderActionTypes.SET_READER_INDEX:
			return { ...state, index: action.payload };

		case ReaderActionTypes.SET_READER_SUPPORTED_READER_CLASS:
			return { ...state, supportedReaderClass: action.payload };

		case ReaderActionTypes.SET_READER_LABELS:
			return { ...state, labels: action.payload };

		case ReaderActionTypes.SET_READER_ANY_READER_OPTIONS:
			return { ...state, anyReaderOptions: action.payload };

		case ReaderActionTypes.SET_READER_CARD_READER_OPTIONS:
			return { ...state, cardReaderOptions: action.payload };

		case ReaderActionTypes.SET_READER_SCRAMBLE_PAD_KEY_OPTIONS:
			return { ...state, scramblePadKeyPadOptions: action.payload };

		case ReaderActionTypes.SET_READER_GENERIC_TIME_ZONES:
			return { ...state, genericTimeZones: action.payload };

		case ReaderActionTypes.SET_READER_CARD_MAPS:
			return { ...state, cardMaps: action.payload };

		case ReaderActionTypes.SET_READER_SPECIAL_HANDLING_INSTRUCTIONS:
			return { ...state, specialHandlingInstructions: action.payload };

		case ReaderActionTypes.SET_READER_THREAT_LEVELS:
			return { ...state, readerThreatLevels: action.payload };

		case ReaderActionTypes.SET_READER_IS_READER_WIRELESS:
			return { ...state, isReaderWireless: action.payload };

		case ReaderActionTypes.SET_READER_RS485_READER_TYPES:
			return { ...state, rS485ReaderTypes: action.payload };

		case ReaderActionTypes.SET_READER_OSDP_ADDRESS:
			return { ...state, oSDPAddress: action.payload };

		case ReaderActionTypes.SET_READER_WLHUB_ADDRESS:
			return { ...state, wLHubAddress: action.payload };

		case ReaderActionTypes.SET_READER_EAC_ADDRESS:
			return { ...state, eACAddress: action.payload };

		case ReaderActionTypes.SET_READER_WL2HUB_ADDRESS:
			return { ...state, wL2HubAddress: action.payload };

		case ReaderActionTypes.SET_READER_ALLEGION_LOCK_ADDRESS:
			return { ...state, allegionLockAddress: action.payload };

		case ReaderActionTypes.SET_READER_OK_DEGRADE:
			return { ...state, okDegrade: action.payload };

		case ReaderActionTypes.SET_READER_PASSBACK_ZONES:
			return { ...state, passbackZones: action.payload };

		case ReaderActionTypes.SET_READER_CCOTZ_ASSURANCE:
			return { ...state, cCOTZAssurance: action.payload };

		case ReaderActionTypes.SET_READER_ASSURANCE_LEVELS:
			return { ...state, assuranceLevels: action.payload };

		case ReaderActionTypes.SET_READER_DEFAULT_ASSURANCE_LEVELS:
			return { ...state, defaultAssuranceLevels: action.payload };

		case ReaderActionTypes.SET_READER_IS_ONBOARD_WIEGAND_AVAILABLE:
			return { ...state, isOnboardWiegandAvailable: action.payload };

		case ReaderActionTypes.SET_READER_IS_CONTROLLER_SNIB3:
			return { ...state, isControllerSNIB3: action.payload };

		case ReaderActionTypes.SET_READER_IS_CONTROLLER_MATCH:
			return { ...state, isControllerMATCH: action.payload };

		case ReaderActionTypes.SET_READER_FIRMWARE:
			return { ...state, firmware: action.payload };

		case ReaderActionTypes.SET_READER_IS_FICAM_ENABLED:
			return { ...state, isFICAMEnabled: action.payload };

		case ReaderActionTypes.SET_READER_ENABLE_READER_BEZEL_TAMPER:
			return { ...state, enableReaderBezelTamper: action.payload };

		case ReaderActionTypes.SET_READER_ENABLE_HEX_PASSTHROUGH:
			return { ...state, enableHexPassThrough: action.payload };

		case ReaderActionTypes.SET_READER_ENABLE_MATCH_BEZEL_TAMPER:
			return { ...state, enableMatchBezelTamper: action.payload };

		case ReaderActionTypes.SET_READER_FACILITY_CODE_CARD_NUMBER:
			return { ...state, facilityCodeCardNumber: action.payload };

		case ReaderActionTypes.SET_READER_CORPORATE1000:
			return { ...state, corporate1000: action.payload };

		case ReaderActionTypes.SET_READER_HEX_DIGIT_UUID:
			return { ...state, hexDigitUUID: action.payload };

		case ReaderActionTypes.SET_READER_ENABLE_KEY_PAD:
			return { ...state, enableKeyPad: action.payload };

		case ReaderActionTypes.SET_READER_ENABLE_OSDP_HANDSHAKE:
			return { ...state, enableOSDPHandshake: action.payload };

		case ReaderActionTypes.SET_READER_IS_DOOR_WIRELESS:
			return { ...state, isDoorWireless: action.payload };

		case ReaderActionTypes.SET_READER_OSDP_TITLE:
			return { ...state, oSDPTitle: action.payload };

		case ReaderActionTypes.SET_READER_OSDP_CONFIRMATION:
			return { ...state, oSDPConfirmation: action.payload };

		case ReaderActionTypes.SET_READER_FIRMWARE_CONFIRM:
			return { ...state, firmwareConfirm: action.payload };

		case ReaderActionTypes.SET_READER_FIRMWARE_WARNING:
			return { ...state, firmwareWarning: action.payload };

		case ReaderActionTypes.SET_READER_FIRMWARE_TITLE:
			return { ...state, firmwareTitle: action.payload };

		case ReaderActionTypes.SET_READER_TS_FIRMWARES:
			return { ...state, tSFirmwares: action.payload };

		case ReaderActionTypes.SET_READER_CAN_UPDATE_FIRMWARE:
			return { ...state, canUpdateFirmware: action.payload };

		case ReaderActionTypes.SET_READER_APPLY_TO_ALL_READERS:
			return { ...state, applyToAllReaders: action.payload };

		case ReaderActionTypes.SET_READER_INTERFACE:
			return { ...state, readerInterface: action.payload };

		case ReaderActionTypes.SET_READER_CARD_MAP:
			return { ...state, selections: { ...state.selections, cardMap: action.payload } };

		case ReaderActionTypes.SET_READER_THREAT_LEVEL:
			return { ...state, selections: { ...state.selections, readerThreatLevel: action.payload } };

		case ReaderActionTypes.SET_READER_CCOTZ_THREAT_LEVEL:
			return { ...state, selections: { ...state.selections, cCOTZThreatLevel: action.payload } };

		case ReaderActionTypes.SET_READER_ANTIPASSBACK:
			return { ...state, antiPassback: action.payload };

		case ReaderActionTypes.SET_READER_MATCH_ALGORITHM:
			return { ...state, matchAlgorithm: action.payload };

		case ReaderActionTypes.SET_READER_FASCN_HANDLING:
			return { ...state, fascnHandling: action.payload };

		case ReaderActionTypes.SET_READER_UPDATE_FIRMWARE_PERMISSION_TEXT:
			return { ...state, updateFirmwarePermissionText: action.payload };

		case ReaderActionTypes.SET_READER_ERROR_TYPE:
			return { ...state, readerErrorType: action.payload };

		case ReaderActionTypes.SET_READER_ERROR_MESSAGE:
			return { ...state, readerErrorMessage: action.payload };

		case ReaderActionTypes.SET_READER_ASSURANCE_LEVEL:
			return { ...state, selections: { ...state.selections, assuranceLevel: action.payload } };

		case ReaderActionTypes.SET_READER_DEFAULT_ASSURANCE_LEVEL:
			return { ...state, selections: { ...state.selections, defaultAssuranceLevel: action.payload } };

		case ReaderActionTypes.SET_READER_TO_ZONE:
			return { ...state, selections: { ...state.selections, toZone: action.payload } };

		case ReaderActionTypes.SET_READER_FROM_ZONE:
			return { ...state, selections: { ...state.selections, fromZone: action.payload } };

		case ReaderActionTypes.SET_READER_SPECIAL_HANDLING_INSTRUCTION:
			return { ...state, selections: { ...state.selections, specialHandlingInstruction: action.payload } };

		case ReaderActionTypes.SET_READER_CARD_CODE_ONLY_TIME_ZONE:
			return { ...state, selections: { ...state.selections, cardCodeOnlyTimeZone: action.payload } };

		case ReaderActionTypes.SET_READER_TS_FIRMWARE:
			return { ...state, selections: { ...state.selections, tSFirmware: action.payload } };

		case ReaderActionTypes.SET_READER_RS485_READER_TYPE:
			return { ...state, readerTypeChanged: true, selections: { ...state.selections, rS485ReaderType: action.payload } };

		case ReaderActionTypes.SET_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE:
			return { ...state, unpermitted: { ...state.unpermitted, cardCodeOnlyTimeZone: action.payload } };

		case ReaderActionTypes.SET_ENTRY_READER_ERROR:
			return { ...state, isEntryReaderError: action.payload };

		case ReaderActionTypes.SET_OSDP_LED_OPTIONS:
			return { ...state, osdpLEDOptions: action.payload };

		case ReaderActionTypes.SET_USE_CUSTOM_OSDP_LED_COLOR:
			return { ...state, useCustomOSDPLEDColor: action.payload };

		case ReaderActionTypes.SET_CHANGED_OSDP_LED_COLOR:
			return { ...state, changedOSDPLEDColor: action.payload };

		case ReaderActionTypes.SET_SCRAMBLE_FACTOR_CATEGORIES:
			return { ...state, scrambleFactorCategories: action.payload };

		case ReaderActionTypes.SET_SELECTED_FACTOR_IMAGES:
			return { ...state, selectedFactorImages: action.payload };

		case ReaderActionTypes.SET_VIEW_PIN_ENTRY_INDICATOR:
			return { ...state, viewPINEntryIndicator: action.payload };

		case ReaderActionTypes.SET_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION:
			return { ...state, updateScrambleFactorSelection: action.payload };

		case ReaderActionTypes.SET_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY:
			return { ...state, scrambleFactorErrorImageCategory: action.payload };

		default:
			return { ...state };
	}
};

//#region functionContextActions
const initReaderAction = (payload: Partial<Reader>): contextReaderInitReader => {
	return {
		type: ReaderActionTypes.INIT_READER,
		payload,
	};
};

const setAssuranceInfo = (payload: Partial<AssuranceLevelList>): contextReaderSetAssuranceInfo => {
	return {
		type: ReaderActionTypes.SET_READER_ASSURANCE_INFO,
		payload,
	};
};

const setName = (payload: string): contextReaderSetName => {
	return {
		type: ReaderActionTypes.SET_READER_NAME,
		payload,
	};
};

const setControllerId = (payload: number): contextReaderControllerId => {
	return {
		type: ReaderActionTypes.SET_READER_CONTROLLER_ID,
		payload,
	};
};

const setIndex = (payload: number): contextReaderSetIndex => {
	return {
		type: ReaderActionTypes.SET_READER_INDEX,
		payload,
	};
};

const setSupportedReaderClass = (payload: SupportedReaderClass): contextReaderSetSupportedReaderClass => {
	return {
		type: ReaderActionTypes.SET_READER_SUPPORTED_READER_CLASS,
		payload,
	};
};

const setLabels = (payload: ReaderLabel[]): contextReaderSetLabels => {
	return {
		type: ReaderActionTypes.SET_READER_LABELS,
		payload,
	};
};

const setAnyReaderOptions = (payload: ReaderOptionsTab[]): contextReaderSetAnyReaderOptions => {
	return {
		type: ReaderActionTypes.SET_READER_ANY_READER_OPTIONS,
		payload,
	};
};

const setCardReaderOptions = (payload: ReaderOptionsTab[]): contextReaderSetCardReaderOptions => {
	return {
		type: ReaderActionTypes.SET_READER_CARD_READER_OPTIONS,
		payload,
	};
};

const setScramblePadKeyOptions = (payload: ReaderOptionsTab[]): contextReaderSetScramblePadKeyOptions => {
	return {
		type: ReaderActionTypes.SET_READER_SCRAMBLE_PAD_KEY_OPTIONS,
		payload,
	};
};

const setGenericTimeZones = (payload: SelectTimeZone[]): contextReaderSetGenericTimeZones => {
	return {
		type: ReaderActionTypes.SET_READER_GENERIC_TIME_ZONES,
		payload,
	};
};

const setCardMaps = (payload: SelectOption[]): contextReaderSetCardMaps => {
	return {
		type: ReaderActionTypes.SET_READER_CARD_MAPS,
		payload,
	};
};

const setIsFICAMEnabled = (payload: boolean): contextReaderSetIsFICAMEnabled => {
	return {
		type: ReaderActionTypes.SET_READER_IS_FICAM_ENABLED,
		payload,
	};
};

const setSpecialHandlingInstructions = (payload: SelectOption[]): contextReaderSetSpecialHandlingInstructions => {
	return {
		type: ReaderActionTypes.SET_READER_SPECIAL_HANDLING_INSTRUCTIONS,
		payload,
	};
};

const setThreatLevels = (payload: SelectOption[]): contextReaderSetReaderThreatLevels => {
	return {
		type: ReaderActionTypes.SET_READER_THREAT_LEVELS,
		payload,
	};
};

const setIsReaderWireless = (payload: boolean): contextReaderSetIsReaderWireless => {
	return {
		type: ReaderActionTypes.SET_READER_IS_READER_WIRELESS,
		payload,
	};
};

const setRS485ReaderTypes = (payload: SelectOption[]): contextReaderSetRS485ReaderTypes => {
	return {
		type: ReaderActionTypes.SET_READER_RS485_READER_TYPES,
		payload,
	};
};

const setOSDPAddress = (payload: number): contextReaderSetOSDPAddress => {
	return {
		type: ReaderActionTypes.SET_READER_OSDP_ADDRESS,
		payload,
	};
};

const setWLHubAddress = (payload: number): contextReaderSetWLHubAddress => {
	return {
		type: ReaderActionTypes.SET_READER_WLHUB_ADDRESS,
		payload,
	};
};

const setEACAddress = (payload: number): contextReaderSetEACAddress => {
	return {
		type: ReaderActionTypes.SET_READER_EAC_ADDRESS,
		payload,
	};
};

const setWL2HubAddress = (payload: number): contextReaderSetWL2HubAddress => {
	return {
		type: ReaderActionTypes.SET_READER_WL2HUB_ADDRESS,
		payload,
	};
};

const setAllegionLockAddress = (payload: number): contextReaderSetAllegionLockAddress => {
	return {
		type: ReaderActionTypes.SET_READER_ALLEGION_LOCK_ADDRESS,
		payload,
	};
};

const setOkDegrade = (payload: boolean): contextReaderSetOkDegrade => {
	return {
		type: ReaderActionTypes.SET_READER_OK_DEGRADE,
		payload,
	};
};

const setPassbackZones = (payload: SelectOption[]): contextReaderSetPassbackZones => {
	return {
		type: ReaderActionTypes.SET_READER_PASSBACK_ZONES,
		payload,
	};
};

const setCCOTZAssurance = (payload: boolean): contextReaderSetCCOTZAssurance => {
	return {
		type: ReaderActionTypes.SET_READER_CCOTZ_ASSURANCE,
		payload,
	};
};

const setAssuranceLevels = (payload: SelectOption[]): contextReaderSetAssuranceLevels => {
	return {
		type: ReaderActionTypes.SET_READER_ASSURANCE_LEVELS,
		payload,
	};
};

const setDefaultAssuranceLevels = (payload: SelectOption[]): contextReaderSetDefaultAssuranceLevels => {
	return {
		type: ReaderActionTypes.SET_READER_DEFAULT_ASSURANCE_LEVELS,
		payload,
	};
};

const setIsOnboardWiegandAvailable = (payload: boolean): contextReaderSetIsOnboardWiegandAvailable => {
	return {
		type: ReaderActionTypes.SET_READER_IS_ONBOARD_WIEGAND_AVAILABLE,
		payload,
	};
};

const setIsControllerSNIB3 = (payload: boolean): contextReaderSetIsControllerSNIB3 => {
	return {
		type: ReaderActionTypes.SET_READER_IS_CONTROLLER_SNIB3,
		payload,
	};
};

const setIsControllerMatch = (payload: boolean): contextReaderSetIsControllerMatch => {
	return {
		type: ReaderActionTypes.SET_READER_IS_CONTROLLER_MATCH,
		payload,
	};
};

const setFirmware = (payload: string): contextReaderSetFirmware => {
	return {
		type: ReaderActionTypes.SET_READER_FIRMWARE,
		payload,
	};
};

const setEnableReaderBezelTamper = (payload: boolean): contextReaderSetEnableReaderBezelTamper => {
	return {
		type: ReaderActionTypes.SET_READER_ENABLE_READER_BEZEL_TAMPER,
		payload,
	};
};

const setEnableHexPassthrough = (payload: boolean): contextReaderSetEnableHexPassthrough => {
	return {
		type: ReaderActionTypes.SET_READER_ENABLE_HEX_PASSTHROUGH,
		payload,
	};
};

const setEnableMatchBezelTamper = (payload: boolean): contextReaderSetEnableMatchBezelTamper => {
	return {
		type: ReaderActionTypes.SET_READER_ENABLE_MATCH_BEZEL_TAMPER,
		payload,
	};
};

const setFacilityCodeCardNumber = (payload: boolean): contextReaderSetFacilityCodeCardNumber => {
	return {
		type: ReaderActionTypes.SET_READER_FACILITY_CODE_CARD_NUMBER,
		payload,
	};
};

const setCorporate1000 = (payload: boolean): contextReaderSetCorporate1000 => {
	return {
		type: ReaderActionTypes.SET_READER_CORPORATE1000,
		payload,
	};
};

const setHexDigitUUID = (payload: boolean): contextReaderSetHexDigitUUID => {
	return {
		type: ReaderActionTypes.SET_READER_HEX_DIGIT_UUID,
		payload,
	};
};

const setEnableKeyPad = (payload: boolean): contextReaderSetEnableKeyPad => {
	return {
		type: ReaderActionTypes.SET_READER_ENABLE_KEY_PAD,
		payload,
	};
};

const setEnableOSDPHandshake = (payload: boolean): contextReaderSetEnableOSDPHandshake => {
	return {
		type: ReaderActionTypes.SET_READER_ENABLE_OSDP_HANDSHAKE,
		payload,
	};
};

const setIsDoorWireless = (payload: boolean): contextReaderSetIsDoorWireless => {
	return {
		type: ReaderActionTypes.SET_READER_IS_DOOR_WIRELESS,
		payload,
	};
};

const setOSDPTitle = (payload: string): contextReaderSetOSDPTitle => {
	return {
		type: ReaderActionTypes.SET_READER_OSDP_TITLE,
		payload,
	};
};

const setOSDPConfirmation = (payload: string): contextReaderSetOSDPConfirmation => {
	return {
		type: ReaderActionTypes.SET_READER_OSDP_CONFIRMATION,
		payload,
	};
};

const setFirmwareConfirm = (payload: string): contextReaderSetFirmwareConfirm => {
	return {
		type: ReaderActionTypes.SET_READER_FIRMWARE_CONFIRM,
		payload,
	};
};

const setFirmwareWarning = (payload: string): contextReaderSetFirmwareWarning => {
	return {
		type: ReaderActionTypes.SET_READER_FIRMWARE_WARNING,
		payload,
	};
};

const setFirmwareTitle = (payload: string): contextReaderSetFirmwareTitle => {
	return {
		type: ReaderActionTypes.SET_READER_FIRMWARE_TITLE,
		payload,
	};
};

const setTSFirmwares = (payload: SelectOption[]): contextReaderSetTSFirmwares => {
	return {
		type: ReaderActionTypes.SET_READER_TS_FIRMWARES,
		payload,
	};
};

const setCanUpdateFirmware = (payload: boolean): contextReaderSetCanUpdateFirmware => {
	return {
		type: ReaderActionTypes.SET_READER_CAN_UPDATE_FIRMWARE,
		payload,
	};
};

const setApplyToAllReaders = (payload: boolean): contextReaderSetApplyToAllReaders => {
	return {
		type: ReaderActionTypes.SET_READER_APPLY_TO_ALL_READERS,
		payload,
	};
};

const setReaderInterface = (payload: ReaderInterface): contextReaderSetReaderInterface => {
	return {
		type: ReaderActionTypes.SET_READER_INTERFACE,
		payload,
	};
};

const setCardMap = (payload: number): contextReaderSetCardMap => {
	return {
		type: ReaderActionTypes.SET_READER_CARD_MAP,
		payload,
	};
};

const setReaderThreatLevel = (payload: number): contextReaderSetReaderThreatLevel => {
	return {
		type: ReaderActionTypes.SET_READER_THREAT_LEVEL,
		payload,
	};
};

const setCCOTZThreatLevel = (payload: number): contextReaderSetCCOTZThreatLevel => {
	return {
		type: ReaderActionTypes.SET_READER_CCOTZ_THREAT_LEVEL,
		payload,
	};
};

const setAntiPassback = (payload: number): contextReaderSetAntipassback => {
	return {
		type: ReaderActionTypes.SET_READER_ANTIPASSBACK,
		payload,
	};
};

const setMatchAlgorithm = (payload: number): contextReaderSetMatchAlgorithm => {
	return {
		type: ReaderActionTypes.SET_READER_MATCH_ALGORITHM,
		payload,
	};
};

const setFascnHandling = (payload: number): contextReaderSetFascnHandling => {
	return {
		type: ReaderActionTypes.SET_READER_FASCN_HANDLING,
		payload,
	};
};

const setAssuranceLevel = (payload: number): contextReaderSetAssuranceLevel => {
	return {
		type: ReaderActionTypes.SET_READER_ASSURANCE_LEVEL,
		payload,
	};
};

const setDefaultAssuranceLevel = (payload: number): contextReaderSetDefaultAssuranceLevel => {
	return {
		type: ReaderActionTypes.SET_READER_DEFAULT_ASSURANCE_LEVEL,
		payload,
	};
};

const setToZone = (payload: number): contextReadeSetToZone => {
	return {
		type: ReaderActionTypes.SET_READER_TO_ZONE,
		payload,
	};
};

const setFromZone = (payload: number): contextReaderSetFromZone => {
	return {
		type: ReaderActionTypes.SET_READER_FROM_ZONE,
		payload,
	};
};

const setSpecialHandlingInstruction = (payload: number): contextReaderSetSpecialHandlingInstruction => {
	return {
		type: ReaderActionTypes.SET_READER_SPECIAL_HANDLING_INSTRUCTION,
		payload,
	};
};

const setCardCodeOnlyTimeZone = (payload: number): contextReaderSetCardCodeOnlyTimeZone => {
	return {
		type: ReaderActionTypes.SET_READER_CARD_CODE_ONLY_TIME_ZONE,
		payload,
	};
};

const setTSFirmware = (payload: number): contextReaderSetTSFirmware => {
	return {
		type: ReaderActionTypes.SET_READER_TS_FIRMWARE,
		payload,
	};
};

const setRS485ReaderType = (payload: number): contextReaderSetRS485ReaderType => {
	return {
		type: ReaderActionTypes.SET_READER_RS485_READER_TYPE,
		payload,
	};
};

const setUnpermittedCardCodeOnly = (payload: SelectTimeZone): contextReaderSetUnpermittedCardCodeOnlyTimeZone => {
	return {
		type: ReaderActionTypes.SET_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE,
		payload,
	};
};

const setUpdateFirmwarePermissionText = (payload: string): contextReaderSetUpdateFirmwarePermissionText => {
	return {
		type: ReaderActionTypes.SET_READER_UPDATE_FIRMWARE_PERMISSION_TEXT,
		payload,
	};
};

const setReaderErrorType = (payload: ReaderError): contextReaderSetReaderErrorType => {
	return {
		type: ReaderActionTypes.SET_READER_ERROR_TYPE,
		payload,
	};
};

const setReaderErrorMessage = (payload: string): contextReaderSetReaderErrorMessage => {
	return {
		type: ReaderActionTypes.SET_READER_ERROR_MESSAGE,
		payload,
	};
};

const setEntryReaderError = (payload: boolean): contextReaderSetEntryReaderError => {
	return {
		type: ReaderActionTypes.SET_ENTRY_READER_ERROR,
		payload,
	};
};

const setOSDPLEDOptions = (payload: OSDPLEDOption[]): contextReaderSetOSDPLEDOptions => {
	return {
		type: ReaderActionTypes.SET_OSDP_LED_OPTIONS,
		payload,
	};
};

const setUseCustomOSDPLEDColor = (payload: boolean): contextReaderSetUseCustomOSDPLEDColor => {
	return {
		type: ReaderActionTypes.SET_USE_CUSTOM_OSDP_LED_COLOR,
		payload,
	};
};

const setChangedOSDPLEDColor = (payload: boolean): contextReaderSetChangedOSDLEDColor => {
	return {
		type: ReaderActionTypes.SET_CHANGED_OSDP_LED_COLOR,
		payload,
	};
};

const setScrambleFactorCategories = (payload: ScrambleFactorImageCategories[]): contextReaderSetScrambleFactorCategories => {
	return {
		type: ReaderActionTypes.SET_SCRAMBLE_FACTOR_CATEGORIES,
		payload,
	};
};

const setSelectedFactorImages = (payload: ScrambleFactorImageShort[]): contextReaderSetSelectedFactorImages => {
	return {
		payload,
		type: ReaderActionTypes.SET_SELECTED_FACTOR_IMAGES,
	};
};

const setViewPinEntryIndicator = (payload: boolean): contextViewPinEntryIndicator => {
	return {
		payload,
		type: ReaderActionTypes.SET_VIEW_PIN_ENTRY_INDICATOR,
	};
};

const setUpdateScrambleFactorImageSelection = (payload: boolean): contextReaderSetUpdateScrambleFactorImageSelection => {
	return {
		payload,
		type: ReaderActionTypes.SET_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION,
	};
};

const setScrambleFactorErrorImageCategory = (payload: string): contextReaderSetScrambleFactorErrorCategory => {
	return {
		payload,
		type: ReaderActionTypes.SET_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY,
	};
};

//#endregion

export type ReaderContext = {
	readerState: Readonly<ReaderContextState>;
	dispatcher: React.Dispatch<ReaderActionContext>;
};

const StoreContext = React.createContext<ReaderContext>({ readerState, dispatcher: null });

export {
	StoreContext,
	initReaderAction,
	readerContext,
	readerState,
	setAllegionLockAddress,
	setAntiPassback,
	setAnyReaderOptions,
	setApplyToAllReaders,
	setAssuranceInfo,
	setAssuranceLevel,
	setAssuranceLevels,
	setCCOTZAssurance,
	setCCOTZThreatLevel,
	setCanUpdateFirmware,
	setCardCodeOnlyTimeZone,
	setCardMap,
	setCardMaps,
	setCardReaderOptions,
	setChangedOSDPLEDColor,
	setControllerId,
	setCorporate1000,
	setDefaultAssuranceLevel,
	setDefaultAssuranceLevels,
	setEACAddress,
	setEnableHexPassthrough,
	setEnableKeyPad,
	setEnableMatchBezelTamper,
	setEnableOSDPHandshake,
	setEnableReaderBezelTamper,
	setEntryReaderError,
	setFacilityCodeCardNumber,
	setFascnHandling,
	setFirmware,
	setFirmwareConfirm,
	setFirmwareTitle,
	setFirmwareWarning,
	setFromZone,
	setGenericTimeZones,
	setHexDigitUUID,
	setIndex,
	setIsControllerMatch,
	setIsControllerSNIB3,
	setIsDoorWireless,
	setIsFICAMEnabled,
	setIsOnboardWiegandAvailable,
	setIsReaderWireless,
	setLabels,
	setMatchAlgorithm,
	setName,
	setOSDPAddress,
	setOSDPConfirmation,
	setOSDPLEDOptions,
	setOSDPTitle,
	setOkDegrade,
	setPassbackZones,
	setRS485ReaderType,
	setRS485ReaderTypes,
	setReaderErrorMessage,
	setReaderErrorType,
	setReaderInterface,
	setReaderThreatLevel,
	setScrambleFactorCategories,
	setScrambleFactorErrorImageCategory,
	setScramblePadKeyOptions,
	setSelectedFactorImages,
	setSpecialHandlingInstruction,
	setSpecialHandlingInstructions,
	setSupportedReaderClass,
	setTSFirmware,
	setTSFirmwares,
	setThreatLevels,
	setToZone,
	setUnpermittedCardCodeOnly,
	setUpdateFirmwarePermissionText,
	setUpdateScrambleFactorImageSelection,
	setUseCustomOSDPLEDColor,
	setViewPinEntryIndicator,
	setWL2HubAddress,
	setWLHubAddress,
};

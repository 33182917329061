// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deleting-loading-modal-deletingLoadingModal--17reaZrQF0gxqqYCuYOrXS{width:420px}.deleting-loading-modal-deletingLoadingModal--17reaZrQF0gxqqYCuYOrXS [class~=ant-modal-body]{height:auto}.deleting-loading-modal-deletingLoadingModal--17reaZrQF0gxqqYCuYOrXS [class~=ant-spin-nested-loading]{min-height:150px}.deleting-loading-modal-deletingLoadingModal--17reaZrQF0gxqqYCuYOrXS [class~=ant-spin-nested-loading] [class~=ant-spin-text]{margin-top:10px}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/VelocityConfiguration/Operators/deletingLoadingModal.module.scss"],"names":[],"mappings":"AAAA,qEACC,WAAA,CACA,6FACC,WAAA,CAED,sGACC,gBAAA,CACA,6HACC,eAAA","sourcesContent":[".deletingLoadingModal {\r\n\twidth: 420px;\r\n\t[class~='ant-modal-body'] {\r\n\t\theight: auto;\r\n\t}\r\n\t[class~='ant-spin-nested-loading'] {\r\n\t\tmin-height: 150px;\r\n\t\t[class~='ant-spin-text'] {\r\n\t\t\tmargin-top: 10px;\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deletingLoadingModal": "deleting-loading-modal-deletingLoadingModal--17reaZrQF0gxqqYCuYOrXS"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import {
	AssuranceLevelList,
	OSDPLEDOption,
	Reader,
	ReaderError,
	ReaderInterface,
	ReaderLabel,
	ReaderOptionsTab,
	ScrambleFactorImageCategories,
	ScrambleFactorImageShort,
	SelectOption,
	SelectTimeZone,
	SupportedReaderClass,
} from '../../../../../model/DeviceAdminModel';
import { getDefaultAssuranceLevelSelection } from '../../helpers';

export type ExitReaderContextState = {
	id?: number;
	controllerId: number;
	name: string;
	index: number;
	supportedReaderClass: SupportedReaderClass;
	labels: ReaderLabel[];
	anyReaderOptions: ReaderOptionsTab[];
	cardReaderOptions: ReaderOptionsTab[];
	scramblePadKeyPadOptions: ReaderOptionsTab[];
	genericTimeZones: SelectTimeZone[];
	cardMaps: SelectOption[];
	specialHandlingInstructions: SelectOption[];
	readerThreatLevels: SelectOption[];
	isReaderWireless: boolean;
	rS485ReaderTypes: SelectOption[];
	oSDPAddress: number;
	wLHubAddress: number;
	eACAddress: number;
	wL2HubAddress: number;
	allegionLockAddress: number;
	okDegrade: boolean;
	passbackZones: SelectOption[];
	cCOTZAssurance: boolean;
	assuranceLevels: SelectOption[];
	defaultAssuranceLevels: SelectOption[];
	isOnboardWiegandAvailable: boolean;
	isControllerSNIB3: boolean;
	isControllerMATCH: boolean;
	firmware: string;
	appVersion: string;
	displayBoardVersion: string;
	enableReaderBezelTamper: boolean;
	enableHexPassThrough: boolean;
	enableMatchBezelTamper: boolean;
	facilityCodeCardNumber: boolean;
	corporate1000: boolean;
	hexDigitUUID: boolean;
	enableKeyPad: boolean;
	enableOSDPHandshake: boolean;
	isDoorWireless: boolean;
	oSDPTitle: string;
	oSDPConfirmation: string;
	firmwareConfirm: string;
	firmwareWarning: string;
	firmwareTitle: string;
	tSFirmwares: SelectOption[];
	canUpdateFirmware: boolean;
	applyToAllReaders: boolean;
	readerInterface: ReaderInterface;
	isFICAMEnabled: boolean;
	antiPassback: number;
	matchAlgorithm: number;
	fascnHandling: number;
	updateFirmwarePermissionText: string;
	isExitReaderError: boolean;
	readerErrorType: ReaderError;
	readerErrorMessage: string;
	selections: {
		cardMap: number;
		readerThreatLevel: number;
		cCOTZThreatLevel: number;
		assuranceLevel: number;
		defaultAssuranceLevel: number;
		toZone: number;
		fromZone: number;
		specialHandlingInstruction: number;
		cardCodeOnlyTimeZone: number;
		tSFirmware: number;
		rS485ReaderType: number;
	};
	unpermitted: {
		cardCodeOnlyTimeZone: SelectTimeZone;
	};
	changedOSDPLEDColor: boolean;
	useCustomOSDPLEDColor: boolean;
	osdpLEDOptions: OSDPLEDOption[];
	osdpCustomLEDColors: SelectOption[];
	readerTypeChanged: boolean;
	scrambleFactorCategories: ScrambleFactorImageCategories[];
	selectedFactorImages: ScrambleFactorImageShort[];
	viewPINEntryIndicator: boolean;
	updateScrambleFactorSelection: boolean;
	scrambleFactorErrorImageCategory: string;
};

enum ExitReaderActionTypes {
	SET_EXIT_READER_CONTROLLER_ID = 'SET_EXIT_READER_CONTROLLER_ID',
	SET_EXIT_READER_NAME = 'SET_EXIT_READER_NAME',
	SET_EXIT_READER_INDEX = 'SET_EXIT_READER_INDEX',
	SET_EXIT_READER_SUPPORTED_READER_CLASS = 'SET_EXIT_READER_SUPPORTED_READER_CLASS',
	SET_EXIT_READER_LABELS = 'SET_EXIT_READER_LABELS',
	SET_EXIT_READER_ANY_READER_OPTIONS = 'SET_EXIT_READER_ANY_READER_OPTIONS',
	SET_EXIT_READER_CARD_READER_OPTIONS = 'SET_EXIT_READER_CARD_READER_OPTIONS',
	SET_EXIT_READER_SCRAMBLE_PAD_KEY_OPTIONS = 'SET_EXIT_READER_SCRAMBLE_PAD_KEY_OPTIONS',
	SET_EXIT_READER_GENERIC_TIME_ZONES = 'SET_EXIT_READER_GENERIC_TIME_ZONES',
	SET_EXIT_READER_CARD_MAPS = 'SET_EXIT_READER_CARD_MAPS',
	SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTIONS = 'SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTIONS',
	SET_EXIT_READER_THREAT_LEVELS = 'SET_EXIT_READER_THREAT_LEVELS',
	SET_EXIT_READER_IS_READER_WIRELESS = 'SET_EXIT_READER_IS_READER_WIRELESS',
	SET_EXIT_READER_RS485_READER_TYPES = 'SET_EXIT_READER_RS485_READER_TYPES',
	SET_EXIT_READER_OSDP_ADDRESS = 'SET_EXIT_READER_OSDP_ADDRESS',
	SET_EXIT_READER_WLHUB_ADDRESS = 'SET_EXIT_READER_WLHUB_ADDRESS',
	SET_EXIT_READER_EAC_ADDRESS = 'SET_EXIT_READER_EAC_ADDRESS',
	SET_EXIT_READER_WL2HUB_ADDRESS = 'SET_EXIT_READER_WL2HUB_ADDRESS',
	SET_EXIT_READER_ALLEGION_LOCK_ADDRESS = 'SET_EXIT_READER_ALLEGION_LOCK_ADDRESS',
	SET_EXIT_READER_OK_DEGRADE = 'SET_EXIT_READER_OK_DEGRADE',
	SET_EXIT_READER_PASSBACK_ZONES = 'SET_EXIT_READER_PASSBACK_ZONES',
	SET_EXIT_READER_CCOTZ_ASSURANCE = 'SET_EXIT_READER_CCOTZ_ASSURANCE',
	SET_EXIT_READER_ASSURANCE_LEVELS = 'SET_EXIT_READER_ASSURANCE_LEVELS',
	SET_EXIT_READER_DEFAULT_ASSURANCE_LEVELS = 'SET_EXIT_READER_DEFAULT_ASSURANCE_LEVELS',
	SET_EXIT_READER_IS_ONBOARD_WIEGAND_AVAILABLE = 'SET_EXIT_READER_IS_ONBOARD_WIEGAND_AVAILABLE',
	SET_EXIT_READER_IS_CONTROLLER_SNIB3 = 'SET_EXIT_READER_IS_CONTROLLER_SNIB3',
	SET_EXIT_READER_IS_CONTROLLER_MATCH = 'SET_EXIT_READER_IS_CONTROLLER_MATCH',
	SET_EXIT_READER_FIRMWARE = 'SET_EXIT_READER_FIRMWARE',
	SET_EXIT_READER_ENABLE_READER_BEZEL_TAMPER = 'SET_EXIT_READER_ENABLE_READER_BEZEL_TAMPER',
	SET_EXIT_READER_ENABLE_HEX_PASSTHROUGH = 'SET_EXIT_READER_ENABLE_HEX_PASSTHROUGH',
	SET_EXIT_READER_ENABLE_MATCH_BEZEL_TAMPER = 'SET_EXIT_READER_ENABLE_MATCH_BEZEL_TAMPER',
	SET_EXIT_READER_FACILITY_CODE_CARD_NUMBER = 'SET_EXIT_READER_FACILITY_CODE_CARD_NUMBER',
	SET_EXIT_READER_CORPORATE1000 = 'SET_EXIT_READER_CORPORATE1000',
	SET_EXIT_READER_HEX_DIGIT_UUID = 'SET_EXIT_READER_HEX_DIGIT_UUID',
	SET_EXIT_READER_ENABLE_KEY_PAD = 'SET_EXIT_READER_ENABLE_KEY_PAD',
	SET_EXIT_READER_ENABLE_OSDP_HANDSHAKE = 'SET_EXIT_READER_ENABLE_OSDP_HANDSHAKE',
	SET_EXIT_READER_IS_DOOR_WIRELESS = 'SET_EXIT_READER_IS_DOOR_WIRELESS',
	SET_EXIT_READER_OSDP_TITLE = 'SET_EXIT_READER_OSDP_TITLE',
	SET_EXIT_READER_OSDP_CONFIRMATION = 'SET_EXIT_READER_OSDP_CONFIRMATION',
	SET_EXIT_READER_FIRMWARE_CONFIRM = 'SET_EXIT_READER_FIRMWARE_CONFIRM',
	SET_EXIT_READER_FIRMWARE_WARNING = 'SET_EXIT_READER_FIRMWARE_WARNING',
	SET_EXIT_READER_FIRMWARE_TITLE = 'SET_EXIT_READER_FIRMWARE_TITLE',
	SET_EXIT_READER_TS_FIRMWARES = 'SET_EXIT_READER_TS_FIRMWARES',
	SET_EXIT_READER_CAN_UPDATE_FIRMWARE = 'SET_EXIT_READER_CAN_UPDATE_FIRMWARE',
	SET_EXIT_READER_APPLY_TO_ALL_READERS = 'SET_EXIT_READER_APPLY_TO_ALL_READERS',
	SET_EXIT_READER_CARD_MAP = 'SET_EXIT_READER_CARD_MAP',
	SET_EXIT_READER_THREAT_LEVEL = 'SET_EXIT_READER_THREAT_LEVEL',
	SET_EXIT_READER_CCOTZ_THREAT_LEVEL = 'SET_EXIT_READER_CCOTZ_THREAT_LEVEL',
	SET_EXIT_READER_ANTIPASSBACK = 'SET_EXIT_READER_ANTIPASSBACK',
	SET_EXIT_READER_MATCH_ALGORITHM = 'SET_EXIT_READER_MATCH_ALGORITHM',
	SET_EXIT_READER_FASCN_HANDLING = 'SET_EXIT_READER_FASCN_HANDLING',
	SET_EXIT_READER_ASSURANCE_LEVEL = 'SET_EXIT_READER_ASSURANCE_LEVEL',
	SET_EXIT_READER_DEFAULT_ASSURANCE_LEVEL = 'SET_EXIT_READER_DEFAULT_ASSURANCE_LEVEL',
	SET_EXIT_READER_TO_ZONE = 'SET_EXIT_READER_TO_ZONE',
	SET_EXIT_READER_FROM_ZONE = 'SET_EXIT_READER_FROM_ZONE',
	SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTION = 'SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTION',
	SET_EXIT_READER_CARD_CODE_ONLY_TIME_ZONE = 'SET_EXIT_READER_CARD_CODE_ONLY_TIME_ZONE',
	SET_EXIT_READER_TS_FIRMWARE = 'SET_EXIT_READER_TS_FIRMWARE',
	SET_EXIT_READER_RS485_READER_TYPE = 'SET_EXIT_READER_RS485_READER_TYPE',
	SET_EXIT_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE = 'SET_EXIT_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE',
	SET_EXIT_READER_INTERFACE = 'SET_EXIT_READER_READER_INTERFACE',
	SET_EXIT_READER_IS_FICAM_ENABLED = 'SET_EXIT_READER_IS_FICAM_ENABLED',
	SET_EXIT_READER_UPDATE_FIRMWARE_PERMISSION_TEXT = 'SET_EXIT_READER_UPDATE_FIRMWARE_PERMISSION_TEXT',
	SET_EXIT_READER_ERROR_TYPE = 'SET_READER_ERROR_TYPE',
	SET_EXIT_READER_ERROR_MESSAGE = 'SET_EXIT_READER_ERROR_MESSAGE',
	INIT_EXIT_READER = 'INIT_EXIT_READER',
	SET_EXIT_READER_ASSURANCE_INFO = 'SET_EXIT_READER_ASSURANCE_INFO',
	SET_EXIT_READER_ERROR = 'SET_EXIT_READER_ERROR',
	SET_EXIT_OSDP_LED_OPTIONS = 'SET_EXIT_OSDP_LED_OPTIONS',
	SET_EXIT_USE_CUSTOM_OSDP_LED_COLOR = 'SET_EXIT_USE_CUSTOM_OSDP_LED_COLOR',
	SET_EXIT_CHANGED_OSDP_LED_COLOR = 'SET_EXIT_CHANGED_OSDP_LED_COLOR',
	SET_EXIT_SCRAMBLE_FACTOR_CATEGORIES = 'SET_EXIT_SCRAMBLE_FACTOR_CATEGORIES',
	SET_EXIT_SELECTED_FACTOR_IMAGES = 'SET_EXIT_SELECTED_FACTOR_IMAGES',
	SET_EXIT_VIEW_PIN_ENTRY_INDICATOR = 'SET_EXIT_VIEW_PIN_ENTRY_INDICATOR',
	SET_EXIT_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION = 'SET_EXIT_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION',
	SET_EXIT_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY = 'SET_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY',
}

type contextExitReaderInitReader = {
	type: ExitReaderActionTypes.INIT_EXIT_READER;
	payload: Partial<Reader>;
};

type contextExitReaderSetAssuranceInfo = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_INFO;
	payload: Partial<AssuranceLevelList>;
};

type contextExitReaderSetName = {
	type: ExitReaderActionTypes.SET_EXIT_READER_NAME;
	payload: string;
};

type contextExitReaderControllerId = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CONTROLLER_ID;
	payload: number;
};

type contextExitReaderSetIndex = {
	type: ExitReaderActionTypes.SET_EXIT_READER_INDEX;
	payload: number;
};

type contextExitReaderSetSupportedReaderClass = {
	type: ExitReaderActionTypes.SET_EXIT_READER_SUPPORTED_READER_CLASS;
	payload: SupportedReaderClass;
};

type contextExitReaderSetLabels = {
	type: ExitReaderActionTypes.SET_EXIT_READER_LABELS;
	payload: ReaderLabel[];
};

type contextExitReaderSetAnyReaderOptions = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ANY_READER_OPTIONS;
	payload: ReaderOptionsTab[];
};

type contextExitReaderSetCardReaderOptions = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CARD_READER_OPTIONS;
	payload: ReaderOptionsTab[];
};

type contextExitReaderSetScramblePadKeyOptions = {
	type: ExitReaderActionTypes.SET_EXIT_READER_SCRAMBLE_PAD_KEY_OPTIONS;
	payload: ReaderOptionsTab[];
};

type contextExitReaderSetGenericTimeZones = {
	type: ExitReaderActionTypes.SET_EXIT_READER_GENERIC_TIME_ZONES;
	payload: SelectTimeZone[];
};

type contextExitReaderSetCardMaps = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CARD_MAPS;
	payload: SelectOption[];
};

type contextExitReaderSetSpecialHandlingInstructions = {
	type: ExitReaderActionTypes.SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTIONS;
	payload: SelectOption[];
};

type contextExitReaderSetReaderThreatLevels = {
	type: ExitReaderActionTypes.SET_EXIT_READER_THREAT_LEVELS;
	payload: SelectOption[];
};

type contextExitReaderSetIsReaderWireless = {
	type: ExitReaderActionTypes.SET_EXIT_READER_IS_READER_WIRELESS;
	payload: boolean;
};

type contextExitReaderSetRS485ReaderTypes = {
	type: ExitReaderActionTypes.SET_EXIT_READER_RS485_READER_TYPES;
	payload: SelectOption[];
};

type contextExitReaderSetOSDPAddress = {
	type: ExitReaderActionTypes.SET_EXIT_READER_OSDP_ADDRESS;
	payload: number;
};

type contextExitReaderSetWLHubAddress = {
	type: ExitReaderActionTypes.SET_EXIT_READER_WLHUB_ADDRESS;
	payload: number;
};

type contextExitReaderSetEACAddress = {
	type: ExitReaderActionTypes.SET_EXIT_READER_EAC_ADDRESS;
	payload: number;
};

type contextExitReaderSetWL2HubAddress = {
	type: ExitReaderActionTypes.SET_EXIT_READER_WL2HUB_ADDRESS;
	payload: number;
};

type contextExitReaderSetAllegionLockAddress = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ALLEGION_LOCK_ADDRESS;
	payload: number;
};

type contextExitReaderSetOkDegrade = {
	type: ExitReaderActionTypes.SET_EXIT_READER_OK_DEGRADE;
	payload: boolean;
};

type contextExitReaderSetPassbackZones = {
	type: ExitReaderActionTypes.SET_EXIT_READER_PASSBACK_ZONES;
	payload: SelectOption[];
};

type contextExitReaderSetCCOTZAssurance = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CCOTZ_ASSURANCE;
	payload: boolean;
};

type contextExitReaderSetAssuranceLevels = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_LEVELS;
	payload: SelectOption[];
};

type contextExitReaderSetDefaultAssuranceLevels = {
	type: ExitReaderActionTypes.SET_EXIT_READER_DEFAULT_ASSURANCE_LEVELS;
	payload: SelectOption[];
};

type contextExitReaderSetIsOnboardWiegandAvailable = {
	type: ExitReaderActionTypes.SET_EXIT_READER_IS_ONBOARD_WIEGAND_AVAILABLE;
	payload: boolean;
};

type contextExitReaderSetIsControllerSNIB3 = {
	type: ExitReaderActionTypes.SET_EXIT_READER_IS_CONTROLLER_SNIB3;
	payload: boolean;
};

type contextExitReaderSetIsControllerMatch = {
	type: ExitReaderActionTypes.SET_EXIT_READER_IS_CONTROLLER_MATCH;
	payload: boolean;
};

type contextExitReaderSetFirmware = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE;
	payload: string;
};

type contextExitReaderSetEnableReaderBezelTamper = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_READER_BEZEL_TAMPER;
	payload: boolean;
};

type contextExitReaderSetEnableHexPassthrough = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_HEX_PASSTHROUGH;
	payload: boolean;
};

type contextExitReaderSetEnableMatchBezelTamper = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_MATCH_BEZEL_TAMPER;
	payload: boolean;
};

type contextExitReaderSetFacilityCodeCardNumber = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FACILITY_CODE_CARD_NUMBER;
	payload: boolean;
};

type contextExitReaderSetCorporate1000 = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CORPORATE1000;
	payload: boolean;
};

type contextExitReaderSetHexDigitUUID = {
	type: ExitReaderActionTypes.SET_EXIT_READER_HEX_DIGIT_UUID;
	payload: boolean;
};

type contextExitReaderSetEnableKeyPad = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_KEY_PAD;
	payload: boolean;
};

type contextExitReaderSetEnableOSDPHandshake = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_OSDP_HANDSHAKE;
	payload: boolean;
};

type contextExitReaderSetIsDoorWireless = {
	type: ExitReaderActionTypes.SET_EXIT_READER_IS_DOOR_WIRELESS;
	payload: boolean;
};

type contextExitReaderSetOSDPTitle = {
	type: ExitReaderActionTypes.SET_EXIT_READER_OSDP_TITLE;
	payload: string;
};

type contextExitReaderSetOSDPConfirmation = {
	type: ExitReaderActionTypes.SET_EXIT_READER_OSDP_CONFIRMATION;
	payload: string;
};

type contextExitReaderSetFirmwareConfirm = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_CONFIRM;
	payload: string;
};

type contextExitReaderSetFirmwareWarning = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_WARNING;
	payload: string;
};

type contextExitReaderSetFirmwareTitle = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_TITLE;
	payload: string;
};

type contextExitReaderSetTSFirmwares = {
	type: ExitReaderActionTypes.SET_EXIT_READER_TS_FIRMWARES;
	payload: SelectOption[];
};

type contextExitReaderSetCanUpdateFirmware = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CAN_UPDATE_FIRMWARE;
	payload: boolean;
};

type contextExitReaderSetApplyToAllReaders = {
	type: ExitReaderActionTypes.SET_EXIT_READER_APPLY_TO_ALL_READERS;
	payload: boolean;
};

type contextExitReaderSetReaderInterface = {
	type: ExitReaderActionTypes.SET_EXIT_READER_INTERFACE;
	payload: ReaderInterface;
};

type contextExitReaderSetCardMap = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CARD_MAP;
	payload: number;
};

type contextExitReaderSetReaderThreatLevel = {
	type: ExitReaderActionTypes.SET_EXIT_READER_THREAT_LEVEL;
	payload: number;
};

type contextExitReaderSetCCOTZThreatLevel = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CCOTZ_THREAT_LEVEL;
	payload: number;
};

type contextExitReaderSetAntipassback = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ANTIPASSBACK;
	payload: number;
};

type contextExitReaderSetMatchAlgorithm = {
	type: ExitReaderActionTypes.SET_EXIT_READER_MATCH_ALGORITHM;
	payload: number;
};

type contextExitReaderSetFascnHandling = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FASCN_HANDLING;
	payload: number;
};

type contextExitReaderSetAssuranceLevel = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_LEVEL;
	payload: number;
};

type contextExitReaderSetDefaultAssuranceLevel = {
	type: ExitReaderActionTypes.SET_EXIT_READER_DEFAULT_ASSURANCE_LEVEL;
	payload: number;
};

type contextExitReadeSetToZone = {
	type: ExitReaderActionTypes.SET_EXIT_READER_TO_ZONE;
	payload: number;
};

type contextExitReaderSetFromZone = {
	type: ExitReaderActionTypes.SET_EXIT_READER_FROM_ZONE;
	payload: number;
};

type contextExitReaderSetSpecialHandlingInstruction = {
	type: ExitReaderActionTypes.SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTION;
	payload: number;
};

type contextExitReaderSetCardCodeOnlyTimeZone = {
	type: ExitReaderActionTypes.SET_EXIT_READER_CARD_CODE_ONLY_TIME_ZONE;
	payload: number;
};

type contextExitReaderSetTSFirmware = {
	type: ExitReaderActionTypes.SET_EXIT_READER_TS_FIRMWARE;
	payload: number;
};

type contextExitReaderSetRS485ReaderType = {
	type: ExitReaderActionTypes.SET_EXIT_READER_RS485_READER_TYPE;
	payload: number;
};

type contextExitReaderSetIsFICAMEnabled = {
	type: ExitReaderActionTypes.SET_EXIT_READER_IS_FICAM_ENABLED;
	payload: boolean;
};

type contextExitReaderSetUnpermittedCardCodeOnlyTimeZone = {
	type: ExitReaderActionTypes.SET_EXIT_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE;
	payload: SelectTimeZone;
};

type contextExitReaderSetUpdateFirmwarePermissionText = {
	type: ExitReaderActionTypes.SET_EXIT_READER_UPDATE_FIRMWARE_PERMISSION_TEXT;
	payload: string;
};

type contextExitReaderSetReaderErrorType = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ERROR_TYPE;
	payload: ReaderError;
};

type contextExitReaderSetReaderErrorMessage = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ERROR_MESSAGE;
	payload: string;
};

type contextExitReaderSetExitReaderError = {
	type: ExitReaderActionTypes.SET_EXIT_READER_ERROR;
	payload: boolean;
};

type contextExitReaderSetOSDPLEDOptions = {
	type: ExitReaderActionTypes.SET_EXIT_OSDP_LED_OPTIONS;
	payload: OSDPLEDOption[];
};

type contextExitReaderSetUseCustomOSDPLEDColor = {
	type: ExitReaderActionTypes.SET_EXIT_USE_CUSTOM_OSDP_LED_COLOR;
	payload: boolean;
};

type contextExitReaderSetChangedOSDLEDColor = {
	type: ExitReaderActionTypes.SET_EXIT_CHANGED_OSDP_LED_COLOR;
	payload: boolean;
};

export type contextExitReaderSetScrambleFactorCategories = {
	type: ExitReaderActionTypes.SET_EXIT_SCRAMBLE_FACTOR_CATEGORIES;
	payload: ScrambleFactorImageCategories[];
};

export type contextExitReaderSetSelectedFactorImages = {
	type: ExitReaderActionTypes.SET_EXIT_SELECTED_FACTOR_IMAGES;
	payload: ScrambleFactorImageShort[];
};

export type contextExitReaderSetViewPinEntryIndicator = {
	type: ExitReaderActionTypes.SET_EXIT_VIEW_PIN_ENTRY_INDICATOR;
	payload: boolean;
};

export type contextExitReaderSetUpdateScrambleFactorImageSelection = {
	type: ExitReaderActionTypes.SET_EXIT_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION;
	payload: boolean;
};

export type contextExitReaderSetScrambleFactorErrorCategory = {
	type: ExitReaderActionTypes.SET_EXIT_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY;
	payload: string;
};

export type SetExitReaderScrambleFactorCategories = (payload: ScrambleFactorImageCategories[]) => contextExitReaderSetScrambleFactorCategories;

export type ExitReaderActionDefinition = (payload: ScrambleFactorImageShort[]) => contextExitReaderSetSelectedFactorImages;

export type ExitViewPinEntryIndicator = (payload: boolean) => contextExitReaderSetViewPinEntryIndicator;

export type SetExitReaderErrorType = (payload: ReaderError) => contextExitReaderSetReaderErrorType;

export type SetExitReaderErrorMessage = (payload: string) => contextExitReaderSetReaderErrorMessage;

export type SetExitReaderErrorImageCategory = (payload: string) => contextExitReaderSetScrambleFactorErrorCategory;

type ExitReaderActionContext =
	| contextExitReaderSetName
	| contextExitReaderControllerId
	| contextExitReaderSetIndex
	| contextExitReaderSetSupportedReaderClass
	| contextExitReaderSetLabels
	| contextExitReaderSetAnyReaderOptions
	| contextExitReaderSetCardReaderOptions
	| contextExitReaderSetScramblePadKeyOptions
	| contextExitReaderSetGenericTimeZones
	| contextExitReaderSetCardMaps
	| contextExitReaderSetSpecialHandlingInstructions
	| contextExitReaderSetReaderThreatLevels
	| contextExitReaderSetIsReaderWireless
	| contextExitReaderSetRS485ReaderTypes
	| contextExitReaderSetOSDPAddress
	| contextExitReaderSetWLHubAddress
	| contextExitReaderSetEACAddress
	| contextExitReaderSetWL2HubAddress
	| contextExitReaderSetOkDegrade
	| contextExitReaderSetPassbackZones
	| contextExitReaderSetCCOTZAssurance
	| contextExitReaderSetAssuranceLevels
	| contextExitReaderSetDefaultAssuranceLevels
	| contextExitReaderSetIsOnboardWiegandAvailable
	| contextExitReaderSetIsControllerSNIB3
	| contextExitReaderSetIsControllerMatch
	| contextExitReaderSetFirmware
	| contextExitReaderSetEnableReaderBezelTamper
	| contextExitReaderSetEnableHexPassthrough
	| contextExitReaderSetEnableMatchBezelTamper
	| contextExitReaderSetFacilityCodeCardNumber
	| contextExitReaderSetCorporate1000
	| contextExitReaderSetHexDigitUUID
	| contextExitReaderSetEnableKeyPad
	| contextExitReaderSetEnableOSDPHandshake
	| contextExitReaderSetIsDoorWireless
	| contextExitReaderSetOSDPTitle
	| contextExitReaderSetFirmwareConfirm
	| contextExitReaderSetFirmwareWarning
	| contextExitReaderSetFirmwareTitle
	| contextExitReaderSetTSFirmwares
	| contextExitReaderSetCanUpdateFirmware
	| contextExitReaderSetApplyToAllReaders
	| contextExitReaderSetCardMap
	| contextExitReaderSetReaderThreatLevel
	| contextExitReaderSetCCOTZThreatLevel
	| contextExitReaderSetAntipassback
	| contextExitReaderSetMatchAlgorithm
	| contextExitReaderSetFascnHandling
	| contextExitReaderSetAssuranceLevel
	| contextExitReaderSetDefaultAssuranceLevel
	| contextExitReadeSetToZone
	| contextExitReaderSetFromZone
	| contextExitReaderSetSpecialHandlingInstruction
	| contextExitReaderSetCardCodeOnlyTimeZone
	| contextExitReaderSetTSFirmware
	| contextExitReaderSetAllegionLockAddress
	| contextExitReaderSetOSDPConfirmation
	| contextExitReaderSetRS485ReaderType
	| contextExitReaderSetReaderInterface
	| contextExitReaderSetIsFICAMEnabled
	| contextExitReaderInitReader
	| contextExitReaderSetUpdateFirmwarePermissionText
	| contextExitReaderSetReaderErrorType
	| contextExitReaderSetReaderErrorMessage
	| contextExitReaderSetAssuranceInfo
	| contextExitReaderSetUnpermittedCardCodeOnlyTimeZone
	| contextExitReaderSetOSDPLEDOptions
	| contextExitReaderSetUseCustomOSDPLEDColor
	| contextExitReaderSetChangedOSDLEDColor
	| contextExitReaderSetExitReaderError
	| contextExitReaderSetScrambleFactorCategories
	| contextExitReaderSetUpdateScrambleFactorImageSelection
	| contextExitReaderSetSelectedFactorImages
	| contextExitReaderSetScrambleFactorErrorCategory
	| contextExitReaderSetViewPinEntryIndicator;

const exitReaderState: ExitReaderContextState = {
	controllerId: 0,
	name: '',
	index: 0,
	supportedReaderClass: SupportedReaderClass.Default,
	labels: [],
	anyReaderOptions: [],
	cardReaderOptions: [],
	scramblePadKeyPadOptions: [],
	genericTimeZones: [],
	cardMaps: [],
	specialHandlingInstructions: [],
	readerThreatLevels: [],
	isReaderWireless: false,
	rS485ReaderTypes: [],
	oSDPAddress: 0,
	wLHubAddress: 0,
	eACAddress: 0,
	wL2HubAddress: 0,
	allegionLockAddress: 0,
	okDegrade: false,
	passbackZones: [],
	cCOTZAssurance: false,
	assuranceLevels: [],
	defaultAssuranceLevels: [],
	isOnboardWiegandAvailable: false,
	isControllerSNIB3: false,
	isControllerMATCH: false,
	firmware: '',
	appVersion: '',
	displayBoardVersion: '',
	enableReaderBezelTamper: false,
	enableHexPassThrough: false,
	enableMatchBezelTamper: false,
	facilityCodeCardNumber: false,
	corporate1000: false,
	hexDigitUUID: false,
	enableKeyPad: false,
	enableOSDPHandshake: false,
	isDoorWireless: false,
	oSDPTitle: '',
	oSDPConfirmation: '',
	firmwareConfirm: '',
	firmwareWarning: '',
	firmwareTitle: '',
	tSFirmwares: [],
	canUpdateFirmware: true,
	applyToAllReaders: false,
	readerInterface: ReaderInterface.MatchDS47,
	isFICAMEnabled: false,
	antiPassback: 0,
	matchAlgorithm: 0,
	fascnHandling: 0,
	updateFirmwarePermissionText: '',
	isExitReaderError: false,
	readerErrorMessage: '',
	readerErrorType: undefined,
	selections: {
		cardMap: 0,
		readerThreatLevel: 0,
		cCOTZThreatLevel: 0,
		assuranceLevel: 0,
		defaultAssuranceLevel: 0,
		toZone: 0,
		fromZone: 0,
		specialHandlingInstruction: 0,
		cardCodeOnlyTimeZone: 1,
		tSFirmware: 0,
		rS485ReaderType: 0,
	},
	unpermitted: {
		cardCodeOnlyTimeZone: null,
	},
	osdpLEDOptions: [],
	useCustomOSDPLEDColor: false,
	changedOSDPLEDColor: false,
	osdpCustomLEDColors: [],
	readerTypeChanged: false,
	scrambleFactorCategories: [],
	selectedFactorImages: [],
	viewPINEntryIndicator: false,
	updateScrambleFactorSelection: false,
	scrambleFactorErrorImageCategory: '',
};

const exitReaderContext = (state: Readonly<ExitReaderContextState>, action: ExitReaderActionContext): ExitReaderContextState => {
	switch (action.type) {
		case ExitReaderActionTypes.INIT_EXIT_READER:
			return {
				...state,
				id: action.payload.Id,
				controllerId: action.payload.ControllerId,
				name: action.payload.Name,
				index: action.payload.Index,
				supportedReaderClass: action.payload.SupportedReaderClass,
				labels: action.payload.Labels,
				anyReaderOptions: action.payload.AnyReaderOptions,
				cardReaderOptions: action.payload.CardReaderOptions,
				scramblePadKeyPadOptions: action.payload.ScramblePadKeyPadOptions,
				genericTimeZones: action.payload.GenericTimeZones,
				cardMaps: action.payload.CardMaps,
				specialHandlingInstructions: action.payload.SpecialHandlingInstructions,
				readerThreatLevels: action.payload.ReaderThreatLevels,
				isReaderWireless: action.payload.IsReaderWireless,
				rS485ReaderTypes: action.payload.RS485ReaderTypes,
				oSDPAddress: action.payload.OSDPAddress,
				wLHubAddress: action.payload.WLHubAddress,
				eACAddress: action.payload.EACAddress,
				wL2HubAddress: action.payload.WL2HubAddress,
				allegionLockAddress: action.payload.AllegionLockAddress,
				okDegrade: action.payload.OkDegrade,
				passbackZones: action.payload.PassbackZones,
				cCOTZAssurance: action.payload.CCOTZAssurance,
				isOnboardWiegandAvailable: action.payload.IsOnboardWiegandAvailable,
				isControllerSNIB3: action.payload.IsControllerSNIB3,
				isControllerMATCH: action.payload.IsControllerMATCH,
				firmware: action.payload.Firmware,
				appVersion: action.payload.AppVersion,
				displayBoardVersion: action.payload.DisplayBoardVersion,
				isFICAMEnabled: action.payload.IsFICAMEnabled,
				enableReaderBezelTamper: action.payload.EnableReaderBezelTamper,
				enableHexPassThrough: action.payload.EnableHexPassThrough,
				enableMatchBezelTamper: action.payload.EnableMatchBezelTamper,
				facilityCodeCardNumber: action.payload.FacilityCodeCardNumber,
				corporate1000: action.payload.Corporate1000,
				hexDigitUUID: action.payload.HexDigitUUID,
				enableKeyPad: action.payload.EnableKeyPad,
				enableOSDPHandshake: action.payload.EnableOSDPHandshake,
				isDoorWireless: action.payload.IsDoorWireless,
				oSDPTitle: action.payload.OSDPTitle,
				oSDPConfirmation: action.payload.OSDPConfirmation,
				firmwareConfirm: action.payload.FirmwareConfirm,
				firmwareWarning: action.payload.FirmwareWarning,
				firmwareTitle: action.payload.FirmwareTitle,
				tSFirmwares: action.payload.TSFirmwares,
				canUpdateFirmware: action.payload.CanUpdateFirmware,
				applyToAllReaders: action.payload.ApplyToAllReaders,
				readerInterface: action.payload.ReaderInterface,
				antiPassback: action.payload.AntiPassback,
				matchAlgorithm: action.payload.MatchAlgorithm,
				fascnHandling: action.payload.FascnHandling,
				updateFirmwarePermissionText: action.payload.UpdateFirmwarePermissionText,
				osdpCustomLEDColors: action.payload.OSDPCustomLEDColors,
				useCustomOSDPLEDColor: action.payload.UseCustomOSDPLEDColor,
				selections: {
					...state.selections,
					cardCodeOnlyTimeZone: action.payload.CardCodeOnlyTimeZone.GenericId,
					cardMap: action.payload.CardMap,
					readerThreatLevel: action.payload.DisabledReaderAboveLevelId,
					cCOTZThreatLevel: action.payload.DisabledCCOTZAboveLevelId,
					assuranceLevel: action.payload.AssuranceLevel,
					defaultAssuranceLevel: action.payload.DefaultAssuranceLevel,
					toZone: action.payload.ToZone,
					fromZone: action.payload.FromZone,
					specialHandlingInstruction: action.payload.SpecialHandlingInstruction,
					rS485ReaderType: action.payload.SupportedReaderClass === 0 ? action.payload.RS485ReaderType : undefined,
				},
				selectedFactorImages: action.payload.TSScrambleImages,
				viewPINEntryIndicator: action.payload.ViewPINEntryIndicator,
			};

		case ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_INFO:
			const defaultAssuranceLevelSelection: number = getDefaultAssuranceLevelSelection(
				action.payload.DefaultAssuranceLevels,
				state.selections.defaultAssuranceLevel,
				action.payload.DefaultAssurance,
				state.readerTypeChanged
			);

			return {
				...state,
				assuranceLevels: action.payload.AssuranceLevels,
				defaultAssuranceLevels: action.payload.DefaultAssuranceLevels,
				canUpdateFirmware: action.payload.CanUpdateFirmware,
				selections: { ...state.selections, defaultAssuranceLevel: defaultAssuranceLevelSelection },
			};

		case ExitReaderActionTypes.SET_EXIT_READER_CONTROLLER_ID:
			return { ...state, controllerId: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_NAME:
			return { ...state, name: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_INDEX:
			return { ...state, index: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_SUPPORTED_READER_CLASS:
			return { ...state, supportedReaderClass: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_LABELS:
			return { ...state, labels: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ANY_READER_OPTIONS:
			return { ...state, anyReaderOptions: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_CARD_READER_OPTIONS:
			return { ...state, cardReaderOptions: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_SCRAMBLE_PAD_KEY_OPTIONS:
			return { ...state, scramblePadKeyPadOptions: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_GENERIC_TIME_ZONES:
			return { ...state, genericTimeZones: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_CARD_MAPS:
			return { ...state, cardMaps: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTIONS:
			return { ...state, specialHandlingInstructions: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_THREAT_LEVELS:
			return { ...state, readerThreatLevels: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_IS_READER_WIRELESS:
			return { ...state, isReaderWireless: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_RS485_READER_TYPES:
			return { ...state, rS485ReaderTypes: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_OSDP_ADDRESS:
			return { ...state, oSDPAddress: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_WLHUB_ADDRESS:
			return { ...state, wLHubAddress: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_EAC_ADDRESS:
			return { ...state, eACAddress: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_WL2HUB_ADDRESS:
			return { ...state, wL2HubAddress: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ALLEGION_LOCK_ADDRESS:
			return { ...state, allegionLockAddress: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_OK_DEGRADE:
			return { ...state, okDegrade: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_PASSBACK_ZONES:
			return { ...state, passbackZones: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_CCOTZ_ASSURANCE:
			return { ...state, cCOTZAssurance: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_LEVELS:
			return { ...state, assuranceLevels: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_DEFAULT_ASSURANCE_LEVELS:
			return { ...state, defaultAssuranceLevels: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_IS_ONBOARD_WIEGAND_AVAILABLE:
			return { ...state, isOnboardWiegandAvailable: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_IS_CONTROLLER_SNIB3:
			return { ...state, isControllerSNIB3: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_IS_CONTROLLER_MATCH:
			return { ...state, isControllerMATCH: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE:
			return { ...state, firmware: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_IS_FICAM_ENABLED:
			return { ...state, isFICAMEnabled: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ENABLE_READER_BEZEL_TAMPER:
			return { ...state, enableReaderBezelTamper: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ENABLE_HEX_PASSTHROUGH:
			return { ...state, enableHexPassThrough: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ENABLE_MATCH_BEZEL_TAMPER:
			return { ...state, enableMatchBezelTamper: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_FACILITY_CODE_CARD_NUMBER:
			return { ...state, facilityCodeCardNumber: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_CORPORATE1000:
			return { ...state, corporate1000: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_HEX_DIGIT_UUID:
			return { ...state, hexDigitUUID: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ENABLE_KEY_PAD:
			return { ...state, enableKeyPad: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ENABLE_OSDP_HANDSHAKE:
			return { ...state, enableOSDPHandshake: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_IS_DOOR_WIRELESS:
			return { ...state, isDoorWireless: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_OSDP_TITLE:
			return { ...state, oSDPTitle: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_OSDP_CONFIRMATION:
			return { ...state, oSDPConfirmation: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_CONFIRM:
			return { ...state, firmwareConfirm: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_WARNING:
			return { ...state, firmwareWarning: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_TITLE:
			return { ...state, firmwareTitle: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_TS_FIRMWARES:
			return { ...state, tSFirmwares: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_CAN_UPDATE_FIRMWARE:
			return { ...state, canUpdateFirmware: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_APPLY_TO_ALL_READERS:
			return { ...state, applyToAllReaders: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_INTERFACE:
			return { ...state, readerInterface: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_CARD_MAP:
			return { ...state, selections: { ...state.selections, cardMap: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_THREAT_LEVEL:
			return { ...state, selections: { ...state.selections, readerThreatLevel: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_CCOTZ_THREAT_LEVEL:
			return { ...state, selections: { ...state.selections, cCOTZThreatLevel: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_ANTIPASSBACK:
			return { ...state, antiPassback: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_MATCH_ALGORITHM:
			return { ...state, matchAlgorithm: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_FASCN_HANDLING:
			return { ...state, fascnHandling: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_UPDATE_FIRMWARE_PERMISSION_TEXT:
			return { ...state, updateFirmwarePermissionText: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ERROR_TYPE:
			return { ...state, readerErrorType: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ERROR_MESSAGE:
			return { ...state, readerErrorMessage: action.payload };

		case ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_LEVEL:
			return { ...state, selections: { ...state.selections, assuranceLevel: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_DEFAULT_ASSURANCE_LEVEL:
			return { ...state, selections: { ...state.selections, defaultAssuranceLevel: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_TO_ZONE:
			return { ...state, selections: { ...state.selections, toZone: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_FROM_ZONE:
			return { ...state, selections: { ...state.selections, fromZone: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTION:
			return { ...state, selections: { ...state.selections, specialHandlingInstruction: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_CARD_CODE_ONLY_TIME_ZONE:
			return { ...state, selections: { ...state.selections, cardCodeOnlyTimeZone: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_TS_FIRMWARE:
			return { ...state, selections: { ...state.selections, tSFirmware: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_RS485_READER_TYPE:
			return { ...state, readerTypeChanged: true, selections: { ...state.selections, rS485ReaderType: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE:
			return { ...state, unpermitted: { ...state.unpermitted, cardCodeOnlyTimeZone: action.payload } };

		case ExitReaderActionTypes.SET_EXIT_READER_ERROR:
			return { ...state, isExitReaderError: action.payload };

		case ExitReaderActionTypes.SET_EXIT_OSDP_LED_OPTIONS:
			return { ...state, osdpLEDOptions: action.payload };

		case ExitReaderActionTypes.SET_EXIT_USE_CUSTOM_OSDP_LED_COLOR:
			return { ...state, useCustomOSDPLEDColor: action.payload };

		case ExitReaderActionTypes.SET_EXIT_CHANGED_OSDP_LED_COLOR:
			return { ...state, changedOSDPLEDColor: action.payload };

		case ExitReaderActionTypes.SET_EXIT_SCRAMBLE_FACTOR_CATEGORIES:
			return { ...state, scrambleFactorCategories: action.payload };

		case ExitReaderActionTypes.SET_EXIT_SELECTED_FACTOR_IMAGES:
			return { ...state, selectedFactorImages: action.payload };

		case ExitReaderActionTypes.SET_EXIT_VIEW_PIN_ENTRY_INDICATOR:
			return { ...state, viewPINEntryIndicator: action.payload };

		case ExitReaderActionTypes.SET_EXIT_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION:
			return { ...state, updateScrambleFactorSelection: action.payload };

		case ExitReaderActionTypes.SET_EXIT_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY:
			return { ...state, scrambleFactorErrorImageCategory: action.payload };

		default:
			return { ...state };
	}
};

const initExitReaderAction = (payload: Partial<Reader>): contextExitReaderInitReader => {
	return {
		type: ExitReaderActionTypes.INIT_EXIT_READER,
		payload,
	};
};

const setExitReaderAssuranceInfo = (payload: Partial<AssuranceLevelList>): contextExitReaderSetAssuranceInfo => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_INFO,
		payload,
	};
};

const setExitReaderName = (payload: string): contextExitReaderSetName => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_NAME,
		payload,
	};
};

const setExitReaderControllerId = (payload: number): contextExitReaderControllerId => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CONTROLLER_ID,
		payload,
	};
};

const setExitReaderIndex = (payload: number): contextExitReaderSetIndex => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_INDEX,
		payload,
	};
};

const setExitReaderSupportedReaderClass = (payload: SupportedReaderClass): contextExitReaderSetSupportedReaderClass => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_SUPPORTED_READER_CLASS,
		payload,
	};
};

const setExitReaderLabels = (payload: ReaderLabel[]): contextExitReaderSetLabels => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_LABELS,
		payload,
	};
};

const setExitReaderAnyReaderOptions = (payload: ReaderOptionsTab[]): contextExitReaderSetAnyReaderOptions => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ANY_READER_OPTIONS,
		payload,
	};
};

const setExitReaderCardReaderOptions = (payload: ReaderOptionsTab[]): contextExitReaderSetCardReaderOptions => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CARD_READER_OPTIONS,
		payload,
	};
};

const setExitReaderScramblePadKeyOptions = (payload: ReaderOptionsTab[]): contextExitReaderSetScramblePadKeyOptions => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_SCRAMBLE_PAD_KEY_OPTIONS,
		payload,
	};
};

const setExitReaderGenericTimeZones = (payload: SelectTimeZone[]): contextExitReaderSetGenericTimeZones => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_GENERIC_TIME_ZONES,
		payload,
	};
};

const setExitReaderCardMaps = (payload: SelectOption[]): contextExitReaderSetCardMaps => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CARD_MAPS,
		payload,
	};
};

const setExitReaderIsFICAMEnabled = (payload: boolean): contextExitReaderSetIsFICAMEnabled => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_IS_FICAM_ENABLED,
		payload,
	};
};

const setExitReaderSpecialHandlingInstructions = (payload: SelectOption[]): contextExitReaderSetSpecialHandlingInstructions => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTIONS,
		payload,
	};
};

const setExitReaderThreatLevels = (payload: SelectOption[]): contextExitReaderSetReaderThreatLevels => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_THREAT_LEVELS,
		payload,
	};
};

const setExitReaderIsReaderWireless = (payload: boolean): contextExitReaderSetIsReaderWireless => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_IS_READER_WIRELESS,
		payload,
	};
};

const setExitReaderRS485ReaderTypes = (payload: SelectOption[]): contextExitReaderSetRS485ReaderTypes => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_RS485_READER_TYPES,
		payload,
	};
};

const setExitReaderOSDPAddress = (payload: number): contextExitReaderSetOSDPAddress => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_OSDP_ADDRESS,
		payload,
	};
};

const setExitReaderWLHubAddress = (payload: number): contextExitReaderSetWLHubAddress => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_WLHUB_ADDRESS,
		payload,
	};
};

const setExitReaderEACAddress = (payload: number): contextExitReaderSetEACAddress => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_EAC_ADDRESS,
		payload,
	};
};

const setExitReaderWL2HubAddress = (payload: number): contextExitReaderSetWL2HubAddress => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_WL2HUB_ADDRESS,
		payload,
	};
};

const setExitReaderAllegionLockAddress = (payload: number): contextExitReaderSetAllegionLockAddress => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ALLEGION_LOCK_ADDRESS,
		payload,
	};
};

const setExitReaderOkDegrade = (payload: boolean): contextExitReaderSetOkDegrade => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_OK_DEGRADE,
		payload,
	};
};

const setExitReaderPassbackZones = (payload: SelectOption[]): contextExitReaderSetPassbackZones => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_PASSBACK_ZONES,
		payload,
	};
};

const setExitReaderCCOTZAssurance = (payload: boolean): contextExitReaderSetCCOTZAssurance => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CCOTZ_ASSURANCE,
		payload,
	};
};

const setExitReaderAssuranceLevels = (payload: SelectOption[]): contextExitReaderSetAssuranceLevels => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_LEVELS,
		payload,
	};
};

const setExitReaderDefaultAssuranceLevels = (payload: SelectOption[]): contextExitReaderSetDefaultAssuranceLevels => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_DEFAULT_ASSURANCE_LEVELS,
		payload,
	};
};

const setExitReaderIsOnboardWiegandAvailable = (payload: boolean): contextExitReaderSetIsOnboardWiegandAvailable => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_IS_ONBOARD_WIEGAND_AVAILABLE,
		payload,
	};
};

const setExitReaderIsControllerSNIB3 = (payload: boolean): contextExitReaderSetIsControllerSNIB3 => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_IS_CONTROLLER_SNIB3,
		payload,
	};
};

const setExitReaderIsControllerMatch = (payload: boolean): contextExitReaderSetIsControllerMatch => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_IS_CONTROLLER_MATCH,
		payload,
	};
};

const setExitReaderFirmware = (payload: string): contextExitReaderSetFirmware => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE,
		payload,
	};
};

const setExitReaderEnableReaderBezelTamper = (payload: boolean): contextExitReaderSetEnableReaderBezelTamper => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_READER_BEZEL_TAMPER,
		payload,
	};
};

const setExitReaderEnableHexPassthrough = (payload: boolean): contextExitReaderSetEnableHexPassthrough => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_HEX_PASSTHROUGH,
		payload,
	};
};

const setExitReaderEnableMatchBezelTamper = (payload: boolean): contextExitReaderSetEnableMatchBezelTamper => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_MATCH_BEZEL_TAMPER,
		payload,
	};
};

const setExitReaderFacilityCodeCardNumber = (payload: boolean): contextExitReaderSetFacilityCodeCardNumber => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FACILITY_CODE_CARD_NUMBER,
		payload,
	};
};

const setExitReaderCorporate1000 = (payload: boolean): contextExitReaderSetCorporate1000 => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CORPORATE1000,
		payload,
	};
};

const setExitReaderHexDigitUUID = (payload: boolean): contextExitReaderSetHexDigitUUID => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_HEX_DIGIT_UUID,
		payload,
	};
};

const setExitReaderEnableKeyPad = (payload: boolean): contextExitReaderSetEnableKeyPad => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_KEY_PAD,
		payload,
	};
};

const setExitReaderEnableOSDPHandshake = (payload: boolean): contextExitReaderSetEnableOSDPHandshake => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ENABLE_OSDP_HANDSHAKE,
		payload,
	};
};

const setExitReaderIsDoorWireless = (payload: boolean): contextExitReaderSetIsDoorWireless => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_IS_DOOR_WIRELESS,
		payload,
	};
};

const setExitReaderOSDPTitle = (payload: string): contextExitReaderSetOSDPTitle => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_OSDP_TITLE,
		payload,
	};
};

const setExitReaderOSDPConfirmation = (payload: string): contextExitReaderSetOSDPConfirmation => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_OSDP_CONFIRMATION,
		payload,
	};
};

const setExitReaderFirmwareConfirm = (payload: string): contextExitReaderSetFirmwareConfirm => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_CONFIRM,
		payload,
	};
};

const setExitReaderFirmwareWarning = (payload: string): contextExitReaderSetFirmwareWarning => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_WARNING,
		payload,
	};
};

const setExitReaderFirmwareTitle = (payload: string): contextExitReaderSetFirmwareTitle => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FIRMWARE_TITLE,
		payload,
	};
};

const setExitReaderTSFirmwares = (payload: SelectOption[]): contextExitReaderSetTSFirmwares => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_TS_FIRMWARES,
		payload,
	};
};

const setExitReaderCanUpdateFirmware = (payload: boolean): contextExitReaderSetCanUpdateFirmware => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CAN_UPDATE_FIRMWARE,
		payload,
	};
};

const setExitReaderApplyToAllReaders = (payload: boolean): contextExitReaderSetApplyToAllReaders => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_APPLY_TO_ALL_READERS,
		payload,
	};
};

const setExitReaderInterface = (payload: ReaderInterface): contextExitReaderSetReaderInterface => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_INTERFACE,
		payload,
	};
};

const setExitReaderCardMap = (payload: number): contextExitReaderSetCardMap => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CARD_MAP,
		payload,
	};
};

const setExitReaderThreatLevel = (payload: number): contextExitReaderSetReaderThreatLevel => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_THREAT_LEVEL,
		payload,
	};
};

const setExitReaderCCOTZThreatLevel = (payload: number): contextExitReaderSetCCOTZThreatLevel => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CCOTZ_THREAT_LEVEL,
		payload,
	};
};

const setExitReaderAntiPassback = (payload: number): contextExitReaderSetAntipassback => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ANTIPASSBACK,
		payload,
	};
};

const setExitReaderMatchAlgorithm = (payload: number): contextExitReaderSetMatchAlgorithm => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_MATCH_ALGORITHM,
		payload,
	};
};

const setExitReaderFascnHandling = (payload: number): contextExitReaderSetFascnHandling => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FASCN_HANDLING,
		payload,
	};
};

const setExitReaderAssuranceLevel = (payload: number): contextExitReaderSetAssuranceLevel => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ASSURANCE_LEVEL,
		payload,
	};
};

const setExitReaderDefaultAssuranceLevel = (payload: number): contextExitReaderSetDefaultAssuranceLevel => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_DEFAULT_ASSURANCE_LEVEL,
		payload,
	};
};

const setExitReaderToZone = (payload: number): contextExitReadeSetToZone => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_TO_ZONE,
		payload,
	};
};

const setExitReaderFromZone = (payload: number): contextExitReaderSetFromZone => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_FROM_ZONE,
		payload,
	};
};

const setExitReaderSpecialHandlingInstruction = (payload: number): contextExitReaderSetSpecialHandlingInstruction => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_SPECIAL_HANDLING_INSTRUCTION,
		payload,
	};
};

const setExitReaderCardCodeOnlyTimeZone = (payload: number): contextExitReaderSetCardCodeOnlyTimeZone => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_CARD_CODE_ONLY_TIME_ZONE,
		payload,
	};
};

const setExitReaderTSFirmware = (payload: number): contextExitReaderSetTSFirmware => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_TS_FIRMWARE,
		payload,
	};
};

const setExitReaderRS485ReaderType = (payload: number): contextExitReaderSetRS485ReaderType => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_RS485_READER_TYPE,
		payload,
	};
};

const setExitReaderUnpermittedCardCodeOnly = (payload: SelectTimeZone): contextExitReaderSetUnpermittedCardCodeOnlyTimeZone => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_UNPERMITTED_CARD_CODE_ONLY_TIME_ZONE,
		payload,
	};
};

const setExitReaderUpdateFirmwarePermissionText = (payload: string): contextExitReaderSetUpdateFirmwarePermissionText => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_UPDATE_FIRMWARE_PERMISSION_TEXT,
		payload,
	};
};

const setExitReaderErrorType = (payload: ReaderError): contextExitReaderSetReaderErrorType => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ERROR_TYPE,
		payload,
	};
};

const setExitReaderErrorMessage = (payload: string): contextExitReaderSetReaderErrorMessage => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ERROR_MESSAGE,
		payload,
	};
};

const setExitReaderError = (payload: boolean): contextExitReaderSetExitReaderError => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_READER_ERROR,
		payload,
	};
};

const setExitOSDPLEDOptions = (payload: OSDPLEDOption[]): contextExitReaderSetOSDPLEDOptions => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_OSDP_LED_OPTIONS,
		payload,
	};
};

const setExitUseCustomOSDPLEDColor = (payload: boolean): contextExitReaderSetUseCustomOSDPLEDColor => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_USE_CUSTOM_OSDP_LED_COLOR,
		payload,
	};
};

const setExitChangedOSDPLEDColor = (payload: boolean): contextExitReaderSetChangedOSDLEDColor => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_CHANGED_OSDP_LED_COLOR,
		payload,
	};
};

const setExitScrambleFactorCategories = (payload: ScrambleFactorImageCategories[]): contextExitReaderSetScrambleFactorCategories => {
	return {
		type: ExitReaderActionTypes.SET_EXIT_SCRAMBLE_FACTOR_CATEGORIES,
		payload,
	};
};

const setExitSelectedFactorImages = (payload: ScrambleFactorImageShort[]): contextExitReaderSetSelectedFactorImages => {
	return {
		payload,
		type: ExitReaderActionTypes.SET_EXIT_SELECTED_FACTOR_IMAGES,
	};
};

const setExitViewPinEntryIndicator = (payload: boolean): contextExitReaderSetViewPinEntryIndicator => {
	return {
		payload,
		type: ExitReaderActionTypes.SET_EXIT_VIEW_PIN_ENTRY_INDICATOR,
	};
};

const setExitUpdateScrambleFactorImageSelection = (payload: boolean): contextExitReaderSetUpdateScrambleFactorImageSelection => {
	return {
		payload,
		type: ExitReaderActionTypes.SET_EXIT_UPDATE_SCRAMBLEFACTOR_IMAGE_SELECTION,
	};
};

const setExitScrambleFactorErrorImageCategory = (payload: string): contextExitReaderSetScrambleFactorErrorCategory => {
	return {
		payload,
		type: ExitReaderActionTypes.SET_EXIT_SCRAMBLEFACTOR_IMAGE_ERROR_CATEGORY,
	};
};

type ReaderContext = {
	exitReaderState: Readonly<ExitReaderContextState>;
	dispatcher: React.Dispatch<ExitReaderActionContext>;
};

const StoreContext = React.createContext<ReaderContext>({ exitReaderState, dispatcher: null });

export {
	StoreContext,
	exitReaderContext,
	exitReaderState,
	initExitReaderAction,
	setExitChangedOSDPLEDColor,
	setExitOSDPLEDOptions,
	setExitReaderAllegionLockAddress,
	setExitReaderAntiPassback,
	setExitReaderAnyReaderOptions,
	setExitReaderApplyToAllReaders,
	setExitReaderAssuranceInfo,
	setExitReaderAssuranceLevel,
	setExitReaderAssuranceLevels,
	setExitReaderCCOTZAssurance,
	setExitReaderCCOTZThreatLevel,
	setExitReaderCanUpdateFirmware,
	setExitReaderCardCodeOnlyTimeZone,
	setExitReaderCardMap,
	setExitReaderCardMaps,
	setExitReaderCardReaderOptions,
	setExitReaderControllerId,
	setExitReaderCorporate1000,
	setExitReaderDefaultAssuranceLevel,
	setExitReaderDefaultAssuranceLevels,
	setExitReaderEACAddress,
	setExitReaderEnableHexPassthrough,
	setExitReaderEnableKeyPad,
	setExitReaderEnableMatchBezelTamper,
	setExitReaderEnableOSDPHandshake,
	setExitReaderEnableReaderBezelTamper,
	setExitReaderError,
	setExitReaderErrorMessage,
	setExitReaderErrorType,
	setExitReaderFacilityCodeCardNumber,
	setExitReaderFascnHandling,
	setExitReaderFirmware,
	setExitReaderFirmwareConfirm,
	setExitReaderFirmwareTitle,
	setExitReaderFirmwareWarning,
	setExitReaderFromZone,
	setExitReaderGenericTimeZones,
	setExitReaderHexDigitUUID,
	setExitReaderIndex,
	setExitReaderInterface,
	setExitReaderIsControllerMatch,
	setExitReaderIsControllerSNIB3,
	setExitReaderIsDoorWireless,
	setExitReaderIsFICAMEnabled,
	setExitReaderIsOnboardWiegandAvailable,
	setExitReaderIsReaderWireless,
	setExitReaderLabels,
	setExitReaderMatchAlgorithm,
	setExitReaderName,
	setExitReaderOSDPAddress,
	setExitReaderOSDPConfirmation,
	setExitReaderOSDPTitle,
	setExitReaderOkDegrade,
	setExitReaderPassbackZones,
	setExitReaderRS485ReaderType,
	setExitReaderRS485ReaderTypes,
	setExitReaderScramblePadKeyOptions,
	setExitReaderSpecialHandlingInstruction,
	setExitReaderSpecialHandlingInstructions,
	setExitReaderSupportedReaderClass,
	setExitReaderTSFirmware,
	setExitReaderTSFirmwares,
	setExitReaderThreatLevel,
	setExitReaderThreatLevels,
	setExitReaderToZone,
	setExitReaderUnpermittedCardCodeOnly,
	setExitReaderUpdateFirmwarePermissionText,
	setExitReaderWL2HubAddress,
	setExitReaderWLHubAddress,
	setExitScrambleFactorCategories,
	setExitScrambleFactorErrorImageCategory,
	setExitSelectedFactorImages,
	setExitUpdateScrambleFactorImageSelection,
	setExitUseCustomOSDPLEDColor,
	setExitViewPinEntryIndicator,
};

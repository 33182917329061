import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { buildColumn, ScrollType } from '../../../../../../../Helper';
import { ControllerDoor, DataTableType, GenericTimeZone } from '../../../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../../../common';
import { ControllersSelected, setErrors, setReadersPreSelected, StoreContext } from '../../context';
import styles from './doorsGrid.module.scss';

type Props = {
	reset: boolean;
	onSelectAll: (checked: boolean) => void;
};

type DataType = DataTableType<ControllerDoor & { index: number }>;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 156 };

const DoorsGrid: React.FC<Props> = ({ reset, onSelectAll }) => {
	const {
		readerState: {
			loading,
			readers,
			timeZoneSelected,
			readersSelected,
			displayMyNetworkLayout,
			readersPreSelected,
			errors: { readerInput },
		},
		dispatcher,
	} = useContext(StoreContext);

	useEffect(() => {
		setSelectedRowKeys([]);
		dispatcher(setReadersPreSelected([]));
	}, [displayMyNetworkLayout]);

	useEffect(() => {
		const notSelectedKeys = selectedRowKeys.filter(x => readersSelected.findIndex(f => f.ReaderId === x) < 0);
		setSelectedRowKeys(notSelectedKeys);

		//set doors preselected on selected keys for avoiding to add unselected items
		if (selectedRowKeys.length > 0) {
			const zone: GenericTimeZone = {
				GenericTimeZoneId: timeZoneSelected.GenericTimeZoneId,
				GlobalTimeZoneId: timeZoneSelected.GlobalTimeZoneId,
				Name: timeZoneSelected.Name,
				Type: timeZoneSelected.Type,
			};
			const selected: ControllersSelected[] = readersPreSelected
				.filter(x => selectedRowKeys.indexOf(x.ReaderId) >= 0)
				.map(x => ({
					Address: x.Address,
					GenericTimeZoneId: zone.GenericTimeZoneId,
					ReaderId: x.ReaderId,
					GlobalTimeZoneId: zone.GlobalTimeZoneId,
					ControllerId: x.ControllerId,
					ControllerName: x.ControllerName,
					ReaderIndex: x.ReaderIndex,
					ReaderName: x.ReaderName,
					BelongToDoor: x.BelongToDoor,
					DoorName: x.DoorName,
					Name: zone.Name,
					Type: zone.Type,
					isInUse: x.isInUse,
				}));
			dispatcher(setReadersPreSelected([...selected]));
		}
	}, [readersSelected.length]);

	const [selectedRowKeys, setSelectedRowKeys] = useState([] as React.Key[]);

	//keep until any bug has been found
	// const handelSelectedReader = (reader: ControllerDoor, isChecked: boolean) => {
	// 	if (isChecked) {
	// 		const zone: GenericTimeZone = {
	// 			Type: timeZoneSelected?.Type ?? null,
	// 			GenericTimeZoneId: timeZoneSelected?.GenericTimeZoneId ?? null,
	// 			GlobalTimeZoneId: timeZoneSelected?.GlobalTimeZoneId ?? null,
	// 			Name: timeZoneSelected?.Name ?? null,
	// 		};
	// 		const preSelected: ControllersSelected[] = readersPreSelected.map(x => ({
	// 			...x,
	// 			...zone,
	// 		}));
	// 		const newReader: ControllersSelected = {
	// 			...reader,
	// 			...zone,
	// 		};
	// 		dispatcher(setReadersPreSelected([...preSelected, newReader]));
	// 		dispatcher(setErrors({ type: 'readerInput', value: false }));
	// 	} else {
	// 		const cloneState = readersPreSelected;
	// 		const findIndex = cloneState.findIndex(x => x.ReaderId === reader.ReaderId);
	// 		if (~findIndex) {
	// 			cloneState.splice(findIndex, 1);
	// 			dispatcher(setReadersPreSelected([...cloneState]));
	// 		}
	// 	}
	// };

	const handleCheckAllReaders = (checked: boolean) => {
		onSelectAll(checked);
		if (checked) {
			const selected: ControllersSelected[] = readers.map(x => ({
				...x,
				Type: timeZoneSelected?.Type ?? null,
				GenericTimeZoneId: timeZoneSelected?.GenericTimeZoneId ?? null,
				GlobalTimeZoneId: timeZoneSelected?.GlobalTimeZoneId ?? null,
				Name: timeZoneSelected?.Name ?? null,
			}));
			dispatcher(setReadersPreSelected([...selected]));
			dispatcher(setErrors({ type: 'readerInput', value: false }));
		} else {
			dispatcher(setReadersPreSelected([]));
		}
	};

	const handleClick = (door: DataType) => {
		let selectedRows: React.Key[] = [];
		let doorSelected: ControllersSelected[] = [];
		if (selectedRowKeys.find(x => x === door.key)) {
			selectedRows = selectedRowKeys.filter(doorSelected => doorSelected !== door.key);
			doorSelected = readersPreSelected.filter(doorSelected => doorSelected.ReaderId !== door.ReaderId);
		} else {
			selectedRows = [...selectedRowKeys, door.key];
			const zone: GenericTimeZone = {
				Type: timeZoneSelected?.Type ?? null,
				GenericTimeZoneId: timeZoneSelected?.GenericTimeZoneId ?? null,
				GlobalTimeZoneId: timeZoneSelected?.GlobalTimeZoneId ?? null,
				Name: timeZoneSelected?.Name ?? null,
			};
			const newDoor: ControllersSelected = {
				...door,
				...zone,
			};
			doorSelected = [...readersPreSelected, newDoor];
		}
		setSelectedRowKeys(selectedRows);
		dispatcher(setReadersPreSelected(doorSelected));
		dispatcher(setErrors({ type: 'readerInput', value: false }));
	};

	const buildColumns = () => {
		let columns: ColumnsType<any> = [
			{
				...buildColumn(_('Index'), 'ReaderIndex', '80px', 'start'),
				sorter: (a, b) => a.ReaderIndex - b.ReaderIndex,
			},
			{
				...buildColumn(_('Doors'), 'DoorName', 'auto', 'start'),
				sorter: (a, b) => a.DoorName.localeCompare(b.DoorName),
				...SearchColumn({ maxLength: 64, dataIndex: 'DoorName', reset: false, label: _('DoorName') }),
			},
		];

		if (!displayMyNetworkLayout) {
			columns = [
				{
					...buildColumn(_('Address'), 'Address', '210px', 'start'),
					sorter: (a, b) => a.Address.localeCompare(b.Address),
				},
				...columns,
			];
		}

		return columns;
	};

	// rowSelection object indicates the need for row selection
	const rowSelection: TableRowSelection<DataType> = {
		selectedRowKeys,
		onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			setSelectedRowKeys(selectedRowKeys);
			const zone: GenericTimeZone = {
				Type: timeZoneSelected?.Type ?? null,
				GenericTimeZoneId: timeZoneSelected?.GenericTimeZoneId ?? null,
				GlobalTimeZoneId: timeZoneSelected?.GlobalTimeZoneId ?? null,
				Name: timeZoneSelected?.Name ?? null,
			};
			const selections: ControllersSelected[] = selectedRows.map(x => ({
				...x,
				...zone,
			}));
			dispatcher(setReadersPreSelected([...selections]));
			dispatcher(setErrors({ type: 'readerInput', value: false }));
		},
		getCheckboxProps: record => ({ id: `doorGroupModalDoorsCheckbox-${record.index}` }),
		onSelectAll: (selected: boolean) => {
			handleCheckAllReaders(selected);
		},
	};

	const createData = (data: ControllerDoor[]) =>
		data.map<DataType>((dataItem: ControllerDoor, index: number) => ({
			...dataItem,
			key: dataItem.ReaderId,
			index,
		}));

	const excludeSelectedDoors = () => createData(readers).filter(x => readersSelected.findIndex(w => w.ReaderId === Number(x.key)) < 0);
	const style = !displayMyNetworkLayout ? { width: '100%' } : { undefined };
	const doorGroupDoorsTableId = 'doorGroupDoorsTable';

	return (
		<div className={cx(styles.container, { [styles.error]: readerInput })} style={style}>
			<div>
				<Table
					id={doorGroupDoorsTableId}
					rowSelection={{
						...rowSelection,
					}}
					onRow={(record: DataType) => ({
						onClick: () => handleClick(record),
					})}
					columns={buildColumns()}
					dataSource={excludeSelectedDoors()}
					scroll={scroll}
					className={cx(styles.statusWidgetTable)}
					loading={{ tip: _('Loading'), spinning: loading }}
					pagination={false}
					size='small'
					onHeaderRow={() => ({ id: `${doorGroupDoorsTableId}Header` })}
					getPopupContainer={() => document.getElementById(`${doorGroupDoorsTableId}Header`)}
				/>
			</div>
			{readerInput && <label htmlFor={doorGroupDoorsTableId}>{_('YouMustSelectAtLeastOneDoorToAddToTheDoorGroup')}</label>}
		</div>
	);
};

export { DoorsGrid };

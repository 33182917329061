import { CameraOutlined, FolderOutlined, ReloadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, InputNumber, Slider, Tooltip, Upload, UploadProps, notification } from 'antd';
import cx from 'classnames';
import 'cropperjs/dist/cropper.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper, { ReactCropperProps } from 'react-cropper';
import { getEnrollmentUploadProps, noneDecimalFormatter } from '../../../Helper';
import { SelectOptions } from '../../../model/CommonModel';
import { Logger } from '../../../model/LoggingModel';
import { Modal, Select, SelectOption } from '../../common';
import { getNextRotateMark, getThumbnailCanvasOptions, supportsHTML5Video } from '../../enrollment/helper';
import styles from './modalCropImage.module.scss';

const videoStyles: React.CSSProperties = { height: 250, width: 333 };
const signatureCropBoxData: Cropper.SetCropBoxDataOptions = { top: 178, left: 151.5, height: 44, width: 197 };
const profileSignatureOptions: Cropper.GetCroppedCanvasOptions = { width: signatureCropBoxData.width, height: signatureCropBoxData.height };

type Props = {
	imageSourceParam: string;
	isSignature?: boolean;
	useCamera?: boolean;
	isAdditionalImage?: boolean;
	optionalImgWidth?: number;
	optionalImgHeight?: number;
	uploadFormats?: string[];
	onClose: () => void;
	onSave: (croppedImageBase64: string, croppedThumbnailBase64?: string) => void;
};

const ModalCropImage: React.FC<Props> = ({
	imageSourceParam,
	isSignature,
	useCamera,
	isAdditionalImage,
	optionalImgWidth,
	optionalImgHeight,
	uploadFormats,
	onClose,
	onSave,
}) => {
	const [rotation, setRotation] = useState<number>(0);
	const [zoom, setZoom] = useState<number>(0);
	const [imageSource, setImageSource] = useState<string>(imageSourceParam);
	const [cameraOptions, setCameraOptions] = useState<SelectOption>([]);
	const [selectedCamera, setSelectedCamera] = useState<string>('');
	const videoRef = useRef<HTMLVideoElement>();
	const cropperRef = useRef<Cropper>();
	const streamRef = useRef<MediaStream>();
	const photoCropBoxData: Cropper.SetCropBoxDataOptions = { top: 93.5, left: 163.5, height: optionalImgHeight ?? 207, width: optionalImgWidth ?? 173 };
	const profilePictureOptions: Cropper.GetCroppedCanvasOptions = {
		width: photoCropBoxData.width,
		height: photoCropBoxData.height,
	};
	const aspectRatio: number = optionalImgHeight && optionalImgWidth ? optionalImgWidth / optionalImgHeight : NaN;

	useEffect(() => {
		if (useCamera) {
			if (supportsHTML5Video()) {
				navigator.mediaDevices
					.enumerateDevices()
					.then((devices: MediaDeviceInfo[]) => {
						const availableCameras: MediaDeviceInfo[] = devices.filter((deviceInfo: MediaDeviceInfo) => deviceInfo.kind === 'videoinput');
						const options: SelectOption = availableCameras.map<SelectOptions<string>>((deviceInfo: MediaDeviceInfo) => ({
							id: `cameraOption-${deviceInfo.deviceId}`,
							label: deviceInfo.label,
							value: deviceInfo.deviceId,
						}));
						setCameraOptions(options);
						if (options.length > 0) {
							handleOnChangeCamera(options[0].value.toString());
						} else {
							notification.error({
								message: _('NoCameraDetected'),
							});
							onClose();
						}
					})
					.catch(err => {
						Logger.writeErrorLog(`${err.name}: ${err.message}`);
					});
			} else {
				notification.error({
					message: _('HTMLVideoNotSupported'),
				});
				onClose();
			}
		}

		return () => {
			destroyMediaObjects();
		};
	}, []);

	const destroyMediaObjects = (): void => {
		if (streamRef.current) {
			streamRef.current.getTracks().forEach(track => {
				track.stop();
			});
		}
	};

	const handleOnSaveCroppedImage = useCallback(async () => {
		if (cropperRef) {
			const croppedCanvasOptions: Cropper.GetCroppedCanvasOptions = isSignature ? profileSignatureOptions : profilePictureOptions;
			if (isAdditionalImage) {
				const thumbnailOptions: Cropper.GetCroppedCanvasOptions = getThumbnailCanvasOptions(cropperRef.current.getImageData());
				const croppedThumbnailBase64 = cropperRef.current.getCroppedCanvas(thumbnailOptions).toDataURL('image/jpeg', 0.9).split(',')[1];
				const croppedImageBase64 = cropperRef.current.getCroppedCanvas(croppedCanvasOptions).toDataURL('image/jpeg', 1).split(',')[1];
				onSave(croppedImageBase64, croppedThumbnailBase64);
			} else {
				onSave(cropperRef.current.getCroppedCanvas(croppedCanvasOptions).toDataURL('image/jpeg', 1).split(',')[1]);
			}
		}
	}, [imageSource, rotation, zoom]);

	const handleOnChangeZoom = (zoom: number): void => {
		setZoom(zoom);
		const realZoom: number = zoom / 100;
		cropperRef.current.zoomTo(Number(realZoom.toFixed(2)));
	};

	const handleOnChangeRotation = (rotation: number): void => {
		if (rotation != undefined) {
			cropperRef.current.rotateTo(rotation);
			setRotation(rotation);
		}
	};

	const handleOnClickZoomIn = (): void => {
		cropperRef.current.zoom(0.01);
	};

	const handleOnClickZoomOut = (): void => {
		cropperRef.current.zoom(-0.01);
	};

	const handleOnClickRotate = (): void => {
		handleOnChangeRotation(getNextRotateMark(rotation));
	};

	const uploadProps: UploadProps = getEnrollmentUploadProps((source: string) => {
		setRotation(0);
		setImageSource(source);
	}, uploadFormats);

	const handleOnCapturePhoto = (): void => {
		setTimeout(() => {
			const canvas: HTMLCanvasElement = document.createElement('canvas');
			const canvasContext: CanvasRenderingContext2D | null = canvas.getContext('2d');

			let ratio: number = 0; // Used for aspect ratio
			const maxWidth = Number(videoStyles.width); // Max width for the image
			const maxHeight = Number(videoStyles.height); // Max height for the image

			let width: number = videoRef.current.videoWidth; // Current image width
			let height: number = videoRef.current.videoHeight; // Current image height

			// Check if the current width is larger than the max
			if (width > maxWidth) {
				ratio = maxWidth / width; // get ratio for scaling image
				height = height * ratio; // Reset height to match scaled image
				width = width * ratio; // Reset width to match scaled image
			}

			// Check if current height is larger than max
			if (height > maxHeight) {
				ratio = maxHeight / height; // get ratio for scaling image
				width = width * ratio; // Reset width to match scaled image
				height = height * ratio; // Reset height to match scaled image
			}

			canvas.width = width;
			canvas.height = height;
			canvasContext.canvas.width = width;
			canvasContext.canvas.height = height;

			canvasContext.drawImage(videoRef.current, 0, 0, width, height);
			setRotation(0);
			setImageSource(canvas.toDataURL());
		}, 500);
	};

	const handleOnZoom = e => {
		if (e.detail.ratio > 10) {
			e.preventDefault();
			cropperRef.current.zoomTo(9.9999);
			setZoom(999);
		} else if (e.detail.ratio < 0) {
			e.preventDefault();
			cropperRef.current.zoomTo(0);
			setZoom(0);
		} else {
			let newZoom = e.detail.ratio * 100;
			newZoom = Number(newZoom.toString().split('.')[0]);
			setZoom(newZoom);
		}
	};

	const handleOnReady = e => {
		if (useCamera && imageSource) {
			const videoCropBoxData: Cropper.SetCropBoxDataOptions = { ...photoCropBoxData, top: 21.5, left: 80 };
			cropperRef.current.setCropBoxData(videoCropBoxData);
		} else {
			cropperRef.current.setCropBoxData(isSignature ? signatureCropBoxData : photoCropBoxData);
		}
	};

	const handleOnCameraError = error => {
		if (error.name == 'NotAllowedError') {
			notification.error({
				message: _('CameraNotAllowed'),
			});
			onClose();
		} else {
			Logger.writeErrorLog(`Video capture error: ${error}`);
		}
	};

	const handleOldBrowserMediaDevice = (videoObj: MediaStreamConstraints): void => {
		const navigatorPolyfill = navigator as any;
		if (navigatorPolyfill.mediaDevices === undefined) {
			navigatorPolyfill.mediaDevices = {};
		}

		if (navigatorPolyfill.mediaDevices.getUserMedia === undefined) {
			navigatorPolyfill.mediaDevices.getUserMedia = constraints => {
				const getUserMedia = navigatorPolyfill.webkitGetUserMedia || navigatorPolyfill.mozGetUserMedia;
				if (!getUserMedia) {
					return Promise.reject(new Error(_('GetUserMediaNotImplemented')));
				}

				return new Promise(function (resolve, reject) {
					getUserMedia.call(navigatorPolyfill, constraints, resolve, reject);
				});
			};
		}

		navigatorPolyfill.mediaDevices
			.getUserMedia(videoObj)
			.then(stream => {
				if ('srcObject' in videoRef.current) {
					videoRef.current.srcObject = stream;
				} else {
					// TODO: Fix this later
					/* videoRef.current.src = window.URL.createObjectURL(stream); */
				}

				videoRef.current.onloadedmetadata = e => {
					videoRef.current.play();
				};
				streamRef.current = stream;
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
			});
	};

	const handleOnChangeCamera = (deviceId: string): void => {
		destroyMediaObjects();
		setSelectedCamera(deviceId);

		const videoObj: MediaStreamConstraints = {
			video: {
				deviceId: deviceId.toString(),
			},
			audio: false,
		};

		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia(videoObj)
				.then(stream => {
					videoRef.current.srcObject = stream;
					videoRef.current.play();
					streamRef.current = stream;
				})
				.catch(handleOnCameraError);
		} else {
			handleOldBrowserMediaDevice(videoObj);
		}
	};

	const cropperProps: ReactCropperProps =
		useCamera && imageSource
			? {
					zoomTo: 1,
					className: styles.cropperComponent,
					style: videoStyles,
			  }
			: {
					zoomTo: 0.5,
					className: styles.cropperComponent,
					aspectRatio,
			  };

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={onClose}
			onClickOk={() => null}
			width={600}
			title={useCamera ? _('CapturePhoto') : _('PreviewImage')}
			visible={true}
			className={styles.container}
			footer={[
				<Button id='cropModalSaveCropImageButton' key='ok' type='primary' onClick={handleOnSaveCroppedImage} disabled={imageSource === ''}>
					{_('Save')}
				</Button>,
				<Button id='cropModalCancelCropImage' key='cancel' onClick={onClose}>
					{_('Cancel')}
				</Button>,
			]}>
			<div className={styles.content}>
				<div className={styles.cropperContainer}>
					<div className={styles.cropperContent}>
						{useCamera && (
							<>
								{cameraOptions.length > 1 && (
									<div className={cx({ [styles.displayCamera]: imageSource })}>
										<label htmlFor='cropModalCameraDropdown'>{_('Camera')}</label>
										<Select
											id='cropModalCameraDropdown'
											options={cameraOptions}
											value={selectedCamera}
											onChange={handleOnChangeCamera}
											className={styles.cameraDropdown}
										/>
									</div>
								)}
								<video id='cropModalVideo' ref={videoRef} style={videoStyles} className={cx({ [styles.displayCamera]: imageSource })} />
							</>
						)}
						{imageSource && (
							<Cropper
								{...cropperProps}
								cropBoxResizable={false}
								initialAspectRatio={1}
								src={imageSource}
								viewMode={1}
								background={true}
								responsive={true}
								autoCropArea={1}
								checkOrientation={false}
								onInitialized={(instance: Cropper) => {
									cropperRef.current = instance;
								}}
								guides={true}
								ready={handleOnReady}
								zoom={handleOnZoom}
								dragMode={'move'}
							/>
						)}
						{useCamera && (
							<div>
								<Tooltip title={_('CapturePhoto')} placement='bottom'>
									<Button id='cropModalCaptureButton' icon={<CameraOutlined />} onClick={handleOnCapturePhoto} />
								</Tooltip>
								<Button
									id='cropModalResetCaptureButton'
									onClick={() => {
										setRotation(0);
										setImageSource('');
									}}>
									{_('Reset')}
								</Button>
							</div>
						)}
					</div>
					{!useCamera && (
						<div>
							<Tooltip title={_('ChangePhoto')} placement='bottom'>
								<Upload {...uploadProps}>
									<Button id='cropModalUploadImageButton' icon={<FolderOutlined />} />
								</Upload>
							</Tooltip>
						</div>
					)}
				</div>
				<div className={styles.optionsContainer}>
					{imageSource && (
						<>
							<div>
								<label htmlFor='cropModalZoomInput'>{_('Zoom')}</label>
								<div className={styles.zoomSlider}>
									<Button id='cropModalZoomOutButton' icon={<ZoomOutOutlined />} onClick={handleOnClickZoomOut} />
									<Slider
										id='cropModalZoomSlider'
										min={0}
										max={999}
										onChange={handleOnChangeZoom}
										value={zoom}
										step={1}
										aria-labelledby='Zoom'
									/>
									<Button id='cropModalZoomInButton' icon={<ZoomInOutlined />} onClick={handleOnClickZoomIn} />
								</div>
								<InputNumber
									id='cropModalZoomInput'
									min={0}
									max={999}
									value={zoom}
									onChange={handleOnChangeZoom}
									formatter={value => `${noneDecimalFormatter(value)}%`}
									parser={value => Number(value.replace(/\D+/g, ''))}
									className={styles.inputNumber}
									maxLength={4}
								/>
							</div>
							<div>
								<label htmlFor='cropModalRotateInput'>{_('Rotation')}</label>
								<div className={styles.rotationSlider}>
									<Slider
										id='cropModalRotateSlider'
										min={0}
										max={360}
										onChange={handleOnChangeRotation}
										value={typeof rotation === 'number' ? rotation : 0}
										aria-labelledby='Rotation'
									/>
									<Button icon={<ReloadOutlined />} onClick={handleOnClickRotate} />
								</div>
								<InputNumber
									id='cropModalRotateInput'
									min={0}
									max={360}
									value={rotation}
									onChange={handleOnChangeRotation}
									formatter={value => `${noneDecimalFormatter(value)}°`}
									parser={value => Number(value.replace(/\D+/g, ''))}
									className={styles.inputNumber}
									maxLength={4}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
};

export { ModalCropImage };

import { ConfigProvider, notification } from 'antd';
import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
// Style
import './resources/style/main.scss';
import { store } from './store';

notification.config({
	duration: 3,
});

const colorPrimary = '#406080';
const colorPrimaryHover = '#5d768c';
const colorPrimaryActive = '#283f59';
const colorButtonDefaultBg = '#a0c0e0';
const transparent = 'rgba(255, 255, 255, 0)';
const container = document.getElementById('root');

ReactDOM.render(
	<Provider store={store}>
		<ConfigProvider
			theme={{
				token: {
					borderRadius: 2,
					borderRadiusLG: 2,
					borderRadiusOuter: 2,
					borderRadiusSM: 2,
					borderRadiusXS: 2,
					colorLink: colorPrimary,
					colorLinkHover: '#5d768c',
					colorPrimary,
					wireframe: false,
				},
				components: {
					Button: {
						colorPrimary: colorPrimary,
						colorText: '#103070',
						colorPrimaryHover: colorPrimaryHover,
						colorPrimaryActive: colorPrimaryActive,
						colorBgContainer: colorButtonDefaultBg,
						borderRadius: 2,
					},
					Modal: {
						headerBg: '#f0f0f0',
						wireframe: true,
					},
					Radio: {
						colorBgContainer: colorButtonDefaultBg,
						controlItemBgActive: colorButtonDefaultBg,
					},
					Select: {
						controlItemBgActive: colorPrimary,
						paddingXXS: 0,
					},
					Table: {
						fontWeightStrong: 500,
						colorIcon: '#bfbfbf',
						colorIconHover: '#a6a6a6',
						colorBorderSecondary: 'rgba(0, 0, 0, 0.06)',
						borderRadiusLG: 0,
					},
					Card: {
						boxShadowTertiary: 'none',
					},
					Breadcrumb: {
						linkColor: colorPrimary,
						linkHoverColor: colorPrimaryHover,
						colorBgTextHover: transparent,
					},
					Menu: {
						paddingXXS: 0,
					},
					Dropdown: {
						paddingXXS: 0,
					},
					Pagination: {
						paddingXXS: 0,
					},
					Checkbox: {
						colorPrimary: colorPrimary,
						colorBgContainerDisabled: '#f5f5f5',
					},
				},
			}}>
			<App />
		</ConfigProvider>
	</Provider>,
	container
);

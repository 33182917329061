import { UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react';
import { Logger } from '../../../model/LoggingModel';
import { WidgetInfo } from '../../../model/StatusModel';
import { DoorStatusTable } from '../DoorStatusTable/DoorStatusTable';
import './doorStatus.scss';

const { Panel } = Collapse;

// Internal state for the component
interface State {
	isExpanded: boolean;
}

// Normal properties for the component
interface OwnProps {
	widgetInfo: WidgetInfo;
}

// combine them together
type Props = OwnProps;

class DoorStatus extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isExpanded: true,
		};
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	handleOnChange(isExpanded) {
		this.setState({
			isExpanded,
		});
	}

	render() {
		const { isExpanded } = this.state;
		const { widgetInfo } = this.props;

		return (
			<div className='door-status-container'>
				<Collapse
					bordered={true}
					defaultActiveKey={'1'}
					expandIconPosition='right'
					expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
					onChange={(keys: string[]) => {
						this.handleOnChange(keys.length === 1);
					}}
					className={'status-widget-collapse'}>
					<Panel id='statusDashboardDoorStatusWidget' key='1' header={_('DoorsStatus')} className='status-widget status-widget-collapse-content'>
						{isExpanded && <DoorStatusTable isExpanded={isExpanded} widgetInfo={widgetInfo} />}
					</Panel>
				</Collapse>
			</div>
		);
	}
}

export { DoorStatus };

import { InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { AlarmRelayTimerInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerMiscSystemSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerMisc } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectAlarmRelayTimerInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../setup.module.scss';

const AlarmRelayTimers: React.FC = () => {
	const dispatch = useStoreDispatch();
	const alarmRelayTimersInfo: AlarmRelayTimerInfoStore = useStoreSelector<AlarmRelayTimerInfoStore>(selectAlarmRelayTimerInfo);

	const handleChangeControllerMiscSystemSetup = (value: number, key: keyof ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				[key]: value,
			})
		);
	};

	return (
		<CardSection title={_('AlarmRelayTimers')}>
			<div className={styles.alarmRelayTimersContainer}>
				<div />
				<span>{_('Timer')}:</span>
				<div />
				<span>{_('MapToRelay')}:</span>
				<span>{_('General')}:</span>
				<InputNumber
					id='controllerSetupAlarmGeneralInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayGeneralTimer')}
					value={alarmRelayTimersInfo.general.timer}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
				<InputNumber
					id='controllerSetupAlarmGeneralToRelayInput'
					min={0}
					max={72}
					maxLength={2}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayGeneralMapToRelay')}
					value={alarmRelayTimersInfo.general.mapToRelay}
					formatter={noneDecimalFormatter}
				/>
				<span>{_('Duress')}:</span>
				<InputNumber
					id='controllerSetupAlarmDuressInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayDuressTimer')}
					value={alarmRelayTimersInfo.duress.timer}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
				<InputNumber
					id='controllerSetupAlarmDuressToRelayInput'
					min={0}
					max={72}
					maxLength={2}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayDuressMapToRelay')}
					value={alarmRelayTimersInfo.duress.mapToRelay}
					formatter={noneDecimalFormatter}
				/>

				<span>{_('Tamper')}:</span>
				<InputNumber
					id='controllerSetupAlarmTamperInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayTamperTimer')}
					value={alarmRelayTimersInfo.tamper.timer}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
				<InputNumber
					id='controllerSetupAlarmTamperToRelayInput'
					min={0}
					max={72}
					maxLength={2}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayTamperMapToRelay')}
					value={alarmRelayTimersInfo.tamper.mapToRelay}
					formatter={noneDecimalFormatter}
				/>

				<span>{_('Trouble')}:</span>
				<InputNumber
					id='controllerSetupAlarmTroubleInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayTroubleTimer')}
					value={alarmRelayTimersInfo.trouble.timer}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
				<InputNumber
					id='controllerSetupAlarmTroubleToRelayInput'
					min={0}
					max={72}
					maxLength={2}
					onChange={value => handleChangeControllerMiscSystemSetup(Number(value), 'AlarmRelayTroubleMapToRelay')}
					value={alarmRelayTimersInfo.trouble.mapToRelay}
					formatter={noneDecimalFormatter}
				/>
			</div>
		</CardSection>
	);
};

export { AlarmRelayTimers };

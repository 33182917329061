import { Button, notification, Spin } from 'antd';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import { getPermissionErrorMessage, SecuredComponents, User } from '../../../../../model/AccountModel';
import { PaginationSetting, ResponseObject, ResponseStatusCode } from '../../../../../model/CommonModel';
import { DeviceObjectType, NewControllerData, PortConnectionType } from '../../../../../model/DeviceAdminModel';
import { Logger } from '../../../../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setCurrentDevice } from '../../../../../store/common/actions';
import { setControllerContacts } from '../../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../../store/deviceControl/selectors';
import { Modal, ModalError, ModalWarning, NotificationStatus } from '../../../../common';
import {
	addControllerContext,
	addControllerState,
	initAddController,
	setAddress,
	setAvailableAddress,
	setXBox,
	setXBoxes,
	StoreContext,
} from './AddControllerContext';
import styles from './addControllerModal.module.scss';
import { BasicAddControllerInformation } from './Sections/BasicAddControllerInformation';

const user: User = getUser();
const permissionsEnabled: boolean = User.getComponentPermission(user, SecuredComponents.Controller).canUpdate;

type Props = {};

const AddControllerModal: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();

	const [stateContext, dispatchActionContext] = useReducer(addControllerContext, addControllerState);

	const contextValue = useMemo(() => {
		return { addControllerState: stateContext, dispatcher: dispatchActionContext };
	}, [stateContext, dispatchActionContext]);

	const [submitted, setSubmit] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [disableAddress, setDisableAddress] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const {
		loadSetupsFromController,
		ports,
		name,
		description,
		enabled,
		useDaylightSavingsTime,
		availableAddresses,
		selections: { portId, xBoxId, controllerType, address, wirelessVendor, timeZoneDisplayName, timeZoneStandardName },
	} = stateContext;

	const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	useEffect(() => {
		if (~xBoxId) {
			deviceAdminApi.getAvailableControllerAddress(portId, xBoxId).then(res => {
				if (res.ResponseStatusCode === ResponseStatusCode.Success) {
					dispatchActionContext(setAvailableAddress(res.Entity));
					dispatchActionContext(setAddress(0));
					setDisableAddress(portId === 0);
				} else {
					ModalWarning({
						content: <p className={styles.warningMessages}>{res.ErrorMessage}</p>,
						hideCancelButton: true,
						okText: _('Ok'),
						width: '600px',
					});
					dispatchActionContext(setAvailableAddress([]));
					setDisableAddress(true);
				}
			});
		}
	}, [xBoxId]);

	useEffect(() => {
		if (~portId) {
			const portIndex: number = ports.findIndex(x => x.Id === portId);
			if (~portIndex) {
				if (ports[portIndex].PortConnectionType === PortConnectionType.XNET) {
					deviceAdminApi.getControllerXBoxes(portId).then(res => {
						dispatchActionContext(setXBoxes(res));
						dispatchActionContext(setXBox(0));
						setDisableAddress(true);
					});
				} else {
					deviceAdminApi.getAvailableControllerAddress(portId, 0).then(res => {
						if (res.ResponseStatusCode === ResponseStatusCode.Success) {
							dispatchActionContext(setAvailableAddress(res.Entity));
							dispatchActionContext(setAddress(0));
							setDisableAddress(false);
						} else {
							ModalWarning({
								content: <p className={styles.warningMessages}>{res.ErrorMessage}</p>,
								hideCancelButton: true,
								okText: _('Ok'),
								width: '600px',
							});
							dispatchActionContext(setAvailableAddress([]));
							setDisableAddress(true);
						}
					});
				}
			}
		}
	}, [portId]);

	useEffect(() => {
		deviceAdminApi
			.getAddController()
			.then(res => {
				dispatchActionContext(initAddController(res));
			})
			.catch(e => {
				Logger.writeErrorLog(e);
			})
			.finally(() => setIsLoading(false));
	}, []);

	const handleAddEditXbox = async () => {
		setSubmit(true);
		if (portId === 0) {
			return;
		}

		const portIndex: number = ports.findIndex(x => x.Id === portId);
		if (~portIndex) {
			if (ports[portIndex].PortConnectionType === PortConnectionType.XNET && xBoxId === 0) {
				return;
			}
		}

		let selectedAddress = address;
		if (selectedAddress === 0 && availableAddresses.length >= 2) {
			selectedAddress = availableAddresses[1]?.Id;
		}

		const controllerData: NewControllerData = {
			PortId: portId,
			XboxId: xBoxId,
			Name: name,
			ControllerType: controllerType,
			Address: selectedAddress,
			WirelessVendor: wirelessVendor,
			LoadSetupsFromController: loadSetupsFromController,
			Description: description,
			Enabled: enabled,
			TimeZoneDisplayName: timeZoneDisplayName,
			TimeZoneStandardName: timeZoneStandardName,
			UseDaylightSavingsTime: useDaylightSavingsTime,
		};
		const response: ResponseObject = await deviceAdminApi.addController(controllerData);

		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;
		const toDispatch = setControllerContacts(DeviceObjectType.Controller, paginationSetting);

		NotificationStatus({
			responseData: response,
			notUseDefaultNotification: true,
			onSuccessCallback: () => {
				setSubmit(false);
				dispatch(toDispatch);
				handleCloseModal();
			},
			onFailedValidation: () => {
				setSubmit(false);
				if (response.AdditionalResponseInfo) {
					ModalError({
						content: <p>{response.ErrorMessage}</p>,
						okText: _('Ok'),
						width: '600px',
					});
				} else {
					setErrorMessage(response.ErrorMessage);
				}
			},
			onPermissionErrorCallback: () => {
				notification['error']({
					message: response.ErrorMessage,
				});
			},
			onDatabaseAccessCallback: () => {
				notification['error']({
					message: response.ErrorMessage,
				});
			},
		});
	};

	const handleCloseModal = (): void => {
		let newDevice = { Id: 0, DeviceObjectType: DeviceObjectType.Controller, address: '', IsModalOpen: false };
		dispatch(setCurrentDevice(newDevice));
	};

	return (
		<StoreContext.Provider value={contextValue}>
			<Modal
				footer={[
					<Button
						key='save'
						type='primary'
						title={getPermissionErrorMessage(permissionsEnabled)}
						disabled={!permissionsEnabled || availableAddresses.length === 1 || submitted}
						onClick={() => handleAddEditXbox()}>
						{_('SaveChanges')}
					</Button>,
					<Button key='cancel' onClick={handleCloseModal}>
						{_('Cancel')}
					</Button>,
				]}
				visible={true}
				title={_('AddController')}
				onCancel={handleCloseModal}
				onClickOk={() => null}
				width={'900px'}
				customZoomClass={styles.withModalZoom}>
				<Spin wrapperClassName={styles.spin} size='large' tip={`${_('Loading')}...`} spinning={isLoading}>
					<div className={styles.container}>
						<p className={styles.addControllerTitles}>{_('BasicControllerInformation')} </p>
						<BasicAddControllerInformation
							resetSubmit={() => setSubmit(false)}
							resetErrorMessage={() => setErrorMessage('')}
							submitted={submitted}
							errorMessage={errorMessage}
							disableAddress={disableAddress}
						/>
					</div>
				</Spin>
			</Modal>
		</StoreContext.Provider>
	);
};

export { AddControllerModal };

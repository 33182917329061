import { SecuredComponents } from '../model/AccountModel';
import { ResponseObjectEntity } from '../model/CommonModel';
import {
	ApplicationSetting,
	ColumnConfiguration,
	ConfigurationAction,
	ConfigurationState,
	ProfileSetting,
	VersionInformation,
} from '../model/ConfigurationModel';
import { PhotoCallUpUserConfiguration } from '../model/PhotoCallUpModel';
import { Api } from './api';

class ConfigurationApi extends Api {
	public constructor() {
		//NEVER FORGET THE SUPER
		super();
	}

	public savePhotoCallUpConfiguration = async (photoCallUpUserConfiguration: PhotoCallUpUserConfiguration): Promise<boolean> => {
		const url = this.getUrl('Configuration/SaveSettingsChanges', true);
		const createItem = (name: string, value: any): ProfileSetting => {
			return {
				Name: name,
				Value: value,
				Label: null,
				ObjectId: 17,
				SelectedValue: false,
			};
		};
		const data: ProfileSetting[] = [
			createItem('PhotoCallUpShowAvailableDoors', JSON.stringify(photoCallUpUserConfiguration.SelectedDoors.map<string>(x => x.DoorId.toString()))),
			createItem('PhotoCallUpShowAvailableCredentials', photoCallUpUserConfiguration.QuantityPerDoor),
			createItem('PhotoCallUpShowAllDoorsSection', photoCallUpUserConfiguration.ShowAllDoorsSection),
			createItem('PhotoCallUpLoadCachedEntries', photoCallUpUserConfiguration.LoadCachedEntries),
			createItem('PhotoCallUpShowDoorName', photoCallUpUserConfiguration.ShowDoorName),
			createItem('PhotoCallUpShowDateTime', photoCallUpUserConfiguration.ShowDateTime),
			createItem('PhotoCallUpShowBorderCard', photoCallUpUserConfiguration.ShowBorderCard),
			createItem('PhotoCallUpShowDoorsInFullLength', photoCallUpUserConfiguration.ShowDoorsInFullWidth),
		];

		return await this.post<boolean>(url, data);
	};

	public saveColumnsConfiguration = async (
		columnConfiguration: ColumnConfiguration,
		securedComponent: SecuredComponents
	): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('Configuration/SaveColumnsConfiguration', true);

		return await this.post<ResponseObjectEntity<boolean>>(url, { columnConfiguration, securedComponent });
	};

	public getVersionInformation = async (): Promise<VersionInformation> => {
		const url = this.getUrl('Configuration/GetVersionInformation', true);

		return await this.post<VersionInformation>(url);
	};

	public reconnectToServer = async (): Promise<boolean> => {
		const url = this.getUrl('Status/ReconnectToServer', true);

		return await this.post<boolean>(url);
	};

	public retrieveConfiguration = async (): Promise<ConfigurationState> => {
		const url = this.getUrl('Configuration/GetConfiguration', true);

		return await this.post<ConfigurationState>(url);
	};

	public retrieveCultureDateFormat = async (cultureCode: string): Promise<string> => {
		const url = this.getUrl('Configuration/GetCultureDateFormat', true);
		const data = {
			cultureCode,
		};

		return await this.post<string>(url, data);
	};

	public saveSettingChanges = async (profileSetting: ProfileSetting): Promise<ResponseObjectEntity<ConfigurationAction>> => {
		return this.saveSettingsChanges([profileSetting], []);
	};

	public saveSettingsChanges = async (
		profileSettings: ProfileSetting[],
		applicationSettings: ApplicationSetting[]
	): Promise<ResponseObjectEntity<ConfigurationAction>> => {
		const url = this.getUrl('Configuration/SaveSettingsChanges', true);
		const data = {
			profileSettings,
			applicationSettings,
		};

		return await this.post<ResponseObjectEntity<ConfigurationAction>>(url, data);
	};
}

const configurationApi = new ConfigurationApi();

export { configurationApi };

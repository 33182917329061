import React, { useContext } from 'react';
import { ReaderOptionsTab } from '../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../DoorModal/DoorStoreSingleContext';
import { setAnyReaderOptions, setCardReaderOptions, setScramblePadKeyOptions, StoreContext } from '../../contextReader';
import { OptionsCheckBoxList } from '../sections';
import styles from './options.module.scss';

type Props = {
	useDoorContext: boolean;
};

const Options: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { anyReaderOptions, cardReaderOptions, scramblePadKeyPadOptions },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { anyReaderOptions, cardReaderOptions, scramblePadKeyPadOptions },
			dispatcher,
		} = useContext(StoreContext);
	}

	const handleOnSetAnyReaderOptions = (array: ReaderOptionsTab[]) => {
		useDoorContext ? dispatcherDoor(setAnyReaderOptions(array)) : dispatcher(setAnyReaderOptions(array));
	};

	const handleOnSetCardReaderOptions = (array: ReaderOptionsTab[]) => {
		useDoorContext ? dispatcherDoor(setCardReaderOptions(array)) : dispatcher(setCardReaderOptions(array));
	};

	const handleOnSetScramblePadKeyOptions = (array: ReaderOptionsTab[]) => {
		useDoorContext ? dispatcherDoor(setScramblePadKeyOptions(array)) : dispatcher(setScramblePadKeyOptions(array));
	};

	return (
		<div className={styles.form}>
			<OptionsCheckBoxList className={styles.anyReader} label={_('AnyReader')} collection={anyReaderOptions} setArray={handleOnSetAnyReaderOptions} />
			<OptionsCheckBoxList className={styles.cardReader} label={_('CardReader')} collection={cardReaderOptions} setArray={handleOnSetCardReaderOptions} />
			<OptionsCheckBoxList
				className={styles.scrambleKeypad}
				label={_('ScrambleKeypad')}
				collection={scramblePadKeyPadOptions}
				setArray={handleOnSetScramblePadKeyOptions}
			/>
		</div>
	);
};

export { Options };

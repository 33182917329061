import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { deviceAdminApi } from '../../../../../../api';
import { buildColumn, ColumnsProps, convertArrayToObject, getDefaultTablePaginationConfig, getTimeZoneInfoByList, ScrollType } from '../../../../../../Helper';
import { ControllerACBSetup, TimeZoneInfo } from '../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { setAlarms, setControllerPropertyAction } from '../../../../../../store/deviceControl/controller/actions';
import { setControlZonesEditMode } from '../../../../../../store/deviceControl/controlZones/actions';
import { SearchColumn, Select } from '../../../../../common';
import { EditableCell, EditableCellProps } from '../../../../../common/EditableCell/EditableCell';
import styles from './alarmActions.module.scss';
import {
	alarmByIdDataSelector,
	alarmsDataSelector,
	controllerIdSelector,
	controllerTimeZoneMapSelector,
	controllerTypeSelector,
	controlZonesSelector,
	selectorEditionMode,
	timeZonesSelector,
} from './selectors';

const scroll: ScrollType = { y: 560 };

const AlarmActions: React.FC = () => {
	const alarms = useStoreSelector(alarmsDataSelector);
	const alarmsById = useStoreSelector(alarmByIdDataSelector);
	const controlZones = useStoreSelector(controlZonesSelector);
	const controlZonesMap = useStoreSelector(controllerTimeZoneMapSelector);
	const controllerId = useStoreSelector(controllerIdSelector);
	const controllerType = useStoreSelector(controllerTypeSelector);
	const controllerTimeZones = useStoreSelector(timeZonesSelector);
	const isEditionMode = useStoreSelector(selectorEditionMode);

	const dispatch = useStoreDispatch();

	const [form] = Form.useForm<ControllerACBSetup>();
	const [editingKey, setEditingKey] = useState('');
	const [initControllerACBSetup, setInitControllerACBSetup] = useState(alarms);

	const mapControlZones = controlZones.map(x => ({ label: x.Name, value: x.CZIndex }));

	const columns: ColumnsProps<ControllerACBSetup>[] = [
		{
			...buildColumn('', 'Action', editingKey !== '' ? '160px' : '100px', 'start'),
			fixed: 'left',
			render: (rest, record) => {
				const editable = isEditing(record);
				return editable ? (
					<div className={styles.flexAlign}>
						<Button id='controllerAlarmActionsApplyButton' onClick={() => handleSave(record.ACBIndex)} type='primary'>
							{_('Apply')}
						</Button>
						<Button id='controllerAlarmActionsRevertButton' onClick={() => handleCancel()}>
							{_('Revert')}
						</Button>
					</div>
				) : (
					<div className={styles.button}>
						{!isEditionMode && <Button type='primary' onClick={() => edit(record)} shape='circle' icon={<EditOutlined />} />}
					</div>
				);
			},
		},
		{
			...buildColumn('#', 'ACBIndex', '40px', 'start'),
			sorter: (a, b) => a.ACBIndex - b.ACBIndex,
			fixed: 'left',
		},
		{
			...buildColumn(_('Description'), 'ACBName', '160px', 'start'),
			sorter: (a, b) => a.ACBName.localeCompare(b.ACBName),
			...SearchColumn({ maxLength: 100, dataIndex: 'ACBName', reset: undefined, label: _('Description') }),
			fixed: 'left',
		},
		{
			...buildColumn(_('GeneralTZ'), 'GeneralTimeZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controllerTimeZones.find(x => x.GenericId === record.GeneralTimeZone).Name,
		},
		{
			...buildColumn(_('DuressTZ'), 'DuressTimeZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controllerTimeZones.find(x => x.GenericId === record.DuressTimeZone).Name,
		},
		{
			...buildColumn(_('TamperTZ'), 'TamperTimeZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controllerTimeZones.find(x => x.GenericId === record.TamperTimeZone).Name,
		},
		{
			...buildColumn(_('TroubleTZ'), 'TroubleTimeZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controllerTimeZones.find(x => x.GenericId === record.TroubleTimeZone).Name,
		},
		{
			...buildColumn(_('DialoutTZ'), 'DialoutTimeZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controllerTimeZones.find(x => x.GenericId === record.DialoutTimeZone).Name,
		},
		{
			...buildColumn(_('LogTZ'), 'LogTimeZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controllerTimeZones.find(x => x.GenericId === record.LogTimeZone).Name,
		},
		{
			...buildColumn(_('TriggerCZ'), 'TriggerControlZone', '130px', 'start'),
			editable: true,
			render: (value, record) => controlZones.find(x => x.CZIndex === record.TriggerControlZone).Name,
		},
		{
			...buildColumn(_('CZOff'), 'ControlZoneOff', '130px', 'start'),
			editable: true,
			render: (value, record) => controlZones.find(x => x.CZIndex === record.ControlZoneOff).Name,
		},
	];

	const isEditing = (record: ControllerACBSetup) => record.ACBIndex.toString() === editingKey;

	const edit = (record: Partial<ControllerACBSetup>) => {
		dispatch(setControlZonesEditMode(true));
		form.setFieldsValue({ ...record });
		setEditingKey(record.ACBIndex.toString());
	};

	const handleSave = async (key: React.Key) => {
		const row = await form.getFieldsValue();
		const item = alarms[key];

		const actionToDispatch = setAlarms({ data: { ...item, ...row }, key: key.toString() });
		dispatch(actionToDispatch);
		setEditingKey('');
		dispatch(setControlZonesEditMode(false));
	};

	const handleCancel = () => {
		setEditingKey('');
		dispatch(setControlZonesEditMode(false));
	};

	const handleUndoChanges = () => {
		const actionToDispatch = setControllerPropertyAction({
			ControllerACBSetup: { ...initControllerACBSetup },
		});
		dispatch(actionToDispatch);
	};

	const handleResetFactoryValues = () => {
		deviceAdminApi.getDefaultACBSetup(controllerId, controllerType, controlZonesMap).then(response => {
			const denormValues = convertArrayToObject(response, 'ACBIndex');
			const actionToDispatch = setControllerPropertyAction({
				ControllerACBSetup: { ...denormValues },
			});
			dispatch(actionToDispatch);
		});
	};

	const mergedColumns = columns.map(col =>
		!col.editable
			? col
			: {
					...col,
					onCell: (record: ControllerACBSetup) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						editing: isEditing(record),
					}),
			  }
	);
	const customComponent = (dataName: keyof ControllerACBSetup, record: ControllerACBSetup) => {
		const timeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(record ? record[dataName?.toString()] : 1, controllerTimeZones);

		return (
			<Form.Item name={dataName}>
				<Select
					options={dataName === 'TriggerControlZone' || dataName === 'ControlZoneOff' ? mapControlZones : timeZoneInfo.TimeZoneList}
					notUseCurrentParse
					disabled={timeZoneInfo.IsUnpermitted}
					getPopupContainer={element => element.parentElement}
				/>
			</Form.Item>
		);
	};

	const mapNormalizerArray: ControllerACBSetup[] = alarmsById.map((x, index) => ({ ...alarms[x] }));

	return (
		<div className={styles.container}>
			<div className={styles.alignButtons}>
				<Button type='primary' onClick={handleUndoChanges} disabled={isEditionMode}>
					{_('UndoChanges')}
				</Button>
				<Button type='primary' onClick={handleResetFactoryValues} disabled={isEditionMode}>
					{_('RestoreFactoryDefaults')}
				</Button>
			</div>
			<Form form={form} component={false}>
				<Table
					columns={mergedColumns as ColumnsType}
					dataSource={mapNormalizerArray}
					components={{
						body: {
							cell: (props: EditableCellProps<ControllerACBSetup>) => {
								return EditableCell({
									...props,
									customComponent: customComponent(props.dataIndex as keyof ControllerACBSetup, props.record),
								});
							},
						},
					}}
					scroll={scroll}
					pagination={getDefaultTablePaginationConfig()}
					size='small'
					rowKey={(record: ControllerACBSetup) => record.ACBIndex}
					className={styles.statusWidgetTable}
				/>
			</Form>
		</div>
	);
};

export { AlarmActions };

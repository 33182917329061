import React, { useContext } from 'react';
import { Select } from '../../../../../../common';
import styles from '../../commandSetsModal.module.scss';
import { setLocation, StoreContext } from '../../contextCommand';

const Location: React.FC = () => {
	const {
		commandState: {
			locations: { list, name },
			selections: { location },
		},
		dispatcher,
	} = useContext(StoreContext);

	const mainDivContainerId = 'CommandSetLocationContainer';
	const selectCommandSetPointsId = 'commandSetPointsDropdown';

	return (
		<div className={styles.sections} id={mainDivContainerId}>
			<label id='commandSetPointsLabel' htmlFor={selectCommandSetPointsId}>
				{_(name)}
			</label>
			<div>
				<Select
					id={selectCommandSetPointsId}
					className={styles.select}
					options={list.map(x => ({ label: x.Item2, value: x.Item1.toString() }))}
					onChange={value => dispatcher(setLocation(Number(value)))}
					disabled={list.length === 0}
					key='points'
					value={location?.toString()}
					getPopupContainer={() => document.getElementById(mainDivContainerId)}
				/>
			</div>
		</div>
	);
};

export { Location };

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setNormalState, StoreContext } from '../../../contextExpansionRelay';
import styles from '../../../expansionRelayModal.module.scss';

type Props = {
	useDoorContext: boolean;
};

const NormalState: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: { normalState },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			expansionRelayState: { normalState },
			dispatcher,
		} = useContext(StoreContext);
	}

	const handleOnChangeNormalState = (e: RadioChangeEvent) => {
		const value = e.target.value;

		useDoorContext ? dispatcherDoor(setNormalState(value)) : dispatcher(setNormalState(value));
	};

	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('NormalState')}</legend>
					<div className={styles.radioButton}>
						<Radio.Group onChange={handleOnChangeNormalState} value={normalState}>
							<Radio id='relayGeneralEnergizedRadioButton' value={true}>
								{_('Energized')}
							</Radio>
							<Radio id='relayGeneralDeEnergizedRadioButton' value={false}>
								{_('Deenergized')}
							</Radio>
						</Radio.Group>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { NormalState };

import { Button, Checkbox, Input, notification, Transfer } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TransferItem } from 'antd/lib/transfer';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import { removeCommasAndPercentSign } from '../../../../../Helper';
import { getPermissionErrorMessage, SecuredComponents, User } from '../../../../../model/AccountModel';
import { PaginationSetting } from '../../../../../model/CommonModel';
import { DeviceAdminWarningType, DoorGroupAndMasterInfo, DoorGroupInfo, MasterDoorGroup } from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setDoorGroupsBy } from '../../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfigurationFilterMode } from '../../../../../store/deviceControl/selectors';
import { Modal, ModalWarning, NotificationStatus } from '../../../../common';
import { DoorGroupConflict, DoorGroupLockStatus } from '../warnings';
import styles from './doorMasterModal.module.scss';

type Props = {
	onSetVisible: () => void;
	doorToEdit?: DoorGroupAndMasterInfo;
	setShouldResetSearchColumn: () => void;
	setRedirectPage?: () => void;
};

type DoorsTransfer = TransferItem & DoorGroupInfo;

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Master_Door_Group);
const masterDoorGroupNameInputId: string = 'masterDoorGroupNameInput';
const masterDoorGroupTagCheckboxId: string = 'masterDoorGroupTagCheckbox';
const masterDoorGroupAlertCheckboxId: string = 'masterDoorGroupAlertCheckbox';
const masterDoorGroupDisableCredentialCheckboxId: string = 'masterDoorGroupDisableCredentialCheckbox';

const DoorMasterModal: React.FC<Props> = ({ onSetVisible, doorToEdit, setShouldResetSearchColumn, setRedirectPage }) => {
	const dispatch = useStoreDispatch();

	const [data, setData] = useState<DoorsTransfer[]>([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [targetKeys, setTargetKeys] = useState<string[]>([]);

	const [nameError, setNameError] = useState('');
	const [name, setName] = useState('');
	const [emptyNameError, setEmptyNameError] = useState('');

	const [alert, setAlert] = useState(false);
	const [tag, setTag] = useState(false);
	const [disableCredential, setDisableCredential] = useState(false);

	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const masterDoorId = doorToEdit?.Id ?? 0;

	useEffect(() => {
		const action = masterDoorId ? deviceAdminApi.editMasterDoorGroupDialog(masterDoorId) : deviceAdminApi.addMasterDoorGroupDialog();
		action.then(res => {
			const { AvailableDoorGroups, MasterDoorGroup } = res;
			const selectedDoors = AvailableDoorGroups.filter(x => MasterDoorGroup.DoorGroups.findIndex(w => w.DoorGroupId === x.DoorGroupId) < 0).sort(
				orderByDoorGroupInfo
			);
			const mergeData = [...AvailableDoorGroups, ...MasterDoorGroup.DoorGroups.filter(d => d.InUse)].sort(orderByDoorGroupInfo);

			setData(
				mergeData.map(x => ({
					...x,
					key: x.DoorGroupId.toString(),
					description: x.DoorGroupName,
					title: x.DoorGroupName,
					disabled: x.InUse,
				}))
			);
			setTargetKeys([...selectedDoors.map(x => x.DoorGroupId.toString())]);
			setName(MasterDoorGroup.Name);
			setTag(MasterDoorGroup.Tag);
			setAlert(MasterDoorGroup.Alert);
			setDisableCredential(MasterDoorGroup.DisableCredential);
		});

		deviceAdminApi.getErrorMessages(SecuredComponents.Master_Door_Group).then(res => setEmptyNameError(res.EmptyName));
		window.addEventListener('beforeunload', handleCloseModal);

		return () => {
			window.removeEventListener('beforeunload', handleCloseModal);
		};
	}, []);

	const onChange = (nextTargetKeys: string[]) => {
		const filterTargetKey = data.filter(d => nextTargetKeys.findIndex(x => x === d.key) >= 0);
		const orderTarget = filterTargetKey.sort(orderByName).map(x => x.key);
		setTargetKeys(orderTarget);
	};

	const orderByDoorGroupInfo = (a: DoorGroupInfo, b: DoorGroupInfo) => a.DoorGroupName.localeCompare(b.DoorGroupName);

	const orderByName = (a: TransferItem, b: TransferItem) => a.title.localeCompare(b.title);

	const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
		const filterSelectedKey = data.filter(d => sourceSelectedKeys.findIndex(x => x === d.key) >= 0);
		const filterTargetKey = data.filter(d => targetSelectedKeys.findIndex(x => x === d.key) >= 0);
		const orderSelected = filterSelectedKey.sort(orderByName).map(x => x.key);
		const orderTarget = filterTargetKey.sort(orderByName).map(x => x.key);
		setSelectedKeys([...orderSelected, ...orderTarget]);
	};

	const shouldResetSearchColumn = doorToEdit => {
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}

		if (!doorToEdit) {
			return;
		}

		setRedirectPage();
	};

	const handleOnSave = async () => {
		if (!name || name.trim() === '') {
			setNameError(emptyNameError);
			return;
		}

		const doors = data.filter(x => targetKeys.indexOf(x.key) < 0);

		const masterDoor: MasterDoorGroup = {
			Alert: alert,
			Tag: tag,
			DisableCredential: disableCredential,
			DoorGroups: [...doors],
			MasterDoorGroupId: masterDoorId,
			Name: name.trim(),
		};

		const action = masterDoorId ? deviceAdminApi.editMasterDoorGroup(masterDoor) : deviceAdminApi.addMasterDoorGroup(masterDoor);
		const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;

		action.then(res => {
			NotificationStatus({
				responseData: res,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					onSetVisible();
					dispatch(setDoorGroupsBy(paginationSetting));
					shouldResetSearchColumn(doorToEdit);
				},
				onFailedValidation: () => setNameError(res.ErrorMessage),
				onAdditionalInfoRequiredCallback: async () => {
					const warningType = Number(res.ResponseErrorDescription) as DeviceAdminWarningType;
					const responseModel =
						warningType !== DeviceAdminWarningType.ControllersRunOutOfMAZ
							? await deviceAdminApi.getDoorGroupConflict(warningType, masterDoorId || 0, res.AdditionalResponseInfo)
							: await deviceAdminApi.checkZoneAvailability(masterDoor);
					ModalWarning({
						width: '600px',
						hideCancelButton: true,
						okText: 'OK',
						content: <DoorGroupConflict conflictModel={responseModel} />,
					});
				},
				onComponentLockedCallback: async () => {
					const warningType = Number(res.ResponseErrorDescription) as DeviceAdminWarningType;
					const conflictModel = await deviceAdminApi.getDoorGroupLockStatusWarning(warningType, masterDoorId, true);
					ModalWarning({
						width: '600px',
						okText: 'OK',
						hideCancelButton: true,
						content: <DoorGroupLockStatus conflictModel={conflictModel} />,
					});
				},
				onPermissionErrorCallback: async () => {
					notification['error']({
						message: res.ErrorMessage,
					});
				},
			});
		});
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		setNameError('');
		setName(value);
	};

	const handleOnCheckTag = (e: CheckboxChangeEvent) => {
		setTag(e.target.checked);
	};

	const handleOnCheckAlert = (e: CheckboxChangeEvent) => {
		setAlert(e.target.checked);
	};

	const handleOnCheckDisableCredential = (e: CheckboxChangeEvent) => {
		setDisableCredential(e.target.checked);
	};

	const handleCloseModal = () => {
		if (masterDoorId) {
			deviceAdminApi.unlockComponentDeviceAdmin(masterDoorId, SecuredComponents.Master_Door_Group);
		}
		onSetVisible();
	};

	const displayName = nameError && nameError !== '';
	const filterOption = (inputValue: string, option: TransferItem) => option.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
	const permissionsEnabled = masterDoorId ? componentPermission.canUpdate : componentPermission.canAdd;

	return (
		<Modal
			footer={[
				<Button
					id='masterDoorGroupSaveButton'
					key='save'
					type='primary'
					title={getPermissionErrorMessage(permissionsEnabled)}
					disabled={!permissionsEnabled}
					onClick={() => handleOnSave()}>
					{_('SaveChanges')}
				</Button>,
				<Button id='masterDoorGroupCancelButton' key='cancel' onClick={() => handleCloseModal()}>
					{_('Cancel')}
				</Button>,
			]}
			width='757px'
			visible={true}
			title={masterDoorId ? _('EditMasterDoorGroup') : _('AddMasterDoorGroup')}
			onCancel={() => handleCloseModal()}
			onClickOk={() => null}>
			<div id='masterDoorGroupContainer' className={styles.container}>
				<div className={cx({ [styles.error]: nameError })}>
					<label htmlFor={masterDoorGroupNameInputId}>{_('Name')}</label>
					<Input id={masterDoorGroupNameInputId} onChange={handleChangeName} value={name} maxLength={64} />
					{displayName && <label htmlFor={masterDoorGroupNameInputId}>{nameError}</label>}
				</div>
				<div>
					<Checkbox id={masterDoorGroupTagCheckboxId} onChange={handleOnCheckTag} checked={tag}>
						<label htmlFor={masterDoorGroupTagCheckboxId}>{_('Tag')}</label>
					</Checkbox>
					<Checkbox id={masterDoorGroupAlertCheckboxId} onChange={handleOnCheckAlert} checked={alert}>
						<label htmlFor={masterDoorGroupAlertCheckboxId}> {_('Alert')}</label>
					</Checkbox>
					<Checkbox id={masterDoorGroupDisableCredentialCheckboxId} onChange={handleOnCheckDisableCredential} checked={disableCredential}>
						<label htmlFor={masterDoorGroupDisableCredentialCheckboxId}>{_('Disable')}</label>
					</Checkbox>
				</div>
				<div>
					<Transfer
						dataSource={data}
						titles={[_('Contains'), _('DoesNotContain')]}
						selectedKeys={selectedKeys}
						targetKeys={targetKeys}
						onChange={onChange}
						onSelectChange={onSelectChange}
						render={item => item.title}
						className={styles.treeTransfer}
						showSearch
						filterOption={filterOption}
					/>
				</div>
			</div>
		</Modal>
	);
};

export { DoorMasterModal };

import { Checkbox, Table, Transfer } from 'antd';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import { TransferDirection, TransferItem } from 'antd/lib/transfer';
import React from 'react';
import { ColumnsProps, getDefaultTransferTableSelection } from '../../../../../../../Helper';
import { ObjectTypeTransfer } from '../../../../../../../model/CommonModel';
import { GlobalIOGroupSNIBColumns } from '../../../../../../../model/DeviceAdminModel';

type Props = {
	leftTableId: string;
	rightTableId: string;
	dataSource: ObjectTypeTransfer<GlobalIOGroupSNIBColumns>[];
	titles: string[];
	className?: string;
	showSearch: boolean;
	globalIOServerId?: number;
	isLeftTransferLoading: boolean;
	isRightTransferLoading: boolean;
	targetKeys: string[];
	leftColumns: ColumnsProps<GlobalIOGroupSNIBColumns>[];
	leftRowSelection: TableRowSelection<GlobalIOGroupSNIBColumns>;
	leftGridSelectedKeys: React.Key[];
	leftPaginationTable: TablePaginationConfig;
	rightColumns: ColumnsProps<GlobalIOGroupSNIBColumns>[];
	rightRowSelection: TableRowSelection<GlobalIOGroupSNIBColumns>;
	rightGridSelectedKeys: React.Key[];
	rightPaginationTable: TablePaginationConfig;
	setLeftGridSelectedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
	setRightGridSelectedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
	onChange: (nextTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => void;
	filterOption: (inputValue: string, option: TransferItem) => boolean;
	onChangeTable?: (
		pagination,
		filters,
		sorter,
		extra,
		direction: TransferDirection,
		onItemSelectAll: (dataSource: string[], checkAll: boolean) => void
	) => void;
	onSearch?: (direction: string, value: string) => void;
	onSelectAll?: (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => void;
	onSelectInvert?: (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => void;
	onSelectNone?: (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => void;
};

type customType<T> = {
	[Property in keyof T]: any;
};

const GlobalIOTransfer = ({
	leftTableId,
	rightTableId,
	leftColumns,
	rightColumns,
	leftRowSelection,
	rightRowSelection,
	leftGridSelectedKeys,
	rightGridSelectedKeys,
	globalIOServerId,
	leftPaginationTable,
	rightPaginationTable,
	isLeftTransferLoading,
	isRightTransferLoading,
	setLeftGridSelectedKeys,
	setRightGridSelectedKeys,
	onChangeTable,
	onSelectAll,
	onSelectInvert,
	onSelectNone,
	...restProps
}: Props) => (
	<Transfer showSelectAll={false} render={item => item.title} {...restProps}>
		{({ direction, filteredItems, onItemSelect, onItemSelectAll }) => {
			const isLeftGrid: boolean = direction === 'left';
			const checkBoxId = isLeftGrid ? 'leftTableTransferCheckbox' : 'rightTableTransferCheckbox';
			const pagination = isLeftGrid ? leftPaginationTable : rightPaginationTable;
			const rowSelection = isLeftGrid ? leftRowSelection : rightRowSelection;
			const columns = isLeftGrid ? leftColumns : rightColumns;
			const selectedRowKeys = isLeftGrid ? leftGridSelectedKeys : rightGridSelectedKeys;
			const tableId: string = isLeftGrid ? leftTableId : rightTableId;

			const shouldDisableItem = (key: string, selectedRowsKeys: React.Key[]): boolean => {
				const isItemSelected: boolean = selectedRowsKeys.includes(key);
				const itemId = Number(key);
				return (isItemSelected && itemId === globalIOServerId) || (!isItemSelected && itemId === globalIOServerId);
			};

			const handleOnChangeRow = (selectedRowKeys: React.Key[]) => {
				isLeftGrid ? setLeftGridSelectedKeys(selectedRowKeys) : setRightGridSelectedKeys(selectedRowKeys);
				onItemSelectAll(
					selectedRowKeys.map<string>(key => key.toString()),
					true
				);
			};

			const rowSelectionTransfer = {
				...rowSelection,
				getCheckboxProps: record => ({
					id: `${checkBoxId}-${record.key}`,
					disabled: record.disabled,
				}),
				renderCell: (checked, record) => {
					return <Checkbox checked={checked} {...record} aria-label={`${checkBoxId}-${record.key}`}></Checkbox>;
				},
				selections: getDefaultTransferTableSelection(direction, onItemSelectAll, onSelectAll, onSelectInvert),
				onChange: handleOnChangeRow,
				onSelectNone: () => onSelectNone(direction, onItemSelectAll),
			};

			const dataSourceMapped = filteredItems.map(item => {
				return { ...item, disabled: shouldDisableItem(item.key, selectedRowKeys) };
			});

			const handleOnClick = record => {
				const { key, disabled } = record;
				if (disabled) {
					return;
				}

				const isRecordSelected: boolean = selectedRowKeys.some(rowKey => rowKey === key);
				let newSelectedRowKeys: React.Key[] = [];

				if (isRecordSelected) {
					newSelectedRowKeys = selectedRowKeys.filter(rowKey => rowKey !== key);
				} else {
					newSelectedRowKeys = [...selectedRowKeys, key];
				}

				onItemSelect(key, !selectedRowKeys.includes(key));
				isLeftGrid ? setLeftGridSelectedKeys(newSelectedRowKeys) : setRightGridSelectedKeys(newSelectedRowKeys);
			};

			const handleOnChange = (pagination, filters, sorter, extra) => {
				onChangeTable(pagination, filters, sorter, extra, direction, onItemSelectAll);
			};

			const isLoading = isLeftGrid ? isLeftTransferLoading : isRightTransferLoading;

			return (
				<Table
					id={tableId}
					rowSelection={rowSelectionTransfer}
					columns={columns}
					dataSource={dataSourceMapped}
					size='small'
					pagination={pagination}
					onChange={handleOnChange}
					loading={isLoading}
					onRow={record => ({
						onClick: () => handleOnClick(record),
					})}
					scroll={{ y: 300 }}
				/>
			);
		}}
	</Transfer>
);

export { GlobalIOTransfer };

import React from 'react';
import {
	BroadCastDevice,
	DataTableType,
	IPAddressOctets,
	NetworkInfo,
	PhysicalPort,
	PortConnectionType,
	PortNetWorkType,
	SnibSearchType,
} from '../../../../model/DeviceAdminModel';

type IPRangeError = {
	hasError: boolean;
	errorMessage: string;
};

export type PortContextState = {
	// Port
	id?: number;
	name: string;
	portNetworkType: PortNetWorkType;
	protocol: number;
	ipAddress: string;
	ipPort: string;
	subnetMask: string;
	defaultGateway: string;
	maxRetryAttempts: number;
	enablePort: boolean;
	resetEncryption: boolean;
	totalTimeoutMultiplier: number;
	totalTimeoutConstant: number;
	intervalTimeout: number;
	portStyle?: string;
	portType?: number;
	baudRate?: number;
	portAddress?: number;
	ipv6Address?: string;
	subnetPrefix?: string;
	defaultGatewayIPv6?: string;
	baudRates: number[];
	comPorts: PhysicalPort[];
	defaultConnectionType: PortConnectionType;
	comPort: number;
	hasPortChanged: boolean;
	// Search modal
	openSearchModal?: boolean;
	snibSelected?: BroadCastDevice;
	ipRangeError?: IPRangeError;
	broadcastData: DataTableType<BroadCastDevice>[];
	activeNetworks: NetworkInfo[];
	currentNetwork: NetworkInfo;
	ipAddressOctets: IPAddressOctets;
	snibSearchType: SnibSearchType;
	loading: boolean;
	globalBroadcastAddress: boolean;
	titleModal: string;
	networkType?: number;
};

enum PortContextActionTypes {
	INIT_PORT = 'INIT_PORT',
	SET_NAME = 'SET_NAME',
	SET_PORT_NETWORKTYPE = 'SET_PORT_NETWORKTYPE',
	SET_PROTOCOL = 'SET_PROTOCOL',
	SET_IP_ADDRESS = 'SET_IP_ADDRESS',
	SET_IP_PORT = 'SET_IP_PORT',
	SET_SUBNET_MASK = 'SET_SUBNET_MASK',
	SET_DEFAULT_GATEWAY = 'SET_DEFAULT_GATEWAY',
	SET_MAX_RETRY_ATTEMPTS = 'SET_MAX_RETRY_ATTEMPTS',
	SET_TOTAL_TIMEOUT_MULTIPLIER = 'SET_TOTAL_TIMEOUT_MULTIPLIER',
	SET_TOTAL_TIMEOUT_CONSTANT = 'SET_TOTAL_TIMEOUT_CONSTANT',
	SET_INTERVAL_TIMEOUT = 'SET_INTERVAL_TIMEOUT',
	SET_RESET_ENCRYPTION = 'SET_RESET_ENCRYPTION',
	SET_ENABLE_PORT = 'SET_ENABLE_PORT',
	SET_OPEN_SEARCH_MODAL = 'SET_OPEN_SEARCH_MODAL',
	SET_SNIB_SELECTED = 'SET_SNIB_SELECTED',
	SET_IP_RANGE_ERROR = 'SET_IP_RANGE_ERROR',
	SET_IP_ADDRESS_IPV6 = 'SET_IP_ADDRESS_IPV6',
	SET_SUBNET_PREFIX = 'SET_SUBNET_PREFIX',
	SET_DEFAULT_GATEWAY_IPV6 = 'SET_DEFAULT_GATEWAY_IPV6',
	SET_BAUD_RATES = 'SET_BAUD_RATES',
	SET_BAUD_RATE = 'SET_BAUD_RATE',
	SET_COM_PORT = 'SET_COM_PORT',
	SET_PORT_CHANGED = 'SET_PORT_CHANGED',
	SET_VALUE_SNIB_SELECTED = 'SET_VALUE_SNIB_SELECTED',
	SET_BROADCAST_DATA = 'SET_BROADCAST_DATA',
	SET_ACTIVE_NETWORKS = 'SET_ACTIVE_NETWORKS',
	SET_CURRENT_NETWORK = 'SET_CURRENT_NETWORK',
	SET_IP_ADDRESS_OCTETS = 'SET_IP_ADDRESS_OCTETS',
	SET_SNIB_SEARCH_TYPE = 'SET_SNIB_SEARCH_TYPE',
	SET_LOADING = 'SET_LOADING',
	SET_GLOBAL_BROADCAST_ADDRESS = 'SET_GLOBAL_BROADCAST_ADDRESS',
	SET_TITLE_MODAL = 'SET_TITLE_MODAL',
	SET_NETWORK_TYPE = 'SET_SEARCH_NETWORK_TYPE',
	RESET_SEARCH_MODAL = 'RESET_SEARCH_MODAL',
}

type contextPortInitPort = {
	type: PortContextActionTypes.INIT_PORT;
	payload: Partial<PortContextState>;
};

type contextPortSetName = {
	type: PortContextActionTypes.SET_NAME;
	payload: string;
};

type contextPortSetPortNetworkType = {
	type: PortContextActionTypes.SET_PORT_NETWORKTYPE;
	payload: number;
};

type contextPortSetProtocol = {
	type: PortContextActionTypes.SET_PROTOCOL;
	payload: number;
};

type contextPortSetIPAddress = {
	type: PortContextActionTypes.SET_IP_ADDRESS;
	payload: string;
};

type contextPortSetIPPort = {
	type: PortContextActionTypes.SET_IP_PORT;
	payload: string;
};

type contextPortSetSubnetMask = {
	type: PortContextActionTypes.SET_SUBNET_MASK;
	payload: string;
};

type contextPortSetDefaultGateway = {
	type: PortContextActionTypes.SET_DEFAULT_GATEWAY;
	payload: string;
};

type contextPortSetMaxRetryAttempts = {
	type: PortContextActionTypes.SET_MAX_RETRY_ATTEMPTS;
	payload: number;
};

type contextPortSetTotalTimeoutMultiplier = {
	type: PortContextActionTypes.SET_TOTAL_TIMEOUT_MULTIPLIER;
	payload: number;
};

type contextPortSetTotalTimeoutConstant = {
	type: PortContextActionTypes.SET_TOTAL_TIMEOUT_CONSTANT;
	payload: number;
};

type contextPortSetIntervalTimeout = {
	type: PortContextActionTypes.SET_INTERVAL_TIMEOUT;
	payload: number;
};

type contextPortSetResetEncryption = {
	type: PortContextActionTypes.SET_RESET_ENCRYPTION;
	payload: boolean;
};

type contextPortSetEnablePort = {
	type: PortContextActionTypes.SET_ENABLE_PORT;
	payload: boolean;
};

type contextPortSetOpenSearchModal = {
	type: PortContextActionTypes.SET_OPEN_SEARCH_MODAL;
	payload: boolean;
};

type contextPortSetSNIBSelected = {
	type: PortContextActionTypes.SET_SNIB_SELECTED;
	payload: BroadCastDevice;
};

type contextPortSetIPRangeError = {
	type: PortContextActionTypes.SET_IP_RANGE_ERROR;
	payload: IPRangeError;
};

type contextPortSetIPv6Address = {
	type: PortContextActionTypes.SET_IP_ADDRESS_IPV6;
	payload: string;
};

type contextPortSetSubnetPrefix = {
	type: PortContextActionTypes.SET_SUBNET_PREFIX;
	payload: string;
};

type contextPortSetDefaultGatewayIPv6 = {
	type: PortContextActionTypes.SET_DEFAULT_GATEWAY_IPV6;
	payload: string;
};

type contextPortSetBaudRate = {
	type: PortContextActionTypes.SET_BAUD_RATE;
	payload: number;
};

type contextPortSetCOMPort = {
	type: PortContextActionTypes.SET_COM_PORT;
	payload: number;
};

type contextPortSetPortChanged = {
	type: PortContextActionTypes.SET_PORT_CHANGED;
	payload: boolean;
};

type contextPortSetValueSNIBSelected = {
	type: PortContextActionTypes.SET_VALUE_SNIB_SELECTED;
	payload: { type: keyof BroadCastDevice; value: number | string | boolean };
};

type contextPortSetBroadcastData = {
	type: PortContextActionTypes.SET_BROADCAST_DATA;
	payload: DataTableType<BroadCastDevice>[];
};

type contextPortSetActiveNetworks = {
	type: PortContextActionTypes.SET_ACTIVE_NETWORKS;
	payload: NetworkInfo[];
};

type contextPortSetCurrentNetwork = {
	type: PortContextActionTypes.SET_CURRENT_NETWORK;
	payload: NetworkInfo;
};

type contextPortSetIPAddressOctets = {
	type: PortContextActionTypes.SET_IP_ADDRESS_OCTETS;
	payload: IPAddressOctets;
};

type contextPortSetSnibSearchType = {
	type: PortContextActionTypes.SET_SNIB_SEARCH_TYPE;
	payload: SnibSearchType;
};

type contextPortSetLoading = {
	type: PortContextActionTypes.SET_LOADING;
	payload: boolean;
};

type contextPortSetGlobalBroadcastAddress = {
	type: PortContextActionTypes.SET_GLOBAL_BROADCAST_ADDRESS;
	payload: boolean;
};

type contextPortSetTitleModal = {
	type: PortContextActionTypes.SET_TITLE_MODAL;
	payload: string;
};

type contextPortSetNetworkType = {
	type: PortContextActionTypes.SET_NETWORK_TYPE;
	payload: number;
};

type contextPortResetSearchModal = {
	type: PortContextActionTypes.RESET_SEARCH_MODAL;
};

type PortActionContext =
	| contextPortInitPort
	| contextPortSetName
	| contextPortSetPortNetworkType
	| contextPortSetProtocol
	| contextPortSetIPAddress
	| contextPortSetIPPort
	| contextPortSetSubnetMask
	| contextPortSetDefaultGateway
	| contextPortSetMaxRetryAttempts
	| contextPortSetTotalTimeoutMultiplier
	| contextPortSetTotalTimeoutConstant
	| contextPortSetIntervalTimeout
	| contextPortSetResetEncryption
	| contextPortSetEnablePort
	| contextPortSetOpenSearchModal
	| contextPortSetSNIBSelected
	| contextPortSetIPRangeError
	| contextPortSetIPv6Address
	| contextPortSetSubnetPrefix
	| contextPortSetDefaultGatewayIPv6
	| contextPortSetBaudRate
	| contextPortSetCOMPort
	| contextPortSetPortChanged
	| contextPortSetValueSNIBSelected
	| contextPortSetBroadcastData
	| contextPortSetActiveNetworks
	| contextPortSetCurrentNetwork
	| contextPortSetIPAddressOctets
	| contextPortSetSnibSearchType
	| contextPortSetLoading
	| contextPortSetGlobalBroadcastAddress
	| contextPortSetTitleModal
	| contextPortSetNetworkType
	| contextPortResetSearchModal;

const portState: PortContextState = {
	// Port
	id: 0,
	name: '',
	portNetworkType: 2,
	protocol: 2,
	ipAddress: '0.0.0.0',
	ipv6Address: '',
	ipPort: '10001',
	subnetMask: '0.0.0.0',
	subnetPrefix: '',
	defaultGateway: '0.0.0.0',
	defaultGatewayIPv6: '',
	maxRetryAttempts: 50,
	enablePort: true,
	resetEncryption: false,
	totalTimeoutMultiplier: 1,
	totalTimeoutConstant: 50,
	intervalTimeout: 2,
	baudRates: [],
	baudRate: 0,
	comPorts: [],
	comPort: 0,
	defaultConnectionType: 0,
	hasPortChanged: false,
	// Search modal
	openSearchModal: false,
	snibSelected: null,
	ipRangeError: {
		errorMessage: '',
		hasError: false,
	},
	broadcastData: [],
	activeNetworks: [],
	currentNetwork: undefined,
	ipAddressOctets: { octet1: '', octet2: '', octet3: '', octet4: 'XXX' },
	snibSearchType: SnibSearchType.Broadcast,
	loading: false,
	globalBroadcastAddress: true,
	titleModal: '',
	networkType: 2,
};

const portContext = (state: Readonly<PortContextState>, action: PortActionContext): PortContextState => {
	switch (action.type) {
		case PortContextActionTypes.INIT_PORT:
			return {
				...state,
				...action.payload,
			};

		case PortContextActionTypes.SET_NAME:
			return { ...state, name: action.payload };

		case PortContextActionTypes.SET_PORT_NETWORKTYPE:
			return { ...state, portNetworkType: action.payload };

		case PortContextActionTypes.SET_PROTOCOL:
			return { ...state, protocol: action.payload };

		case PortContextActionTypes.SET_IP_ADDRESS:
			return { ...state, ipAddress: action.payload };

		case PortContextActionTypes.SET_IP_PORT:
			return { ...state, ipPort: action.payload };

		case PortContextActionTypes.SET_SUBNET_MASK:
			return { ...state, subnetMask: action.payload };

		case PortContextActionTypes.SET_DEFAULT_GATEWAY:
			return { ...state, defaultGateway: action.payload };

		case PortContextActionTypes.SET_MAX_RETRY_ATTEMPTS:
			return { ...state, maxRetryAttempts: action.payload };

		case PortContextActionTypes.SET_TOTAL_TIMEOUT_MULTIPLIER:
			return { ...state, totalTimeoutMultiplier: action.payload };

		case PortContextActionTypes.SET_TOTAL_TIMEOUT_CONSTANT:
			return { ...state, totalTimeoutConstant: action.payload };

		case PortContextActionTypes.SET_INTERVAL_TIMEOUT:
			return { ...state, intervalTimeout: action.payload };

		case PortContextActionTypes.SET_RESET_ENCRYPTION:
			return { ...state, resetEncryption: action.payload };

		case PortContextActionTypes.SET_ENABLE_PORT:
			return { ...state, enablePort: action.payload };

		case PortContextActionTypes.SET_OPEN_SEARCH_MODAL:
			return { ...state, openSearchModal: action.payload };

		case PortContextActionTypes.SET_SNIB_SELECTED:
			return { ...state, snibSelected: action.payload };

		case PortContextActionTypes.SET_IP_RANGE_ERROR:
			return { ...state, ipRangeError: action.payload };

		case PortContextActionTypes.SET_IP_ADDRESS_IPV6:
			return { ...state, ipv6Address: action.payload };

		case PortContextActionTypes.SET_SUBNET_PREFIX:
			return { ...state, subnetPrefix: action.payload };

		case PortContextActionTypes.SET_DEFAULT_GATEWAY_IPV6:
			return { ...state, defaultGatewayIPv6: action.payload };

		case PortContextActionTypes.SET_BAUD_RATE:
			return { ...state, baudRate: action.payload };

		case PortContextActionTypes.SET_COM_PORT:
			return { ...state, comPort: action.payload };

		case PortContextActionTypes.SET_PORT_CHANGED:
			return { ...state, hasPortChanged: action.payload };

		case PortContextActionTypes.SET_VALUE_SNIB_SELECTED:
			return { ...state, snibSelected: { ...state.snibSelected, [action.payload.type]: action.payload.value } };

		case PortContextActionTypes.SET_BROADCAST_DATA:
			return { ...state, broadcastData: action.payload };

		case PortContextActionTypes.SET_ACTIVE_NETWORKS:
			return { ...state, activeNetworks: action.payload };

		case PortContextActionTypes.SET_CURRENT_NETWORK:
			return { ...state, currentNetwork: action.payload };

		case PortContextActionTypes.SET_IP_ADDRESS_OCTETS:
			return { ...state, ipAddressOctets: action.payload };

		case PortContextActionTypes.SET_SNIB_SEARCH_TYPE:
			return { ...state, snibSearchType: action.payload };

		case PortContextActionTypes.SET_LOADING:
			return { ...state, loading: action.payload };

		case PortContextActionTypes.SET_GLOBAL_BROADCAST_ADDRESS:
			return { ...state, globalBroadcastAddress: action.payload };

		case PortContextActionTypes.SET_TITLE_MODAL:
			return { ...state, titleModal: action.payload };

		case PortContextActionTypes.SET_NETWORK_TYPE:
			return { ...state, networkType: action.payload };

		case PortContextActionTypes.RESET_SEARCH_MODAL:
			return {
				...state,
				openSearchModal: false,
				snibSelected: null,
				ipRangeError: {
					errorMessage: '',
					hasError: false,
				},
				broadcastData: [],
				activeNetworks: [],
				currentNetwork: undefined,
				ipAddressOctets: { octet1: '', octet2: '', octet3: '', octet4: 'XXX' },
				snibSearchType: SnibSearchType.Broadcast,
				loading: false,
				globalBroadcastAddress: true,
				titleModal: '',
				networkType: state.portNetworkType,
			};

		default:
			return { ...state };
	}
};

const initPortAction = (payload: Partial<PortContextState>): contextPortInitPort => {
	return {
		type: PortContextActionTypes.INIT_PORT,
		payload,
	};
};

const setName = (payload: string): contextPortSetName => {
	return {
		type: PortContextActionTypes.SET_NAME,
		payload,
	};
};

const setPortNetworkType = (payload: number): contextPortSetPortNetworkType => {
	return {
		type: PortContextActionTypes.SET_PORT_NETWORKTYPE,
		payload,
	};
};

const setProtocol = (payload: number): contextPortSetProtocol => {
	return {
		type: PortContextActionTypes.SET_PROTOCOL,
		payload,
	};
};

const setIPAddress = (payload: string): contextPortSetIPAddress => {
	return {
		type: PortContextActionTypes.SET_IP_ADDRESS,
		payload,
	};
};

const setIPPort = (payload: string): contextPortSetIPPort => {
	return {
		type: PortContextActionTypes.SET_IP_PORT,
		payload,
	};
};

const setSubnetMask = (payload: string): contextPortSetSubnetMask => {
	return {
		type: PortContextActionTypes.SET_SUBNET_MASK,
		payload,
	};
};

const setDefaultGateway = (payload: string): contextPortSetDefaultGateway => {
	return {
		type: PortContextActionTypes.SET_DEFAULT_GATEWAY,
		payload,
	};
};

const setMaxRetryAttempts = (payload: number): contextPortSetMaxRetryAttempts => {
	return {
		type: PortContextActionTypes.SET_MAX_RETRY_ATTEMPTS,
		payload,
	};
};

const setTotalTimeoutMultiplier = (payload: number): contextPortSetTotalTimeoutMultiplier => {
	return {
		type: PortContextActionTypes.SET_TOTAL_TIMEOUT_MULTIPLIER,
		payload,
	};
};

const setTotalTimeoutConstant = (payload: number): contextPortSetTotalTimeoutConstant => {
	return {
		type: PortContextActionTypes.SET_TOTAL_TIMEOUT_CONSTANT,
		payload,
	};
};

const setIntervalTimeout = (payload: number): contextPortSetIntervalTimeout => {
	return {
		type: PortContextActionTypes.SET_INTERVAL_TIMEOUT,
		payload,
	};
};

const setResetEncryption = (payload: boolean): contextPortSetResetEncryption => {
	return {
		type: PortContextActionTypes.SET_RESET_ENCRYPTION,
		payload,
	};
};

const setEnablePort = (payload: boolean): contextPortSetEnablePort => {
	return {
		type: PortContextActionTypes.SET_ENABLE_PORT,
		payload,
	};
};

const setOpenSearchModal = (payload: boolean): contextPortSetOpenSearchModal => {
	return {
		type: PortContextActionTypes.SET_OPEN_SEARCH_MODAL,
		payload,
	};
};

const setSNIBSelected = (payload: BroadCastDevice): contextPortSetSNIBSelected => {
	return {
		type: PortContextActionTypes.SET_SNIB_SELECTED,
		payload,
	};
};

const setIpRangeError = (payload: IPRangeError): contextPortSetIPRangeError => {
	return {
		type: PortContextActionTypes.SET_IP_RANGE_ERROR,
		payload,
	};
};

const setIPv6Address = (payload: string): contextPortSetIPv6Address => {
	return {
		type: PortContextActionTypes.SET_IP_ADDRESS_IPV6,
		payload,
	};
};

const setSubnetPrefix = (payload: string): contextPortSetSubnetPrefix => {
	return {
		type: PortContextActionTypes.SET_SUBNET_PREFIX,
		payload,
	};
};

const setDefaultGatewatIPv6 = (payload: string): contextPortSetDefaultGatewayIPv6 => {
	return {
		type: PortContextActionTypes.SET_DEFAULT_GATEWAY_IPV6,
		payload,
	};
};

const setBaudRateAction = (payload: number): contextPortSetBaudRate => {
	return {
		type: PortContextActionTypes.SET_BAUD_RATE,
		payload,
	};
};

const setCOMPortAction = (payload: number): contextPortSetCOMPort => {
	return {
		type: PortContextActionTypes.SET_COM_PORT,
		payload,
	};
};

const setPortChangedAction = (payload: boolean): contextPortSetPortChanged => {
	return {
		type: PortContextActionTypes.SET_PORT_CHANGED,
		payload,
	};
};

const setValueSNIBSelectedAction = (payload: { type: keyof BroadCastDevice; value: number | string | boolean }): contextPortSetValueSNIBSelected => {
	return {
		type: PortContextActionTypes.SET_VALUE_SNIB_SELECTED,
		payload,
	};
};

const setBroadcastData = (payload: DataTableType<BroadCastDevice>[]): contextPortSetBroadcastData => {
	return {
		type: PortContextActionTypes.SET_BROADCAST_DATA,
		payload,
	};
};

const setActiveNetworks = (payload: NetworkInfo[]): contextPortSetActiveNetworks => {
	return {
		type: PortContextActionTypes.SET_ACTIVE_NETWORKS,
		payload,
	};
};

const setCurrentNetwork = (payload: NetworkInfo): contextPortSetCurrentNetwork => {
	return {
		type: PortContextActionTypes.SET_CURRENT_NETWORK,
		payload,
	};
};

const setIPAddressOctets = (payload: IPAddressOctets): contextPortSetIPAddressOctets => {
	return {
		type: PortContextActionTypes.SET_IP_ADDRESS_OCTETS,
		payload,
	};
};

const setSnibSearchType = (payload: SnibSearchType): contextPortSetSnibSearchType => {
	return {
		type: PortContextActionTypes.SET_SNIB_SEARCH_TYPE,
		payload,
	};
};

const setLoading = (payload: boolean): contextPortSetLoading => {
	return {
		type: PortContextActionTypes.SET_LOADING,
		payload,
	};
};

const setGlobalBroadcastAddress = (payload: boolean): contextPortSetGlobalBroadcastAddress => {
	return {
		type: PortContextActionTypes.SET_GLOBAL_BROADCAST_ADDRESS,
		payload,
	};
};

const setTitleModal = (payload: string): contextPortSetTitleModal => {
	return {
		type: PortContextActionTypes.SET_TITLE_MODAL,
		payload,
	};
};

const setNetworkType = (payload: number): contextPortSetNetworkType => {
	return {
		type: PortContextActionTypes.SET_NETWORK_TYPE,
		payload,
	};
};

const resetSearchModal = (): contextPortResetSearchModal => {
	return {
		type: PortContextActionTypes.RESET_SEARCH_MODAL,
	};
};

type PortContext = {
	portState: Readonly<PortContextState>;
	dispatcher: React.Dispatch<PortActionContext>;
};

const PortStoreContext = React.createContext<PortContext>({ portState, dispatcher: null });

export {
	portContext,
	portState,
	initPortAction,
	setName,
	setPortNetworkType,
	setProtocol,
	setIPAddress,
	setIPPort,
	setSubnetMask,
	setDefaultGateway,
	setMaxRetryAttempts,
	setTotalTimeoutMultiplier,
	setTotalTimeoutConstant,
	setIntervalTimeout,
	setResetEncryption,
	setEnablePort,
	setOpenSearchModal,
	setSNIBSelected,
	setIpRangeError,
	setIPv6Address,
	setSubnetPrefix,
	setDefaultGatewatIPv6,
	setBaudRateAction,
	setCOMPortAction,
	setPortChangedAction,
	setValueSNIBSelectedAction,
	setBroadcastData,
	setActiveNetworks,
	setCurrentNetwork,
	setIPAddressOctets,
	setSnibSearchType,
	setLoading,
	setGlobalBroadcastAddress,
	setTitleModal,
	setNetworkType,
	resetSearchModal,
	PortStoreContext,
};

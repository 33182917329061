import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Menu } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { CheckboxMenuOptions, SelectOptions } from '../../../model/CommonModel';
import styles from './checkboxMenu.module.scss';

type Props<T> = {
	checked: boolean;
	customMenuOptions?: SelectOptions<T>[];
	disabled?: boolean;
	id?: string;
	indeterminate?: boolean;
	ariaLabel?: string;
	onChange: (value: boolean) => void;
	onClickOption?: (value: T) => void;
};

const CheckboxMenu = <T extends object | string | number>({
	customMenuOptions,
	disabled,
	id,
	checked,
	indeterminate,
	ariaLabel,
	onChange,
	onClickOption,
}: Props<T>) => {
	const defaultMenuOptions: SelectOptions<CheckboxMenuOptions>[] = [
		{
			id: 'selectAllOption',
			label: _('SelectAllData'),
			value: CheckboxMenuOptions.SELECT_ALL,
		},
		{
			id: 'clearAllOption',
			label: _('ClearAllData'),
			value: CheckboxMenuOptions.CLEAR_ALL,
		},
	];

	const menuOptions = customMenuOptions ? customMenuOptions : defaultMenuOptions;

	const menu = (
		<Menu>
			{menuOptions.map(({ label, value, icon, separator, disabled, title, id }, index) => (
				<Menu.Item
					id={id}
					key={`${label}-${index}`}
					icon={icon}
					onClick={() => onClickOption?.(value)}
					className={separator ? styles.separator : undefined}
					disabled={disabled}
					title={title}>
					{label}
				</Menu.Item>
			))}
		</Menu>
	);

	const handleOnChange = (e: CheckboxChangeEvent) => {
		const { checked } = e.target;
		onChange(checked);
	};

	return (
		<div className={styles.checkboxMenu}>
			<Checkbox id={id} checked={checked} onChange={handleOnChange} indeterminate={indeterminate} aria-label={ariaLabel}>
				<label htmlFor={id} className={styles.srOnly}>{`${_('SelectItem')}`}</label>
			</Checkbox>
			<Dropdown overlay={menu} disabled={disabled} overlayStyle={{ width: 'max-content' }}>
				<DownOutlined className={styles.checkboxMenuIcon} />
			</Dropdown>
		</div>
	);
};

export { CheckboxMenu };

import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { buildColumn, ScrollType } from '../../../../../../../Helper';
import { DataTableType, PingInfo } from '../../../../../../../model/DeviceAdminModel';
import styles from '../../../portModal.module.scss';

type Props = {
	pingInformation: PingInfo[];
};

const scroll: ScrollType = { y: 200 };

type DataType = DataTableType<PingInfo>;

const renderContent = (value: string | number | boolean, row: DataType, index: number) => {
	if (row.ErrorMessage && row.ErrorMessage !== '') {
		return {
			children: value,
			props: {
				colSpan: 0,
			},
		};
	}

	return { children: value };
};

const PingAddressTable: React.FC<Props> = ({ pingInformation }) => {
	const [pingInfo, setPingInfo] = useState<DataType[]>([]);

	useEffect(() => {
		const data = pingInformation.map<DataType>((x, index) => ({
			...x,
			key: `${x.Address}-${index}`,
		}));

		setPingInfo(data);
	}, [pingInformation]);

	const columns: ColumnsType<DataType> = [
		{
			...buildColumn(_('Address'), 'Address', 'auto', 'start'),
			render: (value: string, row: DataType) => {
				if (row.ErrorMessage && row.ErrorMessage !== '') {
					return {
						children: <span>{_(row.ErrorMessage)}</span>,
						props: {
							colSpan: 4,
						},
					};
				} else {
					return <span>{value}</span>;
				}
			},
		},
		{
			...buildColumn(_('Bytes'), 'Length', 'auto', 'start'),
			render: renderContent,
		},
		{
			...buildColumn(_('Time'), 'RoundtripTime', 'auto', 'start'),
			render: renderContent,
		},
		{
			...buildColumn(_('TTL'), 'Ttl', 'auto', 'start'),
			render: renderContent,
		},
	];

	return (
		<div id='portGeneralPingAddressContainer' className={styles.pingAddressContainer}>
			<Table
				id='portGeneralPingAddressTable'
				columns={columns}
				scroll={scroll}
				dataSource={pingInfo}
				size='small'
				pagination={false}
				className={styles.pingAddressTable}
			/>
		</div>
	);
};

export { PingAddressTable };

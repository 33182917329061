import React, { CSSProperties, useEffect, useRef } from 'react';
import { ColumnsProps } from '../../../Helper';
import { ContextMenu, ContextMenuProps } from '../../common';
import styles from './virtualTable.module.scss';

type RowProps<T> = {
	data: T;
	index: number;
	columnsWidth: CSSProperties;
	contextMenuOptions: ContextMenuProps<number>;
	windowWidth: number;
	backgroundColor: CSSProperties;
	columns: ColumnsProps<T>[];
	isEventViewer: boolean;
	setSize: (index: number, size: number) => void;
};

const component = <T extends {}>({
	data,
	index,
	setSize,
	windowWidth,
	columns,
	columnsWidth,
	contextMenuOptions,
	backgroundColor,
	isEventViewer,
}: RowProps<T>): JSX.Element => {
	const rowRef = useRef<HTMLDivElement>(undefined);

	useEffect(() => {
		const { current } = rowRef;
		if (current) {
			setSize(index, current.getBoundingClientRect().height);
		}
	}, [setSize, index, windowWidth]);

	const row: JSX.Element = (
		<div ref={rowRef} style={columnsWidth} className={styles.row}>
			{columns.map((column: ColumnsProps<T>, columnIndex: number) => (
				<div key={`${index}-${columnIndex}`} className={styles.cell} style={backgroundColor}>
					{column.render(data[index][column.dataIndex], data[index], columnIndex)}
				</div>
			))}
		</div>
	);

	return !isEventViewer && contextMenuOptions ? (
		<ContextMenu options={contextMenuOptions.options} onClickOption={contextMenuOptions.onClickOption} children={row} />
	) : (
		row
	);
};

const Row = React.memo(component);

export { Row };

import { FileSearchOutlined, WarningTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Input, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { credentialApi } from '../../../../../../api';
import { getPermissionErrorMessage, SubPermissions, User } from '../../../../../../model/AccountModel';
import { CredentialPreviewBadge } from '../../../../../../model/CredentialModel';
import '../../../../../../resources/style/main.scss';
import { ModalConfirmation, PreviewBadgeCredential } from '../../../../../common';
import styles from '../../../credentialmodal.module.scss';
import { setCompleteModelAction, setInformationPropertyAction, setRootModelPropertyAction } from '../../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../../credentialTemplateContext/context';
import { GeneralTabInformationProperty, GeneralTabInformationSectionProps } from '../../../CredentialTemplateModalModels';
import { mapModelCredentialToJs } from '../../../Helper';

const InformationSection: React.FC<GeneralTabInformationSectionProps> = ({ user, isNew }) => {
	const { credentialInitialState, dispatcher } = useContext(CredentialStoreContext);

	const {
		configData: { IsDeviceAdminCredentialTemplate },
		credentialMessages: { SelectFirst },
		errors: { name },
		generalTabState: {
			activationExpirationSectionState: { activationDate, expiration, expirationUsed },
			cardSectionState: { cardData, cardStamp, matchCode },
			credentialId,
			IDF,
			isCredentialTemplate,
			credentialTemplateId,
			informationSectionState: {
				badgeTemplate,
				badgeTemplates,
				credentialDescription,
				credentialTemplates,
				isLinkToConfirmed,
				linkedTemplateId,
				linkToCredential,
				linkToNewCredentials,
			},
		},
		userId,
	} = credentialInitialState;

	const subPermission = isCredentialTemplate ? SubPermissions.CredentialTemplate_LinkToTemplate : SubPermissions.EnrollmentManager_Credentials_Linktotemplate;
	const canLinkToTemplate = User.getSubComponentPermission(user, subPermission).allowed;

	const [badgeTemplateD, setBadgeTemplateD] = useState(false);
	const [displayPreview, setDisplayPreview] = useState<boolean>(false);
	const [credentialDescriptionE, setCredentialDescriptionE] = useState<boolean>(name !== undefined);
	const [credentialPreviewBadge, setCredentialPreviewBadge] = useState<CredentialPreviewBadge>(undefined);

	useEffect(() => {
		if (IsDeviceAdminCredentialTemplate) {
			disableFieldsForTemplate();
		}
	}, [IDF]);

	useEffect(() => {
		if (linkToCredential !== undefined) linkToChkChanged();
	}, [linkToCredential]);

	useEffect(() => {
		setCredentialDescriptionE(name !== undefined);
	}, [name]);

	useEffect(() => {
		if (isLinkToConfirmed) {
			dispatcher(setInformationPropertyAction({ isLinkToConfirmed: false }));
		}
	}, [isLinkToConfirmed]);

	useEffect(() => {
		if (credentialPreviewBadge) setDisplayPreview(true);
	}, [credentialPreviewBadge]);

	const linkToChkChanged = (init?: boolean) => {
		if (credentialTemplates?.length > 0) {
			let lTemplateId = 0;
			if (linkToCredential) {
				if (linkedTemplateId < 1) {
					lTemplateId = credentialTemplates[0].CredentialTemplateId;
					dispatcher(setInformationPropertyAction({ linkedTemplateId: lTemplateId }));
				}
				setBadgeTemplateD(true);
				if (!init && lTemplateId > 0) {
					fetchCredentialInformation(lTemplateId);
				}
			} else {
				dispatcher(setInformationPropertyAction({ linkedTemplateId: 0 }));
				setBadgeTemplateD(linkToCredential);
			}
		}
	};

	const fetchCredentialInformation = (credTemplateId: number) => {
		dispatcher(setRootModelPropertyAction({ fetchedCredential: false }));
		credentialApi.getCredentialInformation(credTemplateId, isNew, undefined).then(credentialInfo => {
			const credentialModel = mapModelCredentialToJs(credentialInfo, credentialInitialState, true, IsDeviceAdminCredentialTemplate);
			credentialModel.fetchedCredential = true;
			credentialModel.modelEstablished = true;
			dispatcher(setCompleteModelAction(credentialModel));
		});
	};

	const disableFieldsForTemplate = () => {};

	const previewBadgesAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.Badges_Badges_Preview).allowed;

	const handleOnChangeLinkToCredential = () => {
		ModalConfirmation({
			cancelText: _('No'),
			content: (
				<div>
					{!linkToCredential ? (
						<>
							{_('CredentialLinkMessageA')} <br /> {_('CredentialLinkMessageB')}
						</>
					) : (
						<>
							{_('CredentialUnlinkMessageA')} <br /> {_('CredentialUnlinkMessageB')}
						</>
					)}
				</div>
			),
			okText: _('Yes'),
			onConfirm: () => {
				handleOnConfirmLinkTo();
			},
			width: '600px',
		});
	};

	const handleChangeInformationProperty = (value: GeneralTabInformationProperty) => {
		batch(() => {
			dispatcher(setRootModelPropertyAction({ hasChanged: true }));
			dispatcher(setInformationPropertyAction(value));
		});
	};

	const handleOnConfirmLinkTo = () => {
		batch(() => {
			dispatcher(setRootModelPropertyAction({ hasChanged: true }));
			dispatcher(setInformationPropertyAction({ linkToCredential: !linkToCredential, isLinkToConfirmed: true }));
		});
	};

	const handleOnClickPreviewBadge = () => {
		const closeModalFunction = () => {
			dispatcher(
				setRootModelPropertyAction({
					alertModalObj: {
						children: undefined,
						hidden: true,
					},
				})
			);
		};
		if (badgeTemplate > 0) {
			setCredentialPreviewBadge({
				ActivationDate: new Date(activationDate),
				BadgeTemplate: badgeTemplate,
				CardData: cardData,
				CardStamp: cardStamp,
				CredentialId: credentialId <= 0 ? credentialTemplateId : credentialId,
				Description: credentialDescription,
				ExpirationDate: new Date(expiration),
				HostExpireUsed: expirationUsed,
				MatchCode: matchCode,
				UserId: userId,
			});
		} else {
			dispatcher(
				setRootModelPropertyAction({
					alertModalObj: {
						children: <span data-test-id='badgeTemplateNoneWarningModalMsg'>{SelectFirst}</span>,
						hidden: false,
						title: _('Warning'),
						onOk: closeModalFunction,
						onCancel: closeModalFunction,
					},
				})
			);
		}
	};

	const handleOnHidePreviewBadgeModal = () => {
		setCredentialPreviewBadge(undefined);
		setDisplayPreview(false);
	};

	const getSelectedBadgeTemplate = () => {
		if (badgeTemplates?.length > 0) {
			if (badgeTemplates.some(x => x.CanvasId === badgeTemplate)) return badgeTemplate;
			return badgeTemplates[0].CanvasId;
		}
		return -1;
	};

	const handleCredentialTemplateChange = (value: string) => {
		const tempCredentialTemplateId: number = value ? parseInt(value, 10) : 0;
		handleChangeInformationProperty({ linkedTemplateId: tempCredentialTemplateId, isLinkToConfirmed: !isLinkToConfirmed });
		fetchCredentialInformation(tempCredentialTemplateId);
	};

	return (
		<>
			<div className={styles.containerLegend}>
				<fieldset className={'fieldset'}>
					<legend className={styles.legend}>{_('Information')}</legend>
					<div className={styles.form}>
						<div className={styles.sections}>
							<div>
								<label className={styles.contentSpan}>{_('ID')}:</label>
								<label id='credentialIdDisplay' className='credentialIdDisplay'>
									{credentialId > 0 ? credentialId : `(${_('New')})`}
								</label>
							</div>
						</div>
						<div className={styles.sections} style={IsDeviceAdminCredentialTemplate ? { gridTemplateColumns: 'auto' } : {}}>
							<div>
								<label htmlFor='linkToNewCredentials' title={canLinkToTemplate ? '' : _('DisabledButtonPermissionsWarning')}>
									<Checkbox
										aria-label='linkToNewCredentials'
										checked={linkToNewCredentials}
										className={cx({ [styles.hidden]: !IsDeviceAdminCredentialTemplate })}
										disabled={!canLinkToTemplate}
										id='linkToNewCredentials'
										onChange={(e: CheckboxChangeEvent) => {
											handleChangeInformationProperty({ linkToNewCredentials: e.target.checked });
										}}>
										&nbsp;{_('LinkToNew')}:
									</Checkbox>
								</label>
								<Checkbox
									aria-label='linkToCredential'
									checked={linkToCredential}
									className={cx({ [styles.hidden]: IsDeviceAdminCredentialTemplate })}
									disabled={!canLinkToTemplate}
									id='linkToCredential'
									onChange={handleOnChangeLinkToCredential}>
									<label htmlFor='linkToCredential' title={canLinkToTemplate ? '' : _('DisabledButtonPermissionsWarning')}>
										&nbsp;{_('Linkto')}:
									</label>
								</Checkbox>
							</div>
							<Select
								aria-label={_('CredentialTemplate')}
								className={cx(styles.select, {
									[styles.hidden]: IsDeviceAdminCredentialTemplate,
								})}
								id='linkToTemplate'
								getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
								disabled={!canLinkToTemplate || !linkToCredential}
								onChange={(value: string) => {
									handleCredentialTemplateChange(value);
								}}
								options={credentialTemplates.map(credentialTemplate => ({
									label: credentialTemplate.Description,
									value: credentialTemplate.CredentialTemplateId.toString(),
									key: credentialTemplate.CredentialTemplateId.toString(),
								}))}
								value={linkedTemplateId ? linkedTemplateId.toString() : ''}
							/>
						</div>
						<div className={styles.badgeTemplateSection}>
							<label htmlFor='badgeTemplate'>{_('BadgeTemplate')}:</label>
							<div>
								<Select
									className={styles.select}
									disabled={badgeTemplateD}
									id='badgeTemplate'
									getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
									onChange={value => handleChangeInformationProperty({ badgeTemplate: value })}
									options={badgeTemplates.map(badgeTemplate => ({
										label: badgeTemplate.DisplayName,
										value: badgeTemplate.CanvasId,
										key: badgeTemplate.CanvasId,
									}))}
									value={getSelectedBadgeTemplate()}
								/>
							</div>
							<div>
								<Button
									data-test-id='previewBadge'
									id='previewBadge'
									name='previewBadge'
									type='text'
									size='large'
									disabled={!previewBadgesAllowed}
									className={cx({ [styles.hidden]: isCredentialTemplate })}
									onClick={handleOnClickPreviewBadge}
									title={getPermissionErrorMessage(previewBadgesAllowed) || _('PreviewBadgeTemplate')}>
									<FileSearchOutlined style={{ fontSize: 24, color: 'black' }} />
								</Button>
							</div>
						</div>
						<div className={styles.sections}>
							<label htmlFor='credentialDescription'>
								{_('Description')}:
								<WarningTwoTone twoToneColor={'#ff0000'} className={cx(styles.centeredIcon, { [styles.hidden]: !credentialDescriptionE })} />
							</label>
							<div className={cx({ [styles.error]: credentialDescriptionE })}>
								<div className={styles.gridTwoAutoCols}>
									<Input
										className={styles.input}
										defaultValue={
											credentialDescription ? credentialDescription : IsDeviceAdminCredentialTemplate ? '' : _('DefaultTemplate')
										}
										id='credentialDescription'
										maxLength={50}
										name='credentialDescription'
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											handleChangeInformationProperty({ credentialDescription: e.target.value });
										}}
										value={credentialDescription}
									/>
								</div>
							</div>
						</div>
						{name && (
							<div className={styles.sections}>
								<div></div>
								<label className={styles.errorMessage} htmlFor='credentialDescription'>
									{name}
								</label>
							</div>
						)}
					</div>
				</fieldset>
			</div>
			{displayPreview && (
				<PreviewBadgeCredential
					credentialPreviewBadge={credentialPreviewBadge}
					onHideModal={handleOnHidePreviewBadgeModal}
					credentialsIds={[credentialId]}
				/>
			)}
		</>
	);
};

export default InformationSection;

import { Col, Divider, Row, Select } from 'antd';
import React, { useContext } from 'react';
import { GeneralTabProps } from '../../CredentialTemplateModalModels';
import { setGtIdfAction } from '../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../credentialTemplateContext/context';
import styles from '../../credentialmodal.module.scss';
import ActivationExpirationSection from './Sections/ActivationExpirationSection';
import CardSection from './Sections/Card/CardSection';
import InformationSection from './Sections/InformationSection';
import KeypadSection from './Sections/KeypadSection';

const CredentialTemplateModalGeneralTab: React.FC<GeneralTabProps> = ({ user, isNew }) => {
	const { credentialInitialState, dispatcher } = useContext(CredentialStoreContext);

	const {
		generalTabState: {
			cardSectionState: { cardFormats },
			IDF,
			informationSectionState: { badgeTemplates },
		},
	} = credentialInitialState;

	const handleOnChangeIDF = (IDFValue: number) => {
		dispatcher(setGtIdfAction(IDFValue));
	};

	return (
		<>
			{badgeTemplates?.length > 0 && cardFormats?.length > 0 && (
				<div id='generalTabContainer' className={styles.containerGeneralTab}>
					<Row key='row-1'>
						<Col span={12} style={{ borderRight: 'solid 1px #EEE' }} key='col-1'>
							<InformationSection user={user} isNew={isNew} />
						</Col>
						<Col span={12} key='col-2'>
							<ActivationExpirationSection />
						</Col>
					</Row>
					<Divider style={{ margin: 0, padding: 0 }} key='divider-1' />
					<Row style={{ marginTop: 15 }} key='row-2'>
						<Col span={24}>
							<div className={styles.containerLegend}>
								<fieldset>
									<legend className={styles.legend}>{_('CredentialInformation')}</legend>
									<div className={styles.form}>
										<div className={styles.sections}>
											<label htmlFor='idf'>{_('IDF')}:</label>
											<Select
												id='idf'
												className={styles.select}
												getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
												onChange={handleOnChangeIDF}
												style={{ width: '50%' }}
												value={IDF}>
												<Select.Option value={0}>0 - {_('BadgeOnly')}</Select.Option>
												<Select.Option value={1}>1 - {_('Keypad')}</Select.Option>
												<Select.Option value={2}>2 - {_('Card')}</Select.Option>
												<Select.Option value={3}>3 - {_('Dual')}</Select.Option>
												<Select.Option value={4}>
													4 - {_('Card')}+{_('Dual')}
												</Select.Option>
												<Select.Option value={5}>
													5 - {_('Keypad')}+{_('Dual')}
												</Select.Option>
												<Select.Option value={6}>
													6 - {_('Keypad')}+{_('Card')}
												</Select.Option>
												<Select.Option value={7}>
													7 - {_('Keypad')}/{_('Card')}/{_('Dual')}
												</Select.Option>
											</Select>
										</div>
									</div>
								</fieldset>
							</div>
						</Col>
					</Row>
					<Divider style={{ margin: 0, padding: 0 }} key='divider-2' />
					<Row style={{ marginTop: 15 }} key='row-3'>
						<Col span={12} style={{ paddingRight: 10, borderRight: 'solid 1px #EEE' }} key='col-1'>
							<CardSection isNew={isNew} />
						</Col>
						<Col span={12}>
							<KeypadSection user={user} />
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export { CredentialTemplateModalGeneralTab };

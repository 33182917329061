import React from 'react';
import { HeaderBar } from '../../components/common';
import { EventTable } from '../../components/event';
import { WhosInside } from '../../components/whosInside/WhosInside';
import { User } from '../../model/AccountModel';
import { Logger } from '../../model/LoggingModel';

// Internal state for the component
interface State {}

// Props those being mapped from Store
interface StateProps {}

// Dispatchable methods (invoke our store's actions)
interface DispatchProps {}

// Normal properties for the component
interface OwnProps {}

// combine them together
type Props = StateProps & DispatchProps & OwnProps;

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const { showEventsOnWhosInsidePage } = user;

class WhosInsidePage extends React.Component<Props, State> {
	constructor(prop) {
		super(prop);
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	render() {
		return (
			<>
				<HeaderBar title={_('WhosInside')} />
				<WhosInside />
				{showEventsOnWhosInsidePage && <EventTable />}
			</>
		);
	}
}

export { WhosInsidePage };

import React, { useContext, useEffect, useState } from 'react';
import { ReaderLabel } from '../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { StoreContext } from '../../../contextReader';
import styles from '../../General/general.module.scss';
import { RS485Body, ReaderType, WirelessVersion } from '../../sections';

type Props = {
	submittedForm: boolean;
	handleDownloadFirmware: () => void;
	useDoorContext: boolean;
};

const RS485Version: React.FC<Props> = ({ submittedForm, handleDownloadFirmware, useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { labels },
			},
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { labels },
		} = useContext(StoreContext);
	}

	const [fieldsetLabel, setFieldsetLabel] = useState('');
	const [rs485Body, setRs485Body] = useState<JSX.Element>(<></>);

	useEffect(() => {
		const isWireless: ReaderLabel = labels.find(x => x.Label === 'GeneralSecondFieldSet');
		if (isWireless) {
			setFieldsetLabel(isWireless.Value);
			setRs485Body(<WirelessVersion submittedForm={submittedForm} useDoorContext={useDoorContext}></WirelessVersion>);
		} else {
			setFieldsetLabel(useDoorContext ? _('RS485OSDP') : _('RS485ReaderVersion'));
			setRs485Body(<RS485Body handleDownloadFirmware={handleDownloadFirmware} useDoorContext={useDoorContext}></RS485Body>);
		}
	}, [labels]);

	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{fieldsetLabel}</legend>
					<div>
						<ReaderType useDoorContext={useDoorContext} />
						{rs485Body}
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { RS485Version };

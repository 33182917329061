import { PaginationSetting, ResponseObjectEntity } from '../model/CommonModel';
import {
	AccessSummaryInfo,
	ControllerStatusViewer,
	CredentialSummary,
	DigiTracTransaction,
	DoorAccess,
	DoorStatusData,
	PerformanceData,
	PortStatusViewer,
	StatusDashboard,
	UserModule,
	XboxStatusViewer,
} from '../model/StatusModel';
import { OffNormalPoints } from '../model/StatusViewerModel';
import { Api } from './api';

class StatusApi extends Api {
	public constructor() {
		//NEVER FORGET THE SUPER
		super();
	}

	public retrievePerformanceStatus = async (): Promise<PerformanceData> => {
		const url = this.getUrl('Status/GetPerformanceStatus', true);

		return await this.post<PerformanceData>(url);
	};

	public retrieveDownloadSummary = async (): Promise<DigiTracTransaction[]> => {
		const url = this.getUrl('Status/RetrieveDownloadSummary', true);

		return await this.post<DigiTracTransaction[]>(url);
	};

	public retrieveDoorAccess = async (doorId: number): Promise<DoorAccess> => {
		const url = this.getUrl('Status/GetRetrieveDoorAccess', true);
		const data = {
			doorId,
		};

		return await this.post<DoorAccess>(url, data);
	};

	public retrieveDoorsStatus = async (): Promise<DoorStatusData[]> => {
		const url = this.getUrl('Status/GetRetrieveDoorsStatus', true);

		return await this.post<DoorStatusData[]>(url);
	};

	public retrieveCredentialSummary = async (): Promise<CredentialSummary> => {
		const url = this.getUrl('Status/GetCredentialSummary', true);

		return await this.post<CredentialSummary>(url);
	};

	public retrieveControllerStatusViewer = async (): Promise<ControllerStatusViewer[]> => {
		const url = this.getUrl('Status/GetRetrieveControllerStatusViewer', true);

		return await this.post<ControllerStatusViewer[]>(url);
	};

	public retrievePortStatusViewer = async (): Promise<PortStatusViewer[]> => {
		const url = this.getUrl('Status/GetRetrievePortStatusViewer', true);

		return await this.post<PortStatusViewer[]>(url);
	};

	public retrieveXboxStatusViewer = async (): Promise<XboxStatusViewer[]> => {
		const url = this.getUrl('Status/GetRetrieveXboxStatusViewer', true);

		return await this.post<XboxStatusViewer[]>(url);
	};

	public retrieveAccessSummary = async (paginationSetting: PaginationSetting): Promise<AccessSummaryInfo> => {
		const url = this.getUrl('Status/GetAccessSummary', true);
		const data = {
			paginationSetting,
		};

		return await this.post<AccessSummaryInfo>(url, data);
	};

	public retrieveStatusDashboard = async (): Promise<StatusDashboard> => {
		const url = this.getUrl('Status/GetStatusDashboard', true);

		return await this.post<StatusDashboard>(url);
	};

	public retrieveOnlineActiveUsers = async (): Promise<UserModule[]> => {
		const url = this.getUrl('Status/OnlineActiveUsers', true);

		return await this.post<UserModule[]>(url);
	};

	public abortDownload = async (batchIds: number[]): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('Status/AbortDownload', true);

		return await this.post<ResponseObjectEntity<boolean>>(url, { batchIds });
	};

	public retrieveOffNormalPoints = async (): Promise<OffNormalPoints> => {
		const url = this.getUrl('Status/GetOffNormalPoints', true);

		return await this.post<OffNormalPoints>(url);
	};
}

const statusApi = new StatusApi();

export { statusApi };

import { Button, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { CSSProperties, useEffect, useState } from 'react';
import { ScrollType } from '../../../../Helper';
import { AffectedCredential, TreeViewPersonGroupModel } from '../../../../model/EnrollmentModel';
import { Modal } from '../../../common';
import { SearchPersonBar } from '../../../enrollment';
import styles from './assignCredential.module.scss';

export type AssignCredentialWarningData = AffectedCredential & {
	key: number;
};

const allAvailablePersonGroup: TreeViewPersonGroupModel = { GroupId: -1, FolderId: -1, Name: '' };
const modalBodyStyle: CSSProperties = { maxHeight: '60vh', height: '100%', minHeight: '35vh' };
const scroll: ScrollType = { x: '350px', y: '20vh' };
type Props = {
	onHideModal: () => void;
	onAssignCredential: (userId: number) => void;
	credentialsToAssign: AffectedCredential[];
};

const AssignCredential: React.FC<Props> = ({ onAssignCredential, onHideModal, credentialsToAssign }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [userId, setUserId] = useState<number>(0);
	const [option, setOption] = useState(undefined);
	const [dataSource, setDataSource] = useState<AssignCredentialWarningData[]>([]);

	useEffect(() => {
		const dataSource: AssignCredentialWarningData[] = credentialsToAssign.map<AssignCredentialWarningData>((credential: AffectedCredential) => ({
			...credential,
			key: credential.CredentialId,
		}));
		setDataSource(dataSource);
		setIsLoading(false);
	}, []);

	const columns: ColumnProps<AssignCredentialWarningData>[] = [
		{
			title: _('CID'),
			dataIndex: 'CredentialId',
			width: '100px',
		},
		{
			title: _('Description'),
			dataIndex: 'Description',
		},
	];

	const handleOnSelectPersonSearch = option => {
		setOption(option.children);
		setUserId(Number(option.value));
	};

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={onHideModal}
			onClickOk={() => null}
			width={670}
			title={_('AssignPersonToCredentials')}
			visible={true}
			className={styles.modalContainer}
			bodyStyle={modalBodyStyle}
			footer={[
				<Button id='assignCredentialsButton' key='assign' type='primary' onClick={() => onAssignCredential(userId)} disabled={userId === 0}>
					{_('Assign')}
				</Button>,
			]}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div className={styles.bodyContainer}>
					<div>
						<p>{_('AssignPersonToCredentialMessage')}</p>
						<SearchPersonBar onSelectPerson={handleOnSelectPersonSearch} inModal={true} personGroup={allAvailablePersonGroup} />
					</div>
					<div className={styles.selectedPerson}>{option}</div>
					<div>
						<Table
							id='assignCredentialsTable'
							scroll={scroll}
							pagination={false}
							dataSource={dataSource}
							columns={columns}
							size='small'
							className={styles.assignCredentialTable}
							rowClassName={(record: AssignCredentialWarningData, index) => {
								if (index % 2 !== 0) {
									return styles.evenRow;
								}
							}}
						/>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export { AssignCredential };

import { Modal } from 'antd';
import React from 'react';
import styles from './modalAlert.module.scss';

type Props = {
	title: React.ReactNode;
	children?: React.ReactNode;
};

const ModalAlert = ({ children, title }: Props) => {
	return Modal.warning({
		width: '520px',
		keyboard: false,
		maskClosable: false,
		content: children,
		title,
		okText: _('Ok'),
		okButtonProps: { id: 'timeZonesInUsedByOkButton', className: styles.okButton },
		icon: <div></div>,
		className: styles.body,
	});
};

export { ModalAlert };

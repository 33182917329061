import React, { CSSProperties, FC } from 'react';

type CustomIconProperties = {
	label?: string;
	style?: CSSProperties;
};

const AlarmsIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M9.4,4.74c-.04,.13-.14,.18-.27,.17-.21,0-.42,0-.62,0-.14,0-.22-.07-.22-.19,0-.12,.08-.19,.23-.19,.21,0,.42,0,.62,0,.14,0,.23,.04,.27,.18v.04Z' />
			<path d='M4.76,0c.1,.06,.14,.14,.14,.26,0,.19,0,.39,0,.58,0,.14-.08,.22-.2,.22-.11,0-.19-.09-.19-.22,0-.19,0-.39,0-.58,0-.12,.03-.2,.14-.26h.12Z' />
			<path d='M7.59,7.52s.07,.02,.1,.03c.3,.09,.51,.36,.53,.67,0,.02,0,.03,0,.05-.02,.14,0,.25,.16,.32,.16,.08,.23,.25,.23,.43,0,.2,0,.4,0,.6,0,.13-.08,.21-.2,.21-.94,0-1.89,0-2.83,0-.12,0-.21-.08-.21-.19,0-.11,.09-.19,.22-.19,.84,0,1.68,0,2.52,0,.04,0,.08,0,.12,0,0-.15,0-.29,0-.44,0-.08-.06-.13-.15-.14-.03,0-.07,0-.11,0-2.19,0-4.37,0-6.56,0-.21,0-.26,.04-.26,.26,0,.1,0,.2,0,.32h.12c.82,0,1.65,0,2.47,0,.03,0,.06,0,.1,0,.1,0,.18,.09,.18,.19,0,.1-.08,.18-.18,.19-.03,0-.05,0-.08,0-.91,0-1.82,0-2.73,0-.2,0-.26-.06-.26-.26,0-.17,0-.35,0-.52,0-.25,.11-.42,.33-.53,.05-.03,.07-.06,.07-.11,0-.1,0-.21,.01-.31,.06-.29,.24-.48,.53-.57,.02,0,.05-.02,.08-.02,0-.04,0-.08,0-.12,0-1,0-2,0-3,0-.84,.3-1.57,.92-2.14,.82-.75,1.8-.98,2.86-.64,1.06,.34,1.71,1.09,1.95,2.18,.05,.21,.06,.43,.06,.64,0,.99,0,1.98,0,2.97v.12Zm-.38,0v-.1c0-1,0-2,0-3,0-.13-.01-.26-.03-.39-.22-1.5-1.69-2.46-3.15-2.06-1.09,.3-1.83,1.28-1.84,2.44,0,1.01,0,2.02,0,3.02,0,.03,0,.06,0,.09h1.96v-.1c0-.41,0-.82,0-1.23,0-.07-.02-.11-.08-.15-.34-.28-.48-.64-.38-1.07,.1-.46,.49-.77,.96-.79,.44-.02,.86,.26,1.01,.68,.16,.43,.02,.91-.35,1.18-.05,.04-.07,.08-.07,.14,0,.41,0,.82,0,1.23v.11h1.96Zm.62,.97c0-.1,0-.19,0-.28-.02-.18-.17-.3-.38-.3-1.83,0-3.67,0-5.5,0-.03,0-.06,0-.1,0-.15,.03-.25,.12-.28,.27-.02,.09,0,.2,0,.3H7.83Zm-2.96-.97s0-.07,0-.11c0-.45,0-.91,0-1.36,0-.12,.04-.2,.15-.26,.21-.12,.32-.31,.33-.55,.02-.31-.21-.59-.51-.66-.32-.07-.64,.1-.75,.4-.11,.29,0,.65,.28,.8,.12,.07,.16,.15,.16,.28,0,.45,0,.9,0,1.35v.1h.34Z' />
			<path d='M.56,4.53c.11,0,.22,0,.34,0,.13,0,.21,.08,.21,.19,0,.11-.08,.19-.21,.19-.23,0-.46,0-.69,0-.12,0-.21-.08-.21-.19,0-.11,.09-.19,.21-.19,.12,0,.23,0,.35,0Z' />
			<path d='M1.7,2.85c-.02,.15-.15,.25-.27,.19-.23-.12-.46-.25-.68-.39-.08-.05-.1-.17-.05-.25,.05-.08,.15-.12,.24-.07,.22,.12,.45,.25,.66,.38,.04,.03,.07,.09,.1,.14Z' />
			<path d='M7.69,2.85c.04-.05,.07-.12,.12-.15,.21-.13,.42-.25,.63-.36,.1-.05,.21-.02,.26,.07,.05,.09,.03,.21-.07,.27-.21,.12-.42,.25-.63,.36-.14,.07-.29-.02-.3-.18Z' />
			<path d='M2.37,.58c.06,.04,.12,.05,.15,.09,.16,.18,.32,.36,.47,.54,.08,.09,.07,.2-.01,.27-.08,.07-.19,.07-.27-.02-.16-.18-.32-.36-.47-.54-.05-.06-.07-.14-.03-.21,.04-.05,.1-.09,.16-.13Z' />
			<path d='M7.23,.77c-.04,.07-.05,.12-.09,.16-.15,.17-.3,.34-.45,.51-.09,.1-.2,.11-.29,.04-.09-.07-.09-.19,0-.29,.15-.17,.3-.35,.45-.52,.06-.07,.14-.1,.22-.06,.06,.03,.1,.1,.14,.15Z' />
			<path d='M4.89,9.64c0,.1-.08,.19-.19,.2-.1,0-.19-.08-.2-.18,0-.11,.08-.2,.19-.2,.1,0,.19,.09,.19,.19Z' />
		</svg>
	</span>
);

const DeviceControlIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M7.27,9.5H0V0H7.27V9.5ZM.56,.56V8.94H6.71V.56H.56Z' />
			<path d='M9.5,1.13V6.7s-.01,.05-.02,.07c-.05,.62-.22,1.2-.51,1.75-.09,.18-.2,.36-.31,.54-.07-.11-.12-.21-.18-.3-.44-.76-.66-1.57-.66-2.45,0-1.69,0-3.38,0-5.06v-.12h.56V.58h.55v.55h.56Zm-.56,.56h-.55v2.77h.55V1.7Zm-.28,6.16s.03-.07,.04-.1c.16-.44,.24-.89,.24-1.36,0-.43,0-.85,0-1.28,0-.03,0-.06,0-.09h-.54s0,.02,0,.03c0,.57,0,1.15,.02,1.72,.01,.36,.11,.71,.25,1.07Z' />
			<path d='M1.14,6.55l.49-.25c.29,.51,.48,1.07,.89,1.53,.07-.08,.12-.14,.17-.21,.13-.19,.24-.38,.37-.57,.05-.08,.12-.15,.19-.21,.23-.2,.53-.21,.76,0,.13,.12,.24,.28,.35,.43,.08,.11,.14,.23,.22,.34,.04,.07,.1,.13,.16,.21,.07-.07,.13-.13,.18-.2,.05-.07,.09-.14,.14-.22l.49,.25c-.14,.26-.29,.49-.54,.64-.21,.12-.45,.11-.63-.07-.14-.13-.25-.3-.36-.45-.09-.13-.17-.27-.26-.4-.1-.14-.13-.14-.23,0-.09,.13-.17,.26-.25,.39-.08,.12-.16,.25-.26,.36-.29,.33-.67,.34-.96,.01-.16-.19-.29-.4-.42-.62-.17-.31-.33-.64-.49-.96Z' />
			<path d='M1.12,2.8v-.55H6.15v.55H1.12Z' />
			<path d='M6.16,3.36v.55H1.12v-.55H6.16Z' />
			<path d='M1.12,4.48H6.16v.55H1.12v-.55Z' />
			<path d='M2.25,1.13h.54v.55h-.54v-.55Z' />
			<path d='M3.91,1.14v.55h-.55v-.55h.55Z' />
			<path d='M4.48,1.14h.55v.55h-.55v-.55Z' />
		</svg>
	</span>
);

const EnrollmentIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M4.71,0c.2,0,.39,0,.59,0,.13,.02,.26,.03,.4,.05,.99,.14,1.86,.53,2.6,1.19,.88,.78,1.43,1.74,1.63,2.9,.03,.19,.05,.38,.08,.57v.59s-.01,.07-.02,.1c-.02,.18-.04,.36-.07,.54-.19,1-.64,1.86-1.36,2.58-.75,.75-1.64,1.23-2.69,1.41-.19,.03-.38,.05-.57,.08h-.59c-.13-.02-.26-.03-.4-.05-.99-.14-1.86-.53-2.6-1.19C.83,7.98,.28,7.02,.08,5.86,.04,5.67,.03,5.48,0,5.29c0-.2,0-.39,0-.59,0-.03,.01-.07,.02-.1,.02-.18,.04-.36,.07-.54,.19-1,.64-1.86,1.36-2.58C2.19,.73,3.09,.26,4.14,.08c.19-.03,.38-.05,.57-.08Zm-.89,5.49c-1.15-.83-1.14-2.29-.41-3.12,.78-.87,2.08-.98,2.95-.23,.46,.4,.72,.91,.74,1.52,.02,.76-.3,1.37-.91,1.83,.54,.21,.99,.51,1.37,.93,.38,.42,.63,.91,.78,1.46,1.42-1.59,1.57-4.36-.36-6.14C6.09,.01,3.11,.26,1.54,2.26-.02,4.25,.56,6.71,1.67,7.87c.14-.55,.4-1.03,.78-1.45,.38-.42,.83-.72,1.37-.93Zm1.26,3.92c.1,0,.29-.02,.47-.04,.83-.1,1.58-.43,2.23-.96,.02-.02,.04-.07,.04-.1-.23-1.6-1.71-2.68-3.29-2.41-1.22,.21-2.16,1.18-2.34,2.41,0,.03,.02,.08,.04,.1,.81,.65,1.74,.98,2.85,.99Zm-.08-4.15c.84,0,1.51-.68,1.52-1.52,0-.83-.69-1.52-1.52-1.52-.84,0-1.53,.7-1.52,1.53,0,.84,.69,1.51,1.52,1.51Z' />
		</svg>
	</span>
);

const EventsIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M0,.49c.04-.08,.07-.16,.12-.22C.23,.1,.4,.01,.6,0c.04,0,.08,0,.12,0,2.65,0,5.3,0,7.95,0,.47,0,.71,.24,.71,.71,0,.41,0,.83,0,1.24,0,.41-.26,.67-.67,.67-2.68,0-5.36,0-8.04,0-.33,0-.53-.14-.65-.47,0,0-.01-.02-.02-.03V.49Zm4.67,1.75c1.34,0,2.68,0,4.02,0,.22,0,.31-.09,.31-.3,0-.42,0-.84,0-1.26,0-.22-.09-.3-.31-.3-2.67,0-5.34,0-8.01,0-.22,0-.31,.09-.31,.3,0,.41,0,.83,0,1.24,0,.25,.08,.33,.33,.33,1.32,0,2.65,0,3.97,0Z' />
			<path d='M0,3.49c.04-.08,.07-.16,.12-.22,.12-.17,.28-.25,.48-.27,.04,0,.08,0,.12,0,2.65,0,5.3,0,7.95,0,.47,0,.71,.24,.71,.71,0,.41,0,.83,0,1.24,0,.41-.26,.67-.67,.67-2.68,0-5.36,0-8.04,0-.33,0-.53-.14-.65-.47,0,0-.01-.02-.02-.03v-1.64Zm4.69-.12c-1.34,0-2.67,0-4.01,0-.22,0-.31,.09-.31,.3,0,.42,0,.84,0,1.26,0,.22,.09,.3,.31,.3,2.67,0,5.34,0,8.01,0,.22,0,.31-.09,.31-.3,0-.41,0-.83,0-1.24,0-.25-.08-.33-.33-.33-1.33,0-2.65,0-3.98,0Z' />
			<path d='M0,6.48c.04-.08,.07-.16,.12-.22,.12-.17,.28-.25,.48-.27,.04,0,.08,0,.12,0,2.65,0,5.3,0,7.95,0,.47,0,.71,.24,.71,.71,0,.41,0,.83,0,1.24,0,.41-.26,.67-.67,.67-2.68,0-5.36,0-8.04,0-.33,0-.53-.14-.65-.47,0,0-.01-.02-.02-.03v-1.64Zm4.7-.12c-1.34,0-2.68,0-4.02,0-.22,0-.3,.09-.31,.31,0,.42,0,.84,0,1.26,0,.21,.09,.3,.31,.3,2.67,0,5.34,0,8.01,0,.22,0,.31-.09,.31-.31,0-.41,0-.83,0-1.24,0-.25-.08-.33-.33-.33-1.32,0-2.65,0-3.97,0Z' />
			<path d='M4.68,1.5c-1.02,0-2.05,0-3.07,0-.03,0-.06,0-.09,0-.12,0-.2-.08-.2-.18,0-.11,.08-.18,.2-.19,.03,0,.05,0,.08,0,2.07,0,4.13,0,6.2,0,.03,0,.06,0,.09,0,.1,.01,.18,.09,.18,.18,0,.1-.07,.18-.17,.19-.04,0-.08,0-.12,0-1.03,0-2.06,0-3.09,0Z' />
			<path d='M3.55,4.49c-.1,0-.18-.09-.18-.2,0-.1,.09-.18,.2-.18,.1,0,.18,.09,.18,.2,0,.1-.09,.18-.2,.18Z' />
			<path d='M4.68,4.12c.1,0,.19,.08,.19,.18,0,.1-.08,.19-.18,.19-.1,0-.19-.08-.19-.18,0-.1,.08-.19,.18-.19Z' />
			<path d='M5.82,4.12c.1,0,.19,.09,.18,.19,0,.1-.09,.18-.19,.18-.1,0-.19-.09-.18-.19,0-.1,.09-.18,.19-.18Z' />
			<path d='M7.12,4.3c0,.1-.08,.19-.18,.19-.1,0-.19-.08-.19-.18,0-.1,.08-.19,.18-.19,.1,0,.19,.08,.19,.18Z' />
			<path d='M2.45,4.12c.1,0,.18,.08,.18,.19,0,.1-.08,.19-.19,.18-.1,0-.19-.09-.19-.19,0-.1,.09-.19,.19-.18Z' />
			<path d='M3.75,7.29c0,.1-.08,.19-.18,.19-.1,0-.19-.08-.19-.18,0-.1,.08-.19,.18-.19,.1,0,.19,.08,.19,.18Z' />
			<path d='M4.68,7.48c-.1,0-.19-.09-.18-.19,0-.1,.09-.18,.19-.18,.1,0,.19,.09,.18,.19,0,.1-.09,.18-.19,.18Z' />
			<path d='M5.81,7.48c-.1,0-.18-.09-.18-.19,0-.1,.09-.18,.19-.18,.1,0,.18,.09,.18,.19,0,.1-.09,.18-.19,.18Z' />
			<path d='M6.94,7.48c-.1,0-.19-.08-.19-.18,0-.1,.08-.19,.18-.19,.1,0,.19,.08,.19,.18,0,.1-.08,.19-.18,.19Z' />
			<path d='M2.44,7.48c-.1,0-.19-.09-.18-.19,0-.1,.09-.18,.19-.18,.1,0,.18,.09,.18,.19,0,.1-.09,.18-.19,.18Z' />
		</svg>
	</span>
);

const MenuCloseIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 20 20'>
			<g>
				<rect x='1.18' y='2' width='17.82' height='1.61' />
			</g>
			<g>
				<rect x='1.18' y='16.48' width='17.82' height='1.61' />
			</g>
			<g>
				<rect x='9' y='6.83' width='10' height='1.61' />
			</g>
			<g>
				<rect x='9' y='11.65' width='10' height='1.61' />
			</g>
			<polygon points='7,4.59 7,14.26 1.18,9.43 ' />
		</svg>
	</span>
);

const MenuOpenIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 20 20'>
			<g>
				<rect x='1.18' y='2' width='17.82' height='1.61' />
			</g>
			<g>
				<rect x='1.18' y='16.48' width='17.82' height='1.61' />
			</g>
			<g>
				<rect x='9' y='6.83' width='10' height='1.61' />
			</g>
			<g>
				<rect x='9' y='11.65' width='10' height='1.61' />
			</g>
			<polygon points='1.18,14.26 1.18,4.59 7,9.43 ' />
		</svg>
	</span>
);

const PhotoCallUpIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M5.91,9.67H0V2.21c.06-.11,.15-.14,.27-.13,.32,0,.63,0,.95,0h.1c.02-.13-.04-.27,.09-.33,.1-.05,.23-.04,.35-.06-.01-.02-.03-.05-.04-.08-.24-.43-.48-.86-.71-1.29C.9,.14,.98,0,1.18,0,1.59,0,2.01,0,2.42,0c.11,0,.18,.04,.23,.14,.11,.2,.22,.39,.32,.59,.01,.02,.03,.04,.05,.07,.13-.23,.25-.45,.37-.67C3.44,.04,3.5,0,3.6,0c.42,0,.85,0,1.27,0,.18,0,.26,.14,.18,.3-.01,.02-.02,.04-.04,.07-.23,.41-.46,.82-.69,1.24-.02,.03-.03,.06-.04,.08,.12,.02,.25,.02,.35,.07,.13,.06,.06,.21,.09,.33,.35,0,.7,0,1.05,0,.2,0,.26,.06,.26,.26,0,2.35,0,4.71,0,7.06,0,.12-.03,.21-.13,.27Zm-1.19-7.21s0,.08,0,.12c0,.19-.06,.26-.25,.26-.29,0-.58,0-.87,0-.68,0-1.36,0-2.04,0-.17,0-.24-.07-.24-.24,0-.05,0-.09,0-.13H.38v6.82H5.65V2.46h-.94Zm-1.76-.76c.28,0,.55,0,.81,0,.06,0,.09-.02,.12-.07,.22-.39,.43-.79,.65-1.18,.01-.02,.02-.04,.03-.07-.27,0-.54,0-.8,0-.06,0-.09,.02-.12,.07-.21,.39-.43,.78-.64,1.16-.01,.02-.03,.05-.05,.09ZM1.46,.38c.24,.44,.48,.86,.71,1.28,.01,.02,.04,.03,.07,.04,.08,0,.15,0,.23,0,.03,0,.06-.02,.08-.05,.08-.13,.15-.27,.22-.4,.02-.03,.02-.08,0-.11-.13-.24-.26-.47-.39-.71-.01-.02-.05-.05-.07-.05-.28,0-.55,0-.85,0Zm.24,2.07h2.63v-.37H1.7v.37Z' />
			<path d='M2.94,6.27c-.48,0-.96,0-1.44,0-.19,0-.26-.07-.25-.26,.03-.57,.25-1.03,.76-1.33,.06-.03,.06-.07,.04-.12-.11-.37-.03-.71,.21-1.02,.35-.44,1.03-.44,1.38,0,.24,.3,.31,.64,.2,1.01-.02,.06,0,.09,.05,.13,.52,.31,.74,.79,.75,1.37,0,.14-.08,.21-.23,.21-.49,0-.98,0-1.47,0Zm.69-1.3c-.5,.43-.83,.43-1.38,0-.36,.18-.6,.55-.61,.92h2.61c-.05-.41-.25-.72-.62-.92Zm-1.26-.66c.02,.06,.04,.19,.1,.29,.2,.38,.67,.41,.91,.05,.17-.25,.16-.62-.03-.86-.19-.23-.49-.27-.72-.1-.18,.14-.25,.33-.26,.61Z' />
			<path d='M1.61,7.41c.2,0,.41,0,.61,0,.14,0,.23,.07,.23,.19,0,.12-.09,.19-.23,.19-.41,0-.82,0-1.24,0-.14,0-.23-.07-.23-.19,0-.12,.09-.19,.23-.19,.21,0,.41,0,.62,0Z' />
			<path d='M1.61,8.54c.21,0,.42,0,.63,0,.15,0,.25,.13,.19,.26-.03,.08-.1,.11-.18,.11-.43,0-.87,0-1.3,0-.12,0-.2-.09-.2-.19,0-.1,.08-.18,.2-.19,.22,0,.44,0,.66,0Z' />
			<path d='M4.44,8.54c.21,0,.42,0,.63,0,.15,0,.25,.13,.19,.26-.03,.08-.1,.11-.18,.11-.43,0-.87,0-1.3,0-.12,0-.2-.08-.2-.19,0-.1,.08-.19,.2-.19,.22,0,.44,0,.66,0Z' />
			<path d='M2.99,6.65c.18,0,.35,0,.53,0,.14,0,.22,.07,.22,.19,0,.12-.08,.19-.22,.19-.35,0-.7,0-1.06,0-.14,0-.22-.07-.22-.19,0-.12,.08-.19,.22-.19,.18,0,.35,0,.53,0Z' />
			<path d='M3.5,7.41c.14,0,.29,0,.43,0,.13,0,.22,.08,.22,.19s-.09,.19-.22,.19c-.3,0-.59,0-.89,0-.13,0-.22-.08-.22-.19s.09-.19,.22-.19c.15,0,.3,0,.45,0Z' />
			<path d='M4.9,7.78c-.06,0-.12,0-.18,0-.12,0-.2-.09-.19-.19,0-.1,.08-.18,.19-.18,.13,0,.25,0,.38,0,.11,0,.19,.09,.19,.19,0,.1-.08,.18-.19,.19-.06,0-.13,0-.19,0Z' />
			<path d='M1.67,6.65c.1,0,.18,.08,.18,.19,0,.1-.08,.19-.18,.19-.1,0-.18-.08-.18-.19,0-.1,.08-.19,.18-.19Z' />
			<path d='M4.18,6.83c0-.1,.09-.18,.19-.18,.1,0,.18,.09,.18,.19,0,.1-.09,.18-.19,.18-.1,0-.18-.09-.18-.19Z' />
			<path d='M3.21,8.73c0,.1-.09,.19-.19,.18-.1,0-.19-.09-.18-.19,0-.1,.09-.19,.19-.18,.1,0,.19,.09,.18,.19Z' />
		</svg>
	</span>
);
const ReportingIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M8.51,5.21c-.09,.12-.19,.16-.26,.08-.05-.05-.08-.13-.08-.2,0-1.04,0-.78,0-1.82v-.15h-.13c-.58,0-1.17,0-1.75,0-.55,0-.9-.35-.9-.91,0-.28,0-.57,0-.86h-.13c-1.24,0-2.47,0-3.71,0-.15,0-.15,0-.15,.15,0,.22,0,.43,0,.65,0,.14,0,.14,.15,.14,1.05,0,2.1,0,3.16,0,.18,0,.26,.05,.26,.17,0,.12-.08,.17-.26,.17-1.06,0-2.11,0-3.17,0-.34,0-.48-.15-.48-.49,0-.23,0-.46,0-.69,0-.29,.16-.44,.45-.44,1.24,0,2.49,0,3.73,0h.14V.34h-.12c-1.45,0-2.9,0-4.35,0-.36,0-.57,.21-.57,.56,0,3.27,0,4.41,0,7.67,0,.35,.21,.56,.56,.56,2.23,0,4.47,0,6.7,0,.36,0,.57-.21,.57-.57,0-1.1,0-1.35,0-2.45,0-.03,0-.06,0-.09,0-.1,.01-.19,.13-.22,.11-.03,.17,.04,.21,.12v2.8s-.01,.03-.02,.05c-.11,.44-.42,.69-.87,.7-2.25,0-4.5,0-6.74,0-.09,0-.19-.01-.28-.04C.23,9.32,0,9,0,8.58,0,5.31,0,4.16,0,.9c0-.03,0-.05,0-.08C.03,.47,.2,.21,.52,.07,.59,.04,.66,.02,.72,0c1.63,0,3.26,0,4.89,0,.04,.04,.08,.08,.12,.12,.89,.89,1.78,1.78,2.66,2.67,.04,.04,.08,.09,.11,.14v2.28ZM5.73,.61s0,.03,0,.04c0,.55,0,1.1,0,1.65,0,.28,.22,.49,.5,.49,.54,0,1.08,0,1.62,0,.02,0,.04,0,.06,0L5.73,.61Z' />
			<path d='M1.99,5.5c-.2,0-.39,0-.59,0-.22,0-.35-.13-.35-.34,0-.4,0-.79,0-1.19,0-.22,.14-.35,.36-.35,.38,0,.77,0,1.15,0,.23,0,.36,.13,.36,.36,0,.39,0,.78,0,1.17,0,.23-.13,.35-.36,.36-.19,0-.38,0-.58,0Zm-.59-1.53v1.19h1.18v-1.19H1.4Z' />
			<path d='M1.99,8.41c-.2,0-.39,0-.59,0-.22,0-.35-.12-.35-.34,0-.4,0-.8,0-1.2,0-.21,.14-.34,.35-.34,.4,0,.79,0,1.19,0,.21,0,.34,.13,.34,.34,0,.4,0,.8,0,1.2,0,.21-.14,.33-.35,.34,0,0-.02,0-.02,0-.19,0-.38,0-.57,0Zm-.6-.35h1.18v-1.19H1.4v1.19Z' />
			<path d='M5.49,3.96c-.55,0-1.11,0-1.66,0-.09,0-.17-.02-.2-.11-.04-.1,.02-.2,.12-.23,.04,0,.08,0,.12,0,1.08,0,2.16,0,3.24,0,.02,0,.05,0,.07,0,.12,0,.2,.07,.19,.18,0,.1-.07,.16-.19,.16-.56,0-1.12,0-1.69,0Z' />
			<path d='M5.48,4.64c-.55,0-1.1,0-1.65,0-.06,0-.13-.02-.17-.06-.09-.08-.05-.23,.07-.27,.04-.01,.09-.01,.13-.01,1.08,0,2.17,0,3.25,0,.02,0,.05,0,.07,0,.11,0,.19,.07,.19,.17,0,.1-.07,.17-.19,.17-.41,0-.83,0-1.24,0-.15,0-.3,0-.45,0Z' />
			<path d='M5.49,6.87c-.54,0-1.08,0-1.62,0-.04,0-.08,0-.11,0-.09-.01-.14-.06-.14-.15,0-.09,.04-.15,.12-.18,.04-.01,.08,0,.12,0,1.09,0,2.17,0,3.26,0,.05,0,.11,0,.14,.02,.05,.04,.09,.11,.1,.17,.01,.08-.05,.13-.13,.15-.04,0-.08,0-.11,0-.54,0-1.09,0-1.63,0Z' />
			<path d='M5.49,7.55c-.55,0-1.11,0-1.66,0-.08,0-.16-.01-.2-.1-.05-.1,0-.21,.12-.24,.04,0,.08,0,.12,0,1.08,0,2.16,0,3.24,0,.02,0,.04,0,.06,0,.13,0,.21,.07,.2,.18,0,.1-.08,.16-.2,.16-.56,0-1.12,0-1.67,0Z' />
		</svg>
	</span>
);

const StatusDashboardIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M9,9.37H.37c-.18-.06-.31-.19-.37-.37V.37C.1,.09,.3,0,.6,0,3.35,0,6.1,0,8.86,0c.32,0,.51,.19,.51,.51,0,2.78,0,5.57,0,8.35,0,.05,0,.09-.01,.14-.04,.2-.18,.31-.36,.37Zm.06-7.8H.31v.12c0,2.37,0,4.75,0,7.12,0,.21,.04,.24,.24,.24,2.75,0,5.5,0,8.26,0,.21,0,.25-.04,.25-.24,0-2.38,0-4.75,0-7.13v-.11ZM.31,1.25H9.06c0-.23,0-.46,0-.69,0-.21-.04-.24-.24-.24-2.75,0-5.5,0-8.25,0-.03,0-.06,0-.09,0-.1,0-.16,.06-.16,.16,0,.25,0,.51,0,.77Z' />
			<path d='M6.71,8.43c-.5,0-1,0-1.5,0-.17,0-.21-.05-.21-.21,0-.79,0-1.59,0-2.38,0-.17,.05-.21,.21-.21,1,0,2,0,3.01,0,.17,0,.21,.05,.21,.21,0,.79,0,1.59,0,2.38,0,.17-.05,.21-.21,.21-.5,0-1,0-1.5,0Zm-1.4-2.49v1.78c.16-.15,.32-.31,.48-.47,.1-.09,.17-.09,.27,0,.05,.05,.1,.1,.16,.16,.11-.15,.22-.28,.32-.42,.06-.08,.13-.12,.23-.12,.08,0,.16,0,.24,0,.06,0,.09-.02,.11-.07,.12-.2,.24-.41,.37-.61,.03-.05,.09-.09,.15-.1,.07-.02,.12,.04,.15,.11,.09,.18,.18,.35,.27,.53,0,.02,.02,.04,.04,.07v-.87h-2.8Zm0,2.17h2.8v-1.17c-.15,.13-.24,.11-.32-.07-.05-.1-.1-.19-.15-.3-.11,.18-.21,.34-.3,.5-.04,.07-.1,.11-.19,.11-.09,0-.19,0-.28,0-.04,0-.09,.03-.11,.06-.12,.16-.24,.32-.36,.48-.08,.11-.17,.11-.27,.02-.06-.06-.12-.12-.19-.2-.1,.11-.19,.21-.28,.3-.05,.05-.11,.1-.17,.1-.05,0-.11-.05-.16-.07v.24Z' />
			<path d='M1.25,3.59c0-.78,.64-1.41,1.41-1.4,.78,0,1.4,.64,1.4,1.42,0,.77-.64,1.4-1.42,1.39-.76,0-1.39-.64-1.39-1.41Zm1.25-1.07c-.39,0-.8,.38-.91,.82-.12,.47,.1,.97,.54,1.21,.38,.21,.92,.17,1.17-.09-.17-.17-.33-.35-.51-.51-.23-.19-.32-.4-.29-.7,.03-.24,0-.49,0-.73Zm.32,0v.91h.91c-.02-.43-.49-.89-.91-.91Zm.26,1.23l.47,.47c.1-.14,.17-.29,.19-.47h-.66Z' />
			<path d='M6.71,3.75c-.49,0-.99,0-1.48,0-.03,0-.06,0-.09,0-.08-.02-.13-.07-.13-.15,0-.09,.05-.14,.13-.15,.03,0,.06,0,.09,0,1,0,1.99,0,2.99,0,.03,0,.06,0,.09,0,.08,.02,.13,.07,.13,.15,0,.09-.05,.14-.13,.15-.03,0-.06,0-.09,0-.5,0-1,0-1.5,0Z' />
			<path d='M6.71,3.13c-.49,0-.99,0-1.48,0-.03,0-.07,0-.1,0-.08-.02-.13-.07-.13-.15,0-.09,.05-.14,.13-.16,.03,0,.06,0,.09,0,1,0,2,0,3,0,.03,0,.05,0,.08,0,.08,.01,.13,.06,.14,.15,0,.08-.06,.15-.14,.16-.03,0-.06,0-.09,0-.5,0-1,0-1.49,0Z' />
			<path d='M6.71,4.37c-.49,0-.99,0-1.48,0-.03,0-.06,0-.09,0-.08-.02-.14-.07-.14-.15,0-.09,.05-.14,.13-.15,.02,0,.05,0,.07,0,1.01,0,2.02,0,3.03,0,.02,0,.03,0,.05,0,.09,0,.16,.07,.16,.15,0,.08-.06,.15-.15,.16-.09,0-.18,0-.26,0-.44,0-.87,0-1.31,0Z' />
			<path d='M2.65,6.56c-.5,0-1,0-1.49,0-.03,0-.06,0-.09,0-.08-.02-.13-.07-.13-.16,0-.09,.05-.14,.14-.15,.03,0,.05,0,.08,0,1,0,2,0,3.01,0,.02,0,.04,0,.06,0,.09,0,.15,.07,.15,.16,0,.08-.06,.15-.16,.15-.03,0-.05,0-.08,0-.49,0-.99,0-1.48,0Z' />
			<path d='M2.66,6.87c.49,0,.99,0,1.48,0,.03,0,.06,0,.09,0,.08,.01,.14,.07,.14,.15,0,.09-.05,.14-.13,.16-.03,0-.05,0-.08,0-1.01,0-2.01,0-3.02,0-.03,0-.05,0-.08,0-.08-.02-.13-.07-.13-.15,0-.09,.05-.14,.13-.16,.03,0,.06,0,.09,0,.5,0,1,0,1.5,0Z' />
			<path d='M2.66,7.5c.5,0,1,0,1.49,0,.03,0,.06,0,.09,0,.08,.02,.13,.07,.13,.15,0,.09-.05,.14-.14,.15-.03,0-.06,0-.09,0-1,0-1.99,0-2.99,0-.03,0-.07,0-.1,0-.08-.02-.13-.07-.12-.15,0-.08,.05-.13,.13-.15,.03,0,.06,0,.1,0,.5,0,1,0,1.49,0Z' />
			<path d='M7.1,2.5c-.37,0-.75,0-1.12,0-.05,0-.12-.02-.15-.05-.03-.03-.05-.11-.04-.15,.02-.05,.08-.09,.13-.11,.04-.02,.1,0,.15,0,.71,0,1.41,0,2.12,0,.03,0,.05,0,.08,0,.09,0,.16,.07,.16,.15,0,.09-.06,.16-.16,.16-.18,0-.35,0-.53,0-.21,0-.43,0-.64,0Z' />
			<path d='M6.31,5c-.38,0-.75,0-1.13,0-.11,0-.17-.05-.18-.14-.01-.1,.06-.17,.17-.18,.18,0,.36,0,.54,0,.58,0,1.16,0,1.74,0,.05,0,.12,0,.14,.04,.03,.04,.06,.11,.05,.16-.01,.08-.08,.11-.16,.11-.26,0-.53,0-.79,0-.12,0-.25,0-.37,0Z' />
			<path d='M3.05,5.94c-.38,0-.76,0-1.14,0-.11,0-.17-.05-.18-.14-.01-.1,.06-.17,.17-.18,.18,0,.36,0,.54,0,.58,0,1.16,0,1.74,0,.05,0,.12,0,.14,.04,.03,.04,.06,.11,.05,.16-.01,.08-.08,.11-.16,.11-.27,0-.53,0-.8,0-.12,0-.23,0-.35,0Z' />
			<path d='M2.27,8.12c.37,0,.74,0,1.11,0,.05,0,.11,0,.14,.03,.04,.04,.06,.11,.06,.16,0,.07-.07,.12-.15,.12-.12,0-.23,0-.35,0-.63,0-1.27,0-1.9,0-.03,0-.05,0-.08,0-.1,0-.17-.07-.17-.16,0-.09,.07-.15,.16-.16,.23,0,.46,0,.69,0,.16,0,.32,0,.48,0Z' />
			<path d='M5.23,2.5s-.02,0-.03,0c-.13,0-.2-.06-.2-.16,0-.1,.07-.16,.2-.16,.03,0,.07,0,.1,0,.1,0,.17,.07,.16,.16,0,.09-.07,.15-.17,.16-.02,0-.05,0-.07,0Z' />
			<path d='M8.2,5s-.03,0-.05,0c-.11,0-.18-.06-.18-.16,0-.09,.07-.15,.19-.15,.04,0,.08,0,.12,0,.1,0,.16,.07,.16,.16,0,.09-.07,.15-.17,.15-.02,0-.05,0-.07,0Z' />
			<path d='M1.16,5.93s-.06,0-.09,0c-.08-.01-.13-.06-.14-.15,0-.08,.06-.15,.14-.16,.05,0,.1,0,.16,0,.1,0,.17,.06,.17,.15,0,.09-.07,.16-.17,.16-.02,0-.05,0-.07,0h0Z' />
			<path d='M4.14,8.12s.03,0,.04,0c.12,0,.19,.06,.19,.16,0,.1-.07,.15-.2,.16-.04,0-.07,0-.11,0-.1,0-.16-.07-.16-.16,0-.09,.07-.15,.17-.15,.02,0,.05,0,.07,0Z' />
			<path d='M5.92,.94c-.6,0-1.19,0-1.79,0-.03,0-.07,0-.1,0-.08-.02-.13-.07-.13-.15,0-.09,.05-.14,.13-.15,.03,0,.06,0,.09,0,1.2,0,2.41,0,3.61,0,.03,0,.06,0,.09,0,.08,.02,.13,.07,.13,.15,0,.09-.05,.14-.13,.15-.03,0-.06,0-.1,0-.6,0-1.2,0-1.81,0Z' />
			<path d='M2.42,.63s.04,0,.06,0c.11,0,.17,.07,.17,.16,0,.09-.07,.15-.17,.15-.04,0-.08,0-.13,0-.11,0-.17-.07-.17-.16,0-.09,.07-.15,.17-.15,.02,0,.05,0,.07,0Z' />
			<path d='M8.52,.63s.06,0,.09,0c.08,.01,.13,.07,.14,.15,0,.08-.06,.15-.14,.16-.06,0-.12,0-.18,0-.09,0-.15-.07-.15-.15,0-.09,.06-.15,.15-.16,.03,0,.06,0,.09,0h0Z' />
			<path d='M1.63,.94s-.06,0-.09,0c-.08-.01-.13-.06-.14-.15,0-.08,.06-.15,.14-.16,.05,0,.1,0,.16,0,.1,0,.17,.06,.17,.15,0,.09-.07,.16-.17,.16-.02,0-.05,0-.07,0h0Z' />
			<path d='M.85,.94s-.04,0-.06,0c-.11,0-.17-.07-.17-.16,0-.09,.07-.15,.17-.15,.04,0,.08,0,.13,0,.11,0,.17,.07,.17,.16,0,.09-.07,.15-.17,.15-.02,0-.05,0-.07,0Z' />
		</svg>
	</span>
);

const StatusViewerIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M6.77,9.54c-.22-.08-.32-.24-.32-.47,0-.02,0-.05,0-.07-.02-.11,0-.24-.06-.32-.05-.08-.18-.12-.27-.17-.02-.01-.06,0-.09,0-.13,.05-.25,.12-.37,.17-.21,.09-.41,.03-.53-.17-.1-.17-.2-.35-.3-.53-.11-.2-.06-.4,.12-.54,.1-.08,.21-.16,.31-.23,.05-.04,.07-.07,.06-.14-.01-.07-.02-.14,0-.2,.02-.1-.02-.15-.09-.2-.1-.07-.2-.14-.29-.21-.17-.13-.21-.33-.11-.51,.1-.19,.2-.38,.31-.56,.11-.18,.31-.24,.5-.16,.12,.05,.24,.11,.36,.17,.06,.03,.1,.03,.15-.01,.04-.03,.09-.07,.13-.08,.11-.04,.14-.12,.14-.23,0-.12,.02-.25,.04-.37,.03-.2,.18-.33,.38-.33,.21,0,.42,0,.63,0,.2,0,.35,.14,.37,.33,.02,.15,.03,.31,.05,.46,0,.03,.04,.07,.07,.1,.06,.04,.13,.07,.19,.12,.06,.05,.11,.04,.17,.01,.12-.06,.24-.12,.36-.17,.19-.08,.38-.02,.49,.16,.13,.22,.25,.45,.37,.68v.17c-.05,.13-.14,.23-.25,.31-.06,.04-.12,.09-.18,.13-.1,.06-.11,.13-.12,.24-.03,.23,.07,.35,.25,.45,.14,.08,.26,.18,.3,.35v.17c-.12,.21-.23,.43-.35,.64-.12,.21-.32,.27-.53,.17-.12-.06-.25-.12-.37-.17-.03-.01-.07-.01-.09,0-.1,.05-.22,.09-.28,.18-.05,.08-.04,.21-.05,.32,0,.02,0,.04,0,.06,0,.23-.1,.39-.32,.47h-.78Zm-1.59-3.41c.16,.12,.3,.23,.46,.34,.09,.07,.13,.15,.11,.27-.01,.1-.03,.2-.02,.3,.04,.33,.05,.29-.24,.51-.1,.08-.21,.15-.31,.23l.31,.55c.17-.08,.34-.16,.51-.24,.1-.05,.18-.04,.28,.03,.12,.08,.25,.16,.38,.23,.09,.05,.15,.11,.15,.21,0,.09,.01,.18,.02,.27,.01,.11,.02,.21,.03,.32,.19,0,.37,0,.55,0,.06,0,.06-.04,.06-.08,.01-.17,.03-.33,.04-.5,0-.11,.06-.18,.16-.23,.11-.05,.22-.1,.31-.18,.13-.12,.26-.13,.41-.04,.14,.08,.29,.14,.45,.21l.3-.55c-.15-.11-.3-.23-.45-.34-.1-.07-.13-.16-.12-.28,.01-.13,.01-.27,0-.41-.01-.12,.01-.21,.11-.28,.15-.11,.3-.22,.45-.33l-.31-.55c-.18,.08-.34,.16-.51,.24-.1,.05-.18,.04-.28-.03-.11-.08-.22-.16-.35-.21-.13-.06-.19-.14-.19-.28,0-.18-.03-.36-.05-.54h-.61c-.02,.19-.04,.36-.05,.54,0,.13-.06,.21-.18,.26-.12,.05-.23,.12-.33,.2-.11,.08-.2,.1-.32,.04-.16-.08-.33-.15-.5-.23l-.3,.55Z' />
			<path d='M2.89,9.54c-.09-.04-.2-.08-.28-.14-.15-.11-.22-.27-.22-.45,0-1.33,0-2.66,0-3.99,0-.33,.25-.58,.58-.59,.27,0,.53,0,.8,0,.34,0,.59,.25,.6,.59,0,.08,0,.17,0,.25,0,1.2,0,2.39,0,3.59,0,.43-.1,.58-.5,.73h-.99Zm1.09-2.58c0-.65,0-1.3,0-1.96,0-.16-.07-.23-.23-.23-.24,0-.47,0-.71,0-.18,0-.25,.07-.25,.25,0,1.29,0,2.59,0,3.88,0,.17,.08,.24,.25,.24,.23,0,.45,0,.68,0,.2,0,.27-.06,.27-.27,0-.64,0-1.28,0-1.92Z' />
			<path d='M.5,9.54c-.1-.05-.21-.09-.3-.16-.14-.11-.2-.26-.2-.43,0-.8,0-1.6,0-2.4,0-.33,.26-.58,.59-.59,.26,0,.52,0,.78,0,.35,0,.61,.24,.61,.59,0,.8,0,1.6,0,2.39,0,.29-.18,.5-.46,.58-.01,0-.02,.01-.03,.02H.5Zm1.09-1.78c0-.39,0-.77,0-1.16,0-.18-.07-.25-.25-.25-.23,0-.47,0-.7,0-.17,0-.24,.08-.24,.25,0,.76,0,1.53,0,2.29,0,.17,.07,.25,.24,.25,.24,0,.47,0,.71,0,.17,0,.24-.07,.24-.25,0-.38,0-.76,0-1.14Z' />
			<path d='M9.15,2.37c0,.58,0,1.16,0,1.73,0,.18-.07,.28-.2,.27-.13,0-.2-.1-.2-.27,0-1.14,0-2.28,0-3.42,0-.23-.06-.28-.29-.28-.22,0-.43,0-.65,0-.18,0-.25,.07-.25,.25,0,.88,0,1.77,0,2.65,0,.18-.07,.27-.19,.27-.13,0-.2-.09-.2-.28,0-.88,0-1.76,0-2.65,0-.42,.24-.66,.66-.66,.24,0,.47,0,.71,0,.36,0,.62,.25,.62,.62,0,.58,0,1.16,0,1.74Z' />
			<path d='M4.77,3.36c0-.25,0-.5,0-.75,0-.35,.25-.61,.6-.61,.26,0,.52,0,.78,0,.34,0,.59,.25,.6,.59,0,.26,0,.52,0,.77,0,.14-.08,.22-.2,.22-.12,0-.2-.09-.2-.22,0-.24,0-.48,0-.73,0-.17-.07-.24-.24-.24-.22,0-.45,0-.67,0-.22,0-.28,.06-.28,.27,0,.47,0,.94,0,1.41,0,.02,0,.04,0,.07,0,.15-.08,.24-.2,.24-.12,0-.2-.09-.2-.24,0-.26,0-.52,0-.77Z' />
			<path d='M6.16,6.96c0-.55,.44-1,.99-.99,.55,0,.99,.45,.99,1,0,.55-.44,.99-.99,.99-.55,0-.99-.45-.99-.99Zm.99,.59c.33,0,.6-.26,.6-.59,0-.33-.27-.6-.6-.6-.32,0-.59,.27-.59,.59,0,.33,.26,.6,.59,.6Z' />
		</svg>
	</span>
);

const WhosInsideIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label} className='anticon' {...props}>
		<svg aria-hidden fill='currentColor' focusable viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
			<path d='M6.01,7.74c-.15-.05-.21-.16-.21-.32,0-.58,0-1.15,0-1.73,0-.11,.04-.19,.14-.24,.09-.05,.18-.04,.26,0,.09,.06,.12,.14,.12,.24,0,.47,0,.95,0,1.42v.1h1.93V.52h-1.93s0,.06,0,.1c0,.48,0,.95,0,1.43,0,.19-.15,.31-.32,.26-.11-.03-.19-.12-.19-.24,0-.61,0-1.22,0-1.83,0-.13,.09-.2,.21-.24h2.55c.1,.03,.16,.1,.21,.19V7.55c-.05,.09-.11,.16-.21,.19h-2.55Z' />
			<path d='M0,5.99c.07-.14,.18-.2,.34-.19,.26,0,.51,0,.77,0,.04,0,.08-.02,.11-.05,.28-.31,.56-.63,.84-.95,.02-.03,.05-.06,.07-.09,.22-.44,.44-.88,.66-1.31,0-.01,.01-.03,.02-.05-.18,0-.36,0-.54,0-.13,0-.19,.04-.24,.15-.11,.29-.24,.56-.37,.84-.07,.16-.25,.21-.38,.12-.1-.07-.14-.19-.09-.3,.17-.4,.35-.79,.53-1.19,.04-.09,.12-.14,.22-.14,.43,0,.87,0,1.3,0,.13,0,.21,.07,.25,.21,.11,.36,.22,.71,.32,1.07,.02,.06,.05,.09,.1,.11,.24,.1,.48,.2,.71,.31,.12,.05,.17,.16,.15,.28-.02,.11-.1,.2-.22,.21-.05,0-.11,0-.16-.03-.29-.12-.57-.24-.86-.36-.09-.04-.15-.1-.18-.2-.06-.21-.12-.41-.19-.64l-.29,.58c-.07,.15-.14,.3-.22,.44-.03,.05-.02,.09,.01,.13,.21,.3,.41,.61,.61,.92,.07,.1,.09,.19,.07,.31-.08,.44-.16,.89-.24,1.33-.02,.12-.1,.19-.2,.24h-.12c-.19-.11-.22-.17-.17-.39,.07-.39,.14-.77,.21-1.16,0-.04,0-.1-.03-.13-.14-.23-.3-.45-.44-.67-.01-.02-.03-.04-.05-.06-.26,.29-.51,.58-.76,.86-.08,.09-.17,.13-.29,.13-.3,0-.6,0-.9,0-.16,0-.27-.05-.34-.19v-.14Z' />
			<path d='M3.87,.65c.57,0,1.03,.46,1.03,1.03,0,.57-.46,1.02-1.03,1.02-.57,0-1.03-.46-1.03-1.03,0-.57,.46-1.02,1.03-1.02Zm-.51,1.03c0,.29,.23,.51,.52,.51,.28,0,.51-.23,.51-.51,0-.29-.23-.52-.51-.52-.29,0-.52,.23-.51,.52Z' />
			<path d='M6.57,4.13c-.38,0-.77,0-1.15,0-.14,0-.24-.1-.26-.23-.02-.12,.06-.24,.18-.27,.03-.01,.07-.01,.11-.01,.35,0,.69,0,1.04,0h.11c-.08-.08-.14-.14-.21-.21-.07-.08-.09-.17-.05-.27,.04-.1,.11-.16,.21-.16,.07,0,.16,.03,.21,.07,.22,.21,.43,.42,.64,.64,.11,.11,.11,.26,0,.37-.21,.22-.43,.43-.64,.64-.11,.1-.26,.1-.36,0-.1-.1-.1-.26,0-.37,.06-.06,.12-.12,.19-.18v-.02Z' />
		</svg>
	</span>
);

const UserIcon: FC<CustomIconProperties> = props => (
	<span role='img' aria-label={props.label}  {...props}>
		<svg height='16' width='16' viewBox='0 0 16 16' fill='currentColor' aria-hidden xmlns='http://www.w3.org/2000/svg'>
			<path d='M12.7221 13.4397H2.31548C1.03695 13.4397 0 12.4166 0 11.1632V5.14493C0 3.88793 1.04066 2.86844 2.31548 2.86844H6.9836C7.29209 2.86844 7.5411 3.11326 7.5411 3.41655C7.5411 3.71984 7.29209 3.96466 6.9836 3.96466H2.31548C1.65391 3.96466 1.115 4.4945 1.115 5.14493V11.1632C1.115 11.8136 1.65391 12.3435 2.31548 12.3435H12.7221C13.3837 12.3435 13.9226 11.8136 13.9226 11.1632V10.0341C13.9226 9.7308 14.1716 9.48598 14.4801 9.48598C14.7886 9.48598 15.0376 9.7308 15.0376 10.0341V11.1632C15.0376 12.4202 13.9969 13.4397 12.7221 13.4397Z' />
			<path d='M10.7336 12.8916H5.37793V14.9964H10.7336V12.8916Z' />
			<path d='M12.3541 9.1352C9.7933 9.1352 7.70825 7.08526 7.70825 4.5676C7.70825 2.04994 9.78958 0 12.3541 0C14.9186 0 16.9999 2.04994 16.9999 4.5676C16.9999 7.08526 14.9149 9.1352 12.3541 9.1352ZM12.3541 0.730816C10.2021 0.730816 8.45158 2.45189 8.45158 4.5676C8.45158 6.68331 10.2021 8.40439 12.3541 8.40439C14.506 8.40439 16.2566 6.68331 16.2566 4.5676C16.2566 2.45189 14.506 0.730816 12.3541 0.730816Z' />
			<path d='M12.354 5.84651C11.2278 5.84651 10.3098 4.94396 10.3098 3.83677C10.3098 2.72958 11.2278 1.82703 12.354 1.82703C13.4801 1.82703 14.3981 2.72958 14.3981 3.83677C14.3981 4.94396 13.4801 5.84651 12.354 5.84651ZM12.354 2.55784C11.6367 2.55784 11.0531 3.13153 11.0531 3.83677C11.0531 4.54201 11.6367 5.1157 12.354 5.1157C13.0713 5.1157 13.6548 4.54201 13.6548 3.83677C13.6548 3.13153 13.0713 2.55784 12.354 2.55784Z' />
			<path d='M14.9706 7.71012C14.9446 7.63338 14.283 5.84654 12.3541 5.84654C10.4251 5.84654 9.74498 7.69185 9.74127 7.71012L9.03882 7.4726C9.325 6.65774 10.3842 5.11572 12.3578 5.11572C14.3313 5.11572 15.3906 6.65774 15.6768 7.4726L14.9743 7.71012H14.9706Z' />
		</svg>
	</span>
);

export {
	AlarmsIcon,
	DeviceControlIcon,
	EnrollmentIcon,
	EventsIcon,
	MenuCloseIcon,
	MenuOpenIcon,
	PhotoCallUpIcon,
	ReportingIcon,
	StatusDashboardIcon,
	StatusViewerIcon,
	WhosInsideIcon,
	UserIcon,
};

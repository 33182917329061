import React, { useContext, useEffect } from 'react';
import { PortConnectionType } from '../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../common';
import { PortStoreContext, setBaudRateAction, setPortChangedAction } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

const mainDivContainerId = 'portModalBaudRatesDropdownContainer';

const BaudRate: React.FC = () => {
	const {
		portState: { baudRates, baudRate, defaultConnectionType, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		let newBaudRate;
		if (defaultConnectionType === PortConnectionType.XNET) {
			newBaudRate = baudRates.length ? baudRates[2] : 0;
		} else {
			newBaudRate = baudRates.length ? baudRates[0] : 0;
		}
		dispatcher(setBaudRateAction(newBaudRate));
	}, [defaultConnectionType]);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChangeBaudRates = value => {
		dispatcher(setBaudRateAction(Number(value)));
		checkIfPortChanged();
	};

	return (
		<div id={mainDivContainerId} className={styles.dropdownContainer}>
			<label id='portGeneralBaudRateLabel'>{_('BaudRate')}</label>
			<Select
				id='portGeneralBaudRatesDropdown'
				onChange={handleChangeBaudRates}
				value={baudRate.toString() ?? ''}
				options={baudRates.map((x, index) => ({
					label: x.toString(),
					value: x.toString(),
					id: `portGeneralBaudRatesDropdownOption-${index}`,
				}))}
				className={styles.dropdown}
				getPopupContainer={() => document.getElementById(mainDivContainerId)}
			/>
		</div>
	);
};

export default BaudRate;

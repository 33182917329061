import { CaretDownOutlined } from '@ant-design/icons';
import { Button, FormInstance, Upload, UploadProps } from 'antd';
import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { batch } from 'react-redux';
import { getEnrollmentUploadProps } from '../../../Helper';
import { EnrollmentResourceStrings, PersonDetailModel, PhotoActionOptions } from '../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { setHavePersonChanged, setUpdateCounter } from '../../../store/enrollment/actions';
import {
	selectBlankProfilePictureBase64,
	selectBlankProfileSignatureBase64,
	selectEnrollmentResourceStrings,
	selectHavePersonChanged,
} from '../../../store/enrollment/selectors';
import { ButtonDropdown, ModalConfirmation, ModalCropImage } from '../../common';
import { editPhotoOptions } from '../../enrollment/helper';
import styles from './personImages.module.scss';

type Props = {
	form?: FormInstance<PersonDetailModel>;
	profilePictureBase64?: string;
	profileSignatureBase64?: string;
	showInfoOnly?: boolean;
	tabId?: number;
	additionalImageName?: string;
	onSaveAdditionalImage?: (imageSource: string, thumbnailSource: string, exitEditMode?: boolean) => void;
};

const PersonImages: React.FC<Props> = ({
	form,
	profilePictureBase64,
	profileSignatureBase64,
	showInfoOnly,
	tabId,
	additionalImageName,
	onSaveAdditionalImage,
}) => {
	const dispatch = useStoreDispatch();

	const [imageSource, setImageSource] = useState<string>('');
	const [showPreviewImage, setShowPreviewImage] = useState<boolean>(false);

	const blankProfilePictureBase64: string = useStoreSelector<string>(selectBlankProfilePictureBase64);
	const blankProfileSignatureBase64: string = useStoreSelector<string>(selectBlankProfileSignatureBase64);
	const resourceStrings: EnrollmentResourceStrings = useStoreSelector<EnrollmentResourceStrings>(selectEnrollmentResourceStrings);
	const havePersonChanged: boolean = useStoreSelector<boolean>(selectHavePersonChanged);

	const uploadButton = useRef<HTMLButtonElement>();
	const cameraMode = useRef<boolean>(false);
	const signatureMode = useRef<boolean>(false);

	const getProfilePictureSource = (isAdditionalImage: boolean, pictureSource: string): string => {
		const defaultPicture: string = `data:image/JPG;base64,${blankProfilePictureBase64}`;
		let profilePictureSource: string;
		if (isAdditionalImage && pictureSource === '') {
			profilePictureSource = defaultPicture;
		} else if (pictureSource) {
			profilePictureSource = pictureSource;
		} else {
			profilePictureSource = defaultPicture;
		}

		return profilePictureSource;
	};

	const handleOnPhotoAction = async (key: PhotoActionOptions): Promise<void> => {
		switch (key) {
			case PhotoActionOptions.Upload: {
				if (uploadButton) {
					uploadButton?.current?.click();
					signatureMode.current = false;
				}
				break;
			}

			case PhotoActionOptions.Capture: {
				cameraMode.current = true;
				signatureMode.current = false;
				setImageSource('');
				setShowPreviewImage(true);
				break;
			}

			case PhotoActionOptions.Remove: {
				if (isAdditionalImage && profilePictureBase64 !== '') {
					ModalConfirmation({
						onConfirm: () => onSaveAdditionalImage(null, '', true),
						content: <div>{resourceStrings.DeleteAdditionalImage.replace('%1', additionalImageName)}</div>,
					});
				} else if (form) {
					form.setFieldsValue({
						...form.getFieldsValue(true),
						ProfilePictureBase64: `data:image/JPG;base64,${blankProfilePictureBase64}`,
					});

					if (!havePersonChanged) {
						batch(() => {
							dispatch(setHavePersonChanged(true));
							dispatch(setUpdateCounter());
						});
					} else {
						dispatch(setUpdateCounter());
					}
				}
				break;
			}
		}
	};

	const handleOnSaveCroppedImage = (croppedImageBase64: string, croppedThumbnailBase64?: string): void => {
		let updateStore: boolean = false;
		if (isAdditionalImage) {
			onSaveAdditionalImage(croppedImageBase64, croppedThumbnailBase64);
		} else if (signatureMode.current) {
			form.setFieldsValue({
				...form.getFieldsValue(true),
				ProfileSignatureBase64: `data:image/PNG;base64,${croppedImageBase64}`,
			});
			updateStore = true;
		} else {
			form.setFieldsValue({
				...form.getFieldsValue(true),
				ProfilePictureBase64: `data:image/JPG;base64,${croppedImageBase64}`,
			});
			updateStore = true;
		}

		if (updateStore) {
			if (!havePersonChanged) {
				batch(() => {
					dispatch(setHavePersonChanged(true));
					dispatch(setUpdateCounter());
				});
			} else {
				dispatch(setUpdateCounter());
			}
		}

		handleOnClosePreviewImage();
	};

	const handleOnClosePreviewImage = (): void => {
		cameraMode.current = false;
		signatureMode.current = false;
		setShowPreviewImage(false);
	};

	const handleOnUploadSignature = (): void => {
		if (!showInfoOnly && uploadButton) {
			signatureMode.current = true;
			uploadButton?.current?.click();
		}
	};

	const isAdditionalImage: boolean = onSaveAdditionalImage !== undefined;
	const pictureSource: string = form ? form.getFieldsValue().ProfilePictureBase64 : profilePictureBase64;
	const signatureSource: string = form ? form.getFieldsValue().ProfileSignatureBase64 : profileSignatureBase64;
	const profilePictureSource: string = getProfilePictureSource(isAdditionalImage, pictureSource);
	const profileSignatureSource: string = signatureSource ? signatureSource : `data:image/PNG;base64,${blankProfileSignatureBase64}`;
	const uploadProps: UploadProps = getEnrollmentUploadProps((source: string) => {
		setImageSource(source);
		setShowPreviewImage(true);
	});

	return (
		<div className={styles.container}>
			<div className={styles.profilePictureContainer}>
				{profilePictureSource !== '' && (
					<img id={`enrollmentPhoto-${tabId}`} className={styles.profilePicture} src={profilePictureSource} alt={_('PersonPhoto')} />
				)}
				{!showInfoOnly && (
					<div className={styles.photoActionDropdown}>
						<ButtonDropdown
							id={`enrollmentPhotoActionsDropdown-${tabId}`}
							menuOptions={editPhotoOptions.options}
							icon={<CaretDownOutlined />}
							labelIcon={editPhotoOptions.labelOrIcon}
							onClickOption={handleOnPhotoAction}
							overlayClassName={styles.personImagesOverlayMenu}
						/>
					</div>
				)}
				<Upload className={styles.uploadButton} {...uploadProps}>
					<Button ref={uploadButton} />
				</Upload>
			</div>
			{!isAdditionalImage && (
				<div className={cx(styles.profileSignatureContainer, { [styles.profileSignatureMouse]: !showInfoOnly })} onClick={handleOnUploadSignature}>
					<img id={`enrollmentSignature-${tabId}`} className={styles.profileSignature} src={profileSignatureSource} alt={_('PersonSignature')} />
				</div>
			)}
			{showPreviewImage && (
				<ModalCropImage
					onClose={handleOnClosePreviewImage}
					imageSourceParam={imageSource}
					onSave={handleOnSaveCroppedImage}
					isSignature={signatureMode.current}
					useCamera={cameraMode.current}
					isAdditionalImage={isAdditionalImage}
				/>
			)}
		</div>
	);
};

export { PersonImages };

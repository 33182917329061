import { Button, Input, Spin } from 'antd';
import cx from 'classnames';
import JSEncrypt from 'jsencrypt';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { getMaskMechanism, handleResponse } from '../../../../../../Helper';
import { accountApi } from '../../../../../../api';
import { CodeProperties, ResponseStatusCode } from '../../../../../../model/CommonModel';
import { OperatorCredential } from '../../../../../../model/OperatorModel';
import { Modal } from '../../../../../common/Modal/Modal';
import styles from './operatorPermissionModal.module.scss';

type OperatorPermissionModalProps = {
	onHandleElevatedOperation: (elevatedCredentials: OperatorCredential) => Promise<ResponseStatusCode>;
	onConfirm: () => void;
	onCancel: () => void;
	authPath: string;
	handleErrorNotification?: (errorMessage?: string) => void;
};

const OperatorPermissionModal: React.FC<OperatorPermissionModalProps> = ({
	onConfirm,
	onHandleElevatedOperation,
	onCancel,
	authPath,
	handleErrorNotification,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [credentials, setCredentials] = useState<OperatorCredential>({
		UseCredentials: false,
		Domain: '',
		UserName: '',
		Password: '',
	});
	const [isPermissionError, setIsPermissionError] = useState<boolean>(false);
	const [isCredentialError, setIsCredentialError] = useState<boolean>(false);
	const [isDomainError, setIsDomainError] = useState<boolean>(false);
	const [isUserNameError, setIsUserNameError] = useState<boolean>(false);
	const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
	const [username, setUsername] = useState<string>('');

	useEffect(() => {
		setCredentials({ ...credentials, Domain: authPath });

		return () => setCredentials({ UseCredentials: false, Domain: '', UserName: '', Password: '' });
	}, []);

	const areEntriesValid = (): boolean => {
		if (username === '' || username === null) {
			setIsUserNameError(true);
			handleErrorNotification('User name cannot be empty');
			return false;
		}

		const { Domain, Password } = credentials;

		if (Domain === '' || Domain === null) {
			setIsDomainError(true);
			handleErrorNotification('Domain cannot be empty');
			return false;
		}

		if (Password === '' || Password === null) {
			setIsPasswordError(true);
			handleErrorNotification('Password cannot be empty');
			return false;
		}

		return true;
	};

	const resetError = () => {
		batch(() => {
			setIsDomainError(false);
			setIsUserNameError(false);
			setIsPasswordError(false);
		});
	};

	const getCredentials = (codeProperties: CodeProperties): OperatorCredential => {
		const encryptEngine: JSEncrypt = getMaskMechanism(codeProperties);
		const { Domain, UserName, Password } = credentials;
		const domainEncrypted = encryptEngine.encrypt(Domain);
		const usernameEncrypted = encryptEngine.encrypt(UserName);
		const passwordEncrypted = encryptEngine.encrypt(Password);

		if (domainEncrypted && usernameEncrypted && passwordEncrypted) {
			return {
				UseCredentials: true,
				Domain: domainEncrypted.toString(),
				UserName: usernameEncrypted.toString(),
				Password: passwordEncrypted.toString(),
			} as OperatorCredential;
		}

		return undefined;
	};

	const onHandleConfirm = async () => {
		if (!areEntriesValid()) return;
		resetError();
		setIsLoading(true);
		const sessionResponse = await accountApi.getSessionMask();
		if (!handleResponse(sessionResponse)) {
			const { Entity: maskComponents } = sessionResponse;
			if (maskComponents) {
				const encryptedCredentials = getCredentials(maskComponents);
				if (encryptedCredentials) {
					const furtherResponse = await onHandleElevatedOperation(encryptedCredentials);
					if (furtherResponse === ResponseStatusCode.Success) {
						setIsPermissionError(false);
						onConfirm?.();
						setIsLoading(false);
						return;
					} else if (furtherResponse === ResponseStatusCode.InputError) {
						batch(() => {
							setIsPermissionError(false);
							setIsCredentialError(false);
						});
						onCancel();
					} else if (furtherResponse === ResponseStatusCode.PermissionError) {
						batch(() => {
							setIsPermissionError(false);
							setIsCredentialError(true);
						});
					} else if (furtherResponse === ResponseStatusCode.ComponentLocked) {
						batch(() => {
							setIsPermissionError(false);
							setIsCredentialError(false);
							onCancel();
						});
					} else {
						batch(() => {
							setIsPermissionError(true);
							setIsCredentialError(false);
						});
					}
				} else {
					batch(() => {
						setIsPermissionError(false);
						setIsCredentialError(true);
					});
				}
			}
		}
		setIsLoading(false);
	};

	const onUserNameChanged = (input: string) => {
		let username: string = input;
		if (input.includes('\\')) {
			const inputSplit: string[] = input.split('\\');
			const domain: string = inputSplit[0];
			const un: string = inputSplit[1];
			batch(() => {
				setUsername(username);
				setCredentials({ ...credentials, Domain: domain, UserName: un });
			});
			return;
		}
		batch(() => {
			setUsername(username);
			setCredentials({ ...credentials, Domain: authPath, UserName: username });
		});
	};

	return (
		<Modal
			visible
			title={`${_('ElevatedPermissionsRequired')}: ${_('WindowsAccountManagement')}`}
			footer={[
				<Button id='operatorConfirmCredential' title={_('ConfirmCredentials')} key='save' type='primary' onClick={onHandleConfirm}>
					{_('Confirm')}
				</Button>,
				<Button id='operatorCancelCredential' key='cancel' onClick={onCancel}>
					{_('Cancel')}
				</Button>,
			]}
			className={styles.WithModalZoom}
			onCancel={onCancel}
			width={700}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large' className={styles.spinContainer}>
				<div className={styles.container}>
					<p>{`${_('ElevatedCredentialMessage')}.`}</p>
					<div className={styles.containerCredential}>
						<label id='operatorCredentialUserNameLabel' htmlFor='operatorCredentialUserNameInput'>
							{_('UserName')}
						</label>
						<div>
							<Input
								id='operatorCredentialUserNameInput'
								value={username}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									onUserNameChanged(e.target.value);
								}}
								maxLength={100}
								className={cx('', {
									[styles.error]: isDomainError || isUserNameError,
								})}
							/>
						</div>
					</div>
					<div className={styles.containerCredential}>
						<label id='operatorCredentialPasswordLabel' htmlFor='operatorCredentialPasswordInput'>
							{_('Password')}
						</label>
						<div>
							<Input.Password
								id='operatorCredentialPasswordInput'
								value={credentials.Password}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setCredentials({ ...credentials, Password: e.target.value });
								}}
								maxLength={100}
								onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
									if (e.key === 'Enter' || e.key === 'Return') {
										onHandleConfirm();
									}
								}}
								className={cx('', {
									[styles.error]: isPasswordError,
								})}
							/>
						</div>
					</div>
					<div className={styles.containerDomain}>
						<div></div>
						<p>{`${_('Domain')}:`}</p>
						<p>{credentials.Domain}</p>
					</div>
					{isPermissionError && <p className={styles.errorStyle}>{_('DomainAccessDenied')}</p>}
					{isCredentialError && <p className={styles.errorStyle}>{_('IncorrectCredentials')}</p>}
				</div>
			</Spin>
		</Modal>
	);
};

export { OperatorPermissionModal };

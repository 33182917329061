import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { ReaderError } from '../../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderError, setExitReaderErrorType, setExitReaderName } from '../../../exitReaderContext';
import styles from '../../General/general.module.scss';

type Props = {
	setErrorText: () => void;
};
const Name: React.FC<Props> = ({ setErrorText }) => {
	const {
		contextStateDoor: {
			exitReader: { name, readerErrorMessage, readerErrorType, isExitReaderError },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [errorMessage, setErrorMessage] = useState<JSX.Element>(<></>);

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.currentTarget.value.replace(/[,%]/g, '');
		dispatcherDoor(setExitReaderName(value));

		if (readerErrorType === ReaderError.Name) {
			dispatcherDoor(setExitReaderErrorType(undefined));
			dispatcherDoor(setExitReaderError(false));
			setErrorText();
		}
	};

	useEffect(() => {
		if (readerErrorType === ReaderError.Name) {
			setErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else {
			setErrorMessage(<></>);
		}
	}, [readerErrorType]);

	return (
		<div className={styles.generalSection}>
			<span>{_('ReaderName')}</span>
			<div>
				<Input
					key='name'
					value={name}
					onChange={handleOnChangeName}
					id='exitReaderModalNameInput'
					className={cx(styles.inputName, {
						[styles.error]: readerErrorType === ReaderError.Name && isExitReaderError,
					})}
					maxLength={32}
					autoComplete='chrome-off'
				/>
				{isExitReaderError && errorMessage}
			</div>
		</div>
	);
};

export { Name };

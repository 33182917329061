import React, { useContext } from 'react';
import { ReaderInterface } from '../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../DoorModal/DoorStoreSingleContext';
import { StoreContext } from '../../contextReader';
import { Interface, Name, RS485Version, ThreatLevel } from '../sections';
import styles from './general.module.scss';

type Props = {
	submittedForm: boolean;
	handleDownloadFirmware: () => void;
	useDoorContext: boolean;
	setErrorText: () => void;
};

const General: React.FC<Props> = ({ submittedForm, handleDownloadFirmware, useDoorContext, setErrorText }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { readerInterface },
			},
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { readerInterface },
		} = useContext(StoreContext);
	}

	return (
		<div id='generalReaderContainer' className={styles.form}>
			<Name useDoorContext={useDoorContext} setErrorText={setErrorText} />
			<div className={styles.sections}>
				<Interface useDoorContext={useDoorContext}></Interface>
				<div>
					<ThreatLevel useDoorContext={useDoorContext}></ThreatLevel>
				</div>
			</div>
			{readerInterface === ReaderInterface.RS485 && (
				<RS485Version submittedForm={submittedForm} handleDownloadFirmware={handleDownloadFirmware} useDoorContext={useDoorContext}></RS485Version>
			)}
		</div>
	);
};

export { General };

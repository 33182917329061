import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { validateIPv4AddressFormat, validateIPv6AddressFormat } from '../../../../../../../Helper';
import { PortNetWorkType } from '../../../../../../../model/DeviceAdminModel';
import { Modal, NotificationStatus } from '../../../../../../common';
import { PortStoreContext, setBroadcastData, setSNIBSelected } from '../../../contextPort';
import { DefaultGateway, DHCPEnabled, IPAddress, IPPort, Name, SubnetMask } from './sections';
import styles from './snibConfiguration.module.scss';

type Props = {
	handleSetIsSearchTableView: (boolean) => void;
};

const SnibConfiguration: React.FC<Props> = ({ handleSetIsSearchTableView }) => {
	const {
		portState: { snibSelected, broadcastData, networkType },
		dispatcher,
	} = useContext(PortStoreContext);
	const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
	const [isIPAddressInvalid, setIsIPAddressInvalid] = useState<boolean>(false);
	const [isSubnetMaskInvalid, setIsSubnetMaskInvalid] = useState<boolean>(false);
	const [isDefaultGatewayInvalid, setIsDefaultGatewayInvalid] = useState<boolean>(false);
	const [isSNIBNameInvalid, setIsSNIBNameInvalid] = useState<boolean>(false);

	const updateBroadcastData = () => {
		const broadcastDataUpdated = broadcastData.filter(x => x.Id !== snibSelected.Id);
		dispatcher(setBroadcastData(broadcastDataUpdated));
	};

	const validateSNIBConfiguration = (): boolean => {
		let isValid: boolean = true;

		if (snibSelected?.Hostname === '') {
			setIsSNIBNameInvalid(true);
			isValid = false;
		}

		const dhcpEnabled: boolean = snibSelected.DHCPEnabled === 'Yes';

		if (networkType === PortNetWorkType.IPv4 && !dhcpEnabled) {
			if (!validateIPv4AddressFormat(snibSelected?.IPAddress)) {
				setIsIPAddressInvalid(true);
				isValid = false;
			}
			if (!validateIPv4AddressFormat(snibSelected?.SubnetMask)) {
				setIsSubnetMaskInvalid(true);
				isValid = false;
			}
			if (!validateIPv4AddressFormat(snibSelected?.DefaultGateway)) {
				setIsDefaultGatewayInvalid(true);
				isValid = false;
			}
		} else if (networkType === PortNetWorkType.IPv6 && !dhcpEnabled) {
			if (!validateIPv6AddressFormat(snibSelected?.IPv6Address)) {
				setIsIPAddressInvalid(true);
				isValid = false;
			}
			if (snibSelected?.IPv6Prefix === '') {
				setIsSubnetMaskInvalid(true);
				isValid = false;
			}
			if (!validateIPv6AddressFormat(snibSelected?.IPv6Gateway)) {
				setIsDefaultGatewayInvalid(true);
				isValid = false;
			}
		}
		return isValid;
	};

	const sendSNIBConfiguration = async () => {
		if (!validateSNIBConfiguration()) {
			return;
		}

		setConfirmLoading(true);
		const response = await deviceAdminApi.sendSNIBConfiguration(snibSelected);
		NotificationStatus({
			responseData: response,
			onSuccessCallback: () => {
				setConfirmLoading(false);
				updateBroadcastData();
				closeSNIBConfiguration();
				dispatcher(setSNIBSelected(null));
			},
		});
	};

	const closeSNIBConfiguration = () => {
		if (isSNIBNameInvalid) setIsSNIBNameInvalid(false);
		if (isIPAddressInvalid) setIsIPAddressInvalid(false);
		if (isSubnetMaskInvalid) setIsSubnetMaskInvalid(false);
		if (isDefaultGatewayInvalid) setIsDefaultGatewayInvalid(false);
		handleSetIsSearchTableView(true);
	};

	return (
		<Modal
			visible={true}
			onCancel={closeSNIBConfiguration}
			footer={[
				<Button id='searchUpdateSNIBButton' key='save' type='primary' loading={confirmLoading} onClick={() => sendSNIBConfiguration()}>
					{_('UpdateSNIB')}
				</Button>,
				<Button
					id='searchSNIBCancelButton'
					key='cancel'
					onClick={() => {
						closeSNIBConfiguration();
					}}>
					{_('Cancel')}
				</Button>,
			]}
			title={_('SNIBConfiguration')}
			width='600px'>
			<div id='searchSNIBConfigurationContainer' className={styles.container}>
				<Name isSNIBNameInvalid={isSNIBNameInvalid} onResetSNIBNameError={() => setIsSNIBNameInvalid(false)} />
				<div className={styles.network}>
					<fieldset>
						<legend className={styles.legend}>{_('Network')}</legend>
						<div className={styles.grid}>
							<IPAddress isIPAddressInvalid={isIPAddressInvalid} onResetIPAddressError={() => setIsIPAddressInvalid(false)} />
							<IPPort />
							<SubnetMask isSubnetMaskInvalid={isSubnetMaskInvalid} onResetSubnetMaskError={() => setIsSubnetMaskInvalid(false)} />
							<DefaultGateway
								isDefaultGatewayInvalid={isDefaultGatewayInvalid}
								onResetDefaultGatewayError={() => setIsDefaultGatewayInvalid(false)}
							/>
							<DHCPEnabled
								isIPAddressInvalid={isIPAddressInvalid}
								onResetIPAddressError={() => setIsIPAddressInvalid(false)}
								isSubnetMaskInvalid={isSubnetMaskInvalid}
								onResetSubnetMaskError={() => setIsSubnetMaskInvalid(false)}
								isDefaultGatewayInvalid={isDefaultGatewayInvalid}
								onResetDefaultGatewayError={() => setIsDefaultGatewayInvalid(false)}
							/>
						</div>
					</fieldset>
				</div>
			</div>
		</Modal>
	);
};

export { SnibConfiguration };

import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { PortStoreContext, setName } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
};

const Name: React.FC<Props> = ({ nameError, nameValidation, onResetNameError }) => {
	const {
		portState: { name },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setName(value));
		onResetNameError();
	};

	const portGeneralNameInputId = 'portGeneralNameInput';

	return (
		<>
			<div className={styles.name}>
				<label id='portGeneralNameLabel' htmlFor={portGeneralNameInputId}>
					{_('Name')}
				</label>
				<Input
					id={portGeneralNameInputId}
					key='name'
					value={name}
					onChange={handleChange}
					maxLength={32}
					className={cx(styles.input, {
						[styles.error]: nameValidation || nameError,
					})}
				/>
			</div>
			<div className={styles.inputValidation}>
				{nameValidation && (
					<label id='portGeneralEmptyErrorLabel' htmlFor={portGeneralNameInputId} className={styles.errorMessage}>
						{_('EmptyPortName')}
					</label>
				)}
				{nameError && (
					<label id='portGeneralDuplicatedErrorLabel' htmlFor={portGeneralNameInputId} className={styles.errorMessage}>
						{_('DuplicatedPortName')}
					</label>
				)}
			</div>
		</>
	);
};

export { Name };

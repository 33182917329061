import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { AdvancedReportingMessageFilterOptions, AdvanceReportingSetupInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerHasChanged, setControllerReportingAdvanceSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerReportingAdvanceSetup } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectAdvanceReportingSetupInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../reporting.module.scss';

const AdvanceReportingSetup: React.FC = () => {
	const dispatch = useStoreDispatch();
	const advanceReportingSetupInfo: AdvanceReportingSetupInfoStore = useStoreSelector<AdvanceReportingSetupInfoStore>(selectAdvanceReportingSetupInfo);

	const [currentEventSelected, setCurrentEventSelected] = useState(advanceReportingSetupInfo.messagesFilters[0].MessageFiltersType);
	const [messagesDiagnosticOptions, setMessagesDiagnosticOptions] = useState({
		enableCommandEcho: advanceReportingSetupInfo.enableCommandEcho,
		enablePrintingSNETMessagesOnLocalPrinter: advanceReportingSetupInfo.enablePrintingSNETMessagesOnLocalPrinter,
		enableMCZReporting: advanceReportingSetupInfo.enableMCZReporting,
		enableACBReporting: advanceReportingSetupInfo.enableACBReporting,
	});

	useEffect(() => {
		setControllerReportingAdvanceSetupAction({
			EnableCommandEcho: messagesDiagnosticOptions.enableCommandEcho,
			EnableACBReporting: messagesDiagnosticOptions.enableACBReporting,
			EnableMCZReporting: messagesDiagnosticOptions.enableMCZReporting,
			EnablePrintingSNET: messagesDiagnosticOptions.enablePrintingSNETMessagesOnLocalPrinter,
		});
	}, [messagesDiagnosticOptions]);

	const handleChangeControllerProperty = (value: ControllerReportingAdvanceSetup) => {
		batch(() => {
			dispatch(setControllerHasChanged(true));
			dispatch(
				setControllerReportingAdvanceSetupAction({
					...value,
				})
			);
		});
	};

	const handleChangeMessageType = (key: keyof AdvancedReportingMessageFilterOptions, checked: boolean) => {
		const findIndex = advanceReportingSetupInfo.messagesFilters.findIndex(x => x.MessageFiltersType === currentEventSelected);
		if (~findIndex) {
			const cloneState = [...advanceReportingSetupInfo.messagesFilters];
			const value = cloneState[findIndex];
			cloneState.splice(findIndex, 1, { ...value, [key]: checked });
			handleChangeControllerProperty({
				MessageFilters: [...cloneState],
			});
		}
	};

	const handleChangeDiagnosticOption = (key: Partial<typeof messagesDiagnosticOptions>) => {
		setMessagesDiagnosticOptions({
			...messagesDiagnosticOptions,
			...key,
		});
	};

	const controllerReportingMessageFilterDropdownId = 'controllerReportingMessageFilterDropdown';

	const currentMessageTypeToRender = advanceReportingSetupInfo.messagesFilters.find(x => x.MessageFiltersType === currentEventSelected);
	return (
		<div className={styles.advanceOptions}>
			<Row gutter={16}>
				<Col span={12}>
					<CardSection title={`${_('MessageFilters')}:`}>
						<label htmlFor={controllerReportingMessageFilterDropdownId} className={styles.margin}>
							{_('EnableTheFollowingTypesOf')}:
						</label>
						<Select
							className={styles.margin}
							id={controllerReportingMessageFilterDropdownId}
							notUseCurrentParse
							value={currentEventSelected}
							options={advanceReportingSetupInfo.messagesTypesOptions}
							onChange={value =>
								setCurrentEventSelected(
									advanceReportingSetupInfo.messagesFilters.find(x => x.MessageFiltersType === Number(value)).MessageFiltersType
								)
							}
							getPopupContainer={element => element}
						/>
						<div className={styles.wrappBoxes}>
							<Checkbox
								id='controllerReportingMessageEventsCheckbox'
								onChange={e => handleChangeMessageType('Events', e.target.checked)}
								checked={currentMessageTypeToRender.Events}>
								{_('Events')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageCmdResponsesCheckbox'
								onChange={e => handleChangeMessageType('CmdResponses', e.target.checked)}
								checked={currentMessageTypeToRender.CmdResponses}>
								{_('CmdResponses')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageTitleCheckbox'
								onChange={e => handleChangeMessageType('TitleMessages', e.target.checked)}
								checked={currentMessageTypeToRender.TitleMessages}>
								{_('TitleMessages')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageTransactionsCheckbox'
								onChange={e => handleChangeMessageType('Transactions', e.target.checked)}
								checked={currentMessageTypeToRender.Transactions}>
								{_('Transactions')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageCmdCompletesCheckbox'
								onChange={e => handleChangeMessageType('CmdCompletes', e.target.checked)}
								checked={currentMessageTypeToRender.CmdCompletes}>
								{_('CmdCompletes')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageMiscCheckbox'
								onChange={e => handleChangeMessageType('MiscMessages', e.target.checked)}
								checked={currentMessageTypeToRender.MiscMessages}>
								{_('MiscMessages')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageMidnightCheckbox'
								onChange={e => handleChangeMessageType('MidnightReport', e.target.checked)}
								checked={currentMessageTypeToRender.MidnightReport}>
								{_('MidnightReport')}
							</Checkbox>
							<Checkbox
								id='controllerReportingMessageCmdCompletesCheckbox'
								onChange={e => handleChangeMessageType('QueryResponses', e.target.checked)}
								checked={currentMessageTypeToRender.QueryResponses}>
								{_('QueryResponses')}
							</Checkbox>
						</div>
					</CardSection>
				</Col>
				<Col span={12}>
					<CardSection title={`${_('DiagnosticOptions')}:`}>
						<Checkbox
							id='controllerReportingEnableCommandCheckbox'
							onChange={e => handleChangeDiagnosticOption({ enableCommandEcho: e.target.checked })}
							checked={messagesDiagnosticOptions.enableCommandEcho}>
							{_('EnableCommandEcho')}
						</Checkbox>
						<Checkbox
							id='controllerReportingEnablePrintingCheckbox'
							onChange={e => handleChangeDiagnosticOption({ enablePrintingSNETMessagesOnLocalPrinter: e.target.checked })}
							checked={messagesDiagnosticOptions.enablePrintingSNETMessagesOnLocalPrinter}>
							{_('EnablePrintingSNETMessagesOnLocalPrinter')}
						</Checkbox>
						<Checkbox
							id='controllerReportingEnableMCZCheckbox'
							onChange={e => handleChangeDiagnosticOption({ enableMCZReporting: e.target.checked })}
							checked={messagesDiagnosticOptions.enableMCZReporting}>
							{_('EnableMCZReporting')}
						</Checkbox>
						<Checkbox
							id='controllerReportingEnableACBCheckbox'
							onChange={e => handleChangeDiagnosticOption({ enableACBReporting: e.target.checked })}
							checked={messagesDiagnosticOptions.enableACBReporting}>
							{_('EnableACBReporting')}
						</Checkbox>
					</CardSection>
				</Col>
			</Row>
		</div>
	);
};

export { AdvanceReportingSetup };

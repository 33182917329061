import cx from 'classnames';
import React from 'react';
import { DoorGroup } from '../../../../../../model/DeviceAdminModel';
import styles from './controllerTypeChange.module.scss';

type Props = {
	warningTitle: string;
	affectedDoorGroups: DoorGroup[];
};

const ControllerTypeChange: React.FC<Props> = ({ warningTitle, affectedDoorGroups }) => {
	return (
		<div className={styles.container}>
			<p>{warningTitle}</p>
			<div className={cx(styles.gridBase, styles.doorsGroups, styles.doorsGroupsExtraColumn)}>
				<div>{_('DoorGroupId')}</div>
				<div>{_('DoorGroupName')}</div>
				{affectedDoorGroups.map((doorGroup, index) => (
					<React.Fragment key={index}>
						<div>{doorGroup.DoorGroupId}</div>
						<div>{doorGroup.Name}</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export { ControllerTypeChange };

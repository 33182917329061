import { createSelector } from 'reselect';
import { PaginationSetting } from '../../model/CommonModel';
import { DIGITrac, OperatorModel, TimeZonesStateProps, UserRole, VelocityConfigurationModel } from '../../model/DeviceAdminModel';
import { RootReducer } from '../rootReducer';

export const selectTablePaginationSetting = createSelector(
	(state: RootReducer) => state.deviceControlReducer.tablePaginationSetting,
	(tablePaginationSetting: PaginationSetting) => tablePaginationSetting
);

export const selectDigiTrac = createSelector(
	(state: RootReducer) => state.deviceControlReducer.digiTrac,
	(digiTrac: DIGITrac) => digiTrac
);

export const selectDigitracFilterMode = createSelector(
	(state: RootReducer) => state.deviceControlReducer.digiTrac.isFilterMode,
	(isFilterMode: boolean) => isFilterMode
);

export const selectVelocityConfiguration = createSelector(
	(state: RootReducer) => state.deviceControlReducer.velocityConfiguration,
	(velocityConfiguration: VelocityConfigurationModel) => velocityConfiguration
);

export const selectOperators = createSelector(
	(state: RootReducer) => state.deviceControlReducer.velocityConfiguration.operators,
	(operators: OperatorModel[]) => operators
);

export const selectRoles = createSelector(
	(state: RootReducer) => state.deviceControlReducer.velocityConfiguration.roles,
	(roles: UserRole[]) => roles
);

export const selectVelocityConfigurationFilterMode = createSelector(
	(state: RootReducer) => state.deviceControlReducer.velocityConfiguration.isFilterMode,
	(isFilterMode: boolean) => isFilterMode
);

export const selectTimeZonesState = createSelector(
	(state: RootReducer) => state.deviceControlReducer.velocityConfiguration,
	(velocityConfiguration: VelocityConfigurationModel) => {
		const retValue: TimeZonesStateProps = {
			grand: velocityConfiguration.timeZones.grand,
			master: velocityConfiguration.timeZones.master,
			standard: velocityConfiguration.timeZones.standard,
			isEditMode: velocityConfiguration.isEditMode,
			actionScopeKey: velocityConfiguration.actionScopeKey,
		};

		return retValue;
	}
);

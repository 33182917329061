import { Table } from 'antd';
import React from 'react';
import { ModalConfirmation } from '..';
import { buildColumn, ScrollType } from '../../../Helper';
import styles from './modalConfirmationDualList.module.scss';

type Props<T> = {
	type: string;
	translationKey?: string;
	data: any[];
	onConfirm?: (value: T) => void;
	onCancel?: (value: T) => void;
	firstDataIndex: string;
	secondDataIndex: string;
	firstColumnTitle: string;
	secondColumnTitle: string;
	firstColumnWidth?: string;
	secondColumnWidth?: string;
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 272 };

const ModalConfirmationDualList = <T extends object | string | number>({
	data,
	type,
	translationKey,
	onCancel,
	onConfirm,
	firstDataIndex,
	secondDataIndex,
	firstColumnTitle,
	secondColumnTitle,
	firstColumnWidth,
	secondColumnWidth,
}: Props<T>) => {
	const columns: any[] = [
		{
			...buildColumn(firstColumnTitle, firstDataIndex, firstColumnWidth, 'start'),
		},
		{
			...buildColumn(secondColumnTitle, secondDataIndex, secondColumnWidth, 'start'),
		},
	];
	const dataSource = data.map((x, index) => ({ ...x, key: index }));

	const key: string = translationKey === undefined ? 'AreYouSureYouWantToDeleteTheFollowing' : translationKey;

	return ModalConfirmation({
		onConfirm,
		onCancel,
		content: (
			<div className={styles.container}>
				<span>{_(key).replace('%1', type)}</span>
				<br />
				<Table scroll={scroll} size='small' columns={columns} dataSource={dataSource} className={styles.confirmationTable} pagination={false} />
			</div>
		),
	});
};

export { ModalConfirmationDualList };

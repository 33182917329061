import { UpOutlined } from '@ant-design/icons';
import { Collapse, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import { ScrollType } from '../../../Helper';
import { statusApi } from '../../../api';
import { Logger } from '../../../model/LoggingModel';
import { PerformanceData, WidgetInfo } from '../../../model/StatusModel';
import { useStoreSelector } from '../../../store';
import { selectPerformanceStatus } from '../../../store/common/selectors';
import './performanceStatus.scss';

const { Panel } = Collapse;

type Props = {
	widgetInfo: WidgetInfo;
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: 379, y: 312 };
const columns: ColumnsType<any> = [
	{
		title: _('Type'),
		dataIndex: 'Type',
		align: 'left',
	},
	{
		title: _('Description'),
		dataIndex: 'Description',
		align: 'left',
	},
];
const HeaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{_('PerformanceStatus')}</span>);

const PerformanceStatus: React.FC<Props> = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<PerformanceData>(undefined);

	const performanceStatus: PerformanceData = useStoreSelector<PerformanceData>(selectPerformanceStatus);

	useEffect(() => {
		statusApi
			.retrievePerformanceStatus()
			.then(response => {
				batch(() => {
					setIsLoading(false);
					setData(response);
				});
			})
			.catch(e => Logger.writeErrorLog(e));
	}, []);

	useEffect(() => {
		if (performanceStatus) {
			setData(performanceStatus);
		}
	}, [performanceStatus]);

	let content;
	let tableData = [];
	if (!isLoading) {
		let lastBackUp = [];
		let cpuDrives = [];
		let dbDrives = [];
		let services = [];
		let globalIndex = 0;

		if (data && data.DrivesSizeApp.length) {
			cpuDrives = data.DrivesSizeApp.map(drive => {
				globalIndex++;
				return {
					key: `VelocityApplicationServer-DriveSize-${globalIndex}`,
					Type: drive.Item1 ? _('HowMuchFreeSpaceIsOnTheInstallationDrive') : _('HowMuchFreeSpace'),
					Description: drive.Item2,
				};
			});
			dbDrives = data.DrivesSizeDb.map(drive => {
				globalIndex++;
				return {
					key: `VelocitySQLDBServer-DriveSize-${globalIndex}`,
					Type: drive.Item1 ? _('HowMuchFreeSpaceIsOnTheInstallationDrive') : _('HowMuchFreeSpace'),
					Description: drive.Item2,
				};
			});
			services = data.VelocityServices.map(service => {
				globalIndex += 4;
				return [
					{ key: `Services-${globalIndex - 3}`, Type: service.Name, Description: '' },
					{ key: `Services-CpuUtilization-${globalIndex - 2}`, Type: _('CpuUtilization'), Description: service.CpUsage },
					{ key: `Services-MemoryUsage-${globalIndex - 1}`, Type: _('MemoryUsage'), Description: service.MemoryUsage },
					{ key: `Services-Status-${globalIndex}`, Type: _('Status'), Description: service.Status },
				];
			});

			globalIndex += 2;
			cpuDrives.unshift(
				{ key: `VelocityApplicationServer-${globalIndex - 1}`, Type: _('VelocityApplicationServer'), Description: '' },
				{ key: `VelocityApplicationServer-CpuUtilization-${globalIndex}`, Type: _('CpuUtilization'), Description: data.CpUsage }
			);

			if (data.HasBackUp) {
				globalIndex++;
				lastBackUp.push({
					key: `VelocitySQLDBServer-LastBackupDate-${globalIndex}`,
					Type: _('LastBackupDate'),
					Description: FormatDate(data.LastBackupDate, true, null, true, false),
				});
			}

			globalIndex += 4;
			dbDrives.unshift(
				{
					key: `VelocitySQLDBServer-${globalIndex}`,
					Type: _('VelocitySQLDBServer'),
					Description: '',
				},
				{
					key: `VelocitySQLDBServer-CpuUtilization-${globalIndex - 1}`,
					Type: _('CpuUtilization'),
					Description: data.DbCpUsage,
				},
				{
					key: `VelocitySQLDBServer-DbSize-${globalIndex - 2}`,
					Type: _('DbSize'),
					Description: data.DbSize,
				},
				{
					key: `VelocitySQLDBServer-LogSize-${globalIndex - 3}`,
					Type: _('LogSize'),
					Description: data.LogSize,
				},
				...lastBackUp
			);

			let servicesRows = services.flat();
			tableData = [...cpuDrives, ...dbDrives, ...servicesRows];
		}

		content = (
			<Table
				id='PerformanceStatusTable'
				columns={columns}
				dataSource={tableData}
				rowClassName={record => (record.Description === '' ? 'status-performance-title-row' : '')}
				scroll={scroll}
				pagination={false}
				size='small'
				className={'status-widget-table status-widget-performance-status'}
			/>
		);
	}

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className='spin-container'>
			<div className='performance-status-container'>
				<Collapse
					bordered={true}
					defaultActiveKey={'1'}
					expandIconPosition={'right'}
					expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
					className={'status-widget-collapse'}>
					<Panel id='statusDashboardPerformanceStatusWidget' header={<HeaderWidget />} key='1' className='status-widget'>
						{content}
					</Panel>
				</Collapse>
			</div>
		</Spin>
	);
};

export { PerformanceStatus };

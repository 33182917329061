import { Checkbox, Col, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { setInputAction } from '../../../../InputModal/inputModalContext';
import { setDoorReportDoorOpenTooLongAction, setDoorTwoPersonRuleAction } from '../../../DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorStoreSingleContext';
import styles from '../../general.module.scss';

type Props = {};

const Options: React.FC<Props> = () => {
	const {
		contextStateDoor: {
			door: {
				door: { ReportDoorOpenTooLongWhenUnlocked, EnableTwoPersonRule },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleTwoPersonRule = (e: CheckboxChangeEvent) => {
		dispatcherDoor(setDoorTwoPersonRuleAction(e.target.checked));
	};

	const handleReportDoorOpen = (e: CheckboxChangeEvent) => {
		dispatcherDoor(setDoorReportDoorOpenTooLongAction(e.target.checked));
		dispatcherDoor(setInputAction({ ReportDOTLWhenUnlocked: e.target.checked }));
	};

	return (
		<div className={styles.containerLegend}>
			<fieldset>
				<legend className={styles.legend}>{_('Options')}</legend>
				<div className={styles.containerExtraSpace}>
					<Row gutter={[8, 8]}>
						<Col>
							<Checkbox
								id='doorModalEnableTwoPersonRuleCheckbox'
								checked={EnableTwoPersonRule}
								className={styles.checkboxExtraSpacing}
								onChange={handleTwoPersonRule}>
								{_('EnableTwoPersonRule')}
							</Checkbox>
						</Col>
					</Row>
					<Row gutter={[8, 8]}>
						<Col>
							<Checkbox
								id='doorModalReportDoorOpenTooLongWhenUnlocekdCheckbox'
								checked={ReportDoorOpenTooLongWhenUnlocked}
								className={styles.checkboxExtraSpacing}
								onChange={handleReportDoorOpen}>
								{_('ReportDoorOpenTooLongWhenUnlocekd')}
							</Checkbox>
						</Col>
					</Row>
				</div>
			</fieldset>
		</div>
	);
};

export { Options };

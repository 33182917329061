import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { PortStoreContext, setPortChangedAction, setTotalTimeoutConstant } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const TimeoutConstant: React.FC<Props> = () => {
	const {
		portState: { totalTimeoutConstant, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChange = (value: string) => {
		if (!(Number(value) >= 0)) {
			return;
		}
		dispatcher(setTotalTimeoutConstant(Number(value)));
		checkIfPortChanged();
	};

	const portAdvancedSettingsTotalTimeoutConstantInputId = 'portAdvancedSettingsTotalTimeoutConstantInput';

	return (
		<div className={styles.labelContainer}>
			<label id='portAdvancedSettingsTotalTimeoutConstantLabel' htmlFor={portAdvancedSettingsTotalTimeoutConstantInputId}>
				{_('TotalTimeoutConstant')}
			</label>
			<InputNumber
				id={portAdvancedSettingsTotalTimeoutConstantInputId}
				key='totalTimeoutConstant'
				className={styles.inputNumber}
				value={totalTimeoutConstant}
				min={0}
				max={9999}
				step={1}
				maxLength={4}
				onChange={handleChange}
				formatter={noneDecimalFormatter}
			/>
		</div>
	);
};

export { TimeoutConstant };

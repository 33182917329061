import React from 'react';
import { DisableState, EnableContact, EnableMask, SelectAll } from './sections';
import styles from './stateChangeReporting.module.scss';

type Props = {
	useDoorContext: boolean;
};

const StateChangeReporting: React.FC<Props> = ({ useDoorContext }) => {
	return (
		<div className={styles.container}>
			<div className={styles.gridSelect}>
				<DisableState useDoorContext={useDoorContext}></DisableState>
				<SelectAll useDoorContext={useDoorContext}></SelectAll>
			</div>
			<div className={styles.gridDiv}>
				<EnableContact useDoorContext={useDoorContext}></EnableContact>
				<EnableMask useDoorContext={useDoorContext}></EnableMask>
			</div>
		</div>
	);
};

export { StateChangeReporting };

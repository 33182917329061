import { Button, Form, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { buildColumn } from '../../../Helper';
import { BaseColumns, GridDataColumnItem } from '../../../model/CommonModel';
import { CredentialGridItem } from '../../../model/EnrollmentModel';
import { VelocityEventModel } from '../../../model/EventModel';
import { PhotoCallUpUserInformationModel } from '../../../model/PhotoCallUpModel';
import { UserDefinedFieldDetailModel, UserDefinedFieldModel, UserDefinedFieldType } from '../../../model/UserDefinedFields';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { closeEnrollmentUserInfo } from '../../../store/photocallup/actions';
import { selectModalUserInfo } from '../../../store/photocallup/selectors';
import { Modal, PersonInformation } from '../../common';
import { getCredentialColumnRender } from '../../enrollment/CredentialTemplateModal/Helper';
import styles from './userInfo.module.scss';

type UserInfoDataSource = VelocityEventModel & BaseColumns;

const UserInfo = () => {
	const dispatch = useStoreDispatch();
	const content: PhotoCallUpUserInformationModel = useStoreSelector<PhotoCallUpUserInformationModel>(selectModalUserInfo);
	const udfValues: Partial<UserDefinedFieldModel>[] = content?.PersonDetailModel?.UserDefinedFields;
	const visible: boolean = content ? true : false;

	if (udfValues?.length > 0 && content?.GeneralTab) {
		content.GeneralTab.Items.forEach((userDefinedField: UserDefinedFieldDetailModel) => {
			const udfValue: Partial<UserDefinedFieldModel> = udfValues.find(item => item.FieldNumber == userDefinedField.FieldNumber);
			if (udfValue) {
				if (userDefinedField.Type === UserDefinedFieldType.Date && udfValue.Value !== '') {
					userDefinedField.Value = moment(udfValue.Value) as unknown as string;
				} else {
					userDefinedField.Value = udfValue.Value;
				}
			}
		});
	}

	const cols: ColumnsType<UserInfoDataSource> = [
		{
			key: 'hostTime',
			title: 'Host Time',
			dataIndex: 'HostTime',
			render: (_, row) => {
				let text: string;
				try {
					text = FormatDate(row.HostTime, true, null, true);
				} catch {
					text = row.HostTime.toString();
				}

				return {
					children: <span>{text}</span>,
				};
			},
		},
		{
			key: 'controllerTime',
			title: 'Controller Time',
			dataIndex: 'HardwareTime',
			render: (_, row) => {
				let text: string;
				try {
					text = FormatDate(row.HardwareTime, true, null, true);
				} catch {
					text = row.HostTime.toString();
				}

				return {
					children: <span>{text}</span>,
				};
			},
		},
		{
			key: 'description',
			title: 'Description',
			dataIndex: 'Description',
		},
		{
			key: 'address',
			title: 'Address',
			dataIndex: 'Address',
		},
		{
			key: 'eventId',
			title: 'Event Id',
			dataIndex: 'EventId',
			width: '100px',
		},
	];

	const getCredentialColumns = (cols: GridDataColumnItem[]): ColumnsType<CredentialGridItem> =>
		cols.map(c => {
			return {
				...buildColumn(_(c.Value), c.Key, 'auto', 'start'),
				dataIndex: c.Key,
				key: c.Key,
				render: (value, row: CredentialGridItem) => getCredentialColumnRender(value, row, c),
			};
		});

	const handleCloseEnrollmentUserInfo = () => {
		dispatch(closeEnrollmentUserInfo());
	};

	return (
		visible && (
			<Modal
				keyboard={false}
				maskClosable={false}
				onClickOk={() => null}
				onCancel={handleCloseEnrollmentUserInfo}
				title={_('AccessInformation')}
				visible={true}
				width='92%'
				destroyOnClose={true}
				customZoomClass={styles.withModalZoom}
				footer={[
					<Button id={'photoCallUpUserInfoCloseButton'} key='closePhotoCallUpUserInfo' type='primary' onClick={handleCloseEnrollmentUserInfo}>
						{_('Close')}
					</Button>,
				]}>
				<div className={styles.modalInnerContainer}>
					{content?.Event && (
						<div className={styles.accessTable}>
							<span>{_('AccessData')}</span>
							<Table
								columns={cols}
								dataSource={[{ ...content.Event, key: content.Event.EventId }]}
								pagination={false}
								size='small'
								style={{ margin: 0 }}
							/>
							{content?.CredentialDetail && (
								<Table
									columns={getCredentialColumns(content.CredentialDetail.Columns)}
									dataSource={content.CredentialDetail.Data.map(d => ({ ...d, key: d.CredentialId }))}
									pagination={false}
									size='small'
									scroll={{ x: `${500 + content.CredentialDetail.Columns.length * 190}px` }}
									style={{ marginTop: 0 }}
								/>
							)}
						</div>
					)}
					<Form name='personInformation' autoComplete='off' colon={false} labelWrap={true} labelAlign={'left'} size={'middle'}>
						<PersonInformation
							key={`personInformation-container`}
							personInformation={content?.PersonDetailModel}
							udfData={content?.GeneralTab.Items}
							showInfoOnly={true}
							tabId={1}
							errorMessage={''}
							addMode={false}
						/>
					</Form>
				</div>
			</Modal>
		)
	);
};

export { UserInfo };

import { AlarmSoundConfiguration } from './AlarmModel';

export class User {
	componentPermissions: ComponentPermission[];
	subPermissions: ComponentSubItemPermission[];
	photoCallUpBasicView: boolean;
	isAdmin: boolean;
	operatorId: number;
	showThreatLevel: boolean;
	useVelocityEventColors: boolean;
	alarmsPerPage: number;
	eventsPerPage: number;
	eventsPerEventViewer: number;
	showStatusBar: boolean;
	showEventsOnDeviceAdmPage: boolean;
	showEventsOnEnrollmentPage: boolean;
	showEventsOnAlarmsPage: boolean;
	showEventsOnPhotoCallUpPage: boolean;
	showEventsOnStatusViewerPage: boolean;
	showEventsOnWhosInsidePage: boolean;
	showEventsOnReportingPage: boolean;
	alwaysShowInstructions: boolean;
	alarmStackingEnabled: boolean;
	isClearUnacknowledgedAllowed: boolean;
	requireNoteOnAcknowledge: boolean;
	requireNoteOnClear: boolean;
	forceNoteForMultipleAcknowledge: boolean;
	forceNoteForMultipleClear: boolean;
	canViewEnrollmentPage: boolean;
	canViewAlarmPage: boolean;
	canViewReportsPage: boolean;
	canViewEventsPage: boolean;
	canViewWhosInside: boolean;
	canViewDevicePage: boolean;
	canViewDashboard: boolean;
	canViewPhotoCallUp: boolean;
	canViewStatusViewer: boolean;
	queueSortDir: boolean;
	maxAlarmLevelCanAcknowledge: number;
	cultureDateFormat: string;
	noOfCustomAccessZones: number;
	alarmSoundConfiguration: AlarmSoundConfiguration;

	public static getComponentPermission = (user: User, securedComponents: SecuredComponents): ComponentPermission => {
		return user.componentPermissions.find(u => u.securedComponentType === securedComponents);
	};

	public static getSubComponentPermission = (user: User, subPermissionType: SubPermissions): ComponentSubItemPermission => {
		return user.subPermissions.find(u => u.subPermissionType === subPermissionType);
	};
}

export const getPermissionErrorMessage = (hasPermission: boolean, allowedForMultiSelect: boolean = true, hasMoreThanOne: boolean = false): string => {
	if (hasPermission && (allowedForMultiSelect || (!allowedForMultiSelect && !hasMoreThanOne))) {
		return '';
	}

	if (!hasPermission) {
		return _('DisabledButtonPermissionsWarning');
	}

	return _('OnlyAvailableForSingleSelection');
};

export type ComponentPermission = {
	securedComponentType: SecuredComponents;
	canView: boolean;
	canAdd: boolean;
	canUpdate: boolean;
	canDelete: boolean;
};

export type ComponentSubItemPermission = {
	subPermissionType: SubPermissions;
	allowed: boolean;
};

export type LogOnUser = {
	isWindowsAuthenticated: boolean;
	isDBWindowsEnabled: boolean;
	showEULAAgreement: boolean;
	showUserNotification: boolean;
	redirectUrlFromMVC: string;
	version: string;
};

export type AssertionOptions = { status: string; errorMessage: string } & PublicKeyCredentialRequestOptions;

export type AuthenticatorAssertionRawResponse = {
	extensions: AuthenticationExtensionsClientOutputs;
	response: AuthenticatorAssertionResponse;
	type: number;
} & Partial<Omit<PublicKeyCredential, 'response' | 'type'>>;

export type PublicKeyCredential = {
	type: 'public-key';
	rawId: ArrayBuffer;
	response: AuthenticatorAttestationResponse;
	getClientExtensionResults(): AuthenticationExtensionsClientOutputs;
} & Credential;

export type AuthenticatorAttestationRawResponse = {
	id: string;
	rawId: BufferSource;
	type?: number;
	response: Partial<AuthenticatorAttestationResponse>;
	extensions?: AuthenticationExtensionsClientOutputs;
};

export type CredentialCreateOptions = { status: string; errorMessage: string } & PublicKeyCredentialCreationOptions;

export enum SecuredComponents {
	None = -1,
	Administration_Window = 37,
	Alarm_Receiver = 83,
	Alarm_Responses = 101,
	Alarm_Viewer = 88,
	Badges = 89,
	Card_Data_Mapping = 103,
	CCTV_Camera = 35,
	CCTV_Switcher = 36,
	Central_Stations_Account = 84,
	Central_Stations_Area = 85,
	Central_Stations_Point = 86,
	Command_Set = 18,
	Control_Zone_Editor = 32,
	Controller = 15,
	Credential_Template = 24,
	Customization_Manager = 95,
	Dialup_Location = 40,
	Dialup_Modem_Pool = 41,
	DIGINet_Command_Set = 111,
	DIGINet_Controller_Settings = 112,
	DIGINet_Custom_Line_Module = 114,
	DIGINet_Doors = 108,
	DIGINet_Download_monitor = 118,
	DIGINet_Function_Group_Extension = 116,
	DIGINet_Function_Groups = 115,
	DIGINet_Inputs = 106,
	DIGINet_Physical_Zone = 117,
	DIGINet_Reader_Locations = 107,
	DIGINet_Relays = 105,
	DIGINet_Time_Zones = 104,
	Door = 4,
	Door_Groups = 21,
	DVR_Unit = 50,
	Edge_Controller = 119,
	Edge_DoorGroup = 121,
	Edge_Holiday = 122,
	Edge_HolidayGroup = 123,
	Edge_Schedule = 120,
	Email_Writer = 38,
	Enrollment_Credential = 17,
	Enrollment_Person = 16,
	Enrollment_Manager = 92,
	Event_Control_Block = 113,
	Event_Viewer = 96,
	Expansion_Input = 7,
	Expansion_Relay = 8,
	Function_Control_Zone = 110,
	Function_Group = 19,
	Function_Group_Extensions = 22,
	Grand_Master_Time_Zone = 14,
	Graphics = 94,
	Maps = 128,
	Holiday = 9,
	Input = 5,
	Master_Door_Group = 44,
	Master_Time_Zone = 13,
	Message_Queue_Writer = 87,
	Operator = 10,
	Person_Template = 52,
	Point_Control_Zone = 109,
	Ports = 1,
	Reader = 3,
	Reader_Control_Group = 126,
	Relay = 6,
	Reports = 93,
	Roles = 11,
	Serial_Port_Writer = 39,
	Service_Control_Manager = 98,
	Standard_Time_Zone = 12,
	Status_Viewer = 97,
	Velocity = 91,
	Web_Client_MultiFactor = 129,
	Whos_Inside = 100,
	Workstations = 99,
	XBox = 2,
	Global_IO_Group = 127,
	SNIB_Search = 9900,
}

export enum SubPermissions {
	AdministrationWindow_AdministrationWindow_Use = 212,
	AdministrationWindow_AdministrationWindow_UseFunctions = 213,
	AlarmReceiver_ViewGeneralTab = 198,
	AlarmViewer_AlarmViewer_CopytoClipboard = 294,
	AlarmViewer_AlarmViewer_AddNotes = 295,
	AlarmViewer_AlarmsRTNs_AcknowledgeMultiple = 219,
	AlarmViewer_AlarmsRTNs_Acknowledge = 218,
	AlarmViewer_AlarmsRTNs_ClearinStandardMode = 220,
	AlarmViewer_Alarms_ClearinRestrictedMode = 217,
	AlarmViewer_AlarmViewer_ClosewithActiveAlarms = 215,
	AlarmViewer_AlarmViewer_Use = 216,
	AlarmViewer_AlarmViewer_ChangeProperties = 214,
	AlarmViewer_AlarmsRTNs_ClearMultiple = 221,
	Badges_Badges_Print = 224,
	Badges_Badges_Preview = 223,
	Badges_Badges_OverridePrintControl = 313,
	Badges_Badges_Edit = 222,
	CardDataMapping_ViewGeneralTab = 314,
	CCTVCamera_ViewGeneralTab = 79,
	CCTVCamera_ViewPresetsTab = 85,
	CCTVCamera_CCTVViewer_Use = 226,
	CCTVSwitcher_ViewGeneralTab = 83,
	CentralStationsAccount_ViewGeneralTab = 199,
	CentralStationsArea_ViewGeneralTab = 200,
	CentralStationsPoint_ViewGeneralTab = 201,
	CommandSet_ViewGeneralTab = 48,
	ControlZoneEditor_ViewStandardControlZonesTab = 74,
	ControlZoneEditor_ViewMasterControlZonesTab = 77,
	Controller_ViewGeneralTab = 31,
	Controller_ViewSetupTab = 32,
	Controller_ViewReportingTab = 33,
	Controller_ViewPassbackTab = 34,
	Controller_ViewControlZonesTab = 78,
	Controller_ViewAlarmActionsTab = 36,
	Controller_ViewCommunicationsTab = 38,
	Controller_Firmware_UpdateCCMSNIB2Firmware = 252,
	CredentialTemplate_ViewGeneralTab = 61,
	CredentialTemplate_ViewFunctionTab = 62,
	CredentialTemplate_ViewLimitsTab = 63,
	CredentialTemplate_ViewOptionsTab = 64,
	CredentialTemplate_LinkToTemplate = 500,
	CustomizationManager_Customization_EnterWindowDesignMode = 269,
	CustomizationManager_CustomizationManager_Use = 270,
	DialupLocation_ViewHostSettingsTab = 90,
	DialupLocation_ViewRemoteSettingsTab = 91,
	DialupModemPool_ViewGeneralTab = 101,
	DIGINetDoors_ViewGeneralTab = 332,
	DIGINetDoors_ViewDoorElementsTab = 333,
	DIGINetDownloadmonitor_ViewConfigurationDownloadsTab = 389,
	DIGINetDownloadmonitor_ViewFirmwareDownloadsTab = 390,
	DIGINetDownloadmonitor_ScheduleDownload = 392,
	DIGINetFunctionGroups_ViewDIGINetFunctionGroupGeneraltab = 334,
	DIGINetInputs_ViewGeneralTab = 326,
	DIGINetInputs_ViewLogicTab = 327,
	DIGINetReaderLocations_ViewGeneralTab = 328,
	DIGINetReaderLocations_ViewScramblePadOptionsTab = 329,
	DIGINetReaderLocations_ViewCardReaderOptionsTab = 330,
	DIGINetReaderLocations_ViewLogicTab = 331,
	DIGINetReaderLocations_Bundling_Allow = 394,
	DIGINetRelays_ViewGeneralTab = 324,
	DIGINetRelays_ViewLogicTab = 325,
	DIGINetTimeZones_ViewTimeZonesTab = 320,
	DIGINetTimeZones_ViewTimeZoneGroupsTab = 321,
	DIGINetTimeZones_ViewTimeZoneEventsTab = 322,
	DIGINetTimeZones_ViewTimeZoneEventGroupsTab = 323,
	EnrollmentManager_EMSCANNER_AllowManualOverrideProfile = 496,
	Door_ViewExitReaderTab = 70,
	Door_ViewGeneralTab = 19,
	Door_ViewEntryReaderTab = 20,
	Door_ViewRelayTab = 21,
	Door_ViewInputTab = 22,
	DoorGroups_ViewGeneralTab = 51,
	DVRUnit_ViewGeneralTab = 140,
	DVRUnit_AllVideoEvents_Use = 297,
	DVRUnit_DVREventHistoryViewer_Use = 296,
	E_mailWriter_ViewSettingsTab = 87,
	E_mailWriter_ViewE_mailContentTab = 88,
	Enrollment_Credential_ViewGeneralTab = 44,
	Enrollment_Credential_ViewFunctionTab = 45,
	Enrollment_Credential_ViewLimitsTab = 46,
	Enrollment_Credential_ViewOptionsTab = 47,
	Enrollment_Credential_BiometricsTab = 319,
	Enrollment_Person_EditGeneralTabUDFs = 302,
	Enrollment_Person_EditTab1UDFs = 303,
	Enrollment_Person_EditTab2UDFs = 304,
	Enrollment_Person_EditTab3UDFs = 305,
	Enrollment_Person_EditTab4UDFs = 306,
	Enrollment_Person_EditTab5UDFs = 307,
	Enrollment_Person_EditTab6UDFs = 308,
	Enrollment_Person_EditTab7UDFs = 309,
	Enrollment_Person_EditTab8UDFs = 310,
	Enrollment_Person_EditTab9UDFs = 311,
	Enrollment_Person_EditTab10UDFs = 312,
	Enrollment_Person_ViewGeneralTab = 40,
	Enrollment_Person_ViewAdditionalImagesTab = 93,
	Enrollment_Person_ViewTAB1Tab = 41,
	Enrollment_Person_ViewGroupsTab = 158,
	Enrollment_Person_ViewTAB9Tab = 121,
	Enrollment_Person_ViewTAB10Tab = 122,
	Enrollment_Person_ViewTAB2Tab = 114,
	Enrollment_Person_ViewTAB3Tab = 115,
	Enrollment_Person_ViewTAB4Tab = 116,
	Enrollment_Person_ViewTAB5Tab = 117,
	Enrollment_Person_ViewTAB6Tab = 118,
	Enrollment_Person_ViewTAB7Tab = 119,
	Enrollment_Person_ViewTAB8Tab = 120,
	Enrollment_Person_ViewAdditionalNotesTab = 502,
	EnrollmentManager_EnrollmentManager_DeviceConfiguration = 231,
	EnrollmentManager_EnrollmentManager_FindCredentialbyPIN = 233,
	EnrollmentManager_Credentials_AllowOverrideofOldPINCodes = 254,
	EnrollmentManager_Credentials_AllowOverrideofOldCardCodes = 253,
	EnrollmentManager_Credentials_ForgivePassback = 258,
	EnrollmentManager_Credentials_ResetLimitCount = 261,
	EnrollmentManager_Credentials_AssignUnassign = 255,
	EnrollmentManager_EnrollmentManager_ChangeUserDefinedFields = 230,
	EnrollmentManager_Credentials_BatchEnroll = 256,
	EnrollmentManager_EnrollmentManager_Use = 237,
	EnrollmentManager_Credentials_OverrideCodeTamper = 260,
	EnrollmentManager_Credentials_ForceDownload = 257,
	EnrollmentManager_EnrollmentManager_DisplayPINCodes = 232,
	EnrollmentManager_Person_Usescanners = 316,
	EnrollmentManager_Credentials_PrintControl = 281,
	EnrollmentManager_Credentials_IssueControl = 285,
	EnrollmentManager_EnrollmentManager_Preferences = 236,
	EnrollmentManager_Credentials_Linktotemplate = 259,
	EnrollmentManager_EnrollmentManager_ManagePersonGroups = 235,
	EnrollmentManager_EnrollmentManager_ManagePersonFolders = 234,
	EnrollmentManager_EnrollmentManager_FindCredential = 299,
	EventControlBlock_ViewEvent_CentricViewTab = 335,
	EventControlBlock_ViewPoint_CentricViewTab = 336,
	EventControlBlock_ViewCustomECBSetupsTab = 337,
	EventViewer_ChangeProperties = 292,
	EventViewer_CopytoClipboard = 293,
	EventViewer_ViewAllEvents = 298,
	EventViewer_Use = 272,
	EventViewer_DisplayStateChanges = 271,
	ExpansionInput_ViewStateChangeReportingTab = 100,
	ExpansionInput_ViewGeneralTab = 27,
	ExpansionInput_ViewLogicTab = 28,
	ExpansionRelay_ViewGeneralTab = 29,
	ExpansionRelay_ViewLogicTab = 30,
	FunctionGroup_ViewFunctionGroupGeneralTab = 49,
	FunctionGroupExtensions_ViewDefineExtensionGeneralTab = 82,
	Graphics_Graphics_Use = 264,
	Graphics_Graphics_UseRight_ClickFunctions = 267,
	Graphics_Graphics_UseDesignMode = 266,
	Graphics_Graphics_UseCustomLinkManager = 265,
	Graphics_Graphics_UseLeft_ClickFunctions = 318,
	Maps_Maps_Use = 504,
	Maps_Maps_UseRight_ClickFunctions = 508,
	Maps_Maps_UseDesignMode = 506,
	Maps_Maps_UseCustomLinkManager = 505,
	Maps_Maps_UseLeft_ClickFunctions = 507,
	Holiday_ViewGeneralTab = 1,
	Input_ViewGeneralTab = 23,
	Input_ViewLogicTab = 24,
	Input_ViewStateChangeReportingTab = 99,
	MasterDoorGroup_ViewGeneralTab = 125,
	Operator_ViewGeneralTab = 13,
	Operator_ViewRolesTab = 15,
	PersonTemplate_ViewTAB1Tab = 186,
	PersonTemplate_ViewGeneralTab = 185,
	PersonTemplate_ViewTAB10Tab = 195,
	PersonTemplate_ViewGroupsTab = 197,
	PersonTemplate_ViewTAB2Tab = 187,
	PersonTemplate_ViewTAB3Tab = 188,
	PersonTemplate_ViewTAB5Tab = 190,
	PersonTemplate_ViewTAB4Tab = 189,
	PersonTemplate_ViewTAB8Tab = 193,
	PersonTemplate_ViewTAB9Tab = 194,
	PersonTemplate_ViewTAB6Tab = 191,
	PersonTemplate_ViewTAB7Tab = 192,
	PersonTemplate_ViewAdditionalImagesTab = 196,
	PersonTemplate_ViewAdditionalNotesTab = 501,
	Ports_ViewGeneralTab = 52,
	Reader_ViewGeneralTab = 3,
	Reader_ViewLogicTab = 4,
	Reader_ViewScramblePadOptionsTab = 71,
	Reader_ViewCardReaderSetup = 395,
	Reader_FirmwareUpdateTSReader = 497,
	Relay_ViewGeneralTab = 25,
	Relay_ViewLogicTab = 26,
	Reports_ReportManager_Use = 262,
	Reports_Reports_AddDeleteReportsandReportGroups = 263,
	ServiceControlManager_ServiceControlManager_ChangeSettings = 279,
	StatusViewer_StatusViewer_Use = 276,
	StatusViewer_StatusViewer_AddEditandDeleteStatusGroups = 278,
	StatusViewer_StatusViewer_UseFunctions = 277,
	Velocity_ClientAdd_Ins_UseManagers = 227,
	Velocity_CCMImportWizard_Use = 225,
	Velocity_Customization_ImportExportWizard_Use = 268,
	Velocity_DataExportWizard_Use = 242,
	Velocity_DataImportWizard_Use = 243,
	Velocity_VelocityPreferences_ChangeAll = 228,
	Velocity_ImportControllerConfiguration_Use = 247,
	Velocity_InstallVelocityClients = 248,
	Velocity_ExitVelocity_CloseVelocity = 238,
	Velocity_Off_NormalWindow_Use = 250,
	Velocity_DownloadMonitor_Use = 245,
	Velocity_SNIB2ImportWizard_Use = 273,
	Velocity_ExitVelocity_LogOnasaDifferentOperator = 240,
	Velocity_ExitVelocity_RestartOperatingSystem = 241,
	Velocity_ExitVelocity_LockWorkstation = 239,
	Velocity_SQLManager_Use = 274,
	Velocity_VelocitySchedulingAgent_Use = 275,
	Velocity_DiagnosticWindow_Use = 244,
	Velocity_VelocityPreferences_ChangePrinterSettings = 229,
	Velocity_DIGINetFirmwareImport_Use = 393,
	Velocity_DIGINetDiagnosticWindow_Use = 391,
	Velocity_TriggerActionManager_Use = 317,
	Velocity_ExportControllerConfiguration_Use = 246,
	Velocity_LinkedDevices_Manage = 249,
	Velocity_VideoExplorer = 288,
	Web_Client_MultiFactor_TwoFactorAdministrator = 510,
	WhosInside_DisplayWhosInsideHTMLReport = 289,
	WhosInside_AssignCredentialstoZone = 290,
	WhosInside_WhosInside_Use = 286,
	WhosInside_Permissiontoforgiveusers = 287,
	WhosInside_EditZones = 498,
	XBox_ViewGeneralTab = 9,
	Velocity_EdgeDownloadMonitor = 453,
	Velocity_EdgeConfiguration = 454,
	Velocity_EdgeControllerDownloads = 479,
	Velocity_EdgeControllerAccessFunctions = 480,
	Velocity_EdgeControllerControlFunctions = 481,
	Reader_ViewTSScrambleFactor = 509,
}

export type LogOnModel = {
	UserName: string;
	Password: string;
	RememberMe: boolean;
	OperatorId: number;
	WorkstationName: string;
	IsWindowsAuthenticationEnabled: boolean;
	ShowUserNotification: boolean;
	ShowEULAAgreement: boolean;
	RedirectUrl: string;
	IpLockout: boolean;
	SecurityKeyValidated: boolean;
};

export type LogOnViewModel = {
	LogOnInfo: LogOnModel;
	StatusCode: LoginResponseStatusCode;
	Version: string;
	RedirectTo: string;
	Success: boolean;
	Message: string;
};

export enum LoginResponseStatusCode {
	Success = 0,
	DatabaseAccessError,
	OperatorDoesNotExist,
	BadPassword, //3
	CommunicationError,
	TooManyAttempts,
	SessionTimeout, // 6
	LogoutSuccess,
	UnknownError,
	SystemAccountPermissionError, //9
	BadToken,
	BadLicense,
	None,
	AccountLocked,
	AccessDenied,
	SecurityKeyRequired,
	AddSecurityKey,
}

import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { SubPermissions, User } from '../../../../../../../model/AccountModel';
import { FirmwareRevisionInfoStore, SNIB3EncryptionResetWarning } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setFirmwareMessageAction } from '../../../../../../../store/deviceControl/controller/actions';
import { selectFirmwareRevisionInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { InformationMessages, ModalAlert, ModalConfirmation } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../general.module.scss';
import { FirmwareUpdate } from './FirmwareUpdate/FirmwareUpdate';
import { SNIB3Update } from './SNIB3Update/SNIB3Update';

const user: User = getUser();
const canUpdateCCMFirmware = User.getSubComponentPermission(user, SubPermissions.Controller_Firmware_UpdateCCMSNIB2Firmware).allowed;
type Props = {
	callbackRedirect?: () => void;
};

const FirmwareRevision: React.FC<Props> = ({ callbackRedirect }: Props) => {
	const firmwareRevisionInfo: FirmwareRevisionInfoStore = useStoreSelector<FirmwareRevisionInfoStore>(selectFirmwareRevisionInfo);
	const dispatch = useStoreDispatch();

	useEffect(() => {
		deviceAdminApi.loadMessagesForFirmwareConfiguration(firmwareRevisionInfo.SupportsSNIB3).then(data => dispatch(setFirmwareMessageAction(data.Entity)));
	}, []);

	const [showFirmwareUpdate, setShowFirmwareUpdate] = useState(false);
	const [showSNIB3Update, setSNIB3Update] = useState(false);

	const handleOnClickFirmware = () => {
		if (firmwareRevisionInfo.writeProtect) {
			ModalAlert({
				title: _('UpdateCCMFirmware'),
				children: <span>{firmwareRevisionInfo.firmwareMessage.WriteProtect.Labels.join('')}</span>,
			});
		} else {
			ModalConfirmation({
				title: firmwareRevisionInfo.firmwareMessage.CCM.Caption,
				content: <InformationMessages messages={firmwareRevisionInfo.firmwareMessage.CCM.Labels} />,
				onConfirm: () => setShowFirmwareUpdate(true),
				onCancel: () => setShowFirmwareUpdate(false),
				icon: <WarningOutlined />,
			});
		}
	};

	const encryptionSwitch = () => {
		deviceAdminApi.retrieveSNIB3EncryptionSwitch(firmwareRevisionInfo.controllerId).then(res => {
			const { EncryptionSwitch: encryptionSwitch, Labels: labels }: SNIB3EncryptionResetWarning = res.Entity;

			if (firmwareRevisionInfo.SupportsSNIB3 && encryptionSwitch !== 1) {
				ModalConfirmation({
					title: _('EncryptionResetWarning'),
					content: <InformationMessages messages={labels} className={styles.snibEncryptionTitle} />,
					onConfirm: () => updateFirmware(),
					onCancel: () => undefined,
					okText: _('Proceed'),
					icon: <WarningOutlined />,
				});
			} else {
				updateFirmware();
			}
		});
	};

	const updateFirmware = () => {
		ModalConfirmation({
			title: firmwareRevisionInfo.firmwareMessage.SNIB.Caption,
			content: <InformationMessages messages={firmwareRevisionInfo.firmwareMessage.SNIB.Labels} />,
			onConfirm: () => setSNIB3Update(true),
			onCancel: () => setSNIB3Update(false),
			icon: <WarningOutlined />,
		});
	};

	const handleOnClickSNIB2 = () => {
		encryptionSwitch();
	};

	const firmwareRevisionMapped = firmwareRevisionInfo?.firmwareRevision?.map((firmware, index) => (
		<React.Fragment key={index}>
			<div className={styles.listItem}>
				<div>{_(`${firmware.Label}`)}:</div>
				<div>{firmware.Value}</div>
			</div>
		</React.Fragment>
	));

	return (
		<CardSection title={_('FirmwareRevision')} width='100%'>
			<div className={styles.firmwareRevision}>
				<div className={styles.firmwareGrid}>{...firmwareRevisionMapped}</div>
				<div>
					<Button
						id='controllerGeneralUpdateCCMButton'
						onClick={handleOnClickFirmware}
						type='primary'
						disabled={!firmwareRevisionInfo.canUpdateFirmware || !canUpdateCCMFirmware}>
						{_('FlashCCMFirmware')}
					</Button>
					<Button
						id='controllerGeneralUpdateSNIBButton'
						onClick={handleOnClickSNIB2}
						type='primary'
						disabled={!firmwareRevisionInfo.enableUpdateSNIB || !canUpdateCCMFirmware}>
						{firmwareRevisionInfo.SupportsSNIB3 ? _('FlashSNIB3Firmware') : _('FlashSNIB2Firmware')}
					</Button>
				</div>
			</div>
			{showFirmwareUpdate && <FirmwareUpdate callbackRedirect={callbackRedirect} onCancel={() => setShowFirmwareUpdate(false)} />}
			{showSNIB3Update && <SNIB3Update callbackRedirect={callbackRedirect} onCancel={() => setSNIB3Update(false)} />}
		</CardSection>
	);
};

export { FirmwareRevision };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './enableMask.module.scss';

type Props = {
	useDoorContext: boolean;
};

const EnableMask: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleChange = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledMaskStateChangeReportingDoorRelay: e.target.checked }));
	};
	const handleChangeRQE = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledMaskStateChangeReportingRQE: e.target.checked }));
	};
	const handleChangeEntryExit = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledMaskStateChangeReportingEntyExitDelay: e.target.checked }));
	};
	const handleChangeUnlock = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledMaskStateChangeReportingUnlock: e.target.checked }));
	};
	const handleChangeTimeZone = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledMaskStateChangeReportingTimeZone: e.target.checked }));
	};
	const handleChangeControlZone = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledMaskStateChangeReportingControlCode: e.target.checked }));
	};
	return (
		<div className={styles.container}>
			<fieldset>
				<legend className={styles.legend}>{_('EnableMaskStateChangeReporting')}</legend>
				<div className={styles.content}>
					<Checkbox
						id='enableMaskInputDoorRelayCheckbox'
						checked={inputContext.context?.input?.EnabledMaskStateChangeReportingDoorRelay}
						onChange={handleChange}>
						{_('DoorRelay')}
					</Checkbox>
					<Checkbox
						id='enableMaskInputRQECheckbox'
						checked={inputContext.context?.input?.EnabledMaskStateChangeReportingRQE}
						onChange={handleChangeRQE}>
						{_('RQE')}
					</Checkbox>
					<Checkbox
						id='enableMaskInputEntryExitDelayCheckbox'
						checked={inputContext.context?.input?.EnabledMaskStateChangeReportingEntyExitDelay}
						onChange={handleChangeEntryExit}>
						{_('EntryExitDelay')}
					</Checkbox>
					<Checkbox
						id='enableMaskInputUnlockCheckbox'
						checked={inputContext.context?.input?.EnabledMaskStateChangeReportingUnlock}
						onChange={handleChangeUnlock}>
						{_('Unlock')}
					</Checkbox>
					<Checkbox
						id='enableMaskInputTimeZoneCheckbox'
						checked={inputContext.context?.input?.EnabledMaskStateChangeReportingTimeZone}
						onChange={handleChangeTimeZone}>
						{_('TimeZone')}
					</Checkbox>
					<Checkbox
						id='enableMaskInputControlCodeCheckbox'
						checked={inputContext.context?.input?.EnabledMaskStateChangeReportingControlCode}
						onChange={handleChangeControlZone}>
						{_('ControlCode')}
					</Checkbox>
				</div>
			</fieldset>
		</div>
	);
};

export { EnableMask };

import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import { TransferDirection, TransferItem } from 'antd/lib/transfer';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { ColumnsProps, buildColumn, getDefaultTablePaginationConfig, handleResponse, invertSelectedRowKeys } from '../../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../../api';
import { PaginationSetting, SortDirections } from '../../../../../../../model/CommonModel';
import {
	GlobalIOGroup,
	GlobalIOGroupInfo,
	GlobalIOGroupSNIB3,
	GlobalIOGroupSNIBColumns,
	GlobalIOGroupSNIBTransfer,
} from '../../../../../../../model/DeviceAdminModel';
import styles from '../../globalIOGroupModal.module.scss';
import { GlobalIOTransfer } from '../GlobalIOTransfer/GlobalIOTransfer';

const titles = [_('NonMembers'), _('ClientMembers')];

type Props = {
	globalIOGroup: GlobalIOGroup;
	isCredentialManagement: boolean;
	dataTableTransfer: GlobalIOGroupSNIBTransfer[];
	globalIOServerId: number;
	isLeftTransferLoading: boolean;
	isRightTransferLoading: boolean;
	leftPagination: PaginationSetting;
	rightPagination: PaginationSetting;
	selectedMembers: GlobalIOGroupSNIB3[];
	unselectedMembers: GlobalIOGroupSNIB3[];
	getUnselectedMembersPagination: (paginationSetting: PaginationSetting) => void;
	getSelectedMembersPagination: (paginationSetting: PaginationSetting, isSearchActive?: boolean) => void;
	getGlobalIOMembersPagination: (selectedMembers: GlobalIOGroupSNIB3[], isSearchPerformed: boolean) => void;
	setRightPagination: (paginationSetting: PaginationSetting) => void;
	setIsLeftTransferLoading: (value: boolean) => void;
	setIsRightTransferLoading: (value: boolean) => void;
};

const MembersTableTransfer: React.FC<Props> = ({
	globalIOGroup,
	isCredentialManagement,
	dataTableTransfer,
	globalIOServerId,
	isLeftTransferLoading,
	isRightTransferLoading,
	leftPagination,
	rightPagination,
	selectedMembers,
	unselectedMembers,
	getUnselectedMembersPagination,
	getSelectedMembersPagination,
	setRightPagination,
	getGlobalIOMembersPagination,
	setIsLeftTransferLoading,
	setIsRightTransferLoading,
}) => {
	const [targetKeys, setTargetKeys] = useState<string[]>([]);
	const [leftSelectedKeys, setLeftSelectedKeys] = useState<React.Key[]>([]);
	const [rightSelectedKeys, setRightSelectedKeys] = useState<React.Key[]>([]);
	const [isSearchPerformed, setIsSearchPerformed] = useState<boolean>(false);

	useEffect(() => {
		const currentTargetKeys = getTargetKeys();
		setTargetKeys(currentTargetKeys);
		const newTotalItems = rightPagination.TotalItems > 0 ? rightPagination.TotalItems : currentTargetKeys.length;
		setRightPagination({ ...rightPagination, TotalItems: newTotalItems });
	}, [selectedMembers]);

	useEffect(() => {
		if (leftPagination.TotalItems === 0 && leftPagination.PageNumber > 1) {
			const paginationSetting: PaginationSetting = {
				...leftPagination,
				PageNumber: leftPagination.PageNumber - 1,
			};
			getUnselectedMembersPagination(paginationSetting);
		} else if (rightPagination.TotalItems === 0 && rightPagination.PageNumber > 1) {
			const paginationSetting: PaginationSetting = {
				...rightPagination,
				PageNumber: rightPagination.PageNumber - 1,
			};
			getSelectedMembersPagination(paginationSetting, isSearchPerformed);
		}
	}, [leftPagination, rightPagination]);

	useEffect(() => {
		const isGlobalServerSelected = rightSelectedKeys.some(x => x === globalIOServerId.toString());

		if (isGlobalServerSelected) {
			const newRightSelectedKeys = rightSelectedKeys.filter(x => x !== globalIOServerId.toString());
			setRightSelectedKeys(newRightSelectedKeys);
		}
	}, [globalIOServerId]);

	const handleOnSearchTransfer = useCallback(
		debounce(async (direction: TransferDirection, value: string) => {
			const hasValue = value !== '';
			setIsSearchPerformed(hasValue);
			const isLeftGrid = direction === 'left';
			const paginationSetting: PaginationSetting = isLeftGrid
				? { ...leftPagination, PageNumber: 1, SearchedValue: value }
				: { ...rightPagination, PageNumber: 1, SearchedValue: value };

			isLeftGrid ? getUnselectedMembersPagination(paginationSetting) : getSelectedMembersPagination(paginationSetting, hasValue);
		}, 200),
		[leftPagination, rightPagination]
	);

	const getTargetKeys = (): string[] => {
		return selectedMembers.map<string>(x => x.portId.toString());
	};

	const leftPaginationConfig: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		undefined,
		leftPagination.PageNumber,
		leftPagination.PageSize,
		leftPagination.TotalItems,
		true,
		leftSelectedKeys
	);

	const rightPaginationConfig: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		undefined,
		rightPagination.PageNumber,
		rightPagination.PageSize,
		rightPagination.TotalItems,
		true,
		rightSelectedKeys
	);

	const getSelectedRowKeysForSelectAll = async (isLeftGrid: boolean) => {
		const { id, topic } = globalIOGroup;
		const globalIOGroupInfo: Partial<GlobalIOGroupInfo> = {
			id,
			topic,
			selectedMembers,
		};
		const response = isLeftGrid
			? await deviceAdminApi.getGlobalIOGroupServersBySelectedMembers(globalIOGroupInfo)
			: await deviceAdminApi.getGlobalIOGroupMembersBySelectedMembers(globalIOGroupInfo);

		if (handleResponse(response)) {
			return;
		}

		return response.Entity;
	};

	const handleOnSelectAll = async (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => {
		const isLeftGrid = direction === 'left';
		isLeftGrid ? setIsLeftTransferLoading(true) : setIsRightTransferLoading(true);

		const allKeys = await getSelectedRowKeysForSelectAll(isLeftGrid);

		const newSelectedKeys = allKeys.filter(key => key !== globalIOServerId.toString());

		batch(() => {
			onItemSelectAll(newSelectedKeys, true);
			if (isLeftGrid) {
				setLeftSelectedKeys(newSelectedKeys);
				setIsLeftTransferLoading(false);
			} else {
				setRightSelectedKeys(newSelectedKeys);
				setIsRightTransferLoading(false);
			}
		});
	};

	const handleOnSelectInvert = (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => {
		const isLeftGrid = direction === 'left';
		const selectedRowKeys = isLeftGrid ? leftSelectedKeys : rightSelectedKeys;
		const currentKeys = isLeftGrid
			? unselectedMembers.map<React.Key>(member => member.portId.toString())
			: selectedMembers.map<React.Key>(member => member.portId.toString());

		onItemSelectAll(
			currentKeys.map<string>(key => key.toString()),
			false
		);

		const invertedSelectedRowKeys = invertSelectedRowKeys(currentKeys, selectedRowKeys);

		const newSelectedRowKeys = invertedSelectedRowKeys.filter(key => key !== globalIOServerId.toString()).map<string>(key => key.toString());

		batch(() => {
			onItemSelectAll(newSelectedRowKeys, true);
			isLeftGrid ? setLeftSelectedKeys(newSelectedRowKeys) : setRightSelectedKeys(newSelectedRowKeys);
		});
	};

	const handleOnSelectNone = (direction: TransferDirection, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => {
		let clearSelectedKeys = undefined;
		let selectedRowKeys: string[] = [];
		if (direction === 'left') {
			selectedRowKeys = [...leftSelectedKeys.map<string>(key => key.toString())];
			clearSelectedKeys = () => setLeftSelectedKeys([]);
		} else if (direction === 'right') {
			selectedRowKeys = [...rightSelectedKeys.map<string>(key => key.toString())];
			clearSelectedKeys = () => setRightSelectedKeys([]);
		}

		batch(() => {
			onItemSelectAll(selectedRowKeys, false);
			clearSelectedKeys();
		});
	};

	const getRowSelection = (isLeftGrid: boolean) => {
		const selectedRowKeys = isLeftGrid ? leftSelectedKeys : rightSelectedKeys;
		const rowSelection: TableRowSelection<GlobalIOGroupSNIBColumns> = {
			preserveSelectedRowKeys: true,
			type: 'checkbox',
			selectedRowKeys,
		};
		return rowSelection;
	};

	const leftRowSelection = getRowSelection(true);
	const rightRowSelection = getRowSelection(false);

	const getColumns = () => {
		const columns: ColumnsProps<GlobalIOGroupSNIBColumns>[] = [
			{
				...buildColumn(_('Name'), 'portName', 'auto', 'start'),
				editable: true,
				sorter: (a, b) => a.portName.localeCompare(b.portName),
			},
			{
				...buildColumn(_('Address'), 'address', 'auto', 'start'),
				sorter: (a, b) => a.address.localeCompare(b.address),
			},
		];

		return columns;
	};

	const leftColumns = getColumns();
	const rightColumns = getColumns();

	const filterOption = (inputValue: string, option: TransferItem) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

	const handleOnChangeTransfer = (nextTargetKeys: string[], direction: any, moveKeys: string[]) => {
		const isLeftGrid: boolean = direction === 'left';
		const newSelectedMembers = nextTargetKeys.map<GlobalIOGroupSNIB3>(x => ({ portId: Number(x) } as GlobalIOGroupSNIB3));

		batch(() => {
			setTargetKeys(nextTargetKeys);
			isLeftGrid ? setRightSelectedKeys([]) : setLeftSelectedKeys([]);
		});

		getGlobalIOMembersPagination(newSelectedMembers, isSearchPerformed);
	};

	const clearSelectionsTransfer = (isLeftGrid: boolean, onItemSelectAll: (dataSource: string[], checkAll: boolean) => void) => {
		isLeftGrid ? setLeftSelectedKeys([]) : setRightSelectedKeys([]);
		const allMembers = [...unselectedMembers, ...selectedMembers].map<string>(member => member.portId.toString());
		onItemSelectAll(allMembers, false);
	};

	const handleOnChangeTable = (
		pagination,
		filters,
		sorter,
		extra,
		direction: TransferDirection,
		onItemSelectAll: (dataSource: string[], checkAll: boolean) => void
	) => {
		const isLeftGrid: boolean = direction === 'left';
		const { order, field } = sorter;
		let sortOrder = SortDirections.None;
		const sortField = field ?? '';

		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		const currentPagination = isLeftGrid ? leftPagination : rightPagination;

		const { PageNumber, PageSize, SortDirection, SortField } = currentPagination;
		const { current, pageSize } = pagination;

		if (sortOrder !== SortDirection || pageSize !== PageSize) {
			clearSelectionsTransfer(isLeftGrid, onItemSelectAll);
		}

		const shouldUpdateTableResults = current !== PageNumber || pageSize !== PageSize || sortOrder !== SortDirection || sortField !== SortField;

		if (shouldUpdateTableResults) {
			const paginationSetting = {
				...currentPagination,
				PageNumber: current,
				PageSize: pageSize,
				SortDirection: sortOrder,
				SortField: sortField,
			};
			isLeftGrid ? getUnselectedMembersPagination(paginationSetting) : getSelectedMembersPagination(paginationSetting);
		}
	};

	const leftTableTransferId: string = `${isCredentialManagement ? 'credentialManagement' : 'masterControlZone'}ModalLeftTableTransfer`;
	const rightTableTransferId: string = `${isCredentialManagement ? 'credentialManagement' : 'masterControlZone'}ModalRightTableTransfer`;

	return (
		<GlobalIOTransfer
			leftTableId={leftTableTransferId}
			rightTableId={rightTableTransferId}
			dataSource={dataTableTransfer}
			titles={titles}
			className={styles.tableTransfer}
			showSearch={true}
			targetKeys={targetKeys}
			globalIOServerId={globalIOServerId}
			isLeftTransferLoading={isLeftTransferLoading}
			isRightTransferLoading={isRightTransferLoading}
			leftColumns={leftColumns}
			leftGridSelectedKeys={leftSelectedKeys}
			leftRowSelection={leftRowSelection}
			leftPaginationTable={leftPaginationConfig}
			rightColumns={rightColumns}
			rightGridSelectedKeys={rightSelectedKeys}
			rightPaginationTable={rightPaginationConfig}
			rightRowSelection={rightRowSelection}
			setLeftGridSelectedKeys={setLeftSelectedKeys}
			setRightGridSelectedKeys={setRightSelectedKeys}
			onChange={handleOnChangeTransfer}
			onSearch={handleOnSearchTransfer}
			onChangeTable={handleOnChangeTable}
			filterOption={filterOption}
			onSelectAll={handleOnSelectAll}
			onSelectInvert={handleOnSelectInvert}
			onSelectNone={handleOnSelectNone}
		/>
	);
};

export { MembersTableTransfer };

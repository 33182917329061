import { SelectOption as SelectOptionCommon } from '../components/common/Select/Select';
import {
	BaseColumns,
	DefaultColumns,
	DefaultGridElement,
	ItemsPagination,
	ObjectTypeTransfer,
	ResponseObject,
	SelectOptions,
	Tuple,
} from '../model/CommonModel';
import { ErrorTabs } from '../store/deviceControl/controller/actionTypes';
import { ControllerState } from '../store/deviceControl/controller/reducer';
import { SecuredComponents } from './AccountModel';

export enum DeviceObjectType {
	Unknown = 0,
	Door = 1,
	ExpansionInputs = 2,
	ExpansionRelays = 3,
	Reader = 4,
	Controller = 5,
	Xbox = 6,
	Port = 7,
	Input = 8,
	Relay = 9,
	CommandSets = 10,
	DoorGroup = 11,
	ReaderControlGroup = 12,
	TimeZones = 13,
	CredentialTemplates = 14,
	Holidays = 15,
	Operators = 16,
	GlobalIOGroup = 17,
	EnrollmentCredential = 18,
	Roles = 19,
	Graphics = 20,
	Maps = 21,
	Badge = 22,
}

export enum ObjectTypes {
	physicalZone = -5,
	text = -4,
	customLink = -3,
	link = -2,
	none = -1,
	graphics = 1,
	maps = 60,
	cameras = 2,
	commandSets = 3,
	credentialTemplates = 4,
	doorGroups = 5,
	doors = 6,
	functionGroups = 7,
	inputs = 8,
	objects = 9,
	personGroups = 11,
	relays = 12,
	reports = 13,
	statusViewerGroups = 15,
	videoExplorerGroups = 16,
	eventViewerFilters = 17,
	pollingPorts = 18,
	hardwareControllers = 19,
	xboxes = 20,
	readers = 22,
	servers = 23,
	expansionInputs = 24,
	expansionRelays = 25,
	cctvCameras = 27,
	personTemplates = 29,
	masterDoorGroups = 31,
	workstations = 32,
	dtiiFunctionGroups = 33,
	dtiiPhysicalZones = 34,
	dtiiCommandSets = 35,
	dtii_PCZ = 36,
	dtii_FCZ = 37,
	edgeControllers = 41,
	edgeSchedules = 42,
	edgeDoorGroups = 43,
	edgeHolidays = 44,
	edgeHolidayGroups = 45,
	edgeReader = 46,
	ficamCardValidationProfiles = 48,
	ids_Panel = 51,
	ids_Area = 52,
	ids_Point = 53,
	ids_Output = 54,
	timeZones = 55,
	masterTimeZones = 56,
	grandMasterTimeZones = 57,
	readerControlGroups = 58,
	dtiiHardware = 204,
	dtiiInput = 205,
	dtiiRelay = 206,
	dtiiDoor = 208,
}

export enum DoorGroupType {
	Standard = 0,
	Master = 1,
}

export enum DeviceType {
	Velocity = 1,
	DigiTrac = 2,
}

export type ControllerContact = {
	Enable: boolean;
	Action: ActionDeviceTypes;
	Index: number;
	Id: number;
	ControllerId: number;
	Name: string;
	Address: string;
	Status: string;
	error?: string;
	HasResetEncryption?: boolean;
} & Partial<ControllerContactSpecificData> &
	DefaultGridElement;

export type ControllerContactSpecificData = {
	IPAddress: string;
	Enabled: string;
	LineModule: string;
	NormalStateWhenSecure: string;
	ControllerTimeZone: string;
	ControllerType: string;
	NetworkType: string;
	Protocol: string;
	ReaderInterfaceEntryReader: string;
	ReaderInterfaceExitReader: string;
	ReaderInterface: string;
	TotalItemsPagination: number;
};

export type ControllerContactMappedData = {
	Index: number;
	Id: number;
	ControllerId: number;
	Address: string;
	Status: string;
	editable?: boolean;
	error?: string;
	HasResetEncryption?: boolean;
	DeviceObjectType?: DeviceObjectType;
} & DefaultColumns &
	Partial<ControllerContactSpecificData>;

export type OperatorModel = {
	Id: number;
	Name: string;
	OperatorStatus: boolean;
	Bypass2FA?: boolean;
} & DefaultGridElement;

export type UserRole = {
	Id: number;
	Name: string;
	Description?: string;
	GlobalTimeZoneName?: string;
} & DefaultGridElement;

export type RolePagination = {
	UserRoles: UserRole[];
} & ItemsPagination;

export type RoleResponseObject = {
	RoleId: number;
} & ResponseObject;

export type OperatorPagination = {
	Operators: OperatorModel[];
} & ItemsPagination;

export type Holidays = {
	HolidayID: number;
	Name: string;
	T1: number;
	T2: number;
	T3: number;
	T4: number;
	HolidayDates?: HolidayDate[];
	error?: string;
} & DefaultGridElement;

export type HolidayDate = {
	Year: number;
	Month: number;
	Day: number;
	T1: number;
	T2: number;
	T3: number;
	T4: number;
};

export type HolidayPagination = {
	Holidays: Holidays[];
} & ItemsPagination;

export type DoorGroupAndMasterInfo = {
	Id: number;
	UniqueId: string;
	Name: string;
	IsMasterDoor: boolean;
} & DefaultGridElement;

export type DoorGroupAndMasterPagination = {
	DoorGroupsAndMaster: DoorGroupAndMasterInfo[];
} & ItemsPagination;

export type Commands = {
	CommandSetId: number;
	Name: string;
	Type: VelocityCommandType;
	cmdStrings: CommandSetString[];
} & DefaultGridElement;

export type CommandSetPagination = {
	CommandSets: Commands[];
} & ItemsPagination;

export type CommandEdit = {
	commandSetID: number;
	commandName: string;
	commandType: VelocityCommandType;
	commands: CommandSetString[];
};

export type Command = {
	name: string;
	type: VelocityCommandType;
	commands: CommandSetString[];
};

export type CommandRow = {
	name: string;
	function: number;
	location: number;
	command: string;
	controller: number;
	controllerName: string;
} & BaseColumns;

export type CommandSetString = {
	CommandSetStringId: number;
	Type: VelocityCommandType;
	DeviceId: number;
	CommandString: string;
	DeviceName: string;
};

export type CommandSetView = {
	CommandSetId: string;
	CommandSetName: string;
	CmdStrings: CommandSetString[];
	VelocityCommandType: VelocityCommandType;
	IsUpdate: boolean;
	Functions: ControllerFunction[];
	FunctionOptions: Tuple[];
};

export type Locations = {
	Name: string;
	Items: Tuple[];
};

export type ErrorMessages = {
	EmptyName: string;
	DuplicatedName: string;
	EmptySelection: string;
};

export type ControllerFunction = {
	Id: number;
	Name: string;
};

export enum VelocityCommandType {
	DigiTracCommand = 1,
	XBoxCommand = 2,
}

export type OptionsBuilder = {
	id?: string;
	label: string;
	options: SelectOptions<OptionsDigiTrac>[];
	disabled?: boolean;
	title?: string;
};

export type OptionsDigiTrac = {
	key: string;
	action?: ActionDeviceTypes;
};

export type ActionDigitrac = {
	action: ActionDigitracContextMenu;
	command?: ActionDeviceTypes;
};

export type OptionsButtonBuilder<T> = {
	labelOrIcon: string | React.ReactElement;
	options: SelectOptions<T>[];
};

export type ButtonBuilder = {
	id?: string;
	label: string;
	onClick?: () => void;
	icon?: React.ReactNode;
	disabled?: boolean;
	title?: string;
	separator?: boolean;
};

export type ReaderControlGroupItem = {
	ReaderControlGroupId: number;
	Name: string;
} & DefaultGridElement;

export type ReaderControlGroupPagination = {
	ReaderControlGroups: ReaderControlGroupItem[];
} & ItemsPagination;

export type CredentialTemplateData = {
	CredentialTemplateId: number;
	IDF: IdentificationFormatTypes;
	Description: string;
	IsDefault: boolean;
	HasLinkedCredentials: boolean;
} & DefaultGridElement;

export type CredentialTemplatePagination = {
	CredentialTemplates: CredentialTemplateData[];
} & ItemsPagination;

export enum IdentificationFormatTypes {
	BadgeOnly = 0,
	Keypad,
	Card,
	Dual,
	CardDual,
	KeypadDual,
	KeypadCard,
	KeypadCardDual,
}

export enum ActionDeviceTypes {
	Mask = 0,
	UnMask = 1,
	MomentaryMask = 2,
	Trigger = 3,
	ForceOn = 4,
	ForceOnRelease = 5,
	ForceOff = 6,
	ForceOffRelease = 7,
	LockDown = 8,
	LockDownRelease = 9,
	LockOpen = 10,
	LockOpenRelease = 11,
	UnLockDoor = 12,
	RelockDoor = 13,
	MomentaryAccess = 14,
	ToggleLock = 15,
	SuppressOperate = 16,
	SuppressOperateRelease = 17,
	Enabled = 18,
	DownConfiguration = 19,
	DownCredential = 20,
	DownDateTime = 21,
	DownForGiveUser = 22,
	Resetencry = 23,
	Disabled = 24,
}

export type SendDeviceCommand = {
	type: DeviceObjectType;
	action: ActionDeviceTypes;
	controllerId: number;
	deviceId: number;
};

export type DoorGroupColumns = {
	Type: string;
} & DefaultColumns;

export type DevicePropsWithModal<T> = {
	data: T;
	display: boolean;
};

export type DoorDeletingWarning = {
	ObjectId: number;
	IsMasterDoorGroup: boolean;
	DoorGroupUsersAndCredentials: DoorGroupUserAndCredential[];
	DoorGroupFGsAndFGExts: DoorGroupFGsAndFGExt[];
	MasterDoorGroupsByDG: MasterDoorGroupByDG[];
};

export type DoorGroupUserAndCredential = {
	UserId: number;
	FirstName: string;
	LastName: string;
	CredentialId: number;
	CredentialDescription: string;
	ObjectId: number;
};

export type DoorGroupFGsAndFGExt = {
	FunctionGroupName: string;
	FunctionGroupExtension: string;
	Function: string;
	ObjectId: number;
};

export type MasterDoorGroupByDG = {
	MasterDoorGroupName: string;
};

export type ReaderDeletingWarning = {
	ReaderControlGroupId: number;
	WarningMessage: string;
	RCGCredentials: RCGCredentials[];
};

export type RCGCredentials = {
	FirsName: string;
	LastName: string;
	CredentialId: number;
};

export type StandardTimeZone = {
	Id: number;
	Name: string;
	StartTime: string;
	EndTime: string;
	Monday: boolean;
	Tuesday: boolean;
	Wednesday: boolean;
	Thursday: boolean;
	Friday: boolean;
	Saturday: boolean;
	Sunday: boolean;
	Holidays: boolean;
	HolidayTable1: boolean;
	HolidayTable2: boolean;
	HolidayTable3: boolean;
	HolidayTable4: boolean;
	InUse: boolean;
} & DefaultGridElement;

export type MasterTimeZones = {
	Id: number;
	Name: string;
	InUse: boolean;
	StandardTimeZones: StandardTimeZone[];
} & DefaultGridElement;

export type MasterTimeZonesEntity = {
	Id: number;
	Name: string;
	InUse: boolean;
	StandardTimeZoneIds: number[];
};

export type GrandMasterTimeZones = {
	Id: number;
	Name: string;
	InUse: boolean;
	MasterAndStandardTimeZones: string[];
	MixedTimeZonesList: MixedTimeZones[];
} & DefaultGridElement;

export type MixedTimeZones = {
	Id: number;
	Name: string;
	Type: TimeZoneType;
	InUse: boolean;
	GenericTimeZoneId: number;
};

export enum TimeZoneType {
	Standard,
	Master,
	Grand,
	New,
}

export type SubVelocityType = keyof VelocityConfigurationModel['timeZones'];

export type UseModel = { Type: string; Index: number; Name: string };
export type UseList = {
	InUseByList: UseModel[];
};

export type VelocityConfigurationModel = {
	commandsSets: Commands[];
	credentialTemplates: CredentialTemplateData[];
	doorGroups: DoorGroupAndMasterInfo[];
	readerControls: ReaderControlGroupItem[];
	holidays: Holidays[];
	operators: OperatorModel[];
	roles: UserRole[];
	timeZones: {
		standard: StandardTimeZone[];
		grand: GrandMasterTimeZones[];
		master: MasterTimeZones[];
	};
	isEditMode: boolean;
	actionScopeKey: string;
	globalIOGroups: GlobalIOGroup[];
	isFilterMode: boolean;
	selectedRowKeysPagination: SelectedRowKeyPagination[];
};

export type DIGITrac = {
	port: ControllerContact[];
	xbox: ControllerContact[];
	controllers: ControllerContact[];
	doors: ControllerContact[];
	expansionsInputs: ControllerContact[];
	expansionsRelays: ControllerContact[];
	inputs: ControllerContact[];
	relays: ControllerContact[];
	readers: ControllerContact[];
	controllerFlagListener: string;
	isEditMode: boolean;
	selectedRowKeysPagination: SelectedRowKeyPagination[];
	controllerSelectedKey: SelectedRowKeyPagination;
	isFilterMode: boolean;
};

export type VelocityDevice = {
	Id: number;
	Name: string;
	Address?: string;
};

export type XboxData = {
	OriginalXAddress: number;
	OriginalEnabled: boolean;
	MsgPerPoll: number;
	LogOff: number;
	Enabled: boolean;
	XAddress: number;
	XboxId: number;
	XboxName: string;
	PortId: number;
	Revision: string;
	UnpermittedPortName: string;
	UnpermittedPortAddress: string;
};

export type DetailedXboxAddress = {
	Protocol: string;
	SecurityDomain: string;
	Port: string;
	XAddress: string;
};

export type ControlZone = {
	ControlZoneIndex: number;
	ControlZoneName: string;
	IsMasterControlZone?: boolean;
};

export type ReaderControlGroupReaderInfo = {
	ControllerId: number;
	ReaderName: string;
	ReaderId: number;
	ReaderIndex: number;
	ControlZoneName: string;
	ControlZoneIndex: number;
	ReaderAddress: string;
};

export type ReaderControlGroupInfo = {
	ReaderControlGroupId: number;
	ReaderControlGroupName: string;
	ReaderControlGroupReaders: ReaderControlGroupReaderInfo[];
};

export type ReaderControlGroupView = {
	ControllersForReaders: VelocityDevice[];
	ControlZones: ControlZone[];
	ReaderControlGroup: ReaderControlGroupInfo;
	IsUpdate: boolean;
};

export type ControllerReader = {
	ControllerId: number;
	ControllerName: string;
	ReaderId: number;
	ReaderIndex: number;
	Address: string;
	ReaderName: string;
};

export type Port = {
	Id?: number;
	Name: string;
	NetworkType: PortNetWorkType;
	Protocol: number;
	IPAddress: string;
	IPPort: string;
	SubnetMask: string;
	DefaultGateway: string;
	MaxRetryAttempts: number;
	TotalTimeoutMultiplier: number;
	TotalTimeoutConstant: number;
	IntervalTimeout: number;
	ResetEncryption: boolean;
	EnablePort: boolean;
	DefaultConnectionType: PortConnectionType;
	PortStyle: string;
	PortType: number;
	BaudRate: number;
	PortAddress: number;
	COMPort: number;
	HasChanged: boolean;
};

export type PingInfo = {
	Address: string;
	RoundtripTime: number;
	Ttl: number;
	DontFragment: boolean;
	Length: number;
	ErrorMessage: string;
};

export type CurrentDeviceControlObj = {
	Id: number;
	Address?: string;
	ControllerContact?: number;
	DeviceObjectType: DeviceObjectType;
	IsModalOpen?: boolean;
	CurrentPage?: number;
	IsNew?: boolean;
};

export type Input = {
	Id: number;
	ControllerId: number;
	Name: string;
	Enabled: boolean;
	Rqmo: boolean;
	Rqretrig: boolean;
	LineModuleId: number;
	NormallyOpen: boolean;
	RQEEnabled: boolean;
	RQEType: RQEType;
	DoorModeTime: number;
	DOTLTime: number;
	DOTLWaitingTime: number;
	EntryDelayTime: number;
	ExitDelayTime: number;
	ArClo: boolean;
	ArOff: boolean;
	Overexmax: number;
	Overexw: number;
	ReportDOTLWhenUnlocked: boolean;
	MaskTamperWhenDoorContactMasked: boolean;
	VerifyPassback: boolean;
	MaskTZ: number;
	TZDentDly: number;
	MaskDuringTimeZoneSelected: SelectTimeZone;
	DisabledDuringTimeZoneSelected: SelectTimeZone;
	ActuateRelaysInControlZoneId: number;
	DisabledRelaysInControlZoneId: number;
	TriggerRelaysInControlZoneId: number;
	RetriggerRelaysInControlZoneId: number;
	DisabledStateChangeReportingWhenMasked: boolean;
	EnabledContactStateChangeReportingDoorContact: boolean;
	EnabledContactStateChangeReportingRQE: boolean;
	EnabledContactStateChangeReportingTamper: boolean;
	EnabledContactStateChangeReportingLineFault: boolean;
	EnabledContactStateChangeReportingLineFault2: boolean;
	EnabledContactStateChangeReportingLineFault3: boolean;
	EnabledMaskStateChangeReportingDoorRelay: boolean;
	EnabledMaskStateChangeReportingRQE: boolean;
	EnabledMaskStateChangeReportingEntyExitDelay: boolean;
	EnabledMaskStateChangeReportingUnlock: boolean;
	EnabledMaskStateChangeReportingTimeZone: boolean;
	EnabledMaskStateChangeReportingControlCode: boolean;
	MaskingStateChangeEnableFlags: number;
	GenericTimeZones: SelectTimeZone[];
	moduleTypeDisabled: boolean;
	inputRQEDisabled: boolean;
	WaitingTimeError: string;
};

export enum RQEType {
	TriggerMaskOnly = 0,
	TriggerRelayAndMask,
	RetriggerMaskOnly,
	RetriggerRelayAndMask,
}

export type GenericTimeZone = {
	GenericTimeZoneId: number;
	GlobalTimeZoneId: number;
	Name: string;
	Type: TimeZoneType;
};

export type GenericControlTimeZones = {
	CZIndex: number;
	Name: string;
};

export type ControlZonesStdMaster = {
	StdControlZones: GenericControlTimeZones[];
	MtControlZones: GenericControlTimeZones[];
};

export type ModalTimeZones = {
	entity: StandardTimeZone | GrandMasterTimeZones | MasterTimeZones | null;
	timeZoneType: TimeZoneType;
};

export type RowMasterTimeZone = StandardTimeZone & { key: number };

export type RowGrandMasterTimeZone = StandardTimeZoneAndMasterTimeZone & { key: number };

export type StandardTimeZoneAndMasterTimeZone = {
	Id: number;
	GenericTimeZoneId: number;
	Name: string;
	TimeZoneType: TimeZoneType;
	InUse: boolean;
};

export type GrandMasterTimeZone = {
	Id: number;
	Name: string;
	StandardTimeZoneAndMasterTimeZones: StandardTimeZoneAndMasterTimeZone[];
};

export type ReaderOptionsTab = {
	TableFieldName: string;
	OptionLabel: string;
	Value: boolean;
};

export type SelectTimeZone = {
	GenericId: number;
	Name: string;
	Unpermitted: boolean;
	TimeZoneType: TimeZoneType;
	GlobalId: number;
};

export type SelectCommandSet = {
	CommandId: number;
	CommandName: string;
	Unpermitted: boolean;
};

export type RelayLogicTimeZones = {
	Actuate: number;
	Operate: number;
	Disable: number;
	AutoClear: number;
};

export type InputLogicTimeZones = {
	MaskDuring: SelectTimeZone;
	DisableEntry: SelectTimeZone;
};

export type ReaderLabel = {
	Label: string;
	Value: string;
};

export type SelectOption = {
	Id: number;
	Name: string;
};

export type ExpansionRelayInfo = {
	Name: string;
	Id?: number;
	DoorDelayTime: number;
	ControlMode: number;
	ControlDelay: number;
	NormalState: boolean;
	OperateTimeZone: SelectTimeZone;
	ActuateTimeZone: SelectTimeZone;
	DisableTimeZone: SelectTimeZone;
	AutoClearTimeZone: SelectTimeZone;
	TriggerControlZone: number;
	ReTriggerControlZone: number;
	GenericTimeZones: SelectTimeZone[];
	ControlZones: GenericControlTimeZones[];
	StandardControlZones: GenericControlTimeZones[];
	ControllerId: number;
	TwoMan?: boolean;
};

export type Reader = {
	Id?: number;
	ControllerId: number;
	Index: number;
	SupportedReaderClass: number;
	Labels: ReaderLabel[];
	AnyReaderOptions: ReaderOptionsTab[];
	CardReaderOptions: ReaderOptionsTab[];
	ScramblePadKeyPadOptions: ReaderOptionsTab[];
	GenericTimeZones: SelectTimeZone[];
	CardMaps: SelectOption[];
	CardMap: number;
	SpecialHandlingInstructions: SelectOption[];
	Name: string;
	RS485Interface: boolean;
	IsReaderWireless: boolean;
	ReaderThreatLevels: SelectOption[];
	DisabledReaderAboveLevelId: number;
	DisabledCCOTZAboveLevelId: number;
	RS485ReaderTypes: SelectOption[];
	OSDPAddress: number;
	WLHubAddress: number;
	EACAddress: number;
	WL2HubAddress: number;
	AllegionLockAddress: number;
	OkDegrade: boolean;
	PassbackZones: SelectOption[];
	AntiPassback: number;
	CCOTZAssurance: boolean;
	AssuranceLevel: number;
	DefaultAssuranceLevel: number;
	IsOnboardWiegandAvailable: boolean;
	IsControllerSNIB3: boolean;
	IsControllerMATCH: boolean;
	Firmware: string;
	AppVersion: string;
	DisplayBoardVersion: string;
	ToZone: number;
	FromZone: number;
	EnableReaderBezelTamper: boolean;
	EnableHexPassThrough: boolean;
	Enable200Bit: boolean;
	Fascn75b: boolean;
	Fascn16d: boolean;
	SpecialHandlingInstruction: number;
	EnableMatchBezelTamper: boolean;
	FacilityCodeCardNumber: boolean;
	Corporate1000: boolean;
	HexDigitUUID: boolean;
	EnableKeyPad: boolean;
	CardCodeOnlyTimeZone: SelectTimeZone;
	EnableOSDPHandshake: boolean;
	IsDoorWireless: boolean;
	OSDPTitle: string;
	OSDPConfirmation: string;
	FirmwareConfirm: string;
	FirmwareWarning: string;
	FirmwareTitle: string;
	TSFirmwares: SelectOption[];
	CanUpdateFirmware: boolean;
	ApplyToAllReaders: boolean;
	RS485ReaderType: number;
	ReaderInterface: ReaderInterface;
	IsFICAMEnabled: boolean;
	MatchAlgorithm: number;
	FascnHandling: number;
	UpdateFirmwarePermissionText: string;
	CanNotSeeTabsMessage?: string;
	OSDPCustomLEDColors: SelectOption[];
	ChangedOSDPLEDColor: boolean;
	UseCustomOSDPLEDColor: boolean;
	OSDPLEDOptions: OSDPLEDOption[];
	ScrambleFactorCategories: ScrambleFactorImageCategories[];
	TSScrambleImages: ScrambleFactorImageShort[];
	ViewPINEntryIndicator: boolean;
	ScrambleFactorImages: ScrambleFactorImageCategories[];
};

export type OSDPLEDOption = {
	Index: number;
	Value: OSDPLEDColors;
	Name: string;
};

export type DownloadTSFirmware = {
	ControllerId: number;
	ReaderIndex: number;
	ReaderId: number;
	ReaderName: string;
	FirmwareId: number;
	ApplyToAllReaders: boolean;
};

export enum OSDPLEDColors {
	Black = 0,
	Red = 1,
	Green = 2,
	Amber = 3,
	Blue = 4,
	Magenta = 5,
	Cyan = 6,
	White = 7,
}

export enum ReaderInterface {
	MatchDS47 = 0,
	OnboardWiegand = 1,
	RS485 = 2,
	Disabled = 3,
}

export enum ReaderInterfaceString {
	MatchDS47 = 'MATCH/DS47',
	OnboardWiegand = 'Onboard Wiegand',
	RS485 = 'RS485',
	Disabled = 'Disabled',
	Wireless = 'Wireless',
}

export enum MatchAlgorithm {
	Disable = 0,
	StandardWiegandP = 1,
	StandardWiegandNP = 2,
	OctalPassThroughP = 3,
	OctalPassThroughNP = 4,
	OctalPassThroughDP = 5,
	DecimalSerialNumber = 6,
	HexPassThrough = 7,
	BinaryTestMode = 8,
}

export enum FascnHandling {
	Disable = 0,
	Piv64In16 = 1,
	Piv75In14 = 2,
	Piv75In16 = 3,
	Piv75In22 = 4,
	Piv200In32 = 5,
	Piv128In32 = 6,
}

export enum ReaderError {
	Name = 0,
	Assurance = 1,
	DuplicatedWAddress = 2,
	EACAddress = 3,
	OSDPAddress = 4,
	DuplicatedScrambleImageName = 5,
}

export enum RS485ReaderType {
	None = 0,
	TS = 1,
	TSScramblePad = 2,
	TSKeypad = 3,
	VeridtPIVAUTH = 4,
	VeridtPIVAUTHBIO = 5,
	TSPIVAUTH = 6,
	Aperio = 7,
	Alleggion = 8,
	TS_SF3 = 9,
	TS_SF2 = 10,
	TS_SF1 = 11,
}

export type AssuranceLevelList = {
	DefaultAssurance: number;
	AssuranceLevels: SelectOption[];
	DefaultAssuranceLevels: SelectOption[];
	CanUpdateFirmware: boolean;
};

export type CheckComponentLock = {
	ObjectId: number;
	LockComponent: boolean;
	SecuredComponent: SecuredComponents;
};

export type WarningMsgResetEncryption = {
	IsWarning: boolean;
	Title: string;
	Message: string;
};

export type ControllerDoor = {
	ControllerId: number;
	ControllerName: string;
	ReaderId: number;
	ReaderIndex: number;
	BelongToDoor: number;
	Address: string;
	ReaderName: string;
	DoorName: string;
	isInUse: boolean;
	DoorId?: number;
};

export type DoorGroupInfo = {
	DoorGroupId: number;
	DoorGroupName: string;
	Tag: boolean;
	Alert: boolean;
	DisableCredential: boolean;
	Guid: string;
	InUse: boolean;
	DoorGroupDoors: DoorGroupDoorInfo[];
};

export type DoorGroupDoorInfo = {
	ControllerId: number;
	ReaderId: number;
	ReaderIndex: number;
	ReaderName: string;
	GenericTimeZoneId: number;
	TimeZoneType: TimeZoneType;
	GlobalTimeZoneId: number;
	TimeZoneName: string;
	DoorName: string;
	DoorAddress: string;
	InUse: boolean;
};

export type DoorGroupViewModel = {
	ControllersForDoors: VelocityDevice[];
	GenericTimeZones: GenericTimeZone[];
	DoorGroup: DoorGroupInfo;
	IsUpdate: boolean;
};

export type ControlZonesControllers = {
	CZIndex: number;
	Name: string;
};

export type MasterDoorGroupViewModel = {
	MasterDoorGroup: MasterDoorGroup;
	AvailableDoorGroups: DoorGroupInfo[];
	IsUpdate: boolean;
};

export type MasterDoorGroup = {
	MasterDoorGroupId: number;
	Name: string;
	Tag: boolean;
	Alert: boolean;
	DisableCredential: boolean;
	DoorGroups: DoorGroupInfo[];
};

export type ConflictDoorGroupViewModel = {
	DoorGroupConflict: DoorGroupConflict;
};

export type DoorGroupConflict = {
	HasDoorGroupName: boolean;
	ControllersDoorGroups: Tuple[];
	DoorGroupCredentialControllers: DoorGroupCredentialController[];
	DoorGroupFunctionGroupControllers: DoorGroupFunctionGroupController[];
	ErrorMessage: string;
};

export type DoorGroupCredentialController = {
	ControllerName: string;
	CredentialId?: number;
	DoorGroupName: string;
	FunctionName: string;
	FunctionCategoryName: string;
};

export type DoorGroupFunctionGroupController = {
	ControllerName: string;
	FunctionGroupName: string;
	FunctionGroupExtension: string;
	DoorGroupName: string;
	FunctionName: string;
	FunctionCategoryName: string;
};

export enum DeviceAdminWarningType {
	None = 0,
	DeleteDoorGroup,
	DeleteMasterDoorGroup,
	ChangeControllerType,
	DoorGroupLockStatus,
	MasterDoorGroupLockStatus,
	ConflictChecking,
	ConflictMDChecking,
	ConflictCheckingDueToDGChange,
	FunctionGroupIndexAvailabilityChecking,
	DT2DeleteReader,
	ControllersRunOutOfMAZ,
	ControllersRunOutOfMAZDueToDGChange,
	MAZexceed8SAZLimit,
	MAZexceed8SAZLimitDueToDGChange,
	ControllersRunOutOfFGIndexDueToDGChange,
}

export type DeviceAdminWarningTypeString = keyof typeof DeviceAdminWarningType;

export type LockStatusDoorGroupViewModel = {
	ObjectId: number;
	IsMasterDoorGroup: boolean;
	LockStatusWarning: LockStatusWarning;
};

export type LockStatusWarning = {
	DoorGroupCredentialsLocked: CredentialsLocked[];
	DoorGroupFGsLocked: FunctionGroupLocked[];
} & ResponseObject;

export type CredentialsLocked = {
	OperatorName: string;
	WorkstationName: string;
	CredentialID: number;
	IsTemplate: boolean;
};

export type FunctionGroupLocked = {
	OperatorName: string;
	WorkstationName: string;
	FunctionGroupId: number;
};

export type Holiday = {
	HolidayID: number;
	Name: string;
	HolidayDates: HolidayDate[];
	T1: number;
	T2: number;
	T3: number;
	T4: number;
};

export type DataTableType<T> = {
	[P in keyof T]: T[P];
} & { key: React.Key };

export enum SupportedReaderClass {
	Default = 0,
	WL_Aperio,
	WL_Allegion,
}

export type DoorRelay = {
	RelayId: number;
	Name: string;
	TwoMan: boolean;
	AntiPB: boolean;
	Escort: boolean;
	Invert: boolean;
};
export type Door = {
	Name: string;
	SupportedReaderClass: SupportedReaderClass;
	DoorId: number;
	DoorIndex: number;
	ControllerId: number;
	ControllerType: number;
	EntryReadersOnly: boolean;
	IsControllerSNIB3: boolean;
	AutoRelockOnClose: boolean;
	AutoRelockDisable: boolean;
	EnableTwoPersonRule: boolean;
	ReportDoorOpenTooLongWhenUnlocked: boolean;
	ExtendedAccessOverrideTime: number;
	ExtendedAccessWarningTime: number;
	PhotoCallUpTemplateId: number;
	PhotoCallUpDelay: number;
	EntryReaderId: number;
	ExitReaderId: number;
	RelayId: number;
	InputId: number;
	IsDoorWireless: boolean;
	Input: Partial<Input>;
	DoorRelay: DoorRelay;
	Relay: ExpansionRelayInfo;
	EntryReader: Partial<Reader>;
	ExitReader: Partial<Reader>;
	IsInputTabVisible: boolean;
	Technology: number;
	WaitingTimeError: string;
};

export type LockAffectedDoorGroups = {
	AffectedDoorGroups: string[];
};

export enum ReaderType {
	none = 0,
	entryReader,
	exitReader,
}

export enum LockScope {
	None = 0,
	PerDoorGroup,
	PerDoor,
	PerExitDoor,
	PerControllerTypeChange,
	PerMasterDoorGroup,
}

export type PhysicalPort = {
	PortName: string;
	COMPort: number;
	PortNameFormatted: string;
};

export type SerialInfo = {
	BaudRates: number[];
	COMPorts: PhysicalPort[];
};

export enum PortConnectionType {
	None = -1,
	SNET = 0,
	XNET = 1,
}

export enum PortNetWorkType {
	None = 0,
	Serial,
	IPv4,
	IPv6,
	Dialup,
}

export enum PortProtocolType {
	None = 0,
	SNET,
	XNET,
	XNET2,
	XNET3,
}

export type PortProtocolNetworkType = {
	NetworkType: string;
	ProtocolType: string;
};

export type BroadCastSummary = {
	SearchCompleted: boolean;
	Devices: BroadCastDevice[];
};

export type BroadCastDevice = {
	Id: string;
	IPv6MulticastAddress: string;
	IPAddress: string;
	Port: string;
	SubnetMask: string;
	DefaultGateway: string;
	Hostname: string;
	IPv6Gateway: string;
	DHCPEnabled: string;
	IPv6Address: string;
	IPv6Prefix: string;
	Type: string;
	Version: string;
};

export type SystemTimeZones = {
	DisplayName: string;
	Id: string;
	StandardName: string;
};

export type ControllerMiscSystemSetup = {
	AlarmRelayGeneralTimer: number;
	AlarmRelayDuressTimer: number;
	AlarmRelayTamperTimer: number;
	AlarmRelayTroubleTimer: number;
	AlarmRelayGeneralMapToRelay: number;
	AlarmRelayDuressMapToRelay: number;
	AlarmRelayTamperMapToRelay: number;
	AlarmRelayTroubleMapToRelay: number;
	SpecialNeedsDelayTimer: number;
	SpecialNeedsModeTimer: number;
	SpecialNeedsDOTLTimer: number;
	LocalPrinterLanguage: number;
	LocalKeypadProgrammingTimeout: number;
	LocalKeypadProgrammingAutoGenCode: number;
	LocalKeypadProgrammingDuressDigit: number;
	LocalKeypadProgrammingEnableCodeReset: boolean;
	LocalKeypadProgrammingAutoGenCodesWithDuress: boolean;
	LocalKeypadProgrammingPrinterShowsNewCredentialCodes: boolean;
	LocalKeypadProgrammingReduceAutoGenPrintOut: boolean;
	LocalKeypadProgrammingAutoGenCodesWithDuressOptions: SelectOption[];
	LocalKeypadProgrammingDuressDigitOptions: SelectOption[];
	CodeTamperInvalidPenaltyTimer: number;
	CodeTamperThresholdTimer: number;
	CodeTamperLockoutTimer: number;
	OptionsEmptyLocalAlarm: boolean;
	OptionsEnableDuressAlarm: boolean;
	OptionsEnableGlobalCredentialManagement: boolean;
	OptionsEnableGlobalMCZ: boolean;
	OptionsValidateOnDualCCOTZ: boolean;
	OptionsValidateImmediatelyCCOTZ: boolean;
	AdvancedBypassErrors: boolean;
	AdvancedBypassCodeConflict: boolean;
	AdvancedEnableSEK: boolean;
	AlertTimeZoneGenericId: number;
	TagTimeZoneGenericId: number;
	SCIBBaud: number;
	SCIBParity: number;
	SCIBDataBits: number;
	SCIBStopBits: number;
	SCIBBaudList: SelectOption[];
	SCIBDataBitsList: SelectOption[];
	SCIBStopList: SelectOption[];
	WirelessVendor: number;
	SCIBParityList: SelectOption[];
};

enum MessageFiltersType {
	HostMessages = 1,
	LinePrinterMessages = 2,
	TerminalMessages = 3,
	KeypadMessages = 4,
}

export type AdvancedReportingMessageFilterOptions = {
	MessageFiltersType: MessageFiltersType;
	Events: boolean;
	Transactions: boolean;
	MidnightReport: boolean;
	CmdResponses: boolean;
	CmdCompletes: boolean;
	QueryResponses: boolean;
	TitleMessages: boolean;
	MiscMessages: boolean;
};

export type ControllerAdvancedReportingSetup = {
	EnableCommandEcho: boolean;
	MessageFilterEvents: boolean;
	EnablePrintingSNET: boolean;
	EnableMCZReporting: boolean;
	EnableACBReporting: boolean;
	MessageFiltersTypes: SelectOption[];
	MessageFilters: AdvancedReportingMessageFilterOptions[];
};

export type ControllerReportingSetup = {
	LocalPrinterLinesPerPage: number;
	RemoteSiteBufferThreshold: number;
	CommunicationsSNETTimeout: number;
	DisableReportingOnLocalPrinterTimeZone: number;
	DisableReportingTransactionsTimeZone: number;
	DisableReportingEventsTimeZone: number;
	DisableReportingGrantedTransactionsTimeZone: number;
	DisableReportingDailyMidnight: boolean;
	DisableReportingInternalEvents: boolean;
	DisableReportingExternalEvents: boolean;
	DisableReportingTransactionEvents: boolean;
	DisableReportingRelayStateChanges: boolean;
	DisableReportingTimeZoneStateChanges: boolean;
	DisableReportingInvalidCodes: boolean;
	DisableReportingTimeZoneDrivenRelay: boolean;
	DisableReportingTimeZoneDrivenInput: boolean;
	LanguageOptions: SelectOption[];
	AdvancedReporting: ControllerAdvancedReportingSetup;
};

export type ControllerBaseData = {
	ControllerTypeList: SelectOption[];
	WirelessReaderTypeList: SelectOption[];
	AvailableAddresses: SelectOption[];
	TimeZoneStandardName: string;
	TimeZoneDisplayName: string;
	SystemTimeZones: SystemTimeZones[];
	Ports: ControllerPortData[];
};

export type OccupancySetupByZone = {
	PassbackZoneId: number;
	ZoneIndex: number;
	MinimumPeopleInside: number;
	MaximumPeopleInside: number;
	TwoManDisableThreshold: number;
	Occupancy0To1: number;
	Occupancy1To2: number;
	Occupancy2To1: number;
	Occupancy1To0: number;
	OccupancyMinimum: number;
	OccupancyMinimumLess1: number;
	OccupancyMinimumPlus1: number;
	OccupancyMaximum: number;
	OccupancyMaximumLess1: number;
	error: boolean;
};

export type ControllerPassbackSetup = {
	OnPassbackViolationReport: boolean;
	OnPassbackViolationGenerateAlarm: boolean;
	OnPassbackViolationDenyAccess: boolean;
	ReportAllOccupancyViolations: boolean;
	EnableSingleZone: boolean;
	OptionsDisableTimeZonePassback: number;
	TwoPersonTimeIncrement: number;
	TwoPersonThresholdTime: number;
	TwoPersonDisableTimeZonePassback: number;
	OccupancyZones: SelectOption[];
	OccupancySetupByZone: OccupancySetupByZone[];
};

export enum ControllerType {
	Unknown = -1,
	M2 = 3,
	M8 = 4,
	M16 = 5,
	MSP = 6,
	M1 = 7,
	Mx1 = 8,
	Mx2 = 9,
	Mx4 = 11,
	Mx8 = 15,
	Mx1w = 16,
}
export type ControllerBasicSetup = {
	ControllerType: ControllerType;
	AlarmRelays: number;
	Inputs: number;
	Relays: number;
	XInputs: number;
	XRelays: number;
	VRelays: number;
	Newdefheskey1: number;
	Newdefheskey2: number;
	Newdefheskey3: number;
	Newdefheskey4: number;
	Newhstpswd1: number;
	Newhstpswd2: number;
	Newhstpswd3: number;
	Newhstpswd4: number;
	MasterCode: string;
	IsDisableMasterCode: boolean;
	EnableUpdateSNIB: boolean;
	IsSEKInstalled: boolean;
	SupportsSNIB3: boolean;
	InstalledEquipment: string[];
	WriteProtect: boolean;
};

export type ControllerACBSetup = {
	ACBIndex: number;
	GeneralTimeZone: number;
	DuressTimeZone: number;
	TamperTimeZone: number;
	TroubleTimeZone: number;
	DialoutTimeZone: number;
	LogTimeZone: number;
	TriggerControlZone: number;
	ControlZoneOff: number;
	ACBName: string;
};

export type BufferAllocationInfo = {
	Allocation: boolean;
	IsAllocationEnabled: boolean;
	EventBuf: number;
	AlarmBuf: number;
};

type FirmwareRevision = {
	Label: string;
	Value: string;
};

type ControllerBasic = {
	ControllerId: number;
	Name: string;
	XboxId: number;
	Description: string;
	AddressId: number;
	Address: string;
	IsPanelInstalled: number;
	CanUpdateFirmware: boolean;
	SupportsSNIB3DB: boolean;
	IsUseDST: boolean;
	BufferAllocationInfo: BufferAllocationInfo;
	DownloadAllCredentials: boolean;
	EnabledUserDBLocation: number;
	ActiveUserDBLocation: number;
	CommandSetId: number;
	SupportedReaderClass: SupportedReaderClass;
	ControllerTimeZoneMap: number[];
	GenericTimeZones: SelectTimeZone[];
	ACBTimeZones: SelectTimeZone[];
	ControlZones: GenericControlTimeZones[];
	ControllerCommandSets: SelectCommandSet[];
	ControllerMiscSystemSetup: ControllerMiscSystemSetup;
	ControllerReportingSetup: ControllerReportingSetup;
	ControllerPassbackSetup: ControllerPassbackSetup;
	ControllerBasicSetup: ControllerBasicSetup;
	PortId: number;
	UserDBLocationList: SelectOption[];
	UserDBLocation: number;
	FirmwareRevision: FirmwareRevision[];
};

export type ControllerData = {
	ControllerACBSetup: ControllerACBSetup[];
} & ControllerBasic;

export type ControllerDataState = {
	ControllerACBSetup: { [index: string]: ControllerACBSetup };

	ControllerACBSetupById: number[];
} & ControllerBasic;

export type Controller = ControllerBaseData & ControllerData;
export type ControllerStateDenom = ControllerBaseData & ControllerDataState;

export type MasterControlZonesEditor = {
	Id: number;
	Dirty: boolean;
	Index: number;
	Name: string;
	Description: string;
	Tag: boolean;
	Alert: boolean;
	GenericTimeZoneId: number;
	SecurityLevel: number;
	BroadcastGlobally: boolean;
	GlobalId: number;
	DisableCredential: boolean;
	ActionControlZones: number[];
};

export type StandardControlZonesEditor = {
	Id: number;
	Dirty: boolean;
	Index: number;
	Name: string;
	DisableCredential: boolean;
	Tag: number;
	Alert: number;
	GenericTimeZoneId: number;
	ComponentsArray: ControlZonesComponentsCheck[];
};

export type ControlZonesComponentsCheck = {
	IsExpansionComponent: boolean;
	Value: boolean;
};

export type ControlZonesEditor = {
	ControllerId: number;
	ControllerTimeZoneMap: number[];
	MasterControlZonesEditor: MasterControlZonesEditor[];
	StandardControlZonesEditor: StandardControlZonesEditor[];
	StandardControlZonesHeader: string[];
	MasterControlZonesHeader: string[];
	MasterControlZonesActions: string[];
	MasterControlZonesIndexes: SelectOption[];
	GlobalIdList: SelectOption[];
	ThreatLevels: SelectOption[];
	GenericTimeZones: SelectTimeZone[];
	StandardControlZonesList: ControlZone[];
};

export type ControlZonesEditorState = {
	ControllerId: number;
	ControllerTimeZoneMap: number[];
	MasterControlZonesEditor: { [index: string]: MasterControlZonesEditor };
	MasterControlZonesEditorDataById: number[];
	StandardControlZonesEditor: { [index: string]: StandardControlZonesEditor };
	StandardControlZonesEditorDataById: number[];
	StandardControlZonesHeader: string[];
	MasterControlZonesHeader: string[];
	MasterControlZonesActions: string[];
	MasterControlZonesIndexes: SelectOption[];
	GlobalIdList: SelectOption[];
	ThreatLevels: SelectOption[];
	GenericTimeZones: SelectTimeZone[];
	StandardControlZonesList: ControlZone[];
};

export type UpdateControlZone = {
	ControllerId: number;
	ControllerTimeZoneMap: number[];
	MasterControlZone: MasterControlZonesEditor;
	StandardControlZone: StandardControlZonesEditor;
	GenericTimeZones: SelectTimeZone[];
};

export type DoorGroup = {
	DoorGroupId: number;
	Name: string;
	IsMasterDoorGroup: boolean;
	ControllerIds: number[];
};

export type ControllerPortData = {
	Id: number;
	PortConnectionType: PortConnectionType;
	Name: string;
	Address: string;
	IsPortDialup: boolean;
	NameWithAddress: string;
};

export type ControllerXBoxData = {
	Id: number;
	Name: string;
	Address: string;
	NameWithAddress: string;
};

export type NewControllerData = {
	PortId: number;
	XboxId: number;
	Name: string;
	ControllerType: ControllerType;
	Address: number;
	WirelessVendor: number;
	LoadSetupsFromController: boolean;
	Description: string;
	Enabled: boolean;
	TimeZoneDisplayName: string;
	TimeZoneStandardName: string;
	UseDaylightSavingsTime: boolean;
};

export enum ControllerError {
	Name = 0,
	MasterCode = 1,
	WirelessController = 2,
	ControllerType = 3,
	TwoPersonThresholdTime = 4,
	TwoPersonRuleRange = 5,
	TwoPersonEvenPeople = 6,
}

export type TimeZoneInfo = {
	IsUnpermitted: boolean;
	TimeZoneList: SelectOptionCommon;
};

export type MessagesForFirmwareConfiguration = {
	Labels: string[];
	Caption: string;
};

export type LoadMessagesForFirmwareConfiguration = {
	CCM: MessagesForFirmwareConfiguration;
	SNIB: MessagesForFirmwareConfiguration;
	WriteProtect: MessagesForFirmwareConfiguration;
};

export type CCMFirmwareConfiguration = {
	Labels: string[];
	Caption: string;
	CCMFirmwareOptions: SNIBFirmwareOption[];
};

export type SNIBFirmwareConfiguration = {
	Labels: string[];
	Caption: string;
	SNIBFirmwareOptions: SNIBFirmwareOption[];
};

export type SNIBFirmwareOption = {
	FirmwareID: number;
	RevisionNum: string;
	RevisionDate: Date;
	Version: string;
	Description: string;
};

export type GlobalIOGroup = {
	id: number;
	name: string;
	topic: GlobalIOGroupTopic;
} & DefaultGridElement;

export type GlobalIOGroupColumns = {
	Topic: string;
} & DefaultColumns;

export type GlobalIOGroupSNIBColumns = {
	portName: string;
	address: string;
} & BaseColumns;

export type GlobalIOGroupSNIBTransferColumns = {
	portName: string;
	address: string;
};

export enum GlobalIOGroupTopic {
	None = 0,
	CredentialManagement = 1,
	MasterControlZone = 2,
}

export type GlobalIOGroupSNIB3 = {
	portId: number;
	portName: string;
	address: string;
	controllerId: number;
	type: GlobalIOGroupTopic;
};

export type GlobalIOGroupMembers = {
	serverId: number;
	globalIOGroupServers: VelocityDevice[];
	unselectedMembers: GlobalIOGroupSNIB3[];
	selectedMembers: GlobalIOGroupSNIB3[];
};

export type GlobalIOGroupServerInfo = {
	globalIOServerId: number;
	globalIOGroupServers: VelocityDevice[];
};

export type GlobalIOGroupSNIB3Pagination = {
	globalIOSNIB3Groups: GlobalIOGroupSNIB3[];
} & ItemsPagination;

export type GlobalIOGroupMembersPagination = {
	unselectedMembers: GlobalIOGroupSNIB3Pagination;
	selectedMembers: GlobalIOGroupSNIB3Pagination;
};

export type GlobalIOGroupInfo = {
	serverId: number;
	unselectedMembers: GlobalIOGroupSNIB3[];
	selectedMembers: GlobalIOGroupSNIB3[];
} & GlobalIOGroup;

export type SNIB3EncryptionResetWarning = {
	EncryptionSwitch: number;
	Labels: string[];
};

export type SelectedRowKeyPagination = {
	Id: React.Key;
	PaginationPage: number;
};

export type TimeZonePagination = {
	TotalTimeZonesPaginated: number;
};

export type StandardTimeZonePagination = {
	StandardTimeZones: StandardTimeZone[];
} & TimeZonePagination;

export type MasterTimeZonePagination = {
	MasterTimeZones: MasterTimeZones[];
} & TimeZonePagination;

export type GrandMasterTimeZonePagination = {
	GrandMasterTimeZones: GrandMasterTimeZones[];
} & TimeZonePagination;

export type GlobalIOGroupPagination = {
	GlobalIOGroups: GlobalIOGroup[];
} & ItemsPagination;

export type DeviceSelectedPage = {
	DeviceId: number;
	DeviceObjectType: DeviceObjectType;
	IsMasterDoorGroup: boolean;
};

export type SelectedDoorGroup = {
	DeviceId: number;
	UniqueId: string;
	IsMasterDoorGroup: boolean;
	Name: string;
};

export type GlobalIOGroupSNIBTransfer = ObjectTypeTransfer<GlobalIOGroupSNIBColumns>;

export type ControllerInfoStore = {
	controllerData: ControllerState;
	isControlZoneEditing: boolean;
	hasChangedName: boolean;
	hasChangedController: boolean;
};

export type CommunicationsInfoStore = {
	timeOut: number;
	networkPasswords: {
		pass1: number;
		pass2: number;
		pass3: number;
		pass4: number;
	};
	encryption: {
		encrypt1: number;
		encrypt2: number;
		encrypt3: number;
		encrypt4: number;
	};
};

export type TimeZonesStateProps = {
	standard: StandardTimeZone[];
	grand: GrandMasterTimeZones[];
	master: MasterTimeZones[];
	isEditMode: boolean;
	actionScopeKey: string;
};

export type BasicControllerInfoStore = {
	name: string;
	description: string;
	timeZoneDisplayName: string;
	systemTimeZones: SystemTimeZones[];
	isUseDST: boolean;
	controllerAddressId: number;
	availableAddresses: SelectOption[];
	controllerType: ControllerType;
	wirelessVendor: number;
	wirelessVendorList: SelectOption[];
	commandSetId: number;
	controllerCommandSets: SelectCommandSet[];
	id: number;
	isEnabled: boolean;
	address: string;
	isCommandSetAllowed: boolean;
	error: ErrorTabs;
};

export type FirmwareRevisionInfoStore = {
	canUpdateFirmware: boolean;
	enableUpdateSNIB: boolean;
	SupportsSNIB3: boolean;
	firmwareMessage: LoadMessagesForFirmwareConfiguration;
	controllerId: number;
	firmwareRevision: FirmwareRevision[];
	writeProtect: boolean;
};

export type HardwareConfigurationInfoStore = {
	alarmRelay: number;
	inputs: number;
	relays: number;
	xInputs: number;
	xRelays: number;
	vRelays: number;
};

export type OptionsInstalledInfoStore = {
	optionsInstalled: string[];
	allocateBufferStatus: BufferAllocationInfo;
};

export type OnPassbackViolationInfoStore = {
	reportEnablePassBackOccupancyTracking: boolean;
	generateAlarm: boolean;
	denyAccess: boolean;
};

export type OptionsViolationsZoneInfoStore = {
	reportOptionsOccupancyViolationsInAllZones: boolean;
	occupancyZones: SelectOption[];
	occupancySetupByZone: OccupancySetupByZone[];
	enabled: boolean;
};

export type PassbackOptionsInfoStore = {
	disableDuringTZ: number;
	enableSingleZone: boolean;
	enabled: boolean;
};

export type TwoPersonRuleInfoStore = {
	timeIncrementForEachRead: number;
	thresholdTime: number;
	disableDuringTimeZoneId: number;
};

export type AdvanceReportingSetupInfoStore = {
	messagesTypesOptions: SelectOptions<number>[];
	messagesFilters: AdvancedReportingMessageFilterOptions[];
	enableCommandEcho: boolean;
	enablePrintingSNETMessagesOnLocalPrinter: boolean;
	enableMCZReporting: boolean;
	enableACBReporting: boolean;
};

export type DisableReportingDuringTimeZoneInfoStore = {
	onLocalPrinter: number;
	transaction: number;
	events: number;
	grantedTransactions: number;
};

export type DisableReportingOfInfoStore = {
	dailyMidnightReport: boolean;
	internalEvent: boolean;
	externalEvents: boolean;
	transactionsEvents: boolean;
	invalidCodes: boolean;
	relayStateChanges: boolean;
	timeZoneStateChanges: boolean;
	tzDrivenRelayStateChanges: boolean;
	tzDrivenInputMaskingChanges: boolean;
};

export type LocalPrinterSetupInfoStore = {
	linesPerPageForFormFeed: number;
	languageOptions: SelectOption[];
	languageId: number;
};

type AlarmRelayTimer = {
	timer: number;
	mapToRelay: number;
};

export type AlarmRelayTimerInfoStore = {
	general: AlarmRelayTimer;
	duress: AlarmRelayTimer;
	tamper: AlarmRelayTimer;
	trouble: AlarmRelayTimer;
};

export type CodeTamperAlarmInfoStore = {
	invalidPenaltyTimer: number;
	thresholdTimer: number;
	lockoutTime: number;
};

export type OptionsInfoStore = {
	enableDuressAlarmMode: boolean;
	emptyLocalAlarmViaReset: boolean;
	validateOnDualCCOTZ: boolean;
	validateImmediatelyCCOTZ: boolean;
	enableGlobalCredentialManagement: boolean;
	enableGlobalMCZ: boolean;
	advancedBypassErrors: boolean;
	advancedBypassCodeConflict: boolean;
	advancedEnableSEK: boolean;
};

export type OptionsForLocalKeypadInfoStore = {
	timeout: number;
	systemCode: string;
	isMasterCodeDisable: boolean;
	enableLocalSystemCodeReset: boolean;
	localPrinterShowsNewCredentialCodes: boolean;
	autoGenCodeLength: number;
	genCodesWIthDuressDigit: boolean;
	duressDigit: number;
	reduceAutoGenPrintout: boolean;
	autoGenCodeLengthOptions: SelectOption[];
	duressDigitOptions: SelectOption[];
	disableOptions: boolean;
};

type SCIBValue = {
	value: number;
	options: SelectOption[];
};

export type SCIBInfoStore = {
	baud: SCIBValue;
	parity: SCIBValue;
	dataBits: SCIBValue;
	stopBits: SCIBValue;
};

export type IPAddressOctets = {
	octet1: string;
	octet2: string;
	octet3: string;
	octet4?: string;
};

export enum SnibSearchType {
	None = 0,
	Broadcast,
	IPScan,
}

export enum NetworkOctetsKeys {
	octet1 = 'octet1',
	octet2 = 'octet2',
	octet3 = 'octet3',
	octet4 = 'octet4',
}

export type NetworkInfo = {
	Id: string;
	Description: string;
	DefaultGateway: string;
	BroadcastAddress: string;
};

export type SearchIPAddressConfig = {
	pageId: string;
	networkType: number;
	protocol: number;
	isBroadCastSearch: boolean;
	includeGlobalBroadcastAddress: boolean;
	currentNetworkId: string;
	currentNetworks: NetworkInfo[];
	ipAddressOctets: IPAddressOctets;
};

export enum PortStyle {
	CCTV = 0,
	SNet,
	XNet,
	DVR,
	CSR,
	NONE,
}

export enum ActionDigitracContextMenu {
	SEND_COMMAND = 0,
}

export type ScrambleFactorImage = {
	CanBeDeleted: boolean;
	CategoryID: number;
	Description: string;
	Image: string;
	Name: string;
	Selected: boolean;
	Action?: ScrambleFactorImageAction;
} & ScrambleFactorImageShort;

export type TSScrambleFactorTab = {
	ScramblePadTypes: ScrambleFactorImage[];
	ScrambleFactorImageCategories: ScrambleFactorImageCategories[];
};

export type ScrambleFactorImageCategories = {
	ID: number;
	Name: string;
	Description: string;
	Width: number;
	Height: number;
	ScrambleFactorImages: ScrambleFactorImage[];
};

export type ScrambleFactorImageShort = {
	AddGUID?: string;
	ID: number | string;
	Dirty?: boolean;
};

export enum ReaderContextType {
	Door = 1,
	EntryReader,
	ExitReader,
}

export enum ScrambleFactorImageAction {
	None = 0,
	Add = 1,
	Update = 2,
	Delete = 3,
}

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext, useEffect, useState } from 'react';
import { ReaderInterface, ReaderLabel } from '../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setReaderInterface, StoreContext } from '../../../contextReader';
import styles from '../../General/general.module.scss';

type Props = {
	useDoorContext: boolean;
};

const Interface: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { isOnboardWiegandAvailable, isControllerSNIB3, isControllerMATCH, readerInterface, labels },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { isOnboardWiegandAvailable, isControllerSNIB3, isControllerMATCH, readerInterface, labels },
			dispatcher,
		} = useContext(StoreContext);
	}

	const [interfaceLabel, setInterfaceLabel] = useState('');

	useEffect(() => {
		const rs485Interface: ReaderLabel = labels.find(x => x.Label === 'GeneralThirdInterface');
		if (rs485Interface) {
			setInterfaceLabel(rs485Interface.Value);
		} else {
			setInterfaceLabel(_('RS485Interface'));
		}
	}, [labels]);

	const handleSelectCheckRadio = (e: RadioChangeEvent) => {
		useDoorContext ? dispatcherDoor(setReaderInterface(e.target.value)) : dispatcher(setReaderInterface(e.target.value));
	};

	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('ReaderInterface')}</legend>
					<div className={styles.radioButton}>
						<Radio.Group defaultValue={readerInterface} onChange={handleSelectCheckRadio} value={readerInterface}>
							<Radio id='readerModalMatchDS47InterfaceRadioButton' value={ReaderInterface.MatchDS47} disabled={isControllerMATCH}>
								{_('MatchDS47Interface')}
							</Radio>
							<Radio
								id='readerModalOnboardWiegandInterfaceRadioButton'
								value={ReaderInterface.OnboardWiegand}
								disabled={!isOnboardWiegandAvailable}>
								{_('OnboardWiegandInterface')}
							</Radio>
							<Radio id='readerModalInterfaceRadioButton' value={ReaderInterface.RS485} disabled={!isControllerSNIB3}>
								{interfaceLabel}
							</Radio>
							<Radio id='readerModalDisableRadioButton' value={ReaderInterface.Disabled}>
								{_('Disabled')}
							</Radio>
						</Radio.Group>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { Interface };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { ReaderOptionsTab } from '../../../../../../../../model/DeviceAdminModel';
import styles from '../../Options/options.module.scss';

type Props = {
	label: string;
	setArray: ([]) => void;
	collection: ReaderOptionsTab[];
	className: string;
};

const OptionsCheckBoxList: React.FC<Props> = ({ label, setArray, collection, className }) => {
	const handleOnChange = (e: CheckboxChangeEvent) => {
		const index: number = collection.findIndex(x => x.TableFieldName === e.target.id);
		const copyOfArray: ReaderOptionsTab[] = [...collection];
		copyOfArray[index] = { ...copyOfArray[index], Value: e.target.checked };
		setArray(copyOfArray);
	};

	return (
		<div className={className}>
			<fieldset>
				<legend className={styles.legend}>{label}</legend>
				<div className={styles.sections}>
					{collection.map(x => (
						<Checkbox key={x.TableFieldName} checked={x.Value} onChange={handleOnChange} id={x.TableFieldName}>
							<span>{x.OptionLabel}</span>
						</Checkbox>
					))}
				</div>
			</fieldset>
		</div>
	);
};

export { OptionsCheckBoxList };

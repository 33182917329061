import React from 'react';
import { canAddNewTimeZones } from '../../../../../Helper';
import { ControllerDoor, GenericTimeZone, TimeZoneType, VelocityDevice } from '../../../../../model/DeviceAdminModel';

type ValidationErrors = {
	controlZoneInput: boolean;
	readerInput: boolean;
	nameInput: '';
	timeZoneEditInput: boolean;
};

export type ControllersSelected = GenericTimeZone & ControllerDoor;

type ReaderState = {
	timeZones: GenericTimeZone[];
	timeZoneSelected: GenericTimeZone;
	controllersForReaders: VelocityDevice[];
	controllersSelectedId: number[];
	readers: ControllerDoor[];
	readersSelected: ControllersSelected[];
	readersPreSelected: ControllersSelected[];
	readersInfoGridSelected: number[];
	name: string;
	errors: ValidationErrors;
	displayMyNetworkLayout: boolean;
	timeZoneEditValue: GenericTimeZone;
	tag: boolean;
	alert: boolean;
	disableCredential: boolean;
	reloadControlZones: boolean;
	openTimeZoneModal: boolean;
	loading: boolean;
};

enum ContextActionTypes {
	SET_TIME_ZONES = 'SET_TIME_ZONES',
	SET_CONTROLLERS_FOR_READERS = 'SET_CONTROLLERS_FOR_READERS',
	SET_CONTROLLERS_SELECTED = 'SET_CONTROLLER_SELECTED',
	SET_READERS = 'SET_READERS',
	SET_READERS_SELECTED = 'SET_READERS_SELECTED',
	SET_TIME_ZONE_SELECTED = 'SET_TIME_ZONE_SELECTED',
	SET_PRESELECTED_READERS = 'SET_PRESELECTED_READERS',
	SET_ERRORS_VALIDATION = 'SET_ERRORS_VALIDATION',
	SET_NAME = 'SET_NAME',
	SET_DISPLAY_NETWORK = 'SET_DISPLAY_NETWORK',
	SET_SELECTED_READERS_GRID = 'SET_SELECTED_READERS_GRID',
	SET_EDIT_TIME_ZONE = 'SET_EDIT_TIME_ZONE',
	SET_TAG = 'SET_TAG',
	SET_ALERT = 'SET_ALERT',
	SET_DISABLE_CREDENTIAL = 'SET_DISABLE_CREDENTIAL',
	RELOAD_TIME_ZONES = 'RELOAD_TIME_ZONES',
	SET_OPEN_TIME_ZONE_MODAL = 'SET_OPEN_TIME_ZONE_MODAL',
	SET_LOADING = 'SET_LOADING',
}
type contextReloadTimeZones = {
	type: ContextActionTypes.RELOAD_TIME_ZONES;
	payload: boolean;
};
type contextOpenTimeZones = {
	type: ContextActionTypes.SET_OPEN_TIME_ZONE_MODAL;
	payload: boolean;
};
type contextSetTag = {
	type: ContextActionTypes.SET_TAG;
	payload: boolean;
};
type contextSetAlert = {
	type: ContextActionTypes.SET_ALERT;
	payload: boolean;
};
type contextSetDisableCredential = {
	type: ContextActionTypes.SET_DISABLE_CREDENTIAL;
	payload: boolean;
};
type contextSetControlZones = {
	type: ContextActionTypes.SET_TIME_ZONES;
	payload: GenericTimeZone[];
};
type contextSetControllers = {
	type: ContextActionTypes.SET_CONTROLLERS_FOR_READERS;
	payload: VelocityDevice[];
};
type contextSetControllersSelected = {
	type: ContextActionTypes.SET_CONTROLLERS_SELECTED;
	payload: number[];
};
type contextSetReaders = {
	type: ContextActionTypes.SET_READERS;
	payload: ControllerDoor[];
};
type contextSetReadersSelected = {
	type: ContextActionTypes.SET_READERS_SELECTED;
	payload: ControllersSelected[];
};
type contextSetReadersPreSelected = {
	type: ContextActionTypes.SET_PRESELECTED_READERS;
	payload: ControllersSelected[];
};
type contextSetControlZoneSelected = {
	type: ContextActionTypes.SET_TIME_ZONE_SELECTED;
	payload: GenericTimeZone;
};
type contextSetErrorValidations = {
	type: ContextActionTypes.SET_ERRORS_VALIDATION;
	payload: { type: keyof ValidationErrors; value: boolean | string };
};
type contextSetName = {
	type: ContextActionTypes.SET_NAME;
	payload: string;
};
type contextSetDisplayNetwork = {
	type: ContextActionTypes.SET_DISPLAY_NETWORK;
	payload: boolean;
};
type contextDeleteReaders = {
	type: ContextActionTypes.SET_SELECTED_READERS_GRID;
	payload: number[];
};
type contextSetEditControlZone = {
	type: ContextActionTypes.SET_EDIT_TIME_ZONE;
	payload: GenericTimeZone;
};

type contextSetLoading = {
	type: ContextActionTypes.SET_LOADING;
	payload: boolean;
};

type ActionContext =
	| contextSetControlZones
	| contextSetControllers
	| contextSetControllersSelected
	| contextSetReaders
	| contextSetReadersSelected
	| contextSetControlZoneSelected
	| contextSetReadersPreSelected
	| contextSetErrorValidations
	| contextSetName
	| contextSetDisplayNetwork
	| contextDeleteReaders
	| contextSetEditControlZone
	| contextSetTag
	| contextSetAlert
	| contextSetDisableCredential
	| contextReloadTimeZones
	| contextOpenTimeZones
	| contextSetLoading;

const readerState: ReaderState = {
	timeZones: [],
	controllersForReaders: [],
	controllersSelectedId: [],
	readers: [],
	readersSelected: [],
	readersPreSelected: [],
	timeZoneSelected: null,
	errors: {
		controlZoneInput: false,
		nameInput: '',
		readerInput: false,
		timeZoneEditInput: false,
	},
	name: '',
	displayMyNetworkLayout: true,
	readersInfoGridSelected: [],
	timeZoneEditValue: null,
	tag: false,
	alert: false,
	disableCredential: false,
	reloadControlZones: false,
	openTimeZoneModal: false,
	loading: false,
};
const readerContext = (state: Readonly<ReaderState>, action: ActionContext): ReaderState => {
	switch (action.type) {
		case ContextActionTypes.SET_TIME_ZONES: {
			const newTimeZone: GenericTimeZone = {
				GenericTimeZoneId: 0,
				GlobalTimeZoneId: 0,
				Name: '<New>',
				Type: TimeZoneType.New,
			};
			return { ...state, timeZones: canAddNewTimeZones() ? [newTimeZone, ...action.payload] : [...action.payload] };
		}
		case ContextActionTypes.SET_CONTROLLERS_FOR_READERS:
			return { ...state, controllersForReaders: action.payload };
		case ContextActionTypes.SET_CONTROLLERS_SELECTED:
			return { ...state, controllersSelectedId: action.payload };
		case ContextActionTypes.SET_READERS_SELECTED:
			return { ...state, readersSelected: action.payload };
		case ContextActionTypes.SET_PRESELECTED_READERS:
			return { ...state, readersPreSelected: action.payload };
		case ContextActionTypes.SET_READERS:
			return { ...state, readers: action.payload };
		case ContextActionTypes.SET_TIME_ZONE_SELECTED:
			return { ...state, timeZoneSelected: action.payload };
		case ContextActionTypes.SET_NAME:
			return { ...state, name: action.payload };
		case ContextActionTypes.SET_DISPLAY_NETWORK:
			return { ...state, displayMyNetworkLayout: action.payload };
		case ContextActionTypes.SET_SELECTED_READERS_GRID:
			return { ...state, readersInfoGridSelected: action.payload };
		case ContextActionTypes.SET_EDIT_TIME_ZONE:
			return { ...state, timeZoneEditValue: action.payload };
		case ContextActionTypes.SET_TAG:
			return { ...state, tag: action.payload };
		case ContextActionTypes.SET_ALERT:
			return { ...state, alert: action.payload };
		case ContextActionTypes.SET_DISABLE_CREDENTIAL:
			return { ...state, disableCredential: action.payload };
		case ContextActionTypes.RELOAD_TIME_ZONES:
			return { ...state, reloadControlZones: action.payload };
		case ContextActionTypes.SET_OPEN_TIME_ZONE_MODAL:
			return { ...state, openTimeZoneModal: action.payload };
		case ContextActionTypes.SET_ERRORS_VALIDATION:
			return { ...state, errors: { ...state.errors, [action.payload.type]: action.payload.value } };
		case ContextActionTypes.SET_LOADING:
			return { ...state, loading: action.payload };
		default:
			return { ...state };
	}
};

const setTimeZones = (payload: GenericTimeZone[]): contextSetControlZones => {
	return {
		type: ContextActionTypes.SET_TIME_ZONES,
		payload,
	};
};
const setControllers = (payload: VelocityDevice[]): contextSetControllers => {
	return {
		type: ContextActionTypes.SET_CONTROLLERS_FOR_READERS,
		payload,
	};
};
const setControllersSelected = (payload: number[]): contextSetControllersSelected => {
	return {
		type: ContextActionTypes.SET_CONTROLLERS_SELECTED,
		payload,
	};
};
const setReaders = (payload: ControllerDoor[]): contextSetReaders => {
	return {
		type: ContextActionTypes.SET_READERS,
		payload,
	};
};
const setReadersSelected = (payload: ControllersSelected[]): contextSetReadersSelected => {
	return {
		type: ContextActionTypes.SET_READERS_SELECTED,
		payload,
	};
};
const setReadersPreSelected = (payload: ControllersSelected[]): contextSetReadersPreSelected => {
	return {
		type: ContextActionTypes.SET_PRESELECTED_READERS,
		payload,
	};
};
const setTimeZoneSelected = (payload: GenericTimeZone): contextSetControlZoneSelected => {
	return {
		type: ContextActionTypes.SET_TIME_ZONE_SELECTED,
		payload,
	};
};
const setErrors = (payload: { type: keyof ValidationErrors; value: boolean | string }): contextSetErrorValidations => {
	return {
		type: ContextActionTypes.SET_ERRORS_VALIDATION,
		payload,
	};
};
const setName = (payload: string): contextSetName => {
	return {
		type: ContextActionTypes.SET_NAME,
		payload,
	};
};
const setDisplayNetworkLayout = (payload: boolean): contextSetDisplayNetwork => {
	return {
		type: ContextActionTypes.SET_DISPLAY_NETWORK,
		payload,
	};
};
const setSelectedReadersGridInfo = (payload: number[]): contextDeleteReaders => {
	return {
		type: ContextActionTypes.SET_SELECTED_READERS_GRID,
		payload,
	};
};
const setEditTimeZoneHasValue = (payload: GenericTimeZone): contextSetEditControlZone => {
	return {
		type: ContextActionTypes.SET_EDIT_TIME_ZONE,
		payload,
	};
};
const setTag = (payload: boolean): contextSetTag => {
	return {
		type: ContextActionTypes.SET_TAG,
		payload,
	};
};
const setAlert = (payload: boolean): contextSetAlert => {
	return {
		type: ContextActionTypes.SET_ALERT,
		payload,
	};
};
const setDisableCredential = (payload: boolean): contextSetDisableCredential => {
	return {
		type: ContextActionTypes.SET_DISABLE_CREDENTIAL,
		payload,
	};
};
const reloadTimeZones = (payload: boolean): contextReloadTimeZones => {
	return {
		type: ContextActionTypes.RELOAD_TIME_ZONES,
		payload,
	};
};
const setTimeZoneModal = (payload: boolean): contextOpenTimeZones => {
	return {
		type: ContextActionTypes.SET_OPEN_TIME_ZONE_MODAL,
		payload,
	};
};

const setLoading = (payload: boolean): contextSetLoading => {
	return {
		type: ContextActionTypes.SET_LOADING,
		payload,
	};
};

type CommandContext = {
	readerState: Readonly<ReaderState>;
	dispatcher: React.Dispatch<ActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const StoreContext = React.createContext<CommandContext>({ readerState, dispatcher: null });

export {
	readerState,
	StoreContext,
	readerContext,
	setTimeZones,
	setControllers,
	setControllersSelected,
	setReaders,
	setReadersSelected,
	setTimeZoneSelected,
	setReadersPreSelected,
	setErrors,
	setName,
	setDisplayNetworkLayout,
	setSelectedReadersGridInfo,
	setEditTimeZoneHasValue,
	setTag,
	setAlert,
	setDisableCredential,
	reloadTimeZones,
	setTimeZoneModal,
	setLoading,
};

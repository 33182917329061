import { Radio, RadioChangeEvent, Space } from 'antd';
import React, { useContext } from 'react';
import { PortNetWorkType, SnibSearchType } from '../../../../../../../../../model/DeviceAdminModel';
import { PortStoreContext, setNetworkType, setSnibSearchType } from '../../../../../contextPort';
import styles from './networkType.module.scss';

type Props = {};
const networkTypeRadioId = 'networkTypeRadioId';
const radioStyle = {
	display: 'block',
	height: '30px',
	lineHeight: '30px',
};

const NetworkType: React.FC<Props> = () => {
	const {
		portState: { networkType, loading },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleChangeNetworkType = (e: RadioChangeEvent) => {
		const {
			target: { value },
		} = e;
		dispatcher(setNetworkType(value));
		if (value === PortNetWorkType.IPv6) {
			dispatcher(setSnibSearchType(SnibSearchType.Broadcast));
		}
	};

	return (
		<div className={styles.fieldsetContainer}>
			<fieldset>
				<legend className={styles.legend}>{_('NetworkType')}</legend>
				<div className={styles.radioButton}>
					<Radio.Group id={networkTypeRadioId} value={networkType} onChange={handleChangeNetworkType} disabled={loading}>
						<Space direction='horizontal'>
							<Radio style={radioStyle} value={PortNetWorkType.IPv4}>
								{_('IPv4')}
							</Radio>
							<Radio style={radioStyle} value={PortNetWorkType.IPv6}>
								{_('IPv6')}
							</Radio>
						</Space>
					</Radio.Group>
				</div>
			</fieldset>
		</div>
	);
};

export { NetworkType };

import React from 'react';
import { deviceAdminApi } from '../../../api';
import { ModalConfirmation } from '../ModalConfirmation/ModalConfirmation';

type Props = {
	onConfirm?: () => void;
	onCancel?: () => void;
	okText?: string;
	cancelText?: string;
	protocolData: { portId: number; protocol: number };
};

const WarningProtocolMessage = async ({ onCancel, onConfirm, cancelText, okText, protocolData }: Props) => {
	const { portId, protocol } = protocolData;
	const { Message, IsWarning, Title } = await deviceAdminApi.getWarningMessagePortProtocol(portId, protocol);

	const messages = Message.split('\n');

	const content = (
		<div>
			{messages.map((message, key) => {
				return <p key={key}>{message}</p>;
			})}
		</div>
	);

	if (IsWarning) {
		ModalConfirmation({
			content,
			title: Title,
			onConfirm: () => {
				onConfirm?.();
			},
			onCancel: () => {
				onCancel?.();
			},
			okText: okText || 'Yes',
			cancelText: cancelText || 'No',
		});
	} else {
		onConfirm?.();
	}
};

export { WarningProtocolMessage };

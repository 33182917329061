// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logic-container--19_9R2cV0dL25SaTGg0KFW{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/DigiTrac/InputModal/tabs/Logic/logic.module.scss"],"names":[],"mappings":"AAAA,yCACC,YAAA,CACA,qBAAA","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "logic-container--19_9R2cV0dL25SaTGg0KFW"
};
export default ___CSS_LOADER_EXPORT___;

import { Button, Input, notification, Spin, Table, Tooltip } from 'antd';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { deviceAdminApi, statusViewerApi } from '../../../api';
import { buildColumn, ColumnsProps, getDefaultTablePaginationConfig, removeCommasAndPercentSign } from '../../../Helper';
import { getPermissionErrorMessage, SecuredComponents, SubPermissions, User } from '../../../model/AccountModel';
import { ObjectTypeTransfer, PaginationSetting, SortDirections } from '../../../model/CommonModel';
import {
	StatusGroupNotificationStatus,
	StatusViewerConfigurationColumns,
	StatusViewerGroup,
	StatusViewerPoint,
	StatusViewerPointByType,
} from '../../../model/StatusViewerModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { selectVelocityConfigurationFilterMode } from '../../../store/deviceControl/selectors';
import {
	addStatusViewerPointToConfiguration,
	initStatusViewerGroupConfiguration,
	loadingStatusViewerConfigurationTableAction,
	loadRetrieveStatusPointsByTypeAction,
	loadStatusViewerConfigurationAction,
	newStatusViewerGroup,
	removeStatusViewerPointFromConfiguration,
	resetStatusViewerPoints,
	updateStatusViewerGroup,
} from '../../../store/statusViewer/actions';
import {
	selectIsLoadingStatusViewerConfigurationTable,
	selectSelectedStatusGroup,
	selectStatusViewerConfiguration,
	selectStatusViewerPointByType,
} from '../../../store/statusViewer/selectors';
import { Modal, Select, SelectOption } from '../../common';
import { TableTransfer } from '../StatusGroupTransferTable/StatusGroupTransferTable';
import styles from './statusGroupConfiguration.module.scss';

type Props = {
	onSetVisible: () => void;
	statusViewerGroupToEdit?: StatusViewerGroup;
	setShouldResetSearchColumn: () => void;
	statusGroupFilter: SelectOption;
};

// type StatusViewerTransfer = TransferItem & StatusViewerPoint;

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const canAddEditDeleteStatusGroups: boolean = User.getSubComponentPermission(
	user,
	SubPermissions.StatusViewer_StatusViewer_AddEditandDeleteStatusGroups
).allowed;

type StatusViewerTransfer = ObjectTypeTransfer<StatusViewerConfigurationColumns>;

const defaultTablePaginationConfig: TablePaginationConfig = getDefaultTablePaginationConfig();
const paginationSetting: PaginationSetting = {
	SortField: null,
	SortDirection: SortDirections.None,
	PageNumber: defaultTablePaginationConfig.defaultCurrent,
	PageSize: defaultTablePaginationConfig.defaultPageSize,
};

const columnsLeft: ColumnsProps<StatusViewerConfigurationColumns>[] = [
	{
		...buildColumn(_('Name'), 'Name', '125px', 'start'),
		editable: true,
		sorter: (a, b) => a.Name.localeCompare(b.Name),
		ellipsis: {
			showTitle: false,
		},
		render: name => (
			<Tooltip placement='topLeft' title={name}>
				{name}
			</Tooltip>
		),
	},
	{
		...buildColumn(_('Address'), 'Address', 'auto', 'start'),
		sorter: (a, b) => a.Address.localeCompare(b.Address),
		ellipsis: {
			showTitle: false,
		},
		render: address => (
			<Tooltip placement='topLeft' title={address}>
				{address}
			</Tooltip>
		),
	},
];

const columnsRight: ColumnsProps<StatusViewerConfigurationColumns>[] = [
	{
		...buildColumn(_('Name'), 'Name', '125px', 'start'),
		editable: true,
		sorter: (a, b) => a.Name.localeCompare(b.Name),
		ellipsis: {
			showTitle: false,
		},
		render: name => (
			<Tooltip placement='topLeft' title={name}>
				{name}
			</Tooltip>
		),
	},
	{
		...buildColumn(_('Address'), 'Address', 'auto', 'start'),
		sorter: (a, b) => a.Address.localeCompare(b.Address),
		ellipsis: {
			showTitle: false,
		},
		render: address => (
			<Tooltip placement='topLeft' title={address}>
				{address}
			</Tooltip>
		),
	},
];

const tableId: string = 'StatusViewerConfigurationTransferTable';
const statusViewerGroupNameInputId = 'statusViewerGroupNameInput';
const SelectStatusGroupFilterId = 'SelectStatusGroupFilter';

const StatusGroupConfiguration: React.FC<Props> = ({ onSetVisible, statusViewerGroupToEdit, setShouldResetSearchColumn, statusGroupFilter }) => {
	const dispatch = useStoreDispatch();
	const [optionFilterSelection, setOptionFilterSelection] = useState('-1');
	const [data, setData] = useState<StatusViewerTransfer[]>([]);
	const [targetKeys, setTargetKeys] = useState<string[]>([]);
	const [selectedKeysLeft, setSelectedKeysLeft] = useState<React.Key[]>([]);
	const [selectedRowKeysPaginationLeft, setSelectedRowKeysPaginationLeft] = useState<StatusViewerConfigurationColumns[]>([]);
	const [selectedKeysRight, setSelectedKeysRight] = useState<React.Key[]>([]);
	const [selectedRowKeysPaginationRight, setSelectedRowKeysPaginationRight] = useState<StatusViewerConfigurationColumns[]>([]);
	const [nameError, setNameError] = useState('');
	const [statusPointsError, setStatusPointsError] = useState('');

	const [paginationTableLeft, setPaginationTableLeft] = useState<TablePaginationConfig>({
		...defaultTablePaginationConfig,
		current: defaultTablePaginationConfig.defaultCurrent,
		pageSize: defaultTablePaginationConfig.defaultPageSize,
		total: 0,
	});

	const [paginationTableRight, setPaginationTableRight] = useState<TablePaginationConfig>({
		...defaultTablePaginationConfig,
		current: defaultTablePaginationConfig.defaultCurrent,
		pageSize: defaultTablePaginationConfig.defaultPageSize,
		total: 0,
	});
	const [searchLeftValue, setSearchLeftValue] = useState('');
	const [searchRightValue, setSearchRightValue] = useState('');
	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const statusViewerPoint: StatusViewerPointByType = useStoreSelector<StatusViewerPointByType>(selectStatusViewerPointByType);
	const statusViewerConfiguration: StatusViewerGroup = useStoreSelector<StatusViewerGroup>(selectStatusViewerConfiguration);
	const isLoading: boolean = useStoreSelector<boolean>(selectIsLoadingStatusViewerConfigurationTable);
	const selectedStatusGroup = useStoreSelector(selectSelectedStatusGroup);
	const displayName = nameError && nameError !== '';
	const isStatusPointsEmpty = statusPointsError !== '';
	const filterOption = (inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
	const searchOption = useCallback(
		debounce(async (direction: string, value: string) => {
			const currentPagination: PaginationSetting = {
				PageNumber: paginationTableLeft.current <= 0 ? 1 : paginationTableLeft.current,
				PageSize: paginationTableLeft.pageSize,
				SortField: null,
				SortDirection: SortDirections.None,
				SearchedValue: value,
			};
			if (direction === 'left') {
				setSearchLeftValue(value);
				if (optionFilterSelection) {
					dispatch(loadingStatusViewerConfigurationTableAction(true));
					dispatch(loadRetrieveStatusPointsByTypeAction(Number(optionFilterSelection), targetKeys, currentPagination));
				}
				setSelectedKeysLeft([]);
				setSelectedRowKeysPaginationLeft([]);
			}
			if (direction === 'right') {
				setSelectedKeysRight([]);
				setSelectedRowKeysPaginationRight([]);
				setSearchRightValue(value);
				setPaginationTableRight({
					...paginationTableRight,
					total: 0,
				});
			}
		}, 200),
		[optionFilterSelection, targetKeys, paginationTableLeft]
	);
	const getTotalRight = (): number => {
		if (statusViewerConfiguration && statusViewerConfiguration.StatusPoints.length > 0) {
			return statusViewerConfiguration.StatusPoints.filter(
				f =>
					targetKeys.includes(`${f.ObjectId}-${f.ObjectTypeId}-${f.DeviceId}`) &&
					(searchRightValue == '' || f.Name.toLowerCase().includes(searchRightValue.toLowerCase()))
			).length;
		}
		return 0;
	};

	useEffect(() => {
		if (statusViewerGroupToEdit) {
			dispatch(loadStatusViewerConfigurationAction(statusViewerGroupToEdit.StatusGroupId));
		} else {
			dispatch(initStatusViewerGroupConfiguration({ Name: '', StatusGroupId: 0, StatusPoints: [] }));
		}
		window.addEventListener('beforeunload', handleCloseModal);
		return () => {
			dispatch(resetStatusViewerPoints());
			window.removeEventListener('beforeunload', handleCloseModal);
		};
	}, []);

	useEffect(() => {
		let newData: StatusViewerTransfer[] = [];
		if (statusViewerPoint && statusViewerPoint.StatusViewerPoints && statusViewerPoint.StatusViewerPoints.length > 0) {
			newData = statusViewerPoint.StatusViewerPoints.map(m => ({
				...m,
				key: `${m.ObjectId}-${m.ObjectTypeId}-${m.DeviceId}`,
				title: m.Name,
				description: m.Name,
				disabled: false,
			}));
		}
		if (statusViewerConfiguration && statusViewerConfiguration.StatusPoints.length > 0) {
			let selectedStatusViewerPoint = statusViewerConfiguration.StatusPoints.map(m => ({
				...m,
				key: `${m.ObjectId}-${m.ObjectTypeId}-${m.DeviceId}`,
				title: m.Name,
				description: m.Name,
				disabled: false,
			}));
			newData = newData.concat(selectedStatusViewerPoint);
			const selectKeys = [...statusViewerConfiguration.StatusPoints.map(m => `${m.ObjectId}-${m.ObjectTypeId}-${m.DeviceId}`)];
			setData(newData);
			setTargetKeys(selectKeys);
			setPaginationTableRight({
				...paginationTableRight,
				total: selectKeys.length != 0 ? getTotalRight() : 0,
			});
		} else {
			setData(newData);
			setPaginationTableRight({
				...paginationTableRight,
				total: 0,
			});
		}

		setPaginationTableLeft({
			...paginationTableLeft,
			total: statusViewerPoint?.TotalStatusViewerPoints ?? 0,
		});
		dispatch(loadingStatusViewerConfigurationTableAction(false));
	}, [statusViewerPoint, statusViewerConfiguration]);

	const onHandleSelectionAllLeft = (currentRowKeys: React.Key[], selectedKeysTransfer, onItemSelectAll) => {
		statusViewerApi.retrieveStatusPointsIdsByType(Number(optionFilterSelection), targetKeys, searchLeftValue).then(response => {
			if (response && response.Entity) {
				if (selectedKeysTransfer.length > 0) {
					onItemSelectAll(selectedKeysTransfer, false);
				}
				const allKeysSelections = response.Entity.StatusViewerPoints.map(m => `${m.ObjectId}-${m.ObjectTypeId}-${m.DeviceId}`);
				const allRowsSelected = response.Entity.StatusViewerPoints.map<StatusViewerConfigurationColumns>(m => ({
					key: `${m.ObjectId}-${m.ObjectTypeId}-${m.DeviceId}`,
					Address: m.Address,
					DeviceId: m.DeviceId,
					ObjectId: m.ObjectId,
					StatusViewerPointId: m.StatusViewerPointId,
					ObjectTypeId: m.ObjectTypeId,
					Name: m.Name,
				}));
				setSelectedKeysLeft(allKeysSelections);
				setSelectedRowKeysPaginationLeft(allRowsSelected);
				onItemSelectAll(allKeysSelections, allKeysSelections.length > 0);
			}
		});
	};

	const rowSelectionLeft: TableRowSelection<StatusViewerConfigurationColumns> = useMemo(
		() => ({
			preserveSelectedRowKeys: true,
			type: 'checkbox',
			getCheckboxProps: record => ({
				id: `tableTransferTableCheckbox-${record.key}`,
				children: <label htmlFor={`tableTransferTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
			}),
			selectedRowKeys: selectedKeysLeft,
		}),
		[selectedKeysLeft]
	);

	const rowSelectionRight: TableRowSelection<StatusViewerConfigurationColumns> = useMemo(
		() => ({
			preserveSelectedRowKeys: true,
			type: 'checkbox',
			selections: !canAddEditDeleteStatusGroups ? undefined : [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
			getCheckboxProps: record => ({
				id: `tableTransferTableCheckbox-${record.key}`,
				children: <label htmlFor={`tableTransferTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
			}),
			selectedRowKeys: selectedKeysRight,
		}),
		[selectedKeysRight]
	);

	const onHandleChange = (nextTargetKeys: string[], direction: any, moveKeys: string[]) => {
		setTargetKeys(nextTargetKeys);
		if (direction === 'right') {
			const moveData = selectedRowKeysPaginationLeft
				.filter(f => moveKeys.findIndex(x => x === f.key) >= 0)
				.map<StatusViewerPoint>(m => ({
					StatusViewerPointId: m.StatusViewerPointId,
					Name: m.Name,
					Address: m.Address,
					DeviceId: m.DeviceId,
					ObjectId: m.ObjectId,
					ObjectTypeId: m.ObjectTypeId,
				}));
			let currentPageNumber: number = paginationTableLeft.current;
			const currentSelectTargetKey = statusViewerConfiguration.StatusPoints.map(m => `${m.ObjectId}-${m.ObjectTypeId}-${m.StatusViewerPointId}`);
			const nextCurrentTargetKey = nextTargetKeys.filter(m => !currentSelectTargetKey.includes(m));
			const maxPage = (paginationTableLeft.total - nextCurrentTargetKey.length) / paginationTableLeft.pageSize;
			if (paginationTableLeft.current >= maxPage) {
				currentPageNumber = Math.trunc(maxPage) < maxPage ? Math.trunc(maxPage) + 1 : Math.trunc(maxPage);
				setPaginationTableLeft({
					...paginationTableLeft,
					current: currentPageNumber <= 0 ? 1 : currentPageNumber,
				});
			}
			const paginationMove: PaginationSetting = {
				PageNumber: currentPageNumber <= 0 ? 1 : currentPageNumber,
				PageSize: paginationTableLeft.pageSize,
				SortField: null,
				SortDirection: SortDirections.None,
				SearchedValue: searchLeftValue,
			};
			const optionsRetrieveStatusPointsByType = {
				optionID: Number(optionFilterSelection),
				targetKeys: nextTargetKeys,
				paginationSetting: paginationMove,
			};
			setSelectedKeysLeft([]);
			setSelectedRowKeysPaginationLeft([]);
			setPaginationTableRight({
				...paginationTableRight,
				total: paginationTableRight.total + moveData.length,
			});
			dispatch(loadingStatusViewerConfigurationTableAction(true));
			dispatch(addStatusViewerPointToConfiguration(moveData, optionsRetrieveStatusPointsByType));

			if (isStatusPointsEmpty) {
				setStatusPointsError('');
			}
		} else {
			const moveData = selectedRowKeysPaginationRight
				.filter(f => moveKeys.findIndex(x => x === f.key) >= 0)
				.map<StatusViewerPoint>(m => ({
					StatusViewerPointId: m.StatusViewerPointId,
					Name: m.Name,
					Address: m.Address,
					DeviceId: m.DeviceId,
					ObjectId: m.ObjectId,
					ObjectTypeId: m.ObjectTypeId,
				}));

			const paginationMove: PaginationSetting = {
				PageNumber: paginationTableLeft.current <= 0 ? 1 : paginationTableLeft.current,
				PageSize: paginationTableLeft.pageSize,
				SortField: null,
				SortDirection: SortDirections.None,
				SearchedValue: searchLeftValue,
			};
			const optionsRetrieveStatusPointsByType = {
				optionID: Number(optionFilterSelection),
				targetKeys: nextTargetKeys,
				paginationSetting: paginationMove,
			};
			const removeData = moveData
				.filter(f => f.ObjectId !== Number(optionFilterSelection))
				.map<string>(m => `${m.ObjectId}-${m.ObjectTypeId}-${m.DeviceId}`);
			const newData = data.filter(f => !removeData.includes(f.key));
			setData(newData);
			setSelectedKeysRight([]);
			setSelectedRowKeysPaginationRight([]);
			setPaginationTableRight({
				...paginationTableRight,
				total: nextTargetKeys.length != 0 ? getTotalRight() : 0,
			});
			dispatch(loadingStatusViewerConfigurationTableAction(true));
			dispatch(removeStatusViewerPointFromConfiguration(moveData, optionsRetrieveStatusPointsByType));
		}
	};

	const shouldResetSearchColumn = statusViewerGroup => {
		if (statusViewerGroup && isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const handleOnSave = async () => {
		const notificationStatusConfig: StatusGroupNotificationStatus = {
			statusGroupSelect: selectedStatusGroup,
			paginationSetting: paginationSetting,
			onSetVisible: onSetVisible,
			setNameError: setNameError,
			shouldResetSearchColumn: shouldResetSearchColumn,
			permissionErrorCallback: errorMessage => {
				notification['error']({
					message: errorMessage,
				});
			},
			onHandleCloseModal: handleCloseModal,
			onSetStatusPointsError: setStatusPointsError,
		};
		if (statusViewerConfiguration.StatusGroupId) {
			dispatch(updateStatusViewerGroup(statusViewerConfiguration, notificationStatusConfig));
		} else {
			dispatch(newStatusViewerGroup(statusViewerConfiguration, notificationStatusConfig));
		}
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		setNameError('');
		dispatch(initStatusViewerGroupConfiguration({ ...statusViewerConfiguration, Name: value }));
	};

	const handleFilterChange = value => {
		const currentPagination: PaginationSetting = {
			PageNumber: paginationTableLeft.current <= 0 ? 1 : paginationTableLeft.current,
			PageSize: paginationTableLeft.pageSize,
			SortField: null,
			SortDirection: SortDirections.None,
			SearchedValue: '',
		};
		dispatch(loadRetrieveStatusPointsByTypeAction(Number(value), targetKeys, currentPagination));
	};

	const clearSearch = value => {
		dispatch(loadingStatusViewerConfigurationTableAction(true));
		setOptionFilterSelection(value);
		const searchList = document.getElementsByClassName('ant-input-clear-icon');
		for (let index = 0; index < searchList.length; index++) {
			let element: HTMLElement = searchList[index] as HTMLElement;
			element.click();
		}

		const componentDropdown = document.getElementById(SelectStatusGroupFilterId);
		if (componentDropdown) {
			componentDropdown.focus();
		}

		setSelectedKeysLeft([]);
		setSelectedRowKeysPaginationLeft([]);
		setSelectedKeysRight([]);
		setSelectedRowKeysPaginationRight([]);
		setSearchLeftValue('');

		handleFilterChange(value);
	};

	const handleTableChangePagination = (pagination, filters, sorter, extra, direction) => {
		if (direction === 'left') {
			dispatch(loadingStatusViewerConfigurationTableAction(true));
			const currentPagination: PaginationSetting = {
				PageNumber: pagination.current <= 0 ? 1 : pagination.current,
				PageSize: pagination.pageSize,
				SortField: sorter.field,
				SortDirection: sorter.order,
				SearchedValue: searchLeftValue,
			};
			setPaginationTableLeft({
				...paginationTableLeft,
				current: pagination.current,
				pageSize: pagination.pageSize,
			});
			dispatch(loadRetrieveStatusPointsByTypeAction(Number(optionFilterSelection), targetKeys, currentPagination));
		} else {
			setPaginationTableRight({
				...paginationTableRight,
				current: pagination.current,
				pageSize: pagination.pageSize,
			});
		}
	};

	const handleCloseModal = () => {
		if (statusViewerConfiguration) {
			deviceAdminApi.unlockComponentDeviceAdmin(statusViewerConfiguration.StatusGroupId, SecuredComponents.Status_Viewer);
		}
		onSetVisible();
	};

	const updatedStatePaginationTableLeft = useMemo(
		() =>
			getDefaultTablePaginationConfig(
				paginationTableLeft.disabled,
				paginationTableLeft.onChange,
				paginationTableLeft.current,
				paginationTableLeft.pageSize,
				paginationTableLeft.total,
				paginationTableLeft.showSizeChanger,
				selectedKeysLeft
			),
		[paginationTableLeft, selectedKeysLeft]
	);

	const updatedStatePaginationTableRight = useMemo(
		() =>
			getDefaultTablePaginationConfig(
				paginationTableRight.disabled,
				paginationTableRight.onChange,
				paginationTableRight.current,
				paginationTableRight.pageSize,
				paginationTableRight.total,
				paginationTableRight.showSizeChanger,
				selectedKeysRight
			),
		[paginationTableRight, selectedKeysRight]
	);

	return (
		<Modal
			footer={[
				<Button
					id='statusViewerGroupSaveButton'
					key='save'
					type='primary'
					title={getPermissionErrorMessage(canAddEditDeleteStatusGroups)}
					disabled={!canAddEditDeleteStatusGroups}
					onClick={() => handleOnSave()}>
					{_('SaveChanges')}
				</Button>,
				<Button id='statusViewerGroupCancelButton' key='cancel' onClick={() => handleCloseModal()}>
					{_('Cancel')}
				</Button>,
			]}
			width='1300px'
			visible={true}
			title={statusViewerGroupToEdit ? _('EditStatusGroup') : _('AddStatusGroup')}
			onCancel={() => handleCloseModal()}
			onClickOk={() => null}>
			<div id='statusViewerGroupContainer' className={styles.container}>
				<div className={cx({ [styles.error]: nameError })}>
					<label htmlFor={statusViewerGroupNameInputId}>{_('Name')}</label>
					<Input id={statusViewerGroupNameInputId} onChange={handleChangeName} value={statusViewerConfiguration?.Name} maxLength={50} />
					{displayName && <label htmlFor={statusViewerGroupNameInputId}>{nameError}</label>}
				</div>
				<div>
					<label htmlFor={SelectStatusGroupFilterId}>{_('Component')}</label>
					<Select
						id={SelectStatusGroupFilterId}
						options={statusGroupFilter}
						value={optionFilterSelection}
						onChange={clearSearch}
						className={styles.selectOptionStatusViewer}
						listHeight={320}
						showSearch={true}
						maxSearchLength={64}
					/>
				</div>
				<div>
					{isStatusPointsEmpty && (
						<div className={styles.statusPointsLabelError}>
							<label htmlFor={SelectStatusGroupFilterId}>{statusPointsError}</label>
						</div>
					)}
					<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
						<TableTransfer<StatusViewerConfigurationColumns>
							tableId={tableId}
							dataSource={data}
							titles={[_('DoesNotContain'), _('Contains')]}
							targetKeys={targetKeys}
							showSearch={true}
							columnsLeft={columnsLeft}
							columnsRight={columnsRight}
							className={cx(styles.treeTransfer, { [styles.statusPointsContainerError]: isStatusPointsEmpty })}
							onChange={onHandleChange}
							rowSelectionLeft={rowSelectionLeft}
							setSelectedKeysLeft={setSelectedKeysLeft}
							selectedRowKeysPaginationLeft={selectedRowKeysPaginationLeft}
							setSelectedRowKeysPaginationLeft={setSelectedRowKeysPaginationLeft}
							rowSelectionRight={rowSelectionRight}
							setSelectedKeysRight={setSelectedKeysRight}
							selectedRowKeysPaginationRight={selectedRowKeysPaginationRight}
							setSelectedRowKeysPaginationRight={setSelectedRowKeysPaginationRight}
							onSearch={searchOption}
							filterOption={filterOption}
							onChangeTable={handleTableChangePagination}
							paginationTableLeft={updatedStatePaginationTableLeft}
							paginationTableRight={updatedStatePaginationTableRight}
							canAddEditDeleteStatusGroups={canAddEditDeleteStatusGroups}
							onHandleSelectionAllLeft={onHandleSelectionAllLeft}
						/>
					</Spin>
				</div>
			</div>
		</Modal>
	);
};

export { StatusGroupConfiguration };

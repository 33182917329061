import { batch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { statusApi, statusViewerApi } from '../../api/';
import { NotificationStatus } from '../../components/common/NotificationStatus/NotificationStatus';
import { getDevicesIdsBy } from '../../components/statusViewer/helpers';
import { getDefaultTablePaginationConfig } from '../../Helper';
import { GridColumn, PaginationSetting, ResponseStatusCode, SortDirections } from '../../model/CommonModel';
import {
	ControllerToEdit,
	DeleteItemData,
	ExpandedRowItem,
	PaginationTable,
	StatusGroupSelected,
	StatusObjectEnum,
	StatusViewer,
	StatusViewerEnum,
	StatusViewerEvent,
	StatusViewerGroup,
	StatusViewerPoint,
} from '../../model/StatusViewerModel';
import { AddItemData, StatusGroupNotificationStatus, StatusViewerPointByType } from './../../model/StatusViewerModel';
import { StatusViewerActionTypes } from './actionTypes';

type RetrieveStatusViewer = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER;
	payload: StatusViewer;
};

type SetStatusViewerRealTime = {
	type: StatusViewerActionTypes.SET_STATUS_VIEWER_REAL_TIME;
	payload: StatusViewerEvent[];
};

type RetrieveStatusViewerByPagination = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_BY_PAGINATION;
	payload: StatusViewer;
};

type RetrieveStatusViewerByType = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_BY_TYPE;
	payload: StatusViewer;
};

type RetrieveStatusGroup = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_GROUP;
	payload: StatusViewerGroup[];
};

type RetrieveStatusViewerMaskedOnly = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_MASKED_ONLY;
	payload: StatusViewer;
};

type AddStatusViewerGroup = {
	type: StatusViewerActionTypes.ADD_STATUS_VIEWER_GROUP;
	payload: StatusViewerGroup;
};

type EditStatusViewerGroup = {
	type: StatusViewerActionTypes.EDIT_STATUS_VIEWER_GROUP;
	payload: StatusViewerGroup;
};

type DeleteStatusViewer = {
	type: StatusViewerActionTypes.DELETE_STATUS_VIEWER;
	payload: StatusGroupSelected;
};

type RetrieveStatusViewerConfiguration = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_CONFIGURATION;
	payload: StatusViewerGroup;
};

type RetrieveStatusViewerColumnConfiguration = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_COLUMN_CONFIGURATION;
	payload: Record<StatusObjectEnum, GridColumn[]>;
};

type RetrieveStatusPointsByType = {
	type: StatusViewerActionTypes.RETRIEVE_STATUS_POINTS_BY_TYPE_ACTION;
	payload: StatusViewerPointByType;
};

type CleanStatusViewer = {
	type: StatusViewerActionTypes.CLEAN_STATUS_VIEWER;
	payload: StatusViewer;
};

type NewStatusViewerGroup = {
	type: StatusViewerActionTypes.NEW_STATUS_VIEWER_CONFIGURATION;
	payload: StatusViewerGroup;
};

type AddStatusViewerPoint = {
	type: StatusViewerActionTypes.STATUS_VIEWER_ADD_POINT;
	payload: StatusViewerPoint[];
};

type RemoveStatusViewerPoint = {
	type: StatusViewerActionTypes.STATUS_VIEWER_REMOVE_POINT;
	payload: StatusViewerPoint[];
};

type SaveStatusViewerColumnConfiguration = {
	type: StatusViewerActionTypes.SAVE_STATUS_VIEWER_COLUMN_CONFIGURATION;
	payload: Record<StatusObjectEnum, GridColumn[]>;
};

type UpdateStatusViewerColumnConfiguration = {
	type: StatusViewerActionTypes.UPDATE_STATUS_VIEWER_COLUMN_CONFIGURATION_STATE;
	payload: Record<StatusObjectEnum, GridColumn[]>;
};

type LoadingStatusViewerConfigurationTable = {
	type: StatusViewerActionTypes.LOADING_STATUS_VIEWER_CONFIGURATION_TABLE;
	payload: boolean;
};

type LoadingStatusViewerTable = {
	type: StatusViewerActionTypes.LOADING_STATUS_VIEWER_TABLE;
	payload: boolean;
};

type ResetStatusViewerPoints = {
	type: StatusViewerActionTypes.RESET_STATUS_VIEWER_POINT;
};

type SelectedStatusGroup = {
	type: StatusViewerActionTypes.SELECTED_STATUS_GROUP;
	payload: StatusGroupSelected;
};

type ExpandedRowStatusGroup = {
	type: StatusViewerActionTypes.EXPANDED_ROW_STATUS_GROUP;
	payload: ExpandedRowItem;
};

type SetControllerToEdit = {
	type: StatusViewerActionTypes.SET_CONTROLLER_TO_EDIT;
	payload: ControllerToEdit;
};

type AddStatusViewerItemData = {
	type: StatusViewerActionTypes.ADD_STATUS_VIEWER_ITEM_DATA;
	payload: AddItemData;
};

type DeleteStatusViewerItemData = {
	type: StatusViewerActionTypes.DELETE_STATUS_VIEWER_ITEM_DATA;
	payload: DeleteItemData;
};

type DeleteStatusGroupOption = {
	type: StatusViewerActionTypes.DELETE_STATUS_GROUP_OPTION;
	payload: number;
};

export type StatusViewerAction =
	| RetrieveStatusViewer
	| SetStatusViewerRealTime
	| RetrieveStatusViewerByPagination
	| RetrieveStatusViewerByType
	| RetrieveStatusGroup
	| AddStatusViewerGroup
	| EditStatusViewerGroup
	| DeleteStatusViewer
	| RetrieveStatusViewerConfiguration
	| RetrieveStatusViewerColumnConfiguration
	| RetrieveStatusPointsByType
	| CleanStatusViewer
	| NewStatusViewerGroup
	| AddStatusViewerPoint
	| RemoveStatusViewerPoint
	| SaveStatusViewerColumnConfiguration
	| UpdateStatusViewerColumnConfiguration
	| ResetStatusViewerPoints
	| SelectedStatusGroup
	| LoadingStatusViewerConfigurationTable
	| LoadingStatusViewerTable
	| ExpandedRowStatusGroup
	| RetrieveStatusViewerMaskedOnly
	| SetControllerToEdit
	| AddStatusViewerItemData
	| DeleteStatusViewerItemData
	| DeleteStatusGroupOption;

const defaultData: StatusViewer = {
	StatusViewerItems: [],
};

const resetStatusViewerPointsAction = (): ResetStatusViewerPoints => {
	return {
		type: StatusViewerActionTypes.RESET_STATUS_VIEWER_POINT,
	};
};

export const retrieveStatusViewerAction = (payload: StatusViewer): RetrieveStatusViewer => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER,
		payload,
	};
};

const retrieveStatusViewerByPaginationAction = (payload: StatusViewer): RetrieveStatusViewerByPagination => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_BY_PAGINATION,
		payload,
	};
};

const retrieveStatusViewerMaskedOnlyAction = (payload: StatusViewer): RetrieveStatusViewerMaskedOnly => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_MASKED_ONLY,
		payload,
	};
};

const setStatusViewerRealTimeAction = (payload: StatusViewerEvent[]): SetStatusViewerRealTime => {
	return {
		type: StatusViewerActionTypes.SET_STATUS_VIEWER_REAL_TIME,
		payload,
	};
};

const retrieveStatusViewerConfigurationAction = (payload: StatusViewerGroup): RetrieveStatusViewerConfiguration => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_CONFIGURATION,
		payload,
	};
};

const retrieveStatusViewerColumnConfigurationAction = (payload: Record<StatusObjectEnum, GridColumn[]>): RetrieveStatusViewerColumnConfiguration => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_COLUMN_CONFIGURATION,
		payload,
	};
};

const retrieveStatusPointsByTypeAction = (payload: StatusViewerPointByType): RetrieveStatusPointsByType => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_POINTS_BY_TYPE_ACTION,
		payload,
	};
};

const retrieveStatusViewerByTypeAction = (payload: StatusViewer): RetrieveStatusViewerByType => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_BY_TYPE,
		payload,
	};
};

const retrieveStatusGroupAction = (payload: StatusViewerGroup[]): RetrieveStatusGroup => {
	return {
		type: StatusViewerActionTypes.RETRIEVE_STATUS_GROUP,
		payload,
	};
};

const editStatusViewerGroupAction = (payload: StatusViewerGroup): EditStatusViewerGroup => {
	return {
		type: StatusViewerActionTypes.EDIT_STATUS_VIEWER_GROUP,
		payload,
	};
};

const deleteStatusViewerAction = (payload: StatusGroupSelected): DeleteStatusViewer => {
	return {
		type: StatusViewerActionTypes.DELETE_STATUS_VIEWER,
		payload,
	};
};

const cleanStatusViewerAction = (payload: StatusViewer): CleanStatusViewer => {
	return {
		type: StatusViewerActionTypes.CLEAN_STATUS_VIEWER,
		payload,
	};
};

const newStatusViewerGroupConfigurationAction = (payload: StatusViewerGroup): NewStatusViewerGroup => {
	return {
		type: StatusViewerActionTypes.NEW_STATUS_VIEWER_CONFIGURATION,
		payload,
	};
};

const addStatusViewerPointAction = (payload: StatusViewerPoint[]): AddStatusViewerPoint => {
	return {
		type: StatusViewerActionTypes.STATUS_VIEWER_ADD_POINT,
		payload,
	};
};

const setControllerToEditAction = (payload: ControllerToEdit): SetControllerToEdit => {
	return {
		type: StatusViewerActionTypes.SET_CONTROLLER_TO_EDIT,
		payload,
	};
};

const removeStatusViewerPointAction = (payload: StatusViewerPoint[]): RemoveStatusViewerPoint => {
	return {
		type: StatusViewerActionTypes.STATUS_VIEWER_REMOVE_POINT,
		payload,
	};
};

const updateStatusViewerColumnConfigurationStateAction = (payload: Record<StatusObjectEnum, GridColumn[]>): UpdateStatusViewerColumnConfiguration => {
	return {
		type: StatusViewerActionTypes.UPDATE_STATUS_VIEWER_COLUMN_CONFIGURATION_STATE,
		payload,
	};
};

const saveStatusViewerColumnConfigurationAction = (payload: Record<StatusObjectEnum, GridColumn[]>): SaveStatusViewerColumnConfiguration => {
	return {
		type: StatusViewerActionTypes.SAVE_STATUS_VIEWER_COLUMN_CONFIGURATION,
		payload,
	};
};

const loadingStatusViewerTableAction = (payload: boolean): LoadingStatusViewerTable => {
	return {
		type: StatusViewerActionTypes.LOADING_STATUS_VIEWER_TABLE,
		payload,
	};
};

export const loadingStatusViewerConfigurationTableAction = (payload: boolean): LoadingStatusViewerConfigurationTable => {
	return {
		type: StatusViewerActionTypes.LOADING_STATUS_VIEWER_CONFIGURATION_TABLE,
		payload,
	};
};

export const addStatusViewerItemDataAction = (payload: AddItemData): AddStatusViewerItemData => {
	return {
		type: StatusViewerActionTypes.ADD_STATUS_VIEWER_ITEM_DATA,
		payload,
	};
};

export const deleteStatusViewerItemDataAction = (payload: DeleteItemData): DeleteStatusViewerItemData => {
	return {
		type: StatusViewerActionTypes.DELETE_STATUS_VIEWER_ITEM_DATA,
		payload,
	};
};

export const deleteStatusGroupOption = (payload: number): DeleteStatusGroupOption => {
	return {
		type: StatusViewerActionTypes.DELETE_STATUS_GROUP_OPTION,
		payload,
	};
};

const defaultDataStatusPointByType: StatusViewerPointByType = {
	StatusViewerPoints: [],
	TotalStatusViewerPoints: 0,
};

export const resetStatusViewerPoints = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(resetStatusViewerPointsAction());
	};
};

export const retrieveStatusViewerByPagination = (
	statusGroupId: number,
	objectId: number,
	pagination: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusViewerByPagination(statusGroupId, objectId, pagination).then(response => {
			dispatch(retrieveStatusViewerByPaginationAction(response && response.Entity ? response.Entity : defaultData));
		});
	};
};

export const retrieveStatusViewerMaskedOnly = (
	statusGroupSelected: StatusGroupSelected,
	pagination: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusViewerMaskedOnly(pagination).then(response => {
			dispatch(retrieveStatusViewerMaskedOnlyAction(response && response.Entity ? response.Entity : defaultData));
			dispatch(
				selectedStatusGroupAction({
					...statusGroupSelected,
					PaginationTables: [{ ObjectTypeId: statusGroupSelected.Id, PaginationSetting: pagination }],
				})
			);
		});
	};
};

export const getStatusViewerMaskedOnly = (paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		const response = await statusViewerApi.retrieveStatusViewerMaskedOnly(paginationSetting);

		if (response && response.ResponseStatusCode === ResponseStatusCode.Success) {
			dispatch(retrieveStatusViewerMaskedOnlyAction(response.Entity));
		}
	};
};

export const retrieveExpandedRowStatusGroupAction = (payload: ExpandedRowItem): ExpandedRowStatusGroup => {
	return {
		type: StatusViewerActionTypes.EXPANDED_ROW_STATUS_GROUP,
		payload,
	};
};

export const retrieveExpandedRowStatusGroup = (expandedRowItem: ExpandedRowItem): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusApi.retrieveDoorAccess(expandedRowItem.DoorId).then(response => {
			dispatch(retrieveExpandedRowStatusGroupAction({ ...expandedRowItem, Granted: response.Granted, Denied: response.Denied, isLoading: false }));
		});
	};
};

export const setStatusViewerRealTime = (response: StatusViewerEvent[], optionSelection: StatusViewerEnum): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		if (response && response.length > 0) {
			batch(() => {
				dispatch(setStatusViewerRealTimeAction(response));
				const deviceValid: StatusObjectEnum[] = [StatusObjectEnum.Input, StatusObjectEnum.Door];
				const someOne = response.filter(x => deviceValid.includes(x.ObjectType));

				if (optionSelection === StatusViewerEnum.MaskedGroup && someOne) {
					const selected: StatusGroupSelected = {
						Id: 1,
						StatusViewerType: StatusViewerEnum.MaskedGroup,
					};
					const paginationSetting: PaginationSetting = {
						SortField: null,
						SortDirection: SortDirections.None,
						PageNumber: getDefaultTablePaginationConfig().defaultCurrent,
						PageSize: getDefaultTablePaginationConfig().defaultPageSize,
					};
					dispatch(retrieveStatusViewerMaskedOnly(selected, paginationSetting));
				}
			});
		}
	};
};

export const selectedStatusGroupAction = (payload: StatusGroupSelected): SelectedStatusGroup => {
	return {
		type: StatusViewerActionTypes.SELECTED_STATUS_GROUP,
		payload,
	};
};

export const initStatusViewerGroupConfiguration = (statusViewerGroup: StatusViewerGroup): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(newStatusViewerGroupConfigurationAction(statusViewerGroup));
	};
};

export const addStatusViewerPointToConfiguration = (
	statusViewerPoint: StatusViewerPoint[],
	optionsRetrieveStatusPointsByType
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		const { targetKeys, optionID, paginationSetting } = optionsRetrieveStatusPointsByType;
		const { Item1: selectedDevice, Item2: selectedDeviceExpansion } = getDevicesIdsBy(optionID, targetKeys);
		await dispatch(addStatusViewerPointAction(statusViewerPoint));
		statusViewerApi.retrieveStatusPointsByType(optionID, paginationSetting, selectedDevice, selectedDeviceExpansion).then(response => {
			dispatch(retrieveStatusPointsByTypeAction(response.Entity));
		});
	};
};

export const removeStatusViewerPointFromConfiguration = (
	statusViewerPoint: StatusViewerPoint[],
	optionsRetrieveStatusPointsByType
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		const { targetKeys, optionID, paginationSetting } = optionsRetrieveStatusPointsByType;
		const { Item1: selectedDevice, Item2: selectedDeviceExpansion } = getDevicesIdsBy(optionID, targetKeys);
		await dispatch(removeStatusViewerPointAction(statusViewerPoint));
		statusViewerApi.retrieveStatusPointsByType(optionID, paginationSetting, selectedDevice, selectedDeviceExpansion).then(response => {
			dispatch(retrieveStatusPointsByTypeAction(response.Entity ?? defaultDataStatusPointByType));
		});
	};
};

export const updateStatusViewerColumnConfigurationState = (
	statusViewerColumnConfiguration: Record<StatusObjectEnum, GridColumn[]>
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(updateStatusViewerColumnConfigurationStateAction(statusViewerColumnConfiguration));
	};
};

export const saveStatusViewerColumnConfiguration = (
	statusViewerColumnConfiguration: Record<StatusObjectEnum, GridColumn[]>,
	notificationStatus: StatusGroupNotificationStatus
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.saveStatusViewerColumnConfiguration(statusViewerColumnConfiguration).then(response => {
			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					dispatch(saveStatusViewerColumnConfigurationAction(statusViewerColumnConfiguration));
					if (notificationStatus.statusGroupSelect && notificationStatus.statusGroupSelect.Id != -1) {
						switch (notificationStatus.statusGroupSelect.StatusViewerType) {
							case StatusViewerEnum.ShowOnly: {
								dispatch(
									loadStatusViewerByType(
										notificationStatus.statusGroupSelect,
										notificationStatus.statusGroupSelect.PaginationTables[0].PaginationSetting
									)
								);
								break;
							}
							case StatusViewerEnum.StatusGroup: {
								notificationStatus.statusGroupSelect.PaginationTables.forEach(objectType => {
									dispatch(
										retrieveStatusViewerByPagination(
											notificationStatus.statusGroupSelect.Id,
											objectType.ObjectTypeId,
											objectType.PaginationSetting
										)
									);
								});
								break;
							}
						}
					}
					notificationStatus.onHandleCloseModal();
				},
				onFailedValidation: () => {},
				onPermissionErrorCallback: async () => {
					notificationStatus.permissionErrorCallback(response.ErrorMessage);
				},
			});
		});
	};
};

export const loadStatusViewerStatusGroup = (
	statusGroupSelected: StatusGroupSelected,
	paginationSetting: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusViewer(statusGroupSelected.Id, paginationSetting).then(response => {
			batch(() => {
				if (response && response.ResponseStatusCode === ResponseStatusCode.Success) {
					dispatch(retrieveStatusViewerAction(response.Entity));
					dispatch(
						selectedStatusGroupAction({
							...statusGroupSelected,
							PaginationTables: response.Entity.StatusViewerItems.map<PaginationTable>(m => ({
								ObjectTypeId: m.ObjectId,
								PaginationSetting: paginationSetting,
							})),
						})
					);
				}
				dispatch(setLoadingStatusViewerTable(false));
			});
		});
	};
};

export const getStatusViewerStatusGroup = (statusGroupId: number, paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		const response = await statusViewerApi.retrieveStatusViewer(statusGroupId, paginationSetting);
		if (response && response.ResponseStatusCode == ResponseStatusCode.Success) {
			dispatch(retrieveStatusViewerAction(response.Entity));
		}
	};
};

export const loadStatusViewerConfigurationAction = (statusGroupId: number): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusViewerConfiguration(statusGroupId).then(response => {
			dispatch(retrieveStatusViewerConfigurationAction(response.Entity));
			const whenIsNull: StatusViewerPointByType = {
				StatusViewerPoints: [],
				TotalStatusViewerPoints: 0,
			};
			dispatch(retrieveStatusPointsByTypeAction(whenIsNull));
		});
	};
};

export const loadStatusViewerColumnConfigurationAction = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusViewerColumnConfiguration().then(response => {
			dispatch(retrieveStatusViewerColumnConfigurationAction(response.Entity));
		});
	};
};

export const loadRetrieveStatusPointsByTypeAction = (
	objectId: number,
	targetKeys: string[],
	paginationSetting: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(retrieveStatusPointsByTypeAction(await getStatusViewerPointsByType(objectId, targetKeys, paginationSetting, false)));
	};
};

const getStatusViewerPointsByType = async (objectId: number, targetKeys: string[], paginationSetting: PaginationSetting, fastCheckedTotalItems: boolean) => {
	const { Item1: selectedDevice, Item2: selectedDeviceExpansion } = getDevicesIdsBy(objectId, targetKeys);

	const response = await statusViewerApi.retrieveStatusPointsByType(objectId, paginationSetting, selectedDevice, selectedDeviceExpansion);
	if (response && response.Entity) {
		if (paginationSetting.PageNumber > 1 && response.Entity.StatusViewerPoints?.length === 0) {
			if (!fastCheckedTotalItems) {
				paginationSetting.PageNumber = (await getMaxPageInQuery(objectId, targetKeys, { ...paginationSetting, PageNumber: 1 })) + 1;
			}

			return await getStatusViewerPointsByType(objectId, targetKeys, { ...paginationSetting, PageNumber: paginationSetting.PageNumber - 1 }, true);
		} else {
			return response.Entity;
		}
	} else {
		const whenIsNull: StatusViewerPointByType = {
			StatusViewerPoints: [],
			TotalStatusViewerPoints: 0,
		};

		return whenIsNull;
	}
};

const getMaxPageInQuery = async (objectId: number, targetKeys: string[], paginationSetting: PaginationSetting) => {
	const { Item1: selectedDevice, Item2: selectedDeviceExpansion } = getDevicesIdsBy(objectId, targetKeys);
	let maxPageNumber = 1;

	const response = await statusViewerApi.retrieveStatusPointsByType(objectId, paginationSetting, selectedDevice, selectedDeviceExpansion);
	const totalItems = response?.Entity?.TotalStatusViewerPoints;

	if (totalItems != 0) {
		maxPageNumber = Math.ceil(totalItems / paginationSetting.PageSize);
	}

	return maxPageNumber;
};

export const setSelectedStatusGroup = (statusGroupSelected: StatusGroupSelected): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(selectedStatusGroupAction(statusGroupSelected));
	};
};

export const loadStatusViewerByType = (
	statusGroupSelected: StatusGroupSelected,
	pagination: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusViewerByType(statusGroupSelected.Id, pagination).then(response => {
			batch(() => {
				dispatch(retrieveStatusViewerByTypeAction(response && response.Entity ? response.Entity : defaultData));
				dispatch(
					selectedStatusGroupAction({
						...statusGroupSelected,
						PaginationTables: [{ ObjectTypeId: statusGroupSelected.Id, PaginationSetting: pagination }],
					})
				);
			});
		});
	};
};

export const getStatusViewerByType = (
	statusViewerObjectTypeId: number,
	paginationSetting: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		const response = await statusViewerApi.retrieveStatusViewerByType(statusViewerObjectTypeId, paginationSetting);

		if (response && response.ResponseStatusCode === ResponseStatusCode.Success) {
			dispatch(retrieveStatusViewerByTypeAction(response.Entity));
		}
	};
};

export const loadStatusGroups = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.retrieveStatusGroups().then(response => {
			dispatch(retrieveStatusGroupAction(response));
		});
	};
};

export const newStatusViewerGroup = (
	statusViewerGroup: StatusViewerGroup,
	notificationStatus: StatusGroupNotificationStatus
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.newStatusViewerGroup(statusViewerGroup).then(response => {
			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					notificationStatus.onSetVisible();
					dispatch(loadStatusGroups());
					notificationStatus.shouldResetSearchColumn(statusViewerGroup);
					notificationStatus.onHandleCloseModal();
				},
				onFailedValidation: () => {
					notificationStatus.setNameError(response.ErrorMessage);
				},
				onPermissionErrorCallback: async () => {
					notificationStatus.permissionErrorCallback(response.ErrorMessage);
				},
				onAdditionalInfoRequiredCallback: async () => {
					notificationStatus.onSetStatusPointsError(response.ErrorMessage);
				},
			});
		});
	};
};

export const updateStatusViewerGroup = (
	statusViewerGroup: StatusViewerGroup,
	notificationStatus: StatusGroupNotificationStatus
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.updateStatusViewerGroup(statusViewerGroup).then(response => {
			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					notificationStatus.onSetVisible();
					dispatch(editStatusViewerGroupAction(statusViewerGroup));
					dispatch(
						loadStatusViewerStatusGroup(
							{
								Id: statusViewerGroup.StatusGroupId,
								Name: statusViewerGroup.Name,
								IsStatusViewer: true,
								PaginationTables: [],
								StatusViewerType: StatusViewerEnum.StatusGroup,
							},
							notificationStatus.paginationSetting
						)
					);
					notificationStatus.shouldResetSearchColumn(statusViewerGroup);
					notificationStatus.onHandleCloseModal();
				},
				onFailedValidation: () => {
					notificationStatus.setNameError(response.ErrorMessage);
				},
				onPermissionErrorCallback: async () => {
					notificationStatus.permissionErrorCallback(response.ErrorMessage);
				},
				onAdditionalInfoRequiredCallback: async () => {
					notificationStatus.onSetStatusPointsError(response.ErrorMessage);
				},
			});
		});
	};
};

export const deleteStatusViewerGroup = (statusGroupSelected: StatusGroupSelected, notificationStatus): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		statusViewerApi.deleteStatusViewerGroup(statusGroupSelected).then(response => {
			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					dispatch(deleteStatusViewerAction(statusGroupSelected));
					notificationStatus.onSetDefaultStates();
				},
				onFailedValidation: () => {
					notificationStatus.setNameError(response.ErrorMessage);
				},
				onPermissionErrorCallback: async () => {
					notificationStatus.permissionErrorCallback(response.ErrorMessage);
				},
			});
		});
	};
};

export const cleanStatusViewerTable = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(cleanStatusViewerAction(defaultData));
	};
};

export const setLoadingStatusViewerTable = (isLoading: boolean): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(loadingStatusViewerTableAction(isLoading));
	};
};

export const setControllerToEdit = (controllerToEdit: ControllerToEdit): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, StatusViewerAction>): Promise<void> => {
		dispatch(setControllerToEditAction(controllerToEdit));
	};
};

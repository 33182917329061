import { Spin } from 'antd';
import React from 'react';
import { Modal } from '../../common';
import styles from './modalLoading.module.scss';

type Props = {
	visible: boolean;
};

const ModalLoading: React.FC<Props> = ({ visible, children }) => {
	return (
		<Modal className={styles.modalAutoZoom} closable={false} keyboard={false} maskClosable={false} visible={visible} footer={null} width='400px'>
			<div id='portConfiguringPortContainer' className={styles.container}>
				<span className={styles.loadingText}>{children}</span> <Spin />
			</div>
		</Modal>
	);
};

export { ModalLoading };

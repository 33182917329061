import { createSelector } from 'reselect';
import { ControlZone, MasterControlZonesEditor, SelectOption, SelectTimeZone, StandardControlZonesEditor } from '../../../../../../model/DeviceAdminModel';
import { NewTimeZone } from '../../../../../../store/deviceControl/controller/actionTypes';
import { RootReducer } from '../../../../../../store/rootReducer';

export const selectorMasterControlZonesEditor = createSelector(
	(state: RootReducer) => state.controlZonesReducer.MasterControlZonesEditor,
	(masterControlZonesEditor: { [index: string]: MasterControlZonesEditor }) => masterControlZonesEditor
);

export const selectorMasterZonesById = createSelector(
	(state: RootReducer) => state.controlZonesReducer.MasterControlZonesEditorDataById,
	(masterControlZonesEditorDataById: number[]) => [...masterControlZonesEditorDataById]
);

export const selectorMasterHeaders = createSelector(
	(state: RootReducer) => state.controlZonesReducer.MasterControlZonesHeader,
	(masterControlZonesHeader: string[]) => [...masterControlZonesHeader]
);

export const selectorThreatLevels = createSelector(
	(state: RootReducer) => state.controlZonesReducer.ThreatLevels,
	(threatLevels: SelectOption[]) => [...threatLevels]
);

export const selectorGlobalId = createSelector(
	(state: RootReducer) => state.controlZonesReducer.GlobalIdList,
	(globalIdList: SelectOption[]) => globalIdList
);

export const selectorTimeZones = createSelector(
	(state: RootReducer) => state.controllerReducer.GenericTimeZones,
	(genericTimeZones: SelectTimeZone[]) => genericTimeZones
);

export const selectorMasterControlZones = createSelector(
	(state: RootReducer) => state.controlZonesReducer.MasterControlZonesIndexes,
	(masterControlZonesIndexes: SelectOption[]) => masterControlZonesIndexes
);

export const selectorStandardControlZones = createSelector(
	(state: RootReducer) => state.controlZonesReducer.StandardControlZonesEditor,
	(standardControlZonesEditor: { [index: string]: StandardControlZonesEditor }) => standardControlZonesEditor
);

export const selectorMasterZonesActions = createSelector(
	(state: RootReducer) => state.controlZonesReducer.MasterControlZonesActions,
	(masterControlZonesActions: string[]) => masterControlZonesActions
);

export const selectorStandardControlZonesList = createSelector(
	(state: RootReducer) => state.controlZonesReducer.StandardControlZonesList,
	(standardControlZonesList: ControlZone[]) => standardControlZonesList
);

export const selectorStandardControlZonesById = createSelector(
	(state: RootReducer) => state.controlZonesReducer.StandardControlZonesEditorDataById,
	(standardControlZonesEditorDataById: number[]) => standardControlZonesEditorDataById
);

export const selectorStandardHeaders = createSelector(
	(state: RootReducer) => state.controlZonesReducer.StandardControlZonesHeader,
	(standardControlZonesHeader: string[]) => standardControlZonesHeader
);

export const selectorControllerId = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerId,
	(controllerId: number) => controllerId
);

export const selectorControllerTimeZoneMap = createSelector(
	(state: RootReducer) => state.controlZonesReducer.ControllerTimeZoneMap,
	(controllerTimeZoneMap: number[]) => controllerTimeZoneMap
);

export const selectorNewTimeZoneAdded = createSelector(
	(state: RootReducer) => state.controllerReducer.newTimeZoneAddedId,
	(newTimeZoneAddedId: number) => newTimeZoneAddedId
);

export const selectorTimeZoneModal = createSelector(
	(state: RootReducer) => state.controllerReducer.openTimeZone,
	(openTimeZone: NewTimeZone) => openTimeZone
);

export const selectorEditionMode = createSelector(
	(state: RootReducer) => state.controlZonesReducer.editMode,
	(editMode: boolean) => editMode
);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination-select-select--1kyKsfY3a-8QSu8EtxP0D{display:flex}", "",{"version":3,"sources":["webpack://src/components/common/PaginationSelect/paginationSelect.module.scss"],"names":[],"mappings":"AAAA,iDACC,YAAA","sourcesContent":[".select {\r\n\tdisplay: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "pagination-select-select--1kyKsfY3a-8QSu8EtxP0D"
};
export default ___CSS_LOADER_EXPORT___;

import { VelocityDevice } from './DeviceAdminModel';
import { CredentialResponse, PersonDetailModel } from './EnrollmentModel';
import { VelocityEventModel } from './EventModel';
import { UDFGroupModel } from './UserDefinedFields';

export class PhotoCallUpBase {
	QuantityPerDoor: number;
	ShowAllDoorsSection: boolean;
	ShowDoorName: boolean;
	ShowDateTime: boolean;
	ShowBorderCard: boolean;
	ShowDoorsInFullWidth: boolean;
	LoadCachedEntries: boolean;
	SelectedDoors: AvailableDoorInfo[];
}

export interface PhotoCallUpResponse extends PhotoCallUpBase {
	BlankProfilePictureBase64?: string;
	BlankProfileSignatureBase64?: string;
	PhotoCallUps: PhotoCallUp[];
}

export class AvailableDoorInfo {
	DoorId: number;
	ControllerId: number;
	DoorName: string;
	ControllerName: string;
	Color: string;
	IsHidden: boolean;

	public static getFullName = (doorInfo: AvailableDoorInfo): string => {
		return `${doorInfo.DoorName} - ${doorInfo.ControllerName}`;
	};
}

export type PhotoCallUp = {
	LogId: number;
	EventId: number;
	DoorId: number;
	UsersInformation: PhotoCallUpUserInformation[];
	DoorPhotoCallUpTemplateId: number;
	DoorAccess: string;
	ControllerName: string;
	HardwareTime: Date;
	HostTime: Date;
	OffsetHardwareTime: Date;
	AccessGranted: boolean;
};

export type PhotoCallUpUserInformation = {
	PersonId: number;
	PersonFullName: string;
	CredentialID: number;
	PhotoCredentialBase64: string;
};

export class PhotoCallUpUserConfiguration extends PhotoCallUpBase {
	ControllersForDoors: VelocityDevice[];

	public static isValid = (photoCallUpUserConfiguration: PhotoCallUpUserConfiguration): any => {
		let errors = {
			isValid: true,
			SelectedDoors: '',
			QuantityPerDoor: '',
			Error: '',
		};

		if (photoCallUpUserConfiguration === null) {
			errors.Error = _('PhotoCallUpConfigurationInvalidData');
			errors.isValid = false;
		} else {
			let isValid: boolean = true;
			const { SelectedDoors, QuantityPerDoor } = photoCallUpUserConfiguration;
			if (SelectedDoors && SelectedDoors.filter(d => Number(d) > 0).length > 10) {
				isValid = false;
				errors.SelectedDoors = _('Max10DoorsSelected');
			}

			if (QuantityPerDoor < 1 || QuantityPerDoor > 10) {
				isValid = false;
				errors.QuantityPerDoor = _('Max10RecentCredentialsPerDoor');
			}

			errors.isValid = isValid;
		}

		return errors;
	};
}

export type PersonInfo = {
	PersonId: number;
	CredentialId: number;
	LogId: number;
};

export type PhotoCallUpUserInformationModel = {
	Event: VelocityEventModel;
	CredentialDetail: CredentialResponse;
	PersonAccessInfo: PersonInfo;
	Tabs: UDFGroupModel[];
	GeneralTab: UDFGroupModel;
	PersonDetailModel: PersonDetailModel;
};

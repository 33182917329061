import { Spin } from 'antd';
import React from 'react';
import { useStoreSelector } from '../../../../store';
import { selectIsLoading } from '../../../../store/common/selectors';
import styles from './withLoader.module.scss';

/**
 *
 * @param WrappedComponent component to be showed after loading is true
 */
const WithLoader = <T extends {}>(WrappedComponent: React.ComponentType<T>) => {
	const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

	const ComponentWithLoader: React.ComponentType<T> = props => {
		const isLoading: boolean = useStoreSelector<boolean>(selectIsLoading);
		return (
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
				<WrappedComponent {...(props as T)} />
			</Spin>
		);
	};

	ComponentWithLoader.displayName = `withLoader(${displayName})`;

	return ComponentWithLoader;
};

export { WithLoader };

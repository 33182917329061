import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../Helper';
import { setErrors, setName, StoreContext } from '../../contextReader';
import styles from './nameInput.module.scss';

const NameInput: React.FC = () => {
	const {
		readerState: {
			name,
			errors: { nameInput },
		},
		dispatcher,
	} = useContext(StoreContext);

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		dispatcher(setName(value));
		dispatcher(setErrors({ value: '', type: 'nameInput' }));
	};

	const displayName: boolean = nameInput !== '';
	const readerControlGroupNameInputId = 'readerControlGroupNameInput';

	return (
		<div className={styles.container}>
			<label htmlFor={readerControlGroupNameInputId}>{_('Name')}</label>
			<div className={cx({ [styles.error]: displayName })}>
				<Input id={readerControlGroupNameInputId} onChange={handleChangeName} value={name} maxLength={64} />
				{displayName && (
					<label id='readerControlGroupNameErrorLabel' htmlFor={readerControlGroupNameInputId}>
						{nameInput}
					</label>
				)}
			</div>
		</div>
	);
};

export { NameInput };

import { FilterOutlined, SettingFilled } from '@ant-design/icons';
import { Button, Spin, Tooltip } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	applyCurrentEventFilters,
	buildColumn,
	getColumnWidth,
	getCurrentEventFilterPage,
	getEventFilterOptions,
	handleResponse,
	parseDateTimeString,
	useDispatch,
} from '../../../Helper';
import { eventApi } from '../../../api';
import { SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../../model/AccountModel';
import { GridDataColumnItem, ResponseStatusCode, SelectOptions, Tuple } from '../../../model/CommonModel';
import {
	CustomFilterGroup,
	EventColors,
	EventFilter,
	EventFilterPage,
	EventFilterTypes,
	EventViewerActions,
	VelocityEventModel,
} from '../../../model/EventModel';
import { Logger } from '../../../model/LoggingModel';
import { useStoreSelector } from '../../../store';
import { selectEventList } from '../../../store/common/selectors';
import {
	setConfigurationResponse,
	setCustomFilterGroupsResponseAction,
	setFiltersResponse,
	setSelectedCustomFilterGroupsResponseAction,
} from '../../../store/event/actions';
import { selectCustomFilterGroups, selectEventFilters, selectSelectedCustomFilterGroups } from '../../../store/event/selectors';
import { ColumnConfiguration, HeaderBar, Select, VirtualTable } from '../../common';
import { ModalConfigureFilters } from '../ModalConfigureFilters/ModalConfigureFilters';
import { CustomFilterSelect } from './CustomFilterSelect';
import styles from './eventTable.module.scss';

type VelocityEventModelDataSource = {
	key: React.Key;
} & VelocityEventModel;

const user: User = getUser();
const canViewEvents: boolean = User.getSubComponentPermission(user, SubPermissions.EventViewer_Use).allowed;
const canChangeProperties: boolean = User.getSubComponentPermission(user, SubPermissions.EventViewer_ChangeProperties).allowed;
const { useVelocityEventColors, eventsPerPage, eventsPerEventViewer } = user;
const colors = getColors();
const eventFilterOptions: Tuple[] = getEventFilterOptions();
const eventFilterByTypeId: string = 'eventFilterByTypeDropdown';
const configureFilterButton: string = 'ConfigureFilterButton';
const customFilterGroupsId: string = 'customFilterGroupsDropdown';
const eventsWidth: Record<keyof Pick<VelocityEventModelDataSource, 'HostTime' | 'HardwareTime' | 'Description' | 'Address' | 'EventType' | 'EventId'>, string> =
	{
		HostTime: 'minmax(11em, 13em)',
		HardwareTime: 'minmax(11em, 13em)',
		Description: 'minmax(13em, auto)',
		Address: 'minmax(14em, 15em)',
		EventType: 'minmax(11em, 12em)',
		EventId: 'minmax(6em, 7em)',
	};

const EventTable: React.FC = () => {
	if (!canViewEvents) {
		return <></>;
	}

	const dispatch = useDispatch();
	const [dataSource, setDataSource] = useState<VelocityEventModelDataSource[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [suspendMode, setSuspendMode] = useState<boolean>(false);
	const [maxHeight, setMaxHeight] = useState<number>(200);
	const [currentPageSize, setCurrentPageSize] = useState<number>(eventsPerPage);
	const [tableColumns, setTableColumns] = useState<ColumnsProps<VelocityEventModelDataSource>[]>([]);
	const [visibleEventModalColumns, setVisibleEventModalColumns] = useState<boolean>(false);
	const [visibleConfigureFiltersModal, setVisibleConfigureFiltersModal] = useState<boolean>(false);
	const [updateCounter, setUpdateCounter] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);

	const eventList: VelocityEventModel[] = useStoreSelector<VelocityEventModel[]>(selectEventList);
	const currentEventFilters: EventFilter[] = useStoreSelector<EventFilter[]>(selectEventFilters);
	const currentCustomFilterGroups: CustomFilterGroup[] = useStoreSelector<CustomFilterGroup[]>(selectCustomFilterGroups);
	const currentSelectedCustomFilterGroups: CustomFilterGroup[] = useStoreSelector<CustomFilterGroup[]>(selectSelectedCustomFilterGroups);

	const pendingEvents = useRef<VelocityEventModel[]>([]);

	useEffect(() => {
		if (eventList && eventList.length > 0) {
			if (suspendMode && pendingEvents?.current) {
				pendingEvents.current.unshift(...eventList);
			} else {
				addData(eventList, true);
			}
		}
	}, [eventList]);

	useEffect(() => {
		if (currentEventFilters !== undefined) {
			fetchData(1, true);
		}
	}, [currentEventFilters, updateCounter]);

	useEffect(() => {
		if (!suspendMode && pendingEvents?.current.length > 0) {
			addData(pendingEvents.current, true);
			pendingEvents.current = [];
		}
	}, [suspendMode]);

	useEffect(() => {
		refreshEventsConfigureFilters().then(() => {
			let maxHeight: number = 300;
			const currentEventPage: number = Number(EventFilterPage[getCurrentEventFilterPage()]);

			switch (currentEventPage) {
				case EventFilterPage.Events:
					maxHeight = 700;
					break;

				case EventFilterPage.StatusDashboard:
					maxHeight = 280;
					break;
			}

			setMaxHeight(maxHeight);
		});
	}, []);

	const fetchData = (pageNumber: number, reset: boolean = false) => {
		if (!suspendMode && (reset || dataSource?.length < eventsPerEventViewer)) {
			setCurrentPage(pageNumber);
			setIsLoading(true);
			eventApi
				.retrieveStoredEvents(
					pageNumber,
					currentPageSize,
					currentEventFilters.map(x => x.EventFilterTypes)
				)
				.then(response => {
					addData(response.EventList, false, reset);
				})
				.catch(e => Logger.writeErrorLog(e))
				.finally(() => setIsLoading(false));
		}
	};

	const addData = (eventList: VelocityEventModel[], addToTop: boolean, reset: boolean = false) => {
		let updatedDataState: VelocityEventModel[] = [];
		if (reset) {
			updatedDataState = [...eventList];
		} else {
			if (addToTop) {
				updatedDataState = [...eventList, ...dataSource];
			} else {
				updatedDataState = [...dataSource, ...eventList];
			}
		}

		updatedDataState = applyCurrentEventFilters(updatedDataState, currentEventFilters, currentSelectedCustomFilterGroups);
		if (updatedDataState.length > eventsPerEventViewer) {
			updatedDataState = updatedDataState.slice(0, eventsPerEventViewer);
		}

		setDataSource(createTableData(updatedDataState));
	};

	const createTableData = (updatedDataState: VelocityEventModel[]): VelocityEventModelDataSource[] => {
		return updatedDataState.map<VelocityEventModelDataSource>(x => ({
			...x,
			key: `${x.LogId}|${x.EventId}|${x.HostTime}|${x.EventType}|${x.Address}`,
			HexColor: colors[x.EventColor],
		}));
	};

	const columnsWidth: CSSProperties = useMemo(
		() => getColumnWidth<VelocityEventModelDataSource>(tableColumns, eventsWidth as Record<keyof VelocityEventModelDataSource, string>),
		[tableColumns]
	);

	const contextMenuOptions: SelectOptions<number>[] = [
		{
			id: 'eventsSuspendScrollingOption',
			label: suspendMode ? _('ResumeScrolling') : _('SuspendScrolling'),
			value: EventViewerActions.Suspend,
		},
	];

	const handleActionScope = async (key: number): Promise<void> => {
		switch (key) {
			case EventViewerActions.Suspend:
				setSuspendMode(prevState => !prevState);
				return;
		}
	};

	const handleOnChangeCustomFilterGroups = (values: any[]): void => {
		let customFilterGroups: CustomFilterGroup[] = [];
		values.forEach(x => {
			const item = currentCustomFilterGroups.find(o => o.FilterGroupId.toString() === x || o.FilterGroupId === x);
			if (item) {
				customFilterGroups.push({ ...item });
			}
		});

		eventApi
			.saveEventFilters(
				customFilterGroups?.map(x => x.FilterGroupId),
				currentEventFilters?.map(x => x.EventFilterTypes)
			)
			.then(response => {
				if (!handleResponse(response)) {
					dispatch(setSelectedCustomFilterGroupsResponseAction(customFilterGroups));
					setUpdateCounter(prevState => prevState + 1);
				}
			});
	};

	const handleOnChangeEventFilterType = (values: any[]): void => {
		const eventFiltersType: EventFilterTypes[] = values.map(x => {
			const item: Tuple = eventFilterOptions.find(o => x.toString() === o.Item1.toString() || x.toString() === o.Item2);

			return item.Item1;
		});

		eventApi
			.saveEventFilters(
				currentSelectedCustomFilterGroups?.map(x => x.FilterGroupId),
				eventFiltersType
			)
			.then(response => {
				if (!handleResponse(response)) {
					dispatch(setFiltersResponse(response.Entity));
				}
			});
	};

	const handleOnShowHeaders = (visible: boolean) => {
		setVisibleEventModalColumns(visible);
	};

	const configureFiltersModal = (visible: boolean) => {
		setVisibleConfigureFiltersModal(visible);
	};

	const refreshEventsConfigureFilters = async () => {
		eventApi
			.retrieveEventFilterConfiguration()
			.then(response => {
				if (response.ResponseStatusCode === ResponseStatusCode.Success) {
					const configuration = response.Entity;
					setCurrentPageSize(configuration.EventsPerPage);
					const columns = configuration.Columns.filter(f => !f.IsAuxColumn).map<ColumnsProps<VelocityEventModelDataSource>>(
						(c: GridDataColumnItem) => {
							return {
								...buildColumn(c.Value, c.DataIndex, undefined, 'start'),
								key: c.Key,
								render: (value, row) => {
									const style: CSSProperties = { color: useVelocityEventColors ? `#${row.HexColor}` : '#FFFFFF' };
									let text = value;
									if (c.IsDateFormat) {
										text = parseDateTimeString(value, false, value, true);
									} else if (c.DataIndex === 'EventType') {
										text = _(row.EventTypeTranslation);
									}

									return <span style={style}>{text}</span>;
								},
							};
						}
					);
					setTableColumns(columns);
					dispatch(setConfigurationResponse(configuration));
				} else {
					handleResponse(response);
				}
			})
			.catch(e => Logger.writeErrorLog(e));
	};

	const sortCustomFilterGroups = (customFilterGroups: CustomFilterGroup[]): CustomFilterGroup[] => {
		return customFilterGroups.sort((a: CustomFilterGroup, b: CustomFilterGroup) => {
			const firstValue: string = a.GroupName;
			const secondValue: string = b.GroupName;

			return secondValue.localeCompare(firstValue);
		});
	};

	const refreshConfiguration = async (action: string, sender: Partial<CustomFilterGroup>) => {
		let updatedState: CustomFilterGroup[] = [];
		switch (action) {
			case 'deleted':
				{
					if (currentSelectedCustomFilterGroups.find(el => el.FilterGroupId === sender.FilterGroupId)) {
						await refreshEventsConfigureFilters().then(() => {
							fetchData(1, true);
						});
					} else {
						batch(() => {
							updatedState = currentCustomFilterGroups.filter(x => x.FilterGroupId !== sender.FilterGroupId);
							dispatch(setCustomFilterGroupsResponseAction(updatedState));

							updatedState = currentSelectedCustomFilterGroups.filter(x => x.FilterGroupId !== sender.FilterGroupId);
							dispatch(setSelectedCustomFilterGroupsResponseAction(updatedState));
						});
					}
				}
				break;

			case 'modified':
				{
					if (currentSelectedCustomFilterGroups.find(el => el.FilterGroupId === sender.FilterGroupId)) {
						await refreshEventsConfigureFilters().then(() => {
							fetchData(1, true);
						});
					} else {
						let exists: boolean = false;
						updatedState = currentCustomFilterGroups.map(x => {
							if (x.FilterGroupId === sender.FilterGroupId) {
								exists = true;
								return { ...x, ...(sender as CustomFilterGroup) };
							}

							return { ...x };
						});

						if (!exists) {
							updatedState.push(sender as CustomFilterGroup);
						}

						updatedState = sortCustomFilterGroups(updatedState);
						dispatch(setCustomFilterGroupsResponseAction(updatedState));
					}
				}
				break;

			case 'renamed':
				{
					batch(() => {
						updatedState = sortCustomFilterGroups(
							currentCustomFilterGroups.map(x => {
								if (x.FilterGroupId === sender.FilterGroupId) {
									return { ...x, GroupName: sender.GroupName };
								}

								return { ...x };
							})
						);
						dispatch(setCustomFilterGroupsResponseAction(updatedState));

						updatedState = sortCustomFilterGroups(
							currentSelectedCustomFilterGroups.map(x => {
								if (x.FilterGroupId === sender.FilterGroupId) {
									return { ...x, GroupName: sender.GroupName };
								}

								return { ...x };
							})
						);
						dispatch(setSelectedCustomFilterGroupsResponseAction(updatedState));
					});
				}
				break;
		}
	};

	const headerBarColumnHeadersButton = (
		<Tooltip placement='top' title={_('ConfigureColumnHeaders')}>
			<Button
				type='text'
				className={styles.statusGroupConfigureButton}
				icon={<SettingFilled />}
				id={'EventConfigureEventColumnButton'}
				key='EventConfigureEventColumn'
				onClick={() => handleOnShowHeaders(true)}
			/>
		</Tooltip>
	);

	const suspendHexColor: string = colors[EventColors.SuspendBackColor];
	const enabledHexColor: string = colors[EventColors.BackColor];
	const backgroundColor: CSSProperties = suspendMode
		? {
				backgroundColor: suspendHexColor === '' ? `#000000` : `#${suspendHexColor}`,
		  }
		: {
				backgroundColor: enabledHexColor === '' ? `#000000` : `#${enabledHexColor}`,
		  };

	const and: string = ' + ';
	const or: string = ', ';
	let headerTitle: string = '';
	if (currentSelectedCustomFilterGroups !== undefined && currentSelectedCustomFilterGroups.length) {
		let filtersApplied: string[] = [];
		currentSelectedCustomFilterGroups.forEach(x => {
			filtersApplied.push(`'${x.GroupName}'`);
		});

		headerTitle = filtersApplied.join(or);
	}

	if (currentEventFilters !== undefined && currentEventFilters.length) {
		let filtersApplied: string[] = [];
		currentEventFilters.forEach(x => {
			if (x.EventFilterTypes !== EventFilterTypes.All) {
				const item: Tuple = eventFilterOptions.find(
					o => x.EventFilterTypes.toString() === o.Item1.toString() || x.EventFilterTypes.toString() === o.Item2
				);
				filtersApplied.push(`'${item.Item2}'`);
			}
		});

		if (headerTitle) {
			headerTitle = `(${headerTitle})${and}(${filtersApplied.join(or)})`;
		} else {
			headerTitle = filtersApplied.join(or);
		}
	}

	let filterIcon: JSX.Element = undefined;
	if (headerTitle) {
		headerTitle = `- ${headerTitle}`;
		filterIcon = <FilterOutlined />;
	}

	let filteredCustomFilterGroups: CustomFilterGroup[] = undefined;
	if (currentCustomFilterGroups) {
		filteredCustomFilterGroups = currentCustomFilterGroups.filter(
			o =>
				currentSelectedCustomFilterGroups === undefined ||
				currentSelectedCustomFilterGroups.length === 0 ||
				currentSelectedCustomFilterGroups.findIndex(x => x.FilterGroupId === o.FilterGroupId) === -1
		);
	}

	const filteredOptions: Tuple[] = eventFilterOptions.filter(
		o =>
			currentEventFilters === undefined ||
			currentEventFilters.length === 0 ||
			currentEventFilters.findIndex(x => x.EventFilterTypes.toString() === o.Item1.toString() || x.EventFilterTypes.toString() === o.Item2) === -1
	);

	const handleOnShowHeadersCallback = useCallback(() => handleOnShowHeaders(false), []);
	const getEventsColumnsConfigurationCallback = useCallback(async () => eventApi.getEventsColumnsConfiguration(), []);
	const refreshEventsConfigureFiltersCallback = useCallback(async () => refreshEventsConfigureFilters(), []);

	return (
		<div id='eventViewerContainer' className={styles.container}>
			<div className={styles.actions}>
				<label htmlFor={customFilterGroupsId}>
					<strong>{_('CustomFilters')}</strong>
				</label>
				<CustomFilterSelect
					customFilterGroupsId={customFilterGroupsId}
					filteredCustomFilterGroups={filteredCustomFilterGroups}
					currentSelectedCustomFilterGroups={currentSelectedCustomFilterGroups}
					handleOnChangeCustomFilterGroups={handleOnChangeCustomFilterGroups}
				/>
				<label htmlFor={eventFilterByTypeId}>
					<strong>{_('FilterEventsByType')}</strong>
				</label>
				<Select
					id={eventFilterByTypeId}
					mode='multiple'
					placeholder={_('FilterEventsByType')}
					options={filteredOptions.map(x => ({ id: x.Item1, value: x.Item1, label: x.Item2 }))}
					key={eventFilterByTypeId}
					value={currentEventFilters?.map(x => {
						const item: Tuple = eventFilterOptions.find(
							o => x.EventFilterTypes.toString() === o.Item1.toString() || x.EventFilterTypes.toString() === o.Item2
						);

						const mappedItem: LabeledValue = {
							key: item.Item1,
							value: item.Item1,
							label: item.Item2,
						};

						return mappedItem;
					})}
					onChange={handleOnChangeEventFilterType}
				/>
				<Button
					id={configureFilterButton}
					type='primary'
					disabled={!canChangeProperties}
					title={getPermissionErrorMessage(canChangeProperties)}
					onClick={() => configureFiltersModal(true)}>
					{_('ConfigureFilters')}
				</Button>
			</div>
			<HeaderBar title={`${_('Events')} ${headerTitle}`.trim()} titleElement={filterIcon} buttons={headerBarColumnHeadersButton} />
			<div>
				<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
					<VirtualTable<VelocityEventModelDataSource>
						columns={tableColumns}
						dataSource={dataSource}
						fetchData={fetchData}
						currentPage={currentPage}
						pageSize={currentPageSize}
						columnsWidth={columnsWidth}
						contextMenuOptions={{ options: contextMenuOptions, onClickOption: handleActionScope }}
						backgroundColor={backgroundColor}
						maxHeight={maxHeight}
						isEventViewer={true}
					/>
					{visibleEventModalColumns && (
						<ColumnConfiguration
							onHideModal={handleOnShowHeadersCallback}
							loadColumnConfiguration={getEventsColumnsConfigurationCallback}
							dispatchAction={refreshEventsConfigureFiltersCallback}
							securedComponent={SecuredComponents.Event_Viewer}
						/>
					)}
					{visibleConfigureFiltersModal && <ModalConfigureFilters onSetVisible={configureFiltersModal} onRefreshEvents={refreshConfiguration} />}
				</Spin>
			</div>
		</div>
	);
};

export { EventTable };

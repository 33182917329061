import { DeleteOutlined, EditOutlined, SettingFilled } from '@ant-design/icons';
import { Button, Col, notification, Radio, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { filterOption, getDefaultTablePaginationConfig, statusViewerGroupLockedValidation } from '../../../Helper';
import { getPermissionErrorMessage, SubPermissions, User } from '../../../model/AccountModel';
import { PaginationSetting, SelectOptions, SortDirections } from '../../../model/CommonModel';
import {
	AddItemData,
	ControllerToEdit,
	DeleteItemData,
	ExpandedRowItem,
	StatusGroupSelected,
	StatusObjectEnum,
	StatusViewer,
	StatusViewerChangeType,
	StatusViewerEnum,
	StatusViewerEvent,
	StatusViewerGroup,
	StatusViewerItem,
} from '../../../model/StatusViewerModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { selectStatusViewerEvents } from '../../../store/common/selectors';
import {
	addStatusViewerItemDataAction,
	cleanStatusViewerTable,
	deleteStatusGroupOption,
	deleteStatusViewerGroup,
	deleteStatusViewerItemDataAction,
	getStatusViewerByType,
	getStatusViewerMaskedOnly,
	getStatusViewerStatusGroup,
	loadStatusGroups,
	loadStatusViewerByType,
	loadStatusViewerStatusGroup,
	retrieveExpandedRowStatusGroup,
	retrieveStatusViewerAction,
	retrieveStatusViewerMaskedOnly,
	selectedStatusGroupAction,
	setControllerToEdit,
	setLoadingStatusViewerTable,
	setStatusViewerRealTime,
} from '../../../store/statusViewer/actions';
import {
	selectControllerToEdit,
	selectSelectedStatusGroup,
	selectStatusGroupDoorExpandedRow,
	selectStatusGroups,
	selectStatusViewer,
} from '../../../store/statusViewer/selectors';
import { HeaderBar } from '../../common/HeaderBar/HeaderBar';
import { ModalConfirmationList } from '../../common/ModalConfirmationList/ModalConfirmationList';
import { Select, SelectOption } from '../../common/Select/Select';
import { canAddRealTimeEventItem, canDeleteRealTimeEventItem, isSameGridDataItem } from '../helpers';
import { StatusGroupConfiguration } from '../StatusGroupConfiguration/StatusGroupConfiguration';
import { StatusViewerModalColumns } from '../StatusViewerModalColumns/StatusViewerModalColumns';
import styles from './statusViewerToolBar.module.scss';

type Props = {
	optionShowOnlySelection: string;
	optionStatusGroupSelection: string;
	showOnlyOptionsAll: SelectOptions<string>[];
	componentAllOptions: SelectOptions<string>[];
	noneElement: SelectOptions<string>[];
	setOptionShowOnlySelection: (value: string) => void;
	setOptionStatusGroupSelection: (value: string) => void;
};

const noneHeader: string = `<${_('None')}>`;
const paginationSetting: PaginationSetting = {
	SortField: null,
	SortDirection: SortDirections.None,
	PageNumber: getDefaultTablePaginationConfig().defaultCurrent,
	PageSize: getDefaultTablePaginationConfig().defaultPageSize,
};

const user: User = getUser();
const canAddEditDeleteStatusGroups: boolean = User.getSubComponentPermission(
	user,
	SubPermissions.StatusViewer_StatusViewer_AddEditandDeleteStatusGroups
).allowed;
const noneOptionId = '-1';
const StatusViewerToolBar = ({
	optionShowOnlySelection,
	optionStatusGroupSelection,
	showOnlyOptionsAll,
	componentAllOptions,
	noneElement,
	setOptionShowOnlySelection,
	setOptionStatusGroupSelection,
}: Props) => {
	const [visibleAddNewStatusGroupModal, setVisibleAddNewStatusGroupModal] = useState(false);
	const [visibleStatusViewerModalColumns, setVisibleStatusViewerModalColumns] = useState(false);
	const [optionSelection, setOptionSelection] = useState(StatusViewerEnum.ShowOnly);
	const [statusViewerSelected, setStatusViewerSelected] = useState(noneHeader);
	const [hasSelectedStatusViewer, setHasSelectedStatusViewer] = useState(false);
	const [isDisabledShowOnly, setIsDisabledShowOnly] = useState(false);
	const [isDisabledStatusGroup, setIsDisabledStatusGroup] = useState(true);
	const [isEdit, setIsEdit] = useState(false);
	const [statusGroupOptions, setStatusGroupOptions] = useState<SelectOption>([]);
	const [statusViewerGroupToEdit, setStatusViewerGroupToEdit] = useState<StatusViewerGroup>(undefined);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const selectedStatusGroup = useStoreSelector(selectSelectedStatusGroup);
	const statusGroupDoorExpandedRow: ExpandedRowItem[] = useStoreSelector(selectStatusGroupDoorExpandedRow);

	const dispatch = useStoreDispatch();
	const statusViewerEvents: StatusViewerEvent[] = useStoreSelector<StatusViewerEvent[]>(selectStatusViewerEvents);
	const statusGroups: StatusViewerGroup[] = useStoreSelector<StatusViewerGroup[]>(selectStatusGroups);
	const controllerToEdit: ControllerToEdit = useStoreSelector(selectControllerToEdit);
	const statusViewer: StatusViewer = useStoreSelector(selectStatusViewer);

	useEffect(() => {
		if (statusViewerEvents && statusViewerEvents.length) {
			handleRealTimeEvent();
		}
	}, [statusViewerEvents]);

	useEffect(() => {
		if (statusGroups) {
			const statusGroupOptionsItems = statusGroups.map<SelectOptions<string>>(m => ({
				id: `${m.StatusGroupId}-${m.Name}`,
				label: m.Name,
				value: `${m.StatusGroupId}`,
			}));
			const statusGroupOptionsItemsAll = noneElement.concat(statusGroupOptionsItems);
			setStatusGroupOptions(statusGroupOptionsItemsAll);
		}
	}, [statusGroups]);

	useEffect(() => {
		if (selectedStatusGroup) {
			const headerName: string = selectedStatusGroup.StatusViewerType === StatusViewerEnum.MaskedGroup ? _('Masked') : selectedStatusGroup.Name;
			setStatusViewerSelected(headerName);
		}
	}, [selectedStatusGroup, controllerToEdit.id]);

	useEffect(() => {
		const { id, isModalOpen } = controllerToEdit;
		if (selectedStatusGroup && !id && isModalOpen) {
			dispatch(setControllerToEdit({ ...controllerToEdit, isModalOpen: !controllerToEdit.isModalOpen }));
			const { Id: id, StatusViewerType: statusViewerType, PaginationTables: paginationTables } = selectedStatusGroup;

			setOptionSelection(statusViewerType);
			switch (statusViewerType) {
				case StatusViewerEnum.ShowOnly:
					setOptionShowOnlySelection(id.toString());
					setIsDisabledShowOnly(false);
					setIsDisabledStatusGroup(true);
					const showOnlySelected: StatusGroupSelected = {
						...selectedStatusGroup,
						IsStatusViewer: false,
					};

					dispatch(loadStatusViewerByType(showOnlySelected, paginationTables[0].PaginationSetting));
					break;

				case StatusViewerEnum.StatusGroup:
					setOptionStatusGroupSelection(id.toString());
					setIsDisabledShowOnly(true);
					setIsDisabledStatusGroup(false);
					const statusGroupSelected: StatusGroupSelected = {
						...selectedStatusGroup,
						IsStatusViewer: true,
					};

					dispatch(loadStatusViewerStatusGroup(statusGroupSelected, paginationSetting));
					break;
			}
		}
	}, [controllerToEdit]);

	const dispatchNoneStatusGroupSelected = (noneStatusGroup: StatusGroupSelected) => {
		dispatch(setLoadingStatusViewerTable(true));
		batch(() => {
			dispatch(retrieveStatusViewerAction({ StatusViewerItems: [] } as StatusViewer));
			dispatch(selectedStatusGroupAction(noneStatusGroup));
			dispatch(setLoadingStatusViewerTable(false));
		});
	};

	const setNoneStatusGroup = () => {
		setOptionStatusGroupSelection(noneOptionId);
		setStatusViewerSelected(noneHeader);
		setHasSelectedStatusViewer(false);
		const noneStatusGroup: StatusGroupSelected = {
			Id: Number(noneOptionId),
			Name: noneHeader,
			IsStatusViewer: false,
			PaginationTables: [],
			StatusViewerType: StatusViewerEnum.None,
		};
		dispatchNoneStatusGroupSelected(noneStatusGroup);
	};

	const isStatusGroupSelected = (statusGroupId: number) => {
		return statusGroupId === Number(optionStatusGroupSelection);
	};

	const getStatusViewerItem = (objectType: StatusObjectEnum) => {
		const statusViewerItems: StatusViewerItem[] = statusViewer?.StatusViewerItems;
		return statusViewerItems?.find(statusViewerItem => (statusViewerItem.ObjectId as StatusObjectEnum) === objectType);
	};

	const addEventToShowOnly = (device: StatusViewerItem, realTimeEvent: StatusViewerEvent, objectType: StatusObjectEnum) => {
		const { PaginationSetting: paginationSetting } = device;
		if (canAddRealTimeEventItem(device)) {
			const addItemData: AddItemData = {
				statusViewerEvent: realTimeEvent,
				objectType,
				paginationSetting,
			};
			dispatch(addStatusViewerItemDataAction(addItemData));
		} else {
			syncRealTimeEvent(paginationSetting);
		}
	};

	const deleteEventItem = (device: StatusViewerItem, realTimeEvent: StatusViewerEvent, objectType: StatusObjectEnum) => {
		const { PaginationSetting: paginationSetting, TotalItems } = device;

		if (canDeleteRealTimeEventItem(paginationSetting, TotalItems)) {
			const deviceToDelete: DeleteItemData = {
				deviceId: realTimeEvent.ObjectId,
				objectType,
			};
			dispatch(deleteStatusViewerItemDataAction(deviceToDelete));
		} else {
			syncRealTimeEvent(paginationSetting);
		}
	};

	const addRealTimeEvent = (realTimeEvent: StatusViewerEvent) => {
		const objectType = realTimeEvent.ObjectType as StatusObjectEnum;
		const device = getStatusViewerItem(objectType);
		const selectedOption = getOptionSelection() as StatusViewerEnum;

		if (!device || selectedOption !== StatusViewerEnum.ShowOnly) {
			return;
		}

		switch (objectType) {
			case StatusObjectEnum.Controller:
			case StatusObjectEnum.XBox:
			case StatusObjectEnum.Port:
				addEventToShowOnly(device, realTimeEvent, objectType);
				break;
		}
	};

	const syncRealTimeEvent = (paginationSetting?: PaginationSetting) => {
		const selectedOption = getOptionSelection() as StatusViewerEnum;

		switch (selectedOption) {
			case StatusViewerEnum.ShowOnly:
				const statusViewerObjectTypeId = Number(optionShowOnlySelection);
				dispatch(getStatusViewerByType(statusViewerObjectTypeId, paginationSetting));
				break;

			case StatusViewerEnum.StatusGroup:
				const statusGroupId = Number(optionStatusGroupSelection);
				dispatch(getStatusViewerStatusGroup(statusGroupId, paginationSetting));
				break;

			case StatusViewerEnum.MaskedGroup:
				dispatch(getStatusViewerMaskedOnly(paginationSetting));
				break;
		}
	};

	const deleteRealTimeEvent = (realTimeEvent: StatusViewerEvent) => {
		const objectType = realTimeEvent.ObjectType as StatusObjectEnum;
		const devices = getDevicesInStatusViewer([realTimeEvent]);
		const selectedOption = getOptionSelection() as StatusViewerEnum;

		if (!devices.length && selectedOption !== StatusViewerEnum.MaskedGroup) {
			return;
		}

		switch (objectType) {
			case StatusObjectEnum.Controller:
			case StatusObjectEnum.XBox:
			case StatusObjectEnum.Port:
				deleteEventItem(devices[0], realTimeEvent, objectType);
				break;
		}
	};

	const getDevicesInStatusViewer = (realTimeEvents: StatusViewerEvent[]): StatusViewerItem[] => {
		const devicesInStatusViewer = realTimeEvents.reduce<StatusViewerItem[]>((previousValue, currentValue): StatusViewerItem[] => {
			const item = getStatusViewerItem(currentValue.ObjectType);
			if (item) {
				item.Data.forEach(gridDataItem => {
					const isDeviceInPage =
						currentValue.ObjectId !== 0
							? gridDataItem.some(gridData => isSameGridDataItem(gridData, 'Key', currentValue.ObjectId.toString()))
							: gridDataItem.some(gridData => isSameGridDataItem(gridData, 'Address', currentValue.Address));
					if (isDeviceInPage) {
						previousValue.push(item);
					}
				});
			}
			return previousValue;
		}, []);

		return devicesInStatusViewer;
	};

	const handleDoorExpanded = (realTimeEvents: StatusViewerEvent[]) => {
		const doorEvents = realTimeEvents.filter(event => (event.ObjectType as StatusObjectEnum) === StatusObjectEnum.Door);

		if (!doorEvents && !doorEvents.length) {
			return;
		}

		doorEvents.forEach(door => {
			const doorExpanded = statusGroupDoorExpandedRow.find(doorExpanded => doorExpanded.Address === door.Address && doorExpanded.isExpanded);
			if (doorExpanded) {
				let requestExpanded: ExpandedRowItem = {
					...doorExpanded,
					isLoading: true,
				};
				dispatch(retrieveExpandedRowStatusGroup(requestExpanded));
			}
		});
	};

	const updateRealTimeEvent = (realTimeEvents: StatusViewerEvent[]) => {
		const selectedOption = getOptionSelection() as StatusViewerEnum;

		const devicesInStatusViewer = getDevicesInStatusViewer(realTimeEvents);
		if (!devicesInStatusViewer.length && selectedOption !== StatusViewerEnum.MaskedGroup) {
			return;
		}

		switch (selectedOption) {
			case StatusViewerEnum.ShowOnly:
			case StatusViewerEnum.StatusGroup:
				const validRealTimeEvents = realTimeEvents.filter(x => devicesInStatusViewer.find(i => i.ObjectId === x.ObjectType));
				dispatch(setStatusViewerRealTime(validRealTimeEvents, selectedOption));
				handleDoorExpanded(validRealTimeEvents);
				break;
			case StatusViewerEnum.MaskedGroup:
				dispatch(getStatusViewerMaskedOnly(paginationSetting));
				break;
		}
	};

	const addRealTimeStatusGroup = () => {
		dispatch(loadStatusGroups());
	};

	const updateRealTimeStatusGroup = (realTimeEvent: StatusViewerEvent) => {
		const { ObjectId: statusGroupId } = realTimeEvent;

		if (isStatusGroupSelected(statusGroupId)) {
			const statusGroupSelected = createSelectedStatusGroup(statusGroupId);
			loadStatusGroup(statusGroupSelected);
		}
	};

	const deleteRealTimeStatusGroup = (realTimeEvent: StatusViewerEvent) => {
		const { ObjectId: statusGroupId } = realTimeEvent;
		dispatch(deleteStatusGroupOption(statusGroupId));
		if (isStatusGroupSelected(statusGroupId)) {
			setNoneStatusGroup();
		}
	};

	const handleRealTimeEvent = () => {
		const addEvent = statusViewerEvents.find(x => x.ChangeType === StatusViewerChangeType.AddEvent);
		if (addEvent) {
			addRealTimeEvent(addEvent);
		}

		const syncEvent = statusViewerEvents.find(x => x.ChangeType === StatusViewerChangeType.SyncEvent);
		if (syncEvent) {
			syncRealTimeEvent(paginationSetting);
		}

		const deleteEvent = statusViewerEvents.find(x => x.ChangeType === StatusViewerChangeType.DeleteEvent);
		if (deleteEvent) {
			deleteRealTimeEvent(deleteEvent);
		}

		const updateEvents = statusViewerEvents.filter(x => x.ChangeType === StatusViewerChangeType.UpdateEvent);
		if (updateEvents.length) {
			updateRealTimeEvent(updateEvents);
		}

		const selectedOption = getOptionSelection() as StatusViewerEnum;
		if (selectedOption === StatusViewerEnum.StatusGroup) {
			const addStatusGroup = statusViewerEvents.find(x => x.ChangeType === StatusViewerChangeType.AddStatusGroup);
			if (addStatusGroup) {
				addRealTimeStatusGroup();
			}

			const updateStatusGroup = statusViewerEvents.find(x => x.ChangeType === StatusViewerChangeType.UpdateStatusGroup);
			if (updateStatusGroup) {
				updateRealTimeStatusGroup(updateStatusGroup);
			}

			const deleteStatusGroup = statusViewerEvents.find(x => x.ChangeType === StatusViewerChangeType.DeleteStatusGroup);
			if (deleteStatusGroup) {
				deleteRealTimeStatusGroup(deleteStatusGroup);
			}
		}
	};

	const getOptionSelection: () => number = () => {
		return optionSelection;
	};

	const cleanFunction = (value: StatusViewerEnum) => {
		setOptionSelection(value);
		setStatusViewerSelected(`<${_('None')}>`);
		setHasSelectedStatusViewer(false);
		dispatch(cleanStatusViewerTable());
	};

	const handleOnChangeSelect = e => {
		const statusViewerSelection = e.target.value as StatusViewerEnum;
		switch (statusViewerSelection) {
			case StatusViewerEnum.ShowOnly: {
				setOptionStatusGroupSelection('-1');
				setIsDisabledShowOnly(false);
				setIsDisabledStatusGroup(true);
				cleanFunction(statusViewerSelection);
				break;
			}
			case StatusViewerEnum.StatusGroup: {
				setOptionShowOnlySelection('-1');
				setIsDisabledShowOnly(true);
				setIsDisabledStatusGroup(false);
				cleanFunction(statusViewerSelection);
				break;
			}
			case StatusViewerEnum.MaskedGroup: {
				setOptionShowOnlySelection('-1');
				setOptionStatusGroupSelection('-1');
				setIsDisabledShowOnly(true);
				setIsDisabledStatusGroup(true);
				cleanFunction(statusViewerSelection);
				const selected: StatusGroupSelected = {
					Id: 1,
					StatusViewerType: StatusViewerEnum.MaskedGroup,
				};
				dispatch(retrieveStatusViewerMaskedOnly(selected, paginationSetting));
				break;
			}
		}
	};

	const handleShowOnlyChange = (value: string) => {
		dispatch(setLoadingStatusViewerTable(true));
		setOptionShowOnlySelection(value);
		const name: string = showOnlyOptionsAll.find(f => f.value === value).label;
		setStatusViewerSelected(name);
		const selected: StatusGroupSelected = {
			Id: Number(value),
			Name: name,
			IsStatusViewer: false,
			PaginationTables: [{ ObjectTypeId: Number(value), PaginationSetting: paginationSetting }],
			StatusViewerType: StatusViewerEnum.ShowOnly,
		};
		dispatch(loadStatusViewerByType(selected, paginationSetting));
	};

	const loadStatusGroup = (statusGroupSelected: StatusGroupSelected) => {
		batch(() => {
			dispatch(setLoadingStatusViewerTable(true));
			dispatch(loadStatusViewerStatusGroup(statusGroupSelected, paginationSetting));
		});
	};

	const createSelectedStatusGroup = (statusGroupId: number) => {
		const statusGroupName = statusGroupOptions.find(f => f.value === statusGroupId.toString()).label;

		const statusGroupSelected: StatusGroupSelected = {
			Id: statusGroupId,
			Name: statusGroupName,
			IsStatusViewer: true,
			PaginationTables: [],
			StatusViewerType: StatusViewerEnum.StatusGroup,
		};

		return statusGroupSelected;
	};

	const handleStatusGroupChange = (id: string) => {
		if (id === noneOptionId) {
			setNoneStatusGroup();
			return;
		}
		const statusGroupSelected = createSelectedStatusGroup(Number(id));
		setOptionStatusGroupSelection(id);
		setStatusViewerSelected(statusGroupSelected.Name);
		setHasSelectedStatusViewer(id !== '-1' ? true : false);
		loadStatusGroup(statusGroupSelected);
	};

	const handleAddNewStatsGroup = e => {
		e.stopPropagation();
		setIsEdit(false);
		setVisibleAddNewStatusGroupModal(true);
	};

	const handleConfigureStatusViewerColumn = e => {
		setVisibleStatusViewerModalColumns(true);
	};

	const handleSetModalStatusGroupConfigurationVisible = (visible: boolean, statusViewerGroup?: StatusViewerGroup) => {
		setStatusViewerGroupToEdit(statusViewerGroup);
		setVisibleAddNewStatusGroupModal(visible);
	};

	const handleSetModalStatusGroupColumnsVisible = (visible: boolean) => {
		setVisibleStatusViewerModalColumns(visible);
	};

	const onHandleEditStatusViewer = () => {
		setIsEdit(true);
		const statusViewerName: string = statusGroupOptions.find(f => f.value === optionStatusGroupSelection).label;
		const statusViewerSelect: StatusViewerGroup = {
			StatusGroupId: Number(optionStatusGroupSelection),
			Name: statusViewerName,
			StatusPoints: [],
		};
		setStatusViewerGroupToEdit(statusViewerSelect);
		handleActionScopButton('edit');
	};

	const onHandleDeleteStatusViewer = () => {
		handleActionScopButton('delete');
	};

	const handleActionScopButton = async (key: string) => {
		const lockComponentCondition: boolean = key !== 'delete';
		const isLocked: boolean = await statusViewerGroupLockedValidation(lockComponentCondition, Number(optionStatusGroupSelection));
		if (isLocked) {
			return;
		}

		switch (key) {
			case 'delete':
				ModalConfirmationList({
					type: _('StatusGroups'),
					translationKey: 'AreYouSureYouWantToDeleteTheStatusGroup',
					data: [{ Name: selectedStatusGroup.Name }],
					onConfirm: () => onTryDeleteStatusGroup(),
				});
				break;
			case 'edit':
				setVisibleAddNewStatusGroupModal(true);
				break;
		}
	};

	const onTryDeleteStatusGroup = async () => {
		if (selectedStatusGroup) {
			const notificationStatusConfig = {
				onSetDefaultStates: () => {
					setNoneStatusGroup();
				},
				permissionErrorCallback: errorMessage => {
					notification['error']({
						message: errorMessage,
					});
				},
			};
			dispatch(deleteStatusViewerGroup(selectedStatusGroup, notificationStatusConfig));
		}
	};

	const headerBarActionButtons = (
		<>
			{hasSelectedStatusViewer && isDisabledShowOnly && (
				<>
					<Button
						id='statusViewerEditStatusGroupButton'
						type='text'
						icon={<EditOutlined />}
						onClick={onHandleEditStatusViewer}
						title={canAddEditDeleteStatusGroups ? _('EditThisGroup') : getPermissionErrorMessage(canAddEditDeleteStatusGroups)}
						disabled={!canAddEditDeleteStatusGroups}
					/>
					<Button
						id='statusViewerDeleteStatusGroupButton'
						type='text'
						icon={<DeleteOutlined />}
						onClick={onHandleDeleteStatusViewer}
						title={canAddEditDeleteStatusGroups ? _('DeleteThisGroup') : getPermissionErrorMessage(canAddEditDeleteStatusGroups)}
						disabled={!canAddEditDeleteStatusGroups}
					/>
				</>
			)}
		</>
	);

	const headerBarColumnHeadersButton = (
		<Tooltip placement='top' title={_('ConfigureColumnHeaders')}>
			<Button
				type='text'
				className={styles.statusGroupConfigureButton}
				icon={<SettingFilled />}
				id={'StatusViewerConfigureStatusViewerColumnButton'}
				key='StatusViewerConfigureStatusViewerColumn'
				onClick={handleConfigureStatusViewerColumn}
			/>
		</Tooltip>
	);

	return (
		<>
			<Row justify='space-between'>
				<Col>
					<Radio.Group className={styles.radioGroupOptions} onChange={handleOnChangeSelect} value={optionSelection}>
						<Radio id='statusViewerShowOnlyRadioButton' value={StatusViewerEnum.ShowOnly}>
							{_('ShowOnly')}:
						</Radio>
						<Select
							id='statusViewerShowOnlyDropdown'
							options={showOnlyOptionsAll}
							value={optionShowOnlySelection}
							disabled={isDisabledShowOnly}
							onChange={handleShowOnlyChange}
							className={styles.selectOptionStatusViewer}
							listHeight={320}
						/>
						<Radio id='statusViewerStatusGroupsRadioButton' value={StatusViewerEnum.StatusGroup}>
							{_('StatusGroups')}:
						</Radio>
						<Select
							id='statusViewerStatusGroupDropdown'
							showSearch={true}
							options={statusGroupOptions}
							value={optionStatusGroupSelection}
							disabled={isDisabledStatusGroup}
							onChange={handleStatusGroupChange}
							className={styles.selectOptionStatusViewer}
							filterOption={filterOption}
							listHeight={320}
						/>
						<Radio id='statusViewerMaskedOnlyRadioButton' value={StatusViewerEnum.MaskedGroup}>
							{_('MaskedOnly')}
						</Radio>
						<Button
							type='primary'
							className={styles.addNewStatusGroupButton}
							id={'StatusAddNewStatusGroupButton'}
							key='StatusAddNewStatusGroup'
							onClick={handleAddNewStatsGroup}
							title={getPermissionErrorMessage(canAddEditDeleteStatusGroups)}
							disabled={!canAddEditDeleteStatusGroups}>
							{_('AddStatusGroup')}
						</Button>
					</Radio.Group>
				</Col>
			</Row>
			<HeaderBar
				title={statusViewerSelected}
				titleElement={headerBarActionButtons}
				buttons={optionSelection !== StatusViewerEnum.MaskedGroup ? headerBarColumnHeadersButton : undefined}
			/>
			{visibleAddNewStatusGroupModal && (
				<StatusGroupConfiguration
					statusViewerGroupToEdit={isEdit ? statusViewerGroupToEdit : null}
					onSetVisible={() => handleSetModalStatusGroupConfigurationVisible(false)}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					statusGroupFilter={componentAllOptions}
				/>
			)}
			{visibleStatusViewerModalColumns && (
				<StatusViewerModalColumns statusGroupFilter={showOnlyOptionsAll} onSetVisible={() => handleSetModalStatusGroupColumnsVisible(false)} />
			)}
		</>
	);
};

export { StatusViewerToolBar };

import { Button, FormInstance, Input, Spin, Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { enrollmentApi } from '../../../../../../../api';
import {
	buildColumn,
	ColumnsProps,
	getDefaultPaginationSettings,
	getDefaultTablePaginationConfig,
	handleResponse,
	ScrollType,
	useHandleOnChangeTableLogic,
} from '../../../../../../../Helper';
import { DefaultColumns, PaginationSetting } from '../../../../../../../model/CommonModel';
import { AdditionalImageList, AdditionalImageModel, ImageUploadType, PersonDetailModel, UploadedImageModel } from '../../../../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setHavePersonChanged } from '../../../../../../../store/enrollment/actions';
import { selectHavePersonChanged, selectPersonId } from '../../../../../../../store/enrollment/selectors';
import { HeaderBar, PersonImages } from '../../../../../../common';
import styles from './additionalImages.module.scss';

type Props = {
	form: FormInstance<PersonDetailModel>;
	tabId: number;
	setShowActionButtons: (value: boolean) => void;
};

type AdditionalImagesDataSourceType = Partial<AdditionalImageModel & DefaultColumns>;
const scroll: ScrollType = { y: 272 };

const AdditionalImages: React.FC<Props> = ({ form, tabId, setShowActionButtons }) => {
	const dispatch = useStoreDispatch();

	const [data, setData] = useState<AdditionalImagesDataSourceType[]>([]);
	const [displayThumbnail, setDisplayThumbnail] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [emptyImage, setEmptyImage] = useState<boolean>(true);
	const [description, setDescription] = useState<string>('');
	const [editImage, setEditImage] = useState<AdditionalImagesDataSourceType>(undefined);
	const [paginationSetting, setPaginationSetting] = useState<PaginationSetting>(getDefaultPaginationSettings());
	const [imageSourceString, setImageSourceString] = useState<string>('');
	const [thumbnailSourceString, setThumbnailSourceString] = useState<string>('');

	const personId: number = useStoreSelector<number>(selectPersonId);
	const havePersonChanged: boolean = useStoreSelector<boolean>(selectHavePersonChanged);
	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const isSearchPerformed = useRef<boolean>(false);

	useEffect(() => {
		getAdditionalImages(paginationSetting);
	}, []);

	useEffect(() => {
		if (editImage) {
			setShowActionButtons(false);
		} else {
			setShowActionButtons(true);
		}
	}, [editImage]);

	useEffect(() => {
		if (imageSourceString === null || imageSourceString === '') {
			setDescription('');
			setEmptyImage(true);
		} else {
			setEmptyImage(false);
		}
	}, [imageSourceString]);

	const getAdditionalImages = (currentPaginationSetting: PaginationSetting) => {
		setIsLoading(true);
		enrollmentApi
			.getAdditionalImageList(personId, currentPaginationSetting)
			.then((response: AdditionalImageList) => {
				if (!handleResponse(response)) {
					const dataSource: AdditionalImagesDataSourceType[] = response.AdditionalImages.map<AdditionalImagesDataSourceType>(
						(additionalImage: AdditionalImageModel) => ({
							...additionalImage,
							key: additionalImage.ImagePlaceHolderId,
						})
					);
					setPaginationSetting({ ...currentPaginationSetting, TotalItems: response.TotalItems });
					setDisplayThumbnail(response.ShowProfilePicture);
					setData(dataSource);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const columns: ColumnsProps<AdditionalImagesDataSourceType>[] = [
		{
			...buildColumn(_('Caption'), 'Caption', 'auto', 'start'),
			sorter: false,
		},
		{
			...buildColumn(_('Description'), 'Description', 'auto', 'start'),
			sorter: false,
		},
	];

	if (displayThumbnail) {
		const photoColumn: ColumnsProps<AdditionalImagesDataSourceType> = {
			...buildColumn('', 'photo', '80px', 'start'),
			render: (text, record: AdditionalImagesDataSourceType) => {
				if (record?.ImageThumbnailBase64) {
					return <img width={50} src={`data:image/bmp;base64,${record?.ImageThumbnailBase64}`} alt={_('PersonPhoto')} />;
				}
			},
		};
		columns.unshift(photoColumn);
	}

	const handleOnClickImage = (record: AdditionalImagesDataSourceType) => {
		const additionalImage: AdditionalImagesDataSourceType = data.find(
			(additionalImage: AdditionalImagesDataSourceType) => additionalImage.ImagePlaceHolderId === record.ImagePlaceHolderId
		);

		if (additionalImage) {
			setDescription(additionalImage.Description);
			setImageSourceString(additionalImage.ImagePortraitBase64);
			setEditImage(additionalImage);
		}
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		setPaginationSetting(prevState => ({ ...prevState, PageNumber: page, PageSize: pageSize }));
	};

	const handleOnSaveAdditionalImage = (imageSourceString: string, thumbnailSourceString: string, description: string) => {
		const clonedState: AdditionalImagesDataSourceType[] = [...data];
		let additionalImage: AdditionalImagesDataSourceType = clonedState.find(
			(additionalImage: AdditionalImagesDataSourceType) => additionalImage.ImagePlaceHolderId === editImage.ImagePlaceHolderId
		);

		if (additionalImage) {
			additionalImage.Description = description;
			additionalImage.ImagePortraitBase64 = imageSourceString;
			additionalImage.ImageThumbnailBase64 = thumbnailSourceString;

			const uploadedImages: UploadedImageModel[] = form.getFieldsValue().UploadedImages;
			const storedAdditionalImage: UploadedImageModel = uploadedImages.find(
				(imageModel: UploadedImageModel) => imageModel.ImagePlaceHolderId === additionalImage.ImagePlaceHolderId
			);

			if (storedAdditionalImage) {
				storedAdditionalImage.Description = description;
				storedAdditionalImage.ImageBase64 = imageSourceString;
				storedAdditionalImage.DeleteImage = imageSourceString === null;
				form.setFieldsValue({
					...form.getFieldsValue(true),
					UploadedImages: uploadedImages,
				});
			} else {
				form.setFieldsValue({
					...form.getFieldsValue(true),
					UploadedImages: [
						...uploadedImages,
						{
							ImagePlaceHolderId: additionalImage.ImagePlaceHolderId,
							Description: description,
							ImageBase64: imageSourceString,
							UploadType: ImageUploadType.AdditionalImage,
							DeleteImage: imageSourceString === null,
						},
					],
				});
			}

			if (!havePersonChanged) {
				dispatch(setHavePersonChanged(true));
			}
			setData(clonedState);
		}

		setEditImage(undefined);
	};

	const handleOnCropAdditionalImage = (imageSource: string, thumbnailSource: string, exitEditMode?: boolean) => {
		setImageSourceString(imageSource);
		setThumbnailSourceString(thumbnailSource);
		if (exitEditMode) {
			setDescription('');
			handleOnSaveAdditionalImage(imageSource, thumbnailSource, '');
		}
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<AdditionalImagesDataSourceType>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			filters,
			pagination,
			sorter,
			tablePaginationSetting: paginationSetting,
			handleChangePagination,
			isSearchPerformed,
			clearSelection: () => {},
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			getAdditionalImages({
				...paginationSetting,
				PageNumber: current,
				PageSize: pageSize,
				SortDirection: sortOrder,
				SortField: sortField.toString(),
			});
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangePagination,
		paginationSetting.PageNumber,
		paginationSetting.PageSize,
		paginationSetting.TotalItems
	);

	const profilePictureBase64: string = imageSourceString ? `data:image/JPG;base64,${imageSourceString}` : '';

	return (
		<div className={styles.container}>
			{editImage ? (
				<div className={styles.currentImageContainer}>
					<fieldset>
						<legend className={styles.legend}>
							{_('CurrentImage')}: {editImage.Caption}
						</legend>
						<div className={styles.additionalImageContainer}>
							<PersonImages
								profilePictureBase64={profilePictureBase64}
								tabId={tabId}
								onSaveAdditionalImage={handleOnCropAdditionalImage}
								additionalImageName={editImage.Caption}
							/>
						</div>
						<div className={styles.currentImageContent}>
							<div className={styles.descriptionContainer}>
								<label htmlFor='enrollmentAdditionalImageDescriptionInput'>{_('Description')}</label>
								<Input
									id='enrollmentAdditionalImageDescriptionInput'
									maxLength={50}
									value={description}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.currentTarget.value)}
									disabled={emptyImage}
								/>
							</div>
							<div className={styles.footer}>
								<Button
									id='enrollmentAdditionalImageSaveButton'
									type='primary'
									onClick={() => handleOnSaveAdditionalImage(imageSourceString, thumbnailSourceString, description)}>
									{_('SaveChanges')}
								</Button>
								<Button id='enrollmentAdditionalImageCancelButton' onClick={() => setEditImage(undefined)}>
									{_('Cancel')}
								</Button>
							</div>
						</div>
					</fieldset>
				</div>
			) : (
				<div>
					<HeaderBar title={_('ImagePlaceholders')} />
					<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
						<Table
							scroll={scroll}
							size='small'
							columns={columns}
							dataSource={data}
							className={styles.imagePlaceholders}
							pagination={pagination}
							rowClassName={(record: AdditionalImagesDataSourceType, index) => {
								if (index % 2 !== 0) {
									return styles.evenRow;
								}
							}}
							onChange={handleOnChangeTable}
							onRow={(record: AdditionalImagesDataSourceType) => {
								return {
									onClick: event => handleOnClickImage(record),
								};
							}}
						/>
					</Spin>
				</div>
			)}
		</div>
	);
};

export { AdditionalImages };

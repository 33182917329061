import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Form, notification, PaginationProps, Spin, Table } from 'antd';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { whosInsideApi } from '../../../api';
import {
	buildColumn,
	ColumnsProps,
	filterOption,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getUniqueValuesArray,
	invertSelectedRowKeys,
	ScrollType,
} from '../../../Helper';
import { PaginationSettingTyped, ResponseObject, SearchSettings, SortDirections, SorterConfigEx, SorterConfigTyped } from '../../../model/CommonModel';
import {
	DoubleTableModal,
	LoadZonesHelper,
	SharedZoneInfo,
	SharedZoneModalInfo,
	WhosInsideZoneDataType,
	Zone,
	ZonesColumns,
} from '../../../model/WhosInsideModel';
import { Modal, NotificationStatus, SearchColumn, Select } from '../../common';
import styles from '../whosInside.module.scss';

type Props = {
	onHideModal: () => void;
	onOkModal: () => void;
};

const scroll: ScrollType = { y: 192 };
const initialItemSearchedPagination: SearchSettings<ZonesColumns>[] = [
	{ Field: ZonesColumns.ZoneName, SearchValue: '' },
	{ Field: ZonesColumns.ZoneGroupName, SearchValue: '' },
];
const initialSortConfig: DoubleTableModal<SorterConfigTyped<ZonesColumns>> = {
	left: { direction: SortDirections.None, field: ZonesColumns.None },
	right: { direction: SortDirections.None, field: ZonesColumns.None },
};
const initialSortConfigEx: DoubleTableModal<SorterConfigEx> = {
	left: { columnKey: undefined, order: undefined },
	right: { columnKey: undefined, order: undefined },
};
const zoneGroupMaxLength: number = 100;
const zoneNameMaxLength: number = 100;

const SharedZoneModal: React.VFC<Props> = ({ onHideModal, onOkModal }) => {
	const [saveSharedZone, setSaveSharedZone] = useState<boolean>(false);
	const [loadZones, setLoadZones] = useState<boolean>(true);
	const [sharedZonesList, setSharedZonesList] = useState<Zone[]>([]);
	const [sharedZoneId, setSharedZoneId] = useState<number>(undefined);
	const [sharedZonesToSave, setSharedZonesToSave] = useState<SharedZoneModalInfo[]>([]);
	const [newSelected, setNewSelected] = useState<React.Key[]>([]);
	const [newAvailable, setNewAvailable] = useState<React.Key[]>([]);
	const [leftZones, setLeftZones] = useState([]);
	const [rightZones, setRightZones] = useState([]);
	const [tableData, setTableData] = useState<DoubleTableModal<WhosInsideZoneDataType[]>>({ left: [], right: [] });
	const [currentPage, setCurrentPage] = useState<DoubleTableModal<number>>({ left: 1, right: 1 });
	const [currentPageSize, setCurrentPageSize] = useState<DoubleTableModal<number>>({ left: 25, right: 25 });
	const [totalItemsPagination, setTotalItemsPagination] = useState<DoubleTableModal<number>>({ left: 0, right: 0 });
	const [loadingTables, setLoadingTables] = useState<DoubleTableModal<boolean>>({ left: false, right: false });
	const [allItemsPagination, setAllItemsPagination] = useState<DoubleTableModal<React.Key[]>>({ left: [], right: [] });
	const [leftItemSearchedPagination, setLeftItemSearchedPagination] = useState<SearchSettings<ZonesColumns>[]>(initialItemSearchedPagination);
	const [rightItemSearchedPagination, setRightItemSearchedPagination] = useState<SearchSettings<ZonesColumns>[]>(initialItemSearchedPagination);
	const [shouldResetZoneNameSearchColumn, setShouldResetZoneNameSearchColumn] = useState<DoubleTableModal<boolean>>({ left: false, right: false });
	const [shouldResetZoneGroupNameSearchColumn, setShouldResetZoneGroupNameSearchColumn] = useState<DoubleTableModal<boolean>>({ left: false, right: false });
	const [selectedLeftRowKeys, setSelectedLeftRowKeys] = useState<React.Key[]>([]);
	const [selectedRightRowKeys, setSelectedRightRowKeys] = useState<React.Key[]>([]);
	const [resetSearchColumn, setResetSearchColumn] = useState<DoubleTableModal<boolean>>({ left: false, right: false });
	const [sorterConfig, setSorterConfig] = useState<DoubleTableModal<SorterConfigTyped<ZonesColumns>>>(initialSortConfig);
	const [sorterConfigEx, setSorterConfigEx] = useState<DoubleTableModal<SorterConfigEx>>(initialSortConfigEx);

	const [form] = Form.useForm();

	useEffect(() => {
		const mapRes: WhosInsideZoneDataType[] = createData(leftZones);
		setTableData(prevState => ({ ...prevState, left: mapRes }));

		const previousSelectedRowKeys: React.Key[] = getPreviousSelectedRowKeys(leftZones);
		setSelectedLeftRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedLeftRowKeys));
	}, [leftZones]);

	useEffect(() => {
		const mapRes: WhosInsideZoneDataType[] = createData(rightZones);
		setTableData(prevState => ({ ...prevState, right: mapRes }));

		const previousSelectedRowKeys: React.Key[] = getPreviousSelectedRowKeys(rightZones);
		setSelectedRightRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedRightRowKeys));
	}, [rightZones]);

	useEffect(() => {
		whosInsideApi
			.getSharedZonesList()
			.then(res => {
				setSharedZonesList(res);
			})
			.finally(() => setLoadZones(false));
	}, []);

	const leftColumns: ColumnsProps<WhosInsideZoneDataType>[] = [
		{
			...buildColumn(_('ZoneName'), 'ZoneName', 'auto', 'start'),
			sorter: true,
			key: ZonesColumns[ZonesColumns.ZoneName],
			sortOrder: sorterConfigEx.left.columnKey === ZonesColumns[ZonesColumns.ZoneName] && sorterConfigEx.left.order,
			...SearchColumn({
				maxLength: zoneNameMaxLength,
				dataIndex: 'ZoneName',
				reset: resetSearchColumn.left,
				label: _('ZoneName'),
				notVisible: sharedZoneId === undefined,
				resetSearch: shouldResetZoneNameSearchColumn.left,
				setIsFilterMode: undefined,
				clearSelection: () => clearSelection(true),
				handleResetSearch: () => setShouldResetZoneNameSearchColumn(prevState => ({ ...prevState, left: false })),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, ZonesColumns.ZoneName, true),
				setSearchedValue: (searchedValue: string) => handleOnSearched(searchedValue, ZonesColumns.ZoneName, true),
				searchColumnId: 'searchColumnLeftZoneName',
			}),
		},
		{
			...buildColumn(_('ZoneGroupName'), 'ZoneGroupName', 'auto', 'start'),
			sorter: true,
			key: ZonesColumns[ZonesColumns.ZoneGroupName],
			sortOrder: sorterConfigEx.left.columnKey === ZonesColumns[ZonesColumns.ZoneGroupName] && sorterConfigEx.left.order,
			...SearchColumn({
				maxLength: zoneGroupMaxLength,
				dataIndex: 'ZoneGroupName',
				reset: resetSearchColumn.left,
				label: _('ZoneGroupName'),
				notVisible: sharedZoneId === undefined,
				resetSearch: shouldResetZoneGroupNameSearchColumn.left,
				setIsFilterMode: undefined,
				clearSelection: () => clearSelection(true),
				handleResetSearch: () => setShouldResetZoneGroupNameSearchColumn(prevState => ({ ...prevState, left: false })),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, ZonesColumns.ZoneGroupName, true),
				setSearchedValue: (searchedValue: string) => handleOnSearched(searchedValue, ZonesColumns.ZoneGroupName, true),
				searchColumnId: 'searchColumnLeftZoneGroupName',
			}),
		},
	];

	const rightColumns: ColumnsProps<WhosInsideZoneDataType>[] = [
		{
			...buildColumn(_('ZoneName'), 'ZoneName', 'auto', 'start'),
			sorter: true,
			key: ZonesColumns[ZonesColumns.ZoneName],
			sortOrder: sorterConfigEx.right.columnKey === ZonesColumns[ZonesColumns.ZoneName] && sorterConfigEx.right.order,
			...SearchColumn({
				maxLength: zoneNameMaxLength,
				dataIndex: 'ZoneName',
				reset: resetSearchColumn.right,
				label: _('ZoneName'),
				notVisible: sharedZoneId === undefined,
				resetSearch: shouldResetZoneNameSearchColumn.right,
				setIsFilterMode: undefined,
				clearSelection: () => clearSelection(false),
				handleResetSearch: () => setShouldResetZoneNameSearchColumn(prevState => ({ ...prevState, right: false })),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, ZonesColumns.ZoneName, false),
				setSearchedValue: (searchedValue: string) => handleOnSearched(searchedValue, ZonesColumns.ZoneName, false),
				searchColumnId: 'searchColumnRightZoneName',
			}),
		},
		{
			...buildColumn(_('ZoneGroupName'), 'ZoneGroupName', 'auto', 'start'),
			sorter: true,
			key: ZonesColumns[ZonesColumns.ZoneGroupName],
			sortOrder: sorterConfigEx.right.columnKey === ZonesColumns[ZonesColumns.ZoneGroupName] && sorterConfigEx.right.order,
			...SearchColumn({
				maxLength: zoneGroupMaxLength,
				dataIndex: 'ZoneGroupName',
				reset: resetSearchColumn.right,
				label: _('ZoneGroupName'),
				notVisible: sharedZoneId === undefined,
				resetSearch: shouldResetZoneGroupNameSearchColumn.right,
				setIsFilterMode: undefined,
				clearSelection: () => clearSelection(false),
				handleResetSearch: () => setShouldResetZoneGroupNameSearchColumn(prevState => ({ ...prevState, right: false })),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, ZonesColumns.ZoneGroupName, false),
				setSearchedValue: (searchedValue: string) => handleOnSearched(searchedValue, ZonesColumns.ZoneGroupName, false),
				searchColumnId: 'searchColumnRightZoneGroupName',
			}),
		},
	];

	const createData = (zone: SharedZoneInfo[]): WhosInsideZoneDataType[] => {
		if (zone.length > 0) {
			return zone.map<WhosInsideZoneDataType>((data: SharedZoneInfo) => ({
				...data,
				key: `${data.ZoneGroupId}-${data.ZoneId}-${data.OriginallySelected}`,
			}));
		}

		return [];
	};

	const getPreviousSelectedRowKeys = (zones: WhosInsideZoneDataType[]): React.Key[] => {
		const previousSelectedRowKeys: React.Key[] = zones.reduce<React.Key[]>((result: React.Key[], item: WhosInsideZoneDataType) => {
			if (item.checked) {
				result.push(`${item.ZoneGroupId}-${item.ZoneId}-${item.OriginallySelected}`);
			}
			return result;
		}, []);

		return previousSelectedRowKeys;
	};

	const handleOnSearched = (searchedValue: string, zoneColumn: ZonesColumns, isLeft: boolean) => {
		if (isLeft) {
			setResetSearchColumn(prevState => ({ ...prevState, left: false }));
			setLeftItemSearchedPagination(prevState => {
				const clonedState: SearchSettings<ZonesColumns>[] = [...prevState];
				const index: number = clonedState.findIndex(x => x.Field === zoneColumn);
				if (index !== -1) {
					clonedState[index] = { Field: zoneColumn, SearchValue: searchedValue };
				}
				return clonedState;
			});
		} else {
			setResetSearchColumn(prevState => ({ ...prevState, right: false }));
			setRightItemSearchedPagination(prevState => {
				const clonedState: SearchSettings<ZonesColumns>[] = [...prevState];
				const index: number = clonedState.findIndex(x => x.Field === zoneColumn);
				if (index !== -1) {
					clonedState[index] = { Field: zoneColumn, SearchValue: searchedValue };
				}
				return clonedState;
			});
		}
	};

	const handleOnSearchResults = (searchedValue: string, zoneColumn: ZonesColumns, isLeft: boolean) => {
		let zoneInfoHelper: LoadZonesHelper = {
			SharedZoneId: sharedZoneId,
			IsLeft: true,
			SelectedZones: convertKeysIntoSharedZoneInfo(newSelected),
			UnselectedZones: convertKeysIntoSharedZoneInfo(newAvailable),
		};

		if (isLeft) {
			const clonedState: SearchSettings<ZonesColumns>[] = [...leftItemSearchedPagination];
			const index: number = clonedState.findIndex(x => x.Field === zoneColumn);
			if (index !== -1) {
				clonedState[index] = { Field: zoneColumn, SearchValue: searchedValue };
			}

			const leftPaginationSettings: PaginationSettingTyped<ZonesColumns> = {
				PageNumber: searchedValue !== '' ? 1 : currentPage.left,
				PageSize: currentPageSize.left,
				SortDirection: sorterConfig.left.direction,
				SortField: sorterConfig.left.field,
				SearchSettings: clonedState,
			};

			setZonesBy(leftPaginationSettings, zoneInfoHelper, true);
			whosInsideApi.getZoneKeysAllItemsPagination(zoneInfoHelper, leftPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, left: res }));
			});
		} else {
			const clonedState: SearchSettings<ZonesColumns>[] = [...rightItemSearchedPagination];
			const index: number = clonedState.findIndex(x => x.Field === zoneColumn);
			if (index !== -1) {
				clonedState[index] = { Field: zoneColumn, SearchValue: searchedValue };
			}

			const rightPaginationSettings: PaginationSettingTyped<ZonesColumns> = {
				PageNumber: searchedValue !== '' ? 1 : currentPage.right,
				PageSize: currentPageSize.right,
				SortDirection: sorterConfig.right.direction,
				SortField: sorterConfig.right.field,
				SearchSettings: clonedState,
			};

			setZonesBy(rightPaginationSettings, { ...zoneInfoHelper, IsLeft: false }, false);
			whosInsideApi.getZoneKeysAllItemsPagination({ ...zoneInfoHelper, IsLeft: false }, rightPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, right: res }));
			});
		}
	};

	const updateBothTables = async (
		zoneInfoHelper: LoadZonesHelper,
		leftPaginationSettings?: PaginationSettingTyped<ZonesColumns>,
		rightPaginationSettings?: PaginationSettingTyped<ZonesColumns>,
		uncheckData?: boolean
	) => {
		if (!leftPaginationSettings) {
			leftPaginationSettings = {
				PageNumber: currentPage.left,
				PageSize: currentPageSize.left,
				SortDirection: sorterConfig.left.direction,
				SortField: sorterConfig.left.field,
				SearchSettings: leftItemSearchedPagination,
			};
		}

		if (!rightPaginationSettings) {
			rightPaginationSettings = {
				PageNumber: currentPage.right,
				PageSize: currentPageSize.right,
				SortDirection: sorterConfig.right.direction,
				SortField: sorterConfig.right.field,
				SearchSettings: rightItemSearchedPagination,
			};
		}

		await setZonesBy(leftPaginationSettings, zoneInfoHelper, true, uncheckData);
		await setZonesBy(rightPaginationSettings, { ...zoneInfoHelper, IsLeft: false }, false, uncheckData);
	};

	const getCurrentPageNumber = (
		selectedZones: SharedZoneInfo[],
		unselectedZones: SharedZoneInfo[],
		allItemsPagination: React.Key[],
		currentPageSize: number,
		currentPage: number,
		pageNumber: number,
		isLeft: boolean
	): number => {
		const currentNewSelected: React.Key[] = (selectedZones ?? []).map<React.Key>(x => `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`);
		const currentNewSelectedKey: Set<React.Key> = new Set(currentNewSelected);
		let currentAllItemsPagination: React.Key[] = allItemsPagination.filter(x => !currentNewSelectedKey.has(x));
		const newUnselectedZones = (unselectedZones ?? []).map<React.Key>(x => `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`);
		currentAllItemsPagination = getUniqueValuesArray(currentAllItemsPagination, newUnselectedZones);

		let maxPageNumber: number = Math.ceil(currentAllItemsPagination.length / currentPageSize);
		if (currentPage > maxPageNumber && maxPageNumber != 0) {
			if (isLeft) {
				setCurrentPage(prevState => ({ ...prevState, left: maxPageNumber }));
			} else {
				setCurrentPage(prevState => ({ ...prevState, right: maxPageNumber }));
			}
			return maxPageNumber;
		} else {
			return pageNumber;
		}
	};

	const setZonesBy = async (
		paginationSetting: PaginationSettingTyped<ZonesColumns>,
		zoneInfoHelper: LoadZonesHelper,
		isLeft: boolean,
		uncheckData?: boolean
	) => {
		if (isLeft) {
			paginationSetting.PageNumber = getCurrentPageNumber(
				zoneInfoHelper.SelectedZones,
				zoneInfoHelper.UnselectedZones,
				allItemsPagination.left,
				currentPageSize.left,
				currentPage.left,
				paginationSetting.PageNumber,
				isLeft
			);
		} else {
			paginationSetting.PageNumber = getCurrentPageNumber(
				zoneInfoHelper.UnselectedZones,
				zoneInfoHelper.SelectedZones,
				allItemsPagination.right,
				currentPageSize.right,
				currentPage.right,
				paginationSetting.PageNumber,
				isLeft
			);
		}

		const previousState = isLeft ? leftZones?.filter(c => c.checked) : rightZones?.filter(c => c.checked);
		const res = await whosInsideApi.loadSharedZoneZonesByPage(zoneInfoHelper, paginationSetting);
		const payload = [];
		const { TotalItemsPaginated, Zones } = res;
		if (uncheckData) {
			Zones.forEach((item: SharedZoneInfo) => {
				payload.push({ ...item, checked: false });
			});
		} else {
			Zones.forEach((item: SharedZoneInfo) => {
				payload.push({ ...item, checked: previousState.some(x => x.ZoneId === item.ZoneId && x.ZoneGroupId === item.ZoneGroupId) });
			});
		}

		if (isLeft) {
			setLeftZones(payload);
			setTotalItemsPagination(prevState => ({ ...prevState, left: TotalItemsPaginated }));
			setLoadingTables(prevState => ({ ...prevState, left: false }));
		} else {
			setRightZones(payload);
			setTotalItemsPagination(prevState => ({ ...prevState, right: TotalItemsPaginated }));
			setLoadingTables(prevState => ({ ...prevState, right: false }));
		}
	};

	const clearSelection = (isLeft: boolean) => {
		if (isLeft) {
			setSelectedLeftRowKeys([]);
		} else {
			setSelectedRightRowKeys([]);
		}
	};

	const handleChangeLeftPagination = (page: number, pageSize: number) => {
		setCurrentPage(prevState => ({ ...prevState, left: page }));
		if (pageSize !== currentPageSize.left) {
			setCurrentPageSize(prevState => ({ ...prevState, left: pageSize }));
		}
	};

	const handleChangeRightPagination = (page: number, pageSize: number) => {
		setCurrentPage(prevState => ({ ...prevState, right: page }));
		if (pageSize !== currentPageSize.right) {
			setCurrentPageSize(prevState => ({ ...prevState, right: pageSize }));
		}
	};

	const handleOnChangeTable = (pagination: PaginationProps, filters, sorter, isLeft: boolean) => {
		if (!sharedZoneId) {
			return;
		} else if (isLeft) {
			setSorterConfigEx(prevState => ({ ...prevState, left: sorter }));
			setLoadingTables(prevState => ({ ...prevState, left: true }));
		} else {
			setSorterConfigEx(prevState => ({ ...prevState, right: sorter }));
			setLoadingTables(prevState => ({ ...prevState, right: true }));
		}

		const { order, field, column } = sorter;
		const { current, pageSize } = pagination;
		const sortField: string = (column?.dataIndex as keyof typeof ZonesColumns) ?? ZonesColumns[0];
		const sorterConfigDirection = isLeft ? sorterConfig.left.direction : sorterConfig.right.direction;
		let sortOrder = SortDirections.None;
		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		if (sortOrder !== sorterConfigDirection) {
			if (isLeft) {
				setSorterConfig(prevState => ({ ...prevState, left: { direction: sortOrder, field } }));
			} else {
				setSorterConfig(prevState => ({ ...prevState, right: { direction: sortOrder, field } }));
			}
		}

		const zoneInfoHelper: LoadZonesHelper = {
			SharedZoneId: sharedZoneId,
			IsLeft: isLeft,
			SelectedZones: convertKeysIntoSharedZoneInfo(newSelected),
			UnselectedZones: convertKeysIntoSharedZoneInfo(newAvailable),
		};

		if (isLeft) {
			handleChangeLeftPagination(current, pageSize);
			if (current !== currentPage.left || pageSize !== currentPageSize.left || sortOrder !== sorterConfigDirection) {
				setZonesBy(
					{
						PageNumber: current,
						PageSize: pageSize,
						SortDirection: sortOrder,
						SortField: ZonesColumns[sortField],
						SearchSettings: leftItemSearchedPagination,
					} as PaginationSettingTyped<ZonesColumns>,
					zoneInfoHelper,
					true
				).finally(() => setLoadingTables(prevState => ({ ...prevState, left: false })));
			} else {
				setLoadingTables(prevState => ({ ...prevState, left: false }));
			}
		} else {
			handleChangeRightPagination(current, pageSize);
			if (current !== currentPage.right || pageSize !== currentPageSize.right || sortOrder !== sorterConfigDirection) {
				setZonesBy(
					{
						PageNumber: current,
						PageSize: pageSize,
						SortDirection: sortOrder,
						SortField: ZonesColumns[sortField],
						SearchSettings: rightItemSearchedPagination,
					} as PaginationSettingTyped<ZonesColumns>,
					zoneInfoHelper,
					false
				).finally(() => setLoadingTables(prevState => ({ ...prevState, right: false })));
			} else {
				setLoadingTables(prevState => ({ ...prevState, right: false }));
			}
		}
	};

	const handleSelectAllRight = () => {
		let currentAllRightItemsPagination: React.Key[];
		const index: number = rightItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		if (index !== -1) {
			currentAllRightItemsPagination = allItemsPagination.right;
		} else {
			const currentNewAvailable: Set<React.Key> = new Set(newAvailable);
			currentAllRightItemsPagination = allItemsPagination.right.filter(x => !currentNewAvailable.has(x));
			currentAllRightItemsPagination = getUniqueValuesArray(currentAllRightItemsPagination, newSelected);
		}

		const newSelectedRowKeys = getUniqueValuesArray(selectedRightRowKeys, currentAllRightItemsPagination);

		const cloneStateProps: WhosInsideZoneDataType[] = rightZones.map(x => ({
			...x,
			checked: (newSelectedRowKeys ?? []).findIndex(r => r === `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`) !== -1,
		}));

		setRightZones(cloneStateProps);
		setSelectedRightRowKeys(newSelectedRowKeys);
	};

	const handleRightSelectInvert = () => {
		const dataKeys = rightZones.reduce<React.Key[]>((result, item) => {
			result.push(`${item.ZoneGroupId}-${item.ZoneId}-${item.OriginallySelected}`);
			return result;
		}, []);

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRightRowKeys);

		const cloneStateProps: WhosInsideZoneDataType[] = rightZones.map(x => ({
			...x,
			checked: (newSelectedRowKeys ?? []).findIndex(key => key === `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`) !== -1,
		}));

		setRightZones(cloneStateProps);
		setSelectedRightRowKeys(newSelectedRowKeys);
	};

	const handleSelectAllLeft = () => {
		let currentAllLeftItemsPagination: React.Key[];
		const index: number = leftItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		if (index !== -1) {
			currentAllLeftItemsPagination = allItemsPagination.left;
		} else {
			const currentNewSelected: Set<React.Key> = new Set(newSelected);
			currentAllLeftItemsPagination = allItemsPagination.left.filter(x => !currentNewSelected.has(x));
			currentAllLeftItemsPagination = getUniqueValuesArray(currentAllLeftItemsPagination, newAvailable);
		}

		const newSelectedRowKeys = getUniqueValuesArray(selectedLeftRowKeys, currentAllLeftItemsPagination);

		const cloneStateProps: WhosInsideZoneDataType[] = leftZones.map(x => ({
			...x,
			checked: (newSelectedRowKeys ?? []).findIndex(r => r === `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`) !== -1,
		}));

		setLeftZones(cloneStateProps);
		setSelectedLeftRowKeys(newSelectedRowKeys);
	};

	const handleLeftSelectInvert = () => {
		const dataKeys = leftZones.reduce<React.Key[]>((result, item) => {
			result.push(`${item.ZoneGroupId}-${item.ZoneId}-${item.OriginallySelected}`);
			return result;
		}, []);

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedLeftRowKeys);

		const cloneStateProps: WhosInsideZoneDataType[] = leftZones.map(x => ({
			...x,
			checked: (newSelectedRowKeys ?? []).findIndex(key => key === `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`) !== -1,
		}));

		setLeftZones(cloneStateProps);
		setSelectedLeftRowKeys(newSelectedRowKeys);
	};

	const handleRightChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneStateProps: WhosInsideZoneDataType[] = rightZones.map(x => ({
			...x,
			checked: (newSelectedRowKeys ?? []).findIndex(key => key === `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`) !== -1,
		}));

		setRightZones(cloneStateProps);
		setSelectedRightRowKeys(newSelectedRowKeys);
	};

	const handleLeftChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneStateProps: WhosInsideZoneDataType[] = leftZones.map(x => ({
			...x,
			checked: (newSelectedRowKeys ?? []).findIndex(key => key === `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`) !== -1,
		}));

		setLeftZones(cloneStateProps);
		setSelectedLeftRowKeys(newSelectedRowKeys);
	};

	const leftRowSelection: TableRowSelection<WhosInsideZoneDataType> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: sharedZoneId ? getDefaultTableSelectionConfigPagination(false, handleSelectAllLeft, handleLeftSelectInvert) : false,
		getCheckboxProps: record => ({
			id: `whosInsideLeftTableCheckbox-${record.key}`,
			children: <label htmlFor={`whosInsideLeftTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
		}),
		selectedRowKeys: selectedLeftRowKeys,
		onChange: handleLeftChange,
	};

	const rightRowSelection: TableRowSelection<WhosInsideZoneDataType> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: sharedZoneId ? getDefaultTableSelectionConfigPagination(false, handleSelectAllRight, handleRightSelectInvert) : false,
		getCheckboxProps: record => ({
			id: `whosInsideRightTableCheckbox-${record.key}`,
			children: <label htmlFor={`whosInsideRightTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
		}),
		selectedRowKeys: selectedRightRowKeys,
		onChange: handleRightChange,
	};

	const leftPagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangeLeftPagination,
		currentPage.left,
		currentPageSize.left,
		totalItemsPagination.left,
		undefined,
		selectedLeftRowKeys
	);

	const rightPagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangeRightPagination,
		currentPage.right,
		currentPageSize.right,
		totalItemsPagination.right,
		undefined,
		selectedRightRowKeys
	);

	const handleOnAddZone = () => {
		const clonedNewAvailable = [...newAvailable];
		const clonedNewSelected = [...newSelected];
		for (const selectedZone of selectedLeftRowKeys) {
			const isInSharedZone: string = selectedZone.toString().slice(selectedZone.toString().length - 1);

			if (isInSharedZone === '1') {
				const controllerIndex = clonedNewAvailable.findIndex(x => x === selectedZone);
				if (~controllerIndex) {
					clonedNewAvailable.splice(controllerIndex, 1);
				}
			} else {
				const index = clonedNewSelected.findIndex(x => x === selectedZone);
				if (index === -1) {
					clonedNewSelected.push(selectedZone);
				}
			}
		}

		setNewAvailable(clonedNewAvailable);
		setNewSelected(clonedNewSelected);
		setSelectedLeftRowKeys([]);
		setLoadZones(true);

		const zoneInfoHelper: LoadZonesHelper = {
			SharedZoneId: sharedZoneId,
			IsLeft: true,
			SelectedZones: convertKeysIntoSharedZoneInfo(clonedNewSelected),
			UnselectedZones: convertKeysIntoSharedZoneInfo(clonedNewAvailable),
		};

		handleActiveSearch(zoneInfoHelper);
		updateBothTables(zoneInfoHelper).finally(() => setLoadZones(false));
	};

	const handleActiveSearch = (zoneInfoHelper: LoadZonesHelper) => {
		const rightPaginationSettings: PaginationSettingTyped<ZonesColumns> = {
			PageNumber: 1,
			PageSize: currentPageSize.right,
			SortDirection: sorterConfig.right.direction,
			SortField: sorterConfig.right.field,
			SearchSettings: rightItemSearchedPagination,
		};

		const leftPaginationSettings: PaginationSettingTyped<ZonesColumns> = {
			PageNumber: 1,
			PageSize: currentPageSize.left,
			SortDirection: sorterConfig.left.direction,
			SortField: sorterConfig.left.field,
			SearchSettings: leftItemSearchedPagination,
		};

		const rightIndex: number = rightItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		const leftIndex: number = leftItemSearchedPagination.findIndex(x => x.SearchValue !== '');

		if (rightIndex !== -1 && leftIndex !== -1) {
			Promise.all([
				whosInsideApi.getZoneKeysAllItemsPagination({ ...zoneInfoHelper, IsLeft: false }, rightPaginationSettings),
				whosInsideApi.getZoneKeysAllItemsPagination(zoneInfoHelper, leftPaginationSettings),
			]).then(res => setAllItemsPagination({ left: res[1], right: res[0] }));
		} else if (rightIndex !== -1) {
			whosInsideApi.getZoneKeysAllItemsPagination({ ...zoneInfoHelper, IsLeft: false }, rightPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, right: res }));
			});
		} else if (leftIndex !== -1) {
			whosInsideApi.getZoneKeysAllItemsPagination(zoneInfoHelper, leftPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, left: res }));
			});
		}
	};

	const convertKeysIntoSharedZoneInfo = (keys: React.Key[]): SharedZoneInfo[] => {
		const sharedZoneInfoArray: SharedZoneInfo[] = keys.map<SharedZoneInfo>((key: React.Key) => {
			const parts: string[] = key.toString().split('-');

			const result: SharedZoneInfo = {
				ZoneGroupName: '',
				ZoneName: '',
				ZoneGroupId: Number(parts[0]),
				ZoneId: Number(parts[1]),
				OriginallySelected: Number(parts[2]),
			};

			return result;
		});

		return sharedZoneInfoArray;
	};

	const handleOnRemoveZone = () => {
		const clonedNewAvailable = [...newAvailable];
		const clonedNewSelected = [...newSelected];
		for (const selectedZone of selectedRightRowKeys) {
			const isInSharedZone: string = selectedZone.toString().slice(selectedZone.toString().length - 1);

			if (isInSharedZone === '0') {
				const controllerIndex = clonedNewSelected.findIndex(x => x === selectedZone);
				if (~controllerIndex) {
					clonedNewSelected.splice(controllerIndex, 1);
				}
			} else {
				const index = clonedNewAvailable.findIndex(x => x === selectedZone);
				if (index === -1) {
					clonedNewAvailable.push(selectedZone);
				}
			}
		}

		setNewAvailable(clonedNewAvailable);
		setNewSelected(clonedNewSelected);
		setSelectedRightRowKeys([]);
		setLoadZones(true);

		const zoneInfoHelper: LoadZonesHelper = {
			SharedZoneId: sharedZoneId,
			IsLeft: true,
			SelectedZones: convertKeysIntoSharedZoneInfo(clonedNewSelected),
			UnselectedZones: convertKeysIntoSharedZoneInfo(clonedNewAvailable),
		};

		handleActiveSearch(zoneInfoHelper);
		updateBothTables(zoneInfoHelper).finally(() => setLoadZones(false));
	};

	const handleOnRowClick = (key: React.Key, isLeft: boolean) => {
		if (isLeft) {
			const index = selectedLeftRowKeys.indexOf(key);
			if (index === -1) {
				handleLeftChange([...selectedLeftRowKeys, key]);
			} else {
				selectedLeftRowKeys.splice(index, 1);
				handleLeftChange([...selectedLeftRowKeys]);
			}
		} else {
			const index = selectedRightRowKeys.indexOf(key);
			if (index === -1) {
				handleRightChange([...selectedRightRowKeys, key]);
			} else {
				selectedRightRowKeys.splice(index, 1);
				handleRightChange([...selectedRightRowKeys]);
			}
		}
	};

	const handleChangeCurrentSharedZone = async (value: string) => {
		setLoadZones(true);
		const currentSharedZoneId: number = Number(value);
		const currentSharedZonesToSave: SharedZoneModalInfo[] = [...sharedZonesToSave];
		const sharedZoneIndex: number = currentSharedZonesToSave.findIndex(item => item.SharedZoneId === currentSharedZoneId);

		const leftPaginationSettings: PaginationSettingTyped<ZonesColumns> = {
			PageNumber: 1,
			PageSize: currentPageSize.left,
			SortDirection: SortDirections.None,
			SortField: ZonesColumns.None,
			SearchSettings: initialItemSearchedPagination,
		};

		const rightPaginationSettings: PaginationSettingTyped<ZonesColumns> = {
			PageNumber: 1,
			PageSize: currentPageSize.right,
			SortDirection: SortDirections.None,
			SortField: ZonesColumns.None,
			SearchSettings: initialItemSearchedPagination,
		};

		if (sharedZoneIndex === -1) {
			const previousIndex: number = currentSharedZonesToSave.findIndex(item => item.SharedZoneId === sharedZoneId);
			const previousSharedZoneModalInfo: SharedZoneModalInfo = {
				SharedZoneId: sharedZoneId,
				AvailableZonesList: convertKeysIntoSharedZoneInfo(newAvailable),
				SelectedZonesList: convertKeysIntoSharedZoneInfo(newSelected),
			};

			const zoneInfoHelper: LoadZonesHelper = {
				SharedZoneId: currentSharedZoneId,
				IsLeft: true,
				SelectedZones: [],
				UnselectedZones: [],
			};

			const newSharedZoneModalInfo: SharedZoneModalInfo = {
				SharedZoneId: currentSharedZoneId,
				AvailableZonesList: [],
				SelectedZonesList: [],
			};

			setNewAvailable([]);
			setNewSelected([]);
			setTotalItemsPagination({ left: 0, right: 0 });
			updateBothTables(zoneInfoHelper, leftPaginationSettings, rightPaginationSettings, true).finally(() => setLoadZones(false));
			if (sharedZoneId !== currentSharedZoneId) {
				if (previousIndex !== -1) {
					setSharedZonesToSave(prevState => {
						prevState.splice(sharedZoneIndex, 1, previousSharedZoneModalInfo);
						prevState.push(newSharedZoneModalInfo);
						return prevState;
					});
				} else {
					saveCurrentTransferInfo(newSharedZoneModalInfo, -1);
				}
			}
			setAllItemsPaginationTables(zoneInfoHelper);
		} else {
			const previousIndex: number = currentSharedZonesToSave.findIndex(item => item.SharedZoneId === sharedZoneId);
			const previousSharedZoneModalInfo: SharedZoneModalInfo = {
				SharedZoneId: sharedZoneId,
				AvailableZonesList: convertKeysIntoSharedZoneInfo(newAvailable),
				SelectedZonesList: convertKeysIntoSharedZoneInfo(newSelected),
			};
			setSharedZonesToSave(prevState => {
				prevState.splice(previousIndex, 1, previousSharedZoneModalInfo);
				return prevState;
			});
			const { SharedZoneId, SelectedZonesList, AvailableZonesList } = currentSharedZonesToSave[sharedZoneIndex];

			const zoneInfoHelper: LoadZonesHelper = {
				SharedZoneId: SharedZoneId,
				IsLeft: true,
				SelectedZones: SelectedZonesList,
				UnselectedZones: AvailableZonesList,
			};

			const storedAvailable = (AvailableZonesList ?? []).map<React.Key>(x => `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`);
			const storedSelected = (SelectedZonesList ?? []).map<React.Key>(x => `${x.ZoneGroupId}-${x.ZoneId}-${x.OriginallySelected}`);
			setNewAvailable(storedAvailable);
			setNewSelected(storedSelected);
			setTotalItemsPagination({ left: 0, right: 0 });
			updateBothTables(zoneInfoHelper, leftPaginationSettings, rightPaginationSettings, true).finally(() => setLoadZones(false));
			setAllItemsPaginationTables(zoneInfoHelper);
		}

		setSharedZoneId(currentSharedZoneId);
	};

	const setAllItemsPaginationTables = async (zoneInfoHelper: LoadZonesHelper) => {
		resetTableValues();
		const totalLeftItemsPagination = await whosInsideApi.getZoneKeysAllItemsPagination(zoneInfoHelper);
		const totalRightItemsPagination = await whosInsideApi.getZoneKeysAllItemsPagination({ ...zoneInfoHelper, IsLeft: false });
		setAllItemsPagination({ left: totalLeftItemsPagination, right: totalRightItemsPagination });
	};

	const resetTableValues = () => {
		setSelectedLeftRowKeys([]);
		setSelectedRightRowKeys([]);
		handleChangeLeftPagination(1, currentPageSize.left);
		handleChangeRightPagination(1, currentPageSize.right);
		setSorterConfig(initialSortConfig);
		setSorterConfigEx(initialSortConfigEx);
		setLeftItemSearchedPagination(initialItemSearchedPagination);
		setRightItemSearchedPagination(initialItemSearchedPagination);
		setLoadingTables({ left: false, right: false });
		setShouldResetZoneNameSearchColumn({ left: true, right: true });
		setShouldResetZoneGroupNameSearchColumn({ left: true, right: true });
		setResetSearchColumn({ left: true, right: true });
	};

	const saveCurrentTransferInfo = (sharedZoneInfo: SharedZoneModalInfo, sharedZoneIndex?: number) => {
		let currentSharedZonesToSave: SharedZoneModalInfo[] = [...sharedZonesToSave];
		if (sharedZoneIndex >= 0) {
			setSharedZonesToSave(prevState => {
				prevState.splice(sharedZoneIndex, 1, sharedZoneInfo);
				return prevState;
			});
		} else {
			currentSharedZonesToSave.push(sharedZoneInfo);
			setSharedZonesToSave(currentSharedZonesToSave);
		}
	};

	const handleSaveSharedZone = async () => {
		setSaveSharedZone(true);
		const currentSharedZonesToSave: SharedZoneModalInfo[] = [...sharedZonesToSave];
		const currentSharedZoneIndex: number = currentSharedZonesToSave.findIndex(item => item.SharedZoneId === sharedZoneId);
		const previousSharedZoneModalInfo: SharedZoneModalInfo = {
			SharedZoneId: sharedZoneId,
			AvailableZonesList: convertKeysIntoSharedZoneInfo(newAvailable),
			SelectedZonesList: convertKeysIntoSharedZoneInfo(newSelected),
		};
		if (currentSharedZoneIndex === -1) {
			currentSharedZonesToSave.push(previousSharedZoneModalInfo);
		} else {
			currentSharedZonesToSave[currentSharedZoneIndex] = previousSharedZoneModalInfo;
		}
		const action: Promise<ResponseObject> = whosInsideApi.saveSharedZones(currentSharedZonesToSave);
		saveCurrentTransferInfo(previousSharedZoneModalInfo, currentSharedZoneIndex);

		action.then(res =>
			NotificationStatus({
				responseData: res,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					onOkModal();
				},
				onFailedValidation: () => {
					setSaveSharedZone(false);
					form.setFields([
						{
							name: 'sharedZone',
							errors: [res.ErrorMessage],
						},
					]);
				},
				onSystemErrorCallback: () => {
					setSaveSharedZone(false);
					notification['error']({
						message: _(`ResponseStatusCode_${res.ResponseStatusCode}`),
					});
				},
			})
		);
	};

	const onValuesChange = values => {
		Object.keys(values).forEach(field => {
			const error: string[] = form.getFieldError(field);
			if (!error.length) {
				return;
			}

			form.setFields([
				{
					name: field,
					errors: [],
				},
			]);
		});
	};

	const disableSaveChanges: boolean = sharedZoneId === undefined;
	const disableAddZone: boolean = selectedLeftRowKeys.length === 0;
	const disableRemoveZone: boolean = selectedRightRowKeys.length === 0;

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={onHideModal}
			onClickOk={() => null}
			width={900}
			title={_('SharedZoneManager')}
			visible={true}
			footer={[
				<Button
					id='whosInsideSaveSharedZone'
					key='ok'
					type='primary'
					onClick={handleSaveSharedZone}
					disabled={disableSaveChanges || saveSharedZone || loadZones}>
					{_('SaveChanges')}
				</Button>,
				<Button id='whosInsideCancelSharedZone' key='cancel' onClick={onHideModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<Form form={form} component={false} onValuesChange={onValuesChange}>
				<div className={styles.modalContainer}>
					<div className={styles.nameContainer}>
						<label>{_('SharedZone')}</label>
						<Form.Item name={'sharedZone'} className={styles.nameInputContainer}>
							<Select
								ariaAttributes={{
									'aria-label': _('SelectSharedZone'),
									'aria-owns': 'sharedZonesDropdown',
									'aria-activedescendant': 'sharedZonesDropdown',
									'aria-controls': 'sharedZonesDropdown',
									'aria-expanded': 'true',
								}}
								className={styles.mapToSharedZonesDropdown}
								id='sharedZonesDropdown'
								notUseCurrentParse
								value={sharedZoneId}
								options={sharedZonesList.map((x, optionIndex) => ({
									label: x.ZoneName,
									value: x.ZoneId,
									id: `sharedZonesDropdownOptions-${optionIndex}`,
								}))}
								onChange={handleChangeCurrentSharedZone}
								getPopupContainer={element => element}
								showSearch={true}
								filterOption={filterOption}
								placeholder={_('SelectSharedZone')}
								disabled={loadZones}
							/>
						</Form.Item>
					</div>
					<div className={styles.transferContainer}>
						<div>
							{<strong>{_('AvailableZones')}</strong>}
							<Spin tip={`${_('Loading')}...`} spinning={loadZones || loadingTables.left} size='large'>
								<Table
									id={`whosInsideTableTransferLeft`}
									className={styles.transferTable}
									rowSelection={leftRowSelection}
									columns={leftColumns}
									dataSource={tableData.left}
									size='small'
									pagination={leftPagination}
									scroll={scroll}
									onChange={(pagination, filters, sorter) => handleOnChangeTable(pagination, filters, sorter, true)}
									onRow={({ key }) => ({ onClick: () => handleOnRowClick(key, true) })}
									onHeaderRow={() => ({ id: 'whosInsideTableTransferLeftHeader' })}
									getPopupContainer={() => document.getElementById('whosInsideTableTransferLeftHeader')}
								/>
							</Spin>
						</div>
						<div className={styles.transferActions}>
							<Button id='whosInsideAddZone' type='primary' onClick={handleOnAddZone} disabled={disableAddZone} icon={<DownOutlined />} />
							<Button id='whosInsideRemoveZone' type='primary' onClick={handleOnRemoveZone} disabled={disableRemoveZone} icon={<UpOutlined />} />
						</div>
						<div>
							{<strong>{_('SelectedZones')}</strong>}
							<Spin tip={`${_('Loading')}...`} spinning={loadZones || loadingTables.right} size='large'>
								<Table
									id={`whosInsideTableTransferRight`}
									className={styles.transferTable}
									rowSelection={rightRowSelection}
									columns={rightColumns}
									dataSource={tableData.right}
									size='small'
									pagination={rightPagination}
									scroll={scroll}
									onChange={(pagination, filters, sorter) => handleOnChangeTable(pagination, filters, sorter, false)}
									onRow={({ key }) => ({ onClick: () => handleOnRowClick(key, false) })}
									onHeaderRow={() => ({ id: 'whosInsideTableTransferRightHeader' })}
									getPopupContainer={() => document.getElementById('whosInsideTableTransferRightHeader')}
								/>
							</Spin>
						</div>
					</div>
				</div>
			</Form>
		</Modal>
	);
};

export { SharedZoneModal };

import React from 'react';
import { ControlZonesStdMaster, Input, SelectTimeZone } from '../../../../model/DeviceAdminModel';

export type ReaderState = {
	controlZones: ControlZonesStdMaster;
	lineModules: string[];
	input: Partial<Input>;
	openNewTimezoneModal: boolean;
	maskDuringTimezoneSelected: number;
	disableDuringTimezoneSelected: number;
};

enum ContextActionTypes {
	INIT_INPUT = 'INIT_INPUT',
	SET_CONTROL_TIME_ZONES = 'SET_CONTROL_TIME_ZONES',
	SET_LINE_MODULES = 'SET_LINE_MODULES',
	SET_INPUT = 'SET_INPUT',
	SET_NAME_ERROR = 'SET_NAME_ERROR',
	SET_NAME_VALIDATION = 'SET_NAME_VALIDATION',
	SET_RESET_NAME_ERROR = 'SET_RESET_NAME_ERROR',
	SET_OPEN_NEW_TIMEZONE_MODAL = 'SET_OPEN_NEW_TIMEZONE_MODAL',
	SET_MASK_DURING_TIMEZONE = 'SET_MASK_DURING_TIMEZONE',
	SET_DISABLE_DURING_TIMEZONE = 'SET_DISABLE_DURING_TIMEZONE',
	SET_MASK_TIMEZONE = 'SET_MASK_TIMEZONE',
	SET_DISABLE_TIMEZONE = 'SET_DISABLE_TIMEZONE',
}

type initInput = {
	type: ContextActionTypes.INIT_INPUT;
	payload: Partial<Input>;
};

type setControlTimeZones = {
	type: ContextActionTypes.SET_CONTROL_TIME_ZONES;
	payload: ControlZonesStdMaster;
};

type setLineModules = {
	type: ContextActionTypes.SET_LINE_MODULES;
	payload: string[];
};
type setInput = {
	type: ContextActionTypes.SET_INPUT;
	payload: Partial<Input>;
};

type setOpenNewTimezoneModal = {
	type: ContextActionTypes.SET_OPEN_NEW_TIMEZONE_MODAL;
	payload: boolean;
};

type setMaskDuringTimezone = {
	type: ContextActionTypes.SET_MASK_DURING_TIMEZONE;
	payload: number;
};

type setDisableDuringTimezone = {
	type: ContextActionTypes.SET_DISABLE_DURING_TIMEZONE;
	payload: number;
};

type setMaskTimezone = {
	type: ContextActionTypes.SET_MASK_TIMEZONE;
	payload: SelectTimeZone;
};

type setDisableTimezone = {
	type: ContextActionTypes.SET_DISABLE_TIMEZONE;
	payload: SelectTimeZone;
};

type ActionContext =
	| initInput
	| setControlTimeZones
	| setLineModules
	| setInput
	| setOpenNewTimezoneModal
	| setMaskDuringTimezone
	| setDisableDuringTimezone
	| setDisableTimezone
	| setMaskTimezone;

const inputContextState: ReaderState = {
	lineModules: [],
	controlZones: null,
	input: null,
	openNewTimezoneModal: false,
	maskDuringTimezoneSelected: -1,
	disableDuringTimezoneSelected: -1,
};

const inputContextReducers = (state: Readonly<ReaderState>, action: ActionContext): ReaderState => {
	switch (action.type) {
		case ContextActionTypes.INIT_INPUT:
			return {
				...state,
				maskDuringTimezoneSelected: action.payload.MaskDuringTimeZoneSelected.GenericId,
				disableDuringTimezoneSelected: action.payload.DisabledDuringTimeZoneSelected.GenericId,
				input: {
					...state.input,
					...action.payload,
				},
			};

		case ContextActionTypes.SET_CONTROL_TIME_ZONES:
			return { ...state, controlZones: action.payload };

		case ContextActionTypes.SET_LINE_MODULES:
			return { ...state, lineModules: [...action.payload] };

		case ContextActionTypes.SET_INPUT:
			return { ...state, input: { ...state.input, ...action.payload } };

		case ContextActionTypes.SET_OPEN_NEW_TIMEZONE_MODAL:
			return { ...state, openNewTimezoneModal: action.payload };

		case ContextActionTypes.SET_MASK_DURING_TIMEZONE:
			return { ...state, maskDuringTimezoneSelected: action.payload };

		case ContextActionTypes.SET_DISABLE_DURING_TIMEZONE:
			return { ...state, disableDuringTimezoneSelected: action.payload };

		case ContextActionTypes.SET_MASK_TIMEZONE:
			return {
				...state,
				input: {
					...state.input,
					MaskDuringTimeZoneSelected: action.payload,
				},
			};

		case ContextActionTypes.SET_DISABLE_TIMEZONE:
			return {
				...state,
				input: {
					...state.input,
					DisabledDuringTimeZoneSelected: action.payload,
				},
			};

		default:
			return { ...state };
	}
};

const initInputAction = (payload: Partial<Input>): initInput => {
	return {
		type: ContextActionTypes.INIT_INPUT,
		payload,
	};
};

const setControlZonesAction = (payload: ControlZonesStdMaster): setControlTimeZones => {
	return {
		type: ContextActionTypes.SET_CONTROL_TIME_ZONES,
		payload,
	};
};

const setLineModulesAction = (payload: string[]): setLineModules => {
	return {
		type: ContextActionTypes.SET_LINE_MODULES,
		payload,
	};
};

const setInputAction = (payload: Partial<Input>): setInput => {
	return {
		type: ContextActionTypes.SET_INPUT,
		payload,
	};
};

const setOpenNewTimezoneModalAction = (payload: boolean): setOpenNewTimezoneModal => {
	return {
		type: ContextActionTypes.SET_OPEN_NEW_TIMEZONE_MODAL,
		payload,
	};
};

const setMaskDuringTimezoneAction = (payload: number): setMaskDuringTimezone => {
	return {
		type: ContextActionTypes.SET_MASK_DURING_TIMEZONE,
		payload,
	};
};

const setDisableDuringTimezoneAction = (payload: number): setDisableDuringTimezone => {
	return {
		type: ContextActionTypes.SET_DISABLE_DURING_TIMEZONE,
		payload,
	};
};

const setMaskTimezoneAction = (payload: SelectTimeZone): setMaskTimezone => {
	return {
		type: ContextActionTypes.SET_MASK_TIMEZONE,
		payload,
	};
};

const setDisableTimezoneAction = (payload: SelectTimeZone): setDisableTimezone => {
	return {
		type: ContextActionTypes.SET_DISABLE_TIMEZONE,
		payload,
	};
};

type inputModalContext = {
	contextState: Readonly<ReaderState>;
	dispatcher: React.Dispatch<ActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const StoreContext = React.createContext<inputModalContext>({ contextState: inputContextState, dispatcher: null });

export {
	inputContextReducers,
	initInputAction,
	setControlZonesAction,
	setLineModulesAction,
	setInputAction,
	setOpenNewTimezoneModalAction,
	setMaskDuringTimezoneAction,
	setDisableDuringTimezoneAction,
	setMaskTimezoneAction,
	setDisableTimezoneAction,
	StoreContext,
	inputContextState,
};


import { Checkbox } from 'antd';
import React from 'react';
import { getTimeZoneInfoByList } from '../../../../../../../Helper';
import { PassbackOptionsInfoStore, SelectTimeZone, TimeZoneInfo } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerPassbackSetupAction, setOpenTimeZones } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerPassback } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectGenericTimeZones, selectPassbackOptionsInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../passback.module.scss';

const PassbackOptions: React.FC = () => {
	const dispatch = useStoreDispatch();
	const passbackOptionsInfo: PassbackOptionsInfoStore = useStoreSelector<PassbackOptionsInfoStore>(selectPassbackOptionsInfo);
	const timeZones: SelectTimeZone[] = useStoreSelector<SelectTimeZone[]>(selectGenericTimeZones);

	const handleChangeControllerPassBackSetup = (value: ControllerPassback) => {
		dispatch(
			setControllerPassbackSetupAction({
				...value,
			})
		);
	};

	const controllerPassbackOptionDisableTimeZoneDropdown = 'controllerPassbackOptionDisableTimeZoneDropdown';

	const disableDuringTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(passbackOptionsInfo.disableDuringTZ, timeZones);

	return (
		<CardSection title={`${_('PassbackOption')}:`}>
			<div className={styles.passbackOptions}>
				<label htmlFor={controllerPassbackOptionDisableTimeZoneDropdown}>{_('DisableDuringTZ')}</label>
				<Select
					id={controllerPassbackOptionDisableTimeZoneDropdown}
					onChange={value => {
						if (Number(value) === 0) {
							dispatch(
								setOpenTimeZones({
									index: -1,
									open: true,
									propertyType: 'PassbackOptions',
								})
							);
						} else {
							handleChangeControllerPassBackSetup({ OptionsDisableTimeZonePassback: Number(value) });
						}
					}}
					value={passbackOptionsInfo.disableDuringTZ}
					notUseCurrentParse
					options={disableDuringTimeZoneInfo.TimeZoneList}
					disabled={!passbackOptionsInfo.enabled || disableDuringTimeZoneInfo.IsUnpermitted}
				/>
				<Checkbox
					id='controllerPassbackEnableSingleZoneDropdown'
					disabled={!passbackOptionsInfo.enabled}
					onChange={e => handleChangeControllerPassBackSetup({ EnableSingleZone: e.target.checked })}
					checked={passbackOptionsInfo.enableSingleZone}>
					{_('EnableSingleZone')}
				</Checkbox>
			</div>
		</CardSection>
	);
};

export { PassbackOptions };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alarms-alarmPageContainer--3IqzftsIessSPvGPwQd2Jp>div:last-child{padding-top:3em}", "",{"version":3,"sources":["webpack://src/pages/Alarms/alarms.module.scss"],"names":[],"mappings":"AACC,kEACC,eAAA","sourcesContent":[".alarmPageContainer {\r\n\t& > div:last-child {\r\n\t\tpadding-top: 3em;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alarmPageContainer": "alarms-alarmPageContainer--3IqzftsIessSPvGPwQd2Jp"
};
export default ___CSS_LOADER_EXPORT___;

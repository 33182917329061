import { Spin, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import {
	ScrollType,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	numericPositiveAndNegativeNonDecimalFormatter,
	parseDateTimeString,
} from '../../../../Helper';
import { enrollmentApi } from '../../../../api/';
import { DefaultColumns, PaginationSetting, ResponseStatusCode, SelectOptions, SortDirections, SorterConfigEx } from '../../../../model/CommonModel';
import { OptionsButtonBuilder } from '../../../../model/DeviceAdminModel';
import { AdvanceSearchCriteria, EnrollmentGridItem, PersonActionOptions, TreeViewPersonGroupModel } from '../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import {
	loadPersonInformation,
	setIsPersonTableLoading,
	setPersonTableInformationByPage,
	setPersonTablePaginationSettings,
	setPersonTableSorterConfig,
	setSelectedRowKeys,
} from '../../../../store/enrollment/actions';
import {
	selectAdvanceSearchCriteria,
	selectBlankProfilePictureBase64,
	selectIsPersonTableLoading,
	selectPersonTableInformation,
	selectPersonTablePaginationSettings,
	selectPersonTableSorterConfig,
	selectSelectedPersonGroup,
	selectSelectedRowKeys,
	selectUpdateCounter,
} from '../../../../store/enrollment/selectors';
import { EditableCell } from '../../../common';
import { createEnrollmentButtonOptions } from '../../helper';
import styles from '../enrollmentTab.module.scss';

const checkedKey: string = 'Checked';
const idKey: string = 'Key';
type Props = {
	handleActionScope: (key: PersonActionOptions) => Promise<void>;
};

const PersonTable: React.FC<Props> = ({ handleActionScope }) => {
	const dispatch = useStoreDispatch();
	const [tableColumns, setTableColumns] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [totalItemsPagination, setTotalItemsPagination] = useState<number>();
	const selectedPersonGroup: TreeViewPersonGroupModel = useStoreSelector<TreeViewPersonGroupModel>(selectSelectedPersonGroup);
	const personTableInformation: EnrollmentGridItem = useStoreSelector<EnrollmentGridItem>(selectPersonTableInformation);
	const isPersonTableLoading: boolean = useStoreSelector<boolean>(selectIsPersonTableLoading);
	const blankProfilePictureBase64: string = useStoreSelector<string>(selectBlankProfilePictureBase64);
	const selectedRowKeys: React.Key[] = useStoreSelector<React.Key[]>(selectSelectedRowKeys);
	const personTablePaginationSettings: PaginationSetting = useStoreSelector<PaginationSetting>(selectPersonTablePaginationSettings);
	const updateCounter: number = useStoreSelector<number>(selectUpdateCounter);
	const advanceSearchCriteria: AdvanceSearchCriteria = useStoreSelector<AdvanceSearchCriteria>(selectAdvanceSearchCriteria);
	const sorterConfigEx: SorterConfigEx = useStoreSelector<SorterConfigEx>(selectPersonTableSorterConfig);

	useEffect(() => {
		if (dataSource.length > 0) {
			const data = dataSource.map(d => {
				let row = {};
				d.forEach(item => {
					row = {
						...row,
						[item.Key.toLowerCase()]: item.Value,
					};
				});
				return row;
			});
			setDataTable(data);

			const previousSelectedRowKeys: React.Key[] = getPreviousSelectedRowKeys(dataSource);
			dispatch(setSelectedRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedRowKeys)));
		}
	}, [dataSource]);

	useEffect(() => {
		if (personTableInformation) {
			if (personTableInformation?.TotalItems !== 0) {
				const previousState = dataSource.filter(c => {
					const checkedIndex = c.findIndex(x => x.Key === checkedKey);
					return c[checkedIndex].Value;
				});

				const payload = [];

				personTableInformation.Data.forEach(item => {
					const checkedIndex = item.findIndex(x => x.Key === checkedKey);
					const idIndex = item.findIndex(x => x.Key === idKey);
					item[checkedIndex].Value = previousState.some(x => x.Value === item[idIndex].Value).toString();

					payload.push([...item]);
				});

				setDataSource(payload);
			} else {
				clearTable();
			}

			const columns = personTableInformation.Columns.map(c => {
				return {
					...buildColumn(_(c.Value), c.Key, 'auto', 'start'),
					dataIndex: c.Key.toLowerCase(),
					key: c.Key,
					sorter: !c.DisableSort,
					sortOrder: sorterConfigEx.columnKey === c.Key && sorterConfigEx.order,
					render: value => {
						let text = value;
						if (c.IsDateFormat) {
							if (numericPositiveAndNegativeNonDecimalFormatter(c.Key)) {
								text = parseDateTimeString(value, false, value);
							} else {
								text = parseDateTimeString(value, true, value);
							}
						}

						return <span>{text}</span>;
					},
				};
			});

			setTotalItemsPagination(personTableInformation.TotalItems);
			setTableColumns(columns);
		} else {
			clearTable();
		}
	}, [personTableInformation]);

	useEffect(() => {
		if (updateCounter !== 0) {
			dispatch(setPersonTableInformationByPage(personTablePaginationSettings));
		}
	}, [updateCounter]);

	const hasMoreThanOne = selectedRowKeys.length > 1;
	const items: OptionsButtonBuilder<string | number> = createEnrollmentButtonOptions(hasMoreThanOne);
	const contextOptions: SelectOptions<string | number>[] = items.options.filter(x => !(x.value === PersonActionOptions.Edit && hasMoreThanOne));
	const currentColumns = [...tableColumns];
	if (personTableInformation?.DisplayPicture) {
		const rowSelectionColumn = {
			...buildColumn('', 'photo', '80px', 'start'),
			render: (text, row) => {
				if (row?.photo) {
					return <img width={50} src={`data:image/bmp;base64,${row?.photo}`} alt={'UserImage'} />;
				} else {
					return <img width={50} src={`data:image/bmp;base64,${blankProfilePictureBase64}`} alt={`BlankProfile`} />;
				}
			},
		};
		currentColumns.unshift(rowSelectionColumn);
	}

	const mergedColumns = currentColumns.map(col => ({
		...col,
		onCell: (record: DefaultColumns) => ({
			record,
			dataIndex: col.dataIndex,
			title: col.title,
			options: contextOptions,
			isTableInEditMode: false,
			onChangeSelection,
			onClickOption: handleActionScope,
		}),
	}));

	const clearTable = () => {
		setDataSource([]);
		setTotalItemsPagination(0);
		setDataTable([]);
		batch(() => {
			dispatch(setPersonTableSorterConfig({ columnKey: undefined, order: undefined }));
			dispatch(setSelectedRowKeys([]));
			dispatch(setPersonTablePaginationSettings({ ...personTablePaginationSettings, PageNumber: 1, SortDirection: SortDirections.None, SortField: '' }));
		});
	};

	const onChangeSelection = key => {
		const personId = key.key;
		const cloneStateProps = [];
		if (selectedRowKeys.findIndex(x => x === personId) === -1) {
			dispatch(setSelectedRowKeys([personId]));
			dataSource.forEach(item => {
				const checkedIndex = item.findIndex(x => x.Key === checkedKey);
				const idIndex = item.findIndex(x => x.Key === idKey);
				item[checkedIndex].Value = `${item[idIndex].Value === personId}`;
				cloneStateProps.push([...item]);
			});

			setDataSource(cloneStateProps);
		}
	};

	const handleOnChangeTable = (pagination, filters, sorter) => {
		const { order, field } = sorter;
		const { current, pageSize } = pagination;
		let sortOrder = SortDirections.None;
		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		const paginationSetting: PaginationSetting = {
			PageNumber: current,
			PageSize: pageSize,
			SortField: field,
			SortDirection: sortOrder,
			SearchedValue: personTablePaginationSettings.SearchedValue,
		};

		batch(() => {
			dispatch(setPersonTableSorterConfig(sorter));
			dispatch(setPersonTableInformationByPage(paginationSetting));
		});
	};

	const getPreviousSelectedRowKeys = (dataSource): React.Key[] => {
		const previousSelectedRowKeys: React.Key[] = dataSource.reduce((result: React.Key[], item) => {
			const checkedIndex = item.findIndex(x => x.Key === checkedKey);
			if (item[checkedIndex].Value.toLowerCase() === 'true') {
				const idIndex = item.findIndex(x => x.Key === idKey);
				result.push(item[idIndex].Value);
			}
			return result;
		}, []);

		return previousSelectedRowKeys;
	};

	const handleChange = (newSelectedRowKeys: React.Key[]) => {
		dispatch(setSelectedRowKeys(newSelectedRowKeys));
		const cloneStateProps = [];

		dataSource.forEach(item => {
			const checkedIndex = item.findIndex(x => x.Key === checkedKey);
			const idIndex = item.findIndex(x => x.Key === idKey);
			item[checkedIndex].Value = `${newSelectedRowKeys.findIndex(key => key === item[idIndex].Value) !== -1}`;

			cloneStateProps.push([...item]);
		});

		setDataSource(cloneStateProps);
	};

	const handleSelectAll = () => {
		const currentAdvanceSearchCriteria: AdvanceSearchCriteria = {
			GroupId: selectedPersonGroup.GroupId,
			FolderId: selectedPersonGroup.FolderId,
			CriteriaRequest: advanceSearchCriteria?.CriteriaRequest,
		};

		dispatch(setIsPersonTableLoading(true));
		enrollmentApi
			.getAllUsersPagination(currentAdvanceSearchCriteria, personTablePaginationSettings.SearchedValue)
			.then(res => {
				if (res.ResponseStatusCode === ResponseStatusCode.Success) {
					const newSelectedRowKeys = getUniqueValuesArray(selectedRowKeys, res.Entity);
					dispatch(setSelectedRowKeys(newSelectedRowKeys));
					let cloneStateProps = [];

					dataSource.forEach(item => {
						const checkedIndex = item.findIndex(x => x.Key === checkedKey);
						const idIndex = item.findIndex(x => x.Key === idKey);
						item[checkedIndex].Value = `${newSelectedRowKeys.findIndex(key => key === item[idIndex].Value) !== -1}`;

						cloneStateProps.push([...item]);
					});

					setDataSource(cloneStateProps);
				} else {
					handleResponse(res);
				}
			})
			.finally(() => dispatch(setIsPersonTableLoading(false)));
	};

	const handleSelectInvert = () => {
		const dataKeys = dataSource.reduce<React.Key[]>((result, item) => {
			const idIndex = item.findIndex(x => x.Key === idKey);
			result.push(item[idIndex].Value);
			return result;
		}, []);

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		dispatch(setSelectedRowKeys(newSelectedRowKeys));
		const cloneStateProps = [];

		dataSource.forEach(item => {
			const checkedIndex = item.findIndex(x => x.Key === checkedKey);
			const idIndex = item.findIndex(x => x.Key === idKey);
			item[checkedIndex].Value = `${newSelectedRowKeys.findIndex(key => key === item[idIndex].Value) !== -1}`;

			cloneStateProps.push([...item]);
		});

		setDataSource(cloneStateProps);
	};

	const rowSelection: TableRowSelection<unknown> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(false, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => {
			return {
				id: `enrollmentPerson-${record['key']}`,
				children: <label htmlFor={`enrollmentPerson-${record['key']}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
			};
		},
		selectedRowKeys: selectedRowKeys,
		onChange: handleChange,
		renderCell: (value: boolean, record, index: number, originNode: React.ReactNode) => (
			<div
				className={styles.rowSelectionContainer}
				onClick={e => {
					e.stopPropagation();
				}}>
				{originNode}
			</div>
		),
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		if (pageSize !== personTablePaginationSettings.PageSize) {
			dispatch(setPersonTablePaginationSettings({ ...personTablePaginationSettings, PageNumber: page, PageSize: pageSize }));
		} else {
			dispatch(setPersonTablePaginationSettings({ ...personTablePaginationSettings, PageNumber: page }));
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangePagination,
		personTablePaginationSettings.PageNumber,
		personTablePaginationSettings.PageSize,
		totalItemsPagination,
		undefined,
		selectedRowKeys
	);

	const scroll: ScrollType = { x: `${500 + tableColumns.length * 170}px`, y: '60vh' };

	const handleOnClickPerson = record => {
		dispatch(loadPersonInformation(Number(record.key)));
	};

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isPersonTableLoading} size='large'>
			<Table
				id='enrollmentPersonTable'
				className={styles.personTable}
				columns={mergedColumns as ColumnsType}
				dataSource={dataTable}
				scroll={scroll}
				rowSelection={rowSelection}
				size='small'
				pagination={pagination}
				onChange={handleOnChangeTable}
				rowClassName={(record, index) => {
					if (index % 2 !== 0) {
						return styles.evenRow;
					}
				}}
				onRow={(record, rowIndex) => {
					return {
						onClick: event => {
							handleOnClickPerson(record);
						},
					};
				}}
				components={{
					body: {
						cell: EditableCell,
					},
				}}
			/>
		</Spin>
	);
};

export { PersonTable };

import { ControlZone, ControlZonesEditor, MasterControlZonesEditor, StandardControlZonesEditor } from '../../../model/DeviceAdminModel';

export enum ActionTypes {
	SET_CONTROL_ZONES_FOR_CONTROLLER = 'SET_CONTROL_ZONES_FOR_CONTROLLER',
	SET_CONTROL_ZONE_EDITOR = 'SET_CONTROL_ZONE_EDITOR',
	SET_MASTER_CONTROL_ZONES = 'SET_MASTER_CONTROL_ZONES',
	SET_STANDARD_CONTROL_ZONE_LIST = 'SET_STANDARD_CONTROL_ZONE_LIST',
	SET_CONTROL_ZONES_EDIT_MODE = 'SET_CONTROL_ZONES_EDIT_MODE',
	SET_CONTROL_ZONES_HAS_CHANGE_NAME = 'SET_CONTROL_ZONES_HAS_CHANGE_NAME',
}

export type SetControlZonesForController = {
	type: ActionTypes.SET_CONTROL_ZONES_FOR_CONTROLLER;
	payload: ControlZonesEditor;
};

export type SetControlZonesEditor = {
	type: ActionTypes.SET_CONTROL_ZONE_EDITOR;
	payload: { data: Partial<StandardControlZonesEditor>; propertyIndex: string };
};

export type SeMasterControlZones = {
	type: ActionTypes.SET_MASTER_CONTROL_ZONES;
	payload: { propertyIndex: string; value: Partial<MasterControlZonesEditor> };
};

export type SetStandardListControlZones = {
	type: ActionTypes.SET_STANDARD_CONTROL_ZONE_LIST;
	payload: ControlZone;
};

export type SetControlZonesEditMode = {
	type: ActionTypes.SET_CONTROL_ZONES_EDIT_MODE;
	payload: boolean;
};

export type SetControlZonesHasChangeName = {
	type: ActionTypes.SET_CONTROL_ZONES_HAS_CHANGE_NAME;
	payload: boolean;
};

export type ControlZoneActionType =
	| SetControlZonesForController
	| SetControlZonesEditor
	| SeMasterControlZones
	| SetStandardListControlZones
	| SetControlZonesEditMode
	| SetControlZonesHasChangeName;

import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { PortStoreContext, setValueSNIBSelectedAction } from '../../../../../contextPort';
import styles from '../../snibConfiguration.module.scss';

type Props = {
	isSNIBNameInvalid: boolean;
	onResetSNIBNameError: () => void;
};

const Name: React.FC<Props> = ({ isSNIBNameInvalid, onResetSNIBNameError }) => {
	const {
		portState: { snibSelected },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'Hostname', value: value }));
		onResetSNIBNameError();
	};

	const searchSNIBConfigurationNameInputId = 'searchSNIBConfigurationNameInput';

	return (
		<>
			<div className={styles.name}>
				<label id='searchSNIBConfigurationNameLabel' key='NameLabel'>
					{_('Name')}
				</label>
				<div className={styles.inputContainer}>
					<Input
						id={searchSNIBConfigurationNameInputId}
						key='NameInput'
						maxLength={32}
						value={snibSelected?.Hostname}
						onChange={handleChange}
						className={cx({
							[styles.error]: isSNIBNameInvalid,
						})}
					/>
					{isSNIBNameInvalid && (
						<label id='searchSNIBConfigurationNameErrorLabel' htmlFor={searchSNIBConfigurationNameInputId} className={styles.errorMessage}>
							{_('EmptySNIBName')}
						</label>
					)}
				</div>
			</div>
		</>
	);
};

export { Name };

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { SelectOptions } from '../../../model/CommonModel';
import styles from './dropdown-menu.module.scss';

type Props<T> = {
	label: string;
	title?: string;
	onClickOption?: (value: T) => void;
	menuOptions: SelectOptions<T>[];
	disabled?: boolean;
	id?: string;
};

const DropdownMenu = <T extends object | string | number>({ onClickOption, menuOptions, label, disabled, title, id }: Props<T>) => {
	const menu = (
		<Menu>
			{menuOptions.map(({ label, value, icon, separator, disabled, title, id }, index) => (
				<Menu.Item
					id={id}
					key={`${label}-${index}`}
					icon={icon}
					onClick={() => onClickOption?.(value)}
					className={separator ? styles.separator : undefined}
					disabled={disabled}
					title={title}>
					{label}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<Dropdown overlay={menu} disabled={disabled} overlayStyle={{ width: 'max-content' }}>
			<Button id={id} title={title}>
				{label} <DownOutlined />
			</Button>
		</Dropdown>
	);
};

export { DropdownMenu };

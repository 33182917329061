import { createSelector } from 'reselect';
import { getUnpermittedValue } from '../../../components/devicecontrol/DigiTrac/ControllerModal/tabs/General/BasicControllerInformation/BasicControllerInformation';
import {
	AdvanceReportingSetupInfoStore,
	AlarmRelayTimerInfoStore,
	BasicControllerInfoStore,
	BufferAllocationInfo,
	CodeTamperAlarmInfoStore,
	CommunicationsInfoStore,
	ControllerAdvancedReportingSetup,
	ControllerBasicSetup,
	ControllerInfoStore,
	ControllerMiscSystemSetup,
	ControllerPassbackSetup,
	ControllerReportingSetup,
	ControllerType,
	DisableReportingDuringTimeZoneInfoStore,
	DisableReportingOfInfoStore,
	FirmwareRevisionInfoStore,
	GenericControlTimeZones,
	HardwareConfigurationInfoStore,
	LocalPrinterSetupInfoStore,
	OccupancySetupByZone,
	OnPassbackViolationInfoStore,
	OptionsForLocalKeypadInfoStore,
	OptionsInfoStore,
	OptionsInstalledInfoStore,
	OptionsViolationsZoneInfoStore,
	PassbackOptionsInfoStore,
	SCIBInfoStore,
	SelectOption,
	SelectTimeZone,
	TwoPersonRuleInfoStore,
} from '../../../model/DeviceAdminModel';
import { RootReducer } from '../../rootReducer';
import { ControlZonesState } from '../controlZones/reducer';
import { ErrorTabs } from './actionTypes';
import { ControllerState } from './reducer';

export const selectControllerInfo = createSelector(
	[(state: RootReducer) => state.controllerReducer, (state: RootReducer) => state.controlZonesReducer],
	(controllerReducer: ControllerState, controlZonesReducer: ControlZonesState) => {
		const retValue: ControllerInfoStore = {
			controllerData: controllerReducer,
			isControlZoneEditing: controlZonesReducer.editMode,
			hasChangedName: controlZonesReducer.hasChangedName,
			hasChangedController: controllerReducer.hasChanged,
		};

		return retValue;
	}
);

export const selectCommunicationsInfo = createSelector(
	[
		(state: RootReducer) => state.controllerReducer.ControllerReportingSetup.CommunicationsSNETTimeout,
		(state: RootReducer) => state.controllerReducer.ControllerBasicSetup,
	],
	(CommunicationsSNETTimeout: number, ControllerBasicSetup: ControllerBasicSetup) => {
		const retValue: CommunicationsInfoStore = {
			timeOut: CommunicationsSNETTimeout,
			networkPasswords: {
				pass1: ControllerBasicSetup.Newhstpswd1,
				pass2: ControllerBasicSetup.Newhstpswd2,
				pass3: ControllerBasicSetup.Newhstpswd3,
				pass4: ControllerBasicSetup.Newhstpswd4,
			},
			encryption: {
				encrypt1: ControllerBasicSetup.Newdefheskey1,
				encrypt2: ControllerBasicSetup.Newdefheskey2,
				encrypt3: ControllerBasicSetup.Newdefheskey3,
				encrypt4: ControllerBasicSetup.Newdefheskey4,
			},
		};

		return retValue;
	}
);

export const selectBasicControllerInfo = createSelector(
	(state: RootReducer) => state.controllerReducer,
	(controllerReducer: ControllerState) => {
		const retValue: BasicControllerInfoStore = {
			name: controllerReducer.Name,
			description: controllerReducer.Description,
			timeZoneDisplayName: controllerReducer.TimeZoneDisplayName,
			systemTimeZones: controllerReducer.SystemTimeZones,
			isUseDST: controllerReducer.IsUseDST,
			controllerAddressId: controllerReducer.AddressId,
			availableAddresses: controllerReducer.AvailableAddresses,
			controllerType: controllerReducer.ControllerBasicSetup.ControllerType,
			wirelessVendor: controllerReducer.ControllerMiscSystemSetup.WirelessVendor,
			wirelessVendorList: controllerReducer.WirelessReaderTypeList,
			commandSetId: controllerReducer.CommandSetId,
			controllerCommandSets: controllerReducer.ControllerCommandSets,
			id: controllerReducer.ControllerId,
			isEnabled: controllerReducer.IsPanelInstalled === 1,
			address: controllerReducer.Address,
			isCommandSetAllowed: getUnpermittedValue(controllerReducer.CommandSetId, controllerReducer.ControllerCommandSets),
			error: controllerReducer.error,
		};

		return retValue;
	}
);

export const selectFirmwareRevisionInfo = createSelector(
	(state: RootReducer) => state.controllerReducer,
	(controllerReducer: ControllerState) => {
		const retValue: FirmwareRevisionInfoStore = {
			canUpdateFirmware: controllerReducer.CanUpdateFirmware,
			enableUpdateSNIB: controllerReducer.ControllerBasicSetup.EnableUpdateSNIB,
			SupportsSNIB3: controllerReducer.ControllerBasicSetup.SupportsSNIB3,
			firmwareMessage: controllerReducer.FirmwareMessages,
			controllerId: controllerReducer.ControllerId,
			firmwareRevision: controllerReducer.FirmwareRevision,
			writeProtect: controllerReducer.ControllerBasicSetup.WriteProtect,
		};

		return retValue;
	}
);

export const selectControllerId = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerId,
	(ControllerId: number) => ControllerId
);

export const selectSupportsSNIB3 = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerBasicSetup.SupportsSNIB3,
	(SupportsSNIB3: boolean) => SupportsSNIB3
);

export const selectOccupancyTwoPersonsRules = createSelector(
	(state: RootReducer) => state.controllerReducer.occupancyTwoPersonsRules,
	(occupancyTwoPersonsRules: OccupancySetupByZone[]) => occupancyTwoPersonsRules
);

export const selectCurrentRuleId = createSelector(
	(state: RootReducer) => state.controllerReducer.currentRuleId,
	(currentRuleId: number) => currentRuleId
);

export const selectHardwareConfigurationInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerBasicSetup,
	(ControllerBasicSetup: ControllerBasicSetup) => {
		const retValue: HardwareConfigurationInfoStore = {
			alarmRelay: ControllerBasicSetup.AlarmRelays,
			inputs: ControllerBasicSetup.Inputs,
			relays: ControllerBasicSetup.Relays,
			xInputs: ControllerBasicSetup.XInputs,
			xRelays: ControllerBasicSetup.XRelays,
			vRelays: ControllerBasicSetup.VRelays,
		};

		return retValue;
	}
);

export const selectOptionsInstalledInfo = createSelector(
	[
		(state: RootReducer) => state.controllerReducer.ControllerBasicSetup.InstalledEquipment,
		(state: RootReducer) => state.controllerReducer.BufferAllocationInfo,
	],
	(InstalledEquipment: string[], BufferAllocationInfo: BufferAllocationInfo) => {
		const retValue: OptionsInstalledInfoStore = {
			optionsInstalled: InstalledEquipment,
			allocateBufferStatus: BufferAllocationInfo,
		};

		return retValue;
	}
);

export const selectOnPassbackViolationInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerPassbackSetup,
	(ControllerPassbackSetup: ControllerPassbackSetup) => {
		const retValue: OnPassbackViolationInfoStore = {
			reportEnablePassBackOccupancyTracking: ControllerPassbackSetup.OnPassbackViolationReport,
			generateAlarm: ControllerPassbackSetup.OnPassbackViolationGenerateAlarm,
			denyAccess: ControllerPassbackSetup.OnPassbackViolationDenyAccess,
		};

		return retValue;
	}
);

export const selectOptionsViolationsZoneInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerPassbackSetup,
	(ControllerPassbackSetup: ControllerPassbackSetup) => {
		const retValue: OptionsViolationsZoneInfoStore = {
			reportOptionsOccupancyViolationsInAllZones: ControllerPassbackSetup.ReportAllOccupancyViolations,
			occupancyZones: ControllerPassbackSetup.OccupancyZones,
			occupancySetupByZone: ControllerPassbackSetup.OccupancySetupByZone,
			enabled:
				ControllerPassbackSetup.OnPassbackViolationReport ||
				ControllerPassbackSetup.OnPassbackViolationGenerateAlarm ||
				ControllerPassbackSetup.OnPassbackViolationDenyAccess,
		};

		return retValue;
	}
);

export const selectPassbackOptionsInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerPassbackSetup,
	(ControllerPassbackSetup: ControllerPassbackSetup) => {
		const retValue: PassbackOptionsInfoStore = {
			disableDuringTZ: ControllerPassbackSetup.OptionsDisableTimeZonePassback,
			enableSingleZone: ControllerPassbackSetup.EnableSingleZone,
			enabled:
				ControllerPassbackSetup.OnPassbackViolationReport ||
				ControllerPassbackSetup.OnPassbackViolationGenerateAlarm ||
				ControllerPassbackSetup.OnPassbackViolationDenyAccess,
		};

		return retValue;
	}
);

export const selectTwoPersonRuleInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerPassbackSetup,
	(ControllerPassbackSetup: ControllerPassbackSetup) => {
		const retValue: TwoPersonRuleInfoStore = {
			timeIncrementForEachRead: ControllerPassbackSetup.TwoPersonTimeIncrement,
			thresholdTime: ControllerPassbackSetup.TwoPersonThresholdTime,
			disableDuringTimeZoneId: ControllerPassbackSetup.TwoPersonDisableTimeZonePassback,
		};

		return retValue;
	}
);

export const selectAdvanceReportingSetupInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerReportingSetup.AdvancedReporting,
	(AdvancedReporting: ControllerAdvancedReportingSetup) => {
		const retValue: AdvanceReportingSetupInfoStore = {
			messagesTypesOptions: AdvancedReporting.MessageFiltersTypes.map(x => ({ label: x.Name, value: x.Id })),
			messagesFilters: AdvancedReporting.MessageFilters,
			enableCommandEcho: AdvancedReporting.EnableCommandEcho,
			enablePrintingSNETMessagesOnLocalPrinter: AdvancedReporting.EnablePrintingSNET,
			enableMCZReporting: AdvancedReporting.EnableMCZReporting,
			enableACBReporting: AdvancedReporting.EnableACBReporting,
		};

		return retValue;
	}
);

export const selectDisableReportingDuringTimeZoneInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerReportingSetup,
	(ControllerReportingSetup: ControllerReportingSetup) => {
		const retValue: DisableReportingDuringTimeZoneInfoStore = {
			onLocalPrinter: ControllerReportingSetup.DisableReportingOnLocalPrinterTimeZone,
			transaction: ControllerReportingSetup.DisableReportingTransactionsTimeZone,
			events: ControllerReportingSetup.DisableReportingEventsTimeZone,
			grantedTransactions: ControllerReportingSetup.DisableReportingGrantedTransactionsTimeZone,
		};

		return retValue;
	}
);

export const selectDisableReportingOfInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerReportingSetup,
	(ControllerReportingSetup: ControllerReportingSetup) => {
		const retValue: DisableReportingOfInfoStore = {
			dailyMidnightReport: ControllerReportingSetup.DisableReportingDailyMidnight,
			internalEvent: ControllerReportingSetup.DisableReportingInternalEvents,
			externalEvents: ControllerReportingSetup.DisableReportingExternalEvents,
			transactionsEvents: ControllerReportingSetup.DisableReportingTransactionEvents,
			invalidCodes: ControllerReportingSetup.DisableReportingInvalidCodes,
			relayStateChanges: ControllerReportingSetup.DisableReportingRelayStateChanges,
			timeZoneStateChanges: ControllerReportingSetup.DisableReportingTimeZoneStateChanges,
			tzDrivenRelayStateChanges: ControllerReportingSetup.DisableReportingTimeZoneDrivenRelay,
			tzDrivenInputMaskingChanges: ControllerReportingSetup.DisableReportingTimeZoneDrivenInput,
		};

		return retValue;
	}
);

export const selectError = createSelector(
	(state: RootReducer) => state.controllerReducer.error,
	(error: ErrorTabs) => error
);

export const selectGenericTimeZones = createSelector(
	(state: RootReducer) => state.controllerReducer.GenericTimeZones,
	(GenericTimeZones: SelectTimeZone[]) => GenericTimeZones
);

export const selectLocalPrinterSetupInfo = createSelector(
	[
		(state: RootReducer) => state.controllerReducer.ControllerReportingSetup,
		(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup.LocalPrinterLanguage,
	],
	(ControllerReportingSetup: ControllerReportingSetup, LocalPrinterLanguage: number) => {
		const retValue: LocalPrinterSetupInfoStore = {
			linesPerPageForFormFeed: ControllerReportingSetup.LocalPrinterLinesPerPage,
			languageOptions: ControllerReportingSetup.LanguageOptions,
			languageId: LocalPrinterLanguage,
		};

		return retValue;
	}
);

export const selectHideLocalKeypad = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerBasicSetup.ControllerType,
	(ControllerTypeValue: ControllerType) => ControllerTypeValue === ControllerType.Mx1w
);

export const selectControlZones = createSelector(
	(state: RootReducer) => state.controllerReducer.ControlZones,
	(ControlZones: GenericControlTimeZones[]) => ControlZones
);

export const selectAlarmRelayTimerInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup,
	(ControllerMiscSystemSetup: ControllerMiscSystemSetup) => {
		const retValue: AlarmRelayTimerInfoStore = {
			general: {
				timer: ControllerMiscSystemSetup.AlarmRelayGeneralTimer,
				mapToRelay: ControllerMiscSystemSetup.AlarmRelayGeneralMapToRelay,
			},
			duress: {
				timer: ControllerMiscSystemSetup.AlarmRelayDuressTimer,
				mapToRelay: ControllerMiscSystemSetup.AlarmRelayDuressMapToRelay,
			},
			tamper: {
				timer: ControllerMiscSystemSetup.AlarmRelayTamperTimer,
				mapToRelay: ControllerMiscSystemSetup.AlarmRelayTamperMapToRelay,
			},
			trouble: {
				timer: ControllerMiscSystemSetup.AlarmRelayTroubleTimer,
				mapToRelay: ControllerMiscSystemSetup.AlarmRelayTroubleMapToRelay,
			},
		};

		return retValue;
	}
);

export const selectCodeTamperAlarmInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup,
	(ControllerMiscSystemSetup: ControllerMiscSystemSetup) => {
		const retValue: CodeTamperAlarmInfoStore = {
			invalidPenaltyTimer: ControllerMiscSystemSetup.CodeTamperInvalidPenaltyTimer,
			thresholdTimer: ControllerMiscSystemSetup.CodeTamperThresholdTimer,
			lockoutTime: ControllerMiscSystemSetup.CodeTamperLockoutTimer,
		};

		return retValue;
	}
);

export const selectEnabledUserDBlocation = createSelector(
	(state: RootReducer) => state.controllerReducer.EnabledUserDBLocation,
	(EnabledUserDBLocation: number) => EnabledUserDBLocation
);

export const selectSupportsSNIB3DB = createSelector(
	(state: RootReducer) => state.controllerReducer.SupportsSNIB3DB,
	(SupportsSNIB3DB: boolean) => SupportsSNIB3DB
);

export const selectIsSEKInstalled = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerBasicSetup.IsSEKInstalled,
	(IsSEKInstalled: boolean) => IsSEKInstalled
);

export const selectUserDBLocationList = createSelector(
	(state: RootReducer) => state.controllerReducer.UserDBLocationList,
	(UserDBLocationList: SelectOption[]) => UserDBLocationList.map(x => ({ label: x.Name, value: x.Id }))
);

export const selectOptionsInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup,
	(ControllerMiscSystemSetup: ControllerMiscSystemSetup) => {
		const retValue: OptionsInfoStore = {
			enableDuressAlarmMode: ControllerMiscSystemSetup.OptionsEnableDuressAlarm,
			emptyLocalAlarmViaReset: ControllerMiscSystemSetup.OptionsEmptyLocalAlarm,
			validateOnDualCCOTZ: ControllerMiscSystemSetup.OptionsValidateOnDualCCOTZ,
			validateImmediatelyCCOTZ: ControllerMiscSystemSetup.OptionsValidateImmediatelyCCOTZ,
			enableGlobalCredentialManagement: ControllerMiscSystemSetup.OptionsEnableGlobalCredentialManagement,
			enableGlobalMCZ: ControllerMiscSystemSetup.OptionsEnableGlobalMCZ,
			advancedBypassErrors: ControllerMiscSystemSetup.AdvancedBypassErrors,
			advancedBypassCodeConflict: ControllerMiscSystemSetup.AdvancedBypassCodeConflict,
			advancedEnableSEK: ControllerMiscSystemSetup.AdvancedEnableSEK,
		};

		return retValue;
	}
);

export const selectOptionsForLocalKeypadInfo = createSelector(
	[(state: RootReducer) => state.controllerReducer.ControllerBasicSetup, (state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup],
	(ControllerBasicSetup: ControllerBasicSetup, ControllerMiscSystemSetup: ControllerMiscSystemSetup) => {
		const retValue: OptionsForLocalKeypadInfoStore = {
			timeout: ControllerMiscSystemSetup.LocalKeypadProgrammingTimeout,
			systemCode: ControllerBasicSetup.MasterCode,
			isMasterCodeDisable: ControllerBasicSetup.IsDisableMasterCode,
			enableLocalSystemCodeReset: ControllerMiscSystemSetup.LocalKeypadProgrammingEnableCodeReset,
			localPrinterShowsNewCredentialCodes: ControllerMiscSystemSetup.LocalKeypadProgrammingPrinterShowsNewCredentialCodes,
			autoGenCodeLength: ControllerMiscSystemSetup.LocalKeypadProgrammingAutoGenCode,
			genCodesWIthDuressDigit: ControllerMiscSystemSetup.LocalKeypadProgrammingAutoGenCodesWithDuress,
			duressDigit: ControllerMiscSystemSetup.LocalKeypadProgrammingDuressDigit,
			reduceAutoGenPrintout: ControllerMiscSystemSetup.LocalKeypadProgrammingReduceAutoGenPrintOut,
			autoGenCodeLengthOptions: ControllerMiscSystemSetup.LocalKeypadProgrammingAutoGenCodesWithDuressOptions,
			duressDigitOptions: ControllerMiscSystemSetup.LocalKeypadProgrammingDuressDigitOptions,
			disableOptions:
				ControllerBasicSetup.ControllerType === ControllerType.Mx1 ||
				ControllerBasicSetup.ControllerType === ControllerType.Mx2 ||
				ControllerBasicSetup.ControllerType === ControllerType.Mx4 ||
				ControllerBasicSetup.ControllerType === ControllerType.Mx8,
		};

		return retValue;
	}
);

export const selectTagId = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup.TagTimeZoneGenericId,
	(TagTimeZoneGenericId: number) => TagTimeZoneGenericId
);

export const selectAlertId = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup.AlertTimeZoneGenericId,
	(AlertTimeZoneGenericId: number) => AlertTimeZoneGenericId
);

export const selectDelay = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup.SpecialNeedsDelayTimer,
	(SpecialNeedsDelayTimer: number) => SpecialNeedsDelayTimer
);

export const selectMode = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup.SpecialNeedsModeTimer,
	(SpecialNeedsModeTimer: number) => SpecialNeedsModeTimer
);

export const selectDOTL = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup.SpecialNeedsDOTLTimer,
	(SpecialNeedsDOTLTimer: number) => SpecialNeedsDOTLTimer
);

export const selectDisableOptions = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerBasicSetup.ControllerType,
	(ControllerTypeValue: ControllerType) => ControllerTypeValue === ControllerType.M1
);

export const selectSCIBInfo = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerMiscSystemSetup,
	(ControllerMiscSystemSetup: ControllerMiscSystemSetup) => {
		const retValue: SCIBInfoStore = {
			baud: {
				value: ControllerMiscSystemSetup.SCIBBaud,
				options: ControllerMiscSystemSetup.SCIBBaudList,
			},
			parity: {
				value: ControllerMiscSystemSetup.SCIBParity,
				options: ControllerMiscSystemSetup.SCIBParityList,
			},
			dataBits: {
				value: ControllerMiscSystemSetup.SCIBDataBits,
				options: ControllerMiscSystemSetup.SCIBDataBitsList,
			},
			stopBits: {
				value: ControllerMiscSystemSetup.SCIBStopBits,
				options: ControllerMiscSystemSetup.SCIBStopList,
			},
		};

		return retValue;
	}
);

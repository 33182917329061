import { AlarmAcknowledgementActions, AlarmModel, AlarmNote, AlarmNoteForAcknowledge } from '../../model/AlarmModel';
import { AlarmAction } from './actions';
import { AlarmActionType } from './actionsTypes';

export type AlarmState = {
	alarmModel: Partial<AlarmModel>;
	showAlarmNotes: boolean;
	showAlarmInstructions: boolean;
	showAddOperatorNote: boolean;
	alarmNoteForAcknowledge: AlarmNoteForAcknowledge;
	operatorNoteAdded: AlarmNote[];
};

//States' definition
const INIT_STATE: AlarmState = {
	alarmModel: undefined,
	showAlarmInstructions: false,
	showAlarmNotes: false,
	showAddOperatorNote: false,
	alarmNoteForAcknowledge: {
		acknowledgeAction: AlarmAcknowledgementActions.None,
		alarms: [],
		isAlarmNoteForAcknowledge: false,
	},
	operatorNoteAdded: [],
};

//Reducers
const alarmReducer = (state: Readonly<AlarmState> = INIT_STATE, action: AlarmAction): AlarmState => {
	switch (action.type) {
		case AlarmActionType.SET_SHOW_ALARM_INSTRUCTIONS: {
			return {
				...state,
				showAlarmInstructions: action.payload !== undefined,
				alarmModel: action.payload,
			};
		}

		case AlarmActionType.SET_SHOW_ALARM_NOTES: {
			return {
				...state,
				showAlarmNotes: action.payload !== undefined,
				alarmModel: action.payload,
			};
		}

		case AlarmActionType.SET_SHOW_ALARM_ADD_OPERATOR_NOTE: {
			return {
				...state,
				showAddOperatorNote: action.payload,
			};
		}

		case AlarmActionType.SET_ALARM_NOTE_FOR_ACKNOWLEDGE: {
			return {
				...state,
				alarmNoteForAcknowledge: { ...state.alarmNoteForAcknowledge, ...action.payload },
			};
		}

		case AlarmActionType.SET_OPERATOR_NOTES_ADDED: {
			return {
				...state,
				operatorNoteAdded: action.payload,
			};
		}

		default:
			return state;
	}
};

export { alarmReducer };

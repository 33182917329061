import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { getPermissionErrorMessage } from '../../../model/AccountModel';
import { ApplicationSetting } from '../../../model/ConfigurationModel';

interface Props {
	applicationSetting: ApplicationSetting;
	hasPermission: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	castNumber: (value: number) => boolean;
}

const CheckboxApplicationSettingHelper = (props: Props) => {
	const { applicationSetting, hasPermission, disabled, castNumber } = props;
	const handleChecked = (e: CheckboxChangeEvent) => {
		props.onChange(e.target.checked);
	};

	return (
		<span title={getPermissionErrorMessage(hasPermission)}>
			<Checkbox
				id={applicationSetting.Name}
				name={applicationSetting.Name}
				onChange={handleChecked}
				disabled={disabled}
				checked={castNumber(Number(applicationSetting.Value ?? 0))}
				aria-label={applicationSetting.Label}>
				<label htmlFor={applicationSetting.Name}>{applicationSetting.Label}</label>
			</Checkbox>
		</span>
	);
};

export { CheckboxApplicationSettingHelper };

import { AlarmModel } from '../../model/AlarmModel';
import { ConfigurationState } from '../../model/ConfigurationModel';
import { BroadCastSummary, CurrentDeviceControlObj, DeviceObjectType, ModalTimeZones, TimeZoneType } from '../../model/DeviceAdminModel';
import { StatusSummary, VelocityEventModel } from '../../model/EventModel';
import { PhotoCallUp } from '../../model/PhotoCallUpModel';
import { DigiTracTransaction, PerformanceData } from '../../model/StatusModel';
import { StatusViewerEvent } from '../../model/StatusViewerModel';
import { PIVEnrollmentAction } from '../../model/WebSocketModel';
import { WhosInsideActionDetail } from '../../model/WhosInsideModel';
import { CommonAction } from './actions';
import { CommonActionType } from './actionsTypes';

export type CommonState = {
	//region-start socket response
	pivEnrollmentAction: PIVEnrollmentAction;
	performanceStatus: PerformanceData;
	statusSummary: StatusSummary;
	eventList: VelocityEventModel[];
	alarmList: AlarmModel[];
	ackAlarmList: AlarmModel[];
	clearedAlarmList: AlarmModel[];
	broadCastDeviceSummary: BroadCastSummary;
	whosInsideActionDetails: WhosInsideActionDetail[];
	statusViewerEvents: StatusViewerEvent[];
	photoCallCredentials: PhotoCallUp[];
	transactions: DigiTracTransaction[];
	//region-end socket response
	isLoading: boolean;
	currentDevice: CurrentDeviceControlObj;
	currentTimeZoneModal: ModalTimeZones;
	selectedTimeZone: TimeZoneType;
	activeDeviceControlTree: string;
	totalItemsPagination: number;
	itemSearchedPagination: string;
	allItemsPagination: string[];
	configuration: ConfigurationState;
};

//States' definition
// add readonly state for avoid to overwrite default state
const INIT_STATE: CommonState = {
	pivEnrollmentAction: PIVEnrollmentAction.None,
	performanceStatus: undefined,
	statusSummary: {
		SDConnected: false,
		DTConnected: false,
		VergeConnected: false,
		NumAlarms: 0,
		NumAckAlarms: 0,
		ThreatLevel: 0,
		ThreatLevelCaption: '',
		OffNormal: 0,
		LastAlarmDescription: '',
		LastAlarmAddress: '',
		LastAlarmEventId: 0,
		IsUpdated: false,
	},
	eventList: [],
	alarmList: [],
	ackAlarmList: [],
	clearedAlarmList: [],
	broadCastDeviceSummary: { SearchCompleted: false, Devices: [] },
	whosInsideActionDetails: [],
	statusViewerEvents: [],
	photoCallCredentials: [],
	transactions: [],
	isLoading: false,
	selectedTimeZone: TimeZoneType.Standard,
	activeDeviceControlTree: '',
	currentDevice: { Id: 0, DeviceObjectType: DeviceObjectType.CommandSets, IsModalOpen: false },
	currentTimeZoneModal: {
		entity: null,
		timeZoneType: null,
	},
	totalItemsPagination: 0,
	itemSearchedPagination: '',
	allItemsPagination: [],
	configuration: null,
};

//Reducers
const commonReducer = (state: Readonly<CommonState> = INIT_STATE, action: CommonAction): CommonState => {
	switch (action.type) {
		case CommonActionType.CLEAR_SOCKET_RESPONSE:
			return {
				...state,
				pivEnrollmentAction: PIVEnrollmentAction.None,
				performanceStatus: undefined,
				statusSummary: state.statusSummary.IsUpdated
					? {
							...state.statusSummary,
							IsUpdated: false,
					  }
					: { ...state.statusSummary },
				eventList: state.eventList.length ? [] : [...state.eventList],
				alarmList: state.alarmList.length ? [] : [...state.alarmList],
				ackAlarmList: state.ackAlarmList.length ? [] : [...state.ackAlarmList],
				clearedAlarmList: state.clearedAlarmList.length ? [] : [...state.clearedAlarmList],
				broadCastDeviceSummary: {
					...state.broadCastDeviceSummary,
					SearchCompleted: state.broadCastDeviceSummary.SearchCompleted ? false : state.broadCastDeviceSummary.SearchCompleted,
					Devices: state.broadCastDeviceSummary.Devices.length ? [] : [...state.broadCastDeviceSummary.Devices],
				},
				whosInsideActionDetails: state.whosInsideActionDetails.length ? [] : [...state.whosInsideActionDetails],
				statusViewerEvents: state.statusViewerEvents.length ? [] : [...state.statusViewerEvents],
				photoCallCredentials: state.photoCallCredentials.length ? [] : [...state.photoCallCredentials],
				transactions: state.transactions.length ? [] : [...state.transactions],
			};

		case CommonActionType.SET_SOCKET_PIV_ENROLLMENT_RESPONSE:
			return {
				...state,
				pivEnrollmentAction: action.payload,
			};

		case CommonActionType.SET_SOCKET_PERFORMANCE_STATUS_RESPONSE:
			return {
				...state,
				performanceStatus: action.payload,
			};

		case CommonActionType.SET_SOCKET_STATUS_SUMMARY_RESPONSE:
			return {
				...state,
				statusSummary: { ...action.payload, IsUpdated: true },
			};

		case CommonActionType.SET_SOCKET_EVENT_LIST_RESPONSE:
			return {
				...state,
				eventList: action.payload,
			};

		case CommonActionType.SET_SOCKET_ALARM_LIST_RESPONSE:
			return {
				...state,
				alarmList: action.payload,
			};

		case CommonActionType.SET_SOCKET_ACK_ALARM_LIST_RESPONSE:
			return {
				...state,
				ackAlarmList: action.payload,
			};

		case CommonActionType.SET_SOCKET_CLEAR_ALARM_LIST_RESPONSE:
			return {
				...state,
				clearedAlarmList: action.payload,
			};

		case CommonActionType.SET_SOCKET_WHOS_INSIDE_ACTION_DETAILS_RESPONSE:
			return {
				...state,
				whosInsideActionDetails: action.payload,
			};

		case CommonActionType.SET_SOCKET_WHOS_INSIDE_ACTION_DETAILS_RESPONSE:
			return {
				...state,
				whosInsideActionDetails: action.payload,
			};

		case CommonActionType.SET_SOCKET_STATUS_VIEWER_EVENTS_RESPONSE:
			return {
				...state,
				statusViewerEvents: action.payload,
			};

		case CommonActionType.SET_SOCKET_PHOTO_CALLUP_CREDENTIALS_RESPONSE:
			return {
				...state,
				photoCallCredentials: action.payload,
			};

		case CommonActionType.SET_SOCKET_TRANSACTIONS_RESPONSE:
			return {
				...state,
				transactions: action.payload,
			};

		case CommonActionType.SET_SOCKET_BROADCAST_SUMMARY_RESPONSE:
			return {
				...state,
				broadCastDeviceSummary: action.payload,
			};

		case CommonActionType.SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};

		case CommonActionType.SET_CURRENT_DEVICE:
			return {
				...state,
				currentDevice: action.payload,
			};

		case CommonActionType.SET_CURRENT_TIME_ZONE:
			return {
				...state,
				currentTimeZoneModal: { ...action.payload },
			};
		case CommonActionType.SET_SELECTED_TIME_ZONE:
			return {
				...state,
				selectedTimeZone: action.payload,
			};
		case CommonActionType.SET_ACTIVE_TREE_SELECTED_KEY:
			return {
				...state,
				activeDeviceControlTree: action.payload,
			};

		case CommonActionType.SET_TOTAL_ITEMS_PAGINATION: {
			return {
				...state,
				totalItemsPagination: action.payload,
			};
		}

		case CommonActionType.SET_ITEM_SEARCHED_PAGINATION: {
			return {
				...state,
				itemSearchedPagination: action.payload,
			};
		}

		case CommonActionType.SET_ALL_ITEMS_PAGINATION: {
			return {
				...state,
				allItemsPagination: action.payload,
			};
		}

		case CommonActionType.SET_CONFIGURATION: {
			return {
				...state,
				configuration: action.payload,
			};
		}

		default:
			return state;
	}
};

export { commonReducer };

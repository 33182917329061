import { AlarmModel, AlarmNote, AlarmNoteForAcknowledge } from '../../model/AlarmModel';
import { AlarmActionType } from './actionsTypes';

type SetShowAlarmInstructions = {
	type: AlarmActionType.SET_SHOW_ALARM_INSTRUCTIONS;
	payload: Partial<AlarmModel>;
};

type SetShowAlarmNotes = {
	type: AlarmActionType.SET_SHOW_ALARM_NOTES;
	payload: Partial<AlarmModel>;
};

type SetShowAlarmAddOperatorNote = {
	type: AlarmActionType.SET_SHOW_ALARM_ADD_OPERATOR_NOTE;
	payload: boolean;
};

type SetAlarmNoteForAcknowledge = {
	type: AlarmActionType.SET_ALARM_NOTE_FOR_ACKNOWLEDGE;
	payload: AlarmNoteForAcknowledge;
};

type SetOperatorNotesAdded = {
	type: AlarmActionType.SET_OPERATOR_NOTES_ADDED;
	payload: AlarmNote[];
};

//Union Action Types
export type AlarmAction = SetShowAlarmInstructions | SetShowAlarmNotes | SetShowAlarmAddOperatorNote | SetAlarmNoteForAcknowledge | SetOperatorNotesAdded;

//Action Creators

//Export Action
export const setShowAlarmInstructions = (payload: Partial<AlarmModel>): SetShowAlarmInstructions => {
	return {
		type: AlarmActionType.SET_SHOW_ALARM_INSTRUCTIONS,
		payload,
	};
};

export const setShowAlarmNotes = (payload: Partial<AlarmModel>): SetShowAlarmNotes => {
	return {
		type: AlarmActionType.SET_SHOW_ALARM_NOTES,
		payload,
	};
};

export const setShowAlarmAddOperatorNote = (payload: boolean): SetShowAlarmAddOperatorNote => {
	return {
		type: AlarmActionType.SET_SHOW_ALARM_ADD_OPERATOR_NOTE,
		payload,
	};
};

export const setAlarmNoteForAcknowledge = (payload: AlarmNoteForAcknowledge): SetAlarmNoteForAcknowledge => {
	return {
		type: AlarmActionType.SET_ALARM_NOTE_FOR_ACKNOWLEDGE,
		payload,
	};
};

export const setOperatorNotesAdded = (payload: AlarmNote[]): SetOperatorNotesAdded => {
	return {
		type: AlarmActionType.SET_OPERATOR_NOTES_ADDED,
		payload,
	};
};

import { InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../Helper';
import { CommunicationsInfoStore } from '../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { setControllerBasicSetupAction, setControllerReportingSetupAction } from '../../../../../../store/deviceControl/controller/actions';
import { ControllerBasic, ControllerReporting } from '../../../../../../store/deviceControl/controller/actionTypes';
import { selectCommunicationsInfo } from '../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../common/CardSection/CardSection';
import styles from './communications.module.scss';
import { RandomizeValues, RandomValues } from './RandomizeValues/RandomizeValues';

const Communications: React.FC = () => {
	const communicationsInfo: CommunicationsInfoStore = useStoreSelector<CommunicationsInfoStore>(selectCommunicationsInfo);
	const dispatch = useStoreDispatch();

	const handleOnChangeRandom = (value: RandomValues) => {
		const actionToDispatch = setControllerBasicSetupAction({
			[value.key]: value.value,
		});
		dispatch(actionToDispatch);
	};

	const createRandomInputsPass: RandomValues[] = [
		{
			key: 'Newhstpswd1',
			value: communicationsInfo.networkPasswords.pass1,
		},
		{
			key: 'Newhstpswd2',
			value: communicationsInfo.networkPasswords.pass2,
		},
		{
			key: 'Newhstpswd3',
			value: communicationsInfo.networkPasswords.pass3,
		},
		{
			key: 'Newhstpswd4',
			value: communicationsInfo.networkPasswords.pass4,
		},
	];

	const createRandomInputsEncrypt: RandomValues[] = [
		{
			key: 'Newdefheskey1',
			value: communicationsInfo.encryption.encrypt1,
		},
		{
			key: 'Newdefheskey2',
			value: communicationsInfo.encryption.encrypt2,
		},
		{
			key: 'Newdefheskey3',
			value: communicationsInfo.encryption.encrypt3,
		},
		{
			key: 'Newdefheskey4',
			value: communicationsInfo.encryption.encrypt4,
		},
	];

	const randomValue = () => Math.floor(Math.random() * 65535);

	const handleRandomizePassValues = (isDefault: boolean) => {
		handleChangeControllerBasicSetup({
			Newhstpswd1: isDefault ? 123 : randomValue(),
			Newhstpswd2: isDefault ? 123 : randomValue(),
			Newhstpswd3: isDefault ? 123 : randomValue(),
			Newhstpswd4: isDefault ? 123 : randomValue(),
		});
	};

	const handleRandomizeEncryptValues = (isDefault: boolean) => {
		handleChangeControllerBasicSetup({
			Newdefheskey1: isDefault ? 123 : randomValue(),
			Newdefheskey2: isDefault ? 123 : randomValue(),
			Newdefheskey3: isDefault ? 123 : randomValue(),
			Newdefheskey4: isDefault ? 123 : randomValue(),
		});
	};

	const handleChangeControllerBasicSetup = (value: ControllerBasic) => {
		dispatch(
			setControllerBasicSetupAction({
				...value,
			})
		);
	};

	const handleChangeControllerReportingSetup = (value: ControllerReporting) => {
		const snetTimeout: number = value.CommunicationsSNETTimeout;
		if (!snetTimeout || snetTimeout === undefined || snetTimeout < 10) {
			value.CommunicationsSNETTimeout = 10;
		}

		dispatch(
			setControllerReportingSetupAction({
				...value,
			})
		);
	};

	const controllerCommunicationsSNETTimeoutInputId = 'controllerCommunicationsSNETTimeoutInput';

	return (
		<CardSection width={715} height={315}>
			<div className={styles.container}>
				<div>
					<label htmlFor={controllerCommunicationsSNETTimeoutInputId}>{_('SNETTimeout')}</label>
					<InputNumber
						id={controllerCommunicationsSNETTimeoutInputId}
						min={10}
						max={255}
						maxLength={3}
						value={communicationsInfo.timeOut}
						onChange={value => handleChangeControllerReportingSetup({ CommunicationsSNETTimeout: Number(value) })}
						formatter={noneDecimalFormatter}
					/>
					<label htmlFor={controllerCommunicationsSNETTimeoutInputId}>({`10-255 ${_('Seconds')}`})</label>
				</div>
				<div>
					<label>{_('NetworkPassword')}</label>
					<RandomizeValues
						onPressDefaultValues={() => handleRandomizePassValues(true)}
						onPressRandomizeValues={() => handleRandomizePassValues(false)}
						onChange={handleOnChangeRandom}
						values={createRandomInputsPass}
						sectionId='Network'
					/>
				</div>
				<div>
					<label>{_('DefaultEncryptionKey')}</label>
					<RandomizeValues
						onPressDefaultValues={() => handleRandomizeEncryptValues(true)}
						onPressRandomizeValues={() => handleRandomizeEncryptValues(false)}
						onChange={handleOnChangeRandom}
						values={createRandomInputsEncrypt}
						sectionId='Encryption'
					/>
				</div>
			</div>
		</CardSection>
	);
};

export { Communications };

import { ConfigurationState, ProfileSetting } from '../../model/ConfigurationModel';
import { GeneralSettingsAction } from './actions';
import { ConfigurationActionTypes } from './actionTypes';

//States' definition
// add readonly state for avoid to overwrite default state
const INIT_STATE: ConfigurationState | null = null;
const convertBooleanToNumberString = (value: boolean, key: string) => {
	switch (key) {
		case 'SoundRepeat':
		case 'ShowAlarmBanner':
			return value === true ? '1' : '0';
		default:
			return value === true ? '-1' : '0';
	}
};
const convertBooleanString = (value: boolean) => {
	return value ? 'True' : 'False';
};

//Reducers
const configurationReducer = (state: Readonly<ConfigurationState> = INIT_STATE, action: GeneralSettingsAction): ConfigurationState => {
	switch (action.type) {
		case ConfigurationActionTypes.SET_DEFAULT_DATA:
			return {
				...state,
				...action.payload,
			};

		case ConfigurationActionTypes.SET_CULTURE_FORMAT:
			return {
				...state,
				CultureDateFormat: action.payload,
			};

		case ConfigurationActionTypes.SET_VIEW_SETTING_VALUE: {
			const cloneState = state.ViewSettings;
			const newState = cloneState.map(x =>
				x.Name === action.payload.key
					? {
							...x,
							Value: action.payload.value,
					  }
					: x
			);
			return {
				...state,
				ViewSettings: [...newState],
			};
		}

		case ConfigurationActionTypes.SET_SETTING_VALUE: {
			const { key, value, type } = action.payload;
			return type
				? {
						...state,
						[type]: {
							...state[type],
							[key]: value,
						},
				  }
				: {
						...state,
						[key]: value,
				  };
		}

		case ConfigurationActionTypes.SET_CHECKBOX_SETTING_VALUE: {
			const cloneState = state.ViewSettings;
			const payload = action.payload;
			const changeCheckBox = cloneState.map<ProfileSetting>(x =>
				payload.key === x.Name
					? {
							...x,
							SelectedValue: payload.value,
							Value: payload.type === 'Alarm' ? convertBooleanToNumberString(payload.value, payload.key) : convertBooleanString(payload.value),
					  }
					: x
			);
			return {
				...state,
				ViewSettings: [...changeCheckBox],
			};
		}
		case ConfigurationActionTypes.SET_SETTINGS_HAVE_CHANGED: {
			return {
				...state,
				HaveSettingsChanged: action.payload,
			};
		}

		default:
			return state;
	}
};

export { configurationReducer };

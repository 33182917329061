import { Table } from 'antd';
import React from 'react';
import { buildColumn, ScrollType } from '../../../../../Helper';
import { Tuple } from '../../../../../model/CommonModel';
import styles from './holidayAlert.module.scss';

type Props = {
	data: Tuple[];
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 272 };

const HolidayAlert: React.FC<Props> = ({ data }) => {
	const columns: any[] = [
		{
			...buildColumn(_('Date'), 'Item2', '136px', 'start'),
		},
		{
			...buildColumn(_('HolidayName'), 'Item1', '100px', 'start'),
		},
	];
	const dataSource = data.map((x, index) => ({ ...x, Item2: FormatDate(x.Item2, false, null, true, false), key: index }));

	return (
		<div className={styles.container}>
			<label>{_('HolidaysCanNotBeDeletedError')}</label>
			<Table scroll={scroll} size='small' columns={columns} dataSource={dataSource} className={styles.statusWidgetTable} pagination={false} />
		</div>
	);
};

export { HolidayAlert };

import React from 'react';
import { useStoreSelector } from '../../../../../../store';
import { selectHideLocalKeypad } from '../../../../../../store/deviceControl/controller/selectors';
import { AlarmRelayTimers } from './AlarmRelayTimers/AlarmRelayTimers';
import { CodeTamperAlarm } from './CodeTamperAlarm/CodeTamperAlarm';
import { Options } from './Options/Options';
import { OptionsForLocalKeypad } from './OptionsForLocalKeypad/OptionsForLocalKeypad';
import { Scib } from './Scib/Scib';
import styles from './setup.module.scss';
import { SpecialNeedsTimeExtensions } from './SpecialNeedsTimeExtensions/SpecialNeedsTimeExtensions';
import { Tag } from './Tag/Tag';

const Setup: React.FC = () => {
	const hideLocalKeypad: boolean = useStoreSelector<boolean>(selectHideLocalKeypad);

	return (
		<div className={styles.setup}>
			<div className={styles.setupLeft}>
				<div>
					<div>
						<CodeTamperAlarm />
					</div>
					<div>
						<SpecialNeedsTimeExtensions />
					</div>
				</div>
				{!hideLocalKeypad && (
					<div>
						<OptionsForLocalKeypad />
					</div>
				)}

				<div>
					<Scib />
				</div>
			</div>
			<div className={styles.setupRight}>
				<div>
					<div className={styles.tags}>
						<Tag />
					</div>
					<div>
						<AlarmRelayTimers />
					</div>
				</div>
				<div>
					<Options />
				</div>
			</div>
		</div>
	);
};

export { Setup };

import type { MenuProps } from 'antd';
import { Dropdown, Form, Input, Menu } from 'antd';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import { removeCommasAndPercentSign } from '../../../Helper';
import { BaseColumns, SelectOptions } from '../../../model/CommonModel';
import styles from './editableCell.module.scss';

const { Item, SubMenu } = Menu;

export type EditableCellProps<T> = {
	editing: boolean;
	isTableInEditMode: boolean;
	dataIndex: string;
	title: string;
	inputType: 'number' | 'text';
	record: T;
	index: number;
	children?: React.ReactNode;
	customComponent?: React.ReactNode;
	maxLength?: number;
	inputStyles?: CSSProperties;
	options?: SelectOptions<T>[];
	onClickOption?: (value: T) => void;
	onChangeSelection?: (value: T) => void;
} & React.HTMLAttributes<HTMLElement>;

//Avoid creating object style inline, since increases reconciliations
const style: React.CSSProperties = { margin: 0 };
const handleOnChangeName = (e: React.ChangeEvent<HTMLFormElement>) => removeCommasAndPercentSign(e.target.value);

const EditableCell = <T extends BaseColumns | {}>({
	maxLength,
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	customComponent,
	options,
	isTableInEditMode,
	inputStyles,
	onClickOption,
	onChangeSelection,
	...restProps
}: EditableCellProps<T>) => {
	let content: JSX.Element;

	const createMenuItems = (options: SelectOptions<T>[]): MenuProps['items'] => {
		const menuItems: JSX.Element[] = [];

		options.forEach(({ label, disabled, title, icon, id, separator, value, nestedOptions }, index) => {
			const key = `${label}-${index}`;
			if (!nestedOptions) {
				menuItems.push(
					<Item
						className={cx({ [styles.separator]: separator, [styles.disabled]: disabled })}
						disabled={disabled}
						icon={icon}
						id={id}
						key={key}
						onClick={e => {
							e.domEvent.stopPropagation();
							onClickOption?.(value);
						}}
						title={title}>
						<span
							className={styles.internalSpan}
							onClick={e => {
								if (disabled) e.stopPropagation();
							}}>
							{label}
						</span>
					</Item>
				);
			} else {
				menuItems.push(
					<SubMenu className={cx({ [styles.separator]: separator })} key={key} title={label}>
						{createMenuItems(nestedOptions)}
					</SubMenu>
				);
			}
		});

		return menuItems;
	};

	if (editing) {
		content = (
			<td {...restProps}>
				<div className={styles.input}>
					{customComponent ?? (
						<Form.Item id={'nameInput'} name={dataIndex} style={style} getValueFromEvent={handleOnChangeName}>
							<Input maxLength={maxLength} id='editableInput' style={inputStyles} />
						</Form.Item>
					)}
				</div>
			</td>
		);
	} else if (!isTableInEditMode && options) {
		const menu = <Menu getPopupContainer={(element: HTMLElement) => element.closest('.ant-table-body')}>{createMenuItems(options)}</Menu>;

		content = (
			<Dropdown
				overlay={menu}
				placement='bottomRight'
				trigger={['contextMenu']}
				getPopupContainer={(element: HTMLElement) => element.closest('.ant-table-body')}
				onOpenChange={visible => {
					if (visible) {
						onChangeSelection?.(record);
					}
				}}
				overlayStyle={{ width: 'max-content' }}>
				<td {...restProps}>{children}</td>
			</Dropdown>
		);
	} else {
		content = <td {...restProps}>{children}</td>;
	}

	return content;
};

export { EditableCell };

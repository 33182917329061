import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { PortStoreContext, setIntervalTimeout, setPortChangedAction } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const IntervalTimeout: React.FC<Props> = () => {
	const {
		portState: { intervalTimeout, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleChange = (value: string) => {
		if (!(Number(value) >= 0)) {
			return;
		}
		dispatcher(setIntervalTimeout(Number(value)));

		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const portAdvancedSettingsIntervalTimeoutInputId = 'portAdvancedSettingsIntervalTimeoutInput';

	return (
		<div className={styles.labelContainer}>
			<label id='portAdvancedSettingsIntervalTimeoutLabel' htmlFor={portAdvancedSettingsIntervalTimeoutInputId}>
				{_('IntervalTimeout')}
			</label>
			<InputNumber
				id={portAdvancedSettingsIntervalTimeoutInputId}
				key='intervalTimeout'
				className={styles.inputNumber}
				value={intervalTimeout}
				min={0}
				max={99}
				step={1}
				maxLength={2}
				onChange={handleChange}
				formatter={noneDecimalFormatter}
			/>
		</div>
	);
};

export { IntervalTimeout };

import { Button, Checkbox, Input, Space } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../api';
import { Modal as CommonModal, InputTimePicker } from '../../../../../../components/common';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../../../model/AccountModel';
import { PaginationSetting, SorterConfig } from '../../../../../../model/CommonModel';
import { StandardTimeZone, TimeZoneType } from '../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../store';
import { setCurrentModalTimeZone } from '../../../../../../store/common/actions';
import { selectItemSearchedPagination, selectStandardTimeZone } from '../../../../../../store/common/selectors';
import { setStandardTimeZonesBy } from '../../../../../../store/deviceControl/actions';
import { selectVelocityConfigurationFilterMode } from '../../../../../../store/deviceControl/selectors';
import { NotificationStatus } from '../../../../../common/NotificationStatus/NotificationStatus';
import styles from './standard.module.scss';

type Props = {
	onEntityAction?: (genericTimeZoneId: number) => void;
	isPaginationItemSelected: boolean;
	currentPage: number;
	currentPageSize: number;
	sorterConfig: SorterConfig;
	setShouldResetSearchColumn: () => void;
	setRedirectPage: (value: boolean) => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Standard_Time_Zone);

const Standard: React.FC<Props> = ({
	onEntityAction,
	isPaginationItemSelected,
	currentPage,
	currentPageSize,
	sorterConfig,
	setShouldResetSearchColumn,
	setRedirectPage,
}) => {
	const dispatch = useStoreDispatch();
	const [standardTimeZone, setStandardTimeZone] = useState<StandardTimeZone>(null);
	const [timeError, setTimeError] = useState(false);
	const [nameError, setNameError] = useState('');
	const [emptyNameError, setEmptyNameError] = useState('');

	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');

	const isFilterMode: boolean = useStoreSelector<boolean>(selectVelocityConfigurationFilterMode);
	const itemSearchedPagination: string = useStoreSelector(selectItemSearchedPagination);

	const gridRowHeader = [
		{ display: 'M', key: 'Monday', id: 'standardTimeZoneMondayCheckbox' },
		{ display: 'T', key: 'Tuesday', id: 'standardTimeZoneTuesdayCheckbox' },
		{ display: 'W', key: 'Wednesday', id: 'standardTimeZoneWednesdayCheckbox' },
		{ display: 'T', key: 'Thursday', id: 'standardTimeZoneThursdayCheckbox' },
		{ display: 'F', key: 'Friday', id: 'standardTimeZoneFridayCheckbox' },
		{ display: 'S', key: 'Saturday', id: 'standardTimeZoneSaturdayCheckbox' },
		{ display: 'S', key: 'Sunday', id: 'standardTimeZoneSundayCheckbox' },
		{ display: 'H', key: 'Holidays', id: 'standardTimeZoneHolidaysCheckbox' },
		{ display: '1', key: 'HolidayTable1', id: 'standardTimeZoneHolidaysTable1Checkbox' },
		{ display: '2', key: 'HolidayTable2', id: 'standardTimeZoneHolidaysTable2Checkbox' },
		{ display: '3', key: 'HolidayTable3', id: 'standardTimeZoneHolidaysTable3Checkbox' },
		{ display: '4', key: 'HolidayTable4', id: 'standardTimeZoneHolidaysTable4Checkbox' },
	];

	const handleOnCLose = () => {
		deviceAdminApi.removeTimeZoneLocks(TimeZoneType.Standard);
		dispatch(setCurrentModalTimeZone({ timeZoneType: null, entity: null }));
	};

	const editedEntity: StandardTimeZone = useStoreSelector(selectStandardTimeZone);

	const shouldResetSearchColumn = editedEntity => {
		if (isPaginationItemSelected && editedEntity && isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const redirectPage = editedEntity => {
		if (editedEntity) {
			setRedirectPage(true);
		}
	};

	const handleAction = () => {
		if (!standardTimeZone.Name || standardTimeZone.Name.trim() === '') {
			setNameError(emptyNameError);
			return;
		}

		standardTimeZone.Name = standardTimeZone.Name.trim();
		const action = editedEntity === null ? deviceAdminApi.addStandardTimeZone(standardTimeZone) : deviceAdminApi.editStandardTimeZone(standardTimeZone);
		action.then(res =>
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {
					dispatch(
						setStandardTimeZonesBy({
							PageNumber: currentPage,
							PageSize: currentPageSize,
							SortDirection: sorterConfig.direction,
							SearchedValue: itemSearchedPagination,
						} as PaginationSetting)
					);
					onEntityAction(res.ResponseObjectId);
					shouldResetSearchColumn(editedEntity);
					redirectPage(editedEntity);
					handleOnCLose();
				},
				onFailedValidation: () => {
					setNameError(res.ErrorMessage);
				},
			})
		);
	};

	const handleCheckBox = (isChecked: boolean, day: string) => {
		setStandardTimeZone({
			...standardTimeZone,
			[day]: isChecked,
		});
	};

	const handleChangeTimePickerStart = (value: string) => {
		const defaultDay = moment().format('YYYY-MM-DD');
		const dateString = moment(`${defaultDay} ${value}`).toString();

		if (isEndGreatThanStart(dateString, endTime)) {
			setStandardTimeZone({
				...standardTimeZone,
				StartTime: value,
				EndTime: value,
			});
			setEndTime(dateString);
			setStartTime(dateString);
		} else {
			setStandardTimeZone({
				...standardTimeZone,
				StartTime: value,
			});
			setStartTime(dateString);
		}
	};

	const handleChangeTimePickerEnd = (value: string) => {
		const defaultDay = moment().format('YYYY-MM-DD');
		const dateString = moment(`${defaultDay} ${value}`).toString();
		setStandardTimeZone({
			...standardTimeZone,
			EndTime: value,
		});
		setEndTime(dateString);
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		setStandardTimeZone({
			...standardTimeZone,
			Name: value,
		});
		setNameError('');
	};

	useEffect(() => {
		if (endTime && startTime) {
			setTimeError(isEndGreatThanStart(startTime, endTime));
		}
	}, [endTime, startTime]);

	const isEndGreatThanStart = (startTime: string, endTime: string): boolean => {
		const start = new Date(startTime);
		const end = new Date(endTime);
		if (end < start) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		const defaultState: StandardTimeZone =
			editedEntity !== null
				? editedEntity
				: {
						EndTime: '00:00',
						StartTime: '00:00',
						Id: 0,
						Monday: false,
						Tuesday: false,
						Wednesday: false,
						Thursday: false,
						Friday: false,
						Saturday: false,
						Sunday: false,
						HolidayTable1: false,
						HolidayTable2: false,
						HolidayTable3: false,
						HolidayTable4: false,
						Holidays: false,
						Name: '',
						InUse: false,
				  };
		setStandardTimeZone(defaultState);

		const defaultDay = moment().format('YYYY-MM-DD');
		setStartTime(moment(`${defaultDay} ${defaultState.StartTime}`).toString());
		setEndTime(moment(`${defaultDay} ${defaultState.EndTime}`).toString());

		deviceAdminApi.getErrorMessages(SecuredComponents.Standard_Time_Zone).then(res => setEmptyNameError(res.EmptyName));
		window.addEventListener('beforeunload', handleOnCLose);

		return () => {
			window.removeEventListener('beforeunload', handleOnCLose);
		};
	}, []);

	const permissionsEnabled = editedEntity === null ? componentPermission.canAdd : componentPermission.canUpdate;
	const disabled = timeError || !permissionsEnabled;
	const standardTimeZoneNameInputId = 'standardTimeZoneNameInput';
	const standardTimeZoneStartTimeInputId = 'standardTimeZoneStartTimeInput';
	const standardTimeZoneInfoRowId = 'standardTimeZoneInfoRow';
	const standardTimeZoneEndTimeInputId = 'standardTimeZoneEndTimeInput';

	return (
		<CommonModal
			footer={[
				<Button
					id='standardTimeZoneSaveButton'
					key='save'
					title={getPermissionErrorMessage(permissionsEnabled)}
					type='primary'
					disabled={disabled}
					onClick={() => handleAction()}>
					{_('SaveChanges')}
				</Button>,
				<Button id='standardTimeZoneCancelButton' key='cancel' onClick={() => handleOnCLose()}>
					{_('Cancel')}
				</Button>,
			]}
			onClickOk={() => null}
			onCancel={() => handleOnCLose()}
			customZoomClass={styles.withModalZoom}
			title={_(`${editedEntity === null ? 'Add' : 'Edit'}StandardTimeZone`)}
			visible={true}>
			{standardTimeZone && (
				<div id='standardTimeZoneModalContainer' className={styles.container}>
					<div>
						<label htmlFor={standardTimeZoneNameInputId}>{_('Name')}</label>
						<Input
							id={standardTimeZoneNameInputId}
							value={standardTimeZone.Name}
							maxLength={50}
							onChange={handleChangeName}
							className={cx({ [styles.error]: nameError })}
						/>
						{nameError && (
							<label id='standardTimeZoneErrorMessage' htmlFor={standardTimeZoneNameInputId} className={styles.errorMessage}>
								{nameError}
							</label>
						)}
					</div>
					<div>
						<Space direction='horizontal'>
							<label htmlFor={standardTimeZoneStartTimeInputId}>{_('StartTimeTimeZones')}</label>
							<InputTimePicker
								id={standardTimeZoneStartTimeInputId}
								value={standardTimeZone.StartTime}
								onChange={handleChangeTimePickerStart}></InputTimePicker>
							<label htmlFor={standardTimeZoneEndTimeInputId}>{_('EndTimeTimeZones')}</label>
							<InputTimePicker
								id={standardTimeZoneEndTimeInputId}
								value={standardTimeZone.EndTime}
								onChange={handleChangeTimePickerEnd}></InputTimePicker>
						</Space>
						{timeError && (
							<label id='standardTimeZoneErrorMessage' className={styles.errorMessage}>
								{_('InvalidEndTime')}
							</label>
						)}
					</div>
					<div id={standardTimeZoneInfoRowId} className={styles.week}>
						{gridRowHeader.map((x, index) => (
							<div key={index}>
								<span>{x.display}</span>
								<Checkbox
									id={x.id}
									onChange={e => handleCheckBox(e.target.checked, x.key)}
									defaultChecked={standardTimeZone[x.key]}
									aria-label={`${_('Holiday')}${index}`}></Checkbox>
							</div>
						))}
					</div>
				</div>
			)}
		</CommonModal>
	);
};

export { Standard };

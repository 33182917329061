import { notification } from 'antd';
import {
	AuthenticatorAssertionRawResponse,
	AuthenticatorAttestationRawResponse,
	CredentialCreateOptions,
	LoginResponseStatusCode,
	PublicKeyCredential,
} from '../../model/AccountModel';
import { Logger } from '../../model/LoggingModel';

export const isStatusAnError = (currentState: LoginResponseStatusCode): boolean => {
	let hasError = true;

	switch (currentState) {
		case LoginResponseStatusCode.None:
		case LoginResponseStatusCode.Success:
		case LoginResponseStatusCode.SecurityKeyRequired:
		case LoginResponseStatusCode.AddSecurityKey:
		case LoginResponseStatusCode.LogoutSuccess:
			hasError = false;
			break;
	}

	return hasError;
};

export const buildAuthenticatorAssertionResponse = (assertedCredential): AuthenticatorAssertionRawResponse => {
	const {
		rawId,
		id,
		response: { authenticatorData, clientDataJSON, signature },
	} = assertedCredential;

	// Move data into Arrays in case it is super long
	const authDataArray: Uint8Array = new Uint8Array(authenticatorData);
	const clientDataJSONArray: Uint8Array = new Uint8Array(clientDataJSON);
	const rawIdArray: Uint8Array = new Uint8Array(rawId);
	const signatureArray: Uint8Array = new Uint8Array(signature);

	const data: AuthenticatorAssertionRawResponse = {
		id: convertToBase64String(id),
		rawId: convertToBase64String(rawIdArray),
		type: 0,
		extensions: assertedCredential.getClientExtensionResults(),
		response: {
			userHandle: null,
			authenticatorData: convertToBase64String(authDataArray),
			clientDataJSON: convertToBase64String(clientDataJSONArray),
			signature: convertToBase64String(signatureArray),
		},
	};

	return data;
};

export const handleNavigatorError = err => {
	let errorMessage = err.message;
	if (err.name === 'NotAllowedError') {
		errorMessage = _('KeyNotAllowedError');
		Logger.writeWarnLog(`${err.name}: ${err.message}`);
	} else {
		Logger.writeErrorLog(`${err.name}: ${err.message}`);
	}

	notification.error({
		message: errorMessage,
	});
};

export const buildAuthenticatorAttestationResponse = (attestationPublicKey: PublicKeyCredential): AuthenticatorAttestationRawResponse => {
	const {
		rawId,
		id,
		response: { attestationObject, clientDataJSON },
	} = attestationPublicKey;

	// Move data into Arrays in case it is super long
	const attestationObjectArray: Uint8Array = new Uint8Array(attestationObject);
	const clientDataJSONArray: Uint8Array = new Uint8Array(clientDataJSON);
	const rawIdArray: Uint8Array = new Uint8Array(rawId);

	const data: AuthenticatorAttestationRawResponse = {
		id: convertToBase64String(id),
		rawId: convertToBase64String(rawIdArray),
		type: 0,
		extensions: attestationPublicKey.getClientExtensionResults(),
		response: {
			attestationObject: convertToBase64String(attestationObjectArray),
			clientDataJSON: convertToBase64String(clientDataJSONArray),
		},
	};

	return data;
};

export const getConvertedSecurityOptions = (securityOptions: CredentialCreateOptions) => {
	let newSecurityKeyOptions: CredentialCreateOptions = { ...securityOptions };

	newSecurityKeyOptions.challenge = convertToArrayBuffer(newSecurityKeyOptions.challenge);
	newSecurityKeyOptions.user.id = convertToArrayBuffer(newSecurityKeyOptions.user.id);
	newSecurityKeyOptions.excludeCredentials = newSecurityKeyOptions.excludeCredentials.map(x => ({
		...x,
		id: convertToArrayBuffer(x.id),
		transports: ['usb'],
	}));

	if (newSecurityKeyOptions.authenticatorSelection.authenticatorAttachment === null) {
		newSecurityKeyOptions.authenticatorSelection.authenticatorAttachment = undefined;
	}

	return newSecurityKeyOptions;
};

export const convertToBase64String = input => {
	// Array or ArrayBuffer to Uint8Array
	if (Array.isArray(input)) {
		input = Uint8Array.from(input);
	}

	if (input instanceof ArrayBuffer) {
		input = new Uint8Array(input);
	}

	// Uint8Array to base64
	if (input instanceof Uint8Array) {
		let str = '';
		const len = input.byteLength;

		for (let i = 0; i < len; i++) {
			str += String.fromCharCode(input[i]);
		}
		input = window.btoa(str);
	}

	// error if none of the above worked
	if (typeof input !== 'string') {
		throw new Error('could not coerce to string');
	}

	input = input.replace(/\-/g, '+').replace(/\_/g, '/');

	//add spacing for appropriated length
	const mod4 = input.length % 4;
	if (mod4 > 0) {
		input = `${input}${'='.repeat(4 - mod4)}`;
	}

	return input;
};

export const convertToArrayBuffer = input => {
	if (typeof input === 'string') {
		// base64url to base64
		input = input.replace(/-/g, '+').replace(/_/g, '/');

		// base64 to Uint8Array
		const str = window.atob(input);
		let bytes = new Uint8Array(str.length);
		for (var i = 0; i < str.length; i++) {
			bytes[i] = str.charCodeAt(i);
		}
		input = bytes;
	}

	// Array to Uint8Array
	if (Array.isArray(input)) {
		input = new Uint8Array(input);
	}

	// Uint8Array to ArrayBuffer
	if (input instanceof Uint8Array) {
		input = input.buffer;
	}

	// error if none of the above worked
	if (!(input instanceof ArrayBuffer)) {
		throw new TypeError(`could not coerce '${input}' to ArrayBuffer`);
	}

	return input;
};

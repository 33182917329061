import React, { useContext } from 'react';
import {} from '../../../../../../../Helper';
import { PortNetWorkType } from '../../../../../../../model/DeviceAdminModel';
import { IPv4AddressInput } from '../../../../../../common/IPv4AddressInput/IPv4AddressInput';
import { IPv6AddressInput } from '../../../../../../common/IPv6AddressInput/IPv6AddressInput';
import { PortStoreContext, setIPAddress, setIPv6Address, setPortChangedAction } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {
	ipAddressValidation: boolean;
	onResetIPAddressValidation: () => void;
	errorMessage: string;
	onResetErrorMessage: () => void;
};

const IPAddress: React.FC<Props> = ({ ipAddressValidation, onResetIPAddressValidation, errorMessage, onResetErrorMessage }) => {
	const {
		portState: { ipAddress, portNetworkType, ipv6Address, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const resetErrorMessages = () => {
		onResetIPAddressValidation();
		onResetErrorMessage();
	};

	const handleIPv6Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		checkIfPortChanged();
		resetErrorMessages();
		dispatcher(setIPv6Address(value));
	};

	const handleIPv4Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		checkIfPortChanged();
		resetErrorMessages();
		dispatcher(setIPAddress(value));
	};

	const portGeneralIPv6AddressInputId = 'portGeneralIPv6AddressInput';
	const portGeneralIPv4AddressInputId = 'portGeneralIPv4AddressInput';

	return (
		<>
			<div className={styles.inputContainer}>
				<label
					id='portGeneralIPAddressLabel'
					htmlFor={portNetworkType === PortNetWorkType.IPv6 ? portGeneralIPv6AddressInputId : portGeneralIPv4AddressInputId}>
					{_('IPAddress')}
				</label>
				{portNetworkType === PortNetWorkType.IPv6 ? (
					<IPv6AddressInput
						id={portGeneralIPv6AddressInputId}
						key='ipv6Address'
						addressValidation={ipAddressValidation}
						value={ipv6Address}
						onChange={handleIPv6Change}
					/>
				) : (
					<IPv4AddressInput
						id={portGeneralIPv4AddressInputId}
						key='ipv4Address'
						addressValidation={ipAddressValidation}
						value={ipAddress}
						onChange={handleIPv4Change}
					/>
				)}
			</div>
			<div className={styles.inputValidation}>
				{ipAddressValidation && (
					<label
						id='portGeneralIPAddressErrorLabel'
						htmlFor={portNetworkType === PortNetWorkType.IPv6 ? portGeneralIPv6AddressInputId : portGeneralIPv4AddressInputId}
						className={styles.errorMessage}>
						{errorMessage}
					</label>
				)}
			</div>
		</>
	);
};

export { IPAddress };

import { WarningTwoTone } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import arrayMove from 'array-move';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { SortEnd } from 'react-sortable-hoc';
import { handleResponse, isEmptyOrSpaces } from '../../../../../../../Helper';
import { credentialApi } from '../../../../../../../api';
import { ResponseStatusCode } from '../../../../../../../model/CommonModel';
import { CardFormat, ConcatenatedCardData } from '../../../../../../../model/EnrollmentModel';
import { Modal } from '../../../../../../common';
import { GeneralTabCardProperty } from '../../../../CredentialTemplateModalModels';
import { is200BitCardType } from '../../../../Helper';
import { setCardPropertyAction, setErrorAction, setRootModelPropertyAction } from '../../../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../../../credentialTemplateContext/context';
import styles from '../../../../credentialmodal.module.scss';
import UDFModal from './UDFModal';

const CARD_DATA_MAX_LENGTH = 32;
type Props = {
	isNew: boolean;
};

const CardSection: React.FC<Props> = ({ isNew }) => {
	const CARD_DATA_UDF_START = '<UDF>';
	const {
		credentialInitialState: {
			lastCredentialId,
			configData: { IsDeviceAdminCredentialTemplate },
			errors: { matchCode: matchCodeErr },
			fetchedCredential,
			generalTabState: {
				credentialId,
				credentialTemplateId,
				IDF,
				cardSectionState: { cardFormats, cardData, cardStamp, cardType, matchCode, hasUDFDataSelected },
			},
			modelEstablished,
			userId,
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	const [cardDataD, setCardDataD] = useState<boolean>(false);
	const [cardStampD, setCardStampD] = useState<boolean>(false);
	const [cardTypeD, setCardTypeD] = useState<boolean>(false);
	const [concatenatedCardData, setConcatenatedCardData] = useState<ConcatenatedCardData[]>(undefined);
	const [matchCodeD, setMatchCodeD] = useState<boolean>(false);
	const [matchCodeE, setMatchCodeE] = useState<boolean>(matchCodeErr !== undefined);
	const [showUDFModal, setShowUDFModal] = useState<boolean>(false);
	const [udfConcatButtonD, setUdfConcatButtonD] = useState<boolean>(false);
	const [showMatchModalError, setShowMatchModalError] = useState<boolean>(false);
	const [matchLabel, setMatchLabel] = useState<string>(_('MATCH'));
	const [matchLength, setMatchLength] = useState<number>(16);
	const [matchLengthLabel, setMatchLengthLabel] = useState<string>('8-16');
	const [cardStampInfoH, setCardStampInfoH] = useState<boolean>(false);
	const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

	useEffect(() => {
		dispatcher(setErrorAction({ matchCode: undefined }));
		IDFChanged();
	}, [IDF]);

	useEffect(() => {
		setMatchCodeE(matchCodeErr !== undefined);
	}, [matchCodeErr]);

	useEffect(() => {
		setMatchCodeE(false);
		if (matchCode && matchCode.length < 8) setMatchCodeE(true);
	}, [matchCode]);

	useEffect(() => {
		cardTypeChanged();
	}, [cardType]);

	useEffect(() => {
		if (fetchedCredential && modelEstablished) {
			reconstructConcatenatedData();
		}
	}, [fetchedCredential]);

	useEffect(() => {
		setIsFirstRender(false);
	}, []);

	const cardTypeChanged = (paramCardData?: string) => {
		const localCardData: string = paramCardData || cardData;
		if (is200BitCardType(cardType)) {
			fillMatchCodeSection(localCardData, cardType, 32);
		} else {
			fillMatchCodeSection(localCardData, cardType, 16);
		}

		dispatcher(setRootModelPropertyAction({ lastCredentialId: -2 }));
	};

	const fillMatchCodeSection = (localCardData: string, cardType: number, matchLength: number) => {
		let matchLabel: string = _('MATCH');
		let matchLengthLabel: string = '8-16';
		let cardStampInfoH: boolean = false;

		if (matchCode) {
			dispatcher(setCardPropertyAction({ matchCode: matchCode.substring(0, matchLength) }));
		}

		if (!IsDeviceAdminCredentialTemplate) {
			if (hasUDFDataSelected) {
				setCardDataD(true);
				setMatchCodeD(true);
				generateMatchCode(localCardData, cardType);
			} else if (localCardData.length > 0) {
				generateMatchCode(localCardData, cardType);
			}
		}

		switch (cardType) {
			case 45:
				matchLabel = _('FASCN');
				matchLengthLabel = '32';
				cardStampInfoH = true;
				break;
			case 46:
				matchLabel = _('f200bit');
				matchLengthLabel = '32';
				cardStampInfoH = true;
				break;
		}

		batch(() => {
			setCardStampInfoH(cardStampInfoH);
			setMatchLength(matchLength);
			setMatchLabel(matchLabel);
			setMatchLengthLabel(matchLengthLabel);
		});
	};

	const IDFChanged = () => {
		if (IsDeviceAdminCredentialTemplate) {
			disableFieldsForTemplate();
		} else {
			credentialIDFChangeFields();
		}
	};

	const disableFieldsForTemplate = () => {
		toggleDisableAll(true);
		if (IDF > 1) disableFieldsForTemplateDefaultCase();
	};

	const toggleDisableAll = (disabled: boolean) => {
		batch(() => {
			setCardDataD(disabled);
			setCardStampD(disabled);
			setCardTypeD(disabled);
			setMatchCodeD(disabled);
			setUdfConcatButtonD(disabled);
		});
	};

	const disableFieldsForTemplateDefaultCase = () => {
		batch(() => {
			if (isFirstRender && !(hasUDFDataSelected || credentialId <= 0)) {
				setCardDataD(false);
				setMatchCodeD(false);
			}
			setCardTypeD(false);
			setUdfConcatButtonD(false);
		});
	};

	const credentialIDFChangeFields = () => {
		let disabled = false;
		if (IDF < 2) disabled = true;
		toggleDisableAll(disabled);
	};

	const handleChangeCardProperty = (value: GeneralTabCardProperty) => {
		batch(() => {
			dispatcher(setRootModelPropertyAction({ hasChanged: true }));
			dispatcher(setCardPropertyAction(value));
		});
	};

	const onCheckItem = (idx: number, checked: boolean) => {
		if (concatenatedCardData?.length > 0) {
			const clonedConcatenatedCardData = [...concatenatedCardData];
			const selectedObj = clonedConcatenatedCardData[idx];
			selectedObj.Selected = checked;
			clonedConcatenatedCardData.splice(idx, 1, selectedObj);
			setConcatenatedCardData(clonedConcatenatedCardData);
		}
	};

	const reconstructConcatenatedData = () => {
		const localCredentialId = !IsDeviceAdminCredentialTemplate && credentialTemplateId > -1 ? credentialTemplateId : credentialId;
		if (!IsDeviceAdminCredentialTemplate && (localCredentialId > 0 || userId > 0)) {
			credentialApi.retrieveConcatenatedCardData(localCredentialId, userId, isNew).then(response => {
				const data: Partial<GeneralTabCardProperty> = {
					cardData,
					matchCode: '',
				};

				if (!handleResponse(response) && response.ResponseStatusCode === ResponseStatusCode.Success) {
					const selectedUDF = response.Data.filter(x => x.Selected);
					let concatenatedData =
						selectedUDF?.length > 0 ? selectedUDF.reduce((prev: string, curr: ConcatenatedCardData) => (prev += curr.Value ?? ''), '') : '';
					// selectedUDF.forEach(x => (x.Value ? (concatenatedData += x.Value) : x));

					if (concatenatedData) {
						concatenatedData = concatenatedData.substring(0, Math.min(CARD_DATA_MAX_LENGTH, concatenatedData.length));
						data.cardData = `${CARD_DATA_UDF_START}${concatenatedData}`;
						data.hasUDFDataSelected = true;

						if (is200BitCardType(cardType)) data.matchCode = concatenatedData;

						setCardDataD(true);
						setMatchCodeD(true);
					}
					dispatcher(setCardPropertyAction(data));
				}
				cardTypeChanged(data.cardData);
			});
		}
	};

	const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove([].concat(concatenatedCardData), oldIndex, newIndex);
			setConcatenatedCardData(newData);
		}
	};

	const onConfirmUDFModal = (data: string, hasChecked: boolean, concatenatedData: number[]) => {
		setMatchCodeE(false);
		if (hasChecked) {
			setMatchCodeD(true);
		} else {
			setMatchCodeD(false);
		}

		const payload: GeneralTabCardProperty = {
			cardData: '',
			concatenatedData: concatenatedData || [],
			matchCode: '',
			saveConcatenatedData: false,
			hasUDFDataSelected: hasChecked,
		};

		if (data === '') {
			let cardDataValue = '';
			if (hasChecked) {
				cardDataValue = CARD_DATA_UDF_START;
				setCardDataD(true);
			} else {
				setCardDataD(false);
			}
			payload.cardData = cardDataValue;
			payload.matchCode = '';
		} else {
			let tempData = data.replace(CARD_DATA_UDF_START, '').substring(0, Math.min(CARD_DATA_MAX_LENGTH, data.length));
			if (is200BitCardType(cardType)) {
				payload.matchCode = tempData;
			}
			tempData = `${CARD_DATA_UDF_START}${tempData}`;
			payload.cardData = tempData;
			setCardDataD(true);
			if (hasChecked && !IsDeviceAdminCredentialTemplate) {
				generateMatchCode(tempData, cardType);
			}
		}
		payload.saveConcatenatedData = true;
		batch(() => {
			dispatcher(setRootModelPropertyAction({ hasChanged: true }));
			dispatcher(setErrorAction({ matchCode: undefined }));
			dispatcher(setCardPropertyAction(payload));
		});

		setShowUDFModal(false);
	};

	const generateMatchCode = (lCardData: string, lCardType: number) => {
		const validUDFString: boolean = !hasUDFDataSelected || (hasUDFDataSelected && lCardData !== CARD_DATA_UDF_START);
		if (lCardData !== '' && validUDFString && (!isFirstRender || isNew)) {
			credentialApi.generateMatchCode(lCardData, lCardType).then(res => {
				if (res.indexOf('ERROR_STATUS')) {
					dispatcher(setCardPropertyAction({ matchCode: res }));
				} else {
					setShowMatchModalError(true);
				}
			});
		}
	};

	const triggerMatchCodeGeneration = () => {
		generateMatchCode(cardData, cardType);
		dispatcher(setRootModelPropertyAction({ hasChanged: true }));
	};

	const handleOnCardDataKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			triggerMatchCodeGeneration();
		}
	};

	const generateMatchCodeOnBlur = () => {
		if (!IsDeviceAdminCredentialTemplate && isEmptyOrSpaces(matchCode)) {
			triggerMatchCodeGeneration();
		}
	};

	const retrieveConcatenatedCardData = (credentialId: number) => {
		if (lastCredentialId != credentialId) {
			if (credentialId > 0 || userId > 0 || IsDeviceAdminCredentialTemplate) {
				if (!concatenatedCardData) {
					credentialApi.retrieveConcatenatedCardData(credentialId, userId, isNew).then(response => {
						if (!handleResponse(response) && response.ResponseStatusCode === ResponseStatusCode.Success) {
							setConcatenatedCardData(response.Data);
							setShowUDFModal(true);
							const cd: number[] = [];
							response.Data.forEach(d => {
								if (d.Selected) cd.push(d.FieldNumber);
							});
							batch(() => {
								dispatcher(setCardPropertyAction({ concatenatedData: cd }));
								dispatcher(setRootModelPropertyAction({ lastCredentialId: credentialId }));
							});
						}
					});
				} else {
					dispatcher(setRootModelPropertyAction({ lastCredentialId: credentialId }));
					setShowUDFModal(true);
				}
			}
		} else {
			setShowUDFModal(true);
		}
	};

	const handleClickUDFButton = () => {
		let credId = credentialId;
		if (credentialId === -1) {
			credId = credentialTemplateId;
		}
		retrieveConcatenatedCardData(credId);
	};

	const isHex = (value: string): boolean => {
		if (value) {
			for (const v of value) {
				const a = parseInt(v, 16);
				if (a.toString(16) !== v.toLowerCase()) return false;
			}
		}
		return true;
	};

	const handleOnChangeMatchCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		dispatcher(setCardPropertyAction({ matchCode: isHex(value) ? value : matchCode }));
	};

	const matchCodeInputId = 'matchCode';

	let matchCodeBg: React.CSSProperties = {};
	if (matchCodeE) matchCodeBg = { backgroundColor: 'rgb(255, 178, 178)' };

	return (
		<>
			<div className={styles.containerLegend}>
				<fieldset className={styles.fieldset}>
					<legend className={styles.legend}>{_('Card')}</legend>
					<div className={styles.form}>
						<div className={styles.sections}>
							<label htmlFor='cardType'>{_('Type')}:</label>
							<Select
								className={styles.fullWidthSelect}
								disabled={cardTypeD}
								id='cardType'
								getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
								onChange={value => {
									handleChangeCardProperty({ cardType: value });
								}}
								options={cardFormats.map((cardFormat: CardFormat, idx: number) => ({
									key: `${cardFormat.CardFormatId}_${idx}`,
									label: cardFormat.CardFormatName,
									value: cardFormat.CardFormatId,
								}))}
								value={cardType}
							/>
						</div>
						<div className={cx(styles.sections, { [styles.hidden]: cardStampInfoH })}>
							<label htmlFor='cardStamp'>{_('Stamp')}:</label>
							<Input
								className={styles.fullWidthInput}
								disabled={cardStampD}
								id='cardStamp'
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleChangeCardProperty({ cardStamp: e.target.value });
								}}
								name='cardStamp'
								value={cardStamp}
							/>
						</div>
						<div className={styles.sections} style={{ gridTemplateColumns: '110px auto 90px' }}>
							<label htmlFor='cardData'>{_('Data')}:</label>
							<Input
								className={cx(styles.input, styles.cardInput)}
								disabled={cardDataD}
								id='cardData'
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									handleChangeCardProperty({ cardData: e.target.value });
								}}
								onKeyPress={handleOnCardDataKeyPress}
								onBlur={generateMatchCodeOnBlur}
								maxLength={37}
								name='cardData'
								value={cardData}
							/>
							<Button
								className={cx({ [styles.hidden]: udfConcatButtonD })}
								disabled={udfConcatButtonD}
								htmlType='button'
								id='UDFConcatButton'
								onClick={handleClickUDFButton}
								style={{ marginLeft: 10 }}>
								{_('UDF')}
							</Button>
						</div>
						<div className={styles.sections}>
							<label htmlFor={matchCodeInputId}>
								{matchLabel}:
								<WarningTwoTone twoToneColor={'#ff0000'} className={cx(styles.centeredIcon, { [styles.hidden]: !matchCodeE })} />
							</label>
							<div>
								<div style={{ display: 'grid', gridTemplateColumns: 'auto 90px', columnGap: 5 }}>
									<div className={cx(styles.gridTwoAutoCols, { [styles.error]: matchCodeE })}>
										<Input
											className={cx(styles.input, styles.cardInput)}
											disabled={matchCodeD}
											id={matchCodeInputId}
											maxLength={matchLength}
											onChange={handleOnChangeMatchCode}
											onBlur={generateMatchCodeOnBlur}
											name={matchCodeInputId}
											style={matchCodeBg}
											value={matchCode}
										/>
									</div>
									<div style={{ textAlign: 'center' }}>
										<label id='MatchLenLabel'>{matchLengthLabel}</label>
										{` ${_('digits')}`}
									</div>
								</div>
							</div>
						</div>

						{matchCodeErr && (
							<div className={styles.sections}>
								<div></div>
								<label className={styles.errorMessage} htmlFor={matchCodeInputId}>
									{matchCodeErr}
								</label>
							</div>
						)}
					</div>
				</fieldset>
			</div>
			<UDFModal
				cardType={cardType}
				concatenatedCardData={concatenatedCardData || []}
				IsDeviceAdminCredentialTemplate={IsDeviceAdminCredentialTemplate}
				onCheckItem={onCheckItem}
				onOkModal={onConfirmUDFModal}
				onSortEnd={onSortEnd}
				setShowUDFModal={setShowUDFModal}
				showUDFModal={showUDFModal}
			/>
			{showMatchModalError && (
				<Modal
					onCancel={() => {
						setShowMatchModalError(false);
					}}
					keyboard={false}
					footer={[
						<Button
							htmlType='button'
							onClick={() => {
								setShowMatchModalError(false);
							}}
							type='primary'>
							{_('Ok')}
						</Button>,
					]}
					title={null}
					visible>
					Invalid card data entered
				</Modal>
			)}
		</>
	);
};

export default CardSection;

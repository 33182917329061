import { Button, Input, notification, Select } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import styles from '../../../credentialmodal.module.scss';
import { CredentialReissueTable } from '../../../CredentialTemplateModalModels';

type ReissueFormProps = {
	handleOnCancelReissue: () => void;
	isNewCredential: boolean;
	issue?: CredentialReissueTable;
	issueControlCount: number;
	reissueCredential: (issue: Partial<CredentialReissueTable>, isEdit: boolean) => void;
};

const ReissueForm: React.FC<ReissueFormProps> = ({ handleOnCancelReissue, isNewCredential, issue, issueControlCount, reissueCredential }) => {
	const [reason, setReason] = useState<string>(issue?.Reason || '');
	const [comment, setComment] = useState<string>(issue?.Comment || '');
	const [showContent, setShowContent] = useState(false);

	useEffect(() => {
		setShowContent(true);
		return () => {
			setShowContent(false);
		};
	}, []);

	const addIssue = () => {
		if (reason === '' || comment === '') {
			if (reason === '')
				notification['error']({
					message: 'A reason for re-issuing credential must be specified',
				});
			if (comment === '')
				notification['error']({
					message: 'A comment for re-issuing credential must be specified',
				});
		} else {
			const date = new Date();
			const userTimeFormat: string = (document.getElementById('UserDateFormat') as HTMLInputElement).value;
			const dateText = formatDateTime(date, userTimeFormat);

			let lIssue: Partial<CredentialReissueTable> = issue ? { ...issue } : {};

			lIssue.Comment = comment;
			lIssue.Reason = reason;

			if (!issue) {
				lIssue.IssueNumber = issueControlCount + 1;
				lIssue.Date = date;
				lIssue.formattedDate = dateText;
			}

			reissueCredential(lIssue, issue ? true : false);
		}
	};

	const handleOnChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setComment(e.target.value);
	};

	return (
		<Transition in={showContent} timeout={1}>
			{state => (
				<div className={styles[`my-node-${state}`]}>
					<div className={cx(styles.container, styles.form)}>
						<div className={styles.sections}>
							<label htmlFor='reason'>{_('Reason')}</label>
							<Select
								className={styles.select}
								defaultActiveFirstOption
								disabled={isNewCredential}
								getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
								onChange={value => {
									setReason(value);
								}}
								value={reason}>
								<Select.Option value='Lost'>{_('Lost')}</Select.Option>
								<Select.Option value='Stolen'>{_('Stolen')}</Select.Option>
								<Select.Option value='Destroyed'>{_('Destroyed')}</Select.Option>
							</Select>
						</div>
						<div className={styles.sections}>
							<label htmlFor='issueControlComment'>{_('Comment')}</label>
							<Input.TextArea onChange={handleOnChangeComment} rows={4} value={comment} />
						</div>
						<div className={styles.reissueFormFooter}>
							<Button htmlType='button' onClick={handleOnCancelReissue} type='default'>
								{_('Cancel')}
							</Button>
							<Button htmlType='button' onClick={addIssue} type='primary'>
								{_('Ok')}
							</Button>
						</div>
					</div>
				</div>
			)}
		</Transition>
	);
};

export default ReissueForm;

import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { deviceAdminApi } from '../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	DefaultColumns,
	OperatorsColumn,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	SelectOptions,
	SelectedDeviceKeys,
	SelectedDevicePage,
} from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	DeviceObjectType,
	DeviceSelectedPage,
	OptionsButtonBuilder,
	ReaderControlGroupItem,
	SelectedRowKeyPagination,
	VelocityConfigurationModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import {
	clearVelocityConfigurationSelectionsAction,
	setReaderControlGroupsBy,
	setReaderControlGroupsByData,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
	setVelocitySelectedRowKeysPaginationAction,
} from '../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfiguration } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, EditableCell, ModalConfirmation, ModalConfirmationList, NotificationStatus, SearchColumn } from '../../../common';
import { ReaderModal } from './ReaderModal/ReaderModal';
import { ReaderWarning } from './ReaderWarning/ReaderWarning';
import styles from './readerControlGroup.module.scss';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Reader_Control_Group);
const scroll: ScrollType = { x: 379, y: 312 };
const maxLength: number = 64;
const deviceObjectType: DeviceObjectType = DeviceObjectType.ReaderControlGroup;

const createButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
				id: 'editReaderControlGroupButton',
				label: _('Edit'),
				disabled: !componentPermission.canView || hasMoreThanOne,
				value: 'edit',
				title: getPermissionErrorMessage(componentPermission.canView, false, hasMoreThanOne),
			},
			{
				id: 'renameReaderControlGroupButton',
				label: _('Rename'),
				disabled: !componentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
				value: 'rename',
				title: getPermissionErrorMessage(componentPermission.canUpdate, false, hasMoreThanOne),
			},
			{
				id: 'deleteReaderControlGroupButton',
				label: _('Delete'),
				disabled: !componentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(componentPermission.canDelete),
			},
		],
	};
};

const ReaderControlGroup: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const { readerControls, isEditMode, isFilterMode, selectedRowKeysPagination }: VelocityConfigurationModel = useStoreSelector(selectVelocityConfiguration);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [editingKey, setEditingKey] = useState('');
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<DefaultColumns[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [readerToDelete, setReaderToDelete] = useState<Partial<ReaderControlGroupItem>[]>([]);
	const [readerToBeEditable, setReaderEditable] = useState<ReaderControlGroupItem>(undefined);
	const [readerControlGroupSelectedPage, setReaderControlGroupSelectedPage] = useState<SelectedDevicePage>(null);
	const [form] = Form.useForm();

	const redirectPage = useRef<boolean>(false);
	const isSearchPerformed = useRef<boolean>(false);

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setIsItemOnSamePage(isSamePage);
		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		const mapRes: DefaultColumns[] = createData();
		setTableData(mapRes);
	}, [JSON.stringify(readerControls)]);

	// * This hook is responsible for loading the previously edited command set
	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage.current) {
			const readerControlGroupId = Number(selectedRowKeys?.[0]);
			getReaderControlGroupSelectedPage(readerControlGroupId);
		}
	}, [redirectPage.current]);

	useEffect(() => {
		if (readerControlGroupSelectedPage) {
			setRedirectPage(false);
			setIsItemOnSamePage(true);

			const { deviceId, pageNumber } = readerControlGroupSelectedPage;

			batch(() => {
				dispatch(setVelocitySelectedRowKeysPaginationAction([{ Id: deviceId.toString(), PaginationPage: pageNumber }]));
				dispatch(setReaderControlGroupsBy({ ...tablePaginationSetting, PageNumber: pageNumber, SearchedValue: '' }));
			});

			setReaderControlGroupSelectedPage(null);
		}
	}, [JSON.stringify(readerControlGroupSelectedPage)]);

	// * This hook ensure that always return a valid page number when no values has returned
	useEffect(() => {
		if (tablePaginationSetting.TotalItems === 0 && tablePaginationSetting.PageNumber > 1) {
			const { PageNumber: currentPage } = tablePaginationSetting;
			dispatch(
				setReaderControlGroupsBy({
					...tablePaginationSetting,
					PageNumber: currentPage - 1,
				})
			);
		}
	}, [tablePaginationSetting]);

	useEffect(() => {
		return () => {
			clearSelection();
		};
	}, []);

	const getReaderControlGroupSelectedPage = async (deviceId: number) => {
		const deviceSelectedPage: DeviceSelectedPage = {
			DeviceId: deviceId,
			DeviceObjectType: deviceObjectType,
			IsMasterDoorGroup: false,
		};
		const response = await deviceAdminApi.getVelocityDeviceSelectedPage(deviceSelectedPage, { ...tablePaginationSetting, SearchedValue: '' });
		const { Entity: pageNumber } = response;

		setReaderControlGroupSelectedPage({ deviceId, pageNumber });
	};

	const clearSelection = () => {
		if (redirectPage.current) {
			return;
		}
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.ReaderControlGroup }));
		setSelectedRowKeys([]);
	};

	const getSelectedReaderControlGroups = async (): Promise<SelectedDeviceKeys[]> => {
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);

		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const getReaderControlGroup = async (readerControlGroupId: number): Promise<ReaderControlGroupItem> => {
		const response = await deviceAdminApi.getReaderControlGroup(readerControlGroupId);

		if (handleResponse(response)) {
			return;
		}

		return response.Entity;
	};

	const getSelectedReaderControlGroup = async (readerControlGroupId: number): Promise<ReaderControlGroupItem> => {
		return readerControls.find(x => x.ReaderControlGroupId === readerControlGroupId) ?? (await getReaderControlGroup(readerControlGroupId));
	};

	const handleActionScope = async (key: string) => {
		if (key === 'delete') {
			const selectedReaderControlGroups = await getSelectedReaderControlGroups();
			ModalConfirmationList({
				type: _('ReaderControlGroup'),
				data: selectedReaderControlGroups,
				onConfirm: () => onTryToDeleteReaderGroup(selectedReaderControlGroups),
			});

			return;
		}

		const selectedReaderControlGroupId = Number(selectedRowKeys?.[0]);
		const reader: ReaderControlGroupItem = await getSelectedReaderControlGroup(selectedReaderControlGroupId);
		const lockComponentCondition: boolean = key === 'edit';
		const isLocked: boolean = await lockedValidation(DeviceObjectType.ReaderControlGroup, reader.ReaderControlGroupId, lockComponentCondition);
		if (isLocked) {
			return;
		}

		switch (key) {
			case 'rename':
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: DefaultColumns = tableData.find(x => x.key === reader.ReaderControlGroupId.toString());
				edit(findKey);
				const cloneState: ReaderControlGroupItem[] = changeStateProps(reader.ReaderControlGroupId, { editable: true });
				dispatch(setReaderControlGroupsByData(cloneState));
				break;

			case 'edit':
				handleSetModalVisible(true, reader);
				break;
		}
	};

	const changeStateProps = (deviceId: number, readerControlGroup: Partial<ReaderControlGroupItem>): ReaderControlGroupItem[] => {
		const cloneState: ReaderControlGroupItem[] = [...readerControls];
		const index = cloneState.findIndex(x => x.ReaderControlGroupId === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...readerControlGroup });
		}

		return cloneState;
	};

	const edit = (record: DefaultColumns) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const resetSearchColumn = () => {
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const handleOnSaveEditRow = async (deviceId: number) => {
		const isLocked: boolean = await lockedValidation(DeviceObjectType.ReaderControlGroup, deviceId, false);
		if (isLocked) {
			setEditingKey('');
			const cloneState: ReaderControlGroupItem[] = changeStateProps(deviceId, { editable: false });
			dispatch(setReaderControlGroupsByData(cloneState));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(DeviceObjectType.ReaderControlGroup, name.trim(), deviceId);
		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setReaderControlGroupsBy(tablePaginationSetting));
			return;
		}

		setEditingKey('');
		dispatch(setVelocityConfigurationEditMode(false));
		resetSearchColumn();
		setRedirectPage(true);
		dispatch(setReaderControlGroupsBy(tablePaginationSetting));
	};

	const handleOnCancelEditRow = deviceId => {
		const cloneState: ReaderControlGroupItem[] = changeStateProps(deviceId, { editable: false });
		dispatch(setReaderControlGroupsByData(cloneState));
		dispatch(setVelocityConfigurationEditMode(false));
		setEditingKey('');
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getReaderControlGroups();
		if (handleResponse(response)) {
			return;
		}

		const commandSetsIds = response.Entity?.map<string>(x => x.ReaderControlGroupId.toString());
		return getUniqueValuesArray(newSelectedRowKeys, commandSetsIds);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: ReaderControlGroupItem[] = readerControls.map(x => ({
			...x,
			checked: selectedRowKeys.findIndex(r => r === x.ReaderControlGroupId) !== -1,
		}));
		dispatch(setReaderControlGroupsByData(cloneState));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			selectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);

		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: ReaderControlGroupItem[] = readerControls.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(r => r === x.ReaderControlGroupId?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setReaderControlGroupsByData(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		setIsTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = readerControls.map<React.Key>(x => x.ReaderControlGroupId?.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		const cloneState: ReaderControlGroupItem[] = readerControls.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.ReaderControlGroupId?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setReaderControlGroupsByData(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const handleSetModalVisible = (visible: boolean, reader?: ReaderControlGroupItem) => {
		setReaderEditable(reader);
		setOpenModal(visible);
	};

	const handleOkConfirmation = async (reader: ReaderControlGroupItem) => {
		const response = await deviceAdminApi.deleteReaderControlGroup(reader.ReaderControlGroupId);
		const isValid = !handleResponse(response);

		updateDeleteState(reader);
		if (isValid) {
			setSelectedRowKeys(prevState => [...prevState.filter(key => key !== reader.ReaderControlGroupId)]);
			dispatch(setVelocitySelectedRowKeysPaginationAction([...selectedRowKeysPagination.filter(x => x.Id !== reader.ReaderControlGroupId.toString())]));
		}

		dispatch(setReaderControlGroupsBy(tablePaginationSetting));
	};

	const handleCancelConfirmation = async (reader: ReaderControlGroupItem) => {
		await deviceAdminApi.unlockComponentDeviceAdmin(reader.ReaderControlGroupId, SecuredComponents.Reader_Control_Group);
		updateDeleteState(reader);
	};

	const updateDeleteState = (reader: Partial<ReaderControlGroupItem>) => {
		setReaderToDelete(readerControlGroups => {
			return [...readerControlGroups.filter(x => x.ReaderControlGroupId !== reader.ReaderControlGroupId)];
		});
	};

	const onTryToDeleteReaderGroup = async (selectedReaderControlGroups: SelectedDeviceKeys[]) => {
		const readerControlsSelected = selectedReaderControlGroups.map<ReaderControlGroupItem>(x => ({ ReaderControlGroupId: x.Id, Name: x.Name }));
		setReaderToDelete(readerControlGroups => {
			return [...readerControlsSelected];
		});

		for (const reader of readerControlsSelected) {
			const response = await deviceAdminApi.canDeleteReaderControlGroup(reader.ReaderControlGroupId);

			NotificationStatus({
				responseData: response,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					handleOkConfirmation(reader);
				},
				onAdditionalInfoRequiredCallback: async () => {
					const warningData = await deviceAdminApi.getReaderControlGroupDeleteWarning(reader.ReaderControlGroupId);
					const { WarningMessage, RCGCredentials } = warningData;
					const content = <ReaderWarning rcgCredentials={RCGCredentials} messageError={WarningMessage} />;
					const contentMessage = (
						<span dangerouslySetInnerHTML={{ __html: _('AreYouSureYouWantToDeleteReaderControlGroup').replace('%1', reader.Name) }} />
					);

					ModalConfirmation({
						title: contentMessage,
						content,
						onConfirm: () => handleOkConfirmation(reader),
						onCancel: () => handleCancelConfirmation(reader),
					});
				},
				onPermissionErrorCallback: async () => {
					notification['error']({
						message: response.ErrorMessage,
					});
				},
			});
		}
	};

	const createActionElement = (reader: ReaderControlGroupItem): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (reader.editable) {
			content = (
				<>
					<Button id='renameReaderControlGroupSaveButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(reader.ReaderControlGroupId)}>
						{_('Save')}
					</Button>
					<Button id='renameReaderControlGroupCancelButton' key='cancel' onClick={() => handleOnCancelEditRow(reader.ReaderControlGroupId)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): DefaultColumns[] => {
		if (readerControls.length > 0) {
			return readerControls.map<DefaultColumns>((dataItem: ReaderControlGroupItem) => ({
				Action: createActionElement(dataItem),
				key: dataItem.ReaderControlGroupId.toString(),
				Name: dataItem.Name,
			}));
		}

		return [];
	};

	const handleBeforeUnload = () => {
		setReaderToDelete(readerControlGroups => {
			for (const reader of readerControlGroups) {
				deviceAdminApi.unlockComponentDeviceAdmin(reader.ReaderControlGroupId, SecuredComponents.Reader_Control_Group);
			}

			return readerControlGroups;
		});
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} disabled={x.disabled} onClick={x.onClick} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const buttons: ButtonBuilder[] = [
		{
			id: 'addReaderControlGroupButton',
			label: _('AddReaderControlGroup'),
			icon: <PlusOutlined />,
			onClick: () => {
				handleSetModalVisible(true);
			},
			disabled: !componentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(componentPermission.canAdd),
		},
	];

	const disabled: boolean = editingKey !== '';
	const hasMoreThanOneKeySelected: boolean = selectedRowKeys.length > 1;
	const items = createButtonOptions(hasMoreThanOneKeySelected, isItemOnSamePage);

	const handleOnSearchResults = (searchedValue: string) => {
		if (redirectPage.current) {
			return;
		}
		dispatch(setReaderControlGroupsBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	let columns: ColumnsProps<DefaultColumns>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
	];
	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const onChangeSelection = (key: BaseColumns) => {
		const readerControlGroupId = key.key.toString();
		if (selectedRowKeys.findIndex(key => key === readerControlGroupId) === -1) {
			const cloneState: ReaderControlGroupItem[] = readerControls.map(x =>
				x.ReaderControlGroupId.toString() === readerControlGroupId ? { ...x, checked: true } : { ...x, checked: false }
			);
			dispatch(setReaderControlGroupsByData(cloneState));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: readerControlGroupId.toString(),
				PaginationPage: tablePaginationSetting.PageNumber,
			};
			setIsItemOnSamePage(true);
			dispatch(setVelocitySelectedRowKeysPaginationAction([{ ...selectedKeysPagination }]));
			setSelectedRowKeys([readerControlGroupId]);
		}
	};

	const isEditing = (record: DefaultColumns) => record.key.toString() === editingKey;
	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: DefaultColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: DefaultColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const rowSelection: TableRowSelection<DefaultColumns> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `readerControlGroupTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`readerControlGroupTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectControlGroup')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<OperatorsColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setReaderControlGroupsBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<div className={styles.container}>
			<div className={styles.buttonContainer}>
				<ButtonDropdown
					id={'readerControlGroupTableActionDropdown'}
					disabled={disabledActionButtons}
					menuOptions={items.options}
					icon={<CaretDownOutlined />}
					labelIcon={items.labelOrIcon}
					onClickOption={handleActionScope}
				/>
				{buttonsBuilder(buttons)}
			</div>
			<Form form={form} component={false}>
				<Table
					id='ReaderControlGroupTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					scroll={scroll}
					pagination={pagination}
					size='small'
					className={styles.statusWidgetTable}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
				/>
			</Form>
			{openModal && (
				<ReaderModal
					onSetVisible={() => handleSetModalVisible(false)}
					readerToEdit={readerToBeEditable}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
		</div>
	);
};

export { ReaderControlGroup };

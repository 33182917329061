import React, { useContext, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../../api';
import { handleResponse } from '../../../../../../../../Helper';
import { InputLogicTimeZones } from '../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import {
	ReaderState,
	setDisableDuringTimezoneAction,
	setInputAction,
	setMaskDuringTimezoneAction,
	setOpenNewTimezoneModalAction,
	StoreContext,
} from '../../../../inputModalContext';
import styles from './timeZones.module.scss';

type Props = {
	setPreviousSelections: (InputLogicTimeZones) => void;
	previousSelections: InputLogicTimeZones;
	useDoorContext: boolean;
};

const TimeZones: React.FC<Props> = ({ setPreviousSelections, previousSelections, useDoorContext }) => {
	const [maskOptions, setMaskOptions] = useState([]);
	const [disableOptions, setDisableOptions] = useState([]);

	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleSelectMasking = (value: string) => {
		const timezoneId = Number(value);
		const maskDuringSelected = inputContext.context?.input?.GenericTimeZones.find(x => x.GenericId === timezoneId);

		inputContext.dispatcher(setMaskDuringTimezoneAction(timezoneId));
		inputContext.dispatcher(setInputAction({ MaskDuringTimeZoneSelected: maskDuringSelected }));
	};

	const handleSelectDisable = (value: string) => {
		const timezoneId = Number(value);
		const disableDuringSelected = inputContext.context?.input?.GenericTimeZones.find(x => x.GenericId === timezoneId);

		inputContext.dispatcher(setDisableDuringTimezoneAction(timezoneId));
		inputContext.dispatcher(setInputAction({ DisabledDuringTimeZoneSelected: disableDuringSelected }));
	};

	const isTimezoneLocked = () => {
		deviceAdminApi.checkIfAnyTimeZoneIsNotLocked().then(res => {
			const isLocked = handleResponse(res);
			if (!isLocked) {
				inputContext.dispatcher(setOpenNewTimezoneModalAction(true));
			}
		});
	};

	useEffect(() => {
		if (inputContext.context?.maskDuringTimezoneSelected === 0) {
			isTimezoneLocked();
		}
	}, [inputContext.context?.maskDuringTimezoneSelected]);

	useEffect(() => {
		if (inputContext.context?.disableDuringTimezoneSelected === 0) {
			isTimezoneLocked();
		}
	}, [inputContext.context?.disableDuringTimezoneSelected]);

	useEffect(() => {
		if (inputContext.context?.input?.MaskDuringTimeZoneSelected !== null && inputContext.context?.input?.DisabledDuringTimeZoneSelected !== null) {
			const maskOptions = inputContext.context?.input?.MaskDuringTimeZoneSelected?.Unpermitted
				? [
						{
							label: inputContext.context?.input?.MaskDuringTimeZoneSelected?.Name,
							value: inputContext.context?.input?.MaskDuringTimeZoneSelected?.GenericId.toString(),
						},
				  ]
				: inputContext.context?.input?.GenericTimeZones.map(x => ({ label: x.Name, value: x.GenericId.toString() }));
			if (maskOptions) {
				setMaskOptions([...maskOptions]);
			}
			const disableOptions = inputContext.context?.input?.DisabledDuringTimeZoneSelected?.Unpermitted
				? [
						{
							label: inputContext.context?.input?.DisabledDuringTimeZoneSelected?.Name,
							value: inputContext.context?.input?.DisabledDuringTimeZoneSelected?.GenericId.toString(),
						},
				  ]
				: inputContext.context?.input?.GenericTimeZones.map(x => ({ label: x.Name, value: x.GenericId.toString() }));
			if (disableOptions) {
				setDisableOptions([...disableOptions]);
			}
		}
	}, [JSON.stringify(inputContext.context?.input)]);

	return (
		<div className={styles.container}>
			<fieldset>
				<legend className={styles.legend}>{_('TimeZones')}</legend>
				<div className={styles.content}>
					<span>{_('MaskDuringTimeZone')}</span>
					<Select
						id='logicInputMaskDuringTimeZoneDropdown'
						value={inputContext.context?.maskDuringTimezoneSelected?.toString()}
						onChange={(value: string) => {
							setPreviousSelections({
								...previousSelections,
								MaskDuring:
									inputContext.context?.input?.MaskDuringTimeZoneSelected?.GenericId !== 0
										? inputContext.context?.input.MaskDuringTimeZoneSelected
										: previousSelections.MaskDuring,
							});
							handleSelectMasking(value);
						}}
						options={maskOptions}
						key='maskDuringTimeZone'
						disabled={inputContext.context?.input?.MaskDuringTimeZoneSelected?.Unpermitted ? true : false}
						getPopupContainer={() => document.getElementById('logicInputContainer')}
					/>
					<span>{_('DisableEntryDelayDuring')}</span>
					<Select
						id='logicInputDisableEntryDelayDropdown'
						onChange={(value: string) => {
							setPreviousSelections({
								...previousSelections,
								DisableEntry:
									inputContext.context?.input?.DisabledDuringTimeZoneSelected?.GenericId !== 0
										? inputContext.context?.input.DisabledDuringTimeZoneSelected
										: previousSelections.DisableEntry,
							});
							handleSelectDisable(value);
						}}
						value={inputContext.context?.disableDuringTimezoneSelected?.toString()}
						options={disableOptions}
						disabled={inputContext.context?.input?.DisabledDuringTimeZoneSelected?.Unpermitted ? true : false}
						key='disableEntryDelayDuring'
						getPopupContainer={() => document.getElementById('logicInputContainer')}
					/>
				</div>
			</fieldset>
		</div>
	);
};

export { TimeZones };

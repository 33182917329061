import { Button, List } from 'antd';
import React from 'react';
import { AvailableDoorInfo } from '../../../model/PhotoCallUpModel';
import './pagination.scss';

// Normal properties for the component
type Props = {
	doors: AvailableDoorInfo[];
	onToggleDoor: Function;
};

//Avoid creating object style inline, since increases reconciliations
const style: React.CSSProperties = { textAlign: 'center', justifyContent: 'center' };

const Pagination = (props: Props) => {
	function styleButton(door: AvailableDoorInfo): React.CSSProperties {
		if (door.IsHidden === true) {
			return {
				backgroundColor: 'white',
				color: door.Color,
				borderColor: door.Color,
			};
		}

		return {
			backgroundColor: door.Color,
			color: 'white',
			borderColor: door.Color,
		};
	}

	const { doors, onToggleDoor } = props;

	return (
		<List
			grid={{
				gutter: 0,
				xs: 1,
				sm: 1,
				md: 2,
				lg: 3,
				xl: 4,
				xxl: 5,
			}}
			className={'photo-pagination'}
			dataSource={doors}
			renderItem={item => (
				<List.Item style={style}>
					<Button title={AvailableDoorInfo.getFullName(item)} style={styleButton(item)} onClick={() => onToggleDoor(item.DoorId)}>
						{item.DoorName}
					</Button>
				</List.Item>
			)}
		/>
	);
};

export { Pagination };

import { Button } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { ControlZone } from '../../../../../../../model/DeviceAdminModel';
import { Modal, Select } from '../../../../../../common';
import { setEditControlZoneHasValue, setErrors, StoreContext } from '../../contextReader';
import styles from './editControlZone.module.scss';

type Props = {
	onOkControlZonesEdit: () => void;
	onCancel: () => void;
	controllerId: number;
};

const mainDivContainerId: string = 'readerControlGroupEditControlZoneContainer';

const EditControlZone: React.FC<Props> = ({ onOkControlZonesEdit, onCancel, controllerId }) => {
	const {
		readerState: {
			errors: { controlZoneEditInput },
		},
		dispatcher,
	} = useContext(StoreContext);

	const [controlZonesByController, setControlZonesByController] = useState([] as ControlZone[]);

	useEffect(() => {
		deviceAdminApi.getControlZonesByController(controllerId).then(res => {
			setControlZonesByController(
				res.map(x => ({
					ControlZoneIndex: x.CZIndex,
					ControlZoneName: x.Name,
				}))
			);
		});
	}, []);

	const handleChangeControlZone = (controlZoneId: string) => {
		const currentControlZone: ControlZone = controlZonesByController.find(x => x.ControlZoneIndex === Number(controlZoneId));
		dispatcher(setEditControlZoneHasValue(currentControlZone));
		dispatcher(setErrors({ type: 'controlZoneEditInput', value: false }));
	};

	const readerControlGroupEditControlZoneDropdownId = 'readerControlGroupEditControlZoneDropdown';
	const readerControlGroupEditControlZoneErrorLabelId = 'readerControlGroupEditControlZoneErrorLabel';

	return (
		<Modal
			className={styles.modalZoomAuto}
			footer={[
				<Button id='readerControlGroupEditControlZoneOkButton' key='save' type='primary' onClick={() => onOkControlZonesEdit()}>
					{_('Ok')}
				</Button>,
				<Button id='readerControlGroupEditControlZoneCancelButton' key='cancel' onClick={() => onCancel()}>
					{_('Cancel')}
				</Button>,
			]}
			width='900px'
			visible={true}
			title={_('EditControlZone')}
			onCancel={() => onCancel()}
			onClickOk={() => null}>
			<div className={styles.container} id={mainDivContainerId}>
				<label htmlFor={readerControlGroupEditControlZoneDropdownId}>{_('ControlZones')}</label>
				<div className={cx({ [styles.error]: controlZoneEditInput })}>
					<Select
						id={readerControlGroupEditControlZoneDropdownId}
						onChange={handleChangeControlZone}
						options={controlZonesByController.map(x => ({
							label: x.ControlZoneName,
							value: x.ControlZoneIndex,
							id: `readerControlGroupEditControlZoneOption-${x.ControlZoneIndex}`,
						}))}
					/>
					{controlZoneEditInput && (
						<label id='readerControlGroupEditControlZoneErrorLabel' htmlFor={readerControlGroupEditControlZoneDropdownId}>
							{_('ControlZoneIsRequired')}
						</label>
					)}
				</div>
			</div>
		</Modal>
	);
};

export { EditControlZone };

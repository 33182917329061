import { Input, InputNumber, Radio } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { filterOption, numericPositiveAndNegativeNonDecimalFormatter } from '../../../../../Helper';
import { CriteriaFunctionValue, FieldDataType, SearchEqualToKey, WithinTimes } from '../../../../../model/CommonModel';
import { DatePicker, Select, SelectOption } from '../../../../common';
import { getRemoveTimeSetup } from '../criteriaOptions';

type Props = {
	criteria: CriteriaFunctionValue;
	onChangeInput: (start: string, end?: string) => void;
	fieldType: FieldDataType;
	options: SelectOption;
	optionsBySearchText: SelectOption;
	onSearchSelect: (value: string) => void;
	onScrollPagination: (currentPage: number, reset?: boolean) => void;
	defaultPrimaryValue: string;
	defaultSecondaryValue: string;
	onSelectCriteria: (value: number) => void;
	selectCriteria: number;
	searchEqualToKey: SearchEqualToKey;
	withinTimes: WithinTimes;
	isEditing: boolean;
};
const style: React.CSSProperties = { display: 'flex', alignItems: 'center' };

const datePickerFormat = 'MM/DD/YYYY HH:mm:ss';
const bigIntMaxValue: bigint = BigInt('9223372036854775807');
const bigIntMinValue: bigint = BigInt(-1) * bigIntMaxValue;

const ReportCriteriaType: React.FC<Props> = ({
	defaultPrimaryValue,
	defaultSecondaryValue,
	optionsBySearchText,
	criteria,
	onChangeInput,
	fieldType,
	options,
	onSearchSelect,
	onScrollPagination,
	onSelectCriteria,
	selectCriteria,
	searchEqualToKey,
	withinTimes,
	isEditing,
}) => {
	const [pageNumber, setPageNumber] = useState(1);
	const [value, setValue] = useState(undefined);
	const [valueSearchText, setValueSearchText] = useState(undefined);
	const [timeSetup, setTimeSetup] = useState<{ amount: number; unit: moment.unitOfTime.DurationConstructor }>(getRemoveTimeSetup(withinTimes));
	const counter = useRef<number>(0);
	const initialUpdate = useRef<boolean>(true);
	const newInterval = useRef<boolean>(false);
	const lastEndDate = useRef(undefined);

	useEffect(() => {
		if (!initialUpdate.current && isEditing) {
			counter.current = 2;
		}
	}, [isEditing]);

	useEffect(() => {
		if (criteria === CriteriaFunctionValue.within && !initialUpdate.current) {
			setTimeSetup(getRemoveTimeSetup(withinTimes));
			newInterval.current = true;
		}
	}, [withinTimes]);

	//Reset options on field has changed
	useEffect(() => {
		if (criteria === CriteriaFunctionValue.equalTo && selectCriteria === 2) {
			onScrollPagination(1, true);
			setPageNumber(1);
		}

		if (isEditing) {
			counter.current = 2;
		} else {
			counter.current = 0;
		}

		if (criteria === CriteriaFunctionValue.within && (defaultPrimaryValue === null || defaultSecondaryValue === null)) {
			setInitialWithinData();
		}
		initialUpdate.current = false;
	}, [criteria, selectCriteria]);

	const getFormattedMomentString = (date: moment.Moment): string => (date ? moment(date.toString()).format(datePickerFormat) : null);

	const setInitialWithinData = () => {
		const startDate = moment();
		const endDate = moment().add(timeSetup.amount, timeSetup.unit);
		onChangeInput(getFormattedMomentString(startDate), getFormattedMomentString(endDate));
	};

	const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const target = event.currentTarget;
		if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
			const currentPage = pageNumber + 1;
			onScrollPagination(currentPage);
			setPageNumber(currentPage);
		}
	};

	const handleOnSelectCriteria = (criteria: number) => {
		onSelectCriteria(criteria);
		setValue(null);
		setValueSearchText(null);
		setPageNumber(1);
	};

	const renderContent = () => {
		let content: React.ReactElement = null;

		switch (criteria) {
			case CriteriaFunctionValue.between:
			case CriteriaFunctionValue.within:
				{
					if (fieldType === FieldDataType.Numeric) {
						const start = defaultPrimaryValue;
						const end = defaultSecondaryValue;
						content = (
							<React.Fragment>
								<InputNumber<string>
									stringMode
									aria-label={_('StartRange')}
									value={start === null ? null : start}
									formatter={numericPositiveAndNegativeNonDecimalFormatter}
									parser={numericPositiveAndNegativeNonDecimalFormatter}
									min={bigIntMinValue.toString()}
									max={bigIntMaxValue.toString()}
									width='100%'
									onChange={value => {
										if (value === undefined || value === null || value === '') {
											return;
										}

										const currentValue = BigInt(value);
										if (currentValue < bigIntMinValue || currentValue > bigIntMaxValue) {
											return;
										}

										onChangeInput(value);
										const newNextValue = BigInt(value) + BigInt(1);
										if (BigInt(end ?? bigIntMinValue) <= currentValue) {
											onChangeInput(undefined, `${newNextValue}`);
										} else if (currentValue >= bigIntMaxValue) {
											onChangeInput(undefined, bigIntMaxValue.toString());
										}
									}}
								/>
								<label style={style}>{_('AND')}</label>
								<InputNumber<string>
									stringMode
									aria-label={_('EndRange')}
									value={end === null ? null : end}
									formatter={numericPositiveAndNegativeNonDecimalFormatter}
									parser={numericPositiveAndNegativeNonDecimalFormatter}
									min={start}
									max={bigIntMaxValue.toString()}
									width='100%'
									onChange={value => {
										if (
											value === undefined ||
											value === null ||
											value === '' ||
											BigInt(value) < BigInt(start) ||
											BigInt(value) > bigIntMaxValue
										) {
											return;
										}

										const currentValue = BigInt(value);
										if (currentValue < BigInt(start) || currentValue > bigIntMaxValue) {
											return;
										}

										onChangeInput(undefined, value);
									}}
								/>
							</React.Fragment>
						);
					} else {
						let startDate = moment(defaultPrimaryValue);
						let endDate = moment(defaultSecondaryValue);
						if (criteria === CriteriaFunctionValue.within && !initialUpdate.current && counter.current >= 1) {
							if (startDate.isValid()) {
								endDate = moment(startDate).add(timeSetup.amount, timeSetup.unit);
							} else if (newInterval.current) {
								setInitialWithinData();
							}

							newInterval.current = false;
							if (endDate.isValid() && !endDate.isSame(lastEndDate.current)) {
								onChangeInput(undefined, getFormattedMomentString(endDate));
								lastEndDate.current = endDate;
							}
						}
						counter.current += 1;

						content = (
							<React.Fragment>
								<DatePicker
									aria-label={_('StartDate')}
									value={startDate.isValid() ? startDate : null}
									onChange={value => {
										onChangeInput(getFormattedMomentString(value));
									}}
									format={datePickerFormat}
									showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
								/>

								<label style={style}>{_('AND')}</label>

								<DatePicker
									aria-label={_('EndDate')}
									value={endDate.isValid() ? endDate : null}
									onChange={value => onChangeInput(undefined, getFormattedMomentString(value))}
									format={datePickerFormat}
									showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
									disabled={criteria === CriteriaFunctionValue.within}
								/>
							</React.Fragment>
						);
					}
				}
				break;
			case CriteriaFunctionValue.equalTo:
				{
					if (fieldType === FieldDataType.DateTime) {
						const startDate = moment(defaultPrimaryValue);
						content = (
							<DatePicker
								aria-label={_('StartDate')}
								key={CriteriaFunctionValue[criteria]}
								defaultValue={startDate.isValid() ? startDate : null}
								onChange={value => onChangeInput(getFormattedMomentString(value))}
								format={datePickerFormat}
								showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
							/>
						);
					} else if (fieldType === FieldDataType.Boolean) {
						content = (
							<Select
								ariaAttributes={{
									'aria-label': _('SelectEqualTo'),
									'aria-owns': 'SelectEqualTo',
									'aria-activedescendant': 'SelectEqualTo',
									'aria-controls': 'SelectEqualTo',
									'aria-expanded': 'false',
								}}
								id='SelectEqualTo'
								defaultValue={defaultPrimaryValue}
								key={SearchEqualToKey[SearchEqualToKey.searchByPagination]}
								onScroll={onScroll}
								placeholder={_('Select')}
								value={value}
								onChange={newValue => setValue(newValue)}
								options={options}
								onSelect={value => onChangeInput(value.toString())}
								onFocus={() => onSelectCriteria(2)}
								loading={searchEqualToKey === SearchEqualToKey.searchByPagination}
							/>
						);
					} else {
						content = (
							<React.Fragment>
								<Radio onClick={() => handleOnSelectCriteria(1)} checked={selectCriteria === 1}></Radio>
								<Select
									ariaAttributes={{
										'aria-label': _('TypeEqualTo'),
										'aria-owns': 'TypeEqualToRadioButton',
										'aria-activedescendant': 'TypeEqualToRadioButton',
										'aria-controls': 'TypeEqualToRadioButton',
										'aria-expanded': 'false',
									}}
									id='TypeEqualToRadioButton'
									defaultValue={selectCriteria === 1 ? defaultPrimaryValue : null}
									key={SearchEqualToKey[SearchEqualToKey.searchByText]}
									showSearch
									disabled={selectCriteria !== 1}
									defaultActiveFirstOption={false}
									placeholder={_('TypeToSearch')}
									value={valueSearchText}
									onChange={newValue => setValueSearchText(newValue)}
									onSearchText={onSearchSelect}
									onFocus={() => onSearchSelect(defaultPrimaryValue)}
									options={optionsBySearchText}
									notFoundContent={<div>{_('NotValuesFound')}</div>}
									onSelect={value => onChangeInput(value.toString())}
									removePercentParse
									loading={searchEqualToKey === SearchEqualToKey.searchByText}
								/>
								<Radio onClick={() => handleOnSelectCriteria(2)} checked={selectCriteria === 2}></Radio>
								<Select
									ariaAttributes={{
										'aria-label': _('SelectEqualTo'),
										'aria-owns': 'SelectEqualToRadioButton',
										'aria-activedescendant': 'SelectEqualToRadioButton',
										'aria-controls': 'SelectEqualToRadioButton',
										'aria-expanded': 'false',
									}}
									id='SelectEqualToRadioButton'
									defaultValue={selectCriteria === 2 ? defaultPrimaryValue : null}
									key={SearchEqualToKey[SearchEqualToKey.searchByPagination]}
									onScroll={onScroll}
									disabled={selectCriteria !== 2}
									placeholder={_('Select')}
									value={value}
									onChange={newValue => setValue(newValue)}
									options={options}
									onSelect={value => onChangeInput(value.toString())}
									loading={searchEqualToKey === SearchEqualToKey.searchByPagination}
									showSearch={true}
									filterOption={filterOption}
									maxSearchLength={100}
								/>
							</React.Fragment>
						);
					}
				}
				break;
			case CriteriaFunctionValue.greaterThan:
			case CriteriaFunctionValue.lessThan:
				if (fieldType === FieldDataType.Numeric) {
					content = (
						<InputNumber<string>
							stringMode
							aria-label={_('InputNumber')}
							defaultValue={defaultPrimaryValue === null ? null : defaultPrimaryValue}
							value={defaultPrimaryValue === null ? null : defaultPrimaryValue}
							formatter={numericPositiveAndNegativeNonDecimalFormatter}
							parser={numericPositiveAndNegativeNonDecimalFormatter}
							min={bigIntMinValue.toString()}
							max={bigIntMaxValue.toString()}
							width='100%'
							onChange={value => {
								if (value === undefined || value === null || value === '') {
									return;
								}

								const currentValue = BigInt(value);
								if (currentValue < bigIntMinValue || currentValue > bigIntMaxValue) {
									return;
								}

								onChangeInput(value);
							}}
						/>
					);
				} else {
					const startDate = moment(defaultPrimaryValue);
					content = (
						<DatePicker
							aria-label={_('StartDate')}
							key={CriteriaFunctionValue[criteria]}
							defaultValue={startDate.isValid() ? startDate : null}
							onChange={value => onChangeInput(getFormattedMomentString(value))}
							format={datePickerFormat}
							showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
						/>
					);
				}
				break;
			default:
				content = <Input aria-label={_('InputText')} onChange={e => onChangeInput(e.target.value)} value={defaultPrimaryValue} />;
		}
		return content;
	};

	return <>{renderContent()}</>;
};

export { ReportCriteriaType };

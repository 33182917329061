import { Checkbox, InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../Helper';
import { ConfigurationState, SettingsValue, SettingType } from '../../../model/ConfigurationModel';
import styles from './enrollmentSettings.module.scss';

// Normal properties for the component
interface Props {
	configuration: ConfigurationState;
	onSetSettingValue: (value: SettingsValue<string>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
	castNumber: (value: number) => boolean;
}

//Avoid creating object style inline, since increases reconciliations
const minValue: number = 5;

const EnrollmentSettings: React.FC<Props> = ({ configuration, onSetSettingValue, onSetSettingsHaveChanged, castNumber }) => {
	const handleSettingChange = (value: string, key: string, type: SettingType, skipNumberCheck: boolean = false) => {
		if (!skipNumberCheck && !Number(value)) {
			return;
		}
		const setValues: SettingsValue<string> = {
			key,
			value,
			type,
		};
		onSetSettingValue(setValues);
		if (!configuration.HaveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	const displayPhotoInEnrollment = configuration.DisplayPhotoInEnrollment;
	const count = Number(configuration.EnrollmentQuerySize.Value);
	const label = <label>{configuration.EnrollmentQuerySize.Label}</label>;

	return (
		<div className={styles.container} id='ConfigTabData_1'>
			<div>
				<label htmlFor='EnrollmentQuerySize'>*{_('QuerySize')}</label>
			</div>
			<div>
				<InputNumber
					id='EnrollmentQuerySize'
					min={minValue}
					max={50}
					maxLength={2}
					value={count}
					formatter={noneDecimalFormatter}
					onChange={value => {
						if (!value || value === undefined || (value as number) < minValue) {
							value = minValue;
						}
						handleSettingChange(value.toString(), 'Value', 'EnrollmentQuerySize');
					}}
				/>
				{label}
			</div>
			<div>
				<label htmlFor='EnrollmentDisplayPhoto'>*{displayPhotoInEnrollment.Label}</label>
			</div>
			<div>
				<Checkbox
					id='EnrollmentDisplayPhoto'
					onChange={e => {
						handleSettingChange(e.target.checked ? '1' : '0', 'Value', 'DisplayPhotoInEnrollment', true);
					}}
					checked={castNumber(Number(displayPhotoInEnrollment.Value ?? 0))}
					aria-label={`*${displayPhotoInEnrollment.Label}`}
				/>
			</div>
		</div>
	);
};

export { EnrollmentSettings };

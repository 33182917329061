import React from 'react';
import { OnPassbackViolation } from './OnPassbackViolation/OnPassbackViolation';
import { OptionsViolationsZones } from './OptionsViolationsZones/OptionsViolationsZones';
import styles from './passback.module.scss';
import { PassbackOptions } from './PassbackOptions/PassbackOptions';
import { TwoPersonRule } from './TwoPersonRule/TwoPersonRule';

const PassBack: React.FC = () => {
	return (
		<div className={styles.container} id='testPassback'>
			<div>
				<div>
					<OnPassbackViolation />
				</div>
				<div>
					<PassbackOptions />
				</div>
				<div>
					<TwoPersonRule />
				</div>
			</div>
			<div>
				<OptionsViolationsZones />
			</div>
		</div>
	);
};

export { PassBack };

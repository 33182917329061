import { Table } from 'antd';
import { ColumnsType, GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { buildColumn, ColumnsProps, ScrollType } from '../../../../../Helper';
import { VelocityDevice } from '../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../common';
import styles from '../doorsContainer.module.scss';

type Props = {
	onChangeSelection: (selectedRecords: VelocityDevice[]) => void;
	controllers: VelocityDevice[];
};

const scroll: ScrollType = { y: 131 };
const maxLength = 32;

const mergedColumns = (columns: ColumnsProps<VelocityDevice>[]) =>
	columns.map(col => ({
		...col,
		onCell: (record: VelocityDevice) => ({
			record,
		}),
	}));

const Controllers: React.FC<Props> = ({ onChangeSelection, controllers }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const columns: ColumnsProps<VelocityDevice>[] = [
		{
			...buildColumn(_('ControllerName'), 'Name', undefined, 'start'),
			sorter: (a, b) => a.Name.localeCompare(b.Name),
			...SearchColumn({ maxLength, dataIndex: undefined, reset: false, label: _('ControllerName') }),
		},
	];

	const rowSelection: TableRowSelection<VelocityDevice> = {
		type: 'checkbox',
		selectedRowKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRowKeys(selectedRowKeys);
			onChangeSelection(selectedRows);
		},
	};

	const handleOnSelectRow = (record: VelocityDevice) => {
		const findKey = selectedRowKeys.indexOf(record.Id);
		let findControllers = [];
		if (~findKey) {
			const cloneState = selectedRowKeys;
			cloneState.splice(findKey, 1);
			setSelectedRowKeys([...cloneState]);
			findControllers = controllers.filter(x => cloneState.indexOf(x.Id) >= 0);
		} else {
			const newKeys = [...selectedRowKeys, record.Id];
			setSelectedRowKeys(newKeys);
			findControllers = controllers.filter(x => newKeys.indexOf(x.Id) >= 0);
		}
		onChangeSelection(findControllers);
	};

	const rowKey: GetRowKey<VelocityDevice> = record => record.Id;

	return (
		<div className={styles.containerTable}>
			<Table
				id='photoCallUpConfigurationControllersTable'
				onRow={(record: VelocityDevice) => ({
					onClick: () => handleOnSelectRow(record),
				})}
				pagination={false}
				scroll={scroll}
				className={styles.statusWidgetTable}
				rowSelection={rowSelection}
				columns={mergedColumns(columns) as ColumnsType}
				rowKey={rowKey}
				size='small'
				dataSource={controllers}
				onHeaderRow={() => ({ id: 'photoCallUpConfigurationControllersTableHeader' })}
				getPopupContainer={() => document.getElementById('photoCallUpConfigurationControllersTableHeader')}
			/>
		</div>
	);
};

export { Controllers };

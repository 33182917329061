import { Divider, Form, FormInstance, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { cultureDateOnlyFormat } from '../../../Helper';
import { SubPermissions, User } from '../../../model/AccountModel';
import { PersonDetailModel, PersonFormModel } from '../../../model/EnrollmentModel';
import { UserDefinedFieldDetailModel } from '../../../model/UserDefinedFields';
import { useStoreSelector } from '../../../store';
import { selectEnrollmentResourceStringsSuffixSelectOptions, selectEnrollmentResourceStringsTitleSelectOptions } from '../../../store/enrollment/selectors';
import { PersonImages, Select, SelectOption, UDFField } from '../../common';
// import { nameSuffixOptions, nameTitleOptions } from '../../enrollment/helper';
import styles from './personInformation.module.scss';

const user: User = getUser();
const cultureDateFormat: string = user.cultureDateFormat;
const canEditGeneralUDFTab: boolean = User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditGeneralTabUDFs).allowed;
const personFormObject: PersonFormModel = new PersonFormModel();
const { Title, FirstName, LastName, MiddleName, Suffix } = personFormObject.FormKeysObject;

type Props = {
	form?: FormInstance<PersonDetailModel>;
	personInformation: PersonDetailModel;
	udfData: UserDefinedFieldDetailModel[];
	showInfoOnly: boolean;
	tabId: number;
	errorMessage?: string;
	addMode: boolean;
};

const PersonInformation: React.FC<Props> = ({ form, personInformation, udfData, showInfoOnly, tabId, errorMessage, addMode }) => {
	const [dateFormat, setDateFormat] = useState<string>('YYYY-MM-DD');
	const nameSuffixOptions: SelectOption = useStoreSelector<SelectOption>(selectEnrollmentResourceStringsSuffixSelectOptions);
	const nameTitleOptions: SelectOption = useStoreSelector<SelectOption>(selectEnrollmentResourceStringsTitleSelectOptions);

	useEffect(() => {
		if (cultureDateFormat && cultureDateFormat !== '') {
			const dateFormat: string = cultureDateOnlyFormat(cultureDateFormat);
			setDateFormat(dateFormat);
		}
	}, []);

	const personId: string | number =
		personInformation.PersonId === 0 || personInformation.IsTemplate || personInformation.PersonId === -100
			? `<${_('NewPerson')}>`
			: personInformation.PersonId;
	const lastUpdated: string = personInformation.IsTemplate ? '' : FormatDateStd(personInformation.LastUpdated);

	return (
		<div className={styles.container}>
			<PersonImages
				form={form}
				profilePictureBase64={personInformation.ProfilePictureBase64}
				profileSignatureBase64={personInformation.ProfileSignatureBase64}
				showInfoOnly={showInfoOnly}
				tabId={tabId}
			/>
			<div>
				<div className={styles.idContainer}>
					<span>
						{_('ID')}: {personId}
					</span>
					{errorMessage && (
						<span>
							<strong>{errorMessage}</strong>
						</span>
					)}
					<span>
						{_('RecordLastUpdated')}: {lastUpdated}
					</span>
				</div>
				<Divider className={styles.idDivider} />
				<div className={styles.personNamesContainer}>
					<div className={styles.firstName}>
						<label>{_('NameFirstMiddleLast')}</label>
						<Form.Item name={Title} initialValue={showInfoOnly ? personInformation.Title : undefined}>
							<Select
								id='enrollmentTitleDropdown'
								options={nameTitleOptions}
								disabled={showInfoOnly}
								ariaAttributes={{ 'aria-label': _('PersonTitle') }}
							/>
						</Form.Item>
						<Form.Item name={FirstName} initialValue={showInfoOnly ? personInformation.FirstName : undefined}>
							<Input disabled={showInfoOnly} id='enrollmentFirstNameInput' maxLength={32} aria-label={_('FirstName')} />
						</Form.Item>
					</div>
					<div className={styles.middleName}>
						<Form.Item name={MiddleName} initialValue={showInfoOnly ? personInformation.MiddleName : undefined}>
							<Input disabled={showInfoOnly} id='enrollmentMiddleNameInput' maxLength={32} aria-label={_('MiddleName')} />
						</Form.Item>
					</div>
					<div className={styles.lastName}>
						<Form.Item name={LastName} initialValue={showInfoOnly ? personInformation.LastName : undefined}>
							<Input disabled={showInfoOnly} id='enrollmentLastNameInput' maxLength={80} aria-label={_('LastName')} />
						</Form.Item>
						<Form.Item name={Suffix} initialValue={showInfoOnly ? personInformation.Suffix : undefined}>
							<Select
								id='enrollmentSuffixDropdown'
								options={nameSuffixOptions}
								disabled={showInfoOnly}
								ariaAttributes={{ 'aria-label': _('PersonSuffix') }}
							/>
						</Form.Item>
					</div>
				</div>
				<Divider className={styles.namesDivider} />
				<div className={styles.udfFieldsContainer}>
					{udfData.map(udfData => (
						<UDFField
							key={udfData.FieldNumber}
							udfData={udfData}
							disabled={!canEditGeneralUDFTab}
							useInitialValue={showInfoOnly}
							isPersonInformation={true}
							dateFormat={dateFormat}
							usePlaceHolder={addMode}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export { PersonInformation };

import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { CSSProperties } from 'react';
import { Modal } from '../../common';
import styles from './modalConfirmCustomFooter.module.scss';

type Props<T> = {
	title?: string | React.ReactNode;
	width?: string;
	visible: boolean;
	children?: React.ReactNode;
	navigateTo?: string;
	loadingOk?: boolean;
	onOk: (value?: T) => void;
	onNo?: (value?: T) => void;
	onCancel: (value?: T) => void;
	okText?: string;
	noText?: string;
	cancelText?: string;
	style?: CSSProperties;
	footer?: React.ReactNode[];
	className?: string;
};

const ModalConfirmCustomFooter: React.FC<Props<object | string | number | boolean>> = ({
	title,
	width,
	visible,
	children,
	navigateTo,
	loadingOk,
	onOk,
	onNo,
	onCancel,
	okText,
	noText,
	cancelText,
	style,
	footer,
	className,
}) => {
	const titleElement: React.ReactNode = (
		<div className={styles.header}>
			<WarningOutlined className={styles.icon} />
			<span>{title}</span>
		</div>
	);

	const defaultFooter: React.ReactNode = (
		<div className={styles.footer}>
			<div className={styles.buttons}>
				<Button key='save' onClick={onOk} type='primary' loading={loadingOk}>
					{okText || _('Yes')}
				</Button>
				{onNo ? (
					<Button key='no' onClick={e => onNo()} type='default'>
						{noText || _('No')}
					</Button>
				) : (
					<Button key='redirect' href={navigateTo} type='default'>
						{noText || _('No')}
					</Button>
				)}
				<Button key='cancel' onClick={onCancel} type='default'>
					{cancelText || _('Cancel')}
				</Button>
			</div>
		</div>
	);

	const defaultChildren: React.ReactNode = (
		<>
			<span className={styles.content}>{_('SettingsHaveChanged')}</span>
		</>
	);

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			closable={false}
			title={title ? titleElement : undefined}
			width={width}
			visible={visible}
			onClickOk={onOk}
			onCancel={onCancel}
			style={style}
			className={className || styles.confirmationModalZoom}
			footer={footer || [defaultFooter]}>
			{children || defaultChildren}
		</Modal>
	);
};

export { ModalConfirmCustomFooter };

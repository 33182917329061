import { Button, Checkbox, Input, InputNumber, Select, Spin, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { enrollmentApi } from '../../../../../api';
import { buildColumn, ColumnsProps } from '../../../../../Helper';
import { SubPermissions, User } from '../../../../../model/AccountModel';
import { ReaderControlGroupItem } from '../../../../../model/DeviceAdminModel';
import '../../../../../resources/style/main.scss';
import styles from '../../credentialmodal.module.scss';
import { setInformationPropertyAction, setOptionsTabPropertyAction, setRootModelPropertyAction } from '../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../credentialTemplateContext/context';
import { CredentialReissueTable, OptionsTabProperty, OptionsTabProps } from '../../CredentialTemplateModalModels';
import ReissueForm from './ReissueForm/ReissueForm';

export const CredentialTemplateModalOptionsTab: React.FC<OptionsTabProps> = ({ user }) => {
	const {
		credentialInitialState: {
			configData: { IsDeviceAdminCredentialTemplate },
			fetchedCredential,
			generalTabState: {
				cardSectionState: { saveConcatenatedData },
				credentialTemplateId,
				IDF,
				informationSectionState: { credentialTemplates, isLinkToConfirmed, linkToCredential },
			},
			linkedInfoOnly,
			modelEstablished,
			optionsTabState: {
				alertAction,
				credentialReissueInfo,
				currentIssueStatus,
				currentIssueStatusDropdown,
				disableAction,
				printControlConfirmation,
				enableIssueControl,
				enablePrintControl,
				executiveOverride,
				isCommandSetsTableDisabled,
				issueControlCount,
				issueControlMax,
				printControlAttempts,
				printControlCount,
				printControlMax,
				readerControlGroup,
				readerControlGroups,
				scrambleNormal,
				specialNeeds,
				tagAction,
			},
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	const enableIssueControlPermissionState = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_IssueControl).allowed;
	const enablePrintControlPermissionState = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_Credentials_PrintControl).allowed;

	const [currentIssueStatusD, setCurrentIssueStatusD] = useState<boolean>(false);
	const [currentIssueStatusDropdownD, setCurrentIssueStatusDropdownD] = useState<boolean>(false);
	const [disabledProp, setDisabledProp] = useState<boolean>(false);
	const [displayPrintConfirmationD, setDisplayPrintConfirmationD] = useState<boolean>(false);
	const [enableIssueControlD, setEnableIssueControlD] = useState<boolean>(false);
	const [enableReIssueButton, setEnableReIssueButton] = useState<boolean>(false);
	const [enablePrintControlD, setEnablePrintControlD] = useState<boolean>(enablePrintControlPermissionState);
	const [executiveOverrideD, setExecutiveOverrideD] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [issue, setIssue] = useState<CredentialReissueTable>(undefined);
	const [issueControlCountD, setIssueControlCountD] = useState<boolean>(false);
	const [issueControlMaxD, setIssueControlMaxD] = useState<boolean>(false);
	const [scrambleNormalD, setScrambleNormalD] = useState<boolean>(false);
	const [printControlAttemptsD, setPrintControlAttemptsD] = useState<boolean>(false);
	const [printControlCountD, setPrintControlCountD] = useState<boolean>(false);
	const [printControlMaxD, setPrintControlMaxD] = useState<boolean>(false);
	const [readerControlGroupD, setReaderControlGroupD] = useState<boolean>(false);
	const [reissueBtnD, setReissueBtnD] = useState<boolean>(false);
	const [reissueBtnH, setReissueBtnH] = useState<boolean>(false);
	const [showReissueForm, setShowReissueForm] = useState<boolean>(false);
	const [specialNeedsD, setSpecialNeedsD] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		enrollmentApi
			.getCredentialTemplateReaderControlGroups()
			.then(readerControlGroups => {
				dispatcher(setOptionsTabPropertyAction({ readerControlGroups: readerControlGroups || [] }));
				setIsLoading(false);
			})
			.catch(() => {
				dispatcher(setOptionsTabPropertyAction({ readerControlGroups: [] }));
			});
	}, []);

	useEffect(() => {
		idfChanged();
	}, [IDF]);

	useEffect(() => {
		issueControlChanged();
	}, [enableIssueControl]);

	useEffect(() => {
		printControlChanged();
	}, [enablePrintControl]);

	useEffect(() => {
		if (isLinkToConfirmed) {
			linkToControlToggle();
			linkToChkChanged();
		}
	}, [isLinkToConfirmed]);

	useEffect(() => {
		if (!linkedInfoOnly) {
			printControlChanged();
			issueControlChanged();
			idfChanged();
		}
	}, [fetchedCredential, modelEstablished]);

	useEffect(() => {
		if (credentialReissueInfo?.length >= issueControlMax) {
			setReissueBtnD(true);
		} else {
			setReissueBtnD(false);
		}
	}, [JSON.stringify(credentialReissueInfo), issueControlMax]);

	const idfChanged = () => {
		if (IsDeviceAdminCredentialTemplate) {
			disableFieldsForTemplate();
		} else if (linkToCredential) {
			disableFieldsForLinkTo();
		} else {
			enableAllCredentialIDFFields();
			credentialIDFChangeFields();
		}
	};

	const linkToControlToggle = () => {
		setEnableIssueControlD(linkToCredential);
		setEnablePrintControlD(linkToCredential);
		setIssueControlMaxD(linkToCredential);

		if ((!credentialTemplates || credentialTemplates.length < 1) && linkToCredential) {
			dispatcher(setInformationPropertyAction({ linkToCredential: false }));
			setEnableIssueControlD(false);
			setEnablePrintControlD(false);
			setIssueControlMaxD(false);
		} else {
			batch(() => {
				dispatcher(setOptionsTabPropertyAction({ enableIssueControl: false }));
				dispatcher(setOptionsTabPropertyAction({ enablePrintControl: false }));
			});
		}
	};

	const linkToChkChanged = () => {
		if (credentialTemplates?.length > 0) {
			if (linkToCredential) {
				toggleDisableAll(true);
			} else {
				toggleDisableAll(false);
				printControlChanged();
				setReaderControlGroupD(false);
			}
		}
		idfChanged();
	};

	const toggleDisableAll = (flag: boolean) => {
		// Others
		setDisabledProp(flag);
		setScrambleNormalD(flag);
		setExecutiveOverrideD(flag);
		setReaderControlGroupD(flag);
		setSpecialNeedsD(flag);
		// IssueControl
		toggleDisableIssueControlFields(flag);
		// PrintControl
		toggleDisablePrintControlFields(flag);
	};

	const toggleDisableIssueControlFields = (flag: boolean) => {
		setEnableIssueControlD(flag);
		setIssueControlCountD(flag);
		setIssueControlMaxD(flag);
		setReissueBtnD(flag);
	};

	const toggleDisablePrintControlFields = (flag: boolean) => {
		setDisplayPrintConfirmationD(flag);
		setEnablePrintControlD(flag);
		setPrintControlAttemptsD(flag);
		setPrintControlCountD(flag);
		setPrintControlMaxD(flag);
	};

	const disableFieldsForTemplate = () => {
		let scramble = true;
		enableAllCredentialIDFFields();
		setReissueBtnH(false);
		if (IDF === 0) {
			disableBadgeOnlyFields();
		} else if ([3, 4, 5, 6].includes(IDF)) scramble = false;

		setScrambleNormalD(scramble);
		issueControlChanged();
		printControlChanged();
	};

	const enableAllCredentialIDFFields = () => {
		toggleDisableAll(false);
		const isNewCredential: boolean = credentialTemplateId > 0;
		setEnableReIssueButton(enableIssueControlPermissionState && !isNewCredential);
		setEnableIssueControlD(!enableIssueControlPermissionState);
		setEnablePrintControlD(!enablePrintControlPermissionState);
		setIssueControlCountD(true);
	};

	const disableBadgeOnlyFields = () => {
		toggleDisableAll(true);
		// Enable IssueControl Fields
		toggleDisableIssueControlFields(false);
		// Enable PrintControl Fields
		toggleDisablePrintControlFields(false);
		// TODO: Logic to disable table
		setIssueControlCountD(true);
		setCurrentIssueStatusD(false);
		setCurrentIssueStatusDropdownD(false);
		setEnableIssueControlD(false);
		setEnablePrintControlD(false);
	};

	const issueControlChanged = () => {
		if (enableIssueControl) {
			setIssueControlMaxD(false);
			if (credentialTemplateId > 0) {
				if (enableIssueControlPermissionState) {
					if (issueControlMax === 0 || (issueControlCount < issueControlMax && !IsDeviceAdminCredentialTemplate)) {
						setReissueBtnH(false);
					} else {
						setReissueBtnH(true);
					}
					setReissueBtnD(false);
					setIssueControlMaxD(false);
				} else {
					setReissueBtnD(true);
					setReissueBtnH(true);
					setIssueControlMaxD(true);
				}
			} else setReissueBtnD(true);
		} else {
			setIssueControlMaxD(true);
			setReissueBtnH(true);
			setReissueBtnD(true);
			handleOnCancelReissue();
		}
	};

	const printControlChanged = () => {
		setPrintControlAttemptsD(true);
		setPrintControlCountD(true);
		if (enablePrintControl) {
			setPrintControlMaxD(false);
			setDisplayPrintConfirmationD(false);
		} else {
			setPrintControlMaxD(true);
			setDisplayPrintConfirmationD(true);
		}
	};

	const credentialIDFChangeFields = () => {
		let scramble = true;
		if (IDF === 0) {
			disableBadgeOnlyFields();
		}
		if ([3, 4, 5, 7].includes(IDF)) scramble = false;
		setScrambleNormalD(scramble);
		issueControlChanged();
		printControlChanged();
	};

	const disableFieldsForLinkTo = () => {
		// disableBadgeOnlyFields();
		toggleDisableAll(false);
		credentialIDFChangeFields();

		toggleDisableIssueControlFields(false);
		issueControlChanged();

		setIssueControlCountD(true);
		setEnableIssueControlD(true);
		setCurrentIssueStatusDropdownD(false);
		setReissueBtnH(true);

		setEnablePrintControlD(true);

		setCurrentIssueStatusD(false);
		setReaderControlGroupD(true);
	};

	// Handle contextUpdate
	const handleChangeOptionsProperty = (value: OptionsTabProperty) => {
		batch(() => {
			dispatcher(setRootModelPropertyAction({ hasChanged: true }));
			dispatcher(setOptionsTabPropertyAction(value));
		});
	};

	const handleClickReissueButton = () => {
		setShowReissueForm(true);
	};

	const reissueCredential = (issue: CredentialReissueTable, isEdit: boolean) => {
		const clonedCredentialReissueInfo = [...credentialReissueInfo];
		let editedIssueIndex: number = -1;
		if (isEdit) {
			editedIssueIndex = clonedCredentialReissueInfo.findIndex(cri => cri.IssueNumber === issue.IssueNumber);
			clonedCredentialReissueInfo.splice(editedIssueIndex, 1, issue);
		} else {
			clonedCredentialReissueInfo.push(issue);
		}
		dispatcher(setOptionsTabPropertyAction({ credentialReissueInfo: clonedCredentialReissueInfo, issueControlCount: clonedCredentialReissueInfo.length }));
		setIssue(undefined);
		setShowReissueForm(false);
	};

	const columns: ColumnsProps<CredentialReissueTable>[] = [
		{
			...buildColumn('#', 'IssueNumber', '70px', 'start'),
		},
		{
			...buildColumn(_('Reason'), 'Reason', 'auto', 'start'),
		},
		{
			...buildColumn(_('Date'), 'formattedDate', 'auto', 'start'),
		},
	];

	const handleEditIssue = (issue: CredentialReissueTable) => {
		setIssue(issue);
		setShowReissueForm(true);
	};

	const handleOnCancelReissue = () => {
		setIssue(undefined);
		setShowReissueForm(false);
	};

	const isNewCredential: boolean = credentialTemplateId > 0;

	return (
		<Spin spinning={isLoading} tip={_('Loading')}>
			{readerControlGroups?.length > 0 && (
				<div className={styles.container}>
					<div className={styles.twoColumns}>
						<div>
							<div className={cx(styles.containerLegend, 'fieldsetBorder')}>
								<fieldset className={styles.form} style={{ padding: 0 }}>
									<legend className={styles.legend}>{_('Actions')}</legend>
									<div
										style={{
											display: 'grid',
											gridTemplateColumns: '33% 33% 33%',
											marginTop: 5,
											marginRight: 20,
											marginBottom: 0,
											marginLeft: 20,
										}}>
										<Checkbox
											disabled={disabledProp}
											checked={tagAction}
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ tagAction: e.target.checked });
											}}>
											{_('Tag')}
										</Checkbox>
										<Checkbox
											disabled={disabledProp}
											checked={alertAction}
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ alertAction: e.target.checked });
											}}>
											{_('Alert')}
										</Checkbox>
										<Checkbox
											disabled={disabledProp}
											checked={disableAction}
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ disableAction: e.target.checked });
											}}>
											{_('Disable')}
										</Checkbox>
									</div>
									<div
										className={cx(styles.sections, styles.equalSections)}
										style={{ marginTop: 15, marginRight: 20, marginBottom: 0, marginLeft: 20 }}>
										<label htmlFor='readerControlGroups'>{_('ReaderControlGroups')}:</label>
										<Select
											className={styles.fullWidthSelect}
											disabled={readerControlGroupD}
											id='readerControlGroups'
											getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
											options={readerControlGroups.map((rdc: ReaderControlGroupItem) => ({
												key: rdc.ReaderControlGroupId,
												label: rdc.Name,
												value: rdc.ReaderControlGroupId,
											}))}
											onChange={value => handleChangeOptionsProperty({ readerControlGroup: value })}
											value={readerControlGroup}
										/>
									</div>
								</fieldset>
							</div>
							<div className={cx(styles.containerLegend, 'fieldsetBorder')} style={{ marginTop: 20 }}>
								<fieldset>
									<legend className={styles.legend}>{_('Passback')}</legend>
									<div style={{ display: 'grid', marginTop: 5, marginRight: 15, marginBottom: 0, marginLeft: 20 }}>
										<Checkbox
											checked={executiveOverride}
											disabled={executiveOverrideD}
											id='ExecutiveOverride'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ executiveOverride: e.target.checked });
											}}>
											{_('ExecutiveOverride')}
										</Checkbox>
									</div>
								</fieldset>
							</div>
							<div className={cx(styles.containerLegend, 'fieldsetBorder')} style={{ marginTop: 20 }}>
								<fieldset>
									<legend className={styles.legend}>{_('SpecialNeeds')}</legend>
									<div style={{ display: 'grid', marginTop: 5, marginRight: 15, marginBottom: 0, marginLeft: 20 }}>
										<Checkbox
											checked={specialNeeds}
											disabled={specialNeedsD}
											id='SpecialNeedsTimeAccessExtension'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ specialNeeds: e.target.checked });
											}}>
											{_('SpecialNeedsTimeAccessExtension')}
										</Checkbox>
									</div>
									<div style={{ display: 'grid', marginTop: 5, marginRight: 15, marginBottom: 0, marginLeft: 20 }}>
										<Checkbox
											checked={scrambleNormal}
											disabled={scrambleNormalD}
											id='OverrideScramble'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ scrambleNormal: e.target.checked });
											}}>
											{_('OverrideScramble')}
										</Checkbox>
									</div>
								</fieldset>
							</div>
							<div className={cx(styles.form, styles.currentIssueStatus)}>
								<div style={{ marginBottom: 3 }}>
									<label htmlFor='currentIssueStatusDropdown'>{_('CurrentIssueStatus')}:</label>
								</div>
								<div className={styles.currentIssueStatusC}>
									<Input
										className={styles.issueInput}
										disabled={currentIssueStatusD}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											handleChangeOptionsProperty({ currentIssueStatus: e.target.value });
										}}
										value={currentIssueStatus}
									/>
									<Select
										className={cx(styles.select, styles.issueSelect)}
										defaultValue=''
										disabled={currentIssueStatusDropdownD}
										id='currentIssueStatusDropdown'
										getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
										onChange={value => handleChangeOptionsProperty({ currentIssueStatus: value })}
										value={currentIssueStatusDropdown}>
										<Select.Option value=''>{_('')}</Select.Option>
										<Select.Option value='Lost'>{_('Lost')}</Select.Option>
										<Select.Option value='Stolen'>{_('Stolen')}</Select.Option>
										<Select.Option value='Destroyed'>{_('Destroyed')}</Select.Option>
									</Select>
								</div>
							</div>
						</div>
						<div>
							<div className={cx(styles.containerLegend, 'fieldsetBorder')}>
								<fieldset className={styles.form} style={{ padding: 0 }}>
									<legend className={styles.legend}>{_('PrintControl')}</legend>
									<div style={{ marginTop: 0, marginRight: 10, marginBottom: 0, marginLeft: 10 }}>
										<Checkbox
											checked={enablePrintControl}
											disabled={enablePrintControlD}
											id='EnablePrintControl'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ enablePrintControl: e.target.checked });
											}}>
											{_('EnablePrintControl')}
										</Checkbox>
									</div>
									<div className={cx(styles.sections, styles.equalSections)} style={{ margin: '5px auto 0px auto', width: 386 }}>
										<div>
											<div className={cx(styles.sections, styles.equalSections)}>
												<label htmlFor='printControlAttempts'>{_('Attempts')}:</label>
												<InputNumber
													className={styles.inputNumber}
													disabled={printControlAttemptsD}
													id='printControlAttempts'
													maxLength={4}
													max={9999}
													min={0}
													name='printControlAttempts'
													onChange={value => {
														handleChangeOptionsProperty({ printControlAttempts: value });
													}}
													size='small'
													style={{ width: 80 }}
													value={printControlAttempts}
												/>
											</div>
											<div className={cx(styles.sections, styles.equalSections)}>
												<label htmlFor='printControlMax'>{_('Max')}:</label>
												<InputNumber
													className={styles.inputNumber}
													defaultValue={0}
													disabled={printControlMaxD}
													id='printControlMax'
													maxLength={4}
													max={9999}
													min={0}
													name='printControlMax'
													onChange={value => {
														handleChangeOptionsProperty({ printControlMax: value });
													}}
													size='small'
													style={{ width: 80 }}
													value={printControlMax}
												/>
											</div>
										</div>
										<div>
											<div className={cx(styles.sections, styles.equalSections)}>
												<label htmlFor='printControlCount'>{_('Confirmed')}:</label>
												<InputNumber
													className={styles.inputNumber}
													disabled={printControlCountD}
													id='printControlCount'
													name='printControlCount'
													maxLength={4}
													max={9999}
													min={0}
													onChange={value => {
														handleChangeOptionsProperty({ printControlCount: value });
													}}
													size='small'
													style={{ width: 80 }}
													value={printControlCount}
												/>
											</div>
											<div className={styles.sections} style={{ gridTemplateColumns: 'auto', minHeight: 32 }}>
												<label>(1-9999) (0={_('Unlimited')})</label>
											</div>
										</div>
									</div>
									<div style={{ marginTop: 15, marginRight: 35, marginBottom: 0, marginLeft: 35, width: '100%' }}>
										<Checkbox
											checked={printControlConfirmation}
											disabled={displayPrintConfirmationD}
											id='DisplayPrintConfirmation'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ printControlConfirmation: e.target.checked });
											}}>
											{_('DisplayPrintConfirmation')}
										</Checkbox>
									</div>
								</fieldset>
							</div>
							<div className={cx(styles.containerLegend, 'fieldsetBorder')} style={{ marginTop: 20 }}>
								<fieldset className={styles.form} style={{ padding: 0 }}>
									<legend className={styles.legend}>{_('IssueControl')}</legend>
									<div style={{ marginTop: 0, marginRight: 10, marginBottom: 0, marginLeft: 10 }}>
										<Checkbox
											checked={enableIssueControl}
											disabled={enableIssueControlD}
											id='EnableIssueControl'
											onChange={(e: CheckboxChangeEvent) => {
												handleChangeOptionsProperty({ enableIssueControl: e.target.checked });
											}}>
											{_('EnableIssueControl')}
										</Checkbox>
									</div>
									<div className={styles.issueControlInputs}>
										<div>
											<div className={styles.sections} style={{ gridTemplateColumns: 'auto 80px' }}>
												<label htmlFor='issueControlCount'>{_('Count')}:</label>
												<InputNumber
													className={styles.inputNumber}
													disabled={issueControlCountD}
													id='issueControlCount'
													maxLength={4}
													max={9999}
													min={0}
													name='issueControlCount'
													onChange={value => {
														handleChangeOptionsProperty({ issueControlCount: value });
													}}
													size='small'
													style={{ width: 80 }}
													value={issueControlCount}
												/>
											</div>
										</div>
										<div>
											<div className={styles.sections} style={{ gridTemplateColumns: 'auto 80px auto', columnGap: 4 }}>
												<label htmlFor='issueControlMax'>{_('Max')}:</label>
												<InputNumber
													className={styles.inputNumber}
													disabled={issueControlMaxD}
													id='issueControlMax'
													maxLength={4}
													max={9999}
													min={0}
													name='issueControlMax'
													onChange={value => {
														handleChangeOptionsProperty({ issueControlMax: value });
													}}
													size='small'
													style={{ width: 80 }}
													value={issueControlMax}
												/>
												<label>{`(0-9999)`}</label>
											</div>
										</div>
										<div>
											<div className={styles.sections} style={{ gridTemplateColumns: 'auto' }}>
												{!(!enableReIssueButton || !enableIssueControl || IsDeviceAdminCredentialTemplate) && (
													<Button
														disabled={!enableIssueControl || reissueBtnD}
														htmlType='button'
														id='reissueBtn'
														onClick={handleClickReissueButton}>
														{_('Reissue')}...
													</Button>
												)}
											</div>
										</div>
									</div>
									{!IsDeviceAdminCredentialTemplate && (
										<fieldset
											disabled={isCommandSetsTableDisabled}
											style={{ marginTop: 20, padding: '0 20px' }}
											className={styles.fieldset}>
											{showReissueForm ? (
												<ReissueForm
													handleOnCancelReissue={handleOnCancelReissue}
													isNewCredential={isNewCredential}
													issue={issue}
													issueControlCount={issueControlCount}
													reissueCredential={reissueCredential}
												/>
											) : (
												<Table
													className={cx(styles.statusWidgetTable /*{ [styles.hidden]: showReissueForm }*/)}
													columns={columns}
													dataSource={credentialReissueInfo?.map((cri, idx: number) => ({
														...cri,
														key: idx,
													}))}
													id={'commandSetsTable'}
													loading={credentialReissueInfo === undefined}
													onRow={data => ({
														onClick: () => {
															if (enableIssueControl) {
																handleEditIssue(data);
															}
														},
													})}
													pagination={false}
													rowClassName={styles.antTableRow}
													scroll={{ y: '210px' }}
													size='small'
													style={{ height: 250, width: 410 }}
												/>
											)}
										</fieldset>
									)}
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			)}
		</Spin>
	);
};

export default CredentialTemplateModalOptionsTab;

import { CloseOutlined, DeleteOutlined, EditOutlined, MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { CriteriaInfo } from '../../../../../model/CommonModel';
import { Select } from '../../../../common';
import { buildCriteriaDescription } from '../criteriaOptions';
import styles from '../reportTabs.module.scss';

type Props = {
	onDeleteCriteria: (key: number) => void;
	onSetCriteriaToBeEdited: (key: number) => void;
	onCancelEdition: (key: number) => void;
	requestCriteria: CriteriaInfo[];
	onChangeCriteriaOperator: (value: boolean, key: number) => void;
	isEditing: boolean;
	currentEditionKey: number;
	roundedButtons: boolean;
};

const ReportCriteriaRequestInfo: React.FC<Props> = ({
	onCancelEdition,
	isEditing,
	onSetCriteriaToBeEdited,
	onDeleteCriteria,
	requestCriteria,
	onChangeCriteriaOperator,
	currentEditionKey,
	roundedButtons,
}) => {
	const lastItemOfRequest = requestCriteria[requestCriteria.length - 1];
	const showRemoveButton = (key: number) => currentEditionKey !== key;
	const showCancelButton = (key: number) => isEditing && currentEditionKey === key;

	const getActionButtons = (key: number, isEditingValue: boolean) => {
		return roundedButtons ? (
			<>
				<Button disabled={isEditing} onClick={() => onSetCriteriaToBeEdited(key)} type='primary' shape='circle' icon={<EditOutlined />}></Button>
				{showCancelButton(key) && (
					<Button onClick={() => onCancelEdition(key)} shape='circle' icon={<CloseOutlined style={{ color: '#406080' }} />}></Button>
				)}
				{showRemoveButton(key) && (
					<Button disabled={isEditingValue} onClick={() => onDeleteCriteria(key)} type='primary' shape='circle' icon={<DeleteOutlined />}></Button>
				)}
			</>
		) : (
			<>
				<Button id='CriteriaEditButton' disabled={isEditingValue} onClick={() => onSetCriteriaToBeEdited(key)} type='primary'>
					<EditOutlined />
					{_('Edit')}
				</Button>
				{showCancelButton(key) && <Button onClick={() => onCancelEdition(key)}>{_('Cancel')}</Button>}
				{showRemoveButton(key) && (
					<Button id='CriteriaRemoveButton' disabled={isEditingValue} onClick={() => onDeleteCriteria(key)} type='primary'>
						<MinusOutlined />
						{_('Remove')}
					</Button>
				)}
			</>
		);
	};

	return (
		requestCriteria.length > 0 && (
			<div className={styles.criteriaInfo}>
				<div></div>
				<div>
					<label>{_('Criteria')}</label>
				</div>
				<div>
					<label>
						{_('AND').toLocaleUpperCase()}/{_('OR').toLocaleUpperCase()}
					</label>
				</div>
				{requestCriteria.map(x => (
					<React.Fragment key={x.key}>
						<div className={styles.alignButtons}>{getActionButtons(x.key, isEditing)}</div>
						<div>{buildCriteriaDescription(x.fieldName, x.functionName, x.criteriaName, x.PrimaryInput, x.SecondaryInput)}</div>
						<div>
							{lastItemOfRequest.key !== x.key && (
								<Select
									ariaAttributes={{
										'aria-label': _('AndOr'),
										'aria-owns': 'SelectAndOr',
										'aria-activedescendant': 'SelectAndOr',
										'aria-controls': 'SelectAndOr',
										'aria-expanded': 'true',
									}}
									id='SelectAndOr'
									defaultValue={'false'}
									value={x.OrNextCriteria.toString()}
									disabled={isEditing}
									onChange={value => onChangeCriteriaOperator(value === 'true' ? true : false, x.key)}
									options={[
										{ label: _('AND').toLocaleUpperCase(), value: 'false' },
										{ label: _('OR').toLocaleUpperCase(), value: 'true' },
									]}
								/>
							)}
						</div>
					</React.Fragment>
				))}
			</div>
		)
	);
};

export { ReportCriteriaRequestInfo };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-tags-check-boxes-container--n9h8uNB7r84K1iYwuBSAt{display:flex;flex-direction:row}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/VelocityConfiguration/DoorGroup/DoorModal/sections/TagsCheckBoxes/TagsCheckBoxes.module.scss"],"names":[],"mappings":"AAAA,oDACC,YAAA,CACA,kBAAA","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "-tags-check-boxes-container--n9h8uNB7r84K1iYwuBSAt"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from '../../expansionRelayModal.module.scss';
import { Name, NormalState, Timers } from '../sections';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
	nameErrorText: string;
	useDoorContext: boolean;
	setErrorText: () => void;
};

const General: React.FC<Props> = props => {
	const { nameError, nameValidation, onResetNameError, nameErrorText, useDoorContext, setErrorText } = props;
	return (
		<div id='relayGeneralContainer' className={styles.form}>
			<Name
				nameValidation={nameValidation}
				nameError={nameError}
				onResetNameError={onResetNameError}
				nameErrorText={nameErrorText}
				useDoorContext={useDoorContext}
				setErrorText={setErrorText}
			/>
			<NormalState useDoorContext={useDoorContext}></NormalState>
			<Timers useDoorContext={useDoorContext}></Timers>
		</div>
	);
};

export { General };

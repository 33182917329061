import React, { useMemo, useReducer } from 'react';
import { SubPermissions, User } from '../../../model/AccountModel';
import { WithLockedValidation } from '../../common';
import { credentialInitialState, CredentialStoreContext, credentialTemplateContextReducer } from './credentialTemplateContext/context';
import { CredentialTemplateModalProps } from './CredentialTemplateModalModels';
import { ModalComponent } from './ModalComponent';

const CredentialTemplateModal: React.FC<CredentialTemplateModalProps> = WithLockedValidation(
	({ configData, credentialId, handleModalOnCancel, handleModalOnSuccess, isNew, isTemplate, user, userId }) => {
		const _showClearPin = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_EnrollmentManager_DisplayPINCodes).allowed;
		const [modalContextState, dispatchContextAction] = useReducer(credentialTemplateContextReducer, credentialInitialState);
		const { Provider } = CredentialStoreContext;

		const credentialTemplateModalContextValue = useMemo(
			() => ({
				credentialInitialState: {
					...modalContextState,
					configData: configData,
					generalTabState: {
						...modalContextState.generalTabState,
						isCredentialTemplate: isTemplate,
						keypadSectionState: {
							...modalContextState.generalTabState.keypadSectionState,
							showClearPin: _showClearPin,
						},
					},
					userId,
				},
				dispatcher: dispatchContextAction,
			}),
			[modalContextState, dispatchContextAction]
		);

		return (
			<Provider value={credentialTemplateModalContextValue}>
				<ModalComponent
					credentialId={credentialId}
					handleModalOnCancel={handleModalOnCancel}
					handleModalOnSuccess={handleModalOnSuccess}
					isNew={isNew}
					user={user}
					userId={userId}
				/>
			</Provider>
		);
	}
);

export { CredentialTemplateModal };

import React from 'react';
import { CurrentDeviceControlObj, Door } from '../../../../model/DeviceAdminModel';
import { BadgeTemplate } from '../../../../model/EnrollmentModel';

export type DoorState = {
	door: Door;
	badgeTemplates: BadgeTemplate[];
	inputBelongToDoor: CurrentDeviceControlObj;
	relayBelongToDoor: CurrentDeviceControlObj;
	entryReaderId: number;
	exitReaderId: number;
	newTimeZoneAdded: number;
};

enum ContextActionTypes {
	SET_INIT_DOOR = 'SET_INIT_DOOR',
	SET_DOOR_NAME = 'SET_DOOR_NAME',
	SET_DOOR_DIRECTION = 'SET_DOOR_DIRECTION',
	SET_BADGE_TEMPLATES = 'SET_BADGE_TEMPLATES',
	SET_DOOR_PHOTOCALLUP_DELAY = 'SET_DOOR_PHOTOCALLUP_DELAY',
	SET_DOOR_PHOTOCALLUP_TEMPLATEID = 'SET_DOOR_PHOTOCALLUP_TEMPLATEID',
	SET_DOOR_AUTORELOCK_ON_CLOSE = 'SET_DOOR_AUTORELOCK_ON_CLOSE',
	SET_DOOR_AUTORELOCK_DISABLE = 'SET_DOOR_AUTORELOCK_DISABLE',
	SET_DOOR_EXTENDED_ACCESS_OVERRIDE = 'SET_DOOR_EXTENDED_ACCESS_OVERRIDE',
	SET_DOOR_EXTENDED_ACCESS_WARNING = 'SET_DOOR_EXTENDED_ACCESS_WARNING',
	SET_DOOR_TWO_PERSON_RULE = 'SET_DOOR_TWO_PERSON_RULE',
	SET_DOOR_REPORT_DOOR_OPEN_TOO_LONG = 'SET_REPORT_DOOR_OPEN_TOO_LONG',
	SET_NEW_TIMEZONE_ID = 'SET_NEW_TIMEZONE_ID',
}

type setInitDoor = {
	type: ContextActionTypes.SET_INIT_DOOR;
	payload: DoorState;
};

type setDoorName = {
	type: ContextActionTypes.SET_DOOR_NAME;
	payload: string;
};

type setDoorDirection = {
	type: ContextActionTypes.SET_DOOR_DIRECTION;
	payload: boolean;
};

type setDoorPhotoCallUpDelay = {
	type: ContextActionTypes.SET_DOOR_PHOTOCALLUP_DELAY;
	payload: number;
};

type setBadgeTemplates = {
	type: ContextActionTypes.SET_BADGE_TEMPLATES;
	payload: BadgeTemplate[];
};

type setDoorPhotoCallUpTemplateId = {
	type: ContextActionTypes.SET_DOOR_PHOTOCALLUP_TEMPLATEID;
	payload: number;
};

type setDoorAutoRelockOnClose = {
	type: ContextActionTypes.SET_DOOR_AUTORELOCK_ON_CLOSE;
	payload: boolean;
};

type setDoorAutoRelockDisable = {
	type: ContextActionTypes.SET_DOOR_AUTORELOCK_DISABLE;
	payload: boolean;
};

type setDoorExtendedAccessOverride = {
	type: ContextActionTypes.SET_DOOR_EXTENDED_ACCESS_OVERRIDE;
	payload: number;
};

type setDoorExtendedAccessWarning = {
	type: ContextActionTypes.SET_DOOR_EXTENDED_ACCESS_WARNING;
	payload: number;
};

type setDoorReportDoorOpenTooLong = {
	type: ContextActionTypes.SET_DOOR_REPORT_DOOR_OPEN_TOO_LONG;
	payload: boolean;
};

type setDoorTwoPersonRule = {
	type: ContextActionTypes.SET_DOOR_TWO_PERSON_RULE;
	payload: boolean;
};

type setDoorNewTimeZoneId = {
	type: ContextActionTypes.SET_NEW_TIMEZONE_ID;
	payload: number;
};

type ActionContext =
	| setInitDoor
	| setDoorName
	| setDoorDirection
	| setDoorPhotoCallUpDelay
	| setBadgeTemplates
	| setDoorPhotoCallUpTemplateId
	| setDoorAutoRelockOnClose
	| setDoorAutoRelockDisable
	| setDoorExtendedAccessOverride
	| setDoorExtendedAccessWarning
	| setDoorReportDoorOpenTooLong
	| setDoorTwoPersonRule
	| setDoorNewTimeZoneId;

const doorContextState: DoorState = {
	door: null,
	badgeTemplates: [],
	inputBelongToDoor: null,
	relayBelongToDoor: null,
	entryReaderId: 0,
	exitReaderId: 0,
	newTimeZoneAdded: 0,
};

const doorContextReducers = (state: Readonly<DoorState>, action: ActionContext): DoorState => {
	switch (action.type) {
		case ContextActionTypes.SET_INIT_DOOR:
			return {
				...state,
				door: action.payload.door,
				badgeTemplates: action.payload.badgeTemplates,
				inputBelongToDoor: action.payload.inputBelongToDoor,
				relayBelongToDoor: action.payload.relayBelongToDoor,
				entryReaderId: action.payload.entryReaderId,
				exitReaderId: action.payload.exitReaderId,
			};
		case ContextActionTypes.SET_DOOR_NAME:
			return { ...state, door: { ...state.door, Name: action.payload } };
		case ContextActionTypes.SET_DOOR_DIRECTION:
			return { ...state, door: { ...state.door, EntryReadersOnly: action.payload } };
		case ContextActionTypes.SET_DOOR_PHOTOCALLUP_DELAY:
			return { ...state, door: { ...state.door, PhotoCallUpDelay: action.payload } };
		case ContextActionTypes.SET_BADGE_TEMPLATES:
			return { ...state, badgeTemplates: action.payload };
		case ContextActionTypes.SET_DOOR_PHOTOCALLUP_TEMPLATEID:
			return { ...state, door: { ...state.door, PhotoCallUpTemplateId: action.payload } };
		case ContextActionTypes.SET_DOOR_EXTENDED_ACCESS_OVERRIDE:
			return { ...state, door: { ...state.door, ExtendedAccessOverrideTime: action.payload } };
		case ContextActionTypes.SET_DOOR_EXTENDED_ACCESS_WARNING:
			return { ...state, door: { ...state.door, ExtendedAccessWarningTime: action.payload } };
		case ContextActionTypes.SET_DOOR_AUTORELOCK_ON_CLOSE:
			return { ...state, door: { ...state.door, AutoRelockOnClose: action.payload } };
		case ContextActionTypes.SET_DOOR_AUTORELOCK_DISABLE:
			return { ...state, door: { ...state.door, AutoRelockDisable: action.payload } };
		case ContextActionTypes.SET_DOOR_TWO_PERSON_RULE:
			return { ...state, door: { ...state.door, EnableTwoPersonRule: action.payload } };
		case ContextActionTypes.SET_DOOR_REPORT_DOOR_OPEN_TOO_LONG:
			return { ...state, door: { ...state.door, ReportDoorOpenTooLongWhenUnlocked: action.payload } };
		case ContextActionTypes.SET_NEW_TIMEZONE_ID:
			return { ...state, newTimeZoneAdded: action.payload };
		default:
			return { ...state };
	}
};

const setInitDoorAction = (payload: DoorState): setInitDoor => {
	return {
		type: ContextActionTypes.SET_INIT_DOOR,
		payload,
	};
};

const setDoorNameAction = (payload: string): setDoorName => {
	return {
		type: ContextActionTypes.SET_DOOR_NAME,
		payload,
	};
};

const setDoorDirectionAction = (payload: boolean): setDoorDirection => {
	return {
		type: ContextActionTypes.SET_DOOR_DIRECTION,
		payload,
	};
};

const setDoorPhotoCallUpDelayAction = (payload: number): setDoorPhotoCallUpDelay => {
	return {
		type: ContextActionTypes.SET_DOOR_PHOTOCALLUP_DELAY,
		payload,
	};
};

const setBadgeTemplatesAction = (payload: BadgeTemplate[]): setBadgeTemplates => {
	return {
		type: ContextActionTypes.SET_BADGE_TEMPLATES,
		payload,
	};
};

const setDoorPhotoCallUpTemplateIdAction = (payload: number): setDoorPhotoCallUpTemplateId => {
	return {
		type: ContextActionTypes.SET_DOOR_PHOTOCALLUP_TEMPLATEID,
		payload,
	};
};

const setDoorExtendedAccessOverrideAction = (payload: number): setDoorExtendedAccessOverride => {
	return {
		type: ContextActionTypes.SET_DOOR_EXTENDED_ACCESS_OVERRIDE,
		payload,
	};
};

const setDoorExtendedAccessWarningAction = (payload: number): setDoorExtendedAccessWarning => {
	return {
		type: ContextActionTypes.SET_DOOR_EXTENDED_ACCESS_WARNING,
		payload,
	};
};

const setDoorAutoRelockOnCloseAction = (payload: boolean): setDoorAutoRelockOnClose => {
	return {
		type: ContextActionTypes.SET_DOOR_AUTORELOCK_ON_CLOSE,
		payload,
	};
};

const setDoorAutoRelockDisableAction = (payload: boolean): setDoorAutoRelockDisable => {
	return {
		type: ContextActionTypes.SET_DOOR_AUTORELOCK_DISABLE,
		payload,
	};
};

const setDoorTwoPersonRuleAction = (payload: boolean): setDoorTwoPersonRule => {
	return {
		type: ContextActionTypes.SET_DOOR_TWO_PERSON_RULE,
		payload,
	};
};

const setDoorReportDoorOpenTooLongAction = (payload: boolean): setDoorReportDoorOpenTooLong => {
	return {
		type: ContextActionTypes.SET_DOOR_REPORT_DOOR_OPEN_TOO_LONG,
		payload,
	};
};

const setDoorNewTimeZoneAction = (payload: number): setDoorNewTimeZoneId => {
	return {
		type: ContextActionTypes.SET_NEW_TIMEZONE_ID,
		payload,
	};
};

type doorModalContext = {
	doorContextState: Readonly<DoorState>;
	dispatcher: React.Dispatch<ActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const DoorStoreContext = React.createContext<doorModalContext>({ doorContextState, dispatcher: null });

export {
	doorContextReducers,
	setDoorNameAction,
	setDoorDirectionAction,
	setDoorPhotoCallUpDelayAction,
	setBadgeTemplatesAction,
	setDoorPhotoCallUpTemplateIdAction,
	setDoorExtendedAccessOverrideAction,
	setDoorExtendedAccessWarningAction,
	setInitDoorAction,
	setDoorAutoRelockOnCloseAction,
	setDoorAutoRelockDisableAction,
	setDoorTwoPersonRuleAction,
	setDoorReportDoorOpenTooLongAction,
	setDoorNewTimeZoneAction,
	DoorStoreContext,
	doorContextState,
};

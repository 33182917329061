import { Input } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';
import styles from '../../commandSetsModal.module.scss';

type Props = {
	commandValidation?: boolean;
	onKeyPressCommand?: (value: string) => void;
	label?: string;
	value?: string;
	inputId?: string;
	controlledComponent?: boolean;
	onChange?: (value: string) => void;
};

const CommandInput: React.FC<Props> = ({ commandValidation, onKeyPressCommand, label, value = '', inputId, controlledComponent, onChange }) => {
	//trigger on form reference
	const triggerChange = (changedValue: string) => {
		onChange?.(changedValue);
	};

	//assign value from form reference , state is using only on controlled component is turned on
	const [inputValue, setInputValue] = useState(value);

	const handleOnKeyPressCommand = (e: React.KeyboardEvent<HTMLInputElement>) => {
		const key = e.key.charCodeAt(0);
		const currentValue = controlledComponent ? inputValue : value;
		if ((key >= 48 && key <= 57) || key == 16 || key == 35 || key == 42) {
			const commandValue = currentValue + e.key;
			if (controlledComponent) {
				setInputValue(commandValue);
				triggerChange(commandValue);
			} else {
				onKeyPressCommand?.(commandValue);
			}
		} else if (key == 66) {
			const value = e.currentTarget.value;
			const commandValue = value.substring(0, value.length > 0 ? value.length - 1 : value.length);
			if (controlledComponent) {
				setInputValue(commandValue);
				triggerChange(commandValue);
			} else {
				onKeyPressCommand?.(commandValue);
			}
		} else if (key == 32) {
			e.preventDefault();
			const commandValue = currentValue + '*';
			if (controlledComponent) {
				setInputValue(commandValue);
				triggerChange(commandValue);
			} else {
				onKeyPressCommand?.(commandValue);
			}
		} else if (key == 13) {
		} else {
			e.preventDefault();
		}
	};
	return (
		<div className={styles.sections}>
			{label && (
				<label id='commandSetCommandLabel' htmlFor={inputId}>
					{label}
				</label>
			)}
			<div>
				<Input
					id={inputId}
					className={cx(styles.input, {
						[styles.error]: commandValidation,
					})}
					key='command'
					value={controlledComponent ? inputValue : value}
					onKeyUp={handleOnKeyPressCommand}
					maxLength={40000}
				/>
				{commandValidation && (
					<label className={styles.errorMessage} htmlFor={inputId}>
						{_('PleaseSelectControllerAndEnterCommand')}
					</label>
				)}
			</div>
		</div>
	);
};

export { CommandInput };

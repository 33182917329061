import { Button, Input, InputNumber } from 'antd';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { noneDecimalFormatter, numericAndCommaMinusSignFormatter, splitIPAddressIntoOctets } from '../../../../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../../../../api';
import { ResponseStatusCode } from '../../../../../../../../../model/CommonModel';
import { IPAddressOctets, NetworkOctetsKeys, SnibSearchType } from '../../../../../../../../../model/DeviceAdminModel';
import { InformationMessages, ModalInfo } from '../../../../../../../../common';
import { PortStoreContext, setIPAddressOctets, setIpRangeError } from '../../../../../contextPort';
import styles from './searchIPRange.module.scss';

type Props = {};
const searchIPRangeInput1Id = 'searchIPRangeInput1Id';
const searchIPRangeInput2Id = 'searchIPRangeInput2Id';
const searchIPRangeInput3Id = 'searchIPRangeInput3Id';
const searchIPRangeInput4Id = 'searchIPRangeInput4Id';
const searchIPRangeExamplesButtonId = 'searchIPRangeExamplesButtonId';
const separator = '-';
const octet4Text = 'XXX';

type IPRangeError = {
	hasError: boolean;
	errorMessage: string;
};

const SearchIPRange: React.FC<Props> = () => {
	const [addressesToSearch, setAddressesToSearch] = useState<number>(0);
	const {
		portState: { currentNetwork, ipAddressOctets, snibSearchType, loading, ipRangeError },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		splitNetworkToOctets();
	}, [currentNetwork]);

	useEffect(() => {
		if (snibSearchType === SnibSearchType.IPScan) {
			getAddressesToSearch(ipAddressOctets.octet3);
		} else {
			splitNetworkToOctets();
		}
	}, [snibSearchType]);

	const handleOnClickExamplesButton = () => {
		const messages = [
			_('SearchIPRangeExamplesText1'),
			_('SearchIPRangeExamplesText2'),
			_('SearchIPRangeExamplesText3'),
			_('SearchIPRangeExamplesText4'),
			_('SearchIPRangeExamplesText5'),
			_('SearchIPRangeExamplesText6'),
			_('SearchIPRangeExamplesText7'),
			_('SearchIPRangeExamplesText8'),
			_('SearchIPRangeExamplesText9'),
			_('SearchIPRangeExamplesText10'),
			_('SearchIPRangeExamplesText11'),
		];

		ModalInfo({
			title: _('IPRangeExamplesTitle'),
			content: <InformationMessages messages={messages} />,
			onOk: () => null,
			onCancel: () => null,
			okText: _('Ok'),
			width: '550px',
		});
	};

	const handleChangeOctetsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget;
		const validValue = numericAndCommaMinusSignFormatter(value);
		dispatcher(setIPAddressOctets({ ...ipAddressOctets, [name]: validValue }));
		getAddressesToSearch(validValue);
	};

	const handleChangeOctetsInputNumber = (name: NetworkOctetsKeys, value: string) => {
		dispatcher(setIPAddressOctets({ ...ipAddressOctets, [name]: value }));
	};

	const splitNetworkToOctets = () => {
		const octets: IPAddressOctets = splitIPAddressIntoOctets(currentNetwork, octet4Text);
		dispatcher(setIPAddressOctets(octets));
	};

	const getAddressesToSearch = useCallback(
		debounce(async (octet3: string) => {
			const res = await deviceAdminApi.getAddressesToSearch(octet3);

			batch(() => {
				dispatcher(
					setIpRangeError({
						errorMessage: res.ErrorMessage ?? '',
						hasError: res.ResponseStatusCode !== ResponseStatusCode.Success,
					})
				);
				setAddressesToSearch(res.Entity);
			});
		}, 200),
		[ipAddressOctets.octet3]
	);

	const isIpScanEnabled = snibSearchType === SnibSearchType.IPScan;

	return (
		<div className={styles.fieldsetContainer}>
			<fieldset>
				<legend className={styles.legend}>{_('SearchIPRange')}</legend>
				<div className={styles.fieldsetContent}>
					<div className={styles.label}>
						<label>{_('PleaseProvideIPRangeToScan')}</label>
						<Button disabled={loading} id={searchIPRangeExamplesButtonId} onClick={handleOnClickExamplesButton}>
							{_('Examples')}
						</Button>
					</div>
					<div className={styles.inputs}>
						<div>
							<InputNumber
								id={searchIPRangeInput1Id}
								value={ipAddressOctets.octet1}
								min={1}
								max={254}
								step={1}
								onChange={(value: string) => handleChangeOctetsInputNumber(NetworkOctetsKeys.octet1, value)}
								disabled={!isIpScanEnabled || loading}
								formatter={noneDecimalFormatter}
							/>{' '}
						</div>
						<label>{separator}</label>
						<div>
							<InputNumber
								id={searchIPRangeInput2Id}
								value={ipAddressOctets.octet2}
								min={0}
								max={254}
								step={1}
								onChange={(value: string) => handleChangeOctetsInputNumber(NetworkOctetsKeys.octet2, value)}
								disabled={!isIpScanEnabled || loading}
								formatter={noneDecimalFormatter}
							/>
						</div>
						<label>{separator}</label>
						<div>
							<Input
								id={searchIPRangeInput3Id}
								name={NetworkOctetsKeys.octet3}
								value={ipAddressOctets.octet3}
								onChange={handleChangeOctetsInput}
								disabled={!isIpScanEnabled || loading}
								className={cx({
									[styles.error]: ipRangeError.hasError,
								})}
							/>
						</div>
						<label>{separator}</label>
						<div>
							<Input id={searchIPRangeInput4Id} name={NetworkOctetsKeys.octet4} value={ipAddressOctets.octet4} disabled />
						</div>
						<div>
							{isIpScanEnabled && (
								<>
									<label>{_('AddressesToSearch')}</label>
									<label>{addressesToSearch !== 0 ? addressesToSearch : ''}</label>
								</>
							)}
						</div>
					</div>
					<div className={styles.errorContainer}>
						{ipRangeError.hasError && (
							<label className={styles.errorMessage} htmlFor={searchIPRangeInput3Id}>
								{ipRangeError.errorMessage}
							</label>
						)}
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export { SearchIPRange };

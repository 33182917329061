// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".control-zones-dropdown-container--2fi8ac-K_5ZSeuvce5vJ_j{display:flex;flex-direction:column;width:50%}.control-zones-dropdown-container--2fi8ac-K_5ZSeuvce5vJ_j>div{display:flex;flex-direction:column}.control-zones-dropdown-error--2p9VYKeX6tZyZHc-u78Y6I [class*=\"ant-select ant-select-single ant-select-show-arrow\"]{border:1px solid red !important}.control-zones-dropdown-error--2p9VYKeX6tZyZHc-u78Y6I label{color:red}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/VelocityConfiguration/ReaderControlGroup/ReaderModal/sections/ControlZonesDropdown/controlZonesDropdown.module.scss"],"names":[],"mappings":"AAAA,0DACC,YAAA,CACA,qBAAA,CACA,SAAA,CACA,8DACC,YAAA,CACA,qBAAA,CAID,oHACC,+BAAA,CAED,4DACC,SAAA","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\twidth: 50%;\r\n\t& > div {\r\n\t\tdisplay: flex;\r\n\t\tflex-direction: column;\r\n\t}\r\n}\r\n.error {\r\n\t[class*='ant-select ant-select-single ant-select-show-arrow'] {\r\n\t\tborder: 1px solid red !important;\r\n\t}\r\n\tlabel {\r\n\t\tcolor: red;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "control-zones-dropdown-container--2fi8ac-K_5ZSeuvce5vJ_j",
	"error": "control-zones-dropdown-error--2p9VYKeX6tZyZHc-u78Y6I"
};
export default ___CSS_LOADER_EXPORT___;

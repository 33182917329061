// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ipv4-address-input-input--B8EsEQUDHjMeDnk0C45oW{height:30px !important;width:60% !important}.ipv4-address-input-error--3z-BM9Gq8aZM1mff95Tip2{border:1px solid red !important}", "",{"version":3,"sources":["webpack://src/components/common/IPv4AddressInput/ipv4AddressInput.module.scss"],"names":[],"mappings":"AAAA,iDACC,sBAAA,CACA,oBAAA,CAED,kDACC,+BAAA","sourcesContent":[".input {\r\n\theight: 30px !important;\r\n\twidth: 60% !important;\r\n}\r\n.error {\r\n\tborder: 1px solid red !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "ipv4-address-input-input--B8EsEQUDHjMeDnk0C45oW",
	"error": "ipv4-address-input-error--3z-BM9Gq8aZM1mff95Tip2"
};
export default ___CSS_LOADER_EXPORT___;

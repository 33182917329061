import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../Helper';
import styles from '../../commandSetsModal.module.scss';
import { setName, StoreContext } from '../../contextCommand';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
};

const Name: React.FC<Props> = ({ nameError, nameValidation, onResetNameError }) => {
	const {
		commandState: {
			name,
			errors: { emptyName, duplicatedName },
		},
		dispatcher,
	} = useContext(StoreContext);

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		dispatcher(setName(value));
		onResetNameError();
	};

	const commandSetNameInputId = 'commandSetNameInput';

	return (
		<div className={styles.sections}>
			<label id='commandSetNameLabel' htmlFor={commandSetNameInputId}>
				{_('Name')}
			</label>
			<div>
				<Input
					id={commandSetNameInputId}
					key='name'
					value={name}
					maxLength={32}
					onChange={handleOnChangeName}
					className={cx(styles.input, {
						[styles.error]: nameValidation || nameError,
					})}
				/>
				{nameValidation && (
					<label className={styles.errorMessage} htmlFor={commandSetNameInputId}>
						{emptyName}
					</label>
				)}
				{nameError && (
					<label className={styles.errorMessage} htmlFor={commandSetNameInputId}>
						{duplicatedName.replace('%%1', name)}
					</label>
				)}
			</div>
		</div>
	);
};

export { Name };

import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../Helper';
import { setName as SetNameExpansionRelay } from '../../../../ExpansionRelayModal/contextExpansionRelay';
import { setInputAction } from '../../../../InputModal/inputModalContext';
import { setName } from '../../../../ReaderModal/contextReader';
import { setExitReaderName } from '../../../../ReaderModal/ExitReaderModal/exitReaderContext';
import { setDoorNameAction } from '../../../DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorStoreSingleContext';
import styles from '../../general.module.scss';

type Props = {
	nameError: boolean;
	nameErrorText: string;
	onResetNameError: () => void;
	setErrorText: () => void;
};

const DoorName: React.FC<Props> = ({ nameError, nameErrorText, onResetNameError, setErrorText }) => {
	const {
		contextStateDoor: {
			door: {
				door: { Name },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		dispatcherDoor(setDoorNameAction(value));
		dispatcherDoor(setName(value));
		dispatcherDoor(setExitReaderName(value));
		dispatcherDoor(setInputAction({ Name: value }));
		dispatcherDoor(SetNameExpansionRelay(value));
		onResetNameError();
		setErrorText();
	};

	return (
		<>
			<div className={styles.containerInput}>
				<span>{_('DoorName')}</span>
				<div>
					<Input
						id='doorModalInputDoorName'
						onChange={handleChangeName}
						value={Name}
						maxLength={32}
						className={cx(styles.input, {
							[styles.error]: nameError,
						})}></Input>
					{nameError && <span className={styles.errorMessage}>{nameErrorText}</span>}
				</div>
			</div>
		</>
	);
};

export { DoorName };

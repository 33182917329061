import {
	CaretDownOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	FileSearchOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PauseCircleOutlined,
	PlayCircleOutlined,
	SettingFilled,
} from '@ant-design/icons';
import { Button, Popover, Spin, Tooltip, Typography } from 'antd';
import cx from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { handleResponse } from '../../Helper';
import { whosInsideApi } from '../../api';
import { SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../model/AccountModel';
import { ResponseStatusCode, SelectOptions } from '../../model/CommonModel';
import { OptionsButtonBuilder } from '../../model/DeviceAdminModel';
import { Logger } from '../../model/LoggingModel';
import {
	ActionToExecute,
	CredentialChangeInfo,
	MoveCredential,
	WhosInsideAction,
	WhosInsideActionDetail,
	WhosInsideCurrentAction,
	Zone,
	ZoneGroupTree,
	ZoneKey,
} from '../../model/WhosInsideModel';
import { useStoreSelector } from '../../store';
import { selectWhosInsideActionDetails } from '../../store/common/selectors';
import { ButtonDropdown, ColumnConfiguration, ModalConfirmationList, PreviewBadgeCredential } from '../common';
import {
	WhosInsideInfo,
	WhosInsideMoveTo,
	WhosInsideTree,
	createButtonOptions,
	createMoveToOption,
	getUpdatedZoneGroupNodes,
	handleOnMoveCredential,
	handleOpenReport,
	handleWhosInsideAction,
	onForgiveCredentials,
} from '../whosInside';
import styles from './whosInside.module.scss';

const user: User = getUser();
const canMakeReport: boolean = User.getSubComponentPermission(user, SubPermissions.WhosInside_DisplayWhosInsideHTMLReport).allowed;
const { Paragraph } = Typography;

const WhosInside = () => {
	const [displayPreview, setDisplayPreview] = useState<boolean>(false);
	const [zoneSelected, setZoneSelected] = useState<Zone | null>();
	const [selectedZoneName, setZoneSelectedZoneName] = useState<string>('');
	const [selectedZoneGroupName, setZoneSelectedZoneGroupName] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [autoRefreshEnabled, setAutoRefreshEnabled] = useState<boolean>(true);
	const [hideEmptyZones, setHideEmptyZones] = useState<boolean>(false);
	const [currentEditionAction, setCurrentEditionAction] = useState<WhosInsideCurrentAction | null>();
	const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
	const [refreshZone, setRefreshZone] = useState<number>(0);
	const [credentialChanges, setCredentialChanges] = useState<CredentialChangeInfo[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [zoneGroupTreeRawData, setZoneGroupTreeRawData] = useState<ZoneGroupTree | null>();
	const fetchZonesAbortController = useRef<AbortController>(undefined);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [selectedKey, SetSelectedKey] = useState<string[]>([]);
	const [validateSelectedRowKeys, SetValidateSelectedRowKeys] = useState<boolean>(false);
	const [displayColumnConfiguration, setDisplayColumnConfiguration] = useState<boolean>(false);

	const whosInsideActionDetails: WhosInsideActionDetail[] = useStoreSelector<WhosInsideActionDetail[]>(selectWhosInsideActionDetails);

	//Needed to use reference in order to work in LongPoll's methods
	const hideEmptyZonesReference = React.useRef<boolean>(hideEmptyZones);
	const setHideEmptyZonesReference = (newHideEmptyZonesReference: boolean): void => {
		hideEmptyZonesReference.current = newHideEmptyZonesReference;
		setHideEmptyZones(newHideEmptyZonesReference);
	};

	const autoRefreshReference = React.useRef<boolean>(autoRefreshEnabled);
	const setAutoRefreshReference = (newAutoRefreshReference: boolean): void => {
		autoRefreshReference.current = newAutoRefreshReference;
		setAutoRefreshEnabled(newAutoRefreshReference);
	};

	const zoneGroupTreeRawDataReference = React.useRef<ZoneGroupTree>(zoneGroupTreeRawData);
	const setZoneGroupTreeRawDataReference = (newZoneGroupTreeRawDataReference: ZoneGroupTree): void => {
		if (hideEmptyZonesReference.current) {
			newZoneGroupTreeRawDataReference.GlobalizedGroup = newZoneGroupTreeRawDataReference.GlobalizedGroup.map(x => ({
				...x,
				Zones: x.Zones.filter(y => y.CredentialsOnZone !== 0),
			}));
			newZoneGroupTreeRawDataReference.CustomGroup = newZoneGroupTreeRawDataReference.CustomGroup.map(x => ({
				...x,
				Zones: x.Zones.filter(y => y.CredentialsOnZone !== 0),
			}));
			newZoneGroupTreeRawDataReference.SharedZones = newZoneGroupTreeRawDataReference.SharedZones.filter(x => x.CredentialsOnZone !== 0);
		}

		zoneGroupTreeRawDataReference.current = newZoneGroupTreeRawDataReference;
		validateZoneTreeChanges(newZoneGroupTreeRawDataReference);
		setZoneGroupTreeRawData(newZoneGroupTreeRawDataReference);
	};

	const zoneSelectedReference = React.useRef<Zone | null>(zoneSelected);
	const setZoneSelectedReference = (newZone: Zone): void => {
		zoneSelectedReference.current = newZone;
		setZoneSelected(newZone);
	};

	const currentEditionActionReference = React.useRef<WhosInsideCurrentAction | null>(currentEditionAction);
	const setCurrentEditionActionReference = (currentAction: WhosInsideCurrentAction): void => {
		currentEditionActionReference.current = currentAction;
		setCurrentEditionAction(currentAction);
	};

	const selectedRowKeysReference = React.useRef<React.Key[] | null>(selectedRowKeys);
	const setSelectedRowKeysReference = (selectedRowKeys: React.Key[]): void => {
		selectedRowKeysReference.current = selectedRowKeys;
		setSelectedRowKeys(selectedRowKeys);
	};

	const pendingChanges = useRef<WhosInsideActionDetail[]>([]);

	const handleSetZoneSelectedZoneNameCallback = useCallback((name: string) => setZoneSelectedZoneName(name), [setZoneSelectedZoneName]);
	const handleSetIsLoadingCallback = useCallback((isLoading: boolean) => setIsLoading(isLoading), [setIsLoading]);
	const handleSetZoneSelectedZoneGroupNameCallback = useCallback((name: string) => setZoneSelectedZoneGroupName(name), [setZoneSelectedZoneGroupName]);
	const handleResetCredentialChangesCallback = useCallback(() => setCredentialChanges([]), [setCredentialChanges]);
	const handleValidateSelectedRowKeysCallback = useCallback(() => SetValidateSelectedRowKeys(false), [SetValidateSelectedRowKeys]);
	const handleFetchZonesCallback = useCallback(() => fetchZones(), [setZoneGroupTreeRawData]);
	const loadColumnConfigurationCallback = useCallback(async () => whosInsideApi.getColumnsConfiguration(), []);
	const handleHideModalCallback = useCallback(() => setDisplayColumnConfiguration(false), []);
	const dispatchActionCallback = useCallback(() => setRefreshZone(prevState => prevState + 1), []);

	const handleSetZoneSelectedCallback = useCallback(
		zone => {
			zoneSelectedReference.current = zone;
			setZoneSelected(zone);
		},
		[setZoneSelected]
	);
	const handleSetCurrentEditionActionCallback = useCallback(
		(currentAction: WhosInsideCurrentAction) => {
			currentEditionActionReference.current = currentAction;
			setCurrentEditionAction(currentAction);
		},
		[setCurrentEditionAction]
	);
	const handleSetSelectedRowKeysCallback = useCallback(
		(rowKeys: React.Key[]) => {
			setSelectedRowKeysReference(rowKeys);
		},
		[setSelectedRowKeys]
	);
	const handleSetCurrentPageCallback = useCallback(
		(currentPage: number) => {
			setCurrentPage(currentPage);
		},
		[setCurrentPage]
	);
	const handleSetSelectedKeyCallback = useCallback(
		(selectedKey: string) => {
			SetSelectedKey([selectedKey]);
		},
		[SetSelectedKey]
	);

	useEffect(() => {
		whosInsideApi
			.initWhosInside()
			.then(response => {
				setHideEmptyZonesReference(response.HideEmptyZones);
				setZoneGroupTreeRawDataReference(response.ZoneGroupTree);
			})
			.finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		if (whosInsideActionDetails && whosInsideActionDetails.length) {
			handleLongPollResponse(whosInsideActionDetails);
		}
	}, [whosInsideActionDetails]);

	useEffect(() => {
		if (!autoRefreshReference.current && zoneSelected) {
			const zoneAsAny = zoneSelectedReference.current as any;
			const zoneGroupId = zoneAsAny.ZoneKey === ZoneKey.EveryOne ? -1 : zoneAsAny.ZoneGroupId;
			if (zoneAsAny.ZoneKey !== ZoneKey.WholeGroup) {
				whosInsideApi.getZoneInfo(zoneGroupId, zoneAsAny.ZoneId).then(response => {
					const updatedTree: ZoneGroupTree = getUpdatedZoneGroupNodes(response, zoneAsAny.ZoneKey, { ...zoneGroupTreeRawDataReference.current });
					setZoneGroupTreeRawData(updatedTree);
				});
			}
		}
	}, [zoneSelected]);

	const handleLongPollResponse = async (whosInsideActionDetails: WhosInsideActionDetail[]): Promise<void> => {
		if (autoRefreshReference.current) {
			if (pendingChanges.current.length > 0) {
				whosInsideActionDetails.push(...pendingChanges.current);
				pendingChanges.current = [];
			}

			const hardRefresh: boolean =
				whosInsideActionDetails.findIndex(
					x => x.WhosInsideAction === WhosInsideAction.Refresh || x.WhosInsideAction === WhosInsideAction.UpdateTimer
				) !== -1;

			if (hardRefresh) {
				if (selectedRowKeysReference.current.length > 0) {
					SetValidateSelectedRowKeys(true);
				}

				await fetchZones();
				setRefreshZone(prevState => prevState + 1);
			} else if (whosInsideActionDetails.length > 0) {
				const currentZoneGroupTree: ZoneGroupTree = { ...zoneGroupTreeRawDataReference.current };
				const updatedData: ZoneGroupTree = handleWhosInsideAction(whosInsideActionDetails, currentZoneGroupTree);
				setZoneGroupTreeRawDataReference(updatedData);

				let credentialChanges: CredentialChangeInfo[] = [];
				const credentialChangesActionDetails: WhosInsideActionDetail[] = whosInsideActionDetails.filter(
					x => x.WhosInsideAction === WhosInsideAction.CredentialChanged
				);
				for (const actionDetail of credentialChangesActionDetails) {
					credentialChanges.push(actionDetail.CredentialChanges);
				}

				setCredentialChanges(credentialChanges);
				if (credentialChanges.length > 0) {
					setRefreshZone(prevState => prevState + 1);
				}
			}
		} else if (whosInsideActionDetails.length > 0) {
			pendingChanges.current.push(...whosInsideActionDetails);
		}
	};

	const validateZoneTreeChanges = (currentTree: ZoneGroupTree): void => {
		let zoneIndexHelper: number = -1;
		let zoneGroupIndexHelper: number = -1;
		if (zoneSelectedReference.current) {
			const zoneAsAny = zoneSelectedReference.current as any;

			switch (zoneAsAny.ZoneKey) {
				case ZoneKey.WholeGroup:
					if (zoneAsAny.IsGlobalized) {
						zoneGroupIndexHelper = currentTree.GlobalizedGroup.findIndex(x => x.ZoneGroupId === zoneAsAny.ZoneGroupId);
						if (zoneGroupIndexHelper === -1) {
							clearTreeSelections();
						} else {
							setZoneSelectedZoneName('');
							setZoneSelectedZoneGroupName(currentTree.GlobalizedGroup[zoneGroupIndexHelper].ZoneGroupName);
						}
					} else {
						zoneGroupIndexHelper = currentTree.CustomGroup.findIndex(x => x.ZoneGroupId === zoneAsAny.ZoneGroupId);
						if (zoneGroupIndexHelper === -1) {
							clearTreeSelections();
						} else {
							setZoneSelectedZoneName('');
							setZoneSelectedZoneGroupName(currentTree.CustomGroup[zoneGroupIndexHelper].ZoneGroupName);
						}
					}
					break;

				case ZoneKey.Shared:
					zoneIndexHelper = currentTree.SharedZones.findIndex(x => x.ZoneId === zoneAsAny.ZoneId);
					if (zoneIndexHelper === -1) {
						clearTreeSelections();
					} else {
						setZoneSelectedZoneName(currentTree.SharedZones[zoneIndexHelper].ZoneName);
					}
					break;

				case ZoneKey.Globalized:
					zoneGroupIndexHelper = currentTree.GlobalizedGroup.findIndex(x => x.ZoneGroupId === zoneAsAny.ZoneGroupId);
					if (zoneGroupIndexHelper === -1) {
						clearTreeSelections();
					} else {
						zoneIndexHelper = currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones.findIndex(x => x.ZoneId === zoneAsAny.ZoneId);
						if (zoneIndexHelper === -1) {
							clearTreeSelections();
						} else {
							setZoneSelectedZoneName(currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].ZoneName);
							setZoneSelectedZoneGroupName(currentTree.GlobalizedGroup[zoneGroupIndexHelper].ZoneGroupName);
						}
					}
					break;

				case ZoneKey.Custom:
					zoneGroupIndexHelper = currentTree.CustomGroup.findIndex(x => x.ZoneGroupId === zoneAsAny.ZoneGroupId);
					if (zoneGroupIndexHelper === -1) {
						clearTreeSelections();
					} else {
						zoneIndexHelper = currentTree.CustomGroup[zoneGroupIndexHelper].Zones.findIndex(x => x.ZoneId === zoneAsAny.ZoneId);
						if (zoneIndexHelper === -1) {
							clearTreeSelections();
						} else {
							setZoneSelectedZoneName(currentTree.CustomGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].ZoneName);
							setZoneSelectedZoneGroupName(currentTree.CustomGroup[zoneGroupIndexHelper].ZoneGroupName);
						}
					}
					break;
			}
		}
	};

	const clearTreeSelections = (): void => {
		setZoneSelectedReference(undefined);
		setZoneSelectedZoneName('');
		setZoneSelectedZoneGroupName('');
		handleSetSelectedRowKeysCallback([]);
		SetSelectedKey([]);
		if (currentEditionActionReference.current?.type === ActionToExecute.Rename) {
			setCurrentEditionActionReference(undefined);
		}
	};

	const onHideEmptyZones = (state: boolean): void => {
		setIsLoading(true);
		whosInsideApi
			.hideEmptyZones(state)
			.then(response => {
				const treeZones: ZoneGroupTree = !state ? response.Entity : { ...zoneGroupTreeRawDataReference.current };
				setHideEmptyZonesReference(state);
				setZoneGroupTreeRawDataReference(treeZones);
			})
			.finally(() => setIsLoading(false));
	};

	const fetchZones = async (): Promise<void> => {
		if (fetchZonesAbortController.current) {
			fetchZonesAbortController.current.abort();
		}
		const abortController: AbortController = new AbortController();
		const abortSignal: AbortSignal = abortController.signal;
		fetchZonesAbortController.current = abortController;

		await whosInsideApi
			.getZonesGroupTree(abortSignal)
			.then(response => {
				if (response.EveryoneGroup !== null) {
					setZoneGroupTreeRawDataReference(response);
				}
			})
			.catch(err => {
				if (err.message !== 'canceled') {
					Logger.writeErrorLog(`${err.name}: ${err.message}`);
				}
			});
	};

	const onCollapse = (): void => {
		setMenuCollapsed(prevState => !prevState);
	};

	const isEditMode: boolean = currentEditionAction?.type === ActionToExecute.Rename;
	const disabledActionButtons: boolean = isEditMode || selectedRowKeys.length === 0;
	const items: OptionsButtonBuilder<string> = createButtonOptions(false);
	const moveToOption: SelectOptions<string> = createMoveToOption()?.options?.pop();
	const disablePopover: boolean = disabledActionButtons || moveToOption.disabled;
	const credentialsIds: number[] = selectedRowKeys.map(x => Number(x));

	const handleActionScope = async (key: string, moveCredentialInfo?: Partial<MoveCredential>): Promise<void> => {
		switch (key) {
			case 'forgiveCredentials': {
				if (credentialsIds.length > 0) {
					setIsLoading(true);
					whosInsideApi.getCredentialPersonNameByIds(zoneSelected, credentialsIds).then(res => {
						if (res.ResponseStatusCode === ResponseStatusCode.Success) {
							ModalConfirmationList({
								type: _('ForgiveCredentials'),
								translationKey: 'AreYouSureYouWantToForgiveCredentials',
								data: res.Entity.map(x => ({ Name: x.Description })),
								onConfirm: () => onForgiveCredentials(selectedRowKeys).finally(() => clearTableAfterAction()),
								onCancel: () => setIsLoading(false),
							});
						} else {
							handleResponse(res);
						}
					});
				}
				break;
			}

			case 'displayBadge': {
				if (credentialsIds.length > 0) {
					setDisplayPreview(true);
				}
				break;
			}

			case 'moveCredentialTo': {
				setIsLoading(true);
				whosInsideApi
					.getCredentialPersonNameByIds(zoneSelected, credentialsIds)
					.then(res => {
						if (res.ResponseStatusCode === ResponseStatusCode.Success) {
							const sameZoneIndex: boolean = moveCredentialInfo.ToZoneId === zoneSelected.ZoneId;
							handleOnMoveCredential(res.Entity, moveCredentialInfo, sameZoneIndex);
						} else {
							handleResponse(res);
						}
					})
					.finally(() => clearTableAfterAction());
				break;
			}
		}
	};

	const clearTableAfterAction = (): void => {
		setRefreshZone(prevState => prevState + 1);
		setIsLoading(false);
	};

	const handleOnSuspendAutoRefresh = (value: boolean): void => {
		setAutoRefreshReference(value);

		if (value && pendingChanges.current.length > 0) {
			handleLongPollResponse(pendingChanges.current);
			pendingChanges.current = [];
		}
	};

	const handleOnOpenColumnConfiguration = e => {
		setDisplayColumnConfiguration(true);
	};

	const handleOnHidePreviewBadgeModal = (): void => {
		setDisplayPreview(false);
	};

	const zoneRefreshButton: JSX.Element = autoRefreshReference.current ? (
		<PauseCircleOutlined
			id='whosInsideSuspendZoneRefreshIcon'
			title={_('SuspendZoneRefresh')}
			onClick={() => handleOnSuspendAutoRefresh(false)}
			className={cx(styles.icon, { [styles.disableIcon]: isEditMode })}
		/>
	) : (
		<PlayCircleOutlined
			id='whosInsideStartZoneRefreshIcon'
			title={_('StartZoneRefresh')}
			onClick={() => handleOnSuspendAutoRefresh(true)}
			className={cx(styles.icon, { [styles.disableIcon]: isEditMode })}
		/>
	);

	const hideEmptyZonesButton: JSX.Element = hideEmptyZones ? (
		<EyeInvisibleOutlined
			id='whosInsideHideEmptyZonesIcon'
			onClick={() => onHideEmptyZones(false)}
			title={_('ShowEmptyZones')}
			className={cx(styles.icon, { [styles.disableIcon]: isEditMode })}
		/>
	) : (
		<EyeOutlined
			id='whosInsideShowEmptyZonesIcon'
			onClick={() => onHideEmptyZones(true)}
			title={_('HideEmptyZones')}
			className={cx(styles.icon, { [styles.disableIcon]: isEditMode })}
		/>
	);

	const collapseMenuButton: JSX.Element = menuCollapsed ? (
		<MenuUnfoldOutlined id='whosInsideExpandTreeIcon' className={cx({ [styles.disableIcon]: isEditMode })} onClick={onCollapse} />
	) : (
		<MenuFoldOutlined id='whosInsideCollapseTreeIcon' className={cx({ [styles.disableIcon]: isEditMode })} onClick={onCollapse} />
	);

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			<div id='whosInsideContainer' className={styles.container}>
				<div className={cx(styles.header)}>
					<div
						className={cx({
							[styles.wrapper]: !menuCollapsed,
							[styles.wrapperCollapsed]: menuCollapsed,
						})}>
						<div className={styles.options}>
							<div>
								{hideEmptyZonesButton}
								<FileSearchOutlined
									id='whosInsideGenerateHTMLReportIcon'
									onClick={canMakeReport ? handleOpenReport : undefined}
									title={canMakeReport ? _('GenerateHTMLReport') : getPermissionErrorMessage(canMakeReport)}
									className={cx(styles.icon, { [styles.disableIcon]: isEditMode }, { [styles.disableReportIcon]: !canMakeReport })}
								/>
								{zoneRefreshButton}
							</div>
							<Tooltip trigger={['hover', 'click']} placement='right' title={menuCollapsed ? _('ClickToExpandMenu') : _('ClickToCollapseMenu')}>
								{collapseMenuButton}
							</Tooltip>
						</div>
					</div>
					<div
						className={cx({
							[styles.zoneHeaderContainer]: !menuCollapsed,
							[styles.zoneHeaderContainerCollapsed]: menuCollapsed,
						})}>
						<div className={styles.headerDropdowns}>
							<ButtonDropdown
								id='whosInsideZoneActionDropdown'
								disabled={disabledActionButtons}
								menuOptions={items.options}
								icon={<CaretDownOutlined />}
								labelIcon={items.labelOrIcon}
								onClickOption={handleActionScope}
							/>
							<Popover
								overlayClassName={styles.moveToPopover}
								content={
									<WhosInsideMoveTo
										actionValue={moveToOption.value}
										onClickOption={handleActionScope}
										isPopover={true}
										storedZoneGroupTree={hideEmptyZonesReference.current ? undefined : zoneGroupTreeRawDataReference.current}
									/>
								}
								trigger={disablePopover ? 'focus' : 'hover'}
								destroyTooltipOnHide={true}
								key={selectedRowKeys.length}>
								<span
									title={moveToOption.title}
									className={cx(styles.moveToSpan, {
										[styles.moveToSpanDisabled]: disablePopover,
										[styles.moveToSpanEnabled]: !disablePopover,
									})}>
									<Button id='whosInsideZoneMoveToDropdown' disabled={disablePopover} className={styles.moveToButton}>
										{moveToOption.label}
										<CaretDownOutlined />
									</Button>
								</span>
							</Popover>
						</div>
						<div>
							<div className={styles.zoneHeader}>
								<Paragraph ellipsis={{ rows: menuCollapsed ? 1 : 2 }}>
									<span
										id='whosInsideZoneGroupAndZoneName'
										className={styles.span}
										title={`${selectedZoneGroupName}${selectedZoneGroupName && selectedZoneName ? ' \\ ' : ''}${selectedZoneName}`}>
										<strong>{selectedZoneGroupName}</strong>
										<span>
											{selectedZoneGroupName && selectedZoneName ? ' \\ ' : ''}
											{selectedZoneName}
										</span>
									</span>
								</Paragraph>
								<Button
									id='whosInsideConfigureColumnsButton'
									type='text'
									icon={<SettingFilled />}
									onClick={handleOnOpenColumnConfiguration}
									className={styles.configureButton}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.info}>
					<div
						className={cx({
							[styles.filters]: !menuCollapsed,
							[styles.filtersCollapsed]: menuCollapsed,
						})}>
						<WhosInsideTree
							treeData={zoneGroupTreeRawData}
							menuCollapsed={menuCollapsed}
							zoneSelected={zoneSelected}
							currentEditionAction={currentEditionAction}
							selectedKey={selectedKey}
							setIsLoading={handleSetIsLoadingCallback}
							fetchZones={handleFetchZonesCallback}
							setZoneSelectedZoneName={handleSetZoneSelectedZoneNameCallback}
							setZoneSelectedZoneGroupName={handleSetZoneSelectedZoneGroupNameCallback}
							setSelectedRowKeys={handleSetSelectedRowKeysCallback}
							setZoneSelected={handleSetZoneSelectedCallback}
							setCurrentEditionAction={handleSetCurrentEditionActionCallback}
							setSelectedKey={handleSetSelectedKeyCallback}
						/>
					</div>
					<div className={cx({ [styles.collapseTreeTable]: menuCollapsed }, { [styles.expandedTreeTable]: !menuCollapsed })}>
						{menuCollapsed && <div className={styles.titleBackground}></div>}
						<WhosInsideInfo
							zone={zoneSelected}
							refreshZone={refreshZone}
							isEditMode={isEditMode}
							credentialChanges={credentialChanges}
							storedZoneGroupTree={zoneGroupTreeRawDataReference.current}
							isTreeNotUpdated={hideEmptyZonesReference.current || !autoRefreshReference.current}
							selectedRowKeys={selectedRowKeys}
							currentPage={currentPage}
							validateSelectedRowKeysValue={validateSelectedRowKeys}
							handleActionScope={handleActionScope}
							setSelectedRowKeys={handleSetSelectedRowKeysCallback}
							resetCredentialChanges={handleResetCredentialChangesCallback}
							setCurrentPage={handleSetCurrentPageCallback}
							resetValidateSelectedRowKeys={handleValidateSelectedRowKeysCallback}
						/>
					</div>
				</div>
				{displayColumnConfiguration && (
					<ColumnConfiguration
						onHideModal={handleHideModalCallback}
						loadColumnConfiguration={loadColumnConfigurationCallback}
						dispatchAction={dispatchActionCallback}
						securedComponent={SecuredComponents.Whos_Inside}
					/>
				)}
				{displayPreview && (
					<PreviewBadgeCredential onHideModal={handleOnHidePreviewBadgeModal} credentialsIds={credentialsIds} isWhosInsideFeature={true} />
				)}
			</div>
		</Spin>
	);
};

export { WhosInside };

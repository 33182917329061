import { InboxOutlined } from '@ant-design/icons';
import { Button, Form, Input, Spin, Upload, UploadProps } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';
import { batch } from 'react-redux';
import { getEnrollmentUploadProps, handleResponse } from '../../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../../api';
import { ImageResolution } from '../../../../../../../model/CommonModel';
import { ScrambleFactorImage, ScrambleFactorImageAction, ScrambleFactorImageCategories } from '../../../../../../../model/DeviceAdminModel';
import { Logger } from '../../../../../../../model/LoggingModel';
import { Modal, ModalCropImage } from '../../../../../../common';
import { isImageNameUniqueInLocalCategories } from '../ScrambleFactor';
import styles from './ImportImageModal.module.scss';

const supportedImageTypes: string[] = ['.jpg', '.jpeg'];

type Props = {
	scrambleFactorImageCategories: ScrambleFactorImageCategories;
	scrambleFactorCategories: ScrambleFactorImageCategories[];
	hideModal: () => void;
	handleModal?: (value: boolean) => void;
	showNotificationResponseError: (msg?: string) => void;
	addScrambleFactorImage: (categoryId: number, newImage: ScrambleFactorImage) => void;
};

const ImportImageModal: React.FC<Props> = ({
	hideModal,
	handleModal,
	scrambleFactorImageCategories: { Height, Width, ID, Name, Description },
	scrambleFactorCategories,
	showNotificationResponseError,
	addScrambleFactorImage,
}) => {
	const { Dragger } = Upload;
	const [showModalCrop, setShowModalCrop] = useState<boolean>(false);
	const [imgSource, setImgSource] = useState<string>('');
	const [imgName, setImgName] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [imgSourceError, setImgSourceError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [showResolutionErrorModal, setShowResolutionErrorModal] = useState<boolean>(false);
	const [imgResolutionErrorMessage, setImgResolutionErrorMessage] = useState<string>('');

	const handleOnOk = () => {
		setLoading(true);
		batch(async () => {
			if (!imgName || !imgSource) {
				showNotificationResponseError(_('PleaseFillAllTheFields'));
				if (!imgName) {
					setErrorMessage(_('NameMustBeFilled'));
				} else {
					setErrorMessage('');
				}
				if (!imgSource) {
					setImgSourceError(true);
				} else {
					setImgSourceError(false);
				}
				setLoading(false);
			} else {
				handleModal(true);
				if (!isImageNameUniqueInLocalCategories(scrambleFactorCategories, imgName)) {
					showNotificationResponseError(_('ScrambleImageNameInUse').replace('%1', imgName));
				} else {
					deviceAdminApi
						.uniqueNameCheck(imgName)
						.then(response => {
							if (!handleResponse(response)) {
								if (response.Entity) {
									const imageId = getGuid();
									const scrambleFactorPayload: ScrambleFactorImage = {
										AddGUID: imageId,
										ID: imageId,
										CanBeDeleted: false,
										CategoryID: ID,
										Description,
										Image: imgSource,
										Name: imgName,
										Selected: false,
										Action: ScrambleFactorImageAction.Add,
									};
									addScrambleFactorImage(ID, scrambleFactorPayload);
									hideModal();
								} else {
									showNotificationResponseError(_('ScrambleImageNameInUse').replace('%1', imgName));
								}
							}
						})
						.catch(err => {
							Logger.writeErrorLog(`${err.name}: ${err.message}`);
						})
						.finally(() => setLoading(false));
				}
			}
		});
	};

	const imgResolutionParameters: ImageResolution = {
		width: Width,
		height: Height,
		description: Name,
		callback: (message: string) => {
			batch(() => {
				setImgResolutionErrorMessage(message);
				setShowResolutionErrorModal(true);
			});
		},
	};

	const draggerUploadProps: UploadProps<string> = getEnrollmentUploadProps(
		(source: string) => {
			setImgSource(source);
			setShowModalCrop(true);
		},
		supportedImageTypes,
		imgResolutionParameters
	);

	const handleOnCropImg = (source: string) => {
		batch(() => {
			setImgSource(source);
			setShowModalCrop(false);
		});
	};

	const handleOnCancelCropImg = () => {
		batch(() => {
			setImgSource('');
			setShowModalCrop(false);
		});
	};

	const handleOnCancelResError = () => {
		batch(() => {
			setShowResolutionErrorModal(false);
			setImgResolutionErrorMessage('');
		});
	};

	return (
		<Modal
			footer={[
				<Button id='importImageSaveButton' disabled={loading} type='primary' key='save' onClick={handleOnOk} loading={loading}>
					{_('Ok')}
				</Button>,
				<Button id='importImageCancelButton' disabled={loading} key='cancel' onClick={hideModal}>
					{_('Cancel')}
				</Button>,
			]}
			onCancel={hideModal}
			visible={true}
			title={_('ScrambleFactorImageImport')}
			width='40vw'
			customZoomClass={styles.importModal}
			nestedModal={true}>
			<Spin tip={_('Loading')} spinning={loading}>
				<div className={styles.imgContainer}>
					<section className={styles.imgInfo}>
						<p className={styles.scSectionTitle}>{Name}</p>
						<p>{_('PleaseImportNameDisplay').replace('%1', Name)}</p>
						<p>{_('NoLargerThan').replace('%1', Width.toString()).replace('%2', Height.toString())}</p>

						<Form.Item name='imgName'>
							<label htmlFor='imageNameInput'>{_('NameOfImage')}</label>
							<Input id='imageNameInput' name='imgName' maxLength={32} onChange={ev => setImgName(ev.target.value)} />
							{errorMessage.length > 0 && (
								<span>
									<strong className={styles.errorMessage}>{errorMessage}</strong>
								</span>
							)}
						</Form.Item>
					</section>
					<Dragger className={cx(styles.imgDragger, { [styles.imgErrorBorder]: imgSourceError })} {...draggerUploadProps}>
						{imgSource && (
							<div className={styles.scCurrentImage}>
								<p>{_('CurrentImage')}</p>
								<img src={`data:image/JPG;base64,${imgSource}`} alt='addedImage' className={styles.imgImgSource} />
							</div>
						)}
						<InboxOutlined className={styles.imgIcon} />
						<h1>{_('DragAndDropFile')}</h1>
					</Dragger>
				</div>
				{showModalCrop && (
					<ModalCropImage
						imageSourceParam={imgSource}
						optionalImgHeight={Height}
						optionalImgWidth={Width}
						onClose={() => handleOnCancelCropImg()}
						onSave={source => handleOnCropImg(source)}
						useCamera={false}
						uploadFormats={supportedImageTypes}
					/>
				)}
				{showResolutionErrorModal && (
					<Modal
						footer={[
							<Button id='resolutionErrorModalButton' disabled={loading} type='primary' key='acknowledgeError' onClick={handleOnCancelResError}>
								{_('Ok')}
							</Button>,
						]}
						title={_('ResolutionTooSmall')}
						visible={true}
						onCancel={handleOnCancelResError}
						customZoomClass={styles.resErrorModal}>
						<p>{imgResolutionErrorMessage}</p>
					</Modal>
				)}
			</Spin>
		</Modal>
	);
};

export { ImportImageModal };

import { createSelector } from 'reselect';
import { ControllerACBSetup, ControllerType, GenericControlTimeZones, SelectTimeZone } from '../../../../../../model/DeviceAdminModel';
import { RootReducer } from '../../../../../../store/rootReducer';

export const alarmByIdDataSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerACBSetupById,
	(controllerACBSetupById: number[]) => [...controllerACBSetupById]
);

export const alarmsDataSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerACBSetup,
	(controllerACBSetup: { [index: string]: ControllerACBSetup }) => controllerACBSetup
);

export const controlZonesSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ControlZones,
	(controlZones: GenericControlTimeZones[]) => [...controlZones]
);

export const timeZonesSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ACBTimeZones,
	(aCBTimeZones: SelectTimeZone[]) => [...aCBTimeZones]
);

export const controllerTimeZoneMapSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerTimeZoneMap,
	(controllerTimeZoneMap: number[]) => [...controllerTimeZoneMap]
);

export const controllerIdSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerId,
	(controllerId: number) => controllerId
);

export const controllerTypeSelector = createSelector(
	(state: RootReducer) => state.controllerReducer.ControllerBasicSetup.ControllerType,
	(controllerType: ControllerType) => controllerType
);

export const selectorEditionMode = createSelector(
	(state: RootReducer) => state.controlZonesReducer.editMode,
	(editMode: boolean) => editMode
);

import { Button, Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { handleResponse } from '../../../../../Helper';
import { deviceAdminApi } from '../../../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../../model/AccountModel';
import { DeviceObjectType } from '../../../../../model/DeviceAdminModel';
import { Modal } from '../../../../common/Modal/Modal';
import styles from './addRolesModal.module.scss';

type Props = {
	isCopyMode: boolean;
	copyFromRoleId?: number;
	onConfirm: () => void;
	onCancel: () => void;
};

const { TextArea } = Input;
const deviceObjectType: DeviceObjectType = DeviceObjectType.Roles;

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const roleNameInputId = 'roleNameInput';
const roleDescriptionInputId = 'roleDescriptionInput';

const AddRolesModal: React.FC<Props> = ({ isCopyMode, copyFromRoleId, onConfirm, onCancel }) => {
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [createWithAllPermissions, setCreateWithAllPermissions] = useState<boolean>(false);
	const [viewEmptyNameError, setViewEmptyNameError] = useState<boolean>(false);
	const [emptyNameError, setEmptyNameError] = useState<string>('');
	const [copyName, setCopyName] = useState<string>('');

	useEffect(() => {
		deviceAdminApi.getErrorMessages(SecuredComponents.Roles).then(response => setEmptyNameError(response.EmptyName));
		if (isCopyMode) {
			deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, [copyFromRoleId.toString()]).then(response => {
				if (!handleResponse(response) && response.Entity?.length) {
					setCopyName(response.Entity[0].Name);
				}
			});
		}
	}, []);

	const handleOnClickSave = () => {
		if (!name || name.trim() === '') {
			setViewEmptyNameError(true);
			return;
		}

		setViewEmptyNameError(false);
		if (isCopyMode) {
			deviceAdminApi.copyRole(copyFromRoleId, name, description).then(response => {
				if (!handleResponse(response)) {
					onConfirm();
				}
			});
		} else {
			deviceAdminApi.createNewRole(name, description, createWithAllPermissions).then(response => {
				if (!handleResponse(response)) {
					onConfirm();
				}
			});
		}
	};

	return (
		<Modal
			footer={[
				<Button
					id='roleSaveButton'
					title={getPermissionErrorMessage(user.isAdmin)}
					key='save'
					type='primary'
					disabled={!user.isAdmin}
					onClick={handleOnClickSave}>
					{_('SaveChanges')}
				</Button>,
				<Button id='roleCancelButton' key='cancel' onClick={onCancel}>
					{_('Cancel')}
				</Button>,
			]}
			visible
			title={isCopyMode ? `${_('CopyRole')} (${copyName})` : _('AddNewRole')}
			onCancel={onCancel}
			customZoomClass={styles.WithModalZoom}>
			<div className={styles.sections}>
				<label id='roleNameLabel' htmlFor={roleNameInputId}>
					{_('Name')}
				</label>
				<Input
					id={roleNameInputId}
					key='name'
					value={name}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setName(e.target.value);
					}}
					maxLength={32}
					className={cx(styles.input, {
						[styles.error]: viewEmptyNameError,
					})}
				/>
			</div>
			{viewEmptyNameError && <p className={styles.errorMessage}>{emptyNameError}</p>}
			<div className={styles.sections}>
				<label id='roleDescriptionLabel' htmlFor={roleDescriptionInputId}>
					{_('Description')}
				</label>
				<TextArea
					id={roleDescriptionInputId}
					className={styles.inputWithBottom}
					value={description}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
						setDescription(e.target.value);
					}}
					maxLength={125}
					rows={4}
				/>
			</div>
			{!isCopyMode && (
				<Checkbox
					className={styles.checkbox}
					checked={createWithAllPermissions}
					onChange={(e: CheckboxChangeEvent) => {
						setCreateWithAllPermissions(e.target.checked);
					}}>
					<p className={styles.checkboxContent}>{_('CreateWithPermissionsTurnedOn')}</p>
				</Checkbox>
			)}
		</Modal>
	);
};

export { AddRolesModal };

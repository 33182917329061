import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext, useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../../api';
import { FascnHandling, MatchAlgorithm, OSDPLEDOption, ReaderInterface } from '../../../../../../../../model/DeviceAdminModel';
import { InformationMessages, ModalConfirmation } from '../../../../../../../common';
import { NotificationStatus } from '../../../../../../../common/NotificationStatus/NotificationStatus';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { OSDPColorsModal } from '../../../../OSDPColorsModal/OSDPColorsModal';
import {
	setExitChangedOSDPLEDColor,
	setExitOSDPLEDOptions,
	setExitReaderCorporate1000,
	setExitReaderEnableKeyPad,
	setExitReaderFacilityCodeCardNumber,
	setExitReaderFascnHandling,
	setExitReaderHexDigitUUID,
	setExitReaderMatchAlgorithm,
	setExitUseCustomOSDPLEDColor,
} from '../../../exitReaderContext';
import styles from '../../CardReaderSetup/cardreadersetup.module.scss';

type Props = {
	isOnboardWiegand: boolean;
};

//Avoid creating object style inline, since increases reconciliations
const style: React.CSSProperties = { color: '#faad14' };

const RS485CardReaderInterface: React.FC<Props> = ({ isOnboardWiegand }) => {
	const {
		contextStateDoor: {
			exitReader: {
				matchAlgorithm,
				facilityCodeCardNumber,
				corporate1000,
				hexDigitUUID,
				oSDPConfirmation,
				oSDPTitle,
				id,
				controllerId,
				enableKeyPad,
				enableOSDPHandshake,
				fascnHandling,
				readerInterface,
				isDoorWireless,
				osdpCustomLEDColors,
				osdpLEDOptions,
				useCustomOSDPLEDColor,
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [fieldsetLabel, setFieldsetLabel] = useState('');
	const [showOSDPColorModal, setShowOSDPColorModal] = useState<boolean>(false);

	useEffect(() => {
		if (isOnboardWiegand) {
			setFieldsetLabel(_('OnboardWiegandInterface'));
		} else {
			setFieldsetLabel(_('RS485OSDPInterface'));
		}
	}, [isOnboardWiegand]);

	const initiateHandshake = () => {
		deviceAdminApi.initiateOSDPHandshake(id, controllerId).then(res => {
			NotificationStatus({
				responseData: res,
				onSuccessCallback: () => {},
			});
		});
	};

	const handleOnClickOSDPHandshake = () => {
		const messages: string[] = oSDPConfirmation.split('\n');
		const content: JSX.Element = <InformationMessages messages={messages} />;

		ModalConfirmation({
			title: oSDPTitle,
			content: content,
			onConfirm: () => initiateHandshake(),
			okText: _('Ok'),
			cancelText: _('Cancel'),
			width: '700px',
			icon: <ExclamationCircleOutlined style={style} />,
		});
	};

	const handleOnSetMatchAlgorithm = (e: RadioChangeEvent) => {
		dispatcherDoor(setExitReaderMatchAlgorithm(e.target.value));
	};

	const handleOnSetFascnHandling = (e: RadioChangeEvent) => {
		dispatcherDoor(setExitReaderFascnHandling(e.target.value));
	};

	const handleOnSetFacilityCodeCardNumber = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderFacilityCodeCardNumber(value.target.checked));
	};
	const handleOnSetCorporate = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderCorporate1000(value.target.checked));
	};

	const handleOnSetHexDigitUUID = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderHexDigitUUID(value.target.checked));
	};

	const handleOnSetEnableKeyPad = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderEnableKeyPad(value.target.checked));
	};

	const handleOnSetOSDPLEDOptions = (value: OSDPLEDOption[]) => {
		dispatcherDoor(setExitOSDPLEDOptions(value));
	};

	const handleOnSetUseCustomOSDPLEDColor = (value: boolean) => {
		dispatcherDoor(setExitUseCustomOSDPLEDColor(value));
	};

	const handleOnSetChangedOSDPLEDColor = (value: boolean) => {
		dispatcherDoor(setExitChangedOSDPLEDColor(value));
	};

	return (
		<div className={styles.cardReaderInterface}>
			<span className={styles.interfaceTitle}>{fieldsetLabel}</span>
			<Divider className={styles.divider} />
			<div className={styles.doubleColumn}>
				<div className={styles.radioSectionContainer}>
					<fieldset>
						<legend className={styles.legend}>{_('MatchAlgorithm')}</legend>
						<Radio.Group defaultValue={matchAlgorithm} onChange={handleOnSetMatchAlgorithm}>
							<Radio value={MatchAlgorithm.Disable} id='exitReaderDisableMatchAlgorithmRadio'>
								{_('DisableMatchAlgorithm')}
							</Radio>
							<Radio value={MatchAlgorithm.StandardWiegandP} id='exitReaderDisableMatchAlgorithmRadio'>
								{_('StandardWiegandP')}
							</Radio>
							<Radio value={MatchAlgorithm.StandardWiegandNP} id='exitReaderStandardWiegandPRadio'>
								{_('StandardWiegandNP')}
							</Radio>
							<Radio value={MatchAlgorithm.OctalPassThroughP} id='exitReaderOctalPassThroughPRadio'>
								{_('OctalPassThroughP')}
							</Radio>
							<Radio value={MatchAlgorithm.OctalPassThroughNP} id='exitReaderOctalPassThroughNPRadio'>
								{_('OctalPassThroughNP')}
							</Radio>
							<Radio value={MatchAlgorithm.OctalPassThroughDP} id='exitReaderOctalPassThroughDPRadio'>
								{_('OctalPassThroughDP')}
							</Radio>
							<Radio value={MatchAlgorithm.DecimalSerialNumber} id='exitReaderDecimalSerialNumberRadio'>
								{_('DecimalSerialNumber')}
							</Radio>
							<Radio value={MatchAlgorithm.HexPassThrough} id='exitReaderHexPassThroughRadio'>
								{_('HexPassThrough')}
							</Radio>
							<Radio value={MatchAlgorithm.BinaryTestMode} id='exitReaderBinaryTestModeRadio'>
								{_('BinaryTestMode')}
							</Radio>
						</Radio.Group>
					</fieldset>
				</div>
				<div>
					<div className={styles.sectionContainer}>
						<fieldset>
							<legend className={styles.legend}>{_('FixedBitLength')}</legend>
							<div>
								<Checkbox checked={facilityCodeCardNumber} onChange={handleOnSetFacilityCodeCardNumber} id='exitReaderFacilityCode'>
									<span>{_('FacilityCode')}</span>
								</Checkbox>
								<Checkbox checked={corporate1000} onChange={handleOnSetCorporate} id='exitReaderCorporate'>
									<span>{_('Corporate1000')}</span>
								</Checkbox>
								<Checkbox checked={hexDigitUUID} onChange={handleOnSetHexDigitUUID} id='exitReaderHexDigit'>
									<span>{_('PivUUID')}</span>
								</Checkbox>
							</div>
						</fieldset>
					</div>
					<div className={styles.radioSectionContainer}>
						<fieldset className={styles.pivCard}>
							<legend className={styles.legend}>{_('PivCardFascn')}</legend>
							<Radio.Group defaultValue={fascnHandling} onChange={handleOnSetFascnHandling}>
								<Radio value={FascnHandling.Disable} id='exitReaderModalDisableFascnHandlingRadio'>
									{_('DisableFascnHandling')}
								</Radio>
								<Radio value={FascnHandling.Piv64In16} id='exitReaderModalPiv64In16Radio'>
									{_('Piv64In16')}
								</Radio>
								<Radio value={FascnHandling.Piv75In14} id='exitReaderModalPiv75In14Radio'>
									{_('Piv75In14')}
								</Radio>
								<Radio value={FascnHandling.Piv75In16} id='exitReaderModalPiv75In16Radio'>
									{_('Piv75In16')}
								</Radio>
								<Radio value={FascnHandling.Piv75In22} id='exitReaderModalPiv75In22Radio'>
									{_('Piv75In22')}
								</Radio>
								<Radio value={FascnHandling.Piv200In32} id='exitReaderModalPiv200In32Radio'>
									{_('Piv200In32')}
								</Radio>
								<Radio value={FascnHandling.Piv128In32} id='exitReaderModalPiv128In32Radio'>
									{_('Piv128In32')}
								</Radio>
							</Radio.Group>
						</fieldset>
					</div>
				</div>
			</div>
			<div className={styles.enableKeypad}>
				<Checkbox checked={enableKeyPad} onChange={handleOnSetEnableKeyPad} id='exitReaderEnableKeyPad'>
					<span>{_('EnableKeypad')}</span>
				</Checkbox>
			</div>
			<div className={styles.osdpButtons}>
				{readerInterface === ReaderInterface.RS485 && (
					<>
						{!isDoorWireless && (
							<Button id='exitReaderOSDPHandshakeButton' type='primary' onClick={handleOnClickOSDPHandshake} disabled={!enableOSDPHandshake}>
								{_('InitiateSecureOSDPConnection')}
							</Button>
						)}
						<Button id='exitReaderCustomizeLEDColorsButton' type='primary' onClick={() => setShowOSDPColorModal(true)}>
							{_('CustomizeLEDColors')}
						</Button>
					</>
				)}
			</div>
			{showOSDPColorModal && (
				<OSDPColorsModal
					colorOptions={osdpCustomLEDColors}
					hideModal={() => setShowOSDPColorModal(false)}
					osdpLEDOptions={osdpLEDOptions}
					readerId={id}
					setChangedOSDPLEDColor={handleOnSetChangedOSDPLEDColor}
					setOSDPLEDOptions={handleOnSetOSDPLEDOptions}
					setUseCustomOSDPLEDColor={handleOnSetUseCustomOSDPLEDColor}
					useCustomColor={useCustomOSDPLEDColor}
				/>
			)}
		</div>
	);
};

export { RS485CardReaderInterface };

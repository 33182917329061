// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-column-container--3Eu_erqoUjCFCWXpAWTFBI{padding:8px}.search-column-container--3Eu_erqoUjCFCWXpAWTFBI input{width:188px;margin-bottom:8px;display:block;height:32px}.search-column-container--3Eu_erqoUjCFCWXpAWTFBI button{width:90px}", "",{"version":3,"sources":["webpack://src/components/common/SearchColumn/searchColumn.module.scss"],"names":[],"mappings":"AAAA,iDACC,WAAA,CACA,uDACC,WAAA,CACA,iBAAA,CACA,aAAA,CACA,WAAA,CAED,wDACC,UAAA","sourcesContent":[".container {\r\n\tpadding: 8px;\r\n\tinput {\r\n\t\twidth: 188px;\r\n\t\tmargin-bottom: 8px;\r\n\t\tdisplay: block;\r\n\t\theight: 32px;\r\n\t}\r\n\tbutton {\r\n\t\twidth: 90px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "search-column-container--3Eu_erqoUjCFCWXpAWTFBI"
};
export default ___CSS_LOADER_EXPORT___;

import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, notification, Table } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { deviceAdminApi } from '../../../../../api';
import {
	buildActionColumn,
	buildColumn,
	ColumnsProps,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getPageNumberSelectedItem,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handlePageChanged,
	handleResponse,
	invertSelectedRowKeys,
	ScrollType,
	timeZoneLockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../../Helper';
import { BaseColumns, PaginationSetting, ResponseObject, ResponseStatusCode, SelectOptions, SorterConfig } from '../../../../../model/CommonModel';
import {
	DeviceObjectType,
	OptionsButtonBuilder,
	SelectedRowKeyPagination,
	StandardTimeZone,
	TimeZoneType,
	UseModel,
	VelocityConfigurationModel,
} from '../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../store';
import { setCurrentModalTimeZone, setItemSearchedPagination, setTotalItemsPagination } from '../../../../../store/common/actions';
import { CommonState } from '../../../../../store/common/reducer';
import {
	clearVelocityConfigurationSelectionsAction,
	deleteTimeZoneStandard,
	setStandardTimeZonesBy,
	setStandardTimeZonesByData,
	setVelocityActionScopeKey,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
} from '../../../../../store/deviceControl/actions';
import { RootReducer } from '../../../../../store/rootReducer';
import { ModalConfirmationList, SearchColumn } from '../../../../common';
import { EditableCell } from '../../../../common/EditableCell/EditableCell';
import styles from './timeZonesStandard.module.scss';

type Props = {
	data: StandardTimeZone[];
	options: OptionsButtonBuilder<string>;
	actionScopeKey: string;
	selectedRowKeysPagination: SelectedRowKeyPagination[];
	currentPage: number;
	shouldResetSearchColumn: boolean;
	redirectPage: boolean;
	currentPageSize: number;
	sorterConfig: SorterConfig;
	selectedRowKeys: React.Key[];
	setSelectedRowKeys: (value: React.Key[]) => void;
	setRedirectPage: (value: boolean) => void;
	setSelectedRowKeysPagination: (value: SelectedRowKeyPagination[]) => void;
	setCurrentPage: (value: number) => void;
	setIsItemOnSamePage: (value: boolean) => void;
	onShowAlert: (records: UseModel[], title: string, timeZoneType: TimeZoneType) => void;
	setShouldResetSearchColumn: (value: boolean) => void;
	setCurrentPageSize: (value: number) => void;
	setSorterConfig: (value: SorterConfig) => void;
};

export type StandardTimeZoneColumns = {
	Action: React.ReactNode;
	Name: string;
	Start: string;
	End: string;
	Monday: React.ReactNode;
	Tuesday: React.ReactNode;
	Wednesday: React.ReactNode;
	Thursday: React.ReactNode;
	Friday: React.ReactNode;
	Saturday: React.ReactNode;
	Sunday: React.ReactNode;
	Holiday: React.ReactNode;
	Holiday1: React.ReactNode;
	Holiday2: React.ReactNode;
	Holiday3: React.ReactNode;
	Holiday4: React.ReactNode;
	InUse: boolean;
} & BaseColumns;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: 379, y: 370 };
const maxLength: number = 50;

const TimeZonesStandard: React.FC<Props> = ({
	data,
	actionScopeKey,
	options,
	selectedRowKeysPagination,
	currentPage,
	shouldResetSearchColumn,
	redirectPage,
	currentPageSize,
	sorterConfig,
	selectedRowKeys,
	setSelectedRowKeys,
	setSelectedRowKeysPagination,
	setCurrentPage,
	setIsItemOnSamePage,
	onShowAlert,
	setShouldResetSearchColumn,
	setRedirectPage,
	setCurrentPageSize,
	setSorterConfig,
}) => {
	const dispatch = useStoreDispatch();
	const { isEditMode, isFilterMode }: VelocityConfigurationModel = useStoreSelector(
		(state: RootReducer): VelocityConfigurationModel => state.deviceControlReducer.velocityConfiguration
	);
	const { totalItemsPagination, itemSearchedPagination, allItemsPagination }: CommonState = useStoreSelector(
		(state: RootReducer): CommonState => state.commonReducer
	);
	const [tableData, setTableData] = useState<StandardTimeZoneColumns[]>([]);
	const [standardTimeZoneSelected, setStandardTimeZoneSelected] = useState<StandardTimeZone>();

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [editingKey, setEditingKey] = useState('');
	const [form] = Form.useForm();
	const isSearchPerformed = useRef<boolean>(false);
	const tablePaginationSetting: PaginationSetting = useMemo(
		() => ({
			PageNumber: currentPage,
			PageSize: currentPageSize,
			SortDirection: sorterConfig.direction,
			SortField: sorterConfig.field,
		}),
		[currentPage, currentPageSize, JSON.stringify(sorterConfig)]
	);

	useEffect(() => {
		const mapRes: StandardTimeZoneColumns[] = createData();
		setTableData(mapRes);
		getPreviousSelectedRowKeys();
	}, [JSON.stringify(data)]);

	useEffect(() => {
		if (actionScopeKey !== '') {
			handleActionScope(actionScopeKey).then(res => dispatch(setVelocityActionScopeKey('')));
		}
	}, [actionScopeKey]);

	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage) {
			setRedirectPage(false);
			const stdTimeZoneKeySelected = selectedRowKeys?.[0];
			getStandardTimeZoneSelected(stdTimeZoneKeySelected);
		}
	}, [JSON.stringify(tableData)]);

	useEffect(() => {
		if (standardTimeZoneSelected) {
			const selectedStdTimezonePage = getPageNumberSelectedItem(
				allItemsPagination.map(x => x),
				standardTimeZoneSelected?.Id.toString(),
				pagination.pageSize
			);

			handlePageChanged(
				selectedStdTimezonePage,
				currentPage,
				() => setSelectedRowKeysPagination([{ Id: standardTimeZoneSelected.Id.toString(), PaginationPage: selectedStdTimezonePage }]),
				setCurrentPage,
				setIsItemOnSamePage
			);

			const newConfig: PaginationSetting = {
				...tablePaginationSetting,
				PageNumber: selectedStdTimezonePage,
				SearchedValue: itemSearchedPagination,
			};

			dispatch(setStandardTimeZonesBy(newConfig));
		}
	}, [JSON.stringify(standardTimeZoneSelected)]);

	useEffect(() => {
		return () => {
			clearSelection();
			clearPagination();
		};
	}, []);

	const clearPagination = () => {
		dispatch(setTotalItemsPagination(0));
		dispatch(setItemSearchedPagination(''));
	};

	const clearSelection = () => {
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: DeviceObjectType.TimeZones, subObjectType: 'standard' }));
		setSelectedRowKeys([]);
		setStandardTimeZoneSelected(undefined);
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const getStandardTimeZoneSelected = async (key: React.Key) => {
		const standardTimeZoneId = Number(key);

		const standardTimeZoneSelected = data.find(timeZone => timeZone.Id === standardTimeZoneId);

		const standardTimeZone = standardTimeZoneSelected ? standardTimeZoneSelected : await fetchStandardTimeZone(standardTimeZoneId);
		setStandardTimeZoneSelected(standardTimeZone);
	};

	const fetchStandardTimeZone = async (standardTimeZoneId: number): Promise<StandardTimeZone> => {
		const { Entity: standardTimeZone } = await deviceAdminApi.getStandardTimeZone(standardTimeZoneId);

		return standardTimeZone;
	};

	const onDeleteTimezoneRequest = async (timeZone: StandardTimeZone): Promise<void> => {
		const id = timeZone.Id;
		const response = await deviceAdminApi.deleteStandardTimeZone(id);
		switch (response.ResponseStatusCode) {
			case ResponseStatusCode.FailedValidation:
				onShowAlert(response.InUseByList, _('TheFollowingDependenciesWhereFoundTimeZone').replace('%1', timeZone.Name), TimeZoneType.Standard);
				break;
			case ResponseStatusCode.Success:
				dispatch(deleteTimeZoneStandard(id));
				break;
			default:
				handleResponse(response);
				break;
		}
	};

	const handleOkConfirmation = async (timeZonesSelected: StandardTimeZone[]) => {
		for (const timeZone of timeZonesSelected) {
			await onDeleteTimezoneRequest(timeZone);
		}
		clearSelection();
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setCurrentPage(page);
		setIsItemOnSamePage(isSamePage);
		if (pageSize !== currentPageSize) {
			clearSelection();
			setCurrentPageSize(pageSize);
		}
	};

	const disabled: boolean = editingKey !== '';
	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		currentPage,
		currentPageSize,
		totalItemsPagination,
		undefined,
		selectedRowKeys
	);
	const isPaginationItemSelected: boolean = selectedRowKeys.length !== 1;

	let columns: ColumnsProps<StandardTimeZoneColumns>[] = [
		{
			...buildColumn(_('Name'), 'Name', '300px', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: undefined,
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection: () => clearSelection(),
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => {
					const newConfig: PaginationSetting = {
						...tablePaginationSetting,
						PageNumber: 1,
						SearchedValue: searchedValue,
					};
					dispatch(setStandardTimeZonesBy(newConfig));
				},
				setSearchedValue: (searchedValue: string) => dispatch(setItemSearchedPagination(searchedValue)),
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
			render: (text, record) => {
				const obj = {
					children: <label id={`standardTimeZoneName-${record.key}`}>{text}</label>,
					props: { className: Number(record.key) <= 2 ? styles.systemTimeZone : record.InUse ? styles.inUse : styles.notReferenced },
				};

				return obj;
			},
		},
		{
			...buildColumn(_('Start'), 'Start', '60px', 'start'),
		},
		{
			...buildColumn(_('End'), 'End', '60px', 'start'),
		},
		{
			...buildColumn('M', 'Monday', '40px', 'start'),
		},
		{
			...buildColumn('T', 'Tuesday', '40px', 'start'),
		},
		{
			...buildColumn('W', 'Wednesday', '40px', 'start'),
		},
		{
			...buildColumn('T', 'Thursday', '40px', 'start'),
		},
		{
			...buildColumn('F', 'Friday', '40px', 'start'),
		},
		{
			...buildColumn('S', 'Saturday', '40px', 'start'),
		},
		{
			...buildColumn('S', 'Sunday', '40px', 'start'),
		},
		{
			...buildColumn('H', 'Holiday', '40px', 'start'),
		},
		{
			...buildColumn('1', 'Holiday1', '40px', 'start'),
		},
		{
			...buildColumn('2', 'Holiday2', '40px', 'start'),
		},
		{
			...buildColumn('3', 'Holiday3', '40px', 'start'),
		},
		{
			...buildColumn('4', 'Holiday4', '40px', 'start'),
		},
	];
	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const onChangeSelection = (key: StandardTimeZoneColumns) => {
		const stdTimeZoneIdSelected = key.key;
		if (selectedRowKeys.findIndex(timeZoneId => timeZoneId === stdTimeZoneIdSelected) === -1) {
			const cloneStateProps: StandardTimeZone[] = data.map(x =>
				x.Id.toString() === stdTimeZoneIdSelected ? { ...x, checked: true } : { ...x, checked: false }
			);
			dispatch(setStandardTimeZonesByData(cloneStateProps));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: stdTimeZoneIdSelected,
				PaginationPage: currentPage,
			};
			setIsItemOnSamePage(true);
			setSelectedRowKeysPagination([{ ...selectedKeysPagination }]);
			setSelectedRowKeys([stdTimeZoneIdSelected]);
		}
	};

	const isEditing = (record: StandardTimeZoneColumns) => record.key.toString() === editingKey;
	const contextMenuOptions: SelectOptions<string>[] = [...options.options];

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: StandardTimeZoneColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: Number(record.key) >= 3 ? contextMenuOptions : undefined,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: StandardTimeZoneColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: Number(record.key) >= 3 ? contextMenuOptions : undefined,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const getStandardTimeZonesSelected = async (): Promise<StandardTimeZone[]> => {
		const { Entity: standardTimeZones } = await deviceAdminApi.retrieveStandardTimeZones();

		const standardTimeZonesSelected = standardTimeZones.filter(stdTimeZone => selectedRowKeys.findIndex(k => k === stdTimeZone.Id?.toString()) !== -1);

		return standardTimeZonesSelected;
	};

	const handleActionScope = async (key: string) => {
		setRedirectPage(false);

		if (key === 'delete') {
			const timeZonesSelected = await getStandardTimeZonesSelected();
			ModalConfirmationList({
				type: _('StandardTimeZone'),
				data: timeZonesSelected,
				onConfirm: () => handleOkConfirmation(timeZonesSelected),
			});

			return;
		}

		const standardTimeZoneIdSelected = Number(selectedRowKeys?.[0]);
		const stdTimeZoneSelected = data.find(timeZone => timeZone.Id === standardTimeZoneIdSelected);
		const timeZone: StandardTimeZone = stdTimeZoneSelected ? stdTimeZoneSelected : await fetchStandardTimeZone(standardTimeZoneIdSelected);
		const lockComponentCondition: boolean = key === 'edit';
		const isLocked: boolean = await timeZoneLockedValidation(TimeZoneType.Standard, lockComponentCondition);

		if (isLocked) {
			return;
		}

		switch (key) {
			case 'rename':
				const cloneState: StandardTimeZone[] = changeStateProps(timeZone.Id, { editable: true });
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: StandardTimeZoneColumns = tableData.find(x => x.key === timeZone.Id.toString());
				edit(findKey);
				dispatch(setStandardTimeZonesByData(cloneState));
				break;

			case 'edit':
				dispatch(setCurrentModalTimeZone({ entity: timeZone, timeZoneType: TimeZoneType.Standard }));
				break;
		}
	};

	const changeStateProps = (deviceId: number, timezone: Partial<StandardTimeZone>): StandardTimeZone[] => {
		const cloneState: StandardTimeZone[] = [...data];
		const index = cloneState.findIndex(x => x.Id === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...timezone });
		}

		return cloneState;
	};

	const edit = (record: StandardTimeZoneColumns) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const handleOnSaveEditRow = async (deviceId: number) => {
		const isLocked: boolean = await timeZoneLockedValidation(TimeZoneType.Standard, false);
		if (isLocked) {
			setEditingKey('');
			const cloneStateProps: StandardTimeZone[] = changeStateProps(deviceId, { editable: false });
			dispatch(setStandardTimeZonesByData(cloneStateProps));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(DeviceObjectType.TimeZones, name.trim(), deviceId, TimeZoneType.Standard);
		const newConfig: PaginationSetting = {
			...tablePaginationSetting,
			SearchedValue: itemSearchedPagination,
		};
		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setStandardTimeZonesBy(newConfig));
			return;
		}

		setEditingKey('');
		setRedirectPage(true);
		dispatch(setStandardTimeZonesBy(newConfig));
		dispatch(setVelocityConfigurationEditMode(false));
		if (!isPaginationItemSelected && isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const handleOnCancelEditRow = (deviceId: number) => {
		const cloneStateProps: StandardTimeZone[] = changeStateProps(deviceId, { editable: false });
		dispatch(setStandardTimeZonesByData(cloneStateProps));
		dispatch(setVelocityConfigurationEditMode(false));
		setEditingKey('');
	};

	const getSelectedRowKeysForSelectAll = (newSelectedRowKeys: React.Key[]): React.Key[] => {
		return getUniqueValuesArray(newSelectedRowKeys, allItemsPagination);
	};

	const handleChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneStateProps: StandardTimeZone[] = data.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			currentPage,
			setIsItemOnSamePage
		);

		dispatch(setStandardTimeZonesByData(cloneStateProps));
		setSelectedRowKeysPagination(selectedKeysPagination);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectAll = () => {
		const newSelectedRowKeys = getSelectedRowKeysForSelectAll(selectedRowKeys).filter(x => Number(x) > 2);

		const cloneStateProps: StandardTimeZone[] = data.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			currentPage,
			setIsItemOnSamePage
		);
		dispatch(setStandardTimeZonesByData(cloneStateProps));
		setSelectedRowKeysPagination(selectedKeysPagination);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectInvert = () => {
		const dataKeys = data.reduce<React.Key[]>((result, item) => {
			if (item.Id > 2) {
				result.push(item.Id?.toString());
			}
			return result;
		}, []);

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);

		const cloneStateProps: StandardTimeZone[] = data.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(key => key === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			currentPage,
			setIsItemOnSamePage
		);
		dispatch(setStandardTimeZonesByData(cloneStateProps));
		setSelectedRowKeysPagination(selectedKeysPagination);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const createActionElement = (standardTimeZone: StandardTimeZone): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (standardTimeZone.editable) {
			const id = standardTimeZone.Id;

			content = (
				<>
					<Button id='renameStandardTimeZoneSaveButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(id)}>
						{_('Save')}
					</Button>
					<Button id='renameStandardTimeZoneCancelButton' key='cancel' onClick={() => handleOnCancelEditRow(id)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): StandardTimeZoneColumns[] => {
		if (data.length > 0) {
			return data.map<StandardTimeZoneColumns>((standardTimeZone: StandardTimeZone) => ({
				key: standardTimeZone.Id?.toString(),
				Action: standardTimeZone.Id >= 3 ? createActionElement(standardTimeZone) : null,
				Name: standardTimeZone.Name,
				Start: standardTimeZone.StartTime,
				End: standardTimeZone.EndTime,
				Monday: standardTimeZone.Monday ? <CheckOutlined /> : null,
				Tuesday: standardTimeZone.Tuesday ? <CheckOutlined /> : null,
				Wednesday: standardTimeZone.Wednesday ? <CheckOutlined /> : null,
				Thursday: standardTimeZone.Thursday ? <CheckOutlined /> : null,
				Friday: standardTimeZone.Friday ? <CheckOutlined /> : null,
				Saturday: standardTimeZone.Saturday ? <CheckOutlined /> : null,
				Sunday: standardTimeZone.Sunday ? <CheckOutlined /> : null,
				Holiday: standardTimeZone.Holidays ? <CheckOutlined /> : null,
				Holiday1: standardTimeZone.HolidayTable1 ? <CheckOutlined /> : null,
				Holiday2: standardTimeZone.HolidayTable2 ? <CheckOutlined /> : null,
				Holiday3: standardTimeZone.HolidayTable3 ? <CheckOutlined /> : null,
				Holiday4: standardTimeZone.HolidayTable4 ? <CheckOutlined /> : null,
				InUse: standardTimeZone.InUse,
			}));
		}

		return [];
	};

	const getPreviousSelectedRowKeys = () => {
		const previousSelectedRowKeys = data.reduce<React.Key[]>((result, item) => {
			if (item.checked) {
				result.push(item.Id.toString());
			}
			return result;
		}, []);

		setSelectedRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedRowKeys));
	};

	const rowSelection: TableRowSelection<StandardTimeZoneColumns> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `standardTimeZonesTableCheckbox-${record.key}`,
			disabled: disabled || Number(record.key) < 3,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: PaginationProps, filters: { Name?: string[] }, sorter) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setStandardTimeZonesBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SearchedValue: itemSearchedPagination,
				})
			);
		}
	};

	return (
		<div id='standardTimeZonesTableContainer' className={styles.container}>
			<Form form={form} component={false}>
				<Table
					id='standardTimeZonesTable'
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					pagination={pagination}
					scroll={scroll}
					size='small'
					className={styles.standardTimeZoneTable}
					rowClassName={(record: StandardTimeZoneColumns, index) => {
						if (Number(record.key) >= 3) {
							return record.InUse ? styles.inUse : styles.notReferenced;
						}

						return styles.systemTimeZone;
					}}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					onHeaderRow={() => ({ id: 'standardTimeZonesTableHeader' })}
					getPopupContainer={() => document.getElementById('standardTimeZonesTableHeader')}
				/>
			</Form>
		</div>
	);
};

export { TimeZonesStandard };

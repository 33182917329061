import { InputNumber } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setDoorDelayTime } from '../../../../contextExpansionRelay';
import styles from '../../../../expansionRelayModal.module.scss';

const DoorDelay: React.FC = () => {
	const {
		contextStateDoor: { relay },
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleOnChangeDoorDelayTime = (value: string) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== '0') {
				return;
			}
		}

		const formattedValue = Math.floor(Number(value) * 4) / 4;

		dispatcherDoor(setDoorDelayTime(formattedValue * 4));
	};

	const delayTime = relay?.doorDelayTime / 4;
	return (
		<div className={styles.generalSection}>
			<span>{_('DoorDelayTime')}</span>
			<div>
				<InputNumber
					key='doorDelayTime'
					min={0}
					max={8100}
					maxLength={6}
					value={delayTime}
					onChange={value => handleOnChangeDoorDelayTime(value.toString())}
					id={'DoorDelayTime'}
					precision={2}
					step={0.25}
					className={cx(styles.input)}
					formatter={value => `${value}`.replace(/[^0-9\.]+/g, '')}
				/>
			</div>
		</div>
	);
};

export { DoorDelay };

import { getPermissionErrorMessage, SubPermissions, User } from '../../model/AccountModel';
import { OptionsButtonBuilder } from '../../model/DeviceAdminModel';
import { ActionToExecute, WhosInsideOptions, ZoneGroupType, ZoneKey } from '../../model/WhosInsideModel';

const user: User = getUser();
const canForgiveUsers: boolean = User.getSubComponentPermission(user, SubPermissions.WhosInside_Permissiontoforgiveusers).allowed;
const canEditZone: boolean = User.getSubComponentPermission(user, SubPermissions.WhosInside_EditZones).allowed;
const canMoveTo: boolean = User.getSubComponentPermission(user, SubPermissions.WhosInside_AssignCredentialstoZone).allowed;

export const createOptions = (onClickOption: (type: ActionToExecute, zoneKey: ZoneKey) => void) => {
	const globalizedOptions = (isLeaf: boolean): WhosInsideOptions[] =>
		!isLeaf
			? [
					{
						id: 'whosInsideForgiveAllUserCredentialsInZoneGroupButton',
						label: _('ForgiveAllUsersCredentialsInZoneGroup'),
						separator: true,
						title: getPermissionErrorMessage(canForgiveUsers),
						disabled: !canForgiveUsers,
						onClick: () => onClickOption(ActionToExecute.ForgiveAllUsersCredentialsInGroup, ZoneKey.WholeGroup),
					},
					{
						id: 'whosInsideEditButton',
						label: _('Edit'),
						title: getPermissionErrorMessage(canEditZone),
						disabled: !canEditZone,
						onClick: () => onClickOption(ActionToExecute.Edit, ZoneKey.Custom),
					},
					{
						id: 'whosInsideRenameButton',
						label: _('Rename'),
						separator: true,
						title: getPermissionErrorMessage(canEditZone),
						disabled: !canEditZone,
						onClick: () => onClickOption(ActionToExecute.Rename, ZoneKey.WholeGroup),
					},
			  ]
			: [
					{
						id: 'whosInsideForgiveAllUserCredentialsInZoneButton',
						label: _('ForgiveAllUsersCredentialsInThisZone'),
						separator: true,
						title: getPermissionErrorMessage(canForgiveUsers),
						disabled: !canForgiveUsers,
						onClick: () => onClickOption(ActionToExecute.ForgiveAllUsersCredentialsInZone, ZoneKey.Globalized),
					},
					{
						id: 'whosInsideRenameButton',
						label: _('Rename'),
						separator: true,
						title: getPermissionErrorMessage(canEditZone),
						disabled: !canEditZone,
						onClick: () => onClickOption(ActionToExecute.Rename, ZoneKey.Globalized),
					},
					{
						id: 'whosInsideMapToSharedZoneButton',
						label: _('MapToSharedZone'),
						onClick: () => onClickOption(ActionToExecute.MapToSharedZone, ZoneKey.Globalized),
					},
			  ];

	const customOptions = (isLeaf: boolean, zoneGroupType?: ZoneGroupType): WhosInsideOptions[] =>
		!isLeaf
			? [
					{
						id: 'whosInsideForgiveAllUserCredentialsInZoneGroupButton',
						label: _('ForgiveAllUsersCredentialsInZoneGroup'),
						separator: true,
						title: getPermissionErrorMessage(canForgiveUsers),
						disabled: !canForgiveUsers,
						onClick: () => onClickOption(ActionToExecute.ForgiveAllUsersCredentialsInGroup, ZoneKey.WholeGroup),
					},
					{
						id: 'whosInsideRenameButton',
						label: _('Rename'),
						title: getPermissionErrorMessage(canEditZone),
						separator: zoneGroupType === ZoneGroupType.Default,
						disabled: !canEditZone,
						onClick: () => onClickOption(ActionToExecute.Rename, ZoneKey.WholeGroup),
					},
					...(zoneGroupType === ZoneGroupType.Default
						? [
								{
									id: 'whosInsideEditButton',
									label: _('Edit'),
									title: getPermissionErrorMessage(canEditZone),
									disabled: !canEditZone,
									onClick: () => onClickOption(ActionToExecute.Edit, ZoneKey.Custom),
								},
						  ]
						: [
								{
									id: 'whosInsideEditButton',
									label: _('Edit'),
									title: getPermissionErrorMessage(canEditZone),
									disabled: !canEditZone,
									onClick: () => onClickOption(ActionToExecute.Edit, ZoneKey.Custom),
								},
								{
									id: 'whosInsideDeleteButton',
									label: _('Delete'),
									separator: true,
									title: getPermissionErrorMessage(canEditZone),
									disabled: !canEditZone,
									onClick: () => onClickOption(ActionToExecute.Delete, ZoneKey.WholeGroup),
								},
						  ]),
			  ]
			: [
					{
						id: 'whosInsideForgiveAllUserCredentialsInZoneButton',
						label: _('ForgiveAllUsersCredentialsInThisZone'),
						separator: true,
						title: getPermissionErrorMessage(canForgiveUsers),
						disabled: !canForgiveUsers,
						onClick: () => onClickOption(ActionToExecute.ForgiveAllUsersCredentialsInZone, ZoneKey.Custom),
					},
					{
						id: 'whosInsideRenameButton',
						label: _('Rename'),
						title: getPermissionErrorMessage(canEditZone),
						disabled: !canEditZone,
						separator: true,
						onClick: () => onClickOption(ActionToExecute.Rename, ZoneKey.Custom),
					},
					{
						id: 'whosInsideMapToSharedZoneButton',
						label: _('MapToSharedZone'),
						onClick: () => onClickOption(ActionToExecute.MapToSharedZone, ZoneKey.Custom),
					},
			  ];

	const customSingleOptions = (): WhosInsideOptions[] => [
		{
			id: 'whosInsideAddNewCustomGroupButton',
			label: _('AddCustomZoneGroup'),
			title: getPermissionErrorMessage(canEditZone),
			disabled: !canEditZone,
			onClick: () => onClickOption(ActionToExecute.AddNewCustomGroup, ZoneKey.Custom),
		},
	];

	const sharedOptions = (): WhosInsideOptions[] => [
		{
			id: 'whosInsideForgiveAllUsersCredentialsSharedZoneButton',
			label: _('ForgiveAllUsersCredentialsInThisSharedZone'),
			separator: true,
			title: getPermissionErrorMessage(canForgiveUsers),
			disabled: !canForgiveUsers,
			onClick: () => onClickOption(ActionToExecute.ForgiveAllUsersCredentialsInZone, ZoneKey.Shared),
		},
		{ label: _('Rename'), onClick: () => onClickOption(ActionToExecute.Rename, ZoneKey.Shared) },
	];

	const sharedSingleOptions = (): WhosInsideOptions[] => [
		{ id: 'whosInsideEditSharedZonesButton', label: _('EditSharedZones'), onClick: () => onClickOption(ActionToExecute.EditShareZones, ZoneKey.Shared) },
	];

	return {
		globalizedOptions,
		customOptions,
		customSingleOptions,
		sharedOptions,
		sharedSingleOptions,
	};
};

export const createButtonOptions = (isMenuContext: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: _('Action'),
		options: [
			{
				id: 'whosInsideForgiveCredentialsButton',
				label: _('ForgiveCredentials'),
				disabled: !canForgiveUsers,
				value: 'forgiveCredentials',
				title: getPermissionErrorMessage(canForgiveUsers),
			},
			{
				id: 'whosInsideDisplayBadgeButton',
				label: _('DisplayCredential'),
				separator: isMenuContext,
				value: 'displayBadge',
				title: getPermissionErrorMessage(true, true),
			},
		],
	};
};

export const createMoveToOption = (): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: _('MoveTo'),
		options: [
			{
				id: 'whosInsideMoveToButton',
				label: _('MoveTo'),
				disabled: !canMoveTo,
				value: 'moveCredentialTo',
				title: getPermissionErrorMessage(canMoveTo),
			},
		],
	};
};

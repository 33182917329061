import { Button, Modal, Spin, Tabs } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getFullVelocityUrl } from '../../../../Helper';
import { reportsApi } from '../../../../api/ReportsApi';
import { CriteriaField, CriteriaInfo, FieldDataType, ResponseStatusCode } from '../../../../model/CommonModel';
import { Logger } from '../../../../model/LoggingModel';
import { Report, ReportArchiveListInfo, ReportExportFormat, ReportRequestParameters, SortingInfo } from '../../../../model/ReportsModel';
import { HeaderBar, ModalConfirmation, ModalWarning, Select } from '../../../common';
import { BuildCriteria } from '../../../common/BuildCriteria/BuildCriteria';
import { ReportCriteriaRequestInfo } from './ReportCriteriaRequestInfo/ReportCriteriaRequestInfo';
import { ReportModalWarning } from './ReportModalWarning/ReportModalWarning';
import { ReportSortingRequestInfo } from './ReportSortingRequestInfo/ReportSortingRequestInfo';
import { ReportSortingTab } from './ReportSortingTab/ReportSortingTab';
import { ReportUserDefinedFields } from './ReportUserDefinedField/ReportUserDefinedFields';
import { exportTypes } from './criteriaOptions';
import styles from './reportTabs.module.scss';

const { TabPane } = Tabs;

type Props = {
	report: Report;
};

const velocityUrl = getFullVelocityUrl();

const ReportsTabs: React.FC<Props> = ({ report }) => {
	const [columnData, setColumnData] = useState<CriteriaField[]>([]);
	const [requestCriteria, setRequestCriteria] = useState<CriteriaInfo[]>([]);
	const [criteriaToEdit, setRequestCriteriaToEdit] = useState<CriteriaInfo>();
	const [sortingToEdit, setRequestSortingToEdit] = useState<SortingInfo>();
	const [requestSorting, setRequestSorting] = useState<SortingInfo[]>([]);
	const [visibleWarningModal, setVisibleWarningModal] = useState<boolean>(false);
	const [reportsArchivedInfo, setReportsArchivedInfo] = useState<ReportArchiveListInfo[]>([]);
	const [exportTypeSelected, setExportTypeSelected] = useState<ReportExportFormat>(ReportExportFormat.PDF);
	const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
	const [newUDF1, setNewUDF1] = useState<number>(0);
	const [newUDF2, setNewUDF2] = useState<number>(0);
	const [newUDF3, setNewUDF3] = useState<number>(0);
	const [activeTabKey, setActiveTabKey] = useState('1');
	const [hasUDFChanged, setHasUDFChanged] = useState<boolean>(false);

	useEffect(() => {
		if (report) {
			reportsApi.getReportColumnInfo(report.ReportId).then(setColumnData);

			if (report.UDF1 > 0 || report.UDF2 > 0 || report.UDF3 > 0) {
				reportsApi.getReportUserDefinedFields(report.ReportId).then(res => {
					const { UDF1, UDF2, UDF3 } = res.Entity;
					setNewUDF1(UDF1);
					setNewUDF2(UDF2);
					setNewUDF3(UDF3);
				});
			}
		}
	}, []);

	const handleAddReportCriteria = (newRequest: CriteriaInfo) => {
		if (criteriaToEdit) {
			setRequestCriteriaToEdit(null);
		}

		const findCriteriaToReplace = requestCriteria.some(x => x.key === newRequest.key);
		setRequestCriteria(request =>
			findCriteriaToReplace ? requestCriteria.map(x => (x.key === newRequest.key ? { ...newRequest } : x)) : [...request, newRequest]
		);
	};
	const handleAddReportSorting = (newRequest: SortingInfo) => {
		if (sortingToEdit) {
			setRequestSortingToEdit(null);
		}
		const findSortingToReplace = requestSorting.some(x => x.key === newRequest.key);
		setRequestSorting(request =>
			findSortingToReplace ? requestSorting.map(x => (x.key === newRequest.key ? { ...newRequest } : x)) : [...request, newRequest]
		);
	};
	const handleChangeCriteriaOperator = (value: boolean, key: number) => {
		const criteriaApplied = requestCriteria.map(x => (x.key === key ? { ...x, OrNextCriteria: value } : x));
		setRequestCriteria([...criteriaApplied]);
	};
	const handleRemoveCriteria = (key: number) => {
		const findKey = requestCriteria.findIndex(x => x.key === key);
		if (~findKey) {
			const cloneState = [...requestCriteria];
			cloneState.splice(findKey, 1);
			setRequestCriteria([...cloneState]);
		}
	};
	const handleEditCriteriaToBeEdited = (key: number) => {
		const findCriteria = requestCriteria.find(x => x.key === key);
		if (findCriteria) {
			setRequestCriteriaToEdit(findCriteria);
			setActiveTabKey('1');
		}
	};
	const handleCancelCriteriaEdition = (key: number) => {
		setRequestCriteriaToEdit(null);
	};
	const handleEditSortingToBeEdited = (key: number) => {
		const findSorting = requestSorting.find(x => x.key === key);
		if (findSorting) {
			setRequestSortingToEdit(findSorting);
			setActiveTabKey('2');
		}
	};
	const handleCancelSortingEdition = (key: number) => {
		setRequestSortingToEdit(null);
	};
	const handleRemoveSorting = (key: number) => {
		const findKey = requestSorting.findIndex(x => x.key === key);
		if (~findKey) {
			const cloneState = [...requestSorting];
			cloneState.splice(findKey, 1);
			setRequestSorting([...cloneState]);
		}
	};

	const handleOnSetHasUDFChanged = (hasChanged: boolean) => {
		if (hasChanged) {
			reportsApi.getReportColumnInfo(report?.ReportId).then(setColumnData);
		}
		setHasUDFChanged(hasChanged);
	};

	const handleOnSetUDFNumber = (udfNumber: number) => {
		if (columnData) {
			const reportColumn: CriteriaField = columnData.find(x => x.UDFNumber === udfNumber);
			if (reportColumn) {
				let cloneState = [...requestCriteria];
				const fieldId: number = reportColumn.FieldId;
				const elementsToDelete: CriteriaInfo[] = requestCriteria.filter(x => x.FieldId === fieldId);
				elementsToDelete.forEach(criteria => {
					const findKey = cloneState.findIndex(x => x.key === criteria.key);
					if (~findKey) {
						cloneState.splice(findKey, 1);
					}
				});

				setRequestCriteria([...cloneState]);
			}
		}
	};

	const onTryGenerateReport = () => {
		const checkHasDateField = requestCriteria.some(x => x.Type === FieldDataType.DateTime);

		if (!checkHasDateField) {
			reportsApi.getArchivedReportList(report?.ReportId).then(x => {
				if (x.length === 0) {
					generateReport();
				} else {
					setReportsArchivedInfo(x);
					setVisibleWarningModal(true);
				}
			});
		} else {
			generateReport();
		}
	};

	const generateReport = () => {
		const request: ReportRequestParameters = {
			AckArchiveNotice: true,
			ExportFormat: exportTypeSelected,
			CriteriaRequest: requestCriteria.map(x => {
				let secondaryInput = x.SecondaryInput;
				let primaryInput = x.PrimaryInput;
				if (x.Type === FieldDataType.DateTime) {
					primaryInput = moment(x.PrimaryInput).format('YYYY-MM-DD HH:mm:ss');
					if (secondaryInput) {
						secondaryInput = moment(x.SecondaryInput).format('YYYY-MM-DD HH:mm:ss');
					}
				} else if (x.Type === FieldDataType.Boolean) {
					primaryInput = `${x.PrimaryInput}`;
				}

				return {
					...x,
					PrimaryInput: primaryInput,
					SecondaryInput: secondaryInput,
				};
			}),
			ReportId: report?.ReportId,
			ReturnFilePathOnly: true,
			Sorting: requestSorting,
		};
		setIsGeneratingReport(true);
		reportsApi
			.generateReport(request)
			.then(response => {
				if (response.ResponseStatusCode !== null) {
					if (response.ResponseStatusCode === ResponseStatusCode.Success) {
						const fileName = `${velocityUrl}/Content/Reports/${response.Entity.FileName}`;
						let windowOpen = null;
						try {
							let e: Window = null;
							if (response.Entity.Extension === 'pdf') {
								e = window.open(fileName);
								windowOpen = true;
							}
							if (e == null) {
								windowOpen = false;
							}
						} catch (error) {
							Logger.writeErrorLog(error);
							windowOpen = false;
						}
						if (!windowOpen) {
							const isPdf = response.Entity.Extension === 'pdf';
							ModalConfirmation({
								content: <div>{isPdf ? _('ReportGeneratedpdf') : _('ReportGeneratedxls')}</div>,
								onConfirm: () => {
									window.open(fileName);
									Modal.destroyAll();
								},
								okText: isPdf ? _('OpenReport') : _('DownloadReport'),
							});
						}
					} else {
						ModalWarning({
							okText: _('Ok'),
							content: <label>{_('ReportErrorDesc').replace('%1', `${response.ResponseStatusCode}`)}</label>,
						});
					}
				}
				setIsGeneratingReport(false);
			})
			.catch(() => setIsGeneratingReport(false));
	};

	const getUniqueColumnsValues = (criteriaField: CriteriaField, value: string): Promise<string[]> => {
		return reportsApi.getUniqueColumnValues(report?.ReportId, criteriaField.FieldId, value);
	};

	const getUniqueColumnsValuesPagination = (criteriaField: CriteriaField, currentPage: number, pageSize: number): Promise<string[]> => {
		return reportsApi.getUniqueColumnValuesWithPagination(report?.ReportId, criteriaField.FieldId, currentPage, pageSize);
	};

	const getLastCriteriaKey = requestCriteria.length > 0 ? requestCriteria[requestCriteria.length - 1].key : 1;
	const getLastSortingKey = requestSorting.length > 0 ? requestSorting[requestSorting.length - 1].key : 1;
	const isEditingCriteria = criteriaToEdit !== undefined && criteriaToEdit !== null;
	const isEditingSorting = sortingToEdit !== undefined && sortingToEdit !== null;
	const canRenderedUDF = report?.UDF1 > 0 || report?.UDF2 > 0 || report?.UDF3 > 0;
	const isViewDisabled = report === undefined;

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isGeneratingReport} size='large'>
			<div className={styles.container} id='reportsReportTab'>
				<HeaderBar title={report?.Name ?? _('ThereIsNoReportSelected')} />
				<Tabs defaultActiveKey='1' className={styles.tabs} type='card' activeKey={activeTabKey} onTabClick={key => setActiveTabKey(key)}>
					<TabPane tab={_('Criteria')} key='1' disabled={isViewDisabled}>
						<BuildCriteria
							lastKey={getLastCriteriaKey}
							columnData={columnData}
							onAddRequestCriteria={handleAddReportCriteria}
							criteriaToEdit={criteriaToEdit}
							hasUDFChanged={hasUDFChanged}
							onResetUDFChanged={() => handleOnSetHasUDFChanged(false)}
							isDisabled={isViewDisabled}
							getUniqueColumnsValues={getUniqueColumnsValues}
							getUniqueColumnsValuesPagination={getUniqueColumnsValuesPagination}
							addButtonAtBottom={false}
						/>
					</TabPane>
					<TabPane tab={_('Sorting')} key='2' disabled={isViewDisabled}>
						<ReportSortingTab
							sortingToEdit={sortingToEdit}
							onAddSortingRequest={handleAddReportSorting}
							lastKey={getLastSortingKey}
							name={report?.Name}
							columnData={columnData}
							currentSortingFieldsId={requestSorting.map(x => x.FieldId)}
							hasUDFChanged={hasUDFChanged}
							onResetUDFChanged={() => handleOnSetHasUDFChanged(false)}
							isDisabled={isViewDisabled}
						/>
					</TabPane>
				</Tabs>
				<div className={styles.borderInfo}>
					<ReportCriteriaRequestInfo
						requestCriteria={requestCriteria}
						onDeleteCriteria={handleRemoveCriteria}
						onChangeCriteriaOperator={handleChangeCriteriaOperator}
						onSetCriteriaToBeEdited={handleEditCriteriaToBeEdited}
						onCancelEdition={handleCancelCriteriaEdition}
						isEditing={isEditingCriteria}
						currentEditionKey={criteriaToEdit?.key}
						roundedButtons={false}
					/>
					<br />
					<ReportSortingRequestInfo
						onCancelEdition={handleCancelSortingEdition}
						isEditing={isEditingSorting}
						onSetSortingToBeEdited={handleEditSortingToBeEdited}
						requestSorting={requestSorting}
						onDeleteSorting={handleRemoveSorting}
						currentEditionKey={sortingToEdit?.key}
					/>
				</div>
				{canRenderedUDF && (
					<ReportUserDefinedFields
						reportId={report?.ReportId}
						udf1={newUDF1}
						udf2={newUDF2}
						udf3={newUDF3}
						onSetUDF1={setNewUDF1}
						onSetUDF2={setNewUDF2}
						onSetUDF3={setNewUDF3}
						onSetUDFNumber={handleOnSetUDFNumber}
						onSetHasUDFChanged={handleOnSetHasUDFChanged}
						isEditingCriteria={isEditingCriteria}
					/>
				)}
				<div className={styles.exportType}>
					<label htmlFor='ExportType'>{_('ExportType')}</label>
					<Select
						ariaAttributes={{
							'aria-label': _('ExportType'),
							'aria-owns': 'ExportType',
							'aria-activedescendant': 'ExportType',
							'aria-controls': 'ExportType',
							'aria-expanded': 'true',
						}}
						id='ExportType'
						defaultValue={exportTypeSelected.toString()}
						onChange={value => setExportTypeSelected(Number(value))}
						options={exportTypes}
						disabled={isViewDisabled}
					/>
				</div>

				<div className={styles.generateReport}>
					<Button
						id='GenerateReportButton'
						type='primary'
						onClick={() => onTryGenerateReport()}
						disabled={isViewDisabled}
						title={isViewDisabled ? _('ThereIsNoReportSelected') : ''}>
						{_('GenerateReport')}
					</Button>
				</div>
				{visibleWarningModal && (
					<ReportModalWarning generateReport={generateReport} onVisible={() => setVisibleWarningModal(false)} data={reportsArchivedInfo} />
				)}
			</div>
		</Spin>
	);
};

export { ReportsTabs };

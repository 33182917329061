import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { SelectOptions } from '../../../model/CommonModel';
import styles from './buttonDropdown.module.scss';

type Props<T> = {
	labelIcon?: string | React.ReactElement;
	onClickOption?: (value: T) => void;
	menuOptions: SelectOptions<T>[];
	icon?: React.ReactElement;
	disabled?: boolean;
	id?: string;
	overlayClassName?: string;
	useParentAsPopupContainer?: boolean;
};

const ButtonDropdown = <T extends object | string | number>({
	onClickOption,
	menuOptions,
	labelIcon,
	icon,
	disabled,
	id,
	overlayClassName,
	useParentAsPopupContainer = true,
}: Props<T>) => {
	const menu = (
		<Menu>
			{menuOptions.map(({ label, value, icon, separator, disabled, id, title, leaveTab }, index) => (
				<Menu.Item
					disabled={disabled === true}
					key={`${label}-${index}`}
					title={title}
					icon={icon}
					onClick={() => onClickOption?.(value)}
					className={separator ? styles.separator : undefined}
					id={id}
					data-leavetab={leaveTab}>
					<span data-leavetab={leaveTab}>{label}</span>
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<>
			{disabled ? (
				<Button id={id} disabled={disabled}>
					{labelIcon}
					{icon}
				</Button>
			) : (
				<Dropdown
					overlay={menu}
					disabled={disabled}
					overlayClassName={overlayClassName}
					getPopupContainer={(triggerNode: HTMLElement) => (useParentAsPopupContainer && (triggerNode?.parentNode as HTMLElement)) || document.body}
					overlayStyle={{ width: 'max-content' }}>
					<Button id={id}>
						{labelIcon}
						{icon}
					</Button>
				</Dropdown>
			)}
		</>
	);
};

export { ButtonDropdown };

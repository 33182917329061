import { Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { CSSProperties } from 'react';
import styles from './modalConfirmation.module.scss';

type Props<T> = {
	onConfirm?: (value: T) => void;
	onCancel?: (value: T) => void;
	content?: React.ReactNode;
	title?: string | React.ReactNode;
	icon?: React.ReactElement;
	okText?: string;
	cancelText?: string;
	width?: string;
	style?: CSSProperties;
	visible?: boolean;
};

const okButtonProps: ButtonProps = { id: 'confirmationOkButton', className: styles.okButton };
const cancelButtonProps: ButtonProps = { id: 'confirmationCancelButton', className: styles.cancelButton };

const ModalConfirmation = <T extends object | string | number>({
	content,
	onCancel,
	onConfirm,
	title,
	cancelText,
	okText,
	icon,
	width,
	style,
	visible,
}: Props<T>) => {
	return Modal.confirm({
		keyboard: false,
		maskClosable: false,
		style,
		width,
		content,
		title,
		icon: icon || <div></div>,
		onOk: onConfirm,
		onCancel,
		okText: okText || _('Yes'),
		cancelText: cancelText || _('No'),
		okButtonProps: okButtonProps,
		cancelButtonProps: cancelButtonProps,
		visible,
		className: styles.body,
	});
};

export { ModalConfirmation };

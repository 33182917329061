import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import { CheckboxProps } from 'antd/lib';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ScrollType,
	buildColumn,
	getDefaultTablePaginationConfig,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	useHandleOnChangeTableLogic,
} from '../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../api';
import { RolesColumn, SortDirections } from '../../../../../../model/CommonModel';
import { UserRole } from '../../../../../../model/DeviceAdminModel';
import { OperatorPaginationSetting } from '../../../../../../model/OperatorModel';
import { SearchColumn } from '../../../../../common';
import { setErrorEntryFlagAction, setOperatorRoleModificationsAction } from '../OperatorContext/action';
import { OperatorStoreContext } from '../OperatorContext/context';
import styles from './operatorPropertyRolesTab.module.scss';

type operatorPropertyRoleTabProps = {
	operatorId: number;
};

const scroll: ScrollType = { x: 270, y: 450, scrollToFirstRowOnChange: true };

const errorMessage: string = _('OperatorGroupMemberRequirement');

const OperatorPropertyRoleTab: React.FC<operatorPropertyRoleTabProps> = ({ operatorId }) => {
	const {
		operatorInitialState: {
			rootContext: {
				entryError: { isRoleTabError },
			},
		},
		dispatcher,
	} = useContext(OperatorStoreContext);
	const [loading, setLoading] = useState<boolean>(true);
	const [trackChanges, setTrackChanges] = useState<boolean>(false);
	const [rolesNotIn, setRolesNotIn] = useState<RolesColumn[]>([]);
	const [paginationSettingSource, setPaginationSettingSource] = useState<OperatorPaginationSetting>({
		PageNumber: 1,
		PageSize: 25,
		SortDirection: SortDirections.None,
		SearchedValue: '',
		CachedAdded: [] as React.Key[],
		CachedRemoved: [] as React.Key[],
	});
	const [selectedRowKeysSource, setSelectedRowKeysSource] = useState<React.Key[]>([] as React.Key[]);
	const isSearchPerformedSource = useRef<boolean>(false);
	const [resetSearchColumnSource, setResetSearchColumnSource] = useState<boolean>(false);
	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [rolesIn, setRolesIn] = useState<RolesColumn[]>([]);
	const [paginationSettingTarget, setPaginationSettingTarget] = useState<OperatorPaginationSetting>({
		PageNumber: 1,
		PageSize: 25,
		SortDirection: SortDirections.None,
		SearchedValue: '',
		CachedAdded: [] as React.Key[],
		CachedRemoved: [] as React.Key[],
	});
	const [selectedRowKeysTarget, setSelectedRowKeysTarget] = useState<React.Key[]>([] as React.Key[]);
	const isSearchPerformedTarget = useRef<boolean>(false);
	const [resetSearchColumnTarget, setResetSearchColumnTarget] = useState<boolean>(false);

	const fetchRolesSource = async (paginationSetting: OperatorPaginationSetting): Promise<RolesColumn[]> => {
		const res = await deviceAdminApi.getRolesOperatorNotIn(operatorId, paginationSetting);
		if (!handleResponse(res)) {
			const mappedRoles: RolesColumn[] = mapData(res.Entity.UserRoles);
			batch(() => {
				setRolesNotIn(mappedRoles);
				setPaginationSettingSource({ ...paginationSetting, TotalItems: res.Entity.TotalItemsPaginated });
			});
			return mappedRoles;
		}

		return [] as RolesColumn[];
	};

	const fetchRolesTarget = async (paginationSetting: OperatorPaginationSetting): Promise<RolesColumn[]> => {
		const res = await deviceAdminApi.getRolesOperatorIn(operatorId, paginationSetting);
		if (!handleResponse(res)) {
			const mappedRoles: RolesColumn[] = mapData(res.Entity.UserRoles);
			batch(() => {
				setRolesIn(mappedRoles);
				setPaginationSettingTarget({ ...paginationSetting, TotalItems: res.Entity.TotalItemsPaginated });
			});
			return mappedRoles;
		}

		return [] as RolesColumn[];
	};

	const doesDefaultOperatorRoleExist = async (): Promise<boolean> => {
		const res = await deviceAdminApi.doesDefaultOperatorRoleExist(2, 'Operators');
		if (!handleResponse(res)) {
			return res.Entity;
		}

		return false;
	};

	const updateData = async (
		paginationSettingSource: OperatorPaginationSetting,
		paginationSettingTarget: OperatorPaginationSetting,
		isComponentMounting: boolean = false
	): Promise<void> => {
		if (isComponentMounting) {
			const doesDefaultRoleExist: boolean = await doesDefaultOperatorRoleExist();
			if (doesDefaultRoleExist) {
				paginationSettingSource = { ...paginationSettingSource, CachedRemoved: ['2'] as React.Key[] };
				paginationSettingTarget = { ...paginationSettingTarget, CachedAdded: ['2'] as React.Key[] };
			}
		}
		await fetchRolesSource(paginationSettingSource);
		await fetchRolesTarget(paginationSettingTarget);
	};

	const getData = async (
		paginationSettingSource: OperatorPaginationSetting,
		paginationSettingTarget: OperatorPaginationSetting
	): Promise<{ source: RolesColumn[]; target: RolesColumn[] }> => {
		let data = {
			source: [] as RolesColumn[],
			target: [] as RolesColumn[],
		};

		data.source = await fetchRolesSource(paginationSettingSource);
		data.target = await fetchRolesTarget(paginationSettingTarget);

		return data;
	};

	useEffect(() => {
		setLoading(true);
		updateData(paginationSettingSource, paginationSettingTarget, operatorId === 0).then(() => {
			batch(() => {
				setLoading(false);
				setTrackChanges(true);
			});
		});
	}, []);

	useEffect(() => {
		if (trackChanges) {
			const isModified: boolean = operatorId === 0 || paginationSettingTarget.CachedAdded.length > 0 || paginationSettingTarget.CachedRemoved.length > 0;
			const isReadyToSave: boolean = rolesIn.length > 0;
			dispatcher(
				setOperatorRoleModificationsAction({
					isModified,
					isReadyToSave,
					subscribedRoleIds: paginationSettingTarget.CachedAdded,
					unsubscribedRoleIds: paginationSettingTarget.CachedRemoved,
				})
			);
		}
	}, [JSON.stringify(rolesIn)]);

	const mapData = (data: UserRole[]): RolesColumn[] => {
		if (data.length > 0) {
			return data.map<RolesColumn>((d: UserRole) => ({
				key: d.Id.toString(),
				Action: undefined,
				Name: d.Name,
				Description: d.Description,
			}));
		}

		return [];
	};

	const onAddRoleButtonClick = () => {
		setLoading(true);
		const updateSourceAddedCache: React.Key[] = paginationSettingSource.CachedAdded.filter(ca => !selectedRowKeysSource.some(key => ca === key));
		const fromSourceToTargetCache = [
			...paginationSettingTarget.CachedAdded,
			...selectedRowKeysSource.filter(key => !paginationSettingSource.CachedAdded.some(ca => key === ca)),
		];

		updateData(
			{ ...paginationSettingSource, CachedAdded: updateSourceAddedCache, CachedRemoved: fromSourceToTargetCache },
			{ ...paginationSettingTarget, CachedAdded: fromSourceToTargetCache, CachedRemoved: updateSourceAddedCache }
		).then(() => {
			batch(() => {
				clearSelectionSource();
				setLoading(false);
				dispatcher(setErrorEntryFlagAction({ field: 'isRoleTabError', value: false }));
			});
		});
	};

	const onRemoveRoleButtonClick = () => {
		setLoading(true);
		const updateTargetAddedCache: React.Key[] = paginationSettingTarget.CachedAdded.filter(ca => !selectedRowKeysTarget.some(key => ca === key));
		const fromTargetToSourceCache = [
			...paginationSettingSource.CachedAdded,
			...selectedRowKeysTarget.filter(key => !paginationSettingTarget.CachedAdded.some(ca => ca === key)),
		];

		getData(
			{ ...paginationSettingSource, CachedAdded: fromTargetToSourceCache, CachedRemoved: updateTargetAddedCache },
			{ ...paginationSettingTarget, CachedAdded: updateTargetAddedCache, CachedRemoved: fromTargetToSourceCache }
		).then(data => {
			batch(() => {
				clearSelectionTarget();
				setLoading(false);
			});

			if (data.target.length === 0) {
				dispatcher(setErrorEntryFlagAction({ field: 'isRoleTabError', value: true }));
			}
		});
	};

	const setSearchPerformedSource = (value: boolean, isSourceTable: boolean) => {
		if (isSourceTable) {
			isSearchPerformedSource.current = value;
		} else {
			isSearchPerformedTarget.current = value;
		}
	};

	const handleOnSearch = (searchString: string, isSourceTable: boolean) => {
		setLoading(true);
		if (isSourceTable) {
			fetchRolesSource({ ...paginationSettingSource, PageNumber: 1, SearchedValue: searchString }).then(() => {
				setLoading(false);
			});
		} else {
			fetchRolesTarget({ ...paginationSettingTarget, PageNumber: 1, SearchedValue: searchString }).then(() => {
				setLoading(false);
			});
		}
	};

	const generateColumns = (isSourceTable: boolean) => {
		const columns: ColumnsType<RolesColumn> = [
			{
				...buildColumn(isSourceTable ? _('NotMemberOf') : _('MemberOf'), 'Name', '160px', 'start'),
				key: 1,
				sorter: (a, b) => a.Name.localeCompare(b.Name),
				...SearchColumn({
					maxLength: 20,
					dataIndex: 'Name',
					reset: undefined,
					label: undefined,
					notVisible: undefined,
					resetSearch: isSourceTable ? resetSearchColumnSource : resetSearchColumnTarget,
					//setIsFilterMode: setVelocityConfigurationFilterMode,
					clearSelection: isSourceTable ? clearSelectionSource : clearSelectionTarget,
					handleResetSearch: isSourceTable ? () => setResetSearchColumnSource(false) : () => setResetSearchColumnTarget(false),
					setSearchResults: (searchedValue: string) => handleOnSearch(searchedValue, isSourceTable),
					setSearchedValue: undefined,
					searchColumnId: undefined,
					setSearchPerformed: (value: boolean) => setSearchPerformedSource(value, isSourceTable),
				}),
			},
		];

		return columns;
	};

	const getSelectedRowKeysForSelectAllSource = async (): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getRoleIdsOperatorNotIn(operatorId);
		if (!handleResponse(response)) {
			const selectedKeys = response.Entity;
			const filteredKeys = selectedKeys.filter(sk => !paginationSettingTarget.CachedAdded.includes(sk.toString()));
			return [...filteredKeys, ...paginationSettingSource.CachedAdded];
		}

		return [] as React.Key[];
	};

	const getSelectedRowKeysForSelectAllTarget = async (): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getRoleIdsOperatorIn(operatorId);
		if (!handleResponse(response)) {
			const selectedKeys = response.Entity;
			const filteredKeys = selectedKeys.filter(sk => !paginationSettingSource.CachedAdded.includes(sk.toString()));
			return [...filteredKeys, ...paginationSettingTarget.CachedAdded];
		}

		return [] as React.Key[];
	};

	const handleSelectAllSource = async () => {
		setLoading(true);
		const sourceSelectedKeys: React.Key[] = await getSelectedRowKeysForSelectAllSource();
		const sourceSelectedKeysStrings: string[] = sourceSelectedKeys.map<string>(sk => sk.toString());
		const newSelectedKeys: React.Key[] = getUniqueValuesArray(selectedRowKeysSource, sourceSelectedKeysStrings);
		batch(() => {
			setSelectedRowKeysSource(newSelectedKeys);
			setLoading(false);
		});
	};

	const handleSelectAllTarget = async () => {
		setLoading(true);
		const targetSelectedKeys: React.Key[] = await getSelectedRowKeysForSelectAllTarget();
		let targetSelectedKeysStrings: string[] = targetSelectedKeys.map<string>(sk => sk.toString());
		if (operatorId === 1) {
			targetSelectedKeysStrings = targetSelectedKeysStrings.filter(k => k !== '1');
		}
		const newSelectedKeys: React.Key[] = getUniqueValuesArray(selectedRowKeysTarget, targetSelectedKeysStrings);
		batch(() => {
			setSelectedRowKeysTarget(newSelectedKeys);
			setLoading(false);
		});
	};

	const handleSelectInvertSource = () => {
		const dataKeys = rolesNotIn.map<React.Key>(x => x.key.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeysSource);
		setSelectedRowKeysSource(newSelectedRowKeys);
	};

	const handleSelectInvertTarget = () => {
		const dataKeys = rolesIn.map<React.Key>(x => x.key.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeysTarget);
		if (operatorId === 1) {
			setSelectedRowKeysTarget(newSelectedRowKeys.filter(k => k.toString() !== '1'));
		} else {
			setSelectedRowKeysTarget(newSelectedRowKeys);
		}
	};

	const rowSelectionSource: TableRowSelection<RolesColumn> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: [
			{ key: 'SELECTION_ALL_SOURCE', text: 'Select all data', onSelect: handleSelectAllSource },
			{ key: 'SELECTION_INVERT_SOURCE', text: 'Invert current page', onSelect: handleSelectInvertSource },
			Table.SELECTION_NONE,
		],
		onChange: (selectedRowKeys: React.Key[], selectedRows: RolesColumn[], info: { type }) => {
			setSelectedRowKeysSource(selectedRowKeys);
		},
		getCheckboxProps: record => ({
			id: `rolesOperatorSourceTableCheckbox-${record.key}`,
			disabled: false,
			children: <label htmlFor={`rolesOperatorSourceTableCheckbox-${record.key}`} className={styles.srOnly}></label>,
		}),
		columnWidth: 37,
		selectedRowKeys: selectedRowKeysSource,
	};

	const rowSelectionTarget: TableRowSelection<RolesColumn> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: [
			{ key: 'SELECTION_ALL_TARGET', text: 'Select all data', onSelect: handleSelectAllTarget },
			{ key: 'SELECTION_INVERT_TARGET', text: 'Invert current page', onSelect: handleSelectInvertTarget },
			Table.SELECTION_NONE,
		],
		onChange: (selectedRowKeys: React.Key[], selectedRows: RolesColumn[]) => {
			setSelectedRowKeysTarget(selectedRowKeys);
		},
		getCheckboxProps: record => {
			const disabled: boolean = operatorId === 1 && record.key.toString() === '1';
			const props: CheckboxProps = {
				id: `rolesOperatorTargetTableCheckbox-${record.key}`,
				disabled,
				children: <label htmlFor={`rolesOperatorTargetTableCheckbox-${record.key}`} className={styles.srOnly}></label>,
			};
			if (disabled) {
				props.title = `${_('CannotRemoveAdminRoleToAdminAccount')}.`;
				props.className = styles.roleDisabledCheckbox;
			}
			return props;
		},
		columnWidth: 37,
		selectedRowKeys: selectedRowKeysTarget,
	};

	const clearSelectionSource = () => {
		setSelectedRowKeysSource([] as React.Key[]);
	};

	const clearSelectionTarget = () => {
		setSelectedRowKeysTarget([] as React.Key[]);
	};

	const handleChangePaginationSource = (page: number, pageSize: number) => {
		if (pageSize !== paginationSettingSource.PageSize) {
			clearSelectionSource();
		}
	};

	const handleChangePaginationTarget = (page: number, pageSize: number) => {
		if (pageSize !== paginationSettingTarget.PageSize) {
			clearSelectionTarget();
		}
	};

	const getPagination = (isSourceTable: boolean): TablePaginationConfig => {
		if (isSourceTable) {
			return getDefaultTablePaginationConfig(
				false,
				handleChangePaginationSource,
				paginationSettingSource.PageNumber,
				paginationSettingSource.PageSize,
				paginationSettingSource.TotalItems,
				undefined,
				selectedRowKeysSource
			);
		} else {
			return getDefaultTablePaginationConfig(
				false,
				handleChangePaginationTarget,
				paginationSettingTarget.PageNumber,
				paginationSettingTarget.PageSize,
				paginationSettingTarget.TotalItems,
				undefined,
				selectedRowKeysTarget
			);
		}
	};

	const handleOnChangeTable = (
		pagination: TablePaginationConfig,
		filters: { Name?: string[] },
		sorter: SorterResult<RolesColumn>,
		isSourceTable: boolean
	) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection: isSourceTable ? clearSelectionSource : clearSelectionTarget,
			filters,
			handleChangePagination: isSourceTable ? handleChangePaginationSource : handleChangePaginationTarget,
			isSearchPerformed: isSourceTable ? isSearchPerformedSource : isSearchPerformedTarget,
			pagination,
			shouldResetSearchColumn: false,
			sorter,
			tablePaginationSetting: isSourceTable ? paginationSettingSource : paginationSettingTarget,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			setLoading(true);
			if (isSourceTable) {
				fetchRolesSource({
					...paginationSettingSource,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				}).then(() => {
					setLoading(false);
				});
			} else {
				fetchRolesTarget({
					...paginationSettingTarget,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				}).then(() => {
					setLoading(false);
				});
			}
		}
	};

	return (
		<Spin tip={`${_('Loading')}...`} spinning={loading} size='large' className={styles.spinContainer}>
			<div className={styles.topContainer}>
				{isRoleTabError && <p className={styles.error}>{errorMessage}</p>}
				<div className={styles.container}>
					<div className={styles.tableContainer}>
						<Table
							className={cx(styles.tableTransfer, {
								[styles.tableTransferError]: rolesIn?.length === 0,
							})}
							size='small'
							bordered
							dataSource={rolesIn}
							columns={generateColumns(false)}
							scroll={scroll}
							rowSelection={{ ...rowSelectionTarget }}
							pagination={getPagination(false)}
							onChange={(pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<RolesColumn>) => {
								handleOnChangeTable(pagination, filters, sorter, false);
							}}
						/>
					</div>
					<div className={styles.buttonContainer}>
						<Button
							className={styles.buttonStyle}
							type='primary'
							disabled={!selectedRowKeysTarget || selectedRowKeysTarget.length === 0}
							onClick={onRemoveRoleButtonClick}>
							<ArrowRightOutlined />
						</Button>
						<Button
							className={styles.buttonStyle}
							disabled={!selectedRowKeysSource || selectedRowKeysSource.length === 0}
							onClick={onAddRoleButtonClick}>
							<ArrowLeftOutlined />
						</Button>
					</div>
					<div className={styles.tableContainer}>
						<Table
							className={cx(styles.tableTransfer, {
								[styles.tableTransferError]: rolesNotIn?.length === 0,
							})}
							size='small'
							bordered
							dataSource={rolesNotIn}
							columns={generateColumns(true)}
							scroll={scroll}
							rowSelection={{ ...rowSelectionSource }}
							pagination={getPagination(true)}
							onChange={(pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<RolesColumn>) => {
								handleOnChangeTable(pagination, filters, sorter, true);
							}}
						/>
					</div>
				</div>
			</div>
		</Spin>
	);
};

export { OperatorPropertyRoleTab };

import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { PortStoreContext, setIPPort, setPortChangedAction } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const IPPort: React.FC<Props> = () => {
	const {
		portState: { ipPort, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChange = (value: string) => {
		if (!(Number(value) >= 0)) {
			return;
		}
		dispatcher(setIPPort(value));
		checkIfPortChanged();
	};

	const portGeneralIPPortInputId = 'portGeneralIPPortInput';

	return (
		<div className={styles.inputPortContainer}>
			<label id='portGeneralIPPortLabel' htmlFor={portGeneralIPPortInputId}>
				{_('IPPort')}
			</label>
			<InputNumber
				id={portGeneralIPPortInputId}
				key='ipPort'
				className={styles.input}
				value={ipPort}
				min={0}
				max={65535}
				step={1}
				maxLength={5}
				onChange={handleChange}
				formatter={noneDecimalFormatter}
			/>
		</div>
	);
};

export { IPPort };

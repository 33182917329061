import React from 'react';
import { InputLogicTimeZones } from '../../../../../../model/DeviceAdminModel';
import styles from './logic.module.scss';
import { ControlZones, TimeZones } from './sections/Index';

type Props = {
	setPreviousSelections: (InputLogicTimeZones) => void;
	previousSelections: InputLogicTimeZones;
	useDoorContext: boolean;
};

const Logic: React.FC<Props> = ({ setPreviousSelections, previousSelections, useDoorContext }) => {
	return (
		<div id='logicInputContainer' className={styles.container}>
			<TimeZones setPreviousSelections={setPreviousSelections} previousSelections={previousSelections} useDoorContext={useDoorContext}></TimeZones>
			<ControlZones useDoorContext={useDoorContext}></ControlZones>
		</div>
	);
};

export { Logic };

import { ControlZone, DoorGroup } from '../../../../model/DeviceAdminModel';
import {
	BadgeTemplate,
	CardFormat,
	CredentialFunction,
	CredentialTemplate,
	FunctionCategory,
	FunctionGroup,
	OverrideAnswer,
	XboxController,
} from '../../../../model/EnrollmentModel';
import {
	CredentialTemplateModalConfigData,
	CredentialTemplateModalModel,
	CredentialTemplateModalModelProp,
	GeneralTabActivationExpirationProperty,
	GeneralTabCardProperty,
	GeneralTabInformationProperty,
	GeneralTabKeypadProperty,
	LimitsTabProperty,
	OptionsTabProperty,
	ValidationErrorsProperty,
} from '../CredentialTemplateModalModels';

export enum ContextActionTypes {
	INIT_CONFIG_DATA = 'INIT_CONFIG_DATA',
	SET_COMPLETE_MODEL = 'SET_COMPLETE_MODEL',
	SET_ERROR = 'SET_ERROR',
	SET_ROOT_MODEL_PROPERTY = 'SET_ROOT_MODEL_PROPERTY',
	SET_OVERRIDE_ANSWER = 'SET_OVERRIDE_ANSWER',
	// GeneralTab
	SET_CRED_TEMPLATE_ID = 'SET_CRED_TEMPLATE_ID',
	SET_GENERAL_TAB_SERVICES_DATA = 'SET_GENERAL_TAB_SERVICES_DATA',
	SET_GT_IDF = 'SET_GT_IDF',
	// --- Activation/Expiration Section --- //
	SET_ACTIVATION_EXPIRATION_PROPERTY = 'SET_ACTIVATION_EXPIRATION_PROPERTY',
	// --- CardSection --- //
	SET_CARD_PROPERTY = 'SET_CARD_PROPERTY',
	// --- InformationSection --- //
	SET_INFORMATION_PROPERTY = 'SET_INFORMATION_PROPERTY',
	// --- KeypadSection --- //
	SET_KEYPAD_PROPERTY = 'SET_KEYPAD_PROPERTY',
	// FunctionsTab
	SET_CREDENTIAL_FUNCTIONS_ARRAY = 'SET_CREDENTIAL_FUNCTIONS_ARRAY',
	SET_CONTROL_ZONES_ARRAY = 'SET_CONTROL_ZONES_ARRAY',
	SET_FUNCTION_TAB_SERVICES_DATA = 'SET_FUNCTION_TAB_SERVICES_DATA',
	// LimitsTab
	SET_LIMITS_TAB_PROPERTY = 'SET_LIMITS_TAB_PROPERTY',
	// OptionsTab
	SET_OPTION_TAB_PROPERTY = 'SET_OPTION_TAB_PROPERTY',
}

//
export type initConfigData = {
	type: ContextActionTypes.INIT_CONFIG_DATA;
	payload: CredentialTemplateModalConfigData;
};

export type setCompleteModel = {
	type: ContextActionTypes.SET_COMPLETE_MODEL;
	payload: CredentialTemplateModalModel;
};

export type setError = {
	type: ContextActionTypes.SET_ERROR;
	payload: ValidationErrorsProperty;
};

export type setRootModelProperty = {
	type: ContextActionTypes.SET_ROOT_MODEL_PROPERTY;
	payload: CredentialTemplateModalModelProp;
};

export type setOverrideAnswer = {
	type: ContextActionTypes.SET_OVERRIDE_ANSWER;
	payload: OverrideAnswer;
};

// GeneralTab ActionTypes
export type setCredTemplateId = {
	type: ContextActionTypes.SET_CRED_TEMPLATE_ID;
	payload: number;
};

export type setGeneralTabServicesData = {
	type: ContextActionTypes.SET_GENERAL_TAB_SERVICES_DATA;
	payload: {
		badgeTemplates: BadgeTemplate[];
		cardFormats: CardFormat[];
		credentialTemplates: CredentialTemplate[];
	};
};

export type setGtIdf = {
	type: ContextActionTypes.SET_GT_IDF;
	payload: number;
};

// --- Activation/Expiration Section ---
export type setGeneralTabActivationExpirationProperty = {
	type: ContextActionTypes.SET_ACTIVATION_EXPIRATION_PROPERTY;
	payload: GeneralTabActivationExpirationProperty;
};

// --- CardSection --- //
export type setCardProperty = {
	type: ContextActionTypes.SET_CARD_PROPERTY;
	payload: GeneralTabCardProperty;
};

// --- InformationSection --- //
export type setInformationProperty = {
	type: ContextActionTypes.SET_INFORMATION_PROPERTY;
	payload: GeneralTabInformationProperty;
};

// --- KeypadSection --- //
export type setKeypadProperty = {
	type: ContextActionTypes.SET_KEYPAD_PROPERTY;
	payload: GeneralTabKeypadProperty;
};

// FunctionsTab
export type setControlZonesArray = {
	type: ContextActionTypes.SET_CONTROL_ZONES_ARRAY;
	payload: ControlZone[];
};

export type setCredentialFunctionsArray = {
	type: ContextActionTypes.SET_CREDENTIAL_FUNCTIONS_ARRAY;
	payload: Partial<CredentialFunction>[];
};

export type setFunctionTabServicesData = {
	type: ContextActionTypes.SET_FUNCTION_TAB_SERVICES_DATA;
	payload: {
		controllers: XboxController[];
		doorGroups: DoorGroup[];
		functionCategories: FunctionCategory[];
		functionGroups: FunctionGroup[];
	};
};

// LimitsTab
export type setLimitsTabProperty = {
	type: ContextActionTypes.SET_LIMITS_TAB_PROPERTY;
	payload: LimitsTabProperty;
};

// OptionsTab
export type setOptionsTabProperty = {
	type: ContextActionTypes.SET_OPTION_TAB_PROPERTY;
	payload: OptionsTabProperty;
};

export type ActionContext =
	| initConfigData
	| setCompleteModel
	| setError
	| setRootModelProperty
	| setOverrideAnswer
	// GeneralTab
	| setCredTemplateId
	| setGeneralTabServicesData
	| setGtIdf
	// --- Activation/Expiration Section
	| setGeneralTabActivationExpirationProperty
	// --- CardSection --- //
	| setCardProperty
	// --- InformationSection --- //
	| setInformationProperty
	// --- KeypadSection --- //
	| setKeypadProperty
	// FunctionsTab
	| setControlZonesArray
	| setCredentialFunctionsArray
	| setFunctionTabServicesData
	// LimitsTab
	| setLimitsTabProperty
	// OptionsTab
	| setOptionsTabProperty;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".name-input-container--2eKmgEp5jiTCnKnh52-Fr0{display:flex;flex-direction:column;width:50%}.name-input-container--2eKmgEp5jiTCnKnh52-Fr0>div{display:flex;flex-direction:column}.name-input-container--2eKmgEp5jiTCnKnh52-Fr0>div input{height:32px}.name-input-error--1nEKSouuoaSDpIEijd8aTR input{border:1px solid red}.name-input-error--1nEKSouuoaSDpIEijd8aTR label{color:red}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/VelocityConfiguration/ReaderControlGroup/ReaderModal/sections/NameInput/nameInput.module.scss"],"names":[],"mappings":"AAAA,8CACC,YAAA,CACA,qBAAA,CACA,SAAA,CACA,kDACC,YAAA,CACA,qBAAA,CACA,wDACC,WAAA,CAKF,gDACC,oBAAA,CAED,gDACC,SAAA","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\twidth: 50%;\r\n\t& > div {\r\n\t\tdisplay: flex;\r\n\t\tflex-direction: column;\r\n\t\tinput {\r\n\t\t\theight: 32px;\r\n\t\t}\r\n\t}\r\n}\r\n.error {\r\n\tinput {\r\n\t\tborder: 1px solid red;\r\n\t}\r\n\tlabel {\r\n\t\tcolor: red;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "name-input-container--2eKmgEp5jiTCnKnh52-Fr0",
	"error": "name-input-error--1nEKSouuoaSDpIEijd8aTR"
};
export default ___CSS_LOADER_EXPORT___;

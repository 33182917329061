import { Button, notification, Table } from 'antd';
import { ColumnsType, ColumnType, TablePaginationConfig } from 'antd/lib/table';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../../../../../api';
import { buildColumn, getDefaultTablePaginationConfig, getPageId, ScrollType, unlockSNIBSearchComponent } from '../../../../../../../Helper';
import {
	BroadCastDevice,
	BroadCastSummary,
	DataTableType,
	PortNetWorkType,
	SearchIPAddressConfig,
	SnibSearchType,
} from '../../../../../../../model/DeviceAdminModel';
import { useStoreSelector } from '../../../../../../../store';
import { selectBroadCastSummary } from '../../../../../../../store/common/selectors';
import { SearchColumn } from '../../../../../../common';
import { NotificationStatus } from '../../../../../../common/NotificationStatus/NotificationStatus';
import { PortStoreContext, setActiveNetworks, setBroadcastData, setLoading, setSNIBSelected, setTitleModal } from '../../../contextPort';
import styles from './searchTable.module.scss';
import { NetworkType } from './sections/NetworkType/NetworkType';
import { SearchIPRange } from './sections/SearchIPRange/SearchIPRange';
import { SearchNetwork } from './sections/SearchNetwork/SearchNetworks';
import { SearchType } from './sections/SearchType/SearchType';

type Props = {
	onHandleIsSearchTableView: (value: boolean) => void;
};

type DataType = DataTableType<BroadCastDevice>;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: 1000, y: 200 };
const maxLength: number = 32;

const SearchTable: React.FC<Props> = ({ onHandleIsSearchTableView }) => {
	const {
		portState: {
			protocol,
			snibSelected,
			broadcastData,
			snibSearchType,
			ipAddressOctets,
			currentNetwork,
			activeNetworks,
			loading,
			ipRangeError: { hasError },
			globalBroadcastAddress,
			networkType,
		},
		dispatcher,
	} = useContext(PortStoreContext);

	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [hasSearchButtonTriggered, setHasSearchButtonTriggered] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [pagination, setPagination] = useState<TablePaginationConfig>(getDefaultTablePaginationConfig());

	const subnetColumn = {
		...(networkType === PortNetWorkType.IPv4
			? { ...buildColumn(_('SubnetMask'), 'SubnetMask', '125px', 'start') }
			: { ...buildColumn(_('SubnetPrefixLength'), 'SubnetMask', '125px', 'start') }),
		sorter: (a, b) => a.SubnetMask.localeCompare(b.SubnetMask),
	};
	const [previousSubnetColumn, setPreviousSubnetColumn] = useState<ColumnType<BroadCastDevice>>(subnetColumn);

	const broadCastSummary: BroadCastSummary = useStoreSelector<BroadCastSummary>(selectBroadCastSummary);
	const pageId: string = getPageId();

	useEffect(() => {
		dispatcher(setTitleModal(_('PortSearch')));
		getActiveNetworks();
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		const { octet1 } = ipAddressOctets;
		const isReadyToSearch = octet1 !== '' && octet1 !== undefined;
		if (isFirstLoad && isReadyToSearch) {
			setIsFirstLoad(false);
			searchIPAddress();
		}
	}, [ipAddressOctets?.octet1]);

	useEffect(() => {
		if (snibSelected === null) {
			setSelectedRowKeys([]);
		}
	}, [snibSelected]);

	useEffect(() => {
		if (broadCastSummary) {
			if (broadCastSummary.Devices.length) {
				dispatcher(setLoading(true));
				const newMessagesData: Partial<DataType>[] = getMulticastMessageData();
				if (!newMessagesData || newMessagesData.length === 0) {
					return;
				}

				let newData: DataTableType<BroadCastDevice>[] = [];
				newMessagesData.forEach(newMessageData => {
					const isNewDataSaved = broadcastData.find(data => data.key === newMessageData.key);
					if (isNewDataSaved) {
						newData = broadcastData.map<DataType>(data => {
							if (data.key === newMessageData.key) {
								return {
									...data,
									...(newMessageData as DataType),
								};
							}
							return data;
						});
					} else {
						newData = [
							...broadcastData,
							{
								...newMessageData,
							} as DataType,
						];
					}
				});
				dispatcher(setBroadcastData(newData));
			}

			if (broadCastSummary.SearchCompleted) {
				batch(() => {
					dispatcher(setLoading(false));
					dispatcher(setTitleModal(_('PortSearch')));
				});
			}
		}
	}, [broadCastSummary]);

	const handleBeforeUnload = () => {
		unlockSNIBSearchComponent();
	};

	const getMulticastMessageData = (): Partial<DataType>[] => {
		return broadCastSummary.Devices.map(broadcastDevice => {
			return { ...broadcastDevice, key: `${broadcastDevice.Id}` };
		});
	};

	const getActiveNetworks = async () => {
		const response = await deviceAdminApi.getActiveNetworks();
		dispatcher(setActiveNetworks(response.Entity));
	};

	const searchIPAddress = async () => {
		const searchIPAddressConfig: SearchIPAddressConfig = {
			pageId,
			networkType,
			protocol,
			currentNetworkId: currentNetwork.Id,
			currentNetworks: activeNetworks,
			isBroadCastSearch: snibSearchType === SnibSearchType.Broadcast,
			includeGlobalBroadcastAddress: globalBroadcastAddress,
			ipAddressOctets,
		};

		batch(() => {
			dispatcher(setTitleModal(_('Searching')));
			setHasSearchButtonTriggered(true);
			dispatcher(setBroadcastData([]));
			dispatcher(setLoading(true));
		});

		const response = await deviceAdminApi.searchIPAddress(searchIPAddressConfig);

		NotificationStatus({
			responseData: response,
			notUseDefaultNotification: true,
			onSystemErrorCallback: () => {
				dispatcher(setLoading(false));
				notification['error']({
					message: response.ErrorMessage,
				});
			},
		});
	};

	const handleSelect = (record: DataType) => {
		batch(() => {
			setSelectedRowKeys([record.key.toString()]);
			dispatcher(setSNIBSelected(record));
		});
	};

	const getSubnetColumn = (): ColumnType<BroadCastDevice> => {
		if (!hasSearchButtonTriggered) {
			return previousSubnetColumn;
		}

		let newSubnetColumn: ColumnType<BroadCastDevice> = null;
		if (networkType === PortNetWorkType.IPv4) {
			newSubnetColumn = {
				...buildColumn(_('SubnetMask'), 'SubnetMask', '125px', 'start'),
				sorter: (a, b) => a.SubnetMask.localeCompare(b.SubnetMask),
			};
		} else {
			newSubnetColumn = {
				...buildColumn(_('SubnetPrefixLength'), 'IPv6Prefix', '125px', 'start'),
				sorter: (a, b) => a.IPv6Prefix.localeCompare(b.IPv6Prefix),
			};
		}

		batch(() => {
			setHasSearchButtonTriggered(false);
			setPreviousSubnetColumn(newSubnetColumn);
		});

		return newSubnetColumn;
	};

	const getDefaultGatewayColumn = (): ColumnType<BroadCastDevice> => {
		if (networkType === PortNetWorkType.IPv4) {
			return {
				...buildColumn(_('DefaultGateway'), 'DefaultGateway', '125px', 'start'),
				sorter: (a, b) => a.DefaultGateway.localeCompare(b.DefaultGateway),
			};
		}
		return {
			...buildColumn(_('DefaultGateway'), 'IPv6Gateway', '125px', 'start'),
			sorter: (a, b) => a.IPv6Gateway.localeCompare(b.IPv6Gateway),
		};
	};

	const getIPAddressColumn = (): ColumnType<BroadCastDevice> => {
		if (networkType === PortNetWorkType.IPv4) {
			return {
				...buildColumn(_('IPAddress'), 'IPAddress', '125px', 'start'),
				sorter: (a, b) => a.IPAddress.localeCompare(b.IPAddress),
			};
		}
		return {
			...buildColumn(_('IPAddress'), 'IPv6Address', '125px', 'start'),
			sorter: (a, b) => a.IPv6Address.localeCompare(b.IPv6Address),
		};
	};

	const columns: ColumnsType<BroadCastDevice> = [
		{
			...buildColumn(_('Name'), 'Hostname', '200px', 'start'),
			sorter: (a, b) => a.Hostname.localeCompare(b.Hostname),
			...SearchColumn({ maxLength, dataIndex: 'Hostname', reset: undefined, label: undefined, notVisible: undefined }),
		},
		{ ...useMemo(() => getIPAddressColumn(), [hasSearchButtonTriggered]) },
		{
			...buildColumn(_('Port'), 'Port', '80px', 'start'),
			sorter: (a, b) => a.Port.localeCompare(b.Port),
		},
		{ ...getSubnetColumn() },
		{ ...useMemo(() => getDefaultGatewayColumn(), [hasSearchButtonTriggered]) },
		{
			...buildColumn(_('MACAddress'), 'Id', '125px', 'start'),
			sorter: (a, b) => a.Id.localeCompare(b.Id),
		},
		{
			...buildColumn(_('Type'), 'Type', '125px', 'start'),
			sorter: (a, b) => a.Type.localeCompare(b.Type),
		},
		{
			...buildColumn(_('Version'), 'Version', '80px', 'start'),
			sorter: (a, b) => a.Version.localeCompare(b.Version),
		},
		{
			...buildColumn(_('DHCPEnabled'), 'DHCPEnabled', '80px', 'start'),
			sorter: (a, b) => a.DHCPEnabled.localeCompare(b.DHCPEnabled),
		},
	];

	const handleOnClickSearch = () => {
		batch(() => {
			dispatcher(setSNIBSelected(null));
			setSelectedRowKeys(['0-0']);
			searchIPAddress();
			setHasSearchButtonTriggered(true);
			setPagination({
				...getDefaultTablePaginationConfig(),
				current: 1,
				pageSize: pagination.pageSize,
			});
		});
	};

	const handleOnClickCancel = () => {
		batch(() => {
			deviceAdminApi
				.stopIPAddressSearch(pageId)
				.then(response => {
					NotificationStatus({
						responseData: response,
						notUseDefaultNotification: true,
						onSystemErrorCallback: () => {
							notification['error']({
								message: response.ErrorMessage,
							});
						},
					});
				})
				.finally(() => {
					dispatcher(setLoading(false));
				});
		});
	};

	const handleOnEditSNIBClick = () => {
		onHandleIsSearchTableView(false);
	};

	const searchSearchTableEditSNIButtonId = 'searchSearchTableEditSNIButton';
	const searchSearchTableSearchButtonId = 'searchSearchTableSearchButtonId';
	const searchSearchTableCancelButtonId = 'searchSearchTableCancelButtonId';

	const isEditSnibDisabled = useMemo(() => snibSelected === null, [snibSelected]);
	return (
		<div id='portSearchContainer' className={styles.container}>
			<div className={styles.row}>
				<SearchNetwork />
				<NetworkType />
				<SearchType />
				<div className={styles.portSearchContainerButtonsContainer}>
					<Button
						id={searchSearchTableSearchButtonId}
						className={styles.searchButton}
						type='default'
						onClick={handleOnClickSearch}
						disabled={loading || hasError}>
						{_('Search')}
					</Button>
					<Button
						id={searchSearchTableCancelButtonId}
						className={styles.searchButton}
						type='default'
						onClick={handleOnClickCancel}
						disabled={!loading}>
						{_('StopSearch')}
					</Button>
				</div>
			</div>
			<div className={styles.row}>
				<SearchIPRange />
			</div>
			<div className={styles.buttonsContainer}>
				<Button id={searchSearchTableEditSNIButtonId} type='default' onClick={handleOnEditSNIBClick} disabled={isEditSnibDisabled}>
					{_('EditSNIB')}
				</Button>
			</div>
			<Table
				id='portSearchSearchTable'
				columns={columns}
				dataSource={broadcastData}
				onChange={(pag: TablePaginationConfig) => {
					setPagination(pag);
				}}
				onRow={(record: DataType) => ({
					onClick: () => handleSelect(record),
				})}
				rowSelection={{
					onSelect: (record: DataType) => {
						handleSelect(record);
					},
					hideSelectAll: true,
					type: 'radio',
					selectedRowKeys,
				}}
				scroll={scroll}
				pagination={pagination}
				size='small'
				onHeaderRow={() => ({ id: 'portSearchSearchTableHeader' })}
				getPopupContainer={() => document.getElementById('portSearchSearchTableHeader')}
			/>
		</div>
	);
};

export { SearchTable };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error-message-error--1la3Lcnv97G_Uc1FXnLSAN>:first-child{border:solid 1px red}.error-message-error--1la3Lcnv97G_Uc1FXnLSAN>span{color:red}", "",{"version":3,"sources":["webpack://src/components/common/ErrorMessage/errorMessage.module.scss"],"names":[],"mappings":"AACC,0DACC,oBAAA,CAED,kDACC,SAAA","sourcesContent":[".error {\r\n\t& > :first-child {\r\n\t\tborder: solid 1px red;\r\n\t}\r\n\t& > span {\r\n\t\tcolor: red;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "error-message-error--1la3Lcnv97G_Uc1FXnLSAN"
};
export default ___CSS_LOADER_EXPORT___;

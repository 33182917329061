import React, { useState } from 'react';
import { CriteriaFunctionValue, CriteriaSearchRequest } from '../../../model/CommonModel';
import { CredentialSearchField, CredentialType } from '../../../model/EnrollmentModel';
import { Select } from '../../common';
import { CredentialTable } from '../../enrollment';
import { buildCredentialCriteriaRequest, credentialTypesOptions } from '../helper';
import styles from './credentialTab.module.scss';

const CredentialTab: React.FC = () => {
	const [credentialType, setCredentialType] = useState<CredentialType>(CredentialType.Unassigned);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [updateCounter, setUpdateCounter] = useState<number>(0);

	const userIdCriteriaRequest = buildCredentialCriteriaRequest(credentialType?.toString(), CredentialSearchField.PersonId, CriteriaFunctionValue.equalTo);
	const criteriaSearchRequest: CriteriaSearchRequest = { CriteriaRequest: [userIdCriteriaRequest] };

	const enrollmentCredentialTypeDropdownId = 'enrollmentCredentialTypeDropdown';

	return (
		<div className={styles.container}>
			<div>
				<label htmlFor={enrollmentCredentialTypeDropdownId}>{_('TypeCredential')}</label>
				<Select
					id={enrollmentCredentialTypeDropdownId}
					value={credentialType?.toString()}
					onChange={newValue => setCredentialType(Number(newValue) as CredentialType)}
					options={credentialTypesOptions}
					disabled={isLoading}
				/>
			</div>
			<CredentialTable
				userId={Number(credentialType)}
				criteriaSearchRequest={criteriaSearchRequest}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				setUpdateCounter={() => setUpdateCounter(prevState => prevState + 1)}
				updateCounter={updateCounter}
			/>
		</div>
	);
};

export { CredentialTab };

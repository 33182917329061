import React from 'react';

type Props = {
	messages: string[];
	className?: string;
};

const InformationMessages: React.FC<Props> = ({ messages, className }) => {
	const content = messages.map((message, key) => {
		return <p key={key}>{message}</p>;
	});
	return <div className={className}>{content}</div>;
};

export { InformationMessages };

import { createSelector } from 'reselect';
import { AvailableDoorInfo, PhotoCallUpUserConfiguration, PhotoCallUpUserInformationModel } from '../../model/PhotoCallUpModel';
import { RootReducer } from '../rootReducer';

export const selectPhotoCallUpUserConfiguration = createSelector(
	(state: RootReducer) => state.photoCallUpReducer.photoCallUpUserConfiguration,
	(photoCallUpUserConfiguration: PhotoCallUpUserConfiguration) => photoCallUpUserConfiguration
);

export const selectConfigurationNotification = createSelector(
	(state: RootReducer) => state.photoCallUpReducer.configurationNotification,
	(configurationNotification: number) => configurationNotification
);

export const selectOpenConfigurationModal = createSelector(
	(state: RootReducer) => state.photoCallUpReducer.openConfigurationModal,
	(openConfigurationModal: boolean) => openConfigurationModal
);

export const selectIsModalLoading = createSelector(
	(state: RootReducer) => state.photoCallUpReducer.isModalLoading,
	(isModalLoading: boolean) => isModalLoading
);

export const selectModalUserInfo = createSelector(
	(state: RootReducer) => state.photoCallUpReducer.modalUserInfo,
	(modalUserInfo: PhotoCallUpUserInformationModel) => modalUserInfo
);

export const selectMappedSelectedDoors = createSelector(
	(state: RootReducer) => state.photoCallUpReducer.selectedDoors,
	(selectedDoors: AvailableDoorInfo[]) =>
		selectedDoors.map((x: AvailableDoorInfo) => ({
			DoorId: x.DoorId,
			ControllerId: x.ControllerId,
			DoorName: x.DoorName,
			ControllerName: x.ControllerName,
			Color: x.Color,
			IsHidden: x.IsHidden,
		}))
);

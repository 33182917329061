import React, { useContext } from 'react';
import { ReaderInterface } from '../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { Interface, Name, RS485Version, ThreatLevel } from '../sections';
import styles from './general.module.scss';

type Props = {
	submittedForm: boolean;
	handleDownloadFirmware: () => void;
	setErrorText: () => void;
};

const General: React.FC<Props> = ({ submittedForm, handleDownloadFirmware, setErrorText }) => {
	const {
		contextStateDoor: {
			exitReader: { readerInterface },
		},
	} = useContext(DoorStoreSingleContext);

	return (
		<div id='generalExitReaderContainer' className={styles.form}>
			<Name setErrorText={setErrorText} />
			<div className={styles.sections}>
				<Interface></Interface>
				<div>
					<ThreatLevel></ThreatLevel>
				</div>
			</div>
			{readerInterface === ReaderInterface.RS485 && (
				<RS485Version submittedForm={submittedForm} handleDownloadFirmware={handleDownloadFirmware}></RS485Version>
			)}
		</div>
	);
};

export { General };

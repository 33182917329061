import { CheckOutlined, CheckSquareOutlined, CloseOutlined, CloseSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { Alert, Table } from 'antd';
import React from 'react';
import { getFullVelocityUrl, ScrollType } from '../../../Helper';
import { PivValidation } from '../../../model/EnrollmentModel';
import './pivCardInfo.scss';

// Normal properties for the component
type Props = {
	validationResult: PivValidation;
};

//Avoid creating object style inline, since increases reconciliations
const fieldCaptionTableScroll: ScrollType = { y: 300 };
const validationLogsTableScroll: ScrollType = { y: 200 };

const statusMessages = {
	success: _('CardValidationComplete'),
	error: _('CardValidationIncomplete'),
};

const getCertCheckIcon = certCheck => {
	if (certCheck === 'OK' || certCheck === 'pass' || certCheck === 'valid') {
		return <CheckSquareOutlined className={'enrollment-piv-icon'} />;
	}

	if (certCheck === 'Warn' || certCheck === 'warning') {
		return <MinusSquareOutlined className={'enrollment-piv-icon'} />;
	}

	if (certCheck === 'Fail' || certCheck === 'fail' || certCheck === 'invalid' || certCheck === '' || certCheck === 'Error') {
		return <CloseSquareOutlined className={'enrollment-piv-icon'} />;
	}
};

const getCertCheckStatus = acceptCard => {
	if (acceptCard) {
		return (
			<Alert
				message={statusMessages.success}
				type='success'
				showIcon
				icon={<CheckOutlined alt={statusMessages.success} />}
				className={'enrollment-piv-status'}
			/>
		);
	}

	return (
		<Alert message={statusMessages.error} type='error' showIcon icon={<CloseOutlined alt={statusMessages.error} />} className={'enrollment-piv-status'} />
	);
};

const downloadFile = fileName => {
	if (fileName) {
		const url = `${getFullVelocityUrl()}Enrollment/DownloadFile/?file=${fileName}`;
		window.open(url);
	}
};

const columns = [
	{
		title: _('FieldCaption'),
		dataIndex: 'fieldCaption',
		width: '19em',
	},
	{
		title: _('Value'),
		dataIndex: 'value',
		width: '19em',
	},
];

const PIVCardInfo = (props: Props) => {
	const { validationResult } = props;

	const data = [
		{
			fieldCaption: _('Name'),
			value: validationResult.PivCardData.PIVName,
		},
		{
			fieldCaption: `${_('EmployeeAffiliationLine')} 1`,
			value: validationResult.PivCardData.Line1,
		},
		{
			fieldCaption: `${_('EmployeeAffiliationLine')} 2`,
			value: validationResult.PivCardData.Line2,
		},
		{
			fieldCaption: `${_('ExpirationDate')} (${_('Printed')})`,
			value: validationResult.PivCardData.PIVExpiration,
		},
		{
			fieldCaption: _('AgencyCardSerialNumber'),
			value: validationResult.PivCardData.PIVAgencyCSN,
		},
		{
			fieldCaption: _('IssuerIdentification'),
			value: validationResult.PivCardData.PIVIssuerID,
		},
		{
			fieldCaption: _('AgencyCode'),
			value: validationResult.PivCardData.AC,
		},
		{
			fieldCaption: _('SystemCode'),
			value: validationResult.PivCardData.SC,
		},
		{
			fieldCaption: _('CredentialNumber'),
			value: validationResult.PivCardData.CN,
		},
		{
			fieldCaption: _('CredentialSeries'),
			value: validationResult.PivCardData.CS,
		},
		{
			fieldCaption: _('IndividualCredentialIssue'),
			value: validationResult.PivCardData.ICI,
		},
		{
			fieldCaption: _('PersonIdentifier'),
			value: validationResult.PivCardData.PI,
		},
		{
			fieldCaption: _('OrganizationalCategory'),
			value: validationResult.PivCardData.OC,
		},
		{
			fieldCaption: _('OrganizationalIdentifier'),
			value: validationResult.PivCardData.OI,
		},
		{
			fieldCaption: _('PersonOrgAssocCategory'),
			value: validationResult.PivCardData.POA,
		},
		{
			fieldCaption: _('ExpirationDate'),
			value: validationResult.PivCardData.ED,
		},
		{
			fieldCaption: `${_('AgencyCode')} (CHUID)`,
			value: validationResult.PivCardData.CHUID_AC,
		},
		{
			fieldCaption: `${_('OrganizationIdentifier')} (CHUID)`,
			value: validationResult.PivCardData.CHUID_OI,
		},
		{
			fieldCaption: 'DUNS',
			value: validationResult.PivCardData.DUNS,
		},
		{
			fieldCaption: 'GUID',
			value: validationResult.PivCardData.GUID,
		},
		{
			fieldCaption: `${_('OrganizationalAffiliationLine')} 1`,
			value: validationResult.PivCardData.PIVAffiliation1,
		},
		{
			fieldCaption: `${_('OrganizationalAffiliationLine')} 2`,
			value: validationResult.PivCardData.PIVAffiliation2,
		},
	];

	const dataSource = data.map((item, index) => {
		return {
			key: index,
			...item,
		};
	});

	const columnsValidation = [
		{
			title: _('ValidationLogs'),
			dataIndex: 'validationLogs',
		},
	];

	const dataValidation = validationResult.CertCheckResponse.ValidationLogs.map((item, index) => {
		return {
			key: index,
			validationLogs: (
				<>
					{getCertCheckIcon(item.Result)}
					{item.Validator}
				</>
			),
		};
	});

	const fieldCaptionTable = (
		<Table
			id={'fieldCaptionTable'}
			columns={columns}
			dataSource={dataSource}
			pagination={false}
			bordered={true}
			size={'small'}
			scroll={fieldCaptionTableScroll}
			className='enrollment-piv-table enrollment-piv-table-fields'
		/>
	);

	const validationLogsTable = (
		<Table
			id={'validationLogsTable'}
			columns={columnsValidation}
			dataSource={dataValidation}
			pagination={false}
			bordered={true}
			size={'small'}
			scroll={validationLogsTableScroll}
			className='enrollment-piv-table enrollment-piv-table-validation'
		/>
	);

	const cardValidationStatus = (
		<>
			{getCertCheckStatus(validationResult.CertCheckResponse.OKToAcceptCard)}
			<br />
			<p>
				<img
					className={'enrollment-piv-photo'}
					src={`data:image/bmp;base64,${validationResult.PivCardData.FacialImageBase64}`}
					alt={validationResult.PivCardData.PIVName}
				/>
			</p>
			<p>
				<label>{_('Name')}:</label>
				<br />
				<span>{validationResult.PivCardData.PIVName}</span>
			</p>
			<p>
				<label>UID:</label>
				<br />
				<span>{validationResult.PivCardData.PivFascn}</span>
			</p>
			<p>
				<label>{_('Expiration')}:</label>
				<br />
				<span>{validationResult.PivCardData.PIVExpiration}</span>
			</p>
			<p>
				{getCertCheckIcon(validationResult.PivCardData.CardChallenge)}
				<span>{_('CardChallenge')}</span>
			</p>
			<p>
				{getCertCheckIcon(validationResult.CertCheckResponse.CardChecks)}
				<span>{_('CardChecks')}</span>
			</p>
			<p>
				{getCertCheckIcon(validationResult.CertCheckResponse.CardAuthCert)}
				<a
					onClick={() => {
						downloadFile(validationResult.PivCardData.CardAuthCertificateFileName1);
					}}>
					<span>{_('CardAuthCert')}</span>
				</a>
			</p>
			<p>
				{getCertCheckIcon(validationResult.CertCheckResponse.PivAuthCert)}
				<a
					onClick={() => {
						downloadFile(validationResult.PivCardData.PIVAuthCertificateFileName2);
					}}>
					<span>{_('PIVAuthCert')}</span>
				</a>
			</p>
		</>
	);

	return (
		<div className='enrollment-piv-card-info-container'>
			<div className='enrollment-piv-card-left'>{cardValidationStatus}</div>
			<div className='enrollment-piv-card-right'>
				{fieldCaptionTable}
				{validationLogsTable}
			</div>
		</div>
	);
};

export { PIVCardInfo };

import { Checkbox } from 'antd';
import React from 'react';
import { OptionsInstalledInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerPropertyAction } from '../../../../../../../store/deviceControl/controller/actions';
import { selectOptionsInstalledInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../general.module.scss';

const OptionsInstalled: React.FC = () => {
	const optionsInstalledInfo: OptionsInstalledInfoStore = useStoreSelector<OptionsInstalledInfoStore>(selectOptionsInstalledInfo);
	const dispatch = useStoreDispatch();

	const handleChangeControllerProperty = (value: boolean) => {
		dispatch(
			setControllerPropertyAction({
				BufferAllocationInfo: {
					...optionsInstalledInfo.allocateBufferStatus,
					Allocation: value,
				},
			})
		);
	};

	const installedOptions = optionsInstalledInfo.optionsInstalled.map((option, index) => {
		return <label key={index}>{option}</label>;
	});

	return (
		<CardSection title={_('OptionsInstalled')} height='334px' width='100%'>
			<div className={styles.optionsInstalled}>
				<Checkbox
					id='controllerGeneralAllocateMemoryCheckbox'
					onChange={e => handleChangeControllerProperty(e.target.checked)}
					checked={optionsInstalledInfo.allocateBufferStatus.Allocation}
					disabled={!optionsInstalledInfo.allocateBufferStatus.IsAllocationEnabled}>
					{_('AllocateControllerMemory')}
				</Checkbox>
				<div className={styles.square}>{...installedOptions}</div>
			</div>
		</CardSection>
	);
};

export { OptionsInstalled };

import React from 'react';
import { UseModel } from '../../../../../model/DeviceAdminModel';
import styles from './timeZoneAlert.module.scss';

type Props = {
	records: UseModel[];
	title: string;
};

const TimeZoneAlert: React.FC<Props> = ({ records, title }) => {
	return (
		<div>
			<br />
			<label id='timeZonesInUsedByErrorMessage' className={styles.title}>
				{title}
			</label>
			<div className={styles.container}>
				<div>{_('Type')}</div>
				<div>{_('Index')}</div>
				<div>{_('Name')}</div>
				{records.map((x, index) => (
					<React.Fragment key={index}>
						<div>{x.Type}</div>
						<div>{x.Index}</div>
						<div>{x.Name}</div>
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export { TimeZoneAlert };

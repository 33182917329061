import { InputNumber } from 'antd';
import React from 'react';
import { getTimeZoneInfoByList, noneDecimalFormatter } from '../../../../../../../Helper';
import { SelectTimeZone, TimeZoneInfo, TwoPersonRuleInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { ControllerPassback, ErrorTabs } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { setControllerError, setControllerPassbackSetupAction, setOpenTimeZones } from '../../../../../../../store/deviceControl/controller/actions';
import { selectError, selectGenericTimeZones, selectTwoPersonRuleInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { ErrorMessage, Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../passback.module.scss';

const TwoPersonRule: React.FC = () => {
	const dispatch = useStoreDispatch();
	const twoPersonRuleInfo: TwoPersonRuleInfoStore = useStoreSelector<TwoPersonRuleInfoStore>(selectTwoPersonRuleInfo);
	const timeZones: SelectTimeZone[] = useStoreSelector<SelectTimeZone[]>(selectGenericTimeZones);
	const error: ErrorTabs = useStoreSelector<ErrorTabs>(selectError);

	const handleChangeControllerPassBackSetup = (value: ControllerPassback) => {
		dispatch(
			setControllerPassbackSetupAction({
				...value,
			})
		);
	};

	const controllerPassbackTimeIncrementInputId = 'controllerPassbackTimeIncrementInput';
	const controllerPassbackThresholdInputId = 'controllerPassbackThresholdInput';
	const controllerPassbackTwoManDisableTimeZoneDropdownId = 'controllerPassbackTwoManDisableTimeZoneDropdown';

	const handleChangeIncrementForEachRead = (value: string | number) => {
		if (!value || value === undefined || (value as number) < 1) {
			value = 1;
		}

		const timeIncrementForEachRead = Number(value);
		dispatch(setControllerError({ twoPersonsRules: false, message: '' }));
		if ((value as number) >= twoPersonRuleInfo.thresholdTime) {
			dispatch(setControllerError({ twoPersonsRules: true }));
		}
		handleChangeControllerPassBackSetup({ TwoPersonTimeIncrement: timeIncrementForEachRead });
	};

	const handleChangePersonThresholdTime = (value: string | number) => {
		if (!value || value === undefined || (value as number) < 2) {
			value = 2;
		}

		const twoPersonThresholdTime = Number(value);
		if (error.twoPersonsRules) {
			dispatch(setControllerError({ twoPersonsRules: false, message: '' }));
		}
		if ((value as number) <= twoPersonRuleInfo.timeIncrementForEachRead) {
			dispatch(setControllerError({ twoPersonsRules: true }));
		}
		handleChangeControllerPassBackSetup({ TwoPersonThresholdTime: twoPersonThresholdTime });
	};

	const disableTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(twoPersonRuleInfo.disableDuringTimeZoneId, timeZones);

	return (
		<CardSection title={`${_('2PersonRule')}:`}>
			<div className={styles.twoPersonRule}>
				<div>
					<div>
						<label htmlFor={controllerPassbackTimeIncrementInputId}>{_('TimeIncrementForEachRead')}</label>
						<InputNumber
							id={controllerPassbackTimeIncrementInputId}
							min={1}
							max={99}
							maxLength={2}
							onChange={handleChangeIncrementForEachRead}
							value={twoPersonRuleInfo.timeIncrementForEachRead}
							formatter={noneDecimalFormatter}
						/>
						<label htmlFor={controllerPassbackTimeIncrementInputId}>(1-99 {_('Seconds')})</label>
					</div>
					<div>
						<label htmlFor={controllerPassbackThresholdInputId}>{_('ThresholdTime')}</label>
						<ErrorMessage
							message={_('ThresholdTimeMustBeGreaterThanIncrementTime')}
							show={error.twoPersonsRules && twoPersonRuleInfo.timeIncrementForEachRead >= twoPersonRuleInfo.thresholdTime}>
							<InputNumber
								id={controllerPassbackThresholdInputId}
								min={2}
								max={100}
								maxLength={3}
								onChange={handleChangePersonThresholdTime}
								value={twoPersonRuleInfo.thresholdTime}
								formatter={noneDecimalFormatter}
							/>
						</ErrorMessage>
						<label htmlFor={controllerPassbackThresholdInputId}>(2-100 {_('Seconds')})</label>
					</div>
				</div>
				<div>
					<label htmlFor={controllerPassbackTwoManDisableTimeZoneDropdownId}>{_('DisableTimezone')}</label>
					<Select
						id={controllerPassbackTwoManDisableTimeZoneDropdownId}
						onChange={value => {
							if (Number(value) === 0) {
								dispatch(
									setOpenTimeZones({
										index: -1,
										open: true,
										propertyType: 'TwoPersonRule',
									})
								);
							} else {
								handleChangeControllerPassBackSetup({ TwoPersonDisableTimeZonePassback: Number(value) });
							}
						}}
						value={twoPersonRuleInfo.disableDuringTimeZoneId}
						notUseCurrentParse
						options={disableTimeZoneInfo.TimeZoneList}
						disabled={disableTimeZoneInfo.IsUnpermitted}
					/>
				</div>
			</div>
		</CardSection>
	);
};

export { TwoPersonRule };

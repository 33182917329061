import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Tooltip, notification } from 'antd';
import React from 'react';
import { getFullVelocityUrl, handleResponse } from '../../Helper';
import { whosInsideApi } from '../../api';
import { ResponseObjectEntity, ResponseStatusCode } from '../../model/CommonModel';
import { CredentialDescription } from '../../model/EnrollmentModel';
import { Logger } from '../../model/LoggingModel';
import {
	ActionToExecute,
	MoveCredential,
	WhosInsideAction,
	WhosInsideActionDetail,
	WhosInsideCurrentAction,
	Zone,
	ZoneCredentialsColumns,
	ZoneGroup,
	ZoneGroupTree,
	ZoneKey,
} from '../../model/WhosInsideModel';
import { InformationMessages, ModalConfirmation, ModalInfo, ModalWarning } from '../common';
import { TreeNodeName, WhosInsideContextMenu } from '../whosInside';
import styles from './whosInside.module.scss';

const velocityUrl: string = getFullVelocityUrl();
const highlightStyle: React.CSSProperties = { color: '#1890ff', fontWeight: 'bold' };
const breakSpace: React.CSSProperties = { whiteSpace: 'break-spaces' };
const breakSpaceExpanded: React.CSSProperties = { whiteSpace: 'break-spaces', display: 'flex', alignItems: 'center', height: '41px' };
const folderIconStyle: React.CSSProperties = { marginLeft: '-48px', height: '42px', display: 'block', marginRight: '15px' };
const expandedLabel: React.CSSProperties = {
	whiteSpace: 'break-spaces',
	marginLeft: '-11px',
	height: '42px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const handleOpenReport = () => {
	whosInsideApi.makeReport().then(response => {
		switch (response.ResponseStatusCode) {
			case ResponseStatusCode.Success:
				const fileName = `${velocityUrl}/Content/Reports/${response.Entity.Name}`;
				let windowOpen = null;
				try {
					const e: Window = window.open(fileName);
					windowOpen = true;
				} catch (error) {
					Logger.writeErrorLog(error);
					windowOpen = false;
				}
				if (!windowOpen) {
					ModalConfirmation({
						content: <div>{_('ReportGeneratedWhosInside')}</div>,
						onConfirm: () => {
							window.open(fileName);
							Modal.destroyAll();
						},
						okText: _('OpenReport'),
					});
				}
				break;

			case ResponseStatusCode.PermissionError:
				handleResponse(response);
				break;

			default:
				ModalWarning({
					okText: _('Ok'),
					content: <span id='whosInsideReportError'>{_('ReportErrorDesc').replace('%1', `${response.ResponseStatusCode}`)}</span>,
				});
				break;
		}
	});
};

export const onForgiveCredentials = async (zoneCredentialsSelected: React.Key[]): Promise<void> => {
	const credentialsIds: number[] = zoneCredentialsSelected.map(x => Number(x));
	const response = await whosInsideApi.forgiveCredentials(credentialsIds);
	handleResponse(response);
};

export const highLightText = (
	data: any,
	searchValue: string,
	maxLength: number,
	currentEditionAction?: WhosInsideCurrentAction,
	handleCancelRename?: () => void,
	handleSaveRename?: () => void,
	setContextMenu?: (visible: boolean) => void,
	onlyCount?: boolean
) => {
	const searchText = searchValue ? searchValue.trim().toLowerCase() : '';
	return data.map((item, dataIndex) => {
		let title;
		let showIcon: boolean = true;
		const index: number = item.title.toLowerCase().indexOf(searchText);
		let titleItem = onlyCount && item.isLeaf ? item.credentialsOnZone : onlyCount && !item.isLeaf ? '' : item.title;
		if (onlyCount && item.zone?.ZoneKey === ZoneKey.EveryOne) {
			showIcon = false;
			titleItem = item.zone?.CredentialsOnZoneFormat;
		}

		if (currentEditionAction && currentEditionAction.type === ActionToExecute.Rename) {
			if (item.key == currentEditionAction.key) {
				showIcon = false;
				title = (
					<div className={styles.renameContainer}>
						<div className={styles.button}>
							<Button id='whosInsideSaveRename' type='primary' onClick={handleSaveRename} shape='circle' icon={<CheckOutlined />} />
						</div>
						<div className={styles.button}>
							<Button
								id='whosInsideCancelRename'
								onClick={handleCancelRename}
								shape='circle'
								icon={<CloseOutlined style={{ color: '#406080' }} />}
							/>
						</div>
						<Form.Item name={'zoneName'} className={styles.renameInputContainer}>
							<Input id='whosInsideRenameInput' className={styles.renameInput} maxLength={maxLength} />
						</Form.Item>
					</div>
				);
			} else if (item.zone?.ZoneKey === ZoneKey.EveryOne) {
				title = (
					<span id={`everyoneNode-${item.key}-${dataIndex}`} style={breakSpace}>
						{item.title}
					</span>
				);
			} else {
				title = (
					<TreeNodeName
						nodeKey={`${item.key}-${dataIndex}`}
						hasChildren={item.children}
						name={item.title}
						count={item.credentialsOnZone}
						showCountInBack={item.showCountInBack}
					/>
				);
			}
		} else if (index > -1 && searchText) {
			const beforeStr = titleItem.substr(0, index);
			const matchStr = titleItem.substr(index, searchText.length);
			const afterStr = titleItem.substr(index + searchText.length);
			const highlightedName: JSX.Element = (
				<span>
					{beforeStr}
					<span style={highlightStyle}>{matchStr}</span>
					{afterStr}
				</span>
			);

			let folderIcon;
			if ((item.zone?.ZoneKey === ZoneKey.WholeGroup || item.key === '0-1' || item.key === '0-2' || item.key === '0-3') && onlyCount) {
				folderIcon = (
					<span style={folderIconStyle}>
						<strong>&nbsp;</strong>
					</span>
				);
			} else if (onlyCount) {
				folderIcon = (
					<span id={`count-${item.key}-${dataIndex}`} style={expandedLabel}>
						<strong>{titleItem}</strong>
					</span>
				);
			}

			title =
				item.options && !onlyCount ? (
					<WhosInsideContextMenu menu={item.options} onVisibleChange={setContextMenu}>
						<span>
							<TreeNodeName
								nodeKey={`${item.key}-${dataIndex}`}
								hasChildren={item.children}
								name={highlightedName}
								count={item.credentialsOnZone}
								showCountInBack={item.showCountInBack}
							/>
						</span>
					</WhosInsideContextMenu>
				) : onlyCount ? (
					<Tooltip placement='right' title={<span style={breakSpace}>{item.zone?.ZoneKey === ZoneKey.EveryOne ? 'Everyone' : item.title}</span>}>
						{folderIcon}
					</Tooltip>
				) : (
					<span>
						<span id={`name-${item.key}-${dataIndex}`} style={breakSpace}>
							{item.title}
						</span>
					</span>
				);
		} else {
			let folderIcon;
			if ((item.zone?.ZoneKey === ZoneKey.WholeGroup || item.key === '0-1' || item.key === '0-2' || item.key === '0-3') && onlyCount) {
				folderIcon = (
					<span style={folderIconStyle}>
						<strong>&nbsp;</strong>
					</span>
				);
			} else if (onlyCount) {
				folderIcon = (
					<span id={`count-${item.key}-${dataIndex}`} style={expandedLabel}>
						<strong>{titleItem}</strong>
					</span>
				);
			}

			title =
				item.options && !onlyCount ? (
					<WhosInsideContextMenu menu={item.options} onVisibleChange={setContextMenu}>
						<span>
							<TreeNodeName
								nodeKey={`${item.key}-${dataIndex}`}
								hasChildren={item.children}
								name={item.title}
								count={item.credentialsOnZone}
								showCountInBack={item.showCountInBack}
							/>
						</span>
					</WhosInsideContextMenu>
				) : onlyCount ? (
					<Tooltip placement='right' title={<span style={breakSpace}>{item.zone?.ZoneKey === ZoneKey.EveryOne ? 'Everyone' : item.title}</span>}>
						{folderIcon}
					</Tooltip>
				) : (
					<span>
						<span id={`name-${item.key}-${dataIndex}`} style={breakSpaceExpanded}>
							{item.title}
						</span>
					</span>
				);
		}

		if (item.children) {
			return {
				title,
				key: item.key,
				children: highLightText(
					item.children,
					searchValue,
					maxLength,
					currentEditionAction,
					handleCancelRename,
					handleSaveRename,
					setContextMenu,
					onlyCount
				),
				zone: item.zone,
				isLeaf: item.isLeaf,
				icon: showIcon ? item.icon : <span />,
				options: item.options,
			};
		}

		return {
			title,
			key: item.key,
			zone: item.zone,
			isLeaf: item.isLeaf,
			icon: showIcon ? item.icon : <span />,
			options: item.options,
		};
	});
};

export const keywordFilter = (nodes, keyword, searchValue) => {
	let newNodes: string[] = [];
	for (let n of nodes) {
		let childrenNodes: string[] = [];
		if (n.children) {
			childrenNodes = keywordFilter(n.children, keyword, searchValue);
		}

		if (childrenNodes.length || n.title.toLowerCase().indexOf(searchValue) > -1) {
			newNodes.push(n.key);
			newNodes = newNodes.concat(childrenNodes);
		}
	}

	return newNodes;
};

const updateZoneNode = (zoneGroups: ZoneGroup[], zone: Zone, addZoneIfMissing?: boolean): ZoneGroup[] => {
	const zoneGroupIndex: number = zoneGroups.findIndex(x => x.ZoneGroupId === zone.ZoneGroupId);
	if (~zoneGroupIndex) {
		const zoneIndex: number = zoneGroups[zoneGroupIndex].Zones.findIndex(x => x.ZoneId === zone.ZoneId);
		if (~zoneIndex) {
			const item = zoneGroups[zoneGroupIndex].Zones[zoneIndex];
			item.CredentialsOnZone = zone.CredentialsOnZone;
			item.CredentialsOnZoneFormat = zone.CredentialsOnZoneFormat;
		} else if (addZoneIfMissing) {
			zoneGroups[zoneGroupIndex].Zones.push(zone);
		}
	}

	return zoneGroups;
};

const removeZone = (zoneGroups: ZoneGroup[], zoneGroupId: number, zoneId: number): ZoneGroup[] => {
	const zoneGroupIndex: number = zoneGroups.findIndex(x => x.ZoneGroupId === zoneGroupId);
	if (~zoneGroupIndex) {
		const zoneIndex: number = zoneGroups[zoneGroupIndex].Zones.findIndex(x => x.ZoneId === zoneId);
		if (~zoneIndex) {
			zoneGroups[zoneGroupIndex].Zones.splice(zoneIndex, 1);
		}
	}

	return zoneGroups;
};

const handleModalWarning = (errorMessages: string) => {
	const messages: string[] = errorMessages.split('\n');

	const content: JSX.Element = (
		<div className={styles.warningMessages}>
			<InformationMessages messages={Array.from(new Set(messages))} />
		</div>
	);
	ModalInfo({
		content: content,
		hideCancelButton: true,
		okText: _('Ok'),
		width: '600px',
	});
};

export const handleWhosInsideAction = (actionDetail: WhosInsideActionDetail[], zoneGroupTreeReference: ZoneGroupTree): ZoneGroupTree => {
	let currentZoneGroupTree: ZoneGroupTree = zoneGroupTreeReference;
	actionDetail.forEach((action: WhosInsideActionDetail) => {
		switch (action.WhosInsideAction) {
			case WhosInsideAction.DeleteZoneGroup:
				if (action.IsGlobalized) {
					currentZoneGroupTree.GlobalizedGroup = currentZoneGroupTree.GlobalizedGroup.filter(x => x.ZoneGroupId !== action.ZoneGroupId);
				} else {
					currentZoneGroupTree.CustomGroup = currentZoneGroupTree.CustomGroup.filter(x => x.ZoneGroupId !== action.ZoneGroupId);
				}
				break;

			case WhosInsideAction.AddZoneGroup:
				if (action.IsGlobalized) {
					currentZoneGroupTree.GlobalizedGroup.push(action.NewZoneGroup);
					currentZoneGroupTree.GlobalizedGroup.sort();
				} else {
					currentZoneGroupTree.CustomGroup.push(action.NewZoneGroup);
					currentZoneGroupTree.CustomGroup.sort();
				}
				break;

			case WhosInsideAction.Passback:
				if (action.IsOldGlobalized) {
					currentZoneGroupTree.GlobalizedGroup = updateZoneNode(currentZoneGroupTree.GlobalizedGroup, action.OldZonePassback);
				} else {
					currentZoneGroupTree.CustomGroup = updateZoneNode(currentZoneGroupTree.CustomGroup, action.OldZonePassback);
				}

				if (action.IsNewGlobalized) {
					currentZoneGroupTree.GlobalizedGroup = updateZoneNode(currentZoneGroupTree.GlobalizedGroup, action.NewZonePassback);
				} else {
					currentZoneGroupTree.CustomGroup = updateZoneNode(currentZoneGroupTree.CustomGroup, action.NewZonePassback);
				}
				break;

			case WhosInsideAction.RemoveEmptyZone:
				if (action.IsGlobalized) {
					currentZoneGroupTree.GlobalizedGroup = removeZone(currentZoneGroupTree.GlobalizedGroup, action.ZoneGroupId, action.ZoneId);
				} else if (action.IsSharedZone) {
					const zoneIndex: number = currentZoneGroupTree.SharedZones.findIndex(x => x.ZoneId === action.ZoneId);
					if (~zoneIndex) {
						currentZoneGroupTree.SharedZones.splice(zoneIndex, 1);
					}
				} else {
					currentZoneGroupTree.CustomGroup = removeZone(currentZoneGroupTree.CustomGroup, action.ZoneGroupId, action.ZoneId);
				}
				break;

			case WhosInsideAction.UpdateZone:
				if (action.IsGlobalized) {
					currentZoneGroupTree.GlobalizedGroup = updateZoneNode(currentZoneGroupTree.GlobalizedGroup, action.NewZone);
				} else {
					currentZoneGroupTree.CustomGroup = updateZoneNode(currentZoneGroupTree.CustomGroup, action.NewZone);
				}
				break;

			case WhosInsideAction.AddOrUpdateZone:
				if (action.IsGlobalized) {
					action.NewZone.ZoneKey = ZoneKey.Globalized;
					currentZoneGroupTree.GlobalizedGroup = updateZoneNode(currentZoneGroupTree.GlobalizedGroup, action.NewZone, true);
				} else if (action.IsSharedZone) {
					const zoneIndex: number = currentZoneGroupTree.SharedZones.findIndex(x => x.ZoneId === action.ZoneId);
					if (~zoneIndex) {
						const item = currentZoneGroupTree.SharedZones[zoneIndex];
						item.CredentialsOnZone = action.NewZone.CredentialsOnZone;
						item.CredentialsOnZoneFormat = action.NewZone.CredentialsOnZoneFormat;
					} else {
						action.NewZone.ZoneKey = ZoneKey.Shared;
						currentZoneGroupTree.SharedZones.push(action.NewZone);
					}
				} else {
					action.NewZone.ZoneKey = ZoneKey.Custom;
					currentZoneGroupTree.CustomGroup = updateZoneNode(currentZoneGroupTree.CustomGroup, action.NewZone, true);
				}
				break;

			case WhosInsideAction.UpdateEveryoneGroup:
				currentZoneGroupTree.EveryoneGroup = action.EveryoneGroup;
				break;
		}
	});

	return currentZoneGroupTree;
};

export const handleOnMoveCredential = async (zoneCredentials: CredentialDescription[], moveCredentialInfo: Partial<MoveCredential>, sameZoneIndex: boolean) => {
	let i: number = 0;
	for (const credential of zoneCredentials) {
		moveCredentialInfo.CredentialId = credential.Id;
		moveCredentialInfo.CredentialDescription = credential.Description;
		if (sameZoneIndex) {
			notification['error']({
				message: _(`ResponseStatusCode_${ResponseStatusCode.SystemError}`),
				duration: 4 + i,
			});
		} else {
			let action: ResponseObjectEntity<string> = await whosInsideApi.moveCredentialOutsideZoneGroup(moveCredentialInfo);

			switch (action.ResponseStatusCode) {
				case ResponseStatusCode.Success:
					notification['info']({ message: action.Entity, duration: 4 + i });
					break;

				case ResponseStatusCode.SystemError:
					notification['error']({
						message: _(`ResponseStatusCode_${ResponseStatusCode.SystemError}`),
						duration: 4 + i,
					});
					break;

				case ResponseStatusCode.PermissionError:
					notification['error']({
						message: action.ResponseErrorDescription,
						duration: 4 + i,
					});
					break;

				default:
					handleResponse(action);
					break;
			}
		}

		i++;
	}
};

export const getRenamedZones = (zones: Zone[], zoneId: number, name: string): Zone[] => {
	let newZones: Zone[] = zones;
	const zoneIndex: number = newZones.findIndex(x => x.ZoneId === zoneId);
	if (~zoneIndex) {
		newZones[zoneIndex].ZoneName = name;
	}

	return newZones;
};

export const renameTreeGroupZoneNode = (zoneGroup: ZoneGroup[], zoneGroupId: number, zoneId: number, name: string): ZoneGroup[] => {
	let newZoneGroups: ZoneGroup[] = zoneGroup;
	const groupIndex: number = newZoneGroups.findIndex(x => x.ZoneGroupId === zoneGroupId);
	if (~groupIndex) {
		newZoneGroups[groupIndex].Zones = getRenamedZones(newZoneGroups[groupIndex].Zones, zoneId, name);
	}

	return newZoneGroups;
};

export const getRenamedGroups = (zoneGroup: ZoneGroup[], zoneGroupId: number, name: string): ZoneGroup[] => {
	let newZoneGroups: ZoneGroup[] = zoneGroup;
	const groupIndex: number = newZoneGroups.findIndex(x => x.ZoneGroupId === zoneGroupId);
	if (~groupIndex) {
		newZoneGroups[groupIndex].ZoneGroupName = name;
	}

	return newZoneGroups;
};

export const getZoneCounter = (zoneId: number, zoneGroupId: number, zoneKey: ZoneKey, storedZoneGroupTree: ZoneGroupTree): number => {
	let currentTree: ZoneGroupTree = { ...storedZoneGroupTree };
	let zoneIndexHelper: number = -1;
	let zoneGroupIndexHelper: number = -1;
	let zoneCounter: number = 0;
	switch (zoneKey) {
		case ZoneKey.EveryOne:
			zoneCounter = currentTree.EveryoneGroup.CredentialsOnZone;
			break;

		case ZoneKey.Shared:
			zoneIndexHelper = currentTree.SharedZones.findIndex(x => x.ZoneId === zoneId);
			if (zoneIndexHelper !== -1) {
				zoneCounter = currentTree.SharedZones[zoneIndexHelper].CredentialsOnZone;
			}
			break;

		case ZoneKey.Globalized:
			zoneGroupIndexHelper = currentTree.GlobalizedGroup.findIndex(x => x.ZoneGroupId === zoneGroupId);
			if (zoneGroupIndexHelper !== -1) {
				zoneIndexHelper = currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones.findIndex(x => x.ZoneId === zoneId);
				if (zoneIndexHelper !== -1) {
					zoneCounter = currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].CredentialsOnZone;
				}
			}
			break;

		case ZoneKey.Custom:
			zoneGroupIndexHelper = currentTree.CustomGroup.findIndex(x => x.ZoneGroupId === zoneGroupId);
			if (zoneGroupIndexHelper !== -1) {
				zoneIndexHelper = currentTree.CustomGroup[zoneGroupIndexHelper].Zones.findIndex(x => x.ZoneId === zoneId);
				if (zoneIndexHelper !== -1) {
					zoneCounter = currentTree.CustomGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].CredentialsOnZone;
				}
			}
			break;
	}

	return zoneCounter;
};

export const getUpdatedZoneGroupNodes = (newZone: Zone, zoneKey: ZoneKey, zoneGroupTree: ZoneGroupTree): ZoneGroupTree => {
	let currentTree: ZoneGroupTree = { ...zoneGroupTree };
	let zoneIndexHelper: number = -1;
	let zoneGroupIndexHelper: number = -1;
	switch (zoneKey) {
		case ZoneKey.EveryOne:
			currentTree.EveryoneGroup.CredentialsOnZone = newZone.CredentialsOnZone;
			currentTree.EveryoneGroup.CredentialsOnZoneFormat = newZone.CredentialsOnZoneFormat;
			break;

		case ZoneKey.Shared:
			zoneIndexHelper = currentTree.SharedZones.findIndex(x => x.ZoneId === newZone.ZoneId);
			if (zoneIndexHelper !== -1) {
				currentTree.SharedZones[zoneIndexHelper].ZoneName = newZone.ZoneName;
				currentTree.SharedZones[zoneIndexHelper].CredentialsOnZone = newZone.CredentialsOnZone;
				currentTree.SharedZones[zoneIndexHelper].CredentialsOnZoneFormat = newZone.CredentialsOnZoneFormat;
			}
			break;

		case ZoneKey.Globalized:
			zoneGroupIndexHelper = currentTree.GlobalizedGroup.findIndex(x => x.ZoneGroupId === newZone.ZoneGroupId);
			if (zoneGroupIndexHelper !== -1) {
				zoneIndexHelper = currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones.findIndex(x => x.ZoneId === newZone.ZoneId);
				if (zoneIndexHelper !== -1) {
					currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].ZoneName = newZone.ZoneName;
					currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].CredentialsOnZone = newZone.CredentialsOnZone;
					currentTree.GlobalizedGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].CredentialsOnZoneFormat = newZone.CredentialsOnZoneFormat;
				}
			}
			break;

		case ZoneKey.Custom:
			zoneGroupIndexHelper = currentTree.CustomGroup.findIndex(x => x.ZoneGroupId === newZone.ZoneGroupId);
			if (zoneGroupIndexHelper !== -1) {
				zoneIndexHelper = currentTree.CustomGroup[zoneGroupIndexHelper].Zones.findIndex(x => x.ZoneId === newZone.ZoneId);
				if (zoneIndexHelper !== -1) {
					currentTree.CustomGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].ZoneName = newZone.ZoneName;
					currentTree.CustomGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].CredentialsOnZone = newZone.CredentialsOnZone;
					currentTree.CustomGroup[zoneGroupIndexHelper].Zones[zoneIndexHelper].CredentialsOnZoneFormat = newZone.CredentialsOnZoneFormat;
				}
			}
			break;
	}

	return currentTree;
};

const defaultWhosInsideColumnWidth = [
	{
		key: ZoneCredentialsColumns.CredentialId,
		width: 120,
		columnWidth: '10em',
	},
	{
		key: ZoneCredentialsColumns.Name,
		width: 250,
		columnWidth: 'auto',
	},
	{
		key: ZoneCredentialsColumns.Zone,
		width: 250,
		columnWidth: 'auto',
	},
	{
		key: ZoneCredentialsColumns.CredentialFunction,
		width: 160,
		columnWidth: '12em',
	},
	{
		key: ZoneCredentialsColumns.LastZoneUpdate,
		width: 180,
		columnWidth: '13em',
	},
	{
		key: ZoneCredentialsColumns.LastAccessedReader,
		width: 250,
		columnWidth: 'auto',
	},
	{
		key: ZoneCredentialsColumns.LastTransactionType,
		width: 170,
		columnWidth: '12em',
	},
];

export const getWhosInsideTableFullWidth = (columns: string[]) => {
	let baseWidth: number = 200;
	columns.forEach(columnKey => {
		const index: number = defaultWhosInsideColumnWidth.findIndex(x => x.key === ZoneCredentialsColumns[columnKey]);
		if (index !== -1) {
			baseWidth += defaultWhosInsideColumnWidth[index].width;
		}
	});

	return baseWidth;
};

export const getWhosInsideTableColumnWidth = (column: ZoneCredentialsColumns, totalColumns: number) => {
	let width: string = 'auto';
	const columnWidthIndex: number = defaultWhosInsideColumnWidth.findIndex(x => x.key === column);
	if (columnWidthIndex !== -1 && totalColumns > 4) {
		width = defaultWhosInsideColumnWidth[columnWidthIndex].columnWidth;
	}

	return width;
};

import cx from 'classnames';
import React from 'react';
import { ErrorMessage, SelectOptions } from '../../../../../../../model/CommonModel';
import { Select } from '../../../../../../common';
import styles from '../../globalIOGroupModal.module.scss';

type Props = {
	globalIOServerId: number;
	selectOptions: SelectOptions<string>[];
	errorMessage: ErrorMessage;
	mainContainerId: string;
	handleOnChange: (value: string) => void;
};

const GlobalIOServer: React.FC<Props> = ({ globalIOServerId, errorMessage, selectOptions, mainContainerId, handleOnChange }) => {
	const { message, isInvalid } = errorMessage;

	const selectId = 'globalIOServerSelect';
	return (
		<div className={styles.fieldsSection}>
			<div className={styles.fieldsContainer}>
				<div>
					<label id='globalIoServerLabel'>{_('GlobalIOServer')}</label>
				</div>
				<div>
					<Select
						id={selectId}
						key='globalIOGroupSNIBServers'
						aria-label='globalIoServerLabel'
						options={selectOptions}
						value={globalIOServerId > 0 ? globalIOServerId.toString() : ''}
						onChange={handleOnChange}
						getPopupContainer={() => document.getElementById(mainContainerId)}
						className={cx(styles.fieldInput, { [styles.error]: isInvalid })}
					/>
				</div>
			</div>
			{isInvalid && (
				<div className={styles.errorContainer}>
					<label className={styles.errorMessage} htmlFor={selectId}>
						{message}
					</label>
				</div>
			)}
		</div>
	);
};

export { GlobalIOServer };

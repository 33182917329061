import React, { CSSProperties, FC } from 'react';

type CustomIconProperties = {
	label?: string;
	style?: CSSProperties;
};

const USBIcon: FC<CustomIconProperties> = props => (
	<svg width='98' height='124' aria-label={props.label} {...props} viewBox='0 0 98 124' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19.644 111.744C8.81715 105.493 5.04373 91.6667 11.2371 80.9395L41.2842 28.8963C42.6494 26.5318 45.7296 25.7217 48.116 27.0995L48.6435 27.4041L60.3549 7.11944C61.72 4.75497 64.8002 3.94486 67.1867 5.32267L88.4133 17.5779C90.7997 18.9557 91.6382 22.0283 90.2731 24.3927L78.5617 44.6774L78.7376 44.7789C81.124 46.1567 81.9625 49.2293 80.5974 51.5938L50.5503 103.637C44.3569 114.364 30.4959 118.01 19.6691 111.759L19.644 111.744ZM46.679 29.5884C45.6742 29.0083 44.3711 29.351 43.7963 30.3466L13.7491 82.3898C8.34609 91.7481 11.6357 103.802 21.0809 109.255C30.5262 114.709 42.6101 111.53 48.0131 102.172L78.0603 50.1289C78.6351 49.1334 78.2803 47.8334 77.2755 47.2533L74.5876 45.7015L87.736 22.9279C88.3107 21.9323 87.956 20.6324 86.9512 20.0523L65.7497 7.81158C64.7449 7.23145 63.4417 7.57419 62.8669 8.56976L49.7186 31.3433L46.679 29.5884Z'
			fill='#406080'
		/>
		<path d='M77.3057 43.9513L49.8995 28.1283L48.4626 30.6172L75.8687 46.4402L77.3057 43.9513Z' fill='#406080' />
		<path
			d='M37.1833 96.6792C36.9573 96.5486 36.882 96.2729 37.0113 96.0489C38.8794 92.8133 37.7255 88.6626 34.485 86.7917C31.2445 84.9208 27.0585 86.0217 25.2048 89.2324C25.0755 89.4564 24.7991 89.5291 24.573 89.3986C24.3469 89.2681 24.2717 88.9923 24.401 88.7683C26.5277 85.0847 31.2665 83.8384 34.9843 85.9849C38.7021 88.1313 39.9922 92.8584 37.8654 96.542C37.7361 96.766 37.4597 96.8387 37.2336 96.7082L37.1833 96.6792Z'
			fill='#406080'
		/>
		<path
			d='M35.4744 95.6912C35.2483 95.5606 35.173 95.2849 35.3024 95.0609C36.6244 92.7711 35.8181 89.8167 33.507 88.4824C31.196 87.1481 28.2342 87.927 26.9122 90.2168C26.7829 90.4408 26.5064 90.5135 26.2804 90.383C26.0543 90.2525 25.979 89.9767 26.1083 89.7527C27.689 87.0149 31.2431 86.0802 34.0063 87.6755C36.7696 89.2709 37.7371 92.8162 36.1564 95.554C36.0271 95.778 35.7507 95.8507 35.5246 95.7202L35.4744 95.6912Z'
			fill='#406080'
		/>
		<path
			d='M33.3896 94.4878C33.1636 94.3573 33.0883 94.0815 33.2176 93.8575C33.8786 92.7126 33.4701 91.2157 32.3146 90.5486C31.1591 89.8814 29.6584 90.2761 28.9974 91.421C28.8681 91.645 28.5917 91.7177 28.3656 91.5872C28.1395 91.4566 28.0643 91.1809 28.1936 90.9569C29.1132 89.364 31.1667 88.8239 32.7744 89.7521C34.3821 90.6803 34.9411 92.7287 34.0215 94.3216C33.8922 94.5456 33.6157 94.6183 33.3896 94.4878Z'
			fill='#406080'
		/>
		<path
			d='M24.7348 103.332C18.6809 99.8368 16.5486 92.1014 20.0261 86.0783C23.5036 80.0551 31.2436 78.0194 37.3227 81.5292C43.4018 85.039 45.5089 92.7599 42.0314 98.783C38.554 104.806 30.8139 106.842 24.7348 103.332ZM35.8858 84.0181C31.2134 81.3205 25.2109 82.8992 22.5381 87.5286C19.8654 92.158 21.4994 98.1456 26.1718 100.843C30.8442 103.541 36.8466 101.962 39.5194 97.3327C42.1922 92.7033 40.5581 86.7157 35.8858 84.0181Z'
			fill='#406080'
		/>
		<path d='M69.9713 13.6675L66.0275 11.3905L54.5029 31.3516L58.4468 33.6286L69.9713 13.6675Z' fill='#406080' />
		<path d='M74.9689 20.5012L71.025 18.2242L61.2105 35.2235L65.1544 37.5005L74.9689 20.5012Z' fill='#406080' />
		<path d='M83.3866 21.4119L79.4427 19.1349L67.9182 39.096L71.8621 41.373L83.3866 21.4119Z' fill='#406080' />
	</svg>
);

export { USBIcon };

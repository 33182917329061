import { LeftOutlined, PrinterOutlined, RightOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Carousel, notification, Spin } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { enrollmentApi, whosInsideApi } from '../../../api';
import { handleResponse, printBadge } from '../../../Helper';
import { PreviewBadgeResponse, ResponseStatusCode } from '../../../model/CommonModel';
import { CredentialPreviewBadge } from '../../../model/CredentialModel';
import { Modal } from '../../common';
import styles from './previewBadgeCredential.module.scss';

const CustomNextArrow = (props): JSX.Element => <RightOutlined id='previewBadgeNextArrow' className={props.className} onClick={props.onClick} />;
const CustomPrevArrow = (props): JSX.Element => <LeftOutlined id='previewBadgePreviousArrow' className={props.className} onClick={props.onClick} />;

type Props = {
	credentialsIds: number[];
	credentialPreviewBadge?: CredentialPreviewBadge;
	isWhosInsideFeature?: boolean;
	onHideModal: () => void;
};

const PreviewBadgeCredential: React.FC<Props> = ({ credentialsIds, credentialPreviewBadge, isWhosInsideFeature, onHideModal }) => {
	const [visible, setVisible] = useState<boolean>(false);
	const [displayIndex, setDisplayIndex] = useState<number>(0);
	const [credentialBadges, setCredentialBadges] = useState<JSX.Element[]>([]);
	const [credentialBadgesData, setCredentialBadgesData] = useState<PreviewBadgeResponse[]>([]);
	const printableAreaRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (credentialPreviewBadge) {
			enrollmentApi
				.getNewCredentialPrintableBadge(credentialPreviewBadge)
				.then(res => {
					if (res.ResponseStatusCode === ResponseStatusCode.Success) {
						setCredentialBadges(buildCarrousel([res]));
					} else {
						handleResponse(res);
						onHideModal();
					}
				})
				.catch(() => onHideModal());
		} else {
			if (isWhosInsideFeature) {
				whosInsideApi
					.getWhosInsidePreviewBadgeCredential(credentialsIds)
					.then(res => {
						if (res.ResponseStatusCode === ResponseStatusCode.Success) {
							setCredentialBadges(buildCarrousel(res.Entity));
						} else {
							handleResponse(res);
							onHideModal();
						}
					})
					.catch(() => onHideModal());
			} else {
				enrollmentApi
					.getPreviewBadges(credentialsIds, false)
					.then(res => {
						if (res.ResponseStatusCode === ResponseStatusCode.Success) {
							setCredentialBadges(buildCarrousel(res.Entity));
						} else {
							handleResponse(res);
							onHideModal();
						}
					})
					.catch(() => onHideModal());
			}
		}
	}, []);

	useEffect(() => {
		if (credentialBadges.length > 0) {
			setVisible(true);
		}
	}, [credentialBadges]);

	const buildCarrousel = (badgeResponses: PreviewBadgeResponse[]): JSX.Element[] => {
		let elements: JSX.Element[] = [];
		let previewData: PreviewBadgeResponse[] = [];

		for (let x = 0; x < badgeResponses.length; x++) {
			const currentBadgeResponse = badgeResponses[x];
			switch (currentBadgeResponse.ResponseStatusCode) {
				case ResponseStatusCode.Success:
					elements.push(buildCarrouselElement(x, currentBadgeResponse.PhotoCredentialsBase64, currentBadgeResponse.ShowBack));
					previewData.push(currentBadgeResponse);
					break;

				case ResponseStatusCode.SystemError:
					notification['error']({
						message: currentBadgeResponse.ErrorMessage
							? currentBadgeResponse.ErrorMessage
							: currentBadgeResponse.ResponseErrorDescription
							? currentBadgeResponse.ResponseErrorDescription
							: _(`ResponseStatusCode_${currentBadgeResponse.ResponseStatusCode}`),
						duration: 4 + x,
					});
					break;

				case ResponseStatusCode.FailedValidation:
					notification['error']({
						message: currentBadgeResponse.ResponseErrorDescription,
						duration: 4 + x,
					});
					break;

				default:
					handleResponse(currentBadgeResponse);
					break;
			}
		}

		if (elements.length === 0) {
			onHideModal();
		}
		setCredentialBadgesData(previewData);

		return elements;
	};

	const buildCarrouselElement = (key: number, photoCredentialsBase64: string[], showBack: boolean): JSX.Element => {
		return (
			<div key={`badge-${key}`} className={styles.previewBadge} ref={printableAreaRef}>
				{photoCredentialsBase64.length === 1 ? (
					<img key={`image-${key}`} className={styles.badgeImage} src={photoCredentialsBase64[0]} />
				) : showBack ? (
					<img key={`image-${key}`} id='PhotoCredentialsBase64-1' className={styles.badgeImage} src={photoCredentialsBase64[0]} />
				) : (
					<img key={`image-${key}`} id='PhotoCredentialsBase64-0' className={styles.badgeImage} src={photoCredentialsBase64[1]} />
				)}
			</div>
		);
	};

	const handleOnFlipBadge = (): void => {
		const clonedCredentialBadges: JSX.Element[] = [...credentialBadges];
		const clonedCredentialBadgesData: PreviewBadgeResponse[] = [...credentialBadgesData];

		clonedCredentialBadges[displayIndex] = buildCarrouselElement(
			displayIndex,
			clonedCredentialBadgesData[displayIndex].PhotoCredentialsBase64,
			!clonedCredentialBadgesData[displayIndex].ShowBack
		);
		setCredentialBadges(clonedCredentialBadges);

		clonedCredentialBadgesData[displayIndex].ShowBack = !clonedCredentialBadgesData[displayIndex].ShowBack;
		setCredentialBadgesData(clonedCredentialBadgesData);
	};

	const handleOnPrint = (): void => {
		const image: PreviewBadgeResponse = credentialBadgesData[displayIndex];
		let personId: number = 0;
		if (credentialPreviewBadge) {
			personId = credentialPreviewBadge.UserId;
		}

		printBadge({
			image,
			credentialId: image.CredentialId,
			logPrintEvent: enrollmentApi.printBadgeAndLogEvent,
			personId,
		});
	};

	const flipBadgeButton: JSX.Element =
		credentialBadgesData[displayIndex]?.PhotoCredentialsBase64.length > 1 ? (
			<Button id='previewBadgeFlipBadgeButton' type='primary' onClick={handleOnFlipBadge}>
				<SwapOutlined /> {_('FlipBadge')}
			</Button>
		) : undefined;

	const printButton: JSX.Element = isWhosInsideFeature ? undefined : (
		<Button id='previewBadgePrintBadge' type='primary' onClick={handleOnPrint}>
			<PrinterOutlined /> {_('PrintBadge')}
		</Button>
	);

	return (
		<>
			<Spin tip={`${_('Loading')}...`} spinning={!visible} size='large' className={styles.spinBadgeModal} />
			<Modal
				keyboard={false}
				maskClosable={false}
				onCancel={onHideModal}
				onClickOk={() => null}
				width={490}
				title={isWhosInsideFeature ? _('DisplayCredential') : _('PreviewBadge')}
				visible={true}
				className={cx(styles.container, { [styles.displayContainer]: visible })}
				maskStyle={visible ? {} : { backgroundColor: 'transparent' }}
				footer={[
					<div key='0' className={styles.badgeActions}>
						{flipBadgeButton}
						{printButton}
					</div>,
					<div key='1' className={styles.currentBadgeCount}>
						<span>
							{displayIndex + 1} / {credentialBadgesData?.length}
						</span>
					</div>,
				]}>
				<div data-test-id='previewBadgeModal' className={styles.previewBadgeContent}>
					<div className={styles.credentialDetails}>
						{!credentialPreviewBadge && (
							<>
								<span>{_('CredentialID')}</span>
								<span id='previewBadgeCredentialId'>{credentialBadgesData[displayIndex]?.CredentialId}</span>
								<span>{_('Description')}</span>
								<span id='previewBadgeCredentialDescription'>{credentialBadgesData[displayIndex]?.CredentialDescription}</span>
							</>
						)}
					</div>
					<br />
					<div>
						<Carousel
							arrows={true}
							nextArrow={<CustomNextArrow />}
							prevArrow={<CustomPrevArrow />}
							dots={false}
							afterChange={currentSlide => {
								setDisplayIndex(currentSlide);
							}}>
							{...credentialBadges}
						</Carousel>
					</div>
				</div>
			</Modal>
		</>
	);
};

export { PreviewBadgeCredential };

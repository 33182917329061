import { Button, Input, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { alarmApi } from '../../../api';
import { handleResponse } from '../../../Helper';
import { getPermissionErrorMessage, SubPermissions, User } from '../../../model/AccountModel';
import {
	AcknowledgedAndClearAlarmsPayload,
	AlarmAcknowledgementActions,
	AlarmAcknowledgementResults,
	AlarmAssessmentCode,
	AlarmNote,
	AlarmNoteForAcknowledge,
	OperatorNoteAlarm,
} from '../../../model/AlarmModel';
import { SelectOptions } from '../../../model/CommonModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { setAlarmNoteForAcknowledge, setOperatorNotesAdded, setShowAlarmAddOperatorNote } from '../../../store/alarm/actions';
import { selectAlarmNoteForAcknowledge } from '../../../store/alarm/selectors';
import { Modal, NotificationStatus, Select } from '../../common';
import { AlarmDescription } from '../AlarmDescription/AlarmDescription';
import { getAcknowledgeActionForEachAlarm, isNoteRequiredForAllAlarm, isNoteRequiredForEachAlarm } from '../helpers';
import styles from './addOperatorNoteModal.module.scss';

const { TextArea } = Input;

type Props = {
	saveChangesCallback?: () => void;
};

const containerId = 'addOperatorNoteContainer';
const user: User = getUser();
const isAddNoteAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmViewer_AddNotes).allowed;

const AddOperatorNoteModal: React.FC<Props> = ({ saveChangesCallback }) => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isSaveButtonLoading, setIsSaveButtonLoading] = useState<boolean>(false);
	const [selectOptions, setSelectOptions] = useState<SelectOptions<string>[]>([]);
	const [assessmentCode, setAssessmentCode] = useState<string>(undefined);
	const [operatorNote, setOperatorNote] = useState<string>('');
	const [assessmentCodes, setAssessmentCodes] = useState<AlarmAssessmentCode[]>([]);
	const [alarmModel, setAlarmModel] = useState<OperatorNoteAlarm>(undefined);
	const [alarms, setAlarms] = useState<OperatorNoteAlarm[]>([]);
	const [isMultipleNotes, setIsMultipleNotes] = useState<boolean>(false);
	const [alarmCounter, setAlarmCounter] = useState<number>(0);

	const alarmNoteForAcknowledge: AlarmNoteForAcknowledge = useStoreSelector(selectAlarmNoteForAcknowledge);

	useEffect(() => {
		getAssessmentCodes();
	}, []);

	useEffect(() => {
		if (alarmNoteForAcknowledge) {
			const { alarms } = alarmNoteForAcknowledge;

			if (alarms && alarms.length > 1) {
				setIsMultipleNotes(true);
			}

			const [alarm] = alarms;

			setIsLoading(false);
			batch(() => {
				setAlarmModel(alarm);
				setAlarms(alarms);
				setAlarmCounter(1);
			});
		}
	}, [alarmNoteForAcknowledge]);

	const getAssessmentCodes = async () => {
		const res = await alarmApi.getAlarmAssessmentCodes();

		if (handleResponse(res)) {
			return;
		}

		batch(() => {
			setAssessmentCodes(res.Entity);
			setSelectOptions(createSelectOptions(res.Entity));
		});
	};

	const createSelectOptions = (assessmentCodes: AlarmAssessmentCode[]): SelectOptions<string>[] => {
		return assessmentCodes.map((assessmentCode, index) => ({
			id: `assessmentCodeDropdownOption-${index}`,
			label: assessmentCode.Name,
			value: assessmentCode.Id.toString(),
		}));
	};

	const handleOnChangeAssessmentCode = (value: string) => {
		const assessmentCode = assessmentCodes.find(x => x.Id === Number(value));

		batch(() => {
			setOperatorNote(assessmentCode?.Text);
			setAssessmentCode(value);
		});
	};

	const handleOnChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		setOperatorNote(value);
	};

	const acknowledgeAndClearAlarms = async (payload: AcknowledgedAndClearAlarmsPayload, isLastNote?: boolean) => {
		const res = await saveAcknowledgeAndClearAlarms(payload);

		NotificationStatus({
			responseData: res,
			notUseDefaultNotification: true,
			onSuccessCallback: () => {
				setIsSaveButtonLoading(false);
				if (!isLastNote) {
					loadNextAlarmNote();
				}
			},
			onSystemErrorCallback: () => {
				notification['error']({
					message: res.ErrorMessage,
				});
			},
		});
	};

	const saveAcknowledgeAndClearAlarms = ({ action, alarmIds, operatorNotes }: AcknowledgedAndClearAlarmsPayload): Promise<AlarmAcknowledgementResults> => {
		return alarmApi.acknowledgeAndClearAlarms(action, alarmIds, operatorNotes);
	};

	const saveAlarmNote = async (notes: AlarmNote[]) => {
		const response = await alarmApi.saveAlarmNotes(notes).finally(() => setIsSaveButtonLoading(false));

		handleResponse(response);

		dispatch(setOperatorNotesAdded(response.Entity));
		closeModal();
	};

	const loadNextAlarmNote = () => {
		const currentAlarmIndex = alarms.indexOf(alarmModel);
		const nextAlarm = alarms.at(currentAlarmIndex + 1);

		setAlarmCounter(alarmCounter + 1);
		setAlarmModel(nextAlarm);
		setOperatorNote('');
		setAssessmentCode(undefined);
		setIsSaveButtonLoading(false);
	};

	const handleSaveChanges = () => {
		const { isAlarmNoteForAcknowledge, acknowledgeAction, alarms } = alarmNoteForAcknowledge;

		setIsSaveButtonLoading(true);

		if (isAlarmNoteForAcknowledge) {
			const alarmIndex = alarms.indexOf(alarmModel);
			const alarmId = alarms.length > 0 ? alarms.at(alarmIndex).ActiveAlarmId : 0;

			const note = {
				Text: operatorNote,
				AlarmId: alarmId,
			} as AlarmNote;

			const noteRequiredForEachAlarm = isNoteRequiredForEachAlarm(acknowledgeAction);
			const newAcknowledgeAction = noteRequiredForEachAlarm ? getAcknowledgeActionForEachAlarm(acknowledgeAction) : acknowledgeAction;
			const isLastNote: boolean = noteRequiredForEachAlarm ? alarmCounter === alarms.length : true;
			const alarmsIds = noteRequiredForEachAlarm ? [alarmId] : alarms.map<number>(alarm => alarm.ActiveAlarmId);

			const payload = {
				action: newAcknowledgeAction,
				alarmIds: alarmsIds,
				operatorNotes: [note],
			} as AcknowledgedAndClearAlarmsPayload;

			acknowledgeAndClearAlarms(payload, isLastNote);

			if (noteRequiredForEachAlarm && !isLastNote) {
				return;
			}

			closeModal();
			saveChangesCallback?.();
		} else {
			const notes = alarms.map(x => ({ Text: operatorNote, AlarmId: x.ActiveAlarmId })) as AlarmNote[];
			saveAlarmNote(notes);
		}
	};

	const closeModal = () => {
		const alarmNote = {
			isAlarmNoteForAcknowledge: false,
			acknowledgeAction: AlarmAcknowledgementActions.None,
			alarms: [],
		} as AlarmNoteForAcknowledge;
		dispatch(setShowAlarmAddOperatorNote(false));
		dispatch(setAlarmNoteForAcknowledge(alarmNote));
	};

	const handleCancel = () => closeModal();

	const { acknowledgeAction } = alarmNoteForAcknowledge;
	const saveChangesDisabled = operatorNote === '' || !isAddNoteAllowed;
	const noteRequiredForAllAlarm = isNoteRequiredForAllAlarm(acknowledgeAction);
	const noteRequiredForEachAlarm = isNoteRequiredForEachAlarm(acknowledgeAction);
	const isMultipleAlarm = (acknowledgeAction === AlarmAcknowledgementActions.None && isMultipleNotes) || noteRequiredForAllAlarm;

	return (
		<Modal
			onCancel={closeModal}
			onClickOk={() => null}
			width={750}
			title={_('AddOperatorNote')}
			visible={true}
			customZoomClass={styles.modalZoom}
			footer={[
				<Button
					id='addOperatorNoteSaveChangesButton'
					key='save'
					type='primary'
					disabled={saveChangesDisabled}
					title={getPermissionErrorMessage(isAddNoteAllowed)}
					onClick={handleSaveChanges}
					loading={isSaveButtonLoading}>
					{_('SaveChanges')}
				</Button>,
				<Button id='addOperatorNoteButton' key='cancel' onClick={handleCancel}>
					{_('Cancel')}
				</Button>,
			]}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div id={containerId} className={styles.container}>
					<div>{!noteRequiredForEachAlarm || isMultipleAlarm ? <label>{_('AllSelectedAlarms')}</label> : <AlarmDescription {...alarmModel} />}</div>
					<div>
						<Select
							id='assessmentCodesDropdown'
							key='assessmentCode'
							className={styles.select}
							value={assessmentCode}
							options={selectOptions}
							placeholder={_('SelectanAlarmResponse')}
							getPopupContainer={() => document.getElementById(containerId)}
							onChange={handleOnChangeAssessmentCode}
						/>
						<div className={styles.textAreaContainer}>
							<TextArea
								allowClear
								autoSize={{ minRows: 15, maxRows: 15 }}
								onChange={handleOnChangeNote}
								value={operatorNote}
								className={styles.textArea}
								maxLength={1000}
							/>
						</div>
					</div>
					<div className={styles.alarmCounter}>
						{noteRequiredForEachAlarm && alarms.length > 1 ? <span>{`${alarmCounter} / ${alarms.length}`}</span> : undefined}
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export { AddOperatorNoteModal };

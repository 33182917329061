import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../../Helper';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './nameInput.module.scss';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
	errorText: string;
	useDoorContext: boolean;
	setErrorText: () => void;
};

const NameInput: React.FC<Props> = ({ nameValidation, nameError, onResetNameError, useDoorContext, errorText, setErrorText }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		inputContext.dispatcher(setInputAction({ Name: value }));
		onResetNameError();
		useDoorContext ? setErrorText() : null;
	};

	return (
		<div className={styles.containerInput}>
			<span>{_('InputName')}</span>
			<div>
				<Input
					onChange={handleChangeName}
					value={inputContext.context?.input?.Name}
					maxLength={64}
					id='inputModalInputName'
					className={cx(styles.input, {
						[styles.error]: nameError || nameValidation,
					})}></Input>
				{(nameError || nameValidation) && <span className={styles.errorMessage}>{errorText}</span>}
			</div>
		</div>
	);
};

export { NameInput };

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext } from 'react';
import { setDoorDirectionAction } from '../../../DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorStoreSingleContext';
import styles from '../../general.module.scss';

type Props = {};

const Direction: React.FC<Props> = () => {
	const {
		contextStateDoor: {
			door: {
				door: { EntryReadersOnly },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleSelectCheckBox = (e: RadioChangeEvent) => {
		const value = e.target.value;
		dispatcherDoor(setDoorDirectionAction(value));
	};

	return (
		<div className={styles.containerLegend}>
			<fieldset>
				<legend className={styles.legend}>{_('Direction')}</legend>
				<div className={styles.containerRadioGroup}>
					<Radio.Group onChange={handleSelectCheckBox} value={EntryReadersOnly} className={styles.gridSpaceBetween}>
						<Radio value={true} id='doorModalEntryReaderOnlyRadioButton'>
							{_('EntryReaderOnly')}
						</Radio>
						<Radio value={false} id='doorModalEntryAndExitReadersRadioButton'>
							{_('EntryAndExitReaders')}
						</Radio>
					</Radio.Group>
				</div>
			</fieldset>
		</div>
	);
};

export { Direction };

import { InputNumber } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setControlDelay, StoreContext } from '../../../../contextExpansionRelay';
import styles from '../../../../expansionRelayModal.module.scss';

type Props = {
	useDoorContext: boolean;
};

const ControlDelay: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: { controlDelayTime },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			expansionRelayState: { controlDelayTime },
			dispatcher,
		} = useContext(StoreContext);
	}

	const handleOnChangeControlDelay = (value: string) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== '0') {
				return;
			}
		}

		const formattedValue = Math.floor(Number(value) * 4) / 4;

		useDoorContext ? dispatcherDoor(setControlDelay(formattedValue * 4)) : dispatcher(setControlDelay(formattedValue * 4));
	};

	const delayTime = controlDelayTime / 4;

	return (
		<div className={styles.generalSection}>
			<span>{_('ControlDelay')}</span>
			<div>
				<InputNumber
					id='relayGeneralControlDelayInput'
					key='controlDelay'
					min={0}
					max={8100}
					maxLength={6}
					value={delayTime}
					onChange={value => handleOnChangeControlDelay(value.toString())}
					step={0.25}
					className={cx(styles.input)}
					formatter={value => `${value}`.replace(/[^0-9\.]+/g, '')}
				/>
			</div>
		</div>
	);
};

export { ControlDelay };

import { Button, Checkbox, Input, Radio, RadioChangeEvent } from 'antd';
import debounce from 'lodash.debounce';
import React, { CSSProperties, useEffect, useState } from 'react';
import { SubPermissions, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { CriteriaFunctionValue, CriteriaRequest } from '../../../../model/CommonModel';
import { CredentialSearchField, CredentialType } from '../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { loadPersonInformation } from '../../../../store/enrollment/actions';
import { selectPersonId } from '../../../../store/enrollment/selectors';
import { Modal } from '../../../common';
import { CredentialTable } from '../../../enrollment';
import { buildCredentialCriteriaRequest } from '../../helper';
import styles from './findCredential.module.scss';

const user: User = getUser();
const canSearchByPin: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_EnrollmentManager_FindCredentialbyPIN).allowed;
const canDisplayPinCodes: boolean = User.getSubComponentPermission(user, SubPermissions.EnrollmentManager_EnrollmentManager_DisplayPINCodes).allowed;
const modalBodyStyle: CSSProperties = { maxHeight: '85vh', height: '100%', minHeight: '25vh', paddingBottom: 0 };
const initialCriteriaSearchValues: Record<string, string> = {
	[CredentialSearchField[CredentialSearchField.CredentialID]]: '',
	[CredentialSearchField[CredentialSearchField.CardStampNumber]]: '',
	[CredentialSearchField[CredentialSearchField.MatchCode]]: '',
	[CredentialSearchField[CredentialSearchField.PinCode]]: '',
	[CredentialSearchField[CredentialSearchField.Data]]: '',
};

type Props = {
	onHideModal: () => void;
};

const FindCredential: React.FC<Props> = ({ onHideModal }) => {
	const dispatch = useStoreDispatch();
	const [updateCounter, setUpdateCounter] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchValues, setSearchValues] = useState<Record<string, string>>(initialCriteriaSearchValues);
	const [useLikeFunction, setUseLikeFunction] = useState<boolean>(false);
	const [criteriaRequest, setCriteriaRequest] = useState<CriteriaRequest>(undefined);
	const [selectedCriteriaOption, setSelectedCriteriaOption] = useState<CredentialSearchField>(CredentialSearchField.CredentialID);
	const personId: number = useStoreSelector<number>(selectPersonId);

	useEffect(() => {
		return () => {
			onHideModal();
		};
	}, []);

	useEffect(() => {
		if (personId !== 0) {
			onHideModal();
		}
	}, [personId]);

	const handleOnChangeValue = (value: Record<string, string>): void => {
		setSearchValues(prevState => {
			return {
				...prevState,
				...value,
			};
		});
	};

	const handleOnChangeCriteria = (e: RadioChangeEvent): void => {
		const selectedCriteria: CredentialSearchField = e.target.value;
		if (selectedCriteria === CredentialSearchField.CredentialID || selectedCriteria === CredentialSearchField.PinCode) {
			setUseLikeFunction(false);
		}

		setSelectedCriteriaOption(e.target.value);
	};

	const handleOnApplySearch = debounce(async () => {
		if (isLoading || isSelectedOptionEmpty(CredentialSearchField.CredentialID) || emptyLikeMatchField || emptyLikeStampField) {
			return;
		}

		const criteriaFunction: CriteriaFunctionValue = useLikeFunction ? CriteriaFunctionValue.like : CriteriaFunctionValue.equalTo;
		setCriteriaRequest(
			buildCredentialCriteriaRequest(searchValues[CredentialSearchField[selectedCriteriaOption]].trim(), selectedCriteriaOption, criteriaFunction)
		);
		setUpdateCounter(prevState => prevState + 1);
	}, 200);

	const isSelectedOptionEmpty = (field: CredentialSearchField): boolean =>
		selectedCriteriaOption === field && searchValues[CredentialSearchField[field]]?.trim().length === 0;

	const disableUseLikeFunction: boolean =
		selectedCriteriaOption === CredentialSearchField.CredentialID || selectedCriteriaOption === CredentialSearchField.PinCode;

	const emptyLikeStampField: boolean = isSelectedOptionEmpty(CredentialSearchField.CardStampNumber) && useLikeFunction;
	const emptyLikeDataField: boolean = isSelectedOptionEmpty(CredentialSearchField.Data) && useLikeFunction;
	const emptyLikeMatchField: boolean = isSelectedOptionEmpty(CredentialSearchField.MatchCode) && useLikeFunction;
	const disableSearchButton: boolean =
		isSelectedOptionEmpty(CredentialSearchField.CredentialID) || emptyLikeStampField || emptyLikeDataField || emptyLikeMatchField;

	const handleOnChangeInput = (field: CredentialSearchField, value: string) => {
		switch (field) {
			case CredentialSearchField.CredentialID:
			case CredentialSearchField.PinCode:
				const numberOnlyRegex: RegExp = new RegExp('^(\\s*|[0-9]+)$');
				if (numberOnlyRegex.test(value)) {
					handleOnChangeValue({ [CredentialSearchField[field]]: value.trim() });
				}
				break;

			case CredentialSearchField.CardStampNumber:
			case CredentialSearchField.Data:
				handleOnChangeValue({ [CredentialSearchField[field]]: value });
				break;

			case CredentialSearchField.MatchCode:
				const hexValuesOnlyRegex: RegExp = new RegExp('^(\\s*|[0-9A-Fa-f]+)$');
				if (hexValuesOnlyRegex.test(value)) {
					handleOnChangeValue({ [CredentialSearchField[field]]: value.trim() });
				}
				break;
		}
	};

	const handleOnViewPersonInformation = (personId: number): void => {
		dispatch(loadPersonInformation(personId));
	};

	return (
		<Modal
			footer={null}
			keyboard={false}
			maskClosable={false}
			onCancel={onHideModal}
			onClickOk={() => null}
			width={900}
			title={_('FindCredential')}
			visible={true}
			className={styles.modal}
			bodyStyle={modalBodyStyle}>
			<div className={styles.container}>
				<div>
					<p>{_('SearchForASpecificCredential')}</p>
				</div>
				<div>
					<Radio.Group onChange={handleOnChangeCriteria} value={selectedCriteriaOption} className={styles.findCriteria}>
						<div>
							<Radio value={CredentialSearchField.CredentialID} id='findCredentialByCredentialIdRadio'>
								<label htmlFor='findCredentialByCredentialIdRadio'>{_('CredentialID')}</label>
							</Radio>
							<div>
								<Input
									id='findCredentialByCredentialIdInput'
									aria-label={_('CredentialID')}
									className={styles.criteriaInput}
									disabled={selectedCriteriaOption !== CredentialSearchField.CredentialID}
									maxLength={50}
									onChange={e => handleOnChangeInput(CredentialSearchField.CredentialID, e.target.value)}
									onPressEnter={handleOnApplySearch}
									autoComplete='new-id-criteria'
									value={searchValues[CredentialSearchField[CredentialSearchField.CredentialID]]}
								/>
								<Button
									id='findCredentialApplySearchButton'
									disabled={disableSearchButton}
									type='primary'
									onClick={handleOnApplySearch}
									loading={isLoading}>
									{_('ApplySearch')}
								</Button>
							</div>
						</div>
						<div>
							<Radio value={CredentialSearchField.CardStampNumber} id='findCredentialByStampNumberRadio'>
								<label htmlFor='findCredentialByStampNumberRadio'>{_('CardStampNumber')}</label>
							</Radio>
							<div>
								<Input
									id='findCredentialByStampNumberInput'
									aria-label={_('CardStampNumber')}
									className={styles.criteriaInput}
									disabled={selectedCriteriaOption !== CredentialSearchField.CardStampNumber}
									maxLength={50}
									onChange={e => handleOnChangeInput(CredentialSearchField.CardStampNumber, e.target.value)}
									onPressEnter={handleOnApplySearch}
									autoComplete='new-stamp-criteria'
									value={searchValues[CredentialSearchField[CredentialSearchField.CardStampNumber]]}
								/>
								<Checkbox
									id='findCredentialUseLikeCheckbox'
									onChange={e => setUseLikeFunction(e.target.checked)}
									checked={useLikeFunction}
									disabled={disableUseLikeFunction}>
									<label htmlFor='findCredentialUseLikeCheckbox'>{_('UseLikeFunction')}</label>
								</Checkbox>
							</div>
						</div>
						<div>
							<Radio value={CredentialSearchField.MatchCode} id='findCredentialByMatchCodeRadio'>
								<label htmlFor='findCredentialByMatchCodeRadio'>{_('MatchCode')}</label>
							</Radio>
							<div>
								<Input
									id='findCredentialByMatchCodeInput'
									aria-label={_('MatchCode')}
									className={styles.criteriaInput}
									disabled={selectedCriteriaOption !== CredentialSearchField.MatchCode}
									maxLength={32}
									onChange={e => handleOnChangeInput(CredentialSearchField.MatchCode, e.target.value)}
									onPressEnter={handleOnApplySearch}
									autoComplete='new-match-criteria'
									value={searchValues[CredentialSearchField[CredentialSearchField.MatchCode]]}
								/>
							</div>
						</div>
						<div>
							<Radio value={CredentialSearchField.Data} id='findCredentialByDataRadio'>
								<label htmlFor='findCredentialByDataRadio'>{_('CardData')}</label>
							</Radio>
							<div>
								<Input
									id='findCredentialByDataInput'
									aria-label={_('CardData')}
									className={styles.criteriaInput}
									disabled={selectedCriteriaOption !== CredentialSearchField.Data}
									maxLength={50}
									onChange={e => handleOnChangeInput(CredentialSearchField.Data, e.target.value)}
									onPressEnter={handleOnApplySearch}
									autoComplete='new-match-criteria'
									value={searchValues[CredentialSearchField[CredentialSearchField.Data]]}
								/>
							</div>
						</div>
						<div>
							<Radio value={CredentialSearchField.PinCode} disabled={!canSearchByPin} id='findCredentialByPinCodeRadio'>
								<label htmlFor='findCredentialByPinCodeRadio' title={getPermissionErrorMessage(canSearchByPin)}>
									{_('PinCode')}
								</label>
							</Radio>
							<div>
								<Input
									type={canDisplayPinCodes ? '' : 'Password'}
									id='findCredentialByPinCodeInput'
									aria-label={_('PinCode')}
									className={styles.criteriaInput}
									disabled={selectedCriteriaOption !== CredentialSearchField.PinCode || !canSearchByPin}
									maxLength={15}
									title={getPermissionErrorMessage(canSearchByPin)}
									onChange={e => handleOnChangeInput(CredentialSearchField.PinCode, e.target.value)}
									onPressEnter={handleOnApplySearch}
									autoComplete='new-pin-criteria'
									value={searchValues[CredentialSearchField[CredentialSearchField.PinCode]]}
								/>
							</div>
						</div>
					</Radio.Group>
				</div>
				<div className={styles.credentialTable}>
					<CredentialTable
						userId={CredentialType.FindCredential}
						criteriaSearchRequest={criteriaRequest && { CriteriaRequest: [criteriaRequest] }}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						setUpdateCounter={() => setUpdateCounter(prevState => prevState + 1)}
						updateCounter={updateCounter}
						handleOnViewPersonInformation={handleOnViewPersonInformation}
					/>
				</div>
			</div>
		</Modal>
	);
};

export { FindCredential };

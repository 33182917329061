import { Spin } from 'antd';
import arrayMove from 'array-move';
import React from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { ThunkDispatch } from 'redux-thunk';
import { configurationApi, statusApi } from '../../api/';
import { HeaderBar } from '../../components/common';
import {
	AccessSummary,
	EventSummary,
	EventTableWidget,
	OnlineActiveUsers,
	PeopleCredentials,
	PerformanceStatus,
	QueuedDownload,
} from '../../components/widget/';
import { ProfileSetting } from '../../model/ConfigurationModel';
import { EnrollmentBlankImages } from '../../model/EnrollmentModel';
import { Logger } from '../../model/LoggingModel';
import { WidgetInfo } from '../../model/StatusModel';
import { StoreEvent } from '../../store';
import { setEnrollmentBlankImages } from '../../store/enrollment/actions';
import './statusDashboard.scss';

// Internal state for the component
interface State {
	isLoading: boolean;
	widgetList: WidgetInfo[];
}

interface DispatchProps {
	handleSetEnrollmentBlankImages: (images: EnrollmentBlankImages) => void;
}

interface OwnProps {}

interface StateProps {}

// Normal properties for the component
type Props = DispatchProps & OwnProps & StateProps;

class StatusDashboardComponent extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isLoading: true,
			widgetList: [],
		};
	}

	SortableItem = SortableElement(({ widget }) => {
		return widget;
	});

	SortableList = SortableContainer(({ widgets }) => (
		<div className='status-widgets-grid'>
			{widgets.map((widget, index) => (
				<this.SortableItem key={widget.key} index={index} widget={widget} />
			))}
		</div>
	));

	componentDidMount() {
		const { handleSetEnrollmentBlankImages } = this.props;
		statusApi
			.retrieveStatusDashboard()
			.then(response => {
				this.setState({
					isLoading: false,
					widgetList: response.WidgetList,
				});
				handleSetEnrollmentBlankImages({
					BlankProfilePictureBase64: response.BlankProfilePictureBase64,
					BlankProfileSignatureBase64: response.BlankProfileSignatureBase64,
				});
			})
			.catch(e => Logger.writeErrorLog(e));
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	compareWidgetList = (orderList: WidgetInfo[], newOrderList: WidgetInfo[]) => {
		if (orderList.length !== newOrderList.length) {
			return true;
		}

		let hasChange = false;
		for (let i = 0; i < orderList.length; i++) {
			if (orderList[i].id !== newOrderList[i].id) {
				hasChange = true;
				break;
			}
		}

		return hasChange;
	};

	saveWidgetOrder(newWidgetOrder: WidgetInfo[]) {
		const setting: ProfileSetting = {
			Name: 'StatusDashboardGridLayout',
			ObjectId: 17, //'Web'
			Value: JSON.stringify(newWidgetOrder),
		};

		configurationApi.saveSettingChanges(setting).catch(e => Logger.writeErrorLog(e));
	}

	private handleSortEnd = ({ oldIndex, newIndex }) => {
		const { widgetList } = this.state;

		const newWidgetsOrder = arrayMove(widgetList, oldIndex, newIndex);
		if (this.compareWidgetList(widgetList, newWidgetsOrder)) {
			this.setState({
				widgetList: newWidgetsOrder,
			});
			this.saveWidgetOrder(newWidgetsOrder);
		}
	};

	render() {
		const { isLoading, widgetList } = this.state;

		const widgets = widgetList
			.map(widget => {
				const key = widget.id;
				switch (key) {
					case 'Widget-AccessSummary':
						return (
							<div className='status-widget-full-width' key={key}>
								<AccessSummary />
							</div>
						);

					case 'Widget-People':
						return (
							<div className='status-widget-half-width' key={key}>
								<PeopleCredentials widgetInfo={widget} />
							</div>
						);

					case 'Widget-Downloads':
						return (
							<div className='status-widget-full-width' key={key}>
								<QueuedDownload />
							</div>
						);

					case 'Widget-Events':
						return (
							<div className='status-widget-full-width' key={key}>
								<EventTableWidget />
							</div>
						);

					case 'Widget-EventsSummary':
						return (
							<div className='status-widget-half-width' key={key}>
								<EventSummary />
							</div>
						);

					case 'Widget-PerformanceStatus':
						return (
							<div className='status-widget-half-width' key={key}>
								<PerformanceStatus widgetInfo={widget} />
							</div>
						);

					case 'Widget-OnlineActiveUsers':
						return (
							<div className='status-widget-half-width' key={key}>
								<OnlineActiveUsers widgetInfo={widget} />
							</div>
						);

					default:
						return undefined;
				}
			})
			.filter(w => w !== undefined);

		return (
			<>
				<HeaderBar title={_('StatusDashboard')} />
				<div id='statusDashboardContainer' className='status-dashboard-container'>
					<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={'spin-container status-dashboard-spin-container'}>
						<this.SortableList
							axis='xy'
							distance={1}
							helperClass='status-sortable-helper'
							onSortEnd={this.handleSortEnd}
							useDragHandle={true}
							useWindowAsScrollContainer={true}
							widgets={widgets !== null ? widgets : []}
						/>
					</Spin>
				</div>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, StoreEvent>, ownProps: OwnProps): DispatchProps => ({
	handleSetEnrollmentBlankImages: (images: EnrollmentBlankImages) => {
		dispatch(setEnrollmentBlankImages(images));
	},
});

const StatusDashboardPage = connect<StateProps, DispatchProps, OwnProps>(null, mapDispatchToProps)(StatusDashboardComponent);

export { StatusDashboardPage };

import { Button, notification } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import { ModalInfo } from '../../../../../../common/ModalInfo/ModalInfo';
import { NotificationStatus } from '../../../../../../common/NotificationStatus/NotificationStatus';
import { PortStoreContext } from '../../../contextPort';
import styles from '../../../portModal.module.scss';
import { PingAddressTable } from '../PingAddressTable/PingAddressTable';

type Props = {};

const Ping: React.FC<Props> = () => {
	const {
		portState: { ipAddress },
	} = useContext(PortStoreContext);

	const [isPinging, setIsPinging] = useState<boolean>(false);
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const sendPingToIP = async () => {
		const response = await deviceAdminApi.sendPingToIP(ipAddress);
		NotificationStatus({
			responseData: response,
			onSuccessCallback: () => {
				if (isMounted.current) {
					ModalInfo({
						title: _('PingAddress'),
						content: <PingAddressTable pingInformation={response.Entity} />,
						onOk: () => null,
						onCancel: () => null,
						okText: _('Ok'),
						width: '550px',
					});
					notification['success']({
						message: _('IPAddressValid'),
					});
				}
			},
			onAdditionalInfoRequiredCallback: () => {
				if (isMounted.current) {
					notification['info']({
						message: response.AdditionalResponseInfo,
					});
				}
			},
		});
	};

	const handleClick = () => {
		setIsPinging(true);
		sendPingToIP().finally(() => {
			if (isMounted.current) {
				setIsPinging(false);
			}
		});
	};

	const isDisabled = ipAddress !== '0.0.0.0' ? false : true;

	return (
		<Button
			id='portGeneralPingButton'
			key='ping'
			type='primary'
			className={styles.actionButtons}
			disabled={isDisabled}
			onClick={handleClick}
			loading={isPinging}>
			{_('Ping')}
		</Button>
	);
};

export { Ping };

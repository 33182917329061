import { UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { QueuedDownloadTable } from '../QueuedDownloadTable/QueuedDownloadTable';
import './queuedDownload.scss';

const { Panel } = Collapse;

// Normal properties for the component
interface OwnProps {}

// combine them together
type Props = OwnProps;

const HaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{_('QueuedDownloads')}</span>);

const QueuedDownload: React.VFC = (props: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);

	return (
		<div className='queued-download-container'>
			<Collapse
				bordered={true}
				defaultActiveKey={'1'}
				expandIconPosition='right'
				expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
				onChange={(keys: string[]) => {
					setIsExpanded(keys.length === 1);
				}}
				className={'status-widget-collapse'}>
				<Panel id='statusDashboardQueuedDownloadWidget' key='1' header={<HaderWidget />} className='status-widget status-widget-collapse-content'>
					<QueuedDownloadTable isExpanded={isExpanded} />
				</Panel>
			</Collapse>
		</div>
	);
};

export { QueuedDownload };

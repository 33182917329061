import { Table } from 'antd';
import React from 'react';
import { ModalConfirmation } from '..';
import { buildColumn, ScrollType } from '../../../Helper';
import styles from './modalConfirmationList.module.scss';

type Props<T> = {
	type: string;
	translationKey?: string;
	data: { Name: string }[];
	onConfirm?: (value: T) => void;
	onCancel?: (value: T) => void;
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 272 };

const ModalConfirmationList = <T extends object | string | number>({ data, type, translationKey, onCancel, onConfirm }: Props<T>) => {
	const columns: any[] = [
		{
			...buildColumn(_('Name'), 'Name', '100px', 'start'),
		},
	];
	const dataSource = data.map((x, index) => ({ ...x, key: index }));

	const key: string = translationKey === undefined ? 'AreYouSureYouWantToDeleteTheFollowing' : translationKey;

	return ModalConfirmation({
		onConfirm,
		onCancel,
		content: (
			<div className={styles.container}>
				<span>{_(key).replace('%1', type)}</span>
				<br />
				<Table scroll={scroll} size='small' columns={columns} dataSource={dataSource} className={styles.statusWidgetTable} pagination={false} />
			</div>
		),
	});
};

export { ModalConfirmationList };

import { Checkbox, InputNumber, Tooltip } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../Helper';
import { ApplicationSetting, ConfigurationState, SettingType, SettingsValue } from '../../../model/ConfigurationModel';
import styles from './securitySettings.module.scss';

const isSecureConnection: boolean = isConnectionSecure();

interface Props {
	configuration: ConfigurationState;
	onSetSettingValue: (value: SettingsValue<string>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
	castNumber: (value: number) => boolean;
}

const SecuritySettings: React.FC<Props> = ({ configuration, onSetSettingValue, onSetSettingsHaveChanged, castNumber }) => {
	const setSettingsHaveChanged = () => {
		if (!haveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	const lockoutAmountTime: ApplicationSetting = configuration.LockoutAmountTime;
	const lockoutMaxAttempts: ApplicationSetting = configuration.LockoutMaxAttempts;
	const lockoutPerIpAddress: ApplicationSetting = configuration.LockoutPerIpAddress;
	const enableTwoFactorAuthentication: ApplicationSetting = configuration.EnableTwoFactorAuthentication;
	const lockoutPeriodAttackTime: ApplicationSetting = configuration.LockoutPeriodAttackTime;
	const haveSettingsChanged: boolean = configuration.HaveSettingsChanged;

	const handleOnChangeSetting = (newValue: string, type: SettingType) => {
		const setValues: SettingsValue<string> = {
			key: 'Value',
			value: newValue,
			type: type,
		};
		onSetSettingValue(setValues);
		setSettingsHaveChanged();
	};

	return (
		<div className={styles.container}>
			<div className={styles.info}>
				<div className={styles.gridRow}>
					<div className={styles.addBoldFont}>*{lockoutMaxAttempts.Label}</div>
					<InputNumber
						onChange={e => handleOnChangeSetting(e.toString(), 'LockoutMaxAttempts')}
						value={(lockoutMaxAttempts.Value as number) ?? 4}
						max={10}
						min={1}
						maxLength={2}
						id='LockoutMaxAttempts'
						formatter={noneDecimalFormatter}
					/>
					<div>
						<label htmlFor='LockoutMaxAttempts'>{_('Range1-10')}</label>
					</div>
				</div>
				<div className={styles.gridRow}>
					<div className={styles.addBoldFont}>*{lockoutPeriodAttackTime.Label}</div>
					<div>
						<InputNumber
							onChange={e => handleOnChangeSetting(e.toString(), 'LockoutPeriodAttackTime')}
							value={(lockoutPeriodAttackTime.Value as number) ?? 60}
							max={120}
							min={1}
							maxLength={3}
							id='LockoutPeriodAttackTime'
							formatter={noneDecimalFormatter}
						/>
					</div>
					<div>
						<label htmlFor='LockoutPeriodAttackTime'>{_('Range1-120s')}</label>
					</div>
				</div>
				<div className={styles.gridRow}>
					<div className={styles.addBoldFont}>*{lockoutAmountTime.Label}</div>
					<div>
						<InputNumber
							onChange={e => handleOnChangeSetting(e.toString(), 'LockoutAmountTime')}
							value={(lockoutAmountTime.Value as number) ?? 1}
							max={60}
							min={1}
							maxLength={2}
							id='LockoutAmountTime'
							formatter={noneDecimalFormatter}
						/>
					</div>
					<div>
						<label htmlFor='LockoutAmountTime'>{_('Range1-60m')}</label>
					</div>
				</div>
			</div>
			<div className={styles.lockupIpAddress}>
				<div className={styles.gridRow}>
					<div className={styles.addBoldFont}>
						<label htmlFor='LockoutPerIpAddress'>*{lockoutPerIpAddress.Label}</label>
					</div>
					<div>
						<Checkbox
							id='LockoutPerIpAddress'
							onChange={e => handleOnChangeSetting(e.target.checked ? '1' : '0', 'LockoutPerIpAddress')}
							checked={castNumber(Number(lockoutPerIpAddress.Value ?? 0))}
							aria-label={`*${lockoutPerIpAddress.Label}`}
						/>
					</div>
				</div>
				<div>
					<label>{_('LockoutIPNote')}</label>
				</div>
			</div>
			<div className={styles.enforceTwoFactor}>
				<div className={styles.gridRow}>
					<div className={styles.addBoldFont}>
						<label htmlFor='twoFactorAuthenticationCheckbox'>*{enableTwoFactorAuthentication.Label}</label>
					</div>
					<div>
						<Tooltip trigger={['hover']} placement='right' title={isSecureConnection ? '' : _('RequireHttpsMessage')}>
							<Checkbox
								id='twoFactorAuthenticationCheckbox'
								onChange={e => handleOnChangeSetting(e.target.checked ? '1' : '0', 'EnableTwoFactorAuthentication')}
								checked={!isSecureConnection ? false : castNumber(Number(enableTwoFactorAuthentication.Value ?? 0))}
								aria-label={`*${enableTwoFactorAuthentication.Label}`}
								disabled={!isSecureConnection}
							/>
						</Tooltip>
					</div>
				</div>
				<div>
					<label>{`(${_('IdentivFidoKey')})`}</label>
				</div>
			</div>
		</div>
	);
};

export { SecuritySettings };

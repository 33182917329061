import { Checkbox, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { createSelector } from 'reselect';
import { buildColumn, ColumnsProps, ScrollType } from '../../../../../../../../Helper';
import { ControlZonesComponentsCheck, MasterControlZonesEditor } from '../../../../../../../../model/DeviceAdminModel';
import { useStoreSelector } from '../../../../../../../../store';
import { Select } from '../../../../../../../common';
import styles from '../../controlZones.module.scss';
import {
	selectorMasterControlZonesEditor,
	selectorMasterHeaders,
	selectorMasterZonesActions,
	selectorStandardControlZones,
	selectorStandardControlZonesList,
} from '../../selectors';
type Props = {
	currentZone: number;
	onChangeActionZone: (value: Partial<MasterControlZonesEditor>) => void;
	actionControlZones: number[];
};

type Data = {
	Action: string;
	StandardControlZoneName: string;
	StandardControlZoneId: number;
	ComponentsArray: ControlZonesComponentsCheck[];
	index: number;
};

const scroll: ScrollType = { y: 660 };

const selectorCurrentMasterZone = createSelector(
	(props: number) => props,
	(combiner: number) => combiner
);

const StandardZones: React.FC<Props> = ({ currentZone, onChangeActionZone, actionControlZones }) => {
	const masterZonesActions = useStoreSelector(selectorMasterZonesActions);
	const standardControlZoneEditor = useStoreSelector(selectorStandardControlZones);
	const masterControlZonesHeaders = useStoreSelector(selectorMasterHeaders);
	const standardControlZoneList = useStoreSelector(selectorStandardControlZonesList);
	const masterControlZonesEditor = useStoreSelector(selectorMasterControlZonesEditor);
	const currentMasterZone = selectorCurrentMasterZone(currentZone);

	const mapNormalizerArray: Data[] = masterZonesActions.map((x, index) => {
		const actionStandardZone = actionControlZones[index];
		const currentStandardZone = standardControlZoneEditor[actionStandardZone];
		return {
			Action: x,
			index,
			StandardControlZoneName: currentStandardZone.Name,
			StandardControlZoneId: currentStandardZone.Index,
			ComponentsArray: [...currentStandardZone.ComponentsArray],
		};
	});

	const componentsColumns: ColumnsProps<Data>[] = masterControlZonesHeaders.slice(2, masterControlZonesHeaders.length).map((x, index) => ({
		...buildColumn(x, x, '40px', 'start'),
		render: (value, record) => {
			return <Checkbox disabled={true} checked={record.ComponentsArray[index].Value} />;
		},
	}));

	const columns: ColumnsProps<Data>[] = [
		{
			...buildColumn(_('Action'), 'Action', '180px', 'center'),
			fixed: 'left',
			sorter: (a, b) => a.Action.localeCompare(b.Action),
		},
		{
			...buildColumn(_('StandardControlZone'), 'StandardControlZoneName', '200px', 'center'),
			sorter: (a, b) => a.StandardControlZoneName.localeCompare(b.StandardControlZoneName),
			fixed: 'left',
			render: (value, record, index) => (
				<Select
					value={actionControlZones[index]}
					style={{ width: 130 }}
					options={standardControlZoneList.map(x => ({ label: x.ControlZoneName, value: x.ControlZoneIndex }))}
					onChange={value => {
						const cloneState = masterControlZonesEditor[currentMasterZone];
						cloneState.ActionControlZones[record.index] = Number(value);
						onChangeActionZone({ ActionControlZones: [...cloneState.ActionControlZones] });
					}}
					showOptionalStringOnSelectedValue={currentLabel => currentLabel.substring(currentLabel.indexOf('-') + 1)}
					notUseCurrentParse
					getPopupContainer={() => document.getElementById(`standardControlZoneDropdown-${index}`)}
				/>
			),
		},
		...componentsColumns,
	];

	return (
		<Table
			id='standardControlZonesTable'
			columns={columns as ColumnsType}
			dataSource={mapNormalizerArray}
			scroll={scroll}
			pagination={false}
			size='small'
			rowKey={(record: Data) => record.Action}
			className={styles.statusWidgetTable}
			onRow={(record: Data, rowIndex) => {
				return {
					onClick: event => {}, // click row
					onDoubleClick: event => {}, // double click row
					onContextMenu: event => {}, // right button click row
					onMouseEnter: event => {}, // mouse enter row
					onMouseLeave: event => {}, // mouse leave row
					id: `standardControlZoneDropdown-${rowIndex}`,
				};
			}}
		/>
	);
};

const memoizedComponent = React.memo(StandardZones);
export { memoizedComponent as StandardZones };

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Spin } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../../api';
import { ComponentPermission, SecuredComponents, User } from '../../../../model/AccountModel';
import { CriteriaFunctionValue, CriteriaRequest, CriteriaSearchRequest } from '../../../../model/CommonModel';
import { CredentialSearchField, PersonDetailModel } from '../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setHavePersonChanged, setIsEnrollmentTabLoading, setPersonInformationId, setUpdateCounter } from '../../../../store/enrollment/actions';
import { selectPersonFullName, selectPersonId } from '../../../../store/enrollment/selectors';
import { HeaderBar } from '../../../common';
import { CredentialTable } from '../../CredentialTable/CredentialTable';
import { buildCredentialCriteriaRequest, enrollmentGoBackTextId } from '../../helper';
import styles from './personInformationContainer.module.scss';
import { PersonInformationTabs } from './PersonInformationTabs';

const user: User = getUser();
const personPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Enrollment_Person);

type Props = {
	personTableTitle: string;
	hidden: boolean;
	updatePivPersonData?: PersonDetailModel;
	clearUpdatePivPersonData?: () => void;
};

const PersonInformationContainer: React.FC<Props> = ({ personTableTitle, hidden, updatePivPersonData, clearUpdatePivPersonData }) => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCredentialTableLoading, setIsCredentialTableLoading] = useState<boolean>(true);
	const [updateCounterCredential, setUpdateCounterCredential] = useState<number>(0);
	const [reloadPersonTable, setReloadPersonTable] = useState<boolean>(false);
	const [personIdState, setPersonIdState] = useState<number>(-100);

	const personFullName: string = useStoreSelector<string>(selectPersonFullName);
	const personId: number = useStoreSelector<number>(selectPersonId);

	const personIdReference = React.useRef<number>(personIdState);
	const setPersonIdReference = (personId: number): void => {
		personIdReference.current = personId;
		setPersonIdState(personId);
	};

	useEffect(() => {
		setPersonIdReference(personId);
	}, [personId]);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			handleBeforeUnload();
			window.removeEventListener('beforeunload', handleBeforeUnload);
			dispatch(setHavePersonChanged(false));
		};
	}, []);

	const handleBeforeUnload = (): void => {
		deviceAdminApi.unlockComponentDeviceAdmin(personIdReference.current, SecuredComponents.Enrollment_Person);
	};

	const handleOnClickBack = (reloadPersonTable: boolean): void => {
		setIsLoading(true);
		setTimeout(() => {
			batch(() => {
				dispatch(setPersonInformationId(0));
				dispatch(setIsEnrollmentTabLoading(false));
				dispatch(setHavePersonChanged(false));
			});
			if (reloadPersonTable) {
				dispatch(setUpdateCounter());
			}
		}, 0);
	};

	const personIdCriteriaRequest: CriteriaRequest = buildCredentialCriteriaRequest(
		personId.toString(),
		CredentialSearchField.PersonId,
		CriteriaFunctionValue.equalTo
	);

	const criteriaSearchRequest: CriteriaSearchRequest = { CriteriaRequest: [personIdCriteriaRequest] };
	const isNewPerson: boolean = personId === -100 || personId === 0;
	const style: CSSProperties = hidden ? { display: 'none' } : {};
	const goBackLabelId: string = hidden ? '' : enrollmentGoBackTextId;
	const personInformationReadOnly: string = personPermissions.canUpdate ? '' : `(${_('ReadOnly')})`;

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			<div className={styles.container} style={style}>
				<div>
					<Breadcrumb>
						<Breadcrumb.Item>
							<a onClick={() => handleOnClickBack(reloadPersonTable)}>
								<ArrowLeftOutlined /> <label id={goBackLabelId}>{personTableTitle}</label>
							</a>
						</Breadcrumb.Item>
						<Breadcrumb.Item>{personFullName}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				{!isNewPerson && (
					<div className={styles.credentialTable}>
						<CredentialTable
							userId={personId}
							criteriaSearchRequest={criteriaSearchRequest}
							isLoading={isCredentialTableLoading}
							setIsLoading={setIsCredentialTableLoading}
							setUpdateCounter={() => setUpdateCounterCredential(prevState => prevState + 1)}
							updateCounter={updateCounterCredential}
							scrollHeight={'16rem'}
						/>
					</div>
				)}
				<HeaderBar title={`${_('PersonInformation')} ${personInformationReadOnly}`} />
				<PersonInformationTabs
					setReloadPersonTable={() => setReloadPersonTable(true)}
					handleOnDeletePerson={handleOnClickBack}
					updatePivPersonData={updatePivPersonData}
					clearUpdatePivPersonData={clearUpdatePivPersonData}
				/>
			</div>
		</Spin>
	);
};

export { PersonInformationContainer };

import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkAction } from 'redux-thunk';
import { useDispatch, useSelector } from '../Helper';
import { AlarmAction } from './alarm/actions';
import { CommonAction } from './common/actions';
import { GeneralSettingsAction } from './configuration/actions';
import { DeviceControlAction } from './deviceControl/actions';
import { ControllerActionType } from './deviceControl/controller/actionTypes';
import { ControlZoneActionType } from './deviceControl/controlZones/actionTypes';
import { EnrollmentAction } from './enrollment/actions';
import { PhotoCallUpAction } from './photocallup/actions';
import { configurationMiddleware } from './photocallup/middleware';
import { RootReducer, rootReducer } from './rootReducer';
import { StatusViewerAction } from './statusViewer/actions';

const middleware = applyMiddleware(...configurationMiddleware, thunk);
let store: Store;
if (process.env.NODE_ENV === 'production') {
	store = createStore(rootReducer, middleware);
} else {
	store = createStore(rootReducer, composeWithDevTools(middleware));
}

export type StoreEvent =
	| AlarmAction
	| CommonAction
	| GeneralSettingsAction
	| DeviceControlAction
	| ControllerActionType
	| ControlZoneActionType
	| EnrollmentAction
	| PhotoCallUpAction
	| StatusViewerAction;

//type hooks from redux to achieve data typed
export function useStoreDispatch() {
	return useDispatch<StoreEvent | ThunkAction<Promise<void>, {}, {}, StoreEvent>>();
}

export function useStoreSelector<T>(fn: (store: RootReducer) => T): T {
	return useSelector<RootReducer, T>(fn);
}

export { store };

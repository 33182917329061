// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".time-zones-dropdown-container--1pL2WN_brMJ42U8z_nDUD_{display:flex;flex-direction:column;width:50%}.time-zones-dropdown-container--1pL2WN_brMJ42U8z_nDUD_>div{display:flex;flex-direction:column}.time-zones-dropdown-error--Vo9HB8mj92g7ddHvPXuXf [class*=\"ant-select ant-select-single ant-select-show-arrow\"]{border:1px solid red !important}.time-zones-dropdown-error--Vo9HB8mj92g7ddHvPXuXf label{color:red}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/VelocityConfiguration/DoorGroup/DoorModal/sections/TimeZonesDropdown/timeZonesDropdown.module.scss"],"names":[],"mappings":"AAAA,uDACC,YAAA,CACA,qBAAA,CACA,SAAA,CACA,2DACC,YAAA,CACA,qBAAA,CAID,gHACC,+BAAA,CAED,wDACC,SAAA","sourcesContent":[".container {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\twidth: 50%;\r\n\t& > div {\r\n\t\tdisplay: flex;\r\n\t\tflex-direction: column;\r\n\t}\r\n}\r\n.error {\r\n\t[class*='ant-select ant-select-single ant-select-show-arrow'] {\r\n\t\tborder: 1px solid red !important;\r\n\t}\r\n\tlabel {\r\n\t\tcolor: red;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "time-zones-dropdown-container--1pL2WN_brMJ42U8z_nDUD_",
	"error": "time-zones-dropdown-error--Vo9HB8mj92g7ddHvPXuXf"
};
export default ___CSS_LOADER_EXPORT___;

import { Table } from 'antd';
import { ColumnsType, GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { buildColumn, ColumnsProps, ScrollType } from '../../../../../Helper';
import { AvailableDoorInfo } from '../../../../../model/PhotoCallUpModel';
import { SearchColumn } from '../../../../common';
import { DataType } from '../ConfigurationDoors';
import styles from '../doorsContainer.module.scss';

type Props = {
	onChangeSelection: (selectedRecords: DataType[]) => void;
	doorsSelected: DataType[];
};

const scroll: ScrollType = { y: 131 };
const maxLength = 32;

const mergedColumns = (columns: ColumnsProps<AvailableDoorInfo>[]) =>
	columns.map(col => ({
		...col,
		onCell: (record: AvailableDoorInfo) => ({
			record,
		}),
	})) as ColumnsType;

const DoorsSelected: React.FC<Props> = ({ onChangeSelection, doorsSelected }) => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	useEffect(() => {
		const refreshSelectedKeys = selectedRowKeys.filter(x => doorsSelected.findIndex(w => w.DoorId === x) >= 0);
		setSelectedRowKeys(refreshSelectedKeys);
	}, [JSON.stringify(doorsSelected)]);

	const columns: ColumnsProps<DataType>[] = [
		{
			...buildColumn(_('ControllerName'), 'ControllerName', undefined, 'start'),
			sorter: (a, b) => a.ControllerName.localeCompare(b.ControllerName),
			...SearchColumn({ maxLength, dataIndex: 'ControllerName', reset: false, label: _('ControllerName') }),
		},
		{
			...buildColumn(_('DoorName'), 'DoorName', undefined, 'start'),
			sorter: (a, b) => a.DoorName.localeCompare(b.DoorName),
			...SearchColumn({ maxLength, dataIndex: 'DoorName', reset: false, label: _('DoorName') }),
		},
	];

	const handleOnSelectRow = (record: DataType) => {
		const findKey = selectedRowKeys.indexOf(record.key);
		let findDoors = [];
		if (~findKey) {
			const cloneState = selectedRowKeys;
			cloneState.splice(findKey, 1);
			setSelectedRowKeys([...cloneState]);
			findDoors = doorsSelected.filter(x => cloneState.indexOf(x.key) >= 0);
		} else {
			const newKeys = [...selectedRowKeys, record.key];
			setSelectedRowKeys(newKeys);
			findDoors = doorsSelected.filter(x => newKeys.indexOf(x.key) >= 0);
		}
		onChangeSelection(findDoors);
	};

	const rowSelection: TableRowSelection<DataType> = {
		type: 'checkbox',
		selectedRowKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedRowKeys(selectedRowKeys);
			onChangeSelection(selectedRows);
		},
	};

	const rowKey: GetRowKey<DataType> = record => record.key;

	return (
		<div className={styles.containerTable}>
			<Table
				id='photoCallUpConfigurationSelectedDoorsTable'
				onRow={(record: DataType) => ({
					onClick: () => handleOnSelectRow(record),
				})}
				scroll={scroll}
				pagination={false}
				className={styles.statusWidgetTable}
				rowSelection={rowSelection}
				columns={mergedColumns(columns)}
				rowKey={rowKey}
				dataSource={doorsSelected}
				size='small'
				onHeaderRow={() => ({ id: 'photoCallUpConfigurationSelectedDoorsTableHeader' })}
				getPopupContainer={() => document.getElementById('photoCallUpConfigurationSelectedDoorsTableHeader')}
			/>
		</div>
	);
};

const memoizedComponent = React.memo(DoorsSelected);
export { memoizedComponent as DoorsSelected };

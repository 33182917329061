import { Button } from 'antd';
import React from 'react';
import { Modal } from '../../../../../common';
import styles from '../../controllerModal.module.scss';

type Props = {
	visible: boolean;
	width?: string;
	content?: React.ReactNode;
	onClickOk: () => void;
	onClickCancel: () => void;
	onClickNo: () => void;
};
const ChangeDBLocation: React.FC<Props> = ({ onClickCancel, onClickNo, onClickOk, visible, width, content }) => {
	return (
		<Modal
			className={styles.modalZoomAuto}
			closable={false}
			keyboard={false}
			maskClosable={false}
			onCancel={onClickCancel}
			width={width}
			visible={visible}
			footer={[
				<Button id='controllerChangeDBYesButton' type='primary' key='yes' onClick={onClickOk}>
					{_('Yes')}
				</Button>,
				<Button id='controllerChangeDBNoButton' key='no' onClick={onClickNo}>
					{_('No')}
				</Button>,
				<Button id='controllerChangeDBCancelButton' key='cancel' onClick={onClickCancel}>
					{_('Cancel')}
				</Button>,
			]}>
			{content}
		</Modal>
	);
};

export { ChangeDBLocation };

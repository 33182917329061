import cx from 'classnames';
import React from 'react';
import { RCGCredentials } from '../../../../../model/DeviceAdminModel';
import styles from './readerWarning.module.scss';

type Props = {
	messageError: string;
	rcgCredentials: RCGCredentials[];
};

const ReaderWarning: React.FC<Props> = ({ rcgCredentials, messageError }) => {
	return (
		<div className={styles.container}>
			<p>{messageError}</p>
			{rcgCredentials.length > 0 && (
				<div className={cx(styles.gridBase)}>
					<div>{_('LastName')}</div>
					<div>{_('FirstName')}</div>
					<div>{_('CredentialID')}</div>
					{rcgCredentials.map((data, index) => (
						<React.Fragment key={index}>
							<div>{data.LastName}</div>
							<div>{data.FirsName}</div>
							<div>{data.CredentialId}</div>
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	);
};

export { ReaderWarning };

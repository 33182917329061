import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../Helper';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setName, StoreContext } from '../../../contextExpansionRelay';
import styles from '../../../expansionRelayModal.module.scss';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
	nameErrorText: string;
	useDoorContext: boolean;
	setErrorText: () => void;
};

const Name: React.FC<Props> = ({ nameError, nameValidation, onResetNameError, nameErrorText, useDoorContext, setErrorText }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: { name },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			expansionRelayState: { name },
			dispatcher,
		} = useContext(StoreContext);
	}

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		useDoorContext ? dispatcherDoor(setName(value)) : dispatcher(setName(value));
		onResetNameError();
		useDoorContext ? setErrorText : null;
	};

	return (
		<div className={styles.generalSection}>
			<span>{useDoorContext ? _('RelayName') : _('Name')}</span>
			<div>
				<Input
					id='relayGeneralNameInput'
					key='name'
					value={name}
					onChange={handleOnChangeName}
					className={cx(styles.inputName, {
						[styles.error]: nameValidation || nameError,
					})}
					maxLength={32}
					autoComplete='chrome-off'
				/>
				{(nameValidation || nameError) && (
					<span id='relayErrorMessage' className={styles.errorMessage}>
						{nameErrorText}
					</span>
				)}
			</div>
		</div>
	);
};

export { Name };

import { Checkbox } from 'antd';
import React from 'react';
import { DisableReportingOfInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerReportingSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerReporting } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectDisableReportingOfInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import styles from '../reporting.module.scss';

const DisableReportingOf: React.FC = () => {
	const dispatch = useStoreDispatch();
	const disableReportingOfInfo: DisableReportingOfInfoStore = useStoreSelector<DisableReportingOfInfoStore>(selectDisableReportingOfInfo);

	const handleChangeControllerReportingSetup = (value: ControllerReporting) => {
		dispatch(
			setControllerReportingSetupAction({
				...value,
			})
		);
	};

	return (
		<div className={styles.disableReportingOf}>
			<Checkbox
				id='controllerReportingDisableDailyCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingDailyMidnight: e.target.checked })}
				checked={disableReportingOfInfo.dailyMidnightReport}>
				{_('DailyMidnightReport')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableRelayCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingRelayStateChanges: e.target.checked })}
				checked={disableReportingOfInfo.relayStateChanges}>
				{_('RelayStateChanges')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableInternalCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingInternalEvents: e.target.checked })}
				checked={disableReportingOfInfo.internalEvent}>
				{_('InternalEvents')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableTimeZoneStateCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingTimeZoneStateChanges: e.target.checked })}
				checked={disableReportingOfInfo.timeZoneStateChanges}>
				{_('TimeZoneStateChanges')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableExternalCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingExternalEvents: e.target.checked })}
				checked={disableReportingOfInfo.externalEvents}>
				{_('ExternalEvents')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableTimeZoneRelayCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingTimeZoneDrivenRelay: e.target.checked })}
				checked={disableReportingOfInfo.tzDrivenRelayStateChanges}>
				{_('TZDrivenRelayStateChanges')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableTransactionCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingTransactionEvents: e.target.checked })}
				checked={disableReportingOfInfo.transactionsEvents}>
				{_('TransactionsEvents')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableTimeZoneInputCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingTimeZoneDrivenInput: e.target.checked })}
				checked={disableReportingOfInfo.tzDrivenInputMaskingChanges}>
				{_('TZDrivenInputMaskingChanges')}
			</Checkbox>
			<Checkbox
				id='controllerReportingDisableInvalidCheckbox'
				onChange={e => handleChangeControllerReportingSetup({ DisableReportingInvalidCodes: e.target.checked })}
				checked={disableReportingOfInfo.invalidCodes}>
				{_('InvalidCodes')}
			</Checkbox>
		</div>
	);
};

export { DisableReportingOf };

import { Checkbox, Input } from 'antd';
import React from 'react';
import { removeCommasAndPercentSign } from '../../../../../../../Helper';
import { BasicControllerInfoStore, ControllerType, SelectCommandSet, SystemTimeZones } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import {
	setControllerBasicSetupAction,
	setControllerError,
	setControllerMiscSystemSetupAction,
	setControllerPropertyAction,
} from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerBasic, ControllerMisc, ControllerProperty } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectBasicControllerInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { ErrorMessage, Select, SelectOption } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../general.module.scss';

const modelOptions: SelectOption = [
	{ label: ControllerType[ControllerType.M1], value: ControllerType.M1 },
	{ label: ControllerType[ControllerType.M2], value: ControllerType.M2 },
	{ label: ControllerType[ControllerType.M8], value: ControllerType.M8 },
	{ label: ControllerType[ControllerType.M16], value: ControllerType.M16 },
	{ label: ControllerType[ControllerType.MSP], value: ControllerType.MSP },
	{ label: ControllerType[ControllerType.Mx1], value: ControllerType.Mx1 },
	{ label: ControllerType[ControllerType.Mx2], value: ControllerType.Mx2 },
	{ label: ControllerType[ControllerType.Mx4], value: ControllerType.Mx4 },
	{ label: ControllerType[ControllerType.Mx8], value: ControllerType.Mx8 },
	{ label: ControllerType[ControllerType.Mx1w], value: ControllerType.Mx1w },
];

export const getUnpermittedValue = (commandId: number, controllerCommands: SelectCommandSet[]): boolean => {
	const findCommand: number = controllerCommands.findIndex(x => x.CommandId === commandId);
	let isUnpermitted: boolean = false;
	if (~findCommand) {
		isUnpermitted = controllerCommands[findCommand].Unpermitted;
	}

	return isUnpermitted;
};

const BasicControllerInformation: React.FC = () => {
	const basicControllerInfo: BasicControllerInfoStore = useStoreSelector<BasicControllerInfoStore>(selectBasicControllerInfo);
	const dispatch = useStoreDispatch();

	const handleChangeControllerProperty = (value: ControllerProperty) => {
		dispatch(
			setControllerPropertyAction({
				...value,
			})
		);
	};

	const handleChangeControllerBasicSetup = (value: ControllerBasic) => {
		if (value.ControllerType === ControllerType.Mx1w) {
			handleChangeControllerMiscSetup({ WirelessVendor: 1 });
		}

		dispatch(
			setControllerBasicSetupAction({
				...value,
			})
		);
	};

	const handleChangeControllerMiscSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name: string = removeCommasAndPercentSign(e.currentTarget.value);
		if (basicControllerInfo.error.name) {
			dispatch(setControllerError({ name: false, message: '' }));
		}
		handleChangeControllerProperty({ Name: name });
	};

	const handleOnChangeModel = (value: string) => {
		let mapToRelayValue: number = 0;
		const controllerType = Number(value) as ControllerType;
		if (basicControllerInfo.error.controllerType) {
			dispatch(setControllerError({ controllerType: false, message: '' }));
		}
		handleChangeControllerBasicSetup({ ControllerType: controllerType });
		if (
			controllerType === ControllerType.Mx1 ||
			controllerType === ControllerType.Mx2 ||
			controllerType === ControllerType.Mx4 ||
			controllerType === ControllerType.Mx8
		) {
			dispatch(
				setControllerMiscSystemSetupAction({
					LocalKeypadProgrammingEnableCodeReset: false,
				})
			);
			dispatch(
				setControllerBasicSetupAction({
					IsDisableMasterCode: true,
				})
			);
		}

		if (controllerType === ControllerType.M1) {
			mapToRelayValue = 4;
		} else if (controllerType === ControllerType.Mx1) {
			mapToRelayValue = 2;
		}

		handleChangeControllerMiscSystemSetup(mapToRelayValue, 'AlarmRelayGeneralMapToRelay');
		handleChangeControllerMiscSystemSetup(mapToRelayValue, 'AlarmRelayDuressMapToRelay');
		handleChangeControllerMiscSystemSetup(mapToRelayValue, 'AlarmRelayTamperMapToRelay');
		handleChangeControllerMiscSystemSetup(mapToRelayValue, 'AlarmRelayTroubleMapToRelay');
	};

	const handleChangeControllerMiscSystemSetup = (value: number, key: keyof ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				[key]: value,
			})
		);
	};

	const handleOnChangeWirelessVendor = (value: string) => {
		if (basicControllerInfo.error.wirelessController) {
			dispatch(setControllerError({ wirelessController: false, message: '' }));
		}
		handleChangeControllerMiscSetup({ WirelessVendor: Number(value) });
	};

	const handleOnChangeControllerTimeZone = (id: string) => {
		const systemTimeZone: SystemTimeZones = basicControllerInfo.systemTimeZones.find(x => x.Id === id);
		if (systemTimeZone) {
			handleChangeControllerProperty({ TimeZoneDisplayName: systemTimeZone.DisplayName, TimeZoneStandardName: systemTimeZone.StandardName });
		}
	};

	const controllerGeneralNameInputId = 'controllerGeneralNameInput';
	const controllerGeneralDescriptionInputId = 'controllerGeneralDescriptionInput';
	const controllerGeneralAddressDropdownId = 'controllerGeneralAddressDropdown';
	const controllerGeneralModelDropdownId = 'controllerGeneralModelDropdown';
	const controllerGeneralWirelessDropdownId = 'controllerGeneralWirelessDropdown';
	const controllerGeneralEnableCheckboxId = 'controllerGeneralEnableCheckbox';
	const controllerGeneralUseDSTCheckboxId = 'controllerGeneralUseDSTCheckbox';
	const controllerGeneralTimeZoneDropdownId = 'controllerGeneralTimeZoneDropdown';
	const controllerGeneralCommandSetDropdownId = 'controllerGeneralCommandSetDropdown';

	return (
		<CardSection title={_('BasicControllerInformation')}>
			<div className={styles.controllerId}>
				<label>{_('ID')}:</label>
				<label className={styles.marginSpan}>{basicControllerInfo.id}</label>
			</div>
			<div className={styles.basicInformation}>
				<div>
					<div>
						<label htmlFor={controllerGeneralNameInputId}>{_('Name')}:</label>
						<ErrorMessage message={basicControllerInfo.error.message} show={basicControllerInfo.error.name}>
							<Input id={controllerGeneralNameInputId} maxLength={32} onChange={handleName} value={basicControllerInfo.name} />
						</ErrorMessage>
						<label htmlFor={controllerGeneralNameInputId}>(32 max)</label>
					</div>
					<div>
						<label htmlFor={controllerGeneralDescriptionInputId}>{_('Description')}:</label>
						<Input
							id={controllerGeneralDescriptionInputId}
							maxLength={50}
							onChange={e => handleChangeControllerProperty({ Description: e.target.value })}
							value={basicControllerInfo.description}
						/>
						<label htmlFor={controllerGeneralDescriptionInputId}>(50 max)</label>
					</div>
					<div></div>
					<div></div>
				</div>
				<div>
					<div>
						<label htmlFor={controllerGeneralAddressDropdownId}>{_('Address')}:</label>
						<Select
							id={controllerGeneralAddressDropdownId}
							onChange={value => handleChangeControllerProperty({ AddressId: Number(value) })}
							value={basicControllerInfo.controllerAddressId}
							notUseCurrentParse
							options={basicControllerInfo.availableAddresses.map(x => ({ value: x.Id, label: x.Name.toString() }))}
						/>
					</div>
					<div>
						<label htmlFor={controllerGeneralModelDropdownId}>{_('Model')}:</label>
						<div className={styles.controllerTypeDropdown}>
							<ErrorMessage message={basicControllerInfo.error.message} show={basicControllerInfo.error.controllerType}>
								<Select
									id={controllerGeneralModelDropdownId}
									onChange={handleOnChangeModel}
									value={basicControllerInfo.controllerType}
									notUseCurrentParse
									options={modelOptions}
									className={styles.controllerTypeDropdown}
								/>
							</ErrorMessage>
						</div>

						{basicControllerInfo.controllerType === ControllerType.Mx1w && (
							<React.Fragment>
								<label htmlFor={controllerGeneralWirelessDropdownId}>{_('WirelessVendor')}:</label>
								<ErrorMessage message={basicControllerInfo.error.message} show={basicControllerInfo.error.wirelessController}>
									<Select
										id={controllerGeneralWirelessDropdownId}
										onChange={handleOnChangeWirelessVendor}
										value={basicControllerInfo.wirelessVendor}
										notUseCurrentParse
										className={styles.wirelessVendorDropdown}
										options={basicControllerInfo.wirelessVendorList.map(x => ({ label: x.Name, value: x.Id }))}
									/>
								</ErrorMessage>
							</React.Fragment>
						)}
					</div>
					<div>
						<Checkbox
							id={controllerGeneralEnableCheckboxId}
							onChange={value => handleChangeControllerProperty({ IsPanelInstalled: value.target.checked === true ? 1 : 0 })}
							checked={basicControllerInfo.isEnabled}>
							<label htmlFor={controllerGeneralEnableCheckboxId}>{_('Enable')}</label>
						</Checkbox>
					</div>
				</div>
				<div>
					<div>
						<label htmlFor={controllerGeneralTimeZoneDropdownId}>{_('TimeZoneWhereControllerIsLocated')}</label>
						<Select
							id={controllerGeneralTimeZoneDropdownId}
							onChange={handleOnChangeControllerTimeZone}
							value={basicControllerInfo.timeZoneDisplayName}
							options={basicControllerInfo.systemTimeZones.map(ts => ({ label: ts.DisplayName, value: ts.Id }))}
							showSearch
						/>
						<div></div>
						<Checkbox
							id={controllerGeneralUseDSTCheckboxId}
							className={styles.marginTop}
							onChange={value => handleChangeControllerProperty({ IsUseDST: value.target.checked })}
							checked={basicControllerInfo.isUseDST}>
							<label htmlFor={controllerGeneralUseDSTCheckboxId}>{_('UseDaylightSavingsTime')}</label>
						</Checkbox>
					</div>
					<div>
						<label htmlFor={controllerGeneralCommandSetDropdownId}>{_('AdditionalCommandSetsToDownload')}</label>
						<Select
							id={controllerGeneralCommandSetDropdownId}
							onChange={value => handleChangeControllerProperty({ CommandSetId: Number(value) })}
							value={basicControllerInfo.commandSetId}
							disabled={basicControllerInfo.isCommandSetAllowed}
							notUseCurrentParse
							options={basicControllerInfo.controllerCommandSets.map(x => ({ label: x.CommandName, value: x.CommandId }))}
						/>
					</div>
				</div>
			</div>
		</CardSection>
	);
};

export { BasicControllerInformation };

import { CaretDownOutlined, CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
	useHandleOnChangeTableLogic,
} from '../../../../Helper';
import { deviceAdminApi } from '../../../../api/DeviceAdminApi';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import {
	BaseColumns,
	DefaultColumns,
	OperatorsColumn,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	SelectOptions,
	SelectedDeviceKeys,
	SelectedDevicePage,
} from '../../../../model/CommonModel';
import {
	ButtonBuilder,
	Commands,
	DeviceObjectType,
	DeviceSelectedPage,
	OptionsButtonBuilder,
	SelectedRowKeyPagination,
	VelocityConfigurationModel,
} from '../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import {
	clearVelocityConfigurationSelectionsAction,
	setCommandsSetByData,
	setCommandsSetsBy,
	setVelocityConfigurationEditMode,
	setVelocityConfigurationFilterMode,
	setVelocitySelectedRowKeysPaginationAction,
} from '../../../../store/deviceControl/actions';
import { selectTablePaginationSetting, selectVelocityConfiguration } from '../../../../store/deviceControl/selectors';
import { ButtonDropdown, EditableCell, ModalConfirmationList, SearchColumn } from '../../../common';
import { CommandSetsModal } from './CommandSetsModal/CommandSetsModal';
import styles from './commandSets.module.scss';

type Props = {};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Command_Set);
const scroll: ScrollType = { x: 379, y: 312 };
const maxLength: number = 32;
const deviceObjectType: DeviceObjectType = DeviceObjectType.CommandSets;

const createButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
				id: 'commandSetsTableActionDropdownEditButton',
				label: _('Edit'),
				disabled: !componentPermission.canView || hasMoreThanOne,
				title: getPermissionErrorMessage(componentPermission.canView, false, hasMoreThanOne),
				value: 'edit',
			},
			{
				id: 'commandSetsTableActionDropdownRenameButton',
				label: _('Rename'),
				disabled: !componentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
				title: getPermissionErrorMessage(componentPermission.canUpdate, false, hasMoreThanOne),
				value: 'rename',
			},
			{
				id: 'commandSetsTableActionDropdownDeleteButton',
				label: _('Delete'),
				disabled: !componentPermission.canDelete,
				title: getPermissionErrorMessage(componentPermission.canDelete),
				value: 'delete',
			},
		],
	};
};

const CommandSets: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const { commandsSets, isEditMode, isFilterMode, selectedRowKeysPagination }: VelocityConfigurationModel = useStoreSelector(selectVelocityConfiguration);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const handleOnChangeTableLogic = useHandleOnChangeTableLogic();

	const [editingKey, setEditingKey] = useState('');
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
	const [tableData, setTableData] = useState<DefaultColumns[]>([]);
	const [openModal, setOpenModal] = useState(false);
	const [commandEditable, setCommandEditable] = useState<Commands>(undefined);
	const [commandSetSelectedPage, setCommandSetSelectedPage] = useState<SelectedDevicePage>(null);
	const [form] = Form.useForm();

	const redirectPage = useRef<boolean>(false);
	const isSearchPerformed = useRef<boolean>(false);

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setIsItemOnSamePage(isSamePage);

		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	useEffect(() => {
		const mapRes: DefaultColumns[] = createData();
		setTableData(mapRes);
	}, [JSON.stringify(commandsSets)]);

	// * This hook is responsible for loading the previously edited command set
	useEffect(() => {
		if (redirectPage.current) {
			setRedirectPage(false);
			if (selectedRowKeys.length === 1) {
				const commandSetId = Number(selectedRowKeys?.[0]);
				getCommandSetSelectedPage(commandSetId);
			}
		}
	}, [redirectPage.current]);

	useEffect(() => {
		if (commandSetSelectedPage) {
			setRedirectPage(false);
			setIsItemOnSamePage(true);

			const { deviceId, pageNumber } = commandSetSelectedPage;

			batch(() => {
				dispatch(setVelocitySelectedRowKeysPaginationAction([{ Id: deviceId.toString(), PaginationPage: pageNumber }]));
				dispatch(setCommandsSetsBy({ ...tablePaginationSetting, PageNumber: pageNumber, SearchedValue: '' }));
			});

			setCommandSetSelectedPage(null);
		}
	}, [JSON.stringify(commandSetSelectedPage)]);

	// * This hook ensure that always return a valid page number when no values has returned
	useEffect(() => {
		if (tablePaginationSetting.TotalItems === 0 && tablePaginationSetting.PageNumber > 1) {
			const { PageNumber: currentPage } = tablePaginationSetting;
			dispatch(
				setCommandsSetsBy({
					...tablePaginationSetting,
					PageNumber: currentPage - 1,
				})
			);
		}
	}, [tablePaginationSetting]);

	useEffect(() => {
		return () => {
			clearSelection();
		};
	}, []);

	const getCommandSetSelectedPage = async (deviceId: number) => {
		const deviceSelectedPage: DeviceSelectedPage = {
			DeviceId: deviceId,
			DeviceObjectType: deviceObjectType,
			IsMasterDoorGroup: false,
		};
		const response = await deviceAdminApi.getVelocityDeviceSelectedPage(deviceSelectedPage, { ...tablePaginationSetting, SearchedValue: '' });
		const { Entity: pageNumber } = response;

		setCommandSetSelectedPage({ deviceId, pageNumber });
	};

	const clearSelection = () => {
		if (redirectPage.current) {
			return;
		}
		dispatch(clearVelocityConfigurationSelectionsAction({ objectType: deviceObjectType }));
		setSelectedRowKeys([]);
	};

	const onRemoveCommandSetLocks = async (commandSet: Commands): Promise<void> => {
		await deviceAdminApi.unlockComponentDeviceAdmin(commandSet.CommandSetId, SecuredComponents.Command_Set);
	};

	const onDeleteCommandSetRequest = async (commandSet: Commands): Promise<void> => {
		const response = await deviceAdminApi.deleteCommandSetRequest(commandSet.CommandSetId, commandSet.Name);
		if (handleResponse(response)) {
			return;
		}

		onRemoveCommandSetLocks(commandSet);
	};

	const handleOkConfirmation = async (commandSetsSelected: SelectedDeviceKeys[]) => {
		for (const commandSet of commandSetsSelected) {
			await onDeleteCommandSetRequest({ CommandSetId: commandSet.Id, Name: commandSet.Name } as Commands);
		}
		setSelectedRowKeys([]);
		dispatch(setVelocitySelectedRowKeysPaginationAction([]));
		dispatch(setCommandsSetsBy(tablePaginationSetting));
	};

	const disabled: boolean = editingKey !== '';
	// const commandSetsSelected: Commands[] = commandsSets.filter(c => c.checked);
	const hasMoreThanOneKeySelected: boolean = selectedRowKeys.length > 1;
	const items = createButtonOptions(hasMoreThanOneKeySelected, isItemOnSamePage);
	// const selectedRowKeys = commandSetsSelected.map<React.Key>(c => c.CommandSetId);
	// const isPaginationItemSelected: boolean = commandSetsSelected.length !== 1;

	const handleOnSearchResults = (searchedValue: string) => {
		if (redirectPage.current) {
			return;
		}
		dispatch(setCommandsSetsBy({ ...tablePaginationSetting, TotalItems: 0, PageNumber: 1, SearchedValue: searchedValue }));
	};

	let columns: ColumnsProps<DefaultColumns>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: undefined,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
	];
	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const onChangeSelection = (key: BaseColumns) => {
		const commandSetId = key.key;
		if (selectedRowKeys.findIndex(key => key === commandSetId) === -1) {
			const cloneState: Commands[] = commandsSets.map(x =>
				x.CommandSetId.toString() === commandSetId ? { ...x, checked: true } : { ...x, checked: false }
			);

			dispatch(setCommandsSetByData(cloneState));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: commandSetId,
				PaginationPage: tablePaginationSetting.PageNumber,
			};
			setIsItemOnSamePage(true);
			dispatch(setVelocitySelectedRowKeysPaginationAction([{ ...selectedKeysPagination }]));
			setSelectedRowKeys([commandSetId]);
		}
	};

	const isEditing = (record: DefaultColumns) => record.key.toString() === editingKey;
	const contextMenuOptions: SelectOptions<string>[] = [
		{
			id: 'commandSetsTableExecuteDropdown',
			label: _('Execute'),
			disabled: disabled,
			value: 'execute',
			separator: true,
		},
		...items?.options,
	];

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: DefaultColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: DefaultColumns) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,

						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const getSelectedCommandSets = async (): Promise<SelectedDeviceKeys[]> => {
		const response = await deviceAdminApi.getVelocityDevicesBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);

		if (handleResponse(response)) {
			return [];
		}

		return response.Entity;
	};

	const getCommandSet = async (commandSetId: number): Promise<Commands> => {
		const response = await deviceAdminApi.getCommandSet(commandSetId);

		if (handleResponse(response)) {
			return;
		}

		const { Entity: commandSet } = response;

		return commandSet;
	};

	const getSelectedCommandSet = async (commandSetId: number): Promise<Commands> => {
		return commandsSets.find(x => x.CommandSetId === commandSetId) ?? (await getCommandSet(commandSetId));
	};

	const handleActionScope = async (key: string) => {
		switch (key) {
			case 'delete':
				const commandSetsSelected = await getSelectedCommandSets();
				ModalConfirmationList({
					type: _('CommandSet'),
					data: commandSetsSelected,
					onConfirm: () => handleOkConfirmation(commandSetsSelected),
				});
				return;

			case 'execute':
				handleExecuteCommand();
				return;
		}

		const selectedCommandSetId = Number(selectedRowKeys?.[0]);
		const commandSet: Commands = await getSelectedCommandSet(selectedCommandSetId);
		const lockComponentCondition: boolean = key === 'edit';
		const isLocked: boolean = await lockedValidation(deviceObjectType, commandSet.CommandSetId, lockComponentCondition);
		if (isLocked) {
			return;
		}

		switch (key) {
			case 'rename':
				dispatch(setVelocityConfigurationEditMode(true));
				const findKey: DefaultColumns = tableData.find(x => x.key === commandSet.CommandSetId.toString());
				edit(findKey);
				const cloneState: Commands[] = changeStateProps(commandSet.CommandSetId, { editable: true });
				dispatch(setCommandsSetByData(cloneState));
				break;

			case 'edit':
				setCommandEditable(commandSet);
				handleVisibleAddCommand(true);
				break;
		}
	};

	const changeStateProps = (deviceId: number, commandsSet: Partial<Commands>): Commands[] => {
		const cloneState: Commands[] = [...commandsSets];
		const index = cloneState.findIndex(x => x.CommandSetId === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...commandsSet });
		}

		return cloneState;
	};

	const edit = (record: DefaultColumns) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const resetSearchColumn = () => {
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
	};

	const handleOnSaveEditRow = async (deviceId: number) => {
		const isLocked: boolean = await lockedValidation(deviceObjectType, deviceId, false);
		if (isLocked) {
			setEditingKey('');
			const cloneState: Commands[] = changeStateProps(deviceId, { editable: false });
			dispatch(setCommandsSetByData(cloneState));
			dispatch(setVelocityConfigurationEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(deviceObjectType, name.trim(), deviceId);

		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setVelocityConfigurationEditMode(false));
			dispatch(setCommandsSetsBy(tablePaginationSetting));
			return;
		}

		setEditingKey('');
		dispatch(setVelocityConfigurationEditMode(false));
		resetSearchColumn();
		setRedirectPage(true);
		dispatch(setCommandsSetsBy(tablePaginationSetting));
	};

	const handleOnCancelEditRow = (deviceId: number) => {
		setEditingKey('');
		const cloneState: Commands[] = changeStateProps(deviceId, { editable: false });
		dispatch(setCommandsSetByData(cloneState));
		dispatch(setVelocityConfigurationEditMode(false));
	};

	const handleExecuteCommand = async (): Promise<void> => {
		const commandSetsSelected = await getSelectedCommandSets();
		for (const commandSet of commandSetsSelected) {
			const response = await deviceAdminApi.executeCommandSet(commandSet.Id);
			if (response.ResponseStatusCode !== ResponseStatusCode.Success) {
				notification['error']({
					message: _(`ResponseStatusCode_${response.ResponseStatusCode}`),
				});
			}
		}
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const response = await deviceAdminApi.getCommandSets();
		const commandSetsIds = response?.Entity?.map<string>(x => x.CommandSetId.toString());
		return getUniqueValuesArray(newSelectedRowKeys, commandSetsIds);
	};

	const handleChange = (selectedRowKeys: React.Key[]) => {
		const cloneState: Commands[] = commandsSets.map(x => ({ ...x, checked: selectedRowKeys.findIndex(r => r === x.CommandSetId) !== -1 }));
		dispatch(setCommandsSetByData(cloneState));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			selectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(selectedRowKeys);
	};

	const handleSelectAll = async () => {
		setIsTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: Commands[] = commandsSets.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.CommandSetId?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setCommandsSetByData(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		setIsTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = commandsSets.map<React.Key>(x => x.CommandSetId?.toString());
		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		const cloneState: Commands[] = commandsSets.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.CommandSetId?.toString()) !== -1,
		}));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setCommandsSetByData(cloneState));
		dispatch(setVelocitySelectedRowKeysPaginationAction(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const handleVisibleAddCommand = (visible: boolean) => {
		setOpenModal(visible);
	};

	const createActionElement = (commandSet: Commands): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (commandSet.editable) {
			const commandSetId = commandSet.CommandSetId;

			content = (
				<>
					<Button
						id={`commandSetsTableActionDropdownSaveButton-${commandSetId}`}
						key='save'
						type='primary'
						onClick={() => handleOnSaveEditRow(commandSetId)}>
						{_('Save')}
					</Button>
					<Button id={`commandSetsTableActionDropdownCancelButton-${commandSetId}`} key='cancel' onClick={() => handleOnCancelEditRow(commandSetId)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const createData = (): DefaultColumns[] => {
		if (commandsSets.length > 0) {
			return commandsSets.map<DefaultColumns>((data: Commands) => ({
				key: data.CommandSetId.toString(),
				Action: createActionElement(data),
				Name: data.Name,
			}));
		}

		return [];
	};

	const buttonsBuilder = (button: ButtonBuilder[]): React.ReactNode =>
		button.map(x => (
			<Button id={x.id} key={x.label} onClick={x.onClick} disabled={x.disabled} title={x.title} type='primary'>
				{x.icon} {x.label}
			</Button>
		));

	const buttons: ButtonBuilder[] = [
		{
			id: 'commandSetsAddCommandSetButton',
			label: _('AddCommandSet'),
			icon: <PlusOutlined />,
			disabled: !componentPermission.canAdd || isEditMode,
			title: getPermissionErrorMessage(componentPermission.canAdd),
			onClick: () => {
				setCommandEditable(undefined);
				handleVisibleAddCommand(true);
			},
		},
	];

	const rowSelection: TableRowSelection<DefaultColumns> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `commandSetsTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`commandSetsTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectCommand')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination: TablePaginationConfig, filters: { Name?: string[] }, sorter: SorterResult<OperatorsColumn>) => {
		const { current, pageSize, shouldUpdateSearchResults, shouldUpdateTableResults, sortField, sortOrder } = handleOnChangeTableLogic({
			clearSelection,
			filters,
			handleChangePagination,
			isSearchPerformed,
			pagination,
			shouldResetSearchColumn,
			sorter,
			tablePaginationSetting,
		});

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setCommandsSetsBy({
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField.toString(),
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<div className={styles.container}>
			<div className={styles.buttonContainer}>
				<Button id={'commandSetsTableExecuteDropdown'} disabled={disabledActionButtons} onClick={() => handleExecuteCommand()}>
					<CaretRightOutlined /> {_('Execute')}
				</Button>
				<ButtonDropdown
					id={'commandSetsTableActionDropdown'}
					disabled={disabledActionButtons}
					menuOptions={items.options}
					icon={<CaretDownOutlined />}
					labelIcon={items.labelOrIcon}
					onClickOption={handleActionScope}
				/>
				{buttonsBuilder(buttons)}
			</div>
			<Form form={form} component={false}>
				<Table
					id={'commandSetsTable'}
					columns={mergedColumns as ColumnsType}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={tableData}
					pagination={pagination}
					scroll={scroll}
					size='small'
					className={styles.statusWidgetTable}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
				/>
			</Form>
			{openModal && (
				<CommandSetsModal
					onSetVisible={handleVisibleAddCommand}
					commandToBeEditable={commandEditable}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
		</div>
	);
};

export { CommandSets };

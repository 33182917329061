import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { photoCallUpApi } from '../../../../api';
import { VelocityDevice } from '../../../../model/DeviceAdminModel';
import { AvailableDoorInfo } from '../../../../model/PhotoCallUpModel';
import { Controllers } from './Controllers/Controllers';
import { Doors } from './Doors/Doors';
import styles from './doorsContainer.module.scss';
import { DoorsSelected } from './DoorsSelected/DoorsSelected';

type Props = {
	onChangeDoorsSelected: (records: AvailableDoorInfo[]) => void;
	doorsBelongFromParent: AvailableDoorInfo[];
	controllersForDoors: VelocityDevice[];
};

export type DataType = AvailableDoorInfo & { key: React.Key };

const ConfigurationDoors: React.FC<Props> = ({ onChangeDoorsSelected, doorsBelongFromParent, controllersForDoors }) => {
	const [controllersSelected, setSelectedControllers] = useState<VelocityDevice[]>([]);
	const [doors, setDoors] = useState<DataType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [doorsSelected, setSelectedDoors] = useState<DataType[]>([]);
	const [doorsAdded, setSelectedDoorsAdded] = useState<DataType[]>([]);
	const [doorsPreSelected, setPreSelectedDoors] = useState<DataType[]>([]);

	useEffect(() => {
		setSelectedDoorsAdded(doorsBelongFromParent.map(x => ({ ...x, key: `${x.DoorId}-${x.ControllerId}` })));
	}, []);

	useEffect(() => {
		onChangeDoorsSelected(doorsAdded);
	}, [doorsAdded.length]);

	useEffect(() => {
		if (controllersSelected.length > 0) {
			const controllersIds = controllersSelected.map(x => x.Id);
			setLoading(true);
			photoCallUpApi
				.retrieveAvailableDoors(controllersIds)
				.then(res => {
					setDoors(res.map(x => ({ ...x, key: `${x.DoorId}-${x.ControllerId}` })));
				})
				.finally(() => setLoading(false));
		} else {
			setDoors([]);
		}
	}, [JSON.stringify(controllersSelected)]);

	const handleOnSelectControllers = useCallback(
		(controllers: VelocityDevice[]) => {
			setSelectedControllers(controllers);
		},
		[JSON.stringify(controllersForDoors)]
	);

	const handleOnSetDoors = useCallback(
		(doors: DataType[]) => {
			setSelectedDoors(doors);
		},
		[JSON.stringify(doors)]
	);

	const handleOnPreSelectedDoors = useCallback(
		(doorsAdded: DataType[]) => {
			setPreSelectedDoors(doorsAdded);
		},
		[JSON.stringify(doorsAdded)]
	);

	const handleAddDoors = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const addControllerName = doorsSelected.map(x => ({ ...x, ControllerName: controllersForDoors.find(w => w.Id === x.ControllerId).Name }));
		setSelectedDoorsAdded(doorsAdded => [...doorsAdded, ...addControllerName]);
		setSelectedDoors([]);
	};
	const handleRemoveDoors = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		const filterAdded = doorsAdded.filter(x => doorsPreSelected.findIndex(w => w.DoorId === x.DoorId) < 0);
		setSelectedDoorsAdded(filterAdded);
		setPreSelectedDoors([]);
	};

	const filterDoors = doors.filter(x => doorsAdded.findIndex(w => w.DoorId === x.DoorId) < 0);
	const addDisabled = doorsSelected.length === 0 || doorsAdded.length > 10;
	const removeDisabled = doorsPreSelected.length === 0;
	const disableSelection = doorsAdded.length + doorsSelected.length === 10;

	return (
		<div className={styles.container}>
			<div>
				<h3>{_('PhotoCallUpSelectUpTo10DoorsToView')}</h3>
				<label>{_('PhotoCallUpListOfAvailableDoors')}</label>
			</div>
			<div>
				<Controllers onChangeSelection={handleOnSelectControllers} controllers={controllersForDoors} />
				<Doors
					onChangeSelection={handleOnSetDoors}
					doors={filterDoors}
					disableSelection={disableSelection}
					doorsSelectedKeys={doorsSelected.map(x => x.key)}
					loading={loading}
				/>
			</div>
			<div>
				<Button type='primary' onClick={handleAddDoors} disabled={addDisabled}>
					<PlusOutlined /> {_('Add')}
				</Button>
			</div>
			<div>
				<DoorsSelected onChangeSelection={handleOnPreSelectedDoors} doorsSelected={doorsAdded} />
			</div>
			<div>
				<Button type='primary' onClick={handleRemoveDoors} disabled={removeDisabled}>
					<MinusOutlined /> {_('Remove')}
				</Button>
			</div>
		</div>
	);
};

export { ConfigurationDoors };

export enum UserDefinedFieldType {
	All = -2,
	Text = -1,
	DropDown = 0,
	DropDownList = 2,
	DropDownAsc = 3,
	DropDownDsc = 4,
	DropDownListAsc = 5,
	DropDownListDsc = 6,
	Date = 7,
	Number = 8,
	UniqueNumber = 9,
	AutoUniqueNumber = 10,
	AutoSequentialNumber = 11,
	UniqueText = 12,
	UUID = 13,
}
export type UserDefinedFieldSetup = {
	FieldNumber: number;
	Caption: string;
	Type: UserDefinedFieldType;
	AutoSequentialValue: number;
};

export type UserDefinedFieldModel = {
	Value: string;
	FieldNumber: number;
	PersonId: number;
	Type: UserDefinedFieldType;
	Caption: string;
	IsPIVField: boolean;
};

export type UserDefinedFieldDetailModel = {
	CanView: boolean;
	Required: boolean;
	ListDefaults: string[];
	TabId: number;
	Color: string;
} & UserDefinedFieldModel;

export type UDFGroupModel = {
	CanView: boolean;
	TabId: number;
	TabCaption: string;
	Items: UserDefinedFieldDetailModel[];
};

import React from 'react';
import cx from 'classnames';
import { ConflictDoorGroupViewModel } from '../../../../../../model/DeviceAdminModel';
import styles from './doorGroupConflict.module.scss';

type Props = {
	conflictModel: ConflictDoorGroupViewModel;
};

const DoorGroupConflict: React.FC<Props> = ({ conflictModel }) => {
	const {
		DoorGroupConflict: { ControllersDoorGroups, DoorGroupCredentialControllers, DoorGroupFunctionGroupControllers, HasDoorGroupName, ErrorMessage },
	} = conflictModel;

	return (
		<div className={styles.container}>
			<p>{ErrorMessage}</p>
			{ControllersDoorGroups.length > 0 && (
				<div
					className={cx(styles.gridBase, styles.doorsGroup, {
						[styles.doorsGroupsExtraColumn]: HasDoorGroupName,
					})}>
					<div>{_('ControllerName')}</div>
					{HasDoorGroupName && <div>{_('DoorGroupName')}</div>}
					{conflictModel.DoorGroupConflict.ControllersDoorGroups.map((data, index) => (
						<React.Fragment key={index}>
							<div>{data.Item1}</div>
							{HasDoorGroupName && <div>{data.Item2}</div>}
						</React.Fragment>
					))}
				</div>
			)}
			{DoorGroupCredentialControllers.length > 0 && (
				<div
					className={cx(styles.gridBase, styles.controllers, {
						[styles.controllersExtraColumn]: HasDoorGroupName,
					})}>
					<div>{_('CredentialID')}</div>
					<div>{_('ControllerName')}</div>
					<div>{_('FunctionName')}</div>
					<div>{_('FunctionCategoryName')}</div>
					{HasDoorGroupName && <div>{_('DoorGroupName')}</div>}
					{DoorGroupCredentialControllers.map((data, index) => (
						<React.Fragment key={index}>
							<div>{data.CredentialId}</div>
							<div>{data.ControllerName}</div>
							<div>{data.FunctionName}</div>
							<div>{data.FunctionCategoryName}</div>
							{HasDoorGroupName && <div>{data.DoorGroupName}</div>}
						</React.Fragment>
					))}
				</div>
			)}
			{DoorGroupFunctionGroupControllers.length > 0 && (
				<div
					className={cx(styles.gridBase, styles.groupControllers, {
						[styles.groupControllersExtraColumn]: HasDoorGroupName,
					})}>
					<div>{_('ControllerName')}</div>
					<div>{_('FunctionGroupName')}</div>
					<div>{_('FunctionGroupExtension')}</div>
					<div>{_('FunctionName')}</div>
					<div>{_('FunctionCategoryName')}</div>
					{HasDoorGroupName && <div>{_('DoorGroupName')}</div>}
					{DoorGroupFunctionGroupControllers.map((data, index) => (
						<React.Fragment key={index}>
							<div>{data.ControllerName}</div>
							<div>{data.FunctionGroupName}</div>
							<div>{data.FunctionGroupExtension}</div>
							<div>{data.FunctionName}</div>
							<div>{data.FunctionCategoryName}</div>
							{HasDoorGroupName && <div>{data.DoorGroupName}</div>}
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	);
};

export { DoorGroupConflict };

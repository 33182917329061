import { WarningOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import React from 'react';
import { Modal } from '../../../../../../../common';

type Props = {
	visible: boolean;
	onClickOk: () => void;
	onClickCancel: () => void;
	onClickNo: () => void;
};

const style: React.CSSProperties = { color: 'orange', fontSize: 24 };

const Confirmation: React.FC<Props> = ({ onClickCancel, onClickNo, onClickOk, visible }) => {
	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={onClickCancel}
			visible={visible}
			title={
				<label>
					<WarningOutlined style={style} /> {_('Warning')}
				</label>
			}
			footer={[
				<Button type='primary' key='yes' onClick={onClickOk}>
					{_('Yes')}
				</Button>,
				<Button key='no' onClick={onClickNo}>
					{_('No')}
				</Button>,
				<Button key='cancel' onClick={onClickCancel}>
					{_('Cancel')}
				</Button>,
			]}>
			{_('SaveChangesMadeToThisMasterControlZone')}
		</Modal>
	);
};

export { Confirmation };

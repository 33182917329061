import { Button, notification, Spin, Tabs } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { credentialApi, deviceAdminApi, enrollmentApi } from '../../../api';
import { handleResponse } from '../../../Helper';
import { SecuredComponents, SubPermissions, User } from '../../../model/AccountModel';
import { ResponseStatusCode } from '../../../model/CommonModel';
import { CredentialEnrollmentResponse } from '../../../model/CredentialModel';
import { AffectedCredential, CredentialDetail, CredentialResponseCode, CredentialType, ThreatLevel } from '../../../model/EnrollmentModel';
import { Logger } from '../../../model/LoggingModel';
import { InformationMessages, Modal, ModalConfirmation, ModalConfirmCustomFooter } from '../../common';
import AffectedCredentialsTable from './AffectedCredentialsTable/AffectedCredentialsTable';
import styles from './credentialmodal.module.scss';
import {
	setCardPropertyAction,
	setCompleteModelAction,
	setErrorAction,
	setGeneralTabServicesDataAction,
	setLimitsTabPropertyAction,
	setOverrideResponseAction,
	setRootModelPropertyAction,
} from './credentialTemplateContext/actions';
import { CredentialStoreContext, getCredentialInitialState } from './credentialTemplateContext/context';
import { ValidationErrorsProperty } from './CredentialTemplateModalModels';
import {
	CredentialTemplateModalFunctionsTab,
	CredentialTemplateModalGeneralTab,
	CredentialTemplateModalLimitsTab,
	CredentialTemplateModalOptionsTab,
} from './CredentialTemplateModalTabs';
import { mapJsCredentialToModel, mapModelCredentialToJs } from './Helper';

const { TabPane } = Tabs;

type ModalComponentProps = {
	credentialId: number;
	handleModalOnCancel: () => void;
	handleModalOnSuccess: () => void;
	isNew: boolean;
	user: User;
	userId: number;
};

enum LoadingType {
	MainSaveChanges = '1',
	WarningSaveChanges = '2',
}

enum CredentialConflicts {
	KeypadCode = 0,
	OldCode,
	DualCode,
}

type AffectedCredentialsTableType = {
	affectedCredentials: AffectedCredential[];
	confirmationText: string;
	credential: CredentialDetail;
};

export const ModalComponent: React.FC<ModalComponentProps> = ({ credentialId, handleModalOnCancel, handleModalOnSuccess, isNew, user, userId }) => {
	const { credentialInitialState, dispatcher } = useContext(CredentialStoreContext);
	const {
		alertModalObj: { children, hidden, onCancel, onOk, title },
		configData: { IsDeviceAdminCredentialTemplate },
		errors: { keypadCode, matchCode, name, other },
		fetchInitialServices,
		generalTabState: {
			activationExpirationSectionState: { activationDate, expiration, expirationUsed, hostExpirationUDF, hostExpirationInterval },
			credentialId: contextCredentialId,
			cardSectionState: { cardFormats, cardType },
			informationSectionState: { badgeTemplates, credentialTemplates, linkToNewCredentials },
			isCredentialTemplate,
			keypadSectionState: { alwaysHidePin, pinCode, pinCodeConfirmMasked, pinCodeMasked, showClearPin },
		},
		functionsTabState: { credentialFunctions },
		hasChanged,
		loading,
		previousLinkNewValue,
		restartedModel,
	} = credentialInitialState;

	const canViewGeneralTab: boolean = User.getSubComponentPermission(
		user,
		isCredentialTemplate ? SubPermissions.CredentialTemplate_ViewGeneralTab : SubPermissions.Enrollment_Credential_ViewGeneralTab
	).allowed;
	const canViewFunctionsTab: boolean = User.getSubComponentPermission(
		user,
		isCredentialTemplate ? SubPermissions.CredentialTemplate_ViewFunctionTab : SubPermissions.Enrollment_Credential_ViewFunctionTab
	).allowed;
	const canViewLimitsTab: boolean = User.getSubComponentPermission(
		user,
		isCredentialTemplate ? SubPermissions.CredentialTemplate_ViewLimitsTab : SubPermissions.Enrollment_Credential_ViewLimitsTab
	).allowed;
	const canViewOptionsTab: boolean = User.getSubComponentPermission(
		user,
		isCredentialTemplate ? SubPermissions.CredentialTemplate_ViewOptionsTab : SubPermissions.Enrollment_Credential_ViewOptionsTab
	).allowed;

	const canUpdateComponent = User.getComponentPermission(
		user,
		isCredentialTemplate ? SecuredComponents.Credential_Template : SecuredComponents.Enrollment_Credential
	).canUpdate;

	const getActiveTab = (): string => {
		if (canViewGeneralTab || isNew) return '1';
		else {
			if (canViewFunctionsTab) return '2';
			if (canViewLimitsTab) return '3';
			return '4';
		}
	};

	const [activeTab, setActiveTab] = useState<string>(getActiveTab());
	const [showHasChangedModal, setHasChangedModal] = useState(false);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [warningSaveChangesSubmitted, setWarningSaveChangesSubmitted] = useState<boolean>(false);
	const [affectedCredentialsTable, setAffectedCredentialsTable] = useState<AffectedCredentialsTableType>({
		affectedCredentials: undefined,
		confirmationText: undefined,
		credential: undefined,
	});

	const userIdRef = useRef(userId);

	useEffect(() => {
		dispatcher(setCompleteModelAction(getCredentialInitialState()));
		if (credentialId > -1) {
			credentialApi.getCredentialInformation(credentialId, isNew, undefined).then(credentialInfo => {
				if (!handleResponse(credentialInfo) && credentialInfo.ResponseStatusCode === ResponseStatusCode.Success) {
					const credentialModel = mapModelCredentialToJs(credentialInfo, credentialInitialState, false, IsDeviceAdminCredentialTemplate);
					if (userId === CredentialType.FindCredential) {
						userIdRef.current = credentialInfo.HostUserId;
					}
					credentialModel.fetchInitialServices = true;
					credentialModel.fetchedCredential = true;
					credentialModel.modelEstablished = true;
					dispatcher(setCompleteModelAction(credentialModel));
				} else {
					handleModalOnCancel();
				}
			});
		} else {
			dispatcher(setRootModelPropertyAction({ fetchInitialServices: true }));
		}
	}, []);

	useEffect(() => {
		if (fetchInitialServices) {
			callInitialServices();
		}
	}, [fetchInitialServices]);

	const callInitialServices = () => {
		Promise.all([
			enrollmentApi.getBadgeTemplates(),
			enrollmentApi.getCardFormats(),
			enrollmentApi.getCredentialTemplatesByOperator(),
			enrollmentApi.getCredentialMessages(),
			enrollmentApi.getCredentialTemplateThreatLevels(),
		])
			.then(res => {
				const [badgeTemplates, cardFormats, credentialTemplates, credentialMessages, threatLevels] = res;
				if (handleResponse(credentialTemplates)) {
					return;
				}

				const lCredentialTemplates = credentialTemplates.Entity?.length > 0 ? credentialTemplates.Entity?.filter(c => c.CredentialTemplateId != 1) : [];
				const threatLevelsOptions: ThreatLevel[] = threatLevels?.map<ThreatLevel>(x => ({
					...x,
					Name: `${x.ThreatId.toLocaleString('en-US', { minimumIntegerDigits: 2 })} - ${x.Name}`,
				}));
				batch(() => {
					dispatcher(setRootModelPropertyAction({ credentialMessages }));
					dispatcher(
						setGeneralTabServicesDataAction({
							badgeTemplates: badgeTemplates || [],
							cardFormats: cardFormats || [],
							credentialTemplates: lCredentialTemplates,
						})
					);
					dispatcher(setCardPropertyAction({ cardType: cardType || cardFormats?.[0].CardFormatId }));
					dispatcher(setLimitsTabPropertyAction({ threatLevels: threatLevelsOptions || [] }));
				});
			})
			.catch(err => {
				Logger.writeErrorLog(err);
				batch(() => {
					dispatcher(
						setGeneralTabServicesDataAction({
							badgeTemplates: [],
							cardFormats: [],
							credentialTemplates: [],
						})
					);
					dispatcher(setLimitsTabPropertyAction({ threatLevels: [] }));
				});
			})
			.finally(() => {
				dispatcher(setRootModelPropertyAction({ loading: false }));
			});
	};

	const setLoadingAction = (isLoading: boolean, loadingType: LoadingType) => {
		loadingType === LoadingType.MainSaveChanges ? setSubmitted(isLoading) : setWarningSaveChangesSubmitted(isLoading);
	};

	const handleOnConfirmCredentialConflicts = (credential: CredentialDetail, credentialConflict: CredentialConflicts) => {
		switch (credentialConflict) {
			case CredentialConflicts.KeypadCode:
				credential.PinOverrideAnswer = true;
				break;

			case CredentialConflicts.OldCode:
				credential.CodeOverrideAnswer = true;
				break;

			case CredentialConflicts.DualCode:
				credential.DualOverrideAnswer = true;
				break;
		}

		dispatcher(setOverrideResponseAction(credential));
		saveCredential(credential, true, true);
	};

	const getTitle = (): string => {
		let title = '';
		if (IsDeviceAdminCredentialTemplate) {
			if (!canUpdateComponent || contextCredentialId === 1) title = `(${_('ReadOnly')}) `;
			if (contextCredentialId === 1) {
				title += _('CredentialProperties');
			} else {
				title += _('CredentialTemplateProperties');
			}
		} else {
			title = _('CredentialProperties');
		}
		return title;
	};

	const buildCredEnrollmentResponse = (response: CredentialEnrollmentResponse, credential: CredentialDetail) => {
		const validationErrors: ValidationErrorsProperty = { keypadCode: undefined, matchCode: undefined, name: undefined, other: undefined };
		let msg = '';
		let modalContent = undefined;

		if (response.ResponseStatusCode === ResponseStatusCode.Success) {
			handleModalOnSuccess();
		} else {
			if (response.CredentialResponseCode === CredentialResponseCode.OldPinCode) {
				if (response.AdditionalResponseInfo) {
					const messages: string[] = response.AdditionalResponseInfo.split('</br>');
					modalContent = <InformationMessages messages={messages} />;
				}

				ModalConfirmation({
					cancelText: _('No'),
					okText: _('Yes'),
					width: '600px',
					content: (
						<div>
							{modalContent || (
								<>
									<p>{_('KeypadCodeConflicts')}</p>
									<span>{_('OverrideOldCodeConflict')}</span>
								</>
							)}
						</div>
					),
					onConfirm: () => handleOnConfirmCredentialConflicts(credential, CredentialConflicts.KeypadCode),
				});
			} else if (
				response.CredentialResponseCode === CredentialResponseCode.OldMatchCode ||
				response.CredentialResponseCode === CredentialResponseCode.DualConflict
			) {
				const credentialConflict: CredentialConflicts =
					response.CredentialResponseCode === CredentialResponseCode.OldMatchCode ? CredentialConflicts.OldCode : CredentialConflicts.DualCode;
				if (response.AdditionalResponseInfo) {
					const messages: string[] = response.AdditionalResponseInfo.split('</br>');
					modalContent = <InformationMessages messages={messages} />;
				}

				ModalConfirmation({
					cancelText: _('No'),
					okText: _('Yes'),
					width: '600px',
					content: (
						<div>
							{modalContent || (
								<>
									<p>{_('CodeConflicts')}</p>
									<span>{_('OverrideOldCodeConflict')}</span>
								</>
							)}
						</div>
					),
					onConfirm: () => handleOnConfirmCredentialConflicts(credential, credentialConflict),
				});
			} else {
				msg = response.ErrorMessage;

				switch (response.CredentialResponseCode) {
					case CredentialResponseCode.DuplicatePin:
					case CredentialResponseCode.OldPinCodeUsed:
					case CredentialResponseCode.InvalidPin:
					case CredentialResponseCode.InvalidDefaultPin:
					case CredentialResponseCode.DuplicateDualCode:
						validationErrors.keypadCode = response.AdditionalResponseInfo;
						dispatcher(setErrorAction(validationErrors));
						break;

					case CredentialResponseCode.InvalidMATCHCode:
					case CredentialResponseCode.DuplicateMatch:
					case CredentialResponseCode.OldMatchCodeUsed:
					case CredentialResponseCode.DuplicateCardCode:
					case CredentialResponseCode.DualConflictUsed:
						validationErrors.matchCode = response.AdditionalResponseInfo;
						dispatcher(setErrorAction(validationErrors));
						break;

					case CredentialResponseCode.InputError:
						validationErrors.name = response.AdditionalResponseInfo;
						dispatcher(setErrorAction(validationErrors));
						break;

					case CredentialResponseCode.InvalidName:
						validationErrors.name = response.AdditionalResponseInfo.replace('%%1', credential.Description);
						dispatcher(setErrorAction(validationErrors));
						break;

					default:
						if (response.AdditionalResponseInfo) {
							validationErrors.other = response.AdditionalResponseInfo;
							dispatcher(setErrorAction(validationErrors));
							msg += `:\n${response.AdditionalResponseInfo}`;
						}
						break;
				}
			}
			if (msg) {
				notification['error']({ message: msg });
			}
		}
	};

	const saveCredential = (credential: CredentialDetail, fromConfirmation?: boolean, skipLinkExisting?: boolean): boolean => {
		if (!credential || credential.CredentialId === 1) {
			return true;
		}

		if (!fromConfirmation && linkToNewCredentials) {
			showConfirmationDialog(credential, previousLinkNewValue, credential.CredentialId);
		} else {
			if (fromConfirmation && previousLinkNewValue && !skipLinkExisting) {
				credential.LinkToExisting = true;
			}

			dispatcher(setRootModelPropertyAction({ loading: true }));
			credential.HostUserId = userId === CredentialType.FindCredential ? userIdRef.current : userId;
			credentialApi
				.saveUserCredential(credential)
				.then(res => {
					buildCredEnrollmentResponse(res, credential);
				})
				.finally(() => {
					setLoadingAction(false, LoadingType.WarningSaveChanges);
					dispatcher(setRootModelPropertyAction({ loading: false }));
					resetOverrideAnswer();
				});
		}
		return false;
	};

	// TODO: finish this, this is a DeviceControl usecase
	const showConfirmationDialog = (credential, linkNew: boolean, templateId: number) => {
		const question = linkNew ? 'CONFIRMOVERWRITE' : 'ALSOLINK';
		setAffectedCredentialsTable({
			affectedCredentials: undefined,
			confirmationText: undefined,
			credential: undefined,
		});
		credentialApi
			.getConfirmationDialog(question, templateId)
			.then(res => {
				switch (res.ResponseStatusCode) {
					case ResponseStatusCode.Success:
						if (res.AffectedCredentials?.length > 0) {
							setAffectedCredentialsTable({
								affectedCredentials: res.AffectedCredentials,
								confirmationText: res.AdditionalResponseInfo,
								credential,
							});
						} else {
							const credential: CredentialDetail = mapJsCredentialToModel(credentialInitialState, 0, false);
							saveCredential(credential, true, true);
						}
					default:
				}
			})
			.catch(err => {
				Logger.writeErrorLog(err);
				// TODO: research what to do with this error
			});
	};

	const handleSaveChanges = () => {
		setLoadingAction(true, LoadingType.WarningSaveChanges);
		resetOverrideAnswer();

		let error = false;

		const credential: CredentialDetail = mapJsCredentialToModel(credentialInitialState, 0, false);

		const closeAlertModalFunction = () => {
			dispatcher(
				setRootModelPropertyAction({
					alertModalObj: {
						children: undefined,
						hidden: true,
						onOk: undefined,
						title: undefined,
					},
				})
			);
		};

		if (expirationUsed) {
			const dtActivationDate: Date = new Date(activationDate);
			if (hostExpirationUDF === 0 && hostExpirationInterval <= 0) {
				const dtExpirationDateValidation: Date = new Date(expiration);
				if (dtActivationDate > dtExpirationDateValidation) {
					error = true;
					dispatcher(
						setRootModelPropertyAction({
							alertModalObj: {
								children: <span data-test-id='expirationDateMustAfterActivationWarning'>{_('CredentialFromInputDateError_2')}</span>,
								hidden: false,
								onOk: closeAlertModalFunction,
								onCancel: closeAlertModalFunction,
							},
						})
					);
				}
			}
		}

		const showPin: boolean = showClearPin && !alwaysHidePin;
		if (!showPin && credential.PinCode !== (pinCodeConfirmMasked?.toString() || '')) {
			error = true;
			dispatcher(
				setRootModelPropertyAction({
					alertModalObj: {
						children: _('PinCodeShouldMatch'),
						hidden: false,
						onOk: closeAlertModalFunction,
						onCancel: closeAlertModalFunction,
					},
				})
			);
		}

		if (!error) {
			if ((!credentialFunctions || credentialFunctions.length === 0) && !IsDeviceAdminCredentialTemplate) {
				ModalConfirmation({
					width: '600px',
					cancelText: _('No'),
					okText: _('Yes'),
					onCancel: () => setLoadingAction(false, LoadingType.WarningSaveChanges),
					onConfirm: () => {
						saveCredential(credential);
					},
					content: <span id='continueWithNoFunctionsDefinedMsg'>{_('ContinueWithNoFunctionsDefined')}</span>,
				});
			} else {
				saveCredential(credential);
			}
		} else {
			setLoadingAction(false, LoadingType.WarningSaveChanges);
		}
	};

	const handleNoSave = () => {
		setLoadingAction(false, LoadingType.WarningSaveChanges);
		setHasChangedModal(false);
		handleModalOnCancel();
		unlockCredential();
	};

	const handleCancelSave = () => {
		setLoadingAction(false, LoadingType.WarningSaveChanges);
		setHasChangedModal(false);
	};

	const handleCancel = (saveChanges: boolean) => {
		if (!saveChanges && hasChanged) {
			setHasChangedModal(true);
		} else {
			setHasChangedModal(false);
			handleModalOnCancel();
			unlockCredential();
		}
	};

	const unlockCredential = () => {
		if (!isNew) {
			deviceAdminApi.unlockComponentDeviceAdmin(credentialId, SecuredComponents.Enrollment_Credential);
		}
	};

	const closeCredentialTemplateConfirmation = (credential: CredentialDetail) => {
		if (!previousLinkNewValue) {
			saveCredential(credential, true, true);
		}
		setAffectedCredentialsTable({
			affectedCredentials: undefined,
			confirmationText: undefined,
			credential: undefined,
		});
	};

	const confirmSaveCredentialTemplate = (credential: CredentialDetail) => {
		saveCredential(credential, true);
		setAffectedCredentialsTable({
			affectedCredentials: undefined,
			confirmationText: undefined,
			credential: undefined,
		});
	};

	const resetOverrideAnswer = () => {
		dispatcher(setOverrideResponseAction({ CodeOverrideAnswer: false, DualOverrideAnswer: false, PinOverrideAnswer: false }));
	};

	const Footer: React.ReactNode[] = [
		<Button
			title={!canUpdateComponent ? _('DisabledButtonPermissionsWarning') : ''}
			disabled={!canUpdateComponent}
			htmlType='button'
			style={{ marginRight: 15 }}
			type='primary'
			key='saveChanges'
			onClick={handleSaveChanges}>
			{_('SaveChanges')}
		</Button>,
		<Button htmlType='button' onClick={() => handleCancel(false)} key='cancel'>
			{_('Cancel')}
		</Button>,
	];

	return (
		<>
			<Modal footer={[Footer]} onCancel={() => handleCancel(false)} title={getTitle()} visible={true} width='1000px'>
				<Spin wrapperClassName={styles.spin} tip={`${_('Loading')}...`} spinning={loading} size='large'>
					<div data-test-id='credentialModalInnerContainer'>
						{cardFormats && badgeTemplates && credentialTemplates && (
							<Tabs activeKey={activeTab} className={styles.antTabs} onChange={activeKey => setActiveTab(activeKey)} type='card'>
								{(canViewGeneralTab || isNew) && (
									<TabPane
										forceRender
										tab={
											<span
												className={cx({ [styles.tabError]: keypadCode || matchCode || name || other })}
												id={`credentialTemplateModalGeneralTabName`}>
												{_('General')}
											</span>
										}
										key='1'>
										<CredentialTemplateModalGeneralTab user={user} isNew={isNew} />
									</TabPane>
								)}
								{canViewFunctionsTab && (
									<TabPane forceRender tab={<span id={`credentialTemplateModalFunctionsTabName`}>{_('Functions')}</span>} key='2'>
										<CredentialTemplateModalFunctionsTab />
									</TabPane>
								)}
								{canViewLimitsTab && (
									<TabPane forceRender tab={<span id={`credentialTemplateModalLimitsTabName`}>{_('Limits')}</span>} key='3'>
										<CredentialTemplateModalLimitsTab />
									</TabPane>
								)}
								{canViewOptionsTab && (
									<TabPane forceRender tab={<span id={`credentialTemplateModalOptionsTabName`}>{_('Options')}</span>} key='4'>
										<CredentialTemplateModalOptionsTab user={user} />
									</TabPane>
								)}
							</Tabs>
						)}
					</div>
				</Spin>
			</Modal>
			{!hidden && children && (
				<Modal
					bodyStyle={{ height: 'auto' }}
					footer={[
						<Button htmlType='button' onClick={onOk} key='ok'>
							{_('Ok')}
						</Button>,
					]}
					onCancel={onCancel}
					title={title}
					visible={!hidden}>
					{children}
				</Modal>
			)}
			<ModalConfirmCustomFooter
				title={_('Warning')}
				width={'700px'}
				visible={showHasChangedModal}
				loadingOk={warningSaveChangesSubmitted}
				onOk={handleSaveChanges}
				onNo={handleNoSave}
				onCancel={handleCancelSave}
				children={<label className={styles.saveChangesWarning}>{_('CredentialSettingsHaveChangedSavesChanges')}</label>}
			/>
			<AffectedCredentialsTable
				affectedCredentials={affectedCredentialsTable.affectedCredentials}
				confirmationText={affectedCredentialsTable.confirmationText}
				credential={affectedCredentialsTable.credential}
				onNo={closeCredentialTemplateConfirmation}
				onYes={confirmSaveCredentialTemplate}
			/>
		</>
	);
};

import React from 'react';
import { LogOnForm } from '../../components/account/';
import { Logger } from '../../model/LoggingModel';
// Internal state for the component
interface State {}

// Normal properties for the component
interface OwnProps {}

// combine them together
type Props = OwnProps;

class AccountPage extends React.Component<Props, State> {
	constructor(prop) {
		super(prop);
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	render() {
		return <LogOnForm />;
	}
}

export { AccountPage };

import React from 'react';
import { OperatorModalContextModel, OperatorSecurityKeyContext } from '../../../../../../model/OperatorModel';
import { ActionContext, ContextActionTypes } from './actionTypes';

export const getOperatorInitialState = (): OperatorModalContextModel => {
	return {
		rootContext: {
			enableCustomGroupNames: false,
			entryError: {
				isGeneralTabError: false,
				isRoleTabError: false,
				isSamAccountError: false,
				isSamAccountEmpty: false,
				isFullNameEmpty: false,
				isDomainEmpty: false,
				isPasswordError: false,
				isConfirmPasswordError: false,
			},
			foundInAD: false,
			isGeneralTabModified: false,
			oUManagement: '',
		},
		windowsCredential: {
			password: '',
			confirmPassword: '',
		},
		operator: {
			Id: 0,
			Name: '',
			FullName: '',
			Description: '',
			ShiftStart: undefined,
			ShiftEnd: undefined,
			AckAlarmThreshold: 0,
			AutoLock: false,
			AutoLockTime: 0,
			AbsenteeLimit: 0,
			RestrictShift: false,
			DirectorySource: '',
			Password: '',
			Flags: {
				IsPasswordExpired: false,
				IsCannotChangePassword: false,
				IsPasswordNeverExpires: false,
				IsAccountDisabled: false,
				IsAccountLockedOut: false,
			},
			Bypass2FA: false,
		},
		operatorRoleModifications: {
			isModified: false,
			isReadyToSave: undefined,
			subscribedRoleIds: [] as React.Key[],
			unsubscribedRoleIds: [] as React.Key[],
		},
		isSecurityKeyTableInEditMode: false,
		securityKeyModifications: [] as OperatorSecurityKeyContext[],
		isTwoFactorTabModified: false,
	};
};

const operatorInitialState: OperatorModalContextModel = getOperatorInitialState();

const operatorContextReducer = (state: Readonly<OperatorModalContextModel>, action: ActionContext) => {
	const { type } = action;
	switch (type) {
		case ContextActionTypes.SET_ROOT_MODEL_PROPERTIES: {
			const {
				enableCustomGroupNames,
				entryError: {
					isGeneralTabError,
					isRoleTabError,
					isSamAccountError,
					isSamAccountEmpty,
					isFullNameEmpty,
					isDomainEmpty,
					isPasswordError,
					isConfirmPasswordError,
				},
				isGeneralTabModified,
			} = action.payload;
			return {
				...state,
				rootContext: {
					...state.rootContext,
					enableCustomGroupNames,
					entryError: {
						...state.rootContext.entryError,
						isGeneralTabError,
						isRoleTabError,
						isSamAccountError,
						isSamAccountEmpty,
						isFullNameEmpty,
						isDomainEmpty,
						isPasswordError,
						isConfirmPasswordError,
					},
					isGeneralTabModified,
				},
			};
		}
		case ContextActionTypes.SET_ROOT_MODEL_PROPERTY: {
			const { field, value } = action.payload;
			return {
				...state,
				rootContext: {
					...state.rootContext,
					[field]: value,
					entryError: {
						...state.rootContext.entryError,
					},
				},
			};
		}
		case ContextActionTypes.SET_ERROR_ENTRY_FLAGS: {
			const {
				isGeneralTabError,
				isRoleTabError,
				isSamAccountError,
				isSamAccountEmpty,
				isFullNameEmpty,
				isDomainEmpty,
				isPasswordError,
				isConfirmPasswordError,
			} = action.payload;
			return {
				...state,
				rootContext: {
					...state.rootContext,
					entryError: {
						...state.rootContext.entryError,
						isGeneralTabError,
						isRoleTabError,
						isSamAccountError,
						isSamAccountEmpty,
						isFullNameEmpty,
						isDomainEmpty,
						isPasswordError,
						isConfirmPasswordError,
					},
				},
			};
		}
		case ContextActionTypes.SET_ERROR_ENTRY_FLAG: {
			const { field, value } = action.payload;
			return {
				...state,
				rootContext: {
					...state.rootContext,
					entryError: {
						...state.rootContext.entryError,
						[field]: value,
					},
				},
			};
		}
		case ContextActionTypes.SET_WINDOWS_CREDENTIAL_PROPERTY: {
			const { field, value } = action.payload;
			return {
				...state,
				windowsCredential: {
					...state.windowsCredential,
					[field]: value,
				},
			};
		}
		case ContextActionTypes.SET_OPERATOR: {
			const {
				Id,
				Name,
				FullName,
				Description,
				ShiftStart,
				ShiftEnd,
				AckAlarmThreshold,
				AutoLock,
				AutoLockTime,
				AbsenteeLimit,
				RestrictShift,
				DirectorySource,
				Password,
				Flags: { IsPasswordExpired, IsCannotChangePassword, IsPasswordNeverExpires, IsAccountDisabled, IsAccountLockedOut },
				Bypass2FA,
				OperatorGuid,
			} = action.payload;
			return {
				...state,
				operator: {
					...state.operator,
					Id,
					Name,
					FullName,
					Description,
					ShiftStart,
					ShiftEnd,
					AckAlarmThreshold,
					AutoLock,
					AutoLockTime,
					AbsenteeLimit,
					RestrictShift,
					DirectorySource,
					Password,
					Flags: {
						...state.operator.Flags,
						IsPasswordExpired,
						IsCannotChangePassword,
						IsPasswordNeverExpires,
						IsAccountDisabled,
						IsAccountLockedOut,
					},
					Bypass2FA,
					OperatorGuid,
				},
			};
		}
		case ContextActionTypes.SET_OPERATOR_PROP: {
			const { field, value } = action.payload;
			return {
				...state,
				operator: {
					...state.operator,
					Flags: {
						...state.operator.Flags,
					},
					[field]: value,
				},
			};
		}
		case ContextActionTypes.SET_OPERATOR_PASSWORD_FLAGS: {
			const { IsPasswordExpired, IsCannotChangePassword, IsPasswordNeverExpires, IsAccountDisabled, IsAccountLockedOut } = action.payload;
			return {
				...state,
				operator: {
					...state.operator,
					Flags: {
						...state.operator.Flags,
						IsPasswordExpired,
						IsCannotChangePassword,
						IsPasswordNeverExpires,
						IsAccountDisabled,
						IsAccountLockedOut,
					},
				},
			};
		}
		case ContextActionTypes.SET_OPERATOR_ROLES_MODIFICATIONS: {
			const { isModified, isReadyToSave, subscribedRoleIds, unsubscribedRoleIds } = action.payload;
			return {
				...state,
				operatorRoleModifications: {
					...state.operatorRoleModifications,
					isModified,
					isReadyToSave,
					subscribedRoleIds,
					unsubscribedRoleIds,
				},
			};
		}
		case ContextActionTypes.SET_SECURITY_KEYS_MODIFICATIONS: {
			const { ModificationAction, SecurityKey } = action.payload;
			return {
				...state,
				securityKeyModifications: [...state.securityKeyModifications, { ModificationAction, SecurityKey }],
			};
		}
		case ContextActionTypes.UPDATE_SECURITY_KEYS_MODIFICATIONS: {
			const { ModificationAction, SecurityKey } = action.payload;
			return {
				...state,
				securityKeyModifications: state.securityKeyModifications.map(k => {
					if (k.SecurityKey.KeyId === SecurityKey.KeyId) {
						return { ModificationAction, SecurityKey };
					}
					return k;
				}),
			};
		}
		case ContextActionTypes.SET_SECURITY_KEYS_TABLE_EDIT_MODE: {
			const isEdit: boolean = action.payload;
			return {
				...state,
				isSecurityKeyTableInEditMode: isEdit,
			};
		}
		case ContextActionTypes.SET_TWO_FACTOR_TAB_MODIFICATIONS: {
			const isBypass: boolean = action.payload;
			return {
				...state,
				isTwoFactorTabModified: isBypass,
			};
		}
		default:
			return { ...state };
	}
};

type OperatorModalContext = {
	operatorInitialState: Readonly<OperatorModalContextModel>;
	dispatcher: React.Dispatch<ActionContext>;
};

const OperatorStoreContext = React.createContext<OperatorModalContext>({ operatorInitialState, dispatcher: null });

export { OperatorStoreContext, operatorContextReducer, operatorInitialState };

import { BaseColumns, DefaultGridElement, GridDataColumnItem, ItemsPagination, ResponseObject } from './CommonModel';

export type WhosInsideModel = {
	ZoneGroupTree: ZoneGroupTree;
	HideEmptyZones: boolean;
	LoadZoneAndGroup: boolean;
	LoadZoneOnly: boolean;
	WhosInsideActionDetail: WhosInsideActionDetail[];
} & ResponseObject;

export type ZoneGroupTree = {
	EveryoneGroup: Zone;
	GlobalizedGroup: ZoneGroup[];
	CustomGroup: ZoneGroup[];
	SharedZones: Zone[];
	ShowCountInBack: boolean;
};

export type ZoneGroup = {
	ZoneGroupId: number;
	ZoneGroupCategory: ZoneGroupType;
	ZoneGroupName: string;
	IsGlobalized: boolean;
	Zones: Zone[];
	ZoneKey: ZoneKey;
};

export type Zone = {
	ZoneGroupId: number;
	ZoneId: number;
	ZoneName: string;
	CredentialsOnZone: number;
	CredentialsOnZoneFormat: string;
	ZoneKey: ZoneKey;
};

export type ZoneCredential = {
	Name: string;
	CredentialId: number;
	Zone: string;
	CredentialFunction: string;
	LastZoneUpdate: Date;
	LastAccessedReader: string;
	LastTransactionType: string;
	DeniedCredential: boolean;
	ZoneKey: ZoneKey;
} & DefaultGridElement &
	BaseColumns;

export type RenameRequest = {
	GroupId: number;
	ZoneId: number;
	Name: string;
	ZoneKey: ZoneKey;
};

export type RenameResponse = {
	Name: string;
	NameWithCount: string;
};

export type ZoneGroupModalInfo = {
	Name: string;
	Type: ZoneGroupType;
	Id: number;
	AllLeftItemsPagination: number[];
	AllRightItemsPagination: number[];
	AvailableControllersId: number[];
	SelectedControllersId: number[];
	AvailableControllers: ControllerZone[];
	SelectedControllers: ControllerZone[];
};

export type ControllerZone = {
	ControllerId: number;
	ControllerName: string;
	ControllerAddress: string;
	ZoneGroupId: number;
	ZoneName: string;
	Disabled: boolean;
};

export type SharedZoneModalInfo = {
	SharedZoneId: number;
	AvailableZonesList: SharedZoneInfo[];
	SelectedZonesList: SharedZoneInfo[];
};

export type SharedZoneInfo = {
	ZoneGroupId: number;
	ZoneId: number;
	ZoneName: string;
	ZoneGroupName: string;
	OriginallySelected: number;
};

export type MoveToInfo = {
	selectedCredentials: MoveToCredentialInfo[];
	globalizedGroup: ZoneGroup[];
	customGroup: ZoneGroup[];
	zoneGroupId: number;
	showCountInBack: boolean;
};

export type MoveToCredentialInfo = {
	credentialId: number;
	credentialDescription: string;
};

export type MoveCredential = {
	ToZoneId: number;
	ToZoneGroupId: number;
	ToZoneName: string;
	CredentialDescription: string;
	CredentialId: number;
};

export type WhosInsideActionDetail = {
	WhosInsideAction: WhosInsideAction;
	ZoneGroupId: number;
	ZoneId: number;
	IsGlobalized: boolean;
	IsSharedZone: boolean;
	IsNewGlobalized: boolean;
	IsOldGlobalized: boolean;
	NewZonePassback: Zone;
	OldZonePassback: Zone;
	EveryoneGroup: Zone;
	NewZoneGroup: ZoneGroup;
	NewZone: Zone;
	CredentialChanges: CredentialChangeInfo;
};

export type WhosInsideReportData = {
	RawHTMLData: string;
	Name: string;
	Path: string;
};

export type WhosInsideCredentialDataType = {
	key?: React.Key;
} & ZoneCredential;

export type WhosInsideControllerDataType = {
	key?: React.Key;
} & ControllerZone &
	DefaultGridElement;

export type WhosInsideZoneDataType = {
	key?: React.Key;
} & SharedZoneInfo &
	DefaultGridElement;

export type WhosInsideCurrentAction = {
	type: ActionToExecute;
	zoneKey: ZoneKey;
	key: React.Key;
	zoneId: number;
	zoneGroupId: number;
	zoneType: ZoneType;
};

export type WhosInsideOptions = {
	label: string;
	separator?: boolean;
	onClick?: () => void;
	type?: ActionToExecute;
	zoneType?: ZoneType;
	title?: string;
	disabled?: boolean;
	id?: string;
};

export type ZoneCredentialsPagination = {
	ZoneCredentials: ZoneCredential[];
	RequestedZone: Zone;
} & ItemsPagination;

export type ZoneControllersPagination = {
	ZoneControllers: ControllerZone[];
} & ItemsPagination;

export type ZonesPagination = {
	Zones: SharedZoneInfo[];
} & ItemsPagination;

export type LoadControllerZonesHelper = {
	ZoneGroupId: number;
	ZoneGroupType: ZoneGroupType;
	IsLeft: boolean;
	SelectedControllersId: number[];
	UnselectedControllersId: number[];
};

export type LoadZonesHelper = {
	SharedZoneId: number;
	IsLeft: boolean;
	SelectedZones: SharedZoneInfo[];
	UnselectedZones: SharedZoneInfo[];
};

export type DoubleTableModal<T> = {
	left: T;
	right: T;
};

export type AllZoneCredentialsItemsPagination = {
	AllZoneCredentials: number[];
	RequestedZone: Zone;
};

export type CredentialChangeInfo = {
	FromZone: number;
	ToZone: number;
	ZoneGroupId: number;
	ZoneCredential: ZoneCredential;
};

export type WhosInsideResponse = {
	RequestedZone: Zone;
	Columns: GridDataColumnItem[];
	Data: ZoneCredential[];
	TotalItems: number;
};

export enum WhosInsideAction {
	UpdateTimer = 0,
	Refresh = 1,
	DeleteZoneGroup = 2,
	AddZoneGroup = 3,
	Passback = 4,
	RemoveEmptyZone = 5,
	UpdateZone = 6,
	AddOrUpdateZone = 7,
	UpdateEveryoneGroup = 8,
	CredentialChanged = 9,
}

export enum ZoneCredentialsColumns {
	None = 0,
	CredentialId = 1,
	Name = 2,
	Zone = 3,
	CredentialFunction = 4,
	LastZoneUpdate = 5,
	LastAccessedReader = 6,
	LastTransactionType = 7,
}

export enum ZoneControllersColumns {
	None = 0,
	ControllerName = 1,
	ZoneName = 2,
	ControllerAddress = 3,
}

export enum ZonesColumns {
	None = 0,
	ZoneName = 1,
	ZoneGroupName = 2,
}

export enum ActionToExecute {
	Rename,
	ForgiveAllUsersCredentialsInGroup,
	ForgiveAllUsersCredentialsInZone,
	MapToSharedZone,
	Delete,
	Edit,
	AddNewCustomGroup,
	EditShareZones,
}

export enum ZoneType {
	Custom,
	Globalized,
	Shared,
}

export enum ZoneKey {
	EveryOne = 1,
	Globalized = 2,
	Custom = 3,
	Shared = 4,
	WholeGroup = 5,
}

export enum ErrorInfo {
	AddCustomZoneName = 1,
	EditCustomZoneList,
}

export enum ZoneGroupType {
	LegacyDefault = 0,
	Globalized = 1,
	Default = 2,
	Custom = 3,
	SharedZone = 4,
	GIOG = 5,
}

import { Collapse } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';
import { ReportsTabs } from '../../reports/Reports/ReportTabs/ReportTabs';
import styles from './collapsePanel.module.scss';

const { Panel } = Collapse;

type ExpansibleIcon = {
	expandedIcon: React.ReactElement;
	collapsedIcon: React.ReactElement;
};

export type CollapseChildContent<T> = {
	value: T;
	label: string;
	onClick?: () => void;
	key: React.Key;
	render: (props: T) => React.ReactNode;
};

export type CollapseData<T> = {
	label: string;
	key: React.Key;
	content: CollapseChildContent<T>[];
};

export type CollapseProps<T> = {
	collapseData: CollapseData<T>[];
	expansibleIcon?: ExpansibleIcon;
};

const CollapsePanel = <T extends object | string | number>({ collapseData, expansibleIcon }: CollapseProps<T>) => {
	const [activeKey, setActiveKeys] = useState<React.Key>();

	const handleClickOption = (key: React.Key) => {
		setActiveKeys(key);
	};

	const splitKey = activeKey?.toString().split('-') ?? [];
	const parentKey = splitKey[0] ?? '';
	const childKey = splitKey[1] ?? '';
	const currentData = collapseData.find(x => x.key.toString() === parentKey);
	const currentContent = currentData?.content.find(x => x.key.toString() === childKey) ?? undefined;

	return (
		<div className={styles.container}>
			<div>
				<Collapse
					accordion
					expandIcon={
						expansibleIcon ? panelProps => (panelProps.isActive ? expansibleIcon?.expandedIcon : expansibleIcon?.collapsedIcon) : undefined
					}>
					{collapseData.map(({ content, label, key }, index) => (
						<Panel header={label} key={index}>
							<div>
								{content.map(w => (
									<div
										title={w.label}
										className={cx(styles.link, { [styles.active]: w.key.toString() === childKey })}
										key={w.key}
										onClick={() => {
											handleClickOption(`${key}-${w.key}`);
											w.onClick?.();
										}}>
										<label>{w.label}</label>
									</div>
								))}
							</div>
						</Panel>
					))}
				</Collapse>
			</div>
			<div>{currentContent?.render?.(currentContent.value) ?? <ReportsTabs report={undefined} />}</div>
		</div>
	);
};

export { CollapsePanel };

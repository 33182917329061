import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { ReaderInterface } from '../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../common';
import { DoorStoreSingleContext } from '../../../DoorModal/DoorStoreSingleContext';
import { setCardMap, setEnableReaderBezelTamper, StoreContext } from '../../contextReader';
import { MatchCardReaderInterface, RS485CardReaderInterface } from '../sections';
import styles from './cardreadersetup.module.scss';

type Props = {
	useDoorContext: boolean;
};

const CardReaderSetup: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					cardMaps,
					selections: { cardMap },
					enableReaderBezelTamper,
					readerInterface,
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				cardMaps,
				selections: { cardMap },
				enableReaderBezelTamper,
				readerInterface,
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [cardMapsList, setCardMapsList] = useState([]);

	useEffect(() => {
		setCardMapsList(cardMaps.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [cardMaps]);

	const handleOnSetCardMap = (value: string) => {
		useDoorContext ? dispatcherDoor(setCardMap(Number(value))) : dispatcher(setCardMap(Number(value)));
	};

	const handleOnSetEnableReaderBezelTamper = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setEnableReaderBezelTamper(value.target.checked)) : dispatcher(setEnableReaderBezelTamper(value.target.checked));
	};

	return (
		<div id='cardReaderContainer' className={styles.form}>
			<div className={styles.sections}>
				<span>{_('CustomCardCodes')}</span>
				<div>
					<Select
						id='readerCardReaderCustomCardCodesDropdown'
						className={styles.select}
						options={cardMapsList}
						onChange={handleOnSetCardMap}
						key='cardCode'
						value={cardMap.toString()}
						getPopupContainer={() => document.getElementById('cardReaderContainer')}
					/>
				</div>
			</div>
			<div className={styles.sections}>
				<Checkbox checked={enableReaderBezelTamper} onChange={handleOnSetEnableReaderBezelTamper} id={'readerBezel'}>
					<span>{_('EnableReaderBezel')}</span>
				</Checkbox>
			</div>
			{readerInterface === ReaderInterface.MatchDS47 && <MatchCardReaderInterface useDoorContext={useDoorContext} />}
			{readerInterface === ReaderInterface.OnboardWiegand && <RS485CardReaderInterface isOnboardWiegand={true} useDoorContext={useDoorContext} />}
			{readerInterface === ReaderInterface.RS485 && <RS485CardReaderInterface isOnboardWiegand={false} useDoorContext={useDoorContext} />}
		</div>
	);
};

export { CardReaderSetup };

import React, { useMemo, useReducer } from 'react';
import { OperatorModel } from '../../../../../model/DeviceAdminModel';
import { OperatorPropertyModel } from '../../../../../model/OperatorModel';
import { WithLockedValidation } from '../../../../common';
import { ModalComponent } from './ModalComponent';
import { OperatorStoreContext, operatorContextReducer, operatorInitialState } from './OperatorContext/context';

type Props = {
	isEditMode: boolean;
	operator: OperatorModel;
	onConfirm: () => void;
	onCancel: () => void;
	authPath: string;
	domains: string[];
	operatorPropertyModel?: OperatorPropertyModel;
};

const OperatorsModal: React.FC<Props> = WithLockedValidation(({ operator, onConfirm, onCancel, authPath, domains, operatorPropertyModel }) => {
	const [modalContextState, dispatchContextAction] = useReducer(operatorContextReducer, operatorInitialState);
	const { Provider } = OperatorStoreContext;

	const operatorModalContextValue = useMemo(
		() => ({
			operatorInitialState: {
				...modalContextState,
			},
			dispatcher: dispatchContextAction,
		}),
		[modalContextState, dispatchContextAction]
	);

	return (
		<Provider value={operatorModalContextValue}>
			<ModalComponent
				operator={operator}
				onConfirm={onConfirm}
				onCancel={onCancel}
				authPath={authPath}
				domains={domains}
				operatorPropertyModel={operatorPropertyModel}
			/>
		</Provider>
	);
});

export { OperatorsModal };

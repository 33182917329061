import React from 'react';
import { getTimeZoneInfoByList } from '../../../../../../../Helper';
import { SelectTimeZone, TimeZoneInfo } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerMiscSystemSetupAction, setOpenTimeZones } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerMisc } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectAlertId, selectGenericTimeZones, selectTagId } from '../../../../../../../store/deviceControl/controller/selectors';
import { Select } from '../../../../../../common';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../setup.module.scss';

const Tag: React.FC = () => {
	const dispatch = useStoreDispatch();
	const timeZones: SelectTimeZone[] = useStoreSelector<SelectTimeZone[]>(selectGenericTimeZones);
	const tagId: number = useStoreSelector<number>(selectTagId);
	const alertId: number = useStoreSelector<number>(selectAlertId);

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	const tagTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(tagId, timeZones);
	const alertTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(alertId, timeZones);

	return (
		<CardSection title={_('TagAndAlertSettings')}>
			<div className={styles.tagContainer}>
				<div>
					<span>{_('Tag')}:</span>
					<Select
						id='controllerSetupTagTimeZoneDropdown'
						onChange={value => {
							if (Number(value) === 0) {
								dispatch(
									setOpenTimeZones({
										index: -1,
										open: true,
										propertyType: 'Tag',
									})
								);
							} else {
								handleChangeControllerMiscSystemSetup({ TagTimeZoneGenericId: Number(value) });
							}
						}}
						notUseCurrentParse
						value={tagId}
						options={tagTimeZoneInfo.TimeZoneList}
						disabled={tagTimeZoneInfo.IsUnpermitted}
						getPopupContainer={element => element}
						showSearch
					/>
				</div>
				<div>
					<span>{_('Alert')}:</span>
					<Select
						id='controllerSetupAlertTimeZoneDropdown'
						onChange={value => {
							if (Number(value) === 0) {
								dispatch(
									setOpenTimeZones({
										index: -1,
										open: true,
										propertyType: 'Alert',
									})
								);
							} else {
								handleChangeControllerMiscSystemSetup({ AlertTimeZoneGenericId: Number(value) });
							}
						}}
						notUseCurrentParse
						value={alertId}
						options={alertTimeZoneInfo.TimeZoneList}
						disabled={alertTimeZoneInfo.IsUnpermitted}
						getPopupContainer={element => element}
						showSearch
					/>
				</div>
			</div>
		</CardSection>
	);
};

export { Tag };

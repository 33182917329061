import { SnippetsOutlined } from '@ant-design/icons';
import { Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { buildColumn, ColumnsProps, ScrollType } from '../../../Helper';
import { AlarmModel, AlarmNote } from '../../../model/AlarmModel';
import { BaseColumns } from '../../../model/CommonModel';
import { useStoreSelector } from '../../../store';
import { selectAlarmModel } from '../../../store/alarm/selectors';
import { AlarmTableInModal } from '../../alarms';
import { Modal } from '../../common';
import { notesStyle } from '../helpers';
import styles from './alarmShowNotesModal.module.scss';

const { Paragraph } = Typography;
type Props = {
	onHideModal: () => void;
};

const scroll: ScrollType = { x: 200, y: '47vh' };
type AlarmNotesDataSourceType = AlarmNote & BaseColumns;

const AlarmShowNotesModal: React.FC<Props> = ({ onHideModal }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [dataSource, setDataSource] = useState<AlarmNotesDataSourceType[]>([]);

	const alarmModel: AlarmModel = useStoreSelector<AlarmModel>(selectAlarmModel);

	useEffect(() => {
		if (alarmModel && alarmModel.AlarmDetails.length === 1) {
			const alarmNotes: AlarmNotesDataSourceType[] = alarmModel.AlarmDetails[0].AlarmNotes.map<AlarmNotesDataSourceType>((note: AlarmNote) => ({
				...note,
				key: note.AlarmNoteId,
			}));
			setDataSource(alarmNotes.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()));
			setIsLoading(false);
		} else {
			onHideModal();
		}
	}, []);

	const columns: ColumnsProps<AlarmNotesDataSourceType>[] = [
		{
			...buildColumn(_('Date'), 'Date', '13em', 'start'),
			render: value => {
				let text = value;
				try {
					text = FormatDate(text, true, null, true);
				} catch {
					text = value;
				}

				return <span>{text}</span>;
			},
		},
		{
			...buildColumn(_('Operator'), 'OperatorName', 'auto', 'start'),
		},
	];

	return (
		<Modal
			onCancel={onHideModal}
			onClickOk={() => null}
			width={750}
			title={
				<span>
					<SnippetsOutlined style={notesStyle} /> {_('OperatorNotes')}
				</span>
			}
			visible={true}
			footer={null}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div className={styles.content}>
					<div>
						<AlarmTableInModal />
					</div>
					<div>
						<Table
							size='small'
							columns={columns}
							dataSource={dataSource}
							className={styles.alarmInfo}
							pagination={false}
							expandable={{
								expandedRowRender: (record: AlarmNotesDataSourceType) => (
									<div id={`alarmInfoNote-${record.AlarmNoteId}`} className={styles.instructions}>
										<Paragraph>{record.Text}</Paragraph>
									</div>
								),
								expandedRowClassName: () => styles.expandedRow,
							}}
							scroll={scroll}
						/>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export { AlarmShowNotesModal };

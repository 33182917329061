import React from 'react';
import { DeviceObjectType } from '../../../../../../model/DeviceAdminModel';
import styles from './general.module.scss';
import { CheckBoxes, LineModule, ModuleEnabled, NameInput, RQEEnabled, State, Timers } from './sections/Index';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
	errorText: string;
	currentDeviceObjType: DeviceObjectType;
	useDoorContext: boolean;
	setErrorText: () => void;
};
const General: React.FC<Props> = ({ nameValidation, nameError, onResetNameError, useDoorContext, errorText, currentDeviceObjType, setErrorText }) => {
	return (
		<div id='generalInputContainer' className={styles.container}>
			<NameInput
				nameValidation={nameValidation}
				nameError={nameError}
				onResetNameError={onResetNameError}
				useDoorContext={useDoorContext}
				errorText={errorText}
				setErrorText={setErrorText}></NameInput>
			<div className={styles.grid}>
				<div>
					<ModuleEnabled useDoorContext={useDoorContext} />
				</div>
				<LineModule useDoorContext={useDoorContext} />
				<State useDoorContext={useDoorContext} />
				<RQEEnabled useDoorContext={useDoorContext} />
			</div>
			<div className={styles.gridRow}>
				<Timers useDoorContext={useDoorContext} />
				<CheckBoxes useDoorContext={useDoorContext} currentDeviceObjType={currentDeviceObjType} />
			</div>
		</div>
	);
};

export { General };

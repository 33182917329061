import { CaretDownOutlined, FileSearchOutlined, PlusOutlined, PrinterOutlined, SettingFilled } from '@ant-design/icons';
import { Button, notification, Spin, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { credentialApi, enrollmentApi } from '../../../api';
import {
	buildColumn,
	getDefaultPaginationSettings,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	printBadge,
	ScrollType,
} from '../../../Helper';
import { ComponentPermission, getPermissionErrorMessage, SecuredComponents, SubPermissions, User } from '../../../model/AccountModel';
import {
	BaseColumns,
	CriteriaSearchRequest,
	DefaultColumns,
	PaginationSetting,
	PreviewBadgeResponse,
	ResponseStatusCode,
	SelectOptions,
	SortDirections,
	SorterConfigEx,
} from '../../../model/CommonModel';
import { CurrentDeviceControlObj, DeviceObjectType, OptionsButtonBuilder } from '../../../model/DeviceAdminModel';
import {
	AffectedCredential,
	AssignCredentialDetail,
	CredentialAction,
	CredentialActionOptions,
	CredentialActionResponse,
	CredentialActions,
	CredentialGridItem,
	CredentialOptionsHelper,
	CredentialResponse,
	CredentialType,
	ImageWithCredentialId,
	PrintMultipleBadgeInfo,
} from '../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { setCurrentDevice } from '../../../store/common/actions';
import { selectCurrentDevice } from '../../../store/common/selectors';
import { selectAddCredentialOptions } from '../../../store/enrollment/selectors';
import {
	ButtonDropdown,
	ColumnConfiguration,
	EditableCell,
	HeaderBar,
	ModalConfirmation,
	ModalConfirmationDualList,
	PreviewBadgeCredential,
} from '../../common';
import { AssignCredential } from '../../enrollment';
import CredentialTemplateModal from '../CredentialTemplateModal';
import { CredentialTemplateModalConfigData } from '../CredentialTemplateModal/CredentialTemplateModalModels';
import { getCredentialColumnRender } from '../CredentialTemplateModal/Helper';
import { badgeOptions, createCredentialButtonOptions, getTableTitleHeader, handleOnFailedCredentialUpdates } from '../helper';
import { AssignCredentialWarning } from './AssignCredential/AssignCredentialWarning';
import styles from './credentialTable.module.scss';

const user: User = getUser();
const credentialPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Enrollment_Credential);
const canPreviewCredential: boolean = User.getSubComponentPermission(user, SubPermissions.Badges_Badges_Preview).allowed;
const canPrintCredential: boolean = User.getSubComponentPermission(user, SubPermissions.Badges_Badges_Print).allowed;
const defaultPaginationSettings: PaginationSetting = getDefaultPaginationSettings();
type Props = {
	userId: number;
	criteriaSearchRequest: CriteriaSearchRequest;
	isLoading: boolean;
	setIsLoading: (value: boolean) => void;
	updateCounter: number;
	setUpdateCounter: () => void;
	handleOnViewPersonInformation?: (personId: number) => void;
	scrollHeight?: string;
};

const CredentialTable: React.FC<Props> = ({
	userId,
	criteriaSearchRequest,
	isLoading,
	setIsLoading,
	updateCounter,
	setUpdateCounter,
	handleOnViewPersonInformation,
	scrollHeight = '60vh',
}) => {
	const dispatch = useStoreDispatch();
	const [displayColumnConfiguration, setDisplayColumnConfiguration] = useState<boolean>(false);
	const [displayPreview, setDisplayPreview] = useState<boolean>(false);
	const [eligibleCredentialsForAssign, setEligibleCredentialsForAssign] = useState<AffectedCredential[]>(undefined);
	const [assignCredentialDetails, setAssignCredentialDetails] = useState<AssignCredentialDetail>(undefined);
	const [tableColumns, setTableColumns] = useState([]);
	const [dataSource, setDataSource] = useState<CredentialGridItem[]>([]);
	const [totalItemsPagination, setTotalItemsPagination] = useState<number>();
	const [sorterConfigEx, setSorterConfigEx] = useState<SorterConfigEx>({ columnKey: undefined, order: undefined });
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedCredentialHelper, setSelectedCredentialHelper] = useState<CredentialOptionsHelper>();
	const [paginationSettings, setPaginationSettings] = useState<PaginationSetting>(defaultPaginationSettings);
	const [credentialTableInformation, setCredentialTableInformation] = useState<CredentialResponse>(undefined);
	const [badgesToPrint, setBadgesToPrint] = useState<PrintMultipleBadgeInfo>();
	const [credentialId, setCredentialId] = useState<number>(undefined);
	const [configData, setConfigData] = useState<CredentialTemplateModalConfigData>(undefined);
	const [isNewCredential, setIsNewCredential] = useState<boolean>(false);
	const printMultipleBadgeRef = useRef<HTMLButtonElement>();
	const totalCredentialsToPrint = useRef<number>(0);
	const addCredentialOptions: OptionsButtonBuilder<string> = useStoreSelector<OptionsButtonBuilder<string>>(selectAddCredentialOptions);
	const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);

	useEffect(() => {
		if (userId != CredentialType.None && userId != CredentialType.FindCredential && updateCounter !== 0) {
			setCredentialsByPage(paginationSettings);
		} else if (userId == CredentialType.FindCredential && updateCounter !== 0) {
			const newPaginationSettings: PaginationSetting = {
				...paginationSettings,
				PageNumber: 1,
			};

			clearTable(true);
			setCredentialsByPage(newPaginationSettings);
		}
	}, [updateCounter]);

	useEffect(() => {
		if (userId != CredentialType.None) {
			const newPaginationSettings: PaginationSetting = {
				...paginationSettings,
				PageNumber: 1,
			};

			clearTable();
			if (!isInModal) {
				setCredentialsByPage(newPaginationSettings);
			}
		}
	}, [userId]);

	useEffect(() => {
		if (dataSource.length > 0) {
			const previousSelectedRowKeys: React.Key[] = getPreviousSelectedRowKeys(dataSource);
			const uniqueSelectedKeys: React.Key[] = getUniqueValuesArray(previousSelectedRowKeys, selectedRowKeys);

			setSelectedRowKeysWrapper(uniqueSelectedKeys, dataSource);
		}
	}, [dataSource]);

	useEffect(() => {
		if (credentialTableInformation) {
			if (credentialTableInformation.TotalItems !== 0) {
				const previousState = dataSource.filter(c => c.checked);
				const updatedDataState = credentialTableInformation.Data;
				updatedDataState.forEach(item => {
					item.key = item.CredentialId;
					item.checked = previousState.some(x => x.CredentialId === item.CredentialId);
				});

				setDataSource(updatedDataState);
			} else {
				clearTable();
			}

			const columns = credentialTableInformation.Columns.map(c => {
				return {
					...buildColumn(_(c.Value), c.Key, 'auto', 'start'),
					dataIndex: c.Key,
					key: c.Key,
					sorter: !c.DisableSort,
					sortOrder: sorterConfigEx.columnKey === c.Key && sorterConfigEx.order,
					render: (value, row: CredentialGridItem) => getCredentialColumnRender(value, row, c),
				};
			});

			setTotalItemsPagination(credentialTableInformation.TotalItems);
			setTableColumns(columns);
		} else {
			clearTable();
		}
	}, [credentialTableInformation]);

	useEffect(() => {
		if (badgesToPrint && printMultipleBadgeRef) {
			if (badgesToPrint.Success) {
				printMultipleBadgeRef?.current?.click();
			} else {
				const currentPrintBadgeCount: number = totalCredentialsToPrint.current - badgesToPrint.RestImagesWithCredentialId.length;
				ModalConfirmation({
					title: `${_('BadgePrint')} (${currentPrintBadgeCount} / ${totalCredentialsToPrint.current})`,
					onConfirm: handleOnPrintMultipleBadges,
					onCancel: () => setBadgesToPrint(undefined),
					okText: _('Continue'),
					cancelText: _('CancelPrintingQueue'),
					content: (
						<div>
							<label>{_('PrintDialogBlockByBrowser')}</label>
							<br />
							<label>{_('DoYouWantToContinuePrinting')}</label>
							<br />
						</div>
					),
				});
			}
		} else {
			totalCredentialsToPrint.current = 0;
		}
	}, [badgesToPrint]);

	const getCredentialTemplateModalInitConfig = () => {
		if (!configData) {
			credentialApi.getCredentialTemplateModalConfig(false).then(data => {
				setConfigData(data);
			});
		}
	};

	const clearTable = (isFindCredential?: boolean): void => {
		setDataSource([]);
		setTotalItemsPagination(0);
		setSelectedRowKeysWrapper([], []);
		if (!isFindCredential) {
			setSorterConfigEx({ columnKey: undefined, order: undefined });
			setPaginationSettings({ ...paginationSettings, PageNumber: 1, SortDirection: SortDirections.None, SortField: '' });
		}
	};

	const setSelectedRowKeysWrapper = (rowKeys: React.Key[], dataSource: CredentialGridItem[]): void => {
		setSelectedRowKeys(rowKeys);
		if (rowKeys.length === 1) {
			const credentialIndex: number = dataSource.findIndex(x => x.CredentialId === rowKeys[0]);
			if (credentialIndex !== -1) {
				const credentialGridItem: CredentialGridItem = dataSource[credentialIndex];
				setSelectedCredentialHelper({
					Id: credentialGridItem.CredentialId,
					IDF: credentialGridItem.IDFNumber,
					Tag: credentialGridItem.fTagUser,
					Alert: credentialGridItem.fAlertUser,
					Disabled: credentialGridItem.fDeactivated,
					HostUserId: credentialGridItem.HostUserId,
				});
			}
		}
	};

	const setCredentialsByPage = (pagination: PaginationSetting): void => {
		setIsLoading(true);
		credentialApi
			.getCredentialsByPage(criteriaSearchRequest, pagination, isInModal)
			.then(response => {
				if (!handleResponse(response)) {
					if (pagination.PageNumber > 1 && response.Entity.TotalItems === 0) {
						setPaginationSettings({
							...pagination,
							PageNumber: pagination.PageNumber - 1,
							SortDirection: SortDirections.None,
							SortField: '',
						});
						setUpdateCounter();
					} else {
						setPaginationSettings(pagination);
						setCredentialTableInformation(response.Entity);
					}
				} else {
					setPaginationSettings(pagination);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const currentColumns = [...tableColumns];
	const mergedColumns = currentColumns.map(col => ({
		...col,
		onCell: (record: DefaultColumns) => ({
			record,
			dataIndex: col.dataIndex,
			title: col.title,
			options: contextMenuOptions,
			isTableInEditMode: false,
			onChangeSelection,
			onClickOption: handleActionScope,
		}),
	}));

	const onChangeSelection = (key: BaseColumns): void => {
		const credentialId = key.key;
		let cloneStateProps: CredentialGridItem[] = [];
		if (selectedRowKeys.findIndex(x => x === credentialId) === -1) {
			setSelectedRowKeysWrapper([credentialId], dataSource);
			dataSource.forEach(item => {
				item.checked = item.CredentialId === credentialId;
				cloneStateProps.push({ ...item });
			});

			setDataSource(cloneStateProps);
		}
	};

	const handleOnChangeTable = (pagination, filters, sorter): void => {
		const { order, field } = sorter;
		const { current, pageSize } = pagination;
		let sortOrder: SortDirections = SortDirections.None;
		setSorterConfigEx(sorter);

		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		const paginationSetting: PaginationSetting = {
			PageNumber: current,
			PageSize: pageSize,
			SortField: field,
			SortDirection: sortOrder,
			SearchedValue: paginationSettings.SearchedValue,
		};

		setCredentialsByPage(paginationSetting);
	};

	const getPreviousSelectedRowKeys = (dataSource: CredentialGridItem[]): React.Key[] => {
		const previousSelectedRowKeys: React.Key[] = dataSource.reduce((result: React.Key[], item) => {
			if (item.checked) {
				result.push(item.CredentialId);
			}
			return result;
		}, []);

		return previousSelectedRowKeys;
	};

	const handleChange = (newSelectedRowKeys: React.Key[]): void => {
		setSelectedRowKeysWrapper(newSelectedRowKeys, dataSource);
		let cloneStateProps: CredentialGridItem[] = [];
		dataSource.forEach(item => {
			item.checked = newSelectedRowKeys.findIndex(key => key.valueOf() === item.CredentialId) !== -1;
			cloneStateProps.push({ ...item });
		});

		setDataSource(cloneStateProps);
	};

	const handleSelectAll = (): void => {
		setIsLoading(true);
		credentialApi
			.getAllCredentialsIds(criteriaSearchRequest)
			.then(res => {
				if (!handleResponse(res)) {
					const newSelectedRowKeys: React.Key[] = getUniqueValuesArray(selectedRowKeys, res.Entity);
					setSelectedRowKeysWrapper(newSelectedRowKeys, dataSource);
					let cloneStateProps: CredentialGridItem[] = [];
					dataSource.forEach(item => {
						item.checked = newSelectedRowKeys.findIndex(key => key.valueOf() === item.CredentialId) !== -1;
						cloneStateProps.push({ ...item });
					});

					setDataSource(cloneStateProps);
				}
			})
			.finally(() => setIsLoading(false));
	};

	const handleSelectInvert = (): void => {
		const dataKeys = dataSource.reduce<React.Key[]>((result, item) => {
			result.push(item.CredentialId);
			return result;
		}, []);

		const newSelectedRowKeys: React.Key[] = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		setSelectedRowKeysWrapper(newSelectedRowKeys, dataSource);
		let cloneStateProps: CredentialGridItem[] = [];
		dataSource.forEach(item => {
			item.checked = newSelectedRowKeys.findIndex(key => key.valueOf() === item.CredentialId) !== -1;
			cloneStateProps.push({ ...item });
		});

		setDataSource(cloneStateProps);
	};

	const rowSelection: TableRowSelection<CredentialGridItem> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(false, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => {
			return {
				id: `credential-${userId}-${record['key']}`,
			};
		},
		selectedRowKeys: selectedRowKeys,
		onChange: handleChange,
		renderCell: (value: boolean, record, index: number, originNode: React.ReactNode) => (
			<div
				className={styles.rowSelectionContainer}
				onClick={e => {
					e.stopPropagation();
				}}>
				{originNode}
			</div>
		),
	};

	const handleChangePagination = (page: number, pageSize: number): void => {
		if (pageSize !== paginationSettings.PageSize) {
			setPaginationSettings({ ...paginationSettings, PageNumber: page, PageSize: pageSize });
		} else {
			setPaginationSettings({ ...paginationSettings, PageNumber: page });
		}
	};

	const paginationTable: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangePagination,
		paginationSettings.PageNumber,
		paginationSettings.PageSize,
		totalItemsPagination,
		undefined,
		selectedRowKeys
	);

	const handleOnClickCredential = record => {
		if (credentialPermissions.canView) {
			batch(() => {
				setCredentialId(record.CredentialId);
				handleOnCredentialModalOpen(record.CredentialId, false);
				setIsNewCredential(false);
			});
			getCredentialTemplateModalInitConfig();
		} else {
			notification['error']({
				message: _('ResponseStatusCode_2'),
			});
		}
	};

	const handleOnAddCredential = async (key: string): Promise<void> => {
		const splitKey: string[] = key.split(',');
		const newCredentialId: number = parseInt(splitKey[1], 10);
		batch(() => {
			setCredentialId(newCredentialId);
			setIsNewCredential(true);
			handleOnCredentialModalOpen(newCredentialId, true);
		});
		getCredentialTemplateModalInitConfig();
	};

	const handleOnOpenColumnConfiguration = e => {
		setDisplayColumnConfiguration(true);
	};

	const handleActionScope = async (key: CredentialActionOptions): Promise<void> => {
		switch (key) {
			case CredentialActionOptions.ViewPersonInformation:
				handleOnViewPersonInformation(selectedCredentialHelper.HostUserId);
				break;

			case CredentialActionOptions.Assign:
				credentialApi.getAssignCredentialsInfo(credentialsIds).then(res => {
					if (!handleResponse(res)) {
						const { Eligible, Ineligible }: AssignCredentialDetail = res.Entity;
						if (Ineligible.length === 0) {
							setEligibleCredentialsForAssign(Eligible);
						} else if (Eligible.length === 0) {
							notification['error']({
								message: _('SelectedCredentialsAreAssigned'),
							});
						} else {
							setAssignCredentialDetails(res.Entity);
						}
					}
				});
				break;

			case CredentialActionOptions.Edit:
				const newCredentialId: number = credentialsIds[0];
				batch(() => {
					setCredentialId(newCredentialId);
					handleOnCredentialModalOpen(newCredentialId, false);
					setIsNewCredential(false);
				});
				getCredentialTemplateModalInitConfig();
				break;
			case CredentialActionOptions.Delete:
				credentialApi.getCredentialDescriptionByIds(credentialsIds).then(res => {
					if (!handleResponse(res)) {
						ModalConfirmationDualList({
							type: _('Credential'),
							data: res.Entity,
							onConfirm: () => {
								setIsLoading(true);
								performCredentialAction(CredentialAction.Delete, credentialsIds).catch(() => setIsLoading(false));
							},
							firstColumnTitle: _('CID'),
							secondColumnTitle: _('Description'),
							firstDataIndex: 'Id',
							secondDataIndex: 'Description',
							firstColumnWidth: '100px',
						});
					}
				});
				break;

			case CredentialActionOptions.Preview:
				setDisplayPreview(true);
				break;

			case CredentialActionOptions.Print:
				handleOnPrintBadge();
				break;

			default:
				setIsLoading(true);
				performCredentialAction(key, credentialsIds).catch(() => setIsLoading(false));
				break;
		}
	};

	const performCredentialAction = async (action: CredentialActions, credentialsIds: number[], additionalId?: number): Promise<void> => {
		const clonedSelectedRowKeys: React.Key[] = [...selectedRowKeys];
		for (const credentialId of credentialsIds) {
			const response: CredentialActionResponse = await credentialApi.performCredentialAction(action, credentialId, additionalId);
			if (
				!handleResponse(response) &&
				(action === CredentialAction.Delete || action === CredentialAction.Assign || action === CredentialAction.Unassign)
			) {
				const credentialIndex: number = clonedSelectedRowKeys.findIndex(x => Number(x) === credentialId);
				if (credentialIndex !== -1) {
					clonedSelectedRowKeys.splice(credentialIndex, 1);
				}
			}

			if (response?.FailedCredentialUpdates) {
				handleOnFailedCredentialUpdates(response?.FailedCredentialUpdates);
			}
		}

		setSelectedRowKeysWrapper(clonedSelectedRowKeys, dataSource);
		setUpdateCounter();
	};

	const handleOnPrintBadge = (): void => {
		const clonedCredentialsIds: number[] = [...credentialsIds];
		const elementsToPrint: PreviewBadgeResponse[] = [];
		const credentialsIdsToPrint: number[] = [];
		setIsLoading(true);
		enrollmentApi
			.getPreviewBadges(clonedCredentialsIds, true)
			.then(res => {
				if (!handleResponse(res) && res.Entity.length > 0 && clonedCredentialsIds.length > 0) {
					for (let x = 0; x < res.Entity.length; x++) {
						const currentBadgeResponse: PreviewBadgeResponse = res.Entity[x];
						switch (currentBadgeResponse.ResponseStatusCode) {
							case ResponseStatusCode.Success:
								elementsToPrint.push(currentBadgeResponse);
								credentialsIdsToPrint.push(currentBadgeResponse.CredentialId);
								break;

							case ResponseStatusCode.SystemError:
								notification['error']({
									message: currentBadgeResponse.ErrorMessage
										? currentBadgeResponse.ErrorMessage
										: currentBadgeResponse.ResponseErrorDescription
										? currentBadgeResponse.ResponseErrorDescription
										: _(`ResponseStatusCode_${currentBadgeResponse.ResponseStatusCode}`),
									duration: 4 + x,
								});
								break;

							case ResponseStatusCode.FailedValidation:
								notification['error']({
									message: currentBadgeResponse.ResponseErrorDescription,
									duration: 4 + x,
								});
								break;

							default:
								handleResponse(currentBadgeResponse);
								break;
						}
					}

					if (elementsToPrint.length > 0) {
						totalCredentialsToPrint.current = credentialsIdsToPrint.length;
						printBadge({
							image: elementsToPrint.pop(),
							credentialId: credentialsIdsToPrint.pop(),
							restImagesWithCredentialId: elementsToPrint.map<ImageWithCredentialId>((x, index) => ({
								Image: x,
								CredentialId: credentialsIdsToPrint[index],
							})),
							callback: setBadgesToPrint,
							logPrintEvent: enrollmentApi.printBadgeAndLogEvent,
						});
					}
				}
			})
			.finally(() => setIsLoading(false));
	};

	const handleOnAssignCredentials = (userId: number): void => {
		if (eligibleCredentialsForAssign) {
			setIsLoading(true);
			const credentialsIds: number[] = eligibleCredentialsForAssign.map<number>((credential: AffectedCredential) => credential.CredentialId);
			handleOnHideAssignCredentialModal();
			performCredentialAction(CredentialAction.Assign, credentialsIds, userId).catch(() => setIsLoading(false));
		}
	};

	const scroll: ScrollType = { x: `${500 + tableColumns.length * 190}px`, y: scrollHeight };
	const isInModal: boolean = userId === CredentialType.FindCredential;
	const firstRenderInModal: boolean = isInModal && updateCounter === 0;
	const credentialsIds = selectedRowKeys.map<number>(x => Number(x));
	const noCredentialSelected: boolean = credentialsIds.length === 0;
	const hasMoreThanOne: boolean = credentialsIds.length > 1;
	const items: OptionsButtonBuilder<string | number> = createCredentialButtonOptions(hasMoreThanOne, selectedCredentialHelper, userId);

	let contextMenuOptions: SelectOptions<string | number>[] = [];
	for (let j = 0; j < items.options.length; j++) {
		if (items.options[j].value === CredentialActionOptions.Edit && hasMoreThanOne) {
			continue;
		}

		const separator: boolean = j + 1 === items.options.length;
		contextMenuOptions.push({
			...items.options[j],
			separator: separator || items.options[j].separator,
		});
	}
	contextMenuOptions.push(...badgeOptions);

	const handleOnPrintMultipleBadges = (): void => {
		if (badgesToPrint) {
			const { Image, CredentialId, RestImagesWithCredentialId } = badgesToPrint;
			printBadge({
				image: Image,
				credentialId: CredentialId,
				restImagesWithCredentialId: RestImagesWithCredentialId,
				callback: setBadgesToPrint,
				logPrintEvent: enrollmentApi.printBadgeAndLogEvent,
			});
		}
	};

	const handleOnHidePreviewBadgeModal = (): void => {
		setDisplayPreview(false);
	};

	const handleOnCancelAssignCredentialWarning = (): void => {
		setAssignCredentialDetails(undefined);
	};

	const handleOnHideAssignCredentialModal = (): void => {
		setEligibleCredentialsForAssign(undefined);
	};

	const handleOnHideColumnConfigurationModal = (): void => {
		setDisplayColumnConfiguration(false);
	};

	const handleOnContinueAssignCredential = (eligibleCredentials: AffectedCredential[]) => {
		handleOnCancelAssignCredentialWarning();
		setEligibleCredentialsForAssign(eligibleCredentials);
	};

	const handleOnCredentialModalSuccess = () => {
		// TODO: Retrieve credentials again
		setCredentialsByPage(paginationSettings);
		handleOnCredentialModalCancel();
	};

	const handleOnCredentialModalCancel = () => {
		const newDevice: CurrentDeviceControlObj = {
			Id: 0,
			CurrentPage: 0,
			DeviceObjectType: DeviceObjectType.EnrollmentCredential,
			Address: '',
			IsModalOpen: false,
			IsNew: false,
		};
		setCredentialId(undefined);
		dispatch(setCurrentDevice(newDevice));
	};

	const handleOnCredentialModalOpen = (newCredentialId: number, isNewCredential: boolean) => {
		const newDevice: CurrentDeviceControlObj = {
			...currentDevice,
			Id: newCredentialId,
			IsModalOpen: !currentDevice.IsModalOpen,
			DeviceObjectType: DeviceObjectType.EnrollmentCredential,
			CurrentPage: userId,
			IsNew: isNewCredential,
		};
		dispatch(setCurrentDevice(newDevice));
	};

	const isSameSectionWhereOpened: boolean = currentDevice.CurrentPage === userId;
	const displayCredentialModal: boolean =
		isSameSectionWhereOpened && currentDevice.IsModalOpen && configData && currentDevice.DeviceObjectType === DeviceObjectType.EnrollmentCredential;

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			{displayCredentialModal && (
				<CredentialTemplateModal
					configData={configData}
					credentialId={credentialId}
					handleModalOnCancel={handleOnCredentialModalCancel}
					handleModalOnSuccess={handleOnCredentialModalSuccess}
					isNew={isNewCredential}
					isTemplate={false}
					personId={userId}
					user={user}
					userId={userId}
				/>
			)}
			<div className={styles.container}>
				<div className={styles.header}>
					<div>
						<ButtonDropdown
							id='enrollmentCredentialActionsDropdown'
							menuOptions={items.options}
							icon={<CaretDownOutlined />}
							labelIcon={items.labelOrIcon}
							onClickOption={handleActionScope}
							disabled={noCredentialSelected}
						/>
						<Button
							onClick={() => setDisplayPreview(true)}
							disabled={noCredentialSelected || !canPreviewCredential}
							title={getPermissionErrorMessage(canPreviewCredential)}>
							<FileSearchOutlined /> {_('PreviewBadge')}
						</Button>
						<Button
							onClick={handleOnPrintBadge}
							disabled={noCredentialSelected || !canPrintCredential}
							title={getPermissionErrorMessage(canPrintCredential)}>
							<PrinterOutlined /> {_('PrintBadge')}
						</Button>
					</div>
					<div>
						{!isInModal && (
							<div className={styles.addButton}>
								<span title={getPermissionErrorMessage(credentialPermissions.canAdd)}>
									<ButtonDropdown
										id='enrollmentCredentialAddDropdown'
										menuOptions={addCredentialOptions.options}
										labelIcon={
											<span>
												<PlusOutlined /> {addCredentialOptions.labelOrIcon}
											</span>
										}
										icon={<CaretDownOutlined />}
										onClickOption={handleOnAddCredential}
										disabled={!credentialPermissions.canAdd}
										useParentAsPopupContainer={false}
									/>
								</span>
							</div>
						)}
					</div>
				</div>
				<HeaderBar
					title={getTableTitleHeader(userId, criteriaSearchRequest)}
					buttons={
						!isInModal && (
							<Button
								id='credentialConfigureColumnsButton'
								type='text'
								icon={<SettingFilled />}
								onClick={handleOnOpenColumnConfiguration}
								className={styles.configureButton}
							/>
						)
					}
				/>
				<div>
					<Table
						id='enrollmentCredentialTable'
						className={styles.credentialTable}
						columns={mergedColumns as ColumnsType}
						dataSource={dataSource}
						scroll={scroll}
						rowSelection={!firstRenderInModal ? rowSelection : undefined}
						size='small'
						pagination={paginationTable}
						onChange={handleOnChangeTable}
						rowClassName={(_, index) => {
							if (index % 2 !== 0) {
								return cx(styles.evenRow, styles.cursorPointer);
							} else {
								return styles.cursorPointer;
							}
						}}
						onRow={(record: CredentialGridItem) => {
							return {
								'data-test-active': record.Status === 'Active',
								onClick: () => handleOnClickCredential(record),
							};
						}}
						components={{
							body: {
								cell: EditableCell,
							},
						}}
					/>
				</div>
				<Button
					id='credentialPrintMultipleBadgesButton'
					ref={printMultipleBadgeRef}
					onClick={handleOnPrintMultipleBadges}
					className={styles.printMultipleBadgesButton}
				/>
				{displayColumnConfiguration && (
					<ColumnConfiguration
						onHideModal={handleOnHideColumnConfigurationModal}
						loadColumnConfiguration={credentialApi.getCredentialsColumnsConfiguration}
						dispatchAction={setUpdateCounter}
						securedComponent={SecuredComponents.Enrollment_Credential}
					/>
				)}
				{displayPreview && <PreviewBadgeCredential onHideModal={handleOnHidePreviewBadgeModal} credentialsIds={credentialsIds} />}
				{assignCredentialDetails && (
					<AssignCredentialWarning
						assignCredentialDetails={assignCredentialDetails}
						onCancel={handleOnCancelAssignCredentialWarning}
						onConfirm={handleOnContinueAssignCredential}
					/>
				)}
				{eligibleCredentialsForAssign && (
					<AssignCredential
						onHideModal={handleOnHideAssignCredentialModal}
						credentialsToAssign={eligibleCredentialsForAssign}
						onAssignCredential={handleOnAssignCredentials}
					/>
				)}
			</div>
		</Spin>
	);
};

export { CredentialTable };

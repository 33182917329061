import { InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { LocalPrinterSetupInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import {
	setControllerError,
	setControllerMiscSystemSetupAction,
	setControllerReportingSetupAction,
} from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerMisc, ControllerReporting, ErrorTabs } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectError, selectLocalPrinterSetupInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { ErrorMessage, Select } from '../../../../../../common';
import styles from '../reporting.module.scss';

const LocalPrinterSetup: React.FC = () => {
	const dispatch = useStoreDispatch();
	const localPrinterSetupInfo: LocalPrinterSetupInfoStore = useStoreSelector<LocalPrinterSetupInfoStore>(selectLocalPrinterSetupInfo);
	const error: ErrorTabs = useStoreSelector<ErrorTabs>(selectError);

	const handleChangeControllerReportingSetup = (value: ControllerReporting) => {
		dispatch(
			setControllerReportingSetupAction({
				...value,
			})
		);
	};

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	const handleChangeLinesPerPage = (value: string | number) => {
		const linesPerPage = Number(value);
		dispatch(setControllerError({ linesPerPageForFormFeed: false, message: '' }));
		if ((linesPerPage !== 0 && linesPerPage < 40) || linesPerPage > 255) {
			dispatch(setControllerError({ linesPerPageForFormFeed: true }));
		}
		handleChangeControllerReportingSetup({ LocalPrinterLinesPerPage: linesPerPage });
	};

	const controllerReportingLinesPerPageInputId = 'controllerReportingLinesPerPageInput';
	const controllerReportingLanguageDropdownId = 'controllerReportingLanguageDropdown';

	return (
		<div className={styles.localPrinterSetup}>
			<div>
				<label>{_('LinesPerPageForFormFeed')}:</label>
				<ErrorMessage message={_('LinesPerPageMustBe0Or40To255')} show={error.linesPerPageForFormFeed}>
					<InputNumber
						id={controllerReportingLinesPerPageInputId}
						min={0}
						max={255}
						maxLength={3}
						onChange={handleChangeLinesPerPage}
						value={localPrinterSetupInfo.linesPerPageForFormFeed}
						formatter={noneDecimalFormatter}
					/>
				</ErrorMessage>
				<label htmlFor={controllerReportingLinesPerPageInputId}>({_('0None40255')})</label>
			</div>
			<div>
				<label htmlFor={controllerReportingLanguageDropdownId}>{_('Language')}:</label>
				<Select
					id={controllerReportingLanguageDropdownId}
					onChange={value => handleChangeControllerMiscSystemSetup({ LocalPrinterLanguage: Number(value) })}
					value={localPrinterSetupInfo.languageId}
					notUseCurrentParse
					options={localPrinterSetupInfo.languageOptions.map(x => ({ label: x.Name, value: x.Id }))}
					getPopupContainer={element => element}
				/>
				<div />
			</div>
		</div>
	);
};

export { LocalPrinterSetup };

import { isSameGridDataItem, sortStatusViewerItems } from '../../components/statusViewer/helpers';
import { GridColumn } from '../../model/CommonModel';
import {
	ControllerToEdit,
	StatusGroupSelected,
	StatusObjectEnum,
	StatusViewer,
	StatusViewerGroup,
	StatusViewerPointByType,
} from '../../model/StatusViewerModel';
import { ExpandedRowItem } from './../../model/StatusViewerModel';
import { StatusViewerAction } from './actions';
import { StatusViewerActionTypes } from './actionTypes';

const INIT_STATE: StatusViewerState = {
	statusViewer: null,
	statusGroupDoorExpandedRow: null,
	statusGroups: null,
	statusViewerConfiguration: null,
	statusViewerPointByType: null,
	statusViewerColumnConfiguration: null,
	selectedStatusGroup: null,
	isLoadingStatusViewerTable: false,
	isLoadingStatusViewerConfigurationTable: false,
	controllerToEdit: { id: 0, isModalOpen: false },
};

export type StatusViewerState = {
	statusViewer: StatusViewer;
	statusGroupDoorExpandedRow: ExpandedRowItem[];
	statusGroups: StatusViewerGroup[];
	statusViewerConfiguration: StatusViewerGroup;
	statusViewerColumnConfiguration: Record<StatusObjectEnum, GridColumn[]>;
	statusViewerPointByType: StatusViewerPointByType;
	selectedStatusGroup: StatusGroupSelected;
	isLoadingStatusViewerTable: boolean;
	isLoadingStatusViewerConfigurationTable: boolean;
	controllerToEdit: ControllerToEdit;
};

const statusViewerReducer = (state: Readonly<StatusViewerState> = INIT_STATE, action: StatusViewerAction): StatusViewerState => {
	switch (action.type) {
		case StatusViewerActionTypes.SELECTED_STATUS_GROUP:
			return {
				...state,
				selectedStatusGroup: action.payload,
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER:
			let myExpandedArray: ExpandedRowItem[] = [];
			action.payload.StatusViewerItems?.forEach(statusViewerItem => {
				if (statusViewerItem.ObjectId === StatusObjectEnum.Door) {
					myExpandedArray = statusViewerItem.Data.map<ExpandedRowItem>(statusViewerDataRow => {
						let expandedRowItem: ExpandedRowItem = { Address: '', DoorId: 0, isExpanded: false, isLoading: true };
						statusViewerDataRow.forEach(item => {
							if (item.Key === 'Key') {
								expandedRowItem = {
									...expandedRowItem,
									DoorId: Number(item.Value),
								};
							}
							if (item.Key === 'Address') {
								expandedRowItem = {
									...expandedRowItem,
									Address: item.Value,
								};
							}
						});
						return expandedRowItem;
					});
				}
			});
			return {
				...state,
				statusViewer: action.payload,
				statusGroupDoorExpandedRow: myExpandedArray,
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_BY_PAGINATION:
			return {
				...state,
				statusViewer: {
					...state.statusViewer,
					StatusViewerItems: state.statusViewer.StatusViewerItems.map(m => {
						if (m.ObjectId === action.payload.StatusViewerItems[0]?.ObjectId) {
							return action.payload.StatusViewerItems[0];
						}
						return m;
					}),
				},
			};

		case StatusViewerActionTypes.SET_STATUS_VIEWER_REAL_TIME:
			const statusViewerItems = [...state.statusViewer.StatusViewerItems];

			statusViewerItems.forEach(statusViewerItem => {
				const statusViewerItemUpdated = action.payload.find(x => x.ObjectType === statusViewerItem.ObjectId);

				if (statusViewerItemUpdated) {
					const data = statusViewerItem.Data.map(currentDataItem => {
						const hasDataItemFound =
							statusViewerItemUpdated.ObjectId !== 0
								? currentDataItem.some(x => isSameGridDataItem(x, 'Key', statusViewerItemUpdated.ObjectId.toString()))
								: currentDataItem.some(x => isSameGridDataItem(x, 'Address', statusViewerItemUpdated.Address));

						if (hasDataItemFound) {
							const newDataItem = currentDataItem.map(gridData => {
								const newGridData = statusViewerItemUpdated.Data.find(x => x.Key === gridData.Key);
								if (newGridData) {
									return {
										...newGridData,
									};
								}
								return gridData;
							});
							return newDataItem;
						}
						return currentDataItem;
					});

					const { SortDirection: sortDirection, SortField: sortField } = statusViewerItem.PaginationSetting;
					const sortedData = sortStatusViewerItems(data, sortDirection, sortField);

					statusViewerItem.Data = [...sortedData];
				}
			});

			return {
				...state,
				statusViewer: {
					StatusViewerItems: [...statusViewerItems],
				},
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_BY_TYPE:
			let myExpandedArrayByType: ExpandedRowItem[] = [];
			action.payload.StatusViewerItems?.forEach(statusViewerItem => {
				if (statusViewerItem.ObjectId === StatusObjectEnum.Door) {
					myExpandedArrayByType = statusViewerItem.Data.map<ExpandedRowItem>(statusViewerDataRow => {
						let expandedRowItem: ExpandedRowItem = { Address: '', DoorId: 0, isExpanded: false, isLoading: true };
						statusViewerDataRow.forEach(item => {
							if (item.Key === 'Key') {
								expandedRowItem = {
									...expandedRowItem,
									DoorId: Number(item.Value),
								};
							}
							if (item.Key === 'Address') {
								expandedRowItem = {
									...expandedRowItem,
									Address: item.Value,
								};
							}
						});
						return expandedRowItem;
					});
				}
			});
			return {
				...state,
				statusViewer: action.payload,
				statusGroupDoorExpandedRow: myExpandedArrayByType,
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_MASKED_ONLY:
			return {
				...state,
				statusViewer: action.payload,
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_GROUP:
			return {
				...state,
				statusGroups: action.payload,
			};

		case StatusViewerActionTypes.ADD_STATUS_VIEWER_GROUP:
			return {
				...state,
			};

		case StatusViewerActionTypes.EDIT_STATUS_VIEWER_GROUP:
			return {
				...state,
				statusGroups: state.statusGroups.map(m => {
					if (m.StatusGroupId === action.payload.StatusGroupId) {
						return action.payload;
					}
					return m;
				}),
				selectedStatusGroup: {
					...state.selectedStatusGroup,
					Id: action.payload.StatusGroupId,
					Name: action.payload.Name,
					IsStatusViewer: true,
				},
			};

		case StatusViewerActionTypes.DELETE_STATUS_VIEWER:
			return {
				...state,
				statusGroups: state.statusGroups.filter(f => f.StatusGroupId !== action.payload.Id),
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_CONFIGURATION:
			return {
				...state,
				statusViewerConfiguration: action.payload,
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_VIEWER_COLUMN_CONFIGURATION:
			return {
				...state,
				statusViewerColumnConfiguration: action.payload,
			};

		case StatusViewerActionTypes.CLEAN_STATUS_VIEWER:
			return {
				...state,
				statusViewer: action.payload,
			};

		case StatusViewerActionTypes.RETRIEVE_STATUS_POINTS_BY_TYPE_ACTION:
			return {
				...state,
				statusViewerPointByType: action.payload,
			};

		case StatusViewerActionTypes.NEW_STATUS_VIEWER_CONFIGURATION:
			return {
				...state,
				statusViewerConfiguration: action.payload,
			};

		case StatusViewerActionTypes.STATUS_VIEWER_ADD_POINT:
			return {
				...state,
				statusViewerConfiguration: {
					...state.statusViewerConfiguration,
					StatusPoints: [...state.statusViewerConfiguration.StatusPoints, ...action.payload],
				},
			};

		case StatusViewerActionTypes.STATUS_VIEWER_REMOVE_POINT:
			return {
				...state,
				statusViewerConfiguration: {
					...state.statusViewerConfiguration,
					StatusPoints: [
						...state.statusViewerConfiguration.StatusPoints.filter(
							f => action.payload.filter(ff => ff.ObjectTypeId === f.ObjectTypeId && ff.DeviceId === f.DeviceId).length === 0
						),
					],
				},
			};

		case StatusViewerActionTypes.SAVE_STATUS_VIEWER_COLUMN_CONFIGURATION:
			return {
				...state,
				statusViewerColumnConfiguration: action.payload,
			};
		case StatusViewerActionTypes.UPDATE_STATUS_VIEWER_COLUMN_CONFIGURATION_STATE:
			return {
				...state,
				statusViewerColumnConfiguration: action.payload,
			};

		case StatusViewerActionTypes.RESET_STATUS_VIEWER_POINT:
			return {
				...state,
				statusViewerPointByType: null,
				statusViewerConfiguration: null,
			};

		case StatusViewerActionTypes.LOADING_STATUS_VIEWER_TABLE:
			return {
				...state,
				isLoadingStatusViewerTable: action.payload,
			};

		case StatusViewerActionTypes.LOADING_STATUS_VIEWER_CONFIGURATION_TABLE:
			return {
				...state,
				isLoadingStatusViewerConfigurationTable: action.payload,
			};

		case StatusViewerActionTypes.EXPANDED_ROW_STATUS_GROUP:
			return {
				...state,
				statusGroupDoorExpandedRow: state.statusGroupDoorExpandedRow.map(expandedRow => {
					if (expandedRow.Address === action.payload.Address) {
						return {
							...expandedRow,
							isExpanded: action.payload.isExpanded,
							isLoading: action.payload.isLoading,
							Granted: action.payload.Granted,
							Denied: action.payload.Denied,
							RunTimeFunction: action.payload.RunTimeFunction,
						};
					}
					return expandedRow;
				}),
			};

		case StatusViewerActionTypes.SET_CONTROLLER_TO_EDIT:
			return {
				...state,
				controllerToEdit: action.payload,
			};

		case StatusViewerActionTypes.ADD_STATUS_VIEWER_ITEM_DATA:
			const { objectType, paginationSetting, statusViewerEvent } = action.payload;
			const { SortDirection: sortDirection, SortField: sortField } = paginationSetting;

			const newStatusViewerItems = state.statusViewer.StatusViewerItems.map(x => {
				if ((x.ObjectId as StatusObjectEnum) === objectType) {
					return {
						...x,
						Data: [...sortStatusViewerItems([...x.Data, statusViewerEvent.Data], sortDirection, sortField)],
						PaginationSetting: {
							...x.PaginationSetting,
						},
						TotalItems: x.TotalItems + 1,
					};
				}
				return x;
			});

			return {
				...state,
				statusViewer: {
					...state.statusViewer,
					StatusViewerItems: newStatusViewerItems,
				},
			};

		case StatusViewerActionTypes.DELETE_STATUS_VIEWER_ITEM_DATA:
			const clonedStatusViewerItems = state.statusViewer.StatusViewerItems.map(x => {
				if ((x.ObjectId as StatusObjectEnum) === action.payload.objectType) {
					const newData = x.Data.filter(item => item.some(i => i.Key === 'Key' && i.Value !== action.payload.deviceId.toString()));
					return { ...x, Data: [...newData], TotalItems: x.TotalItems - 1 };
				}
				return x;
			});

			return {
				...state,
				statusViewer: {
					...state.statusViewer,
					StatusViewerItems: [...clonedStatusViewerItems],
				},
			};

		case StatusViewerActionTypes.DELETE_STATUS_GROUP_OPTION:
			const newStatusGroups = state.statusGroups.filter(statusGroup => statusGroup.StatusGroupId !== action.payload);

			return {
				...state,
				statusGroups: [...newStatusGroups],
			};

		default:
			return state;
	}
};

export { statusViewerReducer };

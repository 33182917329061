import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Radio, Spin, Table, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { handleResponse } from '../../../Helper';
import { eventApi } from '../../../api';
import { SecuredComponents, User, getPermissionErrorMessage } from '../../../model/AccountModel';
import { BaseColumns, ResponseStatusCode, TupleExtraItem } from '../../../model/CommonModel';
import { OptionsButtonBuilder, VelocityDevice } from '../../../model/DeviceAdminModel';
import { CustomFilter, CustomFilterGroup, EventCustomFilterGroup, EventFilterGroup, EventModel } from '../../../model/EventModel';
import { ButtonDropdown, Modal, ModalConfirmCustomFooter, ModalConfirmation, Select } from '../../common';
import styles from './modalConfigureFilters.module.scss';

const filterGroupSelect: string = 'FilterGroupSelect';
const criteriaBuilderDiv: string = 'CriteriaBuilderDiv';
const criteriaPortSelect: string = 'CriteriaPortSelect';
const criteriaXboxSelect: string = 'CriteriaXboxSelect';
const criteriaControllersSelect: string = 'CriteriaControllersSelect';
const criteriaEventsSelect: string = 'CriteriaEventsSelect';
const selectedCriteriaTable: string = 'SelectedCriteriaTable';
const filterGroupRename: string = 'FilterGroupRename';
const filterGroupDelete: string = 'FilterGroupDelete';
const criteriaEventAdd: string = 'CriteriaEventAdd';
const filterTypeRadioButton: string = 'FilterTypeRadioButton';
const filterLimitCheckbox: string = 'FilterLimitCheckbox';
const filterExclusionCheckbox: string = 'FilterExclusionCheckbox';
const saveChangesButton: string = 'SaveChangesButton';
const saveCloseChangesButton: string = 'SaveCloseChangesButton';
const closeChangesButton: string = 'CloseChangesButton';
const saveRenameFilterButton: string = 'SaveRenameFilterButton';
const closeRenameFilterButton: string = 'CloseRenameFilterButton';
const deleteSelectedCriteria: string = 'DeleteSelectedCriteria';
const noneValue: number = -1;
const noneLabel: string = `<${_('None')}>`;
const newValue: number = 0;
const newLabel: string = `<${_('New')}>`;
const notApplyValue: number = 0;
const notApplyLabel: string = `<${_('NA')}>`;
const evfPort: string = 'EVF_PORT';
const evfXbox: string = 'EVF_XBOX';
const evfController: string = 'EVF_CONTROLLER';
const evfEvent: string = 'EVF_EVENT';
const inputRenameFilterGroup: string = 'InputRenameFilterGroup';
type CustomFilterDataSourceType = CustomFilter & BaseColumns;

const user: User = getUser();
const componentPermission = User.getComponentPermission(user, SecuredComponents.Event_Viewer);

interface DataType {
	key: React.Key;
	FilterId: number;
	Description: string;
	Filter: string;
}

const createButtonOptions = (): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				id: deleteSelectedCriteria,
				label: _('Delete'),
				disabled: !componentPermission.canDelete,
				value: 'delete',
				title: getPermissionErrorMessage(componentPermission.canDelete),
			},
		],
	};
};
const items = createButtonOptions();
const naObj: VelocityDevice = {
	Id: notApplyValue,
	Name: notApplyLabel,
};

type Props = {
	onSetVisible: (visible: boolean) => void;
	onRefreshEvents: (action: string, sender: Partial<CustomFilterGroup>) => void;
};

const columns: ColumnsType<DataType> = [
	{
		title: 'Filter Description',
		dataIndex: 'Description',
	},
];

export const ModalConfigureFilters = ({ onSetVisible, onRefreshEvents }: Props) => {
	const [limitCheckbox, setLimitCheckbox] = useState<boolean>(true);
	const [customFilterGroup, setCustomFilterGroup] = useState<EventFilterGroup[]>([]);
	const [ports, setPorts] = useState<VelocityDevice[]>([]);
	const [portSelected, setPortSelected] = useState<number>(notApplyValue);
	const [xboxes, setXboxes] = useState<VelocityDevice[]>([]);
	const [xboxSelected, setXboxSelected] = useState<number>(notApplyValue);
	const [controllers, setControllers] = useState<VelocityDevice[]>([]);
	const [controllersSelected, setControllersSelected] = useState<number>(notApplyValue);
	const [events, setEvents] = useState<EventModel[]>([]);
	const [eventsSelected, setEventsSelected] = useState<number>(notApplyValue);
	const [selectedCriteriaDataSource, setSelectedCriteriaDataSource] = useState<CustomFilterDataSourceType[]>([]);
	const [filterGroupSelected, setFilterGroupSelected] = useState<number>(noneValue);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
	const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(true);
	const [deleteButtonDisabled, setDeleteButtonDisabled] = useState<boolean>(true);
	const [modalNewFilterGroup, setModalNewFilterGroup] = useState<boolean>(true);
	const [modalRenameFilterGroup, setModalRenameFilterGroup] = useState<boolean>(false);
	const [pendingChanges, setPendingChanges] = useState<boolean>(false);
	const [pendingIdToChange, setPendingIdToChange] = useState<number>(noneValue);
	const [modalPendingChanges, setModalPendingChanges] = useState<boolean>(false);
	const [renameFilterGroup, setRenameFilterGroup] = useState<string>();
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [validationNameError, setValidationNameError] = useState<boolean>(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	useEffect(() => {
		eventApi.retrieveCustomEventConfiguration().then(response => {
			const isValid = !handleResponse(response);
			if (isValid) {
				batch(() => {
					const entity = response.Entity;
					setCustomFilterGroupSorted(entity.EventFilterGroups);
					setPorts(entity.Ports);
					setXboxes(entity.Xboxes);
					setControllers(entity.Controllers);
					setEvents(entity.Events);
				});
			}
		});
	}, []);

	useEffect(() => {
		if (filterGroupSelected) {
			eventApi.retrieveFilterGroupById(filterGroupSelected).then(response => {
				const isValid = !handleResponse(response);
				if (isValid) {
					const customFilters = response.CustomFilters;
					let filterType: boolean = true;
					const resAux: CustomFilterDataSourceType[] = response.CustomFilters.map<CustomFilterDataSourceType>((filter: CustomFilter) => {
						filterType = filter.FilterType === 1;
						return {
							...filter,
							key: filter.FilterId,
						};
					});
					batch(() => {
						setSaveButtonDisabled(customFilters.length === 0);
						setLimitCheckbox(filterType);
						setSelectedCriteriaDataSource(resAux);
					});
				}
			});
		}
	}, [filterGroupSelected]);

	useEffect(() => {
		if (portSelected || xboxSelected || controllersSelected || eventsSelected) {
			setAddButtonDisabled(false);
		} else {
			setAddButtonDisabled(true);
		}
	}, [portSelected, xboxSelected, controllersSelected, eventsSelected]);

	const handleComparePendingChanges = (filterId: number) => {
		batch(() => {
			if (pendingChanges) {
				setModalPendingChanges(true);
				setPendingIdToChange(filterId);
			} else {
				handleChangeGroupFilterSelected(filterId);
			}
		});
	};

	const handleChangeGroupFilterSelected = (filterId: number) => {
		batch(() => {
			setFilterGroupSelected(filterId);
			cleanCriteriaBuilder();
			switch (filterId) {
				case noneValue:
					setLimitCheckbox(true);
					setSaveButtonDisabled(true);
					break;

				case newValue:
					setLimitCheckbox(true);
					setModalNewFilterGroup(true);
					setRenameFilterGroup('');
					setSaveButtonDisabled(true);
					break;

				default:
					setButtonDisabled(false);
					setSaveButtonDisabled(false);
					break;
			}
		});
	};

	const cleanCriteriaBuilder = () => {
		setPortSelected(notApplyValue);
		setXboxSelected(notApplyValue);
		setControllersSelected(notApplyValue);
		setEventsSelected(notApplyValue);
		setAddButtonDisabled(true);
		setButtonDisabled(true);
		setSelectedCriteriaDataSource([]);
	};

	const handleChangeSelected = async (value: number, filterType: string) => {
		switch (filterType) {
			case evfPort:
				setPortSelected(value);
				break;

			case evfXbox:
				setXboxSelected(value);
				break;

			case evfController:
				setControllersSelected(value);
				break;

			case evfEvent:
				setEventsSelected(value);
				break;
		}
	};

	const addCustomFilter = () => {
		batch(() => {
			setPendingChanges(true);
			setSaveButtonDisabled(false);

			let output: TupleExtraItem[] = [];
			let hasDoubleParenthesis: boolean = false;
			if (eventsSelected !== notApplyValue) {
				hasDoubleParenthesis = true;
				output.push(buildItemSelected(evfEvent));
			}

			if (portSelected !== notApplyValue) {
				output.push(buildItemSelected(evfPort));
			}

			if (xboxSelected !== notApplyValue) {
				output.push(buildItemSelected(evfXbox));
			}

			if (controllersSelected !== notApplyValue) {
				output.push(buildItemSelected(evfController));
			}

			let minKey: React.Key = -1;
			const newKeys: number[] = selectedCriteriaDataSource.filter(x => x.key <= '0').map(x => x.key as number);
			if (newKeys.length) {
				minKey = Math.min(...newKeys) - 1;
			}

			output = output.sort((a: TupleExtraItem, b: TupleExtraItem) => {
				const firstValue: boolean = a.Item1;
				const secondValue: boolean = b.Item1;

				return firstValue === secondValue ? 0 : firstValue ? -1 : 1;
			});

			let addSelectedCriteria: CustomFilter = {
				FilterType: limitCheckbox ? 1 : 0,
				Description: '',
				Filter: '',
				Sql: '',
			};
			for (let i = 0; i < output.length; i++) {
				let item: TupleExtraItem = output[i];
				if (addSelectedCriteria.Filter) {
					addSelectedCriteria.Filter = `${addSelectedCriteria.Filter},${item.Item3}`;
				} else {
					addSelectedCriteria.Filter = item.Item3;
				}

				if (i === output.length - 1) {
					if (hasDoubleParenthesis && !item.Item1) {
						addSelectedCriteria.Description += `${item.Item2})`;
					} else {
						addSelectedCriteria.Description += item.Item2;
					}
					break;
				}

				const operatorChar = item.Item1 ? ' AND (' : ' OR ';
				addSelectedCriteria.Description += `${item.Item2}${operatorChar}`;
			}
			setSelectedCriteriaDataSource([...selectedCriteriaDataSource, { ...addSelectedCriteria, key: minKey, FilterId: minKey }]);
		});
	};

	const buildItemSelected = (filterType: string): TupleExtraItem => {
		let descriptionTyped: TupleExtraItem = undefined;
		switch (filterType) {
			case evfEvent:
				descriptionTyped = {
					Item1: true,
					Item2: `(Event is '${eventsSelected}')`,
					Item3: eventsSelected.toString(),
				};
				break;

			case evfPort:
				const currentSelectedPort = ports.find(x => x.Id === portSelected);
				if (currentSelectedPort) {
					descriptionTyped = {
						Item1: false,
						Item2: `(Port is '${currentSelectedPort.Name}')`,
						Item3: currentSelectedPort.Address,
					};
				}
				break;

			case evfXbox:
				const currentSelectedXbox = xboxes.find(x => x.Id === xboxSelected);
				if (currentSelectedXbox) {
					descriptionTyped = {
						Item1: false,
						Item2: `(XBox is '${currentSelectedXbox.Name}')`,
						Item3: currentSelectedXbox.Address,
					};
				}
				break;

			case evfController:
				const currentSelectedController = controllers.find(x => x.Id === controllersSelected);
				if (currentSelectedController) {
					descriptionTyped = {
						Item1: false,
						Item2: `(Controller is '${currentSelectedController.Name}')`,
						Item3: currentSelectedController.Address,
					};
				}
				break;
		}

		return descriptionTyped;
	};

	const setCustomFilterGroupSorted = (customFilterGroups: EventFilterGroup[]) => {
		const updatedState = customFilterGroups.sort((a: EventFilterGroup, b: EventFilterGroup) => {
			const firstValue: string = a.GroupName;
			const secondValue: string = b.GroupName;

			return secondValue.localeCompare(firstValue);
		});
		setCustomFilterGroup(updatedState);
	};

	const handleAddFilterGroup = async (saveChanges: boolean, isClosing: boolean) => {
		setIsSaving(true);
		if (filterGroupSelected === -1) {
			cleanCriteriaBuilder();
			setIsSaving(false);
			return;
		}

		const findGroupName: EventFilterGroup = customFilterGroup.find(el => el.ObjectId === filterGroupSelected);
		const groupName: string = saveChanges ? findGroupName.GroupName.toString() : renameFilterGroup;
		const params: Partial<EventCustomFilterGroup> = {
			FilterType: limitCheckbox ? 1 : 0,
			FilterGroupId: filterGroupSelected,
			GroupName: groupName,
			EventFilterClause: '',
			CanApply: false,
			CustomFilters: selectedCriteriaDataSource,
		};

		await eventApi.addFilterGroup(params).then(response => {
			batch(() => {
				setIsSaving(false);
				if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
					notification['error']({
						message: response.ErrorMessage,
					});
					setValidationNameError(true);
				} else {
					const isValid = !handleResponse(response);
					if (isValid) {
						const newCustomFilter = response.Entity;
						onRefreshEvents('modified', newCustomFilter);
						if (isClosing) {
							onSetVisible(false);
							return;
						}

						if (filterGroupSelected === 0) {
							setCustomFilterGroupSorted([...customFilterGroup, { ObjectId: newCustomFilter.FilterGroupId, GroupName: groupName }]);
						}
						setFilterGroupSelected(newCustomFilter.FilterGroupId);
						setSaveButtonDisabled(false);
						if (pendingChanges) {
							handleChangeGroupFilterSelected(pendingIdToChange);
						}
						setPendingChanges(false);
						setPendingIdToChange(noneValue);
						if (!saveChanges) {
							setModalRenameFilterGroup(false);
							setButtonDisabled(false);
						}
					}
				}
			});
		});
	};

	const handleRenameFilterGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
		batch(() => {
			const value: string = e.currentTarget.value ? e.currentTarget.value : '';
			setValidationNameError(false);
			setRenameFilterGroup(value);
		});
	};

	const saveRenameFilterGroup = async () => {
		if (modalNewFilterGroup) {
			handleAddFilterGroup(false, isClosing);
		} else {
			if (filterGroupSelected > 0) {
				await eventApi.renameFilterGroup(filterGroupSelected, renameFilterGroup).then(response => {
					batch(() => {
						if (response.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
							notification['error']({
								message: response.ErrorMessage,
							});
							setValidationNameError(true);
						} else {
							setValidationNameError(false);
							const isValid = !handleResponse(response);
							if (isValid) {
								onRefreshEvents('renamed', { FilterGroupId: filterGroupSelected, GroupName: renameFilterGroup });
								setCustomFilterGroupSorted(
									customFilterGroup.map(x => {
										if (x.ObjectId === filterGroupSelected) {
											return {
												...x,
												GroupName: renameFilterGroup,
											};
										}

										return { ...x };
									})
								);
								setModalRenameFilterGroup(false);
							}
						}
					});
				});
			}
		}
	};

	const handleDeleteFilterGroup = async () => {
		if (filterGroupSelected > 0) {
			await eventApi.deleteFilterGroup(filterGroupSelected).then(response => {
				const isValid = !handleResponse(response);
				if (isValid) {
					batch(() => {
						setCustomFilterGroupSorted(customFilterGroup.filter(x => x.ObjectId !== filterGroupSelected));
						onRefreshEvents('deleted', { FilterGroupId: filterGroupSelected });
						setFilterGroupSelected(-1);
						cleanCriteriaBuilder();
						setSaveButtonDisabled(false);
					});
				}
			});
		}
	};

	const removeSelectedCriteria = (key: string) => {
		if (key === 'delete') {
			const selectedCriteriaDataSourceAux: CustomFilterDataSourceType[] = [...selectedCriteriaDataSource];
			selectedRowKeys.map(selectedKey => {
				const index: number = selectedCriteriaDataSourceAux.findIndex(el => el.key === selectedKey);
				selectedCriteriaDataSourceAux.splice(index, 1);
			});
			setPendingChanges(true);
			setSaveButtonDisabled(selectedCriteriaDataSourceAux.length === 0);
			setDeleteButtonDisabled(true);
			setSelectedCriteriaDataSource(selectedCriteriaDataSourceAux);
			setSelectedRowKeys([]);
		}
	};

	const handleSaveChanges = (isClosing: boolean) => {
		batch(() => {
			setIsClosing(isClosing);
			if (filterGroupSelected === 0) {
				setModalRenameFilterGroup(true);
				setModalNewFilterGroup(true);
			} else {
				handleAddFilterGroup(true, isClosing);
			}
		});
	};

	const handleCloseModal = () => {
		setIsClosing(true);
		if (pendingChanges) {
			setModalPendingChanges(true);
		} else {
			onSetVisible(false);
		}
	};

	const rowSelection: TableRowSelection<CustomFilterDataSourceType> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
		getCheckboxProps: record => ({
			id: `eventFiltersTableCheckbox-${record.key}`,
		}),
		selectedRowKeys,
		onChange: (newSelectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			batch(() => {
				setSelectedRowKeys(newSelectedRowKeys);
				setDeleteButtonDisabled(!(selectedRows.length > 0));
			});
		},
	};

	const customFilterGroupOptions = [{ ObjectId: noneValue, GroupName: noneLabel }, { ObjectId: newValue, GroupName: newLabel }, ...customFilterGroup];
	const portsOptions = [naObj, ...ports];
	const xboxesOptions = [naObj, ...xboxes];
	const controllersOptions = [naObj, ...controllers];
	const eventsOptions = [{ Id: notApplyValue, DefaultDescription: notApplyLabel, Description: notApplyLabel }, ...events];

	let renameModalLabel: string = _('FilterGroupName');
	if (!modalNewFilterGroup && filterGroupSelected > 0) {
		renameModalLabel = _('RenameFilterGroup');
	}

	return (
		<Modal
			visible={true}
			width='700px'
			title={_('ConfigureCustomFilters')}
			onCancel={handleCloseModal}
			footer={[
				<Button
					id={saveChangesButton}
					key={saveChangesButton}
					className={styles.buttonTypeContainer}
					type='primary'
					disabled={saveButtonDisabled}
					onClick={() => {
						handleSaveChanges(false);
					}}>
					{_('SaveChanges')}
				</Button>,
				<Button
					id={saveCloseChangesButton}
					key={saveCloseChangesButton}
					className={styles.buttonTypeContainer}
					type='primary'
					disabled={saveButtonDisabled}
					onClick={() => {
						handleSaveChanges(true);
					}}>
					{_('SaveAndClose')}
				</Button>,
				<Button id={closeChangesButton} key={closeChangesButton} className={styles.buttonTypeContainer} type='default' onClick={handleCloseModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<Spin tip={`${_('Loading')}...`} spinning={isSaving}>
				<>
					<div className={styles.groupContainer}>
						<div className={styles.filterGroupContainer}>
							<label htmlFor={filterGroupSelect} className={styles.filterGroupLabel}>
								{_('FilterGroup')}
							</label>
							<Select
								id={filterGroupSelect}
								options={customFilterGroupOptions?.map(x => ({ id: x.ObjectId.toString(), value: x.ObjectId.toString(), label: x.GroupName }))}
								placeholder={_('CustomFilters')}
								showSearch={true}
								onChange={(value: string) => handleComparePendingChanges(Number(value))}
								value={filterGroupSelected?.toString()}
								getPopupContainer={element => element.parentElement}
								virtual={true}
							/>
						</div>
						<div className={styles.buttonGroupContainer}>
							<Button
								id={filterGroupRename}
								type='default'
								className={styles.buttonDefault}
								disabled={buttonDisabled}
								onClick={() => {
									batch(() => {
										setRenameFilterGroup(customFilterGroup.find(el => el.ObjectId === filterGroupSelected).GroupName);
										setModalRenameFilterGroup(true);
										setModalNewFilterGroup(false);
									});
								}}>
								{_('Rename')}
							</Button>
							<Button
								id={filterGroupDelete}
								type='default'
								className={styles.buttonDefault}
								disabled={buttonDisabled}
								onClick={() => {
									const deleteNameFilterGroup: string = customFilterGroup.find(el => el.ObjectId === filterGroupSelected).GroupName;
									ModalConfirmation({
										onConfirm: () => handleDeleteFilterGroup(),
										content: <div>{_('AreYouSureYouWantToDeleteFilterGroup').replace('%1', deleteNameFilterGroup)}</div>,
									});
								}}>
								{_('Delete')}
							</Button>
						</div>
					</div>
					<div className={styles.typeContainer}>
						<div>
							<label htmlFor={filterTypeRadioButton}>
								<strong>{_('SelectFilterType')}</strong>
							</label>
							<div className={styles.buttonTypeContainer}>
								<Radio.Group
									onChange={e => {
										batch(() => {
											setLimitCheckbox(e.target.value);
											setPendingChanges(true);
										});
									}}
									value={limitCheckbox}
									disabled={filterGroupSelected !== newValue && buttonDisabled}
									id={filterTypeRadioButton}>
									<Radio value={true} id={filterLimitCheckbox}>
										<label htmlFor={filterLimitCheckbox}>
											<strong>{_('Limit')}&nbsp;</strong>
											<p>{_('LimitFilterType')}</p>
										</label>
									</Radio>
									<Radio value={false} id={filterExclusionCheckbox}>
										<label htmlFor={filterExclusionCheckbox}>
											<strong>{_('Exclusion')}&nbsp;</strong>
											<p>{_('ExclusionFilterType')}</p>
										</label>
									</Radio>
								</Radio.Group>
							</div>
						</div>
					</div>
					<div className={styles.criteriaContainer}>
						<div>
							<span>
								<strong>{_('CriteriaBuilder')}</strong>
							</span>
							<div className={styles.buttonTypeContainer} style={{ marginTop: '15px' }} id={criteriaBuilderDiv}>
								<label htmlFor={criteriaPortSelect} className={styles.labelCriteria}>
									{_('Port')}:
								</label>
								<Select
									disabled={filterGroupSelected === noneValue}
									id={criteriaPortSelect}
									options={portsOptions?.map(x => ({ id: x.Id.toString(), value: x.Id.toString(), label: x.Name }))}
									placeholder={notApplyLabel}
									showSearch={true}
									onChange={(value: string) => handleChangeSelected(Number(value), evfPort)}
									value={portSelected?.toString()}
									getPopupContainer={element => element.parentElement}
									virtual={true}
								/>
							</div>
							<div className={styles.buttonTypeContainer}>
								<label htmlFor={criteriaXboxSelect} className={styles.labelCriteria}>
									{_('Xbox')}:
								</label>
								<Select
									disabled={filterGroupSelected === noneValue}
									id={criteriaXboxSelect}
									options={xboxesOptions?.map(x => ({ id: x.Id.toString(), value: x.Id.toString(), label: x.Name }))}
									placeholder={notApplyLabel}
									showSearch={true}
									onChange={(value: string) => handleChangeSelected(Number(value), evfXbox)}
									value={xboxSelected?.toString()}
									getPopupContainer={element => element.parentElement}
									virtual={true}
								/>
							</div>
							<div className={styles.buttonTypeContainer}>
								<label htmlFor={criteriaControllersSelect} className={styles.labelCriteria}>
									{_('Controllers')}:
								</label>
								<Select
									disabled={filterGroupSelected === noneValue}
									id={criteriaControllersSelect}
									options={controllersOptions?.map(x => ({ id: x.Id.toString(), value: x.Id.toString(), label: x.Name }))}
									placeholder={notApplyLabel}
									showSearch={true}
									onChange={(value: string) => handleChangeSelected(Number(value), evfController)}
									value={controllersSelected?.toString()}
									getPopupContainer={element => element.parentElement}
									virtual={true}
								/>
							</div>
							<div className={(styles.buttonTypeContainer, styles.andLabelCriteria)}>
								<label htmlFor={criteriaEventAdd}>{_('AND')}</label>
							</div>
							<div className={styles.buttonTypeContainer}>
								<label htmlFor={criteriaEventsSelect} className={styles.labelCriteria}>
									{_('Event')}:
								</label>
								<Select
									disabled={filterGroupSelected === noneValue}
									id={criteriaEventsSelect}
									options={eventsOptions?.map(x => ({
										id: x.Id.toString(),
										value: x.Id.toString(),
										label: x.Id > 0 ? `${x.Id} ${x.DefaultDescription}` : x.DefaultDescription,
									}))}
									placeholder={notApplyLabel}
									showSearch={true}
									onChange={(value: string) => handleChangeSelected(Number(value), evfEvent)}
									value={eventsSelected?.toString()}
									getPopupContainer={element => element.parentElement}
									virtual={true}
								/>
								<Button
									id={criteriaEventAdd}
									type='default'
									disabled={addButtonDisabled}
									icon={<PlusOutlined />}
									onClick={() => addCustomFilter()}>
									{_('Add')}
								</Button>
							</div>
						</div>
					</div>
					<div className={styles.selectedCriteriaContainer}>
						<ButtonDropdown
							id={'deleteSelectedCriteriaDropdown'}
							disabled={deleteButtonDisabled}
							menuOptions={items.options}
							icon={<CaretDownOutlined />}
							labelIcon={items.labelOrIcon}
							onClickOption={removeSelectedCriteria}
						/>
						<Table
							rowSelection={rowSelection}
							title={() => (
								<span>
									<strong>{_('SelectedCriteria')}</strong>&nbsp;
									<strong>{_('SelectedCriteriaEachLine')}</strong>
								</span>
							)}
							columns={columns}
							dataSource={selectedCriteriaDataSource}
							pagination={false}
							id={selectedCriteriaTable}
						/>
					</div>
				</>
				<Modal
					visible={modalRenameFilterGroup}
					onCancel={() => setModalRenameFilterGroup(false)}
					closeIconId='ModalCloseRenameFilterGroup'
					width='400px'
					title={renameModalLabel}
					bodyStyle={{ minHeight: '100px', maxHeight: '100px' }}
					footer={[
						<Button
							id={saveRenameFilterButton}
							key={saveRenameFilterButton}
							className={styles.buttonTypeContainer}
							type='primary'
							onClick={() => saveRenameFilterGroup()}>
							{_('Save')}
						</Button>,
						<Button
							id={closeRenameFilterButton}
							key={closeRenameFilterButton}
							className={styles.buttonTypeContainer}
							type='default'
							onClick={() => {
								batch(() => {
									setRenameFilterGroup('');
									setModalRenameFilterGroup(false);
									setValidationNameError(false);
								});
							}}>
							{_('Cancel')}
						</Button>,
					]}>
					<Input
						id={inputRenameFilterGroup}
						aria-label={_('FilterGroupNameInstruction')}
						placeholder={_('FilterGroupNameInstruction')}
						style={{ height: '25px', margin: '0px 0px 15px 0px' }}
						className={cx({
							[styles.error]: validationNameError,
						})}
						maxLength={32}
						onChange={handleRenameFilterGroup}
						value={renameFilterGroup}
					/>
				</Modal>
				<ModalConfirmCustomFooter
					title={_('WarningMessage')}
					width={'500px'}
					visible={modalPendingChanges}
					onOk={() => {
						batch(() => {
							setModalPendingChanges(false);
							handleSaveChanges(isClosing);
						});
					}}
					onNo={() => {
						batch(() => {
							if (isClosing) {
								onSetVisible(false);
							} else {
								setModalPendingChanges(false);
								setPendingChanges(false);
								handleChangeGroupFilterSelected(pendingIdToChange);
							}
						});
					}}
					onCancel={() => {
						batch(() => {
							setIsClosing(false);
							setModalPendingChanges(false);
							setPendingIdToChange(noneValue);
						});
					}}
					children={<label>{_('CustomFilterHaveSomeChanges')}</label>}
				/>
			</Spin>
		</Modal>
	);
};

import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import cx from 'classnames';
import React, { useContext, useMemo } from 'react';
import { handleResponse } from '../../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../../api';
import { TimeZoneType } from '../../../../../../../model/DeviceAdminModel';
import { StoreContext, setErrors, setTimeZoneModal, setTimeZoneSelected } from '../../context';
import styles from './timeZonesDropdown.module.scss';

const TimeZonesDropdown: React.FC = () => {
	const {
		readerState: {
			timeZones,
			errors: { controlZoneInput },
			timeZoneSelected,
		},
		dispatcher,
	} = useContext(StoreContext);

	const handleChangeControlZone = (controlZoneId: number) => {
		const currentControlZone = timeZones.find(x => x.GenericTimeZoneId === controlZoneId);
		const isNewTimeZone = currentControlZone.Type === TimeZoneType.New;

		if (isNewTimeZone) {
			deviceAdminApi.checkIfAnyTimeZoneIsNotLocked().then(res => {
				const isLocked = handleResponse(res);
				if (!isLocked) {
					dispatcher(setTimeZoneModal(true));
					dispatcher(setTimeZoneSelected(null));
				} else {
					const previousSelection = timeZones.find(x => x.GenericTimeZoneId === timeZoneSelected?.GenericTimeZoneId) ?? null;
					dispatcher(setTimeZoneSelected(previousSelection));
				}
			});
		} else {
			dispatcher(setTimeZoneSelected({ ...currentControlZone }));
			dispatcher(setErrors({ type: 'controlZoneInput', value: false }));
		}
	};

	const mainDivContainerId = 'DoorGroupTimeZoneDropdownContainer';
	const doorGroupTimeZonesModalDropdownId = 'doorGroupTimeZonesModalDropdown';
	const opts: DefaultOptionType[] = useMemo(
		() =>
			timeZones.map((tz, idx) => ({
				label: tz.Name,
				value: tz.GenericTimeZoneId,
				key: idx,
			})),
		[timeZones]
	);

	const filterOption = (value: string, opt: DefaultOptionType) => {
		if (opt?.label?.toString().toLowerCase().indexOf(value?.toLowerCase()) > -1) {
			return true;
		}
		return false;
	};

	return (
		<div className={styles.container} id={mainDivContainerId}>
			<label htmlFor={doorGroupTimeZonesModalDropdownId}>{_('TimeZones')}</label>
			<div className={cx({ [styles.error]: controlZoneInput })}>
				<Select
					id={doorGroupTimeZonesModalDropdownId}
					onChange={handleChangeControlZone}
					value={timeZoneSelected?.GenericTimeZoneId ?? ''}
					filterOption={filterOption}
					showSearch
					options={opts}
					getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
				/>
				{controlZoneInput && <label htmlFor={doorGroupTimeZonesModalDropdownId}>{_('TimeZoneIsRequired')}</label>}
			</div>
		</div>
	);
};

export { TimeZonesDropdown };

import React, { useContext } from 'react';
import { Select } from '../../../../../../common';
import styles from '../../commandSetsModal.module.scss';
import { setFunction, StoreContext } from '../../contextCommand';

const Function: React.FC = () => {
	const {
		commandState: {
			functions,
			selections: { functionName },
		},
		dispatcher,
	} = useContext(StoreContext);

	const mainDivContainerId = 'CommandSetFunctionContainer';

	return (
		<div className={styles.sections} id={mainDivContainerId}>
			<label htmlFor='commandSetFunctionDropdown' id='commandSetFunctionLabel'>
				{_('Function')}
			</label>
			<div>
				<Select
					id='commandSetFunctionDropdown'
					className={styles.select}
					options={functions.map(x => ({ label: x.Item2, value: x.Item1.toString() }))}
					onChange={value => dispatcher(setFunction(Number(value)))}
					key='function'
					value={functionName?.toString()}
					defaultValue='Access'
					getPopupContainer={() => document.getElementById(mainDivContainerId)}
				/>
			</div>
		</div>
	);
};

export { Function };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { setAlert, setDisableCredential, setTag, StoreContext } from '../../context';
import styles from './TagsCheckBoxes.module.scss';

const TagsCheckBoxes: React.FC = () => {
	const {
		readerState: { alert, tag, disableCredential },
		dispatcher,
	} = useContext(StoreContext);

	const handleCheckTag = (e: CheckboxChangeEvent) => {
		dispatcher(setTag(e.target.checked));
	};
	const handleCheckAlert = (e: CheckboxChangeEvent) => {
		dispatcher(setAlert(e.target.checked));
	};
	const handleCheckDisableCredential = (e: CheckboxChangeEvent) => {
		dispatcher(setDisableCredential(e.target.checked));
	};

	const doorGroupModalTagCheckboxId = 'doorGroupModalTagCheckbox';
	const doorGroupModalAlertCheckboxId = 'doorGroupModalAlertCheckbox';
	const doorGroupModalDisableCredentialCheckboxId = 'doorGroupModalDisableCredentialCheckbox';

	return (
		<div className={styles.container}>
			<Checkbox id={doorGroupModalTagCheckboxId} onChange={handleCheckTag} checked={tag}>
				<label htmlFor={doorGroupModalTagCheckboxId}>{_('Tag')}</label>
			</Checkbox>
			<Checkbox id={doorGroupModalAlertCheckboxId} onChange={handleCheckAlert} checked={alert}>
				<label htmlFor={doorGroupModalAlertCheckboxId}>{_('Alert')}</label>
			</Checkbox>
			<Checkbox id={doorGroupModalDisableCredentialCheckboxId} onChange={handleCheckDisableCredential} checked={disableCredential}>
				<label htmlFor={doorGroupModalDisableCredentialCheckboxId}> {_('Disable')}</label>
			</Checkbox>
		</div>
	);
};

export { TagsCheckBoxes };

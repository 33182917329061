import { MenuOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React, { CSSProperties, useMemo } from 'react';
import { getPermissionErrorMessage } from '../../../model/AccountModel';
import { SelectOptions } from '../../../model/CommonModel';
import { UserDefinedFieldDetailModel, UserDefinedFieldType } from '../../../model/UserDefinedFields';
import { AutoCompleteIcon, DatePicker, Select, SelectOption } from '../../common';

const maxWidthStyle: CSSProperties = { width: '100%' };

type Props = {
	udfData: UserDefinedFieldDetailModel;
	disabled: boolean;
	useInitialValue?: boolean;
	isPersonInformation?: boolean;
	usePlaceHolder?: boolean;
	dateFormat: string;
};

const UDFField: React.FC<Props> = ({ udfData, disabled, useInitialValue, isPersonInformation, dateFormat, usePlaceHolder }) => {
	const getPopupContainer = isPersonInformation ? undefined : (element: HTMLElement) => element.parentElement;
	const onBlur = (event: React.FocusEvent<HTMLElement>) => {
		const parentUdfFieldsContainer = event.target.closest('div[class*="udfFieldsContainer"]');
		parentUdfFieldsContainer.setAttribute('style', 'overflow: auto');
	};

	const onFocus = (event: React.FocusEvent<HTMLElement>) => {
		const parentUdfFieldsContainer = event.target.closest('div[class*="udfFieldsContainer"]');
		parentUdfFieldsContainer.setAttribute('style', 'overflow: hidden');
	};

	const style: React.CSSProperties = useMemo(() => ({ color: `#${udfData.Color}`, height: 'auto' }), [udfData.Color]);
	const udfFieldId: string = `udfField-${udfData.FieldNumber},${udfData.Type}`;
	const disableField: boolean = disabled || (udfData.IsPIVField && udfData.Value !== '') || (isPersonInformation && useInitialValue);

	const renderContent = () => {
		let content: React.ReactElement = <></>;

		switch (udfData.Type) {
			case UserDefinedFieldType.Text:
			case UserDefinedFieldType.UniqueText:
			case UserDefinedFieldType.UUID: {
				const maxLength: number = udfData.Type === UserDefinedFieldType.UUID ? 32 : 50;
				content = <Input id={udfFieldId} disabled={disableField} maxLength={maxLength} title={getPermissionErrorMessage(!disabled)} />;

				break;
			}

			case UserDefinedFieldType.DropDownList:
			case UserDefinedFieldType.DropDownListAsc:
			case UserDefinedFieldType.DropDownListDsc: {
				const options: SelectOption = udfData.ListDefaults.map<SelectOptions<string>>(x => ({ label: x, value: x }));

				content = (
					<Select
						disabled={disableField}
						id={udfFieldId}
						onBlur={onBlur}
						onFocus={onFocus}
						options={options}
						showSearch
						title={getPermissionErrorMessage(!disabled)}
					/>
				);

				break;
			}

			case UserDefinedFieldType.DropDown:
			case UserDefinedFieldType.DropDownDsc:
			case UserDefinedFieldType.DropDownAsc: {
				const options = udfData.ListDefaults.map(x => ({ value: x }));

				content = (
					<AutoCompleteIcon
						id={udfFieldId}
						disabled={disableField}
						options={options}
						maxLength={50}
						onBlur={onBlur}
						onFocus={onFocus}
						openIcon={<span />}
						closedIcon={<MenuOutlined />}
						title={getPermissionErrorMessage(!disabled)}
					/>
				);

				break;
			}

			case UserDefinedFieldType.AutoUniqueNumber:
			case UserDefinedFieldType.AutoSequentialNumber:
			case UserDefinedFieldType.UniqueNumber:
			case UserDefinedFieldType.Number: {
				let placeholder: string = '';
				if (usePlaceHolder && udfData.Type === UserDefinedFieldType.AutoUniqueNumber) {
					placeholder = `<${_('AutoUnique')}>`;
				} else if (usePlaceHolder && udfData.Type === UserDefinedFieldType.AutoSequentialNumber) {
					placeholder = `<${_('AutoSequential')}>`;
				}

				content = (
					<Input
						id={udfFieldId}
						maxLength={18}
						disabled={disableField}
						title={getPermissionErrorMessage(!disabled)}
						style={maxWidthStyle}
						placeholder={placeholder}
					/>
				);

				break;
			}

			case UserDefinedFieldType.Date: {
				if (disabled) {
					const inputDatePicker: HTMLInputElement = document.getElementById(udfFieldId) as HTMLInputElement;
					if (inputDatePicker) {
						inputDatePicker.title = getPermissionErrorMessage(!disabled);
					}
				}

				content = (
					<DatePicker id={udfFieldId} disabled={disableField} format={dateFormat} style={maxWidthStyle} getPopupContainer={getPopupContainer} />
				);

				break;
			}
		}
		return content;
	};

	return (
		<Form.Item
			name={udfFieldId}
			htmlFor={udfFieldId}
			label={<span style={style}>{udfData.Caption}</span>}
			initialValue={useInitialValue ? udfData.Value : ''}>
			{renderContent()}
		</Form.Item>
	);
};

export { UDFField };

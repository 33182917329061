import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Form, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { CommandInput } from '..';
import { deviceAdminApi } from '../../../../../../../api';
import { buildColumn, ColumnsProps, ScrollType } from '../../../../../../../Helper';
import { CommandRow, VelocityDevice } from '../../../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../../../common';
import { EditableCell, EditableCellProps } from '../../../../../../common/EditableCell/EditableCell';
import styles from '../../commandSetsModal.module.scss';
import { setCommandByIndex, setCommands, setEditionKey, StoreContext } from '../../contextCommand';

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 294 };
const maxLengthController: number = 132;

const isEditing = (record: CommandRow, editionCommandSetKey: React.Key) => record.key === editionCommandSetKey;
const mergedColumns = (columns: ColumnsProps<CommandRow>[], editionCommandSetKey: React.Key) =>
	columns.map(col =>
		!col.editable
			? col
			: {
					...col,
					onCell: (record: CommandRow) => ({
						record,
						inputType: 'text',
						dataIndex: col.dataIndex,
						title: col.title,
						editing: isEditing(record, editionCommandSetKey),
					}),
			  }
	);
const formFieldName = 'command';
const customComponent = (
	<Form.Item name={formFieldName}>
		<CommandInput controlledComponent inputId='commandSetCommandsTableEditInput' />
	</Form.Item>
);

const CommandsGrid: React.FC = () => {
	const [commandState, setCommandState] = useState([] as CommandRow[]);
	const [controllers, setControllers] = useState([] as VelocityDevice[]);
	const [form] = Form.useForm();

	const {
		commandState: { commands, editionCommandSetKey },
		dispatcher,
	} = useContext(StoreContext);

	useEffect(() => {
		deviceAdminApi.getAllControllers().then(res => {
			setControllers(res);
		});
	}, []);

	useEffect(() => {
		setCommandState(commands.map(x => ({ ...x, controllerName: controllers.find(w => w.Id === x.controller)?.Name })));
	}, [JSON.stringify(commands)]);

	const columns: ColumnsProps<CommandRow>[] = [
		{
			...buildColumn(_('Controller'), 'controllerName', '105px', 'start'),
			...SearchColumn({ maxLength: maxLengthController, dataIndex: 'controllerName', reset: undefined, label: _('Controller') }),
		},
		{
			...buildColumn(_('Command'), 'command', '100px', 'start'),
			editable: true,
		},
		{
			...buildColumn(_('Action'), 'Action', '100px', 'start'),
			render: (rest, record) => {
				const editable = isEditing(record, editionCommandSetKey);
				const style: React.CSSProperties = { marginTop: -20 };
				return editable ? (
					<div className={styles.marginButtons} style={style}>
						<Button id='commandSetCommandsTableSaveButton' key='save' type='primary' onClick={() => handleSaveEditedRow(record.key)}>
							{_('Save')}
						</Button>
						<Button id='commandSetCommandsTableCancelButton' key='cancel' onClick={() => handleCancelEditedRow()}>
							{_('Cancel')}
						</Button>
					</div>
				) : (
					<div className={styles.marginButtons}>
						<Button
							id='commandSetCommandsTableEditButton'
							key='edit'
							type='primary'
							onClick={() => handleEditRow(record.key, record.command)}
							disabled={record.key !== editionCommandSetKey && editionCommandSetKey !== -1}>
							<EditOutlined /> {_('Edit')}
						</Button>
						<Button
							id='commandSetCommandsTableRemoveButton'
							key='remove'
							type='primary'
							onClick={() => handleRemoveCommand(record.key)}
							disabled={record.key !== editionCommandSetKey && editionCommandSetKey !== -1}>
							<MinusOutlined /> {_('Remove')}
						</Button>
					</div>
				);
			},
		},
	];

	const handleRemoveCommand = (key: React.Key) => {
		const cloneState = commands;
		const currentToDelete = cloneState.findIndex(x => x.key === key);
		cloneState.splice(currentToDelete, 1);
		dispatcher(setCommands([...cloneState]));
	};

	const handleEditRow = (key: React.Key, value: string) => {
		form.setFields([
			{
				name: formFieldName,
				value,
			},
		]);
		dispatcher(setEditionKey(key));
	};

	const handleSaveEditedRow = (key: React.Key) => {
		const commandValue: string = form.getFieldValue(formFieldName);
		const replaceCommand = [...commandState];
		const currentToReplace = replaceCommand.find(x => x.key === key);
		const command = commandValue;
		if (command === '') {
			form.setFields([
				{
					name: formFieldName,
					errors: [_('CommandIsRequired')],
				},
			]);
			return;
		}
		const maxLength = 4000;
		let commandAssign: string;
		if (command.length === maxLength) {
			commandAssign = `${command.slice(0, -1)}#`;
		} else {
			commandAssign = command[command.length - 1] !== '#' ? `${command}#` : command;
		}
		currentToReplace.command = commandAssign;
		setCommandState([...replaceCommand]);
		dispatcher(setEditionKey(-1));
		dispatcher(setCommandByIndex({ key, value: commandAssign }));
	};

	const handleCancelEditedRow = () => {
		dispatcher(setEditionKey(-1));
	};

	return (
		<Form form={form} component={false}>
			<Table
				columns={mergedColumns(columns, editionCommandSetKey) as ColumnsType}
				components={{
					body: {
						cell: (props: EditableCellProps<CommandRow>) =>
							EditableCell({
								...props,
								maxLength: 4000,
								customComponent,
							}),
					},
				}}
				scroll={scroll}
				dataSource={commandState}
				size='small'
				className={styles.statusWidgetTable}
				id='commandSetCommandsTable'
				pagination={false}
				onHeaderRow={() => ({ id: 'commandSetCommandsTableHeader' })}
				getPopupContainer={() => document.getElementById('commandSetCommandsTableHeader')}
			/>
		</Form>
	);
};

export { CommandsGrid };

import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { PortNetWorkType } from '../../../../../../../../../model/DeviceAdminModel';
import { IPv4AddressInput } from '../../../../../../../../common/IPv4AddressInput/IPv4AddressInput';
import { PortStoreContext, setValueSNIBSelectedAction } from '../../../../../contextPort';
import styles from '../../snibConfiguration.module.scss';

type Props = {
	isSubnetMaskInvalid: boolean;
	onResetSubnetMaskError: () => void;
};

const SubnetMask: React.FC<Props> = ({ isSubnetMaskInvalid, onResetSubnetMaskError }) => {
	const {
		portState: { snibSelected, networkType },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleSubnetMaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'SubnetMask', value: value }));
		onResetSubnetMaskError();
	};

	const handleIPv6PrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'IPv6Prefix', value: value }));
		onResetSubnetMaskError();
	};

	const searchSNIBConfigurationSubnetMaskInputId = 'searchSNIBConfigurationSubnetMaskInput';

	const subnetMaskInput = (
		<div className={styles.row}>
			<label id='searchSNIBConfigurationSubnetMaskLabel'>{_('SubnetMask')}</label>
			<div className={styles.inputContainer}>
				<IPv4AddressInput
					id={searchSNIBConfigurationSubnetMaskInputId}
					key='subnetMask'
					addressValidation={isSubnetMaskInvalid}
					value={snibSelected?.SubnetMask}
					defaultValue={'0.0.0.0'}
					onChange={handleSubnetMaskChange}
					disabled={snibSelected?.DHCPEnabled === 'Yes'}
				/>
				{isSubnetMaskInvalid && (
					<label id='searchSNIBConfigurationSubnetMaskErrorLabel' htmlFor={searchSNIBConfigurationSubnetMaskInputId} className={styles.errorMessage}>
						{_('SubnetMaskIsRequired')}
					</label>
				)}
			</div>
		</div>
	);

	const searchSNIBConfigurationSubnetPrefixInputId = 'searchSNIBConfigurationSubnetPrefixInput';

	const subnetPrefixInput = (
		<div className={styles.row}>
			<label id='searchSNIBConfigurationSubnetPrefixLabel'>{_('SubnetPrefixLength')}</label>
			<div className={styles.inputContainer}>
				<Input
					id={searchSNIBConfigurationSubnetPrefixInputId}
					key='subnetPrefix'
					value={snibSelected?.IPv6Prefix}
					className={cx({
						[styles.error]: isSubnetMaskInvalid,
					})}
					onChange={handleIPv6PrefixChange}
					maxLength={5}
					disabled={snibSelected?.DHCPEnabled === 'Yes'}
				/>
				{isSubnetMaskInvalid && (
					<label
						id='searchSNIBConfigurationSubnetPrefixErrorLabel'
						htmlFor={searchSNIBConfigurationSubnetPrefixInputId}
						className={styles.errorMessage}>
						{_('SubnetPrefixIsRequired')}
					</label>
				)}
			</div>
		</div>
	);

	return <div>{networkType === PortNetWorkType.IPv6 ? subnetPrefixInput : subnetMaskInput}</div>;
};

export { SubnetMask };

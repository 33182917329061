import React from 'react';
import styles from './headerBar.module.scss';

type Props = {
	icon?: React.ReactNode;
	title: string;
	titleElement?: React.ReactNode;
	buttons?: React.ReactNode;
};

const HeaderBar: React.FC<Props> = ({ icon, title, titleElement, buttons }) => {
	return (
		<div className={styles.headerBar}>
			<div>
				{icon && icon}
				<h6>{title}</h6>
				{titleElement && titleElement}
			</div>
			{buttons && buttons}
		</div>
	);
};

export { HeaderBar };

import { Divider, FormInstance } from 'antd';
import React from 'react';
import { SubPermissions, User } from '../../../../../../../model/AccountModel';
import { PersonDetailModel } from '../../../../../../../model/EnrollmentModel';
import { UserDefinedFieldDetailModel } from '../../../../../../../model/UserDefinedFields';
import { PersonImages, UDFField } from '../../../../../../common';
import styles from './udfTable.module.scss';

const user: User = getUser();
const udfEditPermissions: boolean[] = [
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditGeneralTabUDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab1UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab2UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab3UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab4UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab5UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab6UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab7UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab8UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab9UDFs).allowed,
	User.getSubComponentPermission(user, SubPermissions.Enrollment_Person_EditTab10UDFs).allowed,
];

type Props = {
	form: FormInstance<PersonDetailModel>;
	udfInfo: UserDefinedFieldDetailModel[];
	tabId: number;
	dateFormat: string;
	addMode: boolean;
};

const UDFTable: React.FC<Props> = ({ form, udfInfo, tabId, dateFormat, addMode }) => {
	return (
		<div className={styles.container}>
			<PersonImages form={form} tabId={tabId} />
			<div>
				<div className={styles.udfFieldsHeader}>
					<div>{_('UDF')}</div>
					<div>{_('Caption')}</div>
					<div>{_('Value')}</div>
				</div>
				<Divider className={styles.divider} />
				<div className={styles.udfFieldsContainer}>
					{udfInfo.map((udfData: UserDefinedFieldDetailModel) => (
						<div key={udfData.FieldNumber}>
							<div className={styles.udfNumber}>{udfData.FieldNumber}</div>
							<UDFField udfData={udfData} disabled={!udfEditPermissions[tabId]} dateFormat={dateFormat} usePlaceHolder={addMode} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export { UDFTable };

import { getCurrentEventFilterPage } from '../Helper';
import { ActiveAlarm, AlarmModel } from '../model/AlarmModel';
import { ResponseObject, ResponseObjectEntity } from '../model/CommonModel';
import { ColumnConfiguration } from '../model/ConfigurationModel';
import { ObjectTypes } from '../model/DeviceAdminModel';
import {
	CustomEventConfiguration,
	CustomFilterGroup,
	EventConfiguration,
	EventCustomFilterGroup,
	EventFilter,
	EventFilterPage,
	EventFilterTypes,
	EventListResponseModel,
	EventSummary,
	LiveEvents,
} from '../model/EventModel';
import { Api } from './api';

class EventApi extends Api {
	public constructor() {
		super();
	}

	public getEventsColumnsConfiguration = async (): Promise<ColumnConfiguration> => {
		const url = this.getUrl('Events/GetEventsColumnsConfiguration', true);

		return await this.post<ColumnConfiguration>(url);
	};

	public retrieveLiveEventSummary = async (): Promise<LiveEvents> => {
		const url = this.getUrl('Events/RetrieveLiveEventSummary', true);

		return await this.post<LiveEvents>(url);
	};

	public retrieveEventSummary = async (): Promise<ResponseObjectEntity<EventSummary>> => {
		const url = this.getUrl('Events/RetrieveEventSummary', true);

		return await this.post<ResponseObjectEntity<EventSummary>>(url);
	};

	public retrieveEventFilterConfiguration = async (): Promise<ResponseObjectEntity<EventConfiguration>> => {
		const eventFilterPage: EventFilterPage = getCurrentEventFilterPage();
		const url = this.getUrl('Events/RetrieveEventFilterConfiguration', true);
		const data = {
			eventFilterPage,
		};

		return await this.post<ResponseObjectEntity<EventConfiguration>>(url, data);
	};

	public retrieveCustomEventConfiguration = async (): Promise<ResponseObjectEntity<CustomEventConfiguration>> => {
		const eventFilterPage: EventFilterPage = getCurrentEventFilterPage();
		const url = this.getUrl('Events/RetrieveEventCustomFilter', true);
		const data = {
			eventFilterPage,
		};

		return await this.post<ResponseObjectEntity<CustomEventConfiguration>>(url, data);
	};

	public retrieveFilterGroupById = async (filterGroupId: number): Promise<EventCustomFilterGroup> => {
		const url = this.getUrl('Events/RetrieveFilterGroupById', true);
		const data = {
			filterGroupId,
		};

		return await this.post<EventCustomFilterGroup>(url, data);
	};

	public addFilterGroup = async (eventCustomFilterGroup: Partial<EventCustomFilterGroup>): Promise<ResponseObjectEntity<CustomFilterGroup>> => {
		const url = this.getUrl('Events/AddFilterGroup', true);
		const data = {
			eventCustomFilterGroup,
		};

		return await this.post<ResponseObjectEntity<CustomFilterGroup>>(url, data);
	};

	public applyFilter = async (filterGroupId): Promise<ResponseObject> => {
		const eventFilterPage: EventFilterPage = getCurrentEventFilterPage();
		const url = this.getUrl('Events/ApplyFilterGroup', true);
		const data = {
			eventFilterPage,
			filterGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public renameFilterGroup = async (FilterGroupId, FilterGroupName): Promise<ResponseObject> => {
		const url = this.getUrl('Events/RenameFilterGroup', true);
		const data = {
			FilterGroupId,
			FilterGroupName,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public deleteFilterGroup = async (filterGroupId): Promise<ResponseObject> => {
		const url = this.getUrl('Events/DeleteFilterGroup', true);
		const data = {
			filterGroupId,
		};

		return await this.post<ResponseObject>(url, data);
	};

	public retrieveStoredEvents = async (pageNumber: number, pageSize: number, eventFiltersType: EventFilterTypes[]): Promise<EventListResponseModel> => {
		const eventFilterPage: EventFilterPage = getCurrentEventFilterPage();
		const url = this.getUrl('Events/RetrieveStoredEvents', true);
		const data = {
			pageNumber,
			pageSize,
			eventFilterPage,
			eventFiltersType,
		};

		return await this.post<EventListResponseModel>(url, data);
	};

	public saveEventFilters = async (customFiltersIds: number[], eventFilterTypes: EventFilterTypes[]): Promise<ResponseObjectEntity<EventFilter[]>> => {
		const eventFilterPage: EventFilterPage = getCurrentEventFilterPage();
		const url = this.getUrl('Events/SaveEventFilters', true);
		const data = {
			eventFilterPage,
			customFiltersIds,
			eventFilterTypes,
		};

		return await this.post<ResponseObjectEntity<EventFilter[]>>(url, data);
	};

	public retrieveAlarmsByIds = async (alarmIds: number[]): Promise<ResponseObjectEntity<ActiveAlarm[]>> => {
		const url = this.getUrl('Events/RetriveAlarmsByIds', true);
		const data = {
			alarmIds,
		};
		return await this.post<ResponseObjectEntity<ActiveAlarm[]>>(url, data);
	};

	public retrieveActiveAlarmsByAddress = async (
		address: string,
		objectType: ObjectTypes,
		isAcknowledged: boolean
	): Promise<ResponseObjectEntity<AlarmModel[]>> => {
		const url = this.getUrl('Events/RetrieveActiveAlarmsByAddress', true);
		const data = {
			address,
			objectType,
			isAcknowledged,
		};

		return await this.post<ResponseObjectEntity<AlarmModel[]>>(url, data);
	};
}

const eventApi = new EventApi();

export { eventApi };

import { Form, Input } from 'antd';
import React from 'react';
import { PersonFormModel } from '../../../../../model/EnrollmentModel';

const personFormObject: PersonFormModel = new PersonFormModel();
const { ProfilePictureBase64, ProfileSignatureBase64, PersonGroups, PersonGroupModified, UploadedImages, AdditionalNotes, CertificatesData } =
	personFormObject.FormKeysObject;

const HiddenFormItems: React.FC = () => {
	return (
		<>
			<Form.Item name={PersonGroups} hidden={true}>
				<Input id='personInformationGroups' multiple aria-label={_('PersonGroups')} />
			</Form.Item>
			<Form.Item name={PersonGroupModified} hidden={true}>
				<Input id='personInformationGroupsModified' aria-label={_('Groups')} />
			</Form.Item>
			<Form.Item name={ProfilePictureBase64} hidden={true}>
				<Input id='personInformationPicture' aria-label={_('PersonPhoto')} />
			</Form.Item>
			<Form.Item name={ProfileSignatureBase64} hidden={true}>
				<Input id='personInformationSignature' aria-label={_('PersonSignature')} />
			</Form.Item>
			<Form.Item name={UploadedImages} hidden={true}>
				<Input id='personInformationImages' multiple aria-label={_('AdditionalImages')} />
			</Form.Item>
			<Form.Item name={AdditionalNotes} hidden={true}>
				<Input id='personInformationAdditionalNotes' multiple aria-label={_('AdditionalNotes')} />
			</Form.Item>
			<Form.Item name={CertificatesData} hidden={true}>
				<Input id='personInformationCertificates' multiple aria-label={_('PIVCertificates')} />
			</Form.Item>
		</>
	);
};

export { HiddenFormItems };

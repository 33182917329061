import React from 'react';
import { batch } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { handleResponse } from '../../Helper';
import { enrollmentApi } from '../../api';
import { PaginationSetting, ResponseObjectEntity, ResponseStatusCode, SortDirections, SorterConfigEx } from '../../model/CommonModel';
import {
	AdvanceSearchCriteria,
	EnrollmentBlankImages,
	EnrollmentGridItem,
	EnrollmentTabInfo,
	PersonDetailModel,
	PersonTableSetActionInfo,
	TreeViewPersonGroupModel,
} from '../../model/EnrollmentModel';
import { Logger } from '../../model/LoggingModel';
import { EnrollmentActionTypes } from './actionTypes';
import { EnrollmentState } from './reducers';

export interface FetchingScanDataAction {
	type: EnrollmentActionTypes.FETCHING_SCAN_DATA;
	payload: boolean;
}

export interface DisplayAddViaSmartCard {
	type: EnrollmentActionTypes.DISPLAY_ADD_VIA_SMART_CARD;
	payload: boolean;
}

export interface SetEnrollmentTabInfo {
	type: EnrollmentActionTypes.SET_ENROLLMENT_TAB_INFO;
	payload: EnrollmentTabInfo;
}

export interface SetSelectedPersonGroup {
	type: EnrollmentActionTypes.SET_SELECTED_PERSON_GROUP;
	payload: TreeViewPersonGroupModel;
}

export interface SetPersonTableInformationByPage {
	type: EnrollmentActionTypes.SET_PERSON_TABLE_INFORMATION_BY_PAGE;
	payload: PersonTableSetActionInfo;
}

export interface SetIsPersonTableLoading {
	type: EnrollmentActionTypes.SET_IS_PERSON_TABLE_LOADING;
	payload: boolean;
}

export interface SetSelectedRowKeys {
	type: EnrollmentActionTypes.SET_SELECTED_ROW_KEYS;
	payload: React.Key[];
}

export interface SetKeywordSearchText {
	type: EnrollmentActionTypes.SET_KEYWORD_SEARCH_TEXT;
	payload: string;
}

export interface SetPersonTablePaginationSettings {
	type: EnrollmentActionTypes.SET_PERSON_TABLE_PAGINATION_SETTINGS;
	payload: PaginationSetting;
}

export interface SetUpdateCounter {
	type: EnrollmentActionTypes.SET_UPDATE_COUNTER;
}

export interface SetActiveAdvancedSearch {
	type: EnrollmentActionTypes.SET_ACTIVE_ADVANCED_SEARCH;
	payload: AdvanceSearchCriteria;
}

export interface ClearAdvancedSearch {
	type: EnrollmentActionTypes.CLEAR_ADVANCED_SEARCH;
}

export interface SetPersonInformationId {
	type: EnrollmentActionTypes.SET_PERSON_INFORMATION_ID;
	payload: number;
}

export interface SetPersonInformationData {
	type: EnrollmentActionTypes.SET_PERSON_INFORMATION_DATA;
	payload: PersonDetailModel;
}

export interface SetIsEnrollmentTabLoading {
	type: EnrollmentActionTypes.SET_IS_ENROLLMENT_TAB_LOADING;
	payload: boolean;
}

export interface SetPersonTableSorterConfig {
	type: EnrollmentActionTypes.SET_PERSON_TABLE_SORTER_CONFIG;
	payload: SorterConfigEx;
}

export interface SetHavePersonChanged {
	type: EnrollmentActionTypes.SET_HAVE_PERSON_CHANGED;
	payload: boolean;
}

export interface SetEnrollmentBlankImages {
	type: EnrollmentActionTypes.SET_ENROLLMENT_BLANK_IMAGES;
	payload: EnrollmentBlankImages;
}

//Union Action Types
export type EnrollmentAction =
	| FetchingScanDataAction
	| DisplayAddViaSmartCard
	| SetEnrollmentTabInfo
	| SetSelectedPersonGroup
	| SetPersonTableInformationByPage
	| SetIsPersonTableLoading
	| SetSelectedRowKeys
	| SetKeywordSearchText
	| SetPersonTablePaginationSettings
	| SetUpdateCounter
	| SetActiveAdvancedSearch
	| SetPersonInformationId
	| SetPersonInformationData
	| SetIsEnrollmentTabLoading
	| SetPersonTableSorterConfig
	| SetHavePersonChanged
	| ClearAdvancedSearch
	| SetEnrollmentBlankImages;

//Action Creators
const setPersonTableInformationByPageAction = (payload: PersonTableSetActionInfo): SetPersonTableInformationByPage => {
	return {
		type: EnrollmentActionTypes.SET_PERSON_TABLE_INFORMATION_BY_PAGE,
		payload,
	};
};

//Export Action
export const setFetchingScanData = (payload: boolean): FetchingScanDataAction => {
	return {
		type: EnrollmentActionTypes.FETCHING_SCAN_DATA,
		payload,
	};
};

export const setDisplayAddViaSmartCard = (payload: boolean): DisplayAddViaSmartCard => {
	return {
		type: EnrollmentActionTypes.DISPLAY_ADD_VIA_SMART_CARD,
		payload,
	};
};

export const setEnrollmentTabInfo = (payload: EnrollmentTabInfo): SetEnrollmentTabInfo => {
	return {
		type: EnrollmentActionTypes.SET_ENROLLMENT_TAB_INFO,
		payload,
	};
};

export const setIsPersonTableLoading = (payload: boolean): SetIsPersonTableLoading => {
	return {
		type: EnrollmentActionTypes.SET_IS_PERSON_TABLE_LOADING,
		payload,
	};
};

export const setSelectedRowKeys = (payload: React.Key[]): SetSelectedRowKeys => {
	return {
		type: EnrollmentActionTypes.SET_SELECTED_ROW_KEYS,
		payload,
	};
};

export const setPersonTablePaginationSettings = (payload: PaginationSetting): SetPersonTablePaginationSettings => {
	return {
		type: EnrollmentActionTypes.SET_PERSON_TABLE_PAGINATION_SETTINGS,
		payload,
	};
};

export const setUpdateCounter = (): SetUpdateCounter => {
	return {
		type: EnrollmentActionTypes.SET_UPDATE_COUNTER,
	};
};

export const clearAdvancedSearch = (): ClearAdvancedSearch => {
	return {
		type: EnrollmentActionTypes.CLEAR_ADVANCED_SEARCH,
	};
};

export const setKeywordSearchText = (payload: string): SetKeywordSearchText => {
	return {
		type: EnrollmentActionTypes.SET_KEYWORD_SEARCH_TEXT,
		payload,
	};
};

export const setSelectedPersonGroup = (payload: TreeViewPersonGroupModel): SetSelectedPersonGroup => {
	return {
		type: EnrollmentActionTypes.SET_SELECTED_PERSON_GROUP,
		payload,
	};
};

export const setActiveAdvancedSearch = (payload: AdvanceSearchCriteria): SetActiveAdvancedSearch => {
	return {
		type: EnrollmentActionTypes.SET_ACTIVE_ADVANCED_SEARCH,
		payload,
	};
};

export const setIsEnrollmentTabLoading = (payload: boolean): SetIsEnrollmentTabLoading => {
	return {
		type: EnrollmentActionTypes.SET_IS_ENROLLMENT_TAB_LOADING,
		payload,
	};
};

export const setPersonInformationData = (payload: PersonDetailModel): SetPersonInformationData => {
	return {
		type: EnrollmentActionTypes.SET_PERSON_INFORMATION_DATA,
		payload,
	};
};

export const setPersonInformationId = (payload: number): SetPersonInformationId => {
	return {
		type: EnrollmentActionTypes.SET_PERSON_INFORMATION_ID,
		payload,
	};
};

export const setPersonTableSorterConfig = (payload: SorterConfigEx): SetPersonTableSorterConfig => {
	return {
		type: EnrollmentActionTypes.SET_PERSON_TABLE_SORTER_CONFIG,
		payload,
	};
};

export const setHavePersonChanged = (payload: boolean): SetHavePersonChanged => {
	return {
		type: EnrollmentActionTypes.SET_HAVE_PERSON_CHANGED,
		payload,
	};
};

export const setEnrollmentBlankImages = (payload: EnrollmentBlankImages): SetEnrollmentBlankImages => {
	return {
		type: EnrollmentActionTypes.SET_ENROLLMENT_BLANK_IMAGES,
		payload,
	};
};

export const setPersonTableInformationByPage = (
	pagination: PaginationSetting
): ThunkAction<Promise<void>, { enrollmentReducer: EnrollmentState }, {}, EnrollmentAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, EnrollmentAction>, getState): Promise<void> => {
		try {
			const { activeAdvancedSearch, advanceSearchCriteria, selectedPersonGroup, updateCounter } = getState().enrollmentReducer;

			if (selectedPersonGroup) {
				dispatch(setIsPersonTableLoading(true));
				const FolderId = selectedPersonGroup.FolderId;
				const GroupId = selectedPersonGroup.GroupId;

				let personTableSetActionInfo: PersonTableSetActionInfo = {
					personInformation: undefined,
					paginationSettings: pagination,
					updateCounter: updateCounter,
				};

				const personInformationRequest: ResponseObjectEntity<EnrollmentGridItem> = activeAdvancedSearch
					? await enrollmentApi.getUsersByAdvancedSearch(advanceSearchCriteria, pagination)
					: await enrollmentApi.retrievePersonGroupsPagination(GroupId, FolderId, pagination);

				switch (personInformationRequest.ResponseStatusCode) {
					case ResponseStatusCode.Success:
						if (pagination.PageNumber > 1 && personInformationRequest.Entity.TotalItems === 0) {
							personTableSetActionInfo.updateCounter = updateCounter + 1;
							personTableSetActionInfo.paginationSettings = {
								...pagination,
								PageNumber: pagination.PageNumber - 1,
								SortDirection: SortDirections.None,
								SortField: '',
							};
						} else {
							personTableSetActionInfo.personInformation = personInformationRequest.Entity;
						}

						batch(() => {
							dispatch(setPersonTableInformationByPageAction(personTableSetActionInfo));
							dispatch(setIsPersonTableLoading(false));
						});
						break;

					default:
						handleResponse(personInformationRequest);
						batch(() => {
							dispatch(setPersonTableInformationByPageAction(personTableSetActionInfo));
							dispatch(setIsPersonTableLoading(false));
						});
						break;
				}
			}
		} catch (error) {
			dispatch(setIsPersonTableLoading(false));
			Logger.writeErrorLog(error);
		}
	};
};

export const loadPersonInformation = (
	personId: number,
	isTemplate: boolean = false
): ThunkAction<Promise<void>, { enrollmentReducer: EnrollmentState }, {}, EnrollmentAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, EnrollmentAction>): Promise<void> => {
		dispatch(setIsEnrollmentTabLoading(true));

		if (personId === -100) {
			batch(() => {
				dispatch(setPersonInformationId(personId));
				dispatch(setIsEnrollmentTabLoading(false));
			});
		} else {
			enrollmentApi
				.getPersonInformation(personId)
				.then(res => {
					if (res.ResponseStatusCode === ResponseStatusCode.Success) {
						if (isTemplate) {
							res.TemplateId = personId;
							res.IsTemplate = true;
						}
						batch(() => {
							dispatch(setPersonInformationData(res));
							dispatch(setPersonInformationId(res.PersonId));
							dispatch(setIsEnrollmentTabLoading(false));
						});
					} else {
						handleResponse({
							AdditionalResponseInfo: res.AdditionalResponseInfo,
							ErrorMessage: res.ErrorMessage,
							ResponseErrorDescription: res.ResponseErrorDescription,
							ResponseObjectId: res.ResponseObjectId,
							ResponseStatusCode: res.ResponseStatusCode,
						});
						dispatch(setIsEnrollmentTabLoading(false));
					}
				})
				.catch(e => {
					dispatch(setIsEnrollmentTabLoading(false));
					Logger.writeErrorLog(e);
				});
		}
	};
};

import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../../model/CommonModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderAssuranceLevel, setExitReaderRS485ReaderType } from '../../../exitReaderContext';
import styles from '../../General/general.module.scss';

const readerTypeSelectId: string = 'exitReaderReaderTypeSelect';

const ReaderType: React.FC = () => {
	const [rs485Types, setRs485Types] = useState<SelectOptions<string>[]>([]);
	const [readerType, setReaderType] = useState(undefined);

	var {
		contextStateDoor: {
			exitReader: {
				rS485ReaderTypes,
				isDoorWireless,
				selections: { rS485ReaderType },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	useEffect(() => {
		setRs485Types(rS485ReaderTypes.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [rS485ReaderTypes]);

	useEffect(() => {
		if (!isDoorWireless) {
			setReaderType(rS485ReaderType.toString());
		} else if (rS485ReaderTypes.length > 0) {
			setReaderType(rS485ReaderTypes[0].Id.toString());
		} else {
			setReaderType(undefined);
		}
	}, [rS485ReaderType]);

	const handleOnSetReaderType = (value: string) => {
		if (!isDoorWireless) {
			dispatcherDoor(setExitReaderAssuranceLevel(0));
		}
		dispatcherDoor(setExitReaderRS485ReaderType(Number(value)));
	};

	return (
		<div className={styles.readerType}>
			<label htmlFor={readerTypeSelectId}>{_('ReaderType')}</label>
			<div>
				<Select
					className={styles.select}
					options={rs485Types}
					onChange={handleOnSetReaderType}
					key='readerType'
					id={readerTypeSelectId}
					value={readerType}
					getPopupContainer={() => document.getElementById('generalExitReaderContainer')}
				/>
			</div>
		</div>
	);
};

export { ReaderType };

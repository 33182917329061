import { Tabs } from 'antd';
import React, { useState } from 'react';
import { getPermissionErrorMessage, SubPermissions, User } from '../../../../../../model/AccountModel';
import { useStoreSelector } from '../../../../../../store';
import styles from './controlZones.module.scss';
import { MasterControlZones } from './MasterControlZones/MasterControlZones';
import { selectorEditionMode } from './selectors';
import { StandardControlZones } from './StandarControlZones/StandardControlZones';

const { TabPane } = Tabs;
const canOpenTabs = () => {
	const user: User = getUser();
	return {
		standard: User.getSubComponentPermission(user, SubPermissions.ControlZoneEditor_ViewStandardControlZonesTab).allowed,
		master: User.getSubComponentPermission(user, SubPermissions.ControlZoneEditor_ViewMasterControlZonesTab).allowed,
	};
};

const tabActiveKey = (): string => {
	let activeKey: string = '1';
	if (canOpenTabs().standard) {
		activeKey = '1';
	} else if (canOpenTabs().master) {
		activeKey = '2';
	}

	return activeKey;
};

const ControlZones: React.FC = () => {
	const isEditionMode = useStoreSelector(selectorEditionMode);

	const [activeTab, setActiveTab] = useState(tabActiveKey());

	return (
		<div className={styles.container}>
			<Tabs activeKey={activeTab} onChange={setActiveTab}>
				<TabPane
					tab={<label title={getPermissionErrorMessage(canOpenTabs().standard)}>{_('StandardControlZones')}</label>}
					key='1'
					disabled={!canOpenTabs().standard || isEditionMode}>
					<StandardControlZones />
				</TabPane>
				<TabPane
					tab={<label title={getPermissionErrorMessage(canOpenTabs().master)}>{_('MasterControlZones')}</label>}
					key='2'
					disabled={!canOpenTabs().master || isEditionMode}>
					<MasterControlZones />
				</TabPane>
			</Tabs>
		</div>
	);
};

export { ControlZones };

import { SearchOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, List, Pagination, Radio, Row, Spin, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { handleResponse } from '../../../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../../../api';
import { SecuredComponents } from '../../../../../../../../model/AccountModel';
import {
	PaginationSetting,
	ResponseObject,
	ResponseObjectEntity,
	ResponseStatusCode,
	SelectOptions,
	SortDirections,
} from '../../../../../../../../model/CommonModel';
import {
	ActiveDirectoryUser,
	ActiveDirectoryUserFlags,
	ActiveDirectoryUserValidation,
	ActiveDirectoryUsersPaginated,
	OperatorCredential,
} from '../../../../../../../../model/OperatorModel';
import { Select } from '../../../../../../../common';
import { Modal } from '../../../../../../../common/Modal/Modal';
import { setOperatorAction } from '../../../OperatorContext/action';
import { OperatorStoreContext } from '../../../OperatorContext/context';
import { OperatorPermissionModal } from '../../../OperatorPermissionModal/OperatorPermissionModal';
import styles from './findUserActiveDirectoryModal.module.scss';

type FindUserActiveDirectoryModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
	setDomain: React.Dispatch<React.SetStateAction<string>>;
	initialDomain: string;
	authPath: string;
	domains: string[];
};
const pageSizeOptions: string[] = ['10', '25', '50', '100'];
const invalidNameError: string = _('InvalidName');
const nameDoesNotMatchError: string = _('NameDoesNotMatch');

const FindUserActiveDirectoryModal: React.FC<FindUserActiveDirectoryModalProps> = ({ onConfirm, onCancel, setDomain, initialDomain, authPath, domains }) => {
	const {
		operatorInitialState: { operator },
		dispatcher,
	} = useContext(OperatorStoreContext);
	const [loading, setLoading] = useState<boolean>(true);
	const [selectedDomain, setSelectedDomain] = useState<string>('');
	const [userName, setUserName] = useState<string>('');
	const [domainOptions, setDomainOptions] = useState<SelectOptions<string>[]>([]);
	const [isExcludeExistingUser, setIsExcludeExistingUser] = useState<boolean>(true);
	const [users, setUsers] = useState<ActiveDirectoryUser[]>([]);
	const [selectedUser, setSelectedUser] = useState<ActiveDirectoryUser | null>(null);
	const [paginationSetting, setPaginationSetting] = useState<PaginationSetting>({
		PageNumber: 1,
		PageSize: 25,
		SortDirection: SortDirections.Ascend,
		TotalItems: 0,
		SearchedValue: '',
	});
	const [isNameError, setIsNameError] = useState<boolean>(false);
	const [emptyNameError, setEmptyNameError] = useState<string>('');
	const [isEmptyNameError, setIsEmptyNameError] = useState<boolean>(false);
	const [isNameDoesNotMatchError, setIsNameDoesNotMatchError] = useState<boolean>(false);
	const [openPermissionModal, setOpenPermissionModal] = useState<boolean>(false);
	const [operationType, setOperationType] = useState<'SearchAll' | 'SearchOne' | 'SearchPage' | 'SelectUser' | undefined>(undefined);

	useEffect(() => {
		deviceAdminApi.getErrorMessages(SecuredComponents.Operator).then(response => setEmptyNameError(response.EmptyName));
		const mappedDomains = mapData(domains);
		batch(() => {
			setDomainOptions(mappedDomains);
			setSelectedDomain(authPath === initialDomain ? authPath : initialDomain);
			setLoading(false);
		});
	}, []);

	const mapData = (data: string[]): SelectOptions<string>[] => {
		if (data?.length > 0) {
			return data.map<SelectOptions<string>>((d: string) => ({
				id: d,
				label: d,
				value: d,
			}));
		}

		return [] as SelectOptions<string>[];
	};

	const getDefaultUser = (): ActiveDirectoryUser => {
		return {
			Id: 0,
			Name: '',
			Description: '',
			SamAccountName: '',
			Mail: '',
			GivenName: '',
			Surname: '',
			UserPrincipalName: '',
			DistinguishedName: '',
			Domain: '',
		};
	};

	const processUsers = (res: ResponseObjectEntity<ActiveDirectoryUsersPaginated>, pageSettings: PaginationSetting, keepSelectedUser: boolean = false) => {
		const users: ActiveDirectoryUser[] = res.Entity.Users;
		batch(() => {
			setUsers(users);
			setPaginationSetting({ ...pageSettings, TotalItems: res.Entity.TotalItemsPaginated });
			if (keepSelectedUser) {
				if (users?.some(user => user.SamAccountName === userName)) {
					const user: ActiveDirectoryUser = users.find(u => u.SamAccountName === userName);
					setSelectedUser(user);
					return;
				}
			}
			setSelectedUser(null);
		});
	};

	const getPaginatedUsers = async (
		pageSettings: PaginationSetting,
		keepSelectedUser: boolean = false,
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObject> => {
		const res = await deviceAdminApi.getUsersInDomain(selectedDomain, isExcludeExistingUser, pageSettings, elevatedCredentials);
		if (res.ResponseStatusCode === ResponseStatusCode.Success) {
			processUsers(res, pageSettings, keepSelectedUser);
		} else if (res.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			setPaginationSetting(pageSettings);
			setOpenPermissionModal(true);
		}
		return res;
	};

	const onHandleFindAllButtonClick = () => {
		setLoading(true);
		getPaginatedUsers({ ...paginationSetting, SearchedValue: '' })
			.then(res => {
				if (res.ResponseStatusCode !== ResponseStatusCode.PermissionError) {
					if (!handleResponse(res)) {
						setUserName('');
					}
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onHandleUserSelected = (user: ActiveDirectoryUser) => {
		batch(() => {
			setSelectedUser(user);
			setUserName(user.SamAccountName);
		});

		setInputErrors();
	};

	const loadUserContextProps = async (
		user: ActiveDirectoryUser,
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObject> => {
		const res: ResponseObjectEntity<ActiveDirectoryUserFlags> = await deviceAdminApi.getUserFlags(selectedDomain, user.SamAccountName, elevatedCredentials);
		if (res.ResponseStatusCode !== ResponseStatusCode.PermissionError) {
			if (!handleResponse(res)) {
				batch(() => {
					dispatcher(
						setOperatorAction({
							...operator,
							Name: user.SamAccountName,
							FullName: user.GivenName,
							Description: user.Description,
							DirectorySource: selectedDomain,
							Flags: res.Entity,
						})
					);
					setDomain(selectedDomain);
				});
			}
		}

		return res;
	};

	const confirmSelectedUser = async (
		elevatedCredentials: OperatorCredential = { UseCredentials: false, UserName: '', Domain: '', Password: '' }
	): Promise<ResponseObject> => {
		if (userName === '') {
			setInputErrors('Empty');
			return;
		}
		setOperationType('SelectUser');
		let responseObj: ResponseObject = undefined;

		if (selectedUser) {
			setUserName(userName.trim());
			if (selectedUser.SamAccountName.toLowerCase() !== userName.toLowerCase()) {
				setInputErrors('Match');
				return;
			}
			setInputErrors();
			setLoading(true);
			responseObj = await loadUserContextProps(selectedUser, elevatedCredentials);
			setLoading(false);
			if (responseObj.ResponseStatusCode === ResponseStatusCode.Success) {
				onConfirm();
			} else if (responseObj.ResponseStatusCode === ResponseStatusCode.PermissionError) {
				setOpenPermissionModal(true);
			}
		} else {
			setLoading(true);
			const resp: ResponseObjectEntity<ActiveDirectoryUserValidation> = await onHandleCheckUserNameInput(elevatedCredentials);
			responseObj = { ...resp };
			const entity = resp.Entity;
			if (entity.IsUserValid) {
				responseObj = await loadUserContextProps(entity.User, elevatedCredentials);
				if (resp.ResponseStatusCode === ResponseStatusCode.Success) {
					onConfirm();
				} else if (resp.ResponseStatusCode === ResponseStatusCode.PermissionError) {
					setOpenPermissionModal(true);
				}
			} else if (resp.ResponseStatusCode === ResponseStatusCode.PermissionError) {
				setOpenPermissionModal(true);
			}
			setLoading(false);
		}

		return responseObj;
	};

	const setInputErrors = (errorType: string = null) => {
		switch (errorType) {
			case 'Invalid':
				batch(() => {
					setIsNameDoesNotMatchError(false);
					setIsEmptyNameError(false);
					setIsNameError(true);
				});
				break;
			case 'Empty':
				batch(() => {
					setIsNameDoesNotMatchError(false);
					setIsNameError(false);
					setIsEmptyNameError(true);
				});
				break;
			case 'Match':
				batch(() => {
					setIsNameError(false);
					setIsEmptyNameError(false);
					setIsNameDoesNotMatchError(true);
				});
				break;
			default:
				batch(() => {
					setIsNameError(false);
					setIsEmptyNameError(false);
					setIsNameDoesNotMatchError(false);
				});
				break;
		}
	};

	const onHandleCheckUserNameInput = async (
		elevatedCredentials: OperatorCredential = { UseCredentials: false, Domain: '', UserName: '', Password: '' }
	): Promise<ResponseObjectEntity<ActiveDirectoryUserValidation>> => {
		let user: ResponseObjectEntity<ActiveDirectoryUserValidation> = {
			AdditionalResponseInfo: '',
			ResponseStatusCode: ResponseStatusCode.FailedValidation,
			ResponseErrorDescription: '',
			ErrorMessage: '',
			ResponseObjectId: 0,
			Entity: {
				IsUserValid: false,
				User: getDefaultUser(),
			},
		};

		if (userName === '') {
			setInputErrors('Empty');
			return user;
		}
		batch(() => {
			setUserName(userName.trim());
			setOperationType('SearchOne');
		});
		const res: ResponseObjectEntity<ActiveDirectoryUserValidation> = await deviceAdminApi.getUserInDomain(selectedDomain, userName, elevatedCredentials);
		user = {
			...res,
			Entity: user.Entity,
		};
		if (res.ResponseStatusCode !== ResponseStatusCode.PermissionError) {
			if (!handleResponse(res)) {
				if (res.Entity.IsUserValid) {
					batch(() => {
						setUsers([res.Entity.User]);
						setSelectedUser(res.Entity.User);
						setUserName(res.Entity.User.SamAccountName);
					});
					setInputErrors();
					user.Entity.IsUserValid = true;
					user.Entity.User = res.Entity.User;
				} else {
					setInputErrors('Invalid');
				}
			} else {
				setInputErrors();
			}
		}
		return user;
	};

	const onHandleListPaginationChanged = (page: number, pageSize: number) => {
		batch(() => {
			setLoading(true);
			setOperationType('SearchPage');
		});

		getPaginatedUsers({ ...paginationSetting, PageNumber: page, PageSize: pageSize }, true)
			.then(res => {
				if (res.ResponseStatusCode !== ResponseStatusCode.PermissionError) {
					handleResponse(res);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onHandleListShowSizeChanged = (currentPageSize: number, pageSize: number) => {
		batch(() => {
			setLoading(true);
			setOperationType('SearchPage');
		});
		getPaginatedUsers({ ...paginationSetting, PageSize: pageSize }, true)
			.then(res => {
				if (res.ResponseStatusCode !== ResponseStatusCode.PermissionError) {
					handleResponse(res);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onHandleSearchUser = () => {
		if (userName?.length > 0) {
			batch(() => {
				setLoading(true);
				setOperationType('SearchOne');
			});
			getPaginatedUsers({ ...paginationSetting, PageNumber: 1, SearchedValue: userName }, true)
				.then(res => {
					if (res.ResponseStatusCode !== ResponseStatusCode.PermissionError) {
						handleResponse(res);
					}
				})
				.finally(() => {
					batch(() => {
						setLoading(false);
						setIsEmptyNameError(false);
					});
				});
		}
	};

	const onHandlePermissionModalConfirm = () => {
		setOpenPermissionModal(false);
	};

	const onHandlePermissionModalCancel = () => {
		setOpenPermissionModal(false);
	};

	const onHandleElevatedOperation = async (elevatedCredentials: OperatorCredential): Promise<ResponseStatusCode> => {
		let response: ResponseObject;
		if (operationType === 'SelectUser') {
			response = await confirmSelectedUser(elevatedCredentials);
		}
		if (operationType === 'SearchAll' || operationType === 'SearchPage' || operationType === undefined) {
			response = await getPaginatedUsers(paginationSetting, true, elevatedCredentials);
		} else {
			response = await getPaginatedUsers({ ...paginationSetting, SearchedValue: userName }, true, elevatedCredentials);
		}

		return response.ResponseStatusCode;
	};

	const handleErrorNotification = (message: string = null, isError: boolean = true) => {
		notification[isError ? 'error' : 'info']({
			message: message,
		});
	};

	return (
		<>
			<Modal
				footer={[
					<Button id='selectActiveDirectoryUserConfirmButton' title={'Select user'} key='Select' type='primary' onClick={() => confirmSelectedUser()}>
						{_('Select')}
					</Button>,
					<Button id='selectActiveDirectoryUserCancel' key='cancel' onClick={onCancel}>
						{_('Cancel')}
					</Button>,
				]}
				visible
				title={_('Users')}
				onCancel={onCancel}
				width={700}
				customZoomClass={styles.WithModalZoom}>
				<Spin tip={`${_('Loading')}...`} spinning={loading} size='large'>
					<div className={styles.container}>
						<Row key='findActiveDirectoryModalR1'>
							<Col span={24}>
								<div className={styles.lookInSection}>
									<label id='windowsUserLookInLabel' htmlFor='windowsUserLookInSelect'>
										{_('LookIn')}
									</label>
									<Select
										id='windowsUserLookInSelect'
										value={selectedDomain}
										options={domainOptions}
										onChange={(e: string) => setSelectedDomain(e)}
										disabled={!selectedDomain}
									/>
									<Button className={styles.findButton} onClick={onHandleFindAllButtonClick}>
										<UnorderedListOutlined />
									</Button>
								</div>
							</Col>
						</Row>
						<Row key='findActiveDirectoryModalR2'>
							<Col span={24}>
								<div className={styles.informationSections}>
									<label id='windowsUserNameLabel' htmlFor='windowsUserNameInput'>
										{_('UserName')}
									</label>
									<div>
										<Input
											id='windowsUserNameInput'
											value={userName}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												setUserName(e.target.value);
											}}
											maxLength={100}
											className={cx(null, {
												[styles.error]: isNameError || isEmptyNameError,
											})}
											onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
												if (e.key === 'Enter' || e.key === 'Return') {
													onHandleSearchUser();
												}
											}}
										/>
										{isEmptyNameError && <p className={styles.errorText}>{emptyNameError}</p>}
										{isNameError && <p className={styles.errorText}>{invalidNameError}</p>}
										{isNameDoesNotMatchError && <p className={styles.errorText}>{nameDoesNotMatchError}</p>}
									</div>
									<Button className={styles.confirmButton} onClick={onHandleSearchUser} disabled={!selectedDomain || userName?.length === 0}>
										<SearchOutlined className={styles.findIcon} />
									</Button>
								</div>
							</Col>
						</Row>
						<Row key='findActiveDirectoryModalR5'>
							<Col span={24}>
								<div className={styles.findParametersSection}>
									<Checkbox
										className={styles.checkboxStyle}
										checked={isExcludeExistingUser}
										onChange={(e: CheckboxChangeEvent) => {
											setIsExcludeExistingUser(e.target.checked);
										}}>
										{_('ExcludeExistingUsers')}
									</Checkbox>
								</div>
							</Col>
						</Row>
						<Row key='findActiveDirectoryModalR3'>
							<Col span={24}>
								<div className={styles.listSection}>
									<List
										className={styles.listStyle}
										bordered
										size='small'
										dataSource={users}
										renderItem={user => (
											<List.Item
												className={cx(styles.listItemStyle, {
													[styles.listItemStyleSelected]:
														!selectedUser || !user ? false : selectedUser.SamAccountName === user.SamAccountName,
												})}>
												<Radio
													onChange={() => {
														onHandleUserSelected(user);
													}}
													onClick={() => {
														if (!selectedUser || !user ? false : selectedUser.SamAccountName === user.SamAccountName) {
															onHandleUserSelected(user);
														}
													}}
													checked={!selectedUser || !user ? false : selectedUser.SamAccountName === user.SamAccountName}>
													<div className={styles.listItemContainer}>
														<div className={styles.listItemNameText}>
															{user.Name}
															{user.UserPrincipalName && (
																<span className={styles.listItemUserPrincipalText}>{` (${user.UserPrincipalName})`}</span>
															)}
														</div>
														{user.Description && <div className={styles.listItemDescriptionText}>{user.Description}</div>}
													</div>
												</Radio>
											</List.Item>
										)}
									/>
								</div>
							</Col>
						</Row>
						<Row key='findActiveDirectoryModalR4'>
							<Col span={24}>
								<div className={styles.footerContainer}>
									<Pagination
										className={styles.paginationFooter}
										size='small'
										showSizeChanger={true}
										pageSizeOptions={pageSizeOptions}
										current={paginationSetting.PageNumber}
										pageSize={paginationSetting.PageSize}
										total={paginationSetting.TotalItems}
										onChange={onHandleListPaginationChanged}
										onShowSizeChange={onHandleListShowSizeChanged}
									/>
								</div>
							</Col>
						</Row>
					</div>
				</Spin>
			</Modal>
			{openPermissionModal && (
				<OperatorPermissionModal
					onConfirm={onHandlePermissionModalConfirm}
					onHandleElevatedOperation={onHandleElevatedOperation}
					onCancel={onHandlePermissionModalCancel}
					authPath={authPath}
					handleErrorNotification={handleErrorNotification}
				/>
			)}
		</>
	);
};

export { FindUserActiveDirectoryModal };

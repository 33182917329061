import cx from 'classnames';
import React, { useContext } from 'react';
import { Select } from '../../../../../../common';
import styles from '../../commandSetsModal.module.scss';
import { setController, StoreContext } from '../../contextCommand';

type Props = {
	controllerValidation: boolean;
};

const Controller: React.FC<Props> = ({ controllerValidation }) => {
	const {
		commandState: {
			controllers,
			selections: { controller },
		},
		dispatcher,
	} = useContext(StoreContext);

	const mainDivContainerId = 'CommandSetControllerContainer';
	const selectCommandSetControllerId = 'commandSetControllerDropdown';

	return (
		<div className={styles.sections} id={mainDivContainerId}>
			<label id='commandSetControllerLabel' htmlFor={selectCommandSetControllerId}>
				{_('Controller')}
			</label>
			<div>
				<Select
					id={selectCommandSetControllerId}
					className={cx(styles.select, {
						[styles.error]: controllerValidation,
					})}
					showSearch
					options={controllers.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() }))}
					onChange={value => dispatcher(setController(Number(value)))}
					key='controller'
					value={controller?.toString()}
					getPopupContainer={() => document.getElementById(mainDivContainerId)}
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				/>
				{controllerValidation && (
					<label className={styles.errorMessage} htmlFor={selectCommandSetControllerId}>
						{_('ControllerIsRequired')}
					</label>
				)}
			</div>
		</div>
	);
};

export { Controller };

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * ES6 Axios Class.
 *
 * @class Api
 * @extends {Axios}
 * @example
 * class UserApi extends Api {
 *   public constructor (config) {
 *     super(config);
 *
 *     this.login=this.login.bind(this);
 *   }
 *
 *   public login (user: User) {
 *     return this.api.post<string, User, AxiosResponse<User>>("https://www.domain/login", {name: user.name, pass: user.pass})
 *        .then((res: AxiosResponse<string>) => res.data);
 *   }
 * }
 */
export class Api {
	api: AxiosInstance;

	/**
	 * Creates an instance of Api.
	 *
	 * @memberof Api
	 */
	public constructor() {
		const config: AxiosRequestConfig = this.getDefaultAxiosRequestConfig();

		this.api = axios.create(config);
	}

	/**
	 * Get Default Axios Request Config
	 *
	 * @access protected
	 * @returns {AxiosRequestConfig}
	 * @memberof Api
	 */
	protected getDefaultAxiosRequestConfig = (): AxiosRequestConfig => {
		const token = this.getRequestToken();
		const headers: Record<string, string> = {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Pragma: 'no-cache',
			__RequestVerificationToken: token,
			Accept: 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json; charset=UTF-8',
		};
		const config: AxiosRequestConfig = {
			withCredentials: true,
			timeout: 0,
			baseURL: location.origin,
			maxBodyLength: 1048576,
			maxContentLength: 1048576,
			headers,
		};

		return config;
	};

	/**
	 * Get Request Token
	 *
	 * @access public
	 * @returns {string}
	 * @memberof Api
	 */
	public getRequestToken = (): string => {
		let token = '';

		const TOKEN_NAME: string = '__RequestVerificationToken';
		document.getElementsByName(TOKEN_NAME).forEach((t: HTMLInputElement) => {
			token = t.value;
			return;
		});

		return token;
	};

	/**
	 * Get Uri
	 *
	 * @access public
	 * @param {import("axios").AxiosRequestConfig} [config]
	 * @returns {string}
	 * @memberof Api
	 */
	public getUri = (config?: AxiosRequestConfig): string => {
		return this.api.getUri(config);
	};

	/**
	 * Get Url
	 *
	 * @access protected
	 * @param {string} url - endpoint you want to reach.
	 * @param {string} clearCache - flag to reset cache if needed.
	 * @returns {string}
	 * @memberof Api
	 */
	protected getUrl = (url: string, clearCache: boolean): string => {
		if (clearCache) {
			url = url + '?_=' + new Date().getTime();
		}
		url = getBaseUrl() + url.replace('~', '').replace('//', '/');

		return url;
	};

	/**
	 * Generic request.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @template R - `RESPONSE`: expected object inside a axios response format.
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} - HTTP axios response payload.
	 * @memberof Api
	 *
	 * @example
	 * api.request({
	 *   method: "GET|POST|DELETE|PUT|PATCH"
	 *   baseUrl: "http://www.domain.com",
	 *   url: "/api/v1/users",
	 *   headers: {
	 *     "Content-Type": "application/json"
	 *  }
	 * }).then((response: AxiosResponse<User>) => response.data)
	 *
	 */
	protected request = <T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> => {
		return this.api.request(config);
	};

	/**
	 * HTTP GET method, used to fetch data `statusCode`: 200.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @param {string} url - endpoint you want to reach.
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} HTTP `axios` response payload.
	 * @memberof Api
	 */
	protected get = <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
		return this.api.get(url, config);
	};

	/**
	 * HTTP DELETE method, `statusCode`: 204 No Content.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @template R - `RESPONSE`: expected object inside a axios response format.
	 * @param {string} url - endpoint you want to reach.
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} - HTTP [axios] response payload.
	 * @memberof Api
	 */
	protected delete = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
		return this.api.delete(url, config);
	};

	/**
	 * HTTP HEAD method.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @template R - `RESPONSE`: expected object inside a axios response format.
	 * @param {string} url - endpoint you want to reach.
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} - HTTP [axios] response payload.
	 * @memberof Api
	 */
	protected head = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
		return this.api.head(url, config);
	};

	/**
	 * HTTP POST method `statusCode`: 201 Created.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @param {string} url - endpoint you want to reach.
	 * @param {any} data - payload to be send as the `request body`,
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<T>} - HTTP [axios] response payload.
	 * @memberof Api
	 */
	protected post = async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
		if (config === null || config === undefined) {
			config = this.getDefaultAxiosRequestConfig();
		}

		const response: AxiosResponse<string> = await this.api.post<string>(url, data, config);
		if (this.success(response)) {
			return JSON.parse(response.data) as T;
		}

		return undefined;
	};

	/**
	 * HTTP PUT method.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @template B - `BODY`: body request object.
	 * @template R - `RESPONSE`: expected object inside a axios response format.
	 * @param {string} url - endpoint you want to reach.
	 * @param {B} data - payload to be send as the `request body`,
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} - HTTP [axios] response payload.
	 * @memberof Api
	 */
	protected put = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
		return this.api.put(url, data, config);
	};

	/**
	 * HTTP PATCH method.
	 *
	 * @access protected
	 * @template T - `TYPE`: expected object.
	 * @template B - `BODY`: body request object.
	 * @template R - `RESPONSE`: expected object inside a axios response format.
	 * @param {string} url - endpoint you want to reach.
	 * @param {B} data - payload to be send as the `request body`,
	 * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
	 * @returns {Promise<R>} - HTTP [axios] response payload.
	 * @memberof Api
	 */
	protected patch = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
		return this.api.patch(url, data, config);
	};

	/**
	 * @access private
	 * @template T - type.
	 * @param {import("axios").AxiosResponse<T>} response - axios response.
	 * @returns {boolean} - returns true if session still valid.
	 * @memberof Api
	 */
	private success = <T>(response: AxiosResponse<T>): boolean => {
		const contentType: string = response.headers['content-type']?.toString();
		const sessionExpired: boolean = response.headers['requires_auth']?.toString() === '1';

		let notJsonType: boolean = false;
		if (contentType) {
			notJsonType = contentType.toLowerCase().indexOf('application/json') < 0;
		}

		if (sessionExpired || notJsonType) {
			const url = response.headers['redirect_url'];
			if (url) {
				window.location.href = `${location.origin}${url}`;
			}

			return false;
		}

		return true;
	};
}

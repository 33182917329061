import { Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { VelocityDevice } from '../../../../../../../model/DeviceAdminModel';
import { setControllers, StoreContext } from '../../context';
import styles from './filterInput.module.scss';

const FilterInput: React.FC = () => {
	const { Search } = Input;

	const [initialControllers, setInitialControllers] = useState([] as VelocityDevice[]);

	const {
		readerState: { controllersForReaders },
		dispatcher,
	} = useContext(StoreContext);

	useEffect(() => {
		if (initialControllers.length === 0) {
			setInitialControllers(controllersForReaders);
		}
	}, [controllersForReaders]);

	const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		const filterData = initialControllers.filter(x => x.Name.toString().toLowerCase().includes(value.toLowerCase()));

		dispatcher(setControllers([...filterData]));
	};

	return (
		<div className={styles.container}>
			<Search placeholder='Search controller name' onChange={handleFilter} />
		</div>
	);
};

export { FilterInput };

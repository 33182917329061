export { AccountPage } from './Account/AccountPage';
export { AlarmsPage } from './Alarms/AlarmsPage';
export { ConfigurationPage } from './Configuration/ConfigurationPage';
export { DeviceControlPage } from './DeviceControl/DeviceControlPage';
export { EnrollmentPage } from './Enrollment/EnrollmentPage';
export { EventPage } from './Event/EventPage';
export { PhotoCallUpPage } from './PhotoCallUp/PhotoCallUpPage';
export { ReportsPage } from './Reports/ReportsPage';
export { StatusDashboardPage } from './StatusDashboard/StatusDashboardPage';
export { StatusViewerPage } from './StatusViewer/StatusViewerPage';
export { WhosInsidePage } from './WhosInside/WhosInsidePage';

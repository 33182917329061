// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".additional-notes-additionalNotesContainer--3muEP_uGAUm1PXoxQtpw6L{display:flex;flex-direction:column}.additional-notes-additionalNotesContainer--3muEP_uGAUm1PXoxQtpw6L>div{margin-bottom:15px}.additional-notes-additionalNotesContainer--3muEP_uGAUm1PXoxQtpw6L .additional-notes-category--2X9fIM0KsJegiBbtJ4e-Jl{min-width:300px;width:auto;max-width:55em}.additional-notes-additionalNotesContainer--3muEP_uGAUm1PXoxQtpw6L .additional-notes-additionalNotesData--3b88s9pg08F_c46Q43AV5q{max-height:500px;min-height:500px;resize:none}", "",{"version":3,"sources":["webpack://src/components/enrollment/EnrollmentTab/PersonInformationContainer/PersonInformationTabs/Tabs/AdditionalNotes/additionalNotes.module.scss"],"names":[],"mappings":"AAAA,mEACC,YAAA,CACA,qBAAA,CAEA,uEACC,kBAAA,CAGD,sHACC,eAAA,CACA,UAAA,CACA,cAAA,CAGD,iIACC,gBAAA,CACA,gBAAA,CACA,WAAA","sourcesContent":[".additionalNotesContainer {\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\r\n\t& > div {\r\n\t\tmargin-bottom: 15px;\r\n\t}\r\n\r\n\t.category {\r\n\t\tmin-width: 300px;\r\n\t\twidth: auto;\r\n\t\tmax-width: 55em;\r\n\t}\r\n\r\n\t.additionalNotesData {\r\n\t\tmax-height: 500px;\r\n\t\tmin-height: 500px;\r\n\t\tresize: none;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"additionalNotesContainer": "additional-notes-additionalNotesContainer--3muEP_uGAUm1PXoxQtpw6L",
	"category": "additional-notes-category--2X9fIM0KsJegiBbtJ4e-Jl",
	"additionalNotesData": "additional-notes-additionalNotesData--3b88s9pg08F_c46Q43AV5q"
};
export default ___CSS_LOADER_EXPORT___;

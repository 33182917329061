import { getDefaultTablePaginationConfig } from '../../Helper';
import { PaginationSetting, SortDirections } from '../../model/CommonModel';
import {
	Commands,
	ControllerContact,
	CredentialTemplateData,
	DeviceObjectType,
	DIGITrac,
	DoorGroupAndMasterInfo,
	GrandMasterTimeZones,
	Holidays,
	MasterTimeZones,
	OperatorModel,
	ReaderControlGroupItem,
	StandardTimeZone,
	SubVelocityType,
	VelocityConfigurationModel,
} from '../../model/DeviceAdminModel';
import { GlobalIOGroup } from './../../model/DeviceAdminModel';
import { DeviceControlAction } from './actions';
import { DeviceControlCommonActionTypes, DeviceControlDigiTracActionTypes, DeviceControlVelocityConfigurationActionTypes } from './actionTypes';

export type DeviceControlState = {
	velocityConfiguration: VelocityConfigurationModel;
	digiTrac: DIGITrac;
	tablePaginationSetting: PaginationSetting;
};

//States' definition
// add readonly state for avoid to overwrite default state
const INIT_STATE: DeviceControlState = {
	velocityConfiguration: {
		holidays: [],
		commandsSets: [],
		credentialTemplates: [],
		doorGroups: [],
		operators: [],
		roles: [],
		readerControls: [],
		timeZones: {
			grand: [],
			master: [],
			standard: [],
		},
		globalIOGroups: [],
		isEditMode: false,
		actionScopeKey: '',
		isFilterMode: false,
		selectedRowKeysPagination: [],
	},
	digiTrac: {
		controllers: [],
		doors: [],
		expansionsInputs: [],
		expansionsRelays: [],
		inputs: [],
		port: [],
		relays: [],
		xbox: [],
		readers: [],
		controllerFlagListener: '',
		isEditMode: false,
		selectedRowKeysPagination: [],
		controllerSelectedKey: undefined,
		isFilterMode: false,
	},
	tablePaginationSetting: {
		PageNumber: getDefaultTablePaginationConfig().defaultCurrent,
		PageSize: getDefaultTablePaginationConfig().defaultPageSize,
		SortDirection: SortDirections.None,
		SortField: '',
		SearchedValue: '',
		TotalItems: 0,
	},
};

type ContactType = keyof DIGITrac;
type VelocityType = keyof VelocityConfigurationModel;
type VelocityModels =
	| Commands
	| CredentialTemplateData
	| DoorGroupAndMasterInfo
	| ReaderControlGroupItem
	| Holidays
	| OperatorModel
	| StandardTimeZone
	| GrandMasterTimeZones
	| MasterTimeZones
	| GlobalIOGroup
	| ControllerContact;
//Reducers
const deviceControlReducer = (state: Readonly<DeviceControlState> = INIT_STATE, action: DeviceControlAction): DeviceControlState => {
	const defaultVelocityStatus = (spreadObject: VelocityType): DeviceControlState => {
		//Object for cleanup purposes, only set the variables we want to cleanup
		const getObjectKeys: Partial<VelocityConfigurationModel> = {
			commandsSets: undefined,
			credentialTemplates: undefined,
			doorGroups: undefined,
			readerControls: undefined,
			holidays: undefined,
			operators: undefined,
			globalIOGroups: undefined,
		};

		const velocityConfiguration = {
			...state.velocityConfiguration,
			[spreadObject]: action.payload,
		};

		for (const key of Object.keys(getObjectKeys).filter(k => k !== spreadObject.toString())) {
			velocityConfiguration[key] = [];
		}

		return {
			...state,
			velocityConfiguration,
		};
	};

	switch (action.type) {
		case DeviceControlVelocityConfigurationActionTypes.DELETE_COMMANDS_SETS: {
			const cloneState = state.velocityConfiguration.commandsSets;
			const findCommand = cloneState.findIndex(x => x.CommandSetId === action.payload);
			if (~findCommand) {
				cloneState.splice(findCommand, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						commandsSets: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_HOLIDAY: {
			const cloneState = state.velocityConfiguration.holidays;
			const findCommand = cloneState.findIndex(x => x.HolidayID === action.payload);
			if (~findCommand) {
				cloneState.splice(findCommand, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						holidays: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_CREDENTIAL_TEMPLATE: {
			const cloneState = state.velocityConfiguration.credentialTemplates;
			const findCredential = cloneState.findIndex(x => x.CredentialTemplateId === action.payload);
			if (~findCredential) {
				cloneState.splice(findCredential, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						credentialTemplates: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_DOOR_GROUP: {
			const doorGroup: DoorGroupAndMasterInfo = action.payload;
			const cloneState = state.velocityConfiguration.doorGroups;
			const findDoor = cloneState.findIndex(x => x.IsMasterDoor === doorGroup.IsMasterDoor && x.Id === doorGroup.Id);
			if (~findDoor) {
				cloneState.splice(findDoor, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						doorGroups: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_READER_CONTROL_GROUP: {
			const cloneState = state.velocityConfiguration.readerControls;
			const findReader = cloneState.findIndex(x => x.ReaderControlGroupId === action.payload);
			if (~findReader) {
				cloneState.splice(findReader, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						readerControls: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_GRAND: {
			const cloneState = state.velocityConfiguration.timeZones.grand;
			const findIndex = cloneState.findIndex(x => x.Id === action.payload);
			if (~findIndex) {
				cloneState.splice(findIndex, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						timeZones: {
							...state.velocityConfiguration.timeZones,
							grand: [...cloneState],
						},
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_MASTER: {
			const cloneState = state.velocityConfiguration.timeZones.master;
			const findIndex = cloneState.findIndex(x => x.Id === action.payload);
			if (~findIndex) {
				cloneState.splice(findIndex, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						timeZones: {
							...state.velocityConfiguration.timeZones,
							master: [...cloneState],
						},
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_STANDARD: {
			const cloneState = state.velocityConfiguration.timeZones.standard;
			const findIndex = cloneState.findIndex(x => x.Id === action.payload);
			if (~findIndex) {
				cloneState.splice(findIndex, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						timeZones: {
							...state.velocityConfiguration.timeZones,
							standard: [...cloneState],
						},
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_XBOX: {
			const cloneState = state.digiTrac.xbox;
			const findIndex = cloneState.findIndex(x => x.Id === action.payload);
			if (~findIndex) {
				cloneState.splice(findIndex, 1);

				return {
					...state,
					digiTrac: {
						...state.digiTrac,
						xbox: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_PORT: {
			const cloneState = state.digiTrac.port;
			const findIndex = cloneState.findIndex(x => x.Id === action.payload);
			if (~findIndex) {
				cloneState.splice(findIndex, 1);

				return {
					...state,
					digiTrac: {
						...state.digiTrac,
						port: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_CONTROLLER: {
			const cloneState = state.digiTrac.controllers;
			const findIndex = cloneState.findIndex(x => x.Id === action.payload);
			if (~findIndex) {
				cloneState.splice(findIndex, 1);

				return {
					...state,
					digiTrac: {
						...state.digiTrac,
						controllers: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.DELETE_GLOBAL_IO_GROUP: {
			const cloneState = state.velocityConfiguration.globalIOGroups;
			const findGlobal = cloneState.findIndex(x => x.id === action.payload);
			if (~findGlobal) {
				cloneState.splice(findGlobal, 1);

				return {
					...state,
					velocityConfiguration: {
						...state.velocityConfiguration,
						globalIOGroups: [...cloneState],
					},
				};
			}

			return state;
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_HOLIDAYS:
			return defaultVelocityStatus('holidays');

		case DeviceControlVelocityConfigurationActionTypes.SET_OPERATORS:
			return defaultVelocityStatus('operators');

		case DeviceControlVelocityConfigurationActionTypes.SET_ROLES:
			return defaultVelocityStatus('roles');

		case DeviceControlVelocityConfigurationActionTypes.SET_COMMANDS_SETS:
			return defaultVelocityStatus('commandsSets');

		case DeviceControlVelocityConfigurationActionTypes.SET_CREDENTIAL_TEMPLATES:
			return defaultVelocityStatus('credentialTemplates');

		case DeviceControlVelocityConfigurationActionTypes.SET_DOOR_GROUPS:
			return defaultVelocityStatus('doorGroups');

		case DeviceControlVelocityConfigurationActionTypes.SET_READER_CONTROL_GROUPS:
			return defaultVelocityStatus('readerControls');

		case DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_GRAND: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					timeZones: {
						...state.velocityConfiguration.timeZones,
						grand: [...action.payload],
					},
				},
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_MASTER: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					timeZones: {
						...state.velocityConfiguration.timeZones,
						master: [...action.payload],
					},
				},
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_STANDARD: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					timeZones: {
						...state.velocityConfiguration.timeZones,
						standard: [...action.payload],
					},
				},
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_ACTION_SCOPE_KEY: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					actionScopeKey: action.payload,
				},
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_EDIT_MODE: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					isEditMode: action.payload,
				},
			};
		}

		case DeviceControlDigiTracActionTypes.SET_DIGI_TRAC_EDIT_MODE: {
			return {
				...state,
				digiTrac: {
					...state.digiTrac,
					isEditMode: action.payload,
				},
			};
		}

		case DeviceControlDigiTracActionTypes.SET_CONTROLLER_CONTACTS: {
			const defaultStatus = (spreadObject: ContactType) => {
				//Object for cleanup purposes, only set the variables we want to cleanup
				const getObjectKeys: Partial<DIGITrac> = {
					controllers: undefined,
					doors: undefined,
					expansionsInputs: undefined,
					expansionsRelays: undefined,
					inputs: undefined,
					port: undefined,
					relays: undefined,
					xbox: undefined,
					readers: undefined,
					...(spreadObject.toString() !== 'controllers' && { controllerSelectedKey: undefined }),
				};

				const digiTracConfiguration = {
					...state.digiTrac,
					controllerFlagListener: spreadObject,
					[spreadObject]: action.payload.data,
				};

				for (const key of Object.keys(getObjectKeys).filter(k => k !== spreadObject.toString())) {
					key === 'controllerSelectedKey' ? (digiTracConfiguration[key] = undefined) : (digiTracConfiguration[key] = []);
				}

				return {
					...state,
					digiTrac: digiTracConfiguration,
				};
			};

			switch (action.payload.controllerType) {
				case DeviceObjectType.Controller:
					return defaultStatus('controllers');

				case DeviceObjectType.Door:
					return defaultStatus('doors');

				case DeviceObjectType.ExpansionInputs:
					return defaultStatus('expansionsInputs');

				case DeviceObjectType.ExpansionRelays:
					return defaultStatus('expansionsRelays');

				case DeviceObjectType.Input:
					return defaultStatus('inputs');

				case DeviceObjectType.Port:
					return defaultStatus('port');

				case DeviceObjectType.Relay:
					return defaultStatus('relays');

				case DeviceObjectType.Xbox:
					return defaultStatus('xbox');

				case DeviceObjectType.Reader:
					return defaultStatus('readers');
			}
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_GLOBAL_IO_GROUPS: {
			return defaultVelocityStatus('globalIOGroups');
		}

		case DeviceControlDigiTracActionTypes.SET_SELECTED_ROW_KEYS_PAGINATION: {
			return {
				...state,
				digiTrac: {
					...state.digiTrac,
					selectedRowKeysPagination: action.payload,
				},
			};
		}

		case DeviceControlDigiTracActionTypes.SET_CONTROLLER_SELECTED_KEY: {
			return {
				...state,
				digiTrac: {
					...state.digiTrac,
					controllerSelectedKey: action.payload,
				},
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_FILTER_MODE: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					isFilterMode: action.payload,
				},
			};
		}

		case DeviceControlDigiTracActionTypes.SET_DIGI_TRAC_FILTER_MODE: {
			return {
				...state,
				digiTrac: {
					...state.digiTrac,
					isFilterMode: action.payload,
				},
			};
		}

		case DeviceControlCommonActionTypes.SET_TABLE_PAGINATION_SETTING: {
			return {
				...state,
				tablePaginationSetting: action.payload,
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.SET_SELECTED_ROW_KEYS_PAGINATION: {
			return {
				...state,
				velocityConfiguration: {
					...state.velocityConfiguration,
					selectedRowKeysPagination: action.payload,
				},
			};
		}

		case DeviceControlVelocityConfigurationActionTypes.CLEAR_VELOCITY_CONFIGURATION_SELECTIONS: {
			const clearVelocityConfigurationSelections = (spreadObject: VelocityType, subVelocityType?: SubVelocityType): DeviceControlState => {
				const getObjectKeys: Partial<VelocityConfigurationModel> = {
					commandsSets: undefined,
					credentialTemplates: undefined,
					doorGroups: undefined,
					readerControls: undefined,
					holidays: undefined,
					globalIOGroups: undefined,
					timeZones: {
						standard: undefined,
						master: undefined,
						grand: undefined,
					},
				};

				let velocityConfiguration = {
					...state.velocityConfiguration,
				};

				for (const key of Object.keys(getObjectKeys).filter(k => k === spreadObject.toString())) {
					const data: VelocityModels[] = velocityConfiguration[key];

					if (spreadObject.toString() === 'timeZones') {
						const subData: VelocityModels[] = data[subVelocityType];
						subData.forEach(item => {
							item.checked = false;
						});
						velocityConfiguration = {
							...velocityConfiguration,
							[spreadObject]: {
								...velocityConfiguration.timeZones,
								[subVelocityType]: subData,
							},
						};
					} else {
						data.forEach(item => {
							item.checked = false;
						});
						velocityConfiguration = { ...velocityConfiguration, [spreadObject]: data };
					}
				}

				return {
					...state,
					velocityConfiguration,
				};
			};

			switch (action.payload.objectType) {
				case DeviceObjectType.CommandSets:
					return clearVelocityConfigurationSelections('commandsSets');

				case DeviceObjectType.CredentialTemplates:
					return clearVelocityConfigurationSelections('credentialTemplates');

				case DeviceObjectType.DoorGroup:
					return clearVelocityConfigurationSelections('doorGroups');

				case DeviceObjectType.ReaderControlGroup:
					return clearVelocityConfigurationSelections('readerControls');

				case DeviceObjectType.Holidays:
					return clearVelocityConfigurationSelections('holidays');

				case DeviceObjectType.GlobalIOGroup:
					return clearVelocityConfigurationSelections('globalIOGroups');

				case DeviceObjectType.TimeZones:
					return clearVelocityConfigurationSelections('timeZones', action.payload.subObjectType);

				default:
					return state;
			}
		}

		case DeviceControlDigiTracActionTypes.CLEAR_DIGI_TRAC_SELECTIONS: {
			const clearDigiTracSelections = (spreadObject: ContactType): DeviceControlState => {
				const getObjectKeys: Partial<DIGITrac> = {
					controllers: undefined,
					doors: undefined,
					expansionsInputs: undefined,
					expansionsRelays: undefined,
					inputs: undefined,
					port: undefined,
					relays: undefined,
					xbox: undefined,
					readers: undefined,
				};

				let digitracConfiguration: DIGITrac = {
					...state.digiTrac,
				};

				for (const key of Object.keys(getObjectKeys).filter(k => k === spreadObject.toString())) {
					const isControllerObject = DeviceObjectType.Controller === action.payload;
					const newControllerSelectedKey = isControllerObject ? undefined : digitracConfiguration.controllerSelectedKey;

					const data: VelocityModels[] = digitracConfiguration[key];
					data.forEach(item => {
						item.checked = false;
					});

					digitracConfiguration = {
						...digitracConfiguration,
						[spreadObject]: data,
						selectedRowKeysPagination: [],
						controllerSelectedKey: newControllerSelectedKey,
					};
				}

				return {
					...state,
					digiTrac: digitracConfiguration,
				};
			};

			switch (action.payload) {
				case DeviceObjectType.Controller:
					return clearDigiTracSelections('controllers');

				case DeviceObjectType.Door:
					return clearDigiTracSelections('doors');

				case DeviceObjectType.ExpansionInputs:
					return clearDigiTracSelections('expansionsInputs');

				case DeviceObjectType.ExpansionRelays:
					return clearDigiTracSelections('expansionsRelays');

				case DeviceObjectType.Port:
					return clearDigiTracSelections('port');

				case DeviceObjectType.Input:
					return clearDigiTracSelections('inputs');

				case DeviceObjectType.Relay:
					return clearDigiTracSelections('relays');

				case DeviceObjectType.Xbox:
					return clearDigiTracSelections('xbox');

				case DeviceObjectType.Reader:
					return clearDigiTracSelections('readers');

				default:
					return state;
			}
		}

		default:
			return state;
	}
};

export { deviceControlReducer };

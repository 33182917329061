import { AutoComplete } from 'antd';
import React, { useState } from 'react';

const AutoCompleteIcon = ({ openIcon, closedIcon, ...props }) => {
	const [open, setOpen] = useState<boolean>(false);

	let suffixIcon: React.ReactNode;
	if (open) {
		suffixIcon = openIcon;
	} else {
		suffixIcon = (
			<div className={open ? 'ant-select-suffix' : ''} onClick={() => setOpen(!open)}>
				{closedIcon}
			</div>
		);
	}

	return (
		<AutoComplete
			{...props}
			showArrow
			open={open}
			suffixIcon={suffixIcon}
			onDropdownVisibleChange={(openValue: boolean) => setOpen(openValue)}
			dropdownRender={(menu: React.ReactElement) => <label aria-labelledby={props.id}>{menu}</label>}
			aria-activedescendant={props.id}
		/>
	);
};

export { AutoCompleteIcon };

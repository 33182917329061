import React, { useContext, useEffect, useState } from 'react';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './lineModule.module.scss';

type Props = {
	useDoorContext: boolean;
};

const LineModule: React.FC<Props> = ({ useDoorContext }) => {
	const [lineModulesOptions, setLineModulesOptions] = useState([]);

	let inputContext = { context: null as ReaderState, dispatcher: null };
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleSelectLineModule = (value: string) => {
		const lineModuleId = Number(value);
		inputContext.dispatcher(setInputAction({ LineModuleId: lineModuleId }));
	};

	useEffect(() => {
		if (inputContext.context?.lineModules !== null) {
			const lineModulesOptions = inputContext.context?.lineModules.map((x, index) => ({ value: index, label: x }));
			setLineModulesOptions([...lineModulesOptions]);
		}
	}, [inputContext.context?.lineModules]);

	return (
		<div className={styles.container}>
			<span>{_('LineModule')}</span>
			<Select
				options={lineModulesOptions}
				onChange={handleSelectLineModule}
				key='photoCallUpTemplate'
				id='inputModalLineModuleSelect'
				value={inputContext.context?.input?.LineModuleId <= 3 ? inputContext.context?.input?.LineModuleId.toString() : ''}
				disabled={useDoorContext ? inputContext.context?.input?.moduleTypeDisabled : false}
				getPopupContainer={() => document.getElementById('generalInputContainer')}
			/>
		</div>
	);
};

export { LineModule };

import React, { useContext, useEffect } from 'react';
import { IPv4AddressInput } from '../../../../../../common/IPv4AddressInput/IPv4AddressInput';
import { IPv6AddressInput } from '../../../../../../common/IPv6AddressInput/IPv6AddressInput';
import { PortStoreContext, setDefaultGatewatIPv6, setDefaultGateway } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {
	defaultGatewayValidation: boolean;
	onResetDefaultGatewayValidation: () => void;
};

const DefaultGateway: React.FC<Props> = ({ defaultGatewayValidation, onResetDefaultGatewayValidation }) => {
	const {
		portState: { defaultGateway, portNetworkType, defaultGatewayIPv6, protocol },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		onResetDefaultGatewayValidation();
	}, [defaultGateway, defaultGatewayIPv6]);

	const handleDefaultGatewayIpv6Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setDefaultGatewatIPv6(value));
	};

	const handleDefaultGatewayIpv4Change = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setDefaultGateway(value));
	};

	const portGeneralIPv6DefaultGatewayId = 'portGeneralIPv6DefaultGateway';
	const portGeneralIPv4DefaultGatewayId = 'portGeneralIPv4DefaultGateway';

	return (
		<>
			<div className={styles.inputContainer}>
				<label id='portGeneralDefaultGatewayLabel' htmlFor={portNetworkType > 2 ? portGeneralIPv6DefaultGatewayId : portGeneralIPv4DefaultGatewayId}>
					{_('DefaultGateway')}
				</label>
				{portNetworkType > 2 ? (
					<IPv6AddressInput
						id={portGeneralIPv6DefaultGatewayId}
						key='defaultGatewayIpv6'
						addressValidation={defaultGatewayValidation}
						value={defaultGatewayIPv6}
						onChange={handleDefaultGatewayIpv6Change}
					/>
				) : (
					<IPv4AddressInput
						id={portGeneralIPv4DefaultGatewayId}
						key='defaultGatewayIpv4'
						disabled={protocol === 2}
						addressValidation={defaultGatewayValidation}
						value={defaultGateway}
						onChange={handleDefaultGatewayIpv4Change}
					/>
				)}
			</div>
			<div className={styles.inputValidation}>
				{defaultGatewayValidation && (
					<label
						id='portGeneralDefaultGatewayErrorLabel'
						htmlFor={portNetworkType > 2 ? portGeneralIPv6DefaultGatewayId : portGeneralIPv4DefaultGatewayId}
						className={styles.errorMessage}>
						{_('DefaultGatewayIsRequired')}
					</label>
				)}
			</div>
		</>
	);
};

export { DefaultGateway };

import React from 'react';
import ReactDOM from 'react-dom';
import { Logger } from '../../../model/LoggingModel';

type RefWrapProps = {
	headerScroll: number;
	children: JSX.Element;
	fetchData: () => void;
	setHeaderScroll: (value: number) => void;
};

class RefWrap extends React.Component<RefWrapProps> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		//findDOMNode is deprecated on strict mode
		const ref: Element = ReactDOM.findDOMNode(this) as Element;
		if (ref) {
			ref.addEventListener(
				'scroll',
				event => {
					const target = event.target as HTMLDivElement;
					const maxScroll = Math.floor(target.scrollHeight - target.clientHeight);
					const currentScroll = Math.ceil(target.scrollTop);
					if (currentScroll >= maxScroll) {
						this.props.fetchData();
					}

					if (target.scrollLeft !== this.props.headerScroll) {
						this.props.setHeaderScroll(target.scrollLeft);
					}
				},
				false
			);
		}
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	render() {
		const { children } = this.props;

		return React.Children.only(children);
	}
}

export { RefWrap };

import { canAddNewTimeZones, convertArrayToObject } from '../../../Helper';
import {
	ControllerStateDenom,
	LoadMessagesForFirmwareConfiguration,
	OccupancySetupByZone,
	SelectTimeZone,
	TimeZoneType,
} from '../../../model/DeviceAdminModel';
import { ActionTypes, ControllerActionType, ErrorTabs, NewTimeZone } from './actionTypes';

type ControllerState = ControllerStateDenom & {
	occupancyTwoPersonsRules: OccupancySetupByZone[];
	error: ErrorTabs;
	openTimeZone: NewTimeZone;
	newTimeZoneAddedId: number;
	hasChanged: boolean;
	FirmwareMessages: LoadMessagesForFirmwareConfiguration;
	currentRuleId: number;
};

const INIT_STATE: ControllerState = null;

const controllerReducer = (state: Readonly<ControllerState> = INIT_STATE, action: ControllerActionType): ControllerState => {
	const cloneState = { ...state };

	switch (action.type) {
		case ActionTypes.RESET_CONTROLLER_DATA:
			return null;

		case ActionTypes.SET_CONTROLLER_DATA: {
			const denomAlarmActions = convertArrayToObject(action.payload.ControllerACBSetup, 'ACBIndex');
			const mapAlarmActionsIds = action.payload.ControllerACBSetup.map(x => x.ACBIndex);

			const genericTimeZones = action.payload.GenericTimeZones;
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: false,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};

				genericTimeZones.unshift(newTimeZone);
			}

			return {
				...state,
				...action.payload,
				ControllerBasicSetup: { ...action.payload.ControllerBasicSetup, MasterCode: action.payload.ControllerBasicSetup.MasterCode },
				occupancyTwoPersonsRules: [...action.payload.ControllerPassbackSetup.OccupancySetupByZone],
				ControllerACBSetup: { ...denomAlarmActions },
				ControllerACBSetupById: [...mapAlarmActionsIds],
				openTimeZone: null,
				GenericTimeZones: [...genericTimeZones],
				newTimeZoneAddedId: 0,
				hasChanged: false,
				error: {
					linesPerPageForFormFeed: false,
					systemCode: false,
					thresholdTime: false,
					name: false,
					twoPersonsRules: false,
					controllerType: false,
					twoPersonEvenPeople: false,
					wirelessController: false,
				},
				FirmwareRevision: [...action.payload.FirmwareRevision],
			};
		}

		case ActionTypes.SET_CONTROLLER_BASIC_SETUP:
			return {
				...state,
				hasChanged: true,
				ControllerBasicSetup: {
					...state.ControllerBasicSetup,
					...action.payload,
				},
			};

		case ActionTypes.SET_FIRMWARE_MESSAGES:
			return {
				...state,
				FirmwareMessages: { ...action.payload },
			};

		case ActionTypes.SET_CONTROLLER_MISC_SYSTEM_SETUP:
			return {
				...state,
				hasChanged: true,
				ControllerMiscSystemSetup: {
					...state.ControllerMiscSystemSetup,
					...action.payload,
				},
			};

		case ActionTypes.SET_CONTROLLER_PASSBACK_SETUP:
			return {
				...state,
				hasChanged: true,
				ControllerPassbackSetup: {
					...state.ControllerPassbackSetup,
					...action.payload,
				},
			};
		case ActionTypes.SET_CONTROLLER_REPORTING_SETUP:
			return {
				...state,
				hasChanged: true,
				ControllerReportingSetup: {
					...state.ControllerReportingSetup,
					...action.payload,
				},
			};

		case ActionTypes.SET_CONTROLLER_REPORTING_ADVANCE_SETUP:
			return {
				...state,
				ControllerReportingSetup: {
					...state.ControllerReportingSetup,
					AdvancedReporting: {
						...state.ControllerReportingSetup.AdvancedReporting,
						...action.payload,
					},
				},
			};

		case ActionTypes.SET_OCCUPANCY_TWO_PERSONS_RULES: {
			cloneState.occupancyTwoPersonsRules = [...action.payload];
			return {
				...cloneState,
				hasChanged: true,
			};
		}

		case ActionTypes.SET_CONTROLLER_ERROR:
			return {
				...state,
				error: {
					...state.error,
					...action.payload,
				},
			};

		case ActionTypes.SET_CONTROLLER_PROPERTY:
			return {
				...state,
				hasChanged: true,
				...action.payload,
			};

		case ActionTypes.SET_OPEN_TIME_ZONES:
			return {
				...state,
				openTimeZone: action.payload,
			};

		case ActionTypes.SET_CONTROLLER_RESET_HAS_CHANGED:
			return {
				...state,
				hasChanged: action.payload,
			};

		case ActionTypes.SET_CONTROLLER_CURRENT_TIME_ZONE:
			return {
				...state,
				newTimeZoneAddedId: action.payload,
			};

		case ActionTypes.SET_ALARMS:
			return {
				...state,
				hasChanged: true,
				ControllerACBSetup: {
					...state.ControllerACBSetup,
					[action.payload.key]: { ...action.payload.data },
				},
			};

		case ActionTypes.SET_CURRENT_RULE_ID:
			return {
				...state,
				currentRuleId: action.payload,
			};

		default:
			return { ...state };
	}
};

export { controllerReducer, ControllerState };

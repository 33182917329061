import React from 'react';
import { HeaderBar } from '../../components/common';
import { EventTable } from '../../components/event';
import { Reports } from '../../components/reports';
import { User } from '../../model/AccountModel';
import { Logger } from '../../model/LoggingModel';

// Internal state for the component
interface State {}

// Normal properties for the component
interface OwnProps {}

// combine them together
type Props = OwnProps;

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const { showEventsOnReportingPage } = user;

class ReportsPage extends React.Component<Props, State> {
	constructor(prop) {
		super(prop);
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	render() {
		return (
			<>
				<HeaderBar title={_('Reporting')} />
				<Reports />
				{showEventsOnReportingPage && <EventTable />}
			</>
		);
	}
}

export { ReportsPage };

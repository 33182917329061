import { CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import { parseDateTimeString } from '../../../Helper';
import { GridDataColumnItem, ResponseStatusCode } from '../../../model/CommonModel';
import { DoorGroup } from '../../../model/DeviceAdminModel';
import { CredentialColumnsKeys, CredentialDetail, CredentialFunction, CredentialGridItem, RelativeShortcut } from '../../../model/EnrollmentModel';
import { UserDefinedFieldSetup } from '../../../model/UserDefinedFields';
import { CredentialTemplateModalModel } from './CredentialTemplateModalModels';

export const mapModelCredentialToJs = (
	model: CredentialDetail,
	currentState: CredentialTemplateModalModel,
	linkedInfoOnly: boolean,
	isDeviceAdminCredentialTemplate: boolean
): CredentialTemplateModalModel => {
	currentState.linkedInfoOnly = linkedInfoOnly;

	if (linkedInfoOnly || model.ResponseStatusCode !== ResponseStatusCode.ComponentLocked) {
		if (linkedInfoOnly || model.ResponseStatusCode === ResponseStatusCode.Success) {
			currentState.generalTabState.keypadSectionState.alwaysHidePin = model.AlwaysHidePin;
			currentState.PinOverrideAnswer = model.PinOverrideAnswer;
			currentState.CodeOverrideAnswer = model.CodeOverrideAnswer;
			currentState.DualOverrideAnswer = model.DualOverrideAnswer;

			// --- Activation/Expiration Section
			currentState.generalTabState.activationExpirationSectionState.activationDate = getDateISOString(model.ActivationDate);
			currentState.generalTabState.activationExpirationSectionState.expirationUsed = model.ExpirationUsed;

			currentState.generalTabState.activationExpirationSectionState.expiration = getDateISOString(model.Expiration);
			currentState.generalTabState.activationExpirationSectionState.expirationFunction = model.ExpirationFunction;

			currentState.generalTabState.activationExpirationSectionState.hostExpirationUDF = model.HostExpirationUDF;

			const hostExpirationUDF = model.HostExpirationUDF;
			if (hostExpirationUDF > 0) {
				const setup = getUserDefinedSetup(hostExpirationUDF, currentState.configData.UserDefinedFieldSetups);
				const txt = 'UDF' + hostExpirationUDF + ': ' + setup.Caption;
				currentState.generalTabState.activationExpirationSectionState.expiration = txt;
			}

			if (hostExpirationUDF === 0) {
				if (isDeviceAdminCredentialTemplate) {
					if (model.HostExpirationInterval > 0) {
						const relativeTimeShortcut = applyIntervalAndOffsetToDate(model.HostExpirationInterval, model.HostExpirationOffset);
						const relativeShortcutIndex = getRelativeTimeShortcutIndex(
							model.HostExpirationInterval,
							model.HostExpirationOffset,
							currentState.configData.RelativeShortcuts
						);
						if (relativeShortcutIndex !== -1) {
							const relativeShortcut = currentState.configData.RelativeShortcuts[relativeShortcutIndex];
							currentState.generalTabState.activationExpirationSectionState.expiration = relativeShortcut.Display;
						} else {
							currentState.generalTabState.activationExpirationSectionState.expiration = getDateISOString(relativeTimeShortcut);
						}
						currentState.generalTabState.activationExpirationSectionState.hostExpirationInterval = model.HostExpirationInterval;
						currentState.generalTabState.activationExpirationSectionState.hostExpirationOffset = model.HostExpirationOffset;
					} else {
						currentState.generalTabState.activationExpirationSectionState.expiration = getDateISOString(model.Expiration);
					}
				} else {
					if (model.CredentialId > 0) {
						if (model.HostExpirationInterval === 0 || (linkedInfoOnly && currentState.generalTabState.credentialId > 0)) {
							currentState.generalTabState.activationExpirationSectionState.expiration = getDateISOString(model.Expiration);
						} else {
							const relativeTimeShortcut = applyIntervalAndOffsetToDate(model.HostExpirationInterval, model.HostExpirationOffset);
							currentState.generalTabState.activationExpirationSectionState.expiration = getDateISOString(relativeTimeShortcut);
						}
					} else {
						currentState.generalTabState.activationExpirationSectionState.expiration = getDateISOString(model.Expiration);
					}
				}
			}

			// Populate Limits Tab
			currentState.limitsTabState.globalize = model.Globalize;
			currentState.limitsTabState.limitCount = model.Count === 0 ? 1 : model.Count;
			currentState.limitsTabState.limitRule = model.LimitRule;
			currentState.limitsTabState.threatLevel = model.ThreatLevel;
			currentState.limitsTabState.twoPersonRule = model.TwoPersonRule;

			// Populate Options Tab
			currentState.optionsTabState.printControlMax = model.PrintControlMax;
			currentState.optionsTabState.printControlConfirmation = model.PrintControlConfirmation;
			currentState.optionsTabState.readerControlGroup = model.ReaderControlGroupId;
			currentState.optionsTabState.enableIssueControl = model.EnableIssueControl;
			currentState.optionsTabState.enablePrintControl = model.EnablePrintControl;

			// Populate Functions Tab
			currentState.functionsTabState.credentialFunctions = model.CredentialFunctions;

			if (currentState.functionsTabState.doorGroups?.length > 0) {
				currentState.functionsTabState.doorGroups = filterDoorGroups(model.CredentialFunctions, currentState.functionsTabState.doorGroups);
			}

			// --- Information Section
			currentState.generalTabState.informationSectionState.badgeTemplate = model.BadgeTemplateId;
			if (!linkedInfoOnly) {
				if (!model.IsTemplate) {
					currentState.generalTabState.credentialId = model.CredentialId;
					currentState.generalTabState.credentialTemplateId = -1;
				} else {
					currentState.generalTabState.credentialTemplateId = model.CredentialId;
				}

				if (currentState.configData.IsDeviceAdminCredentialTemplate && model.IsTemplate) {
					currentState.generalTabState.credentialId = model.CredentialId;
				}

				currentState.generalTabState.IDF = model.IDF;
				// Info Section
				currentState.generalTabState.informationSectionState.credentialDescription = model.Description;
				currentState.generalTabState.informationSectionState.linkToCredential = model.IsLinked;
				currentState.generalTabState.informationSectionState.linkedTemplateId = model.LinkedTemplateId;

				if (
					model.LinkNew &&
					!currentState.configData.IsDeviceAdminCredentialTemplate &&
					model.LinkedTemplateId <= 0 &&
					currentState.generalTabState.credentialId === -1
				) {
					currentState.generalTabState.informationSectionState.linkToCredential = true;
					currentState.generalTabState.informationSectionState.linkedTemplateId = model.CredentialId;
				}
				currentState.generalTabState.informationSectionState.linkToNewCredentials = model.LinkNew;

				// --- Card Section
				currentState.generalTabState.cardSectionState.cardType = model.CardType;
				if (model.MatchCode !== '0') {
					currentState.generalTabState.cardSectionState.matchCode = model.MatchCode;
				} else {
					currentState.generalTabState.cardSectionState.matchCode = '';
				}

				currentState.generalTabState.cardSectionState.cardStamp = model.CardStamp;
				currentState.generalTabState.cardSectionState.cardData = model.CardData;
				currentState.generalTabState.cardSectionState.hasUDFDataSelected = model.HasUDFDataSelected;

				// --- Keypad Section
				currentState.generalTabState.keypadSectionState.duressDigit = model.DuressDigit;
				currentState.generalTabState.keypadSectionState.pinCodeLength = model.PinCodeLength;
				currentState.generalTabState.keypadSectionState.pinCode = model.PinCode;
				currentState.generalTabState.keypadSectionState.pinCodeMasked = model.PinCode;
				currentState.generalTabState.keypadSectionState.pinCodeConfirmMasked = model.PinCode;

				// Options Tab
				currentState.optionsTabState.tagAction = model.Tag;
				currentState.optionsTabState.alertAction = model.Alert;
				currentState.optionsTabState.disableAction = model.Disable;
				currentState.optionsTabState.printControlAttempts = model.PrintControlAttempts;
				currentState.optionsTabState.printControlCount = model.PrintControlCount;
				currentState.optionsTabState.executiveOverride = model.ExecutiveOverride;
				currentState.optionsTabState.specialNeeds = model.SpecialNeeds;
				currentState.optionsTabState.scrambleNormal = model.ScrambleNormal;
				currentState.optionsTabState.currentIssueStatus = model.CurrentIssueStatus;
				currentState.optionsTabState.currentIssueStatusDropdown = model.CurrentIssueStatus;
				currentState.optionsTabState.issueControlCount = model.IssueControlCount;
				currentState.optionsTabState.issueControlMax = model.IssueControlMax;

				currentState.previousLinkNewValue = currentState.generalTabState.informationSectionState.linkToNewCredentials;

				const userTimeFormat: string = (document.getElementById('UserDateFormat') as HTMLInputElement).value;
				if (model.CredentialReissueInfo != null && model.CredentialReissueInfo.length <= 0) {
					const date = new Date();
					const formattedDate = formatDateTime(date, userTimeFormat);
					currentState.optionsTabState.credentialReissueInfo = [
						{
							Comment: '',
							Date: new Date(),
							formattedDate,
							IssueNumber: 1,
							key: 0,
							Reason: 'Initial Issue',
							Status: '',
						},
					];
				} else {
					currentState.optionsTabState.credentialReissueInfo = model.CredentialReissueInfo.map((cri, idx: number) => ({
						...cri,
						formattedDate: FormatDate(getDateISOString(cri.Date), false, null, true, false),
						Date: new Date(getDateISOString(cri.Date)),
						key: idx,
					}));
				}

				// Populate UDF Concat data (mostly just used for credential templates)
				if (model.ConcatenatedUDFFields?.length > 0) {
					currentState.generalTabState.cardSectionState.saveConcatenatedData = true;
				}
				currentState.generalTabState.cardSectionState.concatenatedData = model.ConcatenatedUDFFields;
			} else {
				currentState.generalTabState.informationSectionState.linkedTemplateId = model.CredentialId;
			}
		}
	}

	return currentState;
};

export const mapJsCredentialToModel = (jsCredential: CredentialTemplateModalModel, personId: number, linkToExistingCredentials: boolean): CredentialDetail => {
	const { pinCode, alwaysHidePin, pinCodeMasked, showClearPin } = jsCredential.generalTabState.keypadSectionState;
	const { expiration, hostExpirationInterval, hostExpirationOffset, hostExpirationUDF } = jsCredential.generalTabState.activationExpirationSectionState;
	let hostExpirationDate: Date = undefined;
	let lHostExpirationInterval: number = 0;
	let lHostExpirationOffset: string = '';
	const showPin: boolean = showClearPin && !alwaysHidePin;
	if (hostExpirationUDF === 0) {
		if (hostExpirationInterval <= 0) {
			hostExpirationDate = new Date(expiration);
		} else {
			hostExpirationDate = new Date();
			lHostExpirationInterval = hostExpirationInterval;
			lHostExpirationOffset = hostExpirationOffset;
		}
	}

	const credential: Partial<CredentialDetail> = {
		ScrambleNormal: jsCredential.optionsTabState.scrambleNormal,
		CredentialId: jsCredential.generalTabState.credentialId,
		HostUserId: personId,
		Description: jsCredential.generalTabState.informationSectionState.credentialDescription,
		LinkToExisting: linkToExistingCredentials,
		LinkNew: jsCredential.generalTabState.informationSectionState.linkToNewCredentials,
		IsTemplate: jsCredential.generalTabState.isCredentialTemplate,
		CredentialTemplateId: jsCredential.generalTabState.credentialTemplateId,
		ReaderControlGroupId: jsCredential.optionsTabState.readerControlGroup,
		BadgeTemplateId: jsCredential.generalTabState.informationSectionState.badgeTemplate,
		IDF: jsCredential.generalTabState.IDF,
		ActivationDate: new Date(jsCredential.generalTabState.activationExpirationSectionState.activationDate),
		Expiration: hostExpirationDate,
		ExpirationFunction: jsCredential.generalTabState.activationExpirationSectionState.expirationFunction,
		ExpirationUsed: jsCredential.generalTabState.activationExpirationSectionState.expirationUsed,
		CardType: jsCredential.generalTabState.cardSectionState.cardType,
		MatchCode: jsCredential.generalTabState.cardSectionState.matchCode,
		CardData: jsCredential.generalTabState.cardSectionState.cardData,
		CardStamp: jsCredential.generalTabState.cardSectionState.cardStamp,
		PinCode: (showPin ? pinCode : pinCodeMasked)?.toString() || '',
		DuressDigit: jsCredential.generalTabState.keypadSectionState.duressDigit,
		PinCodeLength: jsCredential.generalTabState.keypadSectionState.pinCodeLength,
		Globalize: jsCredential.limitsTabState.globalize,
		LimitRule: jsCredential.limitsTabState.limitRule,
		TwoPersonRule: jsCredential.limitsTabState.twoPersonRule,
		ThreatLevel: jsCredential.limitsTabState.threatLevel,
		Count: jsCredential.limitsTabState.limitCount,
		Tag: jsCredential.optionsTabState.tagAction,
		Alert: jsCredential.optionsTabState.alertAction,
		Disable: jsCredential.optionsTabState.disableAction,
		EnablePrintControl: jsCredential.optionsTabState.enablePrintControl,
		PrintControlConfirmation: jsCredential.optionsTabState.printControlConfirmation,
		ExecutiveOverride: jsCredential.optionsTabState.executiveOverride,
		SpecialNeeds: jsCredential.optionsTabState.specialNeeds,
		EnableIssueControl: jsCredential.optionsTabState.enableIssueControl,
		PrintControlAttempts: jsCredential.optionsTabState.printControlAttempts,
		PrintControlCount: jsCredential.optionsTabState.printControlCount,
		PrintControlMax: jsCredential.optionsTabState.printControlMax,
		CurrentIssueStatus: jsCredential.optionsTabState.currentIssueStatus,
		IssueControlCount: jsCredential.optionsTabState.issueControlCount,
		IssueControlMax: jsCredential.optionsTabState.issueControlMax,
		CredentialReissueInfo: jsCredential.optionsTabState.credentialReissueInfo,
		CredentialFunctions: jsCredential.functionsTabState.credentialFunctions as CredentialFunction[],
		HostExpirationUDF: jsCredential.generalTabState.activationExpirationSectionState.hostExpirationUDF,
		HostExpirationInterval: jsCredential.generalTabState.activationExpirationSectionState.hostExpirationInterval,
		HostExpirationOffset: jsCredential.generalTabState.activationExpirationSectionState.hostExpirationOffset,
		UpdateConcatenatedFields: jsCredential.generalTabState.cardSectionState.saveConcatenatedData,
		ConcatenatedUDFFields: jsCredential.generalTabState.cardSectionState.concatenatedData,
		LinkedTemplateId: jsCredential.generalTabState.informationSectionState.linkedTemplateId,
		IsLinked: jsCredential.generalTabState.informationSectionState.linkToCredential,
		PinOverrideAnswer: jsCredential.PinOverrideAnswer,
		CodeOverrideAnswer: jsCredential.CodeOverrideAnswer,
		DualOverrideAnswer: jsCredential.DualOverrideAnswer,
	};
	return credential as CredentialDetail;
};

export const getRelativeTimeShortcutIndex = (interval: number, offsetOrTime: string, relativeTimeShortcuts: RelativeShortcut[]): number => {
	let timeShortcutIndex = -1;

	if (interval < 1 || interval > 5) {
		timeShortcutIndex = -1;
	} else if (relativeTimeShortcuts) {
		timeShortcutIndex = relativeTimeShortcuts.findIndex(x => x.Interval === interval && x.OffSetOrTime === offsetOrTime);
	}

	return timeShortcutIndex;
};

const applyIntervalAndOffsetToDate = (interval: number, offset: string): Date => {
	let date = new Date();

	switch (interval) {
		case 1:
			const offSetHour = offset.split(':');
			date.setHours(parseInt(offSetHour[0], 10), parseInt(offSetHour[1], 10));
			break;
		case 2:
			const day = Number(offset);
			date.setDate(date.getDate() + day);
			break;
		case 3:
			const week = Number(offset) * 7;
			date.setDate(date.getDate() + week);
			break;
		case 4:
			const month = Number(offset);
			date.setMonth(date.getMonth() + month);
			break;
		case 5:
			const year = Number(offset);
			date.setFullYear(date.getFullYear() + year);
			break;
	}

	return date;
};

const getUserDefinedSetup = (fieldNumber: number, udfSetups: UserDefinedFieldSetup[]): UserDefinedFieldSetup => {
	let setup: UserDefinedFieldSetup = null;
	for (let i = 0; i < udfSetups.length; i++) {
		if (udfSetups[i].FieldNumber == fieldNumber) {
			setup = udfSetups[i];
		}
	}
	return setup;
};

export const is200BitCardType = (val: number): boolean => {
	return val == 45 || val == 46;
};

export const getMomentDate = (date: string, dateFormat: string) => {
	if (date === '') {
		return moment(new Date(), dateFormat);
	}

	const newDate: Date = new Date(date);
	return moment(newDate, dateFormat);
};

export const filterDoorGroups = (credFunctions: Partial<CredentialFunction>[], doorGroups: DoorGroup[]): DoorGroup[] => {
	let tempDg: DoorGroup[] = doorGroups?.length > 0 ? [...doorGroups] : [];
	if (credFunctions.length > 0) {
		credFunctions.forEach(cf => {
			tempDg = tempDg.filter(lDg => {
				if (cf.IsMasterDoorGroup) {
					if (cf.DisplayName !== lDg.Name && cf.MasterDoorGroupId !== lDg.DoorGroupId) return lDg;
				} else {
					if (cf.DisplayName !== lDg.Name || cf.HostZone !== lDg.DoorGroupId) return lDg;
				}
			});
		});
	}
	return tempDg;
};

export const getDateISOString = (dateString: Date) => moment(dateString).toISOString(true);

export const getCredentialColumnRender = (value, row: CredentialGridItem, column: GridDataColumnItem) => {
	let text: string = undefined;
	let node: React.ReactNode = undefined;

	switch (column.Key) {
		case CredentialColumnsKeys[CredentialColumnsKeys.fTagUser]:
		case CredentialColumnsKeys[CredentialColumnsKeys.fAlertUser]:
			const boolValue: boolean = row[column.Key] as boolean;
			if (boolValue) {
				node = <CheckOutlined />;
			}
			break;

		case CredentialColumnsKeys[CredentialColumnsKeys.HostExpirationDate]:
			const matches = value.match(/[-]/g);
			if (matches?.length === 2) {
				text = parseDateTimeString(value);
			} else {
				text = value;
			}
			break;

		default:
			if (column.IsDateFormat) {
				text = parseDateTimeString(value);
			} else {
				text = value;
			}
			break;
	}

	return (
		<span>
			{text && text}
			{node && node}
		</span>
	);
};

import { Layout } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import { accountApi } from './api';
import { Footer, Header } from './components/common';
import { EventFilterPage } from './model/EventModel';
import {
	AccountPage,
	AlarmsPage,
	ConfigurationPage,
	DeviceControlPage,
	EnrollmentPage,
	EventPage,
	PhotoCallUpPage,
	ReportsPage,
	StatusDashboardPage,
	StatusViewerPage,
	WhosInsidePage,
} from './pages/';

const footerStyles: CSSProperties = { padding: '0px' };

export default function App() {
	const sessionStrCollapsed = window.sessionStorage.getItem('collapsed');
	const sessionCollapsed = sessionStrCollapsed ? JSON.parse(sessionStrCollapsed) : false;
	const [collapsed, setCollapsed] = useState(sessionCollapsed);
	const [mainLayoutStyle, setMainLayoutStyle] = useState<CSSProperties>({ marginLeft: sessionCollapsed ? 50 : 200 });

	useEffect(() => {
		initConfiguration();
		accountApi.clearSecurityKeyRegistrationCache();
	}, []);

	let eventFilterPage: EventFilterPage = EventFilterPage.None;
	let addHeader: boolean = true;
	let addFooter: boolean = true;
	let content: React.ReactNode = <></>;
	let containerClassName: string = 'mainContentContainer';
	let userDropdownClassName: string = 'userProfileDropdownContainer';

	const url = window.location.href.toLowerCase();
	if (url.indexOf('photocallup/photocallupdashboard'.toLowerCase()) > -1) {
		eventFilterPage = EventFilterPage.PhotoCallUp;
		content = <PhotoCallUpPage />;
	} else if (url.indexOf('enrollment/enrollmentmanager'.toLowerCase()) > -1) {
		content = <EnrollmentPage />;
		eventFilterPage = EventFilterPage.Enrollment;
	} else if (url.indexOf('status/statusdashboard'.toLowerCase()) > -1) {
		content = <StatusDashboardPage />;
		eventFilterPage = EventFilterPage.StatusDashboard;
	} else if (url.indexOf('statusviewer/statusviewer'.toLowerCase()) > -1) {
		content = <StatusViewerPage />;
		eventFilterPage = EventFilterPage.StatusViewer;
	} else if (url.indexOf('configuration/configurationmanager'.toLowerCase()) > -1) {
		content = <ConfigurationPage />;
	} else if (url.indexOf('deviceadmin/deviceadminmanager'.toLowerCase()) > -1) {
		content = <DeviceControlPage />;
		eventFilterPage = EventFilterPage.DeviceControl;
	} else if (url.indexOf('reports/reportmanager'.toLowerCase()) > -1) {
		content = <ReportsPage />;
		eventFilterPage = EventFilterPage.Reporting;
	} else if (url.indexOf('whosinside/whosinsidemanager'.toLowerCase()) > -1) {
		content = <WhosInsidePage />;
		eventFilterPage = EventFilterPage.WhosInside;
	} else if (url.indexOf('events/fulleventviewer'.toLowerCase()) > -1) {
		content = <EventPage />;
		eventFilterPage = EventFilterPage.Events;
	} else if (url.indexOf('events/eventviewer'.toLowerCase()) > -1) {
		content = <AlarmsPage />;
		eventFilterPage = EventFilterPage.Alarms;
	} else if (url.indexOf('/') > -1) {
		addFooter = false;
		addHeader = false;
		containerClassName = 'loginContentContainer';
		userDropdownClassName = 'loginUserDropdownContainer';
		content = <AccountPage />;
	}

	const handleOnCollapseMenu = () => {
		if (!collapsed)
			setTimeout(() => {
				setMainLayoutStyle({ marginLeft: 50 });
			}, 150);
		else setMainLayoutStyle({ marginLeft: 200 });
		window.sessionStorage.setItem('collapsed', (!collapsed).toString());
		setCollapsed(!collapsed);
	};

	return (
		<Layout className='mainLayout' hasSider>
			{addHeader && <Header collapsed={collapsed} handleOnCollapseMenu={handleOnCollapseMenu} />}
			<Layout className='appBody' style={mainLayoutStyle}>
				<input type='hidden' id='eventFilterPage' value={eventFilterPage} />
				<Layout.Content>
					<div className={containerClassName}>{content}</div>
				</Layout.Content>
				{addFooter && (
					<Layout.Footer style={footerStyles}>
						<Footer />
					</Layout.Footer>
				)}
			</Layout>
		</Layout>
	);
}

import { EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../api';
import {
	buildColumn,
	canSaveControllerControlZones,
	ColumnsProps,
	getDefaultTablePaginationConfig,
	getTimeZoneInfoByList,
	handleResponse,
	removeCommasAndPercentSign,
	ScrollType,
} from '../../../../../../../Helper';
import { getPermissionErrorMessage } from '../../../../../../../model/AccountModel';
import { ControlZonesComponentsCheck, StandardControlZonesEditor, TimeZoneInfo, UpdateControlZone } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setOpenTimeZones } from '../../../../../../../store/deviceControl/controller/actions';
import {
	setControlZonesEditMode,
	setControlZonesEditor,
	setControlZonesHasChangedName,
	setStandardControlZonesList,
} from '../../../../../../../store/deviceControl/controlZones/actions';
import { SearchColumn, Select } from '../../../../../../common';
import { EditableCell, EditableCellProps } from '../../../../../../common/EditableCell/EditableCell';
import styles from '../controlZones.module.scss';
import {
	selectorControllerId,
	selectorControllerTimeZoneMap,
	selectorEditionMode,
	selectorNewTimeZoneAdded,
	selectorStandardControlZones,
	selectorStandardControlZonesById,
	selectorStandardHeaders,
	selectorTimeZoneModal,
	selectorTimeZones,
} from '../selectors';

const scroll: ScrollType = { y: 560 };
const canSave = canSaveControllerControlZones();

const StandardControlZones: React.FC = () => {
	const headers = useStoreSelector(selectorStandardHeaders);
	const editor = useStoreSelector(selectorStandardControlZones);
	const editorById = useStoreSelector(selectorStandardControlZonesById);
	const timeZones = useStoreSelector(selectorTimeZones);
	const controllerId = useStoreSelector(selectorControllerId);
	const controlZonesTimeZoneMap = useStoreSelector(selectorControllerTimeZoneMap);
	const newTimeZoneAdded = useStoreSelector(selectorNewTimeZoneAdded);
	const newTimeZonModal = useStoreSelector(selectorTimeZoneModal);
	const isEditionMode = useStoreSelector(selectorEditionMode);

	const dispatch = useStoreDispatch();

	const [form] = Form.useForm<StandardControlZonesEditor>();
	const [editingKey, setEditingKey] = useState('');

	useEffect(() => {
		if (newTimeZonModal?.propertyType === 'StandardControlZones') {
			form.setFields([
				{
					name: 'GenericTimeZoneId',
					errors: [],
					value: newTimeZoneAdded,
				},
			]);
		}
	}, [newTimeZoneAdded]);

	const isEditing = (record: StandardControlZonesEditor) => record.Index.toString() === editingKey;

	const edit = (record: Partial<StandardControlZonesEditor>) => {
		dispatch(setControlZonesEditMode(true));
		let objectValues = {};
		headers.slice(3, headers.length - 3).forEach((x, index) => {
			objectValues = { ...objectValues, [x]: record.ComponentsArray[index].Value };
		});

		form.setFieldsValue({ ...objectValues, ...record });
		setEditingKey(record.Index.toString());
	};

	const componentsColumns: ColumnsProps<StandardControlZonesEditor>[] = headers.slice(3, headers.length - 3).map((x, index) => ({
		...buildColumn(x, x, '40px', 'start'),
		editable: true,
		render: (value, record) => <label>{record.ComponentsArray[index].Value ? '✓' : ''}</label>,
	}));

	const columns: ColumnsProps<StandardControlZonesEditor>[] = [
		{
			...buildColumn('', 'Action', editingKey !== '' ? '180px' : '100px', 'start'),
			fixed: 'left',
			render: (rest, record) => {
				const editable = isEditing(record);
				return editable ? (
					<div className={styles.flexAlignButtons}>
						<Button onClick={() => handleSave(record.Index, record.Id)} type='primary'>
							{_('Apply')}
						</Button>
						<Button onClick={() => handleCancel()}>{_('Revert')}</Button>
					</div>
				) : (
					<div className={styles.button}>
						{record.Index !== 0 && !isEditionMode && (
							<Button
								type='primary'
								onClick={() => edit(record)}
								title={getPermissionErrorMessage(canSave)}
								disabled={!canSave}
								shape='circle'
								icon={<EditOutlined />}
							/>
						)}
					</div>
				);
			},
		},
		{
			...buildColumn('#', 'Index', '40px', 'center'),
			fixed: 'left',
			sorter: (a, b) => a.Index - b.Index,
		},
		{
			...buildColumn(_('ControlZoneName'), 'Name', '180px', 'center'),
			fixed: 'left',
			sorter: (a, b) => a.Name.localeCompare(b.Name),
			editable: true,
			...SearchColumn({ maxLength: 100, dataIndex: 'Name', reset: undefined, label: _('ControlZoneName') }),
		},
		{
			...buildColumn(_('TimeZone'), 'GenericTimeZoneId', '140px', 'center'),
			fixed: 'left',
			editable: true,
			render: (value, record) => <label>{timeZones.find(x => x.GenericId === record.GenericTimeZoneId).Name}</label>,
		},
		...componentsColumns,
		{
			...buildColumn(_('Tag'), 'Tag', '40px', 'start'),
			editable: true,
			render: (value, record) => <label>{record.Tag ? '✓' : ''}</label>,
		},
		{
			...buildColumn(_('Alert'), 'Alert', '80px', 'start'),
			editable: true,
			render: (value, record) => <label>{record.Alert ? '✓' : ''}</label>,
		},
		{
			...buildColumn(_('DisableCredential'), 'DisableCredential', '140px', 'center'),
			editable: true,
			render: (value, record) => <label>{record.DisableCredential ? '✓' : ''}</label>,
		},
	];

	const handleSave = async (key: React.Key, id: number) => {
		const row = await form.getFieldsValue();
		const newData = { ...editor };
		const currentItem = newData[key].ComponentsArray;
		const item: ControlZonesComponentsCheck[] = Object.entries(row)
			.filter(([key, value]) => key !== 'DisableCredential' && key !== 'Tag' && key !== 'Alert' && key !== 'GenericTimeZoneId' && key !== 'Name')
			.map(([key, value], index) => ({ IsExpansionComponent: currentItem[index].IsExpansionComponent, Value: Boolean(value) }));

		const newItem: StandardControlZonesEditor = {
			DisableCredential: row.DisableCredential,
			GenericTimeZoneId: row.GenericTimeZoneId,
			Alert: row.Alert,
			Tag: row.Tag,
			ComponentsArray: item,
			Name: row.Name,
			Dirty: true,
			Index: Number(key),
			Id: id,
		};

		const standardControlZone: UpdateControlZone = {
			ControllerId: controllerId,
			ControllerTimeZoneMap: controlZonesTimeZoneMap,
			GenericTimeZones: timeZones,
			MasterControlZone: null,
			StandardControlZone: { ...newItem },
		};
		const response = await deviceAdminApi.updateStandardControlZone(standardControlZone);

		if (response.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [response.ErrorMessage],
				},
			]);
			return;
		}
		if (!handleResponse(response)) {
			const actionToDispatch = setControlZonesEditor({
				data: {
					...newItem,
				},
				propertyIndex: key.toString(),
			});
			dispatch(setStandardControlZonesList({ ControlZoneIndex: newData[key].Index, ControlZoneName: `CZ${newData[key].Index}-${row.Name}` }));
			dispatch(actionToDispatch);
			setEditingKey('');
			dispatch(setControlZonesEditMode(false));
			dispatch(setControlZonesHasChangedName(true));
		}
	};

	const handleCancel = () => {
		setEditingKey('');
		dispatch(setControlZonesEditMode(false));
	};

	const mapDenomArray = editorById.map(x => editor[x]);

	const handleOnChangeName = (e: React.ChangeEvent<HTMLFormElement>) => removeCommasAndPercentSign(e.target.value);

	const customComponent = (dataName: keyof StandardControlZonesEditor, record: StandardControlZonesEditor) => {
		const timeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(record?.GenericTimeZoneId, timeZones);
		const renderSwitch = () => {
			switch (dataName) {
				case 'GenericTimeZoneId':
					return (
						<Form.Item
							name={dataName}
							getValueFromEvent={value => {
								const timeZoneId = Number(value);
								if (timeZoneId === 0) {
									return record.GenericTimeZoneId;
								}
								return timeZoneId;
							}}>
							<Select
								style={{ width: 130 }}
								options={timeZoneInfo.TimeZoneList}
								notUseCurrentParse
								onChange={value => {
									if (Number(value) === 0) {
										dispatch(
											setOpenTimeZones({
												index: record.Index,
												open: true,
												propertyType: 'StandardControlZones',
											})
										);
									}
								}}
								disabled={timeZoneInfo.IsUnpermitted}
							/>
						</Form.Item>
					);
				case 'Name':
					return (
						<Form.Item name={dataName} getValueFromEvent={handleOnChangeName}>
							<Input maxLength={32} />
						</Form.Item>
					);
				default:
					return (
						<Form.Item name={dataName} valuePropName='checked'>
							<Checkbox />
						</Form.Item>
					);
			}
		};

		return renderSwitch();
	};

	const mergedColumns = columns.map(col =>
		!col.editable
			? col
			: {
					...col,
					onCell: (record: StandardControlZonesEditor) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						editing: isEditing(record),
					}),
			  }
	);

	return (
		<Form form={form} component={false}>
			<Table
				columns={mergedColumns as ColumnsType}
				dataSource={mapDenomArray}
				components={{
					body: {
						cell: (props: EditableCellProps<StandardControlZonesEditor>) =>
							EditableCell({
								...props,
								customComponent: customComponent(props.dataIndex as keyof StandardControlZonesEditor, props.record),
							}),
					},
				}}
				scroll={scroll}
				pagination={getDefaultTablePaginationConfig(isEditionMode)}
				size='small'
				rowKey={(record: StandardControlZonesEditor) => record.Index}
				className={styles.statusWidgetTable}
			/>
		</Form>
	);
};

const memoizedComponent = React.memo(StandardControlZones);
export { memoizedComponent as StandardControlZones };

import { UpOutlined } from '@ant-design/icons';
import { Collapse, Spin, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { ScrollType, buildColumn, getDefaultTablePaginationConfig } from '../../../Helper';
import { statusApi } from '../../../api';
import constants from '../../../constants';
import { Logger } from '../../../model/LoggingModel';
import { UserModule, WidgetInfo } from '../../../model/StatusModel';
import './onlineActiveUsers.scss';

const { Panel } = Collapse;

// Internal state for the component
interface State {
	isLoading: boolean;
	data: UserModule[];
	isExpanded: boolean;
}

// Normal properties for the component
interface OwnProps {
	widgetInfo: WidgetInfo;
}

// combine them together
type Props = OwnProps;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: '30em', y: 254 };
const pagination: TablePaginationConfig = getDefaultTablePaginationConfig();

const columns: ColumnsType<any> = [
	{
		...buildColumn(_('Operator'), 'Operator', undefined, 'left'),
		sorter: (a, b) => a.Operator.localeCompare(b.Operator),
	},
	{
		...buildColumn(_('LoggedInTimeHostTime'), 'LoggedInDate', undefined, 'left'),
		sorter: (a, b) => a.LoggedInDate.localeCompare(b.LoggedInDate),
	},
	{
		...buildColumn(_('IPAddress'), 'IP', undefined, 'left'),
		sorter: (a, b) => a.IP.localeCompare(b.IP),
	},
];
const HeaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{_('OnlineActiveUsers')}</span>);

class OnlineActiveUsers extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isLoading: true,
			data: null,
			isExpanded: true,
		};

		//We use this flag to avoid multiple api calls at the same time
		window.sessionStorage.setItem(constants.sessionStorage.statusDashboard.RETRIEVE_ONLINE_ACTIVE_USERS_FLAG, '0');
	}

	componentDidMount() {
		this.fetchData();
	}

	componentWillUnmount() {
		this.clearFetchTimeout();
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	clearFetchTimeout() {
		if (this.autoRefreshPerformanceLoop) {
			clearTimeout(this.autoRefreshPerformanceLoop);
		}
	}

	private autoRefreshPerformanceLoop: null | ReturnType<typeof setTimeout> = null;

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		const { isExpanded } = this.state;

		if (nextState.isExpanded) {
			this.clearFetchTimeout();

			if (!isExpanded) {
				this.fetchData();
			} else {
				const { widgetInfo } = this.props;

				if (widgetInfo.autoRefresh) {
					this.autoRefreshPerformanceLoop = setTimeout(() => {
						this.fetchData();
					}, widgetInfo.autoRefreshTimer * 1000);
				}
			}
		}

		return JSON.stringify(this.state.data) !== JSON.stringify(nextState.data);
	}

	fetchData() {
		const sessionStorageKey = constants.sessionStorage.statusDashboard.RETRIEVE_ONLINE_ACTIVE_USERS_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');

			statusApi
				.retrieveOnlineActiveUsers()
				.then(response => {
					window.sessionStorage.setItem(sessionStorageKey, '0');
					this.setState({
						isLoading: false,
						data: response,
					});
				})
				.catch(e => Logger.writeErrorLog(e));
		}
	}

	handleOnChange(isExpanded) {
		if (isExpanded) {
			this.setState({
				isLoading: true,
				isExpanded: true,
			});
		} else {
			this.setState({
				isExpanded: false,
			});
		}
	}

	render() {
		const { isLoading, data } = this.state;

		let content;
		if (!isLoading) {
			let tableData = [];
			if (data && data.length) {
				tableData = data.map((user: UserModule) => {
					return {
						key: user.Identity,
						Operator: user.Identity,
						IP: user.IP,
						LoggedInDate: FormatDate(user.LoggedInDate, true, null, true),
					};
				});
			}

			content = (
				<Table
					pagination={pagination}
					id='OnlineActiveUsersTable'
					columns={columns}
					dataSource={tableData}
					scroll={scroll}
					size='small'
					className='status-widget-table'
				/>
			);
		}

		return (
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className='spin-container'>
				<div className='online-active-users-container'>
					<Collapse
						bordered={true}
						defaultActiveKey={'1'}
						expandIconPosition={'right'}
						expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
						onChange={(keys: string[]) => {
							this.handleOnChange(keys.length === 1);
						}}
						className={'status-widget-collapse'}>
						<Panel id='Widget-OnlineActiveUsers' header={<HeaderWidget />} key='1' className='status-widget status-widget-collapse-content'>
							{content}
						</Panel>
					</Collapse>
				</div>
			</Spin>
		);
	}
}

export { OnlineActiveUsers };

import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space } from 'antd';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { removeCommasAndPercentSign, useDispatch } from '../../../Helper';
import styles from './searchColumn.module.scss';

type Props = {
	dataIndex?: string;
	reset?: boolean;
	label?: string;
	onValidateInput?: (keys: string) => string;
	maxLength?: number;
	notVisible?: boolean;
	resetSearch?: boolean;
	setIsFilterMode?: (value: boolean) => void;
	clearSelection?: () => void;
	handleResetSearch?: () => void;
	setSearchResults?: (value: string) => void;
	setSearchedValue?: (value: string) => void;
	searchColumnId?: string;
	setSearchPerformed?: (value: boolean) => void;
};

const SearchColumn = ({
	dataIndex = 'Name',
	reset = false,
	label = '',
	onValidateInput = (keys: string): string => removeCommasAndPercentSign(keys),
	maxLength,
	notVisible = false,
	resetSearch,
	setIsFilterMode,
	clearSelection,
	handleResetSearch,
	setSearchResults,
	setSearchedValue,
	searchColumnId,
	setSearchPerformed,
}: Props) => {
	const dispatchReduxAction = useDispatch();
	let searchInput = React.createRef<InputRef>();
	const resetButtonRef = useRef<HTMLButtonElement>();

	const [searchState, setSearchState] = useState({
		searchText: '',
		searchedColumn: '',
	});

	const [filteredInfo, setFilteredInfo] = useState<ColumnFilterItem[]>(null);

	useEffect(() => {
		if (reset) {
			setFilteredInfo(null);
			if (searchState.searchText) {
				clearSearch();
			}
		}
	}, [reset]);

	useEffect(() => {
		if (resetSearch && resetButtonRef) {
			resetButtonRef?.current?.click();
		}
	}, [resetSearch]);

	const handleSearch = (selectedKeys: React.Key[], confirm: () => void, dataIndex: string) => {
		if (Array.isArray(selectedKeys) && !selectedKeys.length) {
			return;
		}

		setSearchPerformed?.(true);
		confirm();

		const searchText = selectedKeys[0]?.toString();
		setSearchState({
			searchText,
			searchedColumn: dataIndex,
		});

		const filterName: ColumnFilterItem = {
			text: dataIndex,
			value: selectedKeys[0],
		};
		setFilteredInfo([filterName]);

		const { searchText: previousState } = searchState;

		if (previousState !== searchText) {
			clearSelection?.();
			setSearchedValue?.(filterName.value.toString());
			setSearchResults?.(filterName.value.toString());
		}

		if (setIsFilterMode) {
			dispatchReduxAction(setIsFilterMode(true));
		}
	};

	const clearSearch = () => {
		setSearchState({
			...searchState,
			searchText: '',
		});
		setFilteredInfo(null);

		clearSelection?.();
		handleResetSearch?.();

		if (setIsFilterMode) {
			dispatchReduxAction(setIsFilterMode(false));
		}

		setSearchedValue?.('');
		setSearchResults?.('');
	};

	const handleReset = (clearFilters: () => void, confirm: () => void) => {
		setSearchPerformed?.(true);
		clearFilters();
		clearSearch();
		confirm();
	};

	const filteredInfoValue = filteredInfo?.filter(x => x.text === dataIndex).map(x => x.value) ?? null;

	const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>, setSelectedKeys: (selectedKeys: React.Key[]) => void) => {
		const value = e.target.value;
		const formatInput = onValidateInput(value);
		const keys = formatInput ? [formatInput] : [];
		setSelectedKeys(keys);
		searchInput.current.input.value = formatInput;
	};

	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
			if (notVisible) return <></>;
			return (
				<div className={styles.container}>
					<Input
						id='searchColumnSearchInput'
						ref={searchInput}
						placeholder={`Search ${label || dataIndex}`}
						value={selectedKeys[0]}
						onChange={e => handleOnChangeInput(e, setSelectedKeys)}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						maxLength={maxLength}
					/>
					<Space>
						<Button
							id='searchColumnSearchButton'
							type='primary'
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							icon={<SearchOutlined />}
							size='small'>
							{_('Search')}
						</Button>
						<Button id='searchColumnResetButton' onClick={() => handleReset(clearFilters, confirm)} size='small' ref={resetButtonRef}>
							{_('Reset')}
						</Button>
						<Button
							id='searchColumnCloseButton'
							type='link'
							size='small'
							onClick={() => {
								confirm();
								setSearchState({
									searchText: selectedKeys[0]?.toString(),
									searchedColumn: dataIndex,
								});
							}}>
							{_('Close')}
						</Button>
					</Space>
				</div>
			);
		},
		filterIcon: (filtered: boolean) => {
			const styleDiv: React.CSSProperties = {
				height: '100%',
				borderRadius: 4,
				backgroundColor: filtered ? '#406080' : undefined,
				width: '34px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			};

			const styleForIcon: React.CSSProperties = {
				top: '56%',
				left: '42%',
				fontSize: '16px',
				color: filtered ? 'white' : 'black',
			};

			const filter = <SearchOutlined style={filtered ? { ...styleForIcon, color: 'white' } : styleForIcon} />;

			return (
				<div id={searchColumnId ? searchColumnId : 'searchColumn'} style={styleDiv}>
					{filter}
				</div>
			);
		},
		onFilter: (value, record) => {
			return record[dataIndex] && value ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '';
		},
		filteredValue: filteredInfoValue,
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => searchInput?.current?.select(), 100);
			}
		},
		render: (text: string) => text,
	};
};

export { SearchColumn };

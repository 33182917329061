import { FormInstance, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import React, { Key, useEffect, useRef, useState } from 'react';
import { getUniqueValuesArray } from '../../../../../../../Helper';
import { PersonDetailModel, PersonGroupModel, PersonGroups, TreeViewPersonGroupModel } from '../../../../../../../model/EnrollmentModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setHavePersonChanged } from '../../../../../../../store/enrollment/actions';
import {
	selectHavePersonChanged,
	selectPersonGroups,
	selectPersonId,
	selectPersonInformationPersonGroups,
	selectSelectedPersonGroup,
} from '../../../../../../../store/enrollment/selectors';
import { PersonImages } from '../../../../../../common';
import styles from './groups.module.scss';

type Props = {
	form: FormInstance<PersonDetailModel>;
	tabId: number;
};

const Groups: React.FC<Props> = ({ form, tabId }) => {
	const dispatch = useStoreDispatch();

	const [treeData, setTreeData] = useState<DataNode[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
	const personGroups: PersonGroups[] = useStoreSelector<PersonGroups[]>(selectPersonGroups);
	const currentPersonGroup: TreeViewPersonGroupModel = useStoreSelector<TreeViewPersonGroupModel>(selectSelectedPersonGroup);
	const currentPersonGroups: PersonGroupModel[] = useStoreSelector<PersonGroupModel[]>(selectPersonInformationPersonGroups);
	const havePersonChanged: boolean = useStoreSelector<boolean>(selectHavePersonChanged);
	const personId: number = useStoreSelector<number>(selectPersonId);

	const initialSelectedPersonGroup = useRef<string[]>([]);
	const firstRender = useRef<boolean>(true);
	const treeNodes = useRef<Map<string, Partial<PersonGroupModel>>>(new Map<string, Partial<PersonGroupModel>>());

	const personInformationPersonGroups: PersonGroupModel[] = form.getFieldsValue().PersonGroups;

	useEffect(() => {
		if (personInformationPersonGroups && personInformationPersonGroups.length > 0 && firstRender.current) {
			const groupTree: PersonGroups[] = personGroups.filter((personGroup: PersonGroups) => personGroup.FolderId !== -1 && personGroup.GroupId !== -1);
			const selectedPersonGroupsInformation: PersonGroupModel[] = (personInformationPersonGroups || currentPersonGroups).filter(
				(group: PersonGroupModel) => group.Selected
			);
			setTreeData(buildTreeData(groupTree, selectedPersonGroupsInformation));
			setCheckedKeys(initialSelectedPersonGroup.current);
			initialSelectedPersonGroup.current = [];
			firstRender.current = false;
		}
	}, [personInformationPersonGroups]);

	useEffect(() => {
		if (personId === -100) {
			const groupTree: PersonGroups[] = personGroups.filter((personGroup: PersonGroups) => personGroup.FolderId !== -1 && personGroup.GroupId !== -1);
			const currentPersonTreeData: Partial<PersonGroupModel>[] = currentPersonGroups || [
				{ PersonGroupId: currentPersonGroup.GroupId, PersonFolderId: currentPersonGroup.FolderId, Selected: true },
			];
			const currentTreeData: DataNode[] = buildTreeData(groupTree, currentPersonTreeData);
			setTreeData(currentTreeData);

			const currentPersonTreeKey: string[] = initialSelectedPersonGroup.current.length >= 1 ? initialSelectedPersonGroup.current : [''];
			initialSelectedPersonGroup.current = [];
			const checkedKeys: { checked: Key[]; halfChecked: Key[] } = { checked: [...currentPersonTreeKey], halfChecked: [] };
			setCheckedKeys(checkedKeys.checked);
			currentPersonTreeKey.forEach(x => {
				const checkInfo = {
					checked: true,
					node: {
						key: x,
					},
				};
				onCheck(checkedKeys, checkInfo, currentTreeData);
			});
		}
	}, [personId]);

	const buildTreeData = (personGroups: PersonGroups[] | TreeViewPersonGroupModel[], personTreeData: Partial<PersonGroupModel>[], key: string = '0') =>
		personGroups.map((group: PersonGroups, index: number) => {
			const groupKey: string = `${key}-${index}`;
			const isEveryone: boolean = group.GroupId === 1 && group.FolderId === 0;
			const isSelected: boolean = personTreeData.some(
				(personGroup: Partial<PersonGroupModel>) =>
					(personGroup.PersonGroupId === group.GroupId && personGroup.PersonFolderId === group.FolderId) ||
					(personGroup.PersonFolderId !== 0 && personGroup.PersonFolderId === group.FolderId)
			);

			if (isSelected || isEveryone) {
				initialSelectedPersonGroup.current.push(groupKey);
			}
			if (group.ChildGroups) {
				treeNodes.current.set(groupKey, { PersonGroupId: group.GroupId, PersonFolderId: group.FolderId });
				return {
					groupId: group.GroupId,
					folderId: group.FolderId,
					title: group.Name,
					key: groupKey,
					disabled: isEveryone,
					children: buildTreeData(group.ChildGroups, personTreeData, groupKey),
				};
			}
			treeNodes.current.set(groupKey, { PersonGroupId: group.GroupId, PersonFolderId: group.FolderId });
			return {
				groupId: group.GroupId,
				folderId: group.FolderId,
				title: group.Name,
				key: groupKey,
				disabled: isEveryone,
			};
		});

	const onCheck = (checkedKeys: { checked: Key[]; halfChecked: Key[] }, info, currentTreeData: DataNode[] = treeData): void => {
		let newCheckedKeys: Key[] = [];
		let selectedPersonGroups: Partial<PersonGroupModel>[] = [];
		if (info.checked) {
			const parentKeys: Key[] = getParentKeysArray(info.node.key, currentTreeData);
			newCheckedKeys = getUniqueValuesArray(checkedKeys.checked, parentKeys);
		} else {
			const childrenKeys: Key[] = getChildrenKeysArray(info.node.children);
			newCheckedKeys = checkedKeys.checked.filter(key => !childrenKeys.includes(key));
		}

		treeNodes.current.forEach((value: Partial<PersonGroupModel>, key: string) => {
			selectedPersonGroups.push({ ...value, Selected: newCheckedKeys.includes(key) });
		});
		setCheckedKeys(newCheckedKeys);
		form.setFieldsValue({
			...form.getFieldsValue(true),
			PersonGroups: selectedPersonGroups,
			PersonGroupModified: true,
		});

		if (!firstRender.current && !havePersonChanged) {
			dispatch(setHavePersonChanged(true));
		}
	};

	const getChildrenKeysArray = (treeData: DataNode[]): Key[] => {
		let parentsKeyArray: Key[] = [];

		treeData.forEach((node: DataNode & { groupId?: number; folderId?: number }) => {
			parentsKeyArray.push(node.key);
			if (node.children) {
				parentsKeyArray.push(...getChildrenKeysArray(node.children));
			}
		});

		return parentsKeyArray;
	};

	const getParentKeysArray = (key: Key, treeData: DataNode[]): Key[] => {
		let parentsKeyArray: Key[] = [];

		const parentKey: Key = getParentKey(key, treeData);
		if (parentKey !== undefined) {
			parentsKeyArray.push(parentKey, ...getParentKeysArray(parentKey, treeData));
		}

		return parentsKeyArray;
	};

	const getParentKey = (key: Key, tree: DataNode[]): Key => {
		let parentKey: Key;
		for (let i = 0; i < tree.length; i++) {
			const node: DataNode & { groupId?: number; folderId?: number } = tree[i];
			if (node.children) {
				if (node.children.some(item => item.key === key)) {
					parentKey = node.key;
				} else if (getParentKey(key, node.children)) {
					parentKey = getParentKey(key, node.children);
				}
			}
		}

		return parentKey;
	};

	return (
		<div className={styles.container}>
			<PersonImages form={form} tabId={tabId} />
			<Tree checkable onCheck={onCheck} checkedKeys={checkedKeys} treeData={treeData} checkStrictly height={550} aria-label={_('PersonGroups')} />
		</div>
	);
};

export { Groups };

import { createSelector } from 'reselect';
import { GridColumn } from '../../model/CommonModel';
import {
	ControllerToEdit,
	ExpandedRowItem,
	StatusGroupSelected,
	StatusObjectEnum,
	StatusViewer,
	StatusViewerGroup,
	StatusViewerPointByType,
} from './../../model/StatusViewerModel';
import { RootReducer } from './../rootReducer';

export const selectStatusGroups = createSelector(
	(state: RootReducer) => state.statusViewerReducer.statusGroups,
	(statusGroups: StatusViewerGroup[]) => statusGroups
);

export const selectControllerToEdit = createSelector(
	(state: RootReducer) => state.statusViewerReducer.controllerToEdit,
	(controllerToEdit: ControllerToEdit) => controllerToEdit
);

export const selectSelectedStatusGroup = createSelector(
	(state: RootReducer) => state.statusViewerReducer.selectedStatusGroup,
	(selectSelectedStatusGroup: StatusGroupSelected) => selectSelectedStatusGroup
);

export const selectStatusViewer = createSelector(
	(state: RootReducer) => state.statusViewerReducer.statusViewer,
	(statusViewer: StatusViewer) => statusViewer
);

export const selectStatusGroupDoorExpandedRow = createSelector(
	(state: RootReducer) => state.statusViewerReducer.statusGroupDoorExpandedRow,
	(statusGroupDoorExpandedRow: ExpandedRowItem[]) => statusGroupDoorExpandedRow
);

export const selectStatusViewerColumnConfiguration = createSelector(
	(state: RootReducer) => state.statusViewerReducer.statusViewerColumnConfiguration,
	(statusViewerColumnConfiguration: Record<StatusObjectEnum, GridColumn[]>) => statusViewerColumnConfiguration
);

export const selectIsLoadingStatusViewerTable = createSelector(
	(state: RootReducer) => state.statusViewerReducer.isLoadingStatusViewerTable,
	(isLoadingStatusViewerTable: boolean) => isLoadingStatusViewerTable
);

export const selectStatusViewerConfiguration = createSelector(
	(state: RootReducer) => state.statusViewerReducer.statusViewerConfiguration,
	(statusViewerConfiguration: StatusViewerGroup) => statusViewerConfiguration
);

export const selectIsLoadingStatusViewerConfigurationTable = createSelector(
	(state: RootReducer) => state.statusViewerReducer.isLoadingStatusViewerConfigurationTable,
	(isLoadingStatusViewerConfigurationTable: boolean) => isLoadingStatusViewerConfigurationTable
);

export const selectStatusViewerPointByType = createSelector(
	(state: RootReducer) => state.statusViewerReducer.statusViewerPointByType,
	(statusViewerPointByType: StatusViewerPointByType) => statusViewerPointByType
);

import { configurationApi } from '../../api/';
import constants from '../../constants';
import { PhotoCallUpUserConfiguration } from '../../model/PhotoCallUpModel';
import { closeConfiguration, configurationSaveNotification, PhotoCallUpAction, setPhotoCallUpUserConfiguration } from './actions';
import { PhotoCallUpActionTypes } from './actionTypes';

const formValidationMiddleware =
	({ dispatch }) =>
	next =>
	(action: PhotoCallUpAction) => {
		switch (action.type) {
			default:
				next(action);
				break;
		}
	};

const apiMiddleware =
	({ dispatch, getState }) =>
	next =>
	(action: PhotoCallUpAction) => {
		switch (action.type) {
			case PhotoCallUpActionTypes.SAVE_CONFIGURATION: {
				const photoCallUpUserConfiguration: PhotoCallUpUserConfiguration = action.payload;

				const { photoCallUpReducer } = getState();
				let refreshRequired =
					photoCallUpUserConfiguration.QuantityPerDoor > photoCallUpReducer.quantityPerDoor ||
					(photoCallUpUserConfiguration.LoadCachedEntries && !photoCallUpReducer.loadCachedEntries);

				if (!refreshRequired) {
					photoCallUpUserConfiguration.SelectedDoors.forEach(door => {
						const { DoorId: doorId } = door;
						if (doorId > 0) {
							const found = photoCallUpReducer.selectedDoors.find(d => d.DoorId === doorId);
							if (!found && photoCallUpUserConfiguration.LoadCachedEntries) {
								refreshRequired = true;
							}
						}
					});
				}

				configurationApi.savePhotoCallUpConfiguration(photoCallUpUserConfiguration).then(data => {
					dispatch(configurationSaveNotification(refreshRequired ? 2 : 1));
					dispatch(setPhotoCallUpUserConfiguration(photoCallUpUserConfiguration));
					dispatch(closeConfiguration());

					const sessionStorageKey = constants.sessionStorage.photoCallUp.RETRIEVE_SAVE_CONFIGURATION_FLAG;
					window.sessionStorage.setItem(sessionStorageKey, '0');
				});
				break;
			}

			default:
				next(action);
				break;
		}
	};

const configurationMiddleware = [formValidationMiddleware, apiMiddleware];

export { configurationMiddleware };

import { Button } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { TimeZoneType } from '../../../../../../../model/DeviceAdminModel';
import { Modal, Select } from '../../../../../../common';
import { StoreContext, setEditTimeZoneHasValue, setErrors } from '../../context';
import styles from './editTimeZone.module.scss';

type Props = {
	onOkControlZonesEdit: () => void;
	onCancel: () => void;
};

const EditTimeZone: React.FC<Props> = ({ onOkControlZonesEdit, onCancel }) => {
	const {
		readerState: {
			timeZones,
			errors: { timeZoneEditInput },
		},
		dispatcher,
	} = useContext(StoreContext);

	const handleChangeControlZone = (controlZoneId: string) => {
		const currentControlZone = timeZones.find(x => x.GenericTimeZoneId === Number(controlZoneId));
		dispatcher(setEditTimeZoneHasValue(currentControlZone));
		dispatcher(setErrors({ type: 'timeZoneEditInput', value: false }));
	};

	const mainDivContainerId = 'DoorGroupTimeZoneEditDropdownContainer';
	const doorGroupModalEditTimeZonesDropdownId = 'doorGroupModalEditTimeZonesDropdown';

	return (
		<Modal
			footer={[
				<Button key='save' id='doorGroupModalEditTimeZonesSaveButton' type='primary' onClick={() => onOkControlZonesEdit()}>
					{_('Ok')}
				</Button>,
				<Button key='cancel' onClick={() => onCancel()}>
					{_('Cancel')}
				</Button>,
			]}
			customZoomClass={styles.withModalZoom}
			width='900px'
			visible={true}
			title={_('EditTimeZone')}
			onCancel={() => onCancel()}
			onClickOk={() => null}>
			<div className={styles.container} id={mainDivContainerId}>
				<label htmlFor={doorGroupModalEditTimeZonesDropdownId}>{_('TimeZones')}</label>
				<div className={cx({ [styles.error]: timeZoneEditInput })}>
					<Select
						id={doorGroupModalEditTimeZonesDropdownId}
						onChange={handleChangeControlZone}
						options={timeZones
							.filter(x => x.Type !== TimeZoneType.New)
							.map((x, index) => ({
								label: x.Name,
								value: x.GenericTimeZoneId,
								id: `doorGroupModalEditTimeZonesDropdownOption-${index.toString()}`,
							}))}
					/>
					{timeZoneEditInput && <label htmlFor={doorGroupModalEditTimeZonesDropdownId}>{_('TimeZoneIsRequired')}</label>}
				</div>
			</div>
		</Modal>
	);
};

export { EditTimeZone };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../../model/CommonModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderEnableHexPassthrough, setExitReaderEnableMatchBezelTamper, setExitReaderSpecialHandlingInstruction } from '../../../exitReaderContext';
import styles from '../../CardReaderSetup/cardreadersetup.module.scss';

const MatchCardReaderInterface: React.FC = () => {
	var {
		contextStateDoor: {
			exitReader: {
				enableMatchBezelTamper,
				enableHexPassThrough,
				specialHandlingInstructions,
				selections: { specialHandlingInstruction },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [specialInstructions, setSpecialInstructions] = useState<SelectOptions<string>[]>([]);

	useEffect(() => {
		setSpecialInstructions(specialHandlingInstructions.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [specialHandlingInstructions]);

	const handleOnSetEnableMatchBezelTamper = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderEnableMatchBezelTamper(value.target.checked));
	};

	const handleOnSetEnableHexPassthrough = (value: CheckboxChangeEvent) => {
		dispatcherDoor(setExitReaderEnableHexPassthrough(value.target.checked));
	};

	const handleOnSetSpecialHandlingInstruction = (value: string) => {
		dispatcherDoor(setExitReaderSpecialHandlingInstruction(Number(value)));
	};

	return (
		<div className={styles.cardReaderInterface}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('MatchDS47Interface')}</legend>
					<div>
						<div className={styles.sections}>
							<Checkbox checked={enableMatchBezelTamper} onChange={handleOnSetEnableMatchBezelTamper} id={'matchBezel'}>
								<span>{_('EnableMatchBezel')}</span>
							</Checkbox>
						</div>
						<div className={styles.sections}>
							<Checkbox checked={enableHexPassThrough} onChange={handleOnSetEnableHexPassthrough} id={'hexPassthrough'}>
								<span>{_('EnableWiegandHex')}</span>
							</Checkbox>
						</div>
						<div className={styles.sections}>
							<span>{_('SpecialHandling')}</span>
							<div>
								<Select
									className={styles.select}
									options={specialInstructions}
									onChange={handleOnSetSpecialHandlingInstruction}
									key='specialHangling'
									id='exitReadeModalspecialHanglingSelect'
									value={specialHandlingInstruction.toString()}
									getPopupContainer={() => document.getElementById('cardExitReaderContainer')}
								/>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { MatchCardReaderInterface };

import { Spin, Table } from 'antd';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { statusApi } from '../../../api/StatusApi';
import { buildColumn, handleResponse, ScrollType } from '../../../Helper';
import { ResponseStatusCode } from '../../../model/CommonModel';
import { OffNormalData } from '../../../model/StatusViewerModel';
import { Modal } from '../../common';
import styles from './offNormalModal.module.scss';

type Props = {
	onClose: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: '55vh' };

const OffNormalModal: React.FC<Props> = ({ onClose }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [dataSource, setDataSource] = useState<OffNormalData[]>([]);
	useEffect(() => {
		statusApi
			.retrieveOffNormalPoints()
			.then(response => {
				if (response.ResponseStatusCode === ResponseStatusCode.Success) {
					const dataSource = response.List.map((x, index) => ({ ...x, key: index, Type: _(x.Type) }));
					setDataSource(dataSource);
				} else {
					handleResponse(response);
					onClose();
				}
			})
			.finally(() => setIsLoading(false));
	}, []);

	const columns: ColumnProps<OffNormalData>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
		},
		{
			...buildColumn(_('Type'), 'Type', '95px', 'start'),
		},
		{
			...buildColumn(_('Address'), 'Address', '250px', 'start'),
		},
	];

	return (
		<Modal
			title={_('OffNormalPoints')}
			width='700px'
			visible={true}
			onCancel={() => {
				onClose();
			}}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div className={styles.container}>
					<Table
						scroll={scroll}
						size='small'
						columns={columns as ColumnsType}
						dataSource={dataSource}
						className={styles.statusWidgetTable}
						pagination={false}
					/>
				</div>
			</Spin>
		</Modal>
	);
};

export { OffNormalModal };

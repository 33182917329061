import { Spin, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { canAddNewTimeZones } from '../../../../../Helper';
import { deviceAdminApi } from '../../../../../api/DeviceAdminApi';
import { SubPermissions, User } from '../../../../../model/AccountModel';
import { CurrentDeviceControlObj, DeviceObjectType, Input, InputLogicTimeZones, SelectTimeZone, TimeZoneType } from '../../../../../model/DeviceAdminModel';
import { Modal } from '../../../../common';
import { TimeZones } from '../../../VelocityConfiguration/TimeZones/TimeZones';
import { DoorState, setDoorNewTimeZoneAction } from '../../DoorModal/DoorModalContext';
import { DoorStoreSingleContext } from '../../DoorModal/DoorStoreSingleContext';
import {
	ReaderState,
	StoreContext,
	initInputAction,
	setControlZonesAction,
	setDisableDuringTimezoneAction,
	setDisableTimezoneAction,
	setInputAction,
	setLineModulesAction,
	setMaskDuringTimezoneAction,
	setMaskTimezoneAction,
	setOpenNewTimezoneModalAction,
} from '../inputModalContext';
import { General } from '../tabs/General/General';
import styles from '../tabs/General/general.module.scss';
import { Logic } from '../tabs/Logic/Logic';
import { StateChangeReporting } from '../tabs/StateChangeReporting/StateChangeReporting';

const { TabPane } = Tabs;

type Props = {
	currentDeviceObj: CurrentDeviceControlObj;
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
	setLoading: (boolean) => void;
	isLoading: boolean;
	errorText: string;
	useDoorContext: boolean;
	previousSelection: InputLogicTimeZones;
	setErrorText?: () => void;
	onRefreshTimeZones?: (timeZones: SelectTimeZone[]) => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();

const InputContainer: React.FC<Props> = ({
	currentDeviceObj,
	nameValidation,
	nameError,
	onResetNameError,
	isLoading,
	setLoading,
	errorText,
	useDoorContext,
	previousSelection,
	setErrorText,
	onRefreshTimeZones,
}) => {
	const [canViewGeneralTab, setCanViewGeneralTab] = useState<boolean>(false);
	const [canViewLogicTab, setCanViewLogicTab] = useState<boolean>(false);
	const [canViewStateChangeTab, setCanViewStateChangeTab] = useState<boolean>(false);
	const [newTimeZoneId, setNewTimeZoneId] = useState<number>(0);
	const [activeTab, setActiveTab] = useState('1');
	const [previousSelections, setPreviousSelections] = useState<InputLogicTimeZones>({ MaskDuring: null, DisableEntry: null });

	let inputContext = { context: null as ReaderState, dispatcher: null };
	let doorContext = { context: null as DoorState, dispatcher: null };
	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		doorContext = { context: contextStateDoor?.door, dispatcher: dispatcherDoor };
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	useEffect(() => {
		if (canViewGeneralTab) {
			setActiveTab('1');
		} else if (canViewLogicTab) {
			setActiveTab('2');
		} else if (canViewStateChangeTab) {
			setActiveTab('3');
		}
	}, [canViewGeneralTab, canViewLogicTab, canViewStateChangeTab]);

	useEffect(() => {
		if (currentDeviceObj.Id !== 0) {
			if (currentDeviceObj.DeviceObjectType === DeviceObjectType.ExpansionInputs) {
				setCanViewGeneralTab(User.getSubComponentPermission(user, SubPermissions.ExpansionInput_ViewGeneralTab).allowed);
				setCanViewLogicTab(User.getSubComponentPermission(user, SubPermissions.ExpansionInput_ViewLogicTab).allowed);
				setCanViewStateChangeTab(User.getSubComponentPermission(user, SubPermissions.ExpansionInput_ViewStateChangeReportingTab).allowed);

				fetchInputData();
				deviceAdminApi.getGenericsControlTimeZonesByInput(currentDeviceObj.Id, true).then(res => inputContext.dispatcher(setControlZonesAction(res)));
				deviceAdminApi.getLineModulesForInputs().then(res => inputContext.dispatcher(setLineModulesAction(res)));
			} else if (currentDeviceObj.DeviceObjectType === DeviceObjectType.Input) {
				setCanViewGeneralTab(useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Input_ViewGeneralTab).allowed);
				setCanViewLogicTab(useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Input_ViewLogicTab).allowed);
				setCanViewStateChangeTab(
					useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Input_ViewStateChangeReportingTab).allowed
				);

				if (!useDoorContext) {
					fetchInputData();
					deviceAdminApi
						.getGenericsControlTimeZonesByInput(currentDeviceObj.Id, false)
						.then(res => inputContext.dispatcher(setControlZonesAction(res)));
					deviceAdminApi.getLineModulesForInputs().then(res => inputContext.dispatcher(setLineModulesAction(res)));
				}
			}
		}
	}, [currentDeviceObj.Id]);

	const fetchInputData = () => {
		if (currentDeviceObj.DeviceObjectType === DeviceObjectType.ExpansionInputs) {
			deviceAdminApi.getExpInput(currentDeviceObj.Id).then(res => {
				if (!canAddNewTimeZones()) {
					const genericTimezones = res.GenericTimeZones.filter(item => item.GenericId !== 0);
					res.GenericTimeZones = [...genericTimezones];
				}

				inputContext.dispatcher(initInputAction(res as Partial<Input>));
				setPreviousSelections({ MaskDuring: res.MaskDuringTimeZoneSelected, DisableEntry: res.DisabledDuringTimeZoneSelected });
			});
		} else if (currentDeviceObj.DeviceObjectType === DeviceObjectType.Input) {
			if (!useDoorContext) {
				deviceAdminApi.getInput(currentDeviceObj.Id).then(res => {
					if (!canAddNewTimeZones()) {
						const genericTimezones = res.GenericTimeZones.filter(item => item.GenericId !== 0);
						res.GenericTimeZones = [...genericTimezones];
					}
					inputContext.dispatcher(initInputAction(res as Partial<Input>));
					setPreviousSelections({ MaskDuring: res.MaskDuringTimeZoneSelected, DisableEntry: res.DisabledDuringTimeZoneSelected });
				});
			} else {
				setPreviousSelections(previousSelection);
			}
		}
	};

	useEffect(() => {
		if (inputContext.context?.input && inputContext.context?.controlZones && inputContext.context?.lineModules) {
			setLoading(false);
		}
	}, [inputContext.context?.input, inputContext.context?.controlZones, inputContext.context?.lineModules]);

	const fetchNewTimeZones = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: false,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};

				genericTimeZones.unshift(newTimeZone);
			}

			inputContext.dispatcher(setInputAction({ GenericTimeZones: genericTimeZones }));

			if (inputContext.context?.maskDuringTimezoneSelected === 0) {
				const maskDuring = genericTimeZones.find(x => x.GenericId === newTimeZoneId);

				inputContext.dispatcher(setMaskTimezoneAction(maskDuring));
				inputContext.dispatcher(setMaskDuringTimezoneAction(newTimeZoneId));
			} else if (inputContext.context?.disableDuringTimezoneSelected === 0) {
				const disableDuring = genericTimeZones.find(x => x.GenericId === newTimeZoneId);

				inputContext.dispatcher(setDisableTimezoneAction(disableDuring));
				inputContext.dispatcher(setDisableDuringTimezoneAction(newTimeZoneId));
			}
			inputContext.dispatcher(setOpenNewTimezoneModalAction(false));
		});
	};

	useEffect(() => {
		if (useDoorContext) {
			inputContext.dispatcher(setDoorNewTimeZoneAction(newTimeZoneId));
		} else {
			fetchNewTimeZones();
		}
	}, [newTimeZoneId]);

	useEffect(() => {
		if (doorContext.context?.newTimeZoneAdded) {
			fetchNewTimeZones();
		}
	}, [doorContext.context?.newTimeZoneAdded]);

	const handleCloseNewTimeZone = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: false,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};

				genericTimeZones.unshift(newTimeZone);
			}
			if (useDoorContext) {
				onRefreshTimeZones?.(genericTimeZones);
			} else {
				inputContext.dispatcher(setInputAction({ GenericTimeZones: genericTimeZones }));
			}

			const neverId: number = 1;
			if (inputContext.context?.maskDuringTimezoneSelected === 0) {
				const { GenericId } = previousSelections.MaskDuring;
				let existTimeZone = genericTimeZones.find(x => x.GenericId === GenericId);
				if (!existTimeZone) {
					existTimeZone = genericTimeZones.find(x => x.GenericId === neverId);
				}
				inputContext.dispatcher(setMaskDuringTimezoneAction(existTimeZone.GenericId));
				inputContext.dispatcher(setMaskTimezoneAction(existTimeZone));
			} else if (inputContext.context?.disableDuringTimezoneSelected === 0) {
				const { GenericId } = previousSelections.DisableEntry;
				let existTimeZone = genericTimeZones.find(x => x.GenericId === GenericId);
				if (!existTimeZone) {
					existTimeZone = genericTimeZones.find(x => x.GenericId === neverId);
				}
				inputContext.dispatcher(setDisableDuringTimezoneAction(existTimeZone.GenericId));
				inputContext.dispatcher(setDisableTimezoneAction(existTimeZone));
			}
			inputContext.dispatcher(setOpenNewTimezoneModalAction(false));
		});
	};

	useEffect(() => {
		if (nameError) {
			setActiveTab('1');
		}
	}, [nameError]);

	useEffect(() => {
		fetchNewTimeZones();
	}, []);

	return (
		<div className={styles.container}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default'>
				<Tabs activeKey={activeTab} onChange={activeKey => setActiveTab(activeKey)} type={useDoorContext ? null : 'card'}>
					{canViewGeneralTab && (
						<TabPane
							tab={
								useDoorContext ? (
									<span id='doorModalInputSetUpTabName'>{_('SetUp')}</span>
								) : (
									<span id='inputGeneralTabName'>{_('General')}</span>
								)
							}
							key='1'>
							<General
								nameValidation={nameValidation}
								nameError={nameError}
								errorText={errorText}
								onResetNameError={onResetNameError}
								currentDeviceObjType={currentDeviceObj.DeviceObjectType}
								useDoorContext={useDoorContext}
								setErrorText={setErrorText}
							/>
						</TabPane>
					)}
					{canViewLogicTab && (
						<TabPane tab={<span id={useDoorContext ? 'doorInputLogicTabName' : 'inputLogicTabName'}>{_('Logic')}</span>} key='2'>
							<Logic setPreviousSelections={setPreviousSelections} previousSelections={previousSelections} useDoorContext={useDoorContext} />
						</TabPane>
					)}
					{canViewStateChangeTab && (
						<TabPane
							tab={
								<span id={useDoorContext ? 'doorInputStateChangeReportingTabName' : 'inputStateChangeReportingTabName'}>
									{_('StateChangeReporting')}
								</span>
							}
							key='3'>
							<StateChangeReporting useDoorContext={useDoorContext} />
						</TabPane>
					)}
				</Tabs>
			</Spin>
			{inputContext.context?.openNewTimezoneModal && (
				<Modal
					keyboard={false}
					maskClosable={false}
					visible
					title={_('TimeZones')}
					onClickOk={handleCloseNewTimeZone}
					onCancel={handleCloseNewTimeZone}
					width={'900px'}
					footer={null}>
					<TimeZones onEntityAction={setNewTimeZoneId} disableDelete />
				</Modal>
			)}
		</div>
	);
};

export { InputContainer };

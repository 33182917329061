import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../../model/CommonModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderFromZone, setExitReaderToZone } from '../../../exitReaderContext';
import styles from '../../Logic/logic.module.scss';

const PassbackZones: React.FC = () => {
	var {
		contextStateDoor: {
			exitReader: {
				passbackZones,
				selections: { toZone, fromZone },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [passbackZonesList, setPassbackZonesList] = useState<SelectOptions<string>[]>([]);

	useEffect(() => {
		setPassbackZonesList(passbackZones.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [passbackZones]);

	const handleOnSetFromZone = (value: string) => {
		dispatcherDoor(setExitReaderFromZone(Number(value)));
	};

	const handleOnSetToZone = (value: string) => {
		dispatcherDoor(setExitReaderToZone(Number(value)));
	};

	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('PassbackZones')}</legend>
					<div>
						<div className={styles.passbackSection}>
							<span>{_('FromZone')}</span>
							<div>
								<Select
									className={styles.select}
									options={passbackZonesList}
									onChange={handleOnSetFromZone}
									key='fromZone'
									id='exitReaderFromZoneSelect'
									value={fromZone.toString()}
									getPopupContainer={() => document.getElementById('logicExitReaderContainer')}
								/>
							</div>
						</div>
						<div className={styles.passbackSection}>
							<span>{_('ToZone')}</span>
							<div>
								<Select
									className={styles.select}
									options={passbackZonesList}
									onChange={handleOnSetToZone}
									key='toZone'
									id='exitReaderToZoneSelect'
									value={toZone.toString()}
									getPopupContainer={() => document.getElementById('logicExitReaderContainer')}
								/>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { PassbackZones };

import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { FC } from 'react';
import { ConfigurationState, SettingType, SettingsValue } from '../../../model/ConfigurationModel';
import styles from './photoCallUpSettings.module.scss';

type Props = {
	configuration: ConfigurationState;
	onSetSettingValue: (value: SettingsValue<string>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
	castNumber: (value: number) => boolean;
};
const PhotoCallUpSettings: FC<Props> = ({ castNumber, configuration, onSetSettingValue, onSetSettingsHaveChanged }) => {
	const handleSettingChange = (value: string, key: string, type: SettingType) => {
		const setValues: SettingsValue<string> = {
			key,
			value,
			type,
		};
		onSetSettingValue(setValues);
		if (!configuration.HaveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	const photoCallUpBasicView = configuration.PhotoCallUpBasicView;

	return (
		<div className={styles.container} id='ConfigTabData_5'>
			<div>
				<label htmlFor='PhotoCallUpBasicView'>*{photoCallUpBasicView.Label}</label>
			</div>
			<div>
				<Checkbox
					id='PhotoCallUpBasicView'
					onChange={e => {
						handleSettingChange(e.target.checked ? '1' : '0', 'Value', 'PhotoCallUpBasicView');
					}}
					checked={castNumber(Number(photoCallUpBasicView.Value ?? 0))}
					aria-label={`*${photoCallUpBasicView.Label}`}
				/>
			</div>
		</div>
	);
};

export { PhotoCallUpSettings };

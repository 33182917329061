// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".draggableContainer{position:relative;overflow:hidden}.status-viewer-container .status-widget>.ant-collapse-header{font-size:15px !important;font-weight:600}.status-viewer-container .status-widget-collapse [class*=ant-collapse-content-box]{padding:0px !important}.status-viewer-container .status-widget-status-viewer>.ant-collapse-content{max-height:355px;overflow-y:auto}.status-viewer-container .status-select{width:120px;margin:5px 0px 5px 20px;text-align:center}", "",{"version":3,"sources":["webpack://src/components/widget/StatusViewer/statusViewer.scss"],"names":[],"mappings":"AAAA,oBACC,iBAAA,CACA,eAAA,CAGD,6DACC,yBAAA,CACA,eAAA,CAGD,mFACE,sBAAA,CAGF,4EACE,gBAAA,CACA,eAAA,CAGF,wCACE,WAAA,CACA,uBAAA,CACA,iBAAA","sourcesContent":[".draggableContainer {\r\n\tposition: relative;\r\n\toverflow: hidden;\r\n}\r\n\r\n.status-viewer-container .status-widget > .ant-collapse-header {\r\n\tfont-size: 15px !important;\r\n\tfont-weight: 600;\r\n\t}\r\n\r\n.status-viewer-container .status-widget-collapse [class*='ant-collapse-content-box'] {\r\n\t\tpadding: 0px !important;\r\n\t}\r\n\r\n.status-viewer-container .status-widget-status-viewer > .ant-collapse-content {\r\n\t\tmax-height: 355px;\r\n\t\toverflow-y: auto;\r\n\t}\r\n\r\n.status-viewer-container .status-select {\r\n\t\twidth: 120px;\r\n\t\tmargin: 5px 0px 5px 20px;\r\n\t\ttext-align: center;\r\n\t}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggableContainer": "draggableContainer",
	"status-viewer-container": "status-viewer-container",
	"status-widget": "status-widget",
	"ant-collapse-header": "ant-collapse-header",
	"status-widget-collapse": "status-widget-collapse",
	"status-widget-status-viewer": "status-widget-status-viewer",
	"ant-collapse-content": "ant-collapse-content",
	"status-select": "status-select"
};
export default ___CSS_LOADER_EXPORT___;

import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { SortingInfo } from '../../../../../model/ReportsModel';
import styles from '../reportTabs.module.scss';

type Props = {
	onDeleteSorting: (key: number) => void;
	requestSorting: SortingInfo[];
	onSetSortingToBeEdited: (key: number) => void;
	onCancelEdition: (key: number) => void;
	isEditing: boolean;
	currentEditionKey: number;
};

const ReportSortingRequestInfo: React.FC<Props> = ({
	currentEditionKey,
	onDeleteSorting,
	requestSorting,
	onSetSortingToBeEdited,
	onCancelEdition,
	isEditing,
}) => {
	const showRemoveButton = (key: number) => currentEditionKey !== key;
	const showCancelButton = (key: number) => isEditing && currentEditionKey === key;
	return (
		requestSorting.length > 0 && (
			<div className={styles.sortingInfo}>
				<div />
				<div>
					<label>{_('Sorting')}</label>
				</div>
				{requestSorting.map(x => (
					<React.Fragment key={x.key}>
						<div className={styles.alignButtons}>
							<Button id='SortingEditButton' disabled={isEditing} onClick={() => onSetSortingToBeEdited(x.key)} type='primary'>
								<EditOutlined />
								{_('Edit')}
							</Button>
							{showCancelButton(x.key) && (
								<Button id='SortingCancelButton' onClick={() => onCancelEdition(x.key)}>
									{_('Cancel')}
								</Button>
							)}
							{showRemoveButton(x.key) && (
								<Button id='SortingRemoveButton' disabled={isEditing} onClick={() => onDeleteSorting(x.key)} type='primary'>
									<MinusOutlined />
									{_('Remove')}
								</Button>
							)}
						</div>
						<div>{`${x.fieldName} ${_('sortedin')} ${x.sortingName} ${_('order')}`}</div>
					</React.Fragment>
				))}
			</div>
		)
	);
};

export { ReportSortingRequestInfo };

import { Button } from 'antd';
import React, { useMemo, useReducer, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../../api';
import { ComponentPermission, SecuredComponents, User, getPermissionErrorMessage } from '../../../../model/AccountModel';
import { PaginationSetting, ResponseObject } from '../../../../model/CommonModel';
import {
	CurrentDeviceControlObj,
	DeviceObjectType,
	Reader,
	ReaderError,
	ReaderInterface,
	ScrambleFactorImage,
	ScrambleFactorImageAction,
	ScrambleFactorImageCategories,
} from '../../../../model/DeviceAdminModel';
import { Logger } from '../../../../model/LoggingModel';
import { useStoreDispatch, useStoreSelector } from '../../../../store';
import { setCurrentDevice } from '../../../../store/common/actions';
import { setControllerContacts } from '../../../../store/deviceControl/actions';
import { selectDigitracFilterMode, selectTablePaginationSetting } from '../../../../store/deviceControl/selectors';
import { InformationMessages, Modal, ModalWarning, NotificationStatus, WithLockedValidation } from '../../../common';
import { ReaderContainer } from './ReaderContainer/ReaderContainer';
import { StoreContext, readerContext, readerState } from './contextReader';

const user: User = getUser();
const readerPermissions: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Reader);

type Props = {
	id: number;
	address: string;
	setShouldResetSearchColumn: () => void;
	cancelHandle?: (device: CurrentDeviceControlObj) => void;
	setRedirectPage?: () => void;
	saveCallBack?: () => void;
};

const ReaderModal: React.FC<Props> = WithLockedValidation(({ id, address, setShouldResetSearchColumn, cancelHandle, saveCallBack, setRedirectPage }) => {
	const dispatch = useStoreDispatch();
	const [stateContext, dispatchActionContext] = useReducer(readerContext, readerState);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [errorType, setErrorType] = useState<ReaderError>(undefined);
	const [errorMessage, setErrorMessage] = useState('');
	const [downloadedFirmware, setDownloadedFirmware] = useState<boolean>(false);
	const [submittedForm, setSubmittedForm] = useState<boolean>(false);

	const {
		name,
		readerInterface,
		index,
		supportedReaderClass,
		oSDPAddress,
		wLHubAddress,
		eACAddress,
		wL2HubAddress,
		allegionLockAddress,
		okDegrade,
		antiPassback,
		cCOTZAssurance,
		firmware,
		enableReaderBezelTamper,
		enableHexPassThrough,
		enableMatchBezelTamper,
		facilityCodeCardNumber,
		corporate1000,
		hexDigitUUID,
		enableKeyPad,
		genericTimeZones,
		matchAlgorithm,
		fascnHandling,
		anyReaderOptions,
		cardReaderOptions,
		scramblePadKeyPadOptions,
		controllerId,
		selections: {
			cardMap,
			specialHandlingInstruction,
			readerThreatLevel,
			toZone,
			fromZone,
			defaultAssuranceLevel,
			cCOTZThreatLevel,
			assuranceLevel,
			cardCodeOnlyTimeZone,
			rS485ReaderType,
		},
		unpermitted,
		isDoorWireless,
		osdpLEDOptions,
		useCustomOSDPLEDColor,
		changedOSDPLEDColor,
		selectedFactorImages,
		viewPINEntryIndicator,
		scrambleFactorCategories,
	} = stateContext;

	const isFilterMode: boolean = useStoreSelector<boolean>(selectDigitracFilterMode);
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);

	const shouldResetSearchColumn = () => {
		if (setRedirectPage) {
			setRedirectPage();
		}
		if (isFilterMode) {
			setShouldResetSearchColumn();
		}
	};

	const handleEditReader = async () => {
		batch(() => {
			setSubmittedForm(true);
			setLoading(true);
		});

		try {
			const reader: Partial<Reader> = {
				ControllerId: controllerId,
				Name: name,
				Id: id,
				Index: index,
				SupportedReaderClass: supportedReaderClass,
				CardMap: cardMap,
				RS485Interface: readerInterface === ReaderInterface.RS485,
				AnyReaderOptions: anyReaderOptions,
				CardReaderOptions: cardReaderOptions,
				ScramblePadKeyPadOptions: scramblePadKeyPadOptions,
				DisabledReaderAboveLevelId: readerThreatLevel,
				DisabledCCOTZAboveLevelId: cCOTZThreatLevel,
				OSDPAddress: oSDPAddress,
				WLHubAddress: wLHubAddress,
				EACAddress: eACAddress,
				WL2HubAddress: wL2HubAddress,
				AllegionLockAddress: allegionLockAddress,
				OkDegrade: okDegrade,
				AntiPassback: antiPassback,
				CCOTZAssurance: cCOTZAssurance,
				AssuranceLevel: assuranceLevel,
				DefaultAssuranceLevel: defaultAssuranceLevel,
				Firmware: firmware,
				ToZone: toZone,
				FromZone: fromZone,
				EnableReaderBezelTamper: enableReaderBezelTamper,
				EnableHexPassThrough: enableHexPassThrough,
				SpecialHandlingInstruction: specialHandlingInstruction,
				EnableMatchBezelTamper: enableMatchBezelTamper,
				FacilityCodeCardNumber: facilityCodeCardNumber,
				Corporate1000: corporate1000,
				HexDigitUUID: hexDigitUUID,
				EnableKeyPad: enableKeyPad,
				CardCodeOnlyTimeZone: unpermitted.cardCodeOnlyTimeZone
					? unpermitted.cardCodeOnlyTimeZone
					: genericTimeZones.find(x => x.GenericId === cardCodeOnlyTimeZone),
				RS485ReaderType: rS485ReaderType,
				ReaderInterface: readerInterface,
				MatchAlgorithm: matchAlgorithm,
				FascnHandling: fascnHandling,
				IsDoorWireless: isDoorWireless,
				ChangedOSDPLEDColor: changedOSDPLEDColor,
				OSDPLEDOptions: osdpLEDOptions,
				UseCustomOSDPLEDColor: useCustomOSDPLEDColor,
				TSScrambleImages: selectedFactorImages,
				ViewPINEntryIndicator: viewPINEntryIndicator,
			};

			let scrambleFactorImages = [];

			scrambleFactorCategories.forEach((category: ScrambleFactorImageCategories) =>
				category.ScrambleFactorImages.forEach((image: ScrambleFactorImage) => {
					if (image.Action !== ScrambleFactorImageAction.None) {
						scrambleFactorImages.push(image);
					}
				})
			);

			reader.ScrambleFactorImages = scrambleFactorImages;

			const response: ResponseObject = await deviceAdminApi.editReader(reader);

			const handleSuccess = () => {
				dispatch(setControllerContacts(DeviceObjectType.Reader, paginationSetting));
				handleCancelModal();
				shouldResetSearchColumn();
				if (saveCallBack) {
					saveCallBack();
				}
			};

			const paginationSetting = isFilterMode ? ({ ...tablePaginationSetting, SearchedValue: '' } as PaginationSetting) : tablePaginationSetting;
			NotificationStatus({
				notUseDefaultNotification: ReaderError[response.AdditionalResponseInfo] === ReaderError.DuplicatedScrambleImageName,
				responseData: response,
				onSuccessCallback: () => {
					handleSuccess();
				},
				onAdditionalInfoRequiredCallback: () => {
					const responseObjectId = Number(response.ResponseObjectId);
					let title: string = '';
					let content: JSX.Element = null;
					if (responseObjectId === 2 || responseObjectId === 3) {
						const messages: string[] = response.AdditionalResponseInfo.split('\n');
						content = <InformationMessages messages={messages} />;
						title = responseObjectId === 2 ? _('FailedToSyncTimeZone') : _('UnableToDeleteImage');
					}

					ModalWarning({
						title,
						content,
						onOk: () => handleSuccess(),
						onCancel: () => handleSuccess(),
						okText: _('Ok'),
						width: '700px',
					});

					setSubmittedForm(false);
				},
				onFailedValidation: () => {
					if (errorType !== ReaderError.EACAddress) {
						setErrorType(undefined);
					}

					setErrorMessage(response.ErrorMessage);
					setErrorType(ReaderError[response.AdditionalResponseInfo]);
					setSubmittedForm(false);
				},
			});
		} catch (e) {
			const error: Error = e as Error;
			Logger.writeErrorLog(`${error.name}: ${error.message}`);
		} finally {
			setLoading(false);
		}
	};

	const handleCloseModal = () => {
		const newDevice: CurrentDeviceControlObj = { Id: 0, DeviceObjectType: DeviceObjectType.Reader, IsModalOpen: false };
		if (cancelHandle) {
			cancelHandle({ Id: 0, DeviceObjectType: DeviceObjectType.Reader });
		} else {
			dispatch(setCurrentDevice(newDevice));
		}
	};

	const handleCancelModal = () => {
		deviceAdminApi.unlockComponentDeviceAdmin(id, SecuredComponents.Reader);
		handleCloseModal();
	};

	const handleDownloadFirmware = () => {
		setDownloadedFirmware(true);
	};

	const contextValue = useMemo(() => {
		return { readerState: stateContext, dispatcher: dispatchActionContext };
	}, [stateContext, dispatchActionContext]);

	return (
		<StoreContext.Provider value={contextValue}>
			<Modal
				onClickOk={() => null}
				onCancel={handleCancelModal}
				title={`${_('EditReader')} ${address}`}
				visible={true}
				footer={[
					<Button
						id='readerSaveChangesButton'
						key='save'
						type='primary'
						loading={isLoading}
						onClick={handleEditReader}
						title={getPermissionErrorMessage(readerPermissions.canUpdate)}
						disabled={isLoading || !readerPermissions.canUpdate || cardCodeOnlyTimeZone === 0 || downloadedFirmware}>
						{_('SaveChanges')}
					</Button>,
					<Button disabled={isLoading} id='readerCancelButton' key='cancel' loading={isLoading} onClick={handleCancelModal}>
						{_('Cancel')}
					</Button>,
				]}
				width='800px'>
				<div>
					<ReaderContainer
						submittedForm={submittedForm}
						handleDownloadFirmware={handleDownloadFirmware}
						errorType={errorType}
						errorMessage={errorMessage}
						id={id}
						setLoading={setLoading}
						isLoading={isLoading}
						useDoorContext={false}
					/>
				</div>
			</Modal>
		</StoreContext.Provider>
	);
});

export { ReaderModal };

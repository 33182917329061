import { Spin, Tabs, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { canAddNewTimeZones, handleResponse } from '../../../../../Helper';
import { deviceAdminApi } from '../../../../../api';
import { SubPermissions, User } from '../../../../../model/AccountModel';
import { ResponseObject } from '../../../../../model/CommonModel';
import {
	AssuranceLevelList,
	Reader,
	ReaderContextType,
	ReaderError,
	ReaderInterface,
	SelectTimeZone,
	TimeZoneType,
} from '../../../../../model/DeviceAdminModel';
import { Logger } from '../../../../../model/LoggingModel';
import { Modal } from '../../../../common';
import { TimeZones } from '../../../VelocityConfiguration/TimeZones/TimeZones';
import { setDoorNewTimeZoneAction } from '../../DoorModal/DoorModalContext';
import { DoorStoreSingleContext } from '../../DoorModal/DoorStoreSingleContext';
import { CardReaderSetup } from '../Tabs/CardReaderSetup/CardReaderSetup';
import { General } from '../Tabs/General/General';
import { Logic } from '../Tabs/Logic/Logic';
import { Options } from '../Tabs/Options/Options';
import { ScrambleFactor } from '../Tabs/ScrambleFactor/ScrambleFactor';
import {
	StoreContext,
	initReaderAction,
	setAssuranceInfo,
	setCardCodeOnlyTimeZone,
	setGenericTimeZones,
	setReaderErrorMessage,
	setReaderErrorType,
	setScrambleFactorErrorImageCategory,
	setUnpermittedCardCodeOnly,
} from '../contextReader';

const { TabPane } = Tabs;

type Props = {
	submittedForm: boolean;
	errorType: ReaderError;
	errorMessage: string;
	id: number;
	setLoading: (boolean) => void;
	isLoading: boolean;
	handleDownloadFirmware: () => void;
	useDoorContext: boolean;
	setErrorText?: () => void;
	onRefreshTimeZones?: (timeZones: SelectTimeZone[]) => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();

const ReaderContainer: React.FC<Props> = ({
	submittedForm,
	handleDownloadFirmware,
	errorType,
	errorMessage,
	id,
	setLoading,
	isLoading,
	useDoorContext,
	setErrorText,
	onRefreshTimeZones,
}) => {
	const [showNewTimeZone, setShowNewTimeZone] = useState<boolean>(false);
	const [newTimeZoneId, setNewTimeZoneId] = useState<number>(0);
	const [previousSelection, setPreviousSelection] = useState<number>(1);
	const [activeKey, setActiveKey] = useState(undefined);

	const canViewGeneral: boolean = useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Reader_ViewGeneralTab).allowed;
	const canViewLogic: boolean = useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Reader_ViewLogicTab).allowed;
	const canViewOptions: boolean = useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Reader_ViewScramblePadOptionsTab).allowed;
	const canViewCardReaderSetup: boolean = useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Reader_ViewCardReaderSetup).allowed;
	const canViewTSScrambleFactor: boolean = useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Reader_ViewTSScrambleFactor).allowed;

	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: stateContext,
				door: { newTimeZoneAdded },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var { readerState: stateContext, dispatcher } = useContext(StoreContext);
	}

	const {
		supportedReaderClass,
		selections: { cardCodeOnlyTimeZone, rS485ReaderType },
		readerInterface,
	} = stateContext;

	useEffect(() => {
		useDoorContext ? dispatcherDoor(setReaderErrorType(errorType)) : dispatcher(setReaderErrorType(errorType));

		if (errorType === ReaderError.DuplicatedScrambleImageName) {
			const [message, imageName, categoryId] = errorMessage.split('\n');
			const fullErrorMessage: string = _(message).replace('%1', imageName);
			notification['error']({
				message: fullErrorMessage,
			});

			useDoorContext ? dispatcherDoor(setReaderErrorMessage(fullErrorMessage)) : dispatcher(setReaderErrorMessage(fullErrorMessage));
			useDoorContext ? dispatcherDoor(setScrambleFactorErrorImageCategory(categoryId)) : dispatcher(setScrambleFactorErrorImageCategory(categoryId));
		} else {
			useDoorContext ? dispatcherDoor(setReaderErrorMessage(errorMessage)) : dispatcher(setReaderErrorMessage(errorMessage));
		}

		switch (errorType) {
			case ReaderError.Name:
			case ReaderError.EACAddress:
			case ReaderError.DuplicatedWAddress:
				setActiveKey('1');
				break;
			case ReaderError.Assurance:
				setActiveKey('3');
				break;
			case ReaderError.DuplicatedScrambleImageName:
				setActiveKey('5');
				break;
		}
	}, [errorType]);

	useEffect(() => {
		if (id !== 0 && !useDoorContext) {
			setLoading(true);
			deviceAdminApi
				.loadReader(id)
				.then((res: Reader) => {
					if (canAddNewTimeZones()) {
						const newTimeZone: SelectTimeZone = {
							Name: `<${_('New')}>`,
							Unpermitted: true,
							GenericId: 0,
							TimeZoneType: TimeZoneType.Standard,
							GlobalId: 0,
						};
						res.GenericTimeZones.unshift(newTimeZone);
					}

					dispatcher(initReaderAction(res));

					if (res.CardCodeOnlyTimeZone.Unpermitted) {
						dispatcher(setUnpermittedCardCodeOnly(res.CardCodeOnlyTimeZone));
					}
				})
				.catch(e => {
					const error: Error = e as Error;
					Logger.writeErrorLog(`${error.name}: ${error.message}`);
				})
				.finally(() => setLoading(false));
		}
	}, [id]);

	useEffect(() => {
		if (cardCodeOnlyTimeZone === 0) {
			deviceAdminApi.checkIfAnyTimeZoneIsNotLocked().then((res: ResponseObject) => {
				const isLocked: boolean = handleResponse(res);
				if (!isLocked) {
					setShowNewTimeZone(true);
				} else {
					handleCloseNewTimeZone();
				}
			});
		}
	}, [cardCodeOnlyTimeZone]);

	useEffect(() => {
		if (rS485ReaderType !== 0 && supportedReaderClass === 0 && !useDoorContext) {
			deviceAdminApi.getAssuranceLevels(rS485ReaderType).then((res: AssuranceLevelList) => {
				dispatcher(setAssuranceInfo(res));
			});
		}
	}, [rS485ReaderType]);

	const fetchNewTimeZones = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: true,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};
				genericTimeZones.unshift(newTimeZone);
			}
			useDoorContext ? dispatcherDoor(setGenericTimeZones(genericTimeZones)) : dispatcher(setGenericTimeZones(genericTimeZones));
			if (cardCodeOnlyTimeZone === 0) {
				useDoorContext ? dispatcherDoor(setCardCodeOnlyTimeZone(newTimeZoneId)) : dispatcher(setCardCodeOnlyTimeZone(newTimeZoneId));
			}
			setShowNewTimeZone(false);
		});
	};

	useEffect(() => {
		if (useDoorContext) {
			dispatcherDoor(setDoorNewTimeZoneAction(newTimeZoneId));
		} else {
			fetchNewTimeZones();
		}
	}, [newTimeZoneId]);

	useEffect(() => {
		if (newTimeZoneAdded) {
			fetchNewTimeZones();
		}
	}, [newTimeZoneAdded]);

	const handleOnChangeTab = (activeKey: string) => {
		setActiveKey(activeKey);
	};

	const handleCloseNewTimeZone = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: true,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};
				genericTimeZones.unshift(newTimeZone);
			}

			useDoorContext ? onRefreshTimeZones?.(genericTimeZones) : dispatcher(setGenericTimeZones(genericTimeZones));
			const existTimeZone = genericTimeZones.find(x => x.GenericId === previousSelection) ? previousSelection : 1;
			useDoorContext ? dispatcherDoor(setCardCodeOnlyTimeZone(existTimeZone)) : dispatcher(setCardCodeOnlyTimeZone(existTimeZone));
		});
		setShowNewTimeZone(false);
	};

	const areTypesValidToShowScrambleFactor = (): boolean => {
		return canViewTSScrambleFactor && readerInterface === ReaderInterface.RS485 && rS485ReaderType === 9;
	};

	useEffect(() => {
		fetchNewTimeZones();
	}, []);

	return (
		<>
			<Spin size='large' tip={`${_('Loading')}...`} spinning={isLoading}>
				<Tabs type={useDoorContext ? null : 'card'} onChange={handleOnChangeTab} activeKey={activeKey}>
					{canViewGeneral && (
						<TabPane
							tab={
								useDoorContext ? <span id='entryReaderSetUpTabName'>{_('SetUp')}</span> : <span id='readerGeneralTabName'>{_('General')}</span>
							}
							key='1'>
							<General
								submittedForm={submittedForm}
								handleDownloadFirmware={handleDownloadFirmware}
								useDoorContext={useDoorContext}
								setErrorText={setErrorText}
							/>
						</TabPane>
					)}
					{canViewOptions && (
						<TabPane tab={<span id={useDoorContext ? 'entryReaderOptionsTabName' : 'readerOptionsTabName'}>{_('Options')}</span>} key='2'>
							<Options useDoorContext={useDoorContext} />
						</TabPane>
					)}
					{canViewLogic && (
						<TabPane tab={<span id={useDoorContext ? 'entryReaderLogicTabName' : 'readerLogicTabName'}>{_('Logic')}</span>} key='3'>
							<Logic setPreviousSelection={setPreviousSelection} useDoorContext={useDoorContext} />
						</TabPane>
					)}
					{canViewCardReaderSetup && (
						<TabPane
							tab={<span id={useDoorContext ? 'entryReaderCardReaderSetupTabName' : 'readerCardReaderSetupTabName'}>{_('CardReaderSetup')}</span>}
							key='4'>
							<CardReaderSetup useDoorContext={useDoorContext} />
						</TabPane>
					)}
					{areTypesValidToShowScrambleFactor() && (
						<TabPane
							tab={
								<span id={useDoorContext ? 'entryReaderTSScrambleFactorTabName' : 'readerTSScrambleFactorTabName'}>
									{_('TSScrambleFactor')}
								</span>
							}
							key='5'>
							<ScrambleFactor readerId={id} readerContextType={useDoorContext ? ReaderContextType.Door : ReaderContextType.EntryReader} />
						</TabPane>
					)}
				</Tabs>
			</Spin>
			<Modal
				visible={showNewTimeZone}
				title={_('TimeZones')}
				onClickOk={handleCloseNewTimeZone}
				onCancel={handleCloseNewTimeZone}
				width={'900px'}
				footer={null}>
				<TimeZones onEntityAction={setNewTimeZoneId} disableDelete />
			</Modal>
		</>
	);
};

export { ReaderContainer };

import { AvailableDoorInfo, PhotoCallUpResponse, PhotoCallUpUserConfiguration, PhotoCallUpUserInformationModel } from '../model/PhotoCallUpModel';
import { Api } from './api';

class PhotoCallUpApi extends Api {
	public constructor() {
		//NEVER FORGET THE SUPER
		super();
	}

	public retrievePhotoCallUpAccess = async (): Promise<PhotoCallUpResponse> => {
		const url = this.getUrl('PhotoCallUp/RetrievePhotoCallUpAccess', true);

		return await this.post<PhotoCallUpResponse>(url);
	};

	public retrieveAvailableDoors = async (controllerIds: number[]): Promise<AvailableDoorInfo[]> => {
		const url = this.getUrl('PhotoCallUp/RetrieveAvailableDoors', true);

		return await this.post<AvailableDoorInfo[]>(url, { controllerIds });
	};

	//TODO: Fix this
	public retrieveEnrollmentUserInfo = async (personId: number, credentialId: number, logId: number): Promise<PhotoCallUpUserInformationModel> => {
		const url = this.getUrl('PhotoCallUp/RetrieveEnrollmentUserInfo', true);
		const data = {
			PersonId: personId,
			CredentialId: credentialId,
			LogId: logId,
		};

		return await this.post<PhotoCallUpUserInformationModel>(url, data);
	};

	public retrievePhotoCallUpConfiguration = async (): Promise<PhotoCallUpUserConfiguration> => {
		const url = this.getUrl('PhotoCallUp/GetPhotoCallUpConfiguration', true);

		return await this.post<PhotoCallUpUserConfiguration>(url);
	};
}

const photoCallUpApi = new PhotoCallUpApi();

export { photoCallUpApi };

import { ResponseObject } from './CommonModel';

export enum AlarmState {
	Active = 0,
	Acknowledged,
	Cleared,
}

export type AlarmNote = {
	Date: Date;
	Text: string;
	OperatorName: string;
	AlarmId: number;
	AlarmNoteId: number;
	Type: number;
};

export type AlarmDetail = {
	ActiveAlarmId: number;
	HostTime: Date;
	HardwareTime: Date;
	Operator: string;
	Workstation: string;
	Time: Date;
	AlarmNotes: AlarmNote[];
	IsChecked: boolean;
	IsExpandableRowHeader: boolean;
};

export type AlarmModel = {
	AlarmDetails: AlarmDetail[];
	EventId: number;
	Description: string;
	Address: string;
	Level: number;
	State: AlarmState;
	Status: number;
	Secures: number;
	ReturnedToNormal: boolean;
	Instructions: string;
	Count: number;
	AlarmSoundInfo: AlarmSoundInfo;
};

export type AlarmSoundInfo = {
	ActiveId: number;
	Path: string;
	Address: string;
	Data: string;
	ContentType: string;
};

export type AlarmListResponseObject = {
	alarmList: AlarmModel[];
	totalRecords: number;
	totalPages: number;
	pageNumber: number;
} & ResponseObject;

export type AlarmAssessmentCode = {
	Id: number;
	Name: string;
	Text: string;
};

export enum AlarmAcknowledgementActions {
	None = 0,
	AcknowledgedOne,
	AcknowledgedMultiple,
	AcknowledgedAll,
	ClearOneUnacknowledged,
	ClearMultipleUnacknowledged,
	ClearAllUnacknowledged,
	ClearOneAcknowledged,
	ClearMultipleAcknowledged,
	ClearAllAcknowledged,
}

export type AlarmSoundConfiguration = {
	MakeDelay: boolean;
	SilenceBeep: boolean;
	SilenceCustom: boolean;
	BackgroundPlay: boolean;
	DelaySeconds: number;
};

export enum AlarmAcknowledgementErrorCode {
	Success = 0,
	ActionDoesNoteMatchAlarmCount,
	AlarmAndNotesCountDoNotMatch,
	RequiresNote,
	SystemError,
}

export type AlarmAcknowledgementResults = {
	AlarmAckErrorCode: AlarmAcknowledgementErrorCode;
} & ResponseObject;

export type AcknowledgedAndClearAlarmsPayload = {
	action: AlarmAcknowledgementActions;
	alarmIds: number[];
	operatorNotes: AlarmNote[];
	alarmsDetails?: AlarmModel[];
	addOperatorNoteCallback: (action: AlarmAcknowledgementActions, payload: AcknowledgedAndClearAlarmsPayload) => AlarmNoteForAcknowledge;
	acknowledgeAndClearCallback: (payload: AcknowledgedAndClearAlarmsPayload) => AlarmNoteForAcknowledge;
	setIsLoadingCallback?: (value: boolean) => void;
	setAlarmNoteForAcknowledgeCallback: (alarmNote: AlarmNoteForAcknowledge) => void;
	setShowAlarmAddOperatorNoteCallback: () => void;
	saveChangesCallback?: () => void;
};

export type AlarmNoteForAcknowledge = {
	isAlarmNoteForAcknowledge: boolean;
	acknowledgeAction: AlarmAcknowledgementActions;
	alarms: OperatorNoteAlarm[];
};

export type OperatorNoteAlarm = Pick<AlarmModel, 'EventId' | 'Description' | 'Address' | 'Level' | 'State' | 'Status' | 'Secures' | 'ReturnedToNormal'> &
	Pick<AlarmDetail, 'ActiveAlarmId' | 'HostTime' | 'HardwareTime' | 'Operator' | 'Workstation' | 'Time'>;

export type ActiveAlarm = {
	ActiveId: number;
	DBDate: Date;
	EventLevel: number;
	Description: string;
	Status: number;
	AckFlag: boolean;
	Address: string;
	Secures: number;
	EventID: number;
	AlarmLevel: number;
	IsAlarm: boolean;
	DTIILevel: number;
	IntegratedLevel: number;
	customLevel: string;
	key: React.Key;
};

export enum AlarmActionOptions {
	ADD_OPERATOR_NOTE = 10,
}

export type AlarmsActions = AlarmAcknowledgementActions | AlarmActionOptions;

export type AlarmsOptions = {
	key: string;
	action?: AlarmsActions;
};

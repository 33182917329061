import cx from 'classnames';
import memoize from 'memoize-one';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { ColumnsProps, useWindowResize } from '../../../Helper';
import { RefWrap } from '../../common/VirtualTable/RefWrap';
import { AlarmActionColumnData, AlarmTableDataSourceModel, getAlarmCount, getNextPage } from '../helpers';
import { getAlarmActionColumnDataObject } from './ExpandableRow';
import { Row } from './Row';
import styles from './virtualTableExpandableRow.module.scss';

const minimumRowHeight: number = 30;
type Props = {
	headerColumnsWidth: CSSProperties;
	columns: ColumnsProps<AlarmTableDataSourceModel>[];
	dataSource: AlarmTableDataSourceModel[];
	pageSize: number;
	maxHeight: number;
	idTable: string;
	alwaysShowInstructions: boolean;
	alarmStackingEnabled: boolean;
	totalAlarms: number;
	containerClassName: string;
	fetchData: (pageNumber: number) => void;
	handleOnExpandRow: (itemIndex: number) => void;
	sessionStorageFetchKey: string;
	sessionStorageLiveEventKey: string;
};

type sizeMap = {
	[index: number]: number;
};

const createItem = memoize((items, toggleItemActive) => ({
	items,
	toggleItemActive,
}));

const component = ({
	columns,
	dataSource,
	pageSize,
	headerColumnsWidth,
	maxHeight,
	idTable,
	alarmStackingEnabled,
	alwaysShowInstructions,
	totalAlarms,
	containerClassName,
	fetchData,
	handleOnExpandRow,
	sessionStorageFetchKey,
	sessionStorageLiveEventKey,
}: Props) => {
	const [headerScroll, setHeaderScroll] = useState<number>(0);
	const [height, setHeight] = useState<number>(minimumRowHeight);

	const listRef = useRef(undefined);
	const headerRef = useRef(undefined);
	const sizeMap = useRef<sizeMap>({});

	const setSize = useCallback((index: number, size: number) => {
		sizeMap.current = { ...sizeMap.current, [index]: size };
		if (listRef?.current) {
			listRef.current.resetAfterIndex(index);
		}
	}, []);

	const getSize = (index: number): number => {
		let size: number = sizeMap.current[index] || minimumRowHeight;
		if (index === dataSource.length - 1) {
			const containerElement: HTMLElement = document.getElementsByClassName(containerClassName)[0] as HTMLElement;
			const horizontalScrollPixels: number = containerElement?.scrollWidth > containerElement?.clientWidth ? 13 : 0;
			size += horizontalScrollPixels;
		}

		return size;
	};
	const [windowWidth] = useWindowResize();

	useEffect(() => {
		if (headerRef?.current) {
			headerRef.current.scrollLeft = headerScroll;
		}
	}, [headerScroll]);

	useEffect(() => {
		if (listRef?.current && dataSource?.length > 0) {
			listRef.current.scrollToItem(0);
		}
	}, [height]);

	const onFetchData = (): void => {
		if (window.sessionStorage.getItem(sessionStorageFetchKey) === '1' || window.sessionStorage.getItem(sessionStorageLiveEventKey) === '1') {
			return;
		}

		const quantityOfAlarms = getAlarmCount(dataSource);
		if (quantityOfAlarms !== totalAlarms) {
			const newPage = getNextPage(quantityOfAlarms, pageSize);
			fetchData(newPage);
		}
	};

	const handleInnerRef = ref => {
		if (ref?.children) {
			setTimeout(() => {
				let totalHeight: number = 0;
				for (let index = 0; index < ref.children.length; index++) {
					const elementDiv = ref.children[index];
					if (elementDiv && elementDiv.style.height) {
						const heightNumber: number = Number(elementDiv.style.height.toLowerCase().split('px')[0]);
						if (!isNaN(heightNumber) && heightNumber <= maxHeight && heightNumber >= 2) {
							totalHeight += heightNumber;
						} else {
							return;
						}
					}
				}

				if (totalHeight === 0) {
					setHeight(minimumRowHeight - 2);
				} else if (totalHeight <= maxHeight && totalHeight >= 2) {
					setHeight(totalHeight - 2);
				} else if (height !== maxHeight) {
					setHeight(maxHeight);
				}
			}, 0);
		}
	};

	const listKey: string = dataSource.length === 0 ? `${Math.floor(Math.random() * 100)}` : undefined;
	const itemData = createItem(dataSource, handleOnExpandRow);

	const listComponent: JSX.Element = (
		<RefWrap fetchData={onFetchData} headerScroll={headerScroll} setHeaderScroll={setHeaderScroll}>
			<List
				id={idTable}
				ref={listRef}
				height={height}
				width='100%'
				itemCount={dataSource.length}
				itemSize={getSize}
				itemData={itemData}
				innerRef={handleInnerRef}
				overscanCount={10}
				className={cx(styles.list, containerClassName)}>
				{({ data, index, style }) => {
					const { items, toggleItemActive } = data;
					const dataRow = items as AlarmTableDataSourceModel[];
					const alarmData = dataRow[index];
					const isExpandableRow = (alarmData?.AlarmDetails.length > 1 && alarmStackingEnabled) || alarmData?.IsExpanded;

					return (
						<div style={style} className={styles.rowContainer}>
							<Row
								data={alarmData}
								index={index}
								setSize={setSize}
								windowWidth={windowWidth}
								columns={columns}
								rowColumnsWidth={headerColumnsWidth}
								alwaysShowInstructions={alwaysShowInstructions}
								alarmStackingEnabled={alarmStackingEnabled}
								handleOnToggleItemActive={toggleItemActive}
								isExpandableRow={isExpandableRow}
							/>
						</div>
					);
				}}
			</List>
		</RefWrap>
	);

	return (
		<div className={styles.container}>
			<div ref={headerRef} className={styles.header} style={headerColumnsWidth}>
				{columns.map((column: ColumnsProps<AlarmTableDataSourceModel>, index: number) => {
					const isFirstOrLastCell: boolean = index + 1 === columns.length || index === 0;
					const alarmActionColumn: AlarmActionColumnData = {
						...getAlarmActionColumnDataObject(null, null),
						columnActionHeader: true,
						dataSource,
					};

					return (
						<React.Fragment key={`container-header-${column.dataIndex}`}>
							{column.key === 'ActionHeader' ? (
								<div key={`header-${column.dataIndex}`}>{column.render(alarmActionColumn, undefined, 0)}</div>
							) : (
								<div key={`header-${column.dataIndex}`} className={cx({ [styles.tableHeader]: !isFirstOrLastCell })}>
									<span>{column.title}</span>
									{!isFirstOrLastCell && <span className={styles.separator}>|</span>}
								</div>
							)}
						</React.Fragment>
					);
				})}
			</div>
			<React.Fragment key={listKey}>{listComponent}</React.Fragment>
		</div>
	);
};

const VirtualTableExpandableRow = React.memo(component);

export { VirtualTableExpandableRow };

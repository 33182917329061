import { CheckOutlined, ClearOutlined, FileOutlined, PlusOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React, { CSSProperties } from 'react';
import { alarmApi } from '../../api/AlarmAPI';
import { SubPermissions, User, getPermissionErrorMessage } from '../../model/AccountModel';
import {
	AcknowledgedAndClearAlarmsPayload,
	AlarmAcknowledgementActions,
	AlarmActionOptions,
	AlarmDetail,
	AlarmModel,
	AlarmNote,
	AlarmNoteForAcknowledge,
	OperatorNoteAlarm,
} from '../../model/AlarmModel';
import { SelectOptions } from '../../model/CommonModel';
import { OptionsButtonBuilder } from '../../model/DeviceAdminModel';
import { ModalInfo, NotificationStatus } from '../common';
import { AlarmExpandColumn } from './VirtualTableExpandableRow/ExpandableRow';

export type AlarmTableDataSourceModel = {
	key?: React.Key;
	Count?: number;
	IsExpanded?: boolean;
	HeaderAlarmDetails?: AlarmDetail[];
	HeaderCount?: number;
	ExpandColumn?: AlarmExpandColumn;
	NeedsSorting?: boolean;
} & AlarmModel;

export type AlarmTableColumns = {
	CollapseIcon: React.ReactNode;
	Action: React.ReactNode;
	Icon: boolean;
	HardwareTime: Date;
	Count: number;
	HostTime: Date;
	Description: string;
	Address: string;
	Level: number;
	ActiveAlarmId: number;
};

export type AcknowledgedAlarmTableColumns = {
	AcknowledgedTime: Date;
	Workstation: string;
	AcknowledgedBy: string;
} & AlarmTableColumns;

export type AlarmActionColumnData = Pick<AlarmDetail, 'HardwareTime' | 'HostTime' | 'ActiveAlarmId' | 'AlarmNotes' | 'IsChecked' | 'IsExpandableRowHeader'> &
	Pick<AlarmModel, 'State' | 'Description' | 'Instructions' | 'EventId' | 'AlarmDetails'> &
	Pick<AlarmTableDataSourceModel, 'IsExpanded'> & { columnActionHeader: boolean; dataSource: AlarmTableDataSourceModel[] };

export type AddDataPayload = {
	source: AlarmTableDataSourceModel[];
	newAlarms?: AlarmModel[];
	isRealTimeAlarm?: boolean;
};

export const instructionsStyle: CSSProperties = { color: '#fa8c16', fontSize: '22px', verticalAlign: 'text-top' };
export const notesStyle: CSSProperties = { color: '#406080', fontSize: '22px', verticalAlign: 'text-top' };

//TODO: thick uses custom colors for font and background, should we not?
export const getAlarmTextColor = (isReturnToNormal: boolean): CSSProperties => (isReturnToNormal ? { color: 'green' } : { color: 'red' });

type AlarmUniqueFieldsForStacking = Pick<AlarmModel, 'Status' | 'Description' | 'Address' | 'EventId' | 'Level'>;

export const getAlarmCount = (dataSource: AlarmTableDataSourceModel[]): number => {
	return selectAllAlarms(dataSource).length;
};

export const selectAllAlarms = (dataSource: AlarmTableDataSourceModel[]): string[] => {
	const newKeys = dataSource.reduce((prevValue, alarm) => {
		const newAlarms = alarm.AlarmDetails.map<string>(alarmDetail => alarmDetail.ActiveAlarmId?.toString());
		if (newAlarms.length) {
			prevValue.push(...newAlarms);
		}
		return prevValue;
	}, []);

	return newKeys;
};

export const noIconStyle: React.CSSProperties = {
	height: 22,
	width: 22,
};

const user: User = getUser();
const isAcknowledgedAlarmAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmsRTNs_Acknowledge).allowed;
const isMultipleAcknowledgedAlarmAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmsRTNs_AcknowledgeMultiple).allowed;
const isClearAlarmAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmsRTNs_ClearinStandardMode).allowed;
const isClearMultipleAlarmAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmsRTNs_ClearMultiple).allowed;
const isAddNoteAllowed: boolean = User.getSubComponentPermission(user, SubPermissions.AlarmViewer_AlarmViewer_AddNotes).allowed;
const { isClearUnacknowledgedAllowed, requireNoteOnAcknowledge, requireNoteOnClear, forceNoteForMultipleAcknowledge, forceNoteForMultipleClear } = user;

const iconCheckColor: CSSProperties = { color: 'green', fontSize: '18px' };
const iconPlusColor: CSSProperties = { color: '#113e5a', fontSize: '10px', position: 'relative', left: '-1px', top: '4px' };
const iconClearColor: CSSProperties = { color: '#e87002', fontSize: '18px' };
const iconAddNoteColor: CSSProperties = { color: '#113e5a', fontSize: '18px' };
const iconDisabled: CSSProperties = { opacity: '0.4' };

export const getActionDropdownOptions = (
	isAlarmSelected: boolean,
	isAlarmTableEmpty: boolean,
	isAcknowledgedAlarm: boolean = false
): OptionsButtonBuilder<AlarmAcknowledgementActions | AlarmActionOptions> => {
	const isAckSelectedOptionDisabled = (!isAlarmSelected && isAcknowledgedAlarmAllowed) || !isAcknowledgedAlarmAllowed;
	const isClearAlarmOptionDisabled = (!isAlarmSelected && isClearAlarmAllowed) || !isClearAlarmAllowed;
	const isAddOperatorNoteDisabled = (!isAlarmSelected && isAddNoteAllowed) || !isAddNoteAllowed;

	const acknowledgedOptions: SelectOptions<AlarmAcknowledgementActions>[] = [
		{
			id: 'acknowledgedSelectedOption',
			label: _('AcknowledgedSelected'),
			value: AlarmAcknowledgementActions.AcknowledgedOne,
			icon: <CheckOutlined style={{ ...iconCheckColor, ...(isAckSelectedOptionDisabled ? iconDisabled : {}) }} disabled={isAckSelectedOptionDisabled} />,
			disabled: isAckSelectedOptionDisabled,
			title: getPermissionErrorMessage(isAcknowledgedAlarmAllowed),
		},
		{
			id: 'acknowledgedAllOption',
			label: _('AcknowledgedAll'),
			value: AlarmAcknowledgementActions.AcknowledgedAll,
			icon: (
				<>
					<CheckOutlined
						style={{ ...iconCheckColor, ...(!isMultipleAcknowledgedAlarmAllowed || isAlarmTableEmpty ? iconDisabled : {}) }}
						disabled={!isMultipleAcknowledgedAlarmAllowed || isAlarmTableEmpty}
					/>
					<PlusOutlined
						style={{ ...iconPlusColor, left: '-5px', ...(!isMultipleAcknowledgedAlarmAllowed || isAlarmTableEmpty ? iconDisabled : {}) }}
						disabled={!isMultipleAcknowledgedAlarmAllowed || isAlarmTableEmpty}
					/>
				</>
			),
			separator: true,
			disabled: !isMultipleAcknowledgedAlarmAllowed || isAlarmTableEmpty,
			title: getPermissionErrorMessage(isMultipleAcknowledgedAlarmAllowed || isAlarmTableEmpty),
		},
	];

	const clearOptions: SelectOptions<AlarmAcknowledgementActions>[] = [
		{
			id: 'clearSelectedOption',
			label: _('ClearSelected'),
			value: isAcknowledgedAlarm ? AlarmAcknowledgementActions.ClearOneAcknowledged : AlarmAcknowledgementActions.ClearOneUnacknowledged,
			icon: <ClearOutlined style={{ ...iconClearColor, ...(isClearAlarmOptionDisabled ? iconDisabled : {}) }} disabled={isClearAlarmOptionDisabled} />,
			disabled: isClearAlarmOptionDisabled,
			title: getPermissionErrorMessage(isClearAlarmAllowed),
		},
		{
			id: 'clearAllOption',
			label: _('ClearAll'),
			value: isAcknowledgedAlarm ? AlarmAcknowledgementActions.ClearAllAcknowledged : AlarmAcknowledgementActions.ClearAllUnacknowledged,
			icon: (
				<>
					<ClearOutlined
						style={{ ...iconClearColor, ...(!isClearMultipleAlarmAllowed || isAlarmTableEmpty ? iconDisabled : {}) }}
						disabled={!isClearMultipleAlarmAllowed || isAlarmTableEmpty}
					/>
					<PlusOutlined
						style={{ ...iconPlusColor, ...(!isClearMultipleAlarmAllowed || isAlarmTableEmpty ? iconDisabled : {}) }}
						disabled={!isClearMultipleAlarmAllowed || isAlarmTableEmpty}
					/>
				</>
			),
			separator: true,
			disabled: !isClearMultipleAlarmAllowed || isAlarmTableEmpty,
			title: getPermissionErrorMessage(isClearMultipleAlarmAllowed || isAlarmTableEmpty),
		},
	];

	const addOperatorNoteOption: SelectOptions<AlarmActionOptions> = {
		id: 'addOperatorNoteOption',
		label: _('AddOperatorNote'),
		value: AlarmActionOptions.ADD_OPERATOR_NOTE,
		icon: <FileOutlined style={{ ...iconAddNoteColor, ...(isAddOperatorNoteDisabled ? iconDisabled : {}) }} disabled={isAddOperatorNoteDisabled} />,
		disabled: isAddOperatorNoteDisabled,
		title: getPermissionErrorMessage(isAddNoteAllowed),
	};

	const options = isAcknowledgedAlarm
		? [...clearOptions, { ...addOperatorNoteOption }]
		: [...acknowledgedOptions, ...(isClearUnacknowledgedAllowed ? clearOptions : []), { ...addOperatorNoteOption }];

	return { labelOrIcon: '...', options };
};

export const isMultipleAcknowledgeAction = (action: AlarmAcknowledgementActions) => {
	return action === AlarmAcknowledgementActions.AcknowledgedMultiple || action === AlarmAcknowledgementActions.AcknowledgedAll;
};

export const isMultipleClearAction = (action: AlarmAcknowledgementActions) => {
	return (
		action === AlarmAcknowledgementActions.ClearMultipleUnacknowledged ||
		action === AlarmAcknowledgementActions.ClearAllUnacknowledged ||
		action === AlarmAcknowledgementActions.ClearMultipleAcknowledged ||
		action === AlarmAcknowledgementActions.ClearAllAcknowledged
	);
};

export const isNoteRequiredForEachAlarm = (action: AlarmAcknowledgementActions) => {
	return (forceNoteForMultipleAcknowledge && isMultipleAcknowledgeAction(action)) || (forceNoteForMultipleClear && isMultipleClearAction(action));
};

export const isNoteRequiredForAllAlarm = (action: AlarmAcknowledgementActions) => {
	return (!forceNoteForMultipleAcknowledge && isMultipleAcknowledgeAction(action)) || (!forceNoteForMultipleClear && isMultipleClearAction(action));
};

export const isNoteRequired = action => {
	const isAcknowledgedAlarm =
		action === AlarmAcknowledgementActions.AcknowledgedOne ||
		action === AlarmAcknowledgementActions.AcknowledgedAll ||
		action === AlarmAcknowledgementActions.AcknowledgedMultiple;

	return (requireNoteOnAcknowledge && isAcknowledgedAlarm) || (requireNoteOnClear && !isAcknowledgedAlarm);
};

export const acknowledgedAndClearAlarms = (payload: AcknowledgedAndClearAlarmsPayload) => {
	const {
		action,
		acknowledgeAndClearCallback,
		addOperatorNoteCallback,
		setAlarmNoteForAcknowledgeCallback,
		setShowAlarmAddOperatorNoteCallback,
		saveChangesCallback,
	} = payload;

	const noteRequired = isNoteRequired(action);

	let alarmNote: AlarmNoteForAcknowledge;
	if (noteRequired) {
		alarmNote = addOperatorNoteCallback(action, payload);

		setShowAlarmAddOperatorNoteCallback();
		setAlarmNoteForAcknowledgeCallback(alarmNote);
	} else {
		alarmNote = acknowledgeAndClearCallback(payload);

		if (alarmNote) {
			setAlarmNoteForAcknowledgeCallback(alarmNote);
		}

		saveChangesCallback?.();
	}
};

const showAckMsgModal = (msgs: string[]) => {
	if (msgs?.length > 0) {
		ModalInfo({
			content: msgs[0],
			icon: null,
			okText: _('Ok'),
			onOk: () => {
				msgs.splice(0, 1);
				showAckMsgModal(msgs);
			},
			title: _('AlarmViewer'),
			width: '500px',
		});
	}
};

export const acknowledgeAndClearAlarmCommon = ({
	action,
	alarmIds,
	operatorNotes,
	setIsLoadingCallback,
}: AcknowledgedAndClearAlarmsPayload): AlarmNoteForAcknowledge => {
	setIsLoadingCallback?.(true);
	let alarmNote: AlarmNoteForAcknowledge = undefined;

	alarmApi
		.acknowledgeAndClearAlarms(action, alarmIds, operatorNotes)
		.then(res => {
			if (res.AdditionalResponseInfo?.length > 0) {
				const msgs: string[] = res.AdditionalResponseInfo.split('|');
				showAckMsgModal(msgs);
			}
			NotificationStatus({
				responseData: res,
				notUseDefaultNotification: true,
				onFailedValidation: () => {
					notification['info']({
						message: _('AdditionalPermissionNotReturnedToNormalAlarms'),
					});
				},
			});
		})
		.finally(() => {
			if (isNoteRequired(action)) {
				alarmNote = {
					isAlarmNoteForAcknowledge: false,
					acknowledgeAction: AlarmAcknowledgementActions.None,
					alarms: [],
				} as AlarmNoteForAcknowledge;
			}
			setIsLoadingCallback?.(false);
		});

	return alarmNote;
};

export const getAlarmNoteAddOperatorNoteModal = (
	action: AlarmAcknowledgementActions,
	isAlarmNoteForAcknowledge: boolean,
	alarmIds: React.Key[],
	dataSource: AlarmModel[] = [],
	alarmsDetails: AlarmModel[] = []
): AlarmNoteForAcknowledge => {
	const alarms = isAnyAllAction(action) ? getOperatorNotesAlarms(alarmsDetails, alarmIds) : getOperatorNotesAlarms(dataSource, alarmIds);
	const alarmNote = {
		isAlarmNoteForAcknowledge,
		acknowledgeAction: action,
		alarms,
	} as AlarmNoteForAcknowledge;

	return alarmNote;
};

export const getMultipleAlarmAction = (selectedKeys: React.Key[], action: AlarmAcknowledgementActions): AlarmAcknowledgementActions => {
	if (selectedKeys.length > 1) {
		switch (action) {
			case AlarmAcknowledgementActions.AcknowledgedOne:
				return AlarmAcknowledgementActions.AcknowledgedMultiple;

			case AlarmAcknowledgementActions.ClearOneAcknowledged:
				return AlarmAcknowledgementActions.ClearMultipleAcknowledged;

			case AlarmAcknowledgementActions.ClearOneUnacknowledged:
				return AlarmAcknowledgementActions.ClearMultipleUnacknowledged;
		}
	}
	return action;
};

export const getOperatorNotesAlarms = (alarms: AlarmModel[], selectedKeys: React.Key[]): OperatorNoteAlarm[] => {
	return alarms.reduce<OperatorNoteAlarm[]>((prevAlarms, alarm) => {
		if (alarm.AlarmDetails.length) {
			const newAlarms: OperatorNoteAlarm[] = alarm.AlarmDetails.filter(x =>
				selectedKeys.some(key => Number(key) === x.ActiveAlarmId)
			).map<OperatorNoteAlarm>(alarmDetail => {
				return {
					EventId: alarm.EventId,
					Description: alarm.Description,
					Address: alarm.Address,
					Level: alarm.Level,
					Status: alarm.Status,
					Secures: alarm.Secures,
					ReturnedToNormal: alarm.ReturnedToNormal,
					ActiveAlarmId: alarmDetail.ActiveAlarmId,
					HostTime: alarmDetail.HostTime,
					HardwareTime: alarmDetail.HardwareTime,
					Operator: alarmDetail.Operator,
					Workstation: alarmDetail.Workstation,
					Time: alarmDetail.Time,
				} as OperatorNoteAlarm;
			});

			if (newAlarms.length) {
				prevAlarms.push(...newAlarms);
			}
		}
		return prevAlarms;
	}, []);
};

export const getNextPage = (quantityOfAlarms: number, pageSize: number): number => {
	return Math.ceil((quantityOfAlarms + 1) / pageSize);
};

export const getCurrentPage = (quantityOfAlarms: number, pageSize: number): number => {
	return Math.ceil(quantityOfAlarms / pageSize);
};

export const createTableData = (updatedDataSource: (AlarmModel & Pick<AlarmTableDataSourceModel, 'HeaderAlarmDetails'>)[]): AlarmTableDataSourceModel[] => {
	return updatedDataSource.map<AlarmTableDataSourceModel>(alarm => {
		const isExpandableRow: boolean = alarm.AlarmDetails.length > 1 || alarm.HeaderAlarmDetails?.length > 1;

		if (isExpandableRow) {
			const alarmDetails = alarm.AlarmDetails.map((alarmDetail, index) => {
				if (index === 0) {
					return {
						...alarmDetail,
						IsExpandableRowHeader: true,
					};
				}
				return {
					...alarmDetail,
					IsExpandableRowHeader: false,
				};
			});

			return {
				...alarm,
				key: `${alarm.EventId}|${alarm.Address}`,
				AlarmDetails: [...alarmDetails],
			};
		}

		return {
			...alarm,
			key: `${alarm.EventId}|${alarm.Address}`,
		};
	});
};

export const addNotes = (dataSource: AlarmTableDataSourceModel[], operatorNotesAdded: AlarmNote[]): AlarmTableDataSourceModel[] => {
	const clonedDataSource = [...dataSource];

	operatorNotesAdded.forEach(alarmNote => {
		clonedDataSource.forEach(alarmModel => {
			alarmModel.AlarmDetails.forEach(alarm => {
				if (alarm.ActiveAlarmId === alarmNote.AlarmId) {
					alarm.AlarmNotes = [...alarm.AlarmNotes, alarmNote];
				}
			});
		});
	});

	return clonedDataSource;
};

export const updateTotalAlarmPages = (currentPage: number, currentTotalPages: number): number => {
	if (currentTotalPages === 0 || currentTotalPages < currentPage) {
		return currentPage;
	}

	return currentTotalPages;
};

export const getAcknowledgeActionForEachAlarm = (action: AlarmAcknowledgementActions): AlarmAcknowledgementActions => {
	switch (action) {
		case AlarmAcknowledgementActions.AcknowledgedAll:
			return AlarmAcknowledgementActions.AcknowledgedOne;

		case AlarmAcknowledgementActions.ClearAllUnacknowledged:
			return AlarmAcknowledgementActions.ClearOneUnacknowledged;

		case AlarmAcknowledgementActions.ClearAllAcknowledged:
			return AlarmAcknowledgementActions.ClearOneAcknowledged;
	}
	return action;
};

export const isAnyAllAction = (action: AlarmAcknowledgementActions): boolean => {
	switch (action) {
		case AlarmAcknowledgementActions.AcknowledgedAll:
		case AlarmAcknowledgementActions.ClearAllUnacknowledged:
		case AlarmAcknowledgementActions.ClearAllAcknowledged:
			return true;
	}
	return false;
};

import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import { ControlZone } from '../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../common';
import { StoreContext, setControlZoneSelected, setControlZones, setErrors } from '../../contextReader';
import styles from './controlZonesDropdown.module.scss';

const mainDivContainerId: string = 'readerControlGroupControlZoneArea';

const ControlZonesDropdown: React.FC = () => {
	const {
		readerState: {
			controlZones,
			controlZoneSelected,
			displayMyNetworkLayout,
			errors: { controlZoneInput },
		},
		dispatcher,
	} = useContext(StoreContext);

	//reset state on items selected has been removed from InfoGrid
	useEffect(() => {
		dispatcher(setControlZoneSelected(null));
		dispatcher(setControlZones([]));
	}, [displayMyNetworkLayout]);

	const handleChangeControlZone = (controlZoneId: string) => {
		const currentControlZone: ControlZone = controlZones.find(x => x.ControlZoneIndex === Number(controlZoneId));
		dispatcher(setControlZoneSelected({ ...currentControlZone }));
		dispatcher(setErrors({ type: 'controlZoneInput', value: false }));
	};

	const readerControlGroupControlZonesDropdownId = 'readerControlGroupControlZonesDropdown';
	const readerControlGroupControlZonesErrorLabelId = 'readerControlGroupControlZonesErrorLabel';

	return (
		<div className={styles.container} id={mainDivContainerId}>
			<label htmlFor={readerControlGroupControlZonesDropdownId}>{_('ControlZones')}</label>
			<div className={cx({ [styles.error]: controlZoneInput })}>
				<Select
					id={readerControlGroupControlZonesDropdownId}
					onChange={handleChangeControlZone}
					disabled={controlZones.length === 0}
					value={controlZoneSelected?.ControlZoneName ?? ''}
					options={controlZones.map(x => ({
						label: x.ControlZoneName,
						value: x.ControlZoneIndex,
						id: `readerControlGroupControlZoneOption-${x.ControlZoneIndex}`,
					}))}
					getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
					showSearch
				/>
				{controlZoneInput && (
					<label id='readerControlGroupControlZonesErrorLabel' htmlFor={readerControlGroupControlZonesDropdownId}>
						{_('ControlZoneIsRequired')}
					</label>
				)}
			</div>
		</div>
	);
};

export { ControlZonesDropdown };

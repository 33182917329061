import { ColumnsType } from 'antd/lib/table';
import { CSSProperties, useEffect, useRef } from 'react';
import { ColumnsProps, buildColumn } from '../../../Helper';
import { ComponentPermission, SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../../model/AccountModel';
import { BaseColumns, SelectOptions } from '../../../model/CommonModel';
import {
	ActionDeviceTypes,
	ControllerContact,
	ControllerContactMappedData,
	ControllerContactSpecificData,
	DeviceObjectType,
	OptionsBuilder,
	OptionsDigiTrac,
	SelectOption,
} from '../../../model/DeviceAdminModel';

const user: User = getUser();
const portComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Ports);
const xboxComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.XBox);
const doorComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Door);
const expansionInputComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Expansion_Input);
const expansionRelayComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Expansion_Relay);
const inputComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Input);
const relayComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Relay);
const readerComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Reader);
const controllerComponentPermission = User.getComponentPermission(user, SecuredComponents.Controller);
const inputStyles: CSSProperties = { minWidth: '20em' };

type DigitracOptionsProps = {
	deviceObjectType?;
	hasMoreThanOne?: boolean;
	isItemOnSamePage?: boolean;
	isStatusViewer?: boolean;
};

const getDigiTracOptions = ({
	deviceObjectType,
	hasMoreThanOne = false,
	isItemOnSamePage = false,
	isStatusViewer = false,
}: DigitracOptionsProps): SelectOptions<OptionsDigiTrac>[] => {
	let options: SelectOptions<OptionsDigiTrac>[] = [];

	switch (deviceObjectType) {
		case DeviceObjectType.Port:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'portEditButton',
					label: _('Edit'),
					disabled: !portComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(portComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'portRenameButton',
								label: _('Rename'),
								disabled: !portComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(portComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
				{
					id: 'portDeleteButton',
					label: _('Delete'),
					disabled: !portComponentPermission.canDelete,
					value: { key: 'delete' },
					title: getPermissionErrorMessage(portComponentPermission.canDelete),
				},
			];
			break;

		case DeviceObjectType.Xbox:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'xboxEditButton',
					label: _('Edit'),
					disabled: !xboxComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(xboxComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'xboxRenameButton',
								label: _('Rename'),
								disabled: !xboxComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(xboxComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
				{
					id: 'xboxDeleteButton',
					label: _('Delete'),
					disabled: !xboxComponentPermission.canDelete,
					value: { key: 'delete' },
					title: getPermissionErrorMessage(xboxComponentPermission.canDelete),
				},
			];
			break;

		case DeviceObjectType.Door:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'doorEditButton',
					label: _('Edit'),
					disabled: !doorComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(doorComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'doorRenameButton',
								label: _('Rename'),
								disabled: !doorComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(doorComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
				//TODO: check lstRightPane_MouseUp for options and permissions
			];
			break;

		case DeviceObjectType.ExpansionInputs:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'expansionInputEditButton',
					label: _('Edit'),
					disabled: !expansionInputComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(expansionInputComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'expansionInputRenameButton',
								label: _('Rename'),
								disabled: !expansionInputComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(expansionInputComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
			];
			break;

		case DeviceObjectType.ExpansionRelays:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'expansionRelayEditButton',
					label: _('Edit'),
					disabled: !expansionRelayComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(expansionRelayComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'expansionRelayRenameButton',
								label: _('Rename'),
								disabled: !expansionRelayComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(expansionRelayComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
			];
			break;

		case DeviceObjectType.Input:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'inputEditButton',
					label: _('Edit'),
					disabled: !inputComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(inputComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'inputRenameButton',
								label: _('Rename'),
								disabled: !inputComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(inputComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
			];
			break;

		case DeviceObjectType.Relay:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'relayEditButton',
					label: _('Edit'),
					disabled: !relayComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(relayComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'relayRenameButton',
								label: _('Rename'),
								disabled: !relayComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(relayComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
			];
			break;

		case DeviceObjectType.Reader:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'readerEditButton',
					label: _('Edit'),
					disabled: !readerComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(readerComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'readerRenameButton',
								label: _('Rename'),
								disabled: !readerComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(readerComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
			];
			break;

		case DeviceObjectType.Controller:
			options = [
				{
					//This only uses can view permissions since we disabled the Save button on the component itself, if it doesn't have permissions
					id: 'controllerEditButton',
					label: _('Edit'),
					disabled: !controllerComponentPermission.canView || hasMoreThanOne,
					value: { key: 'edit' },
					title: getPermissionErrorMessage(controllerComponentPermission.canView, false, hasMoreThanOne),
				},
				...(!isStatusViewer
					? [
							{
								id: 'controllerRenameButton',
								label: _('Rename'),
								disabled: !controllerComponentPermission.canUpdate || hasMoreThanOne || !isItemOnSamePage,
								value: { key: 'rename' },
								title: getPermissionErrorMessage(controllerComponentPermission.canUpdate, false, hasMoreThanOne),
							},
					  ]
					: []),
				{
					id: 'controllerDeleteButton',
					label: _('Delete'),
					disabled: !controllerComponentPermission.canDelete,
					value: { key: 'delete' },
					title: getPermissionErrorMessage(controllerComponentPermission.canDelete),
				},
			];
			break;
	}

	return options;
};

type BuildOptionsProps = {
	deviceType?: DeviceObjectType;
	hasMoreThanOne?: boolean;
	hasResetEncryption?: boolean;
	isStatusViewer?: boolean;
};
const buildOptions = ({ deviceType, hasMoreThanOne = false, hasResetEncryption = false, isStatusViewer = false }: BuildOptionsProps): OptionsBuilder[] => {
	let options: OptionsBuilder[] = [];

	const key: string = 'sendCommand';
	const user: User = getUser();
	const disabled: boolean = !User.getSubComponentPermission(
		user,
		!isStatusViewer ? SubPermissions.AdministrationWindow_AdministrationWindow_UseFunctions : SubPermissions.StatusViewer_StatusViewer_UseFunctions
	).allowed;

	switch (deviceType) {
		case DeviceObjectType.Controller:
			options = [
				{
					id: 'controllerActionButton',
					label: _('Action'),
					options: [
						{
							id: 'controllerEnableButton',
							label: _('Enabled'),
							value: { key, action: ActionDeviceTypes.Enabled },
							disabled: !controllerComponentPermission.canUpdate,
							title: getPermissionErrorMessage(controllerComponentPermission.canUpdate),
						},
						{
							id: 'controllerDisableButton',
							label: _('Disable'),
							value: { key, action: ActionDeviceTypes.Disabled },
							separator: true,
							disabled: !controllerComponentPermission.canUpdate,
							title: getPermissionErrorMessage(controllerComponentPermission.canUpdate),
						},
						{
							id: 'controllerDownloadConfigurationButton',
							label: _('DownloadConfiguration'),
							value: { key, action: ActionDeviceTypes.DownConfiguration },
							disabled: !controllerComponentPermission.canUpdate,
							title: getPermissionErrorMessage(controllerComponentPermission.canUpdate),
						},
						{
							id: 'controllerDownloadCredentialsButton',
							label: _('DownloadCredentials'),
							value: { key, action: ActionDeviceTypes.DownCredential },
							disabled: !controllerComponentPermission.canUpdate,
							title: getPermissionErrorMessage(controllerComponentPermission.canUpdate),
						},
						{
							id: 'controllerDownloadDateTimeButton',
							label: _('DownloadDate/Time'),
							value: { key, action: ActionDeviceTypes.DownDateTime },
							disabled: !controllerComponentPermission.canUpdate,
							title: getPermissionErrorMessage(controllerComponentPermission.canUpdate),
						},
						{
							id: 'controllerDownloadForgiveUsersButton',
							label: _('DownloadForgiveUsers'),
							value: { key, action: ActionDeviceTypes.DownForGiveUser },
							disabled: !controllerComponentPermission.canUpdate,
							title: getPermissionErrorMessage(controllerComponentPermission.canUpdate),
						},
					],
				},
			];
			break;

		case DeviceObjectType.Xbox:
			options = [
				{
					id: 'xboxActionButton',
					label: _('Action'),
					options: [
						{
							id: 'xboxEnableButton',
							label: _('Enable'),
							value: { key, action: ActionDeviceTypes.Enabled },
							disabled: !xboxComponentPermission.canUpdate,
							title: getPermissionErrorMessage(xboxComponentPermission.canUpdate),
						},
						{
							id: 'xboxDisableButton',
							label: _('Disable'),
							value: { key, action: ActionDeviceTypes.Disabled },
							disabled: !xboxComponentPermission.canUpdate,
							title: getPermissionErrorMessage(xboxComponentPermission.canUpdate),
						},
					],
				},
			];
			break;

		case DeviceObjectType.Door:
			options = [
				{
					id: 'doorAccessButton',
					label: _('Access'),
					disabled: disabled,
					title: getPermissionErrorMessage(!disabled),
					options: [
						{
							id: 'doorMomentaryAccessButton',
							label: _('MomentaryAccess'),
							value: { key, action: ActionDeviceTypes.MomentaryAccess },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorUnlockButton',
							label: _('Unlock'),
							value: { key, action: ActionDeviceTypes.UnLockDoor },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorRelockButton',
							label: _('Relock'),
							value: { key, action: ActionDeviceTypes.RelockDoor },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorToggleLockButton',
							label: _('ToggleLock'),
							value: { key, action: ActionDeviceTypes.ToggleLock },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
					],
				},
				{
					id: 'doorControlButton',
					label: _('Control'),
					disabled: disabled,
					title: getPermissionErrorMessage(!disabled),
					options: [
						{
							id: 'doorTriggerButton',
							label: _('Trigger'),
							value: { key, action: ActionDeviceTypes.Trigger },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorMaskButton',
							label: _('Mask'),
							value: { key, action: ActionDeviceTypes.Mask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorUnMaskButton',
							label: _('UnMask'),
							value: { key, action: ActionDeviceTypes.UnMask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorMomentaryMaskButton',
							label: _('MomentaryMask'),
							value: { key, action: ActionDeviceTypes.MomentaryMask },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorSuppressOperateButton',
							label: _('SuppressOperate'),
							value: { key, action: ActionDeviceTypes.SuppressOperate },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorSuppressOperateReleaseButton',
							label: _('SuppressOperateRelease'),
							value: { key, action: ActionDeviceTypes.SuppressOperateRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorForceOnButton',
							label: _('ForceOn'),
							value: { key, action: ActionDeviceTypes.ForceOn },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorForceOnReleaseButton',
							label: _('ForceOnRelease'),
							value: { key, action: ActionDeviceTypes.ForceOnRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorForceOffButton',
							label: _('ForceOff'),
							value: { key, action: ActionDeviceTypes.ForceOff },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorForceOffReleaseButton',
							label: _('ForceOffRelease'),
							value: { key, action: ActionDeviceTypes.ForceOffRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorLockDownButton',
							label: _('LockDown'),
							value: { key, action: ActionDeviceTypes.LockDown },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorLockDownReleaseButton',
							label: _('LockDownRelease'),
							value: { key, action: ActionDeviceTypes.LockDownRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorLockOpenButton',
							label: _('LockOpen'),
							value: { key, action: ActionDeviceTypes.LockOpen },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'doorLockOpenReleaseButton',
							label: _('LockOpenRelease'),
							value: { key, action: ActionDeviceTypes.LockOpenRelease },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
					],
				},
			];
			break;

		case DeviceObjectType.Port:
			let opts = [
				{
					id: 'portEnablebutton',
					label: _('Enable'),
					value: { key, action: ActionDeviceTypes.Enabled },
					disabled: !portComponentPermission.canUpdate,
					title: getPermissionErrorMessage(portComponentPermission.canUpdate),
				},
				{
					id: 'portDisableButton',
					label: _('Disable'),
					value: { key, action: ActionDeviceTypes.Disabled },
					separator: hasResetEncryption,
					disabled: !portComponentPermission.canUpdate,
					title: getPermissionErrorMessage(portComponentPermission.canUpdate),
				},
			];

			if (hasResetEncryption) {
				opts.push({
					id: 'portResetEncryptionButton',
					label: _('ResetEncryption'),
					value: { key, action: ActionDeviceTypes.Resetencry },
					disabled: !portComponentPermission.canUpdate || !portComponentPermission.canView || hasMoreThanOne,
					title: getPermissionErrorMessage(portComponentPermission.canUpdate && portComponentPermission.canView, false, hasMoreThanOne),
				});
			}

			options = [
				{
					id: 'portActionButton',
					label: _('Action'),
					options: opts,
				},
			];
			break;

		case DeviceObjectType.ExpansionRelays:
			options = [
				{
					id: 'expansionRelayControlButton',
					label: _('Control'),
					disabled: disabled,
					title: getPermissionErrorMessage(!disabled),
					options: [
						{
							id: 'expansionRelayTriggerButton',
							label: _('Trigger'),
							value: { key, action: ActionDeviceTypes.Trigger },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelaySuppressOperateButton',
							label: _('SuppressOperate'),
							value: { key, action: ActionDeviceTypes.SuppressOperate },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelaySuppressOperateReleaseButton',
							label: _('SuppressOperateRelease'),
							value: { key, action: ActionDeviceTypes.SuppressOperateRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayForceOnButton',
							label: _('ForceOn'),
							value: { key, action: ActionDeviceTypes.ForceOn },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayForceOnReleaseButton',
							label: _('ForceOnRelease'),
							value: { key, action: ActionDeviceTypes.ForceOnRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayForceOffButton',
							label: _('ForceOff'),
							value: { key, action: ActionDeviceTypes.ForceOff },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayForceOffReleaseButton',
							label: _('ForceOffRelease'),
							value: { key, action: ActionDeviceTypes.ForceOffRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayLockDownButton',
							label: _('LockDown'),
							value: { key, action: ActionDeviceTypes.LockDown },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayLockDownReleaseButton',
							label: _('LockDownRelease'),
							value: { key, action: ActionDeviceTypes.LockDownRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayLockOpenButton',
							label: _('LockOpen'),
							value: { key, action: ActionDeviceTypes.LockOpen },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionRelayLockOpenReleaseButton',
							label: _('LockOpenRelease'),
							value: { key, action: ActionDeviceTypes.LockOpenRelease },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
					],
				},
			];
			break;

		case DeviceObjectType.ExpansionInputs:
			options = [
				{
					id: 'expansionInputActionButton',
					label: _('Action'),
					options: [
						{
							id: 'expansionInputEnableButton',
							label: _('Enable'),
							value: { key, action: ActionDeviceTypes.Enabled },
							disabled: !expansionInputComponentPermission.canUpdate,
							title: getPermissionErrorMessage(expansionInputComponentPermission.canUpdate),
						},
						{
							id: 'expansionInputDisableButton',
							label: _('Disable'),
							value: { key, action: ActionDeviceTypes.Disabled },
							disabled: !expansionInputComponentPermission.canUpdate,
							title: getPermissionErrorMessage(expansionInputComponentPermission.canUpdate),
						},
					],
				},
				{
					id: 'expansionInputControlButton',
					label: _('Control'),
					disabled: disabled,
					title: getPermissionErrorMessage(!disabled),
					options: [
						{
							id: 'expansionInputMaskButton',
							label: _('Mask'),
							value: { key, action: ActionDeviceTypes.Mask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionInputUnMaskButton',
							label: _('UnMask'),
							value: { key, action: ActionDeviceTypes.UnMask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'expansionInputMomentaryMaskButton',
							label: _('MomentaryMask'),
							value: { key, action: ActionDeviceTypes.MomentaryMask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
					],
				},
			];
			break;

		case DeviceObjectType.Input:
			options = [
				{
					id: 'inputActionButton',
					label: _('Action'),
					options: [
						{
							id: 'inputEnableButton',
							label: _('Enable'),
							value: { key, action: ActionDeviceTypes.Enabled },
							disabled: !inputComponentPermission.canUpdate,
							title: getPermissionErrorMessage(inputComponentPermission.canUpdate),
						},
						{
							id: 'inputDisableButton',
							label: _('Disable'),
							value: { key, action: ActionDeviceTypes.Disabled },
							disabled: !inputComponentPermission.canUpdate,
							title: getPermissionErrorMessage(inputComponentPermission.canUpdate),
						},
					],
				},
				{
					id: 'inputControlButton',
					label: _('Control'),
					disabled: disabled,
					title: getPermissionErrorMessage(!disabled),
					options: [
						{
							id: 'inputMaskButton',
							label: _('Mask'),
							value: { key, action: ActionDeviceTypes.Mask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'inputUnMaskButton',
							label: _('UnMask'),
							value: { key, action: ActionDeviceTypes.UnMask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'inputMomentaryMaskButton',
							label: _('MomentaryMask'),
							value: { key, action: ActionDeviceTypes.MomentaryMask },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
					],
				},
			];
			break;

		case DeviceObjectType.Relay:
			options = [
				{
					id: 'relayControlButton',
					label: _('Control'),
					disabled: disabled,
					title: getPermissionErrorMessage(!disabled),
					options: [
						{
							id: 'relayTriggerButton',
							label: _('Trigger'),
							value: { key, action: ActionDeviceTypes.Trigger },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relaySuppressOperateButton',
							label: _('SuppressOperate'),
							value: { key, action: ActionDeviceTypes.SuppressOperate },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relaySuppressOperateReleaseButton',
							label: _('SuppressOperateRelease'),
							value: { key, action: ActionDeviceTypes.SuppressOperateRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayForceOnButton',
							label: _('ForceOn'),
							value: { key, action: ActionDeviceTypes.ForceOn },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayForceOnReleaseButton',
							label: _('ForceOnRelease'),
							value: { key, action: ActionDeviceTypes.ForceOnRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayForceOffButton',
							label: _('ForceOff'),
							value: { key, action: ActionDeviceTypes.ForceOff },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayForceOffReleaseButton',
							label: _('ForceOffRelease'),
							value: { key, action: ActionDeviceTypes.ForceOffRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayLockDownButton',
							label: _('LockDown'),
							value: { key, action: ActionDeviceTypes.LockDown },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayLockDownReleaseButton',
							label: _('LockDownRelease'),
							value: { key, action: ActionDeviceTypes.LockDownRelease },
							separator: true,
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayLockOpenButton',
							label: _('LockOpen'),
							value: { key, action: ActionDeviceTypes.LockOpen },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
						{
							id: 'relayLockOpenReleaseButton',
							label: _('LockOpenRelease'),
							value: { key, action: ActionDeviceTypes.LockOpenRelease },
							disabled: disabled,
							title: getPermissionErrorMessage(!disabled),
						},
					],
				},
			];
			break;
	}

	return options;
};

const getSpecificColumns = (deviceType: DeviceObjectType, isEditMode: boolean): ColumnsProps<ControllerContact>[] => {
	let columns: ColumnsProps<ControllerContact>[] = [];

	switch (deviceType) {
		case DeviceObjectType.Controller:
			columns = [
				{
					...buildColumn(_('ControllerTimeZone'), 'ControllerTimeZone', 'auto', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('Model'), 'ControllerType', '85px', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('Enabled'), 'Enabled', '85px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;

		case DeviceObjectType.Xbox:
			columns = [
				{
					...buildColumn(_('Enabled'), 'Enabled', '85px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;

		case DeviceObjectType.Door:
			columns = [
				{
					...buildColumn(_('ReaderInterfaceEntryReader'), 'ReaderInterfaceEntryReader', '230px', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('ReaderInterfaceExitReader'), 'ReaderInterfaceExitReader', '230px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;
		case DeviceObjectType.Reader:
			columns = [
				{
					...buildColumn(_('ReaderInterface'), 'ReaderInterface', '230px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;

		case DeviceObjectType.Port:
			columns = [
				{
					...buildColumn(_('IPAddress'), 'IPAddress', 'auto', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('NetworkType'), 'NetworkType', '125px', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('Protocol'), 'Protocol', '85px', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('Enabled'), 'Enabled', '85px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;

		case DeviceObjectType.ExpansionRelays:
		case DeviceObjectType.Relay:
			columns = [
				{
					...buildColumn(_('NormalStateWhenSecure'), 'NormalStateWhenSecure', '210px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;

		case DeviceObjectType.ExpansionInputs:
		case DeviceObjectType.Input:
			columns = [
				{
					...buildColumn(_('NormalStateWhenSecure'), 'NormalStateWhenSecure', '210px', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('LineModule'), 'LineModule', '140px', 'start'),
					sorter: !isEditMode,
				},
				{
					...buildColumn(_('Enabled'), 'Enabled', '85px', 'start'),
					sorter: !isEditMode,
				},
			];
			break;
	}

	return columns;
};

const getSpecificData = (deviceType: DeviceObjectType, controllerContact: ControllerContact): Partial<ControllerContactSpecificData> => {
	let data: Partial<ControllerContactSpecificData> = {};

	switch (deviceType) {
		case DeviceObjectType.Controller:
			data.ControllerTimeZone = controllerContact.ControllerTimeZone;
			data.ControllerType = controllerContact.ControllerType;
			data.Enabled = controllerContact.Enable ? _('Enabled') : '-';
			break;

		case DeviceObjectType.Xbox:
			data.Enabled = controllerContact.Enable ? _('Enabled') : '-';
			break;

		case DeviceObjectType.Door:
			data.ReaderInterfaceEntryReader = controllerContact.ReaderInterfaceEntryReader;
			data.ReaderInterfaceExitReader = controllerContact.ReaderInterfaceExitReader;
			break;
		case DeviceObjectType.Reader:
			data.ReaderInterface = controllerContact.ReaderInterface;
			break;

		case DeviceObjectType.Port:
			data.IPAddress = controllerContact.IPAddress;
			data.NetworkType = controllerContact.NetworkType;
			data.Protocol = controllerContact.Protocol;
			data.Enabled = controllerContact.Enable ? _('Enabled') : '-';
			break;

		case DeviceObjectType.ExpansionRelays:
		case DeviceObjectType.Relay:
			data.NormalStateWhenSecure = controllerContact.NormalStateWhenSecure ? _('Energized') : _('Deenergized');
			break;

		case DeviceObjectType.ExpansionInputs:
		case DeviceObjectType.Input:
			data.LineModule = controllerContact.LineModule;
			data.NormalStateWhenSecure = controllerContact.NormalStateWhenSecure ? _('NormallyOpen') : _('NormallyClosed');
			data.Enabled = controllerContact.Enable ? _('Enabled') : '-';
			break;
	}

	return data;
};

const usePreviousProps = <T extends {}>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export function usePropRef<T = any>(props: T) {
	const propRef = useRef<T>(props);

	useEffect(() => {
		propRef.current = props;
	}, [props]);

	return propRef.current;
}

/**
 * Validate every view tab permission on Controller
 * @returns true if can not view any tabs and error message should be displayed, false if can view at least one tab
 */
const cannotViewAnyControllerTabs = (): boolean => {
	const canViewControllerGeneral: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewGeneralTab).allowed;
	const canViewControllerSetup: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewSetupTab).allowed;
	const canViewControllerReporting: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewReportingTab).allowed;
	const canViewControllerPassback: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewPassbackTab).allowed;
	const canViewControllerControlZones: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewControlZonesTab).allowed;
	const canViewControllerAlarmActions: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewAlarmActionsTab).allowed;
	const canViewControllerCommunications: boolean = User.getSubComponentPermission(user, SubPermissions.Controller_ViewCommunicationsTab).allowed;

	return (
		!canViewControllerGeneral &&
		!canViewControllerSetup &&
		!canViewControllerReporting &&
		!canViewControllerPassback &&
		!canViewControllerControlZones &&
		!canViewControllerAlarmActions &&
		!canViewControllerCommunications
	);
};

/**
 * Gets custom address width to prevent address in double line
 * @param deviceObjectType current device object type of the table
 * @returns column width
 */
const getCustomAddressColumnWith = (deviceObjectType: DeviceObjectType): string => {
	let columnWidth: string = 'auto';
	switch (deviceObjectType) {
		case DeviceObjectType.Port:
		case DeviceObjectType.Controller:
			columnWidth = '170px';
			break;
		case DeviceObjectType.Xbox:
		case DeviceObjectType.ExpansionInputs:
		case DeviceObjectType.Input:
		case DeviceObjectType.Door:
		case DeviceObjectType.Relay:
		case DeviceObjectType.ExpansionRelays:
		case DeviceObjectType.Reader:
			columnWidth = '230px';
			break;
	}

	return columnWidth;
};

/**
 * Gets custom status width to minimize it and make sure other columns gets wider
 * @param deviceObjectType current device object type of the table
 * @returns column width
 */
const getCustomStatusColumnWith = (deviceObjectType: DeviceObjectType): string => {
	let columnWidth: string = 'auto';
	switch (deviceObjectType) {
		case DeviceObjectType.Port:
		case DeviceObjectType.Controller:
			columnWidth = '100px';
			break;
		case DeviceObjectType.ExpansionInputs:
		case DeviceObjectType.Input:
			columnWidth = '110px';
			break;
	}

	return columnWidth;
};

export const getContextMenuGenericOptions = <T extends {}>(menuOptions: SelectOptions<T>[], additionalItems?: SelectOptions<T>[]): SelectOptions<T>[] => {
	let contextMenuOptions: SelectOptions<T>[] = [];
	const buttonOptionsLength = menuOptions.length;

	for (let i = 0; i < buttonOptionsLength; i++) {
		const currentOption = menuOptions[i];
		const opts = currentOption.nestedOptions;
		const selectOptions: SelectOptions<T>[] = [...opts];
		const selectOption: SelectOptions<T> = {
			label: currentOption.label,
			disabled: currentOption.disabled,
			id: currentOption.id,
			title: currentOption.title,
		};

		if (selectOptions.length > 3) {
			selectOption.nestedOptions = selectOptions;
			contextMenuOptions.push(selectOption);
		} else {
			contextMenuOptions = [...contextMenuOptions, ...selectOptions];
		}
	}

	if (contextMenuOptions?.length && additionalItems?.length) {
		contextMenuOptions[contextMenuOptions.length - 1].separator = true;
	}

	if (!additionalItems) {
		contextMenuOptions.push(...additionalItems);
	}

	return contextMenuOptions;
};

export const getContextMenuOptions = (menuOptions: OptionsBuilder[], additionalItems: SelectOptions<OptionsDigiTrac>[]): SelectOptions<OptionsDigiTrac>[] => {
	let contextMenuOptions: SelectOptions<OptionsDigiTrac>[] = [];
	const buttonOptionsLength = menuOptions.length;

	for (let i = 0; i < buttonOptionsLength; i++) {
		const currentOption = menuOptions[i];
		const opts = currentOption.options;
		const selectOptions: SelectOptions<OptionsDigiTrac>[] = [...opts];
		const selectOption: SelectOptions<OptionsDigiTrac> = {
			label: currentOption.label,
			disabled: currentOption.disabled,
			id: currentOption.id,
			title: currentOption.title,
		};

		if (selectOptions.length > 3) {
			selectOption.nestedOptions = selectOptions;
			contextMenuOptions.push(selectOption);
		} else {
			contextMenuOptions = [...contextMenuOptions, ...selectOptions];
		}
	}

	if (contextMenuOptions?.length && additionalItems?.length) {
		contextMenuOptions[contextMenuOptions.length - 1].separator = true;
	}

	contextMenuOptions.push(...additionalItems);

	return contextMenuOptions;
};

type MergedColumnProps = {
	columns?: ColumnsProps<ControllerContact>[];
	menuOptions?: OptionsBuilder[];
	additionalItems?: SelectOptions<OptionsDigiTrac>[];
	maxLength?: number;
	isEditMode?: boolean;
	isStatusViewer?: boolean;
	onChangeSelection?: (key: BaseColumns) => void;
	onClickOption?: (sender: OptionsDigiTrac) => void;
	editingKey?: string;
};

const getMergedColumns = ({
	columns,
	menuOptions = [],
	additionalItems = [],
	maxLength,
	isEditMode = false,
	isStatusViewer = false,
	onChangeSelection,
	onClickOption,
	editingKey,
}: MergedColumnProps): ColumnsType => {
	const isEditing = (record: Partial<ControllerContactMappedData>) => record.key.toString() === editingKey;
	let contextMenuOptions: SelectOptions<OptionsDigiTrac>[] = [...getContextMenuOptions(menuOptions, additionalItems)];

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: Partial<ControllerContactMappedData>) => {
						const objectType = Number(record.DeviceObjectType) as DeviceObjectType;
						if (isStatusViewer) {
							const expansionItems = getDigiTracOptions({ deviceObjectType: objectType, isStatusViewer });
							const isPortSelected: boolean = objectType === DeviceObjectType.Port;
							const expansionMenuOptions = buildOptions({ deviceType: objectType, hasResetEncryption: isPortSelected, isStatusViewer });
							contextMenuOptions = [...getContextMenuOptions(expansionMenuOptions, expansionItems)];
						}

						return {
							record,
							dataIndex: col.dataIndex,
							title: col.title,
							maxLength,
							options: contextMenuOptions,
							isTableInEditMode: isEditMode,
							inputStyles: inputStyles,
							onChangeSelection,
							onClickOption,
						};
					},
			  }
			: {
					...col,
					onCell: (record: Partial<ControllerContactMappedData>) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption,
						inputType: 'text',
						inputStyles: inputStyles,
						editing: isEditing(record),
					}),
			  }
	) as ColumnsType;

	return mergedColumns;
};

const getDefaultAssuranceLevelSelection = (options: SelectOption[], storedValue: number, newValue: number, readerTypeChanged: boolean): number => {
	let defaultAssuranceLevelSelection: number;

	const optionIndex: number = options.findIndex((assuranceLevel: SelectOption) => assuranceLevel.Id === storedValue);

	if (!readerTypeChanged && optionIndex !== -1) {
		defaultAssuranceLevelSelection = storedValue;
	} else {
		defaultAssuranceLevelSelection = options[newValue].Id;
	}

	return defaultAssuranceLevelSelection;
};

export {
	buildOptions,
	cannotViewAnyControllerTabs,
	getCustomAddressColumnWith,
	getCustomStatusColumnWith,
	getDefaultAssuranceLevelSelection,
	getDigiTracOptions,
	getMergedColumns,
	getSpecificColumns,
	getSpecificData,
	usePreviousProps,
};

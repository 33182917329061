import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../../model/CommonModel';
import { ReaderError, SelectOption } from '../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import {
	setExitReaderAssuranceLevel,
	setExitReaderCCOTZAssurance,
	setExitReaderDefaultAssuranceLevel,
	setExitReaderError,
	setExitReaderErrorType,
} from '../../../exitReaderContext';
import styles from '../../Logic/logic.module.scss';

const AssuranceLevels: React.FC = () => {
	var {
		contextStateDoor: {
			exitReader: {
				rS485ReaderTypes,
				defaultAssuranceLevels,
				assuranceLevels,
				cCOTZAssurance,
				readerErrorType,
				readerErrorMessage,
				selections: { rS485ReaderType, defaultAssuranceLevel, assuranceLevel },
				isExitReaderError,
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [assurance, setAssurance] = useState(undefined);
	const [readerName, setReaderName] = useState('');
	const [assuranceLevelsList, setAssuranceLevelsList] = useState<SelectOptions<string>[]>([]);
	const [defaultAssuranceLevelsList, setDefaultAssuranceLevelsList] = useState<SelectOptions<string>[]>([]);
	const [errorMessage, setErrorMessage] = useState<JSX.Element>(<></>);

	useEffect(() => {
		if (readerErrorType === ReaderError.Assurance) {
			setErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else {
			setErrorMessage(<></>);
		}
	}, [readerErrorType]);

	useEffect(() => {
		const index: number = rS485ReaderTypes.findIndex(x => x.Id === rS485ReaderType);
		const copyOfArray: SelectOption[] = [...rS485ReaderTypes];
		setReaderName(copyOfArray[index].Name);
		setAssurance(assuranceLevel !== 0 ? assuranceLevel.toString() : undefined);
	}, [rS485ReaderType]);

	useEffect(() => {
		setDefaultAssuranceLevelsList(defaultAssuranceLevels.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
		setAssuranceLevelsList(assuranceLevels.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [defaultAssuranceLevels, assuranceLevels]);

	const handleOnChangeAssurance = (value: string) => {
		setAssurance(value);
		dispatcherDoor(setExitReaderAssuranceLevel(Number(value)));

		if (readerErrorType === ReaderError.Assurance) {
			dispatcherDoor(setExitReaderErrorType(undefined));
			dispatcherDoor(setExitReaderError(false));
		}
	};

	const handleSetCCOTZAssurance = (value: CheckboxChangeEvent) => {
		if (!value.target.checked) {
			setAssurance(undefined);
			dispatcherDoor(setExitReaderAssuranceLevel(0));
		}
		dispatcherDoor(setExitReaderCCOTZAssurance(value.target.checked));

		if (readerErrorType === ReaderError.Assurance) {
			dispatcherDoor(setExitReaderErrorType(undefined));
			dispatcherDoor(setExitReaderError(false));
		}
	};

	const handleOnChangeDefaultAssurance = (value: string) => {
		dispatcherDoor(setExitReaderDefaultAssuranceLevel(Number(value)));
	};

	return (
		<>
			<div className={styles.options}>
				<div className={styles.sectionContainer}>
					<fieldset>
						<legend className={styles.legend}>{_('AssuranceLevel')}</legend>
						<div className={styles.readerType}>
							{readerName} {_('reader')}
						</div>
						<div className={styles.assuranceSelect}>
							<div>
								<Select
									className={styles.select}
									options={defaultAssuranceLevelsList}
									onChange={handleOnChangeDefaultAssurance}
									key='defaultAssurance'
									id='exitReadeModalDefaultAssuranceSelect'
									value={defaultAssuranceLevel.toString()}
									getPopupContainer={() => document.getElementById('logicExitReaderContainer')}
								/>
							</div>
							<span>{_('DefaultAssuranceLevel')}</span>
						</div>
						<div className={styles.ccotzAssuranceSection}>
							<div className={styles.ccotzAssurance}>
								<Checkbox checked={cCOTZAssurance} onChange={handleSetCCOTZAssurance} id={'ccotzAssurance'}>
									<span>{_('EnableLowerAssurance')}</span>
								</Checkbox>
							</div>
						</div>
						<div className={styles.assuranceSelect}>
							<div>
								<Select
									className={cx(styles.select, {
										[styles.error]: readerErrorType === ReaderError.Assurance && isExitReaderError,
									})}
									options={assuranceLevelsList}
									onChange={handleOnChangeAssurance}
									key='assurance'
									value={assurance}
									id='exitReadeModalAssuranceLevelListSelect'
									disabled={!cCOTZAssurance}
									getPopupContainer={() => document.getElementById('logicExitReaderContainer')}
								/>
								{isExitReaderError && errorMessage}
							</div>
							<span>{_('CCOTZAssurance')}</span>
						</div>
					</fieldset>
				</div>
			</div>
		</>
	);
};

export { AssuranceLevels };

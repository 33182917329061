import { convertArrayToObject } from '../../../Helper';
import { ControlZonesEditorState } from '../../../model/DeviceAdminModel';
import { ActionTypes, ControlZoneActionType } from './actionTypes';

type ControlZonesState = ControlZonesEditorState & { editMode: boolean; hasChangedName: boolean };

const INIT_STATE: ControlZonesState = null;

const controlZonesReducer = (state: Readonly<ControlZonesState> = INIT_STATE, action: ControlZoneActionType): ControlZonesState => {
	switch (action.type) {
		case ActionTypes.SET_CONTROL_ZONES_FOR_CONTROLLER: {
			const denomMasterControlZonesEditor = convertArrayToObject(action.payload.MasterControlZonesEditor, 'Index');
			const mapMasterControlZonesIds = action.payload.MasterControlZonesEditor.map(x => x.Index);

			const denomStandardEditor = convertArrayToObject(action.payload.StandardControlZonesEditor, 'Index');
			const mapStandardIds = action.payload.StandardControlZonesEditor.map(x => x.Index);

			return {
				...state,
				...action.payload,
				MasterControlZonesEditor: { ...denomMasterControlZonesEditor },
				MasterControlZonesEditorDataById: [...mapMasterControlZonesIds],
				StandardControlZonesEditor: { ...denomStandardEditor },
				StandardControlZonesEditorDataById: [...mapStandardIds],
				editMode: false,
				hasChangedName: false,
			};
		}
		case ActionTypes.SET_CONTROL_ZONE_EDITOR: {
			return {
				...state,
				StandardControlZonesEditor: {
					...state.StandardControlZonesEditor,
					[action.payload.propertyIndex]: { ...state.StandardControlZonesEditor[action.payload.propertyIndex], ...action.payload.data },
				},
			};
		}

		case ActionTypes.SET_STANDARD_CONTROL_ZONE_LIST: {
			const newData = [...state.StandardControlZonesList];
			const index = newData.findIndex(item => item.ControlZoneIndex === action.payload.ControlZoneIndex);

			if (~index) {
				const item = newData[index];
				newData.splice(index, 1, { ...item, ...action.payload });
			}
			return {
				...state,
				StandardControlZonesList: [...newData],
			};
		}

		case ActionTypes.SET_MASTER_CONTROL_ZONES:
			return {
				...state,
				MasterControlZonesEditor: {
					...state.MasterControlZonesEditor,
					[action.payload.propertyIndex]: { ...state.MasterControlZonesEditor[action.payload.propertyIndex], ...action.payload.value },
				},
			};
		case ActionTypes.SET_CONTROL_ZONES_EDIT_MODE:
			return {
				...state,
				editMode: action.payload,
			};
		case ActionTypes.SET_CONTROL_ZONES_HAS_CHANGE_NAME:
			return {
				...state,
				hasChangedName: action.payload,
			};
		default:
			return { ...state };
	}
};

export { controlZonesReducer, ControlZonesState };

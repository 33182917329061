import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { PortNetWorkType } from '../../../../../../../../../model/DeviceAdminModel';
import { PortStoreContext, setValueSNIBSelectedAction } from '../../../../../contextPort';
import styles from '../../snibConfiguration.module.scss';

type Props = {
	isIPAddressInvalid: boolean;
	isSubnetMaskInvalid: boolean;
	isDefaultGatewayInvalid: boolean;
	onResetIPAddressError: () => void;
	onResetSubnetMaskError: () => void;
	onResetDefaultGatewayError: () => void;
};
const DHCPEnabled: React.FC<Props> = ({
	isIPAddressInvalid,
	isDefaultGatewayInvalid,
	isSubnetMaskInvalid,
	onResetDefaultGatewayError,
	onResetIPAddressError,
	onResetSubnetMaskError,
}) => {
	const {
		portState: { snibSelected, networkType },
		dispatcher,
	} = useContext(PortStoreContext);

	const validateSNIBInfo = (DCHPEnabled: boolean) => {
		if (networkType === PortNetWorkType.IPv4 && DCHPEnabled && snibSelected?.IPAddress === '') {
			dispatcher(setValueSNIBSelectedAction({ type: 'IPAddress', value: '0.0.0.0' }));
		}
		if (networkType === PortNetWorkType.IPv4 && DCHPEnabled && snibSelected?.SubnetMask === '') {
			dispatcher(setValueSNIBSelectedAction({ type: 'SubnetMask', value: '0.0.0.0' }));
		}
		if (networkType === PortNetWorkType.IPv4 && DCHPEnabled && snibSelected?.DefaultGateway === '') {
			dispatcher(setValueSNIBSelectedAction({ type: 'DefaultGateway', value: '0.0.0.0' }));
		}

		if (networkType === PortNetWorkType.IPv6 && DCHPEnabled && snibSelected?.IPv6Address === '') {
			dispatcher(setValueSNIBSelectedAction({ type: 'IPv6Address', value: '0000:0000:0000:0000:0000:0000:0000:0000' }));
		}
		if (networkType === PortNetWorkType.IPv6 && DCHPEnabled && snibSelected?.IPv6Prefix === '') {
			dispatcher(setValueSNIBSelectedAction({ type: 'IPv6Prefix', value: '0000:0000:0000:0000:0000:0000:0000:0000' }));
		}
		if (networkType === PortNetWorkType.IPv6 && DCHPEnabled && snibSelected?.IPv6Gateway === '') {
			dispatcher(setValueSNIBSelectedAction({ type: 'IPv6Gateway', value: '0000:0000:0000:0000:0000:0000:0000:0000' }));
		}
	};

	const resetErrorMessage = () => {
		if (isIPAddressInvalid) onResetIPAddressError();
		if (isSubnetMaskInvalid) onResetSubnetMaskError();
		if (isDefaultGatewayInvalid) onResetDefaultGatewayError();
	};

	const handleChange = (e: CheckboxChangeEvent) => {
		const checked = e.target.checked;

		resetErrorMessage();
		validateSNIBInfo(checked);
		dispatcher(setValueSNIBSelectedAction({ type: 'DHCPEnabled', value: checked ? 'Yes' : 'No' }));
	};

	return (
		<div className={styles.row}>
			<div />
			<div className={styles.dhcp}>
				<Checkbox id='searchSNIBConfigurationDHCPCheckbox' checked={snibSelected?.DHCPEnabled === 'Yes'} onChange={handleChange} />
				<label id='searchSNIBConfigurationDHCPLabel' className={styles.label}>
					{_('DHCPEnabledSnibConfig')}
				</label>
			</div>
		</div>
	);
};

export { DHCPEnabled };

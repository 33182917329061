import { DefaultGridElement, Tuple } from './CommonModel';
import { PhotoCallUp } from './PhotoCallUpModel';

export type PerformanceData = {
	LastBackupDate: Date;
	HasBackUp: boolean;
	DbSize: string;
	LogSize: string;
	CpUsage: string;
	DbCpUsage: string;
	DrivesSizeDb: Tuple[];
	DrivesSizeApp: Tuple[];
	VelocityServices: VelocityService[];
};

export type VelocityService = {
	Name: string;
	CpUsage: string;
	MemoryUsage: string;
	Status: string;
	DisplayName: string;
	ProcessId: number;
	Order: number;
};

export type DigiTracTransaction = {
	BatchId: number;
	Status: string;
	Count: number;
	Progress: number;
	Complete: number;
	Errors: number;
	ControllerName: string;
	Description: string;
	StartTime?: Date;
	Action: DigiTracTransactionAction;
	BatchCompleteEvent: boolean;
	CompletedTime?: number;
} & DefaultGridElement;

export enum DigiTracTransactionAction {
	None = 0,
	Add = 1,
	Update = 2,
	Delete = 3,
}

export type DoorStatusExt = {
	Status: string;
	Alarm: string;
	Masked: string;
	InputAlarm: string;
	InputRQE: string;
	InputTamper: string;
};

export type DoorStatusData = {
	DoorID: number;
	ControllerId: number;
	DoorName: string;
	Address: string;
	DoorIndex: number;
	Enabled: string;
	LineModule: number;
	LineModuleVoltage: string;
	RelayState: string;
	RelayDetails: string;
	TwoPersonRule: string;
	DoorAccess?: DoorAccess;
} & DoorStatusExt;

export type DoorAccess = {
	Granted: number;
	Denied: number;
};

export type DoorAccessRecord = {
	DoorID: number;
	DoorAddress: string;
	DoorControllerId: number;
};

export type CredentialSummary = {
	People: number;
	UnassignedCredentials: number;
	GuestCredentials: number;
	InActiveUsedCredentials: number;
	ActiveUsedCredentials: number;
};

export type ControllerStatusViewerExt = {
	Status: string;
	ThreatLevel: string;
	EnclosureTamper: string;
	ACPower: string;
	UPSBattery: string;
	MemoryBattery: string;
};

export type ControllerStatusViewer = {
	ControllerID: number;
	ControllerName: string;
	Address: string;
	Enabled: number;
	CCMFirmware: string;
	CCMBIOS: string;
	SNIB2Firmware: string;
	SNIB3Firmware: string;
	SNIB3OS: string;
	SNIB3Driver: string;
} & ControllerStatusViewerExt;

export type PortStatusViewer = {
	PortID: number;
	PortName: string;
	Address: string;
	Enabled: number;
	Status: string;
};

export type XboxStatusViewer = {
	XboxID: number;
	XboxName: string;
	XBoxAddress: string;
	ControllerName: string;
	ControllerAddress: string;
	Status: string;
	Enabled: number;
};

export type StatusDashboard = {
	BlankProfilePictureBase64: string;
	BlankProfileSignatureBase64: string;
	WidgetList: WidgetInfo[];
};

export type WidgetInfo = {
	id: string;
	autoRefresh: boolean;
	autoRefreshTimer: number;
};

export class AccessSummaryInfo {
	PhotoCallUps: PhotoCallUp[];
	HostTime: Date;
	TotalRecords: number;
	TotalPages: number;
	TotalAccessGranted: number;
	TotalAccessDenied: number;
}
export class AccessInfo {
	ReaderLocation: string;
	Allowed: boolean;
	CredentialId: number;
	FirstName: string;
	LastName: string;
	DateTime: Date;
	IsPerson: boolean;
	Address: string;
}

export class CredentialPersonSummary {
	Credential: CredentialDetail;
	Person: PersonDetailModel;
}

export class CredentialDetail {
	CredentialId: number;
	Description: string;
	IDF: number;
	CredentialFunctions: CredentialFunction[];
}

export class CredentialFunction {
	FunctionName: string;
	ZoneName: string;
}

export class PersonDetailModel {
	PersonId: number;
	FirstName: string;
	LastName: string;
	PhotoId: number;
	PhotoBase64: string;
}

export class UserModule {
	IP: string;
	Identity: string;
	LoggedInDate: string;
}

import { UpOutlined } from '@ant-design/icons';
import { Collapse, notification, Spin, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { deviceAdminApi } from '../../../api';
import { cannotViewAnyControllerTabs, getMergedColumns } from '../../../components/devicecontrol/DigiTrac/helpers';
import { ColumnsProps, getDefaultTablePaginationConfig, handleResponse, parseDateTimeString, ScrollType } from '../../../Helper';
import { SubPermissions, User } from '../../../model/AccountModel';
import { GridDataColumnItem, GridDataItem, PaginationSetting, ResponseObject } from '../../../model/CommonModel';
import {
	ActionDeviceTypes,
	ControllerContact,
	ControllerContactMappedData,
	CurrentDeviceControlObj,
	DeviceObjectType,
	OptionsDigiTrac,
	SendDeviceCommand,
} from '../../../model/DeviceAdminModel';
import {
	ControllerToEdit,
	ExpandedRowItem,
	PaginationTable,
	StatusGroupRow,
	StatusGroupSelected,
	StatusObject,
	StatusObjectEnum,
	StatusViewer,
	StatusViewerEnum,
	StatusViewerItem,
} from '../../../model/StatusViewerModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { setCurrentDevice } from '../../../store/common/actions';
import { selectCurrentDevice } from '../../../store/common/selectors';
import { deleteDeviceById } from '../../../store/deviceControl/actions';
import {
	loadStatusViewerByType,
	retrieveExpandedRowStatusGroup,
	retrieveExpandedRowStatusGroupAction,
	retrieveStatusViewerByPagination,
	retrieveStatusViewerMaskedOnly,
	selectedStatusGroupAction,
	setControllerToEdit,
	setLoadingStatusViewerTable,
	setSelectedStatusGroup,
} from '../../../store/statusViewer/actions';
import {
	selectControllerToEdit,
	selectIsLoadingStatusViewerTable,
	selectSelectedStatusGroup,
	selectStatusGroupDoorExpandedRow,
	selectStatusViewer,
} from '../../../store/statusViewer/selectors';
import { ModalConfirmationList } from '../../common';
import { EditableCell } from '../../common/EditableCell/EditableCell';
import { NotificationStatus } from '../../common/NotificationStatus/NotificationStatus';
import { WarningProtocolMessage } from '../../common/WarningProtocolMessage/WarningProtocolMessage';
import { DoorModal } from '../../devicecontrol/DigiTrac/DoorModal/DoorModal';
import { ExpansionRelayModal } from '../../devicecontrol/DigiTrac/ExpansionRelayModal/ExpansionRelayModal';
import { InputModal } from '../../devicecontrol/DigiTrac/InputModal/InputModal';
import { PortModal } from '../../devicecontrol/DigiTrac/PortModal/PortModal';
import { ReaderModal } from '../../devicecontrol/DigiTrac/ReaderModal/ReaderModal';
import { XboxModal } from '../../devicecontrol/DigiTrac/XboxModal/XboxModal';
import { buildColumnStatusGroup } from '../helpers';
import styles from './StatusViewerTable.module.scss';

// Props those being mapped from Store
interface StateProps {}

// Dispatchable methods (invoke our store's actions)
interface DispatchProps {}

// Normal properties for the component
interface OwnProps {}

const user: User = getUser();
const canViewGeneralTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewGeneralTab).allowed;
const canViewEntryReaderTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewEntryReaderTab).allowed;
const canViewExitReaderTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewExitReaderTab).allowed;
const canViewRelayTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewRelayTab).allowed;
const canViewInputTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewInputTab).allowed;
const canViewGeneral: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewGeneralTab).allowed;
const canViewLogic: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewLogicTab).allowed;
const canViewOptions: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewScramblePadOptionsTab).allowed;
const canViewCardReaderSetup: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewCardReaderSetup).allowed;

// combine them together
type Props = StateProps & DispatchProps & OwnProps;

type GridDataColumnItemCustom = {
	title: string;
	dataIndex: string;
	key: string;
	sorter: boolean;
} & GridDataColumnItem;

type StatusViewerDataSource = {
	objectId: number;
	statusObject: string;
	columns: GridDataColumnItemCustom[];
	data: GridDataItem[][];
	pagination: TablePaginationConfig;
	deviceObjectType: DeviceObjectType;
};

const pagination: TablePaginationConfig = getDefaultTablePaginationConfig();
const StatusViewerTable = (props: Props) => {
	const dispatch = useStoreDispatch();
	const { Panel } = Collapse;
	const [isExpanded, setIsExpanded] = useState(true);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [statusViewerDataSource, setStatusViewerDataSource] = useState<StatusViewerDataSource[]>([]);
	const statusViewer: StatusViewer = useStoreSelector<StatusViewer>(selectStatusViewer);
	const statusGroupDoorExpandedRow: ExpandedRowItem[] = useStoreSelector<ExpandedRowItem[]>(selectStatusGroupDoorExpandedRow);
	const selectedStatusGroup: StatusGroupSelected = useStoreSelector<StatusGroupSelected>(selectSelectedStatusGroup);
	const isLoading: boolean = useStoreSelector<boolean>(selectIsLoadingStatusViewerTable);
	const controllerToEdit: ControllerToEdit = useStoreSelector<ControllerToEdit>(selectControllerToEdit);
	const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);
	const initSelectRow: StatusGroupRow = {
		DeviceObjectType: DeviceObjectType.Unknown,
		Address: '',
		Key: '',
		Name: '',
		Id: 0,
		ObjectTypeId: 0,
		ControllerId: 0,
		CurrentPage: 0,
		IsModalOpen: false,
		HasResetEncryption: false,
	};
	const [selectRowClickRight, setSelectRowClickRight] = useState(initSelectRow);

	useEffect(() => {
		if (statusViewer) {
			const statusViewerDataSourceFormat = statusViewer.StatusViewerItems.map((m: StatusViewerItem) => {
				const statusObject = m.ObjectId === 0 ? 'Masked' : StatusObject[m.ObjectId].toString();
				const deviceObjectType = m.DeviceObjectType;
				return {
					objectId: m.ObjectId,
					statusObject: _(statusObject),
					columns: m.Columns.filter(f => !f.IsAuxColumn && f.Key !== 'Key').map(c => {
						return {
							...buildColumnStatusGroup(m.ObjectId, c.Key, m.Data.length > 0),
							title: c.Value,
							dataIndex: c.Key === 'Key' ? 'key' : c.Key,
							key: c.Key,
							sorter: true,
							render: value => {
								let text = value;
								if (c.IsDateFormat) {
									text = parseDateTimeString(value, true, value);
								}

								return <span>{text}</span>;
							},
						};
					}),
					data: m.Data.map(d => {
						let row = {};
						const address = d.find(x => x.Key === 'Address')?.Value;
						d.forEach(item => {
							row = {
								...row,
								DeviceObjectType: getDeviceObjectTypeFrom(m.Data, deviceObjectType, address),
								[item.Key === 'Key' ? 'key' : item.Key]: item.Key === 'Key' ? `${statusObject}-${item.Value}-${address}` : item.Value,
							} as ControllerContactMappedData;
						});
						return row;
					}),
					pagination: {
						...pagination,
						current: m.PaginationSetting.PageNumber <= 0 ? 1 : m.PaginationSetting.PageNumber,
						pageSize: m.PaginationSetting.PageSize,
						total: m.TotalItems,
					},
					deviceObjectType,
				} as StatusViewerDataSource;
			});
			setStatusViewerDataSource(statusViewerDataSourceFormat);
			if (isLoading) {
				dispatch(setLoadingStatusViewerTable(false));
			}
		}
	}, [JSON.stringify(statusViewer)]);

	const handleExpandRow = (expanded: boolean, record) => {
		const door = statusGroupDoorExpandedRow.find(f => f.Address === record.Address);
		let requestExpanded: ExpandedRowItem = {
			...door,
			isExpanded: expanded,
			isLoading: true,
		};
		if (expanded) {
			dispatch(retrieveExpandedRowStatusGroup(requestExpanded));
		} else {
			dispatch(retrieveExpandedRowStatusGroupAction({ ...requestExpanded, Granted: 0, Denied: 0, RunTimeFunction: null }));
		}
	};

	const onChangeSelection = record => {
		const keyData = record.key.split('-', 2);
		const objectTypeId = StatusObjectEnum[keyData[0]];
		const deviceObjectType = Number(record.DeviceObjectType) as DeviceObjectType;
		const selectedDeviceType = selectedStatusGroup.PaginationTables.find(f => f.ObjectTypeId === Number(objectTypeId));
		setSelectRowClickRight({
			DeviceObjectType: deviceObjectType,
			Address: record.Address,
			Key: record.key,
			Name: record.Name,
			Id: Number(keyData[1]),
			ObjectTypeId: Number(objectTypeId),
			ControllerId: Number(record.ControllerId),
			IsModalOpen: false,
			CurrentPage: selectedDeviceType.PaginationSetting.PageNumber <= 0 ? 1 : selectedDeviceType.PaginationSetting.PageNumber,
			HasResetEncryption: record.HasResetEncryption ? Boolean(record.HasResetEncryption) : false,
		});
	};

	const onDeleteDeviceRequest = async (id: number, deviceType: DeviceObjectType): Promise<void> => {
		let response: ResponseObject;
		switch (deviceType) {
			case DeviceObjectType.Xbox:
				response = await deviceAdminApi.deleteXbox(id);
				break;

			case DeviceObjectType.Port:
				response = await deviceAdminApi.deletePort(id);
				break;

			case DeviceObjectType.Controller:
				response = await deviceAdminApi.deleteController(id);
				break;
		}
		if (handleResponse(response)) {
			return;
		}
		saveCallBack();
		dispatch(deleteDeviceById(id, deviceType));
	};

	const handleDeleteOkConfirmation = async () => {
		await onDeleteDeviceRequest(selectRowClickRight.Id, selectRowClickRight.DeviceObjectType);
	};

	const runDeviceCommand = async (action: ActionDeviceTypes, controllerId: number, deviceId: number) => {
		const command: SendDeviceCommand = {
			type: selectRowClickRight.DeviceObjectType,
			action,
			controllerId,
			deviceId,
		};

		const response = await deviceAdminApi.sendDeviceCommand(command);
		handleResponse(response);
	};

	const onSendCommandRequest = async (action: ActionDeviceTypes): Promise<void> => {
		const isResetencry: boolean = action === ActionDeviceTypes.Resetencry;
		const deviceId: number = selectRowClickRight.Id;
		const controllerId: number = selectRowClickRight.ControllerId;
		if (isResetencry) {
			if (selectRowClickRight.HasResetEncryption) {
				WarningProtocolMessage({
					onConfirm: () => runDeviceCommand(action, controllerId, deviceId),
					onCancel: () => {},
					protocolData: { portId: deviceId, protocol: 0 },
				});
			}
		} else {
			await runDeviceCommand(action, controllerId, deviceId);
		}
	};

	const handleSendDeviceCommand = async ({ action }: OptionsDigiTrac): Promise<void> => {
		await onSendCommandRequest(action);
	};

	const handleActionScope = async (sender: OptionsDigiTrac) => {
		const key: string = sender.key;
		switch (key) {
			case 'delete':
				ModalConfirmationList({
					type: '',
					translationKey: 'AreYouSureYouWishToDelete',
					data: [{ Name: selectRowClickRight.Name }],
					onConfirm: () => handleDeleteOkConfirmation(),
				});
				return;

			case 'sendCommand':
				handleSendDeviceCommand(sender);
				return;
		}
		const deviceId = selectRowClickRight.Id;
		const address = selectRowClickRight.Address;

		switch (key) {
			case 'edit':
				let isValid = true;
				switch (selectRowClickRight.DeviceObjectType) {
					case DeviceObjectType.Door:
						const isDoorEntryReaderOnly = await deviceAdminApi.getIsDoorEntryReaderOnly(deviceId);
						NotificationStatus({
							responseData: isDoorEntryReaderOnly,
							notUseDefaultNotification: true,
							onSuccessCallback: () => {
								if (
									isDoorEntryReaderOnly.Entity &&
									canViewExitReaderTabDoor &&
									!canViewGeneralTabDoor &&
									!canViewEntryReaderTabDoor &&
									!canViewRelayTabDoor &&
									!canViewInputTabDoor
								) {
									isValid = false;
									notification['info']({
										message: isDoorEntryReaderOnly.ErrorMessage,
									});
								}
							},
						});

						break;
					case DeviceObjectType.Reader:
						if (!canViewGeneral && !canViewLogic && !canViewOptions && !canViewCardReaderSetup) {
							isValid = false;
							notification['info']({
								message: _('CanNotViewTabsMessage'),
							});
						}
						break;
					case DeviceObjectType.Controller:
						if (cannotViewAnyControllerTabs()) {
							isValid = false;
							notification['info']({
								message: _('CanNotViewTabsMessage'),
							});
						} else {
							const newControllerToEdit: ControllerToEdit = {
								id: deviceId,
								isModalOpen: !controllerToEdit.isModalOpen,
							};
							const params = new URLSearchParams(location.search);
							params.set('controllerId', deviceId.toString());
							dispatch(setControllerToEdit(newControllerToEdit));
							window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
						}
						break;
				}
				if (isValid) {
					const { IsModalOpen, DeviceObjectType } = selectRowClickRight;
					const newSelectedRow = { ...selectRowClickRight, IsModalOpen: !IsModalOpen };
					const newDevice = {
						...currentDevice,
						Id: deviceId,
						Address: address,
						IsModalOpen: !IsModalOpen,
						DeviceObjectType,
					} as CurrentDeviceControlObj;
					batch(() => {
						dispatch(setCurrentDevice(newDevice));
						setSelectRowClickRight(newSelectedRow);
					});
				}
				break;
		}
	};

	const handleTableChangePagination = (pagination, filters, sorter, extra) => {
		const objectId = StatusObjectEnum[extra.currentDataSource[0]?.key.split('-', 1)[0]];
		const paginationSetting: PaginationSetting = {
			PageNumber: pagination.current <= 0 ? 1 : pagination.current,
			PageSize: pagination.pageSize,
			SortField: sorter.field,
			SortDirection: sorter.order,
		};
		switch (selectedStatusGroup.StatusViewerType) {
			case StatusViewerEnum.ShowOnly: {
				dispatch(setLoadingStatusViewerTable(true));
				dispatch(loadStatusViewerByType(selectedStatusGroup, paginationSetting));
				break;
			}
			case StatusViewerEnum.StatusGroup: {
				dispatch(setLoadingStatusViewerTable(true));
				dispatch(retrieveStatusViewerByPagination(selectedStatusGroup.Id, Number(objectId), paginationSetting));
				dispatch(
					selectedStatusGroupAction({
						...selectedStatusGroup,
						PaginationTables: selectedStatusGroup.PaginationTables.map<PaginationTable>(m => {
							if (m.ObjectTypeId === Number(objectId)) {
								return { ...m, PaginationSetting: paginationSetting };
							}
							return m;
						}),
					})
				);
				break;
			}
			case StatusViewerEnum.MaskedGroup: {
				dispatch(setLoadingStatusViewerTable(true));
				dispatch(retrieveStatusViewerMaskedOnly(selectedStatusGroup, paginationSetting));
				break;
			}
		}
	};

	const handleOnChange = isExpanded => {
		setIsExpanded(isExpanded);
	};

	const saveCallBack = (callback?: () => void) => {
		switch (selectedStatusGroup.StatusViewerType) {
			case StatusViewerEnum.ShowOnly: {
				const selected: StatusGroupSelected = {
					Id: selectedStatusGroup.Id,
					Name: selectedStatusGroup.Name,
					IsStatusViewer: false,
					PaginationTables: selectedStatusGroup.PaginationTables,
					StatusViewerType: StatusViewerEnum.ShowOnly,
				};
				batch(() => {
					dispatch(loadStatusViewerByType(selected, selectedStatusGroup.PaginationTables[0].PaginationSetting));
					callback?.();
				});
				break;
			}
			case StatusViewerEnum.StatusGroup: {
				const selected: StatusGroupSelected = {
					Id: selectedStatusGroup.Id,
					Name: selectedStatusGroup.Name,
					IsStatusViewer: true,
					PaginationTables: selectedStatusGroup.PaginationTables,
					StatusViewerType: StatusViewerEnum.StatusGroup,
				};
				const ObjectTypeId = controllerToEdit && controllerToEdit?.id !== 0 ? StatusObjectEnum.Controller : selectRowClickRight.ObjectTypeId;
				const selectedDeviceType = selectedStatusGroup.PaginationTables.find(f => f.ObjectTypeId === ObjectTypeId);
				batch(() => {
					dispatch(retrieveStatusViewerByPagination(selectedStatusGroup.Id, ObjectTypeId, selectedDeviceType.PaginationSetting));
					dispatch(setSelectedStatusGroup(selected));
					callback?.();
				});
				break;
			}
		}
	};

	const handleCancelModal = (device: CurrentDeviceControlObj) => {
		setSelectRowClickRight({ ...selectRowClickRight, IsModalOpen: false });
	};

	const openModal = selectRowClickRight.IsModalOpen;

	const getDeviceObjectTypeFrom = (dataSource: GridDataItem[][], deviceObjectType: DeviceObjectType, address: string): DeviceObjectType => {
		if (deviceObjectType === DeviceObjectType.Input || deviceObjectType === DeviceObjectType.Relay) {
			let items: GridDataItem[] = [];
			dataSource.forEach(x => {
				if (x.findIndex(y => y.Value === address) >= 0) {
					items = x;
				}
			});

			const item = items.find(x => x.Key === 'DeviceObjectType');

			if (item) {
				const deviceObjectNumber = Number(item.Value);
				deviceObjectType = deviceObjectNumber as DeviceObjectType;
				return deviceObjectType;
			}
		}
		return deviceObjectType;
	};

	return (
		<>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
				{statusViewerDataSource.map(m => {
					const maxLength = 32;
					const columns = m.columns as ColumnsProps<ControllerContact>[];

					const updatedColumns = getMergedColumns({
						columns,
						maxLength,
						isStatusViewer: true,
						onChangeSelection,
						onClickOption: handleActionScope,
					});

					const scroll: ScrollType = { x: `${500 + m.columns.length * 150}px`, y: `${m.data.length === 1 ? 256 : 550}px` };

					return (
						<div key={`tableContainer-${m.statusObject}-${statusViewerDataSource.indexOf(m)}`} className={styles.collapseTableContainer}>
							<Collapse
								key={`collapse-${m.statusObject}-${statusViewerDataSource.indexOf(m)}`}
								bordered={true}
								defaultActiveKey={`${m.statusObject}-${statusViewerDataSource.indexOf(m)}`}
								expandIconPosition='right'
								expandIcon={({ isActive }) => (
									<UpOutlined key={`upOutLined-${m.statusObject}-${statusViewerDataSource.indexOf(m)}`} rotate={isActive ? 180 : 0} />
								)}
								className={styles.statusWidgetCollapse}
								onChange={(keys: string[]) => {
									handleOnChange(keys.length === 1);
								}}>
								<Panel
									id={`panel-${m.statusObject}-${statusViewerDataSource.indexOf(m)}`}
									header={_(m.statusObject)}
									key={`${m.statusObject}-${statusViewerDataSource.indexOf(m)}`}
									className={styles.statusWidget}>
									<Table
										key={`table-panel-${m.statusObject}-${statusViewerDataSource.indexOf(m)}`}
										id={`table-panel-${m.statusObject}-${statusViewerDataSource.indexOf(m)}`}
										columns={updatedColumns}
										components={
											m.objectId !== StatusObjectEnum.MaskedOnly && {
												body: {
													cell: EditableCell,
												},
											}
										}
										dataSource={m.data}
										scroll={scroll}
										size='small'
										pagination={m.pagination}
										onChange={handleTableChangePagination}
										className={styles.statusViewerTable}
										expandable={
											m.objectId === StatusObjectEnum.Door
												? {
														onExpand: handleExpandRow,
														expandedRowRender: record => {
															const expandedRow = statusGroupDoorExpandedRow.find(f => f.Address === record.Address);
															const granted = (
																<label className={styles.statusWidgetDoorStatusGranted}>{expandedRow?.Granted ?? 0}</label>
															);
															const denied = (
																<label className={styles.statusWidgetDoorStatusDenied}>{expandedRow?.Denied ?? 0}</label>
															);
															return (
																<Spin
																	tip={`${_('Loading')}...`}
																	spinning={expandedRow?.isLoading}
																	size='default'
																	className={styles.spinContainerStatus}>
																	<div className={styles.statusWidgetDoorStatusNested}>
																		{_('TodayAccessGrants')}: {granted} {_('TodayAccessDenied')}: {denied}
																	</div>
																</Spin>
															);
														},
														rowExpandable: record => true,
												  }
												: null
										}
										rowClassName={(record, index) => {
											if (index % 2 !== 0) {
												return styles.evenRow;
											}
										}}
									/>
								</Panel>
							</Collapse>
						</div>
					);
				})}
			</Spin>
			{openModal && selectRowClickRight.DeviceObjectType === DeviceObjectType.Port && (
				<PortModal
					id={selectRowClickRight.Id}
					address={selectRowClickRight.Address}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					cancelHandle={handleCancelModal}
					saveCallBack={saveCallBack}
				/>
			)}
			{openModal && selectRowClickRight.DeviceObjectType === DeviceObjectType.Xbox && (
				<XboxModal
					xboxToBeEditable={{
						Id: selectRowClickRight.Id,
						Address: selectRowClickRight.Address,
						DeviceObjectType: selectRowClickRight.DeviceObjectType,
						ControllerContact: selectRowClickRight.ControllerId,
						IsModalOpen: selectRowClickRight.IsModalOpen,
						CurrentPage: selectRowClickRight.CurrentPage,
					}}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					cancelHandle={handleCancelModal}
					saveCallBack={saveCallBack}
				/>
			)}
			{openModal &&
				(selectRowClickRight.DeviceObjectType === DeviceObjectType.Input ||
					selectRowClickRight.DeviceObjectType === DeviceObjectType.ExpansionInputs) && (
					<InputModal
						currentDeviceObj={{
							Id: selectRowClickRight.Id,
							Address: selectRowClickRight.Address,
							DeviceObjectType: selectRowClickRight.DeviceObjectType,
							ControllerContact: selectRowClickRight.ControllerId,
							IsModalOpen: selectRowClickRight.IsModalOpen,
							CurrentPage: selectRowClickRight.CurrentPage,
						}}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						cancelHandle={handleCancelModal}
						saveCallBack={saveCallBack}
					/>
				)}
			{openModal &&
				(selectRowClickRight.DeviceObjectType === DeviceObjectType.Relay ||
					selectRowClickRight.DeviceObjectType === DeviceObjectType.ExpansionRelays) && (
					<ExpansionRelayModal
						currentDeviceObj={{
							Id: selectRowClickRight.Id,
							Address: selectRowClickRight.Address,
							DeviceObjectType: selectRowClickRight.DeviceObjectType,
							ControllerContact: selectRowClickRight.ControllerId,
							IsModalOpen: selectRowClickRight.IsModalOpen,
							CurrentPage: selectRowClickRight.CurrentPage,
						}}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						cancelHandle={handleCancelModal}
						saveCallBack={saveCallBack}
					/>
				)}
			{openModal && selectRowClickRight.DeviceObjectType === DeviceObjectType.Reader && (
				<ReaderModal
					id={selectRowClickRight.Id}
					address={selectRowClickRight.Address}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					cancelHandle={handleCancelModal}
					saveCallBack={saveCallBack}
				/>
			)}
			{openModal && selectRowClickRight.DeviceObjectType === DeviceObjectType.Door && (
				<DoorModal
					currentDevice={{
						Id: selectRowClickRight.Id,
						Address: selectRowClickRight.Address,
						DeviceObjectType: selectRowClickRight.DeviceObjectType,
						ControllerContact: selectRowClickRight.ControllerId,
						IsModalOpen: selectRowClickRight.IsModalOpen,
						CurrentPage: selectRowClickRight.CurrentPage,
					}}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					cancelHandle={handleCancelModal}
					saveCallBack={saveCallBack}
				/>
			)}
		</>
	);
};

export { StatusViewerTable };

import { DragOutlined } from '@ant-design/icons';
import { Button, notification, Table } from 'antd';
import arrayMove from 'array-move';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { GridColumn } from '../../../model/CommonModel';
import { StatusGroupNotificationStatus, StatusObject, StatusObjectEnum } from '../../../model/StatusViewerModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { RootReducer } from '../../../store/rootReducer';
import {
	loadStatusViewerColumnConfigurationAction,
	saveStatusViewerColumnConfiguration,
	updateStatusViewerColumnConfigurationState,
} from '../../../store/statusViewer/actions';
import { selectSelectedStatusGroup } from '../../../store/statusViewer/selectors';
import { Modal, Select, SelectOption } from '../../common';
import styles from './statusViewerModalColumns.module.scss';

type DragHandleProps = { order: number };

type Props = {
	onSetVisible: () => void;
	statusGroupFilter: SelectOption;
};

//Avoid creating object style inline, since increases reconciliations
const DragHandle = SortableHandle((props: DragHandleProps) => <DragOutlined id={`sortHandler-${props.order}`} style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableItemContainer = SortableContainer(props => <tbody {...props} />);

type DataSourceLocal = {
	KeyObject: string;
	DataSource: GridColumn[];
};

const StatusViewerModalColumns: React.FC<Props> = ({ onSetVisible, statusGroupFilter }) => {
	const dispatch = useStoreDispatch();
	const [hasError, setHasError] = useState(false);
	const [dataSourceTable, setDataSourceTable] = useState([]);
	const [optionShowOnlySelection, setOptionShowOnlySelection] = useState('-1');
	const [rowKeysSelected, setRowKeysSelected] = useState([]);
	const [statusViewerDataSourceLocal, setStatusViewerDataSource] = useState<DataSourceLocal[]>([]);
	const statusViewerColumnConfigurationStore: Record<StatusObjectEnum, GridColumn[]> = useStoreSelector(
		(state: RootReducer): Record<StatusObjectEnum, GridColumn[]> => state.statusViewerReducer.statusViewerColumnConfiguration
	);
	const selectedStatusGroup = useStoreSelector(selectSelectedStatusGroup);
	useEffect(() => {
		dispatch(loadStatusViewerColumnConfigurationAction());
	}, []);

	useEffect(() => {
		if (statusViewerColumnConfigurationStore) {
			const statusViewerColumnConfigurationLocal: DataSourceLocal[] = Object.keys(statusViewerColumnConfigurationStore).map(m => {
				return {
					KeyObject: m,
					DataSource: [...statusViewerColumnConfigurationStore[m]],
				};
			});
			setStatusViewerDataSource(statusViewerColumnConfigurationLocal);
			if (optionShowOnlySelection !== '-1') {
				updateDataSourceTableAndSelectedRowKeys(optionShowOnlySelection, statusViewerColumnConfigurationLocal);
			}
		}
	}, [statusViewerColumnConfigurationStore]);

	const updateDataSourceTableAndSelectedRowKeys = (optionShowOnlySelection: string, statusViewerColumnConfiguration: DataSourceLocal[]) => {
		const dataSource = statusViewerColumnConfiguration.find(f => f.KeyObject === StatusObject[Number(optionShowOnlySelection)]).DataSource;
		const dataTable = dataSource
			.filter(x => !x.IsAuxColumn)
			.map(m => ({
				...m,
				index: dataSource.indexOf(m),
			}));
		setDataSourceTable(dataTable);
		setRowKeysSelected(dataTable.filter(f => f.Visible === true).map(m => m.index));
	};

	const onSelectChangeCheckBox = (selectedRowKeys: string[]) => {
		let updateData: Record<StatusObjectEnum, GridColumn[]> = { ...statusViewerColumnConfigurationStore };
		const updateItem: GridColumn[] = dataSourceTable.map<GridColumn>(m => {
			const found = selectedRowKeys.find(f => f === m.index);
			return {
				...m,
				Visible: found !== undefined ? true : false,
			};
		});
		updateData[StatusObject[Number(optionShowOnlySelection)]] = updateItem;
		setHasError(selectedRowKeys.length > 0 ? false : true);
		setRowKeysSelected(selectedRowKeys);
		dispatch(updateStatusViewerColumnConfigurationState(updateData));
	};

	const rowSelection = {
		selectedRowKeys: rowKeysSelected,
		onChange: onSelectChangeCheckBox,
		selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
		getCheckboxProps: record => ({ id: `statusViewerConfigureColumns-${record.Header}` }),
	};

	const columns = [
		{
			title: _('Column'),
			dataIndex: 'Header',
			className: styles.dragVisible,
		},
		{
			title: _('Order'),
			dataIndex: 'sort',
			width: 55,
			className: styles.dragVisible,
			render: (value, row) => <DragHandle order={row?.Order} />,
		},
	];

	const handleOnSave = async () => {
		const notificationStatusConfig: StatusGroupNotificationStatus = {
			statusGroupSelect: selectedStatusGroup,
			onSetVisible: onSetVisible,
			permissionErrorCallback: errorMessage => {
				notification['error']({
					message: errorMessage,
				});
			},
			onHandleCloseModal: handleCloseModal,
		};
		dispatch(saveStatusViewerColumnConfiguration(statusViewerColumnConfigurationStore, notificationStatusConfig));
	};

	const onHandleFilterChange = (value: string) => {
		setOptionShowOnlySelection(value);
		updateDataSourceTableAndSelectedRowKeys(value, statusViewerDataSourceLocal);
	};

	const handleCloseModal = () => {
		onSetVisible();
	};
	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove([].concat(dataSourceTable), oldIndex, newIndex).filter(el => !!el);
			let updateData: Record<StatusObjectEnum, GridColumn[]> = { ...statusViewerColumnConfigurationStore };
			const updateItem: GridColumn[] = newData.map<GridColumn>(m => {
				return {
					...m,
					Order: newData.indexOf(m) + 1,
				};
			});
			updateData[StatusObject[Number(optionShowOnlySelection)]] = updateItem;
			setDataSourceTable(newData);
			dispatch(updateStatusViewerColumnConfigurationState(updateData));
		}
	};

	const draggableContainer = props => (
		<SortableItemContainer useDragHandle disableAutoscroll helperClass={styles.rowDragging} onSortEnd={onSortEnd} {...props} />
	);

	const draggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = dataSourceTable.findIndex(x => x.index === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};

	const statusGroupObjects: SelectOption = statusGroupFilter.map(x => ({ ...x, id: `${x.id}-ColumnConfigurationOption` }));
	const statusViewerObjectSelectId = 'statusViewerObjectSelect';
	const statusViewerColumnsTableId = 'statusViewerColumnsTable';

	return (
		<Modal
			footer={[
				<Button id='statusViewerColumnsSaveButton' key='save' type='primary' disabled={hasError} onClick={() => handleOnSave()}>
					{_('SaveChanges')}
				</Button>,
				<Button id='statusViewerColumnsCancelButton' key='cancel' onClick={() => handleCloseModal()}>
					{_('Cancel')}
				</Button>,
			]}
			width='400px'
			visible={true}
			title={_('ConfigureColumnHeaders')}
			onCancel={() => handleCloseModal()}
			onClickOk={() => null}>
			<div id='statusViewerColumnsContainer' className={styles.container}>
				<div>
					<label htmlFor={statusViewerObjectSelectId}>{_('Object')}</label>
					<Select
						id={statusViewerObjectSelectId}
						options={statusGroupObjects}
						defaultValue={optionShowOnlySelection}
						onChange={onHandleFilterChange}
						className={styles.selectOptionStatusViewer}
						listHeight={320}
						disabled={hasError}
					/>
				</div>
				<div>{_('ConfigureColumnInstructions')}</div>
				<div className={cx({ [styles.error]: hasError })}>
					<Table
						id={statusViewerColumnsTableId}
						className={styles.tableColumnsOptions}
						pagination={false}
						dataSource={dataSourceTable}
						columns={columns}
						rowKey='index'
						size='small'
						rowSelection={rowSelection}
						components={{
							body: {
								wrapper: draggableContainer,
								row: draggableBodyRow,
							},
						}}
						scroll={{ y: 465 }}
					/>
				</div>
				{hasError && (
					<label htmlFor={statusViewerColumnsTableId} className={styles.error}>
						{_('AtLeastOneItem')}
					</label>
				)}
			</div>
		</Modal>
	);
};

export { StatusViewerModalColumns };

import { CloseCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import styles from './modalError.module.scss';

type Props<T> = {
	onOk?: (value: T) => void;
	centered?: boolean;
	content: React.ReactNode;
	okText?: string;
	width?: string;
	icon?: React.ReactElement;
};

const ModalError = <T extends object | string | number>({ centered, content, onOk, okText, width }: Props<T>) => {
	return Modal.error({
		keyboard: false,
		maskClosable: false,
		width,
		content,
		onOk: onOk,
		okText: okText || 'Yes',
		icon: <CloseCircleOutlined className={styles.icon} />,
		className: styles.body,
		okButtonProps: { className: styles.okButton },
		centered: centered ? centered : false,
	});
};

export { ModalError };

import { UpOutlined } from '@ant-design/icons';
import { Collapse, Select } from 'antd';
import React from 'react';
import { Logger } from '../../../model/LoggingModel';
import { WidgetInfo } from '../../../model/StatusModel';
import { ControllerStatus } from '../ControllerStatus/ControllerStatus';
import { PortStatus } from '../PortStatus/PortStatus';
import { XboxStatus } from '../XboxStatus/XboxStatus';
import './statusViewer.scss';

const { Panel } = Collapse;
const { Option } = Select;

// Internal state for the component
interface State {
	isExpanded: boolean;
	selection: string;
}

// Normal properties for the component
interface OwnProps {
	widgetInfo: WidgetInfo;
}

// combine them together
type Props = OwnProps;

class StatusViewer extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isExpanded: true,
			selection: '1',
		};
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	handleOnChange(isExpanded) {
		this.setState({
			isExpanded,
		});
	}

	handleChange(value) {
		this.setState({
			selection: value,
		});
	}

	render() {
		const { isExpanded, selection } = this.state;
		const { widgetInfo } = this.props;

		let content;
		switch (selection) {
			case '0':
				content = (
					<>
						<ControllerStatus isAll isExpanded={isExpanded} widgetInfo={widgetInfo} />
						<br />
						<PortStatus isAll isExpanded={isExpanded} widgetInfo={widgetInfo} />
						<br />
						<XboxStatus isAll isExpanded={isExpanded} widgetInfo={widgetInfo} />
					</>
				);
				break;

			case '1':
				content = <ControllerStatus isExpanded={isExpanded} widgetInfo={widgetInfo} />;
				break;

			case '2':
				content = <PortStatus isExpanded={isExpanded} widgetInfo={widgetInfo} />;
				break;

			case '3':
				content = <XboxStatus isExpanded={isExpanded} widgetInfo={widgetInfo} />;
				break;
		}

		return (
			<div className='status-viewer-container'>
				<Collapse
					bordered={true}
					defaultActiveKey={'1'}
					expandIconPosition={'right'}
					expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
					onChange={(keys: string[]) => {
						this.handleOnChange(keys.length === 1);
					}}
					className={'status-widget-collapse'}>
					<Panel id='statusDashboardStatusViewerWidget' header={_('StatusViewer')} key='1' className='status-widget status-widget-status-viewer'>
						<Select
							id='statusDashboardStatusViewerDeviceDropdown'
							defaultValue='1'
							className='status-select'
							onChange={value => this.handleChange(value)}>
							<Option id='statusDashboardStatusViewerDeviceAllOption' value='0'>
								{_('All')}
							</Option>
							<Option id='statusDashboardStatusViewerDeviceControllersOption' value='1'>
								{_('Controllers')}
							</Option>
							<Option id='statusDashboardStatusViewerDevicePortsOption' value='2'>
								{_('Ports')}
							</Option>
							<Option id='statusDashboardStatusViewerDeviceXboxesOption' value='3'>
								{_('Xboxes')}
							</Option>
						</Select>
						{isExpanded && content}
					</Panel>
				</Collapse>
			</div>
		);
	}
}

export { StatusViewer };

import cx from 'classnames';
import React from 'react';
import { LockStatusDoorGroupViewModel } from '../../../../../../model/DeviceAdminModel';
import styles from './doorGroupLockStatus.module.scss';

type Props = {
	conflictModel: LockStatusDoorGroupViewModel;
	errorDescription?: string;
	isDoorLocks?: boolean;
};

const DoorGroupLockStatus: React.FC<Props> = ({ conflictModel, errorDescription, isDoorLocks }) => {
	const {
		LockStatusWarning: { DoorGroupCredentialsLocked, DoorGroupFGsLocked, ErrorMessage },
	} = conflictModel;

	return (
		<div className={styles.container}>
			<p>{errorDescription ? errorDescription : ErrorMessage ? ErrorMessage : _('UnableObtainFollowingLocks')}</p>
			{DoorGroupCredentialsLocked.length > 0 && (
				<div className={cx(styles.gridBase, { [styles.controllersForDoors]: isDoorLocks === true }, { [styles.controllers]: isDoorLocks !== true })}>
					<div>{_('OperatorName')}</div>
					<div>{_('WorkStationName')}</div>
					<div>{_('CredentialID')}</div>
					{isDoorLocks !== true && <div>{_('IsTemplate')}</div>}
					{DoorGroupCredentialsLocked.map((data, index) => (
						<React.Fragment key={index}>
							<div>{data.OperatorName}</div>
							<div>{data.WorkstationName}</div>
							<div>{data.CredentialID}</div>
							{isDoorLocks !== true && <div>{`${data.IsTemplate}`}</div>}
						</React.Fragment>
					))}
				</div>
			)}
			{DoorGroupFGsLocked.length > 0 && (
				<div className={cx(styles.gridBase, styles.groupControllers)}>
					<div>{_('OperatorName')}</div>
					<div>{_('WorkStationName')}</div>
					<div>{_('FunctionGroupId')}</div>
					{DoorGroupFGsLocked.map((data, index) => (
						<React.Fragment key={index}>
							<div>{data.OperatorName}</div>
							<div>{data.WorkstationName}</div>
							<div>{data.FunctionGroupId}</div>
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	);
};

export { DoorGroupLockStatus };

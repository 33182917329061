import React from 'react';
import { AddOperatorNoteModal, AlarmInstructionsModal, AlarmShowNotesModal } from '../../components/alarms';
import { AlarmTable } from '../../components/alarms/AlarmsTable/AlarmTable';
import { HeaderBar } from '../../components/common';
import { EventTable } from '../../components/event';
import { User } from '../../model/AccountModel';
import { useStoreDispatch, useStoreSelector } from '../../store';
import { setShowAlarmInstructions, setShowAlarmNotes } from '../../store/alarm/actions';
import { selectShowAddOperatorNote, selectShowAlarmInstructions, selectShowAlarmNotes } from '../../store/alarm/selectors';
import styles from './alarms.module.scss';

const user: User = getUser();
const { showEventsOnAlarmsPage } = user;

const AlarmsPage: React.FC = () => {
	const dispatch = useStoreDispatch();
	const showAlarmInstructionsModal: boolean = useStoreSelector<boolean>(selectShowAlarmInstructions);
	const showAlarmNotesModal: boolean = useStoreSelector<boolean>(selectShowAlarmNotes);
	const showAlarmAddOperatorNoteModal: boolean = useStoreSelector<boolean>(selectShowAddOperatorNote);

	return (
		<div id='alarmsPageContainer'>
			<>
				<HeaderBar title={_('AlarmViewer')} />
				<div className={styles.alarmPageContainer}>
					<AlarmTable isAcknowledgedAlarmTable={false} />
					<AlarmTable isAcknowledgedAlarmTable={true} />
				</div>
			</>
			{showAlarmInstructionsModal && <AlarmInstructionsModal onHideModal={() => dispatch(setShowAlarmInstructions(undefined))} />}
			{showAlarmNotesModal && <AlarmShowNotesModal onHideModal={() => dispatch(setShowAlarmNotes(undefined))} />}
			{showAlarmAddOperatorNoteModal && <AddOperatorNoteModal />}
			{showEventsOnAlarmsPage && <EventTable />}
		</div>
	);
};

export { AlarmsPage };

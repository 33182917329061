import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../../api';
import { handleResponse } from '../../../../../../../../Helper';
import { SecuredComponents } from '../../../../../../../../model/AccountModel';
import { DeviceObjectType, SNIBFirmwareConfiguration } from '../../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../../store';
import { setCurrentDevice } from '../../../../../../../../store/common/actions';
import { selectControllerId, selectSupportsSNIB3 } from '../../../../../../../../store/deviceControl/controller/selectors';
import { InformationMessages, Modal, Select } from '../../../../../../../common';
import styles from '../../general.module.scss';

type Props = {
	onCancel: () => void;
	callbackRedirect?: () => void;
};

const styleCombo: React.CSSProperties = { width: 300, marginBottom: 40 };

const SNIB3Update: React.FC<Props> = ({ onCancel, callbackRedirect }) => {
	const supportsSNIB3: boolean = useStoreSelector<boolean>(selectSupportsSNIB3);
	const controllerId: number = useStoreSelector<number>(selectControllerId);
	const [data, setData] = useState<SNIBFirmwareConfiguration>(null);
	const [firmwareId, setFirmwareId] = useState<number>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const dispatch = useStoreDispatch();

	useEffect(() => {
		deviceAdminApi.retrieveSNIBInfoFirmware(supportsSNIB3).then(res => {
			createData(res.Entity);
			setFirmwareId(res.Entity.SNIBFirmwareOptions[0]?.FirmwareID ?? 0);
		});
	}, []);

	const createData = (snibFirmwareConfiguration: SNIBFirmwareConfiguration) => {
		const cloneSNIBFirmwareConfig = { ...snibFirmwareConfiguration };
		cloneSNIBFirmwareConfig.Labels = [...snibFirmwareConfiguration.Labels, _('DownloadButtonRedirectDeviceControlWarning')];
		setData(cloneSNIBFirmwareConfig);
	};

	const handleUpdateFirmware = () => {
		setIsLoading(true);
		deviceAdminApi.updateSNIBFirmwareController(controllerId, firmwareId, supportsSNIB3).then(res => {
			setIsLoading(false);
			if (!handleResponse(res)) {
				onCancel();
				deviceAdminApi.unlockComponentDeviceAdmin(controllerId, SecuredComponents.Controller).finally(() => {
					window.history.replaceState({}, '', `${location.pathname}`);
					const newDevice = { Id: controllerId, DeviceObjectType: DeviceObjectType.Controller, IsModalOpen: false };
					dispatch(setCurrentDevice(newDevice));
					callbackRedirect?.();
				});
			}
		});
	};

	const options =
		data && data.SNIBFirmwareOptions.length > 0
			? data.SNIBFirmwareOptions.map(x =>
					supportsSNIB3
						? { label: `${x.Description} (${x.Version})`, value: x.FirmwareID }
						: { label: `${x.RevisionNum} (${FormatDate(x.RevisionDate, false, undefined, true, false)})`, value: x.FirmwareID }
			  )
			: [{ label: _('NoFirmwareBinaries'), value: 0 }];

	return (
		<>
			{data && (
				<Modal
					visible={true}
					title={data?.Caption}
					onClickOk={() => null}
					onCancel={onCancel}
					customZoomClass={styles.snibFirmwareUpdateModalZoom}
					footer={[
						<Button key='ok' disabled={data.SNIBFirmwareOptions.length === 0} type='primary' onClick={handleUpdateFirmware} loading={isLoading}>
							{_('Download')}
						</Button>,
						<Button key='cancel' onClick={onCancel}>
							{_('Cancel')}
						</Button>,
					]}>
					<label>{_('SelectFirmwareVersionToUpdate')}</label>
					<Select
						style={styleCombo}
						notUseCurrentParse
						options={options}
						value={firmwareId}
						onChange={value => setFirmwareId(Number(value))}
						disabled={data.SNIBFirmwareOptions.length === 0}
					/>
					<InformationMessages messages={data.Labels} />
				</Modal>
			)}
		</>
	);
};

export { SNIB3Update };

import React from 'react';
import combineReducers from 'react-combine-reducers';
import { expansionRelayContext, ExpansionRelayContextState, expansionRelayState } from '../ExpansionRelayModal/contextExpansionRelay';
import { inputContextReducers, inputContextState, ReaderState } from '../InputModal/inputModalContext';
import { readerContext, ReaderContextState, readerState } from '../ReaderModal/contextReader';
import { exitReaderContext, ExitReaderContextState, exitReaderState } from '../ReaderModal/ExitReaderModal/exitReaderContext';
import { doorContextReducers, doorContextState, DoorState } from './DoorModalContext';

export type DoorStore = {
	door: Readonly<DoorState>;
	input: Readonly<ReaderState>;
	relay: Readonly<ExpansionRelayContextState>;
	entryReader: Readonly<ReaderContextState>;
	exitReader: Readonly<ExitReaderContextState>;
};

export type Action = {
	type: string;
	payload: any;
};

type DoorReducer = (state: DoorStore, action: Action) => DoorStore;

const [doorReducer, contextStateDoor] = combineReducers<DoorReducer>({
	door: [doorContextReducers, doorContextState],
	input: [inputContextReducers, inputContextState],
	relay: [expansionRelayContext, expansionRelayState],
	entryReader: [readerContext, readerState],
	exitReader: [exitReaderContext, exitReaderState],
});

type DoorContext = {
	contextStateDoor: Readonly<DoorStore>;
	dispatcherDoor: React.Dispatch<Action>;
};

const DoorStoreSingleContext = React.createContext<DoorContext>({ contextStateDoor, dispatcherDoor: null });

export { contextStateDoor, doorReducer, DoorStoreSingleContext };

import { InputNumber, Select } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../Helper';
import { ConfigurationState, ProfileSetting, SettingType, SettingsValue } from '../../../model/ConfigurationModel';
import { InputNumberHelper } from '../Common/InputNumberHelper';
import styles from './generalSettings.module.scss';

const { Option } = Select;

// Normal properties for the component
interface Props {
	configuration: ConfigurationState;
	isAdmin: boolean;
	onSetDateFormat: (cultureCode: string) => void;
	onSetSettingValue: (value: SettingsValue<string>) => void;
	onSetViewSettingValue: (value: SettingsValue<string>) => void;
	onSetSettingsHaveChanged: (value: boolean) => void;
}

const minValue: number = 1,
	maxValue: number = 5,
	minSessionTimeoutValue: number = 1;

const GeneralSettings: React.FC<Props> = ({ configuration, isAdmin, onSetDateFormat, onSetSettingValue, onSetViewSettingValue, onSetSettingsHaveChanged }) => {
	const sessionTimeoutSetting: ProfileSetting = configuration.ViewSettings.find(r => r.Name === 'SessionTimeout');

	const handleSettingChange = (value: string, key: string) => {
		if (isNaN(parseInt(value)) && key !== 'SelectedSupportedLanguage') {
			return;
		}
		const setValues: SettingsValue<string> = {
			key,
			value,
		};
		saveLocalChanges(key, setValues);
	};

	const handleAppSettingChange = (value: string, key: string, type: SettingType) => {
		if (!Number(value)) {
			return;
		}
		const setValues: SettingsValue<string> = {
			key,
			value,
			type,
		};
		saveLocalChanges(key, setValues);
	};

	const saveLocalChanges = (key: string, setValues: SettingsValue<string>) => {
		if (key !== 'SessionTimeout') {
			onSetSettingValue(setValues);
		} else {
			onSetViewSettingValue(setValues);
		}
		if (!configuration.HaveSettingsChanged) {
			onSetSettingsHaveChanged(true);
		}
	};

	let defaultSelectedLandingPage: string = undefined;
	let selectedLandingPage = configuration.LandingPageOptions.find(x => x.Item1.toString() === configuration.SelectedLandingPage);
	if (!selectedLandingPage && configuration.LandingPageOptions.length) {
		selectedLandingPage = configuration.LandingPageOptions[0];
	}

	if (selectedLandingPage) {
		defaultSelectedLandingPage = selectedLandingPage.Item1.toString();
	}

	const dateFormat = configuration.CultureDateFormat;
	const landingPageOptions = (
		<Select
			aria-label={_('LandingPage')}
			aria-owns='LandingPage'
			aria-activedescendant='LandingPage'
			aria-controls='LandingPage'
			aria-expanded='true'
			defaultValue={defaultSelectedLandingPage}
			className={styles.selectFormatting}
			id='LandingPage'
			onChange={value => handleSettingChange(value, 'SelectedLandingPage')}>
			{configuration.LandingPageOptions.map(o => (
				<Option key={o.Item1} value={o.Item1.toString()}>
					{o.Item2}
				</Option>
			))}
		</Select>
	);

	const supportedLanguageOptions = (
		<Select
			aria-label={_('LanguageOptions')}
			aria-owns='LanguageCultureCode'
			aria-activedescendant='LanguageCultureCode'
			aria-controls='LanguageCultureCode'
			aria-expanded='true'
			defaultValue={configuration.SelectedSupportedLanguage}
			id='LanguageCultureCode'
			onChange={value => {
				onSetDateFormat(value);
				handleSettingChange(value, 'SelectedSupportedLanguage');
			}}>
			{configuration.SupportedLanguages.map(o => (
				<Option key={o.DisplayName} value={o.IsoName}>
					{o.DisplayName}
				</Option>
			))}
		</Select>
	);

	const badgePrintingMethod = configuration.BadgePrintingMethod;
	const printingMethodValue = Number(badgePrintingMethod.Value);

	return (
		<div className={styles.container} id='ConfigTabData_0'>
			<div className={styles.landingPage}>
				<div>
					<label htmlFor='LandingPage'>{_('LandingPage')}</label>
				</div>
				<div>{landingPageOptions}</div>
				<div>
					<label htmlFor='LanguageCultureCode'>{_('Region')}</label>
				</div>
				<div>
					{supportedLanguageOptions}
					<div className={styles.formatDate}>
						<label htmlFor='FormatDate'>
							<span id='FormatDate'>{dateFormat}</span>
						</label>
					</div>
				</div>
			</div>
			{isAdmin && (
				<div className={styles.landingPage}>
					<div>
						<label htmlFor='BadgePrintingMethod'>*{badgePrintingMethod.Label}</label>
					</div>
					<div className={styles.badgePrintingMethodContainer}>
						<InputNumber
							id='BadgePrintingMethod'
							min={minValue}
							max={maxValue}
							maxLength={2}
							value={printingMethodValue}
							formatter={noneDecimalFormatter}
							onChange={value => {
								if (!value || value === undefined || parseInt(value.toString()) < minValue) {
									value = minValue;
								}
								handleAppSettingChange(value.toString(), 'Value', 'BadgePrintingMethod');
							}}
						/>
						<label className={styles.rangeLabel}>({_('Range')}: 1-5)</label>
					</div>
				</div>
			)}
			<div className={styles.globalSessionTimeout}>
				<label htmlFor='SessionTimeout'>{_('SessionTimeout')}</label>
				<label>{_('Allowed')}</label>
				<InputNumberHelper
					profileSetting={sessionTimeoutSetting}
					min={minSessionTimeoutValue}
					max={240}
					maxLength={3}
					id='SessionTimeout'
					onChange={value => {
						if (!value || value === undefined || (value as number) < minSessionTimeoutValue) {
							value = minSessionTimeoutValue;
						}
						handleSettingChange(value.toString(), sessionTimeoutSetting.Name);
					}}
				/>
			</div>
		</div>
	);
};

export { GeneralSettings };

import { DragOutlined } from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import arrayMove from 'array-move';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { configurationApi } from '../../../api';
import { handleResponse, ScrollType } from '../../../Helper';
import { SecuredComponents } from '../../../model/AccountModel';
import { GridColumn, ResponseStatusCode } from '../../../model/CommonModel';
import { ColumnConfiguration } from '../../../model/ConfigurationModel';
import { Modal } from '../../common';
import styles from './columnConfiguration.module.scss';

const scroll: ScrollType = { x: '350px', y: '550px' };
const DragHandle = SortableHandle(() => <DragOutlined className={styles.rowDraggingIcon} />);
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableItemContainer = SortableContainer(props => <tbody {...props} />);
const unsupportedColumnKey: string = 'DVRVideo';
const notUsedColumnKey: string = 'NotUsed';
type Props = {
	onHideModal: () => void;
	loadColumnConfiguration: () => Promise<ColumnConfiguration>;
	dispatchAction: () => void;
	securedComponent: SecuredComponents;
};

const component: React.FC<Props> = ({ onHideModal, loadColumnConfiguration, dispatchAction, securedComponent }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [dataSourceTable, setDataSourceTable] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [columnConfiguration, setColumnConfiguration] = useState<ColumnConfiguration>(undefined);
	const [error, setError] = useState<boolean>(false);
	const [unsupportedColumn, setUnsupportedColumn] = useState<GridColumn>(undefined);
	const [notUsedColumn, setNotUsedColumn] = useState<GridColumn>(undefined);

	useEffect(() => {
		if (columnConfiguration !== undefined) {
			updateDataSourceTableAndSelectedRowKeys(columnConfiguration.Columns);
		}
	}, [columnConfiguration]);

	useEffect(() => {
		if (error && selectedRowKeys.length > 0) {
			setError(false);
		}
	}, [selectedRowKeys]);

	useEffect(() => {
		loadColumnConfiguration().then(res => {
			if (res === undefined) {
				onHideModal();
			} else {
				setColumnConfiguration(res);
				updateDataSourceTableAndSelectedRowKeys(res.Columns);

				if (securedComponent === SecuredComponents.Alarm_Viewer) {
					const unsupportedColumn: GridColumn = res.Columns.find(x => x.Key === unsupportedColumnKey);
					if (unsupportedColumn) {
						setUnsupportedColumn(unsupportedColumn);
					}
					const notUsedColumn: GridColumn = res.Columns.find(x => x.Key === notUsedColumnKey);
					if (notUsedColumn) {
						setNotUsedColumn(notUsedColumn);
					}
				}

				setIsLoading(false);
			}
		});
	}, []);

	const updateDataSourceTableAndSelectedRowKeys = (columnConfiguration: GridColumn[]) => {
		const dataTable = columnConfiguration
			.filter(x => !x.IsAuxColumn)
			.map((column: GridColumn, index) => ({
				...column,
				index,
				Header: _(column.Header),
			}));
		setDataSourceTable(dataTable);
		setSelectedRowKeys(dataTable.filter(f => f.Visible === true).map(m => m.index));
	};

	const onSelectChangeCheckBox = selectedRowKeys => {
		const updateItems: GridColumn[] = dataSourceTable.map<GridColumn>(m => {
			const found = selectedRowKeys.find(f => f === m.index);
			return {
				...m,
				Order: dataSourceTable.indexOf(m) + 1,
				Visible: found !== undefined ? true : false,
			};
		});
		setSelectedRowKeys(selectedRowKeys);
		setColumnConfiguration({ ...columnConfiguration, Columns: updateItems });
	};

	const addExtraColumns = (columns: GridColumn[]): GridColumn[] => {
		let newColumns: GridColumn[] = [...columns];

		if (unsupportedColumn && notUsedColumn) {
			const unsupportedColumnIndex: number = columns.findIndex(x => x.Key === unsupportedColumnKey);
			if (unsupportedColumnIndex === -1) {
				const insertIndex: number = columns.findIndex(x => x.Order === unsupportedColumn.Order);
				if (insertIndex === -1) {
					newColumns.push({ ...unsupportedColumn, Order: columns.length + 1 });
				} else {
					newColumns.splice(insertIndex, 0, unsupportedColumn);
				}
				newColumns = newColumns.map<GridColumn>((column: GridColumn, index: number) => ({ ...column, Order: index + 1 }));
			}

			const notUsedColumnIndex: number = columns.findIndex(x => x.Key === notUsedColumnKey);
			if (notUsedColumnIndex === -1) {
				newColumns.push({ ...notUsedColumn, Order: newColumns.length + 1 });
			}
		}

		return newColumns;
	};

	const handleOnSaveColumnConfiguration = e => {
		if (selectedRowKeys.length === 0) {
			setError(true);
			return;
		}

		let clonedState: ColumnConfiguration = { ...columnConfiguration };
		if (securedComponent === SecuredComponents.Alarm_Viewer && unsupportedColumn && notUsedColumn) {
			clonedState.Columns = addExtraColumns(clonedState.Columns);
		}

		configurationApi.saveColumnsConfiguration(clonedState, securedComponent).then(res => {
			if (res.ResponseStatusCode === ResponseStatusCode.Success) {
				dispatchAction();
				onHideModal();
			} else if (res.ResponseStatusCode === ResponseStatusCode.FailedValidation) {
				setError(true);
			} else {
				handleResponse(res);
			}
		});
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChangeCheckBox,
		selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
	};

	const columns = [
		{
			title: _('Column'),
			dataIndex: 'Header',
			className: styles.dragVisible,
		},
		{
			title: _('Order'),
			dataIndex: 'sort',
			width: 55,
			className: styles.dragVisible,
			render: () => <DragHandle />,
		},
	];

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove([].concat(dataSourceTable), oldIndex, newIndex).filter(el => !!el);
			const updateItems: GridColumn[] = newData.map<GridColumn>(m => {
				return {
					...m,
					Order: newData.indexOf(m) + 1,
				};
			});
			setDataSourceTable(newData);
			setColumnConfiguration({ ...columnConfiguration, Columns: updateItems });
		}
	};

	const draggableContainer = props => {
		return <SortableItemContainer useDragHandle helperClass={styles.rowDragging} onSortEnd={onSortEnd} {...props} />;
	};

	const draggableBodyRow = ({ className, style, ...restProps }) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = dataSourceTable.findIndex(x => x.index === restProps['data-row-key']);
		return <SortableItem index={index} {...restProps} />;
	};

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={onHideModal}
			onClickOk={() => null}
			width={450}
			title={_('ConfigureColumnHeaders')}
			visible={true}
			footer={[
				<Button id='saveColumnConfigurationButton' key='ok' type='primary' onClick={handleOnSaveColumnConfiguration} disabled={error}>
					{_('SaveChanges')}
				</Button>,
				<Button id='cancelColumnConfigurationButton' key='cancel' onClick={onHideModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div>
					<div>
						<p>{_('ConfigureColumnInstructions')}</p>
					</div>
					<div className={cx({ [styles.error]: error })}>
						<Table
							id='columnConfigurationTable'
							scroll={scroll}
							pagination={false}
							dataSource={dataSourceTable}
							columns={columns}
							rowKey='index'
							size='small'
							rowSelection={rowSelection}
							components={{
								body: {
									wrapper: draggableContainer,
									row: draggableBodyRow,
								},
							}}
						/>
					</div>
					{error && (
						<span className={styles.error} id='columnConfigurationErrorMessage'>
							{_('AtLeastOneItem')}
						</span>
					)}
				</div>
			</Spin>
		</Modal>
	);
};

const ColumnConfiguration = React.memo(component);

export { ColumnConfiguration };

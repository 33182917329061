import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { removeCommasAndPercentSign } from '../../../../../../Helper';
import { setName, StoreContext } from '../../contextXbox';
import styles from '../../xboxModal.module.scss';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
};

const Name: React.FC<Props> = ({ nameError, nameValidation, onResetNameError }) => {
	const {
		xboxState: { name },
		dispatcher,
	} = useContext(StoreContext);

	const handleOnChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeCommasAndPercentSign(e.currentTarget.value);
		dispatcher(setName(value));
		onResetNameError();
	};

	const xboxNameInputId = 'xboxNameInput';

	return (
		<div className={styles.sections}>
			<label htmlFor={xboxNameInputId}>{_('Name')}</label>
			<div>
				<Input
					id={xboxNameInputId}
					key='name'
					value={name}
					onChange={handleOnChangeName}
					className={cx(styles.inputName, {
						[styles.error]: nameValidation || nameError,
					})}
					maxLength={32}
					autoComplete='chrome-off'
				/>
				{nameValidation && (
					<label className={styles.errorMessage} htmlFor={xboxNameInputId}>
						{_('EmptyXboxName')}
					</label>
				)}
				{nameError && (
					<label className={styles.errorMessage} htmlFor={xboxNameInputId}>
						{_('DuplicatedXboxName')}
					</label>
				)}
			</div>
		</div>
	);
};

export { Name };

import { Checkbox, InputNumber } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import { PhotoCallUpUserConfiguration } from '../../../../model/PhotoCallUpModel';
import '../configuration.scss';

type Props = {
	photoCallUpUserConfiguration: PhotoCallUpUserConfiguration;
	handleCheckBoxChange: (e: CheckboxChangeEvent) => void;
	handlePhotoCallUpUserConfigurationChange: (name: string, value: string | number) => void;
};

const ConfigurationView: React.FC<Props> = ({ photoCallUpUserConfiguration, handleCheckBoxChange, handlePhotoCallUpUserConfigurationChange }) => {
	return (
		<div className='photo-configure-view'>
			<fieldset className='photo-configure-border photo-configure-border-view'>
				<legend className='photo-configure-section-title'>{_('View')}</legend>
				<div id='photoCallUpConfigurationDoorOptions' className='photo-configure-door-options'>
					<span>
						<label htmlFor={'photoCallUpConfigurationAvailableCredentialsInput'}>{_('MaximumRecentCredentialsShownPerDoor')}</label>
						<InputNumber
							id='photoCallUpConfigurationAvailableCredentialsInput'
							className='photo-configure-doors-number'
							min={1}
							max={10}
							maxLength={2}
							onChange={value => {
								if (typeof value === 'number') {
									handlePhotoCallUpUserConfigurationChange('QuantityPerDoor', value);
								}
							}}
							value={photoCallUpUserConfiguration?.QuantityPerDoor}
						/>
						<span>{`(${_('Min')}: 1 - ${_('Max')}: 10)`}</span>
					</span>
					<div className='photo-configure-checkGrid'>
						<div>
							<label htmlFor='photoCallUpConfigurationShowAllDoorsSectionCheckbox'>
								<Checkbox
									id='photoCallUpConfigurationShowAllDoorsSectionCheckbox'
									name='ShowAllDoorsSection'
									onChange={handleCheckBoxChange}
									checked={photoCallUpUserConfiguration?.ShowAllDoorsSection}>
									{_('ShowAllDoorsSection')}
								</Checkbox>
							</label>
							<label htmlFor='photoCallUpConfigurationLoadCachedEntriesCheckbox'>
								<Checkbox
									id='photoCallUpConfigurationLoadCachedEntriesCheckbox'
									name='LoadCachedEntries'
									onChange={handleCheckBoxChange}
									checked={photoCallUpUserConfiguration?.LoadCachedEntries}>
									{_('LoadCachedEntries')}
								</Checkbox>
							</label>
							<label htmlFor='photoCallUpConfigurationShowDoorNameCheckbox'>
								<Checkbox
									id='photoCallUpConfigurationShowDoorNameCheckbox'
									name='ShowDoorName'
									onChange={handleCheckBoxChange}
									checked={photoCallUpUserConfiguration?.ShowDoorName}>
									{_('ShowDoorNameUsedAtBottomOfCredentials')}
								</Checkbox>
							</label>
						</div>
						<div>
							<label htmlFor='photoCallUpConfigurationShowDateTimeCheckbox'>
								<Checkbox
									id='photoCallUpConfigurationShowDateTimeCheckbox'
									name='ShowDateTime'
									onChange={handleCheckBoxChange}
									checked={photoCallUpUserConfiguration?.ShowDateTime}>
									{_('DateAndTimeAppearAtTopOfCredentials')}
								</Checkbox>
							</label>
							<label htmlFor='photoCallUpConfigurationShowBorderCardCheckbox'>
								<Checkbox
									id='photoCallUpConfigurationShowBorderCardCheckbox'
									name='ShowBorderCard'
									onChange={handleCheckBoxChange}
									checked={photoCallUpUserConfiguration?.ShowBorderCard}>
									{_('ApplyBorderColorToMostRecentCredential')}
								</Checkbox>
							</label>
							<label htmlFor='photoCallUpConfigurationShowDoorsInFullLengthCheckbox'>
								<Checkbox
									id='photoCallUpConfigurationShowDoorsInFullLengthCheckbox'
									name='ShowDoorsInFullWidth'
									onChange={handleCheckBoxChange}
									checked={photoCallUpUserConfiguration?.ShowDoorsInFullWidth}>
									{_('ViewIndividualDoorsInFullLength')}
								</Checkbox>
							</label>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export { ConfigurationView };

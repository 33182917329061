import { Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { batch } from 'react-redux';
import { HeaderBar } from '../../components/common';
import { ControllerModal } from '../../components/devicecontrol/DigiTrac/ControllerModal/ControllerModal';
import { EventTable } from '../../components/event';
import { StatusViewerTable, StatusViewerToolBar } from '../../components/statusViewer';
import { User } from '../../model/AccountModel';
import { SelectOptions } from '../../model/CommonModel';
import { DeviceObjectType } from '../../model/DeviceAdminModel';
import { ControllerToEdit, DescriptionsObject, StatusObject } from '../../model/StatusViewerModel';
import { useStoreDispatch, useStoreSelector } from '../../store';
import { selectCurrentDevice } from '../../store/common/selectors';
import { loadStatusGroups, loadStatusViewerColumnConfigurationAction, setControllerToEdit } from '../../store/statusViewer/actions';
import { selectControllerToEdit, selectStatusGroups } from '../../store/statusViewer/selectors';
import styles from './statusViewer.module.scss';

const user: User = getUser();
const { showEventsOnStatusViewerPage } = user;
const spinText = `${_('Loading')}...`;
const noneOptionId = '-1';
const noneElement: SelectOptions<string>[] = [
	{
		id: '-1-None',
		label: `<${_('None')}>`,
		value: '-1',
	},
];
const showOnlyOptions = Object.keys(StatusObject)
	.filter(k => k !== '10')
	.map<SelectOptions<string>>(e => ({
		id: `${e}-${StatusObject[e]}`,
		label: `${_(DescriptionsObject[e])}`,
		value: `${e}`,
	}));
const componentOptions = Object.keys(StatusObject).map<SelectOptions<string>>(e => ({
	id: `${e}-${StatusObject[e]}`,
	label: `${_(StatusObject[e])}`,
	value: `${e}`,
}));

const showOnlyOptionsAll = noneElement.concat(showOnlyOptions);
const componentAllOptions = noneElement.concat(componentOptions);

const StatusViewerPage: React.FC = () => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [optionShowOnlySelection, setOptionShowOnlySelection] = useState(noneOptionId);
	const [optionStatusGroupSelection, setOptionStatusGroupSelection] = useState(noneOptionId);
	const statusGroups = useStoreSelector(selectStatusGroups);
	const controllerToEdit = useStoreSelector(selectControllerToEdit);
	const currentDevice = useStoreSelector(selectCurrentDevice);

	const isController = useMemo(() => {
		return new URLSearchParams(location.search).get('controllerId');
	}, [currentDevice]);

	useEffect(() => {
		batch(() => {
			dispatch(loadStatusGroups());
			dispatch(loadStatusViewerColumnConfigurationAction());
		});
	}, []);

	useEffect(() => {
		if (statusGroups) {
			setIsLoading(false);
		}
	}, [statusGroups]);

	useEffect(() => {
		if (!isController) {
			const controllerToEdit: ControllerToEdit = {
				id: 0,
				isModalOpen: true,
			};
			dispatch(setControllerToEdit(controllerToEdit));
		}
	}, [isController]);

	const setControllerRedirect = () => {
		dispatch(setControllerToEdit({ ...controllerToEdit, id: 0 }));
	};

	const getNavigationChildTitle = () => {
		if (optionShowOnlySelection !== noneOptionId) {
			return showOnlyOptionsAll?.find(x => x.value === optionShowOnlySelection)?.label ?? '';
		} else if (statusGroups) {
			return statusGroups?.find(x => x.StatusGroupId === Number(optionStatusGroupSelection))?.Name ?? '';
		}
	};

	const navigationChildTitle = getNavigationChildTitle();

	return (
		<>
			<div id='statusViewerContainer' className='status-viewer-container'>
				{isController && controllerToEdit.id !== 0 ? (
					<ControllerModal
						currentDevice={{
							DeviceObjectType: DeviceObjectType.Controller,
							Id: controllerToEdit.id,
						}}
						navigationParentTitle={_('StatusViewer')}
						navigationChildTitle={navigationChildTitle}
						callbackRedirect={setControllerRedirect}
					/>
				) : (
					<>
						<HeaderBar title={_('StatusViewer')} />
						<Spin tip={spinText} spinning={isLoading} size='default' className={'spin-container'}>
							<div className={styles.headerStatusViewerToolBar}>
								<div>
									<StatusViewerToolBar
										optionShowOnlySelection={optionShowOnlySelection}
										optionStatusGroupSelection={optionStatusGroupSelection}
										showOnlyOptionsAll={showOnlyOptionsAll}
										componentAllOptions={componentAllOptions}
										noneElement={noneElement}
										setOptionShowOnlySelection={setOptionShowOnlySelection}
										setOptionStatusGroupSelection={setOptionStatusGroupSelection}
									/>
								</div>
								<div>
									<StatusViewerTable />
								</div>
								{showEventsOnStatusViewerPage && <EventTable />}
							</div>
						</Spin>
					</>
				)}
			</div>
		</>
	);
};

export { StatusViewerPage };

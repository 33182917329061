import { Button } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { enrollmentApi } from '../../../../api';
import { CriteriaField, CriteriaInfo } from '../../../../model/CommonModel';
import { AdvanceSearchCriteria, SearchUniqueValues, TreeViewPersonGroupModel } from '../../../../model/EnrollmentModel';
import { useStoreSelector } from '../../../../store';
import { selectAdvanceSearchCriteria, selectIsPersonTableLoading, selectSelectedPersonGroup } from '../../../../store/enrollment/selectors';
import { BuildCriteria } from '../../../common';
import { ReportCriteriaRequestInfo } from '../../../reports/Reports/ReportTabs/ReportCriteriaRequestInfo/ReportCriteriaRequestInfo';
import styles from './advancedSearch.module.scss';

type Props = {
	hideAdvancedSearchValue: boolean;
	requestCriteria: CriteriaInfo[];
	hasCriteriaChanged: boolean;
	hideAdvancedSearch: () => void;
	setRequestCriteria: (value: CriteriaInfo[], firstPass?: boolean) => void;
	handleOnAdvancedSearch: () => void;
	displaySaveChangesModal: () => void;
};

const AdvancedSearch: React.FC<Props> = ({
	hideAdvancedSearchValue,
	requestCriteria,
	hasCriteriaChanged,
	hideAdvancedSearch,
	setRequestCriteria,
	handleOnAdvancedSearch,
	displaySaveChangesModal,
}) => {
	const [columnData, setColumnData] = useState<CriteriaField[]>([]);
	const [criteriaToEdit, setRequestCriteriaToEdit] = useState<CriteriaInfo>();
	const advancedSearchCriteria: AdvanceSearchCriteria = useStoreSelector<AdvanceSearchCriteria>(selectAdvanceSearchCriteria);
	const selectedPersonGroups: TreeViewPersonGroupModel = useStoreSelector<TreeViewPersonGroupModel>(selectSelectedPersonGroup);
	const isPersonTableLoading: boolean = useStoreSelector<boolean>(selectIsPersonTableLoading);

	useEffect(() => {
		if (criteriaToEdit) {
			setRequestCriteriaToEdit(null);
		}

		setRequestCriteria([], true);
	}, [selectedPersonGroups]);

	useEffect(() => {
		//TODO: Move to an upper level to avoid multiple loads
		enrollmentApi.getAdvanceSearchUserFields().then(res => {
			setColumnData(res);
		});

		if (advancedSearchCriteria?.CriteriaRequest.length > 0) {
			setRequestCriteria(advancedSearchCriteria.CriteriaRequest as CriteriaInfo[], true);
		}

		return () => {
			if (requestCriteria.length !== 0 && hasCriteriaChanged) {
				displaySaveChangesModal();
			}
		};
	}, []);

	const handleAddRequestCriteria = (newRequest: CriteriaInfo) => {
		if (criteriaToEdit) {
			setRequestCriteriaToEdit(null);
		}

		const findCriteriaToReplace = requestCriteria.some(x => x.key === newRequest.key);

		if (findCriteriaToReplace) {
			setRequestCriteria(requestCriteria.map(x => (x.key === newRequest.key ? { ...newRequest } : x)));
		} else {
			setRequestCriteria([...requestCriteria, newRequest]);
		}
	};

	const handleChangeCriteriaOperator = (value: boolean, key: number) => {
		const criteriaApplied = requestCriteria.map(x => (x.key === key ? { ...x, OrNextCriteria: value } : x));
		setRequestCriteria([...criteriaApplied]);
	};

	const handleRemoveCriteria = (key: number) => {
		const findKey = requestCriteria.findIndex(x => x.key === key);
		if (~findKey) {
			const cloneState = [...requestCriteria];
			cloneState.splice(findKey, 1);
			setRequestCriteria([...cloneState]);
		}
	};

	const handleEditCriteriaToBeEdited = (key: number) => {
		const findCriteria = requestCriteria.find(x => x.key === key);
		if (findCriteria) {
			setRequestCriteriaToEdit(findCriteria);
		}
	};

	const handleCancelCriteriaEdition = (key: number) => {
		setRequestCriteriaToEdit(null);
	};

	const getLastCriteriaKey = requestCriteria.length > 0 ? requestCriteria[requestCriteria.length - 1].key : 1;
	const isEditingCriteria = criteriaToEdit !== undefined && criteriaToEdit !== null;

	const getUniqueColumnSearchValuesBySearchText = (criteriaField: CriteriaField, searchText: string): Promise<string[]> => {
		const searchUniqueValuesHelper: Partial<SearchUniqueValues> = {
			FolderId: selectedPersonGroups.FolderId,
			GroupId: selectedPersonGroups.GroupId,
			SearchText: searchText,
		};
		return enrollmentApi.getUniqueColumnSearchValuesBySearchText(criteriaField, searchUniqueValuesHelper);
	};

	const getUniqueColumnsValuesPagination = (criteriaField: CriteriaField, currentPage: number, pageSize: number): Promise<string[]> => {
		const searchUniqueValuesHelper: Partial<SearchUniqueValues> = {
			FolderId: selectedPersonGroups.FolderId,
			GroupId: selectedPersonGroups.GroupId,
			PageNumber: currentPage,
			PageSize: pageSize,
		};

		return enrollmentApi.getUniqueColumnSearchValuesBySearchText(criteriaField, searchUniqueValuesHelper);
	};

	return (
		<div className={cx(styles.advancedSearchContainer, { [styles.advancedSearchContainerCollapse]: hideAdvancedSearchValue })}>
			<div>
				<div>
					<BuildCriteria
						key={`${selectedPersonGroups.GroupId}-${selectedPersonGroups.FolderId}`}
						lastKey={getLastCriteriaKey}
						columnData={columnData}
						onAddRequestCriteria={handleAddRequestCriteria}
						criteriaToEdit={criteriaToEdit}
						hasUDFChanged={false}
						onResetUDFChanged={() => {}}
						isDisabled={false}
						getUniqueColumnsValues={getUniqueColumnSearchValuesBySearchText}
						getUniqueColumnsValuesPagination={getUniqueColumnsValuesPagination}
						addButtonAtBottom={true}
					/>
				</div>
				<div className={styles.criteriaRequestInfo}>
					<ReportCriteriaRequestInfo
						requestCriteria={requestCriteria}
						onDeleteCriteria={handleRemoveCriteria}
						onChangeCriteriaOperator={handleChangeCriteriaOperator}
						onSetCriteriaToBeEdited={handleEditCriteriaToBeEdited}
						onCancelEdition={handleCancelCriteriaEdition}
						isEditing={isEditingCriteria}
						currentEditionKey={criteriaToEdit?.key}
						roundedButtons={true}
					/>
				</div>
			</div>
			<div>
				<Button id='ApplySearchButton' type='primary' onClick={handleOnAdvancedSearch} disabled={requestCriteria.length === 0 || isPersonTableLoading}>
					<label htmlFor='ApplySearchButton'>{_('ApplySearch')}</label>
				</Button>
				<Button id='CloseButton' type='text' onClick={hideAdvancedSearch}>
					<label htmlFor='CloseButton'>{_('Close')}</label>
				</Button>
			</div>
		</div>
	);
};

export { AdvancedSearch };

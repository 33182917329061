import React, { CSSProperties } from 'react';
import { OperatorNoteAlarm } from '../../../model/AlarmModel';
import { getAlarmTextColor } from '../helpers';
import styles from './alarmDescription.module.scss';

type Props = {} & OperatorNoteAlarm;

const AlarmDescription: React.FC<Props> = props => {
	const { Description = '', Address = '', Level = 0, EventId = 0, HostTime = '', HardwareTime = '', ReturnedToNormal = false } = props;
	const alarmStyle: CSSProperties = getAlarmTextColor(ReturnedToNormal);

	return (
		<div className={styles.container}>
			<div className={styles.alarmInfo}>
				<div>
					<div>
						{_('Description')}: <span style={alarmStyle}>{Description}</span>
					</div>
					<div>
						{_('Address')}: <span style={alarmStyle}>{Address}</span>
					</div>
				</div>
				<div>
					<div>
						{_('Level')}: <span style={alarmStyle}>{Level}</span>
					</div>
					<div>
						{_('AlarmID')}: <span style={alarmStyle}>{EventId}</span>
					</div>
				</div>
				<div>
					<div>
						{_('HostTime')}: <span style={alarmStyle}>{FormatDate(HostTime, true, null, true)}</span>
					</div>
					<div>
						{_('ControllerTime')}: <span style={alarmStyle}>{FormatDate(HardwareTime, true, null, true)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export { AlarmDescription };

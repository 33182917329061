import React, { useContext, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../../../api';
import { Reader, ReaderError, SupportedReaderClass } from '../../../../../../../../../model/DeviceAdminModel';
import { NotificationStatus } from '../../../../../../../../common/NotificationStatus/NotificationStatus';
import { DoorStoreSingleContext } from '../../../../../../DoorModal/DoorStoreSingleContext';
import {
	setExitReaderAllegionLockAddress,
	setExitReaderEACAddress,
	setExitReaderErrorMessage,
	setExitReaderErrorType,
	setExitReaderWL2HubAddress,
	setExitReaderWLHubAddress,
} from '../../../../exitReaderContext';
import { WirelessFields } from './WirelessFields';

type Props = {
	submittedForm: boolean;
};

const WirelessVersion: React.FC<Props> = ({ submittedForm }) => {
	var {
		contextStateDoor: {
			exitReader: { supportedReaderClass, wLHubAddress, eACAddress, wL2HubAddress, allegionLockAddress },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [previousHub, setPreviousHub] = useState<number>(undefined);
	const [previousLock, setPreviousLock] = useState<number>(undefined);

	const handleOnSetWirelessAddress = (value: number) => {
		dispatcherDoor(setExitReaderWLHubAddress(value));
	};

	const handleOnSetWirelessAddress2 = (value: number) => {
		dispatcherDoor(setExitReaderWL2HubAddress(value));
	};

	const handleOnSetEACAddress = (value: number) => {
		dispatcherDoor(setExitReaderEACAddress(value));
	};

	const validateEACAddress = async e => {
		if ((previousHub != wLHubAddress || previousLock != eACAddress) && !submittedForm) {
			const hubValue: number = e.target.id === 'wirelessAddress' ? e.target.value : wLHubAddress;
			const lockValue: number = e.target.id === 'lockAddress' ? e.target.value : eACAddress;

			setPreviousHub(hubValue);
			setPreviousLock(lockValue);

			const reader: Partial<Reader> = {
				EACAddress: lockValue,
				WLHubAddress: hubValue,
			};

			const response = await deviceAdminApi.validateEACAddress(reader);
			NotificationStatus({
				responseData: response,
				onSuccessCallback: () => {
					dispatcherDoor(setExitReaderErrorType(undefined));
				},
				onFailedValidation: () => {
					dispatcherDoor(setExitReaderErrorType(undefined));
					dispatcherDoor(setExitReaderErrorMessage(response.ErrorMessage));
					dispatcherDoor(setExitReaderErrorType(ReaderError[response.AdditionalResponseInfo]));
				},
			});
		}
	};

	const handleOnSetLockAddress = (value: number) => {
		dispatcherDoor(setExitReaderAllegionLockAddress(value));
	};

	return (
		<>
			{supportedReaderClass === SupportedReaderClass.WL_Aperio && (
				<WirelessFields
					handleOnFocusOut={validateEACAddress}
					maxLockNumber={127}
					maxWirelessNumber={15}
					wirelessAddress={wLHubAddress}
					setWirelessAddress={handleOnSetWirelessAddress}
					lockAddress={eACAddress}
					setLockAddress={handleOnSetEACAddress}></WirelessFields>
			)}
			{supportedReaderClass === SupportedReaderClass.WL_Allegion && (
				<WirelessFields
					handleOnFocusOut={undefined}
					maxLockNumber={254}
					maxWirelessNumber={254}
					wirelessAddress={wL2HubAddress}
					setWirelessAddress={handleOnSetWirelessAddress2}
					lockAddress={allegionLockAddress}
					setLockAddress={handleOnSetLockAddress}></WirelessFields>
			)}
		</>
	);
};

export { WirelessVersion };

import { ReadOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { AlarmModel } from '../../../model/AlarmModel';
import { useStoreSelector } from '../../../store';
import { selectAlarmModel } from '../../../store/alarm/selectors';
import { AlarmTableInModal } from '../../alarms';
import { Modal } from '../../common';
import { instructionsStyle } from '../helpers';
import styles from './alarmInstructionsModal.module.scss';

const { Paragraph } = Typography;
type Props = {
	onHideModal: () => void;
};

const AlarmInstructionsModal: React.FC<Props> = ({ onHideModal }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [alarmInstructions, setAlarmInstructions] = useState<string>('');

	const alarmModel: AlarmModel = useStoreSelector<AlarmModel>(selectAlarmModel);

	useEffect(() => {
		if (alarmModel) {
			setAlarmInstructions(alarmModel.Instructions);
			setIsLoading(false);
		} else {
			onHideModal();
		}
	}, []);

	return (
		<Modal
			onCancel={onHideModal}
			onClickOk={() => null}
			width={750}
			title={
				<span>
					<ReadOutlined style={instructionsStyle} /> {_('Instructions')}
				</span>
			}
			visible={true}
			customZoomClass={styles.modal}
			footer={null}>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
				<div className={styles.content}>
					<div>
						<AlarmTableInModal />
					</div>
					<div className={styles.instructions}>
						<Paragraph>{alarmInstructions}</Paragraph>
					</div>
				</div>
			</Spin>
		</Modal>
	);
};

export { AlarmInstructionsModal };

import { CloseOutlined } from '@ant-design/icons';
import { ButtonProps, Modal as Modalantd } from 'antd';
import React, { CSSProperties, FunctionComponent, ReactNode, useEffect } from 'react';
import styles from './modal.module.scss';

type Props = {
	children: React.ReactNode;
	visible: boolean;
	onClickOk?: () => void;
	onCancel?: () => void;
	title?: string | ReactNode;
	className?: string;
	footer?: React.ReactNode[];
	width?: string | number;
	style?: CSSProperties;
	customZoomClass?: string;
	keyboard?: boolean;
	maskClosable?: boolean;
	destroyOnClose?: boolean;
	bodyStyle?: React.CSSProperties;
	closable?: boolean;
	maskStyle?: React.CSSProperties;
	closeIconId?: string;
	nestedModal?: boolean;
	okButtonProps?: ButtonProps;
	closeIconStyle?: CSSProperties;
	hideCloseButton?: boolean;
};

const Modal: FunctionComponent<Props> = ({
	visible,
	onClickOk,
	children,
	title,
	className,
	onCancel,
	footer,
	width,
	style,
	customZoomClass,
	keyboard = false,
	maskClosable = false,
	destroyOnClose,
	bodyStyle,
	closable,
	maskStyle,
	closeIconId = 'closeModalIcon',
	nestedModal,
	okButtonProps,
	closeIconStyle,
	hideCloseButton,
}) => {
	///remove scroll when modal is opened
	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			if (!nestedModal) {
				document.body.style.overflow = 'auto';
			}
		};
	}, []);

	const closeIconElement = hideCloseButton ? false : <CloseOutlined id={closeIconId} style={{ fontSize: '12px', ...closeIconStyle }} />;

	return (
		<Modalantd
			keyboard={keyboard}
			closable={closable}
			destroyOnClose={destroyOnClose}
			maskClosable={maskClosable}
			open={visible}
			className={className}
			title={title}
			wrapClassName={customZoomClass || styles.WithModalZoom}
			onOk={onClickOk}
			onCancel={onCancel}
			centered
			width={width}
			bodyStyle={bodyStyle}
			style={style}
			maskStyle={maskStyle}
			footer={footer ? [...footer] : null}
			closeIcon={closeIconElement}
			okButtonProps={okButtonProps}>
			{children}
		</Modalantd>
	);
};

export { Modal };

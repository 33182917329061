import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { Select } from '../../../../../../common';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setEnableHexPassthrough, setEnableMatchBezelTamper, setSpecialHandlingInstruction, StoreContext } from '../../../contextReader';
import styles from '../../CardReaderSetup/cardreadersetup.module.scss';

type Props = {
	useDoorContext: boolean;
};

const MatchCardReaderInterface: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					enableMatchBezelTamper,
					enableHexPassThrough,
					specialHandlingInstructions,
					selections: { specialHandlingInstruction },
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				enableMatchBezelTamper,
				enableHexPassThrough,
				specialHandlingInstructions,
				selections: { specialHandlingInstruction },
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [specialInstructions, setSpecialInstructions] = useState<SelectOptions<string>[]>([]);

	useEffect(() => {
		setSpecialInstructions(specialHandlingInstructions.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [specialHandlingInstructions]);

	const handleOnSetEnableMatchBezelTamper = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setEnableMatchBezelTamper(value.target.checked)) : dispatcher(setEnableMatchBezelTamper(value.target.checked));
	};

	const handleOnSetEnableHexPassthrough = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setEnableHexPassthrough(value.target.checked)) : dispatcher(setEnableHexPassthrough(value.target.checked));
	};

	const handleOnSetSpecialHandlingInstruction = (value: string) => {
		useDoorContext ? dispatcherDoor(setSpecialHandlingInstruction(Number(value))) : dispatcher(setSpecialHandlingInstruction(Number(value)));
	};

	return (
		<div className={styles.cardReaderInterface}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('MatchDS47Interface')}</legend>
					<div>
						<div className={styles.sections}>
							<Checkbox checked={enableMatchBezelTamper} onChange={handleOnSetEnableMatchBezelTamper} id={'matchBezel'}>
								<span>{_('EnableMatchBezel')}</span>
							</Checkbox>
						</div>
						<div className={styles.sections}>
							<Checkbox checked={enableHexPassThrough} onChange={handleOnSetEnableHexPassthrough} id={'hexPassthrough'}>
								<span>{_('EnableWiegandHex')}</span>
							</Checkbox>
						</div>
						<div className={styles.sections}>
							<span>{_('SpecialHandling')}</span>
							<div>
								<Select
									className={styles.select}
									options={specialInstructions}
									onChange={handleOnSetSpecialHandlingInstruction}
									key='specialHangling'
									id='readerModalspecialHanglingSelect'
									value={specialHandlingInstruction.toString()}
									getPopupContainer={() => document.getElementById('cardReaderContainer')}
								/>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { MatchCardReaderInterface };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".draggableContainer{position:relative;overflow:hidden}.event-viewer-widget-container [class^=event-table-actions]{margin-top:5px}.event-viewer-widget-container .status-widget-collapse [class*=ant-collapse-content-box]{padding:0px !important}.event-viewer-widget-container .status-widget>.ant-collapse-header{font-size:15px !important;font-weight:600}.event-viewer-widget-container .status-widget-collapse-content .ant-collapse-content{min-height:413px !important;max-height:460px !important;padding-top:10px}.event-viewer-widget-container .status-widget-collapse-content .ant-collapse-content [class*=event-table-actions]{padding-left:10px}.event-viewer-widget-container h3>div{color:#fff}", "",{"version":3,"sources":["webpack://src/components/widget/EventTableWidget/eventTableWidget.scss"],"names":[],"mappings":"AAAA,oBACC,iBAAA,CACA,eAAA,CAGD,4DACE,cAAA,CAGF,yFACE,sBAAA,CAGF,mEACC,yBAAA,CACA,eAAA,CAGD,qFACE,2BAAA,CACA,2BAAA,CACA,gBAAA,CAGF,kHACG,iBAAA,CAGH,sCACC,UAAA","sourcesContent":[".draggableContainer {\r\n\tposition: relative;\r\n\toverflow: hidden;\r\n}\r\n\r\n.event-viewer-widget-container [class^='event-table-actions'] {\r\n\t\tmargin-top: 5px;\r\n\t}\r\n\r\n.event-viewer-widget-container .status-widget-collapse [class*='ant-collapse-content-box'] {\r\n\t\tpadding: 0px !important;\r\n\t}\r\n\r\n.event-viewer-widget-container .status-widget > .ant-collapse-header {\r\n\tfont-size: 15px !important;\r\n\tfont-weight: 600;\r\n\t}\r\n\r\n.event-viewer-widget-container .status-widget-collapse-content .ant-collapse-content {\r\n\t\tmin-height: 413px !important;\r\n\t\tmax-height: 460px !important;\r\n\t\tpadding-top: 10px;\r\n\t}\r\n\r\n.event-viewer-widget-container .status-widget-collapse-content .ant-collapse-content [class*='event-table-actions'] {\r\n\t\t\tpadding-left: 10px;\r\n\t\t}\r\n\r\n.event-viewer-widget-container h3 > div {\r\n\tcolor: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggableContainer": "draggableContainer",
	"event-viewer-widget-container": "event-viewer-widget-container",
	"status-widget-collapse": "status-widget-collapse",
	"status-widget": "status-widget",
	"ant-collapse-header": "ant-collapse-header",
	"status-widget-collapse-content": "status-widget-collapse-content",
	"ant-collapse-content": "ant-collapse-content"
};
export default ___CSS_LOADER_EXPORT___;

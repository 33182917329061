import { Radio, RadioChangeEvent, Space } from 'antd';
import React, { useContext } from 'react';
import { PortNetWorkType, SnibSearchType } from '../../../../../../../../../model/DeviceAdminModel';
import { PortStoreContext, setSnibSearchType } from '../../../../../contextPort';
import styles from './searchType.module.scss';

type Props = {};
const searchTypeRadioId = 'searchTypeRadioId';
const radioStyle = {
	display: 'block',
	height: '30px',
	lineHeight: '30px',
};

const SearchType: React.FC<Props> = () => {
	const {
		portState: { snibSearchType, networkType, loading },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleChangeSearchType = (e: RadioChangeEvent) => {
		const {
			target: { value },
		} = e;
		dispatcher(setSnibSearchType(value));
	};

	const isSearchTypeDisabled = networkType === PortNetWorkType.IPv6;

	return (
		<div className={styles.fieldsetContainer}>
			<fieldset>
				<legend className={styles.legend}>{_('SearchType')}</legend>
				<div className={styles.radioButton}>
					<Radio.Group id={searchTypeRadioId} value={snibSearchType} onChange={handleChangeSearchType} disabled={isSearchTypeDisabled || loading}>
						<Space direction='horizontal'>
							<Radio style={radioStyle} value={SnibSearchType.Broadcast}>
								{_('Broadcast')}
							</Radio>
							<Radio style={radioStyle} value={SnibSearchType.IPScan}>
								{_('IPScan')}
							</Radio>
						</Space>
					</Radio.Group>
				</div>
			</fieldset>
		</div>
	);
};

export { SearchType };

import { Button } from 'antd';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './selectAll.module.scss';

type Props = {
	useDoorContext: boolean;
};

const SelectAll: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: null, dispatcher: dispatcherDoor };
	} else {
		const { dispatcher } = useContext(StoreContext);
		inputContext = { context: null, dispatcher: dispatcher };
	}

	const handleClickSelectAll = () => {
		inputContext.dispatcher(
			setInputAction({
				EnabledContactStateChangeReportingDoorContact: true,
				EnabledContactStateChangeReportingRQE: true,
				EnabledContactStateChangeReportingTamper: true,
				EnabledContactStateChangeReportingLineFault: true,
				EnabledMaskStateChangeReportingDoorRelay: true,
				EnabledMaskStateChangeReportingRQE: true,
				EnabledMaskStateChangeReportingEntyExitDelay: true,
				EnabledMaskStateChangeReportingUnlock: true,
				EnabledMaskStateChangeReportingTimeZone: true,
				EnabledMaskStateChangeReportingControlCode: true,
			})
		);
	};

	const handleClickDeselectAll = () => {
		inputContext.dispatcher(
			setInputAction({
				EnabledContactStateChangeReportingDoorContact: false,
				EnabledContactStateChangeReportingRQE: false,
				EnabledContactStateChangeReportingTamper: false,
				EnabledContactStateChangeReportingLineFault: false,
				EnabledMaskStateChangeReportingDoorRelay: false,
				EnabledMaskStateChangeReportingRQE: false,
				EnabledMaskStateChangeReportingEntyExitDelay: false,
				EnabledMaskStateChangeReportingUnlock: false,
				EnabledMaskStateChangeReportingTimeZone: false,
				EnabledMaskStateChangeReportingControlCode: false,
			})
		);
	};
	return (
		<div className={styles.container}>
			<Button id='stateChangeReportingInputSelectAllButton' key='selectAll' onClick={handleClickSelectAll}>
				{_('SelectAll')}
			</Button>
			<Button id='stateChangeReportingInputDeselectAllButton' key='deselectAll' onClick={handleClickDeselectAll}>
				{_('DeselectAll')}
			</Button>
		</div>
	);
};

export { SelectAll };

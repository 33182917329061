import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { reportsApi } from '../../../api/ReportsApi';
import { Report, Reports } from '../../../model/ReportsModel';
import { CollapseChildContent, CollapseData, CollapsePanel } from '../../common';
import { ReportsTabs } from './ReportTabs/ReportTabs';

const Reports: React.FC = () => {
	const [reportData, setReportData] = useState<CollapseData<Report>[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const seedData: CollapseData<Report>[] = [];

		reportsApi.getReports().then(response => {
			response.forEach((x, index) => {
				const content: CollapseChildContent<Report>[] = x.ReportsInfo.map((r, index) => ({
					key: r.ReportId,
					value: r,
					label: r.Name,
					render: w => <ReportsTabs report={w} key={w.ReportId} />,
				}));
				seedData.push({ label: x.ReportGroupName, content, key: index });
			});

			setReportData(seedData);
			setIsLoading(false);
		});
	}, []);

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
			<CollapsePanel collapseData={reportData} />
		</Spin>
	);
};

export { Reports };

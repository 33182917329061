import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnsProps, buildColumn } from '../../../../Helper';
import { AffectedCredential, CredentialDetail } from '../../../../model/EnrollmentModel';
import { Modal } from '../../../common';

type AffectedCredentialsTableProps = {
	affectedCredentials: AffectedCredential[];
	confirmationText: string;
	credential: CredentialDetail;
	onNo: (credential: CredentialDetail) => void;
	onYes: (credential: CredentialDetail) => void;
};

const AffectedCredentialsTable: React.FC<AffectedCredentialsTableProps> = ({ affectedCredentials, confirmationText, credential, onNo, onYes }) => {
	const columns: ColumnsProps<AffectedCredential>[] = [
		{
			...buildColumn(_('PersonID'), 'PersonId', '80px', 'start'),
		},
		{
			...buildColumn(_('FirstName'), 'FirstName', 'auto', 'start'),
		},
		{
			...buildColumn(_('LastName'), 'LastName', 'auto', 'start'),
		},
		{
			...buildColumn(_('Credential'), 'CredentialId', '85px', 'start'),
		},
		{
			...buildColumn(_('Description'), 'Description', 'auto', 'start'),
		},
	];

	const [isVisible, setIsVisible] = useState<boolean>(affectedCredentials?.length > 0);

	useEffect(() => {
		setIsVisible(affectedCredentials?.length > 0);
	}, [JSON.stringify(affectedCredentials)]);

	const ModalContent: JSX.Element = (
		<div style={{ minHeight: 400 }}>
			<div dangerouslySetInnerHTML={{ __html: confirmationText }} style={{ marginBottom: 15 }} />
			<Table
				columns={columns}
				dataSource={affectedCredentials}
				id='affectedCredentialsTable'
				loading={affectedCredentials === undefined}
				pagination={false}
				scroll={{ y: '250px' }}
				size='small'
				style={{ height: 290 }}
			/>
		</div>
	);

	const Footer: JSX.Element[] = [
		<Button
			htmlType='button'
			style={{ marginRight: 15 }}
			type='primary'
			key={'yes'}
			onClick={() => {
				onYes(credential);
			}}>
			{_('Yes')}
		</Button>,
		<Button
			htmlType='button'
			type='primary'
			key={'yes'}
			onClick={() => {
				onNo(credential);
			}}>
			{_('No')}
		</Button>,
	];

	return (
		<Modal closable={false} footer={Footer} visible={isVisible} width='950px' title={_('LinkedTemplateConfirmation')}>
			{ModalContent}
		</Modal>
	);
};

export default AffectedCredentialsTable;

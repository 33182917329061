import React from 'react';
import { RelayLogicTimeZones } from '../../../../../../../model/DeviceAdminModel';
import styles from '../../../expansionRelayModal.module.scss';
import { Actuate, AutoClear, Disable, Operate } from './';

type Props = {
	setPreviousSelections: (RelayLogicTimeZones) => void;
	previousSelections: RelayLogicTimeZones;
	useDoorContext: boolean;
};

const TimeZones: React.FC<Props> = props => {
	const { setPreviousSelections, previousSelections, useDoorContext } = props;
	return (
		<div className={styles.options}>
			<div className={styles.sectionContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('TimeZones')}</legend>
					<div className={styles.radioButton}>
						<Operate
							setPreviousSelections={setPreviousSelections}
							previousSelections={previousSelections}
							useDoorContext={useDoorContext}></Operate>
						<Actuate
							setPreviousSelections={setPreviousSelections}
							previousSelections={previousSelections}
							useDoorContext={useDoorContext}></Actuate>
						<Disable
							setPreviousSelections={setPreviousSelections}
							previousSelections={previousSelections}
							useDoorContext={useDoorContext}></Disable>
						<AutoClear
							setPreviousSelections={setPreviousSelections}
							previousSelections={previousSelections}
							useDoorContext={useDoorContext}></AutoClear>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { TimeZones };

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext, useEffect, useState } from 'react';
import { setDoorAutoRelockDisableAction, setDoorAutoRelockOnCloseAction } from '../../../DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorStoreSingleContext';
import styles from '../../general.module.scss';

type Props = {};

const AutoRelock: React.FC<Props> = () => {
	const [autoRelock, setAutoRelock] = useState(0);
	const {
		contextStateDoor: {
			door: {
				door: { AutoRelockOnClose, AutoRelockDisable },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	useEffect(() => {
		if (AutoRelockOnClose) {
			setAutoRelock(1);
		} else if (AutoRelockDisable) {
			setAutoRelock(2);
		} else if (!AutoRelockOnClose) {
			setAutoRelock(0);
		}
	}, []);

	const handleSelectCheckBox = (e: RadioChangeEvent) => {
		const value = e.target.value;
		if (value === 1) {
			dispatcherDoor(setDoorAutoRelockDisableAction(false));
			dispatcherDoor(setDoorAutoRelockOnCloseAction(true));
		} else if (value === 2) {
			dispatcherDoor(setDoorAutoRelockDisableAction(true));
			dispatcherDoor(setDoorAutoRelockOnCloseAction(false));
		} else if (value === 0) {
			dispatcherDoor(setDoorAutoRelockDisableAction(false));
			dispatcherDoor(setDoorAutoRelockOnCloseAction(false));
		}
		setAutoRelock(value);
	};

	return (
		<div className={styles.containerLegend}>
			<fieldset>
				<legend className={styles.legend}>{_('AutoRelock')}</legend>
				<Radio.Group onChange={handleSelectCheckBox} value={autoRelock} className={styles.radioGroupMargin}>
					<Radio className={styles.radioColumnStyle} id='doorModalOnOpenRadioButton' value={0}>
						{_('OnOpen')}
					</Radio>
					<Radio className={styles.radioColumnStyle} id='doorModalOnCloseRadioButton' value={1}>
						{_('OnClose')}
					</Radio>
					<Radio className={styles.radioColumnStyle} id='doorModalDisableRadioButton' value={2}>
						{_('Disable')}
					</Radio>
				</Radio.Group>
			</fieldset>
		</div>
	);
};

export { AutoRelock };

import {
	AssertionOptions,
	AuthenticatorAssertionRawResponse,
	AuthenticatorAttestationRawResponse,
	CredentialCreateOptions,
	LogOnViewModel,
} from '../model/AccountModel';
import { CodeProperties, ResponseObject, ResponseObjectEntity } from '../model/CommonModel';
import { OperatorKey } from '../model/OperatorModel';
import { Api } from './api';

class AccountApi extends Api {
	public constructor() {
		super();
	}

	public updateAgreementByOperator = async (): Promise<boolean> => {
		const url = this.getUrl('Account/UpdateAgreementByOperator', true);

		return await this.post<boolean>(url);
	};

	public logOn = async (model: LogOnViewModel): Promise<LogOnViewModel> => {
		const url = this.getUrl('Account/Login', true);
		const data = {
			model,
		};

		return await this.post<LogOnViewModel>(url, data);
	};

	public getSessionMask = async (): Promise<ResponseObjectEntity<CodeProperties>> => {
		const url = this.getUrl('Account/GetSessionMask', true);
		return await this.post<ResponseObjectEntity<CodeProperties>>(url);
	};

	public getSecurityKeyOptions = async (operatorName: string): Promise<ResponseObjectEntity<CredentialCreateOptions>> => {
		const url = this.getUrl('Account/GetSecurityKeyOptions', true);

		return await this.post<ResponseObjectEntity<CredentialCreateOptions>>(url, { operatorName });
	};

	public retrieveCurrentSecurityKeyOptions = async (
		operatorName: string,
		keyId: string,
		operatorGuid: string,
		isOperatorNew: boolean
	): Promise<ResponseObjectEntity<CredentialCreateOptions>> => {
		const url = this.getUrl('Account/RetrieveCurrentSecurityKeyOptions', true);

		return await this.post<ResponseObjectEntity<CredentialCreateOptions>>(url, { operatorName, keyId, operatorGuid, isOperatorNew });
	};

	public addSecurityKey = async (
		attestationResponse: AuthenticatorAttestationRawResponse,
		name: string
	): Promise<ResponseObjectEntity<PublicKeyCredentialCreationOptions>> => {
		const url = this.getUrl('Account/AddSecurityKey', true);

		return await this.post<ResponseObjectEntity<PublicKeyCredentialCreationOptions>>(url, { attestationResponse, name });
	};

	public registerSecurityKeyCache = async (
		attestationResponse: AuthenticatorAttestationRawResponse,
		name: string,
		keyId: string,
		operatorGuid: string
	): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('Account/RegisterSecurityKeyCache', true);

		return await this.post<ResponseObjectEntity<boolean>>(url, { attestationResponse, name, keyId, operatorGuid });
	};

	public getAssertionOptions = async (userName: string): Promise<ResponseObjectEntity<AssertionOptions>> => {
		const url = this.getUrl('Account/GetAssertionOptions', true);

		return await this.post<ResponseObjectEntity<AssertionOptions>>(url, { userName });
	};

	public makeAssertion = async (clientResponse: AuthenticatorAssertionRawResponse): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('Account/MakeAssertion', true);

		return await this.post<ResponseObjectEntity<boolean>>(url, { clientResponse });
	};

	public getNewOperatorGuid = async (): Promise<ResponseObjectEntity<string>> => {
		const url = this.getUrl('Account/GetNewOperatorGuid', true);

		return await this.post<ResponseObjectEntity<string>>(url);
	};

	public clearSecurityKeyRegistrationCache = async (): Promise<ResponseObject> => {
		const url = this.getUrl('Account/ClearSecurityKeyRegistrationCache', true);

		return await this.post<ResponseObject>(url);
	};

	public getSecurityKeys = async (operatorId: number): Promise<ResponseObjectEntity<OperatorKey[]>> => {
		const url = this.getUrl('Account/GetSecurityKeys', true);
		const data = {
			operatorId,
		};

		return await this.post<ResponseObjectEntity<OperatorKey[]>>(url, data);
	};

	public isSecurityKeyNameUnique = async (operatorId: number, name: string): Promise<ResponseObjectEntity<boolean>> => {
		const url = this.getUrl('Account/IsSecurityKeyNameUnique', true);
		const data = {
			operatorId,
			name,
		};

		return await this.post<ResponseObjectEntity<boolean>>(url, data);
	};
}

const accountApi = new AccountApi();

export { accountApi };

import { Spin, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React from 'react';
import { ScrollType, buildColumn, getDefaultTablePaginationConfig } from '../../../Helper';
import { statusApi } from '../../../api';
import constants from '../../../constants';
import { Logger } from '../../../model/LoggingModel';
import { DoorAccess, DoorAccessRecord, DoorStatusData, WidgetInfo } from '../../../model/StatusModel';
import './doorStatusTable.scss';

// Internal state for the component
interface State {
	isLoading: boolean;
	data: DoorStatusData[];
	expandedRows: DoorAccessRecord[];
	queueToBeProcessed: number[];
}

// Normal properties for the component
interface OwnProps {
	isExpanded: boolean;
	widgetInfo: WidgetInfo;
}

// combine them together
type Props = OwnProps;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { x: 1650, y: 256 };
const pagination: TablePaginationConfig = getDefaultTablePaginationConfig();
const columns: ColumnsType<DoorStatusData> = [
	{
		...buildColumn(_('DoorName'), 'DoorName', '16%', 'left'),
		sorter: (a, b) => a.DoorName.localeCompare(b.DoorName),
	},
	{
		...buildColumn(_('Address'), 'Address', '16%', 'left'),
		sorter: (a, b) => a.Address.localeCompare(b.Address),
	},
	{
		...buildColumn(_('Status'), 'Status', '7%', 'left'),
		sorter: (a, b) => a.Status.localeCompare(b.Status),
	},
	{
		...buildColumn(_('Alarm'), 'Alarm', '7%', 'left'),
		sorter: (a, b) => a.Alarm.localeCompare(b.Alarm),
	},
	{
		...buildColumn(_('Masked'), 'Masked', '7%', 'left'),
		sorter: (a, b) => a.Masked.localeCompare(b.Masked),
	},
	{
		...buildColumn(_('Enabled'), 'Enabled', '5%', 'left'),
		sorter: (a, b) => (a.Enabled !== null ? a.Enabled.localeCompare(b.Enabled) : ''),
	},
	{
		...buildColumn(_('1ALRM'), 'InputAlarm', '7%', 'left'),
		sorter: (a, b) => a.InputAlarm.localeCompare(b.InputAlarm),
	},
	{
		...buildColumn(_('2RQE'), 'InputRQE', '7%', 'left'),
		sorter: (a, b) => a.InputRQE.localeCompare(b.InputRQE),
	},
	{
		...buildColumn(_('3TMPR'), 'InputTamper', '7%', 'left'),
		sorter: (a, b) => a.InputTamper.localeCompare(b.InputTamper),
	},
	{
		...buildColumn(_('LM'), 'LineModule', '5%', 'left'),
		sorter: (a, b) => a.LineModule - b.LineModule,
	},
	{
		...buildColumn(_('LMVolts'), 'LineModuleVoltage', '5%', 'left'),
		sorter: (a, b) => a.LineModuleVoltage.localeCompare(b.LineModuleVoltage),
	},
	{
		...buildColumn(_('Relay'), 'RelayState', '7%', 'left'),
		sorter: (a, b) => a.RelayState.localeCompare(b.RelayState),
	},
	{
		...buildColumn(_('RDetails'), 'RelayDetails', '7%', 'left'),
		sorter: (a, b) => a.RelayDetails.localeCompare(b.RelayDetails),
	},
	{
		...buildColumn(_('2PR'), 'TwoPersonRule', '7%', 'left'),
		sorter: (a, b) => a.TwoPersonRule.localeCompare(b.TwoPersonRule),
	},
];

class DoorStatusTable extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			isLoading: true,
			data: null,
			expandedRows: [],
			queueToBeProcessed: [],
		};

		//We use this flag to avoid multiple api calls at the same time
		window.sessionStorage.setItem(constants.sessionStorage.statusViewer.RETRIEVE_DOOR_STATUS_FLAG, '0');
	}

	componentDidMount() {
		this.fetchData();
	}

	componentWillUnmount() {
		this.clearFetchTimeout();
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	clearFetchTimeout() {
		if (this.autoRefreshPerformanceLoop) {
			clearTimeout(this.autoRefreshPerformanceLoop);
		}
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		const { isExpanded } = this.props;

		if (nextProps.isExpanded) {
			this.clearFetchTimeout();

			if (!isExpanded) {
				this.fetchData();
			} else {
				const { widgetInfo } = this.props;

				if (widgetInfo.autoRefresh) {
					this.autoRefreshPerformanceLoop = setTimeout(() => {
						this.fetchData();
					}, widgetInfo.autoRefreshTimer * 1000);
				}
			}
		} else {
			this.setState({
				expandedRows: [],
			});
		}

		return JSON.stringify(this.state) !== JSON.stringify(nextState);
	}

	async queueFetcher(door: DoorStatusData) {
		const maxSimultaneouslyRequests: number = 5;
		let currentRequests: number = 0;
		let i: number = 0;

		return await new Promise(resolve => {
			const result = [];

			const fetcher = setInterval(async () => {
				const queue: number[] = [...this.state.queueToBeProcessed];
				if (queue.filter(doorId => doorId).length === 0) {
					clearInterval(fetcher);
					resolve(result);
				}

				if (currentRequests >= maxSimultaneouslyRequests || i > queue.length - 1) {
					return;
				}

				// Get current index and increase i
				const index: number = i++;

				currentRequests++;

				// Keep same index as of the passed queue array
				result[index] = await statusApi
					.retrieveDoorAccess(door.DoorID)
					.then(response => {
						const doorAccess: DoorAccess = {
							Granted: response.Granted,
							Denied: response.Denied,
						};
						this.setDoorAccess(door.DoorID, doorAccess);
					})
					.catch(e => Logger.writeErrorLog(e));

				currentRequests--;

				// Set value for current queue processed
				const cloneState: number[] = [...this.state.queueToBeProcessed];
				const findIndex: number = cloneState.findIndex(x => x === queue[index]);
				if (~findIndex) {
					cloneState.splice(findIndex, 1);
					this.setState({ queueToBeProcessed: [...cloneState] });
				}
			}, 100);
		});
	}

	private autoRefreshPerformanceLoop: null | ReturnType<typeof setTimeout> = null;

	fetchData() {
		const sessionStorageKey = constants.sessionStorage.statusViewer.RETRIEVE_DOOR_STATUS_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');

			statusApi
				.retrieveDoorsStatus()
				.then(response => {
					window.sessionStorage.setItem(sessionStorageKey, '0');

					const expandedRows: DoorAccessRecord[] = [...this.state.expandedRows];
					let dataWithAccess: DoorStatusData[] = [];
					let expandedRowsPromises = [];

					if (expandedRows?.length !== 0) {
						response.forEach((doorData: DoorStatusData) => {
							const storedRow: DoorAccessRecord = expandedRows.find((doorAccess: DoorAccessRecord) => doorAccess.DoorID === doorData.DoorID);
							if (storedRow) {
								expandedRowsPromises.push(
									new Promise(resolve => {
										statusApi
											.retrieveDoorAccess(storedRow.DoorID)
											.then(response => {
												resolve({ Granted: response.Granted, Denied: response.Denied, DoorID: doorData.DoorID });
											})
											.catch(e => Logger.writeErrorLog(e));
									})
								);
							}
							dataWithAccess.push(doorData);
						});
					} else {
						dataWithAccess = response;
					}

					Promise.all(expandedRowsPromises)
						.then(response => {
							response.forEach(accessData => {
								const storedRow: number = dataWithAccess.findIndex(
									(storedDoorData: DoorStatusData) => storedDoorData.DoorID === accessData.DoorID
								);
								if (~storedRow) {
									dataWithAccess[storedRow] = {
										...dataWithAccess[storedRow],
										DoorAccess: { Granted: accessData.Granted, Denied: accessData.Denied },
									};
								}
							});
						})
						.catch(e => Logger.writeErrorLog(e))
						.finally(() => {
							try {
								this.setState({
									isLoading: false,
									data: dataWithAccess,
								});
							} catch (error) {
								Logger.writeErrorLog(error);
							}
						});
				})
				.catch(e => Logger.writeErrorLog(e));
		}
	}

	setDoorAccess(doorId: number, doorAccess?: DoorAccess) {
		let retValue: DoorStatusData[] = [];
		const cloneState: DoorStatusData[] = [...this.state.data];
		cloneState.forEach((doorData: DoorStatusData) => {
			if (doorData.DoorID === doorId) {
				doorData.DoorAccess = doorAccess;
			}

			retValue.push(doorData);
		});

		this.setState({
			data: retValue,
		});
	}

	private handleExpandRow = (expanded: boolean, record: DoorStatusData) => {
		if (expanded) {
			this.setState(
				prevState => {
					return {
						...prevState,
						queueToBeProcessed: [...prevState.queueToBeProcessed, record.DoorID],
						expandedRows: [
							...prevState.expandedRows,
							{ DoorID: record.DoorID, DoorAddress: record.Address, DoorControllerId: record.ControllerId },
						],
					};
				},
				() => this.queueFetcher(record)
			);
		} else {
			this.removeQueuedRow(record.DoorID);
		}
	};

	removeQueuedRow(doorId: number) {
		const cloneQueueToBeProcessedState = [...this.state.queueToBeProcessed];
		const cloneExpandedRowState = [...this.state.expandedRows];
		const removeToBeProcessedIdx = cloneQueueToBeProcessedState.findIndex(x => x === doorId);
		const removeExpandedRowIdx = cloneExpandedRowState.findIndex(x => x.DoorID === doorId);
		if (~removeToBeProcessedIdx) {
			cloneQueueToBeProcessedState.splice(removeToBeProcessedIdx, 1);
		}
		if (~removeExpandedRowIdx) {
			cloneExpandedRowState.splice(removeExpandedRowIdx, 1);
		}
		this.setState({
			queueToBeProcessed: [...cloneQueueToBeProcessedState],
			expandedRows: [...cloneExpandedRowState],
		});
	}

	render() {
		const { isLoading, data, queueToBeProcessed } = this.state;

		let content;
		if (!isLoading) {
			let tableData;
			if (data && data.length) {
				tableData = data.map((door, index) => {
					return {
						key: index,
						...door,
					};
				});
			}

			content = (
				<Table
					id='DoorsStatusTable'
					columns={columns as ColumnsType}
					dataSource={tableData}
					scroll={scroll}
					size='small'
					pagination={pagination}
					expandable={{
						onExpand: this.handleExpandRow,
						expandedRowRender: (record: DoorStatusData) => {
							const granted = <span className='status-widget-doorstatus-granted'>{record.DoorAccess?.Granted ?? 0}</span>;
							const denied = <span className='status-widget-doorstatus-denied'>{record.DoorAccess?.Denied ?? 0}</span>;

							return (
								<Spin
									tip={`${_('Loading')}...`}
									spinning={queueToBeProcessed.some(x => x === record.DoorID)}
									size='default'
									className='spin-container'>
									<div className='status-widget-doorstatus-nested'>
										{_('TodayAccessGrants')}: {granted} {_('TodayAccessDenied')}: {denied}
									</div>
								</Spin>
							);
						},
						rowExpandable: record => true,
					}}
					className={'status-widget-table status-widget-table-empty'}
				/>
			);
		}

		return (
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className='spin-container'>
				<div className='door-status-table-container'>{content}</div>
			</Spin>
		);
	}
}

export { DoorStatusTable };

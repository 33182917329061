import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useContext, useEffect, useState } from 'react';
import { PortConnectionType, PortNetWorkType, PortProtocolType } from '../../../../../../../model/DeviceAdminModel';
import { PortStoreContext, setPortChangedAction, setPortNetworkType, setProtocol, setResetEncryption } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {};

const radioStyle = {
	display: 'block',
	height: '30px',
	lineHeight: '30px',
};

const NetworkSelector: React.FC<Props> = () => {
	const [disableSerialNetwork, setDisableSerialNetwork] = useState<boolean>(false);
	const {
		portState: { portNetworkType, protocol, comPorts, hasPortChanged, id, defaultConnectionType },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		validateCOMPorts();
	}, [comPorts]);

	useEffect(() => {}, [protocol]);

	const isXNETDisabled = portNetworkType > PortNetWorkType.IPv4 ? true : false;
	const isIPv6Disabled = defaultConnectionType === PortConnectionType.SNET;

	const validateCOMPorts = () => {
		if (!comPorts.length) {
			setDisableSerialNetwork(true);
		} else {
			setDisableSerialNetwork(false);
			id === 0 ? dispatcher(setPortNetworkType(1)) : null;
		}
	};

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleOnChangeNetworkType = (e: RadioChangeEvent) => {
		const newNetworkType = e.target.value;
		if (newNetworkType > PortNetWorkType.IPv4 && protocol == PortProtocolType.XNET) {
			dispatcher(setProtocol(3));
			validateEnabledResetEncryption(3);
		} else if (newNetworkType === PortNetWorkType.Serial) {
			dispatcher(setProtocol(2));
			validateEnabledResetEncryption(2);
		}

		dispatcher(setPortNetworkType(newNetworkType));
		checkIfPortChanged();
	};

	const validateEnabledResetEncryption = protocol => {
		if (id === 0) {
			protocol === PortProtocolType.XNET ? dispatcher(setResetEncryption(false)) : dispatcher(setResetEncryption(true));
		}

		if (protocol === PortProtocolType.XNET) {
			dispatcher(setResetEncryption(false));
		}
	};

	const handleOnChangeProtocol = (e: RadioChangeEvent) => {
		const newProtocol = e.target.value;
		dispatcher(setProtocol(newProtocol));
		checkIfPortChanged();
		validateEnabledResetEncryption(newProtocol);
	};

	return (
		<div className={styles.options}>
			<div className={styles.networkTypeContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('NetworkType')}</legend>
					<div className={styles.radioButton}>
						<Radio.Group id='portGeneralNetworkTypeRadioButton' onChange={handleOnChangeNetworkType} value={portNetworkType}>
							<Radio style={radioStyle} value={1} disabled={disableSerialNetwork}>
								{_('Serial')}
							</Radio>
							<Radio style={radioStyle} value={2}>
								{_('IPv4')}
							</Radio>
							<Radio style={radioStyle} value={3} disabled={isIPv6Disabled}>
								{_('IPv6')}
							</Radio>
						</Radio.Group>
					</div>
				</fieldset>
			</div>
			<div className={styles.networkTypeContainer}>
				<fieldset>
					<legend className={styles.legend}>{_('Protocol')}</legend>
					<div className={styles.radioButton}>
						<Radio.Group id='portGeneralProtocolTypeRadioButton' onChange={handleOnChangeProtocol} value={protocol}>
							{defaultConnectionType === PortConnectionType.SNET ? (
								<Radio style={radioStyle} value={1}>
									{_('SNET')}
								</Radio>
							) : (
								<>
									<Radio style={radioStyle} value={2} disabled={isXNETDisabled}>
										{_('XNET')}
									</Radio>
									<Radio style={radioStyle} value={3}>
										{_('XNET2')}
									</Radio>
									<Radio style={radioStyle} value={4}>
										{_('XNET3')}
									</Radio>
								</>
							)}
						</Radio.Group>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export { NetworkSelector };

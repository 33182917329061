import { CheckCircleFilled, ExclamationCircleFilled, UpOutlined } from '@ant-design/icons';
import { Button, Collapse, Spin, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import { ColumnsProps, ScrollType, buildColumn, getDefaultTablePaginationConfig } from '../../../Helper';
import { statusApi } from '../../../api';
import constants from '../../../constants';
import { PaginationSetting, SortDirections } from '../../../model/CommonModel';
import { Logger } from '../../../model/LoggingModel';
import { PhotoCallUp } from '../../../model/PhotoCallUpModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { selectPhotoCallCredentials } from '../../../store/common/selectors';
import { openEnrollmentUserInfo } from '../../../store/photocallup/actions';
import { UserInfo } from '../../photocallup';
import styles from './accessSummary.module.scss';

const { Panel } = Collapse;

type Props = {};

type PhotoCallUpDataSource = {
	key: React.Key;
} & PhotoCallUp;

//Avoid creating object style inline, since increases reconciliations
const allowedStyle: React.CSSProperties = { color: 'green', fontSize: '16px' };
const deniedStyle: React.CSSProperties = { color: 'red' };
const scroll: ScrollType = { x: 865, y: 215 };
const DEFAULT_SORT_FIELD: string = 'OffsetDtDate';
const defaultTablePaginationConfig = getDefaultTablePaginationConfig();

const AccessSummary: React.FC<Props> = () => {
	const dispatch = useStoreDispatch();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hostTime, setHostTime] = useState<Date>(undefined);
	const [tableData, setTableData] = useState<PhotoCallUpDataSource[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(defaultTablePaginationConfig.defaultCurrent);
	const [currentPageSize, setCurrentPageSize] = useState<number>(defaultTablePaginationConfig.defaultPageSize);
	const [currentSortDirection, setCurrentSortDirection] = useState<SortDirections>(SortDirections.None);
	const [currentSortField, setCurrentSortField] = useState<string>(DEFAULT_SORT_FIELD);
	const [totalItemsPagination, setTotalItemsPagination] = useState<number>(0);
	const [totalAccessDenied, setTotalAccessDenied] = useState<number>(0);
	const [totalAccessGranted, setTotalAccessGranted] = useState<number>(0);
	const photoCallCredentials: PhotoCallUp[] = useStoreSelector<PhotoCallUp[]>(selectPhotoCallCredentials);

	useEffect(() => {
		//We use this flag to avoid multiple api calls at the same time
		window.sessionStorage.setItem(constants.sessionStorage.statusDashboard.RETRIEVE_ACCESS_SUMMARY_FLAG, '0');
		window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_ENROLLMENT_USER_INFO_FLAG, '0');

		const paginationSetting: PaginationSetting = {
			PageNumber: currentPage,
			PageSize: currentPageSize,
			SortDirection: currentSortDirection,
			SortField: currentSortField,
		};

		fetchData(paginationSetting);
	}, []);

	useEffect(() => {
		if (photoCallCredentials && photoCallCredentials.length) {
			const newTotalItemsPagination: number = totalItemsPagination + photoCallCredentials.length;

			let currentField = DEFAULT_SORT_FIELD;
			let dateField: boolean = true;
			if (currentSortDirection !== SortDirections.None) {
				if (currentSortField === 'OffsetDtDate') {
					currentField = 'OffsetHardwareTime';
				} else if (currentSortField === 'PCDateTime') {
					currentField = 'HostTime';
				} else {
					dateField = false;
					currentField = 'DoorAccess';
				}
			}

			let clonedDataSource: PhotoCallUp[] = getSortedDataItems([...photoCallCredentials, ...tableData], currentField, dateField);
			const maxPageNumber: number = Math.ceil(clonedDataSource.length / currentPageSize);
			const totalPages: number = Math.ceil(newTotalItemsPagination / currentPageSize);
			const isLastPage: boolean = currentPage > 1 && currentPage === totalPages;
			let hardRefresh: boolean = true;
			if (maxPageNumber === 2 || isLastPage) {
				for (const photoCallUpCredential of photoCallCredentials) {
					if (clonedDataSource.length === currentPageSize) {
						break;
					}

					if (currentPage !== 1 || isLastPage) {
						const indexAfterSort: number = clonedDataSource.findIndex(item => item.LogId === photoCallUpCredential.LogId);
						if (indexAfterSort === 0) {
							if (isLastPage) {
								refreshCurrentPage();
								hardRefresh = false;
								break;
							}
							clonedDataSource.shift();
						} else if (!isLastPage) {
							clonedDataSource.pop();
						}
					} else {
						clonedDataSource.pop();
					}
				}
			}

			if (hardRefresh) {
				let accessDenied = 0;
				let accessGranted = 0;
				photoCallCredentials.map(p => {
					if (p.AccessGranted) {
						accessGranted++;
					} else {
						accessDenied++;
					}
				});
				batch(() => {
					setTotalAccessDenied(totalAccessDenied + accessDenied);
					setTotalAccessGranted(totalAccessGranted + accessGranted);
					setTotalItemsPagination(newTotalItemsPagination);
					setTableData(createTableData(clonedDataSource));
				});
			}
		}
	}, [photoCallCredentials]);

	const refreshCurrentPage = (): void => {
		const paginationSetting: PaginationSetting = {
			PageNumber: currentPage,
			PageSize: currentPageSize,
			SortDirection: currentSortDirection,
			SortField: currentSortField,
		};

		fetchData(paginationSetting);
	};

	const getSortedDataItems = (dataSource: PhotoCallUp[], currentField: string, dateField: boolean): PhotoCallUp[] => {
		return dataSource.sort((a: PhotoCallUp, b: PhotoCallUp) => {
			if (dateField) {
				if (currentSortDirection === SortDirections.Ascend) {
					return new Date(a[currentField]).getTime() - new Date(b[currentField]).getTime();
				} else {
					return new Date(b[currentField]).getTime() - new Date(a[currentField]).getTime();
				}
			} else {
				if (currentSortDirection === SortDirections.Ascend) {
					return a[currentField].toLocalCompare(b[currentField]);
				} else {
					return b[currentField].toLocalCompare(a[currentField]);
				}
			}
		});
	};

	const createTableData = (photoCallUps: PhotoCallUp[]): PhotoCallUpDataSource[] => {
		return photoCallUps.map<PhotoCallUpDataSource>(x => ({
			...x,
			key: x.LogId.toString(),
		}));
	};

	const fetchData = (paginationSetting: PaginationSetting) => {
		const sessionStorageKey = constants.sessionStorage.statusDashboard.RETRIEVE_ACCESS_SUMMARY_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');
			setIsLoading(true);
			statusApi
				.retrieveAccessSummary(paginationSetting)
				.then(response => {
					window.sessionStorage.setItem(sessionStorageKey, '0');
					batch(() => {
						setHostTime(response.HostTime);
						setTotalItemsPagination(response.TotalRecords);
						setTotalAccessDenied(response.TotalAccessDenied);
						setTotalAccessGranted(response.TotalAccessGranted);
						setTableData(createTableData(response.PhotoCallUps));
					});
				})
				.catch(e => Logger.writeErrorLog(e))
				.finally(() => setIsLoading(false));
		}
	};

	const columns: ColumnsProps<PhotoCallUp>[] = [
		{
			...buildColumn(_('HostTime'), 'PCDateTime', 'minmax(11em, 13em)', 'left'),
			sorter: true,
			render: (_, row) => {
				return {
					children: <span>{FormatDate(row.HostTime, true, '', false, false)}</span>,
				};
			},
		},
		{
			...buildColumn(_('ControllerTime'), 'OffsetDtDate', 'minmax(11em, 13em)', 'left'),
			sorter: true,
			render: (_, row) => {
				return {
					children: <span>{FormatDate(row.HardwareTime, true, '', false, false)}</span>,
				};
			},
		},
		{
			...buildColumn('', '', '36px', 'left'),
			render: (_, row) => {
				return {
					children: row.AccessGranted ? (
						<CheckCircleFilled style={allowedStyle} data-log-id={row.LogId} data-event-id={row.EventId} />
					) : (
						<ExclamationCircleFilled style={deniedStyle} data-log-id={row.LogId} data-event-id={row.EventId} />
					),
				};
			},
		},
		{
			...buildColumn(_('Location'), 'DoorReaderName', '30%', 'left'),
			sorter: true,
			render: (_, row) => {
				return {
					children: <span>{row.DoorAccess}</span>,
				};
			},
		},
		{
			...buildColumn(_('PersonOperator'), 'PersonFullName', '27%', 'left'),
			render: (_, row) => {
				const children = row.UsersInformation.map((user, index) => {
					const key = `${row.LogId}-${user.PersonId}-${user.CredentialID}`;
					const handleOnClick = () => {
						const sessionStorageKey = constants.sessionStorage.photoCallUp.RETRIEVE_ENROLLMENT_USER_INFO_FLAG;
						const currentUserInfo = window.sessionStorage.getItem(sessionStorageKey) === '1';
						if (currentUserInfo || user.PersonId < 1) {
							return;
						}
						window.sessionStorage.setItem(sessionStorageKey, '1');
						dispatch(openEnrollmentUserInfo(user.PersonId, user.CredentialID, row.LogId));
					};

					return (
						<React.Fragment key={key}>
							{user.PersonId > 0 ? (
								<Button type='link' onClick={handleOnClick} className={styles.accessSummaryPersonLink}>
									<span>{user.PersonFullName}</span>
								</Button>
							) : (
								<span className={styles.accessSummaryPersonSpan}>{user.PersonFullName}</span>
							)}
						</React.Fragment>
					);
				});

				return {
					children: <div className={styles.statusGrid}>{children}</div>,
				};
			},
		},
	];

	const totalGranted = (
		<span id='statusDashboardAccessSummaryTotalAccessGrantsSpan' className={styles.statusWidgetAccessSummaryGranted}>
			{totalAccessGranted}
		</span>
	);

	const totalDenied = (
		<span id='statusDashboardAccessSummaryTotalAccessDeniedSpan' className={styles.statusWidgetAccessSummaryDenied}>
			{totalAccessDenied}
		</span>
	);

	const grantedDeniedFooter = (
		<div id='statusDashboardAccessSummaryFooter' className={styles.statusWidgetAccessSummaryTotal}>
			{_('TotalAccessGrants')}: {totalGranted} | {_('TotalAccessDenied')}: {totalDenied}
		</div>
	);

	const handleOnChangeTable = (pagination, filters, sorter) => {
		const { order, field } = sorter;
		const { current, pageSize } = pagination;
		let sortDirection = SortDirections.None;
		if (order) {
			sortDirection = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		batch(() => {
			setCurrentSortDirection(sortDirection);
			setCurrentSortField(field);
			handleChangePagination(current, pageSize);
		});

		const paginationSetting: PaginationSetting = {
			PageNumber: current,
			PageSize: pageSize,
			SortDirection: sortDirection,
			SortField: field,
		};

		fetchData(paginationSetting);
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		batch(() => {
			if (pageSize !== currentPageSize) {
				setCurrentPageSize(pageSize);
			}
			if (currentPage !== page) {
				setCurrentPage(page);
			}
		});
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangePagination,
		currentPage,
		currentPageSize,
		totalItemsPagination,
		undefined,
		undefined
	);

	let date: string = _('Today');
	if (hostTime) {
		date = `${date} ${FormatDate(hostTime, false, null, true)}`;
	}
	const HeaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{`${_('AccessSummary')} (${date})`}</span>);

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
			<div className={styles.accessSummaryContainer}>
				<Collapse
					bordered={true}
					defaultActiveKey={'1'}
					expandIconPosition='right'
					expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
					className={styles.statusWidgetCollapse}>
					<Panel
						id='statusDashboardAccessSummaryWidget'
						key='1'
						header={<HeaderWidget />}
						className={cx(styles.statusWidget, styles.statusWidgetCollapseContent)}>
						<Table
							id='AccessGrantTable'
							pagination={pagination}
							onChange={handleOnChangeTable}
							columns={columns}
							dataSource={tableData}
							scroll={scroll}
							size='small'
							className={cx(styles.statusWidgetTable, styles.statusWidgetAccessSummaryStatus, styles.statusWidgetAccessSummaryEmpty)}
							footer={() => {
								return grantedDeniedFooter;
							}}
						/>
					</Panel>
				</Collapse>
				<UserInfo />
			</div>
		</Spin>
	);
};

export { AccessSummary };

import { List } from 'antd';
import { ListItemLayout } from 'antd/lib/list';
import React, { ReactNode } from 'react';
import styles from './informationScrollList.module.scss';

type Props = {
	information: string[] | ReactNode[];
	className?: string;
	layout?: ListItemLayout;
};

const InformationScrollList: React.FC<Props> = ({ information, className, layout }) => {
	return (
		<List
			itemLayout={layout || 'horizontal'}
			dataSource={information}
			className={className || styles.overFlow}
			renderItem={item => <List.Item>{item}</List.Item>}
		/>
	);
};

export { InformationScrollList };

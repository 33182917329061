import React from 'react';
import {
	ControllerBaseData,
	ControllerPortData,
	ControllerType,
	ControllerXBoxData,
	SelectOption,
	SystemTimeZones,
} from '../../../../../model/DeviceAdminModel';

type ControllerState = {
	name: string;
	description: string;
	availableAddresses: SelectOption[];
	loadSetupsFromController: boolean;
	wirelessVendorList: SelectOption[];
	enabled: boolean;
	useDaylightSavingsTime: boolean;
	ports: ControllerPortData[];
	xBoxes: ControllerXBoxData[];
	systemTimeZones: SystemTimeZones[];
	controllerTypeList: SelectOption[];
	selections: {
		portId: number;
		xBoxId: number;
		controllerType: ControllerType;
		address: number;
		wirelessVendor: number;
		timeZoneDisplayName: string;
		timeZoneStandardName: string;
	};
};

enum ControllerActionTypes {
	SET_PORTS = 'SET_PORTS',
	SET_PORT = 'SET_PORT',
	SET_XBOXES = 'SET_XBOXES',
	SET_XBOX = 'SET_XBOX',
	SET_NAME = 'SET_NAME',
	SET_DESCRIPTION = 'SET_DESCRIPTION',
	SET_CONTROLLER_TYPE = 'SET_CONTROLLER_TYPE',
	SET_AVAILABLE_ADDRESS = 'SET_AVAILABLE_ADDRESS',
	SET_ADDRESS = 'SET_ADDRESS',
	SET_WIRELESS_VENDOR = 'SET_WIRELESS_VENDOR',
	SET_ENABLED = 'SET_ENABLED',
	SET_TIME_ZONE_STANDARD_NAME = 'SET_TIME_ZONE_STANDARD_NAME',
	SET_TIME_ZONE_DISPLAY_NAME = 'SET_TIME_ZONE_DISPLAY_NAME',
	SET_USE_DAYLIGHT_SAVINGS_TIME = 'SET_USE_DAYLIGHT_SAVINGS_TIME',
	INIT_ADD_CONTROLLER = 'INIT_ADD_CONTROLLER',
}

type controllerInitAddController = {
	type: ControllerActionTypes.INIT_ADD_CONTROLLER;
	payload: Partial<ControllerBaseData>;
};
type controllerSetPorts = {
	type: ControllerActionTypes.SET_PORTS;
	payload: ControllerPortData[];
};
type controllerSetPort = {
	type: ControllerActionTypes.SET_PORT;
	payload: number;
};
type controllerSetName = {
	type: ControllerActionTypes.SET_NAME;
	payload: string;
};
type controllerSetXBoxes = {
	type: ControllerActionTypes.SET_XBOXES;
	payload: ControllerXBoxData[];
};
type controllerSetXBox = {
	type: ControllerActionTypes.SET_XBOX;
	payload: number;
};
type controllerSetDescription = {
	type: ControllerActionTypes.SET_DESCRIPTION;
	payload: string;
};
type controllerSetControllerType = {
	type: ControllerActionTypes.SET_CONTROLLER_TYPE;
	payload: number;
};
type controllerSetAddress = {
	type: ControllerActionTypes.SET_ADDRESS;
	payload: number;
};
type controllerSetAvailableAddress = {
	type: ControllerActionTypes.SET_AVAILABLE_ADDRESS;
	payload: SelectOption[];
};
type controllerSetWirelessVendor = {
	type: ControllerActionTypes.SET_WIRELESS_VENDOR;
	payload: number;
};
type controllerSetEnabled = {
	type: ControllerActionTypes.SET_ENABLED;
	payload: boolean;
};
type controllerSetTimeZoneStandardName = {
	type: ControllerActionTypes.SET_TIME_ZONE_STANDARD_NAME;
	payload: string;
};
type controllerSetTimeZoneDisplayName = {
	type: ControllerActionTypes.SET_TIME_ZONE_DISPLAY_NAME;
	payload: string;
};
type controllerSetUseDaylightSavingsTime = {
	type: ControllerActionTypes.SET_USE_DAYLIGHT_SAVINGS_TIME;
	payload: boolean;
};

type ControllerActionContext =
	| controllerInitAddController
	| controllerSetPorts
	| controllerSetPort
	| controllerSetName
	| controllerSetXBoxes
	| controllerSetDescription
	| controllerSetControllerType
	| controllerSetAddress
	| controllerSetWirelessVendor
	| controllerSetEnabled
	| controllerSetTimeZoneStandardName
	| controllerSetAvailableAddress
	| controllerSetXBox
	| controllerSetTimeZoneDisplayName
	| controllerSetUseDaylightSavingsTime;

const addControllerState: ControllerState = {
	name: '',
	description: '',
	availableAddresses: [],
	wirelessVendorList: [],
	loadSetupsFromController: false,
	enabled: true,
	useDaylightSavingsTime: true,
	ports: [],
	xBoxes: [],
	systemTimeZones: [],
	controllerTypeList: [],
	selections: {
		portId: 0,
		xBoxId: 0,
		controllerType: ControllerType.M8,
		address: 0,
		wirelessVendor: 0,
		timeZoneDisplayName: '',
		timeZoneStandardName: '',
	},
};

const addControllerContext = (state: Readonly<ControllerState>, action: ControllerActionContext): ControllerState => {
	switch (action.type) {
		case ControllerActionTypes.INIT_ADD_CONTROLLER:
			return {
				...state,
				systemTimeZones: action.payload.SystemTimeZones,
				wirelessVendorList: action.payload.WirelessReaderTypeList,
				ports: action.payload.Ports,
				controllerTypeList: action.payload.ControllerTypeList,
				selections: {
					...state.selections,
					timeZoneDisplayName: action.payload.TimeZoneDisplayName,
					timeZoneStandardName: action.payload.TimeZoneStandardName,
				},
			};

		case ControllerActionTypes.SET_PORTS:
			return { ...state, ports: action.payload };

		case ControllerActionTypes.SET_XBOXES:
			return { ...state, xBoxes: action.payload };

		case ControllerActionTypes.SET_NAME:
			return { ...state, name: action.payload };

		case ControllerActionTypes.SET_DESCRIPTION:
			return { ...state, description: action.payload };

		case ControllerActionTypes.SET_ENABLED:
			return { ...state, enabled: action.payload };

		case ControllerActionTypes.SET_USE_DAYLIGHT_SAVINGS_TIME:
			return { ...state, useDaylightSavingsTime: action.payload };

		case ControllerActionTypes.SET_AVAILABLE_ADDRESS:
			return { ...state, availableAddresses: action.payload };

		case ControllerActionTypes.SET_PORT:
			return { ...state, selections: { ...state.selections, portId: action.payload } };

		case ControllerActionTypes.SET_XBOX:
			return { ...state, selections: { ...state.selections, xBoxId: action.payload } };

		case ControllerActionTypes.SET_ADDRESS:
			return { ...state, selections: { ...state.selections, address: action.payload } };

		case ControllerActionTypes.SET_CONTROLLER_TYPE:
			return { ...state, selections: { ...state.selections, controllerType: action.payload } };

		case ControllerActionTypes.SET_TIME_ZONE_STANDARD_NAME:
			return { ...state, selections: { ...state.selections, timeZoneStandardName: action.payload } };

		case ControllerActionTypes.SET_TIME_ZONE_DISPLAY_NAME:
			return { ...state, selections: { ...state.selections, timeZoneDisplayName: action.payload } };

		case ControllerActionTypes.SET_WIRELESS_VENDOR:
			return { ...state, selections: { ...state.selections, wirelessVendor: action.payload } };

		default:
			return { ...state };
	}
};

const initAddController = (payload: Partial<ControllerBaseData>): controllerInitAddController => {
	return {
		type: ControllerActionTypes.INIT_ADD_CONTROLLER,
		payload,
	};
};

const setPorts = (payload: ControllerPortData[]): controllerSetPorts => {
	return {
		type: ControllerActionTypes.SET_PORTS,
		payload,
	};
};

const setPort = (payload: number): controllerSetPort => {
	return {
		type: ControllerActionTypes.SET_PORT,
		payload,
	};
};

const setXBoxes = (payload: ControllerXBoxData[]): controllerSetXBoxes => {
	return {
		type: ControllerActionTypes.SET_XBOXES,
		payload,
	};
};

const setXBox = (payload: number): controllerSetXBox => {
	return {
		type: ControllerActionTypes.SET_XBOX,
		payload,
	};
};

const setName = (payload: string): controllerSetName => {
	return {
		type: ControllerActionTypes.SET_NAME,
		payload,
	};
};

const setDescription = (payload: string): controllerSetDescription => {
	return {
		type: ControllerActionTypes.SET_DESCRIPTION,
		payload,
	};
};

const setAddress = (payload: number): controllerSetAddress => {
	return {
		type: ControllerActionTypes.SET_ADDRESS,
		payload,
	};
};

const setAvailableAddress = (payload: SelectOption[]): controllerSetAvailableAddress => {
	return {
		type: ControllerActionTypes.SET_AVAILABLE_ADDRESS,
		payload,
	};
};

const setControllerType = (payload: number): controllerSetControllerType => {
	return {
		type: ControllerActionTypes.SET_CONTROLLER_TYPE,
		payload,
	};
};

const setWirelessVendor = (payload: number): controllerSetWirelessVendor => {
	return {
		type: ControllerActionTypes.SET_WIRELESS_VENDOR,
		payload,
	};
};

const setStandardTimeZone = (payload: string): controllerSetTimeZoneStandardName => {
	return {
		type: ControllerActionTypes.SET_TIME_ZONE_STANDARD_NAME,
		payload,
	};
};

const setDisplayTimeZone = (payload: string): controllerSetTimeZoneDisplayName => {
	return {
		type: ControllerActionTypes.SET_TIME_ZONE_DISPLAY_NAME,
		payload,
	};
};

const setEnable = (payload: boolean): controllerSetEnabled => {
	return {
		type: ControllerActionTypes.SET_ENABLED,
		payload,
	};
};

const setUseDST = (payload: boolean): controllerSetUseDaylightSavingsTime => {
	return {
		type: ControllerActionTypes.SET_USE_DAYLIGHT_SAVINGS_TIME,
		payload,
	};
};

type AddControllerContext = {
	addControllerState: Readonly<ControllerState>;
	dispatcher: React.Dispatch<ControllerActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const StoreContext = React.createContext<AddControllerContext>({ addControllerState, dispatcher: null });

export {
	addControllerState,
	setEnable,
	setWirelessVendor,
	setDisplayTimeZone,
	setAvailableAddress,
	initAddController,
	setStandardTimeZone,
	setUseDST,
	setDescription,
	setControllerType,
	setAddress,
	setXBoxes,
	setXBox,
	setPort,
	setName,
	setPorts,
	StoreContext,
	addControllerContext,
};

import React, { useContext } from 'react';
import { ReaderOptionsTab } from '../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderAnyReaderOptions, setExitReaderCardReaderOptions, setExitReaderScramblePadKeyOptions } from '../../exitReaderContext';
import { OptionsCheckBoxList } from '../sections';
import styles from './options.module.scss';

const Options: React.FC = () => {
	var {
		contextStateDoor: {
			exitReader: { anyReaderOptions, cardReaderOptions, scramblePadKeyPadOptions },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleOnSetAnyReaderOptions = (array: ReaderOptionsTab[]) => {
		dispatcherDoor(setExitReaderAnyReaderOptions(array));
	};

	const handleOnSetCardReaderOptions = (array: ReaderOptionsTab[]) => {
		dispatcherDoor(setExitReaderCardReaderOptions(array));
	};

	const handleOnSetScramblePadKeyOptions = (array: ReaderOptionsTab[]) => {
		dispatcherDoor(setExitReaderScramblePadKeyOptions(array));
	};

	return (
		<div className={styles.form}>
			<OptionsCheckBoxList className={styles.anyReader} label={_('AnyReader')} collection={anyReaderOptions} setArray={handleOnSetAnyReaderOptions} />
			<OptionsCheckBoxList className={styles.cardReader} label={_('CardReader')} collection={cardReaderOptions} setArray={handleOnSetCardReaderOptions} />
			<OptionsCheckBoxList
				className={styles.scrambleKeypad}
				label={_('ScrambleKeypad')}
				collection={scramblePadKeyPadOptions}
				setArray={handleOnSetScramblePadKeyOptions}
			/>
		</div>
	);
};

export { Options };

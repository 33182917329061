import { UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { EventTable } from '../../event';
import './eventTableWidget.scss';

const { Panel } = Collapse;

// Normal properties for the component
interface OwnProps {}

// combine them together
type Props = OwnProps;

const HeaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{_('Events')}</span>);

const EventTableWidget: React.VFC = (props: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);

	let content: React.ReactElement = <></>;
	if (isExpanded) {
		content = <EventTable />;
	}

	return (
		<div className='event-viewer-widget-container'>
			<Collapse
				bordered={true}
				defaultActiveKey={'1'}
				expandIconPosition='right'
				expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
				onChange={(keys: string[]) => {
					setIsExpanded(keys.length === 1);
				}}
				className={'status-widget-collapse'}>
				<Panel id='statusDashboardEventTableWidget' key='1' header={<HeaderWidget />} className='status-widget status-widget-collapse-content'>
					{content}
				</Panel>
			</Collapse>
		</div>
	);
};

export { EventTableWidget };

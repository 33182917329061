import { Card } from 'antd';
import React from 'react';
import styles from './cardSection.module.scss';

type Props = {
	title?: string;
	height?: number | string;
	width?: number | string;
};

const CardSection: React.FC<Props> = ({ title, height, width, children }) => {
	const style: React.CSSProperties = { height, width };

	return (
		<Card className={styles.cardSection} style={style}>
			{title && <p className={styles.titleCard}>{title}</p>}
			{children}
		</Card>
	);
};

export { CardSection };

import React from 'react';
import { RelayLogicTimeZones } from '../../../../../../model/DeviceAdminModel';
import styles from '../../expansionRelayModal.module.scss';
import { ControlZones, TimeZones } from '../sections';

type Props = {
	setPreviousSelections: (RelayLogicTimeZones) => void;
	previousSelections: RelayLogicTimeZones;
	useDoorContext: boolean;
};

const Logic: React.FC<Props> = props => {
	const { setPreviousSelections, previousSelections, useDoorContext } = props;
	return (
		<div id='logicRelayContainer' className={styles.form}>
			<TimeZones setPreviousSelections={setPreviousSelections} previousSelections={previousSelections} useDoorContext={useDoorContext}></TimeZones>
			<ControlZones useDoorContext={useDoorContext}></ControlZones>
		</div>
	);
};

export { Logic };

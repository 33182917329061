import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Form, Radio, RadioChangeEvent, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { ColumnsProps, buildActionColumn, buildColumn, handleResponse } from '../../../../../../Helper';
import { accountApi, configurationApi } from '../../../../../../api';
import { SubPermissions, User } from '../../../../../../model/AccountModel';
import { BaseColumns, OperatorKeyColumn, ResponseObjectEntity, ResponseStatusCode, SelectOptions } from '../../../../../../model/CommonModel';
import { OperatorModel, OptionsButtonBuilder } from '../../../../../../model/DeviceAdminModel';
import { Logger } from '../../../../../../model/LoggingModel';
import { OperatorKey } from '../../../../../../model/OperatorModel';
import { ButtonDropdown, EditableCell, SearchColumn } from '../../../../../common';
import { RegisterSecurityKeyModal } from '../../../../../common/RegisterSecurityKeyModal/RegisterSecurityKeyModal';
import {
	setOperatorPropAction,
	setSecurityKeyModificationsAction,
	setSecurityKeyTableEditModeAction,
	setTwoFactorTabModificationAction,
	updateSecurityKeyModificationsAction,
} from '../OperatorContext/action';
import { OperatorStoreContext } from '../OperatorContext/context';
import styles from './operatorPropertyTwoFactor.module.scss';

type operatorPropertyTwoFactorProps = {
	operator: OperatorModel;
};

const createButtonOptions = (isMoreThanOneSelected: boolean, isNoneSelected: boolean): OptionsButtonBuilder<string> => {
	return {
		labelOrIcon: '...',
		options: [
			{
				label: _('Rename'),
				disabled: isMoreThanOneSelected || isNoneSelected,
				value: 'rename',
				//title: getPermissionErrorMessage(isUserAdmin),
			},
			{
				label: _('Delete'),
				disabled: isNoneSelected,
				value: 'delete',
				//title: getPermissionErrorMessage(isUserAdmin),
			},
		],
	};
};
const user: User = getUser();
const isUserAdmin: boolean = user.isAdmin;
const TwoFASubPermission = User.getSubComponentPermission(user, SubPermissions.Web_Client_MultiFactor_TwoFactorAdministrator);
const isUser2FAAdmin: boolean = TwoFASubPermission.allowed;
const nonAuthorizedUser: boolean = !isUserAdmin && !isUser2FAAdmin;
const maxLength: number = 50;

const OperatorPropertyTwoFactor: React.FC<operatorPropertyTwoFactorProps> = ({ operator }) => {
	const {
		operatorInitialState: { securityKeyModifications, operator: operatorContext, isSecurityKeyTableInEditMode },
		dispatcher,
	} = useContext(OperatorStoreContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isTableRefreshing, setIsTableRefreshing] = useState<boolean>(false);
	const [status, setStatus] = useState<string>('');
	const [modelKeys, setModelKeys] = useState<OperatorKey[]>([] as OperatorKey[]);
	const [keys, setKeys] = useState<OperatorKeyColumn[]>([] as OperatorKeyColumn[]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([] as React.Key[]);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const isSearchPerformed = useRef<boolean>(false);
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState('');
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [keyUserName, setKeyUserName] = useState<string | null>(null);
	const [keyName, setKeyName] = useState<string | null>(null);
	const [keyId, setKeyId] = useState<string | null>(null);

	useEffect(() => {
		configurationApi
			.retrieveConfiguration()
			.then(response => {
				const {
					EnableTwoFactorAuthentication: { Value },
				} = response;
				const globalSetting: string = Value?.toString();
				if (operator.Id === 0) {
					if (globalSetting === '1') {
						setStatus('1');
					}
					setIsLoading(false);
				} else {
					getKeys(operator.Id).then((keys: OperatorKey[]) => {
						if (keys.length > 0) {
							setStatus(operator.Bypass2FA ? '2' : '1');
						} else {
							setStatus(operator.Bypass2FA ? '2' : globalSetting === '1' ? '1' : '');
						}
						setIsLoading(false);
					});
				}
			})
			.catch(e => Logger.writeErrorLog(e));
	}, []);

	useEffect(() => {
		const mappedKeys = mapKeys(modelKeys);
		setKeys(mappedKeys);
	}, [JSON.stringify(modelKeys)]);

	const getKeys = async (operatorId: number): Promise<OperatorKey[]> => {
		const response: ResponseObjectEntity<OperatorKey[]> = await accountApi.getSecurityKeys(operatorId);
		if (!handleResponse(response)) {
			const { Entity: registeredKeys } = response;
			setModelKeys(registeredKeys);
			return registeredKeys;
		}

		return [] as OperatorKey[];
	};

	const updateTableContext = (action: 'Add' | 'Delete' | 'Rename', securityKey: OperatorKey) => {
		if (securityKeyModifications?.some(km => km.SecurityKey.KeyId === securityKey.KeyId)) {
			dispatcher(
				updateSecurityKeyModificationsAction({
					ModificationAction: action,
					SecurityKey: securityKey,
				})
			);
		} else {
			dispatcher(
				setSecurityKeyModificationsAction({
					ModificationAction: action,
					SecurityKey: securityKey,
				})
			);
		}
	};

	const updateKeysWithNewKey = () => {
		const date: Date = new Date();
		const formattedDate: string = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
		const keyToAdd: OperatorKey = {
			Id: 0,
			Name: keyName,
			RegisterDate: formattedDate,
			KeyId: keyId,
		};

		setModelKeys([...modelKeys, keyToAdd]);
		updateTableContext('Add', keyToAdd);
	};

	const updateKeyWithNewName = (keyId: string, newName: string) => {
		let keyToRename: OperatorKey | null = null;
		setModelKeys(prev =>
			prev.map(p => {
				if (p.KeyId === keyId) {
					keyToRename = { ...p, Name: newName };
					return { ...p, Name: newName, editable: false };
				}
				return p;
			})
		);

		if (keyToRename) {
			updateTableContext(keyToRename.Id > 0 ? 'Rename' : 'Add', keyToRename);
		}
	};

	const updateKeysAfterDelete = (keyIds: string[]) => {
		modelKeys
			.filter(mk => keyIds.some(sk => mk.KeyId === sk))
			.forEach(element => {
				updateTableContext('Delete', element);
			});
		setModelKeys(prev => prev.filter(p => !keyIds.some(sk => p.KeyId === sk)));
	};

	const mapKeys = (data: OperatorKey[]): OperatorKeyColumn[] => {
		if (data?.length > 0) {
			return data.map<OperatorKeyColumn>((d: OperatorKey) => {
				const date: Date = new Date(d.RegisterDate);
				const formattedDate: string = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
				return {
					key: d.KeyId?.length > 0 ? d.KeyId : d.Id.toString(),
					Id: d.Id,
					Name: d.Name,
					Action: createActionElement(d),
					CreatedDate: formattedDate,
				};
			});
		}

		return [] as OperatorKeyColumn[];
	};

	const parseOperatorName = (): string => {
		let operatorName: string = operator.Name;
		if (operatorName.includes('\\')) {
			const strippedName: string = operatorName.replace(/^\\\\/, '');
			const nameParts: string[] = strippedName.split('\\');
			if (nameParts.length > 1) {
				operatorName = nameParts[1].trim();
			}
		}

		return operatorName;
	};

	const isMoreThanOneSelected: boolean = selectedRowKeys?.length > 1;
	const isNoneSelected: boolean = selectedRowKeys ? selectedRowKeys?.length === 0 : true;
	const items = createButtonOptions(isMoreThanOneSelected, isNoneSelected);
	const contextMenuOptions: SelectOptions<string>[] = [...items?.options];

	const clearSelection = () => {
		setSelectedRowKeys([]);
	};

	const handleOnSearch = (searchedValue: string) => {};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};
	const disabled: boolean = editingKey !== '';
	const columns: ColumnsProps<OperatorKeyColumn>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			editable: true,
			sorter: (a, b) => a.Name.localeCompare(b.Name),
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: undefined,
				label: undefined,
				notVisible: undefined,
				resetSearch: shouldResetSearchColumn,
				//setIsFilterMode: setVelocityConfigurationFilterMode,
				clearSelection: clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearch(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
		},
		{
			...buildColumn(_('CreatedDate'), 'CreatedDate', 'auto'),
			editable: false,
		},
	];
	if (isEditMode) {
		columns.unshift(buildActionColumn(disabled));
	}

	const isEditing = (record: OperatorKeyColumn) => record.key.toString() === editingKey;

	const mergedColumns = columns.map(col =>
		!col.editable
			? {
					...col,
					onCell: (record: OperatorKeyColumn) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
					}),
			  }
			: {
					...col,
					onCell: (record: OperatorKeyColumn) => ({
						record,
						dataIndex: col.dataIndex,
						title: col.title,
						maxLength,
						options: contextMenuOptions,
						isTableInEditMode: isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						inputType: 'text',
						editing: isEditing(record),
					}),
			  }
	);

	const onChangeSelection = (key: BaseColumns) => {
		const keyId = key.key.toString();
		if (selectedRowKeys.findIndex(key => key === keyId) === -1) {
			const cloneState: OperatorKey[] = modelKeys.map(x => (x.KeyId === keyId ? { ...x, checked: true } : { ...x, checked: false }));
			batch(() => {
				setModelKeys(cloneState);
				setSelectedRowKeys([keyId]);
			});
		}
	};

	const handleActionScope = async (key: string) => {
		switch (key) {
			case 'rename': {
				setIsEditMode(true);
				const findItem: OperatorKeyColumn = keys.find(k => k.key === selectedRowKeys[0]);
				edit(findItem);
				const selectedKey = modelKeys.find(mk => mk.KeyId === findItem.key.toString());
				const cloneState: OperatorKey[] = changeStateProps(selectedKey.KeyId, { editable: true });
				batch(() => {
					setModelKeys(cloneState);
					dispatcher(setSecurityKeyTableEditModeAction(true));
				});
				break;
			}
			case 'delete': {
				const filteredKeys: OperatorKey[] = modelKeys.filter(mk => selectedRowKeys.some(sr => sr.toString() === mk.KeyId));
				setIsTableRefreshing(true);
				updateKeysAfterDelete(filteredKeys.map<string>(fk => fk.KeyId));
				batch(() => {
					dispatcher(setTwoFactorTabModificationAction(true));
					setIsTableRefreshing(false);
					setSelectedRowKeys([]);
				});
				break;
			}
		}
	};

	const changeStateProps = (keyId: string, keyItem: Partial<OperatorKey>): OperatorKey[] => {
		const cloneState: OperatorKey[] = [...modelKeys];
		const index = cloneState.findIndex(x => x.KeyId === keyId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...keyItem });
		}

		return cloneState;
	};

	const edit = (record: OperatorKeyColumn) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};

	const resetSearchColumn = () => {
		setShouldResetSearchColumn(true);
	};

	const createActionElement = (keyModel: OperatorKey): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (keyModel.editable) {
			const keyId: string = keyModel.KeyId;
			content = (
				<>
					<Button id={`operatorKeysTableActionDropdownSaveButton-${keyId}`} key='save' type='primary' onClick={() => handleOnSaveEditRow(keyId)}>
						{_('OK')}
					</Button>
					<Button id={`operatorKeysTableActionDropdownCancelButton-${keyId}`} key='cancel' onClick={() => handleOnCancelEditRow(keyId)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const verifyKeyName = async (name: string): Promise<ResponseObjectEntity<boolean>> => {
		let response: ResponseObjectEntity<boolean> = {
			ResponseStatusCode: ResponseStatusCode.Success,
			AdditionalResponseInfo: '',
			ResponseErrorDescription: '',
			ErrorMessage: '',
			ResponseObjectId: 0,
			Entity: true,
		};

		if (name?.length === 0) {
			response.Entity = false;
			response.ResponseStatusCode = ResponseStatusCode.FailedValidation;
			response.ErrorMessage = _('PleaseEnterNameForSecurityKey');
		} else {
			if (operator.Id > 0) {
				response = await accountApi.isSecurityKeyNameUnique(operator.Id, name);
			}

			if (response.ResponseStatusCode === ResponseStatusCode.Success) {
				const n: string = name.toLowerCase();
				if (securityKeyModifications?.some(skm => skm.SecurityKey?.Name?.toLowerCase() === n)) {
					response.Entity = false;
					response.ResponseStatusCode = ResponseStatusCode.FailedValidation;
					response.ErrorMessage = _('OperatorAlreadyHasAKeyWithThisName');
				}
			}
		}

		return response;
	};

	const isKeyNameUnique = async (name: string): Promise<boolean> => {
		let isUnique: boolean = true;
		const response: ResponseObjectEntity<boolean> = await verifyKeyName(name);
		if (response.ResponseStatusCode !== ResponseStatusCode.Success) {
			isUnique = response.Entity;
		}
		return isUnique;
	};

	const handleOnSaveEditRow = async (keyId: string) => {
		const name: string = form.getFieldValue('Name');
		if (name === undefined || name?.trim()?.length === 0) {
			form.setFields([
				{
					name: 'Name',
					errors: [_('PleaseEnterNameForSecurityKey')],
				},
			]);
			return;
		}
		const originalName: string = keys.find(k => k.key === selectedRowKeys[0])?.Name;
		if (originalName) {
			if (name.trim().toLowerCase() === originalName.trim().toLowerCase()) {
				onCancelEditRow(keyId);
				return;
			}
		}

		const isUnique: boolean = await isKeyNameUnique(name.trim());
		if (!isUnique) {
			form.setFields([
				{
					name: 'Name',
					errors: [_('OperatorAlreadyHasAKeyWithThisName')],
				},
			]);
			return;
		}

		setEditingKey('');
		resetSearchColumn();
		setIsEditMode(false);
		setIsTableRefreshing(true);
		updateKeyWithNewName(keyId, name.trim());
		batch(() => {
			dispatcher(setSecurityKeyTableEditModeAction(false));
			dispatcher(setTwoFactorTabModificationAction(true));
			setIsTableRefreshing(false);
		});
	};

	const onCancelEditRow = (keyId: string) => {
		setIsEditMode(false);
		const cloneState: OperatorKey[] = changeStateProps(keyId, { editable: false });
		batch(() => {
			setModelKeys(cloneState);
			setEditingKey('');
			dispatcher(setSecurityKeyTableEditModeAction(false));
		});
	};

	const handleOnCancelEditRow = (keyId: string) => {
		onCancelEditRow(keyId);
	};

	const rowSelection: TableRowSelection<OperatorKeyColumn> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		onChange: (selectedRowKeys: React.Key[], selectedRows: OperatorKeyColumn[]) => {
			setSelectedRowKeys(selectedRowKeys);
		},
		selectedRowKeys,
		getCheckboxProps: record => ({
			disabled: isSecurityKeyTableInEditMode,
		}),
	};

	const onRegisterSecurityKeyButtonClick = () => {
		let name: string = '';
		if (operator.Id > 0) {
			name = parseOperatorName();
		} else {
			if (operatorContext.Name?.length > 0) {
				name = operatorContext.Name;
			} else {
				name = '<NO NAME>';
			}
		}
		setKeyUserName(name.toUpperCase());
	};

	const handleOnSuccessfulKeyRegistration = () => {
		batch(() => {
			setKeyUserName(null);
			setIsTableRefreshing(true);
		});
		updateKeysWithNewKey();
		batch(() => {
			setIsTableRefreshing(false);
			dispatcher(setTwoFactorTabModificationAction(true));
			setKeyName(null);
			setKeyId(null);
		});
	};

	const handleOnCancelKeyRegistration = () => {
		setKeyUserName(null);
	};

	return (
		<>
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large' className={styles.spinContainer}>
				<div className={styles.statusContainer}>
					<legend id='operatorPropertyTwoFactorStatusLabel' className={styles.legend}>
						{_('Status')}
					</legend>
					<div>
						<Radio.Group
							value={status}
							disabled={isSecurityKeyTableInEditMode}
							onChange={(e: RadioChangeEvent) => {
								const onOrOff: boolean = e.target.value === '2';
								batch(() => {
									setStatus(e.target.value);
									dispatcher(setOperatorPropAction({ field: 'Bypass2FA', value: onOrOff }));
									dispatcher(setTwoFactorTabModificationAction(true));
								});
							}}>
							<div>
								<Radio value={'1'} disabled={nonAuthorizedUser}>
									<div>
										<div>
											<label>{_('Active')}</label>
										</div>
										<div>
											<p className={styles.p}>{_('Require2FA')}</p>
										</div>
									</div>
								</Radio>
							</div>
							<div>
								<Radio value={'2'} disabled={nonAuthorizedUser}>
									<div>
										<div>
											<label>{_('Bypass')}</label>
										</div>
										<div>
											<p className={styles.p}>{_('Bypass2FA')}</p>
										</div>
									</div>
								</Radio>
							</div>
						</Radio.Group>
					</div>
				</div>
				<div className={styles.buttonContainer}>
					<ButtonDropdown
						id='operatorKeysTableActionDropdown'
						menuOptions={items.options}
						icon={<CaretDownOutlined />}
						labelIcon={items.labelOrIcon}
						onClickOption={handleActionScope}
						disabled={isSecurityKeyTableInEditMode}
					/>
					<Button type='primary' className={styles.button} onClick={onRegisterSecurityKeyButtonClick} disabled={isSecurityKeyTableInEditMode}>
						{_('Add2FAKey')}
					</Button>
				</div>
				<div className={styles.tableContainer}>
					<Form form={form}>
						<Table
							id={'operatorKeysTable'}
							size='small'
							className={styles.table}
							dataSource={keys}
							columns={mergedColumns as ColumnsType}
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							rowSelection={{ ...rowSelection }}
							pagination={false}
							loading={isTableRefreshing}
						/>
					</Form>
				</div>
			</Spin>
			{keyUserName && (
				<RegisterSecurityKeyModal
					operatorId={operator.Id}
					operatorGuid={operatorContext.OperatorGuid}
					userName={keyUserName}
					setKeyName={setKeyName}
					setKeyId={setKeyId}
					existingKeyIds={keys ? keys.map(k => k?.key?.toString()) : []}
					handleOnCloseModal={handleOnCancelKeyRegistration}
					handleOnSuccess={handleOnSuccessfulKeyRegistration}
					handleKeyNameVerification={verifyKeyName}
				/>
			)}
		</>
	);
};

export { OperatorPropertyTwoFactor };

import { SettingOutlined } from '@ant-design/icons';
import { Button, notification, Spin } from 'antd';
import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { photoCallUpApi } from '../../api/';
import { HeaderBar } from '../../components/common';
import { EventTable } from '../../components/event';
import { CardSlider, Configuration, Pagination, UserInfo } from '../../components/photocallup';
import constants from '../../constants';
import { User } from '../../model/AccountModel';
import { Logger } from '../../model/LoggingModel';
import { AvailableDoorInfo, PhotoCallUp, PhotoCallUpResponse, PhotoCallUpUserConfiguration } from '../../model/PhotoCallUpModel';
import { useStoreDispatch, useStoreSelector } from '../../store';
import { selectPhotoCallCredentials } from '../../store/common/selectors';
import { setEnrollmentBlankImages } from '../../store/enrollment/actions';
import { configurationSaveNotification, openConfiguration } from '../../store/photocallup/actions';
import { selectConfigurationNotification, selectOpenConfigurationModal, selectPhotoCallUpUserConfiguration } from '../../store/photocallup/selectors';
import styles from './photoCallUp.module.scss';

const user: User = getUser();
const { showEventsOnPhotoCallUpPage, photoCallUpBasicView } = user;

const PhotoCallUpPage: React.VFC = () => {
	//We use this flag to avoid multiple api calls at the same time
	window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_ACCESS_FLAG, '0');
	window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_CONFIGURATION_FLAG, '0');
	window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_SAVE_CONFIGURATION_FLAG, '0');
	window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_ENROLLMENT_USER_INFO_FLAG, '0');

	const [firstLoad, setFirstLoad] = useState<boolean>(true);
	const [photoCallUpResponse, setPhotoCallUpResponse] = useState<PhotoCallUpResponse>({
		BlankProfilePictureBase64: '',
		BlankProfileSignatureBase64: '',
		QuantityPerDoor: 0,
		ShowAllDoorsSection: false,
		ShowDoorName: false,
		ShowDateTime: false,
		ShowBorderCard: false,
		ShowDoorsInFullWidth: false,
		LoadCachedEntries: false,
		PhotoCallUps: [],
		SelectedDoors: [],
	});

	const dispatch = useStoreDispatch();
	const photoCallCredentials: PhotoCallUp[] = useStoreSelector<PhotoCallUp[]>(selectPhotoCallCredentials);
	const photoCallUpUserConfiguration: PhotoCallUpUserConfiguration = useStoreSelector<PhotoCallUpUserConfiguration>(selectPhotoCallUpUserConfiguration);
	const configurationNotification: number = useStoreSelector<number>(selectConfigurationNotification);
	const openConfigurationModal: boolean = useStoreSelector<boolean>(selectOpenConfigurationModal);

	const photoCallUpResponseReference = React.useRef<PhotoCallUpResponse>(photoCallUpResponse);
	const setPhotoCallUpResponseReference = (newPhotoCallUpResponseReference: PhotoCallUpResponse): void => {
		photoCallUpResponseReference.current = { ...newPhotoCallUpResponseReference };
		setPhotoCallUpResponse(newPhotoCallUpResponseReference);
	};

	const SortableItem = SortableElement(({ slider }) => {
		return slider;
	});

	const SortableList = SortableContainer(({ sliders }) => (
		<div className={styles.photoSliderGrid}>
			{sliders.map((slider, index) => (
				<SortableItem key={slider.props.doorId} index={index} slider={slider} />
			))}
		</div>
	));

	useEffect(() => {
		const sessionStorageKey = constants.sessionStorage.photoCallUp.RETRIEVE_ACCESS_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');
			photoCallUpApi
				.retrievePhotoCallUpAccess()
				.then(data => {
					data.SelectedDoors = setDoorsColors(data.SelectedDoors);
					dispatch(
						setEnrollmentBlankImages({
							BlankProfilePictureBase64: data.BlankProfilePictureBase64,
							BlankProfileSignatureBase64: data.BlankProfileSignatureBase64,
						})
					);
					setPhotoCallUpResponseReference(data);
					window.sessionStorage.setItem(sessionStorageKey, '0');
				})
				.catch(e => Logger.writeErrorLog(e))
				.finally(() => setFirstLoad(false));
		}
	}, []);

	useEffect(() => {
		if (photoCallUpUserConfiguration) {
			const configuration = photoCallUpUserConfiguration;
			const currentState = photoCallUpResponseReference.current;
			const selectedDoorsOnPage = currentState.SelectedDoors ? [...currentState.SelectedDoors] : [];
			const selectedDoorsOnConfiguration = configuration.SelectedDoors ? [...configuration.SelectedDoors] : [];

			let selectedDoors: AvailableDoorInfo[] = selectedDoorsOnPage.filter(d => selectedDoorsOnConfiguration.findIndex(x => x.DoorId == d.DoorId) >= 0);
			const isDoorSelected = (door: AvailableDoorInfo) => selectedDoorsOnConfiguration.findIndex(x => x.DoorId === door.DoorId) >= 0;
			const hasDoorChange = selectedDoorsOnPage.length !== selectedDoorsOnConfiguration.length || !selectedDoorsOnPage.every(isDoorSelected);
			let newPhotoCallUps = [...currentState.PhotoCallUps];

			if (hasDoorChange) {
				selectedDoors = setDoorsColors(selectedDoorsOnConfiguration);
				newPhotoCallUps = newPhotoCallUps.filter(p => selectedDoors.findIndex(sd => sd.DoorId === p.DoorId) >= 0);
			}

			if (currentState.QuantityPerDoor > configuration.QuantityPerDoor) {
				newPhotoCallUps = validateState(configuration.QuantityPerDoor, selectedDoors, newPhotoCallUps);
			}

			setPhotoCallUpResponseReference({
				...currentState,
				PhotoCallUps: newPhotoCallUps,
				QuantityPerDoor: configuration.QuantityPerDoor,
				ShowAllDoorsSection: configuration.ShowAllDoorsSection,
				ShowDoorName: configuration.ShowDoorName,
				ShowDateTime: configuration.ShowDateTime,
				ShowBorderCard: configuration.ShowBorderCard,
				ShowDoorsInFullWidth: configuration.ShowDoorsInFullWidth,
				SelectedDoors: selectedDoors,
			});
		}
	}, [photoCallUpUserConfiguration]);

	useEffect(() => {
		if (photoCallCredentials && photoCallCredentials.length) {
			const currentState = photoCallUpResponseReference.current;
			const maxAmount = currentState.QuantityPerDoor;
			const photoCallUps: PhotoCallUp[] = validateState(maxAmount, currentState.SelectedDoors, [...currentState.PhotoCallUps, ...photoCallCredentials]);

			setPhotoCallUpResponseReference({ ...currentState, PhotoCallUps: photoCallUps });
		}
	}, [photoCallCredentials]);

	useEffect(() => {
		switch (configurationNotification) {
			case 1:
				notification['success']({
					message: _('ConfigurationSaved'),
				});
				dispatch(configurationSaveNotification(0));
				break;

			case 2:
				notification['warning']({
					message: _('ConfigurationSaved'),
					description: _('RefreshPageConfiguration'),
				});
				dispatch(configurationSaveNotification(0));
				break;
		}
	}, [configurationNotification]);

	const validateDoorStateOrderAndColor = (originalDoors: AvailableDoorInfo[], newDoors: AvailableDoorInfo[]) => {
		if (originalDoors.length !== newDoors.length) {
			return true;
		}

		let hasChange = false;
		//We will validate if order actually changed or the visibility, if not then we don't trigger unnecessary renders
		for (let i = 0; i < originalDoors.length; i++) {
			const newDoor = newDoors[i];
			const originalDoor = originalDoors[i];

			if (
				originalDoor.DoorId !== newDoor.DoorId ||
				(originalDoor.DoorId === newDoor.DoorId &&
					((originalDoor.IsHidden !== undefined && newDoor.IsHidden !== undefined && originalDoor.IsHidden !== newDoor.IsHidden) ||
						(originalDoor.Color !== undefined && newDoor.Color !== undefined && originalDoor.Color !== newDoor.Color)))
			) {
				hasChange = true;
				break;
			}
		}

		return hasChange;
	};

	const validateState = (maxAmount: number, selectedDoors: AvailableDoorInfo[], totalPhotoCallUps: PhotoCallUp[]): PhotoCallUp[] => {
		totalPhotoCallUps = totalPhotoCallUps.sort(sortByDescendingPhotoCallUp);
		let retValue: PhotoCallUp[] = [];
		selectedDoors.forEach((door: AvailableDoorInfo) => {
			let temp = totalPhotoCallUps.filter(d => d.DoorId === door.DoorId);
			retValue = temp.slice(0, maxAmount).concat(retValue);
		});

		return retValue;
	};

	const sortByDescendingPhotoCallUp = (a: PhotoCallUp, b: PhotoCallUp) => {
		const firstValue: number = new Date(a.OffsetHardwareTime).getTime();
		const secondValue: number = new Date(b.OffsetHardwareTime).getTime();

		return secondValue - firstValue;
	};

	const handleToggleDoor = (doorId: number) => {
		const currentState = photoCallUpResponseReference.current;
		currentState.SelectedDoors.forEach((door: AvailableDoorInfo) => {
			if (door.DoorId === doorId) {
				door.IsHidden = door.IsHidden ? false : true;
			}
		});
		setPhotoCallUpResponseReference({ ...currentState });
	};

	const setDoorsColors = (selectedDoors: AvailableDoorInfo[]) => {
		const colors = ['#FF9D3B', '#42D12F', '#4F8DF2', '#DB5858', '#9857BF', '#CC48A9', '#35CBAE'];
		const resetColors = selectedDoors.filter(door => door.Color === null || door.Color === undefined).length > 0;
		if (resetColors) {
			for (let i = 0, index = 0; i < selectedDoors.length; i++, index++) {
				if (index == colors.length) {
					index = 0;
				}
				selectedDoors[i].Color = colors[index];
			}
		}

		return selectedDoors;
	};

	const handleSortEnd = ({ oldIndex, newIndex }) => {
		const currentState = photoCallUpResponseReference.current;
		const newDoors = arrayMove(currentState.SelectedDoors, oldIndex, newIndex);
		if (validateDoorStateOrderAndColor(currentState.SelectedDoors, newDoors)) {
			setPhotoCallUpResponseReference({ ...currentState, SelectedDoors: newDoors });
		}
	};

	const configure = _('Configure');
	const loading = `${_('Loading')}...`;
	const photoCallUpInstructions = _('PhotoCallUpInstructions');

	let sliders;
	let viewAllSlider;
	const currentState = photoCallUpResponseReference.current;
	if (currentState !== null && currentState.SelectedDoors !== null) {
		if (!firstLoad && currentState.ShowAllDoorsSection) {
			viewAllSlider = (
				<CardSlider
					key={-1}
					title={_('AllDoors')}
					doorId={-1}
					color={'grey'}
					isHidden={false}
					cards={currentState.PhotoCallUps.sort(sortByDescendingPhotoCallUp)}
					showDoorsInFullWidth={true}
					showDoorName={currentState.ShowDoorName}
					showDateTime={currentState.ShowDateTime}
					showBorderCard={currentState.ShowBorderCard}
					onToggleDoor={handleToggleDoor}
					photoCallUpBasicView={photoCallUpBasicView}
				/>
			);
		}

		sliders = currentState.SelectedDoors.map(door => {
			const doorId = door.DoorId;
			const cardSliderPhotoCallUps = currentState.PhotoCallUps.filter(p => p.DoorId === doorId);

			return (
				<CardSlider
					key={doorId}
					title={AvailableDoorInfo.getFullName(door)}
					doorId={door.DoorId}
					color={door.Color}
					isHidden={door.IsHidden}
					cards={cardSliderPhotoCallUps}
					showDoorsInFullWidth={currentState.ShowDoorsInFullWidth}
					showDoorName={currentState.ShowDoorName}
					showDateTime={currentState.ShowDateTime}
					showBorderCard={currentState.ShowBorderCard}
					onToggleDoor={handleToggleDoor}
					photoCallUpBasicView={photoCallUpBasicView}
				/>
			);
		});
	}

	const configureButton = (
		<Button
			id={'photoCallUpConfigureButton'}
			type='primary'
			className={styles.photoConfigureButton}
			onClick={() => {
				const sessionStorageKey = constants.sessionStorage.photoCallUp.RETRIEVE_CONFIGURATION_FLAG;
				if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
					window.sessionStorage.setItem(sessionStorageKey, '1');
					dispatch(openConfiguration(currentState.QuantityPerDoor, currentState.LoadCachedEntries, currentState.SelectedDoors));
				}
			}}>
			<SettingOutlined />
			{configure}
		</Button>
	);

	return (
		<div className={styles.photoCallUpContainer}>
			<HeaderBar title={_('PhotoCallUp')} />
			<Spin tip={loading} spinning={firstLoad} size='default' className={styles.spinContainer}>
				<div className={styles.photoPaginationDiv}>
					<div className={styles.photoPaginationConfigure}>
						<h1 className={styles.photoPaginationTitle}>{photoCallUpInstructions}</h1>
						{configureButton}
					</div>
					{currentState && <Pagination doors={currentState.SelectedDoors} onToggleDoor={handleToggleDoor} />}
				</div>
				<div id={'photoCollectionsGrid'} className={styles.photoSliderGrid}>
					{viewAllSlider}
					<SortableList
						sliders={sliders !== null ? sliders : []}
						onSortEnd={handleSortEnd}
						distance={3}
						axis='xy'
						helperClass='photo-sortable-helper'
					/>
				</div>
				<UserInfo />
				{showEventsOnPhotoCallUpPage && <EventTable />}
				{openConfigurationModal && <Configuration />}
			</Spin>
		</div>
	);
};

export { PhotoCallUpPage };

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { noneDecimalFormatter } from '../../../../../../../../Helper';
import { SubPermissions, User } from '../../../../../../../../model/AccountModel';
import { RS485ReaderType, ReaderError } from '../../../../../../../../model/DeviceAdminModel';
import { InformationMessages, ModalConfirmation, ModalWarning } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { UpdateFirmwareModal } from '../../../../UpdateFirmwareModal/UpdateFirmwareModal';
import { StoreContext, setOSDPAddress, setOkDegrade, setReaderErrorType } from '../../../../contextReader';
import styles from '../../../General/general.module.scss';

type Props = {
	handleDownloadFirmware: () => void;
	useDoorContext: boolean;
};

type VersionProps = {
	rS485ReaderType: number;
	firmware: string;
	appVersion: string;
	displayBoardVersion: string;
};

export const ReaderVersions: React.FC<VersionProps> = ({ rS485ReaderType, firmware, appVersion, displayBoardVersion }) => {
	let showAPPVersion = false;
	let showDisplayBoardVersion = false;

	switch (rS485ReaderType as RS485ReaderType) {
		case RS485ReaderType.TS:
		case RS485ReaderType.TSKeypad:
		case RS485ReaderType.TSScramblePad:
		case RS485ReaderType.TSPIVAUTH:
			showAPPVersion = true;
			break;

		case RS485ReaderType.TS_SF1:
		case RS485ReaderType.TS_SF2:
		case RS485ReaderType.TS_SF3:
			showAPPVersion = true;
			showDisplayBoardVersion = true;
			break;
	}

	return (
		<div className={styles.versionsContainer}>
			<span>{_('TRNVersion')}:</span>
			<span>{firmware}</span>
			{showAPPVersion && (
				<>
					<span>{_('APPVersion')}:</span>
					<span>{appVersion}</span>
				</>
			)}
			{showDisplayBoardVersion && (
				<>
					<span>{_('DisplayVersion')}:</span>
					<span>{displayBoardVersion}</span>
				</>
			)}
		</div>
	);
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const userCanUpdateFirmware = User.getSubComponentPermission(user, SubPermissions.Reader_FirmwareUpdateTSReader).allowed;
const style: React.CSSProperties = { color: '#faad14' };

const RS485Body: React.FC<Props> = ({ handleDownloadFirmware, useDoorContext }) => {
	const [showModal, setShowModal] = useState(false);
	const [OSDPAddressErrorMessage, setOSDPAddressErrorMessage] = useState<JSX.Element>(<></>);
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					isFICAMEnabled,
					updateFirmwarePermissionText,
					canUpdateFirmware,
					oSDPAddress,
					firmware,
					appVersion,
					displayBoardVersion,
					firmwareConfirm,
					okDegrade,
					firmwareTitle,
					readerErrorMessage,
					readerErrorType,
					selections: { rS485ReaderType },
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				isFICAMEnabled,
				updateFirmwarePermissionText,
				canUpdateFirmware,
				oSDPAddress,
				firmware,
				appVersion,
				displayBoardVersion,
				firmwareConfirm,
				okDegrade,
				firmwareTitle,
				selections: { rS485ReaderType },
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const handleOnChangeAddress = (value: React.Key) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value != 0) {
				return;
			}
		}

		useDoorContext ? dispatcherDoor(setOSDPAddress(Number(value.toString()))) : dispatcher(setOSDPAddress(Number(value.toString())));
		useDoorContext ? dispatcherDoor(setReaderErrorType(undefined)) : null;
	};

	const handleOnClickUpdateFirmware = () => {
		if (userCanUpdateFirmware) {
			const messages: string[] = firmwareConfirm.split('\n');
			const content: JSX.Element = <InformationMessages messages={messages} />;

			ModalConfirmation({
				title: firmwareTitle,
				content: content,
				onConfirm: () => setShowModal(true),
				okText: _('Yes'),
				cancelText: _('No'),
				width: '700px',
				icon: <ExclamationCircleOutlined style={style} />,
			});
		} else {
			const content: JSX.Element = <InformationMessages messages={[updateFirmwarePermissionText]} />;

			ModalWarning({
				okText: _('Ok'),
				content: content,
				width: '600px',
			});
		}
	};

	const handleOnSetOkDegrade = (value: CheckboxChangeEvent) => {
		useDoorContext ? dispatcherDoor(setOkDegrade(value.target.checked)) : dispatcher(setOkDegrade(value.target.checked));
	};

	const handleOnCloseUpdateFirmwareModal = () => {
		setShowModal(false);
	};

	useEffect(() => {
		if (readerErrorType === ReaderError.OSDPAddress) {
			setOSDPAddressErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else {
			setOSDPAddressErrorMessage(<></>);
		}
	}, [readerErrorType]);

	return (
		<>
			<div className={styles.sections}>
				<span>{_('OSDPAddress')}</span>
				<div>
					<InputNumber
						key='osdpAddress'
						min={0}
						max={126}
						maxLength={3}
						value={oSDPAddress}
						onChange={handleOnChangeAddress}
						id={'osdpAddress'}
						className={cx(styles.input, {
							[styles.error]: readerErrorType === ReaderError.OSDPAddress,
						})}
						formatter={noneDecimalFormatter}
					/>
					{OSDPAddressErrorMessage}
				</div>
			</div>
			{isFICAMEnabled && (
				<div className={styles.sections}>
					<div className={styles.okDegrade}>
						<Checkbox checked={okDegrade} onChange={handleOnSetOkDegrade} id={'okDegrade'}>
							<span>{_('AllowAccessInDegrade')}</span>
						</Checkbox>
					</div>
				</div>
			)}
			<div className={styles.doubleColumn}>
				<ReaderVersions rS485ReaderType={rS485ReaderType} firmware={firmware} appVersion={appVersion} displayBoardVersion={displayBoardVersion} />
				<div className={styles.rightColumn}>
					{canUpdateFirmware && (
						<Button key='updateFirmware' type='primary' id='readerModalUpdateReaderFirmwareButton' onClick={handleOnClickUpdateFirmware}>
							{_('UpdateReaderFirmware')}
						</Button>
					)}
				</div>
			</div>
			<UpdateFirmwareModal
				visible={showModal}
				closeModal={handleOnCloseUpdateFirmwareModal}
				handleDownloadFirmware={handleDownloadFirmware}
				useDoorContext={useDoorContext}></UpdateFirmwareModal>
		</>
	);
};

export { RS485Body };

import { BaseColumns, DefaultGridElement, GridDataItem, GridItem, PaginationSetting, ResponseObject } from './CommonModel';
import { DeviceObjectType } from './DeviceAdminModel';
export type StatusViewer = {
	StatusViewerItems: StatusViewerItem[];
};

export type StatusViewerRealTimeEvent = {
	Data: StatusViewerEvent[];
};

export enum StatusViewerChangeType {
	None = 0,
	AddEvent,
	UpdateEvent,
	DeleteEvent,
	SyncEvent,
	AddStatusGroup,
	UpdateStatusGroup,
	DeleteStatusGroup,
}

export type StatusViewerEvent = {
	Address: string;
	ObjectId: number;
	ObjectType: number;
	Data: GridDataItem[];
	ChangeType: StatusViewerChangeType;
};

export type StatusViewerItem = {
	ObjectId: number;
	DeviceObjectType: DeviceObjectType;
} & GridItem;

export type ExpandedRowItem = {
	DoorId: number;
	Address: string;
	Granted?: number;
	Denied?: number;
	isExpanded: boolean;
	isLoading: boolean;
	RunTimeFunction?: NodeJS.Timer;
};

export enum StatusViewerEnum {
	None,
	ShowOnly,
	StatusGroup,
	MaskedGroup,
}

export type StatusGroupSelected = {
	Id?: number;
	Name?: string;
	PaginationTables?: PaginationTable[];
	IsStatusViewer?: boolean;
	StatusViewerType: StatusViewerEnum;
};

export type PaginationTable = {
	ObjectTypeId: number;
	PaginationSetting: PaginationSetting;
};

export type StatusGroupNotificationStatus = {
	statusGroupSelect: StatusGroupSelected;
	paginationSetting?: PaginationSetting;
	onSetVisible?: () => void;
	onHandleCloseModal?: () => void;
	permissionErrorCallback?: (errorMessage: any) => void;
	setNameError?: React.Dispatch<React.SetStateAction<string>>;
	shouldResetSearchColumn?: (statusViewerGroup: any) => void;
	onSetStatusPointsError?: React.Dispatch<React.SetStateAction<string>>;
};

export type StatusViewerGroup = {
	StatusGroupId: number;
	Name: string;
	StatusPoints: StatusViewerPoint[];
};

export type StatusViewerPointByType = {
	StatusViewerPoints: StatusViewerPoint[];
	TotalStatusViewerPoints: number;
};

export type StatusViewerPoint = {
	StatusViewerPointId: number;
	ObjectId: number;
	DeviceId: number;
	ObjectTypeId: number;
	Name: string;
	Address: string;
};

export type OffNormalPoints = {
	List: DeviceInfo[];
} & ResponseObject;

export type DeviceInfo = {
	Id: number;
	Address: string;
	Name: string;
	Type: string;
};

export type OffNormalData = BaseColumns & DeviceInfo;

export type StatusViewerConfigurationColumns = {
	Name: string;
	Address: string;
	StatusViewerPointId: number;
	ObjectId: number;
	DeviceId: number;
	ObjectTypeId: number;
} & BaseColumns &
	DefaultGridElement;

export enum StatusObjectEnum {
	None = -1,
	MaskedOnly,
	Door,
	Input,
	Relay,
	Reader,
	Controller,
	XBox,
	Port,
	WirelessLocks = 10,
	PortsWithNetworkGlobalIO = 11,
}

export const StatusObject: Record<number, string> = {
	1: StatusObjectEnum[StatusObjectEnum.Door],
	2: StatusObjectEnum[StatusObjectEnum.Input],
	3: StatusObjectEnum[StatusObjectEnum.Relay],
	4: StatusObjectEnum[StatusObjectEnum.Reader],
	5: StatusObjectEnum[StatusObjectEnum.Controller],
	6: StatusObjectEnum[StatusObjectEnum.XBox],
	7: StatusObjectEnum[StatusObjectEnum.Port],
	10: StatusObjectEnum[StatusObjectEnum.WirelessLocks],
	11: StatusObjectEnum[StatusObjectEnum.PortsWithNetworkGlobalIO],
};

export const DescriptionsObject: Record<number, string> = {
	1: 'Doors',
	2: 'Inputs',
	3: 'Relays',
	4: 'Readers',
	5: 'Controllers',
	6: 'XBoxes',
	7: 'Ports',
	10: 'WirelessLocks',
	11: 'PortsWithNetworkGlobalIO',
};

export type StatusGroupRow = {
	DeviceObjectType: DeviceObjectType;
	Name: string;
	Address: string;
	Key: string;
	Id: number;
	ObjectTypeId: number;
	ControllerId: number;
	CurrentPage: number;
	IsModalOpen: boolean;
	HasResetEncryption: boolean;
};

export type ControllerToEdit = {
	id: number;
	isModalOpen: boolean;
};

export type AddItemData = {
	statusViewerEvent: StatusViewerEvent;
	objectType: StatusObjectEnum;
	paginationSetting: PaginationSetting;
};

export type DeleteItemData = {
	deviceId: number;
	objectType: number;
};

export enum TargetKeyOption {
	ObjectId = 0,
	ObjectType,
	DeviceId,
}

import React, { CSSProperties } from 'react';
import { USBIcon } from '../../account/LogOnForm/LogOnIcons/logOnIcons';
import { Modal } from '../../common';
import styles from './insertSecurityKeyModal.module.scss';

type Props = {
	handleOnCloseModal: () => void;
};

const iconStyle: CSSProperties = { width: 120, height: 130 };

const InsertSecurityKeyModal: React.FC<Props> = ({ handleOnCloseModal }) => {
	return (
		<Modal customZoomClass={styles.twoFactorModal} visible={true} width='20vw' onCancel={handleOnCloseModal}>
			<div className={styles.twoFactorModalContent}>
				<span>{_('InsertTwoFactorKey')}</span>
				<div className={styles.twoFactorModalContent}>
					<USBIcon style={iconStyle} />
				</div>
			</div>
		</Modal>
	);
};

export { InsertSecurityKeyModal };

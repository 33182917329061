import { InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { ControllerMisc } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { setControllerMiscSystemSetupAction } from '../../../../../../../store/deviceControl/controller/actions';
import { selectDOTL, selectDelay, selectMode } from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../setup.module.scss';

const SpecialNeedsTimeExtensions: React.FC = () => {
	const dispatch = useStoreDispatch();
	const delay: number = useStoreSelector<number>(selectDelay);
	const mode: number = useStoreSelector<number>(selectMode);
	const dotl: number = useStoreSelector<number>(selectDOTL);

	const handleChangeControllerMiscSystemSetup = (value: ControllerMisc) => {
		dispatch(
			setControllerMiscSystemSetupAction({
				...value,
			})
		);
	};

	return (
		<CardSection title={_('SpecialNeedsTimeExtensions')} height='92%'>
			<div className={styles.specialNeedsTime}>
				<div>{_('Delay')}:</div>
				<InputNumber
					id='controllerSetupDelayInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup({ SpecialNeedsDelayTimer: Number(value) })}
					value={delay}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
				<div>{_('Mode')}:</div>
				<InputNumber
					id='controllerSetupModeInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup({ SpecialNeedsModeTimer: Number(value) })}
					value={mode}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
				<div>{_('DoorOpenTooLong')}:</div>
				<InputNumber
					id='controllerSetupDOTLInput'
					min={0}
					max={8100}
					maxLength={4}
					onChange={value => handleChangeControllerMiscSystemSetup({ SpecialNeedsDOTLTimer: Number(value) })}
					value={dotl}
					formatter={noneDecimalFormatter}
				/>
				<span>(0-8100 {_('Seconds')})</span>
			</div>
		</CardSection>
	);
};

export { SpecialNeedsTimeExtensions };

import { Divider } from 'antd';
import React, { useContext } from 'react';
import { PortStoreContext } from '../../contextPort';
import styles from '../../portModal.module.scss';
import { DefaultGateway, IPAddress, IPPort, MaxRetryAttempt, Name, NetworkSelector, Ping, ResetEncryption, Search, SubnetMask } from '../sections';
import BaudRate from '../sections/BaudRate/BaudRate';
import COMPort from '../sections/COMPort/COMPort';
import { EnablePort } from '../sections/EnablePort/EnablePort';

type Props = {
	nameValidation: boolean;
	nameError: boolean;
	onResetNameError: () => void;
	ipAddressValidation: boolean;
	onResetIPAddressValidation: () => void;
	subnetMaskValidation: boolean;
	onResetSubnetMaskValidation: () => void;
	defaultGatewayValidation: boolean;
	onResetDefaultGatewayValidation: () => void;
	errorMessage: string;
	onResetErrorMessage: () => void;
};

const General: React.FC<Props> = props => {
	const {
		portState: { portNetworkType },
		dispatcher,
	} = useContext(PortStoreContext);

	const {
		nameError,
		nameValidation,
		onResetNameError,
		ipAddressValidation,
		onResetIPAddressValidation,
		subnetMaskValidation,
		onResetSubnetMaskValidation,
		defaultGatewayValidation,
		onResetDefaultGatewayValidation,
		errorMessage,
		onResetErrorMessage,
	} = props;
	return (
		<>
			<div className={styles.form}>
				<Name nameValidation={nameValidation} nameError={nameError} onResetNameError={onResetNameError} />
				<div className={styles.EnableDiv}>
					<EnablePort />
					<ResetEncryption />
				</div>
				<NetworkSelector />
				<Divider />
				{portNetworkType !== 1 ? (
					<>
						<div className={styles.formIP}>
							<div className={styles.gridLeft}>
								<IPAddress
									ipAddressValidation={ipAddressValidation}
									onResetIPAddressValidation={onResetIPAddressValidation}
									errorMessage={errorMessage}
									onResetErrorMessage={onResetErrorMessage}
								/>
								<IPPort />
								<SubnetMask subnetMaskValidation={subnetMaskValidation} onResetSubnetMaskValidation={onResetSubnetMaskValidation} />
								<DefaultGateway
									defaultGatewayValidation={defaultGatewayValidation}
									onResetDefaultGatewayValidation={onResetDefaultGatewayValidation}
								/>
							</div>
							<div className={styles.gridRight}>
								<div className={styles.spacingButtons}>
									<Search />
									<Ping />
								</div>
							</div>
						</div>
						<MaxRetryAttempt />
					</>
				) : (
					<div className={styles.serialContainer}>
						<BaudRate />
						<COMPort />
					</div>
				)}
			</div>
		</>
	);
};

export { General };

export enum StatusViewerActionTypes {
	RETRIEVE_STATUS_VIEWER = 'RETRIEVE_STATUS_VIEWER',
	SET_STATUS_VIEWER_REAL_TIME = 'SET_STATUS_VIEWER_REAL_TIME',
	RETRIEVE_STATUS_VIEWER_BY_PAGINATION = 'RETRIEVE_STATUS_VIEWER_BY_PAGINATION',
	RETRIEVE_STATUS_VIEWER_BY_TYPE = 'RETRIEVE_STATUS_VIEWER_BY_TYPE',
	RETRIEVE_STATUS_GROUP = 'RETRIEVE_STATUS_GROUP',
	RETRIEVE_STATUS_MASKED_ONLY = 'RETRIEVE_STATUS_MASKED_ONLY',
	DELETE_STATUS_VIEWER = 'DELETE_STATUS_VIEWER',
	ADD_STATUS_VIEWER_GROUP = 'ADD_STATUS_VIEWER_GROUP',
	EDIT_STATUS_VIEWER_GROUP = 'EDIT_STATUS_VIEWER_GROUP',
	RETRIEVE_STATUS_VIEWER_CONFIGURATION = 'RETRIEVE_STATUS_VIEWER_CONFIGURATION',
	CLEAN_STATUS_VIEWER = 'CLEAN_STATUS_VIEWER',
	RETRIEVE_STATUS_POINTS_BY_TYPE_ACTION = 'RETRIEVE_STATUS_POINTS_BY_TYPE_ACTION',
	NEW_STATUS_VIEWER_CONFIGURATION = 'NEW_STATUS_VIEWER_CONFIGURATION',
	STATUS_VIEWER_ADD_POINT = 'STATUS_VIEWER_ADD_POINT',
	STATUS_VIEWER_REMOVE_POINT = 'STATUS_VIEWER_REMOVE_POINT',
	RETRIEVE_STATUS_VIEWER_COLUMN_CONFIGURATION = 'RETRIEVE_STATUS_VIEWER_COLUMN_CONFIGURATION',
	SAVE_STATUS_VIEWER_COLUMN_CONFIGURATION = 'SAVE_STATUS_VIEWER_COLUMN_CONFIGURATION',
	UPDATE_STATUS_VIEWER_COLUMN_CONFIGURATION_STATE = 'UPDATE_STATUS_VIEWER_COLUMN_CONFIGURATION_STATE',
	RESET_STATUS_VIEWER_POINT = 'RESET_STATUS_VIEWER_POINT',
	SELECTED_STATUS_GROUP = 'SELECTED_STATUS_GROUP',
	LOADING_STATUS_VIEWER_TABLE = 'LOADING_STATUS_VIEWER_TABLE',
	LOADING_STATUS_VIEWER_CONFIGURATION_TABLE = 'LOADING_STATUS_VIEWER_CONFIGURATION_TABLE',
	EXPANDED_ROW_STATUS_GROUP = 'EXPANDED_ROW_STATUS_GROUP',
	SET_CONTROLLER_TO_EDIT = 'SET_CONTROLLER_TO_EDIT',
	ADD_STATUS_VIEWER_ITEM_DATA = 'ADD_STATUS_VIEWER_ITEM_DATA',
	DELETE_STATUS_VIEWER_ITEM_DATA = 'DELETE_STATUS_VIEWER_ITEM_DATA',
	DELETE_STATUS_GROUP_OPTION = 'DELETE_STATUS_GROUP_OPTION',
}

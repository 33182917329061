import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Table, notification } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import {
	ColumnsProps,
	ScrollType,
	buildActionColumn,
	buildColumn,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getSelectedRowKeysPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	lockedValidation,
} from '../../../Helper';
import { deviceAdminApi } from '../../../api/';
import { ComponentPermission, SecuredComponents, SubPermissions, User, getPermissionErrorMessage } from '../../../model/AccountModel';
import {
	BaseColumns,
	DictionaryCustom,
	PaginationSetting,
	ResponseObject,
	ResponseStatusCode,
	SelectOptions,
	SortDirections,
} from '../../../model/CommonModel';
import {
	ActionDeviceTypes,
	ButtonBuilder,
	ControllerContact,
	ControllerContactMappedData,
	CurrentDeviceControlObj,
	DIGITrac,
	DeviceObjectType,
	OptionsButtonBuilder,
	OptionsDigiTrac,
	SelectedRowKeyPagination,
	SendDeviceCommand,
} from '../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import { setCurrentDevice, setLoading } from '../../../store/common/actions';
import { selectCurrentDevice } from '../../../store/common/selectors';
import {
	clearDigiTracSelections,
	setControllerContacts,
	setControllerContactsByData,
	setControllerSelectedKey,
	setDigiTracEditMode,
	setDigiTracFilterMode,
	setDigiTracSelectedRowKeysPagination,
} from '../../../store/deviceControl/actions';
import { selectDigiTrac, selectTablePaginationSetting } from '../../../store/deviceControl/selectors';
import {
	ButtonDropdown,
	DropdownMenu,
	EditableCell,
	ModalConfirmationList,
	ModalWarning,
	NotificationStatus,
	SearchColumn,
	WarningProtocolMessage,
	WithLoader,
} from '../../common/';
import { AddControllerModal, DoorModal, ExpansionRelayModal, InputModal, PortModal, ReaderModal, XboxModal } from '../DigiTrac';
import styles from './digi-trac.module.scss';
import {
	buildOptions,
	cannotViewAnyControllerTabs,
	getCustomAddressColumnWith,
	getDigiTracOptions,
	getMergedColumns,
	getSpecificColumns,
	getSpecificData,
	usePreviousProps,
} from './helpers';

type Props = {
	deviceObjectType: DeviceObjectType;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const canViewGeneralTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewGeneralTab).allowed;
const canViewEntryReaderTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewEntryReaderTab).allowed;
const canViewExitReaderTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewExitReaderTab).allowed;
const canViewRelayTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewRelayTab).allowed;
const canViewInputTabDoor: boolean = User.getSubComponentPermission(user, SubPermissions.Door_ViewInputTab).allowed;
const canViewGeneral: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewGeneralTab).allowed;
const canViewLogic: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewLogicTab).allowed;
const canViewOptions: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewScramblePadOptionsTab).allowed;
const canViewCardReaderSetup: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewCardReaderSetup).allowed;
const xboxComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.XBox);
const portComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Ports);
const controllerComponentPermission: ComponentPermission = User.getComponentPermission(user, SecuredComponents.Controller);
const scroll: ScrollType = { x: 'max-content', y: 380 };

const deleteDeviceMessages: DictionaryCustom<DeviceObjectType, string>[] = [
	{ Key: DeviceObjectType.Port, Value: _('Ports') },
	{ Key: DeviceObjectType.Xbox, Value: _('Xboxes') },
	{ Key: DeviceObjectType.Controller, Value: _('Controllers') },
];

const DigiTrac = WithLoader((props: Props) => {
	const { deviceObjectType } = props;

	const [editingKey, setEditingKey] = useState('');
	const [isItemOnSamePage, setIsItemOnSamePage] = useState<boolean>(false);
	const [shouldResetSearchColumn, setShouldResetSearchColumn] = useState<boolean>(false);
	const [controllerContactIdSelected, setControllerContactIdSelected] = useState<number>();
	const [controllerContactPage, setControllerContactPage] = useState<number>(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [isRedirectPagePerformed, setRedirectPagePerformed] = useState<boolean>(false);
	const [isTableLoading, setTableLoading] = useState<boolean>(false);
	const [form] = Form.useForm();

	const redirectPage = useRef<boolean>(false);
	const clearSelections = useRef<boolean>(true);
	const isSearchPerformed = useRef<boolean>(false);

	const dispatch = useStoreDispatch();
	const tablePaginationSetting: PaginationSetting = useStoreSelector<PaginationSetting>(selectTablePaginationSetting);
	const currentDevice: CurrentDeviceControlObj = useStoreSelector<CurrentDeviceControlObj>(selectCurrentDevice);
	const {
		controllers,
		doors,
		expansionsInputs,
		expansionsRelays,
		inputs,
		port,
		relays,
		xbox,
		readers,
		isEditMode,
		selectedRowKeysPagination,
		controllerSelectedKey,
		isFilterMode,
	}: DIGITrac = useStoreSelector<DIGITrac>(selectDigiTrac);

	// * Valid if needs to redirect page after modifying a controller
	useEffect(() => {
		if (controllerSelectedKey && deviceObjectType === DeviceObjectType.Controller) {
			setRedirectPage(true);
			const controllerIdSelected = controllerSelectedKey.Id;
			getControllerContactSelected(Number(controllerIdSelected));
		}
	}, [JSON.stringify(controllerSelectedKey)]);

	// * This hook is responsible for loading the previously edited controller
	useEffect(() => {
		if (selectedRowKeys.length === 1 && redirectPage.current) {
			const controllerContactId = Number(selectedRowKeys?.[0]);
			getControllerContactSelected(controllerContactId);
		}
	}, [redirectPage.current]);

	useEffect(() => {
		if (controllerContactIdSelected && controllerContactPage) {
			setRedirectPage(false);
			setIsItemOnSamePage(true);

			dispatch(setDigiTracSelectedRowKeysPagination([{ Id: controllerContactIdSelected.toString(), PaginationPage: controllerContactPage }]));

			const dispatchControllerContacts = dispatch(
				setControllerContacts(deviceObjectType, { ...tablePaginationSetting, PageNumber: controllerContactPage, SearchedValue: '' })
			);
			Promise.all([dispatchControllerContacts]).then(res => setRedirectPagePerformed(true));

			if (!controllerSelectedKey) {
				resetControllerContactSelected();
			}
		}
	}, [controllerContactIdSelected, controllerContactPage]);

	useEffect(() => {
		return () => {
			if (clearSelections.current) {
				clearSelection();
				setIsItemOnSamePage(false);
				setShouldResetSearchColumn(false);
				setSearchPerformed(false);
				setClearSelections(true);
				setRedirectPage(false);
			}
		};
	}, [deviceObjectType]);

	// * This hook ensure that always return a valid page number when no values has returned
	useEffect(() => {
		if (tablePaginationSetting.TotalItems === 0 && tablePaginationSetting.PageNumber > 1) {
			const { PageNumber: currentPage } = tablePaginationSetting;
			dispatch(
				setControllerContacts(deviceObjectType, {
					...tablePaginationSetting,
					PageNumber: currentPage - 1,
				})
			);
		}
	}, [tablePaginationSetting]);

	useEffect(() => {
		if (controllerContactIdSelected) {
			handleChange([controllerContactIdSelected.toString()]);
			resetControllerContactSelected();
		}
	}, [isRedirectPagePerformed]);

	useEffect(() => {
		if (!isFilterMode && isSearchPerformed.current) {
			setShouldResetSearchColumn(true);
		}
	}, [isFilterMode]);

	const clearSelection = () => {
		if (redirectPage.current) {
			return;
		}
		dispatch(clearDigiTracSelections(deviceObjectType));
		setSelectedRowKeys([]);
	};

	const resetControllerContactSelected = () => {
		setControllerContactPage(0);
		setControllerContactIdSelected(0);
	};

	const getControllerContactSelected = (deviceId: number) => {
		setControllerContactIdSelected(deviceId);
		getControllerContactPage(deviceId);
	};

	const getControllerContactPage = async (deviceId: number) => {
		const response = await deviceAdminApi.getControllerContactPage(deviceObjectType, deviceId, tablePaginationSetting);

		setControllerContactPage(response?.Entity);
	};

	const createButtonOptions = (hasMoreThanOne: boolean, isItemOnSamePage: boolean): OptionsButtonBuilder<OptionsDigiTrac> => {
		const optionsContextMenu: SelectOptions<OptionsDigiTrac>[] = [];
		if (currentDevice.DeviceObjectType === deviceObjectType) {
			optionsContextMenu.push(...getDigiTracOptions({ deviceObjectType: currentDevice.DeviceObjectType, hasMoreThanOne, isItemOnSamePage }));
		} else {
			optionsContextMenu.push(...getDigiTracOptions({ deviceObjectType: deviceObjectType, hasMoreThanOne, isItemOnSamePage }));
		}
		return {
			labelOrIcon: '...',
			options: optionsContextMenu,
		};
	};

	const controllerData = (): ControllerContact[] => {
		switch (deviceObjectType) {
			case DeviceObjectType.Controller:
				return controllers?.length > 0 ? [...controllers] : [];

			case DeviceObjectType.Door:
				return doors?.length > 0 ? [...doors] : [];

			case DeviceObjectType.Input:
				return inputs?.length > 0 ? [...inputs] : [];

			case DeviceObjectType.ExpansionRelays:
				return expansionsRelays?.length > 0 ? [...expansionsRelays] : [];

			case DeviceObjectType.ExpansionInputs:
				return expansionsInputs?.length > 0 ? [...expansionsInputs] : [];

			case DeviceObjectType.Relay:
				return relays?.length > 0 ? [...relays] : [];

			case DeviceObjectType.Port:
				return port?.length > 0 ? [...port] : [];

			case DeviceObjectType.Xbox:
				return xbox?.length > 0 ? [...xbox] : [];

			case DeviceObjectType.Reader:
				return readers?.length > 0 ? [...readers] : [];
		}
	};

	const onDeleteDeviceRequest = async (id: number): Promise<void> => {
		let response: ResponseObject;
		switch (currentDevice.DeviceObjectType) {
			case DeviceObjectType.Xbox:
				response = await deviceAdminApi.deleteXbox(id);
				break;

			case DeviceObjectType.Port:
				response = await deviceAdminApi.deletePort(id);
				break;

			case DeviceObjectType.Controller:
				response = await deviceAdminApi.deleteController(id);
				break;
		}

		if (handleResponse(response)) {
			return;
		}
	};

	const handleDeleteOkConfirmation = async (tableDataSelected: ControllerContact[]) => {
		for (const device of tableDataSelected) {
			await onDeleteDeviceRequest(device.Id);
		}
		setSelectedRowKeys([]);
		dispatch(setDigiTracSelectedRowKeysPagination([]));
		dispatch(setControllerContacts(deviceObjectType, tablePaginationSetting));
	};

	const runDeviceCommand = async (action: ActionDeviceTypes, controllerId: number, deviceId: number) => {
		const command: SendDeviceCommand = {
			type: deviceObjectType,
			action,
			controllerId,
			deviceId,
		};

		const response = await deviceAdminApi.sendDeviceCommand(command);
		handleResponse(response);
	};

	const onSendCommandRequest = async (action: ActionDeviceTypes): Promise<void> => {
		const isResetencry: boolean = action === ActionDeviceTypes.Resetencry;
		const tableDataSelected = await getSelectedControllerContacts();

		for (const device of tableDataSelected) {
			const deviceId: number = device.Id;
			const controllerId: number = device.ControllerId;

			if (isResetencry) {
				if (device.HasResetEncryption) {
					WarningProtocolMessage({
						onConfirm: () =>
							runDeviceCommand(action, controllerId, deviceId).then(res => {
								dispatch(setControllerContacts(deviceObjectType, tablePaginationSetting));
							}),
						onCancel: () => dispatch(setLoading(false)),
						protocolData: { portId: deviceId, protocol: 0 },
					});
				}
			} else {
				await runDeviceCommand(action, controllerId, deviceId);
			}
		}
	};

	const handleSendDeviceCommand = async (sender: OptionsDigiTrac): Promise<void> => {
		const isResetencry: boolean = sender.action === ActionDeviceTypes.Resetencry;

		dispatch(setLoading(true));
		onSendCommandRequest(sender.action).then(res => {
			if (!isResetencry) {
				dispatch(setControllerContacts(deviceObjectType, tablePaginationSetting));
			}
		});
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		const isSamePage: boolean = selectedRowKeysPagination[0]?.PaginationPage === page;
		setIsItemOnSamePage(isSamePage);

		if (pageSize !== tablePaginationSetting.PageSize) {
			clearSelection();
		}
	};

	const openModal = currentDevice.IsModalOpen;

	const maxLength =
		currentDevice.DeviceObjectType === DeviceObjectType.Input || currentDevice.DeviceObjectType === DeviceObjectType.ExpansionInputs ? 64 : 32;

	const disabled: boolean = editingKey !== '';
	const tableData: ControllerContact[] = controllerData();
	const hasMoreThanOne: boolean = selectedRowKeys.length > 1;
	const items = createButtonOptions(hasMoreThanOne, isItemOnSamePage);
	const buttonOptions = buildOptions({
		deviceType: deviceObjectType,
		hasMoreThanOne,
		hasResetEncryption: tableData.find(c => c.HasResetEncryption && selectedRowKeys.includes(c.Id.toString())) !== undefined,
	});

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		tablePaginationSetting.PageNumber,
		tablePaginationSetting.PageSize,
		tablePaginationSetting.TotalItems,
		undefined,
		selectedRowKeys
	);

	const handleOnSearchResults = (searchedValue: string) => {
		if (redirectPage.current) {
			return;
		}
		dispatch(
			setControllerContacts(deviceObjectType, {
				...tablePaginationSetting,
				TotalItems: 0,
				PageNumber: 1,
				SearchedValue: searchedValue,
			} as PaginationSetting)
		);
	};

	//#region build columns for edit row in antD table
	let genericColumns: ColumnsProps<Partial<ControllerContactMappedData>>[] = [
		{
			...buildColumn(_('Name'), 'Name', 'auto', 'start'),
			editable: true,
			sorter: !isEditMode,
			...SearchColumn({
				maxLength,
				dataIndex: 'Name',
				reset: usePreviousProps(currentDevice)?.DeviceObjectType !== currentDevice.DeviceObjectType,
				label: undefined,
				notVisible: isEditMode,
				resetSearch: shouldResetSearchColumn,
				setIsFilterMode: setDigiTracFilterMode,
				clearSelection,
				handleResetSearch: () => setShouldResetSearchColumn(false),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue),
				setSearchedValue: undefined,
				searchColumnId: undefined,
				setSearchPerformed: (value: boolean) => setSearchPerformed(value),
			}),
			render: (text, record, index) => {
				return <label id={`digitracConfiguration${DeviceObjectType[currentDevice.DeviceObjectType ?? 0]}NameContainer-${record.key}`}>{text}</label>;
			},
		},
		{
			...buildColumn(_('Address'), 'Address', getCustomAddressColumnWith(currentDevice.DeviceObjectType), 'start'),
			sorter: !isEditMode,
		},
	];
	if (isEditMode) {
		genericColumns.unshift(buildActionColumn(disabled));
	}

	const columns: ColumnsProps<ControllerContact>[] = [...genericColumns, ...getSpecificColumns(currentDevice.DeviceObjectType, isEditMode)];

	const onChangeSelection = (key: BaseColumns) => {
		const deviceId = key.key;
		if (selectedRowKeys.findIndex(key => key === deviceId) === -1) {
			const cloneState: ControllerContact[] = tableData.map(x => (x.Id === deviceId ? { ...x, checked: true } : { ...x, checked: false }));
			dispatch(setControllerContactsByData(deviceObjectType, cloneState));

			const selectedKeysPagination: SelectedRowKeyPagination = {
				Id: deviceId,
				PaginationPage: tablePaginationSetting.PageNumber,
			};
			setIsItemOnSamePage(true);
			dispatch(setDigiTracSelectedRowKeysPagination([{ ...selectedKeysPagination }]));

			setSelectedRowKeys([deviceId]);
		}
	};

	const setClearSelections = (value: boolean) => {
		clearSelections.current = value;
	};

	const setRedirectPage = (value: boolean) => {
		redirectPage.current = value;
	};

	const setSearchPerformed = (value: boolean) => {
		isSearchPerformed.current = value;
	};

	const getSelectedControllerContacts = async (): Promise<ControllerContact[]> => {
		const { Entity: controllerContact } = await deviceAdminApi.getControllerContactsBySelectedKeys(deviceObjectType, selectedRowKeys as string[]);

		return controllerContact;
	};

	const handleActionScope = async (sender: OptionsDigiTrac) => {
		const key: string = sender.key;
		switch (key) {
			case 'delete':
				const tableDataSelected = await getSelectedControllerContacts();
				ModalConfirmationList({
					type: deleteDeviceMessages.find(x => x.Key === deviceObjectType)?.Value ?? '',
					translationKey: 'AreYouSureYouWishToDelete',
					data: tableDataSelected,
					onConfirm: () => handleDeleteOkConfirmation(tableDataSelected),
				});
				return;

			case 'sendCommand':
				handleSendDeviceCommand(sender);
				return;
		}

		const device: ControllerContact = tableData.find(x => x.Id.toString() === selectedRowKeys?.[0]);
		const deviceId = device.Id;
		const address = device.Address;

		switch (key) {
			case 'edit':
				let isValid = true;
				switch (currentDevice.DeviceObjectType) {
					case DeviceObjectType.Door:
						const isDoorEntryReaderOnly = await deviceAdminApi.getIsDoorEntryReaderOnly(deviceId);
						NotificationStatus({
							responseData: isDoorEntryReaderOnly,
							notUseDefaultNotification: true,
							onSuccessCallback: () => {
								if (
									isDoorEntryReaderOnly.Entity &&
									canViewExitReaderTabDoor &&
									!canViewGeneralTabDoor &&
									!canViewEntryReaderTabDoor &&
									!canViewRelayTabDoor &&
									!canViewInputTabDoor
								) {
									isValid = false;
									notification['info']({
										message: isDoorEntryReaderOnly.ErrorMessage,
									});
								}
							},
						});

						break;
					case DeviceObjectType.Reader:
						if (!canViewGeneral && !canViewLogic && !canViewOptions && !canViewCardReaderSetup) {
							isValid = false;
							notification['info']({
								message: _('CanNotViewTabsMessage'),
							});
						}
						break;
					case DeviceObjectType.Controller:
						if (cannotViewAnyControllerTabs()) {
							isValid = false;
							notification['info']({
								message: _('CanNotViewTabsMessage'),
							});
						} else {
							setClearSelections(false);
							const params = new URLSearchParams(location.search);
							params.set('controllerId', deviceId.toString());
							window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
							dispatch(
								setControllerSelectedKey({
									Id: deviceId.toString(),
									PaginationPage: tablePaginationSetting.PageNumber,
								} as SelectedRowKeyPagination)
							);
						}
						break;
				}
				if (isValid) {
					const newDevice = { ...currentDevice, Id: deviceId, Address: address, IsModalOpen: !currentDevice.IsModalOpen };
					dispatch(setCurrentDevice(newDevice));
				}
				break;

			case 'rename':
				const cloneState: ControllerContact[] = changeStateProps(deviceId, { editable: true });
				const isLocked: boolean = await lockedValidation(currentDevice.DeviceObjectType, deviceId);
				if (!isLocked) {
					dispatch(setControllerContactsByData(deviceObjectType, cloneState));
					dispatch(setDigiTracEditMode(true));
					const findKey = mapData.find(x => x.key === deviceId.toString());
					edit(findKey);
				}
				break;
		}
	};

	const changeStateProps = (deviceId: number, controllerContact: Partial<ControllerContact>): ControllerContact[] => {
		const cloneState: ControllerContact[] = [...tableData];
		const index = cloneState.findIndex(x => x.Id === deviceId);
		if (~index) {
			const item = cloneState[index];
			cloneState.splice(index, 1, { ...item, ...controllerContact });
		}

		return cloneState;
	};

	const edit = (record: Partial<ControllerContactMappedData>) => {
		form.setFieldsValue({
			Name: '',
			...record,
		});
		setEditingKey(record.key.toString());
	};
	//#endregion

	const handleOnSaveEditRow = async (deviceId: number) => {
		const isLocked: boolean = await lockedValidation(currentDevice.DeviceObjectType, deviceId);
		if (isLocked) {
			setEditingKey('');
			const cloneState: ControllerContact[] = changeStateProps(deviceId, { editable: false });
			dispatch(setControllerContactsByData(deviceObjectType, cloneState));
			dispatch(setDigiTracEditMode(false));
			return;
		}

		const name: string = form.getFieldValue('Name');
		const nameError: ResponseObject = await deviceAdminApi.renameDevice(currentDevice.DeviceObjectType, name.trim(), deviceId);
		if (nameError.ResponseStatusCode !== ResponseStatusCode.Success && nameError.ErrorMessage) {
			form.setFields([
				{
					name: 'Name',
					errors: [nameError.ErrorMessage],
				},
			]);
			return;
		} else if (nameError.ResponseStatusCode === ResponseStatusCode.PermissionError) {
			notification['error']({
				message: nameError.ResponseErrorDescription,
			});
			setEditingKey('');
			dispatch(setDigiTracEditMode(false));
			dispatch(setControllerContacts(deviceObjectType, tablePaginationSetting));
			return;
		}

		setEditingKey('');
		dispatch(setDigiTracEditMode(false));
		if (isFilterMode) {
			setShouldResetSearchColumn(true);
		}
		setRedirectPage(true);
		dispatch(setControllerContacts(deviceObjectType, tablePaginationSetting));
	};

	const handleOnCancelEditRow = deviceId => {
		const cloneState: ControllerContact[] = changeStateProps(deviceId, { editable: false });
		dispatch(setControllerContactsByData(deviceObjectType, cloneState));
		dispatch(setDigiTracEditMode(false));
		setEditingKey('');
	};

	const getControllerContactIds = async (): Promise<string[]> => {
		const res = await deviceAdminApi.getControllerContacts(deviceObjectType, true);
		return res?.Entity.map<string>(x => x.Id?.toString());
	};

	const getSelectedRowKeysForSelectAll = async (newSelectedRowKeys: React.Key[]): Promise<React.Key[]> => {
		const controllerContactsIds = await getControllerContactIds();
		return getUniqueValuesArray(newSelectedRowKeys, controllerContactsIds);
	};

	const handleChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneState: ControllerContact[] = tableData.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id.toString()) !== -1 }));
		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setControllerContactsByData(deviceObjectType, cloneState));
		dispatch(setDigiTracSelectedRowKeysPagination(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectAll = async () => {
		setTableLoading(true);
		const newSelectedRowKeys = await getSelectedRowKeysForSelectAll(selectedRowKeys);

		const cloneState: ControllerContact[] = tableData.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(r => r === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setControllerContactsByData(deviceObjectType, cloneState));
		dispatch(setDigiTracSelectedRowKeysPagination(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
		setTableLoading(false);
	};

	const handleSelectInvert = () => {
		const dataKeys = tableData.map<React.Key>(x => x.Id?.toString());

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRowKeys);

		const cloneState: ControllerContact[] = tableData.map(x => ({ ...x, checked: newSelectedRowKeys.findIndex(key => key === x.Id?.toString()) !== -1 }));

		const selectedKeysPagination: SelectedRowKeyPagination[] = getSelectedRowKeysPagination(
			newSelectedRowKeys,
			selectedRowKeysPagination,
			tablePaginationSetting.PageNumber,
			setIsItemOnSamePage
		);
		dispatch(setControllerContactsByData(deviceObjectType, cloneState));
		dispatch(setDigiTracSelectedRowKeysPagination(selectedKeysPagination));
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const createActionElement = (device: ControllerContact): React.ReactNode => {
		let content: React.ReactNode = undefined;
		if (device.editable) {
			const deviceId = device.Id;

			content = (
				<>
					<Button id='digitracConfigurationRenameSaveButton' key='save' type='primary' onClick={() => handleOnSaveEditRow(deviceId)}>
						{_('Save')}
					</Button>
					<Button id='digitracConfigurationRenameCancelButton' key='cancel' onClick={() => handleOnCancelEditRow(deviceId)}>
						{_('Cancel')}
					</Button>
				</>
			);
		}

		return <div className={styles.actions}>{content}</div>;
	};

	const mapData = tableData.map(
		(controllerContact: ControllerContact): Partial<ControllerContactMappedData> => ({
			key: controllerContact.Id?.toString(),
			Action: createActionElement(controllerContact),
			Name: controllerContact.Name,
			Address: controllerContact.Address,
			Status: controllerContact.Status,
			...getSpecificData(currentDevice.DeviceObjectType, controllerContact),
		})
	);

	const showNoAvailableSerialPorts = newDevice => {
		ModalWarning({
			title: _('SerialCOMPorts'),
			content: _('NoAvailableSerialCOMPorts'),
			onOk: () => dispatch(setCurrentDevice(newDevice)),
			onCancel: () => dispatch(setCurrentDevice(newDevice)),
			okText: _('Ok'),
			width: '550px',
		});
	};

	const validateCOMPorts = async newDevice => {
		const { Entity } = await deviceAdminApi.getAvailableCOMPorts();

		if (Array.isArray(Entity) && !Entity.length) {
			showNoAvailableSerialPorts(newDevice);
		} else {
			dispatch(setCurrentDevice(newDevice));
		}
	};

	const getButtons = (): ButtonBuilder[] => {
		let buttons: ButtonBuilder[] = [];

		let newDevice;
		switch (deviceObjectType) {
			case DeviceObjectType.Port:
				newDevice = { ...currentDevice, Id: 0, IsModalOpen: true };
				buttons = [
					{
						id: 'digitracAddPortButton',
						label: _('AddPort'),
						icon: <PlusOutlined />,
						onClick: () => validateCOMPorts(newDevice),
						disabled: !portComponentPermission.canAdd || isEditMode,
						title: getPermissionErrorMessage(portComponentPermission.canAdd),
					},
				];
				break;

			case DeviceObjectType.Xbox:
				newDevice = { ...currentDevice, Id: 0, Address: '', IsModalOpen: true };
				buttons = [
					{
						id: 'digitracAddXboxButton',
						label: _('AddXbox'),
						icon: <PlusOutlined />,
						onClick: () => dispatch(setCurrentDevice(newDevice)),
						disabled: !xboxComponentPermission.canAdd || isEditMode,
						title: getPermissionErrorMessage(xboxComponentPermission.canAdd),
					},
				];
				break;

			case DeviceObjectType.Controller:
				newDevice = { ...currentDevice, Id: 0, Address: '', IsModalOpen: true };
				buttons = [
					{
						id: 'digitracAddControllerButton',
						label: _('AddController'),
						icon: <PlusOutlined />,
						onClick: () => dispatch(setCurrentDevice(newDevice)),
						disabled: !controllerComponentPermission.canAdd || isEditMode,
						title: getPermissionErrorMessage(controllerComponentPermission.canAdd),
					},
				];
				break;
		}

		return buttons;
	};

	const buttonsBuilder = (): React.ReactNode =>
		getButtons().map(x => (
			<Button
				id={x.id}
				key={x.label}
				onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
					e.preventDefault();
					x.onClick();
				}}
				disabled={x.disabled}
				title={x.title}
				type='primary'>
				<label htmlFor={x.id}>
					{x.icon} {x.label}
				</label>
			</Button>
		));

	const rowSelection: TableRowSelection<Partial<ControllerContactMappedData>> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(disabled, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => ({
			id: `controllerContactTableCheckbox-${record.key}`,
			disabled,
			children: <label htmlFor={`controllerContactTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const handleOnChangeTable = (pagination, filters, sorter) => {
		const { order, field } = sorter;
		let sortOrder = SortDirections.None;
		const sortField = field ?? '';

		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		if (sortOrder !== tablePaginationSetting.SortDirection) {
			clearSelection();
		}

		const { current, pageSize } = pagination;

		handleChangePagination(current, pageSize);

		const { PageNumber, PageSize, SortDirection, SortField } = tablePaginationSetting;

		const shouldUpdateTableResults =
			(current !== PageNumber || pageSize !== PageSize || sortOrder !== SortDirection || sortField !== SortField) &&
			!isSearchPerformed.current &&
			!shouldResetSearchColumn;

		const filter: string[] = filters ? filters.Name : [];

		const shouldUpdateSearchResults =
			isSearchPerformed.current && filter !== null && filter.findIndex(x => x === tablePaginationSetting.SearchedValue) !== -1;
		if (isSearchPerformed.current) isSearchPerformed.current = false;

		if (shouldUpdateTableResults || shouldUpdateSearchResults) {
			dispatch(
				setControllerContacts(deviceObjectType, {
					...tablePaginationSetting,
					PageNumber: current,
					PageSize: pageSize,
					SortDirection: sortOrder,
					SortField: sortField,
				})
			);
		}
	};

	const disabledActionButtons = disabled || selectedRowKeys.length === 0;

	return (
		<div className={styles.container}>
			<div className={styles.buttonContainer}>
				{buttonOptions.map(x => (
					<DropdownMenu
						id={x.id}
						label={x.label}
						menuOptions={x.options}
						key={x.label}
						title={x.title}
						disabled={disabledActionButtons || x.disabled}
						onClickOption={handleSendDeviceCommand}
					/>
				))}
				{items.options.length > 0 && (
					<ButtonDropdown
						id={'digitracConfigurationActionDropdown'}
						menuOptions={items.options}
						icon={<CaretDownOutlined />}
						labelIcon={items.labelOrIcon}
						onClickOption={handleActionScope}
						disabled={disabledActionButtons}
					/>
				)}
				{buttonsBuilder()}
			</div>
			<Form form={form} component={false}>
				<Table
					id='controllerContactTable'
					columns={getMergedColumns({
						columns,
						menuOptions: buttonOptions,
						additionalItems: items.options,
						maxLength,
						isEditMode,
						onChangeSelection,
						onClickOption: handleActionScope,
						editingKey,
					})}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					dataSource={mapData}
					scroll={scroll}
					pagination={pagination}
					size='small'
					className={styles.statusWidgetTable}
					rowSelection={rowSelection}
					onChange={handleOnChangeTable}
					loading={isTableLoading}
				/>
			</Form>
			{openModal && currentDevice.DeviceObjectType === DeviceObjectType.Port && (
				<PortModal
					id={currentDevice.Id}
					address={currentDevice.Address}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
			{openModal && currentDevice.DeviceObjectType === DeviceObjectType.Xbox && (
				<XboxModal
					xboxToBeEditable={currentDevice}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
			{openModal &&
				(currentDevice.DeviceObjectType === DeviceObjectType.Input || currentDevice.DeviceObjectType === DeviceObjectType.ExpansionInputs) && (
					<InputModal
						currentDeviceObj={currentDevice}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						setRedirectPage={() => setRedirectPage(true)}
					/>
				)}
			{openModal &&
				(currentDevice.DeviceObjectType === DeviceObjectType.Relay || currentDevice.DeviceObjectType === DeviceObjectType.ExpansionRelays) && (
					<ExpansionRelayModal
						currentDeviceObj={currentDevice}
						setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
						setRedirectPage={() => setRedirectPage(true)}
					/>
				)}
			{openModal && currentDevice.DeviceObjectType === DeviceObjectType.Reader && (
				<ReaderModal
					id={currentDevice.Id}
					address={currentDevice.Address}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
			{openModal && currentDevice.DeviceObjectType === DeviceObjectType.Door && (
				<DoorModal
					currentDevice={currentDevice}
					setShouldResetSearchColumn={() => setShouldResetSearchColumn(true)}
					setRedirectPage={() => setRedirectPage(true)}
				/>
			)}
			{openModal && currentDevice.DeviceObjectType === DeviceObjectType.Controller && currentDevice.Id === 0 && <AddControllerModal />}
		</div>
	);
});

export { DigiTrac };

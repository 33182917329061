import { Button, Spin, Table } from 'antd';
import { ColumnProps, ColumnsType } from 'antd/lib/table';
import React, { CSSProperties, useEffect, useState } from 'react';
import { buildColumn, ScrollType } from '../../../../Helper';
import { AffectedCredential, AssignCredentialDetail } from '../../../../model/EnrollmentModel';
import { Modal } from '../../../common';
import { AssignCredentialWarningData } from './AssignCredential';
import styles from './assignCredential.module.scss';

type Props = {
	onCancel: () => void;
	onConfirm: (eligibleCredentials: AffectedCredential[]) => void;
	assignCredentialDetails: AssignCredentialDetail;
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: '25vh' };
const modalBodyStyle: CSSProperties = { maxHeight: '60vh', height: '100%', minHeight: '25vh' };
const modalPositionStyle: CSSProperties = { top: 40, verticalAlign: 'top' };

const AssignCredentialWarning: React.FC<Props> = ({ assignCredentialDetails, onCancel, onConfirm }) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [dataSource, setDataSource] = useState<AssignCredentialWarningData[]>([]);

	useEffect(() => {
		const dataSource: AssignCredentialWarningData[] = assignCredentialDetails.Ineligible.map<AssignCredentialWarningData>(
			(credential: AffectedCredential) => ({
				...credential,
				key: credential.CredentialId,
			})
		);
		setDataSource(dataSource);
		setIsLoading(false);
	}, []);

	const columns: ColumnProps<AssignCredentialWarningData>[] = [
		{
			...buildColumn(_('CID'), 'CredentialId', 'auto', 'start'),
		},
		{
			...buildColumn(_('Description'), 'Description', 'auto', 'start'),
		},
		{
			...buildColumn(_('LastName'), 'LastName', 'auto', 'start'),
		},
		{
			...buildColumn(_('FirstName'), 'FirstName', 'auto', 'start'),
		},
	];

	return (
		<Modal
			title={_('WarningMessage')}
			width={700}
			visible={true}
			onCancel={onCancel}
			bodyStyle={modalBodyStyle}
			style={modalPositionStyle}
			footer={[
				<Button id='cancelAssignCredentialButton' key='cancel' onClick={onCancel}>
					{_('Cancel')}
				</Button>,
				<Button id='continueAssignCredentialButton' key='continue' type='primary' onClick={() => onConfirm(assignCredentialDetails.Eligible)}>
					{_('Continue')}
				</Button>,
			]}>
			<div id='assignCredentialWarningContainer'>
				<p>{_('AssignedCredentialsReassign')}.</p>
				<p>{_('TheFollowingCredentialsAreAssigned')}:</p>
				<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
					<Table
						scroll={scroll}
						size='small'
						columns={columns as ColumnsType}
						dataSource={dataSource}
						className={styles.assignCredentialWarningTable}
						pagination={false}
						rowClassName={(record: AssignCredentialWarningData, index) => {
							if (index % 2 !== 0) {
								return styles.evenRow;
							}
						}}
					/>
				</Spin>
				<br />
				<p>{_('WouldYouLikeToContinueAssignCredential')}?</p>
			</div>
		</Modal>
	);
};

export { AssignCredentialWarning };

import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { CSSProperties } from 'react';
import styles from './modalInfo.module.scss';

type Props<T> = {
	onOk?: (value: T) => void;
	onCancel?: (value: T) => void;
	content: React.ReactNode;
	title?: string | React.ReactNode;
	okText?: string;
	cancelText?: string;
	width?: string;
	style?: CSSProperties;
	hideCancelButton?: boolean;
	icon?: React.ReactElement;
};

const ModalInfo = <T extends object | string | number>({ content, onCancel, onOk, title, cancelText, okText, width, style, hideCancelButton }: Props<T>) => {
	return Modal.info({
		keyboard: false,
		maskClosable: false,
		style,
		width,
		content,
		title,
		onOk: onOk,
		onCancel,
		okText: okText || 'Yes',
		cancelText: hideCancelButton ? undefined : cancelText || 'No',
		icon: <InfoCircleOutlined className={styles.icon} />,
		className: styles.body,
		okButtonProps: { className: styles.okButton },
	});
};

export { ModalInfo };

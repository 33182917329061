import { TransferItem } from 'antd/lib/transfer';
import React from 'react';

export type CodeProperties = {
	E: string;
	N: string;
};

export type ChartData = {
	type: string;
	value: number;
	color: string;
};

export type GridDataItem = {
	Key: string;
	Value: string;
};

export type GridDataColumnItem = GridDataItem & {
	DisableSort: boolean;
	IsDateFormat: boolean;
	IsAuxColumn: boolean;
	DataIndex: string;
};

export type GridColumn = {
	Key: string;
	Header: string;
	Order: number;
	Width: number;
	Visible: boolean;
	IsDateFormat: boolean;
	IsAuxColumn: boolean;
	DataIndex: string;
};

export type GridItem = {
	ObjectId: number;
	Columns: GridDataColumnItem[];
	Data: GridDataItem[][];
	TotalItems?: number;
	PaginationSetting: PaginationSetting;
};

export type Tuple = {
	Item1: any;
	Item2: any;
};

export type TupleTyped<T, P> = {
	Item1: T;
	Item2: P;
};

export type TupleExtraItem = {
	Item3: any;
} & Tuple;

export type SelectOptions<T> = {
	key?: string | number;
	value?: T;
	label: string;
	icon?: React.ReactNode;
	id?: string;
	separator?: boolean;
	disabled?: boolean;
	title?: string;
	nestedOptions?: SelectOptions<T>[];
	leaveTab?: boolean;
};

export type ButtonOption = {
	id?: string;
	disabled?: boolean;
	icon?: React.ReactNode;
	label: string;
	separator?: boolean;
	onClick?: () => void;
};

export enum ResponseStatusCode {
	Success = 0,
	DatabaseAccessError = 1,
	PermissionError = 2,
	InputError = 3,
	CommunicationError = 4,
	FailedValidation = 5,
	SystemError = 6,
	AdditionalInfoRequired = 7,
	ComponentLocked = 8,
	EventQueueExpired = 9,
	ServiceSessionExpired = 10,
}

export type ResponseObject = {
	ResponseStatusCode: ResponseStatusCode;
	AdditionalResponseInfo: string;
	ResponseErrorDescription: string;
	ErrorMessage: string;
	ResponseObjectId: number;
};

export type ResponseObjectEntity<T> = {
	Entity: T;
} & ResponseObject;

export type DefaultGridElement = {
	editable?: boolean;
	checked?: boolean;
};

export type BaseColumns = {
	key: React.Key;
};

export type PermissionColumn = {
	Action: React.ReactNode;
	Name: string;
	SubItemID: number;
	Allowed: boolean;
} & BaseColumns;

export type DefaultColumns = {
	Name: string;
	Action: React.ReactNode;
} & BaseColumns;

export type OperatorsColumn = {
	Action: React.ReactNode;
	Name: string;
	OperatorStatus: string;
	Bypass2FA?: boolean;
} & BaseColumns;

export type OperatorKeyColumn = {
	Action: React.ReactNode;
	Id: number;
	Name: string;
	CreatedDate: Date | string;
} & BaseColumns;

export type RolesColumn = {
	Action: React.ReactNode;
	Name: string;
	Description?: string;
} & BaseColumns;

export type DefaultColumnsCredential = {
	Action: React.ReactNode;
	Description: string;
	CredentialId: number;
	IsDefault: boolean;
} & BaseColumns;

export enum SortDirections {
	None,
	Ascend,
	Descend,
}

export type SorterConfig = {
	direction: SortDirections;
	field: string;
};

export type SorterConfigTyped<T> = {
	direction: SortDirections;
	field: T;
};

export type SearchSettings<T> = {
	Field: T;
	SearchValue: string;
};

export type ItemsPagination = {
	TotalItemsPaginated: number;
};

export type PaginationSetting = {
	PageNumber: number;
	PageSize: number;
	SortDirection?: SortDirections;
	SortField?: string;
	SearchedValue?: string;
	TotalItems?: number;
};

export type PaginationSettingTyped<T> = {
	PageNumber: number;
	PageSize: number;
	SortDirection: SortDirections;
	SortField: T;
	SearchSettings: SearchSettings<T> | SearchSettings<T>[];
};

export type DictionaryCustom<K, T> = {
	Key: K;
	Value: T;
};

export type ObjectTypeTransfer<T> = T & TransferItem;

export enum WithinTimes {
	none,
	anHour,
	twelveHours,
	aDay,
	twoDays,
	threeDays,
	aWeek,
	aMonth,
	aYear,
}

export type CriteriaField = {
	FieldId: number;
	UDFNumber?: number;
	DisplayName: string;
	Type: FieldDataType;
};

export enum CriteriaOperatorFunction {
	none,
	is,
	isNot,
}

export enum CriteriaFunctionValue {
	none,
	equalTo,
	lessThan,
	greaterThan,
	between,
	startingWith,
	like,
	within,
	lessThanOrEqualTo,
	greaterThanOrEqualTo,
}

export enum SearchEqualToKey {
	none,
	searchByText,
	searchByPagination,
}

export enum UserProfileDropdownAction {
	about = 'about',
	help = 'help',
	settings = 'settings',
	logout = 'logout',
}

export type CriteriaInfo = CriteriaRequest & {
	key: number;
	fieldName: string;
	criteriaName: string;
	functionName: string;
	criteriaSetup: CriteriaSetup;
};

export type CriteriaSearchRequest = {
	CriteriaRequest: CriteriaRequest[];
};

export type CriteriaRequest = {
	FieldId: number;
	Function: CriteriaFunctionValue;
	FunctionOperator: CriteriaOperatorFunction;
	PrimaryInput: string;
	SecondaryInput: string;
	OrNextCriteria: boolean;
	Type: FieldDataType;
	IsUDF: boolean;
	IsEqualTo: boolean;
	UDFNumber: number;
};

export type CriteriaSetup = {
	Function: CriteriaFunctionValue;
	FunctionOperator: CriteriaOperatorFunction;
	Within?: WithinTimes;
};

export enum FieldDataType {
	String,
	Numeric,
	Boolean,
	DateTime,
}

export type PreviewBadgeResponse = ResponseObject & {
	Width: number;
	Height: number;
	IsLandscape: boolean;
	PhotoCredentialsBase64: string[];
	CredentialId: number;
	CredentialDescription: string;
	ShowBack: boolean;
};

export type SorterConfigEx = {
	order: string;
	columnKey: string;
};

export type SelectedDevicePage = {
	deviceId: number;
	pageNumber: number;
};

export type SelectedDeviceKeys = {
	Id: number;
	Name: string;
	IsMasterDoorGroup: boolean;
};

export type ErrorMessage = {
	message?: string;
	isInvalid?: boolean;
};

export enum CheckboxMenuOptions {
	SELECT_ALL = 0,
	CLEAR_ALL,
}

export enum KeyCodes {
	ENTER = 'Enter',
	ARROW_UP = 'ArrowUp',
	ARROW_DOWN = 'ArrowDown',
}

export type OptionsBuilderGeneric<T> = {
	id?: string;
	label: string;
	value?: T;
	options?: SelectOptions<T>[];
	disabled?: boolean;
	title?: string;
};

export type DigitracAvailableOptions = {
	isActionOptionsAvailable?: boolean;
	isControlOptionsAvailable?: boolean;
};

export type DigitracDisabledOptions = {
	isActionDisabled?: boolean;
	isControlDisabled?: boolean;
};

export type PortResetEncryptionActionOptions = {
	hasMoreThanOne: boolean;
	hasResetEncryption: boolean;
};

export enum DeviceActionTypes {
	DELETE = 0,
	RENAME,
	CONFIGURE,
	SET_AS_PRIMARY,
}

export type BeforeUploadPromise = {
	resolve: () => void;
	reject: () => void;
};

export type ImageResolution = {
	width: number;
	height: number;
	description?: string;
	callback?: (message: string) => void;
};

import { Input } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import { IPv4AddressInput } from '../../../../../../common/IPv4AddressInput/IPv4AddressInput';
import { PortStoreContext, setSubnetMask, setSubnetPrefix } from '../../../contextPort';
import styles from '../../../portModal.module.scss';

type Props = {
	subnetMaskValidation: boolean;
	onResetSubnetMaskValidation: () => void;
};

const SubnetMask: React.FC<Props> = ({ subnetMaskValidation, onResetSubnetMaskValidation }) => {
	const {
		portState: { subnetMask, portNetworkType, subnetPrefix, protocol },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		onResetSubnetMaskValidation();
	}, [subnetMask, subnetPrefix]);

	const handleSubnetPrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setSubnetPrefix(value));
	};

	const handleSubnetMaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setSubnetMask(value));
	};

	const portGeneralSubnetMaskInputId = 'portGeneralSubnetMaskInput';

	const subnetMaskInput = (
		<>
			<label id='portGeneralSubnetMaskLabel' htmlFor={portGeneralSubnetMaskInputId}>
				{_('SubnetMask')}
			</label>
			<IPv4AddressInput
				id='portGeneralSubnetMaskInput'
				key='subnetMask'
				disabled={protocol === 2}
				addressValidation={subnetMaskValidation}
				value={subnetMask}
				onChange={handleSubnetMaskChange}
			/>
		</>
	);

	const subnetPrefixInput = (
		<>
			<label id='portGeneralSubnetPrefixLabel'>{_('SubnetPrefixLength')}</label>
			<Input
				id='portGeneralSubnetPrefixInput'
				key='subnetPrefix'
				value={subnetPrefix}
				className={cx(styles.input, {
					[styles.error]: subnetMaskValidation,
				})}
				onChange={handleSubnetPrefixChange}
				maxLength={5}
			/>
		</>
	);

	const errorMessage = (
		<>
			{portNetworkType > 2 ? (
				<label id='portGeneralSubnetPrefixErrorLabel' className={styles.errorMessage}>
					{_('SubnetPrefixIsRequired')}
				</label>
			) : (
				<label id='portGeneralSubnetMaskErrorLabel' className={styles.errorMessage}>
					{_('SubnetMaskIsRequired')}
				</label>
			)}
		</>
	);

	return (
		<>
			<div className={styles.inputContainer}>{portNetworkType > 2 ? subnetPrefixInput : subnetMaskInput}</div>
			<div className={styles.inputValidation}>{subnetMaskValidation && errorMessage}</div>
		</>
	);
};

export { SubnetMask };

import { Button, Spin, Tabs, TabsProps, notification } from 'antd';
import cx from 'classnames';
import JSEncrypt from 'jsencrypt';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { getMaskMechanism, handleResponse } from '../../../../../Helper';
import { accountApi, deviceAdminApi } from '../../../../../api';
import { SubPermissions, User, getPermissionErrorMessage } from '../../../../../model/AccountModel';
import { ResponseStatusCode } from '../../../../../model/CommonModel';
import { OperatorModel } from '../../../../../model/DeviceAdminModel';
import { OperatorCredential, OperatorPasswordErrorMessage, OperatorPropertyModel } from '../../../../../model/OperatorModel';
import { ModalConfirmCustomFooter } from '../../../../common';
import { Modal } from '../../../../common/Modal/Modal';
import { setErrorEntryFlagAction, setErrorEntryFlagsAction, setOperatorAction, setRootModelPropertiesAction } from './OperatorContext/action';
import { OperatorStoreContext } from './OperatorContext/context';
import { OperatorPermissionModal } from './OperatorPermissionModal/OperatorPermissionModal';
import { OperatorPropertyGeneralTab } from './OperatorPropertyGeneralTab/OperatorPropertyGeneralTab';
import { OperatorPropertyRoleTab } from './OperatorPropertyRolesTab/OperatorPropertyRolesTab';
import { OperatorPropertyTwoFactor } from './OperatorPropertyTwoFactorTab/OperatorPropertyTwoFactor';
import styles from './operatorsModal.module.scss';

type ModalComponentProps = {
	operator: OperatorModel;
	onConfirm: () => void;
	onCancel: () => void;
	authPath: string;
	domains: string[];
	operatorPropertyModel?: OperatorPropertyModel;
};

const user: User = getUser();
const isUserAdmin: boolean = user.isAdmin;
const TwoFASubPermission = User.getSubComponentPermission(user, SubPermissions.Web_Client_MultiFactor_TwoFactorAdministrator);
const isUser2FAAdmin: boolean = TwoFASubPermission.allowed;
const isTheConnectionSecure = isConnectionSecure();

const ModalComponent: React.FC<ModalComponentProps> = ({ operator, onConfirm, onCancel, authPath, domains, operatorPropertyModel }) => {
	const {
		operatorInitialState: {
			rootContext,
			operatorRoleModifications,
			windowsCredential,
			operator: operatorState,
			isSecurityKeyTableInEditMode,
			securityKeyModifications,
			isTwoFactorTabModified,
		},
		dispatcher,
	} = useContext(OperatorStoreContext);
	const { entryError, foundInAD } = rootContext;
	const { isGeneralTabError, isRoleTabError } = entryError;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>(user.isAdmin ? '1' : '3');
	const [title, setTitle] = useState<string>(_('AddNewOperator'));
	const [passwordErrorMessage, setPasswordErrorMessage] = useState<OperatorPasswordErrorMessage>({
		PasswordsDoNotMatch: '',
		PasswordTooShort: '',
		PasswordOnlyAlphaNumeric: '',
		PasswordConfirmMustNotBeEmpty: '',
	} as OperatorPasswordErrorMessage);
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
	const [isWarningModalLoading, setIsWarningModalLoading] = useState<boolean>(false);
	const [openPermissionModal, setOpenPermissionModal] = useState<boolean>(false);
	const [isReadyToSaveParams, setIsReadyToSaveParams] = useState<{ isValid: boolean; applyDefaultRole: boolean }>({
		isValid: false,
		applyDefaultRole: false,
	});

	useEffect(() => {
		if (operator.Id > 0) {
			deviceAdminApi.getOperatorFormCaption().then(res => {
				if (!handleResponse(res)) {
					const formCaption: string = res.Entity;
					if (formCaption?.length > 0) {
						const formCaptionParts: string[] = formCaption.split('-');
						if (formCaptionParts.length > 1) {
							const strippedName: string = operator.Name.replace(/^\\\\/, '');
							const nameParts: string[] = strippedName.split('\\');
							if (nameParts?.length > 1) {
								if (nameParts[0]?.trim().length === 0) {
									setTitle(`${formCaptionParts[0].trim()} - ${nameParts[1]} ${formCaptionParts[1].trim()}`);
								} else {
									setTitle(`${formCaptionParts[0].trim()} - ${nameParts[1]} (${nameParts[0]}) ${formCaptionParts[1].trim()}`);
								}
							} else {
								setTitle(`${formCaptionParts[0].trim()} - ${operator.Name} ${formCaptionParts[1].trim()}`);
							}
						}
					}
				}
			});
			if (operatorPropertyModel) {
				dispatcher(
					setOperatorAction({
						...operatorPropertyModel,
						Id: operatorPropertyModel.Id,
						Name: operatorPropertyModel.Name,
						OperatorGuid: operatorPropertyModel.OperatorGuid,
						Bypass2FA: operatorPropertyModel.Bypass2FA,
					})
				);
			}
		}
		deviceAdminApi.getOperatorErrorMessages().then(res => {
			if (!handleResponse(res)) {
				const errorMessages: OperatorPasswordErrorMessage = res.Entity;
				setPasswordErrorMessage(errorMessages);
			}
		});
	}, []);

	useEffect(() => {
		return () => {
			accountApi.clearSecurityKeyRegistrationCache();
		};
	}, []);

	const persistNewOperator = async (applyDefaultRole: boolean = false): Promise<boolean> => {
		const response = await deviceAdminApi.createNewOperator(
			operatorState,
			!applyDefaultRole ? operatorRoleModifications.subscribedRoleIds.map(key => Number(key)) : [2],
			securityKeyModifications
		);
		if (handleResponse(response)) {
			return response.Entity;
		}
		return true;
	};

	const updateOperatorSecurityKeys = async (): Promise<boolean> => {
		let isSuccess: boolean = false;
		const response = await deviceAdminApi.updateOperatorSecurityKeys(operatorState, securityKeyModifications);
		if (!handleResponse(response)) {
			isSuccess = true;
		}

		return isSuccess;
	};

	const updateOperator2fa = async (): Promise<boolean> => {
		let isSuccess: boolean = false;
		const response = await deviceAdminApi.updateOperator2fa(operatorState, securityKeyModifications);
		if (!handleResponse(response)) {
			isSuccess = true;
		}

		return isSuccess;
	};

	const updateOperator = async (): Promise<boolean> => {
		const response = await deviceAdminApi.updateOperator(
			operatorState,
			operatorRoleModifications.subscribedRoleIds.map(key => Number(key)),
			operatorRoleModifications.unsubscribedRoleIds.map(key => Number(key)),
			securityKeyModifications
		);
		if (handleResponse(response)) {
			return response.Entity;
		}
		return true;
	};

	const doesDefaultOperatorRoleExist = async (): Promise<boolean> => {
		const res = await deviceAdminApi.doesDefaultOperatorRoleExist(2, 'Operators');
		if (!handleResponse(res)) {
			return res.Entity;
		}

		return false;
	};

	const items: TabsProps['items'] = [];
	if (user.isAdmin) {
		items.push(
			{
				id: 'operatorsGeneralTabName',
				key: '1',
				forceRender: true,
				label: (
					<span className={cx({ [styles.tabError]: isGeneralTabError })} id={`operatorsGeneralTabName`}>
						{_('General')}
					</span>
				),
				children: (
					<OperatorPropertyGeneralTab
						operatorId={operator.Id}
						setIsLoading={setIsLoading}
						authPath={authPath}
						domains={domains}
						operatorPropertyModel={operatorPropertyModel}
					/>
				),
				disabled: isSecurityKeyTableInEditMode,
			},
			{
				id: 'operatorsRolesTabName',
				key: '2',
				label: (
					<span className={cx({ [styles.tabError]: isRoleTabError })} id={`operatorsRolesTabName`}>
						{_('Roles')}
					</span>
				),
				children: <OperatorPropertyRoleTab operatorId={operator.Id} />,
				disabled: isSecurityKeyTableInEditMode,
			}
		);
	}
	if (isTheConnectionSecure) {
		items.push({
			id: 'operatorsTwoFactorAdminTabName',
			key: '3',
			label: <span>{_('TwoFactor')}</span>,
			children: <OperatorPropertyTwoFactor operator={operator} />,
		});
	}

	const handleErrorNotification = (errorMessage: string = null) => {
		if (errorMessage) {
			notification['error']({
				message: errorMessage,
			});
		} else {
			notification['error']({
				message: _('OperatorEntryError'),
			});
		}
	};

	const resetErrors = () => {
		batch(() => {
			dispatcher(
				setRootModelPropertiesAction({
					...rootContext,
					entryError: {
						...rootContext.entryError,
						isGeneralTabError: false,
						isRoleTabError: false,
						isSamAccountError: false,
						isSamAccountEmpty: false,
						isFullNameEmpty: false,
						isDomainEmpty: false,
						isPasswordError: false,
						isConfirmPasswordError: false,
					},
				})
			);
		});
	};

	const areAllDataValid = async (): Promise<{ isValid: boolean; applyDefaultRole: boolean }> => {
		let isReady: { isValid: boolean; applyDefaultRole: boolean } = { isValid: false, applyDefaultRole: false };
		if (!rootContext.foundInAD) {
			const samAccountName: string = operatorState.Name?.trim();
			if (samAccountName === null || samAccountName === undefined || samAccountName === '') {
				dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isSamAccountEmpty: true, isSamAccountError: true }));
				handleErrorNotification();
				return isReady;
			}
			for (let i = 0; i < samAccountName.length; i++) {
				const char = samAccountName[i];
				if (char === ' ') {
					dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isSamAccountEmpty: false, isSamAccountError: true }));
					handleErrorNotification(_('NameCannotHaveWhiteSpace'));
					return isReady;
				}
			}
		}
		const response = await deviceAdminApi.isNewOperatorDuplicate(
			operatorState.Name,
			operatorState.DirectorySource,
			rootContext.foundInAD,
			rootContext.enableCustomGroupNames
		);
		if (handleResponse(response)) {
			dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isSamAccountError: true }));
			return isReady;
		}

		const fullName: string = operatorState.FullName?.trim();
		if (fullName === null || fullName === undefined || fullName === '') {
			dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isFullNameEmpty: true }));
			handleErrorNotification();
			return isReady;
		}

		if (rootContext.enableCustomGroupNames && (operatorState.DirectorySource === '' || operatorState.DirectorySource === null)) {
			dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isDomainEmpty: true }));
			handleErrorNotification();
			return isReady;
		}

		if (!rootContext.enableCustomGroupNames && !foundInAD) {
			const password: string = windowsCredential.password?.trim();
			const confirmPassword: string = windowsCredential.confirmPassword?.trim();

			if (password.length < 4) {
				dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isPasswordError: true }));
				handleErrorNotification(passwordErrorMessage.PasswordTooShort);
				return isReady;
			}
			const isAlphaNumeric: boolean = !/[ ']/.test(password);
			if (!isAlphaNumeric) {
				dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isPasswordError: true }));
				handleErrorNotification(passwordErrorMessage.PasswordOnlyAlphaNumeric);
				return isReady;
			}

			if (confirmPassword === null || confirmPassword === '') {
				dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isConfirmPasswordError: true }));
				handleErrorNotification(passwordErrorMessage.PasswordConfirmMustNotBeEmpty);
				return isReady;
			}
			if (password !== confirmPassword) {
				dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isPasswordError: true, isConfirmPasswordError: true }));
				handleErrorNotification(passwordErrorMessage.PasswordsDoNotMatch);
				return isReady;
			}
		}
		if (operatorRoleModifications.isReadyToSave === undefined && !operatorRoleModifications.isModified) {
			if (!(await doesDefaultOperatorRoleExist())) {
				dispatcher(setErrorEntryFlagAction({ field: 'isRoleTabError', value: true }));
				handleErrorNotification(`${_('VerifyOperatorRoles')} (Click on Roles Tab)`);
				return isReady;
			} else {
				isReady.applyDefaultRole = true;
			}
		}

		if (operatorRoleModifications.isModified && !operatorRoleModifications.isReadyToSave) {
			dispatcher(setErrorEntryFlagAction({ field: 'isRoleTabError', value: true }));
			handleErrorNotification();
			return isReady;
		}

		isReady.isValid = true;

		setIsReadyToSaveParams(isReady);

		return isReady;
	};

	const areAllDataValidForUpdate = (): boolean => {
		if (entryError.isRoleTabError) {
			handleErrorNotification(_('VerifyOperatorRoles'));
			return false;
		}
		const fullName: string = operatorState.FullName?.trim();
		if (fullName === null || fullName === undefined || fullName === '') {
			dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isFullNameEmpty: true }));
			handleErrorNotification();
			return false;
		}

		return true;
	};

	const IsSaveReady = async (): Promise<{ isValid: boolean; applyDefaultRole: boolean }> => {
		let isReadyFlags: { isValid: boolean; applyDefaultRole: boolean } = { isValid: false, applyDefaultRole: false };
		if (operator.Id === 0) {
			isReadyFlags = await areAllDataValid();
			if (isReadyFlags.isValid) {
				resetErrors();
				return isReadyFlags;
			}
		} else {
			if (areAllDataValidForUpdate()) {
				resetErrors();
				isReadyFlags.isValid = true;
				return isReadyFlags;
			}
		}

		return isReadyFlags;
	};

	const resetSaveFlags = () => {
		setIsReadyToSaveParams({ isValid: false, applyDefaultRole: false });
		setIsLoading(false);
	};

	const getCredentials = async (credential: string): Promise<string> => {
		const sessionResponse = await accountApi.getSessionMask();
		if (!handleResponse(sessionResponse)) {
			const { Entity: maskComponents } = sessionResponse;
			if (maskComponents) {
				const encryptEngine: JSEncrypt = getMaskMechanism(maskComponents);
				const encryptedCredential = encryptEngine.encrypt(credential);
				if (encryptedCredential) {
					return encryptedCredential.toString();
				}
			}
		}

		return undefined;
	};

	const onHandleSave = async (isReadyFlags: { isValid: boolean; applyDefaultRole: boolean }): Promise<void> => {
		if (isReadyFlags.isValid) {
			if (operator.Id === 0) {
				if (!rootContext.enableCustomGroupNames) {
					const credential: string = await getCredentials(operatorState.Password);
					const resp = await deviceAdminApi.createNewAccountAndOperator(
						{ ...operatorState, Password: credential },
						!isReadyFlags.applyDefaultRole ? operatorRoleModifications.subscribedRoleIds.map(key => Number(key)) : [2],
						foundInAD,
						rootContext.oUManagement,
						securityKeyModifications
					);
					setIsLoading(false);
					const { CreateNewAccount, AddUserToVelocityGroup } = resp.Entity;
					if (!CreateNewAccount || !AddUserToVelocityGroup) {
						setOpenPermissionModal(true);
					} else if (!handleResponse(resp)) {
						batch(() => {
							resetSaveFlags();
							onConfirm();
						});
					}
				} else {
					const success: boolean = await persistNewOperator(isReadyFlags.applyDefaultRole);
					if (success) {
						batch(() => {
							resetSaveFlags();
							onConfirm();
						});
					}
				}
			} else {
				if (await updateOperator()) {
					batch(() => {
						resetSaveFlags();
						onConfirm();
					});
				} else {
					batch(() => {
						resetSaveFlags();
					});
				}
			}
		} else {
			batch(() => {
				resetSaveFlags();
			});
		}
	};

	const onHandleClickSaveSecurityKeys = () => {
		setIsLoading(true);
		let isSaveSuccess: boolean = false;
		updateOperatorSecurityKeys()
			.then(isUpdateSuccess => {
				isSaveSuccess = isUpdateSuccess;
			})
			.finally(() => {
				setIsLoading(false);
				if (isSaveSuccess) {
					onConfirm();
				}
			});
	};

	const onHandleClickSave2FAOnly = () => {
		setIsLoading(true);
		let isSaveSuccess: boolean = false;
		updateOperator2fa()
			.then(isUpdateSuccess => {
				isSaveSuccess = isUpdateSuccess;
			})
			.finally(() => {
				setIsLoading(false);
				if (isSaveSuccess) {
					onConfirm();
				}
			});
	};

	const onHandleClickSave = async () => {
		setIsLoading(true);
		const isReadyFlags = await IsSaveReady();
		await onHandleSave(isReadyFlags);
	};

	const onHandleClickCancel = () => {
		if (rootContext.isGeneralTabModified || operatorRoleModifications.isModified || rootContext.foundInAD || isTwoFactorTabModified) {
			setShowWarningModal(true);
			return;
		}

		onCancel();
	};

	const onHandleWarningModalYes = async () => {
		if (isUserAdmin) {
			setIsWarningModalLoading(true);
			const isReadyFlags = await IsSaveReady();
			setIsWarningModalLoading(false);
			setShowWarningModal(false);
			await onHandleSave(isReadyFlags);
		} else if (isUser2FAAdmin) {
			setShowWarningModal(false);
			onHandleClickSave2FAOnly();
		} else {
			setShowWarningModal(false);
			onHandleClickSaveSecurityKeys();
		}
	};

	const onHandleWarningModalCancel = () => {
		setShowWarningModal(false);
	};

	const onHandleWarningModalNo = () => {
		setShowWarningModal(false);
		onCancel();
	};

	const onHandlePermissionModalConfirm = async (): Promise<void> => {
		batch(() => {
			setOpenPermissionModal(false);
			onConfirm();
		});
	};

	const onHandlePermissionModalCancel = () => {
		setOpenPermissionModal(false);
	};

	const onHandleElevatedOperation = async (elevatedCredentials: OperatorCredential): Promise<ResponseStatusCode> => {
		const credential: string = await getCredentials(operatorState.Password);
		const res = await deviceAdminApi.createNewAccountAndOperator(
			{ ...operatorState, Password: credential },
			!isReadyToSaveParams.applyDefaultRole ? operatorRoleModifications.subscribedRoleIds.map(key => Number(key)) : [2],
			foundInAD,
			rootContext.oUManagement,
			securityKeyModifications,
			elevatedCredentials
		);

		const responseCode: ResponseStatusCode = res.ResponseStatusCode;
		if (responseCode === ResponseStatusCode.Success) {
			setIsReadyToSaveParams({ isValid: false, applyDefaultRole: false });
		} else if (res.ResponseStatusCode === ResponseStatusCode.InputError) {
			const errorMessage: string = res.ErrorMessage;
			if (errorMessage?.length > 0) {
				if (errorMessage.includes('0x800708C5')) {
					dispatcher(setErrorEntryFlagsAction({ ...entryError, isGeneralTabError: true, isPasswordError: true, isConfirmPasswordError: true }));
					handleErrorNotification(errorMessage);
				}
			}
		} else {
			handleErrorNotification(res.ErrorMessage);
		}
		return responseCode;
	};

	return (
		<>
			<Modal
				visible
				title={title}
				onCancel={onHandleClickCancel}
				closable={!isSecurityKeyTableInEditMode}
				footer={[
					<Button
						id='operatorSaveButton'
						title={getPermissionErrorMessage(user.isAdmin)}
						key='save'
						type='primary'
						disabled={isSecurityKeyTableInEditMode}
						onClick={isUserAdmin ? onHandleClickSave : isUser2FAAdmin ? onHandleClickSave2FAOnly : onHandleClickSaveSecurityKeys}>
						{_('SaveChanges')}
					</Button>,
					<Button id='operatorCancelButton' key='cancel' onClick={onHandleClickCancel} disabled={isSecurityKeyTableInEditMode}>
						{_('Cancel')}
					</Button>,
				]}
				width='730px'>
				<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large' className={styles.spinContainer}>
					<div id='operatorModalInnerContainer'>
						<Tabs activeKey={activeTab} onChange={activeKey => setActiveTab(activeKey)} type='card' items={items} />
					</div>
				</Spin>
			</Modal>
			<ModalConfirmCustomFooter
				key='operatorModalCustomFooter'
				title={_('Warning')}
				width={'700px'}
				visible={showWarningModal}
				loadingOk={isWarningModalLoading}
				onOk={onHandleWarningModalYes}
				onNo={onHandleWarningModalNo}
				onCancel={onHandleWarningModalCancel}
				children={
					<label key='operatorModalSaveChangesWarning' className={styles.saveChangesWarning}>
						{_('OperatorPropertyChangesWarning')}
					</label>
				}
			/>
			{openPermissionModal && (
				<OperatorPermissionModal
					onConfirm={onHandlePermissionModalConfirm}
					onHandleElevatedOperation={onHandleElevatedOperation}
					onCancel={onHandlePermissionModalCancel}
					authPath={authPath}
					handleErrorNotification={handleErrorNotification}
				/>
			)}
		</>
	);
};

export { ModalComponent };

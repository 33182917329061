import { measureTextWidth, Pie } from '@ant-design/charts';
import { UpOutlined } from '@ant-design/icons';
import { Collapse, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { eventApi } from '../../../api';
import { getDefaultFontFamily, getEventFilter, numberWithCommas } from '../../../Helper';
import { SubPermissions, User } from '../../../model/AccountModel';
import { ChartData } from '../../../model/CommonModel';
import { EventSummary, VelocityEventModel } from '../../../model/EventModel';
import { Logger } from '../../../model/LoggingModel';
import { useStoreSelector } from '../../../store';
import { selectEventList } from '../../../store/common/selectors';
import styles from './eventSummary.module.scss';

const { Panel } = Collapse;

const user: User = getUser();
const canViewEvents: boolean = User.getSubComponentPermission(user, SubPermissions.EventViewer_Use).allowed;
const colors = getColors();

const HeaderWidget = SortableHandle(() => <span className='status-dashboard-widget-panel-header-title'>{`${_('EventsSummary')} (${_('Today')})`}</span>);
const component: React.FC = () => {
	if (!canViewEvents) {
		return <></>;
	}

	const [dataSource, setDataSource] = useState<EventSummary>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const eventList: VelocityEventModel[] = useStoreSelector<VelocityEventModel[]>(selectEventList);

	useEffect(() => {
		eventApi
			.retrieveEventSummary()
			.then(response => {
				setIsLoading(false);
				setDataSource(response.Entity);
			})
			.catch(e => Logger.writeErrorLog(e));
	}, []);

	useEffect(() => {
		if (dataSource && eventList && eventList.length > 0) {
			let updatedEventSummary: EventSummary = { ...dataSource };
			updatedEventSummary.Items.forEach(e => {
				e.Count += eventList.filter(x => x.EventType === e.EventType).length;
			});
			setDataSource(updatedEventSummary);
		}
	}, [eventList]);

	const renderStatistic = (containerWidth, text, style) => {
		const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
		const R = containerWidth / 2;

		let scale = 1;
		if (containerWidth < textWidth) {
			scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
		}

		const textStyleStr = `width:${containerWidth}px;`;
		return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
	};

	const getBasicDonutChartConfig = (data: ChartData[]): any => {
		const fontFamily: string = getDefaultFontFamily();

		const config = {
			data: data === undefined || data === null ? [] : data,
			color: data === undefined || data === null ? [] : data.map(x => x.color),
			appendPadding: 10,
			colorField: 'type',
			angleField: 'value',
			radius: 0.64,
			innerRadius: 0.64,
			label: {
				type: 'spider',
				labelHeight: 28,
				content: '{value}',
			},
			statistic: {
				title: {
					offsetY: -4,
					customHtml: (container, view, datum) => {
						const { width, height } = container.getBoundingClientRect();
						const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
						const text = datum ? datum.type : _('Total');
						return renderStatistic(d, text, {
							fontSize: 28,
						});
					},
				},
				content: {
					offsetY: 4,
					style: {
						fontSize: '32px',
						fontFamily,
					},
					customHtml: (container, view, datum, data) => {
						const { width } = container.getBoundingClientRect();
						const text: number = datum ? datum.value : data.reduce((r, d) => r + d.value, 0);

						return renderStatistic(width, numberWithCommas(text), {
							fontSize: 32,
						});
					},
				},
			},
			interactions: [
				{
					type: 'element-selected',
				},
				{
					type: 'element-active',
				},
				{
					type: 'pie-statistic-active',
				},
			],
		};

		return config;
	};

	const data: ChartData[] = dataSource?.Items.map(x => {
		return {
			type: getEventFilter(x.EventType),
			value: x.Count,
			color: `#${colors[x.EventColor]}`,
		};
	});
	const config = getBasicDonutChartConfig(data);

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
			<div className={styles.eventSummaryContainer}>
				<Collapse
					bordered={true}
					defaultActiveKey={'1'}
					expandIconPosition='right'
					expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
					className={styles.statusWidgetCollapse}>
					<Panel id='statusDashboardEventsSummaryWidget' key='1' header={<HeaderWidget />} className={styles.statusWidget}>
						{!isLoading && <Pie {...config} />}
					</Panel>
				</Collapse>
			</div>
		</Spin>
	);
};

export { component as EventSummary };

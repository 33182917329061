import { Dropdown, Menu } from 'antd';
import React from 'react';
import { WhosInsideOptions } from '../../../model/WhosInsideModel';
import styles from '../whosInside.module.scss';

type Props = {
	menu: WhosInsideOptions[];
	onVisibleChange?: (visible: boolean) => void;
};

const WhosInsideContextMenu: React.FC<Props> = ({ children, menu, onVisibleChange }) => {
	const menuOptions = (
		<Menu id='whosInsideContextMenu'>
			{menu.map(x => (
				<Menu.Item
					className={x.separator ? styles.separator : undefined}
					key={x.label}
					onClick={() => {
						x.onClick?.();
						onVisibleChange?.(false);
					}}
					title={x.title}
					disabled={x.disabled}>
					{x.label}
				</Menu.Item>
			))}
		</Menu>
	);
	return (
		<Dropdown
			destroyPopupOnHide={true}
			overlay={menuOptions}
			trigger={['contextMenu']}
			onOpenChange={onVisibleChange}
			overlayStyle={{ width: 'max-content' }}>
			{children}
		</Dropdown>
	);
};

export { WhosInsideContextMenu };

import React from 'react';
import { getTimeZoneInfoByList } from '../../../../../../../Helper';
import { DisableReportingDuringTimeZoneInfoStore, SelectTimeZone, TimeZoneInfo } from '../../../../../../../model/DeviceAdminModel';
import { useStoreDispatch, useStoreSelector } from '../../../../../../../store';
import { setControllerReportingSetupAction, setOpenTimeZones } from '../../../../../../../store/deviceControl/controller/actions';
import { ControllerReporting } from '../../../../../../../store/deviceControl/controller/actionTypes';
import { selectDisableReportingDuringTimeZoneInfo, selectGenericTimeZones } from '../../../../../../../store/deviceControl/controller/selectors';
import { Select } from '../../../../../../common';
import styles from '../reporting.module.scss';

const DisableReportingDuringTimeZone: React.FC = () => {
	const dispatch = useStoreDispatch();
	const timeZones: SelectTimeZone[] = useStoreSelector<SelectTimeZone[]>(selectGenericTimeZones);
	const disableReportingDuringTimeZoneInfo: DisableReportingDuringTimeZoneInfoStore =
		useStoreSelector<DisableReportingDuringTimeZoneInfoStore>(selectDisableReportingDuringTimeZoneInfo);

	const handleChangeControllerReportingSetup = (value: ControllerReporting) => {
		dispatch(
			setControllerReportingSetupAction({
				...value,
			})
		);
	};

	const localPrintTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(disableReportingDuringTimeZoneInfo.onLocalPrinter, timeZones);
	const transactionsTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(disableReportingDuringTimeZoneInfo.transaction, timeZones);
	const eventsTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(disableReportingDuringTimeZoneInfo.events, timeZones);
	const grantedTransactionsTimeZoneInfo: TimeZoneInfo = getTimeZoneInfoByList(disableReportingDuringTimeZoneInfo.grantedTransactions, timeZones);

	const controllerReportingOnLocalPrinterTimeZoneDropdownId = 'controllerReportingOnLocalPrinterTimeZoneDropdown';
	const controllerReportingTransactionsTimeZoneDropdownId = 'controllerReportingTransactionsTimeZoneDropdown';
	const controllerReportingEventsTimeZoneDropdownId = 'controllerReportingEventsTimeZoneDropdown';
	const controllerReportingGrantedTransactionsTimeZoneDropdownId = 'controllerReportingGrantedTransactionsTimeZoneDropdown';

	return (
		<div className={styles.disableDuringTimeZone}>
			<label htmlFor={controllerReportingOnLocalPrinterTimeZoneDropdownId}>{_('OnLocalPrinter')}:</label>
			<Select
				id={controllerReportingOnLocalPrinterTimeZoneDropdownId}
				onChange={value => {
					if (Number(value) === 0) {
						dispatch(
							setOpenTimeZones({
								index: -1,
								open: true,
								propertyType: 'LocalPrinter',
							})
						);
					} else {
						handleChangeControllerReportingSetup({ DisableReportingOnLocalPrinterTimeZone: Number(value) });
					}
				}}
				notUseCurrentParse
				value={disableReportingDuringTimeZoneInfo.onLocalPrinter}
				options={localPrintTimeZoneInfo.TimeZoneList}
				disabled={localPrintTimeZoneInfo.IsUnpermitted}
				getPopupContainer={element => element}
			/>
			<label htmlFor={controllerReportingTransactionsTimeZoneDropdownId}>{_('Transactions')}:</label>
			<Select
				id={controllerReportingTransactionsTimeZoneDropdownId}
				onChange={value => {
					if (Number(value) === 0) {
						dispatch(
							setOpenTimeZones({
								index: -1,
								open: true,
								propertyType: 'Transaction',
							})
						);
					} else {
						handleChangeControllerReportingSetup({ DisableReportingTransactionsTimeZone: Number(value) });
					}
				}}
				notUseCurrentParse
				value={disableReportingDuringTimeZoneInfo.transaction}
				options={transactionsTimeZoneInfo.TimeZoneList}
				disabled={transactionsTimeZoneInfo.IsUnpermitted}
				getPopupContainer={element => element}
			/>
			<label htmlFor={controllerReportingEventsTimeZoneDropdownId}>{_('Events')}:</label>
			<Select
				id={controllerReportingEventsTimeZoneDropdownId}
				onChange={value => {
					if (Number(value) === 0) {
						dispatch(
							setOpenTimeZones({
								index: -1,
								open: true,
								propertyType: 'Events',
							})
						);
					} else {
						handleChangeControllerReportingSetup({ DisableReportingEventsTimeZone: Number(value) });
					}
				}}
				notUseCurrentParse
				value={disableReportingDuringTimeZoneInfo.events}
				options={eventsTimeZoneInfo.TimeZoneList}
				disabled={eventsTimeZoneInfo.IsUnpermitted}
				getPopupContainer={element => element}
			/>
			<label htmlFor={controllerReportingGrantedTransactionsTimeZoneDropdownId}>{_('GrantedTransactions')}:</label>
			<Select
				id={controllerReportingGrantedTransactionsTimeZoneDropdownId}
				onChange={value => {
					if (Number(value) === 0) {
						dispatch(
							setOpenTimeZones({
								index: -1,
								open: true,
								propertyType: 'GrantedTransactions',
							})
						);
					} else {
						handleChangeControllerReportingSetup({ DisableReportingGrantedTransactionsTimeZone: Number(value) });
					}
				}}
				notUseCurrentParse
				value={disableReportingDuringTimeZoneInfo.grantedTransactions}
				options={grantedTransactionsTimeZoneInfo.TimeZoneList}
				disabled={grantedTransactionsTimeZoneInfo.IsUnpermitted}
				getPopupContainer={element => element}
			/>
		</div>
	);
};

export { DisableReportingDuringTimeZone };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { getPermissionErrorMessage } from '../../../model/AccountModel';
import { ProfileSetting } from '../../../model/ConfigurationModel';

interface Props {
	id?: string;
	profileSetting: ProfileSetting;
	disabled?: boolean;
	doesntHavePermission?: boolean;
	onChange?: (checked: boolean) => void;
}

const CheckboxHelper = (props: Props) => {
	const { id, profileSetting, disabled, doesntHavePermission, onChange } = props;
	const handleChecked = (e: CheckboxChangeEvent) => {
		onChange(e.target.checked);
	};
	let actualId: string;
	if (id) {
		actualId = id;
	} else {
		actualId = profileSetting.Name;
	}

	const content = (
		<Checkbox name={profileSetting.Name} checked={profileSetting.SelectedValue} disabled={disabled} onChange={handleChecked} id={actualId}>
			<label htmlFor={actualId}>{profileSetting.Label}</label>
		</Checkbox>
	);

	if (doesntHavePermission) {
		return <span title={getPermissionErrorMessage(false)}>{content}</span>;
	}

	return content;
};

export { CheckboxHelper };

import { Button, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { cleanCultureDateFormat, handleResponse } from '../../../../../../Helper';
import { credentialApi, enrollmentApi } from '../../../../../../api';
import { User } from '../../../../../../model/AccountModel';
import { SelectOptions } from '../../../../../../model/CommonModel';
import { RelativeShortcut } from '../../../../../../model/EnrollmentModel';
import { UserDefinedFieldSetup, UserDefinedFieldType } from '../../../../../../model/UserDefinedFields';
import { DatePicker, Modal, Select } from '../../../../../common';
import { GeneralTabActivationExpirationProperty } from '../../../CredentialTemplateModalModels';
import { getDateISOString, getMomentDate, getRelativeTimeShortcutIndex } from '../../../Helper';
import { setGeneralTabActivationExpirationPropertyAction } from '../../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../../credentialTemplateContext/context';
import styles from './expirationDateModal.module.scss';

const user: User = getUser();
const cultureDateFormat: string = user.cultureDateFormat;

type Props = {
	onCloseModal: () => void;
};

type ExpirationFields = {
	expirationDate?: string;
	udfIndex?: number;
	udfDescription?: string;
	interval?: number;
	offset?: string;
};

const ExpirationDateModal: React.FC<Props> = ({ onCloseModal }) => {
	const [expirationDateOption, setExpirationDateOption] = useState<boolean>(true);
	const [relativeShortcuts, setRelativeShortcuts] = useState<RelativeShortcut[]>([]);
	const [relativeShortcutId, setRelativeShortcutId] = useState<string>('');
	const [userDefinedFields, setUserDefinedFields] = useState<UserDefinedFieldSetup[]>([]);
	const [userDefinedFieldId, setUserDefinedFieldId] = useState<string>('');
	const [expirationDateISO, setExpirationDateISO] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isExpireOnUDFDisabled, setExpireOnUDFDisabled] = useState<boolean>(false);
	const [isUDFDateOptionHidden, setUDFDateOptionHidden] = useState<boolean>(true);
	const [hasFormSubmitted, setFormSubmitted] = useState<boolean>(false);

	const dateFormat = cleanCultureDateFormat(cultureDateFormat);

	const {
		credentialInitialState: {
			configData: { IsDeviceAdminCredentialTemplate },
			generalTabState: {
				activationExpirationSectionState: { expiration, hostExpirationInterval, hostExpirationOffset, hostExpirationUDF },
			},
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	useEffect(() => {
		Promise.all([getRelativeShortcuts(), getUserDefinedFields()]).then(res => {
			const [relativeShortcutsResponse, userDefinedFieldsResponse] = res;

			if (handleResponse(relativeShortcutsResponse) || handleResponse(userDefinedFieldsResponse)) {
				return;
			}

			validateExpirationDateOption(relativeShortcutsResponse.Entity);

			if (userDefinedFieldsResponse && userDefinedFieldsResponse.Entity?.length > 0) {
				setUserDefinedFieldId(`${userDefinedFieldsResponse.Entity[0].FieldNumber}`);
			}

			batch(() => {
				setRelativeShortcuts(relativeShortcutsResponse.Entity);
				setUserDefinedFields(userDefinedFieldsResponse.Entity);
			});
		});
	}, []);

	useEffect(() => {
		if (!userDefinedFields.length && relativeShortcuts.length) {
			setExpireOnUDFDisabled(true);
			setExpirationDateOption(true);
			setUDFDateOptionHidden(false);
		}
	}, [userDefinedFields, relativeShortcuts]);

	const validateExpirationDateOption = (relativeShortcuts: RelativeShortcut[]) => {
		let expirationDate: string = expiration;
		const isExpireUDFSelected: boolean = hostExpirationUDF > 0;

		if (hostExpirationInterval > 0 && hostExpirationUDF === 0) {
			const relativeShortcutIndex = getRelativeTimeShortcutIndex(hostExpirationInterval, hostExpirationOffset, relativeShortcuts);
			if (relativeShortcutIndex !== -1) {
				const relativeShortcut = relativeShortcuts[relativeShortcutIndex];
				setRelativeShortcutId(relativeShortcut.Index.toString());
				expirationDate = getDateISOString(generateShortcutDate(hostExpirationInterval, relativeShortcut.OffSetOrTime));
			}
		}

		batch(() => {
			setExpirationDateOption(!isExpireUDFSelected);
			if (isExpireUDFSelected) {
				setUserDefinedFieldId(hostExpirationUDF.toString());
			} else {
				setExpirationDateISO(expirationDate);
			}
		});
		setIsLoading(false);
	};

	const getRelativeShortcuts = async () => {
		return credentialApi.getRelativeTimeShortcuts();
	};

	const getUserDefinedFields = async () => {
		return enrollmentApi.getUserDefinedFieldSetups(UserDefinedFieldType.Date);
	};

	const handleOnCancel = () => {
		onCloseModal();
	};

	const updateExpirationFields = (expirationFields: ExpirationFields) => {
		const { expirationDate = '', udfIndex = 0, udfDescription = '', interval = 0, offset = '' } = expirationFields;
		const activationExpirationProps: GeneralTabActivationExpirationProperty = {
			hostExpirationUDF: udfIndex,
			hostExpirationInterval: interval,
			hostExpirationOffset: offset,
		};

		if (activationExpirationProps.hostExpirationUDF === 0) {
			activationExpirationProps.expiration = expirationDate;
		} else {
			const udfValue = `UDF${activationExpirationProps.hostExpirationUDF}: ${udfDescription}`;
			activationExpirationProps.expiration = udfValue;
		}

		dispatcher(setGeneralTabActivationExpirationPropertyAction(activationExpirationProps));
	};

	const handleOnSave = () => {
		setFormSubmitted(true);
		const expirationDateObject: Date = new Date(expirationDateISO);
		const expirationDateFormatted: string = getDateISOString(expirationDateObject);
		const isExpireOnDateSelected: boolean = expirationDateOption;

		if (isExpireOnDateSelected) {
			if (!IsDeviceAdminCredentialTemplate) {
				updateExpirationFields({ expirationDate: expirationDateFormatted });
			} else {
				const expirationShortcutId = Number(relativeShortcutId);

				if (expirationShortcutId == 0) {
					updateExpirationFields({ expirationDate: expirationDateFormatted });
				} else {
					const shortcutIndexes = relativeShortcuts.map<number>(x => x.Index);
					const minValueShortcut = Math.min(...shortcutIndexes);
					const maxValueShortcut = Math.max(...shortcutIndexes);

					let interval = 0;
					let offset = '';
					let display = '';

					if (expirationShortcutId >= minValueShortcut && expirationShortcutId <= maxValueShortcut) {
						const relativeShortcut = relativeShortcuts.find(x => x.Index === expirationShortcutId);
						interval = relativeShortcut.Interval;
						offset = relativeShortcut.OffSetOrTime;
						display = relativeShortcut.Display;
					}

					updateExpirationFields({ interval, offset, expirationDate: display });
				}
			}
		} else {
			const udfIndex = Number(userDefinedFieldId);
			const udfDescription = userDefinedFields.find(x => x.FieldNumber === udfIndex).Caption;

			updateExpirationFields({ udfIndex, udfDescription });
		}
		onCloseModal();
	};

	const handleOnChangeExpirationOption = (e: RadioChangeEvent) => {
		const isExpireOnDateSelected = e.target.value as boolean;
		if (isExpireOnDateSelected) {
			const newDate = expiration !== '' || relativeShortcutId !== '' ? expirationDateISO : moment().toISOString(true);
			setExpirationDateISO(newDate);
		}
		setExpirationDateOption(isExpireOnDateSelected);
	};

	const handleOnChangeExpireDate = (momentDate: Moment) => {
		setExpirationDateISO(momentDate.toISOString(true));
		setRelativeShortcutId('');
	};

	const generateShortcutDate = (interval: number, offsetOrTime: string): Date => {
		let customDate = new Date();

		if (offsetOrTime !== null) {
			switch (interval) {
				case 1: // Today
					if (offsetOrTime.length >= 5) {
						customDate.setHours(Number(offsetOrTime.slice(0, 2)), Number(offsetOrTime.slice(3, 5)), 0, 0);
					}
					break;

				case 2: // Day
					customDate.setDate(customDate.getDate() + Number(offsetOrTime));
					break;

				case 3: // Week
					customDate.setDate(customDate.getDate() + 7 * Number(offsetOrTime));
					break;

				case 4: // Month
					customDate.setMonth(new Date().getMonth() + Number(offsetOrTime));
					break;

				case 5: // Year
					customDate.setFullYear(new Date().getFullYear() + Number(offsetOrTime));
					break;
			}
		} else {
			switch (interval) {
				case 1:
					customDate.setHours(17, 0, 0, 0);
					break;
				case 2:
					customDate.setHours(1, 0, 0, 0);
					break;
				case 3:
					customDate.setHours(23, 59, 0, 0);
					break;
				case 4:
					customDate.setDate(customDate.getDate() + 7);
					break;
				case 5:
					customDate.setMonth(new Date().getMonth() + 1);
					break;
				case 6:
					customDate.setFullYear(new Date().getFullYear() + 1);
					break;
				case 7:
					customDate.setDate(customDate.getDate() + 21);
					break;
			}
		}

		return customDate;
	};

	const handleOnChangeExpireShortcutDate = value => {
		const expireShortcutId: number = Number(value);
		const relativeShortcut: RelativeShortcut = relativeShortcuts?.find(x => x.Index === expireShortcutId);
		let customDate: string;
		if (relativeShortcut) {
			customDate = getDateISOString(generateShortcutDate(relativeShortcut.Interval, relativeShortcut.OffSetOrTime));
		} else {
			customDate = getDateISOString(generateShortcutDate(expireShortcutId, null));
		}

		batch(() => {
			setRelativeShortcutId(value);
			setExpirationDateISO(customDate);
		});
	};

	const handleOnChangeExpireUDF = value => {
		setUserDefinedFieldId(value);
	};

	const selectDateOptions = relativeShortcuts.map<SelectOptions<string>>((item, index) => ({
		label: item.Display,
		value: item.Index.toString(),
		id: `relativeShortcutsDropdownOption-${index}`,
	}));

	const selectUDFOptions = userDefinedFields.map<SelectOptions<string>>((item, index) => ({
		label: item.Caption,
		value: item.FieldNumber.toString(),
		id: `userDefinedFieldsDropdownOption-${index}`,
	}));

	const momentExpiration = getMomentDate(expirationDateISO || '', dateFormat);

	return (
		<Modal
			title={_('SetExpirationDate')}
			visible={true}
			width='500px'
			customZoomClass={styles.modal}
			onClickOk={() => undefined}
			onCancel={handleOnCancel}
			nestedModal={true}
			footer={[
				<Button id={'activationExpirationModalSaveChangesButton'} key='save' type='primary' loading={hasFormSubmitted} onClick={handleOnSave}>
					{_('SaveChanges')}
				</Button>,
				<Button id={'activationExpirationModalCancelButton'} key='cancel' onClick={handleOnCancel}>
					{_('Cancel')}
				</Button>,
			]}>
			<div id='expireOnDateContainer' className={styles.container}>
				<Spin spinning={isLoading} tip={_('Loading')}>
					<Radio.Group value={expirationDateOption} onChange={handleOnChangeExpirationOption}>
						<Space direction='vertical'>
							<div className={styles.radioContainer}>
								<Radio value={true}>{_('ExpireOnDate')}:</Radio>
								<div>
									<DatePicker
										allowClear={false}
										aria-label={_('ExpireOnDate')}
										className={styles.expireDatePicker}
										disabled={!expirationDateOption}
										format={dateFormat}
										id='expireOnDatePicker'
										name='expireOnDatePicker'
										onChange={handleOnChangeExpireDate}
										showTime
										value={momentExpiration}
									/>
									<Select
										id='expireOnDateSelect'
										options={selectDateOptions}
										value={relativeShortcutId}
										onChange={handleOnChangeExpireShortcutDate}
										getPopupContainer={() => document.getElementById('expireOnDateContainer')}
										className={styles.expireDateSelect}
										disabled={!expirationDateOption}
									/>
								</div>
								<Radio value={false} disabled={isExpireOnUDFDisabled}>
									{_('ExpireOnUDFDate')}:
								</Radio>
								<div>
									<Select
										id='expireOnUDFDateSelect'
										options={selectUDFOptions}
										value={userDefinedFieldId}
										onChange={handleOnChangeExpireUDF}
										getPopupContainer={() => document.getElementById('expireOnDateContainer')}
										className={styles.expireUDFSelect}
										disabled={expirationDateOption}
									/>
								</div>
							</div>
						</Space>
					</Radio.Group>
					{!isUDFDateOptionHidden && (
						<div className={styles.warningUDFMessage}>
							<label id='UDFDateOptionDisabled'>{_('UDFDateOptionDisabled')}</label>
						</div>
					)}
				</Spin>
			</div>
		</Modal>
	);
};

export { ExpirationDateModal };

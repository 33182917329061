import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React, { useState } from 'react';
import { configurationApi } from '../../../api';
import { getFullVelocityUrl } from '../../../Helper';
import { Modal, ServiceConnectionModal } from '../../common';
import styles from './serviceNoConnectionModal.module.scss';

type Props = {
	onSetVisible: (visible: boolean) => void;
};

const msg: string =
	'Velocity has lost its connection to the Velocity Security Domain Server.\r\n\nCheck to make sure the Velocity services are running and that your network is still active.\r\n\nDo you want to try to re-establish your connection to the Velocity Security Domain Server?';

export const ServiceNoConnectionModal = ({ onSetVisible }: Props) => {
	const fullVelocityUrl: string = getFullVelocityUrl();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isConnected, setIsConnected] = useState<boolean>(false);

	const handleOnCancel = () => {
		onSetVisible(false);
		window.location.href = `${fullVelocityUrl}/Account/LogOff`;
	};

	const onShowServiceConnectedModal = (visible: boolean) => {
		onSetVisible(false);
		setIsConnected(visible);
	};

	const reconnectToServer = () => {
		setIsLoading(true);
		configurationApi.reconnectToServer().then(response => {
			if (response) {
				setIsConnected(true);
			}
			setIsLoading(false);
		});
	};

	return (
		<>
			<Modal
				footer={[
					<Button disabled={isLoading} id='commandSetYesButton' key='yes' type='primary' onClick={() => reconnectToServer()}>
						{_('Yes')}
					</Button>,
					<Button disabled={isLoading} id='commandSetNoButton' key='no' onClick={() => handleOnCancel()}>
						{_('No')}
					</Button>,
				]}
				visible={true}
				customZoomClass={styles.withModalZoom}
				width='700px'
				closable={false}
				onClickOk={() => null}>
				<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='large'>
					<div className={styles.container}>
						<div className={styles.icon}>
							<ExclamationCircleOutlined />
						</div>
						<div className={styles.containerMsg}>
							<label>{msg}</label>
						</div>
					</div>
				</Spin>
			</Modal>
			{isConnected && <ServiceConnectionModal onSetVisible={onShowServiceConnectedModal} />}
		</>
	);
};

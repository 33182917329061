import { batch } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { photoCallUpApi } from '../../api/';
import constants from '../../constants';
import { AvailableDoorInfo, PhotoCallUpUserConfiguration, PhotoCallUpUserInformationModel } from '../../model/PhotoCallUpModel';
import { PhotoCallUpActionTypes } from './actionTypes';

export interface PhotoCallUpRetrieveEnrollmentUserInfoAction {
	type: PhotoCallUpActionTypes.RETRIEVE_ENROLLMENT_USER_INFO;
	payload: PhotoCallUpUserInformationModel;
}

export interface PhotoCallUpOpenConfigurationAction {
	type: PhotoCallUpActionTypes.OPEN_CONFIGURATION;
	payload: any;
}

export interface PhotoCallUpSetUserConfigurationAction {
	type: PhotoCallUpActionTypes.SET_PHOTOCALLUP_USER_CONFIGURATION;
	payload: PhotoCallUpUserConfiguration;
}

export interface PhotoCallUpSaveConfigurationAction {
	type: PhotoCallUpActionTypes.SAVE_CONFIGURATION;
	payload: PhotoCallUpUserConfiguration;
}

export interface PhotoCallUpConfigurationSaveNotificationAction {
	type: PhotoCallUpActionTypes.CONFIGURATION_SAVE_NOTIFICATION;
	payload: number;
}

export interface PhotoCallUpCloseConfigurationAction {
	type: PhotoCallUpActionTypes.CLOSE_CONFIGURATION;
	payload: boolean;
}

export interface PhotoCallUpSetModalLoadingAction {
	type: PhotoCallUpActionTypes.SET_MODAL_LOADING;
	payload: boolean;
}

//Union Action Types
export type PhotoCallUpAction =
	| PhotoCallUpRetrieveEnrollmentUserInfoAction
	| PhotoCallUpOpenConfigurationAction
	| PhotoCallUpSetUserConfigurationAction
	| PhotoCallUpSaveConfigurationAction
	| PhotoCallUpConfigurationSaveNotificationAction
	| PhotoCallUpCloseConfigurationAction
	| PhotoCallUpSetModalLoadingAction;

//Action Creators
const retrieveEnrollmentUserInfoAction = (payload: PhotoCallUpUserInformationModel): PhotoCallUpRetrieveEnrollmentUserInfoAction => {
	return {
		type: PhotoCallUpActionTypes.RETRIEVE_ENROLLMENT_USER_INFO,
		payload,
	};
};

const openConfigurationAction = (payload: any): PhotoCallUpOpenConfigurationAction => {
	return {
		type: PhotoCallUpActionTypes.OPEN_CONFIGURATION,
		payload,
	};
};

const setPhotoCallUpUserConfigurationAction = (payload: PhotoCallUpUserConfiguration): PhotoCallUpSetUserConfigurationAction => {
	return {
		type: PhotoCallUpActionTypes.SET_PHOTOCALLUP_USER_CONFIGURATION,
		payload,
	};
};

const setModalLoadingAction = (payload: boolean): PhotoCallUpSetModalLoadingAction => {
	return {
		type: PhotoCallUpActionTypes.SET_MODAL_LOADING,
		payload,
	};
};

//Export Action
export const closeConfiguration = (): PhotoCallUpCloseConfigurationAction => {
	return {
		type: PhotoCallUpActionTypes.CLOSE_CONFIGURATION,
		payload: false,
	};
};

export const saveConfiguration = (payload: PhotoCallUpUserConfiguration): PhotoCallUpSaveConfigurationAction => {
	return {
		type: PhotoCallUpActionTypes.SAVE_CONFIGURATION,
		payload,
	};
};

export const configurationSaveNotification = (payload: number): PhotoCallUpConfigurationSaveNotificationAction => {
	return {
		type: PhotoCallUpActionTypes.CONFIGURATION_SAVE_NOTIFICATION,
		payload,
	};
};

export const openEnrollmentUserInfo = (personId: number, credentialId: number, logId: number): ThunkAction<Promise<void>, {}, {}, PhotoCallUpAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, PhotoCallUpAction>): Promise<void> => {
		photoCallUpApi.retrieveEnrollmentUserInfo(personId, credentialId, logId).then(response => {
			dispatch(retrieveEnrollmentUserInfoAction(response));
			//Reset flag to allow the user to load again if needed
			window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_ENROLLMENT_USER_INFO_FLAG, '0');
		});
	};
};

export const closeEnrollmentUserInfo = (): ThunkAction<Promise<void>, {}, {}, PhotoCallUpAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, PhotoCallUpAction>): Promise<void> => {
		dispatch(retrieveEnrollmentUserInfoAction(null));
	};
};

export const openConfiguration = (
	quantityPerDoor: number,
	loadCachedEntries: boolean,
	selectedDoors: AvailableDoorInfo[]
): ThunkAction<Promise<void>, {}, {}, PhotoCallUpAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, PhotoCallUpAction>): Promise<void> => {
		const payload = {
			quantityPerDoor,
			loadCachedEntries,
			selectedDoors,
		};

		batch(() => {
			dispatch(openConfigurationAction(payload));
			dispatch(setModalLoading(true));
		});

		photoCallUpApi.retrievePhotoCallUpConfiguration().then(response => {
			batch(() => {
				dispatch(setPhotoCallUpUserConfigurationAction(response));
				dispatch(setModalLoading(false));
				//Reset flag to allow the user to load again if needed
				window.sessionStorage.setItem(constants.sessionStorage.photoCallUp.RETRIEVE_CONFIGURATION_FLAG, '0');
			});
		});
	};
};

export const setPhotoCallUpUserConfiguration = (value: PhotoCallUpUserConfiguration): ThunkAction<Promise<void>, {}, {}, PhotoCallUpAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, PhotoCallUpAction>): Promise<void> => {
		dispatch(setPhotoCallUpUserConfigurationAction(value));
	};
};

export const setModalLoading = (value: boolean): ThunkAction<Promise<void>, {}, {}, PhotoCallUpAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, PhotoCallUpAction>): Promise<void> => {
		dispatch(setModalLoadingAction(value));
	};
};

import React, { useContext } from 'react';
import { PortNetWorkType } from '../../../../../../../../../model/DeviceAdminModel';
import { IPv4AddressInput } from '../../../../../../../../common/IPv4AddressInput/IPv4AddressInput';
import { IPv6AddressInput } from '../../../../../../../../common/IPv6AddressInput/IPv6AddressInput';
import { PortStoreContext, setValueSNIBSelectedAction } from '../../../../../contextPort';
import styles from '../../snibConfiguration.module.scss';

type Props = {
	isDefaultGatewayInvalid: boolean;
	onResetDefaultGatewayError: () => void;
};

const DefaultGateway: React.FC<Props> = ({ isDefaultGatewayInvalid, onResetDefaultGatewayError }) => {
	const {
		portState: { snibSelected, networkType },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleIPv4GatewayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'DefaultGateway', value: value }));
		onResetDefaultGatewayError();
	};

	const handleIPv6GatewayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		dispatcher(setValueSNIBSelectedAction({ type: 'IPv6Gateway', value: value }));
		onResetDefaultGatewayError();
	};

	const searchSNIBConfigurationIPv6GatewayInputId = 'searchSNIBConfigurationIPv6GatewayInput';
	const searchSNIBConfigurationIPv4GatewayInputId = 'searchSNIBConfigurationIPv4GatewayInput';

	return (
		<div className={styles.row}>
			<label id='searchSNIBConfigurationDefaultGatewayLabel'>{_('DefaultGateway')}</label>
			<div className={styles.inputContainer}>
				{networkType === PortNetWorkType.IPv6 ? (
					<IPv6AddressInput
						id={searchSNIBConfigurationIPv6GatewayInputId}
						key='defaultGatewayIpv6'
						addressValidation={isDefaultGatewayInvalid}
						defaultValue={'0000:0000:0000:0000:0000:0000:0000:0000'}
						value={snibSelected?.IPv6Gateway}
						onChange={handleIPv6GatewayChange}
						disabled={snibSelected?.DHCPEnabled === 'Yes'}
					/>
				) : (
					<IPv4AddressInput
						id={searchSNIBConfigurationIPv4GatewayInputId}
						key='defaultGatewayIpv4'
						addressValidation={isDefaultGatewayInvalid}
						value={snibSelected?.DefaultGateway}
						defaultValue={'0.0.0.0'}
						onChange={handleIPv4GatewayChange}
						disabled={snibSelected?.DHCPEnabled === 'Yes'}
					/>
				)}
				{isDefaultGatewayInvalid && (
					<label
						id='searchSNIBConfigurationDefaultGatewayErrorLabel'
						htmlFor={networkType === PortNetWorkType.IPv6 ? searchSNIBConfigurationIPv6GatewayInputId : searchSNIBConfigurationIPv4GatewayInputId}
						className={styles.errorMessage}>
						{_('DefaultGatewayIsRequired')}
					</label>
				)}
			</div>
		</div>
	);
};

export { DefaultGateway };

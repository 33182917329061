import React from 'react';
import { Tuple } from '../../../../../model/CommonModel';
import { CommandRow, VelocityDevice } from '../../../../../model/DeviceAdminModel';

type CommandContextState = {
	controllers: VelocityDevice[];
	functions: Tuple[];
	locations: { list: Tuple[]; name: string };
	command: string;
	commands: CommandRow[];
	name: string;
	editionCommandSetKey: React.Key;
	selections: {
		controller: number;
		functionName: number;
		location: number;
	};
	errors: {
		emptyName: string;
		duplicatedName: string;
	};
};

enum CommandContextActionTypes {
	SET_CONTROLLERS = 'SET_CONTROLLERS',
	SET_FUNCTIONS = 'SET_FUNCTIONS',
	SET_LOCATIONS = 'SET_LOCATIONS',
	SET_LOCATION = 'SET_LOCATION',
	SET_CONTROLLER = 'SET_CONTROLLER',
	SET_FUNCTION = 'SET_FUNCTION',
	SET_COMMAND = 'SET_COMMAND',
	SET_NAME = 'SET_NAME',
	SET_COMMANDS = 'SET_COMMANDS',
	SET_COMMAND_BY_INDEX = 'SET_COMMAND_BY_INDEX',
	RESET_SELECTIONS = 'RESET_SELECTIONS',
	SET_NAME_ERRORS = 'SET_NAME_ERRORS',
	SET_EDITION_KEY = 'SET_EDITION_KEY',
}

type contextCommandSetResetSelections = {
	type: CommandContextActionTypes.RESET_SELECTIONS;
};

type contextCommandSetControllers = {
	type: CommandContextActionTypes.SET_CONTROLLERS;
	payload: VelocityDevice[];
};
type contextCommandSetFunctions = {
	type: CommandContextActionTypes.SET_FUNCTIONS;
	payload: Tuple[];
};
type contextCommandSetLocations = {
	type: CommandContextActionTypes.SET_LOCATIONS;
	payload: { list: Tuple[]; name: string };
};
type contextCommandSetController = {
	type: CommandContextActionTypes.SET_CONTROLLER;
	payload: number;
};
type contextCommandSetFunction = {
	type: CommandContextActionTypes.SET_FUNCTION;
	payload: number;
};
type contextCommandSetLocation = {
	type: CommandContextActionTypes.SET_LOCATION;
	payload: number;
};
type contextCommandSetCommand = {
	type: CommandContextActionTypes.SET_COMMAND;
	payload: string;
};
type contextCommandSetName = {
	type: CommandContextActionTypes.SET_NAME;
	payload: string;
};
type contextCommandSetCommands = {
	type: CommandContextActionTypes.SET_COMMANDS;
	payload: CommandRow[];
};
type contextCommandSetCommandByIndex = {
	type: CommandContextActionTypes.SET_COMMAND_BY_INDEX;
	payload: { value: string; key: React.Key };
};
type contextCommandSetNameErrors = {
	type: CommandContextActionTypes.SET_NAME_ERRORS;
	payload: { emptyName: string; duplicatedName: string };
};
type contextCommandSetEditionKey = {
	type: CommandContextActionTypes.SET_EDITION_KEY;
	payload: React.Key;
};

type CommandActionContext =
	| contextCommandSetControllers
	| contextCommandSetFunctions
	| contextCommandSetLocations
	| contextCommandSetController
	| contextCommandSetFunction
	| contextCommandSetLocation
	| contextCommandSetCommand
	| contextCommandSetName
	| contextCommandSetCommands
	| contextCommandSetCommandByIndex
	| contextCommandSetNameErrors
	| contextCommandSetResetSelections
	| contextCommandSetEditionKey;

const commandState: CommandContextState = {
	controllers: [],
	functions: [],
	locations: {
		list: [],
		name: 'Points',
	},
	command: '',
	commands: [],
	name: '',
	editionCommandSetKey: -1,
	selections: {
		controller: null,
		functionName: 0,
		location: null,
	},
	errors: {
		emptyName: '',
		duplicatedName: '',
	},
};

const commandContext = (state: Readonly<CommandContextState>, action: CommandActionContext): CommandContextState => {
	switch (action.type) {
		case CommandContextActionTypes.SET_CONTROLLERS:
			return { ...state, controllers: action.payload };

		case CommandContextActionTypes.SET_FUNCTIONS:
			return { ...state, functions: action.payload };

		case CommandContextActionTypes.SET_LOCATIONS:
			return { ...state, locations: action.payload };

		case CommandContextActionTypes.SET_LOCATION:
			return { ...state, selections: { ...state.selections, location: action.payload } };

		case CommandContextActionTypes.SET_CONTROLLER:
			return { ...state, selections: { ...state.selections, controller: action.payload } };

		case CommandContextActionTypes.SET_FUNCTION:
			return { ...state, selections: { ...state.selections, functionName: action.payload } };

		case CommandContextActionTypes.SET_NAME_ERRORS:
			return { ...state, errors: { ...state.errors, emptyName: action.payload.emptyName, duplicatedName: action.payload.duplicatedName } };

		case CommandContextActionTypes.SET_COMMAND:
			return { ...state, command: action.payload };

		case CommandContextActionTypes.SET_COMMANDS:
			return { ...state, commands: action.payload };

		case CommandContextActionTypes.SET_NAME:
			return { ...state, name: action.payload };
		case CommandContextActionTypes.SET_EDITION_KEY:
			return { ...state, editionCommandSetKey: action.payload };
		case CommandContextActionTypes.RESET_SELECTIONS:
			return { ...state, locations: commandState.locations, selections: { ...commandState.selections }, command: commandState.command };
		case CommandContextActionTypes.SET_COMMAND_BY_INDEX: {
			const cloneState = state.commands;
			const assignValue = cloneState.map<CommandRow>(x => (x.key === action.payload.key ? { ...x, command: action.payload.value } : x));
			return {
				...state,
				commands: [...assignValue],
			};
		}

		default:
			return { ...state };
	}
};

const setControllers = (payload: VelocityDevice[]): contextCommandSetControllers => {
	return {
		type: CommandContextActionTypes.SET_CONTROLLERS,
		payload,
	};
};
const setFunctions = (payload: Tuple[]): contextCommandSetFunctions => {
	return {
		type: CommandContextActionTypes.SET_FUNCTIONS,
		payload,
	};
};
const setLocations = (payload: { list: Tuple[]; name: string }): contextCommandSetLocations => {
	return {
		type: CommandContextActionTypes.SET_LOCATIONS,
		payload,
	};
};
const setLocation = (payload: number): contextCommandSetLocation => {
	return {
		type: CommandContextActionTypes.SET_LOCATION,
		payload,
	};
};
const setController = (payload: number): contextCommandSetController => {
	return {
		type: CommandContextActionTypes.SET_CONTROLLER,
		payload,
	};
};
const setFunction = (payload: number): contextCommandSetFunction => {
	return {
		type: CommandContextActionTypes.SET_FUNCTION,
		payload,
	};
};
const setCommand = (payload: string): contextCommandSetCommand => {
	return {
		type: CommandContextActionTypes.SET_COMMAND,
		payload,
	};
};
const setCommands = (payload: CommandRow[]): contextCommandSetCommands => {
	return {
		type: CommandContextActionTypes.SET_COMMANDS,
		payload,
	};
};
const setName = (payload: string): contextCommandSetName => {
	return {
		type: CommandContextActionTypes.SET_NAME,
		payload,
	};
};
const setCommandByIndex = (payload: { value: string; key: React.Key }): contextCommandSetCommandByIndex => {
	return {
		type: CommandContextActionTypes.SET_COMMAND_BY_INDEX,
		payload,
	};
};
const resetSelections = (): contextCommandSetResetSelections => {
	return {
		type: CommandContextActionTypes.RESET_SELECTIONS,
	};
};
const setNameErrors = (payload: { emptyName: string; duplicatedName: string }): contextCommandSetNameErrors => {
	return {
		type: CommandContextActionTypes.SET_NAME_ERRORS,
		payload,
	};
};

const setEditionKey = (payload: React.Key): contextCommandSetEditionKey => {
	return {
		type: CommandContextActionTypes.SET_EDITION_KEY,
		payload,
	};
};

type CommandContext = {
	commandState: Readonly<CommandContextState>;
	dispatcher: React.Dispatch<CommandActionContext>;
};

///mitigate performance issues by using useState in nested components (create unique context provider)
const StoreContext = React.createContext<CommandContext>({ commandState, dispatcher: null });

export {
	commandContext,
	commandState,
	setControllers,
	setCommand,
	setController,
	setFunction,
	setFunctions,
	setLocation,
	setLocations,
	setCommands,
	setName,
	setCommandByIndex,
	resetSelections,
	setNameErrors,
	setEditionKey,
	StoreContext,
};

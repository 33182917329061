import { WarningTwoTone } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { removeAllNonNumberCharacters } from '../../../../../../Helper';
import { credentialApi } from '../../../../../../api';
import { Logger } from '../../../../../../model/LoggingModel';
import { GeneralTabKeypadProperty, GeneralTabKeypadSectionProps } from '../../../CredentialTemplateModalModels';
import { setErrorAction, setKeypadPropertyAction } from '../../../credentialTemplateContext/actions';
import { CredentialStoreContext } from '../../../credentialTemplateContext/context';
import styles from '../../../credentialmodal.module.scss';

const KeypadSection: React.FC<GeneralTabKeypadSectionProps> = ({ user }) => {
	const {
		credentialInitialState: {
			configData: { IsDeviceAdminCredentialTemplate, SecurePINChecking },
			errors: { keypadCode },
			generalTabState: {
				IDF,
				isCredentialTemplate,
				keypadSectionState: { alwaysHidePin, duressDigit, pinCode, pinCodeConfirmMasked, pinCodeLength, pinCodeMasked, showClearPin },
			},
		},
		dispatcher,
	} = useContext(CredentialStoreContext);

	const [confirmLabelH, setConfirmLabelH] = useState<boolean>(false);
	const [duressDigitD, setDuressDigitD] = useState<boolean>(false);
	const [pinCodeLengthD, setPinCodeLengthD] = useState<boolean>(false);
	const [pinCodeD, setPinCodeD] = useState<boolean>(isCredentialTemplate);
	const [pinCodeE, setPinCodeE] = useState<boolean>(keypadCode !== undefined);
	const [pinCodeH, setPinCodeH] = useState<boolean>(false);
	const [pinCodeMaskedH, setPinCodeMaskedH] = useState<boolean>(true);
	const [pinCodeMaskedD, setPinCodeMaskedD] = useState<boolean>(isCredentialTemplate);
	const [pinCodeAutoBtnD, setPinCodeAutoBtnD] = useState<boolean>(false);
	const [pinCodeAutoBtnH, setPinCodeAutoBtnH] = useState<boolean>(false);
	const [pinCodeConfirmMaskedH, setPinCodeConfirmMaskedH] = useState<boolean>(true);
	const [pinCodeConfirmMaskedD, setPinCodeConfirmMaskedD] = useState<boolean>(isCredentialTemplate);
	const [pinCodeConfirmMaskedE, setPinCodeConfirmMaskedE] = useState<boolean>(false);

	useEffect(() => {
		dispatcher(setErrorAction({ keypadCode: undefined }));
		if (IsDeviceAdminCredentialTemplate) {
			disableFieldsForTemplate();
		} else {
			credentialIDFChangeFields();
		}
	}, [IDF]);

	useEffect(() => {
		if (showClearPin && !alwaysHidePin) {
			setPinCodeH(false);
			setPinCodeAutoBtnH(false);
			setPinCodeMaskedH(true);
			setPinCodeConfirmMaskedH(true);
			setConfirmLabelH(true);
		} else {
			setPinCodeH(true);
			setPinCodeD(true);
			setPinCodeAutoBtnH(true);
			setPinCodeMaskedH(false);
			setPinCodeConfirmMaskedH(false);
			setConfirmLabelH(false);
		}
	}, [alwaysHidePin, showClearPin]);

	useEffect(() => {
		setPinCodeE(keypadCode !== undefined);
	}, [keypadCode]);

	const disableFieldsForTemplate = () => {
		let disableKeypadOptions = true;
		toggleDisableAll(true);
		if ([1, 6, 7].indexOf(IDF) > -1) {
			disableKeypadOptions = false;
		}
		setPinCodeLengthD(disableKeypadOptions);
		setDuressDigitD(disableKeypadOptions);
	};

	const toggleDisableAll = (disabled: boolean) => {
		setPinCodeLengthD(disabled);
		setPinCodeD(disabled);
		setPinCodeMaskedD(disabled);
		setPinCodeAutoBtnD(disabled);
		setPinCodeConfirmMaskedD(disabled);
		setDuressDigitD(disabled);
	};

	const credentialIDFChangeFields = () => {
		let disableKeypad = false;
		if (IDF === 0 || IDF === 2) disableKeypad = true;
		toggleDisableAll(disableKeypad);
	};

	const validatePINCode = (pinCode: string) => {
		let isError = false;
		if (pinCode) {
			if (SecurePINChecking === '1') {
				const patternRepeat = new RegExp('[0]{3,}|[1]{3,}|[2]{3,}|[3]{3,}|[4]{3,}|[5]{3,}|[6]{3,}|[7]{3,}|[8]{3,}|[9]{3,}');
				const patternConsecutive = new RegExp(
					'[0][1][2]|[1][2][3]|[2][3][4]|[3][4][5]|[4][5][6]|[5][6][7]|[6][7][8]|[7][8][9]|[8][9][0]|[0][9][8]|[9][8][7]|[8][7][6]|[7][6][5]|[6][5][4]|[5][4][3]|[4][3][2]|[3][2][1]|[2][1][0]'
				);
				if (patternRepeat.test(pinCode) || patternConsecutive.test(pinCode)) {
					isError = true;
				} else {
					isError = false;
				}
			}
		}
		setPinCodeE(isError);
	};

	const handleChangeKeypadProperty = (value: GeneralTabKeypadProperty) => {
		dispatcher(setKeypadPropertyAction(value));
	};

	const handleOnBlurPinCode = (e: React.FocusEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setPinCodeE(false);
		if (!value) {
			handleChangeKeypadProperty({ pinCode: undefined });
		} else if (value.length < pinCodeLength) {
			handleChangeKeypadProperty({ pinCode: undefined });
			setPinCodeE(true);
		}
	};

	const handleOnBlurPinCodeMasked = (e: React.FocusEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setPinCodeE(false);
		if (!value) {
			handleChangeKeypadProperty({ pinCodeMasked: undefined });
		} else if (value.length < pinCodeLength) {
			handleChangeKeypadProperty({ pinCodeMasked: undefined });
			setPinCodeE(true);
		}
		if (pinCodeConfirmMasked) {
			if (pinCodeConfirmMasked === pinCodeMasked) setPinCodeConfirmMaskedE(false);
			else setPinCodeConfirmMaskedE(true);
		}
	};

	const handleOnBlurConfirmMaskedCode = (e: React.FocusEvent<HTMLInputElement>) => {
		setPinCodeConfirmMaskedE(false);
		if (e.target.value !== pinCodeMasked) {
			setPinCodeConfirmMaskedE(true);
		}
	};

	const handleOnChangePinCode = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeAllNonNumberCharacters(e.currentTarget.value);
		dispatcher(setErrorAction({ keypadCode: undefined }));
		if (value !== undefined) {
			validatePINCode(value);
			handleChangeKeypadProperty({ pinCode: value });
		}
	};

	const handleOnChangePinCodeMaskedProp = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeAllNonNumberCharacters(e.currentTarget.value);
		dispatcher(setErrorAction({ keypadCode: undefined }));
		if (value !== undefined) {
			handleChangeKeypadProperty({ pinCodeMasked: value });
		}
	};

	const handleOnChangePinCodeMaskedConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = removeAllNonNumberCharacters(e.currentTarget.value);
		if (value !== undefined) {
			handleChangeKeypadProperty({ pinCodeConfirmMasked: value });
		}
	};

	const handleOnChangePinLength = (maxLength: number) => {
		handleChangeKeypadProperty({ pinCodeLength: maxLength });
		if (pinCode && pinCode.length > maxLength) {
			handleChangeKeypadProperty({ pinCode: pinCode.slice(0, maxLength) });
		} else {
			handleChangeKeypadProperty({ pinCode: undefined });
		}
		if (pinCodeMasked && pinCodeMasked.length > maxLength) {
			handleChangeKeypadProperty({ pinCodeMasked: pinCodeMasked.slice(0, maxLength) });
		} else {
			handleChangeKeypadProperty({ pinCodeMasked: undefined });
		}
		if (pinCodeConfirmMasked && pinCodeConfirmMasked.length > maxLength) {
			handleChangeKeypadProperty({ pinCodeConfirmMasked: pinCodeConfirmMasked.slice(0, maxLength) });
		} else {
			handleChangeKeypadProperty({ pinCodeConfirmMasked: undefined });
		}
	};

	const handleOnClickPinCodeAutoBtn = () => {
		credentialApi
			.generateRandomPinCode(pinCodeLength)
			.then(pinCode => {
				if (pinCode !== undefined) {
					const value: string = pinCode.toString();
					dispatcher(setKeypadPropertyAction({ pinCode: value, pinCodeMasked: value, pinCodeConfirmMasked: value }));
				}
			})
			.catch(err => {
				Logger.writeErrorLog(err);
			});
	};

	const pinCodeInputId = 'pinCode';

	return (
		<div className={cx(styles.containerLegend, styles.rightContainerLegend)}>
			<fieldset className={styles.fieldset}>
				<legend className={styles.legend}>{_('Code')}</legend>
				<div className={styles.form}>
					<div className={styles.pinCodeLength}>
						<label htmlFor='pinCodeLength'>{_('Length')}:</label>
						<Select
							className={styles.selectDigits}
							disabled={pinCodeLengthD}
							id='pinCodeLength'
							getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
							onChange={handleOnChangePinLength}
							value={pinCodeLength}>
							<Select.Option value={3}>3</Select.Option>
							<Select.Option value={4}>4</Select.Option>
							<Select.Option value={5}>5</Select.Option>
							<Select.Option value={6}>6</Select.Option>
							<Select.Option value={7}>7</Select.Option>
							<Select.Option value={8}>8</Select.Option>
							<Select.Option value={9}>9</Select.Option>
							<Select.Option value={10}>10</Select.Option>
							<Select.Option value={11}>11</Select.Option>
							<Select.Option value={12}>12</Select.Option>
							<Select.Option value={13}>13</Select.Option>
							<Select.Option value={14}>14</Select.Option>
							<Select.Option value={15}>15</Select.Option>
						</Select>
						<label htmlFor={pinCodeInputId}>{`(3-15 ${_('digits')})`}</label>
					</div>
					<div className={styles.sections}>
						<label htmlFor={pinCodeInputId}>
							{_('PIN')}:
							<WarningTwoTone twoToneColor={'#ff0000'} className={cx(styles.centeredIcon, { [styles.hidden]: !pinCodeE })} />
						</label>
						<div>
							<div
								style={{
									display: 'grid',
									gridTemplateColumns: '150px 60px',
									columnGap: 5,
								}}>
								<div className={cx({ [styles.error]: pinCodeE })}>
									<Input
										className={cx(styles.inputNumberKeypad, { [styles.hidden]: pinCodeH })}
										disabled={pinCodeD}
										id={pinCodeInputId}
										maxLength={pinCodeLength}
										name={pinCodeInputId}
										onBlur={handleOnBlurPinCode}
										onChange={handleOnChangePinCode}
										size='small'
										value={pinCode}
									/>
									<Input
										className={cx(styles.inputNumberKeypad, { [styles.hidden]: pinCodeMaskedH })}
										disabled={pinCodeMaskedD}
										id='pinCodeMasked'
										maxLength={pinCodeLength}
										name='pinCodeMasked'
										onBlur={handleOnBlurPinCodeMasked}
										onChange={handleOnChangePinCodeMaskedProp}
										size='small'
										type='password'
										value={pinCodeMasked}
									/>
								</div>
								<Button
									className={cx({ [styles.disabledTemplate]: isCredentialTemplate }, { [styles.hidden]: pinCodeAutoBtnH || pinCodeAutoBtnD })}
									disabled={pinCodeAutoBtnD}
									htmlType='button'
									id='pinCodeAutoBtn'
									onClick={handleOnClickPinCodeAutoBtn}
									style={{ paddingLeft: 8 }}>
									{_('Auto')}
								</Button>
							</div>
						</div>
					</div>
					{keypadCode && (
						<div className={styles.sections}>
							<div></div>
							<label className={styles.errorMessage} htmlFor={pinCodeInputId}>
								{keypadCode}
							</label>
						</div>
					)}
					<div className={cx(styles.sections, { [styles.hidden]: pinCodeConfirmMaskedH })}>
						<label className={cx({ [styles.hidden]: confirmLabelH })}>
							{_('Confirm')}:
							<WarningTwoTone twoToneColor={'#ff0000'} className={cx(styles.centeredIcon, { [styles.hidden]: !pinCodeConfirmMaskedE })} />
						</label>
						<div>
							<Input
								className={cx(styles.inputNumberKeypad, { [styles.hidden]: pinCodeConfirmMaskedH })}
								disabled={pinCodeConfirmMaskedD}
								id='pinCodeConfirmMasked'
								maxLength={pinCodeLength}
								name='pinCodeConfirmMasked'
								onBlur={handleOnBlurConfirmMaskedCode}
								onChange={handleOnChangePinCodeMaskedConfirm}
								size='small'
								type='password'
								value={pinCodeConfirmMasked}
							/>
						</div>
					</div>
					<div className={cx(styles.sections, { [styles.hidden]: !pinCodeConfirmMaskedE }, { [styles.error]: pinCodeConfirmMaskedE })}>
						<div></div>
						<label className={styles.errorMessage} htmlFor={pinCodeInputId}>
							{_('PinCodeShouldMatch')}
						</label>
					</div>
					<div className={styles.pinCodeLength}>
						<label htmlFor='duressDigit'>{_('DuressDigit')}:</label>
						<Select
							className={styles.selectDigits}
							disabled={duressDigitD}
							id='duressDigit'
							getPopupContainer={(trigger: HTMLElement) => trigger.parentElement}
							onChange={value => handleChangeKeypadProperty({ duressDigit: value })}
							value={duressDigit}>
							<Select.Option value={0}>0</Select.Option>
							<Select.Option value={1}>1</Select.Option>
							<Select.Option value={2}>2</Select.Option>
							<Select.Option value={3}>3</Select.Option>
							<Select.Option value={4}>4</Select.Option>
							<Select.Option value={5}>5</Select.Option>
							<Select.Option value={6}>6</Select.Option>
							<Select.Option value={7}>7</Select.Option>
							<Select.Option value={8}>8</Select.Option>
							<Select.Option value={9}>9</Select.Option>
						</Select>
						<label htmlFor='duressDigit'>{` 1-9, 0 to disable`}</label>
					</div>

					<div className={styles.sections}>
						<div></div>
						<label>{_('DuressDigitNote')}</label>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export default KeypadSection;

import React from 'react';
import { HardwareConfigurationInfoStore } from '../../../../../../../model/DeviceAdminModel';
import { useStoreSelector } from '../../../../../../../store';
import { selectHardwareConfigurationInfo } from '../../../../../../../store/deviceControl/controller/selectors';
import { CardSection } from '../../../../../../common/CardSection/CardSection';
import styles from '../general.module.scss';

const HardwareConfiguration: React.FC = () => {
	const hardwareConfigurationInfo: HardwareConfigurationInfoStore = useStoreSelector<HardwareConfigurationInfoStore>(selectHardwareConfigurationInfo);

	return (
		<CardSection title={_('HardwareConfiguration')} height='334px' width='100%'>
			<div className={styles.hardwareConfiguration}>
				<div>
					<label>{_('NumberOfAlarmRelays')}:</label>
					<label>{hardwareConfigurationInfo.alarmRelay}</label>
				</div>
				<div>
					<label>{_('NumberOfInputs')}:</label>
					<label>{hardwareConfigurationInfo.inputs}</label>
				</div>
				<div>
					<label>{_('NumberOfRelays')}:</label>
					<label>{hardwareConfigurationInfo.relays}</label>
				</div>
				<div>
					<label>{_('NumberOfExpansionInputs')}:</label>
					<label>{hardwareConfigurationInfo.xInputs}</label>
				</div>
				<div>
					<label>{_('NumberOfExpansionRelays')}:</label>
					<label>{hardwareConfigurationInfo.xRelays}</label>
				</div>
				<div>
					<label>{_('NumberOfVirtualRelays')}:</label>
					<label>{hardwareConfigurationInfo.vRelays}</label>
				</div>
			</div>
		</CardSection>
	);
};

export { HardwareConfiguration };

import { Input } from 'antd';
import cx from 'classnames';
import React from 'react';
import { ErrorMessage } from '../../../../../../../model/CommonModel';
import styles from '../../globalIOGroupModal.module.scss';

type Props = {
	errorMessage: ErrorMessage;
	name: string;
	handleOnChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Name: React.FC<Props> = ({ errorMessage, name, handleOnChangeName }) => {
	const { isInvalid, message } = errorMessage;

	const nameInputId = 'globalIOModalNameInput';
	return (
		<div className={styles.fieldsSection}>
			<div className={styles.fieldsContainer}>
				<div>
					<label id='globalIOModalNameLabel'>{_('Name')}</label>
				</div>
				<div>
					<Input
						id={nameInputId}
						aria-label='globalIOModalNameLabel'
						maxLength={64}
						className={cx(styles.fieldInput, { [styles.error]: isInvalid })}
						value={name}
						onChange={handleOnChangeName}
					/>
				</div>
			</div>
			{isInvalid && (
				<div className={styles.errorContainer}>
					<label className={styles.errorMessage} htmlFor={nameInputId}>
						{message}
					</label>
				</div>
			)}
		</div>
	);
};

export { Name };

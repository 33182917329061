import { Checkbox } from 'antd';
import React, { useContext } from 'react';
import { setXboxState, StoreContext } from '../../contextXbox';
import styles from '../../xboxModal.module.scss';

const XboxState: React.FC = () => {
	const {
		xboxState: { enabled },
		dispatcher,
	} = useContext(StoreContext);

	const xboxEnableCheckboxId = 'xboxEnableCheckbox';

	return (
		<div className={styles.sections}>
			<div></div>
			<div className={styles.enableXbox}>
				<Checkbox
					id='xboxEnableCheckbox'
					checked={enabled}
					onChange={value => {
						dispatcher(setXboxState(value.target.checked));
					}}>
					<label htmlFor={xboxEnableCheckboxId}>{_('EnableXbox')}</label>
				</Checkbox>
			</div>
		</div>
	);
};

export { XboxState };

import { PaginationProps, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SelectionItem, TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
	ColumnsProps,
	ScrollType,
	buildColumn,
	getDefaultTablePaginationConfig,
	handleResponse,
	invertSelectedRowKeys,
	parseDateTimeString,
} from '../../../Helper';
import { whosInsideApi } from '../../../api';
import {
	BaseColumns,
	PaginationSettingTyped,
	ResponseStatusCode,
	SearchSettings,
	SelectOptions,
	SortDirections,
	SorterConfigEx,
	SorterConfigTyped,
} from '../../../model/CommonModel';
import { OptionsButtonBuilder } from '../../../model/DeviceAdminModel';
import { Logger } from '../../../model/LoggingModel';
import {
	CredentialChangeInfo,
	MoveCredential,
	WhosInsideCredentialDataType,
	WhosInsideResponse,
	Zone,
	ZoneCredential,
	ZoneCredentialsColumns,
	ZoneGroupTree,
	ZoneKey,
} from '../../../model/WhosInsideModel';
import { SearchColumn } from '../../common';
import { WhosInsideCell, createButtonOptions, createMoveToOption, getZoneCounter } from '../../whosInside';
import { getWhosInsideTableColumnWidth, getWhosInsideTableFullWidth } from '../helper';
import styles from '../whosInside.module.scss';

type Props = {
	zone: Zone;
	refreshZone: number;
	isEditMode: boolean;
	credentialChanges: CredentialChangeInfo[];
	storedZoneGroupTree: ZoneGroupTree;
	selectedRowKeys: React.Key[];
	currentPage: number;
	validateSelectedRowKeysValue: boolean;
	isTreeNotUpdated: boolean;
	setSelectedRowKeys: (rowKeys: React.Key[]) => void;
	resetCredentialChanges: () => void;
	resetValidateSelectedRowKeys: () => void;
	setCurrentPage: (currentPage: number) => void;
	handleActionScope: (key: string, moveCredentialInfo?: Partial<MoveCredential>) => Promise<void>;
};

const maxNameLength: number = 114;
const maxZoneNameLength: number = 215;
const initialItemSearchedPagination: SearchSettings<ZoneCredentialsColumns>[] = [
	{ Field: ZoneCredentialsColumns.Name, SearchValue: '' },
	{ Field: ZoneCredentialsColumns.Zone, SearchValue: '' },
];

const WhosInsideInfo: React.VFC<Props> = ({
	zone,
	refreshZone,
	isEditMode,
	credentialChanges,
	storedZoneGroupTree,
	selectedRowKeys,
	currentPage,
	validateSelectedRowKeysValue,
	isTreeNotUpdated,
	setSelectedRowKeys,
	resetCredentialChanges,
	resetValidateSelectedRowKeys,
	setCurrentPage,
	handleActionScope,
}) => {
	const [tableInformation, setTableInformation] = useState<WhosInsideResponse>(undefined);
	const [tableColumns, setTableColumns] = useState([]);
	const [dataSource, setDataSource] = useState<ZoneCredential[]>([]);
	const [refreshZoneInternal, setRefreshZoneInternal] = useState<number>(0);
	const [currentPageSize, setCurrentPageSize] = useState<number>(25);
	const [totalItemsPagination, setTotalItemsPagination] = useState<number>(0);
	const [itemSearchedPagination, setItemSearchedPagination] = useState<SearchSettings<ZoneCredentialsColumns>[]>(initialItemSearchedPagination);
	const [shouldResetNameSearchColumn, setShouldResetNameSearchColumn] = useState<boolean>(false);
	const [shouldResetZoneSearchColumn, setShouldResetZoneSearchColumn] = useState<boolean>(false);
	const [loadCredentials, setLoadCredentials] = useState<boolean>(false);
	const [toDispatchCounter, setToDispatchCounter] = useState<number>(0);
	const [dispatchedCounter, setDispatchedCounter] = useState<number>(0);
	const [sorterConfigEx, setSorterConfigEx] = useState<SorterConfigEx>({ columnKey: undefined, order: undefined });
	const [sorterConfig, setSorterConfig] = useState<SorterConfigTyped<ZoneCredentialsColumns>>({
		direction: SortDirections.None,
		field: ZoneCredentialsColumns.None,
	});

	const previousZone = useRef<Zone | null>();
	const currentZone = useRef<Zone | null>();
	const uncheckElements = useRef<boolean>(false);
	const previousZoneCredentialsIdController = useRef<AbortController>(undefined);
	const previousZoneCredentialsByPageController = useRef<AbortController>(undefined);
	const doubleSearchResetCounter = useRef<number>(0);
	const addDoubleSearchResetCounter = useRef<boolean>(false);
	const comeFromSearch = useRef<boolean>(false);

	useEffect(() => {
		if (tableInformation) {
			if (tableInformation.TotalItems) {
				let previousState: ZoneCredential[] = [];
				if (uncheckElements.current) {
					previousState = [];
				} else {
					previousState = dataSource.filter(c => c.checked);
				}

				const updatedDataState = [...tableInformation.Data];
				updatedDataState.forEach(item => {
					item.key = item.CredentialId;
				});

				setDataSource(updatedDataState);
			} else {
				setDataSource([]);
				setTotalItemsPagination(0);
				setCurrentPage(1);
				setSelectedRowKeys([]);
			}

			uncheckElements.current = false;
			const columns = tableInformation.Columns.map(c => {
				return {
					...buildColumn(_(c.Value), c.Key, getWhosInsideTableColumnWidth(ZoneCredentialsColumns[c.Value], tableInformation.Columns.length), 'start'),
					columnNameValue: c.Value,
					dataIndex: c.Key,
					key: c.Key,
					sorter: !c.DisableSort,
					sortOrder: sorterConfigEx.columnKey === c.Key && sorterConfigEx.order,
					...(c.Value === ZoneCredentialsColumns[ZoneCredentialsColumns.Name] ? searchNameColumn : {}),
					...(c.Value === ZoneCredentialsColumns[ZoneCredentialsColumns.Zone] ? searchZoneColumn : {}),
					render: (value, row: ZoneCredential) => {
						let text = value;
						let className = undefined;
						if (c.Value === ZoneCredentialsColumns[ZoneCredentialsColumns.Name]) {
							className = cx({ [styles.deniedCredential]: row.DeniedCredential });
						} else if (c.IsDateFormat) {
							text = parseDateTimeString(value, true, value);
						}

						return <span className={className}>{text}</span>;
					},
				};
			});

			const nameSearchInfo: SearchSettings<ZoneCredentialsColumns> = itemSearchedPagination.find(x => x.Field === ZoneCredentialsColumns.Name);
			const zoneSearchInfo: SearchSettings<ZoneCredentialsColumns> = itemSearchedPagination.find(x => x.Field === ZoneCredentialsColumns.Zone);
			const isNameColumnHidden: boolean = !columns.some(x => x.columnNameValue === ZoneCredentialsColumns[ZoneCredentialsColumns.Name]);
			const isZoneColumnHidden: boolean = !columns.some(x => x.columnNameValue === ZoneCredentialsColumns[ZoneCredentialsColumns.Zone]);
			const isSearchNameActive: boolean = nameSearchInfo?.SearchValue !== '';
			const isSearchZoneActive: boolean = zoneSearchInfo?.SearchValue !== '';
			if (isSearchNameActive && isNameColumnHidden && isSearchZoneActive && isZoneColumnHidden) {
				addDoubleSearchResetCounter.current = true;
			}

			if (isSearchNameActive && isNameColumnHidden) {
				setShouldResetNameSearchColumn(true);
				setRefreshZoneInternal(prevState => prevState + 1);
			} else if (isSearchZoneActive && isZoneColumnHidden) {
				setShouldResetZoneSearchColumn(true);
				setRefreshZoneInternal(prevState => prevState + 1);
			} else {
				setTableColumns(columns);
				if (addDoubleSearchResetCounter.current) {
					doubleSearchResetCounter.current += 1;
					addDoubleSearchResetCounter.current = false;
				}
			}

			if (sorterConfig.field !== ZoneCredentialsColumns.None && !columns.some(x => x.columnNameValue === sorterConfigEx.columnKey)) {
				setSorterConfig({ direction: SortDirections.None, field: ZoneCredentialsColumns.None });
				setSorterConfigEx({ columnKey: undefined, order: undefined });
				setRefreshZoneInternal(prevState => prevState + 1);
			}

			setTotalItemsPagination(tableInformation.TotalItems);
		} else {
			cleanUpTable();
		}
	}, [tableInformation]);

	useEffect(() => {
		currentZone.current = zone;

		if (zone) {
			let paginationSettings: PaginationSettingTyped<ZoneCredentialsColumns> = {
				PageNumber: currentPage,
				PageSize: currentPageSize,
				SortDirection: sorterConfig.direction,
				SortField: sorterConfig.field,
				SearchSettings: itemSearchedPagination,
			};

			if (zone.ZoneId !== previousZone.current?.ZoneId || zone.ZoneGroupId !== previousZone.current?.ZoneGroupId) {
				cleanUpTable();
				paginationSettings.SortDirection = SortDirections.None;
				paginationSettings.SortField = ZoneCredentialsColumns.None;
				paginationSettings.SearchSettings = initialItemSearchedPagination;
				paginationSettings.PageNumber = 1;
				previousZone.current = zone;
				uncheckElements.current = true;
			}

			if (credentialChanges.length > 0) {
				handleCredentialChanges(credentialChanges);
			} else {
				if (validateSelectedRowKeysValue) {
					validateSelectedRowKeys();
				}
				setZoneCredentialsBy(paginationSettings);
			}
		} else {
			loadColumns();
			cleanUpTable();
		}
	}, [zone, refreshZone, refreshZoneInternal]);

	useEffect(() => {
		setLoadCredentials(dispatchedCounter !== toDispatchCounter);
	}, [dispatchedCounter, toDispatchCounter]);

	useEffect(() => {
		loadColumns();

		return () => {
			clearSelection();
			clearPagination();
		};
	}, []);

	const loadColumns = () => {
		setToDispatchCounter(prevState => prevState + 1);
		const paginationSetting: PaginationSettingTyped<ZoneCredentialsColumns> = {
			PageNumber: currentPage,
			PageSize: currentPageSize,
			SortDirection: sorterConfig.direction,
			SortField: sorterConfig.field,
			SearchSettings: itemSearchedPagination,
		};

		const initialZone: Zone = {
			ZoneGroupId: 0,
			ZoneId: 0,
			ZoneName: '',
			CredentialsOnZone: 0,
			CredentialsOnZoneFormat: '',
			ZoneKey: ZoneKey.Custom,
		};

		whosInsideApi
			.loadZoneCredentialsByPage(initialZone, paginationSetting)
			.then(response => {
				if (response.ResponseStatusCode === ResponseStatusCode.Success) {
					setTableInformation(response.Entity);
				} else {
					handleResponse(response);
				}
				setDispatchedCounter(prevState => prevState + 1);
			})
			.catch(err => {
				setDispatchedCounter(prevState => prevState + 1);
				Logger.writeErrorLog(err);
			});
	};

	const searchNameColumn = {
		...SearchColumn({
			maxLength: maxNameLength,
			dataIndex: 'Name',
			reset: zone?.ZoneId !== previousZone.current?.ZoneId || zone?.ZoneGroupId !== previousZone.current?.ZoneGroupId,
			label: _('Name'),
			notVisible: isEditMode || zone === undefined,
			resetSearch: shouldResetNameSearchColumn,
			setIsFilterMode: undefined,
			clearSelection: () => clearSelection(),
			handleResetSearch: () => setShouldResetNameSearchColumn(false),
			setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, ZoneCredentialsColumns.Name),
			setSearchPerformed: (value: boolean) => (comeFromSearch.current = value),
			setSearchedValue: undefined,
			searchColumnId: 'searchColumnName',
		}),
	};

	const searchZoneColumn = {
		...SearchColumn({
			maxLength: maxZoneNameLength,
			dataIndex: 'Zone',
			reset: zone?.ZoneId !== previousZone.current?.ZoneId || zone?.ZoneGroupId !== previousZone.current?.ZoneGroupId,
			label: _('Zone'),
			notVisible: isEditMode || zone === undefined,
			resetSearch: shouldResetZoneSearchColumn,
			setIsFilterMode: undefined,
			clearSelection: () => clearSelection(),
			handleResetSearch: () => setShouldResetZoneSearchColumn(false),
			setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, ZoneCredentialsColumns.Zone),
			setSearchedValue: undefined,
			searchColumnId: 'searchColumnZone',
		}),
	};

	const handleCredentialChanges = async (credentialChanges: CredentialChangeInfo[]) => {
		let clonedDataSource: ZoneCredential[] = [...dataSource];
		let clonedStateSelectedKeys: React.Key[] = [...selectedRowKeys];
		const activeSearch: boolean = itemSearchedPagination.findIndex(x => x.SearchValue !== '') !== -1;

		for (const credentialChangeInfo of credentialChanges) {
			const zoneCredential: ZoneCredential = { ...credentialChangeInfo.ZoneCredential, key: credentialChangeInfo.ZoneCredential.CredentialId };
			const credentialItemIndex: number = dataSource.findIndex(x => x.CredentialId === zoneCredential.CredentialId);

			const isSameGroup: boolean =
				credentialChangeInfo.ZoneGroupId === zone?.ZoneGroupId && zone?.ZoneKey !== ZoneKey.WholeGroup && credentialChangeInfo.ToZone !== zone?.ZoneId;

			if (credentialItemIndex !== -1) {
				credentialChangeInfo.ZoneCredential.ZoneKey = zone.ZoneKey;
				clonedDataSource.splice(credentialItemIndex, 1, zoneCredential);

				if (zone?.ZoneKey === ZoneKey.Shared) {
					setRefreshZoneInternal(prevState => prevState + 1);
				} else if (zone?.ZoneKey !== ZoneKey.EveryOne && (isSameGroup || credentialChangeInfo.ZoneGroupId !== zone?.ZoneGroupId)) {
					clonedDataSource.splice(credentialItemIndex, 1);
					clonedStateSelectedKeys = clonedStateSelectedKeys.filter(x => x.valueOf() !== zoneCredential.CredentialId);
					removeCredentialFromTable(zoneCredential.CredentialId, clonedDataSource.length);
				}
			} else if (
				zone?.ZoneKey === ZoneKey.EveryOne ||
				(credentialChangeInfo.ZoneGroupId === zone?.ZoneGroupId &&
					(credentialChangeInfo.ToZone === zone?.ZoneId || zone?.ZoneKey === ZoneKey.WholeGroup))
			) {
				credentialChangeInfo.ZoneCredential.ZoneKey = zone.ZoneKey;
				clonedDataSource = addCredentialToTable(clonedDataSource, zoneCredential);
			} else if (zone?.ZoneKey === ZoneKey.Shared) {
				setRefreshZoneInternal(prevState => prevState + 1);
			}
		}

		if (zone?.ZoneKey !== ZoneKey.WholeGroup && !activeSearch) {
			setTotalItemsPagination(getZoneCounter(zone?.ZoneId, zone?.ZoneGroupId, zone?.ZoneKey, storedZoneGroupTree));
		} else if (zone?.ZoneKey === ZoneKey.WholeGroup && !activeSearch) {
			const allZoneCredentialIds: number[] = await getAllCredentialsIds();
			setTotalItemsPagination(allZoneCredentialIds.length);
		}

		setDataSource(clonedDataSource);
		setSelectedRowKeys(clonedStateSelectedKeys);
		resetCredentialChanges();
	};

	const removeCredentialFromTable = (credentialId: number, currentPageRecords: number) => {
		const clonedSelectedRowKeys = [...selectedRowKeys];

		const selectedRowKeysIndex = clonedSelectedRowKeys.findIndex(x => x.valueOf() === credentialId);
		if (selectedRowKeysIndex !== -1) {
			clonedSelectedRowKeys.splice(selectedRowKeysIndex, 1);
			setSelectedRowKeys(clonedSelectedRowKeys);
		}

		setTotalItemsPagination(prevState => prevState - 1);
		if (currentPageRecords === 0) {
			setZoneCredentialsBy({
				PageNumber: currentPage,
				PageSize: currentPageSize,
				SortDirection: sorterConfig.direction,
				SortField: sorterConfig.field,
				SearchSettings: itemSearchedPagination,
			} as PaginationSettingTyped<ZoneCredentialsColumns>);
		}
	};

	const addCredentialToTable = (currentDataSource: ZoneCredential[], credentialInfo: ZoneCredential) => {
		const searchIndex = itemSearchedPagination.findIndex(x => x.SearchValue !== '');
		if (searchIndex !== -1) {
			if (itemSearchedPagination[0].SearchValue !== '' && itemSearchedPagination[1].SearchValue !== '') {
				if (
					credentialInfo.Name.toLowerCase().includes(itemSearchedPagination[0].SearchValue.toLowerCase()) &&
					credentialInfo.Zone.toLowerCase().includes(itemSearchedPagination[1].SearchValue.toLowerCase())
				) {
					currentDataSource = addCredentialIfNotExists(currentDataSource, credentialInfo);
					setTotalItemsPagination(prevState => prevState + 1);
				}
			} else {
				if (itemSearchedPagination[0].Field === ZoneCredentialsColumns.Name && itemSearchedPagination[0].SearchValue !== '') {
					if (credentialInfo.Name.toLowerCase().includes(itemSearchedPagination[0].SearchValue.toLowerCase())) {
						currentDataSource = addCredentialIfNotExists(currentDataSource, credentialInfo);
						setTotalItemsPagination(prevState => prevState + 1);
					}
				}

				if (itemSearchedPagination[1].Field === ZoneCredentialsColumns.Zone && itemSearchedPagination[1].SearchValue !== '') {
					if (credentialInfo.Zone.toLowerCase().includes(itemSearchedPagination[1].SearchValue.toLowerCase())) {
						currentDataSource = addCredentialIfNotExists(currentDataSource, credentialInfo);
						setTotalItemsPagination(prevState => prevState + 1);
					}
				}
			}
		} else {
			currentDataSource = addCredentialIfNotExists(currentDataSource, credentialInfo);
		}

		if (sorterConfig.direction !== SortDirections.None) {
			const currentField: ZoneCredentialsColumns = ZoneCredentialsColumns[sorterConfigEx.columnKey];
			if (currentField === ZoneCredentialsColumns.CredentialId) {
				currentDataSource = getSortedCredentialIdData(currentDataSource, sorterConfig.direction);
			} else if (currentField === ZoneCredentialsColumns.LastZoneUpdate) {
				currentDataSource = getSortedDataDateItems(currentDataSource, sorterConfig.direction);
			} else {
				currentDataSource = getSortedDataItems(currentDataSource, sorterConfig.direction, currentField);
			}
		} else {
			currentDataSource = getSortedDataItems(currentDataSource, SortDirections.Ascend, ZoneCredentialsColumns.Name);
		}

		const maxPageNumber: number = Math.ceil(currentDataSource.length / currentPageSize);
		if (maxPageNumber === 2) {
			if (currentPage !== 1) {
				const indexAfterSort: number = currentDataSource.findIndex(x => x.CredentialId === credentialInfo.CredentialId);
				if (indexAfterSort === 0) {
					currentDataSource.shift();
				} else {
					currentDataSource.pop();
				}
			} else {
				currentDataSource.pop();
			}
		}

		return currentDataSource;
	};

	const getSortedCredentialIdData = (dataSource: ZoneCredential[], sortDirection: SortDirections): ZoneCredential[] => {
		return dataSource.sort((a: ZoneCredential, b: ZoneCredential) => {
			const firstValue: number = a.CredentialId;
			const secondValue: number = b.CredentialId;

			if (sortDirection === SortDirections.Ascend) {
				return firstValue - secondValue;
			} else {
				return secondValue - firstValue;
			}
		});
	};

	const getSortedDataDateItems = (dataSource: ZoneCredential[], sortDirection: SortDirections): ZoneCredential[] => {
		return dataSource.sort((a: ZoneCredential, b: ZoneCredential) => {
			const firstValue: number = new Date(a.LastZoneUpdate).getTime();
			const secondValue: number = new Date(b.LastZoneUpdate).getTime();

			if (sortDirection === SortDirections.Ascend) {
				return firstValue - secondValue;
			} else {
				return secondValue - firstValue;
			}
		});
	};

	const getSortedDataItems = (dataSource: ZoneCredential[], sortDirection: SortDirections, currentField: ZoneCredentialsColumns): ZoneCredential[] => {
		return dataSource.sort((a: ZoneCredential, b: ZoneCredential) => {
			const firstValue: string = a[ZoneCredentialsColumns[currentField]];
			const secondValue: string = b[ZoneCredentialsColumns[currentField]];

			if (sortDirection === SortDirections.Ascend) {
				return firstValue.localeCompare(secondValue);
			} else {
				return secondValue.localeCompare(firstValue);
			}
		});
	};

	const addCredentialIfNotExists = (currentDataSource: ZoneCredential[], credentialInfo: ZoneCredential): ZoneCredential[] => {
		const index: number = currentDataSource.findIndex(x => x.CredentialId === credentialInfo.CredentialId);
		if (index === -1) {
			currentDataSource.push(credentialInfo);
		}

		return currentDataSource;
	};

	const validateSelectedRowKeys = async () => {
		const allZoneCredentialIds: number[] = await getAllCredentialsIds();
		const validatedSelectedRowKeys: number[] = allZoneCredentialIds.filter(x => selectedRowKeys.some(y => y.valueOf() === x));

		setSelectedRowKeys(validatedSelectedRowKeys);
		resetValidateSelectedRowKeys();
	};

	const clearPagination = () => {
		setTotalItemsPagination(0);
		setItemSearchedPagination(initialItemSearchedPagination);
	};

	const cleanUpTable = () => {
		setDataSource([]);
		setTotalItemsPagination(0);
		setSorterConfig({ direction: SortDirections.None, field: ZoneCredentialsColumns.None });
		setItemSearchedPagination(initialItemSearchedPagination);
		setCurrentPage(1);
		setSelectedRowKeys([]);
		setShouldResetZoneSearchColumn(false);
		setShouldResetNameSearchColumn(false);
		setSorterConfigEx({ columnKey: undefined, order: undefined });
	};

	const actionOptions: OptionsButtonBuilder<string> = createButtonOptions(true);
	const moveToOption: OptionsButtonBuilder<string> = createMoveToOption();
	const contextMenuOptions: SelectOptions<string>[] = [...actionOptions?.options, ...moveToOption?.options];

	const handleOnSearchResults = (searchedValue: string, zoneColumn: ZoneCredentialsColumns) => {
		setItemSearchedPagination(prevState => {
			const searchSettings: SearchSettings<ZoneCredentialsColumns>[] = [...prevState];
			const index: number = searchSettings.findIndex(x => x.Field === zoneColumn);
			if (index !== -1) {
				searchSettings[index] = { Field: zoneColumn, SearchValue: searchedValue };
			}

			const paginationSettings: PaginationSettingTyped<ZoneCredentialsColumns> = {
				PageNumber: searchedValue !== '' ? 1 : currentPage,
				PageSize: currentPageSize,
				SortDirection: sorterConfig.direction,
				SortField: sorterConfig.field,
				SearchSettings: searchSettings,
			};

			uncheckElements.current = true;
			setZoneCredentialsBy(paginationSettings);
			return searchSettings;
		});
	};

	const mergedColumns = tableColumns.map((col: ColumnsProps<WhosInsideCredentialDataType>) => ({
		...col,
		onCell: (record: WhosInsideCredentialDataType) => ({
			record,
			options: contextMenuOptions,
			isTableInEditMode: isEditMode,
			selectedCredentials: selectedRowKeys.length,
			onChangeSelection,
			onClickOption: handleActionScope,
			storedZoneGroupTree: isTreeNotUpdated ? undefined : storedZoneGroupTree,
		}),
	}));

	const handleChange = (newSelectedRowKeys: React.Key[]): void => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectAll = async () => {
		const allZoneCredentialIds: number[] = await getAllCredentialsIds();
		setSelectedRowKeys(allZoneCredentialIds);
		if (allZoneCredentialIds.length !== totalItemsPagination) {
			setTotalItemsPagination(allZoneCredentialIds.length);
		}
	};

	const getAllCredentialsIds = async (): Promise<number[]> => {
		let allCredentialsIds: number[] = [];

		const paginationSettings: PaginationSettingTyped<ZoneCredentialsColumns> = {
			PageNumber: currentPage,
			PageSize: currentPageSize,
			SortDirection: sorterConfig.direction,
			SortField: sorterConfig.field,
			SearchSettings: itemSearchedPagination,
		};

		if (previousZoneCredentialsIdController.current) {
			previousZoneCredentialsIdController.current.abort();
		}

		const abortController: AbortController = new AbortController();
		const abortSignal: AbortSignal = abortController.signal;
		previousZoneCredentialsIdController.current = abortController;
		await whosInsideApi
			.loadAllZoneCredentialsIds(zone, paginationSettings, abortSignal)
			.then(response => {
				if (
					response.RequestedZone?.ZoneGroupId === currentZone.current?.ZoneGroupId &&
					(response.RequestedZone?.ZoneId === currentZone.current?.ZoneId ||
						(response.RequestedZone?.ZoneId === 0 && response.RequestedZone?.ZoneKey === currentZone.current?.ZoneKey))
				) {
					allCredentialsIds = response.AllZoneCredentials;
				}
			})
			.catch(err => {
				if (err.message !== 'canceled') {
					Logger.writeErrorLog(err);
				}
			});

		return allCredentialsIds;
	};

	const handleSelectInvert = (): void => {
		const dataKeys = dataSource.reduce<React.Key[]>((result, item) => {
			result.push(item.CredentialId);
			return result;
		}, []);

		const newSelectedRowKeys: React.Key[] = invertSelectedRowKeys(dataKeys, selectedRowKeys);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const handleSelectNone = () => {
		setSelectedRowKeys([]);
	};

	const rowSelection: TableRowSelection<WhosInsideCredentialDataType> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections:
			isEditMode || zone === undefined
				? undefined
				: [
						{ key: 'SELECTION_ALL', text: 'Select all data', onSelect: handleSelectAll } as SelectionItem,
						{ key: 'SELECTION_INVERT', text: 'Invert current page', onSelect: handleSelectInvert } as SelectionItem,
						{ key: 'SELECTION_NONE', text: 'Clear all data', onSelect: handleSelectNone } as SelectionItem,
				  ],
		getCheckboxProps: record => ({
			id: `whosInsideTableCheckbox-${record.key}`,
			disabled: isEditMode,
			children: <label htmlFor={`whosInsideTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectCredential')}`}</label>,
		}),
		selectedRowKeys,
		onChange: handleChange,
	};

	const onChangeSelection = (key: BaseColumns): void => {
		const credentialId = key.key;
		if (selectedRowKeys.findIndex(x => x === credentialId) === -1) {
			setSelectedRowKeys([credentialId]);
		}
	};

	const handleChangePagination = (page: number, pageSize: number) => {
		setCurrentPage(page);
		if (pageSize !== currentPageSize) {
			setCurrentPageSize(pageSize);
		}
	};

	const pagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		isEditMode,
		handleChangePagination,
		currentPage,
		currentPageSize,
		totalItemsPagination,
		undefined,
		selectedRowKeys
	);

	const clearSelection = () => {
		setSelectedRowKeys([]);
	};

	const handleOnChangeTable = (pagination: PaginationProps, filters, sorter) => {
		const { order, field, column } = sorter;
		const { current, pageSize } = pagination;
		const nameFilter: string[] = filters?.Name ? filters.Name : [];
		const zoneFilter: string[] = filters?.Zone ? filters.Zone : [];
		const sortField: string = column?.key as keyof typeof ZoneCredentialsColumns;
		const sorterConfigDirection = sorterConfig.direction;
		let sortOrder = SortDirections.None;
		setSorterConfigEx(sorter);

		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		if (sortOrder !== sorterConfig.direction) {
			setSorterConfig({ direction: sortOrder, field });
		}

		handleChangePagination(current, pageSize);
		const nameItemSearchedPagination: string = itemSearchedPagination[0].SearchValue;
		const zoneItemSearchedPagination: string = itemSearchedPagination[1].SearchValue;
		const isFilterValueUpdated: boolean =
			(nameItemSearchedPagination !== '' && nameFilter?.findIndex(x => x == nameItemSearchedPagination) !== -1) ||
			(zoneItemSearchedPagination !== '' && zoneFilter?.findIndex(x => x == zoneItemSearchedPagination) !== -1);
		const sortFieldDefault = sortField !== undefined ? ZoneCredentialsColumns[sortField] : ZoneCredentialsColumns.None;
		const shouldUpdateTableResults: boolean =
			(current !== currentPage || pageSize !== currentPageSize || sortOrder !== sorterConfigDirection || sortFieldDefault !== sorterConfig.field) &&
			!shouldResetZoneSearchColumn &&
			!shouldResetNameSearchColumn;

		if (shouldUpdateTableResults || !comeFromSearch.current || isFilterValueUpdated) {
			setZoneCredentialsBy({
				PageNumber: current,
				PageSize: pageSize,
				SortDirection: sortOrder,
				SortField: ZoneCredentialsColumns[sortField],
				SearchSettings: itemSearchedPagination,
			} as PaginationSettingTyped<ZoneCredentialsColumns>);
		}
	};

	const setZoneCredentialsBy = async (paginationSetting: PaginationSettingTyped<ZoneCredentialsColumns>, firstLoad?: boolean) => {
		if (zone || firstLoad) {
			setToDispatchCounter(prevState => prevState + 1);

			if (previousZoneCredentialsByPageController.current) {
				previousZoneCredentialsByPageController.current.abort();
			}
			const abortController: AbortController = new AbortController();
			const abortSignal: AbortSignal = abortController.signal;
			previousZoneCredentialsByPageController.current = abortController;

			whosInsideApi
				.loadZoneCredentialsByPage(zone, paginationSetting, abortSignal)
				.then(response => {
					if (response.ResponseStatusCode === ResponseStatusCode.Success) {
						const { TotalItems: totalItems, RequestedZone: requestedZone } = response.Entity;
						if (paginationSetting.PageNumber > 1 && totalItems === 0) {
							paginationSetting = {
								...paginationSetting,
								PageNumber: paginationSetting.PageNumber - 1,
								SortDirection: SortDirections.None,
								SortField: ZoneCredentialsColumns.None,
							};

							setZoneCredentialsBy(paginationSetting);
						} else if (
							requestedZone?.ZoneGroupId === currentZone.current?.ZoneGroupId &&
							(requestedZone?.ZoneId === currentZone.current?.ZoneId ||
								(requestedZone?.ZoneId === 0 && requestedZone?.ZoneKey === currentZone.current?.ZoneKey))
						) {
							setTableInformation(response.Entity);
							setTotalItemsPagination(totalItems);
						}
					} else {
						handleResponse(response);
					}
					setDispatchedCounter(prevState => prevState + 1);
				})
				.catch(err => {
					setDispatchedCounter(prevState => prevState + 1);
					if (err.message !== 'canceled') {
						Logger.writeErrorLog(err);
					}
				});
		}
	};

	const scroll: ScrollType = { x: `${100 + getWhosInsideTableFullWidth(tableColumns.map(x => x.columnNameValue))}px`, y: '60vh' };

	return (
		<Spin tip={`${_('Loading')}...`} spinning={loadCredentials} size='large'>
			<Table
				key={doubleSearchResetCounter.current}
				id={'whosInsideTable'}
				scroll={scroll}
				pagination={pagination}
				className={styles.whosInsideTable}
				columns={mergedColumns as ColumnsType}
				size='small'
				dataSource={[...dataSource]}
				rowSelection={rowSelection}
				components={{
					body: {
						cell: WhosInsideCell,
					},
				}}
				onChange={handleOnChangeTable}
			/>
		</Spin>
	);
};

export { WhosInsideInfo };

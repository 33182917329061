import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { buildColumn, ScrollType } from '../../../../../../../Helper';
import { DataTableType, VelocityDevice } from '../../../../../../../model/DeviceAdminModel';
import { SearchColumn } from '../../../../../../common';
import { setControllersSelected, StoreContext } from '../../context';
import styles from './controllersGrid.module.scss';

type DataType = DataTableType<VelocityDevice>;

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 156 };

const ControllersGrid: React.FC = () => {
	const [treeData, setTree] = useState<DataType[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const {
		readerState: { controllersForReaders, controllersSelectedId },
		dispatcher,
	} = useContext(StoreContext);

	useEffect(() => {
		setSelectedRowKeys(controllersSelectedId.map(x => `${x}`));
	}, [controllersSelectedId]);

	useEffect(() => {
		const controllers = controllersForReaders.map<DataType>((x, index) => ({
			...x,
			id: index,
			key: `${x.Id}`,
		}));
		setTree(controllers);
	}, [controllersForReaders]);

	const handleClick = (controller: DataType) => {
		let selectedRows: React.Key[] = [];
		let controllersSelected: number[] = [];

		if (selectedRowKeys.find(x => x === controller.key)) {
			selectedRows = selectedRowKeys.filter(controllerSelected => controllerSelected !== controller.key);
			controllersSelected = controllersSelectedId.filter(controllerSelected => controllerSelected !== controller.Id);
		} else {
			selectedRows = [...selectedRowKeys, controller.key];
			controllersSelected = [...controllersSelectedId, controller.Id];
		}

		setSelectedRowKeys(selectedRows);
		dispatcher(setControllersSelected(controllersSelected));
	};

	const handleChange = (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
		setSelectedRowKeys(selectedRowKeys);
		const controllersSelected = selectedRows.map(controller => controller.Id);
		dispatcher(setControllersSelected(controllersSelected));
	};

	const columns: ColumnsType<any> = [
		{
			...buildColumn(_('ControllerName'), 'Name', undefined, 'start'),
			sorter: (a, b) => a.Name.localeCompare(b.Name),
			...SearchColumn({ maxLength: 32 }),
		},
	];

	return (
		<div className={styles.container} id='doorGroupControllersContainer'>
			<Table
				id='doorGroupModalControllersTable'
				columns={columns}
				onRow={(record: DataType) => ({
					onClick: () => handleClick(record),
				})}
				dataSource={treeData}
				scroll={scroll}
				pagination={false}
				size='small'
				className={styles.statusWidgetTable}
				rowSelection={{
					type: 'checkbox',
					getCheckboxProps: record => ({ id: `doorGroupModalControllersCheckbox-${record.id}` }),
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => handleChange(selectedRowKeys, selectedRows),
				}}
				onHeaderRow={() => ({ id: 'doorGroupModalControllersTableHeader' })}
				getPopupContainer={() => document.getElementById('doorGroupModalControllersTableHeader')}
			/>
		</div>
	);
};

export { ControllersGrid };

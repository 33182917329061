import { batch } from 'react-redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { deviceAdminApi } from '../../api/';
import {
	Commands,
	ControllerContact,
	CredentialTemplateData,
	DIGITrac,
	DeviceObjectType,
	DoorGroupAndMasterInfo,
	GlobalIOGroup,
	GrandMasterTimeZones,
	Holidays,
	MasterTimeZones,
	OperatorModel,
	ReaderControlGroupItem,
	SelectedRowKeyPagination,
	StandardTimeZone,
	SubVelocityType,
	UserRole,
} from '../../model/DeviceAdminModel';
import { setLoading, setTotalItemsPagination } from '../common/actions';
import { RootReducer } from '../rootReducer';
import { getDefaultTablePaginationConfig, handleResponse } from './../../Helper';
import { PaginationSetting, SortDirections } from './../../model/CommonModel';
import { DeviceControlCommonActionTypes, DeviceControlDigiTracActionTypes, DeviceControlVelocityConfigurationActionTypes } from './actionTypes';

type DeviceControlSetHolidays = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_HOLIDAYS;
	payload: Holidays[];
};

type DeviceControlSetOperators = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_OPERATORS;
	payload: OperatorModel[];
};

type DeviceControlSetRoles = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_ROLES;
	payload: UserRole[];
};

type DeviceControlSetCommandsSets = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_COMMANDS_SETS;
	payload: Commands[];
};

type DeviceControlSetCredentialTemplates = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_CREDENTIAL_TEMPLATES;
	payload: CredentialTemplateData[];
};

type DeviceControlSetDoorGroups = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_DOOR_GROUPS;
	payload: DoorGroupAndMasterInfo[];
};

type DeviceControlSetReaderControlGroups = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_READER_CONTROL_GROUPS;
	payload: ReaderControlGroupItem[];
};

type DeviceControlSetTimeZonesStandard = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_STANDARD;
	payload: StandardTimeZone[];
};

type DeviceControlSetTimeZonesGrand = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_GRAND;
	payload: GrandMasterTimeZones[];
};

type DeviceControlSetTimeZonesMaster = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_MASTER;
	payload: MasterTimeZones[];
};

type DeviceControlSetControllerContacts = {
	type: DeviceControlDigiTracActionTypes.SET_CONTROLLER_CONTACTS;
	payload: { data: ControllerContact[]; controllerType: DeviceObjectType };
};

type DeviceControlDeleteCommandSet = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_COMMANDS_SETS;
	payload: number;
};

type DeviceControlDeleteHoliday = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_HOLIDAY;
	payload: number;
};

type DeviceControlDeleteDoorGroup = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_DOOR_GROUP;
	payload: DoorGroupAndMasterInfo;
};

type DeviceControlDeleteReaderControlGroup = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_READER_CONTROL_GROUP;
	payload: number;
};

type DeviceControlDeleteCredentialTemplate = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_CREDENTIAL_TEMPLATE;
	payload: number;
};

type DeviceControlDeleteTimeZoneStandard = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_STANDARD;
	payload: number;
};

type DeviceControlDeleteTimeZoneGrand = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_GRAND;
	payload: number;
};

type DeviceControlDeleteTimeZoneMaster = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_MASTER;
	payload: number;
};

type DeviceControlDeleteXbox = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_XBOX;
	payload: number;
};

type DeviceControlDeletePort = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_PORT;
	payload: number;
};

type DeviceControlDeleteController = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_CONTROLLER;
	payload: number;
};

type DeviceControlSetVelocityConfigurationEditMode = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_EDIT_MODE;
	payload: boolean;
};

type DeviceControlSetVelocityConfigurationActionScope = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_ACTION_SCOPE_KEY;
	payload: string;
};

type DeviceControlSetDigiTracEditMode = {
	type: DeviceControlDigiTracActionTypes.SET_DIGI_TRAC_EDIT_MODE;
	payload: boolean;
};

type DeviceControlSetGlobalIOGroups = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_GLOBAL_IO_GROUPS;
	payload: GlobalIOGroup[];
};

type DeviceControlDeleteGlobalIOGroup = {
	type: DeviceControlVelocityConfigurationActionTypes.DELETE_GLOBAL_IO_GROUP;
	payload: number;
};

type DeviceControlDigiTracSetSelectedRowKeysPagination = {
	type: DeviceControlDigiTracActionTypes.SET_SELECTED_ROW_KEYS_PAGINATION;
	payload: SelectedRowKeyPagination[];
};

type DeviceControlVelocitySetSelectedRowKeysPagination = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_SELECTED_ROW_KEYS_PAGINATION;
	payload: SelectedRowKeyPagination[];
};

type DeviceControlSetControllerSelectedKey = {
	type: DeviceControlDigiTracActionTypes.SET_CONTROLLER_SELECTED_KEY;
	payload: SelectedRowKeyPagination;
};

type DeviceControlSetVelocityConfigurationFilterMode = {
	type: DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_FILTER_MODE;
	payload: boolean;
};

type DeviceControlSetDigiTracFilterMode = {
	type: DeviceControlDigiTracActionTypes.SET_DIGI_TRAC_FILTER_MODE;
	payload: boolean;
};

type DeviceControlClearVelocityConfigurationSelections = {
	type: DeviceControlVelocityConfigurationActionTypes.CLEAR_VELOCITY_CONFIGURATION_SELECTIONS;
	payload: { objectType: DeviceObjectType; subObjectType?: SubVelocityType };
};

type DeviceControlClearDigiTracSelections = {
	type: DeviceControlDigiTracActionTypes.CLEAR_DIGI_TRAC_SELECTIONS;
	payload: DeviceObjectType;
};

type DeviceControlSetTablePaginationSetting = {
	type: DeviceControlCommonActionTypes.SET_TABLE_PAGINATION_SETTING;
	payload: PaginationSetting;
};

//Union Action Types
export type DeviceControlAction =
	| DeviceControlSetHolidays
	| DeviceControlSetOperators
	| DeviceControlSetRoles
	| DeviceControlSetCommandsSets
	| DeviceControlSetCredentialTemplates
	| DeviceControlSetDoorGroups
	| DeviceControlSetReaderControlGroups
	| DeviceControlSetTimeZonesStandard
	| DeviceControlSetTimeZonesGrand
	| DeviceControlSetTimeZonesMaster
	| DeviceControlSetControllerContacts
	| DeviceControlDeleteCommandSet
	| DeviceControlDeleteHoliday
	| DeviceControlDeleteDoorGroup
	| DeviceControlDeleteReaderControlGroup
	| DeviceControlDeleteCredentialTemplate
	| DeviceControlDeleteTimeZoneStandard
	| DeviceControlDeleteTimeZoneGrand
	| DeviceControlDeleteTimeZoneMaster
	| DeviceControlSetVelocityConfigurationEditMode
	| DeviceControlSetVelocityConfigurationActionScope
	| DeviceControlSetDigiTracEditMode
	| DeviceControlDeleteXbox
	| DeviceControlDeletePort
	| DeviceControlDeleteController
	| DeviceControlSetGlobalIOGroups
	| DeviceControlDeleteGlobalIOGroup
	| DeviceControlDigiTracSetSelectedRowKeysPagination
	| DeviceControlSetControllerSelectedKey
	| DeviceControlSetVelocityConfigurationFilterMode
	| DeviceControlSetDigiTracFilterMode
	| DeviceControlClearVelocityConfigurationSelections
	| DeviceControlClearDigiTracSelections
	| DeviceControlSetTablePaginationSetting
	| DeviceControlVelocitySetSelectedRowKeysPagination;

//Action Creators

const setVelocityConfigurationEditModeAction = (payload: boolean): DeviceControlSetVelocityConfigurationEditMode => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_EDIT_MODE,
		payload,
	};
};

const setVelocityConfigurationActionScopeKeyAction = (payload: string): DeviceControlSetVelocityConfigurationActionScope => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_ACTION_SCOPE_KEY,
		payload,
	};
};

const setDigiTracEditModeAction = (payload: boolean): DeviceControlSetDigiTracEditMode => {
	return {
		type: DeviceControlDigiTracActionTypes.SET_DIGI_TRAC_EDIT_MODE,
		payload,
	};
};

const setHolidaysAction = (payload: Holidays[]): DeviceControlSetHolidays => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_HOLIDAYS,
		payload,
	};
};

const setOperatorsAction = (payload: OperatorModel[]): DeviceControlSetOperators => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_OPERATORS,
		payload,
	};
};

const setRolesAction = (payload: UserRole[]): DeviceControlSetRoles => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_ROLES,
		payload,
	};
};

const setCommandsSetsAction = (payload: Commands[]): DeviceControlSetCommandsSets => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_COMMANDS_SETS,
		payload,
	};
};

const setCredentialTemplatesAction = (payload: CredentialTemplateData[]): DeviceControlSetCredentialTemplates => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_CREDENTIAL_TEMPLATES,
		payload,
	};
};

const setDoorGroupsAction = (payload: DoorGroupAndMasterInfo[]): DeviceControlSetDoorGroups => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_DOOR_GROUPS,
		payload,
	};
};

const setReaderControlGroupsAction = (payload: ReaderControlGroupItem[]): DeviceControlSetReaderControlGroups => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_READER_CONTROL_GROUPS,
		payload,
	};
};

const setMasterTimeZoneAction = (payload: MasterTimeZones[]): DeviceControlSetTimeZonesMaster => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_MASTER,
		payload,
	};
};

const setGrandTimeZoneAction = (payload: GrandMasterTimeZones[]): DeviceControlSetTimeZonesGrand => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_GRAND,
		payload,
	};
};

const setStandardAction = (payload: StandardTimeZone[]): DeviceControlSetTimeZonesStandard => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_TIME_ZONES_STANDARD,
		payload,
	};
};

const setControllerContactsAction = (payload: { data: ControllerContact[]; controllerType: DeviceObjectType }): DeviceControlSetControllerContacts => {
	return {
		type: DeviceControlDigiTracActionTypes.SET_CONTROLLER_CONTACTS,
		payload,
	};
};

const setGlobalIOGroupsAction = (payload: GlobalIOGroup[]): DeviceControlSetGlobalIOGroups => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_GLOBAL_IO_GROUPS,
		payload,
	};
};

const setDigiTracSelectedRowKeysPaginationAction = (payload: SelectedRowKeyPagination[]): DeviceControlDigiTracSetSelectedRowKeysPagination => {
	return {
		type: DeviceControlDigiTracActionTypes.SET_SELECTED_ROW_KEYS_PAGINATION,
		payload,
	};
};

export const setVelocitySelectedRowKeysPaginationAction = (payload: SelectedRowKeyPagination[]): DeviceControlVelocitySetSelectedRowKeysPagination => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_SELECTED_ROW_KEYS_PAGINATION,
		payload,
	};
};

const setControllerSelectedKeyAction = (payload: SelectedRowKeyPagination): DeviceControlSetControllerSelectedKey => {
	return {
		type: DeviceControlDigiTracActionTypes.SET_CONTROLLER_SELECTED_KEY,
		payload,
	};
};

const deleteCommandsSetsAction = (payload: number): DeviceControlDeleteCommandSet => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_COMMANDS_SETS,
		payload,
	};
};

const deleteHolidayAction = (payload: number): DeviceControlDeleteHoliday => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_HOLIDAY,
		payload,
	};
};

const deleteDoorsGroupAction = (payload: DoorGroupAndMasterInfo): DeviceControlDeleteDoorGroup => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_DOOR_GROUP,
		payload,
	};
};

const deleteReaderControlGroupAction = (payload: number): DeviceControlDeleteReaderControlGroup => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_READER_CONTROL_GROUP,
		payload,
	};
};

const deleteCredentialTemplateAction = (payload: number): DeviceControlDeleteCredentialTemplate => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_CREDENTIAL_TEMPLATE,
		payload,
	};
};

const deleteTimeZoneGrandAction = (payload: number): DeviceControlDeleteTimeZoneGrand => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_GRAND,
		payload,
	};
};

const deleteTimeZoneMasterAction = (payload: number): DeviceControlDeleteTimeZoneMaster => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_MASTER,
		payload,
	};
};

const deleteTimeZoneStandardAction = (payload: number): DeviceControlDeleteTimeZoneStandard => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_TIMEZONES_STANDARD,
		payload,
	};
};

const deleteXboxAction = (payload: number): DeviceControlDeleteXbox => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_XBOX,
		payload,
	};
};

const deletePortAction = (payload: number): DeviceControlDeletePort => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_PORT,
		payload,
	};
};

const deleteControllerAction = (payload: number): DeviceControlDeleteController => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_CONTROLLER,
		payload,
	};
};

const deleteGlobalIOGroupAction = (payload: number): DeviceControlDeleteGlobalIOGroup => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.DELETE_GLOBAL_IO_GROUP,
		payload,
	};
};

const setVelocityConfigurationFilterModeAction = (payload: boolean): DeviceControlSetVelocityConfigurationFilterMode => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.SET_VELOCITY_CONFIGURATION_FILTER_MODE,
		payload,
	};
};

const setDigiTracFilterModeAction = (payload: boolean): DeviceControlSetDigiTracFilterMode => {
	return {
		type: DeviceControlDigiTracActionTypes.SET_DIGI_TRAC_FILTER_MODE,
		payload,
	};
};

const setTablePaginationSettingAction = (payload: PaginationSetting): DeviceControlSetTablePaginationSetting => {
	return {
		type: DeviceControlCommonActionTypes.SET_TABLE_PAGINATION_SETTING,
		payload,
	};
};

export const clearVelocityConfigurationSelectionsAction = (payload: {
	objectType: DeviceObjectType;
	subObjectType?: SubVelocityType;
}): DeviceControlClearVelocityConfigurationSelections => {
	return {
		type: DeviceControlVelocityConfigurationActionTypes.CLEAR_VELOCITY_CONFIGURATION_SELECTIONS,
		payload,
	};
};

const clearDigiTracSelectionsAction = (payload: DeviceObjectType): DeviceControlClearDigiTracSelections => {
	return {
		type: DeviceControlDigiTracActionTypes.CLEAR_DIGI_TRAC_SELECTIONS,
		payload,
	};
};

//Export Action
export const setOperatorsBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: OperatorModel[] = getState().deviceControlReducer.velocityConfiguration.operators.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getOperatorsBy(currentPaginationSetting);
		const {
			Entity: { Operators: operators, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		const data: OperatorModel[] = [];

		operators.forEach(item => {
			data.push({ ...item, checked: previousState.some(x => x.Id === item.Id) });
		});

		dispatch(setOperatorsAction(data));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
		dispatch(setLoading(false));
	};
};

export const setRolesBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: UserRole[] = getState().deviceControlReducer.velocityConfiguration.roles.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getRolesPermissions(currentPaginationSetting);
		const {
			Entity: { UserRoles: roles, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		const data: UserRole[] = [];

		roles.forEach(item => {
			data.push({ ...item, checked: previousState.some(x => x.Id === item.Id) });
		});

		dispatch(setRolesAction(data));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
		dispatch(setLoading(false));
	};
};

export const setOperatorsByData = (operators: OperatorModel[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setOperatorsAction(operators));
	};
};

export const setRolesByData = (operators: UserRole[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setRolesAction(operators));
	};
};

export const setHolidaysBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: Holidays[] = getState().deviceControlReducer.velocityConfiguration.holidays.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getHolidaysBy(currentPaginationSetting);
		const payload: Holidays[] = [];
		const {
			Entity: { Holidays: holidays, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		holidays.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.HolidayID === item.HolidayID) });
		});

		dispatch(setHolidaysAction(payload));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
		dispatch(setLoading(false));
	};
};

export const setHolidaysByData = (data: Holidays[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setHolidaysAction(data));
	};
};

export const setCommandsSetsBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: Commands[] = getState().deviceControlReducer.velocityConfiguration.commandsSets.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getCommandSetsBy(currentPaginationSetting);

		const payload: Commands[] = [];
		const {
			Entity: { CommandSets: commandSets, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		commandSets.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.CommandSetId === item.CommandSetId) });
		});

		dispatch(setCommandsSetsAction(payload));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
		dispatch(setLoading(false));
	};
};

export const setCommandsSetByData = (commands: Commands[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setCommandsSetsAction(commands));
	};
};

export const setCredentialTemplatesBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: CredentialTemplateData[] = getState().deviceControlReducer.velocityConfiguration.credentialTemplates.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getCredentialTemplatesBy(currentPaginationSetting);

		const {
			Entity: { CredentialTemplates: credentialTemplates, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		const payload: CredentialTemplateData[] = [];

		credentialTemplates.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.CredentialTemplateId === item.CredentialTemplateId) });
		});
		batch(() => {
			dispatch(setCredentialTemplatesAction(payload));
			dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
			dispatch(setLoading(false));
		});
	};
};

export const setCredentialTemplatesDirty = (): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: CredentialTemplateData[] = getState().deviceControlReducer.velocityConfiguration.credentialTemplates.filter(c => c.checked);
		const res = await deviceAdminApi.getCredentialTemplates();
		if (handleResponse(res)) {
			return;
		}

		const payload: CredentialTemplateData[] = [];
		res.Entity?.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.CredentialTemplateId === item.CredentialTemplateId) });
		});

		dispatch(setCredentialTemplatesAction(payload));
		dispatch(setLoading(false));
	};
};

export const setCredentialTemplatesByData = (credentialTemplates: CredentialTemplateData[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setCredentialTemplatesAction(credentialTemplates));
	};
};

export const setDoorGroupsBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: DoorGroupAndMasterInfo[] = getState().deviceControlReducer.velocityConfiguration.doorGroups.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getDoorGroupsAndMasterDoorGroupsBy(currentPaginationSetting);
		if (handleResponse(res)) {
			return;
		}

		const {
			Entity: { DoorGroupsAndMaster: doorGroupsAndMaster, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		const payload: DoorGroupAndMasterInfo[] = [];

		doorGroupsAndMaster.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.Id === item.Id && x.UniqueId === item.UniqueId) });
		});

		dispatch(setDoorGroupsAction(payload));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
		dispatch(setLoading(false));
	};
};

export const setDoorGroupsByData = (doorsGroups: DoorGroupAndMasterInfo[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setDoorGroupsAction(doorsGroups));
	};
};

export const setReaderControlGroupsBy = (paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: ReaderControlGroupItem[] = getState().deviceControlReducer.velocityConfiguration.readerControls.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getReaderControlGroupsBy(currentPaginationSetting);
		if (handleResponse(res)) {
			return;
		}

		const {
			Entity: { ReaderControlGroups: readerControlGroups, TotalItemsPaginated: totalItemsPaginated },
		} = res;

		const payload: ReaderControlGroupItem[] = [];

		readerControlGroups.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.ReaderControlGroupId === item.ReaderControlGroupId) });
		});

		dispatch(setReaderControlGroupsAction(payload));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));
		dispatch(setLoading(false));
	};
};

export const setReaderControlGroupsByData = (readerControlGroups: ReaderControlGroupItem[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setReaderControlGroupsAction(readerControlGroups));
	};
};

export const setMasterTimeZonesBy = (paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: MasterTimeZones[] = getState().deviceControlReducer.velocityConfiguration.timeZones.master.filter(c => c.checked);
		const res = await deviceAdminApi.retrieveMasterTimeZonesBy(paginationSetting);
		const payload: MasterTimeZones[] = [];

		const {
			Entity: { MasterTimeZones, TotalTimeZonesPaginated },
		} = res;

		MasterTimeZones.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.Id === item.Id) });
		});

		dispatch(setMasterTimeZoneAction(payload));
		dispatch(setTotalItemsPagination(TotalTimeZonesPaginated));
		dispatch(setLoading(false));
	};
};

export const setMasterTimeZonesByData = (masterTimeZones: MasterTimeZones[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setMasterTimeZoneAction(masterTimeZones));
	};
};

export const setGrandTimeZonesBy = (paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: GrandMasterTimeZones[] = getState().deviceControlReducer.velocityConfiguration.timeZones.grand.filter(c => c.checked);
		const res = await deviceAdminApi.retrieveGrandMasterTimeZonesBy(paginationSetting);
		const payload: GrandMasterTimeZones[] = [];

		const {
			Entity: { TotalTimeZonesPaginated, GrandMasterTimeZones },
		} = res;

		GrandMasterTimeZones.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.Id === item.Id) });
		});

		dispatch(setGrandTimeZoneAction(payload));
		dispatch(setTotalItemsPagination(TotalTimeZonesPaginated));
		dispatch(setLoading(false));
	};
};

export const setGrandTimeZonesByData = (grandMasterTimeZones: GrandMasterTimeZones[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setGrandTimeZoneAction(grandMasterTimeZones));
	};
};

export const setStandardTimeZonesBy = (paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: StandardTimeZone[] = getState().deviceControlReducer.velocityConfiguration.timeZones.standard.filter(c => c.checked);
		const res = await deviceAdminApi.retrieveStandardTimeZonesBy(paginationSetting);
		const payload: StandardTimeZone[] = [];

		const {
			Entity: { TotalTimeZonesPaginated, StandardTimeZones },
		} = res;

		StandardTimeZones.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.Id === item.Id) });
		});

		dispatch(setStandardAction(payload));
		dispatch(setTotalItemsPagination(TotalTimeZonesPaginated));
		dispatch(setLoading(false));
	};
};

export const setStandardTimeZonesByData = (standardTimeZones: StandardTimeZone[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setStandardAction(standardTimeZones));
	};
};

export const setGlobalIOGroupsBy = (paginationSetting?: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: GlobalIOGroup[] = getState().deviceControlReducer.velocityConfiguration.globalIOGroups.filter(c => c.checked);
		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getGlobalIOGroupsBy(currentPaginationSetting);
		const {
			Entity: { GlobalIOGroups: globalIOGroups, TotalItemsPaginated: totalItemsPaginated },
		} = res;
		const payload: GlobalIOGroup[] = [];

		globalIOGroups.forEach(item => {
			payload.push({ ...item, editable: false, checked: previousState.some(x => x.id === item.id) });
		});

		dispatch(setGlobalIOGroupsAction(payload));
		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItemsPaginated ?? 0 }));

		dispatch(setLoading(false));
	};
};

export const setGlobalIOGroupsSelected = (globalIOGroups: GlobalIOGroup[]): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setGlobalIOGroupsAction(globalIOGroups));
	};
};

const getStateData = (deviceObjectType: DeviceObjectType, digiTrac: DIGITrac): ControllerContact[] => {
	switch (deviceObjectType) {
		case DeviceObjectType.Controller:
			return digiTrac.controllers;

		case DeviceObjectType.Door:
			return digiTrac.doors;

		case DeviceObjectType.Input:
			return digiTrac.inputs;

		case DeviceObjectType.ExpansionRelays:
			return digiTrac.expansionsRelays;

		case DeviceObjectType.ExpansionInputs:
			return digiTrac.expansionsInputs;

		case DeviceObjectType.Relay:
			return digiTrac.relays;

		case DeviceObjectType.Port:
			return digiTrac.port;

		case DeviceObjectType.Xbox:
			return digiTrac.xbox;

		case DeviceObjectType.Reader:
			return digiTrac.readers;
	}
};

export const setControllerContacts = (
	controllerType: DeviceObjectType,
	paginationSetting?: PaginationSetting
): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlSetControllerContacts>, getState: () => RootReducer): Promise<void> => {
		dispatch(setLoading(true));
		const previousState: ControllerContact[] = getStateData(controllerType, getState().deviceControlReducer.digiTrac).filter(c => c.checked);

		const currentPaginationSetting = paginationSetting ? paginationSetting : getState().deviceControlReducer.tablePaginationSetting;

		const res = await deviceAdminApi.getControllerContactsBy(controllerType, currentPaginationSetting);

		const data: ControllerContact[] = [];

		const { Entity: controllerContacts } = res;

		controllerContacts.forEach(item => {
			data.push({ ...item, editable: false, checked: previousState.some(x => x.Id === item.Id) });
		});

		const payload: { data: ControllerContact[]; controllerType: DeviceObjectType } = {
			data,
			controllerType,
		};

		dispatch(setControllerContactsAction(payload));

		const totalItems = controllerContacts?.[0]?.TotalItemsPagination ?? 0;

		dispatch(setTablePaginationSetting({ ...currentPaginationSetting, TotalItems: totalItems }));
		dispatch(setLoading(false));
	};
};

export const setControllerContactsByData = (
	controllerType: DeviceObjectType,
	data: ControllerContact[]
): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		const payload: { data: ControllerContact[]; controllerType: DeviceObjectType } = {
			data: [...data],
			controllerType,
		};
		dispatch(setControllerContactsAction(payload));
	};
};

export const setDigiTracSelectedRowKeysPagination = (
	selectedRowKeysPagination: SelectedRowKeyPagination[]
): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setDigiTracSelectedRowKeysPaginationAction(selectedRowKeysPagination));
	};
};

export const setControllerSelectedKey = (selectedControllerRowKey: SelectedRowKeyPagination): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setControllerSelectedKeyAction(selectedControllerRowKey));
	};
};

export const deleteCommandsSets = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteCommandsSetsAction(id));
	};
};

export const deleteHoliday = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteHolidayAction(id));
	};
};

export const deleteDoorGroup = (doorGroup: DoorGroupAndMasterInfo): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteDoorsGroupAction(doorGroup));
	};
};

export const deleteReaderControlGroup = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteReaderControlGroupAction(id));
	};
};

export const deleteCredentialTemplate = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteCredentialTemplateAction(id));
	};
};

export const deleteTimeZoneStandard = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteTimeZoneStandardAction(id));
	};
};

export const deleteTimeZoneGrand = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteTimeZoneGrandAction(id));
	};
};

export const deleteTimeZoneMaster = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteTimeZoneMasterAction(id));
	};
};

export const deleteDeviceById = (id: number, deviceType: DeviceObjectType): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		switch (deviceType) {
			case DeviceObjectType.Port:
				dispatch(deletePortAction(id));
				break;
			case DeviceObjectType.Xbox:
				dispatch(deleteXboxAction(id));
				break;
			case DeviceObjectType.Controller:
				dispatch(deleteControllerAction(id));
				break;
		}
	};
};

export const deleteGlobalIOGroup = (id: number): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(deleteGlobalIOGroupAction(id));
	};
};

export const setVelocityConfigurationEditMode = (isEditMode: boolean): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setVelocityConfigurationEditModeAction(isEditMode));
	};
};

export const setVelocityActionScopeKey = (actionScopeKey: string): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setVelocityConfigurationActionScopeKeyAction(actionScopeKey));
	};
};

export const setDigiTracEditMode = (isEditMode: boolean): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setDigiTracEditModeAction(isEditMode));
	};
};

export const setVelocityConfigurationFilterMode = (isFilterMode: boolean): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setVelocityConfigurationFilterModeAction(isFilterMode));
	};
};

export const setDigiTracFilterMode = (isFilterMode: boolean): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setDigiTracFilterModeAction(isFilterMode));
	};
};

export const setTablePaginationSetting = (paginationSetting: PaginationSetting): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(setTablePaginationSettingAction(paginationSetting));
	};
};

export const setDefaultTablePaginationSetting = (): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		const payload: PaginationSetting = {
			PageNumber: getDefaultTablePaginationConfig().defaultCurrent,
			PageSize: getDefaultTablePaginationConfig().defaultPageSize,
			SortDirection: SortDirections.None,
			SortField: '',
			SearchedValue: '',
			TotalItems: 0,
		};

		dispatch(setTablePaginationSettingAction(payload));
	};
};

export const clearDigiTracSelections = (deviceObjectType: DeviceObjectType): ThunkAction<Promise<void>, {}, {}, DeviceControlAction> => {
	return async (dispatch: ThunkDispatch<{}, {}, DeviceControlAction>): Promise<void> => {
		dispatch(clearDigiTracSelectionsAction(deviceObjectType));
	};
};

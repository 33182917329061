import React, { useContext, useState } from 'react';
import { deviceAdminApi } from '../../../../../../../../api';
import { Reader, ReaderError, SupportedReaderClass } from '../../../../../../../../model/DeviceAdminModel';
import { NotificationStatus } from '../../../../../../../common/NotificationStatus/NotificationStatus';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import {
	setAllegionLockAddress,
	setEACAddress,
	setReaderErrorMessage,
	setReaderErrorType,
	setWL2HubAddress,
	setWLHubAddress,
	StoreContext,
} from '../../../../contextReader';
import { WirelessFields } from './WirelessFields';

type Props = {
	submittedForm: boolean;
	useDoorContext: boolean;
};

const WirelessVersion: React.FC<Props> = ({ submittedForm, useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: { supportedReaderClass, wLHubAddress, eACAddress, wL2HubAddress, allegionLockAddress },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: { supportedReaderClass, wLHubAddress, eACAddress, wL2HubAddress, allegionLockAddress },
			dispatcher,
		} = useContext(StoreContext);
	}

	const [previousHub, setPreviousHub] = useState<number>(undefined);
	const [previousLock, setPreviousLock] = useState<number>(undefined);

	const handleOnSetWirelessAddress = (value: number) => {
		useDoorContext ? dispatcherDoor(setWLHubAddress(value)) : dispatcher(setWLHubAddress(value));
	};

	const handleOnSetWirelessAddress2 = (value: number) => {
		useDoorContext ? dispatcherDoor(setWL2HubAddress(value)) : dispatcher(setWL2HubAddress(value));
	};

	const handleOnSetEACAddress = (value: number) => {
		useDoorContext ? dispatcherDoor(setEACAddress(value)) : dispatcher(setEACAddress(value));
	};

	const validateEACAddress = async e => {
		if ((previousHub != wLHubAddress || previousLock != eACAddress) && !submittedForm) {
			const hubValue: number = e.target.id === 'wirelessAddress' ? e.target.value : wLHubAddress;
			const lockValue: number = e.target.id === 'lockAddress' ? e.target.value : eACAddress;

			setPreviousHub(hubValue);
			setPreviousLock(lockValue);

			const reader: Partial<Reader> = {
				EACAddress: lockValue,
				WLHubAddress: hubValue,
			};

			const response = await deviceAdminApi.validateEACAddress(reader);
			NotificationStatus({
				responseData: response,
				onSuccessCallback: () => {
					useDoorContext ? dispatcherDoor(setReaderErrorType(undefined)) : dispatcher(setReaderErrorType(undefined));
				},
				onFailedValidation: () => {
					useDoorContext ? dispatcherDoor(setReaderErrorType(undefined)) : dispatcher(setReaderErrorType(undefined));
					useDoorContext ? dispatcherDoor(setReaderErrorMessage(response.ErrorMessage)) : dispatcher(setReaderErrorMessage(response.ErrorMessage));
					useDoorContext
						? dispatcherDoor(setReaderErrorType(ReaderError[response.AdditionalResponseInfo]))
						: dispatcher(setReaderErrorType(ReaderError[response.AdditionalResponseInfo]));
				},
			});
		}
	};

	const handleOnSetLockAddress = (value: number) => {
		useDoorContext ? dispatcherDoor(setAllegionLockAddress(value)) : dispatcher(setAllegionLockAddress(value));
	};

	return (
		<>
			{supportedReaderClass === SupportedReaderClass.WL_Aperio && (
				<WirelessFields
					handleOnFocusOut={validateEACAddress}
					maxLockNumber={127}
					maxWirelessNumber={15}
					wirelessAddress={wLHubAddress}
					setWirelessAddress={handleOnSetWirelessAddress}
					lockAddress={eACAddress}
					setLockAddress={handleOnSetEACAddress}
					useDoorContext={useDoorContext}></WirelessFields>
			)}
			{supportedReaderClass === SupportedReaderClass.WL_Allegion && (
				<WirelessFields
					handleOnFocusOut={undefined}
					maxLockNumber={254}
					maxWirelessNumber={254}
					wirelessAddress={wL2HubAddress}
					setWirelessAddress={handleOnSetWirelessAddress2}
					lockAddress={allegionLockAddress}
					setLockAddress={handleOnSetLockAddress}
					useDoorContext={useDoorContext}></WirelessFields>
			)}
		</>
	);
};

export { WirelessVersion };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../model/CommonModel';
import { ReaderError, SelectOption } from '../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../common';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { setAssuranceLevel, setCCOTZAssurance, setDefaultAssuranceLevel, setEntryReaderError, setReaderErrorType, StoreContext } from '../../../contextReader';
import styles from '../../Logic/logic.module.scss';

type Props = {
	useDoorContext: boolean;
};
const AssuranceLevels: React.FC<Props> = ({ useDoorContext }) => {
	if (useDoorContext) {
		var {
			contextStateDoor: {
				entryReader: {
					rS485ReaderTypes,
					defaultAssuranceLevels,
					assuranceLevels,
					cCOTZAssurance,
					readerErrorType,
					readerErrorMessage,
					selections: { rS485ReaderType, defaultAssuranceLevel, assuranceLevel },
					isEntryReaderError,
				},
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var {
			readerState: {
				rS485ReaderTypes,
				defaultAssuranceLevels,
				assuranceLevels,
				cCOTZAssurance,
				readerErrorType,
				readerErrorMessage,
				selections: { rS485ReaderType, defaultAssuranceLevel, assuranceLevel },
			},
			dispatcher,
		} = useContext(StoreContext);
	}

	const [assurance, setAssurance] = useState(undefined);
	const [readerName, setReaderName] = useState('');
	const [assuranceLevelsList, setAssuranceLevelsList] = useState<SelectOptions<string>[]>([]);
	const [defaultAssuranceLevelsList, setDefaultAssuranceLevelsList] = useState<SelectOptions<string>[]>([]);
	const [errorMessage, setErrorMessage] = useState<JSX.Element>(<></>);

	useEffect(() => {
		if (readerErrorType === ReaderError.Assurance) {
			setErrorMessage(<span className={styles.errorMessage}>{readerErrorMessage}</span>);
		} else {
			setErrorMessage(<></>);
		}
	}, [readerErrorType]);

	useEffect(() => {
		const index: number = rS485ReaderTypes.findIndex(x => x.Id === rS485ReaderType);
		const copyOfArray: SelectOption[] = [...rS485ReaderTypes];
		setReaderName(copyOfArray[index].Name);
		setAssurance(assuranceLevel !== 0 ? assuranceLevel.toString() : undefined);
	}, [rS485ReaderType]);

	useEffect(() => {
		setDefaultAssuranceLevelsList(defaultAssuranceLevels.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
		setAssuranceLevelsList(assuranceLevels.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [defaultAssuranceLevels, assuranceLevels]);

	const handleOnChangeAssurance = (value: string) => {
		setAssurance(value);
		useDoorContext ? dispatcherDoor(setAssuranceLevel(Number(value))) : dispatcher(setAssuranceLevel(Number(value)));

		if (readerErrorType === ReaderError.Assurance) {
			useDoorContext
				? (dispatcherDoor(setReaderErrorType(undefined)), dispatcherDoor(setEntryReaderError(false)))
				: dispatcher(setReaderErrorType(undefined));
		}
	};

	const handleSetCCOTZAssurance = (value: CheckboxChangeEvent) => {
		if (!value.target.checked) {
			setAssurance(undefined);
			useDoorContext ? dispatcherDoor(setAssuranceLevel(0)) : dispatcher(setAssuranceLevel(0));
		}
		useDoorContext ? dispatcherDoor(setCCOTZAssurance(value.target.checked)) : dispatcher(setCCOTZAssurance(value.target.checked));

		if (readerErrorType === ReaderError.Assurance) {
			useDoorContext
				? (dispatcherDoor(setReaderErrorType(undefined)), dispatcherDoor(setEntryReaderError(false)))
				: dispatcher(setReaderErrorType(undefined));
		}
	};

	const handleOnChangeDefaultAssurance = (value: string) => {
		useDoorContext ? dispatcherDoor(setDefaultAssuranceLevel(Number(value))) : dispatcher(setDefaultAssuranceLevel(Number(value)));
	};

	return (
		<>
			<div className={styles.options}>
				<div className={styles.sectionContainer}>
					<fieldset>
						<legend className={styles.legend}>{_('AssuranceLevel')}</legend>
						<div className={styles.readerType}>
							{readerName} {_('reader')}
						</div>
						<div className={styles.assuranceSelect}>
							<div>
								<Select
									className={styles.select}
									options={defaultAssuranceLevelsList}
									onChange={handleOnChangeDefaultAssurance}
									key='defaultAssurance'
									value={defaultAssuranceLevel.toString()}
									getPopupContainer={() => document.getElementById('logicReaderContainer')}
								/>
							</div>
							<span>{_('DefaultAssuranceLevel')}</span>
						</div>
						<div className={styles.ccotzAssuranceSection}>
							<div className={styles.ccotzAssurance}>
								<Checkbox checked={cCOTZAssurance} onChange={handleSetCCOTZAssurance} id={'ccotzAssurance'}>
									<span>{_('EnableLowerAssurance')}</span>
								</Checkbox>
							</div>
						</div>
						<div className={styles.assuranceSelect}>
							<div>
								<Select
									className={cx(styles.select, {
										[styles.error]:
											(readerErrorType === ReaderError.Assurance && isEntryReaderError) ||
											(readerErrorType === ReaderError.Assurance && !useDoorContext),
									})}
									options={assuranceLevelsList}
									onChange={handleOnChangeAssurance}
									key='assurance'
									id='readerModalAssuranceSelect'
									value={assurance}
									disabled={!cCOTZAssurance}
									getPopupContainer={() => document.getElementById('logicReaderContainer')}
								/>
								{(isEntryReaderError && errorMessage) || (!useDoorContext && errorMessage)}
							</div>
							<span>{_('CCOTZAssurance')}</span>
						</div>
					</fieldset>
				</div>
			</div>
		</>
	);
};

export { AssuranceLevels };

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { DeviceObjectType } from '../../../../../../../../model/DeviceAdminModel';
import { setDoorReportDoorOpenTooLongAction } from '../../../../../DoorModal/DoorModalContext';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './checkboxes.module.scss';

type Props = {
	useDoorContext: boolean;
	currentDeviceObjType: DeviceObjectType;
};

const CheckBoxes: React.FC<Props> = ({ useDoorContext, currentDeviceObjType }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor?.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const isVerifyPassbackEnabled = currentDeviceObjType === DeviceObjectType.Input;

	const handleChangeAATL = (e: CheckboxChangeEvent) => {
		useDoorContext
			? (inputContext.dispatcher(setInputAction({ ReportDOTLWhenUnlocked: e.target.checked })),
			  inputContext.dispatcher(setDoorReportDoorOpenTooLongAction(e.target.checked)))
			: inputContext.dispatcher(setInputAction({ ReportDOTLWhenUnlocked: e.target.checked }));
	};
	const handleChangeTamper = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ MaskTamperWhenDoorContactMasked: e.target.checked }));
	};
	const handleChangePassback = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ VerifyPassback: e.target.checked }));
	};
	return (
		<div className={styles.container}>
			<Checkbox
				id={useDoorContext ? 'doorInputDOTLWhenUnlockedCheckbox' : 'generalInputAATLWhenUnlockedCheckbox'}
				checked={inputContext.context?.input?.ReportDOTLWhenUnlocked}
				onChange={handleChangeAATL}
				className={styles.checkBoxMargin}>
				{useDoorContext ? _('ReportDOTLWhenUnlocked') : _('ReportAATLWhenUnlocked')}
			</Checkbox>
			<Checkbox
				id='generalInputMaskTamperCheckbox'
				checked={inputContext.context?.input?.MaskTamperWhenDoorContactMasked}
				onChange={handleChangeTamper}
				disabled={inputContext.context?.input?.LineModuleId !== 2}
				className={styles.checkBoxMargin}>
				{_('MaskTamperWhenDoorContactMasked')}
			</Checkbox>
			{isVerifyPassbackEnabled && (
				<Checkbox
					id='generalInputVerifyPassbackCheckbox'
					checked={inputContext.context?.input?.VerifyPassback}
					onChange={handleChangePassback}
					className={styles.checkBoxMargin}>
					{_('VerifyPassback')}
				</Checkbox>
			)}
		</div>
	);
};

export { CheckBoxes };

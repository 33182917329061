import React from 'react';
import { BasicControllerInformation } from './BasicControllerInformation/BasicControllerInformation';
import { FirmwareRevision } from './FirmwareRevision/FirmwareRevision';
import styles from './general.module.scss';
import { HardwareConfiguration } from './HardwareConfiguration/HardwareConfiguration';
import { OptionsInstalled } from './OptionsInstalled/OptionsInstalled';

type Props = {
	callbackRedirect?: () => void;
};

const General: React.FC<Props> = ({ callbackRedirect }: Props) => {
	return (
		<div className={styles.general}>
			<BasicControllerInformation />
			<div className={styles.threeCols}>
				<FirmwareRevision callbackRedirect={callbackRedirect} />
				<HardwareConfiguration />
				<OptionsInstalled />
			</div>
		</div>
	);
};

export { General };

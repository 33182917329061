import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { ReaderState, setInputAction, StoreContext } from '../../../../inputModalContext';
import styles from './enableContact.module.scss';

type Props = {
	useDoorContext: boolean;
};

const EnableContact: React.FC<Props> = ({ useDoorContext }) => {
	let inputContext = { context: null as ReaderState, dispatcher: null };

	if (useDoorContext) {
		const { contextStateDoor, dispatcherDoor } = useContext(DoorStoreSingleContext);
		inputContext = { context: contextStateDoor.input, dispatcher: dispatcherDoor };
	} else {
		const { contextState, dispatcher } = useContext(StoreContext);
		inputContext = { context: contextState, dispatcher: dispatcher };
	}

	const handleChange = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledContactStateChangeReportingDoorContact: e.target.checked }));
	};
	const handleChangeRQE = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledContactStateChangeReportingRQE: e.target.checked }));
	};
	const handleChangeTamper = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledContactStateChangeReportingTamper: e.target.checked }));
	};
	const handleChangeLineFault = (e: CheckboxChangeEvent) => {
		inputContext.dispatcher(setInputAction({ EnabledContactStateChangeReportingLineFault: e.target.checked }));
	};
	return (
		<div className={styles.container}>
			<fieldset>
				<legend className={styles.legend}>{_('EnableContactStateChangeReporting')}</legend>
				<div className={styles.content}>
					<Checkbox
						id='enableContactInputDoorContactCheckbox'
						checked={inputContext.context?.input?.EnabledContactStateChangeReportingDoorContact}
						onChange={handleChange}>
						{_('DoorContact')}
					</Checkbox>
					<Checkbox
						id='enableContactInputRQECheckbox'
						checked={inputContext.context?.input?.EnabledContactStateChangeReportingRQE}
						onChange={handleChangeRQE}>
						{_('RQE')}
					</Checkbox>
					<Checkbox
						id='enableContactInputTamperCheckbox'
						checked={inputContext.context?.input?.EnabledContactStateChangeReportingTamper}
						onChange={handleChangeTamper}>
						{_('Tamper')}
					</Checkbox>
					<Checkbox
						id='enableContactInputLineFaultCheckbox'
						checked={inputContext.context?.input?.EnabledContactStateChangeReportingLineFault}
						onChange={handleChangeLineFault}>
						{_('LineFault')}
					</Checkbox>
				</div>
			</fieldset>
		</div>
	);
};

export { EnableContact };

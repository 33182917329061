import { CriteriaField, ResponseObject, ResponseObjectEntity } from '../model/CommonModel';
import { ReportArchiveListInfo, ReportFile, ReportRequestParameters, Reports, ReportUDFInfo } from '../model/ReportsModel';
import { ReportUDF } from './../model/ReportsModel';
import { Api } from './api';

class ReportsApi extends Api {
	public constructor() {
		super();
	}

	public getReports = async (): Promise<Reports[]> => {
		const url = this.getUrl('Reports/GetReports', true);

		return await this.post<Reports[]>(url);
	};

	public getReportColumnInfo = async (reportId: number): Promise<CriteriaField[]> => {
		const url = this.getUrl('Reports/GetReportColumnInfo', true);

		return await this.post<CriteriaField[]>(url, { reportId });
	};

	public getArchivedReportList = async (reportId: number): Promise<ReportArchiveListInfo[]> => {
		const url = this.getUrl('Reports/GetArchivedReportList', true);

		return await this.post<ReportArchiveListInfo[]>(url, { reportId });
	};

	public generateReport = async (request: ReportRequestParameters): Promise<ResponseObjectEntity<ReportFile>> => {
		const url = this.getUrl('Reports/GenerateReportTemp', true);

		return await this.post<ResponseObjectEntity<ReportFile>>(url, { request });
	};

	public getUniqueColumnValues = async (reportId: number, fieldId: number, searchText: string): Promise<string[]> => {
		const url = this.getUrl('Reports/GetUniqueColumnValuesBySearchText', true);

		return await this.post<string[]>(url, { reportId, fieldId, searchText });
	};

	public getUniqueColumnValuesWithPagination = async (reportId: number, fieldId: number, pageNumber: number, pageSize: number): Promise<string[]> => {
		const url = this.getUrl('Reports/GetUniqueColumnValuesByPagination', true);

		return await this.post<string[]>(url, { reportId, fieldId, pageNumber, pageSize });
	};

	public updateReportUserDefinedFields = async (reportUDFInfo: ReportUDFInfo): Promise<ResponseObject> => {
		const url = this.getUrl('Reports/UpdateReportUserDefinedFields', true);

		return await this.post<ResponseObject>(url, { reportUDFInfo });
	};

	public getReportUserDefinedFields = async (reportId: number): Promise<ResponseObjectEntity<ReportUDF>> => {
		const url = this.getUrl('Reports/GetReportUserDefinedFields', true);

		return await this.post<ResponseObjectEntity<ReportUDF>>(url, { reportId });
	};
}

const reportsApi = new ReportsApi();

export { reportsApi };

import { GridColumn, Tuple } from './CommonModel';

export type ConfigurationState = {
	WebPersistedObjectId: number;
	SelectedLandingPage: string;
	SelectedSupportedLanguage: string;
	CultureDateFormat: string;
	EnrollmentTabVisible: boolean;
	AlarmTabVisible: boolean;
	EventTabVisible: boolean;
	AccessSummaryWidgetVisible: boolean;
	PeopleCredentialsWidgetVisible: boolean;
	QueuedDownloadsWidgetVisible: boolean;
	EventsWidgetVisible: boolean;
	SupportedLanguages: CultureCodeInformation[];
	ViewSettings: ProfileSetting[];
	LandingPageOptions: Tuple[];
	EnrollmentQuerySize: ApplicationSetting;
	DisplayPhotoInEnrollment: ApplicationSetting;
	LockoutAmountTime: ApplicationSetting;
	LockoutMaxAttempts: ApplicationSetting;
	LockoutPeriodAttackTime: ApplicationSetting;
	LockoutPerIpAddress: ApplicationSetting;
	EnableTwoFactorAuthentication: ApplicationSetting;
	AlarmStackingEnabled: ApplicationSetting;
	ReportReturnToNormalAlarms: ApplicationSetting;
	AutoAcknowledgeOfReturnToNormal: ApplicationSetting;
	AutoCleanReturnToNormal: ApplicationSetting;
	AlwaysShowInstructions: ApplicationSetting;
	HaveSettingsChanged: boolean;
	BadgePrintingMethod: ApplicationSetting;
	PhotoCallUpBasicView: ApplicationSetting;
};

export enum ConfigurationAction {
	None = 0,
	ReloadPage = 1,
	ReloadSessionTimeout = 2,
	ReloadAppSettings = 4,
	ReloadCurrentLanguage = 8,
	ReloadShowAlarmBanner = 16,
	ReloadAlarmSound = 32,
}

export type VersionInformation = {
	Updates: UpdateInformation[];
};

export type UpdateInformation = {
	Description: string;
	Date: Date;
	Version: string;
};

export type ColumnConfiguration = {
	Key: string;
	ObjectId: number;
	Columns: GridColumn[];
};

export type CultureCodeInformation = {
	DisplayName: string;
	IsoName: string;
};

export type ApplicationSetting = {
	Name: string;
	Value: number | string;
	Label?: string;
};

export type ProfileSetting = {
	ObjectId: number;
	SelectedValue?: boolean;
} & ApplicationSetting;

export type SettingType =
	| 'EnrollmentQuerySize'
	| 'DisplayPhotoInEnrollment'
	| 'AlwaysShowInstructions'
	| 'Alarm'
	| 'AlarmStackingEnabled'
	| 'ReportReturnToNormalAlarms'
	| 'AutoAcknowledgeOfReturnToNormal'
	| 'AutoCleanReturnToNormal'
	| 'LockoutAmountTime'
	| 'LockoutMaxAttempts'
	| 'LockoutPerIpAddress'
	| 'LockoutPeriodAttackTime'
	| 'EnableTwoFactorAuthentication'
	| 'PhotoCallUpBasicView'
	| 'BadgePrintingMethod';

export type SettingsValue<T> = {
	value: T extends string ? string : boolean;
	key: string;
	type?: SettingType;
};

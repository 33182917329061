import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Modal } from '../../common';
import styles from './serviceConnectionModal.module.scss';

type Props = {
	onSetVisible: (visible: boolean) => void;
};

const msg: string = 'Connection to Velocity Security Domain Service has successfully been re-established';

export const ServiceConnectionModal = ({ onSetVisible }: Props) => (
	<Modal
		footer={[
			<Button id='commandSetYesButton' key='yes' type='primary' onClick={() => onSetVisible(false)}>
				{_('Yes')}
			</Button>,
		]}
		visible={true}
		customZoomClass={styles.withModalZoom}
		width='700px'
		closable={false}
		onCancel={() => onSetVisible(false)}
		onClickOk={() => null}>
		<div className={styles.container}>
			<div className={styles.icon}>
				<ExclamationCircleOutlined />
			</div>
			<div className={styles.containerMsg}>
				<label>{msg}</label>
			</div>
		</div>
	</Modal>
);

export enum CommonActionType {
	CLEAR_SOCKET_RESPONSE = 'CLEAR_SOCKET_RESPONSE',
	SET_SOCKET_PIV_ENROLLMENT_RESPONSE = 'SET_SOCKET_PIV_ENROLLMENT_RESPONSE',
	SET_SOCKET_PERFORMANCE_STATUS_RESPONSE = 'SET_SOCKET_PERFORMANCE_STATUS_RESPONSE',
	SET_SOCKET_STATUS_SUMMARY_RESPONSE = 'SET_SOCKET_STATUS_SUMMARY_RESPONSE',
	SET_SOCKET_EVENT_LIST_RESPONSE = 'SET_SOCKET_EVENT_LIST_RESPONSE',
	SET_SOCKET_ALARM_LIST_RESPONSE = 'SET_SOCKET_ALARM_LIST_RESPONSE',
	SET_SOCKET_ACK_ALARM_LIST_RESPONSE = 'SET_SOCKET_ACK_ALARM_LIST_RESPONSE',
	SET_SOCKET_CLEAR_ALARM_LIST_RESPONSE = 'SET_SOCKET_CLEAR_ALARM_LIST_RESPONSE',
	SET_SOCKET_WHOS_INSIDE_ACTION_DETAILS_RESPONSE = 'SET_SOCKET_WHOS_INSIDE_ACTION_DETAILS_RESPONSE',
	SET_SOCKET_STATUS_VIEWER_EVENTS_RESPONSE = 'SET_SOCKET_STATUS_VIEWER_EVENTS_RESPONSE',
	SET_SOCKET_PHOTO_CALLUP_CREDENTIALS_RESPONSE = 'SET_SOCKET_PHOTO_CALLUP_CREDENTIALS_RESPONSE',
	SET_SOCKET_TRANSACTIONS_RESPONSE = 'SET_SOCKET_TRANSACTIONS_RESPONSE',
	SET_SOCKET_BROADCAST_SUMMARY_RESPONSE = 'SET_SOCKET_BROADCAST_SUMMARY_RESPONSE',
	SET_LOADING = 'SET_LOADING',
	SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE',
	SET_CURRENT_TIME_ZONE = 'SET_CURRENT_TIME_ZONE',
	SET_SELECTED_TIME_ZONE = 'SET_SELECTED_TIME_ZONE',
	SET_ACTIVE_TREE_SELECTED_KEY = 'SET_ACTIVE_TREE_SELECTED_KEY',
	SET_TOTAL_ITEMS_PAGINATION = 'SET_TOTAL_ITEMS_PAGINATION',
	SET_ITEM_SEARCHED_PAGINATION = 'SET_ITEM_SEARCHED_PAGINATION',
	SET_ALL_ITEMS_PAGINATION = 'SET_ALL_ITEMS_PAGINATION',
	SET_CONFIGURATION = 'SET_CONFIGURATION',
}

import { CaretDownOutlined } from '@ant-design/icons';
import { Spin, Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import {
	ColumnsProps,
	ScrollType,
	buildColumn,
	getDefaultPaginationSettings,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getPaginatedDataBy,
} from '../../../Helper';
import { statusApi } from '../../../api';
import constants from '../../../constants';
import { BaseColumns, PaginationSetting, SelectOptions } from '../../../model/CommonModel';
import { OptionsButtonBuilder } from '../../../model/DeviceAdminModel';
import { Logger } from '../../../model/LoggingModel';
import { DigiTracTransaction, DigiTracTransactionAction } from '../../../model/StatusModel';
import { useStoreSelector } from '../../../store';
import { selectTransactions } from '../../../store/common/selectors';
import { ButtonDropdown, ModalConfirmation } from '../../common';
import { EditableCell } from '../../common/EditableCell/EditableCell';
import styles from './queuedDownloadTable.module.scss';

type Props = {
	isExpanded: boolean;
};

type DataType = {
	key: React.Key;
	BatchId: number;
	Description: string;
	ControllerName: string;
	StartTime: string;
};

//Avoid creating object style inline, since increases reconciliations
const scroll: ScrollType = { y: 275 };
const columns: ColumnsProps<DigiTracTransaction>[] = [
	{
		...buildColumn(_('ID'), 'BatchId', '5%', 'left'),
		sorter: (a, b) => a.BatchId - b.BatchId,
	},
	{
		...buildColumn(_('Controller'), 'ControllerName', '20%', 'left'),
		sorter: (a, b) => a.ControllerName.localeCompare(b.ControllerName),
	},
	{
		...buildColumn(_('StartTime'), 'StartTime', '10%', 'left'),
		sorter: (a, b) => new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime(),
	},
	{
		...buildColumn(_('Status'), 'Status', '10%', 'left'),
		sorter: (a, b) => (a.Status ? a.Status.localeCompare(b.Status) : ''),
	},
	{
		...buildColumn(_('Description'), 'Description', '20%', 'left'),
		sorter: (a, b) => a.Description.localeCompare(b.Description),
	},
	{
		...buildColumn(_('Count'), 'Count', '10%', 'left'),
		sorter: (a, b) => a.Count - b.Count,
	},
	{
		...buildColumn(_('Complete'), 'Complete', '10%', 'left'),
		sorter: (a, b) => a.Complete - b.Complete,
	},
	{
		...buildColumn(_('Progress'), 'Progress', '10%', 'left'),
		sorter: (a, b) => a.Progress - b.Progress,
		render: (value, row, index) => {
			return `${row.Progress}%`;
		},
	},
	{
		...buildColumn(_('Errors'), 'Errors', '10%', 'left'),
		sorter: (a, b) => a.Errors - b.Errors,
	},
];
const defaultPaginationSettings: PaginationSetting = getDefaultPaginationSettings();

const QueuedDownloadTable = ({ isExpanded }: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [dataSource, setDataSource] = useState<DigiTracTransaction[]>([]);
	const [paginationSettings, setPaginationSettings] = useState<PaginationSetting>(defaultPaginationSettings);
	const transactions: DigiTracTransaction[] = useStoreSelector<DigiTracTransaction[]>(selectTransactions);
	const dataSourceReference = React.useRef<DigiTracTransaction[]>(dataSource);
	const currentDataSource: DigiTracTransaction[] = dataSourceReference.current;

	const intervalRef = useRef<null | ReturnType<typeof setInterval>>(null);
	const items: OptionsButtonBuilder<string> = {
		labelOrIcon: '...',
		options: [
			{
				id: 'abortDownloadButton',
				label: _('AbortDownload'),
				value: 'abort',
			},
		],
	};
	const contextMenuOptions: SelectOptions<string>[] = items.options;

	//We use this flag to avoid multiple api calls at the same time
	window.sessionStorage.setItem(constants.sessionStorage.statusDashboard.RETRIEVE_QUEUE_DOWNLOADS_FLAG, '0');

	useEffect(() => {
		const sessionStorageKey = constants.sessionStorage.statusDashboard.RETRIEVE_QUEUE_DOWNLOADS_FLAG;
		if (window.sessionStorage.getItem(sessionStorageKey) === '0') {
			window.sessionStorage.setItem(sessionStorageKey, '1');

			statusApi
				.retrieveDownloadSummary()
				.then(response => {
					setDataSourceRef(response);
					setIsLoading(false);
					window.sessionStorage.setItem(sessionStorageKey, '0');
				})
				.catch(e => Logger.writeErrorLog(e));
		}

		intervalRef.current = setInterval(() => {
			clearCompletedItems();
		}, 1 * 1000);
		return () => clearTimeout(intervalRef.current);
	}, []);

	useEffect(() => {
		if (transactions && transactions.length) {
			const updatedDataState = [...currentDataSource];
			transactions.forEach(transaction => {
				if (transaction.BatchCompleteEvent) {
					transaction.CompletedTime = new Date().getTime();
				}

				switch (transaction.Action) {
					case DigiTracTransactionAction.Add:
						updatedDataState.push(transaction);
						break;

					case DigiTracTransactionAction.Update:
						const row = updatedDataState.find(x => x.BatchId === transaction.BatchId);
						if (row) {
							row.Status = transaction.Status;
							row.Progress = transaction.Progress;
							row.Complete = transaction.Complete;
							row.Errors = transaction.Errors;
							row.Count = transaction.Count;
							row.BatchCompleteEvent = transaction.BatchCompleteEvent;
							row.CompletedTime = transaction.CompletedTime;
						}
						break;
				}
			});
			setDataSourceRef(updatedDataState);
		}
	}, [transactions]);

	const mappedColumns = columns.map(col => ({
		...col,
		onCell: (record: DigiTracTransaction) => ({
			record,
			dataIndex: col.dataIndex,
			title: col.title,
			options: contextMenuOptions,
			isTableInEditMode: false,
			onChangeSelection,
			onClickOption: handleActionScope,
		}),
	})) as ColumnsType;

	const handleChangePagination = (page: number, pageSize: number): void => {
		if (pageSize !== paginationSettings.PageSize) {
			setPaginationSettings({ ...paginationSettings, PageNumber: page, PageSize: pageSize });
		} else {
			setPaginationSettings({ ...paginationSettings, PageNumber: page });
		}
	};

	const paginationTable: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangePagination,
		paginationSettings.PageNumber,
		paginationSettings.PageSize,
		currentDataSource.length,
		undefined,
		currentDataSource.filter(x => x.checked).map<React.Key>(x => x.BatchId)
	);

	const onChangeSelection = (key: BaseColumns): void => {
		const batchId = key.key;
		let cloneStateProps: DigiTracTransaction[] = [];
		if (currentDataSource.findIndex(x => x.checked && x.BatchId === batchId) === -1) {
			currentDataSource.forEach(item => {
				item.checked = item.BatchId === batchId;
				cloneStateProps.push({ ...item });
			});
			setDataSourceRef(cloneStateProps);
		}
	};

	const setDataSourceRef = (updatedDataState: DigiTracTransaction[]) => {
		dataSourceReference.current = updatedDataState;
		setDataSource(updatedDataState);
	};

	const clearCompletedItems = () => {
		const currentData = dataSourceReference.current;
		if (currentData && currentData.length) {
			const currentTime = new Date().getTime();
			const result = currentData.filter(x => {
				if (!x.BatchCompleteEvent) {
					return true;
				}

				const seconds = (currentTime - x.CompletedTime) / 1000;
				if (seconds >= 5) {
					return false;
				}

				return true;
			});

			if (result.length !== currentData.length) {
				setDataSourceRef([...result]);
			}
		}
	};

	const handleOnClickAbortDownload = () => {
		const selectedRowKeys: number[] = currentDataSource.filter(x => x.checked).map<number>(x => x.BatchId);
		if (selectedRowKeys.length) {
			ModalConfirmation({
				title: _('DownloadCancelConfirmationAbort'),
				onConfirm: () => {
					statusApi.abortDownload(selectedRowKeys);
				},
				content: (
					<div>
						{_('CancelDownloadInstructions1')}
						<br />
						{_('CancelDownloadInstructions2')}
						<br />
						{_('AreYouSureYouWantToCancel')}
					</div>
				),
			});
		}
	};

	const handleActionScope = async (sender: string) => {
		const key: string = sender;
		switch (key) {
			case 'abort':
				handleOnClickAbortDownload();
				break;
		}
	};

	const handleChange = (newSelectedRowKeys: React.Key[]): void => {
		let cloneStateProps: DigiTracTransaction[] = [];
		currentDataSource.forEach(item => {
			item.checked = newSelectedRowKeys.findIndex(key => key.valueOf() === item.BatchId) !== -1;
			cloneStateProps.push({ ...item });
		});
		setDataSourceRef(cloneStateProps);
	};

	const handleSelectAll = (): void => {
		setIsLoading(true);
		let cloneStateProps: DigiTracTransaction[] = [];
		currentDataSource.forEach(item => {
			item.checked = true;
			cloneStateProps.push({ ...item });
		});
		setDataSourceRef(cloneStateProps);
		setIsLoading(false);
	};

	const handleSelectInvert = (): void => {
		setIsLoading(true);
		const currentPaginatedData: DigiTracTransaction[] = getPaginatedDataBy(currentDataSource, paginationSettings.PageNumber, paginationSettings.PageSize);
		const cloneState: DigiTracTransaction[] = currentDataSource.map(x => {
			let checked = x.checked;
			const item = currentPaginatedData.find(y => y.BatchId === x.BatchId);
			if (item) {
				checked = !item.checked;
			}

			return {
				...x,
				checked: checked,
			};
		});
		setDataSourceRef(cloneState);
		setIsLoading(false);
	};

	const rowSelection: TableRowSelection<DigiTracTransaction> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: getDefaultTableSelectionConfigPagination(false, handleSelectAll, handleSelectInvert),
		getCheckboxProps: record => {
			return {
				id: `batch-${record['key']}`,
			};
		},
		selectedRowKeys: currentDataSource.filter(x => x.checked).map<React.Key>(x => x.BatchId),
		onChange: handleChange,
	};

	let content: React.ReactElement = <></>;
	if (!isLoading && isExpanded) {
		const tableData: DataType[] = currentDataSource.map(transaction => {
			return {
				key: transaction.BatchId,
				BatchId: transaction.BatchId,
				Description: transaction.Description.replace(/\|/g, ' '),
				ControllerName: transaction.ControllerName,
				StartTime: FormatDate(transaction.StartTime, true, null, true, false),
				Complete: transaction.Complete,
				Count: transaction.Count,
				Progress: transaction.Progress,
				Status: transaction.Status,
				Errors: transaction.Errors,
			};
		});

		content = (
			<>
				<div className={styles.buttonContainer}>
					<ButtonDropdown
						id='enrollmentCredentialActionsDropdown'
						menuOptions={items.options}
						icon={<CaretDownOutlined />}
						labelIcon={items.labelOrIcon}
						onClickOption={handleActionScope}
						disabled={currentDataSource.filter(x => x.checked).length === 0}
					/>
				</div>
				<Table
					pagination={paginationTable}
					id='statusDashboardQueuedDownloadDownloadMonitorTable'
					columns={mappedColumns as ColumnsType}
					dataSource={tableData}
					scroll={scroll}
					rowSelection={rowSelection}
					size='small'
					rowClassName={(record: DataType, index) => {
						if (index % 2 !== 0) {
							return styles.evenRow;
						}
					}}
					components={{
						body: {
							cell: EditableCell,
						},
					}}
					className={styles.statusWidgetTable}
				/>
			</>
		);
	}

	return (
		<Spin tip={`${_('Loading')}...`} spinning={isLoading} size='default' className={styles.spinContainer}>
			<div className={styles.queuedDownloadTableContainer}>{content}</div>
		</Spin>
	);
};

export { QueuedDownloadTable };

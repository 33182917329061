import { FastBackwardOutlined, FastForwardOutlined, StepBackwardOutlined, StepForwardOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cx from 'classnames';
import React from 'react';
import { ScrollType, buildColumn } from '../../../Helper';
import { Logger } from '../../../model/LoggingModel';
import styles from './calendar.module.scss';

export enum CalendarViewMode {
	Monthly = 'Monthly',
	Quarterly = 'Quarterly',
	Yearly = 'Yearly',
}

type CalendarData = {
	key: Date;
	tableData: Partial<ColumnsCalendar>[];
};

type QuarterDateRange = {
	lowMonth: number;
	highMonth: number;
};

type ColumnsCalendar = {
	key: string;
	LowerIndex: Date;
	HigherIndex: Date;
	Sun: React.ReactNode;
	Mon: React.ReactNode;
	Tue: React.ReactNode;
	Wen: React.ReactNode;
	Thu: React.ReactNode;
	Fri: React.ReactNode;
	Sat: React.ReactNode;
};

// Internal state for the component
interface State {
	viewMode: CalendarViewMode;
	datesData: Date[];
	initialDate: Date;
	content: CalendarData[];
	shiftSelectedDate?: Date;
	selectionMode: boolean;
}

// Normal properties for the component
interface OwnProps {
	selectedDates: Date[];
	onSelectedDate?: (date: Date[]) => void;
	onChangeViewMode?: (viewMode: CalendarViewMode) => void;
}

// combine them together
type Props = OwnProps;

//Avoid creating object style inline, since increases reconciliations
const daysNames: string[] = ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat'];

const monthNames: string[] = [
	_('January'),
	_('February'),
	_('March'),
	_('April'),
	_('May'),
	_('June'),
	_('July'),
	_('August'),
	_('September'),
	_('October'),
	_('November'),
	_('December'),
];

const quarterRange: QuarterDateRange[] = [
	{ lowMonth: 0, highMonth: 2 },
	{ lowMonth: 3, highMonth: 5 },
	{ lowMonth: 6, highMonth: 8 },
	{ lowMonth: 9, highMonth: 11 },
];

const scroll: ScrollType = { x: 379, y: 312 };

const columns: ColumnsType<any> = daysNames.map(day => {
	return { ...buildColumn(_(day), day) };
});

const orderDates = (dates: Date[]): Date[] => {
	return dates.sort((a: Date, b: Date) => {
		return a.getTime() - b.getTime();
	});
};

const orderContent = (content: CalendarData[]): CalendarData[] => {
	return content.sort((a: CalendarData, b: CalendarData) => {
		return a.key.getTime() - b.key.getTime();
	});
};

const dateExists = (lookUpDate: Date, dataArray: Date[]): boolean => {
	return dataArray.find(d => d.getTime() === lookUpDate.getTime()) !== undefined;
};

//Todays date without time
let currentDate: Date = new Date();
currentDate.setHours(0, 0, 0, 0);

const isFastBackwardActionDisabled = (viewMode: CalendarViewMode, lookUpDate: Date, dataArray: Date[]): boolean => {
	const lookUpDateYear: number = lookUpDate.getFullYear();
	const lookUpDateMonth: number = lookUpDate.getMonth();

	if (viewMode !== CalendarViewMode.Quarterly) {
		return dataArray.find(d => d.getFullYear() < lookUpDateYear || (d.getMonth() < lookUpDateMonth && d.getFullYear() === lookUpDateYear)) === undefined;
	}

	const item = quarterRange.find(r => lookUpDateMonth >= r.lowMonth && lookUpDateMonth <= r.highMonth);

	return (
		dataArray.find(d => d.getFullYear() < lookUpDate.getFullYear() || (d.getMonth() < item.lowMonth && d.getFullYear() === lookUpDate.getFullYear())) ===
		undefined
	);
};

const isFastForwardActionDisabled = (viewMode: CalendarViewMode, lookUpDate: Date, dataArray: Date[]): boolean => {
	const lookUpDateYear: number = lookUpDate.getFullYear();
	const lookUpDateMonth: number = lookUpDate.getMonth();

	if (viewMode !== CalendarViewMode.Quarterly) {
		return dataArray.find(d => d.getFullYear() > lookUpDateYear || (d.getMonth() > lookUpDateMonth && d.getFullYear() === lookUpDateYear)) === undefined;
	}

	const item = quarterRange.find(r => lookUpDateMonth >= r.lowMonth && lookUpDateMonth <= r.highMonth);

	return (
		dataArray.find(d => d.getFullYear() > lookUpDate.getFullYear() || (d.getMonth() > item.highMonth && d.getFullYear() === lookUpDate.getFullYear())) ===
		undefined
	);
};

const isBackwardActionDisabled = (date: Date): boolean => {
	return date.getFullYear() < currentDate.getFullYear();
};

const isForwardActionDisabled = (date: Date): boolean => {
	return date.getFullYear() > currentDate.getFullYear() + 1;
};

const getDateForQuarterViewMode = (lookUpDate: Date): Date => {
	const month = lookUpDate.getMonth();
	const item = quarterRange.find(r => month >= r.lowMonth && month <= r.highMonth);

	return new Date(lookUpDate.getFullYear(), item.lowMonth, 1, 0, 0, 0, 0);
};

const getStartDate = (viewMode: CalendarViewMode, lookUpDate: Date, backwards: boolean): Date => {
	if (viewMode === CalendarViewMode.Yearly) {
		return new Date(lookUpDate.getFullYear() + (backwards ? -1 : 1), 0, 1, 0, 0, 0, 0);
	}

	const factor: number = (backwards ? -1 : 1) * (viewMode === CalendarViewMode.Quarterly ? 3 : 1);

	return new Date(lookUpDate.getFullYear(), lookUpDate.getMonth() + factor, 1, 0, 0, 0, 0);
};

const getDateRange = (viewMode: CalendarViewMode, lookUpDate: Date): Date => {
	if (viewMode === CalendarViewMode.Yearly) {
		return new Date(lookUpDate.getFullYear(), 0, 1, 0, 0, 0, 0);
	}

	if (viewMode === CalendarViewMode.Monthly) {
		return new Date(lookUpDate.getFullYear(), lookUpDate.getMonth(), 1, 0, 0, 0, 0);
	}

	return getDateForQuarterViewMode(lookUpDate);
};

class Calendar extends React.Component<Props, State> {
	constructor(prop: Props) {
		super(prop);

		this.state = {
			viewMode: CalendarViewMode.Quarterly,
			datesData: [] as Date[],
			initialDate: currentDate,
			content: [] as CalendarData[],
			shiftSelectedDate: undefined,
			selectionMode: false,
		};
	}

	componentDidMount() {
		const { selectedDates, onChangeViewMode } = this.props;
		const { viewMode } = this.state;

		const cloneDatesDataStateProps: Date[] = orderDates(
			selectedDates.map(date => {
				//Remove time to avoid lookups issues
				date.setHours(0, 0, 0, 0);
				return date;
			})
		);

		onChangeViewMode?.(viewMode);

		let startDate: Date;
		if (cloneDatesDataStateProps.length) {
			startDate = getDateRange(viewMode, cloneDatesDataStateProps[0]);
		} else {
			startDate = getDateRange(viewMode, currentDate);
		}

		this.setState({
			initialDate: startDate,
			datesData: cloneDatesDataStateProps,
			content: this.initCalendar(new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0, 0), cloneDatesDataStateProps),
		});
	}

	componentDidCatch(error, errorInfo) {
		Logger.writeErrorLog(`${error}: ${errorInfo}`);
	}

	// Commented out intentionally if we want to do lazy loading in the future
	// private initCalendar = (startDate: Date, dataArray: Date[]): CalendarData[] => {
	// 	const { viewMode } = this.state;
	// 	let count: number = 12;
	// 	if (viewMode === CalendarViewMode.Monthly) {
	// 		count = 1;
	// 	} else if (viewMode === CalendarViewMode.Quarterly) {
	// 		count = 3;
	// 	}

	// 	let retValue: CalendarData[] = [];
	// 	for (let i: number = 0; i < count; i++) {
	// 		retValue.push(this.buildMonth(startDate, dataArray));
	// 	}

	// 	return retValue;
	// };

	// private validateContent = (viewMode: CalendarViewMode, date: Date) => {
	// 	const { content, datesData } = this.state;

	// 	let count: number = 12;
	// 	if (viewMode === CalendarViewMode.Monthly) {
	// 		count = 1;
	// 	} else if (viewMode === CalendarViewMode.Quarterly) {
	// 		count = 3;
	// 	}

	// 	let lookUpDate: Date = new Date(date.getTime());
	// 	let retValue: CalendarData[] = [];
	// 	for (let i: number = 0; i < count; i++) {
	// 		if (content.find(c => c.key.getTime() === lookUpDate.getTime()) === undefined) {
	// 			retValue.push(this.buildMonth(lookUpDate, datesData));
	// 		} else {
	// 			lookUpDate = new Date(lookUpDate.getFullYear(), lookUpDate.getMonth() + 1, 1, 0, 0, 0, 0);
	// 		}
	// 	}

	// 	this.setState({
	// 		initialDate: date,
	// 		viewMode: viewMode,
	// 		content: retValue.length ? orderContent(content.concat(retValue)) : content,
	// 	});
	// };

	//Fully create the calendar for 24 months view in order to improve performance upfront
	private initCalendar = (startDate: Date, dataArray: Date[]): CalendarData[] => {
		let retValue: CalendarData[] = [];
		for (let i: number = 0; i < 24; i++) {
			retValue.push(this.buildMonth(startDate, dataArray));
		}

		return retValue;
	};

	private validateContent = (viewMode: CalendarViewMode, date: Date) => {
		this.setState({
			initialDate: date,
			viewMode: viewMode,
		});
	};

	private handlerClick = (shiftKey: boolean, itemSelected: boolean, date: Date) => {
		const { content, datesData, shiftSelectedDate, selectionMode } = this.state;
		const { onSelectedDate } = this.props;

		let modifiedDates: Date[] = [];
		let cloneDatesDataStateProps: Date[];
		if (shiftKey && shiftSelectedDate) {
			let starDate: Date;
			let finishDate: Date;
			if (date.getTime() < shiftSelectedDate.getTime()) {
				starDate = new Date(date.getTime());
				finishDate = new Date(shiftSelectedDate.getTime());
			} else {
				starDate = new Date(shiftSelectedDate.getTime());
				finishDate = new Date(date.getTime());
			}

			cloneDatesDataStateProps = [...datesData];
			while (starDate.getTime() <= finishDate.getTime()) {
				const exists: boolean = cloneDatesDataStateProps.find(d => d.getTime() === starDate.getTime()) !== undefined;
				if (selectionMode) {
					if (!exists) {
						modifiedDates.push(new Date(starDate.getTime()));
						cloneDatesDataStateProps = cloneDatesDataStateProps.concat(new Date(starDate.getTime()));
					}
				} else {
					if (exists) {
						modifiedDates.push(new Date(starDate.getTime()));
						cloneDatesDataStateProps = cloneDatesDataStateProps.filter(d => d.getTime() !== starDate.getTime());
					}
				}
				starDate.setDate(starDate.getDate() + 1);
			}

			cloneDatesDataStateProps = orderDates(cloneDatesDataStateProps);
		} else {
			modifiedDates.push(date);
			if (itemSelected) {
				cloneDatesDataStateProps = datesData.filter(d => d.getTime() !== date.getTime());
			} else {
				cloneDatesDataStateProps = orderDates(datesData.concat(date));
			}
		}

		let cloneContentStateProps: CalendarData[] = [...content];
		for (let i: number = 0; i < modifiedDates.length; i++) {
			const lookUpDate: Date = modifiedDates[i];
			const property: string = daysNames[lookUpDate.getDay()];

			cloneContentStateProps = cloneContentStateProps.map(d =>
				d.key.getMonth() === lookUpDate.getMonth() && d.key.getFullYear() === lookUpDate.getFullYear()
					? {
							...d,
							tableData: d.tableData.map(r =>
								r.LowerIndex !== undefined && r.HigherIndex !== undefined && lookUpDate >= r.LowerIndex && lookUpDate <= r.HigherIndex
									? { ...r, [property]: this.buildButton(lookUpDate, cloneDatesDataStateProps) }
									: r
							),
					  }
					: d
			);
		}

		this.setState(
			{
				selectionMode: !itemSelected,
				shiftSelectedDate: new Date(date.getTime()),
				datesData: cloneDatesDataStateProps,
				content: cloneContentStateProps,
			},
			() => {
				onSelectedDate(this.state.datesData);
			}
		);
	};

	private buildButton = (date: Date, dataArray: Date[]): React.ReactNode => {
		const today: boolean = currentDate.getTime() === date.getTime();
		const className: string = `${styles.column} ${today ? styles.today : ''}`;
		const itemSelected: boolean = dateExists(date, dataArray);
		const buttonType: any = itemSelected ? 'primary' : 'default';

		return (
			<Button
				key={date.toString()}
				className={className}
				type={buttonType}
				title={date.toDateString()}
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					this.handlerClick(e.shiftKey, itemSelected, date);
				}}>
				{date.getDate()}
			</Button>
		);
	};

	private buildMonth = (startDate: Date, dataArray: Date[]): CalendarData => {
		let data: CalendarData = {
			key: new Date(startDate.getTime()),
			tableData: [],
		};

		const month: number = startDate.getMonth();
		while (month === startDate.getMonth()) {
			let row: Partial<ColumnsCalendar> = { key: startDate.toString() };
			for (let i = 0; i < daysNames.length; i++) {
				const day: number = startDate.getDay();
				if (i === 0) {
					if (i === day) {
						row.LowerIndex = new Date(startDate.getTime());
					} else {
						const factor: number = (day - i) * -1;
						let date: Date = new Date(startDate.getTime());
						date.setDate(date.getDate() + factor);
						row.LowerIndex = date;
					}
				}

				if (i === day && month === startDate.getMonth()) {
					const date: Date = new Date(startDate.getTime());
					startDate.setDate(startDate.getDate() + 1);

					const property: string = daysNames[i];
					row[property] = this.buildButton(date, dataArray);
				}
			}

			const higherIndex: Date = new Date(row.LowerIndex.getTime());
			higherIndex.setDate(higherIndex.getDate() + daysNames.length - 1);
			row.HigherIndex = higherIndex;
			data.tableData.push(row);
		}

		//Build fake rows
		for (let i = data.tableData.length; i < 6; i++) {
			const row: Partial<ColumnsCalendar> = { key: startDate.toString(), Sun: <div className={styles.emptyRow}></div> };
			data.tableData.push(row);
		}

		return data;
	};

	private moveCalendar = (backwards: boolean) => {
		const { viewMode, initialDate } = this.state;

		let date = new Date(initialDate.getTime());
		if (viewMode === CalendarViewMode.Yearly) {
			date = new Date(initialDate.getFullYear() + (backwards ? -1 : 1), 0, 1, 0, 0, 0, 0);
		} else {
			const factor: number = (backwards ? -1 : 1) * (viewMode === CalendarViewMode.Quarterly ? 3 : 1);
			date = new Date(initialDate.getFullYear(), initialDate.getMonth() + factor, 1, 0, 0, 0, 0);
		}

		this.validateContent(viewMode, date);
	};

	private moveFastCalendar = (backwards: boolean) => {
		const { viewMode, initialDate, datesData } = this.state;
		const factor: number = backwards ? -1 : 1;

		let date: Date;
		if (viewMode === CalendarViewMode.Yearly) {
			date = new Date(initialDate.getFullYear() + factor, 0, 1, 0, 0, 0, 0);
		} else {
			date = new Date(initialDate.getTime());
			if (viewMode === CalendarViewMode.Monthly) {
				while (true) {
					date = new Date(date.getFullYear(), date.getMonth() + factor, 1, 0, 0, 0, 0);
					if (datesData.find(d => d.getFullYear() === date.getFullYear() && d.getMonth() === date.getMonth()) !== undefined) {
						break;
					}
				}
			} else {
				while (true) {
					date = new Date(date.getFullYear(), date.getMonth() + factor * 3, 1, 0, 0, 0, 0);
					const item = quarterRange.find(r => date.getMonth() >= r.lowMonth && date.getMonth() <= r.highMonth);
					if (
						datesData.find(d => d.getFullYear() === date.getFullYear() && d.getMonth() >= item.lowMonth && d.getMonth() <= item.highMonth) !==
						undefined
					) {
						date = getDateForQuarterViewMode(date);
						break;
					}
				}
			}
		}

		this.validateContent(viewMode, date);
	};

	private buildContent = (startDate: Date, tableData: Partial<ColumnsCalendar>[]): React.ReactNode => {
		const headerTitle: string = `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`;
		const header: React.ReactNode = <div className={styles.header}>{headerTitle}</div>;

		return (
			<div key={headerTitle}>
				{header}
				<Table columns={columns} dataSource={tableData} scroll={scroll} size='small' pagination={false} />
			</div>
		);
	};

	render() {
		const { viewMode, content, initialDate, datesData } = this.state;

		const backwardDisabled: boolean = isBackwardActionDisabled(getStartDate(viewMode, initialDate, true));
		const fastBackwardDisabled: boolean = backwardDisabled || isFastBackwardActionDisabled(viewMode, initialDate, datesData);
		const forwardDisabled: boolean = isForwardActionDisabled(getStartDate(viewMode, initialDate, false));
		const fastForwardDisabled: boolean = forwardDisabled || isFastForwardActionDisabled(viewMode, initialDate, datesData);

		const filterContent: CalendarData[] = content.filter(c => {
			const startDate: Date = c.key;
			const startDateYear: number = startDate.getFullYear();
			const initialDateYear: number = initialDate.getFullYear();

			if (viewMode === CalendarViewMode.Yearly) {
				if (startDateYear === initialDateYear) {
					return true;
				}

				return false;
			}

			const startDateMonth: number = startDate.getMonth();
			const initialDateMonth: number = initialDate.getMonth();
			if (viewMode === CalendarViewMode.Monthly) {
				if (startDateMonth === initialDateMonth && startDateYear === initialDateYear) {
					return true;
				}

				return false;
			}

			const item = quarterRange.find(r => initialDateMonth >= r.lowMonth && initialDateMonth <= r.highMonth);

			return startDateMonth >= item.lowMonth && startDateMonth <= item.highMonth && startDateYear === initialDateYear;
		});

		let previousLabel: string;
		let nextLabel: string;
		switch (viewMode) {
			case CalendarViewMode.Monthly:
				previousLabel = _('PreviousMonth');
				nextLabel = _('NextMonth');
				break;

			case CalendarViewMode.Quarterly:
				previousLabel = _('PreviousQuarter');
				nextLabel = _('NextQuarter');
				break;

			case CalendarViewMode.Yearly:
				previousLabel = _('PreviousYear');
				nextLabel = _('NextYear');
				break;
		}

		const buttonStyle: React.CSSProperties = { float: 'right' };

		return (
			<div className={styles.container}>
				<div
					className={cx(styles.buttonsActionOneItem, {
						[styles.buttonsActionThreeItems]: viewMode === CalendarViewMode.Quarterly || viewMode === CalendarViewMode.Yearly,
					})}>
					<div>
						<Button
							title={_('PreviousDateSelected')}
							disabled={fastBackwardDisabled}
							onClick={() => {
								this.moveFastCalendar(true);
							}}>
							<FastBackwardOutlined />
						</Button>
						<Button
							title={previousLabel}
							disabled={backwardDisabled}
							onClick={() => {
								this.moveCalendar(true);
							}}>
							<StepBackwardOutlined />
						</Button>
					</div>
					<div>
						<Button
							title={nextLabel}
							disabled={forwardDisabled}
							onClick={() => {
								this.moveCalendar(false);
							}}>
							<StepForwardOutlined />
						</Button>
						<Button
							title={_('NextDateSelected')}
							disabled={fastForwardDisabled}
							onClick={() => {
								this.moveFastCalendar(false);
							}}>
							<FastForwardOutlined />
						</Button>
					</div>
				</div>
				<div className={styles.grid}>
					{filterContent.map(c => {
						return this.buildContent(c.key, c.tableData);
					})}
				</div>
				<Button
					type='primary'
					style={buttonStyle}
					id='holidayMonthlyView'
					onClick={() => {
						const { viewMode, initialDate, datesData } = this.state;
						const newViewMode: CalendarViewMode = viewMode === CalendarViewMode.Monthly ? CalendarViewMode.Quarterly : CalendarViewMode.Monthly;

						let startDate: Date;
						if (datesData.length) {
							startDate = getDateRange(newViewMode, datesData[0]);
						} else {
							startDate = new Date(initialDate.getFullYear(), 0, 1, 0, 0, 0, 0);
						}
						this.props.onChangeViewMode?.(newViewMode);

						this.validateContent(newViewMode, startDate);
					}}>
					<SwapOutlined />
					{viewMode === CalendarViewMode.Quarterly ? _('MonthlyView') : _('QuarterlyView')}
				</Button>
			</div>
		);
	}
}

export { Calendar };

import { Spin, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { canAddNewTimeZones, handleResponse } from '../../../../../../Helper';
import { deviceAdminApi } from '../../../../../../api';
import { SubPermissions, User } from '../../../../../../model/AccountModel';
import { ResponseObject } from '../../../../../../model/CommonModel';
import { ReaderContextType, ReaderError, ReaderInterface, SelectTimeZone, TimeZoneType } from '../../../../../../model/DeviceAdminModel';
import { Modal } from '../../../../../common';
import { TimeZones } from '../../../../VelocityConfiguration/TimeZones/TimeZones';
import { setDoorNewTimeZoneAction } from '../../../DoorModal/DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorModal/DoorStoreSingleContext';
import { ScrambleFactor } from '../../Tabs/ScrambleFactor/ScrambleFactor';
import { CardReaderSetup, General, Logic, Options } from '../Tabs';
import {
	setExitReaderCardCodeOnlyTimeZone,
	setExitReaderErrorMessage,
	setExitReaderErrorType,
	setExitReaderGenericTimeZones,
	setExitScrambleFactorErrorImageCategory,
} from '../exitReaderContext';

const { TabPane } = Tabs;

type Props = {
	submittedForm: boolean;
	errorType: ReaderError;
	errorMessage: string;
	id: number;
	setLoading: (boolean) => void;
	isLoading: boolean;
	handleDownloadFirmware: () => void;
	setErrorText: () => void;
	onRefreshTimeZones?: (timeZones: SelectTimeZone[]) => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const canViewTSScrambleFactor: boolean = User.getSubComponentPermission(user, SubPermissions.Reader_ViewTSScrambleFactor).allowed;

const ExitReaderContainer: React.FC<Props> = ({
	submittedForm,
	handleDownloadFirmware,
	errorType,
	errorMessage,
	id,
	setLoading,
	isLoading,
	setErrorText,
	onRefreshTimeZones,
}) => {
	const [showNewTimeZone, setShowNewTimeZone] = useState<boolean>(false);
	const [newTimeZoneId, setNewTimeZoneId] = useState<number>(0);
	const [previousSelection, setPreviousSelection] = useState<number>(1);
	const [activeKey, setActiveKey] = useState(undefined);

	const {
		contextStateDoor: {
			exitReader: stateContext,
			door: { newTimeZoneAdded },
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const {
		selections: { cardCodeOnlyTimeZone, rS485ReaderType },
		readerInterface,
		isExitReaderError,
	} = stateContext;

	useEffect(() => {
		if (isExitReaderError) {
			dispatcherDoor(setExitReaderErrorType(errorType));

			if (errorType === ReaderError.DuplicatedScrambleImageName) {
				const [message, imageName, categoryId] = errorMessage.split('\n');
				const fullErrorMessage: string = _(message).replace('%1', imageName);

				dispatcherDoor(setExitReaderErrorMessage(fullErrorMessage));
				dispatcherDoor(setExitScrambleFactorErrorImageCategory(categoryId));
			} else {
				dispatcherDoor(setExitReaderErrorMessage(errorMessage));
			}

			switch (errorType) {
				case ReaderError.Name:
				case ReaderError.EACAddress:
				case ReaderError.DuplicatedWAddress:
					setActiveKey('1');
					break;
				case ReaderError.Assurance:
					setActiveKey('3');
					break;
				case ReaderError.DuplicatedScrambleImageName:
					setActiveKey('5');
					break;
			}
		}
	}, [errorType]);

	useEffect(() => {
		if (cardCodeOnlyTimeZone === 0) {
			deviceAdminApi.checkIfAnyTimeZoneIsNotLocked().then((res: ResponseObject) => {
				const isLocked: boolean = handleResponse(res);
				if (!isLocked) {
					setShowNewTimeZone(true);
				} else {
					handleCloseNewTimeZone();
				}
			});
		}
	}, [cardCodeOnlyTimeZone]);

	const fetchNewTimeZones = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: true,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};
				genericTimeZones.unshift(newTimeZone);
			}
			dispatcherDoor(setExitReaderGenericTimeZones(genericTimeZones));
			if (cardCodeOnlyTimeZone === 0) {
				dispatcherDoor(setExitReaderCardCodeOnlyTimeZone(newTimeZoneId));
			}
			setShowNewTimeZone(false);
		});
	};

	useEffect(() => {
		dispatcherDoor(setDoorNewTimeZoneAction(newTimeZoneId));
	}, [newTimeZoneId]);

	useEffect(() => {
		if (newTimeZoneAdded) {
			fetchNewTimeZones();
		}
	}, [newTimeZoneAdded]);

	const handleOnChangeTab = (activeKey: string) => {
		setActiveKey(activeKey);
	};

	const handleCloseNewTimeZone = () => {
		dispatcherDoor(setExitReaderCardCodeOnlyTimeZone(previousSelection));

		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: false,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};

				genericTimeZones.unshift(newTimeZone);
			}
			onRefreshTimeZones?.(genericTimeZones);

			const existTimeZone = genericTimeZones.find(x => x.GenericId === previousSelection) ? previousSelection : 1;

			dispatcherDoor(setExitReaderCardCodeOnlyTimeZone(existTimeZone));
		});

		setShowNewTimeZone(false);
	};

	useEffect(() => {
		fetchNewTimeZones();
	}, []);

	const areTypesValidToShowScrambleFactor = (): boolean => {
		return canViewTSScrambleFactor && readerInterface === ReaderInterface.RS485 && rS485ReaderType === 9;
	};

	return (
		<>
			<Spin size='large' tip={`${_('Loading')}...`} spinning={isLoading}>
				<Tabs onChange={handleOnChangeTab} activeKey={activeKey}>
					<TabPane tab={<span id='exitReaderSetupTabName'>{_('SetUp')}</span>} key='1'>
						<General submittedForm={submittedForm} handleDownloadFirmware={handleDownloadFirmware} setErrorText={setErrorText} />
					</TabPane>
					<TabPane tab={<span id='exitReaderOptionsTabName'>{_('Options')}</span>} key='2'>
						<Options />
					</TabPane>
					<TabPane tab={<span id='exitReaderLogicTabName'>{_('Logic')}</span>} key='3'>
						<Logic setPreviousSelection={setPreviousSelection} />
					</TabPane>
					<TabPane tab={<span id='exitReaderCardReaderSetupTabName'>{_('CardReaderSetup')}</span>} key='4'>
						<CardReaderSetup />
					</TabPane>
					{areTypesValidToShowScrambleFactor() && (
						<TabPane tab={<span id={'exitReaderTSScrambleFactorTabName'}>{_('TSScrambleFactor')}</span>} key='5'>
							<ScrambleFactor readerId={id} readerContextType={ReaderContextType.ExitReader} />
						</TabPane>
					)}
				</Tabs>
			</Spin>
			<Modal
				visible={showNewTimeZone}
				title={_('TimeZones')}
				onClickOk={handleCloseNewTimeZone}
				onCancel={handleCloseNewTimeZone}
				width={'900px'}
				footer={null}>
				<TimeZones onEntityAction={setNewTimeZoneId} />
			</Modal>
		</>
	);
};

export { ExitReaderContainer };

import React, { useEffect, useState } from 'react';
import { enrollmentApi } from '../../../../../api';
import { reportsApi } from '../../../../../api/ReportsApi';
import { handleResponse } from '../../../../../Helper';
import { ReportUDFInfo } from '../../../../../model/ReportsModel';
import { Select, SelectOption } from '../../../../common/Select/Select';
import styles from '../../ReportTabs/reportTabs.module.scss';

type Props = {
	reportId: number;
	udf1: number;
	udf2: number;
	udf3: number;
	onSetUDF1: (value: number) => void;
	onSetUDF2: (value: number) => void;
	onSetUDF3: (value: number) => void;
	onSetUDFNumber: (value: number) => void;
	onSetHasUDFChanged: (value: boolean) => void;
	isEditingCriteria: boolean;
};
const ReportUserDefinedFields: React.FC<Props> = ({
	reportId,
	udf1,
	udf2,
	udf3,
	onSetUDF1,
	onSetUDF2,
	onSetUDF3,
	onSetHasUDFChanged,
	onSetUDFNumber,
	isEditingCriteria,
}) => {
	const [options, setOptions] = useState<SelectOption>([]);

	useEffect(() => {
		GetUserDefinedFields();
	}, []);

	const GetUserDefinedFields = async () => {
		const udfs = await enrollmentApi.getUserDefinedFields();
		const udfsFormatted: SelectOption = udfs.Entity.map(x => ({
			id: x.FieldNumber.toString(),
			label: `${x.FieldNumber}-${x.Caption}`,
			value: x.FieldNumber,
		}));

		setOptions(udfsFormatted);
	};

	const updateUserDefinedFields = async (reportUDFInfo: ReportUDFInfo) => {
		const response = await reportsApi.updateReportUserDefinedFields(reportUDFInfo);

		handleResponse(response);
	};

	const handleOnChangeUDF1 = (value: string) => {
		const newValue: number = Number(value);
		const reportUDFInfo: ReportUDFInfo = { ReportId: reportId, UDF1: newValue, UDF2: udf2, UDF3: udf3 };

		onSetUDF1(newValue);
		updateUserDefinedFields(reportUDFInfo);
		onSetUDFNumber(1);
		onSetHasUDFChanged(true);
	};

	const handleOnChangeUDF2 = (value: string) => {
		const newValue: number = Number(value);
		const reportUDFInfo: ReportUDFInfo = { ReportId: reportId, UDF1: udf1, UDF2: newValue, UDF3: udf3 };

		onSetUDF2(newValue);
		updateUserDefinedFields(reportUDFInfo);
		onSetUDFNumber(2);
		onSetHasUDFChanged(true);
	};

	const handleOnChangeUDF3 = (value: string) => {
		const newValue: number = Number(value);
		const reportUDFInfo: ReportUDFInfo = { ReportId: reportId, UDF1: udf1, UDF2: udf2, UDF3: newValue };

		onSetUDF3(newValue);
		updateUserDefinedFields(reportUDFInfo);
		onSetUDFNumber(3);
		onSetHasUDFChanged(true);
	};

	return (
		<div className={styles.UserDefinedFields}>
			<div className={styles.grid}>
				<div>
					<h4>{_('SelectUserDefinedColumns')}</h4>
				</div>
				<div>
					<label htmlFor='reportsReportTabUserDefinedField1'>{_('ShowUserDefinedColumn1')}</label>
					<Select
						ariaAttributes={{
							'aria-label': _('UserDefinedColumnOne'),
							'aria-owns': 'reportsReportTabUserDefinedField1',
							'aria-activedescendant': 'reportsReportTabUserDefinedField1',
							'aria-controls': 'reportsReportTabUserDefinedField1',
							'aria-expanded': 'true',
						}}
						options={options}
						onChange={handleOnChangeUDF1}
						value={udf1 > 0 ? udf1.toString() : ''}
						id='reportsReportTabUserDefinedField1'
						getPopupContainer={() => document.getElementById('reportsReportTab')}
						disabled={isEditingCriteria}
					/>
				</div>
				<div>
					<label htmlFor='reportsReportTabUserDefinedField2'>{_('ShowUserDefinedColumn2')}</label>
					<Select
						ariaAttributes={{
							'aria-label': _('UserDefinedColumnTwo'),
							'aria-owns': 'reportsReportTabUserDefinedField2',
							'aria-activedescendant': 'reportsReportTabUserDefinedField2',
							'aria-controls': 'reportsReportTabUserDefinedField2',
							'aria-expanded': 'true',
						}}
						options={options}
						onChange={handleOnChangeUDF2}
						value={udf2 > 0 ? udf2.toString() : ''}
						id='reportsReportTabUserDefinedField2'
						getPopupContainer={() => document.getElementById('reportsReportTab')}
						disabled={isEditingCriteria}
					/>
				</div>
				<div>
					<label htmlFor='reportsReportTabUserDefinedField3'>{_('ShowUserDefinedColumn3')}</label>
					<Select
						ariaAttributes={{
							'aria-label': _('UserDefinedColumnThree'),
							'aria-owns': 'reportsReportTabUserDefinedField3',
							'aria-activedescendant': 'reportsReportTabUserDefinedField3',
							'aria-controls': 'reportsReportTabUserDefinedField3',
							'aria-expanded': 'true',
						}}
						options={options}
						onChange={handleOnChangeUDF3}
						value={udf3 > 0 ? udf3.toString() : ''}
						id='reportsReportTabUserDefinedField3'
						getPopupContainer={() => document.getElementById('reportsReportTab')}
						disabled={isEditingCriteria}
					/>
				</div>
			</div>
		</div>
	);
};

export { ReportUserDefinedFields };

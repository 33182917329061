import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, PaginationProps, Spin, Table, TablePaginationConfig } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { whosInsideApi } from '../../../api';
import {
	buildColumn,
	ColumnsProps,
	getDefaultTablePaginationConfig,
	getDefaultTableSelectionConfigPagination,
	getUniqueValuesArray,
	handleResponse,
	invertSelectedRowKeys,
	ScrollType
} from '../../../Helper';
import { PaginationSettingTyped, ResponseObject, ResponseStatusCode, SearchSettings, SortDirections, SorterConfigTyped } from '../../../model/CommonModel';
import {
	ControllerZone,
	DoubleTableModal,
	ErrorInfo,
	LoadControllerZonesHelper,
	WhosInsideControllerDataType,
	ZoneControllersColumns,
	ZoneGroupModalInfo,
	ZoneGroupType
} from '../../../model/WhosInsideModel';
import { Modal, NotificationStatus, SearchColumn } from '../../common';
import styles from '../whosInside.module.scss';

type Props = {
	onHideModal: () => void;
	onOkModal: (newName: string) => void;
	zoneId?: number;
};
const transferErrorStyle: React.CSSProperties = { border: '1px solid red' };
const scroll: ScrollType = { y: 192 };
const initialItemSearchedPagination: SearchSettings<ZoneControllersColumns>[] = [{ Field: ZoneControllersColumns.ControllerName, SearchValue: '' }];
const zoneGroupMaxLength: number = 100;
const controllerNameMaxLength: number = 64;

const WhosInsideAddCustomZone: React.VFC<Props> = ({ onHideModal, onOkModal, zoneId }) => {
	const [errorList, setErrorList] = useState('');
	const [saveZoneGroup, setSaveZoneGroup] = useState<boolean>(false);
	const [loadZones, setLoadZones] = useState<boolean>(false);
	const [newSelected, setNewSelected] = useState<number[]>([]);
	const [newAvailable, setNewAvailable] = useState<number[]>([]);
	const [leftControllers, setLeftControllers] = useState([]);
	const [rightControllers, setRightControllers] = useState([]);
	const [tableData, setTableData] = useState<DoubleTableModal<WhosInsideControllerDataType[]>>({ left: [], right: [] });
	const [currentPage, setCurrentPage] = useState<DoubleTableModal<number>>({ left: 1, right: 1 });
	const [currentPageSize, setCurrentPageSize] = useState<DoubleTableModal<number>>({ left: 25, right: 25 });
	const [totalItemsPagination, setTotalItemsPagination] = useState<DoubleTableModal<number>>({ left: 0, right: 0 });
	const [allItemsPagination, setAllItemsPagination] = useState<DoubleTableModal<number[]>>({ left: [], right: [] });
	const [leftItemSearchedPagination, setLeftItemSearchedPagination] = useState<SearchSettings<ZoneControllersColumns>[]>(initialItemSearchedPagination);
	const [rightItemSearchedPagination, setRightItemSearchedPagination] = useState<SearchSettings<ZoneControllersColumns>[]>(initialItemSearchedPagination);
	const [shouldResetNameSearchColumn, setShouldResetNameSearchColumn] = useState<DoubleTableModal<boolean>>({ left: false, right: false });
	const [selectedLeftRowKeys, setSelectedLeftRowKeys] = useState<React.Key[]>([]);
	const [selectedRightRowKeys, setSelectedRightRowKeys] = useState<React.Key[]>([]);
	const [loadingTables, setLoadingTables] = useState<DoubleTableModal<boolean>>({ left: false, right: false });

	const leftSorterConfig = useRef<SorterConfigTyped<ZoneControllersColumns>>({ direction: SortDirections.None, field: ZoneControllersColumns.None });
	const rightSorterConfig = useRef<SorterConfigTyped<ZoneControllersColumns>>({ direction: SortDirections.None, field: ZoneControllersColumns.None });
	const currentZoneRef = useRef<ZoneGroupModalInfo | null>();

	const [form] = Form.useForm();

	useEffect(() => {
		const mapRes: WhosInsideControllerDataType[] = createData(leftControllers);
		setTableData(prevState => ({ ...prevState, left: mapRes }));

		const previousSelectedRowKeys: React.Key[] = getPreviousSelectedRowKeys(leftControllers);
		setSelectedLeftRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedLeftRowKeys));
	}, [leftControllers]);

	useEffect(() => {
		const mapRes: WhosInsideControllerDataType[] = createData(rightControllers);
		setTableData(prevState => ({ ...prevState, right: mapRes }));

		const previousSelectedRowKeys: React.Key[] = getPreviousSelectedRowKeys(rightControllers);
		setSelectedRightRowKeys(getUniqueValuesArray(previousSelectedRowKeys, selectedRightRowKeys));
	}, [rightControllers]);

	useEffect(() => {
		setLoadZones(true);
		whosInsideApi
			.getCustomZoneGroupModal(zoneId)
			.then(res => {
				if (res.ResponseStatusCode === ResponseStatusCode.Success) {
					currentZoneRef.current = res.Entity;
					setAllItemsPagination({ left: res.Entity.AllLeftItemsPagination, right: res.Entity.AllRightItemsPagination });
					form.setFields([
						{
							name: 'zoneName',
							value: res.Entity.Name,
						},
					]);

					const zoneInfoHelper: LoadControllerZonesHelper = {
						ZoneGroupId: res.Entity.Id,
						ZoneGroupType: res.Entity.Type,
						IsLeft: true,
						SelectedControllersId: [],
						UnselectedControllersId: [],
					};

					updateBothTables(zoneInfoHelper);
				} else {
					onHideModal();
					handleResponse(res);
				}
			})
			.finally(() => setLoadZones(false));
	}, []);

	const disableSaveChanges: boolean =
		zoneId === 0 ||
		currentZoneRef.current?.Type === ZoneGroupType.Globalized ||
		currentZoneRef.current?.Type === ZoneGroupType.Custom ||
		currentZoneRef.current?.Type === ZoneGroupType.GIOG;
	const disableTransfer: boolean = zoneId === 0 || currentZoneRef.current?.Type === ZoneGroupType.Custom;
	const disableNameInput: boolean = zoneId === 0 || currentZoneRef.current?.Type !== ZoneGroupType.Default;
	const disableAddController: boolean = selectedLeftRowKeys.length === 0 || !disableTransfer;
	const disableRemoveController: boolean = selectedRightRowKeys.length === 0 || !disableTransfer;

	const leftTableColumns: ColumnsProps<WhosInsideControllerDataType>[] = [
		{
			...buildColumn(_('Name'), 'ControllerName', 'auto', 'start'),
			sorter: true,
			...SearchColumn({
				maxLength: controllerNameMaxLength,
				dataIndex: 'ControllerName',
				reset: undefined,
				label: _('Name'),
				notVisible: false,
				resetSearch: shouldResetNameSearchColumn.left,
				setIsFilterMode: undefined,
				searchColumnId: 'searchColumnCustomZone',
				clearSelection: () => clearSelection(true),
				handleResetSearch: () => setShouldResetNameSearchColumn(prevState => ({ ...prevState, left: false })),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, true),
				setSearchedValue: (searchedValue: string) =>
					setLeftItemSearchedPagination([{ Field: ZoneControllersColumns.ControllerName, SearchValue: searchedValue }]),
			}),
		},
		{
			...buildColumn(_('Address'), 'ControllerAddress', '190px', 'start'),
			sorter: true,
		},
		{
			...buildColumn(_('CurrentGroup'), 'ZoneName', 'auto', 'start'),
			sorter: true,
		},
	];

	const rightTableColumns: ColumnsProps<WhosInsideControllerDataType>[] = [
		{
			...buildColumn(_('Name'), 'ControllerName', 'auto', 'start'),
			sorter: true,
			...SearchColumn({
				maxLength: controllerNameMaxLength,
				dataIndex: 'ControllerName',
				reset: undefined,
				label: _('Name'),
				notVisible: false,
				resetSearch: shouldResetNameSearchColumn.right,
				setIsFilterMode: undefined,
				searchColumnId: 'searchColumnCustomZoneRight',
				clearSelection: () => clearSelection(false),
				handleResetSearch: () => setShouldResetNameSearchColumn(prevState => ({ ...prevState, right: false })),
				setSearchResults: (searchedValue: string) => handleOnSearchResults(searchedValue, false),
				setSearchedValue: (searchedValue: string) =>
					setRightItemSearchedPagination([{ Field: ZoneControllersColumns.ControllerName, SearchValue: searchedValue }]),
			}),
		},
		{
			...buildColumn(_('Address'), 'ControllerAddress', '190px', 'start'),
			sorter: true,
		},
	];

	const handleOnSearchResults = (searchedValue: string, isLeft: boolean) => {
		const zoneInfoHelper: LoadControllerZonesHelper = {
			ZoneGroupId: currentZoneRef.current.Id,
			ZoneGroupType: currentZoneRef.current.Type,
			IsLeft: true,
			SelectedControllersId: newSelected,
			UnselectedControllersId: newAvailable,
		};

		if (isLeft) {
			const leftPaginationSettings: PaginationSettingTyped<ZoneControllersColumns> = {
				PageNumber: searchedValue !== '' ? 1 : currentPage.left,
				PageSize: currentPageSize.left,
				SortDirection: leftSorterConfig.current.direction,
				SortField: leftSorterConfig.current.field,
				SearchSettings: [{ Field: ZoneControllersColumns.ControllerName, SearchValue: searchedValue }],
			};

			setZoneControllersBy(leftPaginationSettings, zoneInfoHelper, true);
			whosInsideApi.getZoneControllersAllItemsPagination(zoneInfoHelper, leftPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, left: res }));
			});
		} else {
			const rightPaginationSettings: PaginationSettingTyped<ZoneControllersColumns> = {
				PageNumber: searchedValue !== '' ? 1 : currentPage.right,
				PageSize: currentPageSize.right,
				SortDirection: rightSorterConfig.current.direction,
				SortField: rightSorterConfig.current.field,
				SearchSettings: [{ Field: ZoneControllersColumns.ControllerName, SearchValue: searchedValue }],
			};

			setZoneControllersBy(rightPaginationSettings, { ...zoneInfoHelper, IsLeft: false }, false);
			whosInsideApi.getZoneControllersAllItemsPagination({ ...zoneInfoHelper, IsLeft: false }, rightPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, right: res }));
			});
		}
	};

	const getPreviousSelectedRowKeys = (controllers: WhosInsideControllerDataType[]): React.Key[] => {
		const previousSelectedRowKeys: React.Key[] = controllers.reduce<React.Key[]>((result: React.Key[], item: WhosInsideControllerDataType) => {
			if (item.checked) {
				result.push(item.ControllerId);
			}
			return result;
		}, []);

		return previousSelectedRowKeys;
	};

	const createData = (zoneControllers: ControllerZone[]): WhosInsideControllerDataType[] => {
		if (zoneControllers.length > 0) {
			return zoneControllers.map<WhosInsideControllerDataType>((data: ControllerZone) => ({ ...data, key: data.ControllerId }));
		}

		return [];
	};

	const updateBothTables = async (zoneInfoHelper: LoadControllerZonesHelper) => {
		const leftPaginationSettings: PaginationSettingTyped<ZoneControllersColumns> = {
			PageNumber: currentPage.left,
			PageSize: currentPageSize.left,
			SortDirection: leftSorterConfig.current.direction,
			SortField: leftSorterConfig.current.field,
			SearchSettings: leftItemSearchedPagination,
		};

		const rightPaginationSettings: PaginationSettingTyped<ZoneControllersColumns> = {
			PageNumber: currentPage.right,
			PageSize: currentPageSize.right,
			SortDirection: rightSorterConfig.current.direction,
			SortField: rightSorterConfig.current.field,
			SearchSettings: rightItemSearchedPagination,
		};
		await setZoneControllersBy(leftPaginationSettings, zoneInfoHelper, true);
		await setZoneControllersBy(rightPaginationSettings, { ...zoneInfoHelper, IsLeft: false }, false);
	};

	const clearSelection = (isLeft: boolean) => {
		if (isLeft) {
			setSelectedLeftRowKeys([]);
		} else {
			setSelectedRightRowKeys([]);
		}
	};

	const handleChangeLeftPagination = (page: number, pageSize: number) => {
		setCurrentPage(prevState => ({ ...prevState, left: page }));
		if (pageSize !== currentPageSize.left) {
			setCurrentPageSize(prevState => ({ ...prevState, left: pageSize }));
		}
	};

	const handleChangeRightPagination = (page: number, pageSize: number) => {
		setCurrentPage(prevState => ({ ...prevState, right: page }));
		if (pageSize !== currentPageSize.right) {
			setCurrentPageSize(prevState => ({ ...prevState, right: pageSize }));
		}
	};

	const handleOnChangeTable = (pagination: PaginationProps, filters, sorter, isLeft: boolean) => {
		if (isLeft) {
			setLoadingTables(prevState => ({ ...prevState, left: true }));
		} else {
			setLoadingTables(prevState => ({ ...prevState, right: true }));
		}

		const { order, field, column } = sorter;
		const { current, pageSize } = pagination;
		const sortField: string = column?.dataIndex as keyof typeof ZoneControllersColumns;
		const sorterConfigDirection = isLeft ? leftSorterConfig.current.direction : rightSorterConfig.current.direction;
		let sortOrder = SortDirections.None;
		if (order) {
			sortOrder = order === 'ascend' ? SortDirections.Ascend : SortDirections.Descend;
		}

		if (sortOrder !== sorterConfigDirection) {
			if (isLeft) {
				leftSorterConfig.current = { direction: sortOrder, field };
			} else {
				rightSorterConfig.current = { direction: sortOrder, field };
			}
		}

		const zoneInfoHelper: LoadControllerZonesHelper = {
			ZoneGroupId: currentZoneRef.current.Id,
			ZoneGroupType: currentZoneRef.current.Type,
			IsLeft: isLeft,
			SelectedControllersId: newSelected,
			UnselectedControllersId: newAvailable,
		};

		if (isLeft) {
			handleChangeLeftPagination(current, pageSize);
			if ((current !== currentPage.left || pageSize !== currentPageSize.left || sortOrder !== sorterConfigDirection) && !filters.ZoneName) {
				setZoneControllersBy(
					{
						PageNumber: current,
						PageSize: pageSize,
						SortDirection: sortOrder,
						SortField: ZoneControllersColumns[sortField],
						SearchSettings: leftItemSearchedPagination,
					} as PaginationSettingTyped<ZoneControllersColumns>,
					zoneInfoHelper,
					true
				).finally(() => setLoadingTables(prevState => ({ ...prevState, left: false })));
			} else {
				setLoadingTables(prevState => ({ ...prevState, left: false }));
			}
		} else {
			handleChangeRightPagination(current, pageSize);
			if ((current !== currentPage.right || pageSize !== currentPageSize.right || sortOrder !== sorterConfigDirection) && !filters.ZoneName) {
				setZoneControllersBy(
					{
						PageNumber: current,
						PageSize: pageSize,
						SortDirection: sortOrder,
						SortField: ZoneControllersColumns[sortField],
						SearchSettings: rightItemSearchedPagination,
					} as PaginationSettingTyped<ZoneControllersColumns>,
					zoneInfoHelper,
					false
				).finally(() => setLoadingTables(prevState => ({ ...prevState, right: false })));
			} else {
				setLoadingTables(prevState => ({ ...prevState, right: false }));
			}
		}
	};

	const handleSelectAllRight = () => {
		let currentAllRightItemsPagination: number[];
		const index: number = rightItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		if (index !== -1) {
			currentAllRightItemsPagination = allItemsPagination.right;
		} else {
			const currentNewAvailable: Set<number> = new Set(newAvailable);
			currentAllRightItemsPagination = allItemsPagination.right.filter(x => !currentNewAvailable.has(x));
			currentAllRightItemsPagination = getUniqueValuesArray(currentAllRightItemsPagination, newSelected);
		}

		const newSelectedRowKeys = getUniqueValuesArray(selectedRightRowKeys, currentAllRightItemsPagination);

		const cloneStateProps: WhosInsideControllerDataType[] = rightControllers.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(r => r === x.ControllerId) !== -1,
		}));

		setRightControllers(cloneStateProps);
		setSelectedRightRowKeys(newSelectedRowKeys);
	};

	const handleRightSelectInvert = () => {
		const dataKeys = rightControllers.reduce<React.Key[]>((result, item) => {
			if (!item.Disabled) {
				result.push(item.ControllerId);
			}
			return result;
		}, []);

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedRightRowKeys);

		const cloneStateProps: WhosInsideControllerDataType[] = rightControllers.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.ControllerId) !== -1,
		}));

		setRightControllers(cloneStateProps);
		setSelectedRightRowKeys(newSelectedRowKeys);
	};

	const handleSelectAllLeft = () => {
		let currentAllLeftItemsPagination: number[];
		const index: number = leftItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		if (index !== -1) {
			currentAllLeftItemsPagination = allItemsPagination.left;
		} else {
			const currentNewSelected: Set<number> = new Set(newSelected);
			currentAllLeftItemsPagination = allItemsPagination.left.filter(x => !currentNewSelected.has(x));
			currentAllLeftItemsPagination = getUniqueValuesArray(currentAllLeftItemsPagination, newAvailable);
		}

		const newSelectedRowKeys = getUniqueValuesArray(selectedLeftRowKeys, currentAllLeftItemsPagination);

		const cloneStateProps: WhosInsideControllerDataType[] = leftControllers.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(r => r === x.ControllerId) !== -1,
		}));

		setLeftControllers(cloneStateProps);
		setSelectedLeftRowKeys(newSelectedRowKeys);
	};

	const handleLeftSelectInvert = () => {
		const dataKeys = leftControllers.reduce<React.Key[]>((result, item) => {
			result.push(item.ControllerId);
			return result;
		}, []);

		const newSelectedRowKeys = invertSelectedRowKeys(dataKeys, selectedLeftRowKeys);

		const cloneStateProps: WhosInsideControllerDataType[] = leftControllers.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(key => key === x.ControllerId) !== -1,
		}));

		setLeftControllers(cloneStateProps);
		setSelectedLeftRowKeys(newSelectedRowKeys);
	};

	const handleRightChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneStateProps: WhosInsideControllerDataType[] = rightControllers.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(controllerId => controllerId === x.ControllerId) !== -1,
		}));

		setRightControllers(cloneStateProps);
		setSelectedRightRowKeys(newSelectedRowKeys);
	};

	const handleLeftChange = (newSelectedRowKeys: React.Key[]) => {
		const cloneStateProps: WhosInsideControllerDataType[] = leftControllers.map(x => ({
			...x,
			checked: newSelectedRowKeys.findIndex(controllerId => controllerId === x.ControllerId) !== -1,
		}));

		setLeftControllers(cloneStateProps);
		setSelectedLeftRowKeys(newSelectedRowKeys);
	};

	const leftRowSelection: TableRowSelection<WhosInsideControllerDataType> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: disableTransfer ? getDefaultTableSelectionConfigPagination(false, handleSelectAllLeft, handleLeftSelectInvert) : false,
		getCheckboxProps: record => ({
			id: `whosInsideLeftTableCheckbox-${record.key}`,
			disabled: record.Disabled || !disableTransfer,
			children: <label htmlFor={`controllerContactTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
		}),
		selectedRowKeys: selectedLeftRowKeys,
		onChange: handleLeftChange,
	};

	const rightRowSelection: TableRowSelection<WhosInsideControllerDataType> = {
		preserveSelectedRowKeys: true,
		type: 'checkbox',
		selections: disableTransfer ? getDefaultTableSelectionConfigPagination(false, handleSelectAllRight, handleRightSelectInvert) : false,
		getCheckboxProps: record => ({
			id: `whosInsideRightTableCheckbox-${record.key}`,
			disabled: record.Disabled || !disableTransfer,
			children: <label htmlFor={`whosInsideRightTableCheckbox-${record.key}`} className={styles.srOnly}>{`${_('SelectItem')}`}</label>,
		}),
		selectedRowKeys: selectedRightRowKeys,
		onChange: handleRightChange,
	};

	const leftPagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangeLeftPagination,
		currentPage.left,
		currentPageSize.left,
		totalItemsPagination.left,
		undefined,
		selectedLeftRowKeys
	);

	const rightPagination: TablePaginationConfig = getDefaultTablePaginationConfig(
		false,
		handleChangeRightPagination,
		currentPage.right,
		currentPageSize.right,
		totalItemsPagination.right,
		undefined,
		selectedRightRowKeys
	);

	const handleOnAddController = () => {
		if (errorList) {
			setErrorList('');
		}

		const clonedNewAvailable = [...newAvailable];
		const clonedNewSelected = [...newSelected];
		for (const selectedController of selectedLeftRowKeys) {
			const controllerIndex = clonedNewAvailable.findIndex(x => x === selectedController);
			if (~controllerIndex) {
				clonedNewAvailable.splice(controllerIndex, 1);
			}

			const index = clonedNewSelected.findIndex(x => x === selectedController);
			if (index === -1) {
				clonedNewSelected.push(Number(selectedController));
			}
		}

		setNewAvailable(clonedNewAvailable);
		setNewSelected(clonedNewSelected);
		setSelectedLeftRowKeys([]);
		setLoadZones(true);

		const zoneInfoHelper: LoadControllerZonesHelper = {
			ZoneGroupId: currentZoneRef.current.Id,
			ZoneGroupType: currentZoneRef.current.Type,
			IsLeft: true,
			SelectedControllersId: clonedNewSelected,
			UnselectedControllersId: clonedNewAvailable,
		};

		handleActiveSearch(zoneInfoHelper);
		updateBothTables(zoneInfoHelper).finally(() => setLoadZones(false));
	};

	const handleActiveSearch = (zoneInfoHelper: LoadControllerZonesHelper) => {
		const rightPaginationSettings: PaginationSettingTyped<ZoneControllersColumns> = {
			PageNumber: 1,
			PageSize: currentPageSize.right,
			SortDirection: rightSorterConfig.current.direction,
			SortField: rightSorterConfig.current.field,
			SearchSettings: rightItemSearchedPagination,
		};

		const leftPaginationSettings: PaginationSettingTyped<ZoneControllersColumns> = {
			PageNumber: 1,
			PageSize: currentPageSize.left,
			SortDirection: leftSorterConfig.current.direction,
			SortField: leftSorterConfig.current.field,
			SearchSettings: leftItemSearchedPagination,
		};

		const rightIndex: number = rightItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		const leftIndex: number = leftItemSearchedPagination.findIndex(x => x.SearchValue !== '');
		if (rightIndex !== -1 && leftIndex !== -1) {
			Promise.all([
				whosInsideApi.getZoneControllersAllItemsPagination({ ...zoneInfoHelper, IsLeft: false }, rightPaginationSettings),
				whosInsideApi.getZoneControllersAllItemsPagination(zoneInfoHelper, leftPaginationSettings),
			]).then(res => setAllItemsPagination({ left: res[1], right: res[0] }));
		} else if (rightIndex !== -1) {
			whosInsideApi.getZoneControllersAllItemsPagination({ ...zoneInfoHelper, IsLeft: false }, rightPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, right: res }));
			});
		} else if (leftIndex !== -1) {
			whosInsideApi.getZoneControllersAllItemsPagination(zoneInfoHelper, leftPaginationSettings).then(res => {
				setAllItemsPagination(prevState => ({ ...prevState, left: res }));
			});
		}
	};

	const handleOnRemoveController = () => {
		const clonedNewAvailable = [...newAvailable];
		const clonedNewSelected = [...newSelected];
		for (const selectedController of selectedRightRowKeys) {
			const controllerIndex = clonedNewSelected.findIndex(x => x === selectedController);
			if (~controllerIndex) {
				clonedNewSelected.splice(controllerIndex, 1);
			}

			const index = clonedNewAvailable.findIndex(x => x === selectedController);
			if (index === -1) {
				clonedNewAvailable.push(Number(selectedController));
			}
		}

		setNewAvailable(clonedNewAvailable);
		setNewSelected(clonedNewSelected);
		setSelectedRightRowKeys([]);
		setLoadZones(true);

		const zoneInfoHelper: LoadControllerZonesHelper = {
			ZoneGroupId: currentZoneRef.current.Id,
			ZoneGroupType: currentZoneRef.current.Type,
			IsLeft: true,
			SelectedControllersId: clonedNewSelected,
			UnselectedControllersId: clonedNewAvailable,
		};

		handleActiveSearch(zoneInfoHelper);
		updateBothTables(zoneInfoHelper).finally(() => setLoadZones(false));
	};

	const getCurrentPageNumber = (
		selectedZones: number[],
		unselectedZones: number[],
		allItemsPagination: number[],
		currentPageSize: number,
		currentPage: number,
		pageNumber: number,
		isLeft: boolean
	): number => {
		const currentNewSelected: Set<number> = new Set(selectedZones);
		let currentAllItemsPagination: number[] = allItemsPagination.filter(x => !currentNewSelected.has(x));
		currentAllItemsPagination = getUniqueValuesArray(currentAllItemsPagination, unselectedZones);

		let maxPageNumber: number = Math.ceil(currentAllItemsPagination.length / currentPageSize);
		if (currentPage > maxPageNumber && maxPageNumber != 0) {
			if (isLeft) {
				setCurrentPage(prevState => ({ ...prevState, left: maxPageNumber }));
			} else {
				setCurrentPage(prevState => ({ ...prevState, right: maxPageNumber }));
			}
			return maxPageNumber;
		} else {
			return pageNumber;
		}
	};

	const setZoneControllersBy = async (
		paginationSetting: PaginationSettingTyped<ZoneControllersColumns>,
		zoneInfoHelper: LoadControllerZonesHelper,
		isLeft: boolean
	) => {
		if (isLeft) {
			paginationSetting.PageNumber = getCurrentPageNumber(
				zoneInfoHelper.SelectedControllersId,
				zoneInfoHelper.UnselectedControllersId,
				allItemsPagination.left,
				currentPageSize.left,
				currentPage.left,
				paginationSetting.PageNumber,
				isLeft
			);
		} else {
			paginationSetting.PageNumber = getCurrentPageNumber(
				zoneInfoHelper.UnselectedControllersId,
				zoneInfoHelper.SelectedControllersId,
				allItemsPagination.right,
				currentPageSize.right,
				currentPage.right,
				paginationSetting.PageNumber,
				isLeft
			);
		}

		const previousState = isLeft ? leftControllers?.filter(c => c.checked) : rightControllers?.filter(c => c.checked);
		const res = await whosInsideApi.loadZoneGroupControllersByPage(zoneInfoHelper, paginationSetting);
		const payload = [];
		const { TotalItemsPaginated, ZoneControllers } = res;
		ZoneControllers.forEach(item => {
			payload.push({ ...item, checked: previousState.some(x => x.ControllerId === item.ControllerId) });
		});

		if (isLeft) {
			setLeftControllers(payload);
			setTotalItemsPagination(prevState => ({ ...prevState, left: TotalItemsPaginated }));
			setLoadingTables(prevState => ({ ...prevState, left: false }));
		} else {
			setRightControllers(payload);
			setTotalItemsPagination(prevState => ({ ...prevState, right: TotalItemsPaginated }));
			setLoadingTables(prevState => ({ ...prevState, right: false }));
		}
	};

	const onValuesChange = (values): void => {
		Object.keys(values).forEach(field => {
			const error: string[] = form.getFieldError(field);
			if (!error.length) {
				return;
			}

			form.setFields([
				{
					name: field,
					errors: [],
				},
			]);
		});
	};

	const handleSaveZone = async (): Promise<void> => {
		setSaveZoneGroup(true);

		const controllers: number[] = newSelected;
		const availableControllers: number[] = newAvailable;
		const name: string = form.getFieldValue('zoneName');
		const action: Promise<ResponseObject> = zoneId
			? whosInsideApi.editCustomZoneGroup({
					...currentZoneRef.current,
					Name: name,
					SelectedControllersId: controllers,
					AvailableControllersId: availableControllers,
			  })
			: whosInsideApi.addCustomZoneGroup(name, controllers);

		action.then(res =>
			NotificationStatus({
				responseData: res,
				notUseDefaultNotification: true,
				onSuccessCallback: () => {
					onOkModal(name);
				},
				onFailedValidation: () => {
					setSaveZoneGroup(false);
					const errorInfo: ErrorInfo = ErrorInfo[res.AdditionalResponseInfo];
					if (errorInfo === ErrorInfo.AddCustomZoneName) {
						form.setFields([
							{
								name: 'zoneName',
								errors: [res.ErrorMessage],
							},
						]);
					} else if (errorInfo === ErrorInfo.EditCustomZoneList) {
						setErrorList(res.ErrorMessage);
					}
				},
				onPermissionErrorCallback: () => {
					setSaveZoneGroup(false);
					handleResponse(res);
				},
				onSystemErrorCallback: () => {
					setSaveZoneGroup(false);
					notification['error']({
						message: _(`ResponseStatusCode_${res.ResponseStatusCode}`),
					});
				},
			})
		);
	};

	const handleOnRowClick = (key: React.Key, isLeft: boolean) => {
		if (isLeft) {
			const index = selectedLeftRowKeys.indexOf(key);
			if (index === -1) {
				handleLeftChange([...selectedLeftRowKeys, key]);
			} else {
				selectedLeftRowKeys.splice(index, 1);
				handleLeftChange([...selectedLeftRowKeys]);
			}
		} else {
			const index = selectedRightRowKeys.indexOf(key);
			if (index === -1) {
				handleRightChange([...selectedRightRowKeys, key]);
			} else {
				selectedRightRowKeys.splice(index, 1);
				handleRightChange([...selectedRightRowKeys]);
			}
		}
	};

	const modalTitle: string =
		zoneId === 0
			? _('AddCustomZoneGroup')
			: currentZoneRef.current?.Type === ZoneGroupType.Globalized || currentZoneRef.current?.Type === ZoneGroupType.GIOG
			? _('EditGlobalizedZoneGroup')
			: _('EditCustomZoneGroup');

	return (
		<Modal
			keyboard={false}
			maskClosable={false}
			onCancel={onHideModal}
			onClickOk={() => null}
			width={900}
			title={modalTitle}
			visible={true}
			footer={[
				<Button
					id='whosInsideSaveZoneGroup'
					key='ok'
					type='primary'
					onClick={handleSaveZone}
					disabled={!disableSaveChanges || saveZoneGroup || loadZones}>
					{_('SaveChanges')}
				</Button>,
				<Button id='whosInsideCancelZoneGroup' key='cancel' onClick={onHideModal}>
					{_('Cancel')}
				</Button>,
			]}>
			<Form form={form} component={false} onValuesChange={onValuesChange}>
				<div className={styles.modalContainer}>
					<div className={styles.nameContainer}>
						<label htmlFor='whosInsideZoneGroupNameInput'>{_('Name')}</label>
						<Form.Item name={'zoneName'} className={styles.nameInputContainer}>
							<Input id='whosInsideZoneGroupNameInput' className={styles.nameInput} disabled={!disableNameInput} maxLength={zoneGroupMaxLength} />
						</Form.Item>
					</div>
					<div className={styles.transferContainer}>
						<div>
							<strong>{_('DoesNotContain')}</strong>
							<Spin tip={`${_('Loading')}...`} spinning={loadZones || loadingTables.left} size='large'>
								<Table
									id={`whosInsideTableTransferLeft`}
									className={styles.transferTable}
									rowSelection={leftRowSelection}
									columns={leftTableColumns}
									dataSource={tableData.left}
									size='small'
									pagination={leftPagination}
									scroll={scroll}
									onChange={(pagination, filters, sorter) => handleOnChangeTable(pagination, filters, sorter, true)}
									onHeaderRow={() => ({ id: 'whosInsideTableTransferLeftHeader' })}
									getPopupContainer={() => document.getElementById('whosInsideTableTransferLeftHeader')}
									onRow={({ key, Disabled: itemDisabled }) => ({
										onClick: () => {
											if (itemDisabled || !disableTransfer) return;
											handleOnRowClick(key, true);
										},
									})}
								/>
							</Spin>
						</div>
						<div className={styles.transferActions}>
							<Button
								id='whosInsideAddController'
								type='primary'
								onClick={handleOnAddController}
								disabled={disableAddController}
								icon={<DownOutlined />}
							/>
							<Button
								id='whosInsideRemoveController'
								type='primary'
								onClick={handleOnRemoveController}
								disabled={disableRemoveController}
								icon={<UpOutlined />}
							/>
						</div>
						<div style={errorList ? transferErrorStyle : undefined}>
							<strong>{_('Contains')}</strong>
							<Spin tip={`${_('Loading')}...`} spinning={loadZones || loadingTables.right} size='large'>
								<Table
									id={`whosInsideTableTransferRight`}
									className={styles.transferTable}
									rowSelection={rightRowSelection}
									columns={rightTableColumns}
									dataSource={tableData.right}
									size='small'
									pagination={rightPagination}
									scroll={scroll}
									onChange={(pagination, filters, sorter) => handleOnChangeTable(pagination, filters, sorter, false)}
									onHeaderRow={() => ({ id: 'whosInsideTableTransferRightHeader' })}
									getPopupContainer={() => document.getElementById('whosInsideTableTransferRightHeader')}
									onRow={({ key, Disabled: itemDisabled }) => ({
										onClick: () => {
											if (itemDisabled || !disableTransfer) return;
											handleOnRowClick(key, false);
										},
									})}
								/>
							</Spin>
						</div>
					</div>
					<span id='whosInsideZoneGroupErrorList' className={styles.errorMessage}>
						{errorList}
					</span>
				</div>
			</Form>
		</Modal>
	);
};

export { WhosInsideAddCustomZone };


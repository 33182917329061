import { Spin } from 'antd';
import React, { FC } from 'react';
import { Modal } from '../../../common';
import styles from './deletingLoadingModal.module.scss';

type Props = {
	total: number;
	current: number;
};

const DeletingLoadingModal: FC<Props> = ({ current, total }) => {
	return (
		<Modal closable={false} footer={null} title={null} visible className={styles.deletingLoadingModal}>
			<Spin spinning tip={`Deleting ${current} of ${total} operator(s)...`}>
				<span />
			</Spin>
		</Modal>
	);
};

export { DeletingLoadingModal };

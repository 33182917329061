import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../../../model/CommonModel';
import { NetworkInfo, PortNetWorkType, SnibSearchType } from '../../../../../../../../../model/DeviceAdminModel';
import { Select } from '../../../../../../../../common';
import { PortStoreContext, setCurrentNetwork, setGlobalBroadcastAddress } from '../../../../../contextPort';
import styles from './searchNetwork.module.scss';

const searchNetworkSelectId = 'searchNetworkSelectId';
const searchNetworkContainerId = 'searchNetworkContainerId';

const SearchNetwork: React.FC = () => {
	const [selectOptions, setSelectOptions] = useState<SelectOptions<string>[]>([]);
	const {
		portState: { activeNetworks, currentNetwork, loading, globalBroadcastAddress, networkType, snibSearchType },
		dispatcher,
	} = useContext(PortStoreContext);

	useEffect(() => {
		createSelectOptions();
		loadFirstCurrentNetwork();
	}, [activeNetworks]);

	const handleChangeSearchNetwork = (value: string) => {
		let selectedNetwork: NetworkInfo = undefined;
		if (activeNetworks?.length) {
			selectedNetwork = activeNetworks.find(x => x.Id === value);
		}
		dispatcher(setCurrentNetwork(selectedNetwork));
	};

	const handleChangeGlobalBroadcast = (e: CheckboxChangeEvent) => {
		const value = e.target.checked;
		dispatcher(setGlobalBroadcastAddress(value));
	};

	const loadFirstCurrentNetwork = () => {
		const isCurrentNetworkValueEmpty = currentNetwork === undefined;
		if (isCurrentNetworkValueEmpty) {
			let firstNetwork: NetworkInfo = undefined;
			if (activeNetworks?.length) {
				firstNetwork = activeNetworks[0];
			}
			dispatcher(setCurrentNetwork(firstNetwork));
		}
	};

	const createSelectOptions = () => {
		const options: SelectOptions<string>[] = activeNetworks.map((activeNetwork, index) => ({
			label: activeNetwork.Description,
			value: activeNetwork.Id,
			id: `searchNetworkSelectDropdownOption-${index}`,
		}));

		setSelectOptions(options);
	};

	const globalBroadcastCheckboxId = 'globalBroadcastCheckbox';
	const isVisible = networkType === PortNetWorkType.IPv4 && snibSearchType === SnibSearchType.Broadcast;
	const searchNetworksLoading = loading || networkType === PortNetWorkType.IPv6;

	return (
		<div id={searchNetworkContainerId} className={styles.fieldsetContainer}>
			<fieldset>
				<legend className={styles.legend}>{_('SearchNetworks')}</legend>
				<div className={styles.container}>
					<div className={styles.selectContainer}>
						<Select
							disabled={searchNetworksLoading}
							id={searchNetworkSelectId}
							options={selectOptions}
							value={currentNetwork !== undefined ? currentNetwork.Id : ''}
							onChange={handleChangeSearchNetwork}
							className={styles.select}
							getPopupContainer={() => document.getElementById(searchNetworkContainerId)}
						/>
					</div>
					<div className={styles.checkboxContainer}>
						{isVisible && (
							<Checkbox id={globalBroadcastCheckboxId} onChange={handleChangeGlobalBroadcast} checked={globalBroadcastAddress}>
								<label htmlFor={globalBroadcastCheckboxId}>{_('IncludeGlobalBroadcast')}</label>
							</Checkbox>
						)}
					</div>
				</div>
			</fieldset>
		</div>
	);
};

export { SearchNetwork };

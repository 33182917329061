// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".photo-call-up-settings-container--1Pq5lOAQ168uCnFrsb_Wjy{padding-bottom:20px;display:grid;grid-template-columns:200px auto;grid-row-gap:18px;padding-left:16px;padding-top:15px}.photo-call-up-settings-container--1Pq5lOAQ168uCnFrsb_Wjy [class*=ant-input-number-input]{text-align:center}.photo-call-up-settings-container--1Pq5lOAQ168uCnFrsb_Wjy>div:nth-of-type(2)>label{margin-left:10px}", "",{"version":3,"sources":["webpack://src/components/configuration/PhotoCallUpSettings/photoCallUpSettings.module.scss"],"names":[],"mappings":"AAAA,0DACC,mBAAA,CACA,YAAA,CACA,gCAAA,CACA,iBAAA,CACA,iBAAA,CACA,gBAAA,CAEA,0FACC,iBAAA,CAGA,mFACC,gBAAA","sourcesContent":[".container {\r\n\tpadding-bottom: 20px;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: 200px auto;\r\n\tgrid-row-gap: 18px;\r\n\tpadding-left: 16px;\r\n\tpadding-top: 15px;\r\n\r\n\t[class*='ant-input-number-input'] {\r\n\t\ttext-align: center;\r\n\t}\r\n\t& > div:nth-of-type(2) {\r\n\t\t& > label {\r\n\t\t\tmargin-left: 10px;\r\n\t\t}\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "photo-call-up-settings-container--1Pq5lOAQ168uCnFrsb_Wjy"
};
export default ___CSS_LOADER_EXPORT___;

import { InputNumber } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../../../Helper';
import { PortStoreContext, setValueSNIBSelectedAction } from '../../../../../contextPort';
import styles from '../../snibConfiguration.module.scss';

const IPPort: React.FC = () => {
	const {
		portState: { snibSelected },
		dispatcher,
	} = useContext(PortStoreContext);

	const handleChange = (value: string) => {
		if (!(Number(value) >= 0)) {
			return;
		}
		dispatcher(setValueSNIBSelectedAction({ type: 'Port', value: value }));
	};

	return (
		<div className={styles.row}>
			<label id='searchSNIBConfigurationIPPortLabel'>{_('IPPort')}</label>
			<InputNumber
				id='searchSNIBConfigurationIPPortInput'
				key='ipPort'
				className={styles.inputNumber}
				value={snibSelected?.Port}
				defaultValue={10001}
				min={0}
				max={65535}
				step={1}
				maxLength={5}
				onChange={handleChange}
				formatter={noneDecimalFormatter}
			/>
		</div>
	);
};

export { IPPort };

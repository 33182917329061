import { Col, InputNumber, Row } from 'antd';
import React, { useContext } from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { setDoorExtendedAccessOverrideAction, setDoorExtendedAccessWarningAction } from '../../../DoorModalContext';
import { DoorStoreSingleContext } from '../../../DoorStoreSingleContext';
import styles from '../../general.module.scss';

type Props = {};

const ExtendedAccessTimers: React.FC<Props> = () => {
	const {
		contextStateDoor: {
			door: {
				door: { ExtendedAccessOverrideTime, ExtendedAccessWarningTime },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleChangeExtendedAccessOverride = (e: React.ReactText) => {
		const value = Number(e.toString());
		dispatcherDoor(setDoorExtendedAccessOverrideAction(value));
	};

	const handleChangeExtendedAccessWarning = (e: React.ReactText) => {
		const value = Number(e.toString());
		dispatcherDoor(setDoorExtendedAccessWarningAction(value));
	};

	return (
		<div className={styles.containerLegend}>
			<fieldset>
				<legend className={styles.legend}>{_('ExtendedAccessTimers')}</legend>
				<div className={styles.leftPadding}>
					<Row gutter={[8, 8]}>
						<Col>
							<span className={styles.contentSpan}>{_('ExtendedAccessOverrideTime')}</span>
							<InputNumber
								id='doorModalExtendedAccessOverrideTimeInput'
								min={0}
								max={1440}
								value={ExtendedAccessOverrideTime}
								onChange={handleChangeExtendedAccessOverride}
								formatter={noneDecimalFormatter}
								step={1}
							/>
						</Col>
					</Row>
					<Row gutter={[8, 8]}>
						<Col>
							<span className={styles.contentSpan}>{_('ExtendedAccessWarningTime')}</span>
							<InputNumber
								id='doorModalExtendedAccessWarningTimeInput'
								min={0}
								max={15}
								value={ExtendedAccessWarningTime}
								onChange={handleChangeExtendedAccessWarning}
								formatter={noneDecimalFormatter}
								step={1}
							/>
						</Col>
					</Row>
				</div>
			</fieldset>
		</div>
	);
};

export { ExtendedAccessTimers };

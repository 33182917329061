import { combineReducers } from 'redux';
import { ConfigurationState } from '../model/ConfigurationModel';
import { alarmReducer, AlarmState } from './alarm/reducer';
import { commonReducer, CommonState } from './common/reducer';
import { configurationReducer } from './configuration/reducer';
import { controllerReducer, ControllerState } from './deviceControl/controller/reducer';
import { controlZonesReducer, ControlZonesState } from './deviceControl/controlZones/reducer';
import { deviceControlReducer, DeviceControlState } from './deviceControl/reducer';
import { enrollmentReducer, EnrollmentState } from './enrollment/reducers';
import { eventReducer, EventState } from './event/reducer';
import { photoCallUpReducer, PhotoCallUpState } from './photocallup/reducers';
import { statusViewerReducer, StatusViewerState } from './statusViewer/reducers';

export interface RootReducer {
	photoCallUpReducer: PhotoCallUpState;
	enrollmentReducer: EnrollmentState;
	configurationReducer: ConfigurationState;
	deviceControlReducer: DeviceControlState;
	commonReducer: CommonState;
	controllerReducer: ControllerState;
	controlZonesReducer: ControlZonesState;
	statusViewerReducer: StatusViewerState;
	eventReducer: EventState;
	alarmReducer: AlarmState;
}

const rootReducer = combineReducers<RootReducer>({
	photoCallUpReducer,
	enrollmentReducer,
	configurationReducer,
	deviceControlReducer,
	commonReducer,
	controllerReducer,
	controlZonesReducer,
	statusViewerReducer,
	eventReducer,
	alarmReducer,
});

export { rootReducer };

import React from 'react';
import { getDefaultPaginationSettings } from '../../Helper';
import { PaginationSetting, SorterConfigEx } from '../../model/CommonModel';
import {
	AdvanceSearchCriteria,
	CredentialTemplate,
	EnrollmentGridItem,
	EnrollmentResourceStrings,
	PersonDetailModel,
	PersonGroups,
	PersonTemplate,
	TreeViewPersonGroupModel,
} from '../../model/EnrollmentModel';
import { EnrollmentAction } from './actions';
import { EnrollmentActionTypes } from './actionTypes';

const initialPersonInformationData: PersonDetailModel = {
	PersonId: 0,
	FirstName: '',
	LastName: '',
	MiddleName: '',
	Suffix: '',
	Title: '',
	UserDefinedFields: [],
	LastUpdated: new Date(Date.now()),
};

//States' definition
const INIT_STATE: EnrollmentState = {
	fetchingScanData: false,
	displayAddViaSmartCard: false,
	personGroups: [],
	personTemplates: [],
	isFICAMEnabled: false,
	credentialTemplates: [],
	defaultCredentialTemplateId: 1,
	selectedPersonGroup: undefined,
	blankProfilePictureBase64: '',
	blankProfileSignatureBase64: '',
	personTableInformation: undefined,
	isPersonTableLoading: false,
	selectedRowKeys: [],
	updateCounter: 0,
	personTablePaginationSettings: getDefaultPaginationSettings(),
	advanceSearchCriteria: undefined,
	activeAdvancedSearch: false,
	personInformationId: 0,
	personInformationData: initialPersonInformationData,
	hasPersonInformationChanged: false,
	isEnrollmentTabLoading: false,
	personTableSorterConfig: { columnKey: undefined, order: undefined },
	resourceStrings: { ViewTabError: '', DeleteAdditionalImage: '', Dr: '', Jr: '', Mr: '', Mrs: '', Ms: '', Sr: '' },
	havePersonChanged: false,
};

export type EnrollmentState = {
	fetchingScanData: boolean;
	displayAddViaSmartCard: boolean;
	personGroups: PersonGroups[];
	personTemplates: PersonTemplate[];
	isFICAMEnabled: boolean;
	credentialTemplates: CredentialTemplate[];
	defaultCredentialTemplateId: number;
	selectedPersonGroup: TreeViewPersonGroupModel;
	blankProfilePictureBase64: string;
	blankProfileSignatureBase64: string;
	personTableInformation: EnrollmentGridItem;
	isPersonTableLoading: boolean;
	selectedRowKeys: React.Key[];
	personTablePaginationSettings: PaginationSetting;
	updateCounter: number;
	advanceSearchCriteria: AdvanceSearchCriteria;
	activeAdvancedSearch: boolean;
	personInformationId: number;
	personInformationData: PersonDetailModel;
	hasPersonInformationChanged: boolean;
	isEnrollmentTabLoading: boolean;
	personTableSorterConfig: SorterConfigEx;
	resourceStrings: EnrollmentResourceStrings;
	havePersonChanged: boolean;
};

//Reducers
const enrollmentReducer = (state: Readonly<EnrollmentState> = INIT_STATE, action: EnrollmentAction): EnrollmentState => {
	switch (action.type) {
		case EnrollmentActionTypes.FETCHING_SCAN_DATA:
			return {
				...state,
				fetchingScanData: action.payload,
			};

		case EnrollmentActionTypes.DISPLAY_ADD_VIA_SMART_CARD:
			return {
				...state,
				displayAddViaSmartCard: action.payload,
			};

		case EnrollmentActionTypes.SET_PERSON_TABLE_PAGINATION_SETTINGS:
			return {
				...state,
				personTablePaginationSettings: action.payload,
			};

		case EnrollmentActionTypes.SET_UPDATE_COUNTER:
			return {
				...state,
				updateCounter: state.updateCounter + 1,
			};

		case EnrollmentActionTypes.SET_IS_PERSON_TABLE_LOADING:
			return {
				...state,
				isPersonTableLoading: action.payload,
			};

		case EnrollmentActionTypes.SET_SELECTED_ROW_KEYS:
			return {
				...state,
				selectedRowKeys: action.payload,
			};

		case EnrollmentActionTypes.SET_HAVE_PERSON_CHANGED:
			return {
				...state,
				havePersonChanged: action.payload,
			};

		case EnrollmentActionTypes.SET_PERSON_INFORMATION_ID:
			const personInformationData: PersonDetailModel =
				action.payload === 0 || action.payload === -200 ? initialPersonInformationData : state.personInformationData;

			return {
				...state,
				updateCounter: 0,
				personInformationId: action.payload,
				personInformationData: personInformationData,
			};

		case EnrollmentActionTypes.SET_IS_ENROLLMENT_TAB_LOADING:
			return {
				...state,
				isEnrollmentTabLoading: action.payload,
			};

		case EnrollmentActionTypes.SET_PERSON_TABLE_SORTER_CONFIG:
			return {
				...state,
				personTableSorterConfig: action.payload,
			};

		case EnrollmentActionTypes.SET_PERSON_INFORMATION_DATA:
			return {
				...state,
				personInformationData: action.payload,
			};

		case EnrollmentActionTypes.SET_ENROLLMENT_TAB_INFO:
			const {
				PersonGroups,
				PersonTemplates,
				IsFICAMEnabled,
				CredentialTemplates,
				DefaultCredentialTemplateId,
				BlankProfilePictureBase64,
				BlankProfileSignatureBase64,
				ResourceStrings,
			} = action.payload;
			return {
				...state,
				personGroups: PersonGroups,
				personTemplates: PersonTemplates,
				isFICAMEnabled: IsFICAMEnabled,
				credentialTemplates: CredentialTemplates,
				defaultCredentialTemplateId: DefaultCredentialTemplateId,
				blankProfilePictureBase64: BlankProfilePictureBase64,
				blankProfileSignatureBase64: BlankProfileSignatureBase64,
				resourceStrings: ResourceStrings,
			};

		case EnrollmentActionTypes.SET_SELECTED_PERSON_GROUP:
			return {
				...state,
				selectedRowKeys: [],
				selectedPersonGroup: action.payload,
				updateCounter: state.updateCounter + 1,
				personTablePaginationSettings: {
					...state.personTablePaginationSettings,
					SearchedValue: '',
					PageNumber: 1,
				},
			};

		case EnrollmentActionTypes.SET_PERSON_TABLE_INFORMATION_BY_PAGE:
			return {
				...state,
				personTableInformation: action.payload.personInformation,
				personTablePaginationSettings: action.payload.paginationSettings,
				updateCounter: action.payload.updateCounter,
			};

		case EnrollmentActionTypes.SET_KEYWORD_SEARCH_TEXT:
			return {
				...state,
				selectedRowKeys: [],
				updateCounter: state.updateCounter + 1,
				personTablePaginationSettings: {
					...state.personTablePaginationSettings,
					SearchedValue: action.payload,
					PageNumber: 1,
				},
			};

		case EnrollmentActionTypes.CLEAR_ADVANCED_SEARCH:
			return {
				...state,
				advanceSearchCriteria: undefined,
				selectedRowKeys: [],
				activeAdvancedSearch: false,
				updateCounter: state.updateCounter + 1,
				personTablePaginationSettings: {
					...state.personTablePaginationSettings,
					PageNumber: 1,
					SearchedValue: '',
				},
			};

		case EnrollmentActionTypes.SET_ACTIVE_ADVANCED_SEARCH:
			return {
				...state,
				activeAdvancedSearch: true,
				selectedRowKeys: [],
				isPersonTableLoading: true,
				advanceSearchCriteria: action.payload,
				updateCounter: state.updateCounter + 1,
				personTablePaginationSettings: {
					...state.personTablePaginationSettings,
					PageNumber: 1,
					SearchedValue: '',
				},
			};

		case EnrollmentActionTypes.SET_ENROLLMENT_BLANK_IMAGES:
			return {
				...state,
				blankProfilePictureBase64: action.payload.BlankProfilePictureBase64,
				blankProfileSignatureBase64: action.payload.BlankProfileSignatureBase64,
			};

		default:
			return state;
	}
};

export { enrollmentReducer };

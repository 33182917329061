import { InfoCircleOutlined, LogoutOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Layout, Menu, MenuProps } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { getFullVelocityUrl, getUserName } from '../../../Helper';
import { User } from '../../../model/AccountModel';
import { UserProfileDropdownAction } from '../../../model/CommonModel';
import { HELPFILENAME } from '../../account/UserProfileDropdown/UserProfileDropdown';
import { AboutModal } from '../AboutModal/AboutModal';
import {
	AlarmsIcon,
	DeviceControlIcon,
	EnrollmentIcon,
	EventsIcon,
	MenuCloseIcon,
	MenuOpenIcon,
	PhotoCallUpIcon,
	ReportingIcon,
	StatusDashboardIcon,
	StatusViewerIcon,
	UserIcon,
	WhosInsideIcon,
} from './MenuIcons';
import styles from './header.module.scss';

const { Sider } = Layout;

type Props = {
	collapsed: boolean;
	handleOnCollapseMenu: () => void;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();
const {
	canViewEnrollmentPage,
	canViewAlarmPage,
	canViewReportsPage,
	canViewEventsPage,
	canViewWhosInside,
	canViewDevicePage,
	canViewDashboard,
	canViewPhotoCallUp,
	canViewStatusViewer,
} = user;

const companyName: string = 'Identiv, Inc.';
const fullVelocityUrl: string = getFullVelocityUrl();
const enrollmentUrl: string = `${fullVelocityUrl}Enrollment/EnrollmentManager`;
const alarmsUrl: string = `${fullVelocityUrl}Events/EventViewer`;
const eventsUrl: string = `${fullVelocityUrl}Events/FullEventViewer`;
const deviceAdminManagerUrl: string = `${fullVelocityUrl}DeviceAdmin/DeviceAdminManager`;
const statusViewerUrl: string = `${fullVelocityUrl}StatusViewer/StatusViewer`;
const whosInsideUrl: string = `${fullVelocityUrl}WhosInside/WhosInsideManager`;
const photoCallUpUrl: string = `${fullVelocityUrl}PhotoCallUp/PhotoCallUpDashboard`;
const reportsUrl: string = `${fullVelocityUrl}Reports/ReportManager`;
const statusDashboardUrl: string = `${fullVelocityUrl}Status/StatusDashboard`;

const Header: React.FC<Props> = ({ collapsed, handleOnCollapseMenu }) => {
	const [buttonOnHover, setButtonOnHover] = useState<boolean>(false);
	const [displayAboutModal, setDisplayAboutModal] = useState<boolean>(false);

	let activeKey: string = '-1';
	const input = document.getElementById('SelectedTab') as HTMLInputElement;
	const MenuCollapseStyle: CSSProperties = { width: '20px', fill: '#fff' };

	if (input && input.value) {
		activeKey = input.value;
	}

	const logoStyles: CSSProperties = collapsed ? { display: 'none' } : {};
	const buttonStyles: CSSProperties = collapsed ? { marginRight: 3 } : {};

	useEffect(() => {
		document.getElementById('buttonCollapseMenu').onmouseenter = function () {
			showTitleButtonCollapseMenu(true);
		};
		document.getElementById('buttonCollapseMenu').onmouseleave = function () {
			showTitleButtonCollapseMenu(false);
		};
	}, [buttonOnHover]);

	useEffect(() => {
		batch(() => {
			setButtonOnHover(false);
		});
	}, [collapsed]);

	const showTitleButtonCollapseMenu = (hover: boolean) => {
		batch(() => {
			setButtonOnHover(hover);
		});
	};

	const createUserMenuItems = (): MenuProps['items'] => {
		return [
			{
				key: UserProfileDropdownAction.about,
				label: _('About'),
				icon: <InfoCircleOutlined />,
				title: _('About'),
			},
			{
				key: UserProfileDropdownAction.help,
				label: _('Help'),
				icon: <QuestionCircleOutlined />,
				title: _('Help'),
			},
			{
				key: UserProfileDropdownAction.settings,
				label: _('Settings'),
				icon: <SettingOutlined data-leavetab={true} />,
				title: _('Settings'),
			},
			{
				type: 'divider',
			},
			{
				key: UserProfileDropdownAction.logout,
				label: _('Logout'),
				icon: <LogoutOutlined data-leavetab={true} />,
				title: _('Logout'),
			},
		];
	};

	const handleActionScope = async (action: UserProfileDropdownAction) => {
		switch (action) {
			case UserProfileDropdownAction.about:
				setDisplayAboutModal(true);
				break;
			case UserProfileDropdownAction.help:
				window.open(`${fullVelocityUrl}Content/Files/${HELPFILENAME}`);
				break;
			case UserProfileDropdownAction.settings:
				window.location.href = `${fullVelocityUrl}Configuration/ConfigurationManager`;
				break;
			case UserProfileDropdownAction.logout:
				window.location.href = `${fullVelocityUrl}/Account/LogOff`;
				break;
		}
	};

	const createMenuItems = (): MenuProps['items'] => {
		const iconsStyle: CSSProperties = { color: 'white', width: 16 };
		const menuItems: MenuProps['items'] = [
			{
				children: createUserMenuItems(),
				className: styles.userMenu,
				icon: <UserIcon />,
				key: '0',
				onClick: item => {
					const { key } = item;
					handleActionScope(UserProfileDropdownAction[key]);
				},
				label: (
					<span title={getUserName()} className={styles.headerPersonalInfoTitle}>
						{getUserName()}
					</span>
				),
			},
		];
		if (canViewEnrollmentPage) {
			menuItems.push({
				key: '1',
				icon: <EnrollmentIcon label={_('Enrollment')} style={iconsStyle} />,
				label: (
					<span id='enrollmentManagerPageTab'>
						<a href={enrollmentUrl} data-leavetab={true}>
							&nbsp;{_('Enrollment')}
						</a>
					</span>
				),
			});
		}
		if (canViewAlarmPage) {
			menuItems.push({
				key: '2',
				icon: <AlarmsIcon label={_('AlarmViewer')} style={iconsStyle} />,
				label: (
					<span id='alarmsPageTab'>
						<a href={alarmsUrl} data-leavetab={true}>
							&nbsp;{_('AlarmViewer')}
						</a>
					</span>
				),
			});
		}
		if (canViewEventsPage) {
			menuItems.push({
				key: '3',
				icon: <EventsIcon label={_('Events')} style={iconsStyle} />,
				label: (
					<span id='eventsPageTab'>
						<a href={eventsUrl} data-leavetab={true}>
							&nbsp;{_('EventViewer')}
						</a>
					</span>
				),
			});
		}
		if (canViewDevicePage) {
			menuItems.push({
				key: '4',
				icon: <DeviceControlIcon label={_('DeviceControl')} style={iconsStyle} />,
				label: (
					<span id='deviceControlPageTab'>
						<a href={deviceAdminManagerUrl} data-leavetab={true}>
							&nbsp;{_('DeviceControl')}
						</a>
					</span>
				),
			});
		}
		if (canViewStatusViewer) {
			menuItems.push({
				key: '5',
				icon: <StatusViewerIcon label={_('StatusViewer')} style={iconsStyle} />,
				label: (
					<span id='statusViewerPageTab'>
						<a href={statusViewerUrl} data-leavetab={true}>
							&nbsp;{_('StatusViewer')}
						</a>
					</span>
				),
			});
		}
		if (canViewWhosInside) {
			menuItems.push({
				key: '6',
				icon: <WhosInsideIcon label={_('WhosInside')} style={iconsStyle} />,
				label: (
					<span id='whosInsidePageTab'>
						<a href={whosInsideUrl} data-leavetab={true}>
							&nbsp;{_('WhosInside')}
						</a>
					</span>
				),
			});
		}
		if (canViewPhotoCallUp) {
			menuItems.push({
				key: '7',
				icon: <PhotoCallUpIcon label={_('PhotoCallUp')} style={iconsStyle} />,
				label: (
					<span id='photoCallUpPageTab'>
						<a href={photoCallUpUrl} data-leavetab={true}>
							&nbsp;{_('PhotoCallUp')}
						</a>
					</span>
				),
			});
		}
		if (canViewReportsPage) {
			menuItems.push({
				key: '8',
				icon: <ReportingIcon label={_('Reporting')} style={iconsStyle} />,
				label: (
					<span id='reportingPageTab'>
						<a href={reportsUrl} data-leavetab={true}>
							&nbsp;{_('Reporting')}
						</a>
					</span>
				),
			});
		}
		if (canViewDashboard) {
			menuItems.push({
				key: '9',
				icon: <StatusDashboardIcon label={_('StatusDashboard')} style={iconsStyle} />,
				label: (
					<span id='statusDashboardPageTab'>
						<a href={statusDashboardUrl} data-leavetab={true}>
							&nbsp;{_('StatusDashboard')}
						</a>
					</span>
				),
			});
		}
		return menuItems;
	};

	return (
		<>
			<Sider className={styles.headerMenuContainer} trigger={null} collapsible collapsed={collapsed} collapsedWidth={50}>
				<div className={styles.logoContainer}>
					<img alt={companyName} src={`${fullVelocityUrl}Content/Images/Identiv_Logo.png`} style={logoStyles} />
					<Button id='buttonCollapseMenu' className={styles.menuButton} onClick={handleOnCollapseMenu} style={buttonStyles}>
						{collapsed ? (
							<MenuOpenIcon label={`${_('Menu')} ${_('Fold')}`} style={MenuCollapseStyle} />
						) : (
							<MenuCloseIcon label={`${_('Menu')} ${_('Unfold')}`} style={MenuCollapseStyle} />
						)}
					</Button>
				</div>
				<Menu
					className={styles.headerMenu}
					defaultSelectedKeys={[activeKey]}
					getPopupContainer={(element: HTMLElement) => element.parentElement}
					items={createMenuItems()}
					mode='inline'
				/>
			</Sider>
			{buttonOnHover && (
				<div className={styles.hoverContainer} style={collapsed ? { marginLeft: '58px' } : { marginLeft: '208px' }}>
					<div className={styles.arrowLeft}></div>
					<div className={styles.buttonOnHover}>
						{collapsed ? <label>{_('ClickToExpandMenu')}</label> : <label>{_('ClickToCollapseMenu')}</label>}
					</div>
				</div>
			)}
			{displayAboutModal && <AboutModal hideModal={() => setDisplayAboutModal(false)} />}
		</>
	);
};

export { Header };

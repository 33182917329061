import { Severity } from '../model/LoggingModel';
import { Api } from './api';

class LoggerApi extends Api {
	public constructor() {
		super();
	}

	public writeMessage = async (message: string, severity: Severity): Promise<boolean> => {
		console.log({ severity, message });
		const url = this.getUrl('Logger/WriteMessage', true);
		const data = {
			message,
			severity,
		};

		return await this.post<boolean>(url, data);
	};
}

const loggerApi = new LoggerApi();

export { loggerApi };

import { Button, InputNumber } from 'antd';
import React from 'react';
import { noneDecimalFormatter } from '../../../../../../../Helper';
import { ControllerBasicSetup } from '../../../../../../../model/DeviceAdminModel';
import styles from '../communications.module.scss';

export type RandomValues = {
	value: number;
	key: keyof ControllerBasicSetup;
};

type Props = {
	sectionId: string;
	values: RandomValues[];
	onChange: (value: RandomValues) => void;
	onPressRandomizeValues: () => void;
	onPressDefaultValues: () => void;
};

const RandomizeValues: React.FC<Props> = ({ sectionId, values, onChange, onPressRandomizeValues, onPressDefaultValues }) => {
	return (
		<div className={styles.randomizeValues}>
			<div>
				{values.map(x => (
					<InputNumber
						id={`controllerCommunications${sectionId}Input${x.key}`}
						key={x.key}
						min={0}
						max={65535}
						maxLength={5}
						value={x.value}
						onChange={value => onChange({ value: Number(value), key: x.key })}
						formatter={noneDecimalFormatter}
					/>
				))}
			</div>
			<div>
				<Button id={`controllerCommunicationsRandomize${sectionId}Button`} onClick={() => onPressRandomizeValues()}>
					{_('Randomize')}
				</Button>
				<Button id={`controllerCommunicationsDefault${sectionId}Button`} onClick={() => onPressDefaultValues()}>
					{_('Default')}
				</Button>
			</div>
		</div>
	);
};

export { RandomizeValues };

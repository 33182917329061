import React from 'react';
import styles from '../General/general.module.scss';
import { AutoRelock } from './sections/AutoRelock/AutoRelock';
import { Direction } from './sections/Direction/Direction';
import { DoorName } from './sections/DoorName/DoorName';
import { ExtendedAccessTimers } from './sections/EntededAccessTimers/ExtendedAccessTimers';
import { Options } from './sections/Options/Options';
import { PhotoCallUp } from './sections/PhotoCallUp/PhotoCallUp';

type Props = {
	nameError: boolean;
	nameErrorText: string;
	onResetNameError: () => void;
	setErrorText: () => void;
};

const General: React.FC<Props> = ({ nameError, nameErrorText, onResetNameError, setErrorText }) => {
	return (
		<div id='generalDoorContainer' className={styles.container}>
			<DoorName nameError={nameError} nameErrorText={nameErrorText} onResetNameError={onResetNameError} setErrorText={setErrorText} />
			<Direction />
			<div className={styles.grid}>
				<AutoRelock />
				<Options />
			</div>
			<ExtendedAccessTimers />
			<PhotoCallUp />
		</div>
	);
};

export { General };

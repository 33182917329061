import React, { useContext, useEffect, useState } from 'react';
import { SelectOptions } from '../../../../../../../../model/CommonModel';
import { Select } from '../../../../../../../common';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setExitReaderCCOTZThreatLevel, setExitReaderThreatLevel } from '../../../exitReaderContext';
import styles from '../../General/general.module.scss';

const ThreatLevel: React.FC = () => {
	var {
		contextStateDoor: {
			exitReader: {
				readerThreatLevels,
				selections: { readerThreatLevel, cCOTZThreatLevel },
			},
		},
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const [readerLevels, setReaderLevels] = useState<SelectOptions<string>[]>([]);

	useEffect(() => {
		setReaderLevels(readerThreatLevels.map(x => ({ label: x.Name, value: x.Id.toString(), id: x.Id.toString() })));
	}, [readerThreatLevels]);

	const handleOnSetReaderThreatLevel = (value: string) => {
		dispatcherDoor(setExitReaderThreatLevel(Number(value)));
	};

	const handleOnSetCCOTZThreatLevel = (value: string) => {
		dispatcherDoor(setExitReaderCCOTZThreatLevel(Number(value)));
	};

	return (
		<>
			<div className={styles.sections}>
				<span>{_('DisableReaderAbove')}</span>
				<div>
					<Select
						className={styles.select}
						options={readerLevels}
						onChange={handleOnSetReaderThreatLevel}
						key='readerThreatLevel'
						value={readerThreatLevel.toString()}
						getPopupContainer={() => document.getElementById('generalExitReaderContainer')}
					/>
				</div>
			</div>
			<div className={styles.sections}>
				<span>{_('DisableCCOTZAbove')}</span>
				<div>
					<Select
						className={styles.select}
						options={readerLevels}
						onChange={handleOnSetCCOTZThreatLevel}
						key='ccotzThreatLevel'
						value={cCOTZThreatLevel.toString()}
						getPopupContainer={() => document.getElementById('generalExitReaderContainer')}
					/>
				</div>
			</div>
		</>
	);
};

export { ThreatLevel };

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".line-module-container--2ULG3sE__YEZv4kgs_iygB{align-items:center;display:grid;grid-template-columns:25% 75%}.line-module-container--2ULG3sE__YEZv4kgs_iygB .line-module-input--3GfT--7l4qOQhofqHettT-{height:30px !important}", "",{"version":3,"sources":["webpack://src/components/devicecontrol/DigiTrac/InputModal/tabs/General/sections/LineModule/lineModule.module.scss"],"names":[],"mappings":"AAAA,+CACC,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,0FACC,sBAAA","sourcesContent":[".container {\r\n\talign-items: center;\r\n\tdisplay: grid;\r\n\tgrid-template-columns: 25% 75%;\r\n\t.input {\r\n\t\theight: 30px !important;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "line-module-container--2ULG3sE__YEZv4kgs_iygB",
	"input": "line-module-input--3GfT--7l4qOQhofqHettT-"
};
export default ___CSS_LOADER_EXPORT___;

import { Spin, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { canAddNewTimeZones, handleResponse } from '../../../../../Helper';
import { deviceAdminApi } from '../../../../../api';
import { SubPermissions, User } from '../../../../../model/AccountModel';
import {
	DeviceObjectType,
	ExpansionRelayInfo,
	GenericControlTimeZones,
	RelayLogicTimeZones,
	SelectTimeZone,
	TimeZoneType,
} from '../../../../../model/DeviceAdminModel';
import { Modal } from '../../../../common';
import { TimeZones } from '../../../VelocityConfiguration/TimeZones/TimeZones';
import { setDoorNewTimeZoneAction } from '../../DoorModal/DoorModalContext';
import { DoorStoreSingleContext } from '../../DoorModal/DoorStoreSingleContext';
import { General } from '../Tabs/General/General';
import { Logic } from '../Tabs/Logic/Logic';
import {
	StoreContext,
	setActuateTimeZone,
	setActuateTimeZones,
	setAutoClearAfterTimeZone,
	setAutoClearAfterTimeZones,
	setControlDelay,
	setControlMode,
	setControllerId,
	setDisableTimeZone,
	setDisableTimeZones,
	setName,
	setNormalState,
	setOperateTimeZone,
	setOperateTimeZones,
	setReTriggerControlZone,
	setReTriggerControlZones,
	setTriggerControlZone,
	setTriggerControlZones,
	setUnpermittedActuateTimeZone,
	setUnpermittedAutoClearTimeZone,
	setUnpermittedDisableTimeZone,
	setUnpermittedOperateTimeZone,
} from '../contextExpansionRelay';

const { TabPane } = Tabs;

type Props = {
	deviceType: DeviceObjectType;
	isNameEmpty: boolean;
	nameError: boolean;
	setNameError: (boolean) => void;
	nameErrorText: string;
	id: number;
	setLoading: (boolean) => void;
	isLoading: boolean;
	useDoorContext: boolean;
	setErrorText?: () => void;
	onRefreshTimeZones?: (timeZones: SelectTimeZone[]) => void;
	timeZonesBelongFromDoor?: RelayLogicTimeZones;
};

//Avoid creating object style inline, since increases reconciliations
const user: User = getUser();

const RelayLogicContainer: React.FC<Props> = ({
	deviceType,
	isNameEmpty,
	nameError,
	setNameError,
	nameErrorText,
	id,
	setLoading,
	isLoading,
	useDoorContext,
	setErrorText,
	onRefreshTimeZones,
	timeZonesBelongFromDoor,
}) => {
	const [canViewGeneralTab, setCanViewGeneralTab] = useState(false);
	const [canViewLogicTab, setCanViewLogicTab] = useState(false);
	const [showNewTimeZone, setShowNewTimeZone] = useState(false);
	const [newTimeZoneId, setNewTimeZoneId] = useState(0);
	const [previousSelections, setPreviousSelections] = useState<RelayLogicTimeZones>({ Actuate: 1, Operate: 1, Disable: 1, AutoClear: 1 });

	if (useDoorContext) {
		var {
			contextStateDoor: {
				relay: stateContext,
				door: { newTimeZoneAdded },
			},
			dispatcherDoor,
		} = useContext(DoorStoreSingleContext);
	} else {
		var { expansionRelayState: stateContext, dispatcher } = useContext(StoreContext);
	}

	const {
		selections: { operateTimeZone, actuateTimeZone, disableTimeZone, autoClearAfterTimeZone },
	} = stateContext;

	useEffect(() => {
		if (id !== 0) {
			switch (deviceType) {
				case DeviceObjectType.ExpansionRelays:
					setCanViewGeneralTab(User.getSubComponentPermission(user, SubPermissions.ExpansionRelay_ViewGeneralTab).allowed);
					setCanViewLogicTab(User.getSubComponentPermission(user, SubPermissions.ExpansionRelay_ViewLogicTab).allowed);
					deviceAdminApi.loadExpansionRelay(id).then(res => {
						loadRelayInfo(res);
						const { ActuateTimeZone, OperateTimeZone, DisableTimeZone, AutoClearTimeZone } = res;
						setPreviousSelections({
							Actuate: ActuateTimeZone.GenericId,
							AutoClear: AutoClearTimeZone.GenericId,
							Disable: DisableTimeZone.GenericId,
							Operate: OperateTimeZone.GenericId,
						});
						setLoading(false);
					});
					break;

				case DeviceObjectType.Relay:
					setCanViewGeneralTab(useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Relay_ViewGeneralTab).allowed);
					setCanViewLogicTab(useDoorContext ? true : User.getSubComponentPermission(user, SubPermissions.Relay_ViewLogicTab).allowed);
					if (!useDoorContext) {
						deviceAdminApi.loadRelay(id).then(res => {
							loadRelayInfo(res);
							const { ActuateTimeZone, OperateTimeZone, DisableTimeZone, AutoClearTimeZone } = res;
							setPreviousSelections({
								Actuate: ActuateTimeZone.GenericId,
								AutoClear: AutoClearTimeZone.GenericId,
								Disable: DisableTimeZone.GenericId,
								Operate: OperateTimeZone.GenericId,
							});

							setLoading(false);
						});
					} else if (timeZonesBelongFromDoor) {
						setPreviousSelections(timeZonesBelongFromDoor);
					}
					break;
			}
		}
	}, [id]);

	useEffect(() => {
		if (operateTimeZone === 0 || actuateTimeZone === 0 || disableTimeZone === 0 || autoClearAfterTimeZone === 0) {
			deviceAdminApi.checkIfAnyTimeZoneIsNotLocked().then(res => {
				const isLocked = handleResponse(res);
				if (!isLocked) {
					setShowNewTimeZone(true);
				} else {
					handleCloseNewTimeZone();
				}
			});
		}
	}, [operateTimeZone, actuateTimeZone, disableTimeZone, autoClearAfterTimeZone]);

	const fetchNewTimeZones = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: true,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};

				genericTimeZones.unshift(newTimeZone);
			}

			if (useDoorContext) {
				dispatcherDoor(setOperateTimeZones(genericTimeZones));
				dispatcherDoor(setActuateTimeZones(genericTimeZones));
				dispatcherDoor(setAutoClearAfterTimeZones(genericTimeZones));
				dispatcherDoor(setDisableTimeZones(genericTimeZones));
			} else {
				dispatcher(setOperateTimeZones(genericTimeZones));
				dispatcher(setActuateTimeZones(genericTimeZones));
				dispatcher(setAutoClearAfterTimeZones(genericTimeZones));
				dispatcher(setDisableTimeZones(genericTimeZones));
			}

			if (operateTimeZone === 0) {
				useDoorContext ? dispatcherDoor(setOperateTimeZone(newTimeZoneId)) : dispatcher(setOperateTimeZone(newTimeZoneId));
			} else if (actuateTimeZone === 0) {
				useDoorContext ? dispatcherDoor(setActuateTimeZone(newTimeZoneId)) : dispatcher(setActuateTimeZone(newTimeZoneId));
			} else if (disableTimeZone === 0) {
				useDoorContext ? dispatcherDoor(setDisableTimeZone(newTimeZoneId)) : dispatcher(setDisableTimeZone(newTimeZoneId));
			} else if (autoClearAfterTimeZone === 0) {
				useDoorContext ? dispatcherDoor(setAutoClearAfterTimeZone(newTimeZoneId)) : dispatcher(setAutoClearAfterTimeZone(newTimeZoneId));
			}
			setShowNewTimeZone(false);
		});
	};

	useEffect(() => {
		if (useDoorContext) {
			dispatcherDoor(setDoorNewTimeZoneAction(newTimeZoneId));
		} else {
			fetchNewTimeZones();
		}
	}, [newTimeZoneId]);

	useEffect(() => {
		if (newTimeZoneAdded) {
			fetchNewTimeZones();
		}
	}, [newTimeZoneAdded]);

	const handleCloseNewTimeZone = () => {
		deviceAdminApi.getGenericTimeZones().then(genericTimeZones => {
			if (canAddNewTimeZones()) {
				const newTimeZone: SelectTimeZone = {
					Name: `<${_('New')}>`,
					Unpermitted: true,
					GenericId: 0,
					TimeZoneType: TimeZoneType.Standard,
					GlobalId: 0,
				};

				genericTimeZones.unshift(newTimeZone);
			}

			if (useDoorContext) {
				onRefreshTimeZones?.(genericTimeZones);
			} else {
				dispatcher(setOperateTimeZones(genericTimeZones));
				dispatcher(setActuateTimeZones(genericTimeZones));
				dispatcher(setAutoClearAfterTimeZones(genericTimeZones));
				dispatcher(setDisableTimeZones(genericTimeZones));
			}

			if (operateTimeZone === 0) {
				const existTimeZone = genericTimeZones.find(x => x.GenericId === previousSelections.Operate) ? previousSelections.Operate : 1;
				useDoorContext ? dispatcherDoor(setOperateTimeZone(existTimeZone)) : dispatcher(setOperateTimeZone(existTimeZone));
			} else if (actuateTimeZone === 0) {
				const existTimeZone = genericTimeZones.find(x => x.GenericId === previousSelections.Actuate) ? previousSelections.Actuate : 1;
				useDoorContext ? dispatcherDoor(setActuateTimeZone(existTimeZone)) : dispatcher(setActuateTimeZone(existTimeZone));
			} else if (disableTimeZone === 0) {
				const existTimeZone = genericTimeZones.find(x => x.GenericId === previousSelections.Disable) ? previousSelections.Disable : 1;
				useDoorContext ? dispatcherDoor(setDisableTimeZone(existTimeZone)) : dispatcher(setDisableTimeZone(existTimeZone));
			} else if (autoClearAfterTimeZone === 0) {
				const existTimeZone = genericTimeZones.find(x => x.GenericId === previousSelections.AutoClear) ? previousSelections.AutoClear : 1;
				useDoorContext ? dispatcherDoor(setAutoClearAfterTimeZone(existTimeZone)) : dispatcher(setAutoClearAfterTimeZone(existTimeZone));
			}
		});

		setShowNewTimeZone(false);
	};

	const loadRelayInfo = (res: ExpansionRelayInfo) => {
		const noControlZone: GenericControlTimeZones = {
			Name: `<${_('NoControl')}>`,
			CZIndex: 0,
		};

		dispatcher(setName(res.Name));
		dispatcher(setNormalState(res.NormalState));
		dispatcher(setControlMode(res.ControlMode));
		dispatcher(setControlDelay(res.ControlDelay));
		dispatcher(setControllerId(res.ControllerId));

		if (canAddNewTimeZones()) {
			const newTimeZone: SelectTimeZone = {
				Name: `<${_('New')}>`,
				Unpermitted: true,
				GenericId: 0,
				TimeZoneType: TimeZoneType.Standard,
				GlobalId: 0,
			};

			res.GenericTimeZones.unshift(newTimeZone);
		}

		dispatcher(setOperateTimeZones(res.GenericTimeZones));
		dispatcher(setActuateTimeZones(res.GenericTimeZones));
		dispatcher(setAutoClearAfterTimeZones(res.GenericTimeZones));
		dispatcher(setDisableTimeZones(res.GenericTimeZones));

		res.ControlZones.unshift(noControlZone);
		res.StandardControlZones.unshift(noControlZone);
		dispatcher(setTriggerControlZones(res.ControlZones));
		dispatcher(setReTriggerControlZones(res.StandardControlZones));

		if (res.OperateTimeZone.Unpermitted) {
			dispatcher(setUnpermittedOperateTimeZone(res.OperateTimeZone));
		}

		if (res.ActuateTimeZone.Unpermitted) {
			dispatcher(setUnpermittedActuateTimeZone(res.ActuateTimeZone));
		}

		if (res.DisableTimeZone.Unpermitted) {
			dispatcher(setUnpermittedDisableTimeZone(res.DisableTimeZone));
		}

		if (res.AutoClearTimeZone.Unpermitted) {
			dispatcher(setUnpermittedAutoClearTimeZone(res.AutoClearTimeZone));
		}

		dispatcher(setOperateTimeZone(res.OperateTimeZone.GenericId));
		dispatcher(setActuateTimeZone(res.ActuateTimeZone.GenericId));
		dispatcher(setDisableTimeZone(res.DisableTimeZone.GenericId));
		dispatcher(setAutoClearAfterTimeZone(res.AutoClearTimeZone.GenericId));

		dispatcher(setTriggerControlZone(res.TriggerControlZone));
		dispatcher(setReTriggerControlZone(res.ReTriggerControlZone));
	};

	useEffect(() => {
		fetchNewTimeZones();
	}, []);

	return (
		<>
			<Spin size='large' tip={`${_('Loading')}...`} spinning={isLoading}>
				<Tabs type={useDoorContext ? null : 'card'}>
					{canViewGeneralTab && (
						<TabPane
							tab={
								useDoorContext ? (
									<span id='doorModalRelaySetUpTabName'>{_('SetUp')}</span>
								) : (
									<span id='relayGeneralTabName'>{_('General')}</span>
								)
							}
							key='1'>
							<General
								nameValidation={isNameEmpty}
								nameError={nameError}
								onResetNameError={() => setNameError(false)}
								nameErrorText={nameErrorText}
								useDoorContext={useDoorContext}
								setErrorText={setErrorText}
							/>
						</TabPane>
					)}
					{canViewLogicTab && (
						<TabPane tab={<span id={useDoorContext ? 'doorRelayLogicTabName' : 'relayLogicTabName'}>{_('Logic')}</span>} key='2'>
							<Logic setPreviousSelections={setPreviousSelections} previousSelections={previousSelections} useDoorContext={useDoorContext} />
						</TabPane>
					)}
				</Tabs>
			</Spin>
			<Modal
				keyboard={false}
				maskClosable={false}
				visible={showNewTimeZone}
				title={_('TimeZones')}
				onClickOk={handleCloseNewTimeZone}
				onCancel={handleCloseNewTimeZone}
				width={'900px'}
				footer={null}>
				<TimeZones onEntityAction={setNewTimeZoneId} disableDelete />
			</Modal>
		</>
	);
};

export { RelayLogicContainer };

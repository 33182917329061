import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext } from 'react';
import { PortStoreContext, setEnablePort, setPortChangedAction } from '../../../contextPort';

type Props = {};

const EnablePort: React.FC<Props> = () => {
	const {
		portState: { enablePort, hasPortChanged },
		dispatcher,
	} = useContext(PortStoreContext);

	const checkIfPortChanged = () => {
		if (!hasPortChanged) {
			dispatcher(setPortChangedAction(true));
		}
	};

	const handleChange = (e: CheckboxChangeEvent) => {
		const value = e.target.checked;
		checkIfPortChanged();
		dispatcher(setEnablePort(value));
	};
	return (
		<div>
			<Checkbox id='portGeneralEnableCheckbox' checked={enablePort} onChange={handleChange}>
				{_('Enable')}
			</Checkbox>
		</div>
	);
};

export { EnablePort };

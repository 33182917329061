import { InputNumber } from 'antd';
import cx from 'classnames';
import React, { useContext } from 'react';
import { DoorStoreSingleContext } from '../../../../../DoorModal/DoorStoreSingleContext';
import { setInputAction } from '../../../../../InputModal/inputModalContext';
import styles from '../../../../expansionRelayModal.module.scss';

const DoorMode: React.FC = () => {
	const {
		contextStateDoor: { input },
		dispatcherDoor,
	} = useContext(DoorStoreSingleContext);

	const handleOnChangeDoorModeTime = (value: string) => {
		if (value === '' || value === null || !Number(value.toString())) {
			if (value !== '0') {
				return;
			}
		}

		dispatcherDoor(setInputAction({ DoorModeTime: Number(value.toString()) }));
	};

	return (
		<div className={styles.generalSection}>
			<span>{_('DoorModeTime')}</span>
			<div>
				<InputNumber
					key='doorModeTime'
					min={0}
					max={8100}
					value={input.input?.DoorModeTime}
					onChange={value => handleOnChangeDoorModeTime(value.toString())}
					id={'DoorModeTime'}
					precision={2}
					step={0.25}
					className={cx(styles.input)}
					formatter={value => `${value}`.replace(/[^0-9\.]+/g, '')}
				/>
			</div>
		</div>
	);
};

export { DoorMode };

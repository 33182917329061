import React, { useContext } from 'react';
import { ReaderInterface } from '../../../../../../../model/DeviceAdminModel';
import { DoorStoreSingleContext } from '../../../../DoorModal/DoorStoreSingleContext';
import { AntiPassback, AssuranceLevels, CCOTZ, PassbackZones } from '../sections';
import styles from './logic.module.scss';

type Props = {
	setPreviousSelection: (number) => void;
};

const Logic: React.FC<Props> = ({ setPreviousSelection }) => {
	var {
		contextStateDoor: {
			exitReader: { isDoorWireless, readerInterface },
		},
	} = useContext(DoorStoreSingleContext);

	return (
		<div id='logicExitReaderContainer' className={styles.form}>
			<PassbackZones />
			<AntiPassback />
			<CCOTZ setPreviousSelection={setPreviousSelection} />
			{!isDoorWireless && readerInterface === ReaderInterface.RS485 && <AssuranceLevels />}
		</div>
	);
};

export { Logic };
